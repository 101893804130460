const allWordsObj = {
    "1": 1,
    "3": 1,
    "4": 1,
    "7": 1,
    "10": 1,
    "20": 1,
    "21": 1,
    "22": 1,
    "23": 1,
    "24": 1,
    "25": 1,
    "26": 1,
    "27": 1,
    "28": 1,
    "29": 1,
    "30": 1,
    "60": 1,
    "90": 1,
    "100": 1,
    "150": 1,
    "180": 1,
    "230": 1,
    "544": 1,
    "600": 1,
    "705": 1,
    "969": 1,
    "1000": 1,
    "1279": 1,
    "1368": 1,
    "1861": 1,
    "1916": 1,
    "1921": 1,
    "1937": 1,
    "1957": 1,
    "2000": 1,
    "6133": 1,
    "العاشر": 1,
    "الأول": 1,
    "الثاني": 1,
    "الثالث": 1,
    "الرابع": 1,
    "الخامس": 1,
    "السادس": 1,
    "السابع": 1,
    "الثامن": 1,
    "التاسع": 1,
    "آي": 1,
    "الحرف": 1,
    "في": 1,
    "اللغة": 1,
    "الإنجليزية": 1,
    "زراعي": 1,
    "و": 1,
    "ميكانيكي": 1,
    "شركة": 1,
    "الشاي": 1,
    "الباسيفيكية": 1,
    "الأطلنطية": 1,
    "العظيمة": 1,
    "حرف": 1,
    "يسبق": 1,
    "الكلمة": 1,
    "ويعني": 1,
    "إتجاه": 1,
    "كذا": 1,
    "وكذا": 1,
    "او": 1,
    "الإستمرارية": 1,
    "القنبلة": 1,
    "النووية": 1,
    "الذرية": 1,
    "بحماس": 1,
    "جيدا": 1,
    "آها": 1,
    "فرقة": 1,
    "بوب": 1,
    "نرويجية": 1,
    "المقياس": 1,
    "المستخدم": 1,
    "التحويل": 1,
    "بين": 1,
    "الإشارات": 1,
    "السينية": 1,
    "الى": 1,
    "الرقمية": 1,
    "اصطف": 1,
    "فستان": 1,
    "ضيق": 1,
    "من": 1,
    "الأعلى": 1,
    "وواسع": 1,
    "الأسفل": 1,
    "ممتاز": 1,
    "الدرجة": 1,
    "الأولى": 1,
    "التوثيق": 1,
    "والتفويض": 1,
    "والمحاسبة": 1,
    "الرابطة": 1,
    "الأمريكية": 1,
    "لتقدم": 1,
    "العلم": 1,
    "اتحاد": 1,
    "هواة": 1,
    "الرياضة": 1,
    "رابطة": 1,
    "المصرفيين": 1,
    "مكيف": 1,
    "هواء": 1,
    "تيار": 1,
    "متردد": 1,
    "الحريات": 1,
    "المدنية": 1,
    "الأمريكي": 1,
    "مادة": 1,
    "تحتوي": 1,
    "على": 1,
    "أسبستوس": 1,
    "الجمعية": 1,
    "العربية": 1,
    "للكمبيوتر": 1,
    "الذكاء": 1,
    "الصناعي": 1,
    "ولاية": 1,
    "آلاسكا": 1,
    "ألاباما": 1,
    "وحدة": 1,
    "المنطق": 1,
    "الحسابية": 1,
    "الطبية": 1,
    "المؤسسة": 1,
    "الوطنية": 1,
    "للمقاييس": 1,
    "نظام": 1,
    "الصورة": 1,
    "المتقدم": 1,
    "أركانسو": 1,
    "شركه": 1,
    "أركو": 1,
    "للوقود": 1,
    "وكالة": 1,
    "مشاريع": 1,
    "البحوث": 1,
    "المتقدمة": 1,
    "اسرع": 1,
    "وقت": 1,
    "ممكن": 1,
    "لفحص": 1,
    "المواد": 1,
    "اي": 1,
    "تي": 1,
    "للإتصالات": 1,
    "الانتباه": 1,
    "أريزونا": 1,
    "درجة": 1,
    "الأستاذ": 1,
    "المساعد": 1,
    "الفنون": 1,
    "قاعدة": 1,
    "لبناء": 1,
    "أجهزة": 1,
    "الكمبيوتر": 1,
    "آل": 1,
    "شجيرة": 1,
    "الهند": 1,
    "مقياس": 1,
    "السوائل": 1,
    "هولندا": 1,
    "وألمانيا": 1,
    "جمعية": 1,
    "الناشرين": 1,
    "الأمريكيين": 1,
    "الأرض": 1,
    "حيوان": 1,
    "ثديي": 1,
    "أفريقي": 1,
    "ليلي": 1,
    "كما": 1,
    "الذئب": 1,
    "خنزير": 1,
    "خنازير": 1,
    "ضبع": 1,
    "مخطط": 1,
    "يقتات": 1,
    "الحشرات": 1,
    "مدينة": 1,
    "وميناء": 1,
    "آرهس": 1,
    "هارون": 1,
    "متعلق": 1,
    "بالقساوسة": 1,
    "آرونز": 1,
    "آرونسبرج": 1,
    "للمتقاعدين": 1,
    "مساعد": 1,
    "العلوم": 1,
    "التطبيقية": 1,
    "آسو": 1,
    "فصيلة": 1,
    "الدم": 1,
    "ا": 1,
    "ب": 1,
    "أديس": 1,
    "أبابا": 1,
    "عاصمة": 1,
    "أثيوبيا": 1,
    "بعيدا": 1,
    "عن": 1,
    "أباك": 1,
    "شجرة": 1,
    "الموز": 1,
    "الفلبينية": 1,
    "العدادات": 1,
    "الآلات": 1,
    "الحاسبة": 1,
    "يسبب": 1,
    "العمى": 1,
    "يعمي": 1,
    "العين": 1,
    "إلى": 1,
    "الخلف": 1,
    "الوراء": 1,
    "العداد": 1,
    "أنثى": 1,
    "الخرتيت": 1,
    "ملاك": 1,
    "الجحيم": 1,
    "مؤخرة": 1,
    "السفينة": 1,
    "الإنحناء": 1,
    "تعبيرا": 1,
    "الإحترام": 1,
    "منزعج": 1,
    "منحرج": 1,
    "أذن": 1,
    "البحر": 1,
    "بحري": 1,
    "آذان": 1,
    "حيوانات": 1,
    "بحرية": 1,
    "تساوي": 1,
    "عشرة": 1,
    "أمبير": 1,
    "يهجر": 1,
    "يترك": 1,
    "يتخلى": 1,
    "يتنازل": 1,
    "مهجور": 1,
    "متروك": 1,
    "متخلى": 1,
    "عنه": 1,
    "سائب": 1,
    "بلا": 1,
    "حدود": 1,
    "المتخلي": 1,
    "التارك": 1,
    "الترك": 1,
    "الهجر": 1,
    "التنازل": 1,
    "التخلي": 1,
    "سلسلة": 1,
    "جبال": 1,
    "شرق": 1,
    "الأردن": 1,
    "يحقر": 1,
    "يهين": 1,
    "يذل": 1,
    "أهان": 1,
    "حقر": 1,
    "التقليل": 1,
    "الشأن": 1,
    "التحقير": 1,
    "الإهانة": 1,
    "موجه": 1,
    "الإهانات": 1,
    "المهين": 1,
    "احرج": 1,
    "أخجل": 1,
    "أربك": 1,
    "أحرج": 1,
    "مرتبك": 1,
    "بشكل": 1,
    "مخزي": 1,
    "محرج": 1,
    "يحرج": 1,
    "يربك": 1,
    "الإحراج": 1,
    "الإرباك": 1,
    "الخجل": 1,
    "الحرج": 1,
    "بانتفاء": 1,
    "القدرة": 1,
    "المشي": 1,
    "قابل": 1,
    "للإلغاء": 1,
    "خفف": 1,
    "أخمد": 1,
    "يلغي": 1,
    "يبطل": 1,
    "همد": 1,
    "خف": 1,
    "أبطل": 1,
    "السكون": 1,
    "التخفيض": 1,
    "انقضاء": 1,
    "(سقوط": 1,
    "الحق)": 1,
    "المخفض": 1,
    "المقلل": 1,
    "يخفف": 1,
    "يقلل": 1,
    "خط": 1,
    "الدفاع": 1,
    "المتكون": 1,
    "الأشجار": 1,
    "والأشياء": 1,
    "الحادة": 1,
    "لردع": 1,
    "العدو": 1,
    "مستغل": 1,
    "بعد": 1,
    "وفاة": 1,
    "مالكها": 1,
    "المذبح": 1,
    "المسلخ": 1,
    "المذابح": 1,
    "المسالخ": 1,
    "الأعشاب": 1,
    "الأعواد": 1,
    "التي": 1,
    "تداس": 1,
    "بالحيوانات": 1,
    "عند": 1,
    "مرورها": 1,
    "عليها": 1,
    "بعيد": 1,
    "المحور": 1,
    "نباح": 1,
    "الكلاب": 1,
    "البنك": 1,
    "المتحد": 1,
    "للأعمال": 1,
    "الله": 1,
    "النسخة": 1,
    "الحديثة": 1,
    "للإنجيل": 1,
    "آبا": 1,
    "السويدية": 1,
    "رئاسات": 1,
    "الدير": 1,
    "عباس": 1,
    "بالدير": 1,
    "ودور": 1,
    "العبادة": 1,
    "مختص": 1,
    "رئيس": 1,
    "الراهبة": 1,
    "الرئيسة": 1,
    "أبيفيل": 1,
    "الكنيسة": 1,
    "الأديرة": 1,
    "الكنائس": 1,
    "رؤساء": 1,
    "أبوت": 1,
    "إختصار": 1,
    "لكلمة": 1,
    "اختصر": 1,
    "اختزل": 1,
    "يختصر": 1,
    "مختصر": 1,
    "مختزل": 1,
    "يختزل": 1,
    "الاختصار": 1,
    "الإختزال": 1,
    "الاختصارات": 1,
    "الإختزالات": 1,
    "المختصر": 1,
    "المختزل": 1,
    "إختصاري": 1,
    "المختصرة": 1,
    "الموجز": 1,
    "الحالات": 1,
    "الإبتدائية": 1,
    "لأي": 1,
    "مرحلة": 1,
    "متدين": 1,
    "أو": 1,
    "درويش": 1,
    "إيران": 1,
    "الأحمق": 1,
    "مواطن": 1,
    "أبديرا": 1,
    "عبدي": 1,
    "خادمي": 1,
    "عبدالله": 1,
    "عبد": 1,
    "للخالق": 1,
    "امتلاك": 1,
    "قدرة": 1,
    "الاختباء": 1,
    "مكان": 1,
    "جيد": 1,
    "لتخبئة": 1,
    "الأشياء": 1,
    "ذو": 1,
    "كرش": 1,
    "كبير": 1,
    "بدين": 1,
    "بطين": 1,
    "يبعد": 1,
    "ينحي": 1,
    "نحى": 1,
    "أبعد": 1,
    "العضلة": 1,
    "المحركة": 1,
    "للعين": 1,
    "مبعد": 1,
    "الإبعاد": 1,
    "يتحمل": 1,
    "يتصرف": 1,
    "التصرف": 1,
    "المبتدئ": 1,
    "قصيدة": 1,
    "شعرية": 1,
    "المبدأ": 1,
    "العضو": 1,
    "البدائي": 1,
    "شيئ": 1,
    "نبات": 1,
    "الحور": 1,
    "الأبيض": 1,
    "الـ": 1,
    "أبيليا": 1,
    "أبيل": 1,
    "نهاية": 1,
    "غير": 1,
    "متوقعة": 1,
    "لعملية": 1,
    "عمليات": 1,
    "الكومبيوتر": 1,
    "أبيركرومبي": 1,
    "عصفور": 1,
    "أصفر": 1,
    "أخضر": 1,
    "صغير": 1,
    "يتجول": 1,
    "يتوه": 1,
    "ينحرف": 1,
    "يضل": 1,
    "الحالة": 1,
    "الشاذة": 1,
    "الإنحراف": 1,
    "الطريق": 1,
    "السوي": 1,
    "المتوقع": 1,
    "بالاضطراب": 1,
    "يتخلص": 1,
    "إنساني": 1,
    "كريه": 1,
    "أبياثار": 1,
    "ابن": 1,
    "أهيميليتش": 1,
    "الكاهن": 1,
    "الأكبر": 1,
    "أبيب": 1,
    "الشهر": 1,
    "السنة": 1,
    "الكنائسية": 1,
    "اليهودية": 1,
    "يستمر": 1,
    "يبقى": 1,
    "ثابتا": 1,
    "مع": 1,
    "أبي": 1,
    "الإله": 1,
    "الأب": 1,
    "الرغبة": 1,
    "الإنسحاب": 1,
    "عدم": 1,
    "التعرض": 1,
    "لموقف": 1,
    "معين": 1,
    "إنسان": 1,
    "يتجنب": 1,
    "المواجهة": 1,
    "الأبيس": 1,
    "بشجرة": 1,
    "الإبيس": 1,
    "مقدم": 1,
    "يد": 1,
    "العون": 1,
    "أب": 1,
    "الهاوية": 1,
    "أبينجتون": 1,
    "التطور": 1,
    "المفترض": 1,
    "للكائنات": 1,
    "الحية": 1,
    "المادة": 1,
    "الميتة": 1,
    "بدراسة": 1,
    "الغير": 1,
    "حية": 1,
    "الضعف": 1,
    "الوهن": 1,
    "الندى": 1,
    "الطيبة": 1,
    "يرفض": 1,
    "بطريقة": 1,
    "قانونية": 1,
    "الرفض": 1,
    "طريق": 1,
    "القانون": 1,
    "يحرر": 1,
    "متميز": 1,
    "إستثنائي": 1,
    "يفطم": 1,
    "الرضاعة": 1,
    "فطم": 1,
    "يفتصل": 1,
    "المتحرك": 1,
    "يتمتع": 1,
    "بجسم": 1,
    "سليم": 1,
    "ممثل": 1,
    "البابا": 1,
    "التمييز": 1,
    "الناس": 1,
    "بناء": 1,
    "المقدرة": 1,
    "الجسمية": 1,
    "أبلين": 1,
    "سمك": 1,
    "نهري": 1,
    "غياب": 1,
    "جفون": 1,
    "يقيد": 1,
    "الإنفاق": 1,
    "المفرط": 1,
    "الطعام": 1,
    "ربما": 1,
    "يختلف": 1,
    "منظف": 1,
    "دواء": 1,
    "مطهر": 1,
    "محمر": 1,
    "خجلا": 1,
    "جرامسنتيمترفي": 1,
    "الثانية": 1,
    "التوصيل": 1,
    "الكهربي": 1,
    "يحل": 1,
    "التداخلات": 1,
    "والتشابكات": 1,
    "النذير": 1,
    "سياسة": 1,
    "إبطال": 1,
    "الاسترقاق": 1,
    "الإبطالية": 1,
    "الإلغائية": 1,
    "أبوما": 1,
    "كبيرة": 1,
    "جنوب": 1,
    "أمريكا": 1,
    "القسم": 1,
    "معدة": 1,
    "مجتر": 1,
    "حيث": 1,
    "يتم": 1,
    "الهظم": 1,
    "عدائي": 1,
    "وحاقد": 1,
    "فوق": 1,
    "الشيء": 1,
    "أبور": 1,
    "أحد": 1,
    "لغات": 1,
    "كاماربان": 1,
    "معروفة": 1,
    "الفم": 1,
    "ببعد": 1,
    "يقترب": 1,
    "يتصل": 1,
    "الشعب": 1,
    "الأصلي": 1,
    "قارة": 1,
    "أستراليا": 1,
    "مصل": 1,
    "الإجهاض": 1,
    "الجنين": 1,
    "البشري": 1,
    "الملفوظ": 1,
    "قبل": 1,
    "رحم": 1,
    "الأم": 1,
    "الإستدارة": 1,
    "العدول": 1,
    "رأي": 1,
    "حي": 1,
    "يرزق": 1,
    "المذكور": 1,
    "أعلاه": 1,
    "الأساقفة": 1,
    "معدل": 1,
    "سرعة": 1,
    "الإتصال": 1,
    "الأوتوماتيكي": 1,
    "المجرى": 1,
    "الضيق": 1,
    "التمتمة": 1,
    "الهراء": 1,
    "تعويذة": 1,
    "كلام": 1,
    "مفهوم": 1,
    "أداة": 1,
    "تنعيم": 1,
    "ورق": 1,
    "الصنفرة": 1,
    "بإبراهام": 1,
    "البطريرك": 1,
    "يوقض": 1,
    "ينبه": 1,
    "يثير": 1,
    "يتآكل": 1,
    "الأبروم": 1,
    "حظ": 1,
    "لدى": 1,
    "الفراعنة": 1,
    "مبزول": 1,
    "مفتوح": 1,
    "مثقوب": 1,
    "الألفباء": 1,
    "للتنازل": 1,
    "تنح": 1,
    "تنحى": 1,
    "تنازل": 1,
    "تخلى": 1,
    "يتنحى": 1,
    "التنحي": 1,
    "المتنازل": 1,
    "المتنحي": 1,
    "البطن": 1,
    "الجوف": 1,
    "البطون": 1,
    "بطني": 1,
    "جوفي": 1,
    "بطنيا": 1,
    "جوفيا": 1,
    "اخطف": 1,
    "يخطف": 1,
    "خطف": 1,
    "الخطف": 1,
    "الاختطاف": 1,
    "إبعاد": 1,
    "المختطف": 1,
    "المبعد": 1,
    "المختطفون": 1,
    "نحو": 1,
    "جانب": 1,
    "الفراش": 1,
    "طريح": 1,
    "آبيل": 1,
    "أبيلايان": 1,
    "أبيلسون": 1,
    "أبردين": 1,
    "أبيرناثي": 1,
    "الضلال": 1,
    "شاذ": 1,
    "زائغ": 1,
    "ضال": 1,
    "غاو": 1,
    "بشذوذ": 1,
    "بزيغ": 1,
    "بضلال": 1,
    "الاضطراب": 1,
    "الانحراف": 1,
    "الشذوذ": 1,
    "الاضطرابات": 1,
    "الزيغ": 1,
    "يغري": 1,
    "يحرض": 1,
    "التحريض": 1,
    "الإغواء": 1,
    "يغوي": 1,
    "حرض": 1,
    "أغوى": 1,
    "المحرض": 1,
    "المغوي": 1,
    "تعليق": 1,
    "مؤقت": 1,
    "توقيف": 1,
    "لمدة": 1,
    "محدودة": 1,
    "معطل": 1,
    "معلق": 1,
    "يمقت": 1,
    "يشمئز": 1,
    "بشدة": 1,
    "مقت": 1,
    "اشمئز": 1,
    "كره": 1,
    "يكره": 1,
    "المقت": 1,
    "الكره": 1,
    "الإشمئزاز": 1,
    "ممقوت": 1,
    "مشمئز": 1,
    "ماقت": 1,
    "بصورة": 1,
    "مشمئزة": 1,
    "الماقت": 1,
    "الكاره": 1,
    "المشمئز": 1,
    "التزام": 1,
    "حرص": 1,
    "تقيد": 1,
    "يقيم": 1,
    "يثبت": 1,
    "أقام": 1,
    "التزم": 1,
    "الباقي": 1,
    "الملتزم": 1,
    "يلتزم": 1,
    "الإقامة": 1,
    "الإلتزام": 1,
    "دائم": 1,
    "ثابت": 1,
    "بثبات": 1,
    "بالتزام": 1,
    "أبيدجان": 1,
    "الجارية": 1,
    "الوصيفة": 1,
    "أبيلين": 1,
    "القدرات": 1,
    "المؤهلات": 1,
    "اقتدار": 1,
    "إمكان": 1,
    "ملكة": 1,
    "مشفق": 1,
    "مذل": 1,
    "مقنط": 1,
    "الحقارة": 1,
    "بذل": 1,
    "بوضاعة": 1,
    "الإنحطاط": 1,
    "الذل": 1,
    "التبرؤ": 1,
    "إنكار": 1,
    "الفعلد": 1,
    "السابق": 1,
    "انبذ": 1,
    "تبرأ": 1,
    "نبذ": 1,
    "فعل": 1,
    "سابق": 1,
    "ينبذ": 1,
    "يتبرأ": 1,
    "النبذ": 1,
    "يختفي": 1,
    "خلال": 1,
    "التآكل": 1,
    "التبخر": 1,
    "ذاب": 1,
    "تبخر": 1,
    "إختفى": 1,
    "يذوب": 1,
    "يتبخر": 1,
    "الذوبان": 1,
    "الإختفاء": 1,
    "الاستئصال": 1,
    "الجراحة": 1,
    "صيغة": 1,
    "الجر": 1,
    "بصيغة": 1,
    "إستئصالي": 1,
    "مشتعل": 1,
    "ملتهب": 1,
    "متقد": 1,
    "قادر": 1,
    "مؤهل": 1,
    "القادر": 1,
    "البارع": 1,
    "الجسم": 1,
    "العقل": 1,
    "قوة": 1,
    "الشباب": 1,
    "الحيوية": 1,
    "النشاط": 1,
    "أكثر": 1,
    "نشاطا": 1,
    "حيوية": 1,
    "الأكثر": 1,
    "نشاط": 1,
    "حالة": 1,
    "إزهار": 1,
    "متفتح": 1,
    "مزدهر": 1,
    "الوضوء": 1,
    "الضوء": 1,
    "إستعدادا": 1,
    "للصلاة": 1,
    "المسلمين": 1,
    "ببراعة": 1,
    "متمكن": 1,
    "انكر": 1,
    "حرم": 1,
    "رفض": 1,
    "ينكر": 1,
    "يحرم": 1,
    "الإنكار": 1,
    "التخلى": 1,
    "الزهد": 1,
    "النكران": 1,
    "التنسك": 1,
    "المنكر": 1,
    "الزاهد": 1,
    "المتنسك": 1,
    "أبنر": 1,
    "طبيعي": 1,
    "مختل": 1,
    "العيوب": 1,
    "التصرفات": 1,
    "الغرابة": 1,
    "التخلف": 1,
    "العيب": 1,
    "الخروج": 1,
    "المعهود": 1,
    "سوي": 1,
    "شاذة": 1,
    "السكان": 1,
    "الأصليين": 1,
    "لأستراليا": 1,
    "بالداخل": 1,
    "بجانب": 1,
    "ظهر": 1,
    "المركب": 1,
    "قرب": 1,
    "جزء": 1,
    "مجموعة": 1,
    "المنزل": 1,
    "المسكن": 1,
    "المقر": 1,
    "المنازل": 1,
    "المساكن": 1,
    "البيوت": 1,
    "ألغ": 1,
    "يزيل": 1,
    "إبطاله": 1,
    "إلغاؤه": 1,
    "القضاء": 1,
    "عليه": 1,
    "ألغى": 1,
    "أزال": 1,
    "قضى": 1,
    "المبطل": 1,
    "الملغي": 1,
    "القاضي": 1,
    "يقضي": 1,
    "الإلغاء": 1,
    "الإبطال": 1,
    "مؤيد": 1,
    "العنصرية": 1,
    "المؤيدون": 1,
    "للقضاء": 1,
    "رديء": 1,
    "بغيض": 1,
    "مقيت": 1,
    "اكره": 1,
    "شائن": 1,
    "البغض": 1,
    "المكروه": 1,
    "شيء": 1,
    "شنعة": 1,
    "استنكار": 1,
    "فحشاء": 1,
    "المكروهات": 1,
    "الشناعة": 1,
    "الفحشاء": 1,
    "بدائي": 1,
    "أولي": 1,
    "أصلي": 1,
    "بدائيا": 1,
    "أصليا": 1,
    "استراليا": 1,
    "أثناء": 1,
    "الولادة": 1,
    "اجهض": 1,
    "أحبط": 1,
    "يجهض": 1,
    "يضع": 1,
    "حدا": 1,
    "يوقف": 1,
    "النمو": 1,
    "أجهض": 1,
    "أوقف": 1,
    "وضع": 1,
    "مجهض": 1,
    "إيقاف": 1,
    "الإسقاط": 1,
    "إسقاط": 1,
    "الجهيض": 1,
    "إجهاضي": 1,
    "بالإجهاض": 1,
    "إخصائي": 1,
    "المجهض": 1,
    "فشل": 1,
    "الخطة": 1,
    "فاشل": 1,
    "حابط": 1,
    "مخفق": 1,
    "ناقص": 1,
    "مثمرة": 1,
    "الإخفاق": 1,
    "الفشل": 1,
    "إتمام": 1,
    "المهمة": 1,
    "يتوقف": 1,
    "أداء": 1,
    "كثر": 1,
    "فاض": 1,
    "يسود": 1,
    "يغزر": 1,
    "يكثر": 1,
    "ساد": 1,
    "غزر": 1,
    "الكثرة": 1,
    "متوفر": 1,
    "بكثرة": 1,
    "غزير": 1,
    "يوفر": 1,
    "يغدق": 1,
    "تقريبا": 1,
    "حوالي": 1,
    "حول": 1,
    "زهاء": 1,
    "لأعلى": 1,
    "وراء": 1,
    "صريح": 1,
    "جهارا": 1,
    "صراحة": 1,
    "علانية": 1,
    "سطحي": 1,
    "سطح": 1,
    "الارض": 1,
    "موجود": 1,
    "الذكر": 1,
    "ينكشط": 1,
    "يفرك": 1,
    "بقوة": 1,
    "يتوارى": 1,
    "يضرب": 1,
    "سحج": 1,
    "برى": 1,
    "كشط": 1,
    "يبري": 1,
    "انكشط": 1,
    "حك": 1,
    "ضرب": 1,
    "كاشط": 1,
    "الحاك": 1,
    "الضارب": 1,
    "يكشط": 1,
    "يحك": 1,
    "الكشط": 1,
    "الحك": 1,
    "الضرب": 1,
    "البري": 1,
    "السيد": 1,
    "إبراهيم": 1,
    "أبو": 1,
    "الأنبياء": 1,
    "أبرام": 1,
    "أبرامز": 1,
    "أبرامسون": 1,
    "الجرح": 1,
    "السحج": 1,
    "الجروح": 1,
    "التآكلات": 1,
    "المزيل": 1,
    "الكاشط": 1,
    "كاشطة": 1,
    "وقح": 1,
    "بخشونة": 1,
    "الخشونة": 1,
    "المزيلات": 1,
    "المنظفات": 1,
    "الكواشط": 1,
    "حرر": 1,
    "إزال": 1,
    "العقد": 1,
    "نفس": 1,
    "صرف": 1,
    "تفريج": 1,
    "نفساني": 1,
    "فرج": 1,
    "نفسيا": 1,
    "تخليص": 1,
    "التصريف": 1,
    "النفسي": 1,
    "التفريج": 1,
    "تحرر": 1,
    "عقدة": 1,
    "نفسية": 1,
    "تنفيس": 1,
    "ينفس": 1,
    "يتحرر": 1,
    "جنبا": 1,
    "جنب": 1,
    "متمشيا": 1,
    "واحد": 1,
    "أوجز": 1,
    "قصر": 1,
    "يقصر": 1,
    "فصل": 1,
    "لخص": 1,
    "مقصر": 1,
    "ملخص": 1,
    "موجز": 1,
    "الملخص": 1,
    "المقصر": 1,
    "يوجز": 1,
    "يلخص": 1,
    "الإيجاز": 1,
    "التلخيص": 1,
    "بالخارج": 1,
    "باتساع": 1,
    "خارج": 1,
    "البلاد": 1,
    "كل": 1,
    "اتجاه": 1,
    "مساحة": 1,
    "واسعة": 1,
    "فسخ": 1,
    "يفسخ": 1,
    "الفسخ": 1,
    "إنقطاع": 1,
    "مفاجيء": 1,
    "أبرونيا": 1,
    "مفاجئ": 1,
    "متقطع": 1,
    "مرتبط": 1,
    "شديد": 1,
    "الإنحدار": 1,
    "الانقطاع": 1,
    "توقف": 1,
    "فجأة": 1,
    "عجل": 1,
    "المفاجأة": 1,
    "فظاظة": 1,
    "مباغتة": 1,
    "ترابط": 1,
    "أربس": 1,
    "منطقة": 1,
    "جبلية": 1,
    "وسط": 1,
    "إيطاليا": 1,
    "أبسالوم": 1,
    "إبن": 1,
    "الملك": 1,
    "داود": 1,
    "الدمل": 1,
    "خراج": 1,
    "مصاب": 1,
    "بخراج": 1,
    "فيه": 1,
    "الدمامل": 1,
    "الفصل": 1,
    "العزل": 1,
    "الخراج": 1,
    "الإزالة": 1,
    "يعزل": 1,
    "يفصل": 1,
    "القطع": 1,
    "البتر": 1,
    "الإحداثي": 1,
    "السيني": 1,
    "الإحداثيات": 1,
    "اهرب": 1,
    "هرب": 1,
    "فر": 1,
    "سرا": 1,
    "إستخفى": 1,
    "الإختباء": 1,
    "الإخفاء": 1,
    "المختفي": 1,
    "الهارب": 1,
    "الهروب": 1,
    "خفية": 1,
    "يهرب": 1,
    "الغياب": 1,
    "انعدام": 1,
    "فقدان": 1,
    "شرود": 1,
    "غائب": 1,
    "مفقود": 1,
    "معدوم": 1,
    "شارد": 1,
    "الذهن": 1,
    "المذهول": 1,
    "مشغول": 1,
    "البال": 1,
    "بذهن": 1,
    "ببال": 1,
    "بذهول": 1,
    "غاب": 1,
    "الغائب": 1,
    "المتغيب": 1,
    "التغرب": 1,
    "التغيب": 1,
    "العمل": 1,
    "الواجب": 1,
    "الغائبون": 1,
    "المتغيبون": 1,
    "غيب": 1,
    "النطق": 1,
    "بالحكم": 1,
    "والمتهم": 1,
    "حاضر": 1,
    "الحضور": 1,
    "الظهور": 1,
    "تائه": 1,
    "غافل": 1,
    "بتيه": 1,
    "الشرود": 1,
    "الذهول": 1,
    "(السهو)": 1,
    "الشارد": 1,
    "يغيب": 1,
    "لايظهر": 1,
    "لايحضر": 1,
    "أبسيت": 1,
    "لغة": 1,
    "للبرمجة": 1,
    "الأبسنث": 1,
    "شراب": 1,
    "مسكر": 1,
    "نبتة": 1,
    "تستعمل": 1,
    "كعقار": 1,
    "طبي": 1,
    "القبا": 1,
    "ناتيء": 1,
    "مزخرف": 1,
    "مبنى": 1,
    "نصف": 1,
    "دائري": 1,
    "يقف": 1,
    "يغادر": 1,
    "يقلع": 1,
    "مطلق": 1,
    "حتمي": 1,
    "خالص": 1,
    "كامل": 1,
    "مطبق": 1,
    "تماما": 1,
    "إطلاقا": 1,
    "حتما": 1,
    "جازم": 1,
    "الحقيقة": 1,
    "المطلقة": 1,
    "الغفران": 1,
    "إعفاء": 1,
    "حل": 1,
    "واجب": 1,
    "تبرئة": 1,
    "الاستبداد": 1,
    "حكم": 1,
    "ديكتاتوري": 1,
    "نظرية": 1,
    "سياسية": 1,
    "المستبد": 1,
    "الطاغية": 1,
    "المؤيد": 1,
    "للإستبداد": 1,
    "إعفائي": 1,
    "غفراني": 1,
    "للغفران": 1,
    "للتبرئة": 1,
    "العفو": 1,
    "أعف": 1,
    "برأ": 1,
    "يبرئ": 1,
    "يحلل": 1,
    "غفر": 1,
    "أعفى": 1,
    "حلل": 1,
    "المبرئ": 1,
    "العافي": 1,
    "الغافر": 1,
    "يعفي": 1,
    "يبريء": 1,
    "يغفر": 1,
    "الإعفاء": 1,
    "التبرأة": 1,
    "نشاز": 1,
    "متضارب": 1,
    "متنافر": 1,
    "النغمات": 1,
    "متعارض": 1,
    "امتص": 1,
    "تشرب": 1,
    "يتشرب": 1,
    "يمتص": 1,
    "قابلية": 1,
    "الامتصاص": 1,
    "الامتصاصية": 1,
    "التشربية": 1,
    "للامتصاص": 1,
    "للتشرب": 1,
    "مستغرق": 1,
    "منهمك": 1,
    "بانشغال": 1,
    "بانهماك": 1,
    "التشرب": 1,
    "ممتصية": 1,
    "ممتص": 1,
    "ماص": 1,
    "نشاف": 1,
    "الأعضاء": 1,
    "الماصة": 1,
    "القابلة": 1,
    "للإمتصاص": 1,
    "الممتص": 1,
    "تقوم": 1,
    "إمتصاص": 1,
    "الإشعاع": 1,
    "النووي": 1,
    "المفاعل": 1,
    "الذري": 1,
    "مشوق": 1,
    "مستحوذ": 1,
    "للاهتمام": 1,
    "قابلة": 1,
    "شادة": 1,
    "للإنتباه": 1,
    "الانهماك": 1,
    "استغراق": 1,
    "استيعاب": 1,
    "امتصاص": 1,
    "انشغال": 1,
    "يستوعب": 1,
    "تكاليف": 1,
    "قياس": 1,
    "لمعدل": 1,
    "انخفاض": 1,
    "الكهرومغناطيسي": 1,
    "عملية": 1,
    "كيميائية": 1,
    "بتخلل": 1,
    "اخرى": 1,
    "الإمتصاص": 1,
    "الإستيعاب": 1,
    "خاصية": 1,
    "لإمتصاص": 1,
    "صفة": 1,
    "الكائن": 1,
    "الخاصية": 1,
    "للسوائل": 1,
    "المستوعبة": 1,
    "سرق": 1,
    "شيئا": 1,
    "وفر": 1,
    "هاربا": 1,
    "امتنع": 1,
    "يمتنع": 1,
    "ينفك": 1,
    "تناول": 1,
    "التصويت": 1,
    "الممتنع": 1,
    "محجم": 1,
    "ممتنع": 1,
    "ممسك": 1,
    "الامتناع": 1,
    "الإحجام": 1,
    "متقشف": 1,
    "متعفف": 1,
    "متقلل": 1,
    "متقشفة": 1,
    "بتعفف": 1,
    "التقشف": 1,
    "التعفف": 1,
    "إقلاع": 1,
    "إمساك": 1,
    "انقطاع": 1,
    "زهد": 1,
    "امتناعي": 1,
    "إمساكي": 1,
    "طهر": 1,
    "يطهر": 1,
    "ينظف": 1,
    "المنظفة": 1,
    "التنظيف": 1,
    "التطهير": 1,
    "تعفف": 1,
    "عفة": 1,
    "العفة": 1,
    "بتقشف": 1,
    "الخلاصة": 1,
    "تجريدي": 1,
    "مثالي": 1,
    "مجرد": 1,
    "مذهول": 1,
    "بشرود": 1,
    "ذهن": 1,
    "التركيز": 1,
    "ونسيان": 1,
    "اخر": 1,
    "القائم": 1,
    "الإختصار": 1,
    "الفكرة": 1,
    "المجردة": 1,
    "تجريد": 1,
    "اختلاس": 1,
    "تلخيص": 1,
    "التجريدية": 1,
    "عرض": 1,
    "الشيئ": 1,
    "بدون": 1,
    "الإستدلال": 1,
    "بأي": 1,
    "مرجع": 1,
    "متخصص": 1,
    "الفن": 1,
    "التجريدي": 1,
    "الأفكار": 1,
    "الاختلاس": 1,
    "التجريد": 1,
    "تجردي": 1,
    "تلخيصي": 1,
    "تجريدية": 1,
    "وجهة": 1,
    "التجرد": 1,
    "المعلومات": 1,
    "الوثائق": 1,
    "يجرد": 1,
    "يختلس": 1,
    "صعب": 1,
    "عميق": 1,
    "عويص": 1,
    "مبهم": 1,
    "بصعوبة": 1,
    "الصعوبة": 1,
    "الوضوح": 1,
    "الإستهلاك": 1,
    "اندثار": 1,
    "فناء": 1,
    "إخماد": 1,
    "إطفاء": 1,
    "انقراض": 1,
    "أحمق": 1,
    "باطل": 1,
    "لامعقول": 1,
    "السخف": 1,
    "الحماقة": 1,
    "حماقة": 1,
    "سخافة": 1,
    "طبيعية": 1,
    "بسخف": 1,
    "بحماقة": 1,
    "منافاة": 1,
    "أبسيس": 1,
    "برمجية": 1,
    "قيد": 1,
    "البناء": 1,
    "الإنشاء": 1,
    "الإرادة": 1,
    "القوة": 1,
    "فاقد": 1,
    "أبونا": 1,
    "القسيس": 1,
    "عميد": 1,
    "الحبشية": 1,
    "غزارة": 1,
    "وفرة": 1,
    "وفير": 1,
    "كثير": 1,
    "بوفرة": 1,
    "بغزارة": 1,
    "لإساءة": 1,
    "الإستعمال": 1,
    "سوء": 1,
    "الاستغلال": 1,
    "ابتذال": 1,
    "شتيمة": 1,
    "يسيء": 1,
    "استعمال": 1,
    "يشتم": 1,
    "عابث": 1,
    "استغل": 1,
    "أساء": 1,
    "عبث": 1,
    "شتم": 1,
    "المستغل": 1,
    "الشاتم": 1,
    "العابث": 1,
    "المستغلون": 1,
    "العابثون": 1,
    "الشاتمون": 1,
    "الشتيمة": 1,
    "العبث": 1,
    "إساءة": 1,
    "يخدع": 1,
    "يسيئ": 1,
    "الخديعة": 1,
    "خطأ": 1,
    "بذيء": 1,
    "سفيه": 1,
    "فاسد": 1,
    "مؤذ": 1,
    "بسفالة": 1,
    "بأذى": 1,
    "بفساد": 1,
    "الوقاحة": 1,
    "سفاهة": 1,
    "بذاءة": 1,
    "يتاخم": 1,
    "يرتكز": 1,
    "الأبوطيلون": 1,
    "متاخمة": 1,
    "محاذاة": 1,
    "ملتقى": 1,
    "شيئين": 1,
    "الدعائم": 1,
    "أطراف": 1,
    "اﻷرض": 1,
    "تاخم": 1,
    "شارك": 1,
    "الحدود": 1,
    "المتاخم": 1,
    "المشارك": 1,
    "المتاخمة": 1,
    "ملاصق": 1,
    "محاذ": 1,
    "بصوت": 1,
    "كأزيز": 1,
    "النحل": 1,
    "فرق": 1,
    "جهد": 1,
    "بمقدار": 1,
    "مئة": 1,
    "مليون": 1,
    "فولت": 1,
    "يحسن": 1,
    "لـ": 1,
    "يضيف": 1,
    "قاع": 1,
    "سحيق": 1,
    "لا": 1,
    "قعر": 1,
    "له": 1,
    "سيئ": 1,
    "الهاويات": 1,
    "جهنم": 1,
    "هوة": 1,
    "جدا": 1,
    "يقاس": 1,
    "عمقه": 1,
    "غوير": 1,
    "اﻷعماق": 1,
    "السحيقة": 1,
    "الحبشة": 1,
    "الحبشي": 1,
    "الأثيوبي": 1,
    "الاكاشا": 1,
    "اللثة": 1,
    "المعهد": 1,
    "بيئة": 1,
    "أكاديمية": 1,
    "الجامعي": 1,
    "البيئة": 1,
    "الأكاديمية": 1,
    "أكاديمي": 1,
    "الشخص": 1,
    "الأكاديمي": 1,
    "أدبي": 1,
    "جامعي": 1,
    "دراسي": 1,
    "مدرسي": 1,
    "اللباس": 1,
    "بالحياة": 1,
    "بالدراسة": 1,
    "عضو": 1,
    "هيئة": 1,
    "دراسية": 1,
    "عضوية": 1,
    "شرفية": 1,
    "الحياة": 1,
    "الأكاديميات": 1,
    "المعاهد": 1,
    "المؤسسات": 1,
    "العلمية": 1,
    "مؤسسة": 1,
    "علمية": 1,
    "معهد": 1,
    "المقاطعات": 1,
    "الفرنسية": 1,
    "كندا": 1,
    "المستوطنون": 1,
    "الفرنسيون": 1,
    "الأوائل": 1,
    "أكاديا": 1,
    "الكاجو": 1,
    "أكاليف": 1,
    "نوع": 1,
    "الجيلي": 1,
    "اللاذع": 1,
    "الأقنثا": 1,
    "شائك": 1,
    "حاد": 1,
    "أكانثي": 1,
    "بـ": 1,
    "يشبه": 1,
    "ثعابين": 1,
    "الإلابيد": 1,
    "الأسترالية": 1,
    "ورم": 1,
    "حميد": 1,
    "الأكانثرس": 1,
    "ميناء": 1,
    "أكابولكو": 1,
    "منتجع": 1,
    "الدود": 1,
    "مبيد": 1,
    "حشرات": 1,
    "أكاريد": 1,
    "القمل": 1,
    "تضم": 1,
    "الديدان": 1,
    "والقمل": 1,
    "الدودة": 1,
    "الرهبة": 1,
    "مثمر": 1,
    "أكارس": 1,
    "الشهي": 1,
    "عديم": 1,
    "الذيل": 1,
    "الساق": 1,
    "أكاد": 1,
    "بلاد": 1,
    "الرافدين": 1,
    "القديمة": 1,
    "التعلق": 1,
    "بالجنس": 1,
    "الذي": 1,
    "عاش": 1,
    "بابل": 1,
    "الغزو": 1,
    "الأشوري": 1,
    "يقبل": 1,
    "بعمل": 1,
    "الآخرين": 1,
    "يوافق": 1,
    "ينصاع": 1,
    "وافق": 1,
    "إنصاع": 1,
    "القبول": 1,
    "الموافقة": 1,
    "الإنصياع": 1,
    "التلبية": 1,
    "المنضم": 1,
    "الملبي": 1,
    "الموافق": 1,
    "يلبي": 1,
    "إيقاع": 1,
    "متزايد": 1,
    "تدريجيا": 1,
    "للموسيقى": 1,
    "سرع": 1,
    "يسرع": 1,
    "يعجل": 1,
    "أسرع": 1,
    "متسارع": 1,
    "مسرع": 1,
    "معجل": 1,
    "الإسراع": 1,
    "العجلة": 1,
    "بعجلة": 1,
    "السرعة": 1,
    "تسريع": 1,
    "إسراع": 1,
    "تعجيل": 1,
    "تسارعي": 1,
    "تعجلي": 1,
    "دواسة": 1,
    "البنزين": 1,
    "المسرع": 1,
    "دواسات": 1,
    "المعجلات": 1,
    "المسرعات": 1,
    "ميال": 1,
    "زيادة": 1,
    "المعجل": 1,
    "التسارع": 1,
    "لقياس": 1,
    "يأجج": 1,
    "به": 1,
    "النار": 1,
    "يتوهج": 1,
    "يهيج": 1,
    "إشعال": 1,
    "التوهج": 1,
    "اشتعال": 1,
    "اللكنة": 1,
    "لهجة": 1,
    "نبرة": 1,
    "شدد": 1,
    "أكد": 1,
    "ركز": 1,
    "الإنتباه": 1,
    "التشديد": 1,
    "التأكيد": 1,
    "تركيز": 1,
    "المغني": 1,
    "مغني": 1,
    "اللكنات": 1,
    "باللكنات": 1,
    "تتناسب": 1,
    "أبرز": 1,
    "ينبر": 1,
    "أظهر": 1,
    "أهمية": 1,
    "يبرز": 1,
    "يظهر": 1,
    "الإبراز": 1,
    "إظهار": 1,
    "تفخيم": 1,
    "اقبل": 1,
    "رضي": 1,
    "يرضى": 1,
    "الرضا": 1,
    "الإستحسان": 1,
    "مقبول": 1,
    "مرض": 1,
    "تقبل": 1,
    "موافقة": 1,
    "متقبل": 1,
    "راغب": 1,
    "الاستحسان": 1,
    "حظوة": 1,
    "قبول": 1,
    "معترف": 1,
    "مستحسن": 1,
    "متفق": 1,
    "متعارف": 1,
    "القابل": 1,
    "المستقبل": 1,
    "القابلون": 1,
    "الموافقون": 1,
    "التحرر": 1,
    "الدين": 1,
    "الإرتباط": 1,
    "الصفح": 1,
    "الإستقبال": 1,
    "الحظوة": 1,
    "موافق": 1,
    "متجه": 1,
    "المستحسن": 1,
    "المستحسنون": 1,
    "المستقبلون": 1,
    "يستلم": 1,
    "يستحسن": 1,
    "المدخل": 1,
    "الوصول": 1,
    "الإقتراب": 1,
    "المساعدون": 1,
    "إرتكاب": 1,
    "الجريمة": 1,
    "وصل": 1,
    "حصل": 1,
    "طريقة": 1,
    "للدخول": 1,
    "المداخل": 1,
    "يصل": 1,
    "القرب": 1,
    "سهولة": 1,
    "المنال": 1,
    "إمكانية": 1,
    "إليه": 1,
    "منفتح": 1,
    "الوصولية": 1,
    "قريب": 1,
    "سهلة": 1,
    "للوصول": 1,
    "اليه": 1,
    "الحصول": 1,
    "مدخل": 1,
    "التبوء": 1,
    "الإضافة": 1,
    "الإعتلاء": 1,
    "الزيادة": 1,
    "إضافي": 1,
    "زائد": 1,
    "فائض": 1,
    "ثانوي": 1,
    "الإكسسوارات": 1,
    "الإضافات": 1,
    "الثانوية": 1,
    "الإكسسوار": 1,
    "ملحق": 1,
    "علم": 1,
    "الصرف": 1,
    "الإعراب": 1,
    "الحادث": 1,
    "إصابة": 1,
    "مصيبة": 1,
    "طارئ": 1,
    "مصادفة": 1,
    "عرضي": 1,
    "جوهري": 1,
    "متعمدة": 1,
    "عرضا": 1,
    "العرضية": 1,
    "المصادفة": 1,
    "جوهرية": 1,
    "بالصدفة": 1,
    "عرضيا": 1,
    "الحوادث": 1,
    "العوارض": 1,
    "الإصابات": 1,
    "المصائب": 1,
    "البلادة": 1,
    "سبات": 1,
    "خامل": 1,
    "كسول": 1,
    "الصقر": 1,
    "الطيور": 1,
    "الجارحة": 1,
    "الصقور": 1,
    "جمع": 1,
    "صقر.": 1,
    "النسور": 1,
    "المتكلف": 1,
    "تصنع": 1,
    "يهلل": 1,
    "تصفيق": 1,
    "هتاف": 1,
    "هلل": 1,
    "صفق": 1,
    "هتف": 1,
    "المهلل": 1,
    "المصفق": 1,
    "الهاتف": 1,
    "التصفيق": 1,
    "الهتاف": 1,
    "التهليل": 1,
    "يصفق": 1,
    "يهتف": 1,
    "الترحيب": 1,
    "التهليلات": 1,
    "الترحيبات": 1,
    "التصفيقات": 1,
    "التكيف": 1,
    "التأقلم": 1,
    "أقلمة": 1,
    "تأقلم": 1,
    "يؤقلم": 1,
    "تكيف": 1,
    "يتأقلم": 1,
    "يتكيف": 1,
    "متأقلم": 1,
    "متكيف": 1,
    "المأقلم": 1,
    "المكيف": 1,
    "طرق": 1,
    "ذات": 1,
    "ميل": 1,
    "الأعلى.": 1,
    "المؤدية": 1,
    "أعلى": 1,
    "الجبل": 1,
    "ارتفاع": 1,
    "صعود": 1,
    "منحدر": 1,
    "متحدر": 1,
    "صعدا": 1,
    "متصعد": 1,
    "يجمع": 1,
    "بعض": 1,
    "التقدير": 1,
    "احتضان": 1,
    "معانقة": 1,
    "التقديرات": 1,
    "الإحتضان": 1,
    "المعانقة": 1,
    "أكوماك": 1,
    "استوعب": 1,
    "آوى": 1,
    "كيف": 1,
    "يكيف": 1,
    "يلائم": 1,
    "لائم": 1,
    "الاستيعاب": 1,
    "الملائمة": 1,
    "لطيف": 1,
    "مجامل": 1,
    "بلطف": 1,
    "بمجاملة": 1,
    "بتكيف": 1,
    "توفيق": 1,
    "ملاءمة": 1,
    "ملائم": 1,
    "مريح": 1,
    "التكييف": 1,
    "الملاءمة": 1,
    "التوافق": 1,
    "صاحب": 1,
    "مصحوب": 1,
    "المرافق": 1,
    "المصاحب": 1,
    "يصاحب": 1,
    "يرافق": 1,
    "الموسيقى": 1,
    "تلازم": 1,
    "مرافقة": 1,
    "مصاحب": 1,
    "المرافقة": 1,
    "العازف": 1,
    "العازفون": 1,
    "المصاحبون": 1,
    "رافق": 1,
    "لازم": 1,
    "يلازم": 1,
    "المصاحبة": 1,
    "الملازمة": 1,
    "عازف": 1,
    "الموسيقي": 1,
    "وعادة": 1,
    "يكون": 1,
    "آلة": 1,
    "البيانو": 1,
    "الشريك": 1,
    "المساهم": 1,
    "تنفيذ": 1,
    "شريرة": 1,
    "الشركاء": 1,
    "المساهمون": 1,
    "أنجز": 1,
    "ينجز": 1,
    "محقق": 1,
    "الممكن": 1,
    "إنجازه": 1,
    "تنفيذه": 1,
    "بارع": 1,
    "متمم": 1,
    "منجز": 1,
    "باتقان": 1,
    "المنجز": 1,
    "المتمم": 1,
    "المنفذ": 1,
    "الإنجاز": 1,
    "الإنجازات": 1,
    "الحساب": 1,
    "الفاتورة": 1,
    "المستحقات": 1,
    "الاتفاق": 1,
    "الموافقات": 1,
    "بإنسجام": 1,
    "توافق": 1,
    "المتوافق": 1,
    "المتوافقون": 1,
    "وفقا": 1,
    "لذلك": 1,
    "الأكورديون": 1,
    "الأكورديونات": 1,
    "إتفاقية": 1,
    "الاتفاقيات": 1,
    "فاتح": 1,
    "المفاتحة": 1,
    "يفاتح": 1,
    "طبيب": 1,
    "نساء": 1,
    "توليد": 1,
    "المرأة": 1,
    "المتمرسة": 1,
    "أمور": 1,
    "أكوند": 1,
    "التقرير": 1,
    "المسؤوليات": 1,
    "المسؤولية": 1,
    "مسئول": 1,
    "للتفسير": 1,
    "المحاسبة": 1,
    "المحاسب": 1,
    "المحاسبون": 1,
    "أكونتيد": 1,
    "أكونتر": 1,
    "التقارير": 1,
    "الإتحاد": 1,
    "العلاقة": 1,
    "يلاطف": 1,
    "يجهز": 1,
    "جهز": 1,
    "التجهيز": 1,
    "التجهيزات": 1,
    "المجهزون": 1,
    "الملابس": 1,
    "تحمل": 1,
    "ولاتلبس": 1,
    "كالمعطف": 1,
    "الإضافي": 1,
    "يخضع": 1,
    "يروض": 1,
    "أكرا": 1,
    "فوض": 1,
    "اعتمد": 1,
    "الاعتماد": 1,
    "معتمد": 1,
    "التفويض": 1,
    "يفوض": 1,
    "المستمر": 1,
    "ملتحم": 1,
    "التراكم": 1,
    "تراكمي": 1,
    "التراكمات": 1,
    "يحصل": 1,
    "عد": 1,
    "عاد": 1,
    "الأموال": 1,
    "المتراكمة": 1,
    "يعود": 1,
    "العيادة": 1,
    "حدوث": 1,
    "مبرمجي": 1,
    "السي": 1,
    "والسي": 1,
    "بلس": 1,
    "الاستلقاء": 1,
    "الكنبة": 1,
    "تثقف": 1,
    "يتثقف": 1,
    "التثقف": 1,
    "التبادل": 1,
    "الثقافي": 1,
    "ثقافي": 1,
    "يستلقي": 1,
    "مستلقي": 1,
    "تراكم": 1,
    "يتراكم": 1,
    "التجميع": 1,
    "متراكم": 1,
    "التراكمية": 1,
    "ذاكرة": 1,
    "الأرقام": 1,
    "ذاكرات": 1,
    "الدقة": 1,
    "دقيق": 1,
    "بدقة": 1,
    "لعين": 1,
    "اللعنة": 1,
    "عرضة": 1,
    "لتلقي": 1,
    "الإتهام": 1,
    "التهمة": 1,
    "الاتهام": 1,
    "الاتهامات": 1,
    "منصوب": 1,
    "النصب": 1,
    "متهم": 1,
    "اتهامي": 1,
    "اتهم": 1,
    "المتهم": 1,
    "المتهمون": 1,
    "يتهم": 1,
    "متهما": 1,
    "إتهامي": 1,
    "يوجه": 1,
    "إتهام": 1,
    "عود": 1,
    "مألوف": 1,
    "التعود": 1,
    "التعويد": 1,
    "الآس": 1,
    "تفوق": 1,
    "الفتور": 1,
    "الروحي": 1,
    "الملل": 1,
    "أسيتناس": 1,
    "التجمعات": 1,
    "الفظيعة": 1,
    "خلوي": 1,
    "مركزي": 1,
    "الرخويات": 1,
    "كالمحار": 1,
    "والقواقع": 1,
    "طائفة": 1,
    "مسيحية": 1,
    "زعيم": 1,
    "رأس": 1,
    "قناة": 1,
    "مائية": 1,
    "للري": 1,
    "إسر": 1,
    "أسيريسا": 1,
    "ومدبب": 1,
    "حمضي": 1,
    "المذاق": 1,
    "ساخط": 1,
    "أثار": 1,
    "الإثارة": 1,
    "لاذع": 1,
    "الحدة": 1,
    "حمض": 1,
    "الأسيريك": 1,
    "فاكهة": 1,
    "الأسيرولا": 1,
    "عنقودي": 1,
    "الآسات": 1,
    "التجويف": 1,
    "الفنجان": 1,
    "عظمة": 1,
    "الفخذ": 1,
    "الأسيتال": 1,
    "أي": 1,
    "مركب": 1,
    "عضوي": 1,
    "تشكل": 1,
    "بإضافة": 1,
    "الكحول": 1,
    "الأسيتالديهيد": 1,
    "سائل": 1,
    "للذوبان": 1,
    "متطاير": 1,
    "اللون": 1,
    "أسيتامينوفين": 1,
    "مسكن": 1,
    "للألم": 1,
    "أسيتانيلايد": 1,
    "يستخدم": 1,
    "كمسكن": 1,
    "أسيتاريوس": 1,
    "السلطات": 1,
    "الآستات": 1,
    "اتحد": 1,
    "بحمض": 1,
    "الخل": 1,
    "خلي": 1,
    "خلل": 1,
    "يخلل": 1,
    "التخليل": 1,
    "بالجليسرين": 1,
    "أسيتول": 1,
    "الأسيتون": 1,
    "كمذاق": 1,
    "المجموعة": 1,
    "المتكاملة": 1,
    "لحمض": 1,
    "مشتق": 1,
    "كولين": 1,
    "أبيض": 1,
    "شفاف": 1,
    "الأسيتيلين": 1,
    "وظيفة": 1,
    "الاتصالات": 1,
    "آتش": 1,
    "إسم": 1,
    "يطلق": 1,
    "العديد": 1,
    "النباتات": 1,
    "لأحد": 1,
    "الأقسام": 1,
    "الأربعة": 1,
    "لليونانيين": 1,
    "القدماء": 1,
    "الهمجية": 1,
    "الوجع": 1,
    "آلم": 1,
    "الفاكهة": 1,
    "الجافة": 1,
    "الصغيرة": 1,
    "بالفاكهة": 1,
    "أكرون": 1,
    "نهر": 1,
    ".": 1,
    "الأساطير": 1,
    "اليونانية": 1,
    "غامق": 1,
    "كئيب": 1,
    "الأوجاع": 1,
    "أتشير": 1,
    "متألم": 1,
    "للإنجاز": 1,
    "حقق": 1,
    "الناجح": 1,
    "الناجحون": 1,
    "يحقق": 1,
    "التحقيق": 1,
    "أتشيل": 1,
    "أتشيلي": 1,
    "منيع": 1,
    "أكيليز": 1,
    "أتشيمينيز": 1,
    "الألم": 1,
    "الآلام": 1,
    "الإيلام": 1,
    "مؤلم": 1,
    "أتشيوت": 1,
    "بذور": 1,
    "الأنوتو": 1,
    "أتشيرا": 1,
    "جذور": 1,
    "صالحة": 1,
    "للأكل": 1,
    "تشوهات": 1,
    "هيكلية": 1,
    "موروثة": 1,
    "أتشراس": 1,
    "أشجار": 1,
    "استوائية": 1,
    "النقص": 1,
    "للون": 1,
    "الجلد": 1,
    "لوني": 1,
    "لونيا": 1,
    "الأكروماتية": 1,
    "لون": 1,
    "أكروميسين": 1,
    "مضاد": 1,
    "حيوي": 1,
    "النجم": 1,
    "يسطع": 1,
    "الليل": 1,
    "ويغيب": 1,
    "النهار": 1,
    "طويل": 1,
    "مدبب": 1,
    "الحمض": 1,
    "مقاوم": 1,
    "للأحماض": 1,
    "أسيداليا": 1,
    "اله": 1,
    "الحب": 1,
    "والجنس.": 1,
    "التحمض": 1,
    "تحول": 1,
    "المحمض": 1,
    "يتحول": 1,
    "الحموضة": 1,
    "الحي": 1,
    "يزدهر": 1,
    "حمضية": 1,
    "الحماض": 1,
    "الأحماض": 1,
    "يحمض": 1,
    "التفوق": 1,
    "أسينوس": 1,
    "أ": 1,
    "سيس": 1,
    "يقر": 1,
    "مدفع": 1,
    "للطائرات": 1,
    "أكي": 1,
    "أكيرمان": 1,
    "أكلي": 1,
    "أقر": 1,
    "مشكور": 1,
    "إقراري": 1,
    "الاعتراف": 1,
    "الاعترافات": 1,
    "معترفون": 1,
    "الإقرار": 1,
    "قائمة": 1,
    "تحكم": 1,
    "الدخول": 1,
    "ميلان": 1,
    "القمة": 1,
    "حب": 1,
    "أكنيد": 1,
    "نقطة": 1,
    "معزولة": 1,
    "جاهز": 1,
    "للإنطلاق": 1,
    "مريض": 1,
    "بالحمى": 1,
    "العلاجات": 1,
    "أكوم": 1,
    "قديم": 1,
    "بي": 1,
    "إم": 1,
    "أكونكاجوا": 1,
    "جبل": 1,
    "الكرة": 1,
    "الغربي": 1,
    "البيش": 1,
    "الأقونيطن": 1,
    "سام": 1,
    "المنشط": 1,
    "الجوزة": 1,
    "الإوزة": 1,
    "امتلأ": 1,
    "بالجوز": 1,
    "الجوز": 1,
    "اللجنة": 1,
    "للدراسات": 1,
    "العثمانية": 1,
    "التصديق": 1,
    "وجود": 1,
    "العالم": 1,
    "الأبدي": 1,
    "التعبير": 1,
    "التهديد": 1,
    "السخط": 1,
    "متوعد": 1,
    "صوتي": 1,
    "سمعيا": 1,
    "مهندس": 1,
    "الصوت": 1,
    "سمعيات": 1,
    "صوتيضوئي": 1,
    "سمعيكهربائي": 1,
    "إفريقيا": 1,
    "الكاريبي": 1,
    "الهادئ": 1,
    "استكشف": 1,
    "المعرفة": 1,
    "المعارف": 1,
    "الاستكشاف": 1,
    "يستكشف": 1,
    "يكتسب": 1,
    "مذعن": 1,
    "بإذعان": 1,
    "يهديء": 1,
    "الامتلاك": 1,
    "احصل": 1,
    "الاكتساب": 1,
    "المحصل": 1,
    "مولع": 1,
    "بالاقتناء": 1,
    "بغريزة": 1,
    "الاقتناء": 1,
    "الكسبية": 1,
    "برئ": 1,
    "البراءة": 1,
    "أحكام": 1,
    "التبرئة": 1,
    "مستندات": 1,
    "تسديد": 1,
    "أككوتر": 1,
    "كلي": 1,
    "جزئي": 1,
    "للجمجمة": 1,
    "المغالاة": 1,
    "الإفراط": 1,
    "الفدان": 1,
    "كمية": 1,
    "الماء": 1,
    "سيغطي": 1,
    "فدان": 1,
    "الأفدنة": 1,
    "الجراد": 1,
    "القسوة": 1,
    "الشديدة": 1,
    "لاذعة": 1,
    "الشدة": 1,
    "بفظاظة": 1,
    "ضفدع": 1,
    "الحيوانات": 1,
    "الدنيا": 1,
    "لاتملك": 1,
    "جهاز": 1,
    "عصبي": 1,
    "حرارة": 1,
    "مقرونة": 1,
    "بحدة": 1,
    "شفهي": 1,
    "البهلوانية": 1,
    "البهلوان": 1,
    "بهلواني": 1,
    "بهلوانية": 1,
    "الألعاب": 1,
    "البهلوانات": 1,
    "بالرأس": 1,
    "الجمجمة": 1,
    "مزهر": 1,
    "بالنبات": 1,
    "أكرولين": 1,
    "تمثال": 1,
    "حجري": 1,
    "تضخم": 1,
    "عضام": 1,
    "اليد": 1,
    "والقدمين": 1,
    "متضخم": 1,
    "عادة": 1,
    "متعلقة": 1,
    "بعظام": 1,
    "الأطراف": 1,
    "بعظمة": 1,
    "الكتف": 1,
    "قمري": 1,
    "مسائي": 1,
    "اختصاري": 1,
    "قمي": 1,
    "التعاقب": 1,
    "خوف": 1,
    "المرتفعات": 1,
    "رهبة": 1,
    "الأكروبولس": 1,
    "متوسع": 1,
    "كلمات": 1,
    "متساوية": 1,
    "الطول": 1,
    "مرتبة": 1,
    "بحيث": 1,
    "تكون": 1,
    "قراءتها": 1,
    "عموديا": 1,
    "مطابقة": 1,
    "لقرائتها": 1,
    "أفقيا": 1,
    "شكل": 1,
    "ركيزة": 1,
    "توضع": 1,
    "بأعلى": 1,
    "وأسفل": 1,
    "الأعمدة": 1,
    "النبض": 1,
    "ضعفه": 1,
    "أكريليت": 1,
    "أكريلي": 1,
    "أكريلونتريل": 1,
    "(نيتريل": 1,
    "مشبع)": 1,
    "عنصر": 1,
    "خدمة": 1,
    "التحكم": 1,
    "بالترابط": 1,
    "أكتا": 1,
    "التمثيلية": 1,
    "تمثيل": 1,
    "أكتين": 1,
    "عمل": 1,
    "هرمون": 1,
    "Adrenocorticotropic": 1,
    "(محفز": 1,
    "للغدة": 1,
    "الكظرية)": 1,
    "شعاع": 1,
    "أشعة": 1,
    "شعاعي": 1,
    "شقار": 1,
    "شقائق": 1,
    "النعمان": 1,
    "وأشباهها": 1,
    "أكتيني": 1,
    "أكتينايد": 1,
    "الأكتينيديا": 1,
    "(كرمة": 1,
    "آسيوية": 1,
    "خشبية": 1,
    "صغيرة)": 1,
    "الأكتينية": 1,
    "(قدرة": 1,
    "الطاقة": 1,
    "المشعة": 1,
    "إحداث": 1,
    "التغييرات": 1,
    "الكيميائية)": 1,
    "الأكتينيوم": 1,
    "أكتينولايت": 1,
    "الشمس": 1,
    "مقاييس": 1,
    "الحارش": 1,
    "(طفيلي": 1,
    "بكتيري)": 1,
    "بكتيريا": 1,
    "خييطية": 1,
    "الخييطية": 1,
    "أكتينوميسين": 1,
    "(مضاد": 1,
    "حيوي)": 1,
    "العلاج": 1,
    "بالإشعاع": 1,
    "موجب": 1,
    "لإقامة": 1,
    "دعوى": 1,
    "قضائية": 1,
    "الأفعال": 1,
    "مشع": 1,
    "معركة": 1,
    "أكتيوم": 1,
    "البحرية": 1,
    "للنتشيط": 1,
    "نشط": 1,
    "ينشط": 1,
    "التنشيط": 1,
    "المنشطات": 1,
    "الصيغة": 1,
    "المعلوم": 1,
    "بنشاط": 1,
    "الفعالية": 1,
    "أكتف": 1,
    "إكس": 1,
    "الناشط": 1,
    "ناشط": 1,
    "فعالي": 1,
    "الناشطون": 1,
    "الأنشطة": 1,
    "ميوسين": 1,
    "أكتون": 1,
    "الممثل": 1,
    "الممثلون": 1,
    "الممثلة": 1,
    "الممثلات": 1,
    "الأعمال": 1,
    "فعلي": 1,
    "تحقيقي": 1,
    "الحقائق": 1,
    "الإدراك": 1,
    "التحقيقات": 1,
    "الحقيقيات": 1,
    "بحسابات": 1,
    "التأمين": 1,
    "ناحية": 1,
    "الحسابات": 1,
    "التأمينية": 1,
    "خبراء": 1,
    "الخبير": 1,
    "بشؤون": 1,
    "شغل": 1,
    "يشغل": 1,
    "التشغيل": 1,
    "التنفيذ": 1,
    "المحرك": 1,
    "المحركات": 1,
    "موقف": 1,
    "أكتس": 1,
    "(مقياس": 1,
    "روماني)": 1,
    "يجعله": 1,
    "يشحذ": 1,
    "الشحذ": 1,
    "السن": 1,
    "البراعة": 1,
    "أكيولار": 1,
    "(الاسم": 1,
    "التجاري": 1,
    "لدواء": 1,
    "مثبط)": 1,
    "شوائك": 1,
    "لوادغ": 1,
    "شوكي": 1,
    "لادغ": 1,
    "رؤوس": 1,
    "مدببة": 1,
    "(نحل": 1,
    "مثلا)": 1,
    "(رأس": 1,
    "النحلة": 1,
    "الفطنة": 1,
    "مستدق": 1,
    "الطرف": 1,
    "(كورقة": 1,
    "النبات)": 1,
    "الحاد": 1,
    "بالإبر": 1,
    "علاج": 1,
    "الوخز": 1,
    "بالأبر": 1,
    "أكيوشنيت": 1,
    "قاسي": 1,
    "الزاوية": 1,
    "أشد": 1,
    "الأشد": 1,
    "أسيكليك": 1,
    "أسيكليكالي": 1,
    "أسيكلوفر": 1,
    "للفيروس)": 1,
    "أسيل": 1,
    "الإعلان": 1,
    "خاص": 1,
    "مقارب": 1,
    "شبيه": 1,
    "عفوي": 1,
    "ارتجالي": 1,
    "أدا": 1,
    "تفرع": 1,
    "أداباس": 1,
    "(قاعدة": 1,
    "بيانات": 1,
    "علائقية)": 1,
    "يقود": 1,
    "يجبر": 1,
    "أداد": 1,
    "(إله": 1,
    "الطقس": 1,
    "البابليين)": 1,
    "المثل": 1,
    "الأمثال": 1,
    "المقطوعة": 1,
    "الموسيقية": 1,
    "المقطوعات": 1,
    "أداه": 1,
    "أدير": 1,
    "الخلايا": 1,
    "العصبية": 1,
    "الطولية": 1,
    "التكيفية": 1,
    "آدم": 1,
    "مرن": 1,
    "التعديل": 1,
    "عنيد": 1,
    "أدمنتي": 1,
    "صلب": 1,
    "بعناد": 1,
    "آدمي": 1,
    "بشري": 1,
    "رجل": 1,
    "سلالة": 1,
    "أدامو": 1,
    "(نظام": 1,
    "إدارة": 1,
    "بيانات)": 1,
    "أبناء": 1,
    "آدامسون": 1,
    "يرقص": 1,
    "البابلية": 1,
    "بابلي": 1,
    "مترف": 1,
    "تحويلي": 1,
    "التحويلات": 1,
    "تكيفي": 1,
    "المحول": 1,
    "المحولات": 1,
    "التكيفات": 1,
    "آذار": 1,
    "مارس": 1,
    "قماش": 1,
    "قطني": 1,
    "هندي": 1,
    "يفزع": 1,
    "يوقظ": 1,
    "مجاور": 1,
    "محور": 1,
    "أداي": 1,
    "الموجات": 1,
    "الأحادية": 1,
    "بروتوكول": 1,
    "بالاتصالات": 1,
    "البيانية": 1,
    "أضف": 1,
    "الملحق": 1,
    "صفحة": 1,
    "جديدة": 1,
    "اضافةضبط": 1,
    "المهاة": 1,
    "(بقرة": 1,
    "وحش": 1,
    "كبيرة)": 1,
    "مستودع": 1,
    "التطويرية": 1,
    "المضاف": 1,
    "الأفعى": 1,
    "الأفاعي": 1,
    "مدير": 1,
    "المنطقة": 1,
    "للتحقيقات": 1,
    "الجمع": 1,
    "المدمن": 1,
    "جعل": 1,
    "مدمنا": 1,
    "الإدمان": 1,
    "التكرس": 1,
    "مسبب": 1,
    "بادمان": 1,
    "المدمنون": 1,
    "إديسون": 1,
    "مضاف": 1,
    "مجموع": 1,
    "بالإضافة": 1,
    "شوش": 1,
    "شخص": 1,
    "غبي": 1,
    "ومرتبك": 1,
    "يشوش": 1,
    "التشويش": 1,
    "موضوع": 1,
    "لظهر": 1,
    "(كالشعارات)": 1,
    "العنوان": 1,
    "العنونة": 1,
    "للتخاطب": 1,
    "دفتر": 1,
    "العناوين": 1,
    "خاطب": 1,
    "مرسل": 1,
    "مرسلون": 1,
    "المعنون": 1,
    "المعنونون": 1,
    "المخاطبة": 1,
    "عنوان": 1,
    "ماكنة": 1,
    "قدم": 1,
    "مقرب": 1,
    "أدسير": 1,
    "يقدم": 1,
    "تقريبه": 1,
    "التقديم": 1,
    "التقريب": 1,
    "أدكتايف": 1,
    "المقرب": 1,
    "يقرب": 1,
    "مشروب": 1,
    "فواكه": 1,
    "محلى": 1,
    "مخفف": 1,
    "نبيل": 1,
    "أصل": 1,
    "شهير": 1,
    "أديليد": 1,
    "أديل": 1,
    "أديليا": 1,
    "بطاريق": 1,
    "متوسطة": 1,
    "الحجم": 1,
    "أديلين": 1,
    "أدل": 1,
    "أدلفي": 1,
    "أخوة": 1,
    "عدن": 1,
    "غدة": 1,
    "أدينا": 1,
    "أديناين": 1,
    "إلتهاب": 1,
    "أدينوكارسينوما": 1,
    "(مرض": 1,
    "خبيث": 1,
    "ينشأ": 1,
    "نسيج": 1,
    "الغدة)": 1,
    "ليمفاوي": 1,
    "غددي": 1,
    "اللحمية": 1,
    "الورم": 1,
    "الحميد": 1,
    "ورميغدي": 1,
    "بورم": 1,
    "غدي": 1,
    "الغدة": 1,
    "اللمفاوية": 1,
    "يحتوي": 1,
    "غدد": 1,
    "بالغدد": 1,
    "الغدد": 1,
    "الأدينوساين": 1,
    "دهن": 1,
    "حيواني": 1,
    "شحم": 1,
    "المهارة": 1,
    "الخبراء": 1,
    "الكفاءات": 1,
    "الكفاءة": 1,
    "كافي": 1,
    "الكفاية": 1,
    "متأثر": 1,
    "دمج": 1,
    "انضم": 1,
    "انتسب": 1,
    "الهرمون": 1,
    "المضاد": 1,
    "لإدرار": 1,
    "البول": 1,
    "متمسك": 1,
    "التصق": 1,
    "تماسك": 1,
    "يخلص": 1,
    "الولاء": 1,
    "أخلص": 1,
    "بالولاء": 1,
    "الإلتصاق": 1,
    "الإخلاص": 1,
    "تابع": 1,
    "نصير": 1,
    "ملتحق": 1,
    "ملتصق": 1,
    "مؤيدة": 1,
    "شيعة": 1,
    "أتباع": 1,
    "النصير": 1,
    "التابع": 1,
    "يلتصق": 1,
    "يناصر": 1,
    "يؤيد": 1,
    "التصاق": 1,
    "التأييد": 1,
    "النصرة": 1,
    "التحام": 1,
    "تلاصق": 1,
    "موالاة": 1,
    "الصمغ": 1,
    "دبق": 1,
    "لاصق": 1,
    "لزج": 1,
    "لزوجة": 1,
    "اللاصقة": 1,
    "اللزجة": 1,
    "عكس": 1,
    "البيروقراطية": 1,
    "هرمية": 1,
    "وظيفية": 1,
    "الكل": 1,
    "بمقدوره": 1,
    "إتخاذ": 1,
    "قرار": 1,
    "حث": 1,
    "انصح": 1,
    "يحذر": 1,
    "يحض": 1,
    "الحرارة": 1,
    "متغيرة": 1,
    "معها": 1,
    "يخص": 1,
    "المسائل": 1,
    "بالإيمان": 1,
    "والعرف": 1,
    "اللا": 1,
    "مبالاة": 1,
    "خمول": 1,
    "تحيز": 1,
    "الوداع": 1,
    "وداعا": 1,
    "السلامة": 1,
    "أديجي": 1,
    "الإيطالي": 1,
    "نظم": 1,
    "صف": 1,
    "رتب": 1,
    "أدينو": 1,
    "وداع": 1,
    "سمني": 1,
    "دهني": 1,
    "الدهون": 1,
    "الشمعية": 1,
    "الناتجة": 1,
    "إختلاط": 1,
    "الشحم": 1,
    "عضلات": 1,
    "الميت": 1,
    "كيس": 1,
    "دسم": 1,
    "الحيوان": 1,
    "السمنة": 1,
    "الدهنية": 1,
    "أدرندك": 1,
    "السياحية": 1,
    "المنجم": 1,
    "الصفة": 1,
    "تجاور": 1,
    "تماس": 1,
    "التجاور": 1,
    "التماس": 1,
    "المحاذاة": 1,
    "محاذي": 1,
    "متاخم": 1,
    "يلحق": 1,
    "يظم": 1,
    "وصفي": 1,
    "نعتي": 1,
    "كصفة": 1,
    "كنعت": 1,
    "النعت": 1,
    "الصفات": 1,
    "النعوت": 1,
    "جاور": 1,
    "حاذى": 1,
    "يجاور": 1,
    "يحاذي": 1,
    "يضم": 1,
    "ضم": 1,
    "الجوار": 1,
    "الضم": 1,
    "انفض": 1,
    "أرجأ": 1,
    "يؤجل": 1,
    "يرجئ": 1,
    "أجل": 1,
    "الانفضاض": 1,
    "التأجيل": 1,
    "الإرجاء": 1,
    "إرجاء": 1,
    "تأجيل": 1,
    "تأخير": 1,
    "الإنهاء": 1,
    "ينفض": 1,
    "ينهي": 1,
    "يحكم": 1,
    "بالقضاء": 1,
    "ينطق": 1,
    "نطق": 1,
    "القضية": 1,
    "الحكم": 1,
    "التحكيم": 1,
    "محاكمة": 1,
    "المحاكمة": 1,
    "قضائي": 1,
    "المحكم": 1,
    "يساعد": 1,
    "الملاحق": 1,
    "المساعدات": 1,
    "استحلف": 1,
    "يستحلف": 1,
    "يناشد": 1,
    "ناشد": 1,
    "الاستحلاف": 1,
    "المناشدة": 1,
    "ضبط": 1,
    "عدل": 1,
    "ينظم": 1,
    "التضبيط": 1,
    "للضبط": 1,
    "للتعديل": 1,
    "للتكيف": 1,
    "الضابط": 1,
    "المعدل": 1,
    "الضباط": 1,
    "المعدلون": 1,
    "المكيفون": 1,
    "تعديلي": 1,
    "ضبطي": 1,
    "التعديلات": 1,
    "التضبيطات": 1,
    "المضبط": 1,
    "مكيفون": 1,
    "يعدل": 1,
    "يضبط": 1,
    "الإداري": 1,
    "الإداريون": 1,
    "أدكينز": 1,
    "أدلر": 1,
    "وكيل": 1,
    "الإعلانات": 1,
    "المعلن": 1,
    "أدر": 1,
    "يدبر": 1,
    "يدير": 1,
    "يعاقب": 1,
    "أدار": 1,
    "الإدارة": 1,
    "الإدارات": 1,
    "للإدارة": 1,
    "تول": 1,
    "تولى": 1,
    "يتولى": 1,
    "التولي": 1,
    "إداري": 1,
    "إداريا": 1,
    "المدير": 1,
    "المديرون": 1,
    "المديرة": 1,
    "أدمينت": 1,
    "جدير": 1,
    "بالإعجاب": 1,
    "الجدارة": 1,
    "قائد": 1,
    "أمير": 1,
    "أميرال": 1,
    "أميرة": 1,
    "القادة": 1,
    "البحريات": 1,
    "قوات": 1,
    "الإعجاب": 1,
    "أعجبت": 1,
    "أعجب": 1,
    "المعجب": 1,
    "المعجبون": 1,
    "معجب": 1,
    "بإعجاب": 1,
    "مصدق": 1,
    "مصداقية": 1,
    "اعترف": 1,
    "يعترف": 1,
    "باعتراف": 1,
    "الجميع": 1,
    "اخلط": 1,
    "خلط": 1,
    "يخلط": 1,
    "الخلط": 1,
    "عاتب": 1,
    "الناصح": 1,
    "يعاتب": 1,
    "المعاتبة": 1,
    "عتابي": 1,
    "اللوم": 1,
    "العتاب": 1,
    "أدو": 1,
    "اللبنة": 1,
    "المراهقة": 1,
    "المراهق": 1,
    "مراهق": 1,
    "المراهقون": 1,
    "أدولف": 1,
    "أدولفس": 1,
    "أدونيس": 1,
    "تبن": 1,
    "قدرات": 1,
    "التبني": 1,
    "إقامة": 1,
    "المتبني": 1,
    "المتبنون": 1,
    "بالتبني": 1,
    "يتبنى": 1,
    "الجاذبية": 1,
    "جذاب": 1,
    "العشق": 1,
    "اعشق": 1,
    "عشق": 1,
    "أدورر": 1,
    "يعشق": 1,
    "زين": 1,
    "أدورنر": 1,
    "التزيين": 1,
    "الزينة": 1,
    "تزيين": 1,
    "زخرفة": 1,
    "حلي": 1,
    "يزين": 1,
    "الكظرية": 1,
    "أدرينالاين": 1,
    "كظريا": 1,
    "أدرايان": 1,
    "أدرياتيكي": 1,
    "أدرين": 1,
    "هدي": 1,
    "الدهاء": 1,
    "كثف": 1,
    "مكثف": 1,
    "التكثيف": 1,
    "يكثف": 1,
    "الادمصاص": 1,
    "ادمصاصي": 1,
    "بادمصاصية": 1,
    "تملق": 1,
    "يتملق": 1,
    "التملق": 1,
    "المتملق": 1,
    "متملق": 1,
    "البالغ": 1,
    "الغاشة": 1,
    "غش": 1,
    "يغش": 1,
    "الغش": 1,
    "الغشاش": 1,
    "الغشاشون": 1,
    "الزاني": 1,
    "الزناة": 1,
    "الزانية": 1,
    "الزنا": 1,
    "فاسق": 1,
    "بالزنا": 1,
    "البلوغ": 1,
    "بالغ": 1,
    "سن": 1,
    "الرشد": 1,
    "البالغون": 1,
    "أشر": 1,
    "أشار": 1,
    "يشير": 1,
    "الإشارة": 1,
    "إشاري": 1,
    "بإشارة": 1,
    "التقدم": 1,
    "تقدم": 1,
    "المتقدمون": 1,
    "الميزة": 1,
    "مفاد": 1,
    "مفيد": 1,
    "الفائدة": 1,
    "الميزات": 1,
    "الإفادة": 1,
    "إفادي": 1,
    "الحلول": 1,
    "السبتي": 1,
    "مجيئي": 1,
    "بلدي": 1,
    "المغامرة": 1,
    "غامر": 1,
    "المغامر": 1,
    "المغامرون": 1,
    "المغامرات": 1,
    "مغامر": 1,
    "مقدام": 1,
    "بمخاطرة": 1,
    "الظرف": 1,
    "كظرف": 1,
    "الظروف": 1,
    "الأعداء": 1,
    "معادي": 1,
    "عادى": 1,
    "معاد": 1,
    "الخصومة": 1,
    "يخاصم": 1,
    "مخاصمة": 1,
    "المشكلات": 1,
    "المحنة": 1,
    "مشار": 1,
    "الإهتمام": 1,
    "إهتمامات": 1,
    "منتبه": 1,
    "بيقظة": 1,
    "أعلن": 1,
    "المعلنون": 1,
    "يعلن": 1,
    "إعلان": 1,
    "النصيحة": 1,
    "الصحة": 1,
    "باستحسان": 1,
    "نصح": 1,
    "بروية": 1,
    "المنصوح": 1,
    "المنصوحون": 1,
    "التفكير": 1,
    "المستشار": 1,
    "المستشارون": 1,
    "ينصح": 1,
    "النصح": 1,
    "الإنذارات": 1,
    "استشاري": 1,
    "دافع": 1,
    "تأييدي": 1,
    "المحامي": 1,
    "دفاعي": 1,
    "القدوم": 1,
    "إيجي": 1,
    "إيجيس": 1,
    "إينيس": 1,
    "إينييد": 1,
    "إيولايان": 1,
    "عولس": 1,
    "الدهر": 1,
    "إيرا": 1,
    "أشبع": 1,
    "بالغاز": 1,
    "يشبع": 1,
    "إشباع": 1,
    "للإشباع": 1,
    "بالهواء": 1,
    "إيرجر": 1,
    "الهوائي": 1,
    "هوائيا": 1,
    "الهوائيات": 1,
    "طيراني": 1,
    "سمعي": 1,
    "هوائي": 1,
    "جرثوم": 1,
    "أكسجيني": 1,
    "التمارين": 1,
    "الرياضية": 1,
    "المطار": 1,
    "ديناميكي": 1,
    "ديناميكية": 1,
    "هوائية": 1,
    "ديناميكا": 1,
    "الهواء": 1,
    "مسببة": 1,
    "للغاز": 1,
    "الرسالة": 1,
    "الجوية": 1,
    "ميزان": 1,
    "الطيار": 1,
    "طيران": 1,
    "بالطيران": 1,
    "الطيران": 1,
    "الطيارون": 1,
    "الطائرة": 1,
    "الطائرات": 1,
    "البخاخة": 1,
    "انشر": 1,
    "نشر": 1,
    "البخاخات": 1,
    "صناعة": 1,
    "الفضاء": 1,
    "إسكلس": 1,
    "الذواقة": 1,
    "جمالي": 1,
    "جماليا": 1,
    "الجمال": 1,
    "الود": 1,
    "اجتماعي": 1,
    "اللطافة": 1,
    "بعذوبة": 1,
    "الشئون": 1,
    "أثر": 1,
    "التكلف": 1,
    "متكلف": 1,
    "بتكلف": 1,
    "التأثر": 1,
    "المؤثر": 1,
    "التأثير": 1,
    "علي": 1,
    "مؤثر": 1,
    "المحبة": 1,
    "حنون": 1,
    "بمودة": 1,
    "الحنان": 1,
    "عاطفي": 1,
    "يؤثر": 1,
    "ناقل": 1,
    "اخطب": 1,
    "خطب": 1,
    "يخطب": 1,
    "الخطبة": 1,
    "الشهادة": 1,
    "الموثقة": 1,
    "إقرار": 1,
    "وتعهد": 1,
    "مكتوب": 1,
    "بأيمان": 1,
    "غليضة": 1,
    "(أداء": 1,
    "اليمين)": 1,
    "الشهادات": 1,
    "الفرع": 1,
    "الفروع": 1,
    "الدمج": 1,
    "الانتماء": 1,
    "الانتماءات": 1,
    "القريب": 1,
    "التشابهات": 1,
    "الانجذاب": 1,
    "تعهد": 1,
    "التأكيدات": 1,
    "مثبت": 1,
    "الإيجاب": 1,
    "إيجابي": 1,
    "التعهد": 1,
    "يتعهد": 1,
    "اللاحقة": 1,
    "ألصق": 1,
    "اللاحقات": 1,
    "الإلصاق": 1,
    "الإلهام": 1,
    "ابتلي": 1,
    "ابتلى": 1,
    "الابتلاء": 1,
    "مأساوي": 1,
    "يبتلي": 1,
    "الغنى": 1,
    "غني": 1,
    "متاح": 1,
    "التحمل": 1,
    "شجر": 1,
    "التشجير": 1,
    "الشجار": 1,
    "أجر": 1,
    "التأجير": 1,
    "الساكن": 1,
    "الأصوات": 1,
    "الساكنة": 1,
    "تسكين": 1,
    "الفزع": 1,
    "الأفغاني": 1,
    "الأفغان": 1,
    "أفغانستان": 1,
    "الهاو": 1,
    "الهواة": 1,
    "الميدان": 1,
    "مشتعلا": 1,
    "طافيا": 1,
    "وساق": 1,
    "فيما": 1,
    "مضى": 1,
    "سالف": 1,
    "المذكورون": 1,
    "مدبر": 1,
    "خائف": 1,
    "جديد": 1,
    "الإفريقي": 1,
    "الأفارقة": 1,
    "أفريقيا": 1,
    "الأفرو": 1,
    "الحبل": 1,
    "السري": 1,
    "غرفة": 1,
    "احتراق": 1,
    "مساعدة": 1,
    "النصف": 1,
    "الأخير": 1,
    "السفية": 1,
    "رجعي": 1,
    "آثار": 1,
    "رجعية": 1,
    "الذكرى": 1,
    "الذكريات": 1,
    "مسخن": 1,
    "الصور": 1,
    "الآخرة": 1,
    "النتيجة": 1,
    "الأثر": 1,
    "أخير": 1,
    "الظهر": 1,
    "الحلوى": 1,
    "الكولونيا": 1,
    "التوابع": 1,
    "الطعم": 1,
    "ذلك": 1,
    "الختامية": 1,
    "ثانية": 1,
    "ضد": 1,
    "أجاميمنون": 1,
    "مندهشا": 1,
    "هلامية": 1,
    "طحلبية": 1,
    "مشروم": 1,
    "المثخنة": 1,
    "العقيق": 1,
    "أجاثا": 1,
    "الأغاف": 1,
    "العمر": 1,
    "كبر": 1,
    "بما": 1,
    "الكبار": 1,
    "الشيخوخة": 1,
    "أجي": 1,
    "الجيل": 1,
    "الكبر": 1,
    "الوكالات": 1,
    "الوكالة": 1,
    "جدول": 1,
    "جداول": 1,
    "الوكيل": 1,
    "وكالي": 1,
    "الوكلاء": 1,
    "معمر": 1,
    "معمرون": 1,
    "الأعمار": 1,
    "زراعية": 1,
    "الكتلة": 1,
    "تجمع": 1,
    "الكتل": 1,
    "التجمع": 1,
    "مائل": 1,
    "للتجميع": 1,
    "التصقت": 1,
    "اشتقاقي": 1,
    "الملزن": 1,
    "التفخيم": 1,
    "يكبر": 1,
    "التكبير": 1,
    "صعد": 1,
    "يصعد": 1,
    "التصعيد": 1,
    "متفاقم": 1,
    "المجموع": 1,
    "جماعي": 1,
    "التجمعية": 1,
    "المجاميع": 1,
    "إجمالي": 1,
    "الإعتداء": 1,
    "العنف": 1,
    "عدواني": 1,
    "بعدوانية": 1,
    "العدوانية": 1,
    "المعتد": 1,
    "المعتدون": 1,
    "حزن": 1,
    "مهموم": 1,
    "بظلم": 1,
    "يحزن": 1,
    "الإحزان": 1,
    "رشيق": 1,
    "برشاقة": 1,
    "الرشاقة": 1,
    "العمولة": 1,
    "الصرافة": 1,
    "طالب": 1,
    "بارتعاش": 1,
    "يطالب": 1,
    "المطالبة": 1,
    "الانفعال": 1,
    "الثورات": 1,
    "أجيتاتيف": 1,
    "المشاغب": 1,
    "المشاغبون": 1,
    "مضيء": 1,
    "متوهج": 1,
    "أجنيس": 1,
    "أجنو": 1,
    "مستعار": 1,
    "الملحد": 1,
    "الإيمان": 1,
    "الشك": 1,
    "بوجود": 1,
    "الملحدون": 1,
    "منذ": 1,
    "متلهف": 1,
    "رحل": 1,
    "كروب": 1,
    "يناضل": 1,
    "ناضل": 1,
    "مناضل": 1,
    "مناضلون": 1,
    "مناضلة": 1,
    "بنضال": 1,
    "الكرب": 1,
    "الأماكن": 1,
    "المكشوفة": 1,
    "الأغوطي": 1,
    "اتفق": 1,
    "مناسب": 1,
    "الاتفاقية": 1,
    "موافقون": 1,
    "يتفق": 1,
    "أجري": 1,
    "تجارة": 1,
    "المحاصيل": 1,
    "تجارات": 1,
    "أجريكولا": 1,
    "المهندسون": 1,
    "الزراعيون": 1,
    "بالزراعة": 1,
    "الزراعة": 1,
    "المزارع": 1,
    "الغافث": 1,
    "ملامس": 1,
    "القاع": 1,
    "الحمى": 1,
    "أجواي": 1,
    "آه": 1,
    "المقدمة": 1,
    "عهد": 1,
    "نحنحة": 1,
    "أحمدأباد": 1,
    "أحمد": 1,
    "آباد": 1,
    "صيحة": 1,
    "البحارة": 1,
    "المعونة": 1,
    "عايدة": 1,
    "ساعد": 1,
    "أيدر": 1,
    "المساعدة": 1,
    "أيكين": 1,
    "امرض": 1,
    "الإيلنطس": 1,
    "أيل": 1,
    "أيلين": 1,
    "المؤخرة": 1,
    "المؤخرات": 1,
    "المرض": 1,
    "الأمراض": 1,
    "يمرض": 1,
    "الهدف": 1,
    "وجه": 1,
    "الهداف": 1,
    "الهدافون": 1,
    "التوجيه": 1,
    "هدف": 1,
    "عشوائيا": 1,
    "التشتت": 1,
    "الأهداف": 1,
    "فرد": 1,
    "الأينو": 1,
    "جماعة": 1,
    "النازحين": 1,
    "الأرخبيل": 1,
    "الياباني": 1,
    "أفراد": 1,
    "البريد": 1,
    "الجوي": 1,
    "المضخة": 1,
    "بدوار": 1,
    "الجو": 1,
    "المخدة": 1,
    "الهوائية": 1,
    "أكياس": 1,
    "صاعد": 1,
    "رش": 1,
    "أسقط": 1,
    "جويا": 1,
    "يسقط": 1,
    "تعرض": 1,
    "للهواء": 1,
    "كلب": 1,
    "الصيد": 1,
    "جوي": 1,
    "هيكل": 1,
    "لتجفيف": 1,
    "تهوئة": 1,
    "جويون": 1,
    "هياكل": 1,
    "الثمن": 1,
    "المطارات": 1,
    "جريان": 1,
    "حامل": 1,
    "حملة": 1,
    "الغبي": 1,
    "متجدد": 1,
    "المتجدد": 1,
    "بعبث": 1,
    "التهوية": 1,
    "النزهة": 1,
    "النزهات": 1,
    "خال": 1,
    "الخواء": 1,
    "نقل": 1,
    "الجسرات": 1,
    "شركات": 1,
    "الضغط": 1,
    "غرف": 1,
    "المضغوط": 1,
    "مطار": 1,
    "مدني": 1,
    "البث": 1,
    "المظاهر": 1,
    "المنطاد": 1,
    "المناطيد": 1,
    "دوار": 1,
    "دوارات": 1,
    "المجال": 1,
    "السرعات": 1,
    "المهبط": 1,
    "المهابط": 1,
    "هاتف": 1,
    "محكم": 1,
    "السد": 1,
    "إحكام": 1,
    "الشد": 1,
    "الحنجرة": 1,
    "ممر": 1,
    "الحناجر": 1,
    "ممرات": 1,
    "خطوط": 1,
    "جوية": 1,
    "الصلاحية": 1,
    "صالح": 1,
    "للطيران": 1,
    "الممر": 1,
    "الممرات": 1,
    "أيتكين": 1,
    "جزئيا": 1,
    "إجاكس": 1,
    "أك": 1,
    "أكيرز": 1,
    "أكيمبو": 1,
    "مشابه": 1,
    "علاء": 1,
    "المرمر": 1,
    "بلهفة": 1,
    "اللهفة": 1,
    "ألاي": 1,
    "ألاميدا": 1,
    "ألامو": 1,
    "ألاموس": 1,
    "آلان": 1,
    "المنبه": 1,
    "مذعور": 1,
    "مفزع": 1,
    "مقلق": 1,
    "التنبيه": 1,
    "القلق": 1,
    "إنذارات": 1,
    "الخطر": 1,
    "للأسف": 1,
    "الرداء": 1,
    "ألبا": 1,
    "البكورة": 1,
    "ألبانيا": 1,
    "الألباني": 1,
    "الألبانيون": 1,
    "ألباني": 1,
    "البطروس": 1,
    "ولو": 1,
    "ألبيريتش": 1,
    "ألبرت": 1,
    "ألبيرتا": 1,
    "ألبيرتو": 1,
    "ألبريتشت": 1,
    "أولبرايت": 1,
    "الألبوم": 1,
    "البياض": 1,
    "الزلال": 1,
    "زلالي": 1,
    "الألبومات": 1,
    "ألبوكيركي": 1,
    "ألسيستيس": 1,
    "الكيميائيون": 1,
    "الكيمياء": 1,
    "ألسيبيادز": 1,
    "جنرال": 1,
    "سياسي": 1,
    "أثينا": 1,
    "ألكمينا": 1,
    "ألكو": 1,
    "للكحول": 1,
    "إدمان": 1,
    "الخمور": 1,
    "ألكوت": 1,
    "تجويف": 1,
    "قبة": 1,
    "مجوف": 1,
    "مقبب": 1,
    "التجاويف": 1,
    "قبب": 1,
    "الدبران": 1,
    "الألديهيد": 1,
    "ألدين": 1,
    "الألدر": 1,
    "مجلس": 1,
    "محلي": 1,
    "أعضاء": 1,
    "ألدريتش": 1,
    "ألدرين": 1,
    "البيرة": 1,
    "حظي": 1,
    "أليك": 1,
    "الاستعراضي": 1,
    "أليكس": 1,
    "سفينة": 1,
    "البار": 1,
    "أليف": 1,
    "ألر": 1,
    "الإنذار": 1,
    "حذر": 1,
    "متيقظ": 1,
    "متيقظون": 1,
    "التحذير": 1,
    "بانتباه": 1,
    "اليقظة": 1,
    "أليت": 1,
    "الألويف": 1,
    "أليكساندر": 1,
    "أليكسانديرز": 1,
    "ألكساندرا": 1,
    "الإسكندرية": 1,
    "أليكسيي": 1,
    "ألكسي": 1,
    "ألفا": 1,
    "الفلفا": 1,
    "ألفونسو": 1,
    "ألفريد": 1,
    "ألفريدو": 1,
    "الطلق": 1,
    "الطحلب": 1,
    "ألجايسيد": 1,
    "ألجال": 1,
    "الطحالب": 1,
    "الجبر": 1,
    "جبري": 1,
    "جبريا": 1,
    "ألجنب": 1,
    "نجم": 1,
    "ألجير": 1,
    "الجزائر": 1,
    "الجزائري": 1,
    "ملح": 1,
    "ألجيني": 1,
    "أملاح": 1,
    "ألجينية": 1,
    "الغول": 1,
    "ألجونكون": 1,
    "ألجوريزم": 1,
    "القاعدة": 1,
    "حسابي": 1,
    "حسابيا": 1,
    "القواعد": 1,
    "الحمرا": 1,
    "علياء": 1,
    "الاسم": 1,
    "المستعار": 1,
    "مسمى": 1,
    "استعارة": 1,
    "الأسماء": 1,
    "المستعارة": 1,
    "التعرج": 1,
    "دليل": 1,
    "أدلة": 1,
    "أليس": 1,
    "أليسيا": 1,
    "الأجنبي": 1,
    "القابلية": 1,
    "للتحول": 1,
    "للتحويل": 1,
    "تحوليا": 1,
    "مغترب": 1,
    "الاغتراب": 1,
    "محول": 1,
    "الطبيب": 1,
    "النفساني": 1,
    "الأجانب": 1,
    "حط": 1,
    "الحط": 1,
    "ظبطتوزيع": 1,
    "أليجنر": 1,
    "الاصطفاف": 1,
    "الترتيب": 1,
    "الأحلاف": 1,
    "يصطف": 1,
    "حد": 1,
    "سواء": 1,
    "التماثلية": 1,
    "المغذي": 1,
    "غذائي": 1,
    "المغذيات": 1,
    "النفقات": 1,
    "النفقة": 1,
    "تام": 1,
    "القسمة": 1,
    "أليسون": 1,
    "أليستير": 1,
    "الصبغ": 1,
    "الأحمر": 1,
    "القلوي": 1,
    "قلوي": 1,
    "القلويات": 1,
    "شبه": 1,
    "مركبات": 1,
    "هيدروكربون": 1,
    "مشبع": 1,
    "مستحضر": 1,
    "هيدروكربوني": 1,
    "متعدد": 1,
    "الأغراض": 1,
    "الترانزستور": 1,
    "ألان": 1,
    "هدئ": 1,
    "هدأ": 1,
    "التهدئة": 1,
    "يهدئ": 1,
    "أليجيت": 1,
    "الادعاء": 1,
    "الادعاءات": 1,
    "ادع": 1,
    "ادعائي": 1,
    "مزعوم": 1,
    "ظاهريا": 1,
    "يدعي": 1,
    "الليجاني": 1,
    "الولاءات": 1,
    "مخلص": 1,
    "مجازي": 1,
    "استعاري": 1,
    "استعاريا": 1,
    "الاستعارية": 1,
    "الرموز": 1,
    "المجاز": 1,
    "يحول": 1,
    "مجازيا": 1,
    "الرمز": 1,
    "أليجرا": 1,
    "عاجل": 1,
    "أعجل": 1,
    "مقطوعة": 1,
    "شديدة": 1,
    "مقطوعات": 1,
    "أليل": 1,
    "أكبر": 1,
    "رقصة": 1,
    "الأليماند": 1,
    "موسيقى": 1,
    "ألين": 1,
    "ألينديل": 1,
    "تاون": 1,
    "مثيرات": 1,
    "للحساسية": 1,
    "بالحساسية": 1,
    "الحساسية": 1,
    "سكن": 1,
    "يسكن": 1,
    "التسكين": 1,
    "المسكنات": 1,
    "الزقاق": 1,
    "الأزقة": 1,
    "الحارة": 1,
    "الحارات": 1,
    "عيد": 1,
    "القديسين": 1,
    "التحالف": 1,
    "التحالفات": 1,
    "تحالف": 1,
    "محالف": 1,
    "الحلفاء": 1,
    "التمساح": 1,
    "مظهر": 1,
    "التماسيح": 1,
    "استخدم": 1,
    "الجناس": 1,
    "استخدام": 1,
    "متجانس": 1,
    "جناس": 1,
    "للتقسيم": 1,
    "للتخصيص": 1,
    "خصص": 1,
    "يخصص": 1,
    "التخصيص": 1,
    "الحصة": 1,
    "الحصص": 1,
    "حصصي": 1,
    "محصص": 1,
    "محصصون": 1,
    "تباين": 1,
    "تباينات": 1,
    "متباين": 1,
    "حديقة": 1,
    "مخصصة": 1,
    "النصيب": 1,
    "الأنصبة": 1,
    "تآصلي": 1,
    "تآصليا": 1,
    "مخصص": 1,
    "اسمح": 1,
    "مسموح": 1,
    "قبولية": 1,
    "السماح": 1,
    "العلاوة": 1,
    "أعطى": 1,
    "علاوةه": 1,
    "العلاوات": 1,
    "الإعطاء": 1,
    "علاوة": 1,
    "سمح": 1,
    "باتفاق": 1,
    "عام": 1,
    "يسمح": 1,
    "سبك": 1,
    "السبك": 1,
    "المركبات": 1,
    "البهارات": 1,
    "يلمح": 1,
    "ملمح": 1,
    "التلميح": 1,
    "أغرى": 1,
    "الإغراء": 1,
    "الجاذبي": 1,
    "حالم": 1,
    "جذابة": 1,
    "مستوى": 1,
    "التلميحات": 1,
    "رسوبي": 1,
    "الطمي": 1,
    "الحليف": 1,
    "أليلي": 1,
    "ألمى": 1,
    "ألمادين": 1,
    "المجسط": 1,
    "التقويم": 1,
    "التقاويم": 1,
    "بقدرة": 1,
    "هائل": 1,
    "اللوز": 1,
    "الإخصائي": 1,
    "الاجتماعي": 1,
    "الصدقات": 1,
    "بيت": 1,
    "الفقراء": 1,
    "بيوت": 1,
    "الفقير": 1,
    "ألنيكو": 1,
    "الألوة": 1,
    "الصبر": 1,
    "عاليا": 1,
    "ألوها": 1,
    "ألون": 1,
    "وحيدا": 1,
    "والحدة": 1,
    "الأمام": 1,
    "بمحاذاة": 1,
    "الشاطئ": 1,
    "جانبيا": 1,
    "الانطواء": 1,
    "عالي": 1,
    "عصا": 1,
    "التسلق": 1,
    "الألفا": 1,
    "أبجدي": 1,
    "العدد": 1,
    "الأبجدي": 1,
    "أبجديا": 1,
    "الحروف": 1,
    "الأبجدية": 1,
    "مرتب": 1,
    "مرتبات": 1,
    "أبجدية": 1,
    "يرتب": 1,
    "ترتيب": 1,
    "حرفي": 1,
    "رقمي": 1,
    "هجائي": 1,
    "عددي": 1,
    "هجائيات": 1,
    "عددية": 1,
    "ألفيراتس": 1,
    "ألفونس": 1,
    "جبلي": 1,
    "متسلق": 1,
    "الألب": 1,
    "الجبال": 1,
    "بالفعل": 1,
    "حسنا": 1,
    "الألزاسي": 1,
    "أيضا": 1,
    "ألسب": 1,
    "ألت": 1,
    "نسر": 1,
    "اللوحة": 1,
    "تغير": 1,
    "للتغيير": 1,
    "التغير": 1,
    "بتغير": 1,
    "التغيير": 1,
    "تشاحن": 1,
    "يتشاحن": 1,
    "التشاحن": 1,
    "المشاجرة": 1,
    "المشاجرات": 1,
    "مغير": 1,
    "مغيرون": 1,
    "الرجل": 1,
    "البديل": 1,
    "بديل": 1,
    "تناوب": 1,
    "بالتناوب": 1,
    "يتناوب": 1,
    "التناوب": 1,
    "التناوبات": 1,
    "كبديل": 1,
    "التبادلية": 1,
    "البدائل": 1,
    "المولد": 1,
    "المولدات": 1,
    "يتغير": 1,
    "ألثي": 1,
    "بالرغم": 1,
    "أن": 1,
    "الارتفاع": 1,
    "الارتفاعات": 1,
    "الألتو": 1,
    "كليا": 1,
    "ألتون": 1,
    "الإيثار": 1,
    "بإيثار": 1,
    "المؤثرون": 1,
    "الخريج": 1,
    "أكسيد": 1,
    "ألومنيوم": 1,
    "ألومينات": 1,
    "الألومنيوم": 1,
    "الألمنيوم": 1,
    "الخريجة": 1,
    "ألمنا": 1,
    "الخريجون": 1,
    "مصهور": 1,
    "ألفاريز": 1,
    "ألفيولارلي": 1,
    "ألفيولي": 1,
    "ألفين": 1,
    "ألواي": 1,
    "دائما": 1,
    "أليسا": 1,
    "الآلوسن": 1,
    "هل": 1,
    "أماديس": 1,
    "أماه": 1,
    "بكل": 1,
    "المزيج": 1,
    "اندمج": 1,
    "يندمج": 1,
    "الاندماج": 1,
    "إندماجي": 1,
    "الأمزجة": 1,
    "أماندا": 1,
    "الأمانيت": 1,
    "الكاتب": 1,
    "تذبل": 1,
    "أماريلو": 1,
    "الأمارلس": 1,
    "كدس": 1,
    "تكدس": 1,
    "مكدس": 1,
    "يتكدس": 1,
    "التكدس": 1,
    "الرداءة": 1,
    "الهواية": 1,
    "غرامي": 1,
    "أذهل": 1,
    "مندهش": 1,
    "مذهل": 1,
    "مذهلون": 1,
    "يذهل": 1,
    "رائع": 1,
    "القوية": 1,
    "النساء": 1,
    "السفير": 1,
    "السفراء": 1,
    "منصب": 1,
    "مناصب": 1,
    "سفير": 1,
    "الكهرمان": 1,
    "العنبر": 1,
    "الكهرمانات": 1,
    "البيئات": 1,
    "ماهر": 1,
    "يدويا": 1,
    "ماهرة": 1,
    "الأجواء": 1,
    "امبيانت": 1,
    "الغموض": 1,
    "اللبس": 1,
    "ملتبس": 1,
    "التباس": 1,
    "النطاق": 1,
    "الطموح": 1,
    "الطموحات": 1,
    "طموح": 1,
    "الازدواجية": 1,
    "منقسم": 1,
    "متناقض": 1,
    "التمهل": 1,
    "تمشى": 1,
    "المتمهل": 1,
    "التمشي": 1,
    "أمبروز": 1,
    "لذيذ": 1,
    "بمنتهى": 1,
    "اللذة": 1,
    "الأطعمة": 1,
    "اللذيذة": 1,
    "سيارة": 1,
    "الإسعاف": 1,
    "سيارات": 1,
    "متنقل": 1,
    "تجول": 1,
    "التجول": 1,
    "ممشى": 1,
    "متجول": 1,
    "الكمين": 1,
    "باغت": 1,
    "مباغت": 1,
    "الكمائن": 1,
    "نصب": 1,
    "كمينا": 1,
    "أمبشر": 1,
    "أمدال": 1,
    "أميليا": 1,
    "حسن": 1,
    "التحسين": 1,
    "التحسينات": 1,
    "محسن": 1,
    "آمين": 1,
    "المطاوعة": 1,
    "طوعا": 1,
    "تعويض": 1,
    "تحسن": 1,
    "معوض": 1,
    "التحسن": 1,
    "يتحسن": 1,
    "الطمث": 1,
    "الاصطلاح": 1,
    "الأمركة": 1,
    "أمريكي": 1,
    "الأمريكيون": 1,
    "الأمريكتين": 1,
    "أميريشيوم": 1,
    "أميز": 1,
    "أميسلان": 1,
    "حجر": 1,
    "أميثست": 1,
    "بنفسجي": 1,
    "أحجار": 1,
    "أمهيرست": 1,
    "أمي": 1,
    "اللطف": 1,
    "أنيس": 1,
    "محبوب": 1,
    "بأنس": 1,
    "ودي": 1,
    "السلم": 1,
    "الأميد": 1,
    "الصديق": 1,
    "أميني": 1,
    "أمينوبنزوي": 1,
    "خاطئ": 1,
    "الصداقات": 1,
    "الصداقة": 1,
    "عمان": 1,
    "أميرمان": 1,
    "التيار": 1,
    "الكهربائي": 1,
    "الذخيرة": 1,
    "النشادر": 1,
    "نشادري": 1,
    "الأمونيا": 1,
    "الأمونيوم": 1,
    "الذخائر": 1,
    "الذاكرة": 1,
    "عفا": 1,
    "العامة": 1,
    "العام": 1,
    "أموكو": 1,
    "الأميبا": 1,
    "الأميبات": 1,
    "أميبي": 1,
    "الجنون": 1,
    "القاتل": 1,
    "أخلاقي": 1,
    "أخلاقيات": 1,
    "الفساد": 1,
    "متحايد": 1,
    "أخلاقيا": 1,
    "بغرام": 1,
    "الغرام": 1,
    "ملامح": 1,
    "محدد": 1,
    "وشك": 1,
    "الموت": 1,
    "مقسط": 1,
    "التقسيط": 1,
    "سدد": 1,
    "يسدد": 1,
    "التسديد": 1,
    "عاموس": 1,
    "الكمية": 1,
    "مبلوغ": 1,
    "الكم": 1,
    "محددوا": 1,
    "الكميات": 1,
    "الغرامية": 1,
    "العلاقات": 1,
    "الأمبير": 1,
    "كهربائي": 1,
    "الأمبيرات": 1,
    "الو": 1,
    "الوات": 1,
    "أمبيكس": 1,
    "المقوي": 1,
    "المقويات": 1,
    "البرمائي": 1,
    "الكائنات": 1,
    "البرمائية": 1,
    "برمائي": 1,
    "الأمفيبولات": 1,
    "الطرفين": 1,
    "المدرج": 1,
    "المدرجات": 1,
    "قارورة": 1,
    "جرة": 1,
    "قوارير": 1,
    "جرات": 1,
    "متسع": 1,
    "وافي": 1,
    "مسهب": 1,
    "السعة": 1,
    "كفاية": 1,
    "أسهب": 1,
    "مكبر": 1,
    "مكبرات": 1,
    "يسهب": 1,
    "الإسهاب": 1,
    "مدى": 1,
    "التردد": 1,
    "صم": 1,
    "الحقنة": 1,
    "الحقن": 1,
    "ابتر": 1,
    "بتر": 1,
    "يبتر": 1,
    "المعوق": 1,
    "أمرا": 1,
    "إيه": 1,
    "إس": 1,
    "أمستردام": 1,
    "أمتراك": 1,
    "باهتياج": 1,
    "التميمة": 1,
    "التمائم": 1,
    "سل": 1,
    "مستمتع": 1,
    "بسعادة": 1,
    "المتعة": 1,
    "الملاهي": 1,
    "مسل": 1,
    "مسلون": 1,
    "يسلي": 1,
    "التسلية": 1,
    "مسلية": 1,
    "أمسايف": 1,
    "الصخر": 1,
    "البركاني": 1,
    "أميلي": 1,
    "تعريف": 1,
    "النوادر": 1,
    "قائل": 1,
    "بتجديد": 1,
    "عماد": 1,
    "قائلون": 1,
    "أنابل": 1,
    "الآثار": 1,
    "منطوي": 1,
    "مفارقة": 1,
    "تاريخية": 1,
    "للعصر": 1,
    "مناسبة": 1,
    "أفعى": 1,
    "الأناكوندا": 1,
    "أفاعي": 1,
    "الهجرة": 1,
    "المالح": 1,
    "للعذب": 1,
    "لوضع": 1,
    "البيض": 1,
    "الأنيميا": 1,
    "التخدير": 1,
    "النقش": 1,
    "الناقص": 1,
    "الناقصة": 1,
    "أناهييم": 1,
    "شرجي": 1,
    "مقوي": 1,
    "الإحساس": 1,
    "شرجيا": 1,
    "النظير": 1,
    "قياسي": 1,
    "المقارنات": 1,
    "متشابه": 1,
    "متناظرة": 1,
    "التناظرية": 1,
    "المقارنة": 1,
    "المحلل": 1,
    "التحليلات": 1,
    "التحليل": 1,
    "المحللون": 1,
    "تحليلي": 1,
    "خواص": 1,
    "تحليلية": 1,
    "المنطقي": 1,
    "للتحليل": 1,
    "صورة": 1,
    "بصرية": 1,
    "مشوهة": 1,
    "بالجناس": 1,
    "باستخدام": 1,
    "أنابلازموسيس": 1,
    "الفوضوي": 1,
    "فوضوي": 1,
    "الفوضويون": 1,
    "الفوضوية": 1,
    "أناركتيكا": 1,
    "أناستاسيا": 1,
    "العدسة": 1,
    "مصحح": 1,
    "الوصلات": 1,
    "توصيلي": 1,
    "العن": 1,
    "لعن": 1,
    "يلعن": 1,
    "اللعن": 1,
    "أناتول": 1,
    "تشريحي": 1,
    "تشريحيا": 1,
    "تشريحيات": 1,
    "البنى": 1,
    "عالم": 1,
    "التشريح": 1,
    "شرح": 1,
    "يشرح": 1,
    "الجد": 1,
    "الأجداد": 1,
    "الجدودية": 1,
    "سلفي": 1,
    "سلالي": 1,
    "السلف": 1,
    "الأسلاف": 1,
    "الأصول": 1,
    "الأصل": 1,
    "المرساة": 1,
    "أنكريج": 1,
    "المراسي": 1,
    "رسا": 1,
    "الرسو": 1,
    "الناسك": 1,
    "حياة": 1,
    "العزلة": 1,
    "المراس": 1,
    "الأنشوفة": 1,
    "قديما": 1,
    "القدم": 1,
    "إنديزي": 1,
    "منطقيا": 1,
    "المعامل": 1,
    "&": 1,
    "المتغيرات": 1,
    "أندرس": 1,
    "أندرسن": 1,
    "أندرسون": 1,
    "أنديز": 1,
    "أنديساين": 1,
    "أنديسايت": 1,
    "المسند": 1,
    "أندورا": 1,
    "أندوفر": 1,
    "أندريه": 1,
    "أندريي": 1,
    "أندرو": 1,
    "أندروز": 1,
    "منشط": 1,
    "الذكورة": 1,
    "منشطات": 1,
    "مخنث": 1,
    "الروبوت": 1,
    "أندروماش": 1,
    "آندروميدا": 1,
    "AND": 1,
    "أندي": 1,
    "قصصي": 1,
    "قصصية": 1,
    "النادرة": 1,
    "النادرات": 1,
    "القاص": 1,
    "القصاصون": 1,
    "صدوي": 1,
    "ينتج": 1,
    "صدى": 1,
    "أنيمية": 1,
    "مصابوا": 1,
    "شدة": 1,
    "الريح": 1,
    "ريح": 1,
    "الانيمن": 1,
    "مبحث": 1,
    "المخدر": 1,
    "بالتخدير": 1,
    "المخدرات": 1,
    "إخصائيون": 1,
    "خدر": 1,
    "مختصوا": 1,
    "يخدر": 1,
    "الملاك": 1,
    "أنجيلا": 1,
    "أنجيلينو": 1,
    "أنجيلينوس": 1,
    "أنجيليز": 1,
    "السمك": 1,
    "ملائكي": 1,
    "الأنجليك": 1,
    "بملائكية": 1,
    "أنجيلينا": 1,
    "أنجيلاين": 1,
    "أنجلو": 1,
    "الملائكة": 1,
    "الغضب": 1,
    "أغضب": 1,
    "الإغضاب": 1,
    "أنجي": 1,
    "تصوير": 1,
    "تتبعي": 1,
    "بالأشعة": 1,
    "ملقح": 1,
    "ذاتيا": 1,
    "مزوى": 1,
    "الصياد": 1,
    "الصيادون": 1,
    "الزوايا": 1,
    "أنجليا": 1,
    "الأنجليكي": 1,
    "الأنجليكية": 1,
    "الكراهية": 1,
    "أنجولا": 1,
    "الأنجورا": 1,
    "غضبا": 1,
    "بغضب": 1,
    "غاضب": 1,
    "أنجستروم": 1,
    "أنجسترومز": 1,
    "العذاب": 1,
    "تألم": 1,
    "العذابات": 1,
    "بارز": 1,
    "العظام": 1,
    "زوايا": 1,
    "زاويا": 1,
    "كم": 1,
    "أنجس": 1,
    "متناغم": 1,
    "أنهيسر": 1,
    "الأندريد": 1,
    "الأنهيدريت": 1,
    "جاف": 1,
    "لامائي": 1,
    "أني": 1,
    "الأنيلين": 1,
    "الملاحظة": 1,
    "الانتقادية": 1,
    "الملاحظات": 1,
    "انتقد": 1,
    "ينتقد": 1,
    "الصغير": 1,
    "الحيوانية": 1,
    "السمات": 1,
    "جسديا": 1,
    "متحمس": 1,
    "متحرك": 1,
    "الحركية": 1,
    "ينعش": 1,
    "الإنعاش": 1,
    "الرسوم": 1,
    "المتحركة": 1,
    "رسوم": 1,
    "متحركة": 1,
    "انيما": 1,
    "الروحانية": 1,
    "يعطي": 1,
    "الأنيون": 1,
    "أنيوني": 1,
    "أنيونيات": 1,
    "الينسون": 1,
    "اليانسون": 1,
    "الخواص": 1,
    "أنيتا": 1,
    "أنقرة": 1,
    "الكاحل": 1,
    "عظم": 1,
    "الكواحل": 1,
    "الخلخال": 1,
    "سجلي": 1,
    "الشبكة": 1,
    "الإخبارية": 1,
    "الآنة": 1,
    "أنال": 1,
    "أنيل": 1,
    "أنالين": 1,
    "المؤرخ": 1,
    "السجلات": 1,
    "أنابوليس": 1,
    "يصلب": 1,
    "مصلب": 1,
    "مصلبون": 1,
    "الصلب": 1,
    "الحلقية": 1,
    "أنات": 1,
    "استولى": 1,
    "الاستيلاء": 1,
    "اهلك": 1,
    "أهلك": 1,
    "يهلك": 1,
    "الإهلاك": 1,
    "الإبادة": 1,
    "مدمر": 1,
    "المدمر": 1,
    "الأعياد": 1,
    "العيد": 1,
    "علق": 1,
    "يعلق": 1,
    "التعليق": 1,
    "التعليقات": 1,
    "تعليقي": 1,
    "الحواشي": 1,
    "المذيع": 1,
    "المذيعون": 1,
    "ضايق": 1,
    "مصادر": 1,
    "الإزعاج": 1,
    "متضايق": 1,
    "مزعج": 1,
    "مزعجون": 1,
    "يضايق": 1,
    "النبات": 1,
    "الحولي": 1,
    "محسوب": 1,
    "سنويا": 1,
    "الحولية": 1,
    "السناهي": 1,
    "المعاشات": 1,
    "المعاش": 1,
    "حلقي": 1,
    "حلقيا": 1,
    "مبطل": 1,
    "للإبطال": 1,
    "الإبطالات": 1,
    "الحلقة": 1,
    "بشارة": 1,
    "جبريل": 1,
    "بشائر": 1,
    "قطب": 1,
    "الموجب": 1,
    "أقطاب": 1,
    "أنوديك": 1,
    "متعلقا": 1,
    "بالقطب": 1,
    "اطل": 1,
    "بأكسيد": 1,
    "طلى": 1,
    "يطلي": 1,
    "طلاء": 1,
    "ادهن": 1,
    "مدهن": 1,
    "الدهن": 1,
    "المسح": 1,
    "يدهن": 1,
    "الشواذ": 1,
    "شواذ": 1,
    "اجتماعيا": 1,
    "حالا": 1,
    "إخفاء": 1,
    "الهوية": 1,
    "مجهول": 1,
    "اسم": 1,
    "اللاهوية": 1,
    "المعطف": 1,
    "واق": 1,
    "الفقدان": 1,
    "الشهية": 1,
    "ذي": 1,
    "محاور": 1,
    "منحرفة": 1,
    "أنورثيت": 1,
    "أنورثوسيت": 1,
    "آخر": 1,
    "أنز": 1,
    "أنسيلم": 1,
    "أنسيلمو": 1,
    "أنسون": 1,
    "الإجابة": 1,
    "رد": 1,
    "بث": 1,
    "لاسلكي": 1,
    "مجاوب": 1,
    "مجاوبون": 1,
    "الرد": 1,
    "الإجابات": 1,
    "النملة": 1,
    "للحموضة": 1,
    "أنتايس": 1,
    "العداء": 1,
    "العداءات": 1,
    "الخصم": 1,
    "الخصوم": 1,
    "معادون": 1,
    "يعادي": 1,
    "العداوة": 1,
    "القطب": 1,
    "الجنوبي": 1,
    "القارة": 1,
    "القطبية": 1,
    "الجنوبية": 1,
    "قلب": 1,
    "العقرب": 1,
    "ادفع": 1,
    "آكل": 1,
    "النمل": 1,
    "أكلة": 1,
    "ما": 1,
    "الحرب": 1,
    "اسبق": 1,
    "سبق": 1,
    "الأسبقية": 1,
    "السبق": 1,
    "دفع": 1,
    "عتيق": 1,
    "الدفع": 1,
    "الظبي": 1,
    "الظباء": 1,
    "القرن": 1,
    "القرون": 1,
    "أمامي": 1,
    "الأجزاء": 1,
    "الأمامية": 1,
    "حجرة": 1,
    "الانتظار": 1,
    "حجرات": 1,
    "يدفع": 1,
    "النشيد": 1,
    "الأناشيد": 1,
    "الذكري": 1,
    "عش": 1,
    "المقتطفات": 1,
    "الأدبية": 1,
    "الباحث": 1,
    "أنتوني": 1,
    "أنثراسين": 1,
    "أنثراسايت": 1,
    "أنثراكنوز": 1,
    "اصفرار": 1,
    "دراسة": 1,
    "الأحياء": 1,
    "الغابة": 1,
    "أنثربولوجي": 1,
    "أنثروبولوجيا": 1,
    "الأنثربولوجيا": 1,
    "الأنثروبولوجيا": 1,
    "علماء": 1,
    "بالأنثروبومترية": 1,
    "قياسات": 1,
    "الأنثروبومترية": 1,
    "مجسم": 1,
    "المجسمات": 1,
    "الآدمية": 1,
    "للاسم": 1,
    "للألياسينج": 1,
    "للأجنبي": 1,
    "للمظاهرة": 1,
    "تحسين": 1,
    "الحواف": 1,
    "المحسنة": 1,
    "للبكتيريا": 1,
    "الحيوي": 1,
    "المضادات": 1,
    "الأجسام": 1,
    "المضادة": 1,
    "للعمل": 1,
    "غريب": 1,
    "المسيحون": 1,
    "الدجال": 1,
    "توقع": 1,
    "يتوقع": 1,
    "التوقع": 1,
    "التوقعات": 1,
    "متوقع": 1,
    "تطلعي": 1,
    "سيطرة": 1,
    "القساوسة": 1,
    "بانحدار": 1,
    "الصدمات": 1,
    "منع": 1,
    "تخثر": 1,
    "الصدفة": 1,
    "معاداة": 1,
    "تنافسي": 1,
    "الطرائف": 1,
    "دوري": 1,
    "الإشتقاق": 1,
    "حركة": 1,
    "الموالاة": 1,
    "للكنائسية": 1,
    "الرسمية": 1,
    "للسم": 1,
    "الترياق": 1,
    "الأدوية": 1,
    "الإغراق": 1,
    "أنتيتام": 1,
    "المغنطيسي": 1,
    "المذيب": 1,
    "المزلاق": 1,
    "الأصولي": 1,
    "المناعة": 1,
    "أدوية": 1,
    "أنتيغون": 1,
    "أنتيجورايت": 1,
    "الشخصية": 1,
    "الرئيسية": 1,
    "تأريخي": 1,
    "المقابل": 1,
    "لوغاريتم": 1,
    "مقابلات": 1,
    "اللوغاريثم": 1,
    "الغطاء": 1,
    "الأساسية": 1,
    "مكروبي": 1,
    "الأنتيمون": 1,
    "أنتينج": 1,
    "تناقضي": 1,
    "القوانين": 1,
    "تناقض": 1,
    "أنطاكيا": 1,
    "مقبلات": 1,
    "مبغض": 1,
    "كاره": 1,
    "ببغض": 1,
    "للأفراد": 1,
    "للعرق": 1,
    "التجاوبي": 1,
    "تجاوبيا": 1,
    "المجاوبة": 1,
    "الصوتية": 1,
    "التخطيط": 1,
    "واجع": 1,
    "النقيض": 1,
    "واقع": 1,
    "النقائض": 1,
    "مانع": 1,
    "للحمى": 1,
    "موانع": 1,
    "الأثريات": 1,
    "تخصص": 1,
    "الآثاريون": 1,
    "خبير": 1,
    "محل": 1,
    "حلول": 1,
    "مكاني": 1,
    "القطعة": 1,
    "الأثرية": 1,
    "أثري": 1,
    "العتق": 1,
    "بالآثار": 1,
    "العصور": 1,
    "للاستبدال": 1,
    "الرنين": 1,
    "أنتيس": 1,
    "المعادي": 1,
    "للسامية": 1,
    "السامية": 1,
    "المعاداة": 1,
    "المطهر": 1,
    "مضادة": 1,
    "للتعفن": 1,
    "المطهرات": 1,
    "أمصال": 1,
    "الصدمة": 1,
    "مقاومة": 1,
    "الرق": 1,
    "انطوائي": 1,
    "أنتيسو": 1,
    "سيالي": 1,
    "للغواصات": 1,
    "متماثل": 1,
    "التناظر": 1,
    "للدبابات": 1,
    "العكس": 1,
    "معاكسة": 1,
    "الدرقية": 1,
    "للرأسمالية": 1,
    "الإحتكار": 1,
    "قرون": 1,
    "أنطوان": 1,
    "أنطوانيت": 1,
    "أنتون": 1,
    "أنطونيو": 1,
    "أنتكوتيس": 1,
    "أنتويرب": 1,
    "فتحة": 1,
    "الشرج": 1,
    "أنفينجيد": 1,
    "السندان": 1,
    "السنادين": 1,
    "قلق": 1,
    "بقلق": 1,
    "بأية": 1,
    "حال": 1,
    "لم": 1,
    "يعد": 1,
    "أنيونيز": 1,
    "أنيثينجز": 1,
    "أنيوهيريز": 1,
    "الأورطى": 1,
    "أورتا": 1,
    "بسرعة": 1,
    "أباتشي": 1,
    "حدة": 1,
    "التفرقة": 1,
    "بالوضع": 1,
    "الشقة": 1,
    "الشقق": 1,
    "تحديد": 1,
    "مبالي": 1,
    "بفتور": 1,
    "الأباتيت": 1,
    "القرد": 1,
    "قلد": 1,
    "أبر": 1,
    "دورية": 1,
    "بؤرة": 1,
    "بؤري": 1,
    "بؤر": 1,
    "القرود": 1,
    "الرأس": 1,
    "الرءوس": 1,
    "الكلام": 1,
    "الأوج": 1,
    "الأفد": 1,
    "مبحوح": 1,
    "حاكي": 1,
    "حكيم": 1,
    "الأفرودايت": 1,
    "المناحل": 1,
    "المنحلة": 1,
    "بقيمة": 1,
    "عالية": 1,
    "القمم": 1,
    "لكل": 1,
    "التقليد": 1,
    "مقلد": 1,
    "كالقرد": 1,
    "تقليد": 1,
    "بغباء": 1,
    "الثقة": 1,
    "نهايات": 1,
    "تنبؤي": 1,
    "نذير": 1,
    "بنحس": 1,
    "كتب": 1,
    "التوراة": 1,
    "المزورة": 1,
    "ملفق": 1,
    "بتلفيق": 1,
    "التلفيق": 1,
    "المؤكد": 1,
    "متن": 1,
    "الجملة": 1,
    "الشرطية": 1,
    "بخلايا": 1,
    "عصب": 1,
    "محددة": 1,
    "السياسة": 1,
    "أبوليناير": 1,
    "أبولو": 1,
    "أبولوني": 1,
    "اعتذاري": 1,
    "معتذرا": 1,
    "علوم": 1,
    "المسيحية": 1,
    "الاعتذارات": 1,
    "المدافع": 1,
    "المدافعون": 1,
    "اعتذر": 1,
    "معتذر": 1,
    "معتذرون": 1,
    "يعتذر": 1,
    "اعتذار": 1,
    "سريع": 1,
    "السكتات": 1,
    "السكتة": 1,
    "الردة": 1,
    "المرتد": 1,
    "المرتدون": 1,
    "ارتد": 1,
    "يرتد": 1,
    "الارتداد": 1,
    "الحواري": 1,
    "الحواريون": 1,
    "بابوي": 1,
    "رسوليا": 1,
    "الفاصلة": 1,
    "الفاصلات": 1,
    "ناجي": 1,
    "ناجى": 1,
    "يناجي": 1,
    "المناجاة": 1,
    "الصيدليون": 1,
    "الصيدلي": 1,
    "الحكمة": 1,
    "التأليهات": 1,
    "المثال": 1,
    "معرف": 1,
    "التطبيق": 1,
    "أبالاتشيا": 1,
    "الأبالاتشيا": 1,
    "مرعوب": 1,
    "مريع": 1,
    "مرعب": 1,
    "يهول": 1,
    "الأفراس": 1,
    "أبالز": 1,
    "الميراث": 1,
    "المعدات": 1,
    "مكسو": 1,
    "الكسوة": 1,
    "كسا": 1,
    "الكسو": 1,
    "ظاهر": 1,
    "يبدو": 1,
    "شبح": 1,
    "أشباح": 1,
    "يستأنف": 1,
    "للإستئناف": 1,
    "استأنف": 1,
    "المستأنف": 1,
    "المستأنفون": 1,
    "استئنافات": 1,
    "اظهر": 1,
    "مظاهر": 1,
    "ظاهرون": 1,
    "متسامح": 1,
    "المهادنة": 1,
    "المهدئ": 1,
    "استئنافي": 1,
    "أبيلانتس": 1,
    "إسميا": 1,
    "مذيل": 1,
    "الزائدة": 1,
    "الدودية": 1,
    "أضاف": 1,
    "مضيف": 1,
    "مضيفون": 1,
    "التهاب": 1,
    "الزائدات": 1,
    "المظهر": 1,
    "تعلق": 1,
    "يتعلق": 1,
    "الشهوات": 1,
    "الشهوة": 1,
    "مشهي": 1,
    "فاتحات": 1,
    "للشهية": 1,
    "منتمي": 1,
    "لآبيوس": 1,
    "تهليلي": 1,
    "بتهليل": 1,
    "مهلل": 1,
    "تهليل": 1,
    "التهاليل": 1,
    "التفاحة": 1,
    "فطيرة": 1,
    "التفاح": 1,
    "أبليبي": 1,
    "عصير": 1,
    "بريمج": 1,
    "برنامج": 1,
    "أبليتون": 1,
    "بريمجات": 1,
    "الجهاز": 1,
    "الأجهزة": 1,
    "الانطباق": 1,
    "منطبق": 1,
    "بإنطباق": 1,
    "يطلب": 1,
    "الطلب": 1,
    "بالطلب": 1,
    "التطبيقاتالأحداث": 1,
    "الطلبات": 1,
    "عملي": 1,
    "تطبيقي": 1,
    "القضيب": 1,
    "الرفيع": 1,
    "القضبان": 1,
    "الرفيعة": 1,
    "انطبق": 1,
    "مطبقون": 1,
    "ينطبق": 1,
    "طبق": 1,
    "عين": 1,
    "المعين": 1,
    "المعينات": 1,
    "معينون": 1,
    "التعيين": 1,
    "تعييني": 1,
    "الموعد": 1,
    "المواعيد": 1,
    "يعين": 1,
    "أبوماتوكس": 1,
    "كائن": 1,
    "مادي": 1,
    "أظهره": 1,
    "روحاني": 1,
    "قسم": 1,
    "التقسيم": 1,
    "التقسيمات": 1,
    "يقسم": 1,
    "البدل": 1,
    "عطفي": 1,
    "بالبدل": 1,
    "التقييم": 1,
    "التقييمات": 1,
    "قيم": 1,
    "المثمن": 1,
    "المثمنون": 1,
    "بتقدير": 1,
    "ملحوظ": 1,
    "قدر": 1,
    "يقدر": 1,
    "بامتنان": 1,
    "مقدر": 1,
    "اعتقل": 1,
    "معتقل": 1,
    "الاعتقال": 1,
    "يعتقل": 1,
    "الفهم": 1,
    "المخاوف": 1,
    "أرسل": 1,
    "المبتدئون": 1,
    "التدريب": 1,
    "التدريبات": 1,
    "الإرسال": 1,
    "أبلغ": 1,
    "يبلغ": 1,
    "تبليغ": 1,
    "تبليغات": 1,
    "أخبر": 1,
    "المقيم": 1,
    "المقيمون": 1,
    "تقييم": 1,
    "تقييمات": 1,
    "الطريقة": 1,
    "ودود": 1,
    "اقترب": 1,
    "مقترب": 1,
    "مقتربون": 1,
    "الطرق": 1,
    "الاقتراب": 1,
    "صدق": 1,
    "للملائمة": 1,
    "يستولي": 1,
    "التخصيصات": 1,
    "تلائمي": 1,
    "المستولي": 1,
    "المستولون": 1,
    "للتقريب": 1,
    "تقريبي": 1,
    "قارب": 1,
    "يقارب": 1,
    "المقاربة": 1,
    "إبريل/أب": 1,
    "المشمش": 1,
    "الإبريل": 1,
    "استدلالي": 1,
    "المريلة": 1,
    "المرايل": 1,
    "وبالمناسبة": 1,
    "المحراب": 1,
    "المحاريب": 1,
    "محتمل": 1,
    "الموهبة": 1,
    "المواهب": 1,
    "الاستعداد": 1,
    "أسطوانة": 1,
    "الأكسجين": 1,
    "الزبرجد": 1,
    "رائد": 1,
    "بحار": 1,
    "زلاجة": 1,
    "أحواض": 1,
    "حوض": 1,
    "برج": 1,
    "الدلو": 1,
    "نيلي": 1,
    "مائي": 1,
    "مائيا": 1,
    "المائية": 1,
    "القناة": 1,
    "القنوات": 1,
    "طبقة": 1,
    "صخرية": 1,
    "طبقات": 1,
    "أكويلا": 1,
    "معقوف": 1,
    "الأكويني": 1,
    "العربي": 1,
    "الأرابيسك": 1,
    "الجزيرة": 1,
    "عربي": 1,
    "عرب": 1,
    "للزراعة": 1,
    "العرب": 1,
    "أراكني": 1,
    "الفصيلة": 1,
    "العنكبوتيات": 1,
    "الفصائل": 1,
    "القوس": 1,
    "القذوف": 1,
    "عاطل": 1,
    "الحكام": 1,
    "للتحكيم": 1,
    "المتاجرة": 1,
    "تحكيمي": 1,
    "متعسف": 1,
    "الاستبدادية": 1,
    "الإستبداد": 1,
    "تعسفي": 1,
    "احكم": 1,
    "العريش": 1,
    "شجري": 1,
    "مشجر": 1,
    "المشتل": 1,
    "العرش": 1,
    "القطلب": 1,
    "زود": 1,
    "بممرات": 1,
    "ريفية": 1,
    "هادئة": 1,
    "تسوير": 1,
    "أركانا": 1,
    "سري": 1,
    "قوس": 1,
    "جيب": 1,
    "تمام": 1,
    "انحنى": 1,
    "آركاي": 1,
    "الناحية": 1,
    "المهجورات": 1,
    "استعمل": 1,
    "جعله": 1,
    "مهجورا": 1,
    "مستخدم": 1,
    "مستخدموا": 1,
    "الجعل": 1,
    "المطرانية": 1,
    "المطرانيات": 1,
    "الأرشيدوقة": 1,
    "الدوق": 1,
    "تقوس": 1,
    "الرام": 1,
    "الرماة": 1,
    "الرماية": 1,
    "الأقواس": 1,
    "الأمكر": 1,
    "الأمثلة": 1,
    "نموذجي": 1,
    "ارشيبالد": 1,
    "ارشي": 1,
    "أرخميدس": 1,
    "التقوس": 1,
    "التقوسات": 1,
    "الأرخبيلات": 1,
    "المهندس": 1,
    "المعماري": 1,
    "معماري": 1,
    "معماريون": 1,
    "المعماريون": 1,
    "معماريا": 1,
    "الطراز": 1,
    "الطرز": 1,
    "المعمارية": 1,
    "ارشيف": 1,
    "بالأرشيف": 1,
    "الأرشيف": 1,
    "حفظ": 1,
    "أرشيفي": 1,
    "مأرشف": 1,
    "المحفوظات": 1,
    "أمين": 1,
    "أمناء": 1,
    "بغرور": 1,
    "المكر": 1,
    "الانحناء": 1,
    "مصباح": 1,
    "قوسي": 1,
    "القوسي": 1,
    "ظل": 1,
    "الشمالي": 1,
    "السماك": 1,
    "الرامح": 1,
    "أردن": 1,
    "الحماسة": 1,
    "بحماسة": 1,
    "الحماس": 1,
    "المناطق": 1,
    "أرين": 1,
    "الحلبة": 1,
    "رملي": 1,
    "الحلبات": 1,
    "أريكويبا": 1,
    "آريز": 1,
    "الفضي": 1,
    "الأرجنتين": 1,
    "الأرجنتيني": 1,
    "صلصالي": 1,
    "أرجينين": 1,
    "الآرغوسي": 1,
    "الأرجون": 1,
    "أرجون": 1,
    "بروجو": 1,
    "جدل": 1,
    "يزعم": 1,
    "تجادل": 1,
    "المجادل": 1,
    "المجادلون": 1,
    "يتجادل": 1,
    "التجادل": 1,
    "النقاش": 1,
    "الجدال": 1,
    "مجادل": 1,
    "جدليا": 1,
    "الجدل": 1,
    "النقاشات": 1,
    "نبيه": 1,
    "أرهات": 1,
    "الأغنية": 1,
    "أريادن": 1,
    "الآرية": 1,
    "آري": 1,
    "آريون": 1,
    "الجفاف": 1,
    "جافا": 1,
    "الحمل": 1,
    "صحيح": 1,
    "ارتفع": 1,
    "مرتفع": 1,
    "يرتفع": 1,
    "الطبقات": 1,
    "الأرستقراطية": 1,
    "الطبقة": 1,
    "الأرستقراطي": 1,
    "أرستقراطي": 1,
    "أريستوكراتيكال": 1,
    "أرستقراطية": 1,
    "الأرستقراطيون": 1,
    "أرستوفان": 1,
    "أريستوتيلين": 1,
    "أريستوتيلايان": 1,
    "أرسطو": 1,
    "بالحساب": 1,
    "أركانساس": 1,
    "أرلين": 1,
    "أرلينجتون": 1,
    "الذراع": 1,
    "الحشد": 1,
    "الحشود": 1,
    "الأرماديلو": 1,
    "المدرع": 1,
    "الفانية": 1,
    "السلاح": 1,
    "الأسلحة": 1,
    "أسلحة": 1,
    "الكرسي": 1,
    "الكراسي": 1,
    "أرمكو": 1,
    "تسلح": 1,
    "أرمينيا": 1,
    "الأرميني": 1,
    "سلاح": 1,
    "الحفنة": 1,
    "الحفنات": 1,
    "الجيوش": 1,
    "أرميلاريا": 1,
    "التسلح": 1,
    "الهدنة": 1,
    "الإسورة": 1,
    "حمل": 1,
    "ذراع": 1,
    "الدولاب": 1,
    "أرمونك": 1,
    "الدرع": 1,
    "مدرع": 1,
    "بشعار": 1,
    "النبالة": 1,
    "مستودعات": 1,
    "تزويد": 1,
    "بمدرعات": 1,
    "الدروع": 1,
    "الإبط": 1,
    "الآباط": 1,
    "سندة": 1,
    "الأذرع": 1,
    "أرمسترونج": 1,
    "الجيش": 1,
    "أرنولد": 1,
    "العبير": 1,
    "عطري": 1,
    "عطريا": 1,
    "العطر": 1,
    "علا": 1,
    "الإثارات": 1,
    "أربانيت": 1,
    "صوت": 1,
    "تتابعي": 1,
    "أصوات": 1,
    "تتابعية": 1,
    "المشروب": 1,
    "المسكر": 1,
    "أراجون": 1,
    "استدع": 1,
    "استدعى": 1,
    "الاستدعاء": 1,
    "يستدعي": 1,
    "للترتيب": 1,
    "الترتيبات": 1,
    "المنظم": 1,
    "المنظمون": 1,
    "متشرد": 1,
    "محض": 1,
    "التطريز": 1,
    "الصف": 1,
    "أرير": 1,
    "الصفوف": 1,
    "المتأخر": 1,
    "المتأخرات": 1,
    "اقبض": 1,
    "قبض": 1,
    "الموقوف": 1,
    "الموقوفون": 1,
    "الموقف": 1,
    "الموقفون": 1,
    "القبض": 1,
    "الاعتقالات": 1,
    "أرهينيس": 1,
    "القادمون": 1,
    "صل": 1,
    "قادم": 1,
    "الغرور": 1,
    "التكبر": 1,
    "متكبر": 1,
    "بتكبر": 1,
    "ادعى": 1,
    "السهم": 1,
    "النصل": 1,
    "النصال": 1,
    "النشوية": 1,
    "السهام": 1,
    "الغدير": 1,
    "الغدران": 1,
    "العجيزة": 1,
    "الترسانة": 1,
    "الترسانات": 1,
    "الزرنيخات": 1,
    "الزرنيخ": 1,
    "زرنيخي": 1,
    "الزرنيخيد": 1,
    "الأرسين": 1,
    "التخريب": 1,
    "المخرب": 1,
    "المخربون": 1,
    "التخريبات": 1,
    "الأداة": 1,
    "آرتميس": 1,
    "الأرطماسيا": 1,
    "شرياني": 1,
    "بالشريان": 1,
    "الشرايين": 1,
    "الشريان": 1,
    "تصلب": 1,
    "بتصلب": 1,
    "إرتوازي": 1,
    "داهية": 1,
    "بدهاء": 1,
    "الروماتيزم": 1,
    "لمفاصل": 1,
    "محقونة": 1,
    "بمواد": 1,
    "عاكسة": 1,
    "صور": 1,
    "المفصلي": 1,
    "المفصليات": 1,
    "أرثر": 1,
    "معالج": 1,
    "فوري": 1,
    "للواجهة": 1,
    "الخرشوف": 1,
    "تحت": 1,
    "التمرين": 1,
    "الإلزام": 1,
    "للتأويل": 1,
    "مفصلي": 1,
    "انطق": 1,
    "بوضوح": 1,
    "المفاصل": 1,
    "نطقي": 1,
    "متحدث": 1,
    "بالوضوح": 1,
    "متحدثون": 1,
    "أرتي": 1,
    "متطفل": 1,
    "الأدوات": 1,
    "التحايل": 1,
    "الصانع": 1,
    "الحيل": 1,
    "صناعي": 1,
    "الاصطناعي": 1,
    "الاصطناعية": 1,
    "الإصطناعية": 1,
    "المدفعيات": 1,
    "جندي": 1,
    "المدفعية": 1,
    "المدفعي": 1,
    "المدافعي": 1,
    "ادعاء": 1,
    "الحرفي": 1,
    "الفنان": 1,
    "فني": 1,
    "فنيا": 1,
    "المهارات": 1,
    "الفنية": 1,
    "الفنانون": 1,
    "ساذج": 1,
    "بسذاجة": 1,
    "العفوية": 1,
    "أرترو": 1,
    "أروبا": 1,
    "اللوف": 1,
    "أري": 1,
    "الأوروبي": 1,
    "الأوروبيون": 1,
    "الشماليون": 1,
    "أريل": 1,
    "ك": 1,
    "الأسبستوس": 1,
    "للصعود": 1,
    "السيادة": 1,
    "الصعود": 1,
    "فائق": 1,
    "الصاعد": 1,
    "الصاعدات": 1,
    "صعدة": 1,
    "صعدات": 1,
    "تحقق": 1,
    "التحقق": 1,
    "منه": 1,
    "يتحقق": 1,
    "زهدي": 1,
    "بزهد": 1,
    "الزاهدون": 1,
    "أسكي": 1,
    "الفطر": 1,
    "زقي": 1,
    "ربطة": 1,
    "العنق": 1,
    "ربطات": 1,
    "منسوب": 1,
    "يعزو": 1,
    "ينسب": 1,
    "النسب": 1,
    "النسبة": 1,
    "نسبي": 1,
    "بتطهير": 1,
    "مخصي": 1,
    "الخصي": 1,
    "تزاوج": 1,
    "الرماد": 1,
    "مطفأة": 1,
    "السجائر": 1,
    "أشيم": 1,
    "خجلان": 1,
    "مخجل": 1,
    "شاحب": 1,
    "أشفيل": 1,
    "بالرماد": 1,
    "أشلاند": 1,
    "أشلي": 1,
    "أشمان": 1,
    "أشمين": 1,
    "أشمولي": 1,
    "مطافئ": 1,
    "آسيا": 1,
    "الآسيوي": 1,
    "الآسيويون": 1,
    "جانبا": 1,
    "الجانبية": 1,
    "أبله": 1,
    "اسأل": 1,
    "إستنكارا": 1,
    "سأل": 1,
    "سائلون": 1,
    "بميل": 1,
    "السؤال": 1,
    "يسأل": 1,
    "نائم": 1,
    "الهليونين": 1,
    "الاسبراجس": 1,
    "الاسبراجسي": 1,
    "النواحي": 1,
    "الأسبن": 1,
    "الافتراء": 1,
    "الافتراءات": 1,
    "الأسفلت": 1,
    "سفلت": 1,
    "أسفلتي": 1,
    "كروي": 1,
    "الاختناق": 1,
    "الخانق": 1,
    "اختنق": 1,
    "يختنق": 1,
    "الخنق": 1,
    "الإختناق": 1,
    "الحساء": 1,
    "الأسبيدسترة": 1,
    "الطموحون": 1,
    "هاء": 1,
    "بملء": 1,
    "النفس": 1,
    "المضخات": 1,
    "اطمح": 1,
    "طمح": 1,
    "يطمح": 1,
    "الأسبرين": 1,
    "أسبلينيوم": 1,
    "مزودو": 1,
    "خدمات": 1,
    "الانترنت": 1,
    "الأسد": 1,
    "أساي": 1,
    "هاجم": 1,
    "منتقد": 1,
    "المهاجم": 1,
    "المهاجمون": 1,
    "المهاجمة": 1,
    "يهاجم": 1,
    "أسام": 1,
    "سفاك": 1,
    "اغتل": 1,
    "اغتال": 1,
    "يغتال": 1,
    "الاغتيال": 1,
    "الاغتيالات": 1,
    "المغتال": 1,
    "المغتالون": 1,
    "سفاكون": 1,
    "هجوم": 1,
    "متهجم": 1,
    "متهجمون": 1,
    "التهجم": 1,
    "تهجمي": 1,
    "تجهمية": 1,
    "التهجمية": 1,
    "الهجمات": 1,
    "الفحص": 1,
    "اختبر": 1,
    "الفاحص": 1,
    "الفاحصون": 1,
    "الاختبار": 1,
    "الفحوص": 1,
    "المجموعات": 1,
    "الجامع": 1,
    "الجامعون": 1,
    "يتجمع": 1,
    "المجالس": 1,
    "المجلس": 1,
    "أسن": 1,
    "أسنسيو": 1,
    "يصادق": 1,
    "مصادقات": 1,
    "صادق": 1,
    "مصادق": 1,
    "مصادقون": 1,
    "تصديق": 1,
    "مصادقة": 1,
    "مصداقي": 1,
    "حازم": 1,
    "بجزم": 1,
    "مصداقيات": 1,
    "توكيدي": 1,
    "أكيد": 1,
    "يؤكد": 1,
    "المصحح": 1,
    "المصححون": 1,
    "اجزم": 1,
    "جزم": 1,
    "يجزم": 1,
    "الجزم": 1,
    "المتسكعون": 1,
    "المواظبة": 1,
    "مجتهد": 1,
    "باجتهاد": 1,
    "الاجتهاد": 1,
    "المثابرة": 1,
    "كلف": 1,
    "التكليف": 1,
    "مشترك": 1,
    "اللقاء": 1,
    "اللقاءات": 1,
    "المعينون": 1,
    "الواجبات": 1,
    "الملكية": 1,
    "يكلف": 1,
    "للإنضمام": 1,
    "ينضم": 1,
    "الانضمام": 1,
    "إستيعابي": 1,
    "تمثيلي": 1,
    "مترابط": 1,
    "ربط": 1,
    "الربط": 1,
    "ترابطي": 1,
    "الجمعيات": 1,
    "بترابط": 1,
    "ترابطيات": 1,
    "مترابطون": 1,
    "السجع": 1,
    "مسجوع": 1,
    "سجعي": 1,
    "منوع": 1,
    "التنويع": 1,
    "التشكيلة": 1,
    "التشكيلات": 1,
    "ينوع": 1,
    "التخفيف": 1,
    "بطريق": 1,
    "الافتراض": 1,
    "افترض": 1,
    "مفترض": 1,
    "يفترض": 1,
    "الفرض": 1,
    "الفروض": 1,
    "ثقة": 1,
    "بالنفس": 1,
    "مدفوع": 1,
    "للتأمين": 1,
    "واثق": 1,
    "بالتأكيد": 1,
    "المؤمن": 1,
    "المؤمنون": 1,
    "بثقة": 1,
    "الإمبراطورية": 1,
    "الآشورية": 1,
    "مستقر": 1,
    "أستاير": 1,
    "عائلة": 1,
    "عشتروت": 1,
    "الأستاتين": 1,
    "زهرة": 1,
    "النجمة": 1,
    "الحجر": 1,
    "النجمي": 1,
    "الأستريسك": 1,
    "النجوم": 1,
    "الكويكب": 1,
    "نجمي": 1,
    "الكويكبات": 1,
    "زهور": 1,
    "الربو": 1,
    "داء": 1,
    "بالربو": 1,
    "ربوي": 1,
    "باختناق": 1,
    "إستجمات": 1,
    "بزوغان": 1,
    "النظر": 1,
    "الإستجماتيزم": 1,
    "مستيقظ": 1,
    "مدهش": 1,
    "الدهشة": 1,
    "أستور": 1,
    "أستوريا": 1,
    "الإذهال": 1,
    "منفرج": 1,
    "الساقين": 1,
    "بالنجوم": 1,
    "لاذعات": 1,
    "لواذع": 1,
    "الأوعية": 1,
    "الدواء": 1,
    "القابض": 1,
    "قابضا": 1,
    "للأنسجة": 1,
    "القابضة": 1,
    "مرصد": 1,
    "مراصد": 1,
    "الأسطرلاب": 1,
    "الأسطرلابات": 1,
    "المنجمون": 1,
    "تنجيمي": 1,
    "علاقة": 1,
    "بالتنجيم": 1,
    "التنجيم": 1,
    "بملاحة": 1,
    "الملاحة": 1,
    "الفضائية": 1,
    "رواد": 1,
    "الفلك": 1,
    "ضخم": 1,
    "فلكيا": 1,
    "بالفيزياء": 1,
    "الفلكية": 1,
    "الفيزياء": 1,
    "ذكي": 1,
    "بذكاء": 1,
    "أسونسيون": 1,
    "إربا": 1,
    "اللجوء": 1,
    "الملاجئ": 1,
    "متناسق": 1,
    "متماثلة": 1,
    "التناسق": 1,
    "معد": 1,
    "معدية": 1,
    "الخط": 1,
    "المقارب": 1,
    "الخطوط": 1,
    "التزامن": 1,
    "تزامني": 1,
    "متزامن": 1,
    "اللاتزامنية": 1,
    "أتالانتا": 1,
    "أتاري": 1,
    "الأتافيزم": 1,
    "الأتافيزمي": 1,
    "الترنحات": 1,
    "أتشيسن": 1,
    "أكل": 1,
    "وقتي": 1,
    "أثاباسكان": 1,
    "الإلحاد": 1,
    "إلحادي": 1,
    "الأثيني": 1,
    "الأثينيون": 1,
    "عطشان": 1,
    "الرياضي": 1,
    "الرياضيون": 1,
    "رياضي": 1,
    "رياضية": 1,
    "اهتمام": 1,
    "بالرياضة": 1,
    "ألعاب": 1,
    "القوى": 1,
    "أتكينز": 1,
    "أتكينسون": 1,
    "أطلنطا": 1,
    "أتلانتيز": 1,
    "أطلنطي": 1,
    "أطلانتيكا": 1,
    "أطلنتس": 1,
    "الأطلس": 1,
    "الأطالس": 1,
    "بالجو": 1,
    "الظواهر": 1,
    "الجزر": 1,
    "الذرة": 1,
    "ذري": 1,
    "بالذرة": 1,
    "الانقسام": 1,
    "ذرات": 1,
    "فتت": 1,
    "يفتت": 1,
    "التفتيت": 1,
    "الذرات": 1,
    "حر": 1,
    "كفر": 1,
    "التعويض": 1,
    "يكفر": 1,
    "التكفير": 1,
    "قمة": 1,
    "أتريس": 1,
    "أتريا": 1,
    "الأذين": 1,
    "فظيع": 1,
    "بشع": 1,
    "البشاعات": 1,
    "البشاعة": 1,
    "ضامر": 1,
    "ضمر": 1,
    "الضمور": 1,
    "الأتروبين": 1,
    "أتروبس": 1,
    "ألحق": 1,
    "معرض": 1,
    "للمصادرة": 1,
    "ملحقون": 1,
    "الملحقون": 1,
    "التثبيت": 1,
    "الهجوم": 1,
    "للهجوم": 1,
    "يحرز": 1,
    "إحرازه": 1,
    "الإحراز": 1,
    "بمصادرة": 1,
    "ممتلكات": 1,
    "أحرز": 1,
    "محرز": 1,
    "محرزون": 1,
    "النيل": 1,
    "ينال": 1,
    "دن": 1,
    "أدان": 1,
    "الإدانة": 1,
    "يدين": 1,
    "عطر": 1,
    "الورد": 1,
    "عطور": 1,
    "المحاولة": 1,
    "للمحاولة": 1,
    "محاول": 1,
    "محاولون": 1,
    "المحاولات": 1,
    "احضر": 1,
    "الحاضرون": 1,
    "الخادم": 1,
    "الخدم": 1,
    "حضر": 1,
    "حاضرون": 1,
    "يحضر": 1,
    "سيكلوجية": 1,
    "اضعف": 1,
    "أضعف": 1,
    "يضعف": 1,
    "الإضعاف": 1,
    "المخفف": 1,
    "المخففات": 1,
    "دل": 1,
    "الشاهد": 1,
    "الدلالة": 1,
    "يدل": 1,
    "السندرة": 1,
    "أتيكا": 1,
    "السندرات": 1,
    "مرتدي": 1,
    "ثياب": 1,
    "الارتداء": 1,
    "المواقف": 1,
    "اتجاهي": 1,
    "يتصنع": 1,
    "التصنع": 1,
    "المحامون": 1,
    "محاماة": 1,
    "اجذب": 1,
    "منجذب": 1,
    "جذب": 1,
    "الجذب": 1,
    "الجذاب": 1,
    "الجذابون": 1,
    "يجذب": 1,
    "نسب": 1,
    "المسبب": 1,
    "مسند": 1,
    "الاستنزاف": 1,
    "متآكل": 1,
    "الإستنزافات": 1,
    "أقلم": 1,
    "معتاد": 1,
    "أتواتر": 1,
    "أتود": 1,
    "الإجنبي": 1,
    "مقابل": 1,
    "القيام": 1,
    "ببعض": 1,
    "المنزلية": 1,
    "الحانة": 1,
    "أوبري": 1,
    "بني": 1,
    "أوكلاند": 1,
    "المزاد": 1,
    "باع": 1,
    "بالمزاد": 1,
    "العلني": 1,
    "بائع": 1,
    "باعة": 1,
    "بيع": 1,
    "المزادات": 1,
    "جريء": 1,
    "بجراءة": 1,
    "الجرأة": 1,
    "السمعيات": 1,
    "السمع": 1,
    "مسموع": 1,
    "الجمهور": 1,
    "الجماهير": 1,
    "بصري": 1,
    "الترددات": 1,
    "السمعية": 1,
    "تخطيط": 1,
    "بعلم": 1,
    "متخصصوا": 1,
    "لقوة": 1,
    "شريط": 1,
    "التسجيل": 1,
    "سمعية": 1,
    "يراجع": 1,
    "راجع": 1,
    "المراجعة": 1,
    "أدى": 1,
    "اختبارا": 1,
    "اختبار": 1,
    "الاختبارات": 1,
    "المراجع": 1,
    "القاعة": 1,
    "القاعات": 1,
    "مراجعات": 1,
    "أودري": 1,
    "أودوبون": 1,
    "أويرباتش": 1,
    "أوف": 1,
    "أغسطس": 1,
    "قذر": 1,
    "الخرامة": 1,
    "الخرامات": 1,
    "الأوجايت": 1,
    "يزداد": 1,
    "للازدياد": 1,
    "ازدياد": 1,
    "ازديادات": 1,
    "مزيد": 1,
    "مزداد": 1,
    "الازدياد": 1,
    "تنبأ": 1,
    "النذيرة": 1,
    "يتنبأ": 1,
    "أوجستا": 1,
    "أغسطسي": 1,
    "أوغسطين": 1,
    "مهيب": 1,
    "الهيبة": 1,
    "أشهر": 1,
    "آب/أغسطس": 1,
    "أوغسطس": 1,
    "الأوك": 1,
    "العمة": 1,
    "أونتلي": 1,
    "العمات": 1,
    "الهالة": 1,
    "الهالات": 1,
    "أذني": 1,
    "ذهبي": 1,
    "أوريليوس": 1,
    "الأوريومايسين": 1,
    "الذو": 1,
    "الأعنة": 1,
    "الثور": 1,
    "الفجر": 1,
    "فجري": 1,
    "أوسكويتز": 1,
    "افحص": 1,
    "فحص": 1,
    "يفحص": 1,
    "التسمع": 1,
    "الطالع": 1,
    "رعاية": 1,
    "مبشر": 1,
    "السعادة": 1,
    "الأوستينيت": 1,
    "بسيط": 1,
    "البساطة": 1,
    "أوستن": 1,
    "جنوبي": 1,
    "الأسترالي": 1,
    "الأستراليون": 1,
    "يميل": 1,
    "الجنوب": 1,
    "النمسا": 1,
    "النمساوي": 1,
    "النمساويون": 1,
    "سيادة": 1,
    "مطلقة": 1,
    "يصدق": 1,
    "التوثيقات": 1,
    "الموثق": 1,
    "الموثقون": 1,
    "الأصالة": 1,
    "المؤلف": 1,
    "ألف": 1,
    "المؤلفة": 1,
    "المؤلفات": 1,
    "التأليف": 1,
    "المتسلط": 1,
    "التسلط": 1,
    "الإستبدادية": 1,
    "موضع": 1,
    "متسلط": 1,
    "المسئولون": 1,
    "السلطة": 1,
    "التصريح": 1,
    "التصاريح": 1,
    "وكل": 1,
    "المفوض": 1,
    "المفوضون": 1,
    "يوكل": 1,
    "التوكيل": 1,
    "المؤلفون": 1,
    "التأليفات": 1,
    "بالتوحد": 1,
    "السيارة": 1,
    "تنغلق": 1,
    "تدمر": 1,
    "ترفع": 1,
    "إجابة": 1,
    "تكتشف": 1,
    "تعود": 1,
    "تنسيق": 1,
    "تلقائي": 1,
    "تشغيل": 1,
    "آلي": 1,
    "التلقائية": 1,
    "بسيرة": 1,
    "المرء": 1,
    "الذاتية": 1,
    "كسيرة": 1,
    "ذاتية": 1,
    "السير": 1,
    "السيرة": 1,
    "الآلية": 1,
    "السيارات": 1,
    "الأوتوكلاف": 1,
    "اوتوكود": 1,
    "موازاة": 1,
    "تلقائية": 1,
    "موازي": 1,
    "تلقائيا": 1,
    "اكتمل": 1,
    "تكملة": 1,
    "آلية": 1,
    "تصحيح": 1,
    "يربط": 1,
    "تقائيا": 1,
    "التلقائي": 1,
    "ترابطات": 1,
    "الدول": 1,
    "المقهورة": 1,
    "مستبد": 1,
    "استبدادي": 1,
    "مستبدة": 1,
    "المتسلطون": 1,
    "تنازلي": 1,
    "اكتشاف": 1,
    "اتصال": 1,
    "المتصل": 1,
    "ذاتي": 1,
    "التعليم": 1,
    "متعلم": 1,
    "تعبئة": 1,
    "الاستشعاع": 1,
    "نموذج": 1,
    "اختيار": 1,
    "التوقيع": 1,
    "وقع": 1,
    "توقيعي": 1,
    "التوقيعات": 1,
    "التنويم": 1,
    "المغناطيسي": 1,
    "تزايدي": 1,
    "فهرس": 1,
    "آليا": 1,
    "الملقم": 1,
    "الآلي": 1,
    "المطعم": 1,
    "الباردون": 1,
    "الأتمتة": 1,
    "الآليات": 1,
    "إرادي": 1,
    "البارد": 1,
    "المطاعم": 1,
    "تشكيلي": 1,
    "التشكل": 1,
    "بالسيارات": 1,
    "المبحر": 1,
    "المبحرات": 1,
    "مستقل": 1,
    "استقلالي": 1,
    "الاستقلالي": 1,
    "الاستقلاليون": 1,
    "الذاتي": 1,
    "الطيارات": 1,
    "تشريح": 1,
    "ارتدادي": 1,
    "يعيد": 1,
    "الإعادة": 1,
    "الإيحائية": 1,
    "الإيجاء": 1,
    "الإيحاء": 1,
    "درج": 1,
    "الالتفاف": 1,
    "الخريف": 1,
    "خريفي": 1,
    "خريفيا": 1,
    "الخريفات": 1,
    "انفع": 1,
    "التوفر": 1,
    "الوفرة": 1,
    "نفع": 1,
    "النفع": 1,
    "ينفع": 1,
    "انهار": 1,
    "الانهيار": 1,
    "أفانت": 1,
    "المعاصرون": 1,
    "الطمع": 1,
    "الجشع": 1,
    "طماع": 1,
    "بطمع": 1,
    "تجسد": 1,
    "الآلهة": 1,
    "تجسدات": 1,
    "مرحبا": 1,
    "انتقم": 1,
    "المنتقم": 1,
    "المنتقمون": 1,
    "ينتقم": 1,
    "الانتقام": 1,
    "آفينتين": 1,
    "المتوسط": 1,
    "حسب": 1,
    "متوسط": 1,
    "الوسطية": 1,
    "المتوسطات": 1,
    "حساب": 1,
    "بنفور": 1,
    "متجنب": 1,
    "تجنب": 1,
    "التجنب": 1,
    "أفيري": 1,
    "الأفستا": 1,
    "طيري": 1,
    "الأقفاص": 1,
    "مربي": 1,
    "مربو": 1,
    "القفص": 1,
    "طر": 1,
    "مطامع": 1,
    "بإلكترونيات": 1,
    "إلكترونيات": 1,
    "أفيس": 1,
    "الأفوكادو": 1,
    "الهوايات": 1,
    "الطائر": 1,
    "المائي": 1,
    "أفوجادرو": 1,
    "اجتنابه": 1,
    "يمكن": 1,
    "تجنبه": 1,
    "الاجتناب": 1,
    "متجنبون": 1,
    "الأوزان": 1,
    "آفون": 1,
    "بصراحة": 1,
    "مصرح": 1,
    "العم": 1,
    "انتظر": 1,
    "ينتظر": 1,
    "استيقظ": 1,
    "صحا": 1,
    "أيقظ": 1,
    "يستيقظ": 1,
    "الاستيقاظ": 1,
    "الجائزة": 1,
    "مستحق": 1,
    "مكافأة": 1,
    "منح": 1,
    "مكافئ": 1,
    "مكافئون": 1,
    "المكافئة": 1,
    "الجوائز": 1,
    "مدرك": 1,
    "الوعي": 1,
    "مغمور": 1,
    "البعد": 1,
    "أهاب": 1,
    "رفع": 1,
    "بفظاعة": 1,
    "الفظاعة": 1,
    "لحظة": 1,
    "الإهابة": 1,
    "الشوكة": 1,
    "المظلة": 1,
    "مغطى": 1,
    "بمظلة": 1,
    "المظلات": 1,
    "الفأس": 1,
    "طرد": 1,
    "الفؤوس": 1,
    "محوري": 1,
    "محوريا": 1,
    "إبطي": 1,
    "الطرد": 1,
    "القيم": 1,
    "البديهية": 1,
    "بديهي": 1,
    "بديهية": 1,
    "بديهيات": 1,
    "تبسيط": 1,
    "يبسط": 1,
    "بسط": 1,
    "البديهيات": 1,
    "أكسيون": 1,
    "أكسيونات": 1,
    "المحاور": 1,
    "قنفذ": 1,
    "قنافذ": 1,
    "عصبية": 1,
    "أير": 1,
    "أيرز": 1,
    "المصوتون": 1,
    "بنعم": 1,
    "أيليسبري": 1,
    "الأزالي": 1,
    "أزيربيدجاني": 1,
    "أذربيجان": 1,
    "الأذريبيجاني": 1,
    "الأذريبيجانية": 1,
    "السمت": 1,
    "السمتي": 1,
    "الأزيموث": 1,
    "الآزوت": 1,
    "نيتروجيني": 1,
    "الأزتكي": 1,
    "أزول": 1,
    "الأزرق": 1,
    "السماوي": 1,
    "ضغط": 1,
    "المرتفع": 1,
    "الأمحارك": 1,
    "الاثيوبية": 1,
    "الرقمي": 1,
    "تعريب": 1,
    "اللدغ": 1,
    "الشوك": 1,
    "حافتين": 1,
    "رأسين": 1,
    "يعتمد": 1,
    "الإختصارات": 1,
    "مميز": 1,
    "لايؤمن": 1,
    "كافر": 1,
    "انبوب": 1,
    "لتصريف": 1,
    "المياه": 1,
    "الخطاب": 1,
    "الرسمي": 1,
    "متلاصقين": 1,
    "ملتحمين": 1,
    "حلية": 1,
    "فلكية": 1,
    "المسافات": 1,
    "وتعادل": 1,
    "البكتيريا": 1,
    "يتأثر": 1,
    "بالبكتيريا": 1,
    "أعقف": 1,
    "المغتابون": 1,
    "يغتاب": 1,
    "اللوح": 1,
    "الخلفي": 1,
    "العمود": 1,
    "الفقري": 1,
    "الفقرية": 1,
    "مرهق": 1,
    "السلسلة": 1,
    "المرجعية": 1,
    "مستتر": 1,
    "التراجع": 1,
    "التراجعات": 1,
    "الخلفية": 1,
    "الخلفيات": 1,
    "رجع": 1,
    "لاعبو": 1,
    "أعد": 1,
    "ملئ": 1,
    "أعيد": 1,
    "إعادة": 1,
    "جئ": 1,
    "بنتيجة": 1,
    "عكسية": 1,
    "جاء": 1,
    "يجيء": 1,
    "مجيء": 1,
    "الطاولة": 1,
    "الضربة": 1,
    "بظهر": 1,
    "اللكمة": 1,
    "الضربات": 1,
    "المساندة": 1,
    "البطانات": 1,
    "الثورة": 1,
    "ثائر": 1,
    "وصلة": 1,
    "عودة": 1,
    "القفل": 1,
    "التل": 1,
    "المتأخرة": 1,
    "الأمر": 1,
    "المؤجل": 1,
    "إلغاء": 1,
    "تعديلات": 1,
    "العمليات": 1,
    "المخفقة": 1,
    "حقيبة": 1,
    "الرحال": 1,
    "الرحل": 1,
    "حقائب": 1,
    "مواسير": 1,
    "خلفية": 1,
    "لوحة": 1,
    "الكترونية": 1,
    "معززة": 1,
    "لوحات": 1,
    "لوح": 1,
    "حامي": 1,
    "للظهر": 1,
    "ألواح": 1,
    "حامية": 1,
    "رذاذ": 1,
    "مرتد": 1,
    "مبعثرا": 1,
    "ارتداد": 1,
    "مبعثر": 1,
    "بعثرة": 1,
    "مرتدة": 1,
    "الشرطة": 1,
    "بشرطة": 1,
    "الشرط": 1,
    "تردى": 1,
    "يتردى": 1,
    "المنحرف": 1,
    "المنحرفون": 1,
    "التردي": 1,
    "زر": 1,
    "لإعادة": 1,
    "الكتابة": 1,
    "حذف": 1,
    "أزرار": 1,
    "الحذف": 1,
    "طاعن": 1,
    "الطعن": 1,
    "بالظهر": 1,
    "الكواليس": 1,
    "أدراج": 1,
    "خيط": 1,
    "يخيط": 1,
    "تخييط": 1,
    "المصد": 1,
    "المصدات": 1,
    "سباحة": 1,
    "سباحات": 1,
    "تتبع": 1,
    "تراجع": 1,
    "متعقب": 1,
    "متعقبون": 1,
    "يتراجع": 1,
    "متخلف": 1,
    "التخلفات": 1,
    "جرف": 1,
    "خلفي": 1,
    "المعزل": 1,
    "المعزلات": 1,
    "النائية": 1,
    "المنعزل": 1,
    "المنعزلون": 1,
    "الفناء": 1,
    "الأفنية": 1,
    "لحم": 1,
    "الخنزير": 1,
    "جرثومي": 1,
    "بكتيري": 1,
    "للجراثيم": 1,
    "الجراثيم": 1,
    "مبيدات": 1,
    "بكتريولوجي": 1,
    "الجرثوم": 1,
    "زايد": 1,
    "بادن": 1,
    "الشارة": 1,
    "البادجر": 1,
    "ألح": 1,
    "الإلحاح": 1,
    "الشارات": 1,
    "التأشير": 1,
    "المزاح": 1,
    "مازح": 1,
    "ممازحة": 1,
    "الوعرة": 1,
    "الأراضي": 1,
    "خارجون": 1,
    "تنس": 1,
    "الريشة": 1,
    "الشتم": 1,
    "السوء": 1,
    "مساوئ": 1,
    "بفن": 1,
    "الحاجز": 1,
    "حير": 1,
    "المحير": 1,
    "المحيرون": 1,
    "الحواجز": 1,
    "التحيير": 1,
    "بحيرة": 1,
    "الحقيبة": 1,
    "البلياردو": 1,
    "الخبز": 1,
    "المقدار": 1,
    "مقادير": 1,
    "الأمتعة": 1,
    "عربة": 1,
    "الحقائب": 1,
    "حمال": 1,
    "حمالو": 1,
    "تدلى": 1,
    "معدوا": 1,
    "فضفضة": 1,
    "فضفاضات": 1,
    "بفضفضة": 1,
    "التهدل": 1,
    "التدلي": 1,
    "فضفاض": 1,
    "بغداد": 1,
    "باجلي": 1,
    "مزماري": 1,
    "الزمارون": 1,
    "مزمار": 1,
    "القربة": 1,
    "الرغيف": 1,
    "الفرنسي": 1,
    "هراء": 1,
    "باهاما": 1,
    "الباهاما": 1,
    "البحرين": 1,
    "الكفالة": 1,
    "إطلاق": 1,
    "سراح": 1,
    "بكفالة": 1,
    "أطلق": 1,
    "بضمان": 1,
    "المنزحة": 1,
    "الأسوار": 1,
    "المأمور": 1,
    "المأمورون": 1,
    "دائرة": 1,
    "النفوذ": 1,
    "بايلي": 1,
    "الإنزال": 1,
    "الكفالات": 1,
    "الضامن": 1,
    "الضامنون": 1,
    "بينايت": 1,
    "بيرد": 1,
    "اصطاد": 1,
    "الاصطياد": 1,
    "الطعوم": 1,
    "الجوخ": 1,
    "باك": 1,
    "اخبز": 1,
    "خبز": 1,
    "الباكليت": 1,
    "الخباز": 1,
    "المخابز": 1,
    "الخبازون": 1,
    "بيكرسفيلد": 1,
    "المخبز": 1,
    "يخبز": 1,
    "بختياري": 1,
    "المعجنات": 1,
    "البقلاوة": 1,
    "باكو": 1,
    "الآلة": 1,
    "الوترية": 1,
    "التوازن": 1,
    "توازن": 1,
    "الموازن": 1,
    "الموازنون": 1,
    "الموازين": 1,
    "بلبووة": 1,
    "بلكونة": 1,
    "البلكونات": 1,
    "البلكونة": 1,
    "أصلع": 1,
    "صلعا": 1,
    "اللغو": 1,
    "الأصلع": 1,
    "باندفاع": 1,
    "ذووا": 1,
    "الرؤوس": 1,
    "الصلعاء": 1,
    "بقسوة": 1,
    "الصلع": 1,
    "بالدوين": 1,
    "الحزمة": 1,
    "البلين": 1,
    "حاقد": 1,
    "بحقد": 1,
    "الهلاك": 1,
    "رزمة": 1,
    "مربوطة": 1,
    "صناع": 1,
    "الرزم": 1,
    "رزم": 1,
    "بالفور": 1,
    "بالي": 1,
    "البالينيز": 1,
    "العارضة": 1,
    "بلقاني": 1,
    "تقسيم": 1,
    "البلقنة": 1,
    "دول": 1,
    "البلقان": 1,
    "تقاعس": 1,
    "بالكر": 1,
    "أعند": 1,
    "الحران": 1,
    "التقاعس": 1,
    "العارضات": 1,
    "القصة": 1,
    "المنشد": 1,
    "المنشدون": 1,
    "أغاني": 1,
    "شعبية": 1,
    "الشعبية": 1,
    "القصص": 1,
    "بالارد": 1,
    "الثقل": 1,
    "بالأثقال": 1,
    "الأثقال": 1,
    "تكور": 1,
    "بالر": 1,
    "راقصة": 1,
    "الباليه": 1,
    "راقصات": 1,
    "للباليه": 1,
    "بالباليه": 1,
    "التكور": 1,
    "قذائفي": 1,
    "قذائفيا": 1,
    "القذائف": 1,
    "انتفخ": 1,
    "ركوب": 1,
    "راكب": 1,
    "راكبوا": 1,
    "الاقتراع": 1,
    "اقترع": 1,
    "مقترع": 1,
    "الاقتراعات": 1,
    "اللاعب": 1,
    "اللاعبون": 1,
    "قلم": 1,
    "الحبر": 1,
    "قاعة": 1,
    "الرقص": 1,
    "قاعات": 1,
    "الكرات": 1,
    "الضجة": 1,
    "إنعاشا": 1,
    "بلسمي": 1,
    "الاعتدال": 1,
    "منعش": 1,
    "البلسا": 1,
    "البلسم": 1,
    "الدهانات": 1,
    "بلطيقي": 1,
    "بالتيمور": 1,
    "بالتيموري": 1,
    "الدرابزين": 1,
    "الدرابزينات": 1,
    "بالزاك": 1,
    "بام": 1,
    "باماكو": 1,
    "بامبيرجر": 1,
    "بامباي": 1,
    "الخيزران": 1,
    "اخدع": 1,
    "خدع": 1,
    "الخداع": 1,
    "الخدعة": 1,
    "الحظر": 1,
    "بنك": 1,
    "عادي": 1,
    "التفاهات": 1,
    "التفاهة": 1,
    "عادية": 1,
    "الموزة": 1,
    "بانبري": 1,
    "بانكروفت": 1,
    "الفرقة": 1,
    "الضمادة": 1,
    "ضمد": 1,
    "مضمد": 1,
    "الضمادات": 1,
    "التضميد": 1,
    "البلاستر": 1,
    "المنديل": 1,
    "الصندوق": 1,
    "الصناديق": 1,
    "العصابة": 1,
    "بانديكس": 1,
    "طوق": 1,
    "مرافق": 1,
    "تقاذف": 1,
    "يتقاذف": 1,
    "التطويق": 1,
    "قاطع": 1,
    "قطاع": 1,
    "المايسترو": 1,
    "أحزمة": 1,
    "الرصاص": 1,
    "الموجة": 1,
    "مصفاة": 1,
    "الفرق": 1,
    "المسرح": 1,
    "المسارح": 1,
    "العربة": 1,
    "العربات": 1,
    "متقوس": 1,
    "التقاذف": 1,
    "مصدر": 1,
    "أذى": 1,
    "التوت": 1,
    "السام": 1,
    "مفجع": 1,
    "الأذى": 1,
    "الدوي": 1,
    "دوي": 1,
    "أحدث": 1,
    "ضجيجا": 1,
    "السجق": 1,
    "بانكوك": 1,
    "بنجلاديش": 1,
    "الأساور": 1,
    "بانجور": 1,
    "بانجوي": 1,
    "إصدار": 1,
    "إنف": 1,
    "نفي": 1,
    "ثافي": 1,
    "ينفي": 1,
    "النفي": 1,
    "البانجو": 1,
    "دفاتر": 1,
    "الرصيد": 1,
    "أودع": 1,
    "مالا": 1,
    "المصرفي": 1,
    "المصرفيون": 1,
    "البنوك": 1,
    "البنكنوت": 1,
    "التمويل": 1,
    "مول": 1,
    "التمويلات": 1,
    "المفلس": 1,
    "الإفلاس": 1,
    "أفقر": 1,
    "الإفقار": 1,
    "مفلس": 1,
    "المفلسون": 1,
    "حظر": 1,
    "الراية": 1,
    "الرايات": 1,
    "الوليمة": 1,
    "أولم": 1,
    "المضيف": 1,
    "المضيفون": 1,
    "استمتاع": 1,
    "بالطعام": 1,
    "اقامة": 1,
    "الولائم": 1,
    "الشؤم": 1,
    "البنطم": 1,
    "مزح": 1,
    "ممازح": 1,
    "ممازحون": 1,
    "المزح": 1,
    "بمزاح": 1,
    "البانتو": 1,
    "الشجر": 1,
    "الاستوائي": 1,
    "المعمد": 1,
    "التعميد": 1,
    "بالتعميد": 1,
    "بمعمودية": 1,
    "بابتست": 1,
    "مياه": 1,
    "ماء": 1,
    "المعمدون": 1,
    "اعمد": 1,
    "عمد": 1,
    "يعمد": 1,
    "رسم": 1,
    "بياني": 1,
    "خطي": 1,
    "باربادوس": 1,
    "باربارا": 1,
    "الهمجي": 1,
    "بربري": 1,
    "الهمجيون": 1,
    "متوحش": 1,
    "بربرية": 1,
    "البربرية": 1,
    "الوحشي": 1,
    "الوحشية": 1,
    "اجعل": 1,
    "متوحشا": 1,
    "يجعل": 1,
    "متوحشة": 1,
    "فظاعة": 1,
    "الشواية": 1,
    "شوى": 1,
    "الشوايات": 1,
    "الشوي": 1,
    "الحديد": 1,
    "الحدائد": 1,
    "الحلاق": 1,
    "حلق": 1,
    "الحلق": 1,
    "الباربريس": 1,
    "الحلاقون": 1,
    "الغناء": 1,
    "مهدئ": 1,
    "منوم": 1,
    "المهدئات": 1,
    "باربر": 1,
    "ذقن": 1,
    "سلك": 1,
    "الأسلاك": 1,
    "الشائكة": 1,
    "برشلونة": 1,
    "باركلاي": 1,
    "شفرة": 1,
    "التعرف": 1,
    "الشاعر": 1,
    "بالشعر": 1,
    "الشعراء": 1,
    "عاري": 1,
    "حافي": 1,
    "مكشوف": 1,
    "سرج": 1,
    "كشف": 1,
    "متبجح": 1,
    "بتبجح": 1,
    "الوقاحات": 1,
    "أعزل": 1,
    "بالكاد": 1,
    "ولدت": 1,
    "الخلو": 1,
    "عريا": 1,
    "يكشف": 1,
    "أزيار": 1,
    "الحانات": 1,
    "زير": 1,
    "البيعة": 1,
    "الرابحة": 1,
    "ساوم": 1,
    "المساوم": 1,
    "المساومون": 1,
    "التفاوض": 1,
    "البيعات": 1,
    "العبارة": 1,
    "تخبط": 1,
    "الملاحون": 1,
    "العبارات": 1,
    "التخبط": 1,
    "الكشف": 1,
    "الباريتون": 1,
    "الباريوم": 1,
    "النباح": 1,
    "نبح": 1,
    "الجرسون": 1,
    "المنادي": 1,
    "المنادون": 1,
    "النبح": 1,
    "الشعير": 1,
    "بارلو": 1,
    "النادلة": 1,
    "النادلات": 1,
    "الشونة": 1,
    "بارناباس": 1,
    "الإوز": 1,
    "بارنأرد": 1,
    "بارنيز": 1,
    "بارنيت": 1,
    "ملء": 1,
    "بارنهأرد": 1,
    "الشون": 1,
    "بارنستابل": 1,
    "أخطب": 1,
    "متنقلا": 1,
    "المتنقل": 1,
    "المتنقلون": 1,
    "خطابة": 1,
    "البارومتر": 1,
    "البارومترات": 1,
    "بارومتري": 1,
    "محراري": 1,
    "البارون": 1,
    "البارونة": 1,
    "البارونات": 1,
    "البارونيت": 1,
    "باروني": 1,
    "منازل": 1,
    "منزلة": 1,
    "بر": 1,
    "اهتف": 1,
    "الفريق": 1,
    "إسكان": 1,
    "ثكنات": 1,
    "الثكنة": 1,
    "البركودة": 1,
    "حجز": 1,
    "السدود": 1,
    "الحجز": 1,
    "الإهمال": 1,
    "بار": 1,
    "قضبان": 1,
    "البرميل": 1,
    "إنطلاق": 1,
    "البراميل": 1,
    "قاحل": 1,
    "مقبولة": 1,
    "القحل": 1,
    "عقيم": 1,
    "(جمع)": 1,
    "باريت": 1,
    "المشبك": 1,
    "المشابك": 1,
    "المتراس": 1,
    "حصن": 1,
    "المتاريس": 1,
    "التحصين": 1,
    "باري": 1,
    "سيديد": 1,
    "بارنجر": 1,
    "بارنجتون": 1,
    "المحام": 1,
    "بارون": 1,
    "البارات": 1,
    "عربات": 1,
    "باريمور": 1,
    "مقعد": 1,
    "للبار": 1,
    "مقاعد": 1,
    "بارستو": 1,
    "اسق": 1,
    "النادل": 1,
    "النوادل": 1,
    "المقايضة": 1,
    "قايض": 1,
    "المقايض": 1,
    "المقايضون": 1,
    "المقايضات": 1,
    "بارث": 1,
    "بارثولومو": 1,
    "بارتليت": 1,
    "بارتوك": 1,
    "بارتون": 1,
    "مركز": 1,
    "باس": 1,
    "أساسي": 1,
    "باسالي": 1,
    "البازلت": 1,
    "بازلتي": 1,
    "باسكوم": 1,
    "رقم": 1,
    "البيسبول": 1,
    "أحادي": 1,
    "الألواح": 1,
    "أسس": 1,
    "باسل": 1,
    "أساس": 1,
    "اللاأساس": 1,
    "الأساس": 1,
    "الأسس": 1,
    "بدناءة": 1,
    "الباسيمان": 1,
    "الباسيمين": 1,
    "البدروم": 1,
    "البدرومات": 1,
    "الخسة": 1,
    "القاعدات": 1,
    "الباشا": 1,
    "البلطجي": 1,
    "البلطجيون": 1,
    "اللكمات": 1,
    "خجول": 1,
    "بخجل": 1,
    "أساسا": 1,
    "الدعاميات": 1,
    "باسي": 1,
    "الريحان": 1,
    "باسيلار": 1,
    "كاتدرائية": 1,
    "البازيليكا": 1,
    "البازيليسق": 1,
    "الرياحين": 1,
    "الحوض": 1,
    "حوضي": 1,
    "التأسيس": 1,
    "الأحواض": 1,
    "تمتع": 1,
    "السلة": 1,
    "كرة": 1,
    "كرات": 1,
    "السلات": 1,
    "التمتع": 1,
    "مستقعد": 1,
    "القميص": 1,
    "الباس": 1,
    "الباست": 1,
    "باسيت": 1,
    "السرير": 1,
    "الأسرة": 1,
    "الباص": 1,
    "الباسوون": 1,
    "الزيزفون": 1,
    "أميركي": 1,
    "اللحاء": 1,
    "النذل": 1,
    "الإفساد": 1,
    "يحط": 1,
    "شرعي": 1,
    "الأنذال": 1,
    "غط": 1,
    "بالحساء": 1,
    "غطى": 1,
    "حائك": 1,
    "الألحية": 1,
    "باستيليز": 1,
    "ضربة": 1,
    "العصا": 1,
    "ضربات": 1,
    "تغطية": 1,
    "المعقل": 1,
    "محصن": 1,
    "المعاقل": 1,
    "الخفاش": 1,
    "باتافيا": 1,
    "الدفعة": 1,
    "مختلق": 1,
    "بالدفعة": 1,
    "باتشلدر": 1,
    "الدفعات": 1,
    "اهدأ": 1,
    "قوارب": 1,
    "باتيمان": 1,
    "باتر": 1,
    "يهدأ": 1,
    "الحمام": 1,
    "الاستحمام": 1,
    "استحم": 1,
    "السباح": 1,
    "السباحون": 1,
    "الحمامات": 1,
    "الإسفاف": 1,
    "البشكير": 1,
    "البشاكير": 1,
    "البانيو": 1,
    "باثرست": 1,
    "الغواصة": 1,
    "طباعة": 1,
    "المنسوجات": 1,
    "الذعر": 1,
    "باتيستا": 1,
    "القماش": 1,
    "القطني": 1,
    "العصي": 1,
    "الخفافيش": 1,
    "بات": 1,
    "الكتيبة": 1,
    "الكتائب": 1,
    "الوصلة": 1,
    "تزايد": 1,
    "التزايد": 1,
    "الخليط": 1,
    "حطم": 1,
    "البطاريات": 1,
    "الأخلاط": 1,
    "البطارية": 1,
    "مشغل": 1,
    "بالبطارية": 1,
    "جنونا": 1,
    "المعركة": 1,
    "حارب": 1,
    "ميدان": 1,
    "القتال": 1,
    "ميادين": 1,
    "جبهة": 1,
    "جبهات": 1,
    "ساحة": 1,
    "ساحات": 1,
    "أبراج": 1,
    "محصنة": 1,
    "المقاتل": 1,
    "المقاتلون": 1,
    "المعارك": 1,
    "حربية": 1,
    "سفن": 1,
    "المحاربة": 1,
    "إثارة": 1,
    "الطرائد": 1,
    "بجناح": 1,
    "شاكلة": 1,
    "جناح": 1,
    "الحلي": 1,
    "الباود": 1,
    "بودلاير": 1,
    "بالثانية": 1,
    "اشارة": 1,
    "باور": 1,
    "بوهوس": 1,
    "بوستش": 1,
    "مؤلف": 1,
    "البوكسيت": 1,
    "بافاريا": 1,
    "بافاري": 1,
    "القواد": 1,
    "القوادون": 1,
    "صح": 1,
    "صاح": 1,
    "صائح": 1,
    "صائحون": 1,
    "الصياح": 1,
    "يصيح": 1,
    "باكستر": 1,
    "الخليج": 1,
    "متصاعد": 1,
    "بالخليج": 1,
    "الميريقة": 1,
    "النظرية": 1,
    "الافتراضية": 1,
    "بيلور": 1,
    "بيلي": 1,
    "الحربة": 1,
    "مطعون": 1,
    "الحراب": 1,
    "بايون": 1,
    "الجدول": 1,
    "الجداول": 1,
    "بيبورت": 1,
    "بايروث": 1,
    "الخلجان": 1,
    "البازار": 1,
    "البازارات": 1,
    "البازوكة": 1,
    "س": 1,
    "نسخة": 1,
    "أرقام": 1,
    "عشرية": 1,
    "حقوق": 1,
    "النشر": 1,
    "كن": 1,
    "بيتشكوم": 1,
    "البائع": 1,
    "المتجول": 1,
    "أرسى": 1,
    "الشواطئ": 1,
    "المربط": 1,
    "المرابط": 1,
    "الإرساء": 1,
    "الفنار": 1,
    "أرشد": 1,
    "الإرشاد": 1,
    "الفنارات": 1,
    "الخرزة": 1,
    "مطرز": 1,
    "الديكور": 1,
    "الشماس": 1,
    "الشمامسة": 1,
    "الخرز": 1,
    "المصلي": 1,
    "المستأجر": 1,
    "كالخرز": 1,
    "البيجل": 1,
    "المنقار": 1,
    "منقار": 1,
    "الكوب": 1,
    "الأكواب": 1,
    "المناقير": 1,
    "الشعاع": 1,
    "ابتسم": 1,
    "متعاطي": 1,
    "متعاطوا": 1,
    "الابتسام": 1,
    "الفول": 1,
    "الرأسه": 1,
    "مؤذية": 1,
    "الدب": 1,
    "باحتمال": 1,
    "عنب": 1,
    "اللحية": 1,
    "ملتحي": 1,
    "نمو": 1,
    "شعر": 1,
    "الوجه": 1,
    "لحية": 1,
    "اللحى": 1,
    "بيردزلي": 1,
    "الشيال": 1,
    "الشيالون": 1,
    "الاتجاهات": 1,
    "فظ": 1,
    "بجفاء": 1,
    "الدببة": 1,
    "الفرو": 1,
    "صغيرة": 1,
    "اللبأ": 1,
    "كرها": 1,
    "مغلوب": 1,
    "يصد": 1,
    "المضرب": 1,
    "المضارب": 1,
    "مبهج": 1,
    "بابتهاج": 1,
    "التقديس": 1,
    "الإبتهاج": 1,
    "أبن": 1,
    "يؤبن": 1,
    "أبنت": 1,
    "التأبين": 1,
    "الطوبى": 1,
    "الشاذ": 1,
    "بياتريس": 1,
    "الدقات": 1,
    "بوتشامب": 1,
    "نبيذ": 1,
    "بوجلاي": 1,
    "بيماونت": 1,
    "بيوريجارد": 1,
    "الأصدقاء": 1,
    "جميل": 1,
    "جميلة": 1,
    "جودة": 1,
    "التجميل": 1,
    "جمل": 1,
    "المجمل": 1,
    "يجمل": 1,
    "بيكس": 1,
    "القندس": 1,
    "خشب": 1,
    "خفيف": 1,
    "مضغوط": 1,
    "فرو": 1,
    "الأرنب": 1,
    "المصبوغ": 1,
    "للنسج": 1,
    "القنادس": 1,
    "بيفيرتون": 1,
    "بيبوب": 1,
    "البيبوب": 1,
    "ساكن": 1,
    "الإيقاف": 1,
    "أصبح": 1,
    "لأن": 1,
    "بكتل": 1,
    "بيكر": 1,
    "المثبتة": 1,
    "بيكمان": 1,
    "بيكي": 1,
    "احجب": 1,
    "حجب": 1,
    "الحجب": 1,
    "يحجب": 1,
    "اصبح": 1,
    "يصبح": 1,
    "لطخ": 1,
    "التلطيخ": 1,
    "يلطخ": 1,
    "أبهر": 1,
    "بهر": 1,
    "الإبهار": 1,
    "يبهر": 1,
    "البقة": 1,
    "البق": 1,
    "فرش": 1,
    "متطبق": 1,
    "بدر": 1,
    "نباتات": 1,
    "غطاء": 1,
    "عرقل": 1,
    "العرقلة": 1,
    "يعرقل": 1,
    "المرافقون": 1,
    "بيدفورد": 1,
    "اعتم": 1,
    "عتم": 1,
    "التعتيم": 1,
    "الفوضى": 1,
    "مستشفيات": 1,
    "المجانين": 1,
    "شريك": 1,
    "شركاء": 1,
    "البدو": 1,
    "وعاء": 1,
    "للتبول": 1,
    "بالفراش": 1,
    "أوعية": 1,
    "وسخ": 1,
    "رث": 1,
    "النوم": 1,
    "للنوم": 1,
    "القرحة": 1,
    "القرح": 1,
    "المفرش": 1,
    "المفارش": 1,
    "الزنبرك": 1,
    "الإطار": 1,
    "الإطارات": 1,
    "القش": 1,
    "أوقات": 1,
    "بيب": 1,
    "الزان": 1,
    "بيتشام": 1,
    "بيتشير": 1,
    "بيتشوود": 1,
    "اللحم": 1,
    "اشتكى": 1,
    "أضخم": 1,
    "الأضخم": 1,
    "الشكوى": 1,
    "الشكاوى": 1,
    "خلية": 1,
    "خلايا": 1,
    "النحال": 1,
    "النحالون": 1,
    "تربية": 1,
    "المباشر": 1,
    "المباشرة": 1,
    "الشياطين": 1,
    "الصفارة": 1,
    "صفر": 1,
    "التصفير": 1,
    "الصفافير": 1,
    "كؤوس": 1,
    "الشمع": 1,
    "البنجر": 1,
    "بيتهوفن": 1,
    "الخنفسة": 1,
    "الخنفسات": 1,
    "الفرار": 1,
    "صب": 1,
    "بيفاليد": 1,
    "أصاب": 1,
    "الإصابة": 1,
    "يصيب": 1,
    "ناسب": 1,
    "يناسب": 1,
    "المناسبة": 1,
    "لف": 1,
    "بالضباب": 1,
    "يلف": 1,
    "مسبقا": 1,
    "لوث": 1,
    "التلويث": 1,
    "يلوث": 1,
    "بيفريديجيند": 1,
    "المصادقة": 1,
    "الخبل": 1,
    "تسول": 1,
    "بدأ": 1,
    "أنجب": 1,
    "ينجب": 1,
    "المنجب": 1,
    "المنجبون": 1,
    "الإنجاب": 1,
    "الشحاذ": 1,
    "فاق": 1,
    "القلة": 1,
    "هزيل": 1,
    "الشحاذون": 1,
    "الفقر": 1,
    "التسول": 1,
    "ابدأ": 1,
    "البداية": 1,
    "البدايات": 1,
    "يبدأ": 1,
    "انصرف": 1,
    "البغونية": 1,
    "استكثر": 1,
    "حسود": 1,
    "يستكثر": 1,
    "الاستكثار": 1,
    "حاسدة": 1,
    "يتسول": 1,
    "المخادع": 1,
    "خادع": 1,
    "المصلحة": 1,
    "تصرف": 1,
    "متصرف": 1,
    "السلوك": 1,
    "سلوكي": 1,
    "سلوكيا": 1,
    "السلوكية": 1,
    "السلوكيات": 1,
    "بالسلوكيات": 1,
    "بالسلوك": 1,
    "السلوكي": 1,
    "اقطع": 1,
    "قطع": 1,
    "يقطع": 1,
    "رأى": 1,
    "الحمولة": 1,
    "الحمولات": 1,
    "أمر": 1,
    "طلب": 1,
    "الوصايا": 1,
    "انظر": 1,
    "مدين": 1,
    "الناظر": 1,
    "النظار": 1,
    "الرؤية": 1,
    "يرى": 1,
    "اقتضيت": 1,
    "اقتضى": 1,
    "المصالح": 1,
    "الاقتضاء": 1,
    "البيج": 1,
    "بكين": 1,
    "بيروت": 1,
    "البل": 1,
    "استفض": 1,
    "استفاض": 1,
    "الاستفاضة": 1,
    "يستفيض": 1,
    "روسيا": 1,
    "البيضاء": 1,
    "بالاروسي": 1,
    "يؤخر": 1,
    "متأخر": 1,
    "متأخرا": 1,
    "التأخير": 1,
    "التأخر": 1,
    "حبل": 1,
    "تربيط": 1,
    "التجشؤ": 1,
    "تجشأ": 1,
    "التدفق": 1,
    "عجوز": 1,
    "عجائز": 1,
    "حاصر": 1,
    "الحصار": 1,
    "يحاصر": 1,
    "بلفاست": 1,
    "الأبراج": 1,
    "البرج": 1,
    "البلجيكي": 1,
    "البلجيكيون": 1,
    "بلجيكا": 1,
    "بلغراد": 1,
    "أخف": 1,
    "أخفى": 1,
    "المعتقدات": 1,
    "ناقض": 1,
    "نواقض": 1,
    "يخفي": 1,
    "المصداقية": 1,
    "للتصديق": 1,
    "استهن": 1,
    "استهان": 1,
    "الاستخفاف": 1,
    "مستهين": 1,
    "يستهين": 1,
    "الاستهانة": 1,
    "بيليز": 1,
    "الجرس": 1,
    "بيلا": 1,
    "البيلادونا": 1,
    "بيلامي": 1,
    "بيلاتريكس": 1,
    "الفراشون": 1,
    "الحسناء": 1,
    "الحسناوات": 1,
    "بيليفيل": 1,
    "عدوانية": 1,
    "العدواني": 1,
    "بطن": 1,
    "قتالي": 1,
    "المحاربون": 1,
    "بيلينجهام": 1,
    "بيليني": 1,
    "الخوار": 1,
    "خار": 1,
    "الأجراس": 1,
    "الكراز": 1,
    "بيلوود": 1,
    "المغص": 1,
    "ممغوص": 1,
    "التوجع": 1,
    "السرة": 1,
    "السرر": 1,
    "الكبير": 1,
    "كعكة": 1,
    "محلاة": 1,
    "النفخ": 1,
    "بلمنت": 1,
    "بليت": 1,
    "خص": 1,
    "الخصوص": 1,
    "الطمأنينة": 1,
    "المتعلقات": 1,
    "أحب": 1,
    "أسفل": 1,
    "بلشزار": 1,
    "الحزام": 1,
    "مربوط": 1,
    "لصنع": 1,
    "السيور": 1,
    "بيلتون": 1,
    "الأحزمة": 1,
    "بيلتسفيل": 1,
    "بيلشي": 1,
    "بلفيدير": 1,
    "بيلفيدير": 1,
    "الكذب": 1,
    "المقدس": 1,
    "بيمان": 1,
    "تحسر": 1,
    "التحسر": 1,
    "يتحسر": 1,
    "حيرة": 1,
    "المقعد": 1,
    "المحكمة": 1,
    "المقاعد": 1,
    "المعيار": 1,
    "اختبارات": 1,
    "المعايير": 1,
    "علامات": 1,
    "خاصة": 1,
    "بأعمال": 1,
    "المساحة": 1,
    "انحن": 1,
    "للانحناء": 1,
    "محني": 1,
    "بنديكس": 1,
    "المنحنيات": 1,
    "حديث": 1,
    "الزواج": 1,
    "البينديكتين": 1,
    "الدعاء": 1,
    "الأدعية": 1,
    "دعائي": 1,
    "مبارك": 1,
    "بنيديكت": 1,
    "الإحسان": 1,
    "التبرعات": 1,
    "المتبرع": 1,
    "المتبرعون": 1,
    "المنصب": 1,
    "متصدق": 1,
    "بإحسان": 1,
    "المناصب": 1,
    "مجدي": 1,
    "الاستفادة": 1,
    "المستفيدون": 1,
    "المستفيد": 1,
    "مستفاد": 1,
    "مفيدون": 1,
    "الفائدات": 1,
    "بينيلكس": 1,
    "الكرم": 1,
    "خيري": 1,
    "العطاء": 1,
    "بينجال": 1,
    "البنغالي": 1,
    "يضلل": 1,
    "مضلل": 1,
    "بتضليل": 1,
    "حليم": 1,
    "برقة": 1,
    "بنين": 1,
    "بنيامين": 1,
    "بينيت": 1,
    "بينينجتون": 1,
    "بيني": 1,
    "بينسون": 1,
    "بينثام": 1,
    "بينتلي": 1,
    "بينتون": 1,
    "الميول": 1,
    "بينز": 1,
    "البنزيدرين": 1,
    "بنزين": 1,
    "بيوجراد": 1,
    "بياولف": 1,
    "يغطي": 1,
    "بضماد": 1,
    "ورث": 1,
    "التوريث": 1,
    "توريث": 1,
    "يورث": 1,
    "التركة": 1,
    "التركات": 1,
    "وبخ": 1,
    "يوبخ": 1,
    "التوبيخ": 1,
    "وزن": 1,
    "احرم": 1,
    "مفجوع": 1,
    "الحرمان": 1,
    "بائس": 1,
    "بيرينيسيس": 1,
    "بيريسفورد": 1,
    "البيريه": 1,
    "البيريهات": 1,
    "الجليدي": 1,
    "الشجرة": 1,
    "برغموت": 1,
    "برجن": 1,
    "بيرجر": 1,
    "برجﻻند": 1,
    "بيرجمان": 1,
    "الجليدية": 1,
    "بيرجيسون": 1,
    "بيرجستين": 1,
    "بيرجستروم": 1,
    "مزين": 1,
    "بري": 1,
    "برنجر": 1,
    "بيركيلي": 1,
    "البركيليوم": 1,
    "بيركاويتز": 1,
    "بيركشاير": 1,
    "بيركشايرز": 1,
    "برلين": 1,
    "برليني": 1,
    "برلينيون": 1,
    "بيرليوز": 1,
    "بيرليتز": 1,
    "بيرمان": 1,
    "برمودا": 1,
    "برن": 1,
    "بيرناداين": 1,
    "برنار": 1,
    "بيرنارداين": 1,
    "بيرناردينو": 1,
    "برناردو": 1,
    "بيرنيت": 1,
    "بيرنهأرد": 1,
    "بيرنيس": 1,
    "بيرني": 1,
    "بيرنيني": 1,
    "بيرنولي": 1,
    "بيرنشتاين": 1,
    "بيرا": 1,
    "منتج": 1,
    "ثمر": 1,
    "عليق": 1,
    "هائج": 1,
    "بيرت": 1,
    "المرسى": 1,
    "البرثية": 1,
    "بيرتي": 1,
    "بيرترام": 1,
    "برتراند": 1,
    "بيرويك": 1,
    "البريل": 1,
    "البيريليوم": 1,
    "بيز": 1,
    "التمس": 1,
    "يلتمس": 1,
    "بتوسل": 1,
    "حصار": 1,
    "المحاصر": 1,
    "المحاصرون": 1,
    "المكنسة": 1,
    "المكانس": 1,
    "أسكر": 1,
    "يسكر": 1,
    "سكران": 1,
    "الإسكار": 1,
    "نظارات": 1,
    "مفصل": 1,
    "بسل": 1,
    "بيسمر": 1,
    "بيسي": 1,
    "الأفضل": 1,
    "راسخ": 1,
    "أفضل": 1,
    "وحشي": 1,
    "البهيميات": 1,
    "البهيمية": 1,
    "بأسلوب": 1,
    "تقديم": 1,
    "الحث": 1,
    "يحث": 1,
    "امنح": 1,
    "الأعطية": 1,
    "معطي": 1,
    "معطون": 1,
    "المنح": 1,
    "عطاء": 1,
    "عطاءات": 1,
    "يمنح": 1,
    "التغطية": 1,
    "اعتل": 1,
    "يعتلي": 1,
    "الاعتلاء": 1,
    "اعتلى": 1,
    "بيستس": 1,
    "كتاب": 1,
    "رواجا": 1,
    "الكتب": 1,
    "مبيعا": 1,
    "ذائع": 1,
    "راهن": 1,
    "البيتا": 1,
    "اذهب": 1,
    "ذهب": 1,
    "الذهاب": 1,
    "الجيدات": 1,
    "تجريبية": 1,
    "البيتاترون": 1,
    "البيتل": 1,
    "منكب": 1,
    "الجوزاء": 1,
    "بيتيز": 1,
    "بيث": 1,
    "المكان": 1,
    "باثزدا": 1,
    "ذكر": 1,
    "يذكر": 1,
    "وقعت": 1,
    "مبكرا": 1,
    "بيتوني": 1,
    "خن": 1,
    "الخيانة": 1,
    "الخيانات": 1,
    "خان": 1,
    "الخائن": 1,
    "الخونة": 1,
    "يخون": 1,
    "الخطبات": 1,
    "الرهانات": 1,
    "بيتسي": 1,
    "بتيز": 1,
    "الرهان": 1,
    "المراهن": 1,
    "المراهنون": 1,
    "بيتي": 1,
    "بينهما": 1,
    "البينية": 1,
    "الحافة": 1,
    "مشطوف": 1,
    "الميلا": 1,
    "الميل": 1,
    "الحافات": 1,
    "المشروبات": 1,
    "بيفرلي": 1,
    "اندب": 1,
    "ندب": 1,
    "الندب": 1,
    "يندب": 1,
    "احترس": 1,
    "يحترس": 1,
    "شارب": 1,
    "الحيرة": 1,
    "مذهلة": 1,
    "الارتباك": 1,
    "يحير": 1,
    "اسحر": 1,
    "سحر": 1,
    "يسحر": 1,
    "ساحر": 1,
    "ساحرة": 1,
    "الفتنة": 1,
    "الأسحار": 1,
    "البك": 1,
    "بيزيير": 1,
    "بوتان": 1,
    "ثنائي": 1,
    "بياليستوك": 1,
    "بيانكو": 1,
    "سنوي": 1,
    "التحيز": 1,
    "متحيز": 1,
    "التحيزات": 1,
    "محورين": 1,
    "ثنائية": 1,
    "بب": 1,
    "ذا": 1,
    "مريلة": 1,
    "الشرب": 1,
    "الإنجيل": 1,
    "الأناجيل": 1,
    "إنجيلي": 1,
    "ببليوغرافي": 1,
    "الفهارس": 1,
    "الفهرس": 1,
    "مجنون": 1,
    "القارئ": 1,
    "القراء": 1,
    "مدمن": 1,
    "خمر": 1,
    "سكير": 1,
    "الولع": 1,
    "السكر": 1,
    "مجلسين": 1,
    "تشريعيين": 1,
    "ثاني": 1,
    "الكربونات": 1,
    "مرور": 1,
    "مائتي": 1,
    "لمحاكاة": 1,
    "المفهوم": 1,
    "المتكامل": 1,
    "لساحة": 1,
    "كرومات": 1,
    "بالثاني": 1,
    "مشاحن": 1,
    "مقعر": 1,
    "الوجهين": 1,
    "محدب": 1,
    "الدراجة": 1,
    "ركب": 1,
    "دراجة": 1,
    "الدراجات": 1,
    "بالدرجات": 1,
    "سائق": 1,
    "العرض": 1,
    "انقيادي": 1,
    "المزايد": 1,
    "المزايدون": 1,
    "الدجاج": 1,
    "المزايدة": 1,
    "بيدل": 1,
    "الدجاجة": 1,
    "منتظر": 1,
    "الاتجاه": 1,
    "العروض": 1,
    "يحدث": 1,
    "عامين": 1,
    "فترة": 1,
    "السنتين": 1,
    "بينفيل": 1,
    "النعش": 1,
    "بيرس": 1,
    "النعوش": 1,
    "السلك": 1,
    "البؤرة": 1,
    "النظارة": 1,
    "انقسم": 1,
    "بانقسام": 1,
    "ينقسم": 1,
    "التشعب": 1,
    "التشعبات": 1,
    "متزوج": 1,
    "بالاثنين": 1,
    "متزوجون": 1,
    "بامرأتين": 1,
    "بالزواج": 1,
    "زوجين": 1,
    "بجلو": 1,
    "بجز": 1,
    "المتعصب": 1,
    "متعصب": 1,
    "متزمت": 1,
    "التعصب": 1,
    "المتعصبون": 1,
    "الهام": 1,
    "الجواهر": 1,
    "السائق": 1,
    "السائقون": 1,
    "البيكيني": 1,
    "مرتدية": 1,
    "البيكينيات": 1,
    "الشفهي": 1,
    "الثنائية": 1,
    "القيد": 1,
    "الصفراء": 1,
    "القلب": 1,
    "منفوخ": 1,
    "البلهارزيا": 1,
    "مجيد": 1,
    "اللغتين": 1,
    "ثنائيات": 1,
    "بالصفراء": 1,
    "صفراوي": 1,
    "الصفراوية": 1,
    "مخادع": 1,
    "مخادعون": 1,
    "بيلابل": 1,
    "اللوحات": 1,
    "الفواتير": 1,
    "معدون": 1,
    "أسكن": 1,
    "الإسكان": 1,
    "المحفظة": 1,
    "المحافظ": 1,
    "الزملاء": 1,
    "بليون": 1,
    "البليونير": 1,
    "البلايين": 1,
    "الانتفاخ": 1,
    "متموج": 1,
    "الإناء": 1,
    "الجدي": 1,
    "الجديان": 1,
    "بيلتمور": 1,
    "بنظام": 1,
    "المعدنين": 1,
    "بيميني": 1,
    "الجزيء": 1,
    "بثنائية": 1,
    "الجزء": 1,
    "مجلات": 1,
    "شهرية": 1,
    "مجلة": 1,
    "أسامة": 1,
    "بن": 1,
    "لادن": 1,
    "مولود": 1,
    "مارس,": 1,
    "استهزأ": 1,
    "الرقم": 1,
    "العشري": 1,
    "بكلتا": 1,
    "الأذنين": 1,
    "بالأذنين": 1,
    "معا": 1,
    "المشكلة": 1,
    "الملف": 1,
    "ورش": 1,
    "التجليد": 1,
    "الملفات": 1,
    "ورشة": 1,
    "الغلاف": 1,
    "ملزم": 1,
    "الأغلفة": 1,
    "لفة": 1,
    "بطانية": 1,
    "اللبلاب": 1,
    "بنج": 1,
    "الانغماس": 1,
    "بنجين": 1,
    "بينجهام": 1,
    "بينجمتن": 1,
    "موفقة": 1,
    "البنغو": 1,
    "عيني": 1,
    "عينين": 1,
    "المعظمة": 1,
    "معادلة": 1,
    "حدين": 1,
    "معادلات": 1,
    "النواة": 1,
    "كيميائي": 1,
    "كيمياويا": 1,
    "حيويا": 1,
    "كيمياء": 1,
    "هش": 1,
    "بيوفيدباك": 1,
    "كاتب": 1,
    "كتاتيب": 1,
    "بالسيرة": 1,
    "بيولوجي": 1,
    "بيولوجيا": 1,
    "بيولوجيات": 1,
    "البيولوجي": 1,
    "العلماء": 1,
    "البيولوجيون": 1,
    "بالطب": 1,
    "الأحيائي": 1,
    "الطب": 1,
    "إحصاء": 1,
    "إحصائيات": 1,
    "بيولوجية": 1,
    "جزيء": 1,
    "جزيئات": 1,
    "البيولوجية": 1,
    "الالكترونية": 1,
    "فيزياء": 1,
    "فيزيولوجي": 1,
    "فيزيولوجية": 1,
    "فيزيولوجيون": 1,
    "فيزيولوجيا": 1,
    "المحيط": 1,
    "المحيطات": 1,
    "الإحصاءات": 1,
    "الأحيائية": 1,
    "كائنات": 1,
    "البيوتايت": 1,
    "مكون": 1,
    "الحزبين": 1,
    "قسمين": 1,
    "بالقدمين": 1,
    "قدمين": 1,
    "العرق": 1,
    "البيرش": 1,
    "مصنوع": 1,
    "بيرشر": 1,
    "الأبله": 1,
    "صياح": 1,
    "الطير": 1,
    "صائد": 1,
    "طير": 1,
    "الإنكسار": 1,
    "الثنائي": 1,
    "للضوء": 1,
    "بانكسار": 1,
    "ثنائيا": 1,
    "برجيت": 1,
    "بيرمينجهام": 1,
    "الميلاد": 1,
    "النسل": 1,
    "أعياد": 1,
    "بيرثيد": 1,
    "الشامة": 1,
    "مسقط": 1,
    "مساقط": 1,
    "المواليد": 1,
    "معدلات": 1,
    "الحق": 1,
    "الطبيعي": 1,
    "الحقوق": 1,
    "الطبيعية": 1,
    "جوهرة": 1,
    "بيسكين": 1,
    "البسكويت": 1,
    "البسكويتات": 1,
    "اشطر": 1,
    "شطر": 1,
    "الشطر": 1,
    "التنصيف": 1,
    "المنصف": 1,
    "المنصفات": 1,
    "يشطر": 1,
    "مزدوج": 1,
    "التسلسل": 1,
    "المخنث": 1,
    "ازدواجية": 1,
    "الجنس": 1,
    "المخنثون": 1,
    "الأسقف": 1,
    "الأسقفية": 1,
    "الأسقفيات": 1,
    "بيزمارك": 1,
    "بيسمارك": 1,
    "البزموت": 1,
    "البيسون": 1,
    "بس": 1,
    "بيساو": 1,
    "الاستقرار": 1,
    "المقطع": 1,
    "مقارنة": 1,
    "شريحة": 1,
    "للقطع": 1,
    "الكلبة": 1,
    "شكا": 1,
    "الكلبات": 1,
    "الحقد": 1,
    "الهزل": 1,
    "عمق": 1,
    "البت": 1,
    "العضة": 1,
    "العاض": 1,
    "العاضون": 1,
    "العضات": 1,
    "العض": 1,
    "البتنت": 1,
    "عض": 1,
    "ﻻذع": 1,
    "لذعا": 1,
    "البيترن": 1,
    "اللذاعة": 1,
    "بترنت": 1,
    "روت": 1,
    "حلو": 1,
    "ومر": 1,
    "مر": 1,
    "حﻻوة": 1,
    "مرة": 1,
    "القار": 1,
    "بالقار": 1,
    "المعالجة": 1,
    "أصغر": 1,
    "المعلومة": 1,
    "التكافؤ": 1,
    "البيفالف": 1,
    "الصمام": 1,
    "رخوي": 1,
    "مثل": 1,
    "المحارة": 1,
    "عاى": 1,
    "متغيرين": 1,
    "المعسكر": 1,
    "عسكر": 1,
    "العراء": 1,
    "عسكرة": 1,
    "المعسكرات": 1,
    "حادث": 1,
    "الجانب": 1,
    "العلوي": 1,
    "اﻷلماسة": 1,
    "أفش": 1,
    "السر": 1,
    "أفشى": 1,
    "الثرثار": 1,
    "الثرثارون": 1,
    "يثرثر": 1,
    "إفشاء": 1,
    "يفشي": 1,
    "الأسود": 1,
    "يصوت": 1,
    "العليق": 1,
    "البلاكبيرد": 1,
    "العبيد": 1,
    "السبورة": 1,
    "السبورات": 1,
    "السوداء": 1,
    "جسم": 1,
    "اﻷشعاع": 1,
    "بلاك": 1,
    "بيرن": 1,
    "اسودت": 1,
    "اسود": 1,
    "المسود": 1,
    "المفحم": 1,
    "الاسوداد": 1,
    "سوادا": 1,
    "المكياج": 1,
    "قبيلة": 1,
    "الهنود": 1,
    "الحمر": 1,
    "البثرة": 1,
    "البثور": 1,
    "الدهان": 1,
    "ضارب": 1,
    "السواد": 1,
    "العوامة": 1,
    "العوامات": 1,
    "القائمة": 1,
    "أدرج": 1,
    "واضع": 1,
    "اللائحة": 1,
    "إدراج": 1,
    "القائمات": 1,
    "السوداءة": 1,
    "الابتزاز": 1,
    "ابتز": 1,
    "المبتز": 1,
    "المبتزون": 1,
    "الابتزازات": 1,
    "بلاكمان": 1,
    "بلاكمر": 1,
    "أسود": 1,
    "فقط": 1,
    "السود": 1,
    "الحداد": 1,
    "الحدادة": 1,
    "الحدادون": 1,
    "بلاكستون": 1,
    "بلاكول": 1,
    "بلاكوود": 1,
    "المثانة": 1,
    "الشجيرة": 1,
    "العنقودية": 1,
    "المثائن": 1,
    "بلاديرورت": 1,
    "الشفرة": 1,
    "الشفرات": 1,
    "بلين": 1,
    "بلير": 1,
    "بليك": 1,
    "بلاكي": 1,
    "ملام": 1,
    "للوم": 1,
    "لام": 1,
    "مخطىء": 1,
    "بريء": 1,
    "الإثم": 1,
    "اللائم": 1,
    "اللائمون": 1,
    "استحقاق": 1,
    "مسحوق": 1,
    "تجميلي": 1,
    "ابيضت": 1,
    "بلانتشارد": 1,
    "بلانتش": 1,
    "متبيض": 1,
    "يبيض": 1,
    "الابيضاض": 1,
    "رقيق": 1,
    "أرق": 1,
    "الأرق": 1,
    "النفاق": 1,
    "التقليدية": 1,
    "الفراغ": 1,
    "مسح": 1,
    "فتورا": 1,
    "البطانية": 1,
    "البطانيات": 1,
    "التبلد": 1,
    "الفراغات": 1,
    "بلانتون": 1,
    "الضجيج": 1,
    "نعر": 1,
    "النعر": 1,
    "متضجر": 1,
    "سب": 1,
    "اللاعن": 1,
    "اللاعنون": 1,
    "يسب": 1,
    "السب": 1,
    "كفري": 1,
    "كفرية": 1,
    "الكفرية": 1,
    "الانفجار": 1,
    "الناسف": 1,
    "الناسفون": 1,
    "بلاسثول": 1,
    "النسف": 1,
    "الانطلاق": 1,
    "الانفجارات": 1,
    "مراحل": 1,
    "تطور": 1,
    "البويضة": 1,
    "اثغ": 1,
    "سافر": 1,
    "هذى": 1,
    "المتكلم": 1,
    "الهذيان": 1,
    "الثغاء": 1,
    "بلاتز": 1,
    "اللهب": 1,
    "توهج": 1,
    "السترة": 1,
    "الحلل": 1,
    "بتوهج": 1,
    "الوسام": 1,
    "زركش": 1,
    "الزركشة": 1,
    "شعارات": 1,
    "شعار": 1,
    "الأوسمة": 1,
    "بلدج": 1,
    "المبيض": 1,
    "ابيض": 1,
    "المبيضات": 1,
    "كآبة": 1,
    "الكئابة": 1,
    "أدمع": 1,
    "أعمش": 1,
    "ضعف": 1,
    "مشوش": 1,
    "ثغا": 1,
    "النلطق": 1,
    "الماعز": 1,
    "(تقليد": 1,
    "الماعز)": 1,
    "الفقاعات": 1,
    "نزف": 1,
    "انزف": 1,
    "ثقيل": 1,
    "الظل": 1,
    "ثقيلون": 1,
    "النزيف": 1,
    "ينزف": 1,
    "بليكر": 1,
    "شوه": 1,
    "التشويه": 1,
    "انتفض": 1,
    "الانتفاض": 1,
    "ممزوج": 1,
    "الخلاط": 1,
    "الخلاطات": 1,
    "الانسجام": 1,
    "بلينهيم": 1,
    "انسجم": 1,
    "بارك": 1,
    "ميمون": 1,
    "لحسن": 1,
    "الحظ": 1,
    "النعيم": 1,
    "يبارك": 1,
    "النعمة": 1,
    "النعم": 1,
    "المبارك": 1,
    "هب": 1,
    "أفسد": 1,
    "الشيش": 1,
    "أعمى": 1,
    "الأداء": 1,
    "الجيد": 1,
    "الغمامة": 1,
    "معصوبا": 1,
    "التغمية": 1,
    "الغمائم": 1,
    "نقاط": 1,
    "اصدم": 1,
    "مصدوم": 1,
    "يصدم": 1,
    "الصدم": 1,
    "العظاية": 1,
    "العمياء": 1,
    "الطرفة": 1,
    "رمش": 1,
    "الوامض": 1,
    "الأفق": 1,
    "(للفرس)": 1,
    "الرمش": 1,
    "الطرفات": 1,
    "سعيد": 1,
    "تقرح": 1,
    "التقرح": 1,
    "مرح": 1,
    "اهذ": 1,
    "مرحا": 1,
    "الغارة": 1,
    "الغارات": 1,
    "العاصفة": 1,
    "الثلجية": 1,
    "العاصفات": 1,
    "انفخ": 1,
    "منتفخ": 1,
    "الرنجة": 1,
    "ينفخ": 1,
    "النقطة": 1,
    "النقط": 1,
    "بلوك": 1,
    "بلوكادر": 1,
    "الحصارات": 1,
    "القنابل": 1,
    "سد": 1,
    "بلوكير": 1,
    "الحاجزون": 1,
    "المتخلف": 1,
    "المتخلفون": 1,
    "الحصن": 1,
    "الحصون": 1,
    "المصهورة": 1,
    "ممتلئ": 1,
    "الرجال": 1,
    "بلومبيرج": 1,
    "بلومكويست": 1,
    "أشقر": 1,
    "الشقراء": 1,
    "الشقراوات": 1,
    "شقرة": 1,
    "المذبحة": 1,
    "مروع": 1,
    "دم": 1,
    "الكلب": 1,
    "البوليسي": 1,
    "البوليسية": 1,
    "مدمى": 1,
    "نزفا": 1,
    "دامية": 1,
    "الدموي": 1,
    "سلمي": 1,
    "سلميا": 1,
    "إراقة": 1,
    "دماء": 1,
    "السلالة": 1,
    "الدموية": 1,
    "الدماء": 1,
    "محتقن": 1,
    "بالدم": 1,
    "بقعة": 1,
    "ملطخ": 1,
    "بقع": 1,
    "سفاح": 1,
    "الإدماء": 1,
    "الزهرة": 1,
    "تفتح": 1,
    "البنطلون": 1,
    "البنطلونات": 1,
    "بلومفيلد": 1,
    "التفتح": 1,
    "بلومنغتن": 1,
    "الزهور": 1,
    "الغلطات": 1,
    "أزهر": 1,
    "الإزهار": 1,
    "البقعة": 1,
    "مبقع": 1,
    "التلطخ": 1,
    "شامة": 1,
    "البقع": 1,
    "نشف": 1,
    "النشافة": 1,
    "التنشيف": 1,
    "البلوزة": 1,
    "البلوزات": 1,
    "إنفخ": 1,
    "الرجعي": 1,
    "للبارود": 1,
    "محترق": 1,
    "طلقة": 1,
    "نفخ": 1,
    "المنفاخ": 1,
    "المنافيخ": 1,
    "السمكة": 1,
    "المنتفخة": 1,
    "الذباب": 1,
    "الذبابة": 1,
    "الثقب": 1,
    "الهبوب": 1,
    "الجنسية": 1,
    "اللسان": 1,
    "الخ": 1,
    "موقد": 1,
    "اللحام": 1,
    "مواقد": 1,
    "المكبرة": 1,
    "بلاوبس": 1,
    "مهندم": 1,
    "انتحب": 1,
    "الانتحاب": 1,
    "أزرق": 1,
    "أسماك": 1,
    "السالمون.": 1,
    "يوجد": 1,
    "ألاسكا": 1,
    "العنب": 1,
    "أنواع": 1,
    "البط": 1,
    "البلوبيرد": 1,
    "القنطريون": 1,
    "عنبري": 1,
    "ازرق": 1,
    "شره": 1,
    "سمكة": 1,
    "البلو": 1,
    "جراس": 1,
    "الجندي": 1,
    "البحري": 1,
    "الزرقة": 1,
    "خطط": 1,
    "الخطط": 1,
    "زرقة": 1,
    "البلووز": 1,
    "المثقفة": 1,
    "الهصطونية": 1,
    "مزرق": 1,
    "تحايل": 1,
    "بتلقائية": 1,
    "التهريج": 1,
    "النصبات": 1,
    "الصبغة": 1,
    "الزرقاء": 1,
    "بلم": 1,
    "بلمينثال": 1,
    "الخطأ": 1,
    "البندقية": 1,
    "المتخبط": 1,
    "بتخبط": 1,
    "الأخطاء": 1,
    "كسر": 1,
    "يكسر": 1,
    "الضبابية": 1,
    "انطمس": 1,
    "تشوشا": 1,
    "الانطماس": 1,
    "الضباب": 1,
    "القول": 1,
    "المفاجئ": 1,
    "قال": 1,
    "مندفعا": 1,
    "بلرتر": 1,
    "قول": 1,
    "الأقوال": 1,
    "المفاجئة": 1,
    "الحمرة": 1,
    "احمر": 1,
    "أحمر": 1,
    "الخدود": 1,
    "احمرار": 1,
    "تبجح": 1,
    "المتبجح": 1,
    "المتبجحات": 1,
    "التبجح": 1,
    "عاصف": 1,
    "التهديدات": 1,
    "بلتوورست": 1,
    "الجادة": 1,
    "بليث": 1,
    "دبليو": 1,
    "البواء": 1,
    "التلميذ": 1,
    "التلاميذ": 1,
    "الركوب": 1,
    "اللوكاندة": 1,
    "البنسيونات": 1,
    "الإيواء": 1,
    "الاجتماع": 1,
    "الخنازير": 1,
    "المفخرة": 1,
    "تفاخر": 1,
    "المتفاخر": 1,
    "المتفاخرون": 1,
    "متفاخر": 1,
    "بتفاخر": 1,
    "التفاخر": 1,
    "المفاخر": 1,
    "القارب": 1,
    "بالباخرة": 1,
    "الطاقية": 1,
    "الطواقي": 1,
    "المرفأ": 1,
    "المرافئ": 1,
    "الزوارق": 1,
    "حمولة": 1,
    "المراكبي": 1,
    "المراكبة": 1,
    "القوارب": 1,
    "المراكب": 1,
    "البوب": 1,
    "تمايل": 1,
    "بوبي": 1,
    "الشرطيون": 1,
    "البكرة": 1,
    "التمايل": 1,
    "البكر": 1,
    "الشرطي": 1,
    "البوبكات": 1,
    "طائر": 1,
    "مغرد": 1,
    "طيور": 1,
    "بوبرو": 1,
    "الزلاقة": 1,
    "تزحلق": 1,
    "الجليد": 1,
    "قصير": 1,
    "السماني": 1,
    "بوكا": 1,
    "كأس": 1,
    "البيرا": 1,
    "بوكوورست": 1,
    "أنبئ": 1,
    "أنبأ": 1,
    "بودينهييم": 1,
    "بوديساتفا": 1,
    "الصدر": 1,
    "بالكامل": 1,
    "الإنباء": 1,
    "بودليايان": 1,
    "لاعب": 1,
    "كمال": 1,
    "أجسام": 1,
    "لاعبون": 1,
    "الحارس": 1,
    "الحراس": 1,
    "شكلا": 1,
    "بوينج": 1,
    "اليونان": 1,
    "بويوتيا": 1,
    "(مدينة": 1,
    "يونانية)": 1,
    "البويري": 1,
    "المستنقع": 1,
    "بوجارت": 1,
    "همفري": 1,
    "الهم": 1,
    "الغيلان": 1,
    "الهموم": 1,
    "تعثر": 1,
    "وحلا": 1,
    "التعثر": 1,
    "تحير": 1,
    "يتحير": 1,
    "التحير": 1,
    "موحل": 1,
    "التصويب": 1,
    "بوجوتا": 1,
    "المستنقعات": 1,
    "زائف": 1,
    "بوهيم": 1,
    "بوهيميا": 1,
    "البوهيمي": 1,
    "بوهر": 1,
    "اغل": 1,
    "غلى": 1,
    "الغلاية": 1,
    "غلايات": 1,
    "بويليربليت": 1,
    "الغلايات": 1,
    "الغليان": 1,
    "بويزي": 1,
    "أيداهو": 1,
    "بصخب": 1,
    "الصخب": 1,
    "التقيأ": 1,
    "اجترئ": 1,
    "ملاحظة": 1,
    "عريضة": 1,
    "أجرأ": 1,
    "الأجرأ": 1,
    "بأحرف": 1,
    "بولدفاسيس": 1,
    "طبع": 1,
    "بحروف": 1,
    "ثخينة": 1,
    "بجرأة": 1,
    "الصراحة": 1,
    "الجذع": 1,
    "الجذوع": 1,
    "البوليطس": 1,
    "بوليفار": 1,
    "بوليفيا": 1,
    "الكيس": 1,
    "السكين": 1,
    "بولونيا": 1,
    "البولومتر": 1,
    "البولومترات": 1,
    "السكاكين": 1,
    "البلشفي": 1,
    "البلشفيون": 1,
    "البلشفية": 1,
    "بالبلشفية": 1,
    "بولشوي": 1,
    "الوسادة": 1,
    "دعم": 1,
    "مساند": 1,
    "التدعيم": 1,
    "الوسائد": 1,
    "الترباس": 1,
    "اندفع": 1,
    "لفصل": 1,
    "النخالة": 1,
    "الطحين": 1,
    "المنخل": 1,
    "الاندفاع": 1,
    "بولتون": 1,
    "الترابيس": 1,
    "بولتزمان": 1,
    "القرص": 1,
    "الأقراص": 1,
    "اقصف": 1,
    "قصف": 1,
    "القصف": 1,
    "القذف": 1,
    "يقصف": 1,
    "منمق": 1,
    "طنان": 1,
    "بومباستس": 1,
    "بومباي": 1,
    "منفذ": 1,
    "العملية": 1,
    "القاذفات": 1,
    "بون": 1,
    "الصفقة": 1,
    "الصفقات": 1,
    "بونابارت": 1,
    "بونافنتشر": 1,
    "السند": 1,
    "التعذيب": 1,
    "السلع": 1,
    "الايداع": 1,
    "مضمون": 1,
    "السندات": 1,
    "العظمة": 1,
    "عظام": 1,
    "السماد": 1,
    "النيران": 1,
    "الطبلة": 1,
    "بونهام": 1,
    "الألفة": 1,
    "هزالا": 1,
    "المالك": 1,
    "قطعة": 1,
    "العاج": 1,
    "البينيت": 1,
    "صباح": 1,
    "الخير": 1,
    "جنسيا": 1,
    "الأغطية": 1,
    "بونفيل": 1,
    "بوني": 1,
    "أجمل": 1,
    "بونتمبو": 1,
    "المكافأة": 1,
    "المكافآت": 1,
    "الراهب": 1,
    "البوو": 1,
    "ارتكب": 1,
    "المغفلون": 1,
    "الثدي": 1,
    "المغفل": 1,
    "مزدريا": 1,
    "الرقصة": 1,
    "بوهو": 1,
    "بكى": 1,
    "الكتاب": 1,
    "المجلد": 1,
    "أماكن": 1,
    "تجليد": 1,
    "المجلدون": 1,
    "خزانة": 1,
    "خزائن": 1,
    "المساند": 1,
    "بوكير": 1,
    "الحجوزات": 1,
    "نظري": 1,
    "نظريا": 1,
    "بالأفكار": 1,
    "كتبة": 1,
    "الكتيب": 1,
    "الكتيبات": 1,
    "المؤشر": 1,
    "العلامة": 1,
    "موقع": 1,
    "مفضل": 1,
    "الإنترنت": 1,
    "الشرائط": 1,
    "المكتبة": 1,
    "المتنقلة": 1,
    "الكارت": 1,
    "الكروت": 1,
    "الرف": 1,
    "الرفوف": 1,
    "المكتبات": 1,
    "الكشك": 1,
    "المثقف": 1,
    "المثقفون": 1,
    "مثقف": 1,
    "منطقي": 1,
    "منطقيات": 1,
    "الانتعاش": 1,
    "دوى": 1,
    "العامل": 1,
    "انقلب": 1,
    "بوميرانجينج": 1,
    "الحلقات": 1,
    "المدينة": 1,
    "المزدهرة": 1,
    "المدن": 1,
    "يقوم": 1,
    "تافه": 1,
    "الوقح": 1,
    "فظة": 1,
    "الفظاظة": 1,
    "الوقحون": 1,
    "الدافع": 1,
    "زاد": 1,
    "الجرعة": 1,
    "المنشطة": 1,
    "نصرة": 1,
    "التحمس": 1,
    "الحذاء": 1,
    "محمل": 1,
    "للتشغيل": 1,
    "ماسح": 1,
    "الأحذية": 1,
    "ماسحون": 1,
    "حذاء": 1,
    "بوتيس": 1,
    "شمالي": 1,
    "(فلك)": 1,
    "الأكشاك": 1,
    "الجوارب": 1,
    "الركل": 1,
    "خالعات": 1,
    "الرباط": 1,
    "بوتل": 1,
    "روج": 1,
    "خمور": 1,
    "مروج": 1,
    "مروجون": 1,
    "يروج": 1,
    "المنافق": 1,
    "ألبس": 1,
    "إلباس": 1,
    "يلبس": 1,
    "الغنيمة": 1,
    "البوظة": 1,
    "أسرف": 1,
    "الشراب": 1,
    "ثملا": 1,
    "إسراف": 1,
    "ثمل": 1,
    "البورون": 1,
    "البورات": 1,
    "بالبورات": 1,
    "البوركس": 1,
    "بورد": 1,
    "البوردو": 1,
    "الدعارة": 1,
    "بوردين": 1,
    "ملكوم": 1,
    "بالحدود": 1,
    "مقيم": 1,
    "مناطق": 1,
    "الحد": 1,
    "اثقب": 1,
    "الشمالية": 1,
    "ثقب": 1,
    "السأم": 1,
    "الحفرة": 1,
    "الحفار": 1,
    "الحفارون": 1,
    "المملون": 1,
    "بورج": 1,
    "بوريك": 1,
    "ممل": 1,
    "المتطرفة": 1,
    "بوريس": 1,
    "بورلاند": 1,
    "بورنيو": 1,
    "بورنز": 1,
    "العنصر": 1,
    "المعدني": 1,
    "بورونية": 1,
    "وحوامض": 1,
    "سيليسك": 1,
    "الإقليم": 1,
    "الأقاليم": 1,
    "استعر": 1,
    "استعار": 1,
    "المقترض": 1,
    "المقترضون": 1,
    "المقتبس": 1,
    "المقتبسات": 1,
    "يستعير": 1,
    "البورت": 1,
    "المانية": 1,
    "أمريكية": 1,
    "مجال": 1,
    "الصوتيات": 1,
    "خفي": 1,
    "الصدور": 1,
    "بوسون": 1,
    "الرئيس": 1,
    "متحكم": 1,
    "الرؤساء": 1,
    "تسلطا": 1,
    "بتسلط": 1,
    "التدخل": 1,
    "الحلية": 1,
    "بوسطن": 1,
    "بوستوني": 1,
    "بوستونيون": 1,
    "مترجم": 1,
    "نباتي": 1,
    "نباتيا": 1,
    "بوتانيس": 1,
    "ادرس": 1,
    "مدروس": 1,
    "يدرس": 1,
    "النباتية": 1,
    "الرديء": 1,
    "المفسد": 1,
    "المفسدون": 1,
    "متقنة": 1,
    "الرديئة": 1,
    "متقن": 1,
    "النبر": 1,
    "كلاهما": 1,
    "تضايق": 1,
    "التضايق": 1,
    "بوتسوانا": 1,
    "الزجاجة": 1,
    "معبأ": 1,
    "الضيقة": 1,
    "يعبئ": 1,
    "الأشربة": 1,
    "الزجاجات": 1,
    "المعلب": 1,
    "بالقاع": 1,
    "السفلي": 1,
    "سفلي": 1,
    "الكبس": 1,
    "أقصى": 1,
    "القيعان": 1,
    "بوتيلين": 1,
    "سم": 1,
    "فعال": 1,
    "\"أناروبيك\"": 1,
    "المنتجة": 1,
    "لسم": 1,
    "بوتيلينا": 1,
    "التسمم": 1,
    "بوشير": 1,
    "بوجهيد": 1,
    "اشترى": 1,
    "مشترى": 1,
    "الصخرة": 1,
    "بالصخور": 1,
    "الأحجار": 1,
    "الصخور": 1,
    "تدخل": 1,
    "التخليقية": 1,
    "الشارع": 1,
    "الشوارع": 1,
    "تنطط": 1,
    "مجانا": 1,
    "القفزات": 1,
    "نطاط": 1,
    "التنطط": 1,
    "القفزة": 1,
    "كريم": 1,
    "بكرم": 1,
    "إثابة": 1,
    "مجازاة": 1,
    "الجود": 1,
    "الباقة": 1,
    "الباقات": 1,
    "بورباكي": 1,
    "البوربون": 1,
    "البورجوازي": 1,
    "البورجوازيون": 1,
    "البرجوازية": 1,
    "البطرفة": 1,
    "الوعكة": 1,
    "البوتيك": 1,
    "البوتيكات": 1,
    "الوعكات": 1,
    "بوفيير": 1,
    "بقري": 1,
    "بليد": 1,
    "الانحناءة": 1,
    "الإمرأة": 1,
    "السريعة": 1,
    "الطفل": 1,
    "المدلل": 1,
    "بوديتش": 1,
    "رياضيات": 1,
    "وفلكي": 1,
    "نقح": 1,
    "منقح": 1,
    "ينقح": 1,
    "التنقيح": 1,
    "باودوين": 1,
    "الأمعاء": 1,
    "باوين": 1,
    "معرش": 1,
    "بوويس": 1,
    "البوفن": 1,
    "باوي": 1,
    "العزف": 1,
    "السلطانية": 1,
    "رمى": 1,
    "الرامي": 1,
    "العقدة": 1,
    "البولنج": 1,
    "السلطانيات": 1,
    "الوتر": 1,
    "الأوتار": 1,
    "النقل": 1,
    "تلاكم": 1,
    "الملاكم": 1,
    "الملاكمون": 1,
    "مربعي": 1,
    "الشكل": 1,
    "الملاكمة": 1,
    "المخزن": 1,
    "البقس": 1,
    "الولد": 1,
    "ينتمي": 1,
    "اﻷرستقراطية": 1,
    "بويس": 1,
    "المقاطعة": 1,
    "المقاطع": 1,
    "(من": 1,
    "مقاطعة)": 1,
    "بويد": 1,
    "بوير": 1,
    "الصبا": 1,
    "صبياني": 1,
    "صبيانية": 1,
    "الصبيانية": 1,
    "بويل": 1,
    "بويلستون": 1,
    "الأولاد": 1,
    "هجين": 1,
    "وتوت": 1,
    "(فاكهة)": 1,
    "البترول": 1,
    "البريطانية": 1,
    "لكثافة": 1,
    "التخزين": 1,
    "حمالة": 1,
    "براك": 1,
    "الدعامة": 1,
    "المثبت": 1,
    "الداعم": 1,
    "الحمالات": 1,
    "أذرعة": 1,
    "الكوع": 1,
    "العضد": 1,
    "السرخس": 1,
    "أقواس": 1,
    "مملح": 1,
    "النقاء": 1,
    "الورقة": 1,
    "برادبري": 1,
    "برادفورد": 1,
    "برادلي": 1,
    "برادشو": 1,
    "برادي": 1,
    "المنحدر": 1,
    "المنحدرات": 1,
    "براج": 1,
    "الاستعراض": 1,
    "يتفاخر": 1,
    "براهمابوترا": 1,
    "يصب": 1,
    "خليج": 1,
    "البنغال": 1,
    "براهمس": 1,
    "ملحن": 1,
    "الألماني": 1,
    "بابراهامز": 1,
    "الشريط": 1,
    "مجدل": 1,
    "التضفير": 1,
    "الضفائر": 1,
    "بريل": 1,
    "المخ": 1,
    "براينارد": 1,
    "البنات": 1,
    "دماغ": 1,
    "أذكى": 1,
    "القتل": 1,
    "تهشيم": 1,
    "الماغ": 1,
    "يفهم": 1,
    "الأمخاخ": 1,
    "الرائعة": 1,
    "فكرة": 1,
    "رائعة": 1,
    "اغسل": 1,
    "مخ": 1,
    "غسل": 1,
    "غاسل": 1,
    "الدماغ": 1,
    "يغسل": 1,
    "غسيل": 1,
    "سو": 1,
    "سوى": 1,
    "يسوي": 1,
    "التسوية": 1,
    "الفرامل": 1,
    "فرمل": 1,
    "المسؤول": 1,
    "المسؤولين": 1,
    "الفرملة": 1,
    "الشرشور": 1,
    "عليقي": 1,
    "تشعب": 1,
    "برانتشينجز": 1,
    "برانتشفيل": 1,
    "براندايس": 1,
    "براندل": 1,
    "براندينبرج": 1,
    "براندر": 1,
    "بالبراندي": 1,
    "الخمر": 1,
    "الوسم": 1,
    "يلوح": 1,
    "التلويح": 1,
    "براندون": 1,
    "براندت": 1,
    "البراندي": 1,
    "براندي": 1,
    "واين": 1,
    "برانيف": 1,
    "برانون": 1,
    "البرنتية": 1,
    "حمالات": 1,
    "بوقاحة": 1,
    "برازيليا": 1,
    "النحاس": 1,
    "الأصفر": 1,
    "براسيد": 1,
    "نحاسيا": 1,
    "جسارة": 1,
    "نحاسي": 1,
    "الأطفال": 1,
    "براتوورست": 1,
    "براون": 1,
    "الشجعان": 1,
    "واجه": 1,
    "بشجاعة": 1,
    "أشوس": 1,
    "مغوار": 1,
    "البأس": 1,
    "أشجع": 1,
    "الشجاعة": 1,
    "الأشجع": 1,
    "أحسنت": 1,
    "شجع": 1,
    "استحسن": 1,
    "(": 1,
    "فعلت": 1,
    ")": 1,
    "البرافو": 1,
    "الاستعراضات": 1,
    "تشاجر": 1,
    "المشاكس": 1,
    "المشاكسون": 1,
    "التشاجر": 1,
    "العضلات": 1,
    "العضلية": 1,
    "قوي": 1,
    "النهيق": 1,
    "نهق": 1,
    "المدحاة": 1,
    "يدوية": 1,
    "اصنع": 1,
    "صنع": 1,
    "تطاول": 1,
    "استحياء": 1,
    "التطاول": 1,
    "يصنع": 1,
    "المبخرة": 1,
    "المباخر": 1,
    "البرازيل": 1,
    "البرازيلي": 1,
    "برازافيل": 1,
    "المخالفة": 1,
    "خرق": 1,
    "المتعد": 1,
    "الخارق": 1,
    "للمعاهدة": 1,
    "المتعدون": 1,
    "الناقضون": 1,
    "الخروق": 1,
    "الخرق": 1,
    "سلة": 1,
    "سلات": 1,
    "بالخبز": 1,
    "البريدفروت": 1,
    "بريدينج": 1,
    "بريدروت": 1,
    "العائل": 1,
    "العائلون": 1,
    "الراحة": 1,
    "للكسر": 1,
    "التالف": 1,
    "التالفات": 1,
    "الانفصال": 1,
    "الانهيارات": 1,
    "الإفطار": 1,
    "فطر": 1,
    "المفطر": 1,
    "الانكسار": 1,
    "الكسر": 1,
    "خطير": 1,
    "انقطع": 1,
    "التوقف": 1,
    "الاختراق": 1,
    "الإختراق": 1,
    "الاختراقات": 1,
    "الانتهاء": 1,
    "الأبراميس": 1,
    "رضع": 1,
    "أرضع": 1,
    "للتنفس": 1,
    "تنفس": 1,
    "الاستراحة": 1,
    "الاستراحات": 1,
    "يتنفس": 1,
    "لهاثا": 1,
    "التنفس": 1,
    "لاهث": 1,
    "بتلهف": 1,
    "الأنفاس": 1,
    "مثير": 1,
    "البريشة": 1,
    "تكاثر": 1,
    "الكعب": 1,
    "الكعوب": 1,
    "الطوق": 1,
    "المربي": 1,
    "المربون": 1,
    "التناسل": 1,
    "التربية": 1,
    "السلالات": 1,
    "النسيم": 1,
    "مرق": 1,
    "النسائم": 1,
    "عصفا": 1,
    "بمرح": 1,
    "المرح": 1,
    "المروق": 1,
    "كتلة": 1,
    "لينة": 1,
    "بريمين": 1,
    "أسم": 1,
    "يرد": 1,
    "أقطار": 1,
    "متعددة": 1,
    "بريمستراهلنج": 1,
    "بريندا": 1,
    "بريندان": 1,
    "برينان": 1,
    "برينر": 1,
    "بريسينهام": 1,
    "الإخوة": 1,
    "البريتاني": 1,
    "بريت": 1,
    "النغمة": 1,
    "الطويلة": 1,
    "الترقية": 1,
    "مرفع": 1,
    "الترفيع": 1,
    "الترقيات": 1,
    "مصانع": 1,
    "منتجون": 1,
    "مصنع": 1,
    "البدء": 1,
    "بروستر": 1,
    "برايان": 1,
    "الرشوة": 1,
    "رشا": 1,
    "الراشي": 1,
    "الرشاوي": 1,
    "الراشون": 1,
    "الرشو": 1,
    "بريك": 1,
    "بريس": 1,
    "الطوبة": 1,
    "مطوب": 1,
    "الطابوق": 1,
    "الأتون": 1,
    "البناءون": 1,
    "الطوب": 1,
    "أعمال": 1,
    "بالزفاف": 1,
    "العروسة": 1,
    "العريس": 1,
    "العرسان": 1,
    "العروسات": 1,
    "وصيفة": 1,
    "الشرف": 1,
    "وصيفات": 1,
    "الإصلاحية": 1,
    "الكوبري": 1,
    "جسر": 1,
    "بنى": 1,
    "جسرا": 1,
    "نقط": 1,
    "بريدجبورت": 1,
    "الكباري": 1,
    "بريدجيت": 1,
    "بريدجتاون": 1,
    "بريدج": 1,
    "ووتر": 1,
    "الجسور": 1,
    "اللجام": 1,
    "أشاح": 1,
    "الألجمة": 1,
    "الإشاحة": 1,
    "الاختصاص": 1,
    "أطلع": 1,
    "أقصر": 1,
    "الأقصر": 1,
    "الاجتماعات": 1,
    "باختصار": 1,
    "القصر": 1,
    "الاختصاصات": 1,
    "برين": 1,
    "اللواء": 1,
    "تكوين": 1,
    "لواء": 1,
    "سرية": 1,
    "اللواءات": 1,
    "الأميرالاى": 1,
    "خيالي": 1,
    "الشراعي": 1,
    "بريجس": 1,
    "بريجهام": 1,
    "أضئ": 1,
    "أضاء": 1,
    "منير": 1,
    "الإضاءة": 1,
    "يضيء": 1,
    "إضاءة": 1,
    "ببهاء": 1,
    "الإشراق": 1,
    "السطوع": 1,
    "بريتون": 1,
    "الكشافات": 1,
    "لامع": 1,
    "بتألق": 1,
    "بريق": 1,
    "سناء": 1,
    "الامتلاء": 1,
    "الكبريت": 1,
    "ايطاليا.": 1,
    "معقل": 1,
    "الصليبيين": 1,
    "ابان": 1,
    "الوسطى": 1,
    "الرمادي": 1,
    "الداكن": 1,
    "رمادي": 1,
    "ناقع": 1,
    "بالماء": 1,
    "مالح": 1,
    "المحاليل": 1,
    "الملحية": 1,
    "أحضر": 1,
    "أتى": 1,
    "الإحضار": 1,
    "ملوحة": 1,
    "الملوحة": 1,
    "بالمحلول": 1,
    "الملحي": 1,
    "برينكلي": 1,
    "الدعاية": 1,
    "قالب": 1,
    "فحم": 1,
    "قوالب": 1,
    "بريسبين": 1,
    "نشيط": 1,
    "أنشط": 1,
    "الأنشط": 1,
    "الشعر": 1,
    "الخشن": 1,
    "وقف": 1,
    "خشونة": 1,
    "الوقوف": 1,
    "خشن": 1,
    "بريستول": 1,
    "بريطانيا": 1,
    "بريطاني": 1,
    "بريتانيكا": 1,
    "البريطاني": 1,
    "البريطانيون": 1,
    "بريتاني": 1,
    "بريتين": 1,
    "بهشاشة": 1,
    "الهشاشة": 1,
    "اطرح": 1,
    "طرح": 1,
    "يطرح": 1,
    "الطرح": 1,
    "المكثف": 1,
    "البرنامج": 1,
    "أذاع": 1,
    "البرامج": 1,
    "اتسع": 1,
    "موسع": 1,
    "الاتساع": 1,
    "يتسع": 1,
    "أوسع": 1,
    "الأوسع": 1,
    "منسوج": 1,
    "نول": 1,
    "عريض": 1,
    "بوجه": 1,
    "النقد": 1,
    "برودواي": 1,
    "القطيفة": 1,
    "القرنبيط": 1,
    "بروك": 1,
    "بروكل": 1,
    "بروجلي": 1,
    "طرز": 1,
    "يطرز": 1,
    "اشو": 1,
    "حارق": 1,
    "يشوي": 1,
    "انكسر": 1,
    "يائس": 1,
    "متكسر": 1,
    "السمسار": 1,
    "السمسرة": 1,
    "السمسرات": 1,
    "السماسرة": 1,
    "برومفيلد": 1,
    "البروم": 1,
    "بروملي": 1,
    "الفرس": 1,
    "القصبات": 1,
    "شعبي": 1,
    "بالقصيبات": 1,
    "القصيبة": 1,
    "القصيبات": 1,
    "الرئوي": 1,
    "الشعبة": 1,
    "البرونكو": 1,
    "الديناصور": 1,
    "برونكس": 1,
    "البرونز": 1,
    "برونزي": 1,
    "البشرة": 1,
    "البرنزة": 1,
    "دبوس": 1,
    "دبابيس": 1,
    "العائلة": 1,
    "فكر": 1,
    "الحظيرة": 1,
    "الحظائر": 1,
    "الحضن": 1,
    "العائلات": 1,
    "متأمل": 1,
    "بروكديل": 1,
    "بروكفيلد": 1,
    "بروخافين": 1,
    "بروكلاين": 1,
    "بروكلين": 1,
    "بروكماونت": 1,
    "بروكسايد": 1,
    "المقشة": 1,
    "مكنوس": 1,
    "الكنس": 1,
    "بمكنسة)": 1,
    "يكنس": 1,
    "المقشات": 1,
    "الأخ": 1,
    "الأخوة": 1,
    "أخوي": 1,
    "الحاجب": 1,
    "أكره": 1,
    "الإكراه": 1,
    "براونيل": 1,
    "بنيا": 1,
    "بروبرت": 1,
    "الكعكة": 1,
    "الكعك": 1,
    "الاحمرار": 1,
    "السمرة": 1,
    "اسمرار": 1,
    "سمرة": 1,
    "يحمر": 1,
    "الحواجب": 1,
    "تصفح": 1,
    "المتصفح": 1,
    "المستعرض": 1,
    "المتصفحات": 1,
    "يتصفح": 1,
    "التصفح": 1,
    "برس": 1,
    "المتموجة": 1,
    "بركنر": 1,
    "بريجل": 1,
    "الكدمة": 1,
    "انجرح": 1,
    "الضخم": 1,
    "الضخام": 1,
    "الكدمات": 1,
    "الإشاعة": 1,
    "الغداء": 1,
    "بروناي": 1,
    "برانهيلد": 1,
    "برونو": 1,
    "برنسويك": 1,
    "الوطأة": 1,
    "الفرشة": 1,
    "ناعم": 1,
    "بالفرشاة": 1,
    "الفرش": 1,
    "كثيث": 1,
    "الفرشاة": 1,
    "العيدان": 1,
    "الرسم": 1,
    "كثيف": 1,
    "الجفاء": 1,
    "بروكسل": 1,
    "النزع": 1,
    "الإنسانية": 1,
    "برتاليزاشنس": 1,
    "بهيميا": 1,
    "بوحشية": 1,
    "المتوحش": 1,
    "المتوحشون": 1,
    "عقلاني": 1,
    "وحشية": 1,
    "بروكسيل": 1,
    "بريان": 1,
    "بريانت": 1,
    "الطحلبي": 1,
    "بكالوريوس": 1,
    "دي": 1,
    "الصحيفة": 1,
    "لأنظمة": 1,
    "بيل": 1,
    "زجاجة": 1,
    "يو": 1,
    "حرارية": 1,
    "للشغل": 1,
    "الفقاعة": 1,
    "أخرج": 1,
    "فقاعات": 1,
    "النبع": 1,
    "الفوار": 1,
    "الفقاقيع": 1,
    "مليء": 1,
    "بالفقاعات": 1,
    "إخراج": 1,
    "الشامبانيا": 1,
    "الدبل": 1,
    "لنفاوية": 1,
    "القرصان": 1,
    "القراصنة": 1,
    "بتشانان": 1,
    "بوخارست": 1,
    "بتشينوالد": 1,
    "بتشوالد": 1,
    "الدولار": 1,
    "راعي": 1,
    "البقر": 1,
    "رعاة": 1,
    "الحنطور": 1,
    "الحناطير": 1,
    "قفز": 1,
    "بكر": 1,
    "الجردل": 1,
    "بكيتيد": 1,
    "بكيتفل": 1,
    "المضاربة": 1,
    "الجرادل": 1,
    "بك": 1,
    "اﻷشجار": 1,
    "اﻷمريكية": 1,
    "قرن": 1,
    "القفز": 1,
    "الإبزيم": 1,
    "التوى": 1,
    "الترس": 1,
    "التروس": 1,
    "الأبازيم": 1,
    "بكلي": 1,
    "التواء": 1,
    "بكنل": 1,
    "الدولارات": 1,
    "الجلود": 1,
    "النبق": 1,
    "القمح": 1,
    "بوكي": 1,
    "ريفي": 1,
    "البرعم": 1,
    "بودابست": 1,
    "بد": 1,
    "بوذا": 1,
    "البوذية": 1,
    "البوذي": 1,
    "البوذيون": 1,
    "تزحزح": 1,
    "الببغاء": 1,
    "يتزحزح": 1,
    "الميزانية": 1,
    "بالميزانية": 1,
    "ميزانية": 1,
    "الميزانيات": 1,
    "التزحزح": 1,
    "البراعم": 1,
    "بدويايزر": 1,
    "بويرنج": 1,
    "بوينا": 1,
    "الهاوي": 1,
    "الجاموسة": 1,
    "الجاموسات": 1,
    "لمع": 1,
    "الواق": 1,
    "الدولة": 1,
    "المحايدة": 1,
    "الواقون": 1,
    "البوفيه": 1,
    "لطم": 1,
    "اللطم": 1,
    "الصراع": 1,
    "البوفيهات": 1,
    "التلميع": 1,
    "الزغباء": 1,
    "المهرج": 1,
    "المهرجون": 1,
    "المخيف": 1,
    "بجاتي": 1,
    "جاحظ": 1,
    "التافه": 1,
    "متعب": 1,
    "اللواط": 1,
    "التافهون": 1,
    "اﻷمتلاء": 1,
    "بالبق": 1,
    "المضايقة": 1,
    "البوق": 1,
    "مبوق": 1,
    "عازفون": 1,
    "الأبواق": 1,
    "التبوق": 1,
    "بيوك": 1,
    "الوقت": 1,
    "(العمارة": 1,
    "البنيان)": 1,
    "المبنى": 1,
    "المباني": 1,
    "التعزيز": 1,
    "التعزيزات": 1,
    "مدمج": 1,
    "مكتظ": 1,
    "بالمباني": 1,
    "بوجمبورا": 1,
    "اللمبة": 1,
    "اللمبات": 1,
    "بلغاريا": 1,
    "البلغاري": 1,
    "الانتفاخات": 1,
    "المعظم": 1,
    "بضخامة": 1,
    "الضخامة": 1,
    "البلدغ": 1,
    "اهدم": 1,
    "هدم": 1,
    "البلدوزر": 1,
    "البلدوزرات": 1,
    "يهدم": 1,
    "الهدم": 1,
    "شق": 1,
    "الرصاصة": 1,
    "النشرة": 1,
    "النشرات": 1,
    "مضادا": 1,
    "للرصاص": 1,
    "بليتسنمبيرز": 1,
    "مصارعة": 1,
    "الثيران": 1,
    "مصارع": 1,
    "مصارعات": 1,
    "البولفينش": 1,
    "الضفدع": 1,
    "الضفادع": 1,
    "البلهد": 1,
    "العناد": 1,
    "أرهب": 1,
    "الفتوات": 1,
    "الشق": 1,
    "السبائك": 1,
    "متفائل": 1,
    "باطمئنان": 1,
    "الاطمئنان": 1,
    "غليظ": 1,
    "الرقبة": 1,
    "العجل": 1,
    "العجول": 1,
    "السوط": 1,
    "السياط": 1,
    "الكبيرة": 1,
    "الفتوة": 1,
    "العنيف": 1,
    "الإرهاب": 1,
    "عشب": 1,
    "البرك": 1,
    "الواقيات": 1,
    "المتشرد": 1,
    "تلعثم": 1,
    "التلعثم": 1,
    "المتردد": 1,
    "المترددون": 1,
    "يتلعثم": 1,
    "اهتززت": 1,
    "اهتز": 1,
    "ممتصات": 1,
    "وعورة": 1,
    "وعر": 1,
    "الوعورة": 1,
    "الاهتزاز": 1,
    "لكدمات": 1,
    "مغرور": 1,
    "المتشردون": 1,
    "اجتمع": 1,
    "بنديستاج": 1,
    "الحزم": 1,
    "بندي": 1,
    "الأغبياء": 1,
    "مفسد": 1,
    "الأورام": 1,
    "نام": 1,
    "الفحم": 1,
    "بوقود": 1,
    "بنكيرينج": 1,
    "مشاطر": 1,
    "مشاطروا": 1,
    "الأرانب": 1,
    "بنسن": 1,
    "ألقى": 1,
    "بالعصر": 1,
    "الترياسي": 1,
    "بنيان": 1,
    "الطفو": 1,
    "بانشراح": 1,
    "ساند": 1,
    "الأزيز": 1,
    "بربانك": 1,
    "متمتم": 1,
    "يهذي": 1,
    "البقبقة": 1,
    "بورتش": 1,
    "المسئولية": 1,
    "أرهق": 1,
    "الإرهاق": 1,
    "المسئوليات": 1,
    "الفداحة": 1,
    "الأرقطيون": 1,
    "البر": 1,
    "المكتب": 1,
    "البيروقراطيون": 1,
    "البيروقراطي": 1,
    "بيروقراطي": 1,
    "بيروقراطية": 1,
    "البرقطة": 1,
    "بيروقراطيا": 1,
    "المكاتب": 1,
    "الإنبوبة": 1,
    "القياس": 1,
    "أنابيب": 1,
    "الأعلام": 1,
    "نموت": 1,
    "نما": 1,
    "النماء": 1,
    "ينمو": 1,
    "اللحوم": 1,
    "المواطن": 1,
    "المواطنون": 1,
    "اللص": 1,
    "السطو": 1,
    "اسط": 1,
    "سطا": 1,
    "يسطو": 1,
    "ضمان": 1,
    "السرقة": 1,
    "اللصوص": 1,
    "برغندي": 1,
    "الدفن": 1,
    "دفن": 1,
    "الدافن": 1,
    "يدفن": 1,
    "اخنق": 1,
    "الخيش": 1,
    "الثياب": 1,
    "يعقد": 1,
    "(عمل": 1,
    "عقدة)": 1,
    "السخرية": 1,
    "سخر": 1,
    "هزلي": 1,
    "السخريات": 1,
    "البرلي": 1,
    "أقوى": 1,
    "الأقوى": 1,
    "بورلنجام": 1,
    "بيرلينجتون": 1,
    "فيرمونت": 1,
    "بورما": 1,
    "البورمي": 1,
    "الحرق": 1,
    "احترق": 1,
    "الشعلة": 1,
    "الشعلات": 1,
    "برنيز": 1,
    "برنيت": 1,
    "برنهام": 1,
    "الاحتراق": 1,
    "الإحتراق": 1,
    "اصقل": 1,
    "ملمع": 1,
    "الملمع": 1,
    "الملمعون": 1,
    "يصقل": 1,
    "الصقل": 1,
    "الحروق": 1,
    "الزوالف": 1,
    "(شعر": 1,
    "الرجل)": 1,
    "طنينا": 1,
    "الطنان": 1,
    "الجحش": 1,
    "الجحشات": 1,
    "بروجهس": 1,
    "الجحر": 1,
    "اخترق": 1,
    "المستعير": 1,
    "الجحور": 1,
    "المنحة": 1,
    "الأكياس": 1,
    "كيسي": 1,
    "انفجر": 1,
    "المفجر": 1,
    "برستينجز": 1,
    "برت": 1,
    "برتون": 1,
    "بوروندي": 1,
    "ادفن": 1,
    "الحافلة": 1,
    "بسبار": 1,
    "العمال": 1,
    "بوش": 1,
    "بسيد": 1,
    "الحافلات": 1,
    "البوشل": 1,
    "قام": 1,
    "رديىء": 1,
    "الشجيرات": 1,
    "كثافة": 1,
    "الكثافة": 1,
    "البطانة": 1,
    "الثعبان": 1,
    "بشنل": 1,
    "الأدغال": 1,
    "عيشة": 1,
    "يعيش": 1,
    "انشغالا": 1,
    "منشغلا": 1,
    "التجارة": 1,
    "رجال": 1,
    "سيدة": 1,
    "بوسيه": 1,
    "بسن": 1,
    "التقبيل": 1,
    "التمثال": 1,
    "دجاجة": 1,
    "دجاج": 1,
    "التماثيل": 1,
    "الفضول": 1,
    "الفضولي": 1,
    "الشغل": 1,
    "الانشغال": 1,
    "لكن": 1,
    "البيوتاديين": 1,
    "البيوتان": 1,
    "المترجلة": 1,
    "الجزار": 1,
    "ذبح": 1,
    "الذبح": 1,
    "دموي": 1,
    "الجزارون": 1,
    "بوتين": 1,
    "الأيسومري": 1,
    "(صناعة": 1,
    "المطاط)": 1,
    "بوتيو": 1,
    "نطح": 1,
    "الزبدة": 1,
    "البدين": 1,
    "الغراب": 1,
    "بالزبد": 1,
    "الدسم": 1,
    "بترفيلد": 1,
    "الفراشات": 1,
    "الفراشة": 1,
    "اللبن": 1,
    "محتوي": 1,
    "زبدة": 1,
    "التلال": 1,
    "النطح": 1,
    "الأرداف": 1,
    "الزر": 1,
    "زرر": 1,
    "بتونر": 1,
    "العروة": 1,
    "المكره": 1,
    "العراوي": 1,
    "التزرير": 1,
    "الأزرار": 1,
    "بوتونويد": 1,
    "بترك": 1,
    "بوتيل": 1,
    "زبدي": 1,
    "الزبدات": 1,
    "ناهدة": 1,
    "سمين": 1,
    "الطاعة": 1,
    "بكستيهوده": 1,
    "بكستون": 1,
    "اشتر": 1,
    "للشراء": 1,
    "المشتري": 1,
    "المشترون": 1,
    "الشراء": 1,
    "يشتري": 1,
    "الطنين": 1,
    "أز": 1,
    "الأز": 1,
    "المنشار": 1,
    "التعبيرات": 1,
    "الطنانة": 1,
    "المحلي": 1,
    "وقفة": 1,
    "التأهب": 1,
    "بايرز": 1,
    "وقفات": 1,
    "مهجورات": 1,
    "المحلية": 1,
    "الثانوي": 1,
    "المقالة": 1,
    "الجانبي": 1,
    "تجاوز": 1,
    "التجاوز": 1,
    "النواتج": 1,
    "الزريبة": 1,
    "بايرن": 1,
    "بايرون": 1,
    "بايروني": 1,
    "المتفرج": 1,
    "المتفرجون": 1,
    "البايت": 1,
    "بيزنطي": 1,
    "بيزنطة": 1,
    "المفكرة": 1,
    "سجل": 1,
    "خادم": 1,
    "الويب": 1,
    "سجن": 1,
    "الباستيل": 1,
    "سي": 1,
    "كاليفورنيا": 1,
    "الأجرة": 1,
    "متآمر": 1,
    "التآمر": 1,
    "العصابات": 1,
    "خيمة": 1,
    "خيم": 1,
    "الملهى": 1,
    "الملفوف": 1,
    "اختلس": 1,
    "اﻷختلاس": 1,
    "سائقو": 1,
    "سواق": 1,
    "الحجرة": 1,
    "الوزارة": 1,
    "صانع": 1,
    "الدواليب": 1,
    "صانعو": 1,
    "حرفة": 1,
    "اﻷثاث": 1,
    "المنزلي": 1,
    "الخزانات": 1,
    "الحجرات": 1,
    "مبرق": 1,
    "البرقية": 1,
    "الإبراق": 1,
    "الكاملة": 1,
    "مطبخ": 1,
    "كابوت": 1,
    "مستكشف": 1,
    "إيطالي": 1,
    "كابريوليت": 1,
    "الكاكاو": 1,
    "حوت": 1,
    "المخبأ": 1,
    "مخبأ": 1,
    "المخابئ": 1,
    "الختم": 1,
    "إستخدام": 1,
    "الوسيطة": 1,
    "الثرثرة": 1,
    "مثرثر": 1,
    "تخرجه": 1,
    "وضعها": 1,
    "بيضة": 1,
    "تنافر": 1,
    "بتنافر": 1,
    "الأنغام": 1,
    "ناشز": 1,
    "بتعارض": 1,
    "النشاز": 1,
    "الصبار": 1,
    "كاد": 1,
    "جثمان": 1,
    "جيفة": 1,
    "الشحوب": 1,
    "جثامين": 1,
    "جيف": 1,
    "العلب": 1,
    "رباط": 1,
    "صوفي": 1,
    "العلبة": 1,
    "الإيقاع": 1,
    "مناغم": 1,
    "الإيقاعات": 1,
    "المناغمة": 1,
    "إيقاعي": 1,
    "الدور": 1,
    "المنفرد": 1,
    "الرائع": 1,
    "الطالب": 1,
    "العسكري": 1,
    "الطلاب": 1,
    "العسكريون": 1,
    "متسول": 1,
    "المتسول": 1,
    "كاديلاك": 1,
    "الكادميوم": 1,
    "الملاكات": 1,
    "الأوغاد": 1,
    "كادي": 1,
    "القيصر": 1,
    "قيصري": 1,
    "المقهى": 1,
    "المقاهي": 1,
    "الكافايين": 1,
    "محبوس": 1,
    "كتوم": 1,
    "الحذر": 1,
    "بكتمان": 1,
    "الحبس": 1,
    "كاهيل": 1,
    "كاهوت": 1,
    "التواطؤ": 1,
    "كيمان": 1,
    "زاحف": 1,
    "قابيل": 1,
    "كين": 1,
    "روائي": 1,
    "إنجليزي": 1,
    "بالعلامات": 1,
    "العلامات": 1,
    "القاهرة": 1,
    "صندوق": 1,
    "الأسير": 1,
    "سيء": 1,
    "داهن": 1,
    "مداهن": 1,
    "مداج": 1,
    "مداهنةمراءاة": 1,
    "يداهن": 1,
    "المداهنة": 1,
    "تكتل": 1,
    "كيك": 1,
    "واك": 1,
    "التكتل": 1,
    "كال": 1,
    "كالاباش": 1,
    "كاليه": 1,
    "معدن": 1,
    "أبيض؛": 1,
    "خام": 1,
    "الخارصين": 1,
    "الكوارث": 1,
    "المعاناة": 1,
    "التعاسة": 1,
    "البؤس": 1,
    "الكارثة": 1,
    "القصب": 1,
    "الهندي": 1,
    "احسب": 1,
    "كلسي": 1,
    "كلسية": 1,
    "تكلس": 1,
    "تكليس": 1,
    "مكلس": 1,
    "يكلس": 1,
    "كلس": 1,
    "التكليس": 1,
    "كالسيت": 1,
    "الكالسيوم": 1,
    "إحسب": 1,
    "يحسب": 1,
    "الحاسبات": 1,
    "حسبان": 1,
    "(رياضيات)": 1,
    "التفاضل": 1,
    "والتكامل": 1,
    "حسابات": 1,
    "كلكتا": 1,
    "كالدر": 1,
    "نحات": 1,
    "الولايات": 1,
    "المتحدة": 1,
    "الناجمة": 1,
    "انفجار": 1,
    "البركان": 1,
    "الحفر": 1,
    "البراكين": 1,
    "القدر": 1,
    "كالدويل": 1,
    "كاليب": 1,
    "كاليدونيا": 1,
    "الأسم": 1,
    "القديم": 1,
    "لسكوتلندا": 1,
    "أدخل": 1,
    "الرزنامة": 1,
    "أﻷدخال": 1,
    "التقويمات": 1,
    "بالرزنامة": 1,
    "جلد": 1,
    "كالجاري": 1,
    "كالهون": 1,
    "مدرج": 1,
    "التفصيل": 1,
    "الناعم": 1,
    "يدرج": 1,
    "التدرج": 1,
    "التحديد": 1,
    "التحديدات": 1,
    "المعاير": 1,
    "منقط": 1,
    "بالألوان": 1,
    "كاليفورني": 1,
    "سكان": 1,
    "كاليفورنيوم": 1,
    "كاليجولا": 1,
    "الفرجار": 1,
    "الخليفة": 1,
    "الخلافة": 1,
    "بتمارين": 1,
    "تمارين": 1,
    "يدوس": 1,
    "يتقصى": 1,
    "ملأ": 1,
    "الثغور": 1,
    "والفراغات": 1,
    "ملىء": 1,
    "كولكينز": 1,
    "يسد": 1,
    "النداء": 1,
    "نداء": 1,
    "ليس": 1,
    "كالا": 1,
    "للتسديد": 1,
    "كالاهان": 1,
    "كالار": 1,
    "معاكس": 1,
    "اﻷتصال": 1,
    "بها": 1,
    "الأشخاص": 1,
    "المتصلون": 1,
    "بواسطة": 1,
    "فن": 1,
    "الخطاط": 1,
    "الخطاطون": 1,
    "الدعوة": 1,
    "إلهام": 1,
    "الملحمي": 1,
    "المسماك": 1,
    "قمر": 1,
    "للكوكب": 1,
    "\"المشتري\"": 1,
    "قاس": 1,
    "القساوة": 1,
    "الفتي": 1,
    "النداءات": 1,
    "محطة": 1,
    "الراديو": 1,
    "التلفزيون": 1,
    "النسيج": 1,
    "الهدوء": 1,
    "مهدأ": 1,
    "هادئ": 1,
    "سعري": 1,
    "السعرة": 1,
    "الحرارية": 1,
    "السعرات": 1,
    "فى": 1,
    "إستعمال": 1,
    "للتكنولوجيا": 1,
    "غليون": 1,
    "يستعمل": 1,
    "زورا": 1,
    "أتهم": 1,
    "اﻷتهام": 1,
    "الإفتراء": 1,
    "المفتر": 1,
    "إفترائي": 1,
    "لد": 1,
    "كالفيرت": 1,
    "كالفين": 1,
    "الكالفيني": 1,
    "إمتلاك": 1,
    "طبيعة": 1,
    "قنب": 1,
    "قنبع": 1,
    "كاليبسو": 1,
    "الحدبة": 1,
    "المودة": 1,
    "التحديب": 1,
    "الحدب": 1,
    "الصراف": 1,
    "غشاء": 1,
    "الخلية": 1,
    "(نبات)": 1,
    "كمبوديا": 1,
    "للحصان": 1,
    "أولا": 1,
    "بالترتيب": 1,
    "ابتدائي": 1,
    "كامبردج": 1,
    "كامدين": 1,
    "جيء": 1,
    "الجمل": 1,
    "مقوس": 1,
    "سنام": 1,
    "الكاملية": 1,
    "زرافة": 1,
    "كاميلوت": 1,
    "التصوير": 1,
    "المصور": 1,
    "المصورون": 1,
    "آلات": 1,
    "كاميرون": 1,
    "الكاميرون": 1,
    "جمهورية": 1,
    "الساحل": 1,
    "كامي": 1,
    "كاميلا": 1,
    "كاميل": 1,
    "كامينو": 1,
    "شحن": 1,
    "ضخمة": 1,
    "الأقحوان": 1,
    "التمويه": 1,
    "تخفى": 1,
    "التمويهات": 1,
    "التخفي": 1,
    "الحملة": 1,
    "بحملة": 1,
    "أدارة": 1,
    "الحملات": 1,
    "كامبيل": 1,
    "كامبيلسبورت": 1,
    "مخيم": 1,
    "المخيم": 1,
    "المخيمون": 1,
    "مزارعين": 1,
    "نار": 1,
    "أرض": 1,
    "الكافور": 1,
    "المخيمات": 1,
    "كامبيون": 1,
    "لنوع": 1,
    "مواقع": 1,
    "الحرم": 1,
    "الجامعية": 1,
    "عمود": 1,
    "الحدبات": 1,
    "كنعان": 1,
    "الكنعانيون": 1,
    "الكندي": 1,
    "الكنديون": 1,
    "حفر": 1,
    "يشق": 1,
    "يحفر": 1,
    "شبكة": 1,
    "مشقوق": 1,
    "السخيف": 1,
    "مصطنع": 1,
    "المفرطة": 1,
    "السخيفة": 1,
    "الكناري": 1,
    "الكنستة": 1,
    "كانافيرال": 1,
    "كانبرا": 1,
    "ملغي": 1,
    "العروق": 1,
    "حشوي": 1,
    "بينها": 1,
    "السرطان": 1,
    "سرطاني": 1,
    "أمراض": 1,
    "كانداس": 1,
    "الوحدة": 1,
    "للكثافة": 1,
    "المضيئة": 1,
    "الشمعدانات": 1,
    "الشمعدان": 1,
    "الترشيحات": 1,
    "الترشيح": 1,
    "المرشح": 1,
    "المرشحون": 1,
    "كانديد": 1,
    "بالسكر": 1,
    "العصير": 1,
    "الحلويات": 1,
    "الشمعة": 1,
    "البيضة": 1,
    "ضوء": 1,
    "الشموع": 1,
    "مشعل": 1,
    "أضوية": 1,
    "مضاء": 1,
    "بالشموع": 1,
    "كاندلر": 1,
    "فتيلة": 1,
    "الصدق": 1,
    "كاندي": 1,
    "الحفظ": 1,
    "غلي": 1,
    "بالخيزرانته": 1,
    "الأثاث": 1,
    "قمار": 1,
    "يلعب": 1,
    "الناب": 1,
    "الأنياب": 1,
    "بالخيزرانة": 1,
    "والبرية": 1,
    "الذئاب": 1,
    "الثعالب": 1,
    "استهلك": 1,
    "تآكل": 1,
    "الأكل": 1,
    "أﻷستهلاك": 1,
    "التاكل": 1,
    "قرحي": 1,
    "اليرقة": 1,
    "الخضراء": 1,
    "كانا": 1,
    "مقدار": 1,
    "(6": 1,
    "أقدام)": 1,
    "الحشيش": 1,
    "معلب": 1,
    "يحترق": 1,
    "تعليب": 1,
    "المعلبات": 1,
    "للحم": 1,
    "البشر": 1,
    "بأكل": 1,
    "التفكيك": 1,
    "فكك": 1,
    "مفكك": 1,
    "يفكك": 1,
    "لحوم": 1,
    "بتعقل": 1,
    "الحيطة": 1,
    "التعليب": 1,
    "معدني": 1,
    "لحفظ": 1,
    "اﻷطعمة": 1,
    "معدنية": 1,
    "المدفع": 1,
    "قذيفة": 1,
    "إصطدم": 1,
    "الإصطدام": 1,
    "يستطيع": 1,
    "الزورق": 1,
    "مجذوف": 1,
    "يجدف": 1,
    "الشريعة": 1,
    "النظام": 1,
    "الديني": 1,
    "كالكنيسة": 1,
    "الكاثوليكية": 1,
    "قانوني": 1,
    "لباس": 1,
    "محترف": 1,
    "(الزي": 1,
    "اﻷطباء)": 1,
    "بقانون": 1,
    "كانون": 1,
    "مقدس": 1,
    "الشرائع": 1,
    "مغطاة": 1,
    "الستائر": 1,
    "الستارة": 1,
    "الشمام": 1,
    "تشكيلة": 1,
    "شمام": 1,
    "شرس": 1,
    "الشراسة": 1,
    "الأنشودة": 1,
    "الخبب": 1,
    "كانتيربيري": 1,
    "مخبوب": 1,
    "الخب": 1,
    "المنافقون": 1,
    "خنفساء": 1,
    "لونها": 1,
    "الدينية": 1,
    "الكابولي": 1,
    "زاوية": 1,
    "أوطرف": 1,
    "مقطع": 1,
    "طويلة": 1,
    "كانتوني": 1,
    "مساطير": 1,
    "كانوت": 1,
    "الجنفاص": 1,
    "جامع": 1,
    "جامعو": 1,
    "إلتماس": 1,
    "الوادي": 1,
    "الوديان": 1,
    "مطاطي": 1,
    "القبعة": 1,
    "القابليات": 1,
    "قدير": 1,
    "الكفاءةالكفاية": 1,
    "واسع": 1,
    "مؤهلا": 1,
    "قادرا": 1,
    "بالسعة": 1,
    "سعويا": 1,
    "المكثفات": 1,
    "كابيلا": 1,
    "أوريجا": 1,
    "موثوب": 1,
    "الوثب": 1,
    "يثب": 1,
    "كابيت": 1,
    "الملوك": 1,
    "الفرنسيين": 1,
    "كابيتاون": 1,
    "الشعرية": 1,
    "ظاهرة": 1,
    "التى": 1,
    "تحدث": 1,
    "التقاء": 1,
    "السائل": 1,
    "بالصلب": 1,
    "شعري": 1,
    "كابيسترانو": 1,
    "الرأسمال": 1,
    "الرأسمالية": 1,
    "الرأسمالي": 1,
    "رأسمالي": 1,
    "رأسماليا": 1,
    "الرأسماليون": 1,
    "الرسملة": 1,
    "ممول": 1,
    "يمول": 1,
    "مهلك": 1,
    "العواصم": 1,
    "كابيتان": 1,
    "ضريبة": 1,
    "الأعناق": 1,
    "ضرائب": 1,
    "الكابيتول": 1,
    "بالعاصمة": 1,
    "روما": 1,
    "إستسلم": 1,
    "مستسلم": 1,
    "يستسلم": 1,
    "الإستسلام": 1,
    "المعاهدة": 1,
    "فرع": 1,
    "إتحاد": 1,
    "جريمة": 1,
    "تمويلي": 1,
    "منظم": 1,
    "يخصي": 1,
    "كسب": 1,
    "جميع": 1,
    "الخدع": 1,
    "لعبة": 1,
    "\"بيكويت\"": 1,
    "النزوة": 1,
    "النزوات": 1,
    "نزوي": 1,
    "نزويا": 1,
    "القبعات": 1,
    "إنقلب": 1,
    "منقلب": 1,
    "ينقلب": 1,
    "الإنقلاب": 1,
    "الرحوية": 1,
    "الرحويات": 1,
    "اللمسة": 1,
    "اﻷخيرة": 1,
    "الكبسولة": 1,
    "الكبسولات": 1,
    "القائد": 1,
    "الرئاسة": 1,
    "قاد": 1,
    "ترأس": 1,
    "الترأس": 1,
    "مماحك": 1,
    "المماحكة": 1,
    "سر": 1,
    "مأسور": 1,
    "يأسر": 1,
    "الأسر": 1,
    "جالب": 1,
    "إنتباه": 1,
    "الأسرى": 1,
    "الآسر": 1,
    "الآسرون": 1,
    "المؤسر": 1,
    "المؤسرون": 1,
    "العباءة": 1,
    "للنساء": 1,
    "كابتو": 1,
    "كابيبارا": 1,
    "رافعة": 1,
    "كاراباو": 1,
    "كركاس": 1,
    "الدورق": 1,
    "الكاراميل": 1,
    "القيراط": 1,
    "القيراطات": 1,
    "القافلة": 1,
    "المسافر": 1,
    "الكارافان": 1,
    "القوافل": 1,
    "الكرويا": 1,
    "الكربيد": 1,
    "القصيرة": 1,
    "مسلح": 1,
    "ببندقية": 1,
    "قصيرة": 1,
    "البنادق": 1,
    "الكربوهيدرات": 1,
    "كاربوليكي": 1,
    "كاربولوي": 1,
    "سبيكة": 1,
    "الكاربون": 1,
    "فحمي": 1,
    "مكربن": 1,
    "يكربن": 1,
    "الكربنة": 1,
    "بثاني": 1,
    "كاربوني": 1,
    "كاربونيوم": 1,
    "التفحيم": 1,
    "مفحم": 1,
    "يفحم": 1,
    "معدنا": 1,
    "إندمج": 1,
    "أول": 1,
    "أوكسيد": 1,
    "الكربون": 1,
    "الكربوراندم": 1,
    "الكربوكسيل": 1,
    "(كيمياء)": 1,
    "الدمجانة": 1,
    "الجمرة": 1,
    "(جلدي)": 1,
    "الجمرات": 1,
    "الوقود": 1,
    "للاحتراق": 1,
    "كربن": 1,
    "المازج": 1,
    "(خلط": 1,
    "محرك": 1,
    "السيارة)": 1,
    "الكربوراتور": 1,
    "الجثث": 1,
    "الجثة": 1,
    "تنتج": 1,
    "للسرطان": 1,
    "البطاقة": 1,
    "بحجم": 1,
    "بطاقة": 1,
    "الهيل": 1,
    "كاردان": 1,
    "المقوى": 1,
    "الكارتونات": 1,
    "لعب": 1,
    "بالورق": 1,
    "لخيوط": 1,
    "الصوف": 1,
    "استعدادا": 1,
    "للغزل": 1,
    "قلبي": 1,
    "كاردف": 1,
    "بلوز": 1,
    "بالأزرار": 1,
    "بلوزات": 1,
    "الكاردينال": 1,
    "عدد": 1,
    "العناصر": 1,
    "الكاردينالات": 1,
    "الورق": 1,
    "القلبي": 1,
    "منحنى": 1,
    "يدعى": 1,
    "كذلك": 1,
    "لتشابهه": 1,
    "طب": 1,
    "توسيع": 1,
    "وعائي": 1,
    "البطاقات": 1,
    "العناية": 1,
    "مهتم": 1,
    "مل": 1,
    "مميل": 1,
    "الإمالة": 1,
    "المهنة": 1,
    "إنطلق": 1,
    "الإنطلاق": 1,
    "ممارسة": 1,
    "النزاهة": 1,
    "المحترف": 1,
    "المهن": 1,
    "هانئ": 1,
    "بعناية": 1,
    "مهمل": 1,
    "المعتني": 1,
    "المعتنون": 1,
    "المداعبة": 1,
    "مداعب": 1,
    "المداعبات": 1,
    "بملاطفة": 1,
    "مظهرا": 1,
    "للحب": 1,
    "علامة": 1,
    "الإقحام": 1,
    "كاري": 1,
    "أجرة": 1,
    "اﻷجرة": 1,
    "كارجيل": 1,
    "الشحن": 1,
    "كاريبي": 1,
    "الوعل": 1,
    "الوعول": 1,
    "كاريكاتوري": 1,
    "الكاريكاتير": 1,
    "كاريكاتير": 1,
    "الكاريكاتيرات": 1,
    "رسام": 1,
    "نخر": 1,
    "الأسنان": 1,
    "متسوس": 1,
    "كارل": 1,
    "كارلا": 1,
    "كارلتون": 1,
    "كارلن": 1,
    "كارلايل": 1,
    "كارلو": 1,
    "حمولات": 1,
    "كارلوس": 1,
    "كارلزباد": 1,
    "كارلسون": 1,
    "كارليل": 1,
    "كارميلا": 1,
    "كارمن": 1,
    "كارميكل": 1,
    "قرمزي": 1,
    "المجزرة": 1,
    "جسدي": 1,
    "الجسدية": 1,
    "القرنفلة": 1,
    "القرنفل": 1,
    "كارنيجي": 1,
    "كارني": 1,
    "الكرنفال": 1,
    "الكرنفالات": 1,
    "الخروب": 1,
    "كارول": 1,
    "احتفل": 1,
    "حفلات": 1,
    "كارولنا": 1,
    "كارولين": 1,
    "شمال": 1,
    "كارولينا": 1,
    "التراتيل": 1,
    "سباتي": 1,
    "حمراء": 1,
    "عديمة": 1,
    "تستخرج": 1,
    "الإحتفال": 1,
    "الصاخب": 1,
    "الإحتفالات": 1,
    "الصاخبة": 1,
    "إشرب": 1,
    "بالشرب": 1,
    "يشرب": 1,
    "الشبوط": 1,
    "كارباثيا": 1,
    "كاربينتر": 1,
    "النجارة": 1,
    "النجارون": 1,
    "المنتقد": 1,
    "السجادة": 1,
    "نقالة": 1,
    "للمسافرين": 1,
    "مفروش": 1,
    "بالسجاد": 1,
    "السجاد": 1,
    "العياب": 1,
    "ايجاد": 1,
    "العطل": 1,
    "مرآب": 1,
    "كار": 1,
    "كارارا": 1,
    "لنفر": 1,
    "داخل": 1,
    "محمول": 1,
    "الناقل": 1,
    "الناقلون": 1,
    "يحمل": 1,
    "إحمل": 1,
    "الواسعة": 1,
    "مواجهة": 1,
    "غثيان": 1,
    "الحركة": 1,
    "كارسن": 1,
    "إجرة": 1,
    "كرت": 1,
    "الإحتكارات": 1,
    "كارتر": 1,
    "متعهد": 1,
    "ديكارتي": 1,
    "قرطاجة": 1,
    "قرطاجي": 1,
    "كارتج": 1,
    "الغضروف": 1,
    "الغضاريف": 1,
    "غضروفي": 1,
    "الخرائط": 1,
    "خرائطي": 1,
    "الكارتون": 1,
    "علب": 1,
    "رسامو": 1,
    "الخرطوشة": 1,
    "الخراطيش": 1,
    "الخشبية": 1,
    "كارترايت": 1,
    "كارتي": 1,
    "كاروسو": 1,
    "إقطع": 1,
    "مقطوع": 1,
    "بالقطع": 1,
    "النحات": 1,
    "النحاتون": 1,
    "داعم": 1,
    "منحوت": 1,
    "انسان": 1,
    "كازانوفا": 1,
    "القصبة": 1,
    "الشلال": 1,
    "متعاقب": 1,
    "الشلالات": 1,
    "المسهل": 1,
    "فتش": 1,
    "بروتين": 1,
    "الجبن": 1,
    "النافذة": 1,
    "البابية": 1,
    "الإجتماعي": 1,
    "موظف": 1,
    "موظفين": 1,
    "اجتماعيين": 1,
    "كايسي": 1,
    "مصرف": 1,
    "يصرف": 1,
    "اللوزة": 1,
    "الصرافون": 1,
    "نقود": 1,
    "كشمير": 1,
    "الكازينو": 1,
    "الكازينوات": 1,
    "الدن": 1,
    "الأدنان": 1,
    "كاساندرا": 1,
    "محكمة": 1,
    "الاستئناف": 1,
    "كاسافا": 1,
    "الوعاء": 1,
    "المقاوم": 1,
    "للحرارة": 1,
    "المقاومة": 1,
    "الكاسيت": 1,
    "الكاسيتات": 1,
    "كاسيوبيا": 1,
    "نجوم": 1,
    "قديمة": 1,
    "(سكان": 1,
    "بابل)": 1,
    "كاسيوس": 1,
    "ثوب": 1,
    "لابس": 1,
    "الكهنة": 1,
    "ممثلون": 1,
    "الصنج": 1,
    "الصنوج": 1,
    "المنبوذ": 1,
    "الطائفة": 1,
    "مالك": 1,
    "القلعة": 1,
    "قلعوي": 1,
    "المذرة": 1,
    "الطوائف": 1,
    "موبخ": 1,
    "العقوبة": 1,
    "المعاقب": 1,
    "كاستيلو": 1,
    "الاختيار": 1,
    "تسعات": 1,
    "يملك": 1,
    "قلعة": 1,
    "قلاع": 1,
    "مسؤول": 1,
    "القلاع": 1,
    "المهمل": 1,
    "المملحة": 1,
    "إخص": 1,
    "الخصية": 1,
    "الإخصاء": 1,
    "كاسترو": 1,
    "القوالب": 1,
    "الاهتمام": 1,
    "المصاب": 1,
    "القطة": 1,
    "الأرض,": 1,
    "لأسفل,": 1,
    "تقويضي": 1,
    "الانتقاض": 1,
    "كارثي": 1,
    "سرداب": 1,
    "الموتى": 1,
    "سراديب": 1,
    "الإغماءات": 1,
    "الجمدة": 1,
    "جمدي": 1,
    "كاتالينا": 1,
    "الدليل": 1,
    "المفهرس": 1,
    "الفهرسة": 1,
    "الأدلة": 1,
    "التعديد": 1,
    "كاتالبا": 1,
    "العوامل": 1,
    "المحفز": 1,
    "المحفزات": 1,
    "عامل": 1,
    "حفز": 1,
    "محفز": 1,
    "يحفز": 1,
    "التحفيز": 1,
    "المصيادة": 1,
    "مقذوف": 1,
    "المصيادات": 1,
    "الزكام": 1,
    "زكامي": 1,
    "بالنزلة": 1,
    "(مرض)": 1,
    "الإغماء": 1,
    "بتخشب": 1,
    "مشلول": 1,
    "كاتاوبا": 1,
    "(لغة)": 1,
    "الكتبرد": 1,
    "صفير": 1,
    "الاحتجاج": 1,
    "صيد": 1,
    "للمسك": 1,
    "الأدراج": 1,
    "الماسك": 1,
    "الماسكون": 1,
    "يمسك": 1,
    "المسك": 1,
    "صلصة": 1,
    "الطماطم": 1,
    "الناقلات": 1,
    "المتنصر": 1,
    "الأصناف": 1,
    "التصنيف": 1,
    "صنف": 1,
    "مصنف": 1,
    "يصنف": 1,
    "الصنف": 1,
    "سلسل": 1,
    "مون": 1,
    "ممون": 1,
    "الممون": 1,
    "الطبخ": 1,
    "كاتيربيلر": 1,
    "اليرقات": 1,
    "يمون": 1,
    "مواء": 1,
    "الهر": 1,
    "مميئ": 1,
    "الموء": 1,
    "يموء": 1,
    "السلور": 1,
    "التنفيه": 1,
    "المسهلات": 1,
    "عرش": 1,
    "الكاثدرائية": 1,
    "الكاثدرائيات": 1,
    "كاثرين": 1,
    "كاثروود": 1,
    "القسطرة": 1,
    "قسطر": 1,
    "مقسطر": 1,
    "يقسطر": 1,
    "القسطرات": 1,
    "السالب": 1,
    "سالب": 1,
    "كاثوليكي": 1,
    "كاثوليكالي": 1,
    "الشمول": 1,
    "الكاثوليك": 1,
    "كاثي": 1,
    "الآيون": 1,
    "الكاتيوني": 1,
    "الصفصاف": 1,
    "قطي": 1,
    "الغفوة": 1,
    "نعس": 1,
    "النعس": 1,
    "الغفوات": 1,
    "النعناع": 1,
    "القطط": 1,
    "التيفا": 1,
    "حقدا": 1,
    "الخبث": 1,
    "حقود": 1,
    "الماشية": 1,
    "المواشي": 1,
    "الآخربشكل": 1,
    "الكيبل": 1,
    "التلفزيوني": 1,
    "المنصة": 1,
    "المنصات": 1,
    "قوقازي": 1,
    "القوقازيون": 1,
    "القوقاز": 1,
    "المؤتمر": 1,
    "التحضيري": 1,
    "عقد": 1,
    "مؤتمر": 1,
    "مسك": 1,
    "القدور": 1,
    "القرنابيط": 1,
    "سببي": 1,
    "السببية": 1,
    "سببيا": 1,
    "السبب": 1,
    "الأسباب": 1,
    "سبب": 1,
    "التسبب": 1,
    "الحارق": 1,
    "تهكمي": 1,
    "كاوي": 1,
    "الحارقات": 1,
    "الكوي": 1,
    "إكو": 1,
    "مكوي": 1,
    "يكوى": 1,
    "تحذيري": 1,
    "محذر": 1,
    "المحذر": 1,
    "المحذرين": 1,
    "الناصحين": 1,
    "التحذيرات": 1,
    "الإحتراس": 1,
    "الموكب": 1,
    "المواكب": 1,
    "الفارس": 1,
    "متعرجف": 1,
    "مستخفة": 1,
    "المدرعات": 1,
    "الفرسان": 1,
    "الكهف": 1,
    "الكهوف": 1,
    "تحلية": 1,
    "التبغ": 1,
    "ووضعه": 1,
    "المخطمة": 1,
    "(توضع": 1,
    "أنف": 1,
    "الحصان": 1,
    "ريثما": 1,
    "يتروض)": 1,
    "الكافيار": 1,
    "الإعتراض": 1,
    "المعترض": 1,
    "المعترضون": 1,
    "الإعتراضات": 1,
    "التافهة": 1,
    "يجوف": 1,
    "ثب": 1,
    "النعيب": 1,
    "ناعق": 1,
    "النعيق": 1,
    "ينعق": 1,
    "كايين": 1,
    "كيلي": 1,
    "كيجا": 1,
    "(الهنود": 1,
    "الحمر)": 1,
    "أس": 1,
    "الإذاعية": 1,
    "المدمج": 1,
    "قرص": 1,
    "مسجل": 1,
    "السيطرة": 1,
    "التوقيت": 1,
    "النهاري": 1,
    "الوسطي": 1,
    "(الولايات": 1,
    "المتحدة)": 1,
    "الوقف": 1,
    "متوقف": 1,
    "مستمر": 1,
    "دون": 1,
    "سيسيل": 1,
    "سيسيليا": 1,
    "سيكروبيا": 1,
    "الأرز": 1,
    "إترك": 1,
    "سيدر": 1,
    "سيدريك": 1,
    "سقف": 1,
    "السقف": 1,
    "مسقف": 1,
    "السقوف": 1,
    "الكلندين": 1,
    "سيليبز": 1,
    "جزيرة": 1,
    "شرقي": 1,
    "أندونيسيا": 1,
    "إحتفل": 1,
    "مشهور": 1,
    "يحتفل": 1,
    "إحتفالي": 1,
    "المشاهير": 1,
    "المشهور": 1,
    "سيليرياك": 1,
    "نبات.": 1,
    "جذوره": 1,
    "السرع": 1,
    "الكرفس": 1,
    "سيليستا": 1,
    "سيليست": 1,
    "سماوي": 1,
    "سيليا": 1,
    "العزوبة": 1,
    "عازب": 1,
    "العزاب": 1,
    "القبو": 1,
    "الأقبية": 1,
    "الكمنجة": 1,
    "عازفو": 1,
    "الكمان": 1,
    "التشيلو": 1,
    "السيلوفان": 1,
    "أوراق": 1,
    "السليفون": 1,
    "الخلوي": 1,
    "السيليلويد": 1,
    "سليلوزي": 1,
    "سيللوسيز": 1,
    "(السكر)": 1,
    "مئوي": 1,
    "الكيلتي": 1,
    "الإسمنت": 1,
    "مدعم": 1,
    "المغطي": 1,
    "بالأسمنت": 1,
    "يدعم": 1,
    "المقابر": 1,
    "المقبرة": 1,
    "دهر": 1,
    "تحديثي": 1,
    "الرقيب": 1,
    "مراقب": 1,
    "رقابي": 1,
    "المراقبة": 1,
    "عياب": 1,
    "التنديد": 1,
    "الرقباء": 1,
    "الرقابة": 1,
    "باللوم": 1,
    "إحصاءات": 1,
    "السنت": 1,
    "القنطور": 1,
    "المئوي": 1,
    "المئويون": 1,
    "المئوية": 1,
    "المئويات": 1,
    "المركز": 1,
    "ممركز": 1,
    "سينتريرس": 1,
    "المركزة": 1,
    "مستوي": 1,
    "تشطر": 1,
    "المركزية": 1,
    "المراكز": 1,
    "الدرجات": 1,
    "السنتغرام": 1,
    "السنتغرامات": 1,
    "السنتيليتر": 1,
    "السنتيمتر": 1,
    "السنتيمترات": 1,
    "مئوية": 1,
    "الأقدام": 1,
    "مئويات": 1,
    "سينتراليا": 1,
    "تمركز": 1,
    "يتمركز": 1,
    "التمركز": 1,
    "مركزيا": 1,
    "السنترال": 1,
    "سنترال": 1,
    "طارد": 1,
    "طردي": 1,
    "يطرد": 1,
    "المركزي": 1,
    "الطارد": 1,
    "الطاردات": 1,
    "معتدل": 1,
    "السنتات": 1,
    "الروماني": 1,
    "التنفيذي": 1,
    "رأسي": 1,
    "قيفاوس": 1,
    "شمالية": 1,
    "الخزف": 1,
    "السيراميك": 1,
    "يشكل": 1,
    "الآنية": 1,
    "الفخارية": 1,
    "المرهم": 1,
    "بالشمع": 1,
    "سيربيروس": 1,
    "وحش,": 1,
    "بثلاثة": 1,
    "الحبوب": 1,
    "المخيخ": 1,
    "مخي": 1,
    "مفكر": 1,
    "يفكر": 1,
    "بالمخ": 1,
    "رسمي": 1,
    "احتفالي": 1,
    "المراسيم": 1,
    "المراسم": 1,
    "يشمع": 1,
    "سيريس": 1,
    "جنس": 1,
    "لعائلة": 1,
    "كرزي": 1,
    "السيريوم": 1,
    "الدوري": 1,
    "المختبر": 1,
    "اﻷوروبي": 1,
    "لفيزياء": 1,
    "الجزيئات": 1,
    "متأكد": 1,
    "اليقين": 1,
    "قابلا": 1,
    "للتحقق": 1,
    "الضمان": 1,
    "بشهادة": 1,
    "الذين": 1,
    "يصدقون": 1,
    "يؤكدون": 1,
    "يشهد": 1,
    "إشهد": 1,
    "القضائي": 1,
    "عنقي": 1,
    "قيصر": 1,
    "السيزيوم": 1,
    "التوقفات": 1,
    "السيسنا": 1,
    "البالوعة": 1,
    "القيطس": 1,
    "الاستواء": 1,
    "سيلان": 1,
    "النبيذ": 1,
    "تشاد": 1,
    "تشادويك": 1,
    "الإنزعاج": 1,
    "غضب": 1,
    "(حشرة)": 1,
    "يغضب": 1,
    "القشر": 1,
    "المساومة": 1,
    "مساوم": 1,
    "الممازحة": 1,
    "يمازح": 1,
    "الكدر": 1,
    "مكدر": 1,
    "التكدير": 1,
    "الأكدار": 1,
    "مقيد": 1,
    "التقييد": 1,
    "طابعة": 1,
    "السلاسل": 1,
    "المدخن": 1,
    "الشره": 1,
    "مترأس": 1,
    "الرئيسات": 1,
    "تشيس": 1,
    "شالكوسيت": 1,
    "(خامة": 1,
    "نحاس)": 1,
    "الشاليه": 1,
    "الكأس": 1,
    "الكؤوس": 1,
    "الطباشير": 1,
    "طباشيري": 1,
    "المحجر": 1,
    "للطباشير": 1,
    "التحدي": 1,
    "للتحدي": 1,
    "متحدى": 1,
    "المتحدي": 1,
    "المتحدون": 1,
    "التحديات": 1,
    "تشالمرز": 1,
    "حديدي": 1,
    "الغرفة": 1,
    "يحتل": 1,
    "تشريعية": 1,
    "يحظرون": 1,
    "الفسوق": 1,
    "تشامبرلن": 1,
    "الخزانة": 1,
    "عاملة": 1,
    "عاملات": 1,
    "الغرف": 1,
    "الحرباء": 1,
    "الشطب": 1,
    "مشطوب": 1,
    "يشطب": 1,
    "الشمواة": 1,
    "تشامومل": 1,
    "البطل": 1,
    "الشمبانيا": 1,
    "معضوض": 1,
    "دوفع": 1,
    "الأبطال": 1,
    "البطولة": 1,
    "البطولات": 1,
    "تشامبلين": 1,
    "فرنسي": 1,
    "نوفا": 1,
    "سكوتيا": 1,
    "الفرصة": 1,
    "مصادف": 1,
    "مذبح": 1,
    "المستشاريات": 1,
    "المستشارية": 1,
    "تشانسيلورسفيل": 1,
    "الفرص": 1,
    "مشكوك": 1,
    "المخاطرة": 1,
    "تشانكر": 1,
    "الثريا": 1,
    "الثريات": 1,
    "الشماع": 1,
    "تشانج": 1,
    "متغير": 1,
    "التقلب": 1,
    "بتقلب": 1,
    "متقلب": 1,
    "بالتغيير": 1,
    "استبدل": 1,
    "الكلي": 1,
    "شانا": 1,
    "قناةاسم": 1,
    "تشانينج": 1,
    "تشانسون": 1,
    "الأغاني": 1,
    "المرتل": 1,
    "تشانتي": 1,
    "الديك": 1,
    "الديوك": 1,
    "الخاصة": 1,
    "تشاو": 1,
    "باضطراب": 1,
    "الشاب": 1,
    "البلوط": 1,
    "المصلى": 1,
    "المصليات": 1,
    "الحماية": 1,
    "الوصيفات": 1,
    "مدد": 1,
    "القسسة": 1,
    "الإكليل": 1,
    "الأكاليل": 1,
    "شابلن": 1,
    "كوميدي": 1,
    "أفلام": 1,
    "التاجر": 1,
    "الشبان": 1,
    "الفصول": 1,
    "للتمييز": 1,
    "بالماركات": 1,
    "الخصائص": 1,
    "التمثيل": 1,
    "ميز": 1,
    "المميز": 1,
    "المميزون": 1,
    "يميز": 1,
    "الحزورات": 1,
    "الشوندرة": 1,
    "مزاوج": 1,
    "للإتهام": 1,
    "مشحون": 1,
    "الشاحن": 1,
    "الشاحنات": 1,
    "الأجور": 1,
    "بحذر": 1,
    "الحربية": 1,
    "باحسان": 1,
    "المنظمات": 1,
    "الخيرية": 1,
    "الصدقة": 1,
    "نصاب": 1,
    "الدجل": 1,
    "الدجل,": 1,
    "الإقناع": 1,
    "النصابون": 1,
    "شارلمان": 1,
    "تشارلز": 1,
    "تشارليستون": 1,
    "تشارلي": 1,
    "شارلوت": 1,
    "تشارلوتيسفيل": 1,
    "السحر": 1,
    "مسحور": 1,
    "الساحر": 1,
    "السحرة": 1,
    "سرور": 1,
    "الحواس": 1,
    "شارون": 1,
    "محارف": 1,
    "ترميز": 1,
    "المخطط": 1,
    "الميثاق": 1,
    "الدستور": 1,
    "مؤجر": 1,
    "يستأجر": 1,
    "لرحلة": 1,
    "يستأجرون": 1,
    "تأجير": 1,
    "الناقلة": 1,
    "الدساتير": 1,
    "الجارتي": 1,
    "الجارتيون": 1,
    "تشارتريز": 1,
    "مصفر": 1,
    "المخططات": 1,
    "الخادمة": 1,
    "الخادمات": 1,
    "دوامة": 1,
    "خطيرة": 1,
    "ساحل": 1,
    "صقلية": 1,
    "المطاردة": 1,
    "مطارد": 1,
    "المطارد": 1,
    "المطاردون": 1,
    "المطاردات": 1,
    "الهوة": 1,
    "الهيكل": 1,
    "عفيف": 1,
    "عاقب": 1,
    "معاقب": 1,
    "المطهرون": 1,
    "الطهارة": 1,
    "المعاقبة": 1,
    "التأديب": 1,
    "المعاقبون": 1,
    "الدردشة": 1,
    "تشاثام": 1,
    "تشاتاهوتشي": 1,
    "تشاتانوجا": 1,
    "مدردش": 1,
    "الآثاث": 1,
    "مهذار": 1,
    "بثرثرة": 1,
    "تشوسير": 1,
    "شاعر": 1,
    "مساق": 1,
    "السياقة": 1,
    "السواق": 1,
    "تشونسي": 1,
    "الشوفيني": 1,
    "شوفيني": 1,
    "الشوفينيون": 1,
    "إمضغ": 1,
    "ممضوغ": 1,
    "المضغ": 1,
    "يمضغ": 1,
    "تشدر": 1,
    "رخيص": 1,
    "رخص": 1,
    "مرخص": 1,
    "الترخيص": 1,
    "يرخص": 1,
    "أرخص": 1,
    "بسعر": 1,
    "الرخص": 1,
    "البخيل": 1,
    "الإحتيال": 1,
    "مغشوش": 1,
    "الإحتيالات": 1,
    "للفحص": 1,
    "الصكوك": 1,
    "مفحوص": 1,
    "المدقق": 1,
    "يبقي": 1,
    "أوراقه": 1,
    "خضراء": 1,
    "الشتاء": 1,
    "للتبقع": 1,
    "بالمربعات": 1,
    "المدققون": 1,
    "التدقيق": 1,
    "قوائم": 1,
    "كش": 1,
    "ملك": 1,
    "مهزوم": 1,
    "يهزم": 1,
    "الهزيمة": 1,
    "المخارج": 1,
    "التفتيش": 1,
    "الفحوصات": 1,
    "الخد": 1,
    "لعوب": 1,
    "بشيطنة": 1,
    "الزقزقة": 1,
    "مزقزق": 1,
    "المشجع": 1,
    "المشجعون": 1,
    "مبتهج": 1,
    "فرحان": 1,
    "بفرح": 1,
    "يشجع": 1,
    "المشجعين": 1,
    "الكآبة": 1,
    "الهتافات": 1,
    "جبن": 1,
    "برجر": 1,
    "كعك": 1,
    "لشيىء": 1,
    "الأجبان": 1,
    "جبنا": 1,
    "جبني": 1,
    "الفهد": 1,
    "الطباخين": 1,
    "كبار": 1,
    "كلاب": 1,
    "بالكلاب": 1,
    "إزالة": 1,
    "الكيمياوية": 1,
    "الداخلية": 1,
    "يتعاطي": 1,
    "بالكيماويات": 1,
    "التعاطي": 1,
    "الصيادلة": 1,
    "الكيمياوي": 1,
    "تشين": 1,
    "تشيني": 1,
    "تشينج": 1,
    "تشينيل": 1,
    "الصك": 1,
    "المربعات": 1,
    "تشيكرز": 1,
    "عز": 1,
    "عزيز": 1,
    "المعز": 1,
    "يعز": 1,
    "العز": 1,
    "تشيريتون": 1,
    "الشيروكي": 1,
    "أبالاتشايان": 1,
    "الهندية": 1,
    "الكرز": 1,
    "نقي,": 1,
    "ببراءة": 1,
    "الكزبرة": 1,
    "شيريل": 1,
    "تشيسابيك": 1,
    "تشيشاير": 1,
    "الشطرنج": 1,
    "رقعة": 1,
    "شطرنج": 1,
    "للوضع": 1,
    "نعش": 1,
    "تشيستر": 1,
    "تشيسترفيلد": 1,
    "تشيسترتون": 1,
    "الكستناءة": 1,
    "الكستناء": 1,
    "النبيل": 1,
    "بالمجادلة": 1,
    "تشيفروليت": 1,
    "سبعة": 1,
    "تشيفي": 1,
    "الماضغ": 1,
    "الماضغون": 1,
    "شايان": 1,
    "هنود": 1,
    "محاربة": 1,
    "محاربين": 1,
    "الشايان": 1,
    "تشي": 1,
    "شيانك": 1,
    "الإيطالية": 1,
    "والضوء": 1,
    "الأناقة": 1,
    "شيكاغو": 1,
    "المكسيكية": 1,
    "غالط": 1,
    "يغالط": 1,
    "المكسيكي": 1,
    "المكسيكان": 1,
    "الأمريكان": 1,
    "الفرخ": 1,
    "القرقف": 1,
    "(طائر)": 1,
    "تشيكاسوس": 1,
    "أمريكيين": 1,
    "ارتاع": 1,
    "الارتياع": 1,
    "جديري": 1,
    "الحمصة": 1,
    "الفراخ": 1,
    "تشيكل": 1,
    "أنيق": 1,
    "الهندباء": 1,
    "بتوبيخ": 1,
    "رئيسية": 1,
    "الزعيم": 1,
    "الزعماء": 1,
    "الحريري": 1,
    "البرغوث": 1,
    "البراغيث": 1,
    "العقدة,": 1,
    "تورم": 1,
    "الأصابع": 1,
    "الطفولة": 1,
    "طفولي": 1,
    "أطفال": 1,
    "إنجاب": 1,
    "تشيلي": 1,
    "التشيلي": 1,
    "تشيلز": 1,
    "الفلفل": 1,
    "الحار": 1,
    "التوابل": 1,
    "البرد": 1,
    "مبرد": 1,
    "بارد": 1,
    "الروايات": 1,
    "المثيرة": 1,
    "أبرد": 1,
    "البرودة": 1,
    "التبريد": 1,
    "مخيف": 1,
    "يبرد": 1,
    "دق": 1,
    "الرنان": 1,
    "الوهم": 1,
    "وهمي": 1,
    "الرنانين": 1,
    "الدق": 1,
    "المدخنة": 1,
    "كناس": 1,
    "مدخنة": 1,
    "المداخن": 1,
    "الشمبانزي": 1,
    "الذقن": 1,
    "الصين": 1,
    "الصيني": 1,
    "الصينيون": 1,
    "الأواني": 1,
    "الصينية": 1,
    "الشينشيلة": 1,
    "البصيص": 1,
    "مرنون": 1,
    "يرن": 1,
    "حنك": 1,
    "مذقن": 1,
    "بالذقن": 1,
    "(مسك": 1,
    "الة": 1,
    "الكمان)": 1,
    "تشنوكي": 1,
    "الشاهبلوط": 1,
    "(شجرة)": 1,
    "الذقون": 1,
    "الرقاقة": 1,
    "تمكن": 1,
    "رقاقة": 1,
    "منتقاة": 1,
    "شرائح": 1,
    "السنجاب": 1,
    "طراز": 1,
    "إنكليزي": 1,
    "تشيبيوا": 1,
    "التقطيع": 1,
    "الرقائق": 1,
    "بتقويم": 1,
    "مقوم": 1,
    "مقومين": 1,
    "السقسقة": 1,
    "النقيق": 1,
    "يزقزق": 1,
    "صر": 1,
    "(كما": 1,
    "الصرصور)": 1,
    "الصرصرة": 1,
    "يصر": 1,
    "الإزميل": 1,
    "محفور": 1,
    "الأزاميل": 1,
    "شجاع": 1,
    "الفروسية": 1,
    "الثوم": 1,
    "المعمر": 1,
    "الكلورات": 1,
    "كلوريد": 1,
    "الكلوريد": 1,
    "الكلوريدات": 1,
    "كلور": 1,
    "مكلور": 1,
    "يكلور": 1,
    "التكلور": 1,
    "الكلور": 1,
    "الكلوروفورم": 1,
    "الكلوروفيل": 1,
    "الجبيلة": 1,
    "اليخضور": 1,
    "الوتد": 1,
    "مختنق": 1,
    "الأوتاد": 1,
    "الشوكولاته": 1,
    "الشوكولاتة": 1,
    "تشوكتو": 1,
    "حمر": 1,
    "تشوكتوس": 1,
    "الإختيار": 1,
    "الجودة": 1,
    "الإختيارات": 1,
    "الجوقة": 1,
    "(ولد)": 1,
    "الكورس": 1,
    "الجوقات": 1,
    "تشوكيبيري": 1,
    "مزدحم": 1,
    "المختنق": 1,
    "المختنقون": 1,
    "الإختناقات": 1,
    "الكوليرا": 1,
    "الكولوستيرول": 1,
    "كولينيستيريز": 1,
    "أنزيم": 1,
    "اقضم": 1,
    "قضم": 1,
    "يقضم": 1,
    "تشومسكي": 1,
    "إختر": 1,
    "المختار": 1,
    "المختارون": 1,
    "يختار": 1,
    "الإرضاء": 1,
    "الموسيقار": 1,
    "شوبان": 1,
    "تشوب": 1,
    "المروحية": 1,
    "المروحيات": 1,
    "تلاطما": 1,
    "ميجانا": 1,
    "عيدان": 1,
    "كورالي": 1,
    "الترنيمة": 1,
    "الترنيمات": 1,
    "وتري": 1,
    "الفقاريات": 1,
    "ظهري": 1,
    "باوتار": 1,
    "موسيقية": 1,
    "التزويد": 1,
    "الحبال": 1,
    "الرتيب": 1,
    "صمم": 1,
    "مدراء": 1,
    "راقص": 1,
    "بتلحين": 1,
    "تصميم": 1,
    "الرقصات": 1,
    "يصمم": 1,
    "الرتيبة": 1,
    "فتاة": 1,
    "فتيات": 1,
    "يعمل": 1,
    "الروتين": 1,
    "المشيمة": 1,
    "الضحكة": 1,
    "مغنى": 1,
    "الضحك": 1,
    "ردد": 1,
    "الترديد": 1,
    "مختار": 1,
    "تشو": 1,
    "تشوتشو": 1,
    "الشوربة": 1,
    "كريس": 1,
    "المسيح": 1,
    "كريستين": 1,
    "معمد": 1,
    "كريستنسين": 1,
    "كرستينسون": 1,
    "المسيحي": 1,
    "كريستيانا": 1,
    "التنصر": 1,
    "المسيحيون": 1,
    "كرستيانسين": 1,
    "كرستيانسون": 1,
    "كريستي": 1,
    "كريستينا": 1,
    "رباني": 1,
    "كرستوفيل": 1,
    "كريستوف": 1,
    "كريستوفر": 1,
    "الكرومات": 1,
    "اللونية": 1,
    "الألوان": 1,
    "التبقيع": 1,
    "بالصبغات": 1,
    "التسجيلات": 1,
    "كروماتوغرافي": 1,
    "تسجيلي": 1,
    "الكروم": 1,
    "صحن": 1,
    "كروم": 1,
    "مطلي": 1,
    "بالكروم": 1,
    "كرومي": 1,
    "الطلاء": 1,
    "الكروموسوم": 1,
    "جو": 1,
    "مزمن": 1,
    "السجل": 1,
    "مدون": 1,
    "المؤرخون": 1,
    "التدوين": 1,
    "توقيت": 1,
    "الوصف": 1,
    "لوقت": 1,
    "زمنيا": 1,
    "زمني": 1,
    "يتناول": 1,
    "فترات": 1,
    "منتظمة": 1,
    "تواريخ": 1,
    "الأحداث": 1,
    "تأريخ": 1,
    "الكرونومتر": 1,
    "ميقاتي": 1,
    "الزمن": 1,
    "الشرنقة": 1,
    "الشرانق": 1,
    "الإقحوان": 1,
    "كرايسلر": 1,
    "الزيتوني": 1,
    "الشوب": 1,
    "(كائنات": 1,
    "بحرية)": 1,
    "تشب": 1,
    "ماركة": 1,
    "أقفال": 1,
    "تجارية": 1,
    "تشوك": 1,
    "مرمي": 1,
    "الرمي": 1,
    "الخافتة": 1,
    "ضاحك": 1,
    "الخافت": 1,
    "الطبطبات": 1,
    "تشوكوالا": 1,
    "إز": 1,
    "مأزوز*": 1,
    "يئز": 1,
    "تشوكا": 1,
    "الصاحب": 1,
    "ود": 1,
    "الخشبة": 1,
    "الخشبات": 1,
    "الأصحاب": 1,
    "بورك": 1,
    "مرتاد": 1,
    "ارتياد": 1,
    "تشرشل": 1,
    "وينستون": 1,
    "تشرشيل": 1,
    "المباركة": 1,
    "بالكنيسة": 1,
    "كنسي": 1,
    "عضوة": 1,
    "باحة": 1,
    "باحات": 1,
    "الفظ": 1,
    "مخضة": 1,
    "مخضخض": 1,
    "الخضخضة": 1,
    "مخضات": 1,
    "المزلق": 1,
    "بالمزلاق": 1,
    "المزالق": 1,
    "الصلصة": 1,
    "الصلصات": 1,
    "الشبه": 1,
    "سائلة": 1,
    "المهضوم": 1,
    "المخابرات": 1,
    "السيكادا": 1,
    "سيسرو": 1,
    "المطبعي": 1,
    "السواح": 1,
    "مشابهة": 1,
    "للسيسرو": 1,
    "السيجار": 1,
    "السيجارة": 1,
    "السيجارات": 1,
    "الرموش": 1,
    "لديه": 1,
    "رموش": 1,
    "حزام": 1,
    "السرج": 1,
    "سينسيناتي": 1,
    "مطوق": 1,
    "الأطواق": 1,
    "سيندريلا": 1,
    "سيندي": 1,
    "سينمائي": 1,
    "السينما": 1,
    "سينما": 1,
    "السينمات": 1,
    "سينمائيا": 1,
    "السينمائي": 1,
    "سينيراما": 1,
    "سينابار": 1,
    "سينامون": 1,
    "القرفة": 1,
    "خمسة": 1,
    "الصفر": 1,
    "مشفر": 1,
    "التشفير": 1,
    "الأصفار": 1,
    "يوم": 1,
    "سيرس": 1,
    "(الأساطير": 1,
    "اليونانية)": 1,
    "الدائرة": 1,
    "مدار": 1,
    "دائرةشكل": 1,
    "بيضاوي": 1,
    "الدوائر": 1,
    "الخاتم": 1,
    "دار": 1,
    "الدوران": 1,
    "الإلتواء": 1,
    "التعميم": 1,
    "الاستدارة": 1,
    "ينشر": 1,
    "اﻷستدارة": 1,
    "التعميمات": 1,
    "وزع": 1,
    "موزع": 1,
    "يوزع": 1,
    "التوزيع": 1,
    "التوزيعات": 1,
    "الانتشار": 1,
    "المروج": 1,
    "المروجون": 1,
    "تمر": 1,
    "المضلع": 1,
    "إختن": 1,
    "مختون": 1,
    "الختان": 1,
    "يختن": 1,
    "محيطات": 1,
    "محيطي": 1,
    "الجوانب": 1,
    "الإطناب": 1,
    "مطنب": 1,
    "أبحر": 1,
    "مبحر": 1,
    "يبحر": 1,
    "إبحار": 1,
    "الطواف": 1,
    "حدد": 1,
    "يحدد": 1,
    "التقييدات": 1,
    "خاضع": 1,
    "لظروف": 1,
    "ظرفي": 1,
    "برهن": 1,
    "راوغ": 1,
    "مراوغ": 1,
    "المراوغة": 1,
    "المراوغات": 1,
    "يراوغ": 1,
    "السيرك": 1,
    "السيركات": 1,
    "التليف": 1,
    "الكبدي": 1,
    "المعلاق": 1,
    "السيسكو": 1,
    "سيسكة": 1,
    "كبرى": 1,
    "الإتصالات": 1,
    "الصهريج": 1,
    "الصهاريج": 1,
    "الإقتباس": 1,
    "إستشهد": 1,
    "مستشهد": 1,
    "يستشهد": 1,
    "محاط": 1,
    "بمدن": 1,
    "الإستشهاد": 1,
    "بمواطنة": 1,
    "المواطنة": 1,
    "السيترات": 1,
    "سيتروين": 1,
    "الأترنج": 1,
    "الحمضيات": 1,
    "منظر": 1,
    "مناظر": 1,
    "الكيفت": 1,
    "(حيوان": 1,
    "ثديي)": 1,
    "المدني": 1,
    "المدنيون": 1,
    "الحضارة": 1,
    "الألطاف": 1,
    "حضاري": 1,
    "الحضارات": 1,
    "متحضر": 1,
    "الممدن": 1,
    "الممدنون": 1,
    "التحضير": 1,
    "صوتا": 1,
    "المطقطق": 1,
    "ملتف": 1,
    "يكسو": 1,
    "الإدعاء": 1,
    "للإسترجاع": 1,
    "المدعي": 1,
    "المدعون": 1,
    "مدعى": 1,
    "الإدعاءات": 1,
    "كلير": 1,
    "قراءة": 1,
    "البخت": 1,
    "عراف": 1,
    "قارئو": 1,
    "المحار": 1,
    "الكتوم": 1,
    "المتسلق": 1,
    "اﻷخرق": 1,
    "كلاميد": 1,
    "اللزوجة": 1,
    "كلامينج": 1,
    "صارخ": 1,
    "المثير": 1,
    "للصخب": 1,
    "المثيرون": 1,
    "الصراخ": 1,
    "صاخب": 1,
    "يصرخ": 1,
    "صرخ": 1,
    "بأسنان": 1,
    "تلصق": 1,
    "بالحذاء": 1,
    "للمساعدة": 1,
    "صدفة": 1,
    "أصداف": 1,
    "العشيرة": 1,
    "السرية": 1,
    "الفادح": 1,
    "الفادحة": 1,
    "القعقعة": 1,
    "مقعقع": 1,
    "بصلصلة": 1,
    "عشائري": 1,
    "العشائرية": 1,
    "العشائر": 1,
    "مصفق": 1,
    "اخدش": 1,
    "المصفقون": 1,
    "كلارا": 1,
    "كليرمونت": 1,
    "كلارينس": 1,
    "كليرندون": 1,
    "الكلاريت": 1,
    "التوضيح": 1,
    "التوضيحات": 1,
    "موضح": 1,
    "الموضح": 1,
    "يوضح": 1,
    "وضح": 1,
    "الكلارينت": 1,
    "كلارك": 1,
    "كلارج": 1,
    "الإشتباك": 1,
    "إشتبك": 1,
    "المتصادم": 1,
    "الإشتباكات": 1,
    "مشبوك": 1,
    "التناسلي": 1,
    "الشبك": 1,
    "كلاسيكي": 1,
    "الكلاسيكية": 1,
    "الكلاسيكي": 1,
    "الكلاسيكيون": 1,
    "أفخر": 1,
    "للتصنيف": 1,
    "التصنيفات": 1,
    "تصنيفي": 1,
    "المبوب": 1,
    "المبوبة": 1,
    "المصنف": 1,
    "المصنفون": 1,
    "اﻷناقة": 1,
    "طبقي": 1,
    "المساواة": 1,
    "الزميل": 1,
    "الدروس": 1,
    "فاخر": 1,
    "البعثرة": 1,
    "الضوضائي": 1,
    "التبعثر": 1,
    "بضوضائية": 1,
    "الضوضاء": 1,
    "كلود": 1,
    "كلوديا": 1,
    "كلوديو": 1,
    "كلوس": 1,
    "البند": 1,
    "كلوسين": 1,
    "البنود": 1,
    "كلوسيوس": 1,
    "الخوف": 1,
    "المن": 1,
    "خانق": 1,
    "النميمة": 1,
    "الترقوة": 1,
    "المفاتيح": 1,
    "المخلب": 1,
    "مخموش": 1,
    "الخمش": 1,
    "المخالب": 1,
    "كلاي": 1,
    "بالطين": 1,
    "طيني": 1,
    "الطين": 1,
    "كلايتن": 1,
    "نظيف": 1,
    "للتنظيف": 1,
    "المنظف": 1,
    "المنظفون": 1,
    "أنظف": 1,
    "اﻷنظف": 1,
    "النظافة": 1,
    "التنظيفات": 1,
    "واضح": 1,
    "أوضح": 1,
    "بفطنة": 1,
    "المقاصة": 1,
    "كليرووتر": 1,
    "للشق": 1,
    "الشقوق": 1,
    "ملصوق": 1,
    "الساطور": 1,
    "السواطير": 1,
    "المفتاح": 1,
    "كليم": 1,
    "كليمينس": 1,
    "الرحمة": 1,
    "كليمنس": 1,
    "كليمنت": 1,
    "رحيم": 1,
    "كليمينتس": 1,
    "كليمسون": 1,
    "الإحكام": 1,
    "ديني": 1,
    "الاكليروسية": 1,
    "للأكليروسية": 1,
    "مكتبي": 1,
    "مكتبية": 1,
    "كليفيلند": 1,
    "المبتذلة": 1,
    "إنقر": 1,
    "للنقر": 1,
    "منقور": 1,
    "النقر": 1,
    "النقرات": 1,
    "الزبون": 1,
    "بالزبائن": 1,
    "الزبائن": 1,
    "الرعاية": 1,
    "كلفورد": 1,
    "كليفتون": 1,
    "الفترة": 1,
    "الحرجة": 1,
    "ذروي": 1,
    "حرج": 1,
    "المناخ": 1,
    "مناخي": 1,
    "مناخيا": 1,
    "الذروة": 1,
    "الذرى": 1,
    "بلوغ": 1,
    "للتسلق": 1,
    "إرتفع": 1,
    "المتسلقون": 1,
    "بتثبيت": 1,
    "سريري": 1,
    "سريريا": 1,
    "السريري": 1,
    "الأطباء": 1,
    "السريريون": 1,
    "العيادات": 1,
    "الخشخشة": 1,
    "مخشخش": 1,
    "الآجر": 1,
    "القاسي": 1,
    "كلنت": 1,
    "كلنتن": 1,
    "كليو": 1,
    "الدبوس": 1,
    "النصوص": 1,
    "مقصوص": 1,
    "المقص": 1,
    "المقصات": 1,
    "القصاصة": 1,
    "القصاصات": 1,
    "الدبابيس": 1,
    "الزمرة": 1,
    "الزمر": 1,
    "منغلق": 1,
    "باغلاق": 1,
    "الطائفية": 1,
    "البظر": 1,
    "البظور": 1,
    "كلايف": 1,
    "المعاطف": 1,
    "العبي": 1,
    "إضرب": 1,
    "مضروب": 1,
    "النسائية": 1,
    "الساعة": 1,
    "ساعة": 1,
    "الساعاتي": 1,
    "تسجيل": 1,
    "الساعات": 1,
    "بإتجاه": 1,
    "عقرب": 1,
    "التربة": 1,
    "الأفظاظ": 1,
    "السدادة": 1,
    "معرقل": 1,
    "السدادات": 1,
    "معزول": 1,
    "رواقي": 1,
    "امش": 1,
    "متثاقلا": 1,
    "مشى": 1,
    "مشي": 1,
    "يمشي": 1,
    "مستنسخ": 1,
    "النسخ": 1,
    "رفاسي": 1,
    "الإستنساخ": 1,
    "الإنتهاء": 1,
    "إنتهاء": 1,
    "مغلق": 1,
    "مباشرة": 1,
    "أقرب": 1,
    "يغلق": 1,
    "مستقبل": 1,
    "المقربة": 1,
    "الإغلاق": 1,
    "الجلطة": 1,
    "بالقماش": 1,
    "فرشاة": 1,
    "العصري": 1,
    "الغسيل": 1,
    "حبال": 1,
    "مشبك": 1,
    "مشابك": 1,
    "البزاز": 1,
    "الجلطات": 1,
    "مخثر": 1,
    "التخثر": 1,
    "متخثر": 1,
    "إغلاق": 1,
    "أغلق": 1,
    "اغلاق": 1,
    "الغيمة": 1,
    "العواصف": 1,
    "غيما": 1,
    "غيوما": 1,
    "غائم": 1,
    "التغيم": 1,
    "صافي": 1,
    "الصفاء": 1,
    "الغيوم": 1,
    "الفص": 1,
    "البرسيم": 1,
    "ورقة": 1,
    "الفصوص": 1,
    "هرج": 1,
    "الحمق": 1,
    "مهرج": 1,
    "أتخم": 1,
    "متخم": 1,
    "الإتخام": 1,
    "يتخم": 1,
    "النادي": 1,
    "إرتياد": 1,
    "النوادي": 1,
    "المضربية": 1,
    "مشوه": 1,
    "اجتماع": 1,
    "اجتماعات": 1,
    "القرقة": 1,
    "مقيق": 1,
    "القيق": 1,
    "يقيق": 1,
    "جاهل": 1,
    "متثاقل": 1,
    "التثاقل": 1,
    "أخرق": 1,
    "الخراقة": 1,
    "العنقود": 1,
    "متجمع": 1,
    "العناقيد": 1,
    "الفاصل": 1,
    "ممسوك": 1,
    "الفواصل": 1,
    "يبعثر": 1,
    "كلايد": 1,
    "أساطير": 1,
    "يونانية": 1,
    "زوجة": 1,
    "موصل": 1,
    "الأكسيد": 1,
    "المكمل": 1,
    "كولورادو": 1,
    "المختلط": 1,
    "مدرب": 1,
    "السياق": 1,
    "(جمع": 1,
    "سائق)": 1,
    "الحوذي": 1,
    "الحوذيون": 1,
    "للتجلط": 1,
    "المجلط": 1,
    "يتخثر": 1,
    "(أدى": 1,
    "التفحم)": 1,
    "ناقلة": 1,
    "إلتئم": 1,
    "ملتئم": 1,
    "متحد": 1,
    "يلتئم": 1,
    "الإلتئام": 1,
    "كولنج": 1,
    "الحجري": 1,
    "مخشن": 1,
    "التخشين": 1,
    "يخشن": 1,
    "ساحلي": 1,
    "الوقاية": 1,
    "الوقايات": 1,
    "الساحلية": 1,
    "الساحلي": 1,
    "السواحل": 1,
    "المغطون": 1,
    "الطلاءات": 1,
    "ذيل": 1,
    "ذيول": 1,
    "بمشاركة": 1,
    "المشاركة": 1,
    "بالتأليف": 1,
    "المشاركون": 1,
    "الكابل": 1,
    "المحوري": 1,
    "مقنع": 1,
    "الملاطف": 1,
    "الملاطفون": 1,
    "الكابلات": 1,
    "المحورية": 1,
    "بتودد": 1,
    "الأوز": 1,
    "الكوبالت": 1,
    "كوب": 1,
    "الحصوة": 1,
    "مرقع": 1,
    "الإسكافي": 1,
    "الإسكافيون": 1,
    "الحصى": 1,
    "معبد": 1,
    "بالحصاة": 1,
    "الترقيع": 1,
    "كوبول": 1,
    "الكوبرا": 1,
    "ذكور": 1,
    "العنكبوت": 1,
    "متشابك": 1,
    "عنكبوتي": 1,
    "أنسجة": 1,
    "كوكا": 1,
    "الكوكائين": 1,
    "كوكيديوسيس": 1,
    "حيواني)": 1,
    "العصعص": 1,
    "كوتشيس": 1,
    "القوقعة": 1,
    "كوكران": 1,
    "طيار": 1,
    "كوتشران": 1,
    "والثور": 1,
    "الأشرطة": 1,
    "سخيف": 1,
    "الخنفساء": 1,
    "متبختر": 1,
    "المنغمس": 1,
    "الحولاء": 1,
    "اﻷحول": 1,
    "باحولال": 1,
    "(أحول": 1,
    "العين)": 1,
    "اﻷحولال": 1,
    "الديكة": 1,
    "سباق": 1,
    "سباقات": 1,
    "التبختر": 1,
    "الصدف": 1,
    "العشب": 1,
    "متغضن": 1,
    "الأصداف": 1,
    "التغضن": 1,
    "كوكني": 1,
    "مواطني": 1,
    "لندن": 1,
    "قمرة": 1,
    "القيادة": 1,
    "قمرات": 1,
    "الصرصور": 1,
    "الصراصير": 1,
    "العرف": 1,
    "الكوكتيل": 1,
    "الكوكتيلات": 1,
    "كوكو": 1,
    "جوزة": 1,
    "جوز": 1,
    "القد": 1,
    "الختام": 1,
    "كودينجتون": 1,
    "دلل": 1,
    "مدلل": 1,
    "يدلل": 1,
    "التدليل": 1,
    "رمز": 1,
    "وفاك": 1,
    "للشفرة": 1,
    "الكودين": 1,
    "المحارف": 1,
    "المبرمج": 1,
    "المبرمجون": 1,
    "التشفيرات": 1,
    "اﻷجوبة": 1,
    "المحتملة": 1,
    "تسلسل": 1,
    "معلومات": 1,
    "الجيني": 1,
    "رفرف": 1,
    "الفخذين": 1,
    "كودي": 1,
    "برمائية": 1,
    "للديدان": 1,
    "الطالبة": 1,
    "مختلط": 1,
    "الطالبات": 1,
    "المعاملة": 1,
    "المعاملات": 1,
    "مساوي": 1,
    "أجبر": 1,
    "مجبر": 1,
    "للأكراه": 1,
    "الإجبار": 1,
    "قسري": 1,
    "القهر": 1,
    "اﻷكراه": 1,
    "معاصر": 1,
    "تعايش": 1,
    "متعايش": 1,
    "التعايش": 1,
    "تواجدي": 1,
    "يتعايش": 1,
    "متساوي": 1,
    "امتداد": 1,
    "اﻷمتداد": 1,
    "القهوة": 1,
    "إبريق": 1,
    "كوفي": 1,
    "التابوت": 1,
    "التوابيت": 1,
    "كوفمان": 1,
    "عجلة": 1,
    "ترس": 1,
    "التأمل": 1,
    "التأملات": 1,
    "الكونياك": 1,
    "الكلمات": 1,
    "المشتركة": 1,
    "القرابة": 1,
    "القرابات": 1,
    "قرابي": 1,
    "إدراكي": 1,
    "بادراك": 1,
    "اللقب": 1,
    "عايش": 1,
    "السكن": 1,
    "معايش": 1,
    "المعايشة": 1,
    "يعايش": 1,
    "كوهين": 1,
    "متماسك": 1,
    "التماسك": 1,
    "الترابط": 1,
    "الإرث": 1,
    "يتماسك": 1,
    "بالتحام": 1,
    "كون": 1,
    "كوهوش": 1,
    "(أسم": 1,
    "نبات)": 1,
    "مصفف": 1,
    "مصففو": 1,
    "التسريحة": 1,
    "ملفوف": 1,
    "اللف": 1,
    "العملة": 1,
    "المعدنية": 1,
    "العملات": 1,
    "تزامن": 1,
    "متوافق": 1,
    "بنفس": 1,
    "يتزامن": 1,
    "مسكوك": 1,
    "مزيف": 1,
    "النقود": 1,
    "السك": 1,
    "جنسي": 1,
    "الجماع": 1,
    "الكوكا": 1,
    "تفحم": 1,
    "الكولا": 1,
    "المصفاة": 1,
    "المصافي": 1,
    "كولاس": 1,
    "تتمة": 1,
    "تتمات": 1,
    "كولبي": 1,
    "كول": 1,
    "كولمان": 1,
    "كوليردج": 1,
    "كوليت": 1,
    "القوليوس": 1,
    "كولجايت": 1,
    "بمغص": 1,
    "استاد": 1,
    "القولون": 1,
    "كولاب": 1,
    "نت": 1,
    "تعاون": 1,
    "متعاون": 1,
    "يتعاون": 1,
    "التعاون": 1,
    "تعاوني": 1,
    "بتعاون": 1,
    "المتعاون": 1,
    "المتعاونون": 1,
    "التصويري": 1,
    "الكولاجين": 1,
    "الإنهيار": 1,
    "منهار": 1,
    "السقوط": 1,
    "اﻷنهيار": 1,
    "للانهيار": 1,
    "الياقة": 1,
    "الكرنب": 1,
    "ياقة": 1,
    "الياقات": 1,
    "بالتوثيق": 1,
    "المقارنون": 1,
    "رصين": 1,
    "ببرودة": 1,
    "للجمع": 1,
    "الجماعات": 1,
    "الجماعية": 1,
    "الجموع": 1,
    "التعاونية": 1,
    "الكلية": 1,
    "الكليات": 1,
    "علاقات": 1,
    "الزمالة": 1,
    "طلبة": 1,
    "مصطدم": 1,
    "يصطدم": 1,
    "الكولي": 1,
    "غمق": 1,
    "كولير": 1,
    "مناجم": 1,
    "كوليرز": 1,
    "منجم": 1,
    "كوليس": 1,
    "واز": 1,
    "موازى": 1,
    "يوازي": 1,
    "الموازاة": 1,
    "تلسكوب": 1,
    "الكولنز": 1,
    "كحولي": 1,
    "الإصطدامات": 1,
    "الغروية": 1,
    "صمغي": 1,
    "أختصار": 1,
    "عامي": 1,
    "المؤتمرات": 1,
    "العامية": 1,
    "المحادثات": 1,
    "الدراسية": 1,
    "الحديث": 1,
    "تآمر": 1,
    "يتآمر": 1,
    "كولوسيف": 1,
    "بتواطئ": 1,
    "(عطر)": 1,
    "معطر": 1,
    "كولومبيا": 1,
    "الكولمبي": 1,
    "الكولمبيون": 1,
    "كولومبو": 1,
    "العقيد": 1,
    "العقداء": 1,
    "المستعمر": 1,
    "الإستعمارية": 1,
    "الإستعماري": 1,
    "أستعماري": 1,
    "إستعماري": 1,
    "اﻷستعمار": 1,
    "المستعمرون": 1,
    "المستعمرات": 1,
    "الإستعمار": 1,
    "إستعمر": 1,
    "مستعمر": 1,
    "يستعمر": 1,
    "مزود": 1,
    "بأعمدة": 1,
    "الكولونات": 1,
    "المستعمرة": 1,
    "بخداع": 1,
    "يلون": 1,
    "التلوين": 1,
    "لونحد": 1,
    "ملون": 1,
    "الملونين": 1,
    "الزنوج": 1,
    "تلون": 1,
    "التلون": 1,
    "لونتدرج": 1,
    "الأصباغ": 1,
    "الرسام": 1,
    "تلوين": 1,
    "بانعدام": 1,
    "اﻷلوان": 1,
    "العمالقة": 1,
    "العملاق": 1,
    "بعمى": 1,
    "خريطة": 1,
    "المسدس": 1,
    "مسدس": 1,
    "المهور": 1,
    "كولومبي": 1,
    "كولومبوس": 1,
    "عمودي": 1,
    "أعمدة": 1,
    "المعلق": 1,
    "الصحفي": 1,
    "المعلقون": 1,
    "اللفت": 1,
    "الغيبوبة": 1,
    "كومانتش": 1,
    "(قبيلة": 1,
    "هندية)": 1,
    "المشط": 1,
    "مقاتل": 1,
    "المقاتلة": 1,
    "ممشط": 1,
    "الماشط": 1,
    "الماشطات": 1,
    "للدمج": 1,
    "اندماجي": 1,
    "الحاصدة": 1,
    "الموحد": 1,
    "الموحدين": 1,
    "الحاصدات": 1,
    "التمشيط": 1,
    "منسدل": 1,
    "كومز": 1,
    "للإحتراق": 1,
    "باشتعال": 1,
    "إحتراقي": 1,
    "كومديكس": 1,
    "تعال": 1,
    "العودة": 1,
    "الكوميدي": 1,
    "الكوميديون": 1,
    "الكوميدية": 1,
    "الكوميديا": 1,
    "التدني": 1,
    "وسيم": 1,
    "الوسامة": 1,
    "القادم": 1,
    "المذنب": 1,
    "بالمذنب": 1,
    "المذنبات": 1,
    "القصاص": 1,
    "العادل": 1,
    "يحفظ": 1,
    "بارتياح": 1,
    "المعزي": 1,
    "المعزون": 1,
    "الترييح": 1,
    "راحة": 1,
    "أسباب": 1,
    "فكاهي": 1,
    "المجلات": 1,
    "الهزلية": 1,
    "مكتب": 1,
    "الإعلام": 1,
    "الشيوعي": 1,
    "المجيئ": 1,
    "المجاملة": 1,
    "مأمور": 1,
    "إستول": 1,
    "مستولى": 1,
    "الإستيلاء": 1,
    "يستولى": 1,
    "مشرف": 1,
    "الوصية": 1,
    "المغوار": 1,
    "المغاوير": 1,
    "الأوامر": 1,
    "كوم": 1,
    "أحي": 1,
    "محيى": 1,
    "يحيي": 1,
    "الإحياء": 1,
    "تذكاري": 1,
    "إشرع": 1,
    "مشروع": 1,
    "يشرع": 1,
    "الشرع": 1,
    "وص": 1,
    "بالثناء": 1,
    "يستحق": 1,
    "الثناء": 1,
    "الإطراء": 1,
    "مديحي": 1,
    "موصى": 1,
    "الشاكر": 1,
    "التوصية": 1,
    "يوصي": 1,
    "المقايسة": 1,
    "متناسب": 1,
    "متعادل": 1,
    "التعادل": 1,
    "التعادلات": 1,
    "تاجر": 1,
    "(فعل": 1,
    "ماضي)": 1,
    "التجارية": 1,
    "التتجير": 1,
    "تجر": 1,
    "متجر": 1,
    "يتجر": 1,
    "تجاري": 1,
    "اشتراكي": 1,
    "الشيوعيون": 1,
    "إمزج": 1,
    "يمزج": 1,
    "المزج": 1,
    "إرث": 1,
    "مرثو": 1,
    "يرثو": 1,
    "الرثاء": 1,
    "المواساة": 1,
    "التعاطف": 1,
    "المفوضية": 1,
    "النواب": 1,
    "النائب": 1,
    "مكلف": 1,
    "اللجان": 1,
    "إرتكب": 1,
    "الإلتزامات": 1,
    "يرتكب": 1,
    "الإحالة": 1,
    "ملتزم": 1,
    "المتعهد": 1,
    "الإرتكاب": 1,
    "بملائمة": 1,
    "السلعة": 1,
    "العميد": 1,
    "العمداء": 1,
    "البحريون": 1,
    "المشاعة": 1,
    "الشيوع": 1,
    "العامي": 1,
    "عموما": 1,
    "العموم": 1,
    "الإعتيادي": 1,
    "المحلات": 1,
    "الكومنولث": 1,
    "الإضطراب": 1,
    "الإضطرابات": 1,
    "عمومي": 1,
    "مشاعا": 1,
    "الكومونة": 1,
    "مناجى": 1,
    "الكومونات": 1,
    "الإنتقال": 1,
    "للإتصال": 1,
    "للنقل": 1,
    "المبلغ": 1,
    "المبلغون": 1,
    "إتصل": 1,
    "متصل": 1,
    "اتصالي": 1,
    "المحاورون": 1,
    "البيان": 1,
    "البيانات": 1,
    "الشيوعية": 1,
    "شيوعي": 1,
    "الجاليات": 1,
    "الجالية": 1,
    "أشع": 1,
    "مشاع": 1,
    "يشيع": 1,
    "المبادله": 1,
    "مبادلته": 1,
    "إعكس": 1,
    "معكوس": 1,
    "يعكس": 1,
    "التخفيفات": 1,
    "تبادلي": 1,
    "تبادليا": 1,
    "مسافر": 1,
    "المسافرون": 1,
    "يسافر": 1,
    "السفر": 1,
    "كوموروس": 1,
    "الضاغط": 1,
    "الضاغطين": 1,
    "الاكتناز": 1,
    "المطحنة": 1,
    "المطاحن": 1,
    "المواثيق": 1,
    "الشركات": 1,
    "للرفقة": 1,
    "الرفقة": 1,
    "رفاقي": 1,
    "وحيد": 1,
    "الشركة": 1,
    "كومباك": 1,
    "مقارن": 1,
    "بالمقارنة": 1,
    "نسبيا": 1,
    "صيغ": 1,
    "المقارن": 1,
    "يقارن": 1,
    "المقصورة": 1,
    "مقسم": 1,
    "مجزأ": 1,
    "المقصورات": 1,
    "البوصلة": 1,
    "البوصلات": 1,
    "الشفقة": 1,
    "العطف": 1,
    "التجانس": 1,
    "بانسجام": 1,
    "أرغم": 1,
    "سهل": 1,
    "مرغم": 1,
    "الإرغام": 1,
    "يرغم": 1,
    "بإيجاز": 1,
    "المال": 1,
    "عوض": 1,
    "يعوض": 1,
    "التعويضات": 1,
    "تعويضي": 1,
    "المعوض": 1,
    "تنافس": 1,
    "متنافس": 1,
    "يتنافس": 1,
    "التنافس": 1,
    "المنافسة": 1,
    "المسابقات": 1,
    "بتنافس": 1,
    "المنافس": 1,
    "المنافسون": 1,
    "إجمع": 1,
    "المجمع": 1,
    "المجمعات": 1,
    "راضي": 1,
    "إشتك": 1,
    "المشتكي": 1,
    "المشتكون": 1,
    "مشتكى": 1,
    "المتذمر": 1,
    "المتذمرون": 1,
    "بتشكي": 1,
    "يشتكي": 1,
    "قنوع": 1,
    "بليونة": 1,
    "التكملة": 1,
    "تكامل": 1,
    "مكمل": 1,
    "التتميم": 1,
    "التكملات": 1,
    "الكمال": 1,
    "يكمل": 1,
    "الإكمال": 1,
    "الأكمال": 1,
    "الطبيعة": 1,
    "بشرة": 1,
    "الطبائع": 1,
    "التعقيدات": 1,
    "التعقيد": 1,
    "معقد": 1,
    "الإذعان": 1,
    "طيع": 1,
    "محير": 1,
    "التواطئ": 1,
    "ممتثل": 1,
    "المطيع": 1,
    "المطيعون": 1,
    "يمتثل": 1,
    "مجاني": 1,
    "ممدوح": 1,
    "المجامل": 1,
    "المجاملون": 1,
    "المدح": 1,
    "الصلاة": 1,
    "إمتثل": 1,
    "الإمتثال": 1,
    "المكون": 1,
    "المكونات": 1,
    "إنسجم": 1,
    "منسجم": 1,
    "الإنسجام": 1,
    "ينسجم": 1,
    "أعدد": 1,
    "برباطة": 1,
    "جأش": 1,
    "السكينة": 1,
    "الملحن": 1,
    "الملحنون": 1,
    "الإعداد": 1,
    "التركيب": 1,
    "تركيبي": 1,
    "التراكيب": 1,
    "المنضد": 1,
    "المنضدون": 1,
    "العضوي": 1,
    "الكومبوت": 1,
    "(فاكهة": 1,
    "مطبوخة)": 1,
    "إفهم": 1,
    "شامل": 1,
    "الشمولية": 1,
    "الكمادة": 1,
    "الكمادات": 1,
    "الإنضغاطية": 1,
    "الضغوط": 1,
    "ضغطي": 1,
    "الضواغط": 1,
    "إشمل": 1,
    "مشمول": 1,
    "يشمل": 1,
    "الشمل": 1,
    "المتنازلون": 1,
    "التسويات": 1,
    "كومبتون": 1,
    "المراقب": 1,
    "المراقبون": 1,
    "إلزامي": 1,
    "الندم": 1,
    "بالأيمان": 1,
    "شاهد": 1,
    "كومبيو": 1,
    "سيرف": 1,
    "الحاسوب": 1,
    "بمساعدة": 1,
    "الحوسبة": 1,
    "حوسب": 1,
    "إلكتروني": 1,
    "يحوسب": 1,
    "الرفيق": 1,
    "بود": 1,
    "الرفاق": 1,
    "الرفاقية": 1,
    "كومسات": 1,
    "إخدع": 1,
    "كوناكري": 1,
    "كونانت": 1,
    "مسلسل": 1,
    "يسلسل": 1,
    "مقعرا": 1,
    "التقعر": 1,
    "اﻷخفاء": 1,
    "مخفى": 1,
    "المخفي": 1,
    "المخفون": 1,
    "إعترف": 1,
    "شك": 1,
    "الإعتراف": 1,
    "بخيلاء": 1,
    "الأوهام": 1,
    "المعقولية": 1,
    "معقول": 1,
    "اﻷستيعاب": 1,
    "المعقول": 1,
    "المعتقد": 1,
    "تركيزي": 1,
    "مركزية": 1,
    "فكري": 1,
    "المفاهيم": 1,
    "للاستيعاب": 1,
    "تصوري": 1,
    "تصور": 1,
    "يتصور": 1,
    "التصور": 1,
    "مفهوميا": 1,
    "باهتمام": 1,
    "الحفلة": 1,
    "منسق": 1,
    "بتدبير": 1,
    "كونسيرتينو": 1,
    "لأدوات": 1,
    "وحيدة": 1,
    "(موسيقى)": 1,
    "الحفلات": 1,
    "الملتزمون": 1,
    "التنازلات": 1,
    "القوقع": 1,
    "القواقع": 1,
    "حارس": 1,
    "حراس": 1,
    "مصالح": 1,
    "يصالح": 1,
    "المصالحة": 1,
    "إسترضائي": 1,
    "الوسيط": 1,
    "تصالحي": 1,
    "مصغر": 1,
    "الإجتماع": 1,
    "الإجتماعات": 1,
    "إستنتج": 1,
    "مستنتج": 1,
    "المستنتج": 1,
    "يستنتج": 1,
    "الإستنتاج": 1,
    "الخاتمة": 1,
    "الإستنتاجات": 1,
    "حاسم": 1,
    "الحسم": 1,
    "الملفق": 1,
    "الهاضم": 1,
    "الكونكورد": 1,
    "كونكورديا": 1,
    "الإلتقاء": 1,
    "الخرسانة": 1,
    "بالخرسانة": 1,
    "خرساني": 1,
    "الصلابة": 1,
    "التحجير": 1,
    "الإتخاذ": 1,
    "المحظيات": 1,
    "المحظية": 1,
    "شبق": 1,
    "إلتق": 1,
    "متلاقي": 1,
    "يلتقي": 1,
    "الهزة": 1,
    "الهزات": 1,
    "أدن": 1,
    "مدان": 1,
    "الإدانات": 1,
    "إداني": 1,
    "المستنكر": 1,
    "المتكثف": 1,
    "المتكثفات": 1,
    "للتكثيف": 1,
    "متنازل": 1,
    "التابل": 1,
    "شرطي": 1,
    "مشروط": 1,
    "المكيفات": 1,
    "الشروط": 1,
    "عزائي": 1,
    "معزى": 1,
    "التعزية": 1,
    "التعازي": 1,
    "يعزي": 1,
    "الواقية": 1,
    "إقبل": 1,
    "الكندور": 1,
    "أد": 1,
    "مؤدى": 1,
    "يؤدي": 1,
    "الإداء": 1,
    "باعث": 1,
    "المواصلة": 1,
    "مجرى": 1,
    "للتوصيل": 1,
    "الإجراء": 1,
    "التوصيلات": 1,
    "المخروط": 1,
    "كونيكتيفا": 1,
    "الردبكية": 1,
    "المخاريط": 1,
    "الكونستوغة": 1,
    "تحادث": 1,
    "متحادث": 1,
    "يتحادث": 1,
    "المحادثة": 1,
    "الحلواني": 1,
    "الحلوانيون": 1,
    "يركب": 1,
    "الإتحادات": 1,
    "كونفدرالي": 1,
    "تشاور": 1,
    "المشاور": 1,
    "المشاورون": 1,
    "إجراء": 1,
    "الإهداء": 1,
    "للاجتماع": 1,
    "المانح": 1,
    "المانحون": 1,
    "التشاور": 1,
    "يتشاور": 1,
    "كرسي": 1,
    "إعترافي": 1,
    "كراسي": 1,
    "الأعتراف": 1,
    "الإعترافات": 1,
    "المعترف": 1,
    "المعترفون": 1,
    "النثار": 1,
    "المستشارة": 1,
    "إعهد": 1,
    "معهود": 1,
    "المخبر": 1,
    "المخبرين": 1,
    "يعهد": 1,
    "العهد": 1,
    "إعداد": 1,
    "إصدارةالتعريف": 1,
    "شكلي": 1,
    "التشكيل": 1,
    "وفق": 1,
    "مشكل": 1,
    "محصور": 1,
    "المقيد": 1,
    "الإنحصار": 1,
    "مؤكد": 1,
    "تأكيدي": 1,
    "صادر": 1,
    "يصادر": 1,
    "المصادرة": 1,
    "المصادرات": 1,
    "الحريق": 1,
    "الحرائق": 1,
    "النزاع": 1,
    "التضارب": 1,
    "نزاعي": 1,
    "النزاعات": 1,
    "الملتقى": 1,
    "الملتقيات": 1,
    "مندمج": 1,
    "البؤر": 1,
    "مطابق": 1,
    "مطيعة": 1,
    "إمتثالي": 1,
    "بتكوين": 1,
    "يتوافق": 1,
    "فند": 1,
    "بامتعاظ": 1,
    "التفنيد": 1,
    "يفند": 1,
    "المجابهة": 1,
    "مجابهي": 1,
    "المجابهات": 1,
    "مواجه": 1,
    "المواجه": 1,
    "المواجهين": 1,
    "يواجه": 1,
    "كونفوشيوس": 1,
    "(الفيلسوف": 1,
    "الصيني)": 1,
    "الكونفوشيوسية": 1,
    "اﻷرباك": 1,
    "بارباك": 1,
    "بارتباك": 1,
    "المحيرين": 1,
    "الدحض": 1,
    "إدحض": 1,
    "مدحوض": 1,
    "المفند": 1,
    "يدحض": 1,
    "التصلب": 1,
    "المجانس": 1,
    "تناسبي": 1,
    "ثعبان": 1,
    "إزدحم": 1,
    "الإزدحام": 1,
    "إزدحامي": 1,
    "يزدحم": 1,
    "مكتل": 1,
    "التكتلات": 1,
    "الكونغو": 1,
    "الكونغوليون": 1,
    "هنئ": 1,
    "مهنأ": 1,
    "يهنئ": 1,
    "التهنئة": 1,
    "التهنأة": 1,
    "التهاني": 1,
    "المهنيء": 1,
    "تهنيئي": 1,
    "تجمعي": 1,
    "جماعاتي": 1,
    "الأبرشي": 1,
    "الكونجرس": 1,
    "عضوات": 1,
    "التطابق": 1,
    "متطابق": 1,
    "الإتفاقيات": 1,
    "الإتفاق": 1,
    "اﻷتفاق": 1,
    "مخروطي": 1,
    "المخروطية": 1,
    "هندسة": 1,
    "المخروطيات": 1,
    "الصنوبرية": 1,
    "صنوبري": 1,
    "الصنوبريات": 1,
    "مخمن": 1,
    "تخميني": 1,
    "التخمين": 1,
    "محدوس": 1,
    "المخمن": 1,
    "الحدس": 1,
    "وحد": 1,
    "موحد": 1,
    "التوحيد": 1,
    "يوحد": 1,
    "زوجي": 1,
    "صرفي": 1,
    "الإقتران": 1,
    "إقتراني": 1,
    "الإرتباطات": 1,
    "الملتحمة": 1,
    "رابط": 1,
    "الرمد": 1,
    "حروف": 1,
    "الأزمة": 1,
    "الأزمات": 1,
    "تحضير": 1,
    "الأرواح": 1,
    "التعاويذ": 1,
    "مناشد": 1,
    "المشعوذ": 1,
    "المشعوذون": 1,
    "الشعوذة": 1,
    "الأنف": 1,
    "متعطل": 1,
    "الكونكر": 1,
    "(ثمر": 1,
    "يؤكل)": 1,
    "التعطيل": 1,
    "الأنوف": 1,
    "كونلي": 1,
    "خلقي": 1,
    "أوصل": 1,
    "للربط": 1,
    "بارتباط": 1,
    "الموصل": 1,
    "الموصلات": 1,
    "كونيكتيكت": 1,
    "الإيصال": 1,
    "برابطة": 1,
    "يوصل": 1,
    "مخدوع": 1,
    "كونيلي": 1,
    "كونير": 1,
    "كوني": 1,
    "نوبة": 1,
    "الهستيرية": 1,
    "التغاضي": 1,
    "المتستر": 1,
    "ذواق": 1,
    "الخبرة": 1,
    "التذوق": 1,
    "كونورس": 1,
    "التضمين": 1,
    "تلميحي": 1,
    "إعن": 1,
    "معني": 1,
    "يعني": 1,
    "كونس": 1,
    "زيجي": 1,
    "الزوجية": 1,
    "إفتح": 1,
    "مقهور": 1,
    "محتل": 1,
    "الفتح": 1,
    "باخضاع": 1,
    "الفاتح": 1,
    "الفاتحون": 1,
    "يفتح": 1,
    "الفتوحات": 1,
    "كونراد": 1,
    "قرابة": 1,
    "الرحم": 1,
    "الضمير": 1,
    "الضمائر": 1,
    "واعي": 1,
    "شعوريا": 1,
    "المجند": 1,
    "مجند": 1,
    "التجنيد": 1,
    "المجندون": 1,
    "مكرس": 1,
    "يكرس": 1,
    "التكريس": 1,
    "متتالي": 1,
    "بالتوالي": 1,
    "التتالي": 1,
    "متلازم": 1,
    "الإجماع": 1,
    "بموافقة": 1,
    "النتائج": 1,
    "ناتج": 1,
    "لاحقا": 1,
    "ولذلك": 1,
    "للحفظ": 1,
    "المحافظون": 1,
    "الحمايات": 1,
    "المحافظية": 1,
    "محافظ": 1,
    "الواقي": 1,
    "إحفظ": 1,
    "محفوظ": 1,
    "الموفر": 1,
    "إعتبر": 1,
    "مراعي": 1,
    "لشعور": 1,
    "المراعاة": 1,
    "الإعتبار": 1,
    "الإعتبارات": 1,
    "معتبر": 1,
    "يعتبر": 1,
    "للإيداع": 1,
    "مودع": 1,
    "المرسل": 1,
    "الإيداع": 1,
    "الشحنة": 1,
    "الشحنات": 1,
    "يودع": 1,
    "تضمن": 1,
    "متضمن": 1,
    "الإتساق": 1,
    "يتضمن": 1,
    "المعزى": 1,
    "(مستقبل": 1,
    "العزاء)": 1,
    "مواسى": 1,
    "المؤازر": 1,
    "المؤازرون": 1,
    "إدعم": 1,
    "مدعوم": 1,
    "الدعم": 1,
    "بمواساة": 1,
    "القرين": 1,
    "معاشر": 1,
    "المالية": 1,
    "المعاشرة": 1,
    "الإئتلاف": 1,
    "الأقران": 1,
    "المؤامرات": 1,
    "المؤامرة": 1,
    "المتآمر": 1,
    "تآمري": 1,
    "المتآمرون": 1,
    "شرطة": 1,
    "كونستانس": 1,
    "الثبات": 1,
    "قسطنطين": 1,
    "القسطنطينية": 1,
    "الثوابت": 1,
    "إسطع": 1,
    "أرعب": 1,
    "يرعب": 1,
    "الإرعاب": 1,
    "أمسك": 1,
    "الإمساك": 1,
    "الإنتخابية": 1,
    "الانتخابية": 1,
    "الناخب": 1,
    "تأسيسي": 1,
    "الناخبون": 1,
    "دستوري": 1,
    "الدستورية": 1,
    "دستوريا": 1,
    "أعق": 1,
    "باكراه": 1,
    "الإعاقة": 1,
    "يعيق": 1,
    "القيود": 1,
    "التباين": 1,
    "قلص": 1,
    "مقلص": 1,
    "التقليص": 1,
    "الإنقباض": 1,
    "تضيقي": 1,
    "يقلص": 1,
    "مبني": 1,
    "بنائي": 1,
    "بتركيب": 1,
    "الإنشاءات": 1,
    "الإيجابية": 1,
    "البنائون": 1,
    "التركيبات": 1,
    "فسر": 1,
    "مفسر": 1,
    "يفسر": 1,
    "التفسير": 1,
    "مشاركة": 1,
    "النمط": 1,
    "كونسوتود": 1,
    "القنصل": 1,
    "قنصلي": 1,
    "كونسولات": 1,
    "القنصلية": 1,
    "القنصليات": 1,
    "القناصل": 1,
    "إستشر": 1,
    "الإستشارة": 1,
    "إستشارة": 1,
    "الإستشارات": 1,
    "إستشاري": 1,
    "مستشار": 1,
    "يستشير": 1,
    "مستهلك": 1,
    "إستهلك": 1,
    "المستهلك": 1,
    "الاستهلاك": 1,
    "المستهلكون": 1,
    "يستهلك": 1,
    "باستهلاك": 1,
    "الإتمام": 1,
    "إكمالي": 1,
    "المسلول": 1,
    "سلي": 1,
    "اﻷستهلاكي": 1,
    "العدوى": 1,
    "معدي": 1,
    "إحتو": 1,
    "للإحتواء": 1,
    "محتوى": 1,
    "الحاوية": 1,
    "الحاويات": 1,
    "الإحتواء": 1,
    "الملوث": 1,
    "ملوث": 1,
    "التلوث": 1,
    "تلوثي": 1,
    "تأمل": 1,
    "يتأمل": 1,
    "تأملي": 1,
    "البقاء": 1,
    "(في": 1,
    "الوقت)": 1,
    "الإحتقار": 1,
    "الإذلال": 1,
    "حقير": 1,
    "الازدراء": 1,
    "باحتقار": 1,
    "محتقر": 1,
    "الإستخفاف": 1,
    "المحتوى": 1,
    "قانع": 1,
    "باقتناع": 1,
    "الإقتناع": 1,
    "الزعم": 1,
    "المزاعم": 1,
    "بالحاح": 1,
    "المجادلة": 1,
    "بقناعة": 1,
    "القناعة": 1,
    "المحتويات": 1,
    "المسابقة": 1,
    "للنقض": 1,
    "المتسابق": 1,
    "المتسابقون": 1,
    "متنازع": 1,
    "السياقات": 1,
    "سياقي": 1,
    "التلامس": 1,
    "قاري": 1,
    "باعتدال": 1,
    "القارات": 1,
    "حالات": 1,
    "الطوارئ": 1,
    "الإستمرار": 1,
    "واصل": 1,
    "المواصل": 1,
    "يواصل": 1,
    "الاستمرارية": 1,
    "إلو": 1,
    "ملوي": 1,
    "اللوي": 1,
    "اللي": 1,
    "التشويهات": 1,
    "ملتوي": 1,
    "يلوى": 1,
    "أحيط": 1,
    "الإحاطة": 1,
    "المهربة": 1,
    "كونتراباس": 1,
    "للحمل": 1,
    "متقلص": 1,
    "منقبض": 1,
    "المقاولات": 1,
    "الإنكماش": 1,
    "إنقباضي": 1,
    "المقاول": 1,
    "المقاولون": 1,
    "العقود": 1,
    "تعاقدي": 1,
    "تعاقديا": 1,
    "مناقض": 1,
    "المناقضة": 1,
    "التناقض": 1,
    "التناقضات": 1,
    "يناقض": 1,
    "بالصفات": 1,
    "المعاكسة": 1,
    "بالتغاير": 1,
    "بالتضاد": 1,
    "غيمة": 1,
    "صناعية": 1,
    "(صنع": 1,
    "الانسان)": 1,
    "بطلان": 1,
    "مبين": 1,
    "يبين": 1,
    "تبيين": 1,
    "الاستعمال": 1,
    "كونترالتو": 1,
    "البدعة": 1,
    "البدع": 1,
    "بالعكس": 1,
    "المعارضة": 1,
    "عكسيا": 1,
    "متغاير": 1,
    "التغاير": 1,
    "ناف": 1,
    "منافى": 1,
    "المخالف": 1,
    "ينافي": 1,
    "المنافاة": 1,
    "الإنتهاك": 1,
    "اشتباك": 1,
    "ساهم": 1,
    "مساهم": 1,
    "يساهم": 1,
    "المساهمة": 1,
    "المساهمات": 1,
    "تائب": 1,
    "تائبا": 1,
    "الإختراع": 1,
    "الإختراعات": 1,
    "دبر": 1,
    "التدبير": 1,
    "إتش": 1,
    "التحكمالجسم": 1,
    "التحكمالجسمالمقاطعة": 1,
    "المسيطر": 1,
    "مسيطر": 1,
    "للجدل": 1,
    "جداليا": 1,
    "الخلافات": 1,
    "الخلاف": 1,
    "خالف": 1,
    "نزاع": 1,
    "يخالف": 1,
    "متمرد": 1,
    "التمرد": 1,
    "رض": 1,
    "اللغز": 1,
    "الألغاز": 1,
    "المجتمعات": 1,
    "تعاف": 1,
    "متعافى": 1,
    "النقاهة": 1,
    "ناقه": 1,
    "يتعافى": 1,
    "التعافي": 1,
    "إنقل": 1,
    "المسخن": 1,
    "إجتمع": 1,
    "مجتمع": 1,
    "الداعي": 1,
    "الدعاة": 1,
    "يجتمع": 1,
    "وسائل": 1,
    "الإتفاقية": 1,
    "تقليدي": 1,
    "تمسك": 1,
    "بالتقاليد": 1,
    "تقليديا": 1,
    "بميثاق": 1,
    "(شخص)": 1,
    "تلاق": 1,
    "متلاقى": 1,
    "التقارب": 1,
    "التقاربات": 1,
    "متقارب": 1,
    "يتلاقى": 1,
    "التظافر": 1,
    "مطلع": 1,
    "تحادثي": 1,
    "المتحدث": 1,
    "بالمقابل": 1,
    "نقيض": 1,
    "الأحاديث": 1,
    "التحدث": 1,
    "المتحول": 1,
    "المحولون": 1,
    "التحول": 1,
    "المتحولون": 1,
    "محدبمقعر": 1,
    "التحدبات": 1,
    "التحدب": 1,
    "محرر": 1,
    "وثائق": 1,
    "محررون": 1,
    "إجراءات": 1,
    "الحامل": 1,
    "الحاملون": 1,
    "المدان": 1,
    "الإتهامات": 1,
    "تجريمي": 1,
    "المدانون": 1,
    "إقتنع": 1,
    "مقتنع": 1,
    "المقنع": 1,
    "المقنعون": 1,
    "يقتنع": 1,
    "باقناع": 1,
    "الاقناع": 1,
    "مؤنس": 1,
    "إدع": 1,
    "الدعوات": 1,
    "مدعو": 1,
    "يدعو": 1,
    "يلتوي": 1,
    "الإلتواءات": 1,
    "مواكب": 1,
    "المواكبة": 1,
    "تشنج": 1,
    "متشنج": 1,
    "يتشنج": 1,
    "التشنج": 1,
    "التشنجات": 1,
    "تشنجي": 1,
    "كونواي": 1,
    "إهدل": 1,
    "مهدول": 1,
    "الهدل": 1,
    "الطباخ": 1,
    "كوك": 1,
    "مطبوخ": 1,
    "الطباخات": 1,
    "الكوكي": 1,
    "الكوكيز": 1,
    "الطهي": 1,
    "الطباخون": 1,
    "مطعم": 1,
    "إهدأ": 1,
    "ولا": 1,
    "حار": 1,
    "غاز": 1,
    "بعملية": 1,
    "المبردة": 1,
    "برد": 1,
    "المبردات": 1,
    "الأعصاب": 1,
    "كوليدج": 1,
    "الحمال": 1,
    "الراكون": 1,
    "(حيوان)": 1,
    "كوبير": 1,
    "التعاونيات": 1,
    "صانعي": 1,
    "الأعشاش": 1,
    "تنسيقا": 1,
    "الاحداثية": 1,
    "النظراء": 1,
    "التنسيق": 1,
    "التنسيقات": 1,
    "تناسقي": 1,
    "المنسق": 1,
    "المنسقون": 1,
    "كورس": 1,
    "القملة": 1,
    "الكوبال": 1,
    "متحمل": 1,
    "كوبيلاند": 1,
    "كوبنهاكن": 1,
    "كوبرنيكي": 1,
    "كوبرنيكوس": 1,
    "(عالم": 1,
    "البولندي)": 1,
    "منسوخ": 1,
    "الناسخ": 1,
    "الناسخون": 1,
    "الإفريز": 1,
    "المستوى": 1,
    "البوليمر": 1,
    "يتكون": 1,
    "مونمر": 1,
    "كبريتات": 1,
    "الحديدوز": 1,
    "بالنحاس": 1,
    "الكوبرهيد": 1,
    "(أفعى)": 1,
    "القروش": 1,
    "الصفار": 1,
    "الصفارون": 1,
    "الأجمة": 1,
    "الأجمات": 1,
    "كوبرا": 1,
    "كوبرينس": 1,
    "(عيش": 1,
    "الغراب)": 1,
    "الصلة": 1,
    "بفعل": 1,
    "الصلات": 1,
    "زاوج": 1,
    "يزاوج": 1,
    "التزاوج": 1,
    "كوبولاتوري": 1,
    "الدفتر": 1,
    "الدفاتر": 1,
    "الطبع": 1,
    "انسخ": 1,
    "تدلع": 1,
    "الدلال": 1,
    "المغناج": 1,
    "متدلع": 1,
    "المغانيج": 1,
    "التدلع": 1,
    "الصديفي": 1,
    "المرجان": 1,
    "الا": 1,
    "المرجانية": 1,
    "مرجاني": 1,
    "كوربا": 1,
    "أسند": 1,
    "كوربيت": 1,
    "مشدود": 1,
    "بالحبال": 1,
    "كوردر": 1,
    "وديا": 1,
    "الودية": 1,
    "العصائر": 1,
    "الكوردايت": 1,
    "قرطبة": 1,
    "الأنطقة": 1,
    "القرطبي": 1,
    "كودري": 1,
    "الأقطان": 1,
    "الصميم": 1,
    "رئيسي": 1,
    "كوري": 1,
    "كورك": 1,
    "مسدود": 1,
    "بفلينة": 1,
    "الممتاز": 1,
    "الممتازين": 1,
    "الفلين": 1,
    "فليني": 1,
    "الغاق": 1,
    "الغاقات": 1,
    "كوز": 1,
    "القرنية": 1,
    "كورنيليا": 1,
    "كورنيليوس": 1,
    "كورنيل": 1,
    "حقل": 1,
    "حقول": 1,
    "وردة": 1,
    "أسخف": 1,
    "كورنينج": 1,
    "كورنيشي": 1,
    "الدقيق": 1,
    "ساق": 1,
    "نشا": 1,
    "كورنوال": 1,
    "بتلة": 1,
    "التويج": 1,
    "كورونادو": 1,
    "تاجي": 1,
    "التاجية": 1,
    "يتوج": 1,
    "التتويج": 1,
    "قاض": 1,
    "بالوفاة": 1,
    "قضاة": 1,
    "متوج": 1,
    "التويجات": 1,
    "العريف": 1,
    "بدني": 1,
    "بالشركات": 1,
    "نقابي": 1,
    "المادية": 1,
    "الهيئة": 1,
    "البدانة": 1,
    "كرية": 1,
    "نووي": 1,
    "كورالس": 1,
    "الحضائر": 1,
    "للتصليح": 1,
    "التصحيح": 1,
    "تصليحي": 1,
    "التصحيحات": 1,
    "بتصحيح": 1,
    "الصواب": 1,
    "المصلح": 1,
    "يصحح": 1,
    "بتبادل": 1,
    "راسل": 1,
    "مراسل": 1,
    "المراسلة": 1,
    "المراسلات": 1,
    "المراسل": 1,
    "المراسلون": 1,
    "تماثليا": 1,
    "يراسل": 1,
    "تصويب": 1,
    "للإصلاح": 1,
    "أيد": 1,
    "إثباتي": 1,
    "الكوربوري": 1,
    "(احتفال)": 1,
    "تصدأ": 1,
    "متصدأ": 1,
    "يتصدأ": 1,
    "للتاكل": 1,
    "التصدأ": 1,
    "بتآكل": 1,
    "عوامل": 1,
    "موج": 1,
    "يموج": 1,
    "التموج": 1,
    "التمويج": 1,
    "التمويجات": 1,
    "إفسادي": 1,
    "يفسد": 1,
    "صدر": 1,
    "الفستان": 1,
    "المخصر": 1,
    "الكورسيهات": 1,
    "كورسيكا": 1,
    "كورسيكي": 1,
    "لحائي": 1,
    "القشور": 1,
    "كورتيكوستيرويد": 1,
    "هرمونات": 1,
    "منشطة": 1,
    "الكورتيزون": 1,
    "كورتلاند": 1,
    "الألمونيوم": 1,
    "تألق": 1,
    "متألق": 1,
    "يتألق": 1,
    "التألق": 1,
    "غراب": 1,
    "جتا": 1,
    "نسبة": 1,
    "الضلع": 1,
    "التمام": 1,
    "الهراوة": 1,
    "كوشر": 1,
    "الموقعون": 1,
    "الدفء": 1,
    "مستحضرات": 1,
    "نشأة": 1,
    "الكون": 1,
    "بالكوزموغرافيا": 1,
    "أوصاف": 1,
    "كوزمولوجي": 1,
    "الكوزمولوجيا": 1,
    "الروسي": 1,
    "وشكل": 1,
    "عالمي": 1,
    "الكونية": 1,
    "الرحالة": 1,
    "كوزموبوليتيكي": 1,
    "الراعي": 1,
    "كفل": 1,
    "الرعاة": 1,
    "القوزاقي": 1,
    "الكلفة": 1,
    "توفير": 1,
    "كوستا": 1,
    "كوستيلو": 1,
    "الخضري": 1,
    "التكاليف": 1,
    "أغلى": 1,
    "الغلاء": 1,
    "غالي": 1,
    "الكلف": 1,
    "البدلة": 1,
    "بالملابس": 1,
    "مصمم": 1,
    "الأزياء": 1,
    "مصممو": 1,
    "البدلات": 1,
    "الإلباس": 1,
    "المهد": 1,
    "الشلة": 1,
    "مقربة": 1,
    "كوتيلون": 1,
    "(رقصة)": 1,
    "السفرجلية": 1,
    "المهود": 1,
    "كوتا": 1,
    "الكوخ": 1,
    "الأكواخ": 1,
    "القطن": 1,
    "بذرة": 1,
    "النبتة": 1,
    "النبتات": 1,
    "الأريكة": 1,
    "مبسوط": 1,
    "الأرائك": 1,
    "البسط": 1,
    "السعال": 1,
    "ساعل": 1,
    "يسعل": 1,
    "يكح": 1,
    "الكولون": 1,
    "كهربائية": 1,
    "حديدة": 1,
    "المحراث": 1,
    "الوزير": 1,
    "الوزراء": 1,
    "المحليون": 1,
    "الوزيرة": 1,
    "المحليات": 1,
    "منصوح": 1,
    "الإحصاء": 1,
    "العد": 1,
    "للعد": 1,
    "التنازلي": 1,
    "الطلعة": 1,
    "يحابي": 1,
    "الطلعات": 1,
    "صد": 1,
    "مصدود": 1,
    "الصد": 1,
    "بتضاد": 1,
    "الموازنة": 1,
    "موازن": 1,
    "يوازن": 1,
    "المعاكس": 1,
    "بعكس": 1,
    "الثقافة": 1,
    "محتج": 1,
    "مكافحة": 1,
    "الجاسوسية": 1,
    "بالدليل": 1,
    "بالأدلة": 1,
    "التزييف": 1,
    "المزور": 1,
    "التزييفات": 1,
    "الإحتجاج": 1,
    "المحل": 1,
    "ملغى": 1,
    "الإجراءات": 1,
    "البائعين": 1,
    "الحركات": 1,
    "مزج": 1,
    "الألحان": 1,
    "نتيجة": 1,
    "بنتائج": 1,
    "سلبية": 1,
    "الإقتراح": 1,
    "كلمة": 1,
    "وسع": 1,
    "التوسيع": 1,
    "يوسع": 1,
    "كونترتوب": 1,
    "الكونتيسة": 1,
    "الكونتيسات": 1,
    "معدود": 1,
    "البلدان": 1,
    "ريفيا": 1,
    "الريف": 1,
    "المواطنات": 1,
    "التهم": 1,
    "الزوج": 1,
    "المقرنة": 1,
    "المقرنات": 1,
    "الأزواج": 1,
    "الإثنان": 1,
    "الإزدواج": 1,
    "القسيمة": 1,
    "الكوبونات": 1,
    "الإنقلابات": 1,
    "العسكرية": 1,
    "الساعي": 1,
    "السعاة": 1,
    "الجواد": 1,
    "السريع": 1,
    "التعقيب": 1,
    "العدل": 1,
    "مراود": 1,
    "مهذب": 1,
    "بأدب": 1,
    "التهذيب": 1,
    "المداعب": 1,
    "المداعبون": 1,
    "المجاملات": 1,
    "دور": 1,
    "المراودة": 1,
    "الاحترام": 1,
    "كورتناي": 1,
    "المحاكم": 1,
    "المغازلة": 1,
    "الفناءات": 1,
    "الكوسكوس": 1,
    "(أكلة": 1,
    "أفريقية)": 1,
    "تساهمي": 1,
    "التغايرات": 1,
    "معاهد": 1,
    "المتفق": 1,
    "المجتمع": 1,
    "تغطيته": 1,
    "مفرش": 1,
    "بسرية": 1,
    "إطلب": 1,
    "مرغوب": 1,
    "مطلوب": 1,
    "كوفيتر": 1,
    "برغبة": 1,
    "الإشتهاء": 1,
    "السرب": 1,
    "كوفينجتون": 1,
    "البقرة": 1,
    "ماسوني": 1,
    "الجبان": 1,
    "جبان": 1,
    "الجبناء": 1,
    "جرس": 1,
    "كاوبيرد": 1,
    "(طائر": 1,
    "الماشية)": 1,
    "سياج": 1,
    "إنكمش": 1,
    "منكمش": 1,
    "ينكمش": 1,
    "راعية": 1,
    "يجلد": 1,
    "بجلد": 1,
    "زريبة": 1,
    "إجبار": 1,
    "القلنسوة": 1,
    "مقلنس": 1,
    "الخصلة": 1,
    "القلنسة": 1,
    "يقلنس": 1,
    "زميل": 1,
    "زملاء": 1,
    "لوبياء": 1,
    "السائس": 1,
    "جدري": 1,
    "الأبقار": 1,
    "زهر": 1,
    "الحقل": 1,
    "الدفة": 1,
    "المغرور": 1,
    "ذئب": 1,
    "البراري": 1,
    "الكيب": 1,
    "(قارض": 1,
    "أمريكي)": 1,
    "براحة": 1,
    "محاسب": 1,
    "موثق": 1,
    "الولاية": 1,
    "تدليك": 1,
    "ﻷعادة": 1,
    "المعالجالتقليب": 1,
    "وحدات": 1,
    "كر": 1,
    "مخربش": 1,
    "بمرارة": 1,
    "تعكر": 1,
    "المزاج": 1,
    "الخربشة": 1,
    "القبقب": 1,
    "يخربش": 1,
    "القمع": 1,
    "متصدع": 1,
    "المفرقع": 1,
    "المفرقعات": 1,
    "التصدع": 1,
    "مطقطق": 1,
    "الطقطقة": 1,
    "مكسر": 1,
    "المجنون": 1,
    "مهزوز": 1,
    "الهز": 1,
    "الحرفة": 1,
    "إحتيالا": 1,
    "باحتيال": 1,
    "الحيلة": 1,
    "الصناعة": 1,
    "مهارة": 1,
    "الصنعة": 1,
    "الحرفيون": 1,
    "محتال": 1,
    "كريج": 1,
    "إحشر": 1,
    "محشور": 1,
    "التحميل": 1,
    "النعل": 1,
    "يحشر": 1,
    "كراندال": 1,
    "الرافعة": 1,
    "مرفوع": 1,
    "الشبت": 1,
    "الرافعات": 1,
    "كرانفورد": 1,
    "كرانيا": 1,
    "الرفع": 1,
    "الساعد": 1,
    "علبة": 1,
    "النزعة": 1,
    "الغريبة": 1,
    "التحريك": 1,
    "المرفق": 1,
    "السواعد": 1,
    "متشقق": 1,
    "كرانستون": 1,
    "الفضلات": 1,
    "الكريب": 1,
    "يخصل": 1,
    "الكرابي": 1,
    "(سمكة)": 1,
    "التحطم": 1,
    "متحطم": 1,
    "المحطم": 1,
    "الضيف": 1,
    "المحطمون": 1,
    "الضيوف": 1,
    "مدعوون": 1,
    "حوادث": 1,
    "التحطيم": 1,
    "ببلادة": 1,
    "إشته": 1,
    "مشتهى": 1,
    "يشتهي": 1,
    "بتشوق": 1,
    "الحوصلة": 1,
    "جراد": 1,
    "كراوفورد": 1,
    "الزحف": 1,
    "الزاحف": 1,
    "الزواحف": 1,
    "يزحف": 1,
    "الصيانة": 1,
    "مقرف": 1,
    "بطيئة": 1,
    "الملون": 1,
    "الهوس": 1,
    "يجنن": 1,
    "بجنون": 1,
    "التجنن": 1,
    "الصرير": 1,
    "مصرور": 1,
    "مدهون": 1,
    "للصفرة": 1,
    "معامل": 1,
    "الألبان": 1,
    "أدوات": 1,
    "القشدة": 1,
    "معمل": 1,
    "دهنا": 1,
    "الدسامة": 1,
    "الطوية": 1,
    "متجعد": 1,
    "كريسير": 1,
    "الطويات": 1,
    "التجعيد": 1,
    "إخلق": 1,
    "مخلوق": 1,
    "اعملعدل": 1,
    "يخلق": 1,
    "الخلق": 1,
    "قصة": 1,
    "المخلوقات": 1,
    "مبدع": 1,
    "خلاق": 1,
    "الإبداع": 1,
    "الخالق": 1,
    "الصناع": 1,
    "المخلوق": 1,
    "بكينونة": 1,
    "الحضانة": 1,
    "الحضانات": 1,
    "المؤهل": 1,
    "الإعتماد": 1,
    "موثوق": 1,
    "جدارة": 1,
    "الإئتمان": 1,
    "الدائن": 1,
    "الدائنون": 1,
    "الإعتمادات": 1,
    "العقيدة": 1,
    "السذاجة": 1,
    "كري": 1,
    "المذهب": 1,
    "عقائدي": 1,
    "المذاهب": 1,
    "كريكسايد": 1,
    "إزحف": 1,
    "زحف": 1,
    "مرعبة": 1,
    "القشعريرة": 1,
    "كريغتن": 1,
    "إحرق": 1,
    "محروق": 1,
    "يحرق": 1,
    "إحراق": 1,
    "حرقي": 1,
    "كرييولي": 1,
    "كريون": 1,
    "الهلال": 1,
    "الأهلة": 1,
    "الرشاد": 1,
    "مكتئب": 1,
    "كريستفيو": 1,
    "طباشيريا": 1,
    "كريتي": 1,
    "كريت": 1,
    "المشوه": 1,
    "المشوهون": 1,
    "الأخدود": 1,
    "الأخاديد": 1,
    "الطاقم": 1,
    "الغزل": 1,
    "الصوفي": 1,
    "طاقم": 1,
    "الطواقم": 1,
    "الكريبج": 1,
    "الكريكت": 1,
    "الكريكيت": 1,
    "منادي": 1,
    "منادو": 1,
    "كريمي": 1,
    "أوكرانية": 1,
    "القرم": 1,
    "الجرائم": 1,
    "المجرم": 1,
    "إجراميا": 1,
    "المجرمون": 1,
    "باحث": 1,
    "باحثون": 1,
    "الإجرام": 1,
    "إعقص": 1,
    "معقوص": 1,
    "المكشكش": 1,
    "العقص": 1,
    "يعقص": 1,
    "كرمبي": 1,
    "القرمزة": 1,
    "تذلل": 1,
    "متذلل": 1,
    "المهرول": 1,
    "يتذلل": 1,
    "التذلل": 1,
    "مجعد": 1,
    "يجعد": 1,
    "التجعد": 1,
    "الكسيح": 1,
    "تستخدم": 1,
    "دباغة": 1,
    "الكسحاء": 1,
    "الشل": 1,
    "السادة": 1,
    "هشاشة": 1,
    "الاسكافي": 1,
    "كريسس": 1,
    "تقاطع": 1,
    "الشبكات": 1,
    "التقاطع": 1,
    "الناقد": 1,
    "الانتقاد": 1,
    "الخطورة": 1,
    "للنقد": 1,
    "إنتقد": 1,
    "الإنتقاد": 1,
    "النقاد": 1,
    "نوقش": 1,
    "يناقش": 1,
    "المناقشة": 1,
    "الناعب": 1,
    "الناعبون": 1,
    "كرواكي": 1,
    "كرواتيا": 1,
    "الكرواتي": 1,
    "الكروشيه": 1,
    "الحياكة": 1,
    "الكروشيهات": 1,
    "مسخم": 1,
    "كروكير": 1,
    "كروكيت": 1,
    "يسخم": 1,
    "التمساحي": 1,
    "الزعفران": 1,
    "كروفت": 1,
    "كروا": 1,
    "كرومويل": 1,
    "العجوز": 1,
    "الشمطاء": 1,
    "العجائز": 1,
    "الشمطاوات": 1,
    "الأزلام": 1,
    "المحتال": 1,
    "أعوج": 1,
    "باعوجاج": 1,
    "المحتالون": 1,
    "دندن": 1,
    "مدندن": 1,
    "الدندنة": 1,
    "يدندن": 1,
    "المحصول": 1,
    "المثمر": 1,
    "القص": 1,
    "الكروكيه": 1,
    "كروسبي": 1,
    "الصليب": 1,
    "عبر": 1,
    "للعبور": 1,
    "القرزبيل": 1,
    "هجن": 1,
    "سلس": 1,
    "(مطبات)": 1,
    "تيارات": 1,
    "سلسة": 1,
    "قطري": 1,
    "منقطع": 1,
    "معبور": 1,
    "الصلبان": 1,
    "ظلل": 1,
    "يظلل": 1,
    "التظليل": 1,
    "العبور": 1,
    "المعابر": 1,
    "متقاطع": 1,
    "الانتقال": 1,
    "التصالبي": 1,
    "تصالبي": 1,
    "المتبادل": 1,
    "معبر": 1,
    "المشاة": 1,
    "المتقاطع": 1,
    "صليب": 1,
    "المتقاطعة": 1,
    "المنشعب": 1,
    "متفرع": 1,
    "أوهامي": 1,
    "التخضع": 1,
    "جاثم": 1,
    "يجثم": 1,
    "الجثم": 1,
    "الخانوق": 1,
    "مائدة": 1,
    "القمار": 1,
    "المحمص": 1,
    "الجيفة": 1,
    "المخل": 1,
    "كروبيري": 1,
    "الازدحام": 1,
    "المجمهر": 1,
    "الإحتشاد": 1,
    "أرجل": 1,
    "كرولي": 1,
    "التاج": 1,
    "المتوج": 1,
    "التيجان": 1,
    "الغربان": 1,
    "إنبوب": 1,
    "(شاشة": 1,
    "التلفزيون)": 1,
    "البودقة": 1,
    "مصلوب": 1,
    "المصلوب": 1,
    "إصلب": 1,
    "خثر": 1,
    "الخام": 1,
    "أغلظ": 1,
    "أقسى": 1,
    "قسوة": 1,
    "الإبريق": 1,
    "الزجاجي": 1,
    "الغبار": 1,
    "المتراكم": 1,
    "البنية": 1,
    "الجولة": 1,
    "منطلق": 1,
    "الطراد": 1,
    "الطرادات": 1,
    "الجولات": 1,
    "الفتات": 1,
    "مفتت": 1,
    "إنهر": 1,
    "ينهار": 1,
    "إنهيارا": 1,
    "متفتت": 1,
    "أردأ": 1,
    "دمدم": 1,
    "الفطيرة": 1,
    "تكوم": 1,
    "يتكوم": 1,
    "التكويم": 1,
    "مطحون": 1,
    "الجرش": 1,
    "تقسبا": 1,
    "كرانتشينيس": 1,
    "الطحن": 1,
    "مقسب": 1,
    "الصليبية": 1,
    "محارب": 1,
    "الصليبي": 1,
    "الصليبيون": 1,
    "يسحق": 1,
    "السحق": 1,
    "ساحق": 1,
    "القشرة": 1,
    "قشري": 1,
    "القشرية": 1,
    "متقشر": 1,
    "أيبس": 1,
    "يابس": 1,
    "التقشير": 1,
    "العكاز": 1,
    "العكازات": 1,
    "بط": 1,
    "كروز": 1,
    "البكاء": 1,
    "كثيرو": 1,
    "طفل": 1,
    "درجات": 1,
    "المتدنية": 1,
    "ثرموستات": 1,
    "تحليل": 1,
    "محلل": 1,
    "بالشفرة": 1,
    "غامض": 1,
    "بغموض": 1,
    "المشفرة": 1,
    "شفروي": 1,
    "البلور": 1,
    "بلوري": 1,
    "معادن": 1,
    "دقيقة": 1,
    "البلورة": 1,
    "بلور": 1,
    "مبلور": 1,
    "يبلور": 1,
    "التبلور": 1,
    "البلورات": 1,
    "البلوريات": 1,
    "سنيت": 1,
    "الشبل": 1,
    "كوبا": 1,
    "الكوبي": 1,
    "الكوبيون": 1,
    "الفجوة": 1,
    "الفجوات": 1,
    "مكعب": 1,
    "المكعبات": 1,
    "تكعيبي": 1,
    "كوبيس": 1,
    "التكعيب": 1,
    "التكعيبية": 1,
    "التعكيبي": 1,
    "الأشبال": 1,
    "الديوث": 1,
    "الديوثون": 1,
    "الوقواق": 1,
    "الخيار": 1,
    "القرع": 1,
    "المجتر": 1,
    "محضون": 1,
    "حبا": 1,
    "بالهراوة": 1,
    "الهراوات": 1,
    "ملقن": 1,
    "الكفة": 1,
    "مكبل": 1,
    "التكبيل": 1,
    "الكفات": 1,
    "التلقين": 1,
    "المطبخ": 1,
    "المطابخ": 1,
    "كولبيرتسون": 1,
    "المنتقي": 1,
    "النفايات": 1,
    "تتوج": 1,
    "متتوج": 1,
    "يتتوج": 1,
    "اهمال": 1,
    "الذنب": 1,
    "المذنبون": 1,
    "إزرع": 1,
    "مزروع": 1,
    "يزرع": 1,
    "المزارعون": 1,
    "ثقافيا": 1,
    "الثقافات": 1,
    "التثقيف": 1,
    "كولفير": 1,
    "كولفيرس": 1,
    "المجاري": 1,
    "معاق": 1,
    "الكمبرلاند": 1,
    "ببطئ": 1,
    "الازعاج": 1,
    "الكمون": 1,
    "راكم": 1,
    "مراكم": 1,
    "يراكم": 1,
    "المراكمة": 1,
    "السحاب": 1,
    "الركام": 1,
    "كونارد": 1,
    "مسماري": 1,
    "اللحس": 1,
    "للمرأة": 1,
    "كونينغهام": 1,
    "بمكر": 1,
    "النسوي": 1,
    "كبيرتينو": 1,
    "الفناجين": 1,
    "كيوبيد": 1,
    "الأطماع": 1,
    "الوغد": 1,
    "الشفاء": 1,
    "للعلاج": 1,
    "كرير": 1,
    "الخوري": 1,
    "الخورية": 1,
    "شافي": 1,
    "الأمين": 1,
    "مسؤوليا": 1,
    "الأمناء": 1,
    "الحراسة": 1,
    "مكبوح": 1,
    "الكبح": 1,
    "الرائب": 1,
    "يخثر": 1,
    "التخثير": 1,
    "الروائب": 1,
    "كيورير": 1,
    "الكوري": 1,
    "التحفة": 1,
    "فضولي": 1,
    "بفضول": 1,
    "الكوريوم": 1,
    "الضفيرة": 1,
    "ضافر": 1,
    "بكرة": 1,
    "بكرو": 1,
    "الكروان": 1,
    "الكورليكو": 1,
    "تجعيدا": 1,
    "الضفر": 1,
    "بخيل": 1,
    "كران": 1,
    "الكشمش": 1,
    "الحالية": 1,
    "حاليا": 1,
    "التيارات": 1,
    "المناهج": 1,
    "منهجي": 1,
    "المنهج": 1,
    "الكاري": 1,
    "كيوريش": 1,
    "بجبن": 1,
    "ملعون": 1,
    "بلعنة": 1,
    "الشر": 1,
    "القاذف": 1,
    "القاذفون": 1,
    "اللعنات": 1,
    "المؤشرة": 1,
    "بعجالة": 1,
    "المؤشرات": 1,
    "كيرت": 1,
    "قلل": 1,
    "ستارة": 1,
    "بالستائر": 1,
    "كيرتيس": 1,
    "باقتضاب": 1,
    "الأدب": 1,
    "إنحنى": 1,
    "إحتراما": 1,
    "إنحناء": 1,
    "الآداب": 1,
    "المنحنى": 1,
    "المنحن": 1,
    "منحن": 1,
    "التقويس": 1,
    "الإسناد": 1,
    "كوشمان": 1,
    "العند": 1,
    "المحلبي": 1,
    "كستر": 1,
    "وصائي": 1,
    "الحامي": 1,
    "الحماة": 1,
    "العادة": 1,
    "الجمرك": 1,
    "الجمارك": 1,
    "التحوير": 1,
    "المفصل": 1,
    "المفصلون": 1,
    "العادات": 1,
    "جلدي": 1,
    "جلديا": 1,
    "الإستقطاع": 1,
    "الإستقطاعات": 1,
    "بلطافة": 1,
    "كتيس": 1,
    "سيف": 1,
    "الملاحين": 1,
    "القاطعة": 1,
    "لوازم": 1,
    "المائدة": 1,
    "لحمة": 1,
    "القواطع": 1,
    "النقلة": 1,
    "جديد)": 1,
    "التخفيضات": 1,
    "القاطع": 1,
    "السفاح": 1,
    "السفاحون": 1,
    "جارح": 1,
    "القطوع": 1,
    "لسان": 1,
    "ألسنة": 1,
    "الحبار": 1,
    "سيان": 1,
    "سياناميد": 1,
    "(حمض)": 1,
    "سيانيت": 1,
    "السيانيد": 1,
    "تخيلي": 1,
    "معرفي": 1,
    "الكمبيوترية": 1,
    "المرتبطة": 1,
    "ببعضها": 1,
    "سيكاد": 1,
    "سيكلاديز": 1,
    "مايكيناين": 1,
    "السيكلامين": 1,
    "الدورة": 1,
    "دوريا": 1,
    "راكبو": 1,
    "سايكلوهيكسانول": 1,
    "كحول": 1,
    "زيتي": 1,
    "يبعث": 1,
    "رائحة": 1,
    "الدائري": 1,
    "الإعصار": 1,
    "الأعاصير": 1,
    "الموسوعة": 1,
    "موسوعي": 1,
    "دائرية": 1,
    "سايكلوتوميك": 1,
    "(نظرية": 1,
    "رياضية)": 1,
    "تحطيم": 1,
    "فرخ": 1,
    "الوز": 1,
    "كوكبة": 1,
    "سيل": 1,
    "الإسطوانة": 1,
    "الإسطوانات": 1,
    "اسطواني": 1,
    "إسطواني": 1,
    "إسطوانيا": 1,
    "النحاسي": 1,
    "المتهكم": 1,
    "متهكم": 1,
    "التهكم": 1,
    "المتهكمون": 1,
    "قبلة": 1,
    "الأنظار": 1,
    "قبلات": 1,
    "سينثيا": 1,
    "السرو": 1,
    "قبرصي": 1,
    "القبرصي": 1,
    "القبارصة": 1,
    "قبرص": 1,
    "سيريل": 1,
    "سيريلي": 1,
    "سيروس": 1,
    "سيستياين": 1,
    "حامض": 1,
    "الخراجات": 1,
    "الخلوية": 1,
    "انحلال": 1,
    "السيتوبلازم": 1,
    "السيتوساين": 1,
    "(حمض": 1,
    "نووي)": 1,
    "تشيكوسلوفاكيا": 1,
    "التشاردس": 1,
    "حفل": 1,
    "مجري": 1,
    "القيصرة": 1,
    "القيصرية": 1,
    "التشيكي": 1,
    "تشيكوسلوفاكي": 1,
    "ان": 1,
    "إخبارية": 1,
    "عالمية": 1,
    "كارلوتا": 1,
    "إمبراطورة": 1,
    "المكسيك": 1,
    "بلجيكية": 1,
    "سلكين": 1,
    "متقاطعين": 1,
    "النص": 1,
    "المشفر": 1,
    "سياحية": 1,
    "شهيرة": 1,
    "كمنتجع": 1,
    "سياحي": 1,
    "بحر": 1,
    "قزوين": 1,
    "الواقع": 1,
    "ايران": 1,
    "وروسيا": 1,
    "ملموس": 1,
    "الامس": 1,
    "اللمس": 1,
    "بلل": 1,
    "مبلل": 1,
    "المحترفون": 1,
    "يبلل": 1,
    "التبليل": 1,
    "اللمسات": 1,
    "داكا": 1,
    "النهري": 1,
    "الألمانية": 1,
    "الداكرون": 1,
    "ألياف": 1,
    "البوليستر": 1,
    "الصناعية": 1,
    "التفعيلة": 1,
    "بالإصبع": 1,
    "إصبع": 1,
    "داكتيلوجرام": 1,
    "التفعيلات": 1,
    "أﻷب": 1,
    "(لغة": 1,
    "اﻷطفال)": 1,
    "فنية": 1,
    "الدادائية": 1,
    "المنتسب": 1,
    "(دادا)": 1,
    "الآباء": 1,
    "بترنح": 1,
    "ددلس": 1,
    "(مخترع)": 1,
    "الشيطان": 1,
    "عته": 1,
    "النرجس": 1,
    "أزهار": 1,
    "دافي": 1,
    "بحمق": 1,
    "جرامات": 1,
    "الخنجر": 1,
    "الخناجر": 1,
    "الدال": 1,
    "(أعشاب": 1,
    "استوائية)": 1,
    "الداليا": 1,
    "داهومي": 1,
    "بلد": 1,
    "ديلي": 1,
    "الصحف": 1,
    "اليومية": 1,
    "يوميا": 1,
    "ديملير": 1,
    "مزرعة": 1,
    "لزرع": 1,
    "محاصيل": 1,
    "العلف": 1,
    "للمواشي": 1,
    "الحلابة": 1,
    "الحلابات": 1,
    "اللبان": 1,
    "اللبانون": 1,
    "الإقحوانات": 1,
    "ديسيويل": 1,
    "داكار": 1,
    "داكوتا": 1,
    "دايل": 1,
    "الأودية": 1,
    "داليث": 1,
    "لألفباء": 1,
    "العبري": 1,
    "دالي": 1,
    "دالاس": 1,
    "المضيع": 1,
    "للوقت": 1,
    "المضيعون": 1,
    "يداعب": 1,
    "داعب": 1,
    "دالتن": 1,
    "دالزيل": 1,
    "الضرر": 1,
    "للضرر": 1,
    "متضرر": 1,
    "الأضرار": 1,
    "ضار": 1,
    "دمشق": 1,
    "الدمقس": 1,
    "(حرير)": 1,
    "السيدة": 1,
    "داميد": 1,
    "السيدات": 1,
    "بسد": 1,
    "الملعون": 1,
    "بإدانة": 1,
    "دامون": 1,
    "الرطوبة": 1,
    "ثبط": 1,
    "الموهن": 1,
    "الموهنات": 1,
    "أرطب": 1,
    "التثبيط": 1,
    "رطب": 1,
    "الفتاة": 1,
    "الفتيات": 1,
    "دان": 1,
    "دانا": 1,
    "دانبري": 1,
    "الراقص": 1,
    "الراقصون": 1,
    "الهندباءات": 1,
    "الزغب": 1,
    "متأنق": 1,
    "الأنيقون": 1,
    "بتأنق": 1,
    "هز": 1,
    "يهز": 1,
    "قشرة": 1,
    "الأنيق": 1,
    "الدنماركي": 1,
    "الدنماركيون": 1,
    "لعينا": 1,
    "خطر": 1,
    "الأخطار": 1,
    "المتدلي": 1,
    "المتدلون": 1,
    "دانجلينجلي": 1,
    "دانيال": 1,
    "دانيلز": 1,
    "دانيلسون": 1,
    "دانماركي": 1,
    "داني": 1,
    "دانتي": 1,
    "دانيوب": 1,
    "بنهر": 1,
    "الدانوب": 1,
    "دانفيل": 1,
    "دانزيج": 1,
    "دافن": 1,
    "مرقط": 1,
    "يرقط": 1,
    "الترقيط": 1,
    "بنات": 1,
    "تجاسر": 1,
    "المخاطر": 1,
    "متجاسر": 1,
    "المتهورون": 1,
    "المخاطرون": 1,
    "يتجاسر": 1,
    "التجاسر": 1,
    "الجراءة": 1,
    "داريوس": 1,
    "(أحد": 1,
    "ملوك": 1,
    "فارس)": 1,
    "الظلام": 1,
    "ظلم": 1,
    "مظلم": 1,
    "المعتم": 1,
    "المعتمون": 1,
    "التظليم": 1,
    "يظلم": 1,
    "أظلم": 1,
    "إختف": 1,
    "المظلمة": 1,
    "الظلمات": 1,
    "موجة": 1,
    "قاتمة": 1,
    "دارلين": 1,
    "العزيز": 1,
    "الأعزاء": 1,
    "دارلينجتون": 1,
    "يا": 1,
    "إلاهي": 1,
    "مرتق": 1,
    "الرياف": 1,
    "الرواف": 1,
    "الترتيق": 1,
    "المرتقون": 1,
    "لمشاريع": 1,
    "البحث": 1,
    "دارل": 1,
    "دارو": 1,
    "محامي": 1,
    "داري": 1,
    "النبلة": 1,
    "مندفع": 1,
    "الإندفاع": 1,
    "دارتموث": 1,
    "النبلات": 1,
    "داروين": 1,
    "الداروني": 1,
    "الدارونية": 1,
    "الوثبة": 1,
    "شرطةنقطةنقطة": 1,
    "شرطةنقطة": 1,
    "الوثبات": 1,
    "الدنئ": 1,
    "الغدر,": 1,
    "دنيئ": 1,
    "دات": 1,
    "خاطبت": 1,
    "حساسة": 1,
    "بنوك": 1,
    "قواعد": 1,
    "حزمة": 1,
    "حزم": 1,
    "التأريخ": 1,
    "مؤرخ": 1,
    "داتيدنيس": 1,
    "اليومي": 1,
    "التواريخ": 1,
    "المجرور": 1,
    "لشركة": 1,
    "نيسان": 1,
    "مجصص": 1,
    "الزنبور": 1,
    "الزنابير": 1,
    "التجصيص": 1,
    "الأطلية": 1,
    "البنت": 1,
    "بناتي": 1,
    "تهيب": 1,
    "متهيب": 1,
    "التهيب": 1,
    "هياب": 1,
    "يتهيب": 1,
    "دلفين": 1,
    "ولي": 1,
    "ديف": 1,
    "دافينبورت": 1,
    "الكنبات": 1,
    "ديفيد": 1,
    "ديفيدسن": 1,
    "ديفي": 1,
    "دافيس": 1,
    "دافينيتش": 1,
    "ديفيس": 1,
    "دافيسون": 1,
    "روافع": 1,
    "الزرع": 1,
    "تسكع": 1,
    "متسكع": 1,
    "المتكسع": 1,
    "يتسكع": 1,
    "التسكع": 1,
    "طالع": 1,
    "الطلوع": 1,
    "غربان": 1,
    "داوسون": 1,
    "اليوم": 1,
    "سرير": 1,
    "حلم": 1,
    "إستغرق": 1,
    "أحلام": 1,
    "اليقضة": 1,
    "الحالم": 1,
    "الحالمون": 1,
    "إستغراق": 1,
    "الأيام": 1,
    "دايتون": 1,
    "دايتونا": 1,
    "المذهولون": 1,
    "الدوخ": 1,
    "إنبهار": 1,
    "البصر": 1,
    "مبهر": 1,
    "المبهر": 1,
    "المبهرون": 1,
    "الديسيبل": 1,
    "ي": 1,
    "ش": 1,
    "شس": 1,
    "يش": 1,
    "ديلاوير": 1,
    "الشماسة": 1,
    "عطل": 1,
    "يعطل": 1,
    "ميت": 1,
    "النهاية": 1,
    "المسدودة": 1,
    "خفت": 1,
    "مخفت": 1,
    "المعطل": 1,
    "التخفيت": 1,
    "بتعطيل": 1,
    "بافساد": 1,
    "يخفت": 1,
    "الخاوية": 1,
    "موتا": 1,
    "أخطر": 1,
    "النهائي": 1,
    "النهائية": 1,
    "الجمود": 1,
    "التجمد": 1,
    "مميت": 1,
    "الأغصان": 1,
    "أصم": 1,
    "أصمم": 1,
    "مصم": 1,
    "الإصمام": 1,
    "الصمم": 1,
    "يصم": 1,
    "الطرش": 1,
    "التجار": 1,
    "التعامل": 1,
    "التعاملات": 1,
    "تعامل": 1,
    "دين": 1,
    "ديان": 1,
    "دينيري": 1,
    "ديانا": 1,
    "العمادة": 1,
    "الغالي": 1,
    "ديربورن": 1,
    "المحبوب": 1,
    "كثيرا": 1,
    "المعزة": 1,
    "الندرة": 1,
    "فراش": 1,
    "أفرشة": 1,
    "المميتة": 1,
    "خالد": 1,
    "بخلود": 1,
    "الخلود": 1,
    "بالموت": 1,
    "الوفيات": 1,
    "الفخ": 1,
    "سوس": 1,
    "الخشب": 1,
    "(خنفساء)": 1,
    "إمنع": 1,
    "إنزل": 1,
    "النزول": 1,
    "نازل": 1,
    "ينزل": 1,
    "المنع": 1,
    "ممنوع": 1,
    "يمنع": 1,
    "خفض": 1,
    "مخفض": 1,
    "يخفض": 1,
    "للنقاش": 1,
    "مناقش": 1,
    "المناقش": 1,
    "المناقشون": 1,
    "الفسق": 1,
    "الفاسق": 1,
    "الفاسقون": 1,
    "ديبي": 1,
    "مضعف": 1,
    "الأوهان": 1,
    "المضعف": 1,
    "منقص": 1,
    "الإنقاص": 1,
    "الديون": 1,
    "ديبلوك": 1,
    "بشوش": 1,
    "الرقة": 1,
    "خفة": 1,
    "ديبورا": 1,
    "ديبرا": 1,
    "إستجوب": 1,
    "مستجوب": 1,
    "الإستجواب": 1,
    "يستجوب": 1,
    "الحطام": 1,
    "المدين": 1,
    "المدينون": 1,
    "المنقح": 1,
    "المنقحات": 1,
    "إفضح": 1,
    "مفضوح": 1,
    "كاشف": 1,
    "الزيف": 1,
    "الفضح": 1,
    "يفضح": 1,
    "ديبوسي": 1,
    "لأول": 1,
    "المبتدأ": 1,
    "ظهور": 1,
    "ديسمبر/كانون": 1,
    "عشري": 1,
    "منحط": 1,
    "بالنظام": 1,
    "الكافيين": 1,
    "كافين": 1,
    "جوانب": 1,
    "المعشر": 1,
    "السطوح": 1,
    "نزع": 1,
    "خلص": 1,
    "لترات": 1,
    "ينقل": 1,
    "العشرم": 1,
    "إرتحل": 1,
    "مرتحل": 1,
    "الإرتحال": 1,
    "الارتحال": 1,
    "يرتحل": 1,
    "مصبوب": 1,
    "الدوارق": 1,
    "الصب": 1,
    "المباراة": 1,
    "العشارية": 1,
    "ديكاتور": 1,
    "ديكا": 1,
    "يموت": 1,
    "الأموات": 1,
    "المخادعون": 1,
    "أبطأ": 1,
    "مبطأ": 1,
    "يبطئ": 1,
    "الإبطاء": 1,
    "الحشم": 1,
    "الحشمة": 1,
    "عشر": 1,
    "سنوات": 1,
    "محترم": 1,
    "إجعل": 1,
    "لامركزيا": 1,
    "سحب": 1,
    "اسحب": 1,
    "أزل": 1,
    "ازالة": 1,
    "الديسيبلات": 1,
    "اتخاذ": 1,
    "مقرر": 1,
    "قرر": 1,
    "يقيني": 1,
    "المقرر": 1,
    "يقرر": 1,
    "نفضي": 1,
    "تساقظ": 1,
    "اﻷوراق": 1,
    "الكسور": 1,
    "العشرية": 1,
    "عشريقيمة": 1,
    "يحطم": 1,
    "للفك": 1,
    "محلول": 1,
    "معالجات": 1,
    "الحل": 1,
    "فك": 1,
    "القرار": 1,
    "بالقرارات": 1,
    "القرارات": 1,
    "الطابق": 1,
    "ديكير": 1,
    "الطوابق": 1,
    "إخطب": 1,
    "مخطوب": 1,
    "الخطيب": 1,
    "المحاضر": 1,
    "الخطباء": 1,
    "الخطابة": 1,
    "الخطب": 1,
    "حماسي": 1,
    "للتصريح": 1,
    "تصريحي": 1,
    "معلن": 1,
    "منشور": 1,
    "إنشر": 1,
    "الإنحرافات": 1,
    "للرفض": 1,
    "الهبوط": 1,
    "هابط": 1,
    "النازل": 1,
    "النازلة": 1,
    "قبضة": 1,
    "معلوماتية": 1,
    "(شركة": 1,
    "ديجيتال)": 1,
    "ترجم": 1,
    "يترجم": 1,
    "الترجمة": 1,
    "الترجمات": 1,
    "عنق": 1,
    "لثوب": 1,
    "أنه": 1,
    "للتحلل": 1,
    "متفسخ": 1,
    "تفسخ": 1,
    "يتفسخ": 1,
    "التفسخ": 1,
    "تخفيف": 1,
    "التحرير": 1,
    "تزييني": 1,
    "النقاشون": 1,
    "اللياقة": 1,
    "الديكورات": 1,
    "انزع": 1,
    "لحاء": 1,
    "ينزع": 1,
    "مفصول": 1,
    "الأفخاخ": 1,
    "النقصان": 1,
    "التناقصات": 1,
    "متناقص": 1,
    "المرسوم": 1,
    "ديكرير": 1,
    "التناقص": 1,
    "تناقصي": 1,
    "هرم": 1,
    "التهالك": 1,
    "منتقص": 1,
    "ينتقص": 1,
    "إنتقص": 1,
    "الإنتقاص": 1,
    "يفك": 1,
    "ضاعف": 1,
    "أضعاف": 1,
    "يضاعف": 1,
    "مرات": 1,
    "المضاعفة": 1,
    "كرس": 1,
    "إهدائي": 1,
    "للاستنتاج": 1,
    "إخصم": 1,
    "مخصوم": 1,
    "الاقتطاع": 1,
    "إنقاصهم": 1,
    "الخصومات": 1,
    "إستنتاجي": 1,
    "إستنتاجيا": 1,
    "يخصم": 1,
    "(ترك": 1,
    "ارث)": 1,
    "يعتقد": 1,
    "العمق": 1,
    "المجمدة": 1,
    "معمق": 1,
    "التعميق": 1,
    "يعمق": 1,
    "أعمق": 1,
    "بعمق": 1,
    "الأيل": 1,
    "دير": 1,
    "مخترع": 1,
    "الأيائل": 1,
    "جلود": 1,
    "صياد": 1,
    "يشوه": 1,
    "الإختلاس": 1,
    "الإختلاسات": 1,
    "التشهير": 1,
    "التشهيرات": 1,
    "إطعن": 1,
    "السمعة": 1,
    "مشوهين": 1,
    "يطعن": 1,
    "التقصير": 1,
    "تخلف": 1,
    "يهمل": 1,
    "المقصرون": 1,
    "التقصيرات": 1,
    "اﻷطاحة": 1,
    "الإنهزامية": 1,
    "متخاذل": 1,
    "الإنهزاميون": 1,
    "الهزائم": 1,
    "مصفى": 1,
    "يصفي": 1,
    "التصفية": 1,
    "التغوط": 1,
    "الإرتداد": 1,
    "الإرتدادات": 1,
    "معيوب": 1,
    "الدفاعات": 1,
    "حصين": 1,
    "يدافع": 1,
    "يقذف": 1,
    "قذف": 1,
    "الدفاعي": 1,
    "مبرر": 1,
    "تبجيلي": 1,
    "الأجل": 1,
    "مرجأ": 1,
    "متحدي": 1,
    "بتحد": 1,
    "النقائص": 1,
    "بنقص": 1,
    "العجز": 1,
    "يتحدى": 1,
    "المضيق": 1,
    "الجبلي": 1,
    "مدنس": 1,
    "الملوثون": 1,
    "المضائق": 1,
    "الجبلية": 1,
    "التدنيس": 1,
    "للتعريف": 1,
    "عرف": 1,
    "المحدد": 1,
    "المحددون": 1,
    "يعرف": 1,
    "التعريف": 1,
    "تعريفي": 1,
    "التعاريف": 1,
    "إحترق": 1,
    "فرغ": 1,
    "مفرغ": 1,
    "المفرغ": 1,
    "يفرغ": 1,
    "التفريغ": 1,
    "إنكماشي": 1,
    "إنحرف": 1,
    "للانعكاس": 1,
    "منحرف": 1,
    "الإمالات": 1,
    "للانحراف": 1,
    "العاكس": 1,
    "إفتض": 1,
    "مفتض": 1,
    "الإفتضاض": 1,
    "يفتض": 1,
    "واضحة": 1,
    "ديفو": 1,
    "المسقط": 1,
    "المسقطات": 1,
    "الإسقاطات": 1,
    "للاسقاط": 1,
    "تشويهي": 1,
    "العاهات": 1,
    "العاهة": 1,
    "التجزئة": 1,
    "السارق": 1,
    "السارقون": 1,
    "إدفع": 1,
    "للدفع": 1,
    "جرد": 1,
    "مزيل": 1,
    "الصقيع": 1,
    "مزيلو": 1,
    "حاذق": 1,
    "الحذاقة": 1,
    "تحد": 1,
    "بتحدي": 1,
    "شهادة": 1,
    "دبلوم": 1,
    "أفرغ": 1,
    "الغاز": 1,
    "إفراغ": 1,
    "مغناطيسية": 1,
    "منحل": 1,
    "متحلل": 1,
    "الانحلال": 1,
    "المنحطون": 1,
    "إنحلالي": 1,
    "المهانة": 1,
    "أذلل": 1,
    "بتدهور": 1,
    "السفالة": 1,
    "المدهور": 1,
    "مهين": 1,
    "ديجوم": 1,
    "حيون": 1,
    "محيون": 1,
    "يحيون": 1,
    "الحيونة": 1,
    "التجفيف": 1,
    "جفف": 1,
    "مزيلات": 1,
    "يجفف": 1,
    "مجفف": 1,
    "ذوب": 1,
    "مذوب": 1,
    "التذويب": 1,
    "مؤله": 1,
    "يؤله": 1,
    "أله": 1,
    "التأليه": 1,
    "تكرم": 1,
    "متكرم": 1,
    "التكريم": 1,
    "يتكرم": 1,
    "الربوبية": 1,
    "الربوبي": 1,
    "بايمان": 1,
    "الربوبيون": 1,
    "شوهد": 1,
    "الإعتقاد": 1,
    "وضعا": 1,
    "جديدا": 1,
    "قد": 1,
    "حدث": 1,
    "محزن": 1,
    "باكتئاب": 1,
    "الكابة": 1,
    "الإكتئاب": 1,
    "ديكالب": 1,
    "حصة": 1,
    "ديلاني": 1,
    "مسرحي": 1,
    "ديلانو": 1,
    "ديلوار": 1,
    "المؤخر": 1,
    "المؤخرون": 1,
    "التأخيرات": 1,
    "ممتع": 1,
    "لذيذة": 1,
    "أبهج": 1,
    "للتفويض": 1,
    "الانتداب": 1,
    "المندوب": 1,
    "موفد": 1,
    "المندوبون": 1,
    "الإيفاد": 1,
    "الوفد": 1,
    "الوفود": 1,
    "إحذف": 1,
    "محذوف": 1,
    "مؤذي": 1,
    "يحذف": 1,
    "الحذوفات": 1,
    "دلهي": 1,
    "داليا": 1,
    "متعمد": 1,
    "بتعمد": 1,
    "العمد": 1,
    "الدراسة": 1,
    "المشاورات": 1,
    "تداولي": 1,
    "النقاشية": 1,
    "حساس": 1,
    "الرقيقة": 1,
    "المستحضرة": 1,
    "الجرم": 1,
    "البهجة": 1,
    "مسرور": 1,
    "الباعث": 1,
    "بالسرور": 1,
    "المسرات": 1,
    "ديليلا": 1,
    "موصوف": 1,
    "الجنوح": 1,
    "الجانح": 1,
    "بتقصير": 1,
    "الجانحون": 1,
    "هاذي": 1,
    "لحد": 1,
    "محلات": 1,
    "الوجبات": 1,
    "الجاهزة": 1,
    "سلم": 1,
    "للتسليم": 1,
    "النجاة": 1,
    "مسلم": 1,
    "المسلم": 1,
    "المسلمون": 1,
    "التسليم": 1,
    "يسلم": 1,
    "موصلو": 1,
    "ديل": 1,
    "ديلا": 1,
    "ديلوود": 1,
    "ديلمارفا": 1,
    "فل": 1,
    "مفلى": 1,
    "يفلي": 1,
    "التفلية": 1,
    "احدف": 1,
    "الصفحة": 1,
    "ديلفي": 1,
    "بديلفي": 1,
    "بالدرفيل": 1,
    "الدلفينيون": 1,
    "الدلفين": 1,
    "الدلتا": 1,
    "عضلة": 1,
    "دالية": 1,
    "ضلل": 1,
    "التضليل": 1,
    "خادعة": 1,
    "للعقل": 1,
    "الطوفان": 1,
    "مغرق": 1,
    "إحفر": 1,
    "المغناطيسية": 1,
    "مزال": 1,
    "ديماجنيتيزينت": 1,
    "ديماجوجية": 1,
    "الديماغوجي": 1,
    "الديماغوجية": 1,
    "الديماغوجيون": 1,
    "المطلب": 1,
    "الطالبون": 1,
    "المطالب": 1,
    "يخطط": 1,
    "التخطيطات": 1,
    "الخرف": 1,
    "الدامجانة": 1,
    "إنزع": 1,
    "منزوع": 1,
    "مشبوه": 1,
    "التنقية": 1,
    "التنقيات": 1,
    "الرحيل": 1,
    "انتهى": 1,
    "ثلاثيات": 1,
    "إستقل": 1,
    "استقال": 1,
    "الاستقالة": 1,
    "العينة": 1,
    "التسريح": 1,
    "سرح": 1,
    "مسرح": 1,
    "يسرح": 1,
    "الديمقراطيات": 1,
    "الديمقراطية": 1,
    "الديموقراطي": 1,
    "الديمقراطي": 1,
    "ديموقراطيا": 1,
    "الدمقرطة": 1,
    "دمقرط": 1,
    "مدمقرط": 1,
    "بالديموقراطية": 1,
    "يدمقرط": 1,
    "الدمقرط": 1,
    "الديمقراطيون": 1,
    "الإستخلاص": 1,
    "المستخلص": 1,
    "المستخلصات": 1,
    "سكاني": 1,
    "ديموجرافي": 1,
    "سكانيا": 1,
    "السكانية": 1,
    "مهدم": 1,
    "التهديم": 1,
    "التدمير": 1,
    "يدمر": 1,
    "الشيطانة": 1,
    "شيطاني": 1,
    "شيطانية": 1,
    "سمعة": 1,
    "مكروه": 1,
    "تشويه": 1,
    "الإثباتية": 1,
    "للاثبات": 1,
    "للعرض": 1,
    "تظاهر": 1,
    "متظاهر": 1,
    "يتظاهر": 1,
    "التظاهر": 1,
    "المظاهرة": 1,
    "المظاهرات": 1,
    "برهاني": 1,
    "المتظاهر": 1,
    "المتظاهرون": 1,
    "إضعاف": 1,
    "المعنويات": 1,
    "محبط": 1,
    "مثبط": 1,
    "الهمم": 1,
    "مثبطو": 1,
    "يحبط": 1,
    "الإحباط": 1,
    "للإحباط": 1,
    "ديمورجان": 1,
    "العينات": 1,
    "نزل": 1,
    "منزل": 1,
    "التنزيل": 1,
    "التنزيلات": 1,
    "ديمونت": 1,
    "ديمبسي": 1,
    "ملاكم": 1,
    "والذي": 1,
    "كان": 1,
    "بطل": 1,
    "للوزن": 1,
    "الثقيل": 1,
    "رزين": 1,
    "معترض": 1,
    "الإحتجاجات": 1,
    "التبسيط": 1,
    "الأسطورة": 1,
    "تجرد": 1,
    "المتجرد": 1,
    "العرين": 1,
    "خصخص": 1,
    "مخصخص": 1,
    "يخصخص": 1,
    "الخصخصة": 1,
    "بدل": 1,
    "مبدل": 1,
    "يبدل": 1,
    "تبديل": 1,
    "الزوائد": 1,
    "المتشجرة": 1,
    "شجيري": 1,
    "دينيب": 1,
    "دينيبولا": 1,
    "دينين": 1,
    "منكور": 1,
    "التكذيبات": 1,
    "منكر": 1,
    "الناكر": 1,
    "الناكرون": 1,
    "الإستصغار": 1,
    "مضر": 1,
    "الدينيم": 1,
    "دنيس": 1,
    "الدنمارك": 1,
    "يسمي": 1,
    "التسمية": 1,
    "طائفي": 1,
    "مقام": 1,
    "مقامات": 1,
    "دال": 1,
    "الدلالات": 1,
    "دلالي": 1,
    "مدلول": 1,
    "الخواتم": 1,
    "إشجب": 1,
    "مشجوب": 1,
    "الشجب": 1,
    "الشاجب": 1,
    "الشاجبون": 1,
    "يشجب": 1,
    "العرائن": 1,
    "الكثافات": 1,
    "لشدة": 1,
    "الطعجة": 1,
    "بالأسنان": 1,
    "النطعية": 1,
    "معجون": 1,
    "معاجين": 1,
    "أطباء": 1,
    "دينتون": 1,
    "الطعجات": 1,
    "طقم": 1,
    "أطقم": 1,
    "التعرية": 1,
    "عر": 1,
    "يعري": 1,
    "احصاءه": 1,
    "عده": 1,
    "تهديدي": 1,
    "شجبي": 1,
    "دينفير": 1,
    "أنكر": 1,
    "بنكران": 1,
    "الروائح": 1,
    "تلطيف": 1,
    "الرائحة": 1,
    "نمط": 1,
    "ديوكسيريبونكلييك": 1,
    "ديوكسيريبوز": 1,
    "غادر": 1,
    "مغادر": 1,
    "المغادرة": 1,
    "ديباتشر": 1,
    "إعتمد": 1,
    "الموثوقية": 1,
    "التبعية": 1,
    "الأتباع": 1,
    "التبعيات": 1,
    "إسلب": 1,
    "شخصية": 1,
    "مسلوب": 1,
    "يسلب": 1,
    "سلب": 1,
    "مصور": 1,
    "يصور": 1,
    "إسمط": 1,
    "مسموط": 1,
    "يسمط": 1,
    "السماط": 1,
    "إستنفذ": 1,
    "مستنفذ": 1,
    "يستنفذ": 1,
    "الإستنفاذ": 1,
    "النضوب": 1,
    "إستنزافي": 1,
    "الأسى": 1,
    "بشعة": 1,
    "إستهجن": 1,
    "مستهجن": 1,
    "الحزين": 1,
    "يستهجن": 1,
    "الإستهجان": 1,
    "إنتشر": 1,
    "للانتشار": 1,
    "الإنتشار": 1,
    "ينتشر": 1,
    "الإستقطاب": 1,
    "إستقطاب": 1,
    "أخل": 1,
    "مخلى": 1,
    "يخلي": 1,
    "إخلاء": 1,
    "الإخلاء": 1,
    "المبعدون": 1,
    "إخلع": 1,
    "مخلوع": 1,
    "يخلع": 1,
    "الخلع": 1,
    "المستودع": 1,
    "المقدم": 1,
    "التوديع": 1,
    "المودع": 1,
    "المودعون": 1,
    "الإيداعات": 1,
    "المستودعات": 1,
    "الفاسد": 1,
    "إستنكر": 1,
    "مستنكر": 1,
    "يستنكر": 1,
    "الإستنكار": 1,
    "باستهجان": 1,
    "المستنكرون": 1,
    "إستهجاني": 1,
    "للتخفيض": 1,
    "بتناقص": 1,
    "التلف": 1,
    "التلفيات": 1,
    "اسلب": 1,
    "النهب": 1,
    "النهاب": 1,
    "السللاب": 1,
    "ناهب)": 1,
    "إضغط": 1,
    "يضغط": 1,
    "بكآبة": 1,
    "المثبط": 1,
    "المثبطون": 1,
    "إحرم": 1,
    "محروم": 1,
    "الأعماق": 1,
    "أوفد": 1,
    "يوفد": 1,
    "إنتدب": 1,
    "منتدب": 1,
    "ينتدب": 1,
    "الإنتداب": 1,
    "إخرج": 1,
    "السكة": 1,
    "خرج": 1,
    "خروج": 1,
    "يخرج": 1,
    "درات": 1,
    "دربيس": 1,
    "الدربي": 1,
    "ديربيشاير": 1,
    "مقاطعة": 1,
    "إنجلترا": 1,
    "لمحتويات": 1,
    "(برمجيات)": 1,
    "الواصل": 1,
    "الواصلون": 1,
    "رقابة": 1,
    "ديريك": 1,
    "المهجور": 1,
    "إسخر": 1,
    "ساخر": 1,
    "الساخر": 1,
    "الساخرون": 1,
    "يسخر": 1,
    "بسخرية": 1,
    "للإشتقاق": 1,
    "انشأ": 1,
    "يشتق": 1,
    "الاشتقاق": 1,
    "الإشتقاقات": 1,
    "الاشتقاقية": 1,
    "إشتق": 1,
    "المشتق": 1,
    "بطب": 1,
    "الجلدية": 1,
    "الأدمة": 1,
    "إنتقاصي": 1,
    "المرفعة": 1,
    "الدرويش": 1,
    "الدراويش": 1,
    "ديز": 1,
    "يحلي": 1,
    "التحلية": 1,
    "التشبع": 1,
    "دزك": 1,
    "ديكارت": 1,
    "فيلسوف": 1,
    "السليل": 1,
    "الأحفاد": 1,
    "السليلون": 1,
    "الأسلة": 1,
    "موروث": 1,
    "ينحدر": 1,
    "الواصف": 1,
    "الواصفون": 1,
    "يصف": 1,
    "ملموح": 1,
    "الأوصاف": 1,
    "الموصف": 1,
    "إلمح": 1,
    "اللمح": 1,
    "دنس": 1,
    "المنتهك": 1,
    "يدنس": 1,
    "إدمج": 1,
    "مدموج": 1,
    "يدمج": 1,
    "العنصري": 1,
    "إرفض": 1,
    "مرفوض": 1,
    "حساسية": 1,
    "تقليل": 1,
    "الصحراء": 1,
    "الهاربون": 1,
    "الصحاري": 1,
    "إستحق": 1,
    "بجدارة": 1,
    "الاستحقاق": 1,
    "المستحق": 1,
    "الإستحقاق": 1,
    "يفقد": 1,
    "المجفف": 1,
    "الأمنيات": 1,
    "الأمنية": 1,
    "التصميم": 1,
    "تصميمه": 1,
    "مرشح": 1,
    "التعيينات": 1,
    "ترشيحي": 1,
    "عمدا": 1,
    "المصمم": 1,
    "المصممون": 1,
    "التصاميم": 1,
    "الوجهة": 1,
    "دزيرير": 1,
    "الرغبات": 1,
    "تواق": 1,
    "كف": 1,
    "مكفوف": 1,
    "الكف": 1,
    "يكف": 1,
    "ديزموند": 1,
    "مقفر": 1,
    "يقفر": 1,
    "الإقفار": 1,
    "الخراب": 1,
    "مكثفة": 1,
    "السطح": 1,
    "الغازية": 1,
    "السائلة": 1,
    "اليأس": 1,
    "ميئوس": 1,
    "اليائس": 1,
    "اليئس": 1,
    "الإرسالية": 1,
    "مبعوث": 1,
    "الإرساليات": 1,
    "البعث": 1,
    "الشرير": 1,
    "الشريرون": 1,
    "مستميت": 1,
    "للغاية": 1,
    "بحقارة": 1,
    "إحتقر": 1,
    "المحتقر": 1,
    "يحتقر": 1,
    "الرغم": 1,
    "الأحقاد": 1,
    "السلب": 1,
    "بيأس": 1,
    "ييئس": 1,
    "إستبدادي": 1,
    "بديكتاتورية": 1,
    "الطغاة": 1,
    "زعزع": 1,
    "مزعزع": 1,
    "يزعزع": 1,
    "الزعزعة": 1,
    "الإتجاه": 1,
    "الإتجاهات": 1,
    "الأقدار": 1,
    "معدم": 1,
    "مفتقر": 1,
    "عائل": 1,
    "فقير": 1,
    "الفاقة": 1,
    "محطم": 1,
    "المدمرة": 1,
    "المدمرات": 1,
    "دمر": 1,
    "للتدمير": 1,
    "الدمار": 1,
    "تدميري": 1,
    "هدام": 1,
    "التفكك": 1,
    "إفصل": 1,
    "للفصل": 1,
    "الانحياز": 1,
    "الإنفصال": 1,
    "المفارز": 1,
    "التفاصيل": 1,
    "إحجز": 1,
    "محجوز": 1,
    "المحجوز": 1,
    "المحجوزون": 1,
    "يحجز": 1,
    "إكتشف": 1,
    "الاكتشاف": 1,
    "للكشف": 1,
    "مكتشف": 1,
    "الإكتشاف": 1,
    "الكشوفات": 1,
    "المخبرون": 1,
    "الكاشف": 1,
    "الكاشفات": 1,
    "يكتشف": 1,
    "الماسكة": 1,
    "السلام": 1,
    "إردع": 1,
    "تدهور": 1,
    "متدهور": 1,
    "يتدهور": 1,
    "التدهور": 1,
    "تدهوري": 1,
    "التصميمية": 1,
    "الردع": 1,
    "الاصرار": 1,
    "المقررون": 1,
    "بحسم": 1,
    "تحديدي": 1,
    "بتصميم": 1,
    "مردوع": 1,
    "الرادع": 1,
    "رادع": 1,
    "الروادع": 1,
    "يردع": 1,
    "إمقت": 1,
    "بمقت": 1,
    "للانفجار": 1,
    "فجر": 1,
    "مفجر": 1,
    "يفجر": 1,
    "التفجير": 1,
    "المتفجر": 1,
    "المتفجرات": 1,
    "انفجاري": 1,
    "المفجرات": 1,
    "الإنعطاف": 1,
    "انعطف": 1,
    "الانعطاف": 1,
    "الإنعطافات": 1,
    "أنقص": 1,
    "الذام": 1,
    "الذامون": 1,
    "ينقص": 1,
    "ثقف": 1,
    "يثقف": 1,
    "حتاتي": 1,
    "البقايا": 1,
    "ديترويت": 1,
    "الإلة": 1,
    "الديوتريوم": 1,
    "الديوترون": 1,
    "نواة": 1,
    "ألماني": 1,
    "قيمة": 1,
    "تخفيض": 1,
    "القيمة": 1,
    "خرابي": 1,
    "المدمرون": 1,
    "طور": 1,
    "متطور": 1,
    "يطور": 1,
    "التطوير": 1,
    "التطويرات": 1,
    "المطور": 1,
    "المطورون": 1,
    "تطويري": 1,
    "تنمويا": 1,
    "التطورات": 1,
    "مستقلة": 1,
    "معينة": 1,
    "الشيطانية": 1,
    "الشيطاني": 1,
    "الشرور": 1,
    "للقسمة": 1,
    "على..": 1,
    "مبتكر": 1,
    "المورث": 1,
    "المخترع": 1,
    "الإبتكار": 1,
    "تحريك": 1,
    "اﻷحبال": 1,
    "خالي": 1,
    "منقول": 1,
    "ديفون": 1,
    "ديفونشاير": 1,
    "غرب": 1,
    "بولاء": 1,
    "المحب": 1,
    "المحبون": 1,
    "عبادي": 1,
    "إلتهم": 1,
    "ملتهم": 1,
    "المفترس": 1,
    "المفترسون": 1,
    "الإلتهام": 1,
    "يلتهم": 1,
    "بإيمان": 1,
    "(ترمس": 1,
    "الهواء)": 1,
    "قطرة": 1,
    "قطر": 1,
    "مرطب": 1,
    "بالندى": 1,
    "ديوي": 1,
    "ندي": 1,
    "النداوة": 1,
    "يرطب": 1,
    "ديويت": 1,
    "ديكسيدرين": 1,
    "أيمن": 1,
    "ديكستروسنيس": 1,
    "سكر": 1,
    "ظبي": 1,
    "دارما": 1,
    "المصابون": 1,
    "شيطانيا": 1,
    "ديابوليكالنيس": 1,
    "ديابوليكالنيسيس": 1,
    "بالزمن": 1,
    "اللاتينية": 1,
    "للتشخيص": 1,
    "مشخص": 1,
    "يشخص": 1,
    "التشخيص": 1,
    "تشخيصي": 1,
    "تشخيصيا": 1,
    "تشخيص": 1,
    "القطر": 1,
    "جدولته": 1,
    "قطريا": 1,
    "الأقطار": 1,
    "بيانيا": 1,
    "البياني": 1,
    "رسمه": 1,
    "الهاتفي": 1,
    "اللهجة": 1,
    "لهجوي": 1,
    "جدلي": 1,
    "الجدلي": 1,
    "الجدليون": 1,
    "اللهجات": 1,
    "متلفن": 1,
    "المسجل": 1,
    "المسجلون": 1,
    "التلفنة": 1,
    "الحوار": 1,
    "الحوارات": 1,
    "التحاور": 1,
    "الأوجه": 1,
    "مفرز": 1,
    "ضعيف": 1,
    "النفاذية": 1,
    "الماس": 1,
    "دايانا": 1,
    "حفاظة": 1,
    "ديابيريد": 1,
    "ديابيرينج": 1,
    "حفاظات": 1,
    "الشفافية": 1,
    "الحجاب": 1,
    "الحجابات": 1,
    "الحاجزة": 1,
    "المفكرات": 1,
    "اليوميات": 1,
    "الإسهال": 1,
    "بالإسهال": 1,
    "الشتات": 1,
    "اليهودي": 1,
    "البابلي": 1,
    "الإنبساط": 1,
    "الاستحرار": 1,
    "الاستهداف": 1,
    "الدياتوم": 1,
    "يزخر": 1,
    "بالدياتوم": 1,
    "دياتومس": 1,
    "موسيقي": 1,
    "الهجاء": 1,
    "الديازونيوم": 1,
    "إغرز": 1,
    "النرد": 1,
    "المقامر": 1,
    "النردات": 1,
    "ديتش": 1,
    "الديكلوريد": 1,
    "ديكوندرا": 1,
    "الإنقسامات": 1,
    "إنقسم": 1,
    "الإنقسام": 1,
    "التفرع": 1,
    "بتفرع": 1,
    "شبهة": 1,
    "بوليس": 1,
    "لفضة": 1,
    "عامية": 1,
    "لقضيب": 1,
    "مذكر": 1,
    "الدرسة": 1,
    "الأميركية": 1,
    "يساوم": 1,
    "ديكيرسون": 1,
    "دكي": 1,
    "ديكنسن": 1,
    "ديكس": 1,
    "ديكسون": 1,
    "العصفور": 1,
    "الفلقتين": 1,
    "المأثورة": 1,
    "الدكتافون": 1,
    "الدكتافونات": 1,
    "مفروض": 1,
    "الإملاء": 1,
    "الدكتاتور": 1,
    "دكتاتوري": 1,
    "الدكتاتورية": 1,
    "الدكتاتوريون": 1,
    "الدكتاتوريات": 1,
    "الإلقاء": 1,
    "القواميس": 1,
    "القاموس": 1,
    "المأثور": 1,
    "تعليمي": 1,
    "بالتعليمي": 1,
    "تعليميا": 1,
    "الحماقات": 1,
    "مت": 1,
    "ديبولد": 1,
    "مات": 1,
    "ديجو": 1,
    "العنيد": 1,
    "الأشداء": 1,
    "الديلدرين": 1,
    "عازل": 1,
    "عوازل": 1,
    "الكهرباء": 1,
    "ديم": 1,
    "النقطتين": 1,
    "ديرز": 1,
    "الديزل": 1,
    "ديسين": 1,
    "الحمية": 1,
    "ريجيمي": 1,
    "اتبع": 1,
    "الحميات": 1,
    "أخصائيو": 1,
    "غذائيا": 1,
    "التغذية": 1,
    "ديثيلستيلبيسترول": 1,
    "استروجبن": 1,
    "أخصائي": 1,
    "اتباع": 1,
    "ديتريش": 1,
    "ممثلة": 1,
    "ديتز": 1,
    "معرفة": 1,
    "الإختلاف": 1,
    "إختلف": 1,
    "مختلف": 1,
    "باين": 1,
    "الإختلافات": 1,
    "يفرق": 1,
    "الاختلاف": 1,
    "للاختلاف": 1,
    "تفاضلي": 1,
    "التفاضلات": 1,
    "المفرق": 1,
    "المفرقون": 1,
    "الاختلافات": 1,
    "الصعوبات": 1,
    "إنحرافي": 1,
    "ديفراكتوميتر": 1,
    "(جهاز": 1,
    "مخبري)": 1,
    "(أجهزة": 1,
    "مخبرية)": 1,
    "مستفيض": 1,
    "بانتشار": 1,
    "الناشر": 1,
    "الناشرون": 1,
    "الإنتشارية": 1,
    "إنتشاري": 1,
    "انتشاري": 1,
    "منتشر": 1,
    "موجات": 1,
    "بالتساوي": 1,
    "مهضوم": 1,
    "الهضم": 1,
    "الهضمي": 1,
    "هضمي": 1,
    "الملخصات": 1,
    "ديجيد": 1,
    "الحفريات": 1,
    "الديجيتال": 1,
    "الإرجواني": 1,
    "لاضطرابات": 1,
    "علاجات": 1,
    "يرقم": 1,
    "إلكترونيا": 1,
    "أصابع": 1,
    "الترقيم": 1,
    "محولات": 1,
    "تحويل": 1,
    "وقور": 1,
    "يبجل": 1,
    "بجل": 1,
    "التبجيل": 1,
    "الوجهاء": 1,
    "الوجيه": 1,
    "الكرامات": 1,
    "الكرامة": 1,
    "حرفين": 1,
    "متتاليين": 1,
    "محاد": 1,
    "يحود": 1,
    "الحيد": 1,
    "الإستطراد": 1,
    "إستطرادي": 1,
    "الاستطراد": 1,
    "ديكسترا": 1,
    "ديجون": 1,
    "الخندق": 1,
    "اللفض": 1,
    "السحاقية": 1,
    "الخنادق": 1,
    "خرب": 1,
    "مخرب": 1,
    "يخرب": 1,
    "التوسع": 1,
    "توسع": 1,
    "الإتساع": 1,
    "يتوسع": 1,
    "التوسعات": 1,
    "الموسع": 1,
    "بتلكئ": 1,
    "التوسعة": 1,
    "متلكئ": 1,
    "المعضلة": 1,
    "المعضلات": 1,
    "هاوي": 1,
    "الفني": 1,
    "بجد": 1,
    "ديلينجر": 1,
    "عصابة": 1,
    "ديلون": 1,
    "طوفاني": 1,
    "خافت": 1,
    "ديماجيو": 1,
    "الدايم": 1,
    "بعدي": 1,
    "أبعاد": 1,
    "الأبعاد": 1,
    "مكونة": 1,
    "متشابهة": 1,
    "الدايمات": 1,
    "ديميثيلجليوكسايم": 1,
    "(مركب": 1,
    "كيميائي)": 1,
    "للتناقص": 1,
    "بخفوت": 1,
    "تدريجي": 1,
    "ضئيل": 1,
    "تصغيرا": 1,
    "الصغر": 1,
    "الضئيلون": 1,
    "مخفوت": 1,
    "المخفت": 1,
    "المخفتات": 1,
    "خفوت": 1,
    "الخفت": 1,
    "الخفوت": 1,
    "الجزئي": 1,
    "مدمل": 1,
    "التنقير": 1,
    "دمامل": 1,
    "الغباء": 1,
    "دينا": 1,
    "ابنة": 1,
    "\"يعقوب\"": 1,
    "\"ليه\"": 1,
    "تعش": 1,
    "متعشى": 1,
    "المتعشي": 1,
    "المتعشون": 1,
    "يتعشى": 1,
    "إقرع": 1,
    "أقذر": 1,
    "بقذارة": 1,
    "القذارة": 1,
    "المشجر": 1,
    "المشجرة": 1,
    "الدنغو": 1,
    "التعشي": 1,
    "الأصغر": 1,
    "مضجوج": 1,
    "العشاء": 1,
    "بدلة": 1,
    "وجبات": 1,
    "الديناصورات": 1,
    "أبرشي": 1,
    "الأبرشية": 1,
    "ديود": 1,
    "الثنائيات": 1,
    "دايوجينس": 1,
    "يوناني": 1,
    "ديون": 1,
    "ديونيسي": 1,
    "بالراهب": 1,
    "\"ديونيسيس\"": 1,
    "(راهب": 1,
    "السادس)": 1,
    "ديونيسيس": 1,
    "راهب": 1,
    "ديوفانتاين": 1,
    "استاذ": 1,
    "الديوبتر": 1,
    "والمستوى": 1,
    "الديوريت": 1,
    "الإنخفاض": 1,
    "لحيم": 1,
    "الديفتريا": 1,
    "ديفتيري": 1,
    "خناقي": 1,
    "الإدغام": 1,
    "الإدغامات": 1,
    "مضاعف": 1,
    "بتضاعف": 1,
    "الدبلوم": 1,
    "الدبلوماسية": 1,
    "الدبلومات": 1,
    "الدبلوماسي": 1,
    "موثقون": 1,
    "دبلوماسي": 1,
    "دبلوماسيا": 1,
    "والمخطوطات": 1,
    "الدبلوماسيون": 1,
    "بوحدة": 1,
    "\"ديبودي\"": 1,
    "ديبودي": 1,
    "(وحدة": 1,
    "قياس)": 1,
    "الاقطاب": 1,
    "منخفض": 1,
    "الغطاس": 1,
    "الغطاسون": 1,
    "الغمس": 1,
    "الانغماسات": 1,
    "الإنخفاضات": 1,
    "اختصار": 1,
    "\"اتجاه\"": 1,
    "ديراك": 1,
    "فيزيائي": 1,
    "مباشر": 1,
    "إتجاهي": 1,
    "التوجيهات": 1,
    "المديرية": 1,
    "مديري": 1,
    "المدراء": 1,
    "دليلملف": 1,
    "المخرجات": 1,
    "المخرجة": 1,
    "اللحن": 1,
    "الحزينة": 1,
    "ديريتشليت": 1,
    "ديرك": 1,
    "الوسخ": 1,
    "موسخ": 1,
    "أوسخ": 1,
    "يوسخ": 1,
    "الوساخة": 1,
    "الأوساخ": 1,
    "التوسيخ": 1,
    "يعجز": 1,
    "الأهلية": 1,
    "تعطيل": 1,
    "معوق": 1,
    "عاجز": 1,
    "التعجيز": 1,
    "ديساكهارايد": 1,
    "(سكريات)": 1,
    "الإضرار": 1,
    "بسخط": 1,
    "إنفصل": 1,
    "منفصل": 1,
    "ينفصل": 1,
    "الإنتساب": 1,
    "غموض": 1,
    "أزيل": 1,
    "مختفى": 1,
    "خب": 1,
    "أمل": 1,
    "خائب": 1,
    "الأمل": 1,
    "مخيب": 1,
    "للآمال": 1,
    "خيبة": 1,
    "الإحباطات": 1,
    "يخيب": 1,
    "الإستنكارات": 1,
    "باستنكار": 1,
    "المجرد": 1,
    "المجردون": 1,
    "فادح": 1,
    "المصيبة": 1,
    "أقص": 1,
    "يقصي": 1,
    "الجاحد": 1,
    "الكفرة": 1,
    "الجحود": 1,
    "أرح": 1,
    "مراح": 1,
    "يريح": 1,
    "أنفق": 1,
    "منفق": 1,
    "المنفق": 1,
    "ينفق": 1,
    "إنبذ": 1,
    "منبوذ": 1,
    "المتخلص": 1,
    "معروف": 1,
    "للإدراك": 1,
    "بصير": 1,
    "الفطن": 1,
    "الرؤى": 1,
    "الإطلاق": 1,
    "الإطلاقات": 1,
    "التلمذة": 1,
    "الصارم": 1,
    "الصارمون": 1,
    "تأديبي": 1,
    "الإنضباط": 1,
    "منضبط": 1,
    "المجالات": 1,
    "التنصل": 1,
    "إكشف": 1,
    "الديسكو": 1,
    "رامي": 1,
    "ديسكويد": 1,
    "تغيير": 1,
    "أزعج": 1,
    "يدحر": 1,
    "الارتياح": 1,
    "المضايقات": 1,
    "مضايق": 1,
    "يزعج": 1,
    "مربك": 1,
    "محرجة": 1,
    "تعيس": 1,
    "التعارض": 1,
    "مخالف": 1,
    "الدسكو": 1,
    "للخصم": 1,
    "عزيمة": 1,
    "المحبط": 1,
    "يثبط": 1,
    "تثبيط": 1,
    "باحباط": 1,
    "للإكتشاف": 1,
    "المكتشف": 1,
    "المكتشفون": 1,
    "الإكتشافات": 1,
    "معيب": 1,
    "التكذيب": 1,
    "التحفظ": 1,
    "بتحفظ": 1,
    "إختياري": 1,
    "للتفضيل": 1,
    "إستطراديا": 1,
    "التنقل": 1,
    "ناقش": 1,
    "المناقشات": 1,
    "الإستنكاف": 1,
    "مزدرى": 1,
    "الإزدراء": 1,
    "الترجل": 1,
    "إبقر": 1,
    "مبقور": 1,
    "يبقر": 1,
    "الظن": 1,
    "خيب": 1,
    "الإراحة": 1,
    "تحرير": 1,
    "حرمان": 1,
    "منعزل": 1,
    "فاك": 1,
    "الاشتباك": 1,
    "إختلال": 1,
    "إعزل": 1,
    "الإستياء": 1,
    "المستاء": 1,
    "المستائون": 1,
    "تقيأ": 1,
    "متقيأ": 1,
    "يتقيأ": 1,
    "الخزي": 1,
    "مخزى": 1,
    "للعار": 1,
    "التخزية": 1,
    "أسخط": 1,
    "يسخط": 1,
    "الإسخاط": 1,
    "التنكر": 1,
    "متنكر": 1,
    "تنكري": 1,
    "المتنكر": 1,
    "التنكرات": 1,
    "باشمئزاز": 1,
    "القرف": 1,
    "الصحن": 1,
    "التنافر": 1,
    "الليفة": 1,
    "إخذل": 1,
    "مخذول": 1,
    "الخذل": 1,
    "يخذل": 1,
    "الصحون": 1,
    "شعث": 1,
    "مشعث": 1,
    "يشعث": 1,
    "غشاش": 1,
    "أمانة": 1,
    "العار": 1,
    "شريف": 1,
    "المخزي": 1,
    "مشرفون": 1,
    "الإخزاء": 1,
    "يخزي": 1,
    "بشرف": 1,
    "الفوطة": 1,
    "الفوط": 1,
    "غسالة": 1,
    "غسالات": 1,
    "الخذلان": 1,
    "الخيبة": 1,
    "الصحوة": 1,
    "العقبة": 1,
    "العقبات": 1,
    "النفور": 1,
    "نفر": 1,
    "منفر": 1,
    "ينفر": 1,
    "التنفير": 1,
    "تحلل": 1,
    "يتحلل": 1,
    "تكاملي": 1,
    "إنبش": 1,
    "النبش": 1,
    "مدفون": 1,
    "ينبش": 1,
    "المفارق": 1,
    "فاصل": 1,
    "الإزاحة": 1,
    "أزح": 1,
    "مزاح": 1,
    "يزيح": 1,
    "خائن": 1,
    "الإفزاع": 1,
    "إطرد": 1,
    "مطرود": 1,
    "رافض": 1,
    "ترجل": 1,
    "مترجل": 1,
    "يترجل": 1,
    "ديزني": 1,
    "ديسنيلاند": 1,
    "العصيان": 1,
    "عاصي": 1,
    "إعص": 1,
    "معصي": 1,
    "العاصي": 1,
    "يعصي": 1,
    "ملخبط": 1,
    "بفوضوية": 1,
    "اللخبطة": 1,
    "تملك": 1,
    "إستسخف": 1,
    "مستسخف": 1,
    "المستهزئ": 1,
    "يستسخف": 1,
    "الإستسخاف": 1,
    "إستخفافا": 1,
    "التفاوتات": 1,
    "محايد": 1,
    "المرسلون": 1,
    "بدد": 1,
    "التبديد": 1,
    "يبدد": 1,
    "الصيدليات": 1,
    "الصيدلية": 1,
    "التفريق": 1,
    "مفرق": 1,
    "بتشتت": 1,
    "للنشر": 1,
    "التفرق": 1,
    "تفريقي": 1,
    "يئس": 1,
    "متشائم": 1,
    "بتشائم": 1,
    "مرحل": 1,
    "تنسيب": 1,
    "للعب": 1,
    "معروض": 1,
    "إستاء": 1,
    "مستاء": 1,
    "بعدم": 1,
    "رضى": 1,
    "يستاء": 1,
    "باستياء": 1,
    "تسل": 1,
    "متسلى": 1,
    "التسلي": 1,
    "يتسلى": 1,
    "التخلص": 1,
    "ترتيبي": 1,
    "يلوم": 1,
    "النقض": 1,
    "التفاوت": 1,
    "متكافيء": 1,
    "عنصرين": 1,
    "للبرهان": 1,
    "مفند": 1,
    "للنزاع": 1,
    "اثارة": 1,
    "المنازع": 1,
    "المنازعون": 1,
    "مسكت": 1,
    "الإسكات": 1,
    "بانزعاج": 1,
    "متجاهل": 1,
    "التجاهل": 1,
    "الإهمالات": 1,
    "مخز": 1,
    "إجتث": 1,
    "مجتث": 1,
    "الإجتثاث": 1,
    "يجتث": 1,
    "ديسروبتر": 1,
    "العراقيل": 1,
    "مشرح": 1,
    "القطاع": 1,
    "ديسسيمبلير": 1,
    "معارض": 1,
    "المنشق": 1,
    "المنشقون": 1,
    "المعارضات": 1,
    "الإطروحة": 1,
    "الإطروحات": 1,
    "المتفرق": 1,
    "الإنقشاع": 1,
    "للتفرق": 1,
    "إجتماعي": 1,
    "فصامي": 1,
    "ذائب": 1,
    "الذائب": 1,
    "إنصح": 1,
    "الإستنزاع": 1,
    "مقطعي": 1,
    "بكرات": 1,
    "المسافة": 1,
    "رداءه": 1,
    "الإنتفاخ": 1,
    "الإنتفاخات": 1,
    "بندان": 1,
    "لنفس": 1,
    "النوع": 1,
    "المقطرة": 1,
    "التقطير": 1,
    "مقطر": 1,
    "المقطر": 1,
    "يقطر": 1,
    "الفروق": 1,
    "ديستوما": 1,
    "(دودة)": 1,
    "للتشويه": 1,
    "التحريف": 1,
    "يحرف": 1,
    "إصرف": 1,
    "إنتباهه": 1,
    "بتحير": 1,
    "للتشتت": 1,
    "إلهائي": 1,
    "للحجز": 1,
    "المتدرب": 1,
    "المتدربون": 1,
    "الحزن": 1,
    "للتوزيع": 1,
    "الموزع": 1,
    "توزيعي": 1,
    "التسويقية": 1,
    "الموزعون": 1,
    "البيع": 1,
    "بالتجزأة": 1,
    "ديستروي": 1,
    "الإرتياب": 1,
    "مرتاب": 1,
    "بارتياب": 1,
    "بريبة": 1,
    "المزعج": 1,
    "ذرتين": 1,
    "الإنشقاق": 1,
    "مستعمل": 1,
    "المقطعين": 1,
    "متخندق": 1,
    "التخندق": 1,
    "الإرتباك": 1,
    "نفسها": 1,
    "الإنشودة": 1,
    "ديزيل": 1,
    "مدرر": 1,
    "نهاري": 1,
    "المغنية": 1,
    "المغنيات": 1,
    "الغطس": 1,
    "مغيص": 1,
    "الغواص": 1,
    "تباعد": 1,
    "متباعد": 1,
    "يتباعد": 1,
    "التباعد": 1,
    "الغواصون": 1,
    "متنوع": 1,
    "بتنوع": 1,
    "تضليلي": 1,
    "المقسم": 1,
    "المقسمات": 1,
    "التكهن": 1,
    "القس": 1,
    "متكهن": 1,
    "إلهيا": 1,
    "التميز": 1,
    "قدسي": 1,
    "الغوص": 1,
    "الإلهيات": 1,
    "اللاهوت": 1,
    "تقسيمي": 1,
    "خلافي": 1,
    "الشقاق": 1,
    "المقسوم": 1,
    "المقسومات": 1,
    "الطلاق": 1,
    "المطلق": 1,
    "المطلقون": 1,
    "الطلاقات": 1,
    "الإفشاء": 1,
    "أبح": 1,
    "مباح": 1,
    "الإباحة": 1,
    "يبيح": 1,
    "عوائد": 1,
    "الأسهم": 1,
    "ديكسيكراتس": 1,
    "(حزب": 1,
    "ديكسيلاند": 1,
    "ال11": 1,
    "انسحبت": 1,
    "ديكسن": 1,
    "بالدوار": 1,
    "دوخة": 1,
    "الدوار": 1,
    "بدوخة": 1,
    "الدوخة": 1,
    "جيبوتي": 1,
    "تنزيل": 1,
    "ديامأيه": 1,
    "ديميتري": 1,
    "الدي.إن.إيه": 1,
    "(الحامض": 1,
    "النووي)": 1,
    "دنيبير": 1,
    "دياناس": 1,
    "بيان": 1,
    "دوب": 1,
    "دوبين": 1,
    "دوبس": 1,
    "دوبيرمان": 1,
    "دوبسون": 1,
    "الدكتور": 1,
    "السلاسة": 1,
    "السفن": 1,
    "للإرساء": 1,
    "مرسى": 1,
    "الميناء": 1,
    "القوائم": 1,
    "الرصيف": 1,
    "المسفن": 1,
    "دوس": 1,
    "دكتوراه": 1,
    "الدكتوراه": 1,
    "العقائدي": 1,
    "مذهبي": 1,
    "الوثيقة": 1,
    "للتوثيق": 1,
    "وثائقي": 1,
    "الوثائقيات": 1,
    "الوثائقي": 1,
    "الموثقين": 1,
    "وزارة": 1,
    "دود": 1,
    "إرتعش": 1,
    "مرتعش": 1,
    "الإرتعاش": 1,
    "يرتعش": 1,
    "الثنعشري": 1,
    "الأضلاع": 1,
    "ثنعشري": 1,
    "المراوغ": 1,
    "المراوغون": 1,
    "دودنجتون": 1,
    "الدودو": 1,
    "دودسون": 1,
    "الظبية": 1,
    "الفاعل": 1,
    "الفاعلون": 1,
    "سامة": 1,
    "توت": 1,
    "القضاة": 1,
    "الإصرار": 1,
    "باصرار": 1,
    "الركيك": 1,
    "وضيع": 1,
    "الوضاعة": 1,
    "المنعطف": 1,
    "العقائد": 1,
    "دوغماتي": 1,
    "المذهبية": 1,
    "الدوغماتية": 1,
    "الدوغماتي": 1,
    "الهرولة": 1,
    "دورتي": 1,
    "دولان": 1,
    "بحلاوة": 1,
    "الكساد": 1,
    "بكئابة": 1,
    "الكئآبة": 1,
    "الدمية": 1,
    "الدمى": 1,
    "الكمشة": 1,
    "دولي": 1,
    "الدولوميت": 1,
    "دولور": 1,
    "دلوريس": 1,
    "الأحزان": 1,
    "الدولفين": 1,
    "الدلافين": 1,
    "القبة": 1,
    "دومينيكو": 1,
    "القبب": 1,
    "القيامة": 1,
    "داخليا": 1,
    "دجن": 1,
    "مدجن": 1,
    "يدجن": 1,
    "التدجين": 1,
    "العائلية": 1,
    "مسكون": 1,
    "الهيمنة": 1,
    "مهيمن": 1,
    "سيطر": 1,
    "يسيطر": 1,
    "إستبد": 1,
    "يستبد": 1,
    "بقبة": 1,
    "دومينجو": 1,
    "دومينيك": 1,
    "الدومنيكا": 1,
    "الدومنيكي": 1,
    "الدومنيكيون": 1,
    "المعلم": 1,
    "الدومنة": 1,
    "دوناه": 1,
    "دوناهو": 1,
    "دونالد": 1,
    "دونالدسون": 1,
    "تبرع": 1,
    "متبرع": 1,
    "يتبرع": 1,
    "التبرع": 1,
    "تبرعي": 1,
    "معمول": 1,
    "الدونغ": 1,
    "الحمار": 1,
    "الحمير": 1,
    "دونا": 1,
    "مرتدى": 1,
    "دونيلي": 1,
    "دونير": 1,
    "الإرتداء": 1,
    "دونيبروك": 1,
    "دونوفان": 1,
    "الرسومات": 1,
    "دوهيكي": 1,
    "دووليتل": 1,
    "منكوب": 1,
    "الباب": 1,
    "البواب": 1,
    "البوابون": 1,
    "مقبض": 1,
    "مقابض": 1,
    "الابواب": 1,
    "ممسحة": 1,
    "الأرجل": 1,
    "الأبواب": 1,
    "العتبة": 1,
    "العتبات": 1,
    "حاجز": 1,
    "مخدر": 1,
    "تخدير": 1,
    "دوبلر": 1,
    "دورا": 1,
    "دورادو": 1,
    "دوركاس": 1,
    "دورجيستسر": 1,
    "دورين": 1,
    "دوريسي": 1,
    "دوريس": 1,
    "الخمول": 1,
    "الشباك": 1,
    "الزغبات": 1,
    "الزغبة": 1,
    "دوروثي": 1,
    "ظهريا": 1,
    "الخراف": 1,
    "دورتموند": 1,
    "ميكروسوفت)": 1,
    "الجرعات": 1,
    "مداوى": 1,
    "الجرع": 1,
    "الاشعاع": 1,
    "المداواة": 1,
    "دوستويفسكاي": 1,
    "مصفوفة": 1,
    "الخرفان": 1,
    "إخرف": 1,
    "خارف": 1,
    "بطيء": 1,
    "يخرف": 1,
    "مخرف": 1,
    "النقاط": 1,
    "التنقيط": 1,
    "الفعل": 1,
    "أضلاع": 1,
    "دوبليداي": 1,
    "المباراتان": 1,
    "المتعاقبتان": 1,
    "الإزدواجية": 1,
    "بريدية": 1,
    "مضاعفة": 1,
    "دوبليرس": 1,
    "الأضعاف": 1,
    "الدبلون": 1,
    "عملة": 1,
    "ذهبية": 1,
    "أسبانية": 1,
    "مضاعفا": 1,
    "مريب": 1,
    "الشكاك": 1,
    "الشكاكون": 1,
    "التأكد": 1,
    "الشكوك": 1,
    "الدوش": 1,
    "مغسول": 1,
    "الغسل": 1,
    "دوسين": 1,
    "دوغ": 1,
    "العجينة": 1,
    "دوجهيرتاي": 1,
    "لين": 1,
    "دوغلاس": 1,
    "دوجلاس": 1,
    "غطس": 1,
    "مغطس": 1,
    "دوسير": 1,
    "يغطس": 1,
    "التغطيس": 1,
    "الحمامة": 1,
    "دوفيكي": 1,
    "دوفر": 1,
    "معشق": 1,
    "التعشيق": 1,
    "الأربطة": 1,
    "داو": 1,
    "الأرملة": 1,
    "الأرامل": 1,
    "إبتذالا": 1,
    "نسوة": 1,
    "قذرة": 1,
    "بابتذال": 1,
    "الرثاثة": 1,
    "مبتذل": 1,
    "المسمار": 1,
    "موتد": 1,
    "التوتيد": 1,
    "المسامير": 1,
    "ممهور": 1,
    "مهر": 1,
    "الدتشر": 1,
    "دوولينج": 1,
    "المنخفض": 1,
    "ساقط": 1,
    "داوني": 1,
    "المفاجىء": 1,
    "الإنحدارات": 1,
    "محزونا": 1,
    "مريش": 1,
    "النعومة": 1,
    "الاسقاط": 1,
    "هطول": 1,
    "الأمطار": 1,
    "الريشات": 1,
    "السلبي": 1,
    "ماسورة": 1,
    "الرمية": 1,
    "السفلية": 1,
    "الرميات": 1,
    "الضائع": 1,
    "سكنة": 1,
    "الانخفاض": 1,
    "مظلوم": 1,
    "نزولا": 1,
    "اﻷسفل": 1,
    "فنازلا": 1,
    "باتجاه": 1,
    "المهر": 1,
    "المبلل": 1,
    "الترانيم": 1,
    "الحمدلة": 1,
    "دوز": 1,
    "دويل": 1,
    "الإغفاءة": 1,
    "مغفو": 1,
    "دزينة": 1,
    "العشرات": 1,
    "الغفيان": 1,
    "النعاس": 1,
    "دائخ": 1,
    "المومس": 1,
    "قاتم": 1,
    "المومسات": 1,
    "دراكو": 1,
    "متشدد": 1,
    "المسودة": 1,
    "تعرضا": 1,
    "للتيارات": 1,
    "الصياغة": 1,
    "المسودات": 1,
    "الرسامون": 1,
    "صياغة": 1,
    "العائق": 1,
    "مسحوب": 1,
    "الساحب": 1,
    "السحب": 1,
    "بشد": 1,
    "بسحب": 1,
    "التنين": 1,
    "آباء": 1,
    "المغزل": 1,
    "مغزل": 1,
    "دراجونهيد": 1,
    "(أعشاب)": 1,
    "التنينات": 1,
    "العوائق": 1,
    "التصريفات": 1,
    "المصرف": 1,
    "المصرفات": 1,
    "أنبوب": 1,
    "البواليع": 1,
    "درايك": 1,
    "الدرهم": 1,
    "المسرحية": 1,
    "دراماماين": 1,
    "المسرحيات": 1,
    "المسرحي": 1,
    "المسرحيون": 1,
    "المسرحة": 1,
    "ممسرح": 1,
    "يمسرح": 1,
    "التمسرح": 1,
    "شرب": 1,
    "الأقمشة": 1,
    "البزازون": 1,
    "صارم": 1,
    "الرسامة": 1,
    "التخطيطية": 1,
    "الجسر": 1,
    "المسحوب": 1,
    "الدرج": 1,
    "التشدق": 1,
    "متشدق": 1,
    "يمد": 1,
    "بتشدق": 1,
    "بمد": 1,
    "الفزاعة": 1,
    "المدرعة": 1,
    "الحلم": 1,
    "الإنسان": 1,
    "محلوم": 1,
    "أحلاما": 1,
    "الخيالية": 1,
    "الأحلام": 1,
    "أراضي": 1,
    "خيال": 1,
    "كالحلم": 1,
    "كئابة": 1,
    "موحش": 1,
    "الجرافة": 1,
    "مجروف": 1,
    "الجرافات": 1,
    "الجرف": 1,
    "الرواسب": 1,
    "الثمالة": 1,
    "منقع": 1,
    "الناقع": 1,
    "التنقيع": 1,
    "ملبوس": 1,
    "الخزائن": 1,
    "الألبسة": 1,
    "تأنقا": 1,
    "التأنق": 1,
    "الخياط": 1,
    "الخياطون": 1,
    "درو": 1,
    "دريكسيل": 1,
    "دريفوس": 1,
    "قليل": 1,
    "التقطر": 1,
    "دريبلير": 1,
    "القطرات": 1,
    "المجففات": 1,
    "أجف": 1,
    "الإنجراف": 1,
    "منجرف": 1,
    "التائه": 1,
    "التائهون": 1,
    "بانسياب": 1,
    "الأكوام": 1,
    "المجروف": 1,
    "المثقاب": 1,
    "دريلير": 1,
    "المدرب": 1,
    "المثاقب": 1,
    "للشرب": 1,
    "الشارب": 1,
    "الشرابون": 1,
    "القطرة": 1,
    "المرق": 1,
    "دريسكول": 1,
    "قضاء": 1,
    "الحاجة": 1,
    "سال": 1,
    "لعابه": 1,
    "يسيل": 1,
    "الانسياق": 1,
    "رخصة": 1,
    "الرذاذ": 1,
    "أمطر": 1,
    "مطرا": 1,
    "خفيفا": 1,
    "الخفيفة": 1,
    "إمطار": 1,
    "ممطر": 1,
    "طريف": 1,
    "الدعابة": 1,
    "المهزلة": 1,
    "اﻷزاز": 1,
    "اﻷزيز)": 1,
    "برتابة": 1,
    "رول": 1,
    "مرول": 1,
    "الترويل": 1,
    "يرول": 1,
    "تدل": 1,
    "متدلى": 1,
    "تدلي": 1,
    "بتدلي": 1,
    "يتدلى": 1,
    "دروبي": 1,
    "المنبوذون": 1,
    "القطارة": 1,
    "القطارات": 1,
    "الذرق": 1,
    "إستسقائي": 1,
    "التورمات": 1,
    "الإتسقاء": 1,
    "ذبابة": 1,
    "أحثل": 1,
    "من..": 1,
    "اﻷكثر": 1,
    "حثالة": 1,
    "الحثالة": 1,
    "مشوب": 1,
    "بالشوائب": 1,
    "القطيع": 1,
    "هائلة": 1,
    "إغرق": 1,
    "غارق": 1,
    "للغرق": 1,
    "الغرق": 1,
    "يغرق": 1,
    "إنعس": 1,
    "ناعس": 1,
    "ينعس": 1,
    "نعاسا": 1,
    "بخمول": 1,
    "نعسان": 1,
    "الكادح": 1,
    "كادح": 1,
    "الكدح": 1,
    "الكادحون": 1,
    "بكدح": 1,
    "العقار": 1,
    "عقاقير": 1,
    "الطبل": 1,
    "طبل": 1,
    "قرع": 1,
    "الطبول": 1,
    "جلدة": 1,
    "مطبل": 1,
    "الطبال": 1,
    "الطبالون": 1,
    "التطبيل": 1,
    "دروموند": 1,
    "أعواد": 1,
    "السكير": 1,
    "السكارى": 1,
    "بثمالة": 1,
    "النوي": 1,
    "النويات": 1,
    "دروري": 1,
    "الجنية": 1,
    "درايدن": 1,
    "دريوال": 1,
    "الثنائيين": 1,
    "الثنائيون": 1,
    "دوان": 1,
    "لقب": 1,
    "ملقب": 1,
    "التشحيم": 1,
    "دوبه": 1,
    "دبلن": 1,
    "دوبويس": 1,
    "يلقب": 1,
    "دوبوك": 1,
    "دوقي": 1,
    "الدوقية": 1,
    "أوروبية": 1,
    "الدوقة": 1,
    "الدوقات": 1,
    "الدوقيات": 1,
    "البطة": 1,
    "متفادى": 1,
    "الذليل": 1,
    "البطات": 1,
    "الحبيب": 1,
    "أنبوبي": 1,
    "التطرق": 1,
    "دادلي": 1,
    "الفاشلون": 1,
    "المبارزة": 1,
    "متبارز": 1,
    "المبارز": 1,
    "المبارزون": 1,
    "التبارز": 1,
    "المبارزات": 1,
    "يتبارز": 1,
    "الاستحقاقية": 1,
    "دوف": 1,
    "دوفي": 1,
    "الحلمة": 1,
    "دوجان": 1,
    "عذب": 1,
    "السنطور": 1,
    "السناطير": 1,
    "البليد": 1,
    "البلداء": 1,
    "مبلد": 1,
    "دوليس": 1,
    "يبلد": 1,
    "الدلسي": 1,
    "دولوث": 1,
    "الدوما": 1,
    "دوما": 1,
    "أخرس": 1,
    "الدمبل": 1,
    "(ثقل)": 1,
    "(أثقال)": 1,
    "إصعق": 1,
    "مصعوق": 1,
    "ألمذهل": 1,
    "يصعق": 1,
    "البكم": 1,
    "صمت": 1,
    "الصمت": 1,
    "دومونت": 1,
    "النفاية": 1,
    "تخلص": 1,
    "بسمنة": 1,
    "الفطائر": 1,
    "دنبار": 1,
    "دنكان": 1,
    "الكثيب": 1,
    "دنيدن": 1,
    "الكثبان": 1,
    "الروث": 1,
    "الدنغري": 1,
    "رداء": 1,
    "مسمد": 1,
    "الزنزانة": 1,
    "الزنزانات": 1,
    "المزبلة": 1,
    "التسميد": 1,
    "دنهام": 1,
    "غمس": 1,
    "مغمس": 1,
    "دنكل": 1,
    "دنكر": 1,
    "التغميس": 1,
    "دنكيرك": 1,
    "يغمس": 1,
    "دنلاب": 1,
    "دنلوب": 1,
    "مطالب": 1,
    "المطالبون": 1,
    "إثنا": 1,
    "إثني": 1,
    "دووبوليست": 1,
    "الساذج": 1,
    "السذج": 1,
    "شرنقة": 1,
    "مزدوجة": 1,
    "للمضاعفة": 1,
    "المطابقة": 1,
    "دوبونت": 1,
    "دوكوسن": 1,
    "الدوام": 1,
    "المتانة": 1,
    "متين": 1,
    "المعمرة": 1,
    "السجن": 1,
    "دورانجو": 1,
    "المدة": 1,
    "الآماد": 1,
    "دورير": 1,
    "دورهام": 1,
    "دركي": 1,
    "دركين": 1,
    "دريل": 1,
    "درورد": 1,
    "دوسينبيرج": 1,
    "دوسينبري": 1,
    "الغسق": 1,
    "معتم": 1,
    "الغسوقة": 1,
    "دسلدورف": 1,
    "القمامة": 1,
    "صناديق": 1,
    "منفض": 1,
    "المنفضة": 1,
    "المنافض": 1,
    "ترابا": 1,
    "دستين": 1,
    "التغبر": 1,
    "التنفيض": 1,
    "غبار": 1,
    "المجرفة": 1,
    "المجارف": 1,
    "دوستستورم": 1,
    "مترب": 1,
    "هولندي": 1,
    "دتشاس": 1,
    "الهولندي": 1,
    "الهولنديون": 1,
    "مطيع": 1,
    "الإطاعة": 1,
    "للرسوم": 1,
    "دتون": 1,
    "دفوراك": 1,
    "القزم": 1,
    "مقزم": 1,
    "التقزيم": 1,
    "قزم": 1,
    "القزامة": 1,
    "الضئالة": 1,
    "الأقزام": 1,
    "السكنة": 1,
    "دوايت": 1,
    "تضاءل": 1,
    "متضاءل": 1,
    "يتضاءل": 1,
    "التضاءل": 1,
    "دويندلنجس": 1,
    "دوير": 1,
    "مصبوغ": 1,
    "الصباغة": 1,
    "الصباغ": 1,
    "الصباغون": 1,
    "ديلان": 1,
    "ديمانيك": 1,
    "دينامي": 1,
    "بفاعلية": 1,
    "الديناميكا": 1,
    "الدينامية": 1,
    "الديناميت": 1,
    "منسوف": 1,
    "المفجرون": 1,
    "الديناميات": 1,
    "مقوى": 1,
    "الأمير": 1,
    "الداين": 1,
    "الزحار": 1,
    "عسر": 1,
    "متخوم": 1,
    "الدسبروزيوم": 1,
    "(عنصر": 1,
    "المرير": 1,
    "ربحية": 1,
    "تعني": 1,
    "ببرمجيات": 1,
    "لينكس": 1,
    "وبرمجيات": 1,
    "أخرى": 1,
    "المهملات": 1,
    "المسكرات": 1,
    "قضيب": 1,
    "وتستعمل": 1,
    "للإثارة": 1,
    "الإضافية": 1,
    "المفروضة": 1,
    "الباخرة": 1,
    "تأخر": 1,
    "موعد": 1,
    "مغادرتها": 1,
    "يشكو": 1,
    "صعوبة": 1,
    "المكتوب": 1,
    "بمرض": 1,
    "الديسليكسيا": 1,
    "إي": 1,
    "الإلكتروني": 1,
    "ومثال": 1,
    "ايجان": 1,
    "الإشتياق": 1,
    "النسر": 1,
    "إرتفاع": 1,
    "المد": 1,
    "الأذن": 1,
    "سدادة": 1,
    "وجع": 1,
    "أوجاع": 1,
    "طبلة": 1,
    "طبلات": 1,
    "أذنين": 1,
    "الإيرل": 1,
    "الأرلية": 1,
    "أسبق": 1,
    "التبكير": 1,
    "واقيات": 1,
    "للأذن": 1,
    "إكسب": 1,
    "مكسوب": 1,
    "الكاسب": 1,
    "الكسبة": 1,
    "العربون": 1,
    "جدي": 1,
    "الجدية": 1,
    "الدخل": 1,
    "المداخيل": 1,
    "يكسب": 1,
    "إيرب": 1,
    "قانون": 1,
    "السماعة": 1,
    "السماعات": 1,
    "الاذن": 1,
    "الاذان": 1,
    "القرط": 1,
    "الأقراط": 1,
    "الآذان": 1,
    "صام": 1,
    "اختبأ": 1,
    "الفخاريات": 1,
    "الدنيوية": 1,
    "دنيوي": 1,
    "ماكينة": 1,
    "التراب": 1,
    "الزلزال": 1,
    "الزلازل": 1,
    "المانع": 1,
    "الأرضي": 1,
    "دودة": 1,
    "ديدان": 1,
    "ترابي": 1,
    "شمع": 1,
    "الشهود": 1,
    "السهولة": 1,
    "الحوامل": 1,
    "أسهل": 1,
    "بسهولة": 1,
    "الشرق": 1,
    "شرقا": 1,
    "الفصح": 1,
    "الشرقي": 1,
    "الشرقيون": 1,
    "إيستهامبتون": 1,
    "إيستلاند": 1,
    "الشيوخ": 1,
    "إيسمان": 1,
    "إيستويك": 1,
    "إيستوود": 1,
    "مأكول": 1,
    "الآكل": 1,
    "الأكلة": 1,
    "إيتون": 1,
    "يأكل": 1,
    "إيو": 1,
    "إيف": 1,
    "التصنت": 1,
    "متصنت": 1,
    "المتصنت": 1,
    "المتصنتون": 1,
    "الإنصات": 1,
    "يتصنت": 1,
    "إيزيل": 1,
    "إنحسر": 1,
    "الإنحسار": 1,
    "ينحسر": 1,
    "الموسعة": 1,
    "للتبادل": 1,
    "إبين": 1,
    "الإيبونيت": 1,
    "الأبنوس": 1,
    "الابتهاج": 1,
    "الغريب": 1,
    "الأطوار": 1,
    "لامركزي": 1,
    "غرابة": 1,
    "الغريبو": 1,
    "إكليس": 1,
    "إكليروسي": 1,
    "المستويات": 1,
    "إيكيدنا": 1,
    "(آكل": 1,
    "النمل)": 1,
    "الصدى": 1,
    "مردد": 1,
    "الأصداء": 1,
    "ترديد": 1,
    "الشهرة": 1,
    "إنتقائي": 1,
    "الكسوف": 1,
    "مكسوف": 1,
    "مسير": 1,
    "القصيدة": 1,
    "اقتصادي": 1,
    "دراسات": 1,
    "عليا": 1,
    "بيئي": 1,
    "بيئيا": 1,
    "الالبيئة": 1,
    "اقتصاد": 1,
    "إحصائي": 1,
    "المتخصصون": 1,
    "بالإقتصاد": 1,
    "القياسي": 1,
    "الإقتصاد": 1,
    "إقتصادي": 1,
    "إقتصاديا": 1,
    "الإقتصاديات": 1,
    "الإقتصادي": 1,
    "الإقتصاديون": 1,
    "إقتصد": 1,
    "مقتصد": 1,
    "المقتصد": 1,
    "المقتصدون": 1,
    "يقتصد": 1,
    "الاقتصادي": 1,
    "البيئي": 1,
    "الأنظمة": 1,
    "البيئية": 1,
    "النشوات": 1,
    "إنشرح": 1,
    "منشرح": 1,
    "ينشرح": 1,
    "الإنشراح": 1,
    "النشوة": 1,
    "منتشي": 1,
    "بنشوة": 1,
    "منزاح": 1,
    "إكوادور": 1,
    "بتوحيد": 1,
    "اﻷديان": 1,
    "عالميا": 1,
    "موحدين": 1,
    "الوحدوية": 1,
    "توحيد": 1,
    "الأكزيما": 1,
    "إد": 1,
    "إدام": 1,
    "إدي": 1,
    "مدوم": 1,
    "الدوامة": 1,
    "التدويم": 1,
    "إديلويس": 1,
    "الوذمة": 1,
    "متورم": 1,
    "إدغار": 1,
    "حافة": 1,
    "أحرزت": 1,
    "مشحوذ": 1,
    "المقلم": 1,
    "إجيرتون": 1,
    "ايدجواتر": 1,
    "إجوود": 1,
    "تنرفزا": 1,
    "صلوحية": 1,
    "التلاؤم": 1,
    "المأكولات": 1,
    "التنوير": 1,
    "الصرح": 1,
    "الصروح": 1,
    "منشأ": 1,
    "ينشئ": 1,
    "أنشأ": 1,
    "تنويرية": 1,
    "أدنبرة": 1,
    "للتحرير": 1,
    "إديث": 1,
    "الطبعة": 1,
    "الطبعات": 1,
    "المحرر": 1,
    "الإفتتاحية": 1,
    "إكتب": 1,
    "إفتتاحية": 1,
    "الافتتاحية": 1,
    "يكتب": 1,
    "كتابة": 1,
    "تحريري": 1,
    "الإفتتاحيات": 1,
    "المحررون": 1,
    "إدموندز": 1,
    "إدموندسون": 1,
    "أدمنتون": 1,
    "إدموند": 1,
    "إدنة": 1,
    "معالجة": 1,
    "الإلكترونية": 1,
    "ل": 1,
    "تعليم": 1,
    "إدسجير": 1,
    "الصيفي": 1,
    "إدوارد": 1,
    "إدواردو": 1,
    "للتعليم": 1,
    "تعلم": 1,
    "يتعلم": 1,
    "التعلم": 1,
    "تربوي": 1,
    "إستنباطي": 1,
    "بالملك": 1,
    "\"ايدوارد\"": 1,
    "انجلترا": 1,
    "إدواردز": 1,
    "إدوين": 1,
    "إدوينا": 1,
    "الدماغي": 1,
    "الأنقليس": 1,
    "يلجراس": 1,
    "لجنة": 1,
    "فرصة": 1,
    "المتساوية": 1,
    "يرير": 1,
    "يريست": 1,
    "يرينيس": 1,
    "إمسح": 1,
    "للمسح": 1,
    "ممسوح": 1,
    "الطمس": 1,
    "الماسح": 1,
    "يمسح": 1,
    "الإحداث": 1,
    "عمليا": 1,
    "المستجيب": 1,
    "المستجيبات": 1,
    "التأثيرات": 1,
    "الفاعلية": 1,
    "الخنوثة": 1,
    "متخنث": 1,
    "مفار": 1,
    "الإنفعال": 1,
    "منفعل": 1,
    "يفور": 1,
    "الفوران": 1,
    "واهن": 1,
    "العقم": 1,
    "كفوء": 1,
    "إفي": 1,
    "الدفق": 1,
    "الدفقات": 1,
    "متدفق": 1,
    "المخلفات": 1,
    "الجهد": 1,
    "الجهود": 1,
    "ساطع": 1,
    "مراق": 1,
    "يريق": 1,
    "الإراقة": 1,
    "فياض": 1,
    "الفيض": 1,
    "الإفت": 1,
    "(زاحف)": 1,
    "سبيل": 1,
    "الجرافيك": 1,
    "المحسن": 1,
    "التعجب": 1,
    "يعبر": 1,
    "الفرح": 1,
    "عادل": 1,
    "مذهب": 1,
    "إجان": 1,
    "مضرب": 1,
    "يحرض,": 1,
    "يحرك": 1,
    "مجمع": 1,
    "مشروبات": 1,
    "الباذنجان": 1,
    "قشر": 1,
    "قشور": 1,
    "الأنا": 1,
    "الأنانية": 1,
    "أناني": 1,
    "الأنانيون": 1,
    "مزهو": 1,
    "الغرورية": 1,
    "المغرورون": 1,
    "شنيع": 1,
    "فضيع": 1,
    "الفضاعة": 1,
    "المخرج": 1,
    "مصر": 1,
    "المصري": 1,
    "المصريون": 1,
    "ايه": 1,
    "إهرليتش": 1,
    "آيتشمان": 1,
    "ريش": 1,
    "العيدر": 1,
    "الخطي": 1,
    "ثمانية": 1,
    "ثامن": 1,
    "ثماني": 1,
    "ثامنا": 1,
    "الأثمان": 1,
    "الثمانينات": 1,
    "ثمانون": 1,
    "الثمانيات": 1,
    "آيل": 1,
    "آيلين": 1,
    "آينشتاين": 1,
    "أينشتين": 1,
    "الآينشتانيوم": 1,
    "آير": 1,
    "آيزنهاور": 1,
    "آيسنير": 1,
    "أما": 1,
    "أما..": 1,
    "إقذف": 1,
    "قذفي": 1,
    "إكبيرج": 1,
    "إكستروم": 1,
    "إكتاتشروم": 1,
    "ال": 1,
    "المسهبون": 1,
    "إلين": 1,
    "الهمة": 1,
    "إنقض": 1,
    "منقضي": 1,
    "ينقضي": 1,
    "الإنقضاء": 1,
    "المطاطي": 1,
    "مطاطيا": 1,
    "المرونة": 1,
    "المطاطية": 1,
    "إلاستومر": 1,
    "(يشبه": 1,
    "إنتش": 1,
    "إلاتر": 1,
    "ينتشي": 1,
    "الإنتشاء": 1,
    "إلبا": 1,
    "الشيخ": 1,
    "الكهولة": 1,
    "مسن": 1,
    "إلدون": 1,
    "إلينور": 1,
    "إليزار": 1,
    "منتخب": 1,
    "الإنتخاب": 1,
    "الإنتخابات": 1,
    "مساندة": 1,
    "منظمو": 1,
    "إنتخابي": 1,
    "إختياريا": 1,
    "الاختيارية": 1,
    "إنتخابيا": 1,
    "إليكترا": 1,
    "المنتخبة": 1,
    "قطبية": 1,
    "دائمة": 1,
    "الكهربائية": 1,
    "الكهربائيون": 1,
    "الكهربة": 1,
    "مكهرب": 1,
    "يكهرب": 1,
    "كهرب": 1,
    "التكهرب": 1,
    "كهرو": 1,
    "كهروكيمياوي": 1,
    "بالصدمة": 1,
    "الأقطاب": 1,
    "كهروديناميكي": 1,
    "إليكتروجرافيك": 1,
    "المنحل": 1,
    "بالكهرباء": 1,
    "المنحلات": 1,
    "كهربائيا": 1,
    "تحليلا": 1,
    "المغناطيس": 1,
    "كهرومغناطيسي": 1,
    "بالكهرومغناطيسية": 1,
    "الكهرومغناطيسية": 1,
    "الكهرومغناطيسيات": 1,
    "المغناطيسات": 1,
    "كهروميكانيكي": 1,
    "حركي": 1,
    "العضام": 1,
    "الألكترون": 1,
    "بعث": 1,
    "الإلكترونيات": 1,
    "الألكترونات": 1,
    "مولد": 1,
    "للكهرباء": 1,
    "المطلي": 1,
    "الطلي": 1,
    "الكهربية": 1,
    "بالكهربائية": 1,
    "المستقرة": 1,
    "المعالج": 1,
    "الدهانون": 1,
    "والقوة": 1,
    "الضعيفة": 1,
    "المنتخبون": 1,
    "إليد": 1,
    "إليجانسي": 1,
    "رثائي": 1,
    "المرثيات": 1,
    "المرثية": 1,
    "عنصري": 1,
    "جوهريا": 1,
    "مركبة": 1,
    "إليمنات": 1,
    "إلينا": 1,
    "الفيل": 1,
    "فيلي": 1,
    "الفيلة": 1,
    "إرفع": 1,
    "يرفع": 1,
    "الإرتفاع": 1,
    "الإرتفاعات": 1,
    "المصعد": 1,
    "المصاعد": 1,
    "التصبيرة": 1,
    "الصباحية": 1,
    "حادي": 1,
    "العفريت": 1,
    "عفريتي": 1,
    "العفرتة": 1,
    "الجني": 1,
    "إلجين": 1,
    "ألي": 1,
    "إنتزع": 1,
    "الاستخراج": 1,
    "الاستخراجات": 1,
    "منتزع": 1,
    "الإنتزاع": 1,
    "ينتزع": 1,
    "الإستحقاقات": 1,
    "أليجه": 1,
    "إليوت": 1,
    "إليزابيث": 1,
    "إليز": 1,
    "إليشا": 1,
    "الترخيم": 1,
    "النخبة": 1,
    "النخب": 1,
    "النخبية": 1,
    "نخبوي": 1,
    "الإكسير": 1,
    "اليزابيثي": 1,
    "اليزابيثيون": 1,
    "إلخارت": 1,
    "الأيآئل": 1,
    "إيلا": 1,
    "إلي": 1,
    "البيضوي": 1,
    "مجسمات": 1,
    "الأهليلجية": 1,
    "إهليليجي": 1,
    "الإهليليجية": 1,
    "إليسون": 1,
    "ألسويرث": 1,
    "ألوود": 1,
    "الدردار": 1,
    "ألمير": 1,
    "إلمهورست": 1,
    "إلميرا": 1,
    "إلمسفورد": 1,
    "إلويس": 1,
    "طول": 1,
    "مطول": 1,
    "يطول": 1,
    "التطويل": 1,
    "الإطالة": 1,
    "إهرب": 1,
    "هارب": 1,
    "إلوبير": 1,
    "الفصاحة": 1,
    "بليغ": 1,
    "ألروي": 1,
    "إيلس": 1,
    "عدا": 1,
    "إلسي": 1,
    "إلزينور": 1,
    "ألتن": 1,
    "المحلول": 1,
    "تنشأ": 1,
    "توضيحي": 1,
    "تملص": 1,
    "متملص": 1,
    "يتملص": 1,
    "التملص": 1,
    "الجان": 1,
    "ألفيس": 1,
    "الجنة": 1,
    "إضعف": 1,
    "النحول": 1,
    "إماس": 1,
    "إنبثق": 1,
    "منبثق": 1,
    "ينبثق": 1,
    "الإنبثاق": 1,
    "إنبثاقي": 1,
    "أعتق": 1,
    "معتق": 1,
    "يعتق": 1,
    "الإعتاق": 1,
    "الإنعتاق": 1,
    "المعتق": 1,
    "المعتقون": 1,
    "إمانويل": 1,
    "حنط": 1,
    "محنط": 1,
    "المحنط": 1,
    "المحنطون": 1,
    "التحنيط": 1,
    "يحنط": 1,
    "بخاصة": 1,
    "داخلي": 1,
    "مقاطع": 1,
    "إصعد": 1,
    "باحراج": 1,
    "السفارات": 1,
    "السفارة": 1,
    "عبئ": 1,
    "محاصر": 1,
    "التعبئة": 1,
    "ضمن": 1,
    "مضمن": 1,
    "الغرز": 1,
    "يضمن": 1,
    "الجمر": 1,
    "إختلس": 1,
    "مختلس": 1,
    "المختلس": 1,
    "المختلسون": 1,
    "أشعر": 1,
    "بالمرارة": 1,
    "إشعار": 1,
    "التنغيص": 1,
    "يشعر": 1,
    "مجد": 1,
    "مزركش": 1,
    "الزخرفة": 1,
    "يمجد": 1,
    "الشعار": 1,
    "رمزي": 1,
    "الشعارات": 1,
    "مجسد": 1,
    "المجسد": 1,
    "يجسد": 1,
    "جسد": 1,
    "التجسيد": 1,
    "مشجع": 1,
    "التشجيع": 1,
    "إحضن": 1,
    "يحضن": 1,
    "إنقش": 1,
    "منقوش": 1,
    "المزخرف": 1,
    "المزخرفون": 1,
    "ينقش": 1,
    "النقوش": 1,
    "الشفاه": 1,
    "عزف": 1,
    "العناق": 1,
    "للضم": 1,
    "تعانق": 1,
    "المعانق": 1,
    "التعانق": 1,
    "بعناق": 1,
    "إحتوائي": 1,
    "هشش": 1,
    "المطرز": 1,
    "المطرزون": 1,
    "التطريزات": 1,
    "ورط": 1,
    "مورط": 1,
    "التوريط": 1,
    "يورط": 1,
    "جنيني": 1,
    "الأجنة": 1,
    "صحح": 1,
    "للتصحيح": 1,
    "الزمردة": 1,
    "الزمردات": 1,
    "إظهر": 1,
    "المحارب": 1,
    "أنهى": 1,
    "خدمته": 1,
    "فخري": 1,
    "إيمرسن": 1,
    "مقيئ": 1,
    "المقيئات": 1,
    "مهاجر": 1,
    "المهاجرون": 1,
    "هاجر": 1,
    "يهاجر": 1,
    "الهجرات": 1,
    "أميل": 1,
    "إمليو": 1,
    "إيميلي": 1,
    "السمو": 1,
    "سامي": 1,
    "بتفوق": 1,
    "الإمارة": 1,
    "الإمارات": 1,
    "المبعوثون": 1,
    "المبعوث": 1,
    "الإشعاعات": 1,
    "إبعث": 1,
    "الابتعاثية": 1,
    "الباعثون": 1,
    "إيما": 1,
    "إميت": 1,
    "إموري": 1,
    "العاطفة": 1,
    "العاطفية": 1,
    "عاطفيا": 1,
    "العواطف": 1,
    "إمباث": 1,
    "تقمصي": 1,
    "تعاطف": 1,
    "الإمبراطور": 1,
    "الأباطرة": 1,
    "بتأكيد": 1,
    "الإمبراطوريات": 1,
    "المجرب": 1,
    "تجريبي": 1,
    "التجريبية": 1,
    "التجريبي": 1,
    "التجريبيون": 1,
    "ضع": 1,
    "الموضع": 1,
    "إمبلاستيك": 1,
    "الإستخدام": 1,
    "الإستخدامية": 1,
    "للإستخدام": 1,
    "المستخدمون": 1,
    "رب": 1,
    "أرباب": 1,
    "التوظيف": 1,
    "الإستخدامات": 1,
    "المخازن": 1,
    "المتجر": 1,
    "المتاجر": 1,
    "مخول": 1,
    "الإمبراطورة": 1,
    "الإمبراطورات": 1,
    "معنى": 1,
    "فارغ": 1,
    "الإفراغ": 1,
    "السماء": 1,
    "حاك": 1,
    "محاكى": 1,
    "يحاكي": 1,
    "المحاكاة": 1,
    "محاكي": 1,
    "المحاكي": 1,
    "المحاكون": 1,
    "المستحلب": 1,
    "مستحلب": 1,
    "يستحلب": 1,
    "إستحلب": 1,
    "الإستحلاب": 1,
    "المستحلبات": 1,
    "إن": 1,
    "يؤهل‎": 1,
    "يخول": 1,
    "تمكين": 1,
    "التمكين": 1,
    "شرع": 1,
    "مشرع": 1,
    "التشريع": 1,
    "التشريعات": 1,
    "المينا": 1,
    "مصقول": 1,
    "الصاقل": 1,
    "(يصقل)": 1,
    "الصقال": 1,
    "إفتن": 1,
    "مفتون": 1,
    "يفتن": 1,
    "فتن": 1,
    "معسكر": 1,
    "العسكرة": 1,
    "يعسكر": 1,
    "غلف": 1,
    "مغلف": 1,
    "يغلف": 1,
    "التغليف": 1,
    "التلخيصات": 1,
    "التغليفات": 1,
    "برسم": 1,
    "إسحر": 1,
    "بهيج": 1,
    "الساحرة": 1,
    "الساحرات": 1,
    "تكلفة": 1,
    "ثمن": 1,
    "يفرض": 1,
    "رصع": 1,
    "الإنتشلادا": 1,
    "شفر": 1,
    "يشفر": 1,
    "يطوق": 1,
    "الجيب": 1,
    "الجيوب": 1,
    "أرفق": 1,
    "مرفق": 1,
    "يرفق": 1,
    "الإرفاق": 1,
    "التسييج": 1,
    "المرفقات": 1,
    "إنكوميا": 1,
    "الثناءات": 1,
    "أحط": 1,
    "يحيط": 1,
    "المرة": 1,
    "الأخرى": 1,
    "مستعاد": 1,
    "الإستعادة": 1,
    "متجاوز": 1,
    "المتجاوز": 1,
    "يتجاوز": 1,
    "الإنتهاكات": 1,
    "أثقل": 1,
    "مثقل": 1,
    "الإثقال": 1,
    "يثقل": 1,
    "العبء": 1,
    "الأعباء": 1,
    "المنشور": 1,
    "المنشورات": 1,
    "الموسوعات": 1,
    "المستعمل": 1,
    "للخطر": 1,
    "تعريض": 1,
    "يعرض": 1,
    "حبب": 1,
    "محبب": 1,
    "التحبيب": 1,
    "بتحبب": 1,
    "التحبب": 1,
    "يحبب": 1,
    "المسعى": 1,
    "مسعي": 1,
    "المحاول": 1,
    "المحاولون": 1,
    "السعي": 1,
    "المساعي": 1,
    "منتهى": 1,
    "المستوطن": 1,
    "الاستشراء": 1,
    "طرف": 1,
    "إندرز": 1,
    "المرحلة": 1,
    "إنديكوت": 1,
    "النهايات": 1,
    "لانهائي": 1,
    "اللانهائي": 1,
    "ختامي": 1,
    "تعليقات": 1,
    "ختامية": 1,
    "الداخلي": 1,
    "زواجي": 1,
    "الأقارب": 1,
    "النشوء": 1,
    "التشابه": 1,
    "الشكلي": 1,
    "المصدق": 1,
    "المصدقون": 1,
    "التصديقات": 1,
    "السويداء": 1,
    "غشائي": 1,
    "موهوب": 1,
    "الوهب": 1,
    "الهبة": 1,
    "الهبات": 1,
    "يهب": 1,
    "انتهاء": 1,
    "للتحمل": 1,
    "يطاق": 1,
    "دائمي": 1,
    "اﻷمد": 1,
    "طولا": 1,
    "الشرجية": 1,
    "الطاقات": 1,
    "إنفيلد": 1,
    "إطو": 1,
    "مطوي": 1,
    "الطوي": 1,
    "يطوى": 1,
    "إفرض": 1,
    "الإلزامية": 1,
    "فرضا": 1,
    "المنفذون": 1,
    "إنفورسيبل": 1,
    "بتوكيل": 1,
    "إشغل": 1,
    "إنجيل": 1,
    "محدث": 1,
    "بمحرك": 1,
    "الهندسة": 1,
    "إنجلاندر": 1,
    "إنجل": 1,
    "إنجليوود": 1,
    "إنجليتش": 1,
    "جوزيف": 1,
    "نمساوي": 1,
    "الإنجليزي": 1,
    "الإنجليز": 1,
    "الالتهام": 1,
    "مرسخ": 1,
    "إنسخ": 1,
    "ينسخ": 1,
    "بتشويق": 1,
    "الإنشغال": 1,
    "إبتلع": 1,
    "مبتلع": 1,
    "الإبتلاع": 1,
    "يبتلع": 1,
    "إنيد": 1,
    "النهي": 1,
    "بمتعة": 1,
    "متمتع": 1,
    "للزيادة": 1,
    "المكبر": 1,
    "المكبرات": 1,
    "يتضخم": 1,
    "التضخم": 1,
    "نور": 1,
    "ينور": 1,
    "إنليستر": 1,
    "يسجل": 1,
    "شبك": 1,
    "العداوات": 1,
    "معظم": 1,
    "التشريف": 1,
    "المرتق": 1,
    "يعظم": 1,
    "التعظيم": 1,
    "منتظم": 1,
    "إنول": 1,
    "الفظاعات": 1,
    "الهول": 1,
    "إنوس": 1,
    "إستفسر": 1,
    "مستفسر": 1,
    "المستفسر": 1,
    "المستفسرون": 1,
    "يستفسر": 1,
    "الإستفسار": 1,
    "مغضب": 1,
    "يبهج": 1,
    "الإبهاج": 1,
    "أغن": 1,
    "المثري": 1,
    "يغني": 1,
    "الإغناء": 1,
    "إنريكو": 1,
    "إحجب": 1,
    "محجوب": 1,
    "قدس": 1,
    "يقدس": 1,
    "كفن": 1,
    "مكفن": 1,
    "التكفين": 1,
    "يكفن": 1,
    "إستعبد": 1,
    "مستعبد": 1,
    "الإستعباد": 1,
    "المستعبد": 1,
    "المستعبدون": 1,
    "يستعبد": 1,
    "إنستاتايت": 1,
    "(معدن)": 1,
    "إتل": 1,
    "متلي": 1,
    "يتلي": 1,
    "الإتباع": 1,
    "إضمن": 1,
    "مستلزم": 1,
    "الإستلزام": 1,
    "الاستتباع": 1,
    "التشابك": 1,
    "المعقد": 1,
    "التشابكات": 1,
    "المشابكة": 1,
    "التورية": 1,
    "إدخل": 1,
    "الداخل": 1,
    "المشروع": 1,
    "المستثمر": 1,
    "المشاريع": 1,
    "جريئ": 1,
    "يدخل": 1,
    "مسلى": 1,
    "الهزلي": 1,
    "الهزليون": 1,
    "بامتاع": 1,
    "الترفيه": 1,
    "التسالي": 1,
    "للجسم": 1,
    "نتاج": 1,
    "حجمه": 1,
    "المضروبان": 1,
    "توج": 1,
    "حمس": 1,
    "محمس": 1,
    "يحمس": 1,
    "المتحمس": 1,
    "المتحمسون": 1,
    "أغر": 1,
    "مغرى": 1,
    "الإغراءات": 1,
    "المغري": 1,
    "المغرون": 1,
    "الكيانات": 1,
    "أهل": 1,
    "معنون": 1,
    "التأهيل": 1,
    "يؤهل": 1,
    "الكيان": 1,
    "إدفن": 1,
    "القبر": 1,
    "الحشراتي": 1,
    "الحاشية": 1,
    "الأحشاء": 1,
    "جر": 1,
    "مجرور": 1,
    "المنساب": 1,
    "يجر": 1,
    "مدوخ": 1,
    "التدويخ": 1,
    "المشترك": 1,
    "المشتركون": 1,
    "الفخاخ": 1,
    "توسل": 1,
    "متوسل": 1,
    "الإلتماس": 1,
    "التوسل": 1,
    "يتوسل": 1,
    "المقبلات": 1,
    "تحصن": 1,
    "متحصن": 1,
    "يتحصن": 1,
    "الإستحكام": 1,
    "الإستحكامات": 1,
    "الحر": 1,
    "طابق": 1,
    "طابقين": 1,
    "(ميزانين)": 1,
    "طوابق": 1,
    "منخفضة": 1,
    "إنتري": 1,
    "الانتروبيا": 1,
    "إئتمن": 1,
    "مؤتمن": 1,
    "يأتمن": 1,
    "إضفر": 1,
    "يضفر": 1,
    "للاحصاء": 1,
    "معدد": 1,
    "يعدد": 1,
    "ملفوظ": 1,
    "إلفظ": 1,
    "يلفظ": 1,
    "اللفظ": 1,
    "المصرح": 1,
    "المصرحون": 1,
    "المغلف": 1,
    "سمم": 1,
    "مسمم": 1,
    "يسمم": 1,
    "محسود": 1,
    "مدعاة": 1,
    "للحسد": 1,
    "بحسد": 1,
    "الحسود": 1,
    "الحساد": 1,
    "يحسد": 1,
    "الحسد": 1,
    "حوط": 1,
    "محوط": 1,
    "التحويط": 1,
    "إختصاصي": 1,
    "الضواحي": 1,
    "متصور": 1,
    "إنفيد": 1,
    "إنزيمي": 1,
    "الإنزيم": 1,
    "الإنزيمات": 1,
    "بداية": 1,
    "حصان": 1,
    "صبغة": 1,
    "فوسفورية": 1,
    "حماية": 1,
    "الكتفية": 1,
    "الكتفيات": 1,
    "عابر": 1,
    "العوابر": 1,
    "المذكرات": 1,
    "إفيسي": 1,
    "إفيسيون": 1,
    "إفيسس": 1,
    "(مدينة)": 1,
    "إفرايم": 1,
    "الملحمة": 1,
    "ملحمي": 1,
    "الملاحم": 1,
    "الأبيقوري": 1,
    "الأبيقوريون": 1,
    "إبيكرس": 1,
    "فلك": 1,
    "التدوير": 1,
    "أفلاك": 1,
    "تداويري": 1,
    "الوباء": 1,
    "وبائي": 1,
    "الأوبئة": 1,
    "مرضيا": 1,
    "وبائيا": 1,
    "التخلق": 1,
    "المتعاقب": 1,
    "الابيغرافي": 1,
    "الصرع": 1,
    "بالصرع": 1,
    "صرعي": 1,
    "الظاهرة": 1,
    "الكردوس": 1,
    "الأسقفي": 1,
    "الحادثة": 1,
    "بنظرية": 1,
    "الرسائل": 1,
    "رسائل": 1,
    "شفافة": 1,
    "بنمو": 1,
    "إبيتاز": 1,
    "الظهارة": 1,
    "طلائي": 1,
    "الخلاصات": 1,
    "الملخصون": 1,
    "العهود": 1,
    "إبوكسي": 1,
    "للألفباء": 1,
    "إبسوم": 1,
    "إبشتاين": 1,
    "التساوي": 1,
    "مطرد": 1,
    "برصانة": 1,
    "ساوى": 1,
    "ساو": 1,
    "مساوى": 1,
    "المعادل": 1,
    "المعادلات": 1,
    "يساوي": 1,
    "النظائر": 1,
    "الرصانة": 1,
    "المعادلة": 1,
    "تعادلي": 1,
    "الإستواء": 1,
    "إستوائي": 1,
    "استوائيمجري": 1,
    "الساسة": 1,
    "متساوو": 1,
    "وازن": 1,
    "التوازنات": 1,
    "(المشي": 1,
    "باتزان)": 1,
    "فرسي": 1,
    "الخيول": 1,
    "إعتدالي": 1,
    "الإعتدال": 1,
    "الإعتدالات": 1,
    "مجهز": 1,
    "بانصاف": 1,
    "العادية": 1,
    "العدالة": 1,
    "المكافئات": 1,
    "المكافئ": 1,
    "المكافئون": 1,
    "الطواريء": 1,
    "المستشفى": 1,
    "العصر": 1,
    "للإستئصال": 1,
    "إستأصل": 1,
    "مستأصل": 1,
    "يستأصل": 1,
    "الإستئصال": 1,
    "استئصالي": 1,
    "إمح": 1,
    "ممحو": 1,
    "المحاية": 1,
    "المحايات": 1,
    "يمحو": 1,
    "المحو": 1,
    "إراتو": 1,
    "إراتوسثينيز": 1,
    "الأربيوم": 1,
    "إردا": 1,
    "يحرث": 1,
    "منتصب": 1,
    "للإنتصاب": 1,
    "الإنتصاب": 1,
    "المركبون": 1,
    "ينصب": 1,
    "الإرج": 1,
    "الارجاتية": 1,
    "ولهذا": 1,
    "إرجوديك": 1,
    "احتمال": 1,
    "الشيىء": 1,
    "الانسان": 1,
    "بالمادة": 1,
    "الشقران": 1,
    "إيريك": 1,
    "إريك": 1,
    "إريكسون": 1,
    "أريكسون": 1,
    "آيري": 1,
    "بحيرات": 1,
    "\"البحيرات": 1,
    "العظمى\"": 1,
    "أرتيريا": 1,
    "إرلانج": 1,
    "إرلينميير": 1,
    "القاقم": 1,
    "إرمنيد": 1,
    "إيرنست": 1,
    "إرنيستاين": 1,
    "إرني": 1,
    "للتآكل": 1,
    "إروز": 1,
    "تآكلي": 1,
    "اﻷعمال": 1,
    "(أفلام": 1,
    "صور)": 1,
    "أخطأ": 1,
    "المهمات": 1,
    "ضالا": 1,
    "التجوال": 1,
    "الانحرافات": 1,
    "بعصبية": 1,
    "مخطأ": 1,
    "إرول": 1,
    "إكتشاف": 1,
    "يخطئ": 1,
    "إرسكين": 1,
    "سابقا": 1,
    "الاطلاع": 1,
    "سعة": 1,
    "الإطلاع": 1,
    "إنفجر": 1,
    "منفجر": 1,
    "الإنفجار": 1,
    "الإنفجارات": 1,
    "إنفجاري": 1,
    "ينفجر": 1,
    "إرفإن": 1,
    "إرون": 1,
    "سرب": 1,
    "تصاعد": 1,
    "يتصاعد": 1,
    "التصاعد": 1,
    "التصعيدات": 1,
    "الطيش": 1,
    "الإنهزامي": 1,
    "قوقعة": 1,
    "قواقع": 1,
    "إسكارول": 1,
    "الإستيراث": 1,
    "إسكيريتشيا": 1,
    "معوية": 1,
    "منضدة": 1,
    "أسكيمو": 1,
    "الأسكيمو": 1,
    "\"مريء\"": 1,
    "المرئ": 1,
    "باطني": 1,
    "اللاشعوري": 1,
    "التعريشة": 1,
    "التعريشات": 1,
    "خصوصا": 1,
    "الإسبيرانتو": 1,
    "يراقب": 1,
    "التجسس": 1,
    "المتنزه": 1,
    "إسبوسيتو": 1,
    "الإعتناق": 1,
    "تبنى": 1,
    "الإسبريسو": 1,
    "الروح": 1,
    "راقب": 1,
    "المحترم": 1,
    "المحترمون": 1,
    "إساير": 1,
    "المقالات": 1,
    "إسين": 1,
    "الجوهر": 1,
    "ضروري": 1,
    "الضرورة": 1,
    "الضروريات": 1,
    "إسيكس": 1,
    "قائم": 1,
    "المؤسس": 1,
    "يؤسس": 1,
    "العقارات": 1,
    "مثمن": 1,
    "التثمين": 1,
    "الإحترامات": 1,
    "إستيلا": 1,
    "الإستر": 1,
    "إستيس": 1,
    "إستر": 1,
    "إسذيت": 1,
    "إسذيتيس": 1,
    "الجماليات": 1,
    "احترام": 1,
    "تقدير": 1,
    "محترمة": 1,
    "التخمينات": 1,
    "تقديري": 1,
    "المقدر": 1,
    "المقدرون": 1,
    "أستونيا": 1,
    "الأستوني": 1,
    "مجافى": 1,
    "المنفر": 1,
    "يجافي": 1,
    "المجافاة": 1,
    "ايستروجين": 1,
    "الأنثوي": 1,
    "المصبات": 1,
    "مصبي": 1,
    "المصب": 1,
    "الفضائي": 1,
    "إيتا": 1,
    "للأبجدية": 1,
    "التقريبي": 1,
    "آخره": 1,
    "أبدي": 1,
    "يخلد": 1,
    "(الخلود)": 1,
    "خلد": 1,
    "الأبد": 1,
    "إثان": 1,
    "الإثنين": 1,
    "ايثانول": 1,
    "إثيل": 1,
    "الأثير": 1,
    "أثيري": 1,
    "اﻷثيرية": 1,
    "الإيثرنت": 1,
    "اﻷخلاقية": 1,
    "اﻷخلاق": 1,
    "إثيسيستس": 1,
    "الأخلاق": 1,
    "إثيوبيا": 1,
    "الأثيوبيون": 1,
    "عرقي": 1,
    "عرقيا": 1,
    "الإنتماءات": 1,
    "العرقية": 1,
    "الإنتماء": 1,
    "العرقي": 1,
    "الإنثوغرافيون": 1,
    "أنثوغرافي": 1,
    "وصف": 1,
    "الأعراق": 1,
    "البشرية": 1,
    "إنثولوجي": 1,
    "الأجناس": 1,
    "اﻷخلاقيات": 1,
    "الأخلاقيات": 1,
    "الأثيل": 1,
    "أثيل": 1,
    "الإثيلين": 1,
    "المسببات": 1,
    "إتريوريا": 1,
    "إشتقاقي": 1,
    "أصول": 1,
    "الأوكالبتوس": 1,
    "(نوع": 1,
    "النباتات)": 1,
    "القربان": 1,
    "قرباني": 1,
    "يوكر(لعبة": 1,
    "ورق)": 1,
    "يوكر": 1,
    "(لعبة": 1,
    "إقليديس": 1,
    "إقليدي": 1,
    "يوجين": 1,
    "يوجينيا": 1,
    "للنسل": 1,
    "بتحسين": 1,
    "يوكاريوت": 1,
    "(كائن": 1,
    "الخلية)": 1,
    "يولر": 1,
    "يولر,": 1,
    "قصائد": 1,
    "المداح": 1,
    "مدحي": 1,
    "المداحون": 1,
    "إمدح": 1,
    "المادح": 1,
    "المادحون": 1,
    "يمدح": 1,
    "المهذبون": 1,
    "يونيس": 1,
    "التلطيفية": 1,
    "مسيىء": 1,
    "تلطيفي": 1,
    "باساءة": 1,
    "رخيم": 1,
    "الجميلة": 1,
    "رخامة": 1,
    "الفربيون": 1,
    "الغبطة": 1,
    "الفرات": 1,
    "يوراسيا": 1,
    "أوروبا": 1,
    "أوراسيوي": 1,
    "وجدتها": 1,
    "يوريبيديز": 1,
    "أوربي": 1,
    "تيكنو": 1,
    "يوروكأرد": 1,
    "عدة": 1,
    "مقاسات": 1,
    "موحدة": 1,
    "ﻷلواح": 1,
    "رقص": 1,
    "اوروبي": 1,
    "أوروبي": 1,
    "الأوربة": 1,
    "مؤورب": 1,
    "الأوربيون": 1,
    "الأوروبيوم": 1,
    "يوريديس": 1,
    "أورفيس": 1,
    "إنصهاري": 1,
    "يوتيرب": 1,
    "الوحي": 1,
    "موت": 1,
    "إيفا": 1,
    "اخلائي": 1,
    "المجلى": 1,
    "المجلون": 1,
    "مجتنب": 1,
    "المتهرب": 1,
    "المتهربون": 1,
    "تقييمي": 1,
    "المخمنون": 1,
    "التلاشي": 1,
    "متلاشي": 1,
    "الأنجليكانية": 1,
    "التبشيريون": 1,
    "إفانجيلاين": 1,
    "الإنجيلية": 1,
    "الداعية": 1,
    "تبشيرية": 1,
    "نصر": 1,
    "منصر": 1,
    "ينصر": 1,
    "التنصير": 1,
    "إيفانس": 1,
    "إيفانستون": 1,
    "إيفانسفيل": 1,
    "مبخر": 1,
    "التبخير": 1,
    "تبخري": 1,
    "المبخر": 1,
    "التهرب": 1,
    "تهربا": 1,
    "حواء": 1,
    "إفيد": 1,
    "إفيلن": 1,
    "حتى": 1,
    "مسوى": 1,
    "المساوي": 1,
    "الإنصاف": 1,
    "المساء": 1,
    "الأمسيات": 1,
    "بانتظام": 1,
    "صلاة": 1,
    "الحدث": 1,
    "حافل": 1,
    "بالأحداث": 1,
    "زاخرا": 1,
    "تعددية": 1,
    "اﻷحداث": 1,
    "الأماسي": 1,
    "نهائي": 1,
    "الإحتمالات": 1,
    "الإحتمال": 1,
    "إحدث": 1,
    "المحصلة": 1,
    "أبدا": 1,
    "ايفيرست": 1,
    "إفيريت": 1,
    "إفيرجلاد": 1,
    "إفيرجلاديز": 1,
    "الخضرة": 1,
    "إفيرهارت": 1,
    "الديمومة": 1,
    "الليالي": 1,
    "أثبت": 1,
    "الإثبات": 1,
    "الواضح": 1,
    "الأشرار": 1,
    "شرا": 1,
    "شرير": 1,
    "مبرهن": 1,
    "يبرهن": 1,
    "اثباته": 1,
    "البرهنة": 1,
    "أحشاء": 1,
    "للاثارة": 1,
    "شاعريا": 1,
    "الاثارة": 1,
    "إستدع": 1,
    "مستدعى": 1,
    "يستدعى": 1,
    "الإستدعاء": 1,
    "منشىء": 1,
    "مناشئ": 1,
    "تطوري": 1,
    "التطوري": 1,
    "التطوريون": 1,
    "يتطور": 1,
    "النعجة": 1,
    "إوين": 1,
    "النعاج": 1,
    "إوينج": 1,
    "مثار": 1,
    "التفاقم": 1,
    "مضبوط": 1,
    "الفارض": 1,
    "الضبط": 1,
    "بالضبط": 1,
    "مبالغ": 1,
    "بمبالغة": 1,
    "المبالغة": 1,
    "يبالغ": 1,
    "المبالغات": 1,
    "الإعلاء": 1,
    "بتمجيد": 1,
    "المرقي": 1,
    "المرقون": 1,
    "الإمتحان": 1,
    "إختبار": 1,
    "إفحص": 1,
    "الممتحن": 1,
    "الممتحنون": 1,
    "بمثال": 1,
    "الإمتحانات": 1,
    "ألمغيظ": 1,
    "باغضاب": 1,
    "نقب": 1,
    "منقب": 1,
    "ينقب": 1,
    "التنقيب": 1,
    "المنقب": 1,
    "المنقبون": 1,
    "المتفوق": 1,
    "إبرع": 1,
    "أصحاب": 1,
    "يبرع": 1,
    "النشارة": 1,
    "ماعدا": 1,
    "بأستثناء": 1,
    "الإستثناء": 1,
    "استثنائي": 1,
    "أﻹمتياز": 1,
    "الإستثناءات": 1,
    "إستثناء": 1,
    "المقتطف": 1,
    "مقتطف": 1,
    "الزيادات": 1,
    "مفرط": 1,
    "بإفراط": 1,
    "متبادل": 1,
    "المبدل": 1,
    "المبدلون": 1,
    "التبادلات": 1,
    "الخزينة": 1,
    "للضريبة": 1,
    "الضريبة": 1,
    "الضرائب": 1,
    "فرض": 1,
    "إيكسايت": 1,
    "باثارة": 1,
    "اﻹلكترون": 1,
    "مصيح": 1,
    "الصارخ": 1,
    "الصارخون": 1,
    "الصيحة": 1,
    "تعجبي": 1,
    "إستثن": 1,
    "مستثنى": 1,
    "يستثنى": 1,
    "حصري": 1,
    "الحصري": 1,
    "الخصوصية": 1,
    "المطرود": 1,
    "المطرودون": 1,
    "حرماني": 1,
    "إسلخ": 1,
    "مسلوخ": 1,
    "يسلخ": 1,
    "السلخ": 1,
    "الغائط": 1,
    "تغوط": 1,
    "متغوط": 1,
    "ألفصل": 1,
    "ثم": 1,
    "يتغوط": 1,
    "إبرازي": 1,
    "معذب": 1,
    "يعذب": 1,
    "لايطاق": 1,
    "مبرأ": 1,
    "تبريئي": 1,
    "تجوالي": 1,
    "معذور": 1,
    "بعفو": 1,
    "العذر": 1,
    "معذر": 1,
    "الصافح": 1,
    "الأعذار": 1,
    "الإعذار": 1,
    "الإذن": 1,
    "بالتغيب": 1,
    "إكسيك": 1,
    "إلعن": 1,
    "للتنفيذ": 1,
    "نفذ": 1,
    "ينفذ": 1,
    "الإعدام": 1,
    "الجلاد": 1,
    "الجلادون": 1,
    "التنفيذيون": 1,
    "تنفيذي": 1,
    "المنفذة": 1,
    "المنفذات": 1,
    "التفاسير": 1,
    "إكسيجيت": 1,
    "النموذج": 1,
    "نموذجيا": 1,
    "النماذج": 1,
    "معفى": 1,
    "للاستعمال": 1,
    "ممارس": 1,
    "المتمرن": 1,
    "المتمرنون": 1,
    "الممارسة": 1,
    "يمارس": 1,
    "إكسيس": 1,
    "ثلاثة": 1,
    "إكسيتر": 1,
    "إزفر": 1,
    "مزفور": 1,
    "يزفر": 1,
    "الزفير": 1,
    "العادم": 1,
    "منهك": 1,
    "باستنزاف": 1,
    "المستنفذ": 1,
    "مستنزف": 1,
    "الإستنزاف": 1,
    "الإعياء": 1,
    "العوادم": 1,
    "المعروض": 1,
    "المعرض": 1,
    "متلقي": 1,
    "الإعانة": 1,
    "المتباهون": 1,
    "المعارض": 1,
    "العارض": 1,
    "العارضون": 1,
    "المعروضات": 1,
    "إبتهاجي": 1,
    "محثوث": 1,
    "الحاث": 1,
    "منبوش": 1,
    "نباش": 1,
    "القبور": 1,
    "الضرورات": 1,
    "المنفى": 1,
    "منفي": 1,
    "المنفيون": 1,
    "جد": 1,
    "الوجود": 1,
    "وجودي": 1,
    "الوجودية": 1,
    "الوجودي": 1,
    "الوجوديون": 1,
    "الإيجاد": 1,
    "يجد": 1,
    "النزوح": 1,
    "الجماعي": 1,
    "تحريمي": 1,
    "خارجي": 1,
    "باهظ": 1,
    "بفداحة": 1,
    "التعويذي": 1,
    "التعويذيون": 1,
    "الخارجي": 1,
    "الهياكل": 1,
    "الخارجية": 1,
    "متمدد": 1,
    "المتوسع": 1,
    "المتوسعون": 1,
    "الفسحة": 1,
    "التوسعية": 1,
    "التوسعي": 1,
    "توسعي": 1,
    "طف": 1,
    "مطاف": 1,
    "يطوف": 1,
    "الطوف": 1,
    "المغترب": 1,
    "متغرب": 1,
    "التغريب": 1,
    "الإغتراب": 1,
    "بإنتظار": 1,
    "مقشع": 1,
    "إبصق": 1,
    "البصاق": 1,
    "الذرائع": 1,
    "الذريعة": 1,
    "الوسيلة": 1,
    "التعجيل": 1,
    "البعثة": 1,
    "البعثات": 1,
    "للطرد": 1,
    "للإستهلاك": 1,
    "ألزم": 1,
    "بالنفقات": 1,
    "إلزام": 1,
    "التجربة": 1,
    "مجرب": 1,
    "التجارب": 1,
    "التجريب": 1,
    "المجربون": 1,
    "إكسبيشن": 1,
    "مكفر": 1,
    "تكفيري": 1,
    "إنته": 1,
    "ينتهي": 1,
    "للتوضيح": 1,
    "المفسر": 1,
    "المفسرون": 1,
    "التفسيرات": 1,
    "الشتائم": 1,
    "المأثرة": 1,
    "للاستغلال": 1,
    "الإستغلال": 1,
    "إستغلالي": 1,
    "المآثر": 1,
    "الإستكشاف": 1,
    "الإستكشافات": 1,
    "إستطلاعي": 1,
    "إستكشف": 1,
    "المستكشف": 1,
    "المستكشفون": 1,
    "المتفجرة": 1,
    "الإنفجارية": 1,
    "أسي": 1,
    "تصاعديا": 1,
    "التصدير": 1,
    "الصادرات": 1,
    "المصدر": 1,
    "المصدرون": 1,
    "الفاضح": 1,
    "الفاضحون": 1,
    "التعريض": 1,
    "إشرح": 1,
    "الشرح": 1,
    "الشروحات": 1,
    "تفسيري": 1,
    "مشروح": 1,
    "الشارح": 1,
    "السريعون": 1,
    "للتعبير": 1,
    "بتعبير": 1,
    "الإبداء": 1,
    "التعبيرية": 1,
    "التعبيري": 1,
    "تعبيري": 1,
    "التعبيريون": 1,
    "التعابير": 1,
    "الملكيات": 1,
    "إكسبانجير": 1,
    "المتأنق": 1,
    "الروعة": 1,
    "إرتجالي": 1,
    "الإرتجال": 1,
    "إرتجل": 1,
    "مرتجل": 1,
    "المرتجل": 1,
    "المرتجلون": 1,
    "يرتجل": 1,
    "ممتد": 1,
    "التمديد": 1,
    "الممدد": 1,
    "يمدد": 1,
    "للتمديد": 1,
    "التمدد": 1,
    "للإمتداد": 1,
    "الإمتداد": 1,
    "تفرعي": 1,
    "تفرعيا": 1,
    "توسعيا": 1,
    "الإمتدادات": 1,
    "نطاق": 1,
    "الباسطة": 1,
    "المدى": 1,
    "المديات": 1,
    "الخارج": 1,
    "الخارجيات": 1,
    "أبد": 1,
    "مباد": 1,
    "يبيد": 1,
    "المبيد": 1,
    "المبيدات": 1,
    "التبرير": 1,
    "خارجية": 1,
    "خارجيا": 1,
    "أحداث": 1,
    "منقرض": 1,
    "الإنقراض": 1,
    "إنقراضي": 1,
    "أطفأ": 1,
    "مطفأ": 1,
    "المطفأة": 1,
    "المطافئ": 1,
    "يطفئ": 1,
    "الإطفاء": 1,
    "إجتثاثي": 1,
    "يجتث)": 1,
    "ممجد": 1,
    "الممجد": 1,
    "بانتزاع": 1,
    "بابتزاز": 1,
    "إبتز": 1,
    "مبتز": 1,
    "الإبتزاز": 1,
    "الإبتزازي": 1,
    "الإبتزازيون": 1,
    "المنتزع": 1,
    "المنتزعون": 1,
    "الإبتزازات": 1,
    "إبتزازي": 1,
    "يبتز": 1,
    "الأجر": 1,
    "إكستراس": 1,
    "إستخلاصي": 1,
    "النازع": 1,
    "النازعون": 1,
    "مقنن": 1,
    "التنظيم": 1,
    "إستنبط": 1,
    "مستنبط": 1,
    "يستنبط": 1,
    "الإستنباط": 1,
    "إستقرائي": 1,
    "الإضافيات": 1,
    "العليا": 1,
    "التبذير": 1,
    "بإسراف": 1,
    "لناتج": 1,
    "التطرف": 1,
    "متطرف": 1,
    "التطرفية": 1,
    "المتطرف": 1,
    "المتطرفون": 1,
    "القصوى": 1,
    "الأقصى": 1,
    "للتخليص": 1,
    "التخليص": 1,
    "إنتماء": 1,
    "بإرضاء": 1,
    "الاخرين": 1,
    "المنفتح": 1,
    "المنفتحون": 1,
    "النتوء": 1,
    "الغزارة": 1,
    "الإفرازات": 1,
    "الإفراز": 1,
    "إنضح": 1,
    "ناضح": 1,
    "ينضح": 1,
    "النضوح": 1,
    "إغتبط": 1,
    "الإغتباط": 1,
    "مغتبط": 1,
    "ببهجة": 1,
    "يغتبط": 1,
    "إكسون": 1,
    "إكسزيس": 1,
    "مقلة": 1,
    "ييباليد": 1,
    "ييبالينج": 1,
    "مقل": 1,
    "العيون": 1,
    "ييبولت": 1,
    "ييبرايت": 1,
    "ييدنيس": 1,
    "ييجلاس": 1,
    "النظارات": 1,
    "عيون": 1,
    "الثقوب": 1,
    "الجفن": 1,
    "الجفون": 1,
    "ييبينسيل": 1,
    "العدسات": 1,
    "يير": 1,
    "ييرس": 1,
    "القذى": 1,
    "ييسوريس": 1,
    "حسور": 1,
    "ييتيث": 1,
    "العيان": 1,
    "شهود": 1,
    "حزقيال": 1,
    "عزرا": 1,
    "إله": 1,
    "اف": 1,
    "الفدرالي": 1,
    "فيبر": 1,
    "فابي": 1,
    "الخرافة": 1,
    "إسطوري": 1,
    "الرمزية": 1,
    "الخرافات": 1,
    "الرمزي": 1,
    "إصنع": 1,
    "الإفتراءات": 1,
    "المفتري": 1,
    "الأنسجة": 1,
    "الواجهة": 1,
    "الواجهات": 1,
    "ووجه": 1,
    "الجهل": 1,
    "الصفعة": 1,
    "الوجوه": 1,
    "النحت": 1,
    "الطرافة": 1,
    "تجميل": 1,
    "وجهيا": 1,
    "مسهل": 1,
    "يسهل": 1,
    "التسهيل": 1,
    "تسهيلي": 1,
    "الوساطة": 1,
    "الوسائل": 1,
    "الفئة": 1,
    "فئوي": 1,
    "الفئوية": 1,
    "الفئات": 1,
    "فاكتو": 1,
    "المضروب": 1,
    "المصانع": 1,
    "للعوامل": 1,
    "المصنع": 1,
    "واقعي": 1,
    "الحقيقية": 1,
    "الواقعية": 1,
    "الإبتداعيون": 1,
    "إبهت": 1,
    "مبهوت": 1,
    "بتضاءل": 1,
    "الاختفاء": 1,
    "التدريجي": 1,
    "ترويسة": 1,
    "يبهت": 1,
    "البهت": 1,
    "الإبتعاد": 1,
    "التضاؤلات": 1,
    "برازي": 1,
    "الجن": 1,
    "فافنر": 1,
    "تنين": 1,
    "الحطب": 1,
    "رواية": 1,
    "\"أوليفر": 1,
    "تويست\"": 1,
    "فاهي": 1,
    "الفهرنهايتية": 1,
    "مغيب": 1,
    "بضعف": 1,
    "النحيلون": 1,
    "فيربانكس": 1,
    "فيرتشايلد": 1,
    "فير": 1,
    "فيريد": 1,
    "أعدل": 1,
    "فيرفاكس": 1,
    "فيرفيلد": 1,
    "الجنيات": 1,
    "هدية": 1,
    "فيربورت": 1,
    "فيرفيو": 1,
    "السالك": 1,
    "السالكة": 1,
    "دنيا": 1,
    "الخيال": 1,
    "حوري": 1,
    "بإخلاص": 1,
    "الملفقون": 1,
    "المزيفون": 1,
    "الفلافل": 1,
    "صيادي": 1,
    "فالك": 1,
    "المغالطات": 1,
    "المغالطة": 1,
    "الإحتياطي": 1,
    "الساقط": 1,
    "معصوم": 1,
    "العرضة": 1,
    "لإرتكاب": 1,
    "بتعرض": 1,
    "المحروثة": 1,
    "ترك": 1,
    "حرثها": 1,
    "الإنهيارات": 1,
    "فالماوث": 1,
    "البطلان": 1,
    "الزور": 1,
    "المزيف": 1,
    "يزيف": 1,
    "زيف": 1,
    "فالستاف": 1,
    "ماجنة": 1,
    "مسرحيات": 1,
    "شكسبير": 1,
    "فالترير": 1,
    "بتردد": 1,
    "يتعثر": 1,
    "الشهرةشهرة": 1,
    "عائلي": 1,
    "التآلفات": 1,
    "التآلف": 1,
    "المعرف": 1,
    "يآلف": 1,
    "المؤالفة": 1,
    "حميمة": 1,
    "العوائل": 1,
    "عقيدة": 1,
    "المللة": 1,
    "\"فاميلي\"": 1,
    "فاملي": 1,
    "المجاعة": 1,
    "المجاعات": 1,
    "فامنج": 1,
    "جوع": 1,
    "مجوع": 1,
    "يجوع": 1,
    "التجويع": 1,
    "إنفتح": 1,
    "بتعصب": 1,
    "محب": 1,
    "يحب": 1,
    "بهرجة": 1,
    "وهميا": 1,
    "فخامة": 1,
    "بابتكار": 1,
    "الفخامة": 1,
    "الهوى": 1,
    "الإستعراض": 1,
    "التبويق": 1,
    "بمخالب": 1,
    "مهوى": 1,
    "فاني": 1,
    "الأنصار": 1,
    "الفنتازيا": 1,
    "استغرق": 1,
    "التخيلات": 1,
    "تخيل": 1,
    "متخيل": 1,
    "يتخيل": 1,
    "التخيل": 1,
    "المتخيل": 1,
    "الأسئلة": 1,
    "المكررة": 1,
    "الفاراد": 1,
    "فاراداي": 1,
    "الفارادات": 1,
    "فاربر": 1,
    "المهازل": 1,
    "الحشوة": 1,
    "فريد": 1,
    "فارير": 1,
    "اللامعقولية": 1,
    "منطقية": 1,
    "فارجو": 1,
    "فارينا": 1,
    "فاركاس": 1,
    "فارلي": 1,
    "المزرعة": 1,
    "فالح": 1,
    "البيت": 1,
    "الريفي": 1,
    "الريفية": 1,
    "فارمنجتون": 1,
    "الزراعية": 1,
    "وتوابعها": 1,
    "مساكن": 1,
    "عمال": 1,
    "الزرائب": 1,
    "فارنسورث": 1,
    "فارو": 1,
    "الحباشات": 1,
    "نفوذ": 1,
    "متنفذ": 1,
    "فاريل": 1,
    "مخنص": 1,
    "التخنيص": 1,
    "صغار": 1,
    "الفارسية": 1,
    "إيراني": 1,
    "نظر": 1,
    "ربع": 1,
    "البنس": 1,
    "فاسيا": 1,
    "الكتاب)": 1,
    "محزم": 1,
    "بحزمة": 1,
    "بالحزمة": 1,
    "يحزم": 1,
    "التحزم": 1,
    "الفاشية": 1,
    "الفاشي": 1,
    "فاشي": 1,
    "فاشية": 1,
    "الفاشيون": 1,
    "الأسلوب": 1,
    "عصري": 1,
    "عصريا": 1,
    "المرشد": 1,
    "يصوم": 1,
    "الإنشطار": 1,
    "بسقف": 1,
    "منحني": 1,
    "مؤخرتها": 1,
    "إربط": 1,
    "السحابة": 1,
    "السحابات": 1,
    "الصوم": 1,
    "قاتل": 1,
    "الجبرية": 1,
    "الجبري": 1,
    "الجبريون": 1,
    "الضحايا": 1,
    "الضحية": 1,
    "المصير": 1,
    "أبا": 1,
    "الإبوة": 1,
    "الصبح": 1,
    "يتيم": 1,
    "اﻷبوة": 1,
    "أبوي": 1,
    "الزوجة": 1,
    "القامة": 1,
    "للقياس": 1,
    "القامات": 1,
    "التعب": 1,
    "متعوب": 1,
    "فاطمة": 1,
    "السمينة": 1,
    "أسمن": 1,
    "سمن": 1,
    "مسمن": 1,
    "المسمن": 1,
    "المسمنات": 1,
    "التسمين": 1,
    "يسمن": 1,
    "المملوئين": 1,
    "ببدانة": 1,
    "السخافة": 1,
    "الحنفية": 1,
    "الحنفيات": 1,
    "فولكنر": 1,
    "الفولكنريون": 1,
    "منكفئا": 1,
    "التعييب": 1,
    "عيب": 1,
    "الغابات": 1,
    "المهندم": 1,
    "المهذب": 1,
    "فوست": 1,
    "فاوست": 1,
    "الأفضلية": 1,
    "التفضيل": 1,
    "إيجابيا": 1,
    "المفضل": 1,
    "المفضلون": 1,
    "المفضلة": 1,
    "التفضيلية": 1,
    "الحسنات": 1,
    "فوكيز": 1,
    "متودد": 1,
    "فاونير": 1,
    "التودد": 1,
    "الظبيان": 1,
    "الفاكس": 1,
    "الفاكسات": 1,
    "فايت": 1,
    "فايتيفيل": 1,
    "الفدرالية": 1,
    "الخائف": 1,
    "بتخوف": 1,
    "فيسابل": 1,
    "الجدوى": 1,
    "فيستر": 1,
    "الريشي": 1,
    "إحتياجات": 1,
    "البطالة": 1,
    "المقنعة": 1,
    "الأفرشة": 1,
    "الريشية": 1,
    "الترييش": 1,
    "الريش": 1,
    "فيثيرتوب": 1,
    "ريشي": 1,
    "فبراير/شباط": 1,
    "حمي": 1,
    "\"فبراير\"": 1,
    "خصب": 1,
    "يلقح": 1,
    "الخصب": 1,
    "فيديرس": 1,
    "إتحادي": 1,
    "الإتحادية": 1,
    "الإتحادي": 1,
    "الإتحاديون": 1,
    "الفيدراليون": 1,
    "فيدورا": 1,
    "(قبعة": 1,
    "(قبعات": 1,
    "المحققون": 1,
    "الغذاء": 1,
    "الردود": 1,
    "فيديد": 1,
    "الطاعم": 1,
    "الطاعمون": 1,
    "الإطعام": 1,
    "الملمس": 1,
    "اللامس": 1,
    "اللوامس": 1,
    "الشعور": 1,
    "المشاعر": 1,
    "فيني": 1,
    "إختلق": 1,
    "الإختلاق": 1,
    "يختلق": 1,
    "فيلدر": 1,
    "فيلدمان": 1,
    "الفلسبار": 1,
    "فيليس": 1,
    "فيليسيا": 1,
    "الهناء": 1,
    "فيليسيتي": 1,
    "الماكر": 1,
    "سنوري": 1,
    "الماكرون": 1,
    "فيليكس": 1,
    "فيلا": 1,
    "أولاد": 1,
    "للعضو": 1,
    "قاطعو": 1,
    "فيليني": 1,
    "الزمالات": 1,
    "فيلسيت": 1,
    "لبد": 1,
    "أحس": 1,
    "رأسا": 1,
    "ملبد": 1,
    "التلبيد": 1,
    "اللبادات": 1,
    "الأنثى": 1,
    "الأنوثة": 1,
    "الإناث": 1,
    "المؤنث": 1,
    "أنثويا": 1,
    "مساواة": 1,
    "الجنسين": 1,
    "بمساواة": 1,
    "إمرأة": 1,
    "فخذي": 1,
    "الفين": 1,
    "السياج": 1,
    "مسيج": 1,
    "الأسيجة": 1,
    "صن": 1,
    "مصان": 1,
    "يصون": 1,
    "التثقب": 1,
    "فينيل": 1,
    "مستنقعي": 1,
    "الفينات": 1,
    "فينتون": 1,
    "فينوجريك": 1,
    "فينويك": 1,
    "المدفوع": 1,
    "لة": 1,
    "فيربير": 1,
    "فيردناند": 1,
    "فردناندو": 1,
    "فيرغسن": 1,
    "فرمات": 1,
    "الخميرة": 1,
    "التخمر": 1,
    "للتخمر": 1,
    "الإختمار": 1,
    "متخمر": 1,
    "التخمير": 1,
    "الخمائر": 1,
    "فرمي": 1,
    "فيرميون": 1,
    "لإحصاءات": 1,
    "فيرميديراك": 1,
    "الفيرميوم": 1,
    "فرناندو": 1,
    "زرع": 1,
    "السراخس": 1,
    "بالسرخس": 1,
    "فيريرا": 1,
    "النمس": 1,
    "الفيريت": 1,
    "النموس": 1,
    "فيريس": 1,
    "الفريت": 1,
    "المسلح": 1,
    "بالعازل": 1,
    "الشفاف": 1,
    "مغناطيس": 1,
    "الحديدية": 1,
    "بالمغناطيس": 1,
    "الحديدي": 1,
    "بمقبض": 1,
    "عبارة": 1,
    "مسؤولو": 1,
    "فرتيج": 1,
    "فرتيلينيس": 1,
    "الخصوبة": 1,
    "الإخصاب": 1,
    "مخصب": 1,
    "المخصب": 1,
    "المخصبات": 1,
    "يخصب": 1,
    "التخصيب": 1,
    "التسخين": 1,
    "التأجج": 1,
    "العكرش": 1,
    "فيس": 1,
    "المهرجان": 1,
    "القيح": 1,
    "متقيح": 1,
    "التقيح": 1,
    "المهرجانات": 1,
    "إجلب": 1,
    "مجلوب": 1,
    "الجالب": 1,
    "يجلب": 1,
    "الجلب": 1,
    "مكرم": 1,
    "نتن": 1,
    "الإكرام": 1,
    "الوثن": 1,
    "الأوثان": 1,
    "نتوء": 1,
    "إقطاعي": 1,
    "الإقطاعية": 1,
    "محموم": 1,
    "التحمية": 1,
    "القليل": 1,
    "أقل": 1,
    "الخطيبة": 1,
    "الخطيبات": 1,
    "التام": 1,
    "فيات": 1,
    "الإكذوبة": 1,
    "الكذاب": 1,
    "الليف": 1,
    "الليفي": 1,
    "فيبيريد": 1,
    "فيبيرجلاس": 1,
    "(علامة": 1,
    "تجارية)": 1,
    "الألياف": 1,
    "الزجاجية": 1,
    "فيبوناكسي": 1,
    "فيبريل": 1,
    "الفايبرين": 1,
    "ليفي": 1,
    "الليفية": 1,
    "الأكاذيب": 1,
    "الشظية": 1,
    "ميكروفيش": 1,
    "البنيقة": 1,
    "زائفا": 1,
    "إخلاص": 1,
    "فيديل": 1,
    "الوفاء": 1,
    "الململة": 1,
    "متململ": 1,
    "التململ": 1,
    "تأثير": 1,
    "أجيب": 1,
    "(متلقي": 1,
    "الضربات)": 1,
    "(متلقو": 1,
    "(تلقي": 1,
    "الحقول": 1,
    "فيلدستون": 1,
    "الميداني": 1,
    "الباحثون": 1,
    "الميدانيون": 1,
    "عنيف": 1,
    "بعنف": 1,
    "أعنف": 1,
    "ناري": 1,
    "فايف": 1,
    "الناي": 1,
    "حجم": 1,
    "الطابور": 1,
    "خامس": 1,
    "الخمس": 1,
    "خامسا": 1,
    "الأخماس": 1,
    "الخمسينات": 1,
    "خمسون": 1,
    "مناصفة": 1,
    "التينة": 1,
    "فيجارو": 1,
    "التين": 1,
    "النموذجية": 1,
    "رياضة": 1,
    "التزحلق": 1,
    "فيجي": 1,
    "الشعيرة": 1,
    "شعيري": 1,
    "الشعيرات": 1,
    "البندقة": 1,
    "البندق": 1,
    "إسرق": 1,
    "مسروق": 1,
    "لص": 1,
    "لصوص": 1,
    "يسرق": 1,
    "ملف": 1,
    "أسماء": 1,
    "ملفخروج": 1,
    "ملفات": 1,
    "أنظمة": 1,
    "الشريحة": 1,
    "الشرائح": 1,
    "بنوي": 1,
    "المماطلة": 1,
    "السياسية": 1,
    "الثوري": 1,
    "المماطلات": 1,
    "التخريمية": 1,
    "الفلبيني": 1,
    "الفلبينيون": 1,
    "فيليبو": 1,
    "الملء": 1,
    "فيلابل": 1,
    "مملوء": 1,
    "الفيلير": 1,
    "الحشو": 1,
    "معصب": 1,
    "التعصيب": 1,
    "المهرات": 1,
    "الحشوات": 1,
    "الحافز": 1,
    "الحوافز": 1,
    "الفرزة": 1,
    "فيلمور": 1,
    "إملأ": 1,
    "المهرة": 1,
    "الفلم": 1,
    "عارض": 1,
    "فلم": 1,
    "غشائية": 1,
    "غشائيا": 1,
    "فيلماك": 1,
    "الأفلام": 1,
    "سينمائية": 1,
    "ترشح": 1,
    "المصفي": 1,
    "المرشحات": 1,
    "رشح": 1,
    "يرشح": 1,
    "الزعنفة": 1,
    "بالخدعة": 1,
    "منهى": 1,
    "أخيرا": 1,
    "النهائيات": 1,
    "مالي": 1,
    "ماليا": 1,
    "المالي": 1,
    "الماليون": 1,
    "فينتش": 1,
    "البرقش": 1,
    "للإيجاد": 1,
    "الغرامة": 1,
    "مغرم": 1,
    "رفيع": 1,
    "أدق": 1,
    "التبرج": 1,
    "الغرامات": 1,
    "البداعة": 1,
    "أجود": 1,
    "الإصبع": 1,
    "المشير": 1,
    "باستعمال": 1,
    "الأظفر": 1,
    "الأظافر": 1,
    "الأصبعي": 1,
    "الأصبعية": 1,
    "بصمة": 1,
    "بصم": 1,
    "باصبعه": 1,
    "بصمات": 1,
    "بكسل": 1,
    "التغريم": 1,
    "المنهي": 1,
    "القائمون": 1,
    "محدود": 1,
    "المحدودية": 1,
    "فلندا": 1,
    "فينلاي": 1,
    "الفنلندي": 1,
    "فيننيجان": 1,
    "فلندي": 1,
    "الفنلنديون": 1,
    "مزعنف": 1,
    "الزعانف": 1,
    "فيوريلو": 1,
    "فيوري": 1,
    "التنوب": 1,
    "تدريبات": 1,
    "للوقاية": 1,
    "للحريق": 1,
    "الناري": 1,
    "النارية": 1,
    "إنتشار": 1,
    "بهوس": 1,
    "الإحراق": 1,
    "مشعلو": 1,
    "الطرقة": 1,
    "الطرقات": 1,
    "المناجم": 1,
    "غازات": 1,
    "لدعم": 1,
    "المدفأة": 1,
    "بالأسلحة": 1,
    "اليراعات": 1,
    "اليراع": 1,
    "حواجز": 1,
    "محطات": 1,
    "التأمينات": 1,
    "المشعل": 1,
    "الولاعات": 1,
    "الموقد": 1,
    "المواقد": 1,
    "حنفية": 1,
    "حنفيات": 1,
    "النلر": 1,
    "فيرستون": 1,
    "أرباع": 1,
    "السماوات": 1,
    "بحزم": 1,
    "الدائم": 1,
    "الدائمة": 1,
    "الباكورة": 1,
    "الخور": 1,
    "الخوران": 1,
    "فيسكبين": 1,
    "فيشير": 1,
    "إشعاعي": 1,
    "مصيد": 1,
    "فيشر": 1,
    "الثروات": 1,
    "السمكية": 1,
    "صيادو": 1,
    "فيشيرز": 1,
    "الأسماك": 1,
    "الخطاف": 1,
    "الخطافات": 1,
    "الريبة": 1,
    "فيشكيل": 1,
    "فيشميل": 1,
    "ماسكة": 1,
    "بركة": 1,
    "بائعات": 1,
    "بائعة": 1,
    "فيسك": 1,
    "إنشطاري": 1,
    "الانشطار": 1,
    "الإنفلاق": 1,
    "فيسشنابيليتي": 1,
    "إنشطر": 1,
    "الإنفلاقات": 1,
    "منشق": 1,
    "القبضة": 1,
    "الإطباق": 1,
    "ملاكمة": 1,
    "بالأيدي": 1,
    "ملاكمات": 1,
    "القبضات": 1,
    "الناسور": 1,
    "النواسير": 1,
    "النوبة": 1,
    "فيتش": 1,
    "فيتشبيرغ": 1,
    "لائق": 1,
    "اللياقات": 1,
    "النوبات": 1,
    "الميكانيكي": 1,
    "الميكانيكيون": 1,
    "أصلح": 1,
    "فيتنيس": 1,
    "فيزجيرالد": 1,
    "فيزباتريك": 1,
    "فيزروي": 1,
    "الخمسة": 1,
    "باونات": 1,
    "الخمسات": 1,
    "المأزق": 1,
    "للتثبيت": 1,
    "ثبت": 1,
    "التعلقات": 1,
    "المثبتات": 1,
    "الثابتة": 1,
    "الإصلاح": 1,
    "المثبتون": 1,
    "المآزق": 1,
    "إصلاحات": 1,
    "فيزيو": 1,
    "الفاشل": 1,
    "أخفق": 1,
    "يخفق": 1,
    "هساس": 1,
    "المضايق": 1,
    "فلوريدا": 1,
    "فلاب": 1,
    "إندهش": 1,
    "الإندهاش": 1,
    "يندهش": 1,
    "ترهلا": 1,
    "الترهل": 1,
    "مترهل": 1,
    "التهاون": 1,
    "الليونة": 1,
    "فلاك": 1,
    "القنينة": 1,
    "سوطي": 1,
    "مجلود": 1,
    "مأشر": 1,
    "أثيم": 1,
    "فلاجلر": 1,
    "الإباريق": 1,
    "سارية": 1,
    "ساريات": 1,
    "البلاطة": 1,
    "البلاط": 1,
    "المدراس": 1,
    "المدراسات": 1,
    "الذوق": 1,
    "فلاكير": 1,
    "مقشر": 1,
    "الأكذوبة": 1,
    "مبهرج": 1,
    "فلامين": 1,
    "للهب": 1,
    "المشعلات": 1,
    "قاذف": 1,
    "قاذفات": 1,
    "النحام": 1,
    "للاشتعال": 1,
    "للإشتعال": 1,
    "فلانجان": 1,
    "فلانديرز": 1,
    "مشفه": 1,
    "التشفيه": 1,
    "الجناح": 1,
    "حماة": 1,
    "الأجنحة": 1,
    "الفانيلا": 1,
    "الفانيلات": 1,
    "خافق": 1,
    "الخفقان": 1,
    "الضوئية": 1,
    "الإندلاع": 1,
    "مندلع": 1,
    "الوميض": 1,
    "الإرتجاع": 1,
    "الكاميرا": 1,
    "الكاميرات": 1,
    "الفلاش": 1,
    "الفلاشات": 1,
    "مومض": 1,
    "الوماض": 1,
    "المتعري": 1,
    "الوماضات": 1,
    "المتعرون": 1,
    "الومضات": 1,
    "البهرجة": 1,
    "اللمعان": 1,
    "المصباح": 1,
    "المصابيح": 1,
    "الكاشفة": 1,
    "ومضة": 1,
    "ومضات": 1,
    "القارورة": 1,
    "القوارير": 1,
    "الشاحنة": 1,
    "المسطحة": 1,
    "فاتهيد": 1,
    "هندية": 1,
    "المكواة": 1,
    "المكاوي": 1,
    "شقة": 1,
    "شقق": 1,
    "فلاتنيس": 1,
    "مسطح": 1,
    "فلاتينير": 1,
    "التسطيح": 1,
    "يسطح": 1,
    "المتزلف": 1,
    "المتزلفون": 1,
    "بتملق": 1,
    "تسطحا": 1,
    "نوعا": 1,
    "القصير": 1,
    "إنتفاخ": 1,
    "الغازات": 1,
    "مكونات": 1,
    "السطحية": 1,
    "المباهاة": 1,
    "بكبرياء": 1,
    "يتكبر": 1,
    "فلونتي": 1,
    "الفلوت": 1,
    "النكهة": 1,
    "منكه": 1,
    "بطعم": 1,
    "النكهات": 1,
    "طعم": 1,
    "الكتان": 1,
    "كتاني": 1,
    "فلي": 1,
    "المشرط": 1,
    "المشارط": 1,
    "وورت": 1,
    "(عشب": 1,
    "طبي)": 1,
    "يبقع": 1,
    "الثني": 1,
    "إنثنائي": 1,
    "إنثناءات": 1,
    "يريش": 1,
    "مجزوز": 1,
    "الأصواف": 1,
    "بالصوف": 1,
    "الجز": 1,
    "فليد": 1,
    "الأسطول": 1,
    "زائلا": 1,
    "الأساطيل": 1,
    "فليشمان": 1,
    "فليشر": 1,
    "الفلاندري": 1,
    "الفلمنجيون": 1,
    "فلمنكي": 1,
    "باللحم": 1,
    "بنطلونات": 1,
    "بلون": 1,
    "الحسي": 1,
    "بريش": 1,
    "طار": 1,
    "الشفة": 1,
    "مثني": 1,
    "بمرونة": 1,
    "الإنحناءات": 1,
    "المرنة": 1,
    "متعرج": 1,
    "تعرجي": 1,
    "الانثناء": 1,
    "النقرة": 1,
    "حرك": 1,
    "الرجفة": 1,
    "الإيماض": 1,
    "مضطربة": 1,
    "الرجفات": 1,
    "طيشا": 1,
    "يطير": 1,
    "الرحلات": 1,
    "طائش": 1,
    "الجفلة": 1,
    "جافل": 1,
    "المنتفض": 1,
    "الجفلات": 1,
    "الجفل": 1,
    "باجفال": 1,
    "المؤقتة": 1,
    "مؤقتة": 1,
    "الصوان": 1,
    "متصلب": 1,
    "أمان": 1,
    "تقلب": 1,
    "يرجع": 1,
    "ذلاقة": 1,
    "ثرثار": 1,
    "مقلب": 1,
    "التقليب": 1,
    "اللعوب": 1,
    "مغازل": 1,
    "الخلاعة": 1,
    "متغازل": 1,
    "فليرتر": 1,
    "التغازل": 1,
    "اللعوبون": 1,
    "يرفرف": 1,
    "مرفرف": 1,
    "الرفرفة": 1,
    "سهم": 1,
    "للطفو": 1,
    "التعويم": 1,
    "معام": 1,
    "العوام": 1,
    "العوم": 1,
    "العائمة": 1,
    "الشوائب": 1,
    "صوف": 1,
    "مخصل": 1,
    "تلبد": 1,
    "متلبد": 1,
    "يتلبد": 1,
    "التلبد": 1,
    "خصلة": 1,
    "القطعان": 1,
    "الطفاوات": 1,
    "إجلد": 1,
    "الفيضان": 1,
    "بالفيضان": 1,
    "فلودر": 1,
    "البوابة": 1,
    "البوابات": 1,
    "الفيضانات": 1,
    "الأضواء": 1,
    "الأرضية": 1,
    "بلط": 1,
    "الأرضيات": 1,
    "أرضية": 1,
    "فندق": 1,
    "فنادق": 1,
    "رديئة": 1,
    "متخبط": 1,
    "مرونة": 1,
    "فلوبيس": 1,
    "المرن": 1,
    "يتخبط": 1,
    "زهري": 1,
    "فلورينس": 1,
    "فلورنسي": 1,
    "(إيطاليا)": 1,
    "تنبيت": 1,
    "الأزهار": 1,
    "مواطنو": 1,
    "التورد": 1,
    "الفلورين": 1,
    "الخيط": 1,
    "نظف": 1,
    "الخيوط": 1,
    "خيطي": 1,
    "فلوتاج": 1,
    "فلوتاجيس": 1,
    "الطافية": 1,
    "الإنتفاضة": 1,
    "منتفض": 1,
    "الإنتفاضات": 1,
    "الإنتفاض": 1,
    "المفلطح": 1,
    "المفلطحة": 1,
    "الإزدهار": 1,
    "مخروق": 1,
    "المزدري": 1,
    "تدفق": 1,
    "الإنسيابي": 1,
    "فلوتشارتينج": 1,
    "الإنسيابية": 1,
    "المزهر": 1,
    "الزهورية": 1,
    "الزهرية": 1,
    "الزهريات": 1,
    "وردي": 1,
    "مسترسل": 1,
    "التكلس": 1,
    "فلويد": 1,
    "الإنفلونزا": 1,
    "يخطيء": 1,
    "يخطىء": 1,
    "يتقلب": 1,
    "التقلبات": 1,
    "الطلاقة": 1,
    "طليق": 1,
    "بطلاقة": 1,
    "منفوش": 1,
    "نفشا": 1,
    "النفش": 1,
    "ينفش": 1,
    "والتدفق": 1,
    "السيولة": 1,
    "سائلا": 1,
    "محظوظ": 1,
    "إفشل": 1,
    "المرسب": 1,
    "الإمعة": 1,
    "يفشل": 1,
    "إستشع": 1,
    "مستشع": 1,
    "فلوريسسيين": 1,
    "الإسفار": 1,
    "الفلوريسنت": 1,
    "الفلوريسسيين": 1,
    "يستشع": 1,
    "الإستشعاع": 1,
    "فلوري": 1,
    "يعالج": 1,
    "بالفلور": 1,
    "عالج": 1,
    "المعالجات": 1,
    "الفلوريد": 1,
    "بمستوى": 1,
    "الفلور": 1,
    "الفلوريت": 1,
    "الفلوروكاربون": 1,
    "الفلوري": 1,
    "مضطرب": 1,
    "الإحمرار": 1,
    "مهيج": 1,
    "التهيج": 1,
    "مزمر": 1,
    "المزامير": 1,
    "التزمير": 1,
    "المزمر": 1,
    "المزمرون": 1,
    "الإرتجاف": 1,
    "المرفرف": 1,
    "برفرفة": 1,
    "الجريان": 1,
    "مذاب": 1,
    "إنسدادي": 1,
    "بالسلك": 1,
    "مصيدة": 1,
    "مصائد": 1,
    "الفارغة": 1,
    "الصفحات": 1,
    "فلن": 1,
    "صائدة": 1,
    "صائدات": 1,
    "المهاجرة": 1,
    "الحذافة": 1,
    "الحذافات": 1,
    "إف": 1,
    "تشكيل": 1,
    "ولد": 1,
    "مهرا": 1,
    "فولفوت": 1,
    "ولادة": 1,
    "الرغوة": 1,
    "مرغى": 1,
    "رغوي": 1,
    "فومفلاور": 1,
    "الإرغاء": 1,
    "برغوة": 1,
    "الرغو": 1,
    "البؤرية": 1,
    "مراكز": 1,
    "فوجارتاي": 1,
    "مصممة": 1,
    "أزياء": 1,
    "فوجيس": 1,
    "الممل": 1,
    "مضبب": 1,
    "ضبابا": 1,
    "ضبابي": 1,
    "الضبابيات": 1,
    "التضبيب": 1,
    "صافرة": 1,
    "صافرات": 1,
    "يضبب": 1,
    "النواقص": 1,
    "القصدير": 1,
    "دس": 1,
    "مدسوس": 1,
    "الدس": 1,
    "يدس": 1,
    "الطية": 1,
    "للطوي": 1,
    "الحافظة": 1,
    "للأوراق": 1,
    "مجلد": 1,
    "الحافظات": 1,
    "الطي": 1,
    "المطوية": 1,
    "الطيات": 1,
    "فولي": 1,
    "فولج": 1,
    "بأوراق": 1,
    "مورق": 1,
    "يورق": 1,
    "التوريق": 1,
    "القوم": 1,
    "الفولكلور": 1,
    "فولكروك": 1,
    "المسام": 1,
    "مسامي": 1,
    "المتابعة": 1,
    "يتابع": 1,
    "المتابعات": 1,
    "فولسوم": 1,
    "فم": 1,
    "المحرضون": 1,
    "ولعا": 1,
    "لاطف": 1,
    "ملاطف": 1,
    "الحنون": 1,
    "الملاطفة": 1,
    "الفانديو": 1,
    "(طبق": 1,
    "والزبدة)": 1,
    "فنتنبلو": 1,
    "فونتانا": 1,
    "الأغذية": 1,
    "الغذائية": 1,
    "فوليريس": 1,
    "بتهور": 1,
    "التهور": 1,
    "متهور": 1,
    "الغباوة": 1,
    "الحمقى": 1,
    "كور": 1,
    "خدم": 1,
    "(أولاد)": 1,
    "جسور": 1,
    "فوت": 1,
    "أقدام": 1,
    "الهامش": 1,
    "الهوامش": 1,
    "موطئ": 1,
    "مواطئ": 1,
    "الموطئ": 1,
    "المواطئ": 1,
    "يتلهى": 1,
    "صفوف": 1,
    "أمتعة": 1,
    "خزانات": 1,
    "فوتنوتينج": 1,
    "الممشى": 1,
    "للمشاة": 1,
    "إستراحة": 1,
    "موجع": 1,
    "الخطوة": 1,
    "الخطوات": 1,
    "القدمين": 1,
    "إعبث": 1,
    "يعبث": 1,
    "فوب": 1,
    "المتأنقون": 1,
    "للبيان": 1,
    "فورا": 1,
    "معلوف": 1,
    "العلاف": 1,
    "العلافون": 1,
    "الأعلاف": 1,
    "الغزوة": 1,
    "الغازي": 1,
    "الغزوات": 1,
    "إمتنع": 1,
    "المسلف": 1,
    "(أﻵباء": 1,
    "واﻷجداد)": 1,
    "الإمتناع": 1,
    "فوربز": 1,
    "محرم": 1,
    "التحريم": 1,
    "القهري": 1,
    "كالحروب": 1,
    "إجباري": 1,
    "بقسر": 1,
    "المبضع": 1,
    "الفارضون": 1,
    "القوات": 1,
    "بالقوة": 1,
    "فورد": 1,
    "ضحل": 1,
    "فوردام": 1,
    "أنذر": 1,
    "منذر": 1,
    "المتنبئ": 1,
    "المتنبؤون": 1,
    "ينذر": 1,
    "منذرا": 1,
    "بالشر": 1,
    "الشريرة": 1,
    "نذر": 1,
    "المتوقعون": 1,
    "التنبؤ": 1,
    "التعويق": 1,
    "التعويقات": 1,
    "مقدمة": 1,
    "مقدمات": 1,
    "السبابة": 1,
    "السبابات": 1,
    "الطليعة": 1,
    "الطلائع": 1,
    "تخل": 1,
    "المقدمات": 1,
    "مالية": 1,
    "الجبهة": 1,
    "الجباه": 1,
    "الأمامي": 1,
    "أجنبي": 1,
    "إحكم": 1,
    "محكوم": 1,
    "إحدس": 1,
    "المسبقة": 1,
    "يحدس": 1,
    "رئيسات": 1,
    "رئيسة": 1,
    "الناصية": 1,
    "فورمان": 1,
    "الصاري": 1,
    "الصواري": 1,
    "الاول": 1,
    "الأولية": 1,
    "الضحى": 1,
    "عدلي": 1,
    "عدليا": 1,
    "الجنائية": 1,
    "مسبق": 1,
    "مواعيد": 1,
    "مسبقة": 1,
    "الحقبة": 1,
    "الحقب": 1,
    "الشراع": 1,
    "الأشرعة": 1,
    "حدس": 1,
    "المنظورية": 1,
    "منظور": 1,
    "البصيرة": 1,
    "المستقبلية": 1,
    "البصائر": 1,
    "القلفة": 1,
    "القلفات": 1,
    "السابقة": 1,
    "العراف": 1,
    "التنبأ": 1,
    "التدبر": 1,
    "الماضي": 1,
    "إنذار": 1,
    "الهامة": 1,
    "الأبدية": 1,
    "انخفاضا": 1,
    "للشراع": 1,
    "للإستسلام": 1,
    "خاسر": 1,
    "المستسلم": 1,
    "المستسلمون": 1,
    "الخسارة": 1,
    "إحم": 1,
    "محمي": 1,
    "يحمي": 1,
    "يفرع": 1,
    "للتزوير": 1,
    "مزور": 1,
    "المزورون": 1,
    "دكاكين": 1,
    "إنس": 1,
    "النسيان": 1,
    "ينسي": 1,
    "للنسيان": 1,
    "للعفو": 1,
    "إغفر": 1,
    "مغفور": 1,
    "المغفرة": 1,
    "المسامح": 1,
    "نسى": 1,
    "منسي": 1,
    "التسرع": 1,
    "متشعب": 1,
    "فوركس": 1,
    "الفراق": 1,
    "للتشكيل": 1,
    "الثوب": 1,
    "فورمالديهايد": 1,
    "الفورمالين": 1,
    "الشكلية": 1,
    "الشكليون": 1,
    "الشكليات": 1,
    "الصائغ": 1,
    "المعبر": 1,
    "رسميا": 1,
    "صفات": 1,
    "فورميت": 1,
    "فورماتيز": 1,
    "الصيغ": 1,
    "مهيأ": 1,
    "التهيئة": 1,
    "المشكلون": 1,
    "فورمز": 1,
    "لتأليف": 1,
    "نملي": 1,
    "الفورميكا": 1,
    "يقهر": 1,
    "فورموسا": 1,
    "فورموسان": 1,
    "بتايوان": 1,
    "الأشكال": 1,
    "معادلي": 1,
    "السوابق": 1,
    "صيغي": 1,
    "صغ": 1,
    "يصوغ": 1,
    "الصياغات": 1,
    "المعادلون": 1,
    "إزن": 1,
    "فورست": 1,
    "حقا": 1,
    "بنبذه": 1,
    "بنبذ": 1,
    "فورسيذي": 1,
    "البراعات": 1,
    "فورتسكو": 1,
    "فصاعدا": 1,
    "فورتير": 1,
    "الأربعينات": 1,
    "أربعون": 1,
    "أربعين": 1,
    "للتحصين": 1,
    "التحصينات": 1,
    "المحصن": 1,
    "المحصنات": 1,
    "يحصن": 1,
    "الشديد": 1,
    "الإسبوعان": 1,
    "إسبوعين": 1,
    "فورتران": 1,
    "المحظوظين": 1,
    "الثروة": 1,
    "قارئ": 1,
    "المنتدى": 1,
    "المنتديات": 1,
    "يعزز": 1,
    "الشاحنون": 1,
    "قدما": 1,
    "فوس": 1,
    "متحجر": 1,
    "التحجر": 1,
    "محجر": 1,
    "يحجر": 1,
    "المتحجرات": 1,
    "أحفوري": 1,
    "قوتل": 1,
    "الفولار": 1,
    "قذارة": 1,
    "البذاءة": 1,
    "وجد": 1,
    "المعال": 1,
    "المعالون": 1,
    "مؤسس": 1,
    "المؤسسون": 1,
    "اللقيط": 1,
    "اللقطاء": 1,
    "المسابك": 1,
    "المسبك": 1,
    "الينبوع": 1,
    "النافورة": 1,
    "المنبع": 1,
    "المنابع": 1,
    "النافورات": 1,
    "الينابيع": 1,
    "أربعة": 1,
    "فوريير": 1,
    "الأربعات": 1,
    "الثمانون": 1,
    "اللعبة": 1,
    "الرباعية": 1,
    "مربع": 1,
    "رابع": 1,
    "الربع": 1,
    "رابعا": 1,
    "الأرباع": 1,
    "فوتين": 1,
    "بنقر": 1,
    "منقر": 1,
    "مرتكب": 1,
    "الثعلب": 1,
    "قفازات": 1,
    "فوكسهال": 1,
    "جحر": 1,
    "جحور": 1,
    "مكرا": 1,
    "الفوكستروت": 1,
    "ماكر": 1,
    "الإستراحة": 1,
    "النمطي": 1,
    "هندسي": 1,
    "متكرر": 1,
    "كسري": 1,
    "جزئ": 1,
    "يجزيء": 1,
    "المشاكسة": 1,
    "مكسور": 1,
    "متجزأ": 1,
    "الجزئية": 1,
    "التجزؤ": 1,
    "ممزق": 1,
    "التمزيق": 1,
    "العطور": 1,
    "رائحي": 1,
    "دفاعية": 1,
    "فرامبيسيا": 1,
    "معدي)": 1,
    "مؤطر": 1,
    "إطارمجموعة": 1,
    "إطار": 1,
    "المبروز": 1,
    "المبروزون": 1,
    "التأطير": 1,
    "البراويز": 1,
    "فران": 1,
    "الفرنك": 1,
    "فرانكا": 1,
    "فرنسا": 1,
    "فرانسيس": 1,
    "فرانسيسكا": 1,
    "فرانسيسكو": 1,
    "فرانسي": 1,
    "فرانسين": 1,
    "فرنسيس": 1,
    "الفرانسيسكاني": 1,
    "الفرانسيسكانيون": 1,
    "الفرنسيوم": 1,
    "فرانكو": 1,
    "فرانسوا": 1,
    "الفرنكات": 1,
    "فرانكتيرير": 1,
    "فرانجيباني": 1,
    "(شجيرة)": 1,
    "فرانك": 1,
    "مدموغ": 1,
    "فرانكل": 1,
    "فرانكفورد": 1,
    "فرانكفورت": 1,
    "سجقة": 1,
    "فرانكفورتير": 1,
    "سجق": 1,
    "فرانكي": 1,
    "الدمغ": 1,
    "فرانكلين": 1,
    "الفرانكلين": 1,
    "بريد": 1,
    "فراني": 1,
    "مسعور": 1,
    "فرانز": 1,
    "فريزر": 1,
    "نادي": 1,
    "الإخاء": 1,
    "تآخ": 1,
    "متآخى": 1,
    "للنادي": 1,
    "المنتسبون": 1,
    "يتآخي": 1,
    "التآخي": 1,
    "فراتز": 1,
    "قتل": 1,
    "بإحتيال": 1,
    "الإبلاء": 1,
    "فرين": 1,
    "يبلي": 1,
    "فرايزر": 1,
    "أبل": 1,
    "مبلى": 1,
    "النمشة": 1,
    "منمش": 1,
    "النمش": 1,
    "التنميش": 1,
    "فريدي": 1,
    "فريدريك": 1,
    "فريدريكس": 1,
    "فريدريكسبيرغ": 1,
    "فريدريكو": 1,
    "فريدريكتون": 1,
    "فريدولم": 1,
    "فريدريكسون": 1,
    "الحرة": 1,
    "للأرض": 1,
    "الهدية": 1,
    "الترويجية": 1,
    "الحرية": 1,
    "فريمان": 1,
    "الماسوني": 1,
    "الماسونية": 1,
    "الماسونيون": 1,
    "الأحرار": 1,
    "فريبورت": 1,
    "حرية": 1,
    "صخرة": 1,
    "صخور": 1,
    "المفكر": 1,
    "المفكرون": 1,
    "فري": 1,
    "تون": 1,
    "المجاني": 1,
    "حرة": 1,
    "للتجميد": 1,
    "الإنجماد": 1,
    "المجمدات": 1,
    "يتجمد": 1,
    "فريدا": 1,
    "الهيجان": 1,
    "فريون": 1,
    "تردد": 1,
    "يتردد": 1,
    "الجصي": 1,
    "الجصية": 1,
    "أنعش": 1,
    "المعطر": 1,
    "المعطرات": 1,
    "أعذب": 1,
    "المستجدون": 1,
    "الطراوة": 1,
    "العذب": 1,
    "فريسنل": 1,
    "فريسنو": 1,
    "الحنق": 1,
    "مشاكس": 1,
    "النقاق": 1,
    "فرويد": 1,
    "فرويدي": 1,
    "فراي": 1,
    "(إلة": 1,
    "الخصوبة)": 1,
    "فريا": 1,
    "الجمعة": 1,
    "التفتييت": 1,
    "خبرة": 1,
    "الرهبان": 1,
    "المقلي": 1,
    "المقلية": 1,
    "إحتكاكي": 1,
    "الإحتكاكيون": 1,
    "فريك": 1,
    "الإحتكاك": 1,
    "الاحتكاك": 1,
    "أيام": 1,
    "الثلاجة": 1,
    "الثلاجات": 1,
    "مقلي": 1,
    "فريدمان": 1,
    "أصدقاء": 1,
    "ودية": 1,
    "القالي": 1,
    "يقلي": 1,
    "الأفاريز": 1,
    "الفرقاطة": 1,
    "الفرقاطات": 1,
    "فريجا": 1,
    "الجنة)": 1,
    "التخويف": 1,
    "يخوف": 1,
    "فريجيدير": 1,
    "ببرود": 1,
    "المكسيكي.": 1,
    "فريجوليلو.": 1,
    "للماشية": 1,
    "الرتوش": 1,
    "مكشكش": 1,
    "مهدب": 1,
    "التهديب": 1,
    "الفريسبي": 1,
    "طافر": 1,
    "المفتش": 1,
    "الطفر": 1,
    "يطفر": 1,
    "إطل": 1,
    "حشيشة": 1,
    "الحجل": 1,
    "فريتو": 1,
    "مبدد": 1,
    "فريز": 1,
    "المجعد": 1,
    "مقلى": 1,
    "التقلية": 1,
    "قل": 1,
    "لبست": 1,
    "لبس": 1,
    "الفساتين": 1,
    "بالضفادع": 1,
    "مارح": 1,
    "فرحا": 1,
    "السعفة": 1,
    "أماميا": 1,
    "الرئيسي": 1,
    "واجهه": 1,
    "عصابات": 1,
    "الجبهات": 1,
    "فروست": 1,
    "عضة": 1,
    "الثلج": 1,
    "لسعة": 1,
    "أكله": 1,
    "متجمد": 1,
    "تجمدا": 1,
    "التجميد": 1,
    "الزبد": 1,
    "مزبد": 1,
    "زبدا": 1,
    "الإزباد": 1,
    "يزبد": 1,
    "التجهم": 1,
    "معبس": 1,
    "فراونير": 1,
    "التعبيس": 1,
    "بعبوس": 1,
    "التجهمات": 1,
    "عفن": 1,
    "تجمد": 1,
    "مجمد": 1,
    "يثمر": 1,
    "أثمر": 1,
    "الإثمار": 1,
    "الفواكه": 1,
    "فريهوف": 1,
    "الغرباء": 1,
    "الثمار": 1,
    "أطعم": 1,
    "الحرفوش": 1,
    "رثاثة": 1,
    "للخيبة": 1,
    "المقلاة": 1,
    "القلي": 1,
    "الفيدرالية": 1,
    "انتقال": 1,
    "فيوكس": 1,
    "الفوشيه": 1,
    "بالعامية": 1,
    "يستغل": 1,
    "إستغل": 1,
    "مقتاء": 1,
    "ممارسو": 1,
    "إثمل": 1,
    "ثامل": 1,
    "يثمل": 1,
    "الثمل": 1,
    "المزود": 1,
    "بالوقود": 1,
    "المزودون": 1,
    "متسلسل": 1,
    "هروبا": 1,
    "فوجي": 1,
    "فوجيتسو": 1,
    "فلبريت": 1,
    "الإرتكاز": 1,
    "الشاشة": 1,
    "الفيديو": 1,
    "الكامل": 1,
    "فولير": 1,
    "فوليرتون": 1,
    "أكمل": 1,
    "تنظيف": 1,
    "وتقليص": 1,
    "الإمتلاء": 1,
    "فولسومينيس": 1,
    "فولتن": 1,
    "خليط": 1,
    "والبني": 1,
    "فوم": 1,
    "تحسس": 1,
    "متحسس": 1,
    "المتخبطون": 1,
    "يتحسس": 1,
    "التحسس": 1,
    "الدخان": 1,
    "مدخن": 1,
    "الأدخنة": 1,
    "دخن": 1,
    "يدخن": 1,
    "التدخين": 1,
    "مبيدو": 1,
    "الوظيفة": 1,
    "وظيفي": 1,
    "المنفعة": 1,
    "نفعي": 1,
    "الوظائف": 1,
    "الموظفون": 1,
    "الموظف": 1,
    "مشتغل": 1,
    "الإشتغال": 1,
    "الأصولية": 1,
    "الأصوليون": 1,
    "الأساسيات": 1,
    "الجنازة": 1,
    "الجنائز": 1,
    "جنائزي": 1,
    "فطري": 1,
    "للإستبدال": 1,
    "بقتل": 1,
    "الفطريات": 1,
    "حبلي": 1,
    "الكريهة": 1,
    "قمعي": 1,
    "القموع": 1,
    "تسلية": 1,
    "مضحك": 1,
    "للأوقات": 1,
    "السعيدة": 1,
    "الفراء": 1,
    "فوران": 1,
    "جدد": 1,
    "مجدد": 1,
    "المجدد": 1,
    "يجدد": 1,
    "التجديد": 1,
    "بحيوية": 1,
    "بشراسة": 1,
    "الفرلنغ": 1,
    "الإجازة": 1,
    "مجاز": 1,
    "الإجازات": 1,
    "فرمان": 1,
    "الفرن": 1,
    "الأفران": 1,
    "فرنيس": 1,
    "أثث": 1,
    "يؤثث": 1,
    "التأثيث": 1,
    "تجار": 1,
    "بالفرو": 1,
    "مثلم": 1,
    "التثليم": 1,
    "مخفي": 1,
    "خلسة": 1,
    "القندول": 1,
    "داكن": 1,
    "المصهر": 1,
    "مصهر": 1,
    "فوسي": 1,
    "المصاهر": 1,
    "الإنصهار": 1,
    "للإنصهار": 1,
    "للصهر": 1,
    "مغزلي": 1,
    "الوابل": 1,
    "الإهتمامات": 1,
    "الفاضي": 1,
    "الأسوأ": 1,
    "تنورة": 1,
    "بقدم": 1,
    "بنتن": 1,
    "النتانة": 1,
    "المستقبلي": 1,
    "مستقبلي": 1,
    "المستقبليون": 1,
    "بالمستقبل": 1,
    "فوزيس": 1,
    "ضبابية": 1,
    "المعقوف": 1,
    "بالمصطلح": 1,
    "الاسكتلندي": 1,
    "إناء": 1,
    "لإحتواء": 1,
    "إنعدام": 1,
    "جي": 1,
    "جورجيا": 1,
    "المواقع": 1,
    "الكبردين": 1,
    "ببطىء": 1,
    "المهذار": 1,
    "المهذارون": 1,
    "ثرثرة": 1,
    "يتكلم": 1,
    "الهذرمات": 1,
    "غابرو": 1,
    "غابي": 1,
    "الجملون": 1,
    "جملوني": 1,
    "جابلر": 1,
    "الجملونات": 1,
    "غابون": 1,
    "غابريل": 1,
    "مهيم": 1,
    "الهيام": 1,
    "ذباب": 1,
    "الخيل": 1,
    "الجادولينيوم": 1,
    "جادوال": 1,
    "البط)": 1,
    "الغيلية": 1,
    "الغلطة": 1,
    "المسن": 1,
    "الكمامة": 1,
    "الكيال": 1,
    "العربونات": 1,
    "الكمامات": 1,
    "غايل": 1,
    "الناعورة": 1,
    "المكسب": 1,
    "الرابح": 1,
    "الرابحون": 1,
    "جاينز": 1,
    "غاينيسفيل": 1,
    "مربح": 1,
    "مربحا": 1,
    "الكسب": 1,
    "المكاسب": 1,
    "المشية": 1,
    "مشية": 1,
    "الجرموق": 1,
    "الجراميق": 1,
    "غايثرسبيرغ": 1,
    "المشيات": 1,
    "غال": 1,
    "جالاهاد": 1,
    "مسرحية": 1,
    "جزر": 1,
    "الجالاباجوس": 1,
    "قطن": 1,
    "جالاتيا": 1,
    "المجرات": 1,
    "المجرة": 1,
    "جالبريث": 1,
    "جالينوس": 1,
    "غالينا": 1,
    "جالينايت": 1,
    "الجليل": 1,
    "غالاغير": 1,
    "الشهم": 1,
    "الكياسة": 1,
    "جالبيري": 1,
    "المرارة": 1,
    "اللوحية": 1,
    "بإزعاج": 1,
    "السحنون": 1,
    "الجاليوم": 1,
    "الغالون": 1,
    "بالجالون": 1,
    "الغالونات": 1,
    "الركض": 1,
    "العدائون": 1,
    "الجري": 1,
    "غالواي": 1,
    "المشنقة": 1,
    "المشانق": 1,
    "الصفراوات": 1,
    "الحصاة": 1,
    "جالويس": 1,
    "الكالوش": 1,
    "الكالوشات": 1,
    "جالت": 1,
    "بصدمة": 1,
    "الجلفانومتر": 1,
    "الجلفانومترات": 1,
    "بجهاز": 1,
    "جالفيستون": 1,
    "جالفين": 1,
    "جالواي": 1,
    "غامبيا": 1,
    "المناورة": 1,
    "المناورات": 1,
    "المقامرة": 1,
    "مقامر": 1,
    "المقامرون": 1,
    "المقامرات": 1,
    "ديك": 1,
    "المصارعة": 1,
    "ديوك": 1,
    "اللعوبية": 1,
    "جرثومية": 1,
    "فساد": 1,
    "الصبي": 1,
    "اللعب": 1,
    "الغاما": 1,
    "غامير": 1,
    "فخذ": 1,
    "أفخاذ": 1,
    "الشجاع": 1,
    "أنحف": 1,
    "الدروج": 1,
    "الغنغرينا": 1,
    "متعفن": 1,
    "يتعفن": 1,
    "التعفن": 1,
    "غرغريني": 1,
    "الأطيش": 1,
    "جانيت": 1,
    "جغرافي": 1,
    "خرائط": 1,
    "القفاز": 1,
    "المؤقت": 1,
    "جانيميد": 1,
    "(أكبر": 1,
    "أقمار": 1,
    "المشتري)": 1,
    "العمومي": 1,
    "السجان": 1,
    "السجانون": 1,
    "التثاؤب": 1,
    "مفغور": 1,
    "المنبهر": 1,
    "يفغر": 1,
    "الفغر": 1,
    "بانشداه": 1,
    "غار": 1,
    "المرآب": 1,
    "الكراج": 1,
    "المرائيب": 1,
    "يركن": 1,
    "الزي": 1,
    "الزبالة": 1,
    "غربل": 1,
    "مغربل": 1,
    "المغربل": 1,
    "يغربل": 1,
    "الغربلة": 1,
    "غارسيا": 1,
    "الحديقة": 1,
    "البستاني": 1,
    "البستانيون": 1,
    "الغردينيا": 1,
    "البستنة": 1,
    "الحدائق": 1,
    "جاردنر": 1,
    "غارفيلد": 1,
    "غارفانكيل": 1,
    "الغرغرة": 1,
    "مغرغر": 1,
    "الجرغول": 1,
    "مكلل": 1,
    "ثومي": 1,
    "الكساء": 1,
    "ملبس": 1,
    "التلبيس": 1,
    "التكديس": 1,
    "يكدس": 1,
    "غارنيت": 1,
    "الموجه": 1,
    "المنذرون": 1,
    "غاريت": 1,
    "الحامية": 1,
    "الحاميات": 1,
    "خنق": 1,
    "يخنق": 1,
    "غاري": 1,
    "غارث": 1,
    "غارفي": 1,
    "لتسرب": 1,
    "جاسكوني": 1,
    "غازي": 1,
    "البليغ": 1,
    "مجروح": 1,
    "البليغة": 1,
    "مغزز": 1,
    "يغزز": 1,
    "غزز": 1,
    "الحشية": 1,
    "الحشيات": 1,
    "أضواء": 1,
    "وقود": 1,
    "الغازولين": 1,
    "اللهيث": 1,
    "اللهث": 1,
    "مطلقو": 1,
    "تسمم": 1,
    "جاستون": 1,
    "معوي": 1,
    "تذوقي": 1,
    "طهي": 1,
    "بطنيات": 1,
    "والحلزون": 1,
    "الحلزونات": 1,
    "فصيلتهم": 1,
    "المنظار": 1,
    "الطبي": 1,
    "المعدة": 1,
    "للمعدة": 1,
    "بالمنظار": 1,
    "جراحية": 1,
    "بإيصال": 1,
    "المتطفل": 1,
    "أبواب": 1,
    "جايتس": 1,
    "الجوامع": 1,
    "الشلالة": 1,
    "المحاصرة": 1,
    "جاتلينبرج": 1,
    "غاتس": 1,
    "غاتسبي": 1,
    "أرعن": 1,
    "الرعونة": 1,
    "حفلة": 1,
    "قياسه": 1,
    "مقيس": 1,
    "المعايرون": 1,
    "المقاييس": 1,
    "جوجون": 1,
    "المسئول": 1,
    "لمنطقة": 1,
    "النازية": 1,
    "غول": 1,
    "نحيف": 1,
    "بقفاز": 1,
    "النحافة": 1,
    "الغاوس": 1,
    "بالغاوس": 1,
    "الشاش": 1,
    "المطرقة": 1,
    "المطارق": 1,
    "غافن": 1,
    "حدق": 1,
    "محدق": 1,
    "الأخرق": 1,
    "غاوكيلي": 1,
    "التحديق": 1,
    "يحدق": 1,
    "اللوطي": 1,
    "جيلور": 1,
    "جيلورد": 1,
    "النظرة": 1,
    "شرفة": 1,
    "شرفات": 1,
    "الغزالة": 1,
    "الغزال": 1,
    "الناظرون": 1,
    "النظرات": 1,
    "الجريدة": 1,
    "الجغرافي": 1,
    "الجغرافية": 1,
    "الجرائد": 1,
    "جينيرال": 1,
    "إليكتريك": 1,
    "المسننات": 1,
    "مسخر": 1,
    "التسخير": 1,
    "أذرع": 1,
    "جيري": 1,
    "بريص": 1,
    "جيدانكين": 1,
    "المهوس": 1,
    "المهوسون": 1,
    "رملية": 1,
    "جافة": 1,
    "جيجينشين": 1,
    "جيهريج": 1,
    "\"جيجر\"": 1,
    "الراقصة": 1,
    "اليابانية": 1,
    "الراقصات": 1,
    "اليابانيات": 1,
    "الهلام": 1,
    "الجيلاتين": 1,
    "هيلم": 1,
    "مهيلم": 1,
    "يهيلم": 1,
    "الهيلمة": 1,
    "هلامي": 1,
    "الجبلاتينية": 1,
    "المخصي": 1,
    "المخصية": 1,
    "مثلج": 1,
    "تبلور": 1,
    "الكريم": 1,
    "المجوهرات": 1,
    "الكريمة": 1,
    "الجندرمة": 1,
    "الأنجاب": 1,
    "الجين": 1,
    "سلاسل": 1,
    "الأخصائي": 1,
    "الأنساب": 1,
    "الأخصائيون": 1,
    "الأنواع": 1,
    "الجنرال": 1,
    "اختصاصي": 1,
    "اختصاصيون": 1,
    "العموميات": 1,
    "العمومية": 1,
    "للتعميم": 1,
    "عمم": 1,
    "معمم": 1,
    "المعمم": 1,
    "المعممون": 1,
    "يعمم": 1,
    "الجنرالات": 1,
    "يولد": 1,
    "التوليد": 1,
    "جيلي": 1,
    "الأجيال": 1,
    "المكارم": 1,
    "بسخاء": 1,
    "الجينات": 1,
    "التكوين": 1,
    "الرباح": 1,
    "وراثي": 1,
    "وراثيا": 1,
    "الوراثة": 1,
    "جنيف": 1,
    "جينيفيف": 1,
    "اللطيف": 1,
    "جيني": 1,
    "التناسلية": 1,
    "اليهم": 1,
    "العبقري": 1,
    "العباقرة": 1,
    "جنوى": 1,
    "الإبادات": 1,
    "اليهود": 1,
    "الوثنيون": 1,
    "هذب": 1,
    "جينتليمانلنيس": 1,
    "الراقية": 1,
    "النبلاء": 1,
    "إركع": 1,
    "راكع": 1,
    "الركوع": 1,
    "يركع": 1,
    "أصيل": 1,
    "أرضي": 1,
    "بكيميائية": 1,
    "جيولوجية": 1,
    "عمر": 1,
    "الجيود": 1,
    "(صخور)": 1,
    "جيوديسي": 1,
    "بقياسات": 1,
    "محار": 1,
    "جيفري": 1,
    "الجغرافيون": 1,
    "الجغرافيا": 1,
    "جيولوجي": 1,
    "الجيولوجي": 1,
    "الجيولوجيون": 1,
    "الجيومورفولوجي": 1,
    "الجيومورفولوجيا": 1,
    "جيوفيزيائي": 1,
    "المتخصص": 1,
    "بالجيوفيزياء": 1,
    "جورج": 1,
    "واشنطن": 1,
    "للولايات": 1,
    "الجورجي": 1,
    "الجورجية": 1,
    "الجورجيون": 1,
    "حراري": 1,
    "جيرالد": 1,
    "جيرالدين": 1,
    "جيرارد": 1,
    "جيربير": 1,
    "الجربوع": 1,
    "جيرهارد": 1,
    "شيخوخي": 1,
    "الجرثومة": 1,
    "وثيق": 1,
    "جيرمانيا": 1,
    "الجرمانيوم": 1,
    "صار": 1,
    "ألمانيا": 1,
    "الألمان": 1,
    "جيرمانتاون": 1,
    "إنم": 1,
    "نامي": 1,
    "الإستنبات": 1,
    "إستنباتي": 1,
    "الإختصاصي": 1,
    "بالشيخوخة": 1,
    "الإختصاصيون": 1,
    "جرشوين": 1,
    "جيرترود": 1,
    "مصدري": 1,
    "الجشطالت": 1,
    "الجستابو": 1,
    "أومأ": 1,
    "مومأ": 1,
    "يأومأ": 1,
    "الإيماء": 1,
    "الإيماءات": 1,
    "إيمائي": 1,
    "البادرة": 1,
    "المبادرات": 1,
    "المهرب": 1,
    "المهارب": 1,
    "حاصل": 1,
    "جيتي": 1,
    "جيتيسبيرغ": 1,
    "جيو": 1,
    "السخان": 1,
    "السخانات": 1,
    "غانا": 1,
    "غينت": 1,
    "الخيارة": 1,
    "الغيتو": 1,
    "الشبح": 1,
    "تحرك": 1,
    "بخفة": 1,
    "شبحي": 1,
    "الشبحية": 1,
    "الأشباح": 1,
    "غولي": 1,
    "جياكومو": 1,
    "العملاقة": 1,
    "غب": 1,
    "ثرثر": 1,
    "غيبون": 1,
    "قردة": 1,
    "الغيبون": 1,
    "البروز": 1,
    "جيبس": 1,
    "الإستهزاء": 1,
    "مستهزأ": 1,
    "المتلفظ": 1,
    "طارق": 1,
    "جيبسن": 1,
    "أصابه": 1,
    "جيدينجز": 1,
    "يصاب": 1,
    "جديون": 1,
    "جيفورد": 1,
    "الهدايا": 1,
    "جيجابيت": 1,
    "الجيجابايت": 1,
    "جيجاسيكل": 1,
    "جيجاسيكليز": 1,
    "جيجاهيرتز": 1,
    "عملاق": 1,
    "جيجوات": 1,
    "الضاحك": 1,
    "بقهقهة": 1,
    "جل": 1,
    "جيلا": 1,
    "جلبرت": 1,
    "جيلبيرتسون": 1,
    "جيلكريست": 1,
    "جيلدا": 1,
    "التذهيب": 1,
    "بالذهب": 1,
    "يذهب": 1,
    "جيليد": 1,
    "تقع": 1,
    "جيلز": 1,
    "الخيشوم": 1,
    "خيشومي": 1,
    "جيلر": 1,
    "جيليسبي": 1,
    "جيليت": 1,
    "جيليجان": 1,
    "الخياشيم": 1,
    "جيلمور": 1,
    "جيمبال": 1,
    "أفقي": 1,
    "بجمبل": 1,
    "تبقي": 1,
    "جيمبل": 1,
    "المثقب": 1,
    "الأعرج": 1,
    "بعرج": 1,
    "جينا": 1,
    "الزنجبيل": 1,
    "تبل": 1,
    "بالزنجبيل": 1,
    "التتبيل": 1,
    "الفائقة": 1,
    "القطنية": 1,
    "الجنكة": 1,
    "حانة": 1,
    "الكحولية": 1,
    "جن": 1,
    "جينو": 1,
    "جينسبيرج": 1,
    "جينسبيرغ": 1,
    "الجينسنغ": 1,
    "جيوكوندا": 1,
    "جورجيو": 1,
    "جيوفاني": 1,
    "الغجر": 1,
    "الغجري": 1,
    "الزرافة": 1,
    "الزرافات": 1,
    "الزنرات": 1,
    "التحزيم": 1,
    "الصديقة": 1,
    "الصديقات": 1,
    "البنوتة": 1,
    "الفحوى": 1,
    "بوابة": 1,
    "جيوليانو": 1,
    "جيوسيب": 1,
    "أعط": 1,
    "معطى": 1,
    "الأخذ": 1,
    "المفروض": 1,
    "الحوصلات": 1,
    "جليدي": 1,
    "جمد": 1,
    "يجمد": 1,
    "أفرح": 1,
    "مفرح": 1,
    "الإفراح": 1,
    "يفرح": 1,
    "أسعد": 1,
    "الأسعد": 1,
    "الفسح": 1,
    "المصارع": 1,
    "المصارعون": 1,
    "الزنبق": 1,
    "الزنابق": 1,
    "بسرور": 1,
    "جلاديس": 1,
    "مجمل": 1,
    "فتان": 1,
    "الأبهار": 1,
    "الجذابة": 1,
    "الفتن": 1,
    "اللمحة": 1,
    "اللمحات": 1,
    "السقاوة": 1,
    "حشفة": 1,
    "الوهج": 1,
    "وهج": 1,
    "التوهجات": 1,
    "السطع": 1,
    "جلاس": 1,
    "غلاسكو": 1,
    "الزجاج": 1,
    "نافخ": 1,
    "بزجاج": 1,
    "الأقداح": 1,
    "القدح": 1,
    "شبها": 1,
    "بالزجاج": 1,
    "زجاج": 1,
    "الزجاجيات": 1,
    "الأشنان": 1,
    "الغلاسكويي": 1,
    "الزرق": 1,
    "الأخضر": 1,
    "الشاحب": 1,
    "الصقيل": 1,
    "مزجج": 1,
    "الزجاجون": 1,
    "يزجج": 1,
    "التزجيج": 1,
    "المقتنيات": 1,
    "غليسون": 1,
    "غلين": 1,
    "غليندا": 1,
    "غليندال": 1,
    "جلين": 1,
    "طلاقة": 1,
    "عفويا": 1,
    "المعسول": 1,
    "غليدين": 1,
    "الإنزلاق": 1,
    "منزلق": 1,
    "المنزلق": 1,
    "المنزلقون": 1,
    "الإنزلاقات": 1,
    "التزلق": 1,
    "وميض": 1,
    "موموض": 1,
    "يلمحون": 1,
    "جامد": 1,
    "الومض": 1,
    "غليتر": 1,
    "إشمت": 1,
    "مشموت": 1,
    "السعيد": 1,
    "الشماتة": 1,
    "يشمت": 1,
    "العولمة": 1,
    "يعولم": 1,
    "غلوبالس": 1,
    "الترحال": 1,
    "التكوير": 1,
    "الكروية": 1,
    "الكرية": 1,
    "الكريات": 1,
    "الجلوبيولين": 1,
    "كبيبي": 1,
    "الغم": 1,
    "إكتئابا": 1,
    "التشاؤم": 1,
    "غلوريا": 1,
    "غلوريانا": 1,
    "الأمجاد": 1,
    "التمجيد": 1,
    "المعظمون": 1,
    "المجد": 1,
    "المسارد": 1,
    "المسرد": 1,
    "ألمع": 1,
    "لماع": 1,
    "خطاب": 1,
    "أجوف": 1,
    "صورةلامع": 1,
    "المزمار": 1,
    "اللغات": 1,
    "جلوكيستير": 1,
    "قفاز": 1,
    "القفازات": 1,
    "بتوقد": 1,
    "سراج": 1,
    "الجلوكوز": 1,
    "إلتصق": 1,
    "المصمغ": 1,
    "المصمغون": 1,
    "الأصماغ": 1,
    "التخمة": 1,
    "جلتاميت": 1,
    "الجلوتامين": 1,
    "غروي": 1,
    "التخم": 1,
    "النهم": 1,
    "فجعان": 1,
    "نهم": 1,
    "بشراهة": 1,
    "الشراهة": 1,
    "جليسيرايد": 1,
    "الغليسرين": 1,
    "غلسر": 1,
    "مغلسر": 1,
    "الجلسرين": 1,
    "جليكاين": 1,
    "الجليكوجين": 1,
    "الجليكول": 1,
    "إيثيلين": 1,
    "المنقوش": 1,
    "موتورز": 1,
    "غرينيتش": 1,
    "مغضن": 1,
    "البعوضة": 1,
    "البعوض": 1,
    "إقضم": 1,
    "القضام": 1,
    "القضم": 1,
    "إتصال": 1,
    "النيس": 1,
    "جلسة": 1,
    "جنوم": 1,
    "خانة": 1,
    "جينوم": 1,
    "ميلي": 1,
    "الوطني": 1,
    "إذهب": 1,
    "التباطئ": 1,
    "غوا": 1,
    "المهماز": 1,
    "المهمازات": 1,
    "المرمى": 1,
    "أهداف": 1,
    "عوارض": 1,
    "العنزة": 1,
    "السكسوكة": 1,
    "انطلقخلف": 1,
    "انطلقتقديم": 1,
    "انطلقمنزل": 1,
    "انطلقفهرس": 1,
    "الذهب": 1,
    "الجيدة": 1,
    "بأس": 1,
    "غوودريتش": 1,
    "النية": 1,
    "الحسنة": 1,
    "غوودوين": 1,
    "جود": 1,
    "طري": 1,
    "الهفوة": 1,
    "الهفوات": 1,
    "والتصاق": 1,
    "الأوزة": 1,
    "الحزب": 1,
    "الجمهوري": 1,
    "الغوفر": 1,
    "جوردن": 1,
    "غور": 1,
    "غورين": 1,
    "المسفوكة": 1,
    "الزهو": 1,
    "جورجون": 1,
    "خرافية": 1,
    "جورهام": 1,
    "الغوريلا": 1,
    "الغوريلات": 1,
    "غوركي": 1,
    "غورتون": 1,
    "الباز": 1,
    "إلهي": 1,
    "قاريء": 1,
    "الرقيق": 1,
    "بالنميمة": 1,
    "النمام": 1,
    "قشبي": 1,
    "مسكتك": 1,
    "القوطي": 1,
    "الجرماني": 1,
    "نيويورك": 1,
    "قوطي": 1,
    "جرماني": 1,
    "البرمجيات": 1,
    "يجب": 1,
    "جوتفريد": 1,
    "جوتشر": 1,
    "غوودا": 1,
    "الحفارة": 1,
    "الحفارات": 1,
    "اليخني": 1,
    "غوولد": 1,
    "القرعة": 1,
    "النهمون": 1,
    "النقرس": 1,
    "نقرسي": 1,
    "المعلمة": 1,
    "المعلمات": 1,
    "الحكومة": 1,
    "حكومي": 1,
    "الحكومات": 1,
    "الحاكم": 1,
    "الأردية": 1,
    "للمحاكاة": 1,
    "المسكة": 1,
    "الأيادي": 1,
    "المسكات": 1,
    "غرايس": 1,
    "جراسيليسنيس": 1,
    "جرايسي": 1,
    "السوادية": 1,
    "التدريج": 1,
    "التدريجات": 1,
    "تمهيد": 1,
    "تصنيف": 1,
    "متدرج": 1,
    "التخرج": 1,
    "جرادي": 1,
    "جراف": 1,
    "التطعيم": 1,
    "جرافتون": 1,
    "جراهام": 1,
    "جراهامز": 1,
    "المقدسة": 1,
    "الطحان": 1,
    "النحوي": 1,
    "النحويون": 1,
    "قواعدي": 1,
    "نحوي": 1,
    "نحويا": 1,
    "النحوية": 1,
    "الحاكي": 1,
    "السايلوات": 1,
    "السايلو": 1,
    "الحفيد": 1,
    "جرانداد": 1,
    "الحفيدة": 1,
    "الحفيدات": 1,
    "أعظم": 1,
    "الحذلقة": 1,
    "متحذلق": 1,
    "فخم": 1,
    "حفيد": 1,
    "الجدة": 1,
    "بحنان": 1,
    "حنان": 1,
    "الجدات": 1,
    "الأخت": 1,
    "بنت": 1,
    "المغطى": 1,
    "صواني": 1,
    "الجرانولا": 1,
    "جرانت": 1,
    "للمنحة": 1,
    "ممنوح": 1,
    "الممنوح": 1,
    "حبيبي": 1,
    "الحبوبية": 1,
    "تكسيري": 1,
    "الحبيبة": 1,
    "الحبيبات": 1,
    "كريات": 1,
    "جرانفيل": 1,
    "تخطيطي": 1,
    "جرافيس": 1,
    "الجرافايت": 1,
    "الطارح": 1,
    "الكلابات": 1,
    "الممسك": 1,
    "معشب": 1,
    "جراسيرس": 1,
    "الجندب": 1,
    "الجنادب": 1,
    "عشبا": 1,
    "التعشيب": 1,
    "المعشبة": 1,
    "معشوشب": 1,
    "غراتا": 1,
    "مفرم": 1,
    "ممتن": 1,
    "بإمتنان": 1,
    "الإمتنان": 1,
    "المبشارة": 1,
    "يرضي": 1,
    "مرضى": 1,
    "هنأ": 1,
    "حفار": 1,
    "محصب": 1,
    "التحصيب": 1,
    "جرافيرس": 1,
    "شواهد": 1,
    "بالقياس": 1,
    "الوزن": 1,
    "إنجذب": 1,
    "ينجذب": 1,
    "الإنجذاب": 1,
    "جذبي": 1,
    "الجاذبيات": 1,
    "أشيب": 1,
    "رماديا": 1,
    "المسنون": 1,
    "جرايد": 1,
    "رمادية": 1,
    "بالرمادي": 1,
    "الرمادية": 1,
    "جرايز": 1,
    "متدرجألفا": 1,
    "جرايسون": 1,
    "جرايواك": 1,
    "مرعي": 1,
    "الداهن": 1,
    "الداهنون": 1,
    "التشحم": 1,
    "عظيم": 1,
    "السميك": 1,
    "السميكة": 1,
    "العظماء": 1,
    "إغريقي": 1,
    "اليوناني": 1,
    "اليونانيون": 1,
    "جرينبيرج": 1,
    "جرينبلات": 1,
    "جرين": 1,
    "يخضر": 1,
    "خضرة": 1,
    "الخضراوات": 1,
    "جرينفيلد": 1,
    "البقال": 1,
    "المبتدىء": 1,
    "التخضير": 1,
    "مخضوضر": 1,
    "جرينلاند": 1,
    "باخضرار": 1,
    "الخضر": 1,
    "جرينسبورو": 1,
    "جرينسفيل": 1,
    "جرينفيل": 1,
    "تعالج": 1,
    "بحرارة": 1,
    "جرينوود": 1,
    "جرير": 1,
    "محيا": 1,
    "التحية": 1,
    "التحيات": 1,
    "جريج": 1,
    "إجتماعيا": 1,
    "الإجتماعية": 1,
    "جريجوري": 1,
    "غرينادا": 1,
    "الوحش": 1,
    "جرينير": 1,
    "جرينوبل": 1,
    "جريشام": 1,
    "جريتا": 1,
    "جريتشين": 1,
    "جريتيل": 1,
    "نمى": 1,
    "السلوقي": 1,
    "السلوقية": 1,
    "شائب": 1,
    "جرايلاج": 1,
    "أوزة": 1,
    "المشواة": 1,
    "الشكاوي": 1,
    "إحزن": 1,
    "حزين": 1,
    "جريفين": 1,
    "جريفيث": 1,
    "مشوي": 1,
    "الشواي": 1,
    "متجهم": 1,
    "المكشر": 1,
    "التكشير": 1,
    "جريمالدي": 1,
    "القمر": 1,
    "وساخة": 1,
    "جرمم": 1,
    "إزعاج": 1,
    "التكشيرة": 1,
    "جرينديج": 1,
    "طاحن": 1,
    "يطحن": 1,
    "إبتسم": 1,
    "ابتسامة": 1,
    "إبتسام": 1,
    "باستخفاف": 1,
    "القابضون": 1,
    "جريس": 1,
    "روعة": 1,
    "الغضروفية": 1,
    "الطاحونة": 1,
    "جريسوولد": 1,
    "الحصباء": 1,
    "حزما": 1,
    "جرز": 1,
    "قرد": 1,
    "إفريقي": 1,
    "إقلق": 1,
    "أشهب": 1,
    "يقلق": 1,
    "تعبان": 1,
    "الآهة": 1,
    "مأنون": 1,
    "الأنان": 1,
    "الأنين": 1,
    "الآهات": 1,
    "جروات": 1,
    "الجريش": 1,
    "البقالة": 1,
    "البقالون": 1,
    "ترنحا": 1,
    "الترنح": 1,
    "مترنح": 1,
    "الأربية": 1,
    "الأربيات": 1,
    "جروك": 1,
    "إستوعب": 1,
    "جروميت": 1,
    "التهيأ": 1,
    "رفقاء": 1,
    "جروت": 1,
    "مخدد": 1,
    "أروع": 1,
    "الحز": 1,
    "تلمس": 1,
    "متلمس": 1,
    "المتحسس": 1,
    "يتلمس": 1,
    "التلمس": 1,
    "بتلمس": 1,
    "جروسبيك": 1,
    "الإجمالي": 1,
    "رابح": 1,
    "الإجمالية": 1,
    "الربح": 1,
    "جروزسمان": 1,
    "جروسفينور": 1,
    "جروتون": 1,
    "متذمر": 1,
    "بتذمر": 1,
    "الشكاية": 1,
    "المنخفضة": 1,
    "جرذ": 1,
    "جرذان": 1,
    "القريض": 1,
    "جروبير": 1,
    "القنبرة": 1,
    "تذمر": 1,
    "القنبرات": 1,
    "التذمر": 1,
    "الجص": 1,
    "حشى": 1,
    "البستان": 1,
    "خضع": 1,
    "الخاضع": 1,
    "باذلال": 1,
    "جروفير": 1,
    "البساتين": 1,
    "الزارع": 1,
    "الزارعون": 1,
    "الهدير": 1,
    "مهدور": 1,
    "الهادر": 1,
    "زمجرة": 1,
    "الزمجرة": 1,
    "الهدر": 1,
    "زمجري": 1,
    "معزوق": 1,
    "العزق": 1,
    "الضاغن": 1,
    "الثريد": 1,
    "الشنيع": 1,
    "الفضاضة": 1,
    "بشكوى": 1,
    "جرومان": 1,
    "طبعا": 1,
    "شكس": 1,
    "الجرونج": 1,
    "النخير": 1,
    "شاخر": 1,
    "الشاخر": 1,
    "الشخير": 1,
    "الهمهمات": 1,
    "العنقاء": 1,
    "الخدمات": 1,
    "جوام": 1,
    "جوادالوب": 1,
    "جوانيداين": 1,
    "جواناين": 1,
    "ذرق": 1,
    "الضمانات": 1,
    "باحتراس": 1,
    "الجنود": 1,
    "جوارديا": 1,
    "أولياء": 1,
    "الأمور": 1,
    "الوصاية": 1,
    "الحرس": 1,
    "غواتيمالا": 1,
    "الغواتيمالي": 1,
    "حاكمي": 1,
    "القوبيون": 1,
    "جيلف": 1,
    "جونتر": 1,
    "الفدائي": 1,
    "جيرنسي": 1,
    "القادمة": 1,
    "الفدائيون": 1,
    "محزور": 1,
    "الحازر": 1,
    "الحزر": 1,
    "استضاف": 1,
    "الاستضافة": 1,
    "القهقهة": 1,
    "مقهقه": 1,
    "القهقهات": 1,
    "جوجينهيم": 1,
    "جوهليمان": 1,
    "واجهة": 1,
    "الرسومية": 1,
    "جويانا": 1,
    "للتوجيه": 1,
    "التعليمات": 1,
    "جويدر": 1,
    "الأدلاء": 1,
    "دليلواحدللصفحة": 1,
    "دليلاثنانللصفحة": 1,
    "لضبط": 1,
    "النقابة": 1,
    "الغلدر": 1,
    "مقر": 1,
    "مقرات": 1,
    "النقابات": 1,
    "جيلفورد": 1,
    "الغلموت": 1,
    "المقصلة": 1,
    "ذنبا": 1,
    "مذنب": 1,
    "بريئ": 1,
    "الذنوب": 1,
    "غينيا": 1,
    "الغيني": 1,
    "الغينية": 1,
    "الجنيهات": 1,
    "الإنكليزية": 1,
    "القيثارة": 1,
    "القيثارات": 1,
    "الكوجوراتية": 1,
    "النورس": 1,
    "جلاه": 1,
    "الممزوجة": 1,
    "بالأفريقية": 1,
    "المريئ": 1,
    "النوارس": 1,
    "التخديد": 1,
    "المبتلع": 1,
    "البامية": 1,
    "مصمغ": 1,
    "جومينج": 1,
    "النباهة": 1,
    "اللثث": 1,
    "المسلحة": 1,
    "جاندرسون": 1,
    "الوارية": 1,
    "المسلحون": 1,
    "جونر": 1,
    "المدفعيون": 1,
    "الجوال": 1,
    "الأجولة": 1,
    "البارود": 1,
    "بارود": 1,
    "مهرب": 1,
    "مهربو": 1,
    "الطلقات": 1,
    "جانسلنج": 1,
    "جانثر": 1,
    "جورخا": 1,
    "جورموخي": 1,
    "المعلمون": 1,
    "جوس": 1,
    "البئر": 1,
    "المتدفق": 1,
    "التدفقات": 1,
    "تدفقا": 1,
    "بتدفق": 1,
    "التقوية": 1,
    "جستافسون": 1,
    "ذوقي": 1,
    "غوستاف": 1,
    "غوستافوس": 1,
    "العصف": 1,
    "جوتينبيرج": 1,
    "غوثري": 1,
    "جوتسير": 1,
    "طمعا": 1,
    "مميزب": 1,
    "البالوعات": 1,
    "الأجش": 1,
    "الجشة": 1,
    "غويانا": 1,
    "جير": 1,
    "التوجية": 1,
    "إكرع": 1,
    "مكروع": 1,
    "بنهم": 1,
    "يكرع": 1,
    "الكرع": 1,
    "جوين": 1,
    "الجمنازيوم": 1,
    "الجمباز": 1,
    "جمبازي": 1,
    "البذور": 1,
    "نسائي": 1,
    "النسائي": 1,
    "بصحة": 1,
    "دكتور": 1,
    "إحتال": 1,
    "الجبس": 1,
    "غجري": 1,
    "الدورات": 1,
    "السنقر": 1,
    "الجيروسكوبية": 1,
    "طائرة": 1,
    "عمودية": 1,
    "طيارات": 1,
    "الجيروسكوب": 1,
    "الجيروسكوبات": 1,
    "توازني": 1,
    "الماريوانا": 1,
    "مجففة": 1,
    "وتدخن": 1,
    "الجرمانيون": 1,
    "القوطيون": 1,
    "العويل": 1,
    "النواح": 1,
    "ناح": 1,
    "نحب": 1,
    "لمهمة": 1,
    "بحث": 1,
    "للعام": 1,
    "2003.": 1,
    "يبحث": 1,
    "ومواقع": 1,
    "الأخبار": 1,
    "الإنبهار": 1,
    "ممتازة": 1,
    "الروحية": 1,
    "ها": 1,
    "هاج": 1,
    "هاس": 1,
    "هاب": 1,
    "صك": 1,
    "هابن": 1,
    "الخياطة": 1,
    "دكان": 1,
    "هابيرمان": 1,
    "حبيب": 1,
    "زي": 1,
    "المناسبات": 1,
    "صلاحية": 1,
    "للسكن": 1,
    "صالحا": 1,
    "إعتياديا": 1,
    "السكنية": 1,
    "متعود": 1,
    "يتعود": 1,
    "هاسيندا": 1,
    "العزب": 1,
    "المأجور": 1,
    "الثمر": 1,
    "الميس": 1,
    "هاكيت": 1,
    "الممشقة": 1,
    "الماشق": 1,
    "الممشقات": 1,
    "هاكماتاك": 1,
    "للإستعمال": 1,
    "المأجورون": 1,
    "المناشير": 1,
    "الهابط": 1,
    "عنده": 1,
    "هادامارد": 1,
    "حداد": 1,
    "الحدوق": 1,
    "هادلي": 1,
    "أدريان": 1,
    "هادرون": 1,
    "يتفاعل": 1,
    "حافظ": 1,
    "الهفينوم": 1,
    "العفريتة": 1,
    "هاجن": 1,
    "هيدجر": 1,
    "جموحا": 1,
    "الإنهاك": 1,
    "المساومات": 1,
    "العفاريت": 1,
    "هاجستروم": 1,
    "لاهاي": 1,
    "هان": 1,
    "حيفا": 1,
    "هايكو": 1,
    "رحب": 1,
    "هيلير": 1,
    "الحالوب": 1,
    "هينيس": 1,
    "شعرة": 1,
    "حلاقة": 1,
    "الحلاقة": 1,
    "تصفيفة": 1,
    "تصفيف": 1,
    "مشعر": 1,
    "شعرا": 1,
    "كثرة": 1,
    "منبت": 1,
    "الشعرات": 1,
    "نابض": 1,
    "هايتي": 1,
    "الهايتي": 1,
    "هال": 1,
    "المطرد": 1,
    "القاوند": 1,
    "العافية": 1,
    "معافى": 1,
    "هايلي": 1,
    "النصفي": 1,
    "الوسط": 1,
    "فاتر": 1,
    "بتراخي": 1,
    "الإكتمال": 1,
    "الطباعة": 1,
    "النصفية": 1,
    "القفندر": 1,
    "هالايد": 1,
    "هاليفاكس": 1,
    "هالايت": 1,
    "الصالة": 1,
    "سبحان": 1,
    "التسبيح": 1,
    "هالر": 1,
    "فسيولوجي": 1,
    "سويسري": 1,
    "هالي": 1,
    "هالينان": 1,
    "الشماعة": 1,
    "تهلوس": 1,
    "متهلوس": 1,
    "يتهلوس": 1,
    "التهلوس": 1,
    "الهلوسة": 1,
    "مهلوس": 1,
    "للهلوسة": 1,
    "هالما": 1,
    "هالوكاربون": 1,
    "الهلوجين": 1,
    "هالبيرن": 1,
    "هولسي": 1,
    "هالستيد": 1,
    "الرسن": 1,
    "الأرسنة": 1,
    "منصف": 1,
    "الأنصاف": 1,
    "هالفيرسون": 1,
    "هامبورغ": 1,
    "الهامبرغر": 1,
    "الهامبرغرات": 1,
    "هامي": 1,
    "هاملتن": 1,
    "هاميلتونايان": 1,
    "هملت": 1,
    "القرى": 1,
    "هاملين": 1,
    "مطروق": 1,
    "الطارق": 1,
    "بالمطرقة": 1,
    "هاميرليس": 1,
    "هاميت": 1,
    "الأرجوحة": 1,
    "الأراجيح": 1,
    "هاموند": 1,
    "السلال": 1,
    "هامبشاير": 1,
    "هامبتون": 1,
    "الهامستر": 1,
    "وتر": 1,
    "الركبة": 1,
    "أوتار": 1,
    "الهان": 1,
    "هانكوك": 1,
    "اليدوية": 1,
    "المصافحة": 1,
    "نشرة": 1,
    "نشرات": 1,
    "فرملة": 1,
    "سكة": 1,
    "حديد": 1,
    "تعمل": 1,
    "الصفد": 1,
    "الأصفاد": 1,
    "هاندل": 1,
    "يدوي": 1,
    "المسدسات": 1,
    "السباق": 1,
    "الإعاقات": 1,
    "بحرفة": 1,
    "فائدة": 1,
    "اليدوي": 1,
    "المناديل": 1,
    "المقبض": 1,
    "المقود": 1,
    "المقاود": 1,
    "المقابض": 1,
    "هاندلن": 1,
    "باليد": 1,
    "الأيدي": 1,
    "الودود": 1,
    "الوقفة": 1,
    "اليدين": 1,
    "الوقفات": 1,
    "الكتابات": 1,
    "الماهر": 1,
    "هاني": 1,
    "هانفورد": 1,
    "للتعليق": 1,
    "ذليل": 1,
    "مشنوق": 1,
    "الشنق": 1,
    "ظفر": 1,
    "صداع": 1,
    "أغلاق": 1,
    "هانكيل": 1,
    "مشتاق": 1,
    "المتلهف": 1,
    "المتشوق": 1,
    "يشتاق": 1,
    "هانكس": 1,
    "الجنسي": 1,
    "المشبوهة": 1,
    "هانلي": 1,
    "هانلون": 1,
    "حنا": 1,
    "هانا": 1,
    "هانيبال": 1,
    "هانوي": 1,
    "هانوفر": 1,
    "بالعائلة": 1,
    "المالكة": 1,
    "هانز": 1,
    "هانسيل": 1,
    "هانسين": 1,
    "هانسن": 1,
    "الحانوكة": 1,
    "هابجود": 1,
    "عشوائي": 1,
    "منحوس": 1,
    "بسوء": 1,
    "واحدة": 1,
    "الكروموسومات": 1,
    "بإمتلاك": 1,
    "إثنين": 1,
    "بالمصادفة": 1,
    "العرضي": 1,
    "هابسبيرغ": 1,
    "المتحرش": 1,
    "حربين": 1,
    "الرائد": 1,
    "الرواد": 1,
    "المرافيء": 1,
    "مأوي": 1,
    "الموانئ": 1,
    "هاركورت": 1,
    "الورقية": 1,
    "والمعدنية": 1,
    "مليست": 1,
    "الشيكات": 1,
    "المستندات": 1,
    "هاردبيك": 1,
    "مسلوق": 1,
    "المطبوعة": 1,
    "فاضح": 1,
    "الصلبة": 1,
    "هاردينير": 1,
    "هاردينيرس": 1,
    "أصلب": 1,
    "الخوذة": 1,
    "جرأة": 1,
    "هاردن": 1,
    "هاردينج": 1,
    "والعشرون": 1,
    "المحتمل": 1,
    "قاحلة": 1,
    "المشقة": 1,
    "المشاق": 1,
    "أسلاك": 1,
    "الأخشاب": 1,
    "شغول": 1,
    "المشقوقة": 1,
    "الحريم": 1,
    "البرية": 1,
    "أصغ": 1,
    "مصغى": 1,
    "أنصت": 1,
    "الإصغاء": 1,
    "يصغي": 1,
    "هارلان": 1,
    "هارليم": 1,
    "هارلي": 1,
    "العاهرة": 1,
    "البغاء": 1,
    "العاهرات": 1,
    "مآذي": 1,
    "الإيذاء": 1,
    "المسالمة": 1,
    "هارمون": 1,
    "التوافقية": 1,
    "الهارمونيكا": 1,
    "بتناسق": 1,
    "التوافقيات": 1,
    "الإنسجامات": 1,
    "نسق": 1,
    "ينسق": 1,
    "هارولد": 1,
    "قيثار": 1,
    "الطماعون": 1,
    "القيثار": 1,
    "للحوت": 1,
    "برمح": 1,
    "الحيتان": 1,
    "إصطياد": 1,
    "هاربسيكورد": 1,
    "الطماع": 1,
    "مغزو": 1,
    "هارير": 1,
    "يغزو": 1,
    "هاريت": 1,
    "هاريمان": 1,
    "هارينجتون": 1,
    "هاريس": 1,
    "هاريسبيرغ": 1,
    "هاريسن": 1,
    "هاريسونبيرغ": 1,
    "الأمشاط": 1,
    "يتنحنح": 1,
    "تنحنح": 1,
    "التنحنح": 1,
    "هيري": 1,
    "يصعب": 1,
    "التصعيب": 1,
    "هارت": 1,
    "هارتفورد": 1,
    "هارتلي": 1,
    "هارتمان": 1,
    "هارفارد": 1,
    "الحصاد": 1,
    "محصود": 1,
    "الحاصد": 1,
    "الحاصدون": 1,
    "يحصد": 1,
    "هارفي": 1,
    "المهروس": 1,
    "مثروم": 1,
    "هاشير": 1,
    "الثرم": 1,
    "حزقيل": 1,
    "هاسكينز": 1,
    "الرباطات": 1,
    "هاسلر": 1,
    "يمتلك": 1,
    "الإستعجال": 1,
    "الإستعجالات": 1,
    "مستعجل": 1,
    "هاستينجز": 1,
    "البرنيطة": 1,
    "أربطة": 1,
    "الفتحة": 1,
    "مفقس": 1,
    "الفقاس": 1,
    "المفاقس": 1,
    "المفقس": 1,
    "الفتحات": 1,
    "التفقيس": 1,
    "الرقن": 1,
    "هاتفيلد": 1,
    "هاث": 1,
    "هاثاواي": 1,
    "قبعة": 1,
    "هاتيد": 1,
    "هاتيراس": 1,
    "هاتي": 1,
    "هاتيسبيرغ": 1,
    "هاوجين": 1,
    "متغطرس": 1,
    "بغطرسة": 1,
    "الغطرسة": 1,
    "العتلة": 1,
    "متعهدو": 1,
    "العتلات": 1,
    "الورك": 1,
    "بأفخاذ": 1,
    "المزار": 1,
    "الزائر": 1,
    "الزوار": 1,
    "حزنا": 1,
    "المزارات": 1,
    "هوبت": 1,
    "هوسدورف": 1,
    "هوسير": 1,
    "الإستكبار": 1,
    "هافانا": 1,
    "الملجأ": 1,
    "هافيرينج": 1,
    "الجراب": 1,
    "الموسرون": 1,
    "الإمتلاك": 1,
    "الزعرورة": 1,
    "هاواي": 1,
    "إصطاد": 1,
    "الباعة": 1,
    "المتجولون": 1,
    "هوكينز": 1,
    "هاولي": 1,
    "الزعرور": 1,
    "هاوثورن": 1,
    "حمى": 1,
    "هايكوك": 1,
    "أكوام": 1,
    "هايدن": 1,
    "هاير": 1,
    "هايز": 1,
    "الحشائش": 1,
    "مخزن": 1,
    "التبن": 1,
    "مخازن": 1,
    "هاينيس": 1,
    "كومة": 1,
    "هايوارد": 1,
    "هايوود": 1,
    "مخاطر": 1,
    "السديم": 1,
    "هيزل": 1,
    "هازير": 1,
    "إكفهرارا": 1,
    "مكفهر": 1,
    "التشوش": 1,
    "هو": 1,
    "الصداع": 1,
    "نقانق": 1,
    "برأس": 1,
    "الرأسي": 1,
    "رأستذييل": 1,
    "الرأسية": 1,
    "إندفاعا": 1,
    "بلارأس": 1,
    "العلوية": 1,
    "البارز": 1,
    "المتصدر": 1,
    "عناوين": 1,
    "البارزة": 1,
    "المديرات": 1,
    "أغطية": 1,
    "إتخذ": 1,
    "مقرا": 1,
    "المخدات": 1,
    "سياف": 1,
    "السيافون": 1,
    "الرياح": 1,
    "العكسية": 1,
    "المعجم": 1,
    "أشف": 1,
    "للإشفاء": 1,
    "مشفى": 1,
    "المعالجون": 1,
    "هيلي": 1,
    "يشفي": 1,
    "صحي": 1,
    "الصحية": 1,
    "أصح": 1,
    "الكومة": 1,
    "إسمع": 1,
    "سمع": 1,
    "السامع": 1,
    "السامعون": 1,
    "الجلسة": 1,
    "الجلسات": 1,
    "يسمع": 1,
    "هيرست": 1,
    "نبض": 1,
    "بقلب": 1,
    "المعوية": 1,
    "طيب": 1,
    "الأخلاص": 1,
    "قلبية": 1,
    "رفاق": 1,
    "القلوب": 1,
    "الأحبة": 1,
    "للتسخين": 1,
    "ساخن": 1,
    "المدافئ": 1,
    "المرج": 1,
    "وثني": 1,
    "الوثنية": 1,
    "وثنيا": 1,
    "هذر": 1,
    "هيثمان": 1,
    "التدفئة": 1,
    "السخونة": 1,
    "الحمالون": 1,
    "الثقال": 1,
    "الثقيلة": 1,
    "هيب": 1,
    "الربيع": 1,
    "والشباب": 1,
    "منفصم": 1,
    "عبراني": 1,
    "عبري": 1,
    "هيكات": 1,
    "هيك": 1,
    "المقاطعون": 1,
    "هيكمان": 1,
    "الهكتار": 1,
    "الهكتارات": 1,
    "هيكتر": 1,
    "مرهب": 1,
    "يرهب": 1,
    "هيكبا": 1,
    "هيدا": 1,
    "القنفذ": 1,
    "القنافذ": 1,
    "بانخفاض": 1,
    "هيجير": 1,
    "المتعي": 1,
    "تلذذي": 1,
    "تمتعي": 1,
    "المتعيون": 1,
    "الغفلة": 1,
    "منعل": 1,
    "التنعيل": 1,
    "كعوب": 1,
    "هيغل": 1,
    "هيغلي": 1,
    "الزعامات": 1,
    "هييديجر": 1,
    "هايديلبيرج": 1,
    "هيدي": 1,
    "هيغ": 1,
    "هين": 1,
    "هيينلين": 1,
    "هينريتش": 1,
    "هينز": 1,
    "هيينز": 1,
    "الوريث": 1,
    "الوريثة": 1,
    "الوريثات": 1,
    "الورثة": 1,
    "هيسينبيرج": 1,
    "هيايزر": 1,
    "هيستير": 1,
    "هيلين": 1,
    "هيلينا": 1,
    "هيلجا": 1,
    "حلزوني": 1,
    "اللوالب": 1,
    "الهليكون": 1,
    "بمركز": 1,
    "بمركزية": 1,
    "الملونة": 1,
    "الهليوغراف": 1,
    "بالهليوغراف": 1,
    "هيليوجرافي": 1,
    "الفوتوغرافي": 1,
    "هيليوبوليس": 1,
    "الهليوتروب": 1,
    "الهيليوتيب": 1,
    "الهليوم": 1,
    "اللولب": 1,
    "سمندر": 1,
    "هيلجرامايت": 1,
    "جهنمي": 1,
    "هلو": 1,
    "مخوذ": 1,
    "الخوذ": 1,
    "هيلمولز": 1,
    "هيلموت": 1,
    "مساعدةعن": 1,
    "سوعد": 1,
    "بعجز": 1,
    "هيلسنكي": 1,
    "النصاب": 1,
    "هلفتيكا": 1,
    "الهيماتيت": 1,
    "هيمنغواي": 1,
    "الشوكران": 1,
    "الهيموغلوبين": 1,
    "الهيموفيليا": 1,
    "بالهيموفيليا": 1,
    "النزف": 1,
    "نازف": 1,
    "نزفي": 1,
    "الباسور": 1,
    "بالبواسير": 1,
    "البواسير": 1,
    "هيموسيديرين": 1,
    "المرقأة": 1,
    "القنب": 1,
    "تيل": 1,
    "هيمبستيد": 1,
    "الكفافة": 1,
    "البنج": 1,
    "الآن": 1,
    "السياسي": 1,
    "السياسيون": 1,
    "هيندرسن": 1,
    "هيندريك": 1,
    "هيندريكس": 1,
    "هيندريكسون": 1,
    "هينيكين": 1,
    "هينلي": 1,
    "هينيسي": 1,
    "هينينج": 1,
    "هنري": 1,
    "هنرييتا": 1,
    "كبدي": 1,
    "هيباتيكا": 1,
    "الكبد": 1,
    "هيبورن": 1,
    "فنان": 1,
    "الجاز": 1,
    "فنانو": 1,
    "المسبع": 1,
    "الهيبتان": 1,
    "هي": 1,
    "حيرا": 1,
    "هيراكليتس": 1,
    "التبشير": 1,
    "أعلام": 1,
    "العشبة": 1,
    "عشبي": 1,
    "المختص": 1,
    "بالأعشاب": 1,
    "هيربيرت": 1,
    "للأعشاب": 1,
    "شاق": 1,
    "هرقل": 1,
    "هنا": 1,
    "قريبا": 1,
    "بموجب": 1,
    "هذا": 1,
    "هيريفورد": 1,
    "سابقة": 1,
    "هذه": 1,
    "أدناه": 1,
    "هيريس": 1,
    "الزنديق": 1,
    "ضلالي": 1,
    "الزنادقة": 1,
    "يلي": 1,
    "بهذا": 1,
    "طيا": 1,
    "توريثه": 1,
    "توريثية": 1,
    "التراث": 1,
    "الوارث": 1,
    "هيركيمير": 1,
    "هيرمان": 1,
    "الخنثى": 1,
    "خنثوي": 1,
    "يتعامل": 1,
    "مبادئ": 1,
    "هيرميس": 1,
    "سحري": 1,
    "صومعة": 1,
    "هيرميت": 1,
    "الناسكون": 1,
    "هيرموسا": 1,
    "هيرنانديز": 1,
    "الفتق": 1,
    "بالفتاق": 1,
    "الفتوق": 1,
    "هيرودوتس": 1,
    "التاريخ": 1,
    "بطولي": 1,
    "البطولية": 1,
    "الهيروين": 1,
    "البطلة": 1,
    "البطلات": 1,
    "الحلأ": 1,
    "الزاحفات": 1,
    "هير": 1,
    "الرنغة": 1,
    "زخرف": 1,
    "هيرينجتون": 1,
    "لها": 1,
    "هيرشيل": 1,
    "نفسه": 1,
    "هيرسي": 1,
    "هيرشي": 1,
    "هيرتز": 1,
    "هيرزيس": 1,
    "هيرتزوج": 1,
    "كوكب": 1,
    "هيس": 1,
    "هيستر": 1,
    "ذرة": 1,
    "متحدة": 1,
    "حلقة": 1,
    "بدعي": 1,
    "الزندقة": 1,
    "باقتران": 1,
    "ترددين": 1,
    "الأمشاج": 1,
    "بتباين": 1,
    "المختلف": 1,
    "بالزيجوتات": 1,
    "المتغايرة": 1,
    "هيتمان": 1,
    "هيتي": 1,
    "هيوبلين": 1,
    "هيوتشليريستش": 1,
    "إرشادي": 1,
    "تجريبيا": 1,
    "الاستدلال": 1,
    "هيوسين": 1,
    "هيوسير": 1,
    "هويت": 1,
    "هيويت": 1,
    "هيوليت": 1,
    "الهيكس": 1,
    "سادس": 1,
    "هيكساكلورايد": 1,
    "ستعشري": 1,
    "ستة": 1,
    "السداسي": 1,
    "سداسي": 1,
    "السداسيات": 1,
    "المكعب": 1,
    "التفاعيل": 1,
    "الهكسين": 1,
    "مشعوذ": 1,
    "يشعوذ": 1,
    "العنفوان": 1,
    "هيوود": 1,
    "هجسيبم": 1,
    "الصغرى": 1,
    "هيات": 1,
    "هياواثا": 1,
    "هيباتشي": 1,
    "هيبارد": 1,
    "إسبت": 1,
    "مسبوت": 1,
    "يسبت": 1,
    "السبت": 1,
    "السبات": 1,
    "الشاعري": 1,
    "لجزيرة": 1,
    "أيرلندا": 1,
    "الكركديه": 1,
    "الفواق": 1,
    "هيكوك": 1,
    "الهيكري": 1,
    "هيدالجو": 1,
    "قبيح": 1,
    "هرمي": 1,
    "مرتبي": 1,
    "التدرجات": 1,
    "هيروغليفي": 1,
    "الهيروغليفية": 1,
    "الطلاسم": 1,
    "مدعي": 1,
    "هيجينس": 1,
    "العالي": 1,
    "التذبذب": 1,
    "الرتبة": 1,
    "العالية": 1,
    "التقنية": 1,
    "المتطورة": 1,
    "رفيعة": 1,
    "راقي": 1,
    "هايفيلد": 1,
    "المهم": 1,
    "مبرز": 1,
    "هايت": 1,
    "انسحب": 1,
    "الإختطاف": 1,
    "مختطف": 1,
    "الجذل": 1,
    "هيلبيرت": 1,
    "القديس": 1,
    "هيلاري": 1,
    "هيلكريست": 1,
    "بالتلال": 1,
    "هيليل": 1,
    "مرتفعات": 1,
    "هيلينج": 1,
    "هيلمان": 1,
    "الرابية": 1,
    "الروابي": 1,
    "هيلزبورو": 1,
    "هيلزدال": 1,
    "سفح": 1,
    "سفوح": 1,
    "قمم": 1,
    "أقصىأدنى": 1,
    "وصلأقصىأدنى": 1,
    "فتح": 1,
    "هيلتون": 1,
    "النقير": 1,
    "هملايا": 1,
    "الهيمالياس": 1,
    "هيملير": 1,
    "الوعلة": 1,
    "الوعلات": 1,
    "هندوسي": 1,
    "الهندوسية": 1,
    "الهنودس": 1,
    "المفصلة": 1,
    "متمحور": 1,
    "هينجر": 1,
    "المفصلات": 1,
    "التمحور": 1,
    "هينكل": 1,
    "هينمان": 1,
    "هينسدال": 1,
    "اللماح": 1,
    "الهيب": 1,
    "هوب": 1,
    "الهبي": 1,
    "مجموعات": 1,
    "الهبيز": 1,
    "فرس": 1,
    "النهر": 1,
    "هيبوقراط": 1,
    "أبقراط": 1,
    "أفراس": 1,
    "الوروك": 1,
    "محبو": 1,
    "حيرم": 1,
    "بالتقسيط": 1,
    "مستأجر": 1,
    "المستأجرون": 1,
    "المؤجر": 1,
    "المؤجرون": 1,
    "الإستئجار": 1,
    "التوظيفات": 1,
    "هيروزهي": 1,
    "هيروشيما": 1,
    "هيرش": 1,
    "الكثيف": 1,
    "هسباني": 1,
    "الهسبان": 1,
    "الفحيح": 1,
    "مهسهس": 1,
    "الفحاح": 1,
    "يهسهس": 1,
    "الهسهسة": 1,
    "أصمت": 1,
    "الهستامين": 1,
    "الحامض": 1,
    "الأميني": 1,
    "بكيمياء": 1,
    "وأنسجة": 1,
    "الإحصائي": 1,
    "التاريخية": 1,
    "التأريخية": 1,
    "مسرحيا": 1,
    "وهروب": 1,
    "هيتاتشي": 1,
    "هيتشكوك": 1,
    "الرابط": 1,
    "بالتماس": 1,
    "المجانية": 1,
    "هتلر": 1,
    "الضاربون": 1,
    "الإنحال": 1,
    "هم": 1,
    "سندويشة": 1,
    "سندويشات": 1,
    "هواجلاند": 1,
    "الكنز": 1,
    "مكتنز": 1,
    "المكتنز": 1,
    "المكتنزون": 1,
    "الكنوز": 1,
    "أجش": 1,
    "هوارسين": 1,
    "الجشاشة": 1,
    "هوبارت": 1,
    "هوبز": 1,
    "العقال": 1,
    "عارج": 1,
    "الماشي": 1,
    "يعرج": 1,
    "العرج": 1,
    "هوبداي": 1,
    "عاشر": 1,
    "الأفاق": 1,
    "الأفاقون": 1,
    "هوبوكين": 1,
    "النتوءات": 1,
    "هوك": 1,
    "العرقوب": 1,
    "هوكر": 1,
    "الهوكي": 1,
    "العراقيب": 1,
    "الرهن": 1,
    "الخزعبلات": 1,
    "هودج": 1,
    "الخربطة": 1,
    "هوجيس": 1,
    "هودجكين": 1,
    "المعزقة": 1,
    "المعازق": 1,
    "هوف": 1,
    "هوفمان": 1,
    "هوجان": 1,
    "بقايا": 1,
    "هوي": 1,
    "الرافع": 1,
    "هوكان": 1,
    "هولبروك": 1,
    "هولكوم": 1,
    "ملفا": 1,
    "هولدن": 1,
    "المستمرون": 1,
    "التعطيلات": 1,
    "خزن": 1,
    "للثقوب": 1,
    "العطلة": 1,
    "المصطاف": 1,
    "أقدس": 1,
    "المقدسات": 1,
    "القدسية": 1,
    "شمولي": 1,
    "بعصير": 1,
    "الليمون": 1,
    "هولاندا": 1,
    "الهولندية": 1,
    "الصرخة": 1,
    "هولليريث": 1,
    "الصرخات": 1,
    "هولينجسورث": 1,
    "هوليستير": 1,
    "هولواي": 1,
    "الأجواف": 1,
    "الأطباق": 1,
    "الفضية": 1,
    "هولي": 1,
    "الخطمي": 1,
    "هوليود": 1,
    "هولم": 1,
    "هولمان": 1,
    "هولمدل": 1,
    "هولمز": 1,
    "هولميوم": 1,
    "السهول": 1,
    "المحرقة": 1,
    "10,000": 1,
    "سنة": 1,
    "المجسمة": 1,
    "المجسم": 1,
    "هولوجرافيكال": 1,
    "الهولوجرامي": 1,
    "هولست": 1,
    "هولشتاين": 1,
    "غمد": 1,
    "الغمد": 1,
    "هولت": 1,
    "هوليوك": 1,
    "الخفان": 1,
    "هولزمان": 1,
    "أثنى": 1,
    "مخبوز": 1,
    "وطني": 1,
    "الصنع": 1,
    "للوطن": 1,
    "هوميد": 1,
    "الوطن": 1,
    "المشرد": 1,
    "مشرد": 1,
    "التشرد": 1,
    "ربة": 1,
    "ربات": 1,
    "تدبير": 1,
    "المثلي": 1,
    "بالمثل": 1,
    "المثلية": 1,
    "ملكية": 1,
    "هوميروس": 1,
    "هومري": 1,
    "الزاجل": 1,
    "الحنين": 1,
    "البلدي": 1,
    "العزبة": 1,
    "هومتاون": 1,
    "البيتي": 1,
    "البيتية": 1,
    "المواعظ": 1,
    "التجانسات": 1,
    "بتجانس": 1,
    "جانس": 1,
    "مجانس": 1,
    "الخالط": 1,
    "الخالطون": 1,
    "يجانس": 1,
    "المجانسة": 1,
    "المشابه": 1,
    "التماثل": 1,
    "تشابه": 1,
    "تشابهات": 1,
    "شكلية": 1,
    "المتجانس": 1,
    "باللفظ": 1,
    "الألفاظ": 1,
    "المتجانسة": 1,
    "كراهية": 1,
    "اللوطيون": 1,
    "بتطابق": 1,
    "المبجل": 1,
    "الهوندا": 1,
    "هوندو": 1,
    "يابانية": 1,
    "هندوراس": 1,
    "الجلخ": 1,
    "هونر": 1,
    "بأمانة": 1,
    "الأمانة": 1,
    "العسل": 1,
    "أقراص": 1,
    "معسل": 1,
    "شهر": 1,
    "مقضي": 1,
    "هونييويل": 1,
    "الجنسيات": 1,
    "هونج": 1,
    "زمر": 1,
    "يزمر": 1,
    "هونكيتونك": 1,
    "هونولولو": 1,
    "الوقار": 1,
    "الأتعاب": 1,
    "المكرم": 1,
    "المكرمون": 1,
    "عبارات": 1,
    "شرفيا": 1,
    "شرف": 1,
    "هونشو": 1,
    "هود": 1,
    "القلنسوات": 1,
    "الفارغ": 1,
    "الحافر": 1,
    "حافر": 1,
    "حوافر": 1,
    "هوفر": 1,
    "الحوافر": 1,
    "النرجيلة": 1,
    "النرجيلات": 1,
    "الإنعقاف": 1,
    "هوكير": 1,
    "هوكوب": 1,
    "الإنضمامات": 1,
    "الأنكلستوما": 1,
    "الشغب": 1,
    "هوبير": 1,
    "الهرج": 1,
    "والمرج": 1,
    "الهدهد": 1,
    "الهداهد": 1,
    "الصيحات": 1,
    "السجون": 1,
    "إنديانا": 1,
    "الشعبي": 1,
    "العطعطة": 1,
    "هوفير": 1,
    "متمنى": 1,
    "المتفائلون": 1,
    "المتمني": 1,
    "الآمال": 1,
    "هوبي": 1,
    "الشوشونية": 1,
    "التمني": 1,
    "هوبكنز": 1,
    "قافز": 1,
    "النطاط": 1,
    "النطاطات": 1,
    "هوراس": 1,
    "هوراشيو": 1,
    "الآفاق": 1,
    "هورموني": 1,
    "الهورمون": 1,
    "الهورمونات": 1,
    "النير": 1,
    "الهورنبلند": 1,
    "مقرن": 1,
    "بذوات": 1,
    "الدبور": 1,
    "تقرنا": 1,
    "المنشارية": 1,
    "هورنوورت": 1,
    "الطوالع": 1,
    "هورويز": 1,
    "ببشاعة": 1,
    "يروع": 1,
    "روع": 1,
    "الترويع": 1,
    "الرعب": 1,
    "بجواد": 1,
    "السبيب": 1,
    "هورسيلي": 1,
    "الخيالة": 1,
    "هورسنز": 1,
    "الفجل": 1,
    "حذوة": 1,
    "هورسيشوير": 1,
    "حذوات": 1,
    "الفارسة": 1,
    "الفارسات": 1,
    "بحصان": 1,
    "تشجيعي": 1,
    "بستاني": 1,
    "هورتن": 1,
    "حورس": 1,
    "الخرطوم": 1,
    "مسقي": 1,
    "الخراطيم": 1,
    "السقاية": 1,
    "العجزة": 1,
    "مضياف": 1,
    "الضيافة": 1,
    "ضيافيا": 1,
    "بالمستشفى": 1,
    "إدخال": 1,
    "المستشفيات": 1,
    "الرهينة": 1,
    "الرهائن": 1,
    "النطاقالمضيف": 1,
    "مستضاف": 1,
    "الفندق": 1,
    "الخانات": 1,
    "الخان": 1,
    "الفنادق": 1,
    "المضيفة": 1,
    "المضيفات": 1,
    "الإستضافة": 1,
    "السياس": 1,
    "بترحيب": 1,
    "المضيفالبوابة": 1,
    "مستضيفمستخدم": 1,
    "المستنبت": 1,
    "عندما": 1,
    "ترتفع": 1,
    "حرارته": 1,
    "المقانق": 1,
    "استعجل": 1,
    "المتهور": 1,
    "المستدفأ": 1,
    "الإستنباتي": 1,
    "يبرمج": 1,
    "المعدلة": 1,
    "أسمر": 1,
    "أحر": 1,
    "هوديل": 1,
    "هودني": 1,
    "هوغ": 1,
    "هوغتون": 1,
    "الرملية": 1,
    "هور": 1,
    "روض": 1,
    "الترويض": 1,
    "سرقات": 1,
    "الروب": 1,
    "مدبرة": 1,
    "مدبرات": 1,
    "المشرف": 1,
    "أسطح": 1,
    "بربة": 1,
    "المقرات": 1,
    "هيوستن": 1,
    "إنتفخ": 1,
    "حم": 1,
    "الحوامة": 1,
    "حائم": 1,
    "المحلق": 1,
    "الحوم": 1,
    "يحوم": 1,
    "هاوارد": 1,
    "هاويل": 1,
    "أية": 1,
    "الهاون": 1,
    "العواء": 1,
    "الفاحشة": 1,
    "يعوي": 1,
    "هاوس": 1,
    "مستهتر": 1,
    "باكرد": 1,
    "هروثجار": 1,
    "المستعملة": 1,
    "المتشعب": 1,
    "وتسمى": 1,
    "هوبارد": 1,
    "هوبيل": 1,
    "هبل": 1,
    "هيوبرت": 1,
    "الكبرياء": 1,
    "إستعلائي": 1,
    "التلملم": 1,
    "مكوم": 1,
    "المزاحم": 1,
    "هودسون": 1,
    "هيوي": 1,
    "السيئ": 1,
    "التكشيرات": 1,
    "الحضنة": 1,
    "معانق": 1,
    "هجينجز": 1,
    "هيو": 1,
    "هيوز": 1,
    "هيوجو": 1,
    "الحضنات": 1,
    "هوجو": 1,
    "هاجينوت": 1,
    "هاه": 1,
    "الاستجواب": 1,
    "بتثاقل": 1,
    "البزر": 1,
    "إنسانيا": 1,
    "إنسانية": 1,
    "الإنسانيون": 1,
    "المحسنون": 1,
    "الانسانية": 1,
    "أنس": 1,
    "بالإنسانية": 1,
    "المطعمون": 1,
    "يؤنس": 1,
    "التأنيس": 1,
    "مواصفات": 1,
    "متواضع": 1,
    "بتواضع": 1,
    "هومبولدت": 1,
    "الإستثنائي": 1,
    "الإستثنائيون": 1,
    "هيوم": 1,
    "عضدي": 1,
    "الترطيب": 1,
    "المرطب": 1,
    "المرطبون": 1,
    "بإذلال": 1,
    "مهمهم": 1,
    "الهمهمة": 1,
    "الربوة": 1,
    "الفكاهي": 1,
    "الفكاهيون": 1,
    "بظرافة": 1,
    "الفكاهة": 1,
    "المزاجات": 1,
    "ظريف": 1,
    "الأحدب": 1,
    "أحدب": 1,
    "همفرييس": 1,
    "الدندنات": 1,
    "الدبال": 1,
    "متحدب": 1,
    "مائة": 1,
    "المئات": 1,
    "الهنغاري": 1,
    "هنغاريا": 1,
    "الجوع": 1,
    "مجاع": 1,
    "جوعا": 1,
    "بجوع": 1,
    "جائع": 1,
    "المركع": 1,
    "جلس": 1,
    "الجلوس": 1,
    "هنتر": 1,
    "هانتينجتن": 1,
    "هانتلي": 1,
    "الصيادة": 1,
    "يطارد": 1,
    "هانتسفيل": 1,
    "هيرد": 1,
    "الموانع": 1,
    "هورلي": 1,
    "هيرن": 1,
    "تهاليل": 1,
    "العجالة": 1,
    "عجالة": 1,
    "هورست": 1,
    "بألم": 1,
    "إندفع": 1,
    "يندفع": 1,
    "يآذي": 1,
    "هرويتز": 1,
    "الفلاح": 1,
    "الفلاحون": 1,
    "الفلاحة": 1,
    "يسكت": 1,
    "القشة": 1,
    "المقشر": 1,
    "المقشرون": 1,
    "أقوياء": 1,
    "قشريا": 1,
    "الوقحات": 1,
    "الوقحة": 1,
    "الزحام": 1,
    "المزاحمون": 1,
    "هوتشينس": 1,
    "هوتشينسون": 1,
    "هوتشيسون": 1,
    "هوكسلي": 1,
    "هاكستابل": 1,
    "ابتهاج": 1,
    "السنبل": 1,
    "القلائص": 1,
    "الضبع": 1,
    "الضباع": 1,
    "الهيالين": 1,
    "هيانيس": 1,
    "الهجين": 1,
    "التهجين": 1,
    "مهجن": 1,
    "يهجن": 1,
    "الهجائن": 1,
    "هيد": 1,
    "الهيدرة": 1,
    "الكوبية": 1,
    "ميه": 1,
    "يميه": 1,
    "التمييه": 1,
    "هيدروليكي": 1,
    "هيدروليكيا": 1,
    "الهايدروليكا": 1,
    "الهيدريد": 1,
    "المصحة": 1,
    "الهيدروكربون": 1,
    "القيلة": 1,
    "القيلات": 1,
    "بتضخم": 1,
    "الهيدرو": 1,
    "كلوريك": 1,
    "الهيدروكلوريد": 1,
    "الهيدروناميكا": 1,
    "كهرومائي": 1,
    "بالهيدروفلورين": 1,
    "المزعنف": 1,
    "المزعنفة": 1,
    "الهيدروجين": 1,
    "هدرج": 1,
    "مهدرج": 1,
    "يهدرج": 1,
    "التهدرج": 1,
    "الهدرجة": 1,
    "هايدروجيني": 1,
    "هيدرولوجي": 1,
    "الهيدرولوجية": 1,
    "الموارد": 1,
    "التميؤ": 1,
    "ثقل": 1,
    "النوعي": 1,
    "الهيدرومتر": 1,
    "هيدرونيوم": 1,
    "بالعلاج": 1,
    "للاتحاد": 1,
    "بفوبيا": 1,
    "الزلاقات": 1,
    "المصحات": 1,
    "متوازن": 1,
    "أستاتيكا": 1,
    "الموائع": 1,
    "بالمياه": 1,
    "الهيدروكسيد": 1,
    "هيدروكسيلي": 1,
    "هيدروكسيل": 1,
    "الهيدروكسيل": 1,
    "هيدروكسيزاين": 1,
    "هيدرس": 1,
    "صحيا": 1,
    "الصحي": 1,
    "بمقياس": 1,
    "هيمان": 1,
    "البكارة": 1,
    "أغشية": 1,
    "الترتيلة": 1,
    "إنشاد": 1,
    "الزائد": 1,
    "إطنابي": 1,
    "مكعبات": 1,
    "فرط": 1,
    "بفرط": 1,
    "الإبصار": 1,
    "للبعيد": 1,
    "للقريب": 1,
    "الكمي": 1,
    "أجزاء": 1,
    "التشريط": 1,
    "الشرطات": 1,
    "التناويم": 1,
    "مغنطيسيا": 1,
    "المنومون": 1,
    "المنوم": 1,
    "للتنويم": 1,
    "نوم": 1,
    "ينوم": 1,
    "الهيبوسلفات": 1,
    "قليلة": 1,
    "هيبوكلورايت": 1,
    "هيبوكلوروس": 1,
    "الوسواس": 1,
    "وسواسي": 1,
    "نفاقي": 1,
    "منافق": 1,
    "الناتج": 1,
    "تتدحرج": 1,
    "هيبودرميس": 1,
    "هيبوفيسيل": 1,
    "نخامية": 1,
    "المهاد": 1,
    "البصري": 1,
    "بالهايبوتلاموس": 1,
    "الهايبوتلاموس": 1,
    "فرضية": 1,
    "إنخفاض": 1,
    "الفرضيات": 1,
    "الفرضية": 1,
    "إفترض": 1,
    "الإفتراض": 1,
    "إفتراضي": 1,
    "إفتراضيا": 1,
    "بالقصور": 1,
    "الدرقي": 1,
    "القصور": 1,
    "استئصالات": 1,
    "استئصال": 1,
    "التخلفية": 1,
    "الهستيريا": 1,
    "هستيري": 1,
    "الهيرتز": 1,
    "العاملة": 1,
    "أوشحة": 1,
    "تغطي": 1,
    "جهة": 1,
    "بتزويد": 1,
    "والدعم": 1,
    "لمستخدمي": 1,
    "تلفزيونية": 1,
    "بالتسوق": 1,
    "والتبضع": 1,
    "البضاعة": 1,
    "شاشة": 1,
    "حلال": 1,
    "مذبوح": 1,
    "الإسلامية": 1,
    "انا": 1,
    "أقسم": 1,
    "وبمعنى": 1,
    "أيوا": 1,
    "الإيامبي": 1,
    "إيان": 1,
    "إيبريا": 1,
    "آيبيري": 1,
    "إبيرو": 1,
    "منجل": 1,
    "ام": 1,
    "إبسن": 1,
    "إكاروس": 1,
    "العالمية": 1,
    "الآيس": 1,
    "الثلجي": 1,
    "للتزحلق": 1,
    "بالجليد": 1,
    "كاسحة": 1,
    "آيسلندا": 1,
    "الآيسلندي": 1,
    "بائعو": 1,
    "الثلوج": 1,
    "رقاقات": 1,
    "أثلج": 1,
    "ثلجيا": 1,
    "التثلج": 1,
    "الأيقونة": 1,
    "أيقوني": 1,
    "الثائر": 1,
    "الثوار": 1,
    "الأيقونات": 1,
    "وجها": 1,
    "بمتعدد": 1,
    "العشروني": 1,
    "إدا": 1,
    "إداهو": 1,
    "تطوير": 1,
    "متكاملة": 1,
    "المثالية": 1,
    "المثالي": 1,
    "مثاليا": 1,
    "المثاليون": 1,
    "يمثل": 1,
    "مثله": 1,
    "لو": 1,
    "استخدموا": 1,
    "مماثل": 1,
    "التعريفات": 1,
    "المعرفون": 1,
    "الهويات": 1,
    "إديوليكت": 1,
    "إديولوجيك": 1,
    "أيديولوجي": 1,
    "فكريا": 1,
    "المذهبي": 1,
    "المذهبيون": 1,
    "البلاهة": 1,
    "العلة": 1,
    "تمييزي": 1,
    "البلهاء": 1,
    "مضيع": 1,
    "التسيب": 1,
    "العاطل": 1,
    "العاطلون": 1,
    "يضيع": 1,
    "الضياع": 1,
    "المعبود": 1,
    "الوثني": 1,
    "عبادة": 1,
    "الأصنام": 1,
    "إعبد": 1,
    "معبود": 1,
    "العابد": 1,
    "العابدون": 1,
    "يعبد": 1,
    "الرعوية": 1,
    "شاعري": 1,
    "القصائد": 1,
    "بمعنى": 1,
    "اخر..": 1,
    "مهندسي": 1,
    "والإلكترونيات": 1,
    "إذا": 1,
    "التشكك": 1,
    "متشكك": 1,
    "إيفني": 1,
    "كوخ": 1,
    "الإسكيمو": 1,
    "أكواخ": 1,
    "أشعل": 1,
    "الشاعل": 1,
    "يشعل": 1,
    "الإشعال": 1,
    "الإيقاد": 1,
    "الفضائح": 1,
    "الجهول": 1,
    "الجهلة": 1,
    "الجاهل": 1,
    "أهمل": 1,
    "إيغور": 1,
    "الإيغوانات": 1,
    "تشابك": 1,
    "المشغل": 1,
    "آيك": 1,
    "إلينوي": 1,
    "المعي": 1,
    "اللفائفي": 1,
    "الشلل": 1,
    "إليا": 1,
    "حرقفي": 1,
    "إلياذة": 1,
    "إليوم": 1,
    "الانتهاكات": 1,
    "شرعية": 1,
    "مقروء": 1,
    "تصعب": 1,
    "النغولة": 1,
    "حرام": 1,
    "الآفق": 1,
    "محظور": 1,
    "اللاحدودية": 1,
    "اللامحدود": 1,
    "متناه": 1,
    "الأمية": 1,
    "أميا": 1,
    "الأميون": 1,
    "اللامنطقية": 1,
    "أنر": 1,
    "منار": 1,
    "ينير": 1,
    "للإضاءة": 1,
    "منور": 1,
    "أنوار": 1,
    "مضيئ": 1,
    "الإنارة": 1,
    "خداع": 1,
    "الإيضاح": 1,
    "الإيضاحات": 1,
    "إيضاحي": 1,
    "إلونا": 1,
    "إليشين": 1,
    "للتخيل": 1,
    "الإمكانية": 1,
    "خيالية": 1,
    "إمبيسيليتيس": 1,
    "إطمر": 1,
    "الطمر": 1,
    "يطمر": 1,
    "المتجرع": 1,
    "الورطة": 1,
    "ضرج": 1,
    "مضرج": 1,
    "يضرج": 1,
    "التضريج": 1,
    "شبع": 1,
    "للتقليد": 1,
    "يقلد": 1,
    "المقلد": 1,
    "المقلدون": 1,
    "بنظافة": 1,
    "اللزوم": 1,
    "اللامادية": 1,
    "ناضج": 1,
    "النضوج": 1,
    "النضج": 1,
    "اللامحدودية": 1,
    "حصر": 1,
    "الهائلة": 1,
    "إغطس": 1,
    "غاطس": 1,
    "الغمر": 1,
    "المهاجر": 1,
    "دوائر": 1,
    "الوشاكة": 1,
    "وشيك": 1,
    "للإمتزاج": 1,
    "التعوق": 1,
    "شل": 1,
    "يشل": 1,
    "دعي": 1,
    "ضح": 1,
    "مضحى": 1,
    "يضحي": 1,
    "التضحية": 1,
    "العهر": 1,
    "مخلد": 1,
    "التخليد": 1,
    "الخالدون": 1,
    "الرسوخ": 1,
    "إمان": 1,
    "المناعات": 1,
    "التلقيح": 1,
    "لقح": 1,
    "انفصال": 1,
    "البروتينات": 1,
    "مناعي": 1,
    "مناعيا": 1,
    "إمباكتر": 1,
    "الإنحشار": 1,
    "التصادم": 1,
    "خوزق": 1,
    "مخوزق": 1,
    "يخوزق": 1,
    "التخوزق": 1,
    "محسوس": 1,
    "ملموسة": 1,
    "إمنح": 1,
    "الإفسادات": 1,
    "نزيه": 1,
    "الحيادية": 1,
    "إستحالة": 1,
    "سالكة": 1,
    "المسدود": 1,
    "بعديم": 1,
    "الحس": 1,
    "نفاد": 1,
    "صبور": 1,
    "بنفاذ": 1,
    "صبر": 1,
    "إتهم": 1,
    "غاية": 1,
    "الإتقان": 1,
    "منزه": 1,
    "المعاوقة": 1,
    "المعاوقات": 1,
    "إمبيدر": 1,
    "الدفاعة": 1,
    "هدد": 1,
    "مهدد": 1,
    "يهدد": 1,
    "بمناعة": 1,
    "التمادي": 1,
    "نادم": 1,
    "الأولوية": 1,
    "حتميا": 1,
    "الأولويات": 1,
    "لايدرك": 1,
    "لاإدراكي": 1,
    "مراعاة": 1,
    "للبداية": 1,
    "للفعل": 1,
    "الماضية": 1,
    "مثقب": 1,
    "الإمبريالية": 1,
    "الإمبريالي": 1,
    "إمبريالية": 1,
    "الإمبرياليون": 1,
    "إمبراطوري": 1,
    "خاطر": 1,
    "يخاطر": 1,
    "متجبر": 1,
    "خالدة": 1,
    "المؤقتية": 1,
    "النفاذ": 1,
    "نفاذ": 1,
    "عازلة": 1,
    "شخصي": 1,
    "موضوعيا": 1,
    "الصلافة": 1,
    "صلف": 1,
    "الحصف": 1,
    "المعصية": 1,
    "إرتطم": 1,
    "مرتطم": 1,
    "يرتطم": 1,
    "الإرتطام": 1,
    "العقوق": 1,
    "الغرس": 1,
    "التطبيقات": 1,
    "المطبق": 1,
    "المطبقون": 1,
    "توريطي": 1,
    "مائلا": 1,
    "للتوريط": 1,
    "التضمينية": 1,
    "تضميني": 1,
    "ضمني": 1,
    "ضمنيا": 1,
    "الضمنية": 1,
    "إمبلوسيف": 1,
    "لاسياسيا": 1,
    "ألاسياسية": 1,
    "مستحيل": 1,
    "أستحالة": 1,
    "المجهولة": 1,
    "باستحالة": 1,
    "الإستيراد": 1,
    "للإستيراد": 1,
    "الأهمية": 1,
    "مهم": 1,
    "بأهمية": 1,
    "مستورد": 1,
    "المستورد": 1,
    "المستوردون": 1,
    "الإستيرادات": 1,
    "بإلحاح": 1,
    "المزعجون": 1,
    "اللج": 1,
    "الإستحالات": 1,
    "الإستحالة": 1,
    "مستحيلا": 1,
    "المكس": 1,
    "المنتحل": 1,
    "المنتحلون": 1,
    "المكوس": 1,
    "المفقر": 1,
    "يفقر": 1,
    "التفقير": 1,
    "لاعن": 1,
    "الإشباع": 1,
    "إمبريسير": 1,
    "الأختام": 1,
    "إعجاب": 1,
    "الإنطباع": 1,
    "للتحوير": 1,
    "الإنطباعية": 1,
    "الإنطباعي": 1,
    "إنطباعي": 1,
    "الإنطباعيون": 1,
    "الإنطباعات": 1,
    "للإعجاب": 1,
    "مطبوع": 1,
    "إسجن": 1,
    "مسجون": 1,
    "يسجن": 1,
    "الاستبعاد": 1,
    "إحتمالية": 1,
    "استبعاد": 1,
    "محتملة": 1,
    "إرتجالا": 1,
    "للتحسين": 1,
    "الإسراف": 1,
    "مبذر": 1,
    "إمبروفيسات": 1,
    "صفيق": 1,
    "إمبولس": 1,
    "أبدى": 1,
    "رغبة": 1,
    "قوى": 1,
    "إبداء": 1,
    "الدوافع": 1,
    "الحصانة": 1,
    "الشائبة": 1,
    "للإسناد": 1,
    "إنسب": 1,
    "بالإتجاه": 1,
    "المحطة": 1,
    "دقة": 1,
    "التكاسل": 1,
    "مقصود": 1,
    "تافها": 1,
    "السفاهة": 1,
    "ضاهر": 1,
    "ضاهرة": 1,
    "للإرضاء": 1,
    "اللاتطبيقية": 1,
    "ملائمة": 1,
    "تقديرية": 1,
    "اللاتلائمية": 1,
    "لامفصلي": 1,
    "حس": 1,
    "السهو": 1,
    "إفتتاحي": 1,
    "افتتاحيات": 1,
    "إفتتح": 1,
    "مفتتح": 1,
    "يفتتح": 1,
    "الإفتتاح": 1,
    "التنصيب": 1,
    "مشؤوم": 1,
    "النحس": 1,
    "المنتصف": 1,
    "غريزي": 1,
    "الملعب": 1,
    "الواردات": 1,
    "توالد": 1,
    "التوالد": 1,
    "المحدودة": 1,
    "إنكا": 1,
    "اللاتناهي": 1,
    "بدرجة": 1,
    "إنكانت": 1,
    "التعزيم": 1,
    "إنكانتيد": 1,
    "الإعجاز": 1,
    "شعب": 1,
    "الإينكا": 1,
    "متعجل": 1,
    "المحرقون": 1,
    "البخور": 1,
    "يبخر": 1,
    "الإستهلال": 1,
    "إستهلالي": 1,
    "المتخرج": 1,
    "النكاح": 1,
    "المحرم": 1,
    "زاني": 1,
    "البوصة": 1,
    "البوصات": 1,
    "التحرك": 1,
    "يرقة": 1,
    "فراشة": 1,
    "جيوميتريد": 1,
    "أحرق": 1,
    "محزوز": 1,
    "محرض": 1,
    "للإنحدار": 1,
    "إحصر": 1,
    "يحصر": 1,
    "الحصر": 1,
    "للشمل": 1,
    "بضمن": 1,
    "الإدراج": 1,
    "ضمنا": 1,
    "جامح": 1,
    "التستر": 1,
    "متفكك": 1,
    "قياسية": 1,
    "لايشارك": 1,
    "للتبديل": 1,
    "التفرد": 1,
    "يضاهى": 1,
    "اللامقارنة": 1,
    "العاجز": 1,
    "العاجزون": 1,
    "الإبهام": 1,
    "مبهمة": 1,
    "مستوعب": 1,
    "للضغط": 1,
    "للحساب": 1,
    "هام": 1,
    "مهمة": 1,
    "التضاربات": 1,
    "عزاء": 1,
    "جدال": 1,
    "السلس": 1,
    "منفلت": 1,
    "محسوم": 1,
    "صرفية": 1,
    "للتوحيد": 1,
    "الإندماج": 1,
    "توحيدي": 1,
    "معنوي": 1,
    "فسادية": 1,
    "الإستقامة": 1,
    "إنكوست": 1,
    "تصورية": 1,
    "الشكوكية": 1,
    "بشكوك": 1,
    "إزداد": 1,
    "جرم": 1,
    "مجرم": 1,
    "يجرم": 1,
    "التجريم": 1,
    "فقس": 1,
    "يفقس": 1,
    "حضني": 1,
    "الحاضنة": 1,
    "الحاضنات": 1,
    "إنكوبي": 1,
    "الكابوس": 1,
    "إطبع": 1,
    "يغرس": 1,
    "العضالية": 1,
    "العضول": 1,
    "العضولون": 1,
    "للشفاء": 1,
    "التوغل": 1,
    "التوغلات": 1,
    "الفحش": 1,
    "البذائة": 1,
    "مخل": 1,
    "بالآداب": 1,
    "مخلة": 1,
    "إنديكوروسنيس": 1,
    "بإصرار": 1,
    "للدفاع": 1,
    "زوالية": 1,
    "للإزالة": 1,
    "للإثبات": 1,
    "مطعوج": 1,
    "إندينتر": 1,
    "الطعج": 1,
    "عقود": 1,
    "الإستقلال": 1,
    "المستقلون": 1,
    "أنعدام": 1,
    "متعذر": 1,
    "وصفه": 1,
    "إنتفاء": 1,
    "يوصف": 1,
    "إتلافية": 1,
    "اللاحتمية": 1,
    "مفهرس": 1,
    "إنديانابوليس": 1,
    "مؤشر": 1,
    "المشيرون": 1,
    "أشير": 1,
    "الأساليب": 1,
    "الخبرية": 1,
    "إنديز": 1,
    "اللامبالاة": 1,
    "للهضم": 1,
    "المذلة": 1,
    "إنديرا": 1,
    "تمييزه": 1,
    "مدركة": 1,
    "اكتشافه": 1,
    "الطائشة": 1,
    "اللاتفرقة": 1,
    "اللزومية": 1,
    "غنى": 1,
    "الضرورية": 1,
    "متوعك": 1,
    "التوعك": 1,
    "للجدال": 1,
    "للإنفصال": 1,
    "إنحلالية": 1,
    "يتعذر": 1,
    "تميزه": 1,
    "تميز": 1,
    "إنديوم": 1,
    "الفرد": 1,
    "الفردية": 1,
    "الأناني": 1,
    "فردي": 1,
    "سلوك": 1,
    "التشخيصات": 1,
    "التفصيلات": 1,
    "منفرد": 1,
    "الأفراد": 1,
    "يتفرد": 1,
    "تفرد": 1,
    "ألا": 1,
    "تجزئة": 1,
    "للانقسام": 1,
    "صيني": 1,
    "عنود": 1,
    "لقن": 1,
    "يلقن": 1,
    "المدرس": 1,
    "الكسل": 1,
    "خضوعية": 1,
    "قهر": 1,
    "الأندونوسي": 1,
    "أقنع": 1,
    "مستحث": 1,
    "جينات": 1,
    "يقنع": 1,
    "للتولد": 1,
    "المحاثة": 1,
    "مطاوع": 1,
    "الإدخال": 1,
    "حثي": 1,
    "بالحث": 1,
    "الإستقرائية": 1,
    "أدواة": 1,
    "إنغمس": 1,
    "منغمس": 1,
    "الإنغماس": 1,
    "متساهل": 1,
    "إندولجير": 1,
    "ينغمس": 1,
    "التصنيع": 1,
    "الصناعيون": 1,
    "صناعيا": 1,
    "إندوستري": 1,
    "الصناعات": 1,
    "دؤوب": 1,
    "إندويل": 1,
    "السكنى": 1,
    "إندي": 1,
    "اللاوصفي": 1,
    "يمحى": 1,
    "نافع": 1,
    "اللافاعلية": 1,
    "كفء": 1,
    "اللاأناقة": 1,
    "اللاأهلية": 1,
    "فصيح": 1,
    "ظالم": 1,
    "الإساءات": 1,
    "اللاإنصاف": 1,
    "متأصل": 1,
    "خامد": 1,
    "محتوم": 1,
    "الحتمية": 1,
    "الحتمي": 1,
    "اللاعذرية": 1,
    "لايغتفر": 1,
    "اللانفاذية": 1,
    "ينضب": 1,
    "ينفد": 1,
    "قلة": 1,
    "يغتفر": 1,
    "تعليلية": 1,
    "اللاتعليلية": 1,
    "للإنفجار": 1,
    "يفوق": 1,
    "معبرة": 1,
    "للمد": 1,
    "إطفائه": 1,
    "الإنفصام": 1,
    "المعصومية": 1,
    "يشوبها": 1,
    "الطفولات": 1,
    "الرضيع": 1,
    "الوأد": 1,
    "جنود": 1,
    "عائق": 1,
    "عوائق": 1,
    "إينفونا": 1,
    "للتطبيق": 1,
    "أصب": 1,
    "مسببا": 1,
    "للعدوى": 1,
    "موفق": 1,
    "التوفيق": 1,
    "للإستنتاج": 1,
    "الإستدلالات": 1,
    "الدونية": 1,
    "الإبتلاء": 1,
    "الكافر": 1,
    "الكفار": 1,
    "يلاكم": 1,
    "المشاكل": 1,
    "إخترق": 1,
    "مخترق": 1,
    "يخترق": 1,
    "التسرب": 1,
    "التسربات": 1,
    "إختراقي": 1,
    "أدنى": 1,
    "لقيمتين": 1,
    "الأزل": 1,
    "اللانهائية": 1,
    "المتناهية": 1,
    "متناهي": 1,
    "محدودية": 1,
    "المصادر": 1,
    "تناهي": 1,
    "إنفيرميد": 1,
    "المزيد": 1,
    "المزيدة": 1,
    "ألهب": 1,
    "المهيج": 1,
    "يلهب": 1,
    "الإلهاب": 1,
    "الإشتعال": 1,
    "سريعة": 1,
    "الإلتهاب": 1,
    "الإلتهابات": 1,
    "تحريضي": 1,
    "للنفخ": 1,
    "النافخ": 1,
    "تضخمي": 1,
    "إعرابي": 1,
    "تصريفيا": 1,
    "يقوس": 1,
    "اللامرونة": 1,
    "الصرامة": 1,
    "أوقع": 1,
    "المصيب": 1,
    "يوقع": 1,
    "أعلم": 1,
    "معلوماتي": 1,
    "بالمعلومات": 1,
    "المفيدة": 1,
    "يعلم": 1,
    "الإخلال": 1,
    "الإخلالات": 1,
    "الأشعة": 1,
    "الحمراء": 1,
    "التحتي": 1,
    "التحتية": 1,
    "نادر": 1,
    "للضيق": 1,
    "التحلل": 1,
    "اللاإنصهارية": 1,
    "إنجاثر": 1,
    "الساذجة": 1,
    "الإبداعات": 1,
    "إنجيرسول": 1,
    "القالب": 1,
    "رسخ": 1,
    "متشرب": 1,
    "يرسخ": 1,
    "إنجرام": 1,
    "ناكر": 1,
    "الجميل": 1,
    "تزلف": 1,
    "متزلف": 1,
    "يتزلف": 1,
    "التزلف": 1,
    "الحظي": 1,
    "للداخل": 1,
    "أربيي": 1,
    "إسكن": 1,
    "المستنشق": 1,
    "المستنشقات": 1,
    "الإستنشاق": 1,
    "إستنشق": 1,
    "مستنشق": 1,
    "يستنشق": 1,
    "ملازم": 1,
    "التلازم": 1,
    "أصلا": 1,
    "للتوريث": 1,
    "المواريث": 1,
    "يرث": 1,
    "المانعون": 1,
    "عامة": 1,
    "حفاوة": 1,
    "الظلم": 1,
    "جائر": 1,
    "الأولي": 1,
    "بالأحرف": 1,
    "توقيع": 1,
    "المعد": 1,
    "المعدون": 1,
    "أوليا": 1,
    "مستهل": 1,
    "مبدوء": 1,
    "المبادرة": 1,
    "البادئ": 1,
    "البادئون": 1,
    "إحقن": 1,
    "للحقن": 1,
    "محقون": 1,
    "المتوافقة": 1,
    "الحاقن": 1,
    "يحقن": 1,
    "إينجن": 1,
    "مصطلح": 1,
    "للأمريكيين": 1,
    "إجرح": 1,
    "جريح": 1,
    "المؤذي": 1,
    "يجرح": 1,
    "تجريحي": 1,
    "محاية": 1,
    "حبر": 1,
    "وسادة": 1,
    "رصاص": 1,
    "محبر": 1,
    "المحبر": 1,
    "المحبرات": 1,
    "التحبير": 1,
    "الأحبار": 1,
    "المحبرة": 1,
    "المبطن": 1,
    "إنمان": 1,
    "النزيل": 1,
    "النزلاء": 1,
    "المصارين": 1,
    "الفطرة": 1,
    "يعصب": 1,
    "إينوسينس": 1,
    "الأبرياء": 1,
    "إبتدع": 1,
    "مبتدع": 1,
    "يبتدع": 1,
    "الإبتداع": 1,
    "إبداعي": 1,
    "المبتكر": 1,
    "المبتكرون": 1,
    "الإساءة": 1,
    "المبطنة": 1,
    "اللامعدودية": 1,
    "تعد": 1,
    "تلقح": 1,
    "يتلقح": 1,
    "تلقيحي": 1,
    "مسالم": 1,
    "للعملية": 1,
    "المفعول": 1,
    "العطلان": 1,
    "عضويا": 1,
    "المدخلات": 1,
    "إدخالإخراج": 1,
    "المستعلم": 1,
    "المستعلمون": 1,
    "تحقيق": 1,
    "الإستقصاء": 1,
    "بالمحكمة": 1,
    "المحقق": 1,
    "إستقصائي": 1,
    "المسار": 1,
    "يقطعه": 1,
    "المتزلج": 1,
    "لزيادة": 1,
    "سرعته": 1,
    "خادمات": 1,
    "جشع": 1,
    "بجشع": 1,
    "الحشرة": 1,
    "إنسيكتيفور": 1,
    "آمن": 1,
    "الإطمئنان": 1,
    "الأمان": 1,
    "إكتراث": 1,
    "البرود": 1,
    "ادخل": 1,
    "الواضع": 1,
    "المطلع": 1,
    "المطلعون": 1,
    "الدواخل": 1,
    "بصيرة": 1,
    "بتبصر": 1,
    "التبصر": 1,
    "بتفاهة": 1,
    "تلميحيا": 1,
    "دسي": 1,
    "أصرر": 1,
    "إجتماعية": 1,
    "إنطوائية": 1,
    "لدرجة": 1,
    "للشمس": 1,
    "النعال": 1,
    "فيها": 1,
    "المؤرق": 1,
    "المؤرقون": 1,
    "المبالاة": 1,
    "مفتش": 1,
    "بالتفتيش": 1,
    "المفتشون": 1,
    "يفتش": 1,
    "إلهامي": 1,
    "ألهم": 1,
    "ملهم": 1,
    "الملهم": 1,
    "يلهم": 1,
    "بإلهام": 1,
    "الإستقرار": 1,
    "القسط": 1,
    "الأقساط": 1,
    "تنصيبإزالة": 1,
    "الفورية": 1,
    "اللحظة": 1,
    "آني": 1,
    "التجسيدات": 1,
    "اللحظية": 1,
    "اللحظات": 1,
    "بدلا": 1,
    "مشط": 1,
    "أمشاط": 1,
    "تحريكي": 1,
    "ثوري": 1,
    "إغرس": 1,
    "مغروس": 1,
    "الغريزة": 1,
    "بالغريزة": 1,
    "الفطرية": 1,
    "الغرائز": 1,
    "مؤسساتي": 1,
    "المؤسسي": 1,
    "المؤسساتية": 1,
    "التثقيفية": 1,
    "المدربون": 1,
    "يأمر": 1,
    "المعزوفات": 1,
    "الواسطة": 1,
    "توزيع": 1,
    "واهي": 1,
    "لايحتمل": 1,
    "تحتمل": 1,
    "العازل": 1,
    "العوازل": 1,
    "الأنسيولين": 1,
    "مهان": 1,
    "الإستعصاء": 1,
    "إستعصاء": 1,
    "يحتمل": 1,
    "يقمع": 1,
    "المأمن": 1,
    "أمن": 1,
    "مؤمن": 1,
    "إنجر": 1,
    "يأمن": 1,
    "المتمردون": 1,
    "مستعصية": 1,
    "المتمرد": 1,
    "لايتأثر": 1,
    "بتحصن": 1,
    "المعنوية": 1,
    "الحاجات": 1,
    "الصحيح": 1,
    "الأعداد": 1,
    "الصحيحة": 1,
    "متكامل": 1,
    "يكامل": 1,
    "المكاملة": 1,
    "التكامل": 1,
    "إنتيل": 1,
    "الفكر": 1,
    "التثقفية": 1,
    "الإستخبارات": 1,
    "إنو": 1,
    "المشرفون": 1,
    "يعتزم": 1,
    "ينوى": 1,
    "المشدد": 1,
    "المشددات": 1,
    "يشتد": 1,
    "إشتد": 1,
    "الإشتداد": 1,
    "التوتر": 1,
    "بالشدة": 1,
    "كثيفة": 1,
    "كثافةالإضاءة": 1,
    "تكثيف": 1,
    "النوايا": 1,
    "إنتر": 1,
    "تفاعل": 1,
    "متفاعل": 1,
    "التفاعل": 1,
    "التفاعلات": 1,
    "تفاعلي": 1,
    "التفاعلية": 1,
    "جزئين": 1,
    "تهاجن": 1,
    "مقحم": 1,
    "أقحم": 1,
    "يقحم": 1,
    "بيوم": 1,
    "إقحامي": 1,
    "توسط": 1,
    "المتوسل": 1,
    "يتوسط": 1,
    "التوسط": 1,
    "التعدادات": 1,
    "المتصدي": 1,
    "الاعتراض": 1,
    "الشفاعة": 1,
    "توسطي": 1,
    "الشفيع": 1,
    "المبادل": 1,
    "الاتصال": 1,
    "للقارات": 1,
    "المبرد": 1,
    "الضلوع": 1,
    "إنترديكتيف": 1,
    "مانعة": 1,
    "مهتما": 1,
    "للأنتباه": 1,
    "الإمتاع": 1,
    "متدخل": 1,
    "التدخلات": 1,
    "المتدخل": 1,
    "يتدخل": 1,
    "بتدخل": 1,
    "التداخل": 1,
    "للفيروسات": 1,
    "الأقحام": 1,
    "شابك": 1,
    "يشابك": 1,
    "الموضوعة": 1,
    "صفحات": 1,
    "السطور": 1,
    "الترابطات": 1,
    "إنترليسب": 1,
    "شحوم": 1,
    "إنترلوب": 1,
    "تطفل": 1,
    "المتطفلون": 1,
    "يتطفل": 1,
    "التطفل": 1,
    "الوسطاء": 1,
    "التناغم": 1,
    "يتناغم": 1,
    "اللانهاية": 1,
    "إختلط": 1,
    "يختلط": 1,
    "الإختلاط": 1,
    "التقطع": 1,
    "إنترمودول": 1,
    "التطبع": 1,
    "الدولية": 1,
    "بالدولية": 1,
    "إنترنيشناليتي": 1,
    "مدول": 1,
    "يدول": 1,
    "التدويل": 1,
    "الدوليون": 1,
    "الممارس": 1,
    "الإعتقالات": 1,
    "تبادل": 1,
    "البيني": 1,
    "الكواكب": 1,
    "محرف": 1,
    "موسط": 1,
    "يوسط": 1,
    "المترجم": 1,
    "المترجمون": 1,
    "خلو": 1,
    "متبادلة": 1,
    "المتبادلة": 1,
    "يترابط": 1,
    "الإستجوابات": 1,
    "ضمير": 1,
    "الإستفهام": 1,
    "إستجوابي": 1,
    "ضمائر": 1,
    "المستجوب": 1,
    "استفهاميات": 1,
    "المستجوبون": 1,
    "للمقاطعة": 1,
    "تقاطعي": 1,
    "يقاطع": 1,
    "التقاطعات": 1,
    "يتقاطع": 1,
    "بعثر": 1,
    "التناثر": 1,
    "إنترستاج": 1,
    "المحطات": 1,
    "فراغي": 1,
    "الفترات": 1,
    "للتدخل": 1,
    "فقرات": 1,
    "المقابلة": 1,
    "المقابلون": 1,
    "المقابلات": 1,
    "شوبك": 1,
    "وصية": 1,
    "الانتفاضة": 1,
    "الفلسطينية": 1,
    "الحميميات": 1,
    "إنتيمال": 1,
    "بحميمية": 1,
    "التنويه": 1,
    "التنويهات": 1,
    "مخاف": 1,
    "يخيف": 1,
    "الإخافة": 1,
    "التسامح": 1,
    "تسامح": 1,
    "رتل": 1,
    "الترنيم": 1,
    "مرتل": 1,
    "يرتل": 1,
    "الترتيل": 1,
    "ثملة": 1,
    "العضل": 1,
    "عضلي": 1,
    "العنيدون": 1,
    "اللازم": 1,
    "المتكونة": 1,
    "رئوي": 1,
    "وريدي": 1,
    "الجرءة": 1,
    "الفاتن": 1,
    "الفاتنون": 1,
    "الدسائس": 1,
    "إينترينساكس": 1,
    "المكتبية": 1,
    "Xlib": 1,
    "إنترو": 1,
    "تمهيدي": 1,
    "تمهيديا": 1,
    "الافتتاحي": 1,
    "التفحص": 1,
    "متعمق": 1,
    "بتعمق": 1,
    "وضعك": 1,
    "مشاعرك": 1,
    "الإنطواء": 1,
    "إنطوائي": 1,
    "الإنطوائي": 1,
    "الدخيل": 1,
    "الدخلاء": 1,
    "تدخلي": 1,
    "إبقاء": 1,
    "مفتوحا": 1,
    "حدسي": 1,
    "البداهة": 1,
    "الدهدان": 1,
    "فض": 1,
    "مفيض": 1,
    "يفيض": 1,
    "إغز": 1,
    "المحتل": 1,
    "المحتلون": 1,
    "السقام": 1,
    "باطلا": 1,
    "ثمين": 1,
    "الباهض": 1,
    "الثابتية": 1,
    "الثابت": 1,
    "الإحتلال": 1,
    "الاحتلال": 1,
    "شاتم": 1,
    "مندد": 1,
    "المحتج": 1,
    "يندد": 1,
    "إستدرج": 1,
    "مستدرج": 1,
    "المستدرج": 1,
    "يستدرج": 1,
    "الإستدراج": 1,
    "إخترع": 1,
    "الإختراعية": 1,
    "أحصى": 1,
    "الموجودات": 1,
    "المخترعون": 1,
    "الجرد": 1,
    "بالممتلكات": 1,
    "يخترع": 1,
    "شال": 1,
    "أكمام": 1,
    "العكوس": 1,
    "عكسي": 1,
    "المقلوب": 1,
    "فقريات": 1,
    "العاكسون": 1,
    "للعكس": 1,
    "إستثمر": 1,
    "مستثمر": 1,
    "الإستثمار": 1,
    "الإستثمارات": 1,
    "المستثمرون": 1,
    "يستثمر": 1,
    "مكتمل": 1,
    "حسدا": 1,
    "الإنتعاش": 1,
    "اللاقهر": 1,
    "الإختراقات": 1,
    "إختراقات": 1,
    "يمس": 1,
    "الخفاء": 1,
    "بخفاء": 1,
    "مقصور": 1,
    "للمدعوين": 1,
    "المدعو": 1,
    "المدعوون": 1,
    "ينادي": 1,
    "فاتورة": 1,
    "تنظيم": 1,
    "تضرع": 1,
    "متضرع": 1,
    "المستشهد": 1,
    "المستشهدون": 1,
    "يتضرع": 1,
    "التضرع": 1,
    "اللاإرادية": 1,
    "الإلتفاف": 1,
    "المحتوي": 1,
    "أيو": 1,
    "أحبها": 1,
    "زيوس": 1,
    "نافذة": 1,
    "والإخراج": 1,
    "والخارج": 1,
    "برمجيات": 1,
    "اليود": 1,
    "اليوديد": 1,
    "باليود": 1,
    "أيوني": 1,
    "التأين": 1,
    "للتأين": 1,
    "أين": 1,
    "مؤين": 1,
    "المؤين": 1,
    "يأين": 1,
    "التأيين": 1,
    "الأيونوسفير": 1,
    "أيونوسفيري": 1,
    "الآيونات": 1,
    "الأيوتا": 1,
    "وعد": 1,
    "بدفع": 1,
    "آيوا": 1,
    "إيبيكا": 1,
    "إيبسو": 1,
    "منظمة": 1,
    "الدراسات": 1,
    "التحليلية": 1,
    "الآيرلندي": 1,
    "الإيراني": 1,
    "العراق": 1,
    "العراقي": 1,
    "غضوب": 1,
    "غضوبا": 1,
    "إيرلنده": 1,
    "إرين": 1,
    "متخيف": 1,
    "قزحي": 1,
    "قزحيا": 1,
    "الإيريديوم": 1,
    "آيريس": 1,
    "القزحيات": 1,
    "آيرلندي": 1,
    "الآيرلنديون": 1,
    "الآيرلندية": 1,
    "أتعب": 1,
    "الإتعاب": 1,
    "يتعب": 1,
    "بمشقة": 1,
    "إرما": 1,
    "سخرية": 1,
    "التهكمية": 1,
    "شجاعة": 1,
    "ذوو": 1,
    "القوي": 1,
    "الأشغال": 1,
    "إيروكويس": 1,
    "يضيئ": 1,
    "عقلانية": 1,
    "عقلانيون": 1,
    "إيراوادي": 1,
    "ميانمار": 1,
    "للإسترداد": 1,
    "إسترجاعه": 1,
    "للحل": 1,
    "لايسترد": 1,
    "الإسترجاع": 1,
    "لايعوض": 1,
    "تحرري": 1,
    "للإنقاص": 1,
    "للدحض": 1,
    "اللاتناقضية": 1,
    "لايقبل": 1,
    "المخالفات": 1,
    "إنتظام": 1,
    "إريجولارس": 1,
    "علاقية": 1,
    "عضال": 1,
    "لايمكن": 1,
    "إصلاحه": 1,
    "الإبدال": 1,
    "تستبدل": 1,
    "الكبت": 1,
    "تحاشي": 1,
    "متكاثر": 1,
    "يقاوم": 1,
    "بغض": 1,
    "بالمسؤلية": 1,
    "لامبالي": 1,
    "الإكتراث": 1,
    "مسؤولية": 1,
    "القدرةعلى": 1,
    "القطعية": 1,
    "إسق": 1,
    "يسقي": 1,
    "الري": 1,
    "التهيجات": 1,
    "بانفعال": 1,
    "المهيجات": 1,
    "الإقتحام": 1,
    "إقتحامي": 1,
    "الداخليه": 1,
    "إرفين": 1,
    "إرفينج": 1,
    "إروين": 1,
    "إسحاق": 1,
    "إيساكس": 1,
    "أسترالي": 1,
    "إيزاسون": 1,
    "إيزابيل": 1,
    "إيزابيلا": 1,
    "إيزادور": 1,
    "أشعيا": 1,
    "رقمية": 1,
    "خرمات": 1,
    "إصفهان": 1,
    "إيسينج": 1,
    "جيلاتين": 1,
    "شفاف,": 1,
    "نقي": 1,
    "إيزيس": 1,
    "الربة": 1,
    "المعبودة": 1,
    "المصريين,": 1,
    "الإسلام": 1,
    "إسلام": 1,
    "إسلامي": 1,
    "إيزلانديا": 1,
    "لعمل": 1,
    "الأيزو": 1,
    "آيسو": 1,
    "الأيسوبار": 1,
    "بتساوي": 1,
    "أيزوسيانيت": 1,
    "الإنعزالية": 1,
    "المعتزلي": 1,
    "انعزالي": 1,
    "المعتزلة": 1,
    "أيسولد": 1,
    "أسطورة": 1,
    "أرثرايان,": 1,
    "الأميرة": 1,
    "الأيرلندية": 1,
    "تزوجت": 1,
    "كورنوول": 1,
    "الأيسومر": 1,
    "أيسومري": 1,
    "المماثل": 1,
    "يرسم": 1,
    "الخريطة": 1,
    "بالتوصيل": 1,
    "أيسوبرين": 1,
    "الآيزوبروبيل": 1,
    "الأيسوثرم": 1,
    "توحد": 1,
    "إسرائيل": 1,
    "الإسرائيلي": 1,
    "بإسرائيل": 1,
    "مواطنيها": 1,
    "الإسرائيليون": 1,
    "الإصدار": 1,
    "القضايا": 1,
    "إسطنبول": 1,
    "البرزخ": 1,
    "البرازخ": 1,
    "إيزتفان": 1,
    "الإيطاليون": 1,
    "إتاليس": 1,
    "الحكة": 1,
    "محكوك": 1,
    "كرر": 1,
    "مكرر": 1,
    "يكرر": 1,
    "التكرار": 1,
    "تكراري": 1,
    "عناصر": 1,
    "إيثيكا": 1,
    "الرحلة": 1,
    "تكنولوجيا": 1,
    "إيفان": 1,
    "إفانهوي": 1,
    "إفيرسون": 1,
    "باللبلاب": 1,
    "آيفي": 1,
    "تسعة": 1,
    "إزفيستيا": 1,
    "الأبرة": 1,
    "وخز": 1,
    "جابلونسكاي": 1,
    "الأبر": 1,
    "جاك": 1,
    "المشتغل": 1,
    "الجزمة": 1,
    "جاكيد": 1,
    "جاكير": 1,
    "جاكت": 1,
    "الستر": 1,
    "جاكي": 1,
    "المطواة": 1,
    "جاكمان": 1,
    "جاكسن": 1,
    "جاكسون": 1,
    "جاكسونفيل": 1,
    "صحيفة": 1,
    "يعقوب": 1,
    "يعقوبي": 1,
    "جاكوبي": 1,
    "بطراز": 1,
    "ديكور": 1,
    "جيمس": 1,
    "جاكوبس": 1,
    "جاكوبسين": 1,
    "جاكوبسون": 1,
    "جاكوبوس": 1,
    "جاكلين": 1,
    "اليشم": 1,
    "بانهاك": 1,
    "الساقطات": 1,
    "الكركر": 1,
    "بتعرج": 1,
    "ييجرز": 1,
    "التكسير": 1,
    "جاجي": 1,
    "النمور": 1,
    "خريج": 1,
    "خريجو": 1,
    "جيم": 1,
    "جاكارتا": 1,
    "جايك": 1,
    "المرحاض": 1,
    "القراضة": 1,
    "المربى": 1,
    "جامايكا": 1,
    "الجامايكي": 1,
    "العضادة": 1,
    "العضائد": 1,
    "جيمسن": 1,
    "جيمستاون": 1,
    "المربات": 1,
    "يناير/كانون": 1,
    "جين": 1,
    "جانيرو": 1,
    "جاينسفيل": 1,
    "الخشخاشة": 1,
    "الخشخشات": 1,
    "جانيس": 1,
    "الإنكشاريون": 1,
    "الإنكشاري": 1,
    "بصيانة": 1,
    "يونس": 1,
    "جانسين": 1,
    "كورنليوس": 1,
    "يناير": 1,
    "يانوس": 1,
    "رومانية": 1,
    "اليابان": 1,
    "الجرة": 1,
    "المفردة": 1,
    "التخصصية": 1,
    "المفردات": 1,
    "الجرار": 1,
    "جارفين": 1,
    "الياسمين": 1,
    "جايسون": 1,
    "كاسبر": 1,
    "اليشب": 1,
    "جاسترو": 1,
    "اليرقان": 1,
    "تصفر": 1,
    "بياض": 1,
    "بتبختر": 1,
    "النزه": 1,
    "برمجة": 1,
    "الحاسب": 1,
    "جاوا": 1,
    "جاوي": 1,
    "سكريبت": 1,
    "الرمح": 1,
    "الرماح": 1,
    "الفك": 1,
    "القاسية": 1,
    "الفكاك": 1,
    "بالفك": 1,
    "جاي": 1,
    "جايسي": 1,
    "جازي": 1,
    "الأزهى": 1,
    "غيور": 1,
    "الغيرة": 1,
    "بغيرة": 1,
    "جينيت": 1,
    "الجينز": 1,
    "تعبير": 1,
    "باستهزاء": 1,
    "جيفيرسن": 1,
    "توماس": 1,
    "جيفرسون": 1,
    "متعلقون": 1,
    "جيفريز": 1,
    "فلكي": 1,
    "اليهوه": 1,
    "بضحالة": 1,
    "الضجر": 1,
    "ألأمعاء": 1,
    "الدقيقة": 1,
    "الإثنا": 1,
    "اللفيفي": 1,
    "هلاميا": 1,
    "جيلو": 1,
    "قنديل": 1,
    "جينكنز": 1,
    "جينيفر": 1,
    "جينينجس": 1,
    "جينسين": 1,
    "أرميا": 1,
    "جيرمي": 1,
    "أريحا": 1,
    "مرجوج": 1,
    "متقفز": 1,
    "بتقفز": 1,
    "جيركين": 1,
    "الرجيج": 1,
    "جيروم": 1,
    "جيرزي": 1,
    "القدس": 1,
    "جيسي": 1,
    "جيسيكا": 1,
    "منكت": 1,
    "التنكيت": 1,
    "الدعابات": 1,
    "يسوعي": 1,
    "اليسوعيون": 1,
    "فاحم": 1,
    "النفاثة": 1,
    "أرصفة": 1,
    "إلقاء": 1,
    "رصيف": 1,
    "الجوهرة": 1,
    "مرصع": 1,
    "بالجواهر": 1,
    "الجواهري": 1,
    "الجواهريون": 1,
    "بالمجوهرات": 1,
    "جيول": 1,
    "جويت": 1,
    "يهودي": 1,
    "التأرجح": 1,
    "هزهز": 1,
    "مهزهز": 1,
    "يهزهز": 1,
    "الهزهزة": 1,
    "منشار": 1,
    "التخريم": 1,
    "الجهاد": 1,
    "جيل": 1,
    "إهجر": 1,
    "محبوبته": 1,
    "جيمينيز": 1,
    "جيمي": 1,
    "بعتلة": 1,
    "جيميس": 1,
    "مجلجل": 1,
    "جينجلير": 1,
    "الطنطنة": 1,
    "الجلجلة": 1,
    "جينجلي": 1,
    "القومية": 1,
    "قومي": 1,
    "جلب": 1,
    "جالبو": 1,
    "نرفز": 1,
    "المولع": 1,
    "بالرقص": 1,
    "الجتربغ": 1,
    "منرفز": 1,
    "النرفزة": 1,
    "جوان": 1,
    "جوانا": 1,
    "جوانز": 1,
    "جوكين": 1,
    "بشغل": 1,
    "بالمقاولة": 1,
    "إستغلال": 1,
    "جوبينج": 1,
    "جوك": 1,
    "تسابق": 1,
    "التسابق": 1,
    "الاسطوانات": 1,
    "محبا": 1,
    "للهزل": 1,
    "بنطلون": 1,
    "جودي": 1,
    "يوئيل": 1,
    "هرول": 1,
    "مهرول": 1,
    "إرتج": 1,
    "مهتزة": 1,
    "يرتج": 1,
    "يهرول": 1,
    "يوهان": 1,
    "يوهانز": 1,
    "جوهانسبيرغ": 1,
    "جوهانسين": 1,
    "جوهانسون": 1,
    "جون": 1,
    "جوني": 1,
    "جونس": 1,
    "جونسين": 1,
    "جونسن": 1,
    "جونستون": 1,
    "جونستاون": 1,
    "جويس": 1,
    "إنضم": 1,
    "النجار": 1,
    "الإنضمام": 1,
    "بتمفصل": 1,
    "النكتة": 1,
    "النكات": 1,
    "النكاتون": 1,
    "جوليت": 1,
    "جولا": 1,
    "بالمزاح": 1,
    "الطيب": 1,
    "جوليس": 1,
    "الصاعق": 1,
    "جوناثان": 1,
    "جونز": 1,
    "الجيران": 1,
    "المجاورة": 1,
    "جوبلين": 1,
    "جورجينسين": 1,
    "جورجينسون": 1,
    "خوزيه": 1,
    "يوسف": 1,
    "جوزيفين": 1,
    "جوزيفسون": 1,
    "جوزيفوس": 1,
    "جوش": 1,
    "المازح": 1,
    "يوشع": 1,
    "جوشيا": 1,
    "الزحمة": 1,
    "متدافع": 1,
    "يتدافع": 1,
    "التدافع": 1,
    "الجول": 1,
    "مهتز": 1,
    "يهتز": 1,
    "المجلة": 1,
    "الصحافة": 1,
    "صحفي": 1,
    "صحفيا": 1,
    "الصحفيون": 1,
    "يدون": 1,
    "المدون": 1,
    "المدونون": 1,
    "جوفانوفيتش": 1,
    "البشاشة": 1,
    "بكوكب": 1,
    "اللغد": 1,
    "الألغاد": 1,
    "بألغاد": 1,
    "ثقيلة": 1,
    "مرتخية": 1,
    "مبتهجا": 1,
    "مغموم": 1,
    "سياقة": 1,
    "الإبن": 1,
    "خوان": 1,
    "خوانيتا": 1,
    "كين.": 1,
    "سفر": 1,
    "يبتهج": 1,
    "اليوبيل": 1,
    "يهوذا": 1,
    "يهودا": 1,
    "الأحكام": 1,
    "حكمي": 1,
    "للمحاكمة": 1,
    "قضائيا": 1,
    "سلطات": 1,
    "القضائية": 1,
    "سلطة": 1,
    "متعقل": 1,
    "جوديث": 1,
    "الجودو": 1,
    "جودسون": 1,
    "الطاغوت": 1,
    "أواني": 1,
    "فخارية": 1,
    "جوجوسلافيا": 1,
    "الوداجي": 1,
    "عصر": 1,
    "عصيرا": 1,
    "ممتعة": 1,
    "العصيرية": 1,
    "يوليو/تموز": 1,
    "الجلاب": 1,
    "جولز": 1,
    "جوليا": 1,
    "جوليان": 1,
    "جولي": 1,
    "جوليو": 1,
    "جوليوس": 1,
    "مخلوط": 1,
    "الجمبو": 1,
    "طائرات": 1,
    "البلوز": 1,
    "يونيو/حزيران": 1,
    "الجنك": 1,
    "جانيو": 1,
    "جانج": 1,
    "نظرياته": 1,
    "للسيكولوجيا": 1,
    "مليئة": 1,
    "بالأشجار": 1,
    "الصغار": 1,
    "العرعر": 1,
    "الخردوات": 1,
    "اليونكر": 1,
    "بروسيا": 1,
    "البروسية": 1,
    "يونكرز": 1,
    "صانعها": 1,
    "وسميت": 1,
    "إسمه": 1,
    "وليمة": 1,
    "ضيف": 1,
    "كضيف": 1,
    "الحشاش": 1,
    "الحشاشون": 1,
    "الشراعية": 1,
    "جونو": 1,
    "الجوراسي": 1,
    "جوراسي": 1,
    "جور": 1,
    "هيئات": 1,
    "المحلفين": 1,
    "فقهي": 1,
    "القانوني": 1,
    "القانونيون": 1,
    "المحلف": 1,
    "المحلفون": 1,
    "جستر": 1,
    "للتبرير": 1,
    "التبريرات": 1,
    "المبرر": 1,
    "المبررات": 1,
    "يبرر": 1,
    "برر": 1,
    "جستين": 1,
    "بصراع": 1,
    "جستينايان": 1,
    "بمعاهد": 1,
    "قوانين": 1,
    "الرومان": 1,
    "بعدل": 1,
    "الجوت": 1,
    "بروز": 1,
    "البروزات": 1,
    "برز": 1,
    "الحداثة": 1,
    "صفف": 1,
    "يصفف": 1,
    "التصفيف": 1,
    "التراصف": 1,
    "كي": 1,
    "الحادي": 1,
    "كابوم": 1,
    "لجهاز": 1,
    "الأتاري": 1,
    "كابوكي": 1,
    "لدراما": 1,
    "كابول": 1,
    "واكبر": 1,
    "كاديش": 1,
    "يهودية": 1,
    "يومية": 1,
    "كافكا": 1,
    "بفرانز": 1,
    "مؤلفاته": 1,
    "كاجار": 1,
    "حكمت": 1,
    "كاكو": 1,
    "كالامازو": 1,
    "المشكال": 1,
    "متلون": 1,
    "كالميا": 1,
    "كالموك": 1,
    "كامتشاتكا": 1,
    "إنتحاري": 1,
    "الانتحاريون": 1,
    "كامبالا": 1,
    "الكنغر": 1,
    "الكناغر": 1,
    "كانكاكي": 1,
    "كانساس": 1,
    "كانت": 1,
    "الكاولين": 1,
    "الكولنيت": 1,
    "كابلان": 1,
    "الكابوك": 1,
    "كابا": 1,
    "كراتشي": 1,
    "كارامازوف": 1,
    "الكراتيه": 1,
    "القراريط": 1,
    "كارين": 1,
    "كارب": 1,
    "كاسكاسكيا": 1,
    "كايت": 1,
    "كاثلين": 1,
    "كايتي": 1,
    "كاتماندو": 1,
    "كاتوايس": 1,
    "كاز": 1,
    "الكافا": 1,
    "كاي": 1,
    "كايو": 1,
    "كازاخستان": 1,
    "بايت": 1,
    "كيبيبل": 1,
    "قاموس": 1,
    "لافتة": 1,
    "فارغة": 1,
    "كيلو": 1,
    "بت": 1,
    "بايتث": 1,
    "سعرة": 1,
    "زخارف": 1,
    "النوافذ": 1,
    "كرون": 1,
    "كيدي": 1,
    "كلاتغيراسمالمستضيف": 1,
    "كيتون": 1,
    "كيتس": 1,
    "الكباب": 1,
    "فيلة": 1,
    "كيجان": 1,
    "عارضة": 1,
    "القعر": 1,
    "كيلير": 1,
    "عارضات": 1,
    "كيلسون": 1,
    "كينان": 1,
    "حماسا": 1,
    "كينينج": 1,
    "الحرص": 1,
    "العيش": 1,
    "التذكار": 1,
    "كيشوند": 1,
    "كيث": 1,
    "كيلوج": 1,
    "الجدرة": 1,
    "كيلسي": 1,
    "كيلفن": 1,
    "كيمب": 1,
    "كيندل": 1,
    "كينيلورث": 1,
    "كينيكوت": 1,
    "كندي": 1,
    "كينيث": 1,
    "كيني": 1,
    "كينو": 1,
    "كينوشا": 1,
    "كينسينجتون": 1,
    "كينت": 1,
    "كينتن": 1,
    "كنتاكي": 1,
    "كينيا": 1,
    "الكيني": 1,
    "كينيون": 1,
    "كيبلير": 1,
    "أبقى": 1,
    "القرتين": 1,
    "مغطي": 1,
    "بمنديل": 1,
    "كيرميت": 1,
    "إتصالات": 1,
    "كيرن": 1,
    "اللب": 1,
    "الألباب": 1,
    "كيرنيغان": 1,
    "تقليص": 1,
    "النفط": 1,
    "كيري": 1,
    "كيريجما": 1,
    "كيسلير": 1,
    "العاسوق": 1,
    "الكيتون": 1,
    "الكيتوزية": 1,
    "كيترينج": 1,
    "المغلاة": 1,
    "حاسبة": 1,
    "اليورو": 1,
    "كيفين": 1,
    "كواسكم": 1,
    "كووني": 1,
    "بلوحة": 1,
    "الأورغ": 1,
    "مقفول": 1,
    "كييس": 1,
    "ثقوب": 1,
    "مفتاح": 1,
    "مفاتيح": 1,
    "كيينيز": 1,
    "الأساسات": 1,
    "خرم": 1,
    "الخرام": 1,
    "الدليلية": 1,
    "مميزة": 1,
    "كيز": 1,
    "كفلوبي": 1,
    "كيه": 1,
    "السوفيتي": 1,
    "الخاكي": 1,
    "الخمير": 1,
    "خراشتشيف": 1,
    "ذبذبة": 1,
    "إطحن": 1,
    "كيبتزيم": 1,
    "مستوطنة": 1,
    "إستئذان": 1,
    "الركلة": 1,
    "كيكابو": 1,
    "مرفوس": 1,
    "الراكل": 1,
    "الراكلون": 1,
    "الرفس": 1,
    "الركلات": 1,
    "كيد": 1,
    "اختطف": 1,
    "الكلى": 1,
    "ماعز": 1,
    "كيفير": 1,
    "كيل": 1,
    "كيغالي": 1,
    "الكيكيو": 1,
    "كيلجور": 1,
    "الزقزاق": 1,
    "كيليبرو": 1,
    "مقتول": 1,
    "القتلة": 1,
    "هادم": 1,
    "اللذات": 1,
    "الكيلو": 1,
    "البود": 1,
    "إرسال": 1,
    "الكيلوبت": 1,
    "دولار": 1,
    "الكيلوبايت": 1,
    "الكيلوبايتات": 1,
    "بالكيلو": 1,
    "كيلوجوس": 1,
    "الكيلوغرام": 1,
    "الكيلوغرامات": 1,
    "الكيلوهيرتز": 1,
    "وتساوي": 1,
    "اوم": 1,
    "الكيلوجول": 1,
    "الكيلومتر": 1,
    "الكيلومترات": 1,
    "الكيلوطن": 1,
    "طن": 1,
    "كيلوتونس": 1,
    "الكيلوفولت": 1,
    "الواط": 1,
    "واط": 1,
    "كيلوات": 1,
    "التنورة": 1,
    "جيدة": 1,
    "التنورات": 1,
    "كيم": 1,
    "كيمبول": 1,
    "كيمبيرلي": 1,
    "الكيمونو": 1,
    "أرحم": 1,
    "روضة": 1,
    "بطيبة": 1,
    "طيبة": 1,
    "أوقد": 1,
    "يوقد": 1,
    "عطفا": 1,
    "الكينماتيكا": 1,
    "إحساس": 1,
    "تحركات": 1,
    "حركيا": 1,
    "المملكة": 1,
    "تجيء": 1,
    "الممالك": 1,
    "الرفراف": 1,
    "الرفرافات": 1,
    "لمملكة": 1,
    "ملوكي": 1,
    "كينجليك": 1,
    "الملوكية": 1,
    "كينجسبري": 1,
    "كنجسلي": 1,
    "كينجستون": 1,
    "كينجوود": 1,
    "متلوى": 1,
    "أغرب": 1,
    "التلوي": 1,
    "الأقرباء": 1,
    "كينزي": 1,
    "الأهل": 1,
    "القريبة": 1,
    "القريبات": 1,
    "كيوا": 1,
    "كيبلينج": 1,
    "السلمون": 1,
    "كيربي": 1,
    "كيرتشنير": 1,
    "كيرتشوف": 1,
    "كرباتيا": 1,
    "كيرك": 1,
    "كيركلاند": 1,
    "كيركباتريك": 1,
    "كيركوود": 1,
    "كيروف": 1,
    "كيرش": 1,
    "بروندي": 1,
    "القبلة": 1,
    "للتقبيل": 1,
    "مقبل": 1,
    "المقبل": 1,
    "المقبلون": 1,
    "القبل": 1,
    "العدة": 1,
    "كيتاكيشو": 1,
    "كيتشنر": 1,
    "الورقي": 1,
    "الهريرة": 1,
    "قطط": 1,
    "هريرة": 1,
    "الهريرات": 1,
    "البسيسات": 1,
    "دغدغ": 1,
    "البسيسة": 1,
    "الكيفة": 1,
    "قرية": 1,
    "بويبلو": 1,
    "كيفو": 1,
    "رجالية": 1,
    "وتعنى": 1,
    "بخدمة": 1,
    "الكيوي": 1,
    "كو": 1,
    "كلوكس": 1,
    "كلان": 1,
    "الكوكلوكس": 1,
    "كلينيكس": 1,
    "كلين": 1,
    "كلينروك": 1,
    "احرف": 1,
    "كلاين": 1,
    "كلوف": 1,
    "وادي": 1,
    "لورينز": 1,
    "أهوج": 1,
    "لمشكلة": 1,
    "الخرقاء": 1,
    "الكليسترون": 1,
    "ماتريكس": 1,
    "حافظة": 1,
    "فيلم": 1,
    "ناب": 1,
    "ناير": 1,
    "نافيريس": 1,
    "إعجن": 1,
    "للعجن": 1,
    "نيدر": 1,
    "العجن": 1,
    "يعجن": 1,
    "الرضفة": 1,
    "الرضفات": 1,
    "نيد": 1,
    "نيينج": 1,
    "إسجد": 1,
    "ركع": 1,
    "الراكع": 1,
    "يسجد": 1,
    "نيباد": 1,
    "نيبادس": 1,
    "الركب": 1,
    "النعي": 1,
    "دقات": 1,
    "ساجد": 1,
    "نيك": 1,
    "نيكير": 1,
    "الكلسون": 1,
    "الزهيدة": 1,
    "نيفيليك": 1,
    "نايتيد": 1,
    "نايتينج": 1,
    "نايتليلي": 1,
    "نايتلنيس": 1,
    "فروسي": 1,
    "نايتسبردج": 1,
    "يحوك": 1,
    "محيك": 1,
    "نيتير": 1,
    "نيتوير": 1,
    "عقدا": 1,
    "عقدي": 1,
    "قاضي": 1,
    "أرضا": 1,
    "مقرعة": 1,
    "مقارع": 1,
    "القاضية": 1,
    "مبهرة": 1,
    "سميكة": 1,
    "مقروع": 1,
    "نوب": 1,
    "تخرج": 1,
    "نوت": 1,
    "معقود": 1,
    "إعرف": 1,
    "للمعرفة": 1,
    "العارف": 1,
    "بمعرفة": 1,
    "نولز": 1,
    "ناولتون": 1,
    "نوكس": 1,
    "نوكسفيل": 1,
    "قذفة": 1,
    "تلقى": 1,
    "البطيئة": 1,
    "نودسين": 1,
    "أمريكي,": 1,
    "ندسون": 1,
    "مخرش": 1,
    "نث": 1,
    "نتسين": 1,
    "نتسون": 1,
    "الكوالا": 1,
    "كوباياشي": 1,
    "كوتش": 1,
    "كوداكروم": 1,
    "كوداك": 1,
    "كودياك": 1,
    "دب": 1,
    "كودوميتر": 1,
    "كونيج": 1,
    "كوينيجسبيرج": 1,
    "كولر": 1,
    "كوينونيا": 1,
    "كومبو": 1,
    "كونج": 1,
    "كونكاني": 1,
    "ماراثي": 1,
    "لغربي": 1,
    "كونكيورر": 1,
    "خبلا": 1,
    "القرآن": 1,
    "كوريا": 1,
    "الكوريون": 1,
    "للشريعة": 1,
    "مباحا": 1,
    "كوفاكس": 1,
    "كواليوسكي": 1,
    "كوالسكي": 1,
    "كولون": 1,
    "كبف": 1,
    "أشكال": 1,
    "كرايمير": 1,
    "كرافت": 1,
    "كراكاتوا": 1,
    "كراكو": 1,
    "كرامير": 1,
    "كروس": 1,
    "كروت": 1,
    "كريبز": 1,
    "الكرملين": 1,
    "كريسج": 1,
    "كريوزكويميل": 1,
    "كريوزانجس": 1,
    "كريجير": 1,
    "كريشنا": 1,
    "كرونيكير": 1,
    "كرويجير": 1,
    "كروغار": 1,
    "الكريبتون": 1,
    "السعودية": 1,
    "مؤقتك": 1,
    "جامعة": 1,
    "الكويت": 1,
    "جائزة": 1,
    "كدزو": 1,
    "كرمة": 1,
    "شرقية": 1,
    "الكولاكي": 1,
    "البرتقال": 1,
    "الذهبي": 1,
    "كردي": 1,
    "كورت": 1,
    "مدهور": 1,
    "كمستخدم": 1,
    "نقص": 1,
    "البروتين": 1,
    "كايل": 1,
    "كايوتو": 1,
    "قرغيزستان": 1,
    "بذات": 1,
    "وبما": 1,
    "أنزل": 1,
    "سبحانه": 1,
    "وتعالى": 1,
    "إل": 1,
    "لوس": 1,
    "أنجلوس": 1,
    "الطوابع": 1,
    "واضعو": 1,
    "شفوي": 1,
    "شفويا": 1,
    "بالشفة": 1,
    "والأسنان": 1,
    "المختبرات": 1,
    "شغليا": 1,
    "الإجهاد": 1,
    "اللبرادور": 1,
    "الأطلنطي": 1,
    "اللابرادوريت": 1,
    "سليكات": 1,
    "المتاهة": 1,
    "لاك": 1,
    "مزق": 1,
    "يمزق": 1,
    "تمزقي": 1,
    "لاسيرتا": 1,
    "ليسوينج": 1,
    "بالخيوط": 1,
    "دمعي": 1,
    "متكاسل": 1,
    "لاكوانا": 1,
    "لاكير": 1,
    "كخادم": 1,
    "الإفتقار": 1,
    "باهت": 1,
    "اللاكروس": 1,
    "لاكتيت": 1,
    "أفرز": 1,
    "يفرز": 1,
    "إفراز": 1,
    "الإرضاع": 1,
    "بإفراز": 1,
    "لبني": 1,
    "اللاكتوز": 1,
    "بحيري": 1,
    "مخرم": 1,
    "الفتى": 1,
    "السلالم": 1,
    "لاديد": 1,
    "المغرفة": 1,
    "مغروف": 1,
    "المغرفات": 1,
    "الفتيان": 1,
    "الدعسوقة": 1,
    "الخنافس": 1,
    "لاديفرن": 1,
    "بسيدة": 1,
    "لافايت": 1,
    "بيرة": 1,
    "اللاجر": 1,
    "المتخاذل": 1,
    "البطىء": 1,
    "المتخاذلون": 1,
    "متلكأ": 1,
    "البحيرة": 1,
    "البحيرات": 1,
    "لاجوس": 1,
    "نيجيريا": 1,
    "لاغرانج": 1,
    "لاجير": 1,
    "لاجونا": 1,
    "لاهور": 1,
    "ليدلو": 1,
    "منام": 1,
    "لينج": 1,
    "ليرد": 1,
    "ليسيز": 1,
    "العلماني": 1,
    "لاكيهورست": 1,
    "لاكيسيد": 1,
    "لاكيوود": 1,
    "ذوبان": 1,
    "الهيموجلوبين": 1,
    "بلازما": 1,
    "لامار": 1,
    "لامارك": 1,
    "لمدا": 1,
    "النور": 1,
    "الضياء": 1,
    "هفاف": 1,
    "لامبير": 1,
    "اللامبرت": 1,
    "الحملان": 1,
    "السحيف": 1,
    "لاميد": 1,
    "رقائقي": 1,
    "أعرج": 1,
    "رثى": 1,
    "أقمشة": 1,
    "رقق": 1,
    "مرقق": 1,
    "يرقق": 1,
    "الترقق": 1,
    "التصفيح": 1,
    "السناج": 1,
    "شاعل": 1,
    "القناديل": 1,
    "مهجو": 1,
    "الهجو": 1,
    "لامبورت": 1,
    "الجلكى": 1,
    "أنكليسات": 1,
    "مظلة": 1,
    "مظللات": 1,
    "لانا": 1,
    "لانكاشاير": 1,
    "لانكاستر": 1,
    "الرماحون": 1,
    "المركبة": 1,
    "مراكب": 1,
    "اليابسة": 1,
    "لاندولد": 1,
    "مالكو": 1,
    "ملكيات": 1,
    "لانديس": 1,
    "صاحبات": 1,
    "صاحبة": 1,
    "باليابسة": 1,
    "يفقه": 1,
    "البحار": 1,
    "المعالم": 1,
    "المنظر": 1,
    "المناظر": 1,
    "لانج": 1,
    "مصورة": 1,
    "لانجلاند": 1,
    "لانجفورد": 1,
    "لانغلي": 1,
    "لانجميور": 1,
    "بمعاناة": 1,
    "لانكا": 1,
    "نحافة": 1,
    "لانسينج": 1,
    "الفانوس": 1,
    "الفوانيس": 1,
    "لانثانيد": 1,
    "لانثانام": 1,
    "اللاو": 1,
    "لاوكون": 1,
    "قس": 1,
    "طراودة": 1,
    "أولاده": 1,
    "لاووس": 1,
    "اللاوسي": 1,
    "اللاوسيون": 1,
    "بوذية": 1,
    "التلبيب": 1,
    "بياقة": 1,
    "التلابيب": 1,
    "الجواهرجيين": 1,
    "جواهري": 1,
    "لابلاس": 1,
    "بعمليات": 1,
    "متدلية": 1,
    "تزيينية": 1,
    "الأحضان": 1,
    "منتكس": 1,
    "المنهار": 1,
    "الإنتكاس": 1,
    "النقال": 1,
    "الحاسوبات": 1,
    "النقالة": 1,
    "اللابوينج": 1,
    "لارامي": 1,
    "الجهة": 1,
    "اليسرى": 1,
    "السرقات": 1,
    "لصوصي": 1,
    "لصوصية": 1,
    "اللاريس": 1,
    "طبخ": 1,
    "بشحم": 1,
    "لاريدو": 1,
    "الميتون": 1,
    "أنهم": 1,
    "يحمون": 1,
    "لارجموث": 1,
    "سوداء": 1,
    "قليلا": 1,
    "الوهق": 1,
    "القبرة": 1,
    "لاركن": 1,
    "القبرات": 1,
    "الدلفيون": 1,
    "لاري": 1,
    "لارس": 1,
    "لارسين": 1,
    "لارسون": 1,
    "دودي": 1,
    "حنجري": 1,
    "المجون": 1,
    "يصدر": 1,
    "إشعاعات": 1,
    "الليزر": 1,
    "الكرباج": 1,
    "الجلدات": 1,
    "بالسوط": 1,
    "لاسين": 1,
    "الربقة": 1,
    "بالأنشوطة": 1,
    "مدام": 1,
    "المتحمل": 1,
    "لازلو": 1,
    "المزلاج": 1,
    "المزالج": 1,
    "مؤخرا": 1,
    "المستترون": 1,
    "متقدمة": 1,
    "جانبي": 1,
    "كنيسة": 1,
    "لاتيران": 1,
    "لاتيرايت": 1,
    "مطاط": 1,
    "المخرطة": 1,
    "الترغية": 1,
    "المخارط": 1,
    "الخراطة": 1,
    "لاثروب": 1,
    "لاتيني": 1,
    "متحرر": 1,
    "المراحيض": 1,
    "لاتروب": 1,
    "حديثا": 1,
    "عريش": 1,
    "لاتيمير": 1,
    "والأضلاع": 1,
    "لاتفيا": 1,
    "اللاتفي": 1,
    "بالإحترام": 1,
    "بتبجيل": 1,
    "اللودانوم": 1,
    "إطرائي": 1,
    "الممتدح": 1,
    "لوديرديل": 1,
    "معدات": 1,
    "الضاحكون": 1,
    "الإضحوكة": 1,
    "لافلين": 1,
    "القاذفة": 1,
    "الإصدارات": 1,
    "إغسل": 1,
    "الغسالة": 1,
    "الغسالات": 1,
    "المغسلة": 1,
    "المغاسل": 1,
    "المكوى": 1,
    "منظفو": 1,
    "لورا": 1,
    "الفائز": 1,
    "كرم": 1,
    "بوضع": 1,
    "تاج": 1,
    "الغار": 1,
    "الفائزون": 1,
    "لورين": 1,
    "لورنس": 1,
    "لورنت": 1,
    "لورانس": 1,
    "لوري": 1,
    "لوزان": 1,
    "الحمم": 1,
    "الاغتسال": 1,
    "إغتسل": 1,
    "مغتسل": 1,
    "اللافندر": 1,
    "بنبات": 1,
    "اللافيندر": 1,
    "التعطير": 1,
    "التطييب": 1,
    "يغتسل": 1,
    "الإغتسال": 1,
    "مسرف": 1,
    "مغدق": 1,
    "الإغداق": 1,
    "البذخ": 1,
    "لافويسير": 1,
    "صيدلي": 1,
    "منتهك": 1,
    "منتهكي": 1,
    "لوفورد": 1,
    "الشرعية": 1,
    "المشرع": 1,
    "المشرعون": 1,
    "تشريع": 1,
    "المسؤولون": 1,
    "المعاشب": 1,
    "لورينسيفيل": 1,
    "اللورنسيوم": 1,
    "لوسون": 1,
    "الدعوى": 1,
    "الدعاوي": 1,
    "يصير": 1,
    "محاميا": 1,
    "لاكس": 1,
    "لاكساتيف": 1,
    "رخوا": 1,
    "مستلزمات": 1,
    "الوليد": 1,
    "الوضع": 1,
    "المتخصصين": 1,
    "إبان": 1,
    "ليتون": 1,
    "رمية": 1,
    "رميات": 1,
    "لازاروس": 1,
    "الجديد.": 1,
    "أخو": 1,
    "ماري": 1,
    "مارثا": 1,
    "تكاسل": 1,
    "يتكاسل": 1,
    "كسل": 1,
    "كسلا": 1,
    "بتكاسل": 1,
    "كسلان": 1,
    "الكسول": 1,
    "الباون": 1,
    "الباونات": 1,
    "لي": 1,
    "ليتشات": 1,
    "مرصص": 1,
    "رصاصي": 1,
    "الرصاصية": 1,
    "القيادات": 1,
    "قيادي": 1,
    "المصنوع": 1,
    "متقدم": 1,
    "محددو": 1,
    "حشرة": 1,
    "نطاطة": 1,
    "الإيراق": 1,
    "المنظمة": 1,
    "لمنظمة": 1,
    "مسرب": 1,
    "المسرب": 1,
    "نضوحا": 1,
    "التسريب": 1,
    "ليندر": 1,
    "متكأ": 1,
    "طراوة": 1,
    "قفزة": 1,
    "الكبيسة": 1,
    "القافز": 1,
    "يقفز": 1,
    "لير": 1,
    "للتعلم": 1,
    "مكتسبا": 1,
    "المتعلم": 1,
    "المتعلمون": 1,
    "ليري": 1,
    "للتأجير": 1,
    "الإيجار": 1,
    "الأقل": 1,
    "ليذرباك": 1,
    "سلحفاة": 1,
    "مشاة": 1,
    "أورق": 1,
    "مخمر": 1,
    "ليفينورث": 1,
    "التاركون": 1,
    "الأوراق": 1,
    "اللبناني": 1,
    "اللبنانية": 1,
    "لبنان": 1,
    "ليبيسج": 1,
    "داعر": 1,
    "الفجور": 1,
    "الخطابية": 1,
    "سيتم": 1,
    "قرائتها": 1,
    "المحاضرة": 1,
    "محاضر": 1,
    "المحاضرون": 1,
    "المحاضرات": 1,
    "مقاد": 1,
    "سجلات": 1,
    "تطبيق": 1,
    "المستنزف": 1,
    "ليتشيد": 1,
    "المستنزفون": 1,
    "ليدز": 1,
    "الكراث": 1,
    "الخزرة": 1,
    "شابق": 1,
    "إرتيابا": 1,
    "الشبق": 1,
    "الخزر": 1,
    "ليوينهويك": 1,
    "اليسار": 1,
    "إنترنالسبيكير": 1,
    "يسار": 1,
    "اليساريون": 1,
    "يساري": 1,
    "البقية": 1,
    "اليساري": 1,
    "القانونية": 1,
    "المشروعية": 1,
    "قانونيا": 1,
    "ليجاتيد": 1,
    "الإنابة": 1,
    "المفوضيات": 1,
    "منبسط": 1,
    "ومتساوي": 1,
    "أسطوري": 1,
    "ليجيندر": 1,
    "الجحفل": 1,
    "الفيلق": 1,
    "الجحافل": 1,
    "تشريعي": 1,
    "تشريعيا": 1,
    "التشريعي": 1,
    "التشريعية": 1,
    "التقنين": 1,
    "السيقان": 1,
    "بقلي": 1,
    "ليهاي": 1,
    "ليمان": 1,
    "مصرفي": 1,
    "لاي": 1,
    "لايتن": 1,
    "ليلى": 1,
    "ليايزر": 1,
    "المتكررة": 1,
    "ليلند": 1,
    "ليمكي": 1,
    "قضية": 1,
    "تساعد": 1,
    "تسهيل": 1,
    "قضايا": 1,
    "اليرنب": 1,
    "القطبي": 1,
    "اليرانب": 1,
    "ليمل": 1,
    "تفرغ": 1,
    "لعبادة": 1,
    "اللة": 1,
    "لينا": 1,
    "أعر": 1,
    "الإقراض": 1,
    "يعير": 1,
    "تطويلا": 1,
    "الأطوال": 1,
    "طوليا": 1,
    "التساهل": 1,
    "بتساهل": 1,
    "لينين": 1,
    "لينينغراد": 1,
    "اللينينية": 1,
    "اللينيني": 1,
    "لينيكس": 1,
    "ليني": 1,
    "لينور": 1,
    "لينس": 1,
    "بعدسات": 1,
    "لينسير": 1,
    "فوتوغرافيا": 1,
    "صومي": 1,
    "عدسي": 1,
    "العدس": 1,
    "ليو": 1,
    "ليون": 1,
    "ليونا": 1,
    "ليونارد": 1,
    "ليوناردو": 1,
    "ليوند": 1,
    "أسدي": 1,
    "النمر": 1,
    "ليوبولد": 1,
    "ليوبولدفيل": 1,
    "للاعبي": 1,
    "بداء": 1,
    "الجذام": 1,
    "اللبيدوليت": 1,
    "الميكا": 1,
    "جذامي": 1,
    "الإستجابة": 1,
    "للطوارئ": 1,
    "ليروي": 1,
    "السحاقيات": 1,
    "ليزلي": 1,
    "ليسوتو": 1,
    "مستصغر": 1,
    "ليسينج": 1,
    "كاتبة": 1,
    "بريطانية": 1,
    "الدرس": 1,
    "درس": 1,
    "التدريس": 1,
    "خشية": 1,
    "ليستر": 1,
    "دع": 1,
    "الخمود": 1,
    "ليتيتيا": 1,
    "صحافة": 1,
    "رسالة": 1,
    "نوعية": 1,
    "بالحروف": 1,
    "نقاش": 1,
    "المعنونة": 1,
    "الترقين": 1,
    "ليتيرمان": 1,
    "ليتيرمين": 1,
    "الإيجارات": 1,
    "الخس": 1,
    "ليسين": 1,
    "اللوكيميا": 1,
    "ليف": 1,
    "إستوى": 1,
    "المسوون": 1,
    "المسطحون": 1,
    "الإتزان": 1,
    "المسوي": 1,
    "جابي": 1,
    "البرق": 1,
    "ليفين": 1,
    "بيانو": 1,
    "مايسترو": 1,
    "ليفيس": 1,
    "اللاويين": 1,
    "الهزليات": 1,
    "ليفيت": 1,
    "هاديء": 1,
    "لويلين": 1,
    "لويس": 1,
    "ليكس": 1,
    "معجمي": 1,
    "تأليف": 1,
    "المعاجم": 1,
    "ليكسنغتن": 1,
    "ليدن": 1,
    "ليابلي": 1,
    "الكذابون": 1,
    "ليب": 1,
    "ناشر": 1,
    "تشهيري": 1,
    "ليبيريس": 1,
    "التحرري": 1,
    "التحررية": 1,
    "التحرريون": 1,
    "ليبريا": 1,
    "الخليع": 1,
    "الخليعون": 1,
    "شهواني": 1,
    "المقرونة": 1,
    "بالفجور": 1,
    "الميزان": 1,
    "المكتبي": 1,
    "المكتبيون": 1,
    "ليبراريد": 1,
    "ليبرات": 1,
    "نصوص": 1,
    "الأوبرا": 1,
    "نص": 1,
    "ليبريفيل": 1,
    "العاصمة": 1,
    "وأكبر": 1,
    "الجابون": 1,
    "ليبيا": 1,
    "الليبي": 1,
    "الرخصة": 1,
    "أجيز": 1,
    "الحاصل": 1,
    "ترخيص": 1,
    "للترخيص": 1,
    "المرخص": 1,
    "المرخصون": 1,
    "المجيز": 1,
    "مانح": 1,
    "فاجر": 1,
    "الإباحية": 1,
    "الأشنة": 1,
    "بالطحالب": 1,
    "الأشنات": 1,
    "ليتشتينشتاين": 1,
    "ليتختر": 1,
    "اللعقة": 1,
    "ملعوق": 1,
    "اللاعق": 1,
    "اللعق": 1,
    "اللعقات": 1,
    "عرق": 1,
    "السوس": 1,
    "بغطاء": 1,
    "البلاج": 1,
    "ليبرمان": 1,
    "ليختننشتاين": 1,
    "كذب": 1,
    "الولي": 1,
    "لاين": 1,
    "حق": 1,
    "أموال": 1,
    "شريان": 1,
    "الإنقاذ": 1,
    "ميتا": 1,
    "ليفيليسنيس": 1,
    "ليفيليكينيس": 1,
    "ليفر": 1,
    "ليفرس": 1,
    "المنقذ": 1,
    "أسلوب": 1,
    "أساليب": 1,
    "الخول": 1,
    "للرفع": 1,
    "الرافعون": 1,
    "الإقلاع": 1,
    "الأيون": 1,
    "لتشكيل": 1,
    "تعقيدا": 1,
    "إتحادات": 1,
    "الأيونات": 1,
    "ليجت": 1,
    "مسلط": 1,
    "القداحة": 1,
    "القداحات": 1,
    "الإنارات": 1,
    "الخفة": 1,
    "برق": 1,
    "يبرق": 1,
    "القلم": 1,
    "الضوئي": 1,
    "شاشات": 1,
    "الأقلام": 1,
    "لايسمح": 1,
    "بدخول": 1,
    "الأضوية": 1,
    "المنارات": 1,
    "الخفيف": 1,
    "ليجنوم": 1,
    "احتملا": 1,
    "الإمكانيات": 1,
    "مشبه": 1,
    "التشبيه": 1,
    "الأشباه": 1,
    "ليلا": 1,
    "النيلج": 1,
    "الأرجوانية": 1,
    "الفاتحة": 1,
    "ليليان": 1,
    "زنبقي": 1,
    "ليليس": 1,
    "الهزج": 1,
    "نغمي": 1,
    "التغريد": 1,
    "ليم": 1,
    "ليما": 1,
    "ليمبيد": 1,
    "مرشق": 1,
    "الترشيق": 1,
    "الكلس": 1,
    "بالجير": 1,
    "فرن": 1,
    "لحرق": 1,
    "الجير": 1,
    "الساطع": 1,
    "ليمريك": 1,
    "الفكاهية": 1,
    "بمحدودية": 1,
    "الليموزين": 1,
    "ليموزين": 1,
    "العرجة": 1,
    "البطلينوس": 1,
    "ليمكين": 1,
    "العرجات": 1,
    "لينكولن": 1,
    "ليند": 1,
    "ليندا": 1,
    "ليندبيرج": 1,
    "ليندبيرغ": 1,
    "ليندن": 1,
    "ليندولم": 1,
    "ليندكويست": 1,
    "ليندساي": 1,
    "ليندستروم": 1,
    "ليندي": 1,
    "بالخط": 1,
    "الملامح": 1,
    "الخطية": 1,
    "خطية": 1,
    "الظهير": 1,
    "نهايةالسطر": 1,
    "الطابعة": 1,
    "باخرة": 1,
    "الركاب": 1,
    "بواخر": 1,
    "مباعدة": 1,
    "الإصطفافات": 1,
    "لينغالا": 1,
    "المنطوقة": 1,
    "تباطأ": 1,
    "متباطأ": 1,
    "المتسكع": 1,
    "التباطأ": 1,
    "الأمد": 1,
    "يتباطأ": 1,
    "الرطانة": 1,
    "لساني": 1,
    "اللغوي": 1,
    "لغوي": 1,
    "لغويا": 1,
    "اللغويون": 1,
    "المراهم": 1,
    "الزقاقية": 1,
    "مشمع": 1,
    "بذر": 1,
    "الكتاني": 1,
    "عتبة": 1,
    "الهدب": 1,
    "الكتانية": 1,
    "بالكتان": 1,
    "لينوس": 1,
    "لاينكس": 1,
    "المطابق": 1,
    "يونكس.": 1,
    "ألفه": 1,
    "تورفالدس": 1,
    "ليونيل": 1,
    "اللبوة": 1,
    "اللبوات": 1,
    "إحتف": 1,
    "محتفى": 1,
    "المهتم": 1,
    "المهتمون": 1,
    "يحتفي": 1,
    "الإحتفاء": 1,
    "ليببينكوت": 1,
    "شفة": 1,
    "ليبشيز": 1,
    "ليبسكومب": 1,
    "محمرات": 1,
    "ليبتون": 1,
    "المسيل": 1,
    "المسيلون": 1,
    "الكحولي": 1,
    "التصفيات": 1,
    "ليسا": 1,
    "ليزبن": 1,
    "لايز": 1,
    "اللثغة": 1,
    "ملثوغ": 1,
    "باللثغة": 1,
    "اللثغ": 1,
    "لدن": 1,
    "إستمع": 1,
    "مستمع": 1,
    "المستمع": 1,
    "المستمعون": 1,
    "الإستماع": 1,
    "يستمع": 1,
    "المجدولون": 1,
    "متواني": 1,
    "ليستون": 1,
    "امريكي": 1,
    "خسر": 1,
    "بطولة": 1,
    "وزنه": 1,
    "كاسيس": 1,
    "الإبتهالات": 1,
    "اللتر": 1,
    "القراءة": 1,
    "والكتابة": 1,
    "الحرفية": 1,
    "المطبعية": 1,
    "أدبيا": 1,
    "ليترارينيس": 1,
    "الألتار": 1,
    "اللدانة": 1,
    "الليثيوم": 1,
    "الحجرية": 1,
    "المعدن": 1,
    "الطباعات": 1,
    "أديم": 1,
    "بالجزء": 1,
    "ليتوانيا": 1,
    "ليتواني": 1,
    "المشارع": 1,
    "المشارعون": 1,
    "مقاضى": 1,
    "يقاضي": 1,
    "المقاضاة": 1,
    "للتقاضي": 1,
    "يمكنها": 1,
    "تتلون": 1,
    "باللونين": 1,
    "يلقى": 1,
    "بالقاذورات": 1,
    "الملئ": 1,
    "ليتلينيك": 1,
    "ليتلتون": 1,
    "طقوسي": 1,
    "الطقوس": 1,
    "الصلوات": 1,
    "القداس": 1,
    "للعيش": 1,
    "معيش": 1,
    "الإعالة": 1,
    "مكبود": 1,
    "ليفيرمور": 1,
    "ليفربول": 1,
    "بمدينة": 1,
    "ليفيربول": 1,
    "الأكباد": 1,
    "ليفروورت": 1,
    "بالكبدة": 1,
    "الإسطبل": 1,
    "المعيشة": 1,
    "الأرزاق": 1,
    "ليفينجستون": 1,
    "ليفير": 1,
    "كوبيك": 1,
    "ليز": 1,
    "السحلية": 1,
    "السحالي": 1,
    "ليزي": 1,
    "لويد": 1,
    "المحمل": 1,
    "المحملون": 1,
    "الأحمال": 1,
    "المخصبة": 1,
    "الرمل": 1,
    "الفخار": 1,
    "القرض": 1,
    "مقرض": 1,
    "القروض": 1,
    "الأرغفة": 1,
    "المنحنية": 1,
    "مفصص": 1,
    "اللوبيات": 1,
    "منحنية": 1,
    "اللوبي": 1,
    "شحمة": 1,
    "لوبيد": 1,
    "شحمات": 1,
    "الطينية": 1,
    "يخنة": 1,
    "يأكلها": 1,
    "البحارة.": 1,
    "تتكون": 1,
    "والخضروات": 1,
    "سرطان": 1,
    "سرطانات": 1,
    "بشحمة": 1,
    "الموقع": 1,
    "النعرة": 1,
    "الموضعة": 1,
    "يموضع": 1,
    "محليا": 1,
    "لوكاتر": 1,
    "بالموقع": 1,
    "ظرف": 1,
    "ظروف": 1,
    "معينات": 1,
    "لوتشين": 1,
    "الأمكنة": 1,
    "للإحكام": 1,
    "لوكي": 1,
    "المنجد": 1,
    "المناجد": 1,
    "لوكهارت": 1,
    "لوكهيد": 1,
    "لوكينجز": 1,
    "الموظفين": 1,
    "تعاليق": 1,
    "الأقفال": 1,
    "بإيقاع": 1,
    "لوكوود": 1,
    "محامية": 1,
    "يتحرك": 1,
    "التحركات": 1,
    "القاطرة": 1,
    "القاطرات": 1,
    "لوكويد": 1,
    "الجرادة": 1,
    "مغناطيسات": 1,
    "المنتجع": 1,
    "لودجبول": 1,
    "لودويك": 1,
    "لويب": 1,
    "بتعال": 1,
    "لوفتينيس": 1,
    "لوجان": 1,
    "اللوغاريتم": 1,
    "لوغاريتمي": 1,
    "لوغاريثمية": 1,
    "لوغاريثمي": 1,
    "اللوغاريتمات": 1,
    "مقصورة": 1,
    "المسجلات": 1,
    "ونقل": 1,
    "المنطقية": 1,
    "المنطقيون": 1,
    "لعيسى": 1,
    "لوجستيكي": 1,
    "لوجستيكيا": 1,
    "التموين": 1,
    "الإنقطاع": 1,
    "لوجفيو": 1,
    "الخاصرة": 1,
    "مئزر": 1,
    "الخاصرات": 1,
    "لوير": 1,
    "تريث": 1,
    "متريث": 1,
    "التريث": 1,
    "بتواني": 1,
    "يتريث": 1,
    "شارع": 1,
    "خصوصي": 1,
    "الشرير.": 1,
    "إسكندنافية": 1,
    "لولا": 1,
    "لولير": 1,
    "المصاصات": 1,
    "المصاصة": 1,
    "لومبارد": 1,
    "لومباردي": 1,
    "لومي": 1,
    "توجو": 1,
    "ديري": 1,
    "اللندني": 1,
    "البعيدة": 1,
    "أطول": 1,
    "لونج": 1,
    "فيلو": 1,
    "ذوق": 1,
    "الشوق": 1,
    "بشوق": 1,
    "طولي": 1,
    "لونجس": 1,
    "الملاح": 1,
    "ستريت": 1,
    "البعيد": 1,
    "لونا": 1,
    "أنظر": 1,
    "المشاهدة": 1,
    "المرآة": 1,
    "المنوال": 1,
    "ملاح": 1,
    "لوميس": 1,
    "المنوالات": 1,
    "الإرجاع": 1,
    "لوبير": 1,
    "ثغرة": 1,
    "المنافذ": 1,
    "المرخي": 1,
    "الرخاوة": 1,
    "لووس": 1,
    "ستريف": 1,
    "منهوب": 1,
    "شذب": 1,
    "متخطى": 1,
    "لوبس": 1,
    "لوبيز": 1,
    "التخطي": 1,
    "مشذب": 1,
    "التشذيب": 1,
    "يشذب": 1,
    "لوبسيد": 1,
    "أعشاب": 1,
    "الميلان": 1,
    "لوكوات": 1,
    "اللورد": 1,
    "اللوردية": 1,
    "إعوجاج": 1,
    "اللوردات": 1,
    "صفارة": 1,
    "أسطورية": 1,
    "ألمانية": 1,
    "لوريتا": 1,
    "لوريندا": 1,
    "للضياع": 1,
    "إفقد": 1,
    "الخاسر": 1,
    "الخاسرون": 1,
    "الخسران": 1,
    "ياللخسارة": 1,
    "الخسائر": 1,
    "خسائر": 1,
    "بخسارة": 1,
    "المستحضر": 1,
    "المستحضرات": 1,
    "لوت": 1,
    "اليانصيبات": 1,
    "اليانصيب": 1,
    "لوتي": 1,
    "اللوتس": 1,
    "يزيد": 1,
    "تكلم": 1,
    "الصخابة": 1,
    "لويزا": 1,
    "لويز": 1,
    "لويزيانا": 1,
    "لويسيانان": 1,
    "لويسفيل": 1,
    "لونسبري": 1,
    "تجهم": 1,
    "لورد": 1,
    "بلدة": 1,
    "يتجهم": 1,
    "لوسيد": 1,
    "لاوسوورت": 1,
    "الإبتذال": 1,
    "الشريب": 1,
    "جلف": 1,
    "بجلافة": 1,
    "الكوة": 1,
    "المحبوبية": 1,
    "ببغاء": 1,
    "لفجوي": 1,
    "لوفيليس": 1,
    "لفلاند": 1,
    "خالية": 1,
    "الحسن": 1,
    "ملهوف": 1,
    "لافرينج": 1,
    "الأحباء": 1,
    "ولهان": 1,
    "تشتت": 1,
    "جراء": 1,
    "الواطئ": 1,
    "واطئ": 1,
    "لوبوي": 1,
    "لاو": 1,
    "لويل": 1,
    "أوطأ": 1,
    "الأحرف": 1,
    "إنخفض": 1,
    "ينخفض": 1,
    "الأوطأ": 1,
    "السهل": 1,
    "سهلي": 1,
    "تواضعا": 1,
    "سار": 1,
    "الوطوء": 1,
    "الواطئة": 1,
    "الصخرية": 1,
    "موالي": 1,
    "الموالي": 1,
    "الموالون": 1,
    "باخلاص": 1,
    "لويولا": 1,
    "الواسع": 1,
    "التكتيكية": 1,
    "عديمي": 1,
    "لوبوك": 1,
    "لبيل": 1,
    "زيت": 1,
    "زيوت": 1,
    "مزيت": 1,
    "يزيت": 1,
    "التزييت": 1,
    "لوكاس": 1,
    "لوسينت": 1,
    "بلمعان": 1,
    "الحجازي": 1,
    "لوسيا": 1,
    "لوتشيان": 1,
    "مشرق": 1,
    "لوسدا": 1,
    "لوسين": 1,
    "الإبليس": 1,
    "لوسيل": 1,
    "لوتشيوس": 1,
    "حالفه": 1,
    "حظا": 1,
    "الحظوظ": 1,
    "الربحية": 1,
    "لوكريشيا": 1,
    "لوكريتيوس": 1,
    "لوسي": 1,
    "محطمو": 1,
    "خوفا": 1,
    "إحلال": 1,
    "بسخافة": 1,
    "لدلو": 1,
    "لودفيج": 1,
    "در": 1,
    "اللوفتهانزا": 1,
    "لوفتوافا": 1,
    "مزلاجة": 1,
    "متسابق": 1,
    "بمزلاجة": 1,
    "العروات": 1,
    "لوك": 1,
    "لوكيميا": 1,
    "التهويدات": 1,
    "التهويدة": 1,
    "لولو": 1,
    "ألم": 1,
    "الحطاب": 1,
    "الحطابون": 1,
    "لومنيسسينس": 1,
    "مناول": 1,
    "البضائع": 1,
    "كتلا": 1,
    "لومبور": 1,
    "متكتل": 1,
    "لانات": 1,
    "لاناتيد": 1,
    "لاناتيلي": 1,
    "القمري": 1,
    "متغدى": 1,
    "لانتشير": 1,
    "لانتشهور": 1,
    "التغدي": 1,
    "لاند": 1,
    "لاندبيرج": 1,
    "لاندكويست": 1,
    "الرئة": 1,
    "الطعنة": 1,
    "لانجير": 1,
    "الطعنات": 1,
    "الرئتين": 1,
    "الترمس": 1,
    "الجواس": 1,
    "بشحوب": 1,
    "باستدراج": 1,
    "ترصد": 1,
    "مترصد": 1,
    "لوركير": 1,
    "التسنط": 1,
    "يترصد": 1,
    "لاساكا": 1,
    "زامبيا": 1,
    "حلاوة": 1,
    "الألماس": 1,
    "البريق": 1,
    "شهوانية": 1,
    "بشهوة": 1,
    "اﻷلماس": 1,
    "العود": 1,
    "مطين": 1,
    "لوتيتيم": 1,
    "لوثر": 1,
    "لوثري": 1,
    "التطيين": 1,
    "لوز": 1,
    "لوكسمبورغ": 1,
    "لكس": 1,
    "الثراء": 1,
    "بثراء": 1,
    "مرفه": 1,
    "الرفاهيات": 1,
    "بترف": 1,
    "الرفاهية": 1,
    "الترف": 1,
    "لوزون": 1,
    "صالة": 1,
    "صالات": 1,
    "ليكوبوديوم": 1,
    "ليديا": 1,
    "الغسول": 1,
    "ليكيز": 1,
    "لايل": 1,
    "اللمف": 1,
    "ليمفاويين": 1,
    "اللمفية": 1,
    "أعدم": 1,
    "لنتشبيرغ": 1,
    "تفويض": 1,
    "يعدم": 1,
    "لندون": 1,
    "الوشق": 1,
    "الوشوق": 1,
    "ليرا": 1,
    "الغنائية": 1,
    "غنائي": 1,
    "ليريكالنيس": 1,
    "ليسينكو": 1,
    "لسيرجيك": 1,
    "اوتوماتيكي": 1,
    "لتبادل": 1,
    "وتوزيع": 1,
    "أم": 1,
    "مايبيل": 1,
    "الماك": 1,
    "إرصف": 1,
    "يرصف": 1,
    "الرصف": 1,
    "المكاك": 1,
    "آسيوي": 1,
    "معكرونة": 1,
    "المعكرون": 1,
    "حلوى": 1,
    "وسكر": 1,
    "ولوز": 1,
    "ماكآرثر": 1,
    "لمدينة": 1,
    "Ujung": 1,
    "Pandang": 1,
    "بإندونيسيا": 1,
    "ماكو": 1,
    "ماكولاي": 1,
    "ماكبيث": 1,
    "ماكدونالد": 1,
    "ماكدوجال": 1,
    "ماكدراو": 1,
    "الصولجان": 1,
    "ماسيد": 1,
    "مملكة": 1,
    "إغريقية": 1,
    "مقدونيا": 1,
    "المقدوني": 1,
    "نقع": 1,
    "ينقع": 1,
    "تنقيع": 1,
    "ذبول": 1,
    "الصولجانات": 1,
    "ماكجريجور": 1,
    "الماخي": 1,
    "المنجل": 1,
    "ميكافيللي": 1,
    "المنسوبين": 1,
    "ألالة": 1,
    "المكيدة": 1,
    "مكائد": 1,
    "المكائد": 1,
    "الرشاشة": 1,
    "المكنية": 1,
    "يدار": 1,
    "كالآلة": 1,
    "المكائن": 1,
    "مخرطة": 1,
    "الماكنة": 1,
    "مشغلو": 1,
    "بالعضلات": 1,
    "مفتول": 1,
    "بسائل": 1,
    "الماكنتوش": 1,
    "المشمع": 1,
    "ماكينزي": 1,
    "الإسقمري": 1,
    "الأسقمري": 1,
    "ماكي": 1,
    "مضيق": 1,
    "الماكيناوي": 1,
    "Mackintosh": 1,
    "Mackintoshs": 1,
    "ماكميلان": 1,
    "ماكون": 1,
    "ماكبينت": 1,
    "ماكرو": 1,
    "مسؤولة": 1,
    "مختصرات": 1,
    "عياني": 1,
    "واضحا": 1,
    "بنية": 1,
    "ترى": 1,
    "بالعين": 1,
    "تلوث": 1,
    "مدغشقر": 1,
    "مادكاب": 1,
    "الطائشون": 1,
    "خبل": 1,
    "ماديند": 1,
    "مخبل": 1,
    "التخبيل": 1,
    "يخبل": 1,
    "الفوة": 1,
    "مادوكس": 1,
    "تشخيصا": 1,
    "بإنزال": 1,
    "كتبت": 1,
    "فراق": 1,
    "جعلت": 1,
    "المقفر": 1,
    "مقفرا": 1,
    "حبلى": 1,
    "تأكد": 1,
    "أسوأ": 1,
    "الماديرا": 1,
    "الخمرة": 1,
    "ماديرا": 1,
    "(على": 1,
    "أفريقة": 1,
    "الغربي)": 1,
    "مادلين": 1,
    "آنسة": 1,
    "الآنسات": 1,
    "مايج": 1,
    "مستشفى": 1,
    "ماديسن": 1,
    "سيدات": 1,
    "للملابس": 1,
    "السجف": 1,
    "مدريد": 1,
    "غزلية": 1,
    "يتغزل": 1,
    "المجنونة": 1,
    "ماي": 1,
    "إصخب": 1,
    "المافيا": 1,
    "ماجامب": 1,
    "اللاحق": 1,
    "مريم": 1,
    "المجدلية": 1,
    "ماجي": 1,
    "يرقات": 1,
    "المجوس": 1,
    "السحري": 1,
    "السحرية": 1,
    "سحرية": 1,
    "ماجيل": 1,
    "الصلح": 1,
    "الحاكمية": 1,
    "البركانية": 1,
    "الذائبة": 1,
    "ماجنا": 1,
    "الشهامة": 1,
    "شهم": 1,
    "شهامة": 1,
    "المغنيسيوم": 1,
    "كربونات": 1,
    "المغنسيوم": 1,
    "مغناطيسي": 1,
    "triped": 1,
    "مغناطيسيا": 1,
    "للمغنطة": 1,
    "مغنطة": 1,
    "مغنط": 1,
    "ممغنط": 1,
    "يمغنط": 1,
    "التمغنط": 1,
    "مولدات": 1,
    "صمام": 1,
    "تكبير": 1,
    "بارزة": 1,
    "عدسة": 1,
    "مكبرة": 1,
    "ماغنوليا": 1,
    "قناني": 1,
    "ماجوج": 1,
    "العقعق": 1,
    "مهايانية": 1,
    "شعبة": 1,
    "تقول": 1,
    "مهاياني": 1,
    "ماهلير": 1,
    "الماهوغوني": 1,
    "ماهوني": 1,
    "عذراء": 1,
    "أسرة": 1,
    "سفرة": 1,
    "كزبرة": 1,
    "بكارة": 1,
    "حياء": 1,
    "عذري": 1,
    "عوانس": 1,
    "جواري": 1,
    "خادمة": 1,
    "مير": 1,
    "(اسم": 1,
    "علم)": 1,
    "بالبريد": 1,
    "إرساله": 1,
    "بالإمكان": 1,
    "البريدي": 1,
    "البريدية": 1,
    "ميلجرام": 1,
    "للبرقية": 1,
    "ساعي": 1,
    "سعاة": 1,
    "تشوه": 1,
    "مشوهون": 1,
    "يعوق": 1,
    "بند": 1,
    "المفاوضات": 1,
    "الموضوع": 1,
    "مين": 1,
    "إلكترونية": 1,
    "الكبرى": 1,
    "مينك": 1,
    "حقن": 1,
    "مأخذ": 1,
    "شراع": 1,
    "النابض": 1,
    "النوابض": 1,
    "الركن": 1,
    "الأساسي": 1,
    "الأركان": 1,
    "أبق": 1,
    "للدعم": 1,
    "مبقى": 1,
    "الإبقاء": 1,
    "صيانة": 1,
    "المهيبة": 1,
    "الأغلبيات": 1,
    "الأغلبية": 1,
    "أغلبية": 1,
    "كبرىصغرى": 1,
    "بالغون": 1,
    "تحقيقه": 1,
    "يشارك": 1,
    "بالملاطفة": 1,
    "يجامع": 1,
    "زلة": 1,
    "أعداء": 1,
    "نظير": 1,
    "أعذارا": 1,
    "سقط": 1,
    "خجلانا": 1,
    "ازدهر": 1,
    "ضوضاء": 1,
    "هروب": 1,
    "قم": 1,
    "جعلها": 1,
    "دعاية": 1,
    "وقتا": 1,
    "تركيب": 1,
    "Makefile": 1,
    "Makefiles": 1,
    "الطرازات": 1,
    "إرضاء": 1,
    "طرازات": 1,
    "صعبة": 1,
    "تزدهر": 1,
    "آمنة": 1,
    "غبية": 1,
    "متأكدة": 1,
    "البدلاء": 1,
    "المؤقتون": 1,
    "تجعل": 1,
    "أعذار": 1,
    "المتطلبات": 1,
    "التهيؤ": 1,
    "مهايئ": 1,
    "انحرافات": 1,
    "مدغشقري": 1,
    "توعك": 1,
    "مالامود": 1,
    "مسيء": 1,
    "الملاريا": 1,
    "ملاريا": 1,
    "ملاوي": 1,
    "ملايي": 1,
    "المالايالام": 1,
    "ماليزيا": 1,
    "الماليزي": 1,
    "مالكولم": 1,
    "ولايات": 1,
    "مالديفز": 1,
    "قطة": 1,
    "ممرض": 1,
    "مسبة": 1,
    "مسبات": 1,
    "أشرار": 1,
    "ذكورة": 1,
    "حقد": 1,
    "أحقاد": 1,
    "ارتكاب": 1,
    "يتعطل": 1,
    "ماليبو": 1,
    "بهجة": 1,
    "خبيثة": 1,
    "خبث": 1,
    "خباثة": 1,
    "طعن": 1,
    "تمارض": 1,
    "متمارض": 1,
    "يتمارض": 1,
    "التسوق": 1,
    "قابليات": 1,
    "التطريق": 1,
    "مطواع": 1,
    "مطرقة": 1,
    "مطارق": 1,
    "مالوري": 1,
    "الخبازي": 1,
    "تناغم": 1,
    "سنين": 1,
    "متقابلين": 1,
    "نتانة": 1,
    "منتن": 1,
    "مالون": 1,
    "طاحونة": 1,
    "شعير": 1,
    "مالطا": 1,
    "مملت": 1,
    "مالطي": 1,
    "مالثوسي": 1,
    "بالشعير": 1,
    "يعامل": 1,
    "معاملة": 1,
    "مامبو": 1,
    "رقصات": 1,
    "الثديي": 1,
    "الثدييات": 1,
    "الأمهات": 1,
    "الماموث": 1,
    "مجسدة": 1,
    "غل": 1,
    "الانقياد": 1,
    "مقود": 1,
    "مديرة": 1,
    "نيكاراجوا": 1,
    "المنامه": 1,
    "ماناتي": 1,
    "مانشستر": 1,
    "المؤن": 1,
    "أصدر": 1,
    "أوامر": 1,
    "الأفندي": 1,
    "الأفندية": 1,
    "التفويضات": 1,
    "التخويل": 1,
    "منتدبون": 1,
    "إشارة": 1,
    "إلزامية": 1,
    "ماندلبروت": 1,
    "Mandrake": 1,
    "اللفاحات": 1,
    "MandrakeSoft": 1,
    "(عرف": 1,
    "الديك)": 1,
    "مدرسة": 1,
    "أعراف": 1,
    "مناورة": 1,
    "ناور": 1,
    "مناور": 1,
    "مناورات": 1,
    "منغنيز": 1,
    "الجرب": 1,
    "شوندر": 1,
    "المعلف": 1,
    "المعالف": 1,
    "أجرب": 1,
    "بجرب": 1,
    "يعصر": 1,
    "بالضغط": 1,
    "معصرة": 1,
    "المانجا": 1,
    "قرامات": 1,
    "البدنية": 1,
    "كوكتيل": 1,
    "مانهاتان": 1,
    "فتحات": 1,
    "الرجولة": 1,
    "مطاردة": 1,
    "هوس": 1,
    "معتوه": 1,
    "جنوني": 1,
    "هوسي": 1,
    "مدرم": 1,
    "مدرمات": 1,
    "الإظهار": 1,
    "الأنبوب": 1,
    "المتفرع": 1,
    "المنوع": 1,
    "تنوع": 1,
    "الأنابيب": 1,
    "المتفرعة": 1,
    "مانيلا": 1,
    "للظروف": 1,
    "المحيطه": 1,
    "بتعديله": 1,
    "أوتحويره": 1,
    "بهدف": 1,
    "التلاعب": 1,
    "مانيتوبا": 1,
    "مانيتووك": 1,
    "مانكوسكي": 1,
    "نيبراسكا": 1,
    "رجولة": 1,
    "رجولي": 1,
    "مان": 1,
    "المعرضة": 1,
    "الإسلوب": 1,
    "إسلوب": 1,
    "آداب": 1,
    "تهذيب": 1,
    "بالرجال": 1,
    "مسترجلة": 1,
    "المانومتر": 1,
    "المانومترات": 1,
    "الضيعة": 1,
    "الضيع": 1,
    "يدور": 1,
    "مانسفيلد": 1,
    "المتعمد": 1,
    "مانتا": 1,
    "رف": 1,
    "المستوقد": 1,
    "رفوف": 1,
    "تكهني": 1,
    "النبي": 1,
    "الشرك": 1,
    "تلقيم": 1,
    "مانويل": 1,
    "المنتج": 1,
    "المنتجون": 1,
    "تصنيع": 1,
    "سماد": 1,
    "أسمدة": 1,
    "الأسمدة": 1,
    "المخطوطة": 1,
    "المخطوطات": 1,
    "الكثير": 1,
    "تعدد": 1,
    "الأوقات": 1,
    "جهوري": 1,
    "مانزانيتا": 1,
    "ماو": 1,
    "ماوري": 1,
    "القيقب": 1,
    "نيو": 1,
    "يورك": 1,
    "(الأسفندان)": 1,
    "المخططون": 1,
    "مارس/آذار": 1,
    "الماراثون": 1,
    "الماراثونات": 1,
    "الرخام": 1,
    "رخامي": 1,
    "مرخم": 1,
    "الرخامات": 1,
    "بالرخام": 1,
    "مارك": 1,
    "مارسيل": 1,
    "مارسيلو": 1,
    "اخرج": 1,
    "استعراض": 1,
    "قبالة": 1,
    "سير": 1,
    "أمام": 1,
    "مارسيا": 1,
    "ماركو": 1,
    "ماركوت": 1,
    "ماركوس": 1,
    "مارسي": 1,
    "ماردي": 1,
    "مارديس": 1,
    "مارجريت": 1,
    "زبد": 1,
    "النباتي": 1,
    "مارجيري": 1,
    "هامش": 1,
    "هامشي": 1,
    "مشكلة": 1,
    "هامشية": 1,
    "تلويحات": 1,
    "تهميش": 1,
    "البسيط": 1,
    "بهامش": 1,
    "التهميش": 1,
    "مارجو": 1,
    "اللؤلؤ": 1,
    "ماريا": 1,
    "مريمي": 1,
    "ماريان": 1,
    "ماريتا": 1,
    "المخملية": 1,
    "مارجوانا": 1,
    "عشبة": 1,
    "مخدرة": 1,
    "مارلين": 1,
    "ماريمبا": 1,
    "مارين": 1,
    "لرسو": 1,
    "إصلاحها": 1,
    "مارينو": 1,
    "ماريو": 1,
    "ماريون": 1,
    "المنزلة": 1,
    "الأمة": 1,
    "المرور": 1,
    "السمسق": 1,
    "(نبات": 1,
    "عطري)": 1,
    "مؤنث": 1,
    "مرجعية": 1,
    "(شيئا": 1,
    "آخر)": 1,
    "بحد": 1,
    "للوسم": 1,
    "الملحوظ": 1,
    "السوق": 1,
    "تحليلات": 1,
    "سوق": 1,
    "شروط": 1,
    "إقتصاديات": 1,
    "إقتصاد": 1,
    "خضروات": 1,
    "سعر": 1,
    "تقرير": 1,
    "التسويق": 1,
    "رائج": 1,
    "مسوق": 1,
    "المسوق": 1,
    "المبيعات": 1,
    "الاسواق": 1,
    "أسواق": 1,
    "تأشير": 1,
    "ماركوف": 1,
    "روسي": 1,
    "الماركوفية": 1,
    "رماة": 1,
    "الكلسي": 1,
    "مارلبورو": 1,
    "مارلن": 1,
    "مارلو": 1,
    "مربى": 1,
    "الغرير": 1,
    "الخيمة": 1,
    "الخيمات": 1,
    "المركيز": 1,
    "نبلاء": 1,
    "أوروبة": 1,
    "واليابان": 1,
    "ماركيه": 1,
    "المركيزة": 1,
    "المركيزات": 1,
    "زواج": 1,
    "مراسيم": 1,
    "مراسم": 1,
    "توجيه": 1,
    "منتحل": 1,
    "خطبة": 1,
    "للزواج": 1,
    "أهلية": 1,
    "الزيجات": 1,
    "والزوجة": 1,
    "المتزوجة": 1,
    "يتزوج": 1,
    "ماريوت": 1,
    "نخاع": 1,
    "العظم": 1,
    "النخاعي": 1,
    "النخاعية": 1,
    "نخاعات": 1,
    "نخاعي": 1,
    "تزوج": 1,
    "المريخ": 1,
    "مارسيليس": 1,
    "مستنقع": 1,
    "مارشا": 1,
    "منظمون": 1,
    "مارشال": 1,
    "رتبة": 1,
    "عسكرية": 1,
    "الفرز": 1,
    "الهور": 1,
    "الأهوار": 1,
    "الخبازية": 1,
    "المستنقعية": 1,
    "جرابي": 1,
    "الجرابيون": 1,
    "السمور": 1,
    "الفولاذ": 1,
    "الصلد": 1,
    "عسكري": 1,
    "العرفي": 1,
    "مريخي": 1,
    "المريخيون": 1,
    "مارتن": 1,
    "مارتينيز": 1,
    "مارتينجال": 1,
    "المارتيني": 1,
    "مارتينيكوي": 1,
    "مارتينسون": 1,
    "الأسواق": 1,
    "مارتي": 1,
    "الشهيد": 1,
    "شهيد": 1,
    "الشهداء": 1,
    "الأعجوبة": 1,
    "متعجب": 1,
    "الأعاجيب": 1,
    "مارفن": 1,
    "ماركس": 1,
    "الماركسية": 1,
    "الماركسي": 1,
    "ميريلاند": 1,
    "مجملات": 1,
    "يظن": 1,
    "فحولة": 1,
    "المازر": 1,
    "لتضخيم": 1,
    "النبضات": 1,
    "بابتعاث": 1,
    "وحفزها": 1,
    "ماسيرو": 1,
    "الهريس": 1,
    "مزيح": 1,
    "حنطة": 1,
    "ونخالة": 1,
    "وماء": 1,
    "(يقدم": 1,
    "طعاما": 1,
    "للماشية)": 1,
    "هرس": 1,
    "بطاطا": 1,
    "مهروسة": 1,
    "هراسة": 1,
    "هراسات": 1,
    "الهرائس": 1,
    "يهرس": 1,
    "الهريسة": 1,
    "قناع": 1,
    "مبرمج": 1,
    "مقنعا": 1,
    "التنكرية": 1,
    "المشاهد": 1,
    "أقنعة": 1,
    "الماسوشية": 1,
    "المستألم": 1,
    "ماسوشي": 1,
    "سادي": 1,
    "التلذذ": 1,
    "بالألم": 1,
    "المستألمون": 1,
    "يبني": 1,
    "مصنوعة": 1,
    "ألوح": 1,
    "المضغوطة": 1,
    "مباني": 1,
    "تنكر": 1,
    "ألمتخفي": 1,
    "مقنعون": 1,
    "تمثيليات": 1,
    "الانتاج": 1,
    "الشامل": 1,
    "تحشد": 1,
    "سوية": 1,
    "ماسوشوستس": 1,
    "مذابح": 1,
    "مدلك": 1,
    "المتجمع": 1,
    "جماهير": 1,
    "ماسي": 1,
    "كتل": 1,
    "التحشد": 1,
    "ماسينجر": 1,
    "مقال": 1,
    "بالجملة": 1,
    "بسارية": 1,
    "أستاذ": 1,
    "خياط": 1,
    "بمهارة": 1,
    "براعة": 1,
    "بحكمة": 1,
    "المتقن": 1,
    "العقول": 1,
    "الموجهة": 1,
    "النادر": 1,
    "الإجادة": 1,
    "ماستيك": 1,
    "للمضغ": 1,
    "مضغ": 1,
    "ماضغ": 1,
    "الدرواس": 1,
    "الصناجة": 1,
    "الصناجات": 1,
    "سواري": 1,
    "يستمني": 1,
    "استمنى": 1,
    "استمناء": 1,
    "بالأستمناء": 1,
    "حصير": 1,
    "مباراة": 1,
    "الثقاب": 1,
    "متناظر": 1,
    "مباريات": 1,
    "مواءمة": 1,
    "مواءمات": 1,
    "ماتيو": 1,
    "المادي": 1,
    "بمادية": 1,
    "ماديون": 1,
    "متحقق": 1,
    "مجسدون": 1,
    "ماديا": 1,
    "حسي": 1,
    "والتجهيزات": 1,
    "أمومي": 1,
    "الأمومية": 1,
    "الأمومة": 1,
    "الرياضيات": 1,
    "رياضيا": 1,
    "ماثيوز": 1,
    "ماثيوسون": 1,
    "ماثياس": 1,
    "ماذييو": 1,
    "ماتيلدا": 1,
    "ماتينال": 1,
    "النهارية": 1,
    "صلوات": 1,
    "ماتيسي": 1,
    "الأمومي": 1,
    "مصفوفات": 1,
    "طالبا": 1,
    "كلية": 1,
    "امتحان": 1,
    "إلخ": 1,
    "امتحانات": 1,
    "بجامعة": 1,
    "زيجات": 1,
    "خانات": 1,
    "نسوية)": 1,
    "ماتسون": 1,
    "ماتسوموتو": 1,
    "نحاس": 1,
    "ورصاص": 1,
    "ونيكل": 1,
    "ماثيو": 1,
    "ماتيوس": 1,
    "ماتي": 1,
    "حصيرة": 1,
    "معول": 1,
    "معاول": 1,
    "مفارش": 1,
    "ينضج": 1,
    "نضوج": 1,
    "بالنضوج": 1,
    "نضج": 1,
    "استحقاقات": 1,
    "صباحي": 1,
    "فطير": 1,
    "(يأكله": 1,
    "فصحهم)": 1,
    "مود": 1,
    "قوية": 1,
    "إهذ": 1,
    "مورين": 1,
    "موريس": 1,
    "موريسيو": 1,
    "موريتانيا": 1,
    "موريشيوس": 1,
    "الضريح": 1,
    "الأضرحة": 1,
    "البنفسجي": 1,
    "مخضرم": 1,
    "المستفرد": 1,
    "المستفردون": 1,
    "المطربة": 1,
    "مقزز": 1,
    "مثيرة": 1,
    "للغثيان": 1,
    "تقزز": 1,
    "مار": 1,
    "ماكس": 1,
    "ماكسيلاري": 1,
    "حكمة": 1,
    "سائر": 1,
    "بالحد": 1,
    "عماة": 1,
    "المكبرون": 1,
    "أمثال": 1,
    "سائرة": 1,
    "ماكسين": 1,
    "ماكستور": 1,
    "ماكسويل": 1,
    "مايو": 1,
    "المايا": 1,
    "يقطن": 1,
    "وغواتيمالا": 1,
    "والمكسيك": 1,
    "بيضي": 1,
    "ماير": 1,
    "مايفير": 1,
    "ألأناقة": 1,
    "ضرر": 1,
    "الاحتفال": 1,
    "ماينارد": 1,
    "ميونيز": 1,
    "الميونيز": 1,
    "كثيقة": 1,
    "صفار": 1,
    "المخفوق": 1,
    "والخل": 1,
    "والزيت": 1,
    "والتوابل": 1,
    "البلدية": 1,
    "رئاسة": 1,
    "الناغط": 1,
    "الناغطون": 1,
    "مايست": 1,
    "مازدا": 1,
    "للسيارات": 1,
    "ألحيره": 1,
    "طاس": 1,
    "المتاهات": 1,
    "المازوركا": 1,
    "ميغابايت": 1,
    "ماجستير": 1,
    "ادارة": 1,
    "سوازيلاند": 1,
    "ميجابايت": 1,
    "مكأدامس": 1,
    "مكاليستر": 1,
    "مسبرد": 1,
    "مكاب": 1,
    "مكال": 1,
    "مكالوم": 1,
    "مكارثي": 1,
    "مكارتي": 1,
    "مكولي": 1,
    "مكلين": 1,
    "مكليلان": 1,
    "مكلوسكي": 1,
    "مكونيل": 1,
    "مكورميك": 1,
    "مكراكين": 1,
    "مكولوغ": 1,
    "مكدانيل": 1,
    "مكدرموت": 1,
    "مكدونيل": 1,
    "مكدوجال": 1,
    "مكدويل": 1,
    "مسيلهاني": 1,
    "مسيلروي": 1,
    "مكفادين": 1,
    "مكفارلاند": 1,
    "مكجي": 1,
    "مكجيل": 1,
    "ماجينيز": 1,
    "مكغوفيرن": 1,
    "مكغووان": 1,
    "مكجراث": 1,
    "مكجراو": 1,
    "مكجريجور": 1,
    "مكغواير": 1,
    "متشوغ": 1,
    "مسينتوش": 1,
    "مسينتير": 1,
    "مكاي": 1,
    "مكي": 1,
    "ماكينا": 1,
    "ماكنزي": 1,
    "مكيون": 1,
    "مكيسون": 1,
    "مكينلي": 1,
    "مكينني": 1,
    "مكنايت": 1,
    "مكلاناهان": 1,
    "مكلوغلن": 1,
    "ماكلين": 1,
    "مكليود": 1,
    "مكماهون": 1,
    "مكمارتين": 1,
    "مكميلان": 1,
    "مكمولين": 1,
    "مسنالي": 1,
    "مسناوجهتون": 1,
    "مسنيل": 1,
    "مسنولتي": 1,
    "مكفرسون": 1,
    "المتعددة": 1,
    "ني": 1,
    "عسل": 1,
    "وملت": 1,
    "وخميرة": 1,
    "ميدولاند": 1,
    "ميدولارك": 1,
    "إكليلية": 1,
    "ميدوي": 1,
    "أشربة": 1,
    "ضآلة": 1,
    "وجبة": 1,
    "أكواز": 1,
    "أشحب": 1,
    "طحيني": 1,
    "أحاديث": 1,
    "ممطوطة": 1,
    "تعرجات": 1,
    "بخلا": 1,
    "المعنى": 1,
    "مغزى": 1,
    "المعاني": 1,
    "عنى": 1,
    "الأثناء": 1,
    "حصبة": 1,
    "متحصب": 1,
    "الحصبة": 1,
    "كيال": 1,
    "اجراءات": 1,
    "ميتبال": 1,
    "لحما": 1,
    "لحمي": 1,
    "مكة": 1,
    "المكرمة": 1,
    "ميكانيكيا": 1,
    "ميكانيكيون": 1,
    "ميكانيكا": 1,
    "آليات": 1,
    "مختصون": 1,
    "بالآلات": 1,
    "المكننة": 1,
    "يمكنن": 1,
    "بالمعدات": 1,
    "الميكانيكية": 1,
    "مجهزوا": 1,
    "العقي": 1,
    "داكنة": 1,
    "المولود": 1,
    "ولادته": 1,
    "ميكم": 1,
    "أعطي": 1,
    "ميدالية": 1,
    "اعطاء": 1,
    "النوط": 1,
    "الأنواط": 1,
    "ميديا": 1,
    "ميدفيلد": 1,
    "ميدفورد": 1,
    "إعلامي": 1,
    "الأوساط": 1,
    "بتوسط": 1,
    "وساطة": 1,
    "وساطات": 1,
    "وسيط": 1,
    "وسطاء": 1,
    "طبية": 1,
    "طبيا": 1,
    "ميديسي": 1,
    "لمؤسسات": 1,
    "وفنونها": 1,
    "وأعرافها": 1,
    "بتاريخ": 1,
    "وثقافتها": 1,
    "معجبون": 1,
    "توسطات": 1,
    "تأملات": 1,
    "متوسطات": 1,
    "المشملة": 1,
    "مزيج": 1,
    "مزائج": 1,
    "النخاع": 1,
    "وديع": 1,
    "أحلم": 1,
    "وداعة": 1,
    "ميتر": 1,
    "مصلى": 1,
    "(عند": 1,
    "البروتوستانت": 1,
    "خاصة)": 1,
    "ميج": 1,
    "الميغابايت": 1,
    "الميغابايتات": 1,
    "دورة": 1,
    "هرتز": 1,
    "جنون": 1,
    "مدن": 1,
    "ميجاواط": 1,
    "أوم": 1,
    "انقسام": 1,
    "ميستر": 1,
    "ميكونج": 1,
    "ميلامين": 1,
    "بالسوداء": 1,
    "سوداوي": 1,
    "ميلانيزيا": 1,
    "ميلانيزي": 1,
    "ميلاني": 1,
    "ميلانين": 1,
    "الملاني": 1,
    "ميلبورن": 1,
    "ميلتشر": 1,
    "إخلط": 1,
    "مليندا": 1,
    "تحسينات": 1,
    "ميليسا": 1,
    "سلاسة": 1,
    "ميلون": 1,
    "أنضج": 1,
    "يلين": 1,
    "لحني": 1,
    "بالنغم": 1,
    "لحن": 1,
    "ميلودراما": 1,
    "ميلودرامي": 1,
    "ميلودرامية": 1,
    "(مثير)": 1,
    "بالعمل": 1,
    "النغم": 1,
    "البطيخ": 1,
    "المأساة": 1,
    "الإغريق": 1,
    "ذب": 1,
    "للانصهار": 1,
    "المتسبب": 1,
    "جليد": 1,
    "ثلج": 1,
    "ميلفيل": 1,
    "ميلفن": 1,
    "العضوية": 1,
    "العضويات": 1,
    "الغشاء": 1,
    "بغشاء": 1,
    "الأغشية": 1,
    "تذكار": 1,
    "تذكارات": 1,
    "مذكرة": 1,
    "مذكرات": 1,
    "التذكارية": 1,
    "المذكرة": 1,
    "الحفل": 1,
    "التأبيني": 1,
    "تذكارية": 1,
    "ذكريات": 1,
    "استظهار": 1,
    "استظهر": 1,
    "مستظهر": 1,
    "حفاظ": 1,
    "يستظهر": 1,
    "الإستظهار": 1,
    "الذاكرةالتبديل": 1,
    "ميمفيس": 1,
    "حدائق": 1,
    "تدابير": 1,
    "الحدوث": 1,
    "للحيض": 1,
    "مينكين": 1,
    "ناقد": 1,
    "كذاب": 1,
    "مصلح": 1,
    "ميندل": 1,
    "مندل": 1,
    "رومانسي": 1,
    "إصلاح": 1,
    "ميندوزا": 1,
    "مينيلوس": 1,
    "سبارتا": 1,
    "الإغريقية)": 1,
    "جماعات": 1,
    "الرنكة": 1,
    "سحائي": 1,
    "السحايا": 1,
    "المحدب": 1,
    "مينلو": 1,
    "مينونيت": 1,
    "معارضون": 1,
    "للعنف": 1,
    "الجماعة": 1,
    "المنقرضة": 1,
    "تسمى": 1,
    "(الجونكويان)": 1,
    "مينش": 1,
    "الحيض": 1,
    "حيضي": 1,
    "تحيض": 1,
    "محاض": 1,
    "حيض": 1,
    "عقلي": 1,
    "عقليات": 1,
    "عقلية": 1,
    "عقليا": 1,
    "المنثول": 1,
    "عابرة": 1,
    "مذكور": 1,
    "مذكرون": 1,
    "مينو": 1,
    "مينسيه": 1,
    "تموء": 1,
    "السبعة": 1,
    "الرئيسيين": 1,
    "الوسطى)": 1,
    "مركاتوري": 1,
    "مرسيدس": 1,
    "مرتزقة": 1,
    "الارتزاق": 1,
    "مرتزق": 1,
    "بزاز": 1,
    "وبخاصة": 1,
    "الحريرية": 1,
    "يمتن": 1,
    "المغزول": 1,
    "ويصقله": 1,
    "ويجعله": 1,
    "تقبلا": 1,
    "للأصباغ": 1,
    "بمعالجته": 1,
    "بالصودا": 1,
    "الكاوية": 1,
    "المصقول": 1,
    "ليكون": 1,
    "بزازون": 1,
    "بضائع": 1,
    "يتاجر": 1,
    "متاجرة": 1,
    "رحمة": 1,
    "برحمة": 1,
    "ميرك": 1,
    "زئبقي": 1,
    "الزئبق": 1,
    "ميريديث": 1,
    "ميرينجو": 1,
    "تبهرجا": 1,
    "تقدمها": 1,
    "والعطاء": 1,
    "الفردي": 1,
    "تقدمه": 1,
    "بالتقدير": 1,
    "ميريويذر": 1,
    "لتنظيف": 1,
    "ميرل": 1,
    "صقر": 1,
    "صقور": 1,
    "حورية": 1,
    "حوريات": 1,
    "غرانق": 1,
    "خرافي": 1,
    "وذيل": 1,
    "مخلوقات": 1,
    "ميريام": 1,
    "ميريك": 1,
    "ميريل": 1,
    "ميرريماك": 1,
    "فرح": 1,
    "أفراح": 1,
    "فرحة": 1,
    "ميريت": 1,
    "يلهو": 1,
    "لهو": 1,
    "ميرفن": 1,
    "هضبة": 1,
    "مستوية": 1,
    "السطج": 1,
    "متحدرة": 1,
    "عقار": 1,
    "مساريقي": 1,
    "مساريق": 1,
    "متشبك": 1,
    "شبكات": 1,
    "تشبك": 1,
    "فاتن": 1,
    "منومون": 1,
    "مغناطيسيون": 1,
    "الجرثومية": 1,
    "جنين)": 1,
    "الميزون": 1,
    "البروتون": 1,
    "والإلكترون": 1,
    "النهرين": 1,
    "بالدهر": 1,
    "المسكيت": 1,
    "هوية": 1,
    "هويات": 1,
    "برسالة": 1,
    "رسول": 1,
    "رسل": 1,
    "مطاعم": 1,
    "المنتظر": 1,
    "مسيحي": 1,
    "لخبطة": 1,
    "متسم": 1,
    "بالفوضى": 1,
    "الاختلاط": 1,
    "بادئة": 1,
    "معناها": 1,
    "أيضي": 1,
    "الأيض": 1,
    "الأيضية": 1,
    "الأيضة": 1,
    "ناشئة": 1,
    "محصلة": 1,
    "الغذائي": 1,
    "أيض": 1,
    "مؤيض": 1,
    "يؤيض": 1,
    "التأييض": 1,
    "كسى": 1,
    "بالمعدن": 1,
    "إكساء": 1,
    "التعدين": 1,
    "يعدن": 1,
    "المعادن": 1,
    "مجهريا": 1,
    "بعلوم": 1,
    "معادني": 1,
    "أشغال": 1,
    "بالرياضيات": 1,
    "مسخي": 1,
    "(الصخور)": 1,
    "(بينة": 1,
    "الصخر)": 1,
    "تحويلات": 1,
    "تحولي": 1,
    "الميتافوسفايت": 1,
    "غيبي": 1,
    "الماورائي": 1,
    "الميتافيزيقيا": 1,
    "الانبثاث": 1,
    "\"علة\"": 1,
    "الداء": 1,
    "مقره": 1,
    "السرطان)": 1,
    "الإنبثاث": 1,
    "تيمة": 1,
    "للتيمات": 1,
    "ميتاكاف": 1,
    "نيزك": 1,
    "نيزكي": 1,
    "فائقة": 1,
    "النيزك": 1,
    "النيزكي": 1,
    "شهاب": 1,
    "يتبدد": 1,
    "تبددا": 1,
    "كاملا": 1,
    "نيازك": 1,
    "الشهب": 1,
    "النيازك": 1,
    "الدائر": 1,
    "(حول": 1,
    "الشمس)": 1,
    "إرصادي": 1,
    "أرصادي": 1,
    "بالإرصاد": 1,
    "الأرصادي": 1,
    "الأرصاد": 1,
    "متر": 1,
    "مقاس": 1,
    "أمتار": 1,
    "الميثاكريليت": 1,
    "الميثاكريليك": 1,
    "الميثادون": 1,
    "الميثان": 1,
    "ميثانول": 1,
    "الميثيونين": 1,
    "كزلال": 1,
    "والخميرة": 1,
    "منهجية": 1,
    "المنهاجية": 1,
    "الميثودي": 1,
    "الميثوديون": 1,
    "استنادا": 1,
    "المنهجيات": 1,
    "أشخاص": 1,
    "شديدو": 1,
    "التمسك": 1,
    "بالمذهب": 1,
    "ميتهين": 1,
    "ماساتشوسيتس": 1,
    "ميتهسيلاه": 1,
    "بطريرك": 1,
    "يقال": 1,
    "طاعنون": 1,
    "بالسن": 1,
    "الميثيل": 1,
    "مميثل": 1,
    "الميثيلين": 1,
    "جذر": 1,
    "الوسوسة": 1,
    "المتر": 1,
    "الأمتار": 1,
    "متري": 1,
    "ميتريس": 1,
    "المترو": 1,
    "قطار": 1,
    "ببندول": 1,
    "بتناغم": 1,
    "بندول": 1,
    "حضري": 1,
    "حضريون": 1,
    "قطارات": 1,
    "ميتس": 1,
    "ميتزلر": 1,
    "إبك": 1,
    "مبكي": 1,
    "يبكي": 1,
    "الإسطبلات": 1,
    "المكسيكيون": 1,
    "مييرس": 1,
    "المعتدلة": 1,
    "ميغاهيرتز": 1,
    "ميامي": 1,
    "المواء": 1,
    "بخار": 1,
    "العفن": 1,
    "البلق": 1,
    "الفئران": 1,
    "مايكل": 1,
    "ميكلانجيلو": 1,
    "مايكلز": 1,
    "ميكلسون": 1,
    "ميتشل": 1,
    "ميتشيلانجيلو": 1,
    "ميشيل": 1,
    "ميتشيلن": 1,
    "ميتشيلسون": 1,
    "مشيغان": 1,
    "ميك": 1,
    "ميكيلسون": 1,
    "ميكي": 1,
    "الميكي": 1,
    "المليون": 1,
    "الكيميائي": 1,
    "لكمية": 1,
    "ضئيلة": 1,
    "بالتحليل": 1,
    "جرثومة": 1,
    "بمزيل": 1,
    "الميكروبات": 1,
    "مجهري": 1,
    "تتعامل": 1,
    "كميات": 1,
    "لتنفيذ": 1,
    "تعليمات": 1,
    "المصغر": 1,
    "بالأقتصاديات": 1,
    "الأقتصاديات": 1,
    "الألكترونيات": 1,
    "الميكروفيش": 1,
    "الميكروفيلم": 1,
    "صورا": 1,
    "فوتوغرافية": 1,
    "مصغرة": 1,
    "أخذ": 1,
    "مايكروفيلم": 1,
    "الميكروفلم": 1,
    "الأحافير": 1,
    "الميكروغرام": 1,
    "التجهير": 1,
    "بالمجهر": 1,
    "تعليمة": 1,
    "ميكروجول": 1,
    "للغبار": 1,
    "بالجزيء": 1,
    "الميكرومتر": 1,
    "الميكرومترات": 1,
    "الميكرون": 1,
    "ماكرونيزيا": 1,
    "الماكروني": 1,
    "الماكرونيون": 1,
    "الميكرونات": 1,
    "المجهري": 1,
    "المجهرية": 1,
    "بالصور": 1,
    "تتطلب": 1,
    "مجهر": 1,
    "لرؤيتها": 1,
    "الميكروفيلمي": 1,
    "تجزيء": 1,
    "بالكومبيوتر": 1,
    "خطوات": 1,
    "المجهر": 1,
    "المجاهر": 1,
    "مايكروسوفت": 1,
    "سيتوبلازم": 1,
    "الفولت": 1,
    "المايكرويف": 1,
    "ميداس": 1,
    "الأسطوري": 1,
    "لفريجيا": 1,
    "الأوسط": 1,
    "ترددات": 1,
    "منتصف": 1,
    "مددلبري": 1,
    "المنتصفات": 1,
    "ميدلسيكس": 1,
    "مددلتون": 1,
    "مددلتاون": 1,
    "(لا": 1,
    "١٦٠": 1,
    "باوند)": 1,
    "سلع": 1,
    "(حجما": 1,
    "جودة)": 1,
    "البرغشة": 1,
    "البراغيش": 1,
    "الميدي": 1,
    "أوسطي": 1,
    "مدمورن": 1,
    "أوسط": 1,
    "وسطها": 1,
    "الصيف": 1,
    "إختبارات": 1,
    "نغمة": 1,
    "أجنحة": 1,
    "لضروب": 1,
    "البريئة": 1,
    "الأسبوع": 1,
    "الغرب": 1,
    "وضعت": 1,
    "قابلات": 1,
    "ميد": 1,
    "الهيئات": 1,
    "ميس": 1,
    "الميج": 1,
    "هولا": 1,
    "أقدر": 1,
    "مغنون": 1,
    "الشقيقة": 1,
    "هجرة": 1,
    "اندفاع": 1,
    "هجرات": 1,
    "مهاجرون": 1,
    "ميجيل": 1,
    "مايك": 1,
    "الميكروفونات": 1,
    "ميخائيل": 1,
    "ميكويان": 1,
    "الروسية": 1,
    "ميلا": 1,
    "بالأميال": 1,
    "حلوب": 1,
    "إعتدالا": 1,
    "الفطري": 1,
    "ميلدريد": 1,
    "ميلير": 1,
    "أميال": 1,
    "بروح": 1,
    "فدائية": 1,
    "النضالية": 1,
    "عسكريا": 1,
    "مشرب": 1,
    "بالروح": 1,
    "بدعم": 1,
    "للعسكرية": 1,
    "ناهض": 1,
    "مناهض": 1,
    "يناهض": 1,
    "المناهضة": 1,
    "الحليب": 1,
    "محلوب": 1,
    "الحالب": 1,
    "الحالبون": 1,
    "حليبي": 1,
    "الحليبية": 1,
    "الحلب": 1,
    "يحلب": 1,
    "ميلارد": 1,
    "ألفي": 1,
    "بألف": 1,
    "الألفي": 1,
    "الألفيات": 1,
    "الألفية": 1,
    "وأربعين": 1,
    "أمهات": 1,
    "طحان": 1,
    "طحانون": 1,
    "ميليس": 1,
    "الدخن": 1,
    "المليار": 1,
    "الملليغرام": 1,
    "الملليغرامات": 1,
    "ميليكان": 1,
    "الملليلتر": 1,
    "الملليلترات": 1,
    "الملليمتر": 1,
    "المليمترات": 1,
    "قبعات": 1,
    "متاجر": 1,
    "المليونير": 1,
    "المليونيرات": 1,
    "الملايين": 1,
    "الألف": 1,
    "بالميليفولت": 1,
    "مدرجة": 1,
    "الطاحون": 1,
    "طواحين": 1,
    "الرحى": 1,
    "الطواحين": 1,
    "مركبو": 1,
    "الميلورد": 1,
    "ميلتن": 1,
    "ملتوني": 1,
    "بالشاعر": 1,
    "الإنكليزي": 1,
    "ملتون": 1,
    "بشعره": 1,
    "لهما": 1,
    "ميلووكي": 1,
    "مختلفة": 1,
    "(Multiple": 1,
    "Instruction": 1,
    "Multiple": 1,
    "Data)": 1,
    "الصامتة": 1,
    "تمثيلية": 1,
    "صامتة": 1,
    "الناسخة": 1,
    "نسخ": 1,
    "ناسخات": 1,
    "بالتقليد": 1,
    "والمحاكاة": 1,
    "ساخرة": 1,
    "والمحاكاة)": 1,
    "ميمي": 1,
    "حاكى": 1,
    "للتسلية": 1,
    "التقاليد": 1,
    "محاكاة": 1,
    "المئذنة": 1,
    "المفروم": 1,
    "مفروم": 1,
    "المفرمة": 1,
    "المفارم": 1,
    "المفرومة": 1,
    "عقل": 1,
    "الفلبين": 1,
    "متدبر": 1,
    "حريص": 1,
    "يقظة": 1,
    "بغفلة": 1,
    "غفلة": 1,
    "اللغم": 1,
    "الألغام": 1,
    "ممعدن": 1,
    "يمعدن": 1,
    "التمعدن": 1,
    "بالمعادن": 1,
    "متخصصون": 1,
    "إلاهة": 1,
    "ألغام": 1,
    "كاسحات": 1,
    "مينج": 1,
    "المصغرون": 1,
    "التصغير": 1,
    "صغر": 1,
    "يصغر": 1,
    "المتوسطة": 1,
    "الإصلاحي": 1,
    "الإصلاحيون": 1,
    "الأدنى": 1,
    "مينيماكس": 1,
    "مقلل": 1,
    "أشرعة": 1,
    "وزاري": 1,
    "وزاريا": 1,
    "يخدم": 1,
    "الخدمة": 1,
    "وزارات": 1,
    "مينيكس": 1,
    "ثعلب": 1,
    "ثعالب": 1,
    "ميننيبوليس": 1,
    "مينيسوتا": 1,
    "ميني": 1,
    "البلمة": 1,
    "البلم": 1,
    "بحضارة": 1,
    "اقريطش": 1,
    "قاصر": 1,
    "التخصص": 1,
    "التشعيبي": 1,
    "الأقليات": 1,
    "الأقلية": 1,
    "قاصرون": 1,
    "مينوس": 1,
    "مينوت": 1,
    "منسك": 1,
    "مينسكاي": 1,
    "الكاتدرائية": 1,
    "الكاتدرائيات": 1,
    "الأشعار": 1,
    "سك": 1,
    "المطروح": 1,
    "منهم": 1,
    "كلاسيكية": 1,
    "يحتاج": 1,
    "لدقيقة": 1,
    "مستعدا": 1,
    "للقتال": 1,
    "بالاهتمام": 1,
    "بالتفاصيل": 1,
    "الدقائق": 1,
    "المينكس": 1,
    "الثلثي": 1,
    "المعجزة": 1,
    "المعجزات": 1,
    "أعجوبي": 1,
    "عجيبة": 1,
    "السراب": 1,
    "سراب": 1,
    "الميراج": 1,
    "يتوهم": 1,
    "ميراندا": 1,
    "متوحل": 1,
    "الأطيان": 1,
    "مرايا": 1,
    "طينا": 1,
    "التوحل": 1,
    "مرآة": 1,
    "انعكس": 1,
    "الإنعكاس": 1,
    "المرايا": 1,
    "بحزن": 1,
    "الأفراح": 1,
    "وقدر": 1,
    "مصطف": 1,
    "اصطفاف": 1,
    "عدو": 1,
    "للبشر": 1,
    "مبغضا": 1,
    "مبغضون": 1,
    "مساء": 1,
    "ميسابلير": 1,
    "أسيئ": 1,
    "فهم": 1,
    "اساءة": 1,
    "نغل": 1,
    "متفرقات": 1,
    "أخطئ": 1,
    "أهن": 1,
    "الاخفاقات": 1,
    "ميسكاست": 1,
    "ميسكاستس": 1,
    "تمازج": 1,
    "متنوعة": 1,
    "للخلط": 1,
    "للاختلاط": 1,
    "التخاطب": 1,
    "الخاطيء": 1,
    "أسئ": 1,
    "الفاجر": 1,
    "الفاجرون": 1,
    "الآثام": 1,
    "بجنحة": 1,
    "بجنحات": 1,
    "الجنحة": 1,
    "معلومة": 1,
    "خاطئة": 1,
    "بؤس": 1,
    "مثيرا": 1,
    "للشفقة": 1,
    "التعاسات": 1,
    "بخل": 1,
    "بخلاء": 1,
    "تعاسة": 1,
    "السمة": 1,
    "المريضة": 1,
    "اختل": 1,
    "(السلاح": 1,
    "الناري)": 1,
    "إخفاقات": 1,
    "إخفاق": 1,
    "يقيس": 1,
    "صحيحة": 1,
    "التخوف": 1,
    "مضللة": 1,
    "المضلل": 1,
    "استعماله": 1,
    "مسيؤو": 1,
    "السيء": 1,
    "السيئة": 1,
    "الفقد": 1,
    "تسمية": 1,
    "سمي": 1,
    "باسم": 1,
    "كارهو": 1,
    "مكانها": 1,
    "مطبعي": 1,
    "تلفظ": 1,
    "اللفظي": 1,
    "إقتباس": 1,
    "ميسريدر": 1,
    "تمويهي": 1,
    "اخطأ": 1,
    "الخاطئة": 1,
    "مفتقد": 1,
    "ممسوخ": 1,
    "التشوه": 1,
    "الصاروخ": 1,
    "الصواريخ": 1,
    "المبشرون": 1,
    "المبشر": 1,
    "بمهمة": 1,
    "المسيسيبي": 1,
    "بولاية": 1,
    "ميسيسبي": 1,
    "الخطابات": 1,
    "ميسسولا": 1,
    "ميسسوري": 1,
    "تهجئة": 1,
    "خطا": 1,
    "التهجئة": 1,
    "أخطاء": 1,
    "يبذر": 1,
    "كاذب": 1,
    "كاذبة": 1,
    "العثرة": 1,
    "ميسي": 1,
    "للخطأ": 1,
    "خاطيء": 1,
    "مخطئ": 1,
    "المخطئ": 1,
    "مغشى": 1,
    "ضبابيا": 1,
    "التغشية": 1,
    "الهدال": 1,
    "الهدالات": 1,
    "العشيقة": 1,
    "العشيقات": 1,
    "الباطلة": 1,
    "الظنون": 1,
    "بالخطأ": 1,
    "التفاهم": 1,
    "فهمه": 1,
    "إساءات": 1,
    "اساء": 1,
    "ماساتشوستس": 1,
    "ميتش": 1,
    "ميتشيل": 1,
    "العثة": 1,
    "القلوسة": 1,
    "ميتيروورت": 1,
    "العث": 1,
    "مايتوكوندريا": 1,
    "مولدة": 1,
    "للطاقة": 1,
    "ميتزفاهس": 1,
    "إحدى": 1,
    "العشر": 1,
    "للتشريع": 1,
    "للمزج": 1,
    "الخلاطة": 1,
    "خلاطة": 1,
    "الخلطات": 1,
    "شجار": 1,
    "الإزار": 1,
    "(نجم)": 1,
    "المليلتر": 1,
    "المليمتر": 1,
    "شي": 1,
    "بالذاكرة": 1,
    "التذكر": 1,
    "تقوية": 1,
    "الغوغاء": 1,
    "مهاجم": 1,
    "قلنسوة": 1,
    "نسائية": 1,
    "قلنسوات": 1,
    "موبيل": 1,
    "نقال": 1,
    "هواتف": 1,
    "الشقي": 1,
    "الخف": 1,
    "الأخفاف": 1,
    "موكيرنت": 1,
    "المستهزئون": 1,
    "الهزء": 1,
    "هازئ": 1,
    "بالحجم": 1,
    "نماذج": 1,
    "بالأحجام": 1,
    "المودم": 1,
    "المودمات": 1,
    "المعتدل": 1,
    "مراجع": 1,
    "المعتدلون": 1,
    "مودراتلي": 1,
    "العصرانية": 1,
    "متحررون": 1,
    "التحديث": 1,
    "عصرن": 1,
    "المجددون": 1,
    "يعصرن": 1,
    "العصرنة": 1,
    "العصرية": 1,
    "آراء": 1,
    "عصرية": 1,
    "الأنماط": 1,
    "موديستو": 1,
    "التواضع": 1,
    "تعديل": 1,
    "مجزأة": 1,
    "التحويرات": 1,
    "المغير": 1,
    "المغيرون": 1,
    "تغييري": 1,
    "الوحدات": 1,
    "مودولو": 1,
    "مودوس": 1,
    "مو": 1,
    "موقاديشو": 1,
    "الصومال": 1,
    "الموهير": 1,
    "محمد": 1,
    "محمدي": 1,
    "الموهوك": 1,
    "موهر": 1,
    "إكدح": 1,
    "يكدح": 1,
    "موينيس": 1,
    "حرير": 1,
    "المواريه": 1,
    "مويسييف": 1,
    "برطوبة": 1,
    "مولال": 1,
    "الضرس": 1,
    "الأضراس": 1,
    "الدبس": 1,
    "مولدافيا": 1,
    "الدجر": 1,
    "بالمحراث": 1,
    "المفكك": 1,
    "عفنا": 1,
    "المفككون": 1,
    "عفونة": 1,
    "العفونة": 1,
    "مولدوفيا": 1,
    "معفن": 1,
    "الخلد": 1,
    "جزيئي": 1,
    "الجزيئة": 1,
    "الترابية": 1,
    "فروة": 1,
    "إئذ": 1,
    "المتحرشون": 1,
    "مولير": 1,
    "مولن": 1,
    "صديقة": 1,
    "مولي": 1,
    "مهدوء": 1,
    "مهدئة": 1,
    "الرخوي": 1,
    "بالرخويات": 1,
    "المدللون": 1,
    "مولوتش": 1,
    "سحلية": 1,
    "مولوتوف": 1,
    "فقد": 1,
    "مائع": 1,
    "مولوكاس": 1,
    "موليبديت": 1,
    "موليبديك": 1,
    "الموليبدينيت": 1,
    "الموليبدنم": 1,
    "فضي": 1,
    "الزخم": 1,
    "مونا": 1,
    "موناكو": 1,
    "الأحادي": 1,
    "ملكي": 1,
    "المبادىء": 1,
    "مونارتشيستيك": 1,
    "أنصار": 1,
    "الملكي": 1,
    "رهباني": 1,
    "الرهبانية": 1,
    "بإذن": 1,
    "مونيل": 1,
    "مونيه": 1,
    "ترشيد": 1,
    "نقدي": 1,
    "نقد": 1,
    "منقد": 1,
    "ينقد": 1,
    "التنقيد": 1,
    "شنطة": 1,
    "ضاربي": 1,
    "كساب": 1,
    "الفلوس": 1,
    "مونييوورت": 1,
    "مونفورت": 1,
    "التأجيج": 1,
    "منغوليا": 1,
    "المنغولي": 1,
    "المنغوليون": 1,
    "منغولي": 1,
    "النغول": 1,
    "مونيك": 1,
    "مونيكا": 1,
    "الإسم": 1,
    "الشخصي": 1,
    "المزحة": 1,
    "المضحكة": 1,
    "مونماوث": 1,
    "الإنجليزي.": 1,
    "الابن": 1,
    "لتشارلز": 1,
    "أحادية": 1,
    "مكروفون": 1,
    "مونوسيروس": 1,
    "مونوكروميتور": 1,
    "لعزل": 1,
    "لألوان": 1,
    "الطيف": 1,
    "بنظارة": 1,
    "بثلاث": 1,
    "الفلقة": 1,
    "المنفردة": 1,
    "البلورية": 1,
    "المقترن": 1,
    "المقترنة": 1,
    "بزوج": 1,
    "مونويد": 1,
    "منليثي": 1,
    "المونولمج": 1,
    "المونمر": 1,
    "يتحد": 1,
    "بالآخرين": 1,
    "بوليمر": 1,
    "مونونجاهيلا": 1,
    "احتكارات": 1,
    "احتكار": 1,
    "محتكر": 1,
    "احتكاري": 1,
    "محتكرون": 1,
    "احتكر": 1,
    "المحتكر": 1,
    "يحتكر": 1,
    "كهربية": 1,
    "مستقرة": 1,
    "مقتضب": 1,
    "مفرد": 1,
    "مفردة": 1,
    "الرتابة": 1,
    "رتيب": 1,
    "المونوتيرماتا": 1,
    "مونرو": 1,
    "مونروفيا": 1,
    "مونسانتو": 1,
    "الموسمية": 1,
    "الوحوش": 1,
    "المسوخ": 1,
    "المسخ": 1,
    "مونتانا": 1,
    "المونتاج": 1,
    "مونتاج": 1,
    "مونتين": 1,
    "مقالي": 1,
    "مونتكلير": 1,
    "جيرسي": 1,
    "المنت": 1,
    "المونتنيغري": 1,
    "مونتري": 1,
    "مونتيفيردي": 1,
    "مونتيفديو": 1,
    "مونتغومري": 1,
    "الشهرية": 1,
    "شهريا": 1,
    "الشهور": 1,
    "مونتايسلو": 1,
    "مونتمارتر": 1,
    "مونتبيلير": 1,
    "مونتراتشيت": 1,
    "مونتريال": 1,
    "مونتسيرات": 1,
    "مونتي": 1,
    "الأنصاب": 1,
    "مزاجية": 1,
    "مزاجيا": 1,
    "المزاجية": 1,
    "مزاجي": 1,
    "مخار": 1,
    "هلالي": 1,
    "موني": 1,
    "عملا": 1,
    "إضافيا": 1,
    "المهنتين": 1,
    "مقمر": 1,
    "الأقمار": 1,
    "الممنوع": 1,
    "البور": 1,
    "مور": 1,
    "مربط": 1,
    "التربط": 1,
    "مراسي": 1,
    "مغاربي": 1,
    "مقترح": 1,
    "الممسحة": 1,
    "المكتئب": 1,
    "الإكتآب": 1,
    "المماسح": 1,
    "والأحجار": 1,
    "المغزى": 1,
    "الفيلسوف": 1,
    "الأخلاقي": 1,
    "بتزمت": 1,
    "الفلاسفة": 1,
    "الأخلاقيون": 1,
    "الأخلاقية": 1,
    "عظ": 1,
    "موعوظ": 1,
    "الواعظ": 1,
    "يعظ": 1,
    "الوعظ": 1,
    "موران": 1,
    "مورافيا": 1,
    "مورافي": 1,
    "الموراي": 1,
    "سقيم": 1,
    "المرضية": 1,
    "مورهاوس": 1,
    "موريلاند": 1,
    "الأعراف": 1,
    "موريسبي": 1,
    "مورغان": 1,
    "مورغن": 1,
    "المشرحة": 1,
    "المشارح": 1,
    "موريارتاي": 1,
    "محتضر": 1,
    "الاحتضار": 1,
    "المريون": 1,
    "خوذة": 1,
    "مورلي": 1,
    "المرمون": 1,
    "الصباح": 1,
    "المغربي": 1,
    "المغرب": 1,
    "بغباوة": 1,
    "مورفيمي": 1,
    "المورفين": 1,
    "موريل": 1,
    "موريسون": 1,
    "موريسسي": 1,
    "موريستاون": 1,
    "الغد": 1,
    "مورس": 1,
    "اللقمة": 1,
    "اللقم": 1,
    "لنقل": 1,
    "مملط": 1,
    "التمليط": 1,
    "الهاونات": 1,
    "مورتيم": 1,
    "العقاري": 1,
    "مرهون": 1,
    "المرتهن": 1,
    "المرتهنون": 1,
    "الممول": 1,
    "الممولون": 1,
    "العقارية": 1,
    "مستدين": 1,
    "مستدينو": 1,
    "الجنازات": 1,
    "مجهزو": 1,
    "للحرب": 1,
    "الكابح": 1,
    "يكبح": 1,
    "إكبح": 1,
    "بخزي": 1,
    "مورتيمر": 1,
    "ويلزي": 1,
    "عشيق": 1,
    "مورتن": 1,
    "الفسيفساء": 1,
    "موسكون": 1,
    "موسكو": 1,
    "موس": 1,
    "موسر": 1,
    "موسى": 1,
    "إمش": 1,
    "المسجد": 1,
    "المساجد": 1,
    "بنادق": 1,
    "مطحلب": 1,
    "الغالب": 1,
    "السنوي": 1,
    "النفتالين": 1,
    "الواح": 1,
    "موثيس": 1,
    "المواضيع": 1,
    "تحفيزي": 1,
    "موتيفاتيف": 1,
    "حافز": 1,
    "البخارية": 1,
    "موكب": 1,
    "الموتوسيكل": 1,
    "بالمحرك": 1,
    "مكنن": 1,
    "موتورولا": 1,
    "السنوية": 1,
    "مقولب": 1,
    "التبديل": 1,
    "مولتون": 1,
    "التبديلات": 1,
    "للركوب": 1,
    "تسلق": 1,
    "متسلقو": 1,
    "مونتينوسنيس": 1,
    "إندب": 1,
    "مندوب": 1,
    "النادب": 1,
    "النادبون": 1,
    "الفأر": 1,
    "يصطاد": 1,
    "تصغير": 1,
    "فأر": 1,
    "فئراني": 1,
    "والسكينة": 1,
    "الموس": 1,
    "مشورب": 1,
    "الشوارب": 1,
    "موثر": 1,
    "الحال": 1,
    "الأفواه": 1,
    "غسول": 1,
    "المنقول": 1,
    "للحركة": 1,
    "انقل": 1,
    "المحركون": 1,
    "قص": 1,
    "المحشة": 1,
    "المحشات": 1,
    "يقص": 1,
    "موير": 1,
    "موزمبيق": 1,
    "موزارت": 1,
    "بالساعة": 1,
    "موزيلا": 1,
    "برامج": 1,
    "وتحتوي": 1,
    "متصفح": 1,
    "وقاريء": 1,
    "الآنسة": 1,
    "مثانية": 1,
    "الطن": 1,
    "المتري": 1,
    "تزعم": 1,
    "أنها": 1,
    "(Music": 1,
    "TeleVision)": 1,
    "مخاطي": 1,
    "الوحل": 1,
    "يشهر": 1,
    "الأوحال": 1,
    "المخاطي": 1,
    "المخاط": 1,
    "عكر": 1,
    "أوحل": 1,
    "يعكر": 1,
    "التعكير": 1,
    "للطين": 1,
    "المكونون": 1,
    "واقية": 1,
    "المشهر": 1,
    "المشهرون": 1,
    "مويلير": 1,
    "المؤذن": 1,
    "مكتوم": 1,
    "الخمار": 1,
    "الخمارات": 1,
    "السراق": 1,
    "ميور": 1,
    "المجاهدين": 1,
    "موكدين": 1,
    "الخلاسيون": 1,
    "التوتة": 1,
    "البغل": 1,
    "البغال": 1,
    "مولنج": 1,
    "السليم": 1,
    "الملا": 1,
    "مولين": 1,
    "موليجان": 1,
    "البرمجة": 1,
    "المستعملين": 1,
    "مالتيكس": 1,
    "التنوع": 1,
    "مولتيليفيل": 1,
    "والصورة": 1,
    "طيفي": 1,
    "طيفية": 1,
    "مكالمات": 1,
    "بعدة": 1,
    "للضرب": 1,
    "المضروبة": 1,
    "مكثر": 1,
    "التعدد": 1,
    "المضاعفات": 1,
    "المراحل": 1,
    "المهام": 1,
    "المستخدمين": 1,
    "الجامعة": 1,
    "الهزاز": 1,
    "غمغم": 1,
    "مغمغم": 1,
    "المتمتم": 1,
    "المتمتمون": 1,
    "يغمغم": 1,
    "التمتمات": 1,
    "مومفورد": 1,
    "بدور": 1,
    "الصامت": 1,
    "تحنيط": 1,
    "النكاف": 1,
    "عال": 1,
    "رتابة": 1,
    "مندت": 1,
    "مونج": 1,
    "المصطلح": 1,
    "الازدرائي": 1,
    "مونجر": 1,
    "العنون": 1,
    "ميونخ": 1,
    "البلديات": 1,
    "أخضع": 1,
    "للبلدية": 1,
    "السخاء": 1,
    "سخي": 1,
    "مونسي": 1,
    "مونسون": 1,
    "موونج": 1,
    "الجدارية": 1,
    "الجداريات": 1,
    "القاتلة": 1,
    "غدرا": 1,
    "جرائم": 1,
    "مرياتيك": 1,
    "مرك": 1,
    "ظلاما": 1,
    "الظلمة": 1,
    "مرمرير": 1,
    "ميرفي": 1,
    "موراي": 1,
    "المور": 1,
    "مروو": 1,
    "مسكوفي": 1,
    "عضليا": 1,
    "العضلي": 1,
    "موسير": 1,
    "المتحف": 1,
    "المتاحف": 1,
    "مزلاج": 1,
    "تجره": 1,
    "أطرى": 1,
    "الأطرى": 1,
    "كابر": 1,
    "موشي": 1,
    "موسيقيا": 1,
    "أدائي": 1,
    "الموسيقيون": 1,
    "دراسةتاريخ": 1,
    "بتأمل": 1,
    "مسكيجون": 1,
    "المسكلنج": 1,
    "مسكوكس": 1,
    "ثور": 1,
    "مسكوكسين": 1,
    "ثيران": 1,
    "فئران": 1,
    "مسكي": 1,
    "بلح": 1,
    "موسولني": 1,
    "موسورجسكاي": 1,
    "بشارب": 1,
    "بشنب": 1,
    "الخردل": 1,
    "تغيري": 1,
    "التغيرات": 1,
    "اللازمة": 1,
    "متحول": 1,
    "الأخرس": 1,
    "صامت": 1,
    "الخرس": 1,
    "تمرد": 1,
    "التمردات": 1,
    "تمتم": 1,
    "يتمتم": 1,
    "الخروف": 1,
    "موتواليتي": 1,
    "ميوزاك": 1,
    "مكمم": 1,
    "المكمم": 1,
    "التكميم": 1,
    "خاصتي": 1,
    "مينامار": 1,
    "مايكينا": 1,
    "يقطنها": 1,
    "مايكوباكتيريا": 1,
    "مايلاين": 1,
    "دسمة": 1,
    "بيضاء": 1,
    "الشوكي": 1,
    "بالنخاع": 1,
    "مايرز": 1,
    "مايلار": 1,
    "لمادة": 1,
    "ميناه": 1,
    "مينهير": 1,
    "بعضلة": 1,
    "ميوفيبريل": 1,
    "الميوجلوبين": 1,
    "بقصر": 1,
    "ميرا": 1,
    "مايرون": 1,
    "المر": 1,
    "ميرتل": 1,
    "نفسي": 1,
    "الصوفيون": 1,
    "وبسط": 1,
    "نوتة": 1,
    "قطرات": 1,
    "المعلومات.": 1,
    "نابيسكو": 1,
    "الصوديوم": 1,
    "لؤلؤي": 1,
    "نادية": 1,
    "نديم": 1,
    "نادين": 1,
    "ناجاساكي": 1,
    "ناجويا": 1,
    "عروس": 1,
    "مسمر": 1,
    "نيلير": 1,
    "التسمير": 1,
    "نير": 1,
    "نيروبي": 1,
    "سذاجة": 1,
    "ناكامرا": 1,
    "ناكاياما": 1,
    "الاسمالموقع": 1,
    "ناميبيا": 1,
    "النان": 1,
    "نانسي": 1,
    "نانيت": 1,
    "نانكينج": 1,
    "يانجتسي": 1,
    "مربيات": 1,
    "مربية": 1,
    "النانوميتر": 1,
    "نانوك": 1,
    "النانوسيكند": 1,
    "نانتوكيت": 1,
    "نعومي": 1,
    "النابالم": 1,
    "القفا": 1,
    "النفثالين": 1,
    "نابولي": 1,
    "نابليون": 1,
    "نابليوني": 1,
    "مقيل": 1,
    "الحفاظات": 1,
    "التقييل": 1,
    "الحفاظة": 1,
    "الإغفاءات": 1,
    "ناربون": 1,
    "جنوبية": 1,
    "ضابط": 1,
    "الإفتتان": 1,
    "نرجسي": 1,
    "بأنانية": 1,
    "النرجسيون": 1,
    "جرعة": 1,
    "مخدرا": 1,
    "ناراجانسيت": 1,
    "إرو": 1,
    "مروي": 1,
    "يروي": 1,
    "الرواية": 1,
    "قصصيا": 1,
    "الراوي": 1,
    "الرواة": 1,
    "أضيق": 1,
    "التضييق": 1,
    "للفضاء": 1,
    "والطيران": 1,
    "أنفي": 1,
    "بالأنف": 1,
    "متكلم": 1,
    "أنفيا": 1,
    "ناشئ": 1,
    "ناش": 1,
    "ناشوا": 1,
    "ناشفيل": 1,
    "ناسو": 1,
    "بخبث": 1,
    "السلبوت": 1,
    "ناتالي": 1,
    "بالطبع": 1,
    "ناتشيز": 1,
    "نيت": 1,
    "ناثان": 1,
    "ناثانايل": 1,
    "القومي": 1,
    "بوطنية": 1,
    "الوطنيون": 1,
    "التأميم": 1,
    "أمم": 1,
    "مؤمم": 1,
    "يؤمم": 1,
    "قوميا": 1,
    "الأمم": 1,
    "حلف": 1,
    "الأطلسي": 1,
    "أناقة": 1,
    "بأناقة": 1,
    "التجنس": 1,
    "متجنس": 1,
    "يطبع": 1,
    "التطبيع": 1,
    "طبيعيا": 1,
    "بالطبيعة": 1,
    "شقي": 1,
    "نورو": 1,
    "الغثيان": 1,
    "قزز": 1,
    "يقزز": 1,
    "التقزيز": 1,
    "ملاحي": 1,
    "نوتيلاس": 1,
    "نافاهو": 1,
    "نافاجو": 1,
    "السرات": 1,
    "للملاحة": 1,
    "مجيل": 1,
    "يجول": 1,
    "الإبحار": 1,
    "كلا": 1,
    "الناصري": 1,
    "الناصرة": 1,
    "النازي": 1,
    "النازيون": 1,
    "التلفزيونية": 1,
    "الشماليه": 1,
    "لرياضة": 1,
    "الجامعات": 1,
    "للبحث": 1,
    "الكنسي": 1,
    "ار": 1,
    "نجامينا": 1,
    "النيون": 1,
    "نيل": 1,
    "البدائيون": 1,
    "نبراسكا": 1,
    "السدم": 1,
    "سديمي": 1,
    "بالضرورة": 1,
    "إستلزم": 1,
    "مستوجب": 1,
    "يستوجب": 1,
    "الإستوجاب": 1,
    "أشرطة": 1,
    "عانق": 1,
    "نيكر": 1,
    "منديل": 1,
    "مناديل": 1,
    "القلادة": 1,
    "القلائد": 1,
    "الرقاب": 1,
    "إستحضار": 1,
    "النخر": 1,
    "نخري": 1,
    "السلسبيل": 1,
    "رحيقي": 1,
    "الرحيق": 1,
    "الخوخة": 1,
    "المحتاج": 1,
    "نيدام": 1,
    "حاجة": 1,
    "باحتياج": 1,
    "الإحتياج": 1,
    "الإبرة": 1,
    "بإبرة": 1,
    "بالإبرة": 1,
    "بياع": 1,
    "الإبر": 1,
    "المشتغلون": 1,
    "بياعو": 1,
    "داع": 1,
    "الإكتفاء": 1,
    "الماهرة": 1,
    "التطاريز": 1,
    "محتاج": 1,
    "نيف": 1,
    "النافي": 1,
    "سلبي": 1,
    "سلبيا": 1,
    "السلبية": 1,
    "السلبيات": 1,
    "الناقض": 1,
    "بونك": 1,
    "النيجر": 1,
    "مكترث": 1,
    "الجلباب": 1,
    "الجلابيب": 1,
    "للتفاوض": 1,
    "المفاوض": 1,
    "تفاوض": 1,
    "متفاوض": 1,
    "يتفاوض": 1,
    "المفاوضون": 1,
    "الزنجية": 1,
    "الزنجيات": 1,
    "الزنجي": 1,
    "زنجي": 1,
    "نيهرو": 1,
    "الصهيل": 1,
    "الجار": 1,
    "الجيرة": 1,
    "مصهول": 1,
    "لا.": 1,
    "نيلسين": 1,
    "نيلسن": 1,
    "متوازية": 1,
    "الخيطية": 1,
    "الجديد": 1,
    "لقشرة": 1,
    "النيوديميوم": 1,
    "الجديدة": 1,
    "التحديثات": 1,
    "اللغوية": 1,
    "النيومايسين": 1,
    "وليدي": 1,
    "بغاز": 1,
    "النيوبرين": 1,
    "النيبال": 1,
    "نيبال": 1,
    "لنسيان": 1,
    "المآسي": 1,
    "الكلوي": 1,
    "محاباة": 1,
    "محسوبي": 1,
    "نبتون": 1,
    "النيبتنيوم": 1,
    "يذاكر": 1,
    "نيريد": 1,
    "نيبتون": 1,
    "نيرو": 1,
    "العصب": 1,
    "متنرفز": 1,
    "نيس": 1,
    "العش": 1,
    "متداخل": 1,
    "نيستر": 1,
    "العشعشة": 1,
    "نستلة": 1,
    "معشعش": 1,
    "نيسلير": 1,
    "يعشعش": 1,
    "الكتكوت": 1,
    "الكتاكيت": 1,
    "نيترافيرس": 1,
    "النيتسكاب": 1,
    "القراص": 1,
    "ملدوغ": 1,
    "استفزازي": 1,
    "نيومان": 1,
    "بالتهاب": 1,
    "بالجهاز": 1,
    "العصبي": 1,
    "المتعلق": 1,
    "بالأعصاب": 1,
    "بالخلايا": 1,
    "الفيسيولوجية": 1,
    "المتعلقة": 1,
    "والمواصفات": 1,
    "النفسية": 1,
    "خصى": 1,
    "التخصية": 1,
    "الحياد": 1,
    "المحايد": 1,
    "المحايدون": 1,
    "الحيود": 1,
    "حيد": 1,
    "محيد": 1,
    "المحيد": 1,
    "المحيدون": 1,
    "يحيد": 1,
    "التحييد": 1,
    "النيوترون": 1,
    "نيفا": 1,
    "نيفادا": 1,
    "نيفينز": 1,
    "نيوارك": 1,
    "نيوبولد": 1,
    "الجدد": 1,
    "نيوبري": 1,
    "نيوبريبورت": 1,
    "نيوكاسل": 1,
    "سنادة": 1,
    "دعامة": 1,
    "نيويل": 1,
    "نيوفندلند": 1,
    "سطر": 1,
    "المتزوج": 1,
    "المتزوجون": 1,
    "نيوبورت": 1,
    "صحف": 1,
    "موزعو": 1,
    "كشك": 1,
    "فرعية": 1,
    "نيوزويك": 1,
    "أسبوعية": 1,
    "الاسبوعبة": 1,
    "الأنباء": 1,
    "بالإخبار": 1,
    "النيوت": 1,
    "سالاماندير": 1,
    "سمة": 1,
    "نيوتن": 1,
    "سمندلات": 1,
    "نيز": 1,
    "نجيين": 1,
    "النيكوتين": 1,
    "نياكرا": 1,
    "نيامي": 1,
    "مقضوم": 1,
    "القاضم": 1,
    "القاضمون": 1,
    "نيبيلنج": 1,
    "برجندايان": 1,
    "نيكاان": 1,
    "نيكاراكوا": 1,
    "نيكولو": 1,
    "ألطف": 1,
    "الكوات": 1,
    "نيقولاس": 1,
    "نيكولس": 1,
    "نيكولز": 1,
    "نيكلسن": 1,
    "نيتشروم": 1,
    "خدش": 1,
    "النيكل": 1,
    "بالنيكل": 1,
    "بتكلفة": 1,
    "دخول": 1,
    "زهيدة": 1,
    "إصهل": 1,
    "الخدش": 1,
    "نيكلوس": 1,
    "نيكل": 1,
    "الكنية": 1,
    "الوصفي": 1,
    "الكنيات": 1,
    "التلقيب": 1,
    "نيكوديموس": 1,
    "نيقوسيا": 1,
    "نيكوتينامايد": 1,
    "فيتامينات": 1,
    "أبنة": 1,
    "نيلسون": 1,
    "نيزش": 1,
    "ممتازون": 1,
    "بامتياز": 1,
    "نايجيريا": 1,
    "النايجيري": 1,
    "البخل": 1,
    "البخلاء": 1,
    "سفسف": 1,
    "مسفسف": 1,
    "نيجلير": 1,
    "يسفسف": 1,
    "السفسفة": 1,
    "بازعاج": 1,
    "إقترب": 1,
    "طاقية": 1,
    "طاقيات": 1,
    "ملابس": 1,
    "الليلي": 1,
    "الليلية": 1,
    "البومة": 1,
    "السهر": 1,
    "العندليب": 1,
    "العنادل": 1,
    "ليليا": 1,
    "الكوابيس": 1,
    "الثلثان": 1,
    "قميص": 1,
    "نايتي": 1,
    "للصحة": 1,
    "الإنكارية": 1,
    "عدمي": 1,
    "نيجينسكاي": 1,
    "نيكو": 1,
    "نيقولاي": 1,
    "لأس": 1,
    "فطن": 1,
    "الممطر": 1,
    "الممطرة": 1,
    "ميتال": 1,
    "هايدريد": 1,
    "نينا": 1,
    "نينيبارك": 1,
    "تسع": 1,
    "القناني": 1,
    "التسعات": 1,
    "تاسع": 1,
    "التسعينيات": 1,
    "تسعون": 1,
    "نينيفيه": 1,
    "التسع": 1,
    "تاسعا": 1,
    "الأتساع": 1,
    "نيوب": 1,
    "النيوبيوم": 1,
    "القرصة": 1,
    "مقروض": 1,
    "نيبينجلي": 1,
    "الحلمات": 1,
    "نيسي": 1,
    "الصأبة": 1,
    "تصيد": 1,
    "النترات": 1,
    "منترت": 1,
    "ينترت": 1,
    "النترتة": 1,
    "النيتريك": 1,
    "النيتروجين": 1,
    "النتريد": 1,
    "للمعدن": 1,
    "نترات": 1,
    "النطرون": 1,
    "غليسرين": 1,
    "نيترو": 1,
    "النتروجين": 1,
    "نتروجيني": 1,
    "النتروجليسرين": 1,
    "نتري": 1,
    "بيض": 1,
    "بعنوان": 1,
    "نيكسون": 1,
    "مكسيكو": 1,
    "غربي": 1,
    "شيطان": 1,
    "لاتفعل": 1,
    "الادارة": 1,
    "للمحيطات": 1,
    "والغلاف": 1,
    "نوح": 1,
    "نوبل": 1,
    "النوبليوم": 1,
    "الشهامات": 1,
    "النبل": 1,
    "نبلا": 1,
    "بنبل": 1,
    "للألوان": 1,
    "الهادئة": 1,
    "الإيماءة": 1,
    "نوديس": 1,
    "العجيرة": 1,
    "العجيرات": 1,
    "نويل": 1,
    "نويس": 1,
    "إجرامي": 1,
    "محدثو": 1,
    "نوكيا": 1,
    "نولان": 1,
    "البدوي": 1,
    "بدوي": 1,
    "بدوية": 1,
    "التعريفية": 1,
    "إسمي": 1,
    "المرئي": 1,
    "للعلاقات": 1,
    "للعنونة": 1,
    "كاربون": 1,
    "لاخطي": 1,
    "قفل": 1,
    "جائز": 1,
    "مستقطب": 1,
    "لاربحي": 1,
    "تدخين": 1,
    "لطخة": 1,
    "باستمرار": 1,
    "تفاوت": 1,
    "تبرعم": 1,
    "بالأعمال": 1,
    "كربوهيدرات": 1,
    "الحاضر": 1,
    "مفوض": 1,
    "بالتباس": 1,
    "المعتزل": 1,
    "يطابقون": 1,
    "المواصفات": 1,
    "الإعتزال": 1,
    "العادي": 1,
    "تصميمية": 1,
    "طوارئ": 1,
    "التفهاء": 1,
    "مكافئة": 1,
    "نونيس": 1,
    "سطع": 1,
    "لايشكل": 1,
    "خطورة": 1,
    "نونميتالس": 1,
    "لانووي": 1,
    "أرثذوكسي": 1,
    "متعامد": 1,
    "متوازي": 1,
    "الأفذاذ": 1,
    "تحزبي": 1,
    "دفعة": 1,
    "للتلف": 1,
    "إقلاق": 1,
    "تلويث": 1,
    "وصفة": 1,
    "إجرائي": 1,
    "مهني": 1,
    "تقدمي": 1,
    "رجوع": 1,
    "للإرجاع": 1,
    "سكني": 1,
    "مستجيب": 1,
    "تقييدي": 1,
    "علمي": 1,
    "جاد": 1,
    "إشرافي": 1,
    "تقني": 1,
    "تقنيا": 1,
    "طرفية": 1,
    "إنهاء": 1,
    "حقيقة": 1,
    "لانقابي": 1,
    "تنظم": 1,
    "اللاعنف": 1,
    "طوعي": 1,
    "متطوع": 1,
    "ناخب": 1,
    "تصويت": 1,
    "المعكرونة": 1,
    "نودلد": 1,
    "نودلنج": 1,
    "الزواية": 1,
    "الظهيرة": 1,
    "الأنشوطة": 1,
    "الإنشوطات": 1,
    "نورا": 1,
    "نورادرينالين": 1,
    "نورادرينالاين": 1,
    "نوردوف": 1,
    "نوردستروم": 1,
    "نورين": 1,
    "اعادة": 1,
    "نورفولك": 1,
    "نورما": 1,
    "مطبع": 1,
    "معيد": 1,
    "معيدو": 1,
    "الأوضاع": 1,
    "نورمان": 1,
    "نورماندي": 1,
    "معياري": 1,
    "بمعيار": 1,
    "المعيارية": 1,
    "نوريس": 1,
    "نوريستاون": 1,
    "نرويجي": 1,
    "الشمال": 1,
    "نورثهامبتون": 1,
    "شمالا": 1,
    "الشرقية": 1,
    "عواصف": 1,
    "نورثفيلد": 1,
    "نورثروب": 1,
    "نورثرب": 1,
    "نورثمبيرلاند": 1,
    "الغربية": 1,
    "نورثويسترس": 1,
    "نورتن": 1,
    "نوروالك": 1,
    "النرويج": 1,
    "النرويجي": 1,
    "نورويتش": 1,
    "الأنفي": 1,
    "مشتم": 1,
    "واقي": 1,
    "للأنف": 1,
    "فضولية": 1,
    "بتطفل": 1,
    "الإشتمام": 1,
    "بحنين": 1,
    "نوسترادامس": 1,
    "نوستراند": 1,
    "المنخر": 1,
    "المدجل": 1,
    "المدجلة": 1,
    "ليس...": 1,
    "رغم": 1,
    "للعيان": 1,
    "البارزون": 1,
    "توثيقي": 1,
    "يرمز": 1,
    "الترميز": 1,
    "ترميزي": 1,
    "ترميزية": 1,
    "تشكيلية": 1,
    "نوتر": 1,
    "بالملاحظة": 1,
    "العدم": 1,
    "ملاحظ": 1,
    "التبليغ": 1,
    "التبليغات": 1,
    "الإشعار": 1,
    "نوتوكورد": 1,
    "مشهورا": 1,
    "نوتينغهام": 1,
    "نواكشوط": 1,
    "مغذى": 1,
    "يغذي": 1,
    "نوفيل": 1,
    "نوفمبر/تشرين": 1,
    "نوفاك": 1,
    "الاستبدال": 1,
    "الروائي": 1,
    "الروائيون": 1,
    "مقصص": 1,
    "الطرافات": 1,
    "نوفو": 1,
    "نوفوسيبيرسك": 1,
    "نواي": 1,
    "نوس": 1,
    "نوييز": 1,
    "نروف": 1,
    "نو": 1,
    "صحراوية": 1,
    "النوبي": 1,
    "متنوى": 1,
    "النوى": 1,
    "الأنوية": 1,
    "النيوترونات": 1,
    "البروتونات": 1,
    "النكليوتيد": 1,
    "النكليوتيدات": 1,
    "التعري": 1,
    "العراة": 1,
    "الوكزة": 1,
    "الوخاز": 1,
    "الوكز": 1,
    "العري": 1,
    "العاري": 1,
    "نجينت": 1,
    "الرافض": 1,
    "اللاغي": 1,
    "الرافضون": 1,
    "اللاغون": 1,
    "الباطلون": 1,
    "خدران": 1,
    "عداد": 1,
    "بتخدير": 1,
    "التخدر": 1,
    "بالعد": 1,
    "بسوط": 1,
    "للأعداد": 1,
    "عديد": 1,
    "خشوعي": 1,
    "نقودي": 1,
    "بالعملة": 1,
    "النقدية": 1,
    "والأوسمة": 1,
    "نومير": 1,
    "أديرة": 1,
    "الراهبات": 1,
    "الزفاف": 1,
    "الأعراس": 1,
    "الممرضة": 1,
    "الرضع": 1,
    "المربية": 1,
    "المربيات": 1,
    "الممرض": 1,
    "رياض": 1,
    "الممرضات": 1,
    "كسارة": 1,
    "كسارات": 1,
    "هاتش": 1,
    "القوارض": 1,
    "مغذي": 1,
    "المغذية": 1,
    "جوزا": 1,
    "بمس": 1,
    "مس": 1,
    "بالبندق": 1,
    "مرغ": 1,
    "ممرغ": 1,
    "يمرغ": 1,
    "التمريغ": 1,
    "النايلون": 1,
    "الحورية": 1,
    "الحوريات": 1,
    "الصؤاب": 1,
    "ألختم": 1,
    "المدور": 1,
    "بلوطي": 1,
    "أوكلند": 1,
    "أوكلي": 1,
    "أوكمونت": 1,
    "ألبلوط": 1,
    "نكيث": 1,
    "الأحبال": 1,
    "المجذاف": 1,
    "الجذف": 1,
    "محبس": 1,
    "المجداف": 1,
    "المجاذيف": 1,
    "المجذف": 1,
    "المجذفون": 1,
    "الواحات": 1,
    "الواحة": 1,
    "الشوفان": 1,
    "شوفاني": 1,
    "المجروش": 1,
    "القساوه": 1,
    "بطاعة": 1,
    "السجود": 1,
    "بانحناء": 1,
    "بطأطأة": 1,
    "المسلة": 1,
    "المسلات": 1,
    "أوبيرلن": 1,
    "طع": 1,
    "طعة": 1,
    "يطيع": 1,
    "وغير": 1,
    "المشوش": 1,
    "إرباكي": 1,
    "كشيئ": 1,
    "كأشيائ": 1,
    "يجسم": 1,
    "اعتراضية": 1,
    "المعطرض": 1,
    "علية": 1,
    "كريها": 1,
    "بموضوعية": 1,
    "بموضوعي": 1,
    "الموضوعية": 1,
    "المعطرد": 1,
    "المعطردون": 1,
    "توبيخ": 1,
    "مفلطح": 1,
    "كونه": 1,
    "القرابين": 1,
    "بتسيير": 1,
    "يلزم": 1,
    "بألتزام": 1,
    "بإلزام": 1,
    "إلتزم": 1,
    "الملزم": 1,
    "بإنحراف": 1,
    "الأنحراف": 1,
    "الإعوجاج": 1,
    "إلغائي": 1,
    "بنسيان": 1,
    "مستطيل": 1,
    "الاستطالة": 1,
    "المستطيلات": 1,
    "ببغاضة": 1,
    "بذيئ": 1,
    "بفحش": 1,
    "فحاشة": 1,
    "بحجب": 1,
    "تشييع": 1,
    "أوبسيكوي": 1,
    "ملحوظا": 1,
    "بملاحظة": 1,
    "شهوديا": 1,
    "المراصد": 1,
    "المرصد": 1,
    "لاحظ": 1,
    "لاحظة": 1,
    "يلاحظ": 1,
    "توجس": 1,
    "مهوس": 1,
    "يتوجس": 1,
    "التوجس": 1,
    "بتوجس": 1,
    "الإستحواذ": 1,
    "إستحواذي": 1,
    "الأستحواذ": 1,
    "السج": 1,
    "الزوال": 1,
    "الزائل": 1,
    "آيلا": 1,
    "للإهمال": 1,
    "الالغاء": 1,
    "ولادي": 1,
    "إخصائيو": 1,
    "العنادة": 1,
    "بجموح": 1,
    "الجموح": 1,
    "المعرقل": 1,
    "بإعاقة": 1,
    "إحصل": 1,
    "للحصول": 1,
    "مكتسب": 1,
    "إقتحم": 1,
    "مقتحم": 1,
    "المقتحم": 1,
    "يقتحم": 1,
    "ناتئ": 1,
    "الأقتحام": 1,
    "بغير": 1,
    "التحاش": 1,
    "الأكرينا": 1,
    "بلاستيكية": 1,
    "أوكام": 1,
    "حين": 1,
    "لآخر": 1,
    "التسبيب": 1,
    "قفوي": 1,
    "الإنسداد": 1,
    "الخارقة": 1,
    "الفوق": 1,
    "بالقوى": 1,
    "الشاغل": 1,
    "الشاغلون": 1,
    "مهنيا": 1,
    "إحتل": 1,
    "الأقيانوسية": 1,
    "بالمحيط": 1,
    "أوقيانوغرافي": 1,
    "أوشنسايد": 1,
    "القط": 1,
    "المغرة": 1,
    "أكاسيد": 1,
    "أوكونوماووك": 1,
    "أكتوبر/تشرين": 1,
    "الأظلاع": 1,
    "المثمنات": 1,
    "مضلع": 1,
    "بثمانية": 1,
    "الثماني": 1,
    "الأوكتان": 1,
    "محيط": 1,
    "أوكتاف": 1,
    "أكتافيا": 1,
    "أوكتيل": 1,
    "الأقتيليون": 1,
    "الثمانيني": 1,
    "الأخطبوطات": 1,
    "الأخطبوط": 1,
    "أسلافة": 1,
    "أود": 1,
    "المعدود": 1,
    "بغرابة": 1,
    "أفرط": 1,
    "أودر": 1,
    "أودربيرج": 1,
    "أوديسا": 1,
    "الأودين": 1,
    "للحكمة": 1,
    "عدادات": 1,
    "المميزة": 1,
    "أوديسيوس": 1,
    "أوديبي": 1,
    "إديبوس": 1,
    "أويديبالي": 1,
    "جوكاستا": 1,
    "الأصلية": 1,
    "الإرستد": 1,
    "الذبيحة": 1,
    "أوفينباخ": 1,
    "تبرعات": 1,
    "المصلين": 1,
    "مرتجلا": 1,
    "أيدي": 1,
    "ساعات": 1,
    "أمد": 1,
    "بضباط": 1,
    "الدواوين": 1,
    "الإمام": 1,
    "تأدية": 1,
    "بانعزال": 1,
    "الفرعي": 1,
    "أوفس": 1,
    "التسلل": 1,
    "الذريات": 1,
    "يأخذ": 1,
    "غالبا": 1,
    "أغلب": 1,
    "الأحيان": 1,
    "بتكرار": 1,
    "أوجدين": 1,
    "إنظر": 1,
    "المحملق": 1,
    "ينظر": 1,
    "الغولات": 1,
    "الغولة": 1,
    "أوه": 1,
    "أوهايو": 1,
    "الأوم": 1,
    "أومي": 1,
    "المزيتة": 1,
    "المزيت": 1,
    "المزيتون": 1,
    "زيتا": 1,
    "زيتيا": 1,
    "الزيتية": 1,
    "الزيوت": 1,
    "الحبة": 1,
    "يستخرج": 1,
    "منها": 1,
    "الزيت": 1,
    "أوكاموتو": 1,
    "أوكيناوا": 1,
    "أوكلاهوما": 1,
    "أولاف": 1,
    "أولدينبيرغ": 1,
    "أقدم": 1,
    "الموضوعات": 1,
    "أولدسموبيل": 1,
    "الدفلى": 1,
    "الأولفين": 1,
    "أوليج": 1,
    "السمن": 1,
    "شمي": 1,
    "أولجا": 1,
    "بحكومة": 1,
    "حكومة": 1,
    "أوليجوكليس": 1,
    "إحتكاري": 1,
    "إحتكار": 1,
    "أولن": 1,
    "يخن": 1,
    "الزيتون": 1,
    "أوليفير": 1,
    "أوليفيرز": 1,
    "الأوليفيتي": 1,
    "أوليفيا": 1,
    "أولسين": 1,
    "أولسون": 1,
    "أولمبيا": 1,
    "أوليمبي": 1,
    "الأولمبية": 1,
    "أوليمبوس": 1,
    "أوماها": 1,
    "شكاوى": 1,
    "الأوميغا": 1,
    "صحون": 1,
    "أوميكرون": 1,
    "حذفه": 1,
    "أومني": 1,
    "والحيوان": 1,
    "/": 1,
    "أونا": 1,
    "خطوة": 1,
    "أونيدا": 1,
    "الوحدانية": 1,
    "تلك": 1,
    "البصل": 1,
    "المشاهدون": 1,
    "متفرج": 1,
    "فقط.": 1,
    "بالمحاكاة": 1,
    "أونونداجا": 1,
    "ريع": 1,
    "أونتاريو": 1,
    "وجوديا": 1,
    "الجزع": 1,
    "وسيت": 1,
    "ودلس": 1,
    "ويحي": 1,
    "نز": 1,
    "النزيز": 1,
    "براق": 1,
    "الأوبك": 1,
    "الفتاحة": 1,
    "الفتاحات": 1,
    "علني": 1,
    "الإنفتاح": 1,
    "أوبن": 1,
    "أوفيس": 1,
    "مفتوحة": 1,
    "للمعالجة": 1,
    "الجراحية": 1,
    "يطبق": 1,
    "عليهم": 1,
    "الأوبرات": 1,
    "إشتغل": 1,
    "يشتغل": 1,
    "بالأوبرا": 1,
    "شغال": 1,
    "بفعالية": 1,
    "المشغلون": 1,
    "متصلة": 1,
    "بعضها": 1,
    "ناظور": 1,
    "المنومة": 1,
    "إعتقد": 1,
    "الرأي": 1,
    "يعاند": 1,
    "الآراء": 1,
    "الأفيون": 1,
    "الأبوسوم": 1,
    "أوبينهايمر": 1,
    "المعارضون": 1,
    "الإنتهازية": 1,
    "إنتهازي": 1,
    "بانتهازية": 1,
    "الإنتهازيون": 1,
    "للمعارضة": 1,
    "يعارض": 1,
    "التضاد": 1,
    "إضطهد": 1,
    "مضطهد": 1,
    "يضطهد": 1,
    "الإضطهاد": 1,
    "الجور": 1,
    "المضطهد": 1,
    "المضطهدون": 1,
    "متمني": 1,
    "بصريا": 1,
    "فاحص": 1,
    "فاحصو": 1,
    "البصريات": 1,
    "الأمثلية": 1,
    "التفاؤل": 1,
    "المتفائل": 1,
    "بتفائل": 1,
    "باختيار": 1,
    "الخيارات": 1,
    "الكتروضوئي": 1,
    "بإخصائي": 1,
    "العرافة": 1,
    "لقاعدة": 1,
    "مهتفو": 1,
    "نبوئي": 1,
    "أورالس": 1,
    "أورانجيروت": 1,
    "خطابي": 1,
    "مستدير": 1,
    "المدار": 1,
    "مداري": 1,
    "المداريون": 1,
    "حوله": 1,
    "المتتبع": 1,
    "المتتبعات": 1,
    "المدارات": 1,
    "الأجرام": 1,
    "السماوية": 1,
    "الأوركسترا": 1,
    "أوركسترالي": 1,
    "أوركسترا": 1,
    "السحلب": 1,
    "السحالب": 1,
    "الآمر": 1,
    "المحن": 1,
    "الممرضون": 1,
    "الترتيبي": 1,
    "المألوف": 1,
    "العمودي": 1,
    "العمودية": 1,
    "الرسامات": 1,
    "الأوريجانو": 1,
    "أوريغون": 1,
    "الخامات": 1,
    "أوريستيز": 1,
    "كليتيمنيسترا": 1,
    "الأورجانزا": 1,
    "أورجانيسميك": 1,
    "الأرغن": 1,
    "للتنظيم": 1,
    "تنظيمي": 1,
    "بتنظيم": 1,
    "بالمركب": 1,
    "هزة": 1,
    "لذة": 1,
    "هزات": 1,
    "تشبه": 1,
    "العربيدة": 1,
    "المشرق": 1,
    "الفوهة": 1,
    "الفوهات": 1,
    "الأصالات": 1,
    "الأصليون": 1,
    "إنشأ": 1,
    "منشوء": 1,
    "المنشأ": 1,
    "الإبتكارات": 1,
    "بدئي": 1,
    "المنشئ": 1,
    "المنشؤون": 1,
    "الاصول": 1,
    "أورينوكو": 1,
    "فنزويلا": 1,
    "الأوريول": 1,
    "أوركني": 1,
    "أورلندو": 1,
    "أورلينس": 1,
    "أورلي": 1,
    "ضاحية": 1,
    "بباريس": 1,
    "بزخرفة": 1,
    "أورناتينيس": 1,
    "طيوري": 1,
    "أورونو": 1,
    "الجهورية": 1,
    "اليتيم": 1,
    "ملجأ": 1,
    "الأيتام": 1,
    "ملاجئ": 1,
    "اليتامى": 1,
    "اليتم": 1,
    "التيتيم": 1,
    "أور": 1,
    "هوكي": 1,
    "أورتيجا": 1,
    "أورثيكون": 1,
    "كاميرا": 1,
    "أورثوكروماتي": 1,
    "لجميع": 1,
    "الأورثوكلاز": 1,
    "تقويم": 1,
    "الإعتياديون": 1,
    "التعامدية": 1,
    "إملائي": 1,
    "إملائيا": 1,
    "التهجي": 1,
    "تجبيري": 1,
    "جراحة": 1,
    "التشوهات": 1,
    "الجسمانية": 1,
    "أورثوفوسفيت": 1,
    "الفوسفوريك": 1,
    "أورفيل": 1,
    "أورويل": 1,
    "أورويلي": 1,
    "أوساكا": 1,
    "أوسبيرت": 1,
    "أوسبورن": 1,
    "أوسكار": 1,
    "تذبذب": 1,
    "متذبذب": 1,
    "يتذبذب": 1,
    "التذبذبات": 1,
    "المذبذب": 1,
    "المذبذبات": 1,
    "تذبذي": 1,
    "الذبذبات": 1,
    "راسمة": 1,
    "راسمات": 1,
    "لمسي": 1,
    "أوسجوود": 1,
    "أوشكوش": 1,
    "أوزوريس": 1,
    "أوسلو": 1,
    "الأزميوم": 1,
    "التنافذ": 1,
    "تنافذي": 1,
    "الشماط": 1,
    "عظمي": 1,
    "التعظم": 1,
    "تحجر": 1,
    "زعما": 1,
    "زاعم": 1,
    "العظمي": 1,
    "تقويمي": 1,
    "مجبرو": 1,
    "تجبير": 1,
    "أوسترن": 1,
    "السايس": 1,
    "أوستراكود": 1,
    "قشرية": 1,
    "أوستراندر": 1,
    "النعامة": 1,
    "النعامات": 1,
    "أوزوالد": 1,
    "عطيل": 1,
    "إختلاف": 1,
    "الآخرون": 1,
    "الآخر": 1,
    "الغيبية": 1,
    "أخروي": 1,
    "أوتيس": 1,
    "أوتاوا": 1,
    "عثماني": 1,
    "واجادوجو": 1,
    "بوركينا": 1,
    "فاسو": 1,
    "آخ": 1,
    "Ought": 1,
    "الأونس": 1,
    "الأونسات": 1,
    "نا": 1,
    "لنا": 1,
    "بنفسي": 1,
    "أنفسنا": 1,
    "للخارج": 1,
    "منتهي": 1,
    "زويد": 1,
    "فق": 1,
    "بز": 1,
    "مزايد": 1,
    "البز": 1,
    "يبز": 1,
    "متوجه": 1,
    "التفشي": 1,
    "الإفاقة": 1,
    "الضجات": 1,
    "التفطر": 1,
    "التقادم": 1,
    "مجتاز": 1,
    "يجتاز": 1,
    "الإجتياز": 1,
    "مبزوز": 1,
    "وتيد": 1,
    "ملعب": 1,
    "هزم": 1,
    "يتفوق": 1,
    "بالحيلة": 1,
    "بحسن": 1,
    "السفرة": 1,
    "السفرات": 1,
    "يدوم": 1,
    "لايسكن": 1,
    "عمله": 1,
    "لايسكنون": 1,
    "عملهم": 1,
    "إبق": 1,
    "باق": 1,
    "وجهات": 1,
    "إهزم": 1,
    "عددا": 1,
    "مفاق": 1,
    "إفاقة": 1,
    "يفيق": 1,
    "المتعالج": 1,
    "المتعالجون": 1,
    "الخارجيون": 1,
    "إلعب": 1,
    "ملعوب": 1,
    "المخفر": 1,
    "المخافر": 1,
    "إنهل": 1,
    "السيل": 1,
    "السيول": 1,
    "أنتج": 1,
    "الإنتاج": 1,
    "إجتز": 1,
    "بالمدى": 1,
    "تحفظ": 1,
    "ينكشف": 1,
    "بع": 1,
    "الإقصاء": 1,
    "إغلب": 1,
    "الغلب": 1,
    "يغلب": 1,
    "مبيع": 1,
    "إمتد": 1,
    "بعدد": 1,
    "ألبسة": 1,
    "التنزه": 1,
    "زن": 1,
    "موزون": 1,
    "يزن": 1,
    "بالذكاء": 1,
    "أبلى": 1,
    "وزو": 1,
    "الإهليليج": 1,
    "بيضوي": 1,
    "أوفالنيس": 1,
    "الإهليليجات": 1,
    "مبايض": 1,
    "المبايض": 1,
    "أوفاتيس": 1,
    "الحفاوة": 1,
    "الإحتفائية": 1,
    "أوفينبيرد": 1,
    "إنتهى": 1,
    "حالي": 1,
    "متصنع": 1,
    "أوفيراج": 1,
    "التعسف": 1,
    "بدلات": 1,
    "خشع": 1,
    "مخشع": 1,
    "رجح": 1,
    "مرجح": 1,
    "يرجح": 1,
    "إقهر": 1,
    "متعجرف": 1,
    "يزايد": 1,
    "يغلي": 1,
    "حرق": 1,
    "الموجود": 1,
    "تغلب": 1,
    "مغيم": 1,
    "التغييم": 1,
    "يغيم": 1,
    "السعر": 1,
    "زيادات": 1,
    "بالغيوم": 1,
    "بمعطف": 1,
    "المتغلب": 1,
    "يتغلب": 1,
    "التغلب": 1,
    "تحميض": 1,
    "المطلوب": 1,
    "بولغ": 1,
    "زائدة": 1,
    "رصيد": 1,
    "زد": 1,
    "مبالغة": 1,
    "زيد": 1,
    "سق": 1,
    "الملتهم": 1,
    "تأكيد": 1,
    "ينهك": 1,
    "إنتخم": 1,
    "يملأ": 1,
    "فوقه": 1,
    "الفوقي": 1,
    "الرعي": 1,
    "برفع": 1,
    "الطنف": 1,
    "الإصلاحات": 1,
    "الشاملة": 1,
    "إسخن": 1,
    "سخونة": 1,
    "يسخن": 1,
    "إمتاع": 1,
    "للقفز": 1,
    "معتلى": 1,
    "برا": 1,
    "إقفز": 1,
    "إعتل": 1,
    "طل": 1,
    "مطلول": 1,
    "الطل": 1,
    "يطل": 1,
    "يتخطى": 1,
    "تخطى": 1,
    "تخطي": 1,
    "مما": 1,
    "يعملون": 1,
    "مزحم": 1,
    "الفائض": 1,
    "تكديس": 1,
    "السكاني": 1,
    "جبارة": 1,
    "إنتاج": 1,
    "مؤهلات": 1,
    "تزيد": 1,
    "إجتح": 1,
    "مغالى": 1,
    "يغالي": 1,
    "مغالاة": 1,
    "للإصطدام": 1,
    "منقوض": 1,
    "ينقض": 1,
    "الإجتياح": 1,
    "التجاوزات": 1,
    "أشرف": 1,
    "أوفيرسي": 1,
    "الإشراف": 1,
    "يشرف": 1,
    "مبيعات": 1,
    "أوفيرشوت": 1,
    "التبسيطات": 1,
    "المقاسات": 1,
    "أطل": 1,
    "إطالة": 1,
    "يطيل": 1,
    "أطيل": 1,
    "الطفح": 1,
    "صبغ": 1,
    "صبغها": 1,
    "جاوز": 1,
    "مجاوز": 1,
    "المجاوزة": 1,
    "يجاوز": 1,
    "يخزن": 1,
    "يشدد": 1,
    "يرهق": 1,
    "ويصوب": 1,
    "حش": 1,
    "محشى": 1,
    "التحشية": 1,
    "يحشي": 1,
    "اكثر": 1,
    "يزود": 1,
    "بسكر": 1,
    "بالضرائب": 1,
    "إرهاق": 1,
    "أوفيرتيريد": 1,
    "أوفيرتنيس": 1,
    "يتدرب": 1,
    "تدرب": 1,
    "المفاتحات": 1,
    "مقلوب": 1,
    "يفرط": 1,
    "إفراط": 1,
    "إغمر": 1,
    "يغمر": 1,
    "أجهد": 1,
    "ينمق": 1,
    "نمق": 1,
    "مجهد": 1,
    "أوفيرزيل": 1,
    "الإتقاد": 1,
    "أوفيد": 1,
    "روماني": 1,
    "بيوض": 1,
    "البيضويات": 1,
    "بض": 1,
    "مبيض": 1,
    "التبييض": 1,
    "تبييضي": 1,
    "أوين": 1,
    "أوينز": 1,
    "ولير": 1,
    "البوم": 1,
    "كبومة": 1,
    "البومية": 1,
    "مملوك": 1,
    "المالكون": 1,
    "الأكسالات": 1,
    "الأملاح": 1,
    "أكساليكي": 1,
    "يجرها": 1,
    "أكسفورد": 1,
    "المؤكسد": 1,
    "يؤكسد": 1,
    "الأكسدة": 1,
    "مؤكسد": 1,
    "مؤكسدة": 1,
    "الأكسيدات": 1,
    "أكسد": 1,
    "مكسد": 1,
    "المؤكسدون": 1,
    "يكسد": 1,
    "الإكساد": 1,
    "أوكسنارد": 1,
    "أكسفوردي": 1,
    "الأوكسجين": 1,
    "بالأكسجين": 1,
    "بالنعام": 1,
    "أوزارك": 1,
    "أوزاركس": 1,
    "ميسوري": 1,
    "الأوزون": 1,
    "أوزوني": 1,
    "أوزن": 1,
    "موزن": 1,
    "بالأوزون": 1,
    "يوزن": 1,
    "أوزي": 1,
    "بنسلفانيا": 1,
    "باسكال": 1,
    "بابلو": 1,
    "بابست": 1,
    "مخطو": 1,
    "الغليظ": 1,
    "الهادي": 1,
    "بهدوء": 1,
    "مبدأ": 1,
    "المتسالمون": 1,
    "الرزمة": 1,
    "المغلفون": 1,
    "باكارد": 1,
    "الحزامون": 1,
    "مرزوم": 1,
    "باكوود": 1,
    "الحلف": 1,
    "مبطن": 1,
    "المجدف": 1,
    "الوسادات": 1,
    "باجانيني": 1,
    "سرقة": 1,
    "الفخم": 1,
    "الفخمة": 1,
    "مرقم": 1,
    "البيجر": 1,
    "قاعدةحوارإعدادالصفحة": 1,
    "ترقيم": 1,
    "المعبد": 1,
    "السطل": 1,
    "السطول": 1,
    "التألم": 1,
    "تصويري": 1,
    "بيجامة": 1,
    "فضفاضة": 1,
    "باكستان": 1,
    "الباكستاني": 1,
    "الباكستانيون": 1,
    "بالادن": 1,
    "إستساغة": 1,
    "بلذة": 1,
    "تذوق": 1,
    "متذوق": 1,
    "يتذوق": 1,
    "الأذواق": 1,
    "بلاطي": 1,
    "متمتعون": 1,
    "بامتيازات": 1,
    "بالو": 1,
    "بالازي": 1,
    "بالازو": 1,
    "الحضيرة": 1,
    "شحب": 1,
    "لونه": 1,
    "الشحب": 1,
    "مضت": 1,
    "باليرمو": 1,
    "فلسطين": 1,
    "الفلسطيني": 1,
    "لفظة": 1,
    "تقرأ": 1,
    "طردا": 1,
    "وعكسا": 1,
    "ألفاظ": 1,
    "وعكسيا": 1,
    "صمامات": 1,
    "للمجتمع": 1,
    "بالفن": 1,
    "بالاديو": 1,
    "البالاديوم": 1,
    "تخفيفي": 1,
    "مخففات": 1,
    "الإيهان": 1,
    "النخلة": 1,
    "راحي": 1,
    "بالمير": 1,
    "بالميتو": 1,
    "النخيل": 1,
    "بالمنج": 1,
    "قارىء": 1,
    "بالومار": 1,
    "التداول": 1,
    "للتداول": 1,
    "تسمح": 1,
    "تتناول": 1,
    "إخفق": 1,
    "باميلا": 1,
    "السهوب": 1,
    "البامبرز": 1,
    "الكراريس": 1,
    "الحاسم": 1,
    "الحاسمة": 1,
    "بنما": 1,
    "هبط": 1,
    "بالطائرة": 1,
    "بسلام": 1,
    "هبوط": 1,
    "بانشو": 1,
    "البنكرياس": 1,
    "بنكرياسي": 1,
    "الباندا": 1,
    "باندانس": 1,
    "التقويد": 1,
    "البندور": 1,
    "أفشت": 1,
    "الأرض.": 1,
    "المطبوخة": 1,
    "بانيد": 1,
    "منصة": 1,
    "تنصيب": 1,
    "بمنصة": 1,
    "بألواح": 1,
    "مستجدى": 1,
    "المقالي": 1,
    "الإستجداء": 1,
    "باقة": 1,
    "ورد": 1,
    "المتجبر": 1,
    "العديلات": 1,
    "الإستعراضات": 1,
    "بانورامي": 1,
    "بانورامية": 1,
    "الثالوث": 1,
    "إلهث": 1,
    "بالكون": 1,
    "وخالقه": 1,
    "المهمين": 1,
    "بانتوميميد": 1,
    "التمثيليات": 1,
    "باولي": 1,
    "البابوية": 1,
    "باباو": 1,
    "بابايا": 1,
    "بابين": 1,
    "غلاف": 1,
    "ورقي": 1,
    "الاعلانات": 1,
    "لاصقو": 1,
    "لايحتاج": 1,
    "للورق": 1,
    "ثقالة": 1,
    "ثقالات": 1,
    "الكتابي": 1,
    "النتوئات": 1,
    "حليمي": 1,
    "باباس": 1,
    "بابوا": 1,
    "البردي": 1,
    "المظلي": 1,
    "المكافىء": 1,
    "قطعي": 1,
    "مكافىء": 1,
    "بالمظلة": 1,
    "بالمظلات": 1,
    "المظليون": 1,
    "مستعرض": 1,
    "بارادر": 1,
    "الفردوسات": 1,
    "تفارقي": 1,
    "للمفارقة": 1,
    "المفارقة": 1,
    "نفط": 1,
    "باراجونايت": 1,
    "الفقرة": 1,
    "مفقر": 1,
    "الفقرات": 1,
    "برغواي": 1,
    "الببغاوات": 1,
    "باللغة": 1,
    "المحاذية": 1,
    "المتوازي": 1,
    "مسلسلا": 1,
    "المسلسل": 1,
    "التوازي": 1,
    "متوازيات": 1,
    "المتوازيات": 1,
    "المشل": 1,
    "البارامغناطيسي": 1,
    "باراميسيا": 1,
    "البارامتر": 1,
    "البارامترات": 1,
    "حدودي": 1,
    "بالحدودية": 1,
    "حدودية": 1,
    "العشيق": 1,
    "بارامس": 1,
    "المذعور": 1,
    "المذعورون": 1,
    "الموسوس": 1,
    "لايفسره": 1,
    "يفسرها": 1,
    "بدرابزين": 1,
    "الحاجيات": 1,
    "المتنوعة": 1,
    "أعادة": 1,
    "خوارق": 1,
    "اللاشعور": 1,
    "الطفيلي": 1,
    "الطفيليات": 1,
    "طفيلي": 1,
    "طفيليا": 1,
    "الشمسية": 1,
    "الشمسيات": 1,
    "السمبتاوي": 1,
    "إسلق": 1,
    "السلق": 1,
    "يسلق": 1,
    "إعطش": 1,
    "يعطش": 1,
    "العطش": 1,
    "رق": 1,
    "للصفح": 1,
    "صافحة": 1,
    "معفو": 1,
    "الصافحون": 1,
    "الوالد": 1,
    "الأبوة": 1,
    "أبويا": 1,
    "إعتراضي": 1,
    "أبوين": 1,
    "الأباء": 1,
    "السالخ": 1,
    "يقشر": 1,
    "باريتو": 1,
    "البارفية": 1,
    "عملت": 1,
    "بالقشدة": 1,
    "للمراهنة": 1,
    "القلامة": 1,
    "باريس": 1,
    "الأبرشيات": 1,
    "الأبرشيون": 1,
    "الباريسي": 1,
    "بلوفر": 1,
    "بالفراء": 1,
    "للقطب": 1,
    "واقف": 1,
    "باركر": 1,
    "الفضوليون": 1,
    "باركيرسبيرغ": 1,
    "باركهاوس": 1,
    "باركنسن": 1,
    "متنزه": 1,
    "المتنزهات": 1,
    "بالربح": 1,
    "المفاوضة": 1,
    "البرلمان": 1,
    "برلماني": 1,
    "البرلمانات": 1,
    "البارميجان": 1,
    "أفق": 1,
    "بضيق": 1,
    "الساخرة": 1,
    "السراح": 1,
    "أفرج": 1,
    "السجين": 1,
    "بشروط": 1,
    "السجناء": 1,
    "المطلقوا": 1,
    "الإفراج": 1,
    "المسجون": 1,
    "بالباركيه": 1,
    "تلبيس": 1,
    "سلمون": 1,
    "الإمعه": 1,
    "التفادي": 1,
    "المعدلات": 1,
    "أعرب": 1,
    "3.258": 1,
    "ضوئية": 1,
    "معرب": 1,
    "المعرب": 1,
    "المعربات": 1,
    "يعرب": 1,
    "بارسيفال": 1,
    "ريتشارد": 1,
    "فاجنر": 1,
    "البقدنوس": 1,
    "الخوريون": 1,
    "إشترك": 1,
    "يشترك": 1,
    "الإشتراك": 1,
    "مفترق": 1,
    "بارثينون": 1,
    "لأثينا": 1,
    "جزئيات": 1,
    "مشارك": 1,
    "الإشتراكات": 1,
    "تشاركي": 1,
    "الفعلي": 1,
    "الفعلية": 1,
    "جزيئيات": 1,
    "إستمتع": 1,
    "المفارقات": 1,
    "المجزىء": 1,
    "مجزئ": 1,
    "شورك": 1,
    "الشراكة": 1,
    "الشراكات": 1,
    "الحجول": 1,
    "الإستمتاع": 1,
    "باسادينا": 1,
    "سالك": 1,
    "ركض": 1,
    "باسيك": 1,
    "الباند": 1,
    "فلتر": 1,
    "الجاري": 1,
    "موديل": 1,
    "التصميغ": 1,
    "ممرور": 1,
    "المناول": 1,
    "السبيل": 1,
    "المناولو": 1,
    "عابرو": 1,
    "الترخيصات": 1,
    "وهناك": 1,
    "أحاسيس": 1,
    "لحمايته": 1,
    "السلبيون": 1,
    "الماستر": 1,
    "لعدة": 1,
    "قفول": 1,
    "جواز": 1,
    "جوازات": 1,
    "الباستا": 1,
    "المعجون": 1,
    "البستل": 1,
    "رسغ": 1,
    "الدابة": 1,
    "باستيرناك": 1,
    "المعاجين": 1,
    "باستير": 1,
    "البسترة": 1,
    "بستر": 1,
    "مبستر": 1,
    "يبستر": 1,
    "التبستر": 1,
    "مأخوذة": 1,
    "طابع": 1,
    "يوضع": 1,
    "كتعريف": 1,
    "لهذا": 1,
    "عجيني": 1,
    "فطائر": 1,
    "السكري": 1,
    "العجينية": 1,
    "اللصق": 1,
    "رعوي": 1,
    "المرعى": 1,
    "الغنم": 1,
    "المراعي": 1,
    "باتاجونيا": 1,
    "الرقعة": 1,
    "الرقاع": 1,
    "يرقع": 1,
    "الرقع": 1,
    "رقع": 1,
    "اليافوخ": 1,
    "الطبق": 1,
    "براءة": 1,
    "للتسجيل": 1,
    "إختراع": 1,
    "براءات": 1,
    "الربانية": 1,
    "باترسون": 1,
    "اليافوخات": 1,
    "أمكنة": 1,
    "المرضي": 1,
    "باثولوجي": 1,
    "التأسي": 1,
    "المريض": 1,
    "بصبر": 1,
    "المرضى": 1,
    "الزنجار": 1,
    "الباحة": 1,
    "الباحات": 1,
    "باتلي": 1,
    "يرأس": 1,
    "الأبوي": 1,
    "باترايس": 1,
    "باتريشيا": 1,
    "باتريك": 1,
    "بوراثة": 1,
    "بالباباوات": 1,
    "ومذهب": 1,
    "الباباوات": 1,
    "الدورية": 1,
    "محروس": 1,
    "الدوريات": 1,
    "الراعية": 1,
    "سلطوية": 1,
    "الربتات": 1,
    "باتسيس": 1,
    "بتسي": 1,
    "مربوت": 1,
    "باتيرير": 1,
    "باتيرسون": 1,
    "باتي": 1,
    "مطرب": 1,
    "أوبرا": 1,
    "إيطالية": 1,
    "أسباني": 1,
    "الربت": 1,
    "باتن": 1,
    "بول": 1,
    "بولا": 1,
    "بوليت": 1,
    "بولي": 1,
    "بولين": 1,
    "بولينج": 1,
    "بولو": 1,
    "بولسين": 1,
    "بولسون": 1,
    "بولوس": 1,
    "تكرشا": 1,
    "متكرش": 1,
    "المستديم": 1,
    "المهلة": 1,
    "المهل": 1,
    "مهد": 1,
    "الأرصفة": 1,
    "المرصف": 1,
    "يمهد": 1,
    "السرادق": 1,
    "السرادقات": 1,
    "التبليط": 1,
    "بافلوف": 1,
    "الدقرة": 1,
    "البيدق": 1,
    "باوني": 1,
    "الراهن": 1,
    "البيادق": 1,
    "الرهونات": 1,
    "الكفوف": 1,
    "بوتكيت": 1,
    "تكسب": 1,
    "الراتب": 1,
    "صكوك": 1,
    "الرواتب": 1,
    "يقومون": 1,
    "الصافية": 1,
    "صرافو": 1,
    "بينيز": 1,
    "بايسون": 1,
    "باز": 1,
    "كمبيوتر": 1,
    "كومبيوترات": 1,
    "أنتجت": 1,
    "ديجيتال": 1,
    "الباسفيكي": 1,
    "البازلاء": 1,
    "بيبودي": 1,
    "السلمية": 1,
    "سلمية": 1,
    "سلام": 1,
    "الخوخ": 1,
    "بلغ": 1,
    "خوخي": 1,
    "الإمتياز": 1,
    "بيتشتري": 1,
    "الطاووس": 1,
    "الطواويس": 1,
    "العجيج": 1,
    "مدوى": 1,
    "التدوية": 1,
    "الفستق": 1,
    "الكمثرى": 1,
    "اللؤلؤية": 1,
    "البرليت": 1,
    "بركاني": 1,
    "اللآلئ": 1,
    "بيرلستون": 1,
    "بركانية": 1,
    "زجاجية": 1,
    "الأجاص": 1,
    "بيرسون": 1,
    "بالفلاح": 1,
    "والفلاحة": 1,
    "الفلاحين": 1,
    "الخث": 1,
    "مرصوف": 1,
    "بالحصى": 1,
    "البقان": 1,
    "اقتراف": 1,
    "للإثم": 1,
    "المكيال": 1,
    "المكاييل": 1,
    "بيكوس": 1,
    "صدري": 1,
    "بالصدر": 1,
    "مألوفين": 1,
    "الدواسة": 1,
    "إستعمل": 1,
    "الدواسات": 1,
    "المتحذلق": 1,
    "بتحذلق": 1,
    "التحذلقات": 1,
    "التحذلق": 1,
    "الركيزة": 1,
    "الركائز": 1,
    "بيدياتريك": 1,
    "بالأقدام": 1,
    "واظافرها": 1,
    "بالـ": 1,
    "بيديكور": 1,
    "مؤصل": 1,
    "بقوس": 1,
    "الخطو": 1,
    "بيدرو": 1,
    "تبول": 1,
    "الخاطفة": 1,
    "نظرة": 1,
    "خاطفة": 1,
    "بوو": 1,
    "القشارة": 1,
    "قشارة": 1,
    "مختلسو": 1,
    "الند": 1,
    "المثيل": 1,
    "يتبول": 1,
    "بيفيرس": 1,
    "بيوي": 1,
    "البيغاسوس": 1,
    "المجنح": 1,
    "بفتحات": 1,
    "لتثبيت": 1,
    "شماعات": 1,
    "التشبيك": 1,
    "بيجي": 1,
    "بازدراء": 1,
    "بيلهام": 1,
    "البجع": 1,
    "البجعات": 1,
    "البلاجرا": 1,
    "مكور": 1,
    "لإخفاء": 1,
    "تثبيت": 1,
    "الفروة": 1,
    "مرجوم": 1,
    "الرجم": 1,
    "بيمبروك": 1,
    "طعام": 1,
    "جزائي": 1,
    "العقوبات": 1,
    "الكفارة": 1,
    "آلهة": 1,
    "الرومانية": 1,
    "بالقلم": 1,
    "أقلام": 1,
    "المعلقات": 1,
    "بانتظار": 1,
    "بينديلتون": 1,
    "بندولي": 1,
    "يتأرجح": 1,
    "متدلي": 1,
    "البندول": 1,
    "البندولات": 1,
    "بينيلوب": 1,
    "للإختراق": 1,
    "ثاقب": 1,
    "الإختراقية": 1,
    "المخترق": 1,
    "المخترقون": 1,
    "البطريق": 1,
    "البطاريق": 1,
    "بينه": 1,
    "البنسلين": 1,
    "جزيري": 1,
    "أشباه": 1,
    "سجون": 1,
    "المطاوي": 1,
    "بينا": 1,
    "المثلث": 1,
    "المثلثة": 1,
    "البنسات": 1,
    "بينسلفانيا": 1,
    "بينروز": 1,
    "بينساكولا": 1,
    "التقاعدي": 1,
    "تقاعدي": 1,
    "المتقاعدون": 1,
    "أحال": 1,
    "التقاعد": 1,
    "المتقاعد": 1,
    "التقاعدية": 1,
    "الخماسي": 1,
    "بينتاد": 1,
    "مخمس": 1,
    "المخمسات": 1,
    "خماسي": 1,
    "بينتان": 1,
    "الخماسية": 1,
    "العنصرة": 1,
    "السقيفة": 1,
    "السقائف": 1,
    "المشعشع": 1,
    "الفاونيا": 1,
    "الفاوانيا": 1,
    "مأهول": 1,
    "بيوريا": 1,
    "أحيا": 1,
    "حبوب": 1,
    "متبل": 1,
    "الباباروني": 1,
    "متسرع": 1,
    "البيبسي": 1,
    "بيبسيكو": 1,
    "عملاقة": 1,
    "لإنتاج": 1,
    "البيبسين": 1,
    "بيبتيديس": 1,
    "الببتيد": 1,
    "حامضين": 1,
    "أمينيين": 1,
    "تجولي": 1,
    "بإدراك": 1,
    "أدرك": 1,
    "الملاحظ": 1,
    "الملاحظون": 1,
    "يدرك": 1,
    "بالمائة": 1,
    "التصورات": 1,
    "الإدراكات": 1,
    "العلياء": 1,
    "البركلورات": 1,
    "بيرسيفال": 1,
    "مترشح": 1,
    "يترشح": 1,
    "المسامات": 1,
    "دورق": 1,
    "راووقات": 1,
    "طرقي": 1,
    "الإيقاعية": 1,
    "بيرسي": 1,
    "القطامي": 1,
    "بيريز": 1,
    "للكمال": 1,
    "الكمالية": 1,
    "منشد": 1,
    "منشدو": 1,
    "يتقن": 1,
    "الغدر": 1,
    "يثقب": 1,
    "التثقيب": 1,
    "الخرم": 1,
    "الأداءات": 1,
    "المؤدي": 1,
    "المؤدون": 1,
    "العطارات": 1,
    "العطارة": 1,
    "الإرواء": 1,
    "بيرجامون": 1,
    "العريشة": 1,
    "بيريكليسي": 1,
    "أثيني": 1,
    "بدعمه": 1,
    "للديمقراطية": 1,
    "بريكليز": 1,
    "البريدوتيت": 1,
    "نارية": 1,
    "الحضيض": 1,
    "دوران": 1,
    "الكوكب": 1,
    "بريلا": 1,
    "بخطورة": 1,
    "بالغة": 1,
    "محيطيا": 1,
    "دوريات": 1,
    "بالضرس": 1,
    "الملحقة": 1,
    "الإسهابات": 1,
    "إسهابي": 1,
    "الناظور": 1,
    "المناظير": 1,
    "سريعي": 1,
    "بفناء": 1,
    "بتلف": 1,
    "هالك": 1,
    "مابين": 1,
    "والسائل": 1,
    "منصهر": 1,
    "البريتون": 1,
    "الباروكة": 1,
    "الباروكات": 1,
    "حلزون": 1,
    "حلزونات": 1,
    "إكذب": 1,
    "الحانث": 1,
    "بالقسم": 1,
    "يكذب": 1,
    "الحنث": 1,
    "بيركينس": 1,
    "المنافع": 1,
    "بيرل": 1,
    "التجعيدة": 1,
    "بيرمالوي": 1,
    "نافذ": 1,
    "للنفاذ": 1,
    "مسامات": 1,
    "تخلل": 1,
    "متخلل": 1,
    "يتخلل": 1,
    "التخلل": 1,
    "التغلغل": 1,
    "البرمي": 1,
    "الجوازية": 1,
    "مباحة": 1,
    "التجاعيد": 1,
    "المميت": 1,
    "المنمقة": 1,
    "البيروكسيد": 1,
    "المرتكب": 1,
    "الجناة": 1,
    "أدم": 1,
    "يديم": 1,
    "الإدامة": 1,
    "حائر": 1,
    "بيري": 1,
    "إضطهادي": 1,
    "فرساوس": 1,
    "دانة": 1,
    "مثابر": 1,
    "ثابر": 1,
    "يثابر": 1,
    "بيرشينج": 1,
    "فارس": 1,
    "الفارسي": 1,
    "الكاكي": 1,
    "إستمر": 1,
    "الملح": 1,
    "تجعلها": 1,
    "الشخصيات": 1,
    "إضفاء": 1,
    "يضفي": 1,
    "الإضفاء": 1,
    "شخصيا": 1,
    "إنتحل": 1,
    "ينتحل": 1,
    "إنتحال": 1,
    "المنظور": 1,
    "منظوري": 1,
    "المنظورات": 1,
    "البرسبيكس": 1,
    "تعرقي": 1,
    "تعرق": 1,
    "متعرق": 1,
    "يتعرق": 1,
    "التعرق": 1,
    "للإقناع": 1,
    "مخصوص": 1,
    "الخص": 1,
    "بيرث": 1,
    "أقلق": 1,
    "يوتر": 1,
    "الإقلاق": 1,
    "بيرو": 1,
    "بيروسابل": 1,
    "التمعن": 1,
    "مطالع": 1,
    "القاريء": 1,
    "بتمعن": 1,
    "يطالع": 1,
    "المطالعة": 1,
    "البيروي": 1,
    "البيرويون": 1,
    "المعاندة": 1,
    "الإنسلاخ": 1,
    "والصواب": 1,
    "للشذوذ": 1,
    "شاذا": 1,
    "البسيتا": 1,
    "أسبانيا": 1,
    "المتشائم": 1,
    "بتشاؤم": 1,
    "المتشائمون": 1,
    "الآفة": 1,
    "المدقة": 1,
    "سحق": 1,
    "المدقات": 1,
    "الآفات": 1,
    "الأليف": 1,
    "التويجية": 1,
    "ببتول": 1,
    "تويجي": 1,
    "بيتر": 1,
    "تلاشى": 1,
    "يتلاشى": 1,
    "بيترسبيرغ": 1,
    "بيترسين": 1,
    "بيترسون": 1,
    "العريضة": 1,
    "ملتمس": 1,
    "الملتمس": 1,
    "العرائض": 1,
    "بيتكيويتش": 1,
    "المازور": 1,
    "بيتري": 1,
    "التصخر": 1,
    "بيتروكيماوي": 1,
    "بيتروكيماويات": 1,
    "الأليفة": 1,
    "ملاعب": 1,
    "بيتيرز": 1,
    "بيتيبون": 1,
    "لابسة": 1,
    "داخلية": 1,
    "أتفه": 1,
    "الملاعبة": 1,
    "التغضب": 1,
    "الإستفزاز": 1,
    "البتونيا": 1,
    "البيجو": 1,
    "بيووكي": 1,
    "البيوتر": 1,
    "صبار": 1,
    "بيتن": 1,
    "الفنيك": 1,
    "الفايزر": 1,
    "فيدرا": 1,
    "باسيفا": 1,
    "ثيسيس": 1,
    "الفيروس": 1,
    "تلتهم": 1,
    "الفلنجر": 1,
    "إستراليا": 1,
    "الفلروب": 1,
    "سباح": 1,
    "قضيبي": 1,
    "الخيالات": 1,
    "الفرعون": 1,
    "الصيدلانية": 1,
    "الصيدلة": 1,
    "العقاقير": 1,
    "عقاقيري": 1,
    "دستور": 1,
    "بلعومي": 1,
    "البلعوم": 1,
    "البلاعيم": 1,
    "مرحلي": 1,
    "البطيء": 1,
    "الدراج": 1,
    "فيلبس": 1,
    "الكربوليك": 1,
    "الفينول": 1,
    "فلسفة": 1,
    "الظاهري": 1,
    "فينيلالاناين": 1,
    "فاي": 1,
    "فيلادلفيا": 1,
    "غازل": 1,
    "يغازل": 1,
    "فيلانثروب": 1,
    "طوابعي": 1,
    "هواية": 1,
    "للبطاريات": 1,
    "فيليمون": 1,
    "فلاح": 1,
    "فيليب": 1,
    "فيليبيانز": 1,
    "أرسلها": 1,
    "الفيليبي": 1,
    "فلبيني": 1,
    "الفلسطينيون": 1,
    "فيليز": 1,
    "فيليبس": 1,
    "فيلوديندرون": 1,
    "فقه": 1,
    "فيلوسوف": 1,
    "فلسفي": 1,
    "الفلسفات": 1,
    "فلسف": 1,
    "مفلسف": 1,
    "المتفلسف": 1,
    "يفلسف": 1,
    "الفلسفة": 1,
    "فيبس": 1,
    "الوريدي": 1,
    "خزع": 1,
    "الوريد": 1,
    "النخامة": 1,
    "توصيل": 1,
    "الفلوكس": 1,
    "رهابي": 1,
    "فويب": 1,
    "فينيقيا": 1,
    "فينكس": 1,
    "خابر": 1,
    "كلم": 1,
    "الهواتف": 1,
    "سماع": 1,
    "بزيف": 1,
    "المخابرة": 1,
    "الدالة": 1,
    "الفونوغراف": 1,
    "صوتية": 1,
    "الفونوغرافات": 1,
    "الفوسجين": 1,
    "الفوسفات": 1,
    "فوسفاتي": 1,
    "الفوسفيد": 1,
    "الفوسفين": 1,
    "ومض": 1,
    "التفسفر": 1,
    "فوسفوري": 1,
    "فسفوري": 1,
    "الفوسفور": 1,
    "الفوسفورية": 1,
    "الفسفور": 1,
    "إضافة": 1,
    "ضوئي": 1,
    "الكهروضوئية": 1,
    "بالكيمياء": 1,
    "كهروضوئي": 1,
    "بالتغييرات": 1,
    "المتأثرة": 1,
    "بالضوء": 1,
    "الكتروني": 1,
    "الإلكترونات": 1,
    "الصوري": 1,
    "الفوتوغرافية": 1,
    "المساحي": 1,
    "فوتوغرافي": 1,
    "التلألأ": 1,
    "بالتحلل": 1,
    "بالمغناطيسية": 1,
    "الفوتون": 1,
    "الفوتونات": 1,
    "فوتوبلاي": 1,
    "بأدق": 1,
    "مجس": 1,
    "صوري": 1,
    "مصاغ": 1,
    "البلاغة": 1,
    "أسلوبي": 1,
    "بالعقل": 1,
    "الفراسة": 1,
    "فثاليت": 1,
    "الطحلبية": 1,
    "بالتطور": 1,
    "التأريخات": 1,
    "الفيزياوي": 1,
    "الفيزياويون": 1,
    "سيماء": 1,
    "فسلجي": 1,
    "الفسيولوجي": 1,
    "وظائف": 1,
    "فيتوبلانكتون": 1,
    "متمرس": 1,
    "البيانوات": 1,
    "الميادين": 1,
    "بيكاسو": 1,
    "بيكادللي": 1,
    "ملتقط": 1,
    "القاطف": 1,
    "البيكيلر": 1,
    "بيكيرينج": 1,
    "القاطفون": 1,
    "مرابط": 1,
    "المحتجون": 1,
    "بكيت": 1,
    "بيكفورد": 1,
    "الإلتقاط": 1,
    "المخلل": 1,
    "مخلل": 1,
    "المخللات": 1,
    "النشال": 1,
    "النشالون": 1,
    "اللقطات": 1,
    "المتنزهون": 1,
    "بيكوفاراد": 1,
    "بالترليون": 1,
    "الترليون": 1,
    "بالـكتابة": 1,
    "المصورة": 1,
    "التصويرية": 1,
    "متعة": 1,
    "للنظر": 1,
    "بجين": 1,
    "بايقوسوتر": 1,
    "ألوان": 1,
    "بالقطعة": 1,
    "بيسير": 1,
    "المدفوعة": 1,
    "يتقاضي": 1,
    "بيد": 1,
    "بيدمونت": 1,
    "باي": 1,
    "بلانت": 1,
    "مرفأ": 1,
    "إثقب": 1,
    "بيير": 1,
    "مرافيء": 1,
    "التقوى": 1,
    "بيزو": 1,
    "مهذور": 1,
    "الهذر": 1,
    "جشعا": 1,
    "الإصطباغ": 1,
    "الصبغات": 1,
    "حظيرة": 1,
    "حظائر": 1,
    "زرائب": 1,
    "سرحت": 1,
    "شعرها": 1,
    "البيك": 1,
    "الحذرون": 1,
    "بيكينج": 1,
    "الرز": 1,
    "بيلات": 1,
    "الحاج": 1,
    "الحج": 1,
    "محجوج": 1,
    "الحجات": 1,
    "الحجاج": 1,
    "الناهب": 1,
    "توسد": 1,
    "التوسد": 1,
    "بيلسبري": 1,
    "بشعر": 1,
    "القبطانية": 1,
    "الحلو": 1,
    "قوادا": 1,
    "القوادة": 1,
    "مهنة": 1,
    "بالبثور": 1,
    "البثرات": 1,
    "بثورا": 1,
    "دبس": 1,
    "المريلات": 1,
    "والدبابيس": 1,
    "بينس": 1,
    "الكماشة": 1,
    "إقرص": 1,
    "مقروص": 1,
    "القرصات": 1,
    "المدبسة": 1,
    "الصنوبر": 1,
    "بينيل": 1,
    "الأناناس": 1,
    "موهون": 1,
    "بينيهرست": 1,
    "الصنوبرات": 1,
    "غابة": 1,
    "غابات": 1,
    "النابتة": 1,
    "ريشة": 1,
    "بينج": 1,
    "نبضات": 1,
    "الوردي": 1,
    "الخنصر": 1,
    "الخناصر": 1,
    "الوردية": 1,
    "مدبس": 1,
    "التدبيس": 1,
    "البيناكل": 1,
    "وخزة": 1,
    "تقطع": 1,
    "آذانها": 1,
    "وذيلها": 1,
    "بينسكاي": 1,
    "الباينت": 1,
    "تايل": 1,
    "تايلز": 1,
    "بينتر": 1,
    "سيناريست": 1,
    "ومخرج": 1,
    "الباينتات": 1,
    "المروحة": 1,
    "البينكستر": 1,
    "تقي": 1,
    "الإنبوب": 1,
    "بيبيكروزس": 1,
    "الزمار": 1,
    "لشفط": 1,
    "الماصات": 1,
    "المواسير": 1,
    "التفاحات": 1,
    "الببسوة": 1,
    "إهانة": 1,
    "القرصنة": 1,
    "بيرياس": 1,
    "البيرانا": 1,
    "زورق": 1,
    "جذع": 1,
    "بيسا": 1,
    "بيسكاتواي": 1,
    "الحوت": 1,
    "بيسسي": 1,
    "متبول": 1,
    "بيسسير": 1,
    "التبول": 1,
    "البستول": 1,
    "المكبس": 1,
    "المكابس": 1,
    "وإرم": 1,
    "البيتشبلند": 1,
    "أبريق": 1,
    "الأباريق": 1,
    "إقتلع": 1,
    "المعزقات": 1,
    "ظلمة": 1,
    "الترويج": 1,
    "بيتشستون": 1,
    "بشفقة": 1,
    "بلاغة": 1,
    "ببلاغة": 1,
    "مستخطى": 1,
    "المشفق": 1,
    "الرحمات": 1,
    "بيتني": 1,
    "ومنع": 1,
    "الزهيد": 1,
    "بيتسبيرج": 1,
    "بيتسفيلد": 1,
    "بيتستون": 1,
    "نخامي": 1,
    "الإستخطاء": 1,
    "يرثى": 1,
    "بيوس": 1,
    "بيفيت": 1,
    "بيكسبف": 1,
    "بكسلثانية": 1,
    "مخبول": 1,
    "رسمة": 1,
    "بكسلية": 1,
    "البيتزا": 1,
    "مسامح": 1,
    "بتسامح": 1,
    "إسترض": 1,
    "مسترضى": 1,
    "بلاكاتر": 1,
    "يسترضى": 1,
    "الإسترضاء": 1,
    "الاسترضاء": 1,
    "مسترضي": 1,
    "المموه": 1,
    "إستقرار": 1,
    "التنسيب": 1,
    "مشيمي": 1,
    "الإنتحال": 1,
    "البلاجيوكلاز": 1,
    "الطاعون": 1,
    "البلايد": 1,
    "وشاح": 1,
    "أبسط": 1,
    "بلينفيلد": 1,
    "مشفرة": 1,
    "بلينفيو": 1,
    "مضفر": 1,
    "بلاناريتي": 1,
    "بلانك": 1,
    "مملس": 1,
    "إستواء": 1,
    "الفأرات": 1,
    "مفردها": 1,
    "فأرة": 1,
    "وتستخدم": 1,
    "لتنعيم": 1,
    "كوكبي": 1,
    "اللامحدودة": 1,
    "تدور": 1,
    "نشوء": 1,
    "كواكب": 1,
    "التمليس": 1,
    "الخشبي": 1,
    "بلوح": 1,
    "العوالق": 1,
    "الغراس": 1,
    "الغراسون": 1,
    "الطرطشة": 1,
    "مرشوش": 1,
    "الرش": 1,
    "بلاسم": 1,
    "البلازما": 1,
    "بلازمي": 1,
    "بالبلازما": 1,
    "مأكل": 1,
    "اللصقة": 1,
    "اللصقات": 1,
    "البلاستيك": 1,
    "البلاستيسين": 1,
    "بلاستيكي": 1,
    "بلاستيسول": 1,
    "تجهيز": 1,
    "الهضبة": 1,
    "الهضاب": 1,
    "صفيحة": 1,
    "صفيحات": 1,
    "إسطوانة": 1,
    "الكاتبة": 1,
    "دحروجة": 1,
    "إسطوانات": 1,
    "الألة": 1,
    "التصفيحات": 1,
    "بالبلاتين": 1,
    "البلاتين": 1,
    "المكرر": 1,
    "أفلاطون": 1,
    "أفلاطوني": 1,
    "أفلاطونيا": 1,
    "الأفلاطونية": 1,
    "الفصيل": 1,
    "بلات": 1,
    "بلاتيفيل": 1,
    "بلاتي": 1,
    "المستوية": 1,
    "المستهتر": 1,
    "المستهترون": 1,
    "تشغيلإيقاف": 1,
    "قفص": 1,
    "للأطفال": 1,
    "تذرع": 1,
    "متذرع": 1,
    "يتذرع": 1,
    "رجاء": 1,
    "المبهج": 1,
    "يسر": 1,
    "المسرة": 1,
    "برضى": 1,
    "إبتهج": 1,
    "المتع": 1,
    "ثني": 1,
    "الإستفتاء": 1,
    "الإستفتاءات": 1,
    "الوعد": 1,
    "الوعود": 1,
    "إجتماع": 1,
    "الجنب": 1,
    "بالرئه": 1,
    "بليكسيجلاس": 1,
    "إجتهد": 1,
    "طيات": 1,
    "الزردية": 1,
    "بلني": 1,
    "تثاقل": 1,
    "المثابر": 1,
    "يتثاقل": 1,
    "إسقط": 1,
    "الإنفجاري": 1,
    "محروث": 1,
    "الحراثة": 1,
    "المحاريث": 1,
    "بلوير": 1,
    "شفرات": 1,
    "العزم": 1,
    "ناتف": 1,
    "بلوكينيس": 1,
    "العزوم": 1,
    "التلفون": 1,
    "ملحقات": 1,
    "أجاص": 1,
    "بالريش": 1,
    "الشاقول": 1,
    "معلوم": 1,
    "السباك": 1,
    "السباكون": 1,
    "السباكة": 1,
    "بلامر": 1,
    "الشواقيل": 1,
    "بلومبين": 1,
    "سمنة": 1,
    "الناهبون": 1,
    "ينقر": 1,
    "بلانتلي": 1,
    "الأسبق": 1,
    "التعددية": 1,
    "التعددي": 1,
    "تعددي": 1,
    "التعدديون": 1,
    "جمعي": 1,
    "بلترش": 1,
    "سيرة": 1,
    "بلوتو": 1,
    "الأغنياء": 1,
    "بلوتون": 1,
    "البلوتونيوم": 1,
    "الإجتهاد": 1,
    "بليموث": 1,
    "ميكانيكية": 1,
    "بو": 1,
    "بوكاهونتاس": 1,
    "سكينة": 1,
    "سكاكين": 1,
    "السنفة": 1,
    "منصات": 1,
    "السنفات": 1,
    "الشاعرة": 1,
    "الشاعرات": 1,
    "الشعري": 1,
    "بوجو": 1,
    "المدبرة": 1,
    "البوي": 1,
    "بوينكير": 1,
    "بوينديكستر": 1,
    "البوينسيتيا": 1,
    "استوائي": 1,
    "لنقطة": 1,
    "بتركيز": 1,
    "المدبب": 1,
    "الأحد": 1,
    "مستعد": 1,
    "يستعد": 1,
    "الإستعداد": 1,
    "السم": 1,
    "المسمم": 1,
    "السموم": 1,
    "بويسون": 1,
    "الوخزة": 1,
    "البوكر": 1,
    "المساعر": 1,
    "الوخزات": 1,
    "بولندا": 1,
    "قطبي": 1,
    "التقاطب": 1,
    "إستقطب": 1,
    "المقطب": 1,
    "يستقطب": 1,
    "بولارويد": 1,
    "الظربان": 1,
    "الإنفعالية": 1,
    "إنفعالي": 1,
    "المجادلات": 1,
    "جادل": 1,
    "يجادل": 1,
    "بالعمود": 1,
    "الشرطيات": 1,
    "السياسات": 1,
    "المستند": 1,
    "خشبي": 1,
    "شلل": 1,
    "المصقلات": 1,
    "مؤدب": 1,
    "التأدب": 1,
    "أدبا": 1,
    "سياسيا": 1,
    "مسيس": 1,
    "يسيس": 1,
    "التسييس": 1,
    "سياسيون": 1,
    "بوليتوفايس": 1,
    "بولك": 1,
    "البولكا": 1,
    "المنقط": 1,
    "إستطلاع": 1,
    "منيل": 1,
    "الطلع": 1,
    "المستفتي": 1,
    "يرقي": 1,
    "بولوك": 1,
    "إستطلاعات": 1,
    "مستطلع": 1,
    "الملوثات": 1,
    "للبيئة": 1,
    "بولكس": 1,
    "بوليانا": 1,
    "البولو": 1,
    "البولونيز": 1,
    "البولناز": 1,
    "البولونيوم": 1,
    "العاب": 1,
    "والصولجان": 1,
    "بوليبتين": 1,
    "المشحمات": 1,
    "المطاط": 1,
    "التخصصي": 1,
    "الكريستالات": 1,
    "البولستر": 1,
    "البولي": 1,
    "إيثيلن": 1,
    "للأوعية": 1,
    "إيثيلنات": 1,
    "الزوجات": 1,
    "المضلعات": 1,
    "المتعدد": 1,
    "وحي": 1,
    "المقدس.الأساطير": 1,
    "بوليسوبتيلين": 1,
    "الموسوعي": 1,
    "بوليميريس": 1,
    "الكيميائية": 1,
    "بلمر": 1,
    "مبلمر": 1,
    "يبلمر": 1,
    "البلمرة": 1,
    "بولنيسيا": 1,
    "بولينيزي": 1,
    "متعددو": 1,
    "بوليفيمس": 1,
    "بأكثر": 1,
    "صوت.": 1,
    "كحرف": 1,
    "A": 1,
    "التأليفة": 1,
    "بوليفوسفيت": 1,
    "البوليفوسفوريك": 1,
    "البوليبروبيلين": 1,
    "بوليساكيريد": 1,
    "البوليستيرين": 1,
    "بلاستيك": 1,
    "التقنيات": 1,
    "الإشراك": 1,
    "بالله": 1,
    "المشرك": 1,
    "بموسيقى": 1,
    "بوليفينيل": 1,
    "بوم": 1,
    "الرمانة": 1,
    "بوميرانيا": 1,
    "الأولى.": 1,
    "الان": 1,
    "مقسومة": 1,
    "والمانيا": 1,
    "لكم": 1,
    "اللكم": 1,
    "بومونا": 1,
    "البهاء": 1,
    "البومبادور": 1,
    "تسريح": 1,
    "بومبانو": 1,
    "بومبيي": 1,
    "بومبي": 1,
    "شدات": 1,
    "التفاخرات": 1,
    "التفاخم": 1,
    "البركة": 1,
    "المتأمل": 1,
    "البركات": 1,
    "بونغ": 1,
    "حريري": 1,
    "بونتياك": 1,
    "لمقاطعة": 1,
    "أقم": 1,
    "قداس": 1,
    "تسريحة": 1,
    "القلطي": 1,
    "احمق": 1,
    "براز": 1,
    "غائط": 1,
    "مسربة": 1,
    "جاسوسي)": 1,
    "للفقراء": 1,
    "قوم": 1,
    "بوبيك": 1,
    "بوبفونك": 1,
    "البوبلين": 1,
    "مفرقع": 1,
    "إبزيم": 1,
    "الخشخاش": 1,
    "التفرقع": 1,
    "الفرقعات": 1,
    "الشعبيات": 1,
    "جاعل": 1,
    "محببا": 1,
    "مرغوبا": 1,
    "بالسكان": 1,
    "الشعبيون": 1,
    "قافزة": 1,
    "خنزيري": 1,
    "النيص": 1,
    "ممعن": 1,
    "البغروس": 1,
    "الإمعان": 1,
    "بدانة": 1,
    "الخلاعي": 1,
    "خلاعي": 1,
    "خلاعيا": 1,
    "المساميات": 1,
    "المسامية": 1,
    "السماقي": 1,
    "العصيدة": 1,
    "تنقليا": 1,
    "تعدية": 1,
    "بوابات": 1,
    "التنغيم": 1,
    "إستدار": 1,
    "متنبأ": 1,
    "مشئوم": 1,
    "النذر": 1,
    "بورتشيا": 1,
    "أورانوس": 1,
    "الرواق": 1,
    "الأروقة": 1,
    "المعمدة": 1,
    "بورتلند": 1,
    "وقار": 1,
    "بورتو": 1,
    "البرتغال": 1,
    "بورتسموث": 1,
    "البرتغالي": 1,
    "الرجلة": 1,
    "تهيئته": 1,
    "بوسايدون": 1,
    "المحيرة": 1,
    "المتكلفون": 1,
    "بوسي": 1,
    "باقات": 1,
    "موقعي": 1,
    "الوضعي": 1,
    "الواقعي": 1,
    "الوضعيون": 1,
    "الواقعيون": 1,
    "بشحنة": 1,
    "موجبة": 1,
    "بوسنر": 1,
    "إمتلك": 1,
    "ممسوس": 1,
    "بوسيسيدنيس": 1,
    "بالملكية": 1,
    "الممتلكات": 1,
    "تملكيا": 1,
    "بريدي": 1,
    "يؤرخ": 1,
    "لاحق": 1,
    "أرخ": 1,
    "الدكتوراة": 1,
    "الملصق": 1,
    "الملصقات": 1,
    "أحرف": 1,
    "مضافة": 1,
    "الوفاة": 1,
    "بمرحلة": 1,
    "ختم": 1,
    "مختوم": 1,
    "بختم": 1,
    "أختام": 1,
    "مكاتب": 1,
    "مسبوق": 1,
    "مؤجل": 1,
    "نتائج": 1,
    "التذييل": 1,
    "التذييلات": 1,
    "المسلمة": 1,
    "المسلمات": 1,
    "الإفتراضات": 1,
    "متموضع": 1,
    "بوستشرر": 1,
    "البوتاسيوم": 1,
    "معاقرة": 1,
    "البطاطة": 1,
    "البطاطا": 1,
    "بكرش": 1,
    "الكروش": 1,
    "كوقود": 1,
    "الكرش": 1,
    "يؤلف": 1,
    "بوتيمكين": 1,
    "جيش": 1,
    "الكامنة": 1,
    "فعلا": 1,
    "تطلق": 1,
    "بالحفريات": 1,
    "المغارات": 1,
    "بوتلاتش": 1,
    "إحتفالات": 1,
    "المتوفر": 1,
    "بوتوماك": 1,
    "رديف": 1,
    "العطرية": 1,
    "بوتسدام": 1,
    "بوتاواتومي": 1,
    "الخزافون": 1,
    "بوتس": 1,
    "القعادة": 1,
    "مكيس": 1,
    "بوغكيبسي": 1,
    "الداجنة": 1,
    "مكمد": 1,
    "التكميد": 1,
    "الدواجن": 1,
    "الهجمة": 1,
    "يهجم": 1,
    "هجم": 1,
    "المنقض": 1,
    "الإنقضاض": 1,
    "مقصوف": 1,
    "المدفق": 1,
    "بوسين": 1,
    "العبوس": 1,
    "عبس": 1,
    "الطابي": 1,
    "التبويز": 1,
    "أسير": 1,
    "المسحوق": 1,
    "البودرة": 1,
    "الذر": 1,
    "لمسح": 1,
    "المساحيق": 1,
    "باول": 1,
    "الزهري": 1,
    "بم": 1,
    "تطبيقه": 1,
    "الممارسات": 1,
    "الممارسون": 1,
    "برادو": 1,
    "متحف": 1,
    "الأسباني": 1,
    "براغماتي": 1,
    "الذرائعي": 1,
    "الذرائعيون": 1,
    "براغ": 1,
    "المديح": 1,
    "إستحقاقية": 1,
    "يرفس": 1,
    "الرفاس": 1,
    "البريزيوديميوم": 1,
    "برات": 1,
    "بسقوط": 1,
    "براتفيل": 1,
    "برافدا": 1,
    "جريدة": 1,
    "روسية": 1,
    "التعليمية": 1,
    "تمرين": 1,
    "مصلي": 1,
    "بخشوع": 1,
    "الصلاوات": 1,
    "يصلي": 1,
    "الوعاظ": 1,
    "واعضة": 1,
    "الديباجة": 1,
    "المضخم": 1,
    "الكمبري.": 1,
    "ثبات": 1,
    "الجسيمة": 1,
    "وقائي": 1,
    "الوقائية": 1,
    "إسبق": 1,
    "مرشد": 1,
    "المرتلون": 1,
    "النصائح": 1,
    "يلتف": 1,
    "ويدور": 1,
    "إلتف": 1,
    "وداور": 1,
    "والدوران": 1,
    "بالكلور": 1,
    "عزيزا": 1,
    "النفاسة": 1,
    "للترسيب": 1,
    "مرسب": 1,
    "يهطل": 1,
    "الهطول": 1,
    "ترسبي": 1,
    "الانحدار": 1,
    "حائل": 1,
    "مبكر": 1,
    "المبكر": 1,
    "المسبق": 1,
    "والأحاسيس": 1,
    "الدفينة": 1,
    "إطبخ": 1,
    "يطبخ": 1,
    "البوادر": 1,
    "تبشيري": 1,
    "التسبيق": 1,
    "نهبية": 1,
    "يسند": 1,
    "التوقعية": 1,
    "التنبؤات": 1,
    "المتنبيء": 1,
    "هيئ": 1,
    "يهيئ": 1,
    "سائد": 1,
    "بالدرجة": 1,
    "مساد": 1,
    "الشفعة": 1,
    "وقائيا": 1,
    "مستبق": 1,
    "بريفاب": 1,
    "فضل": 1,
    "التفضيلات": 1,
    "تفضيلي": 1,
    "يفضل": 1,
    "البادئة": 1,
    "بالكلمة": 1,
    "البادئات": 1,
    "إقلاعها": 1,
    "سخن": 1,
    "إلتفافي": 1,
    "حكما": 1,
    "كافية": 1,
    "الإجحاف": 1,
    "الإنحياز": 1,
    "التمهيدات": 1,
    "التمهيد": 1,
    "بدء": 1,
    "الأوان": 1,
    "وقته": 1,
    "المناسب": 1,
    "تعمد": 1,
    "مبيت": 1,
    "يتعمد": 1,
    "التعمد": 1,
    "متعلقق": 1,
    "ليلة": 1,
    "ليالي": 1,
    "الهاجس": 1,
    "الهواجس": 1,
    "بالخطر": 1,
    "متدرب": 1,
    "تلقي": 1,
    "الإنشغالات": 1,
    "المدرسية": 1,
    "بيعها": 1,
    "التحضيرات": 1,
    "تحضيري": 1,
    "تحضيريا": 1,
    "إستعد": 1,
    "بإستعداد": 1,
    "مقدما": 1,
    "الغالبية": 1,
    "متفوق": 1,
    "جرا": 1,
    "إمتاز": 1,
    "الإمتيازات": 1,
    "قصو": 1,
    "المشيخي": 1,
    "بريسبيتارية": 1,
    "ماقبل": 1,
    "المدرسة": 1,
    "الغيب": 1,
    "تنبؤا": 1,
    "بريسكوت": 1,
    "الوصفة": 1,
    "الوصفات": 1,
    "تقادمي": 1,
    "تقادميا": 1,
    "منتقى": 1,
    "المقدمون": 1,
    "حاضرية": 1,
    "الحافظ": 1,
    "الرئاسات": 1,
    "رئاسي": 1,
    "رئاسيا": 1,
    "المترأس": 1,
    "يترأس": 1,
    "بريسلي": 1,
    "غسلها": 1,
    "الضغوطات": 1,
    "الطابعات": 1,
    "بمقام": 1,
    "المهابة": 1,
    "المعزوفة": 1,
    "بريستن": 1,
    "يجهد": 1,
    "تحسبا": 1,
    "لجهد": 1,
    "التظاهرات": 1,
    "تظاهريا": 1,
    "متوتر": 1,
    "خارق": 1,
    "خارقة": 1,
    "إختبر": 1,
    "مختبر": 1,
    "الإختبار": 1,
    "يختبر": 1,
    "بريتوريا": 1,
    "الإدارية": 1,
    "لجنوب": 1,
    "البسكويتة": 1,
    "المملح": 1,
    "بريف": 1,
    "التحاشي": 1,
    "للمنع": 1,
    "بريفينتابلي": 1,
    "الفريسة": 1,
    "مفترس": 1,
    "الإفتراس": 1,
    "الفرائس": 1,
    "بريام": 1,
    "لطروادة": 1,
    "لائحة": 1,
    "الأسعار": 1,
    "مسعر": 1,
    "التسعير": 1,
    "الأشواك": 1,
    "الشائك": 1,
    "الغرزات": 1,
    "الفخر": 1,
    "مفتخر": 1,
    "فخور": 1,
    "الإفتخار": 1,
    "الفضولية": 1,
    "الكاهنة": 1,
    "الكاهنات": 1,
    "الكهانة": 1,
    "بريستلي": 1,
    "لأكثر": 1,
    "الكهنوتية": 1,
    "المتصلف": 1,
    "التزمت": 1,
    "المتصلفون": 1,
    "زم": 1,
    "الصدارات": 1,
    "قرود": 1,
    "معبى": 1,
    "مبادىء": 1,
    "يعبي": 1,
    "البدائية": 1,
    "باحتشام": 1,
    "الاحتشام": 1,
    "برموس": 1,
    "أميري": 1,
    "الأميرية": 1,
    "الأمراء": 1,
    "الأميرات": 1,
    "برينسيتون": 1,
    "المباديء": 1,
    "مبدئي": 1,
    "المبادئ": 1,
    "للطبع": 1,
    "برينتيت": 1,
    "الطباع": 1,
    "المطبوعات": 1,
    "مرتين": 1,
    "بريوري": 1,
    "مقدمو": 1,
    "بريسيلا": 1,
    "الموشور": 1,
    "موشوري": 1,
    "منشوري": 1,
    "الموشورات": 1,
    "التشدد": 1,
    "متحفظ": 1,
    "بريتشارد": 1,
    "فضلك": 1,
    "الخلوات": 1,
    "الحناء": 1,
    "امتيازات": 1,
    "امتياز": 1,
    "سريا": 1,
    "للمحترفين": 1,
    "المتنافس": 1,
    "فائز": 1,
    "بأن": 1,
    "تسير": 1,
    "لليقين": 1,
    "إحتمالي": 1,
    "إحتماليا": 1,
    "إثبات": 1,
    "صحة": 1,
    "إثباتات": 1,
    "إختباري": 1,
    "المختبرون": 1,
    "تقصى": 1,
    "بروبير": 1,
    "المسابر": 1,
    "التقصي": 1,
    "البروكايين": 1,
    "لعلاج": 1,
    "الإيراد": 1,
    "الإيرادات": 1,
    "موكبي": 1,
    "المماطل": 1,
    "المماطلون": 1,
    "إنجابي": 1,
    "إنظباط": 1,
    "والتزام": 1,
    "بروكرستيز": 1,
    "أسطوري.": 1,
    "بروكتر": 1,
    "المستقيم": 1,
    "شرج": 1,
    "محصل": 1,
    "الإقتناء": 1,
    "التحصيل": 1,
    "النخزة": 1,
    "المبذرون": 1,
    "الإعجوبة": 1,
    "النخز": 1,
    "المنتجات": 1,
    "للإنتاج": 1,
    "الإنتاجية": 1,
    "تدنيسي": 1,
    "تجديفي": 1,
    "التجديف": 1,
    "صرح": 1,
    "يصرح": 1,
    "الأستاذية": 1,
    "أستاذي": 1,
    "الأساتذة": 1,
    "لمحة": 1,
    "لمحات": 1,
    "مربحة": 1,
    "الإستغلالي": 1,
    "الإستغلاليون": 1,
    "الأرباح": 1,
    "باسراف": 1,
    "التكهنات": 1,
    "برمج": 1,
    "التقدمية": 1,
    "التعجيزية": 1,
    "القذيفة": 1,
    "المقذوفات": 1,
    "التسليط": 1,
    "إسقاطي": 1,
    "بروكاريوت": 1,
    "بروكوفيف": 1,
    "باليه": 1,
    "سيمفونيات": 1,
    "متطاول": 1,
    "والضع": 1,
    "البروليتاري": 1,
    "البرولين": 1,
    "برولوج": 1,
    "للإطالة": 1,
    "مقتبس": 1,
    "بروميثيوس": 1,
    "أوليمبس": 1,
    "أعطاها": 1,
    "للبشرية.": 1,
    "البروميثيوم": 1,
    "بإباحة": 1,
    "موعود": 1,
    "تعهدي": 1,
    "لبحر": 1,
    "للترقية": 1,
    "ترويجي": 1,
    "التشجيعية": 1,
    "ملقنو": 1,
    "مرئي": 1,
    "التلقينات": 1,
    "المسارعة": 1,
    "منكفئ": 1,
    "لـ..": 1,
    "التصريحات": 1,
    "الناطق": 1,
    "التلفظ": 1,
    "البرهان": 1,
    "مقاوما": 1,
    "تصحيحي": 1,
    "للماء": 1,
    "الصدأ": 1,
    "البراهين": 1,
    "دعائية": 1,
    "حاول": 1,
    "إقناع": 1,
    "حوول": 1,
    "محاولة": 1,
    "كاثر": 1,
    "مكاثر": 1,
    "يكاثر": 1,
    "المكاثرة": 1,
    "التوليدات": 1,
    "البروبان": 1,
    "المراوح": 1,
    "النزعات": 1,
    "النبوءات": 1,
    "النبوءة": 1,
    "الرسول": 1,
    "الرباني": 1,
    "النبية": 1,
    "النبيات": 1,
    "نبوي": 1,
    "بروبيونيت": 1,
    "البروبيونيك": 1,
    "الإستعطاف": 1,
    "مواتي": 1,
    "المقترح": 1,
    "المقترحون": 1,
    "التناسب": 1,
    "متكافئ": 1,
    "يتناسب": 1,
    "المنسب": 1,
    "الإقتراحات": 1,
    "إقترح": 1,
    "الاقتراح": 1,
    "يقترح": 1,
    "إقتراحي": 1,
    "راود": 1,
    "المقترحات": 1,
    "إرادية": 1,
    "وأعضاءه": 1,
    "بروبيل": 1,
    "البروبيلين": 1,
    "بروبين": 1,
    "بملل": 1,
    "النثر": 1,
    "حاكم": 1,
    "محاكم": 1,
    "يحاكم": 1,
    "المحاكمات": 1,
    "نثر": 1,
    "الاهتداء": 1,
    "الناثر": 1,
    "بروسيربن": 1,
    "سيريز": 1,
    "أصبحت": 1,
    "الجريمة.": 1,
    "إسهابا": 1,
    "عروضي": 1,
    "مستقبليا": 1,
    "الترقب": 1,
    "إنجح": 1,
    "ناجح": 1,
    "النجاح": 1,
    "ينجح": 1,
    "البروستات": 1,
    "بديلي": 1,
    "مارست": 1,
    "منطرح": 1,
    "ينطرح": 1,
    "الإنطراح": 1,
    "بروتكتينيوم": 1,
    "البروتيني": 1,
    "البروتينية": 1,
    "المحمية": 1,
    "المحمي": 1,
    "المحميون": 1,
    "البروتستانتي": 1,
    "البروتستانتية": 1,
    "البروتستانتيون": 1,
    "إحتجاجا": 1,
    "احادي": 1,
    "البروتوبلازم": 1,
    "بروتوبلازمي": 1,
    "البروتوزوا": 1,
    "بروتوزوي": 1,
    "للتمدد": 1,
    "المنقلة": 1,
    "بروست": 1,
    "مبرهنة": 1,
    "المبرهن": 1,
    "مثلي": 1,
    "كمثل": 1,
    "بروفيدانس": 1,
    "مدخر": 1,
    "المجهز": 1,
    "المحافظة": 1,
    "المحافظات": 1,
    "القروي": 1,
    "إقليمي": 1,
    "مؤقتا": 1,
    "الإستفزازي": 1,
    "الإستفزازيون": 1,
    "الإستفزازات": 1,
    "باستفزاز": 1,
    "روؤساء": 1,
    "الجؤجؤ": 1,
    "المتصيد": 1,
    "المتصيدون": 1,
    "محتويات": 1,
    "المتزمت": 1,
    "التعقل": 1,
    "المتزمتون": 1,
    "محتشم": 1,
    "الإجاص": 1,
    "التقليم": 1,
    "التلهف": 1,
    "ملحة": 1,
    "لحك": 1,
    "هرش": 1,
    "بروسي": 1,
    "المزمور": 1,
    "الرضى": 1,
    "أرطال": 1,
    "المربعة.": 1,
    "الصدفية": 1,
    "النفسانيون": 1,
    "العقلية": 1,
    "روحي": 1,
    "لفهم": 1,
    "السيكولوجيا": 1,
    "بالمقاييس": 1,
    "السيكولوجي": 1,
    "الخلل": 1,
    "العقلي": 1,
    "مضطربو": 1,
    "بدنيا": 1,
    "البدني": 1,
    "الممات": 1,
    "المعقدون": 1,
    "الإختلالات": 1,
    "الإختلال": 1,
    "سيكولوجي": 1,
    "جسمي": 1,
    "ذهاني": 1,
    "المعتوهون": 1,
    "سيلليوم": 1,
    "والمدرسين": 1,
    "الترمجان": 1,
    "بطليموس": 1,
    "الفلكي": 1,
    "البطليموسي": 1,
    "إسكندراني": 1,
    "الجيفين": 1,
    "عاني": 1,
    "والإعلان": 1,
    "علنا": 1,
    "بوشيني": 1,
    "جعد": 1,
    "الحبيبي": 1,
    "الصهر": 1,
    "المحاشم": 1,
    "بويريليتي": 1,
    "ريكو": 1,
    "النفخة": 1,
    "البافبول": 1,
    "إنتفاخا": 1,
    "البفن": 1,
    "النفخات": 1,
    "الصلصال": 1,
    "بف": 1,
    "الضى": 1,
    "تلاكمي": 1,
    "جبار": 1,
    "بولاسكي": 1,
    "نح": 1,
    "ينوح": 1,
    "بليتزر": 1,
    "بالولايات": 1,
    "بولير": 1,
    "البكرات": 1,
    "بلينجز": 1,
    "البولمان": 1,
    "ركاب": 1,
    "فاخرة": 1,
    "بولبيلي": 1,
    "الجوهرية": 1,
    "اللبية": 1,
    "المنبر": 1,
    "المنابر": 1,
    "المشع": 1,
    "إنبض": 1,
    "ينبض": 1,
    "متعاقد": 1,
    "إمتداد": 1,
    "بولسير": 1,
    "للطحن": 1,
    "للسحق": 1,
    "إسحق": 1,
    "الطاحن": 1,
    "الخفاف": 1,
    "غذت": 1,
    "مضخوخ": 1,
    "الضخ": 1,
    "بزرة": 1,
    "اليقطين": 1,
    "بالرسميات": 1,
    "بالشكليات": 1,
    "ينقط": 1,
    "بيونيك": 1,
    "ضئالة": 1,
    "العقاب": 1,
    "للعقاب": 1,
    "فسادا": 1,
    "ماصة": 1,
    "لدماء": 1,
    "المتلاعب": 1,
    "بالألفاظ": 1,
    "المتلاعبون": 1,
    "التسيير": 1,
    "الجرو": 1,
    "يتشرنق": 1,
    "تشرنق": 1,
    "التشرنق": 1,
    "العرائس": 1,
    "محركو": 1,
    "الجراء": 1,
    "جرو": 1,
    "بورسيل": 1,
    "المشتريات": 1,
    "بوردو": 1,
    "الأصيل": 1,
    "أصفى": 1,
    "حملات": 1,
    "منقى": 1,
    "المنقي": 1,
    "المنقيات": 1,
    "ينقي": 1,
    "نق": 1,
    "بورينا": 1,
    "البيورين": 1,
    "وشفاف": 1,
    "الصفوية": 1,
    "المتشددون": 1,
    "النقاوات": 1,
    "النقاوة": 1,
    "الخرير": 1,
    "ضواحي": 1,
    "البلدة": 1,
    "الأرجوان": 1,
    "باللون": 1,
    "العنابي": 1,
    "إرجواني": 1,
    "بوربلنج": 1,
    "المضمون": 1,
    "المضامين": 1,
    "الغرض": 1,
    "هادف": 1,
    "العشوائية": 1,
    "قصد": 1,
    "القرقرة": 1,
    "مخرخر": 1,
    "الخرخرة": 1,
    "بخرخرة": 1,
    "مزموم": 1,
    "محاسبو": 1,
    "الزم": 1,
    "برسلين": 1,
    "للمتابعة": 1,
    "المواصلات": 1,
    "متابع": 1,
    "قيحي": 1,
    "الممونون": 1,
    "آماد": 1,
    "بوسان": 1,
    "بسي": 1,
    "لاهوتي": 1,
    "لمعلومات": 1,
    "مروجو": 1,
    "بإقدام": 1,
    "المفصول": 1,
    "اليسيرة": 1,
    "الحقير": 1,
    "صديدا": 1,
    "الهرات": 1,
    "الهرة": 1,
    "إزدرائي": 1,
    "الضعيف": 1,
    "مضاجعة": 1,
    "بتنام": 1,
    "إفسد": 1,
    "لفافة": 1,
    "لفاف": 1,
    "الجولف": 1,
    "مضارب": 1,
    "الأسطوانات": 1,
    "بايجماليون": 1,
    "خلق": 1,
    "تمثالا": 1,
    "امرأة": 1,
    "أحبه": 1,
    "البيجامة": 1,
    "بايل": 1,
    "بيونج": 1,
    "يانج": 1,
    "بيراكانث": 1,
    "الهرم": 1,
    "الأهرام": 1,
    "بيران": 1,
    "بيركس": 1,
    "لزجاج": 1,
    "بيريداين": 1,
    "وسام": 1,
    "كمثري": 1,
    "البيريميدين": 1,
    "البيريت": 1,
    "البيريتز": 1,
    "متوفرة": 1,
    "كهربي": 1,
    "الحراري": 1,
    "مهووس": 1,
    "بإشعال": 1,
    "البيرومتر": 1,
    "البيرومترات": 1,
    "الحرهرة": 1,
    "المرتفعة": 1,
    "بيروفوسفيت": 1,
    "البيروكسين": 1,
    "البيروكسينيت": 1,
    "مقطعين": 1,
    "قصيرين": 1,
    "فيثاغورس": 1,
    "فيثاغوري": 1,
    "فيثاغوريون": 1,
    "البيثون": 1,
    "افعى": 1,
    "الثعابين": 1,
    "اضطراب": 1,
    "للجهاز": 1,
    "كيو": 1,
    "القات": 1,
    "تمضغ": 1,
    "وتعمل": 1,
    "كعمل": 1,
    "المنبهات": 1,
    "وهو": 1,
    "موقوق": 1,
    "الوقوقة": 1,
    "دجلي": 1,
    "الدجالون": 1,
    "الساحة": 1,
    "رباعي": 1,
    "رباعيات": 1,
    "المخصصة": 1,
    "لدراسة": 1,
    "التربيعية": 1,
    "التربيع": 1,
    "أربع": 1,
    "الرباعي": 1,
    "1,000,000,000,000,000": 1,
    "0.000,000,000,000,001": 1,
    "ذوات": 1,
    "الأربع": 1,
    "يربع": 1,
    "توائم": 1,
    "التربع": 1,
    "كواهوج": 1,
    "يذوى": 1,
    "جاذبية": 1,
    "الكويكري": 1,
    "وطباع": 1,
    "الكويكريون": 1,
    "الإهتزاز": 1,
    "للتأهيل": 1,
    "بقبول": 1,
    "المؤهلون": 1,
    "يتأهل": 1,
    "تأهل": 1,
    "التأهل": 1,
    "نوعي": 1,
    "النوعيات": 1,
    "النوعية": 1,
    "مغثي": 1,
    "بالغثيان": 1,
    "ضليع": 1,
    "كوانتيكو": 1,
    "للكمية": 1,
    "كمي": 1,
    "المقسمون": 1,
    "محجور": 1,
    "المحاجر": 1,
    "كوارك": 1,
    "المتشاجر": 1,
    "متشاجر": 1,
    "للشجار": 1,
    "مقلع": 1,
    "الحجارة": 1,
    "الكوارت": 1,
    "خلف": 1,
    "الفصلية": 1,
    "فصليا": 1,
    "الإعاشة": 1,
    "ضباط": 1,
    "الرباعيات": 1,
    "الرابعة": 1,
    "الربعية": 1,
    "الكوارتات": 1,
    "الكوارتز": 1,
    "الكوارتزيت": 1,
    "صخر": 1,
    "يأتي": 1,
    "يتشابه": 1,
    "سلوكه": 1,
    "لجزيء": 1,
    "المتهدج": 1,
    "مرتجف": 1,
    "المتهدجة": 1,
    "الكندية": 1,
    "كويبيك": 1,
    "الملكة": 1,
    "الملكات": 1,
    "شذوذا": 1,
    "كويز": 1,
    "غصن": 1,
    "إقمع": 1,
    "مقموع": 1,
    "القامع": 1,
    "للإخماد": 1,
    "مخمد": 1,
    "قامع": 1,
    "مطفيء": 1,
    "الضمأ": 1,
    "يطفأ": 1,
    "الإستفسارات": 1,
    "متشكي": 1,
    "التشكي": 1,
    "إستفسار": 1,
    "بتساؤل": 1,
    "مبحوث": 1,
    "الكوازال": 1,
    "طابور": 1,
    "المصطف": 1,
    "المصطفون": 1,
    "الطوابير": 1,
    "الإنتظار": 1,
    "كويزون": 1,
    "إقليم": 1,
    "الكيش": 1,
    "المحيي": 1,
    "المشوق": 1,
    "التسريع": 1,
    "الرمال": 1,
    "الجنيه": 1,
    "يصمت": 1,
    "أسكت": 1,
    "أهدأ": 1,
    "الهمود": 1,
    "كويلوورت": 1,
    "اللحاف": 1,
    "خياطة": 1,
    "اللحف": 1,
    "خمس": 1,
    "السفرجلة": 1,
    "السفرجل": 1,
    "كوينسي": 1,
    "الكينين": 1,
    "كوين": 1,
    "الخناق": 1,
    "التوأم": 1,
    "القنطار": 1,
    "القناطير": 1,
    "الخماسيات": 1,
    "الخامسة": 1,
    "1,000,000,000,000,000,000": 1,
    "0.000,000,000,000,000,001": 1,
    "يخمس": 1,
    "التخميس": 1,
    "كوينتس": 1,
    "قيل": 1,
    "مازحة": 1,
    "الملزمة": 1,
    "كويرينال": 1,
    "إلتواء": 1,
    "كويتو": 1,
    "المخالصة": 1,
    "المخالصات": 1,
    "الانهزامي": 1,
    "الانهزاميون": 1,
    "الرعشة": 1,
    "الرعشات": 1,
    "كاشيوت": 1,
    "الواية": 1,
    "بشهامة": 1,
    "ممتحن": 1,
    "الإختبارات": 1,
    "متسائل": 1,
    "كوونسيت": 1,
    "لأكواخ": 1,
    "متنقلة": 1,
    "الإقتباسات": 1,
    "يومي": 1,
    "خوارج": 1,
    "آر": 1,
    "الراديوم": 1,
    "ربانيا": 1,
    "الرعاع": 1,
    "رابين": 1,
    "أحصنة": 1,
    "المسابقون": 1,
    "مضمار": 1,
    "حلبة": 1,
    "رايتشل": 1,
    "راتشمانينوف": 1,
    "راسين": 1,
    "العنصريون": 1,
    "راك": 1,
    "سبيس": 1,
    "الرادار": 1,
    "الرادارات": 1,
    "رادكليف": 1,
    "للروايات": 1,
    "القوطية": 1,
    "الدائرية": 1,
    "راديان": 1,
    "إشعاع": 1,
    "شع": 1,
    "مشعوع": 1,
    "يشع": 1,
    "المشعاع": 1,
    "المشعاعات": 1,
    "الراديكالي": 1,
    "الراديكالية": 1,
    "التجذر": 1,
    "مجذر": 1,
    "يجذر": 1,
    "التجذير": 1,
    "جذري": 1,
    "الراديكاليون": 1,
    "أنصاف": 1,
    "الإذاعي": 1,
    "الإشعائية": 1,
    "الإشعائي": 1,
    "الإشعاعي": 1,
    "راديو": 1,
    "الإشعاعية": 1,
    "أبرق": 1,
    "إشعاعيا": 1,
    "الشعاعية": 1,
    "بأجهزة": 1,
    "اللاسلكي": 1,
    "المذياع": 1,
    "بأشعة": 1,
    "أكس": 1,
    "رصد": 1,
    "الأحوال": 1,
    "اللاسلكية": 1,
    "البرقيات": 1,
    "التليفون": 1,
    "المهاتفة": 1,
    "بالعناصر": 1,
    "الرادون": 1,
    "الممتصة": 1,
    "راي": 1,
    "رافائيل": 1,
    "النخل": 1,
    "فاحش": 1,
    "باليانصيب": 1,
    "يقامر": 1,
    "الطوافة": 1,
    "رافتر": 1,
    "مرفد": 1,
    "الطوافات": 1,
    "الخرقة": 1,
    "خرقة": 1,
    "الصعلوك": 1,
    "الصعاليك": 1,
    "مهتاج": 1,
    "الإهتياج": 1,
    "المهترئة": 1,
    "مشاهد": 1,
    "مشاهدو": 1,
    "القطار": 1,
    "سيج": 1,
    "الموبخ": 1,
    "الموبخون": 1,
    "السور": 1,
    "المهازلات": 1,
    "المهازلة": 1,
    "سكك": 1,
    "المطر": 1,
    "قزح": 1,
    "المطري": 1,
    "المطرية": 1,
    "الإمطار": 1,
    "الزبيب": 1,
    "راج": 1,
    "الجراف": 1,
    "المتساقطة": 1,
    "ريلاي": 1,
    "محشد": 1,
    "التحشيد": 1,
    "رالف": 1,
    "رالستون": 1,
    "الكبش": 1,
    "رامادا": 1,
    "رامان": 1,
    "المتسلقة": 1,
    "مشتت": 1,
    "المشاغبة": 1,
    "يتفرع": 1,
    "رامريز": 1,
    "النفاث": 1,
    "صدم": 1,
    "رامو": 1,
    "رامونا": 1,
    "التعلية": 1,
    "التعليات": 1,
    "مدك": 1,
    "مدكات": 1,
    "الأكباش": 1,
    "رامسيس": 1,
    "متداعي": 1,
    "الرند": 1,
    "راندل": 1,
    "الهائجون": 1,
    "راندولف": 1,
    "العشوائي": 1,
    "عشوئ": 1,
    "يعشوئ": 1,
    "التعشئة": 1,
    "متراوح": 1,
    "التراوح": 1,
    "رانجون": 1,
    "رانير": 1,
    "المراتب": 1,
    "رانكين": 1,
    "انتخاب": 1,
    "رانكاين": 1,
    "الرتب": 1,
    "إلتهب": 1,
    "يلتهب": 1,
    "الفدية": 1,
    "مفتدى": 1,
    "المفتدي": 1,
    "الإفتداء": 1,
    "الفديات": 1,
    "المتشدق": 1,
    "المتشدقون": 1,
    "راؤول": 1,
    "بضراوة": 1,
    "الإغتصاب": 1,
    "مغتصب": 1,
    "المغتصب": 1,
    "السيف": 1,
    "السيوف": 1,
    "المغتصبون": 1,
    "إهبط": 1,
    "الراب": 1,
    "مغنو": 1,
    "الوئام": 1,
    "سارح": 1,
    "بانغماس": 1,
    "نشوة": 1,
    "الطرب": 1,
    "بانتشاء": 1,
    "التخلخل": 1,
    "مخلخل": 1,
    "يخلخل": 1,
    "خلخل": 1,
    "نادرا": 1,
    "أندر": 1,
    "متشوق": 1,
    "راريتان": 1,
    "جوهر": 1,
    "الديانة": 1,
    "النذالة": 1,
    "نذل": 1,
    "راسموسين": 1,
    "مقشوط": 1,
    "القشاط": 1,
    "القشط": 1,
    "فتات": 1,
    "المبارد": 1,
    "أفقية": 1,
    "راستس": 1,
    "الجرذ": 1,
    "راتا": 1,
    "نيوزيلندا": 1,
    "للتقدير": 1,
    "بالإستدارة": 1,
    "السقاطة": 1,
    "السقاطات": 1,
    "بالأحرى": 1,
    "بالأحرى...": 1,
    "الجوهري": 1,
    "العقلانيون": 1,
    "العقلانية": 1,
    "الترشيد": 1,
    "عقلانيا": 1,
    "الجرذان": 1,
    "راتيل": 1,
    "تهلهلا": 1,
    "الخرخاشة": 1,
    "الخرخاشات": 1,
    "الجرسية": 1,
    "مهلهل": 1,
    "سبقا": 1,
    "بشبق": 1,
    "مدمرة": 1,
    "العربيد": 1,
    "يهتاج": 1,
    "مكرونة": 1,
    "الرافيولي": 1,
    "إنهب": 1,
    "ينهب": 1,
    "هزيلة": 1,
    "نحيفة": 1,
    "بالجلد": 1,
    "التكسية": 1,
    "رولينجز": 1,
    "راولينز": 1,
    "رولينسون": 1,
    "الدبرة": 1,
    "روسون": 1,
    "رايبرن": 1,
    "رايد": 1,
    "رايليغ": 1,
    "رايموند": 1,
    "رايموندفيل": 1,
    "الحرير": 1,
    "رايثيون": 1,
    "محيرة": 1,
    "بالإصدار": 1,
    "كومبيوتر": 1,
    "تعدل": 1,
    "تتضخم": 1,
    "إمتص": 1,
    "ريكلماتيزيشنس": 1,
    "المتناولات": 1,
    "شكر": 1,
    "علما": 1,
    "الحاضرة": 1,
    "مردود": 1,
    "الرجعيون": 1,
    "ردود": 1,
    "المفاعلات": 1,
    "إقرأ": 1,
    "للقراءة": 1,
    "المقروئية": 1,
    "مقروءة": 1,
    "تكييف": 1,
    "يخاطب": 1,
    "أعاد": 1,
    "عنونة": 1,
    "الإستعدادات": 1,
    "الجاهزية": 1,
    "القراءات": 1,
    "تأجل": 1,
    "إقرأني": 1,
    "متبنى": 1,
    "تبني": 1,
    "يقرأ": 1,
    "للإشتغال": 1,
    "للإستلام": 1,
    "للإرسال": 1,
    "ريغان": 1,
    "الكواشف": 1,
    "حقيقي": 1,
    "الإصطفاف": 1,
    "للتحقيق": 1,
    "بواقعية": 1,
    "المتيقن": 1,
    "متيقن": 1,
    "تخصيص": 1,
    "العوالم": 1,
    "الريالات": 1,
    "إحصد": 1,
    "المفغرة": 1,
    "تعيين": 1,
    "تقدر": 1,
    "تسليح": 1,
    "إعتقل": 1,
    "إعتقال": 1,
    "التفكر": 1,
    "تجميع": 1,
    "تأكيده": 1,
    "زعم": 1,
    "ريسيسمينتس": 1,
    "للتعيين": 1,
    "تستوعب": 1,
    "إستيعاب": 1,
    "طمأن": 1,
    "مطمئن": 1,
    "يطمأن": 1,
    "تطميني": 1,
    "إرتباط": 1,
    "يحاول": 1,
    "تخول": 1,
    "تخويل": 1,
    "إصح": 1,
    "مصحو": 1,
    "صحوة": 1,
    "يصحو": 1,
    "معمودية": 1,
    "تعميد": 1,
    "ريبيكا": 1,
    "الإنبعاث": 1,
    "مذاع": 1,
    "إذاعة": 1,
    "إذاعة..": 1,
    "التفنيدات": 1,
    "تدرج": 1,
    "متذكر": 1,
    "التذكير": 1,
    "رسملة": 1,
    "تمويل": 1,
    "لمعاودة": 1,
    "إسترد": 1,
    "مسترد": 1,
    "يسترد": 1,
    "الإسترداد": 1,
    "تفحيم": 1,
    "منحسر": 1,
    "إستلام": 1,
    "الإيصالات": 1,
    "إستلم": 1,
    "موقعا": 1,
    "مستلم": 1,
    "المستلم": 1,
    "المستلمون": 1,
    "الإستلام": 1,
    "مركزة": 1,
    "موظفو": 1,
    "الإستقبالات": 1,
    "التقبل": 1,
    "التلقن": 1,
    "المستقبلات": 1,
    "تراجعي": 1,
    "الإنحسارات": 1,
    "تراجعية": 1,
    "الرجعية": 1,
    "الإرتدائية": 1,
    "المرتكس": 1,
    "ريسيف": 1,
    "للإستقبال": 1,
    "المتبادلات": 1,
    "بادل": 1,
    "مبادل": 1,
    "يبادل": 1,
    "المبادلة": 1,
    "التسميع": 1,
    "المنغمة": 1,
    "حضارة": 1,
    "يمدن": 1,
    "إرع": 1,
    "الحاسبون": 1,
    "الإستصلاح": 1,
    "إتكأ": 1,
    "يتكئ": 1,
    "الإتكاء": 1,
    "المعتكفون": 1,
    "الإنعزال": 1,
    "الإنطوائية": 1,
    "ريكودز": 1,
    "تشفير": 1,
    "المتعرف": 1,
    "تجديد": 1,
    "تذكر": 1,
    "يتذكر": 1,
    "إستعمار": 1,
    "تهجيني": 1,
    "إبدأ": 1,
    "أوص": 1,
    "التوصيات": 1,
    "المزكي": 1,
    "تكليف": 1,
    "إقترف": 1,
    "يقترف": 1,
    "إحالة": 1,
    "مقترف": 1,
    "إقتراف": 1,
    "تتميم": 1,
    "تحسب": 1,
    "حاسبات": 1,
    "للتسوية": 1,
    "الإستطلاع": 1,
    "يستطلع": 1,
    "إستطلع": 1,
    "إحتلال": 1,
    "تعزيز": 1,
    "بثانية": 1,
    "مجددا": 1,
    "ناسخ": 1,
    "بالسجل": 1,
    "المسجلة": 1,
    "إسترجع": 1,
    "مسترجع": 1,
    "يسترجع": 1,
    "الإستعانة": 1,
    "تعافى": 1,
    "المعافاة": 1,
    "الإستجمام": 1,
    "ترفيهي": 1,
    "بلورة": 1,
    "المستقيمات": 1,
    "مستقيمي": 1,
    "المستطيل": 1,
    "مستقيم": 1,
    "كاهن": 1,
    "إرجع": 1,
    "التكرارات": 1,
    "الرجوع": 1,
    "لعنة": 1,
    "استدعاء": 1,
    "التكرارية": 1,
    "معالجته": 1,
    "واستعماله": 1,
    "ريدبيرد": 1,
    "ريدبد": 1,
    "التحمير": 1,
    "إحمرارا": 1,
    "المحمر": 1,
    "تعلن": 1,
    "ريديكليرس": 1,
    "زينة": 1,
    "أهد": 1,
    "المخلص": 1,
    "المخلصون": 1,
    "المتحرر": 1,
    "عرين": 1,
    "إيداع": 1,
    "توديع": 1,
    "مطور": 1,
    "ريدفورد": 1,
    "هضم": 1,
    "تذويب": 1,
    "باحمرار": 1,
    "ريدموند": 1,
    "الزكية": 1,
    "برائحة": 1,
    "بمهابة": 1,
    "الردبول": 1,
    "التصليح": 1,
    "الطيطواة": 1,
    "ريد": 1,
    "ستارت": 1,
    "ريدستون": 1,
    "توب": 1,
    "ريدر": 1,
    "بالقصب": 1,
    "رييدلينج": 1,
    "البوص": 1,
    "رييدفيل": 1,
    "مفاح": 1,
    "نافث": 1,
    "الفوح": 1,
    "إنتخاب": 1,
    "اللفاف": 1,
    "تصعد": 1,
    "تؤكد": 1,
    "توظيف": 1,
    "تشرع": 1,
    "ريس": 1,
    "تخمين": 1,
    "تقيم": 1,
    "مرر": 1,
    "التمرير": 1,
    "تفحص": 1,
    "خفيفة": 1,
    "أحل": 1,
    "المرجع": 1,
    "مرجعي": 1,
    "مرجعيا": 1,
    "الإحالات": 1,
    "الراجع": 1,
    "يحيل": 1,
    "إخصاب": 1,
    "العبوة": 1,
    "للتعبأة": 1,
    "العبوات": 1,
    "الانعكاس": 1,
    "منعكس": 1,
    "الإنعكاسات": 1,
    "عاكس": 1,
    "الإنعكاسية": 1,
    "العاكسات": 1,
    "حان": 1,
    "إنعكاسي": 1,
    "إزدهر": 1,
    "طوي": 1,
    "تشجير": 1,
    "الإصلاحيات": 1,
    "تهيئة": 1,
    "منصلح": 1,
    "المصلحون": 1,
    "إصلاحي": 1,
    "تأسيس": 1,
    "إنكسر": 1,
    "منكسر": 1,
    "انكساري": 1,
    "إنكسارية": 1,
    "المنكسر": 1,
    "ينكسر": 1,
    "تجميد": 1,
    "تنعش": 1,
    "المنعش": 1,
    "المنعشات": 1,
    "المرطبات": 1,
    "قلي": 1,
    "تزود": 1,
    "التزود": 1,
    "يتزود": 1,
    "المأوى": 1,
    "إلتجأ": 1,
    "اللاجىء": 1,
    "اللاجئون": 1,
    "الإلتجاء": 1,
    "مبلغ": 1,
    "السداد": 1,
    "المبالغ": 1,
    "تأثيث": 1,
    "الداحض": 1,
    "يستعيد": 1,
    "متع": 1,
    "يمتع": 1,
    "بخصوص": 1,
    "مهما": 1,
    "يكن": 1,
    "الوصي": 1,
    "الأوصياء": 1,
    "إستنبت": 1,
    "الريجيه": 1,
    "الفوج": 1,
    "التكتيب": 1,
    "الأفواج": 1,
    "ريجينا": 1,
    "ريجنالد": 1,
    "الإقليمية": 1,
    "ريجيس": 1,
    "تعطي": 1,
    "إرتدادي": 1,
    "إعاقة": 1,
    "الأسف": 1,
    "آسف": 1,
    "مؤسف": 1,
    "أسف": 1,
    "نم": 1,
    "تنمية": 1,
    "منمى": 1,
    "ينمي": 1,
    "النظامي": 1,
    "الإنتظام": 1,
    "بإنتظام": 1,
    "النظاميون": 1,
    "التعليمة": 1,
    "التقئ": 1,
    "معه": 1,
    "قولبة": 1,
    "تسخين": 1,
    "الرايخ": 1,
    "ريتشينبيرج": 1,
    "رييتشستاج": 1,
    "النواب,": 1,
    "للإمبراطورية": 1,
    "رايلي": 1,
    "للإبهار": 1,
    "الزمام": 1,
    "التناسخ": 1,
    "إندماج": 1,
    "الرنة": 1,
    "معدى": 1,
    "إعداء": 1,
    "يعدي": 1,
    "تلهب": 1,
    "إلهاب": 1,
    "عزز": 1,
    "أفعم": 1,
    "راينارد": 1,
    "رينهاردت": 1,
    "راينولد": 1,
    "تشغل": 1,
    "شغلت": 1,
    "تلقيح": 1,
    "تفتيش": 1,
    "أوعز": 1,
    "موعز": 1,
    "الإيعاز": 1,
    "يوعز": 1,
    "تأمين": 1,
    "يتكامل": 1,
    "ترجمة": 1,
    "تفسير": 1,
    "مقابلة": 1,
    "الأعادة": 1,
    "إستثمار": 1,
    "إنتعاش": 1,
    "دعوة": 1,
    "المرفوض": 1,
    "رفضي": 1,
    "المرفوضون": 1,
    "سعيدة": 1,
    "منضم": 1,
    "تحوك": 1,
    "إعتبرت": 1,
    "المعاودة": 1,
    "المنتكس": 1,
    "تالي": 1,
    "علائقي": 1,
    "النسبية": 1,
    "النسبوية": 1,
    "بالنسبية": 1,
    "إرتح": 1,
    "الإرخاء": 1,
    "مرتاح": 1,
    "الإسترخاء": 1,
    "المهديء": 1,
    "يرتاح": 1,
    "الإرتياح": 1,
    "تتعلم": 1,
    "لن": 1,
    "ملين": 1,
    "بالموضوع": 1,
    "بصلة": 1,
    "وثيقة": 1,
    "الإعتمادية": 1,
    "متكل": 1,
    "بإعتماد": 1,
    "مجيز": 1,
    "ريليكت": 1,
    "الإغاثة": 1,
    "إشعر": 1,
    "بالإرتياح": 1,
    "المريح": 1,
    "شعور": 1,
    "الأديان": 1,
    "دينيا": 1,
    "التدين": 1,
    "للمتعة": 1,
    "عيش": 1,
    "تحميل": 1,
    "قرض": 1,
    "مكانه": 1,
    "إنتقل": 1,
    "منتقل": 1,
    "ينتقل": 1,
    "ممانع": 1,
    "تكبر": 1,
    "تبقى": 1,
    "التبقي": 1,
    "المتبقي": 1,
    "ريمبراندت": 1,
    "إصلاحية": 1,
    "علاجي": 1,
    "المتذكر": 1,
    "المذكر": 1,
    "ريمنجتن": 1,
    "تذكري": 1,
    "بالذكرى": 1,
    "مغفرته": 1,
    "الحوالة": 1,
    "الحوالات": 1,
    "تحوير": 1,
    "تنقد": 1,
    "يجيز": 1,
    "كعملة": 1,
    "تنقيد": 1,
    "إحتج": 1,
    "يحتج": 1,
    "بندم": 1,
    "المجددة": 1,
    "إعتلاء": 1,
    "البديلة": 1,
    "الإزالات": 1,
    "كافئ": 1,
    "مكافأ": 1,
    "يكافئ": 1,
    "ريمس": 1,
    "لروملس": 1,
    "رينا": 1,
    "النهضة": 1,
    "كلوي": 1,
    "أعاده": 1,
    "طبيعته": 1,
    "يعيده": 1,
    "العوده": 1,
    "رينو": 1,
    "تواعد": 1,
    "التواعد": 1,
    "رينيه": 1,
    "ريني": 1,
    "إنكث": 1,
    "نكث": 1,
    "ينكث": 1,
    "مفاوضات": 1,
    "المنفحة": 1,
    "رينوا": 1,
    "ترشيح": 1,
    "ومقبولة": 1,
    "الهاجر": 1,
    "رمم": 1,
    "يرمم": 1,
    "الترميم": 1,
    "المرمم": 1,
    "الصيت": 1,
    "رينسيلار": 1,
    "يروف": 1,
    "بالتنازل": 1,
    "رينفيل": 1,
    "تعارض": 1,
    "إعادات": 1,
    "يغير": 1,
    "فئة": 1,
    "الممثلين": 1,
    "تهدأة": 1,
    "إصبغ": 1,
    "صباغة": 1,
    "يصبغ": 1,
    "التصليحات": 1,
    "الجواب": 1,
    "يمر": 1,
    "الأكلات": 1,
    "تبليط": 1,
    "التسديدات": 1,
    "مرارا": 1,
    "وتكرارا": 1,
    "المكررون": 1,
    "التوبة": 1,
    "توبة": 1,
    "يندم": 1,
    "ناس": 1,
    "تشك": 1,
    "متشكى": 1,
    "المتضايق": 1,
    "يتشكى": 1,
    "إستبدل": 1,
    "يستبدل": 1,
    "الإستبدال": 1,
    "المرافعة": 1,
    "المعبيء": 1,
    "للمرة": 1,
    "شبعان": 1,
    "الملىء": 1,
    "الشبع": 1,
    "مجاب": 1,
    "المجاوب": 1,
    "الأجوبة": 1,
    "بولندي": 1,
    "تشيع": 1,
    "التعمير": 1,
    "الصحفية": 1,
    "مخبر": 1,
    "ذكرت": 1,
    "تقريري": 1,
    "بسكون": 1,
    "ملصق": 1,
    "للتمثيل": 1,
    "بتمثيل": 1,
    "مكبوت": 1,
    "للكبح": 1,
    "تسعير": 1,
    "التنفيس": 1,
    "الإنتقامي": 1,
    "الإنتقامية": 1,
    "للتأنيب": 1,
    "ملقي": 1,
    "تأنيبي": 1,
    "بتأنيب": 1,
    "لائما": 1,
    "ملوم": 1,
    "النقمة": 1,
    "التكاثر": 1,
    "بتكاثر": 1,
    "التأنيب": 1,
    "مؤنبا": 1,
    "فئات": 1,
    "ريبتيل": 1,
    "الجمهورية": 1,
    "الجمهوريون": 1,
    "الجمهوريات": 1,
    "الكريه": 1,
    "الصدة": 1,
    "لكمة": 1,
    "شراء": 1,
    "تنقي": 1,
    "بتوقير": 1,
    "أرواح": 1,
    "تطلب": 1,
    "المتطلب": 1,
    "يتطلب": 1,
    "التطلب": 1,
    "مبلغا": 1,
    "يعاود": 1,
    "مسار": 1,
    "جاري": 1,
    "للتالي": 1,
    "صهريج": 1,
    "تنقذ": 1,
    "ضوئيا": 1,
    "منقذ": 1,
    "تعزل": 1,
    "عزل": 1,
    "تفرقة": 1,
    "إختيار": 1,
    "البائعون": 1,
    "الشبيه": 1,
    "إشبه": 1,
    "يرسل": 1,
    "بإمتعاض": 1,
    "حسبت": 1,
    "ريسيرباين": 1,
    "إضافتهه": 1,
    "للإحتياطي": 1,
    "التحفظات": 1,
    "الإحتياطيات": 1,
    "الإحتياط": 1,
    "الخزان": 1,
    "الإستيطان": 1,
    "مستوطن": 1,
    "تخيط": 1,
    "تشحذ": 1,
    "إستقر": 1,
    "يستقر": 1,
    "متبقي": 1,
    "الترسبات": 1,
    "البواقي": 1,
    "منخول": 1,
    "الإستقالة": 1,
    "الإستقالات": 1,
    "مستقيل": 1,
    "المستقيل": 1,
    "يستقيل": 1,
    "الرزين": 1,
    "بعنصر": 1,
    "راتنجي": 1,
    "الراتنج": 1,
    "قاوم": 1,
    "أخت": 1,
    "مقاومته": 1,
    "المقاومات": 1,
    "ريسيزينج": 1,
    "نعل": 1,
    "تنعيل": 1,
    "إسترخائي": 1,
    "العزيمة": 1,
    "العزائم": 1,
    "الملاذ": 1,
    "رنان": 1,
    "رن": 1,
    "الرنانون": 1,
    "ريسورسينول": 1,
    "فينول": 1,
    "المصيف": 1,
    "ملجوء": 1,
    "فارز": 1,
    "المصايف": 1,
    "دو": 1,
    "مدوي": 1,
    "خنزيرة": 1,
    "بذرت": 1,
    "بإحترام": 1,
    "متسما": 1,
    "التوالي": 1,
    "تهج": 1,
    "متهجى": 1,
    "تهجي": 1,
    "يتهجى": 1,
    "تنفسي": 1,
    "متنفس": 1,
    "ممهل": 1,
    "التأجيلات": 1,
    "الإمهال": 1,
    "المستجيبون": 1,
    "المجاوبون": 1,
    "بمسؤولية": 1,
    "للقبول": 1,
    "أوكسفورد": 1,
    "متجاوب": 1,
    "باستجابة": 1,
    "الاستجابة": 1,
    "إستأنف": 1,
    "برهانا": 1,
    "مستأنف": 1,
    "مبدئ": 1,
    "الإستئناف": 1,
    "المستريح": 1,
    "المخزون": 1,
    "للترميم": 1,
    "المقوية": 1,
    "المرممون": 1,
    "بضبط": 1,
    "بتحديد": 1,
    "هيكلة": 1,
    "الهيكلة": 1,
    "تقديمه": 1,
    "المحصلات": 1,
    "النتاج": 1,
    "للتكملة": 1,
    "معاود": 1,
    "تموين": 1,
    "الظاهر": 1,
    "منبعث": 1,
    "النهوض": 1,
    "ينبعث": 1,
    "إنبعث": 1,
    "إحيائي": 1,
    "مصاحبة": 1,
    "مزامن": 1,
    "بالمفرد": 1,
    "يبيع": 1,
    "إحتفظ": 1,
    "محتفظ": 1,
    "الإحتفاظ": 1,
    "الاحتباس": 1,
    "يحتفظ": 1,
    "أخذه": 1,
    "إنتقم": 1,
    "منتقم": 1,
    "الإنتقام": 1,
    "انتقامي": 1,
    "إنتقامي": 1,
    "المانعات": 1,
    "تهوع": 1,
    "متهوع": 1,
    "يتهوع": 1,
    "التهوع": 1,
    "الاستبقاء": 1,
    "التكتم": 1,
    "شبكي": 1,
    "يشبك": 1,
    "لمعدة": 1,
    "شبكية": 1,
    "الشبكية": 1,
    "تقاعد": 1,
    "متقاعد": 1,
    "يتقاعد": 1,
    "لمس": 1,
    "للتقصي": 1,
    "متتبع": 1,
    "يتتبع": 1,
    "إنسحب": 1,
    "للسحب": 1,
    "منسحب": 1,
    "للانقباض": 1,
    "الإنقباضات": 1,
    "جرار": 1,
    "الكامشات": 1,
    "ينسحب": 1,
    "تدريب": 1,
    "متراجع": 1,
    "ريتريتر": 1,
    "تخفيضات": 1,
    "جازى": 1,
    "كافأ": 1,
    "المسترد": 1,
    "المستردون": 1,
    "ريترو": 1,
    "اعد": 1,
    "أدراجه": 1,
    "العائد": 1,
    "أعكس": 1,
    "مراجعة": 1,
    "تاريخ": 1,
    "ريفيفال": 1,
    "يسحب": 1,
    "ريفولتر": 1,
    "الثوريون": 1,
    "الثورية": 1,
    "مثور": 1,
    "يثور": 1,
    "التثوير": 1,
    "للدوران": 1,
    "المنوعات": 1,
    "مجزي": 1,
    "دافئ": 1,
    "تزن": 1,
    "تجشأت": 1,
    "يطوي": 1,
    "ريكس": 1,
    "ريكفيك": 1,
    "رينولدز": 1,
    "أحمرأخضرأزرقألفا": 1,
    "بعاطفة": 1,
    "مفرطة": 1,
    "مؤلفو": 1,
    "تحمس": 1,
    "يتحمس": 1,
    "الرابسودي": 1,
    "ريا": 1,
    "ريمز": 1,
    "رينهولدت": 1,
    "الراين": 1,
    "الرينيوم": 1,
    "بتشوه": 1,
    "المتغيرة": 1,
    "بلاغي": 1,
    "البلاغية": 1,
    "البلغاء": 1,
    "مفعمون": 1,
    "بالبلاغة": 1,
    "بالروماتيزم": 1,
    "الروماتزم": 1,
    "روماتيزمي": 1,
    "رينلاندر": 1,
    "الكركدن": 1,
    "الكركدنات": 1,
    "رو": 1,
    "رودا": 1,
    "رود": 1,
    "رودس": 1,
    "روديسيا": 1,
    "الروديوم": 1,
    "الريددندرين": 1,
    "الرودونيت": 1,
    "قرنفلي": 1,
    "معيني": 1,
    "المعينة": 1,
    "روندا": 1,
    "الروبارب": 1,
    "القافية": 1,
    "مقفى": 1,
    "القوافي": 1,
    "التقفية": 1,
    "ايلند": 1,
    "التضليع": 1,
    "بشريط": 1,
    "فيتامين": 1,
    "ريبوز": 1,
    "البنتوز": 1,
    "بالريبوسوم": 1,
    "ريبوسوم": 1,
    "ريكا": 1,
    "ريكي": 1,
    "ريتشاردز": 1,
    "ريتشاردسون": 1,
    "يثري": 1,
    "أثرى": 1,
    "الإثراء": 1,
    "أغنى": 1,
    "ريتشي": 1,
    "ريتشفيلد": 1,
    "ريتشلاند": 1,
    "ريتشموند": 1,
    "ركتر": 1,
    "ريك": 1,
    "ريكينباوجه": 1,
    "الكساح": 1,
    "الطفيلية": 1,
    "محلحل": 1,
    "مركوب": 1,
    "الراكب": 1,
    "الراكبون": 1,
    "ريج": 1,
    "ريدجيفيلد": 1,
    "ردجواي": 1,
    "الإمتطاء": 1,
    "ريجيل": 1,
    "ريمان": 1,
    "ريمانيان": 1,
    "يخدش": 1,
    "ريغا": 1,
    "التزوير": 1,
    "ريجس": 1,
    "يمين": 1,
    "المحق": 1,
    "اليمنى": 1,
    "الجاك": 1,
    "الأيمن": 1,
    "الميكروفون": 1,
    "إستقام": 1,
    "باستقامة": 1,
    "شرعا": 1,
    "يميني": 1,
    "اليمين": 1,
    "يمينا": 1,
    "الحكاية": 1,
    "المهمرجة": 1,
    "الحكايات": 1,
    "بصرامة": 1,
    "الإغاظة": 1,
    "ريلك": 1,
    "سجع": 1,
    "بالصقيع": 1,
    "رينيهارت": 1,
    "المتقطع": 1,
    "سماعة": 1,
    "قارع": 1,
    "قارعو": 1,
    "ووضوح": 1,
    "الجديلة": 1,
    "الجدائل": 1,
    "المتزحلق": 1,
    "التشطيف": 1,
    "الشطاف": 1,
    "يشطف": 1,
    "الشطف": 1,
    "ريو": 1,
    "ريوردان": 1,
    "إضطرابات": 1,
    "شغب": 1,
    "مشاغب": 1,
    "بشغب": 1,
    "التمزق": 1,
    "ضفاف": 1,
    "بنضج": 1,
    "إنضج": 1,
    "المنضج": 1,
    "ريبلي": 1,
    "الممزق": 1,
    "الفاسدون": 1,
    "توجيهات": 1,
    "الناهض": 1,
    "الناهضون": 1,
    "ريسيسيترانسيت": 1,
    "مضحكون": 1,
    "خوطر": 1,
    "المجازف": 1,
    "خطرا": 1,
    "مجازفة": 1,
    "المنسك": 1,
    "المناسك": 1,
    "ريتير": 1,
    "الطقوسية": 1,
    "المتمسك": 1,
    "بالطقوس": 1,
    "شعائري": 1,
    "المتمسكون": 1,
    "طقس": 1,
    "مطقس": 1,
    "يطقس": 1,
    "التطقيس": 1,
    "ريتز": 1,
    "منافس": 1,
    "التنافسات": 1,
    "ضفة": 1,
    "الأنهار": 1,
    "الضفة": 1,
    "ريفيرفيو": 1,
    "المبرشم": 1,
    "شاطئ": 1,
    "الريفييرا": 1,
    "النهير": 1,
    "الرياض": 1,
    "مصلحو": 1,
    "قارعة": 1,
    "قارعات": 1,
    "تصليح": 1,
    "الهائم": 1,
    "الأغبر": 1,
    "الزئير": 1,
    "المزمجر": 1,
    "الشواء": 1,
    "محمص": 1,
    "التحميص": 1,
    "يحمص": 1,
    "روب": 1,
    "روبي": 1,
    "روبن": 1,
    "روبينس": 1,
    "روبرت": 1,
    "روبيرتا": 1,
    "روبيرتو": 1,
    "روبرتس": 1,
    "روبيرتسون": 1,
    "روبيرتسونس": 1,
    "روبينز": 1,
    "روبنسن": 1,
    "روبنسونفيل": 1,
    "الآليون": 1,
    "روكو": 1,
    "روتشستر": 1,
    "روكيفيلير": 1,
    "الجنائن": 1,
    "الهزازة": 1,
    "الجنينة": 1,
    "وتصميم": 1,
    "روكفورد": 1,
    "صخري": 1,
    "روكيز": 1,
    "روكلاند": 1,
    "روكفيل": 1,
    "روكويل": 1,
    "روكوود": 1,
    "قارض": 1,
    "مسابقة": 1,
    "روجيرس": 1,
    "رودني": 1,
    "رودريجو": 1,
    "رونتجن": 1,
    "لأشعة": 1,
    "روجر": 1,
    "روجرز": 1,
    "عربد": 1,
    "معربد": 1,
    "المعربد": 1,
    "العربدة": 1,
    "يعربد": 1,
    "رولند": 1,
    "الأدوار": 1,
    "اللفة": 1,
    "للدحرجة": 1,
    "الدحروجة": 1,
    "المزلجة": 1,
    "الدحروجات": 1,
    "الملفوفة": 1,
    "رولنز": 1,
    "اللفات": 1,
    "إليها": 1,
    "الرومانسية": 1,
    "الرومانسي": 1,
    "الرومانسيون": 1,
    "الرومانسيات": 1,
    "رومانيا": 1,
    "رومانو": 1,
    "العاطفي": 1,
    "روميو": 1,
    "روميوس": 1,
    "رومبير": 1,
    "السروال": 1,
    "رومولوس": 1,
    "رون": 1,
    "رونالد": 1,
    "روني": 1,
    "بناءون": 1,
    "المغدفات": 1,
    "المغدفة": 1,
    "روكي": 1,
    "روزفيلت": 1,
    "فرانكين": 1,
    "المجثم": 1,
    "المجاثم": 1,
    "الجذر": 1,
    "العراقة": 1,
    "الجذور": 1,
    "رورسكاتش": 1,
    "روزا": 1,
    "روزابيل": 1,
    "روزالي": 1,
    "المسبحات": 1,
    "المسبحة": 1,
    "الوردة": 1,
    "بتفاؤل": 1,
    "أغصان": 1,
    "ثمرة": 1,
    "روزلاند": 1,
    "روزيلا": 1,
    "أكاليل": 1,
    "روزماري": 1,
    "روزنبيرج": 1,
    "روزنبلوم": 1,
    "روزنثال": 1,
    "روزنزويج": 1,
    "روزيتا": 1,
    "روزيت": 1,
    "روزتيس": 1,
    "روزوود": 1,
    "روزي": 1,
    "وردية": 1,
    "الراتينج": 1,
    "الصنوبري": 1,
    "روس": 1,
    "روزسي": 1,
    "روزويل": 1,
    "الروتاري": 1,
    "للتدوير": 1,
    "تدويري": 1,
    "دائر": 1,
    "الدوارات": 1,
    "الاحتياط": 1,
    "روتينون": 1,
    "روث": 1,
    "روثشيلد": 1,
    "البدن": 1,
    "كروية": 1,
    "الروبل": 1,
    "تثخينة": 1,
    "خشنا": 1,
    "أخشن": 1,
    "تعارك": 1,
    "العنيفون": 1,
    "الأشراس": 1,
    "الروليت": 1,
    "رحلة": 1,
    "كاملة": 1,
    "ذهابا": 1,
    "وإيابا": 1,
    "مستديرة": 1,
    "مدور": 1,
    "إستدارة": 1,
    "المستديرة": 1,
    "موقظ": 1,
    "الموقض": 1,
    "الإيقاظ": 1,
    "روسو": 1,
    "روستابوت": 1,
    "الإندحار": 1,
    "المسارات": 1,
    "الروتينات": 1,
    "الإندحارات": 1,
    "روان": 1,
    "زوارق": 1,
    "مجذف": 1,
    "شوكة": 1,
    "روينا": 1,
    "رولاند": 1,
    "رولي": 1,
    "روكسبري": 1,
    "روكسي": 1,
    "روي": 1,
    "الملكيون": 1,
    "رويس": 1,
    "روزيل": 1,
    "أجبني": 1,
    "سمحت": 1,
    "رتي": 1,
    "رواندا": 1,
    "التدليك": 1,
    "مفروك": 1,
    "مطط": 1,
    "ممطط": 1,
    "يمطط": 1,
    "التمطيط": 1,
    "السائح": 1,
    "الفرك": 1,
    "الأنقاض": 1,
    "روبين": 1,
    "الفلمنكية": 1,
    "الروبيديوم": 1,
    "الياقوت": 1,
    "الروبلات": 1,
    "الإرشادات": 1,
    "الجربنديات": 1,
    "الدفات": 1,
    "متورد": 1,
    "تورد": 1,
    "كناية": 1,
    "أوقح": 1,
    "رودولف": 1,
    "رودي": 1,
    "رديارد": 1,
    "الحرمل": 1,
    "الكشكشة": 1,
    "المختال": 1,
    "للحمرة": 1,
    "روفس": 1,
    "البساط": 1,
    "الروم": 1,
    "الرومانيون": 1,
    "الرومبا": 1,
    "الدمدمة": 1,
    "مقرقع": 1,
    "المدوي": 1,
    "يقرقع": 1,
    "قرقرة": 1,
    "المجترة": 1,
    "رومفورد": 1,
    "المجترات": 1,
    "إجتر": 1,
    "يجتر": 1,
    "الإجترار": 1,
    "رمل": 1,
    "مفشي": 1,
    "الشائعات": 1,
    "الإشاعات": 1,
    "الردف": 1,
    "الروني": 1,
    "الرونية": 1,
    "رنج": 1,
    "الساقية": 1,
    "السواقي": 1,
    "سيولة": 1,
    "رنيميد": 1,
    "المرات": 1,
    "الضعفاء": 1,
    "المدارج": 1,
    "رنيون": 1,
    "الروبية": 1,
    "الروبيات": 1,
    "روبيرت": 1,
    "التمزقات": 1,
    "روراليتي": 1,
    "الطلبية": 1,
    "العاجلة": 1,
    "المتعجل": 1,
    "رشمور": 1,
    "روسل": 1,
    "الزعفراني": 1,
    "الزعفرانية": 1,
    "الروس": 1,
    "روسولا": 1,
    "ريف": 1,
    "مريف": 1,
    "يريف": 1,
    "التريف": 1,
    "صدءا": 1,
    "بصدىء": 1,
    "الحفيف": 1,
    "محفوف": 1,
    "سارق": 1,
    "سراق": 1,
    "يحف": 1,
    "للصدأ": 1,
    "الحف": 1,
    "صدئ": 1,
    "روتجيرز": 1,
    "الروثينيوم": 1,
    "روثرفورد": 1,
    "رتايل": 1,
    "التيتانيوم": 1,
    "رتلاند": 1,
    "رتليدج": 1,
    "محفر": 1,
    "قراءةكتابة": 1,
    "موارد": 1,
    "التنمية": 1,
    "الاقتصادية": 1,
    "رايان": 1,
    "ريدبيرج": 1,
    "رايدر": 1,
    "الجاودار": 1,
    "بالماضي": 1,
    "إباحة": 1,
    "إشهار": 1,
    "تجل": 1,
    "الميليشيا": 1,
    "المخلوقة": 1,
    "بقدسية": 1,
    "إجازة": 1,
    "تؤخذ": 1,
    "سبع": 1,
    "سبتي": 1,
    "بالسيف": 1,
    "سابينا": 1,
    "سابين": 1,
    "تيكساس": 1,
    "السمامير": 1,
    "القبقاب": 1,
    "القباقيب": 1,
    "صبرا": 1,
    "اسرائيل": 1,
    "الحويصلة": 1,
    "السكرين": 1,
    "ساتشس": 1,
    "والموسيقى": 1,
    "الكياس": 1,
    "عجزي": 1,
    "ساكرامينتو": 1,
    "ضحية": 1,
    "المضحي": 1,
    "المضحون": 1,
    "التضحيات": 1,
    "تدنيس": 1,
    "مصون": 1,
    "الحويصلات": 1,
    "صدام": 1,
    "حسين": 1,
    "إيران.": 1,
    "تسبب": 1,
    "غزوه": 1,
    "للكويت": 1,
    "حرب": 1,
    "الثانية.": 1,
    "الخرج": 1,
    "أخراج": 1,
    "مسرج": 1,
    "السراج": 1,
    "السروج": 1,
    "السراجون": 1,
    "الإسراج": 1,
    "السادية": 1,
    "السادي": 1,
    "ساديا": 1,
    "الساديون": 1,
    "سادلر": 1,
    "المحزن": 1,
    "ممزوجة": 1,
    "بتعذيب": 1,
    "الذات": 1,
    "ومعذب": 1,
    "بسلامة": 1,
    "أمانا": 1,
    "الأمن": 1,
    "القرطم": 1,
    "الحصافة": 1,
    "الميرمية": 1,
    "ساجينو": 1,
    "سهمي": 1,
    "نخيل": 1,
    "ساجوارو": 1,
    "اشجار": 1,
    "الصحارى": 1,
    "سيجون": 1,
    "بحارة": 1,
    "القديسون": 1,
    "الخواطر": 1,
    "ساكورا": 1,
    "للبيع": 1,
    "سالابلي": 1,
    "السمندر": 1,
    "السمندرات": 1,
    "السلامي": 1,
    "سالم": 1,
    "ساليرنو": 1,
    "البائعة": 1,
    "ساليسايان": 1,
    "البائعات": 1,
    "ساليسيليكي": 1,
    "سالينا": 1,
    "سالينجير": 1,
    "ساليسبري": 1,
    "اللعاب": 1,
    "لعابي": 1,
    "الإندفاعات": 1,
    "سالي": 1,
    "السالمونيلا": 1,
    "الصالون": 1,
    "الصالونات": 1,
    "الصلصا": 1,
    "سالسيفي": 1,
    "الصلت": 1,
    "سالتبش": 1,
    "أملح": 1,
    "مالحا": 1,
    "التمليح": 1,
    "ترحيبيا": 1,
    "ترحيبي": 1,
    "سالوتر": 1,
    "سلفادور": 1,
    "السلفادوري": 1,
    "للإنقاذ": 1,
    "النقائذ": 1,
    "سالفاتور": 1,
    "الصواني": 1,
    "الصلية": 1,
    "الصليات": 1,
    "سالز": 1,
    "سامري": 1,
    "السامريون": 1,
    "السمريوم": 1,
    "نفيس": 1,
    "السامبا": 1,
    "سامو": 1,
    "السامون": 1,
    "السماور": 1,
    "سابمسن": 1,
    "سامسن": 1,
    "صموئيل": 1,
    "صمويل": 1,
    "سامويلسون": 1,
    "سان": 1,
    "صنعاء": 1,
    "سانبورن": 1,
    "سانتشيز": 1,
    "سانشو": 1,
    "بتظاهر": 1,
    "بالتقوى": 1,
    "القداسة": 1,
    "المعتكف": 1,
    "المعتكفات": 1,
    "الصندل": 1,
    "الصنادل": 1,
    "الشاطىء": 1,
    "الرملي": 1,
    "الجلي": 1,
    "بالرمل": 1,
    "ساندبيرغ": 1,
    "صقل": 1,
    "سانديرلينج": 1,
    "ساندرز": 1,
    "ساندرسن": 1,
    "سانديا": 1,
    "المنومات": 1,
    "الطيطوي": 1,
    "ساندرا": 1,
    "ساندسكاي": 1,
    "السندويتش": 1,
    "السندويتشات": 1,
    "ساندي": 1,
    "عاقل": 1,
    "أعقل": 1,
    "سانفورد": 1,
    "السنجري": 1,
    "السانجريا": 1,
    "سانهيدرين": 1,
    "الكنائسي": 1,
    "للأمة": 1,
    "سانيكل": 1,
    "المصح": 1,
    "عقم": 1,
    "تصريف": 1,
    "سلامة": 1,
    "يعقم": 1,
    "التعقيم": 1,
    "غرق": 1,
    "سانس": 1,
    "سنسكريتي": 1,
    "بابا": 1,
    "سانتايانا": 1,
    "سانتياغو": 1,
    "سانتو": 1,
    "بالإنسان": 1,
    "الشتلة": 1,
    "الشتلات": 1,
    "صوبن": 1,
    "عصارة": 1,
    "العصارة": 1,
    "عصيري": 1,
    "سابسكير": 1,
    "انواع": 1,
    "نقار": 1,
    "ساره": 1,
    "سيره": 1,
    "ساران": 1,
    "ساراسوتا": 1,
    "ساراتوجا": 1,
    "التهكمات": 1,
    "اللحمي": 1,
    "السردين": 1,
    "سارجينت": 1,
    "الساري": 1,
    "الفشاغ": 1,
    "لباسي": 1,
    "سارتري": 1,
    "الزنار": 1,
    "ترنح": 1,
    "يترنح": 1,
    "الزنانير": 1,
    "ساسكاتشوان": 1,
    "ساسكاتون": 1,
    "المجففة": 1,
    "لشجرة": 1,
    "الساسافراس": 1,
    "ساسين": 1,
    "يزدري": 1,
    "الساتين": 1,
    "الساتان": 1,
    "بقابلية": 1,
    "الهجاؤون": 1,
    "إهج": 1,
    "يهجو": 1,
    "للرضا": 1,
    "ساتوري": 1,
    "إدراك": 1,
    "للتشبع": 1,
    "زحل": 1,
    "ساتور": 1,
    "الإغريقي": 1,
    "إباحي": 1,
    "شبقي": 1,
    "سعود": 1,
    "السعودي": 1,
    "سوكفيل": 1,
    "ساول": 1,
    "سولت": 1,
    "حمام": 1,
    "البخار": 1,
    "حمامات": 1,
    "ساوندرز": 1,
    "المتهادي": 1,
    "الهمج": 1,
    "العشبي": 1,
    "أنقذ": 1,
    "الموفرون": 1,
    "ينقذ": 1,
    "التوفير": 1,
    "المدخرات": 1,
    "سافوير": 1,
    "سافونارولا": 1,
    "مذاق": 1,
    "المتذوق": 1,
    "المشهية": 1,
    "المشهي": 1,
    "سافوي": 1,
    "شاطر": 1,
    "الشطارة": 1,
    "الساوبيلي": 1,
    "دولارات": 1,
    "نشارة": 1,
    "بالمنشار": 1,
    "سوفيش": 1,
    "بمؤخرة": 1,
    "كالمنشار": 1,
    "طاولة": 1,
    "نشره": 1,
    "مشرشف": 1,
    "النشار": 1,
    "ساكس": 1,
    "سكسوني": 1,
    "السكسونيون": 1,
    "ساكسوني": 1,
    "الساكسفون": 1,
    "الساكسفونات": 1,
    "الساكس": 1,
    "ساكستون": 1,
    "القائل": 1,
    "القائلون": 1,
    "يقول": 1,
    "الجلبة": 1,
    "الأغماد": 1,
    "احتشام": 1,
    "السقالة": 1,
    "التسقيل": 1,
    "السقالات": 1,
    "سكالا": 1,
    "استخدمت": 1,
    "لمعالجة": 1,
    "خلع": 1,
    "جدوى": 1,
    "يتعاظم": 1,
    "السمط": 1,
    "السلقة": 1,
    "الحرشفية": 1,
    "الصدفي": 1,
    "صدفي": 1,
    "الأسقلوب": 1,
    "المحارات": 1,
    "المباضع": 1,
    "سلخ": 1,
    "فروات": 1,
    "الجمبري": 1,
    "الأشقياء": 1,
    "الفضيحة": 1,
    "الإسكندنافيا": 1,
    "إسكندنافي": 1,
    "الإسكندنافيون": 1,
    "الإسكنديوم": 1,
    "النواسخ": 1,
    "تقطيع": 1,
    "الضآلة": 1,
    "سكاب": 1,
    "كبش": 1,
    "الفداء": 1,
    "أكباش": 1,
    "كتفي": 1,
    "كتفيات": 1,
    "الندبة": 1,
    "الجعلان": 1,
    "سكاربوروه": 1,
    "الفزاعات": 1,
    "المفزع": 1,
    "الوشاح": 1,
    "سكارفيس": 1,
    "المتحدة.": 1,
    "الوشاحات": 1,
    "إخافة": 1,
    "مخدوش": 1,
    "الخادش": 1,
    "إخدش": 1,
    "سكارلاتي": 1,
    "القرمزي": 1,
    "سكارسديل": 1,
    "التلفيعة": 1,
    "مسرعا": 1,
    "إنتقاد": 1,
    "بانتقاد": 1,
    "البراز": 1,
    "المشتتون": 1,
    "متفرق": 1,
    "المشتت": 1,
    "سكوب": 1,
    "إقتت": 1,
    "مقتات": 1,
    "الزبال": 1,
    "الزبالون": 1,
    "الإقتيات": 1,
    "بمصدر": 1,
    "السيناريو": 1,
    "السيناريوهات": 1,
    "المشهد": 1,
    "بتفويض": 1,
    "شكاك": 1,
    "شايفر": 1,
    "شايفير": 1,
    "شافر": 1,
    "شافنير": 1,
    "شانز": 1,
    "شابيرو": 1,
    "المجدول": 1,
    "الجدولة": 1,
    "شيهيريزاد": 1,
    "شين": 1,
    "شيل": 1,
    "شيلينج": 1,
    "مخططات": 1,
    "خطة": 1,
    "مدبرو": 1,
    "سكينيكتدي": 1,
    "شيلر": 1,
    "إنشقاقي": 1,
    "الإنشقاقات": 1,
    "بالفصام": 1,
    "فطريات": 1,
    "الفصام": 1,
    "تلكأ": 1,
    "شلسينجر": 1,
    "سكليرين": 1,
    "تكتلات": 1,
    "وتكون": 1,
    "باقي": 1,
    "شليز": 1,
    "شلوس": 1,
    "شمالزي": 1,
    "شميت": 1,
    "سكنابل": 1,
    "الشنوزر": 1,
    "شنيدر": 1,
    "شوينبيرج": 1,
    "سكوفيلد": 1,
    "شولاستيس": 1,
    "الشنطة": 1,
    "المدرسي": 1,
    "تلميذ": 1,
    "تلاميذ": 1,
    "المدارس": 1,
    "تلميذة": 1,
    "تلميذات": 1,
    "Schoolhouses": 1,
    "شوبينهاير": 1,
    "شوتكي": 1,
    "شرويدر": 1,
    "شرويدنجير": 1,
    "المضحك": 1,
    "شوبيرت": 1,
    "شولز": 1,
    "شوماخر": 1,
    "شومان": 1,
    "شوستر": 1,
    "شويلر": 1,
    "شيلكيل": 1,
    "شواب": 1,
    "شوارز": 1,
    "شويزير": 1,
    "وركي": 1,
    "علميا": 1,
    "الشرارة": 1,
    "أومض": 1,
    "يومض": 1,
    "بتأثير": 1,
    "الانشقاق": 1,
    "الانشقاقات": 1,
    "صلبي": 1,
    "بقلة": 1,
    "إحترام": 1,
    "سليطة": 1,
    "المعاتب": 1,
    "سليطات": 1,
    "الكعكات": 1,
    "الغارف": 1,
    "بالمغرفة": 1,
    "شعراء": 1,
    "العتيقة": 1,
    "الحارقة": 1,
    "التلويحات": 1,
    "محرق": 1,
    "بطاقات": 1,
    "مخلفات": 1,
    "المصهور": 1,
    "الاحتقار": 1,
    "العقارب": 1,
    "الإسكتلندي": 1,
    "إسكتلندي": 1,
    "سكوتشجارد": 1,
    "أسكوتلندا": 1,
    "الإسكتلنديون": 1,
    "سكوت": 1,
    "سكوتسدال": 1,
    "سكوتي": 1,
    "وغد": 1,
    "الفراكة": 1,
    "الكشاف": 1,
    "المستطلع": 1,
    "الكشافة": 1,
    "الكشافون": 1,
    "لجرف": 1,
    "سكرابلير": 1,
    "النحيف": 1,
    "الهزال": 1,
    "هزالأكثر": 1,
    "تعرج": 1,
    "هزالالأكثر": 1,
    "النحيفون": 1,
    "مجاهد": 1,
    "الانصراف": 1,
    "سكرانتون": 1,
    "المكشطة": 1,
    "المكشطات": 1,
    "الورطات": 1,
    "تفككا": 1,
    "للهرش": 1,
    "الخدوش": 1,
    "التمهيدية": 1,
    "الشخبطة": 1,
    "بخط": 1,
    "هزالة": 1,
    "بصياح": 1,
    "المملة": 1,
    "أساسه": 1,
    "السينمائية": 1,
    "الشاشات": 1,
    "لقطة": 1,
    "البرغي": 1,
    "برغي": 1,
    "بندق": 1,
    "الأهوج": 1,
    "سكروبين": 1,
    "المفك": 1,
    "المفكات": 1,
    "شد": 1,
    "المبرغي": 1,
    "البراغي": 1,
    "بلوفلاي": 1,
    "طباعي": 1,
    "السكريم": 1,
    "تقتيرا": 1,
    "سكريبس": 1,
    "للقاريء": 1,
    "كتابيا": 1,
    "سكريفين": 1,
    "الليمفاوية": 1,
    "خنازيري": 1,
    "اللفيفة": 1,
    "للتدرج": 1,
    "شريطة": 1,
    "تتيح": 1,
    "رؤية": 1,
    "والصور": 1,
    "شرائط": 1,
    "اللفائف": 1,
    "الصفن": 1,
    "إستجد": 1,
    "يستجدى": 1,
    "بوعورة": 1,
    "الدعك": 1,
    "التورع": 1,
    "إكتشافه": 1,
    "مدقق": 1,
    "بفحص": 1,
    "السكازي": 1,
    "سكود": 1,
    "صواريخ": 1,
    "السكود": 1,
    "جرجر": 1,
    "مجرجر": 1,
    "الجرجرة": 1,
    "يجرجر": 1,
    "المجدفون": 1,
    "سكلبين": 1,
    "إنحت": 1,
    "النحاتة": 1,
    "النحاتات": 1,
    "ينحت": 1,
    "نحتي": 1,
    "تزبد": 1,
    "رغوية": 1,
    "ينطلق": 1,
    "الأسقربوط": 1,
    "اختيارين": 1,
    "أحلاهما": 1,
    "المناجل": 1,
    "سيثيا": 1,
    "سيبورج": 1,
    "بحرا": 1,
    "سيبروك": 1,
    "سواحل": 1,
    "سيجيت": 1,
    "صلبة": 1,
    "سيجرام": 1,
    "للغلق": 1,
    "مانعات": 1,
    "الفقمة": 1,
    "سيلي": 1,
    "الدرز": 1,
    "أسوء": 1,
    "تامة": 1,
    "شون": 1,
    "جلسات": 1,
    "اسماك": 1,
    "فاحصة": 1,
    "بحث***": 1,
    "شاطىء": 1,
    "شواطئ": 1,
    "شاطيء": 1,
    "موسمي": 1,
    "موسميا": 1,
    "محنك": 1,
    "مستعملو": 1,
    "جالس": 1,
    "سياتل": 1,
    "للإبحار": 1,
    "سيباستيان": 1,
    "ثواميثانية": 1,
    "القاطعات": 1,
    "المنسحب": 1,
    "إنفصالي": 1,
    "الإنفصاليون": 1,
    "بعزلة": 1,
    "الإختلاء": 1,
    "إنعزالي": 1,
    "المرتبات": 1,
    "بالثانوي": 1,
    "ثنى": 1,
    "التثنية": 1,
    "ثانيا": 1,
    "الثواني": 1,
    "سكرتاري": 1,
    "سيكريتاريات": 1,
    "الأمانات": 1,
    "السكرتيرات": 1,
    "السكرتير": 1,
    "إفرازي": 1,
    "الأسرار": 1,
    "القطاعات": 1,
    "علماني": 1,
    "العلمانية": 1,
    "ملحد": 1,
    "العلمانيون": 1,
    "العلمنة": 1,
    "علمن": 1,
    "معلمن": 1,
    "يعلمن": 1,
    "مأمون": 1,
    "الحمالة": 1,
    "الرزانة": 1,
    "اﻹقامة": 1,
    "السعادى": 1,
    "سيدجويك": 1,
    "الراسب": 1,
    "الترسب": 1,
    "أغو": 1,
    "مغوى": 1,
    "الإغواءات": 1,
    "المغرر": 1,
    "المغررون": 1,
    "مغري": 1,
    "الغواية": 1,
    "الغاوية": 1,
    "الغاويات": 1,
    "البذرة": 1,
    "المشاتل": 1,
    "ناثر": 1,
    "ناثرو": 1,
    "بذورا": 1,
    "البذر": 1,
    "أرد": 1,
    "يريد": 1,
    "إبد": 1,
    "بدا": 1,
    "تسرب": 1,
    "النز": 1,
    "متسرب": 1,
    "يتسرب": 1,
    "العرافون": 1,
    "الإرجوحة": 1,
    "متأرجح": 1,
    "إغل": 1,
    "مغلي": 1,
    "الغلي": 1,
    "سيجوفيا": 1,
    "المعزول": 1,
    "داعية": 1,
    "سيجاندو": 1,
    "لشرب": 1,
    "الإقطاعي": 1,
    "الإقطاعيون": 1,
    "سيادي": 1,
    "الأسياد": 1,
    "سييل": 1,
    "زلزالي": 1,
    "تسجيلات": 1,
    "زلازلي": 1,
    "بالزلازل": 1,
    "المصادرون": 1,
    "التملك": 1,
    "لإمتلاك": 1,
    "المتملكون": 1,
    "للإختيار": 1,
    "يقع": 1,
    "الانتقاء": 1,
    "الإختياريات": 1,
    "الإنتقائية": 1,
    "الامور": 1,
    "نشأتها": 1,
    "مسيرو": 1,
    "الإنتقاء": 1,
    "سيلينا": 1,
    "سيلينيت": 1,
    "السلينيك": 1,
    "سلنيومي": 1,
    "السلنيوم": 1,
    "لافلزي": 1,
    "برغيا": 1,
    "سلفريدج": 1,
    "سيلكيرك": 1,
    "مهجورة": 1,
    "شيلي": 1,
    "سيليد": 1,
    "سلمى": 1,
    "للمحافظة": 1,
    "كربونية": 1,
    "الأنفس": 1,
    "معاني": 1,
    "دلاليا": 1,
    "الإخصائيون": 1,
    "المني": 1,
    "الدراسي": 1,
    "الفارزة": 1,
    "المنقوطة": 1,
    "الفوارز": 1,
    "العتمة": 1,
    "المتأهل": 1,
    "لشبه": 1,
    "المتأهلون": 1,
    "المباريات": 1,
    "شهري": 1,
    "مؤثرا": 1,
    "اللاهوتي": 1,
    "الطلبة": 1,
    "اللاهوتيون": 1,
    "سيمينول": 1,
    "حرفية": 1,
    "سيميراميس": 1,
    "أشورية": 1,
    "الساميات": 1,
    "إسبوعي": 1,
    "السميذ": 1,
    "الشين": 1,
    "الإستشارية": 1,
    "سناتوري": 1,
    "سيناتوري": 1,
    "مرسلمتلقي": 1,
    "سينيكا": 1,
    "السنيغال": 1,
    "بخرف": 1,
    "الأقدم": 1,
    "الأقدمية": 1,
    "السنا": 1,
    "أحسس": 1,
    "الإثارية": 1,
    "الأحاسيس": 1,
    "محس": 1,
    "بعقلانية": 1,
    "أناس": 1,
    "حسس": 1,
    "محسس": 1,
    "يحسس": 1,
    "التحسيس": 1,
    "المحسس": 1,
    "المجسات": 1,
    "المجس": 1,
    "المحسسات": 1,
    "الحسية": 1,
    "الشهواني": 1,
    "الشهوانيون": 1,
    "الشهوانية": 1,
    "حسيا": 1,
    "بريدا": 1,
    "عقوبي": 1,
    "بجملة": 1,
    "للوعظ": 1,
    "بوعي": 1,
    "العاطفيون": 1,
    "مصبح": 1,
    "صبح": 1,
    "حرس": 1,
    "سيؤول": 1,
    "سبتمبر/أيلول": 1,
    "سيبال": 1,
    "سيبالس": 1,
    "منفصلا": 1,
    "الإفتراق": 1,
    "الإنفصالية": 1,
    "الإنفصالي": 1,
    "سيبيرات": 1,
    "البني": 1,
    "بحجاب": 1,
    "السباعية": 1,
    "السباعيات": 1,
    "السبتيليون": 1,
    "وعلى": 1,
    "شماله": 1,
    "السبعوني": 1,
    "السبعونيون": 1,
    "المرتب": 1,
    "المعقبات": 1,
    "التتابعية": 1,
    "الترتر": 1,
    "تتعلق": 1,
    "بالإجراء": 1,
    "سيكويا": 1,
    "الأمصال": 1,
    "سيرافين": 1,
    "سيريب": 1,
    "المكسيكيين": 1,
    "الساروف": 1,
    "ملائكية": 1,
    "سيرافيم": 1,
    "الصربي": 1,
    "صربيا": 1,
    "السيربو": 1,
    "السلافية": 1,
    "للصرب": 1,
    "والكروات": 1,
    "غن": 1,
    "مواهب": 1,
    "إكتشافي": 1,
    "إكتشافيا": 1,
    "موهبة": 1,
    "بصفاء": 1,
    "العبد": 1,
    "القنانة": 1,
    "العباد": 1,
    "سيرج": 1,
    "العرفاء": 1,
    "سيرجي": 1,
    "الصوفية": 1,
    "للموازاة": 1,
    "التسلسلات": 1,
    "المسلسلات": 1,
    "تباعا": 1,
    "السيرين": 1,
    "بجدية": 1,
    "الواعظون": 1,
    "سيربينز": 1,
    "الاستوائية": 1,
    "أفعواني": 1,
    "يتعرج": 1,
    "سيرا": 1,
    "مسنن": 1,
    "يسنن": 1,
    "التسنين": 1,
    "المصل": 1,
    "المصول": 1,
    "مخدوم": 1,
    "الخادمالقنوات": 1,
    "الصلاح": 1,
    "الغبيراء": 1,
    "أرجوانية": 1,
    "الخدمةالملف": 1,
    "العبودية": 1,
    "الماكينة": 1,
    "سيرفو": 1,
    "المؤازرة": 1,
    "الماكينات": 1,
    "السمسم": 1,
    "بكلمات": 1,
    "بالقاعدة": 1,
    "انعقادي": 1,
    "النكسة": 1,
    "النكسات": 1,
    "سيث": 1,
    "سيتون": 1,
    "زعيمة": 1,
    "دينية": 1,
    "الصامولة": 1,
    "الصواميل": 1,
    "المحددات": 1,
    "المستوطنة": 1,
    "المستوطنات": 1,
    "الإعدادات": 1,
    "السبعات": 1,
    "سابع": 1,
    "سابعا": 1,
    "السبعينات": 1,
    "سبعون": 1,
    "حدية": 1,
    "سيفيرن": 1,
    "ويلز": 1,
    "إشبيلية": 1,
    "سوارد": 1,
    "مخيط": 1,
    "الستيني": 1,
    "بالرقم": 1,
    "جنسية": 1,
    "متعصبون": 1,
    "للجنس": 1,
    "الشهوانيات": 1,
    "سيكستانز": 1,
    "السدسية": 1,
    "السدسيات": 1,
    "لستة": 1,
    "مؤدين": 1,
    "السكستليون": 1,
    "القندلفت": 1,
    "سدس": 1,
    "يسدس": 1,
    "التوائم": 1,
    "الستة": 1,
    "التسديس": 1,
    "مجنس": 1,
    "سايتشيلي": 1,
    "سيمور": 1,
    "بالأصفاد": 1,
    "شاد": 1,
    "شادبش": 1,
    "الشادوك": 1,
    "فاكهه": 1,
    "مظلل": 1,
    "الظلال": 1,
    "شادفلوير": 1,
    "ظلالا": 1,
    "جاسوس": 1,
    "الشاد": 1,
    "شافير": 1,
    "إنكح": 1,
    "شاجبارك": 1,
    "منكوح": 1,
    "شعثا": 1,
    "أشعث": 1,
    "ينكح": 1,
    "الشاه": 1,
    "للهز": 1,
    "المضطجع": 1,
    "الهزازات": 1,
    "شكسبيري": 1,
    "السجيل": 1,
    "ضحالة": 1,
    "الضحالة": 1,
    "الضحلة": 1,
    "شالوم": 1,
    "سوف": 1,
    "الشامان": 1,
    "الشامبو": 1,
    "بالشامبو": 1,
    "المغتسل": 1,
    "النفل": 1,
    "شنغهاي": 1,
    "إختطف": 1,
    "يختطف": 1,
    "شانون": 1,
    "ابتدع": 1,
    "الشانتون": 1,
    "جمال": 1,
    "المشكل": 1,
    "للمشاركة": 1,
    "ازرع": 1,
    "بالعمولة": 1,
    "كومبيوتري": 1,
    "نسخه": 1,
    "وتوزيعه": 1,
    "شاري": 1,
    "القرش": 1,
    "حادا": 1,
    "إشحذ": 1,
    "المبراة": 1,
    "المباري": 1,
    "مختبيء": 1,
    "جيدو": 1,
    "شاستا": 1,
    "شاتيربروف": 1,
    "شاتاك": 1,
    "محلوق": 1,
    "شو": 1,
    "شاوانو": 1,
    "الشال": 1,
    "الشالات": 1,
    "شوني": 1,
    "الجزاز": 1,
    "سلاخ": 1,
    "أغمد": 1,
    "مغمد": 1,
    "غمده": 1,
    "يغمد": 1,
    "الإغماد": 1,
    "إحزم": 1,
    "شيبويجان": 1,
    "شيهان": 1,
    "اللمعة": 1,
    "بملاءة": 1,
    "الشراشف": 1,
    "شافيلد": 1,
    "المشيخة": 1,
    "المشيخات": 1,
    "شيلا": 1,
    "شيلبي": 1,
    "شيلدون": 1,
    "قصف‎": 1,
    "يتخاطب": 1,
    "اللك": 1,
    "طلي": 1,
    "بدهان": 1,
    "نيران": 1,
    "صامد": 1,
    "للقنابل": 1,
    "المؤوي": 1,
    "شيلتن": 1,
    "الأرفف": 1,
    "شيناندواه": 1,
    "الإشكال": 1,
    "الإشكالات": 1,
    "شيبرد": 1,
    "فضاء": 1,
    "رعى": 1,
    "الراعيات": 1,
    "الشيراتون": 1,
    "شريدان": 1,
    "شيرلوك": 1,
    "شيرمان": 1,
    "الشيري": 1,
    "شيرون": 1,
    "شيروود": 1,
    "صاد": 1,
    "يخجل": 1,
    "شيعي": 1,
    "الشيعة": 1,
    "الشلن": 1,
    "الشلنات": 1,
    "شيلونج": 1,
    "ميجهاليا": 1,
    "شيلوه": 1,
    "إهتز": 1,
    "البراق": 1,
    "البراقون": 1,
    "لواح": 1,
    "التسقيف": 1,
    "يتسلق": 1,
    "الشنتو": 1,
    "الشنتويه": 1,
    "متون": 1,
    "بنائو": 1,
    "لجسم": 1,
    "شيبلي": 1,
    "لنيوزيلندا": 1,
    "للشحن": 1,
    "حطام": 1,
    "المسفنة": 1,
    "المسافن": 1,
    "تهرب": 1,
    "متهرب": 1,
    "التهريب": 1,
    "يتهرب": 1,
    "شيرلي": 1,
    "اللم": 1,
    "يلم": 1,
    "القمصان": 1,
    "ترزي": 1,
    "شيش": 1,
    "كسلاح": 1,
    "أغنية": 1,
    "شمويل": 1,
    "الأسراب": 1,
    "المفزعات": 1,
    "شوكلي": 1,
    "أردء": 1,
    "الرديئ": 1,
    "لتسهيل": 1,
    "أداوات": 1,
    "أحذية": 1,
    "شوميك": 1,
    "شوير": 1,
    "شوجي": 1,
    "ستار": 1,
    "ياباني": 1,
    "أشرق": 1,
    "مهشوش": 1,
    "الهش": 1,
    "شوس": 1,
    "الدكان": 1,
    "الدكاكين": 1,
    "تسوق": 1,
    "المتسوق": 1,
    "المتسوقون": 1,
    "مهتريء": 1,
    "حواف": 1,
    "شوروود": 1,
    "المنقص": 1,
    "السراويل": 1,
    "انترنت": 1,
    "الطلقة": 1,
    "الأكتاف": 1,
    "الصائح": 1,
    "الصائحون": 1,
    "الشوفيلبورد": 1,
    "الجرافون": 1,
    "التجريف": 1,
    "بيده": 1,
    "العائم": 1,
    "المواجهات": 1,
    "الدش": 1,
    "زخات": 1,
    "المتباهي": 1,
    "التباهي": 1,
    "اعرض": 1,
    "اظهار": 1,
    "الشضية": 1,
    "ماكينات": 1,
    "شريفيبورت": 1,
    "الزبابة": 1,
    "السليطة": 1,
    "فطنة": 1,
    "سليط": 1,
    "السلاطة": 1,
    "الصرد": 1,
    "الروبيان": 1,
    "للتقلص": 1,
    "المتراجع": 1,
    "بالذنب": 1,
    "ذبل": 1,
    "الذبول": 1,
    "شريفير": 1,
    "الكفن": 1,
    "العشرة": 1,
    "تأتي": 1,
    "الجنبات": 1,
    "الجنبة": 1,
    "بفتح": 1,
    "الإهتزازات": 1,
    "الشفلبورد": 1,
    "المتثاقل": 1,
    "شلمان": 1,
    "التحويلة": 1,
    "حديدية": 1,
    "الأغلاق": 1,
    "فريق": 1,
    "احراز": 1,
    "اهداف": 1,
    "الدرفة": 1,
    "الدرفات": 1,
    "المكوك": 1,
    "يتنقل": 1,
    "المرابي": 1,
    "باستحياء": 1,
    "سيال": 1,
    "سيام": 1,
    "اسيا": 1,
    "ورسميا": 1,
    "تايلاند": 1,
    "سيامي": 1,
    "أخ": 1,
    "سايبيريا": 1,
    "سايبيري": 1,
    "الصفير": 1,
    "المهموس": 1,
    "صافر": 1,
    "المهموسة": 1,
    "سيبلي": 1,
    "الشقيق": 1,
    "الأشقاء": 1,
    "العرافات": 1,
    "هكذا": 1,
    "صقلي": 1,
    "المقزز": 1,
    "الإمراض": 1,
    "أمرض": 1,
    "بالمنجل": 1,
    "سيكلوورت": 1,
    "السوالف": 1,
    "المسحوبة": 1,
    "يتحدث": 1,
    "بالتليفون": 1,
    "جدار": 1,
    "مجانب": 1,
    "يجانب": 1,
    "المجانبة": 1,
    "سيدني": 1,
    "سيجل": 1,
    "سيغفريد": 1,
    "سيجموند": 1,
    "سيمينز": 1,
    "سينا": 1,
    "تربة": 1,
    "القيلولة": 1,
    "سيفيرز": 1,
    "فيلولوجي": 1,
    "المناخل": 1,
    "المغربلات": 1,
    "ينخل": 1,
    "التنهدة": 1,
    "متنهد": 1,
    "المتنهد": 1,
    "التنهد": 1,
    "التنهدات": 1,
    "المشاهدات": 1,
    "الأجمل": 1,
    "بجولة": 1,
    "مشاهدة": 1,
    "معالم": 1,
    "سيجما": 1,
    "للحرف": 1,
    "للإشاعة": 1,
    "التواقيع": 1,
    "الموقعين": 1,
    "التبيين": 1,
    "توقيعتحقق": 1,
    "اللافتة": 1,
    "معلم": 1,
    "لافتات": 1,
    "اللافتات": 1,
    "سيخي": 1,
    "السيخ": 1,
    "سيكيم": 1,
    "للهند": 1,
    "سيكورسكاي": 1,
    "سيلاس": 1,
    "كاتم": 1,
    "كاتمات": 1,
    "السكوت": 1,
    "سيليسيا": 1,
    "سيليكس": 1,
    "للسليكون": 1,
    "المطحون": 1,
    "الظلية": 1,
    "السيليكا": 1,
    "السيليكات": 1,
    "بمادة": 1,
    "السيليسيد": 1,
    "احد": 1,
    "السيليكون": 1,
    "السليكوون": 1,
    "السحار": 1,
    "حريرية": 1,
    "كالحرير": 1,
    "الحرائر": 1,
    "القز": 1,
    "الغرين": 1,
    "مغرين": 1,
    "التغرين": 1,
    "المتحجر": 1,
    "بالطمي": 1,
    "الفضة": 1,
    "طلا": 1,
    "بالفضة": 1,
    "سيلفرمان": 1,
    "صائغ": 1,
    "صاغة": 1,
    "سيلفيرشتاين": 1,
    "المصنوعات": 1,
    "سيما": 1,
    "التشبيهات": 1,
    "المشابهة": 1,
    "سمونس": 1,
    "سمونسفيل": 1,
    "سيمز": 1,
    "سايمون": 1,
    "سمونسون": 1,
    "سموني": 1,
    "الإبتسامة": 1,
    "المتكلفة": 1,
    "مبتسم": 1,
    "الإبتسام": 1,
    "الإبتسامات": 1,
    "البسطاء": 1,
    "بالإتصالات": 1,
    "البساطات": 1,
    "مبسط": 1,
    "المبسط": 1,
    "ببساطة": 1,
    "سيمبسن": 1,
    "سمولاتيف": 1,
    "المحاكيات": 1,
    "أذع": 1,
    "يذيع": 1,
    "التواقت": 1,
    "سيناء": 1,
    "سيناترا": 1,
    "بصدق": 1,
    "أصدق": 1,
    "سينكلير": 1,
    "الزاية": 1,
    "السهلة": 1,
    "سنغافورة": 1,
    "السطحي": 1,
    "سطحيا": 1,
    "المغنون": 1,
    "بالوكالة": 1,
    "الوحيدة": 1,
    "الإفراد": 1,
    "فرادى": 1,
    "المفرد": 1,
    "سينه": 1,
    "إتيان": 1,
    "طبائع": 1,
    "أعسر": 1,
    "العصمة": 1,
    "مأثوم": 1,
    "الآثم": 1,
    "متكلس": 1,
    "يتكلس": 1,
    "بالمنحنى": 1,
    "جيبي": 1,
    "ملتو": 1,
    "السو": 1,
    "الرشفة": 1,
    "السيفون": 1,
    "بالسيفون": 1,
    "السيفونات": 1,
    "مرشوف": 1,
    "الرشف": 1,
    "المولى": 1,
    "صفارات": 1,
    "الشعرى": 1,
    "العاتية": 1,
    "السيزال": 1,
    "السسكن": 1,
    "الأختية": 1,
    "الأخوات": 1,
    "بالبابا": 1,
    "سيكستس": 1,
    "سيسواتي": 1,
    "الشاق": 1,
    "عانى": 1,
    "الاسطوري": 1,
    "كورينث": 1,
    "سيسيفوس": 1,
    "إجلس": 1,
    "السيتار": 1,
    "يجلس": 1,
    "راعيات": 1,
    "قع": 1,
    "الوقع": 1,
    "سيتوس": 1,
    "سيفا": 1,
    "الستات": 1,
    "بستة": 1,
    "السدس": 1,
    "سادسا": 1,
    "الستينات": 1,
    "ستون": 1,
    "الحجوم": 1,
    "تحجيم": 1,
    "المسموح": 1,
    "سكا": 1,
    "السكات": 1,
    "ورقية": 1,
    "الزلاجة": 1,
    "متزحلق": 1,
    "المتزلجون": 1,
    "الزلاجات": 1,
    "التزلج": 1,
    "السكيت": 1,
    "رماية": 1,
    "شلة": 1,
    "شلات": 1,
    "هيكلي": 1,
    "العظمية": 1,
    "كراسة": 1,
    "كراسات": 1,
    "مسيخ": 1,
    "التسييخ": 1,
    "الأشياش": 1,
    "المزلاجة": 1,
    "زلق": 1,
    "سكين": 1,
    "إقشط": 1,
    "تصفية": 1,
    "قتر": 1,
    "مقتر": 1,
    "التقتير": 1,
    "يقتر": 1,
    "يقشط": 1,
    "الدباغ": 1,
    "الدباغون": 1,
    "نحولا": 1,
    "نحيل": 1,
    "سكيبجاك": 1,
    "التونا": 1,
    "الربان": 1,
    "الربانون": 1,
    "سكيبي": 1,
    "اول": 1,
    "تحصل": 1,
    "المناوشة": 1,
    "مناوش": 1,
    "المتحارب": 1,
    "المتحاربون": 1,
    "المناوشات": 1,
    "المتجنب": 1,
    "المزلاجات": 1,
    "إنزلق": 1,
    "ينزلق": 1,
    "سكيتل": 1,
    "سكوبي": 1,
    "تسلل": 1,
    "متسلل": 1,
    "المختبيء": 1,
    "المختبئون": 1,
    "يتسلل": 1,
    "الطاقيات": 1,
    "بجمجمة": 1,
    "الجماجم": 1,
    "الظرابين": 1,
    "سكاي": 1,
    "هيلوكوبتر": 1,
    "لحمل": 1,
    "مختطفو": 1,
    "السقفية": 1,
    "نوافذ": 1,
    "وبناء": 1,
    "ناطحة": 1,
    "سحاب": 1,
    "ناطحات": 1,
    "تكتب": 1,
    "اطلاق": 1,
    "دخان": 1,
    "إركد": 1,
    "راكد": 1,
    "يركد": 1,
    "الكسالى": 1,
    "بطيئ": 1,
    "سلاكوير": 1,
    "إخبث": 1,
    "خابث": 1,
    "يخبث": 1,
    "أرو": 1,
    "مروى": 1,
    "مفترى": 1,
    "النمامون": 1,
    "مصفوع": 1,
    "الصفع": 1,
    "الصفعات": 1,
    "المشرح": 1,
    "بناؤو": 1,
    "القذرات": 1,
    "ارجواني": 1,
    "السلاف": 1,
    "كدح": 1,
    "النخاسون": 1,
    "سلافي": 1,
    "خانع": 1,
    "الخنوع": 1,
    "الصقلبيون": 1,
    "إذبح": 1,
    "يذبح": 1,
    "تزلج": 1,
    "تنقل": 1,
    "بالزلاجة": 1,
    "أملس": 1,
    "نعم": 1,
    "التنعيم": 1,
    "ينعم": 1,
    "النائم": 1,
    "النائمون": 1,
    "بنعاس": 1,
    "مؤرق": 1,
    "ينام": 1,
    "نائما": 1,
    "اثناء": 1,
    "يمشون": 1,
    "الإثلاج": 1,
    "ثلجي": 1,
    "الأكمام": 1,
    "ينحف": 1,
    "نحف": 1,
    "التنحيف": 1,
    "نيم": 1,
    "يتجسس": 1,
    "للتقطيع": 1,
    "القطاعة": 1,
    "وسهولة": 1,
    "أهين": 1,
    "مخاطية": 1,
    "أنحل": 1,
    "النحفاء": 1,
    "التنحيل": 1,
    "إنسل": 1,
    "الإنسلال": 1,
    "ينسل": 1,
    "الزلة": 1,
    "سليب": 1,
    "املس": 1,
    "الانزلاق": 1,
    "الزلات": 1,
    "مروحة": 1,
    "انزلق": 1,
    "المزلقة": 1,
    "مزحلق": 1,
    "بفتحة": 1,
    "ضيقة": 1,
    "المشقق": 1,
    "المشققون": 1,
    "الشظايا": 1,
    "بأخشاب": 1,
    "سلون": 1,
    "شاب": 1,
    "انجليزي": 1,
    "الغنية": 1,
    "مبتل": 1,
    "سلوكم": 1,
    "البرقوق": 1,
    "فضلات": 1,
    "مسكوب": 1,
    "إهمالا": 1,
    "باهمال": 1,
    "السكب": 1,
    "الرخيصة": 1,
    "خض": 1,
    "مخاض": 1,
    "يخوض": 1,
    "الخوض": 1,
    "السلوث.": 1,
    "سلوثفولنيس": 1,
    "متراخى": 1,
    "بترهل": 1,
    "التهدلات": 1,
    "ترهل": 1,
    "التراخي": 1,
    "السلوفاكي": 1,
    "سلوفاكيا": 1,
    "سلوفينيا": 1,
    "السلوفيني": 1,
    "البطئ": 1,
    "بطيئو": 1,
    "وحل": 1,
    "البزاقة": 1,
    "البزاق": 1,
    "الغافي": 1,
    "الركود": 1,
    "الفقيرة": 1,
    "اللطخة": 1,
    "مدغم": 1,
    "طين": 1,
    "اللطخات": 1,
    "الفاسقة": 1,
    "الفاسقات": 1,
    "عاهرة": 1,
    "بعهر": 1,
    "لكمات": 1,
    "سمال": 1,
    "سمالي": 1,
    "الجدري": 1,
    "سمولوود": 1,
    "الذكية": 1,
    "أنق": 1,
    "مؤنق": 1,
    "التأنيق": 1,
    "يؤنق": 1,
    "بالخجل": 1,
    "بسطحية": 1,
    "المعرفات": 1,
    "المسحة": 1,
    "الملطخ": 1,
    "تلطخ": 1,
    "المسحات": 1,
    "رائحته": 1,
    "الهف": 1,
    "المبتسم": 1,
    "المعبرة.": 1,
    "سميث": 1,
    "الأشتات": 1,
    "سميثفيلد": 1,
    "سميثسن": 1,
    "سمثسوني": 1,
    "سميثتاون": 1,
    "المضبب": 1,
    "للتدخين": 1,
    "لتدخين": 1,
    "المدخنون": 1,
    "ساتر": 1,
    "للجنود": 1,
    "دخانا": 1,
    "دخاني": 1,
    "يعانق": 1,
    "ملس": 1,
    "أنعم": 1,
    "بيسر": 1,
    "إخنق": 1,
    "مخنوق": 1,
    "الإحصائية": 1,
    "سموكير": 1,
    "المهربون": 1,
    "الاعتزاز": 1,
    "الهندام": 1,
    "سخاما": 1,
    "التسخيم": 1,
    "سميرنا": 1,
    "سميذي": 1,
    "الوجبة": 1,
    "الكافيتريا": 1,
    "لخبط": 1,
    "اللخبطات": 1,
    "متمزق": 1,
    "الحلزون": 1,
    "سنيك": 1,
    "لدغة": 1,
    "لدغات": 1,
    "أفعوي": 1,
    "سناكيروت": 1,
    "الطقة": 1,
    "ينتعش": 1,
    "النهاش": 1,
    "النهاشات": 1,
    "نزقا": 1,
    "نزق": 1,
    "الطقات": 1,
    "اللقطة": 1,
    "مصطاد": 1,
    "سنارف": 1,
    "الإصطياد": 1,
    "مزمجر": 1,
    "يزمجر": 1,
    "الخلسة": 1,
    "سنيد": 1,
    "منسل": 1,
    "سرانية": 1,
    "المخادعة": 1,
    "العطاس": 1,
    "عاطس": 1,
    "العاطس": 1,
    "العطس": 1,
    "الشص": 1,
    "وخيط": 1,
    "السنكرز": 1,
    "الشمة": 1,
    "مشموم": 1,
    "متشمم": 1,
    "الشم": 1,
    "إشهق": 1,
    "شاهق": 1,
    "يشهق": 1,
    "الشهيق": 1,
    "إستنشاقي": 1,
    "شهيقي": 1,
    "القرقرات": 1,
    "الشنقب": 1,
    "مقنوص": 1,
    "القناص": 1,
    "القناصون": 1,
    "القنص": 1,
    "لسانه": 1,
    "سلاطة": 1,
    "يخبر": 1,
    "الإخبار": 1,
    "الباكي": 1,
    "الباكون": 1,
    "المتأبه": 1,
    "التأبه": 1,
    "تكبرا": 1,
    "المتأبهون": 1,
    "سنودجراس": 1,
    "سنوك": 1,
    "السنوكر": 1,
    "سنوكس": 1,
    "السنوك": 1,
    "المتلصص": 1,
    "المتلصصون": 1,
    "سنوبي": 1,
    "عجرفة": 1,
    "إغف": 1,
    "يغفو": 1,
    "يشخر": 1,
    "غص": 1,
    "يغوص": 1,
    "الفخام": 1,
    "النخرة": 1,
    "اختيال": 1,
    "مختال": 1,
    "الخطم": 1,
    "بأنف": 1,
    "الخطوم": 1,
    "متضاعف": 1,
    "التضاعف": 1,
    "المنحدرلت": 1,
    "الفار": 1,
    "بحثا": 1,
    "ن": 1,
    "بالثلوج": 1,
    "تساقط": 1,
    "ندفة": 1,
    "بأحذية": 1,
    "ثلجية": 1,
    "الماشى": 1,
    "الدافئة": 1,
    "مزجور": 1,
    "الزجر": 1,
    "يزجر": 1,
    "شم": 1,
    "النشوق": 1,
    "مطفئة": 1,
    "إشخر": 1,
    "شخير": 1,
    "للإستنشاق": 1,
    "يشم": 1,
    "عابس": 1,
    "ركن": 1,
    "متحاضن": 1,
    "التحاضن": 1,
    "تحاضن": 1,
    "يتحاضن": 1,
    "سنججلي": 1,
    "أركان": 1,
    "سنيدر": 1,
    "لذا": 1,
    "الإنتقاع": 1,
    "الحفاضات": 1,
    "النقع": 1,
    "الصابون": 1,
    "مصوبن": 1,
    "صابونا": 1,
    "صابوني": 1,
    "الزلق": 1,
    "الرغوية": 1,
    "الصوبنة": 1,
    "الأملس": 1,
    "الملساء": 1,
    "رغوة": 1,
    "ناشج": 1,
    "النشيج": 1,
    "ببكاء": 1,
    "صاحي": 1,
    "الألقاب": 1,
    "إشتراكي": 1,
    "المؤانسة": 1,
    "بالناس": 1,
    "الإشتراكية": 1,
    "الإشتراكي": 1,
    "الإشتراكيون": 1,
    "الإجتماعيون": 1,
    "يعاشر": 1,
    "اقتصاديا": 1,
    "الجورب": 1,
    "المقبس": 1,
    "المقابس": 1,
    "سقراط": 1,
    "سقراطي": 1,
    "العشبية": 1,
    "الصودا": 1,
    "الإبتلال": 1,
    "سودينج": 1,
    "سودي": 1,
    "فاز": 1,
    "بجائزة": 1,
    "اللواطة": 1,
    "أيا": 1,
    "صوفيا": 1,
    "اللحيم": 1,
    "السوفتبول": 1,
    "رقة": 1,
    "برمجته": 1,
    "اللين": 1,
    "تنقعا": 1,
    "التعجن": 1,
    "الترب": 1,
    "الأمسية": 1,
    "الزيارة": 1,
    "الزيارات": 1,
    "الصول": 1,
    "العزاء": 1,
    "سولاسير": 1,
    "شمسي": 1,
    "الشمسي": 1,
    "سولاريس": 1,
    "المشمسة": 1,
    "مباع": 1,
    "ملحوم": 1,
    "لحام": 1,
    "كوفح": 1,
    "الكفاح": 1,
    "العسكر": 1,
    "يهيب": 1,
    "التهييب": 1,
    "اللولبي": 1,
    "اللولبية": 1,
    "اجتذب": 1,
    "يجتذب": 1,
    "الإعتناء": 1,
    "التضامن": 1,
    "يقوي": 1,
    "قو": 1,
    "المائل": 1,
    "ناج": 1,
    "مناجاة": 1,
    "سولينجين": 1,
    "بالذات": 1,
    "إنفرادي": 1,
    "المنعزلة": 1,
    "الخلوة": 1,
    "سول": 1,
    "سولي": 1,
    "منفردا": 1,
    "لوحده": 1,
    "المنفردون": 1,
    "سليمان": 1,
    "شأن": 1,
    "ودولي": 1,
    "المذاب": 1,
    "يذيب": 1,
    "الإذابة": 1,
    "الإفاء": 1,
    "المذيبات": 1,
    "الحلال": 1,
    "سومال": 1,
    "الحاميون": 1,
    "الصومالي": 1,
    "الصوماليون": 1,
    "البعض": 1,
    "يوما": 1,
    "سوميرز": 1,
    "الشقلبة": 1,
    "الشقلبات": 1,
    "سومرست": 1,
    "سوميرفيل": 1,
    "أحيانا": 1,
    "الساقي": 1,
    "السقاة": 1,
    "سوميرفيلد": 1,
    "مشاء": 1,
    "بالمشي": 1,
    "مشاؤو": 1,
    "السونار": 1,
    "السوناتة": 1,
    "السوناتات": 1,
    "المغرد": 1,
    "الغنائي": 1,
    "غنائية": 1,
    "للغناء": 1,
    "المطرب": 1,
    "المطربون": 1,
    "ابنائي": 1,
    "سوني": 1,
    "سونوجرام": 1,
    "سونوما": 1,
    "سونورا": 1,
    "الجهارة": 1,
    "جهارة": 1,
    "الأبناء": 1,
    "السخام": 1,
    "المنقوع": 1,
    "السفسطائي": 1,
    "سفسطائي": 1,
    "المحنك": 1,
    "المحنكون": 1,
    "السفسطة": 1,
    "السفسطائيون": 1,
    "سوفوكليسي": 1,
    "سوفوكليس": 1,
    "اعظم": 1,
    "المسرحيين": 1,
    "بالسنة": 1,
    "بطالب": 1,
    "طفولية": 1,
    "السوبرانو": 1,
    "سورا": 1,
    "السورب": 1,
    "سورينسين": 1,
    "سورينسون": 1,
    "نوادي": 1,
    "الحميض": 1,
    "أسفا": 1,
    "للفرز": 1,
    "الفارز": 1,
    "الفارزات": 1,
    "الإستغاثة": 1,
    "الدانق": 1,
    "أدوار": 1,
    "يتنهد": 1,
    "أريد": 1,
    "عديمات": 1,
    "روح": 1,
    "صديق": 1,
    "يشد": 1,
    "تصدر": 1,
    "الأئر": 1,
    "الشوربات": 1,
    "المصدرية": 1,
    "العجين": 1,
    "المخمر": 1,
    "محمض": 1,
    "التحميض": 1,
    "بحموضة": 1,
    "السوروود": 1,
    "سوسا": 1,
    "القوادين": 1,
    "ساوثامبتون": 1,
    "جنوبا": 1,
    "ساوثير": 1,
    "الجنوبيون": 1,
    "الجنوبيات": 1,
    "سوثي": 1,
    "ساوثفيلد": 1,
    "الأعسر": 1,
    "العسر": 1,
    "سوفيتي": 1,
    "الخضوع": 1,
    "للسيطرة": 1,
    "السوفيتية": 1,
    "سوفت": 1,
    "مسوفت": 1,
    "يسوفت": 1,
    "السوفت": 1,
    "السوفييت": 1,
    "سوفخوز": 1,
    "الخنزيرة": 1,
    "سورز": 1,
    "مبذور": 1,
    "الخنزيرات": 1,
    "الصويا": 1,
    "فول": 1,
    "مباعد": 1,
    "بالنظرية": 1,
    "والخاصة": 1,
    "رائدة": 1,
    "رائدات": 1,
    "بالفضاء": 1,
    "المباعدة": 1,
    "الرحابة": 1,
    "المجرف": 1,
    "طلع": 1,
    "سبافورد": 1,
    "السباغيتي": 1,
    "سبان": 1,
    "إسبانيا": 1,
    "سبالدينج": 1,
    "المقوس": 1,
    "للمبنى": 1,
    "المساحات": 1,
    "المتواجدة": 1,
    "القوسين": 1,
    "تلألأ": 1,
    "يتلألأ": 1,
    "الإسباني": 1,
    "الإسبان": 1,
    "إسباني": 1,
    "إصفع": 1,
    "يصفع": 1,
    "القناطر": 1,
    "السارية": 1,
    "إحتياطي": 1,
    "الماسة": 1,
    "الماسات": 1,
    "سباركمان": 1,
    "الشرارات": 1,
    "سبارلينج": 1,
    "العصافير": 1,
    "الساريات": 1,
    "متناثر": 1,
    "تناثرا": 1,
    "المتقشف": 1,
    "متشنجة": 1,
    "حيز": 1,
    "الرشة": 1,
    "سباتردوك": 1,
    "الرشات": 1,
    "سبولدينج": 1,
    "بعظم": 1,
    "للدابة": 1,
    "أورام": 1,
    "البياضة": 1,
    "البيوض": 1,
    "سبيتش": 1,
    "سبيد": 1,
    "المتكلمون": 1,
    "سبيكيرشيب": 1,
    "الطاعن": 1,
    "بالرمح": 1,
    "متزعم": 1,
    "التزعم": 1,
    "يتزعم": 1,
    "الإختصاصات": 1,
    "الإختصاص": 1,
    "التخصصات": 1,
    "يتخصص": 1,
    "الخاصيات": 1,
    "الرقطة": 1,
    "الروائع": 1,
    "سبيكتور": 1,
    "الأطياف": 1,
    "الطيفية": 1,
    "منظار": 1,
    "الطيفي": 1,
    "التطييف": 1,
    "طيفين": 1,
    "مختلفين": 1,
    "تقارن": 1,
    "كشاف": 1,
    "بمنظار": 1,
    "منظاري": 1,
    "خمن": 1,
    "يخمن": 1,
    "المضاربون": 1,
    "مضاربي": 1,
    "الوجوم": 1,
    "قيادة": 1,
    "بسرع": 1,
    "إسأل": 1,
    "باستكشاف": 1,
    "مستكشفو": 1,
    "استكشاف": 1,
    "املائي": 1,
    "الإملائي": 1,
    "المتهجي": 1,
    "المتهجون": 1,
    "التهجئ": 1,
    "التهجئات": 1,
    "سبيلانكير": 1,
    "مكتشفو": 1,
    "إستكشاف": 1,
    "سبينسر": 1,
    "لهربرت": 1,
    "سبنسر": 1,
    "فلسفته": 1,
    "مصروف": 1,
    "المنفقون": 1,
    "المبذر": 1,
    "المسرفون": 1,
    "سبينجلر": 1,
    "ونظريته": 1,
    "بشان": 1,
    "الحيمن": 1,
    "منوي": 1,
    "البزري": 1,
    "الحيامن": 1,
    "سبيري": 1,
    "المتقيء": 1,
    "كبريتيد": 1,
    "الزنك": 1,
    "كرويا": 1,
    "الكروي": 1,
    "المصرة": 1,
    "المصرات": 1,
    "مشين": 1,
    "للمتحدث": 1,
    "بالأسبانية": 1,
    "السنبلة": 1,
    "فيرجو": 1,
    "سبيسبش": 1,
    "توابلا": 1,
    "سبيك": 1,
    "اطراف": 1,
    "حادة": 1,
    "العناكب": 1,
    "سبيدرورت": 1,
    "متجسس": 1,
    "شبيغل": 1,
    "الجاسوس": 1,
    "الجواسيس": 1,
    "سنبل": 1,
    "التشويك": 1,
    "الإنسكاب": 1,
    "يسكب": 1,
    "المسكوبة": 1,
    "والفائضة": 1,
    "المنسكبة": 1,
    "مصارف": 1,
    "انسكب": 1,
    "السبانخ": 1,
    "منغزل": 1,
    "سبيندلر": 1,
    "المغازل": 1,
    "الإنغزال": 1,
    "وزجاجي": 1,
    "اكسيد": 1,
    "الماغنيسيوم": 1,
    "والألمنيوم": 1,
    "فقري": 1,
    "الشوكية": 1,
    "للأقمشة": 1,
    "الغزالون": 1,
    "العانس": 1,
    "العانسات": 1,
    "التنفسية": 1,
    "الإحتكاكي": 1,
    "المستدبة": 1,
    "الروحاني": 1,
    "محضر": 1,
    "محضرو": 1,
    "روحيا": 1,
    "سبيرو": 1,
    "النكاية": 1,
    "مغاظ": 1,
    "العصبيون": 1,
    "المبصقة": 1,
    "المباصق": 1,
    "سبتز": 1,
    "الرشاش": 1,
    "مرذرذ": 1,
    "يرش": 1,
    "فلطح": 1,
    "مسطحة": 1,
    "وملتوية": 1,
    "التفلطح": 1,
    "يفلطح": 1,
    "الطحال": 1,
    "سبلينوورت": 1,
    "بروعة": 1,
    "طحالي": 1,
    "خدد": 1,
    "التجبير": 1,
    "الإتلاف": 1,
    "متباهى": 1,
    "همهم": 1,
    "الغنائم": 1,
    "للفساد": 1,
    "مفسدو": 1,
    "سبوكان": 1,
    "بأسلاك": 1,
    "للإطارات": 1,
    "منطوق": 1,
    "الناطقون": 1,
    "الرسميون": 1,
    "الناطقة": 1,
    "المتحدثات": 1,
    "بالأسلاك": 1,
    "الإسفنج": 1,
    "الإسفنجات": 1,
    "إسفنجي": 1,
    "الإسفنجة": 1,
    "الإسفنجية": 1,
    "آنيا": 1,
    "الملعقة": 1,
    "سبون": 1,
    "الملاعق": 1,
    "البويغة": 1,
    "بويغات": 1,
    "البويغات": 1,
    "بطهارة": 1,
    "متفاوت": 1,
    "سبراج": 1,
    "الإسبرط": 1,
    "البخاخ": 1,
    "المرشات": 1,
    "الموزعة": 1,
    "الموزعات": 1,
    "الإنتشارات": 1,
    "الغصين": 1,
    "ربيع": 1,
    "المقفز": 1,
    "المقافز": 1,
    "إصطد": 1,
    "الوثاب": 1,
    "تضع": 1,
    "حملها": 1,
    "الوثابون": 1,
    "سبرينجفيلد": 1,
    "فصول": 1,
    "سبرينجتيل": 1,
    "ذروة": 1,
    "موسم": 1,
    "مواسم": 1,
    "ري": 1,
    "الرشاشات": 1,
    "الشرذمة": 1,
    "أسنان": 1,
    "سبرول": 1,
    "الكرنبات": 1,
    "التهندم": 1,
    "احصائي": 1,
    "لعلم": 1,
    "سبموني": 1,
    "آيس": 1,
    "سبيرج": 1,
    "سبيرز": 1,
    "سبوتنيك": 1,
    "تفل": 1,
    "متفل": 1,
    "الباصق": 1,
    "التتفيل": 1,
    "يتفل": 1,
    "التربيعي": 1,
    "فراخ": 1,
    "المربع": 1,
    "سكواريسفيل": 1,
    "السكواتش": 1,
    "ممرود": 1,
    "الساحق": 1,
    "بطراوة": 1,
    "ولكنه": 1,
    "يتقرفص": 1,
    "متقرفص": 1,
    "المستحل": 1,
    "التقرفص": 1,
    "سكيووبش": 1,
    "زاعق": 1,
    "الشاكي": 1,
    "الزعيق": 1,
    "سكيووروت": 1,
    "المحاربات": 1,
    "التنس": 1,
    "اصوات": 1,
    "الصئيل": 1,
    "العصرة": 1,
    "معصور": 1,
    "العصارات": 1,
    "العصرات": 1,
    "المسموعة": 1,
    "المفحمة": 1,
    "سكوبب": 1,
    "الفرقعة": 1,
    "متمايل": 1,
    "يتمايل": 1,
    "سكويل": 1,
    "الحول": 1,
    "الأحول": 1,
    "بحول": 1,
    "تلو": 1,
    "يتلوى": 1,
    "السناجيب": 1,
    "المحقنة": 1,
    "اسفنجية": 1,
    "امتصاصا": 1,
    "الاكثر": 1,
    "اسفنجي": 1,
    "وتعني": 1,
    "جنوبجنوب": 1,
    "ستابلي": 1,
    "المتقطعة": 1,
    "للتكويم": 1,
    "المعبئ": 1,
    "ستايسي": 1,
    "الملاعب": 1,
    "ستافرد": 1,
    "ستافوردشاير": 1,
    "بالمسرح": 1,
    "المترنح": 1,
    "التمايلات": 1,
    "التمثيلات": 1,
    "الأيول": 1,
    "ستال": 1,
    "برزانة": 1,
    "يتسخ": 1,
    "استراحة": 1,
    "اعلى": 1,
    "الحليمات": 1,
    "اللحليمات": 1,
    "السفلى": 1,
    "بااخ": 1,
    "ياخي": 1,
    "ستالي": 1,
    "ستالين": 1,
    "البوخ": 1,
    "ستاليني": 1,
    "الملاحقون": 1,
    "الملاحقة": 1,
    "مخلوطا": 1,
    "بالقش": 1,
    "ستولينجز": 1,
    "السداة": 1,
    "ستامفورد": 1,
    "سداة": 1,
    "متلعثم": 1,
    "المتهته": 1,
    "الطابع": 1,
    "فرارا": 1,
    "الفارين": 1,
    "الختامون": 1,
    "ستان": 1,
    "الحازم": 1,
    "الدعامات": 1,
    "الواقف": 1,
    "المقامات": 1,
    "الماسورة": 1,
    "ستانفورد": 1,
    "ستانلي": 1,
    "بالصفيح": 1,
    "ستانز": 1,
    "ستانتن": 1,
    "ستاف": 1,
    "الكباسة": 1,
    "الكباسات": 1,
    "ستابلتون": 1,
    "الميمنة": 1,
    "ميمنات": 1,
    "النشا": 1,
    "منشى": 1,
    "نشوي": 1,
    "التنشية": 1,
    "النجومية": 1,
    "ستارجاز": 1,
    "مراقبوا": 1,
    "ستاركي": 1,
    "النجيم": 1,
    "النجيمات": 1,
    "الزرزور": 1,
    "الزرازير": 1,
    "ستارأوفايس": 1,
    "المبدئ": 1,
    "يباغت": 1,
    "المباغتة": 1,
    "المتضور": 1,
    "المتضورون": 1,
    "منصوص": 1,
    "وطن": 1,
    "لدولة": 1,
    "ستاتين": 1,
    "الوﻻيةالمحافظة": 1,
    "كرجل": 1,
    "دولة": 1,
    "عموم": 1,
    "سكونيا": 1,
    "خموليا": 1,
    "القرطاسية": 1,
    "منسب": 1,
    "الإحصائيون": 1,
    "الإحصائيات": 1,
    "خبري": 1,
    "ستاتلر": 1,
    "مقاول": 1,
    "فنادق.": 1,
    "الموتور": 1,
    "مكانة": 1,
    "حراك": 1,
    "تمثالي": 1,
    "القوام": 1,
    "شرعيا": 1,
    "ستوفر": 1,
    "وفي": 1,
    "وفاء": 1,
    "ستانتون": 1,
    "الإقامات": 1,
    "العاديان": 1,
    "بصمود": 1,
    "الصمود": 1,
    "تثبت": 1,
    "ثباتا": 1,
    "يتثبت": 1,
    "ستيلير": 1,
    "الخفية": 1,
    "البخاري": 1,
    "متبخر": 1,
    "البواخر": 1,
    "بالبخار": 1,
    "بخاري": 1,
    "الحادلة": 1,
    "الحادلات": 1,
    "الأبخرة": 1,
    "الإستارات": 1,
    "ستيرنز": 1,
    "الجياد": 1,
    "ستيل": 1,
    "فولاذي": 1,
    "الفولاذية": 1,
    "ستين": 1,
    "إنحدر": 1,
    "ستيبلبش": 1,
    "ببرج": 1,
    "دولاب": 1,
    "إستيفان": 1,
    "ستيجوسورس": 1,
    "شتاين": 1,
    "شتاينبيرج": 1,
    "شتاينير": 1,
    "ستيلا": 1,
    "الستينسل": 1,
    "مستخدما": 1,
    "الستينسيل": 1,
    "بالستينسيل": 1,
    "الستينسلات": 1,
    "ستنسل": 1,
    "الاختزال": 1,
    "المختزلون": 1,
    "إختزالي": 1,
    "مختزلة": 1,
    "زوج": 1,
    "أزواج": 1,
    "ستيفاني": 1,
    "ستيبهانوتيس": 1,
    "ستيفن": 1,
    "ستفينز": 1,
    "ستيففنسن": 1,
    "زوجات": 1,
    "السائر": 1,
    "لبدء": 1,
    "ستيرادايان": 1,
    "ستيريو": 1,
    "المجسام": 1,
    "المجسامات": 1,
    "تجسيمي": 1,
    "الاستريوسكوبية": 1,
    "ثلاثية": 1,
    "الجاهز": 1,
    "نمطي": 1,
    "معقم": 1,
    "المعقمات": 1,
    "الإستيرليني": 1,
    "ستيرنبيرج": 1,
    "صرامة": 1,
    "بـ..": 1,
    "شخيري": 1,
    "بالسماعة": 1,
    "ستيبين": 1,
    "ستيف": 1,
    "ستيفينس": 1,
    "ستيفينسون": 1,
    "ستيفي": 1,
    "ستيوارت": 1,
    "غرس": 1,
    "اللاصقات": 1,
    "أدبق": 1,
    "ستيك": 1,
    "تيت": 1,
    "الميبس": 1,
    "بتصنع": 1,
    "وصمة": 1,
    "وصمات": 1,
    "الندبات": 1,
    "تشوهي": 1,
    "بوصمة": 1,
    "عار": 1,
    "الوصم": 1,
    "موشوم": 1,
    "الوشم": 1,
    "خناجر": 1,
    "زال": 1,
    "بالولادة": 1,
    "الأهدأ": 1,
    "الأسكن": 1,
    "ستيلووتر": 1,
    "ستيلويل": 1,
    "المطوال": 1,
    "المطوالات": 1,
    "ستيمسون": 1,
    "منبه": 1,
    "اللدغة": 1,
    "اللسع": 1,
    "لاسع": 1,
    "الكريهون": 1,
    "النتن": 1,
    "السلاحف": 1,
    "الممقوت": 1,
    "ينتن": 1,
    "ستينتر": 1,
    "النقطي": 1,
    "النقطية": 1,
    "إشترط": 1,
    "مشترط": 1,
    "يشترط": 1,
    "الإشتراط": 1,
    "الإشتراطات": 1,
    "ستيرلينج": 1,
    "التخييط": 1,
    "مراقبة": 1,
    "إستمرار": 1,
    "بالسهم": 1,
    "إيصال": 1,
    "سمسار": 1,
    "البورصة": 1,
    "سماسرة": 1,
    "ستوكير": 1,
    "ستوكيرس": 1,
    "ستوكفيش": 1,
    "إستوكهولم": 1,
    "جثة": 1,
    "جوارب": 1,
    "الاحتياطي": 1,
    "مخزون": 1,
    "المكدس": 1,
    "ستوكتون": 1,
    "بحساب": 1,
    "المتفاعلة": 1,
    "الرواقية": 1,
    "المتحملون": 1,
    "أذك": 1,
    "مذكى": 1,
    "الوقاد": 1,
    "الوقادون": 1,
    "يذكي": 1,
    "الإذكاء": 1,
    "عباءة": 1,
    "والبطن": 1,
    "مداس": 1,
    "الدوس": 1,
    "السيدوم": 1,
    "الحجار": 1,
    "الحجارون": 1,
    "محشش": 1,
    "حجارة": 1,
    "العميل": 1,
    "العملاء": 1,
    "بالمرور": 1,
    "الصمامات": 1,
    "إشارات": 1,
    "أيقافه": 1,
    "التوقيتية": 1,
    "الخزن": 1,
    "والمهاجم": 1,
    "واجهات": 1,
    "اللقلق": 1,
    "اللقالق": 1,
    "بسبب": 1,
    "قصص": 1,
    "القاء": 1,
    "راوي": 1,
    "رواة": 1,
    "ستاوت": 1,
    "تشجع": 1,
    "ستو": 1,
    "فحج": 1,
    "مفحج": 1,
    "المتواجد": 1,
    "مكانين.": 1,
    "يفحج": 1,
    "التفحيج": 1,
    "إقصف": 1,
    "ته": 1,
    "متيه": 1,
    "يتيه": 1,
    "التيه": 1,
    "ستراهل": 1,
    "مستقيم.": 1,
    "كمنعم": 1,
    "الأجعد": 1,
    "استقامة": 1,
    "الاستقامة": 1,
    "المستقيمون": 1,
    "الصرحاء": 1,
    "الحشر": 1,
    "الخانقة": 1,
    "الخانقون": 1,
    "الأقوياء": 1,
    "ستراسبورغ": 1,
    "إستراتيجي": 1,
    "الإستراتيجيات": 1,
    "الإستراتيجي": 1,
    "الإستراتيجيون": 1,
    "الإستراتيجية": 1,
    "ستراتفورد": 1,
    "التطبق": 1,
    "المطابقات": 1,
    "بطبقات": 1,
    "ستراتون": 1,
    "شتراوس": 1,
    "سترافينسكاي": 1,
    "سترو": 1,
    "الشليك": 1,
    "ستروفلور": 1,
    "الشوارد": 1,
    "مقلم": 1,
    "ينساب": 1,
    "الإنسياب": 1,
    "للجدول": 1,
    "سترينج": 1,
    "سترينجذينير": 1,
    "الستربتوميسين": 1,
    "النقالات": 1,
    "إنثر": 1,
    "منثور": 1,
    "ينثر": 1,
    "شرخ": 1,
    "شروخ": 1,
    "قنوات": 1,
    "يحزز": 1,
    "التحزيز": 1,
    "ستريكلاند": 1,
    "ستردير": 1,
    "كاسر": 1,
    "الإضراب": 1,
    "كاسرو": 1,
    "تشريط": 1,
    "الإخباري": 1,
    "التوتير": 1,
    "زيه": 1,
    "الصبيان": 1,
    "المتعرية": 1,
    "المتعريات": 1,
    "المتجردة": 1,
    "ثيابها": 1,
    "كافح": 1,
    "مكافح": 1,
    "المكافح": 1,
    "يكافح": 1,
    "الستروب": 1,
    "ستروب": 1,
    "الوماضة": 1,
    "ممسد": 1,
    "التمسيد": 1,
    "متمشى": 1,
    "التجوالات": 1,
    "ستروم": 1,
    "سترومبيرج": 1,
    "السترونتيوم": 1,
    "بالأناشيد": 1,
    "يحد": 1,
    "البنيوية": 1,
    "المتعثر": 1,
    "المتعثرون": 1,
    "إعزف": 1,
    "يعزف": 1,
    "موتر": 1,
    "متهادى": 1,
    "المدعم": 1,
    "التهادي": 1,
    "الإستركنين": 1,
    "العقب": 1,
    "ستبليفيلد": 1,
    "الأعقاب": 1,
    "المشغولات": 1,
    "الجبسية": 1,
    "الترصيع": 1,
    "ستوديبيكر": 1,
    "الدارس": 1,
    "الإستوديو": 1,
    "الإستوديوهات": 1,
    "محشو": 1,
    "ستوفير": 1,
    "رسمية": 1,
    "الكتم": 1,
    "التسفيه": 1,
    "مسفه": 1,
    "يسفه": 1,
    "سفه": 1,
    "متعثر": 1,
    "القرمة": 1,
    "تفرض": 1,
    "متعكز": 1,
    "الصعب": 1,
    "الإكتظاظ": 1,
    "وممتليء": 1,
    "التعكز": 1,
    "لسع": 1,
    "منتون": 1,
    "الخطرة": 1,
    "الفيلم": 1,
    "أغب": 1,
    "الإغباء": 1,
    "المتمثلة": 1,
    "والحجم": 1,
    "الهائل": 1,
    "غباء": 1,
    "الإنذهال": 1,
    "ستربريدج": 1,
    "ستيرجون": 1,
    "سترم": 1,
    "التعتعة": 1,
    "متأتأ": 1,
    "المتهتهة": 1,
    "المتلكئ": 1,
    "بالكلام": 1,
    "المتهتهون": 1,
    "المتلكؤون": 1,
    "التأتأة": 1,
    "متلجلجا": 1,
    "كلامه": 1,
    "شتوتغارد": 1,
    "ستيفيسانت": 1,
    "الاستعماري": 1,
    "لهولندا": 1,
    "معاينة": 1,
    "أسلوبيا": 1,
    "زاهد": 1,
    "أعيق": 1,
    "تقلصات": 1,
    "الستايرين": 1,
    "الستايروفوم": 1,
    "ستيكس": 1,
    "للمسائلة": 1,
    "الملازم": 1,
    "ثانوية": 1,
    "فرعي": 1,
    "الفرعية": 1,
    "ميثاق": 1,
    "الباطن": 1,
    "المقاولة": 1,
    "التعاقد": 1,
    "ثقافة": 1,
    "المخضع": 1,
    "الإخضاع": 1,
    "أقسام": 1,
    "مجلدات": 1,
    "ولكن": 1,
    "إضافية": 1,
    "الإلحاق": 1,
    "شرطية": 1,
    "المتصعد": 1,
    "التسامي": 1,
    "شعوري": 1,
    "غواصة": 1,
    "الغواصات": 1,
    "للغمر": 1,
    "مرؤسي": 1,
    "الموصول": 1,
    "إرش": 1,
    "فقرة": 1,
    "مؤامرة": 1,
    "الحبكات": 1,
    "إحضار": 1,
    "تكميلية": 1,
    "برهان": 1,
    "سؤال": 1,
    "روتين": 1,
    "عينة": 1,
    "عينات": 1,
    "مخطوطة": 1,
    "جملة": 1,
    "العواقب": 1,
    "التذللات": 1,
    "التابعة": 1,
    "الإعانات": 1,
    "الداعمون": 1,
    "النويعي": 1,
    "اسمي": 1,
    "الإسمية": 1,
    "تعقيم": 1,
    "للإحلال": 1,
    "مستبدل": 1,
    "إبدالي": 1,
    "إستبدالي": 1,
    "مشيد": 1,
    "للإدراج": 1,
    "الباطني": 1,
    "الباطنيون": 1,
    "يقابل": 1,
    "إطرح": 1,
    "مطروح": 1,
    "الطوارح": 1,
    "المطروحات": 1,
    "لوحدة": 1,
    "الضاحية": 1,
    "تعمير": 1,
    "ممدن": 1,
    "التمدن": 1,
    "المقوض": 1,
    "النفق": 1,
    "النجاحات": 1,
    "بنجاح": 1,
    "التعاقبات": 1,
    "التتابع": 1,
    "مغاث": 1,
    "بالزبدة": 1,
    "النضارة": 1,
    "نضارة": 1,
    "الماص": 1,
    "ريان": 1,
    "وما": 1,
    "أشبه": 1,
    "المصاص": 1,
    "المصاصون": 1,
    "المص": 1,
    "مرضع": 1,
    "يرضع": 1,
    "المصات": 1,
    "السكروز": 1,
    "سود": 1,
    "السودان": 1,
    "السوداني": 1,
    "سوداني": 1,
    "المدبوغ": 1,
    "سوديد": 1,
    "المدبوغة": 1,
    "رافع": 1,
    "الشحوم": 1,
    "السويس": 1,
    "عان": 1,
    "بضنك": 1,
    "المعانون": 1,
    "يعاني": 1,
    "إكف": 1,
    "مكفي": 1,
    "الكافي": 1,
    "يكفي": 1,
    "اللواحق": 1,
    "إختناقي": 1,
    "سوفولك": 1,
    "مساعدو": 1,
    "التصويتات": 1,
    "بحق": 1,
    "اقتراع": 1,
    "المطالبات": 1,
    "إمتلأ": 1,
    "يمتلئ": 1,
    "سكري": 1,
    "البونبون": 1,
    "السكريات": 1,
    "بالإيحاء": 1,
    "إيحائي": 1,
    "إيحائيا": 1,
    "إنتحارا": 1,
    "الإنتحار": 1,
    "إنتحر": 1,
    "سوكارنو": 1,
    "سلفاوي": 1,
    "السلفانيلاميد": 1,
    "للدواجن": 1,
    "كبرت": 1,
    "مكبرت": 1,
    "يكبرت": 1,
    "الكبرتة": 1,
    "الكبريتيد": 1,
    "الكبريتوز": 1,
    "السلفا": 1,
    "بالكبريت": 1,
    "كبريتي": 1,
    "ورطب": 1,
    "الجهنمية": 1,
    "عبوس": 1,
    "سولكيس": 1,
    "سوليفان": 1,
    "الكبريتات": 1,
    "سولفونال": 1,
    "السولفونامايد": 1,
    "سلطان": 1,
    "السلطنة": 1,
    "السلطنات": 1,
    "السلاطين": 1,
    "سولسبيرغ": 1,
    "السماق": 1,
    "سومطرة": 1,
    "سومر.": 1,
    "السامرية": 1,
    "سومر": 1,
    "السومري": 1,
    "جمعه": 1,
    "وجيز": 1,
    "سميرديل": 1,
    "صيف": 1,
    "صيفي": 1,
    "مؤتمرات": 1,
    "المستدعي": 1,
    "سمنر": 1,
    "حيوية.": 1,
    "السومو": 1,
    "القرارة": 1,
    "خيول": 1,
    "للنفقات": 1,
    "سمتر": 1,
    "مشمس": 1,
    "شمس": 1,
    "تشمس": 1,
    "متشمس": 1,
    "المتشمس": 1,
    "المتشمسون": 1,
    "يتشمس": 1,
    "التشمس": 1,
    "حرقة": 1,
    "حروق": 1,
    "مسفوع": 1,
    "سنديو": 1,
    "المغيب": 1,
    "المتفرقات": 1,
    "عباد": 1,
    "بنور": 1,
    "أشمس": 1,
    "ساني": 1,
    "فيل": 1,
    "شروق": 1,
    "الشروق": 1,
    "الشموس": 1,
    "حاجبة": 1,
    "الغروب": 1,
    "إنتصارية": 1,
    "وافر": 1,
    "قاعد": 1,
    "يقاعد": 1,
    "المقاعدة": 1,
    "سوبيربنيس": 1,
    "ضاغط": 1,
    "يشحن": 1,
    "وفوق": 1,
    "الحواسيب": 1,
    "موصلات": 1,
    "انشطاري": 1,
    "طاقة": 1,
    "مقدرة": 1,
    "إحرازها": 1,
    "السوبرمان": 1,
    "السوبرمانات": 1,
    "طاف": 1,
    "ماوراء": 1,
    "الأعظم": 1,
    "انفجارات": 1,
    "نجمية": 1,
    "الفائضون": 1,
    "وضعه": 1,
    "ودمجه": 1,
    "يطابق": 1,
    "ومن": 1,
    "ثمة": 1,
    "تطابق": 1,
    "الشيئين": 1,
    "العظمى": 1,
    "علوي": 1,
    "الفوقية": 1,
    "المستبدل": 1,
    "مجاميع": 1,
    "بالخرافات": 1,
    "بالبنية": 1,
    "تصاحب": 1,
    "الصلاحيات": 1,
    "إتبع": 1,
    "متبوع": 1,
    "منبطح": 1,
    "الإستلقاء": 1,
    "إلى..": 1,
    "المتوسلون": 1,
    "التضرعات": 1,
    "إعالته": 1,
    "بتحمل": 1,
    "افتراض": 1,
    "التحاميل": 1,
    "التحميلة": 1,
    "القامعات": 1,
    "للقمع": 1,
    "الإخماد": 1,
    "قمعية": 1,
    "تقيح": 1,
    "يتقيح": 1,
    "التقيحات": 1,
    "إنقطع": 1,
    "ينقطع": 1,
    "الأصم": 1,
    "جرنيس": 1,
    "الأمواج": 1,
    "تقلل": 1,
    "للسائل": 1,
    "المذابة": 1,
    "مواد": 1,
    "الفرط": 1,
    "الجراح": 1,
    "الجراحون": 1,
    "الجراحات": 1,
    "جراحي": 1,
    "جراحيا": 1,
    "سورينام": 1,
    "سيرجيكشن": 1,
    "فضاضة": 1,
    "مظنون": 1,
    "تجاوزي": 1,
    "الكهنوتي": 1,
    "المفاجئات": 1,
    "باندهاش": 1,
    "سريالي": 1,
    "السريالية": 1,
    "السرياليون": 1,
    "إستبدال": 1,
    "المحيطة": 1,
    "المراقبات": 1,
    "المساح": 1,
    "المساحون": 1,
    "الإستطلاعات": 1,
    "المكافحون": 1,
    "الناجي": 1,
    "الباق": 1,
    "الباقون": 1,
    "إحزر": 1,
    "سوزان": 1,
    "سوسإ": 1,
    "السوتشي": 1,
    "سوزي": 1,
    "المشتبه": 1,
    "بهم": 1,
    "التوقيف": 1,
    "وواقي": 1,
    "الرياضات": 1,
    "العنيفة": 1,
    "إرتاب": 1,
    "يرتاب": 1,
    "للشك": 1,
    "مريبة": 1,
    "سسكويهانا": 1,
    "السوسيكس": 1,
    "الرزاق": 1,
    "ساذرلاند": 1,
    "ساتن": 1,
    "الخياطات": 1,
    "سواني": 1,
    "المهيمنة": 1,
    "سوزوكي": 1,
    "سوابيا": 1,
    "قمط": 1,
    "مقمط": 1,
    "يقمط": 1,
    "التقميط": 1,
    "المسروقة": 1,
    "مختالا": 1,
    "وتشكيلها": 1,
    "سواهيلي": 1,
    "العاشق": 1,
    "القرويون": 1,
    "العاشقون": 1,
    "البلع": 1,
    "سبح": 1,
    "السوامي": 1,
    "الوحولة": 1,
    "البجعة": 1,
    "إختيالا": 1,
    "سوانسون": 1,
    "التبديلالخلفية": 1,
    "المرجة": 1,
    "معجوج": 1,
    "المتجمهر": 1,
    "العج": 1,
    "سوارثمور": 1,
    "سوارثاوت": 1,
    "الأسمر": 1,
    "شوارتز": 1,
    "ترشرش": 1,
    "المتعنتر": 1,
    "المتعنترون": 1,
    "مترشرش": 1,
    "يستعرض": 1,
    "ذراعيه": 1,
    "يترشرش": 1,
    "الترشرش": 1,
    "سواثر": 1,
    "منشة": 1,
    "مذبذب": 1,
    "سواير": 1,
    "الذبذبة": 1,
    "يذبذب": 1,
    "تعرقا": 1,
    "بعرق": 1,
    "سويتين": 1,
    "السويدي": 1,
    "السويد": 1,
    "السويديون": 1,
    "سويدي": 1,
    "سويني": 1,
    "الكناس": 1,
    "الكناسون": 1,
    "الكناسة": 1,
    "رهان": 1,
    "مراهنات": 1,
    "بنكرياس": 1,
    "بنكرياسات": 1,
    "أحلى": 1,
    "المحبوبون": 1,
    "الحلاوة": 1,
    "سويلت": 1,
    "إحتر": 1,
    "محتر": 1,
    "الإحترار": 1,
    "يحتر": 1,
    "سوينسون": 1,
    "كنس": 1,
    "السمامة": 1,
    "الفضلة": 1,
    "السباحة": 1,
    "يسبح": 1,
    "كسوة": 1,
    "كسوات": 1,
    "سوينبرن": 1,
    "عاهر": 1,
    "العهرة": 1,
    "إيقاعا": 1,
    "التفافا": 1,
    "التواءا": 1,
    "الدوامات": 1,
    "دوامي": 1,
    "سويشر": 1,
    "تصفيرا": 1,
    "سويشي": 1,
    "السويسري": 1,
    "المتعرج": 1,
    "التعرجات": 1,
    "سويسرا": 1,
    "التف": 1,
    "بالإغماء": 1,
    "بفقدان": 1,
    "للوعي": 1,
    "منقض": 1,
    "السرتيل": 1,
    "سابح": 1,
    "المترف": 1,
    "المترفون": 1,
    "سيبيل": 1,
    "الجميز": 1,
    "بالتملق": 1,
    "المتملقون": 1,
    "سدني": 1,
    "سيكيس": 1,
    "مقطعية": 1,
    "بمقاطع": 1,
    "لفظ": 1,
    "الرشيقة": 1,
    "الرشيقات": 1,
    "سيلفانيا": 1,
    "سيلفيستر": 1,
    "سيلفيا": 1,
    "سيلفي": 1,
    "المتكافل": 1,
    "التكافل": 1,
    "تعايشي": 1,
    "تكافلية": 1,
    "بعلامة": 1,
    "الرمزيون": 1,
    "سيمينجتون": 1,
    "متعاطف": 1,
    "بالتعاطف": 1,
    "المتعاطف": 1,
    "المتعاطفون": 1,
    "يتعاطف": 1,
    "متعاطفة": 1,
    "سيمفوني": 1,
    "تناغميا": 1,
    "السمفونيات": 1,
    "السمفونية": 1,
    "الأعراض": 1,
    "سين": 1,
    "المعابد": 1,
    "التزامني": 1,
    "زامن": 1,
    "يزامن": 1,
    "المزامنة": 1,
    "السنكروترون": 1,
    "أدغم": 1,
    "يدغم": 1,
    "الغشيان": 1,
    "نقابة": 1,
    "المتلازمة": 1,
    "المتلازمات": 1,
    "سينج": 1,
    "أيرلندي": 1,
    "بمجلس": 1,
    "المجامع": 1,
    "الكنسية": 1,
    "المرادف": 1,
    "سينونيميكال": 1,
    "مرادف": 1,
    "المرادفات": 1,
    "الترادف": 1,
    "النحو": 1,
    "موالفة": 1,
    "موالفةباصات": 1,
    "موالفةتأجيلات": 1,
    "موالفةمؤثرات": 1,
    "موالفةظروف": 1,
    "موالفةمرشحات": 1,
    "موالفةداخلي": 1,
    "موالفةأمثلة": 1,
    "موالفةتجارب": 1,
    "موالفةهيئات": 1,
    "المصطنعة": 1,
    "سينثبوب": 1,
    "ينغم": 1,
    "بالزهري": 1,
    "سيراكوس": 1,
    "سوريا": 1,
    "السوري": 1,
    "السوريون": 1,
    "الليلاك": 1,
    "التنظيمات": 1,
    "إنقباض": 1,
    "الانقباض": 1,
    "الاقتران": 1,
    "سزيلارد": 1,
    "جليل": 1,
    "إمرأه": 1,
    "أثداء": 1,
    "ضخ": 1,
    "ليرش": 1,
    "الأسطح": 1,
    "الجدران": 1,
    "يتكسر": 1,
    "العشرون": 1,
    "شيرت": 1,
    "التنتالوم": 1,
    "الطبرد": 1,
    "العوانس": 1,
    "المنضدة": 1,
    "شرشف": 1,
    "المناضد": 1,
    "ملعقة": 1,
    "ملاعق": 1,
    "الحرام": 1,
    "دفوف": 1,
    "مجدول": 1,
    "يجدول": 1,
    "منظموا": 1,
    "تاكينا": 1,
    "فحض": 1,
    "الرسومي": 1,
    "آﻻت": 1,
    "للقلب": 1,
    "تاسيتس": 1,
    "الابتذال": 1,
    "تاكو": 1,
    "تاكوما": 1,
    "التاكو": 1,
    "لبق": 1,
    "بلباقة": 1,
    "اللباقة": 1,
    "تكتيكي": 1,
    "تعبويا": 1,
    "التكتيك": 1,
    "حاسة": 1,
    "لمسيا": 1,
    "غلام": 1,
    "الشرغوف": 1,
    "الشراغف": 1,
    "التفتا": 1,
    "الويلزيون": 1,
    "الويلزي": 1,
    "تافت": 1,
    "تاهيتي": 1,
    "تاهو": 1,
    "التتبع": 1,
    "بدنو": 1,
    "التعقب": 1,
    "التعقبات": 1,
    "الذيول": 1,
    "تايبيه": 1,
    "تايوان": 1,
    "التايواني": 1,
    "الطاجيكي": 1,
    "طاجيكستان": 1,
    "الوارد": 1,
    "مأخوذ": 1,
    "الشرائ": 1,
    "الآخذ": 1,
    "الآخذون": 1,
    "ناشروا": 1,
    "تايلر": 1,
    "تاليون": 1,
    "التعويذات": 1,
    "تالكين": 1,
    "تالاديجا": 1,
    "تالاهاسي": 1,
    "تالاهاتشي": 1,
    "تالتشيف": 1,
    "الأطول": 1,
    "تاليراند": 1,
    "التلمود": 1,
    "تلمودي": 1,
    "مخالب": 1,
    "صوفية": 1,
    "للترويض": 1,
    "تامال": 1,
    "الطمراق": 1,
    "تمور": 1,
    "الدف": 1,
    "الدفوف": 1,
    "مروض": 1,
    "التروض": 1,
    "مروضون": 1,
    "ألفة": 1,
    "التاميل": 1,
    "المتداولة": 1,
    "سريلانكا": 1,
    "تاماني": 1,
    "إحش": 1,
    "تامبا": 1,
    "يحشو": 1,
    "ظا": 1,
    "التناجر": 1,
    "تاناكا": 1,
    "تاناناريف": 1,
    "المزدوجة": 1,
    "رنين": 1,
    "تانجانيكا": 1,
    "تماسي": 1,
    "اليوسفي": 1,
    "الملموسية": 1,
    "طنجة": 1,
    "التانجو": 1,
    "تان": 1,
    "الدبابة": 1,
    "المشربة": 1,
    "المشربات": 1,
    "الدبابات": 1,
    "تانينبوم": 1,
    "المدابغ": 1,
    "المدبغة": 1,
    "دباغي": 1,
    "التانين": 1,
    "الدباغة": 1,
    "السمرات": 1,
    "تعذيب": 1,
    "بعذاب": 1,
    "التانتالوم": 1,
    "نوبات": 1,
    "تانيا": 1,
    "تانزانيا": 1,
    "الفضيلة": 1,
    "الكونفوشية": 1,
    "الطاوية": 1,
    "طاوي": 1,
    "تاوس": 1,
    "تابا": 1,
    "النقري": 1,
    "الإستدقاق": 1,
    "بالرسوم": 1,
    "النجود": 1,
    "التابير": 1,
    "بساط": 1,
    "مسترق": 1,
    "مسترقوا": 1,
    "عتلة": 1,
    "عتلات": 1,
    "القطران": 1,
    "تارا": 1,
    "الرتيلاء": 1,
    "تاربل": 1,
    "بطيئون": 1,
    "الأبطأ": 1,
    "مستهدف": 1,
    "الإستهداف": 1,
    "التعريفة": 1,
    "لتعريفة": 1,
    "الجمركية": 1,
    "للتلوث": 1,
    "التارو": 1,
    "المقير": 1,
    "المشمعة": 1,
    "الطربون": 1,
    "مقير": 1,
    "يتلكأ": 1,
    "التلكأ": 1,
    "تاريتاون": 1,
    "الترتان": 1,
    "طرطاري": 1,
    "طرطيري": 1,
    "المرائي": 1,
    "طرزان": 1,
    "المتعسف": 1,
    "تاسمانيا": 1,
    "الأسيمبلي": 1,
    "البرمجية": 1,
    "الشرابة": 1,
    "طعمه": 1,
    "المتذوقون": 1,
    "ألذ": 1,
    "يخرم": 1,
    "التتري": 1,
    "التتر": 1,
    "بطاطس": 1,
    "البلاء": 1,
    "تخريم": 1,
    "الواشم": 1,
    "الأوشام": 1,
    "تو": 1,
    "معنف": 1,
    "التعنيف": 1,
    "تعنيفي": 1,
    "بانشداد": 1,
    "الإنشداد": 1,
    "حشو": 1,
    "إدبغ": 1,
    "تاوني": 1,
    "سمارا": 1,
    "المصفر": 1,
    "المصفرة": 1,
    "أصحر": 1,
    "يدبغ": 1,
    "المستحقة": 1,
    "للدولة": 1,
    "معفي": 1,
    "الضريبي": 1,
    "الضريبية": 1,
    "تحنيطي": 1,
    "بسيارة": 1,
    "بسيارات": 1,
    "التاكسي": 1,
    "بإرهاق": 1,
    "المدرجة": 1,
    "دافعو": 1,
    "تايلورز": 1,
    "تشيكوفسكاي": 1,
    "الارسال": 1,
    "قدح": 1,
    "أقداح": 1,
    "الساج": 1,
    "غلاية": 1,
    "أخشاب": 1,
    "فريقا": 1,
    "أباريق": 1,
    "الدمعة": 1,
    "تيرابل": 1,
    "الدموع": 1,
    "باكي": 1,
    "للدموع": 1,
    "دامع": 1,
    "التدميع": 1,
    "المستدر": 1,
    "المستدرون": 1,
    "دموع": 1,
    "بالدمع": 1,
    "الشايات": 1,
    "إنفش": 1,
    "تيسينجس": 1,
    "حلمة": 1,
    "التكنيتيوم": 1,
    "التقني": 1,
    "التقنيون": 1,
    "تكنيكولور": 1,
    "التطبيقي": 1,
    "الإسرائيلية": 1,
    "التيكنو": 1,
    "الفنيين": 1,
    "تيد": 1,
    "دمية": 1,
    "مضجر": 1,
    "الإضجار": 1,
    "المحملة": 1,
    "مراهقة": 1,
    "المحملات": 1,
    "تأرجح": 1,
    "سنن": 1,
    "عضاضة": 1,
    "للأسنان": 1,
    "ممتنعون": 1,
    "الممتنعون": 1,
    "تفلون": 1,
    "بالتفلون": 1,
    "تجيوسيغالبا": 1,
    "طهران": 1,
    "التكتيت": 1,
    "تكترونكس": 1,
    "الإذاعة": 1,
    "الإذاعات": 1,
    "فاكس": 1,
    "تلفزيوني": 1,
    "دروس": 1,
    "تيليدين": 1,
    "برقي": 1,
    "التلغراف": 1,
    "الذهني": 1,
    "تلمان": 1,
    "ورصد": 1,
    "غائي": 1,
    "الغائية": 1,
    "عظمية": 1,
    "تخاطري": 1,
    "التخاطر": 1,
    "مخابر": 1,
    "هاتفي": 1,
    "تليفوت": 1,
    "تليفوتوغرافي": 1,
    "البعدي": 1,
    "المبرقة": 1,
    "المبرقات": 1,
    "الملقن": 1,
    "تلسكوبي": 1,
    "تلفزيون": 1,
    "تلسكربت": 1,
    "للإشارات": 1,
    "مشاهدوا": 1,
    "تلفز": 1,
    "يتلفز": 1,
    "التلفزة": 1,
    "التلفزيونات": 1,
    "مذيع": 1,
    "بالتلفزيون": 1,
    "مذيعون": 1,
    "التلكس": 1,
    "إخباره": 1,
    "التلوريوم": 1,
    "التلنت": 1,
    "المعلقة": 1,
    "بمزاجية": 1,
    "تيمبيستووسنيس": 1,
    "تمبلمان": 1,
    "تيمبيلتن": 1,
    "المؤقتات": 1,
    "ماطل": 1,
    "مماطل": 1,
    "يماطل": 1,
    "سرعات": 1,
    "للإغراء": 1,
    "المغوون": 1,
    "الفاتنة": 1,
    "الفاتنات": 1,
    "المستأجرين": 1,
    "التليين": 1,
    "المتن": 1,
    "المحلاق": 1,
    "تينكس": 1,
    "تينيسي": 1,
    "تيني": 1,
    "تينيسون": 1,
    "يلسن": 1,
    "بتوتر": 1,
    "يتوتر": 1,
    "توتري": 1,
    "توتير": 1,
    "الموتر": 1,
    "التوترات": 1,
    "الشادة": 1,
    "اللامسة": 1,
    "مجسات": 1,
    "الكلابة": 1,
    "عاشرا": 1,
    "الأعشار": 1,
    "التخييم": 1,
    "الخيم": 1,
    "مدة": 1,
    "الحيازة": 1,
    "تيكيلا": 1,
    "بالتشوهات": 1,
    "الجنينية": 1,
    "عجائب": 1,
    "التيربيوم": 1,
    "الثالثة": 1,
    "تيريزا": 1,
    "ترمن": 1,
    "للإنهاء": 1,
    "الانهاء": 1,
    "الطرفية": 1,
    "طرفي": 1,
    "إنتهائي": 1,
    "المنهون": 1,
    "إصطلاحي": 1,
    "بمصطلح": 1,
    "المصطلحات": 1,
    "خطاف": 1,
    "ترناري": 1,
    "تيرا": 1,
    "الشرفة": 1,
    "متجاور": 1,
    "الشرفات": 1,
    "بشرفة": 1,
    "التضاريس": 1,
    "طرمايسين": 1,
    "السلحفاة": 1,
    "أرضيا": 1,
    "أرضيون": 1,
    "الوبرات": 1,
    "أفزع": 1,
    "إقليميا": 1,
    "الإرهابي": 1,
    "إرهابي": 1,
    "الإرهابيون": 1,
    "مرهبون": 1,
    "الوبرة": 1,
    "إقتضابا": 1,
    "ثلثي": 1,
    "تيس": 1,
    "بالفسيفساء": 1,
    "فسيفسائي": 1,
    "تيسي": 1,
    "للاختبار": 1,
    "الموصي": 1,
    "الموصون": 1,
    "الموصية": 1,
    "الفاحصات": 1,
    "الخصيات": 1,
    "خصيوي": 1,
    "التزكية": 1,
    "التزكيات": 1,
    "التيستوستيرون": 1,
    "درع": 1,
    "الكزاز": 1,
    "تيترسيكلين": 1,
    "الهالوجين": 1,
    "ذوي": 1,
    "توتوني": 1,
    "تكس": 1,
    "تيكساكو": 1,
    "تكساسي": 1,
    "تكساس": 1,
    "توسعة": 1,
    "لارتفاع": 1,
    "المنسوج": 1,
    "إقتباس نص": 1,
    "نصي": 1,
    "نصيا": 1,
    "ث": 1,
    "التايلانديون": 1,
    "تايلند": 1,
    "مهادي": 1,
    "ثاليا": 1,
    "الثاليوم": 1,
    "المشرية": 1,
    "التايمز": 1,
    "إشكر": 1,
    "شاكر": 1,
    "متشكر": 1,
    "شكرا": 1,
    "التشكر": 1,
    "الشكر": 1,
    "للجميل": 1,
    "نكران": 1,
    "تسقيف": 1,
    "ثاير": 1,
    "ثيا": 1,
    "إلف": 1,
    "إلفو": 1,
    "إلفة": 1,
    "الالمسرح": 1,
    "الثيبات": 1,
    "أنت": 1,
    "لهم": 1,
    "إيماني": 1,
    "ثيلما": 1,
    "موضوعي": 1,
    "أنفسهم": 1,
    "الحين": 1,
    "ثيوقراطي": 1,
    "ثيودور": 1,
    "ثيودوسي": 1,
    "ثيودوسيوس": 1,
    "ﻻهوتي": 1,
    "اللاهوتية": 1,
    "النظريات": 1,
    "النظري": 1,
    "نظريات": 1,
    "النظريون": 1,
    "التنظير": 1,
    "تصوفي": 1,
    "التصوف": 1,
    "المتصوف": 1,
    "التداوي": 1,
    "هناك": 1,
    "بذلك": 1,
    "التالي": 1,
    "تيريسا": 1,
    "عقب": 1,
    "الدافئ": 1,
    "حراريا": 1,
    "الثرميوني": 1,
    "الثرميونيات": 1,
    "الثرمستور": 1,
    "الثرمستورات": 1,
    "المزدوجات": 1,
    "بالحرارة": 1,
    "والضغط": 1,
    "لوادن": 1,
    "صلد": 1,
    "بالثرموستات": 1,
    "ثرموستاتي": 1,
    "ثرموستاتيات": 1,
    "منظمات": 1,
    "قواميس": 1,
    "المرادفة": 1,
    "أطروحات": 1,
    "ثيزيوس": 1,
    "أثينا.": 1,
    "الأطروحة": 1,
    "مسرحيون": 1,
    "ثيتا": 1,
    "ثيتيس": 1,
    "ال50": 1,
    "الماء.": 1,
    "الثيامين": 1,
    "سميك": 1,
    "إثخن": 1,
    "ثخن": 1,
    "مثخن": 1,
    "مثخنات": 1,
    "التثخن": 1,
    "يثخن": 1,
    "أثخن": 1,
    "مؤجم": 1,
    "السماكة": 1,
    "السماكات": 1,
    "ثينزفيل": 1,
    "اللصوصية": 1,
    "مفخذ": 1,
    "الأفخاذ": 1,
    "الكشتبان": 1,
    "الكشتبانات": 1,
    "ثيمبو": 1,
    "ذين": 1,
    "مجهولوا": 1,
    "ذينيرس": 1,
    "الثيونيك": 1,
    "ثوراسل": 1,
    "الثلث": 1,
    "ثالثا": 1,
    "الأثلاث": 1,
    "عطش": 1,
    "متعطش": 1,
    "عطشا": 1,
    "التعطش": 1,
    "الثلاثينات": 1,
    "ثلاثون": 1,
    "زغب": 1,
    "ذيسلي": 1,
    "توماني": 1,
    "توبمسن": 1,
    "تومسن": 1,
    "ارتدى": 1,
    "سيور": 1,
    "جلدية": 1,
    "ثورو": 1,
    "الثوريات": 1,
    "الثوريوم": 1,
    "ثورنبيرغ": 1,
    "بمشاكل": 1,
    "الإشاكة": 1,
    "ثورنتن": 1,
    "الأصيلون": 1,
    "ثورب": 1,
    "ثورستاين": 1,
    "أولئك": 1,
    "الآلاف": 1,
    "بتهديد": 1,
    "الثلاثات": 1,
    "الثلاثية": 1,
    "ثريونين": 1,
    "إدرس": 1,
    "ثلاث": 1,
    "إقتصادا": 1,
    "الاقتصاد": 1,
    "التربسة": 1,
    "حنجرة": 1,
    "جلطات": 1,
    "التخثرات": 1,
    "تخثري": 1,
    "جلطة": 1,
    "ثرونبري": 1,
    "اعترش": 1,
    "العروش": 1,
    "متحشد": 1,
    "الإعتراش": 1,
    "السمنات": 1,
    "كافة": 1,
    "أنحاء": 1,
    "إرم": 1,
    "الدج": 1,
    "التهجمات": 1,
    "الخميس": 1,
    "الهدة": 1,
    "الهدات": 1,
    "الثليوم": 1,
    "تقليب": 1,
    "أظافر": 1,
    "القلاووظ": 1,
    "الإبهامي": 1,
    "القلاويظ": 1,
    "الإبهامية": 1,
    "المسيمير": 1,
    "بمسيمير": 1,
    "إبهامي": 1,
    "ضاربون": 1,
    "الرعد": 1,
    "الزوبعة": 1,
    "الرعدية": 1,
    "الصاعقة": 1,
    "الصواعق": 1,
    "الرعادة": 1,
    "رعد": 1,
    "راعد": 1,
    "الرعدي": 1,
    "الزوابع": 1,
    "ثوربر": 1,
    "ثرمان": 1,
    "المجذفين": 1,
    "خاصتك": 1,
    "الزعتر": 1,
    "ثايمين": 1,
    "الصعترية": 1,
    "الثايراترون": 1,
    "الثايرستور": 1,
    "ثايروجلوبولين": 1,
    "بالغدة": 1,
    "ثيرونين": 1,
    "بالثيروتروبين": 1,
    "ثيروتروبين": 1,
    "الثيروتروبين": 1,
    "نفسك": 1,
    "تايبر": 1,
    "التبت": 1,
    "تيبتي": 1,
    "قصبي": 1,
    "تيبورون": 1,
    "اللاإرادي": 1,
    "مدقوق": 1,
    "التذكرة": 1,
    "بتذكرة": 1,
    "التذاكر": 1,
    "الدغدغة": 1,
    "مدغدغ": 1,
    "تيكونديروجا": 1,
    "مدي": 1,
    "أرتب": 1,
    "ترتيبا": 1,
    "تيدنج": 1,
    "الربطة": 1,
    "تيك": 1,
    "تاينستن": 1,
    "الروابط": 1,
    "تيفاني": 1,
    "نمري": 1,
    "النمورة": 1,
    "مشدد": 1,
    "مغلول": 1,
    "بإحكام": 1,
    "النمرة": 1,
    "النمرات": 1,
    "تيجرينيا": 1,
    "سامية": 1,
    "منطوقة": 1,
    "إريتريا": 1,
    "دجلة": 1,
    "تيجوانا": 1,
    "سمسم": 1,
    "التلدة": 1,
    "مبلط": 1,
    "مبلطون": 1,
    "للفلاحة": 1,
    "فلاحة": 1,
    "مفلوح": 1,
    "تليك": 1,
    "تيلي": 1,
    "الحرث": 1,
    "تيم": 1,
    "مشجرة": 1,
    "مشاجر": 1,
    "الشجري": 1,
    "الشجرية": 1,
    "طباع": 1,
    "بالوقت": 1,
    "موقوت": 1,
    "اننضباطا": 1,
    "انضباطا": 1,
    "الانضباط": 1,
    "الموقت": 1,
    "زمن": 1,
    "الموقتات": 1,
    "موفر": 1,
    "مستغلوا": 1,
    "استغلال": 1,
    "جدولة": 1,
    "تيميكس": 1,
    "تيمي": 1,
    "تيمون": 1,
    "الرعبة": 1,
    "تيموثي": 1,
    "طبال": 1,
    "طبالون": 1,
    "تيمس": 1,
    "الصفيح": 1,
    "تينا": 1,
    "التينامو": 1,
    "الصوفان": 1,
    "القداح": 1,
    "رقائق": 1,
    "توخز": 1,
    "متوخز": 1,
    "يتوخز": 1,
    "التوخز": 1,
    "توخزي": 1,
    "صغرا": 1,
    "صفيحي": 1,
    "تنسلتاون": 1,
    "السمكري": 1,
    "السمكريون": 1,
    "صابغ": 1,
    "تيوجا": 1,
    "القلابة": 1,
    "تبيكانو": 1,
    "تبيراري": 1,
    "القلابات": 1,
    "اللفاع": 1,
    "اللفاعات": 1,
    "مبقشش": 1,
    "البقشيش": 1,
    "راشي": 1,
    "الأنتشاء": 1,
    "برفق": 1,
    "تيرانا": 1,
    "تيرينج": 1,
    "لايتعب": 1,
    "كلل": 1,
    "الدأب": 1,
    "العجلات": 1,
    "ينسج": 1,
    "التنسيج": 1,
    "التيتان": 1,
    "التيتانيت": 1,
    "جبابرة": 1,
    "العيار": 1,
    "الحجمي": 1,
    "العيارات": 1,
    "الحجمية": 1,
    "برتقالي": 1,
    "يلاعب": 1,
    "تيتو": 1,
    "عاير": 1,
    "معاير": 1,
    "يعاير": 1,
    "المعايرة": 1,
    "المكتومة": 1,
    "الضحكات": 1,
    "فخريا": 1,
    "تيتوس": 1,
    "اهتياجات": 1,
    "اهتياج": 1,
    "ثالث": 1,
    "التولوين": 1,
    "متفجر": 1,
    "العلجوم": 1,
    "تودفلاكس": 1,
    "التملقية": 1,
    "المحمصة": 1,
    "المحامص": 1,
    "دفء": 1,
    "نخب": 1,
    "المأدبة": 1,
    "دافء": 1,
    "التبوغ": 1,
    "توباغو": 1,
    "متزلج": 1,
    "المزلقات": 1,
    "توبي": 1,
    "التوكاتا": 1,
    "ناقوس": 1,
    "نواقيس": 1,
    "تود": 1,
    "تخط": 1,
    "باصبع": 1,
    "الانجليزية": 1,
    "كلغة": 1,
    "أجنبية": 1,
    "التوفو": 1,
    "تهندم": 1,
    "التآزر": 1,
    "توغو": 1,
    "الشراك": 1,
    "مجهودا": 1,
    "توكاماك": 1,
    "طوكيو": 1,
    "تولاند": 1,
    "توليدو": 1,
    "الاحتمال": 1,
    "الاحتمالية": 1,
    "احتمالي": 1,
    "لدفع": 1,
    "الجزية": 1,
    "محصلي": 1,
    "تولي": 1,
    "الجابي": 1,
    "تولستوي": 1,
    "تولوين": 1,
    "توم": 1,
    "توما": 1,
    "التوماهوك": 1,
    "الطماطة": 1,
    "التمبك": 1,
    "تومبجبي": 1,
    "المسترجلة": 1,
    "المسترجلات": 1,
    "شاهدة": 1,
    "المجلدات": 1,
    "الصبياني": 1,
    "حمقى": 1,
    "توملينسون": 1,
    "تومي": 1,
    "غدا": 1,
    "تومبكينس": 1,
    "تومز": 1,
    "النغمية": 1,
    "تناسق": 1,
    "منغم": 1,
    "تونج": 1,
    "التونجا": 1,
    "الملاقط": 1,
    "ملسن": 1,
    "الألسنة": 1,
    "باللسان": 1,
    "توني": 1,
    "تناغما": 1,
    "الليلة": 1,
    "يضاجع": 1,
    "تونكس": 1,
    "الأطنان": 1,
    "لوزة": 1,
    "اللوزتين": 1,
    "لوزي": 1,
    "إستئصال": 1,
    "حلاقات": 1,
    "تودل": 1,
    "تولنج": 1,
    "تولماك": 1,
    "زمار": 1,
    "المسواك": 1,
    "يصفر": 1,
    "تصفير": 1,
    "تصافير": 1,
    "أصبع": 1,
    "إسكر": 1,
    "ساكر": 1,
    "توبيكا": 1,
    "القنزعة": 1,
    "القنزعات": 1,
    "السواري": 1,
    "الطوبوغرافي": 1,
    "الطوبوغرافيون": 1,
    "طوبوغرافي": 1,
    "الطوبوغرافيا": 1,
    "طوبولوجي": 1,
    "اللاكمية": 1,
    "أمة": 1,
    "حاملوا": 1,
    "المشاعل": 1,
    "مصارعو": 1,
    "نتوءات": 1,
    "المعذب": 1,
    "المعذبون": 1,
    "حلقية": 1,
    "تورنتو": 1,
    "الطوربيد": 1,
    "الطوربيدات": 1,
    "الخدر": 1,
    "بخدر": 1,
    "للعنق": 1,
    "توركيمادا": 1,
    "لاوي": 1,
    "لاوون": 1,
    "أطواق": 1,
    "تورانس": 1,
    "تورنس": 1,
    "إتقاد": 1,
    "إلتوائي": 1,
    "تورتين": 1,
    "الجنح": 1,
    "توروسيس": 1,
    "توسكا": 1,
    "توسكانيني": 1,
    "توشيبا": 1,
    "قذفات": 1,
    "مسالة": 1,
    "الواحد": 1,
    "المجموعيات": 1,
    "الإجمال": 1,
    "مجموعه": 1,
    "المراهنات": 1,
    "الطوطم": 1,
    "الطواطم": 1,
    "كحمال": 1,
    "توتو": 1,
    "لمسا": 1,
    "الملامس": 1,
    "المحك": 1,
    "التجلد": 1,
    "القساة": 1,
    "تولوز": 1,
    "السياحة": 1,
    "السياح": 1,
    "التشعيث": 1,
    "المرابح": 1,
    "المرابحون": 1,
    "واعد": 1,
    "بالخير": 1,
    "المنشفة": 1,
    "بالفوطة": 1,
    "المناشف": 1,
    "بان": 1,
    "التوهي": 1,
    "عصافير": 1,
    "تاونلي": 1,
    "البلدات": 1,
    "تاونسند": 1,
    "قاطن": 1,
    "قاطنو": 1,
    "المتبع": 1,
    "المحاذي": 1,
    "لجر": 1,
    "المتبعة": 1,
    "تاوسلي": 1,
    "بالسم": 1,
    "السمية": 1,
    "توينبي": 1,
    "تويوتا": 1,
    "لإقتفاء": 1,
    "دالة": 1,
    "الراسم": 1,
    "الراسمات": 1,
    "التراخوما": 1,
    "الإقتفاء": 1,
    "إقتفاء": 1,
    "أثره": 1,
    "المقتفي": 1,
    "المقتفون": 1,
    "الإستطراق": 1,
    "الجرارات": 1,
    "تريسي": 1,
    "النقابية": 1,
    "المبادلات": 1,
    "التقليدي": 1,
    "التقليديون": 1,
    "الناجم": 1,
    "إتهامات": 1,
    "زائفة": 1,
    "تراج": 1,
    "التراجيدي": 1,
    "التراجيديون": 1,
    "التراجيدية": 1,
    "مأسوي": 1,
    "تراجيدي": 1,
    "تراجيدية": 1,
    "كوميدية": 1,
    "رائدا": 1,
    "المقطورة": 1,
    "المقطورات": 1,
    "للقافلة": 1,
    "للتدريب": 1,
    "تمش": 1,
    "يتمشى": 1,
    "الخائنة": 1,
    "الخائنات": 1,
    "الترام": 1,
    "المنتظمة": 1,
    "المهشم": 1,
    "الترامبولين": 1,
    "أتمم": 1,
    "متم": 1,
    "قادت": 1,
    "بجبال": 1,
    "ترانسامينيس": 1,
    "الأنزيمات": 1,
    "والاستقبال": 1,
    "متعال": 1,
    "متسام": 1,
    "المتعالية": 1,
    "بالفلسفة": 1,
    "فيرت": 1,
    "لإشارات": 1,
    "وفرق": 1,
    "الناسخات": 1,
    "الإنتقالات": 1,
    "تبدل": 1,
    "المحدود": 1,
    "نقله": 1,
    "إنتهك": 1,
    "ينتهك": 1,
    "المتجاوزون": 1,
    "العابرو": 1,
    "الترنزستور": 1,
    "بترانزستور": 1,
    "الترنزستورات": 1,
    "إنتقالي": 1,
    "متعدي": 1,
    "للترجمة": 1,
    "بالحركة": 1,
    "نقلي": 1,
    "تكريم": 1,
    "للمترجمين": 1,
    "صوتيا": 1,
    "للإنتقال": 1,
    "المرسلات": 1,
    "القبيحة": 1,
    "تحولية": 1,
    "الشفافيات": 1,
    "إرشح": 1,
    "مرشوح": 1,
    "الرشح": 1,
    "للزرع": 1,
    "الزروعات": 1,
    "إبدال": 1,
    "المتخنث": 1,
    "المتخنثون": 1,
    "ترانسهيب": 1,
    "بالعرض": 1,
    "التشبه": 1,
    "ترانسيلفانيا": 1,
    "المنحرفة": 1,
    "البهارج": 1,
    "الموسخ": 1,
    "العناء": 1,
    "معانى": 1,
    "الشاقة": 1,
    "ترافرز": 1,
    "عبره": 1,
    "عبر..": 1,
    "العابر": 1,
    "ترسبات": 1,
    "والأنهار": 1,
    "التحريفات": 1,
    "ترافس": 1,
    "شباك": 1,
    "معسول": 1,
    "لامس": 1,
    "بقدمه": 1,
    "تريدويل": 1,
    "خياني": 1,
    "خائنة": 1,
    "الإدخار": 1,
    "المعاهدات": 1,
    "إرتجف": 1,
    "المرتجف": 1,
    "يرتجف": 1,
    "الارتعاش": 1,
    "الأكول": 1,
    "الأكولون": 1,
    "مسايرة": 1,
    "الموضة": 1,
    "ترينتن": 1,
    "الهلع": 1,
    "الخطيئة": 1,
    "المتجاوزن": 1,
    "الخصلات": 1,
    "تريفيليان": 1,
    "ثلاثي": 1,
    "والخطأ": 1,
    "للإلتهابات": 1,
    "المثلثات": 1,
    "مثلثي": 1,
    "ثلث": 1,
    "مثلث": 1,
    "وتحديد": 1,
    "ترايانجلم": 1,
    "القبلية": 1,
    "القبيلة": 1,
    "القبائل": 1,
    "يمتحن": 1,
    "منبر": 1,
    "الروافد": 1,
    "الرافد": 1,
    "يثني": 1,
    "ثلاثيو": 1,
    "ترايسيراتوبس": 1,
    "تريكينيلا": 1,
    "خيطية": 1,
    "طفيلية": 1,
    "تريكينوسيس": 1,
    "مطهو": 1,
    "التريكلوروسيتيك": 1,
    "تريكلورويثان": 1,
    "الثلاثي": 1,
    "تريكير": 1,
    "أصعب": 1,
    "الوشل": 1,
    "متقطر": 1,
    "يتقطر": 1,
    "تردياجونال": 1,
    "كعكات": 1,
    "الزناد": 1,
    "ثلاثيا": 1,
    "التسجيع": 1,
    "تريليون": 1,
    "تريليونات": 1,
    "تريلوبيت": 1,
    "الثلاثيات": 1,
    "تريمبل": 1,
    "المتقلب": 1,
    "تهذيبا": 1,
    "ترنيداد": 1,
    "بالثالوث": 1,
    "الثالوثية": 1,
    "نتريت": 1,
    "الثلاثيون": 1,
    "تريب": 1,
    "البيريداين": 1,
    "فوسفور": 1,
    "يتضاعف": 1,
    "توأم": 1,
    "تريبليت": 1,
    "يثلث": 1,
    "التثليث": 1,
    "تضاعف": 1,
    "الحاملات": 1,
    "طرابلس": 1,
    "ترايبوليفوسفيت": 1,
    "الكامة": 1,
    "الاحتواء": 1,
    "صوديوم": 1,
    "تريستان": 1,
    "التريتيوم": 1,
    "التريتون": 1,
    "الإنتصار": 1,
    "منتصر": 1,
    "بانتصار": 1,
    "الإنتصارات": 1,
    "الثلاثة": 1,
    "حكومات": 1,
    "ثالوثي": 1,
    "التوافه": 1,
    "مسلي": 1,
    "ديس": 1,
    "للمصابيح": 1,
    "بإنسان": 1,
    "الزحافة": 1,
    "الزحافات": 1,
    "طروادة": 1,
    "بالشاحنة": 1,
    "شاحنات": 1,
    "التصيد": 1,
    "الباغية": 1,
    "الباغيات": 1,
    "شاحنة": 1,
    "الترومبون": 1,
    "الإستعارة": 1,
    "بالجوائز": 1,
    "المؤثرات": 1,
    "الجزيئي": 1,
    "لألياف": 1,
    "كولاجين": 1,
    "للغلاف": 1,
    "تروتسكاي": 1,
    "الكوارع": 1,
    "مثيرو": 1,
    "حلالو": 1,
    "المنخفضات": 1,
    "العروس": 1,
    "ملبوسات": 1,
    "التراوت": 1,
    "تروتمان": 1,
    "الدفين": 1,
    "إملج": 1,
    "مملوج": 1,
    "الجارف": 1,
    "بالجاروف": 1,
    "الملج": 1,
    "يملج": 1,
    "تروي": 1,
    "متغيب": 1,
    "الهدنات": 1,
    "يتهادن": 1,
    "نفقة": 1,
    "مقايض": 1,
    "تروكي": 1,
    "بالشاحنات": 1,
    "ترودو": 1,
    "ممشي": 1,
    "المنهك": 1,
    "ترودي": 1,
    "تروس": 1,
    "التروتايب": 1,
    "الكمأ": 1,
    "تريجيلو": 1,
    "ترومان": 1,
    "ترمبل": 1,
    "بلعب": 1,
    "فوز": 1,
    "التدحرج": 1,
    "مدحرج": 1,
    "المدحرج": 1,
    "يدحرج": 1,
    "بجذع": 1,
    "مجراه": 1,
    "مرتكز": 1,
    "أوصي": 1,
    "وصاية": 1,
    "وصي": 1,
    "بوفاء": 1,
    "وثوق": 1,
    "بأهلية": 1,
    "للثقة": 1,
    "بالثقة": 1,
    "التربسين": 1,
    "القيصري": 1,
    "القياصرة": 1,
    "التسونجا": 1,
    "تسنيماتسو": 1,
    "التسوانية": 1,
    "أتكلم": 1,
    "معك": 1,
    "الثلاثاء": 1,
    "تيوتارا": 1,
    "توبيد": 1,
    "الدرنة": 1,
    "الدرنات": 1,
    "درني": 1,
    "السلين": 1,
    "السل": 1,
    "مسلول": 1,
    "تيوبروز": 1,
    "مكسيكية": 1,
    "الثنية": 1,
    "الصدرية": 1,
    "الثنيات": 1,
    "توسون": 1,
    "تيودور": 1,
    "المسامي": 1,
    "الخصل": 1,
    "تافتس": 1,
    "مصاريف": 1,
    "تولان": 1,
    "التوليب": 1,
    "تولسا": 1,
    "السقطة": 1,
    "التورم": 1,
    "وارم": 1,
    "بتورم": 1,
    "ورمي": 1,
    "تقسيمه": 1,
    "وتضبيطه": 1,
    "والتضبيط": 1,
    "التونة": 1,
    "التندرا": 1,
    "بنغم": 1,
    "العذوبة": 1,
    "مقسمو": 1,
    "المضبطون": 1,
    "تونغ": 1,
    "صينية": 1,
    "تنجستيت": 1,
    "التنجستيك": 1,
    "التنجستن": 1,
    "تونس": 1,
    "التونسي": 1,
    "نفق": 1,
    "الأنفاق": 1,
    "توبيلو": 1,
    "العمامة": 1,
    "العمائم": 1,
    "العكرة": 1,
    "التعكر": 1,
    "المقاوب": 1,
    "القرائن": 1,
    "التوربين": 1,
    "التوربينات": 1,
    "نفاث": 1,
    "التربيني": 1,
    "المروحي": 1,
    "تربيني": 1,
    "التربينية": 1,
    "المطب": 1,
    "السباقات": 1,
    "تورين": 1,
    "تورينج": 1,
    "التركي": 1,
    "تركيا": 1,
    "الرومي": 1,
    "تركي": 1,
    "توركمنستان": 1,
    "الأتراك": 1,
    "الكركم": 1,
    "تيرنابل": 1,
    "وشد": 1,
    "الإنتهازي": 1,
    "المنعطفات": 1,
    "نافر": 1,
    "الإقبال": 1,
    "الدوارة": 1,
    "تيرنستون": 1,
    "التربنتين": 1,
    "الدناءة": 1,
    "السفالات": 1,
    "الفيروز": 1,
    "الحدباء": 1,
    "المدورة": 1,
    "توسكالوسا": 1,
    "جامعية": 1,
    "توسكان": 1,
    "تسكانيا": 1,
    "توسكارورا": 1,
    "توسكيجي": 1,
    "التنازع": 1,
    "الإمتعاض": 1,
    "عنخ": 1,
    "آمون": 1,
    "تاتل": 1,
    "تنورات": 1,
    "قصيرات": 1,
    "توفالو": 1,
    "تكسيدو": 1,
    "مضخم": 1,
    "ترانس": 1,
    "وورلد": 1,
    "توادلر": 1,
    "تواين": 1,
    "الرنات": 1,
    "التويد": 1,
    "ينتف": 1,
    "نتف": 1,
    "النتاف": 1,
    "النتف": 1,
    "الملقط": 1,
    "ثانيات": 1,
    "إثناعشر": 1,
    "العشرينات": 1,
    "العشرين": 1,
    "عشرون": 1,
    "عشرين": 1,
    "توي": 1,
    "الشفق": 1,
    "البرمة": 1,
    "الملتف": 1,
    "المتلازم": 1,
    "البرم": 1,
    "موخوز": 1,
    "التوأمة": 1,
    "متلألأ": 1,
    "المتلألىء": 1,
    "التلألئ": 1,
    "توأمين": 1,
    "بحركة": 1,
    "وفرح": 1,
    "اللية": 1,
    "للف": 1,
    "الإختلاجة": 1,
    "تويت": 1,
    "التغاريد": 1,
    "إثنان": 1,
    "شرطين": 1,
    "صميمين": 1,
    "رقمين": 1,
    "مستو": 1,
    "حزبين": 1,
    "مرحلتين": 1,
    "مينائين": 1,
    "وجهين": 1,
    "دولتين": 1,
    "خطوتين": 1,
    "ضربتين": 1,
    "نغمتين": 1,
    "المقيمين": 1,
    "ضعفين": 1,
    "توومبلي": 1,
    "الإثنينات": 1,
    "مقعدين": 1,
    "تايم": 1,
    "وورنر": 1,
    "تيبرن": 1,
    "للإعدام": 1,
    "الوضيع": 1,
    "الحقراء": 1,
    "تيلر": 1,
    "طبلاني": 1,
    "تيندول": 1,
    "عرضها": 1,
    "للطباعة": 1,
    "التيفوئيد": 1,
    "التيفويد": 1,
    "تيفون": 1,
    "التيفوس": 1,
    "مثال": 1,
    "المماثلة": 1,
    "كاتبو": 1,
    "الطباعون": 1,
    "رمزيا": 1,
    "طغياني": 1,
    "مضطهدة": 1,
    "المستبدون": 1,
    "بمقاطعة": 1,
    "تيرول": 1,
    "التيروسين": 1,
    "تايسن": 1,
    "التكسيدو": 1,
    "الرجالية": 1,
    "مضغة": 1,
    "للخلف": 1,
    "المرسلالمستقبل": 1,
    "العالمي": 1,
    "اللاتزامني": 1,
    "أكاري": 1,
    "القردة": 1,
    "التواجد": 1,
    "يتواجد": 1,
    "بتواجد": 1,
    "أنجليس": 1,
    "بيركلي": 1,
    "يوكون": 1,
    "يودال": 1,
    "الضرع": 1,
    "ضرع": 1,
    "ضروع": 1,
    "حليب": 1,
    "الضروع": 1,
    "يوديل": 1,
    "فنلندية": 1,
    "يوفا": 1,
    "أوغندا": 1,
    "الإجاراتية": 1,
    "اشمئزاز": 1,
    "الأجلي": 1,
    "أقبح": 1,
    "قبيحا": 1,
    "القبح": 1,
    "أهلاند": 1,
    "مرسوم": 1,
    "قيثارة": 1,
    "يوكيا": 1,
    "أوكرانيا": 1,
    "الأوكراني": 1,
    "الأوكرانيون": 1,
    "اوكرانيا": 1,
    "الأكلال": 1,
    "قيثارات": 1,
    "متقرح": 1,
    "يتقرح": 1,
    "تقرحي": 1,
    "يولين": 1,
    "النقوصات": 1,
    "ألمان": 1,
    "أولمان": 1,
    "الزند": 1,
    "للندب": 1,
    "معطف": 1,
    "ارلندي": 1,
    "معاطف": 1,
    "ارلندية": 1,
    "للشهر": 1,
    "بالثأر": 1,
    "إبتدائية": 1,
    "بنفسجية": 1,
    "نابذة": 1,
    "اللازود": 1,
    "المتعصبة": 1,
    "الصوتي": 1,
    "صوتيات": 1,
    "السوناري": 1,
    "الألتريكس": 1,
    "النابح": 1,
    "العاوي": 1,
    "أوليسيس": 1,
    "خيمي": 1,
    "عنقود": 1,
    "اللبيس": 1,
    "مغمق": 1,
    "يغمق": 1,
    "سرر": 1,
    "سرة": 1,
    "ظليل": 1,
    "الاستياء": 1,
    "ظلال": 1,
    "الأمبرين": 1,
    "الضل": 1,
    "يحصى": 1,
    "لكثير": 1,
    "خجل": 1,
    "مقبولية": 1,
    "مرحب": 1,
    "لأسباب": 1,
    "مجهولة": 1,
    "متعذرة": 1,
    "لاينصح": 1,
    "أكسجين": 1,
    "تأثر": 1,
    "اللاتأثر": 1,
    "أناكا": 1,
    "يقظ": 1,
    "تغرب": 1,
    "فتنة": 1,
    "الإجماعية": 1,
    "لكذا": 1,
    "إيفاء": 1,
    "ممات": 1,
    "مرساة": 1,
    "غضبان": 1,
    "بالإجماع": 1,
    "لاجواب": 1,
    "عداء": 1,
    "لتقديم": 1,
    "إعتذار": 1,
    "للاعتذار": 1,
    "للاستئناف": 1,
    "للإسترضاء": 1,
    "تقاربية": 1,
    "استحالة": 1,
    "اللامناسبة": 1,
    "اللاجاذبية": 1,
    "أناو": 1,
    "الكسلان": 1,
    "ارفض": 1,
    "توفر": 1,
    "اللاجدوى": 1,
    "اللامفر": 1,
    "للتجنب": 1,
    "تكييس": 1,
    "أخلل": 1,
    "بتوازن": 1,
    "اللاتوازن": 1,
    "إخلال": 1,
    "أنبانس": 1,
    "قيمته": 1,
    "مخفوف": 1,
    "لجام": 1,
    "لايهزم": 1,
    "ﻻ": 1,
    "ﻻئق": 1,
    "ينهض": 1,
    "المتشكك": 1,
    "المتشككون": 1,
    "بشك": 1,
    "ينحاز": 1,
    "اللاتحيز": 1,
    "المحروم": 1,
    "معمى": 1,
    "لايرف": 1,
    "تبجحا": 1,
    "شكلاه": 1,
    "الجسد": 1,
    "حاسر": 1,
    "اللاحدود": 1,
    "يعتدل": 1,
    "لايقهر": 1,
    "محتوياتها": 1,
    "للشم": 1,
    "مفكوك": 1,
    "متزحزح": 1,
    "مخرج": 1,
    "مغاير": 1,
    "لأصول": 1,
    "مقدرتقديرا": 1,
    "للطبيعة": 1,
    "متواصل": 1,
    "أنسيد": 1,
    "اللارسمية": 1,
    "للمنازلة": 1,
    "تحدي": 1,
    "اللاتسامحية": 1,
    "همجي": 1,
    "وضوح": 1,
    "مرخى": 1,
    "يرخي": 1,
    "الأعمام": 1,
    "يزل": 1,
    "تهيأ": 1,
    "متهيأ": 1,
    "يتهيأ": 1,
    "تحصيله": 1,
    "للتمشيط": 1,
    "بتضايق": 1,
    "إعتيادي": 1,
    "بصمت": 1,
    "مشتكي": 1,
    "مساومة": 1,
    "اللامباﻻة": 1,
    "شرط": 1,
    "إلتزام": 1,
    "بالأمر": 1,
    "بالأمور": 1,
    "متفاني": 1,
    "معقولية": 1,
    "دستورية": 1,
    "أنكونتابل": 1,
    "إعتراض": 1,
    "تقليدية": 1,
    "اللاإقناع": 1,
    "وثاق": 1,
    "معضد": 1,
    "بالقرابة": 1,
    "عبور": 1,
    "اخلع": 1,
    "مرهم": 1,
    "سدل": 1,
    "مسدل": 1,
    "ديموقراطي": 1,
    "لاينكر": 1,
    "دهان": 1,
    "القاصر": 1,
    "يساند": 1,
    "النامية": 1,
    "عجلات": 1,
    "اللامتخرج": 1,
    "التحتانية": 1,
    "تهاون": 1,
    "المستضعف": 1,
    "المستضعفون": 1,
    "لصرف": 1,
    "أسيء": 1,
    "مهاراته": 1,
    "تغذية": 1,
    "إنقاص": 1,
    "الداعمة": 1,
    "تحتي": 1,
    "أثواب": 1,
    "تحتية": 1,
    "معنويا": 1,
    "الجامعيون": 1,
    "تحأرضي": 1,
    "يخفى": 1,
    "بخفية": 1,
    "أندرهيل": 1,
    "متدني": 1,
    "موقوع": 1,
    "يؤجر": 1,
    "بالباطن": 1,
    "بثمن": 1,
    "بخس": 1,
    "تحته": 1,
    "قوض": 1,
    "مقوض": 1,
    "يقوض": 1,
    "التقويض": 1,
    "مرءوس": 1,
    "يتحفظ": 1,
    "الحبكة": 1,
    "إعلانه": 1,
    "قله": 1,
    "تمثيله": 1,
    "وكلاء": 1,
    "الوزارات": 1,
    "إبخس": 1,
    "يبخس": 1,
    "نائب": 1,
    "العمدة": 1,
    "مقصرا": 1,
    "بإقصار": 1,
    "المصروف": 1,
    "كرافعة": 1,
    "السرداب": 1,
    "إغمطه": 1,
    "مغموط": 1,
    "الإستهانة": 1,
    "الإستهانات": 1,
    "يغمطه": 1,
    "الغمط": 1,
    "بدﻻ": 1,
    "التعهدات": 1,
    "البخس": 1,
    "الاستحباب": 1,
    "المكروهون": 1,
    "مستحب": 1,
    "تفاصيل": 1,
    "التنحية": 1,
    "الإستغناء": 1,
    "يقينية": 1,
    "فاعل": 1,
    "داعي": 1,
    "تموج": 1,
    "يتموج": 1,
    "التموجات": 1,
    "بتموج": 1,
    "اللاإلتزام": 1,
    "لهفة": 1,
    "سماوية": 1,
    "إنشاء": 1,
    "مستحي": 1,
    "إستخدامية": 1,
    "يخيص": 1,
    "استنتاج": 1,
    "لاينتهي": 1,
    "الإطاقة": 1,
    "تنوير": 1,
    "انتفاء": 1,
    "لايحسد": 1,
    "يجارى": 1,
    "بلانلد": 1,
    "صائب": 1,
    "مفر": 1,
    "للعلوم": 1,
    "والتربية": 1,
    "والثقافة": 1,
    "محالة": 1,
    "تحسينه": 1,
    "لبعد": 1,
    "الفجائية": 1,
    "زهيد": 1,
    "تنم": 1,
    "يخبأ": 1,
    "يعيض": 1,
    "إيمان": 1,
    "تاعب": 1,
    "أنثوي": 1,
    "مختمر": 1,
    "بالقانون": 1,
    "مليئ": 1,
    "لايكل": 1,
    "الاستثارة": 1,
    "استثارته": 1,
    "إغراء": 1,
    "إغرائي": 1,
    "تفتج": 1,
    "يتفتح": 1,
    "فولو": 1,
    "يالتحلل": 1,
    "إلالأجز": 1,
    "ءيلتمير": 1,
    "نيلتدمير": 1,
    "لسوء": 1,
    "التعساء": 1,
    "تآخي": 1,
    "بالصديق": 1,
    "اللاودية": 1,
    "مؤثث": 1,
    "الغلاظة": 1,
    "شحيح": 1,
    "إعطاء": 1,
    "أنجلوفيد": 1,
    "بعدما": 1,
    "إنغلق": 1,
    "بالصمغ": 1,
    "الإنقياد": 1,
    "لطافة": 1,
    "اللاقواعدية": 1,
    "مكره": 1,
    "بالمسمار": 1,
    "يتركه": 1,
    "للتخمين": 1,
    "والمخالب": 1,
    "متكون": 1,
    "للدهن": 1,
    "الظفر": 1,
    "الظلف": 1,
    "والماعز": 1,
    "ظلف": 1,
    "أنيلهيكسيوم": 1,
    "الطقم": 1,
    "البلية": 1,
    "مشفي": 1,
    "بالصحة": 1,
    "السماع": 1,
    "بالأذن": 1,
    "انتباه": 1,
    "لاتأريخي": 1,
    "إثما": 1,
    "صهوة": 1,
    "متأني": 1,
    "اليونيسيف": 1,
    "للأمم": 1,
    "مسئولة": 1,
    "فريدد": 1,
    "الدولي": 1,
    "وحيدات": 1,
    "ينيكس": 1,
    "أفكار": 1,
    "الاتساق": 1,
    "تصوره": 1,
    "بلاشك": 1,
    "متأئر": 1,
    "مستلهم": 1,
    "إهتمام": 1,
    "لايتدخل": 1,
    "شؤون": 1,
    "النقابي": 1,
    "يلد": 1,
    "أحاديات": 1,
    "إستثنائيا": 1,
    "التفردية": 1,
    "الإنفراد": 1,
    "للجنسين": 1,
    "الإئتلافات": 1,
    "يونيسس": 1,
    "ينيتال": 1,
    "التوحيدية": 1,
    "موحدون": 1,
    "وحدوي": 1,
    "إتحد": 1,
    "باتحاد": 1,
    "توحدي": 1,
    "يونيفاك": 1,
    "المتغير": 1,
    "للعالمية": 1,
    "بالعالمية": 1,
    "الأكوان": 1,
    "اليونيكس": 1,
    "تشويش": 1,
    "غري": 1,
    ",": 1,
    "مبهذل": 1,
    "المجهول": 1,
    "اتفاق": 1,
    "مستوفى": 1,
    "اللاأدب": 1,
    "الإطاحة": 1,
    "الخال": 1,
    "عارف": 1,
    "المجهولون": 1,
    "غرامة": 1,
    "مخالفة": 1,
    "اللاشرعية": 1,
    "عنان": 1,
    "لايتمتع": 1,
    "بشخصية": 1,
    "قيادية": 1,
    "مالم": 1,
    "خلاف": 1,
    "المستبعد": 1,
    "ملدن": 1,
    "التلدين": 1,
    "يقسي": 1,
    "بالحسبان": 1,
    "الكراهة": 1,
    "يغتصب": 1,
    "الساخطة": 1,
    "اللاأخلاقية": 1,
    "إنزال": 1,
    "المستور": 1,
    "أعزب": 1,
    "أعازب": 1,
    "للتحكم": 1,
    "إدارته": 1,
    "ممنوعة": 1,
    "اللارحمة": 1,
    "شاده": 1,
    "الدخيلة": 1,
    "التلطيف": 1,
    "يقيل": 1,
    "تنغيم": 1,
    "خافض": 1,
    "عيوب": 1,
    "القناع": 1,
    "أعصاب": 1,
    "تتراجع": 1,
    "كونها": 1,
    "راهبة": 1,
    "أونو": 1,
    "خدوم": 1,
    "الإحتشام": 1,
    "هجومي": 1,
    "معارضة": 1,
    "للصبغ": 1,
    "الاستساغة": 1,
    "مستساغ": 1,
    "خلاله": 1,
    "للوطنية": 1,
    "سلطوي": 1,
    "وبلا": 1,
    "مهلة": 1,
    "سراحه": 1,
    "للإداء": 1,
    "الجأش": 1,
    "أصباغ": 1,
    "إستخطاء": 1,
    "مرير": 1,
    "القابس": 1,
    "اللاشعبية": 1,
    "لحوح": 1,
    "يتلق": 1,
    "يطيح": 1,
    "مثيل": 1,
    "الانطباع": 1,
    "الحيادي": 1,
    "للتقديم": 1,
    "اللامبدئية": 1,
    "باهض": 1,
    "ربح": 1,
    "أرباح": 1,
    "فوائد": 1,
    "الإرباح": 1,
    "مرجو": 1,
    "إستفزازي": 1,
    "تحفيز": 1,
    "تحرش": 1,
    "عقاب": 1,
    "لاشك": 1,
    "اختم": 1,
    "كلاما": 1,
    "مقتبسا": 1,
    "خميرة": 1,
    "تكشف": 1,
    "متكشف": 1,
    "التكشف": 1,
    "يتكشف": 1,
    "مقروئية": 1,
    "الجهوزية": 1,
    "واقعية": 1,
    "الوهمية": 1,
    "الجهالة": 1,
    "تقبلي": 1,
    "بالجميل": 1,
    "إعتراف": 1,
    "تسوية": 1,
    "لافائدة": 1,
    "ترجى": 1,
    "زنديق": 1,
    "تشكي": 1,
    "إرجائه": 1,
    "تأجيله": 1,
    "للتكرار": 1,
    "لايقاوم": 1,
    "حله": 1,
    "إيجاد": 1,
    "عالق": 1,
    "إزدراء": 1,
    "إستهتار": 1,
    "لايستحق": 1,
    "هامل": 1,
    "التجاوب": 1,
    "إرتياح": 1,
    "التقيد": 1,
    "ليست": 1,
    "لايحفظ": 1,
    "تعويضه": 1,
    "إرجاعه": 1,
    "التوقير": 1,
    "ينم": 1,
    "لانظير": 1,
    "المطوي": 1,
    "رومانسية": 1,
    "مسقوف": 1,
    "يتورع": 1,
    "لايحرم": 1,
    "المستحيل": 1,
    "بيعه": 1,
    "بمذاق": 1,
    "لأنه": 1,
    "منذور": 1,
    "لله": 1,
    "اذن": 1,
    "مصنفر": 1,
    "منعم": 1,
    "استياء": 1,
    "سخط": 1,
    "تبرم": 1,
    "مرضي": 1,
    "تشبع": 1,
    "بلاطعم": 1,
    "إسحب": 1,
    "لاعلمي": 1,
    "مدرز": 1,
    "أوانه": 1,
    "متزعزع": 1,
    "يسلط": 1,
    "أنانية": 1,
    "بلادة": 1,
    "مخصى": 1,
    "خصوبة": 1,
    "الكلبشات": 1,
    "حليق": 1,
    "سقيفة": 1,
    "حفى": 1,
    "لايتقلص": 1,
    "الخطايا": 1,
    "نقيا": 1,
    "للإغراق": 1,
    "برداءة": 1,
    "نهاش": 1,
    "مؤانسة": 1,
    "ملتصقين": 1,
    "عاثر": 1,
    "مبذورة": 1,
    "مزروعة": 1,
    "بلازرع": 1,
    "يصح": 1,
    "ذكره": 1,
    "ضاربا": 1,
    "الحائط": 1,
    "بالقوانين": 1,
    "والمثل": 1,
    "عصرة": 1,
    "تحديق": 1,
    "قاعدتها": 1,
    "جواد": 1,
    "إيقافه": 1,
    "مجزئه": 1,
    "كريهة": 1,
    "ملمة": 1,
    "بإخفاق": 1,
    "العوز": 1,
    "كاف": 1,
    "تشوبه": 1,
    "شائبة": 1,
    "اشتباه": 1,
    "للقهر": 1,
    "تجاوزه": 1,
    "بطأ": 1,
    "دعمه": 1,
    "للبلع": 1,
    "متسق": 1,
    "نظامي": 1,
    "أنتامابل": 1,
    "معتنى": 1,
    "بحبل": 1,
    "جاحد": 1,
    "المعروف": 1,
    "الامتنان": 1,
    "يذب": 1,
    "وارد": 1,
    "الغفل": 1,
    "مغفول": 1,
    "غفل": 1,
    "لأوانه": 1,
    "ارهاقه": 1,
    "المحصنون": 1,
    "للسفر": 1,
    "داس": 1,
    "مستبعد": 1,
    "بكذب": 1,
    "لايثق": 1,
    "الكذبة": 1,
    "مطويا": 1,
    "كالسجادة": 1,
    "تنشز": 1,
    "دارس": 1,
    "المتشابك": 1,
    "المتحابك": 1,
    "انحل": 1,
    "انفك": 1,
    "يسلك": 1,
    "النقاب": 1,
    "يميط": 1,
    "اللثام": 1,
    "يعادله": 1,
    "تزويق": 1,
    "منفس": 1,
    "مفعل": 1,
    "ملم": 1,
    "مزار": 1,
    "بتولي": 1,
    "بحت": 1,
    "طاهر": 1,
    "مأجور": 1,
    "دفىء": 1,
    "مدفأ": 1,
    "مفطوم": 1,
    "لازال": 1,
    "لايصلح": 1,
    "للإرتداء": 1,
    "متزوجة": 1,
    "المأخذ": 1,
    "مرثي": 1,
    "مخفوق": 1,
    "مراسا": 1,
    "الأصعب": 1,
    "واردة": 1,
    "برعونة": 1,
    "ذابل": 1,
    "نباتية": 1,
    "مشاكل": 1,
    "هموم": 1,
    "محاك": 1,
    "لتحقيق": 1,
    "وأدنى": 1,
    "سيحصل": 1,
    "آت": 1,
    "بأخرى": 1,
    "وحديثة": 1,
    "يحشد": 1,
    "يتعاقد": 1,
    "حشد": 1,
    "يسمو": 1,
    "لذوي": 1,
    "سلعة": 1,
    "باهضة": 1,
    "اصول": 1,
    "متواضعة": 1,
    "للأعلى": 1,
    "مستبشر": 1,
    "توبيخي": 1,
    "طريقه": 1,
    "رئتيه": 1,
    "يحتضن": 1,
    "يربي": 1,
    "تهوية": 1,
    "وتنقية": 1,
    "تقيؤ": 1,
    "(الملف)": 1,
    "يداور": 1,
    "التجديدات": 1,
    "يتدفق": 1,
    "اﻷعلى": 1,
    "لصالح": 1,
    "مرقى": 1,
    "يترعرع": 1,
    "بسيف": 1,
    "بخنجر": 1,
    "مؤيدون": 1,
    "نجد": 1,
    "منجد": 1,
    "المنجدون": 1,
    "التنجيد": 1,
    "ينجد": 1,
    "النجادة": 1,
    "النجد": 1,
    "متحظر": 1,
    "يستند": 1,
    "يقفل": 1,
    "ومالية": 1,
    "حروفا": 1,
    "طلاب": 1,
    "موجهة": 1,
    "صعودا": 1,
    "يكوم": 1,
    "الخيلاء": 1,
    "إنهض": 1,
    "(السجادة)": 1,
    "يستيقض": 1,
    "يستحث": 1,
    "أبسالا": 1,
    "التكدر": 1,
    "يرمي": 1,
    "إبسلون": 1,
    "يحلق": 1,
    "وثبة": 1,
    "قف": 1,
    "جلي": 1,
    "حديثو": 1,
    "وﻻية": 1,
    "يؤازر": 1,
    "تمسيد": 1,
    "الإنظمام": 1,
    "يأرجح": 1,
    "يوثق": 1,
    "القرية": 1,
    "يدرب": 1,
    "والواقعة": 1,
    "يوراسيل": 1,
    "إنحباس": 1,
    "إنسداد": 1,
    "بانسداد": 1,
    "تزين": 1,
    "والدال": 1,
    "أورال": 1,
    "الممتدة": 1,
    "اوروبا": 1,
    "واسيا": 1,
    "للبول": 1,
    "يورانيا": 1,
    "بالكوكب": 1,
    "باﻷجرام": 1,
    "باليورانيم": 1,
    "اليورانيوم": 1,
    "للنجوم": 1,
    "والمجرات": 1,
    "وباقي": 1,
    "الفيزيائي": 1,
    "المختصة": 1,
    "واﻷجرام": 1,
    "توضح": 1,
    "متابعة": 1,
    "وملاحظة": 1,
    "التهابات": 1,
    "مؤلمة": 1,
    "إبهام": 1,
    "والقدم": 1,
    "أوربانا": 1,
    "العمران": 1,
    "كمثرى": 1,
    "وطيبة": 1,
    "المديني": 1,
    "المدينيون": 1,
    "الدماثة": 1,
    "أوربانك": 1,
    "لغسل": 1,
    "وفقير": 1,
    "وعابث": 1,
    "والقدر": 1,
    "الأردو": 1,
    "وباكستان": 1,
    "وتكتب": 1,
    "يوريا": 1,
    "اليوريا": 1,
    "تبولن": 1,
    "أسيد": 1,
    "إريثان": 1,
    "الإحليل": 1,
    "الأحاليل": 1,
    "إحليلي": 1,
    "للرؤية": 1,
    "لمجرى": 1,
    "بالبول": 1,
    "الإضطرار": 1,
    "عاجلة": 1,
    "إلحاح": 1,
    "إلحاحات": 1,
    "وري": 1,
    "الملتحية": 1,
    "المبولة": 1,
    "المبولات": 1,
    "تحاليل": 1,
    "لتدفق": 1,
    "بالتبول": 1,
    "بالأعضاء": 1,
    "البولية": 1,
    "البرمائيات": 1,
    "بالبرمائيات": 1,
    "تناسلي": 1,
    "والمسالك": 1,
    "بالقمار": 1,
    "أورسا": 1,
    "دبي": 1,
    "أورسولا": 1,
    "أورسلينية": 1,
    "إلهة": 1,
    "أورغواي": 1,
    "أوروغواي": 1,
    "ومنقرض": 1,
    "نحن": 1,
    "الاستخدام": 1,
    "للاستخدام": 1,
    "الأميركي": 1,
    "المؤتمن": 1,
    "الإستعمالات": 1,
    "العرفية": 1,
    "الكمبيالة": 1,
    "الجيوديكي": 1,
    "والساحلي": 1,
    "اليوزنت": 1,
    "بمستعمل": 1,
    "المستعملون": 1,
    "مستخدمونمهاممتغييرات": 1,
    "يونيكس": 1,
    "الأبحاث": 1,
    "الجيولوجية": 1,
    "المرشدة": 1,
    "هادي": 1,
    "الأسك": 1,
    "الاعتياد": 1,
    "أوسومبورا": 1,
    "المرابون": 1,
    "مراباة": 1,
    "رباوي": 1,
    "الربوية": 1,
    "إغتصب": 1,
    "اغتصابي": 1,
    "الربا": 1,
    "يوتاه": 1,
    "يوتا": 1,
    "رحمي": 1,
    "(بغرض": 1,
    "الولادة)": 1,
    "المهبل": 1,
    "والرحم": 1,
    "الأرحام": 1,
    "العالميه": 1,
    "لتحوير": 1,
    "يوتكا": 1,
    "النفعي": 1,
    "النفعية": 1,
    "منفعة": 1,
    "إستعماله": 1,
    "ورائه": 1,
    "متنفع": 1,
    "اليوطوبيا": 1,
    "اليوطوبي": 1,
    "اليوطوبية": 1,
    "اليوطوبيون": 1,
    "تفائلي": 1,
    "ملتفة": 1,
    "لإلغاء": 1,
    "يوتركت": 1,
    "أوتريللو": 1,
    "إشتهر": 1,
    "برسمه": 1,
    "للشوارع": 1,
    "الباريسية": 1,
    "ألعظمة": 1,
    "والبريد": 1,
    "الالكتروني": 1,
    "ASCII": 1,
    "Binary": 1,
    "يجميع": 1,
    "البنفسجية": 1,
    "لهاة": 1,
    "قرينة": 1,
    "بالزوجة": 1,
    "بالقرينة": 1,
    "زوجته": 1,
    "يقتل": 1,
    "بزوجته": 1,
    "للزوجة": 1,
    "لجمهورية": 1,
    "أوزبيكيستان": 1,
    "أوزبكستان": 1,
    "عوزي": 1,
    "رشاش": 1,
    "انتصار": 1,
    "لاتصالات": 1,
    "فرجينيا": 1,
    "شاغر": 1,
    "الشاغرة": 1,
    "الشاغر": 1,
    "الشواغر": 1,
    "اجازة": 1,
    "المصطافون": 1,
    "القاضى": 1,
    "للاجازة": 1,
    "القاضون": 1,
    "الاجازة": 1,
    "التطعيمات": 1,
    "القائمين": 1,
    "اللقاح": 1,
    "للقاح": 1,
    "اللقاحات": 1,
    "فاكسينيوم": 1,
    "المتذبذب": 1,
    "المتذبذبين": 1,
    "المترددين": 1,
    "فاكنا": 1,
    "الريفي.": 1,
    "فراغ": 1,
    "فراغات": 1,
    "بالفراغ": 1,
    "فادا": 1,
    "فادر": 1,
    "فينا": 1,
    "هوى": 1,
    "والعويل": 1,
    "مهبل": 1,
    "مهبلي": 1,
    "المهابل": 1,
    "بغمد": 1,
    "المهبلي": 1,
    "تشرد": 1,
    "المشردون": 1,
    "إبهاما": 1,
    "عب": 1,
    "تتحكم": 1,
    "بالرئتين": 1,
    "والبلعوم": 1,
    "طأطأ": 1,
    "احتراما": 1,
    "خضوعا": 1,
    "المطأطىء": 1,
    "الساتر": 1,
    "تكبرأ": 1,
    "التصلف": 1,
    "عبثا": 1,
    "هندوسية": 1,
    "ممتلك": 1,
    "إندرا": 1,
    "الإلة.": 1,
    "فالديرز": 1,
    "فالديز": 1,
    "فالدوستا": 1,
    "المتفوقون": 1,
    "فالينسيا": 1,
    "الأسبانية": 1,
    "تكافؤ": 1,
    "فاليرا": 1,
    "الفاليريت": 1,
    "فاليري": 1,
    "الفاليرين": 1,
    "معلول": 1,
    "السقيم": 1,
    "الجسارة": 1,
    "فاليانتنيس": 1,
    "فالير": 1,
    "فاليوم": 1,
    "لمهديء": 1,
    "الإسطورية": 1,
    "فاليهو": 1,
    "فاليانت": 1,
    "حاكمة": 1,
    "فرنسية": 1,
    "معدلة": 1,
    "يسعر": 1,
    "الاستثنائية": 1,
    "الفالز": 1,
    "الثمين": 1,
    "نفاسة": 1,
    "الثمينة": 1,
    "الدلالون": 1,
    "فالفاتا": 1,
    "بأجزاء": 1,
    "بصمام": 1,
    "احكام": 1,
    "صمامي": 1,
    "فاليلين": 1,
    "إرحل": 1,
    "راحل": 1,
    "يرحل": 1,
    "للمغادرة": 1,
    "فامبير": 1,
    "مصاص": 1,
    "مصاصو": 1,
    "الرقعات": 1,
    "للدم": 1,
    "فان": 1,
    "فاناديت": 1,
    "فانديك": 1,
    "الفاناديوم": 1,
    "الفانديوم": 1,
    "فانكوفر": 1,
    "فاندا": 1,
    "فانداليا": 1,
    "تخريبي": 1,
    "تهديمي": 1,
    "فاندينبيرج": 1,
    "فاندر": 1,
    "فاندربيلت": 1,
    "فاندربيرغه": 1,
    "فاندربويل": 1,
    "فاندلينج": 1,
    "دوارة": 1,
    "فينيسا": 1,
    "وقاعدة": 1,
    "ونيلين": 1,
    "الآلهة.": 1,
    "احتجب": 1,
    "بزوال": 1,
    "ألشعور": 1,
    "تنبؤ": 1,
    "تكنولوجي": 1,
    "قهار": 1,
    "الفضل": 1,
    "الأفضال": 1,
    "فانواتو": 1,
    "بمواجهة": 1,
    "سليخ": 1,
    "تبخيره": 1,
    "التبخرة": 1,
    "كالحرارة": 1,
    "ألقائم": 1,
    "تبخير": 1,
    "تحويله": 1,
    "بخر": 1,
    "إنتاجه": 1,
    "اضافية": 1,
    "اﻷصلية": 1,
    "الخفق": 1,
    "أسبانية.": 1,
    "ياردة": 1,
    "فاران": 1,
    "العرسة": 1,
    "فاري": 1,
    "ليموري": 1,
    "ألتقلب": 1,
    "فاريان": 1,
    "المغاير": 1,
    "المغايرات": 1,
    "بالتغير": 1,
    "عروق": 1,
    "الملتوية": 1,
    "المتورمة": 1,
    "الدوالي": 1,
    "دوالي": 1,
    "دموية": 1,
    "متورمة": 1,
    "انتفاخات": 1,
    "انتفاخ": 1,
    "بتفاوت": 1,
    "نقش": 1,
    "هائم": 1,
    "يتفاوت": 1,
    "التنويعات": 1,
    "يباين": 1,
    "وملاحظات": 1,
    "ثلة": 1,
    "الأدباء": 1,
    "اختلاف": 1,
    "ألكع": 1,
    "زنيم": 1,
    "فيه.": 1,
    "اللئيم": 1,
    "اللئيمون": 1,
    "فارنا": 1,
    "البلغارية": 1,
    "ألد": 1,
    "ألدهانون": 1,
    "الهندوسي": 1,
    "توثق": 1,
    "باختلاف": 1,
    "الأوردة": 1,
    "الوعائية": 1,
    "الإحتوائية": 1,
    "تمنع": 1,
    "انتاج": 1,
    "المنوية": 1,
    "الفاسيلين": 1,
    "الفازيلين": 1,
    "فاشتي": 1,
    "اسطوانية": 1,
    "انقباض": 1,
    "لتقلص": 1,
    "فاسوبريسين": 1,
    "فاسكويز": 1,
    "فاس": 1,
    "قطيعة": 1,
    "فاسار": 1,
    "شاسع": 1,
    "فاسم": 1,
    "كمثرية": 1,
    "فاتيا": 1,
    "بالنبي": 1,
    "ونبوأته": 1,
    "رسالته": 1,
    "الفاتيكان": 1,
    "بشيئ": 1,
    "وعلم": 1,
    "برميل": 1,
    "اقليم": 1,
    "فود": 1,
    "فآن": 1,
    "المدفن": 1,
    "التقبيب": 1,
    "المدافن": 1,
    "فسيح": 1,
    "متفاخرة": 1,
    "الفسيح": 1,
    "فوكسهول": 1,
    "تشريف": 1,
    "تم": 1,
    "تصنيفه": 1,
    "فيديو": 1,
    "VAX": 1,
    "الهندوس": 1,
    "ف": 1,
    "فيبلين": 1,
    "إنتقال": 1,
    "الموجهات": 1,
    "الفيدا": 1,
    "بالمدرسة": 1,
    "بديانة": 1,
    "فيدرو": 1,
    "نائبوا": 1,
    "فيري": 1,
    "انحراف": 1,
    "فيجا": 1,
    "فيجاس": 1,
    "الخضار": 1,
    "الريجيم": 1,
    "النباتيون": 1,
    "إنبت": 1,
    "ينبت": 1,
    "والإثمار": 1,
    "الخضروات": 1,
    "الشحخص": 1,
    "بمركبة": 1,
    "بالعربات": 1,
    "محجب": 1,
    "البراقع": 1,
    "الأحجبة": 1,
    "بالوريد": 1,
    "أدات": 1,
    "بالعروق": 1,
    "اصدر": 1,
    "الحلقي": 1,
    "فيلاسكويز": 1,
    "حجاب": 1,
    "الفيلكرو": 1,
    "السهب": 1,
    "بأشرعة": 1,
    "يرقية": 1,
    "لحيوان": 1,
    "مصحوبة": 1,
    "بمجهود": 1,
    "لتحقيقها": 1,
    "المخمل": 1,
    "إلتقط": 1,
    "الرقاق": 1,
    "فيلما": 1,
    "فيلوسيرابتور": 1,
    "القطائف": 1,
    "مخملي": 1,
    "المخملي": 1,
    "الفيلفيتليف": 1,
    "مرتش": 1,
    "الإرتشاء": 1,
    "بالصيد": 1,
    "لعرضه": 1,
    "الأوربي": 1,
    "الشهرالأول": 1,
    "الثأر": 1,
    "لكسب": 1,
    "الرزق": 1,
    "الكاسي": 1,
    "موقر": 1,
    "مبجل": 1,
    "والتبجيل": 1,
    "فينيتا": 1,
    "فينيسي": 1,
    "الفينيسيون": 1,
    "فينيتو": 1,
    "فينيزيا": 1,
    "فينزويلا": 1,
    "الفينزويلي": 1,
    "الإنتقامات": 1,
    "طفيف": 1,
    "فينيسيا": 1,
    "إنتقاء": 1,
    "فين": 1,
    "السمومية": 1,
    "المنفس": 1,
    "يهوي": 1,
    "بالتهوية": 1,
    "فينتولين": 1,
    "البطين": 1,
    "البطينات": 1,
    "بطيني": 1,
    "لبعض": 1,
    "التكلم": 1,
    "بالتكلم": 1,
    "فينتشورا": 1,
    "مخاطرون": 1,
    "بمغامرة": 1,
    "بخاخ": 1,
    "بخاخات": 1,
    "شعيرة": 1,
    "الفيبس": 1,
    "الفيبسيان": 1,
    "فيرا": 1,
    "فيراكروز": 1,
    "فيراباميل": 1,
    "فيراترم": 1,
    "شفهية": 1,
    "شفهيات": 1,
    "فصحاء": 1,
    "الشفهية": 1,
    "يفعل": 1,
    "التفعيل": 1,
    "شفهيا": 1,
    "فيربريوم": 1,
    "تعتمد": 1,
    "فيرباسكم": 1,
    "حرفيا": 1,
    "رعي": 1,
    "للكلمات": 1,
    "فيرد": 1,
    "فيردل": 1,
    "فردن": 1,
    "فيردي": 1,
    "الفيردن": 1,
    "فيرداين": 1,
    "لصبغة": 1,
    "فيردن": 1,
    ").": 1,
    "بالخضرة": 1,
    "مخضر": 1,
    "الاتحاد": 1,
    "فيرجاس": 1,
    "فرجينيز": 1,
    "فيرجل": 1,
    "فيريلوج": 1,
    "مخيلة": 1,
    "فيرميل": 1,
    "بالديدان": 1,
    "متموجة": 1,
    "فيرميكيوﻻيت": 1,
    "للدود": 1,
    "الزنجفر": 1,
    "الزفنجر": 1,
    "حشري": 1,
    "فيرن": 1,
    "فيرنا": 1,
    "بلغة": 1,
    "ربيعي": 1,
    "ربيعيا": 1,
    "الورنية": 1,
    "فيرنون": 1,
    "فيرنونيا": 1,
    "فيرونا": 1,
    "فيرونال": 1,
    "لمنوم": 1,
    "فيرونيكا": 1,
    "الفيرونيكا": 1,
    "فيرساي": 1,
    "إنحدار": 1,
    "فيرساتيك": 1,
    "الاستخدامات": 1,
    "أشعار": 1,
    "منظوم": 1,
    "إنظم": 1,
    "النظم": 1,
    "الفرست": 1,
    "فقاري": 1,
    "الفقريات": 1,
    "فيرفين": 1,
    "النشاطات": 1,
    "الفرفت": 1,
    "معايير": 1,
    "فيسبيوم": 1,
    "بالمثانة": 1,
    "منفط": 1,
    "منفطات": 1,
    "فيسيكاريا": 1,
    "بثر": 1,
    "مبثر": 1,
    "يبثر": 1,
    "التبثير": 1,
    "بثري": 1,
    "فيسبا": 1,
    "نجمة": 1,
    "فيسبكي": 1,
    "دهليزي": 1,
    "lمتعلق": 1,
    "بالدهليز": 1,
    "الدهليز": 1,
    "دهليز": 1,
    "الدهاليز": 1,
    "مجالس": 1,
    "الصداري": 1,
    "فيسفيس": 1,
    "بركان": 1,
    "البيطري": 1,
    "فيتال": 1,
    "الجلبان": 1,
    "الجلبانات": 1,
    "بيطري": 1,
    "بيطريون": 1,
    "البيطريون": 1,
    "فيتيفر": 1,
    "الفيتو": 1,
    "أغظ": 1,
    "يغيظ": 1,
    "الألوية": 1,
    "باللواء": 1,
    "فيو": 1,
    "لنظام": 1,
    "MSDOS": 1,
    "مستعملة": 1,
    "لشكل": 1,
    "المداراة": 1,
    "فايان": 1,
    "مؤنة": 1,
    "مؤن": 1,
    "بالطرقات": 1,
    "بالرحلة": 1,
    "بوليصات": 1,
    "التامين": 1,
    "المصابين": 1,
    "إيقاعية": 1,
    "بقطع": 1,
    "الفيبرافون": 1,
    "الفيبرافونات": 1,
    "إهتزاز": 1,
    "الإهتزازة": 1,
    "تهديج": 1,
    "إهتزازي": 1,
    "الضمة": 1,
    "الضمات": 1,
    "الويبرنوم": 1,
    "فيك": 1,
    "كنائب": 1,
    "بنائب": 1,
    "قاسي,": 1,
    "نائبي": 1,
    "نواب": 1,
    "فيشي": 1,
    "فيسي": 1,
    "فيسيا": 1,
    "جوار": 1,
    "بالقرب": 1,
    "فايكرز": 1,
    "فيكاري": 1,
    "فيكي": 1,
    "فيكسبيرغ": 1,
    "الفيكونت": 1,
    "ضحايا": 1,
    "ضحى": 1,
    "مضحي": 1,
    "المنتصر": 1,
    "فيكتوريا": 1,
    "الفيكتوري": 1,
    "أشياء": 1,
    "الفيكتوريون": 1,
    "النصر": 1,
    "المنتصرون": 1,
    "فيكترولا": 1,
    "المؤونة": 1,
    "ممونون": 1,
    "والمؤن": 1,
    "الطعام,": 1,
    "فيدا": 1,
    "فيدال": 1,
    "فيداليا": 1,
    "لشريط": 1,
    "الفيديوات": 1,
    "الفديو": 1,
    "النصي": 1,
    "فيدور": 1,
    "أرمل": 1,
    "الأرمل": 1,
    "المفجوع": 1,
    "الترمل": 1,
    "نافس": 1,
    "وترية": 1,
    "فينتيان": 1,
    "فيير": 1,
    "ينافس": 1,
    "فيتنام": 1,
    "الفيتناميون": 1,
    "للمشاهدة": 1,
    "فيوجراف": 1,
    "مبدي": 1,
    "الراي": 1,
    "غريبة": 1,
    "بإحتراس": 1,
    "فيجنا": 1,
    "المقاﻻت": 1,
    "فيجو": 1,
    "الفايكنك": 1,
    "الفايكنكز": 1,
    "فانوتو": 1,
    "فيلاس": 1,
    "افترائي": 1,
    "حقارة": 1,
    "مذموم": 1,
    "للسمعة": 1,
    "يذم": 1,
    "ذم": 1,
    "الذم": 1,
    "يستخفيزدري": 1,
    "الفيللا": 1,
    "الخباثة": 1,
    "خسيس": 1,
    "فيلانوفا": 1,
    "فيلارد": 1,
    "الفيلات": 1,
    "زغبي": 1,
    "فيلنا": 1,
    "والمدينة": 1,
    "ليثوانيا": 1,
    "فيلنيوس": 1,
    "فيميناريا": 1,
    "شجيرات": 1,
    "أسترالية": 1,
    "مورقة": 1,
    "بالنبيذ": 1,
    "فينكا": 1,
    "فينس": 1,
    "فينسينز": 1,
    "فنسينت": 1,
    "قهره": 1,
    "التبريئات": 1,
    "الكرمة": 1,
    "لايقوى": 1,
    "ويعتمد": 1,
    "زارع": 1,
    "الكرمات": 1,
    "مزارع": 1,
    "بالخمر": 1,
    "زراعة": 1,
    "كالكرمة": 1,
    "فينينجز": 1,
    "مخمور": 1,
    "فينوس": 1,
    "فينسن": 1,
    "محصول": 1,
    "الخمارون": 1,
    "الفينيل": 1,
    "الفيول": 1,
    "مةسيقية": 1,
    "للإنتهاك": 1,
    "انتهاكي": 1,
    "الكمانات": 1,
    "إنتهاكي": 1,
    "المنتهكون": 1,
    "البنفسج": 1,
    "فيولون": 1,
    "هامة": 1,
    "الخبيثة": 1,
    "فيروسي": 1,
    "النشاب": 1,
    "فيرو": 1,
    "العذراء": 1,
    "فيرجيني": 1,
    "العذارى": 1,
    "المائلة": 1,
    "المعدي": 1,
    "الفيروسات": 1,
    "مقززة": 1,
    "التحف": 1,
    "المزية": 1,
    "المزايا": 1,
    "الموهوبون": 1,
    "الموهوب": 1,
    "فتاك": 1,
    "لوجه": 1,
    "التأشيرة": 1,
    "يعطى": 1,
    "تأشيرة": 1,
    "المحيا": 1,
    "فايسليا": 1,
    "التأشيرات": 1,
    "مطاطية": 1,
    "أرملة": 1,
    "الفيكونتات": 1,
    "فيسيد": 1,
    "كالملزمة": 1,
    "فيشنو": 1,
    "المرئية": 1,
    "الغربيون": 1,
    "للزيارة": 1,
    "بالنظر": 1,
    "المنك": 1,
    "كمشهد": 1,
    "فيستلا": 1,
    "البصرية": 1,
    "متخيلون": 1,
    "المرئيات": 1,
    "الأعناب": 1,
    "الفيتامين": 1,
    "بالفيتامينات": 1,
    "الفيتامينات": 1,
    "الفيتالين": 1,
    "مح": 1,
    "فيتو": 1,
    "زجاجي": 1,
    "تزجيج": 1,
    "زجج": 1,
    "الفترينة": 1,
    "المحال": 1,
    "يلذع": 1,
    "بماء": 1,
    "فيترو": 1,
    "بالمعماري": 1,
    "فيترفيس": 1,
    "ملونة": 1,
    "انتقادي": 1,
    "فيتاس": 1,
    "فيفالدي": 1,
    "المحميات": 1,
    "المقدد": 1,
    "فليحيى": 1,
    "فيفيرين": 1,
    "فيفيان": 1,
    "إفعام": 1,
    "بالحيوية": 1,
    "محيي": 1,
    "الولودية": 1,
    "ولود": 1,
    "ولودي": 1,
    "فيفو": 1,
    "الثعلبة": 1,
    "فييلا": 1,
    "لقماش": 1,
    "فيزلا": 1,
    "للوجه": 1,
    "فلاديمير": 1,
    "فلاديفوستوك": 1,
    "اندماج": 1,
    "فيزا": 1,
    "كهتز": 1,
    "فليتس": 1,
    "الإفتراضية": 1,
    "فوكا": 1,
    "اللفظة": 1,
    "لفظي": 1,
    "معتل": 1,
    "غناء": 1,
    "ناطق": 1,
    "ندائي": 1,
    "ندائيات": 1,
    "الفودكا": 1,
    "فودون": 1,
    "الكاريبية": 1,
    "فوجل": 1,
    "الرواج": 1,
    "متماشي": 1,
    "بالصوت": 1,
    "مصوت": 1,
    "مصوتون": 1,
    "المبرم": 1,
    "فويلا": 1,
    "الفوال": 1,
    "الرشيق": 1,
    "وسريع": 1,
    "مبنية": 1,
    "أخمصي": 1,
    "متطايرة": 1,
    "التطايرية": 1,
    "تطيير": 1,
    "الفولجا": 1,
    "فولين": 1,
    "للتطاير": 1,
    "الإرادات": 1,
    "فولكسواجين": 1,
    "ﻻعب": 1,
    "السوفييتي": 1,
    "فولستد": 1,
    "فولتا": 1,
    "الفولطية": 1,
    "فولطية": 1,
    "الفولطيات": 1,
    "فولطي": 1,
    "فولتاير": 1,
    "الكلفانية": 1,
    "بالتفاعل": 1,
    "فولتيرا": 1,
    "الفولتميتر": 1,
    "الفولتميترات": 1,
    "الفولتات": 1,
    "طلق": 1,
    "بمجلدات": 1,
    "حجمي": 1,
    "التحجيم": 1,
    "الطوعية": 1,
    "التطوعية": 1,
    "المتطوع": 1,
    "تطوع": 1,
    "التطوع": 1,
    "المتطوعون": 1,
    "فولفو": 1,
    "فولفوكس": 1,
    "طحالب": 1,
    "عذبة": 1,
    "وانسدادها": 1,
    "المهجن": 1,
    "لفرق": 1,
    "والمقاومة": 1,
    "الأنفية": 1,
    "بالعظمة": 1,
    "القيئ": 1,
    "للتقيء": 1,
    "للتقيؤ": 1,
    "فون": 1,
    "فونا": 1,
    "الودونية": 1,
    "الدردورية": 1,
    "دوراني": 1,
    "نذري": 1,
    "النذرية": 1,
    "إكفل": 1,
    "مكفول": 1,
    "مستند": 1,
    "يكفل": 1,
    "الكفل": 1,
    "الوصل": 1,
    "فوغت": 1,
    "الناذر": 1,
    "بنقل": 1,
    "فويال": 1,
    "التلصص": 1,
    "بالتلصص": 1,
    "مختلسوا": 1,
    "فريلاند": 1,
    "اصطناعية": 1,
    "HP": 1,
    "فولكان": 1,
    "يبركن": 1,
    "المبتذﻻت": 1,
    "السوقية": 1,
    "إبتذل": 1,
    "يبتذل": 1,
    "ثعلبي": 1,
    "العقبان": 1,
    "نسراني": 1,
    "الفرج": 1,
    "فولفا": 1,
    "بالفرج": 1,
    "فولفار": 1,
    "الإفتراضي": 1,
    "الأفقي": 1,
    "إبتدعته": 1,
    "زاناكس": 1,
    "للقلق": 1,
    "الزنتات": 1,
    "الزانتك": 1,
    "فاتحة": 1,
    "زانثوما": 1,
    "صفراء": 1,
    "أمحاح": 1,
    "زانثس": 1,
    "خافير": 1,
    "للنوافذ": 1,
    "لبرامج": 1,
    "اختراق": 1,
    "الزينون.": 1,
    "صواري": 1,
    "زينيا": 1,
    "زينويكس": 1,
    "للضيوف": 1,
    "ضيافة": 1,
    "ينبغي": 1,
    "الإكسينون": 1,
    "للأجانب": 1,
    "لمركبات": 1,
    "زينويليك": 1,
    "الشريف": 1,
    "بالتصوير": 1,
    "الجاف": 1,
    "صوم": 1,
    "المسيحيين": 1,
    "الزيروفيل": 1,
    "موطن": 1,
    "الزيروفيللم": 1,
    "الصحراوي": 1,
    "يصلح": 1,
    "الصحراوية": 1,
    "زيرس": 1,
    "زركسيس": 1,
    "الزوشا": 1,
    "زيان": 1,
    "زيميان": 1,
    "زينيراما": 1,
    "ابو": 1,
    "لعظمة": 1,
    "أل": 1,
    "مودم": 1,
    "الأشعات": 1,
    "بالإطلاع": 1,
    "تيرم": 1,
    "X": 1,
    "الزيلان": 1,
    "لزجة": 1,
    "الزيليم": 1,
    "الزيلين": 1,
    "زيلينول": 1,
    "زيلوكين": 1,
    "لمخدر": 1,
    "موضعي": 1,
    "زيلوكوباين": 1,
    "الناشئ": 1,
    "للخشب": 1,
    "الزيلوميلم": 1,
    "الإسترالية": 1,
    "زيلوفيلان": 1,
    "تعيش": 1,
    "الاكسليفون": 1,
    "الزيلوز": 1,
    "للدراسة": 1,
    "للتمارين": 1,
    "لكشط": 1,
    "واي": 1,
    "مجهول.": 1,
    "للأفعال": 1,
    "الصادي": 1,
    "ص": 1,
    "ياكير": 1,
    "تمساح": 1,
    "ياك": 1,
    "اليخت": 1,
    "باليخت": 1,
    "اليخوت": 1,
    "مالكة": 1,
    "ياكولت": 1,
    "يافل": 1,
    "ييدجر": 1,
    "ياجي": 1,
    "متوحشون": 1,
    "بشرية": 1,
    "بالعبرية": 1,
    "ياكيما": 1,
    "ياكين": 1,
    "ياكت": 1,
    "ييل": 1,
    "يالتا": 1,
    "اليالو": 1,
    "ياما": 1,
    "ياماها": 1,
    "رسميي": 1,
    "اليامب": 1,
    "يانسي": 1,
    "الفحولة": 1,
    "يانجون": 1,
    "اليانجتز": 1,
    "انتزع": 1,
    "أمريكيون": 1,
    "الانتزاع": 1,
    "يانكتون": 1,
    "ياوندي": 1,
    "الياورت": 1,
    "الأوبوسوم": 1,
    "اليابون": 1,
    "ياكي": 1,
    "الصارية": 1,
    "بالساحة": 1,
    "ياردي": 1,
    "إجرامية": 1,
    "جامايكيا": 1,
    "الساحات": 1,
    "بجاهزية": 1,
    "يارك": 1,
    "يلبسها": 1,
    "المتدينون": 1,
    "يارماوث": 1,
    "خيوط": 1,
    "تجديل": 1,
    "للزمجرة": 1,
    "كالكلب": 1,
    "اليارو": 1,
    "البرقع": 1,
    "اليطقان": 1,
    "بشفرة": 1,
    "ييتس": 1,
    "يستغيث": 1,
    "المستغيث": 1,
    "اليوتيا": 1,
    "انحرف": 1,
    "تثاءب": 1,
    "يونر": 1,
    "بتثاؤب": 1,
    "الجلدي": 1,
    "ياي": 1,
    "التايلندية": 1,
    "الإيتربيوم": 1,
    "أسمى": 1,
    "ييغر": 1,
    "تلد": 1,
    "قامت": 1,
    "يارا": 1,
    "بلوط": 1,
    "اشتق": 1,
    "اشتاق": 1,
    "المشتاق": 1,
    "متمنيا": 1,
    "الأشواق": 1,
    "السنوات": 1,
    "اختمارا": 1,
    "الاختمار": 1,
    "ياتس": 1,
    "يكه": 1,
    "يدو": 1,
    "اليابان.": 1,
    "مرادفة": 1,
    "لطوكيو": 1,
    "اصفر": 1,
    "اصفرارا": 1,
    "يلوفين": 1,
    "اليلوهامر": 1,
    "الاصفرار": 1,
    "يلو": 1,
    "نايف": 1,
    "اليمن": 1,
    "اليمني": 1,
    "الين": 1,
    "الينات": 1,
    "النمامة": 1,
    "اليومن": 1,
    "اليربا": 1,
    "الرفسة": 1,
    "يركس": 1,
    "اليشيفا": 1,
    "يسو": 1,
    "بالأمس": 1,
    "أمس": 1,
    "الأمس": 1,
    "اليتي": 1,
    "انه": 1,
    "الهيمالايا": 1,
    "يتر": 1,
    "اليو": 1,
    "يزو": 1,
    "راح": 1,
    "يهفه": 1,
    "العبرية": 1,
    "يي": 1,
    "كورية": 1,
    "يهود": 1,
    "استسلم": 1,
    "الاستسلام": 1,
    "بإستسلام": 1,
    "يجو": 1,
    "انبح": 1,
    "ينبح": 1,
    "يمير": 1,
    "اسطوري": 1,
    "استمر": 1,
    "البكائية": 1,
    "تغنى": 1,
    "البكائي": 1,
    "البكائيون": 1,
    "التغني": 1,
    "بكائيا": 1,
    "يودر": 1,
    "يود": 1,
    "اليوجا": 1,
    "الزبادي": 1,
    "برياضة": 1,
    "صرخة": 1,
    "تشجيع": 1,
    "لكلاب": 1,
    "يرتبط": 1,
    "يزدوج": 1,
    "عبودية": 1,
    "لفلسفة": 1,
    "يوكناباتوفا": 1,
    "ميسيسيبي": 1,
    "يوكوهاما": 1,
    "يوكتس": 1,
    "المح": 1,
    "الأمحاح": 1,
    "عبرية": 1,
    "يونا": 1,
    "يونكرس": 1,
    "يوريك": 1,
    "يوركر": 1,
    "يوركس": 1,
    "يوركشر": 1,
    "يوركتاون": 1,
    "اليوروبي": 1,
    "اليوروبا": 1,
    "يوسامتي": 1,
    "يوست": 1,
    "يوتابيت": 1,
    "بصغر": 1,
    "يونغز": 1,
    "يانجز": 1,
    "اليوبون": 1,
    "ملكك": 1,
    "أنفسكما": 1,
    "التشيتاه": 1,
    "يوو": 1,
    "عوى": 1,
    "اليويو": 1,
    "إبرس": 1,
    "إبسيﻻنتي": 1,
    "يريكا": 1,
    "الإيتريوم": 1,
    "يوغسلافيا": 1,
    "يوان": 1,
    "للصين": 1,
    "يوبا": 1,
    "يوكاتان": 1,
    "اليوكا": 1,
    "يك": 1,
    "يوين": 1,
    "اليوغا": 1,
    "حقبة": 1,
    "اليوغسلافي": 1,
    "يوغسلافي": 1,
    "الاشمئزاز": 1,
    "يوكي": 1,
    "اليولان": 1,
    "ميلاد": 1,
    "اليومان": 1,
    "الألذ": 1,
    "يونان": 1,
    "يوري": 1,
    "يوتان": 1,
    "إيفز": 1,
    "يفات": 1,
    "المسيحيات": 1,
    "الأغلب": 1,
    "المشهورة": 1,
    "الزابليونية": 1,
    "الحلويات)": 1,
    "زابيولون": 1,
    "زكريا": 1,
    "زاكاري": 1,
    "الزعفر": 1,
    "(أكسيد": 1,
    "الكوبالت)": 1,
    "ممتلىء": 1,
    "تعريج": 1,
    "زغرب": 1,
    "متعرجة": 1,
    "زاهل": 1,
    "زائير": 1,
    "زاليسكي": 1,
    "زاما": 1,
    "زامان": 1,
    "زامبيزي": 1,
    "أبوان": 1,
    "وزنجية": 1,
    "الزاميا": 1,
    "الزمندارية": 1,
    "لمالك": 1,
    "زامورا": 1,
    "زان": 1,
    "الزندر": 1,
    "إضحاكا": 1,
    "تملقا": 1,
    "المضحكون": 1,
    "هزلا": 1,
    "تزلفا": 1,
    "تهريجي": 1,
    "الإضحاك": 1,
    "زانوني": 1,
    "زانت": 1,
    "زنزبار": 1,
    "انطلق": 1,
    "زاباتا": 1,
    "مكسيكي": 1,
    "زاراثسترا": 1,
    "الزرادشتية": 1,
    "زاراتايت": 1,
    "لصهر": 1,
    "ممسكة": 1,
    "لقدح": 1,
    "الساخن": 1,
    "الزارث": 1,
    "الزرزويلة": 1,
    "هزلية": 1,
    "زوسكنيريا": 1,
    "لإصلاح": 1,
    "أسقف": 1,
    "زازن": 1,
    "(التأمل": 1,
    "البوذي)": 1,
    "زيلاند": 1,
    "الزيلوت": 1,
    "تعصب": 1,
    "حماسة": 1,
    "شراعي": 1,
    "أبناءه": 1,
    "يسوع": 1,
    "العتابي": 1,
    "حمار": 1,
    "الزرد": 1,
    "بالحمار": 1,
    "بحمار": 1,
    "الدرباني": 1,
    "ثدي": 1,
    "البقرية": 1,
    "ضارة": 1,
    "تسي": 1,
    "زيبيولون": 1,
    "ليا": 1,
    "ثدية": 1,
    "زخرفي": 1,
    "يخاط": 1,
    "Z": 1,
    "زيمان": 1,
    "زيفيريلي": 1,
    "زيهنر": 1,
    "فضية": 1,
    "نمساوية": 1,
    "زيايدي": 1,
    "زيجلر": 1,
    "الزين": 1,
    "يأتى": 1,
    "زييس": 1,
    "زيل": 1,
    "زيليرباتش": 1,
    "زيمني": 1,
    "الأعمى": 1,
    "زينا": 1,
    "لتجمع": 1,
    "فارسية": 1,
    "للديانة": 1,
    "زيندا": 1,
    "بالأوج": 1,
    "زينو": 1,
    "الزيوليت": 1,
    "زيونا": 1,
    "زيفانياه": 1,
    "نبي": 1,
    "زيفيرهيلس": 1,
    "للأس": 1,
    "الفنك": 1,
    "بالأصفار": 1,
    "زيربابل": 1,
    "عيزرا": 1,
    "نكهة": 1,
    "حريف": 1,
    "زيستريل": 1,
    "لتخفيض": 1,
    "الزيتا": 1,
    "فروع": 1,
    "الف": 1,
    "موقعين": 1,
    "كأن": 1,
    "اخذ": 1,
    "كتابه": 1,
    "وإجازة": 1,
    "زوس": 1,
    "زيزيرا": 1,
    "زيبيت": 1,
    "صيدا": 1,
    "زيجفيلد": 1,
    "منعرج": 1,
    "زيكلاج": 1,
    "اللاشيء": 1,
    "أجراس": 1,
    "تستعملها": 1,
    "الشرقيات": 1,
    "زيلا": 1,
    "والكبير": 1,
    "والكبيرة": 1,
    "زيلوج": 1,
    "منتجة": 1,
    "لمعالجات": 1,
    "زيم": 1,
    "وسامة": 1,
    "زيمبابوي": 1,
    "زيمبابواي": 1,
    "زيميرمان": 1,
    "زنكي": 1,
    "الطﻻء": 1,
    "بالزنك": 1,
    "الأنود": 1,
    "القفشة": 1,
    "الزينية": 1,
    "صهيون": 1,
    "الصهيونية": 1,
    "الصهيوني": 1,
    "الصهاينة": 1,
    "ألصهيونيون": 1,
    "زيور": 1,
    "الكود": 1,
    "سوستة": 1,
    "السوستة": 1,
    "انغلق": 1,
    "الزركون": 1,
    "الزركونات": 1,
    "زركوني": 1,
    "الزركونيوم": 1,
    "احتواء": 1,
    "المكرونة": 1,
    "ترمز": 1,
    "ال613": 1,
    "الزلوتي": 1,
    "زور": 1,
    "زوبو": 1,
    "الجدار": 1,
    "البروج": 1,
    "زوي": 1,
    "زويتروب": 1,
    "داخلها": 1,
    "بعالم": 1,
    "زويلس": 1,
    "وحاسد": 1,
    "الإقلال": 1,
    "الزوسيت": 1,
    "زوكور": 1,
    "زولا": 1,
    "جمركي": 1,
    "زومبا": 1,
    "أعيدت": 1,
    "لبسه": 1,
    "واليهود": 1,
    "لتمييزهم": 1,
    "بمنطقة": 1,
    "للحيوانات": 1,
    "أشكالهم": 1,
    "عاداتهم": 1,
    "داخله": 1,
    "زوك": 1,
    "طبقا": 1,
    "الحيواني": 1,
    "الحيوانيون": 1,
    "بتأليه": 1,
    "تأليه": 1,
    "للحوم": 1,
    "الفقارية": 1,
    "الزوفيت": 1,
    "البلانكتونية": 1,
    "زووزو": 1,
    "زوبل": 1,
    "نعال": 1,
    "زوريل": 1,
    "زورك": 1,
    "زرادشت": 1,
    "زرادشتي": 1,
    "زوروبابل": 1,
    "للملك": 1,
    "جيهوياتشين": 1,
    "زوستيرا": 1,
    "زوفيراكس": 1,
    "للفيروس": 1,
    "زويسيا": 1,
    "للأوامر": 1,
    "يونكس": 1,
    "زولو": 1,
    "المتواجدون": 1,
    "بزيمبابوي": 1,
    "زيوريخ": 1,
    "بحياة": 1,
    "زوينجلي": 1,
    "بأرجل": 1,
    "وجني": 1,
    "للإنقسام": 1,
    "لنصفين": 1,
    "متساويين": 1,
    "موسمية": 1,
    "الملقحة": 1,
    "زيلوبريم": 1,
    "الحمضية": 1,
    "زيماس": 1,
    "أنزيمي": 1,
    "الهياج": 1,
    "بالهياج": 1,
    "الأنزيمية": 1,
    "تفتيت": 1,
    "بسيطة": 1,
    "وانتشار": 1,
    "للإلتهاب": 1,
    "لمشروب": 1,
    "آباءكم": 1,
    "آباءنا": 1,
    "آباءهم": 1,
    "آباؤكم": 1,
    "آباؤنا": 1,
    "آباؤهم": 1,
    "آبائك": 1,
    "آبائكم": 1,
    "آبائنا": 1,
    "آبائهم": 1,
    "آبائهن": 1,
    "آبائي": 1,
    "آتاك": 1,
    "آتاكم": 1,
    "آتانا": 1,
    "آتاني": 1,
    "آتاه": 1,
    "آتاها": 1,
    "آتاهم": 1,
    "آتاهما": 1,
    "آتت": 1,
    "آتنا": 1,
    "آتهم": 1,
    "آتوا": 1,
    "آتوني": 1,
    "آتوه": 1,
    "آتي": 1,
    "آتية": 1,
    "آتيت": 1,
    "آتيتك": 1,
    "آتيتكم": 1,
    "آتيتم": 1,
    "آتيتموهن": 1,
    "آتيتنا": 1,
    "آتيتني": 1,
    "آتيتهن": 1,
    "آتيك": 1,
    "آتيكم": 1,
    "آتينا": 1,
    "آتيناك": 1,
    "آتيناكم": 1,
    "آتيناه": 1,
    "آتيناها": 1,
    "آتيناهم": 1,
    "آتيه": 1,
    "آتيهم": 1,
    "آثارهم": 1,
    "آثارهما": 1,
    "آثرك": 1,
    "آثم": 1,
    "آثما": 1,
    "آخذ": 1,
    "آخذين": 1,
    "آخران": 1,
    "آخرون": 1,
    "آخرين": 1,
    "آذاننا": 1,
    "آذانهم": 1,
    "آذن": 1,
    "آذناك": 1,
    "آذنتكم": 1,
    "آذوا": 1,
    "آذيتمونا": 1,
    "آزر": 1,
    "آسفونا": 1,
    "آسن": 1,
    "آسى": 1,
    "آلآن": 1,
    "آلاء": 1,
    "آلاف": 1,
    "آلذكرين": 1,
    "آلله": 1,
    "آلهتكم": 1,
    "آلهتنا": 1,
    "آلهتهم": 1,
    "آلهتي": 1,
    "آمره": 1,
    "آمنا": 1,
    "آمنت": 1,
    "آمنتم": 1,
    "آمنكم": 1,
    "آمنوا": 1,
    "آمنون": 1,
    "آمنين": 1,
    "آن": 1,
    "آناء": 1,
    "آنس": 1,
    "آنست": 1,
    "آنستم": 1,
    "آنفا": 1,
    "آنية": 1,
    "آووا": 1,
    "آوي": 1,
    "آيات": 1,
    "آياتك": 1,
    "آياتنا": 1,
    "آياته": 1,
    "آياتها": 1,
    "آياتي": 1,
    "آية": 1,
    "آيتك": 1,
    "آيتين": 1,
    "أآلهتنا": 1,
    "أأتخذ": 1,
    "أأرباب": 1,
    "أأسجد": 1,
    "أأسلمتم": 1,
    "أأشفقتم": 1,
    "أأشكر": 1,
    "أأعجمي": 1,
    "أأقررتم": 1,
    "أألد": 1,
    "أألقي": 1,
    "أأمنتم": 1,
    "أأنت": 1,
    "أأنتم": 1,
    "أأنذرتهم": 1,
    "أأنزل": 1,
    "أؤنبئكم": 1,
    "أإذا": 1,
    "أإله": 1,
    "أإنا": 1,
    "أإنك": 1,
    "أئذا": 1,
    "أئفكا": 1,
    "أئمة": 1,
    "أئن": 1,
    "أئنا": 1,
    "أئنكم": 1,
    "أبابيل": 1,
    "أباكم": 1,
    "أبالله": 1,
    "أبانا": 1,
    "أباه": 1,
    "أباهم": 1,
    "أبت": 1,
    "أبتغي": 1,
    "أبدله": 1,
    "أبرئ": 1,
    "أبرح": 1,
    "أبرموا": 1,
    "أبسلوا": 1,
    "أبشر": 1,
    "أبشرا": 1,
    "أبشرتموني": 1,
    "أبصار": 1,
    "أبصاركم": 1,
    "أبصارنا": 1,
    "أبصارها": 1,
    "أبصارهم": 1,
    "أبصارهن": 1,
    "أبصر": 1,
    "أبصرنا": 1,
    "أبعث": 1,
    "أبغي": 1,
    "أبغيكم": 1,
    "أبكارا": 1,
    "أبكم": 1,
    "أبلغتكم": 1,
    "أبلغكم": 1,
    "أبلغه": 1,
    "أبلغوا": 1,
    "أبناءكم": 1,
    "أبناءنا": 1,
    "أبناءهم": 1,
    "أبنائكم": 1,
    "أبنائهن": 1,
    "أبوابا": 1,
    "أبوابها": 1,
    "أبواه": 1,
    "أبوك": 1,
    "أبوهم": 1,
    "أبوهما": 1,
    "أبويك": 1,
    "أبويكم": 1,
    "أبويه": 1,
    "أبى": 1,
    "أبيكم": 1,
    "أبينا": 1,
    "أبيهم": 1,
    "أتأتون": 1,
    "أتأخذونه": 1,
    "أتأمرون": 1,
    "أتاك": 1,
    "أتاكم": 1,
    "أتانا": 1,
    "أتاها": 1,
    "أتاهم": 1,
    "أتبع": 1,
    "أتبعك": 1,
    "أتبعه": 1,
    "أتبنون": 1,
    "أتت": 1,
    "أتتخذ": 1,
    "أتتخذنا": 1,
    "أتتركون": 1,
    "أتتك": 1,
    "أتتكم": 1,
    "أتتهم": 1,
    "أتجادلونني": 1,
    "أتجعل": 1,
    "أتحاجوننا": 1,
    "أتحاجوني": 1,
    "أتحدثونهم": 1,
    "أتخذ": 1,
    "أتخذتم": 1,
    "أتخذناهم": 1,
    "أتخشونهم": 1,
    "أتدعون": 1,
    "أتذر": 1,
    "أتراب": 1,
    "أترابا": 1,
    "أترفتم": 1,
    "أترفوا": 1,
    "أتريد": 1,
    "أتريدون": 1,
    "أتستبدلون": 1,
    "أتصبرون": 1,
    "أتعبدون": 1,
    "أتعجبين": 1,
    "أتعدانني": 1,
    "أتعلمون": 1,
    "أتقاكم": 1,
    "أتقتلون": 1,
    "أتقن": 1,
    "أتقولون": 1,
    "أتل": 1,
    "أتلو": 1,
    "أتمدونن": 1,
    "أتممت": 1,
    "أتمها": 1,
    "أتموا": 1,
    "أتنبئون": 1,
    "أتنهانا": 1,
    "أتهتدي": 1,
    "أتهلكنا": 1,
    "أتوا": 1,
    "أتواصوا": 1,
    "أتوب": 1,
    "أتوك": 1,
    "أتوكأ": 1,
    "أتوه": 1,
    "أتيا": 1,
    "أتيت": 1,
    "أتين": 1,
    "أتينا": 1,
    "أتيناهم": 1,
    "أثاثا": 1,
    "أثارة": 1,
    "أثاما": 1,
    "أثخنتموهم": 1,
    "أثقالكم": 1,
    "أثقالها": 1,
    "أثقالهم": 1,
    "أثقلت": 1,
    "أثم": 1,
    "أثيما": 1,
    "أجئتنا": 1,
    "أجاج": 1,
    "أجاجا": 1,
    "أجبتم": 1,
    "أجد": 1,
    "أجرا": 1,
    "أجرمنا": 1,
    "أجرموا": 1,
    "أجره": 1,
    "أجرها": 1,
    "أجرهم": 1,
    "أجزعنا": 1,
    "أجسامهم": 1,
    "أجعل": 1,
    "أجعلتم": 1,
    "أجعلنا": 1,
    "أجلا": 1,
    "أجلت": 1,
    "أجلنا": 1,
    "أجله": 1,
    "أجلها": 1,
    "أجلهم": 1,
    "أجلهن": 1,
    "أجمعوا": 1,
    "أجمعون": 1,
    "أجمعين": 1,
    "أجنة": 1,
    "أجوركم": 1,
    "أجورهم": 1,
    "أجورهن": 1,
    "أجيبت": 1,
    "أجيبوا": 1,
    "أحاط": 1,
    "أحبارهم": 1,
    "أحببت": 1,
    "أحدا": 1,
    "أحدكم": 1,
    "أحدكما": 1,
    "أحدنا": 1,
    "أحدهم": 1,
    "أحدهما": 1,
    "أحرص": 1,
    "أحسب": 1,
    "أحسن": 1,
    "أحسنتم": 1,
    "أحسنه": 1,
    "أحسنوا": 1,
    "أحسوا": 1,
    "أحصاه": 1,
    "أحصاها": 1,
    "أحصاهم": 1,
    "أحصرتم": 1,
    "أحصروا": 1,
    "أحصن": 1,
    "أحصنت": 1,
    "أحصيناه": 1,
    "أحضرت": 1,
    "أحطت": 1,
    "أحطنا": 1,
    "أحق": 1,
    "أحقابا": 1,
    "أحكم": 1,
    "أحكمت": 1,
    "أحلامهم": 1,
    "أحلت": 1,
    "أحللنا": 1,
    "أحلنا": 1,
    "أحمل": 1,
    "أحملكم": 1,
    "أحوى": 1,
    "أحياء": 1,
    "أحياكم": 1,
    "أحياها": 1,
    "أحياهم": 1,
    "أحيي": 1,
    "أحييناها": 1,
    "أخا": 1,
    "أخاف": 1,
    "أخالفكم": 1,
    "أخانا": 1,
    "أخاه": 1,
    "أخاهم": 1,
    "أخباركم": 1,
    "أخبارها": 1,
    "أختك": 1,
    "أختها": 1,
    "أخدان": 1,
    "أخذا": 1,
    "أخذة": 1,
    "أخذت": 1,
    "أخذتم": 1,
    "أخذته": 1,
    "أخذتها": 1,
    "أخذتهم": 1,
    "أخذنا": 1,
    "أخذناهم": 1,
    "أخذوا": 1,
    "أخر": 1,
    "أخراكم": 1,
    "أخراهم": 1,
    "أخرتن": 1,
    "أخرتنا": 1,
    "أخرتني": 1,
    "أخرجت": 1,
    "أخرجتك": 1,
    "أخرجتم": 1,
    "أخرجك": 1,
    "أخرجكم": 1,
    "أخرجنا": 1,
    "أخرجني": 1,
    "أخرجه": 1,
    "أخرجوا": 1,
    "أخرجوكم": 1,
    "أخرجوهم": 1,
    "أخرقتها": 1,
    "أخرنا": 1,
    "أخزى": 1,
    "أخزيته": 1,
    "أخطأتم": 1,
    "أخطأنا": 1,
    "أخفي": 1,
    "أخفيتم": 1,
    "أخفيها": 1,
    "أخلد": 1,
    "أخلده": 1,
    "أخلصناهم": 1,
    "أخلفنا": 1,
    "أخلفوا": 1,
    "أخلق": 1,
    "أخنه": 1,
    "أخواتكم": 1,
    "أخواتهن": 1,
    "أخوالكم": 1,
    "أخوك": 1,
    "أخوهم": 1,
    "أخويكم": 1,
    "أخي": 1,
    "أخيه": 1,
    "أدباركم": 1,
    "أدبارها": 1,
    "أدبارهم": 1,
    "أدبر": 1,
    "أدخلني": 1,
    "أدخلوا": 1,
    "أدراك": 1,
    "أدراكم": 1,
    "أدركه": 1,
    "أدري": 1,
    "أدعو": 1,
    "أدعوتموهم": 1,
    "أدعوكم": 1,
    "أدعياءكم": 1,
    "أدعيائهم": 1,
    "أدلك": 1,
    "أدلكم": 1,
    "أدهى": 1,
    "أدوا": 1,
    "أذاعوا": 1,
    "أذاقهم": 1,
    "أذاهم": 1,
    "أذبحك": 1,
    "أذقنا": 1,
    "أذقناه": 1,
    "أذكركم": 1,
    "أذكره": 1,
    "أذلة": 1,
    "أذلك": 1,
    "أذنت": 1,
    "أذنيه": 1,
    "أذهب": 1,
    "أذهبتم": 1,
    "أرأيت": 1,
    "أرأيتك": 1,
    "أرأيتكم": 1,
    "أرأيتم": 1,
    "أراد": 1,
    "أرادا": 1,
    "أرادني": 1,
    "أرادوا": 1,
    "أراذلنا": 1,
    "أراغب": 1,
    "أراك": 1,
    "أراكم": 1,
    "أراكهم": 1,
    "أراني": 1,
    "أربابا": 1,
    "أربى": 1,
    "أرجائها": 1,
    "أرجع": 1,
    "أرجلكم": 1,
    "أرجلهم": 1,
    "أرجه": 1,
    "أرحام": 1,
    "أرحامكم": 1,
    "أرحامهن": 1,
    "أرداكم": 1,
    "أردت": 1,
    "أردتم": 1,
    "أردنا": 1,
    "أردناه": 1,
    "أرذل": 1,
    "أرساها": 1,
    "أرسلت": 1,
    "أرسلتم": 1,
    "أرسلنا": 1,
    "أرسلناك": 1,
    "أرسلناه": 1,
    "أرسله": 1,
    "أرسلوا": 1,
    "أرضعت": 1,
    "أرضعن": 1,
    "أرضعنكم": 1,
    "أرضعيه": 1,
    "أرضكم": 1,
    "أرضنا": 1,
    "أرضهم": 1,
    "أرضيتم": 1,
    "أركسهم": 1,
    "أركسوا": 1,
    "أرنا": 1,
    "أرني": 1,
    "أرهطي": 1,
    "أروني": 1,
    "أرى": 1,
    "أريكم": 1,
    "أريناك": 1,
    "أريناه": 1,
    "أزا": 1,
    "أزاغ": 1,
    "أزري": 1,
    "أزفت": 1,
    "أزكى": 1,
    "أزلفت": 1,
    "أزواجا": 1,
    "أزواجك": 1,
    "أزواجكم": 1,
    "أزواجنا": 1,
    "أزواجه": 1,
    "أزواجهم": 1,
    "أزواجهن": 1,
    "أزيد": 1,
    "أسأتم": 1,
    "أسألك": 1,
    "أسألكم": 1,
    "أساءوا": 1,
    "أسارى": 1,
    "أساور": 1,
    "أسباطا": 1,
    "أستجب": 1,
    "أستخلصه": 1,
    "أستغفر": 1,
    "أستغفرت": 1,
    "أستكبرت": 1,
    "أسحر": 1,
    "أسر": 1,
    "أسرفوا": 1,
    "أسرهم": 1,
    "أسروا": 1,
    "أسرى": 1,
    "أسفارا": 1,
    "أسفارنا": 1,
    "أسفر": 1,
    "أسفى": 1,
    "أسكنت": 1,
    "أسكنوهن": 1,
    "أسلحتكم": 1,
    "أسلحتهم": 1,
    "أسلفت": 1,
    "أسلفتم": 1,
    "أسلم": 1,
    "أسلما": 1,
    "أسلمت": 1,
    "أسلمنا": 1,
    "أسلموا": 1,
    "أسمائه": 1,
    "أسمع": 1,
    "أسمعهم": 1,
    "أسوة": 1,
    "أسورة": 1,
    "أشاء": 1,
    "أشتاتا": 1,
    "أشحة": 1,
    "أشداء": 1,
    "أشدكم": 1,
    "أشده": 1,
    "أشدهما": 1,
    "أشراطها": 1,
    "أشرك": 1,
    "أشركت": 1,
    "أشركتم": 1,
    "أشركتمون": 1,
    "أشركنا": 1,
    "أشركوا": 1,
    "أشق": 1,
    "أشقاها": 1,
    "أشكر": 1,
    "أشكو": 1,
    "أشهد": 1,
    "أشهدتهم": 1,
    "أشهدوا": 1,
    "أشياءهم": 1,
    "أشياعكم": 1,
    "أصابت": 1,
    "أصابتكم": 1,
    "أصابته": 1,
    "أصابتهم": 1,
    "أصابعهم": 1,
    "أصابك": 1,
    "أصابكم": 1,
    "أصابها": 1,
    "أصابهم": 1,
    "أصبتم": 1,
    "أصبحوا": 1,
    "أصبرهم": 1,
    "أصبناهم": 1,
    "أصحابهم": 1,
    "أصدقت": 1,
    "أصطفى": 1,
    "أصلابكم": 1,
    "أصلاتك": 1,
    "أصلها": 1,
    "أصنام": 1,
    "أصناما": 1,
    "أصنامكم": 1,
    "أصواتكم": 1,
    "أصواتهم": 1,
    "أصوافها": 1,
    "أصولها": 1,
    "أصيب": 1,
    "أضاءت": 1,
    "أضاعوا": 1,
    "أضحك": 1,
    "أضطره": 1,
    "أضعافا": 1,
    "أضغاث": 1,
    "أضغانكم": 1,
    "أضغانهم": 1,
    "أضل": 1,
    "أضلانا": 1,
    "أضللتم": 1,
    "أضللن": 1,
    "أضلنا": 1,
    "أضلني": 1,
    "أضلوا": 1,
    "أضلونا": 1,
    "أضيع": 1,
    "أطاع": 1,
    "أطاعونا": 1,
    "أطرافها": 1,
    "أطعتم": 1,
    "أطعتموهم": 1,
    "أطعمه": 1,
    "أطعمهم": 1,
    "أطعنا": 1,
    "أطعنكم": 1,
    "أطغيته": 1,
    "أطفأها": 1,
    "أطمع": 1,
    "أطهر": 1,
    "أطوارا": 1,
    "أطيعوا": 1,
    "أظفركم": 1,
    "أظن": 1,
    "أعبد": 1,
    "أعتدنا": 1,
    "أعثرنا": 1,
    "أعجاز": 1,
    "أعجبتكم": 1,
    "أعجبك": 1,
    "أعجبكم": 1,
    "أعجزت": 1,
    "أعجلتم": 1,
    "أعجلك": 1,
    "أعجمي": 1,
    "أعجميا": 1,
    "أعدت": 1,
    "أعذبه": 1,
    "أعرض": 1,
    "أعرضتم": 1,
    "أعرضوا": 1,
    "أعز": 1,
    "أعزة": 1,
    "أعصر": 1,
    "أعصي": 1,
    "أعطوا": 1,
    "أعطيناك": 1,
    "أعظك": 1,
    "أعظكم": 1,
    "أعقابكم": 1,
    "أعقابنا": 1,
    "أعلنت": 1,
    "أعلنتم": 1,
    "أعمالا": 1,
    "أعمالكم": 1,
    "أعمالنا": 1,
    "أعمالهم": 1,
    "أعمامكم": 1,
    "أعمل": 1,
    "أعناب": 1,
    "أعناق": 1,
    "أعناقهم": 1,
    "أعنده": 1,
    "أعهد": 1,
    "أعوذ": 1,
    "أعيبها": 1,
    "أعيدوا": 1,
    "أعيذها": 1,
    "أعين": 1,
    "أعينكم": 1,
    "أعينهم": 1,
    "أعينهن": 1,
    "أغرقنا": 1,
    "أغرقناهم": 1,
    "أغرقوا": 1,
    "أغشيت": 1,
    "أغفلنا": 1,
    "أغلالا": 1,
    "أغناهم": 1,
    "أغنت": 1,
    "أغني": 1,
    "أغنياء": 1,
    "أغويتني": 1,
    "أغوينا": 1,
    "أغويناهم": 1,
    "أغير": 1,
    "أف": 1,
    "أفأصفاكم": 1,
    "أفأمن": 1,
    "أفأمنتم": 1,
    "أفأمنوا": 1,
    "أفأنبئكم": 1,
    "أفأنت": 1,
    "أفأنتم": 1,
    "أفإن": 1,
    "أفئدة": 1,
    "أفئدتهم": 1,
    "أفاء": 1,
    "أفاتخذتم": 1,
    "أفاض": 1,
    "أفاق": 1,
    "أفاك": 1,
    "أفبالباطل": 1,
    "أفبعذابنا": 1,
    "أفبنعمة": 1,
    "أفبهذا": 1,
    "أفتأتون": 1,
    "أفتؤمنون": 1,
    "أفتتخذونه": 1,
    "أفترى": 1,
    "أفتطمعون": 1,
    "أفتعبدون": 1,
    "أفتمارونه": 1,
    "أفتنا": 1,
    "أفتهلكنا": 1,
    "أفتوني": 1,
    "أفحسب": 1,
    "أفحسبتم": 1,
    "أفحكم": 1,
    "أفرأيت": 1,
    "أفرأيتم": 1,
    "أفسحر": 1,
    "أفسدوها": 1,
    "أفصح": 1,
    "أفضتم": 1,
    "أفضى": 1,
    "أفطال": 1,
    "أفعصيت": 1,
    "أفعيينا": 1,
    "أفغير": 1,
    "أفك": 1,
    "أفكلما": 1,
    "أفل": 1,
    "أفلا": 1,
    "أفلت": 1,
    "أفلح": 1,
    "أفلم": 1,
    "أفما": 1,
    "أفمن": 1,
    "أفنان": 1,
    "أفنجعل": 1,
    "أفنضرب": 1,
    "أفهم": 1,
    "أفواجا": 1,
    "أفواههم": 1,
    "أفي": 1,
    "أفيضوا": 1,
    "أقاموا": 1,
    "أقبل": 1,
    "أقبلنا": 1,
    "أقتت": 1,
    "أقتل": 1,
    "أقتلت": 1,
    "أقدامكم": 1,
    "أقدامنا": 1,
    "أقربهم": 1,
    "أقررتم": 1,
    "أقررنا": 1,
    "أقريب": 1,
    "أقسط": 1,
    "أقسمتم": 1,
    "أقسموا": 1,
    "أقطارها": 1,
    "أقفالها": 1,
    "أقلامهم": 1,
    "أقلت": 1,
    "أقلعي": 1,
    "أقمتم": 1,
    "أقواتها": 1,
    "أقول": 1,
    "أقوم": 1,
    "أقيموا": 1,
    "أكابر": 1,
    "أكالون": 1,
    "أكان": 1,
    "أكبرنه": 1,
    "أكثركم": 1,
    "أكثرهم": 1,
    "أكذبتم": 1,
    "أكرمكم": 1,
    "أكرمن": 1,
    "أكرمي": 1,
    "أكرهتنا": 1,
    "أكفاركم": 1,
    "أكفر": 1,
    "أكفرت": 1,
    "أكفرتم": 1,
    "أكفره": 1,
    "أكفلنيها": 1,
    "أكلا": 1,
    "أكلم": 1,
    "أكلها": 1,
    "أكمامها": 1,
    "أكملت": 1,
    "أكن": 1,
    "أكنانا": 1,
    "أكنة": 1,
    "أكننتم": 1,
    "أكون": 1,
    "ألتناهم": 1,
    "ألحقتم": 1,
    "ألحقنا": 1,
    "ألربك": 1,
    "ألزمناه": 1,
    "ألست": 1,
    "ألسنتكم": 1,
    "ألسنتهم": 1,
    "ألفافا": 1,
    "ألفت": 1,
    "ألفوا": 1,
    "ألفينا": 1,
    "ألق": 1,
    "ألقاه": 1,
    "ألقاها": 1,
    "ألقها": 1,
    "ألقوا": 1,
    "ألقي": 1,
    "ألقيا": 1,
    "ألكم": 1,
    "ألن": 1,
    "ألهاكم": 1,
    "ألوانه": 1,
    "ألوانها": 1,
    "ألوف": 1,
    "أليم": 1,
    "أليما": 1,
    "أمات": 1,
    "أماته": 1,
    "أماذا": 1,
    "أمامه": 1,
    "أماناتكم": 1,
    "أمانته": 1,
    "أماني": 1,
    "أمانيهم": 1,
    "أمتا": 1,
    "أمتعكن": 1,
    "أمتكم": 1,
    "أمتنا": 1,
    "أمثالكم": 1,
    "أمثالها": 1,
    "أمثالهم": 1,
    "أمثلهم": 1,
    "أمدا": 1,
    "أمدكم": 1,
    "أمرت": 1,
    "أمرتك": 1,
    "أمرتني": 1,
    "أمرتهم": 1,
    "أمركم": 1,
    "أمرنا": 1,
    "أمره": 1,
    "أمرها": 1,
    "أمرهم": 1,
    "أمروا": 1,
    "أمري": 1,
    "أمسكن": 1,
    "أمسكهما": 1,
    "أمشاج": 1,
    "أمضي": 1,
    "أمطرت": 1,
    "أمعاءهم": 1,
    "أمك": 1,
    "أملا": 1,
    "أملك": 1,
    "أمليت": 1,
    "أمما": 1,
    "أمنا": 1,
    "أمنة": 1,
    "أمنتكم": 1,
    "أمنتم": 1,
    "أمنيته": 1,
    "أمه": 1,
    "أمها": 1,
    "أمهاتكم": 1,
    "أمهاتهم": 1,
    "أمهلهم": 1,
    "أموات": 1,
    "أمواتا": 1,
    "أموالا": 1,
    "أموالكم": 1,
    "أموالنا": 1,
    "أموالهم": 1,
    "أموت": 1,
    "أميون": 1,
    "أنؤمن": 1,
    "أنا": 1,
    "أناب": 1,
    "أنبأك": 1,
    "أنبأهم": 1,
    "أنبئكم": 1,
    "أنبئهم": 1,
    "أنبئوني": 1,
    "أنباء": 1,
    "أنبائكم": 1,
    "أنبائها": 1,
    "أنبتت": 1,
    "أنبتكم": 1,
    "أنبتنا": 1,
    "أنبنا": 1,
    "أنبياء": 1,
    "أنتم": 1,
    "أنتما": 1,
    "أنجاكم": 1,
    "أنجانا": 1,
    "أنجاهم": 1,
    "أنجيتنا": 1,
    "أنجينا": 1,
    "أنجيناكم": 1,
    "أنحن": 1,
    "أندادا": 1,
    "أندعو": 1,
    "أنذرتكم": 1,
    "أنذركم": 1,
    "أنذرناكم": 1,
    "أنذرهم": 1,
    "أنذروا": 1,
    "أنزلت": 1,
    "أنزلتموه": 1,
    "أنزلنا": 1,
    "أنزلناه": 1,
    "أنزلناها": 1,
    "أنزلني": 1,
    "أنزله": 1,
    "أنساب": 1,
    "أنسانيه": 1,
    "أنسجد": 1,
    "أنسوكم": 1,
    "أنشأتم": 1,
    "أنشأكم": 1,
    "أنشأنا": 1,
    "أنشأناه": 1,
    "أنشأناهن": 1,
    "أنشأها": 1,
    "أنشره": 1,
    "أنصارا": 1,
    "أنصاري": 1,
    "أنصتوا": 1,
    "أنصح": 1,
    "أنطعم": 1,
    "أنطق": 1,
    "أنطقنا": 1,
    "أنظرني": 1,
    "أنعام": 1,
    "أنعاما": 1,
    "أنعامكم": 1,
    "أنعامهم": 1,
    "أنعمت": 1,
    "أنعمنا": 1,
    "أنعمها": 1,
    "أنفسكم": 1,
    "أنفسهن": 1,
    "أنفقت": 1,
    "أنفقتم": 1,
    "أنفقوا": 1,
    "أنقض": 1,
    "أنك": 1,
    "أنكاثا": 1,
    "أنكالا": 1,
    "أنكحك": 1,
    "أنكم": 1,
    "أنلزمكموها": 1,
    "أنما": 1,
    "أننا": 1,
    "أنهار": 1,
    "أنهارا": 1,
    "أنهاكم": 1,
    "أنهكما": 1,
    "أنهما": 1,
    "أنى": 1,
    "أنيب": 1,
    "أهؤلاء": 1,
    "أهانن": 1,
    "أهدك": 1,
    "أهدكم": 1,
    "أهدى": 1,
    "أهديكم": 1,
    "أهذا": 1,
    "أهكذا": 1,
    "أهلكت": 1,
    "أهلكتهم": 1,
    "أهلكنا": 1,
    "أهلكناها": 1,
    "أهلكناهم": 1,
    "أهلكني": 1,
    "أهلنا": 1,
    "أهله": 1,
    "أهلها": 1,
    "أهلهم": 1,
    "أهلهن": 1,
    "أهلي": 1,
    "أهليكم": 1,
    "أهليهم": 1,
    "أهم": 1,
    "أهمتهم": 1,
    "أهواء": 1,
    "أهواءكم": 1,
    "أهواءهم": 1,
    "أهون": 1,
    "أهوى": 1,
    "أوآباؤنا": 1,
    "أوأمن": 1,
    "أواب": 1,
    "أواه": 1,
    "أوبي": 1,
    "أوت": 1,
    "أوتادا": 1,
    "أوتوا": 1,
    "أوتوه": 1,
    "أوتي": 1,
    "أوتيت": 1,
    "أوتيتم": 1,
    "أوتيته": 1,
    "أوثانا": 1,
    "أوجفتم": 1,
    "أوحى": 1,
    "أوحي": 1,
    "أوحيت": 1,
    "أوحينا": 1,
    "أودية": 1,
    "أوديتهم": 1,
    "أوذي": 1,
    "أوذينا": 1,
    "أورثتموها": 1,
    "أورثنا": 1,
    "أورثوا": 1,
    "أوزار": 1,
    "أوزارا": 1,
    "أوزارها": 1,
    "أوزارهم": 1,
    "أوزعني": 1,
    "أوسطهم": 1,
    "أوعجبتم": 1,
    "أوعظت": 1,
    "أوفوا": 1,
    "أوفى": 1,
    "أوفي": 1,
    "أوقدوا": 1,
    "أوكلما": 1,
    "أولئكم": 1,
    "أولاء": 1,
    "أولات": 1,
    "أولادكم": 1,
    "أولادهم": 1,
    "أولادهن": 1,
    "أولاهم": 1,
    "أولاهما": 1,
    "أولما": 1,
    "أولو": 1,
    "أولى": 1,
    "أولياءه": 1,
    "أولياؤكم": 1,
    "أولياؤه": 1,
    "أولياؤهم": 1,
    "أوليائكم": 1,
    "أوليائهم": 1,
    "أوليس": 1,
    "أومن": 1,
    "أوهن": 1,
    "أوى": 1,
    "أوينا": 1,
    "أيأمركم": 1,
    "أياما": 1,
    "أيان": 1,
    "أيبتغون": 1,
    "أيتها": 1,
    "أيحب": 1,
    "أيحسب": 1,
    "أيحسبون": 1,
    "أيدتك": 1,
    "أيدك": 1,
    "أيديكم": 1,
    "أيدينا": 1,
    "أيديهم": 1,
    "أيديهما": 1,
    "أيديهن": 1,
    "أيشركون": 1,
    "أيطمع": 1,
    "أيعدكم": 1,
    "أيقاظا": 1,
    "أيكم": 1,
    "أيما": 1,
    "أيمان": 1,
    "أيمانكم": 1,
    "أيمانهم": 1,
    "أيمانهن": 1,
    "أيمسكه": 1,
    "أينا": 1,
    "أينما": 1,
    "أيه": 1,
    "أيها": 1,
    "أيهم": 1,
    "أيوب": 1,
    "أيود": 1,
    "إبليس": 1,
    "إثم": 1,
    "إثمه": 1,
    "إحداهما": 1,
    "إحداهن": 1,
    "إحسانا": 1,
    "إخراجا": 1,
    "إخراجكم": 1,
    "إخراجهم": 1,
    "إخوان": 1,
    "إخوانا": 1,
    "إخوانكم": 1,
    "إخوانهم": 1,
    "إخوانهن": 1,
    "إخوة": 1,
    "إخوتك": 1,
    "إخوتي": 1,
    "إدريس": 1,
    "إذ": 1,
    "إذنه": 1,
    "إستبرق": 1,
    "إسرارا": 1,
    "إسرارهم": 1,
    "إسرافا": 1,
    "إسلامكم": 1,
    "إسلامهم": 1,
    "إسماعيل": 1,
    "إصرا": 1,
    "إصرهم": 1,
    "إصري": 1,
    "إصلاحا": 1,
    "إطعام": 1,
    "إعراضا": 1,
    "إعراضهم": 1,
    "إعصار": 1,
    "إفك": 1,
    "إفكا": 1,
    "إفكهم": 1,
    "إقامتكم": 1,
    "إكراه": 1,
    "إكراههن": 1,
    "إلا": 1,
    "إلحافا": 1,
    "إلها": 1,
    "إلهك": 1,
    "إلهكم": 1,
    "إلهه": 1,
    "إلهين": 1,
    "إلياس": 1,
    "إليك": 1,
    "إليكم": 1,
    "إليكما": 1,
    "إليهم": 1,
    "إليهن": 1,
    "إما": 1,
    "إمام": 1,
    "إماما": 1,
    "إمرا": 1,
    "إملاق": 1,
    "إنا": 1,
    "إناثا": 1,
    "إناه": 1,
    "إنسيا": 1,
    "إنك": 1,
    "إنكم": 1,
    "إنما": 1,
    "إننا": 1,
    "إنني": 1,
    "إنه": 1,
    "إنها": 1,
    "إنهم": 1,
    "إنهما": 1,
    "إنهن": 1,
    "إني": 1,
    "إيابهم": 1,
    "إياك": 1,
    "إياكم": 1,
    "إيانا": 1,
    "إياه": 1,
    "إيلافهم": 1,
    "إيمانا": 1,
    "إيمانكم": 1,
    "إيمانه": 1,
    "إيمانها": 1,
    "إيمانهم": 1,
    "اؤتمن": 1,
    "ائت": 1,
    "ائتنا": 1,
    "ائتوا": 1,
    "ائتوني": 1,
    "ائتيا": 1,
    "ائذن": 1,
    "ابتدعوها": 1,
    "ابتغاء": 1,
    "ابتغوا": 1,
    "ابتغى": 1,
    "ابتغيت": 1,
    "ابتلاه": 1,
    "ابعث": 1,
    "ابلعي": 1,
    "ابنت": 1,
    "ابنتي": 1,
    "ابنك": 1,
    "ابنه": 1,
    "ابنوا": 1,
    "ابني": 1,
    "اتبعت": 1,
    "اتبعتم": 1,
    "اتبعتني": 1,
    "اتبعك": 1,
    "اتبعكما": 1,
    "اتبعن": 1,
    "اتبعني": 1,
    "اتبعوا": 1,
    "اتبعوك": 1,
    "اتبعون": 1,
    "اتبعوه": 1,
    "اتبعوهم": 1,
    "اتخذ": 1,
    "اتخذت": 1,
    "اتخذتم": 1,
    "اتخذها": 1,
    "اتخذوا": 1,
    "اتخذوني": 1,
    "اتخذوه": 1,
    "اتخذوها": 1,
    "اتخذوهم": 1,
    "اتخذي": 1,
    "اتسق": 1,
    "اتق": 1,
    "اتقوا": 1,
    "اتقى": 1,
    "اتقيتن": 1,
    "اتل": 1,
    "اثاقلتم": 1,
    "اثنا": 1,
    "اثنان": 1,
    "اثنتا": 1,
    "اثنتي": 1,
    "اثنتين": 1,
    "اثني": 1,
    "اثنين": 1,
    "اجتباكم": 1,
    "اجتباه": 1,
    "اجتبيتها": 1,
    "اجتثت": 1,
    "اجترحوا": 1,
    "اجتمعت": 1,
    "اجتمعوا": 1,
    "اجتنبوا": 1,
    "اجعلني": 1,
    "اجعلوا": 1,
    "اجهروا": 1,
    "احتمل": 1,
    "احتملوا": 1,
    "احشروا": 1,
    "احمل": 1,
    "اخترتك": 1,
    "اخترناهم": 1,
    "اختصموا": 1,
    "اختلافا": 1,
    "اختلاق": 1,
    "اختلط": 1,
    "اختلف": 1,
    "اختلفتم": 1,
    "اختلفوا": 1,
    "اخرجوا": 1,
    "اخسئوا": 1,
    "اخلفني": 1,
    "ادارك": 1,
    "اداركوا": 1,
    "ادخلا": 1,
    "ادخلوا": 1,
    "ادخلوها": 1,
    "ادخلي": 1,
    "ادعهن": 1,
    "ادعوا": 1,
    "ادعوني": 1,
    "ادعوهم": 1,
    "ادفعوا": 1,
    "اذكر": 1,
    "اذكرني": 1,
    "اذكروا": 1,
    "اذهبا": 1,
    "اذهبوا": 1,
    "ارتابوا": 1,
    "ارتبتم": 1,
    "ارتدوا": 1,
    "ارتضى": 1,
    "ارجع": 1,
    "ارجعوا": 1,
    "ارجعون": 1,
    "ارجعي": 1,
    "ارحمهما": 1,
    "اركب": 1,
    "اركبوا": 1,
    "اركض": 1,
    "اركعوا": 1,
    "ازدادوا": 1,
    "استأجرت": 1,
    "استأجره": 1,
    "استأذن": 1,
    "استأذنك": 1,
    "استأذنوك": 1,
    "استبدال": 1,
    "استجابوا": 1,
    "استجارك": 1,
    "استجيب": 1,
    "استجيبوا": 1,
    "استحبوا": 1,
    "استحفظوا": 1,
    "استحق": 1,
    "استحقا": 1,
    "استحوذ": 1,
    "استخرجها": 1,
    "استخلف": 1,
    "استرق": 1,
    "استزلهم": 1,
    "استسقاه": 1,
    "استسقى": 1,
    "استضعفوا": 1,
    "استضعفوني": 1,
    "استطاع": 1,
    "استطاعوا": 1,
    "استطعت": 1,
    "استطعتم": 1,
    "استطعما": 1,
    "استطعنا": 1,
    "استعجالهم": 1,
    "استعجلتم": 1,
    "استعلى": 1,
    "استعينوا": 1,
    "استغفار": 1,
    "استغفر": 1,
    "استغفروا": 1,
    "استغنى": 1,
    "استقاموا": 1,
    "استقر": 1,
    "استكانوا": 1,
    "استكبارا": 1,
    "استكبر": 1,
    "استكبرتم": 1,
    "استكبروا": 1,
    "استكثرتم": 1,
    "استمتع": 1,
    "استمتعتم": 1,
    "استمسك": 1,
    "استمع": 1,
    "استمعوه": 1,
    "استنصره": 1,
    "استنصروكم": 1,
    "استنكفوا": 1,
    "استهزئ": 1,
    "استهزئوا": 1,
    "استهوته": 1,
    "استوقد": 1,
    "استوى": 1,
    "استويت": 1,
    "استويتم": 1,
    "استيأس": 1,
    "استيأسوا": 1,
    "استيسر": 1,
    "اسجدوا": 1,
    "اسطاعوا": 1,
    "اسكن": 1,
    "اسكنوا": 1,
    "اسلك": 1,
    "اسمه": 1,
    "اشتدت": 1,
    "اشتراه": 1,
    "اشتروا": 1,
    "اشتملت": 1,
    "اشتهت": 1,
    "اشدد": 1,
    "اشرح": 1,
    "اشكر": 1,
    "اشمأزت": 1,
    "اشهدوا": 1,
    "اصبر": 1,
    "اصبروا": 1,
    "اصرف": 1,
    "اصطفاك": 1,
    "اصطفاه": 1,
    "اصطفى": 1,
    "اصطفيتك": 1,
    "اصطفينا": 1,
    "اصطفيناه": 1,
    "اصلوها": 1,
    "اضرب": 1,
    "اضربوه": 1,
    "اضطر": 1,
    "اضطررتم": 1,
    "اطرحوه": 1,
    "اطلعت": 1,
    "اطمأن": 1,
    "اطمأننتم": 1,
    "اطمس": 1,
    "اطيرنا": 1,
    "اعبدوا": 1,
    "اعبدوني": 1,
    "اعتدوا": 1,
    "اعتدى": 1,
    "اعتدينا": 1,
    "اعتراك": 1,
    "اعترفوا": 1,
    "اعتزلتموهم": 1,
    "اعتزلهم": 1,
    "اعتزلوكم": 1,
    "اعتمر": 1,
    "اعدلوا": 1,
    "اعلموا": 1,
    "اعمل": 1,
    "اعملوا": 1,
    "اغترف": 1,
    "اغدوا": 1,
    "اغفر": 1,
    "افتح": 1,
    "افتدت": 1,
    "افتدى": 1,
    "افتراء": 1,
    "افتراه": 1,
    "افترى": 1,
    "افتريته": 1,
    "افترينا": 1,
    "افعل": 1,
    "اقتتل": 1,
    "اقتتلوا": 1,
    "اقتحم": 1,
    "اقتده": 1,
    "اقتربت": 1,
    "اقترفتموها": 1,
    "اقتلوا": 1,
    "اقتلوه": 1,
    "اقذفيه": 1,
    "اقرءوا": 1,
    "اقرأ": 1,
    "اقضوا": 1,
    "اقعدوا": 1,
    "اقنتي": 1,
    "اكتالوا": 1,
    "اكتتبها": 1,
    "اكتسب": 1,
    "اكتسبت": 1,
    "اكتسبن": 1,
    "اكتسبوا": 1,
    "اكشف": 1,
    "اكفر": 1,
    "الآثمين": 1,
    "الآزفة": 1,
    "الآفلين": 1,
    "الآمرون": 1,
    "الآمنين": 1,
    "الآيات": 1,
    "الآية": 1,
    "الأبتر": 1,
    "الأبرار": 1,
    "الأبصار": 1,
    "الأتقى": 1,
    "الأثيم": 1,
    "الأجداث": 1,
    "الأجلين": 1,
    "الأحزاب": 1,
    "الأختين": 1,
    "الأخسرون": 1,
    "الأخسرين": 1,
    "الأخلاء": 1,
    "الأخيار": 1,
    "الأدبار": 1,
    "الأذقان": 1,
    "الأذل": 1,
    "الأذلين": 1,
    "الأرذلون": 1,
    "الأسفلين": 1,
    "الأشر": 1,
    "الأشقى": 1,
    "الأشهاد": 1,
    "الأشهر": 1,
    "الأعجمين": 1,
    "الأعراب": 1,
    "الأعز": 1,
    "الأعلون": 1,
    "الأعين": 1,
    "الأغلال": 1,
    "الأفئدة": 1,
    "الأقاويل": 1,
    "الأقدمون": 1,
    "الأقربين": 1,
    "الأكرم": 1,
    "الأكمه": 1,
    "الأليم": 1,
    "الأماني": 1,
    "الأمي": 1,
    "الأميين": 1,
    "الأنامل": 1,
    "الأنثيين": 1,
    "الأنعام": 1,
    "الأنفال": 1,
    "الأوفى": 1,
    "الأولون": 1,
    "الأوليان": 1,
    "الأولين": 1,
    "الأيامى": 1,
    "الأيد": 1,
    "الأيكة": 1,
    "الأيمان": 1,
    "الإبل": 1,
    "الإربة": 1,
    "الإصباح": 1,
    "الإنس": 1,
    "البأساء": 1,
    "البائس": 1,
    "البارئ": 1,
    "الباطل": 1,
    "الباقين": 1,
    "البالغة": 1,
    "البحران": 1,
    "البشرى": 1,
    "البشير": 1,
    "البصير": 1,
    "البطشة": 1,
    "البغضاء": 1,
    "البغي": 1,
    "البلاغ": 1,
    "البلد": 1,
    "البنون": 1,
    "البنين": 1,
    "البوار": 1,
    "البينات": 1,
    "البينة": 1,
    "التائبون": 1,
    "التابعين": 1,
    "التراقي": 1,
    "التغابن": 1,
    "التقتا": 1,
    "التقى": 1,
    "التقيتم": 1,
    "التلاق": 1,
    "التناد": 1,
    "التناوش": 1,
    "التنور": 1,
    "التهلكة": 1,
    "التواب": 1,
    "التوابين": 1,
    "التوب": 1,
    "الثاقب": 1,
    "الثرى": 1,
    "الثقلان": 1,
    "الثمرات": 1,
    "الثواب": 1,
    "الجاهلون": 1,
    "الجاهلية": 1,
    "الجاهلين": 1,
    "الجب": 1,
    "الجبار": 1,
    "الجرز": 1,
    "الجزاء": 1,
    "الجلاء": 1,
    "الجلال": 1,
    "الجمعان": 1,
    "الجنات": 1,
    "الجنتين": 1,
    "الجهر": 1,
    "الجوارح": 1,
    "الجودي": 1,
    "الحاسبين": 1,
    "الحافرة": 1,
    "الحاقة": 1,
    "الحاكمين": 1,
    "الحامدون": 1,
    "الحبك": 1,
    "الحجة": 1,
    "الحرور": 1,
    "الحسرة": 1,
    "الحسنى": 1,
    "الحسنيين": 1,
    "الحصيد": 1,
    "الحطمة": 1,
    "الحكيم": 1,
    "الحلقوم": 1,
    "الحليم": 1,
    "الحمد": 1,
    "الحميم": 1,
    "الحواريين": 1,
    "الحوايا": 1,
    "الخائضين": 1,
    "الخائنين": 1,
    "الخاسرين": 1,
    "الخاشعين": 1,
    "الخاطئون": 1,
    "الخاطئين": 1,
    "الخالدين": 1,
    "الخالص": 1,
    "الخالفين": 1,
    "الخالقون": 1,
    "الخالقين": 1,
    "الخالية": 1,
    "الخبء": 1,
    "الخبائث": 1,
    "الخبيث": 1,
    "الخبيثات": 1,
    "الخراصون": 1,
    "الخصام": 1,
    "الخطفة": 1,
    "الخلاق": 1,
    "الخلطاء": 1,
    "الخناس": 1,
    "الخوالف": 1,
    "الخيام": 1,
    "الخيرات": 1,
    "الخيرة": 1,
    "الداخلين": 1,
    "الدار": 1,
    "الداع": 1,
    "الدبر": 1,
    "الدرك": 1,
    "الدمع": 1,
    "الدواب": 1,
    "الديار": 1,
    "الذكران": 1,
    "الذكور": 1,
    "الذلة": 1,
    "الر": 1,
    "الرؤيا": 1,
    "الراجفة": 1,
    "الراحمين": 1,
    "الرادفة": 1,
    "الرازقين": 1,
    "الراسخون": 1,
    "الراشدون": 1,
    "الراكعون": 1,
    "الراكعين": 1,
    "الربانيون": 1,
    "الرجز": 1,
    "الرجس": 1,
    "الرجع": 1,
    "الرجعى": 1,
    "الرجيم": 1,
    "الرحمن": 1,
    "الرحيم": 1,
    "الرس": 1,
    "الرسل": 1,
    "الرسولا": 1,
    "الرشيد": 1,
    "الرعاء": 1,
    "الرفث": 1,
    "الرفد": 1,
    "الرهب": 1,
    "الروع": 1,
    "الزاد": 1,
    "الزاهدين": 1,
    "الزبانية": 1,
    "الزبر": 1,
    "الزبور": 1,
    "الزراع": 1,
    "الزقوم": 1,
    "الزكاة": 1,
    "الزوجين": 1,
    "السائحون": 1,
    "السابقون": 1,
    "الساجدون": 1,
    "الساجدين": 1,
    "الساحرون": 1,
    "الساخرين": 1,
    "السامري": 1,
    "السبع": 1,
    "السبل": 1,
    "السبيلا": 1,
    "السحت": 1,
    "السدرة": 1,
    "السدين": 1,
    "السراء": 1,
    "السرائر": 1,
    "السرد": 1,
    "السعير": 1,
    "السفهاء": 1,
    "السميع": 1,
    "السنين": 1,
    "السوأى": 1,
    "السيئات": 1,
    "الشافعين": 1,
    "الشاكرين": 1,
    "الشاهدين": 1,
    "الشح": 1,
    "الشكور": 1,
    "الصابرون": 1,
    "الصابرين": 1,
    "الصاخة": 1,
    "الصادقون": 1,
    "الصادقين": 1,
    "الصاغرين": 1,
    "الصافنات": 1,
    "الصافون": 1,
    "الصالح": 1,
    "الصالحات": 1,
    "الصالحون": 1,
    "الصالحين": 1,
    "الصدع": 1,
    "الصدفين": 1,
    "الصديقون": 1,
    "الصراط": 1,
    "الصفا": 1,
    "الصم": 1,
    "الصمد": 1,
    "الصيام": 1,
    "الضأن": 1,
    "الضالون": 1,
    "الضالين": 1,
    "الضر": 1,
    "الضراء": 1,
    "الضلالة": 1,
    "الطائفتين": 1,
    "الطامة": 1,
    "الطور": 1,
    "الطيبات": 1,
    "الظالم": 1,
    "الظالمون": 1,
    "الظالمين": 1,
    "الظانين": 1,
    "الظلة": 1,
    "الظمآن": 1,
    "الظنونا": 1,
    "العابدين": 1,
    "العادون": 1,
    "العادين": 1,
    "العاقبة": 1,
    "العاكف": 1,
    "العالمون": 1,
    "العالمين": 1,
    "العالين": 1,
    "العاملون": 1,
    "العاملين": 1,
    "العتيق": 1,
    "العرم": 1,
    "العزة": 1,
    "العسرة": 1,
    "العشار": 1,
    "العشير": 1,
    "العظيم": 1,
    "العقيم": 1,
    "العلقة": 1,
    "العلى": 1,
    "العلي": 1,
    "العليم": 1,
    "العماد": 1,
    "العمي": 1,
    "العنت": 1,
    "العير": 1,
    "الغائبين": 1,
    "الغابرين": 1,
    "الغاشية": 1,
    "الغافرين": 1,
    "الغافلات": 1,
    "الغافلون": 1,
    "الغافلين": 1,
    "الغالبون": 1,
    "الغالبين": 1,
    "الغاوون": 1,
    "الغاوين": 1,
    "الغرفات": 1,
    "الغفار": 1,
    "الغفور": 1,
    "الغلام": 1,
    "الغمام": 1,
    "الغني": 1,
    "الغي": 1,
    "الغيث": 1,
    "الغيظ": 1,
    "الغيوب": 1,
    "الفؤاد": 1,
    "الفئتان": 1,
    "الفاتحين": 1,
    "الفاسقين": 1,
    "الفاصلين": 1,
    "الفتاح": 1,
    "الفتية": 1,
    "الفجار": 1,
    "الفجرة": 1,
    "الفرحين": 1,
    "الفردوس": 1,
    "الفرقان": 1,
    "الفريضة": 1,
    "الفريقين": 1,
    "الفلق": 1,
    "الفواحش": 1,
    "الفوز": 1,
    "القادرون": 1,
    "القارعة": 1,
    "القاسطون": 1,
    "القاعدون": 1,
    "القاعدين": 1,
    "القالين": 1,
    "القانتين": 1,
    "القانطين": 1,
    "القانع": 1,
    "القاهر": 1,
    "القتلى": 1,
    "القدوس": 1,
    "القدير": 1,
    "القربى": 1,
    "القرنين": 1,
    "القريتين": 1,
    "القهار": 1,
    "القيوم": 1,
    "الكاذبون": 1,
    "الكاذبين": 1,
    "الكافرون": 1,
    "الكافرين": 1,
    "الكعبة": 1,
    "الكعبين": 1,
    "الكفر": 1,
    "الكفور": 1,
    "الكلالة": 1,
    "الكلم": 1,
    "الكوافر": 1,
    "الكوثر": 1,
    "الكيل": 1,
    "اللائي": 1,
    "اللات": 1,
    "اللاتي": 1,
    "اللاعبين": 1,
    "اللذين": 1,
    "اللمم": 1,
    "اللهم": 1,
    "اللهو": 1,
    "اللوامة": 1,
    "الم": 1,
    "المآب": 1,
    "المؤمنات": 1,
    "المؤمنين": 1,
    "الماعون": 1,
    "الماكرين": 1,
    "الماهدون": 1,
    "المبثوث": 1,
    "المبذرين": 1,
    "المبطلون": 1,
    "المبين": 1,
    "المتربصين": 1,
    "المتصدقين": 1,
    "المتطهرين": 1,
    "المتعال": 1,
    "المتقون": 1,
    "المتقين": 1,
    "المتكبر": 1,
    "المتكبرين": 1,
    "المتكلفين": 1,
    "المتلقيان": 1,
    "المتنافسون": 1,
    "المتوكلون": 1,
    "المتوكلين": 1,
    "المتين": 1,
    "المثاني": 1,
    "المثلات": 1,
    "المثلى": 1,
    "المجرمين": 1,
    "المجيبون": 1,
    "المجيد": 1,
    "المحتظر": 1,
    "المحسنين": 1,
    "المحضرين": 1,
    "المحيض": 1,
    "المخاض": 1,
    "المخبتين": 1,
    "المخرجين": 1,
    "المخسرين": 1,
    "المخلصين": 1,
    "المخلفون": 1,
    "المدائن": 1,
    "المدثر": 1,
    "المدحضين": 1,
    "المراضع": 1,
    "المرجومين": 1,
    "المرسلين": 1,
    "المرفود": 1,
    "المرفوع": 1,
    "المزمل": 1,
    "المزن": 1,
    "المس": 1,
    "المساق": 1,
    "المسبحون": 1,
    "المسبحين": 1,
    "المستأخرين": 1,
    "المستبين": 1,
    "المستضعفين": 1,
    "المستعان": 1,
    "المستقدمين": 1,
    "المستقر": 1,
    "المستكبرين": 1,
    "المستهزئين": 1,
    "المسجور": 1,
    "المسجونين": 1,
    "المسحرين": 1,
    "المسخر": 1,
    "المسرفين": 1,
    "المسكنة": 1,
    "المسكين": 1,
    "المسومة": 1,
    "المسيء": 1,
    "المشأمة": 1,
    "المشارق": 1,
    "المشحون": 1,
    "المشرقين": 1,
    "المشركات": 1,
    "المشركون": 1,
    "المشركين": 1,
    "المشعر": 1,
    "المصدقين": 1,
    "المصطفين": 1,
    "المصلحين": 1,
    "المصيطرون": 1,
    "المضاجع": 1,
    "المضطر": 1,
    "المضعفون": 1,
    "المضغة": 1,
    "المضلين": 1,
    "المطمئنة": 1,
    "المطهرين": 1,
    "المطوعين": 1,
    "المعارج": 1,
    "المعتبين": 1,
    "المعتدين": 1,
    "المعذبين": 1,
    "المعذرون": 1,
    "المعصرات": 1,
    "المعمور": 1,
    "المعوقين": 1,
    "المغربين": 1,
    "المغرقين": 1,
    "المغشي": 1,
    "المغضوب": 1,
    "المفترين": 1,
    "المفتون": 1,
    "المفر": 1,
    "المفسدين": 1,
    "المفلحون": 1,
    "المفلحين": 1,
    "المقامة": 1,
    "المقبوحين": 1,
    "المقتر": 1,
    "المقتسمين": 1,
    "المقربون": 1,
    "المقربين": 1,
    "المقسطين": 1,
    "المقنطرة": 1,
    "المكذبون": 1,
    "المكذبين": 1,
    "المكرمين": 1,
    "المكنون": 1,
    "المكيدون": 1,
    "الملأ": 1,
    "الملإ": 1,
    "الملة": 1,
    "الملعونة": 1,
    "الملقين": 1,
    "الملكين": 1,
    "الممترين": 1,
    "المناد": 1,
    "المنافقين": 1,
    "المنام": 1,
    "المنتصرين": 1,
    "المنتظرين": 1,
    "المنتهى": 1,
    "المنذرين": 1,
    "المنزلون": 1,
    "المنزلين": 1,
    "المنشآت": 1,
    "المنشئون": 1,
    "المنصورون": 1,
    "المنظرين": 1,
    "المنفوش": 1,
    "المنون": 1,
    "المنير": 1,
    "المهاجرين": 1,
    "المهتد": 1,
    "المهتدون": 1,
    "المهتدي": 1,
    "المهتدين": 1,
    "المهلكين": 1,
    "المهيمن": 1,
    "الموءودة": 1,
    "الموتة": 1,
    "الموج": 1,
    "المورود": 1,
    "الموعود": 1,
    "الموقدة": 1,
    "الموقنين": 1,
    "الميعاد": 1,
    "النادمين": 1,
    "الناصرين": 1,
    "الناظرين": 1,
    "الناقة": 1,
    "الناقور": 1,
    "النبإ": 1,
    "النبوة": 1,
    "النبيون": 1,
    "النبيين": 1,
    "النجدين": 1,
    "النجوى": 1,
    "الندامة": 1,
    "النسوة": 1,
    "النسيء": 1,
    "النشأة": 1,
    "النشور": 1,
    "النصارى": 1,
    "النطفة": 1,
    "النفاثات": 1,
    "النفوس": 1,
    "النهى": 1,
    "النون": 1,
    "الهالكين": 1,
    "الهدى": 1,
    "الهدي": 1,
    "الهون": 1,
    "الهيم": 1,
    "الواد": 1,
    "الوارثون": 1,
    "الوارثين": 1,
    "الواعظين": 1,
    "الواقعة": 1,
    "الوالدان": 1,
    "الوالدين": 1,
    "الوتين": 1,
    "الوثاق": 1,
    "الوثقى": 1,
    "الودق": 1,
    "الوعيد": 1,
    "الولدان": 1,
    "الوهاب": 1,
    "الويل": 1,
    "اليسر": 1,
    "اليم": 1,
    "امتحن": 1,
    "امتلأت": 1,
    "امرأ": 1,
    "امرأت": 1,
    "امرأتك": 1,
    "امرأته": 1,
    "امرأتي": 1,
    "امرأتين": 1,
    "امرؤ": 1,
    "امرئ": 1,
    "امشوا": 1,
    "امكثوا": 1,
    "انبعاثهم": 1,
    "انبعث": 1,
    "انتبذت": 1,
    "انتثرت": 1,
    "انتصر": 1,
    "انتظروا": 1,
    "انتقام": 1,
    "انتقمنا": 1,
    "انتهوا": 1,
    "انسلخ": 1,
    "انشزوا": 1,
    "انشقت": 1,
    "انصرفوا": 1,
    "انصرني": 1,
    "انطلقتم": 1,
    "انطلقوا": 1,
    "انظرنا": 1,
    "انظروا": 1,
    "انظرونا": 1,
    "انفخوا": 1,
    "انفروا": 1,
    "انفصام": 1,
    "انفضوا": 1,
    "انفطرت": 1,
    "انقص": 1,
    "انقلبتم": 1,
    "انقلبوا": 1,
    "انكدرت": 1,
    "اهبط": 1,
    "اهبطا": 1,
    "اهبطوا": 1,
    "اهتدوا": 1,
    "اهتدى": 1,
    "اهتديت": 1,
    "اهتديتم": 1,
    "اهتزت": 1,
    "اهدنا": 1,
    "بآبائنا": 1,
    "بآخذيه": 1,
    "بآخرين": 1,
    "بآل": 1,
    "بآلهتنا": 1,
    "بآنية": 1,
    "بآيات": 1,
    "بآياتنا": 1,
    "بآياته": 1,
    "بآياتي": 1,
    "بآية": 1,
    "بأبصارهم": 1,
    "بأحسن": 1,
    "بأحسنها": 1,
    "بأحكم": 1,
    "بأخ": 1,
    "بأخيك": 1,
    "بأربعة": 1,
    "بأرجلهن": 1,
    "بأسا": 1,
    "بأسكم": 1,
    "بأسماء": 1,
    "بأسمائهم": 1,
    "بأسنا": 1,
    "بأسه": 1,
    "بأسهم": 1,
    "بأشياعهم": 1,
    "بأصحاب": 1,
    "بأعدائكم": 1,
    "بأعلم": 1,
    "بأعيننا": 1,
    "بأفواهكم": 1,
    "بأفواههم": 1,
    "بأكواب": 1,
    "بألسنة": 1,
    "بألسنتكم": 1,
    "بألسنتهم": 1,
    "بأمانيكم": 1,
    "بأمر": 1,
    "بأمرنا": 1,
    "بأمره": 1,
    "بأمرهم": 1,
    "بأموال": 1,
    "بأموالكم": 1,
    "بأموالهم": 1,
    "بأنا": 1,
    "بأنعام": 1,
    "بأنعم": 1,
    "بأنفسهم": 1,
    "بأنفسهن": 1,
    "بأنكم": 1,
    "بأننا": 1,
    "بأنه": 1,
    "بأنهم": 1,
    "بأهدى": 1,
    "بأهلك": 1,
    "بأهلكم": 1,
    "بأهله": 1,
    "بأهوائهم": 1,
    "بأوعيتهم": 1,
    "بأيام": 1,
    "بأيد": 1,
    "بأيدي": 1,
    "بأيديكم": 1,
    "بأيدينا": 1,
    "بأيديهم": 1,
    "بأييكم": 1,
    "بإبراهيم": 1,
    "بإثمي": 1,
    "بإخراج": 1,
    "بإذنه": 1,
    "بإذني": 1,
    "بإسحاق": 1,
    "بإلحاد": 1,
    "بإمامهم": 1,
    "بإيمانكم": 1,
    "بإيمانهم": 1,
    "بإيمانهن": 1,
    "بئس": 1,
    "بئسما": 1,
    "بئيس": 1,
    "باء": 1,
    "باب": 1,
    "باتخاذكم": 1,
    "باخع": 1,
    "بادون": 1,
    "بادي": 1,
    "بارئكم": 1,
    "بارزون": 1,
    "باركنا": 1,
    "بازغا": 1,
    "بازغة": 1,
    "باسرة": 1,
    "باسط": 1,
    "باسطو": 1,
    "باسقات": 1,
    "باشروهن": 1,
    "باطنه": 1,
    "باعد": 1,
    "باغ": 1,
    "باقية": 1,
    "بال": 1,
    "بالآخرة": 1,
    "بالآيات": 1,
    "بالأبصار": 1,
    "بالأحقاف": 1,
    "بالأخسرين": 1,
    "بالأزلام": 1,
    "بالأسحار": 1,
    "بالأفق": 1,
    "بالألقاب": 1,
    "بالأمن": 1,
    "بالأنثى": 1,
    "بالإثم": 1,
    "بالإفك": 1,
    "بالبأساء": 1,
    "بالباطل": 1,
    "بالبخل": 1,
    "بالبر": 1,
    "بالبشرى": 1,
    "بالبصر": 1,
    "بالبنين": 1,
    "بالبيت": 1,
    "بالبينات": 1,
    "بالتوراة": 1,
    "بالتي": 1,
    "بالجبت": 1,
    "بالجنب": 1,
    "بالجنة": 1,
    "بالجنود": 1,
    "بالحج": 1,
    "بالحجاب": 1,
    "بالحر": 1,
    "بالحسنات": 1,
    "بالحسنة": 1,
    "بالحسنى": 1,
    "بالحق": 1,
    "بالحكمة": 1,
    "بالخاطئة": 1,
    "بالخلق": 1,
    "بالخنس": 1,
    "بالخيرات": 1,
    "بالدهن": 1,
    "بالدين": 1,
    "بالذكر": 1,
    "بالذي": 1,
    "بالذين": 1,
    "بالرحمن": 1,
    "بالرسل": 1,
    "بالساحل": 1,
    "بالساق": 1,
    "بالساهرة": 1,
    "بالسنين": 1,
    "بالسوء": 1,
    "بالسوق": 1,
    "بالسيئة": 1,
    "بالشاكرين": 1,
    "بالشفق": 1,
    "بالشمس": 1,
    "بالشهادة": 1,
    "بالشهداء": 1,
    "بالشهر": 1,
    "بالصالحين": 1,
    "بالصبر": 1,
    "بالصدق": 1,
    "بالصلاة": 1,
    "بالطاغوت": 1,
    "بالطاغية": 1,
    "بالطيب": 1,
    "بالظالمين": 1,
    "بالعباد": 1,
    "بالعبد": 1,
    "بالعدل": 1,
    "بالعدوة": 1,
    "بالعذاب": 1,
    "بالعراء": 1,
    "بالعرف": 1,
    "بالعروة": 1,
    "بالعشي": 1,
    "بالعصبة": 1,
    "بالعقود": 1,
    "بالعمرة": 1,
    "بالعهد": 1,
    "بالغداة": 1,
    "بالغدو": 1,
    "بالغمام": 1,
    "بالغوه": 1,
    "بالغيب": 1,
    "بالغيه": 1,
    "بالفتح": 1,
    "بالفحشاء": 1,
    "بالقارعة": 1,
    "بالقرآن": 1,
    "بالقسط": 1,
    "بالقسطاس": 1,
    "بالقعود": 1,
    "بالقول": 1,
    "بالكافرين": 1,
    "بالكتاب": 1,
    "بالكفر": 1,
    "باللغو": 1,
    "بالليل": 1,
    "بالمؤمنين": 1,
    "بالمتقين": 1,
    "بالمجرمين": 1,
    "بالمرحمة": 1,
    "بالمعتدين": 1,
    "بالمعروف": 1,
    "بالمغفرة": 1,
    "بالمفسدين": 1,
    "بالملإ": 1,
    "بالملائكة": 1,
    "بالمن": 1,
    "بالمنكر": 1,
    "بالمهتدين": 1,
    "بالمودة": 1,
    "بالناصية": 1,
    "بالنبيين": 1,
    "بالنذر": 1,
    "بالنهار": 1,
    "بالنواصي": 1,
    "بالهدى": 1,
    "بالهزل": 1,
    "بالهم": 1,
    "بالواد": 1,
    "بالوحي": 1,
    "بالوصيد": 1,
    "بالوعيد": 1,
    "باليمين": 1,
    "باليوم": 1,
    "بايعتم": 1,
    "ببابل": 1,
    "بباسط": 1,
    "ببالغه": 1,
    "ببالغيه": 1,
    "ببدر": 1,
    "ببدنك": 1,
    "ببضاعة": 1,
    "ببطن": 1,
    "ببعيد": 1,
    "ببغيهم": 1,
    "ببكة": 1,
    "ببني": 1,
    "ببنيه": 1,
    "ببهتان": 1,
    "ببيعكم": 1,
    "ببينة": 1,
    "بتأويل": 1,
    "بتأويله": 1,
    "بتابع": 1,
    "بتاركي": 1,
    "بتحية": 1,
    "بثالث": 1,
    "بثمره": 1,
    "بثي": 1,
    "بجالوت": 1,
    "بجانبه": 1,
    "بجبار": 1,
    "بجناحيه": 1,
    "بجنتيهم": 1,
    "بجنود": 1,
    "بجنوده": 1,
    "بجهازهم": 1,
    "بجهالة": 1,
    "بجهنم": 1,
    "بحاملين": 1,
    "بحجارة": 1,
    "بحديث": 1,
    "بحرب": 1,
    "بحسبان": 1,
    "بحفيظ": 1,
    "بحكمه": 1,
    "بحمد": 1,
    "بحمدك": 1,
    "بحمده": 1,
    "بحور": 1,
    "بخارج": 1,
    "بخارجين": 1,
    "بخازنين": 1,
    "بخالصة": 1,
    "بخبر": 1,
    "بخسا": 1,
    "بخلاقكم": 1,
    "بخلاقهم": 1,
    "بخلق": 1,
    "بخلقهن": 1,
    "بخلوا": 1,
    "بخمرهن": 1,
    "بخمسة": 1,
    "بخير": 1,
    "بخيلك": 1,
    "بدءوكم": 1,
    "بدأكم": 1,
    "بدأنا": 1,
    "بدت": 1,
    "بدخان": 1,
    "بدعا": 1,
    "بدعاء": 1,
    "بدعائك": 1,
    "بدلنا": 1,
    "بدلناهم": 1,
    "بدله": 1,
    "بدلوا": 1,
    "بدم": 1,
    "بديع": 1,
    "بدينار": 1,
    "بدينكم": 1,
    "بذبح": 1,
    "بذكر": 1,
    "بذكرهم": 1,
    "بذنبه": 1,
    "بذنبهم": 1,
    "بذنوب": 1,
    "بذنوبكم": 1,
    "بذنوبنا": 1,
    "بذنوبهم": 1,
    "برءوسكم": 1,
    "برآء": 1,
    "برأسي": 1,
    "براء": 1,
    "برازقين": 1,
    "برب": 1,
    "بربك": 1,
    "بربكم": 1,
    "بربنا": 1,
    "بربه": 1,
    "بربهم": 1,
    "بربوة": 1,
    "بربي": 1,
    "برجال": 1,
    "برجلك": 1,
    "برحمتك": 1,
    "برحمتنا": 1,
    "برحمته": 1,
    "بردا": 1,
    "بردهن": 1,
    "بررة": 1,
    "برزخ": 1,
    "برزخا": 1,
    "برزق": 1,
    "برزوا": 1,
    "برسالاتي": 1,
    "برسل": 1,
    "برسلنا": 1,
    "برسلي": 1,
    "برسول": 1,
    "برسوله": 1,
    "برسولهم": 1,
    "برشيد": 1,
    "برقه": 1,
    "بركات": 1,
    "بركنه": 1,
    "برهانان": 1,
    "برهانكم": 1,
    "بروج": 1,
    "بروجا": 1,
    "بريئا": 1,
    "بريئون": 1,
    "بريح": 1,
    "بزعمهم": 1,
    "بزينة": 1,
    "بسؤال": 1,
    "بسا": 1,
    "بساحتهم": 1,
    "بساطا": 1,
    "بسحر": 1,
    "بسحرك": 1,
    "بسحره": 1,
    "بسحرهما": 1,
    "بسطة": 1,
    "بسطت": 1,
    "بسكارى": 1,
    "بسلطان": 1,
    "بسم": 1,
    "بسمعهم": 1,
    "بسور": 1,
    "بسورة": 1,
    "بسيماهم": 1,
    "بشر": 1,
    "بشرا": 1,
    "بشراكم": 1,
    "بشرر": 1,
    "بشركائهم": 1,
    "بشرككم": 1,
    "بشرناك": 1,
    "بشرى": 1,
    "بشق": 1,
    "بشماله": 1,
    "بشهاب": 1,
    "بشهاداتهم": 1,
    "بشهيد": 1,
    "بشيء": 1,
    "بشير": 1,
    "بشيرا": 1,
    "بصائر": 1,
    "بصاحبكم": 1,
    "بصاحبهم": 1,
    "بصحاف": 1,
    "بصدقة": 1,
    "بصدقهم": 1,
    "بصرت": 1,
    "بصره": 1,
    "بصلاتك": 1,
    "بصوتك": 1,
    "بصيرا": 1,
    "بضارهم": 1,
    "بضارين": 1,
    "بضاعة": 1,
    "بضاعتنا": 1,
    "بضاعتهم": 1,
    "بضر": 1,
    "بضع": 1,
    "بضنين": 1,
    "بضياء": 1,
    "بطائنها": 1,
    "بطارد": 1,
    "بطانة": 1,
    "بطرا": 1,
    "بطرت": 1,
    "بطريقتكم": 1,
    "بطش": 1,
    "بطشا": 1,
    "بطشتم": 1,
    "بطشتنا": 1,
    "بطغواها": 1,
    "بطنه": 1,
    "بطون": 1,
    "بطونه": 1,
    "بطونها": 1,
    "بطونهم": 1,
    "بظاهر": 1,
    "بظلام": 1,
    "بظلمهم": 1,
    "بعاد": 1,
    "بعالمين": 1,
    "بعبادة": 1,
    "بعبادتهم": 1,
    "بعباده": 1,
    "بعبادي": 1,
    "بعبده": 1,
    "بعثرت": 1,
    "بعثكم": 1,
    "بعثنا": 1,
    "بعثناكم": 1,
    "بعثناهم": 1,
    "بعثه": 1,
    "بعجل": 1,
    "بعدا": 1,
    "بعدت": 1,
    "بعدتهم": 1,
    "بعدك": 1,
    "بعدكم": 1,
    "بعده": 1,
    "بعدها": 1,
    "بعدهم": 1,
    "بعدهن": 1,
    "بعذابكم": 1,
    "بعرشها": 1,
    "بعزة": 1,
    "بعزيز": 1,
    "بعشر": 1,
    "بعصاك": 1,
    "بعصم": 1,
    "بعضا": 1,
    "بعضكم": 1,
    "بعضنا": 1,
    "بعضه": 1,
    "بعضهم": 1,
    "بعلا": 1,
    "بعلمه": 1,
    "بعلها": 1,
    "بعلي": 1,
    "بعهد": 1,
    "بعهدكم": 1,
    "بعهده": 1,
    "بعهدهم": 1,
    "بعهدي": 1,
    "بعورة": 1,
    "بعوضة": 1,
    "بعولتهن": 1,
    "بعير": 1,
    "بعيسى": 1,
    "بغائبين": 1,
    "بغافل": 1,
    "بغت": 1,
    "بغتة": 1,
    "بغلام": 1,
    "بغم": 1,
    "بغى": 1,
    "بغي": 1,
    "بغيا": 1,
    "بغيظكم": 1,
    "بغيظهم": 1,
    "بغيكم": 1,
    "بفاتنين": 1,
    "بفاحشة": 1,
    "بفاكهة": 1,
    "بفضل": 1,
    "بقادر": 1,
    "بقبس": 1,
    "بقدر": 1,
    "بقدرها": 1,
    "بقرآن": 1,
    "بقرات": 1,
    "بقربان": 1,
    "بقرة": 1,
    "بقريب": 1,
    "بقلها": 1,
    "بقميصي": 1,
    "بقنطار": 1,
    "بقول": 1,
    "بقوم": 1,
    "بقي": 1,
    "بقية": 1,
    "بقيت": 1,
    "بقيعة": 1,
    "بكأس": 1,
    "بكاف": 1,
    "بكافرين": 1,
    "بكاهن": 1,
    "بكت": 1,
    "بكتاب": 1,
    "بكتابكم": 1,
    "بكتابي": 1,
    "بكفرك": 1,
    "بكفرهم": 1,
    "بكلماته": 1,
    "بكلمة": 1,
    "بكم": 1,
    "بكيدهن": 1,
    "بل": 1,
    "بلاء": 1,
    "بلاغ": 1,
    "بلاغا": 1,
    "بلحيتي": 1,
    "بلدا": 1,
    "بلسان": 1,
    "بلسانك": 1,
    "بلغا": 1,
    "بلغت": 1,
    "بلغن": 1,
    "بلغني": 1,
    "بلغوا": 1,
    "بلقاء": 1,
    "بلونا": 1,
    "بلوناهم": 1,
    "بلى": 1,
    "بليغا": 1,
    "بليل": 1,
    "بمؤمن": 1,
    "بمؤمنين": 1,
    "بمال": 1,
    "بمبعوثين": 1,
    "بمثل": 1,
    "بمثله": 1,
    "بمثلها": 1,
    "بمجنون": 1,
    "بمخرجين": 1,
    "بمزحزحه": 1,
    "بمسبوقين": 1,
    "بمستيقنين": 1,
    "بمسمع": 1,
    "بمصابيح": 1,
    "بمصر": 1,
    "بمصرخكم": 1,
    "بمصرخي": 1,
    "بمصيطر": 1,
    "بمعجز": 1,
    "بمعجزين": 1,
    "بمعذبين": 1,
    "بمعروف": 1,
    "بمغفرة": 1,
    "بمفازة": 1,
    "بمفازتهم": 1,
    "بمقعدهم": 1,
    "بمكرهن": 1,
    "بملكنا": 1,
    "بملوم": 1,
    "بمن": 1,
    "بمنشرين": 1,
    "بمواقع": 1,
    "بموسى": 1,
    "بميت": 1,
    "بميتين": 1,
    "بميثاقهم": 1,
    "بنا": 1,
    "بناتك": 1,
    "بناصيتها": 1,
    "بنان": 1,
    "بنانه": 1,
    "بناها": 1,
    "بنبإ": 1,
    "بنخل": 1,
    "بنصب": 1,
    "بنصر": 1,
    "بنصره": 1,
    "بنعمة": 1,
    "بنعمت": 1,
    "بنعمته": 1,
    "بنفسك": 1,
    "بنميم": 1,
    "بنو": 1,
    "بنوا": 1,
    "بنورهم": 1,
    "بنون": 1,
    "بنيانا": 1,
    "بنيانه": 1,
    "بنيانهم": 1,
    "بنيناها": 1,
    "بنيه": 1,
    "بهاد": 1,
    "بهادي": 1,
    "بهتان": 1,
    "بهتانا": 1,
    "بهدية": 1,
    "بهديتكم": 1,
    "بهما": 1,
    "بهن": 1,
    "بهيمة": 1,
    "بوأنا": 1,
    "بواحدة": 1,
    "بواد": 1,
    "بوالدتي": 1,
    "بوالديه": 1,
    "بوجهه": 1,
    "بوجوهكم": 1,
    "بورا": 1,
    "بورقكم": 1,
    "بوكيل": 1,
    "بولده": 1,
    "بولدها": 1,
    "بياتا": 1,
    "بيانه": 1,
    "بيتا": 1,
    "بيتك": 1,
    "بيته": 1,
    "بيتها": 1,
    "بيحيى": 1,
    "بيدك": 1,
    "بيدي": 1,
    "بيمينك": 1,
    "بيمينه": 1,
    "بينات": 1,
    "بيناه": 1,
    "بينة": 1,
    "بينت": 1,
    "بينك": 1,
    "بينكم": 1,
    "بيننا": 1,
    "بينهم": 1,
    "بينهن": 1,
    "بيوتا": 1,
    "بيوتكم": 1,
    "بيوتكن": 1,
    "بيوتنا": 1,
    "بيوتهم": 1,
    "بيوتهن": 1,
    "بيوسف": 1,
    "تأتنا": 1,
    "تأتهم": 1,
    "تأتوا": 1,
    "تأتوننا": 1,
    "تأتوني": 1,
    "تأتيكم": 1,
    "تأتينا": 1,
    "تأتيهم": 1,
    "تأثيم": 1,
    "تأثيما": 1,
    "تأجرني": 1,
    "تأخذ": 1,
    "تأخذكم": 1,
    "تأخذه": 1,
    "تأخذهم": 1,
    "تأخذوا": 1,
    "تأخذونه": 1,
    "تأخذونها": 1,
    "تأذن": 1,
    "تأس": 1,
    "تأسوا": 1,
    "تأكل": 1,
    "تأكله": 1,
    "تأكلوا": 1,
    "تأكلون": 1,
    "تأكلوها": 1,
    "تألمون": 1,
    "تأمرك": 1,
    "تأمرنا": 1,
    "تأمرهم": 1,
    "تأمرون": 1,
    "تأمروننا": 1,
    "تأمروني": 1,
    "تأمرين": 1,
    "تأمنا": 1,
    "تأمنه": 1,
    "تأويل": 1,
    "تأويلا": 1,
    "تأويله": 1,
    "تؤاخذنا": 1,
    "تؤاخذني": 1,
    "تؤتوا": 1,
    "تؤتون": 1,
    "تؤتونهن": 1,
    "تؤتوه": 1,
    "تؤتي": 1,
    "تؤثرون": 1,
    "تؤدوا": 1,
    "تؤذوا": 1,
    "تؤذونني": 1,
    "تؤزهم": 1,
    "تؤفكون": 1,
    "تؤمر": 1,
    "تؤمرون": 1,
    "تؤمن": 1,
    "تؤمنوا": 1,
    "تؤمنون": 1,
    "تؤويه": 1,
    "تائبات": 1,
    "تاب": 1,
    "تابوا": 1,
    "تارة": 1,
    "تارك": 1,
    "تالله": 1,
    "تباب": 1,
    "تبارا": 1,
    "تبارك": 1,
    "تباشروهن": 1,
    "تبايعتم": 1,
    "تبت": 1,
    "تبتئس": 1,
    "تبتغوا": 1,
    "تبتغون": 1,
    "تبتغي": 1,
    "تبتم": 1,
    "تبتيلا": 1,
    "تبخسوا": 1,
    "تبخلوا": 1,
    "تبد": 1,
    "تبدوا": 1,
    "تبدون": 1,
    "تبدونها": 1,
    "تبدوه": 1,
    "تبديلا": 1,
    "تبذر": 1,
    "تبذيرا": 1,
    "تبرءوا": 1,
    "تبرأنا": 1,
    "تبرج": 1,
    "تبرجن": 1,
    "تبرنا": 1,
    "تبروا": 1,
    "تبروهم": 1,
    "تبسطها": 1,
    "تبسل": 1,
    "تبشرون": 1,
    "تبصرة": 1,
    "تبصرون": 1,
    "تبطلوا": 1,
    "تبع": 1,
    "تبعا": 1,
    "تبعثون": 1,
    "تبعك": 1,
    "تبعني": 1,
    "تبعوا": 1,
    "تبغ": 1,
    "تبغوا": 1,
    "تبغونها": 1,
    "تبغي": 1,
    "تبكون": 1,
    "تبلغ": 1,
    "تبلو": 1,
    "تبلى": 1,
    "تبوء": 1,
    "تبوءوا": 1,
    "تبوآ": 1,
    "تبوئ": 1,
    "تبور": 1,
    "تبيانا": 1,
    "تبيد": 1,
    "تبيض": 1,
    "تبيعا": 1,
    "تبين": 1,
    "تبينت": 1,
    "تتبدلوا": 1,
    "تتبعان": 1,
    "تتبعن": 1,
    "تتبعها": 1,
    "تتبعوا": 1,
    "تتبعون": 1,
    "تتبعونا": 1,
    "تتبيب": 1,
    "تتبيرا": 1,
    "تتجافى": 1,
    "تتخذ": 1,
    "تتخذوا": 1,
    "تتخذون": 1,
    "تتذكرون": 1,
    "تتركه": 1,
    "تتركوا": 1,
    "تترى": 1,
    "تتفرقوا": 1,
    "تتفكروا": 1,
    "تتفكرون": 1,
    "تتقلب": 1,
    "تتقوا": 1,
    "تتقون": 1,
    "تتكبر": 1,
    "تتلو": 1,
    "تتلى": 1,
    "تتمارى": 1,
    "تتمنوا": 1,
    "تتناجوا": 1,
    "تتنزل": 1,
    "تتوبا": 1,
    "تتوفاهم": 1,
    "تتولوا": 1,
    "تثبيتا": 1,
    "تثريب": 1,
    "تثقفنهم": 1,
    "تثير": 1,
    "تجأروا": 1,
    "تجأرون": 1,
    "تجادلك": 1,
    "تجادلوا": 1,
    "تجارتهم": 1,
    "تجتنبوا": 1,
    "تجد": 1,
    "تجدوا": 1,
    "تجدوه": 1,
    "تجرمون": 1,
    "تجري": 1,
    "تجريان": 1,
    "تجزون": 1,
    "تجزى": 1,
    "تجزي": 1,
    "تجسسوا": 1,
    "تجعلنا": 1,
    "تجعلني": 1,
    "تجعلوا": 1,
    "تجعلونه": 1,
    "تجلى": 1,
    "تجمعوا": 1,
    "تجهر": 1,
    "تجهروا": 1,
    "تجهلون": 1,
    "تجوع": 1,
    "تحاجون": 1,
    "تحاضون": 1,
    "تحاوركما": 1,
    "تحبرون": 1,
    "تحبسونهما": 1,
    "تحبط": 1,
    "تحبوا": 1,
    "تحبون": 1,
    "تحبونها": 1,
    "تحبونهم": 1,
    "تحتك": 1,
    "تحتها": 1,
    "تحتهم": 1,
    "تحذرون": 1,
    "تحرثون": 1,
    "تحرص": 1,
    "تحرم": 1,
    "تحرموا": 1,
    "تحروا": 1,
    "تحزن": 1,
    "تحزنوا": 1,
    "تحزنون": 1,
    "تحزني": 1,
    "تحس": 1,
    "تحسبن": 1,
    "تحسبنهم": 1,
    "تحسبها": 1,
    "تحسبهم": 1,
    "تحسبوه": 1,
    "تحسدوننا": 1,
    "تحسنوا": 1,
    "تحسونهم": 1,
    "تحشرون": 1,
    "تحصنا": 1,
    "تحصنون": 1,
    "تحصوه": 1,
    "تحصوها": 1,
    "تحط": 1,
    "تحكموا": 1,
    "تحكمون": 1,
    "تحل": 1,
    "تحلة": 1,
    "تحلقوا": 1,
    "تحلوا": 1,
    "تحملنا": 1,
    "تحمله": 1,
    "تحملون": 1,
    "تحنث": 1,
    "تحويلا": 1,
    "تحية": 1,
    "تحيتهم": 1,
    "تحيد": 1,
    "تحيطوا": 1,
    "تحيون": 1,
    "تحيي": 1,
    "تخاصم": 1,
    "تخاطبني": 1,
    "تخاف": 1,
    "تخافا": 1,
    "تخافت": 1,
    "تخافن": 1,
    "تخافوا": 1,
    "تخافون": 1,
    "تخافونهم": 1,
    "تخافوهم": 1,
    "تخافي": 1,
    "تخالطوهم": 1,
    "تختانون": 1,
    "تختصموا": 1,
    "تختصمون": 1,
    "تختلفون": 1,
    "تخرجوا": 1,
    "تخرجون": 1,
    "تخرجوهن": 1,
    "تخرصون": 1,
    "تخرق": 1,
    "تخزنا": 1,
    "تخزني": 1,
    "تخزون": 1,
    "تخسروا": 1,
    "تخسير": 1,
    "تخشاه": 1,
    "تخشع": 1,
    "تخشوا": 1,
    "تخشون": 1,
    "تخشوه": 1,
    "تخشوهم": 1,
    "تخشى": 1,
    "تخضعن": 1,
    "تخطه": 1,
    "تخف": 1,
    "تخفوا": 1,
    "تخفون": 1,
    "تخفوه": 1,
    "تخفوها": 1,
    "تخفي": 1,
    "تخلدون": 1,
    "تخلفه": 1,
    "تخلق": 1,
    "تخلقونه": 1,
    "تخوف": 1,
    "تخونوا": 1,
    "تخويفا": 1,
    "تخيرون": 1,
    "تداركه": 1,
    "تداينتم": 1,
    "تدخرون": 1,
    "تدخلوا": 1,
    "تدخلوها": 1,
    "تدرسون": 1,
    "تدرك": 1,
    "تدركه": 1,
    "تدرون": 1,
    "تدري": 1,
    "تدع": 1,
    "تدعهم": 1,
    "تدعو": 1,
    "تدعوا": 1,
    "تدعون": 1,
    "تدعونا": 1,
    "تدعوننا": 1,
    "تدعونني": 1,
    "تدعونه": 1,
    "تدعوهم": 1,
    "تدعى": 1,
    "تدميرا": 1,
    "تدهن": 1,
    "تديرونها": 1,
    "تذبحوا": 1,
    "تذر": 1,
    "تذرن": 1,
    "تذرني": 1,
    "تذرهم": 1,
    "تذروه": 1,
    "تذكرة": 1,
    "تذكروا": 1,
    "تذكرون": 1,
    "تذليلا": 1,
    "تذهب": 1,
    "تذهبوا": 1,
    "تذهبون": 1,
    "تذهل": 1,
    "تذودان": 1,
    "تر": 1,
    "تراءت": 1,
    "تراءى": 1,
    "تراب": 1,
    "تراض": 1,
    "تراضوا": 1,
    "تراضيتم": 1,
    "تراني": 1,
    "تراهم": 1,
    "تراود": 1,
    "تربص": 1,
    "تربصوا": 1,
    "تربصون": 1,
    "ترتابوا": 1,
    "ترتدوا": 1,
    "ترتيلا": 1,
    "ترثوا": 1,
    "ترجع": 1,
    "ترجعون": 1,
    "ترجعونها": 1,
    "ترجعوهن": 1,
    "ترجف": 1,
    "ترجمون": 1,
    "ترجو": 1,
    "ترجون": 1,
    "ترجوها": 1,
    "ترجي": 1,
    "ترحمون": 1,
    "ترد": 1,
    "تردن": 1,
    "تردون": 1,
    "ترزقانه": 1,
    "ترضاه": 1,
    "ترضاها": 1,
    "ترضوا": 1,
    "ترضون": 1,
    "ترضونها": 1,
    "ترضى": 1,
    "ترفعوا": 1,
    "ترقب": 1,
    "ترقى": 1,
    "تركبون": 1,
    "تركت": 1,
    "تركتم": 1,
    "تركتموها": 1,
    "تركضوا": 1,
    "تركن": 1,
    "تركنا": 1,
    "تركناها": 1,
    "تركنوا": 1,
    "تركوا": 1,
    "ترمي": 1,
    "ترميهم": 1,
    "ترن": 1,
    "ترهبون": 1,
    "ترهقني": 1,
    "ترهقها": 1,
    "ترهقهم": 1,
    "تروا": 1,
    "ترون": 1,
    "ترونها": 1,
    "ترونهم": 1,
    "تروها": 1,
    "تريحون": 1,
    "تريد": 1,
    "تريدون": 1,
    "ترين": 1,
    "تريني": 1,
    "تزال": 1,
    "تزاور": 1,
    "تزد": 1,
    "تزداد": 1,
    "تزدري": 1,
    "تزر": 1,
    "تزرعون": 1,
    "تزرعونه": 1,
    "تزعمون": 1,
    "تزغ": 1,
    "تزكوا": 1,
    "تزكى": 1,
    "تزولا": 1,
    "تزيدونني": 1,
    "تزيلوا": 1,
    "تسأل": 1,
    "تسألن": 1,
    "تسألني": 1,
    "تسألهم": 1,
    "تسألوا": 1,
    "تسألون": 1,
    "تسأموا": 1,
    "تسؤكم": 1,
    "تسؤهم": 1,
    "تساءلون": 1,
    "تسبح": 1,
    "تسبحون": 1,
    "تسبق": 1,
    "تسبوا": 1,
    "تسبيحهم": 1,
    "تستأخرون": 1,
    "تستأنسوا": 1,
    "تستترون": 1,
    "تستخفونها": 1,
    "تسترضعوا": 1,
    "تستطع": 1,
    "تستطيع": 1,
    "تستطيعوا": 1,
    "تستطيعون": 1,
    "تستعجل": 1,
    "تستعجلون": 1,
    "تستعجلوه": 1,
    "تستغفر": 1,
    "تستغفرون": 1,
    "تستغيثون": 1,
    "تستفت": 1,
    "تستفتحوا": 1,
    "تستفتيان": 1,
    "تستقدمون": 1,
    "تستقسموا": 1,
    "تستكبرون": 1,
    "تستكثر": 1,
    "تستمعون": 1,
    "تستهزئون": 1,
    "تستوي": 1,
    "تسجد": 1,
    "تسجدوا": 1,
    "تسحرون": 1,
    "تسخروا": 1,
    "تسخرون": 1,
    "تسر": 1,
    "تسرحون": 1,
    "تسرفوا": 1,
    "تسرون": 1,
    "تسطع": 1,
    "تسعا": 1,
    "تسعى": 1,
    "تسفكون": 1,
    "تسقط": 1,
    "تسقى": 1,
    "تسقي": 1,
    "تسكن": 1,
    "تسكنون": 1,
    "تسلمون": 1,
    "تسليما": 1,
    "تسمع": 1,
    "تسمعوا": 1,
    "تسمعون": 1,
    "تسنيم": 1,
    "تسوروا": 1,
    "تسوى": 1,
    "تسيمون": 1,
    "تشاء": 1,
    "تشاءون": 1,
    "تشابهت": 1,
    "تشاقون": 1,
    "تشتروا": 1,
    "تشتهي": 1,
    "تشتهيه": 1,
    "تشخص": 1,
    "تشربون": 1,
    "تشرك": 1,
    "تشركوا": 1,
    "تشركون": 1,
    "تشطط": 1,
    "تشعرون": 1,
    "تشقق": 1,
    "تشكروا": 1,
    "تشكرون": 1,
    "تشمت": 1,
    "تشهد": 1,
    "تشهدون": 1,
    "تصاحبني": 1,
    "تصبحون": 1,
    "تصبر": 1,
    "تصبروا": 1,
    "تصبك": 1,
    "تصبكم": 1,
    "تصبهم": 1,
    "تصدق": 1,
    "تصدقوا": 1,
    "تصدقون": 1,
    "تصدون": 1,
    "تصدونا": 1,
    "تصدى": 1,
    "تصرفون": 1,
    "تصطلون": 1,
    "تصعدون": 1,
    "تصعر": 1,
    "تصف": 1,
    "تصفون": 1,
    "تصل": 1,
    "تصلحوا": 1,
    "تصلى": 1,
    "تصنعون": 1,
    "تصوموا": 1,
    "تصيبن": 1,
    "تصيبنا": 1,
    "تصيبهم": 1,
    "تصيبوا": 1,
    "تصير": 1,
    "تضار": 1,
    "تضاروهن": 1,
    "تضحكون": 1,
    "تضحى": 1,
    "تضربوا": 1,
    "تضرعا": 1,
    "تضرعوا": 1,
    "تضرونه": 1,
    "تضروه": 1,
    "تضعوا": 1,
    "تضعون": 1,
    "تضل": 1,
    "تضلوا": 1,
    "تضليل": 1,
    "تطئوها": 1,
    "تطئوهم": 1,
    "تطرد": 1,
    "تطع": 1,
    "تطعمون": 1,
    "تطعه": 1,
    "تطعهما": 1,
    "تطغوا": 1,
    "تطلع": 1,
    "تطمئن": 1,
    "تطهرن": 1,
    "تطهرهم": 1,
    "تطهيرا": 1,
    "تطيرنا": 1,
    "تطيعوا": 1,
    "تطيعوه": 1,
    "تظاهرا": 1,
    "تظاهرون": 1,
    "تظلم": 1,
    "تظلموا": 1,
    "تظلمون": 1,
    "تظمأ": 1,
    "تظن": 1,
    "تظهرون": 1,
    "تعاسرتم": 1,
    "تعالوا": 1,
    "تعالى": 1,
    "تعاونوا": 1,
    "تعبثون": 1,
    "تعبد": 1,
    "تعبدوا": 1,
    "تعبدون": 1,
    "تعبرون": 1,
    "تعتدوا": 1,
    "تعتدونها": 1,
    "تعتدوها": 1,
    "تعتذروا": 1,
    "تعثوا": 1,
    "تعجب": 1,
    "تعجبك": 1,
    "تعجبون": 1,
    "تعجل": 1,
    "تعدلوا": 1,
    "تعدنا": 1,
    "تعدوا": 1,
    "تعدون": 1,
    "تعذب": 1,
    "تعذبهم": 1,
    "تعرضن": 1,
    "تعرضوا": 1,
    "تعرضون": 1,
    "تعرف": 1,
    "تعرفهم": 1,
    "تعرى": 1,
    "تعزموا": 1,
    "تعضلوهن": 1,
    "تعظون": 1,
    "تعفوا": 1,
    "تعقلون": 1,
    "تعلمن": 1,
    "تعلمها": 1,
    "تعلمهم": 1,
    "تعلموا": 1,
    "تعلمون": 1,
    "تعلمونهم": 1,
    "تعلمونهن": 1,
    "تعلموهم": 1,
    "تعلنون": 1,
    "تعلوا": 1,
    "تعمدت": 1,
    "تعملون": 1,
    "تعمى": 1,
    "تعودوا": 1,
    "تعودون": 1,
    "تعولوا": 1,
    "تغتسلوا": 1,
    "تغرنكم": 1,
    "تغشاها": 1,
    "تغفر": 1,
    "تغفلون": 1,
    "تغلبون": 1,
    "تغلوا": 1,
    "تغمضوا": 1,
    "تغن": 1,
    "تغني": 1,
    "تغيض": 1,
    "تغيظا": 1,
    "تفادوهم": 1,
    "تفتأ": 1,
    "تفتروا": 1,
    "تفترون": 1,
    "تفتنون": 1,
    "تفتني": 1,
    "تفثهم": 1,
    "تفجر": 1,
    "تفجيرا": 1,
    "تفرح": 1,
    "تفرحوا": 1,
    "تفرحون": 1,
    "تفرضوا": 1,
    "تفرق": 1,
    "تفرقوا": 1,
    "تفرون": 1,
    "تفسحوا": 1,
    "تفسدوا": 1,
    "تفسقون": 1,
    "تفسيرا": 1,
    "تفشلا": 1,
    "تفصيلا": 1,
    "تفضحون": 1,
    "تفضيلا": 1,
    "تفعل": 1,
    "تفعلوا": 1,
    "تفعلون": 1,
    "تفعلوه": 1,
    "تفقدون": 1,
    "تفقهون": 1,
    "تفكهون": 1,
    "تفلحوا": 1,
    "تفلحون": 1,
    "تفندون": 1,
    "تفور": 1,
    "تفيء": 1,
    "تفيض": 1,
    "تفيضون": 1,
    "تق": 1,
    "تقاة": 1,
    "تقاتل": 1,
    "تقاتلوا": 1,
    "تقاتلون": 1,
    "تقاتلونهم": 1,
    "تقاتلوهم": 1,
    "تقاته": 1,
    "تقاسموا": 1,
    "تقبلوا": 1,
    "تقتلني": 1,
    "تقتلوا": 1,
    "تقتلون": 1,
    "تقتلوه": 1,
    "تقتلوهم": 1,
    "تقتيلا": 1,
    "تقدروا": 1,
    "تقدموا": 1,
    "تقديرا": 1,
    "تقر": 1,
    "تقربا": 1,
    "تقربكم": 1,
    "تقربوا": 1,
    "تقربون": 1,
    "تقربوها": 1,
    "تقربوهن": 1,
    "تقرضهم": 1,
    "تقرضوا": 1,
    "تقسطوا": 1,
    "تقسموا": 1,
    "تقشعر": 1,
    "تقصروا": 1,
    "تقصص": 1,
    "تقضي": 1,
    "تقعد": 1,
    "تقعدوا": 1,
    "تقف": 1,
    "تقل": 1,
    "تقلبهم": 1,
    "تقلبون": 1,
    "تقم": 1,
    "تقنطوا": 1,
    "تقهر": 1,
    "تقواهم": 1,
    "تقولن": 1,
    "تقوله": 1,
    "تقولوا": 1,
    "تقولون": 1,
    "تقوموا": 1,
    "تقوى": 1,
    "تقيا": 1,
    "تقيكم": 1,
    "تقيموا": 1,
    "تك": 1,
    "تكاد": 1,
    "تكبيرا": 1,
    "تكتبوه": 1,
    "تكتبوها": 1,
    "تكتموا": 1,
    "تكتمون": 1,
    "تكتمونه": 1,
    "تكذبان": 1,
    "تكذبوا": 1,
    "تكذبون": 1,
    "تكذيب": 1,
    "تكرمون": 1,
    "تكره": 1,
    "تكرهوا": 1,
    "تكسبون": 1,
    "تكفر": 1,
    "تكفروا": 1,
    "تكفرون": 1,
    "تكلف": 1,
    "تكلمهم": 1,
    "تكلمون": 1,
    "تكليما": 1,
    "تكن": 1,
    "تكنزون": 1,
    "تكونا": 1,
    "تكونن": 1,
    "تكونوا": 1,
    "تلاها": 1,
    "تلاوته": 1,
    "تلبثوا": 1,
    "تلبسوا": 1,
    "تلبسون": 1,
    "تلبسونها": 1,
    "تلظى": 1,
    "تلفح": 1,
    "تلقاء": 1,
    "تلقف": 1,
    "تلقوا": 1,
    "تلقون": 1,
    "تلقونه": 1,
    "تلقوه": 1,
    "تلكم": 1,
    "تلكما": 1,
    "تلمزوا": 1,
    "تلهكم": 1,
    "تلهى": 1,
    "تلهيهم": 1,
    "تلوته": 1,
    "تلوموني": 1,
    "تلووا": 1,
    "تلوون": 1,
    "تليت": 1,
    "تلين": 1,
    "تمار": 1,
    "تمت": 1,
    "تمترن": 1,
    "تمترون": 1,
    "تمتعوا": 1,
    "تمتعون": 1,
    "تمدن": 1,
    "تمرحون": 1,
    "تمسسكم": 1,
    "تمسسه": 1,
    "تمسكوا": 1,
    "تمسكوهن": 1,
    "تمسنا": 1,
    "تمسون": 1,
    "تمسوها": 1,
    "تمسوهن": 1,
    "تمشون": 1,
    "تمشي": 1,
    "تمكرون": 1,
    "تملكهم": 1,
    "تملكون": 1,
    "تملى": 1,
    "تمنعهم": 1,
    "تمنن": 1,
    "تمنها": 1,
    "تمنوا": 1,
    "تمنون": 1,
    "تمنى": 1,
    "تمهيدا": 1,
    "تموت": 1,
    "تموتن": 1,
    "تموتون": 1,
    "تميد": 1,
    "تميلوا": 1,
    "تنابزوا": 1,
    "تناجيتم": 1,
    "تنازعتم": 1,
    "تنازعوا": 1,
    "تناصرون": 1,
    "تناله": 1,
    "تنالوا": 1,
    "تنبئهم": 1,
    "تنبئونه": 1,
    "تنبت": 1,
    "تنبتوا": 1,
    "تنتشرون": 1,
    "تنتصران": 1,
    "تنته": 1,
    "تنتهوا": 1,
    "تنجيكم": 1,
    "تنحتون": 1,
    "تنذر": 1,
    "تنذرهم": 1,
    "تنزع": 1,
    "تنزل": 1,
    "تنزلت": 1,
    "تنزيلا": 1,
    "تنسوا": 1,
    "تنسى": 1,
    "تنصروا": 1,
    "تنصرون": 1,
    "تنصروه": 1,
    "تنطقون": 1,
    "تنظرون": 1,
    "تنفد": 1,
    "تنفذوا": 1,
    "تنفذون": 1,
    "تنفروا": 1,
    "تنفع": 1,
    "تنفعكم": 1,
    "تنفعها": 1,
    "تنفعهم": 1,
    "تنفقوا": 1,
    "تنفقون": 1,
    "تنقص": 1,
    "تنقصوا": 1,
    "تنقضوا": 1,
    "تنقم": 1,
    "تنقمون": 1,
    "تنكح": 1,
    "تنكحوا": 1,
    "تنكحوهن": 1,
    "تنكرون": 1,
    "تنكصون": 1,
    "تنكيلا": 1,
    "تنهر": 1,
    "تنهرهما": 1,
    "تنهون": 1,
    "تنهى": 1,
    "تنيا": 1,
    "تهتدوا": 1,
    "تهتدون": 1,
    "تهتز": 1,
    "تهجرون": 1,
    "تهدوا": 1,
    "تهدي": 1,
    "تهنوا": 1,
    "تهوى": 1,
    "تهوي": 1,
    "تواب": 1,
    "توابا": 1,
    "توارت": 1,
    "تواعدتم": 1,
    "تواعدوهن": 1,
    "توبتهم": 1,
    "توبوا": 1,
    "توجل": 1,
    "توجه": 1,
    "تورون": 1,
    "توسوس": 1,
    "توصون": 1,
    "توصية": 1,
    "توعدون": 1,
    "توعظون": 1,
    "توفاهم": 1,
    "توفته": 1,
    "توفتهم": 1,
    "توفني": 1,
    "توفون": 1,
    "توفى": 1,
    "توفيتني": 1,
    "توفيقي": 1,
    "توقدون": 1,
    "توقنون": 1,
    "توكلت": 1,
    "توكلنا": 1,
    "توكلوا": 1,
    "توكيدها": 1,
    "تولاه": 1,
    "تولج": 1,
    "تولوا": 1,
    "تولون": 1,
    "تولوهم": 1,
    "توليتم": 1,
    "تيأسوا": 1,
    "تيسر": 1,
    "تيمموا": 1,
    "ثاويا": 1,
    "ثبتناك": 1,
    "ثبوتها": 1,
    "ثبورا": 1,
    "ثجاجا": 1,
    "ثقالا": 1,
    "ثقفتموهم": 1,
    "ثقفوا": 1,
    "ثقلت": 1,
    "ثقيلا": 1,
    "ثلاثين": 1,
    "ثلثا": 1,
    "ثمانين": 1,
    "ثمرات": 1,
    "ثمره": 1,
    "ثمنا": 1,
    "ثمود": 1,
    "ثواب": 1,
    "ثوابا": 1,
    "ثيابا": 1,
    "ثيابكم": 1,
    "ثيابهم": 1,
    "ثيابهن": 1,
    "ثيبات": 1,
    "جئت": 1,
    "جئتك": 1,
    "جئتكم": 1,
    "جئتم": 1,
    "جئتمونا": 1,
    "جئتنا": 1,
    "جئتهم": 1,
    "جئنا": 1,
    "جئناك": 1,
    "جئناكم": 1,
    "جئناهم": 1,
    "جاءت": 1,
    "جاءتك": 1,
    "جاءتكم": 1,
    "جاءتنا": 1,
    "جاءته": 1,
    "جاءتها": 1,
    "جاءتهم": 1,
    "جاءك": 1,
    "جاءكم": 1,
    "جاءنا": 1,
    "جاءني": 1,
    "جاءه": 1,
    "جاءها": 1,
    "جاءهم": 1,
    "جاءوا": 1,
    "جاءوك": 1,
    "جاءوكم": 1,
    "جاءوها": 1,
    "جابوا": 1,
    "جاثمين": 1,
    "جاثية": 1,
    "جادلتم": 1,
    "جادلتنا": 1,
    "جادلوك": 1,
    "جار": 1,
    "جارية": 1,
    "جاز": 1,
    "جاعلك": 1,
    "جالوت": 1,
    "جامدة": 1,
    "جان": 1,
    "جاهد": 1,
    "جاهداك": 1,
    "جاهدوا": 1,
    "جاهلون": 1,
    "جاوزا": 1,
    "جاوزه": 1,
    "جبارا": 1,
    "جبارين": 1,
    "جباههم": 1,
    "جبلا": 1,
    "جثيا": 1,
    "جحدوا": 1,
    "جحيم": 1,
    "جدارا": 1,
    "جدالنا": 1,
    "جدر": 1,
    "جدلا": 1,
    "جذاذا": 1,
    "جذوة": 1,
    "جذوع": 1,
    "جرحتم": 1,
    "جرزا": 1,
    "جزءا": 1,
    "جزاء": 1,
    "جزاؤكم": 1,
    "جزاؤه": 1,
    "جزاؤهم": 1,
    "جزوعا": 1,
    "جزيتهم": 1,
    "جزيناهم": 1,
    "جسدا": 1,
    "جعلا": 1,
    "جعلتم": 1,
    "جعلته": 1,
    "جعلكم": 1,
    "جعلنا": 1,
    "جعلناك": 1,
    "جعلناكم": 1,
    "جعلناه": 1,
    "جعلناها": 1,
    "جعلناهم": 1,
    "جعلوا": 1,
    "جفاء": 1,
    "جلابيبهن": 1,
    "جلاها": 1,
    "جلودا": 1,
    "جلودكم": 1,
    "جلودهم": 1,
    "جما": 1,
    "جمالت": 1,
    "جمعا": 1,
    "جمعكم": 1,
    "جمعناكم": 1,
    "جمعناهم": 1,
    "جمعهم": 1,
    "جمعوا": 1,
    "جميعا": 1,
    "جميلا": 1,
    "جنات": 1,
    "جناحك": 1,
    "جنة": 1,
    "جنتان": 1,
    "جنتك": 1,
    "جنته": 1,
    "جنتي": 1,
    "جنتين": 1,
    "جنحوا": 1,
    "جند": 1,
    "جندا": 1,
    "جندنا": 1,
    "جنفا": 1,
    "جنوبكم": 1,
    "جنوبها": 1,
    "جنوبهم": 1,
    "جنودا": 1,
    "جنوده": 1,
    "جنيا": 1,
    "جهادا": 1,
    "جهاده": 1,
    "جهدهم": 1,
    "جهر": 1,
    "جهرة": 1,
    "جهزهم": 1,
    "جهولا": 1,
    "جواب": 1,
    "جوفه": 1,
    "جيبك": 1,
    "جيدها": 1,
    "جيوبهن": 1,
    "حاج": 1,
    "حاججتم": 1,
    "حاجزا": 1,
    "حاجزين": 1,
    "حاجك": 1,
    "حاجوك": 1,
    "حاذرون": 1,
    "حاسبين": 1,
    "حاسد": 1,
    "حاش": 1,
    "حاشرين": 1,
    "حاصبا": 1,
    "حاضرا": 1,
    "حاضرة": 1,
    "حاضري": 1,
    "حافظا": 1,
    "حافظات": 1,
    "حافظوا": 1,
    "حافظون": 1,
    "حافظين": 1,
    "حافين": 1,
    "حام": 1,
    "حبالهم": 1,
    "حبة": 1,
    "حبط": 1,
    "حبطت": 1,
    "حبه": 1,
    "حثيثا": 1,
    "حج": 1,
    "حجابا": 1,
    "حجة": 1,
    "حجتنا": 1,
    "حجتهم": 1,
    "حجج": 1,
    "حجرا": 1,
    "حجوركم": 1,
    "حدب": 1,
    "حدوده": 1,
    "حديدا": 1,
    "حذركم": 1,
    "حذرهم": 1,
    "حرا": 1,
    "حراما": 1,
    "حرث": 1,
    "حرثكم": 1,
    "حرثه": 1,
    "حرجا": 1,
    "حرد": 1,
    "حرسا": 1,
    "حرصت": 1,
    "حرصتم": 1,
    "حرضا": 1,
    "حرقوه": 1,
    "حرما": 1,
    "حرمات": 1,
    "حرمت": 1,
    "حرمنا": 1,
    "حرمها": 1,
    "حرمهما": 1,
    "حزب": 1,
    "حزبه": 1,
    "حسابا": 1,
    "حسابك": 1,
    "حسابه": 1,
    "حسابهم": 1,
    "حسابيه": 1,
    "حسان": 1,
    "حسبانا": 1,
    "حسبتم": 1,
    "حسبته": 1,
    "حسبتهم": 1,
    "حسبك": 1,
    "حسبنا": 1,
    "حسبه": 1,
    "حسبهم": 1,
    "حسبي": 1,
    "حسد": 1,
    "حسرات": 1,
    "حسرة": 1,
    "حسرتا": 1,
    "حسرتنا": 1,
    "حسنات": 1,
    "حسنة": 1,
    "حسنت": 1,
    "حسنهن": 1,
    "حسوما": 1,
    "حسيبا": 1,
    "حسير": 1,
    "حسيسها": 1,
    "حشر": 1,
    "حشرت": 1,
    "حشرتني": 1,
    "حصاده": 1,
    "حصب": 1,
    "حصحص": 1,
    "حصدتم": 1,
    "حصرت": 1,
    "حصونهم": 1,
    "حصيدا": 1,
    "حصيرا": 1,
    "حضروه": 1,
    "حطاما": 1,
    "حطبا": 1,
    "حطة": 1,
    "حفرة": 1,
    "حفظة": 1,
    "حفظهما": 1,
    "حفي": 1,
    "حفيا": 1,
    "حفيظ": 1,
    "حفيظا": 1,
    "حقبا": 1,
    "حقت": 1,
    "حقه": 1,
    "حقيق": 1,
    "حكمتم": 1,
    "حكمه": 1,
    "حكيما": 1,
    "حلا": 1,
    "حلاف": 1,
    "حلالا": 1,
    "حلفتم": 1,
    "حللتم": 1,
    "حليما": 1,
    "حليهم": 1,
    "حمإ": 1,
    "حمئة": 1,
    "حمارك": 1,
    "حملا": 1,
    "حملت": 1,
    "حملتم": 1,
    "حملته": 1,
    "حملنا": 1,
    "حملناكم": 1,
    "حملهن": 1,
    "حملوا": 1,
    "حمية": 1,
    "حميدا": 1,
    "حميم": 1,
    "حميما": 1,
    "حنفاء": 1,
    "حنيذ": 1,
    "حنيفا": 1,
    "حنين": 1,
    "حوبا": 1,
    "حوتهما": 1,
    "حور": 1,
    "حولا": 1,
    "حولك": 1,
    "حولكم": 1,
    "حولها": 1,
    "حولهم": 1,
    "حولين": 1,
    "حيا": 1,
    "حياتكم": 1,
    "حياتنا": 1,
    "حيتانهم": 1,
    "حيران": 1,
    "حيلة": 1,
    "حينئذ": 1,
    "حيوك": 1,
    "حييتم": 1,
    "خائبين": 1,
    "خائفا": 1,
    "خائفين": 1,
    "خاب": 1,
    "خادعهم": 1,
    "خاسئا": 1,
    "خاسئين": 1,
    "خاسرة": 1,
    "خاسرين": 1,
    "خاشعا": 1,
    "خاشعة": 1,
    "خاشعون": 1,
    "خاشعين": 1,
    "خاضعين": 1,
    "خاضوا": 1,
    "خاطئين": 1,
    "خاطبهم": 1,
    "خاف": 1,
    "خافضة": 1,
    "خافوا": 1,
    "خافية": 1,
    "خالاتك": 1,
    "خالاتكم": 1,
    "خالدا": 1,
    "خالدون": 1,
    "خالدين": 1,
    "خالصا": 1,
    "خالصة": 1,
    "خالق": 1,
    "خالك": 1,
    "خامدون": 1,
    "خامدين": 1,
    "خانوا": 1,
    "خاوية": 1,
    "خبالا": 1,
    "خبت": 1,
    "خبرا": 1,
    "خبزا": 1,
    "خبيرا": 1,
    "ختار": 1,
    "ختامه": 1,
    "خدك": 1,
    "خذ": 1,
    "خذها": 1,
    "خذوا": 1,
    "خذولا": 1,
    "خذوه": 1,
    "خر": 1,
    "خرابها": 1,
    "خرجا": 1,
    "خرجت": 1,
    "خرجتم": 1,
    "خرجن": 1,
    "خرجوا": 1,
    "خردل": 1,
    "خرقها": 1,
    "خروا": 1,
    "خزائنه": 1,
    "خزنتها": 1,
    "خزي": 1,
    "خسارا": 1,
    "خسرا": 1,
    "خسرانا": 1,
    "خسروا": 1,
    "خسفنا": 1,
    "خشعا": 1,
    "خشوعا": 1,
    "خشي": 1,
    "خشيت": 1,
    "خشيته": 1,
    "خصاصة": 1,
    "خصمان": 1,
    "خصمون": 1,
    "خصيم": 1,
    "خصيما": 1,
    "خضر": 1,
    "خضرا": 1,
    "خطئا": 1,
    "خطابا": 1,
    "خطاياكم": 1,
    "خطايانا": 1,
    "خطاياهم": 1,
    "خطبك": 1,
    "خطبكم": 1,
    "خطبكما": 1,
    "خطبكن": 1,
    "خطيئاتكم": 1,
    "خطيئاتهم": 1,
    "خطيئة": 1,
    "خطيئته": 1,
    "خطيئتي": 1,
    "خفافا": 1,
    "خفتكم": 1,
    "خفتم": 1,
    "خفيا": 1,
    "خلا": 1,
    "خلائف": 1,
    "خلافك": 1,
    "خلالكم": 1,
    "خلالها": 1,
    "خلالهما": 1,
    "خلة": 1,
    "خلت": 1,
    "خلصوا": 1,
    "خلطوا": 1,
    "خلفاء": 1,
    "خلفة": 1,
    "خلفتموني": 1,
    "خلفك": 1,
    "خلفكم": 1,
    "خلفنا": 1,
    "خلفه": 1,
    "خلفها": 1,
    "خلفهم": 1,
    "خلفوا": 1,
    "خلقا": 1,
    "خلقت": 1,
    "خلقتك": 1,
    "خلقتني": 1,
    "خلقته": 1,
    "خلقك": 1,
    "خلقكم": 1,
    "خلقنا": 1,
    "خلقناكم": 1,
    "خلقناه": 1,
    "خلقناهم": 1,
    "خلقناهما": 1,
    "خلقني": 1,
    "خلقه": 1,
    "خلقها": 1,
    "خلقهم": 1,
    "خلقهن": 1,
    "خلقوا": 1,
    "خلوا": 1,
    "خليفة": 1,
    "خليلا": 1,
    "خمرا": 1,
    "خمسه": 1,
    "خمسين": 1,
    "خمط": 1,
    "خوار": 1,
    "خوانا": 1,
    "خوض": 1,
    "خوضهم": 1,
    "خوفهم": 1,
    "خولناكم": 1,
    "خولناه": 1,
    "خوله": 1,
    "خيانة": 1,
    "خيانتك": 1,
    "خير": 1,
    "خيرا": 1,
    "خيرات": 1,
    "خيفة": 1,
    "خيفته": 1,
    "خيل": 1,
    "دأب": 1,
    "دأبا": 1,
    "دائبين": 1,
    "دائمون": 1,
    "دابة": 1,
    "دابر": 1,
    "داحضة": 1,
    "داخرون": 1,
    "داخرين": 1,
    "داخلون": 1,
    "داركم": 1,
    "دارهم": 1,
    "دافق": 1,
    "دامت": 1,
    "داموا": 1,
    "دانية": 1,
    "داوود": 1,
    "دبره": 1,
    "دحاها": 1,
    "دحورا": 1,
    "دخل": 1,
    "دخلا": 1,
    "دخلت": 1,
    "دخلتم": 1,
    "دخلتموه": 1,
    "دخله": 1,
    "دخلوا": 1,
    "دخلوه": 1,
    "دراستهم": 1,
    "دراهم": 1,
    "درست": 1,
    "دركا": 1,
    "دري": 1,
    "دساها": 1,
    "دعا": 1,
    "دعاء": 1,
    "دعاءكم": 1,
    "دعاءه": 1,
    "دعاؤكم": 1,
    "دعائهم": 1,
    "دعاكم": 1,
    "دعان": 1,
    "دعانا": 1,
    "دعاه": 1,
    "دعوا": 1,
    "دعواهم": 1,
    "دعوت": 1,
    "دعوتك": 1,
    "دعوتكم": 1,
    "دعوتكما": 1,
    "دعوتهم": 1,
    "دعيتم": 1,
    "دفعتم": 1,
    "دكا": 1,
    "دكاء": 1,
    "دكة": 1,
    "دكت": 1,
    "دلهم": 1,
    "دلوه": 1,
    "دليلا": 1,
    "دما": 1,
    "دماءكم": 1,
    "دماؤها": 1,
    "دمت": 1,
    "دمتم": 1,
    "دمرنا": 1,
    "دمرناهم": 1,
    "دنا": 1,
    "دهاقا": 1,
    "دونك": 1,
    "دونكم": 1,
    "دوننا": 1,
    "دونه": 1,
    "دونها": 1,
    "دونهم": 1,
    "دونهما": 1,
    "دوني": 1,
    "ديارا": 1,
    "دياركم": 1,
    "ديارنا": 1,
    "ديارهم": 1,
    "دينكم": 1,
    "دينه": 1,
    "دينهم": 1,
    "ذائقة": 1,
    "ذاقا": 1,
    "ذاقوا": 1,
    "ذاهب": 1,
    "ذبابا": 1,
    "ذرأ": 1,
    "ذرأكم": 1,
    "ذرأنا": 1,
    "ذراعا": 1,
    "ذرعا": 1,
    "ذرعها": 1,
    "ذرنا": 1,
    "ذرني": 1,
    "ذرهم": 1,
    "ذروا": 1,
    "ذرونا": 1,
    "ذروني": 1,
    "ذرية": 1,
    "ذريتنا": 1,
    "ذريته": 1,
    "ذريتهم": 1,
    "ذريتهما": 1,
    "ذريتي": 1,
    "ذق": 1,
    "ذكرا": 1,
    "ذكرانا": 1,
    "ذكراها": 1,
    "ذكراهم": 1,
    "ذكرتم": 1,
    "ذكرك": 1,
    "ذكركم": 1,
    "ذكرنا": 1,
    "ذكرهم": 1,
    "ذكروا": 1,
    "ذكرى": 1,
    "ذكري": 1,
    "ذكيتم": 1,
    "ذلة": 1,
    "ذلكم": 1,
    "ذلكما": 1,
    "ذللا": 1,
    "ذلول": 1,
    "ذلولا": 1,
    "ذمة": 1,
    "ذنب": 1,
    "ذنبك": 1,
    "ذنبه": 1,
    "ذنوب": 1,
    "ذنوبا": 1,
    "ذنوبكم": 1,
    "ذنوبنا": 1,
    "ذنوبهم": 1,
    "ذهاب": 1,
    "ذهبا": 1,
    "ذهبت": 1,
    "ذهبنا": 1,
    "ذهبوا": 1,
    "ذوا": 1,
    "ذواتا": 1,
    "ذواتي": 1,
    "ذوقوا": 1,
    "رءوس": 1,
    "رءوسكم": 1,
    "رءوسهم": 1,
    "رءوف": 1,
    "رآك": 1,
    "رآه": 1,
    "رآها": 1,
    "رأته": 1,
    "رأتهم": 1,
    "رأسه": 1,
    "رأفة": 1,
    "رأوا": 1,
    "رأوك": 1,
    "رأوه": 1,
    "رأوها": 1,
    "رأوهم": 1,
    "رأيت": 1,
    "رأيتموه": 1,
    "رأيتهم": 1,
    "رأينه": 1,
    "رؤياك": 1,
    "رؤياي": 1,
    "رئاء": 1,
    "رابعهم": 1,
    "رابيا": 1,
    "رابية": 1,
    "راجعون": 1,
    "راد": 1,
    "رادوه": 1,
    "راسيات": 1,
    "راضية": 1,
    "راعنا": 1,
    "راعون": 1,
    "راغبون": 1,
    "راق": 1,
    "راكعا": 1,
    "راكعون": 1,
    "ران": 1,
    "راودتن": 1,
    "راودتني": 1,
    "راودته": 1,
    "راودوه": 1,
    "ربا": 1,
    "ربانيين": 1,
    "ربحت": 1,
    "ربطنا": 1,
    "ربك": 1,
    "ربكم": 1,
    "ربكما": 1,
    "ربنا": 1,
    "ربه": 1,
    "ربها": 1,
    "ربهم": 1,
    "ربهما": 1,
    "ربوة": 1,
    "ربي": 1,
    "ربياني": 1,
    "ربيون": 1,
    "رتقا": 1,
    "رجا": 1,
    "رجالا": 1,
    "رجالكم": 1,
    "رجت": 1,
    "رجز": 1,
    "رجزا": 1,
    "رجس": 1,
    "رجسا": 1,
    "رجسهم": 1,
    "رجعت": 1,
    "رجعتم": 1,
    "رجعك": 1,
    "رجعنا": 1,
    "رجعه": 1,
    "رجعوا": 1,
    "رجلا": 1,
    "رجلان": 1,
    "رجلين": 1,
    "رجما": 1,
    "رجوما": 1,
    "رجيم": 1,
    "رحالهم": 1,
    "رحبت": 1,
    "رحله": 1,
    "رحما": 1,
    "رحماء": 1,
    "رحمت": 1,
    "رحمتك": 1,
    "رحمتنا": 1,
    "رحمته": 1,
    "رحمتي": 1,
    "رحمنا": 1,
    "رحمناهم": 1,
    "رحمه": 1,
    "رحيق": 1,
    "رحيما": 1,
    "رخاء": 1,
    "ردءا": 1,
    "ردت": 1,
    "رددت": 1,
    "رددنا": 1,
    "رددناه": 1,
    "ردف": 1,
    "ردما": 1,
    "ردها": 1,
    "ردوا": 1,
    "ردوه": 1,
    "ردوها": 1,
    "رزق": 1,
    "رزقا": 1,
    "رزقكم": 1,
    "رزقنا": 1,
    "رزقناكم": 1,
    "رزقناه": 1,
    "رزقناهم": 1,
    "رزقه": 1,
    "رزقها": 1,
    "رزقهم": 1,
    "رزقهن": 1,
    "رزقوا": 1,
    "رسالات": 1,
    "رسلا": 1,
    "رسلك": 1,
    "رسلكم": 1,
    "رسلنا": 1,
    "رسله": 1,
    "رسلهم": 1,
    "رسلي": 1,
    "رسولا": 1,
    "رسولكم": 1,
    "رسولنا": 1,
    "رسوله": 1,
    "رسولها": 1,
    "رسولهم": 1,
    "رشدا": 1,
    "رشده": 1,
    "رشيد": 1,
    "رصدا": 1,
    "رضوا": 1,
    "رضوان": 1,
    "رضوانه": 1,
    "رضيا": 1,
    "رضيتم": 1,
    "رطبا": 1,
    "رعايتها": 1,
    "رعبا": 1,
    "رعوها": 1,
    "رغبا": 1,
    "رغدا": 1,
    "رفث": 1,
    "رفعت": 1,
    "رفعه": 1,
    "رفعها": 1,
    "رفيقا": 1,
    "رقبة": 1,
    "رقود": 1,
    "رقيب": 1,
    "رقيبا": 1,
    "ركاما": 1,
    "ركبا": 1,
    "ركبانا": 1,
    "ركبك": 1,
    "ركبوا": 1,
    "ركزا": 1,
    "ركعا": 1,
    "ركوبهم": 1,
    "رمزا": 1,
    "رمضان": 1,
    "رميت": 1,
    "رميم": 1,
    "رهط": 1,
    "رهطك": 1,
    "رهقا": 1,
    "رهوا": 1,
    "رهين": 1,
    "رهينة": 1,
    "رواسي": 1,
    "رواكد": 1,
    "روحا": 1,
    "روحنا": 1,
    "روحه": 1,
    "روضات": 1,
    "رويدا": 1,
    "ريب": 1,
    "ريبة": 1,
    "ريبهم": 1,
    "ريحا": 1,
    "ريحكم": 1,
    "زادته": 1,
    "زادتهم": 1,
    "زادهم": 1,
    "زادوكم": 1,
    "زادوهم": 1,
    "زاغ": 1,
    "زاغت": 1,
    "زاغوا": 1,
    "زالت": 1,
    "زالتا": 1,
    "زانية": 1,
    "زاهق": 1,
    "زبر": 1,
    "زبرا": 1,
    "زبورا": 1,
    "زجرا": 1,
    "زجرة": 1,
    "زحزح": 1,
    "زحفا": 1,
    "زخرفها": 1,
    "زدناهم": 1,
    "زدني": 1,
    "زرتم": 1,
    "زرعا": 1,
    "زرقا": 1,
    "زعمت": 1,
    "زعمتم": 1,
    "زفير": 1,
    "زقوم": 1,
    "زكاة": 1,
    "زكاها": 1,
    "زكى": 1,
    "زكيا": 1,
    "زكية": 1,
    "زلتم": 1,
    "زلزالا": 1,
    "زلزالها": 1,
    "زلزلة": 1,
    "زلزلت": 1,
    "زلفة": 1,
    "زلفى": 1,
    "زلقا": 1,
    "زللتم": 1,
    "زمرا": 1,
    "زمهريرا": 1,
    "زنجبيلا": 1,
    "زهوقا": 1,
    "زوال": 1,
    "زوجا": 1,
    "زوجان": 1,
    "زوجت": 1,
    "زوجك": 1,
    "زوجناكها": 1,
    "زوجه": 1,
    "زوجها": 1,
    "زيتها": 1,
    "زيتونة": 1,
    "زيغ": 1,
    "زينتكم": 1,
    "زينته": 1,
    "زينتهن": 1,
    "سآتيكم": 1,
    "سآوي": 1,
    "سأتلو": 1,
    "سأرهقه": 1,
    "سأريكم": 1,
    "سأستغفر": 1,
    "سأصرف": 1,
    "سأصليه": 1,
    "سألتك": 1,
    "سألتكم": 1,
    "سألتم": 1,
    "سألتموه": 1,
    "سألتموهن": 1,
    "سألتهم": 1,
    "سألقي": 1,
    "سألك": 1,
    "سألها": 1,
    "سألهم": 1,
    "سألوا": 1,
    "سأنبئك": 1,
    "سأنزل": 1,
    "سؤلك": 1,
    "سئل": 1,
    "سئلت": 1,
    "سئلوا": 1,
    "ساء": 1,
    "ساءت": 1,
    "سائبة": 1,
    "سائحات": 1,
    "سائغ": 1,
    "سائغا": 1,
    "سابغات": 1,
    "سابقوا": 1,
    "سابقون": 1,
    "سابقين": 1,
    "ساجدا": 1,
    "ساجدين": 1,
    "سادتنا": 1,
    "سادسهم": 1,
    "سارقين": 1,
    "سافلها": 1,
    "سافلين": 1,
    "ساقطا": 1,
    "ساقيها": 1,
    "ساكنا": 1,
    "سالمون": 1,
    "سامدون": 1,
    "سامرا": 1,
    "ساهون": 1,
    "سبإ": 1,
    "سباتا": 1,
    "سببا": 1,
    "سبتهم": 1,
    "سبحا": 1,
    "سبحانك": 1,
    "سبحوا": 1,
    "سبعا": 1,
    "سبعين": 1,
    "سبقت": 1,
    "سبقكم": 1,
    "سبقوا": 1,
    "سبقونا": 1,
    "سبل": 1,
    "سبلا": 1,
    "سبلنا": 1,
    "سبيلا": 1,
    "سبيلك": 1,
    "سبيلنا": 1,
    "سبيله": 1,
    "سبيلهم": 1,
    "سبيلي": 1,
    "ستجدني": 1,
    "ستجدون": 1,
    "ستدعون": 1,
    "ستذكرونهن": 1,
    "سترا": 1,
    "ستغلبون": 1,
    "ستكتب": 1,
    "سجدا": 1,
    "سجدوا": 1,
    "سجرت": 1,
    "سجى": 1,
    "سجيل": 1,
    "سجين": 1,
    "سحابا": 1,
    "سحار": 1,
    "سحران": 1,
    "سحرهم": 1,
    "سحروا": 1,
    "سخرنا": 1,
    "سخرناها": 1,
    "سخرها": 1,
    "سخروا": 1,
    "سخريا": 1,
    "سدا": 1,
    "سدر": 1,
    "سدرة": 1,
    "سدى": 1,
    "سديدا": 1,
    "سرابا": 1,
    "سرابيل": 1,
    "سرابيلهم": 1,
    "سراجا": 1,
    "سراحا": 1,
    "سرادقها": 1,
    "سراعا": 1,
    "سربا": 1,
    "سرحوهن": 1,
    "سركم": 1,
    "سرمدا": 1,
    "سرهم": 1,
    "سطحت": 1,
    "سعته": 1,
    "سعدوا": 1,
    "سعرت": 1,
    "سعوا": 1,
    "سعى": 1,
    "سعيا": 1,
    "سعيرا": 1,
    "سعيكم": 1,
    "سعيه": 1,
    "سعيها": 1,
    "سعيهم": 1,
    "سفرنا": 1,
    "سفها": 1,
    "سفيها": 1,
    "سفيهنا": 1,
    "سقاية": 1,
    "سقر": 1,
    "سقطوا": 1,
    "سقفا": 1,
    "سقناه": 1,
    "سقيت": 1,
    "سكارى": 1,
    "سكت": 1,
    "سكرا": 1,
    "سكرة": 1,
    "سكرت": 1,
    "سكرتهم": 1,
    "سكنا": 1,
    "سكنتم": 1,
    "سكينا": 1,
    "سكينته": 1,
    "سلاما": 1,
    "سلسبيلا": 1,
    "سلطانا": 1,
    "سلطانه": 1,
    "سلطانيه": 1,
    "سلف": 1,
    "سلفا": 1,
    "سلقوكم": 1,
    "سلككم": 1,
    "سلكناه": 1,
    "سلما": 1,
    "سلمتم": 1,
    "سلهم": 1,
    "سماء": 1,
    "سماعون": 1,
    "سماكم": 1,
    "سمان": 1,
    "سماوات": 1,
    "سمعا": 1,
    "سمعت": 1,
    "سمعتم": 1,
    "سمعتموه": 1,
    "سمعكم": 1,
    "سمعنا": 1,
    "سمعه": 1,
    "سمعهم": 1,
    "سمعوا": 1,
    "سمكها": 1,
    "سموم": 1,
    "سموهم": 1,
    "سميا": 1,
    "سميتموها": 1,
    "سميتها": 1,
    "سميع": 1,
    "سميعا": 1,
    "سنؤتيهم": 1,
    "سنا": 1,
    "سنابل": 1,
    "سنبلات": 1,
    "سنبلة": 1,
    "سنبله": 1,
    "سنت": 1,
    "سنجزي": 1,
    "سندخلهم": 1,
    "سندس": 1,
    "سندع": 1,
    "سنراود": 1,
    "سنريهم": 1,
    "سنزيد": 1,
    "سنستدرجهم": 1,
    "سنسمه": 1,
    "سنشد": 1,
    "سنطيعكم": 1,
    "سنعذبهم": 1,
    "سنعيدها": 1,
    "سنفرغ": 1,
    "سنقتل": 1,
    "سنقرئك": 1,
    "سنكتب": 1,
    "سنلقي": 1,
    "سنمتعهم": 1,
    "سننظر": 1,
    "سهولها": 1,
    "سوءا": 1,
    "سوءة": 1,
    "سوآتكم": 1,
    "سوآتهما": 1,
    "سواعا": 1,
    "سواك": 1,
    "سواه": 1,
    "سواها": 1,
    "سور": 1,
    "سورة": 1,
    "سوط": 1,
    "سوقه": 1,
    "سويا": 1,
    "سويته": 1,
    "سيؤتينا": 1,
    "سيئا": 1,
    "سيئات": 1,
    "سيئاتكم": 1,
    "سيئاتنا": 1,
    "سيئاته": 1,
    "سيئاتهم": 1,
    "سيئة": 1,
    "سيئت": 1,
    "سيئه": 1,
    "سيبطله": 1,
    "سيجزون": 1,
    "سيجزيهم": 1,
    "سيجعل": 1,
    "سيحلفون": 1,
    "سيدخلهم": 1,
    "سيدخلون": 1,
    "سيدها": 1,
    "سيذكر": 1,
    "سيرت": 1,
    "سيرتها": 1,
    "سيرحمهم": 1,
    "سيروا": 1,
    "سيريكم": 1,
    "سيصلى": 1,
    "سيصيب": 1,
    "سيصيبهم": 1,
    "سيطوقون": 1,
    "سيعلمون": 1,
    "سيغفر": 1,
    "سيغلبون": 1,
    "سيقول": 1,
    "سيقولون": 1,
    "سيكفرون": 1,
    "سيكون": 1,
    "سيماهم": 1,
    "سينالهم": 1,
    "سينين": 1,
    "سيهدين": 1,
    "سيهديهم": 1,
    "سيهزم": 1,
    "شأنهم": 1,
    "شئت": 1,
    "شئتم": 1,
    "شئتما": 1,
    "شئنا": 1,
    "شاء": 1,
    "شاخصة": 1,
    "شافعين": 1,
    "شاقوا": 1,
    "شاكرا": 1,
    "شاكرون": 1,
    "شاكرين": 1,
    "شاكلته": 1,
    "شامخات": 1,
    "شانئك": 1,
    "شاهدا": 1,
    "شاهدون": 1,
    "شاهدين": 1,
    "شتى": 1,
    "شجرت": 1,
    "شجرتها": 1,
    "شجرها": 1,
    "شح": 1,
    "شحومهما": 1,
    "شداد": 1,
    "شدادا": 1,
    "شديدا": 1,
    "شر": 1,
    "شرابا": 1,
    "شرابه": 1,
    "شرعة": 1,
    "شرعوا": 1,
    "شرقيا": 1,
    "شرك": 1,
    "شركاءكم": 1,
    "شركاءهم": 1,
    "شركاؤكم": 1,
    "شركاؤنا": 1,
    "شركاؤهم": 1,
    "شركائكم": 1,
    "شركائهم": 1,
    "شركائي": 1,
    "شروا": 1,
    "شريعة": 1,
    "شطأه": 1,
    "شطره": 1,
    "شططا": 1,
    "شعائر": 1,
    "شعوبا": 1,
    "شعيب": 1,
    "شعيبا": 1,
    "شغفها": 1,
    "شغلتنا": 1,
    "شفا": 1,
    "شفاء": 1,
    "شفاعة": 1,
    "شفاعتهم": 1,
    "شفعاء": 1,
    "شفعاءكم": 1,
    "شفعاؤنا": 1,
    "شفيع": 1,
    "شقا": 1,
    "شقاق": 1,
    "شقاقي": 1,
    "شققنا": 1,
    "شقوا": 1,
    "شقوتنا": 1,
    "شقيا": 1,
    "شكرتم": 1,
    "شكله": 1,
    "شكور": 1,
    "شكورا": 1,
    "شمائلهم": 1,
    "شمسا": 1,
    "شنآن": 1,
    "شهابا": 1,
    "شهادات": 1,
    "شهادتهم": 1,
    "شهادتهما": 1,
    "شهد": 1,
    "شهداء": 1,
    "شهداءكم": 1,
    "شهدتم": 1,
    "شهدنا": 1,
    "شهدوا": 1,
    "شهرا": 1,
    "شهرين": 1,
    "شهوة": 1,
    "شهودا": 1,
    "شهيدا": 1,
    "شهيدين": 1,
    "شهيقا": 1,
    "شواظ": 1,
    "شورى": 1,
    "شياطين": 1,
    "شياطينهم": 1,
    "شيبا": 1,
    "شية": 1,
    "شيخ": 1,
    "شيخا": 1,
    "شيطانا": 1,
    "شيع": 1,
    "شيعا": 1,
    "شيعته": 1,
    "شيوخا": 1,
    "صابرا": 1,
    "صابرة": 1,
    "صابرون": 1,
    "صاحبكم": 1,
    "صاحبه": 1,
    "صاحبهم": 1,
    "صاحبي": 1,
    "صادقا": 1,
    "صادقين": 1,
    "صارمين": 1,
    "صاعقة": 1,
    "صاغرون": 1,
    "صاغرين": 1,
    "صافات": 1,
    "صال": 1,
    "صالحين": 1,
    "صالو": 1,
    "صامتون": 1,
    "صبا": 1,
    "صببنا": 1,
    "صبحا": 1,
    "صبحهم": 1,
    "صبرتم": 1,
    "صبرك": 1,
    "صبرنا": 1,
    "صبروا": 1,
    "صبوا": 1,
    "صبيا": 1,
    "صحفا": 1,
    "صددتم": 1,
    "صددناكم": 1,
    "صدرا": 1,
    "صدرك": 1,
    "صدره": 1,
    "صدقا": 1,
    "صدقات": 1,
    "صدقاتكم": 1,
    "صدقاتهن": 1,
    "صدقة": 1,
    "صدقت": 1,
    "صدقتنا": 1,
    "صدقكم": 1,
    "صدقنا": 1,
    "صدقناهم": 1,
    "صدقهم": 1,
    "صدقوا": 1,
    "صدودا": 1,
    "صدور": 1,
    "صدوركم": 1,
    "صدورهم": 1,
    "صدوكم": 1,
    "صديد": 1,
    "صديقا": 1,
    "صديقكم": 1,
    "صراط": 1,
    "صراطا": 1,
    "صراطك": 1,
    "صراطي": 1,
    "صرة": 1,
    "صرحا": 1,
    "صرصر": 1,
    "صرصرا": 1,
    "صرعى": 1,
    "صرفا": 1,
    "صرفت": 1,
    "صرفكم": 1,
    "صرفنا": 1,
    "صرفناه": 1,
    "صريخ": 1,
    "صعقا": 1,
    "صعيدا": 1,
    "صغت": 1,
    "صغيرا": 1,
    "صفا": 1,
    "صفحا": 1,
    "صفصفا": 1,
    "صفوان": 1,
    "صلاتك": 1,
    "صلاته": 1,
    "صلاتهم": 1,
    "صلاتي": 1,
    "صلبوه": 1,
    "صلح": 1,
    "صلحا": 1,
    "صلدا": 1,
    "صلصال": 1,
    "صلوا": 1,
    "صلواتهم": 1,
    "صلوه": 1,
    "صلى": 1,
    "صليا": 1,
    "صما": 1,
    "صنعا": 1,
    "صنعة": 1,
    "صنعوا": 1,
    "صنوان": 1,
    "صوابا": 1,
    "صواع": 1,
    "صواف": 1,
    "صوامع": 1,
    "صوتك": 1,
    "صوركم": 1,
    "صورناكم": 1,
    "صوما": 1,
    "صياصيهم": 1,
    "صيام": 1,
    "صياما": 1,
    "ضاحكا": 1,
    "ضاحكة": 1,
    "ضاقت": 1,
    "ضالين": 1,
    "ضبحا": 1,
    "ضحاها": 1,
    "ضدا": 1,
    "ضر": 1,
    "ضرا": 1,
    "ضراء": 1,
    "ضرارا": 1,
    "ضربا": 1,
    "ضربت": 1,
    "ضربتم": 1,
    "ضربنا": 1,
    "ضربوا": 1,
    "ضربوه": 1,
    "ضره": 1,
    "ضريع": 1,
    "ضعافا": 1,
    "ضعفا": 1,
    "ضعفاء": 1,
    "ضعفوا": 1,
    "ضعيفا": 1,
    "ضغثا": 1,
    "ضل": 1,
    "ضلال": 1,
    "ضلالا": 1,
    "ضلالة": 1,
    "ضلالتهم": 1,
    "ضلالك": 1,
    "ضللت": 1,
    "ضللنا": 1,
    "ضلوا": 1,
    "ضنكا": 1,
    "ضياء": 1,
    "ضير": 1,
    "ضيزى": 1,
    "ضيفه": 1,
    "ضيفي": 1,
    "ضيقا": 1,
    "طائركم": 1,
    "طائره": 1,
    "طائرهم": 1,
    "طائعين": 1,
    "طائف": 1,
    "طائفتان": 1,
    "طائفتين": 1,
    "طاب": 1,
    "طاعة": 1,
    "طاعم": 1,
    "طاغون": 1,
    "طاغين": 1,
    "طال": 1,
    "طالوت": 1,
    "طباقا": 1,
    "طبتم": 1,
    "طبن": 1,
    "طحاها": 1,
    "طرائق": 1,
    "طردتهم": 1,
    "طرفا": 1,
    "طرفك": 1,
    "طرفهم": 1,
    "طريا": 1,
    "طريقا": 1,
    "طس": 1,
    "طسم": 1,
    "طعامك": 1,
    "طعامه": 1,
    "طعمتم": 1,
    "طعموا": 1,
    "طغوا": 1,
    "طغى": 1,
    "طغيانا": 1,
    "طغيانهم": 1,
    "طفلا": 1,
    "طلبا": 1,
    "طلعت": 1,
    "طلعها": 1,
    "طلقتم": 1,
    "طلقتموهن": 1,
    "طلقكن": 1,
    "طلقها": 1,
    "طلوع": 1,
    "طمست": 1,
    "طه": 1,
    "طهرا": 1,
    "طهورا": 1,
    "طوافون": 1,
    "طوبى": 1,
    "طوى": 1,
    "طويلا": 1,
    "طيبا": 1,
    "طيبات": 1,
    "طيباتكم": 1,
    "طيبين": 1,
    "طيرا": 1,
    "ظالمة": 1,
    "ظالمون": 1,
    "ظالمي": 1,
    "ظالمين": 1,
    "ظاهرا": 1,
    "ظاهروهم": 1,
    "ظاهرين": 1,
    "ظعنكم": 1,
    "ظلا": 1,
    "ظلاله": 1,
    "ظلالها": 1,
    "ظلة": 1,
    "ظلت": 1,
    "ظلما": 1,
    "ظلمات": 1,
    "ظلمت": 1,
    "ظلمتم": 1,
    "ظلمك": 1,
    "ظلمنا": 1,
    "ظلمناهم": 1,
    "ظلمه": 1,
    "ظلمهم": 1,
    "ظلموا": 1,
    "ظلمونا": 1,
    "ظلوما": 1,
    "ظليلا": 1,
    "ظمأ": 1,
    "ظن": 1,
    "ظنا": 1,
    "ظنكم": 1,
    "ظننا": 1,
    "ظننت": 1,
    "ظننتم": 1,
    "ظنه": 1,
    "ظنوا": 1,
    "ظهرك": 1,
    "ظهره": 1,
    "ظهرها": 1,
    "ظهوركم": 1,
    "ظهوره": 1,
    "ظهورها": 1,
    "ظهورهم": 1,
    "ظهورهما": 1,
    "ظهير": 1,
    "ظهيرا": 1,
    "عائدون": 1,
    "عائلا": 1,
    "عابد": 1,
    "عابدات": 1,
    "عابدون": 1,
    "عابدين": 1,
    "عابري": 1,
    "عاتية": 1,
    "عادا": 1,
    "عادون": 1,
    "عاديتم": 1,
    "عارضا": 1,
    "عاصفة": 1,
    "عاصم": 1,
    "عاقبة": 1,
    "عاقبتم": 1,
    "عاقبتهما": 1,
    "عاقر": 1,
    "عاقرا": 1,
    "عاكفا": 1,
    "عاكفون": 1,
    "عاكفين": 1,
    "عالمين": 1,
    "عالين": 1,
    "عاليها": 1,
    "عاليهم": 1,
    "عاما": 1,
    "عاملون": 1,
    "عامهم": 1,
    "عاهد": 1,
    "عاهدت": 1,
    "عاهدتم": 1,
    "عاهدوا": 1,
    "عبادا": 1,
    "عبادتكم": 1,
    "عبادته": 1,
    "عبادتي": 1,
    "عبادك": 1,
    "عبادكم": 1,
    "عبادنا": 1,
    "عباده": 1,
    "عبدا": 1,
    "عبدت": 1,
    "عبدتم": 1,
    "عبدنا": 1,
    "عبدناهم": 1,
    "عبده": 1,
    "عبدين": 1,
    "عبرة": 1,
    "عبوسا": 1,
    "عتت": 1,
    "عتل": 1,
    "عتو": 1,
    "عتوا": 1,
    "عتيا": 1,
    "عتيد": 1,
    "عثر": 1,
    "عجاب": 1,
    "عجاف": 1,
    "عجبا": 1,
    "عجبت": 1,
    "عجبوا": 1,
    "عجلا": 1,
    "عجلنا": 1,
    "عجوزا": 1,
    "عجولا": 1,
    "عجيب": 1,
    "عداوة": 1,
    "عدتم": 1,
    "عدتهم": 1,
    "عدنا": 1,
    "عدوا": 1,
    "عدوان": 1,
    "عدوانا": 1,
    "عدوكم": 1,
    "عدوه": 1,
    "عدوهم": 1,
    "عدوي": 1,
    "عذاب": 1,
    "عذابا": 1,
    "عذابه": 1,
    "عذابها": 1,
    "عذابهما": 1,
    "عذابي": 1,
    "عذت": 1,
    "عذرا": 1,
    "عربا": 1,
    "عربيا": 1,
    "عرشك": 1,
    "عرشه": 1,
    "عرشها": 1,
    "عرضتم": 1,
    "عرضنا": 1,
    "عرضهم": 1,
    "عرفا": 1,
    "عرفات": 1,
    "عرفها": 1,
    "عرفوا": 1,
    "عروشها": 1,
    "عزا": 1,
    "عزة": 1,
    "عزلت": 1,
    "عزم": 1,
    "عزما": 1,
    "عزمت": 1,
    "عزموا": 1,
    "عزير": 1,
    "عزين": 1,
    "عسرا": 1,
    "عسرة": 1,
    "عسعس": 1,
    "عسق": 1,
    "عسى": 1,
    "عسيتم": 1,
    "عسير": 1,
    "عسيرا": 1,
    "عشاء": 1,
    "عشرا": 1,
    "عشية": 1,
    "عشيرتك": 1,
    "عشيرتهم": 1,
    "عصاك": 1,
    "عصاني": 1,
    "عصاه": 1,
    "عصاي": 1,
    "عصبة": 1,
    "عصوا": 1,
    "عصوك": 1,
    "عصوني": 1,
    "عصيا": 1,
    "عصيب": 1,
    "عصيت": 1,
    "عصيته": 1,
    "عضدا": 1,
    "عضدك": 1,
    "عضوا": 1,
    "عضين": 1,
    "عطاؤنا": 1,
    "عطفه": 1,
    "عطلت": 1,
    "عظاما": 1,
    "عظامه": 1,
    "عظيما": 1,
    "عفريت": 1,
    "عفوا": 1,
    "عفونا": 1,
    "عفي": 1,
    "عقبا": 1,
    "عقباها": 1,
    "عقبه": 1,
    "عقبى": 1,
    "عقبيه": 1,
    "عقدت": 1,
    "عقدتم": 1,
    "عقلوه": 1,
    "عقيما": 1,
    "علام": 1,
    "علقة": 1,
    "علمت": 1,
    "علمتك": 1,
    "علمتم": 1,
    "علمتموهن": 1,
    "علمتنا": 1,
    "علمته": 1,
    "علمكم": 1,
    "علمنا": 1,
    "علمناه": 1,
    "علمني": 1,
    "علمه": 1,
    "علمها": 1,
    "علمهم": 1,
    "علموا": 1,
    "علوا": 1,
    "عليك": 1,
    "عليكم": 1,
    "عليكما": 1,
    "عليم": 1,
    "عليما": 1,
    "علينا": 1,
    "عليهما": 1,
    "عليهن": 1,
    "عليون": 1,
    "عليين": 1,
    "عم": 1,
    "عما": 1,
    "عماتك": 1,
    "عماتكم": 1,
    "عمرا": 1,
    "عمران": 1,
    "عمرك": 1,
    "عمره": 1,
    "عمروها": 1,
    "عمك": 1,
    "عملتم": 1,
    "عملته": 1,
    "عملك": 1,
    "عملكم": 1,
    "عملوا": 1,
    "عموا": 1,
    "عمون": 1,
    "عمى": 1,
    "عمي": 1,
    "عميا": 1,
    "عمين": 1,
    "عنا": 1,
    "عنتم": 1,
    "عندك": 1,
    "عندكم": 1,
    "عندنا": 1,
    "عندها": 1,
    "عندهم": 1,
    "عندي": 1,
    "عنقك": 1,
    "عنقه": 1,
    "عنك": 1,
    "عنكم": 1,
    "عنها": 1,
    "عنهم": 1,
    "عنهما": 1,
    "عني": 1,
    "عنيدا": 1,
    "عهدا": 1,
    "عهدنا": 1,
    "عهده": 1,
    "عهدهم": 1,
    "عهدي": 1,
    "عوان": 1,
    "عوج": 1,
    "عوجا": 1,
    "عورات": 1,
    "عورة": 1,
    "عوقب": 1,
    "عوقبتم": 1,
    "عيدا": 1,
    "عيسى": 1,
    "عيلة": 1,
    "عينا": 1,
    "عيناك": 1,
    "عينان": 1,
    "عيناه": 1,
    "عينها": 1,
    "عينيك": 1,
    "عيونا": 1,
    "غائبة": 1,
    "غائبين": 1,
    "غاسق": 1,
    "غاشية": 1,
    "غافر": 1,
    "غافلا": 1,
    "غافلون": 1,
    "غافلين": 1,
    "غالب": 1,
    "غالبون": 1,
    "غاوين": 1,
    "غبرة": 1,
    "غثاء": 1,
    "غداءنا": 1,
    "غدقا": 1,
    "غدوا": 1,
    "غدوت": 1,
    "غدوها": 1,
    "غر": 1,
    "غرابا": 1,
    "غراما": 1,
    "غربت": 1,
    "غربية": 1,
    "غرفا": 1,
    "غرقا": 1,
    "غرك": 1,
    "غروبها": 1,
    "غرور": 1,
    "غرورا": 1,
    "غزلها": 1,
    "غزى": 1,
    "غسق": 1,
    "غسلين": 1,
    "غشاوة": 1,
    "غشى": 1,
    "غشيهم": 1,
    "غصبا": 1,
    "غصة": 1,
    "غضبوا": 1,
    "غضبي": 1,
    "غطاءك": 1,
    "غفارا": 1,
    "غفرانك": 1,
    "غفور": 1,
    "غفورا": 1,
    "غلا": 1,
    "غلاظ": 1,
    "غلاما": 1,
    "غلبا": 1,
    "غلبت": 1,
    "غلبهم": 1,
    "غلبوا": 1,
    "غلت": 1,
    "غلظة": 1,
    "غلمان": 1,
    "غليظا": 1,
    "غم": 1,
    "غما": 1,
    "غمة": 1,
    "غمرات": 1,
    "غمرة": 1,
    "غمرتهم": 1,
    "غنم": 1,
    "غنمتم": 1,
    "غنمي": 1,
    "غنيا": 1,
    "غواش": 1,
    "غورا": 1,
    "غوى": 1,
    "غوينا": 1,
    "غيا": 1,
    "غيابت": 1,
    "غيبه": 1,
    "غيث": 1,
    "غيركم": 1,
    "غيره": 1,
    "غيرها": 1,
    "غيري": 1,
    "غيظ": 1,
    "فآت": 1,
    "فآتاهم": 1,
    "فآتت": 1,
    "فآتهم": 1,
    "فآتوا": 1,
    "فآتوهم": 1,
    "فآتوهن": 1,
    "فآتينا": 1,
    "فآخران": 1,
    "فآذوهما": 1,
    "فآزره": 1,
    "فآمن": 1,
    "فآمنا": 1,
    "فآمنت": 1,
    "فآمنوا": 1,
    "فآواكم": 1,
    "فآوى": 1,
    "فأبوا": 1,
    "فأبى": 1,
    "فأبين": 1,
    "فأت": 1,
    "فأتاهم": 1,
    "فأتبع": 1,
    "فأتبعنا": 1,
    "فأتبعه": 1,
    "فأتبعهم": 1,
    "فأتبعوهم": 1,
    "فأتت": 1,
    "فأتمهن": 1,
    "فأتموا": 1,
    "فأتنا": 1,
    "فأتوا": 1,
    "فأتونا": 1,
    "فأتوهن": 1,
    "فأتى": 1,
    "فأتيا": 1,
    "فأتياه": 1,
    "فأثابكم": 1,
    "فأثابهم": 1,
    "فأثرن": 1,
    "فأجاءها": 1,
    "فأجره": 1,
    "فأجمعوا": 1,
    "فأحبط": 1,
    "فأحسن": 1,
    "فأحكم": 1,
    "فأحيا": 1,
    "فأحياكم": 1,
    "فأحيينا": 1,
    "فأحييناه": 1,
    "فأخاف": 1,
    "فأخذتكم": 1,
    "فأخذتهم": 1,
    "فأخذناه": 1,
    "فأخذناهم": 1,
    "فأخذه": 1,
    "فأخذهم": 1,
    "فأخرج": 1,
    "فأخرجنا": 1,
    "فأخرجناهم": 1,
    "فأخرجهما": 1,
    "فأخلفتكم": 1,
    "فأخلفتم": 1,
    "فأدخلوا": 1,
    "فأدلى": 1,
    "فأذاقها": 1,
    "فأذاقهم": 1,
    "فأذن": 1,
    "فأذنوا": 1,
    "فأراد": 1,
    "فأرادوا": 1,
    "فأراه": 1,
    "فأردت": 1,
    "فأردنا": 1,
    "فأرسل": 1,
    "فأرسلنا": 1,
    "فأرسله": 1,
    "فأرسلوا": 1,
    "فأرسلون": 1,
    "فأروني": 1,
    "فأزلهما": 1,
    "فأسر": 1,
    "فأسرها": 1,
    "فأسقط": 1,
    "فأسقيناكموه": 1,
    "فأسكناه": 1,
    "فأشارت": 1,
    "فأشهدوا": 1,
    "فأصابتكم": 1,
    "فأصابه": 1,
    "فأصابها": 1,
    "فأصابهم": 1,
    "فأصبح": 1,
    "فأصبحت": 1,
    "فأصبحتم": 1,
    "فأصبحوا": 1,
    "فأصحاب": 1,
    "فأصدق": 1,
    "فأصلح": 1,
    "فأصلحوا": 1,
    "فأصمهم": 1,
    "فأضلونا": 1,
    "فأطاعوه": 1,
    "فأطلع": 1,
    "فأعذبهم": 1,
    "فأعرض": 1,
    "فأعرضوا": 1,
    "فأعقبهم": 1,
    "فأعينوني": 1,
    "فأغرقناه": 1,
    "فأغرقناهم": 1,
    "فأغرينا": 1,
    "فأغشيناهم": 1,
    "فأغنى": 1,
    "فأغويناكم": 1,
    "فأفوز": 1,
    "فأقامه": 1,
    "فأقبره": 1,
    "فأقبل": 1,
    "فأقبلت": 1,
    "فأقبلوا": 1,
    "فأقم": 1,
    "فأقمت": 1,
    "فأقيموا": 1,
    "فأكثرت": 1,
    "فأكثروا": 1,
    "فأكرمه": 1,
    "فأكلا": 1,
    "فأكله": 1,
    "فأكون": 1,
    "فألف": 1,
    "فألقاها": 1,
    "فألقه": 1,
    "فألقوا": 1,
    "فألقوه": 1,
    "فألقى": 1,
    "فألقي": 1,
    "فألقياه": 1,
    "فألقيه": 1,
    "فألهمها": 1,
    "فأما": 1,
    "فأماته": 1,
    "فأمتعه": 1,
    "فأمسكوهن": 1,
    "فأمطر": 1,
    "فأمكن": 1,
    "فأمليت": 1,
    "فأمه": 1,
    "فأن": 1,
    "فأنا": 1,
    "فأنبئكم": 1,
    "فأنبتنا": 1,
    "فأنت": 1,
    "فأنتم": 1,
    "فأنجاه": 1,
    "فأنجيناكم": 1,
    "فأنجيناه": 1,
    "فأنجيناهم": 1,
    "فأنذر": 1,
    "فأنذرتكم": 1,
    "فأنزل": 1,
    "فأنزلنا": 1,
    "فأنساه": 1,
    "فأنساهم": 1,
    "فأنشأنا": 1,
    "فأنشرنا": 1,
    "فأنظرني": 1,
    "فأنفخ": 1,
    "فأنفقوا": 1,
    "فأنقذكم": 1,
    "فأنه": 1,
    "فأنى": 1,
    "فأهلكته": 1,
    "فأهلكنا": 1,
    "فأهلكناهم": 1,
    "فأهلكوا": 1,
    "فأواري": 1,
    "فأوجس": 1,
    "فأوحى": 1,
    "فأوحينا": 1,
    "فأوردهم": 1,
    "فأوعى": 1,
    "فأوف": 1,
    "فأوفوا": 1,
    "فأوقد": 1,
    "فأولئك": 1,
    "فأولى": 1,
    "فأووا": 1,
    "فأي": 1,
    "فأيدنا": 1,
    "فأين": 1,
    "فأينما": 1,
    "فؤاد": 1,
    "فؤادك": 1,
    "فإخوانكم": 1,
    "فإذ": 1,
    "فإذا": 1,
    "فإطعام": 1,
    "فإلم": 1,
    "فإلهكم": 1,
    "فإلينا": 1,
    "فإليه": 1,
    "فإما": 1,
    "فإمساك": 1,
    "فإن": 1,
    "فإنا": 1,
    "فإنك": 1,
    "فإنكم": 1,
    "فإنما": 1,
    "فإنه": 1,
    "فإنها": 1,
    "فإنهم": 1,
    "فإني": 1,
    "فإياي": 1,
    "فئتكم": 1,
    "فئتين": 1,
    "فاءت": 1,
    "فاءوا": 1,
    "فابتغوا": 1,
    "فابعثوا": 1,
    "فاتباع": 1,
    "فاتبع": 1,
    "فاتبعني": 1,
    "فاتبعها": 1,
    "فاتبعوا": 1,
    "فاتبعوني": 1,
    "فاتبعوه": 1,
    "فاتخذ": 1,
    "فاتخذت": 1,
    "فاتخذتموهم": 1,
    "فاتخذه": 1,
    "فاتخذوه": 1,
    "فاتقوا": 1,
    "فاتقون": 1,
    "فاتكم": 1,
    "فاتلوها": 1,
    "فاثبتوا": 1,
    "فاجتباه": 1,
    "فاجتنبوا": 1,
    "فاجتنبوه": 1,
    "فاجرا": 1,
    "فاجعل": 1,
    "فاجلدوا": 1,
    "فاجلدوهم": 1,
    "فاجنح": 1,
    "فاحترقت": 1,
    "فاحتمل": 1,
    "فاحذرهم": 1,
    "فاحذروا": 1,
    "فاحذروه": 1,
    "فاحذروهم": 1,
    "فاحشة": 1,
    "فاحكم": 1,
    "فاختلط": 1,
    "فاختلف": 1,
    "فاختلفوا": 1,
    "فاخرج": 1,
    "فاخشوهم": 1,
    "فاخلع": 1,
    "فادارأتم": 1,
    "فادخلوا": 1,
    "فادخلوها": 1,
    "فادخلي": 1,
    "فادرءوا": 1,
    "فادع": 1,
    "فادعوا": 1,
    "فادعوه": 1,
    "فادعوهم": 1,
    "فادفعوا": 1,
    "فاذكروا": 1,
    "فاذكروني": 1,
    "فاذهب": 1,
    "فاذهبا": 1,
    "فارتد": 1,
    "فارتدا": 1,
    "فارتقب": 1,
    "فارتقبهم": 1,
    "فارجع": 1,
    "فارجعنا": 1,
    "فارجعوا": 1,
    "فارزقوهم": 1,
    "فارض": 1,
    "فارغا": 1,
    "فارغب": 1,
    "فارقوهن": 1,
    "فارهبون": 1,
    "فارهين": 1,
    "فاسأل": 1,
    "فاسأله": 1,
    "فاسألوا": 1,
    "فاسألوهم": 1,
    "فاسألوهن": 1,
    "فاستأذنوك": 1,
    "فاستبشروا": 1,
    "فاستبقوا": 1,
    "فاستجاب": 1,
    "فاستجبتم": 1,
    "فاستجبنا": 1,
    "فاستحبوا": 1,
    "فاستخف": 1,
    "فاستشهدوا": 1,
    "فاستعذ": 1,
    "فاستعصم": 1,
    "فاستغاثه": 1,
    "فاستغفر": 1,
    "فاستغفروا": 1,
    "فاستغفروه": 1,
    "فاستغلظ": 1,
    "فاستفتهم": 1,
    "فاستقم": 1,
    "فاستقيما": 1,
    "فاستقيموا": 1,
    "فاستكبرتم": 1,
    "فاستكبروا": 1,
    "فاستمتعتم": 1,
    "فاستمتعوا": 1,
    "فاستمسك": 1,
    "فاستمع": 1,
    "فاستمعوا": 1,
    "فاستوى": 1,
    "فاسجد": 1,
    "فاسجدوا": 1,
    "فاسعوا": 1,
    "فاسقا": 1,
    "فاسقون": 1,
    "فاسقين": 1,
    "فاسلك": 1,
    "فاسلكوه": 1,
    "فاسلكي": 1,
    "فاسمعون": 1,
    "فاشهدوا": 1,
    "فاصبر": 1,
    "فاصبروا": 1,
    "فاصدع": 1,
    "فاصطادوا": 1,
    "فاصفح": 1,
    "فاضرب": 1,
    "فاضربوا": 1,
    "فاطر": 1,
    "فاطلع": 1,
    "فاطهروا": 1,
    "فاعبد": 1,
    "فاعبدني": 1,
    "فاعبده": 1,
    "فاعبدوا": 1,
    "فاعبدون": 1,
    "فاعبدوه": 1,
    "فاعتبروا": 1,
    "فاعتدوا": 1,
    "فاعترفنا": 1,
    "فاعترفوا": 1,
    "فاعتزلوا": 1,
    "فاعتزلون": 1,
    "فاعتلوه": 1,
    "فاعدلوا": 1,
    "فاعف": 1,
    "فاعفوا": 1,
    "فاعلم": 1,
    "فاعلموا": 1,
    "فاعلون": 1,
    "فاعلين": 1,
    "فاعمل": 1,
    "فاغسلوا": 1,
    "فاغفر": 1,
    "فافتح": 1,
    "فافرق": 1,
    "فافسحوا": 1,
    "فافعلوا": 1,
    "فاقتلوا": 1,
    "فاقتلوهم": 1,
    "فاقذفيه": 1,
    "فاقرءوا": 1,
    "فاقرة": 1,
    "فاقصص": 1,
    "فاقض": 1,
    "فاقطعوا": 1,
    "فاقع": 1,
    "فاقعدوا": 1,
    "فاكتبنا": 1,
    "فاكتبوه": 1,
    "فاكهون": 1,
    "فاكهين": 1,
    "فالآن": 1,
    "فالتاليات": 1,
    "فالتقطه": 1,
    "فالتقمه": 1,
    "فالتقى": 1,
    "فالتمسوا": 1,
    "فالجاريات": 1,
    "فالحاملات": 1,
    "فالحق": 1,
    "فالحكم": 1,
    "فالذين": 1,
    "فالزاجرات": 1,
    "فالسابقات": 1,
    "فالصالحات": 1,
    "فالعاصفات": 1,
    "فالفارقات": 1,
    "فالق": 1,
    "فالله": 1,
    "فالمدبرات": 1,
    "فالمغيرات": 1,
    "فالمقسمات": 1,
    "فالملقيات": 1,
    "فالموريات": 1,
    "فالنار": 1,
    "فامتحنوهن": 1,
    "فامسحوا": 1,
    "فامشوا": 1,
    "فامنن": 1,
    "فانبجست": 1,
    "فانبذ": 1,
    "فانتبذت": 1,
    "فانتشروا": 1,
    "فانتصر": 1,
    "فانتظروا": 1,
    "فانتقمنا": 1,
    "فانتهوا": 1,
    "فانتهى": 1,
    "فانسلخ": 1,
    "فانشزوا": 1,
    "فانصب": 1,
    "فانصرنا": 1,
    "فانطلقا": 1,
    "فانطلقوا": 1,
    "فانظر": 1,
    "فانظروا": 1,
    "فانظري": 1,
    "فانفجرت": 1,
    "فانفذوا": 1,
    "فانفروا": 1,
    "فانفلق": 1,
    "فانقلبوا": 1,
    "فانكحوا": 1,
    "فانكحوهن": 1,
    "فانهار": 1,
    "فاه": 1,
    "فاهبط": 1,
    "فاهجر": 1,
    "فاهدوهم": 1,
    "فبأي": 1,
    "فبإذن": 1,
    "فبئس": 1,
    "فباءوا": 1,
    "فبايعهن": 1,
    "فبدأ": 1,
    "فبدت": 1,
    "فبدل": 1,
    "فبذلك": 1,
    "فبرأه": 1,
    "فبشر": 1,
    "فبشرناه": 1,
    "فبشرناها": 1,
    "فبشره": 1,
    "فبشرهم": 1,
    "فبصرت": 1,
    "فبصرك": 1,
    "فبظلم": 1,
    "فبعث": 1,
    "فبعدا": 1,
    "فبعزتك": 1,
    "فبغى": 1,
    "فبلغن": 1,
    "فبم": 1,
    "فبما": 1,
    "فبهت": 1,
    "فبهداهم": 1,
    "فتأتون": 1,
    "فتأتيهم": 1,
    "فتأكل": 1,
    "فتاب": 1,
    "فتاها": 1,
    "فتبارك": 1,
    "فتبسم": 1,
    "فتبهتهم": 1,
    "فتبينوا": 1,
    "فتثير": 1,
    "فتحا": 1,
    "فتحت": 1,
    "فتحرير": 1,
    "فتحسسوا": 1,
    "فتحنا": 1,
    "فتحوا": 1,
    "فتخبت": 1,
    "فتخرجوه": 1,
    "فتخشى": 1,
    "فتخطفه": 1,
    "فتدلى": 1,
    "فتذروها": 1,
    "فتذكر": 1,
    "فتراه": 1,
    "فتربصوا": 1,
    "فتردى": 1,
    "فترضى": 1,
    "فتركه": 1,
    "فترى": 1,
    "فتزل": 1,
    "فتستجيبون": 1,
    "فتشابه": 1,
    "فتشقى": 1,
    "فتصبح": 1,
    "فتصبحوا": 1,
    "فتصيبكم": 1,
    "فتطاول": 1,
    "فتطردهم": 1,
    "فتعاطى": 1,
    "فتعالى": 1,
    "فتعالين": 1,
    "فتعرفونها": 1,
    "فتعسا": 1,
    "فتفجر": 1,
    "فتفرق": 1,
    "فتفشلوا": 1,
    "فتقبل": 1,
    "فتقبلها": 1,
    "فتقطعوا": 1,
    "فتقعد": 1,
    "فتقول": 1,
    "فتكفرون": 1,
    "فتكن": 1,
    "فتكون": 1,
    "فتكونا": 1,
    "فتكونون": 1,
    "فتكوى": 1,
    "فتلقى": 1,
    "فتلك": 1,
    "فتم": 1,
    "فتماروا": 1,
    "فتمتعوا": 1,
    "فتمثل": 1,
    "فتمسكم": 1,
    "فتمنوا": 1,
    "فتنا": 1,
    "فتنادوا": 1,
    "فتنازعوا": 1,
    "فتناه": 1,
    "فتنتك": 1,
    "فتنتكم": 1,
    "فتنتم": 1,
    "فتنته": 1,
    "فتنتهم": 1,
    "فتنفخ": 1,
    "فتنفعه": 1,
    "فتنقلبوا": 1,
    "فتنوا": 1,
    "فتهاجروا": 1,
    "فتهجد": 1,
    "فتوبوا": 1,
    "فتوكل": 1,
    "فتوكلوا": 1,
    "فتول": 1,
    "فتولوا": 1,
    "فتولى": 1,
    "فتونا": 1,
    "فتى": 1,
    "فتياتكم": 1,
    "فتيان": 1,
    "فتية": 1,
    "فتيلا": 1,
    "فتيمموا": 1,
    "فثبتوا": 1,
    "فثبطهم": 1,
    "فثم": 1,
    "فج": 1,
    "فجاء": 1,
    "فجاءته": 1,
    "فجاءها": 1,
    "فجاءوهم": 1,
    "فجاجا": 1,
    "فجاسوا": 1,
    "فجرت": 1,
    "فجزاء": 1,
    "فجزاؤه": 1,
    "فجعل": 1,
    "فجعلتم": 1,
    "فجعلنا": 1,
    "فجعلناه": 1,
    "فجعلناها": 1,
    "فجعلناهم": 1,
    "فجعلناهن": 1,
    "فجعله": 1,
    "فجعلهم": 1,
    "فجمع": 1,
    "فجمعناهم": 1,
    "فجوة": 1,
    "فجورها": 1,
    "فحاسبناها": 1,
    "فحاق": 1,
    "فحبطت": 1,
    "فحدث": 1,
    "فحسبه": 1,
    "فحشر": 1,
    "فحق": 1,
    "فحكمه": 1,
    "فحملته": 1,
    "فحيوا": 1,
    "فخانتاهما": 1,
    "فخذها": 1,
    "فخذوه": 1,
    "فخذوهم": 1,
    "فخر": 1,
    "فخراج": 1,
    "فخرج": 1,
    "فخسفنا": 1,
    "فخشينا": 1,
    "فخلف": 1,
    "فخلق": 1,
    "فخلقنا": 1,
    "فخلوا": 1,
    "فخورا": 1,
    "فداء": 1,
    "فدخلوا": 1,
    "فدعا": 1,
    "فدعوهم": 1,
    "فدكتا": 1,
    "فدلاهما": 1,
    "فدمدم": 1,
    "فدمرناها": 1,
    "فدمرناهم": 1,
    "فدية": 1,
    "فذاقت": 1,
    "فذاقوا": 1,
    "فذانك": 1,
    "فذبحوها": 1,
    "فذرني": 1,
    "فذرهم": 1,
    "فذروه": 1,
    "فذروها": 1,
    "فذكر": 1,
    "فذلك": 1,
    "فذلكم": 1,
    "فذلكن": 1,
    "فذو": 1,
    "فذوقوا": 1,
    "فذوقوه": 1,
    "فرآه": 1,
    "فرأوه": 1,
    "فرات": 1,
    "فراتا": 1,
    "فراشا": 1,
    "فربكم": 1,
    "فرت": 1,
    "فرث": 1,
    "فرجالا": 1,
    "فرجت": 1,
    "فرجع": 1,
    "فرجعناك": 1,
    "فرجعوا": 1,
    "فرجل": 1,
    "فرجها": 1,
    "فرحوا": 1,
    "فرحون": 1,
    "فرحين": 1,
    "فردا": 1,
    "فرددناه": 1,
    "فردوا": 1,
    "فردوه": 1,
    "فررتم": 1,
    "فرشناها": 1,
    "فرضتم": 1,
    "فرضنا": 1,
    "فرطا": 1,
    "فرطت": 1,
    "فرطتم": 1,
    "فرطنا": 1,
    "فرعون": 1,
    "فرغت": 1,
    "فرقا": 1,
    "فرقانا": 1,
    "فرقت": 1,
    "فرقنا": 1,
    "فرقناه": 1,
    "فرقوا": 1,
    "فرهان": 1,
    "فروج": 1,
    "فروجهم": 1,
    "فروجهن": 1,
    "فروح": 1,
    "فريضة": 1,
    "فريقان": 1,
    "فزادتهم": 1,
    "فزادهم": 1,
    "فزادوهم": 1,
    "فزده": 1,
    "فزع": 1,
    "فزعوا": 1,
    "فزيلنا": 1,
    "فزين": 1,
    "فزينوا": 1,
    "فسأكتبها": 1,
    "فساء": 1,
    "فسالت": 1,
    "فساهم": 1,
    "فسبح": 1,
    "فسبحان": 1,
    "فسبحه": 1,
    "فستبصر": 1,
    "فستذكرون": 1,
    "فسترضع": 1,
    "فستعلمون": 1,
    "فسجد": 1,
    "فسجدوا": 1,
    "فسحقا": 1,
    "فسخرنا": 1,
    "فسق": 1,
    "فسقا": 1,
    "فسقناه": 1,
    "فسقوا": 1,
    "فسقى": 1,
    "فسلام": 1,
    "فسلكه": 1,
    "فسلموا": 1,
    "فسنيسره": 1,
    "فسواك": 1,
    "فسواها": 1,
    "فسواهن": 1,
    "فسوف": 1,
    "فسوق": 1,
    "فسوى": 1,
    "فسيأتيهم": 1,
    "فسيؤتيه": 1,
    "فسيحشرهم": 1,
    "فسيحوا": 1,
    "فسيدخلهم": 1,
    "فسيروا": 1,
    "فسيرى": 1,
    "فسيعلمون": 1,
    "فسيقولون": 1,
    "فسيكفيكهم": 1,
    "فسينغضون": 1,
    "فسينفقونها": 1,
    "فشاربون": 1,
    "فشدوا": 1,
    "فشربوا": 1,
    "فشرد": 1,
    "فشلتم": 1,
    "فشهادة": 1,
    "فصالا": 1,
    "فصب": 1,
    "فصبر": 1,
    "فصبروا": 1,
    "فصدقت": 1,
    "فصدهم": 1,
    "فصدوا": 1,
    "فصرف": 1,
    "فصرهن": 1,
    "فصعق": 1,
    "فصكت": 1,
    "فصلت": 1,
    "فصلنا": 1,
    "فصلناه": 1,
    "فصلى": 1,
    "فصيام": 1,
    "فضة": 1,
    "فضحكت": 1,
    "فضرب": 1,
    "فضربنا": 1,
    "فضلا": 1,
    "فضلتكم": 1,
    "فضلكم": 1,
    "فضلنا": 1,
    "فضله": 1,
    "فضلوا": 1,
    "فطاف": 1,
    "فطال": 1,
    "فطبع": 1,
    "فطرت": 1,
    "فطركم": 1,
    "فطرنا": 1,
    "فطرني": 1,
    "فطرهن": 1,
    "فطفق": 1,
    "فطل": 1,
    "فطلقوهن": 1,
    "فطمسنا": 1,
    "فطهر": 1,
    "فطور": 1,
    "فطوعت": 1,
    "فظا": 1,
    "فظلت": 1,
    "فظلتم": 1,
    "فظلموا": 1,
    "فظلوا": 1,
    "فظن": 1,
    "فظنوا": 1,
    "فعاقبتم": 1,
    "فعاقبوا": 1,
    "فعتوا": 1,
    "فعجب": 1,
    "فعجل": 1,
    "فعدة": 1,
    "فعدتهن": 1,
    "فعدلك": 1,
    "فعرفهم": 1,
    "فعززنا": 1,
    "فعسى": 1,
    "فعصوا": 1,
    "فعصى": 1,
    "فعظوهن": 1,
    "فعفونا": 1,
    "فعقر": 1,
    "فعقروا": 1,
    "فعقروها": 1,
    "فعلتك": 1,
    "فعلتم": 1,
    "فعلته": 1,
    "فعلتها": 1,
    "فعلم": 1,
    "فعلموا": 1,
    "فعلن": 1,
    "فعلنا": 1,
    "فعله": 1,
    "فعلوا": 1,
    "فعلوه": 1,
    "فعلى": 1,
    "فعليكم": 1,
    "فعليه": 1,
    "فعليها": 1,
    "فعليهم": 1,
    "فعليهن": 1,
    "فعموا": 1,
    "فعميت": 1,
    "فعند": 1,
    "فغشاها": 1,
    "فغشيهم": 1,
    "فغفر": 1,
    "فغفرنا": 1,
    "فغلبوا": 1,
    "فغلوه": 1,
    "فغوى": 1,
    "ففتحنا": 1,
    "ففتقناهما": 1,
    "ففدية": 1,
    "ففررت": 1,
    "ففروا": 1,
    "ففريقا": 1,
    "ففزع": 1,
    "ففسق": 1,
    "ففسقوا": 1,
    "ففهمناها": 1,
    "ففي": 1,
    "فقاتل": 1,
    "فقاتلا": 1,
    "فقاتلوا": 1,
    "فقال": 1,
    "فقالت": 1,
    "فقالوا": 1,
    "فقبضت": 1,
    "فقتل": 1,
    "فقتله": 1,
    "فقدر": 1,
    "فقدرنا": 1,
    "فقدره": 1,
    "فقدموا": 1,
    "فقذفناها": 1,
    "فقرأه": 1,
    "فقراء": 1,
    "فقربه": 1,
    "فقست": 1,
    "فقضاهن": 1,
    "فقضى": 1,
    "فقطع": 1,
    "فقعوا": 1,
    "فقل": 1,
    "فقلت": 1,
    "فقلنا": 1,
    "فقليلا": 1,
    "فقنا": 1,
    "فقولا": 1,
    "فقولوا": 1,
    "فقولي": 1,
    "فقيرا": 1,
    "فكأنما": 1,
    "فكأين": 1,
    "فكاتبوهم": 1,
    "فكان": 1,
    "فكانت": 1,
    "فكانوا": 1,
    "فكبت": 1,
    "فكبر": 1,
    "فكبكبوا": 1,
    "فكثركم": 1,
    "فكذب": 1,
    "فكذبت": 1,
    "فكذبنا": 1,
    "فكذبوا": 1,
    "فكذبوه": 1,
    "فكذبوهما": 1,
    "فكذلك": 1,
    "فكرهتموه": 1,
    "فكسونا": 1,
    "فكشفنا": 1,
    "فكف": 1,
    "فكفارته": 1,
    "فكفرت": 1,
    "فكفروا": 1,
    "فكفى": 1,
    "فكلا": 1,
    "فكلوا": 1,
    "فكلوه": 1,
    "فكلي": 1,
    "فكنتم": 1,
    "فكهين": 1,
    "فكيدون": 1,
    "فكيدوني": 1,
    "فكيف": 1,
    "فلأقطعن": 1,
    "فلأمه": 1,
    "فلأنفسكم": 1,
    "فلأنفسهم": 1,
    "فلا": 1,
    "فلانا": 1,
    "فلبئس": 1,
    "فلبث": 1,
    "فلبثت": 1,
    "فلتقم": 1,
    "فلذلك": 1,
    "فلسوف": 1,
    "فلعرفتهم": 1,
    "فلعلك": 1,
    "فلعنة": 1,
    "فلقاتلوكم": 1,
    "فلكل": 1,
    "فلكم": 1,
    "فللذكر": 1,
    "فلله": 1,
    "فللوالدين": 1,
    "فلما": 1,
    "فلمسوه": 1,
    "فلنأتينك": 1,
    "فلنأتينهم": 1,
    "فلنحيينه": 1,
    "فلنذيقن": 1,
    "فلنسألن": 1,
    "فلنعم": 1,
    "فلنفسه": 1,
    "فلنقصن": 1,
    "فلننبئن": 1,
    "فلنولينك": 1,
    "فله": 1,
    "فلها": 1,
    "فلهم": 1,
    "فلهما": 1,
    "فلهن": 1,
    "فلو": 1,
    "فلولا": 1,
    "فليأت": 1,
    "فليأتكم": 1,
    "فليأتنا": 1,
    "فليأتوا": 1,
    "فليأكل": 1,
    "فليؤد": 1,
    "فليؤمن": 1,
    "فليبتكن": 1,
    "فليتقوا": 1,
    "فليتنافس": 1,
    "فليتوكل": 1,
    "فليحذر": 1,
    "فليدع": 1,
    "فليذوقوه": 1,
    "فليرتقوا": 1,
    "فليس": 1,
    "فليستأذنوا": 1,
    "فليستجيبوا": 1,
    "فليستعفف": 1,
    "فليصلوا": 1,
    "فليصمه": 1,
    "فليضحكوا": 1,
    "فليعبدوا": 1,
    "فليعلمن": 1,
    "فليعمل": 1,
    "فليغيرن": 1,
    "فليفرحوا": 1,
    "فليقاتل": 1,
    "فليكتب": 1,
    "فليكفر": 1,
    "فليكونوا": 1,
    "فليلقه": 1,
    "فليمدد": 1,
    "فليملل": 1,
    "فلينظر": 1,
    "فلينفق": 1,
    "فمأواهم": 1,
    "فما": 1,
    "فماذا": 1,
    "فمال": 1,
    "فمالئون": 1,
    "فمتاع": 1,
    "فمتعناهم": 1,
    "فمتعوهن": 1,
    "فمثله": 1,
    "فمحونا": 1,
    "فمرت": 1,
    "فمستقر": 1,
    "فمكث": 1,
    "فملاقيه": 1,
    "فمن": 1,
    "فمنكم": 1,
    "فمنه": 1,
    "فمنها": 1,
    "فمنهم": 1,
    "فمهل": 1,
    "فناداها": 1,
    "فنادته": 1,
    "فنادوا": 1,
    "فنادى": 1,
    "فناظرة": 1,
    "فنبذتها": 1,
    "فنبذناه": 1,
    "فنبذناهم": 1,
    "فنبذوه": 1,
    "فنتبرأ": 1,
    "فنتبع": 1,
    "فنجعل": 1,
    "فنجي": 1,
    "فنجيناك": 1,
    "فنجيناه": 1,
    "فنخرج": 1,
    "فنذر": 1,
    "فنردها": 1,
    "فنزل": 1,
    "فنسوا": 1,
    "فنسي": 1,
    "فنسيتها": 1,
    "فنسيهم": 1,
    "فنصف": 1,
    "فنظر": 1,
    "فنظرة": 1,
    "فنظل": 1,
    "فنعم": 1,
    "فنعما": 1,
    "فنعمل": 1,
    "فنفخنا": 1,
    "فنفعها": 1,
    "فنقبوا": 1,
    "فنكون": 1,
    "فننبئكم": 1,
    "فننبئهم": 1,
    "فهب": 1,
    "فهدى": 1,
    "فهديناهم": 1,
    "فهذا": 1,
    "فهزموهم": 1,
    "فهل": 1,
    "فهو": 1,
    "فهي": 1,
    "فواحدة": 1,
    "فواق": 1,
    "فوج": 1,
    "فوجا": 1,
    "فوجد": 1,
    "فوجدا": 1,
    "فوجدناها": 1,
    "فورب": 1,
    "فوربك": 1,
    "فورهم": 1,
    "فوزا": 1,
    "فوسطن": 1,
    "فوسوس": 1,
    "فوفاه": 1,
    "فوقاه": 1,
    "فوقاهم": 1,
    "فوقع": 1,
    "فوقكم": 1,
    "فوقها": 1,
    "فوقهم": 1,
    "فوقهن": 1,
    "فوكزه": 1,
    "فولوا": 1,
    "فوهب": 1,
    "فويل": 1,
    "فيأتيهم": 1,
    "فيأخذكم": 1,
    "فيؤخذ": 1,
    "فيؤمنوا": 1,
    "فيئوس": 1,
    "فيبسطه": 1,
    "فيتبعون": 1,
    "فيتعلمون": 1,
    "فيجعله": 1,
    "فيحفكم": 1,
    "فيحل": 1,
    "فيحلفون": 1,
    "فيحلوا": 1,
    "فيحيي": 1,
    "فيخرج": 1,
    "فيدخلهم": 1,
    "فيدمغه": 1,
    "فيدهنون": 1,
    "فيذرها": 1,
    "فيذهب": 1,
    "فيرسل": 1,
    "فيركمه": 1,
    "فيسبوا": 1,
    "فيستحيي": 1,
    "فيسحتكم": 1,
    "فيسخرون": 1,
    "فيسقي": 1,
    "فيشفعوا": 1,
    "فيصبحوا": 1,
    "فيصلب": 1,
    "فيصيب": 1,
    "فيضاعفه": 1,
    "فيضل": 1,
    "فيضلك": 1,
    "فيطمع": 1,
    "فيظللن": 1,
    "فيعتذرون": 1,
    "فيعذبه": 1,
    "فيعذبهم": 1,
    "فيعلمون": 1,
    "فيغرقكم": 1,
    "فيغفر": 1,
    "فيقتل": 1,
    "فيقتلون": 1,
    "فيقسمان": 1,
    "فيقول": 1,
    "فيقولوا": 1,
    "فيقولون": 1,
    "فيكشف": 1,
    "فيكم": 1,
    "فيكون": 1,
    "فيكيدوا": 1,
    "فيم": 1,
    "فيمت": 1,
    "فيمسك": 1,
    "فيمكث": 1,
    "فيموتوا": 1,
    "فيميلون": 1,
    "فينبئكم": 1,
    "فينبئهم": 1,
    "فينتقم": 1,
    "فينسخ": 1,
    "فينظر": 1,
    "فينظروا": 1,
    "فينقلبوا": 1,
    "فيهم": 1,
    "فيهما": 1,
    "فيهن": 1,
    "فيوحي": 1,
    "فيوفيهم": 1,
    "فيومئذ": 1,
    "قائلها": 1,
    "قائما": 1,
    "قائمون": 1,
    "قاب": 1,
    "قاتلكم": 1,
    "قاتلهم": 1,
    "قاتلوا": 1,
    "قاتلوكم": 1,
    "قاتلوهم": 1,
    "قادرون": 1,
    "قادرين": 1,
    "قارون": 1,
    "قاسية": 1,
    "قاصدا": 1,
    "قاصرات": 1,
    "قاصفا": 1,
    "قاطعة": 1,
    "قاعا": 1,
    "قاعدا": 1,
    "قاعدون": 1,
    "قالا": 1,
    "قالت": 1,
    "قالتا": 1,
    "قالها": 1,
    "قالوا": 1,
    "قاموا": 1,
    "قانت": 1,
    "قانتا": 1,
    "قانتات": 1,
    "قانتون": 1,
    "قانتين": 1,
    "قاهرون": 1,
    "قبره": 1,
    "قبس": 1,
    "قبضا": 1,
    "قبضته": 1,
    "قبضناه": 1,
    "قبلا": 1,
    "قبلتك": 1,
    "قبلتهم": 1,
    "قبلك": 1,
    "قبلكم": 1,
    "قبلنا": 1,
    "قبله": 1,
    "قبلها": 1,
    "قبلهم": 1,
    "قبلي": 1,
    "قبيلا": 1,
    "قتال": 1,
    "قتالا": 1,
    "قترة": 1,
    "قتلت": 1,
    "قتلتم": 1,
    "قتلتموهم": 1,
    "قتلنا": 1,
    "قتله": 1,
    "قتلهم": 1,
    "قتلوا": 1,
    "قتلوه": 1,
    "قتورا": 1,
    "قدحا": 1,
    "قددا": 1,
    "قدرا": 1,
    "قدرنا": 1,
    "قدرناه": 1,
    "قدرناها": 1,
    "قدره": 1,
    "قدروا": 1,
    "قدروها": 1,
    "قدمت": 1,
    "قدمتم": 1,
    "قدمتموه": 1,
    "قدموا": 1,
    "قديرا": 1,
    "قرآن": 1,
    "قرآنا": 1,
    "قرآنه": 1,
    "قرأت": 1,
    "قرأناه": 1,
    "قرئ": 1,
    "قرارا": 1,
    "قراطيس": 1,
    "قربا": 1,
    "قربات": 1,
    "قربانا": 1,
    "قربة": 1,
    "قربى": 1,
    "قرة": 1,
    "قرت": 1,
    "قرح": 1,
    "قرضا": 1,
    "قرطاس": 1,
    "قرنا": 1,
    "قرناء": 1,
    "قروء": 1,
    "قرونا": 1,
    "قرى": 1,
    "قريتك": 1,
    "قريتكم": 1,
    "قريتنا": 1,
    "قريش": 1,
    "قرين": 1,
    "قرينا": 1,
    "قرينه": 1,
    "قست": 1,
    "قسمة": 1,
    "قسمنا": 1,
    "قسورة": 1,
    "قسيسين": 1,
    "قصاص": 1,
    "قصصا": 1,
    "قصصنا": 1,
    "قصصناهم": 1,
    "قصصهم": 1,
    "قصمنا": 1,
    "قصورا": 1,
    "قصيا": 1,
    "قصيه": 1,
    "قضاها": 1,
    "قضوا": 1,
    "قضي": 1,
    "قضيت": 1,
    "قضيتم": 1,
    "قضينا": 1,
    "قطرا": 1,
    "قطران": 1,
    "قطعا": 1,
    "قطعت": 1,
    "قطعتم": 1,
    "قطعن": 1,
    "قطمير": 1,
    "قطنا": 1,
    "قطوفها": 1,
    "قعود": 1,
    "قعيد": 1,
    "قفينا": 1,
    "قلبك": 1,
    "قلبه": 1,
    "قلبها": 1,
    "قلبين": 1,
    "قلت": 1,
    "قلتم": 1,
    "قلته": 1,
    "قلن": 1,
    "قلنا": 1,
    "قلوب": 1,
    "قلوبكم": 1,
    "قلوبكما": 1,
    "قلوبنا": 1,
    "قلوبهم": 1,
    "قلى": 1,
    "قليلون": 1,
    "قمتم": 1,
    "قمطريرا": 1,
    "قميصه": 1,
    "قنطارا": 1,
    "قنطوا": 1,
    "قنوان": 1,
    "قنوط": 1,
    "قوا": 1,
    "قواريرا": 1,
    "قواما": 1,
    "قوامون": 1,
    "قوامين": 1,
    "قوتكم": 1,
    "قوتلتم": 1,
    "قوتلوا": 1,
    "قوسين": 1,
    "قولا": 1,
    "قولك": 1,
    "قولكم": 1,
    "قولنا": 1,
    "قوله": 1,
    "قولها": 1,
    "قولهم": 1,
    "قولوا": 1,
    "قولي": 1,
    "قوما": 1,
    "قومك": 1,
    "قومنا": 1,
    "قومه": 1,
    "قومها": 1,
    "قومهم": 1,
    "قويا": 1,
    "قيام": 1,
    "قياما": 1,
    "قيلا": 1,
    "قيما": 1,
    "كأحد": 1,
    "كأسا": 1,
    "كألف": 1,
    "كأمثال": 1,
    "كأنك": 1,
    "كأنما": 1,
    "كأنه": 1,
    "كأنها": 1,
    "كأنهم": 1,
    "كأنهن": 1,
    "كاتبا": 1,
    "كاتبون": 1,
    "كاتبين": 1,
    "كادت": 1,
    "كادوا": 1,
    "كاذبا": 1,
    "كاذبون": 1,
    "كاذبين": 1,
    "كارهون": 1,
    "كارهين": 1,
    "كاشفات": 1,
    "كاشفة": 1,
    "كاشفو": 1,
    "كاظمين": 1,
    "كافرة": 1,
    "كافرون": 1,
    "كافرين": 1,
    "كافورا": 1,
    "كالأعلام": 1,
    "كالأعمى": 1,
    "كالأنثى": 1,
    "كالأنعام": 1,
    "كالتي": 1,
    "كالجبال": 1,
    "كالجواب": 1,
    "كالحجارة": 1,
    "كالحون": 1,
    "كالدهان": 1,
    "كالذي": 1,
    "كالذين": 1,
    "كالرميم": 1,
    "كالصريم": 1,
    "كالطود": 1,
    "كالظلل": 1,
    "كالعرجون": 1,
    "كالعهن": 1,
    "كالفجار": 1,
    "كالفخار": 1,
    "كالفراش": 1,
    "كالقصر": 1,
    "كالمجرمين": 1,
    "كالمعلقة": 1,
    "كالمفسدين": 1,
    "كالمهل": 1,
    "كالوهم": 1,
    "كاملين": 1,
    "كانتا": 1,
    "كانوا": 1,
    "كبائر": 1,
    "كبارا": 1,
    "كباسط": 1,
    "كبت": 1,
    "كبتوا": 1,
    "كبد": 1,
    "كبره": 1,
    "كبيرا": 1,
    "كبيرهم": 1,
    "كتابا": 1,
    "كتابك": 1,
    "كتابنا": 1,
    "كتابها": 1,
    "كتابهم": 1,
    "كتابيه": 1,
    "كتبنا": 1,
    "كتبناها": 1,
    "كتم": 1,
    "كثرت": 1,
    "كثرتكم": 1,
    "كثيبا": 1,
    "كثيرة": 1,
    "كجهر": 1,
    "كحب": 1,
    "كخشية": 1,
    "كخلقه": 1,
    "كخيفتكم": 1,
    "كدأب": 1,
    "كدت": 1,
    "كدحا": 1,
    "كدعاء": 1,
    "كدنا": 1,
    "كذابا": 1,
    "كذبا": 1,
    "كذبت": 1,
    "كذبتم": 1,
    "كذبه": 1,
    "كذبوا": 1,
    "كذبوك": 1,
    "كذبوكم": 1,
    "كذبون": 1,
    "كذبوه": 1,
    "كذكركم": 1,
    "كذلكم": 1,
    "كرام": 1,
    "كراما": 1,
    "كرب": 1,
    "كرتين": 1,
    "كرسيه": 1,
    "كرماد": 1,
    "كرمت": 1,
    "كرمنا": 1,
    "كرهتموهن": 1,
    "كرهوا": 1,
    "كريما": 1,
    "كزرع": 1,
    "كسادها": 1,
    "كسالى": 1,
    "كسبا": 1,
    "كسبت": 1,
    "كسبتم": 1,
    "كسبوا": 1,
    "كسراب": 1,
    "كسفا": 1,
    "كسوتهم": 1,
    "كشجرة": 1,
    "كشطت": 1,
    "كشفت": 1,
    "كشفنا": 1,
    "كصاحب": 1,
    "كصيب": 1,
    "كطي": 1,
    "كظلمات": 1,
    "كظيم": 1,
    "كعذاب": 1,
    "كعرض": 1,
    "كعصف": 1,
    "كغلي": 1,
    "كفاتا": 1,
    "كفار": 1,
    "كفارا": 1,
    "كفارة": 1,
    "كفرا": 1,
    "كفران": 1,
    "كفرت": 1,
    "كفرتم": 1,
    "كفرنا": 1,
    "كفره": 1,
    "كفرهم": 1,
    "كفروا": 1,
    "كففت": 1,
    "كفلين": 1,
    "كفوا": 1,
    "كفور": 1,
    "كفورا": 1,
    "كفى": 1,
    "كفيلا": 1,
    "كفيناك": 1,
    "كفيه": 1,
    "كلالة": 1,
    "كلبهم": 1,
    "كلتا": 1,
    "كلتم": 1,
    "كلما": 1,
    "كلمت": 1,
    "كلمتنا": 1,
    "كلمح": 1,
    "كلمه": 1,
    "كله": 1,
    "كلها": 1,
    "كلهم": 1,
    "كلهن": 1,
    "كلوا": 1,
    "كماء": 1,
    "كمثله": 1,
    "كمشكاة": 1,
    "كمن": 1,
    "كنا": 1,
    "كنت": 1,
    "كنتم": 1,
    "كنتن": 1,
    "كنز": 1,
    "كنزتم": 1,
    "كنزهما": 1,
    "كنفس": 1,
    "كهشيم": 1,
    "كهفهم": 1,
    "كهيئة": 1,
    "كهيعص": 1,
    "كوكبا": 1,
    "كونوا": 1,
    "كيدا": 1,
    "كيدكم": 1,
    "كيدكن": 1,
    "كيده": 1,
    "كيدهم": 1,
    "كيدهن": 1,
    "كيدون": 1,
    "لآبائهم": 1,
    "لآت": 1,
    "لآتوها": 1,
    "لآتية": 1,
    "لآتينا": 1,
    "لآتيناهم": 1,
    "لآتينهم": 1,
    "لآدم": 1,
    "لآكلون": 1,
    "لآمن": 1,
    "لآيات": 1,
    "لآياتنا": 1,
    "لآية": 1,
    "لأبي": 1,
    "لأبيه": 1,
    "لأتخذن": 1,
    "لأجد": 1,
    "لأجدن": 1,
    "لأجرا": 1,
    "لأجعلنك": 1,
    "لأجل": 1,
    "لأحتنكن": 1,
    "لأحدهما": 1,
    "لأخته": 1,
    "لأخذنا": 1,
    "لأخراهم": 1,
    "لأخيه": 1,
    "لأذبحنه": 1,
    "لأذقناك": 1,
    "لأرجمنك": 1,
    "لأريناكهم": 1,
    "لأزواجك": 1,
    "لأزواجهم": 1,
    "لأزيدنكم": 1,
    "لأزينن": 1,
    "لأستغفرن": 1,
    "لأسجد": 1,
    "لأسقيناهم": 1,
    "لأسمعهم": 1,
    "لأصحاب": 1,
    "لأصلبنكم": 1,
    "لأظنك": 1,
    "لأظنه": 1,
    "لأعدل": 1,
    "لأعدوا": 1,
    "لأعذبنه": 1,
    "لأعنتكم": 1,
    "لأغلبن": 1,
    "لأغوينهم": 1,
    "لأقتلك": 1,
    "لأقتلنك": 1,
    "لأقرب": 1,
    "لأقطعن": 1,
    "لأقعدن": 1,
    "لأكثرهم": 1,
    "لأكفر": 1,
    "لأكفرن": 1,
    "لأكلوا": 1,
    "لأكونن": 1,
    "لأكيدن": 1,
    "لأمارة": 1,
    "لأماناتهم": 1,
    "لأمر": 1,
    "لأمسكتم": 1,
    "لأملأن": 1,
    "لأنت": 1,
    "لأنتم": 1,
    "لأنذركم": 1,
    "لأنزل": 1,
    "لأنعمه": 1,
    "لأنفسكم": 1,
    "لأنفسهم": 1,
    "لأهب": 1,
    "لأهل": 1,
    "لأهله": 1,
    "لأواه": 1,
    "لأوتين": 1,
    "لأولاهم": 1,
    "لأولنا": 1,
    "لأولي": 1,
    "لأيمانكم": 1,
    "لؤلؤ": 1,
    "لؤلؤا": 1,
    "لإبراهيم": 1,
    "لإثم": 1,
    "لإحدى": 1,
    "لإخوانهم": 1,
    "لإلى": 1,
    "لإيلاف": 1,
    "لئلا": 1,
    "لئن": 1,
    "لابتغوا": 1,
    "لابثين": 1,
    "لابنه": 1,
    "لاتبعتم": 1,
    "لاتبعناكم": 1,
    "لاتبعوك": 1,
    "لاتخذت": 1,
    "لاتخذناه": 1,
    "لاتخذوك": 1,
    "لاختلفتم": 1,
    "لارتاب": 1,
    "لازب": 1,
    "لاستكثرت": 1,
    "لاصطفى": 1,
    "لاعبين": 1,
    "لاغية": 1,
    "لافتدت": 1,
    "لافتدوا": 1,
    "لاقيه": 1,
    "لامرأته": 1,
    "لامستم": 1,
    "لانتصر": 1,
    "لانفضوا": 1,
    "لاهية": 1,
    "لبإمام": 1,
    "لبئس": 1,
    "لباسا": 1,
    "لباسهما": 1,
    "لبالمرصاد": 1,
    "لبث": 1,
    "لبثت": 1,
    "لبثتم": 1,
    "لبثنا": 1,
    "لبثوا": 1,
    "لبدا": 1,
    "لبرز": 1,
    "لبسبيل": 1,
    "لبشر": 1,
    "لبشرين": 1,
    "لبعثنا": 1,
    "لبعولتهن": 1,
    "لبغوا": 1,
    "لبلاغا": 1,
    "لبلد": 1,
    "لبن": 1,
    "لبنا": 1,
    "لبنيه": 1,
    "لبوس": 1,
    "لبيت": 1,
    "لبيوتهم": 1,
    "لتأتنني": 1,
    "لتأتون": 1,
    "لتأتينكم": 1,
    "لتأخذوها": 1,
    "لتأفكنا": 1,
    "لتأكلوا": 1,
    "لتؤمنن": 1,
    "لتؤمنوا": 1,
    "لتاركو": 1,
    "لتبتغوا": 1,
    "لتبدي": 1,
    "لتبشر": 1,
    "لتبعثن": 1,
    "لتبلغوا": 1,
    "لتبلون": 1,
    "لتبين": 1,
    "لتبيننه": 1,
    "لتتلو": 1,
    "لتجدن": 1,
    "لتجري": 1,
    "لتجزى": 1,
    "لتحسبوه": 1,
    "لتحصنكم": 1,
    "لتحكم": 1,
    "لتحملهم": 1,
    "لتخرج": 1,
    "لتخرجنا": 1,
    "لتخرجوا": 1,
    "لتدخلن": 1,
    "لتدعوهم": 1,
    "لتذكرة": 1,
    "لتذهبوا": 1,
    "لتردين": 1,
    "لترضوا": 1,
    "لترضى": 1,
    "لتركبن": 1,
    "لتركبوا": 1,
    "لتركبوها": 1,
    "لترون": 1,
    "لترونها": 1,
    "لتزول": 1,
    "لتسألن": 1,
    "لتستووا": 1,
    "لتسحرنا": 1,
    "لتسكنوا": 1,
    "لتسلكوا": 1,
    "لتشرك": 1,
    "لتشقى": 1,
    "لتشهدون": 1,
    "لتضيقوا": 1,
    "لتعارفوا": 1,
    "لتعتدوا": 1,
    "لتعجل": 1,
    "لتعرضوا": 1,
    "لتعلم": 1,
    "لتعلموا": 1,
    "لتعودن": 1,
    "لتغرق": 1,
    "لتغفر": 1,
    "لتفتروا": 1,
    "لتفتري": 1,
    "لتفسدن": 1,
    "لتقتلني": 1,
    "لتقرأه": 1,
    "لتقولون": 1,
    "لتكبروا": 1,
    "لتكفرون": 1,
    "لتكون": 1,
    "لتكونن": 1,
    "لتكونوا": 1,
    "لتلفتنا": 1,
    "لتلقى": 1,
    "لتمرون": 1,
    "لتنبؤن": 1,
    "لتنبئنهم": 1,
    "لتنذر": 1,
    "لتنزيل": 1,
    "لتنفقوا": 1,
    "لتنوء": 1,
    "لتهتدوا": 1,
    "لتهدي": 1,
    "لتولوا": 1,
    "لثمود": 1,
    "لجاءهم": 1,
    "لجاعلون": 1,
    "لجالوت": 1,
    "لجبريل": 1,
    "لجة": 1,
    "لجعل": 1,
    "لجعلكم": 1,
    "لجعلنا": 1,
    "لجعلناه": 1,
    "لجعله": 1,
    "لجعلهم": 1,
    "لجلودهم": 1,
    "لجمعهم": 1,
    "لجنبه": 1,
    "لجهنم": 1,
    "لجوا": 1,
    "لجي": 1,
    "لحافظون": 1,
    "لحافظين": 1,
    "لحب": 1,
    "لحبط": 1,
    "لحدود": 1,
    "لحديث": 1,
    "لحسرة": 1,
    "لحق": 1,
    "لحكم": 1,
    "لحكمه": 1,
    "لحكمهم": 1,
    "لحليم": 1,
    "لحومها": 1,
    "لحياتي": 1,
    "لخاسرون": 1,
    "لخاطئين": 1,
    "لخبير": 1,
    "لخرجنا": 1,
    "لخزنة": 1,
    "لخسف": 1,
    "لخلق": 1,
    "لدا": 1,
    "لداوود": 1,
    "لدلوك": 1,
    "لدنا": 1,
    "لدنك": 1,
    "لدنه": 1,
    "لدني": 1,
    "لدي": 1,
    "لدينا": 1,
    "لديهم": 1,
    "لذائقو": 1,
    "لذائقون": 1,
    "لذكر": 1,
    "لذكرى": 1,
    "لذكري": 1,
    "لذكورنا": 1,
    "لذنبك": 1,
    "لذنوبهم": 1,
    "لذهب": 1,
    "لذو": 1,
    "لذي": 1,
    "لرءوف": 1,
    "لرأيته": 1,
    "لرادك": 1,
    "لرب": 1,
    "لربك": 1,
    "لربكم": 1,
    "لربه": 1,
    "لربها": 1,
    "لربهم": 1,
    "لرجل": 1,
    "لرجمناك": 1,
    "لرحمة": 1,
    "لرزقنا": 1,
    "لرسلهم": 1,
    "لرسول": 1,
    "لرسوله": 1,
    "لرفعناه": 1,
    "لرقيك": 1,
    "لزاما": 1,
    "لزلفى": 1,
    "لساحر": 1,
    "لساحران": 1,
    "لسارقون": 1,
    "لسانا": 1,
    "لسانك": 1,
    "لسبإ": 1,
    "لست": 1,
    "لستم": 1,
    "لستن": 1,
    "لسحر": 1,
    "لسريع": 1,
    "لسعيه": 1,
    "لسعيها": 1,
    "لسلطهم": 1,
    "لسليمان": 1,
    "لسميع": 1,
    "لسنة": 1,
    "لسنت": 1,
    "لسنتنا": 1,
    "لسوف": 1,
    "لشاعر": 1,
    "لشتى": 1,
    "لشديد": 1,
    "لشر": 1,
    "لشرذمة": 1,
    "لشركائنا": 1,
    "لشركائهم": 1,
    "لشهادتنا": 1,
    "لشهيد": 1,
    "لشوبا": 1,
    "لشيء": 1,
    "لصاحبه": 1,
    "لصادق": 1,
    "لصادقون": 1,
    "لصالو": 1,
    "لصوت": 1,
    "لضالون": 1,
    "لطمسنا": 1,
    "لطيفا": 1,
    "لظالمون": 1,
    "لظالمين": 1,
    "لظلم": 1,
    "لظلوا": 1,
    "لظلوم": 1,
    "لظى": 1,
    "لعاد": 1,
    "لعادوا": 1,
    "لعال": 1,
    "لعبا": 1,
    "لعبادته": 1,
    "لعبادنا": 1,
    "لعباده": 1,
    "لعبادي": 1,
    "لعبرة": 1,
    "لعجل": 1,
    "لعدتهن": 1,
    "لعذبنا": 1,
    "لعذبهم": 1,
    "لعفو": 1,
    "لعل": 1,
    "لعلك": 1,
    "لعلكم": 1,
    "لعلمه": 1,
    "لعلنا": 1,
    "لعله": 1,
    "لعلهم": 1,
    "لعلى": 1,
    "لعلي": 1,
    "لعليم": 1,
    "لعمرك": 1,
    "لعملكم": 1,
    "لعنا": 1,
    "لعناهم": 1,
    "لعنت": 1,
    "لعنتم": 1,
    "لعنتي": 1,
    "لعنه": 1,
    "لعنهم": 1,
    "لعنوا": 1,
    "لغائظون": 1,
    "لغافلون": 1,
    "لغافلين": 1,
    "لغد": 1,
    "لغفار": 1,
    "لغفور": 1,
    "لغلامين": 1,
    "لغني": 1,
    "لغو": 1,
    "لغوا": 1,
    "لغوب": 1,
    "لغير": 1,
    "لفاسقون": 1,
    "لفاسقين": 1,
    "لفاعلون": 1,
    "لفتاه": 1,
    "لفتحنا": 1,
    "لفتيانه": 1,
    "لفرح": 1,
    "لفرعون": 1,
    "لفروجهم": 1,
    "لفريقا": 1,
    "لفسدت": 1,
    "لفسدتا": 1,
    "لفسق": 1,
    "لفشلتم": 1,
    "لفضله": 1,
    "لفي": 1,
    "لفيفا": 1,
    "لقاء": 1,
    "لقاءنا": 1,
    "لقائه": 1,
    "لقادر": 1,
    "لقادرون": 1,
    "لقال": 1,
    "لقالوا": 1,
    "لقتال": 1,
    "لقد": 1,
    "لقدير": 1,
    "لقرآن": 1,
    "لقسم": 1,
    "لقضي": 1,
    "لقطعنا": 1,
    "لقلنا": 1,
    "لقلوبكم": 1,
    "لقمان": 1,
    "لقوا": 1,
    "لقوكم": 1,
    "لقول": 1,
    "لقولهم": 1,
    "لقوم": 1,
    "لقومكما": 1,
    "لقومه": 1,
    "لقومهم": 1,
    "لقوي": 1,
    "لقيا": 1,
    "لقيتم": 1,
    "لقينا": 1,
    "لك": 1,
    "لكاذبون": 1,
    "لكارهون": 1,
    "لكافرون": 1,
    "لكان": 1,
    "لكبيرة": 1,
    "لكبيركم": 1,
    "لكتاب": 1,
    "لكفرنا": 1,
    "لكفور": 1,
    "لكلمات": 1,
    "لكلماته": 1,
    "لكما": 1,
    "لكنا": 1,
    "لكنت": 1,
    "لكنتم": 1,
    "لكنود": 1,
    "لكي": 1,
    "لكيلا": 1,
    "للآخرة": 1,
    "للآخرين": 1,
    "للآكلين": 1,
    "للأبرار": 1,
    "للأذقان": 1,
    "للأنام": 1,
    "للأوابين": 1,
    "للإسلام": 1,
    "للإنسان": 1,
    "للإيمان": 1,
    "للبث": 1,
    "للتقوى": 1,
    "للتي": 1,
    "للجبل": 1,
    "للجبين": 1,
    "للجوا": 1,
    "للحسنى": 1,
    "للحق": 1,
    "للحواريين": 1,
    "للحي": 1,
    "للخائنين": 1,
    "للخبيثات": 1,
    "للخبيثين": 1,
    "للخروج": 1,
    "للخير": 1,
    "للدين": 1,
    "للذاكرين": 1,
    "للذكر": 1,
    "للذي": 1,
    "للذين": 1,
    "للرؤيا": 1,
    "للرجال": 1,
    "للرحمن": 1,
    "للرسل": 1,
    "للزكاة": 1,
    "للسائلين": 1,
    "للساعة": 1,
    "للسحت": 1,
    "للسلم": 1,
    "للسمع": 1,
    "للشاربين": 1,
    "للشهادة": 1,
    "للشوى": 1,
    "للشياطين": 1,
    "للشيطان": 1,
    "للصابرين": 1,
    "للطائفين": 1,
    "للطاغين": 1,
    "للطيبات": 1,
    "للطيبين": 1,
    "للظالمين": 1,
    "للعابدين": 1,
    "للعالمين": 1,
    "للعباد": 1,
    "للعبيد": 1,
    "للعسرى": 1,
    "للغاوين": 1,
    "للغيب": 1,
    "للقاسية": 1,
    "للقمر": 1,
    "للقوم": 1,
    "للكافرين": 1,
    "للكتب": 1,
    "للكذب": 1,
    "للكفر": 1,
    "للمؤمنات": 1,
    "للمؤمنين": 1,
    "للمتقين": 1,
    "للمتكبرين": 1,
    "للمتوسمين": 1,
    "للمجرمين": 1,
    "للمحسنات": 1,
    "للمحسنين": 1,
    "للمخلفين": 1,
    "للمسرفين": 1,
    "للمسلمين": 1,
    "للمشركين": 1,
    "للمصلين": 1,
    "للمطففين": 1,
    "للمقوين": 1,
    "للمكذبين": 1,
    "للملإ": 1,
    "للملائكة": 1,
    "للموقنين": 1,
    "للناس": 1,
    "للناظرين": 1,
    "للنبي": 1,
    "للهدى": 1,
    "للوالدين": 1,
    "لليتامى": 1,
    "لليسرى": 1,
    "لمؤمن": 1,
    "لما": 1,
    "لمبتلين": 1,
    "لمبعوثون": 1,
    "لمبلسين": 1,
    "لمتنني": 1,
    "لمثل": 1,
    "لمثله": 1,
    "لمثوبة": 1,
    "لمجموعون": 1,
    "لمجنون": 1,
    "لمحجوبون": 1,
    "لمحضرون": 1,
    "لمحيطة": 1,
    "لمحيي": 1,
    "لمخرجون": 1,
    "لمدركون": 1,
    "لمدين": 1,
    "لمدينون": 1,
    "لمردودون": 1,
    "لمرسلون": 1,
    "لمزة": 1,
    "لمساكين": 1,
    "لمستقر": 1,
    "لمسجد": 1,
    "لمسخناهم": 1,
    "لمسرفون": 1,
    "لمسكم": 1,
    "لمسنا": 1,
    "لمشركون": 1,
    "لمعزولون": 1,
    "لمعكم": 1,
    "لمغرمون": 1,
    "لمغفرة": 1,
    "لمفعولا": 1,
    "لمقت": 1,
    "لمكر": 1,
    "لمن": 1,
    "لمنجوهم": 1,
    "لمنقلبون": 1,
    "لمنكم": 1,
    "لمهتدون": 1,
    "لمهلكهم": 1,
    "لموسعون": 1,
    "لموسى": 1,
    "لموعدهم": 1,
    "لموفوهم": 1,
    "لميتون": 1,
    "لميقات": 1,
    "لميقاتنا": 1,
    "لنؤمنن": 1,
    "لناصحون": 1,
    "لناكبون": 1,
    "لنبذ": 1,
    "لنبلوهم": 1,
    "لنبوئنهم": 1,
    "لنبي": 1,
    "لنبيتنه": 1,
    "لنبين": 1,
    "لنت": 1,
    "لنتخذن": 1,
    "لنثبت": 1,
    "لنجعلها": 1,
    "لنحرقنه": 1,
    "لنحشرنهم": 1,
    "لنحضرنهم": 1,
    "لنحن": 1,
    "لنحيي": 1,
    "لنخرج": 1,
    "لنخرجن": 1,
    "لنخرجنك": 1,
    "لنخرجنكم": 1,
    "لندخلنهم": 1,
    "لنذهبن": 1,
    "لنذيقهم": 1,
    "لنراك": 1,
    "لنراها": 1,
    "لنرجمنكم": 1,
    "لنرسل": 1,
    "لنريك": 1,
    "لنريه": 1,
    "لنزلنا": 1,
    "لنسألنهم": 1,
    "لنسفعا": 1,
    "لنسلم": 1,
    "لنصدقن": 1,
    "لنصرف": 1,
    "لنظنك": 1,
    "لنعبد": 1,
    "لنعلم": 1,
    "لنغرينك": 1,
    "لنفتنهم": 1,
    "لنفد": 1,
    "لنفسد": 1,
    "لنفسه": 1,
    "لنفسي": 1,
    "لنقولن": 1,
    "لنكفرن": 1,
    "لنكونن": 1,
    "لننجينه": 1,
    "لننزعن": 1,
    "لننسفنه": 1,
    "لننصر": 1,
    "لننصرنكم": 1,
    "لننظر": 1,
    "لنهتدي": 1,
    "لنهدينهم": 1,
    "لنهلكن": 1,
    "لنوره": 1,
    "لهاد": 1,
    "لهب": 1,
    "لهداكم": 1,
    "لهدمت": 1,
    "لهدى": 1,
    "لهديناكم": 1,
    "لهمت": 1,
    "لهن": 1,
    "لهوا": 1,
    "لهي": 1,
    "لواحة": 1,
    "لواحد": 1,
    "لواذا": 1,
    "لواقح": 1,
    "لواقع": 1,
    "لوالديه": 1,
    "لوجدوا": 1,
    "لوط": 1,
    "لوطا": 1,
    "لوقتها": 1,
    "لوقعتها": 1,
    "لولوا": 1,
    "لوليت": 1,
    "لوليه": 1,
    "لومة": 1,
    "لووا": 1,
    "ليأتيني": 1,
    "ليأخذ": 1,
    "ليأخذوه": 1,
    "ليأكلوا": 1,
    "ليأكلون": 1,
    "ليؤذن": 1,
    "ليؤمنن": 1,
    "ليؤمنوا": 1,
    "ليئوس": 1,
    "ليال": 1,
    "ليبتليكم": 1,
    "ليبدي": 1,
    "ليبطئن": 1,
    "ليبعثن": 1,
    "ليبغي": 1,
    "ليبلغ": 1,
    "ليبلو": 1,
    "ليبلوكم": 1,
    "ليبلونكم": 1,
    "ليبلوني": 1,
    "ليبين": 1,
    "ليت": 1,
    "ليتخذ": 1,
    "ليتساءلوا": 1,
    "ليتفقهوا": 1,
    "ليتنا": 1,
    "ليتني": 1,
    "ليتها": 1,
    "ليتوبوا": 1,
    "ليثبت": 1,
    "ليثبتوك": 1,
    "ليجادلوكم": 1,
    "ليجزي": 1,
    "ليجزيك": 1,
    "ليجزيهم": 1,
    "ليجعل": 1,
    "ليجمعنكم": 1,
    "ليحاجوكم": 1,
    "ليحبطن": 1,
    "ليحزن": 1,
    "ليحزنك": 1,
    "ليحزنني": 1,
    "ليحق": 1,
    "ليحكم": 1,
    "ليحملوا": 1,
    "ليخرج": 1,
    "ليخرجكم": 1,
    "ليخرجن": 1,
    "ليخرجوك": 1,
    "ليدبروا": 1,
    "ليدحضوا": 1,
    "ليدخل": 1,
    "ليدخلنهم": 1,
    "ليذر": 1,
    "ليذكروا": 1,
    "ليذهب": 1,
    "ليذوق": 1,
    "ليذوقوا": 1,
    "ليذيقهم": 1,
    "ليربو": 1,
    "ليردوهم": 1,
    "ليرزقنهم": 1,
    "ليرضوكم": 1,
    "ليروا": 1,
    "ليريكم": 1,
    "ليريه": 1,
    "ليريهما": 1,
    "ليزدادوا": 1,
    "ليزلقونك": 1,
    "ليسأل": 1,
    "ليستأذنكم": 1,
    "ليستخفوا": 1,
    "ليستخلفنهم": 1,
    "ليستفزونك": 1,
    "ليستيقن": 1,
    "ليسجنن": 1,
    "ليسجننه": 1,
    "ليسكن": 1,
    "ليسكنوا": 1,
    "ليسمون": 1,
    "ليسوءوا": 1,
    "ليسوا": 1,
    "ليشتروا": 1,
    "ليشهدوا": 1,
    "ليصبحن": 1,
    "ليصدوا": 1,
    "ليصدونهم": 1,
    "ليصرمنها": 1,
    "ليضل": 1,
    "ليضلنا": 1,
    "ليضلوا": 1,
    "ليضلون": 1,
    "ليضيع": 1,
    "ليطاع": 1,
    "ليطغى": 1,
    "ليطفئوا": 1,
    "ليطلعكم": 1,
    "ليطمئن": 1,
    "ليطهركم": 1,
    "ليظلمهم": 1,
    "ليظهره": 1,
    "ليعبدوا": 1,
    "ليعبدون": 1,
    "ليعجزه": 1,
    "ليعذب": 1,
    "ليعذبهم": 1,
    "ليعلم": 1,
    "ليعلموا": 1,
    "ليعلمون": 1,
    "ليغفر": 1,
    "ليغيظ": 1,
    "ليفتدوا": 1,
    "ليفتنونك": 1,
    "ليفجر": 1,
    "ليفسد": 1,
    "ليفسدوا": 1,
    "ليقتلوك": 1,
    "ليقربونا": 1,
    "ليقض": 1,
    "ليقضوا": 1,
    "ليقضى": 1,
    "ليقضي": 1,
    "ليقطع": 1,
    "ليقولن": 1,
    "ليقولوا": 1,
    "ليقولون": 1,
    "ليقوم": 1,
    "ليقيموا": 1,
    "ليكتمون": 1,
    "ليكفر": 1,
    "ليكفروا": 1,
    "ليكونا": 1,
    "ليكونن": 1,
    "ليكونوا": 1,
    "ليلها": 1,
    "ليمسن": 1,
    "ليمكروا": 1,
    "ليميز": 1,
    "لينبذن": 1,
    "لينذر": 1,
    "لينذركم": 1,
    "لينصرنه": 1,
    "لينفروا": 1,
    "لينفق": 1,
    "ليهديهم": 1,
    "ليهلك": 1,
    "ليواطئوا": 1,
    "ليوحون": 1,
    "ليوسف": 1,
    "ليوفينهم": 1,
    "ليوفيهم": 1,
    "ليولن": 1,
    "ليوم": 1,
    "مآب": 1,
    "مآبا": 1,
    "مآرب": 1,
    "مأتيا": 1,
    "مأمنه": 1,
    "مأواكم": 1,
    "مأواهم": 1,
    "مؤجلا": 1,
    "مؤذن": 1,
    "مؤصدة": 1,
    "مؤمنا": 1,
    "مؤمنات": 1,
    "مؤمنة": 1,
    "مؤمنون": 1,
    "مؤمنين": 1,
    "ماءك": 1,
    "ماءها": 1,
    "ماؤكم": 1,
    "ماؤها": 1,
    "مائتين": 1,
    "ماتوا": 1,
    "ماذا": 1,
    "مارج": 1,
    "مارد": 1,
    "ماكثون": 1,
    "ماكثين": 1,
    "مال": 1,
    "مالكون": 1,
    "ماله": 1,
    "ماليه": 1,
    "مانعتهم": 1,
    "مباركا": 1,
    "مباركة": 1,
    "مبتليكم": 1,
    "مبثوثة": 1,
    "مبديه": 1,
    "مبرءون": 1,
    "مبرمون": 1,
    "مبسوطتان": 1,
    "مبشرا": 1,
    "مبشرات": 1,
    "مبشرين": 1,
    "مبصرا": 1,
    "مبصرة": 1,
    "مبصرون": 1,
    "مبطلون": 1,
    "مبعدون": 1,
    "مبعوثون": 1,
    "مبلسون": 1,
    "مبلغهم": 1,
    "مبوأ": 1,
    "مبينا": 1,
    "مبينات": 1,
    "مبينة": 1,
    "متاب": 1,
    "متابا": 1,
    "متاع": 1,
    "متاعا": 1,
    "متاعنا": 1,
    "متاعهم": 1,
    "متبر": 1,
    "متبرجات": 1,
    "متبعون": 1,
    "متتابعين": 1,
    "متجانف": 1,
    "متجاورات": 1,
    "متحرفا": 1,
    "متحيزا": 1,
    "متخذ": 1,
    "متخذات": 1,
    "متخذي": 1,
    "متراكبا": 1,
    "متربة": 1,
    "متربص": 1,
    "متربصون": 1,
    "مترفوها": 1,
    "مترفين": 1,
    "مترفيها": 1,
    "مترفيهم": 1,
    "متشابها": 1,
    "متشابهات": 1,
    "متشاكسون": 1,
    "متصدعا": 1,
    "متعت": 1,
    "متعتهم": 1,
    "متعمدا": 1,
    "متعنا": 1,
    "متعناه": 1,
    "متعناهم": 1,
    "متفرقة": 1,
    "متفرقون": 1,
    "متقلبكم": 1,
    "متكئون": 1,
    "متكئين": 1,
    "متنا": 1,
    "متوفيك": 1,
    "متى": 1,
    "مثابة": 1,
    "مثاني": 1,
    "مثبورا": 1,
    "مثقال": 1,
    "مثقلة": 1,
    "مثقلون": 1,
    "مثلا": 1,
    "مثلكم": 1,
    "مثلنا": 1,
    "مثلها": 1,
    "مثلهم": 1,
    "مثلهن": 1,
    "مثليها": 1,
    "مثليهم": 1,
    "مثنى": 1,
    "مثواكم": 1,
    "مثواه": 1,
    "مثواي": 1,
    "مثوبة": 1,
    "مثوى": 1,
    "مجتمعون": 1,
    "مجذوذ": 1,
    "مجراها": 1,
    "مجرما": 1,
    "مجرمون": 1,
    "مجرمين": 1,
    "مجرميها": 1,
    "مجيب": 1,
    "محاريب": 1,
    "محبة": 1,
    "محجورا": 1,
    "محذورا": 1,
    "محررا": 1,
    "محرما": 1,
    "محرمة": 1,
    "محرومون": 1,
    "محسنون": 1,
    "محسنين": 1,
    "محسورا": 1,
    "محشورة": 1,
    "محصنات": 1,
    "محصنين": 1,
    "محضرا": 1,
    "محضرون": 1,
    "محظورا": 1,
    "محفوظا": 1,
    "محكمات": 1,
    "محلقين": 1,
    "محله": 1,
    "محلها": 1,
    "محمودا": 1,
    "محياهم": 1,
    "محيص": 1,
    "محيصا": 1,
    "محيطا": 1,
    "مختلفا": 1,
    "مختلفون": 1,
    "مخذولا": 1,
    "مخرجا": 1,
    "مخرجون": 1,
    "مخضرة": 1,
    "مخضود": 1,
    "مخلدون": 1,
    "مخلصا": 1,
    "مخلصون": 1,
    "مخلصين": 1,
    "مخلف": 1,
    "مخلقة": 1,
    "مخمصة": 1,
    "مد": 1,
    "مدا": 1,
    "مدادا": 1,
    "مدبرا": 1,
    "مدبرين": 1,
    "مدت": 1,
    "مدتهم": 1,
    "مدحورا": 1,
    "مدخلا": 1,
    "مددا": 1,
    "مددناها": 1,
    "مدرارا": 1,
    "مدكر": 1,
    "مدهامتان": 1,
    "مدهنون": 1,
    "مدينين": 1,
    "مذءوما": 1,
    "مذبذبين": 1,
    "مذعنين": 1,
    "مذكورا": 1,
    "مذموما": 1,
    "مراء": 1,
    "مراغما": 1,
    "مرتان": 1,
    "مرتفقا": 1,
    "مرتقبون": 1,
    "مرج": 1,
    "مرجعكم": 1,
    "مرجعهم": 1,
    "مرجوا": 1,
    "مرجون": 1,
    "مرد": 1,
    "مردا": 1,
    "مردفين": 1,
    "مردنا": 1,
    "مردوا": 1,
    "مرساها": 1,
    "مرسلا": 1,
    "مرسلة": 1,
    "مرسلو": 1,
    "مرسلين": 1,
    "مرشدا": 1,
    "مرصادا": 1,
    "مرصوص": 1,
    "مرضا": 1,
    "مرضات": 1,
    "مرضاتي": 1,
    "مرضت": 1,
    "مرضعة": 1,
    "مرضية": 1,
    "مرفقا": 1,
    "مرفوعة": 1,
    "مرقدنا": 1,
    "مرقوم": 1,
    "مركوم": 1,
    "مروا": 1,
    "مريئا": 1,
    "مرية": 1,
    "مريج": 1,
    "مريد": 1,
    "مريدا": 1,
    "مريضا": 1,
    "مزاجها": 1,
    "مزجاة": 1,
    "مزدجر": 1,
    "مزقتم": 1,
    "مسئولا": 1,
    "مسئولون": 1,
    "مساجد": 1,
    "مساس": 1,
    "مسافحات": 1,
    "مسافحين": 1,
    "مساكنكم": 1,
    "مساكنهم": 1,
    "مساكين": 1,
    "مستأنسين": 1,
    "مستبشرة": 1,
    "مستبصرين": 1,
    "مستخف": 1,
    "مستخلفين": 1,
    "مستسلمون": 1,
    "مستضعفون": 1,
    "مستضعفين": 1,
    "مستطر": 1,
    "مستطيرا": 1,
    "مستقرا": 1,
    "مستقرها": 1,
    "مستقيما": 1,
    "مستكبرا": 1,
    "مستكبرون": 1,
    "مستكبرين": 1,
    "مستمسكون": 1,
    "مستمعهم": 1,
    "مستمعون": 1,
    "مستنفرة": 1,
    "مسته": 1,
    "مستهزئون": 1,
    "مستهم": 1,
    "مستورا": 1,
    "مسجد": 1,
    "مسجدا": 1,
    "مسحا": 1,
    "مسحورا": 1,
    "مسحورون": 1,
    "مسخرات": 1,
    "مسد": 1,
    "مسرفون": 1,
    "مسرفين": 1,
    "مسرورا": 1,
    "مسطور": 1,
    "مسطورا": 1,
    "مسغبة": 1,
    "مسفرة": 1,
    "مسفوحا": 1,
    "مسكم": 1,
    "مسكنهم": 1,
    "مسكونة": 1,
    "مسكين": 1,
    "مسكينا": 1,
    "مسلما": 1,
    "مسلمات": 1,
    "مسلمة": 1,
    "مسلمون": 1,
    "مسلمين": 1,
    "مسمع": 1,
    "مسنا": 1,
    "مسندة": 1,
    "مسنون": 1,
    "مسني": 1,
    "مسه": 1,
    "مسهم": 1,
    "مسودا": 1,
    "مسودة": 1,
    "مسومة": 1,
    "مسومين": 1,
    "مشارق": 1,
    "مشتبها": 1,
    "مشتركون": 1,
    "مشربهم": 1,
    "مشرقين": 1,
    "مشرك": 1,
    "مشركة": 1,
    "مشركون": 1,
    "مشركين": 1,
    "مشفقون": 1,
    "مشفقين": 1,
    "مشكورا": 1,
    "مشهد": 1,
    "مشهود": 1,
    "مشهودا": 1,
    "مشوا": 1,
    "مشيدة": 1,
    "مشيك": 1,
    "مصبحين": 1,
    "مصدقا": 1,
    "مصرا": 1,
    "مصرفا": 1,
    "مصروفا": 1,
    "مصفرا": 1,
    "مصلحون": 1,
    "مصيبها": 1,
    "مصيرا": 1,
    "مصيركم": 1,
    "مضاجعهم": 1,
    "مضار": 1,
    "مضل": 1,
    "مضيا": 1,
    "مطاع": 1,
    "مطر": 1,
    "مطلعون": 1,
    "مطمئنة": 1,
    "مطمئنين": 1,
    "مطهرة": 1,
    "مطويات": 1,
    "مظلما": 1,
    "مظلمون": 1,
    "مظلوما": 1,
    "معاجزين": 1,
    "معاذ": 1,
    "معاذيره": 1,
    "معاشا": 1,
    "معتد": 1,
    "معجزي": 1,
    "معجزين": 1,
    "معدودات": 1,
    "معدودة": 1,
    "معذبهم": 1,
    "معذبوها": 1,
    "معذبين": 1,
    "معذرة": 1,
    "معذرتهم": 1,
    "معرة": 1,
    "معرضون": 1,
    "معرضين": 1,
    "معروشات": 1,
    "معروفا": 1,
    "معزل": 1,
    "معشار": 1,
    "معشر": 1,
    "معطلة": 1,
    "معقب": 1,
    "معقبات": 1,
    "معكم": 1,
    "معكما": 1,
    "معكوفا": 1,
    "معنا": 1,
    "معهم": 1,
    "معي": 1,
    "معيشة": 1,
    "معيشتها": 1,
    "معيشتهم": 1,
    "مغارات": 1,
    "مغاضبا": 1,
    "مغانم": 1,
    "مغرب": 1,
    "مغرقون": 1,
    "مغرما": 1,
    "مغفرة": 1,
    "مغلولة": 1,
    "مغيرا": 1,
    "مفاتح": 1,
    "مفاتحه": 1,
    "مفازا": 1,
    "مفتحة": 1,
    "مفتر": 1,
    "مفترون": 1,
    "مفتريات": 1,
    "مفرطون": 1,
    "مفروضا": 1,
    "مفسدون": 1,
    "مفسدين": 1,
    "مفصلا": 1,
    "مفصلات": 1,
    "مفعولا": 1,
    "مقاليد": 1,
    "مقاما": 1,
    "مقامع": 1,
    "مقامك": 1,
    "مقامهما": 1,
    "مقامي": 1,
    "مقبوضة": 1,
    "مقتا": 1,
    "مقتدر": 1,
    "مقتدرا": 1,
    "مقتدرون": 1,
    "مقتدون": 1,
    "مقترفون": 1,
    "مقترنين": 1,
    "مقتصدة": 1,
    "مقتكم": 1,
    "مقداره": 1,
    "مقدورا": 1,
    "مقرنين": 1,
    "مقسوم": 1,
    "مقصورات": 1,
    "مقضيا": 1,
    "مقمحون": 1,
    "مقنعي": 1,
    "مقيتا": 1,
    "مقيلا": 1,
    "مكاء": 1,
    "مكانا": 1,
    "مكانتكم": 1,
    "مكانتهم": 1,
    "مكانكم": 1,
    "مكبا": 1,
    "مكتوبا": 1,
    "مكث": 1,
    "مكذبين": 1,
    "مكذوب": 1,
    "مكر": 1,
    "مكرتموه": 1,
    "مكرمة": 1,
    "مكرمون": 1,
    "مكرهم": 1,
    "مكروا": 1,
    "مكروها": 1,
    "مكظوم": 1,
    "مكلبين": 1,
    "مكنا": 1,
    "مكناكم": 1,
    "مكناهم": 1,
    "مكنون": 1,
    "مكني": 1,
    "مكين": 1,
    "ملئت": 1,
    "ملائكة": 1,
    "ملاق": 1,
    "ملاقو": 1,
    "ملاقوه": 1,
    "ملاقيكم": 1,
    "ملة": 1,
    "ملتحدا": 1,
    "ملتكم": 1,
    "ملتنا": 1,
    "ملجإ": 1,
    "ملعونين": 1,
    "ملقون": 1,
    "ملكا": 1,
    "ملكت": 1,
    "ملكتم": 1,
    "ملكه": 1,
    "ملكوت": 1,
    "ملكين": 1,
    "ملوكا": 1,
    "ملوما": 1,
    "ملومين": 1,
    "مليا": 1,
    "مليك": 1,
    "مليم": 1,
    "مم": 1,
    "ممددة": 1,
    "ممدكم": 1,
    "ممدود": 1,
    "ممدودا": 1,
    "ممرد": 1,
    "ممسكات": 1,
    "ممطرنا": 1,
    "مملوكا": 1,
    "ممن": 1,
    "ممنون": 1,
    "منا": 1,
    "مناديا": 1,
    "مناسككم": 1,
    "مناسكنا": 1,
    "مناص": 1,
    "مناع": 1,
    "منافع": 1,
    "منافقون": 1,
    "مناكبها": 1,
    "منامك": 1,
    "منامكم": 1,
    "منامها": 1,
    "منبثا": 1,
    "منتصرا": 1,
    "منتصرين": 1,
    "منتظرون": 1,
    "منتقمون": 1,
    "منتهاها": 1,
    "منتهون": 1,
    "منثورا": 1,
    "منجوك": 1,
    "منذرون": 1,
    "منذرين": 1,
    "منزلا": 1,
    "منزلها": 1,
    "منزلون": 1,
    "منزلين": 1,
    "منسأته": 1,
    "منسكا": 1,
    "منسيا": 1,
    "منشرة": 1,
    "منشورا": 1,
    "منصورا": 1,
    "منضود": 1,
    "منطق": 1,
    "منظرون": 1,
    "منظرين": 1,
    "منعك": 1,
    "منعنا": 1,
    "منعهم": 1,
    "منفطر": 1,
    "منفكين": 1,
    "منقعر": 1,
    "منقلبا": 1,
    "منقلبون": 1,
    "منقوص": 1,
    "منك": 1,
    "منكرا": 1,
    "منكرة": 1,
    "منكرون": 1,
    "منكم": 1,
    "منكن": 1,
    "مننا": 1,
    "منهما": 1,
    "منهمر": 1,
    "منهن": 1,
    "منوعا": 1,
    "مني": 1,
    "منيب": 1,
    "منيبا": 1,
    "منيبين": 1,
    "منيرا": 1,
    "مهاجرا": 1,
    "مهاجرات": 1,
    "مهاد": 1,
    "مهادا": 1,
    "مهانا": 1,
    "مهتد": 1,
    "مهتدون": 1,
    "مهتدين": 1,
    "مهدا": 1,
    "مهطعين": 1,
    "مهلكهم": 1,
    "مهلكو": 1,
    "مهلكوها": 1,
    "مهلكي": 1,
    "مهيلا": 1,
    "مهينا": 1,
    "موئلا": 1,
    "مواخر": 1,
    "موازينه": 1,
    "مواضعه": 1,
    "مواقعوها": 1,
    "مواقيت": 1,
    "موبقا": 1,
    "موتتنا": 1,
    "موتكم": 1,
    "موته": 1,
    "موتها": 1,
    "موتوا": 1,
    "موثقا": 1,
    "موثقهم": 1,
    "مودة": 1,
    "مورا": 1,
    "موص": 1,
    "موضوعة": 1,
    "موضونة": 1,
    "موطئا": 1,
    "موعدا": 1,
    "موعدة": 1,
    "موعدك": 1,
    "موعدكم": 1,
    "موعده": 1,
    "موعدهم": 1,
    "موعدي": 1,
    "موعظة": 1,
    "موفورا": 1,
    "موقنون": 1,
    "موقنين": 1,
    "موقوتا": 1,
    "موقوفون": 1,
    "مولاكم": 1,
    "مولانا": 1,
    "مولاه": 1,
    "مولاهم": 1,
    "مولى": 1,
    "موليها": 1,
    "موهن": 1,
    "ميتة": 1,
    "ميتون": 1,
    "ميثاقا": 1,
    "ميثاقكم": 1,
    "ميثاقه": 1,
    "ميثاقهم": 1,
    "ميراث": 1,
    "ميسرة": 1,
    "ميسورا": 1,
    "ميعاد": 1,
    "ميقات": 1,
    "ميقاتا": 1,
    "ميقاتهم": 1,
    "ميلة": 1,
    "نأت": 1,
    "نأتي": 1,
    "نأتيكم": 1,
    "نأخذ": 1,
    "نأكل": 1,
    "نؤته": 1,
    "نؤتها": 1,
    "نؤتى": 1,
    "نؤتيه": 1,
    "نؤثرك": 1,
    "نؤخره": 1,
    "نؤمن": 1,
    "نائمون": 1,
    "ناجيتم": 1,
    "نادانا": 1,
    "ناداه": 1,
    "نادمين": 1,
    "نادوا": 1,
    "نادى": 1,
    "ناديتم": 1,
    "ناديكم": 1,
    "نادينا": 1,
    "ناديه": 1,
    "نارا": 1,
    "ناسكوه": 1,
    "ناصبة": 1,
    "ناصح": 1,
    "ناصحون": 1,
    "ناصر": 1,
    "ناصرا": 1,
    "ناصرين": 1,
    "ناصية": 1,
    "ناضرة": 1,
    "ناظرة": 1,
    "ناظرين": 1,
    "ناعمة": 1,
    "نافقوا": 1,
    "نافلة": 1,
    "ناقة": 1,
    "ناكسو": 1,
    "نبأ": 1,
    "نبأت": 1,
    "نبأتكما": 1,
    "نبأنا": 1,
    "نبأني": 1,
    "نبأه": 1,
    "نبأها": 1,
    "نبأهم": 1,
    "نبإ": 1,
    "نبئ": 1,
    "نبئنا": 1,
    "نبئوني": 1,
    "نباتا": 1,
    "نباته": 1,
    "نبتغي": 1,
    "نبتليه": 1,
    "نبتهل": 1,
    "نبدل": 1,
    "نبذه": 1,
    "نبرأها": 1,
    "نبرح": 1,
    "نبشرك": 1,
    "نبطش": 1,
    "نبعث": 1,
    "نبغ": 1,
    "نبغي": 1,
    "نبلوهم": 1,
    "نبيا": 1,
    "نبين": 1,
    "نبيهم": 1,
    "نتبع": 1,
    "نتبعكم": 1,
    "نتبعه": 1,
    "نتبعهم": 1,
    "نتبوأ": 1,
    "نتخذ": 1,
    "نتخذه": 1,
    "نتخطف": 1,
    "نتربص": 1,
    "نترك": 1,
    "نتقبل": 1,
    "نتقنا": 1,
    "نتكلم": 1,
    "نتلو": 1,
    "نتلوه": 1,
    "نتلوها": 1,
    "نتنزل": 1,
    "نتوفينك": 1,
    "نتوكل": 1,
    "نثبت": 1,
    "نجا": 1,
    "نجازي": 1,
    "نجاكم": 1,
    "نجانا": 1,
    "نجاهم": 1,
    "نجب": 1,
    "نجزي": 1,
    "نجزيه": 1,
    "نجس": 1,
    "نجعل": 1,
    "نجعلها": 1,
    "نجعلهم": 1,
    "نجعلهما": 1,
    "نجمع": 1,
    "نجني": 1,
    "نجواكم": 1,
    "نجواهم": 1,
    "نجوت": 1,
    "نجوى": 1,
    "نجيا": 1,
    "نجينا": 1,
    "نجيناكم": 1,
    "نجيناه": 1,
    "نجيناهم": 1,
    "نحبه": 1,
    "نحس": 1,
    "نحسات": 1,
    "نحشر": 1,
    "نحشرهم": 1,
    "نحلة": 1,
    "نحيي": 1,
    "نخاف": 1,
    "نختم": 1,
    "نخرة": 1,
    "نخرج": 1,
    "نخرجكم": 1,
    "نخسف": 1,
    "نخشى": 1,
    "نخفي": 1,
    "نخل": 1,
    "نخلفه": 1,
    "نخلقكم": 1,
    "نخوض": 1,
    "نداولها": 1,
    "ندخلها": 1,
    "ندري": 1,
    "ندع": 1,
    "ندعو": 1,
    "ندعوه": 1,
    "ندلكم": 1,
    "نديا": 1,
    "نذرا": 1,
    "نذرت": 1,
    "نذرتم": 1,
    "نذقه": 1,
    "نذهبن": 1,
    "نذورهم": 1,
    "نذيرا": 1,
    "نذيقهم": 1,
    "نراك": 1,
    "نربك": 1,
    "نرث": 1,
    "نرد": 1,
    "نرزقك": 1,
    "نرزقكم": 1,
    "نرزقهم": 1,
    "نرسل": 1,
    "نرفع": 1,
    "نرى": 1,
    "نري": 1,
    "نريد": 1,
    "نريك": 1,
    "نرينك": 1,
    "نريهم": 1,
    "نزاعة": 1,
    "نزد": 1,
    "نزعناها": 1,
    "نزغ": 1,
    "نزلا": 1,
    "نزلة": 1,
    "نزلت": 1,
    "نزلنا": 1,
    "نزلناه": 1,
    "نزله": 1,
    "نزلهم": 1,
    "نزيدكم": 1,
    "نسأل": 1,
    "نسألك": 1,
    "نساءكم": 1,
    "نساءهم": 1,
    "نساؤكم": 1,
    "نسائكم": 1,
    "نسائهم": 1,
    "نسائهن": 1,
    "نسارع": 1,
    "نسبا": 1,
    "نسبح": 1,
    "نسبحك": 1,
    "نستبق": 1,
    "نستحوذ": 1,
    "نستعين": 1,
    "نستنسخ": 1,
    "نسختها": 1,
    "نسخر": 1,
    "نسفا": 1,
    "نسفت": 1,
    "نسقط": 1,
    "نسقي": 1,
    "نسقيكم": 1,
    "نسك": 1,
    "نسلخ": 1,
    "نسلكه": 1,
    "نسله": 1,
    "نسمع": 1,
    "نسوا": 1,
    "نسوق": 1,
    "نسوه": 1,
    "نسوي": 1,
    "نسويكم": 1,
    "نسي": 1,
    "نسيا": 1,
    "نسيت": 1,
    "نسيتم": 1,
    "نسير": 1,
    "نسينا": 1,
    "نسيناكم": 1,
    "نشأ": 1,
    "نشاء": 1,
    "نشتري": 1,
    "نشرا": 1,
    "نشرت": 1,
    "نشرح": 1,
    "نشرك": 1,
    "نشطا": 1,
    "نشهد": 1,
    "نشورا": 1,
    "نشوزا": 1,
    "نشوزهن": 1,
    "نصارى": 1,
    "نصبا": 1,
    "نصبت": 1,
    "نصبر": 1,
    "نصحوا": 1,
    "نصحي": 1,
    "نصرا": 1,
    "نصرانيا": 1,
    "نصرف": 1,
    "نصركم": 1,
    "نصرنا": 1,
    "نصره": 1,
    "نصرهم": 1,
    "نصروهم": 1,
    "نصفه": 1,
    "نصليه": 1,
    "نصليهم": 1,
    "نصوحا": 1,
    "نصيب": 1,
    "نصيبا": 1,
    "نصيبك": 1,
    "نصيبهم": 1,
    "نصيرا": 1,
    "نضاختان": 1,
    "نضجت": 1,
    "نضربها": 1,
    "نضرة": 1,
    "نضطرهم": 1,
    "نضيد": 1,
    "نضيع": 1,
    "نطبع": 1,
    "نطعم": 1,
    "نطعمكم": 1,
    "نطفة": 1,
    "نطمس": 1,
    "نطمع": 1,
    "نطوي": 1,
    "نطيع": 1,
    "نظن": 1,
    "نظنك": 1,
    "نظنكم": 1,
    "نعاجه": 1,
    "نعبد": 1,
    "نعبدهم": 1,
    "نعجة": 1,
    "نعجتك": 1,
    "نعجز": 1,
    "نعجزه": 1,
    "نعد": 1,
    "نعدهم": 1,
    "نعذب": 1,
    "نعذبه": 1,
    "نعف": 1,
    "نعقل": 1,
    "نعلم": 1,
    "نعلمهم": 1,
    "نعلن": 1,
    "نعليك": 1,
    "نعما": 1,
    "نعماء": 1,
    "نعمة": 1,
    "نعمت": 1,
    "نعمتك": 1,
    "نعمته": 1,
    "نعمتي": 1,
    "نعمركم": 1,
    "نعمره": 1,
    "نعمل": 1,
    "نعمه": 1,
    "نعود": 1,
    "نعيدكم": 1,
    "نعيده": 1,
    "نعيم": 1,
    "نعيما": 1,
    "نغادر": 1,
    "نغرقهم": 1,
    "نغفر": 1,
    "نفاقا": 1,
    "نفحة": 1,
    "نفخة": 1,
    "نفدت": 1,
    "نفرا": 1,
    "نفرق": 1,
    "نفسا": 1,
    "نفشت": 1,
    "نفصل": 1,
    "نفعا": 1,
    "نفعت": 1,
    "نفعل": 1,
    "نفعه": 1,
    "نفعهما": 1,
    "نفقا": 1,
    "نفقاتهم": 1,
    "نفقد": 1,
    "نفقه": 1,
    "نفورا": 1,
    "نفوسكم": 1,
    "نفيرا": 1,
    "نقاتل": 1,
    "نقبا": 1,
    "نقتبس": 1,
    "نقدر": 1,
    "نقذف": 1,
    "نقر": 1,
    "نقرؤه": 1,
    "نقصص": 1,
    "نقصصهم": 1,
    "نقصه": 1,
    "نقضت": 1,
    "نقضهم": 1,
    "نقعا": 1,
    "نقعد": 1,
    "نقموا": 1,
    "نقول": 1,
    "نقيبا": 1,
    "نقيرا": 1,
    "نقيم": 1,
    "نك": 1,
    "نكاحا": 1,
    "نكال": 1,
    "نكالا": 1,
    "نكتل": 1,
    "نكتم": 1,
    "نكثوا": 1,
    "نكح": 1,
    "نكحتم": 1,
    "نكدا": 1,
    "نكذب": 1,
    "نكر": 1,
    "نكرا": 1,
    "نكرهم": 1,
    "نكروا": 1,
    "نكسوا": 1,
    "نكسوها": 1,
    "نكص": 1,
    "نكفر": 1,
    "نكلف": 1,
    "نكلم": 1,
    "نكن": 1,
    "نكون": 1,
    "نكير": 1,
    "نلعنهم": 1,
    "نمتعهم": 1,
    "نمد": 1,
    "نمدهم": 1,
    "نمكن": 1,
    "نملة": 1,
    "نمن": 1,
    "ننبئكم": 1,
    "ننج": 1,
    "ننجي": 1,
    "ننجيك": 1,
    "ننزل": 1,
    "ننزله": 1,
    "ننساكم": 1,
    "ننساهم": 1,
    "ننسخ": 1,
    "ننسها": 1,
    "ننشزها": 1,
    "ننظر": 1,
    "ننقصها": 1,
    "ننكسه": 1,
    "ننهك": 1,
    "نهار": 1,
    "نهارا": 1,
    "نهاكم": 1,
    "نهاكما": 1,
    "نهدي": 1,
    "نهرا": 1,
    "نهلك": 1,
    "نهوا": 1,
    "نهيت": 1,
    "نوحا": 1,
    "نوحي": 1,
    "نوحيه": 1,
    "نوحيها": 1,
    "نودي": 1,
    "نورث": 1,
    "نوركم": 1,
    "نورنا": 1,
    "نوره": 1,
    "نورهم": 1,
    "نوف": 1,
    "نوله": 1,
    "نولي": 1,
    "نومكم": 1,
    "نيلا": 1,
    "هؤلاء": 1,
    "هاؤم": 1,
    "هاتوا": 1,
    "هاتين": 1,
    "هاجرن": 1,
    "هاجروا": 1,
    "هاد": 1,
    "هادوا": 1,
    "هاديا": 1,
    "هار": 1,
    "هاروت": 1,
    "هامان": 1,
    "هامدة": 1,
    "هاهنا": 1,
    "هاوية": 1,
    "هباء": 1,
    "هجرا": 1,
    "هدا": 1,
    "هداكم": 1,
    "هدان": 1,
    "هدانا": 1,
    "هداني": 1,
    "هداها": 1,
    "هداهم": 1,
    "هداي": 1,
    "هدنا": 1,
    "هدى": 1,
    "هديا": 1,
    "هديتنا": 1,
    "هدينا": 1,
    "هديناه": 1,
    "هذان": 1,
    "هربا": 1,
    "هزوا": 1,
    "هشيما": 1,
    "هضما": 1,
    "هضيم": 1,
    "هلك": 1,
    "هلم": 1,
    "هلوعا": 1,
    "هما": 1,
    "هماز": 1,
    "همت": 1,
    "همزات": 1,
    "همزة": 1,
    "همسا": 1,
    "هن": 1,
    "هنالك": 1,
    "هنيئا": 1,
    "هواه": 1,
    "هودا": 1,
    "هون": 1,
    "هونا": 1,
    "هيت": 1,
    "هينا": 1,
    "هيه": 1,
    "هيهات": 1,
    "وآباءهم": 1,
    "وآباؤكم": 1,
    "وآباؤنا": 1,
    "وآت": 1,
    "وآتاكم": 1,
    "وآتاني": 1,
    "وآتاه": 1,
    "وآتاهم": 1,
    "وآتت": 1,
    "وآتنا": 1,
    "وآتوا": 1,
    "وآتوهم": 1,
    "وآتوهن": 1,
    "وآتى": 1,
    "وآتيتم": 1,
    "وآتين": 1,
    "وآتينا": 1,
    "وآتيناه": 1,
    "وآتيناهم": 1,
    "وآتيناهما": 1,
    "وآثارا": 1,
    "وآثارهم": 1,
    "وآثر": 1,
    "وآخر": 1,
    "وآخرنا": 1,
    "وآخرون": 1,
    "وآخرين": 1,
    "وآل": 1,
    "وآلهتك": 1,
    "وآمن": 1,
    "وآمنتم": 1,
    "وآمنهم": 1,
    "وآمنوا": 1,
    "وآويناهما": 1,
    "وآياته": 1,
    "وآية": 1,
    "وأبا": 1,
    "وأباريق": 1,
    "وأبرئ": 1,
    "وأبشروا": 1,
    "وأبصارا": 1,
    "وأبصاركم": 1,
    "وأبصارهم": 1,
    "وأبصر": 1,
    "وأبصرهم": 1,
    "وأبقى": 1,
    "وأبكارا": 1,
    "وأبكى": 1,
    "وأبلغكم": 1,
    "وأبناءكم": 1,
    "وأبناؤكم": 1,
    "وأبنائنا": 1,
    "وأبونا": 1,
    "وأبى": 1,
    "وأبيه": 1,
    "وأتاهم": 1,
    "وأتبعناهم": 1,
    "وأتبعوا": 1,
    "وأترفناهم": 1,
    "وأتمروا": 1,
    "وأتممت": 1,
    "وأتممناها": 1,
    "وأتموا": 1,
    "وأتوا": 1,
    "وأتوني": 1,
    "وأتيناك": 1,
    "وأثابهم": 1,
    "وأثاروا": 1,
    "وأثقالا": 1,
    "وأثل": 1,
    "وأجدر": 1,
    "وأجر": 1,
    "وأجرا": 1,
    "وأجل": 1,
    "وأجلب": 1,
    "وأجمعوا": 1,
    "وأحاط": 1,
    "وأحاطت": 1,
    "وأحباؤه": 1,
    "وأحسن": 1,
    "وأحسنوا": 1,
    "وأحصوا": 1,
    "وأحصى": 1,
    "وأحضرت": 1,
    "وأحل": 1,
    "وأحلت": 1,
    "وأحلوا": 1,
    "وأحيا": 1,
    "وأحيط": 1,
    "وأحيي": 1,
    "وأحييتنا": 1,
    "وأحيينا": 1,
    "وأخاف": 1,
    "وأخاه": 1,
    "وأخبتوا": 1,
    "وأخذ": 1,
    "وأخذت": 1,
    "وأخذتم": 1,
    "وأخذن": 1,
    "وأخذنا": 1,
    "وأخذناهم": 1,
    "وأخذهم": 1,
    "وأخذوا": 1,
    "وأخر": 1,
    "وأخرت": 1,
    "وأخرج": 1,
    "وأخرجت": 1,
    "وأخرجنا": 1,
    "وأخرجني": 1,
    "وأخرجوا": 1,
    "وأخرجوكم": 1,
    "وأخرجوهم": 1,
    "وأخرى": 1,
    "وأخفى": 1,
    "وأخلصوا": 1,
    "وأخواتكم": 1,
    "وأخوك": 1,
    "وأخوه": 1,
    "وأخي": 1,
    "وأخيه": 1,
    "وأداء": 1,
    "وأدبار": 1,
    "وأدبارهم": 1,
    "وأدخل": 1,
    "وأدخلنا": 1,
    "وأدخلناه": 1,
    "وأدخلناهم": 1,
    "وأدخلني": 1,
    "وأدخلهم": 1,
    "وأدعو": 1,
    "وأذان": 1,
    "وأذن": 1,
    "وأذنت": 1,
    "وأرادوا": 1,
    "وأرجلكم": 1,
    "وأرجلهم": 1,
    "وأرجلهن": 1,
    "وأرسل": 1,
    "وأرسلنا": 1,
    "وأرسلناك": 1,
    "وأرسلناه": 1,
    "وأرض": 1,
    "وأرضا": 1,
    "وأرنا": 1,
    "وأرى": 1,
    "وأزلفت": 1,
    "وأزلفنا": 1,
    "وأزواج": 1,
    "وأزواجكم": 1,
    "وأزواجه": 1,
    "وأزواجهم": 1,
    "وأسبغ": 1,
    "وأسرحكن": 1,
    "وأسررت": 1,
    "وأسروا": 1,
    "وأسروه": 1,
    "وأسقيناكم": 1,
    "وأسلحتهم": 1,
    "وأسلمت": 1,
    "وأسلموا": 1,
    "وأسلنا": 1,
    "وأسمع": 1,
    "وأسيرا": 1,
    "وأشد": 1,
    "وأشربوا": 1,
    "وأشرقت": 1,
    "وأشرك": 1,
    "وأشركه": 1,
    "وأشعارها": 1,
    "وأشفقن": 1,
    "وأشهدهم": 1,
    "وأشهدوا": 1,
    "وأصابه": 1,
    "وأصبح": 1,
    "وأصحاب": 1,
    "وأصروا": 1,
    "وأصفاكم": 1,
    "وأصلح": 1,
    "وأصلحا": 1,
    "وأصلحنا": 1,
    "وأصلحوا": 1,
    "وأصيلا": 1,
    "وأضعف": 1,
    "وأضل": 1,
    "وأضله": 1,
    "وأضلهم": 1,
    "وأضلوا": 1,
    "وأطراف": 1,
    "وأطعموا": 1,
    "وأطعن": 1,
    "وأطعنا": 1,
    "وأطغى": 1,
    "وأطهر": 1,
    "وأطيعوا": 1,
    "وأطيعون": 1,
    "وأظهره": 1,
    "وأعانه": 1,
    "وأعتدت": 1,
    "وأعتدنا": 1,
    "وأعتزلكم": 1,
    "وأعد": 1,
    "وأعدوا": 1,
    "وأعرض": 1,
    "وأعز": 1,
    "وأعطى": 1,
    "وأعظم": 1,
    "وأعلم": 1,
    "وأعمى": 1,
    "وأعناب": 1,
    "وأعنابا": 1,
    "وأعوذ": 1,
    "وأعينهم": 1,
    "وأغرقنا": 1,
    "وأغطش": 1,
    "وأغلالا": 1,
    "وأفئدة": 1,
    "وأفئدتهم": 1,
    "وأفوض": 1,
    "وأقام": 1,
    "وأقاموا": 1,
    "وأقبل": 1,
    "وأقبلوا": 1,
    "وأقرب": 1,
    "وأقرضتم": 1,
    "وأقرضوا": 1,
    "وأقسطوا": 1,
    "وأقسموا": 1,
    "وأقل": 1,
    "وأقم": 1,
    "وأقمن": 1,
    "وأقنى": 1,
    "وأقوم": 1,
    "وأقيموا": 1,
    "وأكثر": 1,
    "وأكثرهم": 1,
    "وأكدى": 1,
    "وأكلهم": 1,
    "وأكن": 1,
    "وأكواب": 1,
    "وأكيد": 1,
    "وألحقني": 1,
    "وألزمهم": 1,
    "وألسنتهم": 1,
    "وألف": 1,
    "وألفيا": 1,
    "وألق": 1,
    "وألقت": 1,
    "وألقوا": 1,
    "وألقوه": 1,
    "وألقى": 1,
    "وألقي": 1,
    "وألقيت": 1,
    "وألقينا": 1,
    "وألنا": 1,
    "وألوانكم": 1,
    "وأما": 1,
    "وأمتعتكم": 1,
    "وأمددناكم": 1,
    "وأمددناهم": 1,
    "وأمر": 1,
    "وأمرت": 1,
    "وأمرنا": 1,
    "وأمره": 1,
    "وأمرهم": 1,
    "وأمروا": 1,
    "وأمطرنا": 1,
    "وأملى": 1,
    "وأملي": 1,
    "وأمم": 1,
    "وأمنا": 1,
    "وأمه": 1,
    "وأمهات": 1,
    "وأمهاتكم": 1,
    "وأمواتا": 1,
    "وأموال": 1,
    "وأموالا": 1,
    "وأموالهم": 1,
    "وأمي": 1,
    "وأميت": 1,
    "وأن": 1,
    "وأنا": 1,
    "وأناب": 1,
    "وأنابوا": 1,
    "وأناسي": 1,
    "وأنبئكم": 1,
    "وأنبتت": 1,
    "وأنبتنا": 1,
    "وأنبتها": 1,
    "وأنت": 1,
    "وأنتم": 1,
    "وأنثى": 1,
    "وأنجينا": 1,
    "وأنذر": 1,
    "وأنذرهم": 1,
    "وأنزل": 1,
    "وأنزلنا": 1,
    "وأنشأنا": 1,
    "وأنصتوا": 1,
    "وأنصح": 1,
    "وأنعام": 1,
    "وأنعمت": 1,
    "وأنفسكم": 1,
    "وأنفسنا": 1,
    "وأنفسهم": 1,
    "وأنفقوا": 1,
    "وأنك": 1,
    "وأنكحوا": 1,
    "وأنكم": 1,
    "وأنه": 1,
    "وأنهار": 1,
    "وأنهارا": 1,
    "وأنهم": 1,
    "وأنى": 1,
    "وأني": 1,
    "وأنيبوا": 1,
    "وأهديك": 1,
    "وأهش": 1,
    "وأهل": 1,
    "وأهلك": 1,
    "وأهلكنا": 1,
    "وأهلنا": 1,
    "وأهله": 1,
    "وأهلها": 1,
    "وأهلونا": 1,
    "وأهلي": 1,
    "وأهليكم": 1,
    "وأهليهم": 1,
    "وأوبارها": 1,
    "وأوتيت": 1,
    "وأوتينا": 1,
    "وأوجس": 1,
    "وأوحى": 1,
    "وأوحي": 1,
    "وأوحينا": 1,
    "وأوذوا": 1,
    "وأورثكم": 1,
    "وأورثنا": 1,
    "وأورثناها": 1,
    "وأوصاني": 1,
    "وأوفوا": 1,
    "وأولئك": 1,
    "وأولئكم": 1,
    "وأولات": 1,
    "وأولادا": 1,
    "وأولادكم": 1,
    "وأولادهم": 1,
    "وأولو": 1,
    "وأولي": 1,
    "وأياما": 1,
    "وأيدكم": 1,
    "وأيدناه": 1,
    "وأيده": 1,
    "وأيدهم": 1,
    "وأيدي": 1,
    "وأيديكم": 1,
    "وأيديهم": 1,
    "وأيمانهم": 1,
    "وأيوب": 1,
    "وإبراهيم": 1,
    "وإثما": 1,
    "وإثمك": 1,
    "وإثمهما": 1,
    "وإخراج": 1,
    "وإخوان": 1,
    "وإخوانكم": 1,
    "وإخوانهم": 1,
    "وإخوته": 1,
    "وإدبار": 1,
    "وإدريس": 1,
    "وإذ": 1,
    "وإذا": 1,
    "وإرصادا": 1,
    "وإستبرق": 1,
    "وإسحاق": 1,
    "وإسرائيل": 1,
    "وإسرافنا": 1,
    "وإسماعيل": 1,
    "وإقام": 1,
    "وإلا": 1,
    "وإله": 1,
    "وإلهكم": 1,
    "وإلهنا": 1,
    "وإلى": 1,
    "وإلي": 1,
    "وإلياس": 1,
    "وإليك": 1,
    "وإلينا": 1,
    "وإليه": 1,
    "وإما": 1,
    "وإمائكم": 1,
    "وإن": 1,
    "وإنا": 1,
    "وإناثا": 1,
    "وإنك": 1,
    "وإنكم": 1,
    "وإنما": 1,
    "وإننا": 1,
    "وإنني": 1,
    "وإنه": 1,
    "وإنها": 1,
    "وإنهم": 1,
    "وإنهما": 1,
    "وإني": 1,
    "وإياك": 1,
    "وإياكم": 1,
    "وإياهم": 1,
    "وإياي": 1,
    "وإيتاء": 1,
    "وابتغ": 1,
    "وابتغاء": 1,
    "وابتغاؤكم": 1,
    "وابتغوا": 1,
    "وابتلوا": 1,
    "وابعث": 1,
    "وابل": 1,
    "وابن": 1,
    "وابنها": 1,
    "وابيضت": 1,
    "واتبع": 1,
    "واتبعت": 1,
    "واتبعتهم": 1,
    "واتبعك": 1,
    "واتبعنا": 1,
    "واتبعوا": 1,
    "واتبعون": 1,
    "واتبعوه": 1,
    "واتخذ": 1,
    "واتخذتموه": 1,
    "واتخذوا": 1,
    "واترك": 1,
    "واتق": 1,
    "واتقوا": 1,
    "واتقون": 1,
    "واتقوه": 1,
    "واتقى": 1,
    "واتقين": 1,
    "واتل": 1,
    "واثقكم": 1,
    "واجتبينا": 1,
    "واجتبيناهم": 1,
    "واجتنبوا": 1,
    "واجعل": 1,
    "واجعلنا": 1,
    "واجعلني": 1,
    "واجعله": 1,
    "واجعلوا": 1,
    "واجفة": 1,
    "واجنبني": 1,
    "واحدا": 1,
    "واحذرهم": 1,
    "واحذروا": 1,
    "واحصروهم": 1,
    "واحفظوا": 1,
    "واحلل": 1,
    "واختار": 1,
    "واختلاف": 1,
    "واختلفوا": 1,
    "واخشوا": 1,
    "واخشون": 1,
    "واخشوني": 1,
    "واخفض": 1,
    "واد": 1,
    "وادخلوا": 1,
    "وادخلي": 1,
    "وادع": 1,
    "وادعوا": 1,
    "وادعوه": 1,
    "وادكر": 1,
    "واديا": 1,
    "واذكر": 1,
    "واذكرن": 1,
    "واذكروا": 1,
    "واذكروه": 1,
    "وارتابت": 1,
    "وارتبتم": 1,
    "وارتقبوا": 1,
    "وارجعوا": 1,
    "وارجوا": 1,
    "وارحم": 1,
    "وارحمنا": 1,
    "واردها": 1,
    "واردهم": 1,
    "واردون": 1,
    "وارزق": 1,
    "وارزقنا": 1,
    "وارزقهم": 1,
    "وارزقوهم": 1,
    "وارعوا": 1,
    "واركعوا": 1,
    "واركعي": 1,
    "وازدادوا": 1,
    "وازدجر": 1,
    "وازرة": 1,
    "وازينت": 1,
    "واسأل": 1,
    "واسألهم": 1,
    "واسألوا": 1,
    "واستبقا": 1,
    "واستحيوا": 1,
    "واسترهبوهم": 1,
    "واستشهدوا": 1,
    "واستعمركم": 1,
    "واستعينوا": 1,
    "واستغشوا": 1,
    "واستغفر": 1,
    "واستغفره": 1,
    "واستغفروا": 1,
    "واستغفروه": 1,
    "واستغفري": 1,
    "واستغنى": 1,
    "واستفتحوا": 1,
    "واستفزز": 1,
    "واستقم": 1,
    "واستكبر": 1,
    "واستكبرت": 1,
    "واستكبرتم": 1,
    "واستكبروا": 1,
    "واستمتعتم": 1,
    "واستمع": 1,
    "واستوت": 1,
    "واستوى": 1,
    "واستيقنتها": 1,
    "واسجد": 1,
    "واسجدوا": 1,
    "واسجدي": 1,
    "واسعا": 1,
    "واسمع": 1,
    "واسمعوا": 1,
    "واشتروا": 1,
    "واشتعل": 1,
    "واشدد": 1,
    "واشربوا": 1,
    "واشربي": 1,
    "واشكروا": 1,
    "واشهد": 1,
    "واشهدوا": 1,
    "واصب": 1,
    "واصبا": 1,
    "واصبر": 1,
    "واصبروا": 1,
    "واصطبر": 1,
    "واصطفاك": 1,
    "واصطنعتك": 1,
    "واصفح": 1,
    "واصفحوا": 1,
    "واصنع": 1,
    "واضرب": 1,
    "واضربوا": 1,
    "واضربوهن": 1,
    "واضمم": 1,
    "واطمأنوا": 1,
    "واعبد": 1,
    "واعبدوا": 1,
    "واعبدوه": 1,
    "واعتصموا": 1,
    "واعدنا": 1,
    "واعف": 1,
    "واعلم": 1,
    "واعلموا": 1,
    "واعملوا": 1,
    "واعية": 1,
    "واغضض": 1,
    "واغفر": 1,
    "واغلظ": 1,
    "وافعلوا": 1,
    "واقترب": 1,
    "واقتلوهم": 1,
    "واقصد": 1,
    "واقعدوا": 1,
    "واكتب": 1,
    "واكسوهم": 1,
    "واكفروا": 1,
    "وال": 1,
    "والآخر": 1,
    "والآخرة": 1,
    "والآخرين": 1,
    "والآصال": 1,
    "والأبرص": 1,
    "والأبصار": 1,
    "والأحبار": 1,
    "والأحزاب": 1,
    "والأذن": 1,
    "والأذى": 1,
    "والأرحام": 1,
    "والأرض": 1,
    "والأزلام": 1,
    "والأسباط": 1,
    "والأصم": 1,
    "والأعناب": 1,
    "والأعناق": 1,
    "والأغلال": 1,
    "والأفئدة": 1,
    "والأقدام": 1,
    "والأقربون": 1,
    "والأقربين": 1,
    "والأمر": 1,
    "والأميين": 1,
    "والأنثى": 1,
    "والأنصاب": 1,
    "والأنصار": 1,
    "والأنعام": 1,
    "والأنف": 1,
    "والأنفس": 1,
    "والأولاد": 1,
    "والأولى": 1,
    "والأولين": 1,
    "والإبكار": 1,
    "والإثم": 1,
    "والإحسان": 1,
    "والإشراق": 1,
    "والإكرام": 1,
    "والإنجيل": 1,
    "والإنس": 1,
    "والإيمان": 1,
    "والباد": 1,
    "والباطل": 1,
    "والباطن": 1,
    "والباقيات": 1,
    "والبحر": 1,
    "والبدن": 1,
    "والبصر": 1,
    "والبصير": 1,
    "والبغال": 1,
    "والبغضاء": 1,
    "والبغي": 1,
    "والبلد": 1,
    "والبنون": 1,
    "والبنين": 1,
    "والبيت": 1,
    "والترائب": 1,
    "والتفت": 1,
    "والتقوى": 1,
    "والتوراة": 1,
    "والتي": 1,
    "والتين": 1,
    "والثمرات": 1,
    "والجار": 1,
    "والجان": 1,
    "والجبال": 1,
    "والجبلة": 1,
    "والجراد": 1,
    "والجروح": 1,
    "والجسم": 1,
    "والجلود": 1,
    "والجن": 1,
    "والجوع": 1,
    "والحافظات": 1,
    "والحافظون": 1,
    "والحافظين": 1,
    "والحب": 1,
    "والحج": 1,
    "والحجارة": 1,
    "والحرث": 1,
    "والحرمات": 1,
    "والحساب": 1,
    "والحق": 1,
    "والحكم": 1,
    "والحكمة": 1,
    "والحمد": 1,
    "والحمير": 1,
    "والحياة": 1,
    "والخاشعات": 1,
    "والخاشعين": 1,
    "والخامسة": 1,
    "والخبيثون": 1,
    "والخنازير": 1,
    "والخوف": 1,
    "والخير": 1,
    "والخيل": 1,
    "والد": 1,
    "والدار": 1,
    "والدة": 1,
    "والدتك": 1,
    "والدم": 1,
    "والده": 1,
    "والدواب": 1,
    "والدي": 1,
    "والذاريات": 1,
    "والذاكرات": 1,
    "والذاكرين": 1,
    "والذكر": 1,
    "والذين": 1,
    "والراسخون": 1,
    "والربانيون": 1,
    "والرجز": 1,
    "والرسول": 1,
    "والرقيم": 1,
    "والركب": 1,
    "والركع": 1,
    "والرمان": 1,
    "والرهبان": 1,
    "والروح": 1,
    "والريحان": 1,
    "والزاني": 1,
    "والزانية": 1,
    "والزبر": 1,
    "والزرع": 1,
    "والزكاة": 1,
    "والزيتون": 1,
    "والسائلين": 1,
    "والسابحات": 1,
    "والسابقون": 1,
    "والسارق": 1,
    "والسارقة": 1,
    "والساعة": 1,
    "والسحاب": 1,
    "والسراء": 1,
    "والسعة": 1,
    "والسقف": 1,
    "والسلاسل": 1,
    "والسلام": 1,
    "والسلوى": 1,
    "والسماء": 1,
    "والسماوات": 1,
    "والسميع": 1,
    "والسن": 1,
    "والسوء": 1,
    "والسيئات": 1,
    "والشجر": 1,
    "والشجرة": 1,
    "والشعراء": 1,
    "والشفع": 1,
    "والشمائل": 1,
    "والشمس": 1,
    "والشهادة": 1,
    "والشهداء": 1,
    "والشهر": 1,
    "والشياطين": 1,
    "والصائمات": 1,
    "والصائمين": 1,
    "والصابئون": 1,
    "والصابئين": 1,
    "والصابرات": 1,
    "والصابرين": 1,
    "والصاحب": 1,
    "والصادقات": 1,
    "والصادقين": 1,
    "والصافات": 1,
    "والصالحين": 1,
    "والصبح": 1,
    "والصديقين": 1,
    "والصلاة": 1,
    "والصلح": 1,
    "والصيف": 1,
    "والضحى": 1,
    "والضراء": 1,
    "والضفادع": 1,
    "والضلال": 1,
    "والطارق": 1,
    "والطاغوت": 1,
    "والطور": 1,
    "والطيب": 1,
    "والطيبات": 1,
    "والطيبون": 1,
    "والطير": 1,
    "والظالمون": 1,
    "والظالمين": 1,
    "والظاهر": 1,
    "والعاديات": 1,
    "والعافين": 1,
    "والعاقبة": 1,
    "والعاكفين": 1,
    "والعاملين": 1,
    "والعبد": 1,
    "والعدوان": 1,
    "والعذاب": 1,
    "والعزى": 1,
    "والعشي": 1,
    "والعصر": 1,
    "والعصيان": 1,
    "والعمرة": 1,
    "والعمل": 1,
    "والعنهم": 1,
    "والعير": 1,
    "والعين": 1,
    "والغارمين": 1,
    "والغاوون": 1,
    "والغنم": 1,
    "والغوا": 1,
    "والفؤاد": 1,
    "والفتح": 1,
    "والفتنة": 1,
    "والفجر": 1,
    "والفحشاء": 1,
    "والفرقان": 1,
    "والفسوق": 1,
    "والفضة": 1,
    "والفلك": 1,
    "والفواحش": 1,
    "والقائلين": 1,
    "والقائمين": 1,
    "والقاسية": 1,
    "والقانتات": 1,
    "والقانتين": 1,
    "والقرآن": 1,
    "والقلائد": 1,
    "والقلم": 1,
    "والقمر": 1,
    "والقناطير": 1,
    "والقواعد": 1,
    "والكاظمين": 1,
    "والكافرون": 1,
    "والكافرين": 1,
    "والكتاب": 1,
    "والكفار": 1,
    "واللائي": 1,
    "واللاتي": 1,
    "واللذان": 1,
    "والله": 1,
    "والليل": 1,
    "والمؤتفكات": 1,
    "والمؤتفكة": 1,
    "والمؤتون": 1,
    "والمؤلفة": 1,
    "والمؤمنات": 1,
    "والمؤمنون": 1,
    "والمؤمنين": 1,
    "والمتردية": 1,
    "والمتصدقات": 1,
    "والمتصدقين": 1,
    "والمجاهدون": 1,
    "والمجوس": 1,
    "والمحروم": 1,
    "والمحصنات": 1,
    "والمرجان": 1,
    "والمرجفون": 1,
    "والمرسلات": 1,
    "والمروة": 1,
    "والمساكين": 1,
    "والمستضعفين": 1,
    "والمستغفرين": 1,
    "والمسجد": 1,
    "والمسكنة": 1,
    "والمسكين": 1,
    "والمسلمات": 1,
    "والمسيح": 1,
    "والمشركات": 1,
    "والمشركين": 1,
    "والمصدقات": 1,
    "والمطلقات": 1,
    "والمطلوب": 1,
    "والمعتر": 1,
    "والمغارب": 1,
    "والمغرب": 1,
    "والمغفرة": 1,
    "والمقيمي": 1,
    "والمقيمين": 1,
    "والمكذبين": 1,
    "والملائكة": 1,
    "والملك": 1,
    "والمنافقات": 1,
    "والمنافقين": 1,
    "والمنخنقة": 1,
    "والمنفقين": 1,
    "والمنكر": 1,
    "والمهاجرين": 1,
    "والموتى": 1,
    "والموعظة": 1,
    "والموفون": 1,
    "والموقوذة": 1,
    "والميزان": 1,
    "والميسر": 1,
    "والنار": 1,
    "والنازعات": 1,
    "والناس": 1,
    "والناشرات": 1,
    "والناشطات": 1,
    "والناهون": 1,
    "والنبوة": 1,
    "والنبي": 1,
    "والنبيون": 1,
    "والنبيين": 1,
    "والنجم": 1,
    "والنجوم": 1,
    "والنخل": 1,
    "والنخيل": 1,
    "والنذر": 1,
    "والنساء": 1,
    "والنسل": 1,
    "والنصارى": 1,
    "والنطيحة": 1,
    "والنهار": 1,
    "والنور": 1,
    "والنوم": 1,
    "والنوى": 1,
    "والهدى": 1,
    "والهدي": 1,
    "والوالدات": 1,
    "والوتر": 1,
    "والوزن": 1,
    "والولدان": 1,
    "واليتامى": 1,
    "واليسع": 1,
    "واليوم": 1,
    "وامتازوا": 1,
    "وامرأة": 1,
    "وامرأت": 1,
    "وامرأتان": 1,
    "وامرأته": 1,
    "وامرأتي": 1,
    "وامسحوا": 1,
    "وامضوا": 1,
    "وانتصروا": 1,
    "وانتظر": 1,
    "وانتظروا": 1,
    "وانحر": 1,
    "وانشق": 1,
    "وانشقت": 1,
    "وانصرنا": 1,
    "وانصروا": 1,
    "وانطلق": 1,
    "وانظر": 1,
    "وانظرنا": 1,
    "وانظروا": 1,
    "وانقلبوا": 1,
    "وانه": 1,
    "واهجرني": 1,
    "واهجرهم": 1,
    "واهجروهن": 1,
    "واهدنا": 1,
    "واهية": 1,
    "وبأيمانهم": 1,
    "وبئر": 1,
    "وبئس": 1,
    "وباءوا": 1,
    "وبارك": 1,
    "وباركنا": 1,
    "وباطل": 1,
    "وباطنة": 1,
    "وباطنه": 1,
    "وبال": 1,
    "وبالآخرة": 1,
    "وبالأسحار": 1,
    "وبالحق": 1,
    "وبالذي": 1,
    "وبالرسول": 1,
    "وبالزبر": 1,
    "وبالكتاب": 1,
    "وبالليل": 1,
    "وبالمؤمنين": 1,
    "وبالنجم": 1,
    "وبالوالدين": 1,
    "وباليوم": 1,
    "وبث": 1,
    "وبدأ": 1,
    "وبدا": 1,
    "وبدارا": 1,
    "وبداره": 1,
    "وبدلناهم": 1,
    "وبذلك": 1,
    "وبذي": 1,
    "وبرا": 1,
    "وبرحمته": 1,
    "وبرزت": 1,
    "وبرزوا": 1,
    "وبرسوله": 1,
    "وبرسولي": 1,
    "وبرق": 1,
    "وبركات": 1,
    "وبركاته": 1,
    "وبست": 1,
    "وبسر": 1,
    "وبشر": 1,
    "وبشرناه": 1,
    "وبشروه": 1,
    "وبشرى": 1,
    "وبشير": 1,
    "وبصدهم": 1,
    "وبصلها": 1,
    "وبطل": 1,
    "وبعثنا": 1,
    "وبعهد": 1,
    "وبعولتهن": 1,
    "وبقية": 1,
    "وبكفرهم": 1,
    "وبكلامي": 1,
    "وبكم": 1,
    "وبكما": 1,
    "وبكيا": 1,
    "وبلغ": 1,
    "وبلغت": 1,
    "وبلغنا": 1,
    "وبلوناهم": 1,
    "وبمن": 1,
    "وبنات": 1,
    "وبناتك": 1,
    "وبناتكم": 1,
    "وبنعمة": 1,
    "وبنعمت": 1,
    "وبني": 1,
    "وبنين": 1,
    "وبنينا": 1,
    "وبنيه": 1,
    "وبه": 1,
    "وبوأكم": 1,
    "وبيع": 1,
    "وبيلا": 1,
    "وبين": 1,
    "وبينات": 1,
    "وبينك": 1,
    "وبينكم": 1,
    "وبينه": 1,
    "وبينهم": 1,
    "وبينهما": 1,
    "وبينوا": 1,
    "وتأبى": 1,
    "وتأتون": 1,
    "وتأسرون": 1,
    "وتأكلون": 1,
    "وتؤتوها": 1,
    "وتؤمنون": 1,
    "وتؤوي": 1,
    "وتاب": 1,
    "وتالله": 1,
    "وتب": 1,
    "وتبارك": 1,
    "وتبتل": 1,
    "وتبرئ": 1,
    "وتبغونها": 1,
    "وتبين": 1,
    "وتتخذون": 1,
    "وتتقوا": 1,
    "وتتلقاهم": 1,
    "وتثبيتا": 1,
    "وتجارة": 1,
    "وتجاهدون": 1,
    "وتجعلون": 1,
    "وتحبون": 1,
    "وتحرير": 1,
    "وتحسبهم": 1,
    "وتحسبونه": 1,
    "وتحشرون": 1,
    "وتحمل": 1,
    "وتحيتهم": 1,
    "وتخر": 1,
    "وتخرج": 1,
    "وتخرجون": 1,
    "وتخشى": 1,
    "وتخفون": 1,
    "وتخفي": 1,
    "وتخلت": 1,
    "وتخلقون": 1,
    "وتخونوا": 1,
    "وتدعوا": 1,
    "وتدعونني": 1,
    "وتدلوا": 1,
    "وتذرون": 1,
    "وتذكيري": 1,
    "وتذل": 1,
    "وتذهب": 1,
    "وتذوقوا": 1,
    "وتراهم": 1,
    "وتربصتم": 1,
    "وترجون": 1,
    "وترحمنا": 1,
    "وترحمني": 1,
    "وترزق": 1,
    "وترغبون": 1,
    "وتركتم": 1,
    "وتركنا": 1,
    "وتركهم": 1,
    "وتركوك": 1,
    "وترهقهم": 1,
    "وترى": 1,
    "وتزكيهم": 1,
    "وتزهق": 1,
    "وتزودوا": 1,
    "وتسبحوه": 1,
    "وتسبيحه": 1,
    "وتستخرجوا": 1,
    "وتستخرجون": 1,
    "وتسعون": 1,
    "وتسلموا": 1,
    "وتسليما": 1,
    "وتسود": 1,
    "وتسير": 1,
    "وتشاور": 1,
    "وتشتكي": 1,
    "وتشهد": 1,
    "وتصدق": 1,
    "وتصدون": 1,
    "وتصدية": 1,
    "وتصريف": 1,
    "وتصف": 1,
    "وتصفحوا": 1,
    "وتصلحوا": 1,
    "وتصلية": 1,
    "وتضحكون": 1,
    "وتضع": 1,
    "وتطمئن": 1,
    "وتظنون": 1,
    "وتعاونوا": 1,
    "وتعز": 1,
    "وتعزروه": 1,
    "وتعلم": 1,
    "وتعيها": 1,
    "وتغشى": 1,
    "وتغفروا": 1,
    "وتفاخر": 1,
    "وتفريقا": 1,
    "وتفصيل": 1,
    "وتفصيلا": 1,
    "وتفقد": 1,
    "وتقبل": 1,
    "وتقسطوا": 1,
    "وتقطعت": 1,
    "وتقطعوا": 1,
    "وتقطعون": 1,
    "وتقلبك": 1,
    "وتقواها": 1,
    "وتقول": 1,
    "وتقولوا": 1,
    "وتقولون": 1,
    "وتكاثر": 1,
    "وتكتموا": 1,
    "وتكتمون": 1,
    "وتكفرون": 1,
    "وتكلمنا": 1,
    "وتكونوا": 1,
    "وتلذ": 1,
    "وتلك": 1,
    "وتله": 1,
    "وتماثيل": 1,
    "وتمت": 1,
    "وتمتعوا": 1,
    "وتناجوا": 1,
    "وتنازعتم": 1,
    "وتنحتون": 1,
    "وتنذر": 1,
    "وتنزع": 1,
    "وتنسون": 1,
    "وتنشق": 1,
    "وتنهون": 1,
    "وتهدي": 1,
    "وتواصوا": 1,
    "وتوبوا": 1,
    "وتودون": 1,
    "وتوفنا": 1,
    "وتوفى": 1,
    "وتوفيقا": 1,
    "وتوقروه": 1,
    "وتوكل": 1,
    "وتول": 1,
    "وتولج": 1,
    "وتولوا": 1,
    "وتولى": 1,
    "وثاقه": 1,
    "وثامنهم": 1,
    "وثبت": 1,
    "وثقالا": 1,
    "وثلاث": 1,
    "وثلة": 1,
    "وثلثه": 1,
    "وثمانية": 1,
    "وثمود": 1,
    "وثيابك": 1,
    "وجئتك": 1,
    "وجئتكم": 1,
    "وجئنا": 1,
    "وجاء": 1,
    "وجاءت": 1,
    "وجاءته": 1,
    "وجاءتهم": 1,
    "وجاءك": 1,
    "وجاءكم": 1,
    "وجاءه": 1,
    "وجاءهم": 1,
    "وجاءوا": 1,
    "وجادلهم": 1,
    "وجادلوا": 1,
    "وجاعل": 1,
    "وجاعلوه": 1,
    "وجاهد": 1,
    "وجاهدهم": 1,
    "وجاهدوا": 1,
    "وجاوزنا": 1,
    "وجبت": 1,
    "وجبريل": 1,
    "وجحدوا": 1,
    "وجحيما": 1,
    "وجدت": 1,
    "وجدتم": 1,
    "وجدتموهم": 1,
    "وجدكم": 1,
    "وجدنا": 1,
    "وجدناه": 1,
    "وجدها": 1,
    "وجدوا": 1,
    "وجرين": 1,
    "وجزاء": 1,
    "وجزاهم": 1,
    "وجعل": 1,
    "وجعلت": 1,
    "وجعلكم": 1,
    "وجعلنا": 1,
    "وجعلناكم": 1,
    "وجعلناه": 1,
    "وجعلناها": 1,
    "وجعلناهم": 1,
    "وجعلني": 1,
    "وجعلها": 1,
    "وجعلوا": 1,
    "وجفان": 1,
    "وجلة": 1,
    "وجلت": 1,
    "وجلودهم": 1,
    "وجلون": 1,
    "وجمع": 1,
    "وجنات": 1,
    "وجنة": 1,
    "وجنت": 1,
    "وجنوبهم": 1,
    "وجنود": 1,
    "وجنودا": 1,
    "وجنوده": 1,
    "وجنودهما": 1,
    "وجنى": 1,
    "وجهاد": 1,
    "وجهت": 1,
    "وجهرا": 1,
    "وجهركم": 1,
    "وجهك": 1,
    "وجهه": 1,
    "وجهها": 1,
    "وجهي": 1,
    "وجوه": 1,
    "وجوها": 1,
    "وجوهكم": 1,
    "وجوههم": 1,
    "وجيء": 1,
    "وجيها": 1,
    "وحاجه": 1,
    "وحاق": 1,
    "وحال": 1,
    "وحب": 1,
    "وحبط": 1,
    "وحبل": 1,
    "وحجرا": 1,
    "وحدائق": 1,
    "وحده": 1,
    "وحرام": 1,
    "وحرث": 1,
    "وحرض": 1,
    "وحرم": 1,
    "وحرمنا": 1,
    "وحرموا": 1,
    "وحريرا": 1,
    "وحزنا": 1,
    "وحزني": 1,
    "وحسبوا": 1,
    "وحسن": 1,
    "وحسنت": 1,
    "وحشر": 1,
    "وحشرنا": 1,
    "وحشرناهم": 1,
    "وحصل": 1,
    "وحصورا": 1,
    "وحصيد": 1,
    "وحفدة": 1,
    "وحفظا": 1,
    "وحفظناها": 1,
    "وحففناهما": 1,
    "وحق": 1,
    "وحقت": 1,
    "وحكما": 1,
    "وحكمة": 1,
    "وحلائل": 1,
    "وحلالا": 1,
    "وحلوا": 1,
    "وحمر": 1,
    "وحملت": 1,
    "وحملناه": 1,
    "وحملناهم": 1,
    "وحمله": 1,
    "وحملها": 1,
    "وحميم": 1,
    "وحنانا": 1,
    "وحور": 1,
    "وحيا": 1,
    "وحيث": 1,
    "وحيل": 1,
    "وحين": 1,
    "وحيه": 1,
    "وخاب": 1,
    "وخاتم": 1,
    "وخاف": 1,
    "وخافون": 1,
    "وخالاتكم": 1,
    "وختم": 1,
    "وخذ": 1,
    "وخذوا": 1,
    "وخذوهم": 1,
    "وخر": 1,
    "وخرقوا": 1,
    "وخروا": 1,
    "وخسر": 1,
    "وخسف": 1,
    "وخشعت": 1,
    "وخشي": 1,
    "وخضتم": 1,
    "وخفية": 1,
    "وخلق": 1,
    "وخلقته": 1,
    "وخلقنا": 1,
    "وخلقناكم": 1,
    "وخلقهم": 1,
    "وخير": 1,
    "وخيفة": 1,
    "ودا": 1,
    "وداعيا": 1,
    "ودانية": 1,
    "وداوود": 1,
    "ودت": 1,
    "ودخل": 1,
    "ودرسوا": 1,
    "ودسر": 1,
    "ودع": 1,
    "ودعك": 1,
    "ودم": 1,
    "ودمرنا": 1,
    "ودوا": 1,
    "ودون": 1,
    "وديارهم": 1,
    "ودين": 1,
    "وذا": 1,
    "وذات": 1,
    "وذر": 1,
    "وذرني": 1,
    "وذروا": 1,
    "وذرياتنا": 1,
    "وذرياتهم": 1,
    "وذرية": 1,
    "وذريته": 1,
    "وذريتها": 1,
    "وذكر": 1,
    "وذكرا": 1,
    "وذكرهم": 1,
    "وذكروا": 1,
    "وذكرى": 1,
    "وذلة": 1,
    "وذلك": 1,
    "وذلكم": 1,
    "وذللت": 1,
    "وذللناها": 1,
    "وذو": 1,
    "وذوقوا": 1,
    "وذي": 1,
    "ورأوا": 1,
    "ورأى": 1,
    "ورأيت": 1,
    "ورأيتهم": 1,
    "ورئاء": 1,
    "ورئيا": 1,
    "وراءكم": 1,
    "وراءه": 1,
    "وراءهم": 1,
    "ورائكم": 1,
    "ورائهم": 1,
    "ورائي": 1,
    "ورابطوا": 1,
    "وراعنا": 1,
    "ورافعك": 1,
    "وراودته": 1,
    "ورب": 1,
    "وربائبكم": 1,
    "ورباع": 1,
    "وربت": 1,
    "وربطنا": 1,
    "وربك": 1,
    "وربكم": 1,
    "وربنا": 1,
    "وربي": 1,
    "ورتل": 1,
    "ورتلناه": 1,
    "ورثة": 1,
    "ورثوا": 1,
    "ورجلا": 1,
    "ورجلك": 1,
    "ورحمة": 1,
    "ورحمت": 1,
    "ورحمته": 1,
    "ورحمتي": 1,
    "وردا": 1,
    "وردوا": 1,
    "وردوها": 1,
    "ورزق": 1,
    "ورزقا": 1,
    "ورزقكم": 1,
    "ورزقناهم": 1,
    "ورزقني": 1,
    "ورسالاته": 1,
    "ورسلا": 1,
    "ورسلنا": 1,
    "ورسله": 1,
    "ورسلي": 1,
    "ورسول": 1,
    "ورسولا": 1,
    "ورسوله": 1,
    "ورضوا": 1,
    "ورضوان": 1,
    "ورضوانا": 1,
    "ورضي": 1,
    "ورضيت": 1,
    "ورعد": 1,
    "ورفاتا": 1,
    "ورفع": 1,
    "ورفعنا": 1,
    "ورفعناه": 1,
    "ورماحكم": 1,
    "ورمان": 1,
    "ورهبا": 1,
    "ورهبانا": 1,
    "ورهبانهم": 1,
    "ورهبانية": 1,
    "ورواحها": 1,
    "وروح": 1,
    "وريحان": 1,
    "وريشا": 1,
    "وزادكم": 1,
    "وزاده": 1,
    "وزادهم": 1,
    "وزخرفا": 1,
    "وزدناهم": 1,
    "وزر": 1,
    "وزرا": 1,
    "وزرابي": 1,
    "وزرع": 1,
    "وزرك": 1,
    "وزروع": 1,
    "وزفيرا": 1,
    "وزكاة": 1,
    "وزكريا": 1,
    "وزلزلوا": 1,
    "وزلفا": 1,
    "وزنا": 1,
    "وزنوا": 1,
    "وزنوهم": 1,
    "وزهق": 1,
    "وزوجك": 1,
    "وزوجناهم": 1,
    "وزوجه": 1,
    "وزورا": 1,
    "وزيادة": 1,
    "وزيتونا": 1,
    "وزيرا": 1,
    "وزين": 1,
    "وزينا": 1,
    "وزيناها": 1,
    "وزينة": 1,
    "وزينتها": 1,
    "وزينه": 1,
    "وساء": 1,
    "وساءت": 1,
    "وسار": 1,
    "وسارب": 1,
    "وسارعوا": 1,
    "وسبح": 1,
    "وسبحان": 1,
    "وسبحه": 1,
    "وسبحوا": 1,
    "وسبحوه": 1,
    "وسبع": 1,
    "وسبعة": 1,
    "وسبلا": 1,
    "وستردون": 1,
    "وسخر": 1,
    "وسخرنا": 1,
    "وسرابيل": 1,
    "وسراجا": 1,
    "وسرحوهن": 1,
    "وسررا": 1,
    "وسرورا": 1,
    "وسطا": 1,
    "وسعة": 1,
    "وسعت": 1,
    "وسعر": 1,
    "وسعها": 1,
    "وسعى": 1,
    "وسعيد": 1,
    "وسعيرا": 1,
    "وسفرا": 1,
    "وسق": 1,
    "وسقاهم": 1,
    "وسقوا": 1,
    "وسقياها": 1,
    "وسكنتم": 1,
    "وسلام": 1,
    "وسلاما": 1,
    "وسلطان": 1,
    "وسلك": 1,
    "وسلموا": 1,
    "وسليمان": 1,
    "وسمعنا": 1,
    "وسمعهم": 1,
    "وسنجزي": 1,
    "وسنزيد": 1,
    "وسنقول": 1,
    "وسواء": 1,
    "وسوف": 1,
    "وسيجزي": 1,
    "وسيجنبها": 1,
    "وسيحبط": 1,
    "وسيحلفون": 1,
    "وسيدا": 1,
    "وسيرت": 1,
    "وسيرى": 1,
    "وسيصلون": 1,
    "وسيعلم": 1,
    "وسيق": 1,
    "وشاركهم": 1,
    "وشاقوا": 1,
    "وشاهد": 1,
    "وشاورهم": 1,
    "وشجرة": 1,
    "وشددنا": 1,
    "وشراب": 1,
    "وشرابك": 1,
    "وشركاءكم": 1,
    "وشركاؤكم": 1,
    "وشروه": 1,
    "وشفاء": 1,
    "وشفتين": 1,
    "وشقاق": 1,
    "وشمال": 1,
    "وشهبا": 1,
    "وشهد": 1,
    "وشهدوا": 1,
    "وشهيد": 1,
    "وشهيق": 1,
    "وشيء": 1,
    "وشيبة": 1,
    "وصابروا": 1,
    "وصاحبته": 1,
    "وصاحبهما": 1,
    "وصاكم": 1,
    "وصالح": 1,
    "وصبروا": 1,
    "وصبغ": 1,
    "وصد": 1,
    "وصدف": 1,
    "وصدق": 1,
    "وصدقت": 1,
    "وصدها": 1,
    "وصدوا": 1,
    "وصدوكم": 1,
    "وصرفنا": 1,
    "وصفهم": 1,
    "وصلنا": 1,
    "وصلوات": 1,
    "وصما": 1,
    "وصموا": 1,
    "وصهرا": 1,
    "وصوركم": 1,
    "وصى": 1,
    "وصيلة": 1,
    "وصينا": 1,
    "وضائق": 1,
    "وضاق": 1,
    "وضاقت": 1,
    "وضحاها": 1,
    "وضرب": 1,
    "وضربت": 1,
    "وضربنا": 1,
    "وضعتها": 1,
    "وضعف": 1,
    "وضل": 1,
    "وضلوا": 1,
    "وضياء": 1,
    "وطئا": 1,
    "وطائفة": 1,
    "وطبع": 1,
    "وطرا": 1,
    "وطعام": 1,
    "وطعاما": 1,
    "وطعامكم": 1,
    "وطعامه": 1,
    "وطعنا": 1,
    "وطعنوا": 1,
    "وطفقا": 1,
    "وطلح": 1,
    "وطمعا": 1,
    "وطهر": 1,
    "وطهرك": 1,
    "وطور": 1,
    "وظالم": 1,
    "وظاهره": 1,
    "وظاهروا": 1,
    "وظل": 1,
    "وظلالهم": 1,
    "وظللنا": 1,
    "وظلما": 1,
    "وظلموا": 1,
    "وظلها": 1,
    "وظن": 1,
    "وظننتم": 1,
    "وظنوا": 1,
    "وظهر": 1,
    "وظهورهم": 1,
    "وعاد": 1,
    "وعادا": 1,
    "وعاشروهن": 1,
    "وعباد": 1,
    "وعبد": 1,
    "وعبقري": 1,
    "وعتوا": 1,
    "وعجبوا": 1,
    "وعجلت": 1,
    "وعدا": 1,
    "وعدتنا": 1,
    "وعدتهم": 1,
    "وعدده": 1,
    "وعدسها": 1,
    "وعدك": 1,
    "وعدكم": 1,
    "وعدلا": 1,
    "وعدنا": 1,
    "وعدناه": 1,
    "وعدناهم": 1,
    "وعده": 1,
    "وعدها": 1,
    "وعدهم": 1,
    "وعدو": 1,
    "وعدوا": 1,
    "وعدوكم": 1,
    "وعدوه": 1,
    "وعذاب": 1,
    "وعذابا": 1,
    "وعذب": 1,
    "وعذبناها": 1,
    "وعربي": 1,
    "وعرضنا": 1,
    "وعرضوا": 1,
    "وعزرتموهم": 1,
    "وعزروه": 1,
    "وعزني": 1,
    "وعسى": 1,
    "وعشرا": 1,
    "وعشيا": 1,
    "وعشيرتكم": 1,
    "وعصوا": 1,
    "وعصى": 1,
    "وعصيتم": 1,
    "وعصينا": 1,
    "وعصيهم": 1,
    "وعظاما": 1,
    "وعظهم": 1,
    "وعفا": 1,
    "وعقبى": 1,
    "وعلامات": 1,
    "وعلانية": 1,
    "وعلما": 1,
    "وعلمتم": 1,
    "وعلمتني": 1,
    "وعلمك": 1,
    "وعلمناه": 1,
    "وعلمه": 1,
    "وعلوا": 1,
    "وعليكم": 1,
    "وعلينا": 1,
    "وعليه": 1,
    "وعليها": 1,
    "وعليهم": 1,
    "وعماتكم": 1,
    "وعمارة": 1,
    "وعمروها": 1,
    "وعمل": 1,
    "وعمله": 1,
    "وعملوا": 1,
    "وعميانا": 1,
    "وعن": 1,
    "وعنب": 1,
    "وعنبا": 1,
    "وعنت": 1,
    "وعند": 1,
    "وعندنا": 1,
    "وعنده": 1,
    "وعندهم": 1,
    "وعهدنا": 1,
    "وعهدهم": 1,
    "وعيد": 1,
    "وعيسى": 1,
    "وعيون": 1,
    "وغدوا": 1,
    "وغرابيب": 1,
    "وغرتكم": 1,
    "وغرتهم": 1,
    "وغركم": 1,
    "وغرهم": 1,
    "وغساق": 1,
    "وغساقا": 1,
    "وغضب": 1,
    "وغفر": 1,
    "وغلقت": 1,
    "وغواص": 1,
    "وغيض": 1,
    "وفار": 1,
    "وفاقا": 1,
    "وفاكهة": 1,
    "وفتح": 1,
    "وفتحت": 1,
    "وفتناك": 1,
    "وفجرنا": 1,
    "وفدا": 1,
    "وفديناه": 1,
    "وفرادى": 1,
    "وفرحوا": 1,
    "وفرش": 1,
    "وفرشا": 1,
    "وفرضناها": 1,
    "وفرعها": 1,
    "وفرعون": 1,
    "وفريق": 1,
    "وفريقا": 1,
    "وفساد": 1,
    "وفصاله": 1,
    "وفصل": 1,
    "وفصيلته": 1,
    "وفضل": 1,
    "وفضلا": 1,
    "وفضلناهم": 1,
    "وفعلت": 1,
    "وفواكه": 1,
    "وفومها": 1,
    "وفى": 1,
    "وفيكم": 1,
    "وفيه": 1,
    "وفيها": 1,
    "وقائما": 1,
    "وقابل": 1,
    "وقاتل": 1,
    "وقاتلوا": 1,
    "وقاتلوهم": 1,
    "وقارا": 1,
    "وقارون": 1,
    "وقاسمهما": 1,
    "وقال": 1,
    "وقالا": 1,
    "وقالت": 1,
    "وقالوا": 1,
    "وقب": 1,
    "وقبائل": 1,
    "وقبل": 1,
    "وقبيله": 1,
    "وقتل": 1,
    "وقتلت": 1,
    "وقتلهم": 1,
    "وقتلوا": 1,
    "وقثائها": 1,
    "وقد": 1,
    "وقدت": 1,
    "وقدرنا": 1,
    "وقدره": 1,
    "وقدمنا": 1,
    "وقدموا": 1,
    "وقدور": 1,
    "وقذف": 1,
    "وقر": 1,
    "وقرآن": 1,
    "وقرآنا": 1,
    "وقرآنه": 1,
    "وقرا": 1,
    "وقربناه": 1,
    "وقرن": 1,
    "وقرونا": 1,
    "وقري": 1,
    "وقص": 1,
    "وقصر": 1,
    "وقضبا": 1,
    "وقضى": 1,
    "وقضي": 1,
    "وقضينا": 1,
    "وقطعن": 1,
    "وقطعنا": 1,
    "وقطعناهم": 1,
    "وقعد": 1,
    "وقعدوا": 1,
    "وقعودا": 1,
    "وقفوا": 1,
    "وقفوهم": 1,
    "وقفينا": 1,
    "وقل": 1,
    "وقلبه": 1,
    "وقلبوا": 1,
    "وقلن": 1,
    "وقلنا": 1,
    "وقلوبهم": 1,
    "وقلوبهن": 1,
    "وقليل": 1,
    "وقمرا": 1,
    "وقنا": 1,
    "وقهم": 1,
    "وقودها": 1,
    "وقول": 1,
    "وقولهم": 1,
    "وقولوا": 1,
    "وقوم": 1,
    "وقومك": 1,
    "وقومه": 1,
    "وقومها": 1,
    "وقومهم": 1,
    "وقومهما": 1,
    "وقوموا": 1,
    "وقياما": 1,
    "وقيضنا": 1,
    "وقيل": 1,
    "وقيله": 1,
    "وكأس": 1,
    "وكأسا": 1,
    "وكأين": 1,
    "وكادوا": 1,
    "وكان": 1,
    "وكانت": 1,
    "وكانوا": 1,
    "وكبراءنا": 1,
    "وكبره": 1,
    "وكبير": 1,
    "وكتاب": 1,
    "وكتبنا": 1,
    "وكتبه": 1,
    "وكثير": 1,
    "وكذب": 1,
    "وكذبتم": 1,
    "وكذبوا": 1,
    "وكذلك": 1,
    "وكره": 1,
    "وكرها": 1,
    "وكرهوا": 1,
    "وكسوتهن": 1,
    "وكشفت": 1,
    "وكشفنا": 1,
    "وكف": 1,
    "وكفر": 1,
    "وكفرا": 1,
    "وكفرت": 1,
    "وكفرتم": 1,
    "وكفرنا": 1,
    "وكفرهم": 1,
    "وكفروا": 1,
    "وكفلها": 1,
    "وكفى": 1,
    "وكلا": 1,
    "وكلبهم": 1,
    "وكلم": 1,
    "وكلما": 1,
    "وكلماته": 1,
    "وكلمة": 1,
    "وكلمته": 1,
    "وكلمه": 1,
    "وكلمهم": 1,
    "وكلنا": 1,
    "وكلهم": 1,
    "وكلوا": 1,
    "وكم": 1,
    "وكن": 1,
    "وكنا": 1,
    "وكنت": 1,
    "وكنتم": 1,
    "وكنوز": 1,
    "وكهلا": 1,
    "وكواعب": 1,
    "وكونوا": 1,
    "وكيف": 1,
    "وكيلا": 1,
    "ولآمرنهم": 1,
    "ولأبويه": 1,
    "ولأبين": 1,
    "ولأتم": 1,
    "ولأجر": 1,
    "ولأحل": 1,
    "ولأخي": 1,
    "ولأدخلناهم": 1,
    "ولأدخلنكم": 1,
    "ولأدخلنهم": 1,
    "ولأصلبنكم": 1,
    "ولأضلنهم": 1,
    "ولأغوينهم": 1,
    "ولأمة": 1,
    "ولأمنينهم": 1,
    "ولأنعامكم": 1,
    "ولأوضعوا": 1,
    "ولؤلؤا": 1,
    "ولإخواننا": 1,
    "ولئن": 1,
    "ولات": 1,
    "ولاهم": 1,
    "ولايتهم": 1,
    "ولبئس": 1,
    "ولباس": 1,
    "ولباسهم": 1,
    "ولبثت": 1,
    "ولبثوا": 1,
    "ولبيوتهم": 1,
    "ولتأت": 1,
    "ولتبتغوا": 1,
    "ولتبلغوا": 1,
    "ولتتقوا": 1,
    "ولتجدن": 1,
    "ولتجدنهم": 1,
    "ولتجري": 1,
    "ولتجزى": 1,
    "ولتسألن": 1,
    "ولتستبين": 1,
    "ولتسمعن": 1,
    "ولتصغى": 1,
    "ولتصنع": 1,
    "ولتطمئن": 1,
    "ولتعرفنهم": 1,
    "ولتعلم": 1,
    "ولتعلمن": 1,
    "ولتعلموا": 1,
    "ولتعلن": 1,
    "ولتكبروا": 1,
    "ولتكملوا": 1,
    "ولتكن": 1,
    "ولتكون": 1,
    "ولتكونن": 1,
    "ولتنازعتم": 1,
    "ولتنذر": 1,
    "ولتنصرنه": 1,
    "ولتنظر": 1,
    "ولحم": 1,
    "ولدا": 1,
    "ولدار": 1,
    "ولدان": 1,
    "ولدنهم": 1,
    "ولده": 1,
    "ولدينا": 1,
    "ولذكر": 1,
    "ولذي": 1,
    "ولربك": 1,
    "ولرسوله": 1,
    "ولزوجك": 1,
    "ولسانا": 1,
    "ولستم": 1,
    "ولسليمان": 1,
    "ولسوف": 1,
    "ولعب": 1,
    "ولعبا": 1,
    "ولعبد": 1,
    "ولعذاب": 1,
    "ولعلا": 1,
    "ولعلكم": 1,
    "ولعلهم": 1,
    "ولعنه": 1,
    "ولعنهم": 1,
    "ولعنوا": 1,
    "ولقاء": 1,
    "ولقائه": 1,
    "ولقاهم": 1,
    "ولقد": 1,
    "ولقومك": 1,
    "ولك": 1,
    "ولكل": 1,
    "ولكم": 1,
    "ولكنا": 1,
    "ولكنكم": 1,
    "ولكنهم": 1,
    "ولكني": 1,
    "وللآخرة": 1,
    "وللأرض": 1,
    "وللبسنا": 1,
    "وللدار": 1,
    "وللذين": 1,
    "وللرجال": 1,
    "وللرسول": 1,
    "وللسيارة": 1,
    "وللكافرين": 1,
    "وللمؤمنين": 1,
    "وللمطلقات": 1,
    "وللنساء": 1,
    "ولله": 1,
    "ولم": 1,
    "ولما": 1,
    "ولملئت": 1,
    "ولمن": 1,
    "ولن": 1,
    "ولنا": 1,
    "ولنبلونكم": 1,
    "ولنبينه": 1,
    "ولنجزين": 1,
    "ولنجزينهم": 1,
    "ولنجعلك": 1,
    "ولنجعله": 1,
    "ولنحمل": 1,
    "ولنخرجنهم": 1,
    "ولنذيقنهم": 1,
    "ولنرسلن": 1,
    "ولنسألن": 1,
    "ولنسكننكم": 1,
    "ولنصبرن": 1,
    "ولنعلمه": 1,
    "ولنعم": 1,
    "ولنكونن": 1,
    "وله": 1,
    "ولها": 1,
    "ولهديناهم": 1,
    "ولهم": 1,
    "ولهن": 1,
    "ولهو": 1,
    "ولهوا": 1,
    "ولوا": 1,
    "ولوالدي": 1,
    "ولوالديك": 1,
    "ولوطا": 1,
    "ولولا": 1,
    "ولوموا": 1,
    "ولى": 1,
    "وليأتينهم": 1,
    "وليأخذوا": 1,
    "وليؤمنوا": 1,
    "وليا": 1,
    "وليال": 1,
    "وليبتلي": 1,
    "وليبدلنهم": 1,
    "وليبكوا": 1,
    "وليبلي": 1,
    "وليبينن": 1,
    "وليتبروا": 1,
    "وليتذكر": 1,
    "وليتق": 1,
    "وليتلطف": 1,
    "وليتم": 1,
    "وليتمتعوا": 1,
    "وليجة": 1,
    "وليجدوا": 1,
    "وليحكم": 1,
    "وليحلفن": 1,
    "وليحملن": 1,
    "وليخزي": 1,
    "وليخش": 1,
    "وليدا": 1,
    "وليدخلوا": 1,
    "وليدع": 1,
    "وليذكر": 1,
    "وليذيقكم": 1,
    "وليربط": 1,
    "وليرضوه": 1,
    "وليزيدن": 1,
    "وليس": 1,
    "وليسألن": 1,
    "وليسألوا": 1,
    "وليست": 1,
    "وليستعفف": 1,
    "وليشهد": 1,
    "وليصفحوا": 1,
    "وليضربن": 1,
    "وليطوفوا": 1,
    "وليعفوا": 1,
    "وليعلم": 1,
    "وليعلمن": 1,
    "وليعلموا": 1,
    "وليقترفوا": 1,
    "وليقول": 1,
    "وليقولوا": 1,
    "وليكتب": 1,
    "وليكم": 1,
    "وليكون": 1,
    "وليكونا": 1,
    "وليلبسوا": 1,
    "وليمحص": 1,
    "وليمسنكم": 1,
    "وليمكنن": 1,
    "وليملل": 1,
    "ولينا": 1,
    "ولينذروا": 1,
    "ولينصرن": 1,
    "وليه": 1,
    "وليهم": 1,
    "وليهما": 1,
    "وليوفوا": 1,
    "وليوفيهم": 1,
    "وليي": 1,
    "ومأجوج": 1,
    "ومأواكم": 1,
    "ومأواه": 1,
    "ومأواهم": 1,
    "وماتوا": 1,
    "وماذا": 1,
    "وماروت": 1,
    "ومبشرا": 1,
    "ومتاع": 1,
    "ومتاعا": 1,
    "ومتعناهم": 1,
    "ومتعوهن": 1,
    "ومثل": 1,
    "ومثلا": 1,
    "ومثله": 1,
    "ومثلهم": 1,
    "ومثواكم": 1,
    "ومحرم": 1,
    "ومحياي": 1,
    "ومرساها": 1,
    "ومرعاها": 1,
    "ومريم": 1,
    "ومزاجه": 1,
    "ومزقناهم": 1,
    "ومساجد": 1,
    "ومساكن": 1,
    "ومساكنكم": 1,
    "ومستودع": 1,
    "ومستودعها": 1,
    "ومشارب": 1,
    "ومشهود": 1,
    "ومصدقا": 1,
    "ومصيرا": 1,
    "ومضى": 1,
    "ومطهرك": 1,
    "ومعارج": 1,
    "ومعصيت": 1,
    "ومعين": 1,
    "ومغاربها": 1,
    "ومغانم": 1,
    "ومغفرة": 1,
    "ومقام": 1,
    "ومقاما": 1,
    "ومقتا": 1,
    "ومقصرين": 1,
    "ومكر": 1,
    "ومكرنا": 1,
    "ومكروا": 1,
    "وملأه": 1,
    "وملئه": 1,
    "وملئهم": 1,
    "وملائكته": 1,
    "وملك": 1,
    "وملكا": 1,
    "ومما": 1,
    "ومماتهم": 1,
    "ومماتي": 1,
    "وممن": 1,
    "ومنا": 1,
    "ومناة": 1,
    "ومنافع": 1,
    "ومنذرين": 1,
    "ومنك": 1,
    "ومنكم": 1,
    "ومنه": 1,
    "ومنها": 1,
    "ومنهاجا": 1,
    "ومنهم": 1,
    "ومهدت": 1,
    "ومهلهم": 1,
    "ومهيمنا": 1,
    "ومواليكم": 1,
    "وموسى": 1,
    "وموعظة": 1,
    "وميثاقه": 1,
    "وميكال": 1,
    "ونأى": 1,
    "وناداهما": 1,
    "ونادوا": 1,
    "ونادى": 1,
    "وناديناه": 1,
    "ونبئهم": 1,
    "ونباتا": 1,
    "ونبلو": 1,
    "ونبلوكم": 1,
    "ونبيا": 1,
    "ونتبع": 1,
    "ونتجاوز": 1,
    "ونجعل": 1,
    "ونجعلهم": 1,
    "ونجنا": 1,
    "ونجني": 1,
    "ونجواهم": 1,
    "ونجينا": 1,
    "ونجيناه": 1,
    "ونجيناهم": 1,
    "ونجيناهما": 1,
    "ونحاس": 1,
    "ونحشر": 1,
    "ونحشره": 1,
    "ونحشرهم": 1,
    "ونحفظ": 1,
    "ونحن": 1,
    "ونحيا": 1,
    "ونخرج": 1,
    "ونخزى": 1,
    "ونخل": 1,
    "ونخلا": 1,
    "ونخوفهم": 1,
    "ونخيل": 1,
    "ونداء": 1,
    "وندخلكم": 1,
    "وندخلهم": 1,
    "ونذر": 1,
    "ونذرهم": 1,
    "ونذكرك": 1,
    "ونذير": 1,
    "ونذيرا": 1,
    "ونذيقه": 1,
    "ونراه": 1,
    "ونرثه": 1,
    "ونرد": 1,
    "ونري": 1,
    "ونريد": 1,
    "ونزداد": 1,
    "ونزع": 1,
    "ونزعنا": 1,
    "ونزل": 1,
    "ونزلنا": 1,
    "ونزلناه": 1,
    "ونساء": 1,
    "ونساءكم": 1,
    "ونساءنا": 1,
    "ونستحيي": 1,
    "ونسرا": 1,
    "ونسقيه": 1,
    "ونسكي": 1,
    "ونسوا": 1,
    "ونسوق": 1,
    "ونسوه": 1,
    "ونسي": 1,
    "ونصحت": 1,
    "ونصرناه": 1,
    "ونصرناهم": 1,
    "ونصروا": 1,
    "ونصروه": 1,
    "ونصفه": 1,
    "ونصله": 1,
    "ونصيرا": 1,
    "ونضع": 1,
    "ونطبع": 1,
    "ونطمع": 1,
    "ونعلم": 1,
    "ونعم": 1,
    "ونعمة": 1,
    "ونعمه": 1,
    "ونعيم": 1,
    "ونفاقا": 1,
    "ونفخ": 1,
    "ونفخت": 1,
    "ونفس": 1,
    "ونفصل": 1,
    "ونفضل": 1,
    "ونفور": 1,
    "ونقدس": 1,
    "ونقر": 1,
    "ونقص": 1,
    "ونقلب": 1,
    "ونقلبهم": 1,
    "ونقول": 1,
    "ونكتب": 1,
    "ونكفر": 1,
    "ونكون": 1,
    "ونلعب": 1,
    "ونمارق": 1,
    "ونمد": 1,
    "ونمكن": 1,
    "ونمنعكم": 1,
    "ونميت": 1,
    "ونمير": 1,
    "وننزل": 1,
    "وننشئكم": 1,
    "ونهارا": 1,
    "ونهر": 1,
    "ونهوا": 1,
    "ونهى": 1,
    "ونوحا": 1,
    "ونودوا": 1,
    "ونور": 1,
    "ونورهم": 1,
    "ونيسرك": 1,
    "وهؤلاء": 1,
    "وهاجا": 1,
    "وهاجروا": 1,
    "وهارون": 1,
    "وهامان": 1,
    "وهب": 1,
    "وهبت": 1,
    "وهبنا": 1,
    "وهداه": 1,
    "وهدوا": 1,
    "وهدى": 1,
    "وهديناه": 1,
    "وهديناهم": 1,
    "وهديناهما": 1,
    "وهذا": 1,
    "وهذه": 1,
    "وهزي": 1,
    "وهل": 1,
    "وهم": 1,
    "وهما": 1,
    "وهمت": 1,
    "وهموا": 1,
    "وهن": 1,
    "وهنا": 1,
    "وهنوا": 1,
    "وهي": 1,
    "وهيئ": 1,
    "وواعدنا": 1,
    "وواعدناكم": 1,
    "ووالد": 1,
    "ووجد": 1,
    "ووجدك": 1,
    "ووجدوا": 1,
    "ووجوه": 1,
    "ووحينا": 1,
    "وودوا": 1,
    "وورث": 1,
    "وورثه": 1,
    "ووري": 1,
    "ووصى": 1,
    "ووصينا": 1,
    "ووضع": 1,
    "ووضعته": 1,
    "ووضعنا": 1,
    "ووعدتكم": 1,
    "ووفيت": 1,
    "ووقانا": 1,
    "ووقاهم": 1,
    "ووقع": 1,
    "وولدا": 1,
    "وولده": 1,
    "ووهبنا": 1,
    "وويل": 1,
    "ويأبى": 1,
    "ويأت": 1,
    "ويأتوكم": 1,
    "ويأتينا": 1,
    "ويأتيه": 1,
    "ويأخذ": 1,
    "ويأكلون": 1,
    "ويأمركم": 1,
    "ويأمرون": 1,
    "ويأمنوا": 1,
    "ويؤت": 1,
    "ويؤتوا": 1,
    "ويؤتون": 1,
    "ويؤثرون": 1,
    "ويؤخركم": 1,
    "ويؤمن": 1,
    "ويؤمنون": 1,
    "ويا": 1,
    "ويبسط": 1,
    "ويبسطوا": 1,
    "ويبشر": 1,
    "ويبصرون": 1,
    "ويبطل": 1,
    "ويبغون": 1,
    "ويبغونها": 1,
    "ويبقى": 1,
    "ويبين": 1,
    "ويتبع": 1,
    "ويتجنبها": 1,
    "ويتخذ": 1,
    "ويتخذها": 1,
    "ويتخطف": 1,
    "ويتربص": 1,
    "ويتعد": 1,
    "ويتعلمون": 1,
    "ويتفكرون": 1,
    "ويتقه": 1,
    "ويتلوه": 1,
    "ويتم": 1,
    "ويتمتعوا": 1,
    "ويتناجون": 1,
    "ويتوب": 1,
    "ويتولوا": 1,
    "ويتيما": 1,
    "ويثبت": 1,
    "ويجادل": 1,
    "ويجركم": 1,
    "ويجزي": 1,
    "ويجزيهم": 1,
    "ويجعل": 1,
    "ويجعلكم": 1,
    "ويجعلون": 1,
    "ويحب": 1,
    "ويحبون": 1,
    "ويحبونه": 1,
    "ويحذركم": 1,
    "ويحرم": 1,
    "ويحرمونه": 1,
    "ويحسبون": 1,
    "ويحفظن": 1,
    "ويحفظوا": 1,
    "ويحق": 1,
    "ويحل": 1,
    "ويحلفون": 1,
    "ويحمل": 1,
    "ويحيى": 1,
    "ويحيي": 1,
    "ويخافون": 1,
    "ويختار": 1,
    "ويخرج": 1,
    "ويخرجكم": 1,
    "ويخرجهم": 1,
    "ويخرون": 1,
    "ويخزهم": 1,
    "ويخش": 1,
    "ويخشون": 1,
    "ويخشونه": 1,
    "ويخلد": 1,
    "ويخلق": 1,
    "ويخوفونك": 1,
    "ويدخلكم": 1,
    "ويدخله": 1,
    "ويدخلهم": 1,
    "ويدرءون": 1,
    "ويدرأ": 1,
    "ويدع": 1,
    "ويدعون": 1,
    "ويدعوننا": 1,
    "ويذبحون": 1,
    "ويذرك": 1,
    "ويذرهم": 1,
    "ويذرون": 1,
    "ويذكر": 1,
    "ويذكروا": 1,
    "ويذهب": 1,
    "ويذهبا": 1,
    "ويذيق": 1,
    "ويربي": 1,
    "ويرث": 1,
    "ويرجو": 1,
    "ويرجون": 1,
    "ويرحم": 1,
    "ويرزقه": 1,
    "ويرسل": 1,
    "ويرضى": 1,
    "ويرضين": 1,
    "ويرى": 1,
    "ويريد": 1,
    "ويريدون": 1,
    "ويريكم": 1,
    "ويزداد": 1,
    "ويزدكم": 1,
    "ويزكيكم": 1,
    "ويزكيهم": 1,
    "ويزيد": 1,
    "ويزيدهم": 1,
    "ويسألون": 1,
    "ويسألونك": 1,
    "ويسارعون": 1,
    "ويسبح": 1,
    "ويسبحونه": 1,
    "ويستأذن": 1,
    "ويستبدل": 1,
    "ويستبشرون": 1,
    "ويستجيب": 1,
    "ويستحيون": 1,
    "ويستحيي": 1,
    "ويستخرجا": 1,
    "ويستخلف": 1,
    "ويستخلفكم": 1,
    "ويستعجلونك": 1,
    "ويستغفروا": 1,
    "ويستغفرون": 1,
    "ويستغفرونه": 1,
    "ويستفتونك": 1,
    "ويستكبر": 1,
    "ويستنبئونك": 1,
    "ويستهزأ": 1,
    "ويسخرون": 1,
    "ويسر": 1,
    "ويسعون": 1,
    "ويسفك": 1,
    "ويسقون": 1,
    "ويسقى": 1,
    "ويسقين": 1,
    "ويسلموا": 1,
    "ويشترون": 1,
    "ويشرب": 1,
    "ويشف": 1,
    "ويشهد": 1,
    "ويصبر": 1,
    "ويصدكم": 1,
    "ويصدون": 1,
    "ويصرفه": 1,
    "ويصلح": 1,
    "ويصلى": 1,
    "ويصنع": 1,
    "ويضرب": 1,
    "ويضع": 1,
    "ويضل": 1,
    "ويضيق": 1,
    "ويطاف": 1,
    "ويطعمون": 1,
    "ويطهركم": 1,
    "ويطوف": 1,
    "ويطيعون": 1,
    "ويعبدون": 1,
    "ويعذب": 1,
    "ويعظم": 1,
    "ويعف": 1,
    "ويعفو": 1,
    "ويعقوب": 1,
    "ويعلم": 1,
    "ويعلمك": 1,
    "ويعلمكم": 1,
    "ويعلمه": 1,
    "ويعلمهم": 1,
    "ويعلمون": 1,
    "ويعمل": 1,
    "ويعملون": 1,
    "ويعوق": 1,
    "ويعيد": 1,
    "ويغفر": 1,
    "ويفسدون": 1,
    "ويفعل": 1,
    "ويفعلون": 1,
    "ويقبضن": 1,
    "ويقبضون": 1,
    "ويقتلون": 1,
    "ويقدر": 1,
    "ويقذفون": 1,
    "ويقطع": 1,
    "ويقطعون": 1,
    "ويقللكم": 1,
    "ويقول": 1,
    "ويقولوا": 1,
    "ويقولون": 1,
    "ويقيموا": 1,
    "ويقيمون": 1,
    "ويكأن": 1,
    "ويكأنه": 1,
    "ويكتمون": 1,
    "ويكشف": 1,
    "ويكفر": 1,
    "ويكفرون": 1,
    "ويكفوا": 1,
    "ويكلم": 1,
    "ويكور": 1,
    "ويكون": 1,
    "ويكونون": 1,
    "ويل": 1,
    "ويلبسون": 1,
    "ويلتا": 1,
    "ويلتنا": 1,
    "ويلتى": 1,
    "ويلعب": 1,
    "ويلعبوا": 1,
    "ويلعن": 1,
    "ويلعنهم": 1,
    "ويلقوا": 1,
    "ويلقون": 1,
    "ويلك": 1,
    "ويلكم": 1,
    "ويلنا": 1,
    "ويلههم": 1,
    "ويمح": 1,
    "ويمحق": 1,
    "ويمددكم": 1,
    "ويمدهم": 1,
    "ويمسك": 1,
    "ويمشون": 1,
    "ويمشي": 1,
    "ويمكر": 1,
    "ويمكرون": 1,
    "ويمنعون": 1,
    "ويمنيهم": 1,
    "ويميت": 1,
    "وينأون": 1,
    "وينجي": 1,
    "وينذر": 1,
    "وينذرونكم": 1,
    "وينزل": 1,
    "وينشئ": 1,
    "وينشر": 1,
    "وينصرك": 1,
    "وينصركم": 1,
    "وينصرون": 1,
    "وينعه": 1,
    "وينفقوا": 1,
    "وينقلب": 1,
    "وينهاهم": 1,
    "وينهون": 1,
    "وينهى": 1,
    "ويهب": 1,
    "ويهدي": 1,
    "ويهديك": 1,
    "ويهديكم": 1,
    "ويهديه": 1,
    "ويهديهم": 1,
    "ويهلك": 1,
    "ويهيئ": 1,
    "ويوسف": 1,
    "ويولج": 1,
    "ويولون": 1,
    "ويوم": 1,
    "ويومئذ": 1,
    "ويونس": 1,
    "يأب": 1,
    "يأت": 1,
    "يأتك": 1,
    "يأتكم": 1,
    "يأتل": 1,
    "يأتمرون": 1,
    "يأته": 1,
    "يأتهم": 1,
    "يأتوا": 1,
    "يأتوك": 1,
    "يأتوكم": 1,
    "يأتون": 1,
    "يأتونك": 1,
    "يأتوننا": 1,
    "يأتوني": 1,
    "يأتيانها": 1,
    "يأتيك": 1,
    "يأتيكم": 1,
    "يأتيكما": 1,
    "يأتين": 1,
    "يأتينا": 1,
    "يأتينك": 1,
    "يأتينكم": 1,
    "يأتيني": 1,
    "يأتيه": 1,
    "يأتيها": 1,
    "يأتيهم": 1,
    "يأجوج": 1,
    "يأخذه": 1,
    "يأخذهم": 1,
    "يأخذوا": 1,
    "يأخذون": 1,
    "يأخذونها": 1,
    "يأخذوه": 1,
    "يأذن": 1,
    "يأفكون": 1,
    "يأكلان": 1,
    "يأكلن": 1,
    "يأكله": 1,
    "يأكلهن": 1,
    "يأكلوا": 1,
    "يأكلون": 1,
    "يألمون": 1,
    "يألونكم": 1,
    "يأمركم": 1,
    "يأمرهم": 1,
    "يأمرون": 1,
    "يأمنوكم": 1,
    "يأن": 1,
    "يؤاخذ": 1,
    "يؤاخذكم": 1,
    "يؤاخذهم": 1,
    "يؤت": 1,
    "يؤتكم": 1,
    "يؤتوا": 1,
    "يؤتون": 1,
    "يؤتى": 1,
    "يؤتي": 1,
    "يؤتين": 1,
    "يؤتيه": 1,
    "يؤتيهم": 1,
    "يؤخذ": 1,
    "يؤخرهم": 1,
    "يؤده": 1,
    "يؤذن": 1,
    "يؤذون": 1,
    "يؤذي": 1,
    "يؤذين": 1,
    "يؤفك": 1,
    "يؤفكون": 1,
    "يؤلون": 1,
    "يؤمرون": 1,
    "يؤمن": 1,
    "يؤمنوا": 1,
    "يؤمنون": 1,
    "يئسن": 1,
    "يئسوا": 1,
    "يئوده": 1,
    "يئوسا": 1,
    "يابسات": 1,
    "ياسين": 1,
    "يبايعنك": 1,
    "يبايعون": 1,
    "يبايعونك": 1,
    "يبتغ": 1,
    "يبتغون": 1,
    "يبث": 1,
    "يبخسون": 1,
    "يبخل": 1,
    "يبخلون": 1,
    "يبدئ": 1,
    "يبدلنا": 1,
    "يبدله": 1,
    "يبدلهما": 1,
    "يبدلوا": 1,
    "يبدلونه": 1,
    "يبدها": 1,
    "يبدون": 1,
    "يبدين": 1,
    "يبسا": 1,
    "يبسطوا": 1,
    "يبشر": 1,
    "يبشرك": 1,
    "يبشرهم": 1,
    "يبصر": 1,
    "يبصروا": 1,
    "يبصرون": 1,
    "يبصرونهم": 1,
    "يبطش": 1,
    "يبطشون": 1,
    "يبعثك": 1,
    "يبعثكم": 1,
    "يبعثهم": 1,
    "يبعثوا": 1,
    "يبعثون": 1,
    "يبغون": 1,
    "يبغونكم": 1,
    "يبغيان": 1,
    "يبكون": 1,
    "يبلس": 1,
    "يبلغا": 1,
    "يبلغن": 1,
    "يبلغوا": 1,
    "يبلغون": 1,
    "يبلوكم": 1,
    "يبلى": 1,
    "يبور": 1,
    "يبيتون": 1,
    "يبينها": 1,
    "يتأخر": 1,
    "يتامى": 1,
    "يتب": 1,
    "يتبدل": 1,
    "يتبع": 1,
    "يتبعها": 1,
    "يتبعهم": 1,
    "يتبعوكم": 1,
    "يتبعون": 1,
    "يتبوأ": 1,
    "يتبين": 1,
    "يتجرعه": 1,
    "يتحاجون": 1,
    "يتحاكموا": 1,
    "يتخافتون": 1,
    "يتخبطه": 1,
    "يتخذ": 1,
    "يتخذوا": 1,
    "يتخذون": 1,
    "يتخذونك": 1,
    "يتخذوه": 1,
    "يتخطفكم": 1,
    "يتخلفوا": 1,
    "يتخيرون": 1,
    "يتدبرون": 1,
    "يتذكرون": 1,
    "يتراجعا": 1,
    "يتربصن": 1,
    "يتربصون": 1,
    "يترددون": 1,
    "يترقب": 1,
    "يتركم": 1,
    "يتركوا": 1,
    "يتزكى": 1,
    "يتساءلون": 1,
    "يتسللون": 1,
    "يتسنه": 1,
    "يتضرعون": 1,
    "يتطهروا": 1,
    "يتطهرون": 1,
    "يتعارفون": 1,
    "يتعد": 1,
    "يتغامزون": 1,
    "يتفجر": 1,
    "يتفرقا": 1,
    "يتفرقون": 1,
    "يتفضل": 1,
    "يتفطرن": 1,
    "يتفكروا": 1,
    "يتفكرون": 1,
    "يتفيأ": 1,
    "يتق": 1,
    "يتقبل": 1,
    "يتقدم": 1,
    "يتقون": 1,
    "يتقي": 1,
    "يتكئون": 1,
    "يتكبرون": 1,
    "يتكلمون": 1,
    "يتلاومون": 1,
    "يتلقى": 1,
    "يتلو": 1,
    "يتلون": 1,
    "يتلونه": 1,
    "يتلى": 1,
    "يتماسا": 1,
    "يتمتعون": 1,
    "يتمطى": 1,
    "يتمنونه": 1,
    "يتمنوه": 1,
    "يتنازعون": 1,
    "يتناهون": 1,
    "يتنزل": 1,
    "يتوب": 1,
    "يتوبوا": 1,
    "يتوبون": 1,
    "يتوفاكم": 1,
    "يتوفاهن": 1,
    "يتوفون": 1,
    "يتوفونهم": 1,
    "يتوفى": 1,
    "يتوكل": 1,
    "يتوكلون": 1,
    "يتول": 1,
    "يتولهم": 1,
    "يتولوا": 1,
    "يتولون": 1,
    "يتولونه": 1,
    "يتيما": 1,
    "يتيمين": 1,
    "يتيهون": 1,
    "يثرب": 1,
    "يثقفوكم": 1,
    "يثنون": 1,
    "يجأرون": 1,
    "يجادلنا": 1,
    "يجادلون": 1,
    "يجادلونك": 1,
    "يجار": 1,
    "يجاهد": 1,
    "يجاهدوا": 1,
    "يجاهدون": 1,
    "يجاورونك": 1,
    "يجبى": 1,
    "يجتبي": 1,
    "يجتبيك": 1,
    "يجتنبون": 1,
    "يجحد": 1,
    "يجحدون": 1,
    "يجدك": 1,
    "يجده": 1,
    "يجدوا": 1,
    "يجدون": 1,
    "يجدونه": 1,
    "يجرمنكم": 1,
    "يجره": 1,
    "يجري": 1,
    "يجز": 1,
    "يجزاه": 1,
    "يجزون": 1,
    "يجزى": 1,
    "يجزي": 1,
    "يجعلني": 1,
    "يجعلون": 1,
    "يجعلوه": 1,
    "يجليها": 1,
    "يجمحون": 1,
    "يجمعكم": 1,
    "يجمعون": 1,
    "يجهلون": 1,
    "يجيب": 1,
    "يجير": 1,
    "يجيرني": 1,
    "يحاجوكم": 1,
    "يحاجون": 1,
    "يحادد": 1,
    "يحادون": 1,
    "يحاربون": 1,
    "يحاسب": 1,
    "يحاسبكم": 1,
    "يحاط": 1,
    "يحافظون": 1,
    "يحاوره": 1,
    "يحببكم": 1,
    "يحبرون": 1,
    "يحبسه": 1,
    "يحبهم": 1,
    "يحبون": 1,
    "يحبونكم": 1,
    "يحبونهم": 1,
    "يحتسب": 1,
    "يحتسبوا": 1,
    "يحتسبون": 1,
    "يحذرون": 1,
    "يحرفون": 1,
    "يحرفونه": 1,
    "يحرمون": 1,
    "يحزنك": 1,
    "يحزنهم": 1,
    "يحزنون": 1,
    "يحسبن": 1,
    "يحسبه": 1,
    "يحسبهم": 1,
    "يحسبون": 1,
    "يحسدون": 1,
    "يحسنون": 1,
    "يحشرهم": 1,
    "يحشروا": 1,
    "يحشرون": 1,
    "يحضرون": 1,
    "يحطمنكم": 1,
    "يحفظونه": 1,
    "يحق": 1,
    "يحكمان": 1,
    "يحكموك": 1,
    "يحكمون": 1,
    "يحكمونك": 1,
    "يحلفون": 1,
    "يحلون": 1,
    "يحلونه": 1,
    "يحمدوا": 1,
    "يحملنها": 1,
    "يحملون": 1,
    "يحملوها": 1,
    "يحموم": 1,
    "يحمى": 1,
    "يحور": 1,
    "يحيطوا": 1,
    "يحيطون": 1,
    "يحيف": 1,
    "يحيق": 1,
    "يحيك": 1,
    "يحيى": 1,
    "يحييكم": 1,
    "يحيين": 1,
    "يحييها": 1,
    "يخادعون": 1,
    "يخاف": 1,
    "يخافا": 1,
    "يخافه": 1,
    "يخافوا": 1,
    "يخافون": 1,
    "يخالفون": 1,
    "يختانون": 1,
    "يختص": 1,
    "يختصمون": 1,
    "يختلفون": 1,
    "يختم": 1,
    "يخدعوك": 1,
    "يخدعون": 1,
    "يخذلكم": 1,
    "يخربون": 1,
    "يخرجاكم": 1,
    "يخرجكم": 1,
    "يخرجن": 1,
    "يخرجنكما": 1,
    "يخرجهم": 1,
    "يخرجوا": 1,
    "يخرجوك": 1,
    "يخرجوكم": 1,
    "يخرجون": 1,
    "يخرجونهم": 1,
    "يخرصون": 1,
    "يخروا": 1,
    "يخرون": 1,
    "يخزيه": 1,
    "يخزيهم": 1,
    "يخسر": 1,
    "يخسرون": 1,
    "يخسف": 1,
    "يخش": 1,
    "يخشاها": 1,
    "يخشون": 1,
    "يخشى": 1,
    "يخصفان": 1,
    "يخصمون": 1,
    "يخفون": 1,
    "يخفين": 1,
    "يخل": 1,
    "يخلف": 1,
    "يخلفه": 1,
    "يخلفون": 1,
    "يخلقكم": 1,
    "يخلقوا": 1,
    "يخلقون": 1,
    "يخوضوا": 1,
    "يخوضون": 1,
    "يخيل": 1,
    "يدا": 1,
    "يداك": 1,
    "يداه": 1,
    "يدبروا": 1,
    "يدخلنا": 1,
    "يدخلنها": 1,
    "يدخله": 1,
    "يدخلون": 1,
    "يدخلونها": 1,
    "يدخلوها": 1,
    "يدرسونها": 1,
    "يدرككم": 1,
    "يدركه": 1,
    "يدريك": 1,
    "يدسه": 1,
    "يدع": 1,
    "يدعنا": 1,
    "يدعوك": 1,
    "يدعوكم": 1,
    "يدعون": 1,
    "يدعونني": 1,
    "يدعونه": 1,
    "يدعوه": 1,
    "يدعوهم": 1,
    "يدك": 1,
    "يدنين": 1,
    "يده": 1,
    "يدي": 1,
    "يدينون": 1,
    "يديه": 1,
    "يديها": 1,
    "يذبحون": 1,
    "يذرؤكم": 1,
    "يذكرهم": 1,
    "يذكرون": 1,
    "يذهبكم": 1,
    "يذهبن": 1,
    "يذهبوا": 1,
    "يذوقوا": 1,
    "يذوقون": 1,
    "ير": 1,
    "يراءون": 1,
    "يراد": 1,
    "يراك": 1,
    "يراها": 1,
    "يربو": 1,
    "يرتابوا": 1,
    "يرتدد": 1,
    "يرتع": 1,
    "يرثني": 1,
    "يرثها": 1,
    "يرثون": 1,
    "يرجعون": 1,
    "يرجموكم": 1,
    "يرجو": 1,
    "يرجون": 1,
    "يرحمكم": 1,
    "يرحمنا": 1,
    "يردك": 1,
    "يردن": 1,
    "يردوكم": 1,
    "يردون": 1,
    "يردونكم": 1,
    "يرزقكم": 1,
    "يرزقها": 1,
    "يرزقون": 1,
    "يرشدون": 1,
    "يرضعن": 1,
    "يرضه": 1,
    "يرضونكم": 1,
    "يرضونه": 1,
    "يرضوه": 1,
    "يرغب": 1,
    "يرغبوا": 1,
    "يرفعه": 1,
    "يرقبوا": 1,
    "يرقبون": 1,
    "يركبون": 1,
    "يركضون": 1,
    "يركعون": 1,
    "يرم": 1,
    "يرمون": 1,
    "يره": 1,
    "يرهبون": 1,
    "يرهقهما": 1,
    "يروا": 1,
    "يرون": 1,
    "يرونه": 1,
    "يرونها": 1,
    "يرونهم": 1,
    "يريدا": 1,
    "يريدان": 1,
    "يريدوا": 1,
    "يريدون": 1,
    "يريكم": 1,
    "يريكموهم": 1,
    "يريكهم": 1,
    "يريهم": 1,
    "يزال": 1,
    "يزالون": 1,
    "يزجي": 1,
    "يزده": 1,
    "يزدهم": 1,
    "يزرون": 1,
    "يزعمون": 1,
    "يزغ": 1,
    "يزفون": 1,
    "يزكون": 1,
    "يزكى": 1,
    "يزكي": 1,
    "يزكيهم": 1,
    "يزنون": 1,
    "يزنين": 1,
    "يزوجهم": 1,
    "يزيدهم": 1,
    "يزيدون": 1,
    "يزيغ": 1,
    "يس": 1,
    "يسألك": 1,
    "يسألكم": 1,
    "يسألكموها": 1,
    "يسأله": 1,
    "يسألون": 1,
    "يسألونك": 1,
    "يسأم": 1,
    "يسأمون": 1,
    "يسارعون": 1,
    "يساقون": 1,
    "يسبتون": 1,
    "يسبحن": 1,
    "يسبحون": 1,
    "يسبقونا": 1,
    "يسبقونه": 1,
    "يستأخرون": 1,
    "يستأذنك": 1,
    "يستأذنونك": 1,
    "يستأذنوه": 1,
    "يستبشرون": 1,
    "يستثنون": 1,
    "يستجيب": 1,
    "يستجيبوا": 1,
    "يستجيبون": 1,
    "يستحبون": 1,
    "يستحسرون": 1,
    "يستحيي": 1,
    "يستخفنك": 1,
    "يستخفون": 1,
    "يستسخرون": 1,
    "يستصرخه": 1,
    "يستضعف": 1,
    "يستضعفون": 1,
    "يستطع": 1,
    "يستطيعون": 1,
    "يستعتبوا": 1,
    "يستعتبون": 1,
    "يستعجل": 1,
    "يستعجلون": 1,
    "يستعجلونك": 1,
    "يستعففن": 1,
    "يستغشون": 1,
    "يستغفر": 1,
    "يستغفروا": 1,
    "يستغفرون": 1,
    "يستغيثان": 1,
    "يستغيثوا": 1,
    "يستفتحون": 1,
    "يستفتونك": 1,
    "يستفزهم": 1,
    "يستقدمون": 1,
    "يستقيم": 1,
    "يستكبرون": 1,
    "يستمعون": 1,
    "يستنبطونه": 1,
    "يستنقذوه": 1,
    "يستنكحها": 1,
    "يستنكف": 1,
    "يستهزئ": 1,
    "يستهزئون": 1,
    "يستوفون": 1,
    "يستوون": 1,
    "يستوي": 1,
    "يستويان": 1,
    "يسجدان": 1,
    "يسجدوا": 1,
    "يسجدون": 1,
    "يسجرون": 1,
    "يسحبون": 1,
    "يسخطون": 1,
    "يسرا": 1,
    "يسرف": 1,
    "يسرفوا": 1,
    "يسرقن": 1,
    "يسرنا": 1,
    "يسرناه": 1,
    "يسره": 1,
    "يسرون": 1,
    "يسطرون": 1,
    "يسطون": 1,
    "يسعون": 1,
    "يسعى": 1,
    "يسقون": 1,
    "يسقى": 1,
    "يسلبهم": 1,
    "يسلكه": 1,
    "يسلمون": 1,
    "يسمعها": 1,
    "يسمعوا": 1,
    "يسمعون": 1,
    "يسمعونكم": 1,
    "يسومهم": 1,
    "يسومونكم": 1,
    "يسير": 1,
    "يسيرا": 1,
    "يسيركم": 1,
    "يسيروا": 1,
    "يسيغه": 1,
    "يشأ": 1,
    "يشإ": 1,
    "يشاء": 1,
    "يشاءون": 1,
    "يشاق": 1,
    "يشاقق": 1,
    "يشترون": 1,
    "يشتهون": 1,
    "يشربون": 1,
    "يشرك": 1,
    "يشركن": 1,
    "يشركون": 1,
    "يشرون": 1,
    "يشري": 1,
    "يشعركم": 1,
    "يشعرن": 1,
    "يشعرون": 1,
    "يشفع": 1,
    "يشفعون": 1,
    "يشفين": 1,
    "يشقق": 1,
    "يشقى": 1,
    "يشكر": 1,
    "يشكرون": 1,
    "يشهده": 1,
    "يشهدون": 1,
    "يصبروا": 1,
    "يصبكم": 1,
    "يصبها": 1,
    "يصحبون": 1,
    "يصدعون": 1,
    "يصدفون": 1,
    "يصدقني": 1,
    "يصدقوا": 1,
    "يصدكم": 1,
    "يصدنك": 1,
    "يصدنكم": 1,
    "يصدون": 1,
    "يصرفون": 1,
    "يصروا": 1,
    "يصرون": 1,
    "يصطرخون": 1,
    "يصطفي": 1,
    "يصعقون": 1,
    "يصفون": 1,
    "يصلاها": 1,
    "يصلبوا": 1,
    "يصلحا": 1,
    "يصلحون": 1,
    "يصلوا": 1,
    "يصلون": 1,
    "يصلونها": 1,
    "يصلى": 1,
    "يصنعون": 1,
    "يصهر": 1,
    "يصوركم": 1,
    "يصيبكم": 1,
    "يصيبنا": 1,
    "يصيبهم": 1,
    "يضار": 1,
    "يضاعفه": 1,
    "يضاعفها": 1,
    "يضاهئون": 1,
    "يضحكون": 1,
    "يضر": 1,
    "يضربن": 1,
    "يضربون": 1,
    "يضرعون": 1,
    "يضرك": 1,
    "يضركم": 1,
    "يضرنا": 1,
    "يضره": 1,
    "يضرهم": 1,
    "يضروا": 1,
    "يضروك": 1,
    "يضروكم": 1,
    "يضرون": 1,
    "يضرونك": 1,
    "يضعن": 1,
    "يضلله": 1,
    "يضله": 1,
    "يضلهم": 1,
    "يضلوا": 1,
    "يضلوك": 1,
    "يضلون": 1,
    "يضلونكم": 1,
    "يضلونهم": 1,
    "يضيفوهما": 1,
    "يضيق": 1,
    "يطئون": 1,
    "يطاع": 1,
    "يطاف": 1,
    "يطع": 1,
    "يطعم": 1,
    "يطعمني": 1,
    "يطعمه": 1,
    "يطعمها": 1,
    "يطعمون": 1,
    "يطغى": 1,
    "يطفئوا": 1,
    "يطلبه": 1,
    "يطمثهن": 1,
    "يطمع": 1,
    "يطمعون": 1,
    "يطهرن": 1,
    "يطوفون": 1,
    "يطيروا": 1,
    "يطيعكم": 1,
    "يطيقونه": 1,
    "يظاهروا": 1,
    "يظاهرون": 1,
    "يظلمون": 1,
    "يظنون": 1,
    "يظهروا": 1,
    "يظهرون": 1,
    "يظهروه": 1,
    "يعبأ": 1,
    "يعبدون": 1,
    "يعبدونني": 1,
    "يعبدوها": 1,
    "يعتدون": 1,
    "يعتذرون": 1,
    "يعتزلوكم": 1,
    "يعتصم": 1,
    "يعجب": 1,
    "يعجبك": 1,
    "يعجزون": 1,
    "يعدكم": 1,
    "يعدلون": 1,
    "يعدهم": 1,
    "يعدون": 1,
    "يعذبكم": 1,
    "يعذبنا": 1,
    "يعذبه": 1,
    "يعذبهم": 1,
    "يعرجون": 1,
    "يعرشون": 1,
    "يعرضوا": 1,
    "يعرضون": 1,
    "يعرفن": 1,
    "يعرفوا": 1,
    "يعرفون": 1,
    "يعرفونه": 1,
    "يعرفونها": 1,
    "يعرفونهم": 1,
    "يعزب": 1,
    "يعش": 1,
    "يعص": 1,
    "يعصرون": 1,
    "يعصمك": 1,
    "يعصمكم": 1,
    "يعصمني": 1,
    "يعصون": 1,
    "يعصينك": 1,
    "يعض": 1,
    "يعطوا": 1,
    "يعطيك": 1,
    "يعظكم": 1,
    "يعظه": 1,
    "يعفو": 1,
    "يعفون": 1,
    "يعقب": 1,
    "يعقلها": 1,
    "يعقلون": 1,
    "يعكفون": 1,
    "يعلمان": 1,
    "يعلمه": 1,
    "يعلمها": 1,
    "يعلمهم": 1,
    "يعلموا": 1,
    "يعلمون": 1,
    "يعلنون": 1,
    "يعمر": 1,
    "يعمروا": 1,
    "يعمهون": 1,
    "يعودوا": 1,
    "يعودون": 1,
    "يعوذون": 1,
    "يعي": 1,
    "يعيدكم": 1,
    "يعيدنا": 1,
    "يعيدوكم": 1,
    "يغاث": 1,
    "يغاثوا": 1,
    "يغتب": 1,
    "يغررك": 1,
    "يغرنك": 1,
    "يغرنكم": 1,
    "يغشاه": 1,
    "يغشاها": 1,
    "يغشاهم": 1,
    "يغشى": 1,
    "يغشي": 1,
    "يغشيكم": 1,
    "يغضضن": 1,
    "يغضوا": 1,
    "يغضون": 1,
    "يغفروا": 1,
    "يغفرون": 1,
    "يغل": 1,
    "يغلبوا": 1,
    "يغلبون": 1,
    "يغلل": 1,
    "يغن": 1,
    "يغنهم": 1,
    "يغنوا": 1,
    "يغنيا": 1,
    "يغنيكم": 1,
    "يغنيه": 1,
    "يغنيهم": 1,
    "يغوث": 1,
    "يغوصون": 1,
    "يغويكم": 1,
    "يغيروا": 1,
    "يفتدي": 1,
    "يفتر": 1,
    "يفترون": 1,
    "يفترى": 1,
    "يفتري": 1,
    "يفترينه": 1,
    "يفتنكم": 1,
    "يفتننكم": 1,
    "يفتنهم": 1,
    "يفتنوك": 1,
    "يفتنون": 1,
    "يفتيكم": 1,
    "يفجرونها": 1,
    "يفر": 1,
    "يفرحوا": 1,
    "يفرحون": 1,
    "يفرطون": 1,
    "يفرقوا": 1,
    "يفرقون": 1,
    "يفسح": 1,
    "يفسدون": 1,
    "يفسقون": 1,
    "يفعله": 1,
    "يفعلوا": 1,
    "يفعلون": 1,
    "يفقهوا": 1,
    "يفقهون": 1,
    "يفقهوه": 1,
    "يفلح": 1,
    "يفلحون": 1,
    "يقاتل": 1,
    "يقاتلوا": 1,
    "يقاتلوكم": 1,
    "يقاتلون": 1,
    "يقاتلونكم": 1,
    "يقبض": 1,
    "يقتتلان": 1,
    "يقترفون": 1,
    "يقتروا": 1,
    "يقتلن": 1,
    "يقتلوا": 1,
    "يقتلوك": 1,
    "يقتلون": 1,
    "يقتلونني": 1,
    "يقدرون": 1,
    "يقرءون": 1,
    "يقربوا": 1,
    "يقرض": 1,
    "يقسمون": 1,
    "يقصرون": 1,
    "يقصون": 1,
    "يقض": 1,
    "يقضون": 1,
    "يقضى": 1,
    "يقطعون": 1,
    "يقطين": 1,
    "يقل": 1,
    "يقلب": 1,
    "يقنت": 1,
    "يقنط": 1,
    "يقنطون": 1,
    "يقولا": 1,
    "يقولوا": 1,
    "يقولون": 1,
    "يقومان": 1,
    "يقيما": 1,
    "يقيموا": 1,
    "يقيمون": 1,
    "يقين": 1,
    "يقينا": 1,
    "يكاد": 1,
    "يكادون": 1,
    "يكبتهم": 1,
    "يكبروا": 1,
    "يكتبون": 1,
    "يكتم": 1,
    "يكتمن": 1,
    "يكتمها": 1,
    "يكتمون": 1,
    "يكد": 1,
    "يكذبك": 1,
    "يكذبوك": 1,
    "يكذبون": 1,
    "يكذبونك": 1,
    "يكرههن": 1,
    "يكرهون": 1,
    "يكسبه": 1,
    "يكسبون": 1,
    "يكفروا": 1,
    "يكفرون": 1,
    "يكفروه": 1,
    "يكفله": 1,
    "يكفلونه": 1,
    "يكفهم": 1,
    "يكفون": 1,
    "يكفيكم": 1,
    "يكلؤكم": 1,
    "يكلمنا": 1,
    "يكلمه": 1,
    "يكلمهم": 1,
    "يكنزون": 1,
    "يكور": 1,
    "يكونا": 1,
    "يكونوا": 1,
    "يكونون": 1,
    "يكيدون": 1,
    "يلاقوا": 1,
    "يلبثوا": 1,
    "يلبثون": 1,
    "يلبسكم": 1,
    "يلبسوا": 1,
    "يلبسون": 1,
    "يلتفت": 1,
    "يلتقطه": 1,
    "يلتقيان": 1,
    "يلتكم": 1,
    "يلج": 1,
    "يلحدون": 1,
    "يلحقوا": 1,
    "يلدوا": 1,
    "يلعبون": 1,
    "يلعنهم": 1,
    "يلق": 1,
    "يلقاه": 1,
    "يلقاها": 1,
    "يلقون": 1,
    "يلقونه": 1,
    "يلقي": 1,
    "يلمزك": 1,
    "يلمزون": 1,
    "يلهث": 1,
    "يلونكم": 1,
    "يلوون": 1,
    "يمارون": 1,
    "يمترون": 1,
    "يمتعكم": 1,
    "يمتعون": 1,
    "يمحق": 1,
    "يمددكم": 1,
    "يمدكم": 1,
    "يمده": 1,
    "يمدونهم": 1,
    "يمرون": 1,
    "يمسسك": 1,
    "يمسسكم": 1,
    "يمسسني": 1,
    "يمسسهم": 1,
    "يمسكهن": 1,
    "يمسكون": 1,
    "يمسنا": 1,
    "يمسه": 1,
    "يمسهم": 1,
    "يمكر": 1,
    "يمكرون": 1,
    "يمل": 1,
    "يملكون": 1,
    "يمن": 1,
    "يمنون": 1,
    "يمنى": 1,
    "يمهدون": 1,
    "يموتون": 1,
    "يميتكم": 1,
    "يميتني": 1,
    "يمينك": 1,
    "ينابيع": 1,
    "يناد": 1,
    "ينادون": 1,
    "ينادونك": 1,
    "ينادونهم": 1,
    "يناديهم": 1,
    "ينازعنك": 1,
    "يناله": 1,
    "ينالهم": 1,
    "ينالوا": 1,
    "ينالون": 1,
    "ينبأ": 1,
    "ينبئك": 1,
    "ينبئكم": 1,
    "ينبئهم": 1,
    "ينبوعا": 1,
    "ينتصرون": 1,
    "ينتظرون": 1,
    "ينته": 1,
    "ينتهوا": 1,
    "ينتهون": 1,
    "ينجيكم": 1,
    "ينجيه": 1,
    "ينحتون": 1,
    "ينذرون": 1,
    "ينزغ": 1,
    "ينزغنك": 1,
    "ينزفون": 1,
    "ينسفها": 1,
    "ينسلون": 1,
    "ينسى": 1,
    "ينسينك": 1,
    "ينشرون": 1,
    "ينصركم": 1,
    "ينصرنا": 1,
    "ينصرني": 1,
    "ينصره": 1,
    "ينصرون": 1,
    "ينصرونكم": 1,
    "ينصرونه": 1,
    "ينصرونهم": 1,
    "ينطقون": 1,
    "ينظروا": 1,
    "ينظرون": 1,
    "ينفضوا": 1,
    "ينفعك": 1,
    "ينفعكم": 1,
    "ينفعنا": 1,
    "ينفعه": 1,
    "ينفعهم": 1,
    "ينفعونكم": 1,
    "ينفقون": 1,
    "ينفقونها": 1,
    "ينفوا": 1,
    "ينقذون": 1,
    "ينقصوكم": 1,
    "ينقضون": 1,
    "ينقلبون": 1,
    "ينكثون": 1,
    "ينكحن": 1,
    "ينكحها": 1,
    "ينكرونها": 1,
    "ينهاكم": 1,
    "ينهاهم": 1,
    "ينهون": 1,
    "ينهى": 1,
    "ينيب": 1,
    "يهاجروا": 1,
    "يهبط": 1,
    "يهتدوا": 1,
    "يهتدون": 1,
    "يهتدي": 1,
    "يهجعون": 1,
    "يهد": 1,
    "يهدني": 1,
    "يهدون": 1,
    "يهدوننا": 1,
    "يهدى": 1,
    "يهدي": 1,
    "يهديكم": 1,
    "يهدين": 1,
    "يهديني": 1,
    "يهديه": 1,
    "يهديهم": 1,
    "يهرعون": 1,
    "يهلكنا": 1,
    "يهلكون": 1,
    "يهن": 1,
    "يهوديا": 1,
    "يهيمون": 1,
    "يوادون": 1,
    "يواري": 1,
    "يوبقهن": 1,
    "يوجهه": 1,
    "يوح": 1,
    "يوحى": 1,
    "يوحي": 1,
    "يودوا": 1,
    "يورثها": 1,
    "يوزعون": 1,
    "يوسوس": 1,
    "يوصى": 1,
    "يوصيكم": 1,
    "يوصين": 1,
    "يوعدون": 1,
    "يوعظ": 1,
    "يوعظون": 1,
    "يوعون": 1,
    "يوف": 1,
    "يوفضون": 1,
    "يوفق": 1,
    "يوفون": 1,
    "يوفى": 1,
    "يوفيهم": 1,
    "يوق": 1,
    "يوقدون": 1,
    "يوقنون": 1,
    "يولج": 1,
    "يولهم": 1,
    "يولوكم": 1,
    "يولون": 1,
    "يومئذ": 1,
    "يومكم": 1,
    "يومهم": 1,
    "يومين": 1,
    "ييأس": 1,
    "آض": 1,
    "أأجمع": 1,
    "أأجمعك": 1,
    "أأجمعكم": 1,
    "أأجمعكما": 1,
    "أأجمعكن": 1,
    "أأجمعنا": 1,
    "أأجمعه": 1,
    "أأجمعها": 1,
    "أأجمعهم": 1,
    "أأجمعهما": 1,
    "أأجمعهن": 1,
    "أأجمعي": 1,
    "أأمام": 1,
    "أأمامك": 1,
    "أأمامكم": 1,
    "أأمامكما": 1,
    "أأمامكن": 1,
    "أأمامنا": 1,
    "أأمامه": 1,
    "أأمامها": 1,
    "أأمامهم": 1,
    "أأمامهما": 1,
    "أأمامهن": 1,
    "أأمامي": 1,
    "أأنا": 1,
    "أأنتما": 1,
    "أأنتن": 1,
    "أأولئك": 1,
    "أأولئكم": 1,
    "أأولاء": 1,
    "أأولالك": 1,
    "أإلى": 1,
    "أإلي": 1,
    "أإليك": 1,
    "أإليكم": 1,
    "أإليكما": 1,
    "أإليكن": 1,
    "أإلينا": 1,
    "أإليه": 1,
    "أإليها": 1,
    "أإليهم": 1,
    "أإليهما": 1,
    "أإليهن": 1,
    "أإن": 1,
    "أإنكم": 1,
    "أإنكما": 1,
    "أإنكن": 1,
    "أإننا": 1,
    "أإنه": 1,
    "أإنها": 1,
    "أإنهم": 1,
    "أإنهما": 1,
    "أإنهن": 1,
    "أإني": 1,
    "أبأجمع": 1,
    "أبأجمعك": 1,
    "أبأجمعكم": 1,
    "أبأجمعكما": 1,
    "أبأجمعكن": 1,
    "أبأجمعنا": 1,
    "أبأجمعه": 1,
    "أبأجمعها": 1,
    "أبأجمعهم": 1,
    "أبأجمعهما": 1,
    "أبأجمعهن": 1,
    "أبأجمعي": 1,
    "أبأولئك": 1,
    "أبأولئكم": 1,
    "أبأولاء": 1,
    "أبأولالك": 1,
    "أبإن": 1,
    "أبإنك": 1,
    "أبإنكم": 1,
    "أبإنكما": 1,
    "أبإنكن": 1,
    "أبإننا": 1,
    "أبإنه": 1,
    "أبإنها": 1,
    "أبإنهم": 1,
    "أبإنهما": 1,
    "أبإنهن": 1,
    "أبإني": 1,
    "أببعض": 1,
    "أببعضك": 1,
    "أببعضكم": 1,
    "أببعضكما": 1,
    "أببعضكن": 1,
    "أببعضنا": 1,
    "أببعضه": 1,
    "أببعضها": 1,
    "أببعضهم": 1,
    "أببعضهما": 1,
    "أببعضهن": 1,
    "أببعضي": 1,
    "أبتلك": 1,
    "أبتلكم": 1,
    "أبتلكما": 1,
    "أبتينك": 1,
    "أبجميع": 1,
    "أبجميعك": 1,
    "أبجميعكم": 1,
    "أبجميعكما": 1,
    "أبجميعكن": 1,
    "أبجميعنا": 1,
    "أبجميعه": 1,
    "أبجميعها": 1,
    "أبجميعهم": 1,
    "أبجميعهما": 1,
    "أبجميعهن": 1,
    "أبجميعي": 1,
    "أبحسب": 1,
    "أبحسبك": 1,
    "أبحسبكم": 1,
    "أبحسبكما": 1,
    "أبحسبكن": 1,
    "أبحسبنا": 1,
    "أبحسبه": 1,
    "أبحسبها": 1,
    "أبحسبهم": 1,
    "أبحسبهما": 1,
    "أبحسبهن": 1,
    "أبحسبي": 1,
    "أبذاك": 1,
    "أبذانك": 1,
    "أبذلك": 1,
    "أبذلكم": 1,
    "أبذلكما": 1,
    "أبذلكن": 1,
    "أبذواتي": 1,
    "أبذي": 1,
    "أبذين": 1,
    "أبذينك": 1,
    "أبسوى": 1,
    "أبسوي": 1,
    "أبسويك": 1,
    "أبسويكم": 1,
    "أبسويكما": 1,
    "أبسويكن": 1,
    "أبسوينا": 1,
    "أبسويه": 1,
    "أبسويها": 1,
    "أبسويهم": 1,
    "أبسويهما": 1,
    "أبسويهن": 1,
    "أبشبه": 1,
    "أبشبهك": 1,
    "أبشبهكم": 1,
    "أبشبهكما": 1,
    "أبشبهكن": 1,
    "أبشبهنا": 1,
    "أبشبهه": 1,
    "أبشبهها": 1,
    "أبشبههم": 1,
    "أبشبههما": 1,
    "أبشبههن": 1,
    "أبشبهي": 1,
    "أبضع": 1,
    "أبضعك": 1,
    "أبضعكم": 1,
    "أبضعكما": 1,
    "أبضعكن": 1,
    "أبضعنا": 1,
    "أبضعه": 1,
    "أبضعها": 1,
    "أبضعهم": 1,
    "أبضعهما": 1,
    "أبضعهن": 1,
    "أبضعي": 1,
    "أبعامة": 1,
    "أبعامتك": 1,
    "أبعامتكم": 1,
    "أبعامتكما": 1,
    "أبعامتكن": 1,
    "أبعامتنا": 1,
    "أبعامته": 1,
    "أبعامتها": 1,
    "أبعامتهم": 1,
    "أبعامتهما": 1,
    "أبعامتهن": 1,
    "أبعامتي": 1,
    "أبعض": 1,
    "أبعضك": 1,
    "أبعضكم": 1,
    "أبعضكما": 1,
    "أبعضكن": 1,
    "أبعضنا": 1,
    "أبعضه": 1,
    "أبعضها": 1,
    "أبعضهم": 1,
    "أبعضهما": 1,
    "أبعضهن": 1,
    "أبعضي": 1,
    "أبعين": 1,
    "أبعينك": 1,
    "أبعينكم": 1,
    "أبعينكما": 1,
    "أبعينكن": 1,
    "أبعيننا": 1,
    "أبعينه": 1,
    "أبعينها": 1,
    "أبعينهم": 1,
    "أبعينهما": 1,
    "أبعينهن": 1,
    "أبعيني": 1,
    "أبغير": 1,
    "أبغيرك": 1,
    "أبغيركم": 1,
    "أبغيركما": 1,
    "أبغيركن": 1,
    "أبغيرنا": 1,
    "أبغيره": 1,
    "أبغيرها": 1,
    "أبغيرهم": 1,
    "أبغيرهما": 1,
    "أبغيرهن": 1,
    "أبغيري": 1,
    "أبك": 1,
    "أبكل": 1,
    "أبكلتا": 1,
    "أبكلك": 1,
    "أبكلكم": 1,
    "أبكلكما": 1,
    "أبكلكن": 1,
    "أبكلنا": 1,
    "أبكله": 1,
    "أبكلها": 1,
    "أبكلهم": 1,
    "أبكلهما": 1,
    "أبكلهن": 1,
    "أبكلي": 1,
    "أبكليكما": 1,
    "أبكليهما": 1,
    "أبكما": 1,
    "أبكن": 1,
    "أبما": 1,
    "أبمثل": 1,
    "أبمثلك": 1,
    "أبمثلكم": 1,
    "أبمثلكما": 1,
    "أبمثلكن": 1,
    "أبمثلنا": 1,
    "أبمثله": 1,
    "أبمثلها": 1,
    "أبمثلهم": 1,
    "أبمثلهما": 1,
    "أبمثلهن": 1,
    "أبمثلي": 1,
    "أبمن": 1,
    "أبنا": 1,
    "أبنحو": 1,
    "أبنحوك": 1,
    "أبنحوكم": 1,
    "أبنحوكما": 1,
    "أبنحوكن": 1,
    "أبنحونا": 1,
    "أبنحوه": 1,
    "أبنحوها": 1,
    "أبنحوهم": 1,
    "أبنحوهما": 1,
    "أبنحوهن": 1,
    "أبنحوي": 1,
    "أبنفس": 1,
    "أبنفسك": 1,
    "أبنفسكم": 1,
    "أبنفسكما": 1,
    "أبنفسكن": 1,
    "أبنفسنا": 1,
    "أبنفسه": 1,
    "أبنفسها": 1,
    "أبنفسهم": 1,
    "أبنفسهما": 1,
    "أبنفسهن": 1,
    "أبنفسي": 1,
    "أبه": 1,
    "أبهؤلاء": 1,
    "أبها": 1,
    "أبهاته": 1,
    "أبهاتي": 1,
    "أبهاتين": 1,
    "أبهذا": 1,
    "أبهذه": 1,
    "أبهم": 1,
    "أبهما": 1,
    "أبهن": 1,
    "أبهناك": 1,
    "أبهنالك": 1,
    "أبهو": 1,
    "أبهي": 1,
    "أتحت": 1,
    "أتحتك": 1,
    "أتحتكم": 1,
    "أتحتكما": 1,
    "أتحتكن": 1,
    "أتحتنا": 1,
    "أتحته": 1,
    "أتحتها": 1,
    "أتحتهم": 1,
    "أتحتهما": 1,
    "أتحتهن": 1,
    "أتحتي": 1,
    "أتلك": 1,
    "أتلكم": 1,
    "أتلكما": 1,
    "أتينك": 1,
    "أثمة": 1,
    "أجمع": 1,
    "أجمعك": 1,
    "أجمعكم": 1,
    "أجمعكما": 1,
    "أجمعكن": 1,
    "أجمعنا": 1,
    "أجمعه": 1,
    "أجمعها": 1,
    "أجمعهم": 1,
    "أجمعهما": 1,
    "أجمعهن": 1,
    "أجمعي": 1,
    "أجميع": 1,
    "أجميعك": 1,
    "أجميعكم": 1,
    "أجميعكما": 1,
    "أجميعكن": 1,
    "أجميعنا": 1,
    "أجميعه": 1,
    "أجميعها": 1,
    "أجميعهم": 1,
    "أجميعهما": 1,
    "أجميعهن": 1,
    "أجميعي": 1,
    "أحسبك": 1,
    "أحسبكم": 1,
    "أحسبكما": 1,
    "أحسبكن": 1,
    "أحسبنا": 1,
    "أحسبه": 1,
    "أحسبها": 1,
    "أحسبهم": 1,
    "أحسبهما": 1,
    "أحسبهن": 1,
    "أحسبي": 1,
    "أخك": 1,
    "أخكم": 1,
    "أخكما": 1,
    "أخكن": 1,
    "أخلف": 1,
    "أخلفك": 1,
    "أخلفكم": 1,
    "أخلفكما": 1,
    "أخلفكن": 1,
    "أخلفه": 1,
    "أخلفها": 1,
    "أخلفهم": 1,
    "أخلفهما": 1,
    "أخلفهن": 1,
    "أخلفي": 1,
    "أخنا": 1,
    "أخه": 1,
    "أخها": 1,
    "أخهم": 1,
    "أخهما": 1,
    "أخهن": 1,
    "أذاك": 1,
    "أذانك": 1,
    "أذلكم": 1,
    "أذلكما": 1,
    "أذلكن": 1,
    "أذواتا": 1,
    "أذواتي": 1,
    "أذي": 1,
    "أذين": 1,
    "أذينك": 1,
    "أسوى": 1,
    "أسوي": 1,
    "أسويك": 1,
    "أسويكم": 1,
    "أسويكما": 1,
    "أسويكن": 1,
    "أسوينا": 1,
    "أسويه": 1,
    "أسويها": 1,
    "أسويهم": 1,
    "أسويهما": 1,
    "أسويهن": 1,
    "أشبهك": 1,
    "أشبهكم": 1,
    "أشبهكما": 1,
    "أشبهكن": 1,
    "أشبهنا": 1,
    "أشبهه": 1,
    "أشبهها": 1,
    "أشبههم": 1,
    "أشبههما": 1,
    "أشبههن": 1,
    "أشبهي": 1,
    "أشمال": 1,
    "أشمالك": 1,
    "أشمالكم": 1,
    "أشمالكما": 1,
    "أشمالكن": 1,
    "أشمالنا": 1,
    "أشماله": 1,
    "أشمالها": 1,
    "أشمالهم": 1,
    "أشمالهما": 1,
    "أشمالهن": 1,
    "أشمالي": 1,
    "أضحى": 1,
    "أطالما": 1,
    "أعامة": 1,
    "أعامتك": 1,
    "أعامتكم": 1,
    "أعامتكما": 1,
    "أعامتكن": 1,
    "أعامتنا": 1,
    "أعامته": 1,
    "أعامتها": 1,
    "أعامتهم": 1,
    "أعامتهما": 1,
    "أعامتهن": 1,
    "أعامتي": 1,
    "أعلي": 1,
    "أعليك": 1,
    "أعليكم": 1,
    "أعليكما": 1,
    "أعليكن": 1,
    "أعلينا": 1,
    "أعليه": 1,
    "أعليها": 1,
    "أعليهم": 1,
    "أعليهما": 1,
    "أعليهن": 1,
    "أعن": 1,
    "أعنك": 1,
    "أعنكم": 1,
    "أعنكما": 1,
    "أعنكن": 1,
    "أعننا": 1,
    "أعنه": 1,
    "أعنها": 1,
    "أعنهم": 1,
    "أعنهما": 1,
    "أعنهن": 1,
    "أعني": 1,
    "أعينك": 1,
    "أعينكما": 1,
    "أعينكن": 1,
    "أعيننا": 1,
    "أعينه": 1,
    "أعينها": 1,
    "أعينهما": 1,
    "أعيني": 1,
    "أغيرك": 1,
    "أغيركم": 1,
    "أغيركما": 1,
    "أغيركن": 1,
    "أغيرنا": 1,
    "أغيره": 1,
    "أغيرها": 1,
    "أغيرهم": 1,
    "أغيرهما": 1,
    "أغيرهن": 1,
    "أغيري": 1,
    "أفأجمع": 1,
    "أفأجمعك": 1,
    "أفأجمعكم": 1,
    "أفأجمعكما": 1,
    "أفأجمعكن": 1,
    "أفأجمعنا": 1,
    "أفأجمعه": 1,
    "أفأجمعها": 1,
    "أفأجمعهم": 1,
    "أفأجمعهما": 1,
    "أفأجمعهن": 1,
    "أفأجمعي": 1,
    "أفأمام": 1,
    "أفأمامك": 1,
    "أفأمامكم": 1,
    "أفأمامكما": 1,
    "أفأمامكن": 1,
    "أفأمامنا": 1,
    "أفأمامه": 1,
    "أفأمامها": 1,
    "أفأمامهم": 1,
    "أفأمامهما": 1,
    "أفأمامهن": 1,
    "أفأمامي": 1,
    "أفأنا": 1,
    "أفأنتما": 1,
    "أفأنتن": 1,
    "أفأولئك": 1,
    "أفأولئكم": 1,
    "أفأولاء": 1,
    "أفأولالك": 1,
    "أفإلى": 1,
    "أفإلي": 1,
    "أفإليك": 1,
    "أفإليكم": 1,
    "أفإليكما": 1,
    "أفإليكن": 1,
    "أفإلينا": 1,
    "أفإليه": 1,
    "أفإليها": 1,
    "أفإليهم": 1,
    "أفإليهما": 1,
    "أفإليهن": 1,
    "أفإنك": 1,
    "أفإنكم": 1,
    "أفإنكما": 1,
    "أفإنكن": 1,
    "أفإننا": 1,
    "أفإنه": 1,
    "أفإنها": 1,
    "أفإنهم": 1,
    "أفإنهما": 1,
    "أفإنهن": 1,
    "أفإني": 1,
    "أفبأجمع": 1,
    "أفبأجمعك": 1,
    "أفبأجمعكم": 1,
    "أفبأجمعكما": 1,
    "أفبأجمعكن": 1,
    "أفبأجمعنا": 1,
    "أفبأجمعه": 1,
    "أفبأجمعها": 1,
    "أفبأجمعهم": 1,
    "أفبأجمعهما": 1,
    "أفبأجمعهن": 1,
    "أفبأجمعي": 1,
    "أفبأولئك": 1,
    "أفبأولئكم": 1,
    "أفبأولاء": 1,
    "أفبأولالك": 1,
    "أفبإن": 1,
    "أفبإنك": 1,
    "أفبإنكم": 1,
    "أفبإنكما": 1,
    "أفبإنكن": 1,
    "أفبإننا": 1,
    "أفبإنه": 1,
    "أفبإنها": 1,
    "أفبإنهم": 1,
    "أفبإنهما": 1,
    "أفبإنهن": 1,
    "أفبإني": 1,
    "أفببعض": 1,
    "أفببعضك": 1,
    "أفببعضكم": 1,
    "أفببعضكما": 1,
    "أفببعضكن": 1,
    "أفببعضنا": 1,
    "أفببعضه": 1,
    "أفببعضها": 1,
    "أفببعضهم": 1,
    "أفببعضهما": 1,
    "أفببعضهن": 1,
    "أفببعضي": 1,
    "أفبتلك": 1,
    "أفبتلكم": 1,
    "أفبتلكما": 1,
    "أفبتينك": 1,
    "أفبجميع": 1,
    "أفبجميعك": 1,
    "أفبجميعكم": 1,
    "أفبجميعكما": 1,
    "أفبجميعكن": 1,
    "أفبجميعنا": 1,
    "أفبجميعه": 1,
    "أفبجميعها": 1,
    "أفبجميعهم": 1,
    "أفبجميعهما": 1,
    "أفبجميعهن": 1,
    "أفبجميعي": 1,
    "أفبحسب": 1,
    "أفبحسبك": 1,
    "أفبحسبكم": 1,
    "أفبحسبكما": 1,
    "أفبحسبكن": 1,
    "أفبحسبنا": 1,
    "أفبحسبه": 1,
    "أفبحسبها": 1,
    "أفبحسبهم": 1,
    "أفبحسبهما": 1,
    "أفبحسبهن": 1,
    "أفبحسبي": 1,
    "أفبذاك": 1,
    "أفبذانك": 1,
    "أفبذلك": 1,
    "أفبذلكم": 1,
    "أفبذلكما": 1,
    "أفبذلكن": 1,
    "أفبذواتي": 1,
    "أفبذي": 1,
    "أفبذين": 1,
    "أفبذينك": 1,
    "أفبسوى": 1,
    "أفبسوي": 1,
    "أفبسويك": 1,
    "أفبسويكم": 1,
    "أفبسويكما": 1,
    "أفبسويكن": 1,
    "أفبسوينا": 1,
    "أفبسويه": 1,
    "أفبسويها": 1,
    "أفبسويهم": 1,
    "أفبسويهما": 1,
    "أفبسويهن": 1,
    "أفبشبه": 1,
    "أفبشبهك": 1,
    "أفبشبهكم": 1,
    "أفبشبهكما": 1,
    "أفبشبهكن": 1,
    "أفبشبهنا": 1,
    "أفبشبهه": 1,
    "أفبشبهها": 1,
    "أفبشبههم": 1,
    "أفبشبههما": 1,
    "أفبشبههن": 1,
    "أفبشبهي": 1,
    "أفبضع": 1,
    "أفبضعك": 1,
    "أفبضعكم": 1,
    "أفبضعكما": 1,
    "أفبضعكن": 1,
    "أفبضعنا": 1,
    "أفبضعه": 1,
    "أفبضعها": 1,
    "أفبضعهم": 1,
    "أفبضعهما": 1,
    "أفبضعهن": 1,
    "أفبضعي": 1,
    "أفبعامة": 1,
    "أفبعامتك": 1,
    "أفبعامتكم": 1,
    "أفبعامتكما": 1,
    "أفبعامتكن": 1,
    "أفبعامتنا": 1,
    "أفبعامته": 1,
    "أفبعامتها": 1,
    "أفبعامتهم": 1,
    "أفبعامتهما": 1,
    "أفبعامتهن": 1,
    "أفبعامتي": 1,
    "أفبعض": 1,
    "أفبعضك": 1,
    "أفبعضكم": 1,
    "أفبعضكما": 1,
    "أفبعضكن": 1,
    "أفبعضنا": 1,
    "أفبعضه": 1,
    "أفبعضها": 1,
    "أفبعضهم": 1,
    "أفبعضهما": 1,
    "أفبعضهن": 1,
    "أفبعضي": 1,
    "أفبعين": 1,
    "أفبعينك": 1,
    "أفبعينكم": 1,
    "أفبعينكما": 1,
    "أفبعينكن": 1,
    "أفبعيننا": 1,
    "أفبعينه": 1,
    "أفبعينها": 1,
    "أفبعينهم": 1,
    "أفبعينهما": 1,
    "أفبعينهن": 1,
    "أفبعيني": 1,
    "أفبغير": 1,
    "أفبغيرك": 1,
    "أفبغيركم": 1,
    "أفبغيركما": 1,
    "أفبغيركن": 1,
    "أفبغيرنا": 1,
    "أفبغيره": 1,
    "أفبغيرها": 1,
    "أفبغيرهم": 1,
    "أفبغيرهما": 1,
    "أفبغيرهن": 1,
    "أفبغيري": 1,
    "أفبك": 1,
    "أفبكل": 1,
    "أفبكلتا": 1,
    "أفبكلك": 1,
    "أفبكلكم": 1,
    "أفبكلكما": 1,
    "أفبكلكن": 1,
    "أفبكلنا": 1,
    "أفبكله": 1,
    "أفبكلها": 1,
    "أفبكلهم": 1,
    "أفبكلهما": 1,
    "أفبكلهن": 1,
    "أفبكلي": 1,
    "أفبكليكما": 1,
    "أفبكليهما": 1,
    "أفبكم": 1,
    "أفبكما": 1,
    "أفبكن": 1,
    "أفبما": 1,
    "أفبمثل": 1,
    "أفبمثلك": 1,
    "أفبمثلكم": 1,
    "أفبمثلكما": 1,
    "أفبمثلكن": 1,
    "أفبمثلنا": 1,
    "أفبمثله": 1,
    "أفبمثلها": 1,
    "أفبمثلهم": 1,
    "أفبمثلهما": 1,
    "أفبمثلهن": 1,
    "أفبمثلي": 1,
    "أفبمن": 1,
    "أفبنا": 1,
    "أفبنحو": 1,
    "أفبنحوك": 1,
    "أفبنحوكم": 1,
    "أفبنحوكما": 1,
    "أفبنحوكن": 1,
    "أفبنحونا": 1,
    "أفبنحوه": 1,
    "أفبنحوها": 1,
    "أفبنحوهم": 1,
    "أفبنحوهما": 1,
    "أفبنحوهن": 1,
    "أفبنحوي": 1,
    "أفبنفس": 1,
    "أفبنفسك": 1,
    "أفبنفسكم": 1,
    "أفبنفسكما": 1,
    "أفبنفسكن": 1,
    "أفبنفسنا": 1,
    "أفبنفسه": 1,
    "أفبنفسها": 1,
    "أفبنفسهم": 1,
    "أفبنفسهما": 1,
    "أفبنفسهن": 1,
    "أفبنفسي": 1,
    "أفبه": 1,
    "أفبهؤلاء": 1,
    "أفبها": 1,
    "أفبهاته": 1,
    "أفبهاتي": 1,
    "أفبهاتين": 1,
    "أفبهذه": 1,
    "أفبهم": 1,
    "أفبهما": 1,
    "أفبهن": 1,
    "أفبهناك": 1,
    "أفبهنالك": 1,
    "أفبهو": 1,
    "أفبهي": 1,
    "أفبي": 1,
    "أفتحت": 1,
    "أفتحتك": 1,
    "أفتحتكم": 1,
    "أفتحتكما": 1,
    "أفتحتكن": 1,
    "أفتحتنا": 1,
    "أفتحته": 1,
    "أفتحتها": 1,
    "أفتحتهم": 1,
    "أفتحتهما": 1,
    "أفتحتهن": 1,
    "أفتحتي": 1,
    "أفتلك": 1,
    "أفتلكم": 1,
    "أفتلكما": 1,
    "أفتينك": 1,
    "أفثم": 1,
    "أفثمة": 1,
    "أفجميع": 1,
    "أفجميعك": 1,
    "أفجميعكم": 1,
    "أفجميعكما": 1,
    "أفجميعكن": 1,
    "أفجميعنا": 1,
    "أفجميعه": 1,
    "أفجميعها": 1,
    "أفجميعهم": 1,
    "أفجميعهما": 1,
    "أفجميعهن": 1,
    "أفجميعي": 1,
    "أفحسبك": 1,
    "أفحسبكم": 1,
    "أفحسبكما": 1,
    "أفحسبكن": 1,
    "أفحسبنا": 1,
    "أفحسبه": 1,
    "أفحسبها": 1,
    "أفحسبهم": 1,
    "أفحسبهما": 1,
    "أفحسبهن": 1,
    "أفحسبي": 1,
    "أفخلف": 1,
    "أفخلفك": 1,
    "أفخلفكم": 1,
    "أفخلفكما": 1,
    "أفخلفكن": 1,
    "أفخلفنا": 1,
    "أفخلفه": 1,
    "أفخلفها": 1,
    "أفخلفهم": 1,
    "أفخلفهما": 1,
    "أفخلفهن": 1,
    "أفخلفي": 1,
    "أفذاك": 1,
    "أفذانك": 1,
    "أفذلك": 1,
    "أفذلكم": 1,
    "أفذلكما": 1,
    "أفذلكن": 1,
    "أفذواتا": 1,
    "أفذواتي": 1,
    "أفذي": 1,
    "أفذين": 1,
    "أفذينك": 1,
    "أفسوى": 1,
    "أفسوي": 1,
    "أفسويك": 1,
    "أفسويكم": 1,
    "أفسويكما": 1,
    "أفسويكن": 1,
    "أفسوينا": 1,
    "أفسويه": 1,
    "أفسويها": 1,
    "أفسويهم": 1,
    "أفسويهما": 1,
    "أفسويهن": 1,
    "أفشبه": 1,
    "أفشبهك": 1,
    "أفشبهكم": 1,
    "أفشبهكما": 1,
    "أفشبهكن": 1,
    "أفشبهنا": 1,
    "أفشبهه": 1,
    "أفشبهها": 1,
    "أفشبههم": 1,
    "أفشبههما": 1,
    "أفشبههن": 1,
    "أفشبهي": 1,
    "أفشمال": 1,
    "أفشمالك": 1,
    "أفشمالكم": 1,
    "أفشمالكما": 1,
    "أفشمالكن": 1,
    "أفشمالنا": 1,
    "أفشماله": 1,
    "أفشمالها": 1,
    "أفشمالهم": 1,
    "أفشمالهما": 1,
    "أفشمالهن": 1,
    "أفشمالي": 1,
    "أفطالما": 1,
    "أفعامة": 1,
    "أفعامتك": 1,
    "أفعامتكم": 1,
    "أفعامتكما": 1,
    "أفعامتكن": 1,
    "أفعامتنا": 1,
    "أفعامته": 1,
    "أفعامتها": 1,
    "أفعامتهم": 1,
    "أفعامتهما": 1,
    "أفعامتهن": 1,
    "أفعامتي": 1,
    "أفعلى": 1,
    "أفعلي": 1,
    "أفعليك": 1,
    "أفعليكم": 1,
    "أفعليكما": 1,
    "أفعليكن": 1,
    "أفعلينا": 1,
    "أفعليه": 1,
    "أفعليها": 1,
    "أفعليهم": 1,
    "أفعليهما": 1,
    "أفعليهن": 1,
    "أفعن": 1,
    "أفعنك": 1,
    "أفعنكم": 1,
    "أفعنكما": 1,
    "أفعنكن": 1,
    "أفعننا": 1,
    "أفعنه": 1,
    "أفعنها": 1,
    "أفعنهم": 1,
    "أفعنهما": 1,
    "أفعنهن": 1,
    "أفعني": 1,
    "أفعين": 1,
    "أفعينك": 1,
    "أفعينكم": 1,
    "أفعينكما": 1,
    "أفعينكن": 1,
    "أفعيننا": 1,
    "أفعينه": 1,
    "أفعينها": 1,
    "أفعينهم": 1,
    "أفعينهما": 1,
    "أفعينهن": 1,
    "أفعيني": 1,
    "أفغيرك": 1,
    "أفغيركم": 1,
    "أفغيركما": 1,
    "أفغيركن": 1,
    "أفغيرنا": 1,
    "أفغيره": 1,
    "أفغيرها": 1,
    "أفغيرهم": 1,
    "أفغيرهما": 1,
    "أفغيرهن": 1,
    "أفغيري": 1,
    "أففلان": 1,
    "أففوق": 1,
    "أففوقك": 1,
    "أففوقكم": 1,
    "أففوقكما": 1,
    "أففوقكن": 1,
    "أففوقنا": 1,
    "أففوقه": 1,
    "أففوقها": 1,
    "أففوقهم": 1,
    "أففوقهما": 1,
    "أففوقهن": 1,
    "أففوقي": 1,
    "أففي": 1,
    "أففيك": 1,
    "أففيكم": 1,
    "أففيكما": 1,
    "أففيكن": 1,
    "أففينا": 1,
    "أففيه": 1,
    "أففيها": 1,
    "أففيهم": 1,
    "أففيهما": 1,
    "أففيهن": 1,
    "أففيي": 1,
    "أفقلما": 1,
    "أفكأجمع": 1,
    "أفكأجمعك": 1,
    "أفكأجمعكم": 1,
    "أفكأجمعكما": 1,
    "أفكأجمعكن": 1,
    "أفكأجمعنا": 1,
    "أفكأجمعه": 1,
    "أفكأجمعها": 1,
    "أفكأجمعهم": 1,
    "أفكأجمعهما": 1,
    "أفكأجمعهن": 1,
    "أفكأجمعي": 1,
    "أفكأن": 1,
    "أفكأنك": 1,
    "أفكأنكم": 1,
    "أفكأنكما": 1,
    "أفكأنكن": 1,
    "أفكأنما": 1,
    "أفكأننا": 1,
    "أفكأنه": 1,
    "أفكأنها": 1,
    "أفكأنهم": 1,
    "أفكأنهما": 1,
    "أفكأنهن": 1,
    "أفكأني": 1,
    "أفكأولئك": 1,
    "أفكأولئكم": 1,
    "أفكأولاء": 1,
    "أفكأولالك": 1,
    "أفكإن": 1,
    "أفكإنك": 1,
    "أفكإنكم": 1,
    "أفكإنكما": 1,
    "أفكإنكن": 1,
    "أفكإننا": 1,
    "أفكإنه": 1,
    "أفكإنها": 1,
    "أفكإنهم": 1,
    "أفكإنهما": 1,
    "أفكإنهن": 1,
    "أفكإني": 1,
    "أفكبعض": 1,
    "أفكبعضك": 1,
    "أفكبعضكم": 1,
    "أفكبعضكما": 1,
    "أفكبعضكن": 1,
    "أفكبعضنا": 1,
    "أفكبعضه": 1,
    "أفكبعضها": 1,
    "أفكبعضهم": 1,
    "أفكبعضهما": 1,
    "أفكبعضهن": 1,
    "أفكبعضي": 1,
    "أفكتلك": 1,
    "أفكتلكم": 1,
    "أفكتلكما": 1,
    "أفكتينك": 1,
    "أفكجميع": 1,
    "أفكجميعك": 1,
    "أفكجميعكم": 1,
    "أفكجميعكما": 1,
    "أفكجميعكن": 1,
    "أفكجميعنا": 1,
    "أفكجميعه": 1,
    "أفكجميعها": 1,
    "أفكجميعهم": 1,
    "أفكجميعهما": 1,
    "أفكجميعهن": 1,
    "أفكجميعي": 1,
    "أفكحسب": 1,
    "أفكحسبك": 1,
    "أفكحسبكم": 1,
    "أفكحسبكما": 1,
    "أفكحسبكن": 1,
    "أفكحسبنا": 1,
    "أفكحسبه": 1,
    "أفكحسبها": 1,
    "أفكحسبهم": 1,
    "أفكحسبهما": 1,
    "أفكحسبهن": 1,
    "أفكحسبي": 1,
    "أفكذا": 1,
    "أفكذاك": 1,
    "أفكذانك": 1,
    "أفكذلك": 1,
    "أفكذلكم": 1,
    "أفكذلكما": 1,
    "أفكذلكن": 1,
    "أفكذواتي": 1,
    "أفكذي": 1,
    "أفكذين": 1,
    "أفكذينك": 1,
    "أفكسوى": 1,
    "أفكسوي": 1,
    "أفكسويك": 1,
    "أفكسويكم": 1,
    "أفكسويكما": 1,
    "أفكسويكن": 1,
    "أفكسوينا": 1,
    "أفكسويه": 1,
    "أفكسويها": 1,
    "أفكسويهم": 1,
    "أفكسويهما": 1,
    "أفكسويهن": 1,
    "أفكشبه": 1,
    "أفكشبهك": 1,
    "أفكشبهكم": 1,
    "أفكشبهكما": 1,
    "أفكشبهكن": 1,
    "أفكشبهنا": 1,
    "أفكشبهه": 1,
    "أفكشبهها": 1,
    "أفكشبههم": 1,
    "أفكشبههما": 1,
    "أفكشبههن": 1,
    "أفكشبهي": 1,
    "أفكعامة": 1,
    "أفكعامتك": 1,
    "أفكعامتكم": 1,
    "أفكعامتكما": 1,
    "أفكعامتكن": 1,
    "أفكعامتنا": 1,
    "أفكعامته": 1,
    "أفكعامتها": 1,
    "أفكعامتهم": 1,
    "أفكعامتهما": 1,
    "أفكعامتهن": 1,
    "أفكعامتي": 1,
    "أفكعين": 1,
    "أفكعينك": 1,
    "أفكعينكم": 1,
    "أفكعينكما": 1,
    "أفكعينكن": 1,
    "أفكعيننا": 1,
    "أفكعينه": 1,
    "أفكعينها": 1,
    "أفكعينهم": 1,
    "أفكعينهما": 1,
    "أفكعينهن": 1,
    "أفكعيني": 1,
    "أفكغير": 1,
    "أفكغيرك": 1,
    "أفكغيركم": 1,
    "أفكغيركما": 1,
    "أفكغيركن": 1,
    "أفكغيرنا": 1,
    "أفكغيره": 1,
    "أفكغيرها": 1,
    "أفكغيرهم": 1,
    "أفكغيرهما": 1,
    "أفكغيرهن": 1,
    "أفكغيري": 1,
    "أفككل": 1,
    "أفككلتا": 1,
    "أفككلك": 1,
    "أفككلكم": 1,
    "أفككلكما": 1,
    "أفككلكن": 1,
    "أفككلنا": 1,
    "أفككله": 1,
    "أفككلها": 1,
    "أفككلهم": 1,
    "أفككلهما": 1,
    "أفككلهن": 1,
    "أفككلي": 1,
    "أفككليكما": 1,
    "أفككليهما": 1,
    "أفكل": 1,
    "أفكلا": 1,
    "أفكلاهما": 1,
    "أفكلتا": 1,
    "أفكلك": 1,
    "أفكلكم": 1,
    "أفكلكما": 1,
    "أفكلكن": 1,
    "أفكلنا": 1,
    "أفكله": 1,
    "أفكلها": 1,
    "أفكلهم": 1,
    "أفكلهما": 1,
    "أفكلهن": 1,
    "أفكلي": 1,
    "أفكليكما": 1,
    "أفكليهما": 1,
    "أفكمثل": 1,
    "أفكمثلك": 1,
    "أفكمثلكم": 1,
    "أفكمثلكما": 1,
    "أفكمثلكن": 1,
    "أفكمثلنا": 1,
    "أفكمثله": 1,
    "أفكمثلها": 1,
    "أفكمثلهم": 1,
    "أفكمثلهما": 1,
    "أفكمثلهن": 1,
    "أفكمثلي": 1,
    "أفكمن": 1,
    "أفكنحو": 1,
    "أفكنحوك": 1,
    "أفكنحوكم": 1,
    "أفكنحوكما": 1,
    "أفكنحوكن": 1,
    "أفكنحونا": 1,
    "أفكنحوه": 1,
    "أفكنحوها": 1,
    "أفكنحوهم": 1,
    "أفكنحوهما": 1,
    "أفكنحوهن": 1,
    "أفكنحوي": 1,
    "أفكنفس": 1,
    "أفكنفسك": 1,
    "أفكنفسكم": 1,
    "أفكنفسكما": 1,
    "أفكنفسكن": 1,
    "أفكنفسنا": 1,
    "أفكنفسه": 1,
    "أفكنفسها": 1,
    "أفكنفسهم": 1,
    "أفكنفسهما": 1,
    "أفكنفسهن": 1,
    "أفكنفسي": 1,
    "أفكهؤلاء": 1,
    "أفكهاته": 1,
    "أفكهاتي": 1,
    "أفكهاتين": 1,
    "أفكهذا": 1,
    "أفكهذه": 1,
    "أفكهم": 1,
    "أفكهما": 1,
    "أفكهن": 1,
    "أفكهناك": 1,
    "أفكهنالك": 1,
    "أفكهو": 1,
    "أفكهي": 1,
    "أفلأجمع": 1,
    "أفلأجمعك": 1,
    "أفلأجمعكم": 1,
    "أفلأجمعكما": 1,
    "أفلأجمعكن": 1,
    "أفلأجمعنا": 1,
    "أفلأجمعه": 1,
    "أفلأجمعها": 1,
    "أفلأجمعهم": 1,
    "أفلأجمعهما": 1,
    "أفلأجمعهن": 1,
    "أفلأجمعي": 1,
    "أفلأمام": 1,
    "أفلأمامك": 1,
    "أفلأمامكم": 1,
    "أفلأمامكما": 1,
    "أفلأمامكن": 1,
    "أفلأمامنا": 1,
    "أفلأمامه": 1,
    "أفلأمامها": 1,
    "أفلأمامهم": 1,
    "أفلأمامهما": 1,
    "أفلأمامهن": 1,
    "أفلأمامي": 1,
    "أفلأنا": 1,
    "أفلأنت": 1,
    "أفلأنتم": 1,
    "أفلأنتما": 1,
    "أفلأنتن": 1,
    "أفلأولئك": 1,
    "أفلأولئكم": 1,
    "أفلأولاء": 1,
    "أفلأولالك": 1,
    "أفلإلى": 1,
    "أفلإلي": 1,
    "أفلإليك": 1,
    "أفلإليكم": 1,
    "أفلإليكما": 1,
    "أفلإليكن": 1,
    "أفلإلينا": 1,
    "أفلإليه": 1,
    "أفلإليها": 1,
    "أفلإليهم": 1,
    "أفلإليهما": 1,
    "أفلإليهن": 1,
    "أفلإن": 1,
    "أفلإنك": 1,
    "أفلإنكم": 1,
    "أفلإنكما": 1,
    "أفلإنكن": 1,
    "أفلإننا": 1,
    "أفلإنه": 1,
    "أفلإنها": 1,
    "أفلإنهم": 1,
    "أفلإنهما": 1,
    "أفلإنهن": 1,
    "أفلإني": 1,
    "أفلان": 1,
    "أفلبعض": 1,
    "أفلبعضك": 1,
    "أفلبعضكم": 1,
    "أفلبعضكما": 1,
    "أفلبعضكن": 1,
    "أفلبعضنا": 1,
    "أفلبعضه": 1,
    "أفلبعضها": 1,
    "أفلبعضهم": 1,
    "أفلبعضهما": 1,
    "أفلبعضهن": 1,
    "أفلبعضي": 1,
    "أفلتحت": 1,
    "أفلتحتك": 1,
    "أفلتحتكم": 1,
    "أفلتحتكما": 1,
    "أفلتحتكن": 1,
    "أفلتحتنا": 1,
    "أفلتحته": 1,
    "أفلتحتها": 1,
    "أفلتحتهم": 1,
    "أفلتحتهما": 1,
    "أفلتحتهن": 1,
    "أفلتحتي": 1,
    "أفلتلك": 1,
    "أفلتلكم": 1,
    "أفلتلكما": 1,
    "أفلتينك": 1,
    "أفلجميع": 1,
    "أفلجميعك": 1,
    "أفلجميعكم": 1,
    "أفلجميعكما": 1,
    "أفلجميعكن": 1,
    "أفلجميعنا": 1,
    "أفلجميعه": 1,
    "أفلجميعها": 1,
    "أفلجميعهم": 1,
    "أفلجميعهما": 1,
    "أفلجميعهن": 1,
    "أفلجميعي": 1,
    "أفلحسب": 1,
    "أفلحسبك": 1,
    "أفلحسبكم": 1,
    "أفلحسبكما": 1,
    "أفلحسبكن": 1,
    "أفلحسبنا": 1,
    "أفلحسبه": 1,
    "أفلحسبها": 1,
    "أفلحسبهم": 1,
    "أفلحسبهما": 1,
    "أفلحسبهن": 1,
    "أفلحسبي": 1,
    "أفلخلف": 1,
    "أفلخلفك": 1,
    "أفلخلفكم": 1,
    "أفلخلفكما": 1,
    "أفلخلفكن": 1,
    "أفلخلفنا": 1,
    "أفلخلفه": 1,
    "أفلخلفها": 1,
    "أفلخلفهم": 1,
    "أفلخلفهما": 1,
    "أفلخلفهن": 1,
    "أفلخلفي": 1,
    "أفلدى": 1,
    "أفلدي": 1,
    "أفلديك": 1,
    "أفلديكم": 1,
    "أفلديكما": 1,
    "أفلديكن": 1,
    "أفلدينا": 1,
    "أفلديه": 1,
    "أفلديها": 1,
    "أفلديهم": 1,
    "أفلديهما": 1,
    "أفلديهن": 1,
    "أفلذاك": 1,
    "أفلذانك": 1,
    "أفلذلك": 1,
    "أفلذلكم": 1,
    "أفلذلكما": 1,
    "أفلذلكن": 1,
    "أفلذواتي": 1,
    "أفلذي": 1,
    "أفلذين": 1,
    "أفلذينك": 1,
    "أفلست": 1,
    "أفلستم": 1,
    "أفلستما": 1,
    "أفلستن": 1,
    "أفلسن": 1,
    "أفلسنا": 1,
    "أفلسوى": 1,
    "أفلسوي": 1,
    "أفلسويك": 1,
    "أفلسويكم": 1,
    "أفلسويكما": 1,
    "أفلسويكن": 1,
    "أفلسوينا": 1,
    "أفلسويه": 1,
    "أفلسويها": 1,
    "أفلسويهم": 1,
    "أفلسويهما": 1,
    "أفلسويهن": 1,
    "أفلشبه": 1,
    "أفلشبهك": 1,
    "أفلشبهكم": 1,
    "أفلشبهكما": 1,
    "أفلشبهكن": 1,
    "أفلشبهنا": 1,
    "أفلشبهه": 1,
    "أفلشبهها": 1,
    "أفلشبههم": 1,
    "أفلشبههما": 1,
    "أفلشبههن": 1,
    "أفلشبهي": 1,
    "أفلشمال": 1,
    "أفلشمالك": 1,
    "أفلشمالكم": 1,
    "أفلشمالكما": 1,
    "أفلشمالكن": 1,
    "أفلشمالنا": 1,
    "أفلشماله": 1,
    "أفلشمالها": 1,
    "أفلشمالهم": 1,
    "أفلشمالهما": 1,
    "أفلشمالهن": 1,
    "أفلشمالي": 1,
    "أفلطالما": 1,
    "أفلعامة": 1,
    "أفلعامتك": 1,
    "أفلعامتكم": 1,
    "أفلعامتكما": 1,
    "أفلعامتكن": 1,
    "أفلعامتنا": 1,
    "أفلعامته": 1,
    "أفلعامتها": 1,
    "أفلعامتهم": 1,
    "أفلعامتهما": 1,
    "أفلعامتهن": 1,
    "أفلعامتي": 1,
    "أفلعل": 1,
    "أفلعلك": 1,
    "أفلعلكم": 1,
    "أفلعلكما": 1,
    "أفلعلكن": 1,
    "أفلعلنا": 1,
    "أفلعله": 1,
    "أفلعلها": 1,
    "أفلعلهم": 1,
    "أفلعلهما": 1,
    "أفلعلهن": 1,
    "أفلعلى": 1,
    "أفلعلي": 1,
    "أفلعليك": 1,
    "أفلعليكم": 1,
    "أفلعليكما": 1,
    "أفلعليكن": 1,
    "أفلعلينا": 1,
    "أفلعليه": 1,
    "أفلعليها": 1,
    "أفلعليهم": 1,
    "أفلعليهما": 1,
    "أفلعليهن": 1,
    "أفلعن": 1,
    "أفلعنك": 1,
    "أفلعنكم": 1,
    "أفلعنكما": 1,
    "أفلعنكن": 1,
    "أفلعننا": 1,
    "أفلعنه": 1,
    "أفلعنها": 1,
    "أفلعنهم": 1,
    "أفلعنهما": 1,
    "أفلعنهن": 1,
    "أفلعني": 1,
    "أفلعين": 1,
    "أفلعينك": 1,
    "أفلعينكم": 1,
    "أفلعينكما": 1,
    "أفلعينكن": 1,
    "أفلعيننا": 1,
    "أفلعينه": 1,
    "أفلعينها": 1,
    "أفلعينهم": 1,
    "أفلعينهما": 1,
    "أفلعينهن": 1,
    "أفلعيني": 1,
    "أفلغير": 1,
    "أفلغيرك": 1,
    "أفلغيركم": 1,
    "أفلغيركما": 1,
    "أفلغيركن": 1,
    "أفلغيرنا": 1,
    "أفلغيره": 1,
    "أفلغيرها": 1,
    "أفلغيرهم": 1,
    "أفلغيرهما": 1,
    "أفلغيرهن": 1,
    "أفلغيري": 1,
    "أفلفوق": 1,
    "أفلفوقك": 1,
    "أفلفوقكم": 1,
    "أفلفوقكما": 1,
    "أفلفوقكن": 1,
    "أفلفوقنا": 1,
    "أفلفوقه": 1,
    "أفلفوقها": 1,
    "أفلفوقهم": 1,
    "أفلفوقهما": 1,
    "أفلفوقهن": 1,
    "أفلفوقي": 1,
    "أفلفي": 1,
    "أفلفيك": 1,
    "أفلفيكم": 1,
    "أفلفيكما": 1,
    "أفلفيكن": 1,
    "أفلفينا": 1,
    "أفلفيه": 1,
    "أفلفيها": 1,
    "أفلفيهم": 1,
    "أفلفيهما": 1,
    "أفلفيهن": 1,
    "أفلفيي": 1,
    "أفلقلما": 1,
    "أفلك": 1,
    "أفلكأن": 1,
    "أفلكأنك": 1,
    "أفلكأنكم": 1,
    "أفلكأنكما": 1,
    "أفلكأنكن": 1,
    "أفلكأنما": 1,
    "أفلكأننا": 1,
    "أفلكأنه": 1,
    "أفلكأنها": 1,
    "أفلكأنهم": 1,
    "أفلكأنهما": 1,
    "أفلكأنهن": 1,
    "أفلكأني": 1,
    "أفلكل": 1,
    "أفلكلتا": 1,
    "أفلكلك": 1,
    "أفلكلكم": 1,
    "أفلكلكما": 1,
    "أفلكلكن": 1,
    "أفلكلنا": 1,
    "أفلكله": 1,
    "أفلكلها": 1,
    "أفلكلهم": 1,
    "أفلكلهما": 1,
    "أفلكلهن": 1,
    "أفلكلي": 1,
    "أفلكليكما": 1,
    "أفلكليهما": 1,
    "أفلكم": 1,
    "أفلكما": 1,
    "أفلكن": 1,
    "أفلما": 1,
    "أفلمثل": 1,
    "أفلمثلك": 1,
    "أفلمثلكم": 1,
    "أفلمثلكما": 1,
    "أفلمثلكن": 1,
    "أفلمثلنا": 1,
    "أفلمثله": 1,
    "أفلمثلها": 1,
    "أفلمثلهم": 1,
    "أفلمثلهما": 1,
    "أفلمثلهن": 1,
    "أفلمثلي": 1,
    "أفلمع": 1,
    "أفلمعك": 1,
    "أفلمعكم": 1,
    "أفلمعكما": 1,
    "أفلمعكن": 1,
    "أفلمعنا": 1,
    "أفلمعه": 1,
    "أفلمعها": 1,
    "أفلمعهم": 1,
    "أفلمعهما": 1,
    "أفلمعهن": 1,
    "أفلمعي": 1,
    "أفلمن": 1,
    "أفلمنك": 1,
    "أفلمنكم": 1,
    "أفلمنكما": 1,
    "أفلمنكن": 1,
    "أفلمننا": 1,
    "أفلمنه": 1,
    "أفلمنها": 1,
    "أفلمنهم": 1,
    "أفلمنهما": 1,
    "أفلمنهن": 1,
    "أفلمني": 1,
    "أفلن": 1,
    "أفلنا": 1,
    "أفلنحن": 1,
    "أفلنحو": 1,
    "أفلنحوك": 1,
    "أفلنحوكم": 1,
    "أفلنحوكما": 1,
    "أفلنحوكن": 1,
    "أفلنحونا": 1,
    "أفلنحوه": 1,
    "أفلنحوها": 1,
    "أفلنحوهم": 1,
    "أفلنحوهما": 1,
    "أفلنحوهن": 1,
    "أفلنحوي": 1,
    "أفلنفس": 1,
    "أفلنفسك": 1,
    "أفلنفسكم": 1,
    "أفلنفسكما": 1,
    "أفلنفسكن": 1,
    "أفلنفسنا": 1,
    "أفلنفسه": 1,
    "أفلنفسها": 1,
    "أفلنفسهم": 1,
    "أفلنفسهما": 1,
    "أفلنفسهن": 1,
    "أفلنفسي": 1,
    "أفله": 1,
    "أفلهؤلاء": 1,
    "أفلها": 1,
    "أفلهاته": 1,
    "أفلهاتي": 1,
    "أفلهاتين": 1,
    "أفلهذا": 1,
    "أفلهذه": 1,
    "أفلهم": 1,
    "أفلهما": 1,
    "أفلهن": 1,
    "أفلهناك": 1,
    "أفلهنالك": 1,
    "أفلهو": 1,
    "أفلهي": 1,
    "أفلو": 1,
    "أفلي": 1,
    "أفليس": 1,
    "أفليسا": 1,
    "أفليست": 1,
    "أفليستا": 1,
    "أفليسوا": 1,
    "أفليمين": 1,
    "أفليمينك": 1,
    "أفليمينكم": 1,
    "أفليمينكما": 1,
    "أفليمينكن": 1,
    "أفليميننا": 1,
    "أفليمينه": 1,
    "أفليمينها": 1,
    "أفليمينهم": 1,
    "أفليمينهما": 1,
    "أفليمينهن": 1,
    "أفليميني": 1,
    "أفماذا": 1,
    "أفمثل": 1,
    "أفمثلك": 1,
    "أفمثلكم": 1,
    "أفمثلكما": 1,
    "أفمثلكن": 1,
    "أفمثلنا": 1,
    "أفمثله": 1,
    "أفمثلها": 1,
    "أفمثلهم": 1,
    "أفمثلهما": 1,
    "أفمثلهن": 1,
    "أفمثلي": 1,
    "أفمع": 1,
    "أفمعك": 1,
    "أفمعكم": 1,
    "أفمعكما": 1,
    "أفمعكن": 1,
    "أفمعنا": 1,
    "أفمعه": 1,
    "أفمعها": 1,
    "أفمعهم": 1,
    "أفمعهما": 1,
    "أفمعهن": 1,
    "أفمعي": 1,
    "أفمما": 1,
    "أفمنك": 1,
    "أفمنكم": 1,
    "أفمنكما": 1,
    "أفمنكن": 1,
    "أفمننا": 1,
    "أفمنه": 1,
    "أفمنها": 1,
    "أفمنهم": 1,
    "أفمنهما": 1,
    "أفمنهن": 1,
    "أفمني": 1,
    "أفمهما": 1,
    "أفنحن": 1,
    "أفنحو": 1,
    "أفنحوك": 1,
    "أفنحوكم": 1,
    "أفنحوكما": 1,
    "أفنحوكن": 1,
    "أفنحونا": 1,
    "أفنحوه": 1,
    "أفنحوها": 1,
    "أفنحوهم": 1,
    "أفنحوهما": 1,
    "أفنحوهن": 1,
    "أفنحوي": 1,
    "أفنفس": 1,
    "أفنفسك": 1,
    "أفنفسكم": 1,
    "أفنفسكما": 1,
    "أفنفسكن": 1,
    "أفنفسنا": 1,
    "أفنفسه": 1,
    "أفنفسها": 1,
    "أفنفسهم": 1,
    "أفنفسهما": 1,
    "أفنفسهن": 1,
    "أفنفسي": 1,
    "أفهؤلاء": 1,
    "أفهاتان": 1,
    "أفهاته": 1,
    "أفهاتي": 1,
    "أفهاتين": 1,
    "أفهاهنا": 1,
    "أفهذا": 1,
    "أفهذان": 1,
    "أفهذه": 1,
    "أفهكذا": 1,
    "أفهما": 1,
    "أفهن": 1,
    "أفهنا": 1,
    "أفهناك": 1,
    "أفهنالك": 1,
    "أفهو": 1,
    "أفهي": 1,
    "أفوق": 1,
    "أفوقك": 1,
    "أفوقكم": 1,
    "أفوقكما": 1,
    "أفوقكن": 1,
    "أفوقنا": 1,
    "أفوقه": 1,
    "أفوقها": 1,
    "أفوقهم": 1,
    "أفوقهما": 1,
    "أفوقهن": 1,
    "أفوقي": 1,
    "أفيك": 1,
    "أفيكم": 1,
    "أفيكما": 1,
    "أفيكن": 1,
    "أفيمين": 1,
    "أفيمينك": 1,
    "أفيمينكم": 1,
    "أفيمينكما": 1,
    "أفيمينكن": 1,
    "أفيميننا": 1,
    "أفيمينه": 1,
    "أفيمينها": 1,
    "أفيمينهم": 1,
    "أفيمينهما": 1,
    "أفيمينهن": 1,
    "أفيميني": 1,
    "أفينا": 1,
    "أفيه": 1,
    "أفيها": 1,
    "أفيهم": 1,
    "أفيهما": 1,
    "أفيهن": 1,
    "أفيي": 1,
    "أقلك": 1,
    "أقلكم": 1,
    "أقلكما": 1,
    "أقلكن": 1,
    "أقلما": 1,
    "أقلنا": 1,
    "أقله": 1,
    "أقلها": 1,
    "أقلهم": 1,
    "أقلهما": 1,
    "أقلهن": 1,
    "أقلي": 1,
    "أكأجمع": 1,
    "أكأجمعك": 1,
    "أكأجمعكم": 1,
    "أكأجمعكما": 1,
    "أكأجمعكن": 1,
    "أكأجمعنا": 1,
    "أكأجمعه": 1,
    "أكأجمعها": 1,
    "أكأجمعهم": 1,
    "أكأجمعهما": 1,
    "أكأجمعهن": 1,
    "أكأجمعي": 1,
    "أكأن": 1,
    "أكأنك": 1,
    "أكأنكم": 1,
    "أكأنكما": 1,
    "أكأنكن": 1,
    "أكأنما": 1,
    "أكأننا": 1,
    "أكأنه": 1,
    "أكأنها": 1,
    "أكأنهم": 1,
    "أكأنهما": 1,
    "أكأنهن": 1,
    "أكأني": 1,
    "أكأولئك": 1,
    "أكأولئكم": 1,
    "أكأولاء": 1,
    "أكأولالك": 1,
    "أكإن": 1,
    "أكإنك": 1,
    "أكإنكم": 1,
    "أكإنكما": 1,
    "أكإنكن": 1,
    "أكإننا": 1,
    "أكإنه": 1,
    "أكإنها": 1,
    "أكإنهم": 1,
    "أكإنهما": 1,
    "أكإنهن": 1,
    "أكإني": 1,
    "أكبعض": 1,
    "أكبعضك": 1,
    "أكبعضكم": 1,
    "أكبعضكما": 1,
    "أكبعضكن": 1,
    "أكبعضنا": 1,
    "أكبعضه": 1,
    "أكبعضها": 1,
    "أكبعضهم": 1,
    "أكبعضهما": 1,
    "أكبعضهن": 1,
    "أكبعضي": 1,
    "أكتلك": 1,
    "أكتلكم": 1,
    "أكتلكما": 1,
    "أكتينك": 1,
    "أكثرك": 1,
    "أكثركما": 1,
    "أكثركن": 1,
    "أكثرنا": 1,
    "أكثره": 1,
    "أكثرها": 1,
    "أكثرهما": 1,
    "أكثرهن": 1,
    "أكثري": 1,
    "أكجميع": 1,
    "أكجميعك": 1,
    "أكجميعكم": 1,
    "أكجميعكما": 1,
    "أكجميعكن": 1,
    "أكجميعنا": 1,
    "أكجميعه": 1,
    "أكجميعها": 1,
    "أكجميعهم": 1,
    "أكجميعهما": 1,
    "أكجميعهن": 1,
    "أكجميعي": 1,
    "أكحسب": 1,
    "أكحسبك": 1,
    "أكحسبكم": 1,
    "أكحسبكما": 1,
    "أكحسبكن": 1,
    "أكحسبنا": 1,
    "أكحسبه": 1,
    "أكحسبها": 1,
    "أكحسبهم": 1,
    "أكحسبهما": 1,
    "أكحسبهن": 1,
    "أكحسبي": 1,
    "أكذا": 1,
    "أكذاك": 1,
    "أكذانك": 1,
    "أكذلك": 1,
    "أكذلكم": 1,
    "أكذلكما": 1,
    "أكذلكن": 1,
    "أكذواتي": 1,
    "أكذي": 1,
    "أكذين": 1,
    "أكذينك": 1,
    "أكسوى": 1,
    "أكسوي": 1,
    "أكسويك": 1,
    "أكسويكم": 1,
    "أكسويكما": 1,
    "أكسويكن": 1,
    "أكسوينا": 1,
    "أكسويه": 1,
    "أكسويها": 1,
    "أكسويهم": 1,
    "أكسويهما": 1,
    "أكسويهن": 1,
    "أكشبه": 1,
    "أكشبهك": 1,
    "أكشبهكم": 1,
    "أكشبهكما": 1,
    "أكشبهكن": 1,
    "أكشبهنا": 1,
    "أكشبهه": 1,
    "أكشبهها": 1,
    "أكشبههم": 1,
    "أكشبههما": 1,
    "أكشبههن": 1,
    "أكشبهي": 1,
    "أكعامة": 1,
    "أكعامتك": 1,
    "أكعامتكم": 1,
    "أكعامتكما": 1,
    "أكعامتكن": 1,
    "أكعامتنا": 1,
    "أكعامته": 1,
    "أكعامتها": 1,
    "أكعامتهم": 1,
    "أكعامتهما": 1,
    "أكعامتهن": 1,
    "أكعامتي": 1,
    "أكعين": 1,
    "أكعينك": 1,
    "أكعينكم": 1,
    "أكعينكما": 1,
    "أكعينكن": 1,
    "أكعيننا": 1,
    "أكعينه": 1,
    "أكعينها": 1,
    "أكعينهم": 1,
    "أكعينهما": 1,
    "أكعينهن": 1,
    "أكعيني": 1,
    "أكغير": 1,
    "أكغيرك": 1,
    "أكغيركم": 1,
    "أكغيركما": 1,
    "أكغيركن": 1,
    "أكغيرنا": 1,
    "أكغيره": 1,
    "أكغيرها": 1,
    "أكغيرهم": 1,
    "أكغيرهما": 1,
    "أكغيرهن": 1,
    "أكغيري": 1,
    "أككل": 1,
    "أككلتا": 1,
    "أككلك": 1,
    "أككلكم": 1,
    "أككلكما": 1,
    "أككلكن": 1,
    "أككلنا": 1,
    "أككله": 1,
    "أككلها": 1,
    "أككلهم": 1,
    "أككلهما": 1,
    "أككلهن": 1,
    "أككلي": 1,
    "أككليكما": 1,
    "أككليهما": 1,
    "أكلاهما": 1,
    "أكلتا": 1,
    "أكلك": 1,
    "أكلكم": 1,
    "أكلكما": 1,
    "أكلكن": 1,
    "أكلما": 1,
    "أكلنا": 1,
    "أكلهم": 1,
    "أكلهما": 1,
    "أكلهن": 1,
    "أكليكما": 1,
    "أكليهما": 1,
    "أكمثل": 1,
    "أكمثلك": 1,
    "أكمثلكم": 1,
    "أكمثلكما": 1,
    "أكمثلكن": 1,
    "أكمثلنا": 1,
    "أكمثله": 1,
    "أكمثلها": 1,
    "أكمثلهم": 1,
    "أكمثلهما": 1,
    "أكمثلهن": 1,
    "أكمثلي": 1,
    "أكمن": 1,
    "أكنحو": 1,
    "أكنحوك": 1,
    "أكنحوكم": 1,
    "أكنحوكما": 1,
    "أكنحوكن": 1,
    "أكنحونا": 1,
    "أكنحوه": 1,
    "أكنحوها": 1,
    "أكنحوهم": 1,
    "أكنحوهما": 1,
    "أكنحوهن": 1,
    "أكنحوي": 1,
    "أكنفس": 1,
    "أكنفسك": 1,
    "أكنفسكم": 1,
    "أكنفسكما": 1,
    "أكنفسكن": 1,
    "أكنفسنا": 1,
    "أكنفسه": 1,
    "أكنفسها": 1,
    "أكنفسهم": 1,
    "أكنفسهما": 1,
    "أكنفسهن": 1,
    "أكنفسي": 1,
    "أكهؤلاء": 1,
    "أكهاته": 1,
    "أكهاتي": 1,
    "أكهاتين": 1,
    "أكهذا": 1,
    "أكهذه": 1,
    "أكهم": 1,
    "أكهما": 1,
    "أكهن": 1,
    "أكهناك": 1,
    "أكهنالك": 1,
    "أكهو": 1,
    "أكهي": 1,
    "ألأجمع": 1,
    "ألأجمعك": 1,
    "ألأجمعكم": 1,
    "ألأجمعكما": 1,
    "ألأجمعكن": 1,
    "ألأجمعنا": 1,
    "ألأجمعه": 1,
    "ألأجمعها": 1,
    "ألأجمعهم": 1,
    "ألأجمعهما": 1,
    "ألأجمعهن": 1,
    "ألأجمعي": 1,
    "ألأمام": 1,
    "ألأمامك": 1,
    "ألأمامكم": 1,
    "ألأمامكما": 1,
    "ألأمامكن": 1,
    "ألأمامنا": 1,
    "ألأمامه": 1,
    "ألأمامها": 1,
    "ألأمامهم": 1,
    "ألأمامهما": 1,
    "ألأمامهن": 1,
    "ألأمامي": 1,
    "ألأنا": 1,
    "ألأنت": 1,
    "ألأنتم": 1,
    "ألأنتما": 1,
    "ألأنتن": 1,
    "ألأولئك": 1,
    "ألأولئكم": 1,
    "ألأولاء": 1,
    "ألأولالك": 1,
    "ألإلى": 1,
    "ألإلي": 1,
    "ألإليك": 1,
    "ألإليكم": 1,
    "ألإليكما": 1,
    "ألإليكن": 1,
    "ألإلينا": 1,
    "ألإليه": 1,
    "ألإليها": 1,
    "ألإليهم": 1,
    "ألإليهما": 1,
    "ألإليهن": 1,
    "ألإن": 1,
    "ألإنك": 1,
    "ألإنكم": 1,
    "ألإنكما": 1,
    "ألإنكن": 1,
    "ألإننا": 1,
    "ألإنه": 1,
    "ألإنها": 1,
    "ألإنهم": 1,
    "ألإنهما": 1,
    "ألإنهن": 1,
    "ألإني": 1,
    "ألبعض": 1,
    "ألبعضك": 1,
    "ألبعضكم": 1,
    "ألبعضكما": 1,
    "ألبعضكن": 1,
    "ألبعضنا": 1,
    "ألبعضه": 1,
    "ألبعضها": 1,
    "ألبعضهم": 1,
    "ألبعضهما": 1,
    "ألبعضهن": 1,
    "ألبعضي": 1,
    "ألتحت": 1,
    "ألتحتك": 1,
    "ألتحتكم": 1,
    "ألتحتكما": 1,
    "ألتحتكن": 1,
    "ألتحتنا": 1,
    "ألتحته": 1,
    "ألتحتها": 1,
    "ألتحتهم": 1,
    "ألتحتهما": 1,
    "ألتحتهن": 1,
    "ألتحتي": 1,
    "ألتلك": 1,
    "ألتلكم": 1,
    "ألتلكما": 1,
    "ألتينك": 1,
    "ألجميع": 1,
    "ألجميعك": 1,
    "ألجميعكم": 1,
    "ألجميعكما": 1,
    "ألجميعكن": 1,
    "ألجميعنا": 1,
    "ألجميعه": 1,
    "ألجميعها": 1,
    "ألجميعهم": 1,
    "ألجميعهما": 1,
    "ألجميعهن": 1,
    "ألجميعي": 1,
    "ألحسب": 1,
    "ألحسبك": 1,
    "ألحسبكم": 1,
    "ألحسبكما": 1,
    "ألحسبكن": 1,
    "ألحسبنا": 1,
    "ألحسبه": 1,
    "ألحسبها": 1,
    "ألحسبهم": 1,
    "ألحسبهما": 1,
    "ألحسبهن": 1,
    "ألحسبي": 1,
    "ألخلف": 1,
    "ألخلفك": 1,
    "ألخلفكم": 1,
    "ألخلفكما": 1,
    "ألخلفكن": 1,
    "ألخلفنا": 1,
    "ألخلفه": 1,
    "ألخلفها": 1,
    "ألخلفهم": 1,
    "ألخلفهما": 1,
    "ألخلفهن": 1,
    "ألخلفي": 1,
    "ألدى": 1,
    "ألدي": 1,
    "ألديك": 1,
    "ألديكم": 1,
    "ألديكما": 1,
    "ألديكن": 1,
    "ألدينا": 1,
    "ألديه": 1,
    "ألديها": 1,
    "ألديهم": 1,
    "ألديهما": 1,
    "ألديهن": 1,
    "ألذاك": 1,
    "ألذانك": 1,
    "ألذلك": 1,
    "ألذلكم": 1,
    "ألذلكما": 1,
    "ألذلكن": 1,
    "ألذواتي": 1,
    "ألذي": 1,
    "ألذين": 1,
    "ألذينك": 1,
    "ألستم": 1,
    "ألستما": 1,
    "ألستن": 1,
    "ألسن": 1,
    "ألسنا": 1,
    "ألسوى": 1,
    "ألسوي": 1,
    "ألسويك": 1,
    "ألسويكم": 1,
    "ألسويكما": 1,
    "ألسويكن": 1,
    "ألسوينا": 1,
    "ألسويه": 1,
    "ألسويها": 1,
    "ألسويهم": 1,
    "ألسويهما": 1,
    "ألسويهن": 1,
    "ألشبه": 1,
    "ألشبهك": 1,
    "ألشبهكم": 1,
    "ألشبهكما": 1,
    "ألشبهكن": 1,
    "ألشبهنا": 1,
    "ألشبهه": 1,
    "ألشبهها": 1,
    "ألشبههم": 1,
    "ألشبههما": 1,
    "ألشبههن": 1,
    "ألشبهي": 1,
    "ألشمال": 1,
    "ألشمالك": 1,
    "ألشمالكم": 1,
    "ألشمالكما": 1,
    "ألشمالكن": 1,
    "ألشمالنا": 1,
    "ألشماله": 1,
    "ألشمالها": 1,
    "ألشمالهم": 1,
    "ألشمالهما": 1,
    "ألشمالهن": 1,
    "ألشمالي": 1,
    "ألطالما": 1,
    "ألعامة": 1,
    "ألعامتك": 1,
    "ألعامتكم": 1,
    "ألعامتكما": 1,
    "ألعامتكن": 1,
    "ألعامتنا": 1,
    "ألعامته": 1,
    "ألعامتها": 1,
    "ألعامتهم": 1,
    "ألعامتهما": 1,
    "ألعامتهن": 1,
    "ألعامتي": 1,
    "ألعل": 1,
    "ألعلك": 1,
    "ألعلكم": 1,
    "ألعلكما": 1,
    "ألعلكن": 1,
    "ألعلنا": 1,
    "ألعله": 1,
    "ألعلها": 1,
    "ألعلهم": 1,
    "ألعلهما": 1,
    "ألعلهن": 1,
    "ألعلى": 1,
    "ألعلي": 1,
    "ألعليك": 1,
    "ألعليكم": 1,
    "ألعليكما": 1,
    "ألعليكن": 1,
    "ألعلينا": 1,
    "ألعليه": 1,
    "ألعليها": 1,
    "ألعليهم": 1,
    "ألعليهما": 1,
    "ألعليهن": 1,
    "ألعن": 1,
    "ألعنك": 1,
    "ألعنكم": 1,
    "ألعنكما": 1,
    "ألعنكن": 1,
    "ألعننا": 1,
    "ألعنه": 1,
    "ألعنها": 1,
    "ألعنهم": 1,
    "ألعنهما": 1,
    "ألعنهن": 1,
    "ألعني": 1,
    "ألعين": 1,
    "ألعينك": 1,
    "ألعينكم": 1,
    "ألعينكما": 1,
    "ألعينكن": 1,
    "ألعيننا": 1,
    "ألعينه": 1,
    "ألعينها": 1,
    "ألعينهم": 1,
    "ألعينهما": 1,
    "ألعينهن": 1,
    "ألعيني": 1,
    "ألغير": 1,
    "ألغيرك": 1,
    "ألغيركم": 1,
    "ألغيركما": 1,
    "ألغيركن": 1,
    "ألغيرنا": 1,
    "ألغيره": 1,
    "ألغيرها": 1,
    "ألغيرهم": 1,
    "ألغيرهما": 1,
    "ألغيرهن": 1,
    "ألغيري": 1,
    "ألفوق": 1,
    "ألفوقك": 1,
    "ألفوقكم": 1,
    "ألفوقكما": 1,
    "ألفوقكن": 1,
    "ألفوقنا": 1,
    "ألفوقه": 1,
    "ألفوقها": 1,
    "ألفوقهم": 1,
    "ألفوقهما": 1,
    "ألفوقهن": 1,
    "ألفوقي": 1,
    "ألفيك": 1,
    "ألفيكم": 1,
    "ألفيكما": 1,
    "ألفيكن": 1,
    "ألفيه": 1,
    "ألفيها": 1,
    "ألفيهم": 1,
    "ألفيهما": 1,
    "ألفيهن": 1,
    "ألفيي": 1,
    "ألقلما": 1,
    "ألك": 1,
    "ألكأن": 1,
    "ألكأنك": 1,
    "ألكأنكم": 1,
    "ألكأنكما": 1,
    "ألكأنكن": 1,
    "ألكأنما": 1,
    "ألكأننا": 1,
    "ألكأنه": 1,
    "ألكأنها": 1,
    "ألكأنهم": 1,
    "ألكأنهما": 1,
    "ألكأنهن": 1,
    "ألكأني": 1,
    "ألكل": 1,
    "ألكلتا": 1,
    "ألكلك": 1,
    "ألكلكم": 1,
    "ألكلكما": 1,
    "ألكلكن": 1,
    "ألكلنا": 1,
    "ألكله": 1,
    "ألكلها": 1,
    "ألكلهم": 1,
    "ألكلهما": 1,
    "ألكلهن": 1,
    "ألكلي": 1,
    "ألكليكما": 1,
    "ألكليهما": 1,
    "ألكما": 1,
    "ألكن": 1,
    "ألما": 1,
    "ألمثل": 1,
    "ألمثلك": 1,
    "ألمثلكم": 1,
    "ألمثلكما": 1,
    "ألمثلكن": 1,
    "ألمثلنا": 1,
    "ألمثله": 1,
    "ألمثلها": 1,
    "ألمثلهم": 1,
    "ألمثلهما": 1,
    "ألمثلهن": 1,
    "ألمثلي": 1,
    "ألمعك": 1,
    "ألمعكم": 1,
    "ألمعكما": 1,
    "ألمعكن": 1,
    "ألمعنا": 1,
    "ألمعه": 1,
    "ألمعها": 1,
    "ألمعهم": 1,
    "ألمعهما": 1,
    "ألمعهن": 1,
    "ألمعي": 1,
    "ألمن": 1,
    "ألمنك": 1,
    "ألمنكم": 1,
    "ألمنكما": 1,
    "ألمنكن": 1,
    "ألمننا": 1,
    "ألمنه": 1,
    "ألمنها": 1,
    "ألمنهم": 1,
    "ألمنهما": 1,
    "ألمنهن": 1,
    "ألمني": 1,
    "ألنا": 1,
    "ألنحن": 1,
    "ألنحو": 1,
    "ألنحوك": 1,
    "ألنحوكم": 1,
    "ألنحوكما": 1,
    "ألنحوكن": 1,
    "ألنحونا": 1,
    "ألنحوه": 1,
    "ألنحوها": 1,
    "ألنحوهم": 1,
    "ألنحوهما": 1,
    "ألنحوهن": 1,
    "ألنحوي": 1,
    "ألنفس": 1,
    "ألنفسك": 1,
    "ألنفسكم": 1,
    "ألنفسكما": 1,
    "ألنفسكن": 1,
    "ألنفسنا": 1,
    "ألنفسه": 1,
    "ألنفسها": 1,
    "ألنفسهم": 1,
    "ألنفسهما": 1,
    "ألنفسهن": 1,
    "ألنفسي": 1,
    "ألهؤلاء": 1,
    "ألها": 1,
    "ألهاته": 1,
    "ألهاتي": 1,
    "ألهاتين": 1,
    "ألهذا": 1,
    "ألهذه": 1,
    "ألهما": 1,
    "ألهن": 1,
    "ألهناك": 1,
    "ألهنالك": 1,
    "ألهو": 1,
    "ألهي": 1,
    "ألو": 1,
    "أليست": 1,
    "أليستا": 1,
    "أليسوا": 1,
    "أليمين": 1,
    "أليمينك": 1,
    "أليمينكم": 1,
    "أليمينكما": 1,
    "أليمينكن": 1,
    "أليميننا": 1,
    "أليمينه": 1,
    "أليمينها": 1,
    "أليمينهم": 1,
    "أليمينهما": 1,
    "أليمينهن": 1,
    "أليميني": 1,
    "أمامك": 1,
    "أمامكم": 1,
    "أمامكما": 1,
    "أمامكن": 1,
    "أمامنا": 1,
    "أمامها": 1,
    "أمامهم": 1,
    "أمامهما": 1,
    "أمامهن": 1,
    "أمثل": 1,
    "أمثلك": 1,
    "أمثلكم": 1,
    "أمثلكما": 1,
    "أمثلكن": 1,
    "أمثلنا": 1,
    "أمثله": 1,
    "أمثلها": 1,
    "أمثلهما": 1,
    "أمثلهن": 1,
    "أمثلي": 1,
    "أمسى": 1,
    "أمع": 1,
    "أمعك": 1,
    "أمعكم": 1,
    "أمعكما": 1,
    "أمعكن": 1,
    "أمعنا": 1,
    "أمعه": 1,
    "أمعها": 1,
    "أمعهم": 1,
    "أمعهما": 1,
    "أمعهن": 1,
    "أمعي": 1,
    "أمنك": 1,
    "أمنكم": 1,
    "أمنكما": 1,
    "أمنكن": 1,
    "أمننا": 1,
    "أمنه": 1,
    "أمنها": 1,
    "أمنهم": 1,
    "أمنهما": 1,
    "أمنهن": 1,
    "أمني": 1,
    "أمهما": 1,
    "أنتن": 1,
    "أنحو": 1,
    "أنحوك": 1,
    "أنحوكم": 1,
    "أنحوكما": 1,
    "أنحوكن": 1,
    "أنحونا": 1,
    "أنحوه": 1,
    "أنحوها": 1,
    "أنحوهم": 1,
    "أنحوهما": 1,
    "أنحوهن": 1,
    "أنحوي": 1,
    "أنفس": 1,
    "أنفسك": 1,
    "أنفسكن": 1,
    "أنفسه": 1,
    "أنفسها": 1,
    "أنفسهما": 1,
    "أنفسي": 1,
    "أنكما": 1,
    "أنكن": 1,
    "أنهن": 1,
    "أهاتان": 1,
    "أهاته": 1,
    "أهاتي": 1,
    "أهاتين": 1,
    "أهاهنا": 1,
    "أهذان": 1,
    "أهذه": 1,
    "أهما": 1,
    "أهنا": 1,
    "أهناك": 1,
    "أهنالك": 1,
    "أهو": 1,
    "أهي": 1,
    "أوأجمع": 1,
    "أوأجمعك": 1,
    "أوأجمعكم": 1,
    "أوأجمعكما": 1,
    "أوأجمعكن": 1,
    "أوأجمعنا": 1,
    "أوأجمعه": 1,
    "أوأجمعها": 1,
    "أوأجمعهم": 1,
    "أوأجمعهما": 1,
    "أوأجمعهن": 1,
    "أوأجمعي": 1,
    "أوأمام": 1,
    "أوأمامك": 1,
    "أوأمامكم": 1,
    "أوأمامكما": 1,
    "أوأمامكن": 1,
    "أوأمامنا": 1,
    "أوأمامه": 1,
    "أوأمامها": 1,
    "أوأمامهم": 1,
    "أوأمامهما": 1,
    "أوأمامهن": 1,
    "أوأمامي": 1,
    "أوأنا": 1,
    "أوأنت": 1,
    "أوأنتم": 1,
    "أوأنتما": 1,
    "أوأنتن": 1,
    "أوأولئك": 1,
    "أوأولئكم": 1,
    "أوأولاء": 1,
    "أوأولالك": 1,
    "أوإلى": 1,
    "أوإلي": 1,
    "أوإليك": 1,
    "أوإليكم": 1,
    "أوإليكما": 1,
    "أوإليكن": 1,
    "أوإلينا": 1,
    "أوإليه": 1,
    "أوإليها": 1,
    "أوإليهم": 1,
    "أوإليهما": 1,
    "أوإليهن": 1,
    "أوإن": 1,
    "أوإنك": 1,
    "أوإنكم": 1,
    "أوإنكما": 1,
    "أوإنكن": 1,
    "أوإننا": 1,
    "أوإنه": 1,
    "أوإنها": 1,
    "أوإنهم": 1,
    "أوإنهما": 1,
    "أوإنهن": 1,
    "أوإني": 1,
    "أوبأجمع": 1,
    "أوبأجمعك": 1,
    "أوبأجمعكم": 1,
    "أوبأجمعكما": 1,
    "أوبأجمعكن": 1,
    "أوبأجمعنا": 1,
    "أوبأجمعه": 1,
    "أوبأجمعها": 1,
    "أوبأجمعهم": 1,
    "أوبأجمعهما": 1,
    "أوبأجمعهن": 1,
    "أوبأجمعي": 1,
    "أوبأولئك": 1,
    "أوبأولئكم": 1,
    "أوبأولاء": 1,
    "أوبأولالك": 1,
    "أوبإن": 1,
    "أوبإنك": 1,
    "أوبإنكم": 1,
    "أوبإنكما": 1,
    "أوبإنكن": 1,
    "أوبإننا": 1,
    "أوبإنه": 1,
    "أوبإنها": 1,
    "أوبإنهم": 1,
    "أوبإنهما": 1,
    "أوبإنهن": 1,
    "أوبإني": 1,
    "أوببعض": 1,
    "أوببعضك": 1,
    "أوببعضكم": 1,
    "أوببعضكما": 1,
    "أوببعضكن": 1,
    "أوببعضنا": 1,
    "أوببعضه": 1,
    "أوببعضها": 1,
    "أوببعضهم": 1,
    "أوببعضهما": 1,
    "أوببعضهن": 1,
    "أوببعضي": 1,
    "أوبتلك": 1,
    "أوبتلكم": 1,
    "أوبتلكما": 1,
    "أوبتينك": 1,
    "أوبجميع": 1,
    "أوبجميعك": 1,
    "أوبجميعكم": 1,
    "أوبجميعكما": 1,
    "أوبجميعكن": 1,
    "أوبجميعنا": 1,
    "أوبجميعه": 1,
    "أوبجميعها": 1,
    "أوبجميعهم": 1,
    "أوبجميعهما": 1,
    "أوبجميعهن": 1,
    "أوبجميعي": 1,
    "أوبحسب": 1,
    "أوبحسبك": 1,
    "أوبحسبكم": 1,
    "أوبحسبكما": 1,
    "أوبحسبكن": 1,
    "أوبحسبنا": 1,
    "أوبحسبه": 1,
    "أوبحسبها": 1,
    "أوبحسبهم": 1,
    "أوبحسبهما": 1,
    "أوبحسبهن": 1,
    "أوبحسبي": 1,
    "أوبذاك": 1,
    "أوبذانك": 1,
    "أوبذلك": 1,
    "أوبذلكم": 1,
    "أوبذلكما": 1,
    "أوبذلكن": 1,
    "أوبذواتي": 1,
    "أوبذي": 1,
    "أوبذين": 1,
    "أوبذينك": 1,
    "أوبسوى": 1,
    "أوبسوي": 1,
    "أوبسويك": 1,
    "أوبسويكم": 1,
    "أوبسويكما": 1,
    "أوبسويكن": 1,
    "أوبسوينا": 1,
    "أوبسويه": 1,
    "أوبسويها": 1,
    "أوبسويهم": 1,
    "أوبسويهما": 1,
    "أوبسويهن": 1,
    "أوبشبه": 1,
    "أوبشبهك": 1,
    "أوبشبهكم": 1,
    "أوبشبهكما": 1,
    "أوبشبهكن": 1,
    "أوبشبهنا": 1,
    "أوبشبهه": 1,
    "أوبشبهها": 1,
    "أوبشبههم": 1,
    "أوبشبههما": 1,
    "أوبشبههن": 1,
    "أوبشبهي": 1,
    "أوبضع": 1,
    "أوبضعك": 1,
    "أوبضعكم": 1,
    "أوبضعكما": 1,
    "أوبضعكن": 1,
    "أوبضعنا": 1,
    "أوبضعه": 1,
    "أوبضعها": 1,
    "أوبضعهم": 1,
    "أوبضعهما": 1,
    "أوبضعهن": 1,
    "أوبضعي": 1,
    "أوبعامة": 1,
    "أوبعامتك": 1,
    "أوبعامتكم": 1,
    "أوبعامتكما": 1,
    "أوبعامتكن": 1,
    "أوبعامتنا": 1,
    "أوبعامته": 1,
    "أوبعامتها": 1,
    "أوبعامتهم": 1,
    "أوبعامتهما": 1,
    "أوبعامتهن": 1,
    "أوبعامتي": 1,
    "أوبعض": 1,
    "أوبعضك": 1,
    "أوبعضكم": 1,
    "أوبعضكما": 1,
    "أوبعضكن": 1,
    "أوبعضنا": 1,
    "أوبعضه": 1,
    "أوبعضها": 1,
    "أوبعضهم": 1,
    "أوبعضهما": 1,
    "أوبعضهن": 1,
    "أوبعضي": 1,
    "أوبعين": 1,
    "أوبعينك": 1,
    "أوبعينكم": 1,
    "أوبعينكما": 1,
    "أوبعينكن": 1,
    "أوبعيننا": 1,
    "أوبعينه": 1,
    "أوبعينها": 1,
    "أوبعينهم": 1,
    "أوبعينهما": 1,
    "أوبعينهن": 1,
    "أوبعيني": 1,
    "أوبغير": 1,
    "أوبغيرك": 1,
    "أوبغيركم": 1,
    "أوبغيركما": 1,
    "أوبغيركن": 1,
    "أوبغيرنا": 1,
    "أوبغيره": 1,
    "أوبغيرها": 1,
    "أوبغيرهم": 1,
    "أوبغيرهما": 1,
    "أوبغيرهن": 1,
    "أوبغيري": 1,
    "أوبك": 1,
    "أوبكل": 1,
    "أوبكلتا": 1,
    "أوبكلك": 1,
    "أوبكلكم": 1,
    "أوبكلكما": 1,
    "أوبكلكن": 1,
    "أوبكلنا": 1,
    "أوبكله": 1,
    "أوبكلها": 1,
    "أوبكلهم": 1,
    "أوبكلهما": 1,
    "أوبكلهن": 1,
    "أوبكلي": 1,
    "أوبكليكما": 1,
    "أوبكليهما": 1,
    "أوبكم": 1,
    "أوبكما": 1,
    "أوبكن": 1,
    "أوبما": 1,
    "أوبمثل": 1,
    "أوبمثلك": 1,
    "أوبمثلكم": 1,
    "أوبمثلكما": 1,
    "أوبمثلكن": 1,
    "أوبمثلنا": 1,
    "أوبمثله": 1,
    "أوبمثلها": 1,
    "أوبمثلهم": 1,
    "أوبمثلهما": 1,
    "أوبمثلهن": 1,
    "أوبمثلي": 1,
    "أوبمن": 1,
    "أوبنا": 1,
    "أوبنحو": 1,
    "أوبنحوك": 1,
    "أوبنحوكم": 1,
    "أوبنحوكما": 1,
    "أوبنحوكن": 1,
    "أوبنحونا": 1,
    "أوبنحوه": 1,
    "أوبنحوها": 1,
    "أوبنحوهم": 1,
    "أوبنحوهما": 1,
    "أوبنحوهن": 1,
    "أوبنحوي": 1,
    "أوبنفس": 1,
    "أوبنفسك": 1,
    "أوبنفسكم": 1,
    "أوبنفسكما": 1,
    "أوبنفسكن": 1,
    "أوبنفسنا": 1,
    "أوبنفسه": 1,
    "أوبنفسها": 1,
    "أوبنفسهم": 1,
    "أوبنفسهما": 1,
    "أوبنفسهن": 1,
    "أوبنفسي": 1,
    "أوبه": 1,
    "أوبهؤلاء": 1,
    "أوبها": 1,
    "أوبهاته": 1,
    "أوبهاتي": 1,
    "أوبهاتين": 1,
    "أوبهذا": 1,
    "أوبهذه": 1,
    "أوبهم": 1,
    "أوبهما": 1,
    "أوبهن": 1,
    "أوبهناك": 1,
    "أوبهنالك": 1,
    "أوبهو": 1,
    "أوبهي": 1,
    "أوتحت": 1,
    "أوتحتك": 1,
    "أوتحتكم": 1,
    "أوتحتكما": 1,
    "أوتحتكن": 1,
    "أوتحتنا": 1,
    "أوتحته": 1,
    "أوتحتها": 1,
    "أوتحتهم": 1,
    "أوتحتهما": 1,
    "أوتحتهن": 1,
    "أوتحتي": 1,
    "أوتلك": 1,
    "أوتلكم": 1,
    "أوتلكما": 1,
    "أوتينك": 1,
    "أوثم": 1,
    "أوثمة": 1,
    "أوجميع": 1,
    "أوجميعك": 1,
    "أوجميعكم": 1,
    "أوجميعكما": 1,
    "أوجميعكن": 1,
    "أوجميعنا": 1,
    "أوجميعه": 1,
    "أوجميعها": 1,
    "أوجميعهم": 1,
    "أوجميعهما": 1,
    "أوجميعهن": 1,
    "أوجميعي": 1,
    "أوحسب": 1,
    "أوحسبك": 1,
    "أوحسبكم": 1,
    "أوحسبكما": 1,
    "أوحسبكن": 1,
    "أوحسبنا": 1,
    "أوحسبه": 1,
    "أوحسبها": 1,
    "أوحسبهم": 1,
    "أوحسبهما": 1,
    "أوحسبهن": 1,
    "أوحسبي": 1,
    "أوخلف": 1,
    "أوخلفك": 1,
    "أوخلفكم": 1,
    "أوخلفكما": 1,
    "أوخلفكن": 1,
    "أوخلفنا": 1,
    "أوخلفه": 1,
    "أوخلفها": 1,
    "أوخلفهم": 1,
    "أوخلفهما": 1,
    "أوخلفهن": 1,
    "أوخلفي": 1,
    "أوذاك": 1,
    "أوذانك": 1,
    "أوذلك": 1,
    "أوذلكم": 1,
    "أوذلكما": 1,
    "أوذلكن": 1,
    "أوذواتا": 1,
    "أوذواتي": 1,
    "أوذين": 1,
    "أوذينك": 1,
    "أوسوى": 1,
    "أوسوي": 1,
    "أوسويك": 1,
    "أوسويكم": 1,
    "أوسويكما": 1,
    "أوسويكن": 1,
    "أوسوينا": 1,
    "أوسويه": 1,
    "أوسويها": 1,
    "أوسويهم": 1,
    "أوسويهما": 1,
    "أوسويهن": 1,
    "أوشبه": 1,
    "أوشبهك": 1,
    "أوشبهكم": 1,
    "أوشبهكما": 1,
    "أوشبهكن": 1,
    "أوشبهنا": 1,
    "أوشبهه": 1,
    "أوشبهها": 1,
    "أوشبههم": 1,
    "أوشبههما": 1,
    "أوشبههن": 1,
    "أوشبهي": 1,
    "أوشك": 1,
    "أوشمال": 1,
    "أوشمالك": 1,
    "أوشمالكم": 1,
    "أوشمالكما": 1,
    "أوشمالكن": 1,
    "أوشمالنا": 1,
    "أوشماله": 1,
    "أوشمالها": 1,
    "أوشمالهم": 1,
    "أوشمالهما": 1,
    "أوشمالهن": 1,
    "أوشمالي": 1,
    "أوطالما": 1,
    "أوعامة": 1,
    "أوعامتك": 1,
    "أوعامتكم": 1,
    "أوعامتكما": 1,
    "أوعامتكن": 1,
    "أوعامتنا": 1,
    "أوعامته": 1,
    "أوعامتها": 1,
    "أوعامتهم": 1,
    "أوعامتهما": 1,
    "أوعامتهن": 1,
    "أوعامتي": 1,
    "أوعلى": 1,
    "أوعلي": 1,
    "أوعليك": 1,
    "أوعليكم": 1,
    "أوعليكما": 1,
    "أوعليكن": 1,
    "أوعلينا": 1,
    "أوعليه": 1,
    "أوعليها": 1,
    "أوعليهم": 1,
    "أوعليهما": 1,
    "أوعليهن": 1,
    "أوعن": 1,
    "أوعنك": 1,
    "أوعنكم": 1,
    "أوعنكما": 1,
    "أوعنكن": 1,
    "أوعننا": 1,
    "أوعنه": 1,
    "أوعنها": 1,
    "أوعنهم": 1,
    "أوعنهما": 1,
    "أوعنهن": 1,
    "أوعني": 1,
    "أوعين": 1,
    "أوعينك": 1,
    "أوعينكم": 1,
    "أوعينكما": 1,
    "أوعينكن": 1,
    "أوعيننا": 1,
    "أوعينه": 1,
    "أوعينها": 1,
    "أوعينهم": 1,
    "أوعينهما": 1,
    "أوعينهن": 1,
    "أوعيني": 1,
    "أوغير": 1,
    "أوغيرك": 1,
    "أوغيركم": 1,
    "أوغيركما": 1,
    "أوغيركن": 1,
    "أوغيرنا": 1,
    "أوغيره": 1,
    "أوغيرها": 1,
    "أوغيرهم": 1,
    "أوغيرهما": 1,
    "أوغيرهن": 1,
    "أوغيري": 1,
    "أوفلان": 1,
    "أوفوق": 1,
    "أوفوقك": 1,
    "أوفوقكم": 1,
    "أوفوقكما": 1,
    "أوفوقكن": 1,
    "أوفوقنا": 1,
    "أوفوقه": 1,
    "أوفوقها": 1,
    "أوفوقهم": 1,
    "أوفوقهما": 1,
    "أوفوقهن": 1,
    "أوفوقي": 1,
    "أوفيك": 1,
    "أوفيكم": 1,
    "أوفيكما": 1,
    "أوفيكن": 1,
    "أوفينا": 1,
    "أوفيه": 1,
    "أوفيها": 1,
    "أوفيهم": 1,
    "أوفيهما": 1,
    "أوفيهن": 1,
    "أوفيي": 1,
    "أوقلما": 1,
    "أوكأجمع": 1,
    "أوكأجمعك": 1,
    "أوكأجمعكم": 1,
    "أوكأجمعكما": 1,
    "أوكأجمعكن": 1,
    "أوكأجمعنا": 1,
    "أوكأجمعه": 1,
    "أوكأجمعها": 1,
    "أوكأجمعهم": 1,
    "أوكأجمعهما": 1,
    "أوكأجمعهن": 1,
    "أوكأجمعي": 1,
    "أوكأن": 1,
    "أوكأنك": 1,
    "أوكأنكم": 1,
    "أوكأنكما": 1,
    "أوكأنكن": 1,
    "أوكأنما": 1,
    "أوكأننا": 1,
    "أوكأنه": 1,
    "أوكأنها": 1,
    "أوكأنهم": 1,
    "أوكأنهما": 1,
    "أوكأنهن": 1,
    "أوكأني": 1,
    "أوكأولئك": 1,
    "أوكأولئكم": 1,
    "أوكأولاء": 1,
    "أوكأولالك": 1,
    "أوكإن": 1,
    "أوكإنك": 1,
    "أوكإنكم": 1,
    "أوكإنكما": 1,
    "أوكإنكن": 1,
    "أوكإننا": 1,
    "أوكإنه": 1,
    "أوكإنها": 1,
    "أوكإنهم": 1,
    "أوكإنهما": 1,
    "أوكإنهن": 1,
    "أوكإني": 1,
    "أوكبعض": 1,
    "أوكبعضك": 1,
    "أوكبعضكم": 1,
    "أوكبعضكما": 1,
    "أوكبعضكن": 1,
    "أوكبعضنا": 1,
    "أوكبعضه": 1,
    "أوكبعضها": 1,
    "أوكبعضهم": 1,
    "أوكبعضهما": 1,
    "أوكبعضهن": 1,
    "أوكبعضي": 1,
    "أوكتلك": 1,
    "أوكتلكم": 1,
    "أوكتلكما": 1,
    "أوكتينك": 1,
    "أوكجميع": 1,
    "أوكجميعك": 1,
    "أوكجميعكم": 1,
    "أوكجميعكما": 1,
    "أوكجميعكن": 1,
    "أوكجميعنا": 1,
    "أوكجميعه": 1,
    "أوكجميعها": 1,
    "أوكجميعهم": 1,
    "أوكجميعهما": 1,
    "أوكجميعهن": 1,
    "أوكجميعي": 1,
    "أوكحسب": 1,
    "أوكحسبك": 1,
    "أوكحسبكم": 1,
    "أوكحسبكما": 1,
    "أوكحسبكن": 1,
    "أوكحسبنا": 1,
    "أوكحسبه": 1,
    "أوكحسبها": 1,
    "أوكحسبهم": 1,
    "أوكحسبهما": 1,
    "أوكحسبهن": 1,
    "أوكحسبي": 1,
    "أوكذا": 1,
    "أوكذاك": 1,
    "أوكذانك": 1,
    "أوكذلك": 1,
    "أوكذلكم": 1,
    "أوكذلكما": 1,
    "أوكذلكن": 1,
    "أوكذواتي": 1,
    "أوكذي": 1,
    "أوكذين": 1,
    "أوكذينك": 1,
    "أوكسوى": 1,
    "أوكسوي": 1,
    "أوكسويك": 1,
    "أوكسويكم": 1,
    "أوكسويكما": 1,
    "أوكسويكن": 1,
    "أوكسوينا": 1,
    "أوكسويه": 1,
    "أوكسويها": 1,
    "أوكسويهم": 1,
    "أوكسويهما": 1,
    "أوكسويهن": 1,
    "أوكشبه": 1,
    "أوكشبهك": 1,
    "أوكشبهكم": 1,
    "أوكشبهكما": 1,
    "أوكشبهكن": 1,
    "أوكشبهنا": 1,
    "أوكشبهه": 1,
    "أوكشبهها": 1,
    "أوكشبههم": 1,
    "أوكشبههما": 1,
    "أوكشبههن": 1,
    "أوكشبهي": 1,
    "أوكعامة": 1,
    "أوكعامتك": 1,
    "أوكعامتكم": 1,
    "أوكعامتكما": 1,
    "أوكعامتكن": 1,
    "أوكعامتنا": 1,
    "أوكعامته": 1,
    "أوكعامتها": 1,
    "أوكعامتهم": 1,
    "أوكعامتهما": 1,
    "أوكعامتهن": 1,
    "أوكعامتي": 1,
    "أوكعين": 1,
    "أوكعينك": 1,
    "أوكعينكم": 1,
    "أوكعينكما": 1,
    "أوكعينكن": 1,
    "أوكعيننا": 1,
    "أوكعينه": 1,
    "أوكعينها": 1,
    "أوكعينهم": 1,
    "أوكعينهما": 1,
    "أوكعينهن": 1,
    "أوكعيني": 1,
    "أوكغير": 1,
    "أوكغيرك": 1,
    "أوكغيركم": 1,
    "أوكغيركما": 1,
    "أوكغيركن": 1,
    "أوكغيرنا": 1,
    "أوكغيره": 1,
    "أوكغيرها": 1,
    "أوكغيرهم": 1,
    "أوكغيرهما": 1,
    "أوكغيرهن": 1,
    "أوكغيري": 1,
    "أوككل": 1,
    "أوككلتا": 1,
    "أوككلك": 1,
    "أوككلكم": 1,
    "أوككلكما": 1,
    "أوككلكن": 1,
    "أوككلنا": 1,
    "أوككله": 1,
    "أوككلها": 1,
    "أوككلهم": 1,
    "أوككلهما": 1,
    "أوككلهن": 1,
    "أوككلي": 1,
    "أوككليكما": 1,
    "أوككليهما": 1,
    "أوكل": 1,
    "أوكلا": 1,
    "أوكلاهما": 1,
    "أوكلتا": 1,
    "أوكلك": 1,
    "أوكلكم": 1,
    "أوكلكما": 1,
    "أوكلكن": 1,
    "أوكلنا": 1,
    "أوكله": 1,
    "أوكلها": 1,
    "أوكلهم": 1,
    "أوكلهما": 1,
    "أوكلهن": 1,
    "أوكليكما": 1,
    "أوكليهما": 1,
    "أوكمثل": 1,
    "أوكمثلك": 1,
    "أوكمثلكم": 1,
    "أوكمثلكما": 1,
    "أوكمثلكن": 1,
    "أوكمثلنا": 1,
    "أوكمثله": 1,
    "أوكمثلها": 1,
    "أوكمثلهم": 1,
    "أوكمثلهما": 1,
    "أوكمثلهن": 1,
    "أوكمثلي": 1,
    "أوكمن": 1,
    "أوكنحو": 1,
    "أوكنحوك": 1,
    "أوكنحوكم": 1,
    "أوكنحوكما": 1,
    "أوكنحوكن": 1,
    "أوكنحونا": 1,
    "أوكنحوه": 1,
    "أوكنحوها": 1,
    "أوكنحوهم": 1,
    "أوكنحوهما": 1,
    "أوكنحوهن": 1,
    "أوكنحوي": 1,
    "أوكنفس": 1,
    "أوكنفسك": 1,
    "أوكنفسكم": 1,
    "أوكنفسكما": 1,
    "أوكنفسكن": 1,
    "أوكنفسنا": 1,
    "أوكنفسه": 1,
    "أوكنفسها": 1,
    "أوكنفسهم": 1,
    "أوكنفسهما": 1,
    "أوكنفسهن": 1,
    "أوكنفسي": 1,
    "أوكهؤلاء": 1,
    "أوكهاته": 1,
    "أوكهاتي": 1,
    "أوكهاتين": 1,
    "أوكهذا": 1,
    "أوكهذه": 1,
    "أوكهم": 1,
    "أوكهما": 1,
    "أوكهن": 1,
    "أوكهناك": 1,
    "أوكهنالك": 1,
    "أوكهو": 1,
    "أوكهي": 1,
    "أولأجمع": 1,
    "أولأجمعك": 1,
    "أولأجمعكم": 1,
    "أولأجمعكما": 1,
    "أولأجمعكن": 1,
    "أولأجمعنا": 1,
    "أولأجمعه": 1,
    "أولأجمعها": 1,
    "أولأجمعهم": 1,
    "أولأجمعهما": 1,
    "أولأجمعهن": 1,
    "أولأجمعي": 1,
    "أولأمام": 1,
    "أولأمامك": 1,
    "أولأمامكم": 1,
    "أولأمامكما": 1,
    "أولأمامكن": 1,
    "أولأمامنا": 1,
    "أولأمامه": 1,
    "أولأمامها": 1,
    "أولأمامهم": 1,
    "أولأمامهما": 1,
    "أولأمامهن": 1,
    "أولأمامي": 1,
    "أولأنا": 1,
    "أولأنت": 1,
    "أولأنتم": 1,
    "أولأنتما": 1,
    "أولأنتن": 1,
    "أولأولئك": 1,
    "أولأولئكم": 1,
    "أولأولاء": 1,
    "أولأولالك": 1,
    "أولإلى": 1,
    "أولإلي": 1,
    "أولإليك": 1,
    "أولإليكم": 1,
    "أولإليكما": 1,
    "أولإليكن": 1,
    "أولإلينا": 1,
    "أولإليه": 1,
    "أولإليها": 1,
    "أولإليهم": 1,
    "أولإليهما": 1,
    "أولإليهن": 1,
    "أولإن": 1,
    "أولإنك": 1,
    "أولإنكم": 1,
    "أولإنكما": 1,
    "أولإنكن": 1,
    "أولإننا": 1,
    "أولإنه": 1,
    "أولإنها": 1,
    "أولإنهم": 1,
    "أولإنهما": 1,
    "أولإنهن": 1,
    "أولإني": 1,
    "أولالك": 1,
    "أولبعض": 1,
    "أولبعضك": 1,
    "أولبعضكم": 1,
    "أولبعضكما": 1,
    "أولبعضكن": 1,
    "أولبعضنا": 1,
    "أولبعضه": 1,
    "أولبعضها": 1,
    "أولبعضهم": 1,
    "أولبعضهما": 1,
    "أولبعضهن": 1,
    "أولبعضي": 1,
    "أولتحت": 1,
    "أولتحتك": 1,
    "أولتحتكم": 1,
    "أولتحتكما": 1,
    "أولتحتكن": 1,
    "أولتحتنا": 1,
    "أولتحته": 1,
    "أولتحتها": 1,
    "أولتحتهم": 1,
    "أولتحتهما": 1,
    "أولتحتهن": 1,
    "أولتحتي": 1,
    "أولتلك": 1,
    "أولتلكم": 1,
    "أولتلكما": 1,
    "أولتينك": 1,
    "أولجميع": 1,
    "أولجميعك": 1,
    "أولجميعكم": 1,
    "أولجميعكما": 1,
    "أولجميعكن": 1,
    "أولجميعنا": 1,
    "أولجميعه": 1,
    "أولجميعها": 1,
    "أولجميعهم": 1,
    "أولجميعهما": 1,
    "أولجميعهن": 1,
    "أولجميعي": 1,
    "أولحسب": 1,
    "أولحسبك": 1,
    "أولحسبكم": 1,
    "أولحسبكما": 1,
    "أولحسبكن": 1,
    "أولحسبنا": 1,
    "أولحسبه": 1,
    "أولحسبها": 1,
    "أولحسبهم": 1,
    "أولحسبهما": 1,
    "أولحسبهن": 1,
    "أولحسبي": 1,
    "أولخلف": 1,
    "أولخلفك": 1,
    "أولخلفكم": 1,
    "أولخلفكما": 1,
    "أولخلفكن": 1,
    "أولخلفنا": 1,
    "أولخلفه": 1,
    "أولخلفها": 1,
    "أولخلفهم": 1,
    "أولخلفهما": 1,
    "أولخلفهن": 1,
    "أولخلفي": 1,
    "أولدى": 1,
    "أولدي": 1,
    "أولديك": 1,
    "أولديكم": 1,
    "أولديكما": 1,
    "أولديكن": 1,
    "أولدينا": 1,
    "أولديه": 1,
    "أولديها": 1,
    "أولديهم": 1,
    "أولديهما": 1,
    "أولديهن": 1,
    "أولذاك": 1,
    "أولذانك": 1,
    "أولذلك": 1,
    "أولذلكم": 1,
    "أولذلكما": 1,
    "أولذلكن": 1,
    "أولذواتي": 1,
    "أولذي": 1,
    "أولذين": 1,
    "أولذينك": 1,
    "أولست": 1,
    "أولستم": 1,
    "أولستما": 1,
    "أولستن": 1,
    "أولسن": 1,
    "أولسنا": 1,
    "أولسوى": 1,
    "أولسوي": 1,
    "أولسويك": 1,
    "أولسويكم": 1,
    "أولسويكما": 1,
    "أولسويكن": 1,
    "أولسوينا": 1,
    "أولسويه": 1,
    "أولسويها": 1,
    "أولسويهم": 1,
    "أولسويهما": 1,
    "أولسويهن": 1,
    "أولشبه": 1,
    "أولشبهك": 1,
    "أولشبهكم": 1,
    "أولشبهكما": 1,
    "أولشبهكن": 1,
    "أولشبهنا": 1,
    "أولشبهه": 1,
    "أولشبهها": 1,
    "أولشبههم": 1,
    "أولشبههما": 1,
    "أولشبههن": 1,
    "أولشبهي": 1,
    "أولشمال": 1,
    "أولشمالك": 1,
    "أولشمالكم": 1,
    "أولشمالكما": 1,
    "أولشمالكن": 1,
    "أولشمالنا": 1,
    "أولشماله": 1,
    "أولشمالها": 1,
    "أولشمالهم": 1,
    "أولشمالهما": 1,
    "أولشمالهن": 1,
    "أولشمالي": 1,
    "أولطالما": 1,
    "أولعامة": 1,
    "أولعامتك": 1,
    "أولعامتكم": 1,
    "أولعامتكما": 1,
    "أولعامتكن": 1,
    "أولعامتنا": 1,
    "أولعامته": 1,
    "أولعامتها": 1,
    "أولعامتهم": 1,
    "أولعامتهما": 1,
    "أولعامتهن": 1,
    "أولعامتي": 1,
    "أولعل": 1,
    "أولعلك": 1,
    "أولعلكم": 1,
    "أولعلكما": 1,
    "أولعلكن": 1,
    "أولعلنا": 1,
    "أولعله": 1,
    "أولعلها": 1,
    "أولعلهم": 1,
    "أولعلهما": 1,
    "أولعلهن": 1,
    "أولعلى": 1,
    "أولعلي": 1,
    "أولعليك": 1,
    "أولعليكم": 1,
    "أولعليكما": 1,
    "أولعليكن": 1,
    "أولعلينا": 1,
    "أولعليه": 1,
    "أولعليها": 1,
    "أولعليهم": 1,
    "أولعليهما": 1,
    "أولعليهن": 1,
    "أولعن": 1,
    "أولعنك": 1,
    "أولعنكم": 1,
    "أولعنكما": 1,
    "أولعنكن": 1,
    "أولعننا": 1,
    "أولعنه": 1,
    "أولعنها": 1,
    "أولعنهم": 1,
    "أولعنهما": 1,
    "أولعنهن": 1,
    "أولعني": 1,
    "أولعين": 1,
    "أولعينك": 1,
    "أولعينكم": 1,
    "أولعينكما": 1,
    "أولعينكن": 1,
    "أولعيننا": 1,
    "أولعينه": 1,
    "أولعينها": 1,
    "أولعينهم": 1,
    "أولعينهما": 1,
    "أولعينهن": 1,
    "أولعيني": 1,
    "أولغير": 1,
    "أولغيرك": 1,
    "أولغيركم": 1,
    "أولغيركما": 1,
    "أولغيركن": 1,
    "أولغيرنا": 1,
    "أولغيره": 1,
    "أولغيرها": 1,
    "أولغيرهم": 1,
    "أولغيرهما": 1,
    "أولغيرهن": 1,
    "أولغيري": 1,
    "أولفوق": 1,
    "أولفوقك": 1,
    "أولفوقكم": 1,
    "أولفوقكما": 1,
    "أولفوقكن": 1,
    "أولفوقنا": 1,
    "أولفوقه": 1,
    "أولفوقها": 1,
    "أولفوقهم": 1,
    "أولفوقهما": 1,
    "أولفوقهن": 1,
    "أولفوقي": 1,
    "أولفي": 1,
    "أولفيك": 1,
    "أولفيكم": 1,
    "أولفيكما": 1,
    "أولفيكن": 1,
    "أولفينا": 1,
    "أولفيه": 1,
    "أولفيها": 1,
    "أولفيهم": 1,
    "أولفيهما": 1,
    "أولفيهن": 1,
    "أولفيي": 1,
    "أولقلما": 1,
    "أولك": 1,
    "أولكأن": 1,
    "أولكأنك": 1,
    "أولكأنكم": 1,
    "أولكأنكما": 1,
    "أولكأنكن": 1,
    "أولكأنما": 1,
    "أولكأننا": 1,
    "أولكأنه": 1,
    "أولكأنها": 1,
    "أولكأنهم": 1,
    "أولكأنهما": 1,
    "أولكأنهن": 1,
    "أولكأني": 1,
    "أولكل": 1,
    "أولكلتا": 1,
    "أولكلك": 1,
    "أولكلكم": 1,
    "أولكلكما": 1,
    "أولكلكن": 1,
    "أولكلنا": 1,
    "أولكله": 1,
    "أولكلها": 1,
    "أولكلهم": 1,
    "أولكلهما": 1,
    "أولكلهن": 1,
    "أولكلي": 1,
    "أولكليكما": 1,
    "أولكليهما": 1,
    "أولكم": 1,
    "أولكما": 1,
    "أولكن": 1,
    "أولمثل": 1,
    "أولمثلك": 1,
    "أولمثلكم": 1,
    "أولمثلكما": 1,
    "أولمثلكن": 1,
    "أولمثلنا": 1,
    "أولمثله": 1,
    "أولمثلها": 1,
    "أولمثلهم": 1,
    "أولمثلهما": 1,
    "أولمثلهن": 1,
    "أولمثلي": 1,
    "أولمع": 1,
    "أولمعك": 1,
    "أولمعكم": 1,
    "أولمعكما": 1,
    "أولمعكن": 1,
    "أولمعنا": 1,
    "أولمعه": 1,
    "أولمعها": 1,
    "أولمعهم": 1,
    "أولمعهما": 1,
    "أولمعهن": 1,
    "أولمعي": 1,
    "أولمن": 1,
    "أولمنك": 1,
    "أولمنكم": 1,
    "أولمنكما": 1,
    "أولمنكن": 1,
    "أولمننا": 1,
    "أولمنه": 1,
    "أولمنها": 1,
    "أولمنهم": 1,
    "أولمنهما": 1,
    "أولمنهن": 1,
    "أولمني": 1,
    "أولنا": 1,
    "أولنحن": 1,
    "أولنحو": 1,
    "أولنحوك": 1,
    "أولنحوكم": 1,
    "أولنحوكما": 1,
    "أولنحوكن": 1,
    "أولنحونا": 1,
    "أولنحوه": 1,
    "أولنحوها": 1,
    "أولنحوهم": 1,
    "أولنحوهما": 1,
    "أولنحوهن": 1,
    "أولنحوي": 1,
    "أولنفس": 1,
    "أولنفسك": 1,
    "أولنفسكم": 1,
    "أولنفسكما": 1,
    "أولنفسكن": 1,
    "أولنفسنا": 1,
    "أولنفسه": 1,
    "أولنفسها": 1,
    "أولنفسهم": 1,
    "أولنفسهما": 1,
    "أولنفسهن": 1,
    "أولنفسي": 1,
    "أوله": 1,
    "أولهؤلاء": 1,
    "أولها": 1,
    "أولهاته": 1,
    "أولهاتي": 1,
    "أولهاتين": 1,
    "أولهذا": 1,
    "أولهذه": 1,
    "أولهم": 1,
    "أولهما": 1,
    "أولهن": 1,
    "أولهناك": 1,
    "أولهنالك": 1,
    "أولهو": 1,
    "أولهي": 1,
    "أوليسا": 1,
    "أوليست": 1,
    "أوليستا": 1,
    "أوليسوا": 1,
    "أوليمين": 1,
    "أوليمينك": 1,
    "أوليمينكم": 1,
    "أوليمينكما": 1,
    "أوليمينكن": 1,
    "أوليميننا": 1,
    "أوليمينه": 1,
    "أوليمينها": 1,
    "أوليمينهم": 1,
    "أوليمينهما": 1,
    "أوليمينهن": 1,
    "أوليميني": 1,
    "أوماذا": 1,
    "أومثل": 1,
    "أومثلك": 1,
    "أومثلكم": 1,
    "أومثلكما": 1,
    "أومثلكن": 1,
    "أومثلنا": 1,
    "أومثله": 1,
    "أومثلها": 1,
    "أومثلهم": 1,
    "أومثلهما": 1,
    "أومثلهن": 1,
    "أومثلي": 1,
    "أومع": 1,
    "أومعك": 1,
    "أومعكم": 1,
    "أومعكما": 1,
    "أومعكن": 1,
    "أومعنا": 1,
    "أومعه": 1,
    "أومعها": 1,
    "أومعهم": 1,
    "أومعهما": 1,
    "أومعهن": 1,
    "أومعي": 1,
    "أومما": 1,
    "أومنك": 1,
    "أومنكم": 1,
    "أومنكما": 1,
    "أومنكن": 1,
    "أومننا": 1,
    "أومنه": 1,
    "أومنها": 1,
    "أومنهم": 1,
    "أومنهما": 1,
    "أومنهن": 1,
    "أومهما": 1,
    "أونحن": 1,
    "أونحو": 1,
    "أونحوك": 1,
    "أونحوكم": 1,
    "أونحوكما": 1,
    "أونحوكن": 1,
    "أونحونا": 1,
    "أونحوه": 1,
    "أونحوها": 1,
    "أونحوهم": 1,
    "أونحوهما": 1,
    "أونحوهن": 1,
    "أونحوي": 1,
    "أونفس": 1,
    "أونفسك": 1,
    "أونفسكم": 1,
    "أونفسكما": 1,
    "أونفسكن": 1,
    "أونفسنا": 1,
    "أونفسه": 1,
    "أونفسها": 1,
    "أونفسهم": 1,
    "أونفسهما": 1,
    "أونفسهن": 1,
    "أونفسي": 1,
    "أوهؤلاء": 1,
    "أوهاتان": 1,
    "أوهاته": 1,
    "أوهاتي": 1,
    "أوهاتين": 1,
    "أوهاهنا": 1,
    "أوهذا": 1,
    "أوهذان": 1,
    "أوهذه": 1,
    "أوهكذا": 1,
    "أوهم": 1,
    "أوهما": 1,
    "أوهنا": 1,
    "أوهناك": 1,
    "أوهنالك": 1,
    "أوهو": 1,
    "أوهي": 1,
    "أويمين": 1,
    "أويمينك": 1,
    "أويمينكم": 1,
    "أويمينكما": 1,
    "أويمينكن": 1,
    "أويميننا": 1,
    "أويمينه": 1,
    "أويمينها": 1,
    "أويمينهم": 1,
    "أويمينهما": 1,
    "أويمينهن": 1,
    "أويميني": 1,
    "أيك": 1,
    "أيكما": 1,
    "أيكن": 1,
    "أيمين": 1,
    "أيمينك": 1,
    "أيمينكم": 1,
    "أيمينكما": 1,
    "أيمينكن": 1,
    "أيميننا": 1,
    "أيمينه": 1,
    "أيمينها": 1,
    "أيمينهم": 1,
    "أيمينهما": 1,
    "أيمينهن": 1,
    "أيميني": 1,
    "أيهما": 1,
    "أيهن": 1,
    "أيي": 1,
    "إذما": 1,
    "إذن": 1,
    "إزاء": 1,
    "إليكن": 1,
    "إليهما": 1,
    "إنكما": 1,
    "إنكن": 1,
    "إياكما": 1,
    "إياكن": 1,
    "إياها": 1,
    "إياهم": 1,
    "إياهما": 1,
    "إياهن": 1,
    "إياي": 1,
    "ابتدأ": 1,
    "اخلولق": 1,
    "استحال": 1,
    "الألاء": 1,
    "الألى": 1,
    "البأب": 1,
    "البأخ": 1,
    "البحم": 1,
    "البفو": 1,
    "الحم": 1,
    "الفأب": 1,
    "الفأخ": 1,
    "الفبأب": 1,
    "الفبأخ": 1,
    "الفبحم": 1,
    "الفبفو": 1,
    "الففو": 1,
    "الفكأب": 1,
    "الفكأخ": 1,
    "الفكحم": 1,
    "الفكفو": 1,
    "الفلأب": 1,
    "الفلأخ": 1,
    "الفلحم": 1,
    "الفلفو": 1,
    "الفو": 1,
    "الكأب": 1,
    "الكأخ": 1,
    "الكحم": 1,
    "الكفو": 1,
    "اللأب": 1,
    "اللأخ": 1,
    "اللتان": 1,
    "اللتيا": 1,
    "اللتين": 1,
    "اللذان": 1,
    "اللفو": 1,
    "اللواتي": 1,
    "الوأب": 1,
    "الوأخ": 1,
    "الوبأب": 1,
    "الوبأخ": 1,
    "الوبحم": 1,
    "الوبفو": 1,
    "الوحم": 1,
    "الوفو": 1,
    "الوكأب": 1,
    "الوكأخ": 1,
    "الوكحم": 1,
    "الوكفو": 1,
    "الولأب": 1,
    "الولأخ": 1,
    "الولحم": 1,
    "الولفو": 1,
    "انبرى": 1,
    "بأب": 1,
    "بأبك": 1,
    "بأبكم": 1,
    "بأبكما": 1,
    "بأبكن": 1,
    "بأبنا": 1,
    "بأبه": 1,
    "بأبها": 1,
    "بأبهم": 1,
    "بأبهما": 1,
    "بأبهن": 1,
    "بأبي": 1,
    "بأجمع": 1,
    "بأجمعك": 1,
    "بأجمعكم": 1,
    "بأجمعكما": 1,
    "بأجمعكن": 1,
    "بأجمعنا": 1,
    "بأجمعه": 1,
    "بأجمعها": 1,
    "بأجمعهم": 1,
    "بأجمعهما": 1,
    "بأجمعهن": 1,
    "بأجمعي": 1,
    "بأخك": 1,
    "بأخكم": 1,
    "بأخكما": 1,
    "بأخكن": 1,
    "بأخنا": 1,
    "بأخه": 1,
    "بأخها": 1,
    "بأخهم": 1,
    "بأخهما": 1,
    "بأخهن": 1,
    "بأخي": 1,
    "بأقل": 1,
    "بأقلك": 1,
    "بأقلكم": 1,
    "بأقلكما": 1,
    "بأقلكن": 1,
    "بأقلنا": 1,
    "بأقله": 1,
    "بأقلها": 1,
    "بأقلهم": 1,
    "بأقلهما": 1,
    "بأقلهن": 1,
    "بأقلي": 1,
    "بأكثرك": 1,
    "بأكثركم": 1,
    "بأكثركما": 1,
    "بأكثركن": 1,
    "بأكثرنا": 1,
    "بأكثره": 1,
    "بأكثرها": 1,
    "بأكثرهم": 1,
    "بأكثرهما": 1,
    "بأكثرهن": 1,
    "بأكثري": 1,
    "بأنك": 1,
    "بأنكما": 1,
    "بأنكن": 1,
    "بأنها": 1,
    "بأنهما": 1,
    "بأنهن": 1,
    "بأني": 1,
    "بأولئك": 1,
    "بأولئكم": 1,
    "بأولاء": 1,
    "بأولالك": 1,
    "بأيك": 1,
    "بأيكم": 1,
    "بأيكما": 1,
    "بأيكن": 1,
    "بأينا": 1,
    "بأيه": 1,
    "بأيها": 1,
    "بأيهم": 1,
    "بأيهما": 1,
    "بأيهن": 1,
    "بأيي": 1,
    "بإن": 1,
    "بإنك": 1,
    "بإنكم": 1,
    "بإنكما": 1,
    "بإنكن": 1,
    "بإننا": 1,
    "بإنه": 1,
    "بإنها": 1,
    "بإنهم": 1,
    "بإنهما": 1,
    "بإنهن": 1,
    "بإني": 1,
    "بإياك": 1,
    "بإياكم": 1,
    "بإياكما": 1,
    "بإياكن": 1,
    "بإيانا": 1,
    "بإياه": 1,
    "بإياها": 1,
    "بإياهم": 1,
    "بإياهما": 1,
    "بإياهن": 1,
    "بإياي": 1,
    "بالألاء": 1,
    "بالألى": 1,
    "باللائي": 1,
    "باللاتي": 1,
    "باللتيا": 1,
    "باللتين": 1,
    "باللذين": 1,
    "باللواتي": 1,
    "ببعضك": 1,
    "ببعضكم": 1,
    "ببعضكما": 1,
    "ببعضكن": 1,
    "ببعضنا": 1,
    "ببعضه": 1,
    "ببعضهم": 1,
    "ببعضهما": 1,
    "ببعضهن": 1,
    "ببعضي": 1,
    "بتانك": 1,
    "بتجاه": 1,
    "بتجاهك": 1,
    "بتجاهكم": 1,
    "بتجاهكما": 1,
    "بتجاهكن": 1,
    "بتجاهنا": 1,
    "بتجاهه": 1,
    "بتجاهها": 1,
    "بتجاههم": 1,
    "بتجاههما": 1,
    "بتجاههن": 1,
    "بتجاهي": 1,
    "بتلقاء": 1,
    "بتلقاءك": 1,
    "بتلقاءكم": 1,
    "بتلقاءكما": 1,
    "بتلقاءكن": 1,
    "بتلقاءنا": 1,
    "بتلقاءه": 1,
    "بتلقاءها": 1,
    "بتلقاءهم": 1,
    "بتلقاءهما": 1,
    "بتلقاءهن": 1,
    "بتلقاءي": 1,
    "بتلك": 1,
    "بتلكم": 1,
    "بتلكما": 1,
    "بته": 1,
    "بتي": 1,
    "بتين": 1,
    "بتينك": 1,
    "بجميع": 1,
    "بجميعك": 1,
    "بجميعكم": 1,
    "بجميعكما": 1,
    "بجميعكن": 1,
    "بجميعنا": 1,
    "بجميعه": 1,
    "بجميعها": 1,
    "بجميعهم": 1,
    "بجميعهما": 1,
    "بجميعهن": 1,
    "بجميعي": 1,
    "بحسب": 1,
    "بحسبك": 1,
    "بحسبكم": 1,
    "بحسبكما": 1,
    "بحسبكن": 1,
    "بحسبنا": 1,
    "بحسبه": 1,
    "بحسبها": 1,
    "بحسبهم": 1,
    "بحسبهما": 1,
    "بحسبهن": 1,
    "بحسبي": 1,
    "بحم": 1,
    "بحمك": 1,
    "بحمكم": 1,
    "بحمكما": 1,
    "بحمكن": 1,
    "بحمنا": 1,
    "بحمه": 1,
    "بحمها": 1,
    "بحمهم": 1,
    "بحمهما": 1,
    "بحمهن": 1,
    "بحمي": 1,
    "بخ": 1,
    "بذا": 1,
    "بذاك": 1,
    "بذانك": 1,
    "بذلكم": 1,
    "بذلكما": 1,
    "بذلكن": 1,
    "بذه": 1,
    "بذواتي": 1,
    "بذي": 1,
    "بذين": 1,
    "بذينك": 1,
    "بسوى": 1,
    "بسوي": 1,
    "بسويك": 1,
    "بسويكم": 1,
    "بسويكما": 1,
    "بسويكن": 1,
    "بسوينا": 1,
    "بسويه": 1,
    "بسويها": 1,
    "بسويهم": 1,
    "بسويهما": 1,
    "بسويهن": 1,
    "بشبه": 1,
    "بشبهك": 1,
    "بشبهكم": 1,
    "بشبهكما": 1,
    "بشبهكن": 1,
    "بشبهنا": 1,
    "بشبهه": 1,
    "بشبهها": 1,
    "بشبههم": 1,
    "بشبههما": 1,
    "بشبههن": 1,
    "بشبهي": 1,
    "بضعك": 1,
    "بضعكم": 1,
    "بضعكما": 1,
    "بضعكن": 1,
    "بضعنا": 1,
    "بضعه": 1,
    "بضعها": 1,
    "بضعهم": 1,
    "بضعهما": 1,
    "بضعهن": 1,
    "بضعي": 1,
    "بطآن": 1,
    "بعامة": 1,
    "بعامتك": 1,
    "بعامتكم": 1,
    "بعامتكما": 1,
    "بعامتكن": 1,
    "بعامتنا": 1,
    "بعامته": 1,
    "بعامتها": 1,
    "بعامتهم": 1,
    "بعامتهما": 1,
    "بعامتهن": 1,
    "بعامتي": 1,
    "بعدكما": 1,
    "بعدكن": 1,
    "بعدنا": 1,
    "بعدهما": 1,
    "بعضك": 1,
    "بعضكما": 1,
    "بعضكن": 1,
    "بعضهما": 1,
    "بعضهن": 1,
    "بعضي": 1,
    "بعين": 1,
    "بعينك": 1,
    "بعينكم": 1,
    "بعينكما": 1,
    "بعينكن": 1,
    "بعيننا": 1,
    "بعينه": 1,
    "بعينها": 1,
    "بعينهم": 1,
    "بعينهما": 1,
    "بعينهن": 1,
    "بعيني": 1,
    "بغيرك": 1,
    "بغيركم": 1,
    "بغيركما": 1,
    "بغيركن": 1,
    "بغيرنا": 1,
    "بغيره": 1,
    "بغيرها": 1,
    "بغيرهم": 1,
    "بغيرهما": 1,
    "بغيرهن": 1,
    "بغيري": 1,
    "بفو": 1,
    "بفوك": 1,
    "بفوكم": 1,
    "بفوكما": 1,
    "بفوكن": 1,
    "بفونا": 1,
    "بفوه": 1,
    "بفوها": 1,
    "بفوهم": 1,
    "بفوهما": 1,
    "بفوهن": 1,
    "بفوي": 1,
    "بكلك": 1,
    "بكلكم": 1,
    "بكلكما": 1,
    "بكلكن": 1,
    "بكلنا": 1,
    "بكله": 1,
    "بكلها": 1,
    "بكلهم": 1,
    "بكلهما": 1,
    "بكلهن": 1,
    "بكليكما": 1,
    "بكليهما": 1,
    "بكما": 1,
    "بكن": 1,
    "بله": 1,
    "بماذا": 1,
    "بمثلك": 1,
    "بمثلكم": 1,
    "بمثلكما": 1,
    "بمثلكن": 1,
    "بمثلنا": 1,
    "بمثلهم": 1,
    "بمثلهما": 1,
    "بمثلهن": 1,
    "بمثلي": 1,
    "بنحو": 1,
    "بنحوك": 1,
    "بنحوكم": 1,
    "بنحوكما": 1,
    "بنحوكن": 1,
    "بنحونا": 1,
    "بنحوه": 1,
    "بنحوها": 1,
    "بنحوهم": 1,
    "بنحوهما": 1,
    "بنحوهن": 1,
    "بنحوي": 1,
    "بنفسكم": 1,
    "بنفسكما": 1,
    "بنفسكن": 1,
    "بنفسنا": 1,
    "بنفسه": 1,
    "بنفسها": 1,
    "بنفسهم": 1,
    "بنفسهما": 1,
    "بنفسهن": 1,
    "بهؤلاء": 1,
    "بهاته": 1,
    "بهاتي": 1,
    "بهاتين": 1,
    "بهذه": 1,
    "بهذي": 1,
    "بهذين": 1,
    "بهناك": 1,
    "بهنالك": 1,
    "بهو": 1,
    "بهي": 1,
    "بينكما": 1,
    "بينكن": 1,
    "تانك": 1,
    "تجاه": 1,
    "تجاهك": 1,
    "تجاهكم": 1,
    "تجاهكما": 1,
    "تجاهكن": 1,
    "تجاهنا": 1,
    "تجاهه": 1,
    "تجاهها": 1,
    "تجاههم": 1,
    "تجاههما": 1,
    "تجاههن": 1,
    "تجاهي": 1,
    "تحتكم": 1,
    "تحتكما": 1,
    "تحتكن": 1,
    "تحتنا": 1,
    "تحتهما": 1,
    "تحتهن": 1,
    "تلقاءك": 1,
    "تلقاءكم": 1,
    "تلقاءكما": 1,
    "تلقاءكن": 1,
    "تلقاءنا": 1,
    "تلقاءه": 1,
    "تلقاءها": 1,
    "تلقاءهم": 1,
    "تلقاءهما": 1,
    "تلقاءهن": 1,
    "تلقاءي": 1,
    "تين": 1,
    "تينك": 1,
    "جلل": 1,
    "جميعك": 1,
    "جميعكم": 1,
    "جميعكما": 1,
    "جميعكن": 1,
    "جميعنا": 1,
    "جميعه": 1,
    "جميعها": 1,
    "جميعهم": 1,
    "جميعهما": 1,
    "جميعهن": 1,
    "جميعي": 1,
    "حاشا": 1,
    "حاشاك": 1,
    "حاشاكم": 1,
    "حاشاكما": 1,
    "حاشاكن": 1,
    "حاشانا": 1,
    "حاشاه": 1,
    "حاشاها": 1,
    "حاشاهم": 1,
    "حاشاهما": 1,
    "حاشاهن": 1,
    "حاشاي": 1,
    "حاي": 1,
    "حبذا": 1,
    "حذار": 1,
    "حرى": 1,
    "حسبكم": 1,
    "حسبكما": 1,
    "حسبكن": 1,
    "حسبها": 1,
    "حسبهما": 1,
    "حسبهن": 1,
    "حمك": 1,
    "حمكم": 1,
    "حمكما": 1,
    "حمكن": 1,
    "حمنا": 1,
    "حمه": 1,
    "حمها": 1,
    "حمهم": 1,
    "حمهما": 1,
    "حمهن": 1,
    "حوالى": 1,
    "حواليك": 1,
    "حواليكم": 1,
    "حواليكما": 1,
    "حواليكن": 1,
    "حوالينا": 1,
    "حواليه": 1,
    "حواليها": 1,
    "حواليهم": 1,
    "حواليهما": 1,
    "حواليهن": 1,
    "حولكما": 1,
    "حولكن": 1,
    "حولنا": 1,
    "حولهما": 1,
    "حولهن": 1,
    "حولي": 1,
    "حيثما": 1,
    "حينك": 1,
    "حينكم": 1,
    "حينكما": 1,
    "حينكن": 1,
    "حيننا": 1,
    "حينه": 1,
    "حينها": 1,
    "حينهم": 1,
    "حينهما": 1,
    "حينهن": 1,
    "حيني": 1,
    "خلاك": 1,
    "خلاكم": 1,
    "خلاكما": 1,
    "خلاكن": 1,
    "خلالك": 1,
    "خلالكما": 1,
    "خلالكن": 1,
    "خلالنا": 1,
    "خلالهم": 1,
    "خلالهن": 1,
    "خلالي": 1,
    "خلانا": 1,
    "خلاه": 1,
    "خلاها": 1,
    "خلاهم": 1,
    "خلاهما": 1,
    "خلاهن": 1,
    "خلاي": 1,
    "خلفكما": 1,
    "خلفكن": 1,
    "خلفهما": 1,
    "خلفهن": 1,
    "ذاك": 1,
    "ذان": 1,
    "ذانك": 1,
    "ذلكن": 1,
    "ذه": 1,
    "ذيت": 1,
    "ذينك": 1,
    "رويدك": 1,
    "ريث": 1,
    "ساءما": 1,
    "سبحانكم": 1,
    "سبحانكما": 1,
    "سبحانكن": 1,
    "سبحاننا": 1,
    "سبحانها": 1,
    "سبحانهم": 1,
    "سبحانهما": 1,
    "سبحانهن": 1,
    "سبحاني": 1,
    "سرعان": 1,
    "سويك": 1,
    "سويكم": 1,
    "سويكما": 1,
    "سويكن": 1,
    "سوينا": 1,
    "سويه": 1,
    "سويها": 1,
    "سويهم": 1,
    "سويهما": 1,
    "سويهن": 1,
    "شبهك": 1,
    "شبهكم": 1,
    "شبهكما": 1,
    "شبهكن": 1,
    "شبهنا": 1,
    "شبهه": 1,
    "شبهها": 1,
    "شبههم": 1,
    "شبههما": 1,
    "شبههن": 1,
    "شبهي": 1,
    "شتان": 1,
    "شمالك": 1,
    "شمالكم": 1,
    "شمالكما": 1,
    "شمالكن": 1,
    "شمالنا": 1,
    "شمالها": 1,
    "شمالهم": 1,
    "شمالهما": 1,
    "شمالهن": 1,
    "صه": 1,
    "ضحوة": 1,
    "ضمنك": 1,
    "ضمنكم": 1,
    "ضمنكما": 1,
    "ضمنكن": 1,
    "ضمننا": 1,
    "ضمنه": 1,
    "ضمنها": 1,
    "ضمنهم": 1,
    "ضمنهما": 1,
    "ضمنهن": 1,
    "طاق": 1,
    "طالما": 1,
    "طفق": 1,
    "طق": 1,
    "عامتك": 1,
    "عامتكم": 1,
    "عامتكما": 1,
    "عامتكن": 1,
    "عامتنا": 1,
    "عامته": 1,
    "عامتها": 1,
    "عامتهم": 1,
    "عامتهما": 1,
    "عامتهن": 1,
    "عامتي": 1,
    "عداك": 1,
    "عداكم": 1,
    "عداكما": 1,
    "عداكن": 1,
    "عدانا": 1,
    "عداه": 1,
    "عداها": 1,
    "عداهم": 1,
    "عداهما": 1,
    "عداهن": 1,
    "عداي": 1,
    "عدس": 1,
    "عل": 1,
    "عليكن": 1,
    "عندكما": 1,
    "عندكن": 1,
    "عندهما": 1,
    "عندهن": 1,
    "عنكما": 1,
    "عنكن": 1,
    "عننا": 1,
    "عنهن": 1,
    "عينك": 1,
    "عينكم": 1,
    "عينكما": 1,
    "عينكن": 1,
    "عيننا": 1,
    "عينه": 1,
    "عينهم": 1,
    "عينهما": 1,
    "عينهن": 1,
    "غداة": 1,
    "غيرك": 1,
    "غيركما": 1,
    "غيركن": 1,
    "غيرنا": 1,
    "غيرهم": 1,
    "غيرهما": 1,
    "غيرهن": 1,
    "فآض": 1,
    "فآمين": 1,
    "فآناء": 1,
    "فآنفا": 1,
    "فآه": 1,
    "فأب": 1,
    "فأبدا": 1,
    "فأبك": 1,
    "فأبكم": 1,
    "فأبكما": 1,
    "فأبكن": 1,
    "فأبنا": 1,
    "فأبه": 1,
    "فأبها": 1,
    "فأبهم": 1,
    "فأبهما": 1,
    "فأبهن": 1,
    "فأبي": 1,
    "فأجمع": 1,
    "فأجمعك": 1,
    "فأجمعكم": 1,
    "فأجمعكما": 1,
    "فأجمعكن": 1,
    "فأجمعنا": 1,
    "فأجمعه": 1,
    "فأجمعها": 1,
    "فأجمعهم": 1,
    "فأجمعهما": 1,
    "فأجمعهن": 1,
    "فأجمعي": 1,
    "فأخ": 1,
    "فأخذ": 1,
    "فأخك": 1,
    "فأخكم": 1,
    "فأخكما": 1,
    "فأخكن": 1,
    "فأخنا": 1,
    "فأخه": 1,
    "فأخها": 1,
    "فأخهم": 1,
    "فأخهما": 1,
    "فأخهن": 1,
    "فأخي": 1,
    "فأصلا": 1,
    "فأضحى": 1,
    "فأف": 1,
    "فأقل": 1,
    "فأقلك": 1,
    "فأقلكم": 1,
    "فأقلكما": 1,
    "فأقلكن": 1,
    "فأقلنا": 1,
    "فأقله": 1,
    "فأقلها": 1,
    "فأقلهم": 1,
    "فأقلهما": 1,
    "فأقلهن": 1,
    "فأقلي": 1,
    "فأكثر": 1,
    "فأكثرك": 1,
    "فأكثركم": 1,
    "فأكثركما": 1,
    "فأكثركن": 1,
    "فأكثرنا": 1,
    "فأكثره": 1,
    "فأكثرها": 1,
    "فأكثرهم": 1,
    "فأكثرهما": 1,
    "فأكثرهن": 1,
    "فأكثري": 1,
    "فألا": 1,
    "فأمام": 1,
    "فأمامك": 1,
    "فأمامكم": 1,
    "فأمامكما": 1,
    "فأمامكن": 1,
    "فأمامنا": 1,
    "فأمامه": 1,
    "فأمامها": 1,
    "فأمامهم": 1,
    "فأمامهما": 1,
    "فأمامهن": 1,
    "فأمامي": 1,
    "فأمد": 1,
    "فأمس": 1,
    "فأمسى": 1,
    "فأنتما": 1,
    "فأنتن": 1,
    "فأنشأ": 1,
    "فأنك": 1,
    "فأنكم": 1,
    "فأنكما": 1,
    "فأنكن": 1,
    "فأننا": 1,
    "فأنها": 1,
    "فأنهم": 1,
    "فأنهما": 1,
    "فأنهن": 1,
    "فأني": 1,
    "فأوشك": 1,
    "فأول": 1,
    "فأولئكم": 1,
    "فأولاء": 1,
    "فأولالك": 1,
    "فأوه": 1,
    "فأيا": 1,
    "فأيان": 1,
    "فأيك": 1,
    "فأيكم": 1,
    "فأيكما": 1,
    "فأيكن": 1,
    "فأينا": 1,
    "فأيه": 1,
    "فأيها": 1,
    "فأيهم": 1,
    "فأيهما": 1,
    "فأيهن": 1,
    "فأيي": 1,
    "فإذما": 1,
    "فإذن": 1,
    "فإزاء": 1,
    "فإلا": 1,
    "فإلى": 1,
    "فإلي": 1,
    "فإليك": 1,
    "فإليكم": 1,
    "فإليكما": 1,
    "فإليكن": 1,
    "فإليها": 1,
    "فإليهم": 1,
    "فإليهما": 1,
    "فإليهن": 1,
    "فإنكما": 1,
    "فإنكن": 1,
    "فإننا": 1,
    "فإنهما": 1,
    "فإنهن": 1,
    "فإي": 1,
    "فإياك": 1,
    "فإياكم": 1,
    "فإياكما": 1,
    "فإياكن": 1,
    "فإيانا": 1,
    "فإياه": 1,
    "فإياها": 1,
    "فإياهم": 1,
    "فإياهما": 1,
    "فإياهن": 1,
    "فإيه": 1,
    "فابتدأ": 1,
    "فاخلولق": 1,
    "فاستحال": 1,
    "فالألاء": 1,
    "فالألى": 1,
    "فالتي": 1,
    "فالذي": 1,
    "فاللائي": 1,
    "فاللاتي": 1,
    "فاللتان": 1,
    "فاللتيا": 1,
    "فاللتين": 1,
    "فاللذان": 1,
    "فاللذين": 1,
    "فاللواتي": 1,
    "فانبرى": 1,
    "فانقلب": 1,
    "فبأب": 1,
    "فبأبك": 1,
    "فبأبكم": 1,
    "فبأبكما": 1,
    "فبأبكن": 1,
    "فبأبنا": 1,
    "فبأبه": 1,
    "فبأبها": 1,
    "فبأبهم": 1,
    "فبأبهما": 1,
    "فبأبهن": 1,
    "فبأبي": 1,
    "فبأجمع": 1,
    "فبأجمعك": 1,
    "فبأجمعكم": 1,
    "فبأجمعكما": 1,
    "فبأجمعكن": 1,
    "فبأجمعنا": 1,
    "فبأجمعه": 1,
    "فبأجمعها": 1,
    "فبأجمعهم": 1,
    "فبأجمعهما": 1,
    "فبأجمعهن": 1,
    "فبأجمعي": 1,
    "فبأخ": 1,
    "فبأخك": 1,
    "فبأخكم": 1,
    "فبأخكما": 1,
    "فبأخكن": 1,
    "فبأخنا": 1,
    "فبأخه": 1,
    "فبأخها": 1,
    "فبأخهم": 1,
    "فبأخهما": 1,
    "فبأخهن": 1,
    "فبأخي": 1,
    "فبأقل": 1,
    "فبأقلك": 1,
    "فبأقلكم": 1,
    "فبأقلكما": 1,
    "فبأقلكن": 1,
    "فبأقلنا": 1,
    "فبأقله": 1,
    "فبأقلها": 1,
    "فبأقلهم": 1,
    "فبأقلهما": 1,
    "فبأقلهن": 1,
    "فبأقلي": 1,
    "فبأكثر": 1,
    "فبأكثرك": 1,
    "فبأكثركم": 1,
    "فبأكثركما": 1,
    "فبأكثركن": 1,
    "فبأكثرنا": 1,
    "فبأكثره": 1,
    "فبأكثرها": 1,
    "فبأكثرهم": 1,
    "فبأكثرهما": 1,
    "فبأكثرهن": 1,
    "فبأكثري": 1,
    "فبأن": 1,
    "فبأنك": 1,
    "فبأنكم": 1,
    "فبأنكما": 1,
    "فبأنكن": 1,
    "فبأننا": 1,
    "فبأنه": 1,
    "فبأنها": 1,
    "فبأنهم": 1,
    "فبأنهما": 1,
    "فبأنهن": 1,
    "فبأني": 1,
    "فبأولئك": 1,
    "فبأولئكم": 1,
    "فبأولاء": 1,
    "فبأولالك": 1,
    "فبأيك": 1,
    "فبأيكم": 1,
    "فبأيكما": 1,
    "فبأيكن": 1,
    "فبأينا": 1,
    "فبأيه": 1,
    "فبأيها": 1,
    "فبأيهم": 1,
    "فبأيهما": 1,
    "فبأيهن": 1,
    "فبأيي": 1,
    "فبإن": 1,
    "فبإنك": 1,
    "فبإنكم": 1,
    "فبإنكما": 1,
    "فبإنكن": 1,
    "فبإننا": 1,
    "فبإنه": 1,
    "فبإنها": 1,
    "فبإنهم": 1,
    "فبإنهما": 1,
    "فبإنهن": 1,
    "فبإني": 1,
    "فبإياك": 1,
    "فبإياكم": 1,
    "فبإياكما": 1,
    "فبإياكن": 1,
    "فبإيانا": 1,
    "فبإياه": 1,
    "فبإياها": 1,
    "فبإياهم": 1,
    "فبإياهما": 1,
    "فبإياهن": 1,
    "فبإياي": 1,
    "فبات": 1,
    "فبالألاء": 1,
    "فبالألى": 1,
    "فبالتي": 1,
    "فبالذي": 1,
    "فبالذين": 1,
    "فباللائي": 1,
    "فباللاتي": 1,
    "فباللتيا": 1,
    "فباللتين": 1,
    "فباللذين": 1,
    "فباللواتي": 1,
    "فببعض": 1,
    "فببعضك": 1,
    "فببعضكم": 1,
    "فببعضكما": 1,
    "فببعضكن": 1,
    "فببعضنا": 1,
    "فببعضه": 1,
    "فببعضها": 1,
    "فببعضهم": 1,
    "فببعضهما": 1,
    "فببعضهن": 1,
    "فببعضي": 1,
    "فبتانك": 1,
    "فبتجاه": 1,
    "فبتجاهك": 1,
    "فبتجاهكم": 1,
    "فبتجاهكما": 1,
    "فبتجاهكن": 1,
    "فبتجاهنا": 1,
    "فبتجاهه": 1,
    "فبتجاهها": 1,
    "فبتجاههم": 1,
    "فبتجاههما": 1,
    "فبتجاههن": 1,
    "فبتجاهي": 1,
    "فبتلقاء": 1,
    "فبتلقاءك": 1,
    "فبتلقاءكم": 1,
    "فبتلقاءكما": 1,
    "فبتلقاءكن": 1,
    "فبتلقاءنا": 1,
    "فبتلقاءه": 1,
    "فبتلقاءها": 1,
    "فبتلقاءهم": 1,
    "فبتلقاءهما": 1,
    "فبتلقاءهن": 1,
    "فبتلقاءي": 1,
    "فبتلك": 1,
    "فبتلكم": 1,
    "فبتلكما": 1,
    "فبته": 1,
    "فبتي": 1,
    "فبتين": 1,
    "فبتينك": 1,
    "فبجميع": 1,
    "فبجميعك": 1,
    "فبجميعكم": 1,
    "فبجميعكما": 1,
    "فبجميعكن": 1,
    "فبجميعنا": 1,
    "فبجميعه": 1,
    "فبجميعها": 1,
    "فبجميعهم": 1,
    "فبجميعهما": 1,
    "فبجميعهن": 1,
    "فبجميعي": 1,
    "فبحسب": 1,
    "فبحسبك": 1,
    "فبحسبكم": 1,
    "فبحسبكما": 1,
    "فبحسبكن": 1,
    "فبحسبنا": 1,
    "فبحسبه": 1,
    "فبحسبها": 1,
    "فبحسبهم": 1,
    "فبحسبهما": 1,
    "فبحسبهن": 1,
    "فبحسبي": 1,
    "فبحم": 1,
    "فبحمك": 1,
    "فبحمكم": 1,
    "فبحمكما": 1,
    "فبحمكن": 1,
    "فبحمنا": 1,
    "فبحمه": 1,
    "فبحمها": 1,
    "فبحمهم": 1,
    "فبحمهما": 1,
    "فبحمهن": 1,
    "فبحمي": 1,
    "فبحيث": 1,
    "فبخ": 1,
    "فبذا": 1,
    "فبذات": 1,
    "فبذاك": 1,
    "فبذانك": 1,
    "فبذلكم": 1,
    "فبذلكما": 1,
    "فبذلكن": 1,
    "فبذه": 1,
    "فبذواتي": 1,
    "فبذي": 1,
    "فبذين": 1,
    "فبذينك": 1,
    "فبس": 1,
    "فبسوى": 1,
    "فبسوي": 1,
    "فبسويك": 1,
    "فبسويكم": 1,
    "فبسويكما": 1,
    "فبسويكن": 1,
    "فبسوينا": 1,
    "فبسويه": 1,
    "فبسويها": 1,
    "فبسويهم": 1,
    "فبسويهما": 1,
    "فبسويهن": 1,
    "فبشبه": 1,
    "فبشبهك": 1,
    "فبشبهكم": 1,
    "فبشبهكما": 1,
    "فبشبهكن": 1,
    "فبشبهنا": 1,
    "فبشبهه": 1,
    "فبشبهها": 1,
    "فبشبههم": 1,
    "فبشبههما": 1,
    "فبشبههن": 1,
    "فبشبهي": 1,
    "فبضع": 1,
    "فبضعك": 1,
    "فبضعكم": 1,
    "فبضعكما": 1,
    "فبضعكن": 1,
    "فبضعنا": 1,
    "فبضعه": 1,
    "فبضعها": 1,
    "فبضعهم": 1,
    "فبضعهما": 1,
    "فبضعهن": 1,
    "فبضعي": 1,
    "فبطآن": 1,
    "فبعامة": 1,
    "فبعامتك": 1,
    "فبعامتكم": 1,
    "فبعامتكما": 1,
    "فبعامتكن": 1,
    "فبعامتنا": 1,
    "فبعامته": 1,
    "فبعامتها": 1,
    "فبعامتهم": 1,
    "فبعامتهما": 1,
    "فبعامتهن": 1,
    "فبعامتي": 1,
    "فبعد": 1,
    "فبعدك": 1,
    "فبعدكم": 1,
    "فبعدكما": 1,
    "فبعدكن": 1,
    "فبعدنا": 1,
    "فبعده": 1,
    "فبعدها": 1,
    "فبعدهم": 1,
    "فبعدهما": 1,
    "فبعدهن": 1,
    "فبعدي": 1,
    "فبعض": 1,
    "فبعضك": 1,
    "فبعضكم": 1,
    "فبعضكما": 1,
    "فبعضكن": 1,
    "فبعضنا": 1,
    "فبعضه": 1,
    "فبعضها": 1,
    "فبعضهم": 1,
    "فبعضهما": 1,
    "فبعضهن": 1,
    "فبعضي": 1,
    "فبعين": 1,
    "فبعينك": 1,
    "فبعينكم": 1,
    "فبعينكما": 1,
    "فبعينكن": 1,
    "فبعيننا": 1,
    "فبعينه": 1,
    "فبعينها": 1,
    "فبعينهم": 1,
    "فبعينهما": 1,
    "فبعينهن": 1,
    "فبعيني": 1,
    "فبغير": 1,
    "فبغيرك": 1,
    "فبغيركم": 1,
    "فبغيركما": 1,
    "فبغيركن": 1,
    "فبغيرنا": 1,
    "فبغيره": 1,
    "فبغيرها": 1,
    "فبغيرهم": 1,
    "فبغيرهما": 1,
    "فبغيرهن": 1,
    "فبغيري": 1,
    "فبفو": 1,
    "فبفوك": 1,
    "فبفوكم": 1,
    "فبفوكما": 1,
    "فبفوكن": 1,
    "فبفونا": 1,
    "فبفوه": 1,
    "فبفوها": 1,
    "فبفوهم": 1,
    "فبفوهما": 1,
    "فبفوهن": 1,
    "فبفوي": 1,
    "فبك": 1,
    "فبكل": 1,
    "فبكلتا": 1,
    "فبكلك": 1,
    "فبكلكم": 1,
    "فبكلكما": 1,
    "فبكلكن": 1,
    "فبكلنا": 1,
    "فبكله": 1,
    "فبكلها": 1,
    "فبكلهم": 1,
    "فبكلهما": 1,
    "فبكلهن": 1,
    "فبكلي": 1,
    "فبكليكما": 1,
    "فبكليهما": 1,
    "فبكم": 1,
    "فبكما": 1,
    "فبكن": 1,
    "فبله": 1,
    "فبماذا": 1,
    "فبمثل": 1,
    "فبمثلك": 1,
    "فبمثلكم": 1,
    "فبمثلكما": 1,
    "فبمثلكن": 1,
    "فبمثلنا": 1,
    "فبمثله": 1,
    "فبمثلها": 1,
    "فبمثلهم": 1,
    "فبمثلهما": 1,
    "فبمثلهن": 1,
    "فبمثلي": 1,
    "فبمن": 1,
    "فبنا": 1,
    "فبنحو": 1,
    "فبنحوك": 1,
    "فبنحوكم": 1,
    "فبنحوكما": 1,
    "فبنحوكن": 1,
    "فبنحونا": 1,
    "فبنحوه": 1,
    "فبنحوها": 1,
    "فبنحوهم": 1,
    "فبنحوهما": 1,
    "فبنحوهن": 1,
    "فبنحوي": 1,
    "فبنفس": 1,
    "فبنفسك": 1,
    "فبنفسكم": 1,
    "فبنفسكما": 1,
    "فبنفسكن": 1,
    "فبنفسنا": 1,
    "فبنفسه": 1,
    "فبنفسها": 1,
    "فبنفسهم": 1,
    "فبنفسهما": 1,
    "فبنفسهن": 1,
    "فبنفسي": 1,
    "فبه": 1,
    "فبهؤلاء": 1,
    "فبها": 1,
    "فبهاته": 1,
    "فبهاتي": 1,
    "فبهاتين": 1,
    "فبهذا": 1,
    "فبهذه": 1,
    "فبهذي": 1,
    "فبهذين": 1,
    "فبهم": 1,
    "فبهما": 1,
    "فبهن": 1,
    "فبهناك": 1,
    "فبهنالك": 1,
    "فبهو": 1,
    "فبهي": 1,
    "فبي": 1,
    "فبيد": 1,
    "فبين": 1,
    "فبينك": 1,
    "فبينكم": 1,
    "فبينكما": 1,
    "فبينكن": 1,
    "فبيننا": 1,
    "فبينه": 1,
    "فبينها": 1,
    "فبينهم": 1,
    "فبينهما": 1,
    "فبينهن": 1,
    "فبيني": 1,
    "فتارة": 1,
    "فتانك": 1,
    "فتبدل": 1,
    "فتجاه": 1,
    "فتجاهك": 1,
    "فتجاهكم": 1,
    "فتجاهكما": 1,
    "فتجاهكن": 1,
    "فتجاهنا": 1,
    "فتجاهه": 1,
    "فتجاهها": 1,
    "فتجاههم": 1,
    "فتجاههما": 1,
    "فتجاههن": 1,
    "فتجاهي": 1,
    "فتحتك": 1,
    "فتحتكم": 1,
    "فتحتكما": 1,
    "فتحتكن": 1,
    "فتحتنا": 1,
    "فتحته": 1,
    "فتحتها": 1,
    "فتحتهم": 1,
    "فتحتهما": 1,
    "فتحتهن": 1,
    "فتحتي": 1,
    "فتحول": 1,
    "فتلقاء": 1,
    "فتلقاءك": 1,
    "فتلقاءكم": 1,
    "فتلقاءكما": 1,
    "فتلقاءكن": 1,
    "فتلقاءنا": 1,
    "فتلقاءه": 1,
    "فتلقاءها": 1,
    "فتلقاءهم": 1,
    "فتلقاءهما": 1,
    "فتلقاءهن": 1,
    "فتلقاءي": 1,
    "فتلكم": 1,
    "فتلكما": 1,
    "فته": 1,
    "فتي": 1,
    "فتين": 1,
    "فتينك": 1,
    "فثمة": 1,
    "فجميع": 1,
    "فجميعك": 1,
    "فجميعكم": 1,
    "فجميعكما": 1,
    "فجميعكن": 1,
    "فجميعنا": 1,
    "فجميعه": 1,
    "فجميعها": 1,
    "فجميعهم": 1,
    "فجميعهما": 1,
    "فجميعهن": 1,
    "فجميعي": 1,
    "فجير": 1,
    "فحار": 1,
    "فحاشا": 1,
    "فحاشاك": 1,
    "فحاشاكم": 1,
    "فحاشاكما": 1,
    "فحاشاكن": 1,
    "فحاشانا": 1,
    "فحاشاه": 1,
    "فحاشاها": 1,
    "فحاشاهم": 1,
    "فحاشاهما": 1,
    "فحاشاهن": 1,
    "فحاشاي": 1,
    "فحبذا": 1,
    "فحتى": 1,
    "فحذار": 1,
    "فحرى": 1,
    "فحسب": 1,
    "فحسبك": 1,
    "فحسبكم": 1,
    "فحسبكما": 1,
    "فحسبكن": 1,
    "فحسبنا": 1,
    "فحسبها": 1,
    "فحسبهم": 1,
    "فحسبهما": 1,
    "فحسبهن": 1,
    "فحسبي": 1,
    "فحمك": 1,
    "فحمكم": 1,
    "فحمكما": 1,
    "فحمكن": 1,
    "فحمنا": 1,
    "فحمه": 1,
    "فحمها": 1,
    "فحمهم": 1,
    "فحمهما": 1,
    "فحمهن": 1,
    "فحوالى": 1,
    "فحوالي": 1,
    "فحواليك": 1,
    "فحواليكم": 1,
    "فحواليكما": 1,
    "فحواليكن": 1,
    "فحوالينا": 1,
    "فحواليه": 1,
    "فحواليها": 1,
    "فحواليهم": 1,
    "فحواليهما": 1,
    "فحواليهن": 1,
    "فحول": 1,
    "فحولك": 1,
    "فحولكم": 1,
    "فحولكما": 1,
    "فحولكن": 1,
    "فحولنا": 1,
    "فحوله": 1,
    "فحولها": 1,
    "فحولهم": 1,
    "فحولهما": 1,
    "فحولهن": 1,
    "فحولي": 1,
    "فحي": 1,
    "فحيث": 1,
    "فحيثما": 1,
    "فحين": 1,
    "فحينك": 1,
    "فحينكم": 1,
    "فحينكما": 1,
    "فحينكن": 1,
    "فحيننا": 1,
    "فحينه": 1,
    "فحينها": 1,
    "فحينهم": 1,
    "فحينهما": 1,
    "فحينهن": 1,
    "فحيني": 1,
    "فخلا": 1,
    "فخلاك": 1,
    "فخلاكم": 1,
    "فخلاكما": 1,
    "فخلاكن": 1,
    "فخلال": 1,
    "فخلالك": 1,
    "فخلالكم": 1,
    "فخلالكما": 1,
    "فخلالكن": 1,
    "فخلالنا": 1,
    "فخلاله": 1,
    "فخلالها": 1,
    "فخلالهم": 1,
    "فخلالهما": 1,
    "فخلالهن": 1,
    "فخلالي": 1,
    "فخلانا": 1,
    "فخلاه": 1,
    "فخلاها": 1,
    "فخلاهم": 1,
    "فخلاهما": 1,
    "فخلاهن": 1,
    "فخلاي": 1,
    "فخلفك": 1,
    "فخلفكم": 1,
    "فخلفكما": 1,
    "فخلفكن": 1,
    "فخلفنا": 1,
    "فخلفه": 1,
    "فخلفها": 1,
    "فخلفهم": 1,
    "فخلفهما": 1,
    "فخلفهن": 1,
    "فخلفي": 1,
    "فدون": 1,
    "فدونك": 1,
    "فذا": 1,
    "فذات": 1,
    "فذاك": 1,
    "فذان": 1,
    "فذلكما": 1,
    "فذه": 1,
    "فذوا": 1,
    "فذواتا": 1,
    "فذواتي": 1,
    "فذي": 1,
    "فذيت": 1,
    "فذين": 1,
    "فذينك": 1,
    "فراح": 1,
    "فرب": 1,
    "فرويدك": 1,
    "فريث": 1,
    "فساءما": 1,
    "فسبحانك": 1,
    "فسبحانكم": 1,
    "فسبحانكما": 1,
    "فسبحانكن": 1,
    "فسبحاننا": 1,
    "فسبحانه": 1,
    "فسبحانها": 1,
    "فسبحانهم": 1,
    "فسبحانهما": 1,
    "فسبحانهن": 1,
    "فسبحاني": 1,
    "فسرعان": 1,
    "فسوي": 1,
    "فسويك": 1,
    "فسويكم": 1,
    "فسويكما": 1,
    "فسويكن": 1,
    "فسوينا": 1,
    "فسويه": 1,
    "فسويها": 1,
    "فسويهم": 1,
    "فسويهما": 1,
    "فسويهن": 1,
    "فشبه": 1,
    "فشبهك": 1,
    "فشبهكم": 1,
    "فشبهكما": 1,
    "فشبهكن": 1,
    "فشبهنا": 1,
    "فشبهه": 1,
    "فشبهها": 1,
    "فشبههم": 1,
    "فشبههما": 1,
    "فشبههن": 1,
    "فشبهي": 1,
    "فشتان": 1,
    "فشرع": 1,
    "فشمال": 1,
    "فشمالك": 1,
    "فشمالكم": 1,
    "فشمالكما": 1,
    "فشمالكن": 1,
    "فشمالنا": 1,
    "فشماله": 1,
    "فشمالها": 1,
    "فشمالهم": 1,
    "فشمالهما": 1,
    "فشمالهن": 1,
    "فشمالي": 1,
    "فصار": 1,
    "فصباح": 1,
    "فضحوة": 1,
    "فضمن": 1,
    "فضمنك": 1,
    "فضمنكم": 1,
    "فضمنكما": 1,
    "فضمنكن": 1,
    "فضمننا": 1,
    "فضمنه": 1,
    "فضمنها": 1,
    "فضمنهم": 1,
    "فضمنهما": 1,
    "فضمنهن": 1,
    "فضمني": 1,
    "فطالما": 1,
    "فظل": 1,
    "فعاد": 1,
    "فعامة": 1,
    "فعامتك": 1,
    "فعامتكم": 1,
    "فعامتكما": 1,
    "فعامتكن": 1,
    "فعامتنا": 1,
    "فعامته": 1,
    "فعامتها": 1,
    "فعامتهم": 1,
    "فعامتهما": 1,
    "فعامتهن": 1,
    "فعامتي": 1,
    "فعدا": 1,
    "فعداك": 1,
    "فعداكم": 1,
    "فعداكما": 1,
    "فعداكن": 1,
    "فعدانا": 1,
    "فعداه": 1,
    "فعداها": 1,
    "فعداهم": 1,
    "فعداهما": 1,
    "فعداهن": 1,
    "فعداي": 1,
    "فعلق": 1,
    "فعليك": 1,
    "فعليكما": 1,
    "فعليكن": 1,
    "فعلينا": 1,
    "فعليهما": 1,
    "فعما": 1,
    "فعن": 1,
    "فعندك": 1,
    "فعندكم": 1,
    "فعندكما": 1,
    "فعندكن": 1,
    "فعندنا": 1,
    "فعنده": 1,
    "فعندها": 1,
    "فعندهم": 1,
    "فعندهما": 1,
    "فعندهن": 1,
    "فعندي": 1,
    "فعنك": 1,
    "فعنكم": 1,
    "فعنكما": 1,
    "فعنكن": 1,
    "فعننا": 1,
    "فعنه": 1,
    "فعنها": 1,
    "فعنهم": 1,
    "فعنهما": 1,
    "فعنهن": 1,
    "فعني": 1,
    "فعوض": 1,
    "فعين": 1,
    "فعينك": 1,
    "فعينكم": 1,
    "فعينكما": 1,
    "فعينكن": 1,
    "فعيننا": 1,
    "فعينه": 1,
    "فعينها": 1,
    "فعينهم": 1,
    "فعينهما": 1,
    "فعينهن": 1,
    "فعيني": 1,
    "فغدا": 1,
    "فغداة": 1,
    "فغير": 1,
    "فغيرك": 1,
    "فغيركم": 1,
    "فغيركما": 1,
    "فغيركن": 1,
    "فغيرنا": 1,
    "فغيره": 1,
    "فغيرها": 1,
    "فغيرهم": 1,
    "فغيرهما": 1,
    "فغيرهن": 1,
    "فغيري": 1,
    "ففلان": 1,
    "ففو": 1,
    "ففوق": 1,
    "ففوقك": 1,
    "ففوقكم": 1,
    "ففوقكما": 1,
    "ففوقكن": 1,
    "ففوقنا": 1,
    "ففوقه": 1,
    "ففوقها": 1,
    "ففوقهم": 1,
    "ففوقهما": 1,
    "ففوقهن": 1,
    "ففوقي": 1,
    "ففوك": 1,
    "ففوكم": 1,
    "ففوكما": 1,
    "ففوكن": 1,
    "ففونا": 1,
    "ففوه": 1,
    "ففوها": 1,
    "ففوهم": 1,
    "ففوهما": 1,
    "ففوهن": 1,
    "ففوي": 1,
    "ففيك": 1,
    "ففيكم": 1,
    "ففيكما": 1,
    "ففيكن": 1,
    "ففيم": 1,
    "ففيما": 1,
    "ففينا": 1,
    "ففيه": 1,
    "ففيها": 1,
    "ففيهم": 1,
    "ففيهما": 1,
    "ففيهن": 1,
    "ففيي": 1,
    "فقام": 1,
    "فقبل": 1,
    "فقلما": 1,
    "فكأب": 1,
    "فكأبك": 1,
    "فكأبكم": 1,
    "فكأبكما": 1,
    "فكأبكن": 1,
    "فكأبنا": 1,
    "فكأبه": 1,
    "فكأبها": 1,
    "فكأبهم": 1,
    "فكأبهما": 1,
    "فكأبهن": 1,
    "فكأبي": 1,
    "فكأجمع": 1,
    "فكأجمعك": 1,
    "فكأجمعكم": 1,
    "فكأجمعكما": 1,
    "فكأجمعكن": 1,
    "فكأجمعنا": 1,
    "فكأجمعه": 1,
    "فكأجمعها": 1,
    "فكأجمعهم": 1,
    "فكأجمعهما": 1,
    "فكأجمعهن": 1,
    "فكأجمعي": 1,
    "فكأخ": 1,
    "فكأخك": 1,
    "فكأخكم": 1,
    "فكأخكما": 1,
    "فكأخكن": 1,
    "فكأخنا": 1,
    "فكأخه": 1,
    "فكأخها": 1,
    "فكأخهم": 1,
    "فكأخهما": 1,
    "فكأخهن": 1,
    "فكأخي": 1,
    "فكأقل": 1,
    "فكأقلك": 1,
    "فكأقلكم": 1,
    "فكأقلكما": 1,
    "فكأقلكن": 1,
    "فكأقلنا": 1,
    "فكأقله": 1,
    "فكأقلها": 1,
    "فكأقلهم": 1,
    "فكأقلهما": 1,
    "فكأقلهن": 1,
    "فكأقلي": 1,
    "فكأكثر": 1,
    "فكأكثرك": 1,
    "فكأكثركم": 1,
    "فكأكثركما": 1,
    "فكأكثركن": 1,
    "فكأكثرنا": 1,
    "فكأكثره": 1,
    "فكأكثرها": 1,
    "فكأكثرهم": 1,
    "فكأكثرهما": 1,
    "فكأكثرهن": 1,
    "فكأكثري": 1,
    "فكأن": 1,
    "فكأنك": 1,
    "فكأنكم": 1,
    "فكأنكما": 1,
    "فكأنكن": 1,
    "فكأننا": 1,
    "فكأنه": 1,
    "فكأنها": 1,
    "فكأنهم": 1,
    "فكأنهما": 1,
    "فكأنهن": 1,
    "فكأني": 1,
    "فكأولئك": 1,
    "فكأولئكم": 1,
    "فكأولاء": 1,
    "فكأولالك": 1,
    "فكأي": 1,
    "فكأيك": 1,
    "فكأيكم": 1,
    "فكأيكما": 1,
    "فكأيكن": 1,
    "فكأينا": 1,
    "فكأيه": 1,
    "فكأيها": 1,
    "فكأيهم": 1,
    "فكأيهما": 1,
    "فكأيهن": 1,
    "فكأيي": 1,
    "فكإن": 1,
    "فكإنك": 1,
    "فكإنكم": 1,
    "فكإنكما": 1,
    "فكإنكن": 1,
    "فكإننا": 1,
    "فكإنه": 1,
    "فكإنها": 1,
    "فكإنهم": 1,
    "فكإنهما": 1,
    "فكإنهن": 1,
    "فكإني": 1,
    "فكإياك": 1,
    "فكإياكم": 1,
    "فكإياكما": 1,
    "فكإياكن": 1,
    "فكإيانا": 1,
    "فكإياه": 1,
    "فكإياها": 1,
    "فكإياهم": 1,
    "فكإياهما": 1,
    "فكإياهن": 1,
    "فكإياي": 1,
    "فكاد": 1,
    "فكالألاء": 1,
    "فكالألى": 1,
    "فكالتي": 1,
    "فكالذي": 1,
    "فكالذين": 1,
    "فكاللائي": 1,
    "فكاللاتي": 1,
    "فكاللتيا": 1,
    "فكاللتين": 1,
    "فكاللذين": 1,
    "فكاللواتي": 1,
    "فكبعض": 1,
    "فكبعضك": 1,
    "فكبعضكم": 1,
    "فكبعضكما": 1,
    "فكبعضكن": 1,
    "فكبعضنا": 1,
    "فكبعضه": 1,
    "فكبعضها": 1,
    "فكبعضهم": 1,
    "فكبعضهما": 1,
    "فكبعضهن": 1,
    "فكبعضي": 1,
    "فكتانك": 1,
    "فكتجاه": 1,
    "فكتجاهك": 1,
    "فكتجاهكم": 1,
    "فكتجاهكما": 1,
    "فكتجاهكن": 1,
    "فكتجاهنا": 1,
    "فكتجاهه": 1,
    "فكتجاهها": 1,
    "فكتجاههم": 1,
    "فكتجاههما": 1,
    "فكتجاههن": 1,
    "فكتجاهي": 1,
    "فكتلقاء": 1,
    "فكتلقاءك": 1,
    "فكتلقاءكم": 1,
    "فكتلقاءكما": 1,
    "فكتلقاءكن": 1,
    "فكتلقاءنا": 1,
    "فكتلقاءه": 1,
    "فكتلقاءها": 1,
    "فكتلقاءهم": 1,
    "فكتلقاءهما": 1,
    "فكتلقاءهن": 1,
    "فكتلقاءي": 1,
    "فكتلك": 1,
    "فكتلكم": 1,
    "فكتلكما": 1,
    "فكته": 1,
    "فكتي": 1,
    "فكتين": 1,
    "فكتينك": 1,
    "فكجميع": 1,
    "فكجميعك": 1,
    "فكجميعكم": 1,
    "فكجميعكما": 1,
    "فكجميعكن": 1,
    "فكجميعنا": 1,
    "فكجميعه": 1,
    "فكجميعها": 1,
    "فكجميعهم": 1,
    "فكجميعهما": 1,
    "فكجميعهن": 1,
    "فكجميعي": 1,
    "فكحسب": 1,
    "فكحسبك": 1,
    "فكحسبكم": 1,
    "فكحسبكما": 1,
    "فكحسبكن": 1,
    "فكحسبنا": 1,
    "فكحسبه": 1,
    "فكحسبها": 1,
    "فكحسبهم": 1,
    "فكحسبهما": 1,
    "فكحسبهن": 1,
    "فكحسبي": 1,
    "فكحم": 1,
    "فكحمك": 1,
    "فكحمكم": 1,
    "فكحمكما": 1,
    "فكحمكن": 1,
    "فكحمنا": 1,
    "فكحمه": 1,
    "فكحمها": 1,
    "فكحمهم": 1,
    "فكحمهما": 1,
    "فكحمهن": 1,
    "فكحمي": 1,
    "فكحيث": 1,
    "فكذا": 1,
    "فكذات": 1,
    "فكذاك": 1,
    "فكذانك": 1,
    "فكذلكم": 1,
    "فكذلكما": 1,
    "فكذلكن": 1,
    "فكذه": 1,
    "فكذواتي": 1,
    "فكذي": 1,
    "فكذين": 1,
    "فكذينك": 1,
    "فكرب": 1,
    "فكسوى": 1,
    "فكسوي": 1,
    "فكسويك": 1,
    "فكسويكم": 1,
    "فكسويكما": 1,
    "فكسويكن": 1,
    "فكسوينا": 1,
    "فكسويه": 1,
    "فكسويها": 1,
    "فكسويهم": 1,
    "فكسويهما": 1,
    "فكسويهن": 1,
    "فكشبه": 1,
    "فكشبهك": 1,
    "فكشبهكم": 1,
    "فكشبهكما": 1,
    "فكشبهكن": 1,
    "فكشبهنا": 1,
    "فكشبهه": 1,
    "فكشبهها": 1,
    "فكشبههم": 1,
    "فكشبههما": 1,
    "فكشبههن": 1,
    "فكشبهي": 1,
    "فكعامة": 1,
    "فكعامتك": 1,
    "فكعامتكم": 1,
    "فكعامتكما": 1,
    "فكعامتكن": 1,
    "فكعامتنا": 1,
    "فكعامته": 1,
    "فكعامتها": 1,
    "فكعامتهم": 1,
    "فكعامتهما": 1,
    "فكعامتهن": 1,
    "فكعامتي": 1,
    "فكعين": 1,
    "فكعينك": 1,
    "فكعينكم": 1,
    "فكعينكما": 1,
    "فكعينكن": 1,
    "فكعيننا": 1,
    "فكعينه": 1,
    "فكعينها": 1,
    "فكعينهم": 1,
    "فكعينهما": 1,
    "فكعينهن": 1,
    "فكعيني": 1,
    "فكغير": 1,
    "فكغيرك": 1,
    "فكغيركم": 1,
    "فكغيركما": 1,
    "فكغيركن": 1,
    "فكغيرنا": 1,
    "فكغيره": 1,
    "فكغيرها": 1,
    "فكغيرهم": 1,
    "فكغيرهما": 1,
    "فكغيرهن": 1,
    "فكغيري": 1,
    "فكفو": 1,
    "فكفوك": 1,
    "فكفوكم": 1,
    "فكفوكما": 1,
    "فكفوكن": 1,
    "فكفونا": 1,
    "فكفوه": 1,
    "فكفوها": 1,
    "فكفوهم": 1,
    "فكفوهما": 1,
    "فكفوهن": 1,
    "فكفوي": 1,
    "فككل": 1,
    "فككلتا": 1,
    "فككلك": 1,
    "فككلكم": 1,
    "فككلكما": 1,
    "فككلكن": 1,
    "فككلنا": 1,
    "فككله": 1,
    "فككلها": 1,
    "فككلهم": 1,
    "فككلهما": 1,
    "فككلهن": 1,
    "فككلي": 1,
    "فككليكما": 1,
    "فككليهما": 1,
    "فكل": 1,
    "فكلاهما": 1,
    "فكلتا": 1,
    "فكلك": 1,
    "فكلكم": 1,
    "فكلكما": 1,
    "فكلكن": 1,
    "فكلما": 1,
    "فكلنا": 1,
    "فكله": 1,
    "فكلها": 1,
    "فكلهم": 1,
    "فكلهما": 1,
    "فكلهن": 1,
    "فكليكما": 1,
    "فكليهما": 1,
    "فكم": 1,
    "فكما": 1,
    "فكمثل": 1,
    "فكمثلك": 1,
    "فكمثلكم": 1,
    "فكمثلكما": 1,
    "فكمثلكن": 1,
    "فكمثلنا": 1,
    "فكمثله": 1,
    "فكمثلها": 1,
    "فكمثلهم": 1,
    "فكمثلهما": 1,
    "فكمثلهن": 1,
    "فكمثلي": 1,
    "فكمن": 1,
    "فكنحو": 1,
    "فكنحوك": 1,
    "فكنحوكم": 1,
    "فكنحوكما": 1,
    "فكنحوكن": 1,
    "فكنحونا": 1,
    "فكنحوه": 1,
    "فكنحوها": 1,
    "فكنحوهم": 1,
    "فكنحوهما": 1,
    "فكنحوهن": 1,
    "فكنحوي": 1,
    "فكنفس": 1,
    "فكنفسك": 1,
    "فكنفسكم": 1,
    "فكنفسكما": 1,
    "فكنفسكن": 1,
    "فكنفسنا": 1,
    "فكنفسه": 1,
    "فكنفسها": 1,
    "فكنفسهم": 1,
    "فكنفسهما": 1,
    "فكنفسهن": 1,
    "فكنفسي": 1,
    "فكهؤلاء": 1,
    "فكهاته": 1,
    "فكهاتي": 1,
    "فكهاتين": 1,
    "فكهذا": 1,
    "فكهذه": 1,
    "فكهذي": 1,
    "فكهذين": 1,
    "فكهم": 1,
    "فكهما": 1,
    "فكهن": 1,
    "فكهناك": 1,
    "فكهنالك": 1,
    "فكهو": 1,
    "فكهي": 1,
    "فكي": 1,
    "فكيت": 1,
    "فكيفما": 1,
    "فلآض": 1,
    "فلأب": 1,
    "فلأبك": 1,
    "فلأبكم": 1,
    "فلأبكما": 1,
    "فلأبكن": 1,
    "فلأبنا": 1,
    "فلأبه": 1,
    "فلأبها": 1,
    "فلأبهم": 1,
    "فلأبهما": 1,
    "فلأبهن": 1,
    "فلأبي": 1,
    "فلأجمع": 1,
    "فلأجمعك": 1,
    "فلأجمعكم": 1,
    "فلأجمعكما": 1,
    "فلأجمعكن": 1,
    "فلأجمعنا": 1,
    "فلأجمعه": 1,
    "فلأجمعها": 1,
    "فلأجمعهم": 1,
    "فلأجمعهما": 1,
    "فلأجمعهن": 1,
    "فلأجمعي": 1,
    "فلأخ": 1,
    "فلأخذ": 1,
    "فلأخك": 1,
    "فلأخكم": 1,
    "فلأخكما": 1,
    "فلأخكن": 1,
    "فلأخنا": 1,
    "فلأخه": 1,
    "فلأخها": 1,
    "فلأخهم": 1,
    "فلأخهما": 1,
    "فلأخهن": 1,
    "فلأخي": 1,
    "فلأصبح": 1,
    "فلأضحى": 1,
    "فلأقبل": 1,
    "فلأقل": 1,
    "فلأقلك": 1,
    "فلأقلكم": 1,
    "فلأقلكما": 1,
    "فلأقلكن": 1,
    "فلأقلنا": 1,
    "فلأقله": 1,
    "فلأقلها": 1,
    "فلأقلهم": 1,
    "فلأقلهما": 1,
    "فلأقلهن": 1,
    "فلأقلي": 1,
    "فلأكثر": 1,
    "فلأكثرك": 1,
    "فلأكثركم": 1,
    "فلأكثركما": 1,
    "فلأكثركن": 1,
    "فلأكثرنا": 1,
    "فلأكثره": 1,
    "فلأكثرها": 1,
    "فلأكثرهم": 1,
    "فلأكثرهما": 1,
    "فلأكثرهن": 1,
    "فلأكثري": 1,
    "فلأمام": 1,
    "فلأمامك": 1,
    "فلأمامكم": 1,
    "فلأمامكما": 1,
    "فلأمامكن": 1,
    "فلأمامنا": 1,
    "فلأمامه": 1,
    "فلأمامها": 1,
    "فلأمامهم": 1,
    "فلأمامهما": 1,
    "فلأمامهن": 1,
    "فلأمامي": 1,
    "فلأمسى": 1,
    "فلأن": 1,
    "فلأنا": 1,
    "فلأنت": 1,
    "فلأنتم": 1,
    "فلأنتما": 1,
    "فلأنتن": 1,
    "فلأنشأ": 1,
    "فلأنك": 1,
    "فلأنكم": 1,
    "فلأنكما": 1,
    "فلأنكن": 1,
    "فلأننا": 1,
    "فلأنه": 1,
    "فلأنها": 1,
    "فلأنهم": 1,
    "فلأنهما": 1,
    "فلأنهن": 1,
    "فلأني": 1,
    "فلأوشك": 1,
    "فلأولئك": 1,
    "فلأولئكم": 1,
    "فلأولاء": 1,
    "فلأولالك": 1,
    "فلأي": 1,
    "فلأيك": 1,
    "فلأيكم": 1,
    "فلأيكما": 1,
    "فلأيكن": 1,
    "فلأينا": 1,
    "فلأيه": 1,
    "فلأيها": 1,
    "فلأيهم": 1,
    "فلأيهما": 1,
    "فلأيهن": 1,
    "فلأيي": 1,
    "فلإلى": 1,
    "فلإلي": 1,
    "فلإليك": 1,
    "فلإليكم": 1,
    "فلإليكما": 1,
    "فلإليكن": 1,
    "فلإلينا": 1,
    "فلإليه": 1,
    "فلإليها": 1,
    "فلإليهم": 1,
    "فلإليهما": 1,
    "فلإليهن": 1,
    "فلإن": 1,
    "فلإنك": 1,
    "فلإنكم": 1,
    "فلإنكما": 1,
    "فلإنكن": 1,
    "فلإننا": 1,
    "فلإنه": 1,
    "فلإنها": 1,
    "فلإنهم": 1,
    "فلإنهما": 1,
    "فلإنهن": 1,
    "فلإني": 1,
    "فلإياك": 1,
    "فلإياكم": 1,
    "فلإياكما": 1,
    "فلإياكن": 1,
    "فلإيانا": 1,
    "فلإياه": 1,
    "فلإياها": 1,
    "فلإياهم": 1,
    "فلإياهما": 1,
    "فلإياهن": 1,
    "فلإياي": 1,
    "فلئن": 1,
    "فلابتدأ": 1,
    "فلات": 1,
    "فلاخلولق": 1,
    "فلارتد": 1,
    "فلاستحال": 1,
    "فلاسيما": 1,
    "فلان": 1,
    "فلانبرى": 1,
    "فلانقلب": 1,
    "فلبات": 1,
    "فلبعض": 1,
    "فلبعضك": 1,
    "فلبعضكم": 1,
    "فلبعضكما": 1,
    "فلبعضكن": 1,
    "فلبعضنا": 1,
    "فلبعضه": 1,
    "فلبعضها": 1,
    "فلبعضهم": 1,
    "فلبعضهما": 1,
    "فلبعضهن": 1,
    "فلبعضي": 1,
    "فلتانك": 1,
    "فلتبدل": 1,
    "فلتجاه": 1,
    "فلتجاهك": 1,
    "فلتجاهكم": 1,
    "فلتجاهكما": 1,
    "فلتجاهكن": 1,
    "فلتجاهنا": 1,
    "فلتجاهه": 1,
    "فلتجاهها": 1,
    "فلتجاههم": 1,
    "فلتجاههما": 1,
    "فلتجاههن": 1,
    "فلتجاهي": 1,
    "فلتحت": 1,
    "فلتحتك": 1,
    "فلتحتكم": 1,
    "فلتحتكما": 1,
    "فلتحتكن": 1,
    "فلتحتنا": 1,
    "فلتحته": 1,
    "فلتحتها": 1,
    "فلتحتهم": 1,
    "فلتحتهما": 1,
    "فلتحتهن": 1,
    "فلتحتي": 1,
    "فلتحول": 1,
    "فلتلقاء": 1,
    "فلتلقاءك": 1,
    "فلتلقاءكم": 1,
    "فلتلقاءكما": 1,
    "فلتلقاءكن": 1,
    "فلتلقاءنا": 1,
    "فلتلقاءه": 1,
    "فلتلقاءها": 1,
    "فلتلقاءهم": 1,
    "فلتلقاءهما": 1,
    "فلتلقاءهن": 1,
    "فلتلقاءي": 1,
    "فلتلك": 1,
    "فلتلكم": 1,
    "فلتلكما": 1,
    "فلته": 1,
    "فلتي": 1,
    "فلتين": 1,
    "فلتينك": 1,
    "فلجعل": 1,
    "فلجميع": 1,
    "فلجميعك": 1,
    "فلجميعكم": 1,
    "فلجميعكما": 1,
    "فلجميعكن": 1,
    "فلجميعنا": 1,
    "فلجميعه": 1,
    "فلجميعها": 1,
    "فلجميعهم": 1,
    "فلجميعهما": 1,
    "فلجميعهن": 1,
    "فلجميعي": 1,
    "فلحار": 1,
    "فلحرى": 1,
    "فلحسب": 1,
    "فلحسبك": 1,
    "فلحسبكم": 1,
    "فلحسبكما": 1,
    "فلحسبكن": 1,
    "فلحسبنا": 1,
    "فلحسبه": 1,
    "فلحسبها": 1,
    "فلحسبهم": 1,
    "فلحسبهما": 1,
    "فلحسبهن": 1,
    "فلحسبي": 1,
    "فلحم": 1,
    "فلحمك": 1,
    "فلحمكم": 1,
    "فلحمكما": 1,
    "فلحمكن": 1,
    "فلحمنا": 1,
    "فلحمه": 1,
    "فلحمها": 1,
    "فلحمهم": 1,
    "فلحمهما": 1,
    "فلحمهن": 1,
    "فلحمي": 1,
    "فلحيث": 1,
    "فلخلف": 1,
    "فلخلفك": 1,
    "فلخلفكم": 1,
    "فلخلفكما": 1,
    "فلخلفكن": 1,
    "فلخلفنا": 1,
    "فلخلفه": 1,
    "فلخلفها": 1,
    "فلخلفهم": 1,
    "فلخلفهما": 1,
    "فلخلفهن": 1,
    "فلخلفي": 1,
    "فلدن": 1,
    "فلدنك": 1,
    "فلدنكم": 1,
    "فلدنكما": 1,
    "فلدنكن": 1,
    "فلدننا": 1,
    "فلدنه": 1,
    "فلدنها": 1,
    "فلدنهم": 1,
    "فلدنهما": 1,
    "فلدنهن": 1,
    "فلدني": 1,
    "فلدى": 1,
    "فلدي": 1,
    "فلديك": 1,
    "فلديكم": 1,
    "فلديكما": 1,
    "فلديكن": 1,
    "فلدينا": 1,
    "فلديه": 1,
    "فلديها": 1,
    "فلديهم": 1,
    "فلديهما": 1,
    "فلديهن": 1,
    "فلذا": 1,
    "فلذات": 1,
    "فلذاك": 1,
    "فلذانك": 1,
    "فلذلكم": 1,
    "فلذلكما": 1,
    "فلذلكن": 1,
    "فلذه": 1,
    "فلذواتي": 1,
    "فلذي": 1,
    "فلذين": 1,
    "فلذينك": 1,
    "فلراح": 1,
    "فلرجع": 1,
    "فلست": 1,
    "فلستم": 1,
    "فلستما": 1,
    "فلستن": 1,
    "فلسن": 1,
    "فلسنا": 1,
    "فلسوى": 1,
    "فلسوي": 1,
    "فلسويك": 1,
    "فلسويكم": 1,
    "فلسويكما": 1,
    "فلسويكن": 1,
    "فلسوينا": 1,
    "فلسويه": 1,
    "فلسويها": 1,
    "فلسويهم": 1,
    "فلسويهما": 1,
    "فلسويهن": 1,
    "فلشبه": 1,
    "فلشبهك": 1,
    "فلشبهكم": 1,
    "فلشبهكما": 1,
    "فلشبهكن": 1,
    "فلشبهنا": 1,
    "فلشبهه": 1,
    "فلشبهها": 1,
    "فلشبههم": 1,
    "فلشبههما": 1,
    "فلشبههن": 1,
    "فلشبهي": 1,
    "فلشرع": 1,
    "فلشمال": 1,
    "فلشمالك": 1,
    "فلشمالكم": 1,
    "فلشمالكما": 1,
    "فلشمالكن": 1,
    "فلشمالنا": 1,
    "فلشماله": 1,
    "فلشمالها": 1,
    "فلشمالهم": 1,
    "فلشمالهما": 1,
    "فلشمالهن": 1,
    "فلشمالي": 1,
    "فلصار": 1,
    "فلطالما": 1,
    "فلطفق": 1,
    "فلظل": 1,
    "فلعاد": 1,
    "فلعامة": 1,
    "فلعامتك": 1,
    "فلعامتكم": 1,
    "فلعامتكما": 1,
    "فلعامتكن": 1,
    "فلعامتنا": 1,
    "فلعامته": 1,
    "فلعامتها": 1,
    "فلعامتهم": 1,
    "فلعامتهما": 1,
    "فلعامتهن": 1,
    "فلعامتي": 1,
    "فلعسى": 1,
    "فلعل": 1,
    "فلعلق": 1,
    "فلعلكم": 1,
    "فلعلكما": 1,
    "فلعلكن": 1,
    "فلعلنا": 1,
    "فلعله": 1,
    "فلعلها": 1,
    "فلعلهم": 1,
    "فلعلهما": 1,
    "فلعلهن": 1,
    "فلعلى": 1,
    "فلعلي": 1,
    "فلعليك": 1,
    "فلعليكم": 1,
    "فلعليكما": 1,
    "فلعليكن": 1,
    "فلعلينا": 1,
    "فلعليه": 1,
    "فلعليها": 1,
    "فلعليهم": 1,
    "فلعليهما": 1,
    "فلعليهن": 1,
    "فلعمر": 1,
    "فلعمرك": 1,
    "فلعمركم": 1,
    "فلعمركما": 1,
    "فلعمركن": 1,
    "فلعمرنا": 1,
    "فلعمره": 1,
    "فلعمرها": 1,
    "فلعمرهم": 1,
    "فلعمرهما": 1,
    "فلعمرهن": 1,
    "فلعمري": 1,
    "فلعن": 1,
    "فلعنك": 1,
    "فلعنكم": 1,
    "فلعنكما": 1,
    "فلعنكن": 1,
    "فلعننا": 1,
    "فلعنه": 1,
    "فلعنها": 1,
    "فلعنهم": 1,
    "فلعنهما": 1,
    "فلعنهن": 1,
    "فلعني": 1,
    "فلعين": 1,
    "فلعينك": 1,
    "فلعينكم": 1,
    "فلعينكما": 1,
    "فلعينكن": 1,
    "فلعيننا": 1,
    "فلعينه": 1,
    "فلعينها": 1,
    "فلعينهم": 1,
    "فلعينهما": 1,
    "فلعينهن": 1,
    "فلعيني": 1,
    "فلغدا": 1,
    "فلغير": 1,
    "فلغيرك": 1,
    "فلغيركم": 1,
    "فلغيركما": 1,
    "فلغيركن": 1,
    "فلغيرنا": 1,
    "فلغيره": 1,
    "فلغيرها": 1,
    "فلغيرهم": 1,
    "فلغيرهما": 1,
    "فلغيرهن": 1,
    "فلغيري": 1,
    "فلفو": 1,
    "فلفوق": 1,
    "فلفوقك": 1,
    "فلفوقكم": 1,
    "فلفوقكما": 1,
    "فلفوقكن": 1,
    "فلفوقنا": 1,
    "فلفوقه": 1,
    "فلفوقها": 1,
    "فلفوقهم": 1,
    "فلفوقهما": 1,
    "فلفوقهن": 1,
    "فلفوقي": 1,
    "فلفوك": 1,
    "فلفوكم": 1,
    "فلفوكما": 1,
    "فلفوكن": 1,
    "فلفونا": 1,
    "فلفوه": 1,
    "فلفوها": 1,
    "فلفوهم": 1,
    "فلفوهما": 1,
    "فلفوهن": 1,
    "فلفوي": 1,
    "فلفي": 1,
    "فلفيك": 1,
    "فلفيكم": 1,
    "فلفيكما": 1,
    "فلفيكن": 1,
    "فلفينا": 1,
    "فلفيه": 1,
    "فلفيها": 1,
    "فلفيهم": 1,
    "فلفيهما": 1,
    "فلفيهن": 1,
    "فلفيي": 1,
    "فلقام": 1,
    "فلقد": 1,
    "فلقلما": 1,
    "فلكأن": 1,
    "فلكأنك": 1,
    "فلكأنكم": 1,
    "فلكأنكما": 1,
    "فلكأنكن": 1,
    "فلكأنما": 1,
    "فلكأننا": 1,
    "فلكأنه": 1,
    "فلكأنها": 1,
    "فلكأنهم": 1,
    "فلكأنهما": 1,
    "فلكأنهن": 1,
    "فلكأني": 1,
    "فلكاد": 1,
    "فلكان": 1,
    "فلكرب": 1,
    "فلكلتا": 1,
    "فلكلك": 1,
    "فلكلكم": 1,
    "فلكلكما": 1,
    "فلكلكن": 1,
    "فلكلنا": 1,
    "فلكله": 1,
    "فلكلها": 1,
    "فلكلهم": 1,
    "فلكلهما": 1,
    "فلكلهن": 1,
    "فلكلي": 1,
    "فلكليكما": 1,
    "فلكليهما": 1,
    "فلكما": 1,
    "فلكن": 1,
    "فلكنك": 1,
    "فلكنكم": 1,
    "فلكنكما": 1,
    "فلكنكن": 1,
    "فلكنما": 1,
    "فلكننا": 1,
    "فلكنه": 1,
    "فلكنها": 1,
    "فلكنهم": 1,
    "فلكنهما": 1,
    "فلكنهن": 1,
    "فلكني": 1,
    "فلكيلا": 1,
    "فللألاء": 1,
    "فللألى": 1,
    "فللائي": 1,
    "فللاتي": 1,
    "فللتي": 1,
    "فللتيا": 1,
    "فللتين": 1,
    "فللذي": 1,
    "فللذين": 1,
    "فللواتي": 1,
    "فلمثل": 1,
    "فلمثلك": 1,
    "فلمثلكم": 1,
    "فلمثلكما": 1,
    "فلمثلكن": 1,
    "فلمثلنا": 1,
    "فلمثله": 1,
    "فلمثلها": 1,
    "فلمثلهم": 1,
    "فلمثلهما": 1,
    "فلمثلهن": 1,
    "فلمثلي": 1,
    "فلمع": 1,
    "فلمعك": 1,
    "فلمعكم": 1,
    "فلمعكما": 1,
    "فلمعكن": 1,
    "فلمعنا": 1,
    "فلمعه": 1,
    "فلمعها": 1,
    "فلمعهم": 1,
    "فلمعهما": 1,
    "فلمعهن": 1,
    "فلمعي": 1,
    "فلمن": 1,
    "فلمنك": 1,
    "فلمنكم": 1,
    "فلمنكما": 1,
    "فلمنكن": 1,
    "فلمننا": 1,
    "فلمنه": 1,
    "فلمنها": 1,
    "فلمنهم": 1,
    "فلمنهما": 1,
    "فلمنهن": 1,
    "فلمني": 1,
    "فلنا": 1,
    "فلنحن": 1,
    "فلنحو": 1,
    "فلنحوك": 1,
    "فلنحوكم": 1,
    "فلنحوكما": 1,
    "فلنحوكن": 1,
    "فلنحونا": 1,
    "فلنحوه": 1,
    "فلنحوها": 1,
    "فلنحوهم": 1,
    "فلنحوهما": 1,
    "فلنحوهن": 1,
    "فلنحوي": 1,
    "فلنفس": 1,
    "فلنفسك": 1,
    "فلنفسكم": 1,
    "فلنفسكما": 1,
    "فلنفسكن": 1,
    "فلنفسنا": 1,
    "فلنفسها": 1,
    "فلنفسهم": 1,
    "فلنفسهما": 1,
    "فلنفسهن": 1,
    "فلنفسي": 1,
    "فلهؤلاء": 1,
    "فلهاته": 1,
    "فلهاتي": 1,
    "فلهاتين": 1,
    "فلهب": 1,
    "فلهذا": 1,
    "فلهذه": 1,
    "فلهذي": 1,
    "فلهذين": 1,
    "فلهناك": 1,
    "فلهنالك": 1,
    "فلهو": 1,
    "فلهي": 1,
    "فلوما": 1,
    "فليت": 1,
    "فليتك": 1,
    "فليتكم": 1,
    "فليتكما": 1,
    "فليتكن": 1,
    "فليتنا": 1,
    "فليته": 1,
    "فليتها": 1,
    "فليتهم": 1,
    "فليتهما": 1,
    "فليتهن": 1,
    "فليتي": 1,
    "فليسا": 1,
    "فليست": 1,
    "فليستا": 1,
    "فليسوا": 1,
    "فليمين": 1,
    "فليمينك": 1,
    "فليمينكم": 1,
    "فليمينكما": 1,
    "فليمينكن": 1,
    "فليميننا": 1,
    "فليمينه": 1,
    "فليمينها": 1,
    "فليمينهم": 1,
    "فليمينهما": 1,
    "فليمينهن": 1,
    "فليميني": 1,
    "فماانفك": 1,
    "فمابرح": 1,
    "فمادام": 1,
    "فمازال": 1,
    "فمافتئ": 1,
    "فمتى": 1,
    "فمثل": 1,
    "فمثلك": 1,
    "فمثلكم": 1,
    "فمثلكما": 1,
    "فمثلكن": 1,
    "فمثلنا": 1,
    "فمثلها": 1,
    "فمثلهم": 1,
    "فمثلهما": 1,
    "فمثلهن": 1,
    "فمثلي": 1,
    "فمذ": 1,
    "فمرة": 1,
    "فمساء": 1,
    "فمع": 1,
    "فمعاذ": 1,
    "فمعاذك": 1,
    "فمعاذكم": 1,
    "فمعاذكما": 1,
    "فمعاذكن": 1,
    "فمعاذنا": 1,
    "فمعاذه": 1,
    "فمعاذها": 1,
    "فمعاذهم": 1,
    "فمعاذهما": 1,
    "فمعاذهن": 1,
    "فمعاذي": 1,
    "فمعك": 1,
    "فمعكم": 1,
    "فمعكما": 1,
    "فمعكن": 1,
    "فمعنا": 1,
    "فمعه": 1,
    "فمعها": 1,
    "فمعهم": 1,
    "فمعهما": 1,
    "فمعهن": 1,
    "فمعي": 1,
    "فمكانك": 1,
    "فمكانكم": 1,
    "فمكانكما": 1,
    "فمكانكن": 1,
    "فمما": 1,
    "فممن": 1,
    "فمنذ": 1,
    "فمنك": 1,
    "فمنكما": 1,
    "فمنكن": 1,
    "فمننا": 1,
    "فمنهما": 1,
    "فمنهن": 1,
    "فمني": 1,
    "فمهما": 1,
    "فنحن": 1,
    "فنحو": 1,
    "فنحوك": 1,
    "فنحوكم": 1,
    "فنحوكما": 1,
    "فنحوكن": 1,
    "فنحونا": 1,
    "فنحوه": 1,
    "فنحوها": 1,
    "فنحوهم": 1,
    "فنحوهما": 1,
    "فنحوهن": 1,
    "فنحوي": 1,
    "فنفس": 1,
    "فنفسك": 1,
    "فنفسكم": 1,
    "فنفسكما": 1,
    "فنفسكن": 1,
    "فنفسنا": 1,
    "فنفسه": 1,
    "فنفسها": 1,
    "فنفسهم": 1,
    "فنفسهما": 1,
    "فنفسهن": 1,
    "فنفسي": 1,
    "فهؤلاء": 1,
    "فهاؤم": 1,
    "فهاتان": 1,
    "فهاته": 1,
    "فهاتي": 1,
    "فهاتين": 1,
    "فهاهنا": 1,
    "فهذان": 1,
    "فهذه": 1,
    "فهذي": 1,
    "فهذين": 1,
    "فهكذا": 1,
    "فهلا": 1,
    "فهما": 1,
    "فهن": 1,
    "فهنا": 1,
    "فهناك": 1,
    "فهنالك": 1,
    "فهيا": 1,
    "فهيت": 1,
    "فو": 1,
    "فوقك": 1,
    "فوقكما": 1,
    "فوقكن": 1,
    "فوقنا": 1,
    "فوقهما": 1,
    "فوقي": 1,
    "فوك": 1,
    "فوكم": 1,
    "فوكما": 1,
    "فوكن": 1,
    "فوه": 1,
    "فوها": 1,
    "فوهم": 1,
    "فوهما": 1,
    "فوهن": 1,
    "فوي": 1,
    "فيكما": 1,
    "فيكن": 1,
    "فيمين": 1,
    "فيمينك": 1,
    "فيمينكم": 1,
    "فيمينكما": 1,
    "فيمينكن": 1,
    "فيميننا": 1,
    "فيمينه": 1,
    "فيمينها": 1,
    "فيمينهم": 1,
    "فيمينهما": 1,
    "فيمينهن": 1,
    "فيميني": 1,
    "فيي": 1,
    "قط": 1,
    "قلما": 1,
    "كأب": 1,
    "كأبك": 1,
    "كأبكم": 1,
    "كأبكما": 1,
    "كأبكن": 1,
    "كأبنا": 1,
    "كأبه": 1,
    "كأبها": 1,
    "كأبهم": 1,
    "كأبهما": 1,
    "كأبهن": 1,
    "كأبي": 1,
    "كأجمع": 1,
    "كأجمعك": 1,
    "كأجمعكم": 1,
    "كأجمعكما": 1,
    "كأجمعكن": 1,
    "كأجمعنا": 1,
    "كأجمعه": 1,
    "كأجمعها": 1,
    "كأجمعهم": 1,
    "كأجمعهما": 1,
    "كأجمعهن": 1,
    "كأجمعي": 1,
    "كأخ": 1,
    "كأخك": 1,
    "كأخكم": 1,
    "كأخكما": 1,
    "كأخكن": 1,
    "كأخنا": 1,
    "كأخه": 1,
    "كأخها": 1,
    "كأخهم": 1,
    "كأخهما": 1,
    "كأخهن": 1,
    "كأخي": 1,
    "كأقل": 1,
    "كأقلك": 1,
    "كأقلكم": 1,
    "كأقلكما": 1,
    "كأقلكن": 1,
    "كأقلنا": 1,
    "كأقله": 1,
    "كأقلها": 1,
    "كأقلهم": 1,
    "كأقلهما": 1,
    "كأقلهن": 1,
    "كأقلي": 1,
    "كأكثر": 1,
    "كأكثرك": 1,
    "كأكثركم": 1,
    "كأكثركما": 1,
    "كأكثركن": 1,
    "كأكثرنا": 1,
    "كأكثره": 1,
    "كأكثرها": 1,
    "كأكثرهم": 1,
    "كأكثرهما": 1,
    "كأكثرهن": 1,
    "كأكثري": 1,
    "كأنكم": 1,
    "كأنكما": 1,
    "كأنكن": 1,
    "كأننا": 1,
    "كأنهما": 1,
    "كأني": 1,
    "كأولئك": 1,
    "كأولئكم": 1,
    "كأولاء": 1,
    "كأولالك": 1,
    "كأي": 1,
    "كأيك": 1,
    "كأيكم": 1,
    "كأيكما": 1,
    "كأيكن": 1,
    "كأين": 1,
    "كأينا": 1,
    "كأيه": 1,
    "كأيها": 1,
    "كأيهم": 1,
    "كأيهما": 1,
    "كأيهن": 1,
    "كأيي": 1,
    "كإن": 1,
    "كإنك": 1,
    "كإنكم": 1,
    "كإنكما": 1,
    "كإنكن": 1,
    "كإننا": 1,
    "كإنه": 1,
    "كإنها": 1,
    "كإنهم": 1,
    "كإنهما": 1,
    "كإنهن": 1,
    "كإني": 1,
    "كإياك": 1,
    "كإياكم": 1,
    "كإياكما": 1,
    "كإياكن": 1,
    "كإيانا": 1,
    "كإياه": 1,
    "كإياها": 1,
    "كإياهم": 1,
    "كإياهما": 1,
    "كإياهن": 1,
    "كإياي": 1,
    "كالألاء": 1,
    "كالألى": 1,
    "كاللائي": 1,
    "كاللاتي": 1,
    "كاللتيا": 1,
    "كاللتين": 1,
    "كاللذين": 1,
    "كاللواتي": 1,
    "كبعض": 1,
    "كبعضك": 1,
    "كبعضكم": 1,
    "كبعضكما": 1,
    "كبعضكن": 1,
    "كبعضنا": 1,
    "كبعضه": 1,
    "كبعضها": 1,
    "كبعضهم": 1,
    "كبعضهما": 1,
    "كبعضهن": 1,
    "كبعضي": 1,
    "كتانك": 1,
    "كتجاه": 1,
    "كتجاهك": 1,
    "كتجاهكم": 1,
    "كتجاهكما": 1,
    "كتجاهكن": 1,
    "كتجاهنا": 1,
    "كتجاهه": 1,
    "كتجاهها": 1,
    "كتجاههم": 1,
    "كتجاههما": 1,
    "كتجاههن": 1,
    "كتجاهي": 1,
    "كتلقاء": 1,
    "كتلقاءك": 1,
    "كتلقاءكم": 1,
    "كتلقاءكما": 1,
    "كتلقاءكن": 1,
    "كتلقاءنا": 1,
    "كتلقاءه": 1,
    "كتلقاءها": 1,
    "كتلقاءهم": 1,
    "كتلقاءهما": 1,
    "كتلقاءهن": 1,
    "كتلقاءي": 1,
    "كتلك": 1,
    "كتلكم": 1,
    "كتلكما": 1,
    "كته": 1,
    "كتي": 1,
    "كتين": 1,
    "كتينك": 1,
    "كجميع": 1,
    "كجميعك": 1,
    "كجميعكم": 1,
    "كجميعكما": 1,
    "كجميعكن": 1,
    "كجميعنا": 1,
    "كجميعه": 1,
    "كجميعها": 1,
    "كجميعهم": 1,
    "كجميعهما": 1,
    "كجميعهن": 1,
    "كجميعي": 1,
    "كحسب": 1,
    "كحسبك": 1,
    "كحسبكم": 1,
    "كحسبكما": 1,
    "كحسبكن": 1,
    "كحسبنا": 1,
    "كحسبه": 1,
    "كحسبها": 1,
    "كحسبهم": 1,
    "كحسبهما": 1,
    "كحسبهن": 1,
    "كحسبي": 1,
    "كحم": 1,
    "كحمك": 1,
    "كحمكم": 1,
    "كحمكما": 1,
    "كحمكن": 1,
    "كحمنا": 1,
    "كحمه": 1,
    "كحمها": 1,
    "كحمهم": 1,
    "كحمهما": 1,
    "كحمهن": 1,
    "كحمي": 1,
    "كحيث": 1,
    "كخ": 1,
    "كذات": 1,
    "كذاك": 1,
    "كذانك": 1,
    "كذلكما": 1,
    "كذلكن": 1,
    "كذه": 1,
    "كذواتي": 1,
    "كذي": 1,
    "كذين": 1,
    "كذينك": 1,
    "كسوى": 1,
    "كسوي": 1,
    "كسويك": 1,
    "كسويكم": 1,
    "كسويكما": 1,
    "كسويكن": 1,
    "كسوينا": 1,
    "كسويه": 1,
    "كسويها": 1,
    "كسويهم": 1,
    "كسويهما": 1,
    "كسويهن": 1,
    "كشبه": 1,
    "كشبهك": 1,
    "كشبهكم": 1,
    "كشبهكما": 1,
    "كشبهكن": 1,
    "كشبهنا": 1,
    "كشبهه": 1,
    "كشبهها": 1,
    "كشبههم": 1,
    "كشبههما": 1,
    "كشبههن": 1,
    "كشبهي": 1,
    "كعامة": 1,
    "كعامتك": 1,
    "كعامتكم": 1,
    "كعامتكما": 1,
    "كعامتكن": 1,
    "كعامتنا": 1,
    "كعامته": 1,
    "كعامتها": 1,
    "كعامتهم": 1,
    "كعامتهما": 1,
    "كعامتهن": 1,
    "كعامتي": 1,
    "كعين": 1,
    "كعينك": 1,
    "كعينكم": 1,
    "كعينكما": 1,
    "كعينكن": 1,
    "كعيننا": 1,
    "كعينه": 1,
    "كعينها": 1,
    "كعينهم": 1,
    "كعينهما": 1,
    "كعينهن": 1,
    "كعيني": 1,
    "كغير": 1,
    "كغيرك": 1,
    "كغيركم": 1,
    "كغيركما": 1,
    "كغيركن": 1,
    "كغيرنا": 1,
    "كغيره": 1,
    "كغيرها": 1,
    "كغيرهم": 1,
    "كغيرهما": 1,
    "كغيرهن": 1,
    "كغيري": 1,
    "كفو": 1,
    "كفوك": 1,
    "كفوكم": 1,
    "كفوكما": 1,
    "كفوكن": 1,
    "كفونا": 1,
    "كفوه": 1,
    "كفوها": 1,
    "كفوهم": 1,
    "كفوهما": 1,
    "كفوهن": 1,
    "كفوي": 1,
    "ككل": 1,
    "ككلتا": 1,
    "ككلك": 1,
    "ككلكم": 1,
    "ككلكما": 1,
    "ككلكن": 1,
    "ككلنا": 1,
    "ككله": 1,
    "ككلها": 1,
    "ككلهم": 1,
    "ككلهما": 1,
    "ككلهن": 1,
    "ككلي": 1,
    "ككليكما": 1,
    "ككليهما": 1,
    "كلك": 1,
    "كلكم": 1,
    "كلكما": 1,
    "كلكن": 1,
    "كلنا": 1,
    "كلهما": 1,
    "كليكما": 1,
    "كليهما": 1,
    "كمثلك": 1,
    "كمثلكم": 1,
    "كمثلكما": 1,
    "كمثلكن": 1,
    "كمثلنا": 1,
    "كمثلها": 1,
    "كمثلهم": 1,
    "كمثلهما": 1,
    "كمثلهن": 1,
    "كمثلي": 1,
    "كنحو": 1,
    "كنحوك": 1,
    "كنحوكم": 1,
    "كنحوكما": 1,
    "كنحوكن": 1,
    "كنحونا": 1,
    "كنحوه": 1,
    "كنحوها": 1,
    "كنحوهم": 1,
    "كنحوهما": 1,
    "كنحوهن": 1,
    "كنحوي": 1,
    "كنفسك": 1,
    "كنفسكم": 1,
    "كنفسكما": 1,
    "كنفسكن": 1,
    "كنفسنا": 1,
    "كنفسه": 1,
    "كنفسها": 1,
    "كنفسهم": 1,
    "كنفسهما": 1,
    "كنفسهن": 1,
    "كنفسي": 1,
    "كهؤلاء": 1,
    "كهاته": 1,
    "كهاتي": 1,
    "كهاتين": 1,
    "كهذا": 1,
    "كهذه": 1,
    "كهذي": 1,
    "كهذين": 1,
    "كهم": 1,
    "كهما": 1,
    "كهن": 1,
    "كهناك": 1,
    "كهنالك": 1,
    "كهو": 1,
    "كهي": 1,
    "كيت": 1,
    "كيفما": 1,
    "لآض": 1,
    "لأب": 1,
    "لأبك": 1,
    "لأبكم": 1,
    "لأبكما": 1,
    "لأبكن": 1,
    "لأبنا": 1,
    "لأبه": 1,
    "لأبها": 1,
    "لأبهم": 1,
    "لأبهما": 1,
    "لأبهن": 1,
    "لأجمع": 1,
    "لأجمعك": 1,
    "لأجمعكم": 1,
    "لأجمعكما": 1,
    "لأجمعكن": 1,
    "لأجمعنا": 1,
    "لأجمعه": 1,
    "لأجمعها": 1,
    "لأجمعهم": 1,
    "لأجمعهما": 1,
    "لأجمعهن": 1,
    "لأجمعي": 1,
    "لأخ": 1,
    "لأخذ": 1,
    "لأخك": 1,
    "لأخكم": 1,
    "لأخكما": 1,
    "لأخكن": 1,
    "لأخنا": 1,
    "لأخه": 1,
    "لأخها": 1,
    "لأخهم": 1,
    "لأخهما": 1,
    "لأخهن": 1,
    "لأخي": 1,
    "لأصبح": 1,
    "لأضحى": 1,
    "لأقبل": 1,
    "لأقل": 1,
    "لأقلك": 1,
    "لأقلكم": 1,
    "لأقلكما": 1,
    "لأقلكن": 1,
    "لأقلنا": 1,
    "لأقله": 1,
    "لأقلها": 1,
    "لأقلهم": 1,
    "لأقلهما": 1,
    "لأقلهن": 1,
    "لأقلي": 1,
    "لأكثرك": 1,
    "لأكثركم": 1,
    "لأكثركما": 1,
    "لأكثركن": 1,
    "لأكثرنا": 1,
    "لأكثره": 1,
    "لأكثرها": 1,
    "لأكثرهما": 1,
    "لأكثرهن": 1,
    "لأكثري": 1,
    "لأمام": 1,
    "لأمامك": 1,
    "لأمامكم": 1,
    "لأمامكما": 1,
    "لأمامكن": 1,
    "لأمامنا": 1,
    "لأمامه": 1,
    "لأمامها": 1,
    "لأمامهم": 1,
    "لأمامهما": 1,
    "لأمامهن": 1,
    "لأمامي": 1,
    "لأمسى": 1,
    "لأنا": 1,
    "لأنتما": 1,
    "لأنتن": 1,
    "لأنشأ": 1,
    "لأنك": 1,
    "لأنكم": 1,
    "لأنكما": 1,
    "لأنكن": 1,
    "لأننا": 1,
    "لأنها": 1,
    "لأنهم": 1,
    "لأنهما": 1,
    "لأنهن": 1,
    "لأني": 1,
    "لأوشك": 1,
    "لأولئك": 1,
    "لأولئكم": 1,
    "لأولاء": 1,
    "لأولالك": 1,
    "لأيك": 1,
    "لأيكم": 1,
    "لأيكما": 1,
    "لأيكن": 1,
    "لأينا": 1,
    "لأيه": 1,
    "لأيها": 1,
    "لأيهم": 1,
    "لأيهما": 1,
    "لأيهن": 1,
    "لأيي": 1,
    "لإلي": 1,
    "لإليك": 1,
    "لإليكم": 1,
    "لإليكما": 1,
    "لإليكن": 1,
    "لإلينا": 1,
    "لإليه": 1,
    "لإليها": 1,
    "لإليهم": 1,
    "لإليهما": 1,
    "لإليهن": 1,
    "لإن": 1,
    "لإنك": 1,
    "لإنكم": 1,
    "لإنكما": 1,
    "لإنكن": 1,
    "لإننا": 1,
    "لإنه": 1,
    "لإنها": 1,
    "لإنهم": 1,
    "لإنهما": 1,
    "لإنهن": 1,
    "لإني": 1,
    "لإياك": 1,
    "لإياكم": 1,
    "لإياكما": 1,
    "لإياكن": 1,
    "لإيانا": 1,
    "لإياه": 1,
    "لإياها": 1,
    "لإياهم": 1,
    "لإياهما": 1,
    "لإياهن": 1,
    "لإياي": 1,
    "لابتدأ": 1,
    "لات": 1,
    "لاخلولق": 1,
    "لارتد": 1,
    "لاستحال": 1,
    "لاسيما": 1,
    "لانبرى": 1,
    "لانقلب": 1,
    "لبات": 1,
    "لبعضك": 1,
    "لبعضكم": 1,
    "لبعضكما": 1,
    "لبعضكن": 1,
    "لبعضنا": 1,
    "لبعضه": 1,
    "لبعضها": 1,
    "لبعضهم": 1,
    "لبعضهما": 1,
    "لبعضهن": 1,
    "لبعضي": 1,
    "لتانك": 1,
    "لتبدل": 1,
    "لتجاه": 1,
    "لتجاهك": 1,
    "لتجاهكم": 1,
    "لتجاهكما": 1,
    "لتجاهكن": 1,
    "لتجاهنا": 1,
    "لتجاهه": 1,
    "لتجاهها": 1,
    "لتجاههم": 1,
    "لتجاههما": 1,
    "لتجاههن": 1,
    "لتجاهي": 1,
    "لتحت": 1,
    "لتحتك": 1,
    "لتحتكم": 1,
    "لتحتكما": 1,
    "لتحتكن": 1,
    "لتحتنا": 1,
    "لتحته": 1,
    "لتحتها": 1,
    "لتحتهم": 1,
    "لتحتهما": 1,
    "لتحتهن": 1,
    "لتحتي": 1,
    "لتحول": 1,
    "لتلقاء": 1,
    "لتلقاءك": 1,
    "لتلقاءكم": 1,
    "لتلقاءكما": 1,
    "لتلقاءكن": 1,
    "لتلقاءنا": 1,
    "لتلقاءه": 1,
    "لتلقاءها": 1,
    "لتلقاءهم": 1,
    "لتلقاءهما": 1,
    "لتلقاءهن": 1,
    "لتلقاءي": 1,
    "لتلك": 1,
    "لتلكم": 1,
    "لتلكما": 1,
    "لته": 1,
    "لتي": 1,
    "لتين": 1,
    "لتينك": 1,
    "لجميعك": 1,
    "لجميعكم": 1,
    "لجميعكما": 1,
    "لجميعكن": 1,
    "لجميعنا": 1,
    "لجميعه": 1,
    "لجميعها": 1,
    "لجميعهم": 1,
    "لجميعهما": 1,
    "لجميعهن": 1,
    "لجميعي": 1,
    "لحار": 1,
    "لحرى": 1,
    "لحسب": 1,
    "لحسبك": 1,
    "لحسبكم": 1,
    "لحسبكما": 1,
    "لحسبكن": 1,
    "لحسبنا": 1,
    "لحسبه": 1,
    "لحسبها": 1,
    "لحسبهم": 1,
    "لحسبهما": 1,
    "لحسبهن": 1,
    "لحسبي": 1,
    "لحمك": 1,
    "لحمكم": 1,
    "لحمكما": 1,
    "لحمكن": 1,
    "لحمنا": 1,
    "لحمه": 1,
    "لحمها": 1,
    "لحمهم": 1,
    "لحمهما": 1,
    "لحمهن": 1,
    "لحيث": 1,
    "لخلف": 1,
    "لخلفك": 1,
    "لخلفكم": 1,
    "لخلفكما": 1,
    "لخلفكن": 1,
    "لخلفنا": 1,
    "لخلفه": 1,
    "لخلفها": 1,
    "لخلفهم": 1,
    "لخلفهما": 1,
    "لخلفهن": 1,
    "لخلفي": 1,
    "لدنكم": 1,
    "لدنكما": 1,
    "لدنكن": 1,
    "لدننا": 1,
    "لدنها": 1,
    "لدنهم": 1,
    "لدنهما": 1,
    "لدنهن": 1,
    "لديك": 1,
    "لديكم": 1,
    "لديكما": 1,
    "لديكن": 1,
    "لديها": 1,
    "لديهما": 1,
    "لديهن": 1,
    "لذات": 1,
    "لذاك": 1,
    "لذانك": 1,
    "لذلكم": 1,
    "لذلكما": 1,
    "لذلكن": 1,
    "لذه": 1,
    "لذواتي": 1,
    "لذين": 1,
    "لذينك": 1,
    "لراح": 1,
    "لرجع": 1,
    "لستما": 1,
    "لسن": 1,
    "لسنا": 1,
    "لسوى": 1,
    "لسوي": 1,
    "لسويك": 1,
    "لسويكم": 1,
    "لسويكما": 1,
    "لسويكن": 1,
    "لسوينا": 1,
    "لسويه": 1,
    "لسويها": 1,
    "لسويهم": 1,
    "لسويهما": 1,
    "لسويهن": 1,
    "لشبهك": 1,
    "لشبهكم": 1,
    "لشبهكما": 1,
    "لشبهكن": 1,
    "لشبهنا": 1,
    "لشبهه": 1,
    "لشبهها": 1,
    "لشبههم": 1,
    "لشبههما": 1,
    "لشبههن": 1,
    "لشبهي": 1,
    "لشرع": 1,
    "لشمال": 1,
    "لشمالك": 1,
    "لشمالكم": 1,
    "لشمالكما": 1,
    "لشمالكن": 1,
    "لشمالنا": 1,
    "لشماله": 1,
    "لشمالها": 1,
    "لشمالهم": 1,
    "لشمالهما": 1,
    "لشمالهن": 1,
    "لشمالي": 1,
    "لصار": 1,
    "لطالما": 1,
    "لطفق": 1,
    "لظل": 1,
    "لعامة": 1,
    "لعامتك": 1,
    "لعامتكم": 1,
    "لعامتكما": 1,
    "لعامتكن": 1,
    "لعامتنا": 1,
    "لعامته": 1,
    "لعامتها": 1,
    "لعامتهم": 1,
    "لعامتهما": 1,
    "لعامتهن": 1,
    "لعامتي": 1,
    "لعسى": 1,
    "لعلق": 1,
    "لعلكما": 1,
    "لعلكن": 1,
    "لعلها": 1,
    "لعلهما": 1,
    "لعلهن": 1,
    "لعليك": 1,
    "لعليكم": 1,
    "لعليكما": 1,
    "لعليكن": 1,
    "لعلينا": 1,
    "لعليه": 1,
    "لعليها": 1,
    "لعليهم": 1,
    "لعليهما": 1,
    "لعليهن": 1,
    "لعمر": 1,
    "لعمركم": 1,
    "لعمركما": 1,
    "لعمركن": 1,
    "لعمرنا": 1,
    "لعمره": 1,
    "لعمرها": 1,
    "لعمرهم": 1,
    "لعمرهما": 1,
    "لعمرهن": 1,
    "لعمري": 1,
    "لعنك": 1,
    "لعنكم": 1,
    "لعنكما": 1,
    "لعنكن": 1,
    "لعننا": 1,
    "لعنها": 1,
    "لعنهما": 1,
    "لعنهن": 1,
    "لعني": 1,
    "لعينك": 1,
    "لعينكم": 1,
    "لعينكما": 1,
    "لعينكن": 1,
    "لعيننا": 1,
    "لعينه": 1,
    "لعينها": 1,
    "لعينهم": 1,
    "لعينهما": 1,
    "لعينهن": 1,
    "لعيني": 1,
    "لغدا": 1,
    "لغيرك": 1,
    "لغيركم": 1,
    "لغيركما": 1,
    "لغيركن": 1,
    "لغيرنا": 1,
    "لغيره": 1,
    "لغيرها": 1,
    "لغيرهم": 1,
    "لغيرهما": 1,
    "لغيرهن": 1,
    "لغيري": 1,
    "لفو": 1,
    "لفوق": 1,
    "لفوقك": 1,
    "لفوقكم": 1,
    "لفوقكما": 1,
    "لفوقكن": 1,
    "لفوقنا": 1,
    "لفوقه": 1,
    "لفوقها": 1,
    "لفوقهم": 1,
    "لفوقهما": 1,
    "لفوقهن": 1,
    "لفوقي": 1,
    "لفوك": 1,
    "لفوكم": 1,
    "لفوكما": 1,
    "لفوكن": 1,
    "لفونا": 1,
    "لفوه": 1,
    "لفوها": 1,
    "لفوهم": 1,
    "لفوهما": 1,
    "لفوهن": 1,
    "لفوي": 1,
    "لفيك": 1,
    "لفيكم": 1,
    "لفيكما": 1,
    "لفيكن": 1,
    "لفينا": 1,
    "لفيه": 1,
    "لفيها": 1,
    "لفيهم": 1,
    "لفيهما": 1,
    "لفيهن": 1,
    "لفيي": 1,
    "لقام": 1,
    "لقلما": 1,
    "لكأن": 1,
    "لكأنك": 1,
    "لكأنكم": 1,
    "لكأنكما": 1,
    "لكأنكن": 1,
    "لكأنما": 1,
    "لكأننا": 1,
    "لكأنه": 1,
    "لكأنها": 1,
    "لكأنهم": 1,
    "لكأنهما": 1,
    "لكأنهن": 1,
    "لكأني": 1,
    "لكاد": 1,
    "لكرب": 1,
    "لكلتا": 1,
    "لكلك": 1,
    "لكلكم": 1,
    "لكلكما": 1,
    "لكلكن": 1,
    "لكلنا": 1,
    "لكله": 1,
    "لكلها": 1,
    "لكلهم": 1,
    "لكلهما": 1,
    "لكلهن": 1,
    "لكلي": 1,
    "لكليكما": 1,
    "لكليهما": 1,
    "لكنك": 1,
    "لكنكم": 1,
    "لكنكما": 1,
    "لكنكن": 1,
    "لكنما": 1,
    "لكننا": 1,
    "لكنه": 1,
    "لكنها": 1,
    "لكنهم": 1,
    "لكنهما": 1,
    "لكنهن": 1,
    "لكني": 1,
    "للألاء": 1,
    "للألى": 1,
    "للائي": 1,
    "للاتي": 1,
    "للتيا": 1,
    "للتين": 1,
    "للواتي": 1,
    "لمثلك": 1,
    "لمثلكم": 1,
    "لمثلكما": 1,
    "لمثلكن": 1,
    "لمثلنا": 1,
    "لمثلها": 1,
    "لمثلهم": 1,
    "لمثلهما": 1,
    "لمثلهن": 1,
    "لمثلي": 1,
    "لمعك": 1,
    "لمعكما": 1,
    "لمعكن": 1,
    "لمعنا": 1,
    "لمعه": 1,
    "لمعها": 1,
    "لمعهم": 1,
    "لمعهما": 1,
    "لمعهن": 1,
    "لمعي": 1,
    "لمنك": 1,
    "لمنكما": 1,
    "لمنكن": 1,
    "لمننا": 1,
    "لمنه": 1,
    "لمنها": 1,
    "لمنهم": 1,
    "لمنهما": 1,
    "لمنهن": 1,
    "لمني": 1,
    "لنحو": 1,
    "لنحوك": 1,
    "لنحوكم": 1,
    "لنحوكما": 1,
    "لنحوكن": 1,
    "لنحونا": 1,
    "لنحوه": 1,
    "لنحوها": 1,
    "لنحوهم": 1,
    "لنحوهما": 1,
    "لنحوهن": 1,
    "لنحوي": 1,
    "لنفسك": 1,
    "لنفسكم": 1,
    "لنفسكما": 1,
    "لنفسكن": 1,
    "لنفسنا": 1,
    "لنفسها": 1,
    "لنفسهم": 1,
    "لنفسهما": 1,
    "لنفسهن": 1,
    "لهؤلاء": 1,
    "لهاته": 1,
    "لهاتي": 1,
    "لهاتين": 1,
    "لهذه": 1,
    "لهذي": 1,
    "لهذين": 1,
    "لهناك": 1,
    "لهنالك": 1,
    "لوما": 1,
    "ليتك": 1,
    "ليتكم": 1,
    "ليتكما": 1,
    "ليتكن": 1,
    "ليته": 1,
    "ليتهم": 1,
    "ليتهما": 1,
    "ليتهن": 1,
    "ليتي": 1,
    "ليستا": 1,
    "ليمين": 1,
    "ليمينك": 1,
    "ليمينكم": 1,
    "ليمينكما": 1,
    "ليمينكن": 1,
    "ليميننا": 1,
    "ليمينه": 1,
    "ليمينها": 1,
    "ليمينهم": 1,
    "ليمينهما": 1,
    "ليمينهن": 1,
    "ليميني": 1,
    "ماانفك": 1,
    "مابرح": 1,
    "مادام": 1,
    "مازال": 1,
    "مافتئ": 1,
    "مثلك": 1,
    "مثلكما": 1,
    "مثلكن": 1,
    "مثلهما": 1,
    "مذ": 1,
    "معاذك": 1,
    "معاذكم": 1,
    "معاذكما": 1,
    "معاذكن": 1,
    "معاذنا": 1,
    "معاذه": 1,
    "معاذها": 1,
    "معاذهم": 1,
    "معاذهما": 1,
    "معاذهن": 1,
    "معاذي": 1,
    "معكن": 1,
    "معهما": 1,
    "معهن": 1,
    "مكانك": 1,
    "مكانكما": 1,
    "مكانكن": 1,
    "منكما": 1,
    "مه": 1,
    "نحوك": 1,
    "نحوكم": 1,
    "نحوكما": 1,
    "نحوكن": 1,
    "نحونا": 1,
    "نحوه": 1,
    "نحوها": 1,
    "نحوهم": 1,
    "نحوهما": 1,
    "نحوهن": 1,
    "نخ": 1,
    "نفسكم": 1,
    "نفسكما": 1,
    "نفسكن": 1,
    "نفسنا": 1,
    "نفسهم": 1,
    "نفسهما": 1,
    "نفسهن": 1,
    "هاتان": 1,
    "هاته": 1,
    "هاك": 1,
    "هج": 1,
    "هذي": 1,
    "هذين": 1,
    "هلا": 1,
    "هيا": 1,
    "وآض": 1,
    "وآمين": 1,
    "وآناء": 1,
    "وآنفا": 1,
    "وآه": 1,
    "وأب": 1,
    "وأبدا": 1,
    "وأبك": 1,
    "وأبكم": 1,
    "وأبكما": 1,
    "وأبكن": 1,
    "وأبنا": 1,
    "وأبه": 1,
    "وأبها": 1,
    "وأبهم": 1,
    "وأبهما": 1,
    "وأبهن": 1,
    "وأبي": 1,
    "وأجمع": 1,
    "وأجمعك": 1,
    "وأجمعكم": 1,
    "وأجمعكما": 1,
    "وأجمعكن": 1,
    "وأجمعنا": 1,
    "وأجمعه": 1,
    "وأجمعها": 1,
    "وأجمعهم": 1,
    "وأجمعهما": 1,
    "وأجمعهن": 1,
    "وأجمعي": 1,
    "وأخ": 1,
    "وأخك": 1,
    "وأخكم": 1,
    "وأخكما": 1,
    "وأخكن": 1,
    "وأخنا": 1,
    "وأخه": 1,
    "وأخها": 1,
    "وأخهم": 1,
    "وأخهما": 1,
    "وأخهن": 1,
    "وأصلا": 1,
    "وأضحى": 1,
    "وأف": 1,
    "وأقلك": 1,
    "وأقلكم": 1,
    "وأقلكما": 1,
    "وأقلكن": 1,
    "وأقلنا": 1,
    "وأقله": 1,
    "وأقلها": 1,
    "وأقلهم": 1,
    "وأقلهما": 1,
    "وأقلهن": 1,
    "وأقلي": 1,
    "وأكثرك": 1,
    "وأكثركم": 1,
    "وأكثركما": 1,
    "وأكثركن": 1,
    "وأكثرنا": 1,
    "وأكثره": 1,
    "وأكثرها": 1,
    "وأكثرهما": 1,
    "وأكثرهن": 1,
    "وأكثري": 1,
    "وألا": 1,
    "وأمام": 1,
    "وأمامك": 1,
    "وأمامكم": 1,
    "وأمامكما": 1,
    "وأمامكن": 1,
    "وأمامنا": 1,
    "وأمامه": 1,
    "وأمامها": 1,
    "وأمامهم": 1,
    "وأمامهما": 1,
    "وأمامهن": 1,
    "وأمامي": 1,
    "وأمد": 1,
    "وأمس": 1,
    "وأمسى": 1,
    "وأنتما": 1,
    "وأنتن": 1,
    "وأنشأ": 1,
    "وأنكما": 1,
    "وأنكن": 1,
    "وأننا": 1,
    "وأنها": 1,
    "وأنهما": 1,
    "وأنهن": 1,
    "وأوشك": 1,
    "وأول": 1,
    "وأولاء": 1,
    "وأولالك": 1,
    "وأوه": 1,
    "وأي": 1,
    "وأيا": 1,
    "وأيان": 1,
    "وأيك": 1,
    "وأيكم": 1,
    "وأيكما": 1,
    "وأيكن": 1,
    "وأين": 1,
    "وأينا": 1,
    "وأينما": 1,
    "وأيه": 1,
    "وأيها": 1,
    "وأيهم": 1,
    "وأيهما": 1,
    "وأيهن": 1,
    "وأيي": 1,
    "وإذما": 1,
    "وإذن": 1,
    "وإزاء": 1,
    "وإليكم": 1,
    "وإليكما": 1,
    "وإليكن": 1,
    "وإليها": 1,
    "وإليهم": 1,
    "وإليهما": 1,
    "وإليهن": 1,
    "وإنكما": 1,
    "وإنكن": 1,
    "وإنهن": 1,
    "وإي": 1,
    "وإياكما": 1,
    "وإياكن": 1,
    "وإيانا": 1,
    "وإياه": 1,
    "وإياها": 1,
    "وإياهما": 1,
    "وإياهن": 1,
    "وإيه": 1,
    "وا": 1,
    "وابتدأ": 1,
    "واخلولق": 1,
    "وارتد": 1,
    "واستحال": 1,
    "والآن": 1,
    "والألاء": 1,
    "والألى": 1,
    "واللتان": 1,
    "واللتيا": 1,
    "واللتين": 1,
    "واللذين": 1,
    "واللواتي": 1,
    "وانبرى": 1,
    "وانقلب": 1,
    "واها": 1,
    "وبأب": 1,
    "وبأبك": 1,
    "وبأبكم": 1,
    "وبأبكما": 1,
    "وبأبكن": 1,
    "وبأبنا": 1,
    "وبأبه": 1,
    "وبأبها": 1,
    "وبأبهم": 1,
    "وبأبهما": 1,
    "وبأبهن": 1,
    "وبأبي": 1,
    "وبأجمع": 1,
    "وبأجمعك": 1,
    "وبأجمعكم": 1,
    "وبأجمعكما": 1,
    "وبأجمعكن": 1,
    "وبأجمعنا": 1,
    "وبأجمعه": 1,
    "وبأجمعها": 1,
    "وبأجمعهم": 1,
    "وبأجمعهما": 1,
    "وبأجمعهن": 1,
    "وبأجمعي": 1,
    "وبأخ": 1,
    "وبأخك": 1,
    "وبأخكم": 1,
    "وبأخكما": 1,
    "وبأخكن": 1,
    "وبأخنا": 1,
    "وبأخه": 1,
    "وبأخها": 1,
    "وبأخهم": 1,
    "وبأخهما": 1,
    "وبأخهن": 1,
    "وبأخي": 1,
    "وبأقل": 1,
    "وبأقلك": 1,
    "وبأقلكم": 1,
    "وبأقلكما": 1,
    "وبأقلكن": 1,
    "وبأقلنا": 1,
    "وبأقله": 1,
    "وبأقلها": 1,
    "وبأقلهم": 1,
    "وبأقلهما": 1,
    "وبأقلهن": 1,
    "وبأقلي": 1,
    "وبأكثر": 1,
    "وبأكثرك": 1,
    "وبأكثركم": 1,
    "وبأكثركما": 1,
    "وبأكثركن": 1,
    "وبأكثرنا": 1,
    "وبأكثره": 1,
    "وبأكثرها": 1,
    "وبأكثرهم": 1,
    "وبأكثرهما": 1,
    "وبأكثرهن": 1,
    "وبأكثري": 1,
    "وبأن": 1,
    "وبأنك": 1,
    "وبأنكم": 1,
    "وبأنكما": 1,
    "وبأنكن": 1,
    "وبأننا": 1,
    "وبأنه": 1,
    "وبأنها": 1,
    "وبأنهم": 1,
    "وبأنهما": 1,
    "وبأنهن": 1,
    "وبأني": 1,
    "وبأولئك": 1,
    "وبأولئكم": 1,
    "وبأولاء": 1,
    "وبأولالك": 1,
    "وبأي": 1,
    "وبأيك": 1,
    "وبأيكم": 1,
    "وبأيكما": 1,
    "وبأيكن": 1,
    "وبأينا": 1,
    "وبأيه": 1,
    "وبأيها": 1,
    "وبأيهم": 1,
    "وبأيهما": 1,
    "وبأيهن": 1,
    "وبأيي": 1,
    "وبإن": 1,
    "وبإنك": 1,
    "وبإنكم": 1,
    "وبإنكما": 1,
    "وبإنكن": 1,
    "وبإننا": 1,
    "وبإنه": 1,
    "وبإنها": 1,
    "وبإنهم": 1,
    "وبإنهما": 1,
    "وبإنهن": 1,
    "وبإني": 1,
    "وبإياك": 1,
    "وبإياكم": 1,
    "وبإياكما": 1,
    "وبإياكن": 1,
    "وبإيانا": 1,
    "وبإياه": 1,
    "وبإياها": 1,
    "وبإياهم": 1,
    "وبإياهما": 1,
    "وبإياهن": 1,
    "وبإياي": 1,
    "وبات": 1,
    "وبالألاء": 1,
    "وبالألى": 1,
    "وبالتي": 1,
    "وبالذين": 1,
    "وباللائي": 1,
    "وباللاتي": 1,
    "وباللتيا": 1,
    "وباللتين": 1,
    "وباللذين": 1,
    "وباللواتي": 1,
    "وببعض": 1,
    "وببعضك": 1,
    "وببعضكم": 1,
    "وببعضكما": 1,
    "وببعضكن": 1,
    "وببعضنا": 1,
    "وببعضه": 1,
    "وببعضها": 1,
    "وببعضهم": 1,
    "وببعضهما": 1,
    "وببعضهن": 1,
    "وببعضي": 1,
    "وبتانك": 1,
    "وبتجاه": 1,
    "وبتجاهك": 1,
    "وبتجاهكم": 1,
    "وبتجاهكما": 1,
    "وبتجاهكن": 1,
    "وبتجاهنا": 1,
    "وبتجاهه": 1,
    "وبتجاهها": 1,
    "وبتجاههم": 1,
    "وبتجاههما": 1,
    "وبتجاههن": 1,
    "وبتجاهي": 1,
    "وبتلقاء": 1,
    "وبتلقاءك": 1,
    "وبتلقاءكم": 1,
    "وبتلقاءكما": 1,
    "وبتلقاءكن": 1,
    "وبتلقاءنا": 1,
    "وبتلقاءه": 1,
    "وبتلقاءها": 1,
    "وبتلقاءهم": 1,
    "وبتلقاءهما": 1,
    "وبتلقاءهن": 1,
    "وبتلقاءي": 1,
    "وبتلك": 1,
    "وبتلكم": 1,
    "وبتلكما": 1,
    "وبته": 1,
    "وبتي": 1,
    "وبتين": 1,
    "وبتينك": 1,
    "وبجميع": 1,
    "وبجميعك": 1,
    "وبجميعكم": 1,
    "وبجميعكما": 1,
    "وبجميعكن": 1,
    "وبجميعنا": 1,
    "وبجميعه": 1,
    "وبجميعها": 1,
    "وبجميعهم": 1,
    "وبجميعهما": 1,
    "وبجميعهن": 1,
    "وبجميعي": 1,
    "وبحسب": 1,
    "وبحسبك": 1,
    "وبحسبكم": 1,
    "وبحسبكما": 1,
    "وبحسبكن": 1,
    "وبحسبنا": 1,
    "وبحسبه": 1,
    "وبحسبها": 1,
    "وبحسبهم": 1,
    "وبحسبهما": 1,
    "وبحسبهن": 1,
    "وبحسبي": 1,
    "وبحم": 1,
    "وبحمك": 1,
    "وبحمكم": 1,
    "وبحمكما": 1,
    "وبحمكن": 1,
    "وبحمنا": 1,
    "وبحمه": 1,
    "وبحمها": 1,
    "وبحمهم": 1,
    "وبحمهما": 1,
    "وبحمهن": 1,
    "وبحمي": 1,
    "وبحيث": 1,
    "وبذا": 1,
    "وبذات": 1,
    "وبذاك": 1,
    "وبذانك": 1,
    "وبذلكم": 1,
    "وبذلكما": 1,
    "وبذلكن": 1,
    "وبذه": 1,
    "وبذواتي": 1,
    "وبذين": 1,
    "وبذينك": 1,
    "وبس": 1,
    "وبسوى": 1,
    "وبسوي": 1,
    "وبسويك": 1,
    "وبسويكم": 1,
    "وبسويكما": 1,
    "وبسويكن": 1,
    "وبسوينا": 1,
    "وبسويه": 1,
    "وبسويها": 1,
    "وبسويهم": 1,
    "وبسويهما": 1,
    "وبسويهن": 1,
    "وبشبه": 1,
    "وبشبهك": 1,
    "وبشبهكم": 1,
    "وبشبهكما": 1,
    "وبشبهكن": 1,
    "وبشبهنا": 1,
    "وبشبهه": 1,
    "وبشبهها": 1,
    "وبشبههم": 1,
    "وبشبههما": 1,
    "وبشبههن": 1,
    "وبشبهي": 1,
    "وبضع": 1,
    "وبضعك": 1,
    "وبضعكم": 1,
    "وبضعكما": 1,
    "وبضعكن": 1,
    "وبضعنا": 1,
    "وبضعه": 1,
    "وبضعها": 1,
    "وبضعهم": 1,
    "وبضعهما": 1,
    "وبضعهن": 1,
    "وبضعي": 1,
    "وبطآن": 1,
    "وبعامة": 1,
    "وبعامتك": 1,
    "وبعامتكم": 1,
    "وبعامتكما": 1,
    "وبعامتكن": 1,
    "وبعامتنا": 1,
    "وبعامته": 1,
    "وبعامتها": 1,
    "وبعامتهم": 1,
    "وبعامتهما": 1,
    "وبعامتهن": 1,
    "وبعامتي": 1,
    "وبعد": 1,
    "وبعدك": 1,
    "وبعدكم": 1,
    "وبعدكما": 1,
    "وبعدكن": 1,
    "وبعدنا": 1,
    "وبعده": 1,
    "وبعدها": 1,
    "وبعدهم": 1,
    "وبعدهما": 1,
    "وبعدهن": 1,
    "وبعدي": 1,
    "وبعض": 1,
    "وبعضك": 1,
    "وبعضكم": 1,
    "وبعضكما": 1,
    "وبعضكن": 1,
    "وبعضنا": 1,
    "وبعضه": 1,
    "وبعضها": 1,
    "وبعضهم": 1,
    "وبعضهما": 1,
    "وبعضهن": 1,
    "وبعضي": 1,
    "وبعين": 1,
    "وبعينك": 1,
    "وبعينكم": 1,
    "وبعينكما": 1,
    "وبعينكن": 1,
    "وبعيننا": 1,
    "وبعينه": 1,
    "وبعينها": 1,
    "وبعينهم": 1,
    "وبعينهما": 1,
    "وبعينهن": 1,
    "وبعيني": 1,
    "وبغير": 1,
    "وبغيرك": 1,
    "وبغيركم": 1,
    "وبغيركما": 1,
    "وبغيركن": 1,
    "وبغيرنا": 1,
    "وبغيره": 1,
    "وبغيرها": 1,
    "وبغيرهم": 1,
    "وبغيرهما": 1,
    "وبغيرهن": 1,
    "وبغيري": 1,
    "وبفو": 1,
    "وبفوك": 1,
    "وبفوكم": 1,
    "وبفوكما": 1,
    "وبفوكن": 1,
    "وبفونا": 1,
    "وبفوه": 1,
    "وبفوها": 1,
    "وبفوهم": 1,
    "وبفوهما": 1,
    "وبفوهن": 1,
    "وبفوي": 1,
    "وبك": 1,
    "وبكل": 1,
    "وبكلتا": 1,
    "وبكلك": 1,
    "وبكلكم": 1,
    "وبكلكما": 1,
    "وبكلكن": 1,
    "وبكلنا": 1,
    "وبكله": 1,
    "وبكلها": 1,
    "وبكلهم": 1,
    "وبكلهما": 1,
    "وبكلهن": 1,
    "وبكلي": 1,
    "وبكليكما": 1,
    "وبكليهما": 1,
    "وبكن": 1,
    "وبله": 1,
    "وبماذا": 1,
    "وبمثل": 1,
    "وبمثلك": 1,
    "وبمثلكم": 1,
    "وبمثلكما": 1,
    "وبمثلكن": 1,
    "وبمثلنا": 1,
    "وبمثله": 1,
    "وبمثلها": 1,
    "وبمثلهم": 1,
    "وبمثلهما": 1,
    "وبمثلهن": 1,
    "وبمثلي": 1,
    "وبنا": 1,
    "وبنحو": 1,
    "وبنحوك": 1,
    "وبنحوكم": 1,
    "وبنحوكما": 1,
    "وبنحوكن": 1,
    "وبنحونا": 1,
    "وبنحوه": 1,
    "وبنحوها": 1,
    "وبنحوهم": 1,
    "وبنحوهما": 1,
    "وبنحوهن": 1,
    "وبنحوي": 1,
    "وبنفس": 1,
    "وبنفسك": 1,
    "وبنفسكم": 1,
    "وبنفسكما": 1,
    "وبنفسكن": 1,
    "وبنفسنا": 1,
    "وبنفسه": 1,
    "وبنفسها": 1,
    "وبنفسهم": 1,
    "وبنفسهما": 1,
    "وبنفسهن": 1,
    "وبنفسي": 1,
    "وبهؤلاء": 1,
    "وبها": 1,
    "وبهاته": 1,
    "وبهاتي": 1,
    "وبهاتين": 1,
    "وبهذا": 1,
    "وبهذه": 1,
    "وبهذي": 1,
    "وبهذين": 1,
    "وبهم": 1,
    "وبهما": 1,
    "وبهن": 1,
    "وبهناك": 1,
    "وبهنالك": 1,
    "وبهو": 1,
    "وبهي": 1,
    "وبي": 1,
    "وبيد": 1,
    "وبينكما": 1,
    "وبينكن": 1,
    "وبيننا": 1,
    "وبينها": 1,
    "وبينهن": 1,
    "وبيني": 1,
    "وتارة": 1,
    "وتان": 1,
    "وتانك": 1,
    "وتبدل": 1,
    "وتجاه": 1,
    "وتجاهك": 1,
    "وتجاهكم": 1,
    "وتجاهكما": 1,
    "وتجاهكن": 1,
    "وتجاهنا": 1,
    "وتجاهه": 1,
    "وتجاهها": 1,
    "وتجاههم": 1,
    "وتجاههما": 1,
    "وتجاههن": 1,
    "وتجاهي": 1,
    "وتحت": 1,
    "وتحتك": 1,
    "وتحتكم": 1,
    "وتحتكما": 1,
    "وتحتكن": 1,
    "وتحتنا": 1,
    "وتحته": 1,
    "وتحتها": 1,
    "وتحتهم": 1,
    "وتحتهما": 1,
    "وتحتهن": 1,
    "وتحتي": 1,
    "وتحول": 1,
    "وتلقاء": 1,
    "وتلقاءك": 1,
    "وتلقاءكم": 1,
    "وتلقاءكما": 1,
    "وتلقاءكن": 1,
    "وتلقاءنا": 1,
    "وتلقاءه": 1,
    "وتلقاءها": 1,
    "وتلقاءهم": 1,
    "وتلقاءهما": 1,
    "وتلقاءهن": 1,
    "وتلقاءي": 1,
    "وتلكم": 1,
    "وتلكما": 1,
    "وته": 1,
    "وتي": 1,
    "وتين": 1,
    "وتينك": 1,
    "وثم": 1,
    "وثمة": 1,
    "وجميع": 1,
    "وجميعك": 1,
    "وجميعكم": 1,
    "وجميعكما": 1,
    "وجميعكن": 1,
    "وجميعنا": 1,
    "وجميعه": 1,
    "وجميعها": 1,
    "وجميعهم": 1,
    "وجميعهما": 1,
    "وجميعهن": 1,
    "وجميعي": 1,
    "وجير": 1,
    "وحار": 1,
    "وحاشا": 1,
    "وحاشاك": 1,
    "وحاشاكم": 1,
    "وحاشاكما": 1,
    "وحاشاكن": 1,
    "وحاشانا": 1,
    "وحاشاه": 1,
    "وحاشاها": 1,
    "وحاشاهم": 1,
    "وحاشاهما": 1,
    "وحاشاهن": 1,
    "وحاشاي": 1,
    "وحبذا": 1,
    "وحتى": 1,
    "وحذار": 1,
    "وحرى": 1,
    "وحسب": 1,
    "وحسبك": 1,
    "وحسبكم": 1,
    "وحسبكما": 1,
    "وحسبكن": 1,
    "وحسبنا": 1,
    "وحسبه": 1,
    "وحسبها": 1,
    "وحسبهم": 1,
    "وحسبهما": 1,
    "وحسبهن": 1,
    "وحسبي": 1,
    "وحم": 1,
    "وحمك": 1,
    "وحمكم": 1,
    "وحمكما": 1,
    "وحمكن": 1,
    "وحمنا": 1,
    "وحمه": 1,
    "وحمها": 1,
    "وحمهم": 1,
    "وحمهما": 1,
    "وحمهن": 1,
    "وحمي": 1,
    "وحوالى": 1,
    "وحوالي": 1,
    "وحواليك": 1,
    "وحواليكم": 1,
    "وحواليكما": 1,
    "وحواليكن": 1,
    "وحوالينا": 1,
    "وحواليه": 1,
    "وحواليها": 1,
    "وحواليهم": 1,
    "وحواليهما": 1,
    "وحواليهن": 1,
    "وحول": 1,
    "وحولك": 1,
    "وحولكم": 1,
    "وحولكما": 1,
    "وحولكن": 1,
    "وحولنا": 1,
    "وحوله": 1,
    "وحولها": 1,
    "وحولهم": 1,
    "وحولهما": 1,
    "وحولهن": 1,
    "وحولي": 1,
    "وحيثما": 1,
    "وحينك": 1,
    "وحينكم": 1,
    "وحينكما": 1,
    "وحينكن": 1,
    "وحيننا": 1,
    "وحينه": 1,
    "وحينها": 1,
    "وحينهم": 1,
    "وحينهما": 1,
    "وحينهن": 1,
    "وحيني": 1,
    "وخلا": 1,
    "وخلاك": 1,
    "وخلاكم": 1,
    "وخلاكما": 1,
    "وخلاكن": 1,
    "وخلال": 1,
    "وخلالك": 1,
    "وخلالكم": 1,
    "وخلالكما": 1,
    "وخلالكن": 1,
    "وخلالنا": 1,
    "وخلاله": 1,
    "وخلالها": 1,
    "وخلالهم": 1,
    "وخلالهما": 1,
    "وخلالهن": 1,
    "وخلالي": 1,
    "وخلانا": 1,
    "وخلاه": 1,
    "وخلاها": 1,
    "وخلاهم": 1,
    "وخلاهما": 1,
    "وخلاهن": 1,
    "وخلاي": 1,
    "وخلف": 1,
    "وخلفك": 1,
    "وخلفكم": 1,
    "وخلفكما": 1,
    "وخلفكن": 1,
    "وخلفنا": 1,
    "وخلفه": 1,
    "وخلفها": 1,
    "وخلفهم": 1,
    "وخلفهما": 1,
    "وخلفهن": 1,
    "وخلفي": 1,
    "ودونك": 1,
    "وذاك": 1,
    "وذان": 1,
    "وذانك": 1,
    "وذلكما": 1,
    "وذلكن": 1,
    "وذه": 1,
    "وذوا": 1,
    "وذواتا": 1,
    "وذواتي": 1,
    "وذيت": 1,
    "وذين": 1,
    "وذينك": 1,
    "وراءك": 1,
    "وراح": 1,
    "ورجع": 1,
    "ورويدك": 1,
    "وريث": 1,
    "وساءما": 1,
    "وسبحانك": 1,
    "وسبحانكم": 1,
    "وسبحانكما": 1,
    "وسبحانكن": 1,
    "وسبحاننا": 1,
    "وسبحانه": 1,
    "وسبحانها": 1,
    "وسبحانهم": 1,
    "وسبحانهما": 1,
    "وسبحانهن": 1,
    "وسبحاني": 1,
    "وسرعان": 1,
    "وسوى": 1,
    "وسوي": 1,
    "وسويك": 1,
    "وسويكم": 1,
    "وسويكما": 1,
    "وسويكن": 1,
    "وسوينا": 1,
    "وسويه": 1,
    "وسويها": 1,
    "وسويهم": 1,
    "وسويهما": 1,
    "وسويهن": 1,
    "وشبه": 1,
    "وشبهك": 1,
    "وشبهكم": 1,
    "وشبهكما": 1,
    "وشبهكن": 1,
    "وشبهنا": 1,
    "وشبهه": 1,
    "وشبهها": 1,
    "وشبههم": 1,
    "وشبههما": 1,
    "وشبههن": 1,
    "وشبهي": 1,
    "وشتان": 1,
    "وشرع": 1,
    "وشكان": 1,
    "وشمالك": 1,
    "وشمالكم": 1,
    "وشمالكما": 1,
    "وشمالكن": 1,
    "وشمالنا": 1,
    "وشماله": 1,
    "وشمالها": 1,
    "وشمالهم": 1,
    "وشمالهما": 1,
    "وشمالهن": 1,
    "وشمالي": 1,
    "وصار": 1,
    "وصباح": 1,
    "وضحوة": 1,
    "وضمن": 1,
    "وضمنك": 1,
    "وضمنكم": 1,
    "وضمنكما": 1,
    "وضمنكن": 1,
    "وضمننا": 1,
    "وضمنه": 1,
    "وضمنها": 1,
    "وضمنهم": 1,
    "وضمنهما": 1,
    "وضمنهن": 1,
    "وضمني": 1,
    "وطالما": 1,
    "وطفق": 1,
    "وعامة": 1,
    "وعامتك": 1,
    "وعامتكم": 1,
    "وعامتكما": 1,
    "وعامتكن": 1,
    "وعامتنا": 1,
    "وعامته": 1,
    "وعامتها": 1,
    "وعامتهم": 1,
    "وعامتهما": 1,
    "وعامتهن": 1,
    "وعامتي": 1,
    "وعداك": 1,
    "وعداكم": 1,
    "وعداكما": 1,
    "وعداكن": 1,
    "وعدانا": 1,
    "وعداه": 1,
    "وعداها": 1,
    "وعداهم": 1,
    "وعداهما": 1,
    "وعداهن": 1,
    "وعداي": 1,
    "وعل": 1,
    "وعلق": 1,
    "وعلي": 1,
    "وعليك": 1,
    "وعليكما": 1,
    "وعليكن": 1,
    "وعليهما": 1,
    "وعليهن": 1,
    "وعما": 1,
    "وعندك": 1,
    "وعندكم": 1,
    "وعندكما": 1,
    "وعندكن": 1,
    "وعندها": 1,
    "وعندهما": 1,
    "وعندهن": 1,
    "وعندي": 1,
    "وعنك": 1,
    "وعنكم": 1,
    "وعنكما": 1,
    "وعنكن": 1,
    "وعننا": 1,
    "وعنه": 1,
    "وعنها": 1,
    "وعنهم": 1,
    "وعنهما": 1,
    "وعنهن": 1,
    "وعني": 1,
    "وعوض": 1,
    "وعين": 1,
    "وعينك": 1,
    "وعينكم": 1,
    "وعينكما": 1,
    "وعينكن": 1,
    "وعيننا": 1,
    "وعينه": 1,
    "وعينها": 1,
    "وعينهم": 1,
    "وعينهما": 1,
    "وعينهن": 1,
    "وعيني": 1,
    "وغدا": 1,
    "وغداة": 1,
    "وغيرك": 1,
    "وغيركم": 1,
    "وغيركما": 1,
    "وغيركن": 1,
    "وغيرنا": 1,
    "وغيره": 1,
    "وغيرها": 1,
    "وغيرهم": 1,
    "وغيرهما": 1,
    "وغيرهن": 1,
    "وغيري": 1,
    "وفلان": 1,
    "وفو": 1,
    "وفوقك": 1,
    "وفوقكم": 1,
    "وفوقكما": 1,
    "وفوقكن": 1,
    "وفوقنا": 1,
    "وفوقه": 1,
    "وفوقها": 1,
    "وفوقهم": 1,
    "وفوقهما": 1,
    "وفوقهن": 1,
    "وفوقي": 1,
    "وفوك": 1,
    "وفوكم": 1,
    "وفوكما": 1,
    "وفوكن": 1,
    "وفونا": 1,
    "وفوه": 1,
    "وفوها": 1,
    "وفوهم": 1,
    "وفوهما": 1,
    "وفوهن": 1,
    "وفوي": 1,
    "وفيك": 1,
    "وفيكما": 1,
    "وفيكن": 1,
    "وفيم": 1,
    "وفيما": 1,
    "وفينا": 1,
    "وفيهم": 1,
    "وفيهما": 1,
    "وفيهن": 1,
    "وفيي": 1,
    "وقام": 1,
    "وقط": 1,
    "وقلما": 1,
    "وكأب": 1,
    "وكأبك": 1,
    "وكأبكم": 1,
    "وكأبكما": 1,
    "وكأبكن": 1,
    "وكأبنا": 1,
    "وكأبه": 1,
    "وكأبها": 1,
    "وكأبهم": 1,
    "وكأبهما": 1,
    "وكأبهن": 1,
    "وكأبي": 1,
    "وكأجمع": 1,
    "وكأجمعك": 1,
    "وكأجمعكم": 1,
    "وكأجمعكما": 1,
    "وكأجمعكن": 1,
    "وكأجمعنا": 1,
    "وكأجمعه": 1,
    "وكأجمعها": 1,
    "وكأجمعهم": 1,
    "وكأجمعهما": 1,
    "وكأجمعهن": 1,
    "وكأجمعي": 1,
    "وكأخ": 1,
    "وكأخك": 1,
    "وكأخكم": 1,
    "وكأخكما": 1,
    "وكأخكن": 1,
    "وكأخنا": 1,
    "وكأخه": 1,
    "وكأخها": 1,
    "وكأخهم": 1,
    "وكأخهما": 1,
    "وكأخهن": 1,
    "وكأخي": 1,
    "وكأقل": 1,
    "وكأقلك": 1,
    "وكأقلكم": 1,
    "وكأقلكما": 1,
    "وكأقلكن": 1,
    "وكأقلنا": 1,
    "وكأقله": 1,
    "وكأقلها": 1,
    "وكأقلهم": 1,
    "وكأقلهما": 1,
    "وكأقلهن": 1,
    "وكأقلي": 1,
    "وكأكثر": 1,
    "وكأكثرك": 1,
    "وكأكثركم": 1,
    "وكأكثركما": 1,
    "وكأكثركن": 1,
    "وكأكثرنا": 1,
    "وكأكثره": 1,
    "وكأكثرها": 1,
    "وكأكثرهم": 1,
    "وكأكثرهما": 1,
    "وكأكثرهن": 1,
    "وكأكثري": 1,
    "وكأن": 1,
    "وكأنك": 1,
    "وكأنكم": 1,
    "وكأنكما": 1,
    "وكأنكن": 1,
    "وكأنما": 1,
    "وكأننا": 1,
    "وكأنه": 1,
    "وكأنها": 1,
    "وكأنهم": 1,
    "وكأنهما": 1,
    "وكأنهن": 1,
    "وكأني": 1,
    "وكأولئك": 1,
    "وكأولئكم": 1,
    "وكأولاء": 1,
    "وكأولالك": 1,
    "وكأي": 1,
    "وكأيك": 1,
    "وكأيكم": 1,
    "وكأيكما": 1,
    "وكأيكن": 1,
    "وكأينا": 1,
    "وكأيه": 1,
    "وكأيها": 1,
    "وكأيهم": 1,
    "وكأيهما": 1,
    "وكأيهن": 1,
    "وكأيي": 1,
    "وكإن": 1,
    "وكإنك": 1,
    "وكإنكم": 1,
    "وكإنكما": 1,
    "وكإنكن": 1,
    "وكإننا": 1,
    "وكإنه": 1,
    "وكإنها": 1,
    "وكإنهم": 1,
    "وكإنهما": 1,
    "وكإنهن": 1,
    "وكإني": 1,
    "وكإياك": 1,
    "وكإياكم": 1,
    "وكإياكما": 1,
    "وكإياكن": 1,
    "وكإيانا": 1,
    "وكإياه": 1,
    "وكإياها": 1,
    "وكإياهم": 1,
    "وكإياهما": 1,
    "وكإياهن": 1,
    "وكإياي": 1,
    "وكاد": 1,
    "وكالألاء": 1,
    "وكالألى": 1,
    "وكالتي": 1,
    "وكالذي": 1,
    "وكالذين": 1,
    "وكاللائي": 1,
    "وكاللاتي": 1,
    "وكاللتيا": 1,
    "وكاللتين": 1,
    "وكاللذين": 1,
    "وكاللواتي": 1,
    "وكبعض": 1,
    "وكبعضك": 1,
    "وكبعضكم": 1,
    "وكبعضكما": 1,
    "وكبعضكن": 1,
    "وكبعضنا": 1,
    "وكبعضه": 1,
    "وكبعضها": 1,
    "وكبعضهم": 1,
    "وكبعضهما": 1,
    "وكبعضهن": 1,
    "وكبعضي": 1,
    "وكتانك": 1,
    "وكتجاه": 1,
    "وكتجاهك": 1,
    "وكتجاهكم": 1,
    "وكتجاهكما": 1,
    "وكتجاهكن": 1,
    "وكتجاهنا": 1,
    "وكتجاهه": 1,
    "وكتجاهها": 1,
    "وكتجاههم": 1,
    "وكتجاههما": 1,
    "وكتجاههن": 1,
    "وكتجاهي": 1,
    "وكتلقاء": 1,
    "وكتلقاءك": 1,
    "وكتلقاءكم": 1,
    "وكتلقاءكما": 1,
    "وكتلقاءكن": 1,
    "وكتلقاءنا": 1,
    "وكتلقاءه": 1,
    "وكتلقاءها": 1,
    "وكتلقاءهم": 1,
    "وكتلقاءهما": 1,
    "وكتلقاءهن": 1,
    "وكتلقاءي": 1,
    "وكتلك": 1,
    "وكتلكم": 1,
    "وكتلكما": 1,
    "وكته": 1,
    "وكتي": 1,
    "وكتين": 1,
    "وكتينك": 1,
    "وكجميع": 1,
    "وكجميعك": 1,
    "وكجميعكم": 1,
    "وكجميعكما": 1,
    "وكجميعكن": 1,
    "وكجميعنا": 1,
    "وكجميعه": 1,
    "وكجميعها": 1,
    "وكجميعهم": 1,
    "وكجميعهما": 1,
    "وكجميعهن": 1,
    "وكجميعي": 1,
    "وكحسب": 1,
    "وكحسبك": 1,
    "وكحسبكم": 1,
    "وكحسبكما": 1,
    "وكحسبكن": 1,
    "وكحسبنا": 1,
    "وكحسبه": 1,
    "وكحسبها": 1,
    "وكحسبهم": 1,
    "وكحسبهما": 1,
    "وكحسبهن": 1,
    "وكحسبي": 1,
    "وكحم": 1,
    "وكحمك": 1,
    "وكحمكم": 1,
    "وكحمكما": 1,
    "وكحمكن": 1,
    "وكحمنا": 1,
    "وكحمه": 1,
    "وكحمها": 1,
    "وكحمهم": 1,
    "وكحمهما": 1,
    "وكحمهن": 1,
    "وكحمي": 1,
    "وكحيث": 1,
    "وكذات": 1,
    "وكذاك": 1,
    "وكذانك": 1,
    "وكذلكم": 1,
    "وكذلكما": 1,
    "وكذلكن": 1,
    "وكذه": 1,
    "وكذواتي": 1,
    "وكذي": 1,
    "وكذين": 1,
    "وكذينك": 1,
    "وكرب": 1,
    "وكسوى": 1,
    "وكسوي": 1,
    "وكسويك": 1,
    "وكسويكم": 1,
    "وكسويكما": 1,
    "وكسويكن": 1,
    "وكسوينا": 1,
    "وكسويه": 1,
    "وكسويها": 1,
    "وكسويهم": 1,
    "وكسويهما": 1,
    "وكسويهن": 1,
    "وكشبه": 1,
    "وكشبهك": 1,
    "وكشبهكم": 1,
    "وكشبهكما": 1,
    "وكشبهكن": 1,
    "وكشبهنا": 1,
    "وكشبهه": 1,
    "وكشبهها": 1,
    "وكشبههم": 1,
    "وكشبههما": 1,
    "وكشبههن": 1,
    "وكشبهي": 1,
    "وكعامة": 1,
    "وكعامتك": 1,
    "وكعامتكم": 1,
    "وكعامتكما": 1,
    "وكعامتكن": 1,
    "وكعامتنا": 1,
    "وكعامته": 1,
    "وكعامتها": 1,
    "وكعامتهم": 1,
    "وكعامتهما": 1,
    "وكعامتهن": 1,
    "وكعامتي": 1,
    "وكعين": 1,
    "وكعينك": 1,
    "وكعينكم": 1,
    "وكعينكما": 1,
    "وكعينكن": 1,
    "وكعيننا": 1,
    "وكعينه": 1,
    "وكعينها": 1,
    "وكعينهم": 1,
    "وكعينهما": 1,
    "وكعينهن": 1,
    "وكعيني": 1,
    "وكغير": 1,
    "وكغيرك": 1,
    "وكغيركم": 1,
    "وكغيركما": 1,
    "وكغيركن": 1,
    "وكغيرنا": 1,
    "وكغيره": 1,
    "وكغيرها": 1,
    "وكغيرهم": 1,
    "وكغيرهما": 1,
    "وكغيرهن": 1,
    "وكغيري": 1,
    "وكفو": 1,
    "وكفوك": 1,
    "وكفوكم": 1,
    "وكفوكما": 1,
    "وكفوكن": 1,
    "وكفونا": 1,
    "وكفوه": 1,
    "وكفوها": 1,
    "وكفوهم": 1,
    "وكفوهما": 1,
    "وكفوهن": 1,
    "وكفوي": 1,
    "وككل": 1,
    "وككلتا": 1,
    "وككلك": 1,
    "وككلكم": 1,
    "وككلكما": 1,
    "وككلكن": 1,
    "وككلنا": 1,
    "وككله": 1,
    "وككلها": 1,
    "وككلهم": 1,
    "وككلهما": 1,
    "وككلهن": 1,
    "وككلي": 1,
    "وككليكما": 1,
    "وككليهما": 1,
    "وكلاهما": 1,
    "وكلتا": 1,
    "وكلك": 1,
    "وكلكم": 1,
    "وكلكما": 1,
    "وكلكن": 1,
    "وكله": 1,
    "وكلها": 1,
    "وكلهما": 1,
    "وكلهن": 1,
    "وكلي": 1,
    "وكليكما": 1,
    "وكليهما": 1,
    "وكما": 1,
    "وكمثل": 1,
    "وكمثلك": 1,
    "وكمثلكم": 1,
    "وكمثلكما": 1,
    "وكمثلكن": 1,
    "وكمثلنا": 1,
    "وكمثله": 1,
    "وكمثلها": 1,
    "وكمثلهم": 1,
    "وكمثلهما": 1,
    "وكمثلهن": 1,
    "وكمثلي": 1,
    "وكمن": 1,
    "وكنحو": 1,
    "وكنحوك": 1,
    "وكنحوكم": 1,
    "وكنحوكما": 1,
    "وكنحوكن": 1,
    "وكنحونا": 1,
    "وكنحوه": 1,
    "وكنحوها": 1,
    "وكنحوهم": 1,
    "وكنحوهما": 1,
    "وكنحوهن": 1,
    "وكنحوي": 1,
    "وكنفس": 1,
    "وكنفسك": 1,
    "وكنفسكم": 1,
    "وكنفسكما": 1,
    "وكنفسكن": 1,
    "وكنفسنا": 1,
    "وكنفسه": 1,
    "وكنفسها": 1,
    "وكنفسهم": 1,
    "وكنفسهما": 1,
    "وكنفسهن": 1,
    "وكنفسي": 1,
    "وكهؤلاء": 1,
    "وكهاته": 1,
    "وكهاتي": 1,
    "وكهاتين": 1,
    "وكهذا": 1,
    "وكهذه": 1,
    "وكهذي": 1,
    "وكهذين": 1,
    "وكهم": 1,
    "وكهما": 1,
    "وكهن": 1,
    "وكهناك": 1,
    "وكهنالك": 1,
    "وكهو": 1,
    "وكهي": 1,
    "وكي": 1,
    "وكيت": 1,
    "وكيفما": 1,
    "ولآض": 1,
    "ولأب": 1,
    "ولأبك": 1,
    "ولأبكم": 1,
    "ولأبكما": 1,
    "ولأبكن": 1,
    "ولأبنا": 1,
    "ولأبه": 1,
    "ولأبها": 1,
    "ولأبهم": 1,
    "ولأبهما": 1,
    "ولأبهن": 1,
    "ولأبي": 1,
    "ولأجمع": 1,
    "ولأجمعك": 1,
    "ولأجمعكم": 1,
    "ولأجمعكما": 1,
    "ولأجمعكن": 1,
    "ولأجمعنا": 1,
    "ولأجمعه": 1,
    "ولأجمعها": 1,
    "ولأجمعهم": 1,
    "ولأجمعهما": 1,
    "ولأجمعهن": 1,
    "ولأجمعي": 1,
    "ولأخ": 1,
    "ولأخذ": 1,
    "ولأخك": 1,
    "ولأخكم": 1,
    "ولأخكما": 1,
    "ولأخكن": 1,
    "ولأخنا": 1,
    "ولأخه": 1,
    "ولأخها": 1,
    "ولأخهم": 1,
    "ولأخهما": 1,
    "ولأخهن": 1,
    "ولأصبح": 1,
    "ولأضحى": 1,
    "ولأقبل": 1,
    "ولأقل": 1,
    "ولأقلك": 1,
    "ولأقلكم": 1,
    "ولأقلكما": 1,
    "ولأقلكن": 1,
    "ولأقلنا": 1,
    "ولأقله": 1,
    "ولأقلها": 1,
    "ولأقلهم": 1,
    "ولأقلهما": 1,
    "ولأقلهن": 1,
    "ولأقلي": 1,
    "ولأكثر": 1,
    "ولأكثرك": 1,
    "ولأكثركم": 1,
    "ولأكثركما": 1,
    "ولأكثركن": 1,
    "ولأكثرنا": 1,
    "ولأكثره": 1,
    "ولأكثرها": 1,
    "ولأكثرهم": 1,
    "ولأكثرهما": 1,
    "ولأكثرهن": 1,
    "ولأكثري": 1,
    "ولأمام": 1,
    "ولأمامك": 1,
    "ولأمامكم": 1,
    "ولأمامكما": 1,
    "ولأمامكن": 1,
    "ولأمامنا": 1,
    "ولأمامه": 1,
    "ولأمامها": 1,
    "ولأمامهم": 1,
    "ولأمامهما": 1,
    "ولأمامهن": 1,
    "ولأمامي": 1,
    "ولأمسى": 1,
    "ولأن": 1,
    "ولأنا": 1,
    "ولأنت": 1,
    "ولأنتم": 1,
    "ولأنتما": 1,
    "ولأنتن": 1,
    "ولأنشأ": 1,
    "ولأنك": 1,
    "ولأنكم": 1,
    "ولأنكما": 1,
    "ولأنكن": 1,
    "ولأننا": 1,
    "ولأنه": 1,
    "ولأنها": 1,
    "ولأنهم": 1,
    "ولأنهما": 1,
    "ولأنهن": 1,
    "ولأني": 1,
    "ولأوشك": 1,
    "ولأولئك": 1,
    "ولأولئكم": 1,
    "ولأولاء": 1,
    "ولأولالك": 1,
    "ولأي": 1,
    "ولأيك": 1,
    "ولأيكم": 1,
    "ولأيكما": 1,
    "ولأيكن": 1,
    "ولأينا": 1,
    "ولأيه": 1,
    "ولأيها": 1,
    "ولأيهم": 1,
    "ولأيهما": 1,
    "ولأيهن": 1,
    "ولأيي": 1,
    "ولإلى": 1,
    "ولإلي": 1,
    "ولإليك": 1,
    "ولإليكم": 1,
    "ولإليكما": 1,
    "ولإليكن": 1,
    "ولإلينا": 1,
    "ولإليه": 1,
    "ولإليها": 1,
    "ولإليهم": 1,
    "ولإليهما": 1,
    "ولإليهن": 1,
    "ولإن": 1,
    "ولإنك": 1,
    "ولإنكم": 1,
    "ولإنكما": 1,
    "ولإنكن": 1,
    "ولإننا": 1,
    "ولإنه": 1,
    "ولإنها": 1,
    "ولإنهم": 1,
    "ولإنهما": 1,
    "ولإنهن": 1,
    "ولإني": 1,
    "ولإياك": 1,
    "ولإياكم": 1,
    "ولإياكما": 1,
    "ولإياكن": 1,
    "ولإيانا": 1,
    "ولإياه": 1,
    "ولإياها": 1,
    "ولإياهم": 1,
    "ولإياهما": 1,
    "ولإياهن": 1,
    "ولإياي": 1,
    "ولابتدأ": 1,
    "ولاخلولق": 1,
    "ولارتد": 1,
    "ولاستحال": 1,
    "ولاسيما": 1,
    "ولانبرى": 1,
    "ولانقلب": 1,
    "ولبات": 1,
    "ولبعض": 1,
    "ولبعضك": 1,
    "ولبعضكم": 1,
    "ولبعضكما": 1,
    "ولبعضكن": 1,
    "ولبعضنا": 1,
    "ولبعضه": 1,
    "ولبعضها": 1,
    "ولبعضهم": 1,
    "ولبعضهما": 1,
    "ولبعضهن": 1,
    "ولبعضي": 1,
    "ولتانك": 1,
    "ولتبدل": 1,
    "ولتجاه": 1,
    "ولتجاهك": 1,
    "ولتجاهكم": 1,
    "ولتجاهكما": 1,
    "ولتجاهكن": 1,
    "ولتجاهنا": 1,
    "ولتجاهه": 1,
    "ولتجاهها": 1,
    "ولتجاههم": 1,
    "ولتجاههما": 1,
    "ولتجاههن": 1,
    "ولتجاهي": 1,
    "ولتحت": 1,
    "ولتحتك": 1,
    "ولتحتكم": 1,
    "ولتحتكما": 1,
    "ولتحتكن": 1,
    "ولتحتنا": 1,
    "ولتحته": 1,
    "ولتحتها": 1,
    "ولتحتهم": 1,
    "ولتحتهما": 1,
    "ولتحتهن": 1,
    "ولتحتي": 1,
    "ولتحول": 1,
    "ولتلقاء": 1,
    "ولتلقاءك": 1,
    "ولتلقاءكم": 1,
    "ولتلقاءكما": 1,
    "ولتلقاءكن": 1,
    "ولتلقاءنا": 1,
    "ولتلقاءه": 1,
    "ولتلقاءها": 1,
    "ولتلقاءهم": 1,
    "ولتلقاءهما": 1,
    "ولتلقاءهن": 1,
    "ولتلقاءي": 1,
    "ولتلك": 1,
    "ولتلكم": 1,
    "ولتلكما": 1,
    "ولته": 1,
    "ولتي": 1,
    "ولتين": 1,
    "ولتينك": 1,
    "ولجعل": 1,
    "ولجميع": 1,
    "ولجميعك": 1,
    "ولجميعكم": 1,
    "ولجميعكما": 1,
    "ولجميعكن": 1,
    "ولجميعنا": 1,
    "ولجميعه": 1,
    "ولجميعها": 1,
    "ولجميعهم": 1,
    "ولجميعهما": 1,
    "ولجميعهن": 1,
    "ولجميعي": 1,
    "ولحار": 1,
    "ولحرى": 1,
    "ولحسب": 1,
    "ولحسبك": 1,
    "ولحسبكم": 1,
    "ولحسبكما": 1,
    "ولحسبكن": 1,
    "ولحسبنا": 1,
    "ولحسبه": 1,
    "ولحسبها": 1,
    "ولحسبهم": 1,
    "ولحسبهما": 1,
    "ولحسبهن": 1,
    "ولحسبي": 1,
    "ولحمك": 1,
    "ولحمكم": 1,
    "ولحمكما": 1,
    "ولحمكن": 1,
    "ولحمنا": 1,
    "ولحمه": 1,
    "ولحمها": 1,
    "ولحمهم": 1,
    "ولحمهما": 1,
    "ولحمهن": 1,
    "ولحمي": 1,
    "ولحيث": 1,
    "ولخلف": 1,
    "ولخلفك": 1,
    "ولخلفكم": 1,
    "ولخلفكما": 1,
    "ولخلفكن": 1,
    "ولخلفنا": 1,
    "ولخلفه": 1,
    "ولخلفها": 1,
    "ولخلفهم": 1,
    "ولخلفهما": 1,
    "ولخلفهن": 1,
    "ولخلفي": 1,
    "ولدن": 1,
    "ولدنك": 1,
    "ولدنكم": 1,
    "ولدنكما": 1,
    "ولدنكن": 1,
    "ولدننا": 1,
    "ولدنه": 1,
    "ولدنها": 1,
    "ولدنهما": 1,
    "ولدنهن": 1,
    "ولدني": 1,
    "ولدى": 1,
    "ولدي": 1,
    "ولديك": 1,
    "ولديكم": 1,
    "ولديكما": 1,
    "ولديكن": 1,
    "ولديه": 1,
    "ولديها": 1,
    "ولديهم": 1,
    "ولديهما": 1,
    "ولديهن": 1,
    "ولذا": 1,
    "ولذات": 1,
    "ولذاك": 1,
    "ولذانك": 1,
    "ولذلكم": 1,
    "ولذلكما": 1,
    "ولذلكن": 1,
    "ولذه": 1,
    "ولذواتي": 1,
    "ولذين": 1,
    "ولذينك": 1,
    "ولراح": 1,
    "ولرجع": 1,
    "ولست": 1,
    "ولستما": 1,
    "ولستن": 1,
    "ولسن": 1,
    "ولسنا": 1,
    "ولسوى": 1,
    "ولسوي": 1,
    "ولسويك": 1,
    "ولسويكم": 1,
    "ولسويكما": 1,
    "ولسويكن": 1,
    "ولسوينا": 1,
    "ولسويه": 1,
    "ولسويها": 1,
    "ولسويهم": 1,
    "ولسويهما": 1,
    "ولسويهن": 1,
    "ولشبه": 1,
    "ولشبهك": 1,
    "ولشبهكم": 1,
    "ولشبهكما": 1,
    "ولشبهكن": 1,
    "ولشبهنا": 1,
    "ولشبهه": 1,
    "ولشبهها": 1,
    "ولشبههم": 1,
    "ولشبههما": 1,
    "ولشبههن": 1,
    "ولشبهي": 1,
    "ولشرع": 1,
    "ولشمال": 1,
    "ولشمالك": 1,
    "ولشمالكم": 1,
    "ولشمالكما": 1,
    "ولشمالكن": 1,
    "ولشمالنا": 1,
    "ولشماله": 1,
    "ولشمالها": 1,
    "ولشمالهم": 1,
    "ولشمالهما": 1,
    "ولشمالهن": 1,
    "ولشمالي": 1,
    "ولصار": 1,
    "ولطالما": 1,
    "ولطفق": 1,
    "ولظل": 1,
    "ولعاد": 1,
    "ولعامة": 1,
    "ولعامتك": 1,
    "ولعامتكم": 1,
    "ولعامتكما": 1,
    "ولعامتكن": 1,
    "ولعامتنا": 1,
    "ولعامته": 1,
    "ولعامتها": 1,
    "ولعامتهم": 1,
    "ولعامتهما": 1,
    "ولعامتهن": 1,
    "ولعامتي": 1,
    "ولعسى": 1,
    "ولعل": 1,
    "ولعلق": 1,
    "ولعلك": 1,
    "ولعلكما": 1,
    "ولعلكن": 1,
    "ولعلنا": 1,
    "ولعله": 1,
    "ولعلها": 1,
    "ولعلهما": 1,
    "ولعلهن": 1,
    "ولعلى": 1,
    "ولعلي": 1,
    "ولعليك": 1,
    "ولعليكم": 1,
    "ولعليكما": 1,
    "ولعليكن": 1,
    "ولعلينا": 1,
    "ولعليه": 1,
    "ولعليها": 1,
    "ولعليهم": 1,
    "ولعليهما": 1,
    "ولعليهن": 1,
    "ولعمر": 1,
    "ولعمرك": 1,
    "ولعمركم": 1,
    "ولعمركما": 1,
    "ولعمركن": 1,
    "ولعمرنا": 1,
    "ولعمره": 1,
    "ولعمرها": 1,
    "ولعمرهم": 1,
    "ولعمرهما": 1,
    "ولعمرهن": 1,
    "ولعمري": 1,
    "ولعن": 1,
    "ولعنك": 1,
    "ولعنكم": 1,
    "ولعنكما": 1,
    "ولعنكن": 1,
    "ولعننا": 1,
    "ولعنها": 1,
    "ولعنهما": 1,
    "ولعنهن": 1,
    "ولعني": 1,
    "ولعين": 1,
    "ولعينك": 1,
    "ولعينكم": 1,
    "ولعينكما": 1,
    "ولعينكن": 1,
    "ولعيننا": 1,
    "ولعينه": 1,
    "ولعينها": 1,
    "ولعينهم": 1,
    "ولعينهما": 1,
    "ولعينهن": 1,
    "ولعيني": 1,
    "ولغدا": 1,
    "ولغير": 1,
    "ولغيرك": 1,
    "ولغيركم": 1,
    "ولغيركما": 1,
    "ولغيركن": 1,
    "ولغيرنا": 1,
    "ولغيره": 1,
    "ولغيرها": 1,
    "ولغيرهم": 1,
    "ولغيرهما": 1,
    "ولغيرهن": 1,
    "ولغيري": 1,
    "ولفو": 1,
    "ولفوق": 1,
    "ولفوقك": 1,
    "ولفوقكم": 1,
    "ولفوقكما": 1,
    "ولفوقكن": 1,
    "ولفوقنا": 1,
    "ولفوقه": 1,
    "ولفوقها": 1,
    "ولفوقهم": 1,
    "ولفوقهما": 1,
    "ولفوقهن": 1,
    "ولفوقي": 1,
    "ولفوك": 1,
    "ولفوكم": 1,
    "ولفوكما": 1,
    "ولفوكن": 1,
    "ولفونا": 1,
    "ولفوه": 1,
    "ولفوها": 1,
    "ولفوهم": 1,
    "ولفوهما": 1,
    "ولفوهن": 1,
    "ولفوي": 1,
    "ولفي": 1,
    "ولفيك": 1,
    "ولفيكم": 1,
    "ولفيكما": 1,
    "ولفيكن": 1,
    "ولفينا": 1,
    "ولفيه": 1,
    "ولفيها": 1,
    "ولفيهم": 1,
    "ولفيهما": 1,
    "ولفيهن": 1,
    "ولفيي": 1,
    "ولقام": 1,
    "ولقلما": 1,
    "ولكأن": 1,
    "ولكأنك": 1,
    "ولكأنكم": 1,
    "ولكأنكما": 1,
    "ولكأنكن": 1,
    "ولكأنما": 1,
    "ولكأننا": 1,
    "ولكأنه": 1,
    "ولكأنها": 1,
    "ولكأنهم": 1,
    "ولكأنهما": 1,
    "ولكأنهن": 1,
    "ولكأني": 1,
    "ولكاد": 1,
    "ولكان": 1,
    "ولكرب": 1,
    "ولكلتا": 1,
    "ولكلك": 1,
    "ولكلكم": 1,
    "ولكلكما": 1,
    "ولكلكن": 1,
    "ولكلنا": 1,
    "ولكله": 1,
    "ولكلها": 1,
    "ولكلهم": 1,
    "ولكلهما": 1,
    "ولكلهن": 1,
    "ولكلي": 1,
    "ولكليكما": 1,
    "ولكليهما": 1,
    "ولكما": 1,
    "ولكنك": 1,
    "ولكنكما": 1,
    "ولكنكن": 1,
    "ولكنما": 1,
    "ولكننا": 1,
    "ولكنها": 1,
    "ولكنهما": 1,
    "ولكنهن": 1,
    "ولكي": 1,
    "ولكيلا": 1,
    "وللألاء": 1,
    "وللألى": 1,
    "وللائي": 1,
    "وللاتي": 1,
    "وللتي": 1,
    "وللتيا": 1,
    "وللتين": 1,
    "وللذي": 1,
    "وللواتي": 1,
    "ولمثل": 1,
    "ولمثلك": 1,
    "ولمثلكم": 1,
    "ولمثلكما": 1,
    "ولمثلكن": 1,
    "ولمثلنا": 1,
    "ولمثله": 1,
    "ولمثلها": 1,
    "ولمثلهم": 1,
    "ولمثلهما": 1,
    "ولمثلهن": 1,
    "ولمثلي": 1,
    "ولمع": 1,
    "ولمعك": 1,
    "ولمعكم": 1,
    "ولمعكما": 1,
    "ولمعكن": 1,
    "ولمعنا": 1,
    "ولمعه": 1,
    "ولمعها": 1,
    "ولمعهم": 1,
    "ولمعهما": 1,
    "ولمعهن": 1,
    "ولمعي": 1,
    "ولمنك": 1,
    "ولمنكم": 1,
    "ولمنكما": 1,
    "ولمنكن": 1,
    "ولمننا": 1,
    "ولمنه": 1,
    "ولمنها": 1,
    "ولمنهم": 1,
    "ولمنهما": 1,
    "ولمنهن": 1,
    "ولمني": 1,
    "ولنحن": 1,
    "ولنحو": 1,
    "ولنحوك": 1,
    "ولنحوكم": 1,
    "ولنحوكما": 1,
    "ولنحوكن": 1,
    "ولنحونا": 1,
    "ولنحوه": 1,
    "ولنحوها": 1,
    "ولنحوهم": 1,
    "ولنحوهما": 1,
    "ولنحوهن": 1,
    "ولنحوي": 1,
    "ولنفس": 1,
    "ولنفسك": 1,
    "ولنفسكم": 1,
    "ولنفسكما": 1,
    "ولنفسكن": 1,
    "ولنفسنا": 1,
    "ولنفسه": 1,
    "ولنفسها": 1,
    "ولنفسهم": 1,
    "ولنفسهما": 1,
    "ولنفسهن": 1,
    "ولنفسي": 1,
    "ولهؤلاء": 1,
    "ولهاته": 1,
    "ولهاتي": 1,
    "ولهاتين": 1,
    "ولهب": 1,
    "ولهذه": 1,
    "ولهذي": 1,
    "ولهذين": 1,
    "ولهما": 1,
    "ولهناك": 1,
    "ولهنالك": 1,
    "ولهي": 1,
    "ولوما": 1,
    "وليت": 1,
    "وليتك": 1,
    "وليتكم": 1,
    "وليتكما": 1,
    "وليتكن": 1,
    "وليتنا": 1,
    "وليته": 1,
    "وليتها": 1,
    "وليتهم": 1,
    "وليتهما": 1,
    "وليتهن": 1,
    "وليتي": 1,
    "وليسا": 1,
    "وليستا": 1,
    "وليسوا": 1,
    "وليمين": 1,
    "وليمينك": 1,
    "وليمينكم": 1,
    "وليمينكما": 1,
    "وليمينكن": 1,
    "وليميننا": 1,
    "وليمينه": 1,
    "وليمينها": 1,
    "وليمينهم": 1,
    "وليمينهما": 1,
    "وليمينهن": 1,
    "وليميني": 1,
    "وماانفك": 1,
    "ومابرح": 1,
    "ومادام": 1,
    "ومازال": 1,
    "ومافتئ": 1,
    "ومتى": 1,
    "ومثلك": 1,
    "ومثلكم": 1,
    "ومثلكما": 1,
    "ومثلكن": 1,
    "ومثلنا": 1,
    "ومثلها": 1,
    "ومثلهما": 1,
    "ومثلهن": 1,
    "ومثلي": 1,
    "ومذ": 1,
    "ومرة": 1,
    "ومساء": 1,
    "ومع": 1,
    "ومعاذ": 1,
    "ومعاذك": 1,
    "ومعاذكم": 1,
    "ومعاذكما": 1,
    "ومعاذكن": 1,
    "ومعاذنا": 1,
    "ومعاذه": 1,
    "ومعاذها": 1,
    "ومعاذهم": 1,
    "ومعاذهما": 1,
    "ومعاذهن": 1,
    "ومعاذي": 1,
    "ومعك": 1,
    "ومعكم": 1,
    "ومعكما": 1,
    "ومعكن": 1,
    "ومعنا": 1,
    "ومعه": 1,
    "ومعها": 1,
    "ومعهم": 1,
    "ومعهما": 1,
    "ومعهن": 1,
    "ومعي": 1,
    "ومكانك": 1,
    "ومكانكم": 1,
    "ومكانكما": 1,
    "ومكانكن": 1,
    "ومنذ": 1,
    "ومنكما": 1,
    "ومنكن": 1,
    "ومننا": 1,
    "ومنهما": 1,
    "ومنهن": 1,
    "ومني": 1,
    "ومهما": 1,
    "ونحو": 1,
    "ونحوك": 1,
    "ونحوكم": 1,
    "ونحوكما": 1,
    "ونحوكن": 1,
    "ونحونا": 1,
    "ونحوه": 1,
    "ونحوها": 1,
    "ونحوهم": 1,
    "ونحوهما": 1,
    "ونحوهن": 1,
    "ونحوي": 1,
    "ونعما": 1,
    "ونفسك": 1,
    "ونفسكم": 1,
    "ونفسكما": 1,
    "ونفسكن": 1,
    "ونفسنا": 1,
    "ونفسه": 1,
    "ونفسها": 1,
    "ونفسهم": 1,
    "ونفسهما": 1,
    "ونفسهن": 1,
    "ونفسي": 1,
    "وهاؤم": 1,
    "وهاتان": 1,
    "وهاته": 1,
    "وهاتي": 1,
    "وهاتين": 1,
    "وهاهنا": 1,
    "وهذان": 1,
    "وهذي": 1,
    "وهذين": 1,
    "وهكذا": 1,
    "وهلا": 1,
    "وهنالك": 1,
    "وهيا": 1,
    "وهيت": 1,
    "وي": 1,
    "ويكأنك": 1,
    "ويكأنكم": 1,
    "ويكأنكما": 1,
    "ويكأنكن": 1,
    "ويكأننا": 1,
    "ويكأنها": 1,
    "ويكأنهم": 1,
    "ويكأنهما": 1,
    "ويكأنهن": 1,
    "ويكأني": 1,
    "ويمين": 1,
    "ويمينك": 1,
    "ويمينكم": 1,
    "ويمينكما": 1,
    "ويمينكن": 1,
    "ويميننا": 1,
    "ويمينه": 1,
    "ويمينها": 1,
    "ويمينهم": 1,
    "ويمينهما": 1,
    "ويمينهن": 1,
    "ويميني": 1,
    "يمينكم": 1,
    "يمينكما": 1,
    "يمينكن": 1,
    "يميننا": 1,
    "يمينه": 1,
    "يمينها": 1,
    "يمينهم": 1,
    "يمينهما": 1,
    "يمينهن": 1,
    "آب": 1,
    "آبار": 1,
    "آباض": 1,
    "آباط": 1,
    "آبد": 1,
    "آبدة": 1,
    "آبق": 1,
    "آتشجي": 1,
    "آتن": 1,
    "آتى": 1,
    "آثاره": 1,
    "آثاري": 1,
    "آثام": 1,
    "آثر": 1,
    "آجال": 1,
    "آجام": 1,
    "آجر": 1,
    "آجل": 1,
    "آجلا": 1,
    "آجله": 1,
    "آجن": 1,
    "آح": 1,
    "آحانية": 1,
    "آحد": 1,
    "آحين": 1,
    "آخرا": 1,
    "آخرة": 1,
    "آخرھم": 1,
    "آخرھما": 1,
    "آد": 1,
    "آدب": 1,
    "آدمية": 1,
    "آذانا": 1,
    "آراءه": 1,
    "آراب": 1,
    "آرام": 1,
    "آرامى": 1,
    "آرى": 1,
    "آرية": 1,
    "آزال": 1,
    "آس": 1,
    "آساد": 1,
    "آسر": 1,
    "آصار": 1,
    "آصال": 1,
    "آصرة": 1,
    "آغا": 1,
    "آفاق": 1,
    "آفاقى": 1,
    "آفاقي": 1,
    "آفة": 1,
    "آفرين": 1,
    "آفل": 1,
    "آق": 1,
    "آكام": 1,
    "آكلة": 1,
    "آلاتى": 1,
    "آلاتية": 1,
    "آلام": 1,
    "آلامه": 1,
    "آلاى": 1,
    "آله": 1,
    "آلو": 1,
    "آلى": 1,
    "آلھة": 1,
    "آلھي": 1,
    "آم": 1,
    "آماق": 1,
    "آمال": 1,
    "آمت": 1,
    "آمر": 1,
    "آمل": 1,
    "آنئذ": 1,
    "آنا": 1,
    "آناف": 1,
    "آنذاك": 1,
    "آنف": 1,
    "آنك": 1,
    "آنيسون": 1,
    "آونة": 1,
    "آھ": 1,
    "آھا": 1,
    "آھات": 1,
    "آھة": 1,
    "آھل": 1,
    "أبھته": 1,
    "أثآر": 1,
    "أثاف": 1,
    "أثفية": 1,
    "أثير": 1,
    "أخاذ": 1,
    "أخباره": 1,
    "أخطأه": 1,
    "أخلاقية": 1,
    "أخماسا": 1,
    "أخمص": 1,
    "أخيذ": 1,
    "أخيذة": 1,
    "أدرية": 1,
    "أساورة": 1,
    "أسوار": 1,
    "أسوان": 1,
    "أعطافه": 1,
    "أعماله": 1,
    "أكيدا": 1,
    "ألحاد": 1,
    "أماله": 1,
    "أنفاسه": 1,
    "أولوية": 1,
    "أوناش": 1,
    "أھجأ": 1,
    "أھداف": 1,
    "أھدب": 1,
    "أھمية": 1,
    "إذاك": 1,
    "إلحاد": 1,
    "إمضاء": 1,
    "إنشائي": 1,
    "إيثار": 1,
    "إيماء": 1,
    "إيماءة": 1,
    "ئمجير": 1,
    "ائتكل": 1,
    "ائتلاف": 1,
    "ائتلافي": 1,
    "ائتلافية": 1,
    "ائتمار": 1,
    "ائتمان": 1,
    "ائتمر": 1,
    "ائتناس": 1,
    "ائمة": 1,
    "اانوار": 1,
    "اب": 1,
    "ابؤس": 1,
    "ابا": 1,
    "اباء": 1,
    "اباءة": 1,
    "اباة": 1,
    "اباتي": 1,
    "اباحة": 1,
    "اباحى": 1,
    "اباحية": 1,
    "ابادة": 1,
    "اباديد": 1,
    "اباريق": 1,
    "ابازم": 1,
    "ابازن": 1,
    "ابازير": 1,
    "اباسيط": 1,
    "اباطح": 1,
    "اباطرة": 1,
    "اباطيل": 1,
    "اباعد": 1,
    "اباعر": 1,
    "اباق": 1,
    "ابالة": 1,
    "ابالسة": 1,
    "ابالى": 1,
    "ابالية": 1,
    "ابانة": 1,
    "ابانه": 1,
    "اباه": 1,
    "اباھيم": 1,
    "ابت": 1,
    "ابتاع": 1,
    "ابتداء": 1,
    "ابتدائية": 1,
    "ابتدرھا": 1,
    "ابتذل": 1,
    "ابتزاز": 1,
    "ابتسام": 1,
    "ابتشاك": 1,
    "ابتع": 1,
    "ابتكار": 1,
    "ابتكارات": 1,
    "ابتلاء": 1,
    "ابتلع": 1,
    "ابتياع": 1,
    "ابتھاج": 1,
    "ابتھار": 1,
    "ابتھال": 1,
    "ابجد": 1,
    "ابجدي": 1,
    "ابجديات": 1,
    "ابجر": 1,
    "ابح": 1,
    "ابحاث": 1,
    "ابحار": 1,
    "ابحر": 1,
    "ابخر": 1,
    "ابخرة": 1,
    "ابخق": 1,
    "ابد": 1,
    "ابدا": 1,
    "ابداء": 1,
    "ابداع": 1,
    "ابداعي": 1,
    "ابداعية": 1,
    "ابدال": 1,
    "ابدان": 1,
    "ابدع": 1,
    "ابدن": 1,
    "ابدى": 1,
    "ابدي": 1,
    "ابر": 1,
    "ابرأ": 1,
    "ابراء": 1,
    "ابراج": 1,
    "ابراد": 1,
    "ابرار": 1,
    "ابراز": 1,
    "ابراش": 1,
    "ابراص": 1,
    "ابرام": 1,
    "ابراميس": 1,
    "ابراھيم": 1,
    "ابرة": 1,
    "ابرز": 1,
    "ابرش": 1,
    "ابرص": 1,
    "ابرك": 1,
    "ابرميس": 1,
    "ابروشية": 1,
    "ابرياء": 1,
    "ابريز": 1,
    "ابريسم": 1,
    "ابريشية": 1,
    "ابريق": 1,
    "ابريل": 1,
    "ابزار": 1,
    "ابزاز": 1,
    "ابزن": 1,
    "ابزيم": 1,
    "ابسط": 1,
    "ابسطة": 1,
    "ابسقوبس": 1,
    "ابسوطة": 1,
    "ابشع": 1,
    "ابصار": 1,
    "ابصر": 1,
    "ابض": 1,
    "ابضاع": 1,
    "ابط": 1,
    "ابطأ": 1,
    "ابطاء": 1,
    "ابطال": 1,
    "ابطح": 1,
    "ابطن": 1,
    "ابطنة": 1,
    "ابعاد": 1,
    "ابعادية": 1,
    "ابعد": 1,
    "ابعرة": 1,
    "ابغال": 1,
    "ابق": 1,
    "ابقاء": 1,
    "ابقال": 1,
    "ابقع": 1,
    "ابقى": 1,
    "ابكار": 1,
    "ابكر": 1,
    "ابكم": 1,
    "ابل": 1,
    "ابلاغ": 1,
    "ابلال": 1,
    "ابلام": 1,
    "ابلج": 1,
    "ابلد": 1,
    "ابلطة": 1,
    "ابلغ": 1,
    "ابلق": 1,
    "ابله": 1,
    "ابلى": 1,
    "ابلي": 1,
    "ابليز": 1,
    "ابليس": 1,
    "ابناء": 1,
    "ابنوس": 1,
    "ابنياء": 1,
    "ابنية": 1,
    "ابه": 1,
    "ابواب": 1,
    "ابوابھا": 1,
    "ابواز": 1,
    "ابواش": 1,
    "ابواص": 1,
    "ابواع": 1,
    "ابواق": 1,
    "ابوال": 1,
    "ابوام": 1,
    "ابوة": 1,
    "ابود": 1,
    "ابونا": 1,
    "ابونيت": 1,
    "ابونيه": 1,
    "ابوي": 1,
    "ابويه": 1,
    "ابويھا": 1,
    "ابى": 1,
    "ابي": 1,
    "ابيات": 1,
    "ابيب": 1,
    "ابيضاض": 1,
    "ابيعه": 1,
    "ابيقوري": 1,
    "ابين": 1,
    "ابيه": 1,
    "ابيھم": 1,
    "ابھاء": 1,
    "ابھام": 1,
    "ابھة": 1,
    "ابھر": 1,
    "ابھل": 1,
    "ابھى": 1,
    "ات": 1,
    "اتأد": 1,
    "اتأر": 1,
    "اتاتن": 1,
    "اتان": 1,
    "اتاه": 1,
    "اتاوة": 1,
    "اتاوى": 1,
    "اتباعا": 1,
    "اتباعى": 1,
    "اتباعية": 1,
    "اتجار": 1,
    "اتجه": 1,
    "اتحادي": 1,
    "اتحادية": 1,
    "اتخم": 1,
    "اتراب": 1,
    "اتراح": 1,
    "اتراني": 1,
    "اتراھا": 1,
    "اتربة": 1,
    "اترج": 1,
    "اترس": 1,
    "اترنج": 1,
    "اتزان": 1,
    "اتزر": 1,
    "اتساع": 1,
    "اتساق": 1,
    "اتسخ": 1,
    "اتسم": 1,
    "اتشاح": 1,
    "اتصاله": 1,
    "اتصف": 1,
    "اتصل": 1,
    "اتصلت": 1,
    "اتضاح": 1,
    "اتضاع": 1,
    "اتضح": 1,
    "اتضع": 1,
    "اتعاب": 1,
    "اتعب": 1,
    "اتعتع": 1,
    "اتعد": 1,
    "اتعظ": 1,
    "اتفاقا": 1,
    "اتفاقي": 1,
    "اتفاقية": 1,
    "اتقاد": 1,
    "اتقان": 1,
    "اتقد": 1,
    "اتقن": 1,
    "اتقياء": 1,
    "اتكأ": 1,
    "اتكاء": 1,
    "اتكال": 1,
    "اتكل": 1,
    "اتلاف": 1,
    "اتلال": 1,
    "اتلام": 1,
    "اتم": 1,
    "اتمام": 1,
    "اتماما": 1,
    "اتن": 1,
    "اتوبيس": 1,
    "اتوماتيكي": 1,
    "اتومبيل": 1,
    "اتوموبيل": 1,
    "اتون": 1,
    "اتى": 1,
    "اتياس": 1,
    "اتيان": 1,
    "اتيح": 1,
    "اتيس": 1,
    "اتھام": 1,
    "اتھامية": 1,
    "اتھم": 1,
    "اث": 1,
    "اثأر": 1,
    "اثاث": 1,
    "اثاثة": 1,
    "اثار": 1,
    "اثبات": 1,
    "اثباتى": 1,
    "اثبت": 1,
    "اثبته": 1,
    "اثخن": 1,
    "اثخنه": 1,
    "اثداء": 1,
    "اثر": 1,
    "اثرا": 1,
    "اثرة": 1,
    "اثره": 1,
    "اثري": 1,
    "اثرياء": 1,
    "اثرية": 1,
    "اثفار": 1,
    "اثفية": 1,
    "اثقاب": 1,
    "اثقال": 1,
    "اثقل": 1,
    "اثكل": 1,
    "اثل": 1,
    "اثلاث": 1,
    "اثلج": 1,
    "اثلجت": 1,
    "اثم": 1,
    "اثماء": 1,
    "اثمار": 1,
    "اثمان": 1,
    "اثمة": 1,
    "اثمد": 1,
    "اثمن": 1,
    "اثمنة": 1,
    "اثنتان": 1,
    "اثنى": 1,
    "اثواب": 1,
    "اثول": 1,
    "اثير": 1,
    "اثيل": 1,
    "اثيم": 1,
    "اثينا": 1,
    "اثيوبي": 1,
    "اثيوبيا": 1,
    "اج": 1,
    "اجاب": 1,
    "اجابة": 1,
    "اجاج": 1,
    "اجاحة": 1,
    "اجاد": 1,
    "اجارة": 1,
    "اجاص": 1,
    "اجاطه": 1,
    "اجاعة": 1,
    "اجال": 1,
    "اجانب": 1,
    "اجاود": 1,
    "اجاويد": 1,
    "اجباب": 1,
    "اجباح": 1,
    "اجبار": 1,
    "اجبارى": 1,
    "اجبال": 1,
    "اجبح": 1,
    "اجبن": 1,
    "اجبنة": 1,
    "اجبية": 1,
    "اجتذاب": 1,
    "اجتر": 1,
    "اجترح": 1,
    "اجتلاء": 1,
    "اجتماعى": 1,
    "اجتماعية": 1,
    "اجتناب": 1,
    "اجتياح": 1,
    "اجتياز": 1,
    "اجتھاد": 1,
    "اجثاث": 1,
    "اجحار": 1,
    "اجحاش": 1,
    "اجحاف": 1,
    "اجد": 1,
    "اجداث": 1,
    "اجدب": 1,
    "اجدر": 1,
    "اجدع": 1,
    "اجدى": 1,
    "اجذار": 1,
    "اجذاع": 1,
    "اجذال": 1,
    "اجذام": 1,
    "اجر": 1,
    "اجراء": 1,
    "اجراح": 1,
    "اجراد": 1,
    "اجراس": 1,
    "اجراف": 1,
    "اجرام": 1,
    "اجرامى": 1,
    "اجران": 1,
    "اجراه": 1,
    "اجرب": 1,
    "اجربة": 1,
    "اجرة": 1,
    "اجرضه": 1,
    "اجرودى": 1,
    "اجرى": 1,
    "اجرياء": 1,
    "اجرية": 1,
    "اجزاء": 1,
    "اجزائى": 1,
    "اجزائية": 1,
    "اجزاجى": 1,
    "اجزاجي": 1,
    "اجزاخانة": 1,
    "اجزل": 1,
    "اجساد": 1,
    "اجسام": 1,
    "اجسر": 1,
    "اجسم": 1,
    "اجش": 1,
    "اجعال": 1,
    "اجعد": 1,
    "اجفان": 1,
    "اجفانه": 1,
    "اجفى": 1,
    "اجل": 1,
    "اجلا": 1,
    "اجلاء": 1,
    "اجلاد": 1,
    "اجلاف": 1,
    "اجلال": 1,
    "اجلام": 1,
    "اجلة": 1,
    "اجلح": 1,
    "اجله": 1,
    "اجلى": 1,
    "اجم": 1,
    "اجماع": 1,
    "اجماعى": 1,
    "اجمال": 1,
    "اجمالا": 1,
    "اجماله": 1,
    "اجمالى": 1,
    "اجمالية": 1,
    "اجمة": 1,
    "اجمع": 1,
    "اجمعوا": 1,
    "اجمل": 1,
    "اجمله": 1,
    "اجمية": 1,
    "اجناب": 1,
    "اجناد": 1,
    "اجناس": 1,
    "اجنان": 1,
    "اجنبي": 1,
    "اجنبية": 1,
    "اجنة": 1,
    "اجنح": 1,
    "اجنحة": 1,
    "اجندة": 1,
    "اجنون": 1,
    "اجو": 1,
    "اجواء": 1,
    "اجواخ": 1,
    "اجواد": 1,
    "اجواز": 1,
    "اجواف": 1,
    "اجواق": 1,
    "اجوان": 1,
    "اجوبة": 1,
    "اجود": 1,
    "اجور": 1,
    "اجوف": 1,
    "اجياد": 1,
    "اجياف": 1,
    "اجيال": 1,
    "اجيج": 1,
    "اجير": 1,
    "اجيرة": 1,
    "اجھاد": 1,
    "اجھاش": 1,
    "اجھاض": 1,
    "اجھد": 1,
    "اجھر": 1,
    "اجھزة": 1,
    "اجھش": 1,
    "اجھضت": 1,
    "اح": 1,
    "احابيل": 1,
    "احاج": 1,
    "احاجى": 1,
    "احاديث": 1,
    "احاسن": 1,
    "احاسيس": 1,
    "احاط": 1,
    "احاطة": 1,
    "احافير": 1,
    "احالة": 1,
    "احاييل": 1,
    "احايين": 1,
    "احب": 1,
    "احباء": 1,
    "احباب": 1,
    "احبار": 1,
    "احباس": 1,
    "احباش": 1,
    "احباط": 1,
    "احبال": 1,
    "احبة": 1,
    "احبل": 1,
    "احبه": 1,
    "احبولة": 1,
    "احتباس": 1,
    "احتجاب": 1,
    "احتجاج": 1,
    "احتدام": 1,
    "احتدم": 1,
    "احتذاء": 1,
    "احتراب": 1,
    "احتراز": 1,
    "احتراس": 1,
    "احتراسا": 1,
    "احتراف": 1,
    "احترم": 1,
    "احتساب": 1,
    "احتسب": 1,
    "احتشاد": 1,
    "احتضار": 1,
    "احتفاء": 1,
    "احتفاظ": 1,
    "احتفال": 1,
    "احتفظ": 1,
    "احتقار": 1,
    "احتقان": 1,
    "احتقن": 1,
    "احتك": 1,
    "احتكاك": 1,
    "احتل": 1,
    "احتلال": 1,
    "احتلالي": 1,
    "احتماء": 1,
    "احتياج": 1,
    "احتياط": 1,
    "احتياطى": 1,
    "احتياطي": 1,
    "احتياطية": 1,
    "احتيال": 1,
    "احتيالي": 1,
    "احجاء": 1,
    "احجار": 1,
    "احجاف": 1,
    "احجال": 1,
    "احجام": 1,
    "احجبة": 1,
    "احجة": 1,
    "احجن": 1,
    "احجى": 1,
    "احجية": 1,
    "احدا": 1,
    "احداء": 1,
    "احداب": 1,
    "احداث": 1,
    "احداثيات": 1,
    "احداج": 1,
    "احداق": 1,
    "احدب": 1,
    "احدة": 1,
    "احدث": 1,
    "احدق": 1,
    "احدوة": 1,
    "احدوثة": 1,
    "احدودب": 1,
    "احدى": 1,
    "احدي": 1,
    "احدية": 1,
    "احدھم": 1,
    "احذية": 1,
    "احر": 1,
    "احراج": 1,
    "احرار": 1,
    "احراش": 1,
    "احراق": 1,
    "احرام": 1,
    "احرز": 1,
    "احرش": 1,
    "احرص": 1,
    "احرق": 1,
    "احرمة": 1,
    "احرى": 1,
    "احرياء": 1,
    "احزاب": 1,
    "احزابه": 1,
    "احزابھم": 1,
    "احزان": 1,
    "احزم": 1,
    "احزمة": 1,
    "احساب": 1,
    "احساس": 1,
    "احساسات": 1,
    "احساسية": 1,
    "احسان": 1,
    "احسن": 1,
    "احسنت": 1,
    "احسنه": 1,
    "احسية": 1,
    "احشاء": 1,
    "احصاء": 1,
    "احصائية": 1,
    "احصان": 1,
    "احصنة": 1,
    "احضار": 1,
    "احضان": 1,
    "احضانه": 1,
    "احضانھا": 1,
    "احضة": 1,
    "احضره": 1,
    "احط": 1,
    "احطاب": 1,
    "احطياطية": 1,
    "احفاد": 1,
    "احفور": 1,
    "احق": 1,
    "احقا": 1,
    "احقاء": 1,
    "احقاب": 1,
    "احقاد": 1,
    "احقاق": 1,
    "احقاقا": 1,
    "احقر": 1,
    "احقية": 1,
    "احلاء": 1,
    "احلاس": 1,
    "احلاف": 1,
    "احلاق": 1,
    "احلام": 1,
    "احلس": 1,
    "احله": 1,
    "احلولك": 1,
    "احليل": 1,
    "احم": 1,
    "احماء": 1,
    "احماض": 1,
    "احمال": 1,
    "احمد": 1,
    "احمرة": 1,
    "احمس": 1,
    "احن": 1,
    "احناء": 1,
    "احنائھا": 1,
    "احناث": 1,
    "احناش": 1,
    "احناك": 1,
    "احنة": 1,
    "احنف": 1,
    "احوات": 1,
    "احوار": 1,
    "احواز": 1,
    "احواش": 1,
    "احواض": 1,
    "احوال": 1,
    "احوج": 1,
    "احوجه": 1,
    "احوص": 1,
    "احول": 1,
    "احيا": 1,
    "احياء": 1,
    "احيائي": 1,
    "احيائية": 1,
    "احياز": 1,
    "احيان": 1,
    "احيانا": 1,
    "احيت": 1,
    "احيل": 1,
    "احيلاه": 1,
    "احيلت": 1,
    "احيلى": 1,
    "احيلي": 1,
    "احين": 1,
    "احيى": 1,
    "اخ": 1,
    "اخاء": 1,
    "اخاديد": 1,
    "اخادير": 1,
    "اخاديع": 1,
    "اخافة": 1,
    "اخامص": 1,
    "اخاوة": 1,
    "اخاوين": 1,
    "اخاير": 1,
    "اخايل": 1,
    "اخبئة": 1,
    "اخباث": 1,
    "اخبار": 1,
    "اخبارا": 1,
    "اخباره": 1,
    "اخباري": 1,
    "اخبارية": 1,
    "اخباز": 1,
    "اخبث": 1,
    "اخبل": 1,
    "اخبية": 1,
    "اخت": 1,
    "اختار": 1,
    "اختام": 1,
    "اختان": 1,
    "اختباري": 1,
    "اختبارية": 1,
    "اختبال": 1,
    "اختتام": 1,
    "اختراع": 1,
    "اختزال": 1,
    "اختص": 1,
    "اختصاص": 1,
    "اختطاف": 1,
    "اختفاء": 1,
    "اختفى": 1,
    "اختلاء": 1,
    "اختلاجة": 1,
    "اختلاط": 1,
    "اختلافھا": 1,
    "اختلال": 1,
    "اختلت": 1,
    "اختلج": 1,
    "اختمار": 1,
    "اختناق": 1,
    "اختيارا": 1,
    "اختياره": 1,
    "اختياري": 1,
    "اختھا": 1,
    "اخدار": 1,
    "اخداش": 1,
    "اخدان": 1,
    "اخدعه": 1,
    "اخدود": 1,
    "اخذتني": 1,
    "اخذه": 1,
    "اخرات": 1,
    "اخراج": 1,
    "اخربة": 1,
    "اخرت": 1,
    "اخرجه": 1,
    "اخرس": 1,
    "اخرفة": 1,
    "اخرق": 1,
    "اخروي": 1,
    "اخريات": 1,
    "اخسأ": 1,
    "اخساء": 1,
    "اخشاب": 1,
    "اخشن": 1,
    "اخشوشن": 1,
    "اخشى": 1,
    "اخص": 1,
    "اخصاء": 1,
    "اخصائي": 1,
    "اخصاب": 1,
    "اخصاص": 1,
    "اخصام": 1,
    "اخضاع": 1,
    "اخضر": 1,
    "اخضوضب": 1,
    "اخضوضر": 1,
    "اخطار": 1,
    "اخطبة": 1,
    "اخطبوط": 1,
    "اخطر": 1,
    "اخف": 1,
    "اخفاء": 1,
    "اخفاف": 1,
    "اخفاق": 1,
    "اخفش": 1,
    "اخفض": 1,
    "اخفه": 1,
    "اخفى": 1,
    "اخلاء": 1,
    "اخلاب": 1,
    "اخلاد": 1,
    "اخلاص": 1,
    "اخلاط": 1,
    "اخلاف": 1,
    "اخلاق": 1,
    "اخلاقي": 1,
    "اخلال": 1,
    "اخلة": 1,
    "اخلص": 1,
    "اخلق": 1,
    "اخلى": 1,
    "اخلياء": 1,
    "اخماد": 1,
    "اخماسا": 1,
    "اخماسه": 1,
    "اخمام": 1,
    "اخمرة": 1,
    "اخمص": 1,
    "اخن": 1,
    "اخنان": 1,
    "اخنس": 1,
    "اخو": 1,
    "اخوات": 1,
    "اخوار": 1,
    "اخوال": 1,
    "اخوان": 1,
    "اخوة": 1,
    "اخور": 1,
    "اخوص": 1,
    "اخوف": 1,
    "اخونة": 1,
    "اخوه": 1,
    "اخوي": 1,
    "اخوية": 1,
    "اخيار": 1,
    "اخياط": 1,
    "اخير": 1,
    "اخيرا": 1,
    "اخيل": 1,
    "اخيلة": 1,
    "اد": 1,
    "ادأب": 1,
    "ادؤب": 1,
    "اداء": 1,
    "اداة": 1,
    "ادار": 1,
    "اداري": 1,
    "اداريا": 1,
    "ادارية": 1,
    "ادام": 1,
    "ادان": 1,
    "ادانة": 1,
    "ادب": 1,
    "ادباء": 1,
    "ادباب": 1,
    "ادبار": 1,
    "ادبخانة": 1,
    "ادبي": 1,
    "ادبيا": 1,
    "ادبيات": 1,
    "ادجن": 1,
    "ادخار": 1,
    "ادخال": 1,
    "ادخر": 1,
    "ادخلت": 1,
    "ادخله": 1,
    "ادخنة": 1,
    "ادراج": 1,
    "ادراجه": 1,
    "ادراع": 1,
    "ادراك": 1,
    "ادران": 1,
    "ادرة": 1,
    "ادرد": 1,
    "ادرع": 1,
    "ادرك": 1,
    "ادركه": 1,
    "ادرنة": 1,
    "ادرى": 1,
    "ادري": 1,
    "ادسم": 1,
    "ادعج": 1,
    "ادعم": 1,
    "ادعياء": 1,
    "ادعية": 1,
    "ادغال": 1,
    "ادغشت": 1,
    "ادغم": 1,
    "ادفأ": 1,
    "ادق": 1,
    "ادقاع": 1,
    "ادقة": 1,
    "ادكر": 1,
    "ادكن": 1,
    "ادل": 1,
    "ادلاء": 1,
    "ادلة": 1,
    "ادلى": 1,
    "ادلھمام": 1,
    "ادم": 1,
    "ادماج": 1,
    "ادماس": 1,
    "ادمان": 1,
    "ادمغة": 1,
    "ادنأ": 1,
    "ادناء": 1,
    "ادناس": 1,
    "ادناف": 1,
    "ادناه": 1,
    "ادنون": 1,
    "ادنى": 1,
    "ادنياء": 1,
    "ادواء": 1,
    "ادوات": 1,
    "ادوار": 1,
    "ادواوت": 1,
    "ادور": 1,
    "ادوية": 1,
    "ادى": 1,
    "اديار": 1,
    "ادياك": 1,
    "اديان": 1,
    "اديب": 1,
    "اديبة": 1,
    "اديرة": 1,
    "اديل": 1,
    "اديم": 1,
    "ادھان": 1,
    "ادھر": 1,
    "ادھم": 1,
    "ادھنة": 1,
    "ادھى": 1,
    "اذ": 1,
    "اذا": 1,
    "اذاب": 1,
    "اذابة": 1,
    "اذاة": 1,
    "اذاع": 1,
    "اذاعة": 1,
    "اذان": 1,
    "اذاية": 1,
    "اذبة": 1,
    "اذحال": 1,
    "اذخار": 1,
    "اذرت": 1,
    "اذرع": 1,
    "اذعان": 1,
    "اذقان": 1,
    "اذكى": 1,
    "اذكياء": 1,
    "اذلاء": 1,
    "اذلال": 1,
    "اذلة": 1,
    "اذلف": 1,
    "اذمة": 1,
    "اذنه": 1,
    "اذنية": 1,
    "اذنيه": 1,
    "اذواق": 1,
    "اذون": 1,
    "اذونات": 1,
    "اذى": 1,
    "اذيال": 1,
    "اذية": 1,
    "اذھان": 1,
    "اذھب": 1,
    "ارآد": 1,
    "ارآم": 1,
    "ارأف": 1,
    "ارؤس": 1,
    "ارائك": 1,
    "اراتقة": 1,
    "اراتيقي": 1,
    "اراتيكي": 1,
    "اراجيح": 1,
    "اراجيز": 1,
    "اراجيف": 1,
    "اراح": 1,
    "اراخنة": 1,
    "اراد": 1,
    "ارادب": 1,
    "ارادة": 1,
    "ارادته": 1,
    "اراده": 1,
    "ارادي": 1,
    "اراض": 1,
    "اراضون": 1,
    "اراضى": 1,
    "اراغن": 1,
    "اراغيط": 1,
    "اراق": 1,
    "اراقة": 1,
    "اراكيل": 1,
    "ارام": 1,
    "ارامل": 1,
    "اراملة": 1,
    "ارانب": 1,
    "ارانيك": 1,
    "اراه": 1,
    "اراھط": 1,
    "اراھيط": 1,
    "ارب": 1,
    "اربا": 1,
    "ارباء": 1,
    "ارباب": 1,
    "ارباح": 1,
    "ارباض": 1,
    "ارباع": 1,
    "ارباق": 1,
    "اربة": 1,
    "اربح": 1,
    "اربطة": 1,
    "اربع": 1,
    "اربعاء": 1,
    "اربعة": 1,
    "اربعون": 1,
    "اربيل": 1,
    "ارت": 1,
    "ارتأى": 1,
    "ارتاح": 1,
    "ارتال": 1,
    "ارتباط": 1,
    "ارتباك": 1,
    "ارتج": 1,
    "ارتجاج": 1,
    "ارتجاع": 1,
    "ارتجاعي": 1,
    "ارتجال": 1,
    "ارتحال": 1,
    "ارتحل": 1,
    "ارتخاء": 1,
    "ارترزاق": 1,
    "ارتسام": 1,
    "ارتشاء": 1,
    "ارتشاح": 1,
    "ارتضخ": 1,
    "ارتعاش": 1,
    "ارتعدت": 1,
    "ارتفاق": 1,
    "ارتقاء": 1,
    "ارتقائه": 1,
    "ارتقاب": 1,
    "ارتكاز": 1,
    "ارتمس": 1,
    "ارتمى": 1,
    "ارتوازي": 1,
    "ارتياب": 1,
    "ارتياح": 1,
    "ارتياع": 1,
    "ارتھن": 1,
    "ارث": 1,
    "ارثة": 1,
    "ارثوذكسي": 1,
    "ارج": 1,
    "ارجاء": 1,
    "ارجائه": 1,
    "ارجاز": 1,
    "ارجاس": 1,
    "ارجاع": 1,
    "ارجاف": 1,
    "ارجال": 1,
    "ارجح": 1,
    "ارجحية": 1,
    "ارجف": 1,
    "ارجل": 1,
    "ارجوان": 1,
    "ارجوحة": 1,
    "ارجوز": 1,
    "ارجوزة": 1,
    "ارحاء": 1,
    "ارحام": 1,
    "ارحية": 1,
    "ارخ": 1,
    "ارخبيل": 1,
    "ارخن": 1,
    "ارد": 1,
    "اردأ": 1,
    "ارداء": 1,
    "ارداف": 1,
    "اردان": 1,
    "اردب": 1,
    "اردبة": 1,
    "اردن": 1,
    "اردني": 1,
    "اردواز": 1,
    "اردياء": 1,
    "اردية": 1,
    "ارذال": 1,
    "ارز": 1,
    "ارزاء": 1,
    "ارزاق": 1,
    "ارزق": 1,
    "ارزيز": 1,
    "ارس": 1,
    "ارساغ": 1,
    "ارسال": 1,
    "ارسالا": 1,
    "ارسالية": 1,
    "ارسان": 1,
    "ارستقراطي": 1,
    "ارستقراطية": 1,
    "ارسخ": 1,
    "ارسطو": 1,
    "ارسغ": 1,
    "ارسل": 1,
    "ارسن": 1,
    "ارسنة": 1,
    "ارش": 1,
    "ارشاد": 1,
    "ارشادات": 1,
    "ارشادي": 1,
    "ارشيدوق": 1,
    "ارشيدوقة": 1,
    "ارصاد": 1,
    "ارصدة": 1,
    "ارصفة": 1,
    "ارض": 1,
    "ارضروم": 1,
    "ارضعني": 1,
    "ارضى": 1,
    "ارضي": 1,
    "ارضياء": 1,
    "ارضية": 1,
    "ارط": 1,
    "ارطاب": 1,
    "ارطال": 1,
    "ارطة": 1,
    "ارطقة": 1,
    "ارعاب": 1,
    "ارعن": 1,
    "ارعنى": 1,
    "ارعوى": 1,
    "ارعى": 1,
    "ارعيته": 1,
    "ارغاطة": 1,
    "ارغام": 1,
    "ارغفة": 1,
    "ارغن": 1,
    "ارغى": 1,
    "ارفاد": 1,
    "ارفاض": 1,
    "ارفاق": 1,
    "ارفع": 1,
    "ارق": 1,
    "ارقاء": 1,
    "ارقام": 1,
    "ارقش": 1,
    "ارقط": 1,
    "ارقعة": 1,
    "ارقى": 1,
    "اركان": 1,
    "اركبه": 1,
    "اركزة": 1,
    "اركيلة": 1,
    "ارم": 1,
    "ارماث": 1,
    "ارماح": 1,
    "ارماس": 1,
    "ارماق": 1,
    "ارماك": 1,
    "ارمد": 1,
    "ارمدة": 1,
    "ارمل": 1,
    "ارملة": 1,
    "ارمن": 1,
    "ارمنى": 1,
    "ارمني": 1,
    "ارمينيا": 1,
    "ارناوطي": 1,
    "ارنب": 1,
    "ارنبة": 1,
    "ارنيك": 1,
    "اروائي": 1,
    "ارواب": 1,
    "ارواث": 1,
    "ارواح": 1,
    "ارواق": 1,
    "اروام": 1,
    "اروبا": 1,
    "اروبي": 1,
    "اروج": 1,
    "اروح": 1,
    "اروع": 1,
    "اروغ": 1,
    "اروقة": 1,
    "ارول": 1,
    "ارومة": 1,
    "ارى": 1,
    "ارياح": 1,
    "ارياش": 1,
    "ارياف": 1,
    "ارياق": 1,
    "اريب": 1,
    "اريج": 1,
    "اريح": 1,
    "اريحا": 1,
    "اريحي": 1,
    "اريحية": 1,
    "اريس": 1,
    "اريكة": 1,
    "ارھاب": 1,
    "ارھابي": 1,
    "ارھاط": 1,
    "ارھاف": 1,
    "ارھاق": 1,
    "ارھط": 1,
    "ارھف": 1,
    "ارھقه": 1,
    "از": 1,
    "ازاء": 1,
    "ازاح": 1,
    "ازاحة": 1,
    "ازار": 1,
    "ازاغ": 1,
    "ازاميل": 1,
    "ازاھر": 1,
    "ازاھير": 1,
    "ازب": 1,
    "ازباب": 1,
    "ازباد": 1,
    "ازج": 1,
    "ازجال": 1,
    "ازجى": 1,
    "ازخم": 1,
    "ازداد": 1,
    "ازدان": 1,
    "ازدجر": 1,
    "ازدحام": 1,
    "ازدحم": 1,
    "ازدراء": 1,
    "ازدرد": 1,
    "ازدرع": 1,
    "ازدرى": 1,
    "ازدقم": 1,
    "ازدلف": 1,
    "ازدواج": 1,
    "ازدوج": 1,
    "ازدھاء": 1,
    "ازدھار": 1,
    "ازدھر": 1,
    "ازدھف": 1,
    "ازدھى": 1,
    "ازر": 1,
    "ازراء": 1,
    "ازرار": 1,
    "ازرقاق": 1,
    "ازره": 1,
    "ازعاج": 1,
    "ازعر": 1,
    "ازغب": 1,
    "ازف": 1,
    "ازق": 1,
    "ازقاق": 1,
    "ازقة": 1,
    "ازكى": 1,
    "ازكياء": 1,
    "ازل": 1,
    "ازلام": 1,
    "ازلقه": 1,
    "ازلي": 1,
    "ازلية": 1,
    "ازم": 1,
    "ازمان": 1,
    "ازمة": 1,
    "ازمنة": 1,
    "ازمير": 1,
    "ازميل": 1,
    "ازمھر": 1,
    "ازند": 1,
    "ازواج": 1,
    "ازواد": 1,
    "ازوال": 1,
    "ازوام": 1,
    "ازوت": 1,
    "ازوتي": 1,
    "ازودة": 1,
    "ازور": 1,
    "ازورار": 1,
    "ازوف": 1,
    "ازى": 1,
    "ازياء": 1,
    "ازياح": 1,
    "ازيار": 1,
    "ازياق": 1,
    "ازيتون": 1,
    "ازيد": 1,
    "ازيز": 1,
    "ازيف": 1,
    "ازھار": 1,
    "ازھر": 1,
    "ازھرى": 1,
    "ازھق": 1,
    "ازھى": 1,
    "اس": 1,
    "اسآر": 1,
    "اسأله": 1,
    "اسئلة": 1,
    "اسا": 1,
    "اسابيع": 1,
    "اساتذة": 1,
    "اسار": 1,
    "اساره": 1,
    "اسارى": 1,
    "اسارير": 1,
    "اساس": 1,
    "اساسا": 1,
    "اساسي": 1,
    "اساسيات": 1,
    "اساسية": 1,
    "اساطير": 1,
    "اساطيل": 1,
    "اساطين": 1,
    "اسافل": 1,
    "اسافين": 1,
    "اساق": 1,
    "اساقف": 1,
    "اساقفة": 1,
    "اساكفة": 1,
    "اساكل": 1,
    "اسال": 1,
    "اسالة": 1,
    "اساليب": 1,
    "اسام": 1,
    "اسانيد": 1,
    "اساور": 1,
    "اساورة": 1,
    "اسباب": 1,
    "اسبابه": 1,
    "اسباط": 1,
    "اسباع": 1,
    "اسباق": 1,
    "اسبانخ": 1,
    "اسباني": 1,
    "اسبانيا": 1,
    "اسبتة": 1,
    "اسبخة": 1,
    "اسبداج": 1,
    "اسبرتو": 1,
    "اسبع": 1,
    "اسبغ": 1,
    "اسبقية": 1,
    "اسبلة": 1,
    "اسبليطة": 1,
    "اسبوع": 1,
    "اسبوعي": 1,
    "اسبوعيا": 1,
    "اسبوعية": 1,
    "اسبيداج": 1,
    "است": 1,
    "استأثر": 1,
    "استأصل": 1,
    "استئثار": 1,
    "استئجار": 1,
    "استئمارة": 1,
    "استئمان": 1,
    "استئناف": 1,
    "استئنافا": 1,
    "استئنافى": 1,
    "استئنافيا": 1,
    "استئنافية": 1,
    "استاتيكي": 1,
    "استاذية": 1,
    "استار": 1,
    "استانبول": 1,
    "استانبولي": 1,
    "استانبولية": 1,
    "استاه": 1,
    "استباح": 1,
    "استباحة": 1,
    "استبداد": 1,
    "استبداديات": 1,
    "استبراء": 1,
    "استبرق": 1,
    "استبسال": 1,
    "استبشر": 1,
    "استبقاء": 1,
    "استبوال": 1,
    "استتب": 1,
    "استتباب": 1,
    "استتمام": 1,
    "استثار": 1,
    "استثقل": 1,
    "استثمار": 1,
    "استثناء": 1,
    "استثنائى": 1,
    "استثنائيا": 1,
    "استثنائية": 1,
    "استجاب": 1,
    "استجابة": 1,
    "استجار": 1,
    "استجلاء": 1,
    "استجلاب": 1,
    "استجماتم": 1,
    "استجواب": 1,
    "استجوب": 1,
    "استحداث": 1,
    "استحسان": 1,
    "استحضار": 1,
    "استحكام": 1,
    "استحكامات": 1,
    "استحمام": 1,
    "استحى": 1,
    "استحيا": 1,
    "استحين": 1,
    "استخار": 1,
    "استختبار": 1,
    "استخذاء": 1,
    "استخراج": 1,
    "استخفاف": 1,
    "استخلاص": 1,
    "استخلص": 1,
    "استخون": 1,
    "استدارة": 1,
    "استدانة": 1,
    "استدر": 1,
    "استدراج": 1,
    "استدراك": 1,
    "استدلال": 1,
    "استذكار": 1,
    "استراتيجي": 1,
    "استراح": 1,
    "استرالي": 1,
    "استرجاع": 1,
    "استرجع": 1,
    "استرحام": 1,
    "استرخاء": 1,
    "استرد": 1,
    "استرداد": 1,
    "استرسال": 1,
    "استرضاء": 1,
    "استرضائي": 1,
    "استرليني": 1,
    "استرواح": 1,
    "استروح": 1,
    "استزادة": 1,
    "استسرار": 1,
    "استسقاء": 1,
    "استسقائي": 1,
    "استسلام": 1,
    "استشارة": 1,
    "استشاط": 1,
    "استشفاء": 1,
    "استشھاد": 1,
    "استصباح": 1,
    "استصدار": 1,
    "استصطفاء": 1,
    "استصغر": 1,
    "استصفى": 1,
    "استصلاح": 1,
    "استصواب": 1,
    "استطاب": 1,
    "استطاعة": 1,
    "استطالة": 1,
    "استطراد": 1,
    "استطراق": 1,
    "استطرد": 1,
    "استطلاع": 1,
    "استطلاعي": 1,
    "استطلع": 1,
    "استطلعه": 1,
    "استطلق": 1,
    "استطيب": 1,
    "استطير": 1,
    "استطيع": 1,
    "استعادة": 1,
    "استعباد": 1,
    "استعجاب": 1,
    "استعجال": 1,
    "استعجالي": 1,
    "استعداد": 1,
    "استعداده": 1,
    "استعدادي": 1,
    "استعراضي": 1,
    "استعراضية": 1,
    "استعصاء": 1,
    "استعطاء": 1,
    "استعطاف": 1,
    "استعفاء": 1,
    "استعلاء": 1,
    "استعلام": 1,
    "استعمار": 1,
    "استعماري": 1,
    "استعمارية": 1,
    "استغاثة": 1,
    "استغراب": 1,
    "استغرب": 1,
    "استغشي": 1,
    "استغلالي": 1,
    "استغلق": 1,
    "استغمائية": 1,
    "استغنم": 1,
    "استفادة": 1,
    "استفاضة": 1,
    "استفتاء": 1,
    "استفتاح": 1,
    "استفتاحية": 1,
    "استفحال": 1,
    "استفراغ": 1,
    "استفرغ": 1,
    "استفزاز": 1,
    "استفسار": 1,
    "استفھام": 1,
    "استفھامي": 1,
    "استقالة": 1,
    "استقالته": 1,
    "استقام": 1,
    "استقبال": 1,
    "استقبالا": 1,
    "استقباله": 1,
    "استقراء": 1,
    "استقرائي": 1,
    "استقرار": 1,
    "استقراض": 1,
    "استقرت": 1,
    "استقصاء": 1,
    "استقطاب": 1,
    "استقطار": 1,
    "استقطاع": 1,
    "استقل": 1,
    "استقلال": 1,
    "استكانة": 1,
    "استكتاب": 1,
    "استكتابي": 1,
    "استكتابية": 1,
    "استكشافي": 1,
    "استكشافية": 1,
    "استكمال": 1,
    "استكناه": 1,
    "استلاب": 1,
    "استلام": 1,
    "استلحاق": 1,
    "استلفات": 1,
    "استلفاتة": 1,
    "استلفت": 1,
    "استماتة": 1,
    "استماح": 1,
    "استمارة": 1,
    "استمداد": 1,
    "استمرار": 1,
    "استمساك": 1,
    "استن": 1,
    "استناد": 1,
    "استنبات": 1,
    "استنباط": 1,
    "استنتاجاته": 1,
    "استنزال": 1,
    "استنساخ": 1,
    "استنشاق": 1,
    "استنصاح": 1,
    "استنطاق": 1,
    "استنفد": 1,
    "استنقاذ": 1,
    "استننھاض": 1,
    "استنوق": 1,
    "استنھج": 1,
    "استواء": 1,
    "استوبة": 1,
    "استوحى": 1,
    "استودعك": 1,
    "استودعه": 1,
    "استوديو": 1,
    "استوديوھات": 1,
    "استوصب": 1,
    "استوصى": 1,
    "استوقف": 1,
    "استوكھولم": 1,
    "استونيا": 1,
    "استياتيت": 1,
    "استيحاش": 1,
    "استيداع": 1,
    "استيراد": 1,
    "استيضاح": 1,
    "استيطان": 1,
    "استيفاء": 1,
    "استيلاء": 1,
    "استيلاد": 1,
    "استھان": 1,
    "استھانة": 1,
    "استھتار": 1,
    "استھجان": 1,
    "استھزاء": 1,
    "استھلاك": 1,
    "استھلاكي": 1,
    "استھلال": 1,
    "استھلالي": 1,
    "استھواء": 1,
    "استھون": 1,
    "استھيم": 1,
    "اسجاف": 1,
    "اسجح": 1,
    "اسحات": 1,
    "اسحار": 1,
    "اسحم": 1,
    "اسخياء": 1,
    "اسد": 1,
    "اسداد": 1,
    "اسداس": 1,
    "اسداسه": 1,
    "اسداف": 1,
    "اسدال": 1,
    "اسدة": 1,
    "اسدى": 1,
    "اسدية": 1,
    "اسر": 1,
    "اسراء": 1,
    "اسرائيلي": 1,
    "اسرائيليات": 1,
    "اسرائيلية": 1,
    "اسراب": 1,
    "اسرار": 1,
    "اسراع": 1,
    "اسراف": 1,
    "اسرافيل": 1,
    "اسرب": 1,
    "اسرة": 1,
    "اسروجة": 1,
    "اسرى": 1,
    "اسرياء": 1,
    "اسرية": 1,
    "اسس": 1,
    "اسطار": 1,
    "اسطال": 1,
    "اسطانبول": 1,
    "اسطبة": 1,
    "اسطبل": 1,
    "اسطح": 1,
    "اسطحة": 1,
    "اسطر": 1,
    "اسطرلاب": 1,
    "اسطع": 1,
    "اسطقس": 1,
    "اسطوات": 1,
    "اسطوانات": 1,
    "اسطوانة": 1,
    "اسطوانى": 1,
    "اسطورة": 1,
    "اسطورى": 1,
    "اسطول": 1,
    "اسطى": 1,
    "اسع": 1,
    "اسعار": 1,
    "اسعاف": 1,
    "اسعد": 1,
    "اسف": 1,
    "اسفار": 1,
    "اسفاط": 1,
    "اسفاف": 1,
    "اسفاناخ": 1,
    "اسفانخ": 1,
    "اسفاه": 1,
    "اسفع": 1,
    "اسفل": 1,
    "اسفلت": 1,
    "اسفله": 1,
    "اسفنج": 1,
    "اسفندان": 1,
    "اسفيداج": 1,
    "اسفين": 1,
    "اسقاط": 1,
    "اسقام": 1,
    "اسقربوطي": 1,
    "اسقط": 1,
    "اسقطه": 1,
    "اسقف": 1,
    "اسقفي": 1,
    "اسقفية": 1,
    "اسقمري": 1,
    "اسقيات": 1,
    "اسقية": 1,
    "اسقيل": 1,
    "اسك": 1,
    "اسكاف": 1,
    "اسكافي": 1,
    "اسكان": 1,
    "اسكتش": 1,
    "اسكتلندا": 1,
    "اسكتلندي": 1,
    "اسكفة": 1,
    "اسكلة": 1,
    "اسكملة": 1,
    "اسكندرونة": 1,
    "اسكندينافيا": 1,
    "اسل": 1,
    "اسلئة": 1,
    "اسلاب": 1,
    "اسلاف": 1,
    "اسلاك": 1,
    "اسلام": 1,
    "اسلامبولي": 1,
    "اسلامي": 1,
    "اسلامية": 1,
    "اسلانده": 1,
    "اسلة": 1,
    "اسلحة": 1,
    "اسلس": 1,
    "اسلفنا": 1,
    "اسلم": 1,
    "اسلنطح": 1,
    "اسلوب": 1,
    "اسما": 1,
    "اسماء": 1,
    "اسمار": 1,
    "اسماع": 1,
    "اسماعھم": 1,
    "اسمال": 1,
    "اسمانجوني": 1,
    "اسمدة": 1,
    "اسمر": 1,
    "اسمره": 1,
    "اسمطة": 1,
    "اسمنت": 1,
    "اسمنتي": 1,
    "اسمى": 1,
    "اسمية": 1,
    "اسن": 1,
    "اسناخ": 1,
    "اسناد": 1,
    "اسنان": 1,
    "اسنانه": 1,
    "اسنة": 1,
    "اسند": 1,
    "اسنمة": 1,
    "اسو": 1,
    "اسوأ": 1,
    "اسواء": 1,
    "اسوار": 1,
    "اسواط": 1,
    "اسواق": 1,
    "اسوان": 1,
    "اسوة": 1,
    "اسوج": 1,
    "اسوجة": 1,
    "اسوجي": 1,
    "اسودة": 1,
    "اسورة": 1,
    "اسوياء": 1,
    "اسى": 1,
    "اسياج": 1,
    "اسياخ": 1,
    "اسياد": 1,
    "اسياف": 1,
    "اسير": 1,
    "اسيرة": 1,
    "اسيف": 1,
    "اسيل": 1,
    "اسيوط": 1,
    "اسھاب": 1,
    "اسھال": 1,
    "اسھر": 1,
    "اسھل": 1,
    "اسھم": 1,
    "اسھمي": 1,
    "اشأم": 1,
    "اشائب": 1,
    "اشابة": 1,
    "اشابين": 1,
    "اشاجع": 1,
    "اشاحت": 1,
    "اشاد": 1,
    "اشادة": 1,
    "اشار": 1,
    "اشارته": 1,
    "اشارجي": 1,
    "اشاري": 1,
    "اشاعة": 1,
    "اشاعية": 1,
    "اشاف": 1,
    "اشاوس": 1,
    "اشب": 1,
    "اشباح": 1,
    "اشبار": 1,
    "اشباع": 1,
    "اشبال": 1,
    "اشباه": 1,
    "اشباھه": 1,
    "اشببيلية": 1,
    "اشبع": 1,
    "اشبعه": 1,
    "اشبه": 1,
    "اشبين": 1,
    "اشبينة": 1,
    "اشتات": 1,
    "اشتبك": 1,
    "اشتبه": 1,
    "اشتد": 1,
    "اشتداد": 1,
    "اشتراء": 1,
    "اشتراط": 1,
    "اشتراعي": 1,
    "اشتراك": 1,
    "اشتراكية": 1,
    "اشتعل": 1,
    "اشتغال": 1,
    "اشتغل": 1,
    "اشتقاق": 1,
    "اشتمله": 1,
    "اشتياق": 1,
    "اشتية": 1,
    "اشتھاء": 1,
    "اشتھار": 1,
    "اشجان": 1,
    "اشجر": 1,
    "اشجع": 1,
    "اشحاء": 1,
    "اشحة": 1,
    "اشخاص": 1,
    "اشد": 1,
    "اشداء": 1,
    "اشداق": 1,
    "اشدة": 1,
    "اشدق": 1,
    "اشده": 1,
    "اشر": 1,
    "اشرأب": 1,
    "اشراء": 1,
    "اشراج": 1,
    "اشرار": 1,
    "اشراط": 1,
    "اشراف": 1,
    "اشراق": 1,
    "اشراك": 1,
    "اشربة": 1,
    "اشربه": 1,
    "اشرطة": 1,
    "اشرع": 1,
    "اشرك": 1,
    "اشركه": 1,
    "اشرم": 1,
    "اشرية": 1,
    "اشطره": 1,
    "اشعار": 1,
    "اشعاع": 1,
    "اشعاعي": 1,
    "اشعال": 1,
    "اشعب": 1,
    "اشعبي": 1,
    "اشعة": 1,
    "اشعر": 1,
    "اشعرة": 1,
    "اشغال": 1,
    "اشغل": 1,
    "اشفار": 1,
    "اشفاع": 1,
    "اشفاق": 1,
    "اشفى": 1,
    "اشفية": 1,
    "اشق": 1,
    "اشقاء": 1,
    "اشقة": 1,
    "اشقر": 1,
    "اشقياء": 1,
    "اشكال": 1,
    "اشل": 1,
    "اشلاء": 1,
    "اشم": 1,
    "اشمأز": 1,
    "اشمط": 1,
    "اشمل": 1,
    "اشنان": 1,
    "اشنب": 1,
    "اشنة": 1,
    "اشنراك": 1,
    "اشنع": 1,
    "اشواطا": 1,
    "اشواق": 1,
    "اشواك": 1,
    "اشور": 1,
    "اشوري": 1,
    "اشوس": 1,
    "اشوه": 1,
    "اشياء": 1,
    "اشياخ": 1,
    "اشياع": 1,
    "اشياف": 1,
    "اشيب": 1,
    "اشيك": 1,
    "اشھاد": 1,
    "اشھار": 1,
    "اشھب": 1,
    "اشھر": 1,
    "اشھق": 1,
    "اشھل": 1,
    "اصائل": 1,
    "اصاب": 1,
    "اصابات": 1,
    "اصابة": 1,
    "اصابع": 1,
    "اصابه": 1,
    "اصابيع": 1,
    "اصاغر": 1,
    "اصال": 1,
    "اصالة": 1,
    "اصباء": 1,
    "اصباب": 1,
    "اصباح": 1,
    "اصباغ": 1,
    "اصبع": 1,
    "اصبغة": 1,
    "اصبوع": 1,
    "اصبية": 1,
    "اصتياف": 1,
    "اصح": 1,
    "اصحاء": 1,
    "اصحاب": 1,
    "اصحابه": 1,
    "اصحاح": 1,
    "اصحر": 1,
    "اصحنة": 1,
    "اصد": 1,
    "اصداء": 1,
    "اصدار": 1,
    "اصداغ": 1,
    "اصداف": 1,
    "اصدق": 1,
    "اصدقاء": 1,
    "اصدقة": 1,
    "اصر": 1,
    "اصرار": 1,
    "اصرح": 1,
    "اصص": 1,
    "اصطبار": 1,
    "اصطبل": 1,
    "اصطحاب": 1,
    "اصطخاب": 1,
    "اصطدام": 1,
    "اصطراع": 1,
    "اصطفاء": 1,
    "اصطلاح": 1,
    "اصطلاحي": 1,
    "اصطناع": 1,
    "اصطناعي": 1,
    "اصغاء": 1,
    "اصغار": 1,
    "اصغر": 1,
    "اصغرى": 1,
    "اصفاد": 1,
    "اصفار": 1,
    "اصفان": 1,
    "اصفاه": 1,
    "اصفياء": 1,
    "اصفھان": 1,
    "اصقاع": 1,
    "اصقر": 1,
    "اصقع": 1,
    "اصك": 1,
    "اصل": 1,
    "اصلا": 1,
    "اصلاء": 1,
    "اصلاب": 1,
    "اصلاح": 1,
    "اصلاحي": 1,
    "اصلاحية": 1,
    "اصلاد": 1,
    "اصلال": 1,
    "اصلاه": 1,
    "اصلب": 1,
    "اصلح": 1,
    "اصلحت": 1,
    "اصلخ": 1,
    "اصلع": 1,
    "اصلي": 1,
    "اصلية": 1,
    "اصم": 1,
    "اصمخة": 1,
    "اصناء": 1,
    "اصناف": 1,
    "اصنام": 1,
    "اصواع": 1,
    "اصواف": 1,
    "اصوب": 1,
    "اصوبية": 1,
    "اصوع": 1,
    "اصولي": 1,
    "اصونة": 1,
    "اصياف": 1,
    "اصيب": 1,
    "اصيص": 1,
    "اصيل": 1,
    "اصھار": 1,
    "اصھب": 1,
    "اضاءة": 1,
    "اضابر": 1,
    "اضاحك": 1,
    "اضاحى": 1,
    "اضاع": 1,
    "اضاعة": 1,
    "اضاف": 1,
    "اضافة": 1,
    "اضافي": 1,
    "اضاليل": 1,
    "اضب": 1,
    "اضبارة": 1,
    "اضبع": 1,
    "اضبور": 1,
    "اضجع": 1,
    "اضحاة": 1,
    "اضحك": 1,
    "اضحوكة": 1,
    "اضحى": 1,
    "اضحية": 1,
    "اضداد": 1,
    "اضر": 1,
    "اضراب": 1,
    "اضرار": 1,
    "اضراس": 1,
    "اضرحة": 1,
    "اضطجع": 1,
    "اضطرابات": 1,
    "اضطرار": 1,
    "اضطرارى": 1,
    "اضطراري": 1,
    "اضطرام": 1,
    "اضطرب": 1,
    "اضطھاد": 1,
    "اضعاف": 1,
    "اضعافا": 1,
    "اضعافه": 1,
    "اضغاث": 1,
    "اضغان": 1,
    "اضف": 1,
    "اضفار": 1,
    "اضلاع": 1,
    "اضلال": 1,
    "اضلع": 1,
    "اضلولة": 1,
    "اضمار": 1,
    "اضمحل": 1,
    "اضمحلال": 1,
    "اضمر": 1,
    "اضمن": 1,
    "اضواء": 1,
    "اضوى": 1,
    "اضياف": 1,
    "اضيق": 1,
    "اطار": 1,
    "اطارة": 1,
    "اطاري": 1,
    "اطاعة": 1,
    "اطاقة": 1,
    "اطال": 1,
    "اطالة": 1,
    "اطالس": 1,
    "اطايب": 1,
    "اطباء": 1,
    "اطباق": 1,
    "اطبال": 1,
    "اطبة": 1,
    "اطبق": 1,
    "اطر": 1,
    "اطراء": 1,
    "اطراب": 1,
    "اطراح": 1,
    "اطراحا": 1,
    "اطراد": 1,
    "اطرافا": 1,
    "اطرافھا": 1,
    "اطراقة": 1,
    "اطرب": 1,
    "اطرزة": 1,
    "اطرس": 1,
    "اطرش": 1,
    "اطرغلة": 1,
    "اطرف": 1,
    "اطرق": 1,
    "اطروحة": 1,
    "اطرون": 1,
    "اطرية": 1,
    "اطعام": 1,
    "اطعمة": 1,
    "اطعمه": 1,
    "اطفأ": 1,
    "اطفاء": 1,
    "اطفائي": 1,
    "اطفائية": 1,
    "اطفال": 1,
    "اطقم": 1,
    "اطلاع": 1,
    "اطلاقا": 1,
    "اطلال": 1,
    "اطلانطي": 1,
    "اطلانطيكي": 1,
    "اطلس": 1,
    "اطلسي": 1,
    "اطلق": 1,
    "اطلقوا": 1,
    "اطلنطي": 1,
    "اطمئنان": 1,
    "اطمار": 1,
    "اطماع": 1,
    "اطمع": 1,
    "اطناب": 1,
    "اطنابه": 1,
    "اطناف": 1,
    "اطنان": 1,
    "اطواء": 1,
    "اطواد": 1,
    "اطوار": 1,
    "اطواف": 1,
    "اطواق": 1,
    "اطوال": 1,
    "اطول": 1,
    "اطوم": 1,
    "اطيار": 1,
    "اطياف": 1,
    "اطيان": 1,
    "اطيب": 1,
    "اطيط": 1,
    "اطھار": 1,
    "اظافر": 1,
    "اظافير": 1,
    "اظعان": 1,
    "اظفار": 1,
    "اظفاره": 1,
    "اظفور": 1,
    "اظلاف": 1,
    "اظلال": 1,
    "اظلام": 1,
    "اظلم": 1,
    "اظن": 1,
    "اظنك": 1,
    "اظھار": 1,
    "اظھر": 1,
    "اظھرنا": 1,
    "اظھرھم": 1,
    "اعاجم": 1,
    "اعاجيب": 1,
    "اعاد": 1,
    "اعاده": 1,
    "اعارة": 1,
    "اعاري": 1,
    "اعارية": 1,
    "اعاريض": 1,
    "اعاصير": 1,
    "اعاظم": 1,
    "اعال": 1,
    "اعالي": 1,
    "اعاليم": 1,
    "اعانة": 1,
    "اعبئة": 1,
    "اعباء": 1,
    "اعبل": 1,
    "اعتاء": 1,
    "اعتاب": 1,
    "اعتاق": 1,
    "اعتبار": 1,
    "اعتبارا": 1,
    "اعتباري": 1,
    "اعتبارية": 1,
    "اعتباطا": 1,
    "اعتد": 1,
    "اعتداء": 1,
    "اعتداد": 1,
    "اعتدال": 1,
    "اعتدالي": 1,
    "اعتدة": 1,
    "اعتراض": 1,
    "اعتراف": 1,
    "اعترافا": 1,
    "اعترض": 1,
    "اعتزار": 1,
    "اعتزاز": 1,
    "اعتزال": 1,
    "اعتزل": 1,
    "اعتساف": 1,
    "اعتصاب": 1,
    "اعتصام": 1,
    "اعتصر": 1,
    "اعتطف": 1,
    "اعتقاد": 1,
    "اعتقال": 1,
    "اعتقد": 1,
    "اعتلاء": 1,
    "اعتلال": 1,
    "اعتماد": 1,
    "اعتناء": 1,
    "اعتناق": 1,
    "اعتور": 1,
    "اعتياد": 1,
    "اعتيادي": 1,
    "اعجاب": 1,
    "اعجاز": 1,
    "اعجام": 1,
    "اعجب": 1,
    "اعجز": 1,
    "اعجزه": 1,
    "اعجف": 1,
    "اعجل": 1,
    "اعجله": 1,
    "اعجم": 1,
    "اعجمي": 1,
    "اعجوبة": 1,
    "اعداء": 1,
    "اعداد": 1,
    "اعدادي": 1,
    "اعدادية": 1,
    "اعدال": 1,
    "اعدام": 1,
    "اعدامية": 1,
    "اعدل": 1,
    "اعدى": 1,
    "اعذار": 1,
    "اعذاق": 1,
    "اعذب": 1,
    "اعذبة": 1,
    "اعذر": 1,
    "اعراب": 1,
    "اعرابي": 1,
    "اعراس": 1,
    "اعراش": 1,
    "اعراص": 1,
    "اعراض": 1,
    "اعراف": 1,
    "اعرب": 1,
    "اعرج": 1,
    "اعرف": 1,
    "اعرق": 1,
    "اعره": 1,
    "اعز": 1,
    "اعزاء": 1,
    "اعزاب": 1,
    "اعزاز": 1,
    "اعزب": 1,
    "اعزة": 1,
    "اعزل": 1,
    "اعسار": 1,
    "اعسال": 1,
    "اعسر": 1,
    "اعشاء": 1,
    "اعشاب": 1,
    "اعشار": 1,
    "اعشاري": 1,
    "اعشاش": 1,
    "اعشى": 1,
    "اعشية": 1,
    "اعص": 1,
    "اعصاب": 1,
    "اعصابه": 1,
    "اعصار": 1,
    "اعصر": 1,
    "اعصم": 1,
    "اعصمة": 1,
    "اعصياء": 1,
    "اعضاء": 1,
    "اعضاءه": 1,
    "اعضاد": 1,
    "اعضل": 1,
    "اعط": 1,
    "اعطاف": 1,
    "اعطافه": 1,
    "اعطاه": 1,
    "اعطفة": 1,
    "اعطى": 1,
    "اععجاج": 1,
    "اعف": 1,
    "اعفاء": 1,
    "اعفار": 1,
    "اعفاه": 1,
    "اعفة": 1,
    "اعفر": 1,
    "اعق": 1,
    "اعقاب": 1,
    "اعقابھم": 1,
    "اعقب": 1,
    "اعقة": 1,
    "اعقد": 1,
    "اعقف": 1,
    "اعقك": 1,
    "اعقل": 1,
    "اعلاء": 1,
    "اعلاف": 1,
    "اعلاق": 1,
    "اعلام": 1,
    "اعلان": 1,
    "اعلانات": 1,
    "اعلاه": 1,
    "اعلق": 1,
    "اعلم": 1,
    "اعلن": 1,
    "اعلومة": 1,
    "اعمار": 1,
    "اعماق": 1,
    "اعمال": 1,
    "اعماله": 1,
    "اعمام": 1,
    "اعمدة": 1,
    "اعمر": 1,
    "اعمش": 1,
    "اعمى": 1,
    "اعناب": 1,
    "اعنات": 1,
    "اعناق": 1,
    "اعنة": 1,
    "اعنتھا": 1,
    "اعنز": 1,
    "اعنف": 1,
    "اعنق": 1,
    "اعني": 1,
    "اعواد": 1,
    "اعوام": 1,
    "اعوج": 1,
    "اعود": 1,
    "اعوذ": 1,
    "اعور": 1,
    "اعوز": 1,
    "اعوزه": 1,
    "اعيادا": 1,
    "اعيته": 1,
    "اعيننا": 1,
    "اعھد": 1,
    "اغا": 1,
    "اغاثة": 1,
    "اغارة": 1,
    "اغاريد": 1,
    "اغاريض": 1,
    "اغاسي": 1,
    "اغاليط": 1,
    "اغان": 1,
    "اغاوى": 1,
    "اغباب": 1,
    "اغباش": 1,
    "اغبر": 1,
    "اغبرار": 1,
    "اغبرة": 1,
    "اغبش": 1,
    "اغبى": 1,
    "اغبياء": 1,
    "اغتباط": 1,
    "اغتراب": 1,
    "اغترز": 1,
    "اغتصاب": 1,
    "اغتصب": 1,
    "اغتنم": 1,
    "اغتياب": 1,
    "اغتياظ": 1,
    "اغتيال": 1,
    "اغد": 1,
    "اغدية": 1,
    "اغذية": 1,
    "اغر": 1,
    "اغراء": 1,
    "اغراب": 1,
    "اغرار": 1,
    "اغراس": 1,
    "اغراض": 1,
    "اغراق": 1,
    "اغرب": 1,
    "اغربة": 1,
    "اغرة": 1,
    "اغرض": 1,
    "اغرق": 1,
    "اغرودة": 1,
    "اغرورقت": 1,
    "اغري": 1,
    "اغريقي": 1,
    "اغسال": 1,
    "اغسطس": 1,
    "اغشية": 1,
    "اغصان": 1,
    "اغضاء": 1,
    "اغضاب": 1,
    "اغضى": 1,
    "اغطية": 1,
    "اغفاءة": 1,
    "اغفال": 1,
    "اغلاء": 1,
    "اغلاط": 1,
    "اغلال": 1,
    "اغلالات": 1,
    "اغلب": 1,
    "اغلبية": 1,
    "اغلظ": 1,
    "اغلف": 1,
    "اغلق": 1,
    "اغلوطة": 1,
    "اغلى": 1,
    "اغم": 1,
    "اغماء": 1,
    "اغماد": 1,
    "اغمار": 1,
    "اغمض": 1,
    "اغمي": 1,
    "اغن": 1,
    "اغنام": 1,
    "اغنى": 1,
    "اغنياء": 1,
    "اغنية": 1,
    "اغواء": 1,
    "اغوات": 1,
    "اغوار": 1,
    "اغواط": 1,
    "اغوال": 1,
    "اغوية": 1,
    "اغياث": 1,
    "اغياد": 1,
    "اغياض": 1,
    "اغيال": 1,
    "اغيان": 1,
    "افؤس": 1,
    "افؤل": 1,
    "افئدة": 1,
    "افائك": 1,
    "افاحيص": 1,
    "افادة": 1,
    "افارقة": 1,
    "افاريز": 1,
    "افاشين": 1,
    "افاضة": 1,
    "افاضل": 1,
    "افاع": 1,
    "افاعيل": 1,
    "افاق": 1,
    "افاقة": 1,
    "افاك": 1,
    "افاكيه": 1,
    "افانين": 1,
    "افاويق": 1,
    "افاويه": 1,
    "افتآت": 1,
    "افتأت": 1,
    "افتئت": 1,
    "افتاء": 1,
    "افتار": 1,
    "افتتاح": 1,
    "افتتاحي": 1,
    "افتتاحية": 1,
    "افتخار": 1,
    "افتداه": 1,
    "افتراضي": 1,
    "افتراق": 1,
    "افترش": 1,
    "افترق": 1,
    "افتضاح": 1,
    "افتضاض": 1,
    "افتضح": 1,
    "افتقاد": 1,
    "افتقار": 1,
    "افتك": 1,
    "افتكاك": 1,
    "افتنان": 1,
    "افتيات": 1,
    "افحوص": 1,
    "افخاذ": 1,
    "افخر": 1,
    "افدح": 1,
    "افدنة": 1,
    "افذاذ": 1,
    "افراء": 1,
    "افراج": 1,
    "افراح": 1,
    "افراخ": 1,
    "افراد": 1,
    "افراز": 1,
    "افراس": 1,
    "افراط": 1,
    "افراغ": 1,
    "افران": 1,
    "افرخ": 1,
    "افرزة": 1,
    "افرشة": 1,
    "افرع": 1,
    "افرغ": 1,
    "افرغه": 1,
    "افرقاء": 1,
    "افرقة": 1,
    "افرنجى": 1,
    "افرنجي": 1,
    "افرنسي": 1,
    "افريز": 1,
    "افريقا": 1,
    "افريقي": 1,
    "افريقيا": 1,
    "افريقية": 1,
    "افزاع": 1,
    "افساد": 1,
    "افسد": 1,
    "افسنتين": 1,
    "افشين": 1,
    "افصاح": 1,
    "افصح": 1,
    "افضال": 1,
    "افضل": 1,
    "افضلية": 1,
    "افضى": 1,
    "افضية": 1,
    "افطار": 1,
    "افطح": 1,
    "افطس": 1,
    "افظاظ": 1,
    "افعال": 1,
    "افعله": 1,
    "افعوان": 1,
    "افغانستان": 1,
    "افغاني": 1,
    "افف": 1,
    "افق": 1,
    "افقي": 1,
    "افك": 1,
    "افكار": 1,
    "افكاره": 1,
    "افكوھة": 1,
    "افل": 1,
    "افلاء": 1,
    "افلات": 1,
    "افلاذ": 1,
    "افلاس": 1,
    "افلاطون": 1,
    "افلاك": 1,
    "افلال": 1,
    "افلام": 1,
    "افمام": 1,
    "افن": 1,
    "افناء": 1,
    "افنان": 1,
    "افندم": 1,
    "افندي": 1,
    "افنون": 1,
    "افنية": 1,
    "افوات": 1,
    "افواج": 1,
    "افواجا": 1,
    "افواه": 1,
    "افوك": 1,
    "افوكاتو": 1,
    "افول": 1,
    "افوه": 1,
    "افوھد": 1,
    "افياء": 1,
    "افيال": 1,
    "افيح": 1,
    "افيد": 1,
    "افيكة": 1,
    "افين": 1,
    "افيون": 1,
    "افھام": 1,
    "افھد": 1,
    "اقاح": 1,
    "اقاحى": 1,
    "اقاحي": 1,
    "اقاديح": 1,
    "اقارب": 1,
    "اقاص": 1,
    "اقاصي": 1,
    "اقاصيص": 1,
    "اقال": 1,
    "اقالة": 1,
    "اقاله": 1,
    "اقاليد": 1,
    "اقاليم": 1,
    "اقام": 1,
    "اقامه": 1,
    "اقانيم": 1,
    "اقاويل": 1,
    "اقب": 1,
    "اقبال": 1,
    "اقبالا": 1,
    "اقبح": 1,
    "اقبية": 1,
    "اقة": 1,
    "اقتاب": 1,
    "اقتار": 1,
    "اقتال": 1,
    "اقتباس": 1,
    "اقتبال": 1,
    "اقتحام": 1,
    "اقتداء": 1,
    "اقتدح": 1,
    "اقتراب": 1,
    "اقتراح": 1,
    "اقتراحات": 1,
    "اقتراض": 1,
    "اقتران": 1,
    "اقتسار": 1,
    "اقتسام": 1,
    "اقتصادا": 1,
    "اقتصادى": 1,
    "اقتصادية": 1,
    "اقتضاء": 1,
    "اقتضاب": 1,
    "اقتطاف": 1,
    "اقتفاء": 1,
    "اقتفى": 1,
    "اقتل": 1,
    "اقتم": 1,
    "اقتناء": 1,
    "اقتناع": 1,
    "اقتنع": 1,
    "اقتور": 1,
    "اقحاح": 1,
    "اقحاف": 1,
    "اقحام": 1,
    "اقحم": 1,
    "اقحوان": 1,
    "اقد": 1,
    "اقداح": 1,
    "اقدار": 1,
    "اقداس": 1,
    "اقدام": 1,
    "اقدامه": 1,
    "اقدح": 1,
    "اقدر": 1,
    "اقدس": 1,
    "اقدم": 1,
    "اقدمية": 1,
    "اقذاء": 1,
    "اقذار": 1,
    "اقذلة": 1,
    "اقذى": 1,
    "اقر": 1,
    "اقرأه": 1,
    "اقراص": 1,
    "اقراط": 1,
    "اقران": 1,
    "اقرب": 1,
    "اقرباء": 1,
    "اقرباذين": 1,
    "اقرباذيني": 1,
    "اقربة": 1,
    "اقربون": 1,
    "اقرع": 1,
    "اقرن": 1,
    "اقزام": 1,
    "اقساط": 1,
    "اقسام": 1,
    "اقسة": 1,
    "اقسط": 1,
    "اقسم": 1,
    "اقسماء": 1,
    "اقسموا": 1,
    "اقسى": 1,
    "اقشاب": 1,
    "اقشعر": 1,
    "اقصاء": 1,
    "اقصاه": 1,
    "اقصد": 1,
    "اقصر": 1,
    "اقصوصة": 1,
    "اقصى": 1,
    "اقض": 1,
    "اقضية": 1,
    "اقطاب": 1,
    "اقطار": 1,
    "اقطاري": 1,
    "اقطاع": 1,
    "اقطاعة": 1,
    "اقطاعي": 1,
    "اقطاعية": 1,
    "اقطان": 1,
    "اقعد": 1,
    "اقعدة": 1,
    "اقعس": 1,
    "اقعنسس": 1,
    "اقف": 1,
    "اقفاء": 1,
    "اقفار": 1,
    "اقفاص": 1,
    "اقفال": 1,
    "اقفزة": 1,
    "اقفية": 1,
    "اقل": 1,
    "اقلاء": 1,
    "اقلاع": 1,
    "اقلاق": 1,
    "اقلال": 1,
    "اقلام": 1,
    "اقلبة": 1,
    "اقلص": 1,
    "اقلع": 1,
    "اقلق": 1,
    "اقلم": 1,
    "اقلية": 1,
    "اقليد": 1,
    "اقليدس": 1,
    "اقليمي": 1,
    "اقليمية": 1,
    "اقمار": 1,
    "اقماط": 1,
    "اقماع": 1,
    "اقمح": 1,
    "اقمر": 1,
    "اقمشة": 1,
    "اقمصة": 1,
    "اقمطة": 1,
    "اقناء": 1,
    "اقناع": 1,
    "اقنان": 1,
    "اقنة": 1,
    "اقنعة": 1,
    "اقنوم": 1,
    "اقنى": 1,
    "اقنية": 1,
    "اقواء": 1,
    "اقواب": 1,
    "اقوات": 1,
    "اقواس": 1,
    "اقواش": 1,
    "اقواط": 1,
    "اقواع": 1,
    "اقوال": 1,
    "اقواله": 1,
    "اقوالھم": 1,
    "اقوام": 1,
    "اقود": 1,
    "اقوع": 1,
    "اقول": 1,
    "اقوم": 1,
    "اقونة": 1,
    "اقوى": 1,
    "اقوياء": 1,
    "اقياد": 1,
    "اقيال": 1,
    "اقيانوسية": 1,
    "اقيةن": 1,
    "اقيسة": 1,
    "اقيط": 1,
    "اقھى": 1,
    "اكابر": 1,
    "اكادى": 1,
    "اكاديمية": 1,
    "اكاذيب": 1,
    "اكار": 1,
    "اكارع": 1,
    "اكارم": 1,
    "اكاسر": 1,
    "اكاسرة": 1,
    "اكال": 1,
    "اكاليل": 1,
    "اكام": 1,
    "اكاميم": 1,
    "اكباب": 1,
    "اكباد": 1,
    "اكبار": 1,
    "اكباش": 1,
    "اكبر": 1,
    "اكتئاب": 1,
    "اكتاف": 1,
    "اكتتاب": 1,
    "اكتتام": 1,
    "اكتحل": 1,
    "اكتراء": 1,
    "اكتراث": 1,
    "اكتساب": 1,
    "اكتساح": 1,
    "اكتع": 1,
    "اكتفاء": 1,
    "اكتمال": 1,
    "اكتناز": 1,
    "اكتناه": 1,
    "اكتوبر": 1,
    "اكثار": 1,
    "اكثبة": 1,
    "اكثرية": 1,
    "اكحال": 1,
    "اكحل": 1,
    "اكد": 1,
    "اكداس": 1,
    "اكدر": 1,
    "اكذب": 1,
    "اكذوبة": 1,
    "اكر": 1,
    "اكراء": 1,
    "اكراح": 1,
    "اكراد": 1,
    "اكراش": 1,
    "اكرام": 1,
    "اكراما": 1,
    "اكرامية": 1,
    "اكراه": 1,
    "اكراھي": 1,
    "اكرة": 1,
    "اكرت": 1,
    "اكرش": 1,
    "اكرع": 1,
    "اكرم": 1,
    "اكزيما": 1,
    "اكس": 1,
    "اكسب": 1,
    "اكسبريس": 1,
    "اكسترا": 1,
    "اكسجين": 1,
    "اكسح": 1,
    "اكسية": 1,
    "اكسير": 1,
    "اكشاك": 1,
    "اكف": 1,
    "اكفاء": 1,
    "اكفال": 1,
    "اكفان": 1,
    "اكفس": 1,
    "اكفھرار": 1,
    "اكل": 1,
    "اكلاء": 1,
    "اكلة": 1,
    "اكلف": 1,
    "اكلمة": 1,
    "اكله": 1,
    "اكليركى": 1,
    "اكليركي": 1,
    "اكليروس": 1,
    "اكليروسية": 1,
    "اكليل": 1,
    "اكلينيكي": 1,
    "اكم": 1,
    "اكمؤ": 1,
    "اكماء": 1,
    "اكمار": 1,
    "اكمال": 1,
    "اكمام": 1,
    "اكمة": 1,
    "اكمد": 1,
    "اكمل": 1,
    "اكمه": 1,
    "اكناف": 1,
    "اكنافه": 1,
    "اكنان": 1,
    "اكنة": 1,
    "اكواب": 1,
    "اكواخ": 1,
    "اكواد": 1,
    "اكوار": 1,
    "اكواز": 1,
    "اكواس": 1,
    "اكواع": 1,
    "اكوام": 1,
    "اكوان": 1,
    "اكور": 1,
    "اكوس": 1,
    "اكول": 1,
    "اكيار": 1,
    "اكياس": 1,
    "اكيال": 1,
    "اكيس": 1,
    "اكيل": 1,
    "الآبدين": 1,
    "الآتي": 1,
    "الآجلة": 1,
    "الآحد": 1,
    "الآدميين": 1,
    "الآستانة": 1,
    "الآلھة": 1,
    "الآنام": 1,
    "الآوراس": 1,
    "الآونة": 1,
    "الأبوية": 1,
    "الأثافي": 1,
    "الأثينيين": 1,
    "الأجمية": 1,
    "الأجنبية": 1,
    "الأحايين": 1,
    "الأحباس": 1,
    "الأحمدية": 1,
    "الأحمران": 1,
    "الأخص": 1,
    "الأخمص": 1,
    "الأخوين": 1,
    "الأخيرة": 1,
    "الأدبي": 1,
    "الأدنون": 1,
    "الأدون": 1,
    "الأذھان": 1,
    "الأرب": 1,
    "الأربعاء": 1,
    "الأربعون": 1,
    "الأربعين": 1,
    "الأرجاء": 1,
    "الأرجح": 1,
    "الأروام": 1,
    "الأروبية": 1,
    "الأزمان": 1,
    "الأزھر": 1,
    "الأزھران": 1,
    "الأساسى": 1,
    "الأسبان": 1,
    "الأسھم": 1,
    "الأشراف": 1,
    "الأشھاد": 1,
    "الأشھر": 1,
    "الأصح": 1,
    "الأصقاع": 1,
    "الأصلى": 1,
    "الأضحى": 1,
    "الأطفاء": 1,
    "الأطلال": 1,
    "الأظفار": 1,
    "الأعتاب": 1,
    "الأعرابية": 1,
    "الأعزل": 1,
    "الأعصم": 1,
    "الأعطاف": 1,
    "الأعور": 1,
    "الأعيان": 1,
    "الأفلاك": 1,
    "الأفھام": 1,
    "الأقباط": 1,
    "الأقداس": 1,
    "الأقرب": 1,
    "الأقوام": 1,
    "الأكابر": 1,
    "الأكاذب": 1,
    "الأكثرون": 1,
    "الأكحل": 1,
    "الأكراد": 1,
    "الأكف": 1,
    "الأكمة": 1,
    "الألسن": 1,
    "الألواء": 1,
    "الألوف": 1,
    "الأمانية": 1,
    "الأمران": 1,
    "الأمرض": 1,
    "الأمرين": 1,
    "الأمطر": 1,
    "الأملاك": 1,
    "الأممي": 1,
    "الأممية": 1,
    "الأناة": 1,
    "الأناشد": 1,
    "الأنباط": 1,
    "الأنشاد": 1,
    "الأنقى": 1,
    "الأنوار": 1,
    "الأوقاف": 1,
    "الأھالى": 1,
    "الأھرام": 1,
    "الأھلون": 1,
    "الأھلي": 1,
    "الأھلية": 1,
    "الأھمية": 1,
    "الأھواء": 1,
    "الأھوال": 1,
    "الإخوان": 1,
    "الإشفاق": 1,
    "الإشمام": 1,
    "الإشھار": 1,
    "الإفرنج": 1,
    "الإفرنجي": 1,
    "الإقطاع": 1,
    "الإلھية": 1,
    "الإمضاء": 1,
    "الإمكان": 1,
    "الإنساني": 1,
    "الإيھام": 1,
    "الائف": 1,
    "الااشتباك": 1,
    "الابتدائى": 1,
    "الابجدية": 1,
    "الابدي": 1,
    "الابرة": 1,
    "الابصار": 1,
    "الابعاد": 1,
    "الابعد": 1,
    "الابوان": 1,
    "الابيض": 1,
    "الاتحادية": 1,
    "الاتراك": 1,
    "الاتقان": 1,
    "الاتھام": 1,
    "الاتھامية": 1,
    "الاثبات": 1,
    "الاثر": 1,
    "الاثقال": 1,
    "الاثم": 1,
    "الاثناء": 1,
    "الاثنين": 1,
    "الاثير": 1,
    "الاجازات": 1,
    "الاجانب": 1,
    "الاجبارى": 1,
    "الاجباري": 1,
    "الاجبارية": 1,
    "الاجتماعية": 1,
    "الاجرام": 1,
    "الاجربة": 1,
    "الاجرة": 1,
    "الاجماع": 1,
    "الاجماعية": 1,
    "الاجمال": 1,
    "الاجنبية": 1,
    "الاجواد": 1,
    "الاحالة": 1,
    "الاحايين": 1,
    "الاحباب": 1,
    "الاحبار": 1,
    "الاحباش": 1,
    "الاحتراز": 1,
    "الاحدوثة": 1,
    "الاحرار": 1,
    "الاحساس": 1,
    "الاحساسية": 1,
    "الاحسان": 1,
    "الاحسن": 1,
    "الاحكام": 1,
    "الاحلام": 1,
    "الاحيائية": 1,
    "الاخراج": 1,
    "الاخضر": 1,
    "الاخلال": 1,
    "الاخير": 1,
    "الاداري": 1,
    "الادب": 1,
    "الادراك": 1,
    "الادنى": 1,
    "الادياتيك": 1,
    "الاذاعة": 1,
    "الارادة": 1,
    "الاراضي": 1,
    "الارتكاز": 1,
    "الارث": 1,
    "الارثوذكسي": 1,
    "الارثوذكسية": 1,
    "الارجنتين": 1,
    "الارحام": 1,
    "الاردن": 1,
    "الاردنية": 1,
    "الارشادات": 1,
    "الارضاء": 1,
    "الارضية": 1,
    "الارم": 1,
    "الارمن": 1,
    "الارناوط": 1,
    "الارواح": 1,
    "الازبك": 1,
    "الازدواج": 1,
    "الازرقاق": 1,
    "الازمة": 1,
    "الازياء": 1,
    "الاساسي": 1,
    "الاساقفة": 1,
    "الاسبان": 1,
    "الاستاذ": 1,
    "الاستانة": 1,
    "الاستختبارات": 1,
    "الاسترليني": 1,
    "الاستسقاء": 1,
    "الاستشراق": 1,
    "الاستصباح": 1,
    "الاستطلاع": 1,
    "الاستعجال": 1,
    "الاستعلامات": 1,
    "الاستعمالات": 1,
    "الاستغاثة": 1,
    "الاستغراب": 1,
    "الاستفھام": 1,
    "الاستقبال": 1,
    "الاستلام": 1,
    "الاستماع": 1,
    "الاستمرار": 1,
    "الاستيداع": 1,
    "الاسد": 1,
    "الاسر": 1,
    "الاسراء": 1,
    "الاسرائيلي": 1,
    "الاسعاف": 1,
    "الاسعتمال": 1,
    "الاسغماية": 1,
    "الاسف": 1,
    "الاسفيني": 1,
    "الاسكا": 1,
    "الاسكندرية": 1,
    "الاسلام": 1,
    "الاسلامى": 1,
    "الاسلامية": 1,
    "الاسلية": 1,
    "الاسماء": 1,
    "الاسماك": 1,
    "الاسنان": 1,
    "الاسود": 1,
    "الاشارات": 1,
    "الاشتراع": 1,
    "الاشتراك": 1,
    "الاشتغال": 1,
    "الاشراقيون": 1,
    "الاشعة": 1,
    "الاصابة": 1,
    "الاصابع": 1,
    "الاصدار": 1,
    "الاصطياف": 1,
    "الاصغر": 1,
    "الاضطرار": 1,
    "الاضلاع": 1,
    "الاطفاء": 1,
    "الاطلانتيك": 1,
    "الاطلانطيك": 1,
    "الاطلنطى": 1,
    "الاطلنطي": 1,
    "الاطوال": 1,
    "الاطيان": 1,
    "الاعتبار": 1,
    "الاعتداء": 1,
    "الاعتداد": 1,
    "الاعتقادي": 1,
    "الاعتناء": 1,
    "الاعجاب": 1,
    "الاعظم": 1,
    "الاعلى": 1,
    "الاعمال": 1,
    "الاعيب": 1,
    "الاغارقة": 1,
    "الاغريق": 1,
    "الاف": 1,
    "الافتتاح": 1,
    "الافرنج": 1,
    "الافرنسية": 1,
    "الافرنسيون": 1,
    "الافغان": 1,
    "الافكار": 1,
    "الافلام": 1,
    "الافوكاتو": 1,
    "الافيون": 1,
    "الاق": 1,
    "الاقاليم": 1,
    "الاقامة": 1,
    "الاقتصاديات": 1,
    "الاقدمون": 1,
    "الاقرباذين": 1,
    "الاقصر": 1,
    "الاقصى": 1,
    "الاقليمية": 1,
    "الاكبر": 1,
    "الاكتراث": 1,
    "الاكزيما": 1,
    "الاكل": 1,
    "الاكمة": 1,
    "الالاھيات": 1,
    "الالب": 1,
    "الالبان": 1,
    "الالتباس": 1,
    "الالتفات": 1,
    "الالتقاط": 1,
    "الالتھاب": 1,
    "الالسن": 1,
    "الالسنة": 1,
    "الالعاب": 1,
    "الالمان": 1,
    "الالمانية": 1,
    "الالوف": 1,
    "الام": 1,
    "الامارة": 1,
    "الامام": 1,
    "الامانات": 1,
    "الامة": 1,
    "الامتحان": 1,
    "الامتياز": 1,
    "الامتيازات": 1,
    "الامد": 1,
    "الامر": 1,
    "الامراء": 1,
    "الامراض": 1,
    "الامريكان": 1,
    "الامكان": 1,
    "الامم": 1,
    "الامن": 1,
    "الامناء": 1,
    "الامواج": 1,
    "الاموال": 1,
    "الاميرية": 1,
    "الامين": 1,
    "الاناة": 1,
    "الاناضل": 1,
    "الانام": 1,
    "الانتخاب": 1,
    "الانتدابات": 1,
    "الانتظام": 1,
    "الانتھاء": 1,
    "الانثيان": 1,
    "الانجليز": 1,
    "الانحطاط": 1,
    "الاندلس": 1,
    "الانساب": 1,
    "الانصھار": 1,
    "الانظار": 1,
    "الانعقاد": 1,
    "الانف": 1,
    "الانفراد": 1,
    "الانفية": 1,
    "الانقاذ": 1,
    "الانقضاض": 1,
    "الانقلاب": 1,
    "الانكليز": 1,
    "الانوق": 1,
    "الاه": 1,
    "الاوائل": 1,
    "الاوامر": 1,
    "الاوان": 1,
    "الاوراس": 1,
    "الاولمبية": 1,
    "الاولون": 1,
    "الاولى": 1,
    "الاى": 1,
    "الايام": 1,
    "الايراد": 1,
    "الايفاء": 1,
    "الايمان": 1,
    "الاين": 1,
    "الايوبيون": 1,
    "الاھبة": 1,
    "الاھة": 1,
    "الاھتزازات": 1,
    "الاھتزازي": 1,
    "الاھتمام": 1,
    "الاھمية": 1,
    "الاھي": 1,
    "الاھية": 1,
    "الب": 1,
    "الباء": 1,
    "الباباوية": 1,
    "البابوي": 1,
    "الباد": 1,
    "البادية": 1,
    "البارح": 1,
    "البارحة": 1,
    "الباردة": 1,
    "البارى": 1,
    "الباشقرد": 1,
    "الباطنة": 1,
    "الباطنية": 1,
    "الباع": 1,
    "الباقيات": 1,
    "الباقية": 1,
    "الباكر": 1,
    "الباكستان": 1,
    "الباليار": 1,
    "البان": 1,
    "البانيا": 1,
    "الباى": 1,
    "البتة": 1,
    "البتول": 1,
    "البحارنة": 1,
    "البحتة": 1,
    "البداھية": 1,
    "البديع": 1,
    "البديھة": 1,
    "البرانس": 1,
    "البربر": 1,
    "البرجاس": 1,
    "البرجيس": 1,
    "البردج": 1,
    "البرديات": 1,
    "البرقة": 1,
    "البروتستو": 1,
    "البرونزى": 1,
    "البرھان": 1,
    "البسة": 1,
    "البسيطة": 1,
    "البشارة": 1,
    "البصرة": 1,
    "البطاطس": 1,
    "البطراء": 1,
    "البعير": 1,
    "البقري": 1,
    "البقلة": 1,
    "البقلية": 1,
    "البكتريا": 1,
    "البلطيق": 1,
    "البلوى": 1,
    "البن": 1,
    "البنجاب": 1,
    "البنفسجى": 1,
    "البوسته": 1,
    "البوسطة": 1,
    "البوسفور": 1,
    "البوع": 1,
    "البوليس": 1,
    "البولينا": 1,
    "البيرنيه": 1,
    "البيضان": 1,
    "البيطرى": 1,
    "البين": 1,
    "البيوتات": 1,
    "التأثري": 1,
    "التأمور": 1,
    "التأنيث": 1,
    "التابنة": 1,
    "التاريخى": 1,
    "التاسعة": 1,
    "التاميز": 1,
    "التباغض": 1,
    "التبانة": 1,
    "التبعة": 1,
    "التتار": 1,
    "التجاء": 1,
    "التجريبى": 1,
    "التجلى": 1,
    "التجھيزات": 1,
    "التحري": 1,
    "التحضيرية": 1,
    "التحق": 1,
    "التحقبالحكومة": 1,
    "التحليف": 1,
    "التخديم": 1,
    "التخطاب": 1,
    "التخييل": 1,
    "التدابير": 1,
    "التدرني": 1,
    "التذاكير": 1,
    "التراويح": 1,
    "الترعة": 1,
    "التركمان": 1,
    "التركية": 1,
    "الترويح": 1,
    "التزاما": 1,
    "التسعيرة": 1,
    "التسليف": 1,
    "التسنيم": 1,
    "التشريد": 1,
    "التشريفات": 1,
    "التشريفة": 1,
    "التشريق": 1,
    "التشريك": 1,
    "التشطيب": 1,
    "التشعع": 1,
    "التصوري": 1,
    "التطيب": 1,
    "التعليمي": 1,
    "التفات": 1,
    "التفاتة": 1,
    "التفاف": 1,
    "التفافية": 1,
    "التفاھم": 1,
    "التفت": 1,
    "التفريخ": 1,
    "التفصيلية": 1,
    "التفضل": 1,
    "التفقيص": 1,
    "التفلسة": 1,
    "التفليسة": 1,
    "التقاط": 1,
    "التقط": 1,
    "التكرير": 1,
    "التكسب": 1,
    "التلبس": 1,
    "التليفونات": 1,
    "التمسته": 1,
    "التناول": 1,
    "التنصيص": 1,
    "التنفيذية": 1,
    "التواءة": 1,
    "التوجيھي": 1,
    "التوجيھية": 1,
    "التياع": 1,
    "التيس": 1,
    "التيفودية": 1,
    "التيفية": 1,
    "التھاب": 1,
    "التھابي": 1,
    "التھذيب": 1,
    "التھمة": 1,
    "الثانى": 1,
    "الثغ": 1,
    "الثكلى": 1,
    "الثلاثى": 1,
    "الثلاثين": 1,
    "الثلثاء": 1,
    "الثمام": 1,
    "الثنايا": 1,
    "الجائيات": 1,
    "الجاثى": 1,
    "الجارى": 1,
    "الجبروتية": 1,
    "الجبين": 1,
    "الجبھة": 1,
    "الجدرى": 1,
    "الجدى": 1,
    "الجديدان": 1,
    "الجذماء": 1,
    "الجراية": 1,
    "الجركس": 1,
    "الجرمان": 1,
    "الجريض": 1,
    "الجزئيات": 1,
    "الجس": 1,
    "الجسمانى": 1,
    "الجفر": 1,
    "الجلالة": 1,
    "الجلة": 1,
    "الجلوة": 1,
    "الجليدى": 1,
    "الجماھير": 1,
    "الجمبازية": 1,
    "الجمة": 1,
    "الجمد": 1,
    "الجمعي": 1,
    "الجمھوري": 1,
    "الجمھورية": 1,
    "الجنا": 1,
    "الجنائي": 1,
    "الجنان": 1,
    "الجنايات": 1,
    "الجنباز": 1,
    "الجنسى": 1,
    "الجنويب": 1,
    "الجوق": 1,
    "الجوى": 1,
    "الجويجوري": 1,
    "الجيوغرافيا": 1,
    "الجيوفيزيائية": 1,
    "الجھات": 1,
    "الجھاز": 1,
    "الجھامة": 1,
    "الجھة": 1,
    "الجھد": 1,
    "الح": 1,
    "الحابل": 1,
    "الحاجبين": 1,
    "الحاح": 1,
    "الحاخام": 1,
    "الحارث": 1,
    "الحاش": 1,
    "الحاصمة": 1,
    "الحاظ": 1,
    "الحاف": 1,
    "الحاق": 1,
    "الحالي": 1,
    "الحان": 1,
    "الحبالة": 1,
    "الحبش": 1,
    "الحبلين": 1,
    "الحتد": 1,
    "الحجاز": 1,
    "الحجال": 1,
    "الحجلة": 1,
    "الحديدة": 1,
    "الحذق": 1,
    "الحربى": 1,
    "الحربي": 1,
    "الحرفان": 1,
    "الحرمة": 1,
    "الحرمين": 1,
    "الحروب": 1,
    "الحساس": 1,
    "الحسان": 1,
    "الحسبان": 1,
    "الحسرات": 1,
    "الحسنيون": 1,
    "الحشا": 1,
    "الحصرحصر": 1,
    "الحصين": 1,
    "الحضرة": 1,
    "الحفة": 1,
    "الحقانية": 1,
    "الحقة": 1,
    "الحقوقية": 1,
    "الحكر": 1,
    "الحكمي": 1,
    "الحكومية": 1,
    "الحلوب": 1,
    "الحلوة": 1,
    "الحمسين": 1,
    "الحمقاء": 1,
    "الحنيف": 1,
    "الحنيفة": 1,
    "الحنيفية": 1,
    "الحواني": 1,
    "الحى": 1,
    "الحياء": 1,
    "الحيات": 1,
    "الحيثيات": 1,
    "الحيثية": 1,
    "الحيثيون": 1,
    "الحھشية": 1,
    "الخاتون": 1,
    "الخارجة": 1,
    "الخاسرة": 1,
    "الخاص": 1,
    "الخاطر": 1,
    "الخافقين": 1,
    "الخالدات": 1,
    "الخالي": 1,
    "الخبر": 1,
    "الختامي": 1,
    "الخروع": 1,
    "الخريجين": 1,
    "الخزفي": 1,
    "الخشناء": 1,
    "الخض": 1,
    "الخضيب": 1,
    "الخضيراء": 1,
    "الخطاطيف": 1,
    "الخطل": 1,
    "الخطوب": 1,
    "الخطى": 1,
    "الخفض": 1,
    "الخلاء": 1,
    "الخلاص": 1,
    "الخلفاء": 1,
    "الخلقة": 1,
    "الخلول": 1,
    "الخليفية": 1,
    "الخليل": 1,
    "الخليلى": 1,
    "الخم": 1,
    "الخمسين": 1,
    "الخوافق": 1,
    "الخوافى": 1,
    "الخوالي": 1,
    "الخوص": 1,
    "الد": 1,
    "الدابر": 1,
    "الداخلة": 1,
    "الدارجة": 1,
    "الداما": 1,
    "الداننوب": 1,
    "الداھرين": 1,
    "الدخلة": 1,
    "الدردنيل": 1,
    "الدروز": 1,
    "الدريسة": 1,
    "الدستورى": 1,
    "الدستوري": 1,
    "الدفتان": 1,
    "الدقيقي": 1,
    "الدلاء": 1,
    "الدلع": 1,
    "الدمام": 1,
    "الدملي": 1,
    "الدوا": 1,
    "الدولى": 1,
    "الديان": 1,
    "الديانات": 1,
    "الديجوليون": 1,
    "الديدبان": 1,
    "الديكي": 1,
    "الديموقراطية": 1,
    "الدينار": 1,
    "الدھاليز": 1,
    "الدھر": 1,
    "الدھشة": 1,
    "الدھليز": 1,
    "الدھماء": 1,
    "الدھور": 1,
    "الذبحة": 1,
    "الذلق": 1,
    "الذلقية": 1,
    "الذمة": 1,
    "الذوات": 1,
    "الذوللقية": 1,
    "الذى": 1,
    "الذيوع": 1,
    "الذھاب": 1,
    "الذھب": 1,
    "الذھبي": 1,
    "الذھبية": 1,
    "الذھن": 1,
    "الرأى": 1,
    "الراجعة": 1,
    "الراحل": 1,
    "الرازق": 1,
    "الراس": 1,
    "الراعى": 1,
    "الرافدان": 1,
    "الراقع": 1,
    "الراھنة": 1,
    "الرب": 1,
    "الربانيات": 1,
    "الرجاء": 1,
    "الرجراج": 1,
    "الرحب": 1,
    "الرخوة": 1,
    "الردفان": 1,
    "الردھة": 1,
    "الرسولي": 1,
    "الرسولية": 1,
    "الرضاع": 1,
    "الرعشي": 1,
    "الرعيان": 1,
    "الرعية": 1,
    "الرفعة": 1,
    "الرفق": 1,
    "الرقي": 1,
    "الركائب": 1,
    "الركبان": 1,
    "الركة": 1,
    "الرمضاء": 1,
    "الرمق": 1,
    "الروبابيكيا": 1,
    "الروك": 1,
    "الرياسة": 1,
    "الريحاني": 1,
    "الريحة": 1,
    "الريع": 1,
    "الريق": 1,
    "الرھاء": 1,
    "الرھان": 1,
    "الرھن": 1,
    "الزاس": 1,
    "الزام": 1,
    "الزامي": 1,
    "الزباد": 1,
    "الزبى": 1,
    "الزحلقة": 1,
    "الزفر": 1,
    "الزفرات": 1,
    "الزقازق": 1,
    "الزلط": 1,
    "الزلل": 1,
    "الزمان": 1,
    "الزمه": 1,
    "الزنجير": 1,
    "الزياتين": 1,
    "الزيتونة": 1,
    "الزيتونية": 1,
    "الزيدية": 1,
    "الزيود": 1,
    "الزھد": 1,
    "الزھر": 1,
    "الزھرة": 1,
    "الس": 1,
    "السآمة": 1,
    "السائحين": 1,
    "السائرة": 1,
    "السابعة": 1,
    "السابلة": 1,
    "السات": 1,
    "السادسة": 1,
    "الساسانيون": 1,
    "السالم": 1,
    "السامة": 1,
    "السامرة": 1,
    "السامي": 1,
    "السباتي": 1,
    "السبعينية": 1,
    "السبوت": 1,
    "السبوعات": 1,
    "الست": 1,
    "الستار": 1,
    "السجار": 1,
    "السجدة": 1,
    "السحائي": 1,
    "السخرة": 1,
    "السدة": 1,
    "السراية": 1,
    "السرطانية": 1,
    "السرور": 1,
    "السرى": 1,
    "السريرة": 1,
    "السعدية": 1,
    "السعديون": 1,
    "السعف": 1,
    "السعى": 1,
    "السفريات": 1,
    "السفرية": 1,
    "السكت": 1,
    "السكرى": 1,
    "السلاجقة": 1,
    "السلاق": 1,
    "السلطان": 1,
    "السلفية": 1,
    "السماحة": 1,
    "السماكان": 1,
    "السماكين": 1,
    "السمحاء": 1,
    "السمل": 1,
    "السمھورية": 1,
    "السنتھم": 1,
    "السنسكريتية": 1,
    "السنفرة": 1,
    "السنية": 1,
    "السواء": 1,
    "السوفياتي": 1,
    "السوفيت": 1,
    "السيآت": 1,
    "السيار": 1,
    "السيكارة": 1,
    "السھرة": 1,
    "السھل": 1,
    "السھم": 1,
    "الشأر": 1,
    "الشأو": 1,
    "الشؤون": 1,
    "الشائع": 1,
    "الشادنة": 1,
    "الشافععي": 1,
    "الشام": 1,
    "الشاھدة": 1,
    "الشبيبة": 1,
    "الشبھات": 1,
    "الشبھة": 1,
    "الشحائح": 1,
    "الشرابين": 1,
    "الشراة": 1,
    "الشرعي": 1,
    "الشروية": 1,
    "الشريانية": 1,
    "الشريفة": 1,
    "الشرين": 1,
    "الششخانة": 1,
    "الشطط": 1,
    "الشعائر": 1,
    "الشعانين": 1,
    "الشعوب": 1,
    "الشعوبية": 1,
    "الشعوري": 1,
    "الشفاھي": 1,
    "الشفه": 1,
    "الشفوية": 1,
    "الشفھي": 1,
    "الشفھية": 1,
    "الشكيمة": 1,
    "الشماسمة": 1,
    "الشوال": 1,
    "الشورى": 1,
    "الشوف": 1,
    "الشي": 1,
    "الشيب": 1,
    "الشيشة": 1,
    "الشھباء": 1,
    "الشھر": 1,
    "الشھوة": 1,
    "الشھود": 1,
    "الشھية": 1,
    "الصابئة": 1,
    "الصابغ": 1,
    "الصاص": 1,
    "الصاع": 1,
    "الصاق": 1,
    "الصحابة": 1,
    "الصدد": 1,
    "الصدرعجز": 1,
    "الصدغ": 1,
    "الصرب": 1,
    "الصرة": 1,
    "الصعداء": 1,
    "الصعيد": 1,
    "الصفرة": 1,
    "الصفو": 1,
    "الصق": 1,
    "الصنائع": 1,
    "الصولة": 1,
    "الصون": 1,
    "الصھباء": 1,
    "الضاد": 1,
    "الضارية": 1,
    "الضخمة": 1,
    "الضد": 1,
    "الضررين": 1,
    "الضروري": 1,
    "الضوارب": 1,
    "الطائف": 1,
    "الطاف": 1,
    "الطبيعيات": 1,
    "الطحرورية": 1,
    "الطخرورية": 1,
    "الطريدان": 1,
    "الطف": 1,
    "الطفلي": 1,
    "الطقسيات": 1,
    "الطلقاء": 1,
    "الطليان": 1,
    "الطم": 1,
    "الطوائح": 1,
    "الطولى": 1,
    "الطونة": 1,
    "الطوى": 1,
    "الطويل": 1,
    "الطيارة": 1,
    "الظاھر": 1,
    "الظواھر": 1,
    "الظھر": 1,
    "الظھران": 1,
    "الظھور": 1,
    "العاتب": 1,
    "العاجل": 1,
    "العادمة": 1,
    "العارضان": 1,
    "العارضين": 1,
    "العاشرة": 1,
    "العاقب": 1,
    "العال": 1,
    "العالمان": 1,
    "العالى": 1,
    "العامر": 1,
    "العباس": 1,
    "العبراني": 1,
    "العبرانية": 1,
    "العبرة": 1,
    "العجب": 1,
    "العجم": 1,
    "العدلية": 1,
    "العذار": 1,
    "العذري": 1,
    "العذل": 1,
    "العراقان": 1,
    "العراقية": 1,
    "العربان": 1,
    "العرس": 1,
    "العروسان": 1,
    "العريكة": 1,
    "العزوة": 1,
    "العزى": 1,
    "العس": 1,
    "العسة": 1,
    "العشائري": 1,
    "العصفر": 1,
    "العطب": 1,
    "العطلات": 1,
    "العطن": 1,
    "العطوفة": 1,
    "العفاء": 1,
    "العفش": 1,
    "العفنة": 1,
    "العقليات": 1,
    "العكر": 1,
    "العلائق": 1,
    "العلات": 1,
    "العلقم": 1,
    "العلل": 1,
    "العلمي": 1,
    "العلويون": 1,
    "العلية": 1,
    "العمارة": 1,
    "العمدي": 1,
    "العمرين": 1,
    "العملي": 1,
    "العميان": 1,
    "العنان": 1,
    "العوبة": 1,
    "العورة": 1,
    "العياذ": 1,
    "العينين": 1,
    "العھد": 1,
    "الغاب": 1,
    "الغابر": 1,
    "الغابرة": 1,
    "الغاد": 1,
    "الغارب": 1,
    "الغازى": 1,
    "الغاط": 1,
    "الغال": 1,
    "الغالى": 1,
    "الغام": 1,
    "الغاية": 1,
    "الغب": 1,
    "الغبراء": 1,
    "الغداة": 1,
    "الغدو": 1,
    "الغراء": 1,
    "الغرار": 1,
    "الغربين": 1,
    "الغرل": 1,
    "الغشائي": 1,
    "الغشوم": 1,
    "الغصن": 1,
    "الغضى": 1,
    "الغور": 1,
    "الغوطة": 1,
    "الغيار": 1,
    "الغيد": 1,
    "الغھد": 1,
    "الفأل": 1,
    "الفؤادية": 1,
    "الفائت": 1,
    "الفارط": 1,
    "الفارق": 1,
    "الفاروق": 1,
    "الفاطر": 1,
    "الفاطميون": 1,
    "الفاظ": 1,
    "الفاظه": 1,
    "الفاف": 1,
    "الفت": 1,
    "الفتلة": 1,
    "الفحمية": 1,
    "الفحول": 1,
    "الفخرية": 1,
    "الفرائض": 1,
    "الفراغية": 1,
    "الفرقدان": 1,
    "الفرنسيس": 1,
    "الفرنسيسكان": 1,
    "الفسطاط": 1,
    "الفصحى": 1,
    "الفضائل": 1,
    "الفطحل": 1,
    "الفقه": 1,
    "الفقيد": 1,
    "الفكاھة": 1,
    "الفلاحية": 1,
    "الفلانية": 1,
    "الفليبين": 1,
    "الفه": 1,
    "الفوات": 1,
    "الفور": 1,
    "الفى": 1,
    "الفي": 1,
    "الفيحاء": 1,
    "الفيلاريا": 1,
    "الفيلبين": 1,
    "الفينة": 1,
    "الفيوم": 1,
    "الفھم": 1,
    "الق": 1,
    "القاب": 1,
    "القاصى": 1,
    "القاصية": 1,
    "القاضمة": 1,
    "القاف": 1,
    "القالة": 1,
    "القاھر": 1,
    "القاھرة": 1,
    "القبط": 1,
    "القبلتين": 1,
    "القبلي": 1,
    "القبيل": 1,
    "القتاد": 1,
    "القدرية": 1,
    "القذة": 1,
    "القراع": 1,
    "القران": 1,
    "القرفصاء": 1,
    "القرمز": 1,
    "القرمزية": 1,
    "القرويين": 1,
    "القريتان": 1,
    "القريم": 1,
    "القزر": 1,
    "القساطلة": 1,
    "القصد": 1,
    "القصصي": 1,
    "القصيد": 1,
    "القطاعى": 1,
    "القطوف": 1,
    "القعد": 1,
    "القفقاس": 1,
    "القلاعية": 1,
    "القلزم": 1,
    "القمران": 1,
    "القمرقية": 1,
    "القمرية": 1,
    "القنطرة": 1,
    "القوا": 1,
    "القوت": 1,
    "القوط": 1,
    "القوقاس": 1,
    "القويم": 1,
    "القى": 1,
    "القيء": 1,
    "القياد": 1,
    "القية": 1,
    "القيروان": 1,
    "القيودات": 1,
    "القھار": 1,
    "القھقرى": 1,
    "القھوة": 1,
    "الك": 1,
    "الكاب": 1,
    "الكار": 1,
    "الكارات": 1,
    "الكاك": 1,
    "الكان": 1,
    "الكانتو": 1,
    "الكتمان": 1,
    "الكثلكة": 1,
    "الكثيرون": 1,
    "الكر": 1,
    "الكرازة": 1,
    "الكراكي": 1,
    "الكرام": 1,
    "الكرج": 1,
    "الكرد": 1,
    "الكرمل": 1,
    "الكرنن": 1,
    "الكرى": 1,
    "الكريمان": 1,
    "الكريمتان": 1,
    "الكزازي": 1,
    "الكسبياتي": 1,
    "الكعبرة": 1,
    "الكلدان": 1,
    "الكمبيالات": 1,
    "الكمرك": 1,
    "الكن": 1,
    "الكنانة": 1,
    "الكناية": 1,
    "الكنج": 1,
    "الكنسة": 1,
    "الكنيشة": 1,
    "الكوبون": 1,
    "الكوفة": 1,
    "الكوفي": 1,
    "الكوفيون": 1,
    "الكي": 1,
    "الكيف": 1,
    "الكينا": 1,
    "الكھرباء": 1,
    "الكھربائي": 1,
    "الكھربائية": 1,
    "الكھف": 1,
    "الكھنة": 1,
    "الكھنوت": 1,
    "الكھنوتية": 1,
    "الكھيربي": 1,
    "الل": 1,
    "اللأجنبية": 1,
    "اللائق": 1,
    "اللائى": 1,
    "اللاتى": 1,
    "اللاتين": 1,
    "اللاتيني": 1,
    "اللاجئين": 1,
    "اللاذقية": 1,
    "اللاسامية": 1,
    "اللالتھاب": 1,
    "اللانھاية": 1,
    "اللاوي": 1,
    "اللاھوت": 1,
    "اللاھوتية": 1,
    "اللثوية": 1,
    "اللحد": 1,
    "اللد": 1,
    "اللدني": 1,
    "اللدنية": 1,
    "اللطمات": 1,
    "اللعين": 1,
    "اللفيف": 1,
    "اللواتى": 1,
    "اللواقح": 1,
    "اللوردين": 1,
    "اللوزتان": 1,
    "اللوندا": 1,
    "اللھب": 1,
    "اللھجة": 1,
    "اللھم": 1,
    "اللھو": 1,
    "اللھويتان": 1,
    "اللھيب": 1,
    "المآل": 1,
    "المؤاخذة": 1,
    "المؤلمة": 1,
    "المئة": 1,
    "المائة": 1,
    "الماديات": 1,
    "المار": 1,
    "المارشالية": 1,
    "الماز": 1,
    "الماضى": 1,
    "الماعة": 1,
    "الماكوك": 1,
    "المالكية": 1,
    "المام": 1,
    "المان": 1,
    "المانش": 1,
    "الماني": 1,
    "المانيا": 1,
    "الماھية": 1,
    "المبكى": 1,
    "المبھم": 1,
    "المتأخرون": 1,
    "المتاع": 1,
    "المتاعب": 1,
    "المتجمدة": 1,
    "المتحكم": 1,
    "المتحولة": 1,
    "المتخلفة": 1,
    "المتداول": 1,
    "المتساوى": 1,
    "المتصوفة": 1,
    "المتعاقدة": 1,
    "المتعالي": 1,
    "المتعاھدان": 1,
    "المتقارب": 1,
    "المتقاضيان": 1,
    "المتقشفة": 1,
    "المتناوب": 1,
    "المتوكلية": 1,
    "المجاذيب": 1,
    "المجر": 1,
    "المجن": 1,
    "المجيء": 1,
    "المجھر": 1,
    "المجھول": 1,
    "المحافل": 1,
    "المحاماة": 1,
    "المحبس": 1,
    "المحتفلون": 1,
    "المحجة": 1,
    "المحرسون": 1,
    "المحز": 1,
    "المحسوس": 1,
    "المحسوسات": 1,
    "المحصولات": 1,
    "المحظورات": 1,
    "المحلة": 1,
    "المخا": 1,
    "المخاطئ": 1,
    "المختصون": 1,
    "المختلطة": 1,
    "المخجلة": 1,
    "المخى": 1,
    "المخية": 1,
    "المدارك": 1,
    "المدر": 1,
    "المدعى": 1,
    "المديد": 1,
    "المذاھب": 1,
    "المذھب": 1,
    "المرارية": 1,
    "المرافعات": 1,
    "المربوط": 1,
    "المرتبة": 1,
    "المرجو": 1,
    "المرحوم": 1,
    "المرصاد": 1,
    "المرصع": 1,
    "المرفع": 1,
    "المرقسية": 1,
    "المركم": 1,
    "المريء": 1,
    "المزاحمة": 1,
    "المزركش": 1,
    "المزمع": 1,
    "المسألة": 1,
    "المسائية": 1,
    "المساعى": 1,
    "المساكين": 1,
    "المسالك": 1,
    "المساھمة": 1,
    "المستحية": 1,
    "المستطاع": 1,
    "المستظرفة": 1,
    "المستعجلة": 1,
    "المستقلة": 1,
    "المسجدان": 1,
    "المسطحات": 1,
    "المسكوكات": 1,
    "المسكونة": 1,
    "المسلسلة": 1,
    "المسماري": 1,
    "المسوح": 1,
    "المش": 1,
    "المشار": 1,
    "المشاعيل": 1,
    "المشترى": 1,
    "المشرقان": 1,
    "المشرقيات": 1,
    "المشوار": 1,
    "المشھد": 1,
    "المشھود": 1,
    "المشھور": 1,
    "المصادفات": 1,
    "المصاريف": 1,
    "المصاعب": 1,
    "المصراع": 1,
    "المصرية": 1,
    "المصطفى": 1,
    "المصطكاء": 1,
    "المصوغات": 1,
    "المصونة": 1,
    "المضجع": 1,
    "المضي": 1,
    "المطابع": 1,
    "المطاف": 1,
    "المطاوع": 1,
    "المطبعة": 1,
    "المطبقة": 1,
    "المطران": 1,
    "المطمورات": 1,
    "المظھر": 1,
    "المع": 1,
    "المعاد": 1,
    "المعاري": 1,
    "المعالي": 1,
    "المعاميل": 1,
    "المعانى": 1,
    "المعايدة": 1,
    "المعاھدة": 1,
    "المعتدلتان": 1,
    "المعتمد": 1,
    "المعدنيات": 1,
    "المعراج": 1,
    "المعقود": 1,
    "المعلول": 1,
    "المعلى": 1,
    "المعمدان": 1,
    "المعمودية": 1,
    "المعمورة": 1,
    "المعى": 1,
    "المعية": 1,
    "المعيش": 1,
    "المعھد": 1,
    "المعھود": 1,
    "المغازى": 1,
    "المغبون": 1,
    "المغطس": 1,
    "المغفور": 1,
    "المغنة": 1,
    "المغنطيسية": 1,
    "المغنى": 1,
    "المغول": 1,
    "المغيبات": 1,
    "المفالي": 1,
    "المفتي": 1,
    "المفخمة": 1,
    "المفرزات": 1,
    "المفقودة": 1,
    "المفھوم": 1,
    "المقاصد": 1,
    "المقام": 1,
    "المقتضب": 1,
    "المقتل": 1,
    "المقررة": 1,
    "المقضي": 1,
    "المقطم": 1,
    "المقول": 1,
    "المقولات": 1,
    "المقيئ": 1,
    "المقيت": 1,
    "المقيدة": 1,
    "المكاسر": 1,
    "المكرمية": 1,
    "المكسر": 1,
    "المكشوف": 1,
    "المكن": 1,
    "المكى": 1,
    "الملاعنة": 1,
    "الملايو": 1,
    "الملاھى": 1,
    "الملاھي": 1,
    "الملكى": 1,
    "الملوان": 1,
    "الملوكي": 1,
    "الممناصب": 1,
    "المناداة": 1,
    "المناقصة": 1,
    "المنامة": 1,
    "المنان": 1,
    "المنة": 1,
    "المنتقلة": 1,
    "المنتوجات": 1,
    "المنتھى": 1,
    "المنجمد": 1,
    "المندب": 1,
    "المندل": 1,
    "المنسرح": 1,
    "المنش": 1,
    "المنصرف": 1,
    "المنصورة": 1,
    "المنطقتان": 1,
    "المنفرجة": 1,
    "المنورة": 1,
    "المنيا": 1,
    "الموات": 1,
    "المواسم": 1,
    "الموحدون": 1,
    "المودعة": 1,
    "المورة": 1,
    "الموصى": 1,
    "الموطن": 1,
    "الموفد": 1,
    "الموفي": 1,
    "الموقوفة": 1,
    "المولدون": 1,
    "المومأ": 1,
    "الميري": 1,
    "الميمون": 1,
    "المھاجرون": 1,
    "المھجع": 1,
    "المھد": 1,
    "المھملات": 1,
    "المھنة": 1,
    "المھني": 1,
    "النابذة": 1,
    "الناطعة": 1,
    "النافعة": 1,
    "الناموس": 1,
    "الناھى": 1,
    "النبت": 1,
    "النبط": 1,
    "النبوي": 1,
    "النجاد": 1,
    "النجدة": 1,
    "النجسة": 1,
    "النجف": 1,
    "النحر": 1,
    "النحيزة": 1,
    "الندف": 1,
    "الندوة": 1,
    "النسا": 1,
    "النسق": 1,
    "النسوية": 1,
    "النشء": 1,
    "النشوئيون": 1,
    "النشيط": 1,
    "النصيرية": 1,
    "النعائم": 1,
    "النعماء": 1,
    "النفاثي": 1,
    "النفاس": 1,
    "النفاسية": 1,
    "النفود": 1,
    "النقاھة": 1,
    "النقب": 1,
    "النقطتان": 1,
    "النكفية": 1,
    "النكير": 1,
    "النمشية": 1,
    "النميات": 1,
    "النوازل": 1,
    "النوامي": 1,
    "النوعى": 1,
    "النيابات": 1,
    "النيابة": 1,
    "النھائي": 1,
    "النھار": 1,
    "النھاية": 1,
    "النھج": 1,
    "النھر": 1,
    "النھرين": 1,
    "النھضة": 1,
    "النھي": 1,
    "الواء": 1,
    "الواج": 1,
    "الواحى": 1,
    "الواعي": 1,
    "الوافر": 1,
    "الواقى": 1,
    "الوان": 1,
    "الوانه": 1,
    "الوبر": 1,
    "الوتيرة": 1,
    "الوجاھة": 1,
    "الوجھاء": 1,
    "الوجھة": 1,
    "الورقات": 1,
    "الورى": 1,
    "الوساد": 1,
    "الوضعية": 1,
    "الوطئة": 1,
    "الوطاب": 1,
    "الوطيس": 1,
    "الوف": 1,
    "الوفاض": 1,
    "الوقائع": 1,
    "الوقائي": 1,
    "الومنيا": 1,
    "الومنيوم": 1,
    "الوندل": 1,
    "الونش": 1,
    "الوى": 1,
    "الويات": 1,
    "الوية": 1,
    "الوھابية": 1,
    "الوھلة": 1,
    "الوھية": 1,
    "الي": 1,
    "الياذة": 1,
    "الياف": 1,
    "اليبس": 1,
    "الية": 1,
    "اليدويون": 1,
    "اليرموك": 1,
    "اليزيدية": 1,
    "اليس": 1,
    "اليسير": 1,
    "اليف": 1,
    "اليق": 1,
    "اليقظان": 1,
    "اليك": 1,
    "اليكون": 1,
    "اليمنية": 1,
    "اليمينيون": 1,
    "الينا": 1,
    "اليناء": 1,
    "اليونسكو": 1,
    "اليھا": 1,
    "اليھم": 1,
    "اليھود": 1,
    "الھاب": 1,
    "الھاج": 1,
    "الھاجرة": 1,
    "الھادئ": 1,
    "الھاشمية": 1,
    "الھافر": 1,
    "الھال": 1,
    "الھام": 1,
    "الھان": 1,
    "الھاون": 1,
    "الھاوية": 1,
    "الھبة": 1,
    "الھتاف": 1,
    "الھج": 1,
    "الھجرة": 1,
    "الھجوم": 1,
    "الھدوء": 1,
    "الھر": 1,
    "الھزات": 1,
    "الھزيمة": 1,
    "الھضم": 1,
    "الھفوف": 1,
    "الھلاك": 1,
    "الھلال": 1,
    "الھم": 1,
    "الھمة": 1,
    "الھموم": 1,
    "الھند": 1,
    "الھندسة": 1,
    "الھندسية": 1,
    "الھندي": 1,
    "الھندية": 1,
    "الھنود": 1,
    "الھواء": 1,
    "الھوائي": 1,
    "الھوارة": 1,
    "الھود": 1,
    "الھول": 1,
    "الھوى": 1,
    "الھوية": 1,
    "الھوينا": 1,
    "الھي": 1,
    "الھيئات": 1,
    "الھيئة": 1,
    "الھيل": 1,
    "اما": 1,
    "اماء": 1,
    "امائر": 1,
    "امابير": 1,
    "اماتة": 1,
    "اماثل": 1,
    "اماثيل": 1,
    "اماجد": 1,
    "اماديح": 1,
    "امار": 1,
    "امارات": 1,
    "امارة": 1,
    "اماسى": 1,
    "اماسي": 1,
    "اماط": 1,
    "اماعة": 1,
    "اماكن": 1,
    "امالة": 1,
    "اماليح": 1,
    "اماليد": 1,
    "امام": 1,
    "امامة": 1,
    "امامه": 1,
    "امامى": 1,
    "امامية": 1,
    "امان": 1,
    "امانة": 1,
    "اماني": 1,
    "امبراطور": 1,
    "امبراطوري": 1,
    "امبراطورية": 1,
    "امبير": 1,
    "امبيق": 1,
    "امة": 1,
    "امت": 1,
    "امتار": 1,
    "امتاع": 1,
    "امتثال": 1,
    "امتحانا": 1,
    "امتزاج": 1,
    "امتشاق": 1,
    "امتع": 1,
    "امتعاض": 1,
    "امتعة": 1,
    "امتلاء": 1,
    "امتلك": 1,
    "امتناع": 1,
    "امتنان": 1,
    "امتھان": 1,
    "امثال": 1,
    "امثاله": 1,
    "امثل": 1,
    "امثلة": 1,
    "امثولة": 1,
    "امجاد": 1,
    "امجد": 1,
    "امحاء": 1,
    "امحق": 1,
    "امحوضة": 1,
    "امحى": 1,
    "امخال": 1,
    "امد": 1,
    "امداد": 1,
    "امدة": 1,
    "امدوحة": 1,
    "امر": 1,
    "امرء": 1,
    "امرئة": 1,
    "امرا": 1,
    "امراء": 1,
    "امرار": 1,
    "امراس": 1,
    "امراض": 1,
    "امراع": 1,
    "امرة": 1,
    "امرته": 1,
    "امرر": 1,
    "امرط": 1,
    "امرع": 1,
    "امرك": 1,
    "امرلس": 1,
    "امره": 1,
    "امري": 1,
    "امريقا": 1,
    "امريكا": 1,
    "امريكاني": 1,
    "امرين": 1,
    "امرھا": 1,
    "امرھم": 1,
    "امزجة": 1,
    "امس": 1,
    "امسا": 1,
    "امساء": 1,
    "امساد": 1,
    "امساك": 1,
    "امساكية": 1,
    "امستردام": 1,
    "امسك": 1,
    "امسكت": 1,
    "امسى": 1,
    "امسيات": 1,
    "امسية": 1,
    "امشاق": 1,
    "امشير": 1,
    "امصار": 1,
    "امصرة": 1,
    "امضاء": 1,
    "امضى": 1,
    "امضي": 1,
    "امطار": 1,
    "امطر": 1,
    "امطره": 1,
    "امع": 1,
    "امعاء": 1,
    "امعان": 1,
    "امعة": 1,
    "امعز": 1,
    "امعط": 1,
    "امعن": 1,
    "امعية": 1,
    "امغر": 1,
    "امكان": 1,
    "امكانه": 1,
    "امكانية": 1,
    "امكن": 1,
    "امكنة": 1,
    "امل": 1,
    "املا": 1,
    "املاء": 1,
    "املاح": 1,
    "املاط": 1,
    "املاك": 1,
    "املد": 1,
    "املط": 1,
    "املق": 1,
    "امله": 1,
    "املوحة": 1,
    "املى": 1,
    "امم": 1,
    "اممي": 1,
    "امن": 1,
    "امناء": 1,
    "امنان": 1,
    "امنع": 1,
    "امنيبوس": 1,
    "امنية": 1,
    "اموات": 1,
    "امواج": 1,
    "امواس": 1,
    "اموال": 1,
    "امواه": 1,
    "امور": 1,
    "اموره": 1,
    "امومة": 1,
    "اموى": 1,
    "اموي": 1,
    "امي": 1,
    "اميار": 1,
    "امياس": 1,
    "اميال": 1,
    "اميبا": 1,
    "امية": 1,
    "امير": 1,
    "اميرال": 1,
    "اميرالية": 1,
    "اميرة": 1,
    "اميركا": 1,
    "اميركه": 1,
    "اميري": 1,
    "اميرية": 1,
    "اميز": 1,
    "اميل": 1,
    "امين": 1,
    "امھات": 1,
    "امھار": 1,
    "امھال": 1,
    "امھالي": 1,
    "انآء": 1,
    "انأى": 1,
    "اناء": 1,
    "اناب": 1,
    "انابات": 1,
    "انابة": 1,
    "انابر": 1,
    "انابيب": 1,
    "انابير": 1,
    "انابيش": 1,
    "انابيق": 1,
    "اناة": 1,
    "انات": 1,
    "اناث": 1,
    "اناثى": 1,
    "اناجيل": 1,
    "اناخ": 1,
    "انادر": 1,
    "انار": 1,
    "انارة": 1,
    "اناس": 1,
    "اناسب": 1,
    "اناسى": 1,
    "اناشيد": 1,
    "اناشيط": 1,
    "اناطه": 1,
    "اناقة": 1,
    "انامل": 1,
    "اناناس": 1,
    "اناني": 1,
    "انانية": 1,
    "اناييب": 1,
    "انب": 1,
    "انبا": 1,
    "انباء": 1,
    "انباذ": 1,
    "انبار": 1,
    "انباز": 1,
    "انباشى": 1,
    "انباشي": 1,
    "انباض": 1,
    "انبال": 1,
    "انبت": 1,
    "انبثاق": 1,
    "انبذة": 1,
    "انبساط": 1,
    "انبلاج": 1,
    "انبوبة": 1,
    "انبوبي": 1,
    "انبوش": 1,
    "انبوشة": 1,
    "انبياء": 1,
    "انبيق": 1,
    "انة": 1,
    "انت": 1,
    "انتاجية": 1,
    "انتباھه": 1,
    "انتبذ": 1,
    "انتجاب": 1,
    "انتحار": 1,
    "انتحال": 1,
    "انتحر": 1,
    "انتحل": 1,
    "انتحى": 1,
    "انتخابي": 1,
    "انتخابية": 1,
    "انتداب": 1,
    "انتدابي": 1,
    "انتدابية": 1,
    "انتذا": 1,
    "انتزاع": 1,
    "انتساب": 1,
    "انتشاء": 1,
    "انتشار": 1,
    "انتشق": 1,
    "انتصاب": 1,
    "انتصارا": 1,
    "انتصب": 1,
    "انتظار": 1,
    "انتظاره": 1,
    "انتظام": 1,
    "انتظم": 1,
    "انتظو": 1,
    "انتعاش": 1,
    "انتفاض": 1,
    "انتفاضة": 1,
    "انتفاع": 1,
    "انتفخت": 1,
    "انتقاد": 1,
    "انتقاص": 1,
    "انتقاض": 1,
    "انتقالي": 1,
    "انتقالية": 1,
    "انتقص": 1,
    "انتقل": 1,
    "انتكاس": 1,
    "انتم": 1,
    "انتما": 1,
    "انتماء": 1,
    "انتمون": 1,
    "انتن": 1,
    "انتيكة": 1,
    "انتيكخانة": 1,
    "انتھاء": 1,
    "انتھار": 1,
    "انتھاز": 1,
    "انتھازية": 1,
    "انتھاك": 1,
    "انتھب": 1,
    "انتھز": 1,
    "انتھى": 1,
    "انث": 1,
    "انثروبولوجيا": 1,
    "انثناء": 1,
    "انثناءة": 1,
    "انثوى": 1,
    "انثى": 1,
    "انجاب": 1,
    "انجاح": 1,
    "انجاز": 1,
    "انجاس": 1,
    "انجاص": 1,
    "انجال": 1,
    "انجب": 1,
    "انجذاب": 1,
    "انجر": 1,
    "انجرارية": 1,
    "انجل": 1,
    "انجليزى": 1,
    "انجم": 1,
    "انجماد": 1,
    "انجية": 1,
    "انجيل": 1,
    "انجيلى": 1,
    "انجيلية": 1,
    "انحاء": 1,
    "انحباس": 1,
    "انحدار": 1,
    "انحصار": 1,
    "انحطاط": 1,
    "انحطاطي": 1,
    "انحناء": 1,
    "انحناءة": 1,
    "انحياز": 1,
    "انخاخ": 1,
    "انخذال": 1,
    "انخراط": 1,
    "انخرام": 1,
    "انخرط": 1,
    "انخلع": 1,
    "انداء": 1,
    "انداب": 1,
    "انداد": 1,
    "اندحار": 1,
    "اندر": 1,
    "اندفاعا": 1,
    "اندفاعة": 1,
    "اندق": 1,
    "اندمار": 1,
    "اندونيسيا": 1,
    "اندية": 1,
    "اندھاش": 1,
    "انذار": 1,
    "انذال": 1,
    "انذر": 1,
    "انذره": 1,
    "انزاح": 1,
    "انزال": 1,
    "انزعاج": 1,
    "انزل": 1,
    "انزلاق": 1,
    "انزله": 1,
    "انزلوھم": 1,
    "انزواء": 1,
    "انزوى": 1,
    "انس": 1,
    "انساج": 1,
    "انساق": 1,
    "انسال": 1,
    "انسام": 1,
    "انسانة": 1,
    "انسانى": 1,
    "انسانية": 1,
    "انسب": 1,
    "انسباء": 1,
    "انسجام": 1,
    "انسجة": 1,
    "انسحاب": 1,
    "انسحاق": 1,
    "انسرح": 1,
    "انسرى": 1,
    "انسلال": 1,
    "انسى": 1,
    "انسي": 1,
    "انسيابي": 1,
    "انش": 1,
    "انشاءات": 1,
    "انشائي": 1,
    "انشائية": 1,
    "انشاد": 1,
    "انشاز": 1,
    "انشراح": 1,
    "انشرح": 1,
    "انشطار": 1,
    "انشعاب": 1,
    "انشغف": 1,
    "انشقاق": 1,
    "انشوجة": 1,
    "انشودة": 1,
    "انشوطة": 1,
    "انصاب": 1,
    "انصار": 1,
    "انصارا": 1,
    "انصاف": 1,
    "انصباء": 1,
    "انصبة": 1,
    "انصراف": 1,
    "انصل": 1,
    "انصھار": 1,
    "انضاء": 1,
    "انضاد": 1,
    "انضباط": 1,
    "انضغاط": 1,
    "انضمام": 1,
    "انطاع": 1,
    "انطاكية": 1,
    "انطلاق": 1,
    "انطماس": 1,
    "انطواء": 1,
    "انطولوجى": 1,
    "انطوى": 1,
    "انظار": 1,
    "انظاره": 1,
    "انظف": 1,
    "انظمة": 1,
    "انعاش": 1,
    "انعام": 1,
    "انعتزالية": 1,
    "انعزال": 1,
    "انعطاف": 1,
    "انعقاد": 1,
    "انعكاسي": 1,
    "انعكاسية": 1,
    "انعل": 1,
    "انعم": 1,
    "انغام": 1,
    "انغلاق": 1,
    "انف": 1,
    "انفاذ": 1,
    "انفار": 1,
    "انفاس": 1,
    "انفاسه": 1,
    "انفاق": 1,
    "انفال": 1,
    "انفة": 1,
    "انفتحت": 1,
    "انفتل": 1,
    "انفراج": 1,
    "انفراد": 1,
    "انفرادي": 1,
    "انفرة": 1,
    "انفرس": 1,
    "انفرط": 1,
    "انفس": 1,
    "انفساح": 1,
    "انفسحت": 1,
    "انفسھم": 1,
    "انفصالي": 1,
    "انفصالية": 1,
    "انفضاض": 1,
    "انفطر": 1,
    "انفعال": 1,
    "انفعالي": 1,
    "انفعالية": 1,
    "انفكاك": 1,
    "انفلات": 1,
    "انفلونزا": 1,
    "انفه": 1,
    "انفى": 1,
    "انق": 1,
    "انقاب": 1,
    "انقاذ": 1,
    "انقاص": 1,
    "انقاض": 1,
    "انقبة": 1,
    "انقبض": 1,
    "انقره": 1,
    "انقضاض": 1,
    "انقضاضية": 1,
    "انقطاعية": 1,
    "انقع": 1,
    "انقلاب": 1,
    "انقليس": 1,
    "انقياد": 1,
    "انكاد": 1,
    "انكار": 1,
    "انكاري": 1,
    "انكال": 1,
    "انكب": 1,
    "انكباب": 1,
    "انكد": 1,
    "انكرت": 1,
    "انكسار": 1,
    "انكساف": 1,
    "انكسرت": 1,
    "انكشارى": 1,
    "انكشاري": 1,
    "انكفاء": 1,
    "انكلترا": 1,
    "انكلترة": 1,
    "انكلوسكسون": 1,
    "انكلوسكسونية": 1,
    "انكليزى": 1,
    "انكليزي": 1,
    "انكليزية": 1,
    "انكليس": 1,
    "انكماش": 1,
    "انكى": 1,
    "انما": 1,
    "انماء": 1,
    "انمار": 1,
    "انماط": 1,
    "انمحق": 1,
    "انمحى": 1,
    "انمر": 1,
    "انمش": 1,
    "انملة": 1,
    "انموذج": 1,
    "انمون": 1,
    "انواء": 1,
    "انوار": 1,
    "انواط": 1,
    "انواعھم": 1,
    "انواف": 1,
    "انواك": 1,
    "انوال": 1,
    "انوان": 1,
    "انوثة": 1,
    "انور": 1,
    "انوف": 1,
    "انوق": 1,
    "انى": 1,
    "اني": 1,
    "انياب": 1,
    "انيابه": 1,
    "انيار": 1,
    "انيس": 1,
    "انيسون": 1,
    "انيق": 1,
    "انيميا": 1,
    "انين": 1,
    "انھا": 1,
    "انھاء": 1,
    "انھار": 1,
    "انھاض": 1,
    "انھاك": 1,
    "انھال": 1,
    "انھج": 1,
    "انھر": 1,
    "انھزام": 1,
    "انھزامي": 1,
    "انھزامية": 1,
    "انھضام": 1,
    "انھماك": 1,
    "انھيار": 1,
    "انھيال": 1,
    "اوائل": 1,
    "اوائله": 1,
    "اوابد": 1,
    "اواخر": 1,
    "اوادم": 1,
    "اوار": 1,
    "اواسط": 1,
    "اواش": 1,
    "اواصر": 1,
    "اواطب": 1,
    "اواع": 1,
    "اوام": 1,
    "اوامر": 1,
    "اوان": 1,
    "اوانس": 1,
    "اوانه": 1,
    "اواه": 1,
    "اوب": 1,
    "اوبئة": 1,
    "اوباء": 1,
    "اوبار": 1,
    "اوباش": 1,
    "اوبة": 1,
    "اوبر": 1,
    "اوبرا": 1,
    "اوبريت": 1,
    "اوت": 1,
    "اوتاد": 1,
    "اوتار": 1,
    "اوتجراف": 1,
    "اوتشك": 1,
    "اوتنة": 1,
    "اوتوبيس": 1,
    "اوتوقراطي": 1,
    "اوتوموبيل": 1,
    "اوتيل": 1,
    "اوثان": 1,
    "اوثق": 1,
    "اوج": 1,
    "اوجار": 1,
    "اوجاع": 1,
    "اوجاق": 1,
    "اوجال": 1,
    "اوجرة": 1,
    "اوجس": 1,
    "اوجه": 1,
    "اوح": 1,
    "اوحال": 1,
    "اوحد": 1,
    "اوحي": 1,
    "اوخم": 1,
    "اود": 1,
    "اوداج": 1,
    "اوداجه": 1,
    "اوداد": 1,
    "اودة": 1,
    "اودع": 1,
    "اودعه": 1,
    "اوده": 1,
    "اودى": 1,
    "اودية": 1,
    "اوراب": 1,
    "اوراد": 1,
    "اوراع": 1,
    "اوراق": 1,
    "اوراك": 1,
    "اورال": 1,
    "اورام": 1,
    "اوربا": 1,
    "اوربي": 1,
    "اوردة": 1,
    "اورشليم": 1,
    "اورط": 1,
    "اورطة": 1,
    "اورغوي": 1,
    "اوركسترا": 1,
    "اورنيك": 1,
    "اوره": 1,
    "اوروجواي": 1,
    "اوروپا": 1,
    "اورى": 1,
    "اوز": 1,
    "اوزار": 1,
    "اوزارھا": 1,
    "اوزاع": 1,
    "اوزان": 1,
    "اوساخ": 1,
    "اوساط": 1,
    "اوساق": 1,
    "اوستراليا": 1,
    "اوستريا": 1,
    "اوسط": 1,
    "اوسطى": 1,
    "اوسع": 1,
    "اوسعه": 1,
    "اوسلو": 1,
    "اوسمة": 1,
    "اوشاب": 1,
    "اوشال": 1,
    "اوشحة": 1,
    "اوشية": 1,
    "اوصاب": 1,
    "اوصاف": 1,
    "اوصافه": 1,
    "اوصال": 1,
    "اوصاله": 1,
    "اوصاه": 1,
    "اوصد": 1,
    "اوصل": 1,
    "اوصياء": 1,
    "اوضاح": 1,
    "اوضار": 1,
    "اوضاع": 1,
    "اوضام": 1,
    "اوضح": 1,
    "اوطأ": 1,
    "اوطاب": 1,
    "اوطاد": 1,
    "اوطار": 1,
    "اوطان": 1,
    "اوطف": 1,
    "اوعار": 1,
    "اوعاس": 1,
    "اوعال": 1,
    "اوعر": 1,
    "اوعية": 1,
    "اوغاد": 1,
    "اوغر": 1,
    "اوغل": 1,
    "اوفاد": 1,
    "اوفاز": 1,
    "اوفر": 1,
    "اوفرول": 1,
    "اوفق": 1,
    "اوفى": 1,
    "اوفياء": 1,
    "اوق": 1,
    "اوقاب": 1,
    "اوقات": 1,
    "اوقاتا": 1,
    "اوقار": 1,
    "اوقاف": 1,
    "اوقة": 1,
    "اوقد": 1,
    "اوقع": 1,
    "اوقعه": 1,
    "اوقف": 1,
    "اوقفه": 1,
    "اوقية": 1,
    "اوكار": 1,
    "اوكازيون": 1,
    "اوكسجين": 1,
    "اوكية": 1,
    "اولئك": 1,
    "اولا": 1,
    "اولاء": 1,
    "اولائك": 1,
    "اولائكم": 1,
    "اولاد": 1,
    "اولاه": 1,
    "اولدھا": 1,
    "اولمبى": 1,
    "اولمبياد": 1,
    "اوله": 1,
    "اولو": 1,
    "اولوا": 1,
    "اولى": 1,
    "اولياء": 1,
    "اولية": 1,
    "اومنيبوس": 1,
    "اون": 1,
    "اونباشى": 1,
    "اونباشي": 1,
    "اوه": 1,
    "اوى": 1,
    "اويقات": 1,
    "اويقيانس": 1,
    "اويقيانوس": 1,
    "اوھاق": 1,
    "اوھام": 1,
    "اوھد": 1,
    "اى": 1,
    "ايا": 1,
    "ايائل": 1,
    "ايائم": 1,
    "اياب": 1,
    "اياد": 1,
    "ايادي": 1,
    "ايار": 1,
    "اياس": 1,
    "اياك": 1,
    "ايالة": 1,
    "ايام": 1,
    "اياما": 1,
    "ايامه": 1,
    "ايامى": 1,
    "ايان": 1,
    "اية": 1,
    "ايتام": 1,
    "ايتھا": 1,
    "ايجاب": 1,
    "ايجابا": 1,
    "ايجابي": 1,
    "ايجابية": 1,
    "ايجار": 1,
    "ايجاز": 1,
    "ايجازا": 1,
    "ايحاء": 1,
    "ايحاش": 1,
    "ايد": 1,
    "ايداع": 1,
    "ايدروجين": 1,
    "ايدروجينية": 1,
    "ايدي": 1,
    "ايديكم": 1,
    "ايدينا": 1,
    "ايديھم": 1,
    "ايذاء": 1,
    "ايذان": 1,
    "ايذنا": 1,
    "اير": 1,
    "ايراد": 1,
    "ايرانى": 1,
    "ايرلندا": 1,
    "ايرلندى": 1,
    "ايريال": 1,
    "ايزيس": 1,
    "ايس": 1,
    "ايسر": 1,
    "ايشرب": 1,
    "ايصاء": 1,
    "ايصال": 1,
    "ايض": 1,
    "ايضا": 1,
    "ايضاح": 1,
    "ايضاحي": 1,
    "ايطالى": 1,
    "ايطاليا": 1,
    "ايعاد": 1,
    "ايعاز": 1,
    "ايعازي": 1,
    "ايفاء": 1,
    "ايفاد": 1,
    "ايفاع": 1,
    "ايقاظ": 1,
    "ايقاع": 1,
    "ايقاعي": 1,
    "ايقاف": 1,
    "ايقونة": 1,
    "ايقونية": 1,
    "ايك": 1,
    "ايل": 1,
    "ايلاء": 1,
    "ايلاج": 1,
    "ايلول": 1,
    "ايلولة": 1,
    "ايم": 1,
    "ايما": 1,
    "ايماء": 1,
    "ايمان": 1,
    "ايمانھم": 1,
    "ايمة": 1,
    "ايمن": 1,
    "اين": 1,
    "ايناس": 1,
    "ايناع": 1,
    "اينما": 1,
    "ايواء": 1,
    "ايوان": 1,
    "ايوب": 1,
    "ايور": 1,
    "ايوم": 1,
    "ايون": 1,
    "ايھا": 1,
    "ايھاب": 1,
    "ايھام": 1,
    "اڤلاط": 1,
    "اھاب": 1,
    "اھابط": 1,
    "اھابه": 1,
    "اھاجي": 1,
    "اھازيج": 1,
    "اھال": 1,
    "اھاليل": 1,
    "اھانة": 1,
    "اھانه": 1,
    "اھب": 1,
    "اھباء": 1,
    "اھبة": 1,
    "اھبته": 1,
    "اھبل": 1,
    "اھبوطة": 1,
    "اھتار": 1,
    "اھتبل": 1,
    "اھتز": 1,
    "اھتزاز": 1,
    "اھتزازة": 1,
    "اھتم": 1,
    "اھتمام": 1,
    "اھتمامه": 1,
    "اھجوة": 1,
    "اھجية": 1,
    "اھداء": 1,
    "اھداب": 1,
    "اھداف": 1,
    "اھدام": 1,
    "اھدل": 1,
    "اھدى": 1,
    "اھراء": 1,
    "اھراق": 1,
    "اھرام": 1,
    "اھرامات": 1,
    "اھرامي": 1,
    "اھزوجة": 1,
    "اھطع": 1,
    "اھكومة": 1,
    "اھل": 1,
    "اھلا": 1,
    "اھلاب": 1,
    "اھلب": 1,
    "اھلة": 1,
    "اھلك": 1,
    "اھله": 1,
    "اھلي": 1,
    "اھلية": 1,
    "اھليلج": 1,
    "اھليلجي": 1,
    "اھم": 1,
    "اھماج": 1,
    "اھمال": 1,
    "اھمام": 1,
    "اھمل": 1,
    "اھمية": 1,
    "اھواء": 1,
    "اھوار": 1,
    "اھوال": 1,
    "اھوان": 1,
    "اھوج": 1,
    "اھوس": 1,
    "اھوسة": 1,
    "اھول": 1,
    "اھون": 1,
    "اھوناء": 1,
    "اھونه": 1,
    "اھوى": 1,
    "اھوية": 1,
    "اھيف": 1,
    "بآمال": 1,
    "بأبأ": 1,
    "بأثر": 1,
    "بأجله": 1,
    "بأدبه": 1,
    "بأذيال": 1,
    "بأر": 1,
    "بأرضنا": 1,
    "بأز": 1,
    "بأساء": 1,
    "بأسباب": 1,
    "بأسبابه": 1,
    "بأعباء": 1,
    "بأقدم": 1,
    "بأنظاره": 1,
    "بأنفه": 1,
    "بأوربا": 1,
    "بأوقاتھا": 1,
    "بأھداب": 1,
    "بؤبؤ": 1,
    "بؤساء": 1,
    "بؤسى": 1,
    "بؤوز": 1,
    "بؤونة": 1,
    "بإدانته": 1,
    "بإرشاده": 1,
    "بئار": 1,
    "بئر": 1,
    "بئزان": 1,
    "با": 1,
    "بائت": 1,
    "بائد": 1,
    "بائر": 1,
    "بائض": 1,
    "بائقة": 1,
    "بائن": 1,
    "بائنة": 1,
    "باباوات": 1,
    "باباوى": 1,
    "بابة": 1,
    "بابتسامة": 1,
    "بابلى": 1,
    "بابنك": 1,
    "بابه": 1,
    "بابوات": 1,
    "بابوج": 1,
    "بابور": 1,
    "بابونج": 1,
    "بابوى": 1,
    "بابوية": 1,
    "باتسته": 1,
    "باتصفيق": 1,
    "باتع": 1,
    "باثولوجى": 1,
    "باثولوجيا": 1,
    "باجمعه": 1,
    "باجمعھم": 1,
    "باح": 1,
    "باخ": 1,
    "باخس": 1,
    "باد": 1,
    "بادأه": 1,
    "بادئ": 1,
    "بادر": 1,
    "بادرة": 1,
    "بادره": 1,
    "بادى": 1,
    "بادية": 1,
    "باذخ": 1,
    "باذل": 1,
    "باذن": 1,
    "باذنجان": 1,
    "باذياله": 1,
    "باراشوت": 1,
    "باراغواى": 1,
    "باربوني": 1,
    "بارة": 1,
    "بارت": 1,
    "بارجة": 1,
    "بارح": 1,
    "باردة": 1,
    "بارق": 1,
    "بارقة": 1,
    "باركيه": 1,
    "بارنامج": 1,
    "بارودة": 1,
    "باريسى": 1,
    "باريھا": 1,
    "بازاء": 1,
    "بازار": 1,
    "بازلت": 1,
    "بازوبند": 1,
    "باستثناء": 1,
    "باستيل": 1,
    "باستھزاء": 1,
    "باسره": 1,
    "باسرھم": 1,
    "باسطة": 1,
    "باسق": 1,
    "باسور": 1,
    "باسھاب": 1,
    "باش": 1,
    "باشا": 1,
    "باشاوات": 1,
    "باشجاويش": 1,
    "باشحكيم": 1,
    "باشريش": 1,
    "باشق": 1,
    "باشكاتب": 1,
    "باشكير": 1,
    "باشمفتش": 1,
    "باشمفتى": 1,
    "باشمھندس": 1,
    "باشوات": 1,
    "باص": 1,
    "باصرة": 1,
    "باض": 1,
    "باطان": 1,
    "باطن": 1,
    "باطنا": 1,
    "باطنية": 1,
    "باطون": 1,
    "باطية": 1,
    "باعتبار": 1,
    "باعتباره": 1,
    "باعوث": 1,
    "باغة": 1,
    "بافتراءات": 1,
    "باقرب": 1,
    "باقعة": 1,
    "باكر": 1,
    "باكرا": 1,
    "باكرة": 1,
    "باكستانى": 1,
    "باكمله": 1,
    "باكملھا": 1,
    "باكور": 1,
    "باكورة": 1,
    "باكية": 1,
    "بالأجر": 1,
    "بالأجل": 1,
    "بالأحمر": 1,
    "بالأداء": 1,
    "بالأسعار": 1,
    "بالأقل": 1,
    "بالأمل": 1,
    "بالأولى": 1,
    "بالإسلام": 1,
    "بالإعدام": 1,
    "بالإكراه": 1,
    "بالإنجيل": 1,
    "بالإياب": 1,
    "بالإيجاب": 1,
    "بالإيجاز": 1,
    "بالا": 1,
    "بالاتفاق": 1,
    "بالاجازة": 1,
    "بالاجماع": 1,
    "بالاجمال": 1,
    "بالاحالة": 1,
    "بالاحرف": 1,
    "بالاحرى": 1,
    "بالاحكام": 1,
    "بالاختصار": 1,
    "بالاشتراك": 1,
    "بالاصالة": 1,
    "بالاضافة": 1,
    "بالاعدام": 1,
    "بالامتحان": 1,
    "بالامس": 1,
    "بالانتساب": 1,
    "بالباع": 1,
    "بالبحر": 1,
    "بالبداھة": 1,
    "بالبكاء": 1,
    "بالبنان": 1,
    "بالة": 1,
    "بالتالى": 1,
    "بالتبع": 1,
    "بالتبعية": 1,
    "بالتتابع": 1,
    "بالتدابير": 1,
    "بالتداول": 1,
    "بالتدرج": 1,
    "بالتدريج": 1,
    "بالتراب": 1,
    "بالتزاماته": 1,
    "بالتسلسل": 1,
    "بالتسليم": 1,
    "بالتشھير": 1,
    "بالتصديق": 1,
    "بالتفرقة": 1,
    "بالتفريق": 1,
    "بالتفصيل": 1,
    "بالتقريب": 1,
    "بالتلفزة": 1,
    "بالتمام": 1,
    "بالتناو": 1,
    "بالتوصية": 1,
    "بالثلاثة": 1,
    "بالجراح": 1,
    "بالجرم": 1,
    "بالجريمة": 1,
    "بالحائط": 1,
    "بالحديث": 1,
    "بالحديد": 1,
    "بالحرام": 1,
    "بالحرف": 1,
    "بالحرى": 1,
    "بالحري": 1,
    "بالحصر": 1,
    "بالحضن": 1,
    "بالحمل": 1,
    "بالحيوان": 1,
    "بالخسائر": 1,
    "بالخيار": 1,
    "بالخيبة": 1,
    "بالدار": 1,
    "بالدموع": 1,
    "بالدور": 1,
    "بالدون": 1,
    "بالذمة": 1,
    "بالذھب": 1,
    "بالذھن": 1,
    "بالراحة": 1,
    "بالرترتيب": 1,
    "بالرسمي": 1,
    "بالرصاص": 1,
    "بالرفاء": 1,
    "بالزانة": 1,
    "بالزوال": 1,
    "بالزور": 1,
    "بالسقوط": 1,
    "بالسلاح": 1,
    "بالسلطة": 1,
    "بالسين": 1,
    "بالشؤون": 1,
    "بالشباب": 1,
    "بالشطرنج": 1,
    "بالشكال": 1,
    "بالشكر": 1,
    "بالشكوى": 1,
    "بالشملة": 1,
    "بالشيء": 1,
    "بالصمت": 1,
    "بالضاد": 1,
    "بالضحك": 1,
    "بالضراعة": 1,
    "بالضرب": 1,
    "بالطريحة": 1,
    "بالطلاق": 1,
    "بالطو": 1,
    "بالطوات": 1,
    "بالطول": 1,
    "بالعادة": 1,
    "بالعار": 1,
    "بالعداوة": 1,
    "بالعطاف": 1,
    "بالغصب": 1,
    "بالفارغ": 1,
    "بالفتق": 1,
    "بالفشل": 1,
    "بالفضول": 1,
    "بالفطام": 1,
    "بالقذة": 1,
    "بالقراءة": 1,
    "بالقطاعي": 1,
    "بالقنابل": 1,
    "بالك": 1,
    "بالكاشف": 1,
    "بالكاف": 1,
    "بالكتابة": 1,
    "بالكفاية": 1,
    "بالكل": 1,
    "بالكلية": 1,
    "بالكناية": 1,
    "بالكورجة": 1,
    "بالكھرباء": 1,
    "باللاسلكي": 1,
    "باللياقة": 1,
    "بالمال": 1,
    "بالمجان": 1,
    "بالمجمل": 1,
    "بالمدة": 1,
    "بالمراضاة": 1,
    "بالمرة": 1,
    "بالمرصاد": 1,
    "بالمروحة": 1,
    "بالمسحة": 1,
    "بالمصاريف": 1,
    "بالمعين": 1,
    "بالمفردات": 1,
    "بالمفرق": 1,
    "بالمفھوم": 1,
    "بالمقابلة": 1,
    "بالمقطوعية": 1,
    "بالمقلوب": 1,
    "بالمكان": 1,
    "بالمكشوف": 1,
    "بالمناوبة": 1,
    "بالمنحرف": 1,
    "بالمنطوق": 1,
    "بالمينا": 1,
    "بالميناه": 1,
    "بالنابل": 1,
    "بالنار": 1,
    "بالنبع": 1,
    "بالنتيجة": 1,
    "بالنجاح": 1,
    "بالنسبة": 1,
    "بالنصر": 1,
    "بالنعل": 1,
    "بالنعمة": 1,
    "بالنفاذ": 1,
    "بالنقد": 1,
    "بالنقط": 1,
    "بالنواجذ": 1,
    "بالنوبة": 1,
    "بالنور": 1,
    "بالنيابة": 1,
    "بالنھار": 1,
    "باله": 1,
    "بالواجب": 1,
    "بالورب": 1,
    "بالوساطة": 1,
    "بالوعة": 1,
    "بالوعود": 1,
    "بالوفاء": 1,
    "بالوفقة": 1,
    "بالوكوس": 1,
    "بالون": 1,
    "بالويل": 1,
    "بالياطاش": 1,
    "بالھاتف": 1,
    "بالھموم": 1,
    "بالھواء": 1,
    "بامره": 1,
    "باميا": 1,
    "بامية": 1,
    "بانتصاف": 1,
    "بانتھاء": 1,
    "بانظاره": 1,
    "بانفه": 1,
    "باه": 1,
    "باوده": 1,
    "باى": 1,
    "باية": 1,
    "بايخ": 1,
    "بايديھم": 1,
    "بايرق": 1,
    "باھانة": 1,
    "باھت": 1,
    "باھداب": 1,
    "باھدابه": 1,
    "باھدافه": 1,
    "باھر": 1,
    "باھظ": 1,
    "باھظا": 1,
    "باھل": 1,
    "باھم": 1,
    "بباله": 1,
    "ببر": 1,
    "ببراءته": 1,
    "ببصره": 1,
    "ببطء": 1,
    "ببغاوات": 1,
    "ببنان": 1,
    "ببور": 1,
    "ببيت": 1,
    "ببھائه": 1,
    "بتا": 1,
    "بتاتا": 1,
    "بتاتى": 1,
    "بتار": 1,
    "بتة": 1,
    "بتدقيق": 1,
    "بتراء": 1,
    "بترول": 1,
    "بتسلسل": 1,
    "بتسليم": 1,
    "بتصرف": 1,
    "بتصريح": 1,
    "بتك": 1,
    "بتل": 1,
    "بتلابيبه": 1,
    "بتمام": 1,
    "بتمھل": 1,
    "بتواصل": 1,
    "بتوقيع": 1,
    "بتول": 1,
    "بتولا": 1,
    "بتولية": 1,
    "بتى": 1,
    "بتية": 1,
    "بتيومين": 1,
    "بثرة": 1,
    "بثق": 1,
    "بثور": 1,
    "بثير": 1,
    "بجأش": 1,
    "بجح": 1,
    "بجدة": 1,
    "بجدتھا": 1,
    "بجدع": 1,
    "بجراح": 1,
    "بجرادھا": 1,
    "بجرانه": 1,
    "بجرة": 1,
    "بجس": 1,
    "بجع": 1,
    "بجلاء": 1,
    "بجم": 1,
    "بجمع": 1,
    "بجن": 1,
    "بجنوب": 1,
    "بجوار": 1,
    "بجوم": 1,
    "بجيس": 1,
    "بجھد": 1,
    "بح": 1,
    "بحائر": 1,
    "بحاث": 1,
    "بحاثة": 1,
    "بحاح": 1,
    "بحاحة": 1,
    "بحال": 1,
    "بحاله": 1,
    "بحبح": 1,
    "بحبه": 1,
    "بحبوح": 1,
    "بحبوحة": 1,
    "بحة": 1,
    "بحتا": 1,
    "بحتر": 1,
    "بحتري": 1,
    "بحثر": 1,
    "بحجة": 1,
    "بحجر": 1,
    "بحح": 1,
    "بحذاء": 1,
    "بحذافيره": 1,
    "بحران": 1,
    "بحراني": 1,
    "بحرة": 1,
    "بحرف": 1,
    "بحروفھا": 1,
    "بحرى": 1,
    "بحضوره": 1,
    "بحقه": 1,
    "بحقوقه": 1,
    "بحكم": 1,
    "بحلق": 1,
    "بحمل": 1,
    "بحوث": 1,
    "بحوثات": 1,
    "بحوح": 1,
    "بحوحة": 1,
    "بحياته": 1,
    "بخاخة": 1,
    "بخارية": 1,
    "بخاشيش": 1,
    "بخاطره": 1,
    "بخانق": 1,
    "بخت": 1,
    "بختر": 1,
    "بخدمته": 1,
    "بخشيش": 1,
    "بخع": 1,
    "بخفي": 1,
    "بخقاء": 1,
    "بخلاف": 1,
    "بخناقه": 1,
    "بخنق": 1,
    "بخور": 1,
    "بخياله": 1,
    "بخيت": 1,
    "بخيخة": 1,
    "بخيره": 1,
    "بدأة": 1,
    "بدئه": 1,
    "بداءة": 1,
    "بدائع": 1,
    "بدائه": 1,
    "بداة": 1,
    "بدار": 1,
    "بدافع": 1,
    "بدال": 1,
    "بدالة": 1,
    "بداوة": 1,
    "بداھة": 1,
    "بدرات": 1,
    "بدرة": 1,
    "بدروم": 1,
    "بدرون": 1,
    "بدع": 1,
    "بدعة": 1,
    "بدعوى": 1,
    "بدلاء": 1,
    "بدلوه": 1,
    "بدلية": 1,
    "بدن": 1,
    "بده": 1,
    "بدو": 1,
    "بدوات": 1,
    "بدوره": 1,
    "بدوري": 1,
    "بدونة": 1,
    "بديئة": 1,
    "بديعة": 1,
    "بديعي": 1,
    "بديلة": 1,
    "بديھة": 1,
    "بديھي": 1,
    "بديھية": 1,
    "بدھي": 1,
    "بذ": 1,
    "بذأ": 1,
    "بذؤ": 1,
    "بذئ": 1,
    "بذاء": 1,
    "بذاته": 1,
    "بذاذة": 1,
    "بذار": 1,
    "بذخ": 1,
    "بذراع": 1,
    "بذراعيه": 1,
    "بذريعة": 1,
    "بذكره": 1,
    "بذكرياتي": 1,
    "بذلة": 1,
    "بذمته": 1,
    "بذنجان": 1,
    "برء": 1,
    "برأسه": 1,
    "برأي": 1,
    "برأيه": 1,
    "برئيه": 1,
    "برائز": 1,
    "برائق": 1,
    "برابخ": 1,
    "برابرة": 1,
    "برابى": 1,
    "براثن": 1,
    "براجل": 1,
    "براجم": 1,
    "براجواى": 1,
    "براح": 1,
    "براحا": 1,
    "براد": 1,
    "برادة": 1,
    "براديق": 1,
    "براذع": 1,
    "براذعى": 1,
    "براذين": 1,
    "برارى": 1,
    "براريك": 1,
    "برازخ": 1,
    "براسيرى": 1,
    "براطيل": 1,
    "براعته": 1,
    "براعم": 1,
    "براعيم": 1,
    "براغى": 1,
    "براغيث": 1,
    "برافان": 1,
    "برافير": 1,
    "براقع": 1,
    "براكين": 1,
    "برام": 1,
    "برامة": 1,
    "برامق": 1,
    "براميل": 1,
    "برانس": 1,
    "برانسى": 1,
    "برانى": 1,
    "برانيس": 1,
    "برانيط": 1,
    "براويز": 1,
    "برايا": 1,
    "براية": 1,
    "براھما": 1,
    "براھمة": 1,
    "براھين": 1,
    "بربا": 1,
    "برباط": 1,
    "بربخ": 1,
    "بربر": 1,
    "بربرى": 1,
    "بربط": 1,
    "بربون": 1,
    "بربونى": 1,
    "بربى": 1,
    "بربيس": 1,
    "بربيش": 1,
    "برتغال": 1,
    "برتغالى": 1,
    "برتقال": 1,
    "برتقالى": 1,
    "برتقان": 1,
    "برتقانى": 1,
    "برثن": 1,
    "برجل": 1,
    "برجمة": 1,
    "برجوع": 1,
    "برح": 1,
    "برداء": 1,
    "برداق": 1,
    "برداية": 1,
    "بردة": 1,
    "بردخ": 1,
    "بردعة": 1,
    "بردقان": 1,
    "بردقوش": 1,
    "بردورة": 1,
    "بردى": 1,
    "بردية": 1,
    "برذعة": 1,
    "برذون": 1,
    "برزان": 1,
    "برزوق": 1,
    "برسام": 1,
    "برسيم": 1,
    "برش": 1,
    "برشام": 1,
    "برشامة": 1,
    "برشامجى": 1,
    "برشان": 1,
    "برشت": 1,
    "برشده": 1,
    "برشم": 1,
    "برشمة": 1,
    "برشمجية": 1,
    "برص": 1,
    "برصة": 1,
    "برض": 1,
    "برطع": 1,
    "برطعة": 1,
    "برطل": 1,
    "برطلة": 1,
    "برطم": 1,
    "برطمان": 1,
    "برطوز": 1,
    "برطوم": 1,
    "برطيل": 1,
    "برع": 1,
    "برعايته": 1,
    "برعم": 1,
    "برعوم": 1,
    "برغش": 1,
    "برغل": 1,
    "برغوث": 1,
    "برغى": 1,
    "برفقة": 1,
    "برفير": 1,
    "برقاب": 1,
    "برقت": 1,
    "برقش": 1,
    "برقع": 1,
    "برقوق": 1,
    "برقى": 1,
    "برقية": 1,
    "برك": 1,
    "بركار": 1,
    "بركانى": 1,
    "برلمان": 1,
    "برلمانى": 1,
    "برلمانية": 1,
    "برلنتى": 1,
    "برم": 1,
    "برما": 1,
    "برمائى": 1,
    "برماننت": 1,
    "برمة": 1,
    "برمته": 1,
    "برمتھا": 1,
    "برمق": 1,
    "برمنكھام": 1,
    "برمودة": 1,
    "برمھات": 1,
    "برنجك": 1,
    "برنس": 1,
    "برنسيسة": 1,
    "برنط": 1,
    "برنطية": 1,
    "برنوس": 1,
    "برنية": 1,
    "بره": 1,
    "برواز": 1,
    "بروة": 1,
    "بروتستانتي": 1,
    "بروتستانتية": 1,
    "بروتستو": 1,
    "بروتون": 1,
    "بروجى": 1,
    "بروحه": 1,
    "برود": 1,
    "برودة": 1,
    "بروض": 1,
    "بروعه": 1,
    "بروفة": 1,
    "بروق": 1,
    "برونز": 1,
    "برونزى": 1,
    "بروڤة": 1,
    "برية": 1,
    "بريدى": 1,
    "بريز": 1,
    "بريزة": 1,
    "بريقه": 1,
    "بريم": 1,
    "بريمة": 1,
    "بريمية": 1,
    "برھان": 1,
    "برھة": 1,
    "برھتين": 1,
    "برھم": 1,
    "برھمن": 1,
    "برھمية": 1,
    "برھن": 1,
    "برھنة": 1,
    "بزابيز": 1,
    "بزاة": 1,
    "بزار": 1,
    "بزازة": 1,
    "بزاق": 1,
    "بزاقة": 1,
    "بزال": 1,
    "بزبوز": 1,
    "بزة": 1,
    "بزر": 1,
    "بزغ": 1,
    "بزغبره": 1,
    "بزق": 1,
    "بزل": 1,
    "بزلا": 1,
    "بزلة": 1,
    "بزمامه": 1,
    "بزموت": 1,
    "بزنطى": 1,
    "بزور": 1,
    "بزوز": 1,
    "بزوغ": 1,
    "بزيد": 1,
    "بزيرة": 1,
    "بسء": 1,
    "بسأ": 1,
    "بسائط": 1,
    "بساتن": 1,
    "بساتين": 1,
    "بسار": 1,
    "بسارابيا": 1,
    "بساس": 1,
    "بساطة": 1,
    "بساعة": 1,
    "بسالة": 1,
    "بسام": 1,
    "بسباس": 1,
    "بسباسة": 1,
    "بسبوسة": 1,
    "بسبيل": 1,
    "بسة": 1,
    "بستان": 1,
    "بستانى": 1,
    "بستن": 1,
    "بستنة": 1,
    "بستون": 1,
    "بستيلية": 1,
    "بسخة": 1,
    "بسداد": 1,
    "بسر": 1,
    "بسركم": 1,
    "بسطاء": 1,
    "بسطرمة": 1,
    "بسعة": 1,
    "بسق": 1,
    "بسكليت": 1,
    "بسكوت": 1,
    "بسكويت": 1,
    "بسلاء": 1,
    "بسلة": 1,
    "بسمعه": 1,
    "بسمل": 1,
    "بسنة": 1,
    "بسواء": 1,
    "بسوطه": 1,
    "بسوق": 1,
    "بسيفه": 1,
    "بسيكولوجى": 1,
    "بسينة": 1,
    "بسھم": 1,
    "بسھولة": 1,
    "بش": 1,
    "بشأن": 1,
    "بشأنه": 1,
    "بشاشة": 1,
    "بشاعة": 1,
    "بشاك": 1,
    "بشاكير": 1,
    "بشامرى": 1,
    "بشانيق": 1,
    "بشبر": 1,
    "بشت": 1,
    "بشتة": 1,
    "بشراء": 1,
    "بشرط": 1,
    "بشرفه": 1,
    "بشرق": 1,
    "بشروش": 1,
    "بشقه": 1,
    "بشكره": 1,
    "بشكله": 1,
    "بشكور": 1,
    "بشكير": 1,
    "بشلة": 1,
    "بشم": 1,
    "بشمار": 1,
    "بشمق": 1,
    "بشنة": 1,
    "بشنس": 1,
    "بشنوقة": 1,
    "بشنين": 1,
    "بص": 1,
    "بصائص": 1,
    "بصارة": 1,
    "بصاع": 1,
    "بصاق": 1,
    "بصبص": 1,
    "بصبصة": 1,
    "بصة": 1,
    "بصحته": 1,
    "بصخة": 1,
    "بصدد": 1,
    "بصدده": 1,
    "بصدره": 1,
    "بصر": 1,
    "بصراء": 1,
    "بصرف": 1,
    "بصرى": 1,
    "بصريات": 1,
    "بصفة": 1,
    "بصفته": 1,
    "بصفقة": 1,
    "بصق": 1,
    "بصقة": 1,
    "بصل": 1,
    "بصلى": 1,
    "بصليب": 1,
    "بصنعه": 1,
    "بصوابه": 1,
    "بصوة": 1,
    "بصوته": 1,
    "بصيص": 1,
    "بصيلة": 1,
    "بضعة": 1,
    "بطء": 1,
    "بطؤ": 1,
    "بطئ": 1,
    "بطاء": 1,
    "بطاءة": 1,
    "بطائح": 1,
    "بطائق": 1,
    "بطائل": 1,
    "بطائن": 1,
    "بطابعه": 1,
    "بطاح": 1,
    "بطارخ": 1,
    "بطارقة": 1,
    "بطاركة": 1,
    "بطارية": 1,
    "بطاطين": 1,
    "بطال": 1,
    "بطالة": 1,
    "بطالسة": 1,
    "بطان": 1,
    "بطباعه": 1,
    "بطبط": 1,
    "بطبطة": 1,
    "بطبيعة": 1,
    "بطة": 1,
    "بطح": 1,
    "بطحاء": 1,
    "بطحاوات": 1,
    "بطر": 1,
    "بطرخ": 1,
    "بطرس": 1,
    "بطرشيل": 1,
    "بطرشين": 1,
    "بطرف": 1,
    "بطرفة": 1,
    "بطرك": 1,
    "بطركية": 1,
    "بطريركية": 1,
    "بطريك": 1,
    "بطشة": 1,
    "بطعنة": 1,
    "بطق": 1,
    "بطلة": 1,
    "بطلق": 1,
    "بطم": 1,
    "بطنا": 1,
    "بطنة": 1,
    "بطنى": 1,
    "بطول": 1,
    "بطىء": 1,
    "بطيحة": 1,
    "بطيخ": 1,
    "بظ": 1,
    "بظر": 1,
    "بظرف": 1,
    "بظلفه": 1,
    "بظور": 1,
    "بظھر": 1,
    "بعابع": 1,
    "بعاداته": 1,
    "بعارين": 1,
    "بعاليه": 1,
    "بعبارة": 1,
    "بعبع": 1,
    "بعثة": 1,
    "بعج": 1,
    "بعدئذ": 1,
    "بعداء": 1,
    "بعدان": 1,
    "بعر": 1,
    "بعران": 1,
    "بعرس": 1,
    "بعزق": 1,
    "بعزقة": 1,
    "بعضھم": 1,
    "بعقبه": 1,
    "بعقله": 1,
    "بعكوكة": 1,
    "بعل": 1,
    "بعلبك": 1,
    "بعلة": 1,
    "بعلى": 1,
    "بعملة": 1,
    "بعنايته": 1,
    "بعوث": 1,
    "بعودة": 1,
    "بعوض": 1,
    "بعول": 1,
    "بعولة": 1,
    "بعيدة": 1,
    "بعينيه": 1,
    "بعھدته": 1,
    "بغاء": 1,
    "بغاة": 1,
    "بغاث": 1,
    "بغادة": 1,
    "بغاددة": 1,
    "بغاشة": 1,
    "بغاضة": 1,
    "بغال": 1,
    "بغايا": 1,
    "بغثان": 1,
    "بغدادى": 1,
    "بغدادي": 1,
    "بغدد": 1,
    "بغز": 1,
    "بغش": 1,
    "بغشة": 1,
    "بغشت": 1,
    "بغضاء": 1,
    "بغضة": 1,
    "بغل": 1,
    "بغلة": 1,
    "بغية": 1,
    "بفارغ": 1,
    "بفاعل": 1,
    "بفتة": 1,
    "بفتيك": 1,
    "بق": 1,
    "بقاء": 1,
    "بقار": 1,
    "بقاشيش": 1,
    "بقاع": 1,
    "بقاق": 1,
    "بقال": 1,
    "بقالة": 1,
    "بقام": 1,
    "بقباق": 1,
    "بقبق": 1,
    "بقبقة": 1,
    "بقبوقة": 1,
    "بقج": 1,
    "بقجة": 1,
    "بقدونس": 1,
    "بقر": 1,
    "بقرب": 1,
    "بقرى": 1,
    "بقس": 1,
    "بقسمات": 1,
    "بقشة": 1,
    "بقشيش": 1,
    "بقضيضھم": 1,
    "بقل": 1,
    "بقلاوا": 1,
    "بقلاوة": 1,
    "بقلمه": 1,
    "بقليته": 1,
    "بقليتھم": 1,
    "بقليل": 1,
    "بقيد": 1,
    "بكاء": 1,
    "بكاة": 1,
    "بكارتھا": 1,
    "بكارج": 1,
    "بكاسين": 1,
    "بكالوريا": 1,
    "بكامله": 1,
    "بكباشى": 1,
    "بكبدك": 1,
    "بكتيرى": 1,
    "بكثير": 1,
    "بكذبة": 1,
    "بكران": 1,
    "بكرتھا": 1,
    "بكرتھم": 1,
    "بكرج": 1,
    "بكرسي": 1,
    "بكرى": 1,
    "بكرية": 1,
    "بكسماد": 1,
    "بكسماط": 1,
    "بكلام": 1,
    "بكلامه": 1,
    "بكلاه": 1,
    "بكلة": 1,
    "بكلكله": 1,
    "بكلوريوس": 1,
    "بكليته": 1,
    "بكماء": 1,
    "بكماله": 1,
    "بكوات": 1,
    "بكور": 1,
    "بكورة": 1,
    "بكورى": 1,
    "بكورية": 1,
    "بكوية": 1,
    "بكيء": 1,
    "بكير": 1,
    "بلابل": 1,
    "بلاتين": 1,
    "بلاج": 1,
    "بلاجرا": 1,
    "بلادا": 1,
    "بلارج": 1,
    "بلاشفة": 1,
    "بلاص": 1,
    "بلاط": 1,
    "بلاطة": 1,
    "بلاطى": 1,
    "بلاعة": 1,
    "بلاعم": 1,
    "بلاعيم": 1,
    "بلاغم": 1,
    "بلاقع": 1,
    "بلاليص": 1,
    "بلاليع": 1,
    "بلالين": 1,
    "بلان": 1,
    "بلانة": 1,
    "بلايا": 1,
    "بلايين": 1,
    "بلاھة": 1,
    "بلاھين": 1,
    "بلبال": 1,
    "بلبط": 1,
    "بلبل": 1,
    "بلبلة": 1,
    "بلبه": 1,
    "بلة": 1,
    "بلج": 1,
    "بلجيكى": 1,
    "بلحمه": 1,
    "بلحية": 1,
    "بلدان": 1,
    "بلدى": 1,
    "بلدية": 1,
    "بلزقه": 1,
    "بلسانه": 1,
    "بلسم": 1,
    "بلشف": 1,
    "بلشفة": 1,
    "بلشفى": 1,
    "بلشفية": 1,
    "بلشون": 1,
    "بلص": 1,
    "بلصقي": 1,
    "بلطة": 1,
    "بلطجى": 1,
    "بلطو": 1,
    "بلطى": 1,
    "بلطيق": 1,
    "بلع": 1,
    "بلعة": 1,
    "بلعم": 1,
    "بلعه": 1,
    "بلعوم": 1,
    "بلغاء": 1,
    "بلغارى": 1,
    "بلغم": 1,
    "بلغمى": 1,
    "بلغمي": 1,
    "بلغه": 1,
    "بلف": 1,
    "بلفة": 1,
    "بلقانى": 1,
    "بلقع": 1,
    "بلقعة": 1,
    "بلقيس": 1,
    "بلك": 1,
    "بلكون": 1,
    "بلن": 1,
    "بلنسية": 1,
    "بلو": 1,
    "بلوج": 1,
    "بلورى": 1,
    "بلورية": 1,
    "بلوظة": 1,
    "بلوعة": 1,
    "بلوى": 1,
    "بلي": 1,
    "بلياتشو": 1,
    "بلياردو": 1,
    "بلية": 1,
    "بليسيه": 1,
    "بليلاه": 1,
    "بليلة": 1,
    "بلھارسيا": 1,
    "بلھجة": 1,
    "بلھنية": 1,
    "بلھوان": 1,
    "بلھون": 1,
    "بمباغ": 1,
    "بمباغة": 1,
    "بمباى": 1,
    "بمبلغ": 1,
    "بمثابة": 1,
    "بمجامع": 1,
    "بمجرد": 1,
    "بمحض": 1,
    "بمحضر": 1,
    "بمخنقه": 1,
    "بمداد": 1,
    "بمدرسة": 1,
    "بمراحل": 1,
    "بمرثاة": 1,
    "بمرور": 1,
    "بمزيد": 1,
    "بمشيئة": 1,
    "بمضي": 1,
    "بمعدل": 1,
    "بمعزل": 1,
    "بمعقد": 1,
    "بمعية": 1,
    "بمفرده": 1,
    "بمقامه": 1,
    "بمقتضى": 1,
    "بمقدساتا": 1,
    "بمكان": 1,
    "بمنأى": 1,
    "بمناسبة": 1,
    "بمنتھى": 1,
    "بمنجاة": 1,
    "بمنكبيه": 1,
    "بمننزلة": 1,
    "بمنه": 1,
    "بمواجھة": 1,
    "بمھمة": 1,
    "بنائى": 1,
    "بنابه": 1,
    "بناة": 1,
    "بنادر": 1,
    "بنادقة": 1,
    "بنادورة": 1,
    "بناديق": 1,
    "بنارس": 1,
    "بناره": 1,
    "بنازلة": 1,
    "بناصر": 1,
    "بناصره": 1,
    "بناصيته": 1,
    "بنانك": 1,
    "بناية": 1,
    "بنباشى": 1,
    "بنته": 1,
    "بنتو": 1,
    "بنجر": 1,
    "بندر": 1,
    "بندقانى": 1,
    "بندقة": 1,
    "بندقى": 1,
    "بندقية": 1,
    "بندورة": 1,
    "بندورى": 1,
    "بندوق": 1,
    "بنديرة": 1,
    "بنزھير": 1,
    "بنس": 1,
    "بنسلين": 1,
    "بنسيه": 1,
    "بنسيون": 1,
    "بنصه": 1,
    "بنط": 1,
    "بنظره": 1,
    "بنغازى": 1,
    "بنفسج": 1,
    "بنفسجى": 1,
    "بنفسوسنا": 1,
    "بنفش": 1,
    "بنقة": 1,
    "بنكنوت": 1,
    "بنوار": 1,
    "بنوال": 1,
    "بنوة": 1,
    "بنود": 1,
    "بنوط": 1,
    "بنوع": 1,
    "بنوى": 1,
    "بنيقة": 1,
    "بنيو": 1,
    "بوء": 1,
    "بوأ": 1,
    "بوائض": 1,
    "بوائق": 1,
    "بواب": 1,
    "بوابج": 1,
    "بوابل": 1,
    "بوابير": 1,
    "بواجب": 1,
    "بواجباته": 1,
    "بواخ": 1,
    "بوادر": 1,
    "بوادق": 1,
    "بواذخ": 1,
    "بوار": 1,
    "بوارتة": 1,
    "بوارج": 1,
    "بوارق": 1,
    "بوارى": 1,
    "بواريد": 1,
    "بواز": 1,
    "بواسل": 1,
    "بواسير": 1,
    "بواشق": 1,
    "بواصر": 1,
    "بواط": 1,
    "بواطن": 1,
    "بواعث": 1,
    "بواغيز": 1,
    "بواق": 1,
    "بواقع": 1,
    "بواقيل": 1,
    "بواك": 1,
    "بواكر": 1,
    "بواكير": 1,
    "بوالة": 1,
    "بوالس": 1,
    "بوالص": 1,
    "بواليع": 1,
    "بوانى": 1,
    "بوبلين": 1,
    "بوبينة": 1,
    "بوتاس": 1,
    "بوتاسا": 1,
    "بوتقة": 1,
    "بوجرام": 1,
    "بوجية": 1,
    "بوجيز": 1,
    "بوجھه": 1,
    "بوجھھا": 1,
    "بوح": 1,
    "بوخ": 1,
    "بودرة": 1,
    "بودقة": 1,
    "بودنا": 1,
    "بوذى": 1,
    "بور": 1,
    "بورانى": 1,
    "بورتغال": 1,
    "بورتوريكو": 1,
    "بورتوكول": 1,
    "بورصة": 1,
    "بورغى": 1,
    "بورق": 1,
    "بوروجى": 1,
    "بورى": 1,
    "بوريه": 1,
    "بوز": 1,
    "بوزة": 1,
    "بوس": 1,
    "بوساطة": 1,
    "بوسة": 1,
    "بوستة": 1,
    "بوستو": 1,
    "بوسطة": 1,
    "بوسيلة": 1,
    "بوص": 1,
    "بوصة": 1,
    "بوصلة": 1,
    "بوطة": 1,
    "بوظة": 1,
    "بوع": 1,
    "بوعده": 1,
    "بوغ": 1,
    "بوغادة": 1,
    "بوغاز": 1,
    "بوغاضة": 1,
    "بوفيه": 1,
    "بوق": 1,
    "بوقال": 1,
    "بوقالة": 1,
    "بوقته": 1,
    "بوقوع": 1,
    "بوكسفورد": 1,
    "بوكسكاف": 1,
    "بولاد": 1,
    "بولاق": 1,
    "بولصة": 1,
    "بولندة": 1,
    "بولندى": 1,
    "بولوني": 1,
    "بولى": 1,
    "بولية": 1,
    "بوليسة": 1,
    "بوليسية": 1,
    "بوليصة": 1,
    "بوليفى": 1,
    "بونسايرس": 1,
    "بونى": 1,
    "بوياجى": 1,
    "بوية": 1,
    "بويت": 1,
    "بويجى": 1,
    "بويخرة": 1,
    "بويضة": 1,
    "بوھيمي": 1,
    "بوھيميا": 1,
    "بوھيمية": 1,
    "بيات": 1,
    "بياتة": 1,
    "بيادة": 1,
    "بيادر": 1,
    "بيادق": 1,
    "بيادى": 1,
    "بيارة": 1,
    "بيارتة": 1,
    "بياضات": 1,
    "بياطرة": 1,
    "بيانا": 1,
    "بيانة": 1,
    "بيانى": 1,
    "بيبان": 1,
    "بيبة": 1,
    "بية": 1,
    "بيتى": 1,
    "بيجاما": 1,
    "بيداء": 1,
    "بيداوات": 1,
    "بيدر": 1,
    "بيدق": 1,
    "بيديه": 1,
    "بيذق": 1,
    "بيذنجان": 1,
    "بيرق": 1,
    "بيرقدار": 1,
    "بيروتى": 1,
    "بيروقراطى": 1,
    "بيزان": 1,
    "بيزنطيا": 1,
    "بيسون": 1,
    "بيص": 1,
    "بيضاوى": 1,
    "بيضوى": 1,
    "بيضى": 1,
    "بيضھا": 1,
    "بيطار": 1,
    "بيطر": 1,
    "بيطرة": 1,
    "بيطرى": 1,
    "بيعة": 1,
    "بيك": 1,
    "بيكار": 1,
    "بيكباشى": 1,
    "بيكه": 1,
    "بيكوات": 1,
    "بيكوية": 1,
    "بيلة": 1,
    "بيلسان": 1,
    "بيليه": 1,
    "بيلھارسيا": 1,
    "بيمارستان": 1,
    "بينباشى": 1,
    "بينتو": 1,
    "بينما": 1,
    "بينونة": 1,
    "بينى": 1,
    "بينھم": 1,
    "بينھما": 1,
    "بيه": 1,
    "بيوتات": 1,
    "بيوريه": 1,
    "بيوع": 1,
    "بيوعات": 1,
    "بييضة": 1,
    "بھا": 1,
    "بھاء": 1,
    "بھائم": 1,
    "بھائى": 1,
    "بھار": 1,
    "بھاليل": 1,
    "بھت": 1,
    "بھتان": 1,
    "بھتة": 1,
    "بھج": 1,
    "بھجة": 1,
    "بھدل": 1,
    "بھدلة": 1,
    "بھدوء": 1,
    "بھذا": 1,
    "بھذه": 1,
    "بھر": 1,
    "بھرة": 1,
    "بھرج": 1,
    "بھرجان": 1,
    "بھرجة": 1,
    "بھريز": 1,
    "بھظ": 1,
    "بھق": 1,
    "بھل": 1,
    "بھلوان": 1,
    "بھلوانى": 1,
    "بھلواني": 1,
    "بھلوانية": 1,
    "بھلول": 1,
    "بھم": 1,
    "بھمة": 1,
    "بھو": 1,
    "بھوات": 1,
    "بھى": 1,
    "بھيج": 1,
    "بھيم": 1,
    "بھيمة": 1,
    "بھيمى": 1,
    "بھيمية": 1,
    "تآزر": 1,
    "تآلف": 1,
    "تآليف": 1,
    "تأبين": 1,
    "تأتأ": 1,
    "تأثري": 1,
    "تأثرية": 1,
    "تأثيري": 1,
    "تأخذني": 1,
    "تأدب": 1,
    "تأديب": 1,
    "تأديبى": 1,
    "تأديبية": 1,
    "تأر": 1,
    "تأزم": 1,
    "تأسف": 1,
    "تأسية": 1,
    "تأسيسى": 1,
    "تأصل": 1,
    "تأصيلة": 1,
    "تأم": 1,
    "تأمر": 1,
    "تأمور": 1,
    "تأميم": 1,
    "تأن": 1,
    "تأنان": 1,
    "تأنس": 1,
    "تأنق": 1,
    "تأنيب": 1,
    "تأنيث": 1,
    "تأوه": 1,
    "تأيم": 1,
    "تأييد": 1,
    "تأيين": 1,
    "تأھب": 1,
    "تؤخر": 1,
    "تؤدة": 1,
    "تؤكل": 1,
    "تا": 1,
    "تاء": 1,
    "تائق": 1,
    "تابعة": 1,
    "تابعية": 1,
    "تابل": 1,
    "تابوت": 1,
    "تابيوكا": 1,
    "تاجرة": 1,
    "تاح": 1,
    "تاريخي": 1,
    "تاريع": 1,
    "تازة": 1,
    "تافل": 1,
    "تافھة": 1,
    "تاق": 1,
    "تاك": 1,
    "تال": 1,
    "تالد": 1,
    "تالف": 1,
    "تاله": 1,
    "تانئ": 1,
    "تاه": 1,
    "تاى": 1,
    "تايور": 1,
    "تايير": 1,
    "تب": 1,
    "تبا": 1,
    "تبائع": 1,
    "تبادر": 1,
    "تبار": 1,
    "تباريح": 1,
    "تباشير": 1,
    "تباطؤ": 1,
    "تباع": 1,
    "تباعة": 1,
    "تباغض": 1,
    "تبان": 1,
    "تباه": 1,
    "تباينى": 1,
    "تبب": 1,
    "تبتيت": 1,
    "تبجيل": 1,
    "تبحر": 1,
    "تبديد": 1,
    "تبذير": 1,
    "تبر": 1,
    "تبرر": 1,
    "تبرية": 1,
    "تبريد": 1,
    "تبرير": 1,
    "تبريز": 1,
    "تبريك": 1,
    "تبسط": 1,
    "تبسم": 1,
    "تبشير": 1,
    "تبصر": 1,
    "تبعة": 1,
    "تبعى": 1,
    "تبعية": 1,
    "تبعيد": 1,
    "تبعيض": 1,
    "تبكيت": 1,
    "تبلبل": 1,
    "تبلد": 1,
    "تبلوه": 1,
    "تبوأ": 1,
    "تبوغ": 1,
    "تبولة": 1,
    "تبويب": 1,
    "تبويخ": 1,
    "تبويزة": 1,
    "تبيان": 1,
    "تبيح": 1,
    "تبيع": 1,
    "تبيوكا": 1,
    "تبييضة": 1,
    "تتابع": 1,
    "تتبعات": 1,
    "تتحلب": 1,
    "تتريك": 1,
    "تتسع": 1,
    "تتقطع": 1,
    "تتك": 1,
    "تتن": 1,
    "تتنوس": 1,
    "تتويج": 1,
    "تتيه": 1,
    "تثاوب": 1,
    "تثقيل": 1,
    "تثليث": 1,
    "تثليثى": 1,
    "تثمين": 1,
    "تثنية": 1,
    "تجاذبوا": 1,
    "تجارب": 1,
    "تجارتھم": 1,
    "تجارى": 1,
    "تجاريد": 1,
    "تجاعيد": 1,
    "تجافيف": 1,
    "تجانس": 1,
    "تجاواف": 1,
    "تجاوب": 1,
    "تجاوبت": 1,
    "تجاھل": 1,
    "تجبر": 1,
    "تجبين": 1,
    "تجدد": 1,
    "تجديف": 1,
    "تجذير": 1,
    "تجربة": 1,
    "تجريب": 1,
    "تجريبى": 1,
    "تجريح": 1,
    "تجريدة": 1,
    "تجزية": 1,
    "تجسس": 1,
    "تجسيم": 1,
    "تجعدات": 1,
    "تجفاف": 1,
    "تجفيف": 1,
    "تجلة": 1,
    "تجلد": 1,
    "تجلية": 1,
    "تجمي": 1,
    "تجميم": 1,
    "تجمھر": 1,
    "تجن": 1,
    "تجند": 1,
    "تجنس": 1,
    "تجنيد": 1,
    "تجنيس": 1,
    "تجوال": 1,
    "تجويد": 1,
    "تجويع": 1,
    "تجھيز": 1,
    "تجھيزات": 1,
    "تجھيزي": 1,
    "تجھيل": 1,
    "تحابب": 1,
    "تحات": 1,
    "تحارير": 1,
    "تحاريق": 1,
    "تحاسد": 1,
    "تحاسين": 1,
    "تحاش": 1,
    "تحاك": 1,
    "تحامل": 1,
    "تحاور": 1,
    "تحاويل": 1,
    "تحايا": 1,
    "تحايلي": 1,
    "تحبب": 1,
    "تحتانى": 1,
    "تحتانية": 1,
    "تحجج": 1,
    "تحجير": 1,
    "تحدر": 1,
    "تحدى": 1,
    "تحديات": 1,
    "تحذير": 1,
    "تحر": 1,
    "تحرج": 1,
    "تحرق": 1,
    "تحريات": 1,
    "تحريج": 1,
    "تحريرية": 1,
    "تحريش": 1,
    "تحريض": 1,
    "تحريف": 1,
    "تحريم": 1,
    "تحزب": 1,
    "تحشم": 1,
    "تحشية": 1,
    "تحصيص": 1,
    "تحصيل": 1,
    "تحصيلجي": 1,
    "تحصين": 1,
    "تحضر": 1,
    "تحطم": 1,
    "تحطيب": 1,
    "تحف": 1,
    "تحفة": 1,
    "تحفز": 1,
    "تحفظي": 1,
    "تحفظية": 1,
    "تحفيظ": 1,
    "تحقتر": 1,
    "تحقيقا": 1,
    "تحكمي": 1,
    "تحكيم": 1,
    "تحكيمات": 1,
    "تحكيمية": 1,
    "تحلب": 1,
    "تحليف": 1,
    "تحليق": 1,
    "تحميلة": 1,
    "تحنان": 1,
    "تحنط": 1,
    "تحنن": 1,
    "تحوط": 1,
    "تحويط": 1,
    "تحويلة": 1,
    "تحيل": 1,
    "تحين": 1,
    "تحييذ": 1,
    "تخ": 1,
    "تخابر": 1,
    "تخارج": 1,
    "تخاشيب": 1,
    "تخالفني": 1,
    "تخت": 1,
    "تختة": 1,
    "تختخ": 1,
    "تختذل": 1,
    "تختروان": 1,
    "تختطب": 1,
    "تختم": 1,
    "تخديم": 1,
    "تخريب": 1,
    "تخريج": 1,
    "تخريف": 1,
    "تخريمة": 1,
    "تخزين": 1,
    "تخشب": 1,
    "تخشيب": 1,
    "تخشيبة": 1,
    "تخصيب": 1,
    "تخطيب": 1,
    "تخلخل": 1,
    "تخلع": 1,
    "تخلية": 1,
    "تخليد": 1,
    "تخليط": 1,
    "تخم": 1,
    "تخمة": 1,
    "تخمير": 1,
    "تخمينا": 1,
    "تخنث": 1,
    "تخوم": 1,
    "تخويف": 1,
    "تخييل": 1,
    "تدابر": 1,
    "تداخل": 1,
    "تداركت": 1,
    "تداع": 1,
    "تداعى": 1,
    "تداو": 1,
    "تداول": 1,
    "تداولته": 1,
    "تداينوا": 1,
    "تدبر": 1,
    "تدبيج": 1,
    "تدجيل": 1,
    "تدرجة": 1,
    "تدرن": 1,
    "تدرني": 1,
    "تدريج": 1,
    "تدريجى": 1,
    "تدريس": 1,
    "تدشين": 1,
    "تدعيم": 1,
    "تدفئة": 1,
    "تدقيق": 1,
    "تدلل": 1,
    "تدليس": 1,
    "تدليسي": 1,
    "تدليل": 1,
    "تدليلا": 1,
    "تدلھت": 1,
    "تدمرى": 1,
    "تدمري": 1,
    "تدميج": 1,
    "تدمير": 1,
    "تدن": 1,
    "تدوم": 1,
    "تدويخ": 1,
    "تدوير": 1,
    "تدويل": 1,
    "تدوين": 1,
    "تدين": 1,
    "تدھور": 1,
    "تذاكر": 1,
    "تذكرجي": 1,
    "تذكير": 1,
    "تذليل": 1,
    "ترؤس": 1,
    "ترائب": 1,
    "ترائك": 1,
    "ترابة": 1,
    "ترابس": 1,
    "ترابى": 1,
    "ترابيزة": 1,
    "ترابيس": 1,
    "ترابيع": 1,
    "تراتيل": 1,
    "تراث": 1,
    "تراجم": 1,
    "تراجمة": 1,
    "تراجيم": 1,
    "تراحيم": 1,
    "تراخ": 1,
    "تراخوما": 1,
    "تراخيص": 1,
    "ترادف": 1,
    "تراس": 1,
    "تراص": 1,
    "تراق": 1,
    "تراكز": 1,
    "تراكيب": 1,
    "ترام": 1,
    "ترامواى": 1,
    "ترامى": 1,
    "ترانم": 1,
    "ترب": 1,
    "ترباس": 1,
    "تربان": 1,
    "تربس": 1,
    "تربع": 1,
    "تربنتين": 1,
    "تربى": 1,
    "تربيزة": 1,
    "تربيع": 1,
    "تربيعة": 1,
    "تربيعى": 1,
    "تربيعي": 1,
    "تربين": 1,
    "تربيوي": 1,
    "ترتب": 1,
    "ترتر": 1,
    "ترتيبات": 1,
    "ترتيل": 1,
    "ترتيلة": 1,
    "ترجمان": 1,
    "ترح": 1,
    "ترحاب": 1,
    "ترحال": 1,
    "ترحيب": 1,
    "ترحيل": 1,
    "ترحيم": 1,
    "ترخيم": 1,
    "ترداد": 1,
    "ترزى": 1,
    "ترزية": 1,
    "ترسانة": 1,
    "ترسحانة": 1,
    "ترسكل": 1,
    "ترسل": 1,
    "ترسم": 1,
    "ترسيب": 1,
    "ترسينة": 1,
    "ترضية": 1,
    "ترع": 1,
    "ترعة": 1,
    "ترغل": 1,
    "ترغلة": 1,
    "ترغيب": 1,
    "ترف": 1,
    "ترفاس": 1,
    "ترفة": 1,
    "ترفض": 1,
    "ترفق": 1,
    "ترفل": 1,
    "ترفيع": 1,
    "ترفيه": 1,
    "ترق": 1,
    "ترقم": 1,
    "ترقوة": 1,
    "ترقية": 1,
    "ترقيد": 1,
    "ترقيع": 1,
    "تركة": 1,
    "تركته": 1,
    "تركز": 1,
    "تركستان": 1,
    "تركه": 1,
    "تركى": 1,
    "ترمبطجى": 1,
    "ترمبيتجى": 1,
    "ترمبيط": 1,
    "ترمبيطة": 1,
    "ترمس": 1,
    "ترمل": 1,
    "ترموس": 1,
    "ترمومتر": 1,
    "ترميد": 1,
    "ترميم": 1,
    "ترنج": 1,
    "ترنحت": 1,
    "ترنيمة": 1,
    "تره": 1,
    "ترو": 1,
    "ترواده": 1,
    "تروب": 1,
    "تروبص": 1,
    "تروية": 1,
    "ترويح": 1,
    "ترويحة": 1,
    "ترويض": 1,
    "ترويق": 1,
    "ترويقة": 1,
    "ترياق": 1,
    "تريبة": 1,
    "تريزا": 1,
    "تريض": 1,
    "تريكة": 1,
    "تريكو": 1,
    "ترييح": 1,
    "ترييم": 1,
    "ترھب": 1,
    "ترھة": 1,
    "ترھل": 1,
    "ترھيب": 1,
    "تزاحم": 1,
    "تزايل": 1,
    "تزفيت": 1,
    "تزكية": 1,
    "تزويج": 1,
    "تزوير": 1,
    "تزيا": 1,
    "تزيى": 1,
    "تزييف": 1,
    "تزھد": 1,
    "تزھير": 1,
    "تسآل": 1,
    "تساءل": 1,
    "تساؤل": 1,
    "تسابيح": 1,
    "تساريح": 1,
    "تسامع": 1,
    "تساو": 1,
    "تساوق": 1,
    "تساير": 1,
    "تساھل": 1,
    "تسبحة": 1,
    "تسبيب": 1,
    "تسبيح": 1,
    "تسبيحة": 1,
    "تسبيخ": 1,
    "تسبيك": 1,
    "تستر": 1,
    "تستيف": 1,
    "تسخط": 1,
    "تسخير": 1,
    "تسد": 1,
    "تسرة": 1,
    "تسرر": 1,
    "تسرع": 1,
    "تسرى": 1,
    "تسرية": 1,
    "تسطير": 1,
    "تسعرة": 1,
    "تسعيرة": 1,
    "تسكسك": 1,
    "تسلط": 1,
    "تسلطن": 1,
    "تسلم": 1,
    "تسليف": 1,
    "تسليفة": 1,
    "تسليم": 1,
    "تسميد": 1,
    "تسنم": 1,
    "تسوس": 1,
    "تسويد": 1,
    "تسويغ": 1,
    "تسويف": 1,
    "تسويفي": 1,
    "تسويق": 1,
    "تسويقة": 1,
    "تسيير": 1,
    "تسھيل": 1,
    "تشائم": 1,
    "تشاكس": 1,
    "تشاكل": 1,
    "تشامخ": 1,
    "تشبيه": 1,
    "تشتيت": 1,
    "تشحم": 1,
    "تشحيم": 1,
    "تشدق": 1,
    "تشديد": 1,
    "تشرط": 1,
    "تشريد": 1,
    "تشريفاتية": 1,
    "تشريق": 1,
    "تشريك": 1,
    "تشرين": 1,
    "تشطيب": 1,
    "تشعع": 1,
    "تشف": 1,
    "تشكك": 1,
    "تشكيلات": 1,
    "تشليح": 1,
    "تشمل": 1,
    "تشمم": 1,
    "تشه": 1,
    "تشوق": 1,
    "تشويق": 1,
    "تشيطن": 1,
    "تشيلى": 1,
    "تشييد": 1,
    "تشھاق": 1,
    "تصادق": 1,
    "تصادم": 1,
    "تصاريح": 1,
    "تصاريف": 1,
    "تصاغر": 1,
    "تصاف": 1,
    "تصالح": 1,
    "تصاميم": 1,
    "تصانيف": 1,
    "تصاوير": 1,
    "تصايح": 1,
    "تصبب": 1,
    "تصبح": 1,
    "تصبيرة": 1,
    "تصحيف": 1,
    "تصدية": 1,
    "تصدير": 1,
    "تصديره": 1,
    "تصرفا": 1,
    "تصرفات": 1,
    "تصرفه": 1,
    "تصريح": 1,
    "تصفيح": 1,
    "تصلف": 1,
    "تصميغ": 1,
    "تصنيفة": 1,
    "تصويرة": 1,
    "تصيير": 1,
    "تضاؤل": 1,
    "تضاد": 1,
    "تضارب": 1,
    "تضاريس": 1,
    "تضاعيف": 1,
    "تضاعيفه": 1,
    "تضاغط": 1,
    "تضام": 1,
    "تضاما": 1,
    "تضامن": 1,
    "تضحية": 1,
    "تضخيم": 1,
    "تضييع": 1,
    "تضييق": 1,
    "تطأمن": 1,
    "تطبع": 1,
    "تطبيب": 1,
    "تطبيقية": 1,
    "تطرف": 1,
    "تطريز": 1,
    "تطعيم": 1,
    "تطفيف": 1,
    "تطمين": 1,
    "تطواف": 1,
    "تطوان": 1,
    "تطورية": 1,
    "تطويب": 1,
    "تطويع": 1,
    "تطويق": 1,
    "تطويل": 1,
    "تطير": 1,
    "تطھيري": 1,
    "تظاھر": 1,
    "تظرف": 1,
    "تظريف": 1,
    "تظھير": 1,
    "تع": 1,
    "تعابير": 1,
    "تعادل": 1,
    "تعاريج": 1,
    "تعاريش": 1,
    "تعاريف": 1,
    "تعاز": 1,
    "تعاضد": 1,
    "تعاضدي": 1,
    "تعاط": 1,
    "تعاقب": 1,
    "تعام": 1,
    "تعاور": 1,
    "تعاونية": 1,
    "تعب": 1,
    "تعبيد": 1,
    "تعتع": 1,
    "تعتويذ": 1,
    "تعتيم": 1,
    "تعجله": 1,
    "تعجيب": 1,
    "تعداد": 1,
    "تعدى": 1,
    "تعديات": 1,
    "تعدين": 1,
    "تعذر": 1,
    "تعريشة": 1,
    "تعريفة": 1,
    "تعز": 1,
    "تعزية": 1,
    "تعزيته": 1,
    "تعزير": 1,
    "تعس": 1,
    "تعساء": 1,
    "تعسر": 1,
    "تعسف": 1,
    "تعسيلة": 1,
    "تعشيق": 1,
    "تعضل": 1,
    "تعضيد": 1,
    "تعطب": 1,
    "تعطشا": 1,
    "تعطف": 1,
    "تعطل": 1,
    "تعطيب": 1,
    "تعظيم": 1,
    "تعفن": 1,
    "تعفير": 1,
    "تعقب": 1,
    "تعقد": 1,
    "تعقل": 1,
    "تعقيب": 1,
    "تعقيد": 1,
    "تعكير": 1,
    "تعلامة": 1,
    "تعلة": 1,
    "تعلل": 1,
    "تعلية": 1,
    "تعليقة": 1,
    "تعليل": 1,
    "تعليماتا": 1,
    "تعمدا": 1,
    "تعمدي": 1,
    "تعمدھا": 1,
    "تعميرة": 1,
    "تعميم": 1,
    "تعن": 1,
    "تعنت": 1,
    "تعنيف": 1,
    "تعويد": 1,
    "تعويذ": 1,
    "تعھد": 1,
    "تغابن": 1,
    "تغاض": 1,
    "تغاضى": 1,
    "تغافل": 1,
    "تغايير": 1,
    "تغجير": 1,
    "تغرض": 1,
    "تغرغرت": 1,
    "تغريب": 1,
    "تغريد": 1,
    "تغريق": 1,
    "تغزل": 1,
    "تغطرس": 1,
    "تغطيس": 1,
    "تغفيل": 1,
    "تغيب": 1,
    "تغييرا": 1,
    "تف": 1,
    "تفا": 1,
    "تفاؤل": 1,
    "تفاتيش": 1,
    "تفاح": 1,
    "تفاحش": 1,
    "تفاريق": 1,
    "تفاسير": 1,
    "تفاضل": 1,
    "تفاعيل": 1,
    "تفافة": 1,
    "تفاقم": 1,
    "تفال": 1,
    "تفاليس": 1,
    "تفان": 1,
    "تفاھة": 1,
    "تفاھم": 1,
    "تفتا": 1,
    "تفتاه": 1,
    "تفتق": 1,
    "تفتيشي": 1,
    "تفجع": 1,
    "تفجعن": 1,
    "تفرج": 1,
    "تفرنج": 1,
    "تفريح": 1,
    "تفريدي": 1,
    "تفريط": 1,
    "تفريغ": 1,
    "تفريق": 1,
    "تفزع": 1,
    "تفسرة": 1,
    "تفش": 1,
    "تفصيل": 1,
    "تفصيلي": 1,
    "تفصيليا": 1,
    "تفضل": 1,
    "تفضيل": 1,
    "تفطن": 1,
    "تفعيل": 1,
    "تفقد": 1,
    "تفقيص": 1,
    "تفك": 1,
    "تفكة": 1,
    "تفكر": 1,
    "تفكك": 1,
    "تفكه": 1,
    "تفكير": 1,
    "تفكيك": 1,
    "تفكھة": 1,
    "تفليس": 1,
    "تفنن": 1,
    "تفه": 1,
    "تفوته": 1,
    "تفوه": 1,
    "تفويت": 1,
    "تفھم": 1,
    "تفھيم": 1,
    "تقابض": 1,
    "تقادم": 1,
    "تقادير": 1,
    "تقاديم": 1,
    "تقاذفت": 1,
    "تقارب": 1,
    "تقارضوا": 1,
    "تقارير": 1,
    "تقاريط": 1,
    "تقاريظ": 1,
    "تقاسيم": 1,
    "تقاطيع": 1,
    "تقاليد": 1,
    "تقانة": 1,
    "تقاوى": 1,
    "تقاوي": 1,
    "تقاويم": 1,
    "تقاييد": 1,
    "تقبيل": 1,
    "تقتير": 1,
    "تقتيل": 1,
    "تقدمة": 1,
    "تقدمت": 1,
    "تقديس": 1,
    "تقذيف": 1,
    "تقرب": 1,
    "تقريب": 1,
    "تقريرا": 1,
    "تقريطة": 1,
    "تقريظ": 1,
    "تقريع": 1,
    "تقسموه": 1,
    "تقسيط": 1,
    "تقسيمة": 1,
    "تقشف": 1,
    "تقشير": 1,
    "تقص": 1,
    "تقصير": 1,
    "تقطعت": 1,
    "تقطير": 1,
    "تقفيصة": 1,
    "تقلص": 1,
    "تقلية": 1,
    "تقليش": 1,
    "تقليم": 1,
    "تقمص": 1,
    "تقمقم": 1,
    "تقميص": 1,
    "تقن": 1,
    "تقنع": 1,
    "تقنعنا": 1,
    "تقنين": 1,
    "تقوت": 1,
    "تقوير": 1,
    "تقويرة": 1,
    "تقويض": 1,
    "تقى": 1,
    "تقية": 1,
    "تقييد": 1,
    "تقھقر": 1,
    "تكأة": 1,
    "تكابر": 1,
    "تكاثف": 1,
    "تكاريز": 1,
    "تكاسر": 1,
    "تكافل": 1,
    "تكالب": 1,
    "تكاوين": 1,
    "تكايا": 1,
    "تكبيس": 1,
    "تكة": 1,
    "تكتك": 1,
    "تكتكة": 1,
    "تكتم": 1,
    "تكتيك": 1,
    "تكثير": 1,
    "تكحيل": 1,
    "تكدير": 1,
    "تكرار": 1,
    "تكرارا": 1,
    "تكرمة": 1,
    "تكرير": 1,
    "تكريز": 1,
    "تكريس": 1,
    "تكريعة": 1,
    "تكريما": 1,
    "تكسر": 1,
    "تكسية": 1,
    "تكسير": 1,
    "تكسيم": 1,
    "تكشيرة": 1,
    "تكضكض": 1,
    "تكعيب": 1,
    "تكعيبة": 1,
    "تكفير": 1,
    "تكك": 1,
    "تكلام": 1,
    "تكلامة": 1,
    "تكلة": 1,
    "تكليل": 1,
    "تكميد": 1,
    "تكميل": 1,
    "تكميلي": 1,
    "تكنھات": 1,
    "تكوت": 1,
    "تكية": 1,
    "تكھن": 1,
    "تكھنات": 1,
    "تل": 1,
    "تلآفاق": 1,
    "تلألؤ": 1,
    "تلا": 1,
    "تلابيب": 1,
    "تلاتل": 1,
    "تلاحين": 1,
    "تلاد": 1,
    "تلاش": 1,
    "تلاع": 1,
    "تلاعب": 1,
    "تلاف": 1,
    "تلافيف": 1,
    "تلافيفه": 1,
    "تلال": 1,
    "تلاليس": 1,
    "تلامذة": 1,
    "تلاميح": 1,
    "تلاوة": 1,
    "تلبس": 1,
    "تلبيب": 1,
    "تلبية": 1,
    "تلبيسة": 1,
    "تلحين": 1,
    "تلحيني": 1,
    "تلطف": 1,
    "تلع": 1,
    "تلعاب": 1,
    "تلعة": 1,
    "تلغراف": 1,
    "تلغرافا": 1,
    "تلغرافى": 1,
    "تلغرافية": 1,
    "تلف": 1,
    "تلفاز": 1,
    "تلفازية": 1,
    "تلفان": 1,
    "تلفت": 1,
    "تلفزة": 1,
    "تلفزية": 1,
    "تلفن": 1,
    "تلفون": 1,
    "تلفوني": 1,
    "تلفونيا": 1,
    "تلفونية": 1,
    "تلفيف": 1,
    "تلفيق": 1,
    "تلفيقة": 1,
    "تلق": 1,
    "تلقاه": 1,
    "تلقين": 1,
    "تلم": 1,
    "تلماح": 1,
    "تلماذ": 1,
    "تلمذ": 1,
    "تلمذة": 1,
    "تلمسان": 1,
    "تلمظ": 1,
    "تلمود": 1,
    "تلميح": 1,
    "تلميحا": 1,
    "تلميع": 1,
    "تله": 1,
    "تلوكه": 1,
    "تلول": 1,
    "تلويم": 1,
    "تلى": 1,
    "تلي": 1,
    "تليباثى": 1,
    "تليد": 1,
    "تليس": 1,
    "تليع": 1,
    "تليف": 1,
    "تليفزيون": 1,
    "تليفزيوني": 1,
    "تليفون": 1,
    "تليفونى": 1,
    "تليفونية": 1,
    "تلھية": 1,
    "تمائم": 1,
    "تماثل": 1,
    "تماثيل": 1,
    "تماد": 1,
    "تماريد": 1,
    "تماسيح": 1,
    "تمالك": 1,
    "تمباك": 1,
    "تمتين": 1,
    "تمثل": 1,
    "تمجيد": 1,
    "تمحل": 1,
    "تمحيس": 1,
    "تمدح": 1,
    "تمدد": 1,
    "تمدني": 1,
    "تمديد": 1,
    "تمدين": 1,
    "تمديني": 1,
    "تمراد": 1,
    "تمرجى": 1,
    "تمرجية": 1,
    "تمرس": 1,
    "تمرن": 1,
    "تمريض": 1,
    "تمرينات": 1,
    "تمزيق": 1,
    "تمشيا": 1,
    "تمشيط": 1,
    "تمصير": 1,
    "تمضية": 1,
    "تمطط": 1,
    "تمعن": 1,
    "تمغة": 1,
    "تملأ": 1,
    "تململ": 1,
    "تمليح": 1,
    "تمليق": 1,
    "تمليك": 1,
    "تمن": 1,
    "تمنية": 1,
    "تموجات": 1,
    "تمورا": 1,
    "تمورجى": 1,
    "تموز": 1,
    "تمويج": 1,
    "تمويه": 1,
    "تمويھي": 1,
    "تمويھية": 1,
    "تميع": 1,
    "تميمة": 1,
    "تمييز": 1,
    "تمھل": 1,
    "تمھيد": 1,
    "تمھيدي": 1,
    "تمھيدية": 1,
    "تن": 1,
    "تناء": 1,
    "تنابر": 1,
    "تنابلة": 1,
    "تنابيل": 1,
    "تناثر": 1,
    "تناجز": 1,
    "تنادر": 1,
    "تناذر": 1,
    "تنازع": 1,
    "تناسب": 1,
    "تناسخ": 1,
    "تناسل": 1,
    "تناسليات": 1,
    "تناظر": 1,
    "تناف": 1,
    "تناقص": 1,
    "تناقل": 1,
    "تناقلت": 1,
    "تناقلته": 1,
    "تنانير": 1,
    "تنانين": 1,
    "تناه": 1,
    "تناوبته": 1,
    "تناھب": 1,
    "تناھى": 1,
    "تنباك": 1,
    "تنبال": 1,
    "تنبر": 1,
    "تنبك": 1,
    "تنبل": 1,
    "تنبه": 1,
    "تنبيه": 1,
    "تنجستين": 1,
    "تنجية": 1,
    "تنجيد": 1,
    "تنجيز": 1,
    "تنجيس": 1,
    "تنجيم": 1,
    "تنحني": 1,
    "تندة": 1,
    "تندر": 1,
    "تندم": 1,
    "تنديد": 1,
    "تنزه": 1,
    "تنزيه": 1,
    "تنسق": 1,
    "تنسم": 1,
    "تنشئة": 1,
    "تنشق": 1,
    "تنشيء": 1,
    "تنشيط": 1,
    "تنشيف": 1,
    "تنشيقة": 1,
    "تنصل": 1,
    "تنصير": 1,
    "تنصيص": 1,
    "تنصيف": 1,
    "تنضيد": 1,
    "تنطاف": 1,
    "تنطل": 1,
    "تنفخ": 1,
    "تنفق": 1,
    "تنفيذية": 1,
    "تنفير": 1,
    "تنقيب": 1,
    "تنقيح": 1,
    "تنقيص": 1,
    "تنك": 1,
    "تنكة": 1,
    "تنكجى": 1,
    "تنكس": 1,
    "تنكيت": 1,
    "تنكيل": 1,
    "تنمير": 1,
    "تنميق": 1,
    "تنميل": 1,
    "تنور": 1,
    "تنوط": 1,
    "تنوق": 1,
    "تنويع": 1,
    "تنويم": 1,
    "تنوين": 1,
    "تني": 1,
    "تنيس": 1,
    "تنيق": 1,
    "تنييل": 1,
    "تنھد": 1,
    "توءم": 1,
    "توا": 1,
    "توابع": 1,
    "توابل": 1,
    "توابيت": 1,
    "تواتر": 1,
    "تواد": 1,
    "توارث": 1,
    "توارد": 1,
    "تواز": 1,
    "توازنه": 1,
    "تواشيح": 1,
    "تواص": 1,
    "تواصل": 1,
    "تواصيل": 1,
    "تواضع": 1,
    "تواطؤ": 1,
    "توافه": 1,
    "تواكل": 1,
    "تواكيد": 1,
    "توال": 1,
    "توالى": 1,
    "توالي": 1,
    "تواليت": 1,
    "تواليف": 1,
    "توان": 1,
    "توانسة": 1,
    "توانى": 1,
    "توبل": 1,
    "توبوغرافيا": 1,
    "توتر": 1,
    "توترت": 1,
    "توتيا": 1,
    "توتياء": 1,
    "توتية": 1,
    "توثقة": 1,
    "توثيق": 1,
    "توجع": 1,
    "توجولند": 1,
    "توجيھي": 1,
    "توجيھية": 1,
    "توح": 1,
    "توحده": 1,
    "توحش": 1,
    "توخ": 1,
    "توخى": 1,
    "تور": 1,
    "توراة": 1,
    "توربيد": 1,
    "توربين": 1,
    "تورتة": 1,
    "تورط": 1,
    "تورية": 1,
    "توريد": 1,
    "توسعية": 1,
    "توسلا": 1,
    "توسم": 1,
    "توشية": 1,
    "توشيح": 1,
    "توصل": 1,
    "توصيلة": 1,
    "توصيم": 1,
    "توضؤ": 1,
    "توضيب": 1,
    "توضيح": 1,
    "توطئة": 1,
    "توطنت": 1,
    "توطيد": 1,
    "توظف": 1,
    "توعد": 1,
    "توعدي": 1,
    "توغل": 1,
    "توفاه": 1,
    "توفرت": 1,
    "توفية": 1,
    "توق": 1,
    "توقان": 1,
    "توقد": 1,
    "توقر": 1,
    "توكؤ": 1,
    "توكة": 1,
    "توكل": 1,
    "توكيد": 1,
    "تولد": 1,
    "تولع": 1,
    "توله": 1,
    "تولية": 1,
    "تومرجى": 1,
    "تونة": 1,
    "تونسى": 1,
    "تونية": 1,
    "توه": 1,
    "تويج": 1,
    "توھة": 1,
    "توھم": 1,
    "تياترو": 1,
    "تياتل": 1,
    "تياربطيء": 1,
    "تياه": 1,
    "تيتل": 1,
    "تيتنوس": 1,
    "تيجان": 1,
    "تيح": 1,
    "تيزة": 1,
    "تيسير": 1,
    "تيفوس": 1,
    "تيقظ": 1,
    "تيلة": 1,
    "تيماء": 1,
    "تيمارجى": 1,
    "تيمن": 1,
    "تينن": 1,
    "تيه": 1,
    "تيوس": 1,
    "تيوليب": 1,
    "تيھاء": 1,
    "تيھان": 1,
    "تھاتر": 1,
    "تھافت": 1,
    "تھالك": 1,
    "تھاليل": 1,
    "تھامة": 1,
    "تھانئ": 1,
    "تھاون": 1,
    "تھاويل": 1,
    "تھتك": 1,
    "تھته": 1,
    "تھج": 1,
    "تھجم": 1,
    "تھجية": 1,
    "تھدئة": 1,
    "تھدد": 1,
    "تھدم": 1,
    "تھديد": 1,
    "تھديدي": 1,
    "تھديم": 1,
    "تھذب": 1,
    "تھذيب": 1,
    "تھذيبي": 1,
    "تھريب": 1,
    "تھزيز": 1,
    "تھطال": 1,
    "تھكم": 1,
    "تھكمي": 1,
    "تھلكة": 1,
    "تھلل": 1,
    "تھليل": 1,
    "تھلين": 1,
    "تھم": 1,
    "تھمة": 1,
    "تھميم": 1,
    "تھميمة": 1,
    "تھنئة": 1,
    "تھور": 1,
    "تھوية": 1,
    "تھويد": 1,
    "تھويش": 1,
    "تھويل": 1,
    "تھيأ": 1,
    "تھيؤ": 1,
    "تھيئة": 1,
    "تھيب": 1,
    "تھيج": 1,
    "تھييج": 1,
    "ثآليل": 1,
    "ثأب": 1,
    "ثأر": 1,
    "ثأره": 1,
    "ثأى": 1,
    "ثؤباء": 1,
    "ثؤلول": 1,
    "ثؤلولة": 1,
    "ثئب": 1,
    "ثاء": 1,
    "ثائرة": 1,
    "ثائرته": 1,
    "ثائره": 1,
    "ثاب": 1,
    "ثابتة": 1,
    "ثار": 1,
    "ثاغ": 1,
    "ثاغم": 1,
    "ثاغية": 1,
    "ثاقبات": 1,
    "ثاكلة": 1,
    "ثالب": 1,
    "ثالثة": 1,
    "ثالم": 1,
    "ثالوث": 1,
    "ثان": 1,
    "ثانوى": 1,
    "ثانى": 1,
    "ثبان": 1,
    "ثبر": 1,
    "ثبن": 1,
    "ثبنة": 1,
    "ثبوت": 1,
    "ثبور": 1,
    "ثج": 1,
    "ثجاج": 1,
    "ثخانة": 1,
    "ثخناء": 1,
    "ثخونة": 1,
    "ثخين": 1,
    "ثدى": 1,
    "ثر": 1,
    "ثراه": 1,
    "ثرب": 1,
    "ثرة": 1,
    "ثرثارة": 1,
    "ثرد": 1,
    "ثرم": 1,
    "ثرو": 1,
    "ثروة": 1,
    "ثروته": 1,
    "ثرى": 1,
    "ثري": 1,
    "ثريا": 1,
    "ثريات": 1,
    "ثرية": 1,
    "ثريد": 1,
    "ثعالة": 1,
    "ثعبانى": 1,
    "ثعلبة": 1,
    "ثعلبى": 1,
    "ثغاء": 1,
    "ثغر": 1,
    "ثغم": 1,
    "ثغو": 1,
    "ثغور": 1,
    "ثفر": 1,
    "ثفل": 1,
    "ثفن": 1,
    "ثفنة": 1,
    "ثقاب": 1,
    "ثقافى": 1,
    "ثقال": 1,
    "ثقبة": 1,
    "ثقته": 1,
    "ثقلاء": 1,
    "ثقلية": 1,
    "ثقيف": 1,
    "ثكل": 1,
    "ثكلان": 1,
    "ثكلى": 1,
    "ثكن": 1,
    "ثكنة": 1,
    "ثل": 1,
    "ثلاثا": 1,
    "ثلاثاء": 1,
    "ثلاثى": 1,
    "ثلاج": 1,
    "ثلاجة": 1,
    "ثلالا": 1,
    "ثلب": 1,
    "ثلجت": 1,
    "ثلجى": 1,
    "ثلل": 1,
    "ثلم": 1,
    "ثلمة": 1,
    "ثلوج": 1,
    "ثليج": 1,
    "ثمار": 1,
    "ثمالة": 1,
    "ثمام": 1,
    "ثمان": 1,
    "ثمت": 1,
    "ثمنة": 1,
    "ثناء": 1,
    "ثنائى": 1,
    "ثناد": 1,
    "ثنايا": 1,
    "ثنة": 1,
    "ثندوة": 1,
    "ثنن": 1,
    "ثنوى": 1,
    "ثنوية": 1,
    "ثنيا": 1,
    "ثنية": 1,
    "ثواء": 1,
    "ثوائر": 1,
    "ثوابت": 1,
    "ثوار": 1,
    "ثواكل": 1,
    "ثوان": 1,
    "ثوة": 1,
    "ثوران": 1,
    "ثورة": 1,
    "ثوروى": 1,
    "ثورى": 1,
    "ثوريوم": 1,
    "ثول": 1,
    "ثوم": 1,
    "ثوى": 1,
    "ثيابه": 1,
    "ثياتل": 1,
    "ثيب": 1,
    "ثيتل": 1,
    "جآجئ": 1,
    "جأءني": 1,
    "جأر": 1,
    "جأشا": 1,
    "جأشك": 1,
    "جأشه": 1,
    "جؤار": 1,
    "جؤجؤ": 1,
    "جاؤوا": 1,
    "جائح": 1,
    "جائحة": 1,
    "جائلون": 1,
    "جاب": 1,
    "جابر": 1,
    "جابية": 1,
    "جاث": 1,
    "جاثليق": 1,
    "جاثوم": 1,
    "جاح": 1,
    "جادة": 1,
    "جادت": 1,
    "جاذب": 1,
    "جاذبة": 1,
    "جاذبه": 1,
    "جاذي": 1,
    "جارة": 1,
    "جارحة": 1,
    "جارف": 1,
    "جارور": 1,
    "جارورة": 1,
    "جاروشة": 1,
    "جازت": 1,
    "جازع": 1,
    "جازف": 1,
    "جازون": 1,
    "جاس": 1,
    "جاسئ": 1,
    "جاسوسة": 1,
    "جاسوسى": 1,
    "جاسوسية": 1,
    "جاش": 1,
    "جاع": 1,
    "جاكتة": 1,
    "جاكته": 1,
    "جاكيته": 1,
    "جال": 1,
    "جالون": 1,
    "جالية": 1,
    "جاليرى": 1,
    "جام": 1,
    "جامعى": 1,
    "جامكية": 1,
    "جاموس": 1,
    "جاموسة": 1,
    "جانبه": 1,
    "جانبى": 1,
    "جانح": 1,
    "جانحة": 1,
    "جانرك": 1,
    "جاه": 1,
    "جاودار": 1,
    "جاول": 1,
    "جاوه": 1,
    "جاوى": 1,
    "جاويش": 1,
    "جاھز": 1,
    "جاھزة": 1,
    "جاھل": 1,
    "جاھلى": 1,
    "جاھلية": 1,
    "جب": 1,
    "جبائب": 1,
    "جبائر": 1,
    "جبائي": 1,
    "جباب": 1,
    "جبابر": 1,
    "جباة": 1,
    "جباح": 1,
    "جباسة": 1,
    "جبانة": 1,
    "جباه": 1,
    "جباية": 1,
    "جبب": 1,
    "جبة": 1,
    "جبح": 1,
    "جبخانة": 1,
    "جبر": 1,
    "جبرئيل": 1,
    "جبرا": 1,
    "جبروت": 1,
    "جبرى": 1,
    "جبرياء": 1,
    "جبرية": 1,
    "جبس": 1,
    "جبلاوى": 1,
    "جبلاية": 1,
    "جبلة": 1,
    "جبلى": 1,
    "جبناء": 1,
    "جبنة": 1,
    "جبه": 1,
    "جبور": 1,
    "جبى": 1,
    "جبيرة": 1,
    "جبين": 1,
    "جبينه": 1,
    "جبينى": 1,
    "جبھات": 1,
    "جبھة": 1,
    "جبھته": 1,
    "جث": 1,
    "جثا": 1,
    "جثالقة": 1,
    "جثام": 1,
    "جثث": 1,
    "جثل": 1,
    "جثليق": 1,
    "جثم": 1,
    "جثمانى": 1,
    "جثمة": 1,
    "جثو": 1,
    "جثوة": 1,
    "جثوم": 1,
    "جحاش": 1,
    "جحافل": 1,
    "جحد": 1,
    "جحش": 1,
    "جحشان": 1,
    "جحشة": 1,
    "جحظ": 1,
    "جحف": 1,
    "جحفل": 1,
    "جحود": 1,
    "جحوش": 1,
    "جحوظ": 1,
    "جحيمى": 1,
    "جخ": 1,
    "جخاخ": 1,
    "جداء": 1,
    "جدائل": 1,
    "جداجد": 1,
    "جدارى": 1,
    "جدب": 1,
    "جدباء": 1,
    "جدة": 1,
    "جدث": 1,
    "جدجد": 1,
    "جدراء": 1,
    "جدران": 1,
    "جدرى": 1,
    "جدع": 1,
    "جدعان": 1,
    "جدف": 1,
    "جدلى": 1,
    "جدو": 1,
    "جدوبة": 1,
    "جدود": 1,
    "جدى": 1,
    "جديا": 1,
    "جديان": 1,
    "جديب": 1,
    "جدية": 1,
    "جديلة": 1,
    "جذ": 1,
    "جذاء": 1,
    "جذاذات": 1,
    "جذاذة": 1,
    "جذام": 1,
    "جذامة": 1,
    "جذامى": 1,
    "جذبا": 1,
    "جذبه": 1,
    "جذرا": 1,
    "جذرى": 1,
    "جذعا": 1,
    "جذعة": 1,
    "جذعى": 1,
    "جذف": 1,
    "جذل": 1,
    "جذلان": 1,
    "جذم": 1,
    "جذمور": 1,
    "جذول": 1,
    "جذوم": 1,
    "جذى": 1,
    "جذيل": 1,
    "جرؤ": 1,
    "جرئ": 1,
    "جراءة": 1,
    "جرائد": 1,
    "جرائر": 1,
    "جراب": 1,
    "جرابزة": 1,
    "جرابندية": 1,
    "جرابيع": 1,
    "جراثيم": 1,
    "جراج": 1,
    "جراح": 1,
    "جراحى": 1,
    "جرادل": 1,
    "جراذين": 1,
    "جرارة": 1,
    "جراريف": 1,
    "جرافة": 1,
    "جراك": 1,
    "جراكسة": 1,
    "جرام": 1,
    "جرامز": 1,
    "جراميز": 1,
    "جراميزه": 1,
    "جران": 1,
    "جرانه": 1,
    "جرانيت": 1,
    "جرانيتى": 1,
    "جرانيل": 1,
    "جراية": 1,
    "جرب": 1,
    "جرباء": 1,
    "جربان": 1,
    "جربذة": 1,
    "جربز": 1,
    "جربزة": 1,
    "جربندية": 1,
    "جربوع": 1,
    "جرت": 1,
    "جرجرة": 1,
    "جرجير": 1,
    "جرح": 1,
    "جرحى": 1,
    "جرداء": 1,
    "جردل": 1,
    "جرده": 1,
    "جرذون": 1,
    "جرساية": 1,
    "جرسة": 1,
    "جرسى": 1,
    "جرسية": 1,
    "جرش": 1,
    "جرض": 1,
    "جرع": 1,
    "جركسى": 1,
    "جرمانى": 1,
    "جرن": 1,
    "جرنال": 1,
    "جروح": 1,
    "جروحات": 1,
    "جروسة": 1,
    "جروف": 1,
    "جروم": 1,
    "جرى": 1,
    "جري": 1,
    "جريا": 1,
    "جريب": 1,
    "جريد": 1,
    "جريرة": 1,
    "جريش": 1,
    "جريض": 1,
    "جريم": 1,
    "جرينش": 1,
    "جز": 1,
    "جزأ": 1,
    "جزئى": 1,
    "جزئية": 1,
    "جزائر": 1,
    "جزائرى": 1,
    "جزائز": 1,
    "جزائى": 1,
    "جزار": 1,
    "جزاز": 1,
    "جزازة": 1,
    "جزاف": 1,
    "جزافا": 1,
    "جزاك": 1,
    "جزال": 1,
    "جزالة": 1,
    "جزاه": 1,
    "جزاھم": 1,
    "جزة": 1,
    "جزدان": 1,
    "جزرة": 1,
    "جزرى": 1,
    "جزز": 1,
    "جزع": 1,
    "جزف": 1,
    "جزل": 1,
    "جزلان": 1,
    "جزماتى": 1,
    "جزمة": 1,
    "جزمجى": 1,
    "جزور": 1,
    "جزى": 1,
    "جزية": 1,
    "جزيل": 1,
    "جزيلا": 1,
    "جس": 1,
    "جسا": 1,
    "جسام": 1,
    "جسامة": 1,
    "جسدانى": 1,
    "جسدى": 1,
    "جسطن": 1,
    "جسمان": 1,
    "جسمانى": 1,
    "جسمانية": 1,
    "جسمه": 1,
    "جسمى": 1,
    "جسو": 1,
    "جسورة": 1,
    "جسوم": 1,
    "جسيم": 1,
    "جش": 1,
    "جشأ": 1,
    "جشأة": 1,
    "جشاء": 1,
    "جشار": 1,
    "جشامة": 1,
    "جشم": 1,
    "جشنى": 1,
    "جشيش": 1,
    "جص": 1,
    "جصطن": 1,
    "جعائل": 1,
    "جعاب": 1,
    "جعادة": 1,
    "جعالة": 1,
    "جعب": 1,
    "جعبة": 1,
    "جعة": 1,
    "جعجاع": 1,
    "جعجع": 1,
    "جعجعة": 1,
    "جعدة": 1,
    "جعدنة": 1,
    "جعدى": 1,
    "جعر": 1,
    "جعران": 1,
    "جعفر": 1,
    "جعلان": 1,
    "جعلى": 1,
    "جعودة": 1,
    "جعيدى": 1,
    "جغرافيا": 1,
    "جغرافية": 1,
    "جف": 1,
    "جفا": 1,
    "جفاة": 1,
    "جفاف": 1,
    "جفان": 1,
    "جفت": 1,
    "جفتجي": 1,
    "جفتلك": 1,
    "جفر": 1,
    "جفرة": 1,
    "جفل": 1,
    "جفن": 1,
    "جفنات": 1,
    "جفنة": 1,
    "جفنه": 1,
    "جفنيه": 1,
    "جفو": 1,
    "جفوة": 1,
    "جفوف": 1,
    "جفول": 1,
    "جفونه": 1,
    "جكر": 1,
    "جلا": 1,
    "جلاء": 1,
    "جلائل": 1,
    "جلاب": 1,
    "جلابيب": 1,
    "جلابية": 1,
    "جلاتين": 1,
    "جلاجل": 1,
    "جلاد": 1,
    "جلادة": 1,
    "جلاسية": 1,
    "جلاقة": 1,
    "جلال": 1,
    "جلالاء": 1,
    "جلالة": 1,
    "جلاليب": 1,
    "جلامد": 1,
    "جلاميد": 1,
    "جلايا": 1,
    "جلبا": 1,
    "جلباء": 1,
    "جلباب": 1,
    "جلبان": 1,
    "جلبب": 1,
    "جلبة": 1,
    "جلبى": 1,
    "جلة": 1,
    "جلجل": 1,
    "جلجلان": 1,
    "جلجلة": 1,
    "جلح": 1,
    "جلحاء": 1,
    "جلخ": 1,
    "جلداء": 1,
    "جلدتنا": 1,
    "جلده": 1,
    "جلدى": 1,
    "جلساء": 1,
    "جلسته": 1,
    "جلط": 1,
    "جلفط": 1,
    "جلفطة": 1,
    "جلفن": 1,
    "جلفنة": 1,
    "جلم": 1,
    "جلمبو": 1,
    "جلمد": 1,
    "جلمز": 1,
    "جلمود": 1,
    "جلنار": 1,
    "جله": 1,
    "جلو": 1,
    "جلوب": 1,
    "جلودة": 1,
    "جلوس": 1,
    "جلون": 1,
    "جلوى": 1,
    "جلى": 1,
    "جليا": 1,
    "جليان": 1,
    "جليب": 1,
    "جلية": 1,
    "جليدة": 1,
    "جليدى": 1,
    "جليس": 1,
    "جليسة": 1,
    "جليسه": 1,
    "جليلا": 1,
    "جليوتين": 1,
    "جم": 1,
    "جماجم": 1,
    "جماح": 1,
    "جماحه": 1,
    "جماد": 1,
    "جمادى": 1,
    "جمار": 1,
    "جماع": 1,
    "جماعى": 1,
    "جمام": 1,
    "جمان": 1,
    "جماھير": 1,
    "جمباز": 1,
    "جمبرى": 1,
    "جمبري": 1,
    "جمة": 1,
    "جمجم": 1,
    "جمجمة": 1,
    "جمجمى": 1,
    "جمح": 1,
    "جمدت": 1,
    "جمر": 1,
    "جمرة": 1,
    "جمرك": 1,
    "جمركى": 1,
    "جمركية": 1,
    "جمرى": 1,
    "جمري": 1,
    "جمش": 1,
    "جمعاء": 1,
    "جمعة": 1,
    "جمكية": 1,
    "جموح": 1,
    "جمود": 1,
    "جمودة": 1,
    "جموع": 1,
    "جميز": 1,
    "جميله": 1,
    "جمھر": 1,
    "جمھرة": 1,
    "جمھرى": 1,
    "جمھري": 1,
    "جمھور": 1,
    "جمھورية": 1,
    "جنائز": 1,
    "جنائن": 1,
    "جنائنى": 1,
    "جنائي": 1,
    "جنائية": 1,
    "جناب": 1,
    "جنابة": 1,
    "جنابكم": 1,
    "جنابية": 1,
    "جناة": 1,
    "جناحه": 1,
    "جنادب": 1,
    "جنادل": 1,
    "جناديل": 1,
    "جناز": 1,
    "جنازة": 1,
    "جنازير": 1,
    "جنان": 1,
    "جنانيز": 1,
    "جناية": 1,
    "جنبات": 1,
    "جنباته": 1,
    "جنباز": 1,
    "جنبازى": 1,
    "جنبازي": 1,
    "جنبة": 1,
    "جنبري": 1,
    "جنبى": 1,
    "جنبيه": 1,
    "جنح": 1,
    "جنحة": 1,
    "جنحي": 1,
    "جندارى": 1,
    "جندب": 1,
    "جندر": 1,
    "جندرة": 1,
    "جندرمة": 1,
    "جندرمى": 1,
    "جندفلى": 1,
    "جندل": 1,
    "جندول": 1,
    "جندى": 1,
    "جندية": 1,
    "جنز": 1,
    "جنزار": 1,
    "جنزبيل": 1,
    "جنزير": 1,
    "جنسنا": 1,
    "جنطيانا": 1,
    "جنف": 1,
    "جنفاص": 1,
    "جنفيص": 1,
    "جنك": 1,
    "جنن": 1,
    "جنوا": 1,
    "جنوبى": 1,
    "جنوح": 1,
    "جنوك": 1,
    "جنونه": 1,
    "جنى": 1,
    "جنية": 1,
    "جنين": 1,
    "جنينة": 1,
    "جنيه": 1,
    "جوا": 1,
    "جواء": 1,
    "جوائح": 1,
    "جوائز": 1,
    "جوائط": 1,
    "جوابا": 1,
    "جوابى": 1,
    "جوارح": 1,
    "جوارحه": 1,
    "جوارش": 1,
    "جواره": 1,
    "جوارير": 1,
    "جوازل": 1,
    "جوازم": 1,
    "جواسق": 1,
    "جواسيس": 1,
    "جوافة": 1,
    "جوال": 1,
    "جوالة": 1,
    "جوامد": 1,
    "جوامع": 1,
    "جوامك": 1,
    "جواميس": 1,
    "جوانبه": 1,
    "جوانبھم": 1,
    "جوانتي": 1,
    "جوانح": 1,
    "جوانحه": 1,
    "جوانحي": 1,
    "جوانحھا": 1,
    "جوانى": 1,
    "جواھر": 1,
    "جوب": 1,
    "جوبة": 1,
    "جوت": 1,
    "جوح": 1,
    "جوخ": 1,
    "جودار": 1,
    "جورب": 1,
    "جورة": 1,
    "جورى": 1,
    "جوري": 1,
    "جوزل": 1,
    "جوزي": 1,
    "جوسسة": 1,
    "جوسق": 1,
    "جوعان": 1,
    "جوعه": 1,
    "جوعى": 1,
    "جوف": 1,
    "جوفاء": 1,
    "جوفية": 1,
    "جوق": 1,
    "جوقة": 1,
    "جول": 1,
    "جولان": 1,
    "جولة": 1,
    "جولف": 1,
    "جونلا": 1,
    "جونلة": 1,
    "جونيلة": 1,
    "جوى": 1,
    "جويدار": 1,
    "جويطة": 1,
    "جوھر": 1,
    "جوھرة": 1,
    "جوھرجي": 1,
    "جوھري": 1,
    "جيئة": 1,
    "جياد": 1,
    "جيار": 1,
    "جيارة": 1,
    "جياش": 1,
    "جياع": 1,
    "جيبه": 1,
    "جيران": 1,
    "جيرة": 1,
    "جيرو": 1,
    "جيزة": 1,
    "جيشا": 1,
    "جيشان": 1,
    "جيفي": 1,
    "جيلاتي": 1,
    "جيوب": 1,
    "جيود": 1,
    "جيوش": 1,
    "جيوغرافيا": 1,
    "جيوفيزيا": 1,
    "جيوفيزيقي": 1,
    "جيولوجيا": 1,
    "جھابذة": 1,
    "جھات": 1,
    "جھاد": 1,
    "جھادي": 1,
    "جھار": 1,
    "جھارا": 1,
    "جھارة": 1,
    "جھاز": 1,
    "جھال": 1,
    "جھالة": 1,
    "جھام": 1,
    "جھامة": 1,
    "جھبذ": 1,
    "جھة": 1,
    "جھتي": 1,
    "جھد": 1,
    "جھدا": 1,
    "جھده": 1,
    "جھدي": 1,
    "جھر": 1,
    "جھرا": 1,
    "جھرة": 1,
    "جھري": 1,
    "جھز": 1,
    "جھش": 1,
    "جھشة": 1,
    "جھض": 1,
    "جھل": 1,
    "جھلاء": 1,
    "جھلة": 1,
    "جھم": 1,
    "جھنم": 1,
    "جھنمي": 1,
    "جھنمية": 1,
    "جھود": 1,
    "جھول": 1,
    "جھومة": 1,
    "جھوي": 1,
    "جھيد": 1,
    "جھير": 1,
    "جھيض": 1,
    "حؤول": 1,
    "حاء": 1,
    "حائد": 1,
    "حائرا": 1,
    "حائز": 1,
    "حائض": 1,
    "حائضة": 1,
    "حائط": 1,
    "حابل": 1,
    "حاثة": 1,
    "حاجب": 1,
    "حاجبيه": 1,
    "حاجته": 1,
    "حاجزة": 1,
    "حاجيات": 1,
    "حاخام": 1,
    "حادثة": 1,
    "حادر": 1,
    "حادق": 1,
    "حادى": 1,
    "حاذ": 1,
    "حارة": 1,
    "حارث": 1,
    "حارد": 1,
    "حارزة": 1,
    "حارض": 1,
    "حارقة": 1,
    "حارك": 1,
    "حارون": 1,
    "حاز": 1,
    "حازب": 1,
    "حازوقة": 1,
    "حاسب": 1,
    "حاسمة": 1,
    "حاشدة": 1,
    "حاشى": 1,
    "حاشية": 1,
    "حاص": 1,
    "حاصبة": 1,
    "حاصد": 1,
    "حاصدة": 1,
    "حاصلات": 1,
    "حاضت": 1,
    "حاضنة": 1,
    "حاط": 1,
    "حاطب": 1,
    "حاطمة": 1,
    "حاف": 1,
    "حافرة": 1,
    "حافرته": 1,
    "حافري": 1,
    "حافلة": 1,
    "حاق": 1,
    "حاكة": 1,
    "حاكما": 1,
    "حاكمية": 1,
    "حاكورة": 1,
    "حالب": 1,
    "حالق": 1,
    "حالك": 1,
    "حالما": 1,
    "حاله": 1,
    "حالوم": 1,
    "حالية": 1,
    "حالھا": 1,
    "حامت": 1,
    "حاملات": 1,
    "حاملة": 1,
    "حامى": 1,
    "حانبة": 1,
    "حانت": 1,
    "حانق": 1,
    "حانوت": 1,
    "حانوتي": 1,
    "حاو": 1,
    "حايد": 1,
    "حايل": 1,
    "حباء": 1,
    "حبائب": 1,
    "حبائل": 1,
    "حباب": 1,
    "حبات": 1,
    "حباحب": 1,
    "حبار": 1,
    "حباري": 1,
    "حباريات": 1,
    "حباكة": 1,
    "حبالة": 1,
    "حبالى": 1,
    "حبحب": 1,
    "حبذ": 1,
    "حبرة": 1,
    "حبري": 1,
    "حبرية": 1,
    "حبس": 1,
    "حبسا": 1,
    "حبساء": 1,
    "حبسة": 1,
    "حبق": 1,
    "حبك": 1,
    "حبكة": 1,
    "حبلانة": 1,
    "حبله": 1,
    "حبن": 1,
    "حبو": 1,
    "حبوة": 1,
    "حبور": 1,
    "حبوس": 1,
    "حبوط": 1,
    "حبول": 1,
    "حبي": 1,
    "حبيا": 1,
    "حبيبة": 1,
    "حبيس": 1,
    "حبھان": 1,
    "حت": 1,
    "حتات": 1,
    "حتار": 1,
    "حتة": 1,
    "حتد": 1,
    "حتر": 1,
    "حترة": 1,
    "حتف": 1,
    "حتفه": 1,
    "حتم": 1,
    "حتمية": 1,
    "حتوف": 1,
    "حتوم": 1,
    "حثا": 1,
    "حثو": 1,
    "حثيث": 1,
    "حجا": 1,
    "حجابة": 1,
    "حجاج": 1,
    "حجار": 1,
    "حجازي": 1,
    "حجال": 1,
    "حجام": 1,
    "حجامة": 1,
    "حجبة": 1,
    "حجدز": 1,
    "حجران": 1,
    "حجرصحي": 1,
    "حجزا": 1,
    "حجل": 1,
    "حجلان": 1,
    "حجلى": 1,
    "حجن": 1,
    "حجو": 1,
    "حجور": 1,
    "حجورة": 1,
    "حجول": 1,
    "حجوم": 1,
    "حجى": 1,
    "حجي": 1,
    "حجيج": 1,
    "حجير": 1,
    "حخام": 1,
    "حدآن": 1,
    "حدأ": 1,
    "حدأة": 1,
    "حداء": 1,
    "حدائج": 1,
    "حدائد": 1,
    "حداب": 1,
    "حداة": 1,
    "حداث": 1,
    "حداثة": 1,
    "حداجة": 1,
    "حدادة": 1,
    "حدارة": 1,
    "حداف": 1,
    "حدافة": 1,
    "حداق": 1,
    "حداية": 1,
    "حدباء": 1,
    "حدته": 1,
    "حدثا": 1,
    "حدثاء": 1,
    "حدثان": 1,
    "حدثته": 1,
    "حدثه": 1,
    "حدج": 1,
    "حدر": 1,
    "حدرا": 1,
    "حدقة": 1,
    "حدل": 1,
    "حدم": 1,
    "حده": 1,
    "حدو": 1,
    "حدوة": 1,
    "حدوج": 1,
    "حدور": 1,
    "حدول": 1,
    "حدى": 1,
    "حدي": 1,
    "حديثه": 1,
    "حديج": 1,
    "حديده": 1,
    "حذا": 1,
    "حذاري": 1,
    "حذاف": 1,
    "حذافير": 1,
    "حذاق": 1,
    "حذاقة": 1,
    "حذره": 1,
    "حذق": 1,
    "حذلق": 1,
    "حذلقة": 1,
    "حذو": 1,
    "حذوق": 1,
    "حذوك": 1,
    "حذوه": 1,
    "حرائر": 1,
    "حرائري": 1,
    "حرائق": 1,
    "حراب": 1,
    "حرابش": 1,
    "حرابى": 1,
    "حراث": 1,
    "حراثة": 1,
    "حراج": 1,
    "حراجة": 1,
    "حراذين": 1,
    "حرار": 1,
    "حرارى": 1,
    "حرازة": 1,
    "حراسة": 1,
    "حراشة": 1,
    "حراشف": 1,
    "حراص": 1,
    "حرافة": 1,
    "حراق": 1,
    "حراقة": 1,
    "حراقد": 1,
    "حراقف": 1,
    "حرامل": 1,
    "حرامي": 1,
    "حران": 1,
    "حراير": 1,
    "حربا": 1,
    "حرباء": 1,
    "حرباه": 1,
    "حربة": 1,
    "حربوشة": 1,
    "حربي": 1,
    "حرت": 1,
    "حرثة": 1,
    "حردان": 1,
    "حرذون": 1,
    "حرره": 1,
    "حرز": 1,
    "حرسة": 1,
    "حرش": 1,
    "حرشة": 1,
    "حرشف": 1,
    "حرصا": 1,
    "حرصاء": 1,
    "حرفا": 1,
    "حرفاء": 1,
    "حرفه": 1,
    "حرقان": 1,
    "حرقد": 1,
    "حرقفة": 1,
    "حركات": 1,
    "حركاته": 1,
    "حركته": 1,
    "حركث": 1,
    "حركش": 1,
    "حرمة": 1,
    "حرمته": 1,
    "حرمل": 1,
    "حرمه": 1,
    "حرن": 1,
    "حروب": 1,
    "حروة": 1,
    "حرور": 1,
    "حروز": 1,
    "حروش": 1,
    "حروم": 1,
    "حرون": 1,
    "حري": 1,
    "حريت": 1,
    "حريرة": 1,
    "حريز": 1,
    "حريفات": 1,
    "حريق": 1,
    "حريقة": 1,
    "حريم": 1,
    "حريمي": 1,
    "حز": 1,
    "حزائني": 1,
    "حزاز": 1,
    "حزازة": 1,
    "حزامة": 1,
    "حزانى": 1,
    "حزايني": 1,
    "حزبي": 1,
    "حزبية": 1,
    "حزة": 1,
    "حزر": 1,
    "حزقاني": 1,
    "حزماء": 1,
    "حزناء": 1,
    "حزنان": 1,
    "حزورة": 1,
    "حزوز": 1,
    "حزوقة": 1,
    "حزومة": 1,
    "حزون": 1,
    "حزيران": 1,
    "حزيم": 1,
    "حسا": 1,
    "حساء": 1,
    "حسابة": 1,
    "حساد": 1,
    "حساسي": 1,
    "حساسين": 1,
    "حسام": 1,
    "حسباء": 1,
    "حسباني": 1,
    "حسبة": 1,
    "حسبما": 1,
    "حسبى": 1,
    "حسبية": 1,
    "حسدة": 1,
    "حسر": 1,
    "حسران": 1,
    "حسرتاه": 1,
    "حسرتي": 1,
    "حسرى": 1,
    "حسك": 1,
    "حسكي": 1,
    "حسم": 1,
    "حسناء": 1,
    "حسه": 1,
    "حسو": 1,
    "حسوة": 1,
    "حسوم": 1,
    "حسون": 1,
    "حسيات": 1,
    "حسيب": 1,
    "حسيس": 1,
    "حشا": 1,
    "حشائش": 1,
    "حشاش": 1,
    "حشاشة": 1,
    "حشايا": 1,
    "حشرج": 1,
    "حشرجة": 1,
    "حشرية": 1,
    "حشف": 1,
    "حشك": 1,
    "حشم": 1,
    "حشماء": 1,
    "حشمة": 1,
    "حشود": 1,
    "حشية": 1,
    "حشيد": 1,
    "حشيش": 1,
    "حشيشي": 1,
    "حشيم": 1,
    "حص": 1,
    "حصائد": 1,
    "حصائر": 1,
    "حصائل": 1,
    "حصاة": 1,
    "حصاد": 1,
    "حصادة": 1,
    "حصافة": 1,
    "حصالبان": 1,
    "حصالة": 1,
    "حصانة": 1,
    "حصد": 1,
    "حصرم": 1,
    "حصص": 1,
    "حصف": 1,
    "حصوة": 1,
    "حصول": 1,
    "حصون": 1,
    "حصوي": 1,
    "حصى": 1,
    "حصيات": 1,
    "حصيد": 1,
    "حصيدة": 1,
    "حصيف": 1,
    "حصيلة": 1,
    "حض": 1,
    "حضائر": 1,
    "حضار": 1,
    "حضارم": 1,
    "حضان": 1,
    "حضانة": 1,
    "حضرة": 1,
    "حضرتكم": 1,
    "حضرته": 1,
    "حضرموت": 1,
    "حضرمي": 1,
    "حضض": 1,
    "حضن": 1,
    "حضور": 1,
    "حضوري": 1,
    "حضوريا": 1,
    "حضورية": 1,
    "حضون": 1,
    "حضيرة": 1,
    "حضيض": 1,
    "حضين": 1,
    "حطاب": 1,
    "حطب": 1,
    "حطمة": 1,
    "حطوط": 1,
    "حطيطة": 1,
    "حطيم": 1,
    "حظار": 1,
    "حظايا": 1,
    "حظه": 1,
    "حظو": 1,
    "حظوظ": 1,
    "حظية": 1,
    "حظيرته": 1,
    "حظيظ": 1,
    "حف": 1,
    "حفاء": 1,
    "حفائر": 1,
    "حفائظ": 1,
    "حفاة": 1,
    "حفاف": 1,
    "حفدة": 1,
    "حفريات": 1,
    "حفرية": 1,
    "حفظه": 1,
    "حفن": 1,
    "حفنة": 1,
    "حفو": 1,
    "حفيدة": 1,
    "حفير": 1,
    "حفيرة": 1,
    "حفيظة": 1,
    "حفيف": 1,
    "حفيل": 1,
    "حقاء": 1,
    "حقائد": 1,
    "حقائق": 1,
    "حقاب": 1,
    "حقاق": 1,
    "حقاني": 1,
    "حقانية": 1,
    "حقب": 1,
    "حقة": 1,
    "حقدة": 1,
    "حقراء": 1,
    "حقلي": 1,
    "حقنا": 1,
    "حقنة": 1,
    "حقنه": 1,
    "حقو": 1,
    "حقوقي": 1,
    "حقويه": 1,
    "حقي": 1,
    "حقيدة": 1,
    "حقيقته": 1,
    "حكاك": 1,
    "حكام": 1,
    "حكاية": 1,
    "حكة": 1,
    "حكر": 1,
    "حكرة": 1,
    "حكك": 1,
    "حكماء": 1,
    "حكمدار": 1,
    "حكمدارية": 1,
    "حكومية": 1,
    "حكى": 1,
    "حكيمباشي": 1,
    "حلائل": 1,
    "حلاب": 1,
    "حلابة": 1,
    "حلاج": 1,
    "حلاجة": 1,
    "حلاق": 1,
    "حلاقم": 1,
    "حلاليف": 1,
    "حلاوى": 1,
    "حلب": 1,
    "حلبي": 1,
    "حلة": 1,
    "حلت": 1,
    "حلتيت": 1,
    "حلج": 1,
    "حلحل": 1,
    "حلزونة": 1,
    "حلزونى": 1,
    "حلس": 1,
    "حلساء": 1,
    "حلفا": 1,
    "حلفاء": 1,
    "حلفة": 1,
    "حلقات": 1,
    "حلقوم": 1,
    "حلقى": 1,
    "حلك": 1,
    "حلكة": 1,
    "حلماء": 1,
    "حلمي": 1,
    "حلواء": 1,
    "حلوائي": 1,
    "حلوان": 1,
    "حلواني": 1,
    "حلوة": 1,
    "حلوس": 1,
    "حلوف": 1,
    "حلوق": 1,
    "حلوكة": 1,
    "حلوم": 1,
    "حلويات": 1,
    "حلى": 1,
    "حليج": 1,
    "حليف": 1,
    "حليفة": 1,
    "حليل": 1,
    "حليلة": 1,
    "حمأ": 1,
    "حمأة": 1,
    "حمئ": 1,
    "حماء": 1,
    "حمائر": 1,
    "حمائل": 1,
    "حمائم": 1,
    "حمارة": 1,
    "حماس": 1,
    "حماض": 1,
    "حماق": 1,
    "حماقى": 1,
    "حماه": 1,
    "حماوة": 1,
    "حمة": 1,
    "حمحم": 1,
    "حمحمة": 1,
    "حمد": 1,
    "حمدل": 1,
    "حمدلة": 1,
    "حمرة": 1,
    "حمري": 1,
    "حمز": 1,
    "حمش": 1,
    "حمشة": 1,
    "حمص": 1,
    "حمصية": 1,
    "حمق": 1,
    "حمقاء": 1,
    "حملان": 1,
    "حملايا": 1,
    "حملق": 1,
    "حملقة": 1,
    "حمله": 1,
    "حملي": 1,
    "حمم": 1,
    "حمو": 1,
    "حموات": 1,
    "حمود": 1,
    "حمور": 1,
    "حموضة": 1,
    "حمول": 1,
    "حموي": 1,
    "حميا": 1,
    "حميات": 1,
    "حمير": 1,
    "حميرة": 1,
    "حميض": 1,
    "حميل": 1,
    "حميلة": 1,
    "حن": 1,
    "حنأ": 1,
    "حناء": 1,
    "حنابلة": 1,
    "حناجر": 1,
    "حناجير": 1,
    "حنادس": 1,
    "حناطة": 1,
    "حنانة": 1,
    "حنانيك": 1,
    "حنايا": 1,
    "حناية": 1,
    "حنبلي": 1,
    "حنة": 1,
    "حنث": 1,
    "حنجل": 1,
    "حنجور": 1,
    "حندس": 1,
    "حندقوق": 1,
    "حنش": 1,
    "حنظل": 1,
    "حنف": 1,
    "حنفي": 1,
    "حنق": 1,
    "حنكة": 1,
    "حنكي": 1,
    "حنو": 1,
    "حنى": 1,
    "حني": 1,
    "حنية": 1,
    "حنيف": 1,
    "حنيفة": 1,
    "حوائج": 1,
    "حوائط": 1,
    "حوائل": 1,
    "حواة": 1,
    "حواجب": 1,
    "حواجل": 1,
    "حوار": 1,
    "حوارة": 1,
    "حوارى": 1,
    "حواري": 1,
    "حواس": 1,
    "حواسر": 1,
    "حواسه": 1,
    "حواش": 1,
    "حواصل": 1,
    "حواضر": 1,
    "حواضن": 1,
    "حوافز": 1,
    "حوافظ": 1,
    "حوافل": 1,
    "حوافيه": 1,
    "حواقل": 1,
    "حوال": 1,
    "حوالة": 1,
    "حوامض": 1,
    "حوامل": 1,
    "حوانب": 1,
    "حوانيت": 1,
    "حوانيھم": 1,
    "حوايا": 1,
    "حواية": 1,
    "حوب": 1,
    "حوباء": 1,
    "حوبة": 1,
    "حوج": 1,
    "حوجلة": 1,
    "حود": 1,
    "حودة": 1,
    "حوذ": 1,
    "حوذي": 1,
    "حوذية": 1,
    "حوراء": 1,
    "حوران": 1,
    "حوز": 1,
    "حوزة": 1,
    "حوزه": 1,
    "حوزي": 1,
    "حوس": 1,
    "حوش": 1,
    "حوشي": 1,
    "حوص": 1,
    "حوصاء": 1,
    "حوصل": 1,
    "حوصلة": 1,
    "حوطة": 1,
    "حوف": 1,
    "حوق": 1,
    "حوقل": 1,
    "حوقلة": 1,
    "حوك": 1,
    "حوليات": 1,
    "حوليھما": 1,
    "حوم": 1,
    "حومة": 1,
    "حوى": 1,
    "حوية": 1,
    "حويصل": 1,
    "حويصلة": 1,
    "حويضة": 1,
    "حويط": 1,
    "حوين": 1,
    "حى": 1,
    "حياته": 1,
    "حياتي": 1,
    "حياتھا": 1,
    "حياد": 1,
    "حيادي": 1,
    "حيارى": 1,
    "حيازة": 1,
    "حياصة": 1,
    "حياض": 1,
    "حياضه": 1,
    "حياط": 1,
    "حياطة": 1,
    "حياك": 1,
    "حياكة": 1,
    "حيال": 1,
    "حيتان": 1,
    "حيثية": 1,
    "حيدان": 1,
    "حيدة": 1,
    "حيدراباد": 1,
    "حيرى": 1,
    "حيزبون": 1,
    "حيشان": 1,
    "حيص": 1,
    "حيصة": 1,
    "حيضة": 1,
    "حيطان": 1,
    "حيطة": 1,
    "حيطته": 1,
    "حيف": 1,
    "حيفاء": 1,
    "حيق": 1,
    "حيك": 1,
    "حيل": 1,
    "حيله": 1,
    "حيلولة": 1,
    "حيلي": 1,
    "حينا": 1,
    "حينذاك": 1,
    "حينما": 1,
    "حيو": 1,
    "حيوات": 1,
    "حيوانية": 1,
    "حيود": 1,
    "حيويات": 1,
    "حيي": 1,
    "خؤول": 1,
    "خؤولة": 1,
    "خؤون": 1,
    "خاء": 1,
    "خائط": 1,
    "خابئة": 1,
    "خابور": 1,
    "خابية": 1,
    "خاتام": 1,
    "خاتر": 1,
    "خاتم": 1,
    "خاتمة": 1,
    "خاتون": 1,
    "خاثر": 1,
    "خاخام": 1,
    "خادر": 1,
    "خادرة": 1,
    "خادمية": 1,
    "خارب": 1,
    "خارجا": 1,
    "خارصين": 1,
    "خارصينى": 1,
    "خارطة": 1,
    "خازن": 1,
    "خازوق": 1,
    "خاس": 1,
    "خاسئ": 1,
    "خاش": 1,
    "خاشع": 1,
    "خاصا": 1,
    "خاصرة": 1,
    "خاصرته": 1,
    "خاض": 1,
    "خاط": 1,
    "خاطبة": 1,
    "خاطبه": 1,
    "خاطرك": 1,
    "خاطره": 1,
    "خاطف": 1,
    "خافرة": 1,
    "خافقي": 1,
    "خاقان": 1,
    "خاكى": 1,
    "خالب": 1,
    "خالة": 1,
    "خالج": 1,
    "خالجة": 1,
    "خالط": 1,
    "خالع": 1,
    "خالى": 1,
    "خامات": 1,
    "خانوق": 1,
    "خاو": 1,
    "خاوى": 1,
    "خبء": 1,
    "خبأ": 1,
    "خبا": 1,
    "خباء": 1,
    "خباز": 1,
    "خبازة": 1,
    "خبازى": 1,
    "خباص": 1,
    "خباط": 1,
    "خبال": 1,
    "خبايا": 1,
    "خبب": 1,
    "خبثاء": 1,
    "خبثة": 1,
    "خبر": 1,
    "خبره": 1,
    "خبرية": 1,
    "خبزة": 1,
    "خبص": 1,
    "خبصة": 1,
    "خبط": 1,
    "خبطة": 1,
    "خبو": 1,
    "خبيئة": 1,
    "خبياري": 1,
    "خبيب": 1,
    "خبيز": 1,
    "خبيص": 1,
    "خبيصة": 1,
    "ختام": 1,
    "ختان": 1,
    "ختانة": 1,
    "ختر": 1,
    "ختل": 1,
    "ختلا": 1,
    "ختما": 1,
    "ختمة": 1,
    "ختن": 1,
    "ختوم": 1,
    "خثار": 1,
    "خثارة": 1,
    "خثرة": 1,
    "خد": 1,
    "خدائع": 1,
    "خداج": 1,
    "خداعي": 1,
    "خدام": 1,
    "خدة": 1,
    "خدرة": 1,
    "خدعة": 1,
    "خدل": 1,
    "خدمتكم": 1,
    "خدمه": 1,
    "خدن": 1,
    "خده": 1,
    "خدود": 1,
    "خدور": 1,
    "خدوش": 1,
    "خديج": 1,
    "خديعة": 1,
    "خدين": 1,
    "خديو": 1,
    "خديوي": 1,
    "خذأ": 1,
    "خذاريف": 1,
    "خذره": 1,
    "خذروف": 1,
    "خذروفي": 1,
    "خذف": 1,
    "خذل": 1,
    "خذلان": 1,
    "خذو": 1,
    "خرء": 1,
    "خرئ": 1,
    "خراء": 1,
    "خرائب": 1,
    "خرائد": 1,
    "خراب": 1,
    "خرابة": 1,
    "خرابيش": 1,
    "خراجك": 1,
    "خراجي": 1,
    "خراسان": 1,
    "خراشيف": 1,
    "خراص": 1,
    "خراط": 1,
    "خراطة": 1,
    "خراطيش": 1,
    "خراطيم": 1,
    "خراطين": 1,
    "خراطيني": 1,
    "خراعة": 1,
    "خراف": 1,
    "خرافة": 1,
    "خرافيش": 1,
    "خرامة": 1,
    "خرانق": 1,
    "خربان": 1,
    "خربة": 1,
    "خربش": 1,
    "خربط": 1,
    "خربق": 1,
    "خربوش": 1,
    "خرت": 1,
    "خرتيت": 1,
    "خرجة": 1,
    "خرخر": 1,
    "خرد": 1,
    "خردة": 1,
    "خردجي": 1,
    "خردق": 1,
    "خردوات": 1,
    "خرز": 1,
    "خرس": 1,
    "خرساء": 1,
    "خرسان": 1,
    "خرسانة": 1,
    "خرشوف": 1,
    "خرص": 1,
    "خرصان": 1,
    "خرط": 1,
    "خرطال": 1,
    "خرطوش": 1,
    "خرطوشة": 1,
    "خرطوم": 1,
    "خرطيط": 1,
    "خرع": 1,
    "خرف": 1,
    "خرفان": 1,
    "خرفش": 1,
    "خرفوشة": 1,
    "خرقاء": 1,
    "خرماشة": 1,
    "خرنق": 1,
    "خرنوب": 1,
    "خروب": 1,
    "خروبة": 1,
    "خروت": 1,
    "خروجات": 1,
    "خرور": 1,
    "خروع": 1,
    "خروف": 1,
    "خروق": 1,
    "خروم": 1,
    "خريدة": 1,
    "خرير": 1,
    "خريع": 1,
    "خريف": 1,
    "خز": 1,
    "خزائم": 1,
    "خزازين": 1,
    "خزاف": 1,
    "خزافة": 1,
    "خزام": 1,
    "خزامة": 1,
    "خزامى": 1,
    "خزان": 1,
    "خزايا": 1,
    "خزاية": 1,
    "خزر": 1,
    "خزعبل": 1,
    "خزف": 1,
    "خزفي": 1,
    "خزق": 1,
    "خزل": 1,
    "خزم": 1,
    "خزنة": 1,
    "خزندار": 1,
    "خزوز": 1,
    "خزى": 1,
    "خزيا": 1,
    "خزيان": 1,
    "خزينة": 1,
    "خس": 1,
    "خسأ": 1,
    "خسئ": 1,
    "خسئت": 1,
    "خسائس": 1,
    "خسار": 1,
    "خسارة": 1,
    "خساسة": 1,
    "خسة": 1,
    "خستكة": 1,
    "خسران": 1,
    "خسف": 1,
    "خسفا": 1,
    "خسوف": 1,
    "خسيسة": 1,
    "خش": 1,
    "خشاب": 1,
    "خشاخيش": 1,
    "خشارة": 1,
    "خشاش": 1,
    "خشاف": 1,
    "خشان": 1,
    "خشايا": 1,
    "خشبة": 1,
    "خشت": 1,
    "خشخاش": 1,
    "خشخش": 1,
    "خشخشة": 1,
    "خشخشية": 1,
    "خشعة": 1,
    "خشكار": 1,
    "خشكريشة": 1,
    "خشم": 1,
    "خشناء": 1,
    "خشوت": 1,
    "خشوع": 1,
    "خشيا": 1,
    "خشيان": 1,
    "خصاء": 1,
    "خصائص": 1,
    "خصاص": 1,
    "خصاف": 1,
    "خصال": 1,
    "خصام": 1,
    "خصة": 1,
    "خصر": 1,
    "خصف": 1,
    "خصفة": 1,
    "خصل": 1,
    "خصم": 1,
    "خصماء": 1,
    "خصمه": 1,
    "خصه": 1,
    "خصور": 1,
    "خصوص": 1,
    "خصوصية": 1,
    "خصوم": 1,
    "خصومة": 1,
    "خصيان": 1,
    "خصيب": 1,
    "خصية": 1,
    "خصيصا": 1,
    "خصيصة": 1,
    "خضاب": 1,
    "خضار": 1,
    "خضارة": 1,
    "خضارم": 1,
    "خضب": 1,
    "خضة": 1,
    "خضخض": 1,
    "خضد": 1,
    "خضراوات": 1,
    "خضرم": 1,
    "خضري": 1,
    "خضعان": 1,
    "خضل": 1,
    "خضم": 1,
    "خضوب": 1,
    "خضوع": 1,
    "خضيب": 1,
    "خضير": 1,
    "خطء": 1,
    "خطئ": 1,
    "خطاء": 1,
    "خطابات": 1,
    "خطاة": 1,
    "خطار": 1,
    "خطاط": 1,
    "خطاطة": 1,
    "خطاطيف": 1,
    "خطام": 1,
    "خطاه": 1,
    "خطايا": 1,
    "خطباء": 1,
    "خطبتھا": 1,
    "خطبه": 1,
    "خطران": 1,
    "خطرف": 1,
    "خطرفة": 1,
    "خطفا": 1,
    "خطفات": 1,
    "خطفة": 1,
    "خطل": 1,
    "خطم": 1,
    "خطمي": 1,
    "خطه": 1,
    "خطو": 1,
    "خطوبة": 1,
    "خطوتان": 1,
    "خطور": 1,
    "خطى": 1,
    "خطيب": 1,
    "خطيبة": 1,
    "خطيف": 1,
    "خفاء": 1,
    "خفارة": 1,
    "خفاش": 1,
    "خفاف": 1,
    "خفافش": 1,
    "خفاق": 1,
    "خفان": 1,
    "خفايا": 1,
    "خفر": 1,
    "خفراء": 1,
    "خفس": 1,
    "خفش": 1,
    "خفشاء": 1,
    "خفق": 1,
    "خفقان": 1,
    "خفقة": 1,
    "خفوق": 1,
    "خفى": 1,
    "خفير": 1,
    "خل": 1,
    "خلاء": 1,
    "خلائق": 1,
    "خلاب": 1,
    "خلابة": 1,
    "خلابصة": 1,
    "خلابيص": 1,
    "خلاج": 1,
    "خلاخل": 1,
    "خلاخيل": 1,
    "خلاسي": 1,
    "خلاص": 1,
    "خلاصة": 1,
    "خلاط": 1,
    "خلاع": 1,
    "خلاعة": 1,
    "خلافا": 1,
    "خلافة": 1,
    "خلافه": 1,
    "خلافھم": 1,
    "خلاقة": 1,
    "خلاقين": 1,
    "خلالة": 1,
    "خلان": 1,
    "خلب": 1,
    "خلبص": 1,
    "خلبوص": 1,
    "خلبي": 1,
    "خلج": 1,
    "خلجان": 1,
    "خلجة": 1,
    "خلخال": 1,
    "خلده": 1,
    "خلس": 1,
    "خلسه": 1,
    "خلصاء": 1,
    "خلطاء": 1,
    "خلطة": 1,
    "خلعاء": 1,
    "خلعة": 1,
    "خلعه": 1,
    "خلفھا": 1,
    "خلقاء": 1,
    "خلقان": 1,
    "خلقاني": 1,
    "خلقة": 1,
    "خلقية": 1,
    "خلقين": 1,
    "خلنج": 1,
    "خلنجان": 1,
    "خلوة": 1,
    "خلود": 1,
    "خلوص": 1,
    "خلوق": 1,
    "خلون": 1,
    "خلى": 1,
    "خليص": 1,
    "خليع": 1,
    "خليق": 1,
    "خليقة": 1,
    "خليقون": 1,
    "خليل": 1,
    "خليلة": 1,
    "خم": 1,
    "خمائر": 1,
    "خمائل": 1,
    "خمار": 1,
    "خمارة": 1,
    "خماس": 1,
    "خماسة": 1,
    "خماسين": 1,
    "خماشة": 1,
    "خمة": 1,
    "خمج": 1,
    "خمد": 1,
    "خمرة": 1,
    "خمري": 1,
    "خمرية": 1,
    "خمش": 1,
    "خمص": 1,
    "خمع": 1,
    "خمل": 1,
    "خملة": 1,
    "خمود": 1,
    "خموش": 1,
    "خموع": 1,
    "خموم": 1,
    "خمير": 1,
    "خميس": 1,
    "خميسة": 1,
    "خميص": 1,
    "خميلة": 1,
    "خنا": 1,
    "خناء": 1,
    "خناث": 1,
    "خناثى": 1,
    "خنادق": 1,
    "خناصر": 1,
    "خنافس": 1,
    "خناق": 1,
    "خناقة": 1,
    "خنانيص": 1,
    "خنة": 1,
    "خنث": 1,
    "خنثى": 1,
    "خنجر": 1,
    "خنخن": 1,
    "خندق": 1,
    "خندقا": 1,
    "خنزب": 1,
    "خنزوانية": 1,
    "خنس": 1,
    "خنساء": 1,
    "خنشار": 1,
    "خنصر": 1,
    "خنع": 1,
    "خنف": 1,
    "خنفر": 1,
    "خنفس": 1,
    "خنقة": 1,
    "خنو": 1,
    "خنوثة": 1,
    "خنوص": 1,
    "خنوع": 1,
    "خنى": 1,
    "خني": 1,
    "خنين": 1,
    "خواء": 1,
    "خواب": 1,
    "خوابئ": 1,
    "خوابير": 1,
    "خواتم": 1,
    "خواتيم": 1,
    "خواتين": 1,
    "خواجة": 1,
    "خوازق": 1,
    "خوازيق": 1,
    "خواصة": 1,
    "خواصر": 1,
    "خواطئ": 1,
    "خواطر": 1,
    "خواطف": 1,
    "خواف": 1,
    "خواقين": 1,
    "خوال": 1,
    "خوالج": 1,
    "خوالد": 1,
    "خوانق": 1,
    "خوانيق": 1,
    "خوة": 1,
    "خوجة": 1,
    "خوخ": 1,
    "خوخة": 1,
    "خوذ": 1,
    "خور": 1,
    "خورس": 1,
    "خورنة": 1,
    "خورى": 1,
    "خوري": 1,
    "خوشق": 1,
    "خوص": 1,
    "خوصة": 1,
    "خول": 1,
    "خولة": 1,
    "خولط": 1,
    "خولع": 1,
    "خولى": 1,
    "خون": 1,
    "خونة": 1,
    "خوى": 1,
    "خوي": 1,
    "خويصة": 1,
    "خويف": 1,
    "خيار": 1,
    "خياري": 1,
    "خيازر": 1,
    "خياشم": 1,
    "خياشيم": 1,
    "خياض": 1,
    "خيالة": 1,
    "خياله": 1,
    "خيام": 1,
    "خية": 1,
    "خيدع": 1,
    "خيران": 1,
    "خيرة": 1,
    "خيرى": 1,
    "خيرية": 1,
    "خيزران": 1,
    "خيزرانة": 1,
    "خيس": 1,
    "خيسان": 1,
    "خيش": 1,
    "خيشوم": 1,
    "خيطان": 1,
    "خيلاء": 1,
    "خيلان": 1,
    "خيلت": 1,
    "خيلولة": 1,
    "خيمه": 1,
    "خيور": 1,
    "خيوله": 1,
    "دئب": 1,
    "دائب": 1,
    "دائن": 1,
    "داج": 1,
    "داجن": 1,
    "داجنة": 1,
    "داحة": 1,
    "داحس": 1,
    "داخ": 1,
    "داخلا": 1,
    "داخلة": 1,
    "داخنة": 1,
    "دادة": 1,
    "دارة": 1,
    "دارت": 1,
    "دارسه": 1,
    "دارع": 1,
    "دارعة": 1,
    "دارفلفل": 1,
    "دارنا": 1,
    "داره": 1,
    "دارى": 1,
    "داسر": 1,
    "داغ": 1,
    "داغصة": 1,
    "داغل": 1,
    "داف": 1,
    "دافعة": 1,
    "دافعوا": 1,
    "داقرة": 1,
    "دالت": 1,
    "دالف": 1,
    "دام": 1,
    "داما": 1,
    "دامجانة": 1,
    "دامس": 1,
    "دامغة": 1,
    "دانتيلا": 1,
    "دانق": 1,
    "دانمارك": 1,
    "دانماركى": 1,
    "داه": 1,
    "داورية": 1,
    "داية": 1,
    "داينه": 1,
    "داھية": 1,
    "دباب": 1,
    "دبابة": 1,
    "دبابيج": 1,
    "دباديب": 1,
    "دبارة": 1,
    "دباغ": 1,
    "دببة": 1,
    "دبة": 1,
    "دبج": 1,
    "دبدب": 1,
    "دبدبة": 1,
    "دبدوبة": 1,
    "دبرة": 1,
    "دبري": 1,
    "دبش": 1,
    "دبغ": 1,
    "دبك": 1,
    "دبكة": 1,
    "دبل": 1,
    "دبلة": 1,
    "دبلوماسية": 1,
    "دبلومة": 1,
    "دبور": 1,
    "دبيب": 1,
    "دبير": 1,
    "دثار": 1,
    "دثر": 1,
    "دثور": 1,
    "دج": 1,
    "دجا": 1,
    "دجاجلة": 1,
    "دجال": 1,
    "دجالة": 1,
    "دجة": 1,
    "دججه": 1,
    "دجر": 1,
    "دجل": 1,
    "دجنة": 1,
    "دجنه": 1,
    "دجو": 1,
    "دجى": 1,
    "دجيج": 1,
    "دحا": 1,
    "دحداح": 1,
    "دحدح": 1,
    "دحر": 1,
    "دحرج": 1,
    "دحس": 1,
    "دحض": 1,
    "دحو": 1,
    "دحور": 1,
    "دحوض": 1,
    "دخائل": 1,
    "دخاخني": 1,
    "دخس": 1,
    "دخلاء": 1,
    "دخلة": 1,
    "دخلته": 1,
    "دخمس": 1,
    "دخمسة": 1,
    "دخنة": 1,
    "دخولي": 1,
    "دخولية": 1,
    "دخيل": 1,
    "دخيلة": 1,
    "دخيلك": 1,
    "دخينة": 1,
    "ددن": 1,
    "ددى": 1,
    "درء": 1,
    "درأ": 1,
    "درابزين": 1,
    "درابيس": 1,
    "دراج": 1,
    "درارع": 1,
    "دراريج": 1,
    "درازين": 1,
    "دراس": 1,
    "دراسى": 1,
    "دراعة": 1,
    "دراق": 1,
    "دراك": 1,
    "دراكا": 1,
    "درامة": 1,
    "درامي": 1,
    "دراويش": 1,
    "دراية": 1,
    "دراھم": 1,
    "درب": 1,
    "درباس": 1,
    "دربة": 1,
    "دربس": 1,
    "دربكة": 1,
    "درة": 1,
    "درجته": 1,
    "درد": 1,
    "دردار": 1,
    "دردبيس": 1,
    "دردرة": 1,
    "دردش": 1,
    "دردور": 1,
    "دردى": 1,
    "درر": 1,
    "درز": 1,
    "درزى": 1,
    "درزين": 1,
    "درسا": 1,
    "درف": 1,
    "درفة": 1,
    "درفيل": 1,
    "درق": 1,
    "درقة": 1,
    "درقي": 1,
    "درك": 1,
    "دركة": 1,
    "درم": 1,
    "درمان": 1,
    "درن": 1,
    "درنة": 1,
    "دره": 1,
    "درواس": 1,
    "دروب": 1,
    "دروة": 1,
    "دروج": 1,
    "دروز": 1,
    "دروسا": 1,
    "دروسه": 1,
    "دروع": 1,
    "درى": 1,
    "دريئة": 1,
    "درياق": 1,
    "دريبة": 1,
    "دريس": 1,
    "دريھمات": 1,
    "درھم": 1,
    "دسائس": 1,
    "دساتير": 1,
    "دساس": 1,
    "دساكر": 1,
    "دسام": 1,
    "دسامة": 1,
    "دست": 1,
    "دستة": 1,
    "دسر": 1,
    "دسكر": 1,
    "دسماء": 1,
    "دسمبر": 1,
    "دسو": 1,
    "دسوت": 1,
    "دسومة": 1,
    "دسيسة": 1,
    "دش": 1,
    "دشت": 1,
    "دشن": 1,
    "دشو": 1,
    "دشيش": 1,
    "دشيشة": 1,
    "دعائم": 1,
    "دعاب": 1,
    "دعابة": 1,
    "دعاة": 1,
    "دعار": 1,
    "دعارة": 1,
    "دعاو": 1,
    "دعاوة": 1,
    "دعاوى": 1,
    "دعب": 1,
    "دعبل": 1,
    "دعة": 1,
    "دعج": 1,
    "دعجاء": 1,
    "دعر": 1,
    "دعس": 1,
    "دعك": 1,
    "دعمة": 1,
    "دعنا": 1,
    "دعني": 1,
    "دعو": 1,
    "دعوات": 1,
    "دغال": 1,
    "دغر": 1,
    "دغرى": 1,
    "دغش": 1,
    "دغص": 1,
    "دغل": 1,
    "دغم": 1,
    "دغيشة": 1,
    "دف": 1,
    "دفآن": 1,
    "دفأى": 1,
    "دفؤ": 1,
    "دفئ": 1,
    "دفاء": 1,
    "دفاءة": 1,
    "دفائن": 1,
    "دفاع": 1,
    "دفاق": 1,
    "دفاية": 1,
    "دفة": 1,
    "دفترخانة": 1,
    "دفتيريا": 1,
    "دفر": 1,
    "دفس": 1,
    "دفعات": 1,
    "دفعه": 1,
    "دفق": 1,
    "دفقات": 1,
    "دفقة": 1,
    "دفلى": 1,
    "دفناء": 1,
    "دفوق": 1,
    "دفيء": 1,
    "دفية": 1,
    "دفيف": 1,
    "دفين": 1,
    "دفينة": 1,
    "دقائق": 1,
    "دقاق": 1,
    "دقاقة": 1,
    "دقت": 1,
    "دقشوم": 1,
    "دقع": 1,
    "دقق": 1,
    "دقل": 1,
    "دك": 1,
    "دكانجي": 1,
    "دكتاتورى": 1,
    "دكتاتورية": 1,
    "دكتوراة": 1,
    "دكريتات": 1,
    "دكريتو": 1,
    "دكك": 1,
    "دكن": 1,
    "دكناء": 1,
    "دكه": 1,
    "دكوك": 1,
    "دلاء": 1,
    "دلائل": 1,
    "دلاع": 1,
    "دلافين": 1,
    "دلال": 1,
    "دلالة": 1,
    "دلاية": 1,
    "دلة": 1,
    "دلتا": 1,
    "دلج": 1,
    "دلح": 1,
    "دلدل": 1,
    "دلدول": 1,
    "دلس": 1,
    "دلع": 1,
    "دلغان": 1,
    "دلف": 1,
    "دلفان": 1,
    "دلق": 1,
    "دلك": 1,
    "دله": 1,
    "دلو": 1,
    "دلوح": 1,
    "دلوف": 1,
    "دلوك": 1,
    "دلھم": 1,
    "دمائع": 1,
    "دماث": 1,
    "دماثة": 1,
    "دمار": 1,
    "دماسة": 1,
    "دماع": 1,
    "دمالج": 1,
    "دمام": 1,
    "دمامي": 1,
    "دماميس": 1,
    "دماميل": 1,
    "دمان": 1,
    "دمث": 1,
    "دمجانة": 1,
    "دمس": 1,
    "دمع": 1,
    "دمعة": 1,
    "دمعه": 1,
    "دمعى": 1,
    "دمعية": 1,
    "دمغ": 1,
    "دمغة": 1,
    "دمقراطي": 1,
    "دمقراطية": 1,
    "دمقس": 1,
    "دمقسي": 1,
    "دمل": 1,
    "دملان": 1,
    "دملج": 1,
    "دملي": 1,
    "دمن": 1,
    "دمنة": 1,
    "دمنھور": 1,
    "دمه": 1,
    "دمور": 1,
    "دموري": 1,
    "دموس": 1,
    "دمى": 1,
    "دمي": 1,
    "دمياط": 1,
    "دميرة": 1,
    "دميع": 1,
    "دميم": 1,
    "دنأ": 1,
    "دنؤ": 1,
    "دناءة": 1,
    "دناجل": 1,
    "دنادشة": 1,
    "دنان": 1,
    "دنانير": 1,
    "دناوة": 1,
    "دنايا": 1,
    "دناية": 1,
    "دنتلا": 1,
    "دنتلة": 1,
    "دنجل": 1,
    "دندرمه": 1,
    "دندي": 1,
    "دنساء": 1,
    "دنف": 1,
    "دنقلة": 1,
    "دنمرك": 1,
    "دنو": 1,
    "دنوءة": 1,
    "دنى": 1,
    "دني": 1,
    "دنيء": 1,
    "دنياوي": 1,
    "دنية": 1,
    "دنين": 1,
    "دوء": 1,
    "دوائي": 1,
    "دواب": 1,
    "دواة": 1,
    "دواجن": 1,
    "دواخل": 1,
    "دواخن": 1,
    "دوارس": 1,
    "دوارع": 1,
    "دوارق": 1,
    "دواع": 1,
    "دواعي": 1,
    "دواغص": 1,
    "دوافع": 1,
    "دوافق": 1,
    "دواقر": 1,
    "دوال": 1,
    "دوالف": 1,
    "دواليب": 1,
    "دواليك": 1,
    "دوام": 1,
    "دواما": 1,
    "دوانق": 1,
    "دواه": 1,
    "دواوين": 1,
    "دواية": 1,
    "دوبارة": 1,
    "دوبرة": 1,
    "دوبية": 1,
    "دوبيت": 1,
    "دوح": 1,
    "دوحة": 1,
    "دوخ": 1,
    "دودية": 1,
    "دوره": 1,
    "دورى": 1,
    "دوزان": 1,
    "دوزن": 1,
    "دوزنة": 1,
    "دوزينة": 1,
    "دوسنتاريا": 1,
    "دوسنطاريا": 1,
    "دوسية": 1,
    "دوسيه": 1,
    "دوش": 1,
    "دوشة": 1,
    "دوطة": 1,
    "دوق": 1,
    "دوقة": 1,
    "دوقية": 1,
    "دوك": 1,
    "دولب": 1,
    "دولية": 1,
    "دوم": 1,
    "دومان": 1,
    "دومانجي": 1,
    "دومج": 1,
    "دومرى": 1,
    "دونم": 1,
    "دونھا": 1,
    "دونھم": 1,
    "دويات": 1,
    "دويبة": 1,
    "دويلات": 1,
    "دياج": 1,
    "دياجير": 1,
    "ديادبة": 1,
    "ديار": 1,
    "ديارات": 1,
    "دياري": 1,
    "ديارھم": 1,
    "دياس": 1,
    "ديالوج": 1,
    "دياميس": 1,
    "ديانة": 1,
    "ديانم": 1,
    "ديباج": 1,
    "ديباجة": 1,
    "دية": 1,
    "ديجور": 1,
    "ديجوري": 1,
    "ديدبان": 1,
    "ديدن": 1,
    "ديرانى": 1,
    "ديراني": 1,
    "ديرة": 1,
    "ديرى": 1,
    "ديسة": 1,
    "ديسم": 1,
    "ديسمبر": 1,
    "ديشين": 1,
    "ديكة": 1,
    "ديماس": 1,
    "ديمة": 1,
    "ديمقراطي": 1,
    "ديمقراطية": 1,
    "ديموس": 1,
    "ديموطيقي": 1,
    "ديموقراطية": 1,
    "ديمومة": 1,
    "دينار": 1,
    "ديناري": 1,
    "دينامو": 1,
    "ديناميت": 1,
    "دينم": 1,
    "دينونة": 1,
    "ديوان": 1,
    "ديواني": 1,
    "ديوث": 1,
    "ديورة": 1,
    "ديوس": 1,
    "ديوم": 1,
    "دھاء": 1,
    "دھاة": 1,
    "دھاق": 1,
    "دھاقنة": 1,
    "دھاقين": 1,
    "دھاليز": 1,
    "دھان": 1,
    "دھر": 1,
    "دھري": 1,
    "دھس": 1,
    "دھش": 1,
    "دھشة": 1,
    "دھق": 1,
    "دھقان": 1,
    "دھك": 1,
    "دھلز": 1,
    "دھلي": 1,
    "دھليز": 1,
    "دھم": 1,
    "دھماء": 1,
    "دھمة": 1,
    "دھن": 1,
    "دھناء": 1,
    "دھنيات": 1,
    "دھور": 1,
    "دھون": 1,
    "دھي": 1,
    "دھياء": 1,
    "دھينة": 1,
    "ذأب": 1,
    "ذؤابة": 1,
    "ذؤبان": 1,
    "ذئاب": 1,
    "ذائد": 1,
    "ذابت": 1,
    "ذاتا": 1,
    "ذاته": 1,
    "ذاتى": 1,
    "ذاتھا": 1,
    "ذاد": 1,
    "ذادة": 1,
    "ذاع": 1,
    "ذاق": 1,
    "ذاكر": 1,
    "ذاكرته": 1,
    "ذال": 1,
    "ذاو": 1,
    "ذاھب": 1,
    "ذاھل": 1,
    "ذبائح": 1,
    "ذباح": 1,
    "ذبالة": 1,
    "ذبان": 1,
    "ذبانة": 1,
    "ذبحة": 1,
    "ذبذب": 1,
    "ذبيح": 1,
    "ذبيحة": 1,
    "ذحل": 1,
    "ذحول": 1,
    "ذخائر": 1,
    "ذخر": 1,
    "ذخيرة": 1,
    "ذر": 1,
    "ذرا": 1,
    "ذرائر": 1,
    "ذرائع": 1,
    "ذراح": 1,
    "ذراري": 1,
    "ذراريح": 1,
    "ذرب": 1,
    "ذرع": 1,
    "ذرعان": 1,
    "ذرف": 1,
    "ذرفان": 1,
    "ذرو": 1,
    "ذرور": 1,
    "ذروري": 1,
    "ذروف": 1,
    "ذرى": 1,
    "ذريرة": 1,
    "ذريع": 1,
    "ذريعا": 1,
    "ذريعة": 1,
    "ذريف": 1,
    "ذعاف": 1,
    "ذعر": 1,
    "ذعق": 1,
    "ذعن": 1,
    "ذفر": 1,
    "ذقنه": 1,
    "ذقون": 1,
    "ذقونھم": 1,
    "ذكا": 1,
    "ذكاء": 1,
    "ذكاءه": 1,
    "ذكران": 1,
    "ذكرة": 1,
    "ذكو": 1,
    "ذكى": 1,
    "ذكير": 1,
    "ذل": 1,
    "ذلاذل": 1,
    "ذلالة": 1,
    "ذلذل": 1,
    "ذلف": 1,
    "ذلفاء": 1,
    "ذلق": 1,
    "ذلل": 1,
    "ذليق": 1,
    "ذماء": 1,
    "ذمائم": 1,
    "ذمار": 1,
    "ذمام": 1,
    "ذمته": 1,
    "ذمتي": 1,
    "ذمر": 1,
    "ذمم": 1,
    "ذمي": 1,
    "ذميم": 1,
    "ذميمة": 1,
    "ذنبي": 1,
    "ذنيب": 1,
    "ذوائب": 1,
    "ذوابة": 1,
    "ذود": 1,
    "ذووه": 1,
    "ذوى": 1,
    "ذياد": 1,
    "ذيع": 1,
    "ذيله": 1,
    "ذيوع": 1,
    "ذھاب": 1,
    "ذھابا": 1,
    "ذھب": 1,
    "ذھبوا": 1,
    "ذھبي": 1,
    "ذھبية": 1,
    "ذھل": 1,
    "ذھن": 1,
    "ذھنه": 1,
    "ذھني": 1,
    "ذھنية": 1,
    "ذھوب": 1,
    "ذھول": 1,
    "رء": 1,
    "رءوم": 1,
    "رآسة": 1,
    "رآفة": 1,
    "رأب": 1,
    "رأرأ": 1,
    "رأسمالية": 1,
    "رأف": 1,
    "رأم": 1,
    "رأيا": 1,
    "رأيته": 1,
    "رأيه": 1,
    "رأيي": 1,
    "رأيھم": 1,
    "رؤبة": 1,
    "رؤد": 1,
    "رؤس": 1,
    "رؤف": 1,
    "رؤوسھم": 1,
    "رؤوف": 1,
    "رؤى": 1,
    "رؤي": 1,
    "رؤيا": 1,
    "رئات": 1,
    "رئة": 1,
    "رئد": 1,
    "رئم": 1,
    "رئمان": 1,
    "رئون": 1,
    "رئيسا": 1,
    "راء": 1,
    "رائب": 1,
    "رائح": 1,
    "رائق": 1,
    "رائية": 1,
    "راب": 1,
    "رابة": 1,
    "رابحة": 1,
    "رابعه": 1,
    "رابغ": 1,
    "رابور": 1,
    "راتب": 1,
    "راتينة": 1,
    "راتينج": 1,
    "راث": 1,
    "راجح": 1,
    "راجل": 1,
    "راحلة": 1,
    "راحلته": 1,
    "راحيل": 1,
    "راخ": 1,
    "رادار": 1,
    "راديكال": 1,
    "راديوفونية": 1,
    "راديولوجي": 1,
    "راديوم": 1,
    "راز": 1,
    "راس": 1,
    "راسب": 1,
    "راش": 1,
    "راشد": 1,
    "راشن": 1,
    "راصد": 1,
    "راصدة": 1,
    "راض": 1,
    "راضع": 1,
    "راطب": 1,
    "راع": 1,
    "راعب": 1,
    "راعف": 1,
    "راعني": 1,
    "راعى": 1,
    "راغ": 1,
    "راغم": 1,
    "راغية": 1,
    "رافد": 1,
    "رافدة": 1,
    "رافضة": 1,
    "رافضي": 1,
    "رافيا": 1,
    "راقد": 1,
    "راقوبة": 1,
    "راقود": 1,
    "رال": 1,
    "رام": 1,
    "راموز": 1,
    "راموس": 1,
    "رامية": 1,
    "راو": 1,
    "راوده": 1,
    "راوند": 1,
    "راووق": 1,
    "راوية": 1,
    "راى": 1,
    "راية": 1,
    "رايته": 1,
    "راھب": 1,
    "راھبة": 1,
    "راھل": 1,
    "راھن": 1,
    "ربأ": 1,
    "رباء": 1,
    "ربائب": 1,
    "رباب": 1,
    "ربابة": 1,
    "ربابنة": 1,
    "ربابيح": 1,
    "رباح": 1,
    "رباطه": 1,
    "رباع": 1,
    "رباق": 1,
    "ربالة": 1,
    "ربان": 1,
    "ربايا": 1,
    "ربت": 1,
    "ربد": 1,
    "ربص": 1,
    "ربض": 1,
    "ربعة": 1,
    "ربعي": 1,
    "ربق": 1,
    "ربقة": 1,
    "ربل": 1,
    "ربلة": 1,
    "ربلي": 1,
    "ربو": 1,
    "ربوب": 1,
    "ربوبية": 1,
    "ربورتاج": 1,
    "ربوع": 1,
    "ربوى": 1,
    "ربى": 1,
    "ربيئة": 1,
    "ربيب": 1,
    "ربيبة": 1,
    "ربيل": 1,
    "رت": 1,
    "رتا": 1,
    "رتاء": 1,
    "رتائج": 1,
    "رتائم": 1,
    "رتائن": 1,
    "رتاج": 1,
    "رتاع": 1,
    "رتام": 1,
    "رتباء": 1,
    "رتج": 1,
    "رتع": 1,
    "رتق": 1,
    "رتك": 1,
    "رتكان": 1,
    "رتم": 1,
    "رتمة": 1,
    "رتو": 1,
    "رتوش": 1,
    "رتوع": 1,
    "رتوق": 1,
    "رتى": 1,
    "رتيلاء": 1,
    "رتيمة": 1,
    "رتينة": 1,
    "رتينج": 1,
    "رثا": 1,
    "رثاء": 1,
    "رثاث": 1,
    "رثاه": 1,
    "رثة": 1,
    "رثو": 1,
    "رثوثة": 1,
    "رثي": 1,
    "رثية": 1,
    "رثيث": 1,
    "رج": 1,
    "رجأ": 1,
    "رجاة": 1,
    "رجاج": 1,
    "رجاحة": 1,
    "رجاس": 1,
    "رجاسة": 1,
    "رجاف": 1,
    "رجالات": 1,
    "رجالة": 1,
    "رجالي": 1,
    "رجام": 1,
    "رجاه": 1,
    "رجب": 1,
    "رجة": 1,
    "رجحان": 1,
    "رجراج": 1,
    "رجرج": 1,
    "رجعع": 1,
    "رجف": 1,
    "رجفان": 1,
    "رجفة": 1,
    "رجله": 1,
    "رجليه": 1,
    "رجم": 1,
    "رجمة": 1,
    "رجو": 1,
    "رجوح": 1,
    "رجولية": 1,
    "رجي": 1,
    "رجية": 1,
    "رجيع": 1,
    "رحاب": 1,
    "رحابة": 1,
    "رحال": 1,
    "رحالة": 1,
    "رحاله": 1,
    "رحالھا": 1,
    "رحاوى": 1,
    "رحبا": 1,
    "رحبة": 1,
    "رحراح": 1,
    "رحرح": 1,
    "رحض": 1,
    "رحلوا": 1,
    "رحمك": 1,
    "رحمى": 1,
    "رحوم": 1,
    "رحوى": 1,
    "رحى": 1,
    "رحيب": 1,
    "رحيل": 1,
    "رخ": 1,
    "رخا": 1,
    "رخاخ": 1,
    "رخاصة": 1,
    "رخام": 1,
    "رخاوة": 1,
    "رخخة": 1,
    "رخم": 1,
    "رخمت": 1,
    "رخو": 1,
    "رخي": 1,
    "ردء": 1,
    "ردأ": 1,
    "ردؤ": 1,
    "ردا": 1,
    "رداءة": 1,
    "رداف": 1,
    "ردة": 1,
    "ردح": 1,
    "ردحا": 1,
    "ردس": 1,
    "ردع": 1,
    "ردغة": 1,
    "ردم": 1,
    "ردن": 1,
    "ردنجوت": 1,
    "رده": 1,
    "ردى": 1,
    "ردي": 1,
    "رديفة": 1,
    "رديم": 1,
    "ردينى": 1,
    "ردھة": 1,
    "ردھم": 1,
    "رذ": 1,
    "رذائل": 1,
    "رذالة": 1,
    "رذت": 1,
    "رذل": 1,
    "رذلاء": 1,
    "رذول": 1,
    "رذيل": 1,
    "رز": 1,
    "رزء": 1,
    "رزأ": 1,
    "رزئ": 1,
    "رزاح": 1,
    "رزانة": 1,
    "رزايا": 1,
    "رزب": 1,
    "رزة": 1,
    "رزح": 1,
    "رزن": 1,
    "رزنامة": 1,
    "رزوح": 1,
    "رزيئة": 1,
    "رزية": 1,
    "رسامة": 1,
    "رساميل": 1,
    "رسب": 1,
    "رست": 1,
    "رستامية": 1,
    "رستق": 1,
    "رستو": 1,
    "رستوران": 1,
    "رسراس": 1,
    "رسف": 1,
    "رسلاء": 1,
    "رسمال": 1,
    "رسميات": 1,
    "رسن": 1,
    "رسو": 1,
    "رسوب": 1,
    "رسوخ": 1,
    "رسولي": 1,
    "رسولية": 1,
    "رسومات": 1,
    "رسى": 1,
    "رسيس": 1,
    "رسيل": 1,
    "رسيلات": 1,
    "رسيلاته": 1,
    "رشاء": 1,
    "رشاحة": 1,
    "رشاد": 1,
    "رشاشة": 1,
    "رشاقة": 1,
    "رشاوى": 1,
    "رشة": 1,
    "رشد": 1,
    "رشداء": 1,
    "رشراش": 1,
    "رشرش": 1,
    "رشف": 1,
    "رشفة": 1,
    "رشق": 1,
    "رشم": 1,
    "رشمة": 1,
    "رشو": 1,
    "رشوة": 1,
    "رشوم": 1,
    "رشومات": 1,
    "رشى": 1,
    "رص": 1,
    "رصاد": 1,
    "رصاصة": 1,
    "رصافة": 1,
    "رصانة": 1,
    "رصدخانة": 1,
    "رصف": 1,
    "رصفاء": 1,
    "رصن": 1,
    "رصيص": 1,
    "رصيفة": 1,
    "رصيفتنا": 1,
    "رضا": 1,
    "رضاء": 1,
    "رضائع": 1,
    "رضاب": 1,
    "رضاة": 1,
    "رضاع": 1,
    "رضاعة": 1,
    "رضخ": 1,
    "رضخة": 1,
    "رضراض": 1,
    "رضرض": 1,
    "رضعاء": 1,
    "رضه": 1,
    "رضوخ": 1,
    "رضوض": 1,
    "رضيحة": 1,
    "رضيض": 1,
    "رضيع": 1,
    "رطاب": 1,
    "رطابة": 1,
    "رطانة": 1,
    "رطل": 1,
    "رطم": 1,
    "رطن": 1,
    "رطوبة": 1,
    "رطيب": 1,
    "رطينى": 1,
    "رعا": 1,
    "رعاء": 1,
    "رعائى": 1,
    "رعائية": 1,
    "رعاد": 1,
    "رعاديد": 1,
    "رعارع": 1,
    "رعاع": 1,
    "رعاف": 1,
    "رعال": 1,
    "رعام": 1,
    "رعان": 1,
    "رعاوى": 1,
    "رعايا": 1,
    "رعب": 1,
    "رعدة": 1,
    "رعديد": 1,
    "رعراع": 1,
    "رعرع": 1,
    "رعش": 1,
    "رعشة": 1,
    "رعص": 1,
    "رعف": 1,
    "رعلة": 1,
    "رعمسيس": 1,
    "رعن": 1,
    "رعنته": 1,
    "رعو": 1,
    "رعوة": 1,
    "رعود": 1,
    "رعونة": 1,
    "رعوى": 1,
    "رعوية": 1,
    "رعيا": 1,
    "رعيان": 1,
    "رعية": 1,
    "رعيف": 1,
    "رعيل": 1,
    "رغا": 1,
    "رغاء": 1,
    "رغائب": 1,
    "رغادة": 1,
    "رغام": 1,
    "رغامى": 1,
    "رغاو": 1,
    "رغاوة": 1,
    "رغب": 1,
    "رغبته": 1,
    "رغث": 1,
    "رغد": 1,
    "رغرغ": 1,
    "رغف": 1,
    "رغفان": 1,
    "رغما": 1,
    "رغو": 1,
    "رغوث": 1,
    "رغيبة": 1,
    "رغيد": 1,
    "رغيف": 1,
    "رفأ": 1,
    "رفا": 1,
    "رفاء": 1,
    "رفائع": 1,
    "رفادة": 1,
    "رفارف": 1,
    "رفاريف": 1,
    "رفاس": 1,
    "رفاص": 1,
    "رفاع": 1,
    "رفاف": 1,
    "رفاه": 1,
    "رفاھة": 1,
    "رفاھية": 1,
    "رفت": 1,
    "رفتية": 1,
    "رفح": 1,
    "رفد": 1,
    "رفروف": 1,
    "رفس": 1,
    "رفش": 1,
    "رفعة": 1,
    "رفعنا": 1,
    "رفق": 1,
    "رفقة": 1,
    "رفل": 1,
    "رفه": 1,
    "رفو": 1,
    "رفود": 1,
    "رفيض": 1,
    "رفيف": 1,
    "رفيق": 1,
    "رفيقة": 1,
    "رقأ": 1,
    "رقاء": 1,
    "رقاب": 1,
    "رقابھم": 1,
    "رقاة": 1,
    "رقاد": 1,
    "رقارق": 1,
    "رقاص": 1,
    "رقاصة": 1,
    "رقاع": 1,
    "رقاعة": 1,
    "رقاق": 1,
    "رقب": 1,
    "رقباء": 1,
    "رقبتھا": 1,
    "رقبى": 1,
    "رقد": 1,
    "رقدة": 1,
    "رقراق": 1,
    "رقرق": 1,
    "رقش": 1,
    "رقط": 1,
    "رقما": 1,
    "رقوب": 1,
    "رقوبة": 1,
    "رقوص": 1,
    "رقوق": 1,
    "رقى": 1,
    "رقي": 1,
    "رقية": 1,
    "رقيع": 1,
    "رقيقة": 1,
    "رقيم": 1,
    "رك": 1,
    "ركائب": 1,
    "ركائز": 1,
    "ركابه": 1,
    "ركاز": 1,
    "ركاض": 1,
    "ركاك": 1,
    "ركاكة": 1,
    "ركام": 1,
    "ركبان": 1,
    "ركبة": 1,
    "ركبتاه": 1,
    "ركبتيه": 1,
    "ركة": 1,
    "ركد": 1,
    "ركزان": 1,
    "ركزة": 1,
    "ركس": 1,
    "ركعة": 1,
    "رككة": 1,
    "ركل": 1,
    "ركلة": 1,
    "ركم": 1,
    "ركنى": 1,
    "ركنية": 1,
    "ركوبة": 1,
    "ركوة": 1,
    "ركود": 1,
    "ركوض": 1,
    "ركوع": 1,
    "ركون": 1,
    "ركيك": 1,
    "ركين": 1,
    "رم": 1,
    "رمائم": 1,
    "رماح": 1,
    "رماد": 1,
    "رماك": 1,
    "رمال": 1,
    "رمام": 1,
    "رمان": 1,
    "رمانة": 1,
    "رماه": 1,
    "رمايا": 1,
    "رمة": 1,
    "رمث": 1,
    "رمح": 1,
    "رمد": 1,
    "رمدي": 1,
    "رمزيات": 1,
    "رمس": 1,
    "رمشة": 1,
    "رمص": 1,
    "رمض": 1,
    "رمضاء": 1,
    "رمق": 1,
    "رمقه": 1,
    "رمك": 1,
    "رمكاء": 1,
    "رمكة": 1,
    "رموز": 1,
    "رموس": 1,
    "رمي": 1,
    "رميا": 1,
    "رنا": 1,
    "رنانة": 1,
    "رنة": 1,
    "رنح": 1,
    "رندح": 1,
    "رنق": 1,
    "رنم": 1,
    "رنو": 1,
    "رنى": 1,
    "رواء": 1,
    "روائح": 1,
    "روائس": 1,
    "روائع": 1,
    "رواب": 1,
    "روابط": 1,
    "رواتب": 1,
    "رواج": 1,
    "رواح": 1,
    "رواحل": 1,
    "روادع": 1,
    "رواس": 1,
    "رواسب": 1,
    "رواسم": 1,
    "رواشن": 1,
    "رواغ": 1,
    "روافد": 1,
    "روافض": 1,
    "رواق": 1,
    "رواقه": 1,
    "رواقيد": 1,
    "روال": 1,
    "روامز": 1,
    "روايا": 1,
    "روبابيكا": 1,
    "روبصة": 1,
    "روبل": 1,
    "روبية": 1,
    "روتانية": 1,
    "روحانية": 1,
    "روحة": 1,
    "روحى": 1,
    "روحية": 1,
    "روحھم": 1,
    "روز": 1,
    "روزنامة": 1,
    "روستو": 1,
    "روسم": 1,
    "روشن": 1,
    "روعك": 1,
    "روعه": 1,
    "روغ": 1,
    "روغان": 1,
    "روق": 1,
    "روقة": 1,
    "روم": 1,
    "روماتزم": 1,
    "رومة": 1,
    "رومي": 1,
    "رومية": 1,
    "روند": 1,
    "رونق": 1,
    "روى": 1,
    "روية": 1,
    "رويغة": 1,
    "رياء": 1,
    "رياح": 1,
    "رياحين": 1,
    "ريادة": 1,
    "رياسة": 1,
    "رياسي": 1,
    "رياش": 1,
    "رياضات": 1,
    "رياع": 1,
    "رية": 1,
    "ريحان": 1,
    "ريحة": 1,
    "ريحه": 1,
    "ريخ": 1,
    "ريضان": 1,
    "ريعان": 1,
    "ريفى": 1,
    "ريق": 1,
    "ريقة": 1,
    "ريقه": 1,
    "ريل": 1,
    "ريم": 1,
    "رين": 1,
    "ريوع": 1,
    "رھا": 1,
    "رھاء": 1,
    "رھائن": 1,
    "رھاط": 1,
    "رھافة": 1,
    "رھام": 1,
    "رھان": 1,
    "رھب": 1,
    "رھبان": 1,
    "رھبانية": 1,
    "رھبة": 1,
    "رھبن": 1,
    "رھبنة": 1,
    "رھبوب": 1,
    "رھبى": 1,
    "رھج": 1,
    "رھط": 1,
    "رھف": 1,
    "رھق": 1,
    "رھل": 1,
    "رھم": 1,
    "رھمة": 1,
    "رھن": 1,
    "رھنا": 1,
    "رھنية": 1,
    "رھو": 1,
    "رھوان": 1,
    "رھون": 1,
    "رھونات": 1,
    "رھيب": 1,
    "رھيف": 1,
    "رھين": 1,
    "رھينة": 1,
    "رھينه": 1,
    "زأر": 1,
    "زأط": 1,
    "زؤام": 1,
    "زؤان": 1,
    "زئاط": 1,
    "زئبق": 1,
    "زئير": 1,
    "زاء": 1,
    "زاؤوق": 1,
    "زائر": 1,
    "زائرة": 1,
    "زائغة": 1,
    "زائل": 1,
    "زاج": 1,
    "زاجر": 1,
    "زاجرة": 1,
    "زاجورا": 1,
    "زاح": 1,
    "زاخر": 1,
    "زاده": 1,
    "زار": 1,
    "زاره": 1,
    "زاروب": 1,
    "زاعولة": 1,
    "زاف": 1,
    "زام": 1,
    "زانة": 1,
    "زاننية": 1,
    "زاه": 1,
    "زاول": 1,
    "زاووق": 1,
    "زاي": 1,
    "زايل": 1,
    "زاھر": 1,
    "زب": 1,
    "زباء": 1,
    "زبائن": 1,
    "زباد": 1,
    "زبادي": 1,
    "زباطة": 1,
    "زبال": 1,
    "زبالة": 1,
    "زبانة": 1,
    "زبانى": 1,
    "زبانيا": 1,
    "زبانية": 1,
    "زبدية": 1,
    "زبرة": 1,
    "زبرج": 1,
    "زبرجد": 1,
    "زبط": 1,
    "زبع": 1,
    "زبق": 1,
    "زبل": 1,
    "زبلة": 1,
    "زبور": 1,
    "زبون": 1,
    "زبى": 1,
    "زبيب": 1,
    "زبية": 1,
    "زت": 1,
    "زج": 1,
    "زجا": 1,
    "زجاء": 1,
    "زجال": 1,
    "زجر": 1,
    "زجري": 1,
    "زجل": 1,
    "زحار": 1,
    "زحافة": 1,
    "زحام": 1,
    "زحر": 1,
    "زحزحة": 1,
    "زحلاوي": 1,
    "زحلقة": 1,
    "زحم": 1,
    "زحمة": 1,
    "زحوف": 1,
    "زحول": 1,
    "زحير": 1,
    "زخار": 1,
    "زخة": 1,
    "زخر": 1,
    "زخم": 1,
    "زخمة": 1,
    "زخور": 1,
    "زرائف": 1,
    "زرابى": 1,
    "زرابيل": 1,
    "زرابين": 1,
    "زرازير": 1,
    "زراع": 1,
    "زرافات": 1,
    "زرافى": 1,
    "زراية": 1,
    "زرب": 1,
    "زربول": 1,
    "زربون": 1,
    "زربية": 1,
    "زرة": 1,
    "زرد": 1,
    "زردة": 1,
    "زردية": 1,
    "زرزر": 1,
    "زرزور": 1,
    "زرزوري": 1,
    "زرق": 1,
    "زرقاء": 1,
    "زرقون": 1,
    "زرنيخ": 1,
    "زرود": 1,
    "زرور": 1,
    "زروع": 1,
    "زرى": 1,
    "زريعة": 1,
    "زعابيط": 1,
    "زعارة": 1,
    "زعارير": 1,
    "زعازع": 1,
    "زعاف": 1,
    "زعامة": 1,
    "زعانف": 1,
    "زعبر": 1,
    "زعبوط": 1,
    "زعتر": 1,
    "زعج": 1,
    "زعر": 1,
    "زعراء": 1,
    "زعران": 1,
    "زعرور": 1,
    "زعزعان": 1,
    "زعزعة": 1,
    "زعزوع": 1,
    "زعط": 1,
    "زعف": 1,
    "زعفران": 1,
    "زعق": 1,
    "زعقة": 1,
    "زعل": 1,
    "زعلان": 1,
    "زعماء": 1,
    "زعمھم": 1,
    "زعنفة": 1,
    "زعيق": 1,
    "زغاريد": 1,
    "زغاريط": 1,
    "زغاليل": 1,
    "زغبر": 1,
    "زغد": 1,
    "زغر": 1,
    "زغرد": 1,
    "زغردة": 1,
    "زغرط": 1,
    "زغزغ": 1,
    "زغطة": 1,
    "زغل": 1,
    "زغلل": 1,
    "زغلول": 1,
    "زف": 1,
    "زفاف": 1,
    "زفة": 1,
    "زفت": 1,
    "زفر": 1,
    "زفرة": 1,
    "زفزفة": 1,
    "زفن": 1,
    "زفوف": 1,
    "زفيف": 1,
    "زق": 1,
    "زقا": 1,
    "زقاء": 1,
    "زقائل": 1,
    "زقاق": 1,
    "زقان": 1,
    "زقزاق": 1,
    "زقزق": 1,
    "زقزقة": 1,
    "زقلة": 1,
    "زقلية": 1,
    "زقم": 1,
    "زقو": 1,
    "زقيلة": 1,
    "زكا": 1,
    "زكاء": 1,
    "زكائب": 1,
    "زكام": 1,
    "زكانة": 1,
    "زكب": 1,
    "زكر": 1,
    "زكرة": 1,
    "زكم": 1,
    "زكمة": 1,
    "زكو": 1,
    "زكوات": 1,
    "زكوب": 1,
    "زكوة": 1,
    "زكي": 1,
    "زكيبة": 1,
    "زل": 1,
    "زلابية": 1,
    "زلازل": 1,
    "زلاعيم": 1,
    "زلاقة": 1,
    "زلال": 1,
    "زلالم": 1,
    "زلاليات": 1,
    "زلالية": 1,
    "زلت": 1,
    "زلج": 1,
    "زلزال": 1,
    "زلزل": 1,
    "زلط": 1,
    "زلع": 1,
    "زلعة": 1,
    "زلعوم": 1,
    "زلف": 1,
    "زلقة": 1,
    "زلل": 1,
    "زلم": 1,
    "زلوج": 1,
    "زلومة": 1,
    "زليج": 1,
    "زليجي": 1,
    "زليف": 1,
    "زمارة": 1,
    "زمازم": 1,
    "زمالة": 1,
    "زمام": 1,
    "زمامير": 1,
    "زمان": 1,
    "زمانة": 1,
    "زمانه": 1,
    "زماني": 1,
    "زمانية": 1,
    "زمت": 1,
    "زمجر": 1,
    "زمرة": 1,
    "زمرد": 1,
    "زمردى": 1,
    "زمزم": 1,
    "زمزمة": 1,
    "زمزمية": 1,
    "زمط": 1,
    "زمع": 1,
    "زمل": 1,
    "زملة": 1,
    "زمنا": 1,
    "زمنة": 1,
    "زمنى": 1,
    "زمنية": 1,
    "زمور": 1,
    "زمير": 1,
    "زميلة": 1,
    "زمين": 1,
    "زمھر": 1,
    "زمھرير": 1,
    "زنأ": 1,
    "زنا": 1,
    "زناء": 1,
    "زناببيل": 1,
    "زنابق": 1,
    "زنابك": 1,
    "زنابير": 1,
    "زناة": 1,
    "زناجير": 1,
    "زناد": 1,
    "زنادقة": 1,
    "زنار": 1,
    "زنارة": 1,
    "زنانير": 1,
    "زنبر": 1,
    "زنبرك": 1,
    "زنبق": 1,
    "زنبلك": 1,
    "زنبور": 1,
    "زنبيل": 1,
    "زنة": 1,
    "زنتاري": 1,
    "زنج": 1,
    "زنجار": 1,
    "زنجبار": 1,
    "زنجبيل": 1,
    "زنجر": 1,
    "زنجفر": 1,
    "زنجير": 1,
    "زنخ": 1,
    "زند": 1,
    "زندق": 1,
    "زندقة": 1,
    "زنر": 1,
    "زنزانة": 1,
    "زنطارية": 1,
    "زنق": 1,
    "زنقة": 1,
    "زنك": 1,
    "زنلخت": 1,
    "زنمردة": 1,
    "زنوج": 1,
    "زنود": 1,
    "زنى": 1,
    "زوائد": 1,
    "زوابع": 1,
    "زواجا": 1,
    "زواجر": 1,
    "زواحف": 1,
    "زواد": 1,
    "زوادة": 1,
    "زوار": 1,
    "زوارب": 1,
    "زواق": 1,
    "زوالي": 1,
    "زوان": 1,
    "زوبعة": 1,
    "زوجية": 1,
    "زوجھا": 1,
    "زوح": 1,
    "زوراء": 1,
    "زورة": 1,
    "زوغ": 1,
    "زوغان": 1,
    "زوفاء": 1,
    "زوفى": 1,
    "زوق": 1,
    "زول": 1,
    "زولوجي": 1,
    "زولوجيا": 1,
    "زولية": 1,
    "زوم": 1,
    "زوى": 1,
    "زيا": 1,
    "زيات": 1,
    "زياح": 1,
    "زيار": 1,
    "زيارة": 1,
    "زيانة": 1,
    "زيبق": 1,
    "زيتون": 1,
    "زيتوني": 1,
    "زيتونية": 1,
    "زيتية": 1,
    "زيج": 1,
    "زيجة": 1,
    "زيح": 1,
    "زيحان": 1,
    "زيز": 1,
    "زيزان": 1,
    "زيزفون": 1,
    "زيغان": 1,
    "زيق": 1,
    "زينت": 1,
    "زينكو": 1,
    "زيوف": 1,
    "زيى": 1,
    "زھا": 1,
    "زھاء": 1,
    "زھاد": 1,
    "زھادة": 1,
    "زھار": 1,
    "زھد": 1,
    "زھر": 1,
    "زھراوى": 1,
    "زھرة": 1,
    "زھرةا": 1,
    "زھرى": 1,
    "زھرية": 1,
    "زھف": 1,
    "زھق": 1,
    "زھقت": 1,
    "زھم": 1,
    "زھمة": 1,
    "زھو": 1,
    "زھور": 1,
    "زھوف": 1,
    "زھوق": 1,
    "زھومة": 1,
    "زھى": 1,
    "زھيد": 1,
    "سآمة": 1,
    "سأال": 1,
    "سأسباب": 1,
    "سألة": 1,
    "سأله": 1,
    "سأم": 1,
    "سؤالا": 1,
    "سؤدد": 1,
    "سؤر": 1,
    "سؤرة": 1,
    "سؤل": 1,
    "سؤلة": 1,
    "سؤله": 1,
    "سؤول": 1,
    "سؤوم": 1,
    "سئر": 1,
    "سئم": 1,
    "سائح": 1,
    "سائس": 1,
    "سائغة": 1,
    "سائلية": 1,
    "سائمة": 1,
    "ساب": 1,
    "ساباط": 1,
    "سابحات": 1,
    "سابحة": 1,
    "سابغ": 1,
    "سابقية": 1,
    "سابل": 1,
    "سابلة": 1,
    "ساتان": 1,
    "ساج": 1,
    "ساجات": 1,
    "ساجع": 1,
    "ساجله": 1,
    "ساح": 1,
    "ساحب": 1,
    "ساحته": 1,
    "ساحلية": 1,
    "ساخ": 1,
    "ساخت": 1,
    "سادات": 1,
    "سادة": 1,
    "سادر": 1,
    "سادن": 1,
    "سارب": 1,
    "ساربة": 1,
    "سارقة": 1,
    "سارقه": 1,
    "ساروخ": 1,
    "سارى": 1,
    "ساري": 1,
    "ساس": 1,
    "ساسة": 1,
    "ساط": 1,
    "ساطور": 1,
    "ساع": 1,
    "ساعاتي": 1,
    "ساعتئذ": 1,
    "ساعته": 1,
    "ساعتين": 1,
    "ساعدة": 1,
    "ساعده": 1,
    "ساعى": 1,
    "ساغ": 1,
    "ساغب": 1,
    "ساغو": 1,
    "سافل": 1,
    "سافياء": 1,
    "ساقة": 1,
    "ساقطة": 1,
    "ساقه": 1,
    "ساقية": 1,
    "ساقيه": 1,
    "ساقھا": 1,
    "ساك": 1,
    "ساكت": 1,
    "ساكف": 1,
    "ساكنه": 1,
    "ساكو": 1,
    "ساكوه": 1,
    "سالفا": 1,
    "سالما": 1,
    "سامد": 1,
    "سامر": 1,
    "سامع": 1,
    "سامق": 1,
    "سامه": 1,
    "سانح": 1,
    "سانحة": 1,
    "سانية": 1,
    "ساه": 1,
    "ساھد": 1,
    "ساھر": 1,
    "ساھرة": 1,
    "ساھل": 1,
    "ساھم": 1,
    "ساھيا": 1,
    "سبأ": 1,
    "سبا": 1,
    "سباء": 1,
    "سبائب": 1,
    "سبائخ": 1,
    "سبائك": 1,
    "سباب": 1,
    "سبابة": 1,
    "سبابيط": 1,
    "سباخ": 1,
    "سبار": 1,
    "سباسب": 1,
    "سباط": 1,
    "سباطة": 1,
    "سباع": 1,
    "سباعي": 1,
    "سباك": 1,
    "سباكة": 1,
    "سبال": 1,
    "سبانخ": 1,
    "سبايا": 1,
    "سبايس": 1,
    "سباھلة": 1,
    "سباھى": 1,
    "سباھي": 1,
    "سببية": 1,
    "سبة": 1,
    "سبت": 1,
    "سبتمبر": 1,
    "سبج": 1,
    "سبحاء": 1,
    "سبحات": 1,
    "سبحة": 1,
    "سبحلة": 1,
    "سبخ": 1,
    "سبخة": 1,
    "سبر": 1,
    "سبس": 1,
    "سبسب": 1,
    "سبط": 1,
    "سبعوني": 1,
    "سبغ": 1,
    "سبقة": 1,
    "سبقه": 1,
    "سبلان": 1,
    "سبلة": 1,
    "سبنسة": 1,
    "سبه": 1,
    "سبوح": 1,
    "سبور": 1,
    "سبورة": 1,
    "سبوطة": 1,
    "سبوع": 1,
    "سبوعة": 1,
    "سبى": 1,
    "سبي": 1,
    "سبيب": 1,
    "سبية": 1,
    "سبيخ": 1,
    "سبيداج": 1,
    "سبيذاج": 1,
    "سبھللا": 1,
    "ست": 1,
    "ستائر": 1,
    "ستجابة": 1,
    "ستر": 1,
    "سترة": 1,
    "ستري": 1,
    "ستف": 1,
    "سته": 1,
    "ستور": 1,
    "ستونى": 1,
    "سجا": 1,
    "سجائر": 1,
    "سجاجيد": 1,
    "سجاد": 1,
    "سجادة": 1,
    "سجارة": 1,
    "سجاف": 1,
    "سجال": 1,
    "سجالا": 1,
    "سجام": 1,
    "سجان": 1,
    "سجايا": 1,
    "سجاير": 1,
    "سجحاء": 1,
    "سجد": 1,
    "سجدة": 1,
    "سجر": 1,
    "سجس": 1,
    "سجعة": 1,
    "سجف": 1,
    "سجله": 1,
    "سجم": 1,
    "سجمان": 1,
    "سجناء": 1,
    "سجنى": 1,
    "سجو": 1,
    "سجود": 1,
    "سجوف": 1,
    "سجوم": 1,
    "سجية": 1,
    "سجيته": 1,
    "سجيتھا": 1,
    "سجينة": 1,
    "سح": 1,
    "سحاءة": 1,
    "سحائب": 1,
    "سحابة": 1,
    "سحاحة": 1,
    "سحاحير": 1,
    "سحارة": 1,
    "سحاق": 1,
    "سحالة": 1,
    "سحام": 1,
    "سحايا": 1,
    "سحت": 1,
    "سحتوت": 1,
    "سحرة": 1,
    "سحره": 1,
    "سحقا": 1,
    "سحل": 1,
    "سحلب": 1,
    "سحم": 1,
    "سحماء": 1,
    "سحمة": 1,
    "سحن": 1,
    "سحنة": 1,
    "سحوبات": 1,
    "سحوح": 1,
    "سحور": 1,
    "سحوق": 1,
    "سخا": 1,
    "سخاء": 1,
    "سخائف": 1,
    "سخائم": 1,
    "سخاف": 1,
    "سخال": 1,
    "سخام": 1,
    "سخان": 1,
    "سخانات": 1,
    "سخانة": 1,
    "سخاوة": 1,
    "سختيان": 1,
    "سخرة": 1,
    "سخري": 1,
    "سخف": 1,
    "سخفاء": 1,
    "سخلة": 1,
    "سخم": 1,
    "سخمة": 1,
    "سخنة": 1,
    "سخو": 1,
    "سخية": 1,
    "سخيمة": 1,
    "سدائر": 1,
    "سداد": 1,
    "سدارة": 1,
    "سدانة": 1,
    "سدب": 1,
    "سدة": 1,
    "سدف": 1,
    "سدم": 1,
    "سدنة": 1,
    "سدو": 1,
    "سدود": 1,
    "سدور": 1,
    "سدول": 1,
    "سديد": 1,
    "سديم": 1,
    "سذاب": 1,
    "سذبي": 1,
    "سذج": 1,
    "سراء": 1,
    "سرائر": 1,
    "سراة": 1,
    "سراجة": 1,
    "سرادب": 1,
    "سرادق": 1,
    "سراديك": 1,
    "سرار": 1,
    "سراري": 1,
    "سراس": 1,
    "سراط": 1,
    "سراع": 1,
    "سراه": 1,
    "سراويل": 1,
    "سراى": 1,
    "سرايا": 1,
    "سراية": 1,
    "سراير": 1,
    "سربال": 1,
    "سربة": 1,
    "سربل": 1,
    "سرجين": 1,
    "سرحان": 1,
    "سرد": 1,
    "سردار": 1,
    "سردوك": 1,
    "سردين": 1,
    "سرروات": 1,
    "سرسام": 1,
    "سرط": 1,
    "سرعتك": 1,
    "سرعسكر": 1,
    "سرف": 1,
    "سرقان": 1,
    "سرقسطة": 1,
    "سرقين": 1,
    "سرك": 1,
    "سركي": 1,
    "سرم": 1,
    "سرمدي": 1,
    "سرنا": 1,
    "سرنديب": 1,
    "سره": 1,
    "سرو": 1,
    "سرواء": 1,
    "سروات": 1,
    "سروج": 1,
    "سروجي": 1,
    "سروجية": 1,
    "سروح": 1,
    "سرورنا": 1,
    "سروري": 1,
    "سرولا": 1,
    "سرى": 1,
    "سريان": 1,
    "سرياني": 1,
    "سرياوران": 1,
    "سريح": 1,
    "سريدة": 1,
    "سريرة": 1,
    "سريعا": 1,
    "سريوران": 1,
    "سطام": 1,
    "سطحيات": 1,
    "سطحية": 1,
    "سطرا": 1,
    "سطل": 1,
    "سطو": 1,
    "سطوة": 1,
    "سطوح": 1,
    "سطور": 1,
    "سطوع": 1,
    "سطول": 1,
    "سطيح": 1,
    "سعادة": 1,
    "سعادتكم": 1,
    "سعادين": 1,
    "سعار": 1,
    "سعال": 1,
    "سعاية": 1,
    "سعتر": 1,
    "سعتي": 1,
    "سعد": 1,
    "سعداء": 1,
    "سعدان": 1,
    "سعدانة": 1,
    "سعط": 1,
    "سعف": 1,
    "سعل": 1,
    "سعلاة": 1,
    "سعلة": 1,
    "سعلى": 1,
    "سعليات": 1,
    "سعن": 1,
    "سعودي": 1,
    "سعوط": 1,
    "سعير": 1,
    "سغابة": 1,
    "سغب": 1,
    "سف": 1,
    "سفائن": 1,
    "سفاد": 1,
    "سفارة": 1,
    "سفارج": 1,
    "سفاسف": 1,
    "سفافيد": 1,
    "سفال": 1,
    "سفالة": 1,
    "سفان": 1,
    "سفانة": 1,
    "سفاه": 1,
    "سفاھة": 1,
    "سفتجة": 1,
    "سفد": 1,
    "سفراء": 1,
    "سفرات": 1,
    "سفرجل": 1,
    "سفرجي": 1,
    "سفرجية": 1,
    "سفرى": 1,
    "سفرية": 1,
    "سفساف": 1,
    "سفسة": 1,
    "سفسطات": 1,
    "سفسطة": 1,
    "سفسطي": 1,
    "سفط": 1,
    "سفع": 1,
    "سفعاء": 1,
    "سفعة": 1,
    "سفق": 1,
    "سفك": 1,
    "سفل": 1,
    "سفلاق": 1,
    "سفلة": 1,
    "سفلقة": 1,
    "سفلى": 1,
    "سفنج": 1,
    "سفود": 1,
    "سفور": 1,
    "سفوف": 1,
    "سفول": 1,
    "سفى": 1,
    "سفين": 1,
    "سفھاء": 1,
    "سقاء": 1,
    "سقائف": 1,
    "سقاة": 1,
    "سقارة": 1,
    "سقاط": 1,
    "سقاطة": 1,
    "سقالبة": 1,
    "سقالة": 1,
    "سقام": 1,
    "سقاوة": 1,
    "سقسقة": 1,
    "سقطات": 1,
    "سقطة": 1,
    "سقطراء": 1,
    "سقطى": 1,
    "سقع": 1,
    "سقلب": 1,
    "سقلبي": 1,
    "سقم": 1,
    "سقماء": 1,
    "سقوط": 1,
    "سقوف": 1,
    "سقى": 1,
    "سقيط": 1,
    "سكاء": 1,
    "سكائر": 1,
    "سكائن": 1,
    "سكات": 1,
    "سكارة": 1,
    "سكارتو": 1,
    "سكاريج": 1,
    "سكارين": 1,
    "سكاف": 1,
    "سكافة": 1,
    "سكاكر": 1,
    "سكاكيني": 1,
    "سكب": 1,
    "سكباج": 1,
    "سكتة": 1,
    "سكرات": 1,
    "سكرتاة": 1,
    "سكرتارية": 1,
    "سكرتو": 1,
    "سكرتير": 1,
    "سكرتيرية": 1,
    "سكرجة": 1,
    "سكروز": 1,
    "سكرى": 1,
    "سكرية": 1,
    "سكرين": 1,
    "سكسك": 1,
    "سكسكة": 1,
    "سكسونيا": 1,
    "سكع": 1,
    "سكنت": 1,
    "سكنجبين": 1,
    "سكندنافي": 1,
    "سكندناوة": 1,
    "سكندينافيا": 1,
    "سكنى": 1,
    "سكون": 1,
    "سكي": 1,
    "سكيب": 1,
    "سكيت": 1,
    "سلأ": 1,
    "سلا": 1,
    "سلاء": 1,
    "سلائق": 1,
    "سلائل": 1,
    "سلاب": 1,
    "سلاحدار": 1,
    "سلاحف": 1,
    "سلاحه": 1,
    "سلاطح": 1,
    "سلاطين": 1,
    "سلاف": 1,
    "سلافة": 1,
    "سلاقة": 1,
    "سلاقون": 1,
    "سلاقى": 1,
    "سلاقي": 1,
    "سلال": 1,
    "سلالم": 1,
    "سلالى": 1,
    "سلاليم": 1,
    "سلامتك": 1,
    "سلاملك": 1,
    "سلامى": 1,
    "سلامي": 1,
    "سلاميات": 1,
    "سلامية": 1,
    "سلانيك": 1,
    "سلاوى": 1,
    "سلبند": 1,
    "سلت": 1,
    "سلج": 1,
    "سلجم": 1,
    "سلح": 1,
    "سلحفاء": 1,
    "سلحفائية": 1,
    "سلسبيل": 1,
    "سلسله": 1,
    "سلط": 1,
    "سلطانة": 1,
    "سلطاني": 1,
    "سلطانية": 1,
    "سلطح": 1,
    "سلطن": 1,
    "سلطنة": 1,
    "سلطوا": 1,
    "سلفاء": 1,
    "سلفات": 1,
    "سلفة": 1,
    "سلفت": 1,
    "سلفية": 1,
    "سلفيد": 1,
    "سلق": 1,
    "سلقون": 1,
    "سلكة": 1,
    "سلكه": 1,
    "سلكي": 1,
    "سلكية": 1,
    "سلماء": 1,
    "سلمة": 1,
    "سلندر": 1,
    "سلو": 1,
    "سلواة": 1,
    "سلوان": 1,
    "سلوة": 1,
    "سلوع": 1,
    "سلوقي": 1,
    "سلوى": 1,
    "سليح": 1,
    "سليخة": 1,
    "سليقة": 1,
    "سليل": 1,
    "سليلة": 1,
    "سليماني": 1,
    "سلينيوم": 1,
    "سما": 1,
    "سمائم": 1,
    "سمائي": 1,
    "سماة": 1,
    "سمات": 1,
    "سماج": 1,
    "سماجة": 1,
    "سماجى": 1,
    "سماح": 1,
    "سماحة": 1,
    "سماحته": 1,
    "سماحق": 1,
    "سمادير": 1,
    "سمار": 1,
    "سماسر": 1,
    "سماسير": 1,
    "سماط": 1,
    "سماعي": 1,
    "سماعيات": 1,
    "سماق": 1,
    "سماقي": 1,
    "سماك": 1,
    "سماكة": 1,
    "سمام": 1,
    "سمامن": 1,
    "سمامير": 1,
    "سماناة": 1,
    "سمانة": 1,
    "سمانجوني": 1,
    "سمانى": 1,
    "سمانيات": 1,
    "سمباتوي": 1,
    "سمت": 1,
    "سمته": 1,
    "سمج": 1,
    "سمجاء": 1,
    "سمحاء": 1,
    "سمحاق": 1,
    "سمد": 1,
    "سمدور": 1,
    "سمر": 1,
    "سمراء": 1,
    "سمراوات": 1,
    "سمرقند": 1,
    "سمسر": 1,
    "سمسرة": 1,
    "سمسيرة": 1,
    "سمط": 1,
    "سمعاء": 1,
    "سمعته": 1,
    "سمعك": 1,
    "سمعى": 1,
    "سمعھم": 1,
    "سمق": 1,
    "سمكرة": 1,
    "سمكري": 1,
    "سمكرية": 1,
    "سمكي": 1,
    "سمل": 1,
    "سمنتو": 1,
    "سمنجوني": 1,
    "سمندل": 1,
    "سمو": 1,
    "سموات": 1,
    "سموت": 1,
    "سمود": 1,
    "سمور": 1,
    "سموط": 1,
    "سموق": 1,
    "سمول": 1,
    "سمولة": 1,
    "سمون": 1,
    "سمى": 1,
    "سميج": 1,
    "سميح": 1,
    "سميد": 1,
    "سميذ": 1,
    "سمير": 1,
    "سميرة": 1,
    "سمھوري": 1,
    "سنابك": 1,
    "سنابيق": 1,
    "سنابيك": 1,
    "سناج": 1,
    "سناجق": 1,
    "سناخة": 1,
    "سنادين": 1,
    "سنارة": 1,
    "سناكرة": 1,
    "سنان": 1,
    "سنانير": 1,
    "سنباذج": 1,
    "سنبك": 1,
    "سنبلي": 1,
    "سنبوسك": 1,
    "سنبوق": 1,
    "سنبوك": 1,
    "سنتمتر": 1,
    "سنتين": 1,
    "سنج": 1,
    "سنجاب": 1,
    "سنجابي": 1,
    "سنجة": 1,
    "سنجق": 1,
    "سنح": 1,
    "سنخ": 1,
    "سند": 1,
    "سندات": 1,
    "سندال": 1,
    "سندان": 1,
    "سندروس": 1,
    "سندسي": 1,
    "سنديان": 1,
    "سندياني": 1,
    "سنديك": 1,
    "سنط": 1,
    "سنطة": 1,
    "سنطور": 1,
    "سنطير": 1,
    "سنغافومرة": 1,
    "سنغال": 1,
    "سنف": 1,
    "سنفرة": 1,
    "سنكة": 1,
    "سنكرى": 1,
    "سنكسار": 1,
    "سنكونا": 1,
    "سنم": 1,
    "سنمار": 1,
    "سنمة": 1,
    "سنمورة": 1,
    "سنه": 1,
    "سنو": 1,
    "سنوح": 1,
    "سنوخ": 1,
    "سنود": 1,
    "سنودس": 1,
    "سنور": 1,
    "سنون": 1,
    "سنونو": 1,
    "سنى": 1,
    "سني": 1,
    "سوأة": 1,
    "سوائل": 1,
    "سوائم": 1,
    "سوابح": 1,
    "سوابط": 1,
    "سوابغ": 1,
    "سوابق": 1,
    "سواح": 1,
    "سواحر": 1,
    "سواحلة": 1,
    "سواد": 1,
    "سوار": 1,
    "سوارب": 1,
    "سوارق": 1,
    "سوارى": 1,
    "سواريخ": 1,
    "سواس": 1,
    "سواسية": 1,
    "سواطع": 1,
    "سواطير": 1,
    "سواعد": 1,
    "سواكن": 1,
    "سوالب": 1,
    "سوالف": 1,
    "سوامر": 1,
    "سوان": 1,
    "سوانح": 1,
    "سوايا": 1,
    "سوتيان": 1,
    "سوتيه": 1,
    "سوح": 1,
    "سوخ": 1,
    "سودان": 1,
    "سودن": 1,
    "سودنة": 1,
    "سودوي": 1,
    "سوري": 1,
    "سورية": 1,
    "سوسته": 1,
    "سوسن": 1,
    "سوغ": 1,
    "سوفسطائي": 1,
    "سوفيات": 1,
    "سوفياتي": 1,
    "سوفييت": 1,
    "سوفييتي": 1,
    "سوقة": 1,
    "سوقي": 1,
    "سوقية": 1,
    "سوك": 1,
    "سوكر": 1,
    "سولب": 1,
    "سوم": 1,
    "سويداء": 1,
    "سويدائھا": 1,
    "سويعة": 1,
    "سويف": 1,
    "سويق": 1,
    "سويقة": 1,
    "سوھج": 1,
    "سياح": 1,
    "سياحة": 1,
    "سيادتكم": 1,
    "سيار": 1,
    "سياس": 1,
    "سياط": 1,
    "سياق": 1,
    "سيالة": 1,
    "سيامة": 1,
    "سياية": 1,
    "سيب": 1,
    "سيباط": 1,
    "سيبيريا": 1,
    "سية": 1,
    "سيجار": 1,
    "سيجارة": 1,
    "سيجان": 1,
    "سيح": 1,
    "سيحان": 1,
    "سيخ": 1,
    "سيخان": 1,
    "سيد": 1,
    "سيدارة": 1,
    "سيدي": 1,
    "سيراس": 1,
    "سيرك": 1,
    "سيرورة": 1,
    "سيسان": 1,
    "سيسبان": 1,
    "سيسى": 1,
    "سيسيات": 1,
    "سيطرته": 1,
    "سيقارة": 1,
    "سيقان": 1,
    "سيكارة": 1,
    "سيكران": 1,
    "سيكورتاه": 1,
    "سيكولوجى": 1,
    "سيلة": 1,
    "سيم": 1,
    "سيمة": 1,
    "سيمى": 1,
    "سيميا": 1,
    "سيمياء": 1,
    "سينماتوغراف": 1,
    "سينماسكوب": 1,
    "سينودس": 1,
    "سيوف": 1,
    "سيول": 1,
    "سھا": 1,
    "سھاد": 1,
    "سھار": 1,
    "سھام": 1,
    "سھامية": 1,
    "سھب": 1,
    "سھد": 1,
    "سھر": 1,
    "سھران": 1,
    "سھرة": 1,
    "سھري": 1,
    "سھف": 1,
    "سھل": 1,
    "سھلة": 1,
    "سھم": 1,
    "سھمه": 1,
    "سھو": 1,
    "سھوا": 1,
    "سھوان": 1,
    "سھوب": 1,
    "سھوة": 1,
    "سھول": 1,
    "سھولة": 1,
    "سھوم": 1,
    "سھى": 1,
    "سھيل": 1,
    "شآ": 1,
    "شآبيب": 1,
    "شآمي": 1,
    "شأفة": 1,
    "شأفته": 1,
    "شأم": 1,
    "شأنا": 1,
    "شأنك": 1,
    "شأنه": 1,
    "شأني": 1,
    "شأنھا": 1,
    "شأو": 1,
    "شأوا": 1,
    "شأوه": 1,
    "شؤبوب": 1,
    "شؤم": 1,
    "شؤما": 1,
    "شؤمى": 1,
    "شؤونه": 1,
    "شائع": 1,
    "شائعة": 1,
    "شائق": 1,
    "شائكة": 1,
    "شائه": 1,
    "شابة": 1,
    "شابه": 1,
    "شابورة": 1,
    "شاة": 1,
    "شات": 1,
    "شاحط": 1,
    "شاحن": 1,
    "شاخ": 1,
    "شاخص": 1,
    "شادر": 1,
    "شادن": 1,
    "شادوف": 1,
    "شادون": 1,
    "شادية": 1,
    "شاذرون": 1,
    "شار": 1,
    "شاربان": 1,
    "شارة": 1,
    "شارح": 1,
    "شاردة": 1,
    "شارعا": 1,
    "شاركه": 1,
    "شاروبيم": 1,
    "شارى": 1,
    "شاسعة": 1,
    "شاسي": 1,
    "شاش": 1,
    "شاشية": 1,
    "شاط": 1,
    "شاطره": 1,
    "شاع": 1,
    "شاعرية": 1,
    "شاغل": 1,
    "شاغلا": 1,
    "شاغول": 1,
    "شاف": 1,
    "شافع": 1,
    "شافعي": 1,
    "شافن": 1,
    "شاقة": 1,
    "شاقل": 1,
    "شاقول": 1,
    "شاك": 1,
    "شاكلتھم": 1,
    "شاكوش": 1,
    "شاكي": 1,
    "شالت": 1,
    "شاليه": 1,
    "شام": 1,
    "شاما": 1,
    "شامت": 1,
    "شامح": 1,
    "شامخ": 1,
    "شامس": 1,
    "شامي": 1,
    "شان": 1,
    "شانتاج": 1,
    "شاه": 1,
    "شاور": 1,
    "شاورمة": 1,
    "شاوش": 1,
    "شاويش": 1,
    "شاي": 1,
    "شاھاني": 1,
    "شاھانية": 1,
    "شاھد": 1,
    "شاھدة": 1,
    "شاھق": 1,
    "شاھين": 1,
    "شب": 1,
    "شبا": 1,
    "شبائب": 1,
    "شباب": 1,
    "شبابة": 1,
    "شبابه": 1,
    "شبابي": 1,
    "شبابير": 1,
    "شبابيك": 1,
    "شبابھم": 1,
    "شباة": 1,
    "شباشب": 1,
    "شباط": 1,
    "شباع": 1,
    "شباعي": 1,
    "شبان": 1,
    "شباه": 1,
    "شباين": 1,
    "شبب": 1,
    "شببة": 1,
    "شبة": 1,
    "شبت": 1,
    "شبث": 1,
    "شبثان": 1,
    "شبر": 1,
    "شبرا": 1,
    "شبرق": 1,
    "شبرقة": 1,
    "شبشب": 1,
    "شبط": 1,
    "شبعة": 1,
    "شبعى": 1,
    "شبل": 1,
    "شبن": 1,
    "شبوات": 1,
    "شبوب": 1,
    "شبوح": 1,
    "شبور": 1,
    "شبورة": 1,
    "شبوط": 1,
    "شبوكات": 1,
    "شبيب": 1,
    "شبيبة": 1,
    "شبين": 1,
    "شبينة": 1,
    "شبھات": 1,
    "شبھان": 1,
    "شبھة": 1,
    "شت": 1,
    "شتا": 1,
    "شتاء": 1,
    "شتائل": 1,
    "شتائم": 1,
    "شتات": 1,
    "شتام": 1,
    "شتت": 1,
    "شتر": 1,
    "شترات": 1,
    "شتل": 1,
    "شتلة": 1,
    "شتما": 1,
    "شتو": 1,
    "شتول": 1,
    "شتوي": 1,
    "شتيت": 1,
    "شتيم": 1,
    "شج": 1,
    "شجا": 1,
    "شجائع": 1,
    "شجاب": 1,
    "شجاج": 1,
    "شجب": 1,
    "شجة": 1,
    "شجراء": 1,
    "شجران": 1,
    "شجعاء": 1,
    "شجعان": 1,
    "شجعة": 1,
    "شجن": 1,
    "شجو": 1,
    "شجون": 1,
    "شجوى": 1,
    "شجى": 1,
    "شجية": 1,
    "شجير": 1,
    "شجيع": 1,
    "شحائح": 1,
    "شحات": 1,
    "شحاح": 1,
    "شحاذ": 1,
    "شحاذة": 1,
    "شحار": 1,
    "شحارير": 1,
    "شحاطة": 1,
    "شحامة": 1,
    "شحت": 1,
    "شحذ": 1,
    "شحر": 1,
    "شحرور": 1,
    "شحط": 1,
    "شحطة": 1,
    "شحمي": 1,
    "شحناء": 1,
    "شحنة": 1,
    "شحوب": 1,
    "شحوبة": 1,
    "شحور": 1,
    "شحومات": 1,
    "شحيطة": 1,
    "شحيم": 1,
    "شخ": 1,
    "شخاتير": 1,
    "شخاخ": 1,
    "شخاشخ": 1,
    "شخب": 1,
    "شخبط": 1,
    "شختور": 1,
    "شختورة": 1,
    "شخر": 1,
    "شخشخ": 1,
    "شخشخية": 1,
    "شخشيخة": 1,
    "شخصاتي": 1,
    "شخصيته": 1,
    "شخط": 1,
    "شخلل": 1,
    "شخليلة": 1,
    "شخوص": 1,
    "شدا": 1,
    "شدائد": 1,
    "شدايقة": 1,
    "شدح": 1,
    "شدعضده": 1,
    "شدق": 1,
    "شدقاء": 1,
    "شدقه": 1,
    "شدقيه": 1,
    "شدن": 1,
    "شده": 1,
    "شدو": 1,
    "شدياق": 1,
    "شذ": 1,
    "شذا": 1,
    "شذاذ": 1,
    "شذر": 1,
    "شذرة": 1,
    "شذو": 1,
    "شذوذ": 1,
    "شذور": 1,
    "شذى": 1,
    "شرائك": 1,
    "شرابة": 1,
    "شراة": 1,
    "شراح": 1,
    "شراد": 1,
    "شراذم": 1,
    "شراذيم": 1,
    "شرار": 1,
    "شرارة": 1,
    "شراري": 1,
    "شراريب": 1,
    "شراس": 1,
    "شراسة": 1,
    "شراسيف": 1,
    "شراسيفي": 1,
    "شراشف": 1,
    "شراشير": 1,
    "شراعية": 1,
    "شراق": 1,
    "شراقوة": 1,
    "شراقي": 1,
    "شراك": 1,
    "شراكسة": 1,
    "شراميط": 1,
    "شرانق": 1,
    "شراني": 1,
    "شراويل": 1,
    "شرايين": 1,
    "شراھة": 1,
    "شربة": 1,
    "شربت": 1,
    "شربك": 1,
    "شربين": 1,
    "شرة": 1,
    "شرحة": 1,
    "شرحي": 1,
    "شرد": 1,
    "شردق": 1,
    "شرذم": 1,
    "شرذمة": 1,
    "شرر": 1,
    "شررا": 1,
    "شررى": 1,
    "شرسوف": 1,
    "شرش": 1,
    "شرشور": 1,
    "شرشير": 1,
    "شرطا": 1,
    "شرطن": 1,
    "شرطونية": 1,
    "شرقاوي": 1,
    "شرقراق": 1,
    "شرقرق": 1,
    "شركا": 1,
    "شركسي": 1,
    "شرم": 1,
    "شرماء": 1,
    "شرمط": 1,
    "شرموطة": 1,
    "شرو": 1,
    "شروال": 1,
    "شروب": 1,
    "شروة": 1,
    "شروح": 1,
    "شرور": 1,
    "شروش": 1,
    "شروع": 1,
    "شروم": 1,
    "شروى": 1,
    "شرى": 1,
    "شري": 1,
    "شريب": 1,
    "شريد": 1,
    "شريس": 1,
    "شريفة": 1,
    "شريكة": 1,
    "شزرا": 1,
    "شزراء": 1,
    "شست": 1,
    "ششخان": 1,
    "ششم": 1,
    "ششمة": 1,
    "ششنجي": 1,
    "ششنى": 1,
    "شص": 1,
    "شصر": 1,
    "شصوص": 1,
    "شط": 1,
    "شطآن": 1,
    "شطار": 1,
    "شطارة": 1,
    "شطب": 1,
    "شطة": 1,
    "شطح": 1,
    "شطحات": 1,
    "شطحة": 1,
    "شطرة": 1,
    "شطط": 1,
    "شطف": 1,
    "شطفة": 1,
    "شطن": 1,
    "شطوب": 1,
    "شطور": 1,
    "شطورة": 1,
    "شطوط": 1,
    "شطيرة": 1,
    "شطيطة": 1,
    "شظاف": 1,
    "شظايا": 1,
    "شظف": 1,
    "شظي": 1,
    "شظية": 1,
    "شعاب": 1,
    "شعارى": 1,
    "شعاعا": 1,
    "شعاف": 1,
    "شعانين": 1,
    "شعبان": 1,
    "شعبذ": 1,
    "شعبذة": 1,
    "شعثه": 1,
    "شعراني": 1,
    "شعره": 1,
    "شعرور": 1,
    "شعرى": 1,
    "شعشع": 1,
    "شعط": 1,
    "شعفة": 1,
    "شعل": 1,
    "شعلة": 1,
    "شعنينة": 1,
    "شعواء": 1,
    "شعوب": 1,
    "شعوبي": 1,
    "شعوبية": 1,
    "شعوذ": 1,
    "شعوذة": 1,
    "شعوره": 1,
    "شعيرات": 1,
    "شعيرية": 1,
    "شغاف": 1,
    "شغر": 1,
    "شغف": 1,
    "شغلة": 1,
    "شغلك": 1,
    "شغوب": 1,
    "شغور": 1,
    "شغوف": 1,
    "شغيل": 1,
    "شف": 1,
    "شفائف": 1,
    "شفائي": 1,
    "شفار": 1,
    "شفاطة": 1,
    "شفاع": 1,
    "شفافية": 1,
    "شفاه": 1,
    "شفاهية": 1,
    "شفايف": 1,
    "شفاھا": 1,
    "شفاھي": 1,
    "شفاھيا": 1,
    "شفت": 1,
    "شفتر": 1,
    "شفتشي": 1,
    "شفتلك": 1,
    "شفتورة": 1,
    "شفتيه": 1,
    "شفري": 1,
    "شفشف": 1,
    "شفط": 1,
    "شفع": 1,
    "شفعة": 1,
    "شفعي": 1,
    "شفف": 1,
    "شفق": 1,
    "شفقة": 1,
    "شفه": 1,
    "شفو": 1,
    "شفوات": 1,
    "شفوت": 1,
    "شفوف": 1,
    "شفوق": 1,
    "شفى": 1,
    "شفي": 1,
    "شفير": 1,
    "شفيف": 1,
    "شفيق": 1,
    "شفھى": 1,
    "شفھي": 1,
    "شفھيا": 1,
    "شقاء": 1,
    "شقاذف": 1,
    "شقاشق": 1,
    "شقافة": 1,
    "شقاوة": 1,
    "شقت": 1,
    "شقح": 1,
    "شقذف": 1,
    "شقر": 1,
    "شقراء": 1,
    "شقرق": 1,
    "شقشق": 1,
    "شقشقة": 1,
    "شقف": 1,
    "شقلب": 1,
    "شقلبة": 1,
    "شقو": 1,
    "شقوة": 1,
    "شقوق": 1,
    "شقيق": 1,
    "شقيقة": 1,
    "شكاء": 1,
    "شكائر": 1,
    "شكائم": 1,
    "شكاة": 1,
    "شكات": 1,
    "شكارة": 1,
    "شكاسة": 1,
    "شكاية": 1,
    "شكة": 1,
    "شكران": 1,
    "شكره": 1,
    "شكري": 1,
    "شكك": 1,
    "شكلة": 1,
    "شكليات": 1,
    "شكم": 1,
    "شكه": 1,
    "شكو": 1,
    "شكوات": 1,
    "شكوة": 1,
    "شكوريا": 1,
    "شكوش": 1,
    "شكوك": 1,
    "شكول": 1,
    "شكولاته": 1,
    "شكوى": 1,
    "شكية": 1,
    "شكيم": 1,
    "شكيمة": 1,
    "شلاء": 1,
    "شلال": 1,
    "شلبي": 1,
    "شلت": 1,
    "شلتة": 1,
    "شلح": 1,
    "شلشل": 1,
    "شلفة": 1,
    "شلق": 1,
    "شلن": 1,
    "شلو": 1,
    "شليك": 1,
    "شمأز": 1,
    "شماء": 1,
    "شمائل": 1,
    "شمات": 1,
    "شماتة": 1,
    "شمار": 1,
    "شماريخ": 1,
    "شماس": 1,
    "شماسي": 1,
    "شماع": 1,
    "شماعة": 1,
    "شماعد": 1,
    "شماعدين": 1,
    "شماليل": 1,
    "شمبانيا": 1,
    "شمة": 1,
    "شمت": 1,
    "شمخ": 1,
    "شمر": 1,
    "شمرة": 1,
    "شمروخ": 1,
    "شمسية": 1,
    "شمشم": 1,
    "شمط": 1,
    "شمطاء": 1,
    "شمطان": 1,
    "شمعة": 1,
    "شمعدان": 1,
    "شمعي": 1,
    "شمل": 1,
    "شملة": 1,
    "شمله": 1,
    "شملول": 1,
    "شملھم": 1,
    "شمم": 1,
    "شممسة": 1,
    "شمندر": 1,
    "شمندورة": 1,
    "شموخ": 1,
    "شموس": 1,
    "شموع": 1,
    "شمول": 1,
    "شميم": 1,
    "شن": 1,
    "شنأ": 1,
    "شناتين": 1,
    "شناخيب": 1,
    "شنار": 1,
    "شنارق": 1,
    "شناشن": 1,
    "شناعة": 1,
    "شناغيب": 1,
    "شناكل": 1,
    "شنب": 1,
    "شنتيان": 1,
    "شنج": 1,
    "شنخوبة": 1,
    "شنر": 1,
    "شنشنة": 1,
    "شنط": 1,
    "شنع": 1,
    "شنعاء": 1,
    "شنغوبة": 1,
    "شنف": 1,
    "شنق": 1,
    "شنقا": 1,
    "شنقب": 1,
    "شنكل": 1,
    "شنوف": 1,
    "شنون": 1,
    "شنير": 1,
    "شنيطة": 1,
    "شنيعا": 1,
    "شنھق": 1,
    "شوء": 1,
    "شواء": 1,
    "شوائب": 1,
    "شوائع": 1,
    "شوابق": 1,
    "شوابك": 1,
    "شواة": 1,
    "شواحب": 1,
    "شوادر": 1,
    "شوادف": 1,
    "شوادن": 1,
    "شواذات": 1,
    "شوارب": 1,
    "شوارد": 1,
    "شواش": 1,
    "شواشي": 1,
    "شواشية": 1,
    "شواعر": 1,
    "شواغر": 1,
    "شواغل": 1,
    "شوافع": 1,
    "شواكل": 1,
    "شواكيش": 1,
    "شوال": 1,
    "شوام": 1,
    "شوامت": 1,
    "شوامخ": 1,
    "شواه": 1,
    "شواويل": 1,
    "شواية": 1,
    "شواھد": 1,
    "شواھق": 1,
    "شواھين": 1,
    "شوب": 1,
    "شوبق": 1,
    "شوح": 1,
    "شوحة": 1,
    "شور": 1,
    "شورب": 1,
    "شوربا": 1,
    "شوربة": 1,
    "شورت": 1,
    "شورمة": 1,
    "شوري": 1,
    "شوساء": 1,
    "شوشبرك": 1,
    "شوشة": 1,
    "شوط": 1,
    "شوطا": 1,
    "شوف": 1,
    "شوفان": 1,
    "شوفة": 1,
    "شوق": 1,
    "شوقا": 1,
    "شوك": 1,
    "شوكته": 1,
    "شوكران": 1,
    "شوكى": 1,
    "شول": 1,
    "شولقى": 1,
    "شولقي": 1,
    "شولم": 1,
    "شومة": 1,
    "شوية": 1,
    "شويعر": 1,
    "شوھاء": 1,
    "شى": 1,
    "شيأ": 1,
    "شيئي": 1,
    "شيئية": 1,
    "شياب": 1,
    "شيات": 1,
    "شياح": 1,
    "شياخ": 1,
    "شياخة": 1,
    "شياع": 1,
    "شيال": 1,
    "شيالة": 1,
    "شيامي": 1,
    "شياه": 1,
    "شيب": 1,
    "شيباء": 1,
    "شيبة": 1,
    "شيت": 1,
    "شيح": 1,
    "شيخة": 1,
    "شيخوخة": 1,
    "شيد": 1,
    "شيراز": 1,
    "شيرة": 1,
    "شيرج": 1,
    "شيزوفرانيا": 1,
    "شيشة": 1,
    "شيط": 1,
    "شيطن": 1,
    "شيطنة": 1,
    "شيعت": 1,
    "شيق": 1,
    "شيك": 1,
    "شيكران": 1,
    "شيكوريا": 1,
    "شيكولاته": 1,
    "شيلام": 1,
    "شيلان": 1,
    "شيلة": 1,
    "شيلم": 1,
    "شيلمان": 1,
    "شيم": 1,
    "شيمة": 1,
    "شيمية": 1,
    "شيوتات": 1,
    "شيوخ": 1,
    "شيوخة": 1,
    "شيوع": 1,
    "شيوعية": 1,
    "شيون": 1,
    "شھا": 1,
    "شھاب": 1,
    "شھاد": 1,
    "شھادة": 1,
    "شھاق": 1,
    "شھام": 1,
    "شھامة": 1,
    "شھاوى": 1,
    "شھب": 1,
    "شھباء": 1,
    "شھبان": 1,
    "شھبة": 1,
    "شھد": 1,
    "شھداء": 1,
    "شھدة": 1,
    "شھر": 1,
    "شھرا": 1,
    "شھرة": 1,
    "شھرته": 1,
    "شھري": 1,
    "شھريا": 1,
    "شھرية": 1,
    "شھق": 1,
    "شھقة": 1,
    "شھل": 1,
    "شھلاء": 1,
    "شھلة": 1,
    "شھم": 1,
    "شھو": 1,
    "شھواته": 1,
    "شھوان": 1,
    "شھواني": 1,
    "شھوة": 1,
    "شھود": 1,
    "شھور": 1,
    "شھوى": 1,
    "شھوي": 1,
    "شھي": 1,
    "شھية": 1,
    "شھيد": 1,
    "شھيدة": 1,
    "شھير": 1,
    "شھيق": 1,
    "صأى": 1,
    "صؤاب": 1,
    "صئبان": 1,
    "صئى": 1,
    "صائت": 1,
    "صائتة": 1,
    "صائفة": 1,
    "صائم": 1,
    "صائن": 1,
    "صاب": 1,
    "صابئ": 1,
    "صابة": 1,
    "صابر": 1,
    "صابورة": 1,
    "صابون": 1,
    "صابونة": 1,
    "صات": 1,
    "صاج": 1,
    "صاخ": 1,
    "صاخبة": 1,
    "صادح": 1,
    "صادف": 1,
    "صاروخ": 1,
    "صاروخية": 1,
    "صارى": 1,
    "صارية": 1,
    "صاع": 1,
    "صاعا": 1,
    "صاعة": 1,
    "صاعدا": 1,
    "صاعين": 1,
    "صاغ": 1,
    "صاغر": 1,
    "صاغقول": 1,
    "صاغية": 1,
    "صاف": 1,
    "صافح": 1,
    "صافى": 1,
    "صاقور": 1,
    "صالحات": 1,
    "صالحه": 1,
    "صالحھا": 1,
    "صالون": 1,
    "صامتا": 1,
    "صامولة": 1,
    "صان": 1,
    "صانغ": 1,
    "صبأ": 1,
    "صباء": 1,
    "صبابة": 1,
    "صباحا": 1,
    "صباحة": 1,
    "صباحك": 1,
    "صبارة": 1,
    "صباع": 1,
    "صباغ": 1,
    "صبان": 1,
    "صبايا": 1,
    "صبحان": 1,
    "صبحة": 1,
    "صبحى": 1,
    "صبرة": 1,
    "صبره": 1,
    "صبري": 1,
    "صبع": 1,
    "صبغته": 1,
    "صبغه": 1,
    "صبغيات": 1,
    "صبن": 1,
    "صبو": 1,
    "صبوء": 1,
    "صبوات": 1,
    "صبوة": 1,
    "صبوح": 1,
    "صبى": 1,
    "صبي": 1,
    "صبيان": 1,
    "صبيب": 1,
    "صبية": 1,
    "صبيح": 1,
    "صبيحة": 1,
    "صحائف": 1,
    "صحابة": 1,
    "صحابي": 1,
    "صحاة": 1,
    "صحاح": 1,
    "صحاحير": 1,
    "صحار": 1,
    "صحارة": 1,
    "صحاري": 1,
    "صحاف": 1,
    "صحافي": 1,
    "صحافية": 1,
    "صحافيي": 1,
    "صحب": 1,
    "صحبان": 1,
    "صحبة": 1,
    "صحت": 1,
    "صحراء": 1,
    "صحراوي": 1,
    "صحروات": 1,
    "صحفة": 1,
    "صحل": 1,
    "صحناة": 1,
    "صحو": 1,
    "صحية": 1,
    "صحيفته": 1,
    "صخاب": 1,
    "صخب": 1,
    "صخرات": 1,
    "صخرى": 1,
    "صخورة": 1,
    "صدأ": 1,
    "صدؤ": 1,
    "صدا": 1,
    "صداءة": 1,
    "صداح": 1,
    "صدار": 1,
    "صدارة": 1,
    "صداق": 1,
    "صداقة": 1,
    "صداه": 1,
    "صدح": 1,
    "صدحات": 1,
    "صدد": 1,
    "صدرة": 1,
    "صدرية": 1,
    "صدع": 1,
    "صدغ": 1,
    "صدغي": 1,
    "صدف": 1,
    "صدقاء": 1,
    "صدقان": 1,
    "صدقه": 1,
    "صدمة": 1,
    "صدود": 1,
    "صدوع": 1,
    "صدوف": 1,
    "صدوق": 1,
    "صديدي": 1,
    "صديري": 1,
    "صديرية": 1,
    "صرائح": 1,
    "صرائف": 1,
    "صراح": 1,
    "صراخ": 1,
    "صراد": 1,
    "صرار": 1,
    "صراصير": 1,
    "صراع": 1,
    "صراف": 1,
    "صرافة": 1,
    "صرامى": 1,
    "صربي": 1,
    "صرحاء": 1,
    "صرد": 1,
    "صرر": 1,
    "صرصار": 1,
    "صرصور": 1,
    "صرع": 1,
    "صرفه": 1,
    "صرفيات": 1,
    "صرم": 1,
    "صرمايا": 1,
    "صرمة": 1,
    "صروحة": 1,
    "صروف": 1,
    "صريد": 1,
    "صرير": 1,
    "صريرة": 1,
    "صريع": 1,
    "صريعا": 1,
    "صريف": 1,
    "صريفة": 1,
    "صريمة": 1,
    "صعاب": 1,
    "صعاليك": 1,
    "صعايدة": 1,
    "صعتر": 1,
    "صعداء": 1,
    "صعر": 1,
    "صعق": 1,
    "صعقة": 1,
    "صعلكة": 1,
    "صعلوك": 1,
    "صعيد": 1,
    "صعيدي": 1,
    "صغا": 1,
    "صغائر": 1,
    "صغارة": 1,
    "صغراء": 1,
    "صغرة": 1,
    "صغرني": 1,
    "صغرى": 1,
    "صغو": 1,
    "صغي": 1,
    "صفاء": 1,
    "صفائح": 1,
    "صفاة": 1,
    "صفاح": 1,
    "صفاد": 1,
    "صفارية": 1,
    "صفاع": 1,
    "صفاف": 1,
    "صفافيح": 1,
    "صفاق": 1,
    "صفاقة": 1,
    "صفاكس": 1,
    "صفاه": 1,
    "صفايا": 1,
    "صفح": 1,
    "صفحاتھا": 1,
    "صفحته": 1,
    "صفد": 1,
    "صفراوية": 1,
    "صفرة": 1,
    "صفرت": 1,
    "صفصف": 1,
    "صفع": 1,
    "صفعة": 1,
    "صفقة": 1,
    "صفن": 1,
    "صفو": 1,
    "صفوات": 1,
    "صفوة": 1,
    "صفوح": 1,
    "صفور": 1,
    "صفوفا": 1,
    "صفوه": 1,
    "صفى": 1,
    "صفية": 1,
    "صفيح": 1,
    "صفين": 1,
    "صقائل": 1,
    "صقاع": 1,
    "صقال": 1,
    "صقالبة": 1,
    "صقالة": 1,
    "صقب": 1,
    "صقع": 1,
    "صقعة": 1,
    "صقلب": 1,
    "صقيع": 1,
    "صقيل": 1,
    "صكاك": 1,
    "صكت": 1,
    "صلاء": 1,
    "صلابة": 1,
    "صلاح": 1,
    "صلادة": 1,
    "صلال": 1,
    "صلبان": 1,
    "صلبه": 1,
    "صلبوت": 1,
    "صلة": 1,
    "صلت": 1,
    "صلج": 1,
    "صلحي": 1,
    "صلحية": 1,
    "صلص": 1,
    "صلصل": 1,
    "صلصلة": 1,
    "صلع": 1,
    "صلعاء": 1,
    "صلعان": 1,
    "صلعة": 1,
    "صلعم": 1,
    "صلفاء": 1,
    "صلو": 1,
    "صلوة": 1,
    "صلوتة": 1,
    "صلوح": 1,
    "صلودة": 1,
    "صلون": 1,
    "صليبي": 1,
    "صليل": 1,
    "صماء": 1,
    "صماخ": 1,
    "صمادة": 1,
    "صماليخ": 1,
    "صمامة": 1,
    "صمان": 1,
    "صمة": 1,
    "صمد": 1,
    "صمدانى": 1,
    "صمصم": 1,
    "صمع": 1,
    "صمغ": 1,
    "صمل": 1,
    "صملاخ": 1,
    "صموت": 1,
    "صمود": 1,
    "صموغ": 1,
    "صمولة": 1,
    "صمى": 1,
    "صميم": 1,
    "صميمي": 1,
    "صنائع": 1,
    "صنائعي": 1,
    "صنابير": 1,
    "صنادل": 1,
    "صناديد": 1,
    "صنارة": 1,
    "صنان": 1,
    "صنانير": 1,
    "صنبر": 1,
    "صنبور": 1,
    "صنة": 1,
    "صنتيم": 1,
    "صنج": 1,
    "صنجات": 1,
    "صنجة": 1,
    "صنجقية": 1,
    "صندل": 1,
    "صنديد": 1,
    "صنعه": 1,
    "صنعي": 1,
    "صنفا": 1,
    "صنفر": 1,
    "صنفرة": 1,
    "صنم": 1,
    "صنو": 1,
    "صنوبر": 1,
    "صنوج": 1,
    "صنوف": 1,
    "صنيع": 1,
    "صنيعا": 1,
    "صنيعة": 1,
    "صواب": 1,
    "صوابه": 1,
    "صوات": 1,
    "صواحب": 1,
    "صواحبات": 1,
    "صوار": 1,
    "صواعد": 1,
    "صواعق": 1,
    "صواغ": 1,
    "صوالجة": 1,
    "صوالح": 1,
    "صوامل": 1,
    "صواميل": 1,
    "صوان": 1,
    "صوانية": 1,
    "صواوين": 1,
    "صوب": 1,
    "صوبة": 1,
    "صوة": 1,
    "صوته": 1,
    "صوح": 1,
    "صوخ": 1,
    "صودا": 1,
    "صودم": 1,
    "صورته": 1,
    "صوص": 1,
    "صوصي": 1,
    "صوع": 1,
    "صوغ": 1,
    "صوفان": 1,
    "صوفانة": 1,
    "صول": 1,
    "صولة": 1,
    "صولجان": 1,
    "صومالي": 1,
    "صون": 1,
    "صوى": 1,
    "صويحب": 1,
    "صويحبة": 1,
    "صيادلة": 1,
    "صيارف": 1,
    "صيارفة": 1,
    "صياص": 1,
    "صياغ": 1,
    "صيال": 1,
    "صيامي": 1,
    "صيب": 1,
    "صيبان": 1,
    "صيبوبة": 1,
    "صيت": 1,
    "صيته": 1,
    "صيح": 1,
    "صيحات": 1,
    "صيداء": 1,
    "صيدلاني": 1,
    "صيدليات": 1,
    "صيدلية": 1,
    "صير": 1,
    "صيرف": 1,
    "صيرفي": 1,
    "صيرورة": 1,
    "صيصان": 1,
    "صيصية": 1,
    "صيعان": 1,
    "صيقل": 1,
    "صيم": 1,
    "صيوان": 1,
    "صھاء": 1,
    "صھاريج": 1,
    "صھب": 1,
    "صھباء": 1,
    "صھد": 1,
    "صھر": 1,
    "صھريج": 1,
    "صھل": 1,
    "صھوة": 1,
    "صھود": 1,
    "صھيد": 1,
    "صھير": 1,
    "صھيل": 1,
    "صھيون": 1,
    "صھيوني": 1,
    "صھيونية": 1,
    "ضأن": 1,
    "ضؤل": 1,
    "ضؤلاء": 1,
    "ضؤولة": 1,
    "ضئال": 1,
    "ضاء": 1,
    "ضائع": 1,
    "ضائقة": 1,
    "ضائن": 1,
    "ضابطة": 1,
    "ضابطية": 1,
    "ضاح": 1,
    "ضاد": 1,
    "ضارع": 1,
    "ضاع": 1,
    "ضاغطة": 1,
    "ضاغوط": 1,
    "ضاف": 1,
    "ضاق": 1,
    "ضالة": 1,
    "ضام": 1,
    "ضامة": 1,
    "ضامن": 1,
    "ضاو": 1,
    "ضب": 1,
    "ضباب": 1,
    "ضبارة": 1,
    "ضبان": 1,
    "ضبة": 1,
    "ضبح": 1,
    "ضبر": 1,
    "ضبطا": 1,
    "ضبطية": 1,
    "ضبن": 1,
    "ضبوط": 1,
    "ضج": 1,
    "ضجاج": 1,
    "ضجة": 1,
    "ضجر": 1,
    "ضجع": 1,
    "ضجعة": 1,
    "ضجعي": 1,
    "ضجوج": 1,
    "ضجوع": 1,
    "ضجيج": 1,
    "ضجيع": 1,
    "ضحا": 1,
    "ضحاك": 1,
    "ضحضاح": 1,
    "ضحضح": 1,
    "ضحك": 1,
    "ضحكة": 1,
    "ضحو": 1,
    "ضحوك": 1,
    "ضحيته": 1,
    "ضخامة": 1,
    "ضدة": 1,
    "ضده": 1,
    "ضدية": 1,
    "ضرائح": 1,
    "ضرائر": 1,
    "ضراب": 1,
    "ضراط": 1,
    "ضراع": 1,
    "ضراعة": 1,
    "ضراغم": 1,
    "ضراغمة": 1,
    "ضرام": 1,
    "ضراوة": 1,
    "ضربان": 1,
    "ضربخان": 1,
    "ضربخانة": 1,
    "ضربه": 1,
    "ضرة": 1,
    "ضرس": 1,
    "ضرط": 1,
    "ضرعة": 1,
    "ضرغام": 1,
    "ضرغم": 1,
    "ضرم": 1,
    "ضرمة": 1,
    "ضرو": 1,
    "ضروب": 1,
    "ضرورة": 1,
    "ضروريات": 1,
    "ضروس": 1,
    "ضريب": 1,
    "ضريبي": 1,
    "ضريح": 1,
    "ضرير": 1,
    "ضريط": 1,
    "ضعائف": 1,
    "ضعاف": 1,
    "ضعة": 1,
    "ضعضع": 1,
    "ضعضعة": 1,
    "ضعفة": 1,
    "ضغائن": 1,
    "ضغث": 1,
    "ضغطة": 1,
    "ضغن": 1,
    "ضغوطية": 1,
    "ضغينة": 1,
    "ضفا": 1,
    "ضفادع": 1,
    "ضفار": 1,
    "ضفر": 1,
    "ضفف": 1,
    "ضفو": 1,
    "ضفوة": 1,
    "ضفور": 1,
    "ضفيرة": 1,
    "ضلاعة": 1,
    "ضلع": 1,
    "ضلعه": 1,
    "ضلمه": 1,
    "ضلوع": 1,
    "ضلوعي": 1,
    "ضمائم": 1,
    "ضماد": 1,
    "ضمادة": 1,
    "ضمار": 1,
    "ضمانة": 1,
    "ضمخ": 1,
    "ضمناء": 1,
    "ضمنھم": 1,
    "ضمه": 1,
    "ضمور": 1,
    "ضميره": 1,
    "ضميمة": 1,
    "ضمين": 1,
    "ضن": 1,
    "ضنا": 1,
    "ضناكة": 1,
    "ضنك": 1,
    "ضنو": 1,
    "ضنى": 1,
    "ضنين": 1,
    "ضوائق": 1,
    "ضوابط": 1,
    "ضواح": 1,
    "ضواحك": 1,
    "ضوار": 1,
    "ضوارب": 1,
    "ضوال": 1,
    "ضور": 1,
    "ضوضى": 1,
    "ضوع": 1,
    "ضوى": 1,
    "ضياع": 1,
    "ضيافتي": 1,
    "ضيع": 1,
    "ضيعانة": 1,
    "ضيعة": 1,
    "ضيعت": 1,
    "ضيفا": 1,
    "ضيفان": 1,
    "ضيم": 1,
    "ضيوف": 1,
    "ضيوفا": 1,
    "ضيوم": 1,
    "ضھد": 1,
    "ضھر": 1,
    "ضھور": 1,
    "ضھى": 1,
    "طأمن": 1,
    "طاء": 1,
    "طاؤوس": 1,
    "طائب": 1,
    "طائع": 1,
    "طائعا": 1,
    "طائفية": 1,
    "طائل": 1,
    "طائلة": 1,
    "طاباق": 1,
    "طابة": 1,
    "طابت": 1,
    "طابونة": 1,
    "طابية": 1,
    "طاجن": 1,
    "طاح": 1,
    "طاحنة": 1,
    "طاحون": 1,
    "طارئة": 1,
    "طارة": 1,
    "طارحه": 1,
    "طاردة": 1,
    "طارف": 1,
    "طارقة": 1,
    "طارمة": 1,
    "طازة": 1,
    "طازج": 1,
    "طاسة": 1,
    "طاش": 1,
    "طاع": 1,
    "طاعنة": 1,
    "طاعون": 1,
    "طاغ": 1,
    "طاغوت": 1,
    "طافية": 1,
    "طاقته": 1,
    "طالبي": 1,
    "طالح": 1,
    "طالعة": 1,
    "طالعه": 1,
    "طالعي": 1,
    "طالق": 1,
    "طامة": 1,
    "طامح": 1,
    "طامس": 1,
    "طاه": 1,
    "طاو": 1,
    "طاووس": 1,
    "طاھر": 1,
    "طبابة": 1,
    "طباخ": 1,
    "طباخة": 1,
    "طباشير": 1,
    "طباق": 1,
    "طبالي": 1,
    "طبة": 1,
    "طبخة": 1,
    "طبر": 1,
    "طبردار": 1,
    "طبرية": 1,
    "طبشورة": 1,
    "طبطابة": 1,
    "طبطب": 1,
    "طبعة": 1,
    "طبعه": 1,
    "طبقت": 1,
    "طبلي": 1,
    "طبنجة": 1,
    "طبول": 1,
    "طبونة": 1,
    "طبيبة": 1,
    "طبيخ": 1,
    "طبيعتھا": 1,
    "طبيق": 1,
    "طحال": 1,
    "طحطح": 1,
    "طحل": 1,
    "طحلب": 1,
    "طحن": 1,
    "طحين": 1,
    "طحينة": 1,
    "طحينية": 1,
    "طرآني": 1,
    "طرأ": 1,
    "طرأت": 1,
    "طرا": 1,
    "طرائد": 1,
    "طرائف": 1,
    "طراب": 1,
    "طرابيد": 1,
    "طرابيش": 1,
    "طرابيشي": 1,
    "طراحة": 1,
    "طراد": 1,
    "طرادة": 1,
    "طرار": 1,
    "طرارة": 1,
    "طراريح": 1,
    "طراطور": 1,
    "طراطير": 1,
    "طرافة": 1,
    "طراق": 1,
    "طرب": 1,
    "طربوش": 1,
    "طربوشة": 1,
    "طربيزة": 1,
    "طرة": 1,
    "طرحة": 1,
    "طرحت": 1,
    "طرحه": 1,
    "طرحى": 1,
    "طرخون": 1,
    "طردة": 1,
    "طرده": 1,
    "طرر": 1,
    "طرزي": 1,
    "طرس": 1,
    "طرش": 1,
    "طرشاء": 1,
    "طرشة": 1,
    "طرشي": 1,
    "طرطر": 1,
    "طرطش": 1,
    "طرطور": 1,
    "طرطوفة": 1,
    "طرطير": 1,
    "طرفاء": 1,
    "طرفة": 1,
    "طرفه": 1,
    "طرقات": 1,
    "طرقة": 1,
    "طرقتين": 1,
    "طرقع": 1,
    "طرمبة": 1,
    "طرو": 1,
    "طروء": 1,
    "طروب": 1,
    "طرور": 1,
    "طروز": 1,
    "طروس": 1,
    "طروش": 1,
    "طرى": 1,
    "طريء": 1,
    "طريحة": 1,
    "طريد": 1,
    "طريدة": 1,
    "طريفة": 1,
    "طزازن": 1,
    "طزالق": 1,
    "طزلق": 1,
    "طزينة": 1,
    "طست": 1,
    "طسوت": 1,
    "طشت": 1,
    "طشقند": 1,
    "طشوت": 1,
    "طصلق": 1,
    "طصلقة": 1,
    "طعمة": 1,
    "طعمية": 1,
    "طعنة": 1,
    "طعنت": 1,
    "طعوم": 1,
    "طعون": 1,
    "طغا": 1,
    "طغاة": 1,
    "طغار": 1,
    "طغام": 1,
    "طغراء": 1,
    "طغرى": 1,
    "طغمة": 1,
    "طغو": 1,
    "طغوان": 1,
    "طغي": 1,
    "طغيان": 1,
    "طفئ": 1,
    "طفا": 1,
    "طفاءة": 1,
    "طفاحة": 1,
    "طفال": 1,
    "طفالة": 1,
    "طفاية": 1,
    "طفح": 1,
    "طفحان": 1,
    "طفحة": 1,
    "طفحى": 1,
    "طفحي": 1,
    "طفر": 1,
    "طفران": 1,
    "طفرة": 1,
    "طفرت": 1,
    "طفش": 1,
    "طفلة": 1,
    "طفلي": 1,
    "طفو": 1,
    "طفوء": 1,
    "طفوح": 1,
    "طفولة": 1,
    "طفيليات": 1,
    "طقاطيق": 1,
    "طقسي": 1,
    "طقطق": 1,
    "طقطوقة": 1,
    "طقوس": 1,
    "طقوم": 1,
    "طقومة": 1,
    "طلائق": 1,
    "طلاح": 1,
    "طلاحى": 1,
    "طلاسم": 1,
    "طلاع": 1,
    "طلاق": 1,
    "طلاوة": 1,
    "طلبه": 1,
    "طلبية": 1,
    "طلح": 1,
    "طلحية": 1,
    "طلس": 1,
    "طلسانة": 1,
    "طلسم": 1,
    "طلعة": 1,
    "طلقاء": 1,
    "طلقت": 1,
    "طلل": 1,
    "طلمبة": 1,
    "طلمس": 1,
    "طلو": 1,
    "طلوح": 1,
    "طلوقة": 1,
    "طلول": 1,
    "طليحة": 1,
    "طليطلة": 1,
    "طليعة": 1,
    "طليقة": 1,
    "طم": 1,
    "طمأنينة": 1,
    "طما": 1,
    "طماح": 1,
    "طماطة": 1,
    "طماطم": 1,
    "طماعة": 1,
    "طماعية": 1,
    "طمان": 1,
    "طمث": 1,
    "طمر": 1,
    "طمس": 1,
    "طمطماني": 1,
    "طمع": 1,
    "طمن": 1,
    "طمو": 1,
    "طموس": 1,
    "طموم": 1,
    "طمى": 1,
    "طمي": 1,
    "طنابير": 1,
    "طناجر": 1,
    "طنافس": 1,
    "طنب": 1,
    "طنبور": 1,
    "طنبوري": 1,
    "طنبوشة": 1,
    "طنجرة": 1,
    "طنجي": 1,
    "طنطا": 1,
    "طنطن": 1,
    "طنطنة": 1,
    "طنف": 1,
    "طنفسة": 1,
    "طنوف": 1,
    "طنين": 1,
    "طوائح": 1,
    "طوائف": 1,
    "طواب": 1,
    "طوابع": 1,
    "طوابير": 1,
    "طوابيق": 1,
    "طواجن": 1,
    "طواحن": 1,
    "طوار": 1,
    "طوارق": 1,
    "طواري": 1,
    "طواشي": 1,
    "طواشية": 1,
    "طواعية": 1,
    "طواعين": 1,
    "طواف": 1,
    "طوافة": 1,
    "طواقي": 1,
    "طوال": 1,
    "طوالة": 1,
    "طوالع": 1,
    "طوامر": 1,
    "طوامير": 1,
    "طواويس": 1,
    "طوايا": 1,
    "طواية": 1,
    "طوب": 1,
    "طوبا": 1,
    "طوبجي": 1,
    "طوبجية": 1,
    "طوبه": 1,
    "طوح": 1,
    "طود": 1,
    "طورا": 1,
    "طوراني": 1,
    "طوربيد": 1,
    "طوره": 1,
    "طوري": 1,
    "طورية": 1,
    "طوزلق": 1,
    "طوس": 1,
    "طوش": 1,
    "طوع": 1,
    "طوعت": 1,
    "طوعيا": 1,
    "طوف": 1,
    "طوفان": 1,
    "طوقا": 1,
    "طوقه": 1,
    "طوقي": 1,
    "طولاني": 1,
    "طولك": 1,
    "طولكرم": 1,
    "طوله": 1,
    "طولى": 1,
    "طولھا": 1,
    "طومار": 1,
    "طونولاطة": 1,
    "طوية": 1,
    "طي": 1,
    "طياب": 1,
    "طياته": 1,
    "طيارة": 1,
    "طياشان": 1,
    "طياشة": 1,
    "طيالسة": 1,
    "طيان": 1,
    "طية": 1,
    "طيح": 1,
    "طيرة": 1,
    "طيش": 1,
    "طيشان": 1,
    "طيف": 1,
    "طيقان": 1,
    "طيلة": 1,
    "طيلسان": 1,
    "طينة": 1,
    "طيه": 1,
    "طيوب": 1,
    "طيوف": 1,
    "طھا": 1,
    "طھاة": 1,
    "طھارة": 1,
    "طھاية": 1,
    "طھر": 1,
    "طھران": 1,
    "طھق": 1,
    "طھو": 1,
    "طھور": 1,
    "طھى": 1,
    "طھي": 1,
    "ظئر": 1,
    "ظاء": 1,
    "ظاعن": 1,
    "ظافر": 1,
    "ظامئ": 1,
    "ظاھر": 1,
    "ظاھرا": 1,
    "ظاھرة": 1,
    "ظاھري": 1,
    "ظباء": 1,
    "ظبية": 1,
    "ظر": 1,
    "ظرائف": 1,
    "ظرابي": 1,
    "ظرابين": 1,
    "ظرافة": 1,
    "ظربان": 1,
    "ظرة": 1,
    "ظرفاء": 1,
    "ظرفية": 1,
    "ظعن": 1,
    "ظعينة": 1,
    "ظفران": 1,
    "ظلام": 1,
    "ظلامة": 1,
    "ظلع": 1,
    "ظلماء": 1,
    "ظلمان": 1,
    "ظله": 1,
    "ظلوف": 1,
    "ظلول": 1,
    "ظليم": 1,
    "ظمء": 1,
    "ظمآن": 1,
    "ظمأى": 1,
    "ظمئ": 1,
    "ظماء": 1,
    "ظماءة": 1,
    "ظنابيب": 1,
    "ظنبوب": 1,
    "ظنة": 1,
    "ظنك": 1,
    "ظنن": 1,
    "ظنون": 1,
    "ظني": 1,
    "ظنين": 1,
    "ظواھر": 1,
    "ظھار": 1,
    "ظھارة": 1,
    "ظھر": 1,
    "ظھرا": 1,
    "ظھرانيھم": 1,
    "ظھره": 1,
    "ظھري": 1,
    "ظھريا": 1,
    "ظھور": 1,
    "ظھورات": 1,
    "ظھير": 1,
    "ظھيرة": 1,
    "عائد": 1,
    "عائدات": 1,
    "عائدة": 1,
    "عائدية": 1,
    "عائم": 1,
    "عابئ": 1,
    "عابق": 1,
    "عات": 1,
    "عاتق": 1,
    "عاتقه": 1,
    "عاتك": 1,
    "عاثور": 1,
    "عاج": 1,
    "عاجلا": 1,
    "عاجله": 1,
    "عاجي": 1,
    "عادت": 1,
    "عادته": 1,
    "عادتھم": 1,
    "عادم": 1,
    "عادوا": 1,
    "عاديات": 1,
    "عاذ": 1,
    "عاذل": 1,
    "عاذلة": 1,
    "عارضي": 1,
    "عارفة": 1,
    "عارم": 1,
    "عارية": 1,
    "عاز": 1,
    "عازة": 1,
    "عازم": 1,
    "عاس": 1,
    "عاشق": 1,
    "عاشوراء": 1,
    "عاص": 1,
    "عاطر": 1,
    "عاطش": 1,
    "عاطف": 1,
    "عاطفة": 1,
    "عاطفية": 1,
    "عاطوس": 1,
    "عاظل": 1,
    "عاف": 1,
    "عافن": 1,
    "عافية": 1,
    "عاق": 1,
    "عاقد": 1,
    "عاقلة": 1,
    "عاقول": 1,
    "عاكف": 1,
    "عالجه": 1,
    "عالماني": 1,
    "عالمة": 1,
    "عالمون": 1,
    "عالمى": 1,
    "عالى": 1,
    "عاليه": 1,
    "عامر": 1,
    "عامله": 1,
    "عامود": 1,
    "عانس": 1,
    "عاھر": 1,
    "عاھرة": 1,
    "عاھل": 1,
    "عاھن": 1,
    "عبء": 1,
    "عبأ": 1,
    "عباء": 1,
    "عباب": 1,
    "عباسي": 1,
    "عباقرة": 1,
    "عبال": 1,
    "عباية": 1,
    "عبدان": 1,
    "عبدة": 1,
    "عبدلاوي": 1,
    "عبرود": 1,
    "عبطاء": 1,
    "عبق": 1,
    "عبقة": 1,
    "عبقر": 1,
    "عبقري": 1,
    "عبقرية": 1,
    "عبك": 1,
    "عبل": 1,
    "عبو": 1,
    "عبوة": 1,
    "عبودة": 1,
    "عبوسة": 1,
    "عبيد": 1,
    "عبير": 1,
    "عبيط": 1,
    "عتا": 1,
    "عتاب": 1,
    "عتاة": 1,
    "عتاد": 1,
    "عتاقة": 1,
    "عتال": 1,
    "عتالة": 1,
    "عتامة": 1,
    "عتاھة": 1,
    "عتب": 1,
    "عتد": 1,
    "عتق": 1,
    "عتك": 1,
    "عتمة": 1,
    "عتي": 1,
    "عث": 1,
    "عثا": 1,
    "عثار": 1,
    "عثانين": 1,
    "عثث": 1,
    "عثرة": 1,
    "عثرتك": 1,
    "عثرته": 1,
    "عثنون": 1,
    "عثو": 1,
    "عثور": 1,
    "عثى": 1,
    "عثي": 1,
    "عثير": 1,
    "عج": 1,
    "عجائن": 1,
    "عجاج": 1,
    "عجاجة": 1,
    "عجال": 1,
    "عجالى": 1,
    "عجان": 1,
    "عجب": 1,
    "عجة": 1,
    "عجر": 1,
    "عجرة": 1,
    "عجرف": 1,
    "عجره": 1,
    "عجز": 1,
    "عجزا": 1,
    "عجزة": 1,
    "عجعج": 1,
    "عجعجة": 1,
    "عجفاء": 1,
    "عجلان": 1,
    "عجلى": 1,
    "عجم": 1,
    "عجماء": 1,
    "عجمة": 1,
    "عجموات": 1,
    "عجمي": 1,
    "عجن": 1,
    "عجوة": 1,
    "عجور": 1,
    "عجول": 1,
    "عجيج": 1,
    "عجيزة": 1,
    "عجيل": 1,
    "عجين": 1,
    "عجينة": 1,
    "عدائل": 1,
    "عداة": 1,
    "عدادھم": 1,
    "عدالة": 1,
    "عدت": 1,
    "عدته": 1,
    "عدلا": 1,
    "عدلى": 1,
    "عدلية": 1,
    "عدمية": 1,
    "عدنان": 1,
    "عدواء": 1,
    "عدوة": 1,
    "عدول": 1,
    "عدوى": 1,
    "عدى": 1,
    "عديدة": 1,
    "عديل": 1,
    "عذا": 1,
    "عذار": 1,
    "عذاره": 1,
    "عذارى": 1,
    "عذال": 1,
    "عذر": 1,
    "عذرة": 1,
    "عذق": 1,
    "عذل": 1,
    "عذو": 1,
    "عذوبة": 1,
    "عذول": 1,
    "عذى": 1,
    "عرا": 1,
    "عراء": 1,
    "عرائس": 1,
    "عرائش": 1,
    "عرائض": 1,
    "عرائن": 1,
    "عراب": 1,
    "عرابة": 1,
    "عرابين": 1,
    "عراة": 1,
    "عراض": 1,
    "عرافة": 1,
    "عراق": 1,
    "عراقة": 1,
    "عراقيب": 1,
    "عراقية": 1,
    "عراقيل": 1,
    "عراك": 1,
    "عرام": 1,
    "عرانيس": 1,
    "عرانين": 1,
    "عرايا": 1,
    "عربان": 1,
    "عربجي": 1,
    "عربخانة": 1,
    "عربس": 1,
    "عربن": 1,
    "عربون": 1,
    "عربية": 1,
    "عربيد": 1,
    "عرة": 1,
    "عرج": 1,
    "عرجاء": 1,
    "عرجان": 1,
    "عرر": 1,
    "عرزال": 1,
    "عرس": 1,
    "عرسات": 1,
    "عرسة": 1,
    "عرشا": 1,
    "عرص": 1,
    "عرضاني": 1,
    "عرضحال": 1,
    "عرضحالات": 1,
    "عرضك": 1,
    "عرضه": 1,
    "عرضيات": 1,
    "عرضية": 1,
    "عرفاء": 1,
    "عرفان": 1,
    "عرفانا": 1,
    "عرفت": 1,
    "عرفه": 1,
    "عرفى": 1,
    "عرفي": 1,
    "عرفية": 1,
    "عرقا": 1,
    "عرقب": 1,
    "عرقة": 1,
    "عرقلة": 1,
    "عرقوب": 1,
    "عرقوبي": 1,
    "عرقوبية": 1,
    "عرقية": 1,
    "عرك": 1,
    "عركة": 1,
    "عرم": 1,
    "عرمة": 1,
    "عرمرم": 1,
    "عرن": 1,
    "عرناس": 1,
    "عرنين": 1,
    "عرو": 1,
    "عروب": 1,
    "عروبة": 1,
    "عروة": 1,
    "عروج": 1,
    "عروسة": 1,
    "عروش": 1,
    "عروشه": 1,
    "عروض": 1,
    "عرى": 1,
    "عري": 1,
    "عريان": 1,
    "عريانية": 1,
    "عرية": 1,
    "عريس": 1,
    "عريف": 1,
    "عريق": 1,
    "عريقة": 1,
    "عريكة": 1,
    "عرينة": 1,
    "عزائم": 1,
    "عزائمه": 1,
    "عزاب": 1,
    "عزازة": 1,
    "عزال": 1,
    "عزب": 1,
    "عزبة": 1,
    "عزر": 1,
    "عزرائيل": 1,
    "عزق": 1,
    "عزلاء": 1,
    "عزلة": 1,
    "عزمة": 1,
    "عزمه": 1,
    "عزو": 1,
    "عزوب": 1,
    "عزوبة": 1,
    "عزوة": 1,
    "عزوف": 1,
    "عزوم": 1,
    "عزومة": 1,
    "عزى": 1,
    "عزي": 1,
    "عزيزي": 1,
    "عزيمته": 1,
    "عزھا": 1,
    "عس": 1,
    "عساف": 1,
    "عساكر": 1,
    "عسال": 1,
    "عسالة": 1,
    "عسالج": 1,
    "عساليج": 1,
    "عساه": 1,
    "عسب": 1,
    "عسجد": 1,
    "عسجدي": 1,
    "عسس": 1,
    "عسف": 1,
    "عسقلان": 1,
    "عسكرى": 1,
    "عسلج": 1,
    "عسلوج": 1,
    "عسلي": 1,
    "عسوف": 1,
    "عسول": 1,
    "عسي": 1,
    "عسيب": 1,
    "عسيون": 1,
    "عشا": 1,
    "عشائر": 1,
    "عشابة": 1,
    "عشار": 1,
    "عشاش": 1,
    "عشاق": 1,
    "عشاوة": 1,
    "عشايا": 1,
    "عشبية": 1,
    "عشراء": 1,
    "عشرات": 1,
    "عشش": 1,
    "عششة": 1,
    "عشو": 1,
    "عشواء": 1,
    "عشوة": 1,
    "عشور": 1,
    "عشوراء": 1,
    "عشى": 1,
    "عشير": 1,
    "عشيرة": 1,
    "عشيقة": 1,
    "عص": 1,
    "عصائب": 1,
    "عصاب": 1,
    "عصاة": 1,
    "عصار": 1,
    "عصاري": 1,
    "عصاعص": 1,
    "عصافة": 1,
    "عصام": 1,
    "عصامي": 1,
    "عصامية": 1,
    "عصاية": 1,
    "عصاھم": 1,
    "عصره": 1,
    "عصرى": 1,
    "عصص": 1,
    "عصعص": 1,
    "عصف": 1,
    "عصفر": 1,
    "عصفورة": 1,
    "عصفورية": 1,
    "عصفورين": 1,
    "عصل": 1,
    "عصم": 1,
    "عصماء": 1,
    "عصمة": 1,
    "عصمتھا": 1,
    "عصو": 1,
    "عصور": 1,
    "عصوف": 1,
    "عصى": 1,
    "عصي": 1,
    "عصيان": 1,
    "عصية": 1,
    "عصيدة": 1,
    "عصيرة": 1,
    "عضاض": 1,
    "عضاه": 1,
    "عضب": 1,
    "عضد": 1,
    "عضده": 1,
    "عضل": 1,
    "عضه": 1,
    "عضوض": 1,
    "عضيض": 1,
    "عطا": 1,
    "عطار": 1,
    "عطارة": 1,
    "عطارد": 1,
    "عطاس": 1,
    "عطاش": 1,
    "عطاف": 1,
    "عطالة": 1,
    "عطان": 1,
    "عطايا": 1,
    "عطب": 1,
    "عطرة": 1,
    "عطرية": 1,
    "عطس": 1,
    "عطسة": 1,
    "عطشجي": 1,
    "عطشجيات": 1,
    "عطشى": 1,
    "عطعط": 1,
    "عطف": 1,
    "عطفة": 1,
    "عطلة": 1,
    "عطن": 1,
    "عطو": 1,
    "عطورات": 1,
    "عطوف": 1,
    "عطوفة": 1,
    "عطية": 1,
    "عطين": 1,
    "عظائم": 1,
    "عظامة": 1,
    "عظامي": 1,
    "عظة": 1,
    "عظل": 1,
    "عظماء": 1,
    "عظموت": 1,
    "عظمى": 1,
    "عظيمة": 1,
    "عف": 1,
    "عفاء": 1,
    "عفارة": 1,
    "عفارم": 1,
    "عفاشة": 1,
    "عفاف": 1,
    "عفر": 1,
    "عفراء": 1,
    "عفرت": 1,
    "عفرتة": 1,
    "عفريتة": 1,
    "عفش": 1,
    "عفص": 1,
    "عفو": 1,
    "عفوصة": 1,
    "عق": 1,
    "عقائد": 1,
    "عقائص": 1,
    "عقائق": 1,
    "عقائل": 1,
    "عقابي": 1,
    "عقاد": 1,
    "عقادة": 1,
    "عقارب": 1,
    "عقارة": 1,
    "عقاري": 1,
    "عقارية": 1,
    "عقاص": 1,
    "عقاعق": 1,
    "عقال": 1,
    "عقاله": 1,
    "عقالھا": 1,
    "عقام": 1,
    "عقبان": 1,
    "عقبة": 1,
    "عقداء": 1,
    "عقده": 1,
    "عقدھم": 1,
    "عقر": 1,
    "عقص": 1,
    "عقعق": 1,
    "عقف": 1,
    "عقفاء": 1,
    "عقفة": 1,
    "عقلاء": 1,
    "عقلة": 1,
    "عقله": 1,
    "عقمة": 1,
    "عقوبات": 1,
    "عقوبة": 1,
    "عقور": 1,
    "عقوق": 1,
    "عقول": 1,
    "عقيب": 1,
    "عقيد": 1,
    "عقيدتي": 1,
    "عقيرة": 1,
    "عقيصة": 1,
    "عقيق": 1,
    "عقيقي": 1,
    "عقيلة": 1,
    "عقيلته": 1,
    "عك": 1,
    "عكا": 1,
    "عكاء": 1,
    "عكارة": 1,
    "عكاز": 1,
    "عكازة": 1,
    "عكاشة": 1,
    "عكاكيز": 1,
    "عكة": 1,
    "عكز": 1,
    "عكف": 1,
    "عكم": 1,
    "عكوف": 1,
    "عكيس": 1,
    "عكيك": 1,
    "علائف": 1,
    "علائق": 1,
    "علائم": 1,
    "علاب": 1,
    "علاة": 1,
    "علاته": 1,
    "علاف": 1,
    "علاق": 1,
    "علاقم": 1,
    "علالة": 1,
    "علالي": 1,
    "علاية": 1,
    "علبه": 1,
    "علة": 1,
    "علت": 1,
    "علته": 1,
    "علج": 1,
    "علف": 1,
    "علقم": 1,
    "علك": 1,
    "علل": 1,
    "علله": 1,
    "علمك": 1,
    "علمى": 1,
    "علن": 1,
    "علو": 1,
    "علوان": 1,
    "علوج": 1,
    "علوفة": 1,
    "علوق": 1,
    "علوما": 1,
    "علوية": 1,
    "عليقة": 1,
    "عليل": 1,
    "عليھا": 1,
    "عليھم": 1,
    "عماء": 1,
    "عمائر": 1,
    "عمائم": 1,
    "عماارة": 1,
    "عمارة": 1,
    "عمارية": 1,
    "عماص": 1,
    "عماقة": 1,
    "عمالة": 1,
    "عمالقة": 1,
    "عمالي": 1,
    "عمالية": 1,
    "عمامة": 1,
    "عماني": 1,
    "عماية": 1,
    "عمة": 1,
    "عمت": 1,
    "عمداء": 1,
    "عمدة": 1,
    "عمدي": 1,
    "عمراني": 1,
    "عمرة": 1,
    "عمرى": 1,
    "عمري": 1,
    "عملاء": 1,
    "عمن": 1,
    "عمه": 1,
    "عمولة": 1,
    "عمومة": 1,
    "عمومية": 1,
    "عمياء": 1,
    "عميان": 1,
    "عمية": 1,
    "عميدة": 1,
    "عميل": 1,
    "عميلة": 1,
    "عميم": 1,
    "عناء": 1,
    "عناب": 1,
    "عنابر": 1,
    "عناترة": 1,
    "عناد": 1,
    "عنادل": 1,
    "عناز": 1,
    "عناصل": 1,
    "عناق": 1,
    "عناقيد": 1,
    "عناكب": 1,
    "عناية": 1,
    "عنبر": 1,
    "عنبرة": 1,
    "عنة": 1,
    "عنت": 1,
    "عنتر": 1,
    "عنترى": 1,
    "عنترية": 1,
    "عنجھية": 1,
    "عندئذ": 1,
    "عندلة": 1,
    "عندليب": 1,
    "عندم": 1,
    "عندياته": 1,
    "عندھا": 1,
    "عنز": 1,
    "عنزة": 1,
    "عنصل": 1,
    "عنعنات": 1,
    "عنف": 1,
    "عنفوان": 1,
    "عنقاء": 1,
    "عنقاش": 1,
    "عنكبوت": 1,
    "عنن": 1,
    "عنو": 1,
    "عنوانا": 1,
    "عنوة": 1,
    "عنوز": 1,
    "عنوق": 1,
    "عنون": 1,
    "عنين": 1,
    "عواتق": 1,
    "عواثير": 1,
    "عواجز": 1,
    "عواد": 1,
    "عوادة": 1,
    "عوادم": 1,
    "عواذل": 1,
    "عوار": 1,
    "عواشق": 1,
    "عواصم": 1,
    "عواطف": 1,
    "عواطل": 1,
    "عواقب": 1,
    "عوالم": 1,
    "عوام": 1,
    "عواميد": 1,
    "عواھر": 1,
    "عواھل": 1,
    "عواھن": 1,
    "عواھنه": 1,
    "عوجاء": 1,
    "عودا": 1,
    "عوده": 1,
    "عوذ": 1,
    "عوذة": 1,
    "عور": 1,
    "عوراء": 1,
    "عوز": 1,
    "عوسج": 1,
    "عون": 1,
    "عويجة": 1,
    "عياد": 1,
    "عيادة": 1,
    "عياذ": 1,
    "عيالم": 1,
    "عياله": 1,
    "عيان": 1,
    "عير": 1,
    "عيسلان": 1,
    "عيشه": 1,
    "عيلم": 1,
    "عينيه": 1,
    "عھار": 1,
    "عھارة": 1,
    "عھد": 1,
    "عھدا": 1,
    "عھدة": 1,
    "عھدته": 1,
    "عھدنا": 1,
    "عھده": 1,
    "عھدھا": 1,
    "عھدھم": 1,
    "عھر": 1,
    "عھن": 1,
    "عھود": 1,
    "عھيد": 1,
    "غائر": 1,
    "غائلة": 1,
    "غائية": 1,
    "غاباني": 1,
    "غابر": 1,
    "غاث": 1,
    "غادة": 1,
    "غارات": 1,
    "غارب": 1,
    "غاربه": 1,
    "غارة": 1,
    "غازوزة": 1,
    "غازية": 1,
    "غاسول": 1,
    "غاشم": 1,
    "غاشمة": 1,
    "غاص": 1,
    "غاصب": 1,
    "غاض": 1,
    "غاظ": 1,
    "غاغة": 1,
    "غالبية": 1,
    "غالية": 1,
    "غام": 1,
    "غانة": 1,
    "غانم": 1,
    "غانما": 1,
    "غانية": 1,
    "غبا": 1,
    "غباره": 1,
    "غبارھم": 1,
    "غباشة": 1,
    "غباني": 1,
    "غباوة": 1,
    "غبب": 1,
    "غبة": 1,
    "غبر": 1,
    "غبراء": 1,
    "غبش": 1,
    "غبشاء": 1,
    "غبشة": 1,
    "غبط": 1,
    "غبطة": 1,
    "غبن": 1,
    "غبو": 1,
    "غبوة": 1,
    "غبور": 1,
    "غبون": 1,
    "غت": 1,
    "غث": 1,
    "غثاثة": 1,
    "غثت": 1,
    "غثوثة": 1,
    "غثي": 1,
    "غثيت": 1,
    "غثيث": 1,
    "غجر": 1,
    "غد": 1,
    "غداء": 1,
    "غدائر": 1,
    "غدار": 1,
    "غدارة": 1,
    "غداف": 1,
    "غدان": 1,
    "غدر": 1,
    "غدران": 1,
    "غدف": 1,
    "غدفة": 1,
    "غدق": 1,
    "غدن": 1,
    "غدنة": 1,
    "غدو": 1,
    "غدوات": 1,
    "غدواته": 1,
    "غدوة": 1,
    "غدوه": 1,
    "غدى": 1,
    "غدير": 1,
    "غديرة": 1,
    "غذا": 1,
    "غذاء": 1,
    "غذائية": 1,
    "غذو": 1,
    "غرا": 1,
    "غراء": 1,
    "غرائب": 1,
    "غرائر": 1,
    "غرائز": 1,
    "غرائس": 1,
    "غرائي": 1,
    "غرابلي": 1,
    "غرابلية": 1,
    "غرار": 1,
    "غرارا": 1,
    "غرارة": 1,
    "غراريف": 1,
    "غراس": 1,
    "غراسة": 1,
    "غراف": 1,
    "غرام": 1,
    "غراميات": 1,
    "غرامية": 1,
    "غرانيق": 1,
    "غراية": 1,
    "غربا": 1,
    "غرباء": 1,
    "غربال": 1,
    "غربة": 1,
    "غربه": 1,
    "غرة": 1,
    "غرد": 1,
    "غرر": 1,
    "غرز": 1,
    "غرزة": 1,
    "غرسة": 1,
    "غرش": 1,
    "غرض": 1,
    "غرضه": 1,
    "غرضي": 1,
    "غرغر": 1,
    "غرغرة": 1,
    "غرقان": 1,
    "غرقى": 1,
    "غرل": 1,
    "غرلان": 1,
    "غرلة": 1,
    "غرم": 1,
    "غرماء": 1,
    "غرناطة": 1,
    "غرنوق": 1,
    "غرنيق": 1,
    "غرو": 1,
    "غروب": 1,
    "غرود": 1,
    "غروش": 1,
    "غرول": 1,
    "غروى": 1,
    "غريد": 1,
    "غرير": 1,
    "غريزة": 1,
    "غريسة": 1,
    "غريض": 1,
    "غريق": 1,
    "غريم": 1,
    "غريمة": 1,
    "غرين": 1,
    "غز": 1,
    "غزا": 1,
    "غزاة": 1,
    "غزار": 1,
    "غزارى": 1,
    "غزال": 1,
    "غزالة": 1,
    "غزاوة": 1,
    "غزل": 1,
    "غزلي": 1,
    "غزو": 1,
    "غزوات": 1,
    "غزوان": 1,
    "غزوة": 1,
    "غزي": 1,
    "غس": 1,
    "غسال": 1,
    "غسلة": 1,
    "غشا": 1,
    "غشم": 1,
    "غشماء": 1,
    "غشو": 1,
    "غشوة": 1,
    "غشوم": 1,
    "غشومة": 1,
    "غشي": 1,
    "غشيان": 1,
    "غشية": 1,
    "غشيم": 1,
    "غصب": 1,
    "غصص": 1,
    "غصنة": 1,
    "غصون": 1,
    "غض": 1,
    "غضا": 1,
    "غضاب": 1,
    "غضابى": 1,
    "غضارة": 1,
    "غضاريف": 1,
    "غضاضة": 1,
    "غضباني": 1,
    "غضبة": 1,
    "غضبه": 1,
    "غضبى": 1,
    "غضة": 1,
    "غضر": 1,
    "غضروف": 1,
    "غضن": 1,
    "غضنفر": 1,
    "غضو": 1,
    "غضوضة": 1,
    "غضون": 1,
    "غضى": 1,
    "غضير": 1,
    "غضيض": 1,
    "غطا": 1,
    "غطارف": 1,
    "غطارفة": 1,
    "غطاريس": 1,
    "غطاريف": 1,
    "غطاس": 1,
    "غطرة": 1,
    "غطرس": 1,
    "غطرسة": 1,
    "غطريس": 1,
    "غطريف": 1,
    "غطش": 1,
    "غطم": 1,
    "غطو": 1,
    "غطيط": 1,
    "غطيطة": 1,
    "غف": 1,
    "غفا": 1,
    "غفائر": 1,
    "غفار": 1,
    "غفارة": 1,
    "غفرا": 1,
    "غفراء": 1,
    "غفران": 1,
    "غفرة": 1,
    "غفقة": 1,
    "غفلان": 1,
    "غفو": 1,
    "غفوة": 1,
    "غفول": 1,
    "غفى": 1,
    "غفية": 1,
    "غفير": 1,
    "غلاء": 1,
    "غلائل": 1,
    "غلاب": 1,
    "غلاة": 1,
    "غلاصم": 1,
    "غلاظة": 1,
    "غلاقة": 1,
    "غلال": 1,
    "غلالة": 1,
    "غلامية": 1,
    "غلاوين": 1,
    "غلايين": 1,
    "غلب": 1,
    "غلباوي": 1,
    "غلبة": 1,
    "غلة": 1,
    "غلته": 1,
    "غلس": 1,
    "غلصمة": 1,
    "غلط": 1,
    "غلطان": 1,
    "غلطة": 1,
    "غلظ": 1,
    "غلغل": 1,
    "غلفاء": 1,
    "غلفة": 1,
    "غلق": 1,
    "غلم": 1,
    "غلمة": 1,
    "غله": 1,
    "غلو": 1,
    "غلواء": 1,
    "غلوائه": 1,
    "غليان": 1,
    "غليظة": 1,
    "غليل": 1,
    "غليله": 1,
    "غماء": 1,
    "غمائم": 1,
    "غمار": 1,
    "غمارة": 1,
    "غمازة": 1,
    "غماض": 1,
    "غماضا": 1,
    "غماغم": 1,
    "غمام": 1,
    "غمامة": 1,
    "غمر": 1,
    "غمز": 1,
    "غمص": 1,
    "غمض": 1,
    "غمضا": 1,
    "غمضة": 1,
    "غمط": 1,
    "غمطه": 1,
    "غمغمة": 1,
    "غملاج": 1,
    "غملج": 1,
    "غمود": 1,
    "غمور": 1,
    "غمورة": 1,
    "غموس": 1,
    "غموضة": 1,
    "غموم": 1,
    "غمومه": 1,
    "غمى": 1,
    "غمي": 1,
    "غميزة": 1,
    "غنائم": 1,
    "غنادرة": 1,
    "غنام": 1,
    "غنة": 1,
    "غنج": 1,
    "غندر": 1,
    "غندقجي": 1,
    "غندور": 1,
    "غنغرينا": 1,
    "غنية": 1,
    "غنيمة": 1,
    "غوائل": 1,
    "غواة": 1,
    "غوارب": 1,
    "غواز": 1,
    "غواص": 1,
    "غوامض": 1,
    "غوان": 1,
    "غواية": 1,
    "غوايش": 1,
    "غوث": 1,
    "غوريلا": 1,
    "غوص": 1,
    "غوط": 1,
    "غوطى": 1,
    "غوغاء": 1,
    "غوي": 1,
    "غويشة": 1,
    "غويص": 1,
    "غويط": 1,
    "غى": 1,
    "غي": 1,
    "غيابة": 1,
    "غيابه": 1,
    "غيابي": 1,
    "غيابيا": 1,
    "غياث": 1,
    "غيار": 1,
    "غياري": 1,
    "غياص": 1,
    "غياصة": 1,
    "غياض": 1,
    "غياط": 1,
    "غيام": 1,
    "غياھب": 1,
    "غيبا": 1,
    "غيبة": 1,
    "غيبوبة": 1,
    "غية": 1,
    "غيتو": 1,
    "غيد": 1,
    "غيداء": 1,
    "غيران": 1,
    "غيرة": 1,
    "غيرترت": 1,
    "غيرما": 1,
    "غيرى": 1,
    "غيرية": 1,
    "غيرھا": 1,
    "غيض": 1,
    "غيضة": 1,
    "غيط": 1,
    "غيطان": 1,
    "غيظا": 1,
    "غيظه": 1,
    "غيل": 1,
    "غيلان": 1,
    "غيلة": 1,
    "غيلم": 1,
    "غيم": 1,
    "غين": 1,
    "غينة": 1,
    "غيني": 1,
    "غيه": 1,
    "غيوب": 1,
    "غيوث": 1,
    "غيوم": 1,
    "غيون": 1,
    "غيھب": 1,
    "غھب": 1,
    "فآنا": 1,
    "فأس": 1,
    "فأفأ": 1,
    "فأل": 1,
    "فأله": 1,
    "فأولا": 1,
    "فؤاده": 1,
    "فؤادية": 1,
    "فؤوس": 1,
    "فؤول": 1,
    "فئ": 1,
    "فئام": 1,
    "فاء": 1,
    "فائت": 1,
    "فائرة": 1,
    "فائزة": 1,
    "فابريقة": 1,
    "فابريكة": 1,
    "فات": 1,
    "فاتته": 1,
    "فاتك": 1,
    "فاته": 1,
    "فاجئ": 1,
    "فاجرة": 1,
    "فاجع": 1,
    "فاجعة": 1,
    "فاح": 1,
    "فاخورة": 1,
    "فاخوري": 1,
    "فاد": 1,
    "فادحة": 1,
    "فادن": 1,
    "فار": 1,
    "فارة": 1,
    "فارح": 1,
    "فارزة": 1,
    "فارسة": 1,
    "فارسي": 1,
    "فارط": 1,
    "فارع": 1,
    "فارق": 1,
    "فاره": 1,
    "فاروز": 1,
    "فاروق": 1,
    "فاروقية": 1,
    "فازلين": 1,
    "فاسياء": 1,
    "فاشستي": 1,
    "فاشستية": 1,
    "فاصلة": 1,
    "فاصمى": 1,
    "فاصوليا": 1,
    "فاضت": 1,
    "فاضل": 1,
    "فاطمي": 1,
    "فاعلية": 1,
    "فاغية": 1,
    "فاقة": 1,
    "فاقدو": 1,
    "فاقعة": 1,
    "فاكثر": 1,
    "فاكه": 1,
    "فاكون": 1,
    "فاكھاني": 1,
    "فال": 1,
    "فالاول": 1,
    "فالت": 1,
    "فالج": 1,
    "فالس": 1,
    "فالوذج": 1,
    "فالوذجى": 1,
    "فالية": 1,
    "فانلا": 1,
    "فانلات": 1,
    "فانلة": 1,
    "فانوس": 1,
    "فاوريقة": 1,
    "فايظ": 1,
    "فايظجي": 1,
    "فبارك": 1,
    "فبراير": 1,
    "فبھا": 1,
    "فت": 1,
    "فتء": 1,
    "فتأ": 1,
    "فتئ": 1,
    "فتاء": 1,
    "فتائل": 1,
    "فتاح": 1,
    "فتاحة": 1,
    "فتاش": 1,
    "فتافيت": 1,
    "فتاق": 1,
    "فتال": 1,
    "فتاو": 1,
    "فتاوى": 1,
    "فتة": 1,
    "فتر": 1,
    "فتفت": 1,
    "فتفوتة": 1,
    "فتق": 1,
    "فتقت": 1,
    "فتك": 1,
    "فتكا": 1,
    "فتكة": 1,
    "فتل": 1,
    "فتلة": 1,
    "فتو": 1,
    "فتوة": 1,
    "فتوح": 1,
    "فتوحات": 1,
    "فتور": 1,
    "فتوق": 1,
    "فتون": 1,
    "فتوى": 1,
    "فتيا": 1,
    "فتيت": 1,
    "فتيتة": 1,
    "فتيق": 1,
    "فتيل": 1,
    "فثء": 1,
    "فثأ": 1,
    "فجء": 1,
    "فجأ": 1,
    "فجئ": 1,
    "فجا": 1,
    "فجاءة": 1,
    "فجائع": 1,
    "فجائي": 1,
    "فجاج": 1,
    "فجار": 1,
    "فجاعة": 1,
    "فجرة": 1,
    "فجع": 1,
    "فجعة": 1,
    "فجعنة": 1,
    "فجفج": 1,
    "فجل": 1,
    "فجو": 1,
    "فجور": 1,
    "فجوع": 1,
    "فجول": 1,
    "فجيعة": 1,
    "فح": 1,
    "فحاش": 1,
    "فحام": 1,
    "فحش": 1,
    "فحل": 1,
    "فحمة": 1,
    "فحواه": 1,
    "فحوص": 1,
    "فحوم": 1,
    "فحومات": 1,
    "فحومة": 1,
    "فحوى": 1,
    "فحيح": 1,
    "فحيم": 1,
    "فخ": 1,
    "فخا": 1,
    "فخاخ": 1,
    "فخار": 1,
    "فخاري": 1,
    "فخت": 1,
    "فخذة": 1,
    "فخرة": 1,
    "فخطوة": 1,
    "فخفخ": 1,
    "فخفخة": 1,
    "فخوخ": 1,
    "فخير": 1,
    "فداءه": 1,
    "فدائي": 1,
    "فدائيون": 1,
    "فداحة": 1,
    "فدادين": 1,
    "فدافد": 1,
    "فداك": 1,
    "فدام": 1,
    "فدح": 1,
    "فدخ": 1,
    "فدر": 1,
    "فدفد": 1,
    "فدم": 1,
    "فدن": 1,
    "فدور": 1,
    "فدى": 1,
    "فديات": 1,
    "فذ": 1,
    "فذلكة": 1,
    "فذوذ": 1,
    "فرأ": 1,
    "فراء": 1,
    "فرائد": 1,
    "فرائس": 1,
    "فرائص": 1,
    "فرائصه": 1,
    "فرائض": 1,
    "فراد": 1,
    "فرادا": 1,
    "فراديس": 1,
    "فرار": 1,
    "فرارجي": 1,
    "فراره": 1,
    "فراريج": 1,
    "فرازة": 1,
    "فرازين": 1,
    "فراسة": 1,
    "فراسخ": 1,
    "فراطة": 1,
    "فراعنة": 1,
    "فراغا": 1,
    "فراك": 1,
    "فرامت": 1,
    "فرامين": 1,
    "فراولة": 1,
    "فراھة": 1,
    "فرتيكة": 1,
    "فرجار": 1,
    "فرجة": 1,
    "فرجون": 1,
    "فرحه": 1,
    "فرخان": 1,
    "فرخة": 1,
    "فردة": 1,
    "فردوسي": 1,
    "فردى": 1,
    "فردية": 1,
    "فرز": 1,
    "فرزان": 1,
    "فرسان": 1,
    "فرسخ": 1,
    "فرسه": 1,
    "فرسوفيا": 1,
    "فرشاية": 1,
    "فرشة": 1,
    "فرشح": 1,
    "فرشحة": 1,
    "فرشخ": 1,
    "فرشينة": 1,
    "فرص": 1,
    "فرصاد": 1,
    "فرضة": 1,
    "فرضي": 1,
    "فرطح": 1,
    "فرعاء": 1,
    "فرفر": 1,
    "فرفش": 1,
    "فرفشة": 1,
    "فرفور": 1,
    "فرفوري": 1,
    "فرفير": 1,
    "فرقان": 1,
    "فرقد": 1,
    "فرقع": 1,
    "فرقعة": 1,
    "فرقلة": 1,
    "فرك": 1,
    "فركش": 1,
    "فرم": 1,
    "فرمبواز": 1,
    "فرمسون": 1,
    "فرملجي": 1,
    "فرموزا": 1,
    "فرنج": 1,
    "فرنجة": 1,
    "فرنجي": 1,
    "فرند": 1,
    "فرندة": 1,
    "فرنس": 1,
    "فرنساوي": 1,
    "فرنسة": 1,
    "فرنسوي": 1,
    "فرنك": 1,
    "فرنكة": 1,
    "فره": 1,
    "فروخ": 1,
    "فرود": 1,
    "فرودة": 1,
    "فروسة": 1,
    "فروسية": 1,
    "فروش": 1,
    "فروض": 1,
    "فروغ": 1,
    "فروق": 1,
    "فرى": 1,
    "فرية": 1,
    "فريدة": 1,
    "فريرة": 1,
    "فريسة": 1,
    "فريسته": 1,
    "فريسي": 1,
    "فريصة": 1,
    "فريصته": 1,
    "فز": 1,
    "فزارة": 1,
    "فزاعة": 1,
    "فزة": 1,
    "فزر": 1,
    "فزعان": 1,
    "فزورة": 1,
    "فسا": 1,
    "فسائل": 1,
    "فساتين": 1,
    "فساح": 1,
    "فساحة": 1,
    "فساطيط": 1,
    "فساطين": 1,
    "فسافس": 1,
    "فسافيس": 1,
    "فساق": 1,
    "فساقي": 1,
    "فستق": 1,
    "فسح": 1,
    "فسحة": 1,
    "فسخة": 1,
    "فسخي": 1,
    "فسد": 1,
    "فسدي": 1,
    "فسطاط": 1,
    "فسطان": 1,
    "فسفات": 1,
    "فسفر": 1,
    "فسفس": 1,
    "فسفوسة": 1,
    "فسقة": 1,
    "فسقية": 1,
    "فسل": 1,
    "فسلجة": 1,
    "فسو": 1,
    "فسود": 1,
    "فسول": 1,
    "فسولية": 1,
    "فسيحا": 1,
    "فسيخ": 1,
    "فسيفساء": 1,
    "فسيل": 1,
    "فسيلة": 1,
    "فسيولوجيا": 1,
    "فش": 1,
    "فشا": 1,
    "فشار": 1,
    "فشبرا": 1,
    "فشة": 1,
    "فشخ": 1,
    "فشر": 1,
    "فشش": 1,
    "فشفاش": 1,
    "فشك": 1,
    "فشو": 1,
    "فشي": 1,
    "فشيئا": 1,
    "فص": 1,
    "فصائد": 1,
    "فصائل": 1,
    "فصاح": 1,
    "فصاحة": 1,
    "فصاد": 1,
    "فصادة": 1,
    "فصال": 1,
    "فصح": 1,
    "فصحى": 1,
    "فصد": 1,
    "فصفات": 1,
    "فصفور": 1,
    "فصفوري": 1,
    "فصلان": 1,
    "فصلة": 1,
    "فصم": 1,
    "فصوح": 1,
    "فصوص": 1,
    "فصوليا": 1,
    "فصولية": 1,
    "فصومات": 1,
    "فصى": 1,
    "فصيل": 1,
    "فضا": 1,
    "فضائح": 1,
    "فضائل": 1,
    "فضائي": 1,
    "فضائية": 1,
    "فضاح": 1,
    "فضالة": 1,
    "فضح": 1,
    "فضلاء": 1,
    "فضلة": 1,
    "فضلى": 1,
    "فضليات": 1,
    "فضو": 1,
    "فضول": 1,
    "فضولھا": 1,
    "فضيات": 1,
    "فضيح": 1,
    "فضيحة": 1,
    "فضيل": 1,
    "فضيلة": 1,
    "فطائس": 1,
    "فطاحل": 1,
    "فطاطري": 1,
    "فطاطرية": 1,
    "فطام": 1,
    "فطانة": 1,
    "فطايري": 1,
    "فطايرية": 1,
    "فطح": 1,
    "فطحل": 1,
    "فطرة": 1,
    "فطس": 1,
    "فطناء": 1,
    "فطوس": 1,
    "فطيس": 1,
    "فطيسة": 1,
    "فطيم": 1,
    "فظائع": 1,
    "فظع": 1,
    "فعالية": 1,
    "فعبارة": 1,
    "فعلة": 1,
    "فعلية": 1,
    "فعم": 1,
    "فغر": 1,
    "فغرة": 1,
    "فغفوري": 1,
    "فقأ": 1,
    "فقاح": 1,
    "فقار": 1,
    "فقارة": 1,
    "فقاعة": 1,
    "فقاقيع": 1,
    "فقامة": 1,
    "فقاھة": 1,
    "فقتلوه": 1,
    "فقحة": 1,
    "فقر": 1,
    "فقرية": 1,
    "فقش": 1,
    "فقص": 1,
    "فقع": 1,
    "فقم": 1,
    "فقنس": 1,
    "فقوس": 1,
    "فقوص": 1,
    "فقوم": 1,
    "فقيد": 1,
    "فقيه": 1,
    "فقھاء": 1,
    "فقھي": 1,
    "فكاك": 1,
    "فكاكز": 1,
    "فكاھة": 1,
    "فكاھي": 1,
    "فكاھية": 1,
    "فكة": 1,
    "فكره": 1,
    "فكرى": 1,
    "فكش": 1,
    "فكلمة": 1,
    "فكه": 1,
    "فكوك": 1,
    "فكير": 1,
    "فلائك": 1,
    "فلائكي": 1,
    "فلاة": 1,
    "فلاتي": 1,
    "فلاتية": 1,
    "فلاحي": 1,
    "فلاذي": 1,
    "فلاسفة": 1,
    "فلاق": 1,
    "فلال": 1,
    "فلامنكى": 1,
    "فلانة": 1,
    "فلاني": 1,
    "فلاوى": 1,
    "فلايكي": 1,
    "فلة": 1,
    "فلت": 1,
    "فلتاء": 1,
    "فلتة": 1,
    "فلج": 1,
    "فلجان": 1,
    "فلح": 1,
    "فلذ": 1,
    "فلذة": 1,
    "فلز": 1,
    "فلس": 1,
    "فلسطيني": 1,
    "فلطاح": 1,
    "فلع": 1,
    "فلفل": 1,
    "فلفلة": 1,
    "فلفلي": 1,
    "فلق": 1,
    "فلقة": 1,
    "فلمندي": 1,
    "فلندرة": 1,
    "فلنكة": 1,
    "فلوات": 1,
    "فلوج": 1,
    "فلور": 1,
    "فلوس": 1,
    "فلوع": 1,
    "فلوق": 1,
    "فلوكة": 1,
    "فلول": 1,
    "فلى": 1,
    "فليا": 1,
    "فلية": 1,
    "فليذھب": 1,
    "فليسقط": 1,
    "فليق": 1,
    "فلين": 1,
    "فلينة": 1,
    "فليه": 1,
    "فليون": 1,
    "فمه": 1,
    "فمي": 1,
    "فناجيل": 1,
    "فناجين": 1,
    "فنار": 1,
    "فناطيس": 1,
    "فنانة": 1,
    "فنانون": 1,
    "فنجال": 1,
    "فنجان": 1,
    "فنجانة": 1,
    "فنجر": 1,
    "فنخ": 1,
    "فنط": 1,
    "فنطاس": 1,
    "فنطيس": 1,
    "فنطيسة": 1,
    "فنغراف": 1,
    "فنق": 1,
    "فنك": 1,
    "فنلندا": 1,
    "فنلندي": 1,
    "فنن": 1,
    "فنون": 1,
    "فنيقي": 1,
    "فنيقيا": 1,
    "فنيقية": 1,
    "فنيك": 1,
    "فوائض": 1,
    "فوائه": 1,
    "فوات": 1,
    "فواتح": 1,
    "فواتر": 1,
    "فواتن": 1,
    "فواتير": 1,
    "فواجر": 1,
    "فواجع": 1,
    "فواح": 1,
    "فواحدا": 1,
    "فواحش": 1,
    "فوادح": 1,
    "فوادن": 1,
    "فوار": 1,
    "فوارة": 1,
    "فوارس": 1,
    "فوارق": 1,
    "فواريق": 1,
    "فوازر": 1,
    "فواصل": 1,
    "فواضل": 1,
    "فواقع": 1,
    "فواكين": 1,
    "فوال": 1,
    "فوانيس": 1,
    "فوة": 1,
    "فوتيه": 1,
    "فوح": 1,
    "فوحان": 1,
    "فوحة": 1,
    "فور": 1,
    "فورة": 1,
    "فورشة": 1,
    "فورشينة": 1,
    "فوره": 1,
    "فورى": 1,
    "فوريقة": 1,
    "فورھا": 1,
    "فوسفات": 1,
    "فوصفور": 1,
    "فوضوية": 1,
    "فوضى": 1,
    "فوط": 1,
    "فوطة": 1,
    "فوعة": 1,
    "فوف": 1,
    "فوفة": 1,
    "فوقاني": 1,
    "فولاذ": 1,
    "فونوغراف": 1,
    "فووق": 1,
    "فويت": 1,
    "فوھة": 1,
    "فيء": 1,
    "فيأ": 1,
    "فياح": 1,
    "فياش": 1,
    "فياف": 1,
    "فيالج": 1,
    "فيالق": 1,
    "فيتون": 1,
    "فيحاء": 1,
    "فيد": 1,
    "فيدرالي": 1,
    "فيدرالية": 1,
    "فيران": 1,
    "فيروز": 1,
    "فيروزج": 1,
    "فيسيولوجيا": 1,
    "فيش": 1,
    "فيصل": 1,
    "فيض": 1,
    "فيضان": 1,
    "فيظ": 1,
    "فيفاء": 1,
    "فيقة": 1,
    "فيكونت": 1,
    "فيلات": 1,
    "فيلج": 1,
    "فيلجة": 1,
    "فيلق": 1,
    "فيلولة": 1,
    "فيلولوجيا": 1,
    "فينان": 1,
    "فينة": 1,
    "فينيقي": 1,
    "فيوء": 1,
    "فيوض": 1,
    "فيول": 1,
    "فيولة": 1,
    "فيوما": 1,
    "فيينا": 1,
    "فيھا": 1,
    "فيھقة": 1,
    "فھارس": 1,
    "فھامة": 1,
    "فھاھة": 1,
    "فھد": 1,
    "فھرس": 1,
    "فھرسة": 1,
    "فھرست": 1,
    "فھم": 1,
    "فھماء": 1,
    "فھود": 1,
    "فھيق": 1,
    "فھيم": 1,
    "قؤود": 1,
    "قاء": 1,
    "قائظ": 1,
    "قائلا": 1,
    "قائلة": 1,
    "قائممقام": 1,
    "قائممقامية": 1,
    "قابض": 1,
    "قابضة": 1,
    "قابله": 1,
    "قابوس": 1,
    "قات": 1,
    "قاتر": 1,
    "قاتلات": 1,
    "قاتله": 1,
    "قاح": 1,
    "قاحة": 1,
    "قادات": 1,
    "قادة": 1,
    "قادحة": 1,
    "قادرة": 1,
    "قادس": 1,
    "قادوس": 1,
    "قاذفة": 1,
    "قاذورة": 1,
    "قار": 1,
    "قارس": 1,
    "قارص": 1,
    "قازوزة": 1,
    "قاسى": 1,
    "قاشاني": 1,
    "قاص": 1,
    "قاصد": 1,
    "قاصرة": 1,
    "قاصل": 1,
    "قاصم": 1,
    "قاصمة": 1,
    "قاصون": 1,
    "قاضية": 1,
    "قاطبة": 1,
    "قاطرات": 1,
    "قاطرة": 1,
    "قاف": 1,
    "قافل": 1,
    "قافلة": 1,
    "قافية": 1,
    "قاق": 1,
    "قاقلة": 1,
    "قاقلى": 1,
    "قاقلي": 1,
    "قاقم": 1,
    "قالبه": 1,
    "قالة": 1,
    "قالته": 1,
    "قاله": 1,
    "قالوش": 1,
    "قامة": 1,
    "قامر": 1,
    "قان": 1,
    "قانئ": 1,
    "قانص": 1,
    "قانصة": 1,
    "قانط": 1,
    "قانونا": 1,
    "قانية": 1,
    "قاه": 1,
    "قاورمة": 1,
    "قاوق": 1,
    "قاووش": 1,
    "قاووق": 1,
    "قاوون": 1,
    "قايش": 1,
    "قاھر": 1,
    "قاھرة": 1,
    "قاھري": 1,
    "قب": 1,
    "قبا": 1,
    "قباء": 1,
    "قبائح": 1,
    "قبائل": 1,
    "قباب": 1,
    "قباج": 1,
    "قباح": 1,
    "قباحة": 1,
    "قباحى": 1,
    "قبار": 1,
    "قباض": 1,
    "قباضة": 1,
    "قباطنة": 1,
    "قباطين": 1,
    "قباع": 1,
    "قباقيب": 1,
    "قباقيبي": 1,
    "قبال": 1,
    "قبان": 1,
    "قبج": 1,
    "قبح": 1,
    "قبحا": 1,
    "قبحى": 1,
    "قبر": 1,
    "قبرس": 1,
    "قبسة": 1,
    "قبص": 1,
    "قبصة": 1,
    "قبضاى": 1,
    "قبضت": 1,
    "قبضه": 1,
    "قبط": 1,
    "قبطان": 1,
    "قبطي": 1,
    "قبع": 1,
    "قبعته": 1,
    "قبقاب": 1,
    "قبقب": 1,
    "قبلئذ": 1,
    "قبلى": 1,
    "قبن": 1,
    "قبو": 1,
    "قبوة": 1,
    "قبودان": 1,
    "قبور": 1,
    "قبوع": 1,
    "قبيحة": 1,
    "قبيل": 1,
    "قت": 1,
    "قتات": 1,
    "قتاد": 1,
    "قتار": 1,
    "قتام": 1,
    "قتب": 1,
    "قتلة": 1,
    "قتلى": 1,
    "قتم": 1,
    "قتمة": 1,
    "قتور": 1,
    "قتوم": 1,
    "قتيل": 1,
    "قتيلا": 1,
    "قث": 1,
    "قثاء": 1,
    "قثاطير": 1,
    "قثطرة": 1,
    "قح": 1,
    "قحاب": 1,
    "قحاف": 1,
    "قحبة": 1,
    "قحة": 1,
    "قحط": 1,
    "قحطان": 1,
    "قحف": 1,
    "قحفة": 1,
    "قحل": 1,
    "قحلاء": 1,
    "قحم": 1,
    "قحمة": 1,
    "قحوط": 1,
    "قحوف": 1,
    "قحولة": 1,
    "قدا": 1,
    "قدائم": 1,
    "قداح": 1,
    "قداحة": 1,
    "قداديس": 1,
    "قداسة": 1,
    "قدام": 1,
    "قدامى": 1,
    "قداوة": 1,
    "قدة": 1,
    "قدد": 1,
    "قدرھا": 1,
    "قدسية": 1,
    "قدماء": 1,
    "قدمان": 1,
    "قدمه": 1,
    "قدميه": 1,
    "قده": 1,
    "قدو": 1,
    "قدوة": 1,
    "قدود": 1,
    "قدور": 1,
    "قدوس": 1,
    "قدوم": 1,
    "قدى": 1,
    "قدي": 1,
    "قديد": 1,
    "قديس": 1,
    "قذائف": 1,
    "قذاة": 1,
    "قذاذ": 1,
    "قذاف": 1,
    "قذافة": 1,
    "قذال": 1,
    "قذة": 1,
    "قذذ": 1,
    "قذع": 1,
    "قذفه": 1,
    "قذل": 1,
    "قذى": 1,
    "قر": 1,
    "قرء": 1,
    "قرآني": 1,
    "قرأ": 1,
    "قراء": 1,
    "قرائح": 1,
    "قرائن": 1,
    "قراب": 1,
    "قرابيس": 1,
    "قرابين": 1,
    "قراج": 1,
    "قراح": 1,
    "قراد": 1,
    "قرارات": 1,
    "قرارة": 1,
    "قرارلا": 1,
    "قراريط": 1,
    "قراصة": 1,
    "قراصن": 1,
    "قراصية": 1,
    "قراصين": 1,
    "قراضة": 1,
    "قراط": 1,
    "قراع": 1,
    "قرافة": 1,
    "قراقر": 1,
    "قراقيش": 1,
    "قرام": 1,
    "قرامطة": 1,
    "قراميد": 1,
    "قراميط": 1,
    "قران": 1,
    "قراني": 1,
    "قرايا": 1,
    "قربان": 1,
    "قربانة": 1,
    "قربوس": 1,
    "قربينة": 1,
    "قرحة": 1,
    "قردان": 1,
    "قرس": 1,
    "قرش": 1,
    "قرشا": 1,
    "قرشي": 1,
    "قرصان": 1,
    "قرصة": 1,
    "قرصنة": 1,
    "قرصه": 1,
    "قرصي": 1,
    "قرط": 1,
    "قرطاجنة": 1,
    "قرطجنة": 1,
    "قرطس": 1,
    "قرطم": 1,
    "قرطمان": 1,
    "قرظ": 1,
    "قرعة": 1,
    "قرعه": 1,
    "قرعي": 1,
    "قرف": 1,
    "قرفال": 1,
    "قرفان": 1,
    "قرفة": 1,
    "قرفص": 1,
    "قرفصاء": 1,
    "قرق": 1,
    "قرقذان": 1,
    "قرقر": 1,
    "قرقض": 1,
    "قرقع": 1,
    "قرقعة": 1,
    "قرقوز": 1,
    "قرقوش": 1,
    "قرقوشة": 1,
    "قرقول": 1,
    "قرم": 1,
    "قرمة": 1,
    "قرمد": 1,
    "قرمز": 1,
    "قرمش": 1,
    "قرمطي": 1,
    "قرموط": 1,
    "قرميد": 1,
    "قرنبيط": 1,
    "قرنة": 1,
    "قرنفل": 1,
    "قرنه": 1,
    "قرني": 1,
    "قرنية": 1,
    "قره": 1,
    "قرو": 1,
    "قرواطي": 1,
    "قرواطيا": 1,
    "قروح": 1,
    "قروش": 1,
    "قروض": 1,
    "قروط": 1,
    "قروم": 1,
    "قروى": 1,
    "قروي": 1,
    "قروية": 1,
    "قرويون": 1,
    "قريح": 1,
    "قريحة": 1,
    "قريدس": 1,
    "قرير": 1,
    "قريشة": 1,
    "قريص": 1,
    "قريض": 1,
    "قريظ": 1,
    "قريع": 1,
    "قريفة": 1,
    "قرينته": 1,
    "قز": 1,
    "قزائز": 1,
    "قزاز": 1,
    "قزازة": 1,
    "قزان": 1,
    "قزحية": 1,
    "قزع": 1,
    "قزعة": 1,
    "قزقز": 1,
    "قزل": 1,
    "قزموغرافي": 1,
    "قزموغرافيا": 1,
    "قزوز": 1,
    "قزيعة": 1,
    "قسا": 1,
    "قساء": 1,
    "قسائم": 1,
    "قساة": 1,
    "قساس": 1,
    "قساطل": 1,
    "قساطيس": 1,
    "قسام": 1,
    "قسامة": 1,
    "قسان": 1,
    "قساو": 1,
    "قساوة": 1,
    "قساوسة": 1,
    "قسر": 1,
    "قسرا": 1,
    "قسس": 1,
    "قسط": 1,
    "قسطاس": 1,
    "قسطل": 1,
    "قسما": 1,
    "قسماء": 1,
    "قسمات": 1,
    "قسنطينة": 1,
    "قسو": 1,
    "قسوس": 1,
    "قسوسة": 1,
    "قسوسية": 1,
    "قسى": 1,
    "قسي": 1,
    "قسيس": 1,
    "قسيم": 1,
    "قسيمة": 1,
    "قش": 1,
    "قشاط": 1,
    "قشافة": 1,
    "قشب": 1,
    "قشة": 1,
    "قشد": 1,
    "قشدة": 1,
    "قشط": 1,
    "قشطة": 1,
    "قشع": 1,
    "قشعر": 1,
    "قشف": 1,
    "قشفة": 1,
    "قشقش": 1,
    "قشل": 1,
    "قشلاق": 1,
    "قشلة": 1,
    "قشمش": 1,
    "قشيب": 1,
    "قشيش": 1,
    "قصا": 1,
    "قصاء": 1,
    "قصاب": 1,
    "قصابة": 1,
    "قصاج": 1,
    "قصاد": 1,
    "قصادة": 1,
    "قصار": 1,
    "قصاراك": 1,
    "قصاراه": 1,
    "قصارة": 1,
    "قصارك": 1,
    "قصارى": 1,
    "قصاصة": 1,
    "قصاع": 1,
    "قصال": 1,
    "قصب": 1,
    "قصبة": 1,
    "قصدا": 1,
    "قصدك": 1,
    "قصده": 1,
    "قصدي": 1,
    "قصدير": 1,
    "قصرك": 1,
    "قصرية": 1,
    "قصصيون": 1,
    "قصع": 1,
    "قصعة": 1,
    "قصقص": 1,
    "قصل": 1,
    "قصم": 1,
    "قصور": 1,
    "قصوف": 1,
    "قصوى": 1,
    "قصى": 1,
    "قصي": 1,
    "قصيبة": 1,
    "قصيد": 1,
    "قصيف": 1,
    "قصيل": 1,
    "قصيم": 1,
    "قض": 1,
    "قضائيون": 1,
    "قضابة": 1,
    "قضاع": 1,
    "قضامة": 1,
    "قضب": 1,
    "قضة": 1,
    "قضض": 1,
    "قضع": 1,
    "قضف": 1,
    "قضوية": 1,
    "قضيته": 1,
    "قضيض": 1,
    "قضيضھم": 1,
    "قضھم": 1,
    "قطا": 1,
    "قطائع": 1,
    "قطائف": 1,
    "قطاة": 1,
    "قطارة": 1,
    "قطاط": 1,
    "قطاعات": 1,
    "قطاف": 1,
    "قطان": 1,
    "قطاني": 1,
    "قطانية": 1,
    "قطايف": 1,
    "قطبة": 1,
    "قطرجي": 1,
    "قطرميز": 1,
    "قطرن": 1,
    "قططة": 1,
    "قطعاء": 1,
    "قطعان": 1,
    "قطعيا": 1,
    "قطعية": 1,
    "قطف": 1,
    "قطقوطة": 1,
    "قطل": 1,
    "قطم": 1,
    "قطمة": 1,
    "قطميرا": 1,
    "قطنية": 1,
    "قطوب": 1,
    "قطورات": 1,
    "قطوع": 1,
    "قطوف": 1,
    "قطون": 1,
    "قطيرة": 1,
    "قطيطة": 1,
    "قطيع": 1,
    "قطيفة": 1,
    "قطيلة": 1,
    "قعائد": 1,
    "قعاد": 1,
    "قعارة": 1,
    "قعاقع": 1,
    "قعد": 1,
    "قعدان": 1,
    "قعدة": 1,
    "قعدت": 1,
    "قعدي": 1,
    "قعرة": 1,
    "قعس": 1,
    "قعقع": 1,
    "قعقعة": 1,
    "قعور": 1,
    "قعيدة": 1,
    "قعير": 1,
    "قفا": 1,
    "قفاء": 1,
    "قفار": 1,
    "قفاطين": 1,
    "قفافيز": 1,
    "قفال": 1,
    "قفة": 1,
    "قفر": 1,
    "قفراء": 1,
    "قفران": 1,
    "قفرة": 1,
    "قفزان": 1,
    "قفش": 1,
    "قفشات": 1,
    "قفطان": 1,
    "قفع": 1,
    "قفف": 1,
    "قفقف": 1,
    "قفقفة": 1,
    "قفو": 1,
    "قفوف": 1,
    "قفي": 1,
    "قفير": 1,
    "قفيز": 1,
    "قلا": 1,
    "قلاء": 1,
    "قلائد": 1,
    "قلائل": 1,
    "قلاب": 1,
    "قلابة": 1,
    "قلاح": 1,
    "قلادة": 1,
    "قلاشين": 1,
    "قلاعي": 1,
    "قلافة": 1,
    "قلاقل": 1,
    "قلال": 1,
    "قلالي": 1,
    "قلامة": 1,
    "قلانيس": 1,
    "قلاووز": 1,
    "قلاووظ": 1,
    "قلاية": 1,
    "قلبا": 1,
    "قلبان": 1,
    "قلبة": 1,
    "قلبق": 1,
    "قلبيا": 1,
    "قلح": 1,
    "قلده": 1,
    "قلس": 1,
    "قلش": 1,
    "قلشين": 1,
    "قلع": 1,
    "قلعط": 1,
    "قلعه": 1,
    "قلعوط": 1,
    "قلف": 1,
    "قلفة": 1,
    "قلفته": 1,
    "قلفط": 1,
    "قلفون": 1,
    "قلفونية": 1,
    "قلقاس": 1,
    "قلقل": 1,
    "قلقلة": 1,
    "قلقيلة": 1,
    "قلمه": 1,
    "قلندار": 1,
    "قلنس": 1,
    "قلنسية": 1,
    "قلو": 1,
    "قلوبات": 1,
    "قلوبھم": 1,
    "قلوز": 1,
    "قلوس": 1,
    "قلوظ": 1,
    "قلوع": 1,
    "قلوق": 1,
    "قليب": 1,
    "قلية": 1,
    "قليط": 1,
    "قمؤ": 1,
    "قماءة": 1,
    "قمائم": 1,
    "قماح": 1,
    "قمارق": 1,
    "قماري": 1,
    "قماص": 1,
    "قماط": 1,
    "قماطر": 1,
    "قماقم": 1,
    "قمامة": 1,
    "قمامصة": 1,
    "قمح": 1,
    "قمحة": 1,
    "قمحي": 1,
    "قمراء": 1,
    "قمرق": 1,
    "قمرية": 1,
    "قمز": 1,
    "قمس": 1,
    "قمش": 1,
    "قمشة": 1,
    "قمص": 1,
    "قمصان": 1,
    "قمطة": 1,
    "قمطر": 1,
    "قمع": 1,
    "قمقم": 1,
    "قمقمة": 1,
    "قمل": 1,
    "قمن": 1,
    "قموع": 1,
    "قميء": 1,
    "قمير": 1,
    "قمين": 1,
    "قمينة": 1,
    "قن": 1,
    "قنا": 1,
    "قناء": 1,
    "قنابر": 1,
    "قنابل": 1,
    "قنابيز": 1,
    "قناته": 1,
    "قناج": 1,
    "قنادق": 1,
    "قناديل": 1,
    "قنازع": 1,
    "قناص": 1,
    "قناصة": 1,
    "قناصل": 1,
    "قناطة": 1,
    "قناطر": 1,
    "قناطير": 1,
    "قناعات": 1,
    "قناعة": 1,
    "قنال": 1,
    "قنان": 1,
    "قناية": 1,
    "قنبار": 1,
    "قنباز": 1,
    "قنبر": 1,
    "قنبرة": 1,
    "قنبل": 1,
    "قنبلة": 1,
    "قنبور": 1,
    "قنبي": 1,
    "قنبيط": 1,
    "قنة": 1,
    "قنت": 1,
    "قنجة": 1,
    "قند": 1,
    "قندز": 1,
    "قندس": 1,
    "قندق": 1,
    "قندلفت": 1,
    "قندول": 1,
    "قنزعة": 1,
    "قنص": 1,
    "قنصل": 1,
    "قنصلاتو": 1,
    "قنصلية": 1,
    "قنط": 1,
    "قنطار": 1,
    "قنطاريون": 1,
    "قنطر": 1,
    "قنطرة": 1,
    "قنطرمة": 1,
    "قنع": 1,
    "قنعان": 1,
    "قنقر": 1,
    "قنلسوة": 1,
    "قنم": 1,
    "قنن": 1,
    "قنو": 1,
    "قنوب": 1,
    "قنوة": 1,
    "قنوت": 1,
    "قنود": 1,
    "قنومة": 1,
    "قنون": 1,
    "قنونة": 1,
    "قنى": 1,
    "قني": 1,
    "قنيات": 1,
    "قنيان": 1,
    "قنية": 1,
    "قنيص": 1,
    "قنينة": 1,
    "قواء": 1,
    "قوابل": 1,
    "قواتم": 1,
    "قواد": 1,
    "قواديس": 1,
    "قواربي": 1,
    "قوارص": 1,
    "قوارع": 1,
    "قواس": 1,
    "قواص": 1,
    "قواصم": 1,
    "قواض": 1,
    "قواضى": 1,
    "قواطع": 1,
    "قواف": 1,
    "قوافل": 1,
    "قوال": 1,
    "قوالح": 1,
    "قوام": 1,
    "قوامة": 1,
    "قوامس": 1,
    "قوانص": 1,
    "قواه": 1,
    "قواويش": 1,
    "قواويق": 1,
    "قواية": 1,
    "قوايش": 1,
    "قوب": 1,
    "قوباء": 1,
    "قوبة": 1,
    "قوت": 1,
    "قوح": 1,
    "قود": 1,
    "قور": 1,
    "قورمة": 1,
    "قوسان": 1,
    "قوش": 1,
    "قوط": 1,
    "قوطة": 1,
    "قوع": 1,
    "قوق": 1,
    "قوقع": 1,
    "قولة": 1,
    "قولحة": 1,
    "قولنج": 1,
    "قولون": 1,
    "قولى": 1,
    "قومة": 1,
    "قومس": 1,
    "قومسير": 1,
    "قومندان": 1,
    "قومى": 1,
    "قوميات": 1,
    "قومية": 1,
    "قون": 1,
    "قونة": 1,
    "قونية": 1,
    "قوه": 1,
    "قويسة": 1,
    "قويق": 1,
    "قويم": 1,
    "قى": 1,
    "قىء": 1,
    "قي": 1,
    "قيء": 1,
    "قياء": 1,
    "قياتة": 1,
    "قياتير": 1,
    "قياثير": 1,
    "قياد": 1,
    "قياسا": 1,
    "قياسر": 1,
    "قياسرة": 1,
    "قياسرية": 1,
    "قياسى": 1,
    "قياسيا": 1,
    "قياصر": 1,
    "قياصرة": 1,
    "قياض": 1,
    "قياطين": 1,
    "قيافة": 1,
    "قيامة": 1,
    "قيامته": 1,
    "قيان": 1,
    "قيتار": 1,
    "قيح": 1,
    "قيدوم": 1,
    "قيدى": 1,
    "قيدية": 1,
    "قير": 1,
    "قيراط": 1,
    "قيران": 1,
    "قيروان": 1,
    "قيس": 1,
    "قيسرى": 1,
    "قيسرية": 1,
    "قيشانى": 1,
    "قيصانة": 1,
    "قيصرى": 1,
    "قيصرية": 1,
    "قيض": 1,
    "قيطان": 1,
    "قيطن": 1,
    "قيطوس": 1,
    "قيظ": 1,
    "قيعان": 1,
    "قيف": 1,
    "قيقان": 1,
    "قيقب": 1,
    "قيلة": 1,
    "قيلولة": 1,
    "قيمي": 1,
    "قين": 1,
    "قينة": 1,
    "قيوح": 1,
    "قيود": 1,
    "قيودا": 1,
    "قيوم": 1,
    "قيون": 1,
    "قھار": 1,
    "قھارمة": 1,
    "قھاوي": 1,
    "قھر": 1,
    "قھرا": 1,
    "قھرة": 1,
    "قھرمان": 1,
    "قھرمانة": 1,
    "قھري": 1,
    "قھرية": 1,
    "قھقر": 1,
    "قھقرة": 1,
    "قھقرى": 1,
    "قھقه": 1,
    "قھقھة": 1,
    "قھواتي": 1,
    "قھوة": 1,
    "قھوجي": 1,
    "قھي": 1,
    "كأبة": 1,
    "كأد": 1,
    "كأداء": 1,
    "كأسات": 1,
    "كأمس": 1,
    "كؤود": 1,
    "كئاس": 1,
    "كئب": 1,
    "كائنا": 1,
    "كاب": 1,
    "كابسة": 1,
    "كابل": 1,
    "كابلي": 1,
    "كابوس": 1,
    "كابولي": 1,
    "كابين": 1,
    "كاترة": 1,
    "كاحل": 1,
    "كاخية": 1,
    "كادر": 1,
    "كادميوم": 1,
    "كارتداد": 1,
    "كارتون": 1,
    "كارث": 1,
    "كارثة": 1,
    "كاردينال": 1,
    "كارز": 1,
    "كارع": 1,
    "كاروبيم": 1,
    "كارى": 1,
    "كاريكاتورية": 1,
    "كارھا": 1,
    "كازينو": 1,
    "كازينوھات": 1,
    "كاس": 1,
    "كاسب": 1,
    "كاسد": 1,
    "كاسف": 1,
    "كاشح": 1,
    "كاشحة": 1,
    "كاشفه": 1,
    "كاع": 1,
    "كاعب": 1,
    "كاغط": 1,
    "كافل": 1,
    "كافور": 1,
    "كاكنج": 1,
    "كالآتي": 1,
    "كالأول": 1,
    "كالازم": 1,
    "كالتنوين": 1,
    "كالح": 1,
    "كالحسام": 1,
    "كالسابق": 1,
    "كالماء": 1,
    "كالمجمعين": 1,
    "كالمعتاد": 1,
    "كالو": 1,
    "كالون": 1,
    "كالوھات": 1,
    "كامبوديا": 1,
    "كامبيو": 1,
    "كامخ": 1,
    "كامد": 1,
    "كامن": 1,
    "كاميه": 1,
    "كانتين": 1,
    "كانمن": 1,
    "كاو": 1,
    "كاوتشق": 1,
    "كاوية": 1,
    "كاھل": 1,
    "كاھله": 1,
    "كاھن": 1,
    "كاھية": 1,
    "كب": 1,
    "كبا": 1,
    "كبائن": 1,
    "كباب": 1,
    "كبابة": 1,
    "كبابيت": 1,
    "كباد": 1,
    "كبارة": 1,
    "كباري": 1,
    "كباريه": 1,
    "كباس": 1,
    "كباش": 1,
    "كباية": 1,
    "كباين": 1,
    "كبب": 1,
    "كبة": 1,
    "كبتن": 1,
    "كبتولة": 1,
    "كبح": 1,
    "كبذه": 1,
    "كبراء": 1,
    "كبرة": 1,
    "كبري": 1,
    "كبرياء": 1,
    "كبريات": 1,
    "كبريت": 1,
    "كبريتة": 1,
    "كبس": 1,
    "كبسول": 1,
    "كبسولة": 1,
    "كبشة": 1,
    "كبكب": 1,
    "كبل": 1,
    "كبو": 1,
    "كبوة": 1,
    "كبوت": 1,
    "كبود": 1,
    "كبوش": 1,
    "كبول": 1,
    "كبولي": 1,
    "كبيبة": 1,
    "كبيس": 1,
    "كبيسة": 1,
    "كبيكج": 1,
    "كت": 1,
    "كتائب": 1,
    "كتابات": 1,
    "كتابخانة": 1,
    "كتابي": 1,
    "كتابية": 1,
    "كتاف": 1,
    "كتاكيت": 1,
    "كتالوج": 1,
    "كتام": 1,
    "كتان": 1,
    "كتبخانة": 1,
    "كتبي": 1,
    "كتع": 1,
    "كتف": 1,
    "كتفاء": 1,
    "كتفة": 1,
    "كتفه": 1,
    "كتفيه": 1,
    "كتكت": 1,
    "كتكوت": 1,
    "كتمان": 1,
    "كتن": 1,
    "كتيب": 1,
    "كتيبة": 1,
    "كتيت": 1,
    "كتيم": 1,
    "كتينة": 1,
    "كث": 1,
    "كثاثة": 1,
    "كثار": 1,
    "كثاف": 1,
    "كثب": 1,
    "كثبان": 1,
    "كثث": 1,
    "كثلك": 1,
    "كثلكة": 1,
    "كثوثة": 1,
    "كثوليكي": 1,
    "كثيب": 1,
    "كثيراء": 1,
    "كح": 1,
    "كحائل": 1,
    "كحاطب": 1,
    "كحال": 1,
    "كحة": 1,
    "كحت": 1,
    "كحكح": 1,
    "كحكحة": 1,
    "كحل": 1,
    "كحلاء": 1,
    "كحلة": 1,
    "كحلي": 1,
    "كحيل": 1,
    "كحيلان": 1,
    "كحيلي": 1,
    "كخية": 1,
    "كد": 1,
    "كداديس": 1,
    "كدارة": 1,
    "كداس": 1,
    "كداسة": 1,
    "كدر": 1,
    "كدراء": 1,
    "كدرة": 1,
    "كدش": 1,
    "كدم": 1,
    "كدود": 1,
    "كدور": 1,
    "كدورة": 1,
    "كدى": 1,
    "كدية": 1,
    "كدير": 1,
    "كديش": 1,
    "كذابة": 1,
    "كذبة": 1,
    "كذوب": 1,
    "كرا": 1,
    "كراء": 1,
    "كرائم": 1,
    "كرائه": 1,
    "كرابيج": 1,
    "كرابيس": 1,
    "كرابيل": 1,
    "كراتين": 1,
    "كراث": 1,
    "كراج": 1,
    "كراخين": 1,
    "كرادلة": 1,
    "كرادين": 1,
    "كرار": 1,
    "كرارط": 1,
    "كرارطي": 1,
    "كرارية": 1,
    "كراريط": 1,
    "كرازة": 1,
    "كراس": 1,
    "كراسيع": 1,
    "كراع": 1,
    "كرافتة": 1,
    "كراكة": 1,
    "كراكوفيا": 1,
    "كراكي": 1,
    "كرامات": 1,
    "كرامة": 1,
    "كراميل": 1,
    "كرانيش": 1,
    "كرانيف": 1,
    "كراھة": 1,
    "كراھية": 1,
    "كرباج": 1,
    "كرباس": 1,
    "كربال": 1,
    "كربة": 1,
    "كربلاء": 1,
    "كربون": 1,
    "كرتن": 1,
    "كرتون": 1,
    "كرث": 1,
    "كرجي": 1,
    "كرح": 1,
    "كرخانة": 1,
    "كرخانجي": 1,
    "كردان": 1,
    "كردس": 1,
    "كردون": 1,
    "كرز": 1,
    "كرزى": 1,
    "كرسف": 1,
    "كرسنة": 1,
    "كرسوع": 1,
    "كرسى": 1,
    "كرشة": 1,
    "كرشوني": 1,
    "كرع": 1,
    "كرعة": 1,
    "كرفس": 1,
    "كرك": 1,
    "كركب": 1,
    "كركبة": 1,
    "كركة": 1,
    "كركدن": 1,
    "كركر": 1,
    "كركرة": 1,
    "كركم": 1,
    "كركند": 1,
    "كركوز": 1,
    "كركون": 1,
    "كركي": 1,
    "كرما": 1,
    "كرماء": 1,
    "كرمانا": 1,
    "كرمش": 1,
    "كرمل": 1,
    "كرملا": 1,
    "كرملي": 1,
    "كرناف": 1,
    "كرنافة": 1,
    "كرنب": 1,
    "كرنبة": 1,
    "كرنتينة": 1,
    "كرنك": 1,
    "كرنيش": 1,
    "كرو": 1,
    "كروان": 1,
    "كروبي": 1,
    "كروبيم": 1,
    "كرور": 1,
    "كروسة": 1,
    "كروش": 1,
    "كروع": 1,
    "كروكي": 1,
    "كروكيات": 1,
    "كرى": 1,
    "كريث": 1,
    "كريد": 1,
    "كريشة": 1,
    "كريطة": 1,
    "كريك": 1,
    "كريمة": 1,
    "كريھا": 1,
    "كريھة": 1,
    "كرھا": 1,
    "كز": 1,
    "كزاز": 1,
    "كزازة": 1,
    "كزرونة": 1,
    "كزز": 1,
    "كزورنة": 1,
    "كزوزة": 1,
    "كساء": 1,
    "كسائه": 1,
    "كسابق": 1,
    "كساتا": 1,
    "كساتبين": 1,
    "كساتك": 1,
    "كساح": 1,
    "كساحة": 1,
    "كساد": 1,
    "كسارى": 1,
    "كساه": 1,
    "كساو": 1,
    "كسبرة": 1,
    "كستاك": 1,
    "كستبان": 1,
    "كستك": 1,
    "كستلية": 1,
    "كستنائي": 1,
    "كستنة": 1,
    "كستيك": 1,
    "كسح": 1,
    "كسد": 1,
    "كسرة": 1,
    "كسرت": 1,
    "كسره": 1,
    "كسرونة": 1,
    "كسرى": 1,
    "كسع": 1,
    "كسف": 1,
    "كسكاس": 1,
    "كسكت": 1,
    "كسكس": 1,
    "كسكسو": 1,
    "كسكسي": 1,
    "كسله": 1,
    "كسلى": 1,
    "كسم": 1,
    "كسو": 1,
    "كسود": 1,
    "كسور": 1,
    "كسورات": 1,
    "كسوف": 1,
    "كسي": 1,
    "كسيح": 1,
    "كسيد": 1,
    "كسير": 1,
    "كسيم": 1,
    "كشاتبين": 1,
    "كشاحة": 1,
    "كشافة": 1,
    "كشافي": 1,
    "كشاكش": 1,
    "كشب": 1,
    "كشة": 1,
    "كشتبان": 1,
    "كشح": 1,
    "كشحا": 1,
    "كشحه": 1,
    "كشر": 1,
    "كشرة": 1,
    "كشفة": 1,
    "كشفي": 1,
    "كشفية": 1,
    "كشكش": 1,
    "كشكشية": 1,
    "كشكول": 1,
    "كشمش": 1,
    "كشنى": 1,
    "كشوح": 1,
    "كشوف": 1,
    "كشوفات": 1,
    "كشيف": 1,
    "كض": 1,
    "كظ": 1,
    "كظة": 1,
    "كظر": 1,
    "كظم": 1,
    "كظوم": 1,
    "كظيظ": 1,
    "كظھر": 1,
    "كعاب": 1,
    "كعابر": 1,
    "كعب": 1,
    "كعبا": 1,
    "كعبة": 1,
    "كعبرة": 1,
    "كعبري": 1,
    "كعبل": 1,
    "كعبورة": 1,
    "كعبي": 1,
    "كعبھم": 1,
    "كعم": 1,
    "كعيب": 1,
    "كعھده": 1,
    "كعھدي": 1,
    "كعھدھم": 1,
    "كغم": 1,
    "كفأ": 1,
    "كفؤ": 1,
    "كفا": 1,
    "كفاء": 1,
    "كفاءات": 1,
    "كفاءة": 1,
    "كفاة": 1,
    "كفاح": 1,
    "كفاف": 1,
    "كفافة": 1,
    "كفالة": 1,
    "كفاه": 1,
    "كفة": 1,
    "كفت": 1,
    "كفتة": 1,
    "كفح": 1,
    "كفرة": 1,
    "كفس": 1,
    "كفساء": 1,
    "كفف": 1,
    "كفكف": 1,
    "كفلاء": 1,
    "كفه": 1,
    "كفوف": 1,
    "كفول": 1,
    "كفي": 1,
    "كفيت": 1,
    "كفيف": 1,
    "كفيل": 1,
    "كفھر": 1,
    "كلء": 1,
    "كلأ": 1,
    "كلاء": 1,
    "كلاءة": 1,
    "كلابة": 1,
    "كلابي": 1,
    "كلاح": 1,
    "كلاسة": 1,
    "كلاسط": 1,
    "كلاسكي": 1,
    "كلاف": 1,
    "كلافي": 1,
    "كلاكس": 1,
    "كلاكل": 1,
    "كلاكله": 1,
    "كلاكيع": 1,
    "كلال": 1,
    "كلاليب": 1,
    "كلامي": 1,
    "كلامية": 1,
    "كلاوى": 1,
    "كلبة": 1,
    "كلبش": 1,
    "كلبى": 1,
    "كلبي": 1,
    "كلة": 1,
    "كلت": 1,
    "كلتي": 1,
    "كلح": 1,
    "كلحة": 1,
    "كلخ": 1,
    "كلداني": 1,
    "كلسة": 1,
    "كلسون": 1,
    "كلسيطة": 1,
    "كلسيوم": 1,
    "كلفاء": 1,
    "كلفة": 1,
    "كلفه": 1,
    "كلكل": 1,
    "كلكلة": 1,
    "كلكله": 1,
    "كلماء": 1,
    "كلماني": 1,
    "كلمته": 1,
    "كلمتھم": 1,
    "كلمى": 1,
    "كلوء": 1,
    "كلوب": 1,
    "كلوة": 1,
    "كلوح": 1,
    "كلول": 1,
    "كلولة": 1,
    "كلوم": 1,
    "كلوى": 1,
    "كلى": 1,
    "كليات": 1,
    "كليب": 1,
    "كليشيه": 1,
    "كليل": 1,
    "كلھا": 1,
    "كلھم": 1,
    "كمء": 1,
    "كمأة": 1,
    "كمائم": 1,
    "كماة": 1,
    "كماخ": 1,
    "كمادة": 1,
    "كمارك": 1,
    "كماشة": 1,
    "كمالة": 1,
    "كمالي": 1,
    "كماليات": 1,
    "كمام": 1,
    "كمامة": 1,
    "كمان": 1,
    "كمانجي": 1,
    "كمب": 1,
    "كمبريت": 1,
    "كمبيالة": 1,
    "كمبيو": 1,
    "كمت": 1,
    "كمثراة": 1,
    "كمثريات": 1,
    "كمح": 1,
    "كمخ": 1,
    "كمخا": 1,
    "كمد": 1,
    "كمدة": 1,
    "كمر": 1,
    "كمرة": 1,
    "كمرك": 1,
    "كمركي": 1,
    "كمساري": 1,
    "كمسارية": 1,
    "كمسلم": 1,
    "كمش": 1,
    "كمشة": 1,
    "كمع": 1,
    "كمل": 1,
    "كملة": 1,
    "كممة": 1,
    "كمناء": 1,
    "كمنة": 1,
    "كمنجا": 1,
    "كمنجة": 1,
    "كمه": 1,
    "كمول": 1,
    "كمون": 1,
    "كميت": 1,
    "كميد": 1,
    "كميش": 1,
    "كميع": 1,
    "كمين": 1,
    "كميون": 1,
    "كمھاء": 1,
    "كنائس": 1,
    "كنائسي": 1,
    "كنائن": 1,
    "كنادر": 1,
    "كنار": 1,
    "كناري": 1,
    "كناسة": 1,
    "كناش": 1,
    "كناشات": 1,
    "كناشة": 1,
    "كنافة": 1,
    "كنان": 1,
    "كنانة": 1,
    "كنب": 1,
    "كنبه": 1,
    "كنبيه": 1,
    "كنة": 1,
    "كنجرو": 1,
    "كندر": 1,
    "كندرة": 1,
    "كندش": 1,
    "كنصول": 1,
    "كنغر": 1,
    "كنف": 1,
    "كنفاش": 1,
    "كنكة": 1,
    "كنكن": 1,
    "كنكينا": 1,
    "كنه": 1,
    "كنو": 1,
    "كنود": 1,
    "كنوز": 1,
    "كنون": 1,
    "كنى": 1,
    "كني": 1,
    "كنية": 1,
    "كنيس": 1,
    "كنيف": 1,
    "كنين": 1,
    "كنھور": 1,
    "كواء": 1,
    "كوائر": 1,
    "كوابيس": 1,
    "كوابيل": 1,
    "كواتر": 1,
    "كواحل": 1,
    "كواخ": 1,
    "كوارة": 1,
    "كوارث": 1,
    "كوارع": 1,
    "كواسر": 1,
    "كواعب": 1,
    "كواليس": 1,
    "كوالين": 1,
    "كواليني": 1,
    "كوامخ": 1,
    "كوامن": 1,
    "كوانين": 1,
    "كواه": 1,
    "كواية": 1,
    "كواھل": 1,
    "كوبة": 1,
    "كوبرته": 1,
    "كوبري": 1,
    "كوبنھاج": 1,
    "كوبنھاجن": 1,
    "كوبيا": 1,
    "كوبيل": 1,
    "كوبيه": 1,
    "كوة": 1,
    "كوتر": 1,
    "كوثة": 1,
    "كوثر": 1,
    "كوثل": 1,
    "كود": 1,
    "كودة": 1,
    "كودية": 1,
    "كورة": 1,
    "كوردون": 1,
    "كورريا": 1,
    "كورسيه": 1,
    "كورسيھات": 1,
    "كورنيش": 1,
    "كوروي": 1,
    "كورى": 1,
    "كوريك": 1,
    "كوس": 1,
    "كوسا": 1,
    "كوساة": 1,
    "كوساية": 1,
    "كوسج": 1,
    "كوسى": 1,
    "كوش": 1,
    "كوشة": 1,
    "كوع": 1,
    "كوف": 1,
    "كوفية": 1,
    "كولان": 1,
    "كولونيا": 1,
    "كوليرا": 1,
    "كوماندان": 1,
    "كومساري": 1,
    "كومسيوجي": 1,
    "كومودينو": 1,
    "كوميديا": 1,
    "كونتراتو": 1,
    "كونكرداتو": 1,
    "كونياك": 1,
    "كوى": 1,
    "كوية": 1,
    "كويس": 1,
    "كياد": 1,
    "كياس": 1,
    "كياسة": 1,
    "كياكي": 1,
    "كيان": 1,
    "كية": 1,
    "كير": 1,
    "كيران": 1,
    "كيروان": 1,
    "كيروسين": 1,
    "كيزان": 1,
    "كيسة": 1,
    "كيسه": 1,
    "كيسى": 1,
    "كيعان": 1,
    "كيفك": 1,
    "كيفي": 1,
    "كيفيا": 1,
    "كيفية": 1,
    "كيكا": 1,
    "كيكة": 1,
    "كيلة": 1,
    "كيلوجرام": 1,
    "كيلوسيكل": 1,
    "كيلوغرام": 1,
    "كيلومتر": 1,
    "كيلومترات": 1,
    "كيلون": 1,
    "كيلوواط": 1,
    "كيما": 1,
    "كيماوي": 1,
    "كيماويات": 1,
    "كيموس": 1,
    "كيمي": 1,
    "كينا": 1,
    "كينونة": 1,
    "كينين": 1,
    "كيوان": 1,
    "كيوف": 1,
    "كيھك": 1,
    "كھارب": 1,
    "كھاربي": 1,
    "كھال": 1,
    "كھامة": 1,
    "كھان": 1,
    "كھانة": 1,
    "كھاية": 1,
    "كھذا": 1,
    "كھرب": 1,
    "كھربا": 1,
    "كھرباء": 1,
    "كھربائى": 1,
    "كھربائي": 1,
    "كھربائية": 1,
    "كھربة": 1,
    "كھربي": 1,
    "كھربية": 1,
    "كھرطيسي": 1,
    "كھرمان": 1,
    "كھف": 1,
    "كھل": 1,
    "كھلان": 1,
    "كھن": 1,
    "كھنة": 1,
    "كھنجي": 1,
    "كھنوت": 1,
    "كھنوتي": 1,
    "كھوف": 1,
    "كھول": 1,
    "كھولة": 1,
    "كھيرب": 1,
    "كھيربي": 1,
    "لآلي": 1,
    "لآمة": 1,
    "لأجلك": 1,
    "لأذقانھم": 1,
    "لأسداس": 1,
    "لأعتاب": 1,
    "لأعمال": 1,
    "لأك": 1,
    "لألأ": 1,
    "لألأة": 1,
    "لألاء": 1,
    "لأم": 1,
    "لأمة": 1,
    "لأواء": 1,
    "لأھلية": 1,
    "لؤلؤى": 1,
    "لؤم": 1,
    "لؤماء": 1,
    "لؤمان": 1,
    "لئام": 1,
    "لئم": 1,
    "لئيم": 1,
    "لائذ": 1,
    "لائكي": 1,
    "لائكية": 1,
    "لائمة": 1,
    "لاث": 1,
    "لاج": 1,
    "لاجئ": 1,
    "لاجنسي": 1,
    "لاح": 1,
    "لاحب": 1,
    "لاحقة": 1,
    "لاديني": 1,
    "لاذ": 1,
    "لازمة": 1,
    "لازورد": 1,
    "لازوردي": 1,
    "لازوردية": 1,
    "لازوق": 1,
    "لاس": 1,
    "لاسلكية": 1,
    "لاسيه": 1,
    "لاشتداد": 1,
    "لاشعوري": 1,
    "لاشيئية": 1,
    "لاط": 1,
    "لاطفه": 1,
    "لاع": 1,
    "لاعج": 1,
    "لاعضوي": 1,
    "لاغ": 1,
    "لاغب": 1,
    "لاف": 1,
    "لافح": 1,
    "لاق": 1,
    "لاقط": 1,
    "لاقطة": 1,
    "لاقمي": 1,
    "لاقى": 1,
    "لاكن": 1,
    "لالا": 1,
    "لالاء": 1,
    "لالامر": 1,
    "لاما": 1,
    "لامبالاة": 1,
    "لامبالية": 1,
    "لامة": 1,
    "لامركزية": 1,
    "لامسؤولية": 1,
    "لامعة": 1,
    "لامي": 1,
    "لان": 1,
    "لانت": 1,
    "لانش": 1,
    "لانھائي": 1,
    "لانھائية": 1,
    "لاه": 1,
    "لاول": 1,
    "لاونجي": 1,
    "لايجف": 1,
    "لاھاي": 1,
    "لاھف": 1,
    "لاھوت": 1,
    "لاھوتي": 1,
    "لاھور": 1,
    "لاھيا": 1,
    "لب": 1,
    "لبؤة": 1,
    "لبائن": 1,
    "لباب": 1,
    "لبابة": 1,
    "لبابيد": 1,
    "لباث": 1,
    "لباد": 1,
    "لبادة": 1,
    "لباقة": 1,
    "لباليب": 1,
    "لبان": 1,
    "لبانة": 1,
    "لبب": 1,
    "لبة": 1,
    "لبتة": 1,
    "لبثة": 1,
    "لبخ": 1,
    "لبخة": 1,
    "لبدة": 1,
    "لبسة": 1,
    "لبط": 1,
    "لبطن": 1,
    "لبك": 1,
    "لبكة": 1,
    "لبلاب": 1,
    "لبلب": 1,
    "لبلوب": 1,
    "لبنات": 1,
    "لبناني": 1,
    "لبنة": 1,
    "لبنى": 1,
    "لبنية": 1,
    "لبه": 1,
    "لبوب": 1,
    "لبوة": 1,
    "لبود": 1,
    "لبون": 1,
    "لبونة": 1,
    "لبى": 1,
    "لبي": 1,
    "لبيب": 1,
    "لبيخ": 1,
    "لبيريا": 1,
    "لبيس": 1,
    "لبيق": 1,
    "لبيك": 1,
    "لت": 1,
    "لتات": 1,
    "لتر": 1,
    "لتسجيل": 1,
    "لتفقيص": 1,
    "لتلميع": 1,
    "لتموس": 1,
    "لتوانيا": 1,
    "لتوطئة": 1,
    "لتوى": 1,
    "لتوھا": 1,
    "لثام": 1,
    "لثة": 1,
    "لثغ": 1,
    "لثغاء": 1,
    "لثغة": 1,
    "لثم": 1,
    "لثمة": 1,
    "لثو": 1,
    "لثوي": 1,
    "لثى": 1,
    "لج": 1,
    "لجء": 1,
    "لجأ": 1,
    "لجئ": 1,
    "لجاج": 1,
    "لجاجة": 1,
    "لجان": 1,
    "لجب": 1,
    "لجج": 1,
    "لجلاج": 1,
    "لجلج": 1,
    "لجلجة": 1,
    "لجم": 1,
    "لجميله": 1,
    "لجن": 1,
    "لجنب": 1,
    "لجنس": 1,
    "لجوء": 1,
    "لجوج": 1,
    "لجين": 1,
    "لجيني": 1,
    "لح": 1,
    "لحا": 1,
    "لحاجة": 1,
    "لحاد": 1,
    "لحاف": 1,
    "لحافه": 1,
    "لحاق": 1,
    "لحالة": 1,
    "لحامة": 1,
    "لحانة": 1,
    "لحتفه": 1,
    "لحج": 1,
    "لحح": 1,
    "لحديثه": 1,
    "لحس": 1,
    "لحساب": 1,
    "لحسة": 1,
    "لحظ": 1,
    "لحظات": 1,
    "لحظان": 1,
    "لحظتئذ": 1,
    "لحف": 1,
    "لحقي": 1,
    "لحقية": 1,
    "لحقھا": 1,
    "لحكمة": 1,
    "لحمية": 1,
    "لحو": 1,
    "لحود": 1,
    "لحون": 1,
    "لحى": 1,
    "لحي": 1,
    "لحيان": 1,
    "لحيته": 1,
    "لحين": 1,
    "لخاطرك": 1,
    "لخدمة": 1,
    "لخطة": 1,
    "لخلخ": 1,
    "لخمة": 1,
    "لخن": 1,
    "لخناء": 1,
    "لخواطرھم": 1,
    "لخير": 1,
    "لداء": 1,
    "لدائن": 1,
    "لدات": 1,
    "لداد": 1,
    "لدان": 1,
    "لدانة": 1,
    "لدة": 1,
    "لدد": 1,
    "لدعوته": 1,
    "لدغ": 1,
    "لدغاء": 1,
    "لدغى": 1,
    "لدمائھم": 1,
    "لدواع": 1,
    "لدود": 1,
    "لدون": 1,
    "لدونة": 1,
    "لديد": 1,
    "لديغ": 1,
    "لذ": 1,
    "لذائذ": 1,
    "لذاته": 1,
    "لذاذ": 1,
    "لذاذة": 1,
    "لذاع": 1,
    "لذع": 1,
    "لذكراه": 1,
    "لرصد": 1,
    "لز": 1,
    "لزاب": 1,
    "لزاق": 1,
    "لزام": 1,
    "لزب": 1,
    "لزبة": 1,
    "لزة": 1,
    "لزز": 1,
    "لززاز": 1,
    "لزق": 1,
    "لزقة": 1,
    "لزقه": 1,
    "لزم": 1,
    "لزمة": 1,
    "لزوب": 1,
    "لزوج": 1,
    "لزوق": 1,
    "لزوم": 1,
    "لساعته": 1,
    "لسانين": 1,
    "لسبع": 1,
    "لسبيله": 1,
    "لستك": 1,
    "لستيك": 1,
    "لسعاء": 1,
    "لسعى": 1,
    "لسناء": 1,
    "لسنه": 1,
    "لسيع": 1,
    "لشبونة": 1,
    "لشد": 1,
    "لشى": 1,
    "لصق": 1,
    "لصقي": 1,
    "لصوق": 1,
    "لصيد": 1,
    "لصيق": 1,
    "لطائف": 1,
    "لطابق": 1,
    "لطاف": 1,
    "لطس": 1,
    "لطش": 1,
    "لطع": 1,
    "لطعة": 1,
    "لطف": 1,
    "لطفاء": 1,
    "لطلبكم": 1,
    "لطمة": 1,
    "لطيته": 1,
    "لطيخ": 1,
    "لطيفة": 1,
    "لطيم": 1,
    "لظي": 1,
    "لظھر": 1,
    "لع": 1,
    "لعا": 1,
    "لعاب": 1,
    "لعالع": 1,
    "لعان": 1,
    "لعبت": 1,
    "لعبرته": 1,
    "لعثم": 1,
    "لعثمة": 1,
    "لعج": 1,
    "لعجب": 1,
    "لعجة": 1,
    "لعساء": 1,
    "لعق": 1,
    "لعقة": 1,
    "لعلع": 1,
    "لعوق": 1,
    "لعياله": 1,
    "لعيب": 1,
    "لعيبة": 1,
    "لغا": 1,
    "لغاديد": 1,
    "لغاط": 1,
    "لغام": 1,
    "لغاية": 1,
    "لغايته": 1,
    "لغب": 1,
    "لغدود": 1,
    "لغز": 1,
    "لغط": 1,
    "لغم": 1,
    "لغما": 1,
    "لغمط": 1,
    "لغوة": 1,
    "لغود": 1,
    "لغويات": 1,
    "لغي": 1,
    "لفاء": 1,
    "لفائف": 1,
    "لفات": 1,
    "لفاح": 1,
    "لفت": 1,
    "لفتاء": 1,
    "لفتة": 1,
    "لفح": 1,
    "لفحان": 1,
    "لفحة": 1,
    "لفظا": 1,
    "لفظه": 1,
    "لفظية": 1,
    "لفع": 1,
    "لفق": 1,
    "لفلان": 1,
    "لفلف": 1,
    "لفه": 1,
    "لفوت": 1,
    "لفوح": 1,
    "لفيظ": 1,
    "لفيف": 1,
    "لفيفة": 1,
    "لقاح": 1,
    "لقاط": 1,
    "لقاطة": 1,
    "لقالق": 1,
    "لقانة": 1,
    "لقانية": 1,
    "لقاية": 1,
    "لقرصه": 1,
    "لقس": 1,
    "لقط": 1,
    "لقطاء": 1,
    "لقع": 1,
    "لقف": 1,
    "لقفان": 1,
    "لقلاق": 1,
    "لقلق": 1,
    "لقلقة": 1,
    "لقم": 1,
    "لقمة": 1,
    "لقمي": 1,
    "لقولى": 1,
    "لقى": 1,
    "لقي": 1,
    "لقيان": 1,
    "لقية": 1,
    "لقيط": 1,
    "لقيطة": 1,
    "لقيمة": 1,
    "لكء": 1,
    "لكأ": 1,
    "لكأة": 1,
    "لكئ": 1,
    "لكاز": 1,
    "لكاعة": 1,
    "لكانة": 1,
    "لكز": 1,
    "لكعاء": 1,
    "لكناء": 1,
    "لكنة": 1,
    "لكنونة": 1,
    "لكوك": 1,
    "لكونة": 1,
    "لكونه": 1,
    "لكيع": 1,
    "لكيما": 1,
    "للآداب": 1,
    "للآن": 1,
    "للأخطار": 1,
    "للأعداء": 1,
    "للأمر": 1,
    "للأميرال": 1,
    "للإبرة": 1,
    "للإيجار": 1,
    "للاانثناء": 1,
    "للارصاد": 1,
    "للاسف": 1,
    "للالتھاب": 1,
    "للامتحان": 1,
    "للاميرال": 1,
    "للبوليس": 1,
    "للتجديد": 1,
    "للتجزئة": 1,
    "للتعس": 1,
    "للتو": 1,
    "للتوليد": 1,
    "للجندية": 1,
    "للحال": 1,
    "للحبل": 1,
    "للحسرة": 1,
    "للحقيقة": 1,
    "للحكم": 1,
    "للحيويات": 1,
    "للخجل": 1,
    "للخزى": 1,
    "للدار": 1,
    "للدھان": 1,
    "للرجوع": 1,
    "للريح": 1,
    "للسقف": 1,
    "للسقوط": 1,
    "للشابورة": 1,
    "للشقاء": 1,
    "للشيء": 1,
    "للشيخ": 1,
    "للضرورة": 1,
    "للطعام": 1,
    "للطعن": 1,
    "للعادة": 1,
    "للعجب": 1,
    "للعزل": 1,
    "للغارات": 1,
    "للفظ": 1,
    "للكاتب": 1,
    "للمداولة": 1,
    "للمعروف": 1,
    "للمعلمين": 1,
    "للمقاومة": 1,
    "للموت": 1,
    "للنجاة": 1,
    "للنصاب": 1,
    "للنور": 1,
    "للنھاية": 1,
    "للھول": 1,
    "لماح": 1,
    "لماذا": 1,
    "لماز": 1,
    "لماعة": 1,
    "لمام": 1,
    "لماما": 1,
    "لمباجو": 1,
    "لمبة": 1,
    "لمة": 1,
    "لمج": 1,
    "لمجة": 1,
    "لمح": 1,
    "لمحان": 1,
    "لمحض": 1,
    "لمدافع": 1,
    "لمز": 1,
    "لمسة": 1,
    "لمستزيد": 1,
    "لمسى": 1,
    "لمسية": 1,
    "لمشرقان": 1,
    "لمص": 1,
    "لمصلحة": 1,
    "لمظ": 1,
    "لمعان": 1,
    "لمعاونتنا": 1,
    "لمعة": 1,
    "لملم": 1,
    "لمم": 1,
    "لمناسبة": 1,
    "لميس": 1,
    "لنج": 1,
    "لندرة": 1,
    "لنش": 1,
    "لنينغراد": 1,
    "لواؤھا": 1,
    "لوائح": 1,
    "لوائم": 1,
    "لوائه": 1,
    "لواة": 1,
    "لواحظ": 1,
    "لواحق": 1,
    "لواذ": 1,
    "لواط": 1,
    "لواطة": 1,
    "لواعج": 1,
    "لوافح": 1,
    "لوالب": 1,
    "لوام": 1,
    "لوامع": 1,
    "لوبي": 1,
    "لوبيا": 1,
    "لوثة": 1,
    "لوج": 1,
    "لوجھه": 1,
    "لوذ": 1,
    "لوذع": 1,
    "لوذعي": 1,
    "لوذعية": 1,
    "لور": 1,
    "لورى": 1,
    "لوزى": 1,
    "لوزية": 1,
    "لوص": 1,
    "لوع": 1,
    "لوعة": 1,
    "لوغاريتمات": 1,
    "لوف": 1,
    "لوقته": 1,
    "لوكانده": 1,
    "لوكنده": 1,
    "لولانا": 1,
    "لولب": 1,
    "لولبي": 1,
    "لولبيات": 1,
    "لوم": 1,
    "لومان": 1,
    "لومانجي": 1,
    "لونجي": 1,
    "لونجية": 1,
    "لوندا": 1,
    "لوى": 1,
    "لوي": 1,
    "لى": 1,
    "لياء": 1,
    "ليائل": 1,
    "لياذ": 1,
    "لياقة": 1,
    "ليان": 1,
    "ليبي": 1,
    "لية": 1,
    "ليتبين": 1,
    "ليترجية": 1,
    "ليث": 1,
    "ليحي": 1,
    "ليرة": 1,
    "ليساء": 1,
    "ليسانس": 1,
    "ليسقط": 1,
    "ليصعب": 1,
    "ليفاني": 1,
    "ليفة": 1,
    "ليق": 1,
    "ليقاد": 1,
    "ليقة": 1,
    "ليكتب": 1,
    "ليكن": 1,
    "ليل": 1,
    "ليلاء": 1,
    "ليلاه": 1,
    "ليلتئذ": 1,
    "ليلتكم": 1,
    "ليلك": 1,
    "ليله": 1,
    "ليلية": 1,
    "ليمون": 1,
    "ليوث": 1,
    "ليونة": 1,
    "لھا": 1,
    "لھاء": 1,
    "لھاب": 1,
    "لھاة": 1,
    "لھاث": 1,
    "لھاف": 1,
    "لھافى": 1,
    "لھب": 1,
    "لھبان": 1,
    "لھبى": 1,
    "لھث": 1,
    "لھثان": 1,
    "لھثى": 1,
    "لھج": 1,
    "لھجة": 1,
    "لھد": 1,
    "لھذا": 1,
    "لھذم": 1,
    "لھذه": 1,
    "لھط": 1,
    "لھف": 1,
    "لھفا": 1,
    "لھفان": 1,
    "لھفة": 1,
    "لھفى": 1,
    "لھفي": 1,
    "لھق": 1,
    "لھلق": 1,
    "لھلك": 1,
    "لھم": 1,
    "لھنا": 1,
    "لھو": 1,
    "لھوات": 1,
    "لھوم": 1,
    "لھوى": 1,
    "لھي": 1,
    "لھيات": 1,
    "لھيان": 1,
    "لھيب": 1,
    "لھيف": 1,
    "مآبض": 1,
    "مآت": 1,
    "مآتم": 1,
    "مآثر": 1,
    "مآثم": 1,
    "مآخذ": 1,
    "مآدب": 1,
    "مآذن": 1,
    "مآزر": 1,
    "مآزق": 1,
    "مآزيب": 1,
    "مآس": 1,
    "مآق": 1,
    "مآكل": 1,
    "مآل": 1,
    "مآمل": 1,
    "مآو": 1,
    "مأبض": 1,
    "مأبون": 1,
    "مأتاة": 1,
    "مأتم": 1,
    "مأتى": 1,
    "مأثرة": 1,
    "مأثم": 1,
    "مأثور": 1,
    "مأثورة": 1,
    "مأخذا": 1,
    "مأخوذات": 1,
    "مأدبة": 1,
    "مأذنة": 1,
    "مأذون": 1,
    "مأذونية": 1,
    "مأرب": 1,
    "مأزق": 1,
    "مأزوم": 1,
    "مأساة": 1,
    "مأسورة": 1,
    "مأسوف": 1,
    "مأفون": 1,
    "مأق": 1,
    "مأقة": 1,
    "مأكولات": 1,
    "مألف": 1,
    "مأمأ": 1,
    "مأمل": 1,
    "مأمن": 1,
    "مأمورية": 1,
    "مأمول": 1,
    "مأن": 1,
    "مأنة": 1,
    "مأنوس": 1,
    "مأوى": 1,
    "مأھول": 1,
    "مؤاخذة": 1,
    "مؤازرة": 1,
    "مؤاكل": 1,
    "مؤبد": 1,
    "مؤتمون": 1,
    "مؤتنف": 1,
    "مؤثل": 1,
    "مؤخر": 1,
    "مؤسل": 1,
    "مؤلفة": 1,
    "مؤلما": 1,
    "مؤمل": 1,
    "مؤوف": 1,
    "مؤون": 1,
    "مؤونة": 1,
    "مؤوه": 1,
    "مؤھلات": 1,
    "مئات": 1,
    "مئبر": 1,
    "مئخار": 1,
    "مئذنة": 1,
    "مئرم": 1,
    "مئزاب": 1,
    "مئشار": 1,
    "مئق": 1,
    "مئني": 1,
    "مئون": 1,
    "مئيني": 1,
    "مئينية": 1,
    "مائت": 1,
    "مائج": 1,
    "مائد": 1,
    "مائدته": 1,
    "مائن": 1,
    "ماتة": 1,
    "ماتح": 1,
    "ماتع": 1,
    "ماتينيه": 1,
    "ماثل": 1,
    "ماثلة": 1,
    "ماج": 1,
    "ماجريات": 1,
    "ماجل": 1,
    "ماجن": 1,
    "ماجور": 1,
    "ماح": 1,
    "ماحك": 1,
    "ماحل": 1,
    "ماحية": 1,
    "ماخر": 1,
    "ماخرة": 1,
    "ماخور": 1,
    "ماد": 1,
    "مادح": 1,
    "مادية": 1,
    "مارة": 1,
    "مارستان": 1,
    "مارش": 1,
    "مارشالية": 1,
    "مارق": 1,
    "ماركسي": 1,
    "ماركسية": 1,
    "ماروني": 1,
    "ماز": 1,
    "مازة": 1,
    "مازوت": 1,
    "مازية": 1,
    "ماس": 1,
    "ماسة": 1,
    "ماسون": 1,
    "ماسونية": 1,
    "ماش": 1,
    "ماشط": 1,
    "ماشطة": 1,
    "ماشك": 1,
    "ماشيا": 1,
    "ماشية": 1,
    "ماض": 1,
    "ماضر": 1,
    "ماضي": 1,
    "ماط": 1,
    "ماطر": 1,
    "ماع": 1,
    "ماعلينا": 1,
    "ماعون": 1,
    "ماعونة": 1,
    "ماكن": 1,
    "ماكوك": 1,
    "ماكياج": 1,
    "مالج": 1,
    "مالحة": 1,
    "مالطة": 1,
    "مالكي": 1,
    "مالنخوليا": 1,
    "ماليخاليا": 1,
    "مانجو": 1,
    "مانجوست": 1,
    "مانعا": 1,
    "مانوكان": 1,
    "مانوليا": 1,
    "مانوي": 1,
    "مانوية": 1,
    "مانيفاتورة": 1,
    "مانيفستو": 1,
    "مانيكان": 1,
    "ماه": 1,
    "ماوي": 1,
    "ماوية": 1,
    "مايونيز": 1,
    "مايوه": 1,
    "ماھة": 1,
    "ماھر": 1,
    "ماھن": 1,
    "ماھي": 1,
    "ماھية": 1,
    "مباءة": 1,
    "مباحات": 1,
    "مباحث": 1,
    "مباحثة": 1,
    "مباخر": 1,
    "مبادرة": 1,
    "مبادلات": 1,
    "مبادلة": 1,
    "مباذل": 1,
    "مباذله": 1,
    "مبار": 1,
    "مبارأة": 1,
    "مباران": 1,
    "مبارحة": 1,
    "مبارد": 1,
    "مبارز": 1,
    "مبارزة": 1,
    "مبازق": 1,
    "مبازل": 1,
    "مباسم": 1,
    "مباضع": 1,
    "مباعث": 1,
    "مباغ": 1,
    "مبال": 1,
    "مبان": 1,
    "مباول": 1,
    "مبايعة": 1,
    "مباھاة": 1,
    "مباھج": 1,
    "مبتاع": 1,
    "مبتدأ": 1,
    "مبتدئ": 1,
    "مبتغى": 1,
    "مبتلى": 1,
    "مبتور": 1,
    "مبتھج": 1,
    "مبحبح": 1,
    "مبحثر": 1,
    "مبخة": 1,
    "مبخرة": 1,
    "مبخلة": 1,
    "مبخوت": 1,
    "مبدئيا": 1,
    "مبذل": 1,
    "مبراة": 1,
    "مبرة": 1,
    "مبرح": 1,
    "مبرقش": 1,
    "مبرم": 1,
    "مبرمة": 1,
    "مبرور": 1,
    "مبروم": 1,
    "مبزقة": 1,
    "مبزل": 1,
    "مبسم": 1,
    "مبشرة": 1,
    "مبشور": 1,
    "مبشورة": 1,
    "مبصقة": 1,
    "مبضع": 1,
    "مبطان": 1,
    "مبطخة": 1,
    "مبطلة": 1,
    "مبطون": 1,
    "مبعث": 1,
    "مبعزق": 1,
    "مبغوض": 1,
    "مبغى": 1,
    "مبك": 1,
    "مبكرة": 1,
    "مبلورة": 1,
    "مبلول": 1,
    "مبنك": 1,
    "مبوب": 1,
    "مبوبة": 1,
    "مبوز": 1,
    "مبولة": 1,
    "مبيضة": 1,
    "مبيونة": 1,
    "مبھج": 1,
    "مبھجة": 1,
    "مبھدل": 1,
    "مبھرج": 1,
    "مبھم": 1,
    "مبھوت": 1,
    "مبھور": 1,
    "متآلف": 1,
    "متآمرون": 1,
    "متأجج": 1,
    "متأسف": 1,
    "متأكل": 1,
    "متأله": 1,
    "متأن": 1,
    "متأين": 1,
    "متأھب": 1,
    "متأھل": 1,
    "متئد": 1,
    "متابن": 1,
    "متاحف": 1,
    "متار": 1,
    "متارب": 1,
    "متارس": 1,
    "متارك": 1,
    "متاريس": 1,
    "متاعب": 1,
    "متاعيس": 1,
    "متالف": 1,
    "متان": 1,
    "متانة": 1,
    "متاولة": 1,
    "متاھة": 1,
    "متبار": 1,
    "متباعدة": 1,
    "متبتل": 1,
    "متبحر": 1,
    "متبذل": 1,
    "متبربر": 1,
    "متبرم": 1,
    "متبطل": 1,
    "متبع": 1,
    "متبق": 1,
    "متبلد": 1,
    "متبن": 1,
    "متتابع": 1,
    "متتال": 1,
    "متجاذب": 1,
    "متجرى": 1,
    "متجسد": 1,
    "متجل": 1,
    "متجلد": 1,
    "متح": 1,
    "متحاب": 1,
    "متحالف": 1,
    "متحتم": 1,
    "متحرج": 1,
    "متحرض": 1,
    "متحزب": 1,
    "متحصل": 1,
    "متحوط": 1,
    "متخرج": 1,
    "متخشب": 1,
    "متخف": 1,
    "متخلخل": 1,
    "متخلفات": 1,
    "متخلفة": 1,
    "متداع": 1,
    "متدان": 1,
    "متداول": 1,
    "متدرن": 1,
    "متدل": 1,
    "متدينة": 1,
    "متراخ": 1,
    "مترادف": 1,
    "مترادفات": 1,
    "متراس": 1,
    "متراكز": 1,
    "متراليوز": 1,
    "مترام": 1,
    "مترامي": 1,
    "متربعا": 1,
    "مترد": 1,
    "مترس": 1,
    "مترفع": 1,
    "مترھل": 1,
    "متزن": 1,
    "متساو": 1,
    "متساوى": 1,
    "متساھل": 1,
    "متسبب": 1,
    "متستر": 1,
    "متسربل": 1,
    "متسعا": 1,
    "متشابھات": 1,
    "متشامخ": 1,
    "متشبث": 1,
    "متشح": 1,
    "متشيع": 1,
    "متصف": 1,
    "متصلف": 1,
    "متضاد": 1,
    "متضح": 1,
    "متضعضع": 1,
    "متضلع": 1,
    "متطامن": 1,
    "متطبب": 1,
    "متطربش": 1,
    "متطرفة": 1,
    "متطلبات": 1,
    "متطلع": 1,
    "متطلق": 1,
    "متطير": 1,
    "متظاھر": 1,
    "متظرف": 1,
    "متعاظم": 1,
    "متعاقدان": 1,
    "متعاكس": 1,
    "متعاھد": 1,
    "متعبد": 1,
    "متعد": 1,
    "متعسر": 1,
    "متعص": 1,
    "متعلقاته": 1,
    "متعنت": 1,
    "متعه": 1,
    "متعوس": 1,
    "متعھد": 1,
    "متغفل": 1,
    "متغلغل": 1,
    "متغيم": 1,
    "متفاوتة": 1,
    "متفتر": 1,
    "متفرعات": 1,
    "متفرغ": 1,
    "متفرنج": 1,
    "متفقد": 1,
    "متفلة": 1,
    "متفلسف": 1,
    "متفنن": 1,
    "متق": 1,
    "متقاض": 1,
    "متقاضون": 1,
    "متقاضى": 1,
    "متقاعس": 1,
    "متقدس": 1,
    "متقوم": 1,
    "متكاثف": 1,
    "متكدر": 1,
    "متكره": 1,
    "متكلفة": 1,
    "متكھن": 1,
    "متلألئ": 1,
    "متلاحق": 1,
    "متلاش": 1,
    "متلاصق": 1,
    "متلاعن": 1,
    "متلاف": 1,
    "متلبس": 1,
    "متلبسا": 1,
    "متلثم": 1,
    "متلدد": 1,
    "متلذذ": 1,
    "متلصص": 1,
    "متلصصا": 1,
    "متلطم": 1,
    "متلف": 1,
    "متلفة": 1,
    "متلمع": 1,
    "متله": 1,
    "متلوف": 1,
    "متلھف": 1,
    "متماوج": 1,
    "متماھل": 1,
    "متمدن": 1,
    "متمدين": 1,
    "متمرض": 1,
    "متمرن": 1,
    "متمصر": 1,
    "متمغط": 1,
    "متمول": 1,
    "متمھل": 1,
    "متناقضات": 1,
    "متناوب": 1,
    "متناول": 1,
    "متناولا": 1,
    "متناوله": 1,
    "متنبر": 1,
    "متنبه": 1,
    "متندم": 1,
    "متنسم": 1,
    "متنور": 1,
    "متنوعات": 1,
    "متنيح": 1,
    "متواتر": 1,
    "متوارث": 1,
    "متواز": 1,
    "متواشج": 1,
    "متواصلة": 1,
    "متوافر": 1,
    "متوال": 1,
    "متوالية": 1,
    "متوان": 1,
    "متوثب": 1,
    "متوحد": 1,
    "متوطن": 1,
    "متوع": 1,
    "متوفق": 1,
    "متوفى": 1,
    "متوقد": 1,
    "متوكد": 1,
    "متوكلي": 1,
    "متوله": 1,
    "متولي": 1,
    "متيسر": 1,
    "متيم": 1,
    "متھالك": 1,
    "متھاود": 1,
    "متھاون": 1,
    "متھتك": 1,
    "متھدم": 1,
    "متھذب": 1,
    "متھكم": 1,
    "متھلل": 1,
    "متھم": 1,
    "متھود": 1,
    "متھور": 1,
    "متھوس": 1,
    "متھيب": 1,
    "متھيج": 1,
    "مثائل": 1,
    "مثاب": 1,
    "مثابرة": 1,
    "مثاث": 1,
    "مثاعب": 1,
    "مثاقب": 1,
    "مثاقفة": 1,
    "مثاقيل": 1,
    "مثالب": 1,
    "مثالة": 1,
    "مثالج": 1,
    "مثانة": 1,
    "مثاو": 1,
    "مثبوت": 1,
    "مثر": 1,
    "مثرد": 1,
    "مثعب": 1,
    "مثلبة": 1,
    "مثلة": 1,
    "مثلجة": 1,
    "مثلما": 1,
    "مثلوج": 1,
    "مثلوجات": 1,
    "مثلوم": 1,
    "مثلى": 1,
    "مثمون": 1,
    "مثول": 1,
    "مثيلتھا": 1,
    "مثيله": 1,
    "مج": 1,
    "مجابھة": 1,
    "مجاج": 1,
    "مجاجة": 1,
    "مجادة": 1,
    "مجادف": 1,
    "مجادلة": 1,
    "مجاذب": 1,
    "مجاذف": 1,
    "مجار": 1,
    "مجاراة": 1,
    "مجارد": 1,
    "مجارف": 1,
    "مجارى": 1,
    "مجاريح": 1,
    "مجارير": 1,
    "مجاريھا": 1,
    "مجازا": 1,
    "مجازر": 1,
    "مجازف": 1,
    "مجازى": 1,
    "مجاعة": 1,
    "مجالا": 1,
    "مجالب": 1,
    "مجالد": 1,
    "مجالسة": 1,
    "مجامر": 1,
    "مجامع": 1,
    "مجاملة": 1,
    "مجان": 1,
    "مجانة": 1,
    "مجانسة": 1,
    "مجانق": 1,
    "مجانية": 1,
    "مجانين": 1,
    "مجاورة": 1,
    "مجاوزة": 1,
    "مجاھد": 1,
    "مجاھدة": 1,
    "مجاھر": 1,
    "مجاھرة": 1,
    "مجاھل": 1,
    "مجاھيل": 1,
    "مجبور": 1,
    "مجبى": 1,
    "مجترئ": 1,
    "مجترة": 1,
    "مجتزل": 1,
    "مجتھد": 1,
    "مجثى": 1,
    "مجحف": 1,
    "مجداف": 1,
    "مجدال": 1,
    "مجدب": 1,
    "مجدر": 1,
    "مجدرة": 1,
    "مجدود": 1,
    "مجدور": 1,
    "مجذاف": 1,
    "مجذوب": 1,
    "مجذوم": 1,
    "مجر": 1,
    "مجراف": 1,
    "مجرة": 1,
    "مجردة": 1,
    "مجرف": 1,
    "مجرفة": 1,
    "مجرمة": 1,
    "مجروم": 1,
    "مجريط": 1,
    "مجز": 1,
    "مجزر": 1,
    "مجزرة": 1,
    "مجزع": 1,
    "مجزوم": 1,
    "مجسة": 1,
    "مجسمة": 1,
    "مجففات": 1,
    "مجل": 1,
    "مجلبة": 1,
    "مجلدة": 1,
    "مجلسا": 1,
    "مجلسه": 1,
    "مجلفن": 1,
    "مجلون": 1,
    "مجلى": 1,
    "مجم": 1,
    "مجمرة": 1,
    "مجمرك": 1,
    "مجمعى": 1,
    "مجن": 1,
    "مجنبة": 1,
    "مجنة": 1,
    "مجنح": 1,
    "مجنزر": 1,
    "مجنونا": 1,
    "مجنى": 1,
    "مجنيزيوم": 1,
    "مجوز": 1,
    "مجوس": 1,
    "مجوسي": 1,
    "مجوسية": 1,
    "مجون": 1,
    "مجوني": 1,
    "مجوھرات": 1,
    "مجيؤك": 1,
    "مجيئه": 1,
    "مجيدي": 1,
    "مجھار": 1,
    "مجھد": 1,
    "مجھر": 1,
    "مجھرى": 1,
    "مجھز": 1,
    "مجھل": 1,
    "مجھود": 1,
    "مجھودا": 1,
    "مجھوداته": 1,
    "مجھوده": 1,
    "مجھول": 1,
    "مجھولية": 1,
    "محا": 1,
    "محابر": 1,
    "محابس": 1,
    "محابيب": 1,
    "محاج": 1,
    "محاجر": 1,
    "محاجفة": 1,
    "محاجم": 1,
    "محاجن": 1,
    "محادثة": 1,
    "محاذرة": 1,
    "محارة": 1,
    "محارث": 1,
    "محارق": 1,
    "محارم": 1,
    "محاسبة": 1,
    "محاسبجي": 1,
    "محاسن": 1,
    "محاسنة": 1,
    "محاش": 1,
    "محاشش": 1,
    "محاشم": 1,
    "محاصة": 1,
    "محاصد": 1,
    "محاصرة": 1,
    "محاصل": 1,
    "محاضرة": 1,
    "محاضن": 1,
    "محافر": 1,
    "محافظة": 1,
    "محافل": 1,
    "محاق": 1,
    "محاقلة": 1,
    "محاقن": 1,
    "محال": 1,
    "محالج": 1,
    "محالفة": 1,
    "محالق": 1,
    "محام": 1,
    "محامد": 1,
    "محامل": 1,
    "محاميون": 1,
    "محان": 1,
    "محاورة": 1,
    "محاويج": 1,
    "محايدة": 1,
    "محبسة": 1,
    "محبنا": 1,
    "محبوبة": 1,
    "محبوك": 1,
    "محتار": 1,
    "محتبك": 1,
    "محتد": 1,
    "محترس": 1,
    "محتسب": 1,
    "محتضن": 1,
    "محتفل": 1,
    "محتلة": 1,
    "محتلم": 1,
    "محتم": 1,
    "محج": 1,
    "محجة": 1,
    "محجل": 1,
    "محجن": 1,
    "محدلة": 1,
    "محذور": 1,
    "محر": 1,
    "محراب": 1,
    "محراث": 1,
    "محراك": 1,
    "محرجات": 1,
    "محررات": 1,
    "محرقة": 1,
    "محرور": 1,
    "محروقات": 1,
    "محز": 1,
    "محزرة": 1,
    "محزنات": 1,
    "محزنة": 1,
    "محزون": 1,
    "محسبا": 1,
    "محسة": 1,
    "محسنات": 1,
    "محسنة": 1,
    "محسوبية": 1,
    "محسوسة": 1,
    "محش": 1,
    "محشة": 1,
    "محششة": 1,
    "محشوات": 1,
    "محشي": 1,
    "محص": 1,
    "محصد": 1,
    "محصدة": 1,
    "محضا": 1,
    "محضن": 1,
    "محضور": 1,
    "محط": 1,
    "محطمة": 1,
    "محظورات": 1,
    "محظية": 1,
    "محفة": 1,
    "محفظة": 1,
    "محفل": 1,
    "محفوظات": 1,
    "محفوظة": 1,
    "محق": 1,
    "محقة": 1,
    "محقنة": 1,
    "محقوق": 1,
    "محك": 1,
    "محكة": 1,
    "محكك": 1,
    "محكي": 1,
    "محلا": 1,
    "محلالات": 1,
    "محلب": 1,
    "محلة": 1,
    "محلج": 1,
    "محلجة": 1,
    "محلف": 1,
    "محلق": 1,
    "محليات": 1,
    "محلية": 1,
    "محم": 1,
    "محمدة": 1,
    "محمدل": 1,
    "محمدم": 1,
    "محمدية": 1,
    "محمرة": 1,
    "محمود": 1,
    "محمولة": 1,
    "محمولون": 1,
    "محمى": 1,
    "محمية": 1,
    "محن": 1,
    "محنة": 1,
    "محنق": 1,
    "محنى": 1,
    "محو": 1,
    "محوج": 1,
    "محوضة": 1,
    "محولة": 1,
    "محيض": 1,
    "محيل": 1,
    "مخابئ": 1,
    "مخابرة": 1,
    "مخابز": 1,
    "مخابير": 1,
    "مخاتل": 1,
    "مخاتير": 1,
    "مخاخ": 1,
    "مخاخة": 1,
    "مخاد": 1,
    "مخاديم": 1,
    "مخارج": 1,
    "مخارز": 1,
    "مخارط": 1,
    "مخارق": 1,
    "مخاز": 1,
    "مخازني": 1,
    "مخاصر": 1,
    "مخاصم": 1,
    "مخاضة": 1,
    "مخاط": 1,
    "مخاطب": 1,
    "مخاطبة": 1,
    "مخاطرة": 1,
    "مخافة": 1,
    "مخافر": 1,
    "مخالصة": 1,
    "مخالط": 1,
    "مخالطة": 1,
    "مخالي": 1,
    "مخاليط": 1,
    "مخاليف": 1,
    "مخاليق": 1,
    "مخاو": 1,
    "مخاوض": 1,
    "مخاوف": 1,
    "مخايل": 1,
    "مخبآت": 1,
    "مخبار": 1,
    "مخبز": 1,
    "مخبزة": 1,
    "مختارا": 1,
    "مختارات": 1,
    "مختبئ": 1,
    "مختتم": 1,
    "مختصات": 1,
    "مختف": 1,
    "مختفت": 1,
    "مختلطة": 1,
    "مختم": 1,
    "مخجول": 1,
    "مخدع": 1,
    "مخدم": 1,
    "مخدومة": 1,
    "مخدومية": 1,
    "مخذفة": 1,
    "مخر": 1,
    "مخراز": 1,
    "مخربشات": 1,
    "مخرجات": 1,
    "مخرز": 1,
    "مخرفة": 1,
    "مخرقة": 1,
    "مخروط": 1,
    "مخروم": 1,
    "مخزاة": 1,
    "مخزنجي": 1,
    "مخزني": 1,
    "مخزنية": 1,
    "مخزية": 1,
    "مخستك": 1,
    "مخسر": 1,
    "مخصبات": 1,
    "مخصرة": 1,
    "مخصصات": 1,
    "مخض": 1,
    "مخضرات": 1,
    "مخط": 1,
    "مخطر": 1,
    "مخطرة": 1,
    "مخطمة": 1,
    "مخطوبة": 1,
    "مخطوط": 1,
    "مخفر": 1,
    "مخففة": 1,
    "مخفقة": 1,
    "مخفور": 1,
    "مخق": 1,
    "مخلاة": 1,
    "مخلاف": 1,
    "مخلب": 1,
    "مخلط": 1,
    "مخلوفة": 1,
    "مخم": 1,
    "مخمض": 1,
    "مخمل": 1,
    "مخنق": 1,
    "مخه": 1,
    "مخور": 1,
    "مخوط": 1,
    "مخوف": 1,
    "مخير": 1,
    "مخيض": 1,
    "مخيل": 1,
    "مدائح": 1,
    "مدائن": 1,
    "مدابغ": 1,
    "مداجاة": 1,
    "مداح": 1,
    "مداخل": 1,
    "مداخلة": 1,
    "مداخن": 1,
    "مداخيل": 1,
    "مداد": 1,
    "مداراة": 1,
    "مدارج": 1,
    "مدارس": 1,
    "مدارك": 1,
    "مداره": 1,
    "مداعبة": 1,
    "مدافئ": 1,
    "مدافع": 1,
    "مدافعة": 1,
    "مدافن": 1,
    "مداق": 1,
    "مداليات": 1,
    "مداليه": 1,
    "مداليون": 1,
    "مدامع": 1,
    "مداواة": 1,
    "مداورة": 1,
    "مداولة": 1,
    "مداومة": 1,
    "مداھمة": 1,
    "مداھن": 1,
    "مداھنة": 1,
    "مدب": 1,
    "مدبجات": 1,
    "مدبغة": 1,
    "مدثور": 1,
    "مدجج": 1,
    "مدح": 1,
    "مدحاض": 1,
    "مدحة": 1,
    "مدحور": 1,
    "مدحى": 1,
    "مدخرة": 1,
    "مدخول": 1,
    "مدر": 1,
    "مدراة": 1,
    "مدرات": 1,
    "مدرار": 1,
    "مدراس": 1,
    "مدرة": 1,
    "مدرجه": 1,
    "مدرس": 1,
    "مدرعة": 1,
    "مدرعون": 1,
    "مدركات": 1,
    "مدره": 1,
    "مدرى": 1,
    "مدع": 1,
    "مدعبل": 1,
    "مدعيات": 1,
    "مدغل": 1,
    "مدفأة": 1,
    "مدفعي": 1,
    "مدفعية": 1,
    "مدفن": 1,
    "مدفنة": 1,
    "مدفوعات": 1,
    "مدق": 1,
    "مدقة": 1,
    "مدقع": 1,
    "مدل": 1,
    "مدلس": 1,
    "مدلسة": 1,
    "مدلكة": 1,
    "مدلن": 1,
    "مدله": 1,
    "مدلولات": 1,
    "مدلھم": 1,
    "مدماة": 1,
    "مدمس": 1,
    "مدمشق": 1,
    "مدمع": 1,
    "مدمك": 1,
    "مدموغة": 1,
    "مدموك": 1,
    "مدنف": 1,
    "مدنية": 1,
    "مدود": 1,
    "مدوس": 1,
    "مدونات": 1,
    "مدونة": 1,
    "مديات": 1,
    "مدية": 1,
    "مديح": 1,
    "مديد": 1,
    "مديدة": 1,
    "مديرية": 1,
    "مديل": 1,
    "مدينا": 1,
    "مديون": 1,
    "مديونية": 1,
    "مدھش": 1,
    "مدھم": 1,
    "مدھن": 1,
    "مدھوش": 1,
    "مذار": 1,
    "مذارة": 1,
    "مذاكرة": 1,
    "مذال": 1,
    "مذاود": 1,
    "مذاييع": 1,
    "مذاھب": 1,
    "مذبة": 1,
    "مذبحة": 1,
    "مذخر": 1,
    "مذر": 1,
    "مذرى": 1,
    "مذعان": 1,
    "مذق": 1,
    "مذلة": 1,
    "مذمة": 1,
    "مذود": 1,
    "مذياع": 1,
    "مذيعة": 1,
    "مذيق": 1,
    "مذھب": 1,
    "مذھبا": 1,
    "مذھبه": 1,
    "مذھبي": 1,
    "مذھبية": 1,
    "مذھوب": 1,
    "مذھول": 1,
    "مرأ": 1,
    "مرأب": 1,
    "مرأى": 1,
    "مرؤ": 1,
    "مرؤوس": 1,
    "مرئ": 1,
    "مراءاة": 1,
    "مراءة": 1,
    "مراآة": 1,
    "مراؤون": 1,
    "مرائب": 1,
    "مرائر": 1,
    "مراب": 1,
    "مرابحة": 1,
    "مرابض": 1,
    "مرابع": 1,
    "مراتب": 1,
    "مراتع": 1,
    "مراث": 1,
    "مراجل": 1,
    "مراجيح": 1,
    "مراحض": 1,
    "مراحم": 1,
    "مراحى": 1,
    "مراد": 1,
    "مرادة": 1,
    "مرادن": 1,
    "مراده": 1,
    "مرادى": 1,
    "مرار": 1,
    "مرارة": 1,
    "مرارته": 1,
    "مراريد": 1,
    "مرازب": 1,
    "مرازبة": 1,
    "مرازيب": 1,
    "مراس": 1,
    "مراسة": 1,
    "مراسح": 1,
    "مراسلات": 1,
    "مراسلة": 1,
    "مراشيد": 1,
    "مراضع": 1,
    "مراضى": 1,
    "مراع": 1,
    "مراغم": 1,
    "مرافئ": 1,
    "مرافع": 1,
    "مرافعة": 1,
    "مرافقھم": 1,
    "مراقد": 1,
    "مراقص": 1,
    "مراقصة": 1,
    "مراقم": 1,
    "مراقيش": 1,
    "مراكبى": 1,
    "مراكش": 1,
    "مراكشي": 1,
    "مراكن": 1,
    "مراكنة": 1,
    "مراكيب": 1,
    "مرام": 1,
    "مران": 1,
    "مرانة": 1,
    "مراوح": 1,
    "مراوس": 1,
    "مراوغة": 1,
    "مراويس": 1,
    "مراية": 1,
    "مراھق": 1,
    "مراھقة": 1,
    "مراھم": 1,
    "مراھنة": 1,
    "مرب": 1,
    "مربة": 1,
    "مربد": 1,
    "مربض": 1,
    "مربعة": 1,
    "مربوع": 1,
    "مربون": 1,
    "مرتاع": 1,
    "مرتبه": 1,
    "مرتخص": 1,
    "مرتع": 1,
    "مرتفق": 1,
    "مرتقى": 1,
    "مرتك": 1,
    "مرتھن": 1,
    "مرث": 1,
    "مرثاء": 1,
    "مرثاة": 1,
    "مرثية": 1,
    "مرجاة": 1,
    "مرجان": 1,
    "مرجانية": 1,
    "مرجل": 1,
    "مرجوحة": 1,
    "مرجونة": 1,
    "مرحاض": 1,
    "مرحمة": 1,
    "مرحوم": 1,
    "مرحى": 1,
    "مرخ": 1,
    "مرداء": 1,
    "مردة": 1,
    "مردقوش": 1,
    "مردن": 1,
    "مرده": 1,
    "مردى": 1,
    "مرذول": 1,
    "مرزاب": 1,
    "مرزبان": 1,
    "مرزبة": 1,
    "مرزجوش": 1,
    "مرزغ": 1,
    "مرزنجوش": 1,
    "مرزوق": 1,
    "مرس": 1,
    "مرسال": 1,
    "مرسة": 1,
    "مرسح": 1,
    "مرسحي": 1,
    "مرسلية": 1,
    "مرسم": 1,
    "مرسومة": 1,
    "مرسيليا": 1,
    "مرسين": 1,
    "مرش": 1,
    "مرشال": 1,
    "مرشة": 1,
    "مرشدة": 1,
    "مرص": 1,
    "مرصاد": 1,
    "مرصود": 1,
    "مرضاة": 1,
    "مرضاح": 1,
    "مرط": 1,
    "مرطاء": 1,
    "مرطبات": 1,
    "مرطم": 1,
    "مرع": 1,
    "مرعة": 1,
    "مرعى": 1,
    "مرغبات": 1,
    "مرغرين": 1,
    "مرغمة": 1,
    "مرفعة": 1,
    "مرفقات": 1,
    "مرفين": 1,
    "مرقاة": 1,
    "مرقاش": 1,
    "مرقاق": 1,
    "مرقب": 1,
    "مرقبة": 1,
    "مرقة": 1,
    "مرقد": 1,
    "مرقدات": 1,
    "مرقص": 1,
    "مرقعات": 1,
    "مرقعة": 1,
    "مرقعية": 1,
    "مرقوق": 1,
    "مركزا": 1,
    "مركم": 1,
    "مركن": 1,
    "مركيز": 1,
    "مرمة": 1,
    "مرمتون": 1,
    "مرمر": 1,
    "مرمري": 1,
    "مرمط": 1,
    "مرمطون": 1,
    "مرملة": 1,
    "مرموق": 1,
    "مرمى": 1,
    "مرميس": 1,
    "مرنان": 1,
    "مرنحة": 1,
    "مرنز": 1,
    "مرو": 1,
    "مروء": 1,
    "مروءة": 1,
    "مرواح": 1,
    "مروة": 1,
    "مروخ": 1,
    "مرود": 1,
    "مروق": 1,
    "مروقي": 1,
    "مرون": 1,
    "مرويات": 1,
    "مرى": 1,
    "مريء": 1,
    "مرياح": 1,
    "مريخ": 1,
    "مريرة": 1,
    "مريسة": 1,
    "مريسي": 1,
    "مريط": 1,
    "مريعة": 1,
    "مريمية": 1,
    "مريول": 1,
    "مرھف": 1,
    "مرھق": 1,
    "مرھم": 1,
    "مرھوب": 1,
    "مرھون": 1,
    "مرھونة": 1,
    "مز": 1,
    "مزائد": 1,
    "مزابد": 1,
    "مزابل": 1,
    "مزاج": 1,
    "مزاجه": 1,
    "مزاحة": 1,
    "مزاحم": 1,
    "مزاحمة": 1,
    "مزاد": 1,
    "مزادة": 1,
    "مزارعة": 1,
    "مزاريب": 1,
    "مزاريق": 1,
    "مزاعم": 1,
    "مزاغل": 1,
    "مزافت": 1,
    "مزالج": 1,
    "مزالق": 1,
    "مزاليج": 1,
    "مزامير": 1,
    "مزاوجة": 1,
    "مزاود": 1,
    "مزاول": 1,
    "مزاولة": 1,
    "مزايا": 1,
    "مزايدة": 1,
    "مزاھر": 1,
    "مزبدة": 1,
    "مزبلة": 1,
    "مزة": 1,
    "مزججات": 1,
    "مزججة": 1,
    "مزجر": 1,
    "مزجى": 1,
    "مزدان": 1,
    "مزر": 1,
    "مزراب": 1,
    "مزراق": 1,
    "مزرد": 1,
    "مزرع": 1,
    "مزرعاني": 1,
    "مزرى": 1,
    "مزع": 1,
    "مزعة": 1,
    "مزعجات": 1,
    "مزغل": 1,
    "مزفة": 1,
    "مزفتة": 1,
    "مزقة": 1,
    "مزقه": 1,
    "مزلج": 1,
    "مزلق": 1,
    "مزلقان": 1,
    "مزلقة": 1,
    "مزمز": 1,
    "مزمع": 1,
    "مزمور": 1,
    "مزن": 1,
    "مزوج": 1,
    "مزوق": 1,
    "مزولة": 1,
    "مزية": 1,
    "مزيتة": 1,
    "مزھر": 1,
    "مزھرية": 1,
    "مزھو": 1,
    "مسألة": 1,
    "مسئلة": 1,
    "مسئولية": 1,
    "مساءة": 1,
    "مساءلة": 1,
    "مساؤكم": 1,
    "مسائل": 1,
    "مسابة": 1,
    "مسابح": 1,
    "مسابر": 1,
    "مسابق": 1,
    "مسابك": 1,
    "مسابير": 1,
    "مساتير": 1,
    "مساجلة": 1,
    "مساجين": 1,
    "مساح": 1,
    "مساحج": 1,
    "مساحر": 1,
    "مساحره": 1,
    "مساحق": 1,
    "مساحقة": 1,
    "مساحل": 1,
    "مساحن": 1,
    "مساحيج": 1,
    "مساخر": 1,
    "مساخط": 1,
    "مساخيط": 1,
    "مسارب": 1,
    "مسارج": 1,
    "مسارح": 1,
    "مساطب": 1,
    "مساطر": 1,
    "مساع": 1,
    "مساعر": 1,
    "مساعيد": 1,
    "مساعير": 1,
    "مساغ": 1,
    "مساغا": 1,
    "مسافة": 1,
    "مساقاة": 1,
    "مساقة": 1,
    "مساقه": 1,
    "مساك": 1,
    "مساكب": 1,
    "مساكة": 1,
    "مسال": 1,
    "مسالخ": 1,
    "مسالك": 1,
    "مسالمة": 1,
    "مساليق": 1,
    "مسام": 1,
    "مسامحة": 1,
    "مسامر": 1,
    "مسامرة": 1,
    "مسامط": 1,
    "مسامع": 1,
    "مسامعه": 1,
    "مسامعھم": 1,
    "مسامك": 1,
    "مسامية": 1,
    "مسامير": 1,
    "مسان": 1,
    "مسانھة": 1,
    "مساو": 1,
    "مساوف": 1,
    "مساويك": 1,
    "مسايفة": 1,
    "مسايل": 1,
    "مساھر": 1,
    "مساھمة": 1,
    "مسبار": 1,
    "مسببات": 1,
    "مسبت": 1,
    "مسبح": 1,
    "مسبحة": 1,
    "مسبر": 1,
    "مسبسل": 1,
    "مسبك": 1,
    "مسبول": 1,
    "مسبوه": 1,
    "مسة": 1,
    "مست": 1,
    "مستأنس": 1,
    "مستأنفة": 1,
    "مستأھل": 1,
    "مستبضع": 1,
    "مستجد": 1,
    "مستحجر": 1,
    "مستحدث": 1,
    "مستحفز": 1,
    "مستحفظ": 1,
    "مستحكم": 1,
    "مستحكمات": 1,
    "مستحيلات": 1,
    "مستخذ": 1,
    "مستخذئ": 1,
    "مستخرج": 1,
    "مستخرجة": 1,
    "مستخلص": 1,
    "مستدع": 1,
    "مستديم": 1,
    "مستديمة": 1,
    "مستراب": 1,
    "مستراح": 1,
    "مسترده": 1,
    "مستريب": 1,
    "مستريح": 1,
    "مستسر": 1,
    "مستسھل": 1,
    "مستشرق": 1,
    "مستضعف": 1,
    "مستطاع": 1,
    "مستطير": 1,
    "مستظرف": 1,
    "مستظرفة": 1,
    "مستعرب": 1,
    "مستعص": 1,
    "مستعط": 1,
    "مستعمرة": 1,
    "مستعير": 1,
    "مستغرب": 1,
    "مستغلق": 1,
    "مستفحل": 1,
    "مستفيق": 1,
    "مستقتل": 1,
    "مستقطع": 1,
    "مستكر": 1,
    "مستكه": 1,
    "مستكين": 1,
    "مستلة": 1,
    "مستمد": 1,
    "مستمرة": 1,
    "مستمسك": 1,
    "مستملح": 1,
    "مستنبت": 1,
    "مستنبطات": 1,
    "مستنزل": 1,
    "مستنطق": 1,
    "مستنير": 1,
    "مستوحد": 1,
    "مستوحش": 1,
    "مستوحى": 1,
    "مستوخم": 1,
    "مستور": 1,
    "مستوردات": 1,
    "مستوصف": 1,
    "مستوفز": 1,
    "مستوقد": 1,
    "مستيئس": 1,
    "مستھام": 1,
    "مستھتر": 1,
    "مستھتك": 1,
    "مستھدف": 1,
    "مستھدم": 1,
    "مستھزئ": 1,
    "مستھل": 1,
    "مستھلك": 1,
    "مستھين": 1,
    "مسجر": 1,
    "مسجلة": 1,
    "مسجى": 1,
    "مسحاء": 1,
    "مسحاة": 1,
    "مسحاج": 1,
    "مسحب": 1,
    "مسحة": 1,
    "مسحج": 1,
    "مسحجة": 1,
    "مسحل": 1,
    "مسحنة": 1,
    "مسحوج": 1,
    "مسحى": 1,
    "مسخ": 1,
    "مسخة": 1,
    "مسخرة": 1,
    "مسخطة": 1,
    "مسخنة": 1,
    "مسخوط": 1,
    "مسدسات": 1,
    "مسدسة": 1,
    "مسده": 1,
    "مسر": 1,
    "مسرة": 1,
    "مسرجة": 1,
    "مسرد": 1,
    "مسروقات": 1,
    "مسرى": 1,
    "مسطاح": 1,
    "مسطار": 1,
    "مسطبة": 1,
    "مسطر": 1,
    "مسطرة": 1,
    "مسطرين": 1,
    "مسعار": 1,
    "مسعد": 1,
    "مسعط": 1,
    "مسعود": 1,
    "مسعى": 1,
    "مسفى": 1,
    "مسقام": 1,
    "مسقعة": 1,
    "مسقى": 1,
    "مسكاتي": 1,
    "مسكان": 1,
    "مسكب": 1,
    "مسكة": 1,
    "مسكرات": 1,
    "مسكنة": 1,
    "مسكوبية": 1,
    "مسكوني": 1,
    "مسلاة": 1,
    "مسلاخ": 1,
    "مسلة": 1,
    "مسلحة": 1,
    "مسلخ": 1,
    "مسلطح": 1,
    "مسلفة": 1,
    "مسلفت": 1,
    "مسلك": 1,
    "مسلكا": 1,
    "مسلكي": 1,
    "مسلوقة": 1,
    "مسلوك": 1,
    "مسم": 1,
    "مسمار": 1,
    "مسمدات": 1,
    "مسمط": 1,
    "مسمعة": 1,
    "مسموحات": 1,
    "مسموم": 1,
    "مسميات": 1,
    "مسمھم": 1,
    "مسناة": 1,
    "مسنم": 1,
    "مسننة": 1,
    "مسنيات": 1,
    "مسو": 1,
    "مسواك": 1,
    "مسوح": 1,
    "مسوخ": 1,
    "مسورة": 1,
    "مسوط": 1,
    "مسوغ": 1,
    "مسوقر": 1,
    "مسوكر": 1,
    "مسي": 1,
    "مسيح": 1,
    "مسيرة": 1,
    "مسيك": 1,
    "مسيل": 1,
    "مسيلمة": 1,
    "مسيو": 1,
    "مسھب": 1,
    "مسھر": 1,
    "مسھل": 1,
    "مش": 1,
    "مشا": 1,
    "مشائخ": 1,
    "مشائيم": 1,
    "مشابھة": 1,
    "مشات": 1,
    "مشاتل": 1,
    "مشاتمة": 1,
    "مشاجب": 1,
    "مشاجرة": 1,
    "مشاحة": 1,
    "مشاحم": 1,
    "مشاحنة": 1,
    "مشادة": 1,
    "مشارب": 1,
    "مشارط": 1,
    "مشارطة": 1,
    "مشارقة": 1,
    "مشاط": 1,
    "مشاطرة": 1,
    "مشاعر": 1,
    "مشاعره": 1,
    "مشاعل": 1,
    "مشاعلي": 1,
    "مشاعلية": 1,
    "مشاغبة": 1,
    "مشاغل": 1,
    "مشاف": 1,
    "مشافر": 1,
    "مشافھة": 1,
    "مشاق": 1,
    "مشاقة": 1,
    "مشاك": 1,
    "مشاكسة": 1,
    "مشاكلة": 1,
    "مشال": 1,
    "مشالح": 1,
    "مشانق": 1,
    "مشاو": 1,
    "مشاور": 1,
    "مشاورة": 1,
    "مشاوير": 1,
    "مشاية": 1,
    "مشايخ": 1,
    "مشايع": 1,
    "مشايعة": 1,
    "مشايم": 1,
    "مشاھد": 1,
    "مشاھدات": 1,
    "مشاھدة": 1,
    "مشاھرة": 1,
    "مشاھير": 1,
    "مشبوب": 1,
    "مشتاع": 1,
    "مشتبك": 1,
    "مشتبه": 1,
    "مشتر": 1,
    "مشتريات": 1,
    "مشتك": 1,
    "مشتل": 1,
    "مشتمل": 1,
    "مشتملات": 1,
    "مشته": 1,
    "مشتى": 1,
    "مشتھى": 1,
    "مشتھيات": 1,
    "مشج": 1,
    "مشجب": 1,
    "مشح": 1,
    "مشحة": 1,
    "مشحذ": 1,
    "مشحذة": 1,
    "مشحر": 1,
    "مشحري": 1,
    "مشحم": 1,
    "مشحمة": 1,
    "مشحونات": 1,
    "مشخخة": 1,
    "مشخصات": 1,
    "مشخصة": 1,
    "مشد": 1,
    "مشددة": 1,
    "مشدوه": 1,
    "مشربة": 1,
    "مشربية": 1,
    "مشرحة": 1,
    "مشرط": 1,
    "مشرقي": 1,
    "مشروعا": 1,
    "مششخن": 1,
    "مشطي": 1,
    "مشعال": 1,
    "مشعة": 1,
    "مشعراني": 1,
    "مشعلة": 1,
    "مشعوذة": 1,
    "مشعور": 1,
    "مشغوف": 1,
    "مشغولية": 1,
    "مشف": 1,
    "مشفوع": 1,
    "مشق": 1,
    "مشقة": 1,
    "مشكاة": 1,
    "مشكاوات": 1,
    "مشكو": 1,
    "مشلح": 1,
    "مشلوز": 1,
    "مشمخر": 1,
    "مشمر": 1,
    "مشمش": 1,
    "مشملا": 1,
    "مشملة": 1,
    "مشمولات": 1,
    "مشنة": 1,
    "مشنقة": 1,
    "مشه": 1,
    "مشو": 1,
    "مشوار": 1,
    "مشواه": 1,
    "مشورة": 1,
    "مشورته": 1,
    "مشوم": 1,
    "مشيئة": 1,
    "مشيب": 1,
    "مشيتة": 1,
    "مشيته": 1,
    "مشيج": 1,
    "مشيخة": 1,
    "مشير": 1,
    "مشيق": 1,
    "مشيم": 1,
    "مشيمة": 1,
    "مشھد": 1,
    "مشھر": 1,
    "مشھود": 1,
    "مشھور": 1,
    "مشھيات": 1,
    "مص": 1,
    "مصائب": 1,
    "مصابرة": 1,
    "مصابيح": 1,
    "مصاحف": 1,
    "مصادرة": 1,
    "مصادمة": 1,
    "مصاراف": 1,
    "مصارمة": 1,
    "مصاريع": 1,
    "مصارين": 1,
    "مصاصة": 1,
    "مصاطب": 1,
    "مصاعب": 1,
    "مصاعد": 1,
    "مصاف": 1,
    "مصافاة": 1,
    "مصاقبة": 1,
    "مصاقع": 1,
    "مصاقل": 1,
    "مصالحة": 1,
    "مصايد": 1,
    "مصاير": 1,
    "مصايف": 1,
    "مصاھر": 1,
    "مصاھرة": 1,
    "مصب": 1,
    "مصبرات": 1,
    "مصبع": 1,
    "مصبغة": 1,
    "مصبنة": 1,
    "مصبه": 1,
    "مصبوبات": 1,
    "مصة": 1,
    "مصح": 1,
    "مصحة": 1,
    "مصحف": 1,
    "مصحون": 1,
    "مصدأ": 1,
    "مصداق": 1,
    "مصدقة": 1,
    "مصدور": 1,
    "مصدوع": 1,
    "مصراع": 1,
    "مصراعيه": 1,
    "مصران": 1,
    "مصرع": 1,
    "مصروع": 1,
    "مصرى": 1,
    "مصري": 1,
    "مصرية": 1,
    "مصطاف": 1,
    "مصطبة": 1,
    "مصطخب": 1,
    "مصطفى": 1,
    "مصطكاء": 1,
    "مصطكى": 1,
    "مصطلى": 1,
    "مصطول": 1,
    "مصعد": 1,
    "مصعدة": 1,
    "مصغ": 1,
    "مصف": 1,
    "مصفح": 1,
    "مصفحة": 1,
    "مصقع": 1,
    "مصقلة": 1,
    "مصلحة": 1,
    "مصلحي": 1,
    "مصلطح": 1,
    "مصمت": 1,
    "مصمص": 1,
    "مصنعية": 1,
    "مصنوعات": 1,
    "مصوات": 1,
    "مصوح": 1,
    "مصوغات": 1,
    "مصول": 1,
    "مصيب": 1,
    "مصير": 1,
    "مصيرھا": 1,
    "مصيص": 1,
    "مصيف": 1,
    "مصھر": 1,
    "مض": 1,
    "مضائق": 1,
    "مضابط": 1,
    "مضاجع": 1,
    "مضادات": 1,
    "مضاربة": 1,
    "مضارع": 1,
    "مضارعة": 1,
    "مضاض": 1,
    "مضاضة": 1,
    "مضاغة": 1,
    "مضاغط": 1,
    "مضافا": 1,
    "مضامير": 1,
    "مضامين": 1,
    "مضانك": 1,
    "مضاه": 1,
    "مضايقة": 1,
    "مضاھاة": 1,
    "مضبطة": 1,
    "مضجع": 1,
    "مضجعه": 1,
    "مضحكة": 1,
    "مضخة": 1,
    "مضراب": 1,
    "مضربة": 1,
    "مضرة": 1,
    "مضض": 1,
    "مضطجع": 1,
    "مضطر": 1,
    "مضطرم": 1,
    "مضطھد": 1,
    "مضلة": 1,
    "مضمحل": 1,
    "مضمحلة": 1,
    "مضمدة": 1,
    "مضمر": 1,
    "مضمض": 1,
    "مضموم": 1,
    "مضنك": 1,
    "مضنى": 1,
    "مضور": 1,
    "مضي": 1,
    "مضياع": 1,
    "مضيض": 1,
    "مضيعة": 1,
    "مضيفة": 1,
    "مط": 1,
    "مطأطأ": 1,
    "مطأطئ": 1,
    "مطا": 1,
    "مطائل": 1,
    "مطابخ": 1,
    "مطابع": 1,
    "مطاحن": 1,
    "مطارة": 1,
    "مطارح": 1,
    "مطارنة": 1,
    "مطارين": 1,
    "مطاعمه": 1,
    "مطاعن": 1,
    "مطاق": 1,
    "مطالبة": 1,
    "مطالعة": 1,
    "مطاليب": 1,
    "مطامح": 1,
    "مطامير": 1,
    "مطاو": 1,
    "مطاوى": 1,
    "مطاويه": 1,
    "مطايا": 1,
    "مطايب": 1,
    "مطايبة": 1,
    "مطاھر": 1,
    "مطبعة": 1,
    "مطبعجي": 1,
    "مطبعية": 1,
    "مطحنة": 1,
    "مطحول": 1,
    "مطراق": 1,
    "مطران": 1,
    "مطرانية": 1,
    "مطربة": 1,
    "مطربش": 1,
    "مطرة": 1,
    "مطرت": 1,
    "مطرح": 1,
    "مطردة": 1,
    "مطرش": 1,
    "مطرف": 1,
    "مطرق": 1,
    "مطرن": 1,
    "مطرنة": 1,
    "مطرنية": 1,
    "مطعوم": 1,
    "مطفحة": 1,
    "مطق": 1,
    "مطل": 1,
    "مطلب": 1,
    "مطلقا": 1,
    "مطلمة": 1,
    "مطلى": 1,
    "مطماع": 1,
    "مطمح": 1,
    "مطمر": 1,
    "مطمع": 1,
    "مطمعة": 1,
    "مطمور": 1,
    "مطمورة": 1,
    "مطو": 1,
    "مطواة": 1,
    "مطوة": 1,
    "مطوحة": 1,
    "مطوس": 1,
    "مطوع": 1,
    "مطوف": 1,
    "مطوى": 1,
    "مطي": 1,
    "مطياف": 1,
    "مطيب": 1,
    "مطية": 1,
    "مطير": 1,
    "مطيلة": 1,
    "مطھر": 1,
    "مطھرة": 1,
    "مطھم": 1,
    "مطھى": 1,
    "مظاريف": 1,
    "مظال": 1,
    "مظالم": 1,
    "مظان": 1,
    "مظانه": 1,
    "مظاھر": 1,
    "مظاھرة": 1,
    "مظروف": 1,
    "مظفر": 1,
    "مظل": 1,
    "مظلمة": 1,
    "مظلى": 1,
    "مظنة": 1,
    "مظھر": 1,
    "معاء": 1,
    "معابد": 1,
    "معابر": 1,
    "معاتبة": 1,
    "معاتيه": 1,
    "معاجم": 1,
    "معاجن": 1,
    "معادل": 1,
    "معاديد": 1,
    "معاذر": 1,
    "معاذير": 1,
    "معار": 1,
    "معارج": 1,
    "معارف": 1,
    "معارك": 1,
    "معاركة": 1,
    "معاريج": 1,
    "معاريض": 1,
    "معاز": 1,
    "معازف": 1,
    "معازق": 1,
    "معازل": 1,
    "معاش": 1,
    "معاشرة": 1,
    "معاص": 1,
    "معاصم": 1,
    "معاضدة": 1,
    "معاضل": 1,
    "معاطاة": 1,
    "معاطس": 1,
    "معافاة": 1,
    "معاقبات": 1,
    "معاقبة": 1,
    "معاقد": 1,
    "معاقل": 1,
    "معال": 1,
    "معالف": 1,
    "معالى": 1,
    "معالي": 1,
    "معاليق": 1,
    "معاليم": 1,
    "معاليه": 1,
    "معامع": 1,
    "معاملات": 1,
    "معاملته": 1,
    "معان": 1,
    "معاناة": 1,
    "معاند": 1,
    "معاندة": 1,
    "معاھد": 1,
    "معاھدة": 1,
    "معبودة": 1,
    "معتب": 1,
    "معتر": 1,
    "معترضة": 1,
    "معتز": 1,
    "معتزل": 1,
    "معتزم": 1,
    "معتقد": 1,
    "معتمدية": 1,
    "معتن": 1,
    "معثر": 1,
    "معثوث": 1,
    "معج": 1,
    "معجز": 1,
    "معجزة": 1,
    "معجلة": 1,
    "معجم": 1,
    "معجن": 1,
    "معجنات": 1,
    "معداد": 1,
    "معددة": 1,
    "معدنه": 1,
    "معذار": 1,
    "معر": 1,
    "معراج": 1,
    "معراض": 1,
    "معرج": 1,
    "معرص": 1,
    "معرق": 1,
    "معروق": 1,
    "معز": 1,
    "معزب": 1,
    "معزة": 1,
    "معزف": 1,
    "معزقة": 1,
    "معزوفة": 1,
    "معس": 1,
    "معسجد": 1,
    "معسر": 1,
    "معسرة": 1,
    "معسلة": 1,
    "معسور": 1,
    "معشوق": 1,
    "معشوقة": 1,
    "معصري": 1,
    "معصفر": 1,
    "معصم": 1,
    "معصية": 1,
    "معض": 1,
    "معضل": 1,
    "معضلة": 1,
    "معط": 1,
    "معطاء": 1,
    "معطس": 1,
    "معظمة": 1,
    "معظمه": 1,
    "معقدا": 1,
    "معقرب": 1,
    "معقف": 1,
    "معقودا": 1,
    "معقودة": 1,
    "معقوفان": 1,
    "معكر": 1,
    "معكرونه": 1,
    "معل": 1,
    "معلافة": 1,
    "معلاق": 1,
    "معلف": 1,
    "معلقة": 1,
    "معلمة": 1,
    "معماة": 1,
    "معمار": 1,
    "معمعان": 1,
    "معمعة": 1,
    "معمعي": 1,
    "معمور": 1,
    "معميات": 1,
    "معن": 1,
    "معناه": 1,
    "معنعن": 1,
    "معنوبات": 1,
    "معنوى": 1,
    "معنوية": 1,
    "معو": 1,
    "معوج": 1,
    "معوجه": 1,
    "معود": 1,
    "معى": 1,
    "معية": 1,
    "معير": 1,
    "معيز": 1,
    "معھد": 1,
    "معھود": 1,
    "مغار": 1,
    "مغارب": 1,
    "مغاربة": 1,
    "مغارة": 1,
    "مغارز": 1,
    "مغارس": 1,
    "مغارسة": 1,
    "مغارسى": 1,
    "مغارف": 1,
    "مغارم": 1,
    "مغاز": 1,
    "مغازلة": 1,
    "مغاسل": 1,
    "مغاص": 1,
    "مغاض": 1,
    "مغاطس": 1,
    "مغافر": 1,
    "مغالبة": 1,
    "مغالط": 1,
    "مغالطة": 1,
    "مغاليق": 1,
    "مغامرة": 1,
    "مغامز": 1,
    "مغان": 1,
    "مغاور": 1,
    "مغاوير": 1,
    "مغبة": 1,
    "مغبوط": 1,
    "مغبون": 1,
    "مغتاب": 1,
    "مغتاظ": 1,
    "مغتم": 1,
    "مغدودن": 1,
    "مغدى": 1,
    "مغر": 1,
    "مغراة": 1,
    "مغربة": 1,
    "مغربي": 1,
    "مغرة": 1,
    "مغرز": 1,
    "مغرس": 1,
    "مغرض": 1,
    "مغرفة": 1,
    "مغريات": 1,
    "مغزاة": 1,
    "مغسل": 1,
    "مغسلة": 1,
    "مغص": 1,
    "مغصوب": 1,
    "مغضوب": 1,
    "مغط": 1,
    "مغطسة": 1,
    "مغفر": 1,
    "مغفل": 1,
    "مغفلة": 1,
    "مغل": 1,
    "مغلاق": 1,
    "مغلب": 1,
    "مغلطة": 1,
    "مغلظ": 1,
    "مغلظة": 1,
    "مغمز": 1,
    "مغموس": 1,
    "مغمى": 1,
    "مغن": 1,
    "مغناج": 1,
    "مغنطيس": 1,
    "مغنطيسي": 1,
    "مغنطيسية": 1,
    "مغنم": 1,
    "مغنية": 1,
    "مغنيسيا": 1,
    "مغواة": 1,
    "مغولي": 1,
    "مغويات": 1,
    "مغيبات": 1,
    "مغيبة": 1,
    "مغيث": 1,
    "مفاتحة": 1,
    "مفاتن": 1,
    "مفاجآت": 1,
    "مفاجأة": 1,
    "مفاجئات": 1,
    "مفاخر": 1,
    "مفاخرة": 1,
    "مفاداة": 1,
    "مفادھا": 1,
    "مفارج": 1,
    "مفارح": 1,
    "مفارز": 1,
    "مفارق": 1,
    "مفازة": 1,
    "مفاسد": 1,
    "مفاسق": 1,
    "مفاصد": 1,
    "مفاصل": 1,
    "مفاضل": 1,
    "مفاعيل": 1,
    "مفاقس": 1,
    "مفاكھة": 1,
    "مفاليس": 1,
    "مفاوز": 1,
    "مفاوضة": 1,
    "مفاھيم": 1,
    "مفت": 1,
    "مفتاحجي": 1,
    "مفتح": 1,
    "مفتشية": 1,
    "مفتعل": 1,
    "مفتن": 1,
    "مفتوق": 1,
    "مفتي": 1,
    "مفجعات": 1,
    "مفحشة": 1,
    "مفخرة": 1,
    "مفخم": 1,
    "مفدي": 1,
    "مفراة": 1,
    "مفراك": 1,
    "مفرج": 1,
    "مفردات": 1,
    "مفرزات": 1,
    "مفرزة": 1,
    "مفرشة": 1,
    "مفرص": 1,
    "مفرطح": 1,
    "مفرع": 1,
    "مفرغة": 1,
    "مفرقعات": 1,
    "مفرمة": 1,
    "مفرمم": 1,
    "مفروشات": 1,
    "مفروضات": 1,
    "مفروغ": 1,
    "مفزعة": 1,
    "مفسدة": 1,
    "مفسقة": 1,
    "مفصح": 1,
    "مفصد": 1,
    "مفصلة": 1,
    "مفض": 1,
    "مفضال": 1,
    "مفضلة": 1,
    "مفطح": 1,
    "مفظع": 1,
    "مفعم": 1,
    "مفعول": 1,
    "مفعوله": 1,
    "مفك": 1,
    "مفكرات": 1,
    "مفكرة": 1,
    "مفلح": 1,
    "مفلفل": 1,
    "مفلك": 1,
    "مفلوج": 1,
    "مفلور": 1,
    "مفلوك": 1,
    "مفلول": 1,
    "مفوضية": 1,
    "مفوف": 1,
    "مفوه": 1,
    "مفيضا": 1,
    "مفيق": 1,
    "مفھوم": 1,
    "مقابح": 1,
    "مقابر": 1,
    "مقابيض": 1,
    "مقاتة": 1,
    "مقاتلة": 1,
    "مقاتله": 1,
    "مقاحيم": 1,
    "مقادة": 1,
    "مقاذر": 1,
    "مقاذف": 1,
    "مقاذيف": 1,
    "مقار": 1,
    "مقارعة": 1,
    "مقارم": 1,
    "مقاريض": 1,
    "مقاساة": 1,
    "مقاسم": 1,
    "مقاسمة": 1,
    "مقاشط": 1,
    "مقاص": 1,
    "مقاصة": 1,
    "مقاصد": 1,
    "مقاصف": 1,
    "مقاصل": 1,
    "مقاصير": 1,
    "مقاصيص": 1,
    "مقاضاة": 1,
    "مقاطر": 1,
    "مقاطف": 1,
    "مقاطيع": 1,
    "مقالب": 1,
    "مقالة": 1,
    "مقالد": 1,
    "مقالع": 1,
    "مقاليع": 1,
    "مقامة": 1,
    "مقامرة": 1,
    "مقامه": 1,
    "مقانب": 1,
    "مقانق": 1,
    "مقاه": 1,
    "مقاود": 1,
    "مقاوضة": 1,
    "مقاولة": 1,
    "مقايسة": 1,
    "مقايضة": 1,
    "مقبر": 1,
    "مقبرة": 1,
    "مقبري": 1,
    "مقبع": 1,
    "مقبوض": 1,
    "مقتبسات": 1,
    "مقتبل": 1,
    "مقتتل": 1,
    "مقتصر": 1,
    "مقتضبات": 1,
    "مقتضى": 1,
    "مقتضيات": 1,
    "مقتل": 1,
    "مقتلة": 1,
    "مقتنى": 1,
    "مقتنيات": 1,
    "مقحام": 1,
    "مقدحة": 1,
    "مقدرات": 1,
    "مقدور": 1,
    "مقدوره": 1,
    "مقدونس": 1,
    "مقدوني": 1,
    "مقذاف": 1,
    "مقذف": 1,
    "مقذوفة": 1,
    "مقرئ": 1,
    "مقراء": 1,
    "مقراض": 1,
    "مقرح": 1,
    "مقررات": 1,
    "مقررة": 1,
    "مقرش": 1,
    "مقرقش": 1,
    "مقرم": 1,
    "مقرمش": 1,
    "مقرود": 1,
    "مقرون": 1,
    "مقرى": 1,
    "مقشة": 1,
    "مقشط": 1,
    "مقشطة": 1,
    "مقص": 1,
    "مقصب": 1,
    "مقصد": 1,
    "مقصف": 1,
    "مقصل": 1,
    "مقصلة": 1,
    "مقصوصة": 1,
    "مقضب": 1,
    "مقطرات": 1,
    "مقطرة": 1,
    "مقطف": 1,
    "مقطنة": 1,
    "مقطورات": 1,
    "مقطوعية": 1,
    "مقع": 1,
    "مقعدة": 1,
    "مقفز": 1,
    "مقلاة": 1,
    "مقلاد": 1,
    "مقلاع": 1,
    "مقلقل": 1,
    "مقلمة": 1,
    "مقلية": 1,
    "مقمة": 1,
    "مقمرة": 1,
    "مقمل": 1,
    "مقمن": 1,
    "مقنب": 1,
    "مقنبلات": 1,
    "مقنبلة": 1,
    "مقند": 1,
    "مقنطر": 1,
    "مقنطرة": 1,
    "مقنود": 1,
    "مقو": 1,
    "مقوتب": 1,
    "مقور": 1,
    "مقورة": 1,
    "مقول": 1,
    "مقومات": 1,
    "مقويات": 1,
    "مقيئات": 1,
    "مقيظ": 1,
    "مقيمي": 1,
    "مقيمية": 1,
    "مقينة": 1,
    "مقھاة": 1,
    "مقھاية": 1,
    "مقھى": 1,
    "مكائن": 1,
    "مكابر": 1,
    "مكابرة": 1,
    "مكابس": 1,
    "مكابيس": 1,
    "مكاتبة": 1,
    "مكاتل": 1,
    "مكاتيب": 1,
    "مكاحل": 1,
    "مكار": 1,
    "مكارم": 1,
    "مكاره": 1,
    "مكاريب": 1,
    "مكاس": 1,
    "مكاسب": 1,
    "مكاسدة": 1,
    "مكاشري": 1,
    "مكافاة": 1,
    "مكافيف": 1,
    "مكاكيك": 1,
    "مكالمة": 1,
    "مكامن": 1,
    "مكانته": 1,
    "مكانس": 1,
    "مكانية": 1,
    "مكاو": 1,
    "مكايد": 1,
    "مكايل": 1,
    "مكاييل": 1,
    "مكب": 1,
    "مكباس": 1,
    "مكبتل": 1,
    "مكبح": 1,
    "مكبس": 1,
    "مكبوس": 1,
    "مكبوسات": 1,
    "مكتاب": 1,
    "مكتبة": 1,
    "مكتتب": 1,
    "مكتر": 1,
    "مكترب": 1,
    "مكتشفات": 1,
    "مكتف": 1,
    "مكتنف": 1,
    "مكتوف": 1,
    "مكثار": 1,
    "مكحال": 1,
    "مكحل": 1,
    "مكحلة": 1,
    "مكدام": 1,
    "مكدود": 1,
    "مكدوني": 1,
    "مكدونيا": 1,
    "مكذا": 1,
    "مكرة": 1,
    "مكررونه": 1,
    "مكرش": 1,
    "مكروب": 1,
    "مكروھة": 1,
    "مكرى": 1,
    "مكرھة": 1,
    "مكس": 1,
    "مكسال": 1,
    "مكسب": 1,
    "مكسبة": 1,
    "مكسح": 1,
    "مكسحة": 1,
    "مكسرات": 1,
    "مكسم": 1,
    "مكشط": 1,
    "مكعبة": 1,
    "مكعبر": 1,
    "مكفت": 1,
    "مكفھر": 1,
    "مكلفة": 1,
    "مكلكل": 1,
    "مكلوب": 1,
    "مكمدة": 1,
    "مكمن": 1,
    "مكمور": 1,
    "مكن": 1,
    "مكناء": 1,
    "مكناس": 1,
    "مكناسة": 1,
    "مكنب": 1,
    "مكنة": 1,
    "مكنسة": 1,
    "مكنى": 1,
    "مكواة": 1,
    "مكوث": 1,
    "مكوجي": 1,
    "مكورة": 1,
    "مكوس": 1,
    "مكوك": 1,
    "مكوه": 1,
    "مكوى": 1,
    "مكى": 1,
    "مكيال": 1,
    "مكيدة": 1,
    "مكيساتي": 1,
    "مكيفات": 1,
    "مكيفة": 1,
    "مكيل": 1,
    "مكيلة": 1,
    "مكينا": 1,
    "مكھرب": 1,
    "مكھن": 1,
    "ملآن": 1,
    "ملآنة": 1,
    "ملأة": 1,
    "ملأك": 1,
    "ملأمة": 1,
    "ملؤه": 1,
    "ملا": 1,
    "ملاء": 1,
    "ملاءة": 1,
    "ملائك": 1,
    "ملابسات": 1,
    "ملابسة": 1,
    "ملابسه": 1,
    "ملاحة": 1,
    "ملاحدة": 1,
    "ملاحف": 1,
    "ملاحق": 1,
    "ملاحقة": 1,
    "ملاحم": 1,
    "ملاذ": 1,
    "ملازمة": 1,
    "ملاسة": 1,
    "ملاشاة": 1,
    "ملاصقة": 1,
    "ملاط": 1,
    "ملاطفة": 1,
    "ملاطيس": 1,
    "ملاعيب": 1,
    "ملاعين": 1,
    "ملافاة": 1,
    "ملاقاة": 1,
    "ملاقط": 1,
    "ملاكي": 1,
    "ملال": 1,
    "ملالة": 1,
    "ملاليم": 1,
    "ملامة": 1,
    "ملامسة": 1,
    "ملاه": 1,
    "ملاو": 1,
    "ملاوص": 1,
    "ملاوع": 1,
    "ملاوق": 1,
    "ملاوم": 1,
    "ملايا": 1,
    "ملاية": 1,
    "ملاينة": 1,
    "ملايو": 1,
    "ملايين": 1,
    "ملبن": 1,
    "ملبنة": 1,
    "ملتاث": 1,
    "ملتاح": 1,
    "ملتجئ": 1,
    "ملتح": 1,
    "ملتحف": 1,
    "ملتحمة": 1,
    "ملتفت": 1,
    "ملتقيات": 1,
    "ملتوى": 1,
    "ملتھب": 1,
    "ملتھبة": 1,
    "ملثم": 1,
    "ملج": 1,
    "ملجلج": 1,
    "ملجم": 1,
    "ملجوم": 1,
    "ملحاح": 1,
    "ملحس": 1,
    "ملحف": 1,
    "ملحفة": 1,
    "ملحقة": 1,
    "ملحمة": 1,
    "ملحوس": 1,
    "ملحوظة": 1,
    "ملحون": 1,
    "ملحي": 1,
    "ملخ": 1,
    "ملخلخ": 1,
    "ملد": 1,
    "ملداء": 1,
    "ملذة": 1,
    "ملزز": 1,
    "ملزمة": 1,
    "ملزوم": 1,
    "ملزومية": 1,
    "ملساء": 1,
    "ملسون": 1,
    "ملص": 1,
    "ملضوم": 1,
    "ملط": 1,
    "ملطاس": 1,
    "ملطفات": 1,
    "ملطم": 1,
    "ملعبة": 1,
    "ملغاة": 1,
    "ملغز": 1,
    "ملفاف": 1,
    "ملفت": 1,
    "ملفع": 1,
    "ملق": 1,
    "ملقط": 1,
    "ملقم": 1,
    "ملقى": 1,
    "ملقيات": 1,
    "ملكاء": 1,
    "ملكمة": 1,
    "ملكوتي": 1,
    "ملل": 1,
    "مللأى": 1,
    "ملمس": 1,
    "ملمسي": 1,
    "ململ": 1,
    "ململة": 1,
    "ململمة": 1,
    "ملموسات": 1,
    "ملموم": 1,
    "ملنخوليا": 1,
    "مله": 1,
    "ملو": 1,
    "ملوة": 1,
    "ملوح": 1,
    "ملوخية": 1,
    "ملوسة": 1,
    "ملوط": 1,
    "ملوق": 1,
    "ملول": 1,
    "ملوى": 1,
    "ملى": 1,
    "ملي": 1,
    "مليار": 1,
    "ملية": 1,
    "مليجرام": 1,
    "مليح": 1,
    "مليحة": 1,
    "مليخ": 1,
    "مليص": 1,
    "مليط": 1,
    "مليكة": 1,
    "مليما": 1,
    "مليمتر": 1,
    "ملينات": 1,
    "ملھاة": 1,
    "ملھم": 1,
    "ملھوف": 1,
    "ملھى": 1,
    "مماثلة": 1,
    "مماحكة": 1,
    "مماخض": 1,
    "مماذق": 1,
    "ممارق": 1,
    "مماس": 1,
    "مماسة": 1,
    "مماسح": 1,
    "مماش": 1,
    "مماطر": 1,
    "مماطلة": 1,
    "ممالأة": 1,
    "ممالئ": 1,
    "ممالح": 1,
    "ممالك": 1,
    "مماليك": 1,
    "ممانعة": 1,
    "مماھد": 1,
    "ممتعض": 1,
    "ممثلية": 1,
    "ممجمج": 1,
    "ممحاة": 1,
    "ممحل": 1,
    "ممخضة": 1,
    "ممدد": 1,
    "ممراح": 1,
    "ممراض": 1,
    "ممراع": 1,
    "ممرضة": 1,
    "ممرن": 1,
    "ممسح": 1,
    "ممشوق": 1,
    "ممص": 1,
    "ممصوص": 1,
    "ممض": 1,
    "ممضى": 1,
    "ممطرة": 1,
    "ممعود": 1,
    "ممغطس": 1,
    "ممقتع": 1,
    "ممكنات": 1,
    "مملحة": 1,
    "مملقة": 1,
    "مملك": 1,
    "مملوح": 1,
    "مملول": 1,
    "ممنوعية": 1,
    "ممنونية": 1,
    "مموج": 1,
    "مميتة": 1,
    "ممھد": 1,
    "منأى": 1,
    "منائر": 1,
    "مناب": 1,
    "منابت": 1,
    "منابر": 1,
    "منابع": 1,
    "منابه": 1,
    "منابير": 1,
    "مناج": 1,
    "مناجذ": 1,
    "مناجر": 1,
    "مناجزة": 1,
    "مناجل": 1,
    "مناح": 1,
    "مناحة": 1,
    "مناحت": 1,
    "مناحس": 1,
    "مناحل": 1,
    "مناحي": 1,
    "مناخ": 1,
    "مناخر": 1,
    "مناخس": 1,
    "مناخل": 1,
    "مناخير": 1,
    "مناخيس": 1,
    "مناد": 1,
    "مناداة": 1,
    "منادب": 1,
    "منادح": 1,
    "منادر": 1,
    "منادرات": 1,
    "منادف": 1,
    "منادم": 1,
    "منادمة": 1,
    "منادى": 1,
    "منارة": 1,
    "منازع": 1,
    "منازعة": 1,
    "منازلة": 1,
    "منازه": 1,
    "مناسج": 1,
    "مناسر": 1,
    "مناسف": 1,
    "مناسك": 1,
    "مناسم": 1,
    "مناسيب": 1,
    "مناشدة": 1,
    "مناشف": 1,
    "مناشير": 1,
    "مناصر": 1,
    "مناصرة": 1,
    "مناضح": 1,
    "مناضد": 1,
    "مناط": 1,
    "مناطحة": 1,
    "مناطقة": 1,
    "مناطيد": 1,
    "مناظير": 1,
    "مناعة": 1,
    "مناعم": 1,
    "مناف": 1,
    "منافخ": 1,
    "منافذ": 1,
    "منافسة": 1,
    "منافض": 1,
    "منافقة": 1,
    "منافيخ": 1,
    "مناقب": 1,
    "مناقشة": 1,
    "مناقصة": 1,
    "مناقضة": 1,
    "مناقع": 1,
    "مناقل": 1,
    "مناقيد": 1,
    "مناقيش": 1,
    "مناكب": 1,
    "مناكح": 1,
    "مناكدة": 1,
    "مناكش": 1,
    "مناكيد": 1,
    "مناكيش": 1,
    "منال": 1,
    "منامة": 1,
    "منان": 1,
    "مناو": 1,
    "مناوأة": 1,
    "مناوب": 1,
    "مناوبة": 1,
    "مناوشة": 1,
    "مناوط": 1,
    "مناولة": 1,
    "مناويش": 1,
    "مناويشي": 1,
    "منايا": 1,
    "مناھج": 1,
    "مناھضة": 1,
    "مناھيج": 1,
    "منبار": 1,
    "منبسق": 1,
    "منبض": 1,
    "منبع": 1,
    "منبعج": 1,
    "منبھة": 1,
    "منة": 1,
    "منتاش": 1,
    "منتجات": 1,
    "منتحر": 1,
    "منتخبات": 1,
    "منتدبة": 1,
    "منتدح": 1,
    "منتزه": 1,
    "منتسب": 1,
    "منتشب": 1,
    "منتصت": 1,
    "منتفع": 1,
    "منتقلة": 1,
    "منتم": 1,
    "منتمى": 1,
    "منته": 1,
    "منتوج": 1,
    "منتول": 1,
    "منتھاه": 1,
    "منتھى": 1,
    "منثصور": 1,
    "منثلم": 1,
    "منج": 1,
    "منجاة": 1,
    "منجر": 1,
    "منجزم": 1,
    "منجلة": 1,
    "منجمد": 1,
    "منجنيق": 1,
    "منجو": 1,
    "منجور": 1,
    "منجى": 1,
    "منحاز": 1,
    "منحبس": 1,
    "منحة": 1,
    "منحت": 1,
    "منحر": 1,
    "منحف": 1,
    "منحنيات": 1,
    "منحور": 1,
    "منحوف": 1,
    "منحى": 1,
    "منخار": 1,
    "منخاس": 1,
    "منخر": 1,
    "منخس": 1,
    "منخل": 1,
    "منخوب": 1,
    "مندالة": 1,
    "مندب": 1,
    "مندرة": 1,
    "مندف": 1,
    "مندم": 1,
    "مندوبية": 1,
    "مندوحة": 1,
    "مندوف": 1,
    "مندى": 1,
    "مندية": 1,
    "مندھش": 1,
    "منذرة": 1,
    "منذھل": 1,
    "منزع": 1,
    "منزله": 1,
    "منزلي": 1,
    "منزلية": 1,
    "منزو": 1,
    "منزوعة": 1,
    "منزوف": 1,
    "منزول": 1,
    "منزولي": 1,
    "منزھة": 1,
    "منسأة": 1,
    "منسج": 1,
    "منسحق": 1,
    "منسر": 1,
    "منسرح": 1,
    "منسرق": 1,
    "منسف": 1,
    "منسفة": 1,
    "منسلخ": 1,
    "منسم": 1,
    "منسوبية": 1,
    "منسوجات": 1,
    "منسيات": 1,
    "منشأة": 1,
    "منشئ": 1,
    "منشع": 1,
    "منشفة": 1,
    "منشوبية": 1,
    "منشود": 1,
    "منشودة": 1,
    "منصبه": 1,
    "منصرف": 1,
    "منصرفھم": 1,
    "منصرم": 1,
    "منصور": 1,
    "منصون": 1,
    "منضح": 1,
    "منضحة": 1,
    "منضد": 1,
    "منضمة": 1,
    "منطاد": 1,
    "منطر": 1,
    "منطو": 1,
    "منطوفلة": 1,
    "منطيق": 1,
    "منظرة": 1,
    "منظورة": 1,
    "منظومة": 1,
    "منعاء": 1,
    "منعاة": 1,
    "منعام": 1,
    "منعة": 1,
    "منعطف": 1,
    "منعقد": 1,
    "منعقف": 1,
    "منعقفين": 1,
    "منعكسة": 1,
    "منعن": 1,
    "منعه": 1,
    "منعوت": 1,
    "منعى": 1,
    "منغاظ": 1,
    "منغص": 1,
    "منغنيس": 1,
    "منغوم": 1,
    "منفاخ": 1,
    "منفاق": 1,
    "منفجرة": 1,
    "منفحة": 1,
    "منفخ": 1,
    "منفرجة": 1,
    "منفسح": 1,
    "منفضة": 1,
    "منفى": 1,
    "منفيلا": 1,
    "منقاد": 1,
    "منقاش": 1,
    "منقبة": 1,
    "منقش": 1,
    "منقضة": 1,
    "منقل": 1,
    "منقلة": 1,
    "منقوط": 1,
    "منقوطة": 1,
    "منقوع": 1,
    "منقولات": 1,
    "منقولة": 1,
    "منكاش": 1,
    "منكبه": 1,
    "منكرات": 1,
    "منكس": 1,
    "منكش": 1,
    "منكص": 1,
    "منكود": 1,
    "منكوس": 1,
    "منلوج": 1,
    "منمر": 1,
    "منمنمة": 1,
    "منمول": 1,
    "منن": 1,
    "مننلته": 1,
    "منو": 1,
    "منوال": 1,
    "منوالك": 1,
    "منواله": 1,
    "منوب": 1,
    "منوط": 1,
    "منوفي": 1,
    "منول": 1,
    "منولوج": 1,
    "منولوجات": 1,
    "منومتر": 1,
    "منون": 1,
    "منى": 1,
    "منية": 1,
    "منيص": 1,
    "منيف": 1,
    "منھا": 1,
    "منھاج": 1,
    "منھج": 1,
    "منھك": 1,
    "منھل": 1,
    "منھم": 1,
    "منھمك": 1,
    "منھوم": 1,
    "منھي": 1,
    "موء": 1,
    "موئل": 1,
    "موائد": 1,
    "موات": 1,
    "مواثبة": 1,
    "مواثر": 1,
    "مواثق": 1,
    "مواج": 1,
    "مواجل": 1,
    "مواجن": 1,
    "مواجير": 1,
    "مواجھة": 1,
    "مواخير": 1,
    "موار": 1,
    "موارب": 1,
    "مواربة": 1,
    "موارنة": 1,
    "مواريث": 1,
    "مواز": 1,
    "موازنة": 1,
    "موازين": 1,
    "مواس": 1,
    "مواساة": 1,
    "مواش": 1,
    "مواشك": 1,
    "مواشير": 1,
    "مواصفة": 1,
    "مواصلة": 1,
    "مواض": 1,
    "مواضع": 1,
    "مواضعات": 1,
    "مواضيع": 1,
    "مواطأة": 1,
    "مواظب": 1,
    "مواظبة": 1,
    "مواعد": 1,
    "مواعدة": 1,
    "مواعز": 1,
    "مواعظ": 1,
    "مواعين": 1,
    "موافاة": 1,
    "مواقت": 1,
    "مواقف": 1,
    "مواكبة": 1,
    "مواكيك": 1,
    "موال": 1,
    "موالج": 1,
    "موالح": 1,
    "موالد": 1,
    "موالسة": 1,
    "مواليا": 1,
    "موالية": 1,
    "مواليد": 1,
    "موام": 1,
    "موان": 1,
    "موانئ": 1,
    "موانيء": 1,
    "مواويل": 1,
    "مواھب": 1,
    "موبر": 1,
    "موبق": 1,
    "موبقة": 1,
    "موبوء": 1,
    "موبيليات": 1,
    "موتان": 1,
    "موتة": 1,
    "موتور": 1,
    "موتوسيكل": 1,
    "موتى": 1,
    "موجد": 1,
    "موجدة": 1,
    "موجل": 1,
    "موجوب": 1,
    "موجوع": 1,
    "موح": 1,
    "موحى": 1,
    "موحيات": 1,
    "مودتھا": 1,
    "مودل": 1,
    "موده": 1,
    "موراتزم": 1,
    "موراني": 1,
    "مورث": 1,
    "مورد": 1,
    "موردة": 1,
    "مورفين": 1,
    "مورم": 1,
    "موروب": 1,
    "موروط": 1,
    "مورينة": 1,
    "موز": 1,
    "موسد": 1,
    "موسطردة": 1,
    "موسوس": 1,
    "موسوعة": 1,
    "موسوم": 1,
    "موسوي": 1,
    "موسيقار": 1,
    "موشح": 1,
    "موشحة": 1,
    "موشور": 1,
    "موصول": 1,
    "موصولة": 1,
    "موضعه": 1,
    "موضه": 1,
    "موضوعا": 1,
    "موضوعية": 1,
    "موطأ": 1,
    "موطد": 1,
    "موعودة": 1,
    "موعوك": 1,
    "موغل": 1,
    "موف": 1,
    "موفور": 1,
    "موق": 1,
    "موقت": 1,
    "موقتة": 1,
    "موقعة": 1,
    "موقعه": 1,
    "موقفا": 1,
    "موقفه": 1,
    "موقن": 1,
    "موقود": 1,
    "موقوذ": 1,
    "موقوف": 1,
    "موقيت": 1,
    "موكد": 1,
    "موكل": 1,
    "موكم": 1,
    "مولاة": 1,
    "مولاى": 1,
    "مولج": 1,
    "مولودا": 1,
    "مولوى": 1,
    "موم": 1,
    "موماء": 1,
    "موماة": 1,
    "مومس": 1,
    "مومسة": 1,
    "مومياء": 1,
    "مومية": 1,
    "مونة": 1,
    "مونق": 1,
    "مونيخ": 1,
    "موه": 1,
    "موھبة": 1,
    "موھن": 1,
    "موھوب": 1,
    "موھوم": 1,
    "ميؤوس": 1,
    "مياتم": 1,
    "مياثر": 1,
    "ميار": 1,
    "ميازيب": 1,
    "ميازيت": 1,
    "مياس": 1,
    "مياسر": 1,
    "مياسم": 1,
    "مياسير": 1,
    "مياط": 1,
    "ميامن": 1,
    "ميان": 1,
    "مياومة": 1,
    "ميتم": 1,
    "ميتوى": 1,
    "ميثرة": 1,
    "ميجار": 1,
    "ميح": 1,
    "ميحار": 1,
    "ميحوحة": 1,
    "ميداء": 1,
    "ميدة": 1,
    "ميدعة": 1,
    "ميدى": 1,
    "ميرآلاى": 1,
    "ميرة": 1,
    "ميرلواء": 1,
    "ميروق": 1,
    "ميرون": 1,
    "ميري": 1,
    "ميزاب": 1,
    "ميزانين": 1,
    "ميزة": 1,
    "ميسان": 1,
    "ميسر": 1,
    "ميسم": 1,
    "ميسور": 1,
    "ميضأة": 1,
    "ميضاءة": 1,
    "ميط": 1,
    "ميطان": 1,
    "ميطدة": 1,
    "ميع": 1,
    "ميعاده": 1,
    "ميعاس": 1,
    "ميعة": 1,
    "ميقان": 1,
    "ميقعة": 1,
    "ميكا": 1,
    "ميكاني": 1,
    "ميكروب": 1,
    "ميكروفون": 1,
    "ميكعة": 1,
    "ميلادي": 1,
    "ميلادية": 1,
    "ميمن": 1,
    "ميمنة": 1,
    "مينة": 1,
    "ميوعة": 1,
    "ميول": 1,
    "ميون": 1,
    "مھاب": 1,
    "مھابة": 1,
    "مھابط": 1,
    "مھابل": 1,
    "مھاة": 1,
    "مھاترة": 1,
    "مھاجر": 1,
    "مھاجرة": 1,
    "مھاجع": 1,
    "مھاجم": 1,
    "مھاجمة": 1,
    "مھاجنة": 1,
    "مھاجين": 1,
    "مھاد": 1,
    "مھادنة": 1,
    "مھار": 1,
    "مھارب": 1,
    "مھارة": 1,
    "مھارق": 1,
    "مھاريس": 1,
    "مھازل": 1,
    "مھازيل": 1,
    "مھالك": 1,
    "مھام": 1,
    "مھامز": 1,
    "مھاميز": 1,
    "مھان": 1,
    "مھانة": 1,
    "مھاو": 1,
    "مھاودة": 1,
    "مھايأة": 1,
    "مھايع": 1,
    "مھب": 1,
    "مھبط": 1,
    "مھبل": 1,
    "مھبلي": 1,
    "مھبوط": 1,
    "مھبول": 1,
    "مھتاج": 1,
    "مھتد": 1,
    "مھتر": 1,
    "مھترئ": 1,
    "مھتز": 1,
    "مھتم": 1,
    "مھتمات": 1,
    "مھج": 1,
    "مھجات": 1,
    "مھجة": 1,
    "مھجتھا": 1,
    "مھجر": 1,
    "مھجع": 1,
    "مھجور": 1,
    "مھد": 1,
    "مھدة": 1,
    "مھدد": 1,
    "مھدر": 1,
    "مھدل": 1,
    "مھدم": 1,
    "مھده": 1,
    "مھدود": 1,
    "مھدوم": 1,
    "مھدي": 1,
    "مھذب": 1,
    "مھر": 1,
    "مھرات": 1,
    "مھراس": 1,
    "مھراق": 1,
    "مھرب": 1,
    "مھرة": 1,
    "مھرج": 1,
    "مھرجان": 1,
    "مھردار": 1,
    "مھرق": 1,
    "مھرقان": 1,
    "مھرم": 1,
    "مھرمة": 1,
    "مھروش": 1,
    "مھرول": 1,
    "مھزأة": 1,
    "مھزة": 1,
    "مھزلة": 1,
    "مھزول": 1,
    "مھشم": 1,
    "مھضوم": 1,
    "مھفة": 1,
    "مھفوت": 1,
    "مھفوف": 1,
    "مھفھف": 1,
    "مھك": 1,
    "مھكة": 1,
    "مھل": 1,
    "مھلا": 1,
    "مھلبية": 1,
    "مھلة": 1,
    "مھلك": 1,
    "مھلكة": 1,
    "مھلل": 1,
    "مھلھل": 1,
    "مھم": 1,
    "مھما": 1,
    "مھمات": 1,
    "مھماز": 1,
    "مھمة": 1,
    "مھمز": 1,
    "مھمل": 1,
    "مھملة": 1,
    "مھموم": 1,
    "مھن": 1,
    "مھنئ": 1,
    "مھناء": 1,
    "مھنة": 1,
    "مھند": 1,
    "مھندس": 1,
    "مھندم": 1,
    "مھني": 1,
    "مھواة": 1,
    "مھوات": 1,
    "مھوب": 1,
    "مھود": 1,
    "مھور": 1,
    "مھوس": 1,
    "مھوش": 1,
    "مھول": 1,
    "مھووس": 1,
    "مھوى": 1,
    "مھيأ": 1,
    "مھيات": 1,
    "مھيب": 1,
    "مھيج": 1,
    "مھيض": 1,
    "مھيع": 1,
    "مھيلة": 1,
    "مھيمن": 1,
    "مھين": 1,
    "نأم": 1,
    "نأمة": 1,
    "نأى": 1,
    "نأي": 1,
    "نؤوم": 1,
    "نؤى": 1,
    "نئ": 1,
    "ناء": 1,
    "ناءوس": 1,
    "نائبة": 1,
    "نائحات": 1,
    "نائحة": 1,
    "نائرة": 1,
    "نائل": 1,
    "نابتة": 1,
    "نابذ": 1,
    "نابغ": 1,
    "نابغة": 1,
    "نابل": 1,
    "نابلس": 1,
    "نابلي": 1,
    "نابه": 1,
    "نات": 1,
    "ناتئة": 1,
    "ناجذ": 1,
    "ناجذيه": 1,
    "ناجز": 1,
    "ناجع": 1,
    "ناحل": 1,
    "ناخر": 1,
    "ناد": 1,
    "نادبة": 1,
    "نادرة": 1,
    "نادل": 1,
    "ناذر": 1,
    "نارجيل": 1,
    "نارجيلة": 1,
    "ناردين": 1,
    "نارنج": 1,
    "نارى": 1,
    "نازح": 1,
    "نازع": 1,
    "نازعة": 1,
    "نازلة": 1,
    "نازي": 1,
    "نازية": 1,
    "ناسخة": 1,
    "ناسف": 1,
    "ناسفة": 1,
    "ناسك": 1,
    "ناسوت": 1,
    "ناسور": 1,
    "ناشزة": 1,
    "ناشف": 1,
    "ناشفة": 1,
    "ناص": 1,
    "ناصب": 1,
    "ناصبه": 1,
    "ناصري": 1,
    "ناصع": 1,
    "ناصعة": 1,
    "ناصف": 1,
    "ناصل": 1,
    "ناصور": 1,
    "ناصيته": 1,
    "ناض": 1,
    "ناضب": 1,
    "ناضر": 1,
    "ناط": 1,
    "ناطح": 1,
    "ناطر": 1,
    "ناطقة": 1,
    "ناطور": 1,
    "ناطورة": 1,
    "ناظر": 1,
    "ناظريه": 1,
    "ناظم": 1,
    "ناعل": 1,
    "ناعور": 1,
    "ناعورة": 1,
    "ناعوظ": 1,
    "نافجة": 1,
    "نافد": 1,
    "نافدة": 1,
    "نافذا": 1,
    "نافعة": 1,
    "نافق": 1,
    "نافورة": 1,
    "ناقات": 1,
    "ناقلات": 1,
    "ناقلية": 1,
    "ناقم": 1,
    "ناك": 1,
    "ناكث": 1,
    "نال": 1,
    "ناله": 1,
    "نامة": 1,
    "ناموس": 1,
    "ناموسية": 1,
    "نامية": 1,
    "ناه": 1,
    "ناوأه": 1,
    "ناوله": 1,
    "ناولون": 1,
    "ناووس": 1,
    "ناي": 1,
    "ناھج": 1,
    "ناھد": 1,
    "ناھز": 1,
    "ناھض": 1,
    "ناھية": 1,
    "ناھيك": 1,
    "نب": 1,
    "نبء": 1,
    "نبأة": 1,
    "نبا": 1,
    "نبابيت": 1,
    "نباريس": 1,
    "نبال": 1,
    "نبالة": 1,
    "نباھة": 1,
    "نبت": 1,
    "نبذة": 1,
    "نبر": 1,
    "نبرات": 1,
    "نبراس": 1,
    "نبراسا": 1,
    "نبز": 1,
    "نبس": 1,
    "نبسة": 1,
    "نبش": 1,
    "نبضان": 1,
    "نبضة": 1,
    "نبضه": 1,
    "نبط": 1,
    "نبطي": 1,
    "نبع": 1,
    "نبعان": 1,
    "نبق": 1,
    "نبكة": 1,
    "نبل": 1,
    "نبه": 1,
    "نبو": 1,
    "نبوء": 1,
    "نبوءة": 1,
    "نبوة": 1,
    "نبوت": 1,
    "نبوض": 1,
    "نبوط": 1,
    "نبوع": 1,
    "نبوغ": 1,
    "نبيح": 1,
    "نبيش": 1,
    "نبھاء": 1,
    "نتء": 1,
    "نتأ": 1,
    "نتأة": 1,
    "نتائجه": 1,
    "نتج": 1,
    "نتحة": 1,
    "نتر": 1,
    "نتروجين": 1,
    "نتش": 1,
    "نتع": 1,
    "نتفة": 1,
    "نتوع": 1,
    "نتونة": 1,
    "نتيف": 1,
    "نثار": 1,
    "نثريات": 1,
    "نثرية": 1,
    "نجاء": 1,
    "نجاب": 1,
    "نجابة": 1,
    "نجاة": 1,
    "نجاح": 1,
    "نجاحا": 1,
    "نجاد": 1,
    "نجادة": 1,
    "نجار": 1,
    "نجارة": 1,
    "نجاري": 1,
    "نجاز": 1,
    "نجاسة": 1,
    "نجاشي": 1,
    "نجاف": 1,
    "نجام": 1,
    "نجاوى": 1,
    "نجباء": 1,
    "نجبة": 1,
    "نجح": 1,
    "نجدة": 1,
    "نجدي": 1,
    "نجذ": 1,
    "نجر": 1,
    "نجز": 1,
    "نجع": 1,
    "نجعة": 1,
    "نجف": 1,
    "نجفة": 1,
    "نجل": 1,
    "نجلاء": 1,
    "نجو": 1,
    "نجوة": 1,
    "نجوع": 1,
    "نجوما": 1,
    "نجي": 1,
    "نجيب": 1,
    "نجيح": 1,
    "نجيس": 1,
    "نجيع": 1,
    "نجيل": 1,
    "نجيمة": 1,
    "نحا": 1,
    "نحاة": 1,
    "نحاتة": 1,
    "نحارير": 1,
    "نحاسة": 1,
    "نحاف": 1,
    "نحال": 1,
    "نحالة": 1,
    "نحام": 1,
    "نحت": 1,
    "نحر": 1,
    "نحرير": 1,
    "نحفاء": 1,
    "نحل": 1,
    "نحلى": 1,
    "نحم": 1,
    "نحمان": 1,
    "نحنح": 1,
    "نحوا": 1,
    "نحور": 1,
    "نحوس": 1,
    "نحوسة": 1,
    "نحول": 1,
    "نحيب": 1,
    "نحير": 1,
    "نحيزة": 1,
    "نحيم": 1,
    "نخا": 1,
    "نخاريب": 1,
    "نخاسة": 1,
    "نخاشيش": 1,
    "نخاعة": 1,
    "نخالة": 1,
    "نخامة": 1,
    "نخبة": 1,
    "نخبه": 1,
    "نخرب": 1,
    "نخروب": 1,
    "نخز": 1,
    "نخس": 1,
    "نخشوش": 1,
    "نخع": 1,
    "نخم": 1,
    "نخو": 1,
    "نخوة": 1,
    "نخير": 1,
    "ند": 1,
    "ندا": 1,
    "ندابة": 1,
    "نداد": 1,
    "ندارة": 1,
    "نداف": 1,
    "ندام": 1,
    "ندامة": 1,
    "ندامى": 1,
    "نداوة": 1,
    "ندبة": 1,
    "ندح": 1,
    "ندحة": 1,
    "ندد": 1,
    "ندداء": 1,
    "ندر": 1,
    "ندرة": 1,
    "ندس": 1,
    "ندف": 1,
    "ندل": 1,
    "ندم": 1,
    "ندماء": 1,
    "ندمان": 1,
    "نده": 1,
    "ندو": 1,
    "ندوب": 1,
    "ندوة": 1,
    "ندود": 1,
    "ندور": 1,
    "ندورة": 1,
    "ندى": 1,
    "نديان": 1,
    "نديد": 1,
    "نديف": 1,
    "نذال": 1,
    "نذالة": 1,
    "نذلاء": 1,
    "نذور": 1,
    "نذورات": 1,
    "نذول": 1,
    "نذولة": 1,
    "نذيل": 1,
    "نذھب": 1,
    "نرابيج": 1,
    "نرابيش": 1,
    "نربيج": 1,
    "نربيش": 1,
    "نرجس": 1,
    "نرجع": 1,
    "نردين": 1,
    "نرفزة": 1,
    "نرنج": 1,
    "نروج": 1,
    "نرويج": 1,
    "نزا": 1,
    "نزازة": 1,
    "نزال": 1,
    "نزالة": 1,
    "نزاه": 1,
    "نزاھة": 1,
    "نزة": 1,
    "نزح": 1,
    "نزر": 1,
    "نزعة": 1,
    "نزعته": 1,
    "نزغة": 1,
    "نزفة": 1,
    "نزك": 1,
    "نزلاء": 1,
    "نزنز": 1,
    "نزه": 1,
    "نزو": 1,
    "نزوان": 1,
    "نزوة": 1,
    "نزوح": 1,
    "نزوز": 1,
    "نزوع": 1,
    "نزوق": 1,
    "نزول": 1,
    "نزولي": 1,
    "نزيح": 1,
    "نزير": 1,
    "نزيز": 1,
    "نزيع": 1,
    "نزيف": 1,
    "نزيل": 1,
    "نزھاء": 1,
    "نزھة": 1,
    "نسء": 1,
    "نسأ": 1,
    "نسائم": 1,
    "نسائيات": 1,
    "نساب": 1,
    "نساج": 1,
    "نساجة": 1,
    "نساخ": 1,
    "نساخة": 1,
    "نسارية": 1,
    "نساسيف": 1,
    "نساطرة": 1,
    "نساف": 1,
    "نسافة": 1,
    "نساك": 1,
    "نساكة": 1,
    "نسالة": 1,
    "نسام": 1,
    "نسانس": 1,
    "نسبية": 1,
    "نستقر": 1,
    "نسج": 1,
    "نسجه": 1,
    "نسخي": 1,
    "نسطوري": 1,
    "نسغ": 1,
    "نسف": 1,
    "نسقا": 1,
    "نسكي": 1,
    "نسل": 1,
    "نسم": 1,
    "نسمان": 1,
    "نسمة": 1,
    "نسو": 1,
    "نسوان": 1,
    "نسوج": 1,
    "نسور": 1,
    "نسورة": 1,
    "نسول": 1,
    "نسولة": 1,
    "نسيئة": 1,
    "نسيان": 1,
    "نسيب": 1,
    "نسيق": 1,
    "نسيلة": 1,
    "نسيم": 1,
    "نش": 1,
    "نشء": 1,
    "نشأته": 1,
    "نشؤ": 1,
    "نشائد": 1,
    "نشاب": 1,
    "نشادر": 1,
    "نشار": 1,
    "نشاش": 1,
    "نشاشيب": 1,
    "نشافة": 1,
    "نشال": 1,
    "نشان": 1,
    "نشانجي": 1,
    "نشاوى": 1,
    "نشب": 1,
    "نشبة": 1,
    "نشج": 1,
    "نشد": 1,
    "نشدان": 1,
    "نشدة": 1,
    "نشز": 1,
    "نشطة": 1,
    "نشع": 1,
    "نشق": 1,
    "نشل": 1,
    "نشن": 1,
    "نشنجي": 1,
    "نشنش": 1,
    "نشنكاه": 1,
    "نشو": 1,
    "نشوءا": 1,
    "نشوان": 1,
    "نشوب": 1,
    "نشور": 1,
    "نشوز": 1,
    "نشوق": 1,
    "نشوى": 1,
    "نشويات": 1,
    "نشي": 1,
    "نشيج": 1,
    "نشيد": 1,
    "نشيش": 1,
    "نصا": 1,
    "نصائح": 1,
    "نصابه": 1,
    "نصابھا": 1,
    "نصاح": 1,
    "نصاحة": 1,
    "نصار": 1,
    "نصاعة": 1,
    "نصاف": 1,
    "نصال": 1,
    "نصبة": 1,
    "نصبوا": 1,
    "نصة": 1,
    "نصت": 1,
    "نصحه": 1,
    "نصراء": 1,
    "نصراني": 1,
    "نصرانية": 1,
    "نصص": 1,
    "نصع": 1,
    "نصفة": 1,
    "نصفي": 1,
    "نصل": 1,
    "نصمة": 1,
    "نصوح": 1,
    "نصور": 1,
    "نصوع": 1,
    "نصول": 1,
    "نصيبه": 1,
    "نصيح": 1,
    "نصيحة": 1,
    "نصيع": 1,
    "نصيف": 1,
    "نض": 1,
    "نضا": 1,
    "نضائد": 1,
    "نضاحة": 1,
    "نضار": 1,
    "نضال": 1,
    "نضالي": 1,
    "نضب": 1,
    "نضح": 1,
    "نضد": 1,
    "نضر": 1,
    "نضف": 1,
    "نضل": 1,
    "نضناض": 1,
    "نضو": 1,
    "نضوب": 1,
    "نضور": 1,
    "نضيج": 1,
    "نضيدة": 1,
    "نضير": 1,
    "نضيض": 1,
    "نضيف": 1,
    "نط": 1,
    "نطا": 1,
    "نطاح": 1,
    "نطار": 1,
    "نطارة": 1,
    "نطاسي": 1,
    "نطافة": 1,
    "نطاقا": 1,
    "نطة": 1,
    "نطحة": 1,
    "نطر": 1,
    "نطراء": 1,
    "نطرة": 1,
    "نطرون": 1,
    "نطس": 1,
    "نطع": 1,
    "نطف": 1,
    "نطفان": 1,
    "نطل": 1,
    "نطنط": 1,
    "نطوع": 1,
    "نطوق": 1,
    "نطول": 1,
    "نطيح": 1,
    "نظائر": 1,
    "نظار": 1,
    "نظاراتي": 1,
    "نظارة": 1,
    "نظارته": 1,
    "نظاف": 1,
    "نظافة": 1,
    "نظامية": 1,
    "نظراء": 1,
    "نظراؤه": 1,
    "نظرات": 1,
    "نظره": 1,
    "نظفاء": 1,
    "نظلي": 1,
    "نظما": 1,
    "نظيرة": 1,
    "نعائم": 1,
    "نعاب": 1,
    "نعاج": 1,
    "نعار": 1,
    "نعارة": 1,
    "نعاس": 1,
    "نعام": 1,
    "نعامته": 1,
    "نعب": 1,
    "نعت": 1,
    "نعرة": 1,
    "نعسة": 1,
    "نعشة": 1,
    "نعظ": 1,
    "نعق": 1,
    "نعمان": 1,
    "نعمى": 1,
    "نعناع": 1,
    "نعناعي": 1,
    "نعنع": 1,
    "نعوت": 1,
    "نعوظ": 1,
    "نعومة": 1,
    "نعى": 1,
    "نعي": 1,
    "نعيان": 1,
    "نعيب": 1,
    "نعية": 1,
    "نعير": 1,
    "نعيق": 1,
    "نغا": 1,
    "نغاش": 1,
    "نغاشة": 1,
    "نغاشي": 1,
    "نغبة": 1,
    "نغبشة": 1,
    "نغز": 1,
    "نغش": 1,
    "نغشان": 1,
    "نغشة": 1,
    "نغص": 1,
    "نغم": 1,
    "نغو": 1,
    "نغولة": 1,
    "نغى": 1,
    "نغيل": 1,
    "نف": 1,
    "نفا": 1,
    "نفاء": 1,
    "نفائس": 1,
    "نفائض": 1,
    "نفاة": 1,
    "نفاثة": 1,
    "نفاثي": 1,
    "نفاج": 1,
    "نفاح": 1,
    "نفاخ": 1,
    "نفاخة": 1,
    "نفار": 1,
    "نفاس": 1,
    "نفاسه": 1,
    "نفاسية": 1,
    "نفاش": 1,
    "نفاض": 1,
    "نفاضة": 1,
    "نفاع": 1,
    "نفاف": 1,
    "نفاق": 1,
    "نفال": 1,
    "نفانخ": 1,
    "نفانف": 1,
    "نفاوة": 1,
    "نفايات": 1,
    "نفاية": 1,
    "نفة": 1,
    "نفث": 1,
    "نفثات": 1,
    "نفثة": 1,
    "نفج": 1,
    "نفجان": 1,
    "نفح": 1,
    "نفحان": 1,
    "نفد": 1,
    "نفدة": 1,
    "نفرة": 1,
    "نفساء": 1,
    "نفسانية": 1,
    "نفسة": 1,
    "نفسى": 1,
    "نفسھا": 1,
    "نفش": 1,
    "نفض": 1,
    "نفضة": 1,
    "نفضية": 1,
    "نفطة": 1,
    "نفطي": 1,
    "نفل": 1,
    "نفنف": 1,
    "نفنوف": 1,
    "نفو": 1,
    "نفوج": 1,
    "نفور": 1,
    "نفوس": 1,
    "نفوسنا": 1,
    "نفوض": 1,
    "نفوع": 1,
    "نفوق": 1,
    "نفول": 1,
    "نفى": 1,
    "نفير": 1,
    "نفيسة": 1,
    "نفيضة": 1,
    "نفيي": 1,
    "نقاء": 1,
    "نقائب": 1,
    "نقائص": 1,
    "نقائض": 1,
    "نقاب": 1,
    "نقابية": 1,
    "نقاد": 1,
    "نقادة": 1,
    "نقارية": 1,
    "نقاز": 1,
    "نقاشة": 1,
    "نقاطة": 1,
    "نقاع": 1,
    "نقاعيات": 1,
    "نقاق": 1,
    "نقاقة": 1,
    "نقاوة": 1,
    "نقاية": 1,
    "نقاھة": 1,
    "نقباء": 1,
    "نقدا": 1,
    "نقدة": 1,
    "نقدية": 1,
    "نقذ": 1,
    "نقرة": 1,
    "نقرزان": 1,
    "نقرس": 1,
    "نقريس": 1,
    "نقز": 1,
    "نقزان": 1,
    "نقزة": 1,
    "نقصان": 1,
    "نقض": 1,
    "نقضه": 1,
    "نقف": 1,
    "نقلا": 1,
    "نقلة": 1,
    "نقليات": 1,
    "نقلية": 1,
    "نقم": 1,
    "نقمات": 1,
    "نقمة": 1,
    "نقنق": 1,
    "نقه": 1,
    "نقو": 1,
    "نقودا": 1,
    "نقوش": 1,
    "نقوط": 1,
    "نقوع": 1,
    "نقوه": 1,
    "نقيب": 1,
    "نقيبة": 1,
    "نقير": 1,
    "نقيصة": 1,
    "نقيضة": 1,
    "نقيضه": 1,
    "نقيطة": 1,
    "نقيع": 1,
    "نقيق": 1,
    "نقيل": 1,
    "نقھة": 1,
    "نكات": 1,
    "نكاح": 1,
    "نكاف": 1,
    "نكاية": 1,
    "نكت": 1,
    "نكتة": 1,
    "نكد": 1,
    "نكراء": 1,
    "نكرة": 1,
    "نكز": 1,
    "نكس": 1,
    "نكسة": 1,
    "نكش": 1,
    "نكف": 1,
    "نكفة": 1,
    "نكفي": 1,
    "نكل": 1,
    "نكلة": 1,
    "نكه": 1,
    "نكور": 1,
    "نكوص": 1,
    "نكول": 1,
    "نكى": 1,
    "نكھة": 1,
    "نماء": 1,
    "نمائم": 1,
    "نماة": 1,
    "نمارة": 1,
    "نمارسة": 1,
    "نمارق": 1,
    "نماط": 1,
    "نمال": 1,
    "نمام": 1,
    "نمة": 1,
    "نمر": 1,
    "نمراء": 1,
    "نمرة": 1,
    "نمرسي": 1,
    "نمرق": 1,
    "نمرقة": 1,
    "نمس": 1,
    "نمسا": 1,
    "نمش": 1,
    "نمشاء": 1,
    "نمل": 1,
    "نملية": 1,
    "نمنم": 1,
    "نمنمة": 1,
    "نموس": 1,
    "نمي": 1,
    "نمية": 1,
    "نميمة": 1,
    "نوء": 1,
    "نوآن": 1,
    "نوائب": 1,
    "نوائح": 1,
    "نوابغ": 1,
    "نواتئ": 1,
    "نواتي": 1,
    "نواجذ": 1,
    "نواجذه": 1,
    "نواح": 1,
    "نواحة": 1,
    "نواد": 1,
    "نوادب": 1,
    "نوادر": 1,
    "نوار": 1,
    "نوارج": 1,
    "نوازع": 1,
    "نوازل": 1,
    "نواس": 1,
    "نواسر": 1,
    "نواشز": 1,
    "نواص": 1,
    "نواصب": 1,
    "نواصي": 1,
    "نواصير": 1,
    "نواصيه": 1,
    "نواطح": 1,
    "نواطر": 1,
    "نواطير": 1,
    "نواظر": 1,
    "نواظم": 1,
    "نواعر": 1,
    "نوافج": 1,
    "نوافر": 1,
    "نوافس": 1,
    "نوافل": 1,
    "نوافير": 1,
    "نوال": 1,
    "نوالك": 1,
    "نوالين": 1,
    "نوام": 1,
    "نواميس": 1,
    "نواه": 1,
    "نواوير": 1,
    "نواويس": 1,
    "نوايا": 1,
    "نوبتجي": 1,
    "نوبته": 1,
    "نوبي": 1,
    "نوة": 1,
    "نوتي": 1,
    "نوتية": 1,
    "نوخ": 1,
    "نود": 1,
    "نودان": 1,
    "نوراني": 1,
    "نورانية": 1,
    "نورج": 1,
    "نورز": 1,
    "نورستانيا": 1,
    "نوروز": 1,
    "نوري": 1,
    "نوسان": 1,
    "نوسر": 1,
    "نوش": 1,
    "نوشادر": 1,
    "نوشة": 1,
    "نوص": 1,
    "نوط": 1,
    "نوعه": 1,
    "نوفرة": 1,
    "نوفمبر": 1,
    "نوق": 1,
    "نوكيل": 1,
    "نولون": 1,
    "نومة": 1,
    "نومه": 1,
    "نومي": 1,
    "نون": 1,
    "نونة": 1,
    "نوني": 1,
    "نوه": 1,
    "نووية": 1,
    "نوى": 1,
    "نويات": 1,
    "نيء": 1,
    "نيابة": 1,
    "نيابى": 1,
    "نيابي": 1,
    "نيابية": 1,
    "نياح": 1,
    "نياحة": 1,
    "نياشين": 1,
    "نياط": 1,
    "نيافة": 1,
    "نياق": 1,
    "نيام": 1,
    "نيب": 1,
    "نية": 1,
    "نيتروجين": 1,
    "نيته": 1,
    "نيرة": 1,
    "نيروز": 1,
    "نيزيلاندا": 1,
    "نيشان": 1,
    "نيص": 1,
    "نيط": 1,
    "نيق": 1,
    "نيلة": 1,
    "نيلج": 1,
    "نيلوفر": 1,
    "نيلون": 1,
    "نينان": 1,
    "نينة": 1,
    "نيوء": 1,
    "نيوءة": 1,
    "نيورالجيا": 1,
    "نيوني": 1,
    "نھا": 1,
    "نھاء": 1,
    "نھائي": 1,
    "نھائيا": 1,
    "نھائية": 1,
    "نھاب": 1,
    "نھار": 1,
    "نھارا": 1,
    "نھاري": 1,
    "نھاريات": 1,
    "نھاز": 1,
    "نھاش": 1,
    "نھاق": 1,
    "نھاكة": 1,
    "نھامة": 1,
    "نھاية": 1,
    "نھب": 1,
    "نھبا": 1,
    "نھبة": 1,
    "نھبرة": 1,
    "نھبى": 1,
    "نھتز": 1,
    "نھج": 1,
    "نھجه": 1,
    "نھد": 1,
    "نھر": 1,
    "نھري": 1,
    "نھرية": 1,
    "نھز": 1,
    "نھزة": 1,
    "نھش": 1,
    "نھض": 1,
    "نھضة": 1,
    "نھضت": 1,
    "نھق": 1,
    "نھك": 1,
    "نھكة": 1,
    "نھل": 1,
    "نھلة": 1,
    "نھم": 1,
    "نھمة": 1,
    "نھنه": 1,
    "نھو": 1,
    "نھوج": 1,
    "نھود": 1,
    "نھور": 1,
    "نھوض": 1,
    "نھى": 1,
    "نھي": 1,
    "نھية": 1,
    "نھيج": 1,
    "نھير": 1,
    "نھيق": 1,
    "نھيم": 1,
    "نھيي": 1,
    "وآخرا": 1,
    "وآونة": 1,
    "وآھات": 1,
    "وأد": 1,
    "وأر": 1,
    "وأشكالھم": 1,
    "وأم": 1,
    "وئام": 1,
    "وئية": 1,
    "وئيد": 1,
    "وئيدا": 1,
    "وابلا": 1,
    "وابور": 1,
    "وات": 1,
    "واتتني": 1,
    "واجبات": 1,
    "واجبه": 1,
    "واجد": 1,
    "واجس": 1,
    "واجف": 1,
    "واجم": 1,
    "واجھة": 1,
    "واح": 1,
    "واحة": 1,
    "واحده": 1,
    "واحرق": 1,
    "واخرى": 1,
    "واخز": 1,
    "واخص": 1,
    "واخيرا": 1,
    "وادبارا": 1,
    "وادك": 1,
    "وادى": 1,
    "واراه": 1,
    "واربعين": 1,
    "وارث": 1,
    "واردات": 1,
    "وارسو": 1,
    "وارش": 1,
    "وارف": 1,
    "وارق": 1,
    "وازبد": 1,
    "وازع": 1,
    "واستمرارا": 1,
    "واسطة": 1,
    "واسلات": 1,
    "واسى": 1,
    "واش": 1,
    "واشنطون": 1,
    "واشون": 1,
    "واضرابه": 1,
    "واطراف": 1,
    "واع": 1,
    "واعظ": 1,
    "واغل": 1,
    "واف": 1,
    "وافاه": 1,
    "وافد": 1,
    "وافدة": 1,
    "وافرادا": 1,
    "واقعا": 1,
    "واقعة": 1,
    "واقعده": 1,
    "واقعدھا": 1,
    "واقفا": 1,
    "واقل": 1,
    "واقواق": 1,
    "واكل": 1,
    "والآجل": 1,
    "والأعم": 1,
    "والأعياد": 1,
    "والأعيان": 1,
    "والأنجد": 1,
    "والإبرام": 1,
    "والإخلاص": 1,
    "والإدارة": 1,
    "والإرادة": 1,
    "والإساءة": 1,
    "والإضافة": 1,
    "والإعارة": 1,
    "والا": 1,
    "والابرام": 1,
    "والاحسان": 1,
    "والاحمر": 1,
    "والاخرى": 1,
    "والاستعداد": 1,
    "والامر": 1,
    "والبأساء": 1,
    "والترقي": 1,
    "والتسويغ": 1,
    "والتعليم": 1,
    "والتنباك": 1,
    "والتى": 1,
    "والثبور": 1,
    "والجمال": 1,
    "والجوھر": 1,
    "والحالة": 1,
    "والحديد": 1,
    "والحرف": 1,
    "والخاص": 1,
    "والخلاصة": 1,
    "والدانى": 1,
    "والدانية": 1,
    "والدفع": 1,
    "والذراع": 1,
    "والربط": 1,
    "والرخيص": 1,
    "والرم": 1,
    "والزراعة": 1,
    "والسلوك": 1,
    "والشكوى": 1,
    "والصنائع": 1,
    "والضحايا": 1,
    "والضرع": 1,
    "والطاعة": 1,
    "والطلب": 1,
    "والطول": 1,
    "والعام": 1,
    "والعامة": 1,
    "والعقد": 1,
    "والعكس": 1,
    "والغالب": 1,
    "والغلب": 1,
    "والفر": 1,
    "والفضية": 1,
    "والفعل": 1,
    "والفينة": 1,
    "والقبول": 1,
    "والقدرة": 1,
    "والقروية": 1,
    "والكبراء": 1,
    "والكليات": 1,
    "والكنس": 1,
    "والكيل": 1,
    "واللأواء": 1,
    "واللعب": 1,
    "والمبنى": 1,
    "والمتأخرون": 1,
    "والمدر": 1,
    "والمسبب": 1,
    "والمشرقين": 1,
    "والمشي": 1,
    "والمعاد": 1,
    "والمعلول": 1,
    "والمعنويات": 1,
    "والمغربان": 1,
    "والمفسدة": 1,
    "والمكاييل": 1,
    "والملاحظ": 1,
    "والنفس": 1,
    "والنفيس": 1,
    "والنقل": 1,
    "والنمو": 1,
    "والنواھي": 1,
    "والنھي": 1,
    "واله": 1,
    "والوبر": 1,
    "والوضيع": 1,
    "والى": 1,
    "واليابس": 1,
    "واليكھا": 1,
    "واليه": 1,
    "والھيلمان": 1,
    "واما": 1,
    "وان": 1,
    "وانتم": 1,
    "وانفه": 1,
    "وانى": 1,
    "واه": 1,
    "واو": 1,
    "واياب": 1,
    "وايابا": 1,
    "واياك": 1,
    "واياه": 1,
    "واھا": 1,
    "واھب": 1,
    "واھمة": 1,
    "واھن": 1,
    "وبأ": 1,
    "وبؤ": 1,
    "وبئ": 1,
    "وباء": 1,
    "وباءة": 1,
    "وبار": 1,
    "وبارة": 1,
    "وبالة": 1,
    "وبجره": 1,
    "وبحثا": 1,
    "وبحرا": 1,
    "وبحرية": 1,
    "وبدءا": 1,
    "وبر": 1,
    "وبراء": 1,
    "وبرح": 1,
    "وبش": 1,
    "وبق": 1,
    "وبل": 1,
    "وبور": 1,
    "وبوق": 1,
    "وبول": 1,
    "وبيء": 1,
    "وبيل": 1,
    "وبينھم": 1,
    "وتائر": 1,
    "وتد": 1,
    "وترا": 1,
    "وترث": 1,
    "وترحاله": 1,
    "وتسلسل": 1,
    "وتن": 1,
    "وتى": 1,
    "وتيرة": 1,
    "وثء": 1,
    "وثأ": 1,
    "وثئ": 1,
    "وثاءة": 1,
    "وثاب": 1,
    "وثار": 1,
    "وثارة": 1,
    "وثاقة": 1,
    "وثب": 1,
    "وثبا": 1,
    "وثبان": 1,
    "وثر": 1,
    "وثق": 1,
    "وثقي": 1,
    "وثل": 1,
    "وثن": 1,
    "وثنية": 1,
    "وثوء": 1,
    "وثوب": 1,
    "وثيب": 1,
    "وثير": 1,
    "وثيل": 1,
    "وجائب": 1,
    "وجار": 1,
    "وجازة": 1,
    "وجاع": 1,
    "وجاق": 1,
    "وجال": 1,
    "وجاھة": 1,
    "وجاھي": 1,
    "وجب": 1,
    "وجبان": 1,
    "وجدان": 1,
    "وجداني": 1,
    "وجدانية": 1,
    "وجر": 1,
    "وجرة": 1,
    "وجز": 1,
    "وجس": 1,
    "وجسان": 1,
    "وجف": 1,
    "وجق": 1,
    "وجقات": 1,
    "وجل": 1,
    "وجلاء": 1,
    "وجم": 1,
    "وجملة": 1,
    "وجوب": 1,
    "وجوز": 1,
    "وجوف": 1,
    "وجوم": 1,
    "وجوية": 1,
    "وجيب": 1,
    "وجيزة": 1,
    "وجيع": 1,
    "وجيعا": 1,
    "وجيف": 1,
    "وجيه": 1,
    "وجيھة": 1,
    "وجھا": 1,
    "وجھاء": 1,
    "وجھة": 1,
    "وجھته": 1,
    "وجھه": 1,
    "وجھي": 1,
    "وجھين": 1,
    "وجھھا": 1,
    "وحام": 1,
    "وحامى": 1,
    "وحدان": 1,
    "وحداني": 1,
    "وحدانية": 1,
    "وحدب": 1,
    "وحدھا": 1,
    "وحشان": 1,
    "وحشة": 1,
    "وحف": 1,
    "وحمى": 1,
    "وحوح": 1,
    "وحوش": 1,
    "وحى": 1,
    "وخارجية": 1,
    "وخاز": 1,
    "وخامة": 1,
    "وخزان": 1,
    "وخصوم": 1,
    "وخط": 1,
    "وخلافه": 1,
    "وخم": 1,
    "وخى": 1,
    "وخي": 1,
    "وخيم": 1,
    "ودائع": 1,
    "ودائق": 1,
    "وداج": 1,
    "وداد": 1,
    "ودادي": 1,
    "ودب": 1,
    "ودبير": 1,
    "ودج": 1,
    "وددت": 1,
    "ودر": 1,
    "ودركات": 1,
    "ودعه": 1,
    "ودقة": 1,
    "ودك": 1,
    "ودما": 1,
    "ودى": 1,
    "وديان": 1,
    "وديد": 1,
    "وديعة": 1,
    "وديقة": 1,
    "وديك": 1,
    "ودھا": 1,
    "وذراعا": 1,
    "وذھاب": 1,
    "وذھابا": 1,
    "وذھوب": 1,
    "وراءھا": 1,
    "وراب": 1,
    "وراث": 1,
    "وراثة": 1,
    "وراثية": 1,
    "وراد": 1,
    "وراط": 1,
    "وراعة": 1,
    "وراق": 1,
    "وراقة": 1,
    "ورثاء": 1,
    "وردان": 1,
    "ورطة": 1,
    "ورع": 1,
    "ورف": 1,
    "ورك": 1,
    "ورل": 1,
    "ورلان": 1,
    "ورن": 1,
    "ورنش": 1,
    "ورنيش": 1,
    "ورواحه": 1,
    "وروار": 1,
    "وروحا": 1,
    "وروحاته": 1,
    "ورود": 1,
    "وروط": 1,
    "وروف": 1,
    "ورى": 1,
    "وريد": 1,
    "وريف": 1,
    "ورھاء": 1,
    "ورھبا": 1,
    "وز": 1,
    "وزائع": 1,
    "وزارية": 1,
    "وزال": 1,
    "وزان": 1,
    "وزب": 1,
    "وزراء": 1,
    "وزرة": 1,
    "وزره": 1,
    "وزنة": 1,
    "وزني": 1,
    "وزوب": 1,
    "وزى": 1,
    "وزير": 1,
    "وزيعة": 1,
    "وسائد": 1,
    "وسائط": 1,
    "وساد": 1,
    "وساطته": 1,
    "وساع": 1,
    "وساعة": 1,
    "وساوس": 1,
    "وسد": 1,
    "وسداد": 1,
    "وسده": 1,
    "وسطاني": 1,
    "وسطنا": 1,
    "وسطى": 1,
    "وسطي": 1,
    "وسطية": 1,
    "وسعه": 1,
    "وسعي": 1,
    "وسكناته": 1,
    "وسكي": 1,
    "وسل": 1,
    "وسلم": 1,
    "وسم": 1,
    "وسماء": 1,
    "وسمه": 1,
    "وسن": 1,
    "وسنان": 1,
    "وسنى": 1,
    "وسواد": 1,
    "وسواس": 1,
    "وسوس": 1,
    "وسوم": 1,
    "وسى": 1,
    "وسيطة": 1,
    "وسيع": 1,
    "وسيلة": 1,
    "وسھلا": 1,
    "وشأن": 1,
    "وشأنه": 1,
    "وشأني": 1,
    "وشاء": 1,
    "وشائج": 1,
    "وشائح": 1,
    "وشائع": 1,
    "وشاة": 1,
    "وشاحة": 1,
    "وشارق": 1,
    "وشاكة": 1,
    "وشام": 1,
    "وشاية": 1,
    "وشب": 1,
    "وشبھھم": 1,
    "وشتما": 1,
    "وشح": 1,
    "وشحمه": 1,
    "وشر": 1,
    "وشرب": 1,
    "وشرق": 1,
    "وشع": 1,
    "وشق": 1,
    "وشل": 1,
    "وشم": 1,
    "وشمالا": 1,
    "وشنة": 1,
    "وشوش": 1,
    "وشوشة": 1,
    "وشوم": 1,
    "وشى": 1,
    "وشي": 1,
    "وشيج": 1,
    "وشيجة": 1,
    "وشيع": 1,
    "وشيعة": 1,
    "وشيمة": 1,
    "وصائف": 1,
    "وصاة": 1,
    "وصادرات": 1,
    "وصاف": 1,
    "وصال": 1,
    "وصاوص": 1,
    "وصايا": 1,
    "وصب": 1,
    "وصفاء": 1,
    "وصلا": 1,
    "وصلني": 1,
    "وصله": 1,
    "وصلية": 1,
    "وصم": 1,
    "وصو": 1,
    "وصواص": 1,
    "وصوب": 1,
    "وصوص": 1,
    "وصوصة": 1,
    "وصول": 1,
    "وصولات": 1,
    "وصولي": 1,
    "وصيد": 1,
    "وصيف": 1,
    "وصيل": 1,
    "وضؤ": 1,
    "وضاء": 1,
    "وضاءة": 1,
    "وضائع": 1,
    "وضاح": 1,
    "وضاعة": 1,
    "وضب": 1,
    "وضحاھا": 1,
    "وضر": 1,
    "وضعاء": 1,
    "وضعة": 1,
    "وضعي": 1,
    "وضعية": 1,
    "وضم": 1,
    "وضمنا": 1,
    "وضوء": 1,
    "وضوع": 1,
    "وضيء": 1,
    "وضيعة": 1,
    "وطء": 1,
    "وطأ": 1,
    "وطأة": 1,
    "وطئ": 1,
    "وطاء": 1,
    "وطاب": 1,
    "وطاعة": 1,
    "وطاوط": 1,
    "وطاويط": 1,
    "وطب": 1,
    "وطد": 1,
    "وطر": 1,
    "وطره": 1,
    "وطش": 1,
    "وطف": 1,
    "وطفاء": 1,
    "وطنه": 1,
    "وطنية": 1,
    "وطواط": 1,
    "وطى": 1,
    "وطيد": 1,
    "وطيس": 1,
    "وظب": 1,
    "وظف": 1,
    "وظلال": 1,
    "وظوب": 1,
    "وظيفته": 1,
    "وعارة": 1,
    "وعاظ": 1,
    "وعب": 1,
    "وعث": 1,
    "وعثاء": 1,
    "وعجن": 1,
    "وعدت": 1,
    "وعرة": 1,
    "وعرضھا": 1,
    "وعز": 1,
    "وعس": 1,
    "وعشرون": 1,
    "وعطاء": 1,
    "وعظ": 1,
    "وعظة": 1,
    "وعق": 1,
    "وعك": 1,
    "وعكة": 1,
    "وعملا": 1,
    "وعود": 1,
    "وعودا": 1,
    "وعور": 1,
    "وعوع": 1,
    "وعى": 1,
    "وعي": 1,
    "وعيدي": 1,
    "وعير": 1,
    "وعيه": 1,
    "وغاد": 1,
    "وغال": 1,
    "وغدان": 1,
    "وغر": 1,
    "وغل": 1,
    "وغلان": 1,
    "وغول": 1,
    "وغي": 1,
    "وفاد": 1,
    "وفادة": 1,
    "وفادته": 1,
    "وفارة": 1,
    "وفاض": 1,
    "وفاق": 1,
    "وفد": 1,
    "وفدي": 1,
    "وفرخ": 1,
    "وفرعا": 1,
    "وفز": 1,
    "وفصه": 1,
    "وفض": 1,
    "وفقة": 1,
    "وفود": 1,
    "وفور": 1,
    "وفيات": 1,
    "وفيعة": 1,
    "وقاء": 1,
    "وقائع": 1,
    "وقاح": 1,
    "وقاحة": 1,
    "وقاد": 1,
    "وقارة": 1,
    "وقاع": 1,
    "وقاعة": 1,
    "وقاف": 1,
    "وقالبا": 1,
    "وقاية": 1,
    "وقبة": 1,
    "وقة": 1,
    "وقتئذ": 1,
    "وقتذاك": 1,
    "وقتما": 1,
    "وقتيا": 1,
    "وقدة": 1,
    "وقدرا": 1,
    "وقذ": 1,
    "وقرة": 1,
    "وقرت": 1,
    "وقس": 1,
    "وقطران": 1,
    "وقعة": 1,
    "وقعه": 1,
    "وقعوا": 1,
    "وقفا": 1,
    "وقفزة": 1,
    "وقفه": 1,
    "وقفي": 1,
    "وقفية": 1,
    "وقق": 1,
    "وقواق": 1,
    "وقوحة": 1,
    "وقوع": 1,
    "وقوف": 1,
    "وقوق": 1,
    "وقولا": 1,
    "وقى": 1,
    "وقي": 1,
    "وقية": 1,
    "وقيح": 1,
    "وقيد": 1,
    "وقيذ": 1,
    "وقيعة": 1,
    "وكأ": 1,
    "وكاء": 1,
    "وكاعة": 1,
    "وكب": 1,
    "وكبان": 1,
    "وكبيرة": 1,
    "وكد": 1,
    "وكر": 1,
    "وكرامة": 1,
    "وكرة": 1,
    "وكز": 1,
    "وكس": 1,
    "وكسور": 1,
    "وكع": 1,
    "وكفان": 1,
    "وكمية": 1,
    "وكنة": 1,
    "وكوب": 1,
    "وكور": 1,
    "وكول": 1,
    "وكون": 1,
    "وكى": 1,
    "وكيد": 1,
    "ولآخر": 1,
    "ولاء": 1,
    "ولائد": 1,
    "ولائم": 1,
    "ولائي": 1,
    "ولاة": 1,
    "ولاعة": 1,
    "ولاه": 1,
    "ولاول": 1,
    "ولج": 1,
    "ولدة": 1,
    "ولدنة": 1,
    "ولدھا": 1,
    "ولس": 1,
    "ولسان": 1,
    "ولط": 1,
    "ولع": 1,
    "ولغ": 1,
    "ولنھب": 1,
    "ولوج": 1,
    "ولودية": 1,
    "ولوع": 1,
    "ولوغ": 1,
    "ولول": 1,
    "ولولة": 1,
    "ولية": 1,
    "وليد": 1,
    "وليدة": 1,
    "وليلا": 1,
    "وليلة": 1,
    "ولھان": 1,
    "ومآب": 1,
    "ومأ": 1,
    "وماديا": 1,
    "ومبادئھم": 1,
    "ومتكلم": 1,
    "ومد": 1,
    "ومراح": 1,
    "ومرج": 1,
    "ومرح": 1,
    "ومروج": 1,
    "ومس": 1,
    "ومستجمعه": 1,
    "ومسمع": 1,
    "ومصر": 1,
    "ومضان": 1,
    "ومعنى": 1,
    "ومغاربھا": 1,
    "ومغربھا": 1,
    "ومق": 1,
    "ومقبلا": 1,
    "ومنھم": 1,
    "ون": 1,
    "وناء": 1,
    "ونابلھم": 1,
    "وناعر": 1,
    "وندر": 1,
    "ونش": 1,
    "ونشر": 1,
    "ونصيب": 1,
    "ونعمت": 1,
    "ونكير": 1,
    "ونى": 1,
    "ونيابة": 1,
    "ونيف": 1,
    "ووحدانا": 1,
    "ووزير": 1,
    "ووعد": 1,
    "ووھمية": 1,
    "ويؤخر": 1,
    "وياءه": 1,
    "ويبة": 1,
    "ويبدأه": 1,
    "ويح": 1,
    "ويحا": 1,
    "ويحك": 1,
    "ويحه": 1,
    "ويركو": 1,
    "ويسرة": 1,
    "ويسكى": 1,
    "ويغاديھا": 1,
    "ويفعله": 1,
    "ويقيم": 1,
    "ويك": 1,
    "ويكة": 1,
    "ويله": 1,
    "ويماسيه": 1,
    "ويمنا": 1,
    "ويوجد": 1,
    "وھابي": 1,
    "وھاة": 1,
    "وھاج": 1,
    "وھاد": 1,
    "وھب": 1,
    "وھبة": 1,
    "وھبته": 1,
    "وھج": 1,
    "وھجان": 1,
    "وھد": 1,
    "وھدة": 1,
    "وھذا": 1,
    "وھر": 1,
    "وھران": 1,
    "وھرة": 1,
    "وھق": 1,
    "وھكذا": 1,
    "وھل": 1,
    "وھلة": 1,
    "وھلم": 1,
    "وھم": 1,
    "وھمي": 1,
    "وھمية": 1,
    "وھن": 1,
    "وھناك": 1,
    "وھو": 1,
    "وھواه": 1,
    "وھى": 1,
    "وھيج": 1,
    "وھين": 1,
    "وھيھات": 1,
    "يآسة": 1,
    "يأس": 1,
    "يألو": 1,
    "يؤبه": 1,
    "يؤنقه": 1,
    "يؤوس": 1,
    "يئد": 1,
    "يئر": 1,
    "ياء": 1,
    "يائه": 1,
    "يار": 1,
    "يازرجة": 1,
    "يازرجي": 1,
    "يازول": 1,
    "ياسمين": 1,
    "ياسنت": 1,
    "ياطاش": 1,
    "يافا": 1,
    "يافة": 1,
    "يافطة": 1,
    "يافوت": 1,
    "يافوخ": 1,
    "ياقوت": 1,
    "ياميش": 1,
    "يانسون": 1,
    "يانع": 1,
    "ياور": 1,
    "ياى": 1,
    "يباب": 1,
    "يبالى": 1,
    "يبتسم": 1,
    "يبس": 1,
    "يبق": 1,
    "يبل": 1,
    "يبوسة": 1,
    "يبھر": 1,
    "يتزعزع": 1,
    "يتطرق": 1,
    "يتعاظمه": 1,
    "يثأ": 1,
    "يثر": 1,
    "يثق": 1,
    "يجدر": 1,
    "يجدى": 1,
    "يجدي": 1,
    "يجديك": 1,
    "يجرى": 1,
    "يجس": 1,
    "يجف": 1,
    "يجم": 1,
    "يجوز": 1,
    "يجيبه": 1,
    "يحبور": 1,
    "يحتمله": 1,
    "يحلو": 1,
    "يحم": 1,
    "يحمور": 1,
    "يحي": 1,
    "يحيا": 1,
    "يخبط": 1,
    "يخت": 1,
    "يخز": 1,
    "يخصني": 1,
    "يخضور": 1,
    "يخط": 1,
    "يخطر": 1,
    "يخطو": 1,
    "يخلو": 1,
    "يخنى": 1,
    "يخوت": 1,
    "يخي": 1,
    "يدان": 1,
    "يدانى": 1,
    "يدخر": 1,
    "يدري": 1,
    "يدلع": 1,
    "يدھا": 1,
    "يذر": 1,
    "يذھب": 1,
    "يرابيع": 1,
    "يراع": 1,
    "يرام": 1,
    "يراوحھا": 1,
    "يراھا": 1,
    "يربوع": 1,
    "يرع": 1,
    "يرف": 1,
    "يرقان": 1,
    "يرقب": 1,
    "يري": 1,
    "يزب": 1,
    "يزجو": 1,
    "يزر": 1,
    "يزرجة": 1,
    "يزع": 1,
    "يزك": 1,
    "يزيدي": 1,
    "يسارا": 1,
    "يساره": 1,
    "يساق": 1,
    "يستغنى": 1,
    "يستھان": 1,
    "يسرة": 1,
    "يسرى": 1,
    "يسريات": 1,
    "يسع": 1,
    "يسعدن": 1,
    "يسعني": 1,
    "يسق": 1,
    "يسقجي": 1,
    "يسم": 1,
    "يسمين": 1,
    "يسوءني": 1,
    "يسيرة": 1,
    "يسھل": 1,
    "يشار": 1,
    "يشب": 1,
    "يشتھى": 1,
    "يشر": 1,
    "يشمق": 1,
    "يشمك": 1,
    "يشي": 1,
    "يصابحه": 1,
    "يصار": 1,
    "يصطلى": 1,
    "يضح": 1,
    "يضحك": 1,
    "يطأ": 1,
    "يطد": 1,
    "يطرأ": 1,
    "يطش": 1,
    "يطق": 1,
    "يطن": 1,
    "يطھى": 1,
    "يظب": 1,
    "يعاسيب": 1,
    "يعافير": 1,
    "يعاقبة": 1,
    "يعاقيب": 1,
    "يعب": 1,
    "يعبوب": 1,
    "يعتم": 1,
    "يعدو": 1,
    "يعذرني": 1,
    "يعر": 1,
    "يعرفه": 1,
    "يعس": 1,
    "يعسوب": 1,
    "يعطني": 1,
    "يعفور": 1,
    "يعول": 1,
    "يغر": 1,
    "يغرب": 1,
    "يفته": 1,
    "يفد": 1,
    "يفض": 1,
    "يفطة": 1,
    "يفع": 1,
    "يفق": 1,
    "يفنى": 1,
    "يفوقه": 1,
    "يفي": 1,
    "يفھم": 1,
    "يفھموا": 1,
    "يقاظة": 1,
    "يقاظى": 1,
    "يقام": 1,
    "يقب": 1,
    "يقح": 1,
    "يقد": 1,
    "يقذ": 1,
    "يقصيه": 1,
    "يقظان": 1,
    "يقظى": 1,
    "يقن": 1,
    "يقنة": 1,
    "يقوله": 1,
    "يقي": 1,
    "يقينيات": 1,
    "يكب": 1,
    "يكز": 1,
    "يكس": 1,
    "يكل": 1,
    "يكي": 1,
    "يلبث": 1,
    "يلة": 1,
    "يلس": 1,
    "يلغ": 1,
    "يلمس": 1,
    "يله": 1,
    "يلوي": 1,
    "يم": 1,
    "يمائم": 1,
    "يمام": 1,
    "يمض": 1,
    "يمق": 1,
    "يمكنه": 1,
    "يمم": 1,
    "يمنا": 1,
    "يمنة": 1,
    "يمنيات": 1,
    "يموم": 1,
    "يمي": 1,
    "ينائر": 1,
    "ينبغى": 1,
    "ينبوع": 1,
    "ينتھك": 1,
    "ينجلى": 1,
    "يندى": 1,
    "ينسون": 1,
    "ينشب": 1,
    "ينع": 1,
    "ينعقد": 1,
    "ينكف": 1,
    "ينوف": 1,
    "يني": 1,
    "ينيع": 1,
    "ينيف": 1,
    "يوافيخ": 1,
    "يواقيت": 1,
    "يوبأ": 1,
    "يوبر": 1,
    "يوبق": 1,
    "يوبل": 1,
    "يوبه": 1,
    "يوبيل": 1,
    "يوثر": 1,
    "يوجع": 1,
    "يوحل": 1,
    "يوحم": 1,
    "يوحنا": 1,
    "يوخم": 1,
    "يورانيوم": 1,
    "يوزباشي": 1,
    "يوزر": 1,
    "يوسفي": 1,
    "يوسن": 1,
    "يوشك": 1,
    "يوصب": 1,
    "يوضؤ": 1,
    "يوطف": 1,
    "يوطنة": 1,
    "يوعر": 1,
    "يوغر": 1,
    "يوغوسلافي": 1,
    "يوقح": 1,
    "يوقر": 1,
    "يوكع": 1,
    "يولع": 1,
    "يوله": 1,
    "يوليه": 1,
    "يوليو": 1,
    "يومذاك": 1,
    "يومنا": 1,
    "يومه": 1,
    "يوميات": 1,
    "يومھا": 1,
    "يونوسفير": 1,
    "يونى": 1,
    "يونيو": 1,
    "يووجل": 1,
    "يوية": 1,
    "يوھل": 1,
    "يوھم": 1,
    "يوھن": 1,
    "يھب": 1,
    "يھج": 1,
    "يھر": 1,
    "يھم": 1,
    "يھن": 1,
    "يھودي": 1,
    "يھودية": 1,
    "يھي": 1,
    "پلاتين": 1,
    "پنى": 1,
    "پيد": 1,
    "چفت": 1,
    "چفتشى": 1,
    "چفلك": 1,
    "چيت": 1,
    "چيكى": 1,
    "ڤلط": 1,
    "ڤيزا": 1,
    "ھأمتذا": 1,
    "ھأنتذا": 1,
    "ھأنذا": 1,
    "ھأھأ": 1,
    "ھؤلاء": 1,
    "ھؤلائك": 1,
    "ھؤور": 1,
    "ھؤول": 1,
    "ھا": 1,
    "ھاء": 1,
    "ھاءناذا": 1,
    "ھاءنذا": 1,
    "ھائج": 1,
    "ھائجه": 1,
    "ھائل": 1,
    "ھائم": 1,
    "ھاب": 1,
    "ھابط": 1,
    "ھابطة": 1,
    "ھابيل": 1,
    "ھات": 1,
    "ھاتان": 1,
    "ھاتف": 1,
    "ھاتفي": 1,
    "ھاته": 1,
    "ھاتوا": 1,
    "ھاتور": 1,
    "ھاتيك": 1,
    "ھاج": 1,
    "ھاجرة": 1,
    "ھاجري": 1,
    "ھاجس": 1,
    "ھاد": 1,
    "ھادئ": 1,
    "ھادم": 1,
    "ھاذ": 1,
    "ھار": 1,
    "ھارب": 1,
    "ھاربا": 1,
    "ھازئ": 1,
    "ھازل": 1,
    "ھازلة": 1,
    "ھاش": 1,
    "ھاشمي": 1,
    "ھاض": 1,
    "ھاط": 1,
    "ھاع": 1,
    "ھاف": 1,
    "ھافت": 1,
    "ھاك": 1,
    "ھاكم": 1,
    "ھاكه": 1,
    "ھال": 1,
    "ھالة": 1,
    "ھالك": 1,
    "ھام": 1,
    "ھامة": 1,
    "ھامج": 1,
    "ھامد": 1,
    "ھامش": 1,
    "ھامشي": 1,
    "ھامل": 1,
    "ھان": 1,
    "ھانئ": 1,
    "ھانئة": 1,
    "ھانم": 1,
    "ھاو": 1,
    "ھاواي": 1,
    "ھاون": 1,
    "ھاوون": 1,
    "ھاوية": 1,
    "ھاويس": 1,
    "ھايتي": 1,
    "ھاھنا": 1,
    "ھب": 1,
    "ھبا": 1,
    "ھباء": 1,
    "ھباءة": 1,
    "ھباب": 1,
    "ھبة": 1,
    "ھبت": 1,
    "ھبر": 1,
    "ھبرة": 1,
    "ھبش": 1,
    "ھبط": 1,
    "ھبطة": 1,
    "ھبل": 1,
    "ھبلاء": 1,
    "ھبلك": 1,
    "ھبني": 1,
    "ھبو": 1,
    "ھبوب": 1,
    "ھبوة": 1,
    "ھبوط": 1,
    "ھبيب": 1,
    "ھبيت": 1,
    "ھبيرة": 1,
    "ھبيط": 1,
    "ھبيل": 1,
    "ھبھاب": 1,
    "ھبھب": 1,
    "ھتاريخ": 1,
    "ھتاف": 1,
    "ھتافا": 1,
    "ھتامة": 1,
    "ھتر": 1,
    "ھتف": 1,
    "ھتفة": 1,
    "ھتك": 1,
    "ھتكة": 1,
    "ھتم": 1,
    "ھتماء": 1,
    "ھتن": 1,
    "ھتون": 1,
    "ھتيكة": 1,
    "ھج": 1,
    "ھجء": 1,
    "ھجأ": 1,
    "ھجا": 1,
    "ھجاء": 1,
    "ھجائن": 1,
    "ھجائي": 1,
    "ھجاس": 1,
    "ھجان": 1,
    "ھجانة": 1,
    "ھجد": 1,
    "ھجر": 1,
    "ھجراء": 1,
    "ھجران": 1,
    "ھجرة": 1,
    "ھجرية": 1,
    "ھجس": 1,
    "ھجسة": 1,
    "ھجص": 1,
    "ھجع": 1,
    "ھجعة": 1,
    "ھجل": 1,
    "ھجم": 1,
    "ھجمة": 1,
    "ھجمي": 1,
    "ھجن": 1,
    "ھجناء": 1,
    "ھجنة": 1,
    "ھجو": 1,
    "ھجوء": 1,
    "ھجود": 1,
    "ھجوع": 1,
    "ھجوم": 1,
    "ھجوما": 1,
    "ھجومي": 1,
    "ھجونة": 1,
    "ھجوي": 1,
    "ھجيج": 1,
    "ھجير": 1,
    "ھجيرة": 1,
    "ھجين": 1,
    "ھجينة": 1,
    "ھد": 1,
    "ھدء": 1,
    "ھدأ": 1,
    "ھدئ": 1,
    "ھداء": 1,
    "ھداءة": 1,
    "ھداب": 1,
    "ھداة": 1,
    "ھداج": 1,
    "ھداد": 1,
    "ھداديك": 1,
    "ھدار": 1,
    "ھدارة": 1,
    "ھداف": 1,
    "ھدام": 1,
    "ھدايا": 1,
    "ھداية": 1,
    "ھداھد": 1,
    "ھدب": 1,
    "ھدباء": 1,
    "ھدة": 1,
    "ھدج": 1,
    "ھدجان": 1,
    "ھدر": 1,
    "ھدرا": 1,
    "ھدف": 1,
    "ھدفا": 1,
    "ھدفان": 1,
    "ھدل": 1,
    "ھدلاء": 1,
    "ھدم": 1,
    "ھدن": 1,
    "ھدنة": 1,
    "ھدوء": 1,
    "ھدود": 1,
    "ھدوم": 1,
    "ھدون": 1,
    "ھدى": 1,
    "ھدي": 1,
    "ھدية": 1,
    "ھديد": 1,
    "ھدير": 1,
    "ھديل": 1,
    "ھدھد": 1,
    "ھذا": 1,
    "ھذاء": 1,
    "ھذاك": 1,
    "ھذاليل": 1,
    "ھذان": 1,
    "ھذب": 1,
    "ھذر": 1,
    "ھذرم": 1,
    "ھذلول": 1,
    "ھذلولي": 1,
    "ھذه": 1,
    "ھذى": 1,
    "ھذي": 1,
    "ھذيان": 1,
    "ھر": 1,
    "ھرأ": 1,
    "ھرا": 1,
    "ھراء": 1,
    "ھراب": 1,
    "ھراة": 1,
    "ھراس": 1,
    "ھراسة": 1,
    "ھراش": 1,
    "ھراطقة": 1,
    "ھراطقي": 1,
    "ھراع": 1,
    "ھراوة": 1,
    "ھراوى": 1,
    "ھرب": 1,
    "ھربان": 1,
    "ھرة": 1,
    "ھرج": 1,
    "ھرجلة": 1,
    "ھردبشت": 1,
    "ھرر": 1,
    "ھررة": 1,
    "ھرس": 1,
    "ھرش": 1,
    "ھرطق": 1,
    "ھرطقة": 1,
    "ھرطمان": 1,
    "ھرطوقي": 1,
    "ھرع": 1,
    "ھرف": 1,
    "ھرق": 1,
    "ھرقل": 1,
    "ھرم": 1,
    "ھرمس": 1,
    "ھرمون": 1,
    "ھرمي": 1,
    "ھرو": 1,
    "ھروب": 1,
    "ھرول": 1,
    "ھرولة": 1,
    "ھروي": 1,
    "ھري": 1,
    "ھرير": 1,
    "ھريرة": 1,
    "ھرھر": 1,
    "ھز": 1,
    "ھزء": 1,
    "ھزأ": 1,
    "ھزأة": 1,
    "ھزؤ": 1,
    "ھزئ": 1,
    "ھزئي": 1,
    "ھزائم": 1,
    "ھزابر": 1,
    "ھزار": 1,
    "ھزاز": 1,
    "ھزال": 1,
    "ھزاھز": 1,
    "ھزبر": 1,
    "ھزة": 1,
    "ھزج": 1,
    "ھزر": 1,
    "ھزع": 1,
    "ھزل": 1,
    "ھزلى": 1,
    "ھزلي": 1,
    "ھزلية": 1,
    "ھزم": 1,
    "ھزمھم": 1,
    "ھزه": 1,
    "ھزوء": 1,
    "ھزيز": 1,
    "ھزيل": 1,
    "ھزيم": 1,
    "ھزيمة": 1,
    "ھزھز": 1,
    "ھزھزة": 1,
    "ھس": 1,
    "ھستولوجيا": 1,
    "ھستيريا": 1,
    "ھسيس": 1,
    "ھش": 1,
    "ھشاش": 1,
    "ھشاشة": 1,
    "ھشم": 1,
    "ھشوشة": 1,
    "ھشيش": 1,
    "ھشيم": 1,
    "ھصر": 1,
    "ھصور": 1,
    "ھض": 1,
    "ھضاب": 1,
    "ھضب": 1,
    "ھضبة": 1,
    "ھضم": 1,
    "ھضمي": 1,
    "ھضوم": 1,
    "ھضيم": 1,
    "ھضيمة": 1,
    "ھطع": 1,
    "ھطل": 1,
    "ھطلان": 1,
    "ھطول": 1,
    "ھف": 1,
    "ھفا": 1,
    "ھفات": 1,
    "ھفاف": 1,
    "ھفت": 1,
    "ھفتان": 1,
    "ھفو": 1,
    "ھفوان": 1,
    "ھفوة": 1,
    "ھفيف": 1,
    "ھفھاف": 1,
    "ھفھف": 1,
    "ھفھفة": 1,
    "ھكتار": 1,
    "ھكذا": 1,
    "ھكم": 1,
    "ھل": 1,
    "ھلا": 1,
    "ھلابة": 1,
    "ھلاك": 1,
    "ھلال": 1,
    "ھلالي": 1,
    "ھلام": 1,
    "ھلامى": 1,
    "ھلاھل": 1,
    "ھلاھيل": 1,
    "ھلب": 1,
    "ھلباء": 1,
    "ھلس": 1,
    "ھلسنكي": 1,
    "ھلع": 1,
    "ھلعدل": 1,
    "ھلقم": 1,
    "ھلك": 1,
    "ھلكة": 1,
    "ھلكى": 1,
    "ھلل": 1,
    "ھللويا": 1,
    "ھلم": 1,
    "ھلمي": 1,
    "ھلوسة": 1,
    "ھلوع": 1,
    "ھلوف": 1,
    "ھليكوبتر": 1,
    "ھليلج": 1,
    "ھليون": 1,
    "ھلھل": 1,
    "ھلھولة": 1,
    "ھم": 1,
    "ھما": 1,
    "ھمائم": 1,
    "ھماز": 1,
    "ھماليج": 1,
    "ھمام": 1,
    "ھمايون": 1,
    "ھماھم": 1,
    "ھمة": 1,
    "ھمته": 1,
    "ھمج": 1,
    "ھمجي": 1,
    "ھمجية": 1,
    "ھمد": 1,
    "ھمر": 1,
    "ھمرة": 1,
    "ھمز": 1,
    "ھمزة": 1,
    "ھمس": 1,
    "ھمسات": 1,
    "ھمسة": 1,
    "ھمش": 1,
    "ھمع": 1,
    "ھمك": 1,
    "ھمل": 1,
    "ھملاج": 1,
    "ھملان": 1,
    "ھملج": 1,
    "ھمم": 1,
    "ھمو": 1,
    "ھمود": 1,
    "ھمول": 1,
    "ھموم": 1,
    "ھمھم": 1,
    "ھمھمة": 1,
    "ھن": 1,
    "ھنء": 1,
    "ھنأ": 1,
    "ھنئ": 1,
    "ھنا": 1,
    "ھناء": 1,
    "ھناءة": 1,
    "ھناشير": 1,
    "ھناك": 1,
    "ھنالك": 1,
    "ھنة": 1,
    "ھنداز": 1,
    "ھندازة": 1,
    "ھنداسة": 1,
    "ھندام": 1,
    "ھندامه": 1,
    "ھندب": 1,
    "ھندباء": 1,
    "ھندسة": 1,
    "ھندسي": 1,
    "ھندسية": 1,
    "ھندم": 1,
    "ھندمة": 1,
    "ھندى": 1,
    "ھندي": 1,
    "ھنشير": 1,
    "ھنغاري": 1,
    "ھنف": 1,
    "ھنم": 1,
    "ھنو": 1,
    "ھنوات": 1,
    "ھنود": 1,
    "ھني": 1,
    "ھنيء": 1,
    "ھنيئا": 1,
    "ھنيھة": 1,
    "ھنھن": 1,
    "ھنھونة": 1,
    "ھه": 1,
    "ھو": 1,
    "ھواء": 1,
    "ھوائي": 1,
    "ھوائية": 1,
    "ھواة": 1,
    "ھواتف": 1,
    "ھواجر": 1,
    "ھواجس": 1,
    "ھوادة": 1,
    "ھوادج": 1,
    "ھوارة": 1,
    "ھواري": 1,
    "ھوالك": 1,
    "ھوام": 1,
    "ھوامس": 1,
    "ھوان": 1,
    "ھوانم": 1,
    "ھوانمي": 1,
    "ھواه": 1,
    "ھواوين": 1,
    "ھوايا": 1,
    "ھواية": 1,
    "ھواھا": 1,
    "ھوة": 1,
    "ھوت": 1,
    "ھوتة": 1,
    "ھوج": 1,
    "ھوجاء": 1,
    "ھود": 1,
    "ھودج": 1,
    "ھوذا": 1,
    "ھور": 1,
    "ھورة": 1,
    "ھورمون": 1,
    "ھوس": 1,
    "ھوسة": 1,
    "ھوش": 1,
    "ھوشة": 1,
    "ھوع": 1,
    "ھوقظ": 1,
    "ھوكي": 1,
    "ھول": 1,
    "ھولاندا": 1,
    "ھولانده": 1,
    "ھولاندي": 1,
    "ھولة": 1,
    "ھوليوود": 1,
    "ھوم": 1,
    "ھون": 1,
    "ھونا": 1,
    "ھوناء": 1,
    "ھونك": 1,
    "ھوى": 1,
    "ھوية": 1,
    "ھويته": 1,
    "ھويس": 1,
    "ھوينا": 1,
    "ھي": 1,
    "ھيء": 1,
    "ھيأ": 1,
    "ھيأة": 1,
    "ھيؤ": 1,
    "ھيئ": 1,
    "ھيئة": 1,
    "ھيا": 1,
    "ھياءة": 1,
    "ھياب": 1,
    "ھياج": 1,
    "ھياط": 1,
    "ھياطل": 1,
    "ھياطلة": 1,
    "ھياكل": 1,
    "ھيام": 1,
    "ھيب": 1,
    "ھيبة": 1,
    "ھيت": 1,
    "ھيج": 1,
    "ھيجا": 1,
    "ھيجاء": 1,
    "ھيجان": 1,
    "ھيدروجين": 1,
    "ھيدروجينية": 1,
    "ھير": 1,
    "ھيراطيقي": 1,
    "ھيروغليفي": 1,
    "ھيروين": 1,
    "ھيستيري": 1,
    "ھيستيريا": 1,
    "ھيش": 1,
    "ھيشة": 1,
    "ھيض": 1,
    "ھيضة": 1,
    "ھيط": 1,
    "ھيطل": 1,
    "ھيف": 1,
    "ھيفاء": 1,
    "ھيفان": 1,
    "ھيكل": 1,
    "ھيكلي": 1,
    "ھيكلية": 1,
    "ھيل": 1,
    "ھيلان": 1,
    "ھيم": 1,
    "ھيمان": 1,
    "ھيمن": 1,
    "ھيمنة": 1,
    "ھيمى": 1,
    "ھين": 1,
    "ھينة": 1,
    "ھينم": 1,
    "ھيه": 1,
    "ھيوب": 1,
    "ھيوف": 1,
    "ھيول": 1,
    "ھيولاني": 1,
    "ھيولى": 1,
    "ھيولي": 1,
    "ھيوم": 1,
    "ھيھات": 1,
    "ھھ": 1,
    "ھھنا": 1,
    "آخراً": 1,
    "أبدأ": 1,
    "أبلع": 1,
    "أجوبة": 1,
    "أحتمالية": 1,
    "أحتياطي": 1,
    "أحصائي": 1,
    "أخبار": 1,
    "أختيار": 1,
    "أخدم": 1,
    "أذون": 1,
    "أربعينية": 1,
    "أرجوانى": 1,
    "أرشفة": 1,
    "أرشيف": 1,
    "أزمة": 1,
    "أسئلة": 1,
    "أسابيع": 1,
    "أساسٌ": 1,
    "أساسية": 1,
    "أسبوع": 1,
    "أسبوعان": 1,
    "أستغلال": 1,
    "أسهم": 1,
    "أسية": 1,
    "أصدرة": 1,
    "أصناف": 1,
    "أطروحة": 1,
    "أعداد": 1,
    "أعزف": 1,
    "أعيان": 1,
    "أغرق": 1,
    "أفتار": 1,
    "أفتراضي": 1,
    "أفقى": 1,
    "أفقياً": 1,
    "أقذف": 1,
    "أكتب": 1,
    "ألأول": 1,
    "ألضرورة": 1,
    "ألكترونيات": 1,
    "أمً": 1,
    "أمامية": 1,
    "أمثلة": 1,
    "أمضاء": 1,
    "أنبوبة": 1,
    "أهلا": 1,
    "أولاً": 1,
    "أوليً": 1,
    "أوًلي": 1,
    "أولية": 1,
    "أيسلندا": 1,
    "أيقونات": 1,
    "أيقونة": 1,
    "أيقونية": 1,
    "إبانة": 1,
    "إبتدائي": 1,
    "إبراز": 1,
    "إبرة": 1,
    "إبلاغ": 1,
    "إتاحة": 1,
    "إتساع": 1,
    "إتصالي": 1,
    "إجبارية": 1,
    "إجتثاث": 1,
    "إجتذاب": 1,
    "إجرائية": 1,
    "إجمالية": 1,
    "إجهاض": 1,
    "إحاطة": 1,
    "إحتشاد": 1,
    "إحتياج": 1,
    "إحداثيات": 1,
    "إحراز": 1,
    "إخباري": 1,
    "إختمال": 1,
    "إخطاء": 1,
    "إدانة": 1,
    "إدراكية": 1,
    "إدلاء": 1,
    "إذابة": 1,
    "إذاعية": 1,
    "إرباك": 1,
    "إرتعاش": 1,
    "إرتقب": 1,
    "إرتياع": 1,
    "إرساء": 1,
    "إرشادات": 1,
    "إرفاق": 1,
    "إزاحة": 1,
    "إستثنائات": 1,
    "إستخبر": 1,
    "إستدعاء": 1,
    "إسترجاع": 1,
    "إستقلالي": 1,
    "إستنتاجية": 1,
    "إسناد": 1,
    "إسهال": 1,
    "إشادات": 1,
    "إشهاد": 1,
    "إصاغة": 1,
    "إصدارة": 1,
    "إضائة": 1,
    "إطارات": 1,
    "إعتماد": 1,
    "إعتمادية": 1,
    "إعتيان": 1,
    "إعدادات": 1,
    "إغفاءة": 1,
    "إقبال": 1,
    "إقتراح": 1,
    "إقترانية": 1,
    "إقرء": 1,
    "إقفال": 1,
    "إكثار": 1,
    "إكسابايت": 1,
    "إكسترانت": 1,
    "إكمال": 1,
    "إلتقاط": 1,
    "إلحاق": 1,
    "إلصاق": 1,
    "إلمام": 1,
    "إمتدادة": 1,
    "إمتياز": 1,
    "إنترانت": 1,
    "إنترنت": 1,
    "إنسدال": 1,
    "إنطلاقة": 1,
    "إنطمار": 1,
    "إنعاش": 1,
    "إنفراج": 1,
    "إنفراجي": 1,
    "إنفراق": 1,
    "إنقضة": 1,
    "إنقلاب": 1,
    "إنقياد": 1,
    "إنهياري": 1,
    "إهليج": 1,
    "إهليلجي": 1,
    "إيفاد": 1,
    "اتجديد": 1,
    "اتصالات": 1,
    "اتقاء": 1,
    "اختر": 1,
    "ارتباطية": 1,
    "استحصال": 1,
    "استخرج": 1,
    "استراتجية": 1,
    "استعاد": 1,
    "استيثاق": 1,
    "اشترك": 1,
    "اصدارات": 1,
    "اصدارة": 1,
    "اصطلاحية": 1,
    "اضافى": 1,
    "اعتمادية": 1,
    "اقتصار": 1,
    "اقتصاص": 1,
    "الأبحار": 1,
    "الأبكر": 1,
    "الأحجام": 1,
    "الأحساس": 1,
    "الأمثل": 1,
    "الأوًل": 1,
    "الإخراج": 1,
    "الإصطلاحية": 1,
    "الإعتيان": 1,
    "الإنضغاط": 1,
    "الابتدائي": 1,
    "الاجراء": 1,
    "الارتباط": 1,
    "الاستفهام": 1,
    "الاقسام": 1,
    "الاقلاع": 1,
    "الانظمة": 1,
    "البرتغالية": 1,
    "البلًور": 1,
    "البولندية": 1,
    "التأدية": 1,
    "التجهيزة": 1,
    "التربوي": 1,
    "الترويسة": 1,
    "التسامتي": 1,
    "التسلسي": 1,
    "التسنن": 1,
    "التشظية": 1,
    "التعاونيً": 1,
    "التعمية": 1,
    "التوضيحي": 1,
    "الجذري": 1,
    "الحثً": 1,
    "الحدً": 1,
    "الحلً": 1,
    "الخردة": 1,
    "الخزامى": 1,
    "الخلوص": 1,
    "الدقًة": 1,
    "الدينامي": 1,
    "الرصة": 1,
    "الرطل": 1,
    "الرقمنة": 1,
    "الرماز": 1,
    "الزوجيًة": 1,
    "السطر": 1,
    "السواقة": 1,
    "السوكت": 1,
    "الشيفرة": 1,
    "الصادر": 1,
    "الضجًة": 1,
    "الضمنيًة": 1,
    "الظاهرية": 1,
    "العدً": 1,
    "العلًة": 1,
    "الفأرة": 1,
    "الفعال": 1,
    "الفكرية": 1,
    "الفيليبين": 1,
    "القاسم": 1,
    "القصً": 1,
    "القوًة": 1,
    "الكدسة": 1,
    "الكروماتية": 1,
    "الكمومية": 1,
    "الماكرو": 1,
    "المتبقية": 1,
    "المتوازية": 1,
    "المتورطة": 1,
    "المجلًد": 1,
    "المجمتع": 1,
    "المدخلة": 1,
    "المربعة": 1,
    "المرتقب": 1,
    "المضيفين": 1,
    "المعطيات": 1,
    "المعياري": 1,
    "المفتوحة": 1,
    "الملموس": 1,
    "الملموسة": 1,
    "الموجودة": 1,
    "المورد": 1,
    "الموسطة": 1,
    "الميدى": 1,
    "الميفاق": 1,
    "الميقاتية": 1,
    "النانو": 1,
    "النتيحة": 1,
    "النشدان": 1,
    "النكل": 1,
    "النواقل": 1,
    "الهاك": 1,
    "الواب": 1,
    "الوحيد": 1,
    "الوسائط": 1,
    "الوظيفية": 1,
    "الولوج": 1,
    "انتحارية": 1,
    "انتقاء": 1,
    "انتهت": 1,
    "انصهار": 1,
    "انضمامي": 1,
    "انطباع": 1,
    "انهاء": 1,
    "انهيار": 1,
    "باث": 1,
    "باعية": 1,
    "باقٍ": 1,
    "بالاتصال": 1,
    "بالاسم": 1,
    "بالحاجة": 1,
    "بالحاسوب": 1,
    "بالدفعات": 1,
    "بالسياق": 1,
    "بالعالم": 1,
    "بالعمق": 1,
    "بالقيمة": 1,
    "باللمس": 1,
    "بالمئة": 1,
    "بالمرجع": 1,
    "بالمظاهر": 1,
    "بالمفاتيح": 1,
    "بالمفتاح": 1,
    "بالملفات": 1,
    "بالمواصلة": 1,
    "باني": 1,
    "بايتات": 1,
    "بداهة": 1,
    "برايل": 1,
    "برمجي": 1,
    "برهة": 1,
    "بزبز": 1,
    "بسطٌ": 1,
    "بفواصل": 1,
    "بكبر": 1,
    "بلازمية": 1,
    "بلخدمة": 1,
    "بلضرورة": 1,
    "بلماكنتوش": 1,
    "بلمذاق": 1,
    "بلمورد": 1,
    "بلنكهة": 1,
    "بنائية": 1,
    "بنوية": 1,
    "بنيوي": 1,
    "بوت": 1,
    "بيئيات": 1,
    "تأجج": 1,
    "تباعدي": 1,
    "تبدد": 1,
    "تبديلي": 1,
    "تتالي": 1,
    "تجاهل": 1,
    "تجميعة": 1,
    "تجميعية": 1,
    "تجنيب": 1,
    "تجهيزات": 1,
    "تجهيزة": 1,
    "تحديث": 1,
    "تحريكة": 1,
    "تحسيب": 1,
    "تحضيرية": 1,
    "تخبئة": 1,
    "تخطيطة": 1,
    "تدفًق": 1,
    "تدفيع": 1,
    "تذكيري": 1,
    "تذييل": 1,
    "ترابطية": 1,
    "تراثى": 1,
    "تراثية": 1,
    "تراكب": 1,
    "ترتيبة": 1,
    "ترددي": 1,
    "ترصدات": 1,
    "ترصيف": 1,
    "ترقين": 1,
    "تسامتي": 1,
    "تسامتية": 1,
    "تسجيلة": 1,
    "تسريب": 1,
    "تسلسلي": 1,
    "تسلسلية": 1,
    "تسلًق": 1,
    "تسنن": 1,
    "تشعًب": 1,
    "تشغيلي": 1,
    "تشفيرة": 1,
    "تصادمات": 1,
    "تصاعدي": 1,
    "تصالب": 1,
    "تصنيفات": 1,
    "تصوين": 1,
    "تضبيط": 1,
    "تضمين": 1,
    "تطبيقات": 1,
    "تظليل": 1,
    "تعاقبية": 1,
    "تعالق": 1,
    "تعريفات": 1,
    "تعمية": 1,
    "تغليف": 1,
    "تغيرات": 1,
    "تفاعلية": 1,
    "تفضيلات": 1,
    "تقابل": 1,
    "تقدًمي": 1,
    "تقريباً": 1,
    "تقريبية": 1,
    "تقريرية": 1,
    "تقنية": 1,
    "تكرارية": 1,
    "تكيفية": 1,
    "تكييفي": 1,
    "تلاؤم": 1,
    "تلاشي": 1,
    "تلبيد": 1,
    "تماثلي": 1,
    "تماماً": 1,
    "تمرير": 1,
    "تمهيدية": 1,
    "تمهيل": 1,
    "تمييزية": 1,
    "تناظري": 1,
    "تناقش": 1,
    "تناوبي": 1,
    "تنقيل": 1,
    "تنويت": 1,
    "تنويعة": 1,
    "تواردية": 1,
    "توازي": 1,
    "توافقي": 1,
    "توافقية": 1,
    "توجيهي": 1,
    "توزيعة": 1,
    "توطين": 1,
    "توقًع": 1,
    "ثمانيات": 1,
    "جاما": 1,
    "جانت": 1,
    "جذرية": 1,
    "جرسي": 1,
    "جزىء": 1,
    "جزيئية": 1,
    "جنو": 1,
    "جني": 1,
    "جنيسة": 1,
    "جواني": 1,
    "حاسوب": 1,
    "حاسوبون": 1,
    "حاسوبي": 1,
    "حاسوبية": 1,
    "حاصرة": 1,
    "حاوية": 1,
    "حبً": 1,
    "حدًة": 1,
    "حدًث": 1,
    "حدسية": 1,
    "حرً": 1,
    "حزات": 1,
    "حقوقيات": 1,
    "حقيقياً": 1,
    "حقيقية": 1,
    "حلقت": 1,
    "حلقيًة": 1,
    "حواسب": 1,
    "حواشي": 1,
    "حوسبة": 1,
    "حومان": 1,
    "حيً": 1,
    "خادوم": 1,
    "خارجة": 1,
    "خاصيات": 1,
    "خدمية": 1,
    "خربشة": 1,
    "خصلي": 1,
    "خصيلة": 1,
    "خطً": 1,
    "خطًاط": 1,
    "خلفيا": 1,
    "خلوية": 1,
    "خوادم": 1,
    "خوارزم": 1,
    "خوارزمية": 1,
    "خيارات": 1,
    "داب": 1,
    "دالًة": 1,
    "دبينة": 1,
    "دردشة": 1,
    "دعاميع": 1,
    "دلاليات": 1,
    "دورات": 1,
    "دوريً": 1,
    "ديكارتية": 1,
    "دينامية": 1,
    "ديناميكياً": 1,
    "ذرائعي": 1,
    "ذقف": 1,
    "ذكيًة": 1,
    "رأسية": 1,
    "رتبية": 1,
    "رجرجة": 1,
    "ردً": 1,
    "رسومي": 1,
    "رسوميات": 1,
    "رسومية": 1,
    "رصة": 1,
    "رفً": 1,
    "رقمنة": 1,
    "رماز": 1,
    "رمزية": 1,
    "رنًم": 1,
    "روبوت": 1,
    "زرً": 1,
    "زوجيًة": 1,
    "زومبي": 1,
    "ساعياً": 1,
    "سبستروم": 1,
    "سبقي": 1,
    "ستي": 1,
    "سجلً": 1,
    "سعي": 1,
    "سكزي": 1,
    "سكوني": 1,
    "سلًمي": 1,
    "سماحية": 1,
    "سنتيمتر": 1,
    "سنغفورة": 1,
    "سياقية": 1,
    "سيني": 1,
    "شبكى": 1,
    "شبيكة": 1,
    "شجرى": 1,
    "شجرية": 1,
    "شعاعية": 1,
    "شفرةٌ": 1,
    "شقب": 1,
    "شيفرة": 1,
    "صادي": 1,
    "صافية": 1,
    "صحً": 1,
    "صدً": 1,
    "صرًة": 1,
    "صفحيً": 1,
    "صفحي": 1,
    "صفحيًة": 1,
    "صفرية": 1,
    "صمًام": 1,
    "صنفية": 1,
    "ضؤية": 1,
    "ضجًة": 1,
    "ضمنيً": 1,
    "ضمنيًة": 1,
    "طبيعى": 1,
    "ظاهري": 1,
    "ظاهرية": 1,
    "ظلًة": 1,
    "عائمة": 1,
    "عالميأً": 1,
    "عامودي": 1,
    "عاموديا": 1,
    "عتادى": 1,
    "عدً": 1,
    "عروية": 1,
    "عشوائية": 1,
    "عفنة": 1,
    "عمادي": 1,
    "عميمة": 1,
    "عنصورات": 1,
    "عول": 1,
    "عيًنة": 1,
    "غائمة": 1,
    "غامضة": 1,
    "غرضية": 1,
    "غميق": 1,
    "غورو": 1,
    "غيغابايت": 1,
    "فايروس": 1,
    "فرعيًة": 1,
    "فطرى": 1,
    "فعالة": 1,
    "فقود": 1,
    "فكً": 1,
    "فوريه": 1,
    "فوقية": 1,
    "فيروس": 1,
    "فيروسات": 1,
    "قارءى": 1,
    "قاسم": 1,
    "قانونيات": 1,
    "قردى": 1,
    "قصاصات": 1,
    "قطً": 1,
    "قطرية": 1,
    "قنوية": 1,
    "كائنية": 1,
    "كاتيوني": 1,
    "كاثودي": 1,
    "كافيا": 1,
    "كامرة": 1,
    "كراكيب": 1,
    "كسبية": 1,
    "كٍسرة": 1,
    "كسوري": 1,
    "كسولة": 1,
    "كلً": 1,
    "كمومي": 1,
    "كنغرية": 1,
    "كونسول": 1,
    "كيلوبايت": 1,
    "كيلوبت": 1,
    "كيلوبود": 1,
    "لاسم": 1,
    "لاشيء": 1,
    "لامتناظر": 1,
    "لامتناهية": 1,
    "لامدا": 1,
    "لتغيير": 1,
    "لصاق": 1,
    "لصيقة": 1,
    "لل": 1,
    "للإنطمار": 1,
    "للإنعاش": 1,
    "للاتصال": 1,
    "للاشيء": 1,
    "للتجهيزة": 1,
    "للتحسيب": 1,
    "للتحميل": 1,
    "للتعداد": 1,
    "للجميع": 1,
    "للجيب": 1,
    "للزلق": 1,
    "للطمر": 1,
    "للقصد": 1,
    "للكتابة": 1,
    "لللغة": 1,
    "للنافذة": 1,
    "للنظام": 1,
    "لليسار": 1,
    "لليمين": 1,
    "لمصفوفة": 1,
    "لمورد": 1,
    "لوغارتم": 1,
    "ليزر": 1,
    "مأمثل": 1,
    "مؤثرات": 1,
    "مؤول": 1,
    "مائلة": 1,
    "مارً": 1,
    "ماسحة": 1,
    "ماسك": 1,
    "ماسيً": 1,
    "ماليً": 1,
    "مبدال": 1,
    "مبطًل": 1,
    "مبنٍ": 1,
    "مبن": 1,
    "مبيان": 1,
    "متأثرة": 1,
    "متأهب": 1,
    "متتالية": 1,
    "متجانسة": 1,
    "متًجه": 1,
    "متجهية": 1,
    "متدرًج": 1,
    "مترفعة": 1,
    "متسلسلة": 1,
    "متصفًح": 1,
    "متطلب": 1,
    "متطلًبات": 1,
    "متطورة": 1,
    "متعامدة": 1,
    "متعدية": 1,
    "متغيًر": 1,
    "متغييرة": 1,
    "متقصف": 1,
    "متقطًع": 1,
    "متناقضة": 1,
    "متنصت": 1,
    "متوائم": 1,
    "متورط": 1,
    "متوصل": 1,
    "مثبًت": 1,
    "مثنوي": 1,
    "مجالات": 1,
    "مجدداً": 1,
    "مجلًد": 1,
    "محاذى": 1,
    "محاكاه": 1,
    "محث": 1,
    "محجوزة": 1,
    "محد": 1,
    "محرفي": 1,
    "محفظ": 1,
    "محلًل": 1,
    "محلًي": 1,
    "محليً": 1,
    "محوًل": 1,
    "مخبأة": 1,
    "مخسوف": 1,
    "مخطًط": 1,
    "مداول": 1,
    "مدخلة": 1,
    "مدلًى": 1,
    "مرآوي": 1,
    "مرآوية": 1,
    "مرئيات": 1,
    "مرئية": 1,
    "مربعات": 1,
    "مرذاذ": 1,
    "مرقاب": 1,
    "مرقمة": 1,
    "مرماز": 1,
    "مرمز": 1,
    "مزامنة": 1,
    "مزودج": 1,
    "مزودجا": 1,
    "مساحات": 1,
    "مساهمة": 1,
    "مسببي": 1,
    "مستصعب": 1,
    "مستضيف": 1,
    "مسروية": 1,
    "مسط": 1,
    "مسماة": 1,
    "مسيًر": 1,
    "مشاركون": 1,
    "مشبًك": 1,
    "مشتركة": 1,
    "مشتغلات": 1,
    "مشتقة": 1,
    "مصححة": 1,
    "مصطلحات": 1,
    "مصين": 1,
    "مضغوطة": 1,
    "مضمًن": 1,
    "مطارف": 1,
    "مطراف": 1,
    "معاودة": 1,
    "معدًل": 1,
    "معرًف": 1,
    "معطوب": 1,
    "معطيات": 1,
    "معيار": 1,
    "مغادرة": 1,
    "مغلقة": 1,
    "مغناطيسى": 1,
    "مغوي": 1,
    "مغيير": 1,
    "مفتاحية": 1,
    "مفروز": 1,
    "مفسًر": 1,
    "مفصلًة": 1,
    "مفصولة": 1,
    "مفككة": 1,
    "مفل": 1,
    "مفهمة": 1,
    "مقبس": 1,
    "مقتطع": 1,
    "مقهى": 1,
    "مقيمة": 1,
    "مكانيً": 1,
    "مكتبات": 1,
    "مكتلة": 1,
    "مكرًس": 1,
    "مكسورة": 1,
    "مكعًب": 1,
    "مكنز": 1,
    "مكونية": 1,
    "مكيًف": 1,
    "ملاحظات": 1,
    "ملتقطة": 1,
    "ملمتر": 1,
    "مميًزة": 1,
    "مناسخة": 1,
    "منبثقة": 1,
    "منتدى": 1,
    "مندسس": 1,
    "منساق": 1,
    "منسدلة": 1,
    "منطقى": 1,
    "منقال": 1,
    "مهاتفة": 1,
    "مهملات": 1,
    "مهنية": 1,
    "مهيء": 1,
    "مهيئ": 1,
    "موائم": 1,
    "مواردة": 1,
    "مواصلات": 1,
    "موجًهة": 1,
    "موزعة": 1,
    "موسطي": 1,
    "موسعة": 1,
    "موقعيً": 1,
    "ميجا": 1,
    "ميركرى": 1,
    "ميفاق": 1,
    "ميكرو": 1,
    "ميكرون": 1,
    "مينكس": 1,
    "ناقذة": 1,
    "نانو": 1,
    "نجاعة": 1,
    "نزولاً": 1,
    "نسخةٌ": 1,
    "نسيجي": 1,
    "نصفية": 1,
    "نصيبية": 1,
    "نقرتان": 1,
    "نقطتان": 1,
    "نقطي": 1,
    "نقطية": 1,
    "نمذجة": 1,
    "نمظ": 1,
    "نهائية": 1,
    "نهايةغير": 1,
    "نهايتين": 1,
    "نواقل": 1,
    "نوية": 1,
    "نيتسكيب": 1,
    "هاكر": 1,
    "هامد": 1,
    "هامشيات": 1,
    "هتفي": 1,
    "هجاء": 1,
    "هلال": 1,
    "هوجاء": 1,
    "هيديوغرافيا": 1,
    "وألصق": 1,
    "وإفلات": 1,
    "واب": 1,
    "واسم": 1,
    "واصف": 1,
    "واعٍ": 1,
    "والصق": 1,
    "وب": 1,
    "وجهاء": 1,
    "وخادم": 1,
    "وخادوم": 1,
    "ودجة": 1,
    "وسرد": 1,
    "وصفية": 1,
    "وصلات": 1,
    "وقائية": 1,
    "وقتياً": 1,
    "وقتية": 1,
    "ومتبوع": 1,
    "ويب": 1,
    "ويكي": 1,
    "ويندوز": 1,
    "يؤشر": 1,
    "يتوسطه": 1,
    "يحتشد": 1,
    "يسارى": 1,
    "يسارية": 1,
    "يستثني": 1,
    "يستشم": 1,
    "يسدً": 1,
    "يمينى": 1,
    "يمينية": 1,
    "يناسخ": 1,
    "يندس": 1,
    "يندسس": 1,
    "يهمهم": 1,
    "يونيكود": 1,
    "متأبط": 1,
    "أواتي": 1,
    "مستأثر": 1,
    "متأدب": 1,
    "مؤدي": 1,
    "مؤارب": 1,
    "مؤتزر": 1,
    "أواسف": 1,
    "أفول": 1,
    "متأكسد": 1,
    "مؤتلف": 1,
    "أوامن": 1,
    "متأمرك": 1,
    "مستأهل": 1,
    "آهل": 1,
    "أواهل": 1,
    "متأهل": 1,
    "متأوه": 1,
    "بوائس": 1,
    "بوائر": 1,
    "بواتر": 1,
    "بترة": 1,
    "بواث": 1,
    "بثثة": 1,
    "باجح": 1,
    "بواجح": 1,
    "بجحة": 1,
    "بجاح": 1,
    "بواحث": 1,
    "بحثة": 1,
    "منبث": 1,
    "متباجح": 1,
    "متباحث": 1,
    "متبدل": 1,
    "باخر": 1,
    "بوادئ": 1,
    "متبدد": 1,
    "متبادر": 1,
    "بادع": 1,
    "بوادن": 1,
    "بدنة": 1,
    "بدان": 1,
    "بوادي": 1,
    "بذخة": 1,
    "بذاخ": 1,
    "باذر": 1,
    "براة": 1,
    "بواري": 1,
    "بارئ": 1,
    "بوارئ": 1,
    "مبربر": 1,
    "متبرج": 1,
    "بواذر": 1,
    "بواذل": 1,
    "بذال": 1,
    "بوارح": 1,
    "برحة": 1,
    "مبارح": 1,
    "بوارد": 1,
    "متبرد": 1,
    "أبرار": 1,
    "بوارز": 1,
    "برزة": 1,
    "متبرز": 1,
    "بارص": 1,
    "مبرطل": 1,
    "بوارع": 1,
    "برعة": 1,
    "براع": 1,
    "متبرعم": 1,
    "متبارك": 1,
    "متبرك": 1,
    "مستبرك": 1,
    "بارم": 1,
    "منبرم": 1,
    "مبروز": 1,
    "متباري": 1,
    "بازغ": 1,
    "بوازغ": 1,
    "بازق": 1,
    "بازل": 1,
    "باسر": 1,
    "بواسط": 1,
    "مباسط": 1,
    "متبسط": 1,
    "بواسق": 1,
    "بسال": 1,
    "مستبسل": 1,
    "بواسم": 1,
    "بسمة": 1,
    "بواطل": 1,
    "متبسم": 1,
    "مبسمل": 1,
    "باشر": 1,
    "بواش": 1,
    "بشاش": 1,
    "بششة": 1,
    "مستبشع": 1,
    "باصر": 1,
    "بصار": 1,
    "بصرة": 1,
    "مبصر": 1,
    "متبصر": 1,
    "مستبصر": 1,
    "باصق": 1,
    "باصم": 1,
    "باضع": 1,
    "بواضع": 1,
    "متبضع": 1,
    "متباطئ": 1,
    "باطح": 1,
    "باطر": 1,
    "باطش": 1,
    "أباطيل": 1,
    "مستبطن": 1,
    "بعاث": 1,
    "متبعثر": 1,
    "مبتعد": 1,
    "باعر": 1,
    "باغض": 1,
    "متباغض": 1,
    "باغي": 1,
    "بواغي": 1,
    "بقاة": 1,
    "بواقي": 1,
    "باقر": 1,
    "باكم": 1,
    "بواكي": 1,
    "متباكي": 1,
    "متبلبل": 1,
    "متبلج": 1,
    "مبلسم": 1,
    "بالع": 1,
    "متبلع": 1,
    "مبلعم": 1,
    "بوالغ": 1,
    "متبلغ": 1,
    "متبلل": 1,
    "متبلور": 1,
    "بوالي": 1,
    "مبنج": 1,
    "بواني": 1,
    "متبني": 1,
    "باهج": 1,
    "متبهج": 1,
    "مبهدل": 1,
    "باهر": 1,
    "بواهر": 1,
    "متبهر": 1,
    "منبهر": 1,
    "متبهرج": 1,
    "بواهظ": 1,
    "مبهظ": 1,
    "مبتهل": 1,
    "مبرئ": 1,
    "متباهي": 1,
    "بائخ": 1,
    "بوائخ": 1,
    "مبور": 1,
    "بوائت": 1,
    "مبيح": 1,
    "بوائد": 1,
    "مبادر": 1,
    "مبيطر": 1,
    "متبيطر": 1,
    "بوائع": 1,
    "مبايع": 1,
    "متبايع": 1,
    "بوائن": 1,
    "بثاث": 1,
    "بوات": 1,
    "بتات": 1,
    "بتتة": 1,
    "متبين": 1,
    "مستتب": 1,
    "مستتبع": 1,
    "تواجر": 1,
    "تجرة": 1,
    "تواسع": 1,
    "تاعس": 1,
    "تفاه": 1,
    "تفهة": 1,
    "أواثم": 1,
    "متكتك": 1,
    "تالع": 1,
    "متتالع": 1,
    "متلفز": 1,
    "متتلمذ": 1,
    "متلمذ": 1,
    "تلاة": 1,
    "تامر": 1,
    "توام": 1,
    "تممة": 1,
    "توائب": 1,
    "متتوق": 1,
    "توائه": 1,
    "متثائب": 1,
    "ثوامر": 1,
    "ثبتة": 1,
    "متثبت": 1,
    "ثابط": 1,
    "ثارب": 1,
    "ثارد": 1,
    "مثري": 1,
    "ثواقب": 1,
    "منثقب": 1,
    "ثاقف": 1,
    "متثقف": 1,
    "مستثقل": 1,
    "ثاكل": 1,
    "ثكال": 1,
    "ثكلة": 1,
    "ثوالث": 1,
    "ثالج": 1,
    "متثلج": 1,
    "ثامر": 1,
    "ثواني": 1,
    "روابع": 1,
    "خوامس": 1,
    "سوادس": 1,
    "سوابع": 1,
    "ثوامن": 1,
    "مثامن": 1,
    "ثناة": 1,
    "مستثير": 1,
    "جابذ": 1,
    "جوابر": 1,
    "جبرة": 1,
    "منجبر": 1,
    "مجبس": 1,
    "جابل": 1,
    "متجبن": 1,
    "جابه": 1,
    "مجابه": 1,
    "جوابي": 1,
    "جواثم": 1,
    "جاثي": 1,
    "جثاة": 1,
    "جواحد": 1,
    "جحاد": 1,
    "جحدة": 1,
    "جواحظ": 1,
    "جحاظ": 1,
    "جحظة": 1,
    "أثام": 1,
    "جداد": 1,
    "جددة": 1,
    "جادر": 1,
    "جادع": 1,
    "جادف": 1,
    "مجدف": 1,
    "متجادل": 1,
    "مستجدي": 1,
    "جوارف": 1,
    "جواذب": 1,
    "جذبة": 1,
    "مجتذب": 1,
    "جاذع": 1,
    "جاذف": 1,
    "متجذف": 1,
    "جرحة": 1,
    "مجرح": 1,
    "متجرد": 1,
    "جارد": 1,
    "مجرر": 1,
    "جارز": 1,
    "جارس": 1,
    "مجرس": 1,
    "جارع": 1,
    "متجرع": 1,
    "مجترع": 1,
    "جواسر": 1,
    "جرفة": 1,
    "مجترف": 1,
    "جارم": 1,
    "جازر": 1,
    "جزام": 1,
    "جاسد": 1,
    "جاسر": 1,
    "متجسم": 1,
    "متجشم": 1,
    "جاعب": 1,
    "جافر": 1,
    "جواف": 1,
    "جففة": 1,
    "متجفف": 1,
    "مجفل": 1,
    "جسار": 1,
    "جسرة": 1,
    "جافن": 1,
    "جافي": 1,
    "جوافي": 1,
    "مجتلب": 1,
    "مجلب": 1,
    "مستجلب": 1,
    "منجلب": 1,
    "متجلبب": 1,
    "مجلبب": 1,
    "جالخ": 1,
    "جالد": 1,
    "جوالس": 1,
    "متجالس": 1,
    "جالف": 1,
    "منجلي": 1,
    "متجلي": 1,
    "مجلي": 1,
    "جوافل": 1,
    "جمدة": 1,
    "جامر": 1,
    "مستجمع": 1,
    "جامل": 1,
    "متجمل": 1,
    "مستجمل": 1,
    "متجمهر": 1,
    "مجمهر": 1,
    "مجنب": 1,
    "جهدة": 1,
    "متجانب": 1,
    "جوامح": 1,
    "متجند": 1,
    "مجندل": 1,
    "متجنن": 1,
    "مجنن": 1,
    "جاني": 1,
    "جواهد": 1,
    "جهاد": 1,
    "جهلة": 1,
    "جاهر": 1,
    "متجاهر": 1,
    "مجاهر": 1,
    "جواهز": 1,
    "جهزة": 1,
    "متجهز": 1,
    "مجاهض": 1,
    "جواهل": 1,
    "جهال": 1,
    "جهلاء": 1,
    "مجهل": 1,
    "مجود": 1,
    "مجير": 1,
    "مستجير": 1,
    "جوائر": 1,
    "جواثي": 1,
    "جوائع": 1,
    "متجوع": 1,
    "مجول": 1,
    "جائب": 1,
    "جوائب": 1,
    "متجيش": 1,
    "مجيش": 1,
    "مجيف": 1,
    "متحبب": 1,
    "محبذ": 1,
    "حابر": 1,
    "حابس": 1,
    "محتبس": 1,
    "حابض": 1,
    "حابق": 1,
    "حابك": 1,
    "حواد": 1,
    "محابي": 1,
    "محثث": 1,
    "متحجب": 1,
    "محتجب": 1,
    "حاجر": 1,
    "حجاز": 1,
    "حجزة": 1,
    "محتجز": 1,
    "حاجل": 1,
    "حاجم": 1,
    "محدودب": 1,
    "محادث": 1,
    "حادج": 1,
    "محتدم": 1,
    "حاذف": 1,
    "حواذق": 1,
    "حذقة": 1,
    "حوارث": 1,
    "متحذق": 1,
    "متحارب": 1,
    "حددة": 1,
    "حارز": 1,
    "متحرز": 1,
    "محترز": 1,
    "حوارس": 1,
    "حارش": 1,
    "متحرش": 1,
    "محرش": 1,
    "متحرق": 1,
    "حارم": 1,
    "حارن": 1,
    "محزب": 1,
    "حازر": 1,
    "حازق": 1,
    "حوازم": 1,
    "متحزم": 1,
    "حازن": 1,
    "متحاسب": 1,
    "متحسب": 1,
    "حواسد": 1,
    "متحاسد": 1,
    "متحسر": 1,
    "حواسم": 1,
    "حسمة": 1,
    "متحسن": 1,
    "حاشد": 1,
    "حواشد": 1,
    "حشاد": 1,
    "حشدة": 1,
    "حشار": 1,
    "حواشر": 1,
    "متحاشد": 1,
    "محتشد": 1,
    "حاشر": 1,
    "حاشم": 1,
    "حاصب": 1,
    "حصدة": 1,
    "حواصد": 1,
    "منحصر": 1,
    "حاصف": 1,
    "حصال": 1,
    "حصلة": 1,
    "مستحصل": 1,
    "محضض": 1,
    "حاضن": 1,
    "حضنة": 1,
    "حواطب": 1,
    "حطبة": 1,
    "محتطب": 1,
    "حاطم": 1,
    "منحطم": 1,
    "متحفز": 1,
    "محفض": 1,
    "حففة": 1,
    "محفف": 1,
    "حفال": 1,
    "حوافي": 1,
    "حاقب": 1,
    "حواقد": 1,
    "حقاد": 1,
    "متحاقد": 1,
    "محقد": 1,
    "حاقر": 1,
    "حاقن": 1,
    "حاكر": 1,
    "محتك": 1,
    "متحاكم": 1,
    "محتكم": 1,
    "حكاة": 1,
    "حواكي": 1,
    "حوالب": 1,
    "متحلب": 1,
    "محتلب": 1,
    "منحلب": 1,
    "حالج": 1,
    "حالف": 1,
    "مستحلف": 1,
    "متحلق": 1,
    "محلقم": 1,
    "مستحل": 1,
    "متحلي": 1,
    "حامد": 1,
    "حواكم": 1,
    "حامس": 1,
    "حامص": 1,
    "متحامل": 1,
    "مستحمل": 1,
    "محملق": 1,
    "متحمم": 1,
    "محمم": 1,
    "مستحم": 1,
    "متحنث": 1,
    "محنجر": 1,
    "حانط": 1,
    "حانف": 1,
    "محنف": 1,
    "حانك": 1,
    "حوائر": 1,
    "متحاور": 1,
    "حوائز": 1,
    "محوز": 1,
    "محوصل": 1,
    "محوض": 1,
    "محتاط": 1,
    "محوق": 1,
    "حوكة": 1,
    "حوائك": 1,
    "حاوي": 1,
    "متحير": 1,
    "حوائض": 1,
    "متحايل": 1,
    "متحين": 1,
    "متخابر": 1,
    "متخبر": 1,
    "مستخبر": 1,
    "خابز": 1,
    "خابط": 1,
    "خوابط": 1,
    "خابل": 1,
    "خوابل": 1,
    "خاتل": 1,
    "خواتل": 1,
    "ختال": 1,
    "ختلة": 1,
    "متخاتل": 1,
    "خاتن": 1,
    "خواتن": 1,
    "ختنة": 1,
    "خواثر": 1,
    "خبلة": 1,
    "خادش": 1,
    "خوادع": 1,
    "متخادع": 1,
    "منخدع": 1,
    "خاذل": 1,
    "خرخار": 1,
    "خارس": 1,
    "خارط": 1,
    "منخرط": 1,
    "خارع": 1,
    "خراق": 1,
    "خازر": 1,
    "مخزر": 1,
    "خازف": 1,
    "خوازن": 1,
    "مختزن": 1,
    "خواسئ": 1,
    "خواسر": 1,
    "خسرة": 1,
    "خاسف": 1,
    "خواسف": 1,
    "خساف": 1,
    "خسفة": 1,
    "متخشع": 1,
    "خواشع": 1,
    "خشاع": 1,
    "خاشف": 1,
    "متخشن": 1,
    "خاصب": 1,
    "خاصر": 1,
    "خاصل": 1,
    "متخاصم": 1,
    "مختصم": 1,
    "خاضب": 1,
    "متخضب": 1,
    "خواضع": 1,
    "خضاع": 1,
    "خضعة": 1,
    "مخضع": 1,
    "خطأة": 1,
    "متخاطب": 1,
    "متخاطر": 1,
    "خوافت": 1,
    "خوافي": 1,
    "خافر": 1,
    "خواطب": 1,
    "خوافض": 1,
    "خفاض": 1,
    "خفضة": 1,
    "خوافق": 1,
    "مختفي": 1,
    "خلاد": 1,
    "خلدة": 1,
    "خالس": 1,
    "خوالص": 1,
    "خلصة": 1,
    "متخالص": 1,
    "متخلص": 1,
    "متخالط": 1,
    "مخلع": 1,
    "منخلع": 1,
    "خوائن": 1,
    "متخالف": 1,
    "مستخلف": 1,
    "خوالق": 1,
    "متخلق": 1,
    "خلاة": 1,
    "خوالي": 1,
    "خامر": 1,
    "خامص": 1,
    "مخندق": 1,
    "خانس": 1,
    "خوانع": 1,
    "خناع": 1,
    "خنعة": 1,
    "مخنع": 1,
    "خانف": 1,
    "منخنق": 1,
    "خائر": 1,
    "حوامي": 1,
    "مخوصص": 1,
    "خوائف": 1,
    "خافي": 1,
    "خفاة": 1,
    "دوائم": 1,
    "متخوف": 1,
    "خوائر": 1,
    "خاوي": 1,
    "خوامل": 1,
    "خمال": 1,
    "خوائب": 1,
    "متخير": 1,
    "خوائط": 1,
    "متخايل": 1,
    "متخيم": 1,
    "دوائب": 1,
    "دوابر": 1,
    "دواثر": 1,
    "مستدبر": 1,
    "دابغ": 1,
    "دابق": 1,
    "دابل": 1,
    "مدبلج": 1,
    "مدثر": 1,
    "متدثر": 1,
    "داثر": 1,
    "مندثر": 1,
    "متدجج": 1,
    "مدجل": 1,
    "داجم": 1,
    "داجي": 1,
    "دواجي": 1,
    "داحر": 1,
    "مندحر": 1,
    "متدحرج": 1,
    "دواحس": 1,
    "داحص": 1,
    "داحض": 1,
    "مدحض": 1,
    "دخال": 1,
    "داخن": 1,
    "متدخن": 1,
    "دارج": 1,
    "دوارج": 1,
    "مندرج": 1,
    "درسة": 1,
    "متدارس": 1,
    "مندرس": 1,
    "دراع": 1,
    "درعة": 1,
    "دارك": 1,
    "متدارك": 1,
    "مستدرك": 1,
    "دارم": 1,
    "متدروش": 1,
    "مندس": 1,
    "داسع": 1,
    "مدسم": 1,
    "داشن": 1,
    "مدشن": 1,
    "دواعب": 1,
    "دعبة": 1,
    "متداعب": 1,
    "داعث": 1,
    "دواعر": 1,
    "دعرة": 1,
    "داعص": 1,
    "داعق": 1,
    "داعك": 1,
    "مستدعي": 1,
    "داغر": 1,
    "دواغر": 1,
    "دغار": 1,
    "دغرة": 1,
    "داغم": 1,
    "مندفق": 1,
    "مندفن": 1,
    "مندق": 1,
    "مدكك": 1,
    "مندك": 1,
    "دواكن": 1,
    "مدكن": 1,
    "دالج": 1,
    "مدلج": 1,
    "مندلس": 1,
    "دالق": 1,
    "دالك": 1,
    "متدلك": 1,
    "متدلل": 1,
    "متدله": 1,
    "مدلهم": 1,
    "دامج": 1,
    "دوامج": 1,
    "دماج": 1,
    "دمجة": 1,
    "دواني": 1,
    "مدمدم": 1,
    "دلاف": 1,
    "دلفة": 1,
    "دوامس": 1,
    "دامغ": 1,
    "دوامغ": 1,
    "دامل": 1,
    "مدملج": 1,
    "دامن": 1,
    "دامي": 1,
    "متدنس": 1,
    "دناة": 1,
    "ذواد": 1,
    "داهر": 1,
    "داهس": 1,
    "داهق": 1,
    "داهك": 1,
    "مداهم": 1,
    "داهم": 1,
    "متدهن": 1,
    "داهي": 1,
    "دهاة": 1,
    "دواهي": 1,
    "مداور": 1,
    "مداوم": 1,
    "داوي": 1,
    "مديث": 1,
    "دوائن": 1,
    "متداين": 1,
    "ذابح": 1,
    "ذابر": 1,
    "ذوابل": 1,
    "ذكار": 1,
    "ذواخر": 1,
    "ذخار": 1,
    "ذخرة": 1,
    "ذاخر": 1,
    "ذوائد": 1,
    "ذارب": 1,
    "ذارح": 1,
    "ذارع": 1,
    "ذارف": 1,
    "مذرف": 1,
    "ذاعر": 1,
    "ذواعر": 1,
    "ذعار": 1,
    "ذعرة": 1,
    "ذاعف": 1,
    "ذاقن": 1,
    "ذواكر": 1,
    "متذاكر": 1,
    "مستذكر": 1,
    "ذالق": 1,
    "مذلل": 1,
    "ذامر": 1,
    "مذم": 1,
    "ذانب": 1,
    "منذهل": 1,
    "ذاهل": 1,
    "ذاهن": 1,
    "ذاوي": 1,
    "ذواة": 1,
    "ذوائع": 1,
    "ذائل": 1,
    "ذوائل": 1,
    "رائز": 1,
    "روائز": 1,
    "رازة": 1,
    "مربت": 1,
    "رابث": 1,
    "مربث": 1,
    "روابح": 1,
    "رابد": 1,
    "رابص": 1,
    "رابض": 1,
    "ربوض": 1,
    "روابض": 1,
    "رباض": 1,
    "ربضة": 1,
    "رجاز": 1,
    "متربع": 1,
    "رابق": 1,
    "متربل": 1,
    "مرابي": 1,
    "مترتب": 1,
    "راتع": 1,
    "راثم": 1,
    "راجب": 1,
    "مرتج": 1,
    "رواجح": 1,
    "رجاح": 1,
    "رجحة": 1,
    "مترجح": 1,
    "راجز": 1,
    "رواجز": 1,
    "رجزة": 1,
    "رواجع": 1,
    "رجاع": 1,
    "رجعة": 1,
    "راجف": 1,
    "رواجف": 1,
    "مترجف": 1,
    "رواجل": 1,
    "رجلة": 1,
    "راجم": 1,
    "مرجم": 1,
    "راجن": 1,
    "راجي": 1,
    "رواجي": 1,
    "رداع": 1,
    "راحم": 1,
    "رواحم": 1,
    "رحام": 1,
    "متراحم": 1,
    "مترحم": 1,
    "مسترخص": 1,
    "راخف": 1,
    "راخم": 1,
    "متراخي": 1,
    "راخي": 1,
    "رواخي": 1,
    "رادح": 1,
    "مرتدع": 1,
    "مردع": 1,
    "رادف": 1,
    "مردف": 1,
    "رادم": 1,
    "راذل": 1,
    "رازح": 1,
    "رازق": 1,
    "مسترزق": 1,
    "رازم": 1,
    "رازن": 1,
    "مترزن": 1,
    "مترسب": 1,
    "رساب": 1,
    "رسبة": 1,
    "رواسخ": 1,
    "رساخ": 1,
    "رسخة": 1,
    "مترسخ": 1,
    "راسف": 1,
    "متراسل": 1,
    "مترسل": 1,
    "راسم": 1,
    "ردعة": 1,
    "مترسم": 1,
    "مرتسم": 1,
    "راسن": 1,
    "راسي": 1,
    "راشح": 1,
    "رواشح": 1,
    "رواشد": 1,
    "رشدة": 1,
    "مسترشد": 1,
    "مترشش": 1,
    "راشف": 1,
    "مترشف": 1,
    "مرتشف": 1,
    "راشق": 1,
    "متراشق": 1,
    "راشم": 1,
    "مرتشي": 1,
    "رشاة": 1,
    "رواشي": 1,
    "رواصد": 1,
    "رصدة": 1,
    "متراصد": 1,
    "متراص": 1,
    "راصع": 1,
    "مترصع": 1,
    "راصف": 1,
    "متراصف": 1,
    "مترصف": 1,
    "راصن": 1,
    "راضخ": 1,
    "رواضع": 1,
    "رضعة": 1,
    "راضف": 1,
    "مرضف": 1,
    "رواضي": 1,
    "مترطب": 1,
    "راطل": 1,
    "راطم": 1,
    "مرتعب": 1,
    "رواعب": 1,
    "رعاب": 1,
    "رواغد": 1,
    "رعبة": 1,
    "مرتعد": 1,
    "مرعد": 1,
    "مترعرع": 1,
    "مرعرع": 1,
    "راعش": 1,
    "مرعش": 1,
    "رواعف": 1,
    "رعفة": 1,
    "رواعي": 1,
    "متراغب": 1,
    "مرغب": 1,
    "راغد": 1,
    "رغاد": 1,
    "رغدة": 1,
    "راغف": 1,
    "راغن": 1,
    "رافت": 1,
    "رافس": 1,
    "رفاض": 1,
    "رفضة": 1,
    "رفهة": 1,
    "مترافع": 1,
    "روافق": 1,
    "مترافق": 1,
    "مترفق": 1,
    "رافه": 1,
    "روافه": 1,
    "مترفه": 1,
    "مرتقب": 1,
    "متراقب": 1,
    "مترقب": 1,
    "رفاد": 1,
    "رفدة": 1,
    "مترقرق": 1,
    "مرقش": 1,
    "رواقص": 1,
    "متراقص": 1,
    "راقط": 1,
    "راقع": 1,
    "مترقع": 1,
    "مترقق": 1,
    "راقن": 1,
    "رواقن": 1,
    "رقان": 1,
    "رقنة": 1,
    "مرتقي": 1,
    "رواكب": 1,
    "متركب": 1,
    "راكز": 1,
    "مرتكس": 1,
    "راكض": 1,
    "متراكض": 1,
    "مرتكض": 1,
    "مركض": 1,
    "روامق": 1,
    "رواكع": 1,
    "ركاع": 1,
    "مركع": 1,
    "راكل": 1,
    "متراكل": 1,
    "متركل": 1,
    "راكن": 1,
    "متركن": 1,
    "رامح": 1,
    "روامح": 1,
    "رمحة": 1,
    "رامد": 1,
    "مرمد": 1,
    "رامز": 1,
    "رامس": 1,
    "رامش": 1,
    "رامق": 1,
    "رهاب": 1,
    "رماق": 1,
    "رمقة": 1,
    "مرمق": 1,
    "رامل": 1,
    "مترمل": 1,
    "مرمم": 1,
    "مترمم": 1,
    "روامي": 1,
    "مرنح": 1,
    "مترنق": 1,
    "مرنق": 1,
    "مترنم": 1,
    "مرنم": 1,
    "رهبان": 1,
    "رواهب": 1,
    "مترهب": 1,
    "راهص": 1,
    "مرهط": 1,
    "راهف": 1,
    "مرتهن": 1,
    "رواهن": 1,
    "رهنة": 1,
    "متراهن": 1,
    "مراهن": 1,
    "روائب": 1,
    "مروب": 1,
    "روائج": 1,
    "مروح": 1,
    "متروح": 1,
    "روائد": 1,
    "متروض": 1,
    "ربحة": 1,
    "متروع": 1,
    "متراوغ": 1,
    "روائق": 1,
    "مترول": 1,
    "مروم": 1,
    "متريض": 1,
    "مريق": 1,
    "مزئر": 1,
    "زابد": 1,
    "زابر": 1,
    "زابل": 1,
    "زجار": 1,
    "منزجر": 1,
    "زاجل": 1,
    "زواجل": 1,
    "زجلة": 1,
    "زاحر": 1,
    "مزحزح": 1,
    "زحاف": 1,
    "زحفة": 1,
    "متزاحف": 1,
    "زاحم": 1,
    "متزاحم": 1,
    "زواخر": 1,
    "زخرة": 1,
    "متزخرف": 1,
    "مزدرد": 1,
    "زارد": 1,
    "زوارع": 1,
    "زرعة": 1,
    "زارف": 1,
    "مزدري": 1,
    "مزغرد": 1,
    "زافت": 1,
    "مزفت": 1,
    "زافر": 1,
    "زاكم": 1,
    "زاكي": 1,
    "زواكي": 1,
    "زالج": 1,
    "متزلزل": 1,
    "مزلزل": 1,
    "زالف": 1,
    "مزلف": 1,
    "مزلل": 1,
    "زالم": 1,
    "مزلم": 1,
    "زامت": 1,
    "زامج": 1,
    "متزمخر": 1,
    "متزمزم": 1,
    "مزمزم": 1,
    "زامع": 1,
    "زامل": 1,
    "زوامل": 1,
    "زمال": 1,
    "مزمل": 1,
    "متزمل": 1,
    "متزندق": 1,
    "زواني": 1,
    "متزهد": 1,
    "زهاد": 1,
    "زواهد": 1,
    "زهدة": 1,
    "زاهر": 1,
    "زواهر": 1,
    "زواهق": 1,
    "زاهي": 1,
    "زهاة": 1,
    "زواهي": 1,
    "متزاوج": 1,
    "متزود": 1,
    "زوائر": 1,
    "متزاور": 1,
    "زوائل": 1,
    "زامر": 1,
    "زوامر": 1,
    "منزوي": 1,
    "مستزيد": 1,
    "مزير": 1,
    "زوائغ": 1,
    "زوائف": 1,
    "متزين": 1,
    "ساكب": 1,
    "سواكب": 1,
    "سكبة": 1,
    "ساهر": 1,
    "سواهر": 1,
    "سهار": 1,
    "سهرة": 1,
    "سابت": 1,
    "سابخ": 1,
    "سابر": 1,
    "مسبع": 1,
    "مسبغ": 1,
    "سابك": 1,
    "منسبك": 1,
    "سوابل": 1,
    "سواتر": 1,
    "سواجد": 1,
    "سواجع": 1,
    "سجاع": 1,
    "ساجل": 1,
    "متساجل": 1,
    "متسجل": 1,
    "مساجل": 1,
    "ساجن": 1,
    "ساجي": 1,
    "سجاة": 1,
    "سواجي": 1,
    "ساحت": 1,
    "سواحق": 1,
    "سحقة": 1,
    "سواخر": 1,
    "سخار": 1,
    "مستسخر": 1,
    "سواخط": 1,
    "سخاط": 1,
    "سخطة": 1,
    "مسخف": 1,
    "سواخن": 1,
    "سادج": 1,
    "مسدد": 1,
    "سوادر": 1,
    "سدار": 1,
    "سادل": 1,
    "سواذج": 1,
    "سذاج": 1,
    "سذجة": 1,
    "منسرب": 1,
    "سارج": 1,
    "سوارح": 1,
    "سرحة": 1,
    "متسرح": 1,
    "سارد": 1,
    "سررة": 1,
    "مسارع": 1,
    "متسرول": 1,
    "مسرول": 1,
    "ساطح": 1,
    "متسطح": 1,
    "منسطح": 1,
    "ساطر": 1,
    "سطاع": 1,
    "سطعة": 1,
    "مسطع": 1,
    "مستعر": 1,
    "ساعر": 1,
    "مسعف": 1,
    "مساعف": 1,
    "ساعف": 1,
    "سواعي": 1,
    "سافح": 1,
    "سوافح": 1,
    "سفحة": 1,
    "سوافر": 1,
    "مسفر": 1,
    "مسفسط": 1,
    "سافط": 1,
    "مسفط": 1,
    "سافك": 1,
    "مسفك": 1,
    "منسفك": 1,
    "سوافل": 1,
    "متسفل": 1,
    "مسفل": 1,
    "سافه": 1,
    "سوافه": 1,
    "سفهة": 1,
    "متسفه": 1,
    "ساقر": 1,
    "متساقط": 1,
    "سواقط": 1,
    "ساقف": 1,
    "ساقي": 1,
    "سواقي": 1,
    "منسكب": 1,
    "سواكت": 1,
    "سواكر": 1,
    "سكار": 1,
    "ساكع": 1,
    "مسكع": 1,
    "سواكف": 1,
    "متساكن": 1,
    "متسكن": 1,
    "سلبة": 1,
    "مستلب": 1,
    "منسلب": 1,
    "سالت": 1,
    "متسلح": 1,
    "سالخ": 1,
    "مسلس": 1,
    "متسلطن": 1,
    "مسلطن": 1,
    "متسلف": 1,
    "مسلف": 1,
    "سالق": 1,
    "سوالك": 1,
    "سلاك": 1,
    "سوالم": 1,
    "متسالم": 1,
    "سامح": 1,
    "مستسمح": 1,
    "سوامد": 1,
    "سمدة": 1,
    "متسمر": 1,
    "سوامع": 1,
    "متسامر": 1,
    "سامط": 1,
    "متسامع": 1,
    "سوامق": 1,
    "متسمم": 1,
    "سامن": 1,
    "سوامن": 1,
    "سهاة": 1,
    "سوامي": 1,
    "سناح": 1,
    "سنحة": 1,
    "متساند": 1,
    "ساهب": 1,
    "متسهد": 1,
    "متسهل": 1,
    "مستسهل": 1,
    "سواهم": 1,
    "سهام": 1,
    "سهمة": 1,
    "ساهي": 1,
    "مستاءة": 1,
    "مسود": 1,
    "سوائد": 1,
    "مساور": 1,
    "مسور": 1,
    "مسوس": 1,
    "سوائغ": 1,
    "مسوف": 1,
    "سوائق": 1,
    "متسوق": 1,
    "مسوم": 1,
    "سوائب": 1,
    "مسيب": 1,
    "سوائح": 1,
    "سوائر": 1,
    "شوائط": 1,
    "متساير": 1,
    "مساير": 1,
    "سواهي": 1,
    "متسايف": 1,
    "مسايف": 1,
    "شائم": 1,
    "متشبب": 1,
    "مشبب": 1,
    "شابح": 1,
    "متشبع": 1,
    "شواجن": 1,
    "شابل": 1,
    "متشبه": 1,
    "متشتت": 1,
    "متشاتم": 1,
    "مشتي": 1,
    "شاتي": 1,
    "شواتي": 1,
    "شاجب": 1,
    "شاجر": 1,
    "شاجع": 1,
    "متشجع": 1,
    "شاجن": 1,
    "شجان": 1,
    "شجنة": 1,
    "شاجي": 1,
    "شجاة": 1,
    "شواجي": 1,
    "شاحذ": 1,
    "شاحم": 1,
    "شواحم": 1,
    "شحام": 1,
    "شواحن": 1,
    "شحان": 1,
    "متشاحن": 1,
    "شاخب": 1,
    "شواخص": 1,
    "شخاص": 1,
    "شخصة": 1,
    "متشخص": 1,
    "شادي": 1,
    "شوادي": 1,
    "شداة": 1,
    "شاذب": 1,
    "شرخة": 1,
    "شوارح": 1,
    "شارخ": 1,
    "شوارخ": 1,
    "شردة": 1,
    "متشارس": 1,
    "شارط": 1,
    "متشارط": 1,
    "شوارع": 1,
    "شارف": 1,
    "شوارف": 1,
    "شراف": 1,
    "متشرف": 1,
    "مستشرف": 1,
    "شارق": 1,
    "شوارق": 1,
    "متشارك": 1,
    "مشتري": 1,
    "أواسن": 1,
    "شواري": 1,
    "متشازر": 1,
    "شواسع": 1,
    "شاطب": 1,
    "مشطب": 1,
    "منشطر": 1,
    "شواطر": 1,
    "مشطر": 1,
    "شاطف": 1,
    "مشاعب": 1,
    "مشعبذ": 1,
    "مستشعر": 1,
    "متشعشع": 1,
    "شاعف": 1,
    "متشعل": 1,
    "شاغب": 1,
    "شواغب": 1,
    "شغاب": 1,
    "شغبة": 1,
    "متشاغب": 1,
    "شوافي": 1,
    "منشغف": 1,
    "شاغف": 1,
    "منشغل": 1,
    "متشاغل": 1,
    "مستشف": 1,
    "متشفع": 1,
    "مستشفع": 1,
    "مشفع": 1,
    "شافه": 1,
    "مشافه": 1,
    "شفاة": 1,
    "شواق": 1,
    "شققة": 1,
    "مشقق": 1,
    "شوارك": 1,
    "متشاكس": 1,
    "مشكك": 1,
    "شاكل": 1,
    "متشاكل": 1,
    "متشكل": 1,
    "شاكم": 1,
    "شواكي": 1,
    "شالخ": 1,
    "شمتة": 1,
    "شماخ": 1,
    "شمخة": 1,
    "متشمت": 1,
    "مشمت": 1,
    "شكال": 1,
    "شامر": 1,
    "متشمر": 1,
    "شوامس": 1,
    "شوامل": 1,
    "مشمم": 1,
    "شانع": 1,
    "مشنف": 1,
    "شانق": 1,
    "متشانق": 1,
    "شاهب": 1,
    "أشهاد": 1,
    "شهاد": 1,
    "شهدة": 1,
    "متشهد": 1,
    "شاهر": 1,
    "مشتهر": 1,
    "مشهر": 1,
    "شواهق": 1,
    "شاهم": 1,
    "مشتهي": 1,
    "متشاور": 1,
    "مشور": 1,
    "متشوش": 1,
    "شائط": 1,
    "شوائم": 1,
    "مشوط": 1,
    "شوائق": 1,
    "مشوك": 1,
    "متشوه": 1,
    "متشايخ": 1,
    "مشيخ": 1,
    "مستشيط": 1,
    "مشيط": 1,
    "متشيطن": 1,
    "مشيطن": 1,
    "شوانع": 1,
    "مشيع": 1,
    "متشايع": 1,
    "شوائن": 1,
    "صواحي": 1,
    "متصبب": 1,
    "صابح": 1,
    "متصبح": 1,
    "صوابر": 1,
    "متصبر": 1,
    "مصبر": 1,
    "مصطبر": 1,
    "صابع": 1,
    "مصبغ": 1,
    "مصطبغ": 1,
    "متصبن": 1,
    "مصبن": 1,
    "صابي": 1,
    "صباة": 1,
    "صوابي": 1,
    "مصطحب": 1,
    "صحاب": 1,
    "متصاحب": 1,
    "مستصحب": 1,
    "متصحح": 1,
    "مصحر": 1,
    "صاحن": 1,
    "صواخب": 1,
    "صخبة": 1,
    "متصاخب": 1,
    "صاخر": 1,
    "صداد": 1,
    "صواد": 1,
    "صددة": 1,
    "صوادح": 1,
    "صوادر": 1,
    "متصدر": 1,
    "مستصدر": 1,
    "صادع": 1,
    "مصدع": 1,
    "منصدع": 1,
    "صادغ": 1,
    "متصادف": 1,
    "صوادق": 1,
    "صوارم": 1,
    "متصادق": 1,
    "صادم": 1,
    "متصادم": 1,
    "صداة": 1,
    "صوادي": 1,
    "صارح": 1,
    "مصارح": 1,
    "منصرح": 1,
    "صوارخ": 1,
    "متصارخ": 1,
    "مستصرخ": 1,
    "صوابئ": 1,
    "صوائغ": 1,
    "صارع": 1,
    "متصارع": 1,
    "منصرع": 1,
    "صارف": 1,
    "صرام": 1,
    "متصرم": 1,
    "صاري": 1,
    "مصطب": 1,
    "مصعب": 1,
    "متصاعب": 1,
    "صعاد": 1,
    "صاعق": 1,
    "متصعلك": 1,
    "صواغر": 1,
    "متصاغر": 1,
    "صاغي": 1,
    "صغاة": 1,
    "صواغي": 1,
    "مصغي": 1,
    "مصافح": 1,
    "متصافح": 1,
    "صافد": 1,
    "صافع": 1,
    "متصافع": 1,
    "صافق": 1,
    "صوافي": 1,
    "مصفي": 1,
    "صاقر": 1,
    "مصقر": 1,
    "صاقع": 1,
    "صاقل": 1,
    "صالب": 1,
    "صالت": 1,
    "صلحاء": 1,
    "صمدة": 1,
    "صلحة": 1,
    "متصالح": 1,
    "مستصلح": 1,
    "مصلد": 1,
    "صوامت": 1,
    "صمات": 1,
    "صمتة": 1,
    "صوامد": 1,
    "صماد": 1,
    "صانج": 1,
    "صوانع": 1,
    "متصنف": 1,
    "صاهد": 1,
    "متصاهر": 1,
    "مصاهر": 1,
    "صاهل": 1,
    "صواهل": 1,
    "متصاهل": 1,
    "شواهر": 1,
    "شهار": 1,
    "شهرة": 1,
    "مصوب": 1,
    "ضواغن": 1,
    "صوائب": 1,
    "متصوف": 1,
    "متصاول": 1,
    "صوائم": 1,
    "مصوم": 1,
    "صاوي": 1,
    "صوائح": 1,
    "متصايح": 1,
    "صوائد": 1,
    "مصيدل": 1,
    "صائر": 1,
    "صوائر": 1,
    "متصيف": 1,
    "ضوائن": 1,
    "ظأنة": 1,
    "ضابح": 1,
    "ضابر": 1,
    "ضبطة": 1,
    "ضابن": 1,
    "مضاجر": 1,
    "ضاجع": 1,
    "ضواجع": 1,
    "ضجاع": 1,
    "متضاجع": 1,
    "متضاحك": 1,
    "ضاحل": 1,
    "ضاحي": 1,
    "ضحاة": 1,
    "ضارح": 1,
    "ضرار": 1,
    "ضررة": 1,
    "ضارس": 1,
    "ضوارع": 1,
    "متضرم": 1,
    "مضرم": 1,
    "ضاري": 1,
    "ضراة": 1,
    "ضواري": 1,
    "ضاغث": 1,
    "متضاغط": 1,
    "منضغط": 1,
    "ضاغم": 1,
    "ضاغن": 1,
    "ضغان": 1,
    "ضغنة": 1,
    "متضاغن": 1,
    "متضافر": 1,
    "مضافر": 1,
    "ضالع": 1,
    "مضطلع": 1,
    "ضواغط": 1,
    "ضغاط": 1,
    "ضللة": 1,
    "ضامخ": 1,
    "ضامد": 1,
    "متضمد": 1,
    "ضوامر": 1,
    "ضمرة": 1,
    "متضمر": 1,
    "متضامن": 1,
    "ضمنة": 1,
    "ضوامن": 1,
    "مضني": 1,
    "مضيئة": 1,
    "متضور": 1,
    "ضافي": 1,
    "ضوافي": 1,
    "منضوي": 1,
    "ضاوي": 1,
    "ضواة": 1,
    "ضوائع": 1,
    "ضائق": 1,
    "متضيق": 1,
    "ضائم": 1,
    "ضوائم": 1,
    "طوائع": 1,
    "مطبب": 1,
    "طابخ": 1,
    "منطبخ": 1,
    "متطبع": 1,
    "منطبع": 1,
    "طاحل": 1,
    "طراء": 1,
    "طرأة": 1,
    "مستطرب": 1,
    "طارح": 1,
    "متطارح": 1,
    "متطارد": 1,
    "مستطرد": 1,
    "متطرز": 1,
    "طوارف": 1,
    "متطرق": 1,
    "طواعم": 1,
    "مستطعم": 1,
    "طواعن": 1,
    "طعان": 1,
    "متطاعن": 1,
    "طاغي": 1,
    "مطفئ": 1,
    "طافح": 1,
    "طوافح": 1,
    "طفاح": 1,
    "طوالب": 1,
    "مطفف": 1,
    "طافي": 1,
    "طفاة": 1,
    "طوافي": 1,
    "طواغي": 1,
    "طوالح": 1,
    "طلحة": 1,
    "طالس": 1,
    "طماس": 1,
    "طوالق": 1,
    "طالم": 1,
    "طامث": 1,
    "طوامح": 1,
    "طمحة": 1,
    "طامر": 1,
    "طوامس": 1,
    "منطمس": 1,
    "طامع": 1,
    "طمعاء": 1,
    "طوامع": 1,
    "طمعة": 1,
    "طمسة": 1,
    "أطهار": 1,
    "طواهر": 1,
    "طهار": 1,
    "طهرة": 1,
    "متطهر": 1,
    "طاهي": 1,
    "طهاة": 1,
    "طواهي": 1,
    "ظوافر": 1,
    "متطوس": 1,
    "متطاوع": 1,
    "متطوف": 1,
    "طوائل": 1,
    "متطول": 1,
    "طام": 1,
    "طوام": 1,
    "طواة": 1,
    "متطيب": 1,
    "مطاير": 1,
    "طوائش": 1,
    "متطيف": 1,
    "ظارف": 1,
    "ظوالم": 1,
    "ظفار": 1,
    "ظفرة": 1,
    "متظافر": 1,
    "مستظل": 1,
    "ظالع": 1,
    "ظوالع": 1,
    "ظلاع": 1,
    "ظلعة": 1,
    "ظالف": 1,
    "متظلل": 1,
    "متظلم": 1,
    "ظوامئ": 1,
    "ظمأة": 1,
    "ظواهر": 1,
    "ظهار": 1,
    "ظهرة": 1,
    "عوائل": 1,
    "عوابث": 1,
    "عباث": 1,
    "عبثة": 1,
    "عبسة": 1,
    "عوابد": 1,
    "عوابر": 1,
    "عبار": 1,
    "عوابس": 1,
    "متعبس": 1,
    "عابط": 1,
    "معتبط": 1,
    "معبق": 1,
    "عواتب": 1,
    "معاتب": 1,
    "عتاق": 1,
    "عتقة": 1,
    "عاتل": 1,
    "عاتم": 1,
    "عواتم": 1,
    "عتام": 1,
    "عاتي": 1,
    "عواتي": 1,
    "عواثر": 1,
    "مستعجب": 1,
    "معجج": 1,
    "عدال": 1,
    "عجاز": 1,
    "عاجف": 1,
    "عواجل": 1,
    "عاجم": 1,
    "عاجن": 1,
    "معتجن": 1,
    "عادس": 1,
    "عوادل": 1,
    "عدلة": 1,
    "منعدم": 1,
    "عادن": 1,
    "عوادي": 1,
    "معتدي": 1,
    "عاذب": 1,
    "متعذب": 1,
    "مستعذب": 1,
    "عاذر": 1,
    "عاذق": 1,
    "عذلة": 1,
    "عارب": 1,
    "متعرب": 1,
    "معرس": 1,
    "عارش": 1,
    "متعرض": 1,
    "عوارف": 1,
    "عرفة": 1,
    "متعرف": 1,
    "مستعرف": 1,
    "عارق": 1,
    "متعرقل": 1,
    "عارك": 1,
    "متعارك": 1,
    "معترك": 1,
    "عوارم": 1,
    "عواري": 1,
    "عوازب": 1,
    "متعزب": 1,
    "عازر": 1,
    "معزر": 1,
    "معزز": 1,
    "متعزز": 1,
    "عوازف": 1,
    "عزاف": 1,
    "عزفة": 1,
    "متعازل": 1,
    "عوازم": 1,
    "عزام": 1,
    "معزم": 1,
    "معزي": 1,
    "عاسر": 1,
    "عاسف": 1,
    "عاسل": 1,
    "عاشب": 1,
    "عواشب": 1,
    "متعاشر": 1,
    "معشش": 1,
    "عاصب": 1,
    "عواصب": 1,
    "عاصد": 1,
    "عاصر": 1,
    "عواصر": 1,
    "متعصر": 1,
    "معتصر": 1,
    "معصر": 1,
    "منعصر": 1,
    "معصف": 1,
    "معاصف": 1,
    "عشقة": 1,
    "معتصم": 1,
    "مستعصم": 1,
    "مستعصي": 1,
    "عواصي": 1,
    "عاضد": 1,
    "متعاضد": 1,
    "معاضد": 1,
    "عاضل": 1,
    "معطب": 1,
    "عواطر": 1,
    "متعطر": 1,
    "معطش": 1,
    "متعطف": 1,
    "مستعطف": 1,
    "عطال": 1,
    "مستعطي": 1,
    "متعاظل": 1,
    "متعظل": 1,
    "متعظم": 1,
    "مستعظم": 1,
    "عافر": 1,
    "متعفر": 1,
    "متعفرت": 1,
    "عافس": 1,
    "عافي": 1,
    "عفاة": 1,
    "عوافي": 1,
    "عواقد": 1,
    "متعقد": 1,
    "عاقص": 1,
    "عاقف": 1,
    "عواق": 1,
    "عقاق": 1,
    "عققة": 1,
    "عواقل": 1,
    "عكفة": 1,
    "عاقم": 1,
    "عاكر": 1,
    "عاكز": 1,
    "عواكس": 1,
    "عكاس": 1,
    "عكسة": 1,
    "عواكف": 1,
    "عكاف": 1,
    "معتكف": 1,
    "متعالج": 1,
    "متعلف": 1,
    "معلقم": 1,
    "عالك": 1,
    "معلك": 1,
    "معلل": 1,
    "متعلل": 1,
    "علمة": 1,
    "متعالم": 1,
    "مستعلم": 1,
    "عالن": 1,
    "عوالن": 1,
    "متعالي": 1,
    "عوالي": 1,
    "عامد": 1,
    "معتمر": 1,
    "عوامر": 1,
    "عمار": 1,
    "عامش": 1,
    "متعامل": 1,
    "عامه": 1,
    "معمي": 1,
    "معنبر": 1,
    "عاند": 1,
    "عواند": 1,
    "متعاند": 1,
    "عانز": 1,
    "عناس": 1,
    "عنسة": 1,
    "معتنق": 1,
    "متعانق": 1,
    "معتني": 1,
    "متعاهد": 1,
    "عهار": 1,
    "عواهر": 1,
    "غاصة": 1,
    "عاهل": 1,
    "عواهل": 1,
    "عهل": 1,
    "متعوج": 1,
    "متعوذ": 1,
    "معور": 1,
    "معوز": 1,
    "عائز": 1,
    "عوائز": 1,
    "عندة": 1,
    "معيل": 1,
    "عالة": 1,
    "عيل": 1,
    "معولم": 1,
    "عوائم": 1,
    "معاون": 1,
    "عاوي": 1,
    "عائب": 1,
    "عوائب": 1,
    "متعايب": 1,
    "متعاير": 1,
    "متعيش": 1,
    "عائش": 1,
    "عيلى": 1,
    "عوائش": 1,
    "معيق": 1,
    "معاين": 1,
    "عوابئ": 1,
    "عبأة": 1,
    "غوابر": 1,
    "مغبر": 1,
    "متغابط": 1,
    "متغابن": 1,
    "غادق": 1,
    "غادي": 1,
    "غوادي": 1,
    "غوادر": 1,
    "غدرة": 1,
    "متغرد": 1,
    "مغرر": 1,
    "غارز": 1,
    "غوارز": 1,
    "غراز": 1,
    "غواسق": 1,
    "منغرز": 1,
    "غارس": 1,
    "منغرس": 1,
    "غارض": 1,
    "متغرغر": 1,
    "غارف": 1,
    "مغترف": 1,
    "غوارق": 1,
    "غراق": 1,
    "غرقة": 1,
    "غارم": 1,
    "متغزل": 1,
    "غوازي": 1,
    "منغسل": 1,
    "غاش": 1,
    "غششة": 1,
    "غوامش": 1,
    "غماش": 1,
    "غمشة": 1,
    "غصاب": 1,
    "غواصب": 1,
    "غصبة": 1,
    "غاصن": 1,
    "مغصن": 1,
    "غواضب": 1,
    "متغضب": 1,
    "مغطرس": 1,
    "متغطرف": 1,
    "غواطس": 1,
    "غطسة": 1,
    "مغطش": 1,
    "مستغفر": 1,
    "غوافر": 1,
    "مغتفر": 1,
    "غافق": 1,
    "غوافل": 1,
    "غفال": 1,
    "مستغفل": 1,
    "غوالب": 1,
    "متغلب": 1,
    "متغالط": 1,
    "مغلط": 1,
    "غالظ": 1,
    "غوالظ": 1,
    "مغلغل": 1,
    "غالف": 1,
    "متغلف": 1,
    "غالق": 1,
    "غوالي": 1,
    "مغتمر": 1,
    "غوائص": 1,
    "غوامر": 1,
    "منغمر": 1,
    "غامز": 1,
    "غوامز": 1,
    "غماز": 1,
    "غمزة": 1,
    "متغامز": 1,
    "غامس": 1,
    "مغتمس": 1,
    "مغمض": 1,
    "غوامق": 1,
    "غوام": 1,
    "مغم": 1,
    "متغنج": 1,
    "مغتنم": 1,
    "غاني": 1,
    "غناة": 1,
    "غواني": 1,
    "مستغيث": 1,
    "غوائر": 1,
    "غائص": 1,
    "مغوص": 1,
    "غاوي": 1,
    "غوائب": 1,
    "متغيظ": 1,
    "مغيظ": 1,
    "غوائم": 1,
    "متفاتح": 1,
    "مستفتح": 1,
    "فتار": 1,
    "فاتق": 1,
    "متفتق": 1,
    "مفتق": 1,
    "منفتق": 1,
    "فواتك": 1,
    "متفتل": 1,
    "مفتل": 1,
    "منفتل": 1,
    "مفتتن": 1,
    "أوافل": 1,
    "متفجع": 1,
    "فحشة": 1,
    "متفحش": 1,
    "متفحص": 1,
    "فواحص": 1,
    "فحاص": 1,
    "فحصة": 1,
    "مفتحص": 1,
    "فاحل": 1,
    "فواحم": 1,
    "فاخذ": 1,
    "متفخر": 1,
    "فداح": 1,
    "فدحة": 1,
    "فادي": 1,
    "فداة": 1,
    "فوادي": 1,
    "مفرخ": 1,
    "متفرد": 1,
    "مستفرد": 1,
    "فررة": 1,
    "فوارز": 1,
    "فراز": 1,
    "فرزة": 1,
    "متفرس": 1,
    "مفترش": 1,
    "فارش": 1,
    "فوارض": 1,
    "فراض": 1,
    "فراط": 1,
    "فوارط": 1,
    "فرطة": 1,
    "منفرط": 1,
    "فوارع": 1,
    "فراع": 1,
    "فرعة": 1,
    "متفرعن": 1,
    "مفرعن": 1,
    "فوارغ": 1,
    "مستفرغ": 1,
    "متفارق": 1,
    "منفرق": 1,
    "متفرقع": 1,
    "فارك": 1,
    "منفرك": 1,
    "متفرنس": 1,
    "فواره": 1,
    "فراه": 1,
    "فرهة": 1,
    "فازر": 1,
    "متفزر": 1,
    "مفزر": 1,
    "مستفز": 1,
    "فازع": 1,
    "فاسح": 1,
    "متفسح": 1,
    "منفسخ": 1,
    "فواسد": 1,
    "فسدة": 1,
    "متفاسد": 1,
    "فاسر": 1,
    "فواسق": 1,
    "مفسق": 1,
    "مفسل": 1,
    "متفشي": 1,
    "فاصح": 1,
    "متفصح": 1,
    "فاصد": 1,
    "متفصد": 1,
    "منفصد": 1,
    "متفصل": 1,
    "فاصم": 1,
    "فواضح": 1,
    "فضحة": 1,
    "متفاضح": 1,
    "مفتضح": 1,
    "منفضح": 1,
    "مفضض": 1,
    "فضال": 1,
    "متفاضل": 1,
    "متفضل": 1,
    "فاضي": 1,
    "فواضي": 1,
    "فاطح": 1,
    "فواطر": 1,
    "فطار": 1,
    "متفطر": 1,
    "مفطر": 1,
    "فاطس": 1,
    "مفطس": 1,
    "فاطم": 1,
    "مفطم": 1,
    "منفطم": 1,
    "فاطن": 1,
    "فواطن": 1,
    "فطان": 1,
    "متفطن": 1,
    "مفطن": 1,
    "فواعل": 1,
    "فاغر": 1,
    "فواغر": 1,
    "فغار": 1,
    "فواقد": 1,
    "فقاد": 1,
    "فقدة": 1,
    "مفقد": 1,
    "فاقر": 1,
    "فاقس": 1,
    "فاقص": 1,
    "متفقص": 1,
    "متفقفق": 1,
    "فاقم": 1,
    "متفقم": 1,
    "فاقه": 1,
    "متفقه": 1,
    "مفقه": 1,
    "فاكر": 1,
    "متفكر": 1,
    "مفتكر": 1,
    "فكهة": 1,
    "فكاه": 1,
    "متفكه": 1,
    "متفلت": 1,
    "متفلج": 1,
    "منفلج": 1,
    "متفلح": 1,
    "مفلق": 1,
    "متفلق": 1,
    "منفلق": 1,
    "مفنن": 1,
    "فناة": 1,
    "فواني": 1,
    "فاهم": 1,
    "متفاهم": 1,
    "متفهم": 1,
    "مستفهم": 1,
    "مفهم": 1,
    "مفوت": 1,
    "مفوج": 1,
    "فائر": 1,
    "فوائر": 1,
    "مفاوض": 1,
    "فوائق": 1,
    "متفاوه": 1,
    "متفوه": 1,
    "مستفيد": 1,
    "قابح": 1,
    "مستقبح": 1,
    "مقبح": 1,
    "قابر": 1,
    "قابس": 1,
    "قوابض": 1,
    "متقبض": 1,
    "قابع": 1,
    "قوابع": 1,
    "متقابل": 1,
    "قواتر": 1,
    "قواتن": 1,
    "قواتل": 1,
    "متقاتل": 1,
    "قاتن": 1,
    "قاحب": 1,
    "قواحب": 1,
    "قاحط": 1,
    "قواحط": 1,
    "قاحف": 1,
    "قواحل": 1,
    "قاحم": 1,
    "متقحم": 1,
    "قادح": 1,
    "متقدد": 1,
    "قوادر": 1,
    "قدار": 1,
    "متقدر": 1,
    "قوادم": 1,
    "قدمة": 1,
    "متقادم": 1,
    "مستقدم": 1,
    "قاذر": 1,
    "مقذع": 1,
    "قاذع": 1,
    "قواذف": 1,
    "متقاذف": 1,
    "قوارئ": 1,
    "قرأة": 1,
    "متقرب": 1,
    "قارح": 1,
    "قوارح": 1,
    "قارد": 1,
    "قوار": 1,
    "قررة": 1,
    "متقرر": 1,
    "قراة": 1,
    "قواري": 1,
    "قارش": 1,
    "قراص": 1,
    "متقارص": 1,
    "مقرص": 1,
    "مقترض": 1,
    "قوارض": 1,
    "قراض": 1,
    "قرضة": 1,
    "قارط": 1,
    "مقرظ": 1,
    "متقارع": 1,
    "مقرع": 1,
    "قارف": 1,
    "مقرفص": 1,
    "قوارس": 1,
    "مقترن": 1,
    "قارن": 1,
    "متقارن": 1,
    "متقزز": 1,
    "قازم": 1,
    "متقزم": 1,
    "قاسح": 1,
    "قواسح": 1,
    "قاسر": 1,
    "قاسط": 1,
    "قساط": 1,
    "قواسط": 1,
    "قسطة": 1,
    "قواسم": 1,
    "مقتسم": 1,
    "متقاسم": 1,
    "قواسي": 1,
    "قاشر": 1,
    "قاشط": 1,
    "متقشط": 1,
    "قاشع": 1,
    "منقشع": 1,
    "مقشعر": 1,
    "قاصب": 1,
    "قواصر": 1,
    "قصرة": 1,
    "قصصة": 1,
    "قواصد": 1,
    "قصدة": 1,
    "قاصع": 1,
    "قاصف": 1,
    "قواصف": 1,
    "قصاف": 1,
    "قصفة": 1,
    "متقاصف": 1,
    "منقصف": 1,
    "قصام": 1,
    "قصمة": 1,
    "منقصم": 1,
    "قاصي": 1,
    "قصاة": 1,
    "قواصي": 1,
    "مستقصي": 1,
    "قاضب": 1,
    "متقضب": 1,
    "قاضم": 1,
    "قواضم": 1,
    "قضام": 1,
    "قضمة": 1,
    "قواضي": 1,
    "قاطب": 1,
    "قواضب": 1,
    "مقطب": 1,
    "قواطب": 1,
    "قطاب": 1,
    "قاطر": 1,
    "قواطر": 1,
    "قاطف": 1,
    "قواطن": 1,
    "قطنة": 1,
    "مقتعد": 1,
    "متقعر": 1,
    "قاعس": 1,
    "قافش": 1,
    "قافص": 1,
    "قفلة": 1,
    "منقفل": 1,
    "متقلد": 1,
    "قالص": 1,
    "مقتلع": 1,
    "قالع": 1,
    "قوالع": 1,
    "متقلع": 1,
    "منقلع": 1,
    "متقلقل": 1,
    "متقامر": 1,
    "متقمص": 1,
    "مقمص": 1,
    "قامط": 1,
    "قوانئ": 1,
    "مقنبل": 1,
    "قوانع": 1,
    "قوانت": 1,
    "قنات": 1,
    "قنتة": 1,
    "مقتنص": 1,
    "قوانط": 1,
    "قناط": 1,
    "متقنع": 1,
    "مقتني": 1,
    "قاني": 1,
    "قواني": 1,
    "قاهر": 1,
    "قواهر": 1,
    "قهرة": 1,
    "متقهقر": 1,
    "متقوت": 1,
    "قوائد": 1,
    "متقوض": 1,
    "قوائل": 1,
    "متقاول": 1,
    "متقول": 1,
    "كوابي": 1,
    "قاوي": 1,
    "قنعة": 1,
    "مقيح": 1,
    "متقيد": 1,
    "متقايض": 1,
    "متقيض": 1,
    "مقيض": 1,
    "قوائظ": 1,
    "مقايظ": 1,
    "متقيل": 1,
    "مقين": 1,
    "كابت": 1,
    "منكبت": 1,
    "كابح": 1,
    "مكبد": 1,
    "كابد": 1,
    "متكبد": 1,
    "مكابد": 1,
    "متكابر": 1,
    "مستكبر": 1,
    "كابس": 1,
    "كوابس": 1,
    "كبسة": 1,
    "منكبس": 1,
    "كابش": 1,
    "كابي": 1,
    "كواتب": 1,
    "متكاتب": 1,
    "مستكتب": 1,
    "منكتب": 1,
    "كاتف": 1,
    "متكاتف": 1,
    "متكتف": 1,
    "متكتكت": 1,
    "كاتل": 1,
    "متكتم": 1,
    "كواتم": 1,
    "كتمة": 1,
    "مكتم": 1,
    "منكتم": 1,
    "كاثب": 1,
    "متكثر": 1,
    "مستكثر": 1,
    "كاثم": 1,
    "مكتحل": 1,
    "متكحل": 1,
    "كادس": 1,
    "متكدس": 1,
    "كواذب": 1,
    "كواسب": 1,
    "متكاذب": 1,
    "متكذب": 1,
    "مكذب": 1,
    "متكربع": 1,
    "متكرس": 1,
    "متكركر": 1,
    "كارم": 1,
    "كواره": 1,
    "كراه": 1,
    "كرهة": 1,
    "مستكره": 1,
    "مكاري": 1,
    "متكسب": 1,
    "مكتسح": 1,
    "كاسح": 1,
    "كواسح": 1,
    "كسحة": 1,
    "كواسد": 1,
    "كسبة": 1,
    "كواشح": 1,
    "كسار": 1,
    "كواسف": 1,
    "كساف": 1,
    "كسفة": 1,
    "منكسف": 1,
    "كاسي": 1,
    "كساة": 1,
    "كواسي": 1,
    "كشاح": 1,
    "كشحة": 1,
    "كاشر": 1,
    "كواشر": 1,
    "كشار": 1,
    "متكشر": 1,
    "مكشر": 1,
    "كواشف": 1,
    "متكاشف": 1,
    "منكشف": 1,
    "كاظم": 1,
    "كواظم": 1,
    "كظام": 1,
    "كظمة": 1,
    "كوافل": 1,
    "كففة": 1,
    "كواف": 1,
    "متكعب": 1,
    "متكافح": 1,
    "كوافر": 1,
    "متكفف": 1,
    "متكفكف": 1,
    "كفال": 1,
    "كفلة": 1,
    "متكافل": 1,
    "متكفل": 1,
    "مكفل": 1,
    "كافن": 1,
    "متكفن": 1,
    "مكتفي": 1,
    "كوافي": 1,
    "كالب": 1,
    "كوالب": 1,
    "متكالب": 1,
    "كوالح": 1,
    "متكلل": 1,
    "كالم": 1,
    "مكالم": 1,
    "كامت": 1,
    "كوامد": 1,
    "كامش": 1,
    "مكمش": 1,
    "متكمكم": 1,
    "كوامل": 1,
    "متكمل": 1,
    "مستكمل": 1,
    "كانز": 1,
    "كانس": 1,
    "كوانس": 1,
    "كنسة": 1,
    "متكنس": 1,
    "مكتنس": 1,
    "كانف": 1,
    "مكتنه": 1,
    "متكهرب": 1,
    "كاهل": 1,
    "كهنة": 1,
    "كهان": 1,
    "كواهن": 1,
    "متكوثر": 1,
    "متكور": 1,
    "مكوكب": 1,
    "كوائن": 1,
    "كائد": 1,
    "كوائد": 1,
    "متكايد": 1,
    "متكيس": 1,
    "متكايل": 1,
    "متلائم": 1,
    "لابد": 1,
    "لوابد": 1,
    "لاهي": 1,
    "لابث": 1,
    "لوابث": 1,
    "متلبث": 1,
    "لابط": 1,
    "لابن": 1,
    "لوابن": 1,
    "متلبنن": 1,
    "ملبنن": 1,
    "ملبي": 1,
    "لاثم": 1,
    "لواجئ": 1,
    "لجاء": 1,
    "لجأة": 1,
    "لواحس": 1,
    "لحاس": 1,
    "لاجم": 1,
    "لاجن": 1,
    "متلجن": 1,
    "لاحد": 1,
    "لاحس": 1,
    "متلاحظ": 1,
    "لاحف": 1,
    "متلحف": 1,
    "لحقة": 1,
    "ملحم": 1,
    "متلاحم": 1,
    "لاحم": 1,
    "لواحم": 1,
    "لاحن": 1,
    "لواحن": 1,
    "لحان": 1,
    "لحنة": 1,
    "متلدن": 1,
    "مستلذ": 1,
    "لذعة": 1,
    "متلذع": 1,
    "لوازب": 1,
    "ملتزق": 1,
    "ملزق": 1,
    "لاسن": 1,
    "ملاسن": 1,
    "لواصق": 1,
    "لصقة": 1,
    "لاطخ": 1,
    "متلطخ": 1,
    "لاطع": 1,
    "ملطف": 1,
    "متلاطف": 1,
    "متلطف": 1,
    "مستلطف": 1,
    "لاطم": 1,
    "متلاطم": 1,
    "ملاطم": 1,
    "ملتطم": 1,
    "لواعب": 1,
    "متلاعب": 1,
    "لاعق": 1,
    "لواغق": 1,
    "لعاق": 1,
    "ملعق": 1,
    "متلعلع": 1,
    "لواعن": 1,
    "لواغي": 1,
    "ملاعن": 1,
    "ملعن": 1,
    "لاغد": 1,
    "لاغز": 1,
    "لاغط": 1,
    "لاغم": 1,
    "متلغم": 1,
    "لاغي": 1,
    "لافت": 1,
    "لوافت": 1,
    "متلفت": 1,
    "مستلفت": 1,
    "لافظ": 1,
    "متلفظ": 1,
    "متلفع": 1,
    "لافع": 1,
    "متلفف": 1,
    "ملفف": 1,
    "لافق": 1,
    "متلفلف": 1,
    "ملفلف": 1,
    "متلقب": 1,
    "لاقح": 1,
    "متلقح": 1,
    "لواقط": 1,
    "متلقف": 1,
    "ملتقف": 1,
    "متلقم": 1,
    "ملتقم": 1,
    "متلقن": 1,
    "ملاقي": 1,
    "لاكز": 1,
    "متلاكز": 1,
    "لاكع": 1,
    "لاكم": 1,
    "متلاكم": 1,
    "متلاكن": 1,
    "لامج": 1,
    "متلمج": 1,
    "لامح": 1,
    "لوامح": 1,
    "لامز": 1,
    "لوامس": 1,
    "لماس": 1,
    "متلامع": 1,
    "متلمق": 1,
    "متلملم": 1,
    "ململم": 1,
    "متلهب": 1,
    "ملهب": 1,
    "لاهج": 1,
    "لواهج": 1,
    "لهاج": 1,
    "لاهف": 1,
    "لواهف": 1,
    "لهاف": 1,
    "متلوث": 1,
    "متلوح": 1,
    "متلاوم": 1,
    "متلوم": 1,
    "متلوي": 1,
    "متليث": 1,
    "مليف": 1,
    "لوائق": 1,
    "متلين": 1,
    "متشائل": 1,
    "ماتن": 1,
    "ماته": 1,
    "متمثل": 1,
    "ماجد": 1,
    "مواجد": 1,
    "مجاد": 1,
    "مجدة": 1,
    "متماجد": 1,
    "متمجد": 1,
    "متمجن": 1,
    "ماحص": 1,
    "متمحص": 1,
    "ممحص": 1,
    "ماحض": 1,
    "ماحق": 1,
    "مواحق": 1,
    "منمحق": 1,
    "مواحك": 1,
    "مواحل": 1,
    "ماخض": 1,
    "متمخض": 1,
    "ممتخض": 1,
    "متمخط": 1,
    "موادح": 1,
    "ممتدح": 1,
    "متمدرس": 1,
    "مادق": 1,
    "متمادي": 1,
    "ماذق": 1,
    "متمذهب": 1,
    "ممرر": 1,
    "متمترس": 1,
    "ممترس": 1,
    "متمرغ": 1,
    "مارغ": 1,
    "متمرفق": 1,
    "ممرق": 1,
    "موارق": 1,
    "متمركز": 1,
    "مارن": 1,
    "موارن": 1,
    "مازج": 1,
    "متمازج": 1,
    "ممتزج": 1,
    "متمازح": 1,
    "مازق": 1,
    "مواسح": 1,
    "ماسخ": 1,
    "ماسد": 1,
    "مسسة": 1,
    "مواسك": 1,
    "متمسكن": 1,
    "متمسلم": 1,
    "ماشج": 1,
    "مواشط": 1,
    "مشطة": 1,
    "ماشق": 1,
    "ممتشق": 1,
    "مشنع": 1,
    "ماشي": 1,
    "مواشي": 1,
    "ماصل": 1,
    "متمضمض": 1,
    "ممضمض": 1,
    "مضاة": 1,
    "مواضي": 1,
    "مواطر": 1,
    "متمطر": 1,
    "متمطط": 1,
    "ممعجم": 1,
    "ماعد": 1,
    "ماعك": 1,
    "متمعن": 1,
    "متمغرب": 1,
    "ماغس": 1,
    "ممفصل": 1,
    "متمقت": 1,
    "ممتقع": 1,
    "ماقل": 1,
    "ماكث": 1,
    "متمكث": 1,
    "ممكث": 1,
    "متماكر": 1,
    "مالذ": 1,
    "مالس": 1,
    "متملس": 1,
    "مالص": 1,
    "ممالق": 1,
    "مالق": 1,
    "موالك": 1,
    "متمالك": 1,
    "متملك": 1,
    "لواهي": 1,
    "ممنح": 1,
    "متمندل": 1,
    "متمنطق": 1,
    "مواكر": 1,
    "متمنع": 1,
    "ممنع": 1,
    "ممهد": 1,
    "ماهد": 1,
    "متمهد": 1,
    "مهرة": 1,
    "مواهر": 1,
    "مهار": 1,
    "متمهر": 1,
    "متمهل": 1,
    "مستمهل": 1,
    "ممتهن": 1,
    "ماهن": 1,
    "متماوت": 1,
    "موائت": 1,
    "موائج": 1,
    "مموضع": 1,
    "أندية": 1,
    "متمون": 1,
    "مموه": 1,
    "متموه": 1,
    "أمجاد": 1,
    "متمايز": 1,
    "متميس": 1,
    "موائع": 1,
    "متميع": 1,
    "مميع": 1,
    "موائل": 1,
    "متميل": 1,
    "نائي": 1,
    "نآة": 1,
    "نوائي": 1,
    "نابت": 1,
    "نوابت": 1,
    "نابج": 1,
    "نابح": 1,
    "نوابي": 1,
    "نوابح": 1,
    "نبحة": 1,
    "متنابذ": 1,
    "نابر": 1,
    "متنابز": 1,
    "نابش": 1,
    "نوابض": 1,
    "نباض": 1,
    "نابع": 1,
    "نوابع": 1,
    "نوابل": 1,
    "نبلة": 1,
    "متنبل": 1,
    "نابي": 1,
    "نواتج": 1,
    "ناتح": 1,
    "ناتر": 1,
    "ناتش": 1,
    "متنتف": 1,
    "نواثر": 1,
    "نثرة": 1,
    "متنثر": 1,
    "منجب": 1,
    "مناجب": 1,
    "ناجب": 1,
    "نواجح": 1,
    "نواجم": 1,
    "نجحة": 1,
    "منجح": 1,
    "متنجد": 1,
    "مستنجد": 1,
    "ناجد": 1,
    "ناجر": 1,
    "نواجز": 1,
    "ناجس": 1,
    "نواجس": 1,
    "متنجس": 1,
    "منجس": 1,
    "نواجع": 1,
    "ناجف": 1,
    "ناجل": 1,
    "ناجم": 1,
    "متنجم": 1,
    "مناجي": 1,
    "نواجي": 1,
    "منجي": 1,
    "منتحب": 1,
    "ناحب": 1,
    "ناحت": 1,
    "ناحر": 1,
    "نواحر": 1,
    "نحار": 1,
    "نحرة": 1,
    "نواحل": 1,
    "متناحر": 1,
    "ناحس": 1,
    "ناحط": 1,
    "ناحم": 1,
    "متنحنح": 1,
    "منحنح": 1,
    "ناحي": 1,
    "نواحي": 1,
    "نواخب": 1,
    "نخاب": 1,
    "نخال": 1,
    "نواخر": 1,
    "نخار": 1,
    "ناخز": 1,
    "ناخس": 1,
    "نواخس": 1,
    "نخاس": 1,
    "نخسة": 1,
    "ناخط": 1,
    "ناخع": 1,
    "ناخل": 1,
    "نواخل": 1,
    "نوادل": 1,
    "نخلة": 1,
    "ناخم": 1,
    "نادب": 1,
    "نداب": 1,
    "نادف": 1,
    "نزحة": 1,
    "ندال": 1,
    "ندلة": 1,
    "نوادم": 1,
    "ندمة": 1,
    "متنادم": 1,
    "ناده": 1,
    "نوازح": 1,
    "نزاح": 1,
    "نازر": 1,
    "نازغ": 1,
    "نوازف": 1,
    "نزاف": 1,
    "نواسخ": 1,
    "متنزل": 1,
    "نازه": 1,
    "ناسئ": 1,
    "نسأة": 1,
    "نواشئ": 1,
    "متنسب": 1,
    "ناسج": 1,
    "ناسح": 1,
    "متناسخ": 1,
    "ناسغ": 1,
    "نواسغ": 1,
    "نساغ": 1,
    "نسغة": 1,
    "نواسف": 1,
    "نسفة": 1,
    "متنسق": 1,
    "ناسق": 1,
    "نواسك": 1,
    "بسكة": 1,
    "متنسك": 1,
    "ناسل": 1,
    "نواسل": 1,
    "نسال": 1,
    "متناسل": 1,
    "منتسل": 1,
    "ناسم": 1,
    "نواسم": 1,
    "ناشب": 1,
    "نسلة": 1,
    "متنشب": 1,
    "نواشد": 1,
    "نشاد": 1,
    "نواشر": 1,
    "نواشف": 1,
    "نشزة": 1,
    "متنشر": 1,
    "منشر": 1,
    "نشابة": 1,
    "نواشط": 1,
    "متنشط": 1,
    "منشف": 1,
    "منتشل": 1,
    "منشل": 1,
    "ناشل": 1,
    "متنصب": 1,
    "منصت": 1,
    "ناصت": 1,
    "نواصح": 1,
    "نصحة": 1,
    "نواصر": 1,
    "منتصح": 1,
    "متناصر": 1,
    "متنصر": 1,
    "مستنصر": 1,
    "نواصع": 1,
    "متناصف": 1,
    "نواصف": 1,
    "متنصف": 1,
    "مناصف": 1,
    "متنصل": 1,
    "نواضب": 1,
    "نواضح": 1,
    "نضاح": 1,
    "نضحة": 1,
    "متنضح": 1,
    "ناضخ": 1,
    "متنضد": 1,
    "ناضد": 1,
    "نواضر": 1,
    "منضر": 1,
    "متناطح": 1,
    "متنطع": 1,
    "ناطع": 1,
    "ناطف": 1,
    "نواطف": 1,
    "نواطق": 1,
    "نطقة": 1,
    "متنطق": 1,
    "ناطل": 1,
    "متنظف": 1,
    "نظمة": 1,
    "متناظم": 1,
    "متنظم": 1,
    "ناعب": 1,
    "نواعب": 1,
    "ناعت": 1,
    "نواعم": 1,
    "متناعت": 1,
    "منعت": 1,
    "ناعر": 1,
    "نواعس": 1,
    "منتعش": 1,
    "مستنعش": 1,
    "ناعش": 1,
    "نعقة": 1,
    "نعاق": 1,
    "نواعق": 1,
    "متنعل": 1,
    "منتعل": 1,
    "متناعم": 1,
    "متنعم": 1,
    "ناعي": 1,
    "نعاة": 1,
    "نواعي": 1,
    "ناغب": 1,
    "ناغز": 1,
    "متنغص": 1,
    "ناغص": 1,
    "متنغم": 1,
    "ناغم": 1,
    "نوافيث": 1,
    "نوافث": 1,
    "منافح": 1,
    "نوائم": 1,
    "نافح": 1,
    "مستنفد": 1,
    "نفذة": 1,
    "مستنفر": 1,
    "منتفش": 1,
    "متنفش": 1,
    "منفش": 1,
    "نافش": 1,
    "نافض": 1,
    "نوافض": 1,
    "نافط": 1,
    "نوافط": 1,
    "متنفط": 1,
    "نوافع": 1,
    "نفعة": 1,
    "نوافق": 1,
    "متنفل": 1,
    "نافل": 1,
    "منافي": 1,
    "نافي": 1,
    "نوافي": 1,
    "متنقب": 1,
    "ناقب": 1,
    "ناقح": 1,
    "نواقد": 1,
    "ناقذ": 1,
    "ناقر": 1,
    "نواقر": 1,
    "ناقس": 1,
    "نواقص": 1,
    "نقاص": 1,
    "نقصة": 1,
    "متنقص": 1,
    "متنقض": 1,
    "منتقض": 1,
    "ناقط": 1,
    "نواقع": 1,
    "نقعة": 1,
    "ناقف": 1,
    "متناقل": 1,
    "نواقم": 1,
    "نقام": 1,
    "منتقي": 1,
    "متنكب": 1,
    "ناكب": 1,
    "ناكح": 1,
    "نواكح": 1,
    "نكحة": 1,
    "متناكح": 1,
    "منكح": 1,
    "ناكد": 1,
    "نواكد": 1,
    "منكد": 1,
    "متنكد": 1,
    "نواكر": 1,
    "نكار": 1,
    "ناكز": 1,
    "ناكس": 1,
    "نواكس": 1,
    "نكاس": 1,
    "متنكس": 1,
    "منتكص": 1,
    "ناكص": 1,
    "مستنكف": 1,
    "منكل": 1,
    "ناكل": 1,
    "ناكه": 1,
    "متنمر": 1,
    "نامس": 1,
    "نامش": 1,
    "متنمل": 1,
    "منمنم": 1,
    "ناهب": 1,
    "منتهج": 1,
    "ناهج": 1,
    "ناهد": 1,
    "نهداء": 1,
    "نهاد": 1,
    "نواهد": 1,
    "ناهر": 1,
    "نواهر": 1,
    "نهرة": 1,
    "منتهر": 1,
    "ناهز": 1,
    "نواهز": 1,
    "نهاز": 1,
    "نهزة": 1,
    "منتهز": 1,
    "متناهش": 1,
    "ناهش": 1,
    "نهض": 1,
    "نواهض": 1,
    "نهاض": 1,
    "نهضة": 1,
    "متناهض": 1,
    "مستنهض": 1,
    "منتهض": 1,
    "منهض": 1,
    "ناهق": 1,
    "نواهق": 1,
    "ناهك": 1,
    "ناهل": 1,
    "نهل": 1,
    "نهول": 1,
    "نهلة": 1,
    "نواهل": 1,
    "منهل": 1,
    "ناهم": 1,
    "ناهي": 1,
    "نهاة": 1,
    "نواهي": 1,
    "مناوئ": 1,
    "نائح": 1,
    "نوائل": 1,
    "متناوم": 1,
    "متنوم": 1,
    "منوه": 1,
    "ناوي": 1,
    "نواء": 1,
    "هابر": 1,
    "هابش": 1,
    "هوابط": 1,
    "هباط": 1,
    "هبطة": 1,
    "منهبط": 1,
    "مهبط": 1,
    "هابل": 1,
    "هوابل": 1,
    "هبال": 1,
    "هبلة": 1,
    "مهاتر": 1,
    "متهاتر": 1,
    "هاتر": 1,
    "هتفة": 1,
    "متهتك": 1,
    "منهتك": 1,
    "هاتك": 1,
    "هاتم": 1,
    "متهجد": 1,
    "هاجد": 1,
    "هجود": 1,
    "هجد": 1,
    "هواجد": 1,
    "هجاد": 1,
    "هجدة": 1,
    "هواجر": 1,
    "هجار": 1,
    "متهجر": 1,
    "هاجس": 1,
    "هواجس": 1,
    "هاجع": 1,
    "هجوع": 1,
    "هجع": 1,
    "هواجع": 1,
    "هجاع": 1,
    "هجعة": 1,
    "متهاجم": 1,
    "هاجي": 1,
    "هجاة": 1,
    "هواجي": 1,
    "هوادئ": 1,
    "متهدب": 1,
    "هادب": 1,
    "متهدج": 1,
    "هواد": 1,
    "هداد": 1,
    "هددة": 1,
    "متهدد": 1,
    "هادر": 1,
    "هوادر": 1,
    "هدار": 1,
    "هدرة": 1,
    "مهدر": 1,
    "هوادف": 1,
    "هداف": 1,
    "هدفة": 1,
    "هادل": 1,
    "هوادل": 1,
    "متهدم": 1,
    "هوادم": 1,
    "هدمة": 1,
    "منهدم": 1,
    "مهادن": 1,
    "متهادن": 1,
    "هادن": 1,
    "مهتدي": 1,
    "هداة": 1,
    "هوادي": 1,
    "متهذب": 1,
    "هاذب": 1,
    "هاذر": 1,
    "هواذر": 1,
    "مهترئ": 1,
    "هززة": 1,
    "هوارب": 1,
    "هراب": 1,
    "هربة": 1,
    "متهارب": 1,
    "هارج": 1,
    "هارد": 1,
    "هارس": 1,
    "متهرطق": 1,
    "هارع": 1,
    "هارف": 1,
    "هارق": 1,
    "مستهزئ": 1,
    "هوازئ": 1,
    "هزاء": 1,
    "هزأة": 1,
    "متهزج": 1,
    "هازر": 1,
    "هاز": 1,
    "هواز": 1,
    "هزاز": 1,
    "هشم": 1,
    "متهزز": 1,
    "متهزع": 1,
    "هازع": 1,
    "هازل": 1,
    "هوازل": 1,
    "هزال": 1,
    "هزلة": 1,
    "منهزم": 1,
    "هازم": 1,
    "هاشم": 1,
    "متهشم": 1,
    "منهشم": 1,
    "مهشم": 1,
    "هاصر": 1,
    "هاضب": 1,
    "هاضل": 1,
    "هاضم": 1,
    "هواضم": 1,
    "هضام": 1,
    "هضمة": 1,
    "منهضم": 1,
    "هاطل": 1,
    "هواطل": 1,
    "همدة": 1,
    "هلكى": 1,
    "هوالك": 1,
    "هلاك": 1,
    "هلكة": 1,
    "متهطل": 1,
    "متهافت": 1,
    "هافت": 1,
    "متهفهف": 1,
    "هواشم": 1,
    "هشام": 1,
    "هشمة": 1,
    "متهالك": 1,
    "منهلك": 1,
    "مهتلك": 1,
    "متهلل": 1,
    "متهلهل": 1,
    "هوامد": 1,
    "هماد": 1,
    "همسة": 1,
    "مهمد": 1,
    "هامر": 1,
    "هوامر": 1,
    "هامز": 1,
    "هوامز": 1,
    "متهامس": 1,
    "هامس": 1,
    "هوامس": 1,
    "هماس": 1,
    "هوامش": 1,
    "متهامش": 1,
    "هامع": 1,
    "هوامع": 1,
    "هامك": 1,
    "هوامل": 1,
    "": 1,
    "هوانئ": 1,
    "هناء": 1,
    "هنأة": 1,
    "مهند": 1,
    "متهوج": 1,
    "هائد": 1,
    "هوائد": 1,
    "متهود": 1,
    "مهود": 1,
    "متهوس": 1,
    "متهوش": 1,
    "هوائل": 1,
    "متهاون": 1,
    "مهون": 1,
    "هائب": 1,
    "هوائب": 1,
    "هوائج": 1,
    "متهايج": 1,
    "متهيج": 1,
    "هائف": 1,
    "هوائف": 1,
    "مهيكل": 1,
    "مستهيم": 1,
    "هيم": 1,
    "هيام": 1,
    "هوائم": 1,
    "متهيم": 1,
    "واظب": 1,
    "وظاب": 1,
    "وظبة": 1,
    "وبلة": 1,
    "واتد": 1,
    "واثب": 1,
    "متواثب": 1,
    "موثف": 1,
    "وثقة": 1,
    "متوثق": 1,
    "واثن": 1,
    "متوجب": 1,
    "وجاد": 1,
    "وجدة": 1,
    "متواجد": 1,
    "واجز": 1,
    "متوجس": 1,
    "موجس": 1,
    "متوجع": 1,
    "وجاف": 1,
    "وجفة": 1,
    "واجل": 1,
    "وجام": 1,
    "وجمة": 1,
    "متواجه": 1,
    "متوحم": 1,
    "موحم": 1,
    "موحي": 1,
    "ودعة": 1,
    "متوادع": 1,
    "متودع": 1,
    "متوارد": 1,
    "متورع": 1,
    "وراف": 1,
    "ورفة": 1,
    "وسطة": 1,
    "متورك": 1,
    "متواري": 1,
    "متوزر": 1,
    "موزر": 1,
    "وزعة": 1,
    "وزاع": 1,
    "متوزع": 1,
    "متسخ": 1,
    "متوسخ": 1,
    "متوسد": 1,
    "واسط": 1,
    "وساط": 1,
    "واسق": 1,
    "متوسم": 1,
    "مواسي": 1,
    "واشج": 1,
    "متوشح": 1,
    "موشك": 1,
    "موشم": 1,
    "واشي": 1,
    "موصد": 1,
    "متواصف": 1,
    "مواصل": 1,
    "موصي": 1,
    "وضحة": 1,
    "متوضح": 1,
    "مستوضح": 1,
    "وضاع": 1,
    "متواطئ": 1,
    "متوطد": 1,
    "متوظف": 1,
    "متواعد": 1,
    "مستوعد": 1,
    "واعر": 1,
    "موعر": 1,
    "واعز": 1,
    "وفدة": 1,
    "متعظ": 1,
    "موعك": 1,
    "واعك": 1,
    "وعاة": 1,
    "واغر": 1,
    "وغلة": 1,
    "متوغل": 1,
    "متوافد": 1,
    "مستوفد": 1,
    "متوفي": 1,
    "متوقر": 1,
    "وقاة": 1,
    "متكئ": 1,
    "متوكئ": 1,
    "موكس": 1,
    "واكف": 1,
    "وكال": 1,
    "وكلة": 1,
    "متوكل": 1,
    "متواكل": 1,
    "واكن": 1,
    "متولد": 1,
    "متوالد": 1,
    "مستولد": 1,
    "متولع": 1,
    "ولهة": 1,
    "مولول": 1,
    "متولول": 1,
    "مستولي": 1,
    "متوالي": 1,
    "والي": 1,
    "وامض": 1,
    "وهاة": 1,
    "واني": 1,
    "واهب": 1,
    "وهاب": 1,
    "وهبة": 1,
    "متواهب": 1,
    "متوهم": 1,
    "موهم": 1,
    "وهان": 1,
    "وهنة": 1,
    "يفعة": 1,
    "يوائس": 1,
    "يوابس": 1,
    "متيتم": 1,
    "ياتن": 1,
    "ياسر": 1,
    "أيسار": 1,
    "يواسر": 1,
    "يافع": 1,
    "يوانع": 1,
    "يفعان": 1,
    "يوافر": 1,
    "يفاع": 1,
    "ميقظ": 1,
    "مستيقن": 1,
    "متيمم": 1,
    "يامن": 1,
    "يوامن": 1,
    "يمان": 1,
    "متيامن": 1,
    "متيمن": 1,
    "عواشر": 1,
    "إفرنج": 1,
    "أهواز": 1,
    "أوباش": 1,
    "برائل": 1,
    "أكال": 1,
    "أكتوبر": 1,
    "إكسير": 1,
    "إكليروس": 1,
    "إلق": 1,
    "ألماس": 1,
    "أمونيوم": 1,
    "أناناس": 1,
    "أندلس": 1,
    "أنقليس": 1,
    "أنوق": 1,
    "إهاب": 1,
    "أوج": 1,
    "أورانيوم": 1,
    "أورثوذكس": 1,
    "أيار": 1,
    "أيدز": 1,
    "أيلول": 1,
    "باريوم": 1,
    "بجيش": 1,
    "بديه": 1,
    "برجد": 1,
    "برطال": 1,
    "بشام": 1,
    "بطير": 1,
    "بغام": 1,
    "بغرير": 1,
    "بنكام": 1,
    "بهار": 1,
    "بهرج": 1,
    "بوتاسيوم": 1,
    "بيرم": 1,
    "تفلق": 1,
    "تيفوئيد": 1,
    "ثدام": 1,
    "ثيل": 1,
    "جساد": 1,
    "جودو": 1,
    "حاقول": 1,
    "إحبال": 1,
    "محرار": 1,
    "حساف": 1,
    "حشيف": 1,
    "حاطوم": 1,
    "حفف": 1,
    "حقال": 1,
    "حملاج": 1,
    "حنبل": 1,
    "حناط": 1,
    "حنوط": 1,
    "حو": 1,
    "حيدر": 1,
    "خبار": 1,
    "خدار": 1,
    "خرشاف": 1,
    "خزرج": 1,
    "خزاع": 1,
    "خزعبيل": 1,
    "تخليف": 1,
    "خناس": 1,
    "مخوض": 1,
    "خيت": 1,
    "خيلع": 1,
    "دباج": 1,
    "دراما": 1,
    "درور": 1,
    "دعاع": 1,
    "دفتريا": 1,
    "دفل": 1,
    "دقوق": 1,
    "دكتاتور": 1,
    "دلبوث": 1,
    "ديلم": 1,
    "دمال": 1,
    "دهس": 1,
    "دهش": 1,
    "دهق": 1,
    "ديناصور": 1,
    "ديناميك": 1,
    "ذؤاف": 1,
    "ذفاف": 1,
    "ذولق": 1,
    "ذؤب": 1,
    "رؤال": 1,
    "رؤام": 1,
    "ربيد": 1,
    "ربيض": 1,
    "ربيق": 1,
    "تربل": 1,
    "أرتج": 1,
    "مرجاس": 1,
    "رحاق": 1,
    "رخف": 1,
    "رساغ": 1,
    "رضاض": 1,
    "رطام": 1,
    "تركيع": 1,
    "رهبل": 1,
    "روماتيزم": 1,
    "ريال": 1,
    "زبن": 1,
    "زريع": 1,
    "مزعم": 1,
    "زك": 1,
    "زلاج": 1,
    "زمخر": 1,
    "زمهرير": 1,
    "زها": 1,
    "سدير": 1,
    "سداس": 1,
    "سديس": 1,
    "سرعوب": 1,
    "سرمد": 1,
    "سرمق": 1,
    "سريل": 1,
    "سفار": 1,
    "سافن": 1,
    "سلبوت": 1,
    "سها": 1,
    "سهيل": 1,
    "سيدا": 1,
    "سيناريو": 1,
    "شبام": 1,
    "شزر": 1,
    "شعف": 1,
    "شقراق": 1,
    "مشكاك": 1,
    "شليخ": 1,
    "شهاق": 1,
    "شوار": 1,
    "شياط": 1,
    "صبير": 1,
    "صفصاف": 1,
    "صفيف": 1,
    "صناج": 1,
    "صهر": 1,
    "صومال": 1,
    "طفطاف": 1,
    "طلم": 1,
    "طياف": 1,
    "عاسوق": 1,
    "عبط": 1,
    "إعتاق": 1,
    "عرار": 1,
    "عرعر": 1,
    "عقاف": 1,
    "عهر": 1,
    "عواذ": 1,
    "عياط": 1,
    "غرانيت": 1,
    "غرقئ": 1,
    "غشت": 1,
    "غضار": 1,
    "فالوذ": 1,
    "فتوش": 1,
    "فرير": 1,
    "فرزدق": 1,
    "فراس": 1,
    "فسفاط": 1,
    "فسفور": 1,
    "فصيد": 1,
    "فقاس": 1,
    "فقاع": 1,
    "قاقوم": 1,
    "قائمقام": 1,
    "قداد": 1,
    "مقرأ": 1,
    "قراصيا": 1,
    "قشار": 1,
    "قنزع": 1,
    "قنغر": 1,
    "قواع": 1,
    "قيق": 1,
    "كاكاو": 1,
    "كاليتوس": 1,
    "كروميت": 1,
    "كلوروفورم": 1,
    "كهربا": 1,
    "كهرطيس": 1,
    "كهنوت": 1,
    "كوبلت": 1,
    "كولوم": 1,
    "كويت": 1,
    "كيلوس": 1,
    "لاهوت": 1,
    "لحاظ": 1,
    "لاصوق": 1,
    "لهاب": 1,
    "مازور": 1,
    "مؤق": 1,
    "مخيخ": 1,
    "مرددوش": 1,
    "مريس": 1,
    "مضير": 1,
    "معدنوس": 1,
    "مفاعل": 1,
    "مقراج": 1,
    "مكروسكوب": 1,
    "مكاك": 1,
    "مهل": 1,
    "ممواج": 1,
    "ميم": 1,
    "ميموزا": 1,
    "نافور": 1,
    "نيفق": 1,
    "نورس": 1,
    "هباب": 1,
    "هتر": 1,
    "هتك": 1,
    "هجيع": 1,
    "هداب": 1,
    "هدال": 1,
    "إهذار": 1,
    "هريس": 1,
    "هزع": 1,
    "هستيريا": 1,
    "هلام": 1,
    "هلهل": 1,
    "هليكوبتر": 1,
    "هليوم": 1,
    "همس": 1,
    "هند": 1,
    "هندبا": 1,
    "هندام": 1,
    "هوام": 1,
    "هوينا": 1,
    "هيول": 1,
    "وجور": 1,
    "وحر": 1,
    "ورس": 1,
    "وسوط": 1,
    "ميقاف": 1,
    "يانصيب": 1,
    "تيتم": 1,
    "مياوم": 1,
    "يونيسكو": 1,
    "يونيسيف": 1,
    "يونيه": 1,
    "باسك": 1,
    "بانجو": 1,
    "بانوراما": 1,
    "برازيل": 1,
    "تبرقش": 1,
    "تبرقع": 1,
    "أميركا": 1,
    "مقفاة": 1,
    "مبتوت": 1,
    "ميامين": 1,
    "مناديب": 1,
    "أرعاء": 1,
    "مؤاتي": 1,
    "متابيع": 1,
    "مناكير": 1,
    "مساليخ": 1,
    "مغاليل": 1,
    "مثؤوب": 1,
    "مبتسر": 1,
    "مبتغاة": 1,
    "مبتلاة": 1,
    "مترع": 1,
    "متكأة": 1,
    "مؤداة": 1,
    "مرتجع": 1,
    "مرتقع": 1,
    "مرتقاة": 1,
    "مراسيل": 1,
    "مرهف": 1,
    "مستباح": 1,
    "مستثناة": 1,
    "مستجاب": 1,
    "مستدار": 1,
    "مستدل": 1,
    "مستشفاة": 1,
    "مستعان": 1,
    "مستعجم": 1,
    "مستمطر": 1,
    "مستنجع": 1,
    "مستولاة": 1,
    "مستواة": 1,
    "مشتكاة": 1,
    "مشتهاة": 1,
    "مصطفاة": 1,
    "مضناة": 1,
    "معطاة": 1,
    "معفاة": 1,
    "مغتال": 1,
    "مغماة": 1,
    "مقتضاة": 1,
    "مقفل": 1,
    "مآكيل": 1,
    "ملتاع": 1,
    "ملقاة": 1,
    "منتهاة": 1,
    "منساب": 1,
    "مهرق": 1,
    "مهارق": 1,
    "مواجز": 1,
    "موحاة": 1,
    "موصاة": 1,
    "مومأة": 1,
    "مبثور": 1,
    "مبرعم": 1,
    "مبري": 1,
    "مبغاة": 1,
    "مباغي": 1,
    "مبهور": 1,
    "متبناة": 1,
    "متنصح": 1,
    "متوفاة": 1,
    "مثرود": 1,
    "مثناة": 1,
    "مجبول": 1,
    "مجاذيب": 1,
    "مجذور": 1,
    "مجمر": 1,
    "مجني": 1,
    "مجهود": 1,
    "مجاهيل": 1,
    "محاسيب": 1,
    "محشاة": 1,
    "محاليل": 1,
    "محلوج": 1,
    "مخبوء": 1,
    "مخبوءة": 1,
    "مخابيل": 1,
    "مخاذيل": 1,
    "مخصور": 1,
    "مخضب": 1,
    "مخطوف": 1,
    "مدبج": 1,
    "مدبوغ": 1,
    "مدلع": 1,
    "مدهوش": 1,
    "مذلق": 1,
    "مذمم": 1,
    "مرجوع": 1,
    "مراجيع": 1,
    "مردوم": 1,
    "مرزأ": 1,
    "مرزأة": 1,
    "مرعبل": 1,
    "مرموز": 1,
    "مرمش": 1,
    "مرهوب": 1,
    "مزكوم": 1,
    "مسترة": 1,
    "مسحوت": 1,
    "مساحيق": 1,
    "مسطول": 1,
    "مسعاة": 1,
    "مساعي": 1,
    "مسفوح": 1,
    "مسفوك": 1,
    "مسكوت": 1,
    "مساليب": 1,
    "مسهد": 1,
    "مشابيب": 1,
    "مشاريب": 1,
    "مشطور": 1,
    "مشاغيل": 1,
    "مشكول": 1,
    "مشنج": 1,
    "مشاهير": 1,
    "مصعر": 1,
    "مضرس": 1,
    "مضروس": 1,
    "مطروف": 1,
    "مطموس": 1,
    "مطهم": 1,
    "معتقة": 1,
    "معرى": 1,
    "معراة": 1,
    "معازيل": 1,
    "معصوب": 1,
    "معفر": 1,
    "معلى": 1,
    "معلاة": 1,
    "معناة": 1,
    "مغذاة": 1,
    "مغارير": 1,
    "مغشي": 1,
    "مذهوب": 1,
    "مغلوط": 1,
    "مغلوق": 1,
    "مغمود": 1,
    "مغموز": 1,
    "مفاهيم": 1,
    "مفلوق": 1,
    "مقبور": 1,
    "مقدود": 1,
    "مقدد": 1,
    "مقرور": 1,
    "مقروح": 1,
    "مقشب": 1,
    "مقفع": 1,
    "مقواة": 1,
    "مكاسير": 1,
    "مكموم": 1,
    "مكمود": 1,
    "مكناة": 1,
    "ملسوع": 1,
    "ملغوم": 1,
    "ملوز": 1,
    "ملولب": 1,
    "ممخوض": 1,
    "ممشوط": 1,
    "مملوءة": 1,
    "مناحيس": 1,
    "منسوق": 1,
    "منفوض": 1,
    "منقوب": 1,
    "منكوش": 1,
    "منهوك": 1,
    "منهي": 1,
    "مهيض": 1,
    "مهول": 1,
    "مهبول": 1,
    "مهابيل": 1,
    "مهجر": 1,
    "مهدي": 1,
    "مهزول": 1,
    "مهفهف": 1,
    "مهموس": 1,
    "مهمش": 1,
    "موبوءة": 1,
    "موشى": 1,
    "موشاة": 1,
    "موصوم": 1,
    "موطأة": 1,
    "موله": 1,
    "موهوم": 1,
    "ميمم": 1,
    "الهمزة": 1,
    "آ": 1,
    "الآب": 1,
    "الآبنوس - الآبنوس": 1,
    "الآبنوسية": 1,
    "الآح": 1,
    "الآذريون": 1,
    "الآنسون": 1,
    "الآنك": 1,
    "الآيين": 1,
    "أبأه": 1,
    "الأباءة": 1,
    "ائتب": 1,
    "استأب": 1,
    "تأبب به": 1,
    "الأباب": 1,
    "الأبابة": 1,
    "أبجد": 1,
    "تأبد": 1,
    "الآبدة": 1,
    "المؤبد": 1,
    "ائتبر": 1,
    "تأبر": 1,
    "الإبارة": 1,
    "الأبار": 1,
    "الإبرة المغنيطيسية": 1,
    "بيت الإبرة": 1,
    "الأبور": 1,
    "المأبر": 1,
    "المئبر": 1,
    "المئبرة": 1,
    "الأبرا": 1,
    "الإبريز": 1,
    "الإبريسم": 1,
    "إبريل": 1,
    "أبز": 1,
    "الأبزن": 1,
    "أبسه": 1,
    "أبيس": 1,
    "أبش": 1,
    "أبشه": 1,
    "تأبش": 1,
    "الأباشة": 1,
    "أبص": 1,
    "أبض": 1,
    "تأبض": 1,
    "الإباض": 1,
    "الإباضية": 1,
    "الأبوض": 1,
    "المأبض": 1,
    "تأبط": 1,
    "الإباط": 1,
    "تأبق": 1,
    "أبلت": 1,
    "آبل": 1,
    "ائتبل": 1,
    "تأبلت": 1,
    "الأبابيل": 1,
    "الإبالة": 1,
    "الأبلة": 1,
    "الأبيل": 1,
    "الأبيلة": 1,
    "المأبلة": 1,
    "الإبليز": 1,
    "تأبن": 1,
    "الأبنة": 1,
    "المأبون": 1,
    "تأبه": 1,
    "الأبهة": 1,
    "تأبى": 1,
    "استأبى": 1,
    "الأبا": 1,
    "الأبوان": 1,
    "آبى": 1,
    "الإبية": 1,
    "المأباة": 1,
    "أتب": 1,
    "تأتبت": 1,
    "الإتب": 1,
    "المؤتب": 1,
    "أت": 1,
    "الإتاد": 1,
    "الأترج": 1,
    "أتم": 1,
    "الأتم": 1,
    "المأتم": 1,
    "أتن": 1,
    "آتنت": 1,
    "استأتن": 1,
    "الأتان": 1,
    "أتا": 1,
    "الإتاء": 1,
    "الإتاوة": 1,
    "الأتو": 1,
    "أتي": 1,
    "تأتى": 1,
    "استأتاه": 1,
    "الآتية": 1,
    "الأتي": 1,
    "الميتاء": 1,
    "الأثأب": 1,
    "الأثب": 1,
    "أث": 1,
    "أثثه": 1,
    "تأثث": 1,
    "آثره": 1,
    "ائتثره": 1,
    "الإثار": 1,
    "الأثارة": 1,
    "الإثر": 1,
    "الأثر الأثر": 1,
    "الأثرة": 1,
    "الأثري": 1,
    "وعند الطبيعيين": 1,
    "الإيثارية": 1,
    "التأثرية": 1,
    "المئثرة": 1,
    "أثف": 1,
    "آثف": 1,
    "تأثفت": 1,
    "الأثفية": 1,
    "أثل": 1,
    "تأثل": 1,
    "الأثال": 1,
    "الأثل": 1,
    "الأثلة": 1,
    "آثمه": 1,
    "أثمه": 1,
    "تأثم": 1,
    "الأثام": 1,
    "الإثمد": 1,
    "أثا": 1,
    "آثاه": 1,
    "تآثوا": 1,
    "أجت": 1,
    "آجج": 1,
    "أجج": 1,
    "ائتجت": 1,
    "تأججت": 1,
    "الأجاج": 1,
    "الأجوج": 1,
    "آجده": 1,
    "أجده": 1,
    "الأجد": 1,
    "آجره": 1,
    "ائتجر": 1,
    "الإجارة": 1,
    "والأجر الحقيقي": 1,
    "الأجير": 1,
    "استأجز": 1,
    "الإجاز": 1,
    "الأجزخانة": 1,
    "استأجل": 1,
    "الإجل": 1,
    "الأجيل": 1,
    "المأجل": 1,
    "أجم": 1,
    "أجمه": 1,
    "آجمه": 1,
    "تأجم": 1,
    "الأجم": 1,
    "الأجوم": 1,
    "أجن": 1,
    "الإجانة": 1,
    "المئجنة": 1,
    "الميجنة": 1,
    "أح": 1,
    "أحى": 1,
    "الأحاح": 1,
    "الأحيح": 1,
    "استأحد": 1,
    "أحاد": 1,
    "أحن": 1,
    "آحنه": 1,
    "الإحنة": 1,
    "إخ": 1,
    "الأخ والإخ": 1,
    "الأخيخة": 1,
    "آخذته": 1,
    "آخذه": 1,
    "ائتخذ": 1,
    "اتخذه": 1,
    "استأخذ": 1,
    "الإخاذة": 1,
    "الأخذ والإخذ": 1,
    "الأخذ والأخذ": 1,
    "الإخذة": 1,
    "الأخذة": 1,
    "الأخيذ": 1,
    "الأخيذة": 1,
    "استأخر": 1,
    "الآخري": 1,
    "الأخر": 1,
    "الأخرة": 1,
    "الأخرة والأخرة": 1,
    "الأخروي": 1,
    "المئخار": 1,
    "الإخشيد": 1,
    "آخى": 1,
    "أخى": 1,
    "تآخيا": 1,
    "تأخى": 1,
    "الآخية": 1,
    "دم الأخوين": 1,
    "الأخية": 1,
    "أدب": 1,
    "أدبه": 1,
    "الآدب": 1,
    "الأديب": 1,
    "المأدبة والمأدبة": 1,
    "المؤدب": 1,
    "تأدد": 1,
    "الأدد": 1,
    "الإد": 1,
    "الإدة": 1,
    "الأديد": 1,
    "الأدرة": 1,
    "المأدور": 1,
    "الإدرجين أو الأيدروجين": 1,
    "أدرينالين": 1,
    "اللاأدرية": 1,
    "ائتدم": 1,
    "استأدم": 1,
    "الآدمي": 1,
    "الإدام": 1,
    "الأديم": 1,
    "آدى": 1,
    "تآدى": 1,
    "تأدى": 1,
    "استأداه": 1,
    "الإداوة": 1,
    "إذ ما": 1,
    "استأذنه": 1,
    "الآذن": 1,
    "الأذان": 1,
    "الأذانان": 1,
    "الأذاني": 1,
    "الأذن الأذن": 1,
    "أذن الحمار": 1,
    "آذان الأرنب": 1,
    "آذان الجدي": 1,
    "آذان الدب": 1,
    "آذان الشاة": 1,
    "آذان العنز": 1,
    "آذان الفيل": 1,
    "آذان الحيطان": 1,
    "إذن البريد": 1,
    "الأذنة": 1,
    "الأذينة": 1,
    "المأذون": 1,
    "آذاه": 1,
    "تأذى به": 1,
    "الآذي": 1,
    "الأذاة": 1,
    "الأذية": 1,
    "أربه": 1,
    "أرب": 1,
    "آرب": 1,
    "تأرب": 1,
    "استأرب": 1,
    "الإرب": 1,
    "الأربى": 1,
    "الأربان": 1,
    "الأربة": 1,
    "الأربون": 1,
    "المأرب": 1,
    "المأربة": 1,
    "أرثوذكس": 1,
    "أرث": 1,
    "تأرثت": 1,
    "الإراث": 1,
    "الأرثة": 1,
    "أرج": 1,
    "تأرجت": 1,
    "الأريجة": 1,
    "الإردب": 1,
    "الإردبة": 1,
    "الإردواز": 1,
    "أر": 1,
    "الإرة": 1,
    "الأرير": 1,
    "أرز": 1,
    "الأريز": 1,
    "أرس": 1,
    "آرس": 1,
    "الإرس": 1,
    "الإريس": 1,
    "الأريس": 1,
    "أرش": 1,
    "ائترش": 1,
    "الأرش": 1,
    "أرضت": 1,
    "تأرض": 1,
    "استأرضت": 1,
    "الإراض": 1,
    "الأرضة": 1,
    "أرطت": 1,
    "آرطت": 1,
    "الأرطاة": 1,
    "الأرغول": 1,
    "آرفه": 1,
    "أرف": 1,
    "الأرفة": 1,
    "آرقه": 1,
    "أرقه": 1,
    "ائترق": 1,
    "تأرق": 1,
    "الأرقان": 1,
    "أركت": 1,
    "الأراك": 1,
    "الأركون": 1,
    "أرم": 1,
    "الأرم": 1,
    "الأرم الإرم": 1,
    "الإرم": 1,
    "الأروم والأرومة": 1,
    "الأرمادا": 1,
    "أرنه": 1,
    "أرن": 1,
    "آرنه": 1,
    "الإران": 1,
    "الأرنة": 1,
    "الأرنباني": 1,
    "الأرنبة": 1,
    "الأرندج": 1,
    "أرا النار": 1,
    "ائترى": 1,
    "تأرى": 1,
    "الآري": 1,
    "الأروية": 1,
    "الأري": 1,
    "الأريحي": 1,
    "أزب": 1,
    "تأزبوا": 1,
    "الإزب": 1,
    "المئزاب": 1,
    "أزج": 1,
    "الأزج": 1,
    "تأزح": 1,
    "الأزوح": 1,
    "الأزاذ": 1,
    "أزر": 1,
    "أزره": 1,
    "ائتزر واتزر": 1,
    "تأزر": 1,
    "الإزارة": 1,
    "الأزر": 1,
    "الإزر": 1,
    "المئزر": 1,
    "المئزرة": 1,
    "ائتز": 1,
    "تأزز": 1,
    "الأزز": 1,
    "الأزة": 1,
    "أزف": 1,
    "آزفه": 1,
    "تآزف": 1,
    "الأزف": 1,
    "أزق": 1,
    "تأزق": 1,
    "آزلت": 1,
    "تأزل": 1,
    "الآزل": 1,
    "الأزلي": 1,
    "المأزل": 1,
    "أزم": 1,
    "الآزم": 1,
    "الآزمة": 1,
    "الأزمة الأزمة": 1,
    "المأزم": 1,
    "أزى": 1,
    "أزي": 1,
    "آزى": 1,
    "آزاه": 1,
    "تآزيا": 1,
    "تأزى": 1,
    "الإزاء": 1,
    "الإسباناخ": 1,
    "الإسبيداج": 1,
    "الأسبيرين": 1,
    "الإستاج": 1,
    "الإستار": 1,
    "الإستبرق": 1,
    "الإستيج": 1,
    "أسد": 1,
    "آسد": 1,
    "تأسد": 1,
    "استأسد": 1,
    "داء الأسد": 1,
    "المأسدة": 1,
    "أسره": 1,
    "استأسره": 1,
    "الإسار": 1,
    "الأسر الأسر": 1,
    "الأسرب": 1,
    "الأس الإس الأس": 1,
    "الأس": 1,
    "الأسطبة": 1,
    "الأسطقس": 1,
    "الأسطوانة": 1,
    "وأساطين العلم أو الأدب": 1,
    "آسفه": 1,
    "تأسف عليه": 1,
    "الأسيف": 1,
    "الاسفاناخ": 1,
    "الإسفيداج": 1,
    "الإسفين": 1,
    "الإسقاله": 1,
    "الأسكرجة": 1,
    "الإسكارية": 1,
    "الإسكاف": 1,
    "الإسكلة": 1,
    "الإسكيم": 1,
    "أسل": 1,
    "أسله": 1,
    "تأسل": 1,
    "الأسل": 1,
    "الأسمنت": 1,
    "أسمنجون": 1,
    "آسنته": 1,
    "تأسن": 1,
    "الأسينة": 1,
    "أسا": 1,
    "أسى": 1,
    "آساه": 1,
    "ائتسى": 1,
    "تآسوا": 1,
    "تأسى": 1,
    "الآسي": 1,
    "الآسية": 1,
    "الأسوة": 1,
    "أسيا": 1,
    "أشب": 1,
    "ائتشبوا": 1,
    "تأشب": 1,
    "الأشابة": 1,
    "أشره": 1,
    "الأشارة": 1,
    "الأشرة": 1,
    "المئشار": 1,
    "الإشراس": 1,
    "أش": 1,
    "الأش": 1,
    "الإشفى": 1,
    "تأشن": 1,
    "الإشنان": 1,
    "أصد": 1,
    "آصده": 1,
    "الأصدة": 1,
    "الإصدة": 1,
    "الأصيد": 1,
    "الأصيدة": 1,
    "المؤصد": 1,
    "المؤصدة": 1,
    "أصره": 1,
    "ائتصرت": 1,
    "الآصرة": 1,
    "الإصار": 1,
    "الإصر": 1,
    "الأصير": 1,
    "المأصر": 1,
    "أصت": 1,
    "أصصه": 1,
    "ائتصوا": 1,
    "الأصاص": 1,
    "الأصيص": 1,
    "الأصيصة": 1,
    "الأصطبة": 1,
    "الإصطبل": 1,
    "الإصطفلين": 1,
    "الإصطيل": 1,
    "الأصف": 1,
    "آصل": 1,
    "الأصلة": 1,
    "الأصيلة": 1,
    "أضت": 1,
    "ائتض": 1,
    "الإضاض": 1,
    "الإض": 1,
    "أضم": 1,
    "الإضاء": 1,
    "الأضاة": 1,
    "أطر": 1,
    "انأطر": 1,
    "تأطر": 1,
    "الآطرة": 1,
    "الأطر": 1,
    "الأطرة": 1,
    "الأطير": 1,
    "المأطورة": 1,
    "الأطربون": 1,
    "أط": 1,
    "الإطل": 1,
    "الأيطل": 1,
    "أطم": 1,
    "آطم": 1,
    "تأطم": 1,
    "الأطام": 1,
    "الأطم الأطم": 1,
    "الأطوم": 1,
    "آفخه": 1,
    "اليأفوخ": 1,
    "أفد": 1,
    "استأفد": 1,
    "أفر": 1,
    "استأفر": 1,
    "الأفرة": 1,
    "الأفار": 1,
    "المئفر": 1,
    "الإفرنج والإفرنجة": 1,
    "إفريقية": 1,
    "أفٍ": 1,
    "أفف": 1,
    "تأفف": 1,
    "الأفة": 1,
    "اليأفوف": 1,
    "تأفق": 1,
    "الآفقة": 1,
    "الأفيق": 1,
    "آفكه": 1,
    "ائتفكت": 1,
    "تأفك": 1,
    "الأفكة": 1,
    "المؤتفكات": 1,
    "الأفيل": 1,
    "أفن": 1,
    "تأفن": 1,
    "أفندي": 1,
    "أقته": 1,
    "الأقط": 1,
    "الأقة": 1,
    "الإقليد": 1,
    "الأقنوم": 1,
    "الأقيانس": 1,
    "آكده": 1,
    "أكده": 1,
    "الإكاد": 1,
    "أكر": 1,
    "آكره": 1,
    "الأكرة": 1,
    "الأكار": 1,
    "الأكارة": 1,
    "والأكسيجين": 1,
    "أكف": 1,
    "الإكاف": 1,
    "الأكاف": 1,
    "ائتك": 1,
    "آكله": 1,
    "استأكل": 1,
    "الائتكال": 1,
    "الآكلة": 1,
    "الأكال": 1,
    "الأكل الأكل": 1,
    "الأكولة": 1,
    "الأكيل": 1,
    "الأكيلة": 1,
    "المئكال": 1,
    "المأكل": 1,
    "المأكلة": 1,
    "استأكم": 1,
    "المأكم المأكم": 1,
    "المأكمة": 1,
    "الأكنة": 1,
    "أكونتين": 1,
    "أولى أولاء أولئك": 1,
    "ألب": 1,
    "تألبوا": 1,
    "الإلب": 1,
    "الألبة": 1,
    "المئلب": 1,
    "آلت": 1,
    "الألتة": 1,
    "ألسه": 1,
    "ألس": 1,
    "آلسه": 1,
    "تألس": 1,
    "الألوس": 1,
    "آلف": 1,
    "آلفه": 1,
    "ائتلف": 1,
    "تألف": 1,
    "الإلاف": 1,
    "الإلف": 1,
    "المألف": 1,
    "ائتلق": 1,
    "الإلاق": 1,
    "الألاق": 1,
    "الإلق": 1,
    "الإلقة": 1,
    "الألقة": 1,
    "الألق": 1,
    "الأولق": 1,
    "المئلق": 1,
    "استألك": 1,
    "الألوك": 1,
    "الألوكة": 1,
    "المألك": 1,
    "المألكة والمألكة": 1,
    "الالكترون": 1,
    "ألل": 1,
    "ألله": 1,
    "ائتل": 1,
    "تألل": 1,
    "الإل": 1,
    "الألل": 1,
    "الأليل": 1,
    "الأليلة": 1,
    "المئل": 1,
    "آلمه": 1,
    "الألومة": 1,
    "الألمنيم": 1,
    "ألهه": 1,
    "تأله": 1,
    "الإلاهيات": 1,
    "الإلاهة": 1,
    "الأليهة": 1,
    "ألى": 1,
    "ائتلى": 1,
    "تألى": 1,
    "الإلى الألى": 1,
    "الألا": 1,
    "الألو": 1,
    "الإلوة الألوة": 1,
    "الإلى والألى": 1,
    "الألية": 1,
    "الألي": 1,
    "المئلاة": 1,
    "أمته": 1,
    "أمت": 1,
    "الأمت": 1,
    "أمج": 1,
    "أمح": 1,
    "أمده": 1,
    "تأمد": 1,
    "الآمد": 1,
    "الآمدة": 1,
    "تآمروا": 1,
    "استأمره": 1,
    "الاستئمارة": 1,
    "الأمارة": 1,
    "الإمر": 1,
    "الإمرة": 1,
    "الأمرة": 1,
    "الإمر الأمر": 1,
    "التأمور والتامور": 1,
    "التأمورة والتامورة": 1,
    "التؤمري": 1,
    "التؤمور": 1,
    "المئمر": 1,
    "أمريكة": 1,
    "أمريكة الشمالية": 1,
    "أمشير": 1,
    "أمض": 1,
    "تأمع": 1,
    "الإمع": 1,
    "أمق": 1,
    "أمله": 1,
    "الآمل": 1,
    "المؤمل": 1,
    "أممه": 1,
    "ائتم": 1,
    "تأمم": 1,
    "استأم": 1,
    "الآمة": 1,
    "الإمامة": 1,
    "الإمامية": 1,
    "الإمة": 1,
    "الأميم": 1,
    "الأميمة": 1,
    "المئم": 1,
    "ائتمن": 1,
    "استأمن": 1,
    "الأمنة والأمنة": 1,
    "الأمنة": 1,
    "الأمون": 1,
    "تأمه": 1,
    "الأمه": 1,
    "الأميهة": 1,
    "أميت": 1,
    "أمى": 1,
    "تأمت": 1,
    "استأمى": 1,
    "أمية": 1,
    "وبنو أمية": 1,
    "أناضول أناطول": 1,
    "الأنام": 1,
    "أنبه": 1,
    "الأناب": 1,
    "الأنب": 1,
    "الأنبوبة": 1,
    "الأنبج": 1,
    "الإنبيق": 1,
    "أنتاركتكا": 1,
    "أنث": 1,
    "آنثت": 1,
    "تأنث": 1,
    "الأنثيان": 1,
    "الأنيث": 1,
    "المئناث": 1,
    "الإنجاص": 1,
    "الأنجر": 1,
    "أنح": 1,
    "الأنزيم": 1,
    "آنسه": 1,
    "أنسه": 1,
    "تآنسا": 1,
    "استأنس": 1,
    "الأنس": 1,
    "الإنسي": 1,
    "الأنوس": 1,
    "الأنيس": 1,
    "الأنيسة": 1,
    "الأنيسون": 1,
    "المؤنسات": 1,
    "المأنوس": 1,
    "الأنسولين": 1,
    "الأنشوجة": 1,
    "أنض": 1,
    "آنض": 1,
    "أنفت": 1,
    "آنفه": 1,
    "ائتنفه": 1,
    "تأنف": 1,
    "الآنف": 1,
    "الآنفة": 1,
    "الأنفة": 1,
    "الأنيف": 1,
    "الأنيفة": 1,
    "المئناف": 1,
    "الانفلونزا": 1,
    "آنقه": 1,
    "الأنوق": 1,
    "الأنكليس": 1,
    "الأنموذج": 1,
    "أننه": 1,
    "تأننه": 1,
    "الأنن": 1,
    "الأنة": 1,
    "الأننة": 1,
    "المئنة": 1,
    "آناه": 1,
    "تأنى": 1,
    "استأنى": 1,
    "الآناء": 1,
    "أهب": 1,
    "تأهب": 1,
    "الإهاب": 1,
    "الأهبة": 1,
    "آهله": 1,
    "ائتهل": 1,
    "استأهل": 1,
    "الآهل": 1,
    "الإهالة": 1,
    "الأهلي": 1,
    "الإهليلج": 1,
    "الإهليجي": 1,
    "أه": 1,
    "أهى": 1,
    "آوبه": 1,
    "أوب": 1,
    "تآوبا": 1,
    "تأوب": 1,
    "الأوب": 1,
    "الأواب": 1,
    "أوده": 1,
    "انآد": 1,
    "تأود": 1,
    "الأويد": 1,
    "استأور": 1,
    "الآر": 1,
    "الأوار": 1,
    "الأواري": 1,
    "الأورة": 1,
    "أورانس": 1,
    "الإوزي": 1,
    "المأوزة": 1,
    "آسه": 1,
    "استآسه": 1,
    "الأوس": 1,
    "الأويس": 1,
    "آفت": 1,
    "أوقه": 1,
    "تأوق": 1,
    "الأواقي": 1,
    "الأوق": 1,
    "الإوقة": 1,
    "الأوقة": 1,
    "الأوقية": 1,
    "ائتال": 1,
    "تأول": 1,
    "الآل": 1,
    "الآلاتي": 1,
    "الآلي أو الذاتي": 1,
    "الإيال": 1,
    "الإيالة": 1,
    "أومه": 1,
    "الأوام": 1,
    "أونت": 1,
    "تأون": 1,
    "الإوان والإيوان": 1,
    "الأون": 1,
    "آه آه": 1,
    "الأواه": 1,
    "ائتوى": 1,
    "تآووا": 1,
    "تأوى": 1,
    "استأوى": 1,
    "ابن آوى": 1,
    "الأياب": 1,
    "آيد": 1,
    "آيده": 1,
    "أيده": 1,
    "تأيد": 1,
    "الإياد": 1,
    "الأير": 1,
    "الأيار": 1,
    "الإيار": 1,
    "أيس": 1,
    "آيسه": 1,
    "تأيس": 1,
    "الآيسة": 1,
    "الإياس": 1,
    "أيش": 1,
    "استأيك": 1,
    "إيل": 1,
    "إيلياء": 1,
    "الأيل الأيل": 1,
    "أيم": 1,
    "ائتامت": 1,
    "تأيمت": 1,
    "الأيم": 1,
    "أيم الله": 1,
    "المأيمة": 1,
    "المؤيمة": 1,
    "الأين": 1,
    "أيهات أيهات": 1,
    "الأيهقان": 1,
    "الإيوان": 1,
    "تأيا": 1,
    "إيا": 1,
    "إياة الشمس": 1,
    "البابوج": 1,
    "البابونج": 1,
    "الباذق": 1,
    "الباسليق": 1,
    "البؤبؤ": 1,
    "البأج": 1,
    "بأدل": 1,
    "البأدلة": 1,
    "أبأر": 1,
    "ابتأر": 1,
    "البآر": 1,
    "البئرة": 1,
    "البئيرة": 1,
    "البأز": 1,
    "أبأس": 1,
    "ابتأس": 1,
    "تباءس": 1,
    "تبأس": 1,
    "البؤسي": 1,
    "بأش": 1,
    "تبأط": 1,
    "بؤل": 1,
    "بأت": 1,
    "بأى": 1,
    "البب": 1,
    "الببان": 1,
    "الببة": 1,
    "ببة": 1,
    "الببر": 1,
    "بتأ": 1,
    "بتت": 1,
    "تبتت": 1,
    "البتات": 1,
    "البتي": 1,
    "بتره": 1,
    "أبتره": 1,
    "انبتر": 1,
    "تبتر": 1,
    "الأبتران": 1,
    "الباتر": 1,
    "البتار": 1,
    "البتراء": 1,
    "البتور": 1,
    "بتع": 1,
    "أبتع": 1,
    "البتاع": 1,
    "البتع": 1,
    "بتكه": 1,
    "انبتك": 1,
    "تبتك": 1,
    "الباتك": 1,
    "البتاك": 1,
    "البتكة": 1,
    "البتوك": 1,
    "بتله": 1,
    "انبتل": 1,
    "تبتل": 1,
    "البتل": 1,
    "البتلاء": 1,
    "البتلة": 1,
    "البتيل": 1,
    "البتيلة": 1,
    "بثه": 1,
    "أبثه": 1,
    "باثه": 1,
    "انبث": 1,
    "استبثه": 1,
    "تبثر": 1,
    "الباثر": 1,
    "البثر": 1,
    "بثعت": 1,
    "بثع": 1,
    "تبثعت": 1,
    "البثعة": 1,
    "بثقه": 1,
    "انبثق": 1,
    "البثق": 1,
    "البثنة": 1,
    "البثاء": 1,
    "البثي": 1,
    "بجبج": 1,
    "تبجبج": 1,
    "البجباج": 1,
    "البجباجة": 1,
    "بجه": 1,
    "باجه": 1,
    "تباجا": 1,
    "البجة": 1,
    "أبجحه": 1,
    "بجحه": 1,
    "ابتجح": 1,
    "تباجحوا": 1,
    "البجاد": 1,
    "البجد": 1,
    "البجدة": 1,
    "بجر": 1,
    "أبجر": 1,
    "تبجر": 1,
    "الأبجر": 1,
    "البجر": 1,
    "البجراء": 1,
    "البجرة": 1,
    "البجير": 1,
    "بجسه": 1,
    "انبجس": 1,
    "تبجس": 1,
    "البجاس": 1,
    "البجيس": 1,
    "أبجله": 1,
    "بجله": 1,
    "الأبجل": 1,
    "البجال": 1,
    "البجلة": 1,
    "البجيل": 1,
    "البجامة": 1,
    "البجم": 1,
    "تبحبح": 1,
    "بحباح": 1,
    "البحباح": 1,
    "البحبحي": 1,
    "البحبوحة": 1,
    "باحت": 1,
    "البحت": 1,
    "البحتر": 1,
    "باحثه": 1,
    "ابتحثه": 1,
    "تباحثا": 1,
    "تبحثه": 1,
    "استبحثه": 1,
    "البحاثة": 1,
    "البحثة": 1,
    "البحيث": 1,
    "تبحثر": 1,
    "أبحه": 1,
    "الأبح": 1,
    "البحاح": 1,
    "البحة": 1,
    "بحدل": 1,
    "استبحر": 1,
    "الباحر": 1,
    "الباحرة": 1,
    "الباحور": 1,
    "الباحوراء": 1,
    "البحرة": 1,
    "بحشل": 1,
    "البحشل": 1,
    "البحشلي": 1,
    "بخبخ": 1,
    "تبخبخ": 1,
    "البخباخ": 1,
    "البخات": 1,
    "البخيت": 1,
    "المبخوت": 1,
    "تبختر": 1,
    "البختري": 1,
    "البخترية": 1,
    "بخدج": 1,
    "البخدج": 1,
    "أبخره": 1,
    "البخر": 1,
    "تباخس": 1,
    "الأباخس": 1,
    "بخص": 1,
    "البخص": 1,
    "بخقت": 1,
    "أبخق": 1,
    "انبخقت": 1,
    "أبخله": 1,
    "بخله": 1,
    "تبخل": 1,
    "استبخله": 1,
    "البخال": 1,
    "المبخلة": 1,
    "تبخنقت": 1,
    "البخنق": 1,
    "المبخنق": 1,
    "بخا": 1,
    "أبخى": 1,
    "البخو": 1,
    "بدئ": 1,
    "تبدأ": 1,
    "البداء": 1,
    "البداءة": 1,
    "البدأة": 1,
    "البدئ": 1,
    "البديئة": 1,
    "المبدأة": 1,
    "بدحت": 1,
    "تبادحوا": 1,
    "تبدحت": 1,
    "ابتد": 1,
    "تباد": 1,
    "استبد": 1,
    "أباديد": 1,
    "بداد": 1,
    "البداد": 1,
    "البد": 1,
    "البدد": 1,
    "البدة": 1,
    "البديد": 1,
    "البديدة": 1,
    "أبدر": 1,
    "ابتدرت": 1,
    "البادر": 1,
    "البدارة": 1,
    "البدر": 1,
    "البدري": 1,
    "البدرة": 1,
    "المبتدر": 1,
    "البدرون": 1,
    "بدعه": 1,
    "أبدع": 1,
    "أبدعت": 1,
    "تبدع": 1,
    "استبدعه": 1,
    "الابتداعية": 1,
    "البادع": 1,
    "تبادلا": 1,
    "الأبدال": 1,
    "البدال": 1,
    "التبذل": 1,
    "البدنة": 1,
    "المبدان": 1,
    "بدهه": 1,
    "بادهه": 1,
    "ابتده": 1,
    "تباده": 1,
    "البديهة": 1,
    "المبده": 1,
    "ابتدى": 1,
    "تبادى": 1,
    "تبدى": 1,
    "البادي": 1,
    "البداة": 1,
    "البداوة": 1,
    "أبذأ": 1,
    "باذأه": 1,
    "البذج": 1,
    "بذحه": 1,
    "بذحت": 1,
    "تبذح": 1,
    "البذح": 1,
    "باذخه": 1,
    "تبذخ": 1,
    "البيذخ": 1,
    "باذه": 1,
    "ابتذه": 1,
    "استبذر": 1,
    "البذارة": 1,
    "البذيرة": 1,
    "البيذار": 1,
    "التبذارة": 1,
    "بذرق": 1,
    "البذرقة": 1,
    "ابذعرت": 1,
    "ابذقر": 1,
    "بذله": 1,
    "تبذل": 1,
    "استبذله": 1,
    "البذل": 1,
    "البذلة": 1,
    "المبذل": 1,
    "المبذلة": 1,
    "بذم": 1,
    "بذو": 1,
    "أبذى": 1,
    "برؤ": 1,
    "أبرأ": 1,
    "بارأ": 1,
    "برأه": 1,
    "تبارأ": 1,
    "استبرأ": 1,
    "البراء": 1,
    "البرأة": 1,
    "البريئة البرية": 1,
    "برأل": 1,
    "ابرأل": 1,
    "تبرأل": 1,
    "البرائل": 1,
    "البربخ": 1,
    "بربرت": 1,
    "البربار": 1,
    "البربري": 1,
    "البربط": 1,
    "البرت": 1,
    "البرت البرت": 1,
    "البرثن": 1,
    "برجت": 1,
    "أبرج": 1,
    "تبرجت": 1,
    "الإبريج": 1,
    "البارجة": 1,
    "التباريج": 1,
    "البرجد": 1,
    "البرجل": 1,
    "برجم": 1,
    "البرجمة": 1,
    "تبرح": 1,
    "براح براح": 1,
    "البراح": 1,
    "البرح": 1,
    "برحى": 1,
    "البرحاء": 1,
    "البرحة": 1,
    "البريح": 1,
    "التباريح": 1,
    "ابترد": 1,
    "تبرد": 1,
    "استبرد": 1,
    "الأبردان": 1,
    "الإبردة": 1,
    "البرادة": 1,
    "البرداء": 1,
    "البردان": 1,
    "البردة": 1,
    "البراد": 1,
    "البردعة": 1,
    "البرذعة": 1,
    "برذن": 1,
    "البرذون": 1,
    "تباروا": 1,
    "البراني": 1,
    "برة": 1,
    "البرير": 1,
    "المبرة": 1,
    "بارزه": 1,
    "تبارزا": 1,
    "تبرز": 1,
    "البرزة": 1,
    "البريزة": 1,
    "البرس": 1,
    "البرستاتة": 1,
    "البرسام": 1,
    "البرش": 1,
    "برشق": 1,
    "ابرنشق": 1,
    "البراشم": 1,
    "البرشام": 1,
    "البرشم": 1,
    "أبرص": 1,
    "تبرص": 1,
    "الأبرص": 1,
    "البرص": 1,
    "البرصة": 1,
    "البريص": 1,
    "البرصوم": 1,
    "أبرضت": 1,
    "برضت": 1,
    "ابترض": 1,
    "تبرضت": 1,
    "البارض": 1,
    "البراض": 1,
    "البراضة": 1,
    "البرض": 1,
    "البرضة": 1,
    "تبرطل": 1,
    "البرطل": 1,
    "البرطلة البرطلة": 1,
    "البرطيل": 1,
    "تبرطم": 1,
    "البراطم": 1,
    "البرطم": 1,
    "البرطمان": 1,
    "البرطوم": 1,
    "البرعمة": 1,
    "البرعوم": 1,
    "البرعومة": 1,
    "برغ": 1,
    "البرغ": 1,
    "البرغثة": 1,
    "ابرغش": 1,
    "البرغش": 1,
    "البرغل": 1,
    "البرغيل": 1,
    "أبرقت": 1,
    "الأبرق": 1,
    "الاستبرق": 1,
    "البارقة": 1,
    "البرقاء": 1,
    "البيرق": 1,
    "البيرقدار": 1,
    "ابرنقشت": 1,
    "براقش": 1,
    "برقط": 1,
    "تبرقط": 1,
    "برقعت": 1,
    "البرقوع": 1,
    "المبرقع": 1,
    "المبرقعة": 1,
    "برقل": 1,
    "البرقيل": 1,
    "أبرك": 1,
    "ابترك": 1,
    "تبرك": 1,
    "البراكاء": 1,
    "وحبة البركة": 1,
    "البروك": 1,
    "البريك": 1,
    "البريكة": 1,
    "المبرك": 1,
    "البركار": 1,
    "بركع": 1,
    "تبركع": 1,
    "البركع": 1,
    "البرلنت": 1,
    "أبرم": 1,
    "البرامة": 1,
    "البريمة": 1,
    "البريم": 1,
    "البيرم": 1,
    "برمهات": 1,
    "البرني": 1,
    "البرنية": 1,
    "البرنز": 1,
    "تبرنس": 1,
    "البرنس": 1,
    "البرنوف": 1,
    "برنق": 1,
    "البرنيقي": 1,
    "أبره": 1,
    "البرهة": 1,
    "برهم": 1,
    "البراهمة": 1,
    "البرهمة": 1,
    "أبرى": 1,
    "البرة": 1,
    "البروة": 1,
    "البروتستينية": 1,
    "البرواز": 1,
    "البروفة": 1,
    "البروق": 1,
    "باراه": 1,
    "ابترى": 1,
    "تبارى": 1,
    "تبرى": 1,
    "البارياء": 1,
    "الباري": 1,
    "البارية": 1,
    "البرى": 1,
    "البراية": 1,
    "البراءة البراية": 1,
    "البري بري": 1,
    "البزابز": 1,
    "البزباز": 1,
    "البزبز": 1,
    "بزج": 1,
    "بازج": 1,
    "تبازج": 1,
    "البزيج": 1,
    "بزخه": 1,
    "بزخ": 1,
    "انبزخ": 1,
    "تبازخ": 1,
    "البزخ": 1,
    "البزدرة": 1,
    "البازور": 1,
    "وبزر قطونا": 1,
    "البزراء": 1,
    "البزرة": 1,
    "البزار": 1,
    "البيزار": 1,
    "البيزارة": 1,
    "البيزر": 1,
    "البيزرة": 1,
    "المبزر": 1,
    "المبزور": 1,
    "البزازة": 1,
    "البزز": 1,
    "البزة": 1,
    "بزع": 1,
    "تبزع": 1,
    "بزغت": 1,
    "بزغه": 1,
    "ابتزغ": 1,
    "البازغة": 1,
    "المبزغ": 1,
    "البزق": 1,
    "انبزل": 1,
    "تبزل": 1,
    "البازل": 1,
    "البازلة": 1,
    "البزال": 1,
    "المبزال": 1,
    "المبزلة": 1,
    "بزم": 1,
    "البازمة": 1,
    "البزمة": 1,
    "البزيم": 1,
    "البزيمة": 1,
    "بزا": 1,
    "بزي": 1,
    "أبزى": 1,
    "تبازى": 1,
    "البازي": 1,
    "البزو": 1,
    "بس بس": 1,
    "بسئ": 1,
    "أبسأه": 1,
    "بسبس": 1,
    "تبسبس": 1,
    "البسباسة": 1,
    "البسبس": 1,
    "أبسر": 1,
    "باسرت": 1,
    "ابتسرت": 1,
    "ابتسر": 1,
    "تبسر": 1,
    "البسر": 1,
    "البسرة": 1,
    "المبسار": 1,
    "المبسرة": 1,
    "أبس": 1,
    "انبس": 1,
    "الباسة": 1,
    "البس": 1,
    "البساسة": 1,
    "البسوس": 1,
    "البسيس": 1,
    "باسطه": 1,
    "انبسط": 1,
    "الباسط": 1,
    "البسطة": 1,
    "المتبسط": 1,
    "أبسقت": 1,
    "تبسق": 1,
    "الباسقة": 1,
    "بساقة القمر": 1,
    "البسقة": 1,
    "أبسل": 1,
    "باسله": 1,
    "ابتسل": 1,
    "استبسل": 1,
    "الباسل": 1,
    "البسل": 1,
    "البسلة": 1,
    "البسلي": 1,
    "البسول": 1,
    "البسيل": 1,
    "البسيلة": 1,
    "المتبسل": 1,
    "البسيمة": 1,
    "المبسم": 1,
    "أبسن": 1,
    "الباسنة": 1,
    "البشت": 1,
    "أبشرت": 1,
    "بشرت": 1,
    "ابتشر": 1,
    "تباشر": 1,
    "تبشر": 1,
    "البشار": 1,
    "البشور": 1,
    "التباشير": 1,
    "المبشرة": 1,
    "المبشورة": 1,
    "البشرف": 1,
    "أبشت": 1,
    "تبشش": 1,
    "البشيش": 1,
    "أبشعه": 1,
    "استبشع": 1,
    "الباشق": 1,
    "البشق": 1,
    "بشكت": 1,
    "أبشك": 1,
    "ابتشك": 1,
    "البشكى": 1,
    "البشكور": 1,
    "أبشمه": 1,
    "البشامة": 1,
    "البشملة": 1,
    "البشنين": 1,
    "تبصبص": 1,
    "البصابص": 1,
    "البصباص": 1,
    "باصره": 1,
    "تباصر": 1,
    "استبصر": 1,
    "الباصر": 1,
    "الباصرة": 1,
    "البصارة": 1,
    "البصريون": 1,
    "المبصر": 1,
    "بصصت": 1,
    "تبصص": 1,
    "بصع": 1,
    "تبصع": 1,
    "البصع": 1,
    "البصيع": 1,
    "البصاقة": 1,
    "بصله": 1,
    "تبصل": 1,
    "البصلة": 1,
    "البصم": 1,
    "البصمة": 1,
    "بصا": 1,
    "البصوة": 1,
    "تبضبض": 1,
    "البضابض": 1,
    "البضباضة": 1,
    "بضض": 1,
    "ابتض": 1,
    "تبضض": 1,
    "البضاضة": 1,
    "انبضع": 1,
    "تبضع": 1,
    "استبضع": 1,
    "الباضعة": 1,
    "البضع": 1,
    "البضعة": 1,
    "البضيعة": 1,
    "بطأه": 1,
    "تبطأ": 1,
    "استبطأه": 1,
    "أبطح": 1,
    "انبطح": 1,
    "تبطح": 1,
    "استبطح": 1,
    "الأبطح": 1,
    "البطاح": 1,
    "البطحاء": 1,
    "البطحة": 1,
    "البطيحة": 1,
    "أبطخ": 1,
    "تبطخ": 1,
    "المبطخة": 1,
    "أبطره": 1,
    "البطرير": 1,
    "البطير": 1,
    "البيطار": 1,
    "تبطرق": 1,
    "البطارق": 1,
    "البطرك": 1,
    "بطط": 1,
    "البطاط": 1,
    "البطيط": 1,
    "المبط": 1,
    "المبطة": 1,
    "تبطل": 1,
    "الأبطولة": 1,
    "البطم": 1,
    "أبطن": 1,
    "تبطن": 1,
    "استبطن": 1,
    "الأبطن": 1,
    "البطان": 1,
    "البطنان": 1,
    "البطنة": 1,
    "المبطان": 1,
    "الباطية": 1,
    "البظارة": 1,
    "البظراء": 1,
    "بظرم": 1,
    "تبظرم": 1,
    "أبظ": 1,
    "بظا": 1,
    "البعبع": 1,
    "ابتعثه": 1,
    "تباعث": 1,
    "تبعث": 1,
    "انبعج": 1,
    "تبعج": 1,
    "باعده": 1,
    "ابتعد": 1,
    "تبعد": 1,
    "استبعد": 1,
    "الأبعد": 1,
    "أما بعد": 1,
    "بعرت": 1,
    "أبعر": 1,
    "باعرت": 1,
    "البعار": 1,
    "البعر": 1,
    "المبعر المبعرة": 1,
    "بعزقه": 1,
    "تبعزق": 1,
    "بعص": 1,
    "تبعصص": 1,
    "البعصوص": 1,
    "أبعضت": 1,
    "تبعض": 1,
    "بعض الشيء": 1,
    "بعط": 1,
    "أبعطه": 1,
    "البعاع": 1,
    "بعق": 1,
    "ابتعق": 1,
    "انبعق": 1,
    "تبعق": 1,
    "الباعق": 1,
    "بعكه": 1,
    "بعك": 1,
    "البعكوكاء": 1,
    "البعكوكة": 1,
    "باعل": 1,
    "ابتعلت": 1,
    "تباعل": 1,
    "تبعلت": 1,
    "استبعل": 1,
    "البعل": 1,
    "البعلة": 1,
    "البعيم": 1,
    "بعا": 1,
    "أبعاه": 1,
    "استبعاه": 1,
    "بغبغ": 1,
    "البغباغ": 1,
    "البغبغ": 1,
    "بغته": 1,
    "باغته": 1,
    "بغث": 1,
    "البغاث": 1,
    "البغثاء": 1,
    "البغيث": 1,
    "تبغدد": 1,
    "بغر": 1,
    "البغر": 1,
    "البغرة": 1,
    "بغزت": 1,
    "البغاشة": 1,
    "البغشة": 1,
    "أبغضه": 1,
    "باغضه": 1,
    "بغضه": 1,
    "تبغض": 1,
    "بغ": 1,
    "بغمت": 1,
    "البغام": 1,
    "البغو": 1,
    "أبغاه": 1,
    "انبغى": 1,
    "تباغى": 1,
    "تبغى": 1,
    "الباغة": 1,
    "الباغي": 1,
    "البغية والبغية": 1,
    "بقبقت": 1,
    "البقباق": 1,
    "انبقر": 1,
    "تبقر": 1,
    "الأبيقر": 1,
    "الباقر": 1,
    "البقار": 1,
    "البقير": 1,
    "بقسماط": 1,
    "بقط": 1,
    "تبقط": 1,
    "البقط": 1,
    "بقعه": 1,
    "تبقع": 1,
    "الأبقع": 1,
    "الباقعة": 1,
    "البقعاء": 1,
    "البقيع": 1,
    "بقق": 1,
    "البقاق": 1,
    "المبق": 1,
    "المبقة": 1,
    "أبقل": 1,
    "ابتقلت": 1,
    "تبقلت": 1,
    "الباقلاء": 1,
    "الباقلي": 1,
    "الباقول": 1,
    "البقل": 1,
    "المبقلة": 1,
    "تبقمت": 1,
    "البقامة": 1,
    "البقم": 1,
    "بقاه": 1,
    "استبقاه": 1,
    "الأبقى": 1,
    "البقيا": 1,
    "بكأت": 1,
    "بكؤ": 1,
    "أبكأ": 1,
    "بكبك": 1,
    "البكبكة": 1,
    "بكته": 1,
    "أبكر": 1,
    "باكره": 1,
    "ابتكر": 1,
    "تبكر": 1,
    "الإبكار": 1,
    "الباكور الباكورة": 1,
    "البكرة البكرة": 1,
    "المبكار": 1,
    "بكس": 1,
    "بكش": 1,
    "البكاش": 1,
    "بكعه": 1,
    "تباك": 1,
    "الباك": 1,
    "بكة": 1,
    "تبكل": 1,
    "البكالة": 1,
    "البكل": 1,
    "البكلة": 1,
    "البكيلة": 1,
    "تبكم": 1,
    "أبكاه": 1,
    "بكاه": 1,
    "تباكى": 1,
    "استبكاه": 1,
    "التبكاء": 1,
    "البلبال والبلبالة": 1,
    "البلبل": 1,
    "البلبول": 1,
    "بلت": 1,
    "تبلتع": 1,
    "أبلج": 1,
    "ابتلج انبلج تبلج": 1,
    "البلجة": 1,
    "أبلحت": 1,
    "بالح": 1,
    "تبالحا": 1,
    "البلح": 1,
    "بلخ": 1,
    "تبلخ": 1,
    "تبلخص": 1,
    "أبلد": 1,
    "البلد والبلدة": 1,
    "بلدح": 1,
    "تبلدح": 1,
    "البلدح": 1,
    "بلدم": 1,
    "البلدم": 1,
    "بلوره": 1,
    "البلور البلور": 1,
    "أبلس": 1,
    "البلاس": 1,
    "البلس": 1,
    "البلسان": 1,
    "تبلسم": 1,
    "البلسن": 1,
    "بلصه": 1,
    "بالصه": 1,
    "تبلص": 1,
    "البلاصي": 1,
    "أبلط": 1,
    "بالط": 1,
    "تبالطوا": 1,
    "البلط": 1,
    "البلطة": 1,
    "البلطي": 1,
    "بلطح": 1,
    "أبلعه": 1,
    "ابتلعه": 1,
    "تبلعه": 1,
    "البالوعة البلوعة": 1,
    "البلعة": 1,
    "البلاعة": 1,
    "البلوع": 1,
    "البلعم والبلعوم": 1,
    "تبالغ": 1,
    "البلغة": 1,
    "التبلغة": 1,
    "البلغم": 1,
    "بلق": 1,
    "أبلق": 1,
    "انبلق": 1,
    "ابلولق": 1,
    "البلقع": 1,
    "بلله": 1,
    "ابتل": 1,
    "تبلل": 1,
    "البالة": 1,
    "البالول": 1,
    "البلال": 1,
    "البلالة": 1,
    "البلة": 1,
    "البليلة": 1,
    "بليم": 1,
    "أبلم": 1,
    "الأبلم": 1,
    "البلام": 1,
    "البلان": 1,
    "البلانة": 1,
    "البلنط": 1,
    "تباله": 1,
    "تبله": 1,
    "البلهنية": 1,
    "البلهارسيا": 1,
    "بلاه": 1,
    "بالى": 1,
    "البلي": 1,
    "البم": 1,
    "بنجه": 1,
    "البندار": 1,
    "البندر": 1,
    "البندقي": 1,
    "بنزهير": 1,
    "البنصر": 1,
    "البنط": 1,
    "في اصطلاح الطباعة": 1,
    "بنق": 1,
    "تبنك": 1,
    "البنكام": 1,
    "بنن": 1,
    "البنان": 1,
    "أبنى": 1,
    "ابتنى": 1,
    "انبنى": 1,
    "استبنت": 1,
    "ابنم": 1,
    "البانية": 1,
    "البناية": 1,
    "البناؤون الأحرار": 1,
    "البنوة": 1,
    "البنيان": 1,
    "المبناة": 1,
    "بهأ": 1,
    "بهىء": 1,
    "البهائية": 1,
    "بهته": 1,
    "بهت": 1,
    "تباهت": 1,
    "البهت والبهتان": 1,
    "البهيتة": 1,
    "بهتر": 1,
    "بهث": 1,
    "تباهث": 1,
    "البهثة": 1,
    "بهجه": 1,
    "بهج": 1,
    "أبهجت": 1,
    "باهجه": 1,
    "ابتهج": 1,
    "تباهج": 1,
    "استبهج": 1,
    "المبهاج": 1,
    "بهدل": 1,
    "البهدل": 1,
    "البهدلة": 1,
    "بهره": 1,
    "باهره": 1,
    "ابتهر": 1,
    "انبهر": 1,
    "تبهر": 1,
    "ابهار": 1,
    "الأبهر": 1,
    "الأبهران": 1,
    "البهار": 1,
    "البهر": 1,
    "البهرة": 1,
    "البهيرة": 1,
    "تبهرج": 1,
    "البهرج": 1,
    "بهرم": 1,
    "تبهرم": 1,
    "بهرام": 1,
    "البهرم": 1,
    "بهزه": 1,
    "أبهزه": 1,
    "باهزه": 1,
    "تبهزت": 1,
    "بهس": 1,
    "تبيهس": 1,
    "البهس": 1,
    "البيهس": 1,
    "البيهسية": 1,
    "بهش": 1,
    "ابتهش": 1,
    "تباهش": 1,
    "البهش": 1,
    "بهص": 1,
    "أبهصه": 1,
    "بهصل": 1,
    "تبهصل": 1,
    "بهضه": 1,
    "أبهضه": 1,
    "المبهوض": 1,
    "بهط": 1,
    "بهظه": 1,
    "أبهظه": 1,
    "الباهظ": 1,
    "الباهظة": 1,
    "بهق": 1,
    "البهاق": 1,
    "البهق": 1,
    "تبهكنت": 1,
    "البهكن": 1,
    "بهله": 1,
    "بهل": 1,
    "أبهله": 1,
    "باهل": 1,
    "ابتهل": 1,
    "تباهل": 1,
    "تبهل": 1,
    "استبهله": 1,
    "أبهل": 1,
    "البهل": 1,
    "البهلول": 1,
    "تبهم": 1,
    "استبهم": 1,
    "الأبهم": 1,
    "البهمة": 1,
    "البهيم": 1,
    "البهيمة": 1,
    "المبهم": 1,
    "المبهمة": 1,
    "البهنانة": 1,
    "بهنس": 1,
    "تبهنس": 1,
    "البهنس": 1,
    "أبهى": 1,
    "باهاه": 1,
    "بهى": 1,
    "ابتهى": 1,
    "تباهى": 1,
    "البهو": 1,
    "البها والبهاء": 1,
    "أباء": 1,
    "باوأه": 1,
    "تباوأ": 1,
    "استباء": 1,
    "الباءة": 1,
    "المباءة": 1,
    "تبوب": 1,
    "البابي": 1,
    "البوتقة": 1,
    "أباثه": 1,
    "ابتاثه": 1,
    "استباثه": 1,
    "باج": 1,
    "بوج": 1,
    "انباج": 1,
    "تبوج": 1,
    "البائج": 1,
    "البائجة": 1,
    "الباج": 1,
    "أباحه": 1,
    "استباحه": 1,
    "أباخ": 1,
    "أباره": 1,
    "ابتاره": 1,
    "البوري": 1,
    "البورق": 1,
    "البوز": 1,
    "باسه": 1,
    "باوشه": 1,
    "بوشوا": 1,
    "انباش": 1,
    "تباوشا": 1,
    "تبوشوا": 1,
    "البوش": 1,
    "البوشي البوشي": 1,
    "انباص": 1,
    "استباصه": 1,
    "البوصي": 1,
    "باط": 1,
    "بوط": 1,
    "انباع": 1,
    "تبوع": 1,
    "البواع": 1,
    "أباغ": 1,
    "البوغاء": 1,
    "انباق": 1,
    "تبوق": 1,
    "البائق": 1,
    "البائقة": 1,
    "البوقة": 1,
    "البواق": 1,
    "باوكهم": 1,
    "انباك": 1,
    "البائكة": 1,
    "البوكاء": 1,
    "البوكة": 1,
    "أباله": 1,
    "استباله": 1,
    "البوال": 1,
    "البولة": 1,
    "المبال": 1,
    "البوام": 1,
    "بانه": 1,
    "البوان": 1,
    "البون والبون": 1,
    "استباه": 1,
    "استبيه": 1,
    "الباه": 1,
    "الباهة": 1,
    "البوه": 1,
    "البوهة": 1,
    "البو": 1,
    "البوية": 1,
    "البيب": 1,
    "البيبة": 1,
    "أباته": 1,
    "تبيت": 1,
    "البيات": 1,
    "البيتة": 1,
    "البيوتة": 1,
    "بيح": 1,
    "البيحان": 1,
    "أباده": 1,
    "البيد": 1,
    "البيداء": 1,
    "البيدانة": 1,
    "البيدر": 1,
    "البيذق": 1,
    "باضت": 1,
    "أباض": 1,
    "بايضه": 1,
    "ابتاض": 1,
    "ابياض": 1,
    "المبيضة": 1,
    "تبيطر": 1,
    "البيطر": 1,
    "البيطرة": 1,
    "باظ": 1,
    "البيظ": 1,
    "باعه": 1,
    "أباعه": 1,
    "بايعه": 1,
    "ابتاعه": 1,
    "تبايعا": 1,
    "استباعه": 1,
    "البياعة": 1,
    "البيوع": 1,
    "تبيغ": 1,
    "البيغ": 1,
    "بيقر": 1,
    "تبيقر": 1,
    "البيقر": 1,
    "البيقرة": 1,
    "البيلم": 1,
    "البيمارستان": 1,
    "أبان": 1,
    "باينه": 1,
    "تباينا": 1,
    "استبان": 1,
    "البائن": 1,
    "البائنة": 1,
    "البانة": 1,
    "البيانلا": 1,
    "بين بين": 1,
    "البيون": 1,
    "بياه": 1,
    "تبياه": 1,
    "البي": 1,
    "التاء": 1,
    "التأتاء": 1,
    "البى": 1,
    "أتأره": 1,
    "التأرة": 1,
    "التؤرور": 1,
    "تأز": 1,
    "تئق": 1,
    "أتأق": 1,
    "المتأق": 1,
    "أتأمت": 1,
    "تاءم": 1,
    "التؤام": 1,
    "التؤامية": 1,
    "التؤم": 1,
    "التئيم": 1,
    "التوءم": 1,
    "التوءمة": 1,
    "المتئام": 1,
    "تتاءن": 1,
    "تتأن": 1,
    "تببه": 1,
    "تبتب": 1,
    "التبوت": 1,
    "التبر": 1,
    "أتبعت": 1,
    "تابعه": 1,
    "تتابعت": 1,
    "تتبعه": 1,
    "استتبعه": 1,
    "الاتباعية": 1,
    "التابعي": 1,
    "التباعة": 1,
    "التبع": 1,
    "التبيع": 1,
    "التبغين": 1,
    "أتبله": 1,
    "التبال": 1,
    "التبل": 1,
    "التوبال": 1,
    "التوبل": 1,
    "اتبن": 1,
    "التبان": 1,
    "المتبن": 1,
    "المتبنة": 1,
    "التجاب": 1,
    "التجباب": 1,
    "اتجر": 1,
    "التاجرة": 1,
    "المتجرة": 1,
    "التحت": 1,
    "تحتحه": 1,
    "تتحتح": 1,
    "التحتحة": 1,
    "التحتربة": 1,
    "أتحفه": 1,
    "اتحفه": 1,
    "التخت": 1,
    "التختة": 1,
    "التختاخ": 1,
    "التختروان": 1,
    "أتخ": 1,
    "التخ": 1,
    "تخذ": 1,
    "التدرة": 1,
    "تراجيديا": 1,
    "الرمد الحبيبي": 1,
    "أترب": 1,
    "تاربه": 1,
    "تترب": 1,
    "الترائب": 1,
    "الترباء": 1,
    "التربي": 1,
    "التربس": 1,
    "التربنة": 1,
    "التربين": 1,
    "تترتر": 1,
    "الترتور": 1,
    "ترج": 1,
    "الترج": 1,
    "التريج": 1,
    "الترجمان": 1,
    "أترحه": 1,
    "ترحه": 1,
    "تترح": 1,
    "المتارح": 1,
    "المترح": 1,
    "الترخ": 1,
    "أتر": 1,
    "الأترور": 1,
    "التر": 1,
    "ترز": 1,
    "أترزه": 1,
    "التراز التراز": 1,
    "الترزي": 1,
    "تترس": 1,
    "التارس": 1,
    "التراسة": 1,
    "التراس": 1,
    "الترسة": 1,
    "المترس": 1,
    "ترص": 1,
    "أترصه": 1,
    "ترصه": 1,
    "المترصات": 1,
    "ترعه": 1,
    "أترع": 1,
    "اترع": 1,
    "تترع": 1,
    "الأترع": 1,
    "الترع": 1,
    "التراع": 1,
    "أترف": 1,
    "ترفه": 1,
    "تترف": 1,
    "استترف": 1,
    "الترفة": 1,
    "الترفاس": 1,
    "تاركه": 1,
    "اترك": 1,
    "تراك": 1,
    "تتاركوا": 1,
    "التريك": 1,
    "التريكة": 1,
    "الترمسة": 1,
    "ترموجراف": 1,
    "ترموجراء": 1,
    "التره": 1,
    "الترهة": 1,
    "أترى": 1,
    "أتسع": 1,
    "التاسوعاء": 1,
    "التسعة": 1,
    "التسيع": 1,
    "تعتعت": 1,
    "تتعتع": 1,
    "التعاتع": 1,
    "أتعسه": 1,
    "التعس": 1,
    "التعل": 1,
    "تغب": 1,
    "أتغبه": 1,
    "تغتع": 1,
    "تغر": 1,
    "تفئ": 1,
    "التفيئة": 1,
    "تفتف": 1,
    "التفتاف": 1,
    "تفث": 1,
    "التفث": 1,
    "أتفحه": 1,
    "المتفحة": 1,
    "أتفر": 1,
    "التفرة": 1,
    "تففه": 1,
    "التفافة": 1,
    "التفان": 1,
    "التفة": 1,
    "أتفله": 1,
    "التنفل التنفل": 1,
    "التفال": 1,
    "التفل": 1,
    "المتفلة": 1,
    "تفنه": 1,
    "التفن": 1,
    "أتقنه": 1,
    "تتقنت": 1,
    "التقن": 1,
    "التقنة": 1,
    "استتك": 1,
    "التك": 1,
    "التكة": 1,
    "التكيك": 1,
    "المتك": 1,
    "التكية": 1,
    "التلب": 1,
    "التولب": 1,
    "التلبائي": 1,
    "تلتل": 1,
    "التلاتل": 1,
    "التلتلة": 1,
    "أتلد": 1,
    "التلاد": 1,
    "التلد": 1,
    "التليد": 1,
    "التلسكوب": 1,
    "أتلع": 1,
    "تتالع": 1,
    "تتلع": 1,
    "استتلع": 1,
    "التلعة": 1,
    "أتلفه": 1,
    "التلفة": 1,
    "المتلاف": 1,
    "المتلف": 1,
    "المتلفة": 1,
    "تلله": 1,
    "التلالة": 1,
    "التلل": 1,
    "التلة": 1,
    "التلى": 1,
    "التلول": 1,
    "التليل": 1,
    "المتل": 1,
    "المتلول": 1,
    "التليسة": 1,
    "التلام": 1,
    "التلم": 1,
    "أتله": 1,
    "تلهه": 1,
    "اتله": 1,
    "تتله": 1,
    "المتلة": 1,
    "المتلهة": 1,
    "تلاه": 1,
    "أتلت": 1,
    "تالاه": 1,
    "تتالت": 1,
    "استتلى": 1,
    "التلاء": 1,
    "التلاوة": 1,
    "التلو": 1,
    "التلية": 1,
    "المتالي": 1,
    "تمره": 1,
    "تمرت": 1,
    "أتمر": 1,
    "تتمر": 1,
    "التامر": 1,
    "التامور": 1,
    "التامورة": 1,
    "التماري": 1,
    "التمر": 1,
    "التمار": 1,
    "تمك": 1,
    "أتمك": 1,
    "التامك": 1,
    "التامول": 1,
    "أتمت": 1,
    "تمم": 1,
    "تتام": 1,
    "استتم": 1,
    "التتمة": 1,
    "التمامة التمامة": 1,
    "التمامة": 1,
    "التم": 1,
    "التمم": 1,
    "تميم": 1,
    "المتم": 1,
    "تمه": 1,
    "المتماه": 1,
    "تنأ": 1,
    "التانبول": 1,
    "التنبال": 1,
    "التنبالة": 1,
    "التنبل": 1,
    "التنبول": 1,
    "تنتلت": 1,
    "التنتال": 1,
    "التنتالة": 1,
    "التنتل": 1,
    "التنتيل": 1,
    "تنتن": 1,
    "التنتنة": 1,
    "التنوج": 1,
    "تنخ": 1,
    "أتنخه": 1,
    "تانخه": 1,
    "التنار": 1,
    "التنضب": 1,
    "التنوفة": 1,
    "التنوفية": 1,
    "التنكة": 1,
    "التنوم": 1,
    "التن": 1,
    "ته ته": 1,
    "تهته": 1,
    "تتهته": 1,
    "التهاتة": 1,
    "التهتهة": 1,
    "التيهور": 1,
    "تهم": 1,
    "تاهم": 1,
    "اتهمه": 1,
    "تتهم": 1,
    "تهامة": 1,
    "المتهام": 1,
    "استتابه": 1,
    "التوتياء": 1,
    "المتوتة": 1,
    "التائج": 1,
    "التاجي": 1,
    "تار": 1,
    "تير": 1,
    "أتار": 1,
    "تاوره": 1,
    "التارة": 1,
    "التور": 1,
    "التورة": 1,
    "تاف": 1,
    "التوفة": 1,
    "التويفة": 1,
    "تتوق": 1,
    "التال": 1,
    "التولة": 1,
    "التويلة": 1,
    "التولج": 1,
    "التومة": 1,
    "توهه": 1,
    "التوه": 1,
    "أتوى": 1,
    "التو": 1,
    "التوة": 1,
    "توى": 1,
    "المتواة": 1,
    "أتاحه": 1,
    "التيحان والتيحان": 1,
    "التياح": 1,
    "المتياح": 1,
    "المتيح": 1,
    "التيد": 1,
    "التير": 1,
    "تاز": 1,
    "تايزه": 1,
    "تتيز": 1,
    "تس تس": 1,
    "تاس": 1,
    "تيست": 1,
    "تايسه": 1,
    "تتايس": 1,
    "استتيست": 1,
    "المتيوساء": 1,
    "تاع": 1,
    "أتاع": 1,
    "تايع": 1,
    "تيع": 1,
    "تتايع": 1,
    "اتايع": 1,
    "تتيع": 1,
    "الأتيع": 1,
    "التاعة": 1,
    "التيع": 1,
    "التيعة": 1,
    "التيعان": 1,
    "التيفود": 1,
    "أتام": 1,
    "تيمه": 1,
    "اتام": 1,
    "التيم": 1,
    "التيماء": 1,
    "التيمة": 1,
    "التين الشوكي": 1,
    "التيان": 1,
    "المتينة": 1,
    "أتاهه": 1,
    "تيهه": 1,
    "الأتيه": 1,
    "التيهاء": 1,
    "التيهان": 1,
    "المتيه": 1,
    "المتيهة": 1,
    "التيوقراطية": 1,
    "الثاء": 1,
    "تثأب": 1,
    "الثؤباء": 1,
    "ثأثأ": 1,
    "تثأثأ": 1,
    "ثئد": 1,
    "الثأد": 1,
    "الثأداء": 1,
    "الثأدة": 1,
    "أثأر": 1,
    "استثأر": 1,
    "أنا له يا رسول الله الموتور الثائر": 1,
    "ثألله": 1,
    "تثألل": 1,
    "الثؤلول": 1,
    "ثئي": 1,
    "الثأي والثأي": 1,
    "اثبأج": 1,
    "استثبت": 1,
    "الثبت": 1,
    "ثبج": 1,
    "الثبج": 1,
    "الثبجة": 1,
    "اثبجر": 1,
    "ثبرت": 1,
    "ثبره": 1,
    "تثابروا": 1,
    "الثبار": 1,
    "الثبرة": 1,
    "أثبطه": 1,
    "ثبطه": 1,
    "تثبط": 1,
    "ثبقت": 1,
    "أثبن": 1,
    "الثبان": 1,
    "الثبنة": 1,
    "الثبين": 1,
    "المثبنة": 1,
    "ثبى": 1,
    "الثبة": 1,
    "ثتم": 1,
    "انثتم": 1,
    "تثتم": 1,
    "الثتى": 1,
    "الثتاة": 1,
    "ثجثج": 1,
    "تثجثج": 1,
    "الثجاج": 1,
    "الثجة": 1,
    "الثجوج": 1,
    "الثجيج": 1,
    "المثج": 1,
    "ثجر": 1,
    "الأثجر": 1,
    "الثجر": 1,
    "الثجرة": 1,
    "الثجير": 1,
    "المثجر": 1,
    "ثجل": 1,
    "الأثجل": 1,
    "ثجمه": 1,
    "ثجم": 1,
    "أثجمت": 1,
    "ثجمت": 1,
    "ثحثح": 1,
    "الثحثحة": 1,
    "استثخن": 1,
    "الثخن": 1,
    "الثخنة": 1,
    "ثدق": 1,
    "انثدق": 1,
    "ثدم": 1,
    "الثدام": 1,
    "ثدن": 1,
    "ثداه": 1,
    "ثربه": 1,
    "أثرب": 1,
    "الثرب": 1,
    "ثردت": 1,
    "أثرد": 1,
    "الثرد": 1,
    "المثراد": 1,
    "المثردة": 1,
    "ثرر": 1,
    "الثر": 1,
    "الثرعلة": 1,
    "الثرعامة": 1,
    "ثرمه": 1,
    "أثرمه": 1,
    "انثرم": 1,
    "الأثرم": 1,
    "الأثرمان": 1,
    "ثرمد": 1,
    "ثرمل": 1,
    "الثرملة": 1,
    "ثريوم": 1,
    "ثرا": 1,
    "المثراة": 1,
    "ثط": 1,
    "ثطا": 1,
    "ثعب": 1,
    "انثعب": 1,
    "الأثعوب": 1,
    "الثعب": 1,
    "ثعبان السمك": 1,
    "الثعبة": 1,
    "الثعوب": 1,
    "المثعب": 1,
    "ثعثع": 1,
    "الثعثع": 1,
    "ثعجر": 1,
    "اثعنجر": 1,
    "المثعنجر": 1,
    "الثعد": 1,
    "ثعر": 1,
    "أثعر": 1,
    "الثعر": 1,
    "ثعرر": 1,
    "الثعارير": 1,
    "الثعرور": 1,
    "ثعط": 1,
    "ثعطه": 1,
    "الثعيط": 1,
    "ثع": 1,
    "انثع": 1,
    "ثعلت": 1,
    "أثعل": 1,
    "الأثعل": 1,
    "الثعل": 1,
    "الثعلول": 1,
    "الثعول": 1,
    "المثعلة": 1,
    "تثعلب": 1,
    "الثعلبان": 1,
    "الثعلبية": 1,
    "ثغب": 1,
    "تثغبت": 1,
    "الثغب": 1,
    "ثغثغ": 1,
    "أثغر": 1,
    "اثغر": 1,
    "الثغر": 1,
    "الثغرة": 1,
    "أثغم": 1,
    "ثاغمها": 1,
    "الثغامة": 1,
    "ثغت": 1,
    "أثغى": 1,
    "الثاغي": 1,
    "الثاغية": 1,
    "ثفأ": 1,
    "الثفاءة": 1,
    "ثفد": 1,
    "الثفافيد": 1,
    "أثفر": 1,
    "استثفر": 1,
    "الثفر": 1,
    "الثفروق": 1,
    "أثفل": 1,
    "ثافل": 1,
    "تثفل": 1,
    "الثافل": 1,
    "الثفال": 1,
    "الثفل": 1,
    "ثفنت": 1,
    "أثفن": 1,
    "ثافن": 1,
    "الثفنة": 1,
    "ثفاه": 1,
    "أثفى": 1,
    "ثفى": 1,
    "ثقببت": 1,
    "أثقب": 1,
    "انثقب": 1,
    "تثقب": 1,
    "الأثقوب": 1,
    "الثقبة": 1,
    "الثقابة": 1,
    "ثاقفه": 1,
    "تثاقفوا": 1,
    "الثقاف": 1,
    "ثقله": 1,
    "اثاقل": 1,
    "الثاقل": 1,
    "الثقالة": 1,
    "الثقلة": 1,
    "المثقال": 1,
    "المثقلة": 1,
    "أثكلت": 1,
    "الثكل": 1,
    "الثكول": 1,
    "الثلب والثلب": 1,
    "المثلب": 1,
    "المثلبة": 1,
    "أثلث": 1,
    "الثلاثون": 1,
    "الثلث الثلث": 1,
    "الثليث": 1,
    "أثلجت": 1,
    "الثلاجي": 1,
    "الثلاج": 1,
    "المثلجة": 1,
    "ثلخ": 1,
    "ثلخه": 1,
    "ثلط": 1,
    "الثلط": 1,
    "ثلغ": 1,
    "انثل": 1,
    "تثلل": 1,
    "الثلة": 1,
    "ثلمه": 1,
    "انثلم": 1,
    "تثلم": 1,
    "الثلم": 1,
    "الثلمة": 1,
    "ثمأ": 1,
    "ثمثم": 1,
    "تثمثم": 1,
    "الثمثام": 1,
    "الثمثم": 1,
    "ثمج": 1,
    "أثمج": 1,
    "المثمج": 1,
    "ثمد": 1,
    "أثمده": 1,
    "استثمد": 1,
    "الثمد": 1,
    "استثمر": 1,
    "الاستثمار": 1,
    "الثامر": 1,
    "الثمرة": 1,
    "الثمير": 1,
    "ثمغ": 1,
    "ثمغه": 1,
    "الثميغة": 1,
    "أثمل": 1,
    "تثمل": 1,
    "الثمال": 1,
    "الثملة": 1,
    "الثميلة": 1,
    "المثملة": 1,
    "ثمم": 1,
    "انثم": 1,
    "الثمة": 1,
    "المثم": 1,
    "المثمة": 1,
    "أثمن": 1,
    "ثامنه": 1,
    "الثمن الثمن": 1,
    "الثندوة": 1,
    "الثنطب": 1,
    "أثنت": 1,
    "الثن": 1,
    "الثنة": 1,
    "انثنى": 1,
    "تثانوا": 1,
    "تثنى": 1,
    "استثناه": 1,
    "الاثنان": 1,
    "الاثنتان": 1,
    "اثنا عشر": 1,
    "اثنتا عشرة": 1,
    "الاثنا عشري": 1,
    "الاثنا عشرية": 1,
    "الثنى": 1,
    "الثنوية": 1,
    "الثنيان": 1,
    "المثنى": 1,
    "المثنوي": 1,
    "أثابه": 1,
    "ثاوبه": 1,
    "ثيبت": 1,
    "تثوب": 1,
    "تثيبت": 1,
    "استثابه": 1,
    "الثائب": 1,
    "الثيب": 1,
    "المثاب والمثابة": 1,
    "المثوبة والمثوبة": 1,
    "أثاره": 1,
    "ثاوره": 1,
    "ثوره": 1,
    "تثور": 1,
    "تثاوروا": 1,
    "استثاره": 1,
    "المثورة": 1,
    "ثال": 1,
    "انثال": 1,
    "تثول": 1,
    "الأثول": 1,
    "الثول": 1,
    "الثوينى": 1,
    "أثوى": 1,
    "ثواه": 1,
    "تثواه": 1,
    "الثاية": 1,
    "الثوة": 1,
    "الثوي": 1,
    "الثوية": 1,
    "المثوى": 1,
    "الثيل": 1,
    "الجيم": 1,
    "الجازولين": 1,
    "جئ جئ": 1,
    "جأجأ": 1,
    "جأب": 1,
    "الجأب": 1,
    "الجأبة": 1,
    "جأث": 1,
    "جئث": 1,
    "أجأثه": 1,
    "انجأث": 1,
    "الجؤذر": 1,
    "جئر": 1,
    "الجؤار": 1,
    "الجأر": 1,
    "الجائر": 1,
    "جئز": 1,
    "أجأزه": 1,
    "جأشت": 1,
    "الجؤشوش": 1,
    "جأفه": 1,
    "جئف": 1,
    "اجتأفه": 1,
    "انجأفت": 1,
    "جيئل": 1,
    "جيئلة": 1,
    "الجيئل": 1,
    "جأى": 1,
    "جئي": 1,
    "أجأى": 1,
    "الجئاوة": 1,
    "الجئوة": 1,
    "جبأ": 1,
    "أجبأت": 1,
    "الجبء": 1,
    "الجبأة": 1,
    "الجبأى": 1,
    "الجبأ": 1,
    "الجباء": 1,
    "أجب": 1,
    "اجتب": 1,
    "تجاب": 1,
    "الجباب": 1,
    "الجبائية": 1,
    "الجبة": 1,
    "الجبوب": 1,
    "المجبة": 1,
    "الجبت": 1,
    "جبجب": 1,
    "تجبجب": 1,
    "الجبجبة": 1,
    "الجبخانة": 1,
    "جبذ": 1,
    "اجتبذه": 1,
    "انجبذ": 1,
    "أجبره": 1,
    "اجتبر": 1,
    "انجبر": 1,
    "استجبر": 1,
    "التجبار": 1,
    "الجبارة": 1,
    "الجبير": 1,
    "الجبرياء": 1,
    "الجبروت": 1,
    "الجبيرة": 1,
    "تجبس": 1,
    "الأجبس": 1,
    "الجباس": 1,
    "الجباسة": 1,
    "الجبيس": 1,
    "أجبل": 1,
    "جبله": 1,
    "تجبل": 1,
    "الجبلة": 1,
    "الجبيل": 1,
    "المجبال": 1,
    "أجبنه": 1,
    "جبنه": 1,
    "اجتبته": 1,
    "تجبن": 1,
    "التجبن": 1,
    "الجبانة": 1,
    "المجبنة": 1,
    "جبهه": 1,
    "اجتبه": 1,
    "الأجبه": 1,
    "الجابه": 1,
    "الجبه": 1,
    "جبا": 1,
    "أجبا": 1,
    "الجابية": 1,
    "الجبا": 1,
    "اجثأل": 1,
    "اجتث": 1,
    "انجث": 1,
    "الجث": 1,
    "الجثيثة": 1,
    "المجتث": 1,
    "المجثاث": 1,
    "المجثة": 1,
    "جثجث": 1,
    "تجثجث": 1,
    "الجثاجث": 1,
    "الجثجاث": 1,
    "جثلته": 1,
    "الجثالة": 1,
    "الجثلة": 1,
    "الجاثليق": 1,
    "جثمه": 1,
    "تجثم": 1,
    "الجاثوم": 1,
    "الجثام": 1,
    "الجثامة": 1,
    "الجثمان": 1,
    "الجثمة": 1,
    "أجثاه": 1,
    "جاثى": 1,
    "جثاه": 1,
    "تجاثوا": 1,
    "الجثاء": 1,
    "الجثوة": 1,
    "جحجح": 1,
    "الجحجاح": 1,
    "الجحجح": 1,
    "جحه": 1,
    "أجحت": 1,
    "انجح": 1,
    "الجحة": 1,
    "أجحد": 1,
    "جحدره": 1,
    "تجحدر": 1,
    "الجحدر": 1,
    "جحدل": 1,
    "الجحدل": 1,
    "أجحر": 1,
    "اجتحر": 1,
    "انجحر": 1,
    "تجحر": 1,
    "الجحرة": 1,
    "المجحر": 1,
    "جاحش": 1,
    "الجحشة": 1,
    "جحظت": 1,
    "تجاحظ": 1,
    "الجاحظة": 1,
    "الجاحظية": 1,
    "الجحاظ": 1,
    "جاحف": 1,
    "أجحف": 1,
    "اجتحف": 1,
    "تجاحفوا": 1,
    "الجحاف": 1,
    "الجحفة": 1,
    "جحفله": 1,
    "تجحفل": 1,
    "الجحفلة": 1,
    "جحله": 1,
    "الجحال": 1,
    "الجحل": 1,
    "جحم": 1,
    "جحمت": 1,
    "أجحم": 1,
    "جحمه": 1,
    "تجاحم": 1,
    "تجحم": 1,
    "الجاحم": 1,
    "الجحام": 1,
    "الجحمة": 1,
    "الجحمرش": 1,
    "جحن": 1,
    "أجحن": 1,
    "الجحن": 1,
    "جحا": 1,
    "اجتحى": 1,
    "جخا": 1,
    "جخى": 1,
    "تجخى": 1,
    "أجدب": 1,
    "جادبت": 1,
    "تجدبه": 1,
    "الأجدب": 1,
    "المجداب": 1,
    "اجتدث": 1,
    "الجدث": 1,
    "الجدجد": 1,
    "جدح": 1,
    "أجدح": 1,
    "جدحه": 1,
    "اجتدح": 1,
    "المجدح": 1,
    "جاده": 1,
    "استجد": 1,
    "الجداد": 1,
    "الجدادة": 1,
    "الجداء": 1,
    "أجدر": 1,
    "اجتدر": 1,
    "الجداري": 1,
    "الجدر": 1,
    "الجدير": 1,
    "الجديرة": 1,
    "المجدار": 1,
    "المجدرة": 1,
    "المجدور": 1,
    "جدس": 1,
    "جدعه": 1,
    "أجدعه": 1,
    "جادعه": 1,
    "تجادعا": 1,
    "الجداع": 1,
    "الجدعة": 1,
    "جدفت": 1,
    "أجدفوا": 1,
    "الأجدف": 1,
    "الجادوف": 1,
    "الجدف": 1,
    "الجدفة": 1,
    "أجدلت": 1,
    "جادله": 1,
    "جدله": 1,
    "اجتدل": 1,
    "انجدل": 1,
    "تجادلا": 1,
    "تجدل": 1,
    "الأجدل": 1,
    "الأجدلي": 1,
    "الجدالة": 1,
    "الجدلة": 1,
    "الجديل": 1,
    "المجدل": 1,
    "الجدام": 1,
    "الجدامة": 1,
    "الجدامية": 1,
    "الجدمة": 1,
    "جداه": 1,
    "أجدى": 1,
    "اجتداه": 1,
    "استجداه": 1,
    "الجادي": 1,
    "الجادياء": 1,
    "الجدا": 1,
    "الجداية": 1,
    "اجذأر": 1,
    "انجذب": 1,
    "تجذب": 1,
    "التجاذب المغنطيسي": 1,
    "الجاذبة": 1,
    "الجذبة": 1,
    "الجوذاب": 1,
    "المجذوب": 1,
    "جذه": 1,
    "جذذه": 1,
    "انجذ": 1,
    "تجذذ": 1,
    "الجذاذ": 1,
    "الجذاذة": 1,
    "الجذاء": 1,
    "الجذة": 1,
    "الجذيذ": 1,
    "المجذ": 1,
    "أجذر": 1,
    "انجذر": 1,
    "الجذر الأصم": 1,
    "الجذير": 1,
    "المجذر": 1,
    "المجذور": 1,
    "جذعه": 1,
    "أجذع": 1,
    "تجاذع": 1,
    "المجذع": 1,
    "أجذف": 1,
    "انجذف": 1,
    "تجذف": 1,
    "أجذله": 1,
    "اجتذل": 1,
    "تجاذلوا": 1,
    "استجذل": 1,
    "جذمه": 1,
    "جذمت": 1,
    "أجذم": 1,
    "انجذم": 1,
    "تجذم": 1,
    "الجذامة": 1,
    "الجذم": 1,
    "الجذمة": 1,
    "المجذام": 1,
    "المجذامة": 1,
    "المجذم": 1,
    "جذا": 1,
    "جذاه": 1,
    "أجذى": 1,
    "تجذى": 1,
    "تجاذى": 1,
    "اجذوى": 1,
    "اجذوذى": 1,
    "الجاذي": 1,
    "الجاذية": 1,
    "الجذاة": 1,
    "الجذوة": 1,
    "الجذي": 1,
    "المجذاء": 1,
    "جرأه": 1,
    "اجترأ": 1,
    "تجرأ": 1,
    "الجريئة": 1,
    "الجرية": 1,
    "اجرأش": 1,
    "الجرائض": 1,
    "جرافيت": 1,
    "الجرانيت": 1,
    "جربه": 1,
    "جورب وتجورب": 1,
    "الجرابيات": 1,
    "الجرباء": 1,
    "الجربان": 1,
    "الجربة": 1,
    "الجربياء": 1,
    "الجريب": 1,
    "تجرثم": 1,
    "اجرنثم": 1,
    "جرجت": 1,
    "جرج": 1,
    "جرجه": 1,
    "الجرجة": 1,
    "الجراجر": 1,
    "الجرجار": 1,
    "الجرجر": 1,
    "الجرجير": 1,
    "جرجم": 1,
    "تجرجم": 1,
    "جرحه": 1,
    "استجرح": 1,
    "الجارح": 1,
    "الجرحة": 1,
    "انجرد": 1,
    "الأجرد": 1,
    "الجارود": 1,
    "الجارودية": 1,
    "الجرداء": 1,
    "الجردة": 1,
    "الجرود": 1,
    "جردب": 1,
    "الجردق": 1,
    "جردم": 1,
    "الجردم": 1,
    "جرذت": 1,
    "أجرذه": 1,
    "الأجرذ": 1,
    "الجرذة": 1,
    "أجرت": 1,
    "جاره": 1,
    "جرره": 1,
    "استجر": 1,
    "الأجران": 1,
    "الجارة": 1,
    "الجارور": 1,
    "الجرارة": 1,
    "الجرور": 1,
    "الجرير": 1,
    "الجريرة": 1,
    "جرزت": 1,
    "أجرزت": 1,
    "جارزه": 1,
    "تجارزا": 1,
    "الجارز": 1,
    "الجارزة": 1,
    "الجراز": 1,
    "الجرزة": 1,
    "المجراز": 1,
    "أجرس": 1,
    "تجرس": 1,
    "الجاروس": 1,
    "الجرسة": 1,
    "جرشه": 1,
    "اجترش": 1,
    "الجراشة": 1,
    "الجرشي": 1,
    "قانون جريشام في الاقتصاد السياسي": 1,
    "جرضه": 1,
    "أجرضه": 1,
    "الجراض": 1,
    "أجرع": 1,
    "جرعه": 1,
    "اجترع": 1,
    "الأجرع": 1,
    "الجرعاء": 1,
    "أجرف المكان": 1,
    "جرفه": 1,
    "اجترفه": 1,
    "تجرفه": 1,
    "انجرف": 1,
    "الجاروف": 1,
    "الجرفة": 1,
    "أجرم": 1,
    "اجترم": 1,
    "تجرم": 1,
    "الجرام": 1,
    "الجرامة": 1,
    "الجرمة": 1,
    "الجريم": 1,
    "جرمز": 1,
    "اجرمز": 1,
    "تجرمز": 1,
    "الجراميز": 1,
    "الجرموز": 1,
    "الجرمقاني": 1,
    "أجرن": 1,
    "اجترن": 1,
    "الجارن": 1,
    "الجران": 1,
    "الجرن": 1,
    "الجرين": 1,
    "المجرن": 1,
    "الجروة": 1,
    "أجرى": 1,
    "جاراه": 1,
    "جراه": 1,
    "تجاروا": 1,
    "استجراه": 1,
    "الإجريا": 1,
    "الإجرياء": 1,
    "الإجرية": 1,
    "الجرى": 1,
    "الجرياء": 1,
    "الجريال": 1,
    "جزأت": 1,
    "أجزأ": 1,
    "جزأه": 1,
    "اجتزأ": 1,
    "تجزأ": 1,
    "الجازيء": 1,
    "الجازئة": 1,
    "الجزأة": 1,
    "جزحت": 1,
    "أجزر": 1,
    "اجتزر": 1,
    "انجزر": 1,
    "الجزارة": 1,
    "الجزور": 1,
    "المجزر": 1,
    "أجز": 1,
    "اجتز": 1,
    "استجز": 1,
    "الجازة": 1,
    "الجزازة": 1,
    "الجزز": 1,
    "الجزة": 1,
    "الجزوز": 1,
    "الجزوزة": 1,
    "المجز": 1,
    "أجزعه": 1,
    "اجتزعه": 1,
    "انجزع": 1,
    "تجزع": 1,
    "الجازع": 1,
    "الجزاع": 1,
    "الجزعة": 1,
    "المجزع": 1,
    "اجتزف": 1,
    "الجزاف": 1,
    "الجزافة": 1,
    "الجزفة": 1,
    "جزله": 1,
    "أجزله": 1,
    "استجزله": 1,
    "الجزال": 1,
    "الجزل": 1,
    "الجزلة": 1,
    "اجتزم": 1,
    "انجزم": 1,
    "تجزم": 1,
    "الجزام": 1,
    "الجزمية": 1,
    "أجزى": 1,
    "جازاه": 1,
    "اجتزاه": 1,
    "تجازى": 1,
    "الجازي": 1,
    "الجازية": 1,
    "جسأ": 1,
    "الجاسي": 1,
    "الجسوء البسيط": 1,
    "الجاسياء": 1,
    "جسده": 1,
    "جسد الدم": 1,
    "أجسد": 1,
    "الجساد": 1,
    "جسره": 1,
    "اجتسرت": 1,
    "الجسرة": 1,
    "اجتسه": 1,
    "الجاسة": 1,
    "الجساس": 1,
    "الجسيس": 1,
    "المجسة": 1,
    "تجسم": 1,
    "الجسام": 1,
    "الجسمان": 1,
    "الجسماني": 1,
    "الجسمي": 1,
    "الجسيم": 1,
    "الجسيمات الكنسلمانية": 1,
    "الجسان": 1,
    "الجسنة": 1,
    "جسي": 1,
    "جاساه": 1,
    "جشأت": 1,
    "اجتشأته": 1,
    "الجشء": 1,
    "الجشاء": 1,
    "الجشأة": 1,
    "جشب": 1,
    "الجشوب": 1,
    "المجشاب": 1,
    "المجشب": 1,
    "جشر": 1,
    "جشره": 1,
    "تجشر": 1,
    "الجاشر": 1,
    "الجاشرية": 1,
    "الجشار": 1,
    "الجشر": 1,
    "الجشرة": 1,
    "الجشير": 1,
    "المجشر": 1,
    "أجشت": 1,
    "اجتشت": 1,
    "الجشش": 1,
    "الجش": 1,
    "الجشان": 1,
    "الجشيشة": 1,
    "المجش": 1,
    "المجشة": 1,
    "تجاشعوا": 1,
    "تجشع": 1,
    "أجشمه": 1,
    "جشمه": 1,
    "تجشم": 1,
    "الجشم": 1,
    "جشن": 1,
    "الجشنة": 1,
    "جصص": 1,
    "الجصاص": 1,
    "الجصاصة": 1,
    "الجصيصة": 1,
    "جض": 1,
    "جضض": 1,
    "الجيضي": 1,
    "انجعب": 1,
    "تجعب": 1,
    "الجعابة": 1,
    "الجعبة": 1,
    "الجعاب": 1,
    "المجعب": 1,
    "الجعبوب": 1,
    "جعبره": 1,
    "الجعبر": 1,
    "الجعبري": 1,
    "تجعثن": 1,
    "الجعثن": 1,
    "الجعثنة": 1,
    "تجعجع": 1,
    "الجعجاع": 1,
    "الجعجع": 1,
    "تجعد": 1,
    "الجعادة": 1,
    "الجعد": 1,
    "الجعدة": 1,
    "تجعر": 1,
    "الجاعرة": 1,
    "جعار": 1,
    "الجعار": 1,
    "الجعر": 1,
    "الجعراء": 1,
    "الجعران": 1,
    "الجعرة": 1,
    "الجعري": 1,
    "المجعر": 1,
    "الجعرور": 1,
    "جعس": 1,
    "تجعس": 1,
    "الجعس": 1,
    "الجعيس": 1,
    "الجعسوس": 1,
    "الجعشم": 1,
    "الجعضيض": 1,
    "جعظ": 1,
    "أجعظ": 1,
    "الجعظ": 1,
    "جع": 1,
    "جعفه": 1,
    "أجعفه": 1,
    "اجتعف": 1,
    "انجعف": 1,
    "الجاعف": 1,
    "الجعاف": 1,
    "الجعف": 1,
    "الجعفر": 1,
    "الجعفرية": 1,
    "الجعفيل": 1,
    "جاعله": 1,
    "اجتعل": 1,
    "تجاعلوا": 1,
    "الجعال": 1,
    "الجعالة": 1,
    "الجعلة": 1,
    "الجعيلة": 1,
    "جعم": 1,
    "أجعم": 1,
    "تجعم": 1,
    "الجعام": 1,
    "الجعم": 1,
    "الجعمي": 1,
    "الجعوم": 1,
    "الجيعم": 1,
    "المجعم": 1,
    "جعا": 1,
    "الجعة": 1,
    "الجعو": 1,
    "الجعول": 1,
    "جفأ": 1,
    "أجفأت": 1,
    "اجتفأ": 1,
    "تجفأت": 1,
    "الجفت": 1,
    "جفخف": 1,
    "تجفجف": 1,
    "الجفاجف": 1,
    "الجفجف": 1,
    "جفخ": 1,
    "جافخه": 1,
    "أجفر": 1,
    "اجتفر": 1,
    "تجفر": 1,
    "استجفر": 1,
    "الجفرة": 1,
    "الجفرى": 1,
    "الجفير": 1,
    "الجفيرة": 1,
    "المجفر": 1,
    "المجفرة": 1,
    "جفس": 1,
    "الجفس": 1,
    "جفشه": 1,
    "جفظه": 1,
    "اجفأظ": 1,
    "الجفظ": 1,
    "الجفيظ": 1,
    "اجتف": 1,
    "تجفف": 1,
    "التجفاف": 1,
    "الجفافة": 1,
    "الجفف": 1,
    "الجف": 1,
    "الجفة": 1,
    "الجفيف": 1,
    "أجفل": 1,
    "جفله": 1,
    "انجفل": 1,
    "تجفل": 1,
    "الأجفلى": 1,
    "الأجفلة": 1,
    "الإجفيل": 1,
    "الجفال": 1,
    "الجفالة": 1,
    "الجفلى": 1,
    "الجفول": 1,
    "الجفيل": 1,
    "تجفن": 1,
    "الجفنة": 1,
    "جفى": 1,
    "أجفت": 1,
    "جافى": 1,
    "اجتفاه": 1,
    "تجافى": 1,
    "استجفاه": 1,
    "الجافي": 1,
    "الجفاية": 1,
    "أجلب": 1,
    "اجتلب": 1,
    "انجلب": 1,
    "تجلب": 1,
    "استجلب": 1,
    "الجالبه": 1,
    "الجلوبة": 1,
    "الجليب": 1,
    "الجليبة": 1,
    "جلببه": 1,
    "تجلبب": 1,
    "تجلجل": 1,
    "الجلاجل": 1,
    "الجلجال": 1,
    "الجلجلان": 1,
    "الجلجل": 1,
    "المجلجل": 1,
    "جالحه": 1,
    "الأجلح": 1,
    "الإجليح": 1,
    "الجالحة": 1,
    "الجلاح": 1,
    "الجلحاء": 1,
    "الجلحة": 1,
    "المجالح": 1,
    "المجلاح": 1,
    "اجلخ": 1,
    "الجلاخ": 1,
    "الجليخ": 1,
    "جلدت": 1,
    "أجلد": 1,
    "جالده": 1,
    "اجتلدوا": 1,
    "تجالدوا": 1,
    "الأجلد": 1,
    "التجاليد": 1,
    "الجلدة": 1,
    "المجلاد": 1,
    "المجلدة": 1,
    "اجلوذ": 1,
    "الجلاذي": 1,
    "الجلذاء": 1,
    "الجلذي": 1,
    "الجلوذ": 1,
    "جلز": 1,
    "جلوز": 1,
    "الجلاز": 1,
    "الجلز": 1,
    "الجلوز": 1,
    "الجلواز": 1,
    "المجلوز": 1,
    "أجلسه": 1,
    "جالسه": 1,
    "تجالسوا": 1,
    "استجلسه": 1,
    "الجلس": 1,
    "الجلسي": 1,
    "الجلسان": 1,
    "الجليس": 1,
    "جلسرين": 1,
    "جلاش": 1,
    "تجلط": 1,
    "اجتلطه": 1,
    "انجلط": 1,
    "جلع": 1,
    "جالع": 1,
    "انجلع": 1,
    "تجالعوا": 1,
    "الجليع": 1,
    "جلفه": 1,
    "أجلف": 1,
    "اجتلفه": 1,
    "تجلف": 1,
    "الجالفة": 1,
    "الجلاف": 1,
    "الجلف": 1,
    "الجلفة": 1,
    "الجليف": 1,
    "الجليفة": 1,
    "الجلفاط": 1,
    "الجلفق": 1,
    "جلق": 1,
    "تجلق": 1,
    "الجلاقة": 1,
    "الجلقة": 1,
    "الجلق": 1,
    "الجوالق": 1,
    "المنجليق": 1,
    "اجتل": 1,
    "تجال": 1,
    "تجلل": 1,
    "إجلال": 1,
    "التجلة": 1,
    "الجالة": 1,
    "الجلائل": 1,
    "الجل": 1,
    "الجلل": 1,
    "الجلى": 1,
    "اجتلم": 1,
    "الجلامة": 1,
    "الجلم": 1,
    "الجلمان": 1,
    "الجلمد": 1,
    "الجلمدة": 1,
    "الجلمود": 1,
    "جلمق": 1,
    "الجلماق": 1,
    "الجلمق": 1,
    "جلنبلق": 1,
    "الجلنار": 1,
    "الأجله": 1,
    "الجلهة": 1,
    "الجليهة": 1,
    "جلهز": 1,
    "الجلاهق": 1,
    "الجلهم": 1,
    "الجلهمة": 1,
    "الجلهوم": 1,
    "جليت": 1,
    "أجلى": 1,
    "جاليته": 1,
    "اجتلى": 1,
    "انجلى": 1,
    "تجاليا": 1,
    "اجلولى": 1,
    "الأجلى": 1,
    "الجلا": 1,
    "الجلية": 1,
    "المجالي": 1,
    "الجلوكوما": 1,
    "جمئ": 1,
    "تجمأ": 1,
    "الجمأ": 1,
    "الجماء": 1,
    "المجمأ": 1,
    "تجمجم": 1,
    "الجمجم": 1,
    "الجماح": 1,
    "الجمحل": 1,
    "جمخ": 1,
    "جامخه": 1,
    "انجمخ": 1,
    "أجمد": 1,
    "الجامد": 1,
    "الجماد": 1,
    "أجمر": 1,
    "اجتمر": 1,
    "تجمر": 1,
    "استجمر": 1,
    "الجامور": 1,
    "الجمار": 1,
    "الجمارا": 1,
    "جمارى": 1,
    "الجمير": 1,
    "الجميرة": 1,
    "المجمر": 1,
    "جمز": 1,
    "الجمز": 1,
    "الجمزة": 1,
    "الجماز": 1,
    "الجمازة": 1,
    "الجميزى": 1,
    "جمس": 1,
    "الجاموس": 1,
    "الجماسية": 1,
    "الجمسة": 1,
    "الجموش": 1,
    "الجميش": 1,
    "استجمع": 1,
    "الجمعاء": 1,
    "المجمعة": 1,
    "جامله": 1,
    "جمله": 1,
    "اجتمل": 1,
    "تجمل": 1,
    "استجمل": 1,
    "الجامل": 1,
    "الجمالي": 1,
    "الجملانة": 1,
    "الجمول": 1,
    "عند النطاح يغلب الكبش الأجم": 1,
    "جمم": 1,
    "تجمم": 1,
    "استجم": 1,
    "الجمام": 1,
    "الجمامة": 1,
    "الجم": 1,
    "الجمم": 1,
    "الجماني": 1,
    "الجموم": 1,
    "الجميم": 1,
    "المجم": 1,
    "المجمة": 1,
    "الجمان": 1,
    "جمهر": 1,
    "تجمهر": 1,
    "الجماهر": 1,
    "الجمهرة": 1,
    "الجمهورة": 1,
    "تجمى": 1,
    "الجما": 1,
    "الجماءة": 1,
    "جنأ": 1,
    "جنئ": 1,
    "أجنأ": 1,
    "جانأ": 1,
    "اجتنأ": 1,
    "تجانأ": 1,
    "الأجنأ": 1,
    "المجنأ": 1,
    "المجنأة": 1,
    "أجنب": 1,
    "اجتنب": 1,
    "تجانب": 1,
    "استجنب": 1,
    "الأجنب": 1,
    "الجناب": 1,
    "الجنابى": 1,
    "الجناباء": 1,
    "الجنابة": 1,
    "الجنيب": 1,
    "الجنيبة": 1,
    "المجنب": 1,
    "المجنبة": 1,
    "تجنث": 1,
    "الجنث": 1,
    "الجنثي": 1,
    "الجنجن والجنجن": 1,
    "الجنجنة والجنجنة": 1,
    "الجنجون": 1,
    "أجنح": 1,
    "جنحه": 1,
    "اجتنح": 1,
    "تجنح": 1,
    "استجنح": 1,
    "الجانحة": 1,
    "الجناحية": 1,
    "الجنبح": 1,
    "المجنحة": 1,
    "الجنادي": 1,
    "الجند": 1,
    "الجندية": 1,
    "الجندرة": 1,
    "الجنادل": 1,
    "الجندل": 1,
    "الجنائزي": 1,
    "الجنزير": 1,
    "جنست": 1,
    "جانسه": 1,
    "تجانسا": 1,
    "التجنيس": 1,
    "الجنيس": 1,
    "أجنف": 1,
    "جانف": 1,
    "تجانف": 1,
    "الأجنف": 1,
    "الجنافي": 1,
    "المجنف": 1,
    "جنقه": 1,
    "الجنق": 1,
    "المنجنيق": 1,
    "جننه": 1,
    "اجتن": 1,
    "تجان وتجانن": 1,
    "تجنن": 1,
    "استجن": 1,
    "التجنين": 1,
    "الجنن": 1,
    "المجنة": 1,
    "أجنى": 1,
    "جانى": 1,
    "اجتنى": 1,
    "تجنى": 1,
    "الجاني": 1,
    "الجنى": 1,
    "الجناية": 1,
    "الجاهب": 1,
    "الجهب": 1,
    "المجهب": 1,
    "الجهباذ": 1,
    "الجهبذ": 1,
    "الجهبل": 1,
    "الجهبلة": 1,
    "جهث": 1,
    "جهجه": 1,
    "تجهجه": 1,
    "اجتهد": 1,
    "تجاهد": 1,
    "الجهادى": 1,
    "الجهدان": 1,
    "الجهيدى": 1,
    "المجتهد": 1,
    "المجهود": 1,
    "أجهر": 1,
    "جاهره": 1,
    "جهور": 1,
    "تجاهر": 1,
    "اجتهر": 1,
    "الجهار": 1,
    "الجهراء": 1,
    "الجهرة": 1,
    "الجهور": 1,
    "الجهير": 1,
    "الجهيرة": 1,
    "المجهار": 1,
    "المجهور": 1,
    "أجهز": 1,
    "جهزه": 1,
    "تجهز": 1,
    "الجهيز": 1,
    "الجهيزة": 1,
    "جهشت": 1,
    "جهش": 1,
    "أجهش": 1,
    "الجهشة": 1,
    "جهض": 1,
    "أجهضت": 1,
    "جاهضه": 1,
    "الجاهض": 1,
    "الجاهضة": 1,
    "الجهاض": 1,
    "الجهض": 1,
    "الجهاضة": 1,
    "المجهاض": 1,
    "تجهضم": 1,
    "الجهضم": 1,
    "جهلت": 1,
    "أجهله": 1,
    "جهله": 1,
    "جاهله": 1,
    "اجتهله": 1,
    "استجهله": 1,
    "الجيهل": 1,
    "الجيهلة": 1,
    "المجهال": 1,
    "المجهل": 1,
    "المجهلة": 1,
    "جهمه": 1,
    "جهم": 1,
    "أجهمت": 1,
    "اجتهم": 1,
    "تجهمه": 1,
    "الجهام": 1,
    "الجهمة": 1,
    "الجهمية": 1,
    "الجهوم": 1,
    "الجيهمان": 1,
    "جهن": 1,
    "الجهانة": 1,
    "الجهن": 1,
    "الجهنة": 1,
    "جهينة": 1,
    "الجهنام": 1,
    "جهنمية": 1,
    "جهيت": 1,
    "أجهت": 1,
    "جاهاه": 1,
    "جهى": 1,
    "الجاهي": 1,
    "الجهوة": 1,
    "أجابت": 1,
    "جاوبه": 1,
    "اجتاب": 1,
    "استجابه": 1,
    "استجوبه": 1,
    "الجوب": 1,
    "الجوبة": 1,
    "المجواب": 1,
    "المجوب": 1,
    "المجوبة": 1,
    "جوث": 1,
    "أجاحت": 1,
    "اجتاحت": 1,
    "الأجوح": 1,
    "الجائحة": 1,
    "الجوح": 1,
    "الجوحة": 1,
    "المجوح": 1,
    "جاخ": 1,
    "تجوخت": 1,
    "الجوخة": 1,
    "أجاد": 1,
    "جاوده": 1,
    "تجاودوا": 1,
    "تجود": 1,
    "استجاد": 1,
    "التجاويد": 1,
    "أجاره": 1,
    "جاوره": 1,
    "جوره": 1,
    "اجتور": 1,
    "تجور": 1,
    "جوربه": 1,
    "تجورب": 1,
    "أجاز": 1,
    "اجتاز": 1,
    "تجوز": 1,
    "استجاز": 1,
    "الجائز": 1,
    "الجواز": 1,
    "الجيز": 1,
    "الجيزة": 1,
    "المجازة": 1,
    "اجتاس": 1,
    "الجوسق": 1,
    "تجوش": 1,
    "الجوش": 1,
    "الجوشن": 1,
    "أجاعه": 1,
    "استجاع": 1,
    "المجاع": 1,
    "المجوعة": 1,
    "جافه": 1,
    "أجافه": 1,
    "اجتافه": 1,
    "تجوف": 1,
    "استجاف": 1,
    "استجوف": 1,
    "الأجوفان": 1,
    "الجائف": 1,
    "الجائفة": 1,
    "الجواف": 1,
    "الجوافة": 1,
    "المجوف": 1,
    "تجوق": 1,
    "المجوق": 1,
    "أجاله": 1,
    "جاوله": 1,
    "اجتال": 1,
    "تجاولوا": 1,
    "استجاله": 1,
    "الجال": 1,
    "الجولان": 1,
    "الجولاني": 1,
    "الجوالة": 1,
    "الجويل": 1,
    "المجول": 1,
    "الجام": 1,
    "الجامة": 1,
    "الجوم": 1,
    "الجون": 1,
    "الجوناء": 1,
    "الجونة": 1,
    "الجواني": 1,
    "الجوني": 1,
    "تجوه": 1,
    "الجاه": 1,
    "الجاهة": 1,
    "اجتوى": 1,
    "استجوى": 1,
    "الجواء": 1,
    "الجواءة": 1,
    "الجوة": 1,
    "أجاء": 1,
    "جايأه": 1,
    "جيأ": 1,
    "الجايئة": 1,
    "الجياء": 1,
    "الجياءة": 1,
    "الجيئة": 1,
    "يجيد ويجاد": 1,
    "جيره": 1,
    "الجيار": 1,
    "الجيز والجيزة": 1,
    "تجيشت": 1,
    "استجاشت": 1,
    "الجيشان": 1,
    "جاض": 1,
    "جايضهم": 1,
    "جيض": 1,
    "جافت": 1,
    "جيفت": 1,
    "اجتافت": 1,
    "انجافت": 1,
    "الجيولوجيا": 1,
    "الحاء": 1,
    "حأحأ": 1,
    "حابه": 1,
    "تحابوا": 1,
    "استحبه": 1,
    "الحاب": 1,
    "الحباب": 1,
    "الحبب": 1,
    "المستحب": 1,
    "حبتر": 1,
    "الحباتر والحبتر": 1,
    "تحبجرت": 1,
    "الحباحب": 1,
    "الحبحب": 1,
    "حبره": 1,
    "أحبرت": 1,
    "الحابور": 1,
    "الحبارى": 1,
    "الحبرة": 1,
    "الحبير": 1,
    "الحبركى": 1,
    "حبسه": 1,
    "أحبسه": 1,
    "احتبس": 1,
    "تحبس": 1,
    "الحابس": 1,
    "الحباسة": 1,
    "الحبسة": 1,
    "الحبيس": 1,
    "حبش": 1,
    "أحبشت": 1,
    "احتبش": 1,
    "تحبش": 1,
    "الأحبش": 1,
    "الأحبوش والأحبوشة": 1,
    "الحباشة": 1,
    "الحباشية": 1,
    "حبض": 1,
    "أحبض": 1,
    "الحباض": 1,
    "الحبض": 1,
    "المحبض": 1,
    "الحباط": 1,
    "الحبق": 1,
    "الحبقى": 1,
    "الحبقة": 1,
    "احتبك": 1,
    "تحبك": 1,
    "الحباك": 1,
    "الحبيكة": 1,
    "المحبوك": 1,
    "حبلت": 1,
    "أحبله": 1,
    "احتبل": 1,
    "تحبلت": 1,
    "الأحبول والأحبولة": 1,
    "الحابول": 1,
    "الحبلان": 1,
    "الحبلة": 1,
    "المحبل": 1,
    "المحتبل": 1,
    "أحبنه": 1,
    "الحبن": 1,
    "الحبناء": 1,
    "الحبنة": 1,
    "الحبين": 1,
    "أحبى": 1,
    "حاباه": 1,
    "احتبى": 1,
    "الحبا": 1,
    "الحباء": 1,
    "الحبوة": 1,
    "حتأ": 1,
    "أحتأ": 1,
    "الحتء": 1,
    "الحتيء": 1,
    "أحت": 1,
    "انحت": 1,
    "الحتات والحتاتة": 1,
    "الحتات": 1,
    "الحت": 1,
    "الحتت": 1,
    "الحتة": 1,
    "الحتوت والمحتات": 1,
    "حتحت": 1,
    "تحتحت": 1,
    "حتده": 1,
    "الحتود": 1,
    "المحتد": 1,
    "أحتر": 1,
    "الحتار": 1,
    "الحتر": 1,
    "الحترة": 1,
    "الحتيرة": 1,
    "الحتف": 1,
    "الحتفل": 1,
    "حتك": 1,
    "الحوتك": 1,
    "أحتم": 1,
    "انحتم": 1,
    "تحتم": 1,
    "الحاتم": 1,
    "الحتامة": 1,
    "الحتم": 1,
    "الحتمة": 1,
    "حتنت": 1,
    "أحتن": 1,
    "حاتن": 1,
    "احتتن": 1,
    "تحاتنت": 1,
    "الحاتن": 1,
    "الحتن": 1,
    "الحتنى": 1,
    "حتا": 1,
    "أحتى": 1,
    "الحاتي": 1,
    "الحتي": 1,
    "حثه": 1,
    "أحثه": 1,
    "حثث": 1,
    "احتثه": 1,
    "تحاث": 1,
    "استحثه": 1,
    "الحثاث": 1,
    "الحثاثة": 1,
    "الحثيثي": 1,
    "الحثوث": 1,
    "الحثيث": 1,
    "المحثة": 1,
    "حثحث": 1,
    "الحثحاث": 1,
    "الحثحوث": 1,
    "حثر": 1,
    "أحثر": 1,
    "الحثر": 1,
    "الحثرة": 1,
    "الحثيرة": 1,
    "المحثر": 1,
    "حثرب": 1,
    "الحثرب": 1,
    "الحثرفة": 1,
    "حثرمت": 1,
    "الحثارم": 1,
    "الحثرمة": 1,
    "الحثافير": 1,
    "الحثفر": 1,
    "الحثفرة": 1,
    "حثل": 1,
    "أحثله": 1,
    "الحثل": 1,
    "الحثلة": 1,
    "الحثمة": 1,
    "الحوثم": 1,
    "حثى": 1,
    "أحثاه": 1,
    "احتثى": 1,
    "استحثوا": 1,
    "الحاثياء": 1,
    "الحثا": 1,
    "الحثى": 1,
    "الحثواء": 1,
    "الحثوة": 1,
    "الحثية": 1,
    "حجأ": 1,
    "تحجأ": 1,
    "المحجأ": 1,
    "تحجب": 1,
    "استحجبه": 1,
    "الحجابة": 1,
    "الحجبة": 1,
    "أحج": 1,
    "حاجه": 1,
    "احتج": 1,
    "تحاجوا": 1,
    "المحجاج": 1,
    "حجحج": 1,
    "احتجر": 1,
    "استحجر": 1,
    "الحاجر": 1,
    "الحاجور": 1,
    "الحاجورة": 1,
    "أحجز": 1,
    "حاجزه": 1,
    "احتجز": 1,
    "انحجز": 1,
    "تحاجز": 1,
    "تحجز": 1,
    "حجازيك": 1,
    "الحجزة": 1,
    "حجف": 1,
    "الحجاف": 1,
    "الحجفة": 1,
    "الحجيف": 1,
    "حجلت": 1,
    "أحجل": 1,
    "التحجيل": 1,
    "المحجل": 1,
    "أحجم": 1,
    "احتجم": 1,
    "الحجام": 1,
    "الحجامة": 1,
    "المحجم": 1,
    "المحجمة": 1,
    "حجنه": 1,
    "احتجن": 1,
    "تحجن": 1,
    "التحجين": 1,
    "الحجنة": 1,
    "الحجون": 1,
    "المحجن": 1,
    "المحجنة": 1,
    "أحجى": 1,
    "حاجاه": 1,
    "احتجى": 1,
    "تحجى": 1,
    "استحجى": 1,
    "الأحجوة": 1,
    "الأحجية": 1,
    "الحجا": 1,
    "الحجوى": 1,
    "الحجيا": 1,
    "حدأه": 1,
    "حدئ": 1,
    "الحدأة": 1,
    "حدبت": 1,
    "أحدبه": 1,
    "حدبه": 1,
    "تحادب": 1,
    "تحدب": 1,
    "حادثه": 1,
    "استحدثه": 1,
    "الأحدوثة": 1,
    "الحدثان": 1,
    "الحديثي": 1,
    "المحدث": 1,
    "المحدثون": 1,
    "حدجه": 1,
    "أحدجت": 1,
    "الحدج": 1,
    "المحدج": 1,
    "أحدت": 1,
    "حادت": 1,
    "احتد": 1,
    "تحادوا": 1,
    "تحدد": 1,
    "استحد": 1,
    "حدادك": 1,
    "الحدد": 1,
    "حدرت": 1,
    "أحدر": 1,
    "انحدر": 1,
    "تحادر": 1,
    "الأحدور": 1,
    "الحادر": 1,
    "الحادرة": 1,
    "الحادور": 1,
    "الحدر": 1,
    "الحدراء": 1,
    "الحدرة": 1,
    "الحدور": 1,
    "حدرج": 1,
    "الحدرج": 1,
    "الحدروج": 1,
    "المحدرج": 1,
    "تحدس": 1,
    "الحدسية": 1,
    "أحدقت": 1,
    "الحدقة": 1,
    "حادله": 1,
    "تحادل": 1,
    "الأحدل": 1,
    "الحدل": 1,
    "حدمه": 1,
    "حدمت": 1,
    "أحدمت": 1,
    "احتدمت": 1,
    "تحدم": 1,
    "الحدمة": 1,
    "احتدى": 1,
    "الأحدوة والأحدية": 1,
    "الحداء": 1,
    "الحدى": 1,
    "الحدواء": 1,
    "الحديا": 1,
    "حذه": 1,
    "حذ": 1,
    "الأحذ": 1,
    "الحذذ": 1,
    "الحذة": 1,
    "حاذره": 1,
    "الحاذورة": 1,
    "الحذرية": 1,
    "الحذير": 1,
    "المحذور": 1,
    "المحذورة": 1,
    "احتذف": 1,
    "الحذفاء": 1,
    "الحذفة": 1,
    "المحذوف": 1,
    "حذفره": 1,
    "الحذفار والحذفور": 1,
    "أحذقه": 1,
    "انحذق": 1,
    "تحذق": 1,
    "الحذاقي": 1,
    "حذلت": 1,
    "أحذل": 1,
    "الحذال": 1,
    "الحذل": 1,
    "تحذلق": 1,
    "الحذلق": 1,
    "حذلم": 1,
    "تحذلم": 1,
    "الحذلوم": 1,
    "حذم": 1,
    "الحذم": 1,
    "الحذيم": 1,
    "الحذمور": 1,
    "الحذن": 1,
    "الحذنة": 1,
    "أحذاه": 1,
    "حاذاه": 1,
    "احتذى": 1,
    "تحاذى": 1,
    "استحذاه": 1,
    "الحاذي": 1,
    "الحذاوة": 1,
    "الحذاية": 1,
    "الحذوة": 1,
    "الحذيا": 1,
    "الحذية": 1,
    "المحذى": 1,
    "المحذاء": 1,
    "حربه": 1,
    "أحرب": 1,
    "حاربه": 1,
    "احتربوا": 1,
    "تحاربوا": 1,
    "احرنبى": 1,
    "الحرابة": 1,
    "الحريبة": 1,
    "المحرب": 1,
    "المحروبة": 1,
    "حرته": 1,
    "الحرتة": 1,
    "أحرث": 1,
    "احترث": 1,
    "الحراث": 1,
    "المحراث والمحرث": 1,
    "الحارج": 1,
    "الحروج": 1,
    "الحريج": 1,
    "المحراج": 1,
    "المحرجة": 1,
    "الحرجوج": 1,
    "الحرجف": 1,
    "حرجل": 1,
    "الحرجلة": 1,
    "حرجم": 1,
    "احرنجم": 1,
    "حرده": 1,
    "أحرد": 1,
    "حاردت": 1,
    "انحرد": 1,
    "تحرد": 1,
    "الأحرد": 1,
    "الحارد": 1,
    "الحرد": 1,
    "الحرود": 1,
    "الحريد": 1,
    "حردب": 1,
    "استحر": 1,
    "الأحر": 1,
    "الحراريات": 1,
    "الحرار": 1,
    "الحرورية": 1,
    "الحريرة": 1,
    "المحر": 1,
    "المحرور": 1,
    "حرزه": 1,
    "أحرزه": 1,
    "احترز": 1,
    "تحرز": 1,
    "استحرز": 1,
    "الحارز": 1,
    "الحرز": 1,
    "الحرزة": 1,
    "الحريز": 1,
    "الحريزة": 1,
    "حرسه": 1,
    "أحرس": 1,
    "تحرس": 1,
    "الاحتراس": 1,
    "الأحرس": 1,
    "الحرسي": 1,
    "الحريسة": 1,
    "المحروسة": 1,
    "حرشه": 1,
    "أحرش": 1,
    "حارشه": 1,
    "احترش": 1,
    "الحرش": 1,
    "الحرشاء": 1,
    "الحريش": 1,
    "الحريشة": 1,
    "المحراش": 1,
    "الحرشف": 1,
    "حرصه": 1,
    "احترص": 1,
    "الحارصة": 1,
    "الحرصة": 1,
    "الحرصيان": 1,
    "الحريصة": 1,
    "أحرض": 1,
    "حرضه": 1,
    "تحارضوا": 1,
    "الأحرض": 1,
    "الإحريض": 1,
    "الحارضة": 1,
    "الحراض": 1,
    "الحراضة": 1,
    "الحرض": 1,
    "الحرضة": 1,
    "المحرضة": 1,
    "حارف": 1,
    "حورف": 1,
    "احترف": 1,
    "تحرف": 1,
    "الحرافة": 1,
    "الحريف": 1,
    "المحراف": 1,
    "المحرف": 1,
    "أحرقت": 1,
    "حرقت": 1,
    "الحاروقة": 1,
    "الحراق": 1,
    "الحراقة": 1,
    "الحرقان": 1,
    "الحرقة": 1,
    "الحرقوة": 1,
    "الحريقة": 1,
    "الحرقدة": 1,
    "حرقص": 1,
    "الحرقوص": 1,
    "الحرقفة": 1,
    "الحرقوف": 1,
    "حركه": 1,
    "الحارك": 1,
    "الحراك": 1,
    "الحرك": 1,
    "المحراك": 1,
    "الحرككة": 1,
    "الحركوك": 1,
    "أحرم": 1,
    "احترمه": 1,
    "استحرم": 1,
    "الحرامي": 1,
    "الحرمي": 1,
    "الحريمة": 1,
    "المحرمة والمحرمة": 1,
    "حرمد": 1,
    "حرمزه": 1,
    "تحرمز": 1,
    "الحرماس": 1,
    "الحرمس": 1,
    "الحرملة": 1,
    "حرنت": 1,
    "المحران": 1,
    "أحراه": 1,
    "تحرى": 1,
    "الأحرى": 1,
    "الحرا": 1,
    "الحرى": 1,
    "الحراوة": 1,
    "الحروة": 1,
    "حزأ": 1,
    "احزوزأ": 1,
    "حزبهم": 1,
    "تحازب": 1,
    "الحزباءة": 1,
    "حزحز": 1,
    "تحزحز": 1,
    "الحزحزة": 1,
    "الحزراء": 1,
    "الحزرة": 1,
    "الحزور": 1,
    "الحزورة": 1,
    "الحزيرة": 1,
    "حزه": 1,
    "أحز": 1,
    "حازه": 1,
    "حززه": 1,
    "احتزه": 1,
    "الحزاز": 1,
    "الحزازي": 1,
    "الحزز": 1,
    "الحزة": 1,
    "الحزيز": 1,
    "حزق": 1,
    "أحزقه": 1,
    "تحزق": 1,
    "الحازق": 1,
    "الحازقة": 1,
    "الحزاق": 1,
    "الحزق": 1,
    "الحزقة": 1,
    "احتزك": 1,
    "حزمه": 1,
    "أحزمه": 1,
    "احتزم": 1,
    "تحزم": 1,
    "الأحزم": 1,
    "الحزيم": 1,
    "الحيزوم": 1,
    "المحزم المحزمة": 1,
    "أحزن": 1,
    "تحازن": 1,
    "الحزانة": 1,
    "حزا": 1,
    "أحزى": 1,
    "تحزى": 1,
    "الحازي": 1,
    "الحزاء": 1,
    "حاسبه": 1,
    "تحاسبا": 1,
    "الحسب": 1,
    "الحسبة": 1,
    "الحسيب": 1,
    "المحتسب": 1,
    "حسبل": 1,
    "حسحس": 1,
    "تحسحس": 1,
    "الحسحاس": 1,
    "حسده": 1,
    "أحسده": 1,
    "تحاسدا": 1,
    "المحسدة": 1,
    "الحسدل": 1,
    "أحسر": 1,
    "انحسر": 1,
    "استحسر": 1,
    "الحاسر": 1,
    "الحسار": 1,
    "المحسر": 1,
    "انحس": 1,
    "الحاسة": 1,
    "الحسيس": 1,
    "المحسة": 1,
    "حسف": 1,
    "أحسفه": 1,
    "حسفه": 1,
    "انحسف": 1,
    "تحسف": 1,
    "الحساف": 1,
    "الحسافة": 1,
    "الحشف": 1,
    "الحسيفة": 1,
    "أحسك": 1,
    "الحساكة": 1,
    "الحسك": 1,
    "الحسكة": 1,
    "الحسيكة": 1,
    "حسل": 1,
    "احتسل": 1,
    "الحسالة": 1,
    "الحسل": 1,
    "الحسيل": 1,
    "الحسيلة": 1,
    "الأحسم": 1,
    "الحسام": 1,
    "الحسوم": 1,
    "المحسمة": 1,
    "المحسوم": 1,
    "حاسنه": 1,
    "استحسنه": 1,
    "الأحسن": 1,
    "التحاسين": 1,
    "أحساه": 1,
    "حاساه": 1,
    "حساه": 1,
    "احتسى": 1,
    "تحاسيا": 1,
    "تحسى": 1,
    "الحسا": 1,
    "الحسو": 1,
    "الحسوة": 1,
    "حسى": 1,
    "الحسى": 1,
    "حشأه": 1,
    "المحشأة": 1,
    "أحشبه": 1,
    "احتشبوا": 1,
    "الحشيب": 1,
    "حشحشوا": 1,
    "تحشحشوا": 1,
    "أحشدوا": 1,
    "احتشدوا": 1,
    "تحاشدوا": 1,
    "تحشدوا": 1,
    "الحاشد": 1,
    "الحشاد": 1,
    "المحشود": 1,
    "حشرهم": 1,
    "حشره": 1,
    "احتشر": 1,
    "انحشر": 1,
    "تحشر": 1,
    "الحاشر": 1,
    "الحشر الحشر": 1,
    "المحشر": 1,
    "المحشرة": 1,
    "الحشرج": 1,
    "أحش": 1,
    "احتش": 1,
    "استحش": 1,
    "الأحشوش": 1,
    "الحشاشى": 1,
    "الحشاشة": 1,
    "الحش": 1,
    "الحشة": 1,
    "الحشيشة": 1,
    "المحش": 1,
    "أحشف": 1,
    "تحشف": 1,
    "استحشف": 1,
    "الحشافة": 1,
    "الحشفة": 1,
    "الحشيف": 1,
    "أحشك": 1,
    "الحشاك": 1,
    "الحشكة": 1,
    "الحشوك": 1,
    "الحشيكة": 1,
    "أحشمه": 1,
    "حشمه": 1,
    "احتشم": 1,
    "الحشوم": 1,
    "الحشيم": 1,
    "حشن": 1,
    "أحشن": 1,
    "حاشنه": 1,
    "تحشن": 1,
    "الحشان": 1,
    "الحشنة": 1,
    "الحشور": 1,
    "الحشورة": 1,
    "حشي": 1,
    "أحشاه": 1,
    "احتشى": 1,
    "انحشى": 1,
    "تحاشى": 1,
    "تحشت": 1,
    "الحاشيتان": 1,
    "الحشاة": 1,
    "الحشوية الحشوية": 1,
    "الحشي": 1,
    "حصأ": 1,
    "أحصأه": 1,
    "حصبه": 1,
    "أحصب": 1,
    "تحاصبا": 1,
    "تحصب": 1,
    "الحاصب": 1,
    "الحصاب": 1,
    "الحصب": 1,
    "المحصبة": 1,
    "المحصب": 1,
    "تحصحص": 1,
    "الحصحاص": 1,
    "الحصحص والحصحوص": 1,
    "أحصد": 1,
    "احتصد": 1,
    "استحصد": 1,
    "تحصدوا": 1,
    "الحصد": 1,
    "الحصداء": 1,
    "الحصيدة": 1,
    "المحصد": 1,
    "أحصر": 1,
    "حاصره": 1,
    "احتصر": 1,
    "الحصور": 1,
    "الحصير": 1,
    "الحصيرة": 1,
    "المحصورة": 1,
    "تحصرم": 1,
    "الحصرم": 1,
    "أحصه": 1,
    "انحص": 1,
    "تحاصوا": 1,
    "تحصص": 1,
    "الأحص": 1,
    "الحصاء": 1,
    "الحاصة": 1,
    "الحصاصة": 1,
    "الحص": 1,
    "الحصيص": 1,
    "الحصيصة": 1,
    "أحصف": 1,
    "استحصف": 1,
    "المحصاف": 1,
    "المحصف والمحصف": 1,
    "أحصل": 1,
    "الحصالة": 1,
    "الحصل": 1,
    "الحصيل": 1,
    "الحصيلة": 1,
    "حصم": 1,
    "انحصم": 1,
    "الحصيم": 1,
    "المحصمة": 1,
    "الحاصن والحاصنة": 1,
    "الحصناء": 1,
    "حصاه": 1,
    "الحصو": 1,
    "حصيت": 1,
    "حصي": 1,
    "تحصى": 1,
    "استحصى": 1,
    "الحصي": 1,
    "المحصاة": 1,
    "حضأت": 1,
    "احتضأ": 1,
    "الحضاء": 1,
    "الحضيء": 1,
    "المحضأ": 1,
    "حضب": 1,
    "أحضبت": 1,
    "تحضب": 1,
    "الحضب الحضب": 1,
    "الحضب": 1,
    "المحضب": 1,
    "حضج": 1,
    "انحضج": 1,
    "الحضج": 1,
    "الحضيج": 1,
    "المحضاج": 1,
    "المحضج": 1,
    "المحضجة": 1,
    "احتضر": 1,
    "استحضره": 1,
    "الحضار": 1,
    "الحضر": 1,
    "الحضراء": 1,
    "الحضوري": 1,
    "المحضار": 1,
    "المحضر": 1,
    "المحضور": 1,
    "حضرم": 1,
    "الحضرمي": 1,
    "حضه": 1,
    "حاض": 1,
    "حضضه": 1,
    "تحاضوا": 1,
    "التحضيض": 1,
    "الحضض": 1,
    "الحضيضي": 1,
    "الحضيضة": 1,
    "حضنه": 1,
    "أحضن": 1,
    "احتضن": 1,
    "المحتضن": 1,
    "المحضن": 1,
    "حضا": 1,
    "حطأ": 1,
    "الحطء": 1,
    "الحطيء": 1,
    "الحطيئة": 1,
    "أحطب": 1,
    "احتطب": 1,
    "استحطب": 1,
    "الحطوبة": 1,
    "المحطب": 1,
    "حطحط": 1,
    "حطره": 1,
    "الحاطورة": 1,
    "احتطه": 1,
    "انحط": 1,
    "استحط": 1,
    "الحطائط": 1,
    "الحطاط": 1,
    "الحطاطة": 1,
    "الحطة": 1,
    "الحطوط": 1,
    "الحطيط": 1,
    "الحطيطة": 1,
    "المحط": 1,
    "المحطوط": 1,
    "المحطوطة": 1,
    "أحطمت": 1,
    "حطمه": 1,
    "انحطم": 1,
    "الحاطوم": 1,
    "الحطم": 1,
    "الحطمة الحطمة": 1,
    "الحطمية": 1,
    "الحطيم": 1,
    "حطا": 1,
    "احطوطى": 1,
    "الحطا": 1,
    "الحطواء": 1,
    "حظب": 1,
    "الحظب": 1,
    "أحظر": 1,
    "احتظر": 1,
    "الحظار": 1,
    "المحظار": 1,
    "أحظ": 1,
    "حظل": 1,
    "أحظل": 1,
    "أحظاه": 1,
    "احتظى": 1,
    "الحظة": 1,
    "الحظوة الحظوة": 1,
    "الحظيا": 1,
    "الحظية المحظية": 1,
    "حف حف": 1,
    "حفأه": 1,
    "احتفأ": 1,
    "الحفأ": 1,
    "حفحف": 1,
    "حفد": 1,
    "أحفد": 1,
    "احتفد": 1,
    "الحافد": 1,
    "الحفد": 1,
    "المحفد": 1,
    "حفرت": 1,
    "أحفر": 1,
    "احتفر": 1,
    "تحفر": 1,
    "استحفر": 1,
    "الحفراة": 1,
    "الحفير": 1,
    "الحفيرة": 1,
    "المحافر": 1,
    "المحفار": 1,
    "حفزه": 1,
    "حافزه": 1,
    "احتفز": 1,
    "الحفاز": 1,
    "حفش": 1,
    "الحافشة": 1,
    "الحفش": 1,
    "حفص": 1,
    "الحفاصة": 1,
    "الحفص": 1,
    "حفصة": 1,
    "المحفصة": 1,
    "حفض": 1,
    "الحفض": 1,
    "الحفيضة": 1,
    "أحفظه": 1,
    "استحفظه": 1,
    "الحفاظ": 1,
    "الحفظة": 1,
    "الحفيظ": 1,
    "الحفيظة": 1,
    "حفت": 1,
    "أحف": 1,
    "احتف": 1,
    "استحف": 1,
    "الحفاف": 1,
    "الحفافة": 1,
    "الحفف": 1,
    "الحفان": 1,
    "المحفة": 1,
    "المحفوف": 1,
    "تحفل": 1,
    "الحفال": 1,
    "الحفيل": 1,
    "المحتفل": 1,
    "المحفل": 1,
    "احتفن": 1,
    "الحفنة الحفنة": 1,
    "المحفن": 1,
    "حفاه وحفا به": 1,
    "أحفى": 1,
    "حافاه": 1,
    "احتفى": 1,
    "تحفى": 1,
    "استحفى": 1,
    "الحفا": 1,
    "الحافي": 1,
    "الحفي": 1,
    "أحقب": 1,
    "احتقب": 1,
    "استحقب": 1,
    "الأحقب": 1,
    "الحاقب": 1,
    "الحقاب": 1,
    "الحقب الحقب": 1,
    "أحقد": 1,
    "تحاقدوا": 1,
    "احتقد": 1,
    "تحقد": 1,
    "الحقيدة": 1,
    "أحقره": 1,
    "حقره": 1,
    "تحاقر": 1,
    "احتقره": 1,
    "استحقره": 1,
    "المحقرة": 1,
    "المحقرات": 1,
    "حقط": 1,
    "الحقطة": 1,
    "احقوقف": 1,
    "الأحقف": 1,
    "الحقف": 1,
    "المحقف": 1,
    "حاقه": 1,
    "احتق": 1,
    "انحقت": 1,
    "تحاقا": 1,
    "الحقاني": 1,
    "الحقيق": 1,
    "المحقوق": 1,
    "حقلت": 1,
    "أحقل": 1,
    "حاقله": 1,
    "احتقل": 1,
    "الحاقل": 1,
    "الحقال": 1,
    "الحقلي": 1,
    "الحقلة": 1,
    "المحقلة": 1,
    "الحقلد": 1,
    "الحقيم": 1,
    "الحاقنة": 1,
    "المحقان": 1,
    "المحقن": 1,
    "حقاه": 1,
    "تحقى": 1,
    "الحقاء": 1,
    "الحقو": 1,
    "حكأ": 1,
    "أحكأها": 1,
    "احتكأ": 1,
    "الحكأة": 1,
    "الحكاءة": 1,
    "حكره": 1,
    "حاكره": 1,
    "تحكر": 1,
    "الحاكورة": 1,
    "الحكرة": 1,
    "حكش": 1,
    "الحكشة": 1,
    "أحك": 1,
    "حاكه": 1,
    "حككه": 1,
    "تحاكا": 1,
    "تحكك": 1,
    "استحك": 1,
    "الحاكة": 1,
    "الحكاك": 1,
    "الحكاكة": 1,
    "الحكك": 1,
    "الحككة": 1,
    "الحكيكة": 1,
    "حكل": 1,
    "أحكل": 1,
    "احتكل": 1,
    "تحكل": 1,
    "الأحكل": 1,
    "الحاكل": 1,
    "الحكلة": 1,
    "الحكيلة": 1,
    "حاكمه": 1,
    "احتكم": 1,
    "تحاكما": 1,
    "استحكم": 1,
    "حاكاه": 1,
    "الحكاء": 1,
    "الحكي": 1,
    "حلأ": 1,
    "حلئ": 1,
    "أحلأه": 1,
    "حلأه": 1,
    "التحلئ": 1,
    "التحلئة": 1,
    "الحلاءة": 1,
    "الحلوء": 1,
    "المحلأة": 1,
    "أحلب": 1,
    "حالبه": 1,
    "احتلب": 1,
    "استحلب": 1,
    "الإحلابة": 1,
    "الحلاب": 1,
    "الحلباة": 1,
    "الحلوبة": 1,
    "المحلب": 1,
    "الحلابس": 1,
    "الحلبس": 1,
    "الحلاتة": 1,
    "الحلتيت": 1,
    "الحليت": 1,
    "الحلاجة": 1,
    "الحلاج": 1,
    "الحلوج": 1,
    "المحلاج": 1,
    "المحلج": 1,
    "تحلحل": 1,
    "الحلاحل": 1,
    "المحلحل": 1,
    "حلز": 1,
    "احتلز": 1,
    "تحلز": 1,
    "الحالز": 1,
    "الحلز": 1,
    "الحلزوني": 1,
    "حلست": 1,
    "أحلست": 1,
    "حالسه": 1,
    "تحلس": 1,
    "استحلست": 1,
    "الحلس": 1,
    "الحلوس": 1,
    "الحليس": 1,
    "حلط": 1,
    "أحلط": 1,
    "احتلط": 1,
    "أحلفت": 1,
    "حلفه": 1,
    "تحالفوا": 1,
    "استحلفه": 1,
    "الحلفة": 1,
    "الحلاف": 1,
    "الحلفق": 1,
    "أحلق": 1,
    "احتلق": 1,
    "تحلق": 1,
    "تحلاق": 1,
    "الحالق": 1,
    "الحالقة": 1,
    "الحالوق": 1,
    "الحالوقة": 1,
    "حلقم": 1,
    "الحلقامة": 1,
    "حلقن": 1,
    "الحلقانة": 1,
    "استحلك": 1,
    "الحلك": 1,
    "الحلكة": 1,
    "انحلت": 1,
    "استحل": 1,
    "التحلة": 1,
    "الحلان": 1,
    "الحلة": 1,
    "الحلولية": 1,
    "الحليل": 1,
    "الحليلة": 1,
    "المحلال": 1,
    "حلمه": 1,
    "احتلم": 1,
    "تحالم": 1,
    "تحلم": 1,
    "التحلمة": 1,
    "الحالوم": 1,
    "الحلام": 1,
    "حالاه": 1,
    "تحالى": 1,
    "تحلى": 1,
    "استحلى": 1,
    "احلولى": 1,
    "الحلى": 1,
    "حلاوة القفا": 1,
    "الحلواء": 1,
    "الحلوان": 1,
    "المحلى": 1,
    "حليت": 1,
    "الحلاة": 1,
    "أحمأ": 1,
    "الحمأ": 1,
    "الحمأة": 1,
    "الحمئ": 1,
    "حمته": 1,
    "حمت": 1,
    "تحمت": 1,
    "الحميت": 1,
    "تحمحم": 1,
    "الحماحم": 1,
    "الحمحم": 1,
    "حمده": 1,
    "تحمد": 1,
    "تحامدوا": 1,
    "استحمد": 1,
    "حماد": 1,
    "حمادى": 1,
    "الحمدة": 1,
    "المحمدة": 1,
    "احمار": 1,
    "الأحيمر": 1,
    "الحامر": 1,
    "الحامرة": 1,
    "حمار قبان": 1,
    "الحمارة": 1,
    "الحمارة الحمارة": 1,
    "وحمراء الشدقين": 1,
    "الحميراء": 1,
    "المحمرة": 1,
    "اليحمور": 1,
    "الحمارس": 1,
    "الحمزة": 1,
    "الحموز": 1,
    "الحميز": 1,
    "المحموز": 1,
    "أحمسه": 1,
    "احتمس": 1,
    "تحامس": 1,
    "الحماس والحماسة": 1,
    "الحمسة": 1,
    "الحميس": 1,
    "الحميسة": 1,
    "حمشت": 1,
    "أحمش": 1,
    "تحمش": 1,
    "استحمش": 1,
    "انحمص": 1,
    "تحمص": 1,
    "الحمص والحمص": 1,
    "الحمصاني": 1,
    "حمضت": 1,
    "أحمض": 1,
    "تحمض": 1,
    "استحمض": 1,
    "الحمضي": 1,
    "المحمض والمحمض": 1,
    "حمط": 1,
    "الحماط": 1,
    "الحماطة": 1,
    "حامقه": 1,
    "حمقه": 1,
    "انحمق": 1,
    "تحامق": 1,
    "تحمق": 1,
    "استحمق": 1,
    "الأحموقة": 1,
    "الحماق والحماق": 1,
    "الحموقة": 1,
    "المحماق": 1,
    "المحقات": 1,
    "الحمك": 1,
    "أحملت": 1,
    "استحمل": 1,
    "الحاملة": 1,
    "الحملة والحملة": 1,
    "الحمول": 1,
    "الحميل": 1,
    "الحميلة": 1,
    "المحمول": 1,
    "حملج": 1,
    "الحملاج": 1,
    "حملاق": 1,
    "أحمت": 1,
    "حممت": 1,
    "احتم": 1,
    "تحمم": 1,
    "الحامة": 1,
    "الحمامي": 1,
    "الحمة": 1,
    "الحميمة": 1,
    "المحم": 1,
    "المحمة": 1,
    "المستحم": 1,
    "اليحموم": 1,
    "الحمنان": 1,
    "المحمنة": 1,
    "حميت": 1,
    "أحمى": 1,
    "احتمى": 1,
    "تحاماه": 1,
    "حما": 1,
    "حموة": 1,
    "الحمي": 1,
    "تحمير": 1,
    "تحنأ": 1,
    "حنب": 1,
    "تحنب": 1,
    "تحنبل": 1,
    "الحنابل": 1,
    "الحنبل": 1,
    "الحنبلي": 1,
    "الحانوت": 1,
    "الحنتف": 1,
    "الحنتوف": 1,
    "الحنتم": 1,
    "أحنثه": 1,
    "حنثه": 1,
    "المحانث": 1,
    "حنجت": 1,
    "أحنج": 1,
    "احتنج": 1,
    "الحنج": 1,
    "حنجرت": 1,
    "الحنجور": 1,
    "الحناجل": 1,
    "الحنجل": 1,
    "الحندرة": 1,
    "الحندورة": 1,
    "تحندس": 1,
    "الحندس": 1,
    "الحندقوق": 1,
    "حنذ": 1,
    "أحنذ": 1,
    "استحنذ": 1,
    "حناذ": 1,
    "الحنذة": 1,
    "الحنذيان": 1,
    "الحنيذ": 1,
    "حنر": 1,
    "الحنيرة": 1,
    "حنس": 1,
    "الحنس": 1,
    "الحونس": 1,
    "حنشه": 1,
    "أحنشه": 1,
    "الحنش": 1,
    "المحنش": 1,
    "أحنط": 1,
    "استحنط": 1,
    "الحانط": 1,
    "الحناط": 1,
    "الحناطة": 1,
    "الحنطة": 1,
    "الحنطي": 1,
    "الحنوط": 1,
    "الحنوطي": 1,
    "حنظلت": 1,
    "الحنظل": 1,
    "حنفه": 1,
    "تحنف": 1,
    "الحنفاء": 1,
    "الحنفي": 1,
    "أحنق": 1,
    "الحنيق": 1,
    "حنكت": 1,
    "أحنكته": 1,
    "احتنك": 1,
    "استحنك": 1,
    "الحانك": 1,
    "الحناك": 1,
    "الحنك": 1,
    "الحنكة": 1,
    "الحنيك": 1,
    "حنكل": 1,
    "الحنكل": 1,
    "الحنكلة": 1,
    "حنن": 1,
    "تحان": 1,
    "استحن": 1,
    "التحنان": 1,
    "الحنانة": 1,
    "الحنة": 1,
    "أحنى": 1,
    "تحنى": 1,
    "الأحنى": 1,
    "الحاناة": 1,
    "الحانية": 1,
    "الحاني": 1,
    "الحنو": 1,
    "الحنواء": 1,
    "الحنية": 1,
    "المحنية": 1,
    "حاب": 1,
    "أحوب": 1,
    "تحوب": 1,
    "الحوب": 1,
    "الحوباء": 1,
    "الحوبة": 1,
    "حات": 1,
    "حاوته": 1,
    "الحائت": 1,
    "الحوتاء": 1,
    "حوتك": 1,
    "أحاثه": 1,
    "استحاثه": 1,
    "حاث باث": 1,
    "حوث": 1,
    "الحوث": 1,
    "حوث بوث": 1,
    "الحوثاء": 1,
    "أحوج": 1,
    "احتاج": 1,
    "تحوج": 1,
    "الحائج": 1,
    "الحائجة": 1,
    "الحوج": 1,
    "الحوجاء": 1,
    "المحوجب": 1,
    "الحوجلة": 1,
    "حاودته": 1,
    "أحوذ": 1,
    "الأحوذي": 1,
    "الحاذ": 1,
    "الحواذ": 1,
    "الحوذان": 1,
    "الحويذ": 1,
    "حورت": 1,
    "أحارت": 1,
    "حاوره": 1,
    "تحاوروا": 1,
    "احور": 1,
    "استحاره": 1,
    "الأحوري": 1,
    "الحائر": 1,
    "الحائرة": 1,
    "الحوراء": 1,
    "الحوارى": 1,
    "الحوير": 1,
    "المحورة": 1,
    "احتازه": 1,
    "انحاز": 1,
    "تحاوزوا": 1,
    "تحوز": 1,
    "استحازه": 1,
    "الأحوز": 1,
    "الأحوزي": 1,
    "الحوز": 1,
    "الحوزاء": 1,
    "الحوزة": 1,
    "الحوزي": 1,
    "الحويزاء": 1,
    "الحيز": 1,
    "حاست": 1,
    "حاوست": 1,
    "تحوس": 1,
    "استحوس": 1,
    "الأحوسي": 1,
    "الحائس": 1,
    "الحواسة": 1,
    "أحاش": 1,
    "أحوش": 1,
    "حاوشه": 1,
    "احتوش": 1,
    "انحاش": 1,
    "تحاوشوا": 1,
    "تحوش": 1,
    "الحائش": 1,
    "الحواشة": 1,
    "الحوش": 1,
    "الحوشي": 1,
    "المحاش": 1,
    "الحوشب": 1,
    "الحوشبة": 1,
    "الحوشكة": 1,
    "حاوصه": 1,
    "احتاص": 1,
    "الحواص": 1,
    "الحوص": 1,
    "الحياصة": 1,
    "الحوصل": 1,
    "احتاض": 1,
    "استحوض": 1,
    "المحوض": 1,
    "حاوطه": 1,
    "احتاط": 1,
    "تحوطه": 1,
    "استحاط": 1,
    "التحويطة": 1,
    "الحواطة": 1,
    "الحوط": 1,
    "الحوطة": 1,
    "الحواط": 1,
    "الحيطة الحيطة": 1,
    "الحيط": 1,
    "المحاط": 1,
    "حوفه": 1,
    "تحوف": 1,
    "الحوف": 1,
    "حوفز": 1,
    "احتاق": 1,
    "الحواقة": 1,
    "الحوق": 1,
    "الحوقة": 1,
    "المحوقة": 1,
    "الحوقل": 1,
    "الحوقلة": 1,
    "أحاك": 1,
    "الحوك": 1,
    "الحوكة": 1,
    "حولت": 1,
    "أحول": 1,
    "احتوله": 1,
    "احتال": 1,
    "الاحتيال": 1,
    "التحاويل": 1,
    "الحائل": 1,
    "الحوال": 1,
    "الحوالي الحوالي": 1,
    "الحولان": 1,
    "الحولة": 1,
    "الحيال": 1,
    "المحالة": 1,
    "المحوال": 1,
    "المحيل": 1,
    "حولق": 1,
    "الحومة": 1,
    "الحومانة": 1,
    "الحومل": 1,
    "تحون": 1,
    "حوي": 1,
    "احتوى": 1,
    "تحوى": 1,
    "الحاوي": 1,
    "الحاوياء": 1,
    "الحواء": 1,
    "الحو": 1,
    "الحواءة": 1,
    "الحوة": 1,
    "الحوي": 1,
    "الحوية": 1,
    "المحوى": 1,
    "المحواة": 1,
    "أحاجت": 1,
    "حيجم": 1,
    "أحاده": 1,
    "حايده": 1,
    "الحيدى": 1,
    "الحيدان": 1,
    "الحيدة": 1,
    "حيره": 1,
    "استحار": 1,
    "استحير": 1,
    "الحاري": 1,
    "الحير": 1,
    "المستحير": 1,
    "المستحيرة": 1,
    "حاس": 1,
    "حيس": 1,
    "الحيس": 1,
    "تحيشت": 1,
    "الحيش": 1,
    "الحيشان": 1,
    "حايصه": 1,
    "انحاص": 1,
    "تحايص": 1,
    "حيص وحيص": 1,
    "الحيوص": 1,
    "المحيص": 1,
    "تحيضت": 1,
    "استحيضت": 1,
    "الحياض": 1,
    "الحيضة": 1,
    "حيعل": 1,
    "تحيف": 1,
    "الحائف": 1,
    "الحيف": 1,
    "الحيفة": 1,
    "أحاق": 1,
    "حايقه": 1,
    "الحيق": 1,
    "الحيقر": 1,
    "احتاك": 1,
    "تحايك": 1,
    "تحيك": 1,
    "الحيلان": 1,
    "أحان": 1,
    "أحين": 1,
    "حاينه": 1,
    "الحائنة": 1,
    "الحينة الحينة": 1,
    "المحيان": 1,
    "حياه": 1,
    "حايا": 1,
    "تحيا": 1,
    "الحيا": 1,
    "الحابي": 1,
    "الخاء": 1,
    "خبأه": 1,
    "أخبأه": 1,
    "خابأه": 1,
    "تخبأ": 1,
    "الخابية": 1,
    "الخباء": 1,
    "الخبأة": 1,
    "الخبئ": 1,
    "الخبيئة": 1,
    "المخبأة": 1,
    "أخب": 1,
    "خببه": 1,
    "اختب": 1,
    "الخاب": 1,
    "الخبة": 1,
    "الخبيب": 1,
    "الخبيبة": 1,
    "المخبة": 1,
    "أخبت": 1,
    "الخبت": 1,
    "الخبتة": 1,
    "أخبث": 1,
    "تخابث": 1,
    "استخبثه": 1,
    "الأخبثان": 1,
    "خباث": 1,
    "الخبثة": 1,
    "المخبثة": 1,
    "مخبثان": 1,
    "خبخب": 1,
    "تخبخب": 1,
    "خبرت": 1,
    "أخبره": 1,
    "خابره": 1,
    "تخبر": 1,
    "استخبره": 1,
    "الأخباري": 1,
    "الخابور": 1,
    "الخبار": 1,
    "الخبور": 1,
    "الخبيرة": 1,
    "المخبار": 1,
    "المخبراني": 1,
    "المخبرة": 1,
    "اختبز": 1,
    "انخبز": 1,
    "تخبزه": 1,
    "الخابز": 1,
    "الخبازى": 1,
    "الخبازة": 1,
    "الخبازيات": 1,
    "الخبيز": 1,
    "خبس": 1,
    "اختبس": 1,
    "تخبس": 1,
    "الخباساء": 1,
    "الخباسة": 1,
    "خبشه": 1,
    "تخبشه": 1,
    "الخباشة": 1,
    "خبصه": 1,
    "اختبص": 1,
    "تخبص": 1,
    "الخبيص": 1,
    "الخبيصة": 1,
    "المخبصة": 1,
    "اختبطت": 1,
    "تخبطت": 1,
    "الخابط": 1,
    "الخباط": 1,
    "الخبط": 1,
    "الخبطة": 1,
    "الخبوط": 1,
    "الخبيط": 1,
    "المخبط": 1,
    "المخبطة": 1,
    "خبع": 1,
    "الخباع": 1,
    "الخبعة": 1,
    "خبق": 1,
    "تخبق": 1,
    "الخبقة": 1,
    "الخبق": 1,
    "الخبقى": 1,
    "الخبقاء": 1,
    "أخبله": 1,
    "خبله": 1,
    "اختبل": 1,
    "تخبل": 1,
    "استخبله": 1,
    "الخابل": 1,
    "الخبال": 1,
    "الخبلة": 1,
    "الخبول": 1,
    "خبن": 1,
    "أخبن": 1,
    "الخبن": 1,
    "الخبنة": 1,
    "أخبى": 1,
    "خبى": 1,
    "تخبى": 1,
    "استخبى": 1,
    "ختأه": 1,
    "اختتأ": 1,
    "المختتئة": 1,
    "خت": 1,
    "خترت": 1,
    "ختره": 1,
    "تختر": 1,
    "ختع": 1,
    "انختع": 1,
    "الختع": 1,
    "الختعة": 1,
    "الختيع": 1,
    "الختيعة": 1,
    "الخوتع": 1,
    "الخوتعة": 1,
    "ختعر": 1,
    "الخيتعور": 1,
    "ختله": 1,
    "خاتله": 1,
    "اختتل": 1,
    "تخاتلوا": 1,
    "الختل": 1,
    "الخوتل": 1,
    "ختمه": 1,
    "اختتم": 1,
    "الخاتام": 1,
    "الخاتيام": 1,
    "الخيتام": 1,
    "المختم": 1,
    "المختوم": 1,
    "اختتن": 1,
    "الختانة": 1,
    "الختن": 1,
    "ختا": 1,
    "أختى": 1,
    "اختتى": 1,
    "الختي": 1,
    "خثثه": 1,
    "الخثة": 1,
    "أخثر": 1,
    "التخثر التاجي": 1,
    "الخثار": 1,
    "الخثارة": 1,
    "الخثارم": 1,
    "الخثرمة": 1,
    "خثعمه": 1,
    "تخثعم": 1,
    "خثم": 1,
    "خثمه": 1,
    "خثى": 1,
    "أخثى": 1,
    "الخثو": 1,
    "الخثواء": 1,
    "الخثوة": 1,
    "الخثى": 1,
    "الخثي": 1,
    "خجأ": 1,
    "خجىء": 1,
    "أخجأه": 1,
    "تخاجأت": 1,
    "الخجأة": 1,
    "خج": 1,
    "اختج": 1,
    "الخجيج": 1,
    "خجخج": 1,
    "الخجخاجة": 1,
    "خجله": 1,
    "اختجله": 1,
    "الخوجلى": 1,
    "خدب": 1,
    "تخدب": 1,
    "الخادبة": 1,
    "الخدب": 1,
    "الخيدب": 1,
    "الخيدبة": 1,
    "خدج": 1,
    "أخدجت": 1,
    "خدجت": 1,
    "الخديج": 1,
    "المخداج": 1,
    "خاده": 1,
    "تخادا": 1,
    "تخدد": 1,
    "الخدة": 1,
    "المخد": 1,
    "أخدر": 1,
    "خدره": 1,
    "اختدر": 1,
    "تخدر": 1,
    "الأخدور": 1,
    "التخدير الكوكاييني": 1,
    "الخداري": 1,
    "الخدرة": 1,
    "خدشه": 1,
    "المخادش": 1,
    "المخدش": 1,
    "أخدعه": 1,
    "خادعه": 1,
    "خدعه": 1,
    "انخدع": 1,
    "تخادعا": 1,
    "تخدع": 1,
    "اختدعه": 1,
    "الأخدع": 1,
    "الخادع": 1,
    "الخادعة": 1,
    "الخدوع": 1,
    "الخيدع": 1,
    "المخدع": 1,
    "خدف": 1,
    "اختدفه": 1,
    "الخدف": 1,
    "الخدفة": 1,
    "الخدل": 1,
    "الخدلج": 1,
    "أخدمه": 1,
    "تخدم": 1,
    "اختدم": 1,
    "استخدمه": 1,
    "الخدام": 1,
    "الخدوم": 1,
    "المخدم": 1,
    "المخدمة": 1,
    "خادنه": 1,
    "الخدن": 1,
    "الخدنة": 1,
    "الخديو": 1,
    "الخديوية": 1,
    "خذىء": 1,
    "أخذأه": 1,
    "استخذأ": 1,
    "خذرف": 1,
    "تخذرف": 1,
    "الخذرفة": 1,
    "الخذروف": 1,
    "خذع": 1,
    "خذعه": 1,
    "تخذع": 1,
    "الخذعة": 1,
    "المخدعة": 1,
    "المخذفة": 1,
    "خدق": 1,
    "الخدق": 1,
    "أخذلت": 1,
    "خذله": 1,
    "تخاذلت": 1,
    "الخذلة": 1,
    "الخذول": 1,
    "خذم": 1,
    "أخذم": 1,
    "خذمه": 1,
    "تخذم": 1,
    "الخذامة": 1,
    "الخذماء": 1,
    "المخذم": 1,
    "خذا": 1,
    "خذي": 1,
    "أخذاه": 1,
    "استخذى": 1,
    "الخذا": 1,
    "الخذاوية": 1,
    "الخرء": 1,
    "المخرأة": 1,
    "المخرؤة": 1,
    "أخربه": 1,
    "خربه": 1,
    "استخرب": 1,
    "الأخرب": 1,
    "الخرابة": 1,
    "الخرب": 1,
    "الخرباء": 1,
    "الخربان": 1,
    "الخربة": 1,
    "الخروبة": 1,
    "الخربز": 1,
    "الخرباش": 1,
    "خربص": 1,
    "اخرنبق": 1,
    "الخرباق": 1,
    "الخربق": 1,
    "الخرت": 1,
    "الخرتة": 1,
    "الخريت": 1,
    "المخرت": 1,
    "خرثت": 1,
    "الخرثاء": 1,
    "الخرثي": 1,
    "خرجه": 1,
    "اخترج": 1,
    "استخرجه": 1,
    "التخريج": 1,
    "الخرجة": 1,
    "الخوارج": 1,
    "تخرخر": 1,
    "الخرخار": 1,
    "الخرخر": 1,
    "الخرخور": 1,
    "أخرد": 1,
    "تخردت": 1,
    "الخرود": 1,
    "الخريد": 1,
    "الخريدة": 1,
    "الخرديق": 1,
    "خردلت": 1,
    "الخردولة": 1,
    "خرذل": 1,
    "أخره": 1,
    "انخر": 1,
    "الخرارة": 1,
    "الخر": 1,
    "الخريان": 1,
    "خرزه": 1,
    "الخرازة": 1,
    "الخراز": 1,
    "المخراز": 1,
    "المخرز": 1,
    "أخرست": 1,
    "تخارس": 1,
    "تخرست": 1,
    "استخرست": 1,
    "الخراس": 1,
    "الخرساء": 1,
    "الخرسة": 1,
    "الخروس": 1,
    "الخرسى": 1,
    "تخرسن": 1,
    "خرش": 1,
    "خارشه": 1,
    "اخترش": 1,
    "تخارشت": 1,
    "الخراش": 1,
    "الخراشة": 1,
    "الخرش": 1,
    "الخرشاء": 1,
    "المخراش": 1,
    "المخرش": 1,
    "المخرشة": 1,
    "خرشب": 1,
    "الخرشب": 1,
    "خرشف": 1,
    "الخرشاف": 1,
    "الخرشفة": 1,
    "خرشم": 1,
    "اخرنشم": 1,
    "الخرشم": 1,
    "الخرشمة": 1,
    "الخرشوم": 1,
    "اخترص": 1,
    "تخرص": 1,
    "الخراص": 1,
    "الخرص": 1,
    "الخرصة": 1,
    "الخريص": 1,
    "المخرص": 1,
    "خرطت": 1,
    "أخرطت": 1,
    "اخترط": 1,
    "انخرطت": 1,
    "تخرط": 1,
    "استخرط": 1,
    "الإخريط": 1,
    "الخراط": 1,
    "الخرط": 1,
    "المخراط": 1,
    "المخرط": 1,
    "الخرطوش": 1,
    "خرطمه": 1,
    "اخرنطم": 1,
    "الخراطم": 1,
    "الخرطماني": 1,
    "الخراطين": 1,
    "اخترعه": 1,
    "انخرع": 1,
    "تخرع": 1,
    "الخراع": 1,
    "الخراعة": 1,
    "الخريع": 1,
    "الخرع": 1,
    "الخروعة": 1,
    "الخريعة": 1,
    "الخرعب": 1,
    "الخرعوب": 1,
    "الخرعوبة": 1,
    "أخرف": 1,
    "خارفه": 1,
    "خرفه": 1,
    "اخترف": 1,
    "الخارف": 1,
    "الخرفة": 1,
    "الخروفة": 1,
    "الخريفة": 1,
    "المخرف": 1,
    "المخرفة": 1,
    "خرفجه": 1,
    "الخرافج": 1,
    "الخرفاج": 1,
    "الخرفج": 1,
    "الخرفيج": 1,
    "الخرفع": 1,
    "الخرفق": 1,
    "أخرقه": 1,
    "انخرق": 1,
    "الخريق": 1,
    "المخراق": 1,
    "المخرق": 1,
    "المخروق": 1,
    "خرقل": 1,
    "اخترمته": 1,
    "انخرم": 1,
    "تخرم": 1,
    "الأخرم": 1,
    "الخرمية": 1,
    "الخرماء": 1,
    "الخرمان": 1,
    "الخرمة": 1,
    "الخورمة": 1,
    "المخرم": 1,
    "خرمد": 1,
    "اخرمس": 1,
    "الخرمس": 1,
    "خرمش": 1,
    "اخرمص": 1,
    "خرمل": 1,
    "تخرمل": 1,
    "الخرمل": 1,
    "خرنفه": 1,
    "الخرانف": 1,
    "الخرنف": 1,
    "الخرنفة": 1,
    "خرنقت": 1,
    "الخرنق": 1,
    "الخورنق": 1,
    "خزب": 1,
    "تخزب": 1,
    "الخزب": 1,
    "الخزباء": 1,
    "الخزيبة": 1,
    "الخوزب": 1,
    "الخيزب": 1,
    "الخيزبان": 1,
    "الخيزبة": 1,
    "المخزاب": 1,
    "تخزبز": 1,
    "الخازباز": 1,
    "الخزباز": 1,
    "خزج": 1,
    "المخزاج": 1,
    "الخزاخز": 1,
    "الخزخز": 1,
    "خزرت": 1,
    "تخازر": 1,
    "الخزير": 1,
    "الخزيرة": 1,
    "الخوزرى": 1,
    "الخيزرانة": 1,
    "خزرب": 1,
    "خزرجت": 1,
    "الخزرج": 1,
    "الخزرافة": 1,
    "خزه": 1,
    "اختزه": 1,
    "الخز": 1,
    "الخزز": 1,
    "الخزاز": 1,
    "الخزيز": 1,
    "المخزة": 1,
    "اختزعه": 1,
    "انخزع": 1,
    "تخزع": 1,
    "الخزاع": 1,
    "الخزاعة": 1,
    "الخزعة": 1,
    "الخزوع": 1,
    "المخزاع": 1,
    "المخزع": 1,
    "الخزعبل": 1,
    "الخزعبيلة": 1,
    "خزعلت": 1,
    "الخزعال": 1,
    "الخزعالة": 1,
    "الخزعل": 1,
    "الخزاف": 1,
    "انخزق": 1,
    "الخازق": 1,
    "الخازوق": 1,
    "الخزق": 1,
    "الخزوق": 1,
    "المخزق": 1,
    "المخزقة": 1,
    "انخزل": 1,
    "تخزل": 1,
    "الأخزل": 1,
    "الخزل": 1,
    "الخزلة": 1,
    "الخوزلى": 1,
    "الخيزل": 1,
    "الخيزلى": 1,
    "خزلب": 1,
    "خزمه": 1,
    "خازمه": 1,
    "تخازم": 1,
    "تخزم": 1,
    "الأخزم": 1,
    "الخزام": 1,
    "الخزامة": 1,
    "الخزم": 1,
    "الخزومة": 1,
    "المخزم": 1,
    "أخزن": 1,
    "اختزن": 1,
    "استخزنه": 1,
    "خزًا": 1,
    "أخزاه": 1,
    "خازى": 1,
    "الخزية": 1,
    "المخزاة": 1,
    "المخزية": 1,
    "خاسأ": 1,
    "انخسأ": 1,
    "تخاسأ": 1,
    "الخاسئ": 1,
    "الخسئ": 1,
    "أخسر": 1,
    "الخناسير": 1,
    "الخنسرى": 1,
    "الخيسرى": 1,
    "أخس": 1,
    "خسس": 1,
    "تخاسوه": 1,
    "استخسه": 1,
    "الخساس": 1,
    "الخساسة": 1,
    "الخسان": 1,
    "الخسيس": 1,
    "الخسيسة": 1,
    "المستخس": 1,
    "خسفت": 1,
    "أخسفت": 1,
    "انخسفت": 1,
    "الأخاسيف": 1,
    "الخاسف": 1,
    "الخسف": 1,
    "الخسيفة": 1,
    "خسق": 1,
    "الخساق": 1,
    "الخسقة": 1,
    "الخيسق": 1,
    "خسله": 1,
    "الخسالة": 1,
    "الخسيل": 1,
    "الخسيلة": 1,
    "أخساه": 1,
    "خاساه": 1,
    "تخاسى": 1,
    "الخسا": 1,
    "الخسي": 1,
    "خشبه": 1,
    "اختشب": 1,
    "تخشبت": 1,
    "اخشوشب": 1,
    "الخشاب": 1,
    "الخشباء": 1,
    "الخشيب": 1,
    "الخشيبة": 1,
    "المخشب": 1,
    "تخشخش": 1,
    "خشر": 1,
    "الخشار": 1,
    "الخشارة": 1,
    "مخاشر": 1,
    "خشرمت": 1,
    "الخشرم": 1,
    "أخش": 1,
    "اختش": 1,
    "انخش": 1,
    "الخشاش": 1,
    "الخشاشة": 1,
    "الخش": 1,
    "الخشاء": 1,
    "الخشيش": 1,
    "المخش": 1,
    "اختشع": 1,
    "الخاشع": 1,
    "الخشعة": 1,
    "خشف": 1,
    "أخشفت": 1,
    "الخشاف": 1,
    "الخشف": 1,
    "الخشفة": 1,
    "الخشوف": 1,
    "الخشيف": 1,
    "المخشف": 1,
    "الخشكار": 1,
    "الخشكنان": 1,
    "خشله": 1,
    "خشل": 1,
    "تخشل": 1,
    "الخشل": 1,
    "الخشيل": 1,
    "المخشلة": 1,
    "خشمه": 1,
    "أخشم": 1,
    "تخشم": 1,
    "الخشام": 1,
    "الخشم": 1,
    "خاشنه": 1,
    "خشنه": 1,
    "تخاشنوا": 1,
    "تخشن": 1,
    "استخشنه": 1,
    "الخشا": 1,
    "الخشو": 1,
    "الخشي": 1,
    "خشاه": 1,
    "خاشاه": 1,
    "الأخشى": 1,
    "أخصب": 1,
    "اختصب": 1,
    "الخصاب": 1,
    "الخصيب": 1,
    "خصره": 1,
    "أخصره": 1,
    "خاصره": 1,
    "تخاصر": 1,
    "تخصر": 1,
    "الأخصر": 1,
    "الخصار": 1,
    "الخصر": 1,
    "الخصيرى": 1,
    "المخصرة": 1,
    "المخصور": 1,
    "أخصه": 1,
    "استخصه": 1,
    "الخصاصة": 1,
    "الخصيص": 1,
    "الخصوصة": 1,
    "الخصيصة": 1,
    "خصفت": 1,
    "أخصف": 1,
    "اختصفت": 1,
    "تخصف": 1,
    "الخصاف": 1,
    "الخصفة": 1,
    "الخصيف": 1,
    "الخصيفة": 1,
    "المخصف": 1,
    "أخصل": 1,
    "خاصله": 1,
    "تخاصل": 1,
    "الخصيل": 1,
    "الخصيلة": 1,
    "المخصال": 1,
    "المخصل": 1,
    "أخصم": 1,
    "خاصمه": 1,
    "اختصم": 1,
    "تخاصموا": 1,
    "الأخصوم": 1,
    "الخصين": 1,
    "خصاه": 1,
    "خصي": 1,
    "اختصاه": 1,
    "المخصى": 1,
    "أخضبت": 1,
    "خضبه": 1,
    "اختضب": 1,
    "تخضب": 1,
    "الخاضب": 1,
    "الخضاب": 1,
    "الخضب": 1,
    "الخضبة": 1,
    "الخضوب": 1,
    "المخضب": 1,
    "المخضبة": 1,
    "تخضخض": 1,
    "الخضاخض": 1,
    "الخضخاض": 1,
    "الخضخض": 1,
    "أخضد": 1,
    "خضده": 1,
    "اختضد": 1,
    "انخضد": 1,
    "تخضد": 1,
    "الخضاد": 1,
    "الخضد": 1,
    "المخضد": 1,
    "المخضود": 1,
    "أخضره": 1,
    "خاضره": 1,
    "خضره": 1,
    "اختضره": 1,
    "اخضار": 1,
    "الأخاضر": 1,
    "الأخيضر": 1,
    "الخضارة": 1,
    "الخضاري": 1,
    "الخضارى": 1,
    "الخضور": 1,
    "الخضير": 1,
    "الخضيرة": 1,
    "الخضيري": 1,
    "المخضار": 1,
    "المخضر": 1,
    "المخضرة": 1,
    "خضرب": 1,
    "الخضارب": 1,
    "المخضرب": 1,
    "الخضريج": 1,
    "خضرع": 1,
    "تخضرع": 1,
    "الخضارع": 1,
    "خضرف": 1,
    "تخضرم": 1,
    "الخضارم": 1,
    "الخضارمة": 1,
    "الخضرم": 1,
    "المخضرم": 1,
    "خاضه": 1,
    "خضض": 1,
    "الخضاض": 1,
    "الخضاضة": 1,
    "الخضض": 1,
    "الخضيض": 1,
    "خاضعه": 1,
    "اختضع": 1,
    "تخضع": 1,
    "الخضعة": 1,
    "الخضيعة": 1,
    "الخيضعة": 1,
    "خضف": 1,
    "الخضف": 1,
    "أخضل": 1,
    "خضله": 1,
    "اخضل": 1,
    "اخضأل": 1,
    "اخضال": 1,
    "اخضوضل": 1,
    "الخضل": 1,
    "الخضلة": 1,
    "الخضيلة": 1,
    "خضمه": 1,
    "أخضم": 1,
    "اختضمه": 1,
    "الخضام": 1,
    "الخضامة": 1,
    "الخضمة": 1,
    "الخضم": 1,
    "الخضيمة": 1,
    "خضن": 1,
    "خاضن": 1,
    "المخضن": 1,
    "خطأه": 1,
    "تخاطأ": 1,
    "تخطأه": 1,
    "استخطأت": 1,
    "الخطء": 1,
    "الخطاء": 1,
    "اختطب": 1,
    "تخاطبا": 1,
    "تخاطرا": 1,
    "الخاطرة": 1,
    "الخطار": 1,
    "الخطارة": 1,
    "الخطير": 1,
    "خططه": 1,
    "اختط": 1,
    "الخطيطة": 1,
    "المخطاط": 1,
    "المخط": 1,
    "المخطوط": 1,
    "أخطف": 1,
    "اختطفه": 1,
    "تخطفه": 1,
    "الأخطف": 1,
    "الخاطف": 1,
    "الخاطوف": 1,
    "الخطفى": 1,
    "الخطيفة": 1,
    "المخطف": 1,
    "أخطل": 1,
    "تخطل": 1,
    "الخطال": 1,
    "خطمه": 1,
    "الأخطم": 1,
    "الخاطم": 1,
    "الخطام": 1,
    "المخطم": 1,
    "أخطاه": 1,
    "اختطى": 1,
    "تخطاه": 1,
    "أخظ": 1,
    "خظا": 1,
    "أخظى": 1,
    "خظاه": 1,
    "الخاظي": 1,
    "الخظاة": 1,
    "الخظوان": 1,
    "الخظي": 1,
    "تخافتا": 1,
    "خفج": 1,
    "تخفج": 1,
    "الخفج": 1,
    "الخفيج": 1,
    "خفخف": 1,
    "الخفخاف": 1,
    "خفد": 1,
    "أخفدت": 1,
    "خفره": 1,
    "خفرت": 1,
    "أخفره": 1,
    "تخفر": 1,
    "استخفره": 1,
    "الخفارة": 1,
    "الخفير": 1,
    "أخفس": 1,
    "انخفس": 1,
    "تخفس": 1,
    "خفشه": 1,
    "الخفش": 1,
    "اختفض": 1,
    "انخفض": 1,
    "تخفض": 1,
    "الخافض": 1,
    "الخافضة": 1,
    "خفع": 1,
    "أخفعه": 1,
    "انخفعت": 1,
    "الخفاع": 1,
    "تخفف": 1,
    "استخفه": 1,
    "خفقت": 1,
    "اختفق": 1,
    "الخافق": 1,
    "الخفاق": 1,
    "الخفاقة": 1,
    "المخفق": 1,
    "خفا": 1,
    "استخفى": 1,
    "الخافي": 1,
    "الخافية": 1,
    "خقخق": 1,
    "خق": 1,
    "أخقت": 1,
    "الأخقوق": 1,
    "الخق": 1,
    "خقن": 1,
    "الخاقان": 1,
    "خلأت": 1,
    "خالأ": 1,
    "أخلب": 1,
    "اختلب": 1,
    "استخلب": 1,
    "الخلابة": 1,
    "الخلب": 1,
    "الخلبوب": 1,
    "خلبسه": 1,
    "الخلابس": 1,
    "الخلابيس": 1,
    "الخلبوص": 1,
    "أخلج": 1,
    "تخلج": 1,
    "تخالجه": 1,
    "المخلج": 1,
    "الخلخل": 1,
    "المخلخل": 1,
    "الخلدة": 1,
    "الخوالد": 1,
    "المخلد": 1,
    "أخلس": 1,
    "خالسه": 1,
    "تخالس": 1,
    "تخلس": 1,
    "الخالس": 1,
    "الخلاسي": 1,
    "الخلس": 1,
    "الخليس": 1,
    "الخليسة": 1,
    "خالصه": 1,
    "تخالص": 1,
    "استخلصه": 1,
    "الخالصة": 1,
    "الخلص": 1,
    "الخلصان": 1,
    "الخلصة": 1,
    "الخليص": 1,
    "خالطه": 1,
    "تخالط": 1,
    "الخلطة": 1,
    "المخلاط": 1,
    "المخلط": 1,
    "خالعت": 1,
    "تخالع": 1,
    "اختلع": 1,
    "الخالع": 1,
    "الخلاع": 1,
    "الخلعة": 1,
    "الخولع": 1,
    "المخلع": 1,
    "المخلوع": 1,
    "تخالفا": 1,
    "استخلفه": 1,
    "التخاليف": 1,
    "الخالف": 1,
    "الخالفة": 1,
    "الخلفة": 1,
    "الخليف": 1,
    "المخلاف": 1,
    "المخلفة": 1,
    "أخلق به وما أخلقه": 1,
    "خالقه": 1,
    "اختلق": 1,
    "الأخلق": 1,
    "الخلقاء": 1,
    "الخلوق": 1,
    "الخليقة": 1,
    "المخلقة": 1,
    "خاله": 1,
    "الخلال": 1,
    "الخلالة": 1,
    "الخلة": 1,
    "خالمه": 1,
    "خلمه": 1,
    "اختلمه": 1,
    "الخالم": 1,
    "الخلم": 1,
    "الخلمة": 1,
    "أخلى": 1,
    "تخالى": 1,
    "استخلى": 1,
    "اخلولى": 1,
    "التخلاء الشحماني": 1,
    "الخلا": 1,
    "الخلي": 1,
    "المخالي": 1,
    "المخلاء": 1,
    "المخلاة": 1,
    "أخلت": 1,
    "اختلى": 1,
    "الخلى": 1,
    "المخمج": 1,
    "خمدت": 1,
    "الخامد": 1,
    "أخمر": 1,
    "اختمرت": 1,
    "تخمرت": 1,
    "استخمر": 1,
    "الخمري": 1,
    "الخمارة": 1,
    "المختمرة": 1,
    "المخمرة": 1,
    "المستخمر": 1,
    "أخمسوا": 1,
    "الخماسين": 1,
    "المخمس": 1,
    "المخموس": 1,
    "خمشه": 1,
    "تخمش": 1,
    "الخامشة": 1,
    "الخماشة": 1,
    "تخامص": 1,
    "انخمص": 1,
    "الخمصان": 1,
    "الخميصة": 1,
    "المخمصة": 1,
    "تخمط": 1,
    "الخمط": 1,
    "الخمطة": 1,
    "الخماط": 1,
    "أخملت": 1,
    "اختمل": 1,
    "الخامل": 1,
    "الخمال": 1,
    "الخمالة": 1,
    "الخمل": 1,
    "الخملة": 1,
    "الخميل": 1,
    "الخميلة": 1,
    "أخم": 1,
    "تخمم": 1,
    "الخمامة": 1,
    "الخميم": 1,
    "المخمة": 1,
    "الخمن": 1,
    "الخمان": 1,
    "الخمانة": 1,
    "خنب": 1,
    "أخنب": 1,
    "اختنبوا": 1,
    "تخنب": 1,
    "الخناب": 1,
    "الخنابة": 1,
    "الخنب": 1,
    "الخنبات": 1,
    "الخنبة": 1,
    "المخنبة": 1,
    "خنبس": 1,
    "الخنابس": 1,
    "الخنابسة": 1,
    "الخنبس": 1,
    "الخنبسة": 1,
    "الخنبوس": 1,
    "خنثه": 1,
    "اختنث": 1,
    "انخنث": 1,
    "الخناثة": 1,
    "الخنث": 1,
    "المخناث": 1,
    "الخنجرة": 1,
    "الخنجرير": 1,
    "الخندب": 1,
    "الخندريس": 1,
    "الخندع": 1,
    "تخنذذ": 1,
    "الخنذيذ": 1,
    "خنز": 1,
    "الخنزوان": 1,
    "الخنزوانة": 1,
    "الخنزوانية": 1,
    "الخناز": 1,
    "الخنوز": 1,
    "الخنيز": 1,
    "خنزر": 1,
    "الخنزرة": 1,
    "أخنسه": 1,
    "خنسه": 1,
    "اختنس": 1,
    "انخنس": 1,
    "تخنس": 1,
    "الأخنس": 1,
    "الخنساء": 1,
    "الخنس": 1,
    "الخنوس": 1,
    "الخنسر": 1,
    "الخنسير": 1,
    "الخنوص": 1,
    "أخنعته": 1,
    "خنعه": 1,
    "الخناعة": 1,
    "الخنعة": 1,
    "الخناف": 1,
    "الخنفة": 1,
    "الخنيف": 1,
    "المخناف": 1,
    "الخنفس": 1,
    "خنقه": 1,
    "انخنق": 1,
    "الخانقاه": 1,
    "الخناقة": 1,
    "الخناقية": 1,
    "المخنقة": 1,
    "المخنق": 1,
    "استخنب": 1,
    "الخنان": 1,
    "الخن": 1,
    "الخنة": 1,
    "الخنين": 1,
    "المخنة": 1,
    "أخنى": 1,
    "الخنا": 1,
    "الخنوة": 1,
    "الخوبة": 1,
    "خات": 1,
    "خاوت": 1,
    "خوت": 1,
    "اختات": 1,
    "انخات": 1,
    "تخوت": 1,
    "الخائتة": 1,
    "خوث": 1,
    "الخوثاء": 1,
    "أخاخ": 1,
    "خود": 1,
    "تخود": 1,
    "الخود": 1,
    "خاوذ": 1,
    "تخاوذوه": 1,
    "تخوذه": 1,
    "الخوذان": 1,
    "تخاورت": 1,
    "الخوارة": 1,
    "خازه": 1,
    "خاوش": 1,
    "خوش": 1,
    "تخوش": 1,
    "الخوش": 1,
    "الخوشان": 1,
    "الخوشق": 1,
    "أخوصت": 1,
    "أخاصت": 1,
    "خاوص": 1,
    "خوصت": 1,
    "تخاوص": 1,
    "تخوص": 1,
    "اخواصت": 1,
    "الخوصاء": 1,
    "الخياصة": 1,
    "المخوص": 1,
    "أخاض": 1,
    "خاوضه": 1,
    "اختاض": 1,
    "تخاوضوا": 1,
    "تخوض": 1,
    "الخوضة": 1,
    "الخيض": 1,
    "المخاضة": 1,
    "المخوض": 1,
    "تخوط": 1,
    "الخوط": 1,
    "خوع": 1,
    "تخوع": 1,
    "الخواع": 1,
    "الخواعة": 1,
    "الخوع": 1,
    "خاوفه": 1,
    "خوفه": 1,
    "الخاف": 1,
    "الخواف": 1,
    "خوق": 1,
    "أخاق": 1,
    "انخاق": 1,
    "تخوق": 1,
    "الخاق": 1,
    "الخوق": 1,
    "أخالت": 1,
    "أخول": 1,
    "استخول": 1,
    "الأخول": 1,
    "الخائل": 1,
    "الخؤولة": 1,
    "الخولة": 1,
    "الخولي": 1,
    "أخام": 1,
    "خوم": 1,
    "الخامة": 1,
    "اختانه": 1,
    "تخون": 1,
    "الخانة": 1,
    "الخوان": 1,
    "الخو": 1,
    "الخوة": 1,
    "أخوى": 1,
    "اختوى": 1,
    "الخوى": 1,
    "الخواة": 1,
    "الخواية": 1,
    "الخوي": 1,
    "الخوية": 1,
    "خيبه": 1,
    "الأخيب": 1,
    "الخياب": 1,
    "اختاته": 1,
    "خايره": 1,
    "اختاره": 1,
    "تخايروا": 1,
    "تخيره": 1,
    "استخاره": 1,
    "الاستخارة": 1,
    "الخيري": 1,
    "الخبزبة": 1,
    "تخيس": 1,
    "الأخيس": 1,
    "الخيس": 1,
    "الخيسة": 1,
    "المخيس": 1,
    "خيشه": 1,
    "المخيش": 1,
    "خيص": 1,
    "الخائص": 1,
    "الخيص": 1,
    "الخيصاء": 1,
    "الخيصى": 1,
    "الخيصان": 1,
    "خاطت": 1,
    "اختاط": 1,
    "الخيطة": 1,
    "الخياطية": 1,
    "المخيط": 1,
    "خيف": 1,
    "أخيف": 1,
    "اختاف": 1,
    "تخيفت": 1,
    "الأخياف": 1,
    "الخيف": 1,
    "الخيفانة": 1,
    "الخيفة": 1,
    "أخيلت": 1,
    "خايلت": 1,
    "اختالت": 1,
    "تخايل": 1,
    "تخيلت": 1,
    "استخال": 1,
    "الأخيل": 1,
    "الخالة": 1,
    "الخيلة": 1,
    "المخيل": 1,
    "المخيلة": 1,
    "المخيول": 1,
    "أخامت": 1,
    "أخيم": 1,
    "تخيم": 1,
    "الخيمي": 1,
    "أدأب": 1,
    "الدأب الدأب": 1,
    "الدائبان": 1,
    "دأث": 1,
    "الدئث": 1,
    "الدأثاء": 1,
    "الدئثان": 1,
    "الدؤثي": 1,
    "دأدأ": 1,
    "تدأدأ": 1,
    "الدأداء": 1,
    "الدئداء": 1,
    "الدؤدؤ": 1,
    "الدأدأة": 1,
    "دأظ": 1,
    "دأل": 1,
    "داءله": 1,
    "الدأل": 1,
    "الدئل": 1,
    "الدألان": 1,
    "الدؤلول": 1,
    "دأم": 1,
    "تداءم": 1,
    "تدأمه": 1,
    "الدأم": 1,
    "الدأماء": 1,
    "دءا": 1,
    "دأى": 1,
    "الدأية": 1,
    "دبأ": 1,
    "دبأه": 1,
    "دببه": 1,
    "الدبب": 1,
    "الدباء": 1,
    "الدبة": 1,
    "الدبوب": 1,
    "الدبيب": 1,
    "المدبة": 1,
    "دبجه": 1,
    "الديباج": 1,
    "المدبج": 1,
    "دبح": 1,
    "اندبح": 1,
    "دبخ": 1,
    "دباخ": 1,
    "الدبادب": 1,
    "الدبداب": 1,
    "الدبدبة": 1,
    "دبرت": 1,
    "استدبره": 1,
    "الدابرة": 1,
    "دبار": 1,
    "الدبار": 1,
    "الدبارة": 1,
    "الدبري": 1,
    "الدبير": 1,
    "المدابر": 1,
    "المستدبر": 1,
    "أدبست": 1,
    "دبسه": 1,
    "ادبس": 1,
    "الأدبس": 1,
    "الدباسة": 1,
    "الدبسة": 1,
    "الدبسي": 1,
    "دبشه": 1,
    "الدباش": 1,
    "الدبش": 1,
    "دبغه": 1,
    "اندبغ": 1,
    "الدبغ": 1,
    "الدبغة": 1,
    "الدبوغ": 1,
    "تدبق": 1,
    "الدابوق": 1,
    "الدبوق": 1,
    "الدبوقة": 1,
    "الدبق": 1,
    "الدبيقية": 1,
    "المدبق": 1,
    "الدبلة": 1,
    "الدبول": 1,
    "الدبيلة": 1,
    "الدوبل": 1,
    "الدبن": 1,
    "الدبنة": 1,
    "دبيت": 1,
    "أدبت": 1,
    "الدبى": 1,
    "المدباة": 1,
    "دثت": 1,
    "دث": 1,
    "الدث": 1,
    "الدثاث": 1,
    "الدثة": 1,
    "تداثر": 1,
    "تدثر": 1,
    "أدثر": 1,
    "اندثر": 1,
    "الأدثر": 1,
    "الداثر": 1,
    "الدثار": 1,
    "الدثاري": 1,
    "الدثر": 1,
    "الدثور": 1,
    "دججت": 1,
    "تدجج": 1,
    "الداج": 1,
    "الداجة": 1,
    "الدجاجي": 1,
    "الدجة": 1,
    "الدجوج": 1,
    "الدجوجي": 1,
    "الدجيج": 1,
    "الديجوج": 1,
    "المدجج": 1,
    "دج دج": 1,
    "دجدجت": 1,
    "تدجدج": 1,
    "الدجداج": 1,
    "داجر": 1,
    "اندجر": 1,
    "الدجران": 1,
    "الديجور": 1,
    "الديجوري": 1,
    "الدجالة": 1,
    "الدجيل": 1,
    "دجم": 1,
    "الدجم": 1,
    "الدجمة": 1,
    "أدجن": 1,
    "داجنه": 1,
    "الداجن": 1,
    "الدجن": 1,
    "الدجنة": 1,
    "المدجان": 1,
    "أدجى": 1,
    "داجاه": 1,
    "تدجى": 1,
    "الداجية": 1,
    "الدجى": 1,
    "الدجية": 1,
    "الدياجي": 1,
    "دحبه": 1,
    "دحجه": 1,
    "دح": 1,
    "اندح": 1,
    "الدحوح": 1,
    "دح دح": 1,
    "الدحادح": 1,
    "الدحداح": 1,
    "الدحداحة": 1,
    "الدحدح": 1,
    "الدحيدحة": 1,
    "دحدره": 1,
    "تدحدر": 1,
    "دحره": 1,
    "اندحر": 1,
    "دحرجه": 1,
    "تدحرج": 1,
    "الدحريج": 1,
    "دحست": 1,
    "أدحس": 1,
    "الداحس": 1,
    "الداحوس": 1,
    "الدحاس": 1,
    "الدحاسة": 1,
    "الدحس": 1,
    "الديحس": 1,
    "دحص": 1,
    "دحضت": 1,
    "أدحضه": 1,
    "الداحضة": 1,
    "الدحوض": 1,
    "المدحاض": 1,
    "المدحضة": 1,
    "دحقت": 1,
    "أدحقه": 1,
    "اندحق": 1,
    "الداحق": 1,
    "الدحاق": 1,
    "الدحوق": 1,
    "الدحيق": 1,
    "دحقبه": 1,
    "الدحقوم": 1,
    "دحل": 1,
    "أدحل": 1,
    "داحله": 1,
    "الداحل": 1,
    "الداحول": 1,
    "الدحال": 1,
    "الدحل": 1,
    "الدحلاء": 1,
    "الدحلة": 1,
    "الدحول": 1,
    "الدحيلة": 1,
    "دحلق": 1,
    "دحمه": 1,
    "الداحوم": 1,
    "الدحم": 1,
    "دحمر": 1,
    "دحمس": 1,
    "الدحامس": 1,
    "الدحمس": 1,
    "الدحمسان": 1,
    "الدحمسة": 1,
    "دحمله": 1,
    "الدحامل": 1,
    "الدحمل": 1,
    "الدحملة": 1,
    "دحن": 1,
    "الدحن": 1,
    "الدحنة": 1,
    "الدحونة": 1,
    "الديحان": 1,
    "دحاه": 1,
    "داحاه": 1,
    "اندحى": 1,
    "تداحيا": 1,
    "تدحى": 1,
    "ادحوى": 1,
    "الأدحوة": 1,
    "الأدحي": 1,
    "الأدحية": 1,
    "الدحية": 1,
    "دخه": 1,
    "دخ": 1,
    "الدخ": 1,
    "دخ دخ": 1,
    "دخدخ": 1,
    "تدخدخ": 1,
    "الدخادخ": 1,
    "الدخداخ": 1,
    "الدخدخ": 1,
    "دخدره": 1,
    "الدخدار": 1,
    "دخر": 1,
    "أدخره": 1,
    "ادخره": 1,
    "الادخار": 1,
    "دخرص": 1,
    "الدخرص": 1,
    "الدخرصة": 1,
    "أدخس": 1,
    "الدخاس": 1,
    "الدخس": 1,
    "الدخوس": 1,
    "الدخيس": 1,
    "الديخس": 1,
    "المداخس": 1,
    "أدخله": 1,
    "داخلت": 1,
    "تداخلت": 1,
    "الدخال": 1,
    "الدوخلة": 1,
    "الدخلل": 1,
    "الدخامس": 1,
    "الدخماس": 1,
    "الدخمس": 1,
    "الدخمسة": 1,
    "المدخمس": 1,
    "دخنت": 1,
    "أدخنت": 1,
    "ادخنت": 1,
    "تدخن": 1,
    "الداخنة": 1,
    "الدخنان": 1,
    "الدخنة": 1,
    "الدخنس": 1,
    "الداخي": 1,
    "الدخى": 1,
    "الدخياء": 1,
    "الديدب": 1,
    "الددان": 1,
    "الددن": 1,
    "الديدن": 1,
    "الددا": 1,
    "الدد": 1,
    "أدرأت": 1,
    "دارأه": 1,
    "ادرأ": 1,
    "اندرأ": 1,
    "تدارءا": 1,
    "ادارءا": 1,
    "تدرأ": 1,
    "التدرأ": 1,
    "التدرأة": 1,
    "الدرء": 1,
    "الدريء": 1,
    "الدريئة": 1,
    "المدرأ": 1,
    "أدرب": 1,
    "الدارب": 1,
    "الدرب": 1,
    "الدربة": 1,
    "تدربأ": 1,
    "دربج": 1,
    "الدرابج": 1,
    "دربح": 1,
    "دربخ": 1,
    "تدربس": 1,
    "الدرابس": 1,
    "الدرباس": 1,
    "دربص": 1,
    "الدرابكة": 1,
    "الدربكة": 1,
    "دربل": 1,
    "الدربان": 1,
    "الدربانية": 1,
    "درجه": 1,
    "اندرج": 1,
    "استدرجه": 1,
    "الأدرجة": 1,
    "الدارج": 1,
    "الدريج": 1,
    "المدراج": 1,
    "درحه": 1,
    "درح": 1,
    "أدرده": 1,
    "الدردي": 1,
    "دردب": 1,
    "الدرداب": 1,
    "الدردب": 1,
    "الدردبي": 1,
    "الدردبيس": 1,
    "الدردح": 1,
    "الدردحة": 1,
    "دردر": 1,
    "تدردر": 1,
    "الدردر": 1,
    "الدردرى": 1,
    "الدردور": 1,
    "الدرداق": 1,
    "الدردق": 1,
    "الدردم": 1,
    "الدر": 1,
    "الدرر": 1,
    "الدرارة": 1,
    "الدرة": 1,
    "الدري": 1,
    "الدرير": 1,
    "المدرار": 1,
    "المدرة": 1,
    "الدرزة": 1,
    "الدرزي": 1,
    "الدرزية": 1,
    "أدرس": 1,
    "اندرس": 1,
    "تدارس": 1,
    "ادارس": 1,
    "تدرس": 1,
    "الدرياس": 1,
    "الدريس": 1,
    "المدروس": 1,
    "الدارش": 1,
    "دروش": 1,
    "تدروش": 1,
    "درصت": 1,
    "الدرص": 1,
    "الدروص": 1,
    "درعت": 1,
    "أدرع": 1,
    "اندرع": 1,
    "تدرع": 1,
    "تمدرع": 1,
    "الدارع": 1,
    "الدراعة": 1,
    "الدرعة": 1,
    "الدرعية": 1,
    "درفس": 1,
    "الدرفاس": 1,
    "الدرفس": 1,
    "الدرفسة": 1,
    "الدرفلة": 1,
    "درقه": 1,
    "تدرق": 1,
    "الدرق": 1,
    "الدرقاء": 1,
    "الدرقة": 1,
    "الدرياق": 1,
    "الدرياقة": 1,
    "درقع": 1,
    "ادرنقع": 1,
    "الدرقع": 1,
    "الدرقوع": 1,
    "درقل": 1,
    "الدرقل": 1,
    "الدرقلة": 1,
    "الدراقن": 1,
    "داركه": 1,
    "تدارك": 1,
    "استدرك": 1,
    "الدركة": 1,
    "الدريكة": 1,
    "المتدارك": 1,
    "درمت": 1,
    "أدرم": 1,
    "درمه": 1,
    "الأدرم": 1,
    "الدارم": 1,
    "الدراما": 1,
    "الدرام": 1,
    "الدرامة": 1,
    "الدرماء": 1,
    "الدرمة": 1,
    "الدريم": 1,
    "درمج": 1,
    "ادرمج": 1,
    "الدرامج": 1,
    "درمك": 1,
    "الدرمك": 1,
    "أدرن": 1,
    "الإدرون": 1,
    "الدرانة": 1,
    "الدرن": 1,
    "الدرين": 1,
    "المدران": 1,
    "الداره": 1,
    "الدارهة": 1,
    "الدريه": 1,
    "المدره": 1,
    "درهمت": 1,
    "ادرهم": 1,
    "المدرهم": 1,
    "أدراه": 1,
    "داراه": 1,
    "ادرت": 1,
    "تدرت": 1,
    "الدرية": 1,
    "المدرى": 1,
    "المدراة": 1,
    "الدست": 1,
    "الدستة": 1,
    "الدستجة": 1,
    "الداسرة": 1,
    "الدسار": 1,
    "الدواسر": 1,
    "الدوسر": 1,
    "الدوسرة": 1,
    "الدوسري": 1,
    "الدوسراني": 1,
    "دسه": 1,
    "دسسه": 1,
    "اندس": 1,
    "الدساس": 1,
    "الدساسة": 1,
    "الدسة": 1,
    "الدسيس": 1,
    "الدسيسة": 1,
    "دسع": 1,
    "ادسع": 1,
    "الدسيع": 1,
    "الدسيعة": 1,
    "المدسع": 1,
    "دسق": 1,
    "الدسق": 1,
    "الدسقان": 1,
    "الديسق": 1,
    "الدسكرة": 1,
    "أدسم": 1,
    "تدسم": 1,
    "الدسام": 1,
    "الدسمة": 1,
    "الدسيم": 1,
    "الديسم": 1,
    "الديسمة": 1,
    "دسا": 1,
    "دسى": 1,
    "تدسى": 1,
    "الدشت": 1,
    "الدشيشة": 1,
    "أدعب": 1,
    "داعبه": 1,
    "تداعب": 1,
    "تدعب": 1,
    "الداعب": 1,
    "الداعبة": 1,
    "الدعاب": 1,
    "الدعبب": 1,
    "الدعبوب": 1,
    "دعث": 1,
    "أدعث": 1,
    "اندعث": 1,
    "تدعثت": 1,
    "الدعث": 1,
    "دعثره": 1,
    "الدعثر": 1,
    "الدعثور": 1,
    "دعجت": 1,
    "الأدعج": 1,
    "الدعجاء": 1,
    "المدعوج": 1,
    "داع داع وداع داع": 1,
    "دع دع": 1,
    "دعدع": 1,
    "تدعدع": 1,
    "الدعداع": 1,
    "الدعدع": 1,
    "تدعر": 1,
    "الداعرة": 1,
    "الدعر": 1,
    "الدعرة": 1,
    "المداعير": 1,
    "الدعرور": 1,
    "دعرم": 1,
    "الدعرم": 1,
    "دعسه": 1,
    "أدعسه": 1,
    "داعسه": 1,
    "الدعس": 1,
    "الدعوس": 1,
    "الدعيس": 1,
    "المدعس": 1,
    "المدعاس": 1,
    "المدعوس": 1,
    "دعص": 1,
    "أدعص": 1,
    "داعصه": 1,
    "تدعص": 1,
    "اندعص": 1,
    "الدعص": 1,
    "الدعصاء": 1,
    "الدعصة": 1,
    "المدعص": 1,
    "دعه": 1,
    "أدع": 1,
    "الدعاع": 1,
    "دعقت": 1,
    "أدعق": 1,
    "الدعقة": 1,
    "المداعق": 1,
    "المداعيق": 1,
    "المدعق": 1,
    "المدعوقة": 1,
    "داعكه": 1,
    "تداعك": 1,
    "الدعكة": 1,
    "المدعك": 1,
    "المدعوك": 1,
    "دعله": 1,
    "داعله": 1,
    "الداعل": 1,
    "دعلج": 1,
    "الدعلج": 1,
    "الدعلجة": 1,
    "تداعمته": 1,
    "الأدعم": 1,
    "الدعام": 1,
    "الدعمة": 1,
    "الدعمي": 1,
    "المدعوم": 1,
    "دعن": 1,
    "أدعن": 1,
    "الدعن": 1,
    "المدعن": 1,
    "داعى": 1,
    "دعى": 1,
    "اندعى": 1,
    "تدعت": 1,
    "استدعاه": 1,
    "الأدعوة": 1,
    "الدعاوة": 1,
    "الدعاءة": 1,
    "الدعوي": 1,
    "الدعي": 1,
    "المدعاة": 1,
    "المدعى والمدعى عليه": 1,
    "المدغدغ": 1,
    "تدغر": 1,
    "الداغر": 1,
    "الدغرة": 1,
    "الدغرى": 1,
    "المدغرة": 1,
    "الدغرور": 1,
    "دغرق": 1,
    "الدغرق": 1,
    "أدغش": 1,
    "داغش": 1,
    "تداغش": 1,
    "الدغش": 1,
    "الدغشة": 1,
    "الدغيشة": 1,
    "أدغصه": 1,
    "داغصه": 1,
    "الداغصة": 1,
    "دغف": 1,
    "دغفاء": 1,
    "دغفق": 1,
    "الدغفق": 1,
    "الدغفل": 1,
    "الدغفلي": 1,
    "أدغل": 1,
    "الداغل": 1,
    "الداغلة": 1,
    "الدغل": 1,
    "الدغيلة": 1,
    "المدغل": 1,
    "ادغمه": 1,
    "ادغام": 1,
    "الأدغم": 1,
    "الدغام": 1,
    "الدغمان": 1,
    "دغمره": 1,
    "الدغمري": 1,
    "الدغمور": 1,
    "المدغمر": 1,
    "المدغمس": 1,
    "دغن": 1,
    "الدغنة": 1,
    "دغينة": 1,
    "أدفأت": 1,
    "دافأ": 1,
    "دفأه": 1,
    "تدفأ": 1,
    "استدفأ": 1,
    "الدفاء": 1,
    "الدفيئة": 1,
    "المدفئة": 1,
    "الدفتردار": 1,
    "دفدف": 1,
    "الدفادف": 1,
    "دفره": 1,
    "أدفر": 1,
    "دفار": 1,
    "الدفر": 1,
    "تدافع": 1,
    "تدفع": 1,
    "استدفع": 1,
    "الدفاع الشرعي": 1,
    "وزارة الدفاع": 1,
    "الدفوع": 1,
    "دفت": 1,
    "أدف": 1,
    "دافه": 1,
    "دفف": 1,
    "تداف": 1,
    "استدف": 1,
    "الدافة": 1,
    "الدفاف": 1,
    "أدفق": 1,
    "اندفق": 1,
    "استدفق": 1,
    "الأدفق": 1,
    "الدفقة": 1,
    "الدفوق": 1,
    "الدفل": 1,
    "دفنت": 1,
    "اندفن": 1,
    "تدافن": 1,
    "تدفن": 1,
    "الدفون": 1,
    "دفا": 1,
    "دفي": 1,
    "أدفى": 1,
    "دافى": 1,
    "تدافى": 1,
    "الدفواء": 1,
    "دقدق": 1,
    "دقر": 1,
    "الدقر والدقرى": 1,
    "الدقرارة": 1,
    "الدقران": 1,
    "دقس": 1,
    "أدقع": 1,
    "الأدقع": 1,
    "الداقع": 1,
    "الدقاع": 1,
    "الدقعاء": 1,
    "الدقعى": 1,
    "الدوقعة": 1,
    "الديقوع": 1,
    "المدقع": 1,
    "داقه": 1,
    "تداقا": 1,
    "استدق": 1,
    "الدقاق والدقاقة": 1,
    "الدقاق": 1,
    "الدقاقة": 1,
    "الدققة": 1,
    "الدقوق": 1,
    "الدقوقة": 1,
    "الدقية": 1,
    "المدق والمدق": 1,
    "المستدق": 1,
    "أدقلت": 1,
    "دوقل": 1,
    "الدقل": 1,
    "الدقلة": 1,
    "الدقيلة": 1,
    "الدوقل": 1,
    "دقمه": 1,
    "دقم": 1,
    "أدقم": 1,
    "الدقم": 1,
    "الدقمة": 1,
    "دقن": 1,
    "الديقان": 1,
    "دقي": 1,
    "دكدك": 1,
    "تدكدكت": 1,
    "الدكداك والدكدك": 1,
    "دكس": 1,
    "تداكس": 1,
    "الداكس": 1,
    "الدكاس": 1,
    "الدكيسة": 1,
    "الدوكس": 1,
    "دكع": 1,
    "الدكاع": 1,
    "تداك": 1,
    "اندك": 1,
    "الدكاء": 1,
    "الدك": 1,
    "الدكة": 1,
    "المدك": 1,
    "دكل": 1,
    "تدكل": 1,
    "الدكلة": 1,
    "دكمه": 1,
    "دكم": 1,
    "تداكم": 1,
    "أدكن": 1,
    "الدالب": 1,
    "الدلب": 1,
    "المدلبة": 1,
    "دلث": 1,
    "ادلث": 1,
    "اندلث": 1,
    "تدلث": 1,
    "الدلاث": 1,
    "المدالث": 1,
    "أدلج": 1,
    "ادلج": 1,
    "الدلجان": 1,
    "الدلجة": 1,
    "الدولج": 1,
    "المدلج": 1,
    "المدلجة": 1,
    "تدالح": 1,
    "دلخ": 1,
    "الدالخ": 1,
    "الدلاخ": 1,
    "الدلخة": 1,
    "الدلوخ": 1,
    "تدلدل": 1,
    "الدلدل": 1,
    "أدلس": 1,
    "دالسه": 1,
    "اندلس": 1,
    "تدلس": 1,
    "الدلس": 1,
    "الدلسة": 1,
    "دلصت": 1,
    "دلص": 1,
    "أدلصت": 1,
    "اندلص": 1,
    "الدلاص": 1,
    "الدلص": 1,
    "الدلصة": 1,
    "الدليص": 1,
    "دلظ": 1,
    "دالظه": 1,
    "اندلظ": 1,
    "الدلظى": 1,
    "الدلظ": 1,
    "الدليظ": 1,
    "أدلع": 1,
    "ادلع": 1,
    "اندلع": 1,
    "الأدلع": 1,
    "الدالع": 1,
    "الدلوع": 1,
    "الدليع": 1,
    "الدولع": 1,
    "أدلف": 1,
    "اندلف": 1,
    "تدلف": 1,
    "الدالف": 1,
    "الدلف": 1,
    "الدلوف": 1,
    "أدلقه": 1,
    "اندلق": 1,
    "تدلق": 1,
    "استدلق": 1,
    "الأدلق": 1,
    "الدالق": 1,
    "الدلق": 1,
    "الدلوق": 1,
    "دلكت": 1,
    "تدلك": 1,
    "الدلك": 1,
    "الدلاك": 1,
    "الدلوك": 1,
    "الدليك": 1,
    "المدالك": 1,
    "المدلك": 1,
    "دلله": 1,
    "اندل": 1,
    "تدللت": 1,
    "استدل": 1,
    "الأدل": 1,
    "الدل": 1,
    "الدليلي": 1,
    "الدليلة": 1,
    "دلم": 1,
    "ادلام": 1,
    "الدلام": 1,
    "الدلم": 1,
    "الدلماء": 1,
    "الدلمة": 1,
    "الديلم": 1,
    "ادلمس": 1,
    "الدلامس": 1,
    "الدلمس": 1,
    "دلمص": 1,
    "تدلمص": 1,
    "الدلامص": 1,
    "دلهه": 1,
    "تدله": 1,
    "الداله والدالهة": 1,
    "المدله": 1,
    "ادلهم": 1,
    "الدلهام": 1,
    "المدلهمة": 1,
    "دلا": 1,
    "دلي": 1,
    "أدلى": 1,
    "دالاه": 1,
    "دلى": 1,
    "ادلولى": 1,
    "الدالي": 1,
    "الدالية": 1,
    "الدلاة": 1,
    "الدمثاء": 1,
    "أدمج": 1,
    "دامجه": 1,
    "ادمج": 1,
    "تدامجوا": 1,
    "تدمج": 1,
    "الدامج": 1,
    "الدماج": 1,
    "الدمجة": 1,
    "الدميجة": 1,
    "المدماجة": 1,
    "تدمدم": 1,
    "الدمادم": 1,
    "الدمدم": 1,
    "دامر": 1,
    "دمست": 1,
    "أدمس": 1,
    "دامسه": 1,
    "اندمس": 1,
    "تدمست": 1,
    "الأدموس": 1,
    "الداموس": 1,
    "الدماس": 1,
    "الدمس": 1,
    "الدميس": 1,
    "الديماس": 1,
    "المدمس": 1,
    "دمش": 1,
    "أدمشه": 1,
    "دمشه": 1,
    "الدماشق": 1,
    "الدمشق": 1,
    "دمص": 1,
    "الدومص": 1,
    "دمعت": 1,
    "الدماع": 1,
    "الدمعان": 1,
    "الدميع": 1,
    "المدمع": 1,
    "أدمغ": 1,
    "الدامغة": 1,
    "الداموغ والداموغة": 1,
    "الدمغة": 1,
    "دمق": 1,
    "اندمق": 1,
    "الدمق": 1,
    "الدمقاس والدمقس": 1,
    "دمك": 1,
    "الدموك": 1,
    "المدماك": 1,
    "المدمك": 1,
    "أدمل": 1,
    "دامله": 1,
    "ادمل": 1,
    "اندمل": 1,
    "تدامل": 1,
    "تدملت": 1,
    "الدمال": 1,
    "الدملج والدملوج": 1,
    "دملق": 1,
    "الدملوق والدمالق": 1,
    "دملك": 1,
    "تدملك": 1,
    "الدملوك": 1,
    "الداماء": 1,
    "الديموم والديمومة": 1,
    "المدمة": 1,
    "أدمن": 1,
    "دمنت": 1,
    "تدمن": 1,
    "الأدمان": 1,
    "الدمان": 1,
    "الدمون": 1,
    "الدمن": 1,
    "الدمنة": 1,
    "أدمى": 1,
    "استدمى": 1,
    "الدامي": 1,
    "الدامية": 1,
    "الدمة": 1,
    "المدمى": 1,
    "دنئ": 1,
    "أدنأ": 1,
    "تدنأ": 1,
    "الدنيء": 1,
    "الدنيئة": 1,
    "الدنية": 1,
    "دنح": 1,
    "الدنح": 1,
    "دنخ": 1,
    "دنخت": 1,
    "دنر": 1,
    "تدنر": 1,
    "تدنس": 1,
    "الدنس": 1,
    "الدنع": 1,
    "أدنف": 1,
    "الدنف": 1,
    "دنق": 1,
    "الدوانيقي": 1,
    "دنقس": 1,
    "دنقع": 1,
    "دنن": 1,
    "الدنانة": 1,
    "الدنان": 1,
    "داناه": 1,
    "تدانى": 1,
    "تدنى": 1,
    "استدناه": 1,
    "الدانية": 1,
    "الدنا": 1,
    "ده ده": 1,
    "الدهب": 1,
    "دهبل": 1,
    "دهثه": 1,
    "الدهثم": 1,
    "دهدق": 1,
    "الدهداق": 1,
    "دهدم": 1,
    "تدهدم": 1,
    "دهده": 1,
    "تدهده": 1,
    "الدهداه": 1,
    "الدهدهان": 1,
    "الدهدهة": 1,
    "دهدى": 1,
    "تدهدى": 1,
    "داهره": 1,
    "دهور": 1,
    "الداهر": 1,
    "الداهرة": 1,
    "الدهارير": 1,
    "الدهري": 1,
    "الدهوري": 1,
    "الدهير": 1,
    "دهرج": 1,
    "أدهس": 1,
    "ادهاس": 1,
    "الدهاس": 1,
    "الدهس": 1,
    "الدهسة": 1,
    "الدهوس": 1,
    "دهشه": 1,
    "أدهشه": 1,
    "دهشر": 1,
    "الدهشرة": 1,
    "دهف": 1,
    "أدهف": 1,
    "الداهف": 1,
    "الداهفة": 1,
    "أدهق": 1,
    "ادهقت": 1,
    "الدهاق": 1,
    "الدهق": 1,
    "الدهقة": 1,
    "دهقن": 1,
    "تدهقن": 1,
    "الدهقان": 1,
    "دهك": 1,
    "الدهوك": 1,
    "تدهكر": 1,
    "الدهكر": 1,
    "تدهكم": 1,
    "الدهكم": 1,
    "الداهل": 1,
    "الدهل": 1,
    "دهمه": 1,
    "دهم": 1,
    "أدهمه": 1,
    "دهمت": 1,
    "ادهم": 1,
    "ادهام": 1,
    "الأدهم": 1,
    "الدهام": 1,
    "الدهم": 1,
    "الدهماء": 1,
    "الدهمة": 1,
    "الدهيم": 1,
    "الدهيماء": 1,
    "الدهمثة": 1,
    "الدهموث": 1,
    "دهمج": 1,
    "الدهامج": 1,
    "الدهمج": 1,
    "دهمس": 1,
    "دهمق": 1,
    "الدهامق": 1,
    "دهنت": 1,
    "أدهن": 1,
    "ادهان": 1,
    "تمدهن": 1,
    "الدهناء": 1,
    "الدهنة": 1,
    "الدهين": 1,
    "المدهن": 1,
    "المدهنة": 1,
    "دهاه": 1,
    "دهي": 1,
    "دهو": 1,
    "داهاه": 1,
    "تدهى": 1,
    "الداهي": 1,
    "الداهية": 1,
    "الدهو": 1,
    "الدهواء": 1,
    "الدهوية": 1,
    "الدهي": 1,
    "الدهياء": 1,
    "أدوأ": 1,
    "الديئ": 1,
    "الدوبارة": 1,
    "الدواج": 1,
    "داحت": 1,
    "انداح": 1,
    "تدوح": 1,
    "الداح": 1,
    "الداحة": 1,
    "الدوح": 1,
    "الدوحة": 1,
    "الدواح": 1,
    "أداخ": 1,
    "الدائخ": 1,
    "داد": 1,
    "ديد": 1,
    "الدواد": 1,
    "الدوداة": 1,
    "داوره": 1,
    "تدير": 1,
    "استدار": 1,
    "التدورة": 1,
    "الداري": 1,
    "الدارة": 1,
    "الدواري": 1,
    "الديور": 1,
    "الديرة": 1,
    "المدارة": 1,
    "أداس": 1,
    "انداس": 1,
    "الدوائس": 1,
    "الدواس": 1,
    "الدويسة": 1,
    "الديس": 1,
    "الديسة": 1,
    "المداس": 1,
    "المداسة": 1,
    "المدواس": 1,
    "المدوس": 1,
    "المدوسة": 1,
    "الدوسق": 1,
    "داش": 1,
    "الدوطة": 1,
    "عند الفرنجة": 1,
    "أداف": 1,
    "الدوفان": 1,
    "داق": 1,
    "ديقت": 1,
    "أداقوا": 1,
    "دوقه": 1,
    "انداق": 1,
    "تدوق": 1,
    "الدائق": 1,
    "الدوقانية": 1,
    "الدوقلة": 1,
    "داك": 1,
    "تداوك": 1,
    "الدوك": 1,
    "الدوكة": 1,
    "المداك": 1,
    "المدوك": 1,
    "أدال": 1,
    "داول": 1,
    "اندال": 1,
    "تداولت": 1,
    "استدال": 1,
    "الدويل": 1,
    "المداولة": 1,
    "ما دام": 1,
    "ديم به": 1,
    "أدامت": 1,
    "داوم": 1,
    "دومت": 1,
    "استدام": 1,
    "الدوم": 1,
    "الديمة": 1,
    "الديوم": 1,
    "المدام": 1,
    "المدامة": 1,
    "المدوام": 1,
    "المدوم": 1,
    "أدين": 1,
    "تدون": 1,
    "الدون": 1,
    "الديوان": 1,
    "أدوى": 1,
    "داوى": 1,
    "ادوى": 1,
    "تداوى": 1,
    "الداوية": 1,
    "الداية": 1,
    "الدوى": 1,
    "الدواة": 1,
    "الدواية": 1,
    "الدو": 1,
    "الدوية": 1,
    "الديبوب": 1,
    "داث": 1,
    "ديث": 1,
    "تديث": 1,
    "الديثان": 1,
    "الديثاني": 1,
    "الديراني": 1,
    "داص": 1,
    "انداص": 1,
    "الدائص": 1,
    "الدياص": 1,
    "الدياصة": 1,
    "المداكة": 1,
    "المديكة": 1,
    "الديلع": 1,
    "تداين": 1,
    "استدان": 1,
    "الدينة": 1,
    "المديان": 1,
    "دي دي": 1,
    "الذال": 1,
    "وذات الصدر": 1,
    "وذات الرئة": 1,
    "وذات الجنب": 1,
    "أذأبت": 1,
    "ذأبه": 1,
    "تذاءبت": 1,
    "تذأب": 1,
    "استذأب": 1,
    "الذؤابة": 1,
    "المذأبة": 1,
    "ذئر": 1,
    "أذأره": 1,
    "ذاءرت": 1,
    "ذأف": 1,
    "الذؤاف": 1,
    "ذأل": 1,
    "تذاءل": 1,
    "ذؤالة": 1,
    "المذأل": 1,
    "ذأمه": 1,
    "أذأمه": 1,
    "الذأم": 1,
    "الذأمة": 1,
    "أذب": 1,
    "ذبب": 1,
    "الذب": 1,
    "المذبة": 1,
    "ذبحه": 1,
    "اذبح": 1,
    "تذابحوا": 1,
    "الذابح": 1,
    "الذباح": 1,
    "الذبيح": 1,
    "الذبذب": 1,
    "ذبر": 1,
    "الذبر": 1,
    "المذبر": 1,
    "أذبله": 1,
    "الذابل": 1,
    "الذبالة": 1,
    "الذيلة": 1,
    "الذبيل": 1,
    "ذحجته": 1,
    "أذحجت": 1,
    "ذح": 1,
    "ذحذح": 1,
    "الذحذاح": 1,
    "الذحل": 1,
    "اذخر": 1,
    "الذاخر": 1,
    "الذخر": 1,
    "المذاخر": 1,
    "ذرئ": 1,
    "أذرأه": 1,
    "الذرأة": 1,
    "الذرء": 1,
    "ذرء ذرء": 1,
    "الذرآني": 1,
    "الذرئ": 1,
    "أذرب": 1,
    "الذراب": 1,
    "الذرب": 1,
    "الذربة": 1,
    "المذرب": 1,
    "ذرح": 1,
    "الذراح": 1,
    "الذريحة": 1,
    "الذريحي": 1,
    "ذرت": 1,
    "الذرارة": 1,
    "الذرور": 1,
    "الذريرة": 1,
    "المذره": 1,
    "ذارعت": 1,
    "انذرع": 1,
    "تذارعوا": 1,
    "استذرع": 1,
    "الذرع": 1,
    "الذرعة": 1,
    "الذروع": 1,
    "الذريع": 1,
    "المذراع": 1,
    "المذرع": 1,
    "ذرفت": 1,
    "تذارف": 1,
    "استذرف": 1,
    "الذراف": 1,
    "المذرف": 1,
    "أذرق": 1,
    "الذراق": 1,
    "أذرت": 1,
    "تذرى": 1,
    "استذرى": 1,
    "الذرا": 1,
    "الذراوة": 1,
    "الذرو": 1,
    "المذرى": 1,
    "المذراة": 1,
    "دغدغت": 1,
    "تذعذع": 1,
    "الذعاذع": 1,
    "الذعذاع": 1,
    "ذعره": 1,
    "أذعره": 1,
    "تذعر": 1,
    "انذعر": 1,
    "الذاعر": 1,
    "الذعرة": 1,
    "الذعور": 1,
    "ذعف": 1,
    "أذعفه": 1,
    "انذعف": 1,
    "الذعاف": 1,
    "الذعف": 1,
    "أذعن": 1,
    "المذعان": 1,
    "ذفذف": 1,
    "استذفر": 1,
    "الذفرى": 1,
    "ذف": 1,
    "أذف": 1,
    "ذاف": 1,
    "ذففت": 1,
    "استذف": 1,
    "الذفاف": 1,
    "الذف": 1,
    "الذفف": 1,
    "الذفيف": 1,
    "المذفف": 1,
    "ذقنت": 1,
    "الذاقنة": 1,
    "أذكرت": 1,
    "ذاكره": 1,
    "اذكره": 1,
    "تذاكروا": 1,
    "تذكرت": 1,
    "الذكرة": 1,
    "الذكير": 1,
    "المذكار": 1,
    "ذكت": 1,
    "أذكت": 1,
    "استذكت": 1,
    "الذكا": 1,
    "الذكاة": 1,
    "الذكوة": 1,
    "أذلق": 1,
    "انذلق": 1,
    "الذلقة": 1,
    "الذولق": 1,
    "المذلاقة": 1,
    "تذلذل": 1,
    "الذلذل": 1,
    "أذل": 1,
    "ذلله": 1,
    "استذله": 1,
    "الذلول": 1,
    "المذلل": 1,
    "ذمره": 1,
    "تذامروا": 1,
    "الذمار": 1,
    "الذمارة": 1,
    "الذمر": 1,
    "الذمرة": 1,
    "الذمير": 1,
    "المذمر": 1,
    "ذمل": 1,
    "ذمله": 1,
    "أذم": 1,
    "ذممه": 1,
    "تذاموا": 1,
    "تذمم": 1,
    "استذم": 1,
    "الأذم": 1,
    "الذمام": 1,
    "الذمامة": 1,
    "الذمي": 1,
    "الذميم": 1,
    "الذميمة": 1,
    "المذم": 1,
    "المذمة": 1,
    "المذمم": 1,
    "ذمه": 1,
    "أذمهته": 1,
    "ذمى": 1,
    "أذماه": 1,
    "استذمى": 1,
    "الذمى": 1,
    "الذماء": 1,
    "المذماة": 1,
    "أذنب": 1,
    "تذانب": 1,
    "تذنب": 1,
    "استذنب": 1,
    "الأذنب": 1,
    "الذناب": 1,
    "الذنابى": 1,
    "الذنابة": 1,
    "الذنبة": 1,
    "الذنيباء": 1,
    "الذنبية": 1,
    "ذن": 1,
    "ذنن": 1,
    "الذنان": 1,
    "الذنانة": 1,
    "الذنن": 1,
    "الذنين": 1,
    "أذهبه": 1,
    "ذهبه": 1,
    "الذهبة": 1,
    "الذهبية": 1,
    "الذهيب": 1,
    "ذهله": 1,
    "ذهل": 1,
    "أذهله": 1,
    "الذهل": 1,
    "المذهل": 1,
    "ذاهنه": 1,
    "استذهنه": 1,
    "أذاب": 1,
    "ذوبه": 1,
    "استذاب": 1,
    "الإذواب": 1,
    "الإذوابة": 1,
    "الذوب": 1,
    "الذوبة": 1,
    "المذوب": 1,
    "المذوبة": 1,
    "ذاح": 1,
    "ذوح": 1,
    "المذوح": 1,
    "ذاده": 1,
    "أذاده": 1,
    "الذود": 1,
    "المذاد": 1,
    "المذود": 1,
    "تذاوق": 1,
    "استذاق": 1,
    "الذواق": 1,
    "ذول": 1,
    "أذواه": 1,
    "الذواة": 1,
    "ذبأه": 1,
    "تذيأ": 1,
    "أذاخ": 1,
    "ذيخت": 1,
    "الذيخ": 1,
    "المذيخة": 1,
    "ذاط": 1,
    "أذاعه": 1,
    "أذاله": 1,
    "تذايلت": 1,
    "تذيلت": 1,
    "الذيال": 1,
    "ذامه": 1,
    "الذيم": 1,
    "الراء": 1,
    "الراتين": 1,
    "رأبت": 1,
    "الرئابة": 1,
    "رأبل": 1,
    "ترأبل": 1,
    "الرئبال": 1,
    "رأد": 1,
    "ارتأدت": 1,
    "تراءد": 1,
    "ترأد": 1,
    "الرأد": 1,
    "الرؤد": 1,
    "الرئد": 1,
    "الرأدة": 1,
    "الرؤدة": 1,
    "الرؤودة": 1,
    "رئس": 1,
    "راءس": 1,
    "ارتأس": 1,
    "الرائس": 1,
    "الرئاس": 1,
    "الرآس": 1,
    "الرؤاس": 1,
    "الرؤاسي": 1,
    "الريس": 1,
    "المرآس": 1,
    "رئف": 1,
    "تراءفوا": 1,
    "ترأف": 1,
    "استرأفه": 1,
    "أرألت": 1,
    "راءل": 1,
    "استرأل": 1,
    "الرال": 1,
    "الراءول": 1,
    "الرؤال": 1,
    "أرأمها": 1,
    "راءم": 1,
    "ترأمت": 1,
    "الرأم": 1,
    "الرئم": 1,
    "الرؤمة": 1,
    "رئي": 1,
    "أرأى": 1,
    "راءاه": 1,
    "ترأى": 1,
    "تمرأى": 1,
    "استرأى": 1,
    "الرئي": 1,
    "الرواء": 1,
    "المرأى": 1,
    "رابأه": 1,
    "ارتبأ": 1,
    "الربيء": 1,
    "الربيئة": 1,
    "المربأ": 1,
    "المربأة": 1,
    "المرتبأ": 1,
    "أربت": 1,
    "ربب": 1,
    "ارتب": 1,
    "تربب": 1,
    "التربيب": 1,
    "الرابة": 1,
    "الرباب": 1,
    "الربابة": 1,
    "الربب": 1,
    "الربى": 1,
    "الربي": 1,
    "الربوب": 1,
    "الربيب": 1,
    "الربيبة": 1,
    "المرباب": 1,
    "المرب": 1,
    "ربثه": 1,
    "ارتبث": 1,
    "اربث": 1,
    "تربث": 1,
    "ارباث": 1,
    "الربيثى": 1,
    "ربج": 1,
    "أربج": 1,
    "تربج": 1,
    "الرابج": 1,
    "الرباجي": 1,
    "أربحت": 1,
    "رابحه": 1,
    "تربح": 1,
    "الربيح": 1,
    "المرابحة": 1,
    "ربخ": 1,
    "أربخ": 1,
    "تربخ": 1,
    "الربيخ": 1,
    "اربد": 1,
    "تربد": 1,
    "ارباد": 1,
    "الربد": 1,
    "الربيد": 1,
    "الربيدة": 1,
    "المربد": 1,
    "ربذ": 1,
    "أربذ": 1,
    "الربذاني": 1,
    "الربذة": 1,
    "الربذي": 1,
    "المرباذ": 1,
    "الربرب": 1,
    "ربز": 1,
    "ارتبز": 1,
    "الربيز": 1,
    "ربسه": 1,
    "ارتبس": 1,
    "اربس": 1,
    "الربس": 1,
    "الربيس": 1,
    "ربشت": 1,
    "أربش": 1,
    "الربش": 1,
    "الربصة": 1,
    "ربضت": 1,
    "أربضت": 1,
    "تربض": 1,
    "الرابض": 1,
    "الرابضة": 1,
    "الربض": 1,
    "الربضة": 1,
    "الربوض": 1,
    "الربيض": 1,
    "الرويبضة": 1,
    "المربض": 1,
    "ارتبط": 1,
    "الربيط": 1,
    "الربيطة": 1,
    "المرابطة": 1,
    "المربطة": 1,
    "ارتبع": 1,
    "تربعت": 1,
    "استربع": 1,
    "الأربعا": 1,
    "الرباع": 1,
    "الرباعة": 1,
    "الربعة": 1,
    "الربعي": 1,
    "الربوع": 1,
    "الربيعة": 1,
    "الروبع": 1,
    "الروبعة": 1,
    "المرباع": 1,
    "المربوع": 1,
    "اليربوع": 1,
    "ربغ": 1,
    "أربغت": 1,
    "الرابغ": 1,
    "الربغ": 1,
    "ربقه": 1,
    "ارتبق": 1,
    "الربق": 1,
    "الريبق": 1,
    "ارتبك": 1,
    "الربك": 1,
    "الربيك": 1,
    "الربيكة": 1,
    "ارتبل": 1,
    "الرابلة": 1,
    "الربل": 1,
    "الربلة": 1,
    "الربيل": 1,
    "الربيلة": 1,
    "الريبال": 1,
    "الريبالة": 1,
    "الريبل": 1,
    "المربال": 1,
    "أربنه": 1,
    "تربن": 1,
    "الربون": 1,
    "ربًا": 1,
    "راباه": 1,
    "رباه": 1,
    "الرباء": 1,
    "الرباة": 1,
    "الرباوة": 1,
    "رتأ": 1,
    "أرتأ": 1,
    "رتبه": 1,
    "أرته": 1,
    "الأرت": 1,
    "الرت": 1,
    "الرتة": 1,
    "الرتى": 1,
    "الرتاج": 1,
    "الرتاجة": 1,
    "الرتج": 1,
    "المراتج": 1,
    "المرتاج": 1,
    "رتخ": 1,
    "أرتخ": 1,
    "الرتخ": 1,
    "الرتخة": 1,
    "رتعت": 1,
    "أرتع": 1,
    "الرتاع": 1,
    "الرتعة": 1,
    "المرتع": 1,
    "ارتتق": 1,
    "الرتاق": 1,
    "الرتق": 1,
    "الرتقة": 1,
    "أرتك": 1,
    "ترتل": 1,
    "الرتل": 1,
    "الرتيلي": 1,
    "رتمت": 1,
    "أرتم": 1,
    "ارتتم": 1,
    "ترتم": 1,
    "الرتام": 1,
    "الرتم": 1,
    "الرتمة": 1,
    "الرتيمة": 1,
    "رتن": 1,
    "المرتنة": 1,
    "الراتي": 1,
    "الرتوة": 1,
    "الرتينة": 1,
    "رثأ": 1,
    "رثئ": 1,
    "أرثأ": 1,
    "ارتثأ": 1,
    "الرثء": 1,
    "الرثأة": 1,
    "الرثيئة": 1,
    "المرثوء": 1,
    "ارتثوا": 1,
    "ارتث": 1,
    "الرث": 1,
    "الرثة": 1,
    "الرثيث": 1,
    "رثد": 1,
    "أرثد": 1,
    "ارتثد": 1,
    "الرثد": 1,
    "الرثدة": 1,
    "المرثد": 1,
    "رثع": 1,
    "ارثعن": 1,
    "المرثعن": 1,
    "رثم": 1,
    "رثمت": 1,
    "الرثم": 1,
    "الرثمة": 1,
    "الرثيم": 1,
    "المرثم": 1,
    "رثنت": 1,
    "ترثنت": 1,
    "الرثنة": 1,
    "الرثو": 1,
    "ترثاه": 1,
    "الرثاية": 1,
    "الرثية": 1,
    "المرثاة": 1,
    "أرجأت": 1,
    "المرجئة": 1,
    "أرجب": 1,
    "الأرجاب": 1,
    "الراجبة": 1,
    "الرجب": 1,
    "الرجبة": 1,
    "الرجبية": 1,
    "رجه": 1,
    "أرجت": 1,
    "الارتجاج": 1,
    "الرجاج": 1,
    "الرجاجة": 1,
    "الرجة": 1,
    "أرجحه": 1,
    "راجحه": 1,
    "رجحه": 1,
    "ارتجح": 1,
    "ترجح": 1,
    "الراجح": 1,
    "الرجاح": 1,
    "الرجاحة": 1,
    "الرجحانية": 1,
    "المرجاح": 1,
    "المرجوحة": 1,
    "ارجحن": 1,
    "رجد": 1,
    "ترجرج": 1,
    "الرجراجة": 1,
    "الرجرج": 1,
    "الرجرجة": 1,
    "راجزه": 1,
    "رجزه": 1,
    "ارتجز": 1,
    "تراجز": 1,
    "ترجز": 1,
    "الأرجوزة": 1,
    "الراجز": 1,
    "الرجازة": 1,
    "الرجاز": 1,
    "أرجس": 1,
    "ارتجست": 1,
    "المرجاس": 1,
    "المرجوسة": 1,
    "ارتجع": 1,
    "الرجاع": 1,
    "الرجعة": 1,
    "الرجيع": 1,
    "الرجيعة": 1,
    "المرجعة": 1,
    "المرجوع": 1,
    "المرجوعة": 1,
    "أرجف": 1,
    "ارتجف": 1,
    "استرجف": 1,
    "الإرجاف": 1,
    "الراجف": 1,
    "الرجاف": 1,
    "الرجف": 1,
    "أرجلت": 1,
    "ارتجل": 1,
    "الراجل": 1,
    "الرجلان": 1,
    "الرجليون": 1,
    "الرجولة والرجولية": 1,
    "الرجيل": 1,
    "المرجل": 1,
    "رجمه": 1,
    "ارتجم": 1,
    "تراجموا": 1,
    "استرجمه": 1,
    "الرجام": 1,
    "الرجمة": 1,
    "المراجم": 1,
    "المرجام": 1,
    "المرجم": 1,
    "رجن": 1,
    "أرجن": 1,
    "ارتجن": 1,
    "الرجين": 1,
    "الرجينة": 1,
    "المرجونة": 1,
    "ارتجاه": 1,
    "ترجاه": 1,
    "الأرجية": 1,
    "الترجي": 1,
    "الرجا": 1,
    "الرجية": 1,
    "أرحب": 1,
    "تراحب": 1,
    "الرحاب": 1,
    "الرحبى": 1,
    "الرحبة": 1,
    "الرحيب": 1,
    "المرحب": 1,
    "أبو مرحب": 1,
    "رح": 1,
    "الأرح": 1,
    "ترحرحت": 1,
    "الرحراح": 1,
    "الرحرح": 1,
    "الرحرحان": 1,
    "أرحض": 1,
    "رحضه": 1,
    "ارتحض": 1,
    "الرحاضة": 1,
    "الرحض": 1,
    "الرحضاء": 1,
    "المرحضة": 1,
    "الرحاق": 1,
    "أرحل": 1,
    "راحله": 1,
    "ترحل": 1,
    "استرحله": 1,
    "الراحلة": 1,
    "الراحول": 1,
    "الرحول": 1,
    "الرحولة": 1,
    "المرتحل": 1,
    "المرحل": 1,
    "تراحم": 1,
    "ترحم": 1,
    "استرحمه": 1,
    "الراحم": 1,
    "الرحام": 1,
    "الرحم والرحم والرحم": 1,
    "الرحموت": 1,
    "الرحمى": 1,
    "الرحوم": 1,
    "المرحمة": 1,
    "رحت": 1,
    "ترحت": 1,
    "الرحا والرحى": 1,
    "المرحى": 1,
    "ارتخ": 1,
    "الرخاخ": 1,
    "الرخ": 1,
    "الرخاء": 1,
    "الرخراخ": 1,
    "الرخرخ": 1,
    "ارتخصه": 1,
    "ترخص": 1,
    "استرخصه": 1,
    "الرخصة والرخصة": 1,
    "الرخيص": 1,
    "أرخف": 1,
    "الرخفة": 1,
    "ترخل": 1,
    "الرخاخيل": 1,
    "الرخل": 1,
    "الرخلة": 1,
    "أرخمت": 1,
    "الرخامى": 1,
    "الرخم": 1,
    "أرخى": 1,
    "رخى": 1,
    "راخى": 1,
    "تراخى": 1,
    "استرخى": 1,
    "الأرخية": 1,
    "الرخو": 1,
    "الرخي": 1,
    "المرخاء": 1,
    "رخود": 1,
    "الرخود": 1,
    "الرخودة": 1,
    "ردأه": 1,
    "ترادأ": 1,
    "الردء": 1,
    "تردب": 1,
    "الرداب": 1,
    "الردب": 1,
    "ردج": 1,
    "الردج": 1,
    "اليرندج": 1,
    "ردحت": 1,
    "أردح": 1,
    "الرادحة": 1,
    "الرداح": 1,
    "الرداحة": 1,
    "الردح": 1,
    "الردحة": 1,
    "الردحي": 1,
    "الردوح": 1,
    "المرتدح": 1,
    "ردخ": 1,
    "الردخ": 1,
    "راده": 1,
    "ردده": 1,
    "تراد": 1,
    "استرده": 1,
    "الأرد": 1,
    "الراد": 1,
    "الرادة": 1,
    "الردي": 1,
    "الرديد": 1,
    "الاسترداد": 1,
    "المرد": 1,
    "المردد": 1,
    "المردودة": 1,
    "ردسه": 1,
    "تردس": 1,
    "المرداس": 1,
    "المردس": 1,
    "ردعه": 1,
    "ارتدع": 1,
    "ترادعوا": 1,
    "تردع": 1,
    "الأردع": 1,
    "الرداع": 1,
    "الردعاء": 1,
    "الرديع": 1,
    "المردع": 1,
    "ردغ": 1,
    "أردغ": 1,
    "ارتدغ": 1,
    "الردغة": 1,
    "المردغة": 1,
    "ردفه": 1,
    "أردف": 1,
    "رادفت": 1,
    "ارتدف": 1,
    "ترادفا": 1,
    "تردفه": 1,
    "استردفه": 1,
    "الرداف": 1,
    "الردافى": 1,
    "الردافة": 1,
    "الرديف": 1,
    "أردم": 1,
    "ارتدم": 1,
    "تردم": 1,
    "الأردم": 1,
    "الردام": 1,
    "الردم": 1,
    "الرديم": 1,
    "الرديمة": 1,
    "المتردم": 1,
    "ارتدن": 1,
    "رودن": 1,
    "الرادن": 1,
    "الردن": 1,
    "الرديني": 1,
    "المردن": 1,
    "الرده": 1,
    "الردهة": 1,
    "أردى": 1,
    "رادى": 1,
    "رداه": 1,
    "ترادوا": 1,
    "الردى": 1,
    "الرداة": 1,
    "الردية": 1,
    "المردى": 1,
    "المرداة": 1,
    "المردي": 1,
    "أرذت": 1,
    "المرذاذ": 1,
    "الروذق": 1,
    "رذله": 1,
    "استرذله": 1,
    "الأرذل": 1,
    "الرذال": 1,
    "الرذالة": 1,
    "الرذل": 1,
    "الرذيل": 1,
    "الرذيلة": 1,
    "رذم": 1,
    "أرذم": 1,
    "الرذم": 1,
    "الرذوم": 1,
    "الروذمة": 1,
    "رذي": 1,
    "أرذى": 1,
    "أرذي": 1,
    "المرذي": 1,
    "رزأه": 1,
    "رزئه": 1,
    "ارتزأ": 1,
    "ترازءوا": 1,
    "الرزء": 1,
    "الرزيئة": 1,
    "المرزئة": 1,
    "الإرزب": 1,
    "الإرزبة": 1,
    "المرزاب": 1,
    "المرزبان": 1,
    "المرزبة": 1,
    "أرزح": 1,
    "رزحه": 1,
    "ترازح": 1,
    "المرزاح": 1,
    "المرزح": 1,
    "المرزحة": 1,
    "المرزيح": 1,
    "رزخه": 1,
    "المرزخة": 1,
    "الرزداق": 1,
    "الرزدق": 1,
    "رزرزه": 1,
    "رزت": 1,
    "أرزت": 1,
    "رززه": 1,
    "ارتز": 1,
    "الإرزيز": 1,
    "الرزاز": 1,
    "الرزازة": 1,
    "الرزة": 1,
    "الرزيز": 1,
    "المرزة": 1,
    "رزغ": 1,
    "أرزغ": 1,
    "ارتزغ": 1,
    "استرزغه": 1,
    "رازغه": 1,
    "الرازغ": 1,
    "الرزغة": 1,
    "رزف": 1,
    "أرزف": 1,
    "الرزافة": 1,
    "ارتزق": 1,
    "استرزقه": 1,
    "الرازقي": 1,
    "الرازقية": 1,
    "الرزقة": 1,
    "الروازق": 1,
    "المرتزقة": 1,
    "المرزوق": 1,
    "أزرم": 1,
    "ترزم": 1,
    "الرزام": 1,
    "الرزيم": 1,
    "المرزام": 1,
    "المرزم": 1,
    "رازنه": 1,
    "ترازن": 1,
    "ترزن": 1,
    "الأرزن": 1,
    "الرزان": 1,
    "الرزن": 1,
    "الرزنة": 1,
    "الروزنة": 1,
    "أرسب": 1,
    "الرسابة": 1,
    "الرسوب": 1,
    "المراسب": 1,
    "الرستاق": 1,
    "رسح": 1,
    "أرسحه": 1,
    "الأرسح": 1,
    "الرسحاء": 1,
    "أرسخه": 1,
    "رسرس": 1,
    "رسس": 1,
    "ارتس": 1,
    "تراسوا": 1,
    "الأرسوسة": 1,
    "الزس": 1,
    "الرسة": 1,
    "الرسيس": 1,
    "رسعت": 1,
    "الرساعة": 1,
    "الرسيع": 1,
    "المرسع": 1,
    "المرسعة": 1,
    "راسغه": 1,
    "ارتسغ": 1,
    "الرساغ": 1,
    "الرسغ": 1,
    "الرسيغ": 1,
    "المرسغ": 1,
    "أرسف": 1,
    "راسله": 1,
    "تراسل": 1,
    "استرسل": 1,
    "الراسلان": 1,
    "الرسال": 1,
    "الرسلة": 1,
    "الرسيل": 1,
    "الرسيلة": 1,
    "المرسال": 1,
    "المرسلة": 1,
    "رسمت": 1,
    "أرسم": 1,
    "ارتسم": 1,
    "الراسوم": 1,
    "الروسم": 1,
    "المرسم": 1,
    "أرسنت": 1,
    "الأرسان": 1,
    "الراسن": 1,
    "المرسن": 1,
    "الراسي": 1,
    "الراسية": 1,
    "الرسوة": 1,
    "الرسي": 1,
    "رشأت": 1,
    "الرشأ": 1,
    "الرشتة": 1,
    "أرشح": 1,
    "رشحه": 1,
    "استرشح": 1,
    "الارتشاح": 1,
    "الراشح": 1,
    "الرشوح": 1,
    "الرشيح": 1,
    "المرشحة": 1,
    "أرشده": 1,
    "استرشد": 1,
    "الراشد": 1,
    "الرشادة": 1,
    "الرشدة": 1,
    "الرشيدية": 1,
    "المراشد": 1,
    "الرشراش": 1,
    "الرشرش": 1,
    "رشت": 1,
    "أرشت": 1,
    "ترشش": 1,
    "استرش": 1,
    "المرش": 1,
    "المرشة": 1,
    "رشفه": 1,
    "أرشف": 1,
    "ارتشفه": 1,
    "ترشفه": 1,
    "الرشوف": 1,
    "الرشيفة": 1,
    "المرشف": 1,
    "رشقه": 1,
    "أرشق": 1,
    "راشقه": 1,
    "تراشق": 1,
    "ترشق": 1,
    "الأرشق": 1,
    "الرشق": 1,
    "رشمه": 1,
    "أرشم": 1,
    "ارتشم": 1,
    "الأرشم": 1,
    "الراشوم": 1,
    "الرشم": 1,
    "الروشم": 1,
    "المرشم": 1,
    "رشن": 1,
    "الراشن": 1,
    "الرشن": 1,
    "الروشن": 1,
    "أرشى": 1,
    "راشاه": 1,
    "ارتشى": 1,
    "ترشاه": 1,
    "استرشى": 1,
    "الرشاء": 1,
    "الرشاة": 1,
    "الرصب": 1,
    "رصده": 1,
    "رصدت": 1,
    "أرصدت": 1,
    "راصده": 1,
    "ارتصده": 1,
    "تراصدا": 1,
    "ترصده": 1,
    "الراصد": 1,
    "الرصد": 1,
    "الرصدة": 1,
    "الرصدي": 1,
    "رصرص": 1,
    "الرصراصة": 1,
    "رصه": 1,
    "رصص": 1,
    "ارتصت": 1,
    "تراصت": 1,
    "ترصصت": 1,
    "الأرصوصة": 1,
    "الرصاصي": 1,
    "الرصيص": 1,
    "أرصعه": 1,
    "رصعه": 1,
    "ارتصع": 1,
    "الرصيع": 1,
    "الرصيعة": 1,
    "المرصاع": 1,
    "المرصعان": 1,
    "أرصف": 1,
    "رصفه": 1,
    "ارتصف": 1,
    "تراصف": 1,
    "ترصف": 1,
    "الرصاف": 1,
    "الرصافة": 1,
    "الرصفة": 1,
    "المرصافة": 1,
    "أرصقه": 1,
    "ارتصق": 1,
    "رصنه": 1,
    "أرصنه": 1,
    "الراصن": 1,
    "الرصين": 1,
    "المرصن": 1,
    "رصاه": 1,
    "أرصى": 1,
    "رضب": 1,
    "ترضب": 1,
    "الراضب": 1,
    "الرضاب": 1,
    "المرضب": 1,
    "رضحه": 1,
    "ارتضح": 1,
    "ترضح": 1,
    "الرضح": 1,
    "الرضحة": 1,
    "المرضاح": 1,
    "المرضحة": 1,
    "رضخت": 1,
    "أرضخ": 1,
    "تراضخوا": 1,
    "ترضخ": 1,
    "الرضاخة": 1,
    "الرضخ": 1,
    "الرضخة": 1,
    "الرضيخة": 1,
    "المرضاخ": 1,
    "المرضخة": 1,
    "رضرضه": 1,
    "ترضرض": 1,
    "الرضراض": 1,
    "الرضراضة": 1,
    "الرضرض": 1,
    "ارتض": 1,
    "الرضاض": 1,
    "الرض": 1,
    "المرضة": 1,
    "رضعها": 1,
    "راضعه": 1,
    "ارتضعها": 1,
    "تراضعا": 1,
    "استرضع": 1,
    "الراضع": 1,
    "الراضعة": 1,
    "الرضوعة": 1,
    "المرضعة": 1,
    "رضفه": 1,
    "المرضافة": 1,
    "أرضك": 1,
    "رضم": 1,
    "ارتضم": 1,
    "الرضام": 1,
    "الرضم": 1,
    "الرضمة": 1,
    "المرضوم": 1,
    "رضنه": 1,
    "رضاه": 1,
    "رضيه": 1,
    "أرضاه": 1,
    "راضاه": 1,
    "ارتضاه": 1,
    "تراضيا": 1,
    "استرضاه": 1,
    "الرضاء": 1,
    "الرضي": 1,
    "رطأه": 1,
    "رطئ": 1,
    "استرطأ": 1,
    "ترطب": 1,
    "الرطب": 1,
    "الرطبة": 1,
    "الرطيب": 1,
    "المرطبة": 1,
    "الرطراط": 1,
    "ارطست": 1,
    "أرط": 1,
    "استرطه": 1,
    "الرطيط": 1,
    "أرطل": 1,
    "راطله": 1,
    "استرطله": 1,
    "رطمه": 1,
    "أرطم": 1,
    "ارتطم": 1,
    "تراطم": 1,
    "ترطم": 1,
    "الراطم": 1,
    "الرطام": 1,
    "الرطمة": 1,
    "الرطوم": 1,
    "الرطومة": 1,
    "راطنه": 1,
    "تراطنا": 1,
    "الأرطى": 1,
    "أرعبه": 1,
    "رعبت": 1,
    "ارتعب": 1,
    "الأرعب": 1,
    "الراعبي": 1,
    "الرعيب": 1,
    "الترعابة": 1,
    "الترعيبة": 1,
    "المرعبة": 1,
    "رعبب": 1,
    "الرعبب": 1,
    "الرعبوب": 1,
    "الرعبوبة": 1,
    "رعبل": 1,
    "ترعبل": 1,
    "الرعبل": 1,
    "الرعبلة": 1,
    "الرعبولة": 1,
    "رعثت": 1,
    "رعثها": 1,
    "ارتعثت": 1,
    "ترعثت": 1,
    "الأرعوثة": 1,
    "الراعوثة": 1,
    "الرعث": 1,
    "الرعثاء": 1,
    "الرعثة": 1,
    "رعج": 1,
    "أرعج": 1,
    "ارتعج": 1,
    "الرعج": 1,
    "أرعد": 1,
    "ارتعد": 1,
    "ترعد": 1,
    "الراعد": 1,
    "الرعدة": 1,
    "الرعاد": 1,
    "رعدد": 1,
    "ترعدد": 1,
    "الرعديد": 1,
    "الرعديدة": 1,
    "ترعرع": 1,
    "الرعراع": 1,
    "الرعرع": 1,
    "راعز": 1,
    "المرعز": 1,
    "المرعزاء": 1,
    "المرعزي": 1,
    "الممرعز": 1,
    "رعس": 1,
    "أرعسه": 1,
    "ارتعس": 1,
    "ترعس": 1,
    "الرعاس": 1,
    "الرعوس": 1,
    "المرعس": 1,
    "أرعشه": 1,
    "رعشه": 1,
    "ارتعش": 1,
    "الرعاش": 1,
    "الرعاشي": 1,
    "الرعش": 1,
    "الرعشاء": 1,
    "الرعيش": 1,
    "المرعش": 1,
    "الرعشيش": 1,
    "الرعشن": 1,
    "أرعصه": 1,
    "ارتعص": 1,
    "ترعص": 1,
    "رعض": 1,
    "أرعضه": 1,
    "ارتعض": 1,
    "رعظ": 1,
    "أرعظه": 1,
    "رعظه": 1,
    "ترعظ": 1,
    "الرعظ": 1,
    "رع": 1,
    "الرعاعة": 1,
    "أرعف": 1,
    "ارتعف": 1,
    "استرعف": 1,
    "الأرعوفة": 1,
    "الراعف": 1,
    "الرعاف": 1,
    "الرعافي": 1,
    "الرعوف": 1,
    "الرعيف": 1,
    "المراعف": 1,
    "رعقت": 1,
    "الرعاق": 1,
    "الرعيق": 1,
    "رعله": 1,
    "رعل": 1,
    "أرعلت": 1,
    "استرعل": 1,
    "الأرعل": 1,
    "الراعل": 1,
    "الرعال": 1,
    "الرعل": 1,
    "الرعلاء": 1,
    "الرعلة": 1,
    "الرعولي": 1,
    "الرعيل": 1,
    "المرعل": 1,
    "رعم": 1,
    "رعمه": 1,
    "الرعام": 1,
    "الرعم": 1,
    "الرعوم": 1,
    "الأرعن": 1,
    "الرعن": 1,
    "الرعناء": 1,
    "الرعون": 1,
    "الأرعاوية": 1,
    "الأرعوة": 1,
    "الرعاوى": 1,
    "الرعاوية": 1,
    "رعت": 1,
    "أرعت": 1,
    "راعاه": 1,
    "رعاه": 1,
    "ارتعت": 1,
    "ترعت": 1,
    "استرعاه": 1,
    "الترعاية": 1,
    "الترعي": 1,
    "الترعية": 1,
    "المرعاة": 1,
    "أرغب": 1,
    "رغبه": 1,
    "ارتغب": 1,
    "تراغب": 1,
    "الرغاب": 1,
    "الرغب": 1,
    "الرغبانة": 1,
    "الرغيب": 1,
    "الرغيبة": 1,
    "المرغب": 1,
    "المرغبة": 1,
    "أرغثته": 1,
    "ارتغثها": 1,
    "الرغاث": 1,
    "الرغثاء": 1,
    "الرغوث": 1,
    "أرغد": 1,
    "استرغد": 1,
    "ارغاد": 1,
    "الراغد": 1,
    "الرغد": 1,
    "الرغيد": 1,
    "الرغيدة": 1,
    "المرغدة": 1,
    "المرغرغ": 1,
    "رغست": 1,
    "أرغس": 1,
    "رغسه": 1,
    "استرغسه": 1,
    "الرغس": 1,
    "المرغس": 1,
    "المرغوس": 1,
    "المرغوسة": 1,
    "رغش": 1,
    "الرغيغة": 1,
    "رغل": 1,
    "أرغل": 1,
    "الأرغل": 1,
    "الرغلة": 1,
    "الرغول": 1,
    "أرغمه": 1,
    "رغمه": 1,
    "ترغم": 1,
    "الراغم": 1,
    "الرغام": 1,
    "الرغامي": 1,
    "الرغامة": 1,
    "المراغم": 1,
    "المرغم": 1,
    "المرغمة": 1,
    "رغن": 1,
    "أرغن": 1,
    "الأرغون": 1,
    "أرغى": 1,
    "رغى": 1,
    "ارتغى": 1,
    "تراغوا": 1,
    "الراغية": 1,
    "الرغاء": 1,
    "الرغاوى": 1,
    "الرغاوة": 1,
    "المرغاة": 1,
    "أرفأت": 1,
    "رافأه": 1,
    "رفأه": 1,
    "الرفاء": 1,
    "الرفاءة": 1,
    "اليرفئي": 1,
    "ارفأن": 1,
    "الرفأنينة": 1,
    "ترفت": 1,
    "الرفات": 1,
    "الرفت": 1,
    "رافثه": 1,
    "ترافثوا": 1,
    "رفده": 1,
    "رافده": 1,
    "ارتفد": 1,
    "ترافدوا": 1,
    "استرفده": 1,
    "الترفيد": 1,
    "الرافدة": 1,
    "الرفادة": 1,
    "الرفدة": 1,
    "الرفود": 1,
    "الرفيدة": 1,
    "المرفد": 1,
    "الرفرف": 1,
    "الرفوس": 1,
    "المرفس": 1,
    "أرفش": 1,
    "لرفش": 1,
    "الرفاش": 1,
    "المرفشة": 1,
    "ارتفص": 1,
    "ترافصوا": 1,
    "الرفصة": 1,
    "الرفيص": 1,
    "استرفض": 1,
    "الرافضة": 1,
    "الرافضي": 1,
    "الرفاض": 1,
    "الرفضة": 1,
    "الرفيض": 1,
    "المرفض": 1,
    "رافعه": 1,
    "ترافعا": 1,
    "استرفع": 1,
    "الرفاع": 1,
    "الرفاعة": 1,
    "المرفاع": 1,
    "رفغ": 1,
    "أرفغ": 1,
    "ترفغ": 1,
    "الأرفغ": 1,
    "الرفاغة": 1,
    "الرفاغية": 1,
    "الرفغ": 1,
    "الرفغنية": 1,
    "المرافغ": 1,
    "أرفت": 1,
    "ارتف": 1,
    "الرفاف": 1,
    "الرفافة": 1,
    "الر ف": 1,
    "الرفة": 1,
    "الرفيف": 1,
    "أرفقه": 1,
    "رافقه": 1,
    "ارتفق": 1,
    "ترافقا": 1,
    "استرفقة": 1,
    "الأرفق": 1,
    "الرافق": 1,
    "الرافقة": 1,
    "الرفيقة": 1,
    "المرتفق": 1,
    "المرفقة": 1,
    "أرفل": 1,
    "الترفيل": 1,
    "الرفال": 1,
    "الرفل": 1,
    "المرفال": 1,
    "المرفلة": 1,
    "أرفه": 1,
    "رفهه": 1,
    "استرفه": 1,
    "الرفاهة": 1,
    "الرفهة": 1,
    "الرفهنية": 1,
    "رافاه": 1,
    "رفى": 1,
    "ترافوا": 1,
    "أرقأه": 1,
    "الرقوء": 1,
    "رقبه": 1,
    "أرقبه": 1,
    "راقبه": 1,
    "ارتقب": 1,
    "ترقبه": 1,
    "الرقبى": 1,
    "الرقوب": 1,
    "المرقب": 1,
    "المرقبة": 1,
    "رقح": 1,
    "ارتقح": 1,
    "ترقح": 1,
    "الرقاحي": 1,
    "أرقده": 1,
    "رقده": 1,
    "تراقد": 1,
    "ارقد": 1,
    "استرقد": 1,
    "الراقود": 1,
    "الرقدة": 1,
    "الرقود": 1,
    "المرقد": 1,
    "ترقرق": 1,
    "الرقارق": 1,
    "الرقراق": 1,
    "رقشه": 1,
    "ارتقشوا": 1,
    "ترقش": 1,
    "الرقش": 1,
    "الرقاش": 1,
    "الرقشاء": 1,
    "الرقشة": 1,
    "أرقص": 1,
    "رقصه": 1,
    "ترقص": 1,
    "الرقاص": 1,
    "الرقاصة": 1,
    "المرقص": 1,
    "المرقصة": 1,
    "رقطه": 1,
    "ترقط": 1,
    "ارقاط": 1,
    "الأرقط": 1,
    "الرقط": 1,
    "الرقطاء": 1,
    "أرقع": 1,
    "رقعه": 1,
    "ارتقع": 1,
    "ترقع": 1,
    "استرقع": 1,
    "الأرقع": 1,
    "الرقاعة": 1,
    "الرقاعي": 1,
    "الرقعاء": 1,
    "الرقيع": 1,
    "المرقعان": 1,
    "المرقع": 1,
    "المرقعة": 1,
    "رقه": 1,
    "ترقق": 1,
    "الرقق": 1,
    "المرقاق": 1,
    "المرقق": 1,
    "المسترق": 1,
    "أرقل": 1,
    "الراقول": 1,
    "الرقلة": 1,
    "المرقال": 1,
    "الأرقم": 1,
    "الرقمة": 1,
    "الرقيم": 1,
    "المرقم": 1,
    "رقنت": 1,
    "أرقن": 1,
    "ارتقن": 1,
    "ترقن": 1,
    "الإرقان": 1,
    "الراقن": 1,
    "الرقان": 1,
    "الرقون": 1,
    "الرقين": 1,
    "رقا": 1,
    "رقاه": 1,
    "ارتقى": 1,
    "تراقى": 1,
    "استرقى": 1,
    "الراقي": 1,
    "الرقاء": 1,
    "الرقو": 1,
    "الرقوة": 1,
    "الرقية": 1,
    "المرقى": 1,
    "المرقاة": 1,
    "ركبه": 1,
    "أركب": 1,
    "تركب": 1,
    "استركبه": 1,
    "التراكب": 1,
    "الراكبة": 1,
    "الراكوب": 1,
    "الراكوبة": 1,
    "الركيب": 1,
    "الركوبة": 1,
    "المركوب": 1,
    "ركح": 1,
    "أركح": 1,
    "ارتكح": 1,
    "تركح": 1,
    "الركح": 1,
    "الركحاء": 1,
    "الركحة": 1,
    "المركاح": 1,
    "أركده": 1,
    "تراكد": 1,
    "الراكدة": 1,
    "ركرك": 1,
    "تركرك": 1,
    "أركز": 1,
    "ركزه": 1,
    "ارتكز": 1,
    "الركاز": 1,
    "الركز": 1,
    "الركزة": 1,
    "المرتكزة": 1,
    "ركسه": 1,
    "أركست": 1,
    "ارتكس": 1,
    "تراكس": 1,
    "الركاس": 1,
    "الركاسة": 1,
    "الركس": 1,
    "الركوسية": 1,
    "الركيس": 1,
    "المركوس": 1,
    "أركضت": 1,
    "راكضه": 1,
    "ارتكض": 1,
    "تراكضوا": 1,
    "الركوض": 1,
    "المركض": 1,
    "المركضة": 1,
    "أركعه": 1,
    "تركع": 1,
    "الركعة": 1,
    "ارتكف": 1,
    "الركفة": 1,
    "رككت": 1,
    "ارتك": 1,
    "استركه": 1,
    "الأرك": 1,
    "الركاك": 1,
    "الركاكة": 1,
    "الرك": 1,
    "الركى": 1,
    "الركيكة": 1,
    "المرتك": 1,
    "ركله": 1,
    "راكله": 1,
    "تراكلوا": 1,
    "تركل": 1,
    "المركل": 1,
    "ركمه": 1,
    "ارتكم": 1,
    "الركم": 1,
    "الركمة": 1,
    "مرتكم": 1,
    "المركن": 1,
    "ركا": 1,
    "أركى": 1,
    "ارتكى": 1,
    "الركوة": 1,
    "الركية": 1,
    "المركو": 1,
    "رمأ": 1,
    "أرمأ": 1,
    "مرمآت": 1,
    "رمثه": 1,
    "أرمث": 1,
    "استرمث": 1,
    "الرمث": 1,
    "الرمثة": 1,
    "المرمثة": 1,
    "رمج": 1,
    "الرامج": 1,
    "الرماج": 1,
    "رامحه": 1,
    "ترامحوا": 1,
    "الرماحة": 1,
    "الرموح": 1,
    "الرميح": 1,
    "أرمخ": 1,
    "الرمخ": 1,
    "أرمد": 1,
    "الأرمد": 1,
    "الرامد": 1,
    "الرمادة": 1,
    "الرمدي": 1,
    "رمرم": 1,
    "ترمرم": 1,
    "الرمرام": 1,
    "ترامزوا": 1,
    "ارتمز": 1,
    "الرامزة": 1,
    "الراموز": 1,
    "الرمازة": 1,
    "أرمس": 1,
    "الراموس": 1,
    "الرامس": 1,
    "الرامسة": 1,
    "الرمس": 1,
    "المرمس": 1,
    "المرموسة": 1,
    "رمشت": 1,
    "أرمش": 1,
    "المرماش": 1,
    "المرمش": 1,
    "رمصت": 1,
    "أرمصه": 1,
    "الرمص": 1,
    "الرميصاء": 1,
    "أرمضه": 1,
    "رمضه": 1,
    "ارتمض": 1,
    "ترمضت": 1,
    "الرمض": 1,
    "الرمضي": 1,
    "المرمض": 1,
    "رمطه": 1,
    "رمع": 1,
    "أرمع": 1,
    "رمعت": 1,
    "ترمع": 1,
    "الرماع": 1,
    "الرمعة": 1,
    "الرماعة": 1,
    "اليرمع": 1,
    "ارمعل": 1,
    "رمغه": 1,
    "رمغ": 1,
    "رامقه": 1,
    "ارمق": 1,
    "ترمق": 1,
    "الأرماق": 1,
    "الرماق": 1,
    "الرمقة": 1,
    "المرامق": 1,
    "المرمق": 1,
    "أرمكه": 1,
    "ارمك": 1,
    "استرمك": 1,
    "الرامك": 1,
    "الرمكة": 1,
    "ارتمل": 1,
    "أم رمال": 1,
    "الرملاء": 1,
    "المرمل": 1,
    "المرملة": 1,
    "رممه": 1,
    "ارتمت": 1,
    "ترمم": 1,
    "استرم": 1,
    "الأرمام": 1,
    "الرمام": 1,
    "الرمامة": 1,
    "الرم": 1,
    "الرماء": 1,
    "الرمة": 1,
    "الرميم": 1,
    "المرمة": 1,
    "الرمان": 1,
    "المرمنة": 1,
    "رمه": 1,
    "أرمى": 1,
    "راماه": 1,
    "الرميا": 1,
    "المرتمي": 1,
    "المر ماة": 1,
    "المؤرنب": 1,
    "المؤرنبة": 1,
    "الرنح": 1,
    "المرنحة": 1,
    "رنخ": 1,
    "رنخه": 1,
    "ترنخ": 1,
    "الرناز": 1,
    "المرتعة": 1,
    "أرن فت": 1,
    "الرانف": 1,
    "الرانفة": 1,
    "أرنق": 1,
    "ترنق": 1,
    "الرنق": 1,
    "الرنقاء": 1,
    "الرنقة": 1,
    "الرونق": 1,
    "الرنك": 1,
    "ترنم": 1,
    "الرنم": 1,
    "الرنمة": 1,
    "الرنيم": 1,
    "رننت": 1,
    "الرنن": 1,
    "الرنى": 1,
    "المرنان": 1,
    "أرناه": 1,
    "راناه": 1,
    "ترنى": 1,
    "الرنا": 1,
    "الرناء": 1,
    "الرنو": 1,
    "رهبه": 1,
    "رهب": 1,
    "ترهب": 1,
    "استرهبه": 1,
    "الرهاب": 1,
    "الرهابة": 1,
    "الرهبنة": 1,
    "الرهبوت": 1,
    "ترهبل": 1,
    "الرهبل": 1,
    "الرهبلة": 1,
    "أرهجت": 1,
    "الرهج": 1,
    "المرهج": 1,
    "رهده": 1,
    "رهد": 1,
    "المرهود": 1,
    "الرهدل": 1,
    "رهدن": 1,
    "الرهدن": 1,
    "رهره": 1,
    "ترهره": 1,
    "الرهراه": 1,
    "الرهره": 1,
    "ارتهز": 1,
    "رهس": 1,
    "ارتهس": 1,
    "ترهس": 1,
    "رهشت": 1,
    "ارتهش": 1,
    "الراهشان": 1,
    "الرواهش": 1,
    "الرهيش": 1,
    "رهص": 1,
    "رهصت": 1,
    "أرهص": 1,
    "راهصه": 1,
    "الإرهاص": 1,
    "الرهص": 1,
    "الرهصة": 1,
    "الرهيص": 1,
    "الرواهص": 1,
    "المتراهصة": 1,
    "المرهصة": 1,
    "ارتهطوا": 1,
    "الرهاط": 1,
    "الرهط": 1,
    "رهفه": 1,
    "رهف": 1,
    "أرهفه": 1,
    "المرهف": 1,
    "المرهوف": 1,
    "رهق": 1,
    "راهق": 1,
    "الرهاق": 1,
    "الريهقان": 1,
    "المرهق": 1,
    "رهك": 1,
    "رهوك": 1,
    "ارتهك": 1,
    "ترهوك": 1,
    "الرهكة": 1,
    "الرهوك": 1,
    "رهل": 1,
    "رهله": 1,
    "الرهل": 1,
    "رهمت": 1,
    "أرهمت": 1,
    "الرهمان": 1,
    "الرهمة": 1,
    "الرهوم": 1,
    "رهمس": 1,
    "رهن": 1,
    "أرهن": 1,
    "راهنه": 1,
    "ارتهنه": 1,
    "تراهن": 1,
    "استرهنه": 1,
    "الراهنة": 1,
    "الرهين": 1,
    "رها": 1,
    "أرهى": 1,
    "راهاه": 1,
    "ارتهى": 1,
    "تراهبا": 1,
    "أرهاء": 1,
    "الراهي": 1,
    "الراهية": 1,
    "الرهاء": 1,
    "الرهو": 1,
    "الرهوة": 1,
    "المرهاة": 1,
    "رهيأ": 1,
    "ترهيأ": 1,
    "روأ": 1,
    "تروأ": 1,
    "الرويئة": 1,
    "أراب": 1,
    "الأروب": 1,
    "الروبان": 1,
    "الروبة": 1,
    "المروب": 1,
    "الروثة": 1,
    "راجت": 1,
    "الروجة": 1,
    "أراح": 1,
    "أروح": 1,
    "راوح": 1,
    "ارتوح": 1,
    "تراوح": 1,
    "تروح": 1,
    "الاسترواح": 1,
    "الأريح": 1,
    "الأريحية": 1,
    "الراح": 1,
    "الرواح": 1,
    "الرواحة": 1,
    "الروحة": 1,
    "الريحي": 1,
    "المراح": 1,
    "المرتاح": 1,
    "المرواح": 1,
    "المروح": 1,
    "المرياح": 1,
    "المستراح": 1,
    "رادت": 1,
    "أرود": 1,
    "ارتاد": 1,
    "استرادت": 1,
    "الأرود": 1,
    "الرود": 1,
    "الرويد": 1,
    "المرود": 1,
    "روذن": 1,
    "رازه": 1,
    "راوزه": 1,
    "الراز": 1,
    "الروز": 1,
    "الروزنامة": 1,
    "الريازة": 1,
    "روش": 1,
    "الرؤوش": 1,
    "راضه": 1,
    "أراض": 1,
    "أروض": 1,
    "راوضه": 1,
    "ارتاض": 1,
    "تراوضا": 1,
    "استراض": 1,
    "استروض": 1,
    "الروضة": 1,
    "الريض": 1,
    "الريضة": 1,
    "أراعه": 1,
    "تروع": 1,
    "الأروع": 1,
    "الرواع": 1,
    "المروع": 1,
    "أراغه": 1,
    "راوغه": 1,
    "ارتاغه": 1,
    "تراوغا": 1,
    "تروغت": 1,
    "الأروغ": 1,
    "الرائغ": 1,
    "الرائغة": 1,
    "الرواغة": 1,
    "الرواغ": 1,
    "الرياغ": 1,
    "راوقه": 1,
    "تروق": 1,
    "الأروق": 1,
    "الراووق": 1,
    "الرواقيون": 1,
    "الروق": 1,
    "الروقة": 1,
    "ترول": 1,
    "الرائل": 1,
    "الراوول": 1,
    "الروال": 1,
    "المرول": 1,
    "رامه": 1,
    "تروم": 1,
    "الرومة": 1,
    "المرام": 1,
    "الأرونان": 1,
    "الرواني": 1,
    "الرون": 1,
    "الرونة": 1,
    "المرون": 1,
    "أرواه": 1,
    "ارتوى": 1,
    "تروى": 1,
    "الأروى": 1,
    "التروية": 1,
    "الراوية": 1,
    "الروى": 1,
    "الروي": 1,
    "الروية": 1,
    "الريا": 1,
    "الريان": 1,
    "الرية": 1,
    "المروى": 1,
    "المروي": 1,
    "رابه": 1,
    "ارتاب": 1,
    "استراب": 1,
    "الريب": 1,
    "الرياب": 1,
    "المسترابة": 1,
    "الريباس": 1,
    "أراثه": 1,
    "استراثه": 1,
    "الريث": 1,
    "المريث": 1,
    "الريجي": 1,
    "ريخه": 1,
    "الريد": 1,
    "الريدانة": 1,
    "الريدة": 1,
    "رار": 1,
    "أرار": 1,
    "الرائرة": 1,
    "الرير": 1,
    "ارتاش": 1,
    "تريش": 1,
    "الأرتش": 1,
    "الرائش": 1,
    "الرياش": 1,
    "المريش": 1,
    "الرائطة": 1,
    "الريطة": 1,
    "أراع": 1,
    "تريع": 1,
    "استراع": 1,
    "ريغ": 1,
    "تريغ": 1,
    "الريغ": 1,
    "راف": 1,
    "أريف": 1,
    "أرافت": 1,
    "رايف": 1,
    "تريف": 1,
    "الريفة": 1,
    "أراق": 1,
    "تريق": 1,
    "الرائق": 1,
    "الريقة": 1,
    "الريال": 1,
    "الريالة": 1,
    "ريمت": 1,
    "الريم": 1,
    "أران": 1,
    "الران": 1,
    "الرين": 1,
    "الرينة": 1,
    "راه": 1,
    "ريهت": 1,
    "تريه": 1,
    "الزاي": 1,
    "زأب": 1,
    "زأبر": 1,
    "الزئبر": 1,
    "زأبق": 1,
    "زئر": 1,
    "أزأر": 1,
    "تزأر": 1,
    "الزئر": 1,
    "الزأرة": 1,
    "زأزأ": 1,
    "تزأزأ": 1,
    "زأفه": 1,
    "أزأف": 1,
    "الزؤاف": 1,
    "زأم": 1,
    "زئم": 1,
    "أزأمه": 1,
    "الزؤام": 1,
    "الزئم": 1,
    "الزأمة": 1,
    "المزآم": 1,
    "الزؤان": 1,
    "زأى": 1,
    "أزآه": 1,
    "ازبأر": 1,
    "أزبت": 1,
    "زبب": 1,
    "تزبب": 1,
    "الأزب": 1,
    "الزبب": 1,
    "الزبيبة": 1,
    "الزبيبي": 1,
    "زبده": 1,
    "أزبد": 1,
    "الزبدية": 1,
    "زبره": 1,
    "أزبر": 1,
    "الزبرة": 1,
    "المزبر": 1,
    "زبرجه": 1,
    "الزبرج": 1,
    "زبرق": 1,
    "الزبرقان": 1,
    "زبطت": 1,
    "الزباطة": 1,
    "تزبع": 1,
    "الزبعرى": 1,
    "انزبق": 1,
    "الزابوقة": 1,
    "الزبل": 1,
    "الزبيل": 1,
    "الزنبيل": 1,
    "زينه": 1,
    "زابنه": 1,
    "تزابنوا": 1,
    "تزبنه": 1,
    "استزبنه": 1,
    "الزبونة": 1,
    "الزبن": 1,
    "زباه": 1,
    "أزباه": 1,
    "تزابى": 1,
    "تزبى": 1,
    "الزبية": 1,
    "تزتت": 1,
    "الزتة": 1,
    "ازدج": 1,
    "الزج": 1,
    "المزجة": 1,
    "انزجر": 1,
    "تزاجروا": 1,
    "الزجرة": 1,
    "المزجر": 1,
    "المزجرة": 1,
    "زجله": 1,
    "الزجال": 1,
    "الزجل": 1,
    "الزجلة": 1,
    "المزجال": 1,
    "المزجل": 1,
    "زجم": 1,
    "الزجم": 1,
    "الزجمة": 1,
    "أزجى": 1,
    "زجى": 1,
    "تزجى": 1,
    "المزجاء": 1,
    "المزجي": 1,
    "زحه": 1,
    "زاحره": 1,
    "تزحر": 1,
    "الزحر": 1,
    "الزحرة": 1,
    "الزحير": 1,
    "زحزحه": 1,
    "الزحزاح": 1,
    "الزحزح": 1,
    "أزحف": 1,
    "زاحفه": 1,
    "تزاحفوا": 1,
    "تزحف": 1,
    "الزحاف": 1,
    "الزحفة": 1,
    "الزحوف": 1,
    "المزحف": 1,
    "زحوله": 1,
    "أزحله": 1,
    "زحله": 1,
    "تزحل": 1,
    "تزحول": 1,
    "الزحل": 1,
    "المزحل": 1,
    "زحلف": 1,
    "تزحلف": 1,
    "الزحلوفة": 1,
    "زحلقه": 1,
    "الزحلوقة": 1,
    "المزحلق": 1,
    "زحمه": 1,
    "زاحمه": 1,
    "تزاحموا": 1,
    "الزحم": 1,
    "المزحم": 1,
    "زحن": 1,
    "تزحن": 1,
    "الزحن": 1,
    "الزحنة": 1,
    "زخ": 1,
    "الزخة": 1,
    "زاخره": 1,
    "تزخر": 1,
    "الزاخر": 1,
    "الزخاري": 1,
    "زخرفه": 1,
    "تزخرف": 1,
    "الزخارف": 1,
    "الزخرف": 1,
    "زخف": 1,
    "تزخف": 1,
    "زخمه": 1,
    "أزخم": 1,
    "الزخمة": 1,
    "انزرب": 1,
    "الزرب": 1,
    "الزربية": 1,
    "الزرياب": 1,
    "المزراب": 1,
    "الزرجون": 1,
    "زرده": 1,
    "الزرادة": 1,
    "الزراد": 1,
    "المزرد": 1,
    "تزرر": 1,
    "الزرة": 1,
    "الزرير": 1,
    "المزر": 1,
    "تزرزر": 1,
    "الزرازر": 1,
    "الزرزار": 1,
    "أزرع": 1,
    "زارعه": 1,
    "الاستزراع": 1,
    "الزرعة": 1,
    "الزريعة": 1,
    "المزارعة": 1,
    "زرف": 1,
    "أزرف": 1,
    "انزرف": 1,
    "الزراف": 1,
    "أزرقت": 1,
    "انزرق": 1,
    "ازراق": 1,
    "تزورق": 1,
    "الأزارقة": 1,
    "الأزرقي": 1,
    "الزراق": 1,
    "الزراقة": 1,
    "المزراق": 1,
    "زرمه": 1,
    "زرم": 1,
    "الأزرم": 1,
    "الزريم": 1,
    "الزرنب": 1,
    "أزرى": 1,
    "زاراه": 1,
    "تزرى": 1,
    "ازدراه": 1,
    "استزراه": 1,
    "الزارية": 1,
    "المزراء": 1,
    "زوزكت": 1,
    "الزوزك": 1,
    "زعب": 1,
    "تزعب": 1,
    "الزاعب": 1,
    "الزاعبية": 1,
    "الزعب": 1,
    "الزعبة": 1,
    "الزعبل": 1,
    "زعجه": 1,
    "أزعجه": 1,
    "انزعج": 1,
    "المزعاج": 1,
    "ازعار": 1,
    "الأزعر": 1,
    "الزعر": 1,
    "زعزعه": 1,
    "تزعزع": 1,
    "الزعازع": 1,
    "الزعزاع": 1,
    "الزعزاعة": 1,
    "الزعزع": 1,
    "الزاعط": 1,
    "أزعف": 1,
    "الزعاف": 1,
    "الزعوف": 1,
    "المزعف": 1,
    "زعفره": 1,
    "تزعفر": 1,
    "أزعقه": 1,
    "انزعق": 1,
    "الزعاق": 1,
    "الزعقة": 1,
    "المزعق": 1,
    "أزعله": 1,
    "تزعل": 1,
    "الزعلة": 1,
    "أزعم": 1,
    "تزاعما": 1,
    "الزعامة": 1,
    "الزعمة": 1,
    "المزعم": 1,
    "زعنفت": 1,
    "ازغاب": 1,
    "الأزغب": 1,
    "الزغابة": 1,
    "أزغدت": 1,
    "تزغدت": 1,
    "الزغيد": 1,
    "زغدب": 1,
    "الزغدب": 1,
    "الزغزغ": 1,
    "زغفت": 1,
    "ازدغف": 1,
    "الزغف": 1,
    "المزغف": 1,
    "أزغلت": 1,
    "الزغل": 1,
    "الزغلة": 1,
    "الزغلول": 1,
    "تزغم": 1,
    "الزغوم": 1,
    "الزفت": 1,
    "الزافرة": 1,
    "زفزفت": 1,
    "تزفزف": 1,
    "الزفزاف": 1,
    "الزفزافة": 1,
    "الزفزف": 1,
    "استزفه": 1,
    "الزف": 1,
    "الزفة": 1,
    "الزفوف": 1,
    "الزفيف": 1,
    "المزفة": 1,
    "الزفن": 1,
    "أزفاه": 1,
    "زقب": 1,
    "انزقب": 1,
    "الزقب": 1,
    "الزقزاقة": 1,
    "زقفه": 1,
    "تزققه": 1,
    "الزقفة": 1,
    "زقق": 1,
    "الزق": 1,
    "أزقمه": 1,
    "ازدقمه": 1,
    "تزقم": 1,
    "الزقمة": 1,
    "زقن": 1,
    "أزقنه": 1,
    "زقى": 1,
    "أزقاه": 1,
    "الزاقي": 1,
    "الزقية": 1,
    "زكأ": 1,
    "أزكا": 1,
    "الزكاء": 1,
    "المزكأ": 1,
    "زكبت": 1,
    "الزكبة": 1,
    "الزكيبة": 1,
    "زكره": 1,
    "تزكر": 1,
    "الزكرة": 1,
    "زكزك": 1,
    "تزكزك": 1,
    "أزك": 1,
    "تزكك": 1,
    "الزك": 1,
    "الزكة": 1,
    "زكمت": 1,
    "أزكمه": 1,
    "الزكمة": 1,
    "زكن": 1,
    "أزكن": 1,
    "زاكنه": 1,
    "الزكانة": 1,
    "الزكن": 1,
    "الزكا": 1,
    "زلب": 1,
    "الزلابية": 1,
    "الزلبة": 1,
    "أزلجه": 1,
    "زلجه": 1,
    "انزلج": 1,
    "الزالج": 1,
    "الزلوج": 1,
    "المزلج": 1,
    "زلحه": 1,
    "تزلحه": 1,
    "الزلح": 1,
    "زلحقه": 1,
    "تزلحف": 1,
    "ازلحف": 1,
    "زلخ": 1,
    "زلخه": 1,
    "الزلخة": 1,
    "زلز": 1,
    "الزلز": 1,
    "الزلزة": 1,
    "الزلزاء": 1,
    "زلزله": 1,
    "تزلزل": 1,
    "الزلزل": 1,
    "زلعت": 1,
    "أزلعه": 1,
    "تزلعت": 1,
    "ازدلعه": 1,
    "الزلعة": 1,
    "الزيلع": 1,
    "المزلع": 1,
    "أزلفه": 1,
    "الزلف": 1,
    "الزلفة": 1,
    "الزلفى": 1,
    "المزدلفة": 1,
    "المزلف": 1,
    "المزلفة": 1,
    "زلقت": 1,
    "أزلقت": 1,
    "تزلق": 1,
    "الزلقة": 1,
    "الزليق": 1,
    "الزيلق": 1,
    "المزلقان": 1,
    "زلقمه": 1,
    "الزلقم": 1,
    "الزلقمة": 1,
    "الزلقوم": 1,
    "أزله": 1,
    "استزله": 1,
    "الزلالي": 1,
    "الزل": 1,
    "الزلاء": 1,
    "الزلية": 1,
    "الزلول": 1,
    "الزليل": 1,
    "المزلة": 1,
    "زلمه": 1,
    "ازدلم": 1,
    "الأزلم": 1,
    "الزلم": 1,
    "الزلماء": 1,
    "الزلمة": 1,
    "المزلم": 1,
    "زمته": 1,
    "تزمت": 1,
    "الزمت": 1,
    "الزميت": 1,
    "زمج": 1,
    "ازمأج": 1,
    "الزماج": 1,
    "الزمج": 1,
    "تزمجر": 1,
    "زمخ": 1,
    "تزمخ": 1,
    "الزامخ": 1,
    "الزمخ": 1,
    "الزموخ": 1,
    "تزمخر": 1,
    "ازمخر": 1,
    "الزماخر والزماخري": 1,
    "الزمخر": 1,
    "استزمر": 1,
    "الزامر": 1,
    "الزمارة": 1,
    "الزمير": 1,
    "الزمور": 1,
    "الزمرد": 1,
    "تزمزم": 1,
    "الزمازم": 1,
    "الزمزام": 1,
    "الزمزم": 1,
    "الزمزمة": 1,
    "الزمزمية": 1,
    "الزمزوم": 1,
    "أزمع": 1,
    "الزماع": 1,
    "الزمع": 1,
    "الزمعة": 1,
    "الزمعي": 1,
    "الزموع": 1,
    "الزميع": 1,
    "زمكه": 1,
    "زمك": 1,
    "الزمكة": 1,
    "الزمك": 1,
    "الزمكي": 1,
    "زامله": 1,
    "زمله": 1,
    "تزاملوا": 1,
    "تزمل": 1,
    "ازدمل": 1,
    "الأزمل": 1,
    "الأزملة": 1,
    "الزامل": 1,
    "الزاملة": 1,
    "الزمال": 1,
    "الزمل": 1,
    "الزملة": 1,
    "المزملة": 1,
    "زممه": 1,
    "الإزميم": 1,
    "الزمم": 1,
    "أزمن": 1,
    "زامنه": 1,
    "الزمانة": 1,
    "الزمين": 1,
    "المتزامن": 1,
    "زمه": 1,
    "زمهرت": 1,
    "ازمهر": 1,
    "الزمهرير": 1,
    "الزماورد": 1,
    "أزنأ": 1,
    "الزناء": 1,
    "الزنيء": 1,
    "زنب": 1,
    "الزنابى": 1,
    "الزنابة": 1,
    "الزينب": 1,
    "الزنبار": 1,
    "الزنبر": 1,
    "الزنبرى": 1,
    "الزنبرية": 1,
    "الزنبورية": 1,
    "المزبرة": 1,
    "الزنبلك": 1,
    "تزنتر": 1,
    "الزنترة": 1,
    "زنجت": 1,
    "زانجه": 1,
    "تزنج": 1,
    "الزنج": 1,
    "الزنجر": 1,
    "أزند": 1,
    "تزند": 1,
    "الزندان": 1,
    "الزندة": 1,
    "الزندية": 1,
    "المزند": 1,
    "تزندق": 1,
    "الزندق": 1,
    "زنره": 1,
    "زنرت": 1,
    "تزنر": 1,
    "زنف": 1,
    "تزنف": 1,
    "الزنوف": 1,
    "زنفل": 1,
    "أزنق": 1,
    "الزناق": 1,
    "الزنق": 1,
    "الزنقة": 1,
    "زنم": 1,
    "زنمه": 1,
    "الزنمة": 1,
    "الزنيم": 1,
    "أزنه": 1,
    "الزنن": 1,
    "الزنة": 1,
    "الزنين": 1,
    "زنهر": 1,
    "أزناه": 1,
    "زناه": 1,
    "الزنية": 1,
    "زه": 1,
    "أزهد": 1,
    "زهده": 1,
    "تزاهدوه": 1,
    "تزهد": 1,
    "ازدهده": 1,
    "الزهادة": 1,
    "الزهاد": 1,
    "ازهر": 1,
    "ازهار": 1,
    "الأزهر": 1,
    "الأزهران": 1,
    "الزاهر": 1,
    "الزاهرية": 1,
    "الزهر": 1,
    "الزهراء": 1,
    "الزهراوان": 1,
    "الزهار": 1,
    "زهزق": 1,
    "الزهزاه": 1,
    "زهف": 1,
    "أزهف": 1,
    "انزهف": 1,
    "تزهف": 1,
    "ازدهف": 1,
    "زهق": 1,
    "أزهق": 1,
    "زاهقه": 1,
    "انزهق": 1,
    "الزاهق": 1,
    "الزهق": 1,
    "الزهوق": 1,
    "المزهقة": 1,
    "زهكه": 1,
    "زهل": 1,
    "الزهلول": 1,
    "زهم": 1,
    "زهمت": 1,
    "أزهم": 1,
    "زاهمه": 1,
    "الزهم": 1,
    "الزهمة": 1,
    "الزهومة": 1,
    "زهي": 1,
    "أزهى": 1,
    "زهى": 1,
    "ازدهى": 1,
    "الزهاء": 1,
    "زاب": 1,
    "الميزاب": 1,
    "زوبر": 1,
    "الزوبر": 1,
    "أزوج": 1,
    "زاوجه": 1,
    "ازدوجا": 1,
    "تزاوجا": 1,
    "الزاج": 1,
    "المزاوجة": 1,
    "المزواج": 1,
    "المزدوج": 1,
    "أزاحه": 1,
    "الإزاحة الزاوية": 1,
    "أزاده": 1,
    "زوده": 1,
    "أزاره": 1,
    "تزور": 1,
    "استزاره": 1,
    "ازوار": 1,
    "الزار": 1,
    "الزارة": 1,
    "الزاورة": 1,
    "الزوراء": 1,
    "الزورة": 1,
    "الزيار": 1,
    "الزير": 1,
    "زوزك": 1,
    "زوزى": 1,
    "زاع": 1,
    "تزوع": 1,
    "الزاعة": 1,
    "الزوعة": 1,
    "أزاغه": 1,
    "الزاغ": 1,
    "المزوغ": 1,
    "تزاوف": 1,
    "التزاوف": 1,
    "الزواف": 1,
    "الزوفى": 1,
    "زوقه": 1,
    "التزويق": 1,
    "الزاووق": 1,
    "الزواق": 1,
    "الزوقة": 1,
    "المزوق": 1,
    "زاك": 1,
    "أزوك": 1,
    "تزاوك": 1,
    "أزاله": 1,
    "زاوله": 1,
    "زوله": 1,
    "تزول": 1,
    "استزاله": 1,
    "الزائلة": 1,
    "الزوائل": 1,
    "الزول": 1,
    "الزولة": 1,
    "المزولة": 1,
    "الزامة": 1,
    "الزويم": 1,
    "الزانة": 1,
    "الزوان": 1,
    "الزون": 1,
    "أزوى": 1,
    "الزو": 1,
    "زواه": 1,
    "تزوى": 1,
    "الزاوي": 1,
    "المزواة": 1,
    "تزيب": 1,
    "الأزيب": 1,
    "زات": 1,
    "أزات": 1,
    "زيته": 1,
    "استزات": 1,
    "الزيتي": 1,
    "الزيات": 1,
    "الزيج": 1,
    "زايده": 1,
    "استزاد": 1,
    "المزادة": 1,
    "الزيازية": 1,
    "الزيزاء": 1,
    "زاط": 1,
    "الزياط": 1,
    "الزيطة": 1,
    "زيغه": 1,
    "الزيق": 1,
    "زال ويزال": 1,
    "زايله": 1,
    "زيله": 1,
    "تزايلوا": 1,
    "تزيمت": 1,
    "الزيمة": 1,
    "زانه": 1,
    "أزين": 1,
    "ازين": 1,
    "الزائن": 1,
    "الزيان": 1,
    "المزين": 1,
    "الزينون": 1,
    "في الكيمياء": 1,
    "زياه": 1,
    "السين": 1,
    "سأ": 1,
    "سأبه": 1,
    "سئب": 1,
    "السأب": 1,
    "السؤبان": 1,
    "المسأب": 1,
    "سأده": 1,
    "سئد": 1,
    "أسأد": 1,
    "السؤاد": 1,
    "السؤدة": 1,
    "المسأد": 1,
    "سأر": 1,
    "أسأر": 1,
    "تسأر": 1,
    "السؤر": 1,
    "السؤرة": 1,
    "سأسأ": 1,
    "تسأسأت": 1,
    "سأفت": 1,
    "سئفت": 1,
    "سؤفت": 1,
    "انسأف": 1,
    "السؤاف": 1,
    "السأف": 1,
    "ساءله": 1,
    "تساءلوا": 1,
    "السآل": 1,
    "السؤل والسول": 1,
    "السؤلة": 1,
    "السؤول": 1,
    "أسأمه": 1,
    "السؤوم": 1,
    "سآ": 1,
    "سأى": 1,
    "السأو": 1,
    "أسبأ": 1,
    "استبأ": 1,
    "انسبأ": 1,
    "السباء": 1,
    "السبأة": 1,
    "السبئية": 1,
    "السبئ": 1,
    "السبيئة": 1,
    "المسبأ": 1,
    "سابه": 1,
    "سببه": 1,
    "استبوا": 1,
    "تسابوا": 1,
    "استسب": 1,
    "الأسبوية": 1,
    "السبة": 1,
    "السببة": 1,
    "المسب": 1,
    "المسبة": 1,
    "أسبتت": 1,
    "انسبت": 1,
    "السبتة": 1,
    "المسبوت": 1,
    "تسبج": 1,
    "السبج": 1,
    "السبجة": 1,
    "السبيجة": 1,
    "أسبحه": 1,
    "السابحات": 1,
    "السبوح": 1,
    "السبح": 1,
    "السبحة": 1,
    "السوابح": 1,
    "المسبح": 1,
    "سبحل": 1,
    "سبخت": 1,
    "أسبخت": 1,
    "تسبخ": 1,
    "السباخ": 1,
    "السبخ": 1,
    "السبخة": 1,
    "السبيخة": 1,
    "سبد": 1,
    "أسبد": 1,
    "السبد": 1,
    "سبره": 1,
    "أسبره": 1,
    "استبره": 1,
    "السابري": 1,
    "السبار": 1,
    "السبور": 1,
    "السبر": 1,
    "السبرة": 1,
    "المسبار": 1,
    "المسبرة": 1,
    "المسبور": 1,
    "سبرت": 1,
    "السبرات": 1,
    "السبروت": 1,
    "السبريت": 1,
    "سبرج": 1,
    "سبرد": 1,
    "تسبسب": 1,
    "السبسب": 1,
    "أسبط": 1,
    "سبطت": 1,
    "الساباط": 1,
    "السباطة": 1,
    "مصرية قديمة عربيتها الكباسة": 1,
    "السبط": 1,
    "السبطانة": 1,
    "اسبطر": 1,
    "السبطر": 1,
    "السبطرة": 1,
    "السبيطر": 1,
    "أسبعٌ": 1,
    "استبعوا": 1,
    "السباعي": 1,
    "السبوع": 1,
    "السبيع": 1,
    "المسبعة": 1,
    "أسبغ": 1,
    "التسبغة": 1,
    "استبقوا": 1,
    "تسابقوا": 1,
    "انسبك": 1,
    "السبيكة": 1,
    "المسبكة": 1,
    "اسبكر": 1,
    "أسبلت": 1,
    "الأسبل": 1,
    "السابل": 1,
    "السبلاء": 1,
    "السبلة": 1,
    "السبنجونة": 1,
    "السبنسة": 1,
    "السباهي": 1,
    "السبه": 1,
    "السباهية": 1,
    "السبهلل": 1,
    "السبهلى": 1,
    "تسابى": 1,
    "تسبى": 1,
    "السابياء": 1,
    "السبي": 1,
    "السبية": 1,
    "الستون": 1,
    "ستره": 1,
    "ساتره": 1,
    "استتر": 1,
    "انستر": 1,
    "الستير": 1,
    "المسترة": 1,
    "الستوق": 1,
    "المستقة": 1,
    "ستل": 1,
    "ستله": 1,
    "ساتل": 1,
    "تساتل": 1,
    "ستالة": 1,
    "الستل": 1,
    "المستل": 1,
    "ستهه": 1,
    "الإست": 1,
    "سج": 1,
    "السجاج": 1,
    "سجح": 1,
    "أسجح": 1,
    "سجاح": 1,
    "السجاح": 1,
    "السجح": 1,
    "السجحة": 1,
    "السجيح": 1,
    "السجيحة": 1,
    "سجدت": 1,
    "أسجد": 1,
    "الساجد": 1,
    "الساجدة": 1,
    "المسجدة": 1,
    "ساجره": 1,
    "انسجر": 1,
    "الأسجر": 1,
    "الساجر": 1,
    "الساجور": 1,
    "السجر": 1,
    "السجور": 1,
    "السجير": 1,
    "المسجرة": 1,
    "الساجسي": 1,
    "السجس": 1,
    "السجيس": 1,
    "السجسج": 1,
    "سجعت": 1,
    "الأسجوعة": 1,
    "الساجع": 1,
    "السجعة": 1,
    "المسجع": 1,
    "سجفت": 1,
    "أسجف": 1,
    "السجاف": 1,
    "السجافة": 1,
    "السجفة": 1,
    "أسجل": 1,
    "انسجل": 1,
    "تساجلوا": 1,
    "الساجول": 1,
    "السجيلة": 1,
    "أسجمت": 1,
    "السجم": 1,
    "السجوم": 1,
    "المسجام": 1,
    "سجنه": 1,
    "الساجون": 1,
    "السجنجل": 1,
    "أسجى": 1,
    "ساجاه": 1,
    "تسجى": 1,
    "الساجي": 1,
    "السجواء": 1,
    "السجية": 1,
    "أسحبه": 1,
    "تسحب": 1,
    "سحبان": 1,
    "السحبان": 1,
    "السحبة": 1,
    "المسحب": 1,
    "أسحت": 1,
    "الأسحت": 1,
    "السحتوت": 1,
    "السحتيت": 1,
    "السحيت": 1,
    "السحيتة": 1,
    "المسحوت": 1,
    "سحجه": 1,
    "انسحج": 1,
    "تسحج": 1,
    "السحوج": 1,
    "المسحاج": 1,
    "المسحج": 1,
    "سحح": 1,
    "انسح": 1,
    "تسحح": 1,
    "السحاء": 1,
    "السحاحة": 1,
    "تسحر": 1,
    "السحارة": 1,
    "السحور": 1,
    "السوحر": 1,
    "المساحر": 1,
    "المسحورة": 1,
    "تسحسح": 1,
    "السحساح": 1,
    "السحساحة": 1,
    "السحسحة": 1,
    "سحطه": 1,
    "انسحط": 1,
    "المسحط": 1,
    "سحف": 1,
    "أسحفت": 1,
    "السحاف": 1,
    "السحفة": 1,
    "السحوف": 1,
    "السحيفة": 1,
    "المسحف": 1,
    "المسحفة": 1,
    "سحقه": 1,
    "أسحق": 1,
    "انسحق": 1,
    "السحوق": 1,
    "سحلت": 1,
    "أسحل": 1,
    "ساحلوا": 1,
    "انسحل": 1,
    "الإسحل": 1,
    "السحال": 1,
    "السحالة": 1,
    "السحل": 1,
    "السحيل": 1,
    "المسحلة": 1,
    "المسحل": 1,
    "المسحول": 1,
    "أسحمت": 1,
    "الإسحمان": 1,
    "السحم": 1,
    "السحمة": 1,
    "ساحنه": 1,
    "السحناء": 1,
    "السحن": 1,
    "السحنة": 1,
    "المسحن": 1,
    "المسحنة": 1,
    "سحا": 1,
    "سحى": 1,
    "أسحى": 1,
    "سحاه": 1,
    "انسحى": 1,
    "الأسحوان": 1,
    "الأسحية": 1,
    "الساحية": 1,
    "السحا": 1,
    "السحاية": 1,
    "المسحاة": 1,
    "السخاب": 1,
    "السخبر": 1,
    "السخت": 1,
    "السختيت": 1,
    "السختيان": 1,
    "المسخوت": 1,
    "سخت": 1,
    "السخاخ": 1,
    "سخد": 1,
    "السخد": 1,
    "السخود": 1,
    "المسخد": 1,
    "سخرت": 1,
    "سخره": 1,
    "تسخره": 1,
    "استسخر": 1,
    "المسخرة": 1,
    "السخرية والسخرية": 1,
    "سخطه": 1,
    "أسخطه": 1,
    "تسخطه": 1,
    "سخفه": 1,
    "السخفة": 1,
    "سخل": 1,
    "أسخل": 1,
    "سخلت": 1,
    "السخالة": 1,
    "السخل": 1,
    "السخلة": 1,
    "تسخم": 1,
    "الأسخم": 1,
    "السخامي": 1,
    "السخم": 1,
    "السخمة": 1,
    "السخيم": 1,
    "السخيمة": 1,
    "المسخم": 1,
    "أسخنه": 1,
    "سخنه": 1,
    "التساخين": 1,
    "السخين": 1,
    "السخن": 1,
    "السخنان": 1,
    "السخنة": 1,
    "السخون": 1,
    "السخينة": 1,
    "المسخنة": 1,
    "أسخى": 1,
    "سخى": 1,
    "تسخى": 1,
    "السخاوية": 1,
    "السخواء": 1,
    "سدحت": 1,
    "السادح": 1,
    "السادحة": 1,
    "استد": 1,
    "انسد": 1,
    "تسدد": 1,
    "الانسداد التاجي": 1,
    "الساد": 1,
    "السدد": 1,
    "المسد": 1,
    "أسدره": 1,
    "انسدر": 1,
    "تسدر": 1,
    "الأسدران": 1,
    "السدار": 1,
    "السدر": 1,
    "السدير": 1,
    "السيدارة": 1,
    "أسدس": 1,
    "السداس": 1,
    "السدوس": 1,
    "السديس": 1,
    "سدع": 1,
    "المسدع": 1,
    "أسدف": 1,
    "الأسدف": 1,
    "السدافة": 1,
    "السدف": 1,
    "السدفة": 1,
    "السديف": 1,
    "سدك": 1,
    "السدك": 1,
    "أسدله": 1,
    "سدله": 1,
    "انسدل": 1,
    "السدل": 1,
    "السديل": 1,
    "انسدم": 1,
    "سدن": 1,
    "السادن": 1,
    "سدي": 1,
    "أسدى": 1,
    "استدى": 1,
    "تسدى": 1,
    "السدى": 1,
    "السذاب": 1,
    "سرأت": 1,
    "أسرأت": 1,
    "الس رء": 1,
    "أسرب": 1,
    "انسرب": 1,
    "السربة": 1,
    "المسربة": 1,
    "سربله": 1,
    "تسربل": 1,
    "السربال": 1,
    "أسرج": 1,
    "استسرج": 1,
    "السارج": 1,
    "السراجة": 1,
    "سريج": 1,
    "المسرجة": 1,
    "سرجن": 1,
    "السرجين": 1,
    "تسرح": 1,
    "السارح": 1,
    "السارحة": 1,
    "السرح": 1,
    "السرحان": 1,
    "السروح": 1,
    "السريح": 1,
    "السريحة": 1,
    "أسرد": 1,
    "سرده": 1,
    "تسرد": 1,
    "السارد": 1,
    "السراد": 1,
    "السردار": 1,
    "سردق": 1,
    "سرره": 1,
    "استسر": 1,
    "تساروا": 1,
    "الأسارير": 1,
    "السرار": 1,
    "السرارة": 1,
    "السرسوب": 1,
    "السرسور": 1,
    "السرسام": 1,
    "سرطه": 1,
    "انسرط": 1,
    "تسرطه": 1,
    "السراط": 1,
    "السرط": 1,
    "السريط": 1,
    "المسرط": 1,
    "سارع": 1,
    "تسارع": 1,
    "الأسروع": 1,
    "السراع": 1,
    "السرعان": 1,
    "السرعرع": 1,
    "سرفت": 1,
    "السرف": 1,
    "السرفة": 1,
    "السروف": 1,
    "السريف": 1,
    "انسرق": 1,
    "تسرق": 1,
    "السارقة": 1,
    "السراقة": 1,
    "السرق": 1,
    "سرقن": 1,
    "السرقين": 1,
    "تسارك": 1,
    "السركي": 1,
    "السركودية": 1,
    "السركومة": 1,
    "السركومة اللمفية": 1,
    "سرمه": 1,
    "تسرم": 1,
    "السرم": 1,
    "السرمد": 1,
    "السرمدي": 1,
    "السراة": 1,
    "سروله": 1,
    "تسرول": 1,
    "المسرول": 1,
    "استرى": 1,
    "سريوم": 1,
    "سزيوم": 1,
    "المسطبة": 1,
    "سطحه": 1,
    "انسطح": 1,
    "تسطح": 1,
    "السطاح": 1,
    "السطيح": 1,
    "السطيحة": 1,
    "المسطح": 1,
    "أسطر": 1,
    "استطر": 1,
    "الساطر": 1,
    "السطرة": 1,
    "السطار": 1,
    "المسطار": 1,
    "المسطرة": 1,
    "المسطر": 1,
    "المسطرين": 1,
    "السطاع": 1,
    "السطيع": 1,
    "المسطع": 1,
    "الساطل": 1,
    "سطم": 1,
    "الإسطام": 1,
    "الأسطم": 1,
    "الأسطمة": 1,
    "السطام": 1,
    "السطم": 1,
    "سطنه": 1,
    "أسطى": 1,
    "ساطاه": 1,
    "السواطي": 1,
    "سعب": 1,
    "انسعب": 1,
    "تسعب": 1,
    "السعب": 1,
    "السعابيب": 1,
    "أسعده": 1,
    "استسعد": 1,
    "الساعدة": 1,
    "السعدان": 1,
    "السعدانة": 1,
    "سعديك": 1,
    "السعود": 1,
    "أسعر": 1,
    "استعرت": 1,
    "تسعرت": 1,
    "الأسعر": 1,
    "التسعير الجبري": 1,
    "الساعور": 1,
    "الساعورة": 1,
    "السعار": 1,
    "المسعار": 1,
    "المسعر": 1,
    "المسعور": 1,
    "سعسع": 1,
    "تسعسع": 1,
    "سعطه": 1,
    "أسعطه": 1,
    "استعط": 1,
    "السعاط": 1,
    "السعوط": 1,
    "المسعط": 1,
    "سعفت": 1,
    "أسعف": 1,
    "ساعفه": 1,
    "تسعفت": 1,
    "السعاف": 1,
    "السعوف": 1,
    "المساعف": 1,
    "أسعله": 1,
    "استسعلت": 1,
    "الساعل": 1,
    "السعلى": 1,
    "السعلاة": 1,
    "أسعن": 1,
    "تسعن": 1,
    "السعن": 1,
    "السعنة": 1,
    "السعو": 1,
    "السعوة": 1,
    "أسعاه": 1,
    "ساعاه": 1,
    "تساعوا": 1,
    "استسعاه": 1,
    "المسعاة": 1,
    "أسغب": 1,
    "السغاب": 1,
    "المسغبة": 1,
    "سغد": 1,
    "سغسغ": 1,
    "تسغسغت": 1,
    "سغل": 1,
    "السغل التناسلي": 1,
    "سغمه": 1,
    "أسغمه": 1,
    "المسغم": 1,
    "السغن": 1,
    "سفت": 1,
    "استفت": 1,
    "السفت": 1,
    "سفتج": 1,
    "السفتجة": 1,
    "سافحها": 1,
    "تسافحا": 1,
    "السفح": 1,
    "السفيح": 1,
    "المسافح": 1,
    "المسافحة": 1,
    "المسفوح": 1,
    "أسفد": 1,
    "سافدها": 1,
    "تسافد": 1,
    "تسفد": 1,
    "استسفد": 1,
    "السفود": 1,
    "سفره": 1,
    "انسفر": 1,
    "تسفر": 1,
    "استسفره": 1,
    "السافر": 1,
    "السافرة": 1,
    "السافير": 1,
    "السفار": 1,
    "السفيرة": 1,
    "المسفار": 1,
    "المسفر": 1,
    "المسفرة": 1,
    "سفسط": 1,
    "السفسطي": 1,
    "السوفسطائية": 1,
    "السفساف": 1,
    "السفسافة": 1,
    "المسفسف": 1,
    "سفسق": 1,
    "سفسفة": 1,
    "استفط": 1,
    "تسفط": 1,
    "السفاطة": 1,
    "السفط": 1,
    "السفاط": 1,
    "السفيط": 1,
    "سافعه": 1,
    "سفعت": 1,
    "استفع": 1,
    "تسفع": 1,
    "السفع": 1,
    "السفعاء": 1,
    "السوافع": 1,
    "استف": 1,
    "السفة": 1,
    "السفوف": 1,
    "السفيفة": 1,
    "سفكه": 1,
    "انسفك": 1,
    "السفكة": 1,
    "السفوك": 1,
    "سفله": 1,
    "استفل": 1,
    "تسفل": 1,
    "السافلة": 1,
    "السفلة": 1,
    "المسفلة": 1,
    "سفنت": 1,
    "السافن": 1,
    "السفانة": 1,
    "السفان": 1,
    "السوافن": 1,
    "سافهه": 1,
    "سفهه": 1,
    "تسافه": 1,
    "تسفهت": 1,
    "السافه": 1,
    "السفيه": 1,
    "المسفهة": 1,
    "سفا": 1,
    "سفي": 1,
    "السافياء": 1,
    "السفا": 1,
    "السفاء": 1,
    "سقب": 1,
    "أسقب": 1,
    "ساقبه": 1,
    "تساقبا": 1,
    "السقاب": 1,
    "السقب": 1,
    "السقيبة": 1,
    "سقد": 1,
    "السقد": 1,
    "السقدة": 1,
    "أسقرت": 1,
    "الساقور": 1,
    "السقر": 1,
    "السقرة": 1,
    "السقار": 1,
    "المسقار": 1,
    "سقسق": 1,
    "المسقسق": 1,
    "استسقطه": 1,
    "السقاط": 1,
    "السقط": 1,
    "السقطي": 1,
    "السقيط": 1,
    "السقيطة": 1,
    "المسقاط": 1,
    "المسقطة": 1,
    "السقطري": 1,
    "الأسقع": 1,
    "سقفه": 1,
    "تسقف": 1,
    "السقاف": 1,
    "السقيف": 1,
    "المسقف": 1,
    "سقلت": 1,
    "الإسقال": 1,
    "الإسقيل": 1,
    "السقل": 1,
    "أسقم": 1,
    "سقمه": 1,
    "المسقام": 1,
    "المسقمة": 1,
    "السقمونيا": 1,
    "سقى بطنه": 1,
    "سقي": 1,
    "أسقاه": 1,
    "ساقى": 1,
    "سقاه": 1,
    "استقى": 1,
    "تساقى": 1,
    "السقاء": 1,
    "السقي": 1,
    "السقيا": 1,
    "المسقاة": 1,
    "المسقوي": 1,
    "الإسكابة": 1,
    "الأسكوب": 1,
    "السكبة": 1,
    "المسكبة": 1,
    "سكبج": 1,
    "السكباج": 1,
    "أسكته": 1,
    "سكته": 1,
    "الأسكات": 1,
    "الإسكاتة": 1,
    "الساكوت": 1,
    "الساكوتة": 1,
    "المسكت": 1,
    "أسكره": 1,
    "سكره": 1,
    "تساكر": 1,
    "السكارين": 1,
    "السكرة": 1,
    "السكار": 1,
    "السكرية": 1,
    "السكور": 1,
    "السكران": 1,
    "السكرجة": 1,
    "السكركة": 1,
    "الساكع": 1,
    "السكع": 1,
    "المسكعة": 1,
    "سكف": 1,
    "أسكف": 1,
    "تسكف": 1,
    "الأسكف": 1,
    "الأسكفة": 1,
    "الساكف": 1,
    "السكافة": 1,
    "استك": 1,
    "انسك": 1,
    "تسكك": 1,
    "السكاك": 1,
    "السكاكة": 1,
    "السكاء": 1,
    "السكي": 1,
    "السكل": 1,
    "سكم": 1,
    "السيكم": 1,
    "استكن": 1,
    "استكان": 1,
    "تساكنوا": 1,
    "تمسكن": 1,
    "السكاكيني": 1,
    "السكنجبين": 1,
    "استلأ": 1,
    "السلاء": 1,
    "المسلوء": 1,
    "سلبت": 1,
    "أسلب": 1,
    "استلبه": 1,
    "تسلبت": 1,
    "السلاب": 1,
    "السلبة": 1,
    "السلابة": 1,
    "السليب": 1,
    "سلته": 1,
    "انسلت": 1,
    "الأسلت": 1,
    "السلاتة": 1,
    "السلت": 1,
    "السلتاء": 1,
    "السلتة": 1,
    "سلجت": 1,
    "استلج": 1,
    "تسلج": 1,
    "السلج": 1,
    "السلجان": 1,
    "السليج": 1,
    "السليجة": 1,
    "السلاجم": 1,
    "السلجم": 1,
    "أسلحه": 1,
    "سلحه": 1,
    "الإسليح": 1,
    "السالح": 1,
    "السلح": 1,
    "سلخت": 1,
    "سلخه": 1,
    "تسلخ": 1,
    "الأسلخ": 1,
    "السلاخة": 1,
    "السلخة": 1,
    "السلاخ": 1,
    "السليخ": 1,
    "السليخة": 1,
    "المسلاخ": 1,
    "المسلخة": 1,
    "أسلست": 1,
    "السلاس": 1,
    "السلسة": 1,
    "السلسال": 1,
    "السلسل": 1,
    "سلطه": 1,
    "السلائط": 1,
    "السلط": 1,
    "السليط": 1,
    "المسلاط": 1,
    "المسلوط": 1,
    "سلطنه": 1,
    "أسلع": 1,
    "انسلع": 1,
    "تسلع": 1,
    "الأسلع": 1,
    "السولع": 1,
    "المسلع": 1,
    "سلغ": 1,
    "استلف": 1,
    "تسالف": 1,
    "تسلف": 1,
    "استسلف": 1,
    "الأسلوفة": 1,
    "السالفة": 1,
    "السلافة": 1,
    "السلفة": 1,
    "السلفي": 1,
    "السليف": 1,
    "المسلفة": 1,
    "أسلق": 1,
    "انسلق": 1,
    "الأسالق": 1,
    "السالقة": 1,
    "السليق": 1,
    "السليقة": 1,
    "السليقي": 1,
    "المسلاق": 1,
    "المسلق": 1,
    "المسلوقة": 1,
    "السلقون": 1,
    "السيلقون": 1,
    "أسلكه": 1,
    "انسلك": 1,
    "تسلك": 1,
    "السلكى": 1,
    "السلكة": 1,
    "السلاك": 1,
    "المسلك": 1,
    "استل": 1,
    "انسل": 1,
    "الإسلال": 1,
    "السال": 1,
    "السليلة": 1,
    "المسلل": 1,
    "سالمه": 1,
    "استلم": 1,
    "تسالما": 1,
    "تمسلم": 1,
    "الأسيلم": 1,
    "السلامى": 1,
    "السلامان": 1,
    "أبو سلمان": 1,
    "السلمة": 1,
    "اسلهب": 1,
    "السلهاب": 1,
    "السلهابة": 1,
    "السلهب": 1,
    "اسلهم": 1,
    "السلهم": 1,
    "سلاه": 1,
    "سليه": 1,
    "أسلى": 1,
    "انسلى": 1,
    "تسلى": 1,
    "السلوى": 1,
    "السلوان": 1,
    "السلوانة": 1,
    "السلوة": 1,
    "المسلى": 1,
    "المسلاة": 1,
    "سلى": 1,
    "سليت": 1,
    "أسلت": 1,
    "استلت": 1,
    "السلى": 1,
    "سمأل": 1,
    "اسمأل": 1,
    "السمأل": 1,
    "السموأل": 1,
    "سامته": 1,
    "تسامتا": 1,
    "تسمته": 1,
    "المتسامتة": 1,
    "المسمت": 1,
    "سمجه": 1,
    "استسمجه": 1,
    "السمج": 1,
    "السميج": 1,
    "سمجر": 1,
    "أسمح": 1,
    "سامحه": 1,
    "السمح": 1,
    "السمحة": 1,
    "المسماح": 1,
    "المسمح": 1,
    "السمحاق": 1,
    "السمحوق": 1,
    "سمده": 1,
    "اسمد": 1,
    "السمد": 1,
    "السميد": 1,
    "اسمدر": 1,
    "السمادير": 1,
    "السميدع": 1,
    "سامره": 1,
    "تسامرا": 1,
    "الأسمران": 1,
    "السامر": 1,
    "السمار": 1,
    "السمارة": 1,
    "السمر": 1,
    "السمير": 1,
    "السميرية": 1,
    "المسمور": 1,
    "سمرج": 1,
    "السمرج": 1,
    "السمرجة": 1,
    "السمرمر": 1,
    "السمرمرة": 1,
    "السماسم": 1,
    "السمسام": 1,
    "السمسمان": 1,
    "تسمط": 1,
    "السامط": 1,
    "السمطاء": 1,
    "السميط": 1,
    "المسمط": 1,
    "استمعه": 1,
    "تسمعه": 1,
    "السامعة": 1,
    "السماعي": 1,
    "المسمع": 1,
    "المسمعة": 1,
    "السمعمع": 1,
    "السمعمعة": 1,
    "السمق": 1,
    "السميق": 1,
    "استمك": 1,
    "انسمك": 1,
    "السميكاء": 1,
    "المسموك": 1,
    "أسمل": 1,
    "استمل": 1,
    "تسمل": 1,
    "السمال": 1,
    "السملان": 1,
    "السملة": 1,
    "سملج": 1,
    "السمالج": 1,
    "السملاج": 1,
    "السملج": 1,
    "السمالخي": 1,
    "السملق": 1,
    "سملك": 1,
    "سممه": 1,
    "السمام": 1,
    "المسم": 1,
    "المسمة": 1,
    "سمنه": 1,
    "تسمن": 1,
    "استسمن": 1,
    "الأسمن": 1,
    "السامن": 1,
    "السمانى": 1,
    "السمان": 1,
    "السمنية": 1,
    "السمين": 1,
    "المسمنة": 1,
    "السمند": 1,
    "السمندل": 1,
    "سمه": 1,
    "السمه": 1,
    "السمهى": 1,
    "السمهة": 1,
    "السميهى": 1,
    "سمهج": 1,
    "السماهج": 1,
    "السمهاج": 1,
    "السمهج": 1,
    "السمهجة": 1,
    "سمهر": 1,
    "اسمهر": 1,
    "السمهري": 1,
    "ساماه": 1,
    "سماه": 1,
    "استمى": 1,
    "تسامى": 1,
    "استسمى": 1,
    "السما": 1,
    "السماوة": 1,
    "السمي": 1,
    "المسماة": 1,
    "المسمى": 1,
    "السناب": 1,
    "السنابة": 1,
    "السنب": 1,
    "السنبة": 1,
    "السنوب": 1,
    "السنبوق": 1,
    "السنبك": 1,
    "السنبوك": 1,
    "السنبول": 1,
    "أسنت": 1,
    "تسنت": 1,
    "السنوت": 1,
    "سنجه": 1,
    "السنجة": 1,
    "السنجق": 1,
    "كانت في التقسيم الإداري": 1,
    "تسنح": 1,
    "استسنحه": 1,
    "السنح": 1,
    "السنيح": 1,
    "السناخة": 1,
    "السنخ": 1,
    "سانده": 1,
    "استند": 1,
    "تساند": 1,
    "السناد": 1,
    "السنديان": 1,
    "السنيد": 1,
    "سندر": 1,
    "السندر": 1,
    "السندري": 1,
    "السندروس": 1,
    "السندس": 1,
    "سندل": 1,
    "السندال": 1,
    "السندل": 1,
    "سنر": 1,
    "السنور": 1,
    "سنسنت": 1,
    "السنسن": 1,
    "السنسنة": 1,
    "السنط": 1,
    "السنطير": 1,
    "سنع": 1,
    "أسنع": 1,
    "الأسنع": 1,
    "السنع": 1,
    "السنيع": 1,
    "السنيعة": 1,
    "السنعبة": 1,
    "أسنف": 1,
    "السناف": 1,
    "السنف": 1,
    "السنيف": 1,
    "المسناف": 1,
    "سنق": 1,
    "أسنقه": 1,
    "السنيق": 1,
    "السنكسار": 1,
    "عند النصارى": 1,
    "أسنم": 1,
    "استنم": 1,
    "تسنمه": 1,
    "السنام": 1,
    "السنما": 1,
    "السنماتوغراف": 1,
    "السنمة": 1,
    "السنيم": 1,
    "المسنم": 1,
    "السنمار": 1,
    "استسن": 1,
    "السنان": 1,
    "السنن": 1,
    "السنون": 1,
    "السنينة": 1,
    "المسان": 1,
    "سانهت": 1,
    "تسنه": 1,
    "السناهية": 1,
    "السنهاء": 1,
    "أسنى": 1,
    "سانى": 1,
    "سناه": 1,
    "استنى": 1,
    "تسنى": 1,
    "السانية": 1,
    "السناء": 1,
    "السنواء": 1,
    "المسناة": 1,
    "المسنوية": 1,
    "السنيورية": 1,
    "سهبه": 1,
    "استهب": 1,
    "التسهيب": 1,
    "السهبة": 1,
    "المسهب": 1,
    "المسهبة": 1,
    "سهجت": 1,
    "الأساهيج": 1,
    "الساهج": 1,
    "السهوج": 1,
    "السيهج": 1,
    "المسهج": 1,
    "سهجر": 1,
    "سهد": 1,
    "أسهدت": 1,
    "سهده": 1,
    "الأسهد": 1,
    "السهد": 1,
    "السهدة": 1,
    "السهود": 1,
    "سهر": 1,
    "أسهره": 1,
    "الأسهران": 1,
    "الساهر": 1,
    "الساهرة": 1,
    "الساهور": 1,
    "السهار": 1,
    "السهرة": 1,
    "السهاري": 1,
    "المسهار": 1,
    "سهف": 1,
    "استهفه": 1,
    "الساهف": 1,
    "السهاف": 1,
    "السهف": 1,
    "المسهاف": 1,
    "المسهفة": 1,
    "المسهوف": 1,
    "سهكت": 1,
    "سهك": 1,
    "الأساهيك": 1,
    "الساهك": 1,
    "الساهكة": 1,
    "السهاكة": 1,
    "السهكة": 1,
    "السهوك": 1,
    "ساهله": 1,
    "سهله": 1,
    "تساهل": 1,
    "تسهل": 1,
    "استسهل": 1,
    "السهلي": 1,
    "ساهمه": 1,
    "تساهم": 1,
    "السهمة": 1,
    "السهيم": 1,
    "سهى": 1,
    "سهو": 1,
    "أسهى": 1,
    "ساهاه": 1,
    "سهاه": 1,
    "السها": 1,
    "السهواء": 1,
    "السهوة": 1,
    "سوأه": 1,
    "استاء": 1,
    "السوآء": 1,
    "السوأة": 1,
    "المساءة": 1,
    "السوبة": 1,
    "السوبية": 1,
    "سوج": 1,
    "الساجة": 1,
    "السوج": 1,
    "المسوجة": 1,
    "أساخه": 1,
    "تسوخ": 1,
    "السواخ": 1,
    "أساد": 1,
    "ساوده": 1,
    "تسود": 1,
    "اسواد": 1,
    "الأسودان": 1,
    "الأسودة": 1,
    "السودد": 1,
    "السودق": 1,
    "سودل": 1,
    "السودل": 1,
    "السوذانق": 1,
    "السوذق": 1,
    "السوذقي": 1,
    "ساوره": 1,
    "سوره": 1,
    "تساور": 1,
    "تسورت": 1,
    "الإسوار": 1,
    "السوار": 1,
    "السورة": 1,
    "المسور": 1,
    "المسورة": 1,
    "استاس": 1,
    "الساس": 1,
    "السواس": 1,
    "السوسن": 1,
    "ساطت": 1,
    "ساوط": 1,
    "استوط": 1,
    "السواط": 1,
    "السويطاء": 1,
    "السويطة": 1,
    "المسواط": 1,
    "المسوط": 1,
    "المسياط": 1,
    "أساع": 1,
    "ساوعه": 1,
    "الساع": 1,
    "السواع": 1,
    "سواع": 1,
    "السوع": 1,
    "المسياع": 1,
    "أساغ": 1,
    "أسوغ": 1,
    "استساغه": 1,
    "الأسوغ": 1,
    "التسويغ": 1,
    "السواغ": 1,
    "السوغ": 1,
    "السوغة": 1,
    "المسوغات": 1,
    "سافت": 1,
    "أساف": 1,
    "ساوف": 1,
    "استافه": 1,
    "السائفة": 1,
    "الساف": 1,
    "السواف": 1,
    "السوف": 1,
    "السيفة": 1,
    "المساف": 1,
    "أساقه": 1,
    "ساوقه": 1,
    "استاقه": 1,
    "تساوقت": 1,
    "الساقة": 1,
    "السوقة": 1,
    "السوقي": 1,
    "السويق": 1,
    "السويقة": 1,
    "السيق": 1,
    "السيقة": 1,
    "المسوقة": 1,
    "سوكه": 1,
    "استاك": 1,
    "تساوك": 1,
    "تسوك": 1,
    "السواك": 1,
    "السوال": 1,
    "السول": 1,
    "السولار": 1,
    "ساومه": 1,
    "استامت": 1,
    "تساوما": 1,
    "تسوم": 1,
    "السائمة": 1,
    "السومة": 1,
    "السيمة": 1,
    "السيما": 1,
    "السيماء": 1,
    "السيمياء": 1,
    "ساواه": 1,
    "تساويا": 1,
    "السوية": 1,
    "المستوي": 1,
    "سيأت": 1,
    "انسيأ": 1,
    "تسيأت": 1,
    "السياء": 1,
    "سيبه": 1,
    "انساب": 1,
    "الإسابة": 1,
    "السائبة": 1,
    "السياب": 1,
    "السيابة": 1,
    "السيب": 1,
    "السيوبة": 1,
    "أساح": 1,
    "انساح": 1,
    "السيح": 1,
    "المسياح": 1,
    "أساخ": 1,
    "السيدانة": 1,
    "السيداق": 1,
    "أساره": 1,
    "سايره": 1,
    "السيراء": 1,
    "المسير": 1,
    "السيساء": 1,
    "السيساءة": 1,
    "تسيطر": 1,
    "أساعه": 1,
    "سيع": 1,
    "انساع": 1,
    "تسيع": 1,
    "الأسيع": 1,
    "السياع": 1,
    "السيع": 1,
    "السيعاء": 1,
    "المسيعة": 1,
    "أساغه": 1,
    "السيغ": 1,
    "سيفت": 1,
    "سايفه": 1,
    "سيفه": 1,
    "استافوا": 1,
    "تسايفوا": 1,
    "تسيفه": 1,
    "السائف": 1,
    "السيفان": 1,
    "السياف": 1,
    "المسائف": 1,
    "المسايفة": 1,
    "المسيف": 1,
    "أساله": 1,
    "سيله": 1,
    "تسايل": 1,
    "السيال": 1,
    "السيلان": 1,
    "السيمافور": 1,
    "السية": 1,
    "الشؤبوب": 1,
    "شئز": 1,
    "أشأزه": 1,
    "اشتأز": 1,
    "شأشأت": 1,
    "تشأشأ": 1,
    "شئفت": 1,
    "استشأفت": 1,
    "شأف": 1,
    "الشأفة": 1,
    "شأمهم": 1,
    "شئم": 1,
    "أشأم": 1,
    "شاءم": 1,
    "تشاءم": 1,
    "تشأم": 1,
    "الأشأم": 1,
    "الشأم": 1,
    "الشؤمي": 1,
    "الشأمة": 1,
    "اشتأن": 1,
    "شأوت": 1,
    "شاءاه": 1,
    "تشاءى": 1,
    "استشب": 1,
    "الشبب": 1,
    "الشب": 1,
    "الشبة": 1,
    "الشبوب": 1,
    "المشبوب": 1,
    "شابث": 1,
    "تشبث": 1,
    "الشبث": 1,
    "الشبثة": 1,
    "تشابر": 1,
    "الشبر": 1,
    "المشابر": 1,
    "الشبور": 1,
    "الشبورة": 1,
    "شبرقه": 1,
    "شبارق": 1,
    "الشباريق": 1,
    "الشبرقة": 1,
    "أشبعه": 1,
    "الشباعة": 1,
    "الشبعة": 1,
    "الشبيع": 1,
    "الشابك": 1,
    "الشبيكة": 1,
    "أشبلت": 1,
    "شبمه": 1,
    "شبم": 1,
    "الشبام": 1,
    "الشبين": 1,
    "شابهه": 1,
    "الشبهة": 1,
    "المتشابه": 1,
    "المشبهة": 1,
    "أشبى": 1,
    "الشبا": 1,
    "شبوة": 1,
    "أشت": 1,
    "تشتتوا": 1,
    "الشت": 1,
    "الشتوت": 1,
    "الشتيت": 1,
    "شتره": 1,
    "الشتير": 1,
    "الشترة": 1,
    "شتمه": 1,
    "شاتمه": 1,
    "تشاتما": 1,
    "الشتام والشتامة": 1,
    "شتن": 1,
    "أشتى": 1,
    "شاتاه": 1,
    "الشتوة": 1,
    "الشتوي": 1,
    "الشتي": 1,
    "المشتى": 1,
    "المشتاة": 1,
    "شثنت": 1,
    "الشثن": 1,
    "أشجبه": 1,
    "تشاجبت": 1,
    "الشجاب": 1,
    "الشجوب": 1,
    "المشجب": 1,
    "شجه": 1,
    "شاجه": 1,
    "الشجج": 1,
    "الشجة": 1,
    "الشجيج": 1,
    "أشجرت": 1,
    "شاجره": 1,
    "اشتجر": 1,
    "الشجراء": 1,
    "الشجير": 1,
    "الشواجر": 1,
    "شاجعه": 1,
    "الشجعة": 1,
    "الشجيع": 1,
    "شجنت": 1,
    "أشجن": 1,
    "تشجن": 1,
    "الشاجن": 1,
    "الشجن": 1,
    "الشجنة": 1,
    "شجاه": 1,
    "شجي": 1,
    "أشجاه": 1,
    "الشجا": 1,
    "الشجو": 1,
    "الشجي": 1,
    "شحث": 1,
    "الشحاث": 1,
    "شحج": 1,
    "تشحج": 1,
    "الشاحج": 1,
    "شاح": 1,
    "تشاحوا": 1,
    "الشحة": 1,
    "الشحيح": 1,
    "أشحذ": 1,
    "المشحذ": 1,
    "المشحذة": 1,
    "الشحر": 1,
    "الشحرور": 1,
    "أشحطه": 1,
    "شحطه": 1,
    "الشحط": 1,
    "الشحطة": 1,
    "الشوحط": 1,
    "أشحم": 1,
    "الشحمة": 1,
    "الشحيم": 1,
    "أشحن": 1,
    "شاحنه": 1,
    "تشاحنوا": 1,
    "الشحناء": 1,
    "شحا": 1,
    "شحى": 1,
    "تشحى": 1,
    "الشحوة": 1,
    "انشخب": 1,
    "الأشخوب": 1,
    "الشخب والشخب": 1,
    "الشخبة": 1,
    "شخت": 1,
    "الشخت": 1,
    "شخس": 1,
    "تشاخست": 1,
    "أشخص": 1,
    "الشاخص": 1,
    "شخم": 1,
    "أشخم": 1,
    "الأشخم": 1,
    "شدخت": 1,
    "شدخه": 1,
    "انشدخ": 1,
    "تشدخ": 1,
    "الشدخة": 1,
    "المشدخ والمشدخة": 1,
    "تشادا": 1,
    "تشدد": 1,
    "الشداد": 1,
    "والشديد": 1,
    "المشد": 1,
    "شدفه": 1,
    "شدف": 1,
    "تشادف": 1,
    "الأشدف": 1,
    "الشادوف": 1,
    "الشدفة": 1,
    "الأشدق": 1,
    "الشدق": 1,
    "الشدقم": 1,
    "أشدنت": 1,
    "الشادن": 1,
    "أشدهه": 1,
    "انشده": 1,
    "الشداه": 1,
    "المشاده": 1,
    "الشادي": 1,
    "الشدا": 1,
    "الشدو": 1,
    "تشذب": 1,
    "الشاذب": 1,
    "الشذب": 1,
    "المشذب": 1,
    "أشذ": 1,
    "تشذر": 1,
    "الشذر": 1,
    "شذر مذر": 1,
    "أشذاه": 1,
    "الشذو": 1,
    "الشذا": 1,
    "الشذاة": 1,
    "أشرب": 1,
    "شاربه": 1,
    "استشرب": 1,
    "الشاربة": 1,
    "الشربة": 1,
    "الشروب": 1,
    "المشرب": 1,
    "شرثت": 1,
    "انشرثت": 1,
    "الشرث": 1,
    "أشرج": 1,
    "انشرج": 1,
    "تشرج": 1,
    "الشريج": 1,
    "الشريجة": 1,
    "شرجع": 1,
    "الشرجع": 1,
    "المشرجع": 1,
    "الشرخ": 1,
    "أشرده": 1,
    "شرده": 1,
    "تشردوا": 1,
    "الشريد": 1,
    "الإشرارة": 1,
    "الشرار": 1,
    "الشرر": 1,
    "الشرة": 1,
    "شرز": 1,
    "أشرزه": 1,
    "شارزه": 1,
    "شرزه": 1,
    "الشرز": 1,
    "المشارز": 1,
    "شارسه": 1,
    "تشارس": 1,
    "الشراس": 1,
    "الشريس": 1,
    "الشرسوف": 1,
    "شرشر": 1,
    "الشراشر": 1,
    "الشرشر": 1,
    "الشرشرة": 1,
    "أشرطه": 1,
    "شارطه": 1,
    "اشترط": 1,
    "تشارطا": 1,
    "الشريطة": 1,
    "المشرطة": 1,
    "أشرع": 1,
    "اشترع": 1,
    "الأشرع": 1,
    "الشراعة": 1,
    "الشرعة": 1,
    "المشرعة": 1,
    "شرفت": 1,
    "شارفه": 1,
    "تشرف": 1,
    "استشرف": 1,
    "أشراف": 1,
    "الشارف": 1,
    "الشاروف": 1,
    "الشرافة": 1,
    "المشارف": 1,
    "المشرفي": 1,
    "شرقت": 1,
    "أشرقت": 1,
    "انشرقت": 1,
    "تشرق": 1,
    "الشارق": 1,
    "الشراقي": 1,
    "الشرقة": 1,
    "الشريق": 1,
    "المشارقة": 1,
    "المشريق": 1,
    "شركت": 1,
    "أشركه": 1,
    "تشاركا": 1,
    "الاشتراكية": 1,
    "الش راك": 1,
    "شرمه": 1,
    "انشرم": 1,
    "تشرم": 1,
    "الشرم": 1,
    "شرنقه": 1,
    "شراه": 1,
    "أشرت": 1,
    "شاراه": 1,
    "تشرى": 1,
    "استشرى": 1,
    "الشاري": 1,
    "الشرى": 1,
    "الشري": 1,
    "شزب": 1,
    "الشزيب": 1,
    "تشازروا": 1,
    "استشزر": 1,
    "الشزر": 1,
    "شزن": 1,
    "تشزنت": 1,
    "الشزن": 1,
    "شسع": 1,
    "شسعت": 1,
    "الشسع": 1,
    "الشسف": 1,
    "الشسيف": 1,
    "الششم": 1,
    "شصب": 1,
    "الشصب": 1,
    "الشصيبة": 1,
    "الشاصر": 1,
    "الشاصرة": 1,
    "الشصار": 1,
    "الشصر": 1,
    "أشصه": 1,
    "الشصوص": 1,
    "شصا": 1,
    "أشصى": 1,
    "الشصو": 1,
    "شطأ": 1,
    "أشطأ": 1,
    "شاطأته": 1,
    "الشطء": 1,
    "انشطب": 1,
    "الشاطب": 1,
    "الشطبة": 1,
    "الشطيبة": 1,
    "الشطحة": 1,
    "الشاطر": 1,
    "الشطور": 1,
    "الشطير": 1,
    "الشطيرة": 1,
    "المشطور": 1,
    "وانظر الرقعة": 1,
    "أشط": 1,
    "شاطه": 1,
    "اشتط": 1,
    "الشطاط": 1,
    "الشط": 1,
    "الشطوف": 1,
    "الشطفة": 1,
    "المشطوف": 1,
    "شطنت": 1,
    "أشطنه": 1,
    "الشطن": 1,
    "الشطون": 1,
    "الشظف": 1,
    "أشظاه": 1,
    "شظى": 1,
    "تشظى": 1,
    "الشظى": 1,
    "أشعب": 1,
    "انشعب": 1,
    "الشعيبة": 1,
    "المشعب": 1,
    "تشعث": 1,
    "الأشعث": 1,
    "الشعث": 1,
    "شاعره": 1,
    "تشاعر": 1,
    "استشعر": 1,
    "الأشعر": 1,
    "الأشعرية": 1,
    "الشعراني": 1,
    "الشعرة": 1,
    "الشعرور": 1,
    "تشعشع": 1,
    "الشعشاع": 1,
    "الشعشع": 1,
    "أشعت": 1,
    "الأشعة السينية": 1,
    "الأشعة الكونية": 1,
    "الشع": 1,
    "الشعاف": 1,
    "الشعفة": 1,
    "المشعوف": 1,
    "شعلت": 1,
    "اشتعلت": 1,
    "تشعل": 1,
    "الأشعل": 1,
    "الشعيل": 1,
    "المشعال": 1,
    "شعن": 1,
    "أشعن": 1,
    "اشعن": 1,
    "الشعنون": 1,
    "شعا": 1,
    "شعي": 1,
    "أشعى": 1,
    "الشاعي": 1,
    "الشعى": 1,
    "الشعواء": 1,
    "انظرها مع مشتقاتها في شعذ": 1,
    "شاغبه": 1,
    "تشاغب": 1,
    "المشغب": 1,
    "أشغر": 1,
    "شاغره": 1,
    "اشتغر": 1,
    "تشاغرا": 1,
    "تشغر": 1,
    "الشغار": 1,
    "الشغر": 1,
    "الشغارة": 1,
    "شغشغ": 1,
    "شغفه": 1,
    "تشغفه": 1,
    "الشغاف": 1,
    "شغله": 1,
    "انشغل": 1,
    "تشاغل": 1,
    "الأشغولة": 1,
    "الشغال": 1,
    "المشغول": 1,
    "شغيت": 1,
    "أشغى": 1,
    "شفره": 1,
    "شفرت": 1,
    "الشفر": 1,
    "الشفار": 1,
    "الشفير": 1,
    "الشفشاف": 1,
    "المشفشف": 1,
    "تشفع": 1,
    "استشفع": 1,
    "الشافع": 1,
    "الشفائع": 1,
    "الشفع": 1,
    "الشفوع": 1,
    "اشتف": 1,
    "استشف": 1,
    "الشفافة": 1,
    "الشف": 1,
    "الشفف": 1,
    "الشفيف": 1,
    "أشفق": 1,
    "الشفيق": 1,
    "شفنه": 1,
    "شفهه": 1,
    "شافهه": 1,
    "الشافه": 1,
    "الشفاهي": 1,
    "أشفى": 1,
    "شافاه": 1,
    "اشتفى": 1,
    "تشفى": 1,
    "استشفى": 1,
    "الأشفى": 1,
    "الشفا": 1,
    "الشفوي": 1,
    "شقأ": 1,
    "المشقأ": 1,
    "أشقح": 1,
    "شاقحه": 1,
    "تشاقحا": 1,
    "الأشقح": 1,
    "الشقحة": 1,
    "الشقدة": 1,
    "الشقدف": 1,
    "شقذ": 1,
    "أشقذه": 1,
    "شاقذه": 1,
    "الشقذ": 1,
    "الشقذان": 1,
    "الشقذانة": 1,
    "الشقيذ": 1,
    "الأشقر": 1,
    "الشقارى": 1,
    "الشقر": 1,
    "الشقرى": 1,
    "الشقرة": 1,
    "الشقار": 1,
    "الشقور": 1,
    "الشقير": 1,
    "المشقر": 1,
    "الشقراق والشقراق": 1,
    "الشقشقة": 1,
    "شقص": 1,
    "الشقص": 1,
    "الشقيص": 1,
    "المشقص": 1,
    "الشقف": 1,
    "الشقاف": 1,
    "شاقه": 1,
    "شققه": 1,
    "انشق": 1,
    "تشاقا": 1,
    "استشق": 1,
    "الشقائق": 1,
    "شقله": 1,
    "شقاه": 1,
    "أشقاه": 1,
    "شاقى": 1,
    "الشقاء": 1,
    "الشقاوة": 1,
    "الشقوة": 1,
    "شكئ": 1,
    "أشكأت": 1,
    "الشكاء": 1,
    "الشكبان": 1,
    "شكده": 1,
    "الشكد": 1,
    "شكرت": 1,
    "اشتكر": 1,
    "تشكر": 1,
    "الشكائر": 1,
    "الشكارة": 1,
    "الشكير": 1,
    "المشكار": 1,
    "المشكرة": 1,
    "شكزه": 1,
    "شاكسه": 1,
    "تشاكسا": 1,
    "الشاكوش": 1,
    "شكشك": 1,
    "شكع": 1,
    "أشكعه": 1,
    "شككه": 1,
    "اشتك": 1,
    "التشكيك": 1,
    "الشاكة": 1,
    "الشكة": 1,
    "الشكيكة": 1,
    "المشك": 1,
    "أشكل": 1,
    "شاكله": 1,
    "تشاكلا": 1,
    "استشكل": 1,
    "الأشكل": 1,
    "الشاكلة": 1,
    "الشكال": 1,
    "الشكلة": 1,
    "المشاكلة": 1,
    "أشكمه": 1,
    "الشكم": 1,
    "شاكهه": 1,
    "تشاكها": 1,
    "أشكى": 1,
    "شاكاه": 1,
    "شكى": 1,
    "تشاكى": 1,
    "تشكى": 1,
    "الشكاة": 1,
    "الشكوة": 1,
    "الشكية": 1,
    "المشكاة": 1,
    "الشلجم": 1,
    "شلحه": 1,
    "شلح العين": 1,
    "تشلشل": 1,
    "الشلاشل": 1,
    "الشلشل": 1,
    "شلقه": 1,
    "أشل": 1,
    "انشل": 1,
    "الشلول": 1,
    "الشليل": 1,
    "المشلل": 1,
    "الشالم": 1,
    "الشولم": 1,
    "الشيلم": 1,
    "أشلى": 1,
    "اشتلاه": 1,
    "استشلى": 1,
    "الشلا": 1,
    "الشلو": 1,
    "الشلية": 1,
    "المشلى": 1,
    "أشمته": 1,
    "شمته": 1,
    "الشامتة": 1,
    "الشمات": 1,
    "الشماتى": 1,
    "شمجت": 1,
    "الشماج": 1,
    "الشمخ": 1,
    "الشموخ": 1,
    "الشمختر": 1,
    "شمخر": 1,
    "اشمخر": 1,
    "الشمخريرة": 1,
    "الشمخر": 1,
    "شمذ": 1,
    "الشامذ": 1,
    "الشمذة": 1,
    "أشمر": 1,
    "انشمر": 1,
    "تشمر": 1,
    "الشامر": 1,
    "الشمار": 1,
    "الشمر": 1,
    "الشمري": 1,
    "الشمير": 1,
    "شمرج": 1,
    "الشمرج": 1,
    "الشمروج": 1,
    "شمرخ": 1,
    "الشمراخ": 1,
    "الشمروخ": 1,
    "الشمردل": 1,
    "شمز": 1,
    "تشمز": 1,
    "الشمأزيزة": 1,
    "شامسه": 1,
    "تشامسا": 1,
    "الشامس": 1,
    "شمص": 1,
    "الشموص": 1,
    "المشموص": 1,
    "أشمط": 1,
    "اشمأط": 1,
    "اشماط": 1,
    "الأشمط": 1,
    "الشمط": 1,
    "الشمطات": 1,
    "الشمطان": 1,
    "الشميط": 1,
    "الشماطيط": 1,
    "أشمع": 1,
    "شمعه": 1,
    "الشماع والشماعة": 1,
    "اشمعل": 1,
    "المشمعل": 1,
    "شمق": 1,
    "الأشمق": 1,
    "الشمق": 1,
    "الشمقمق": 1,
    "شملت": 1,
    "أشملت": 1,
    "اشتمل": 1,
    "الشملة": 1,
    "المشمال": 1,
    "المشمل": 1,
    "المشمول": 1,
    "شملل": 1,
    "الشملال": 1,
    "الشملول": 1,
    "الشمليل": 1,
    "شمه": 1,
    "أشم": 1,
    "شممه": 1,
    "اشتمه": 1,
    "تشممه": 1,
    "الشمم": 1,
    "الشمامات": 1,
    "الشميم": 1,
    "شنأه": 1,
    "تشانؤوا": 1,
    "الشناءة": 1,
    "الشنوءة": 1,
    "المشنأ": 1,
    "المشنأة": 1,
    "المشنوء": 1,
    "الشنب": 1,
    "الشنباء": 1,
    "شنتر": 1,
    "الأشنج": 1,
    "المشنج": 1,
    "شنره": 1,
    "الشنار": 1,
    "شنشن": 1,
    "الشنشنة": 1,
    "تشنع": 1,
    "استشنع": 1,
    "الشنعاء": 1,
    "الشنعة": 1,
    "تشنفت": 1,
    "الشنف": 1,
    "شنقه": 1,
    "اشنق": 1,
    "شانقه": 1,
    "تشانق": 1,
    "الشناق": 1,
    "الشنقاء": 1,
    "المشناق": 1,
    "أشن": 1,
    "شنن": 1,
    "انشن": 1,
    "تشنن": 1,
    "استشنت": 1,
    "الشانة": 1,
    "الشنان": 1,
    "الشنانة": 1,
    "الشن": 1,
    "الشنة": 1,
    "الشنون": 1,
    "الشنين": 1,
    "المشنة": 1,
    "شهبه": 1,
    "شهب": 1,
    "اشتهب": 1,
    "اشهاب": 1,
    "اشهب": 1,
    "الأشهب": 1,
    "الشهاب": 1,
    "الشهابة": 1,
    "الشهباء": 1,
    "الشهبة": 1,
    "أشهده": 1,
    "شاهده": 1,
    "استشهد": 1,
    "الإشهاد": 1,
    "التشهد": 1,
    "الشهد": 1,
    "المشهود": 1,
    "الشهدانج": 1,
    "الشهدانق": 1,
    "شهره": 1,
    "شاهره": 1,
    "اشتهر": 1,
    "تشاهر": 1,
    "الشهير": 1,
    "المشهورات": 1,
    "الشهرمان": 1,
    "شهق": 1,
    "الشاهق": 1,
    "شهل": 1,
    "شاهله": 1,
    "تشهل": 1,
    "الشهل": 1,
    "الشهلة": 1,
    "شهمه": 1,
    "الشيهم": 1,
    "الشاهنشاه": 1,
    "الشاهين": 1,
    "شهاه": 1,
    "شهية": 1,
    "شهو": 1,
    "أشهاه": 1,
    "اشتهى": 1,
    "تشهى": 1,
    "الشاهية": 1,
    "الشهوان": 1,
    "المشهيات": 1,
    "الشوبة": 1,
    "الشياب": 1,
    "شوذت": 1,
    "شاوره": 1,
    "اشتار": 1,
    "اشتور": 1,
    "تشاوروا": 1,
    "استشار": 1,
    "الشار": 1,
    "الشوار": 1,
    "الشور": 1,
    "الشورة": 1,
    "الشيار": 1,
    "الشير": 1,
    "المشور": 1,
    "المشورة": 1,
    "المشيرة": 1,
    "شاس": 1,
    "شاوس": 1,
    "تشاوس": 1,
    "شوشه": 1,
    "تشوش": 1,
    "شاص": 1,
    "شوصت": 1,
    "تشوص": 1,
    "الأشوص": 1,
    "الشوص": 1,
    "الشوصاء": 1,
    "الشوصة": 1,
    "الشياص": 1,
    "تشوط": 1,
    "الشوط": 1,
    "شاظ": 1,
    "الشواظ": 1,
    "شوع": 1,
    "الشوع": 1,
    "أشاف": 1,
    "شوفه": 1,
    "شيف": 1,
    "اشتاف": 1,
    "تشوف": 1,
    "الشياف": 1,
    "المشوفة": 1,
    "أشاق": 1,
    "شوقه": 1,
    "اشتاقه": 1,
    "الشائق": 1,
    "الشيق": 1,
    "شاكته": 1,
    "أشاك": 1,
    "الشوكاء": 1,
    "أشاله": 1,
    "شاول": 1,
    "انشال": 1,
    "تشاول": 1,
    "الشائل": 1,
    "الشائلة": 1,
    "الشول": 1,
    "الشولة": 1,
    "الشوالة": 1,
    "المشوال": 1,
    "المشول": 1,
    "المشولة": 1,
    "الشوم": 1,
    "الشوان": 1,
    "الشونيز": 1,
    "شوهه": 1,
    "الأشوه": 1,
    "الشاة": 1,
    "وشاه بلوط": 1,
    "شاهنشاه وشهنشاه": 1,
    "الشوهاء": 1,
    "الشوهة": 1,
    "المشاهة": 1,
    "أشوى": 1,
    "اشتوى": 1,
    "انشوى": 1,
    "الشوى": 1,
    "الشواة": 1,
    "الشوية": 1,
    "المشوى": 1,
    "شاءه": 1,
    "أشاءه": 1,
    "شيأه": 1,
    "تشيأ": 1,
    "المشيئة": 1,
    "المشيأ": 1,
    "أشاب": 1,
    "شيبه": 1,
    "الأشيب": 1,
    "المشيب": 1,
    "الشيت": 1,
    "الشائح": 1,
    "الشيح": 1,
    "تشيخ": 1,
    "الشياخ": 1,
    "الشياخة": 1,
    "أشاد": 1,
    "شيده": 1,
    "الشيد": 1,
    "الشيرج": 1,
    "شيز": 1,
    "الشيز": 1,
    "الشيزى": 1,
    "أشاشت": 1,
    "الشيشاء": 1,
    "أشاصت": 1,
    "شايص": 1,
    "شيصت": 1,
    "الشيص": 1,
    "الشيصاء": 1,
    "أشاط": 1,
    "اشتاط": 1,
    "تشيط": 1,
    "الشياط": 1,
    "تشيظم": 1,
    "الشيظم": 1,
    "أشاع": 1,
    "شايعه": 1,
    "اشتاعا": 1,
    "تشايع": 1,
    "الشائعة": 1,
    "الشاع": 1,
    "الشاعة": 1,
    "الشياع": 1,
    "الشيع": 1,
    "الشيعي": 1,
    "المشاع": 1,
    "المشياع": 1,
    "المشيعة": 1,
    "المشيع": 1,
    "الشيك": 1,
    "شاله": 1,
    "الشيالة": 1,
    "المشيال": 1,
    "تشيم": 1,
    "الشيم": 1,
    "الشيمة": 1,
    "شانه": 1,
    "المشاين": 1,
    "الشيه": 1,
    "الصاد": 1,
    "صئب": 1,
    "أصأب": 1,
    "الصؤابة": 1,
    "صأصأ": 1,
    "تصأصأ": 1,
    "صئك": 1,
    "صاءكه": 1,
    "الصأكة": 1,
    "صؤل": 1,
    "تصأى": 1,
    "الصآة": 1,
    "أصبأ": 1,
    "الصابئون": 1,
    "انصب": 1,
    "اصطب": 1,
    "تصاب": 1,
    "الصبابة": 1,
    "الصبب": 1,
    "الصبة": 1,
    "الصبيب": 1,
    "اصطبح": 1,
    "تصابح": 1,
    "استصبح": 1,
    "الصابح": 1,
    "الصباحي": 1,
    "الصبحان": 1,
    "الصبحة": 1,
    "الصبوح": 1,
    "الصبيحة": 1,
    "المصبح": 1,
    "أصبر": 1,
    "صابره": 1,
    "اصطبر": 1,
    "استصبر": 1,
    "الصابورة": 1,
    "الصبارة": 1,
    "الصبرة": 1,
    "الصبور": 1,
    "الصبير": 1,
    "المصبور": 1,
    "صبصب": 1,
    "تصبصب": 1,
    "الصبصاب": 1,
    "الإصبع والأصبع": 1,
    "الأصبوع": 1,
    "أصبغت": 1,
    "صبغت": 1,
    "اصطبغ": 1,
    "تصبغ": 1,
    "المصبغة": 1,
    "الصبغي": 1,
    "الصبان": 1,
    "الصبانة": 1,
    "المصبنة": 1,
    "الصبية": 1,
    "أصبت": 1,
    "صابى": 1,
    "تصابى": 1,
    "تصبى": 1,
    "صحبه": 1,
    "أصحب": 1,
    "اصطحب": 1,
    "تصاحبا": 1,
    "استصحب": 1,
    "الصاحبة": 1,
    "الصحابي": 1,
    "المصحاب": 1,
    "المصحب": 1,
    "صححه": 1,
    "تصحح": 1,
    "استصح": 1,
    "الصحاح": 1,
    "صحر": 1,
    "صاحر": 1,
    "الصحار": 1,
    "الصحيرة": 1,
    "صحصح": 1,
    "الصحصاح والصحصح": 1,
    "الصحصح والصحصوح": 1,
    "أصحف": 1,
    "تصحفت": 1,
    "الصحاف": 1,
    "الصحفة": 1,
    "المصحف": 1,
    "صحنه": 1,
    "الصحنان": 1,
    "الصحناء": 1,
    "الصحناة": 1,
    "المصحنة": 1,
    "أصحى": 1,
    "الصحو": 1,
    "المصحاة": 1,
    "صاخبه": 1,
    "اصطخب": 1,
    "تصاخب": 1,
    "صخ": 1,
    "أصخ": 1,
    "الصخة": 1,
    "الصخيخ": 1,
    "صخد": 1,
    "أصخد": 1,
    "اصطخد": 1,
    "الصاخدة": 1,
    "المصخدة": 1,
    "الصيخود": 1,
    "أصخر": 1,
    "الصاخر": 1,
    "الصاخرة": 1,
    "أصدأ": 1,
    "الصدح": 1,
    "الصيداح": 1,
    "الصيدح": 1,
    "الصديد": 1,
    "صادره": 1,
    "استصدر": 1,
    "الأصدر": 1,
    "الصدار": 1,
    "الصدارة": 1,
    "الصدرة": 1,
    "الصديرة": 1,
    "صدصد": 1,
    "صدعه": 1,
    "تصدع": 1,
    "انصدع": 1,
    "الصادع": 1,
    "الصدعة": 1,
    "الصديع": 1,
    "صادغه": 1,
    "تصدغ": 1,
    "الأصدغان": 1,
    "الصداغ": 1,
    "المصدغة": 1,
    "أصدفه": 1,
    "صادفه": 1,
    "تصادفا": 1,
    "تصدف": 1,
    "الصداف الشدقي": 1,
    "الصدوف": 1,
    "صادقه": 1,
    "تصادقا": 1,
    "الصادق": 1,
    "الصادقة": 1,
    "الصداق": 1,
    "الماصدق": 1,
    "صادمه": 1,
    "اصطدما": 1,
    "تصادما": 1,
    "صدي": 1,
    "أصدى": 1,
    "صاداه": 1,
    "صرب": 1,
    "اصطرب": 1,
    "الصريب": 1,
    "المصرب": 1,
    "صرج": 1,
    "الصاروج": 1,
    "انصرح": 1,
    "تصرح": 1,
    "الصراح": 1,
    "الصرحة": 1,
    "الصريح": 1,
    "أصرخه": 1,
    "تصرخ": 1,
    "اصطرخ": 1,
    "تصارخوا": 1,
    "استصرخه": 1,
    "الصارخة": 1,
    "الصريخ": 1,
    "أصرد": 1,
    "الصردان": 1,
    "الصراد": 1,
    "الصريدة": 1,
    "المصراد": 1,
    "أصر": 1,
    "صاره": 1,
    "اصطر": 1,
    "الصار": 1,
    "الصارة": 1,
    "الصارور": 1,
    "الصارورة": 1,
    "الصرار": 1,
    "الصر": 1,
    "الصرر": 1,
    "الصراء": 1,
    "الصريرة": 1,
    "المصار": 1,
    "الصرصر": 1,
    "صرعه": 1,
    "صارعه": 1,
    "اصطرع": 1,
    "تصارع": 1,
    "الصرعة": 1,
    "الصريع": 1,
    "أصرف": 1,
    "اصطرف": 1,
    "استصرف": 1,
    "الصارف": 1,
    "الصرفان": 1,
    "الصريف": 1,
    "الصيرف": 1,
    "الصيرفي": 1,
    "صرمه": 1,
    "أصرم": 1,
    "صارمه": 1,
    "انصرم": 1,
    "تصارما": 1,
    "تصرم": 1,
    "الصرام": 1,
    "الصرم": 1,
    "الصرمة": 1,
    "الصريم": 1,
    "الصريمة": 1,
    "صرى": 1,
    "صريت": 1,
    "أصرت": 1,
    "الصرى": 1,
    "المصراة": 1,
    "المصطب": 1,
    "المصطبة": 1,
    "صعبه": 1,
    "استصعب": 1,
    "الصعبة": 1,
    "المصعب": 1,
    "أصعد": 1,
    "الصعد": 1,
    "الصعدة": 1,
    "الصعوداء": 1,
    "المصعاد": 1,
    "أصعر": 1,
    "صاعر": 1,
    "تصاعر": 1,
    "اصعرت": 1,
    "الصعر": 1,
    "صعصع": 1,
    "تصعصع": 1,
    "الصعصع": 1,
    "صعفق": 1,
    "الصعفق": 1,
    "الصعفوق": 1,
    "صعقتهم": 1,
    "أصعقه": 1,
    "الصعق": 1,
    "صعل": 1,
    "اصعال": 1,
    "الصعل": 1,
    "الصعلة": 1,
    "صعلك": 1,
    "تصعلكت": 1,
    "المصعلك": 1,
    "صغره": 1,
    "أصغى": 1,
    "الصغو": 1,
    "صفحت": 1,
    "أصفح": 1,
    "صافحه": 1,
    "تصافحا": 1,
    "استصفح": 1,
    "الصفاح": 1,
    "الصفوح": 1,
    "الصفيحة": 1,
    "المصفح": 1,
    "المصفحة": 1,
    "صفده": 1,
    "أصفده": 1,
    "الصفاد": 1,
    "الأصفران": 1,
    "الصفرية": 1,
    "الصفصف": 1,
    "صفعه": 1,
    "أصفه": 1,
    "صففه": 1,
    "تصافوا": 1,
    "الصفف": 1,
    "الصفيف": 1,
    "أصفق": 1,
    "اصطفق": 1,
    "انصفق": 1,
    "تصافق": 1,
    "تصفق": 1,
    "الصفاق": 1,
    "الصفق": 1,
    "صافن": 1,
    "تصافن": 1,
    "الصافن": 1,
    "صافاه": 1,
    "تصافيا": 1,
    "استصفاه": 1,
    "الصفاة": 1,
    "الصفواء": 1,
    "الصفوان": 1,
    "الصفوة": 1,
    "الصفي": 1,
    "الصوافي": 1,
    "أصقب": 1,
    "صاقبه": 1,
    "تصاقبت": 1,
    "الصقب": 1,
    "صقرت": 1,
    "تصقر": 1,
    "الصاقر": 1,
    "الصاقرة": 1,
    "الصاقور": 1,
    "الصاقورة": 1,
    "الصقرة": 1,
    "الصقار": 1,
    "صقعت": 1,
    "أصقع": 1,
    "الصقاع": 1,
    "الصقع": 1,
    "الصقعة": 1,
    "المصقع": 1,
    "صقله": 1,
    "الصقلة": 1,
    "الصيقل": 1,
    "المصقلة": 1,
    "الصقالبة": 1,
    "صكه": 1,
    "اصطك": 1,
    "الأصك": 1,
    "المصك": 1,
    "صكم": 1,
    "صلبت": 1,
    "اصطلب": 1,
    "الصالب": 1,
    "أصلت": 1,
    "انصلت": 1,
    "الإصليت": 1,
    "الصلتان": 1,
    "تصالج": 1,
    "الصلجة": 1,
    "الصليجة": 1,
    "الصولج": 1,
    "الصولجة": 1,
    "الصولجانة": 1,
    "اصطلح": 1,
    "تصالحوا": 1,
    "استصلح": 1,
    "الصليح": 1,
    "صلخ": 1,
    "تصالخ": 1,
    "أصلد": 1,
    "الأصلد": 1,
    "الصلود": 1,
    "المصلاد": 1,
    "المصلادة": 1,
    "الصلدم": 1,
    "تصلصل": 1,
    "الصلاصل": 1,
    "الصلصل": 1,
    "صلطحه": 1,
    "الصلطح": 1,
    "المصلطح": 1,
    "صلعت": 1,
    "تصلعت": 1,
    "الصلاع": 1,
    "الصلعة": 1,
    "الصلاعة": 1,
    "الصليع": 1,
    "صلفه": 1,
    "أصلف": 1,
    "الأصلف": 1,
    "الصلف": 1,
    "الصلفاء": 1,
    "الصليف": 1,
    "الصليفان": 1,
    "صلق": 1,
    "أصلق": 1,
    "تصلق": 1,
    "اصطلق": 1,
    "الصلاقة": 1,
    "الصلق": 1,
    "الصلاق": 1,
    "الصليق": 1,
    "المصلق": 1,
    "صلل": 1,
    "الصال": 1,
    "الصل": 1,
    "الصلال": 1,
    "صلمه": 1,
    "اصطلمه": 1,
    "الأصلم": 1,
    "الصلماء": 1,
    "الصلمة": 1,
    "الصيلم": 1,
    "المصلم": 1,
    "صليت": 1,
    "الصلا": 1,
    "صلي": 1,
    "أصلاه": 1,
    "صلاه": 1,
    "اصطلى": 1,
    "الصلى": 1,
    "الصلاء": 1,
    "الصلاية": 1,
    "الصلي": 1,
    "المصلاة": 1,
    "اصمأل": 1,
    "الصمات": 1,
    "الصمتة": 1,
    "الصموت": 1,
    "المصمت": 1,
    "صمح": 1,
    "الصماح": 1,
    "صمخه": 1,
    "الأصموخ": 1,
    "الصماخ": 1,
    "الصمخة": 1,
    "الصماخة": 1,
    "أصمد": 1,
    "صامده": 1,
    "صمده": 1,
    "تصامدا": 1,
    "الصماد": 1,
    "الصمادة": 1,
    "الصمدة": 1,
    "المصماد": 1,
    "المصمد": 1,
    "صمر": 1,
    "أصمر": 1,
    "الصامورة": 1,
    "الصمر": 1,
    "الصمير": 1,
    "الصمصام": 1,
    "الصمصامة": 1,
    "الصمصم": 1,
    "الصمصمة": 1,
    "انصمع": 1,
    "الأصمع": 1,
    "الصمع": 1,
    "الصمعاء": 1,
    "صومع": 1,
    "الصومع": 1,
    "الصومعة": 1,
    "أصمغت": 1,
    "صمغه": 1,
    "استصمغ": 1,
    "التصمغ": 1,
    "الصامغان": 1,
    "الصمغان": 1,
    "الصمغة": 1,
    "الصماغة": 1,
    "أصمله": 1,
    "الصمولة": 1,
    "الصملاخ": 1,
    "الصملوخ": 1,
    "تصام": 1,
    "الصماء": 1,
    "الصمامة": 1,
    "الصمة": 1,
    "الصمان": 1,
    "الصمانة": 1,
    "أصمى": 1,
    "الصناب": 1,
    "الصنابي": 1,
    "صنبرت": 1,
    "الصنبر": 1,
    "الصنبور": 1,
    "الصنجة": 1,
    "الصناج": 1,
    "الصندد": 1,
    "الصنديد": 1,
    "تصندل": 1,
    "الصندلاني": 1,
    "الصنارة": 1,
    "أصنع": 1,
    "صانعه": 1,
    "اصطنع": 1,
    "استصنع": 1,
    "الصنيع": 1,
    "الصنيعة": 1,
    "المصانعة": 1,
    "المصنعة": 1,
    "تصنف": 1,
    "أصنق": 1,
    "صنمت": 1,
    "الصنم": 1,
    "الصنمة": 1,
    "أصن": 1,
    "الصنان": 1,
    "أصنى": 1,
    "تصنى": 1,
    "الصناء": 1,
    "الصنو": 1,
    "صهب": 1,
    "صهبه": 1,
    "اصهب": 1,
    "الأصهب": 1,
    "الصهباء": 1,
    "الصهبة": 1,
    "صهده": 1,
    "الصيهد": 1,
    "أصهر": 1,
    "صاهر": 1,
    "اصطهر": 1,
    "انصهر": 1,
    "تصاهرا": 1,
    "الانصهار": 1,
    "الصهارة": 1,
    "الصهور": 1,
    "الصهير": 1,
    "صهرج": 1,
    "صهصه": 1,
    "صهل": 1,
    "تصاهلت": 1,
    "الصاهل": 1,
    "صهي": 1,
    "أصهى": 1,
    "صاهى": 1,
    "الصهوة": 1,
    "تصوب": 1,
    "استصاب": 1,
    "استصوبه": 1,
    "الصاب": 1,
    "الصوب": 1,
    "الصوبة": 1,
    "الصوابة": 1,
    "الصويب": 1,
    "الصيب": 1,
    "الصبوب": 1,
    "الصوبج": 1,
    "أصات": 1,
    "انصات": 1,
    "الصات": 1,
    "الصيتة": 1,
    "صاحه": 1,
    "انصاح": 1,
    "تصوح": 1,
    "الصاحة": 1,
    "الصواح": 1,
    "الصوحان": 1,
    "الصواحة": 1,
    "أصاخ": 1,
    "أصاره": 1,
    "صوره": 1,
    "التصورية": 1,
    "الصوار": 1,
    "الصير": 1,
    "صاعت": 1,
    "انصاع": 1,
    "تصوع": 1,
    "الصاعة": 1,
    "الصواع": 1,
    "صاغه": 1,
    "انصاغ": 1,
    "الصواغ": 1,
    "الصياغ": 1,
    "المصاغ": 1,
    "المصوغ": 1,
    "تصوف": 1,
    "الصوفاني": 1,
    "الصوفة": 1,
    "الصواف": 1,
    "صاك": 1,
    "تصوك": 1,
    "صاوله": 1,
    "تصاولا": 1,
    "الصؤول": 1,
    "المصولة": 1,
    "الصولج والصولجة": 1,
    "الصولجان والصولجانة": 1,
    "صومه": 1,
    "اصطام": 1,
    "الصائم": 1,
    "الصوام": 1,
    "المصام": 1,
    "اصطانه": 1,
    "تصاون": 1,
    "تصون": 1,
    "الصينة": 1,
    "صوي": 1,
    "أصوى": 1,
    "الصوة": 1,
    "صيأ": 1,
    "الصاء": 1,
    "الصيأة": 1,
    "الصببوب": 1,
    "الصيابة": 1,
    "صايح": 1,
    "الصائحة": 1,
    "أصاد": 1,
    "اصطاده": 1,
    "تصيده": 1,
    "اصيد": 1,
    "الصيود": 1,
    "المصاد": 1,
    "المصطاد": 1,
    "المصيد والمصيدة": 1,
    "صيدل": 1,
    "الصيدل": 1,
    "الصيدلاني": 1,
    "الصيدان": 1,
    "الصيدانة": 1,
    "الصيدن": 1,
    "صيره": 1,
    "الصائرة": 1,
    "الصيارة": 1,
    "الصيرة": 1,
    "الصيور": 1,
    "صاصت": 1,
    "أصاصت": 1,
    "صيصت": 1,
    "الصيص": 1,
    "الصيصاء": 1,
    "الصيصة": 1,
    "الصيصية": 1,
    "أصاعه": 1,
    "أصاف": 1,
    "صايفه": 1,
    "اصطاف": 1,
    "تصيف": 1,
    "الصائف": 1,
    "الصائفة": 1,
    "الصاف": 1,
    "المصياف": 1,
    "صاق": 1,
    "الصيق": 1,
    "صيل": 1,
    "الضئب": 1,
    "الضوبان": 1,
    "ضأزه": 1,
    "الضئزى": 1,
    "ضأضأ": 1,
    "الضأضاء": 1,
    "الضئضئ": 1,
    "ضئط": 1,
    "ضاءل": 1,
    "الضؤلان": 1,
    "أضأن": 1,
    "الضائن": 1,
    "الضئنى": 1,
    "ضأى": 1,
    "ضبأ": 1,
    "أضبأ": 1,
    "الضابئ": 1,
    "الضابئة": 1,
    "المضبأ": 1,
    "أضب": 1,
    "ضبب": 1,
    "تضبب": 1,
    "الضب": 1,
    "الضبب": 1,
    "الضبة": 1,
    "الضبوب": 1,
    "الضبيب": 1,
    "الضبيبة": 1,
    "ضبث": 1,
    "الضباث": 1,
    "ضبج": 1,
    "ضابحه": 1,
    "انضبح": 1,
    "الضباح": 1,
    "الضبح": 1,
    "الضبحاء": 1,
    "المضابح": 1,
    "المضبوحة": 1,
    "أضبر": 1,
    "الإضبارة": 1,
    "الضبارة": 1,
    "الضبارم": 1,
    "الضبارمة": 1,
    "الضبار": 1,
    "الضبر": 1,
    "الضبرة": 1,
    "الضبير": 1,
    "المضبر": 1,
    "المضبور": 1,
    "ضبسه": 1,
    "ضبس": 1,
    "الضبس": 1,
    "ضبطه": 1,
    "انضبط": 1,
    "تضبط": 1,
    "الضابطة": 1,
    "المضبطة": 1,
    "ضبعت": 1,
    "أضبعت": 1,
    "ضابعه": 1,
    "اضطبع": 1,
    "استضبعت": 1,
    "الضبعان": 1,
    "المضبعة": 1,
    "ضبنه": 1,
    "أضبن": 1,
    "اضطبن": 1,
    "الضبانة": 1,
    "الضبن": 1,
    "الضبنة": 1,
    "الضبينة": 1,
    "ضبا": 1,
    "أضبى": 1,
    "الضابي": 1,
    "أضج": 1,
    "ضاجه": 1,
    "الضجاج": 1,
    "الضجوج": 1,
    "أضجره": 1,
    "تضجر": 1,
    "الضجور": 1,
    "أضجع": 1,
    "ضاجعه": 1,
    "انضجع": 1,
    "تضاجع": 1,
    "تضجع": 1,
    "الأضجع": 1,
    "الضاجع": 1,
    "الضجعة": 1,
    "الضجعى": 1,
    "الضجوع": 1,
    "الضجيع": 1,
    "المضجوع": 1,
    "ضجم": 1,
    "تضاجم": 1,
    "الضجم": 1,
    "الضح": 1,
    "تضحضح": 1,
    "الضحضاح": 1,
    "الضحضح": 1,
    "أضحكت": 1,
    "ضاحكه": 1,
    "ضحكه": 1,
    "تضاحك": 1,
    "تضحك": 1,
    "استضحك": 1,
    "الأضحوكة": 1,
    "الضاحكة": 1,
    "الضحاك": 1,
    "الضحوك": 1,
    "الضحل": 1,
    "ضاحت": 1,
    "استضحى": 1,
    "الأضحاة": 1,
    "الإضحيان": 1,
    "الأضحية": 1,
    "الضحاء": 1,
    "الضحو": 1,
    "الضحوة": 1,
    "المضحاة": 1,
    "انضخ": 1,
    "ضخمه": 1,
    "الأضخومة": 1,
    "أضد": 1,
    "ضاده": 1,
    "الضديد": 1,
    "المتضادان": 1,
    "ضدي": 1,
    "أضدى": 1,
    "ضاداه": 1,
    "الضوادي": 1,
    "أضرب": 1,
    "ضاربه": 1,
    "تضاربا": 1,
    "تضرب": 1,
    "استضرب": 1,
    "الضروب": 1,
    "الضريب": 1,
    "المضراب": 1,
    "المضربة": 1,
    "ضرجه": 1,
    "انضرج": 1,
    "تضرج": 1,
    "الإضريج": 1,
    "الضريج": 1,
    "المضرج": 1,
    "المضروجة": 1,
    "ضرحت": 1,
    "أضرحه": 1,
    "ضارحه": 1,
    "اضطرحه": 1,
    "انضرح": 1,
    "ضراح": 1,
    "الضرح": 1,
    "الضروح": 1,
    "الضريحة": 1,
    "المضرح": 1,
    "المضرحي": 1,
    "أضرت": 1,
    "ضاره": 1,
    "اضطره": 1,
    "تضارا": 1,
    "تضرر": 1,
    "استضر": 1,
    "التضرة": 1,
    "الضرارة": 1,
    "الضرة": 1,
    "الضرير": 1,
    "المضرار": 1,
    "المضرة": 1,
    "ضرست": 1,
    "أضرسه": 1,
    "ضرسه": 1,
    "تضارس": 1,
    "تضرس": 1,
    "التضريس": 1,
    "الضروس": 1,
    "الضريس": 1,
    "المضروسة": 1,
    "أضرطه": 1,
    "الضراط": 1,
    "الضروط": 1,
    "أضرعت": 1,
    "ضارعه": 1,
    "تضارعا": 1,
    "الضارع": 1,
    "الضرعاء": 1,
    "المضارع": 1,
    "ضرغمت": 1,
    "تضرغمت": 1,
    "الضرغام": 1,
    "الضرغامة": 1,
    "الضرغم": 1,
    "ضرك": 1,
    "الضراك": 1,
    "الضريك": 1,
    "ضرمت": 1,
    "أضرم": 1,
    "اضطرمت": 1,
    "تضرمت": 1,
    "استضرم": 1,
    "الضرام": 1,
    "الضرم": 1,
    "الضرمة": 1,
    "الضريم": 1,
    "ضرى": 1,
    "ضري": 1,
    "أضراه": 1,
    "ضراه": 1,
    "استضرى": 1,
    "الضاري": 1,
    "الضرو": 1,
    "الضري": 1,
    "ضزنه": 1,
    "تضازنا": 1,
    "الضيزن": 1,
    "تضعضع": 1,
    "الضعضاع": 1,
    "الضعضع": 1,
    "ضاعفه": 1,
    "استضعفه": 1,
    "التضاعيف": 1,
    "الضعفان": 1,
    "الضعفة": 1,
    "الضعوف": 1,
    "المضاعف": 1,
    "المضعفة": 1,
    "الضغبوس": 1,
    "أضغث": 1,
    "ضغثه": 1,
    "الضغاثة": 1,
    "الضغث": 1,
    "ضغضغ": 1,
    "ضغطه": 1,
    "ضاغطه": 1,
    "تضاغطا": 1,
    "الضاغطة": 1,
    "الضغطة": 1,
    "الضغيط": 1,
    "المضغط": 1,
    "ضغمه": 1,
    "أضغم": 1,
    "الضغامة": 1,
    "الضيغم": 1,
    "أضغن": 1,
    "ضاغنه": 1,
    "اضطغن": 1,
    "تضاغنا": 1,
    "الضغن": 1,
    "الضغون": 1,
    "الضغينة": 1,
    "ضغا": 1,
    "أضغاه": 1,
    "ضغاء": 1,
    "تضاغى": 1,
    "الضاغية": 1,
    "ضافره": 1,
    "ضفره": 1,
    "انضفر": 1,
    "تضافروا": 1,
    "الضفار": 1,
    "الضفير": 1,
    "ضفز": 1,
    "اضطفز": 1,
    "الضفز": 1,
    "الضفاز": 1,
    "الضفيزة": 1,
    "ضف": 1,
    "تضافوا": 1,
    "الضف": 1,
    "الضفف": 1,
    "الضفوف": 1,
    "الضفيف": 1,
    "الضفيفة": 1,
    "المضفوف": 1,
    "ضفن": 1,
    "ضافنه": 1,
    "تضافنوا": 1,
    "الضفن": 1,
    "الضفا": 1,
    "الضفوة": 1,
    "ضكضك": 1,
    "تضكضك": 1,
    "الضكاضك": 1,
    "الضكضاك": 1,
    "ضكه": 1,
    "أضلعت": 1,
    "اضطلع": 1,
    "تضلع": 1,
    "استضلع": 1,
    "الأضلع": 1,
    "الضالع": 1,
    "الضليع": 1,
    "أضله": 1,
    "ضلله": 1,
    "تضال": 1,
    "تضلل": 1,
    "استضل": 1,
    "الأضلولة": 1,
    "الضال": 1,
    "الضالة": 1,
    "الضلل": 1,
    "الضلة": 1,
    "الضليل": 1,
    "الضلول": 1,
    "المضل": 1,
    "المضلة": 1,
    "ضمخه": 1,
    "تضمخ": 1,
    "الضمخة": 1,
    "أضمد": 1,
    "ضمده": 1,
    "تضمد": 1,
    "الضماد": 1,
    "الضمد": 1,
    "المضمدة": 1,
    "أضمرت": 1,
    "ضمره": 1,
    "اضطمر": 1,
    "انضمر": 1,
    "تضمر": 1,
    "الإضمار": 1,
    "الضامر": 1,
    "الضمار": 1,
    "الضمر": 1,
    "الضومران والضيمران": 1,
    "المضمار": 1,
    "ضمز": 1,
    "الضمز": 1,
    "ضمسه": 1,
    "ضمضم": 1,
    "الضماضم": 1,
    "الضمضام": 1,
    "الضمضم": 1,
    "الضميلة": 1,
    "ضامه": 1,
    "اضطم": 1,
    "الإضمامة": 1,
    "الضمام": 1,
    "الضمامة": 1,
    "الضميم": 1,
    "أضمنه": 1,
    "تضامنوا": 1,
    "الضامنة": 1,
    "الضمانة": 1,
    "الضمن": 1,
    "الضمين": 1,
    "المضمان": 1,
    "ضنأت": 1,
    "أضنأت": 1,
    "الضنء": 1,
    "ضنكه": 1,
    "أضنكه": 1,
    "تضنك": 1,
    "الضناك": 1,
    "الضنك": 1,
    "الضنكة": 1,
    "الضنيك": 1,
    "الضنانة": 1,
    "الضنن": 1,
    "الضن": 1,
    "الضنين": 1,
    "المضنة": 1,
    "المضنون والمضنونة": 1,
    "ضنت": 1,
    "ضني": 1,
    "أضنى": 1,
    "ضانى": 1,
    "تضنى": 1,
    "الضنى": 1,
    "الضني": 1,
    "ضاهأه": 1,
    "الضهبأة": 1,
    "ضهب": 1,
    "ضاهيه": 1,
    "الضهباء": 1,
    "ضهده": 1,
    "أضهده": 1,
    "اضطهده": 1,
    "الضهدة": 1,
    "الضاهر": 1,
    "الضهر": 1,
    "ضهل": 1,
    "أضهل": 1,
    "تضهل": 1,
    "استضهل": 1,
    "الضاهلة": 1,
    "الضهل": 1,
    "الضهول": 1,
    "ضهيت": 1,
    "أضهى": 1,
    "ضاهاه": 1,
    "الضهواء": 1,
    "الضهوة": 1,
    "الضهياء": 1,
    "الضهي": 1,
    "ضوأ": 1,
    "تضوأ": 1,
    "استضاء": 1,
    "ضاج": 1,
    "انضاج": 1,
    "انضوج": 1,
    "تضوج": 1,
    "الضوج": 1,
    "تضور": 1,
    "الضورة": 1,
    "ضوضأ": 1,
    "الضوضى": 1,
    "ضوط": 1,
    "الضويطة": 1,
    "الضوطار": 1,
    "الضوطر": 1,
    "الضوطرى": 1,
    "أبو ضوطرى": 1,
    "ضوعه": 1,
    "انضاع": 1,
    "تضوع": 1,
    "الضواع": 1,
    "الضوع": 1,
    "ضان": 1,
    "تضون": 1,
    "الضون": 1,
    "الضيون": 1,
    "ضوي": 1,
    "ضويت": 1,
    "أضوى": 1,
    "انضوى": 1,
    "الضواة": 1,
    "تضبح": 1,
    "ضاز": 1,
    "الضيزى": 1,
    "أضاع": 1,
    "ضيعه": 1,
    "المضياع": 1,
    "المضيعة": 1,
    "انضاف": 1,
    "تضايف": 1,
    "تضيف": 1,
    "التضايف": 1,
    "الضيفن": 1,
    "المضوفة": 1,
    "المضياف": 1,
    "أضاق": 1,
    "ضايقه": 1,
    "ضيقه": 1,
    "أضال": 1,
    "استضامه": 1,
    "الضيم": 1,
    "أطبعه": 1,
    "انطبع": 1,
    "الطبيع": 1,
    "المطبوع": 1,
    "أطبق": 1,
    "تطابقا": 1,
    "تطبق": 1,
    "الطباق": 1,
    "الطباقاء": 1,
    "المطبقية": 1,
    "التطبل": 1,
    "الطبالة": 1,
    "الطبلية": 1,
    "طابنه": 1,
    "الطابون": 1,
    "الطبن": 1,
    "الطبنة": 1,
    "طباه": 1,
    "طبيت": 1,
    "اطباه": 1,
    "الطبواء": 1,
    "الطبوغرافيا": 1,
    "طثه": 1,
    "الطثرة": 1,
    "طجن": 1,
    "طجنه": 1,
    "الطاجن": 1,
    "المطجن": 1,
    "طحه": 1,
    "أطحه": 1,
    "انطح": 1,
    "طحر": 1,
    "الطحار": 1,
    "الطحور": 1,
    "تطحطح": 1,
    "طحله": 1,
    "الأطحل": 1,
    "الطحل": 1,
    "الطحلاء": 1,
    "الطحلة": 1,
    "طحنه": 1,
    "انطحن": 1,
    "تطحن": 1,
    "الطاحنة": 1,
    "الطحانة": 1,
    "الطحنة": 1,
    "الطحون": 1,
    "الطحينة": 1,
    "المطحان": 1,
    "المطحن": 1,
    "طحا": 1,
    "طحى": 1,
    "تطحى": 1,
    "الطحا": 1,
    "المطحية": 1,
    "طخ": 1,
    "المطخة": 1,
    "طخا": 1,
    "الطخاء": 1,
    "الطخواء": 1,
    "الطخياء": 1,
    "الطخية": 1,
    "طرؤ": 1,
    "طرأه": 1,
    "الطارئ": 1,
    "الطارئة": 1,
    "الطرآني": 1,
    "أطربه": 1,
    "تطرب": 1,
    "استطرب": 1,
    "الطروب": 1,
    "الطربيد": 1,
    "الطربوش": 1,
    "طربل": 1,
    "الطربال": 1,
    "الطربيل": 1,
    "الطرثوث": 1,
    "اطرحه": 1,
    "انطرح": 1,
    "تطارحا": 1,
    "تطرح": 1,
    "الطراح": 1,
    "الطرحة": 1,
    "الطروح": 1,
    "الطريح": 1,
    "المطرح": 1,
    "المطرحة": 1,
    "الطرخون": 1,
    "الطريخ": 1,
    "أطرده": 1,
    "طارده": 1,
    "اطرد": 1,
    "تطارد": 1,
    "الطرادة": 1,
    "الطريد": 1,
    "الطريدة": 1,
    "المطردة": 1,
    "الطر": 1,
    "الطرة": 1,
    "الطرار": 1,
    "الطرور": 1,
    "الطرير": 1,
    "طرزه": 1,
    "تطرز": 1,
    "الطرازة": 1,
    "الطرزي": 1,
    "طرسه": 1,
    "تطرس": 1,
    "الطرس": 1,
    "تطارش": 1,
    "الأطرش": 1,
    "الأطروش": 1,
    "الطرطر": 1,
    "الطرطور": 1,
    "الطراطير": 1,
    "أطرف": 1,
    "استطرفه": 1,
    "الأطروفة": 1,
    "التطريف": 1,
    "الطارف": 1,
    "الطارفة": 1,
    "الطراف": 1,
    "الطرفاء": 1,
    "الطريف": 1,
    "المطرف": 1,
    "المطرفة": 1,
    "المطروف": 1,
    "المطروفة": 1,
    "أطرق": 1,
    "طرقت": 1,
    "انطرق": 1,
    "تطارق": 1,
    "تطرق": 1,
    "استطرق": 1,
    "الطارقة": 1,
    "الطراق": 1,
    "المستطرقة": 1,
    "المطراق": 1,
    "المطرق": 1,
    "المطروق": 1,
    "المنطرقات": 1,
    "طرمت": 1,
    "أطرمت": 1,
    "تطرم": 1,
    "الطارمة": 1,
    "الطرامة": 1,
    "الطرم": 1,
    "الطرمة": 1,
    "الطرنشول": 1,
    "أطراه": 1,
    "طراه": 1,
    "الإطرية": 1,
    "الطري": 1,
    "المطراة": 1,
    "الطازج": 1,
    "الطزاجة": 1,
    "الطست": 1,
    "طسس": 1,
    "الطاسة": 1,
    "الطساسة": 1,
    "الطس": 1,
    "الطساس": 1,
    "الطسان": 1,
    "الطسة": 1,
    "الطشت": 1,
    "طش": 1,
    "أطشت": 1,
    "الطشاش": 1,
    "الطش": 1,
    "الطشيش": 1,
    "أطعمت": 1,
    "طاعمه": 1,
    "اطعمت": 1,
    "تطاعما": 1,
    "تطعم": 1,
    "استطعم": 1,
    "الطعامي": 1,
    "الطعمة": 1,
    "الطعمية": 1,
    "الطعومة": 1,
    "الطعيم": 1,
    "المطعام": 1,
    "المطعمة": 1,
    "طاعنه": 1,
    "اطعنا": 1,
    "تطاعنا": 1,
    "الطعان": 1,
    "الطعين": 1,
    "المطعان": 1,
    "المطعن": 1,
    "الطغراء": 1,
    "الطغرى والطغرى": 1,
    "الطغرائي": 1,
    "تطغم": 1,
    "الطغام": 1,
    "الطغامة": 1,
    "الطغم": 1,
    "الطغومة": 1,
    "الطغومية": 1,
    "أطغاه": 1,
    "تطاغى": 1,
    "الطغوى": 1,
    "الطغيان": 1,
    "الطغية": 1,
    "طفئت": 1,
    "طفأ": 1,
    "انطفأ": 1,
    "المطفئ": 1,
    "المطفئة": 1,
    "أطفحه": 1,
    "طفحه": 1,
    "اطفح": 1,
    "الطفاحة": 1,
    "الطفحان": 1,
    "المطفحة": 1,
    "أطفر": 1,
    "اطفر": 1,
    "الطفرة": 1,
    "طفس": 1,
    "طفطف": 1,
    "الطفطاف": 1,
    "الطفطفة": 1,
    "أطف": 1,
    "طفف": 1,
    "استطف": 1,
    "الطافة": 1,
    "الطفاف": 1,
    "الطفافة": 1,
    "الطفيف": 1,
    "أطفقه": 1,
    "طفلت": 1,
    "أطفلت": 1,
    "الطفال": 1,
    "الطفولية": 1,
    "الطفيل": 1,
    "المطفل": 1,
    "أطفى": 1,
    "الطفاوة": 1,
    "الطفية": 1,
    "أطلب": 1,
    "طالبه": 1,
    "اطلبه": 1,
    "تطلبه": 1,
    "الطلابة": 1,
    "الطلوب": 1,
    "الطليب": 1,
    "أطلح": 1,
    "الطلح": 1,
    "الطليح": 1,
    "طلسه": 1,
    "انطلس": 1,
    "تطلس": 1,
    "الطالسان": 1,
    "الطلس": 1,
    "الطلساء": 1,
    "الطلسة": 1,
    "الطلاسة": 1,
    "الطليس": 1,
    "الطيلس": 1,
    "الطيلسان": 1,
    "الطلسم": 1,
    "طلطل": 1,
    "الطلاطل": 1,
    "الطلاطلة": 1,
    "الطلطل": 1,
    "اطلع": 1,
    "الطالعة": 1,
    "الطلاع": 1,
    "أطلف": 1,
    "طلف": 1,
    "الطلف": 1,
    "الطليف": 1,
    "طلقه": 1,
    "الطالق": 1,
    "الطالقة": 1,
    "الطليق": 1,
    "المطلاق": 1,
    "تطال": 1,
    "تطللت": 1,
    "استطل": 1,
    "الطلالة": 1,
    "الطلل": 1,
    "الطلة": 1,
    "الطليل": 1,
    "المطل": 1,
    "المطلول": 1,
    "طلمه": 1,
    "الطلم": 1,
    "الطلمة": 1,
    "المطلمة": 1,
    "اطلمس": 1,
    "الطلمساء": 1,
    "طليت": 1,
    "أطلت": 1,
    "الطلا": 1,
    "الطلاة": 1,
    "الطلاوة": 1,
    "الطلو": 1,
    "الطلواء": 1,
    "الطلوان": 1,
    "الطلوة": 1,
    "اطلى": 1,
    "تطلى": 1,
    "الطلى": 1,
    "الطلياء": 1,
    "الطلية": 1,
    "الطليا": 1,
    "المطلى": 1,
    "المطلاء": 1,
    "طمثت": 1,
    "أطمحه": 1,
    "الطامح": 1,
    "الطمحة": 1,
    "الطماح": 1,
    "الطماحة": 1,
    "طمرت": 1,
    "أطمره": 1,
    "طمره": 1,
    "اطمر": 1,
    "الطامر": 1,
    "الطامور": 1,
    "طمار": 1,
    "طمرة": 1,
    "الطومار": 1,
    "المطمار": 1,
    "المطمر": 1,
    "المطمورة": 1,
    "طمسه": 1,
    "تطمس": 1,
    "الطامس": 1,
    "الطميس": 1,
    "طمطم": 1,
    "الطمطام": 1,
    "الطمطماني": 1,
    "الطمطمانية": 1,
    "الطمطم": 1,
    "الطمطمة": 1,
    "الطمطمي": 1,
    "أطمعه": 1,
    "طمعه": 1,
    "تطمع": 1,
    "الطموع": 1,
    "المطماع": 1,
    "المطمع": 1,
    "المطمعة": 1,
    "طمل": 1,
    "أطمل": 1,
    "اطمل": 1,
    "انطمل": 1,
    "الطمل": 1,
    "الطملة": 1,
    "الطمول": 1,
    "الطميل": 1,
    "الطميلة": 1,
    "المطملة": 1,
    "طمم": 1,
    "استطم": 1,
    "الطام": 1,
    "الطمة": 1,
    "الطموم": 1,
    "الطميم": 1,
    "طمأنه": 1,
    "طنأ": 1,
    "طنئ": 1,
    "أطنأ": 1,
    "الطنء": 1,
    "أطنب": 1,
    "طانبه": 1,
    "تطانبا": 1,
    "الإطنابة": 1,
    "الطنب": 1,
    "الطنيب": 1,
    "المطناب": 1,
    "المطنب": 1,
    "الطنبور": 1,
    "طنبل": 1,
    "الطنبل": 1,
    "طنثر": 1,
    "تطنثر": 1,
    "تطنج": 1,
    "الطنج": 1,
    "الطنجرة": 1,
    "الطنجير": 1,
    "طنخ": 1,
    "أطنخه": 1,
    "طنخه": 1,
    "طنز": 1,
    "طانزه": 1,
    "تطانزوا": 1,
    "الطناز": 1,
    "المطنزة": 1,
    "الطنطان": 1,
    "أطنف": 1,
    "تطنف": 1,
    "طنفس": 1,
    "الطنفس": 1,
    "الطنفسة": 1,
    "طنفش": 1,
    "الطنفش": 1,
    "أطنه": 1,
    "طنن": 1,
    "الطني": 1,
    "طني": 1,
    "أطنى": 1,
    "اطنى": 1,
    "الطنى": 1,
    "اطهر": 1,
    "تطهر": 1,
    "الطاهر": 1,
    "الطهر": 1,
    "الطهور": 1,
    "الطهورية": 1,
    "المطهرة": 1,
    "طهش": 1,
    "طهم": 1,
    "تطهم": 1,
    "الطهم": 1,
    "الطهمة": 1,
    "المطهم": 1,
    "تطهمل": 1,
    "الطهمل": 1,
    "الطهملي": 1,
    "طها": 1,
    "أطهى": 1,
    "الطاهي": 1,
    "الطهاوة": 1,
    "الطهاية": 1,
    "الطهيان": 1,
    "الطواب": 1,
    "طوبة": 1,
    "أطاحه": 1,
    "طاوحه": 1,
    "طوحه": 1,
    "تطاوح": 1,
    "تطوح": 1,
    "المطاح": 1,
    "المطاحة": 1,
    "المطواح": 1,
    "طاد": 1,
    "انطاد": 1,
    "تطود": 1,
    "الطاد": 1,
    "الطود": 1,
    "ابن الطود": 1,
    "المطاد": 1,
    "المطادة": 1,
    "الطوار": 1,
    "الطوراني": 1,
    "الطورة": 1,
    "تطوس": 1,
    "الطاس": 1,
    "الطاءوس": 1,
    "الطواس": 1,
    "الطوس": 1,
    "الطواشي": 1,
    "طاوعه": 1,
    "تطاوع": 1,
    "الطواعية": 1,
    "الطوع": 1,
    "الطيع": 1,
    "المطاع": 1,
    "المطواع": 1,
    "المطوع": 1,
    "أطاف": 1,
    "اطاف": 1,
    "تطوف": 1,
    "استطاف": 1,
    "الطائفي": 1,
    "المطوف": 1,
    "طاقه": 1,
    "أطاقه": 1,
    "تطوق": 1,
    "الطائق": 1,
    "الطاق": 1,
    "الطوقة": 1,
    "المطوق": 1,
    "المطوقة": 1,
    "أطال": 1,
    "أطوله": 1,
    "طاول": 1,
    "تطول": 1,
    "استطال": 1,
    "الطائل": 1,
    "الطائلة": 1,
    "الطوال": 1,
    "الطوالة": 1,
    "الطيل والطيل والطيلة": 1,
    "أطوى": 1,
    "طواه": 1,
    "اطوى": 1,
    "تطوى": 1,
    "الطاية": 1,
    "المطوى": 1,
    "أطاب": 1,
    "طايبه": 1,
    "تطايبا": 1,
    "تطيب": 1,
    "الأطيب": 1,
    "الأطيبان": 1,
    "الطاب": 1,
    "الطابة": 1,
    "المطايب": 1,
    "المطيبة": 1,
    "المطيبون": 1,
    "طيحه": 1,
    "تطايح": 1,
    "الطيح": 1,
    "الطيحة": 1,
    "طاخ": 1,
    "طيخ": 1,
    "تطيخ": 1,
    "الطائخ": 1,
    "الطيخة": 1,
    "أطار": 1,
    "طايره": 1,
    "طيره": 1,
    "انطار": 1,
    "تطاير": 1,
    "استطار": 1,
    "الطيرة": 1,
    "الطيرة والطيرة": 1,
    "الطيرية": 1,
    "المطارة": 1,
    "الطيس": 1,
    "أطاشه": 1,
    "الطائش": 1,
    "الطياش": 1,
    "أطافه": 1,
    "تطيف": 1,
    "الطياف": 1,
    "طان": 1,
    "أطانه": 1,
    "طينه": 1,
    "تطين": 1,
    "الطيانة": 1,
    "الطينة": 1,
    "الطيان": 1,
    "الظاء": 1,
    "ظأب": 1,
    "ظاءب": 1,
    "تظاءب": 1,
    "الظأب": 1,
    "ظأرت": 1,
    "أظأرها": 1,
    "ظاءر": 1,
    "اظأرت": 1,
    "الظأر": 1,
    "الظئر": 1,
    "الظئرة": 1,
    "ظأفه": 1,
    "الظبأة": 1,
    "ظبظب": 1,
    "الظبظاب": 1,
    "الظبظبة": 1,
    "الظبة": 1,
    "أظبت": 1,
    "المظباة": 1,
    "ظرب": 1,
    "الظرب": 1,
    "أظر": 1,
    "الظر": 1,
    "الظرر": 1,
    "الظري": 1,
    "أظرف": 1,
    "ظارفه": 1,
    "تظارف": 1,
    "استظرفه": 1,
    "الظرفية": 1,
    "المظروف": 1,
    "أظعنه": 1,
    "الظعان": 1,
    "الظعنة": 1,
    "الظعينة": 1,
    "أظفر": 1,
    "تظافروا": 1,
    "أظفار": 1,
    "الأظفور": 1,
    "الظفرة": 1,
    "المظفار": 1,
    "المظفر": 1,
    "الظالع": 1,
    "الظلاع": 1,
    "الظلع": 1,
    "ظلفه": 1,
    "ظلفت": 1,
    "أظلف": 1,
    "الظلفات": 1,
    "الظليف": 1,
    "الظليفة": 1,
    "أظل": 1,
    "تظلل": 1,
    "استظل": 1,
    "الأظل": 1,
    "الظلالة": 1,
    "الظلل": 1,
    "الظليل": 1,
    "الظليلة": 1,
    "ظالمه": 1,
    "تظالم": 1,
    "انظلم": 1,
    "الظلامة": 1,
    "الظلماء": 1,
    "الظليم": 1,
    "الظليمة": 1,
    "المظلام": 1,
    "المظلم": 1,
    "أظمأه": 1,
    "الظمء": 1,
    "الظمأى": 1,
    "المظماء": 1,
    "ظميت": 1,
    "الأظمى": 1,
    "الظنب": 1,
    "الظنبوب": 1,
    "اظنه": 1,
    "تظنن": 1,
    "الظنانة": 1,
    "الظنة": 1,
    "الظنين": 1,
    "تظاهروا": 1,
    "الظهار": 1,
    "الظهرة": 1,
    "الظهري": 1,
    "اعتبأ": 1,
    "العباء": 1,
    "المعبأ": 1,
    "تعبب": 1,
    "العباب": 1,
    "العب": 1,
    "العبية": 1,
    "العبيثة": 1,
    "العبيثران والعبوثران": 1,
    "عبد الله": 1,
    "اعتبده": 1,
    "استعبده": 1,
    "العبابيد": 1,
    "عباد الشمس": 1,
    "العبدة": 1,
    "العبدية": 1,
    "العبودة": 1,
    "المتعبد": 1,
    "العبادلة": 1,
    "العبدل": 1,
    "العبدلي": 1,
    "اعتبر": 1,
    "استعبر": 1,
    "العابرة": 1,
    "العبر": 1,
    "المعابير": 1,
    "المعبرة": 1,
    "تعبس": 1,
    "العبس": 1,
    "أعبطه": 1,
    "اعتبط": 1,
    "العابط": 1,
    "العبطة": 1,
    "العبيط": 1,
    "العباطة": 1,
    "تعبق": 1,
    "العباقية": 1,
    "العبق": 1,
    "العبقة": 1,
    "العبقر": 1,
    "العبقرة": 1,
    "العبقرية": 1,
    "أعبل": 1,
    "الأعبل": 1,
    "العابل": 1,
    "العبال": 1,
    "العبالة": 1,
    "العبل": 1,
    "العبلاء": 1,
    "عبول": 1,
    "المعبلة": 1,
    "العباهر": 1,
    "العبهر": 1,
    "العبهرة": 1,
    "عبا": 1,
    "عبى": 1,
    "العابية": 1,
    "العبابة": 1,
    "أعتبه": 1,
    "عاتبه": 1,
    "تعاتبوا": 1,
    "تعتب": 1,
    "اعتتب": 1,
    "استعتب": 1,
    "الأعتوبة": 1,
    "العتب": 1,
    "العتبى": 1,
    "العتوب": 1,
    "عت": 1,
    "عاته": 1,
    "تعتت": 1,
    "أعتد": 1,
    "تعتد": 1,
    "العتاد": 1,
    "العتد": 1,
    "العتود": 1,
    "العتيد": 1,
    "العتيدة": 1,
    "عتر": 1,
    "العتار": 1,
    "العتر": 1,
    "العترة": 1,
    "العتيرة": 1,
    "المعتر": 1,
    "عترس": 1,
    "العتريس": 1,
    "العاتق": 1,
    "العاتك": 1,
    "العاتكة": 1,
    "العتيك": 1,
    "عتله": 1,
    "انعتل": 1,
    "تعتل": 1,
    "العتال": 1,
    "العتل": 1,
    "العتيل": 1,
    "المعتل": 1,
    "أعتم": 1,
    "استعتمه": 1,
    "العاتم": 1,
    "العتم": 1,
    "تعته": 1,
    "العتاه الشللي": 1,
    "العتاهة": 1,
    "العتاهية": 1,
    "المعته": 1,
    "تعتى": 1,
    "العاتي": 1,
    "عثت": 1,
    "عاث": 1,
    "اعتثه": 1,
    "العثاء": 1,
    "العثيثة": 1,
    "أعثر": 1,
    "عثره": 1,
    "العاثر": 1,
    "العاثور": 1,
    "العثار": 1,
    "العثر": 1,
    "العثري": 1,
    "العثور": 1,
    "العثير": 1,
    "العثيرة": 1,
    "عثلت": 1,
    "عثل": 1,
    "العثول": 1,
    "العثولية": 1,
    "عثم": 1,
    "أعثم": 1,
    "اعتثم": 1,
    "العاثم": 1,
    "العثمان": 1,
    "العيثم": 1,
    "العيثوم": 1,
    "عثنت": 1,
    "عثن": 1,
    "العثان": 1,
    "العثن": 1,
    "المعثن": 1,
    "المعثون": 1,
    "العثنون": 1,
    "الأعثى": 1,
    "العثوة": 1,
    "أعجبه": 1,
    "عجبه": 1,
    "استعجب": 1,
    "التعاجيب": 1,
    "العجاب": 1,
    "العجيب": 1,
    "أعجت": 1,
    "عجج": 1,
    "تعجج": 1,
    "العجاج": 1,
    "العجاجة": 1,
    "العجة": 1,
    "المعجاج": 1,
    "عاجر": 1,
    "اعتجر": 1,
    "تعجر": 1,
    "الأعجر": 1,
    "العجار": 1,
    "العجراء": 1,
    "العجرة": 1,
    "تعجرف": 1,
    "العجرفة": 1,
    "عجارف": 1,
    "عجاريف": 1,
    "العجرفية": 1,
    "عجزت": 1,
    "أعجز": 1,
    "العجازة": 1,
    "العجوزة": 1,
    "المعجاز": 1,
    "العجعاج": 1,
    "العجعجة": 1,
    "عجف": 1,
    "أعجف": 1,
    "الأعجف": 1,
    "العجفاء": 1,
    "أعجلت": 1,
    "المعجال": 1,
    "أعجم": 1,
    "تعاجم": 1,
    "استعجم": 1,
    "الأعجم": 1,
    "الأعجمي": 1,
    "العجام": 1,
    "العجامة": 1,
    "العجماء": 1,
    "أعجن": 1,
    "اعتجن": 1,
    "عاجنه": 1,
    "العجان": 1,
    "المعجن": 1,
    "المعجنة": 1,
    "عجت": 1,
    "عجي": 1,
    "عاجى": 1,
    "عجى": 1,
    "العجاوة": 1,
    "العجوة": 1,
    "العجي": 1,
    "عاده": 1,
    "تعاد": 1,
    "استعد": 1,
    "التعداد": 1,
    "العدان": 1,
    "العديدة": 1,
    "المعداد": 1,
    "المعدودات": 1,
    "العدوس": 1,
    "العديسة": 1,
    "اعتدل": 1,
    "تعادلا": 1,
    "العديل": 1,
    "العديلتان": 1,
    "العديم": 1,
    "المعدوم": 1,
    "العدانة": 1,
    "أعداه": 1,
    "عاداه": 1,
    "تعادوا": 1,
    "استعداه": 1,
    "العدى": 1,
    "العدواء": 1,
    "العدوان": 1,
    "العدوة": 1,
    "العدي": 1,
    "المتعدي": 1,
    "المعدى": 1,
    "المعدية": 1,
    "العدولية": 1,
    "عذبه": 1,
    "اعتذب": 1,
    "استعذب": 1,
    "اعذوذب": 1,
    "العذبة": 1,
    "أعذر": 1,
    "استعذر": 1,
    "العاذر": 1,
    "العاذور": 1,
    "العذرة": 1,
    "العذير": 1,
    "العذيرة": 1,
    "المعذرة": 1,
    "عذف": 1,
    "العذوف": 1,
    "العذق": 1,
    "العذقة": 1,
    "عذله": 1,
    "اعتذل": 1,
    "تعاذلوا": 1,
    "تعذل": 1,
    "العاذل": 1,
    "العذلة": 1,
    "استعدى": 1,
    "العذاة": 1,
    "العذي": 1,
    "العذية": 1,
    "تعرب": 1,
    "استعرب": 1,
    "التعريب": 1,
    "العاربة": 1,
    "العراب": 1,
    "العرباء": 1,
    "العرباني": 1,
    "العربين": 1,
    "العروب": 1,
    "العروبة": 1,
    "العروبية": 1,
    "العريب": 1,
    "المتعربة": 1,
    "المستعربة": 1,
    "عربنه": 1,
    "انعرج": 1,
    "تعارج": 1,
    "التعاريج": 1,
    "العريج": 1,
    "عرجن": 1,
    "العرجون": 1,
    "عرد": 1,
    "أعرد": 1,
    "العرادة": 1,
    "العرد": 1,
    "عرت": 1,
    "اعتر": 1,
    "تعار": 1,
    "استعرهم": 1,
    "العرار": 1,
    "العرارة": 1,
    "العر": 1,
    "العرى": 1,
    "العرة": 1,
    "العرير": 1,
    "المعرة": 1,
    "العرزال": 1,
    "أعرس": 1,
    "تعرس": 1,
    "عرائس النيل": 1,
    "العريسة": 1,
    "المعرس": 1,
    "تعرش": 1,
    "عرصت": 1,
    "أعرص": 1,
    "اعترص": 1,
    "العراص": 1,
    "العرصة": 1,
    "تعارضا": 1,
    "استعرض": 1,
    "العراضة": 1,
    "المعراض": 1,
    "العرطنيثا": 1,
    "عرعرة": 1,
    "أعرف": 1,
    "تعارفوا": 1,
    "استعرف": 1,
    "العارفة": 1,
    "العرفة": 1,
    "العرفط": 1,
    "أعرق": 1,
    "عارقه": 1,
    "اعترق": 1,
    "استعرق": 1,
    "العرقاة": 1,
    "العرقة": 1,
    "العرقوتان": 1,
    "العريق": 1,
    "المعرق": 1,
    "تعرقب": 1,
    "تعرقل": 1,
    "عاركه": 1,
    "اعتركوا": 1,
    "تعاركوا": 1,
    "العراك": 1,
    "العركة": 1,
    "العركي": 1,
    "العريك": 1,
    "المعترك": 1,
    "المعرك": 1,
    "عارمه": 1,
    "اعترم": 1,
    "تعرم": 1,
    "العارم": 1,
    "العرام": 1,
    "العرمة": 1,
    "العريم": 1,
    "العرمرم": 1,
    "عرنت": 1,
    "أعرن": 1,
    "عارن": 1,
    "العارن": 1,
    "العران": 1,
    "العرن": 1,
    "العرنين": 1,
    "العرناس": 1,
    "عراه": 1,
    "أعرى": 1,
    "اعتراه": 1,
    "العرو": 1,
    "العرواء": 1,
    "العرية": 1,
    "عارى": 1,
    "اعرورى": 1,
    "العارية": 1,
    "العريان": 1,
    "عزبه": 1,
    "تعزب": 1,
    "الأعزب": 1,
    "العازبة": 1,
    "العزيب": 1,
    "المعزابة": 1,
    "عزره": 1,
    "التعزير": 1,
    "أعزه": 1,
    "عازه": 1,
    "عززه": 1,
    "اعتز": 1,
    "تعزز": 1,
    "استعز": 1,
    "العزاز": 1,
    "عزفت": 1,
    "تعازفوا": 1,
    "العزاف": 1,
    "العزوف": 1,
    "العزيف": 1,
    "المعزف": 1,
    "المعزفة": 1,
    "أعزق": 1,
    "العزيق": 1,
    "المعزق": 1,
    "عزله": 1,
    "انعزل": 1,
    "تعازل": 1,
    "العزلاء": 1,
    "المعزال": 1,
    "اعتزم": 1,
    "تعزم": 1,
    "العزام": 1,
    "العزمة": 1,
    "العزمي": 1,
    "عزاه": 1,
    "اعتزى": 1,
    "تعازى": 1,
    "تعزى": 1,
    "العزية": 1,
    "عسبه": 1,
    "أعسب": 1,
    "استعسب": 1,
    "العسب": 1,
    "العسبة": 1,
    "العسيب": 1,
    "عسيبة": 1,
    "اليعسوب": 1,
    "العوسج": 1,
    "العسجد": 1,
    "عاسره": 1,
    "اعتسره": 1,
    "تعاسر": 1,
    "استعسر": 1,
    "العسراء": 1,
    "العسرى": 1,
    "المعسر": 1,
    "المعسرة": 1,
    "المعسور": 1,
    "اعتس": 1,
    "العاس": 1,
    "العساس": 1,
    "العسوس": 1,
    "العسيس": 1,
    "المعس": 1,
    "تعسعس": 1,
    "العسعاس": 1,
    "أعسف": 1,
    "عسفه": 1,
    "اعتسف": 1,
    "انعسف": 1,
    "التعاسيف": 1,
    "العسيف": 1,
    "تعسق": 1,
    "العسق": 1,
    "العسيقة": 1,
    "العسقول": 1,
    "عسلت": 1,
    "استعسل": 1,
    "العاسل": 1,
    "العاسلة": 1,
    "العسال": 1,
    "العسالة": 1,
    "العسل الأسود": 1,
    "العسلة": 1,
    "العسلي": 1,
    "العسيل": 1,
    "المعسلة": 1,
    "عسلجت": 1,
    "العسلاج": 1,
    "العسلوج": 1,
    "عسم": 1,
    "عسمت": 1,
    "أعسمت": 1,
    "اعتسم": 1,
    "العسم": 1,
    "العسمة": 1,
    "العسمي": 1,
    "المعسم": 1,
    "عست": 1,
    "أعسى": 1,
    "العسي": 1,
    "أعشب": 1,
    "اعتشبت": 1,
    "تعشبت": 1,
    "اعشوشب": 1,
    "التعاشيب": 1,
    "العاشب": 1,
    "المعشاب": 1,
    "عشرن": 1,
    "أعشرت": 1,
    "عاشره": 1,
    "اعتشر": 1,
    "تعاشروا": 1,
    "العاشور": 1,
    "العشارة": 1,
    "العشاري": 1,
    "العشراء": 1,
    "المعشار": 1,
    "أعش": 1,
    "اعتش": 1,
    "انعش": 1,
    "المعش": 1,
    "المعشة": 1,
    "العشعش": 1,
    "عشقه": 1,
    "تعشق": 1,
    "العشقة": 1,
    "عشم": 1,
    "تعشم": 1,
    "الأعشم": 1,
    "العشمة": 1,
    "عشي": 1,
    "أعشى": 1,
    "عشاه": 1,
    "اعتشى": 1,
    "تعاشى": 1,
    "تعشى": 1,
    "استعشاه": 1,
    "العشواء": 1,
    "العشوة": 1,
    "العشي": 1,
    "العشية": 1,
    "عصبت": 1,
    "عصبه": 1,
    "اعتصب": 1,
    "انعصب": 1,
    "اعصوصب": 1,
    "العصاب": 1,
    "العصبة": 1,
    "العصيب": 1,
    "المعصب": 1,
    "المعصوب": 1,
    "المعصوبة": 1,
    "عصد": 1,
    "أعصد": 1,
    "المعصد": 1,
    "انعصر": 1,
    "تعصر": 1,
    "العصار": 1,
    "المعصار": 1,
    "المعصر": 1,
    "المعصرة": 1,
    "العصعصة": 1,
    "العصعوص": 1,
    "عصفت": 1,
    "أعصفت": 1,
    "العاصف": 1,
    "العصافة": 1,
    "العصفة": 1,
    "العصيفة": 1,
    "تعصفر": 1,
    "العصفورة": 1,
    "العصفوري": 1,
    "العصفورية": 1,
    "العصل": 1,
    "العصلاء": 1,
    "المعصال": 1,
    "المعصل": 1,
    "عصلب": 1,
    "العصلب": 1,
    "العصلبي": 1,
    "عصلج": 1,
    "العصلج": 1,
    "أعصم": 1,
    "اعتصم": 1,
    "انعصم": 1,
    "استعصم": 1,
    "العصام": 1,
    "العصامي": 1,
    "المعصم": 1,
    "أعصى": 1,
    "عاصاه": 1,
    "اعتصى": 1,
    "العصية": 1,
    "اعتصت": 1,
    "تعصى": 1,
    "استعصى": 1,
    "أعضب": 1,
    "عاضبه": 1,
    "انعضب": 1,
    "الأعضب": 1,
    "العضاب": 1,
    "عضبر": 1,
    "العضبارة": 1,
    "عاضده": 1,
    "اعتضد": 1,
    "تعضده": 1,
    "استعضد": 1,
    "الأعضد": 1,
    "العضاد": 1,
    "المعضاد": 1,
    "المعضد": 1,
    "اليعضيد": 1,
    "العضرس": 1,
    "أعضت": 1,
    "عضض": 1,
    "العضاض": 1,
    "العضوض": 1,
    "أعضلت": 1,
    "عضلت": 1,
    "تعضله": 1,
    "استعضل": 1,
    "العضال": 1,
    "العضم": 1,
    "عضهت": 1,
    "أعضه": 1,
    "العاضه": 1,
    "العاضهة": 1,
    "العضاه": 1,
    "العضيهة": 1,
    "عضا": 1,
    "عضى": 1,
    "أعطبه": 1,
    "اعتطب": 1,
    "العطبة": 1,
    "المعطب": 1,
    "العطبل": 1,
    "العطبول": 1,
    "عطره": 1,
    "تعطر": 1,
    "استعطر": 1,
    "العاطر": 1,
    "العطار": 1,
    "المعطار": 1,
    "المعطير": 1,
    "عطرده": 1,
    "العطرود": 1,
    "عطسه": 1,
    "العاطوس": 1,
    "العطسة": 1,
    "العطوس": 1,
    "المعطس": 1,
    "أعطش": 1,
    "عطشه": 1,
    "تعطش": 1,
    "العطاش": 1,
    "المعطشة": 1,
    "عط": 1,
    "عطط": 1,
    "اعتط": 1,
    "انعط": 1,
    "تعطط": 1,
    "استعطفه": 1,
    "العاطوف": 1,
    "العطاف": 1,
    "العطوف": 1,
    "العطيف": 1,
    "العيطل": 1,
    "المعطال": 1,
    "عطنت": 1,
    "أعطن": 1,
    "انعطن": 1,
    "تعطنت": 1,
    "العطان": 1,
    "المعطن": 1,
    "عاطاه": 1,
    "عطاه": 1,
    "تعاطى": 1,
    "تعطى": 1,
    "استعطى": 1,
    "العطية": 1,
    "المعطاء": 1,
    "عظر": 1,
    "أعظره": 1,
    "العظار": 1,
    "العظارة": 1,
    "العظاري": 1,
    "العظور": 1,
    "عظه": 1,
    "عاظ": 1,
    "عظلت": 1,
    "عاظلت": 1,
    "اعتظلت": 1,
    "تعاظلت": 1,
    "تعظل": 1,
    "تعظلم": 1,
    "العظلم": 1,
    "العظلمة": 1,
    "عظمه": 1,
    "تعاظم": 1,
    "تعظم": 1,
    "استعظم": 1,
    "العظامة": 1,
    "العظامي": 1,
    "المعظوم": 1,
    "عظاه": 1,
    "العظاءة": 1,
    "عظى": 1,
    "العنظوان": 1,
    "عفته": 1,
    "عفت": 1,
    "العفتان": 1,
    "العفيتة": 1,
    "المعفت": 1,
    "عفجه": 1,
    "عفج": 1,
    "تعفج": 1,
    "العفج": 1,
    "المعفاج": 1,
    "المعفج": 1,
    "المعفجة": 1,
    "عفد": 1,
    "اعتفد": 1,
    "عفره": 1,
    "عافره": 1,
    "اعتفر": 1,
    "انعفر": 1,
    "تعفر": 1,
    "الأعفر": 1,
    "العفار": 1,
    "العفارية": 1,
    "العفر": 1,
    "العفراء": 1,
    "العفرة": 1,
    "العفري": 1,
    "العفرين": 1,
    "العفرية": 1,
    "العفير": 1,
    "المعافر": 1,
    "المعفورة": 1,
    "اليعفور": 1,
    "تعفرت": 1,
    "العفاز": 1,
    "العفازة": 1,
    "عفسه": 1,
    "اعتفس": 1,
    "انعفس": 1,
    "تعافسوا": 1,
    "الأعفش": 1,
    "العفاشة": 1,
    "أعفص": 1,
    "العفاص": 1,
    "العفص": 1,
    "عفطت": 1,
    "أعفه": 1,
    "عففه": 1,
    "اعتف": 1,
    "استعف": 1,
    "العفافة": 1,
    "العفيفة": 1,
    "عفق": 1,
    "أعفق": 1,
    "عافقه": 1,
    "اعتفق": 1,
    "انعفق": 1,
    "تعفق": 1,
    "المعفاق": 1,
    "عفك": 1,
    "عفل": 1,
    "عفلت": 1,
    "العافل": 1,
    "العفل": 1,
    "العفلاء": 1,
    "العفلة": 1,
    "أعفن": 1,
    "عافاه": 1,
    "عفى": 1,
    "اعتفاه": 1,
    "تعفى": 1,
    "استعفى": 1,
    "العفا": 1,
    "العفاءة": 1,
    "العفاوة": 1,
    "عقبت": 1,
    "أعقب": 1,
    "اعتقب": 1,
    "استعقب": 1,
    "العقبى": 1,
    "العقوب": 1,
    "العقيب": 1,
    "المعقاب": 1,
    "المعقب": 1,
    "اليعاقبة": 1,
    "اليعقوبية": 1,
    "تعقبله": 1,
    "العقبول": 1,
    "أعقد": 1,
    "عاقده": 1,
    "انعقد": 1,
    "تعاقد": 1,
    "العقاد": 1,
    "عقرت": 1,
    "أعقر": 1,
    "انعقر": 1,
    "تعاقر": 1,
    "تعقر": 1,
    "العاقر": 1,
    "العقر": 1,
    "العقرة": 1,
    "العقور": 1,
    "العقير": 1,
    "العقيرة": 1,
    "العقرباء": 1,
    "العقربة": 1,
    "العقربان": 1,
    "المعقرب": 1,
    "عقصت": 1,
    "العقاص": 1,
    "العقصة": 1,
    "العقيص": 1,
    "العقيصاء": 1,
    "العقيصة": 1,
    "المعقاص": 1,
    "المعقص": 1,
    "العقعقة": 1,
    "عقفت": 1,
    "عقفه": 1,
    "انعقف": 1,
    "تعقف": 1,
    "الأعقف": 1,
    "العقاف": 1,
    "العقفاء": 1,
    "العقافة": 1,
    "عقت": 1,
    "أعقت": 1,
    "عاقه": 1,
    "اعتق": 1,
    "انعق": 1,
    "العقاق": 1,
    "العق": 1,
    "العقق": 1,
    "العقان": 1,
    "العقة": 1,
    "العقيقة": 1,
    "عاقله": 1,
    "تعاقل": 1,
    "تعقله": 1,
    "العاقل": 1,
    "العاقول": 1,
    "العقيلى": 1,
    "العقلة": 1,
    "العقيلة": 1,
    "المعتقل": 1,
    "عقمت": 1,
    "عاقمه": 1,
    "العقام": 1,
    "العقنقل": 1,
    "عقا": 1,
    "عقى": 1,
    "أعقى": 1,
    "اعتقى": 1,
    "العقاة": 1,
    "العقوة": 1,
    "العقيان": 1,
    "عكب": 1,
    "عكبت": 1,
    "اعتكب": 1,
    "تعكبته": 1,
    "العكاب": 1,
    "العكوب": 1,
    "عكد": 1,
    "أعكد": 1,
    "اعتكده": 1,
    "استعكد": 1,
    "العكدة": 1,
    "المعكود": 1,
    "أعكر": 1,
    "اعتكر": 1,
    "تعاكر": 1,
    "عكرم": 1,
    "العكرمة": 1,
    "تعكز": 1,
    "العكازة": 1,
    "عاكسه": 1,
    "العكاس": 1,
    "العكيس": 1,
    "عكشت": 1,
    "عكش": 1,
    "تعكش": 1,
    "العكاشة والعكاشة": 1,
    "عكصه": 1,
    "عكص": 1,
    "تعكص": 1,
    "عكظ": 1,
    "عاكظه": 1,
    "عكظه": 1,
    "تعاكظوا": 1,
    "تعكظ": 1,
    "عكاظ": 1,
    "اعتكف": 1,
    "تعكف": 1,
    "الاعتكاف": 1,
    "العكف": 1,
    "العكاك": 1,
    "العكك": 1,
    "العك": 1,
    "العكة": 1,
    "عكل": 1,
    "عكلت": 1,
    "أعكل": 1,
    "اعتكل": 1,
    "العكال": 1,
    "العكل": 1,
    "العوكل": 1,
    "العوكلة": 1,
    "أعكم": 1,
    "عكمت": 1,
    "اعتكم": 1,
    "العكام": 1,
    "العكم": 1,
    "عكنت": 1,
    "تعكن": 1,
    "العكناء": 1,
    "العكنة": 1,
    "عكت": 1,
    "عكى": 1,
    "أعكى": 1,
    "الأعكى": 1,
    "العاكي": 1,
    "العكوة": 1,
    "العكي": 1,
    "علبى": 1,
    "استعلب": 1,
    "اعلنبى": 1,
    "العلباء": 1,
    "المعلوب": 1,
    "علث": 1,
    "عالثه": 1,
    "علثت": 1,
    "اعتلث": 1,
    "العلاثة": 1,
    "العلث": 1,
    "العلثة": 1,
    "العلثى": 1,
    "العليث": 1,
    "العليثة": 1,
    "اعتلج": 1,
    "تعلج": 1,
    "استعلج": 1,
    "العالج": 1,
    "العلج": 1,
    "العلجان": 1,
    "العلجم": 1,
    "علد": 1,
    "العلد": 1,
    "علز": 1,
    "أعلزه": 1,
    "العلوز": 1,
    "علس": 1,
    "العلاس": 1,
    "العلس": 1,
    "العلسي": 1,
    "العلوس": 1,
    "العليس": 1,
    "علصت": 1,
    "العلوص": 1,
    "علضه": 1,
    "علط": 1,
    "اعتلطه": 1,
    "تعلط": 1,
    "اعلوط": 1,
    "الإعليط": 1,
    "العالط": 1,
    "العلاط": 1,
    "العلط": 1,
    "العلطة": 1,
    "عل عل": 1,
    "تعلعل": 1,
    "العلعال": 1,
    "العلعل": 1,
    "العلعول": 1,
    "أعلف": 1,
    "اعتلفت": 1,
    "تعلف": 1,
    "استعلفت": 1,
    "العلفى": 1,
    "العلوفة": 1,
    "العليف": 1,
    "العلفوف": 1,
    "علقت": 1,
    "أعلق": 1,
    "عالقه": 1,
    "اعتلقه": 1,
    "الأعاليق": 1,
    "التعليقة": 1,
    "العلاق": 1,
    "العلق": 1,
    "العلقى": 1,
    "العليقي": 1,
    "العلوق": 1,
    "المعلوق": 1,
    "العلقمة": 1,
    "العالك": 1,
    "العلاك": 1,
    "العلك": 1,
    "العلكة": 1,
    "أعل": 1,
    "تعالت": 1,
    "تعللت": 1,
    "التعلة": 1,
    "التعليل": 1,
    "العلالة": 1,
    "العل": 1,
    "العلول": 1,
    "عالمه": 1,
    "اعتلم": 1,
    "تعالم": 1,
    "استعلمه": 1,
    "الأعلومة": 1,
    "العلامي": 1,
    "العلام": 1,
    "العلمة": 1,
    "العيلام": 1,
    "العيلم": 1,
    "العلماد": 1,
    "أعلنه": 1,
    "عالنه": 1,
    "علنه": 1,
    "اعتلن": 1,
    "استعلن": 1,
    "العلانية": 1,
    "العلنة": 1,
    "عله": 1,
    "العلهاء": 1,
    "العاله": 1,
    "العله": 1,
    "تعلى": 1,
    "اعلولى": 1,
    "العلا": 1,
    "العلاء": 1,
    "العلو": 1,
    "العلوة": 1,
    "المعلاة": 1,
    "علون": 1,
    "العلوان": 1,
    "العميت": 1,
    "العميتة": 1,
    "عمج": 1,
    "تعمج": 1,
    "أعمد": 1,
    "انعمد": 1,
    "أعمر": 1,
    "تعمر": 1,
    "استعمره": 1,
    "عومر": 1,
    "العمار": 1,
    "العمرة": 1,
    "العمرتان": 1,
    "العمرى": 1,
    "العمري": 1,
    "العمرد": 1,
    "العمرود": 1,
    "العمرس": 1,
    "العمروس": 1,
    "عمرط": 1,
    "العمروط": 1,
    "عمس": 1,
    "أعمس": 1,
    "عامسه": 1,
    "تعامس": 1,
    "العماس": 1,
    "العموس": 1,
    "العميس": 1,
    "عمش": 1,
    "استعمشه": 1,
    "العمش": 1,
    "عمقت": 1,
    "تعمق": 1,
    "العمقي": 1,
    "أعمله": 1,
    "اعتمل": 1,
    "تعاملا": 1,
    "استعمله": 1,
    "العمالة": 1,
    "العمول": 1,
    "المعمل": 1,
    "المعمول": 1,
    "عملس": 1,
    "العملس": 1,
    "عملق": 1,
    "أعم": 1,
    "تعمم": 1,
    "استعم": 1,
    "الأعم": 1,
    "العمم": 1,
    "العمومة": 1,
    "العميم": 1,
    "العمه": 1,
    "أعماه": 1,
    "عماه": 1,
    "تعامى": 1,
    "الأعميان": 1,
    "العماء": 1,
    "العماءة": 1,
    "المعمى": 1,
    "العانب": 1,
    "العناب": 1,
    "العنبا": 1,
    "أعنته": 1,
    "عنته": 1,
    "تعنته": 1,
    "العنتر": 1,
    "عنج": 1,
    "أعنج": 1,
    "العناج": 1,
    "عنجد": 1,
    "العنجد": 1,
    "العنجه": 1,
    "العنجهي": 1,
    "العنجهية": 1,
    "تعاند": 1,
    "استعند": 1,
    "العنادية": 1,
    "العندية": 1,
    "العنود": 1,
    "عندل": 1,
    "أعنزه": 1,
    "اعتنز": 1,
    "تعنز": 1,
    "استعنز": 1,
    "العنز": 1,
    "العنزي": 1,
    "المعنز": 1,
    "عنست": 1,
    "عنس": 1,
    "أعنس": 1,
    "العناس": 1,
    "العنس": 1,
    "أعنص": 1,
    "العنصل": 1,
    "العنصاة": 1,
    "العنصوة": 1,
    "العنظاب": 1,
    "عنظى": 1,
    "عنعن": 1,
    "أعنفه": 1,
    "عنفه": 1,
    "اعتنف": 1,
    "العنفة": 1,
    "العنفق": 1,
    "العنفقة": 1,
    "أعنق": 1,
    "عانقه": 1,
    "اعتنق": 1,
    "تعانقا": 1,
    "تعنق": 1,
    "العانقاء": 1,
    "العنيق": 1,
    "المعناق": 1,
    "المعنق": 1,
    "المعنقة": 1,
    "العنقد": 1,
    "العنقر": 1,
    "عنقش": 1,
    "تعنقش": 1,
    "العنقاش": 1,
    "عنكه": 1,
    "اعتنك": 1,
    "تعنك": 1,
    "العنك": 1,
    "العنيك": 1,
    "العنكب": 1,
    "العنكبة": 1,
    "عنكش": 1,
    "تعنكش": 1,
    "العنكش": 1,
    "عنم": 1,
    "العنم": 1,
    "العنمة": 1,
    "أعنت": 1,
    "عانه": 1,
    "اعتن": 1,
    "تعنن": 1,
    "الأعنان": 1,
    "العنن": 1,
    "العنة": 1,
    "المعن": 1,
    "عاناه": 1,
    "عناه": 1,
    "اعتنى": 1,
    "تعنى": 1,
    "التعنية": 1,
    "العاني": 1,
    "العانية": 1,
    "العنا": 1,
    "العنو": 1,
    "المعنوي": 1,
    "أعهده": 1,
    "عاهده": 1,
    "اعتهده": 1,
    "تعاهدا": 1,
    "استعهد": 1,
    "العهاد": 1,
    "العهادة": 1,
    "العهدة": 1,
    "العهيد": 1,
    "عاهرها": 1,
    "العاهل": 1,
    "العاهلية": 1,
    "عهن": 1,
    "العاهن": 1,
    "العهن": 1,
    "عاثه": 1,
    "عوثه": 1,
    "تعوث": 1,
    "المعاث": 1,
    "انعاج": 1,
    "تعوج": 1,
    "الأعوجيات": 1,
    "العواج": 1,
    "المعاج": 1,
    "عاوده": 1,
    "اعتاده": 1,
    "استعاده": 1,
    "الأعود": 1,
    "العائدة": 1,
    "العواد": 1,
    "المعادة": 1,
    "المعيد": 1,
    "أعاذه": 1,
    "عوذه": 1,
    "تعاوذ": 1,
    "تعوذ": 1,
    "استعاذ": 1,
    "العوائذ": 1,
    "العوذ": 1,
    "العوذة": 1,
    "المعاذ": 1,
    "المعاذة": 1,
    "المعوذتان": 1,
    "عورت": 1,
    "أعور": 1,
    "أعاره": 1,
    "عاوره": 1,
    "عوره": 1,
    "اعتوروا": 1,
    "تعاوروا": 1,
    "تعور": 1,
    "اعوارت": 1,
    "اعورت": 1,
    "الاستعارة": 1,
    "العائر": 1,
    "العائرة": 1,
    "العارة": 1,
    "العوار": 1,
    "العور": 1,
    "العوراء": 1,
    "المعار": 1,
    "المعور": 1,
    "أعوز": 1,
    "المعوز": 1,
    "المعوزة": 1,
    "عوس": 1,
    "العواسة": 1,
    "عوص": 1,
    "أعوص": 1,
    "اعتاص": 1,
    "العوصاء": 1,
    "عاضه": 1,
    "أعاضه": 1,
    "عاوضه": 1,
    "عوضه": 1,
    "اعتاض": 1,
    "تعاوض": 1,
    "تعوض": 1,
    "استعاضه": 1,
    "العوض": 1,
    "المعوضة": 1,
    "عاطت": 1,
    "اعتاطت": 1,
    "العواف": 1,
    "العوافة": 1,
    "العوف": 1,
    "عوقه": 1,
    "اعتاقه": 1,
    "تعوق": 1,
    "عاق عاق": 1,
    "العواق": 1,
    "العوق": 1,
    "العوقة": 1,
    "العيوق": 1,
    "أعال": 1,
    "العالة": 1,
    "العول": 1,
    "العولة": 1,
    "العيل": 1,
    "المعول": 1,
    "أعوم": 1,
    "عاومت": 1,
    "عوم": 1,
    "العومة": 1,
    "عانت": 1,
    "أعانه": 1,
    "عاونه": 1,
    "عونت": 1,
    "استعان": 1,
    "العانة": 1,
    "العوان": 1,
    "المتعاونة": 1,
    "المعانة": 1,
    "المعاون": 1,
    "المعوان": 1,
    "المعون": 1,
    "عاه": 1,
    "أعاه": 1,
    "عوه": 1,
    "عاواهم": 1,
    "اعتوى": 1,
    "انعوى": 1,
    "تعاوت": 1,
    "استعواهم": 1,
    "العوي": 1,
    "العوة": 1,
    "المعاوية": 1,
    "عاب": 1,
    "عيبه": 1,
    "تعيبه": 1,
    "العيبة": 1,
    "العيابة": 1,
    "المعاب": 1,
    "المعابة": 1,
    "المعيب": 1,
    "عيث": 1,
    "تعيثت": 1,
    "العيوث": 1,
    "العياث": 1,
    "عيدنت": 1,
    "العيدانة": 1,
    "العيدة": 1,
    "العيداه": 1,
    "أعير": 1,
    "عيره": 1,
    "تعايروا": 1,
    "أعيس": 1,
    "تعيست": 1,
    "الأعيس": 1,
    "العيساء": 1,
    "أعاشه": 1,
    "عايشه": 1,
    "تعايشوا": 1,
    "العائش": 1,
    "العائشة": 1,
    "العيشة": 1,
    "العياش": 1,
    "المتعيش": 1,
    "العيص": 1,
    "المعياص": 1,
    "المعيص": 1,
    "عيط": 1,
    "تعيطت": 1,
    "العائط": 1,
    "العياط": 1,
    "العيط": 1,
    "عافت": 1,
    "أعاف": 1,
    "اعتاف": 1,
    "تعيف": 1,
    "العيافة": 1,
    "العيفة": 1,
    "العيوف": 1,
    "عيق": 1,
    "العيق": 1,
    "العيقة": 1,
    "عاك": 1,
    "أعيل": 1,
    "تعيل": 1,
    "العيلة": 1,
    "المعيل": 1,
    "أعام": 1,
    "اعتام": 1,
    "العيام": 1,
    "العيمة": 1,
    "أعان": 1,
    "عاينه": 1,
    "اعتان": 1,
    "تعين": 1,
    "العائن": 1,
    "العائنة": 1,
    "المعان": 1,
    "المعيان": 1,
    "المعيون": 1,
    "عيه": 1,
    "أعيه": 1,
    "العائهة": 1,
    "عي": 1,
    "عيي": 1,
    "أعيا": 1,
    "عايا": 1,
    "عيا": 1,
    "تعايا": 1,
    "تعيا": 1,
    "استعيا": 1,
    "الأعيية": 1,
    "العياء": 1,
    "العي": 1,
    "الغين": 1,
    "الغازوزة": 1,
    "غبت": 1,
    "التغبة": 1,
    "الغبب": 1,
    "الغبة": 1,
    "الغبيب": 1,
    "الغبيبة": 1,
    "المغبة": 1,
    "غبث": 1,
    "اغبث": 1,
    "الغبيثة": 1,
    "غبج": 1,
    "الغبجة": 1,
    "أغبر": 1,
    "تغبر": 1,
    "الغبارية": 1,
    "الغبر": 1,
    "الغبران": 1,
    "الغبرة": 1,
    "غبس": 1,
    "أغبس": 1,
    "الغبس": 1,
    "الغبسة": 1,
    "أغبش": 1,
    "الغبش": 1,
    "الغبشة": 1,
    "غبصت": 1,
    "الغبص": 1,
    "أغبط": 1,
    "اغتبط": 1,
    "الغبط": 1,
    "الغبيط": 1,
    "غبغب": 1,
    "الغبغب": 1,
    "غبقه": 1,
    "اغتبق": 1,
    "تغبق": 1,
    "الغبقان": 1,
    "الغبوق": 1,
    "المغتبق": 1,
    "غبنه": 1,
    "اغتبن": 1,
    "الغبن": 1,
    "الغبينة": 1,
    "المغبن": 1,
    "أغبت": 1,
    "غبى": 1,
    "تغابى": 1,
    "الأغبى": 1,
    "الغبوة": 1,
    "الغبياء": 1,
    "الغبية": 1,
    "غتت": 1,
    "اغتت": 1,
    "غتم": 1,
    "أغتم": 1,
    "اغتتم": 1,
    "المغتوم": 1,
    "أغث": 1,
    "استغث": 1,
    "الغث": 1,
    "الغثة": 1,
    "الغثيث": 1,
    "الغثيثة": 1,
    "غثر": 1,
    "أغثر": 1,
    "اغثار": 1,
    "تمغثر": 1,
    "الأغثر": 1,
    "الغثر": 1,
    "الغثراء": 1,
    "الغثرة": 1,
    "الغيثرة": 1,
    "المغثار": 1,
    "المغثر": 1,
    "المغثور": 1,
    "غثغث": 1,
    "غثم": 1,
    "الغثمة": 1,
    "غثمر": 1,
    "المغثمر": 1,
    "غثا": 1,
    "الغثاء": 1,
    "أغدت": 1,
    "أغد": 1,
    "الغددة": 1,
    "أغدره": 1,
    "غادره": 1,
    "اغتدر": 1,
    "تغدر": 1,
    "استغدر": 1,
    "الغادرة": 1,
    "الغدارة": 1,
    "الغدرة": 1,
    "الغديرة": 1,
    "أغدف": 1,
    "غادف": 1,
    "اغتدف": 1,
    "اغدودف": 1,
    "الغداف": 1,
    "الغدافي": 1,
    "الغدف": 1,
    "الغدفة": 1,
    "غدقت": 1,
    "أغدق": 1,
    "اغدودق": 1,
    "غيدق": 1,
    "الغدق": 1,
    "الغيداق": 1,
    "الغيدق": 1,
    "تغدن": 1,
    "اغدودن": 1,
    "الغداني": 1,
    "الغدن": 1,
    "الغدنة": 1,
    "الغدودن": 1,
    "المغدودن": 1,
    "غاداه": 1,
    "غداه": 1,
    "اغتدى": 1,
    "تغدى": 1,
    "الغادية": 1,
    "الغدوة": 1,
    "المغدى": 1,
    "المغداة": 1,
    "غذ": 1,
    "أغذ": 1,
    "الغاذ": 1,
    "الغاذة": 1,
    "الغذيذة": 1,
    "غذرم": 1,
    "تغذرم": 1,
    "الغذارم": 1,
    "المغذرم": 1,
    "غذمه": 1,
    "أغذم": 1,
    "اغتذم": 1,
    "تغذم": 1,
    "الغذامة": 1,
    "الغذم": 1,
    "الغذمة": 1,
    "غذمر": 1,
    "تغذمر": 1,
    "الغذامر": 1,
    "غذى": 1,
    "اغتذى": 1,
    "تغذى": 1,
    "الغاذي": 1,
    "الغاذية": 1,
    "الغذوي": 1,
    "اغترب": 1,
    "الغربة": 1,
    "الغربيب": 1,
    "الغربال": 1,
    "غرث": 1,
    "غرثه": 1,
    "أغرد": 1,
    "استغرده": 1,
    "الأغرودة": 1,
    "الغراد": 1,
    "الغرد": 1,
    "غارت": 1,
    "اغتر": 1,
    "تغرر": 1,
    "استغر": 1,
    "الأغر": 1,
    "الغرارة": 1,
    "الغر": 1,
    "الغرر": 1,
    "الغرة": 1,
    "غرزت": 1,
    "أغرز": 1,
    "التغريز": 1,
    "الغارز": 1,
    "المغرز": 1,
    "أغرس": 1,
    "اغترس": 1,
    "الغراسة": 1,
    "الغريس": 1,
    "الغريسة": 1,
    "المغرس": 1,
    "أغرض": 1,
    "اغترض": 1,
    "انغرض": 1,
    "الإغريض": 1,
    "الغارض": 1,
    "الغرضان": 1,
    "الغريض": 1,
    "الغريضة": 1,
    "المغروض": 1,
    "تغرغر": 1,
    "الغرغر": 1,
    "أغرف": 1,
    "انغرف": 1,
    "تغرفه": 1,
    "الغرافة": 1,
    "الغراف": 1,
    "الغروف": 1,
    "الغريف": 1,
    "الغريفة": 1,
    "غارقه": 1,
    "اغترق": 1,
    "غرقأت": 1,
    "الغرقيء": 1,
    "الغرقد": 1,
    "غرقل": 1,
    "الغرقل": 1,
    "الغرلة": 1,
    "أغرمه": 1,
    "أغرم": 1,
    "غرمه": 1,
    "الغارم": 1,
    "الغرم": 1,
    "الغريم": 1,
    "المغرم": 1,
    "غرن": 1,
    "غرنق": 1,
    "الغرانق": 1,
    "الغرنوق": 1,
    "أغري": 1,
    "غاراه": 1,
    "غرى": 1,
    "الغرا": 1,
    "الغرو": 1,
    "الغروى": 1,
    "الغروي": 1,
    "الغرواني": 1,
    "الغري": 1,
    "أغزر": 1,
    "غازر": 1,
    "استغزر": 1,
    "الغزر": 1,
    "المغزرة": 1,
    "أغزت": 1,
    "غازه": 1,
    "اغتز": 1,
    "تغازوا": 1,
    "الغز": 1,
    "غزغز": 1,
    "أغزل": 1,
    "اغتزل": 1,
    "أغزاه": 1,
    "غزاه": 1,
    "اغتزاه": 1,
    "الغزاة": 1,
    "المغزاة": 1,
    "غسر": 1,
    "تغسر": 1,
    "الغسر": 1,
    "انغس": 1,
    "الغساس": 1,
    "الغس": 1,
    "أغسق": 1,
    "الغاسق": 1,
    "الغساق": 1,
    "اغتسل": 1,
    "انغسل": 1,
    "الغاسول": 1,
    "الغسلة": 1,
    "الغسلين": 1,
    "المغسل": 1,
    "غسم": 1,
    "أغسم": 1,
    "الغسم": 1,
    "الغسمة": 1,
    "غسنه": 1,
    "الغسان": 1,
    "الغساني": 1,
    "الغسنة": 1,
    "الغيسان": 1,
    "غسا": 1,
    "غسي": 1,
    "أغسى": 1,
    "الغسو": 1,
    "أغشه": 1,
    "غششه": 1,
    "اغتشه": 1,
    "استغشه": 1,
    "الغشش": 1,
    "المغشوش": 1,
    "تغاشموا": 1,
    "الأغشم": 1,
    "الغشيم": 1,
    "المغشم": 1,
    "غشمر": 1,
    "تغشمر": 1,
    "الغشمشم": 1,
    "أغشى": 1,
    "تغشى": 1,
    "استغشى": 1,
    "الغشاوة": 1,
    "الغشية": 1,
    "أغصه": 1,
    "اغتص": 1,
    "الغصة": 1,
    "أغصن": 1,
    "الغصنة": 1,
    "أغضبه": 1,
    "تغضب": 1,
    "الغضابي": 1,
    "الغضوب": 1,
    "اغتضر": 1,
    "تغضر": 1,
    "الغاضر": 1,
    "الغضار": 1,
    "الغضارة": 1,
    "الغضراء": 1,
    "غضت": 1,
    "أغض": 1,
    "غضض": 1,
    "اغتض": 1,
    "انغض": 1,
    "الغضاض": 1,
    "الغضاضة": 1,
    "الغض": 1,
    "الغضة": 1,
    "الغضيض": 1,
    "المغضة": 1,
    "غضف": 1,
    "أغضفت": 1,
    "غضفه": 1,
    "انغضف": 1,
    "تغضف": 1,
    "الغضف": 1,
    "غضفر": 1,
    "الغضافر": 1,
    "الغضفر": 1,
    "الغضنفر": 1,
    "غضنت": 1,
    "أغضنت": 1,
    "غاضن": 1,
    "تغضن": 1,
    "الغضن": 1,
    "غضيت": 1,
    "أغضى": 1,
    "الغاضية": 1,
    "الغضياء": 1,
    "الغطرس": 1,
    "الغطريس": 1,
    "غطرش": 1,
    "تغطرش": 1,
    "غطرف": 1,
    "تغطرف": 1,
    "الغطارف": 1,
    "الغطراف": 1,
    "الغطريف": 1,
    "غطسه": 1,
    "تغاطس": 1,
    "الغاطس": 1,
    "الغطيس": 1,
    "أغطش": 1,
    "تغاطش": 1,
    "تغطشت": 1,
    "اغطاش": 1,
    "انغط": 1,
    "تغاط": 1,
    "الغطاط": 1,
    "غطغطت": 1,
    "تغطغط": 1,
    "غطف": 1,
    "أغطت": 1,
    "غطاه": 1,
    "اغتطى": 1,
    "تغطى": 1,
    "الغطاية": 1,
    "الغطوان": 1,
    "الغطيان": 1,
    "أغفر": 1,
    "اغتفر": 1,
    "تغافر": 1,
    "اغفار": 1,
    "الغفارة": 1,
    "الغفر": 1,
    "الغفرة": 1,
    "الغفير": 1,
    "الغفيرة": 1,
    "المغفار": 1,
    "المغفر": 1,
    "غافصه": 1,
    "الغافصة": 1,
    "اغتفت": 1,
    "تغففت": 1,
    "الغف": 1,
    "الغفان": 1,
    "الغفة": 1,
    "غفق": 1,
    "اغتفق": 1,
    "تغفق": 1,
    "الغفق": 1,
    "أغفل": 1,
    "تغفل": 1,
    "استغفله": 1,
    "أغفى": 1,
    "الغفا": 1,
    "غفي": 1,
    "انغفى": 1,
    "الغفى": 1,
    "الغفاء": 1,
    "الغفاءة": 1,
    "غق غق": 1,
    "غق": 1,
    "الغققة": 1,
    "غقغق": 1,
    "غلبه": 1,
    "غالبه": 1,
    "تغالبوا": 1,
    "استغلب": 1,
    "اغلولب": 1,
    "التغليب": 1,
    "الغلبة": 1,
    "اغتلته": 1,
    "تغلته": 1,
    "الغلتة": 1,
    "غلث": 1,
    "أغلث": 1,
    "اغتلث": 1,
    "تغلث": 1,
    "الغلث": 1,
    "الغليث": 1,
    "المغلث": 1,
    "غلج": 1,
    "تغلج": 1,
    "الأغلوج": 1,
    "أغلس": 1,
    "الغلس": 1,
    "غلصمه": 1,
    "الغلصمة": 1,
    "أغلطه": 1,
    "غالطه": 1,
    "غلطه": 1,
    "الأغلوطة": 1,
    "الغلاط": 1,
    "الغلوط": 1,
    "المغلاط": 1,
    "المغلطاني": 1,
    "المغلطة": 1,
    "غالظه": 1,
    "غلظه": 1,
    "استغلظ": 1,
    "الغلظ": 1,
    "الغلظة": 1,
    "المستغلظ": 1,
    "المغلظة": 1,
    "تغلغل": 1,
    "الغلغل": 1,
    "الغلغلة": 1,
    "المغلغلة": 1,
    "تغلف": 1,
    "الأغلف": 1,
    "الغلفة": 1,
    "الإغليق": 1,
    "الغلق": 1,
    "المغلاق": 1,
    "المغلق": 1,
    "أغل": 1,
    "غلله": 1,
    "انغل": 1,
    "تغلل": 1,
    "الغالة": 1,
    "الغلالة": 1,
    "الغل": 1,
    "الغلل": 1,
    "الغلة": 1,
    "الغلول": 1,
    "الغليل": 1,
    "المغل": 1,
    "أغلمه": 1,
    "اغتلم": 1,
    "الغلامية": 1,
    "الغلمة": 1,
    "الغلومة": 1,
    "الغلومية": 1,
    "الغيلم": 1,
    "غالى": 1,
    "اغتلى": 1,
    "استغلى": 1,
    "الغلواء": 1,
    "الغلوة": 1,
    "تغلى": 1,
    "الغالية": 1,
    "غمت": 1,
    "اغتمد": 1,
    "تغمد": 1,
    "الغامد": 1,
    "الغامدة": 1,
    "الغمدية": 1,
    "غمره": 1,
    "غمرت": 1,
    "أغمره": 1,
    "اغتمرت": 1,
    "انغمر": 1,
    "تغمرت": 1,
    "الغامر": 1,
    "الغمار": 1,
    "الغمرة": 1,
    "الغمير": 1,
    "الغميرة": 1,
    "المغتمر": 1,
    "المغمر": 1,
    "المغمور": 1,
    "غمزت": 1,
    "أغمز": 1,
    "اغتمز": 1,
    "تغامز": 1,
    "الغماز": 1,
    "الغمازة": 1,
    "الغميز": 1,
    "الغميزة": 1,
    "المغمز": 1,
    "المغموز": 1,
    "اغتمس": 1,
    "انغمس": 1,
    "تغامس": 1,
    "الغماسة": 1,
    "الغموس": 1,
    "الغميس": 1,
    "الغميسة": 1,
    "غمصه": 1,
    "غمصت": 1,
    "اغتمصه": 1,
    "الغمص": 1,
    "المتغمص": 1,
    "الغميصاء": 1,
    "أغمضت": 1,
    "اغتمض": 1,
    "انغمض": 1,
    "تغامض": 1,
    "الغامض": 1,
    "الغماض": 1,
    "الغمض": 1,
    "المغمضات والمغمضات": 1,
    "أغمط": 1,
    "غامط": 1,
    "اغتمط": 1,
    "تغمط": 1,
    "تغمغم": 1,
    "الغمغمة": 1,
    "التغمغم": 1,
    "غمقت": 1,
    "الغامق": 1,
    "الغمق": 1,
    "غمل": 1,
    "أغمل": 1,
    "انغمل": 1,
    "تغمل": 1,
    "الغمالج": 1,
    "الغملج والغملج": 1,
    "الغملس": 1,
    "أغمت": 1,
    "غامه": 1,
    "اغتم": 1,
    "انغم": 1,
    "الغماء": 1,
    "الغمة": 1,
    "الغمى": 1,
    "المغمم": 1,
    "المغموم": 1,
    "غمن": 1,
    "انغمن": 1,
    "الغمنة": 1,
    "أغمي": 1,
    "الغمي": 1,
    "الغمية": 1,
    "الغنب": 1,
    "الغنبة": 1,
    "غنجت": 1,
    "تغنجت": 1,
    "الأغنوجة": 1,
    "الغناج": 1,
    "الغنج": 1,
    "الغندبة": 1,
    "الغندر": 1,
    "الغندور": 1,
    "الغنوصية": 1,
    "غنظ": 1,
    "الغناظ": 1,
    "أغنمه": 1,
    "غنمه": 1,
    "تغنم": 1,
    "الغنام": 1,
    "المغنم": 1,
    "غننه": 1,
    "الغنة": 1,
    "اغتنى": 1,
    "تغانى": 1,
    "الغاني": 1,
    "الغانية": 1,
    "الغنيان": 1,
    "غهب": 1,
    "أغهب": 1,
    "اغتهب": 1,
    "الغهب": 1,
    "الغيهب": 1,
    "الغيهبة": 1,
    "غاثه": 1,
    "أغاثه": 1,
    "استغاث": 1,
    "الغوث": 1,
    "الغويث": 1,
    "الغياث": 1,
    "المغاث": 1,
    "المغوثة": 1,
    "غاج": 1,
    "تغوج": 1,
    "الغوج": 1,
    "أغار": 1,
    "غاور": 1,
    "تغاور": 1,
    "استغار": 1,
    "الغائرة": 1,
    "المغار": 1,
    "المغارة": 1,
    "المغيرية": 1,
    "غوز": 1,
    "الأغوز": 1,
    "غوصه": 1,
    "الغياصة": 1,
    "المتغوصة": 1,
    "المغاص": 1,
    "المغوصة": 1,
    "غاط": 1,
    "أغاط": 1,
    "تغاوطا": 1,
    "الغوط": 1,
    "الغويط": 1,
    "الغيط": 1,
    "الغاغة": 1,
    "غاله": 1,
    "غاول": 1,
    "اغتاله": 1,
    "تغول": 1,
    "الغائل": 1,
    "الغائلة": 1,
    "الغيلة": 1,
    "المغالة": 1,
    "أغواه": 1,
    "غواه": 1,
    "تغاوى": 1,
    "استغواه": 1,
    "الأغواء": 1,
    "الأغوية": 1,
    "الغية": 1,
    "المغواة": 1,
    "أغاب": 1,
    "أغيبت": 1,
    "غايبه": 1,
    "اغتابه": 1,
    "الغيابة": 1,
    "الغيبة": 1,
    "الغيبان": 1,
    "أغاث": 1,
    "تغايد": 1,
    "الأغيد": 1,
    "الغادة": 1,
    "الغيدان": 1,
    "غاره": 1,
    "غايره": 1,
    "اغتار": 1,
    "تغايرت": 1,
    "الغيرية": 1,
    "المتغاير": 1,
    "غيس": 1,
    "الغيساني": 1,
    "أغاض": 1,
    "انغاض": 1,
    "الغيض": 1,
    "الغيضة": 1,
    "المغيض": 1,
    "غايط": 1,
    "غاظه": 1,
    "أغاظه": 1,
    "غايظه": 1,
    "اغتاظ": 1,
    "تغيظ": 1,
    "غافت": 1,
    "غيف": 1,
    "أغاف": 1,
    "تغيف": 1,
    "الغاف": 1,
    "غيق": 1,
    "تغيق": 1,
    "غاق": 1,
    "الغاقة": 1,
    "غالت": 1,
    "أغالت": 1,
    "أغيلت": 1,
    "تغيل": 1,
    "الأغيل": 1,
    "الغيل": 1,
    "غامت": 1,
    "أغامت": 1,
    "أغيمت": 1,
    "غيمت": 1,
    "تغيمت": 1,
    "الغيم": 1,
    "المغيوم": 1,
    "غانت": 1,
    "أغبن": 1,
    "الغانة": 1,
    "الغينة": 1,
    "أغيا": 1,
    "غايا": 1,
    "تغايا": 1,
    "الغياية": 1,
    "الفاء": 1,
    "الفالوذ": 1,
    "فأده": 1,
    "فئد": 1,
    "افتأد": 1,
    "تفأدت": 1,
    "الفئيد": 1,
    "المفآد والمفأد والمفأدة": 1,
    "فئر": 1,
    "الفؤارة": 1,
    "المفأر": 1,
    "فأق": 1,
    "فئق": 1,
    "تفأق": 1,
    "الفائق": 1,
    "الفأق": 1,
    "الفؤاق": 1,
    "فاءله": 1,
    "افتأل": 1,
    "تفاءل": 1,
    "تفأل": 1,
    "الفئال": 1,
    "المفائل": 1,
    "فأم": 1,
    "أفأم": 1,
    "الفئام": 1,
    "الفؤمة": 1,
    "فأى": 1,
    "انفأى": 1,
    "تفأى": 1,
    "الفأو": 1,
    "انفت": 1,
    "تفتت": 1,
    "الفتة والفتة": 1,
    "الفتوت": 1,
    "الفتيت": 1,
    "الفتيتة": 1,
    "فاتحه": 1,
    "افتتح": 1,
    "انفتح": 1,
    "تفاتحا": 1,
    "استفتح": 1,
    "المفتح": 1,
    "فتخه": 1,
    "فتخ": 1,
    "أفتخ": 1,
    "تفتخ": 1,
    "الفتخ": 1,
    "الفتخاء": 1,
    "الفتخة": 1,
    "أفتر": 1,
    "الفاتر": 1,
    "الفتار": 1,
    "الفتر": 1,
    "الفتاش": 1,
    "أفتق": 1,
    "انفتق": 1,
    "الفتاق": 1,
    "الفتقة": 1,
    "الفتيق": 1,
    "المفتق": 1,
    "المفتقة": 1,
    "أفتك": 1,
    "تفتك": 1,
    "الفتاك": 1,
    "أفتل": 1,
    "تفتل": 1,
    "الفتل": 1,
    "الفتيل": 1,
    "الفتيلة": 1,
    "فتنه": 1,
    "افتتن": 1,
    "تفاتن": 1,
    "الفتان": 1,
    "الفتين": 1,
    "فتاه": 1,
    "أفتى": 1,
    "فاتاه": 1,
    "تفاتى": 1,
    "تفتى": 1,
    "استفتاه": 1,
    "الفتوى": 1,
    "الفتيا": 1,
    "فثئ": 1,
    "أفثأ": 1,
    "فث": 1,
    "افتثه": 1,
    "انفث": 1,
    "الفث": 1,
    "فثج": 1,
    "أفثج": 1,
    "فثد": 1,
    "الفثائيد": 1,
    "الفاثور": 1,
    "فثغ": 1,
    "فجأه": 1,
    "فاجأه": 1,
    "الفجأة": 1,
    "الفجاءة": 1,
    "أفج": 1,
    "فاج": 1,
    "افتج": 1,
    "انفجت": 1,
    "تفاج": 1,
    "الإفجيج": 1,
    "الفجاج": 1,
    "الفجاجة": 1,
    "الفج": 1,
    "الفجة": 1,
    "أفجر": 1,
    "افتجر": 1,
    "فجره": 1,
    "فجس": 1,
    "تفجس": 1,
    "فجعه": 1,
    "الفاجع": 1,
    "الفاجعة": 1,
    "الفجوع": 1,
    "الفجيعة": 1,
    "المفجع": 1,
    "افتجل": 1,
    "الأفجل": 1,
    "الفجال": 1,
    "فجم": 1,
    "انفجم": 1,
    "تفجم": 1,
    "الفجمة": 1,
    "فجي": 1,
    "أفجى": 1,
    "فجاه": 1,
    "تفاجى": 1,
    "الفجواء": 1,
    "فحث": 1,
    "افتحث": 1,
    "الفحث": 1,
    "الفحثة": 1,
    "أفحج": 1,
    "انفحجت": 1,
    "فحت": 1,
    "الفحة": 1,
    "أفحش": 1,
    "تفحش": 1,
    "فحصت": 1,
    "افتحص": 1,
    "الأفحوص": 1,
    "الفحصة": 1,
    "المفحص": 1,
    "فحفح": 1,
    "أفحل": 1,
    "افتحل": 1,
    "تفحل": 1,
    "استفحل": 1,
    "الفحالة": 1,
    "الفحال": 1,
    "الفحل": 1,
    "الفحلة": 1,
    "الفحيل": 1,
    "أفحم": 1,
    "الفاحم": 1,
    "الفحامة": 1,
    "الفحام": 1,
    "الفحم والفحم": 1,
    "الفحمة": 1,
    "الفحيم": 1,
    "فحا": 1,
    "فحى": 1,
    "فاحاه": 1,
    "الفحا": 1,
    "الفحوة": 1,
    "الفحية": 1,
    "فختت": 1,
    "تفخت": 1,
    "الفاختة": 1,
    "الفخت": 1,
    "فخج": 1,
    "فخذه": 1,
    "تفخذ": 1,
    "الفخذ والفخذ": 1,
    "فاخره": 1,
    "فخره": 1,
    "افتخر": 1,
    "استفخر": 1,
    "الفاخر": 1,
    "الفاخور": 1,
    "الفاخورة": 1,
    "الفخارة": 1,
    "الفخاري": 1,
    "المفخر والمفخرة": 1,
    "الفخفخة": 1,
    "فخمه": 1,
    "تفخمه": 1,
    "الفيخمان": 1,
    "فدحه": 1,
    "استفدح": 1,
    "فد": 1,
    "فدد": 1,
    "الفداد": 1,
    "الفدادة": 1,
    "الفديد": 1,
    "أفدر": 1,
    "تفدر": 1,
    "الفادر": 1,
    "الفادرة": 1,
    "الفدر": 1,
    "الفدرة": 1,
    "الفدور": 1,
    "المفدرة": 1,
    "فدع": 1,
    "فدعه": 1,
    "الفدع": 1,
    "فدغ": 1,
    "انفدغ": 1,
    "الفدفد": 1,
    "الفدفدة": 1,
    "أفدمه": 1,
    "الفدام": 1,
    "الفدامة": 1,
    "الفدم": 1,
    "المفدمات": 1,
    "الفدن": 1,
    "فداه": 1,
    "أفدى": 1,
    "فاداه": 1,
    "تفادى": 1,
    "الفدائية": 1,
    "أفذت": 1,
    "تفذذ": 1,
    "استفذ": 1,
    "الأفذ": 1,
    "الفاذة": 1,
    "الفذاذى": 1,
    "الفذاذ": 1,
    "الفذ": 1,
    "الفذة": 1,
    "المفذاذ": 1,
    "الفذلكة": 1,
    "الفرأ": 1,
    "الفريء": 1,
    "فرتك": 1,
    "فرتن": 1,
    "فرتنى": 1,
    "الفرتنى": 1,
    "فرثت": 1,
    "أفرث": 1,
    "انفرثت": 1,
    "تفرث": 1,
    "الفراثة": 1,
    "الفرث": 1,
    "انفرج": 1,
    "التفاريج": 1,
    "الفرجة": 1,
    "الفرجية": 1,
    "الفروج": 1,
    "الفريج": 1,
    "المفرج": 1,
    "فرجن": 1,
    "الفرجون": 1,
    "أفرحه": 1,
    "الفرحة": 1,
    "المفراح": 1,
    "المفرح": 1,
    "أفرخ": 1,
    "استفرخ": 1,
    "الفرخة": 1,
    "الفروخ": 1,
    "المفارخ": 1,
    "أفردت": 1,
    "انفرد": 1,
    "استفرد": 1,
    "الفارد": 1,
    "الفردان": 1,
    "الفردة": 1,
    "الفراد": 1,
    "الفرود": 1,
    "الفريد": 1,
    "المفراد": 1,
    "فردس": 1,
    "الفرادس": 1,
    "الفردسة": 1,
    "أفرت": 1,
    "فاررته": 1,
    "افتر": 1,
    "تفار": 1,
    "الفر": 1,
    "الفرة": 1,
    "الفرى": 1,
    "الفرير": 1,
    "أفرزه": 1,
    "افترز": 1,
    "تفارزا": 1,
    "الإفرير": 1,
    "المفروز": 1,
    "الفرزدق": 1,
    "أفرس": 1,
    "فارسه": 1,
    "افترس": 1,
    "تفرس": 1,
    "الأفرس": 1,
    "الفراس": 1,
    "فرس البحر": 1,
    "الفرسة": 1,
    "الفريس": 1,
    "الفرسخ": 1,
    "المفرسخ": 1,
    "الفرسن": 1,
    "المفرسن": 1,
    "أفرش": 1,
    "انفرش": 1,
    "تفرش": 1,
    "الفريش": 1,
    "المفرشة": 1,
    "المفروشة": 1,
    "تفرشح": 1,
    "الفرشاح": 1,
    "أفرصت": 1,
    "فارصه": 1,
    "افترص": 1,
    "تفارص": 1,
    "تفرص": 1,
    "الفراص": 1,
    "الفرصاء": 1,
    "الفريص": 1,
    "الفريصة": 1,
    "المفراص": 1,
    "المفرص": 1,
    "الفرصاد": 1,
    "أفرض": 1,
    "الفراض": 1,
    "الفرضة": 1,
    "الفرضي": 1,
    "الفريض": 1,
    "المفراض": 1,
    "المفرضة": 1,
    "المفرض": 1,
    "افترط": 1,
    "تفارط": 1,
    "تفرط": 1,
    "الفراطة": 1,
    "الفرطي": 1,
    "المفارط": 1,
    "فرطس": 1,
    "الفرطاس": 1,
    "الفرطوسة": 1,
    "أفرع": 1,
    "افترع": 1,
    "الأفرع": 1,
    "الفارع": 1,
    "الفريعاء": 1,
    "فرعن": 1,
    "تفرعن": 1,
    "افترغ": 1,
    "الفراغة": 1,
    "الفرغ": 1,
    "الفريغ": 1,
    "المفرغة": 1,
    "الفرافر": 1,
    "الفرفار": 1,
    "الفرفر": 1,
    "الفرفير": 1,
    "الفرفيرين": 1,
    "في الطب": 1,
    "الفرفيريني": 1,
    "أفرق": 1,
    "فارقه": 1,
    "انفرق": 1,
    "تفارق": 1,
    "التفاريق": 1,
    "الفاروقة": 1,
    "الفرق والفرق": 1,
    "الفرقد": 1,
    "الفرقود": 1,
    "افرنقع": 1,
    "تفرقعت": 1,
    "أفرك": 1,
    "انفرك": 1,
    "تفرك": 1,
    "استفرك": 1,
    "الفريك": 1,
    "الفريكة": 1,
    "المفروكة": 1,
    "أفرم": 1,
    "الفرامة": 1,
    "الفران": 1,
    "الفرنية": 1,
    "الفرند": 1,
    "الفرانق": 1,
    "أفره": 1,
    "استفره": 1,
    "فرهد": 1,
    "تفرهد": 1,
    "الفرهد": 1,
    "الفرهود": 1,
    "الفرا": 1,
    "أفرى": 1,
    "انفرى": 1,
    "تفرى": 1,
    "لفرية": 1,
    "الفري": 1,
    "أفزر": 1,
    "انفرز": 1,
    "تفزر": 1,
    "الفازرة": 1,
    "الفزارة": 1,
    "الفزر": 1,
    "الفزراء": 1,
    "الفزرة": 1,
    "أفزه": 1,
    "تفازا": 1,
    "استفزه": 1,
    "الفز": 1,
    "الفزة": 1,
    "أفزعه": 1,
    "فزعه": 1,
    "الفزعة": 1,
    "المفازع": 1,
    "المفزعة": 1,
    "فزفزه": 1,
    "فسأ": 1,
    "فسئ": 1,
    "تفسأ": 1,
    "المفسوء": 1,
    "الفستقي": 1,
    "أفسح": 1,
    "انفسح": 1,
    "تفاسح": 1,
    "تفسح": 1,
    "أفسخ": 1,
    "فاسخه": 1,
    "انفسخ": 1,
    "تفاسخ": 1,
    "الفسيخ": 1,
    "فسده": 1,
    "تفاسد": 1,
    "استفسد": 1,
    "المفسدة": 1,
    "استفسره": 1,
    "التفسرة": 1,
    "الفسيط": 1,
    "الفسفاس": 1,
    "الفسفس": 1,
    "فسقه": 1,
    "انفسقت": 1,
    "الفسيق": 1,
    "الفسقية": 1,
    "فسكل": 1,
    "الفسكول": 1,
    "أفسل": 1,
    "افتسل": 1,
    "الفسالة": 1,
    "الفسل": 1,
    "الفسولة": 1,
    "الفسيلة": 1,
    "المفسى": 1,
    "الفاسياء": 1,
    "فشج": 1,
    "تفشج": 1,
    "تفشخ": 1,
    "انفشت": 1,
    "الفش": 1,
    "الفشاش": 1,
    "الفشة": 1,
    "الفشوش": 1,
    "الفشيش": 1,
    "فشغ": 1,
    "فشغت": 1,
    "فاشغ": 1,
    "فشغه": 1,
    "انفشغ": 1,
    "تفشغ": 1,
    "الأفشغ": 1,
    "الفشغة": 1,
    "فشفش": 1,
    "الفشفاش": 1,
    "فشق": 1,
    "فاشقه": 1,
    "تفشق": 1,
    "تفشل": 1,
    "الفيشلة": 1,
    "أفشاه": 1,
    "تفشى": 1,
    "الفشاء": 1,
    "الفشوة": 1,
    "فصحه": 1,
    "تفاصح": 1,
    "تفصح": 1,
    "الفصيح": 1,
    "أفصدت": 1,
    "افتصد": 1,
    "انفصد": 1,
    "تفصد": 1,
    "الفاصدان": 1,
    "أبو فصادة": 1,
    "المفصد": 1,
    "أفص": 1,
    "فصصه": 1,
    "افتص": 1,
    "انفص": 1,
    "استفص": 1,
    "الفصاص": 1,
    "الفصيص": 1,
    "فصع": 1,
    "افتصع": 1,
    "انفصع": 1,
    "تفصع": 1,
    "الأفصع": 1,
    "الفصعان": 1,
    "الفصعة": 1,
    "فصفص": 1,
    "تفصفصوا": 1,
    "الفصافص": 1,
    "الفصفص": 1,
    "الفصفصة": 1,
    "افتصلت": 1,
    "انفصل": 1,
    "الفاصولية والفاصولياء": 1,
    "الفصال": 1,
    "الفصلة": 1,
    "الفيصل": 1,
    "المفص ليات": 1,
    "أفصم": 1,
    "فصمه": 1,
    "انفصم": 1,
    "تفصم": 1,
    "الفصمة": 1,
    "الفصيم": 1,
    "أفصى": 1,
    "فصاه": 1,
    "انفصى": 1,
    "تفصى": 1,
    "تفضج": 1,
    "فضحه": 1,
    "تفاضح": 1,
    "فضخ": 1,
    "أفضخ": 1,
    "افتضخ": 1,
    "انفضخ": 1,
    "الفضوخ": 1,
    "الفضيخ": 1,
    "المفضخة": 1,
    "فضض": 1,
    "افتض": 1,
    "الفضاض": 1,
    "الفض": 1,
    "الفضض": 1,
    "الفضيض": 1,
    "المفضاض": 1,
    "المفض والمفضة": 1,
    "فضفض": 1,
    "تفضفض": 1,
    "الفضافضة": 1,
    "الفضفاض": 1,
    "الفضفاضة": 1,
    "فاضله": 1,
    "استفضل": 1,
    "الفاضل": 1,
    "الفاضلة": 1,
    "الفضال": 1,
    "الفضالة": 1,
    "الفضا": 1,
    "فطأ": 1,
    "فطئ": 1,
    "أفطأ": 1,
    "تفاطأ": 1,
    "فاطأ": 1,
    "الفطأة": 1,
    "فطحت": 1,
    "الأفطح": 1,
    "أفطر": 1,
    "افتطر": 1,
    "تفطر": 1,
    "التفاطير": 1,
    "الفطار": 1,
    "الفطاري": 1,
    "الفطور": 1,
    "الفطير": 1,
    "الفطسة": 1,
    "الفطيس": 1,
    "الفطيسة": 1,
    "أفطم": 1,
    "انفطم": 1,
    "الفاطمية": 1,
    "الفطام": 1,
    "الفطيم": 1,
    "الفواطم": 1,
    "فاطنه": 1,
    "فطنه": 1,
    "الفطانة": 1,
    "افتظ": 1,
    "أفظع": 1,
    "استفظع": 1,
    "فعفع": 1,
    "الفعفاع": 1,
    "افتعل": 1,
    "انفعل": 1,
    "تفاعلا": 1,
    "الأفعولة": 1,
    "الفعلة": 1,
    "المفاعل الذري": 1,
    "المفتعل": 1,
    "المفعولية": 1,
    "فعمه": 1,
    "الأفعم": 1,
    "الفعم": 1,
    "فعى": 1,
    "تفعى": 1,
    "الأفعوان": 1,
    "المفعاة": 1,
    "أفغر": 1,
    "انفغر": 1,
    "الفاغرة": 1,
    "الفغرة": 1,
    "فغم": 1,
    "أفغم": 1,
    "فغمه": 1,
    "افتغم": 1,
    "انفغم": 1,
    "تفغم": 1,
    "الفغم": 1,
    "الفغمة": 1,
    "المفغوم": 1,
    "فغا": 1,
    "فغي": 1,
    "أفغى": 1,
    "الفاغية": 1,
    "الفغى": 1,
    "الفغو": 1,
    "الفغوة": 1,
    "المفغو": 1,
    "أفقأ": 1,
    "انفقأ": 1,
    "تفقأ": 1,
    "الفاقياء": 1,
    "الفقء": 1,
    "الفقأة": 1,
    "المفقئة": 1,
    "فقح": 1,
    "تفاقح": 1,
    "تفقح": 1,
    "الفقاحة": 1,
    "الفقاحية": 1,
    "أفقده": 1,
    "افتقد": 1,
    "تفاقد": 1,
    "الفاقد": 1,
    "افتقر": 1,
    "تفاقر": 1,
    "تفقرت": 1,
    "الفاقرة": 1,
    "الفقارة": 1,
    "المفاقر": 1,
    "انفقس": 1,
    "تفاقسا": 1,
    "الفقوس": 1,
    "المفقاس": 1,
    "الفقوص": 1,
    "انفقع": 1,
    "تفاقعت": 1,
    "تفقعت": 1,
    "الأفقع": 1,
    "الفاقع": 1,
    "الفاقعة": 1,
    "الفقع": 1,
    "الفقاع": 1,
    "فقفق": 1,
    "الفقفاق": 1,
    "انفق": 1,
    "الفقاق": 1,
    "الأفقم": 1,
    "الفقم": 1,
    "أفقهه": 1,
    "فاقهه": 1,
    "فقهه": 1,
    "تفقه": 1,
    "الفقاهة": 1,
    "الفقيه": 1,
    "أفكر": 1,
    "افتكر": 1,
    "الفكرى": 1,
    "الفكير": 1,
    "استفك": 1,
    "الأفك": 1,
    "الفاك": 1,
    "الأفكل": 1,
    "فكن": 1,
    "تفكن": 1,
    "الفكنة": 1,
    "أفكهت": 1,
    "تفاكه": 1,
    "الأفكوهة": 1,
    "الفاكه": 1,
    "الفاكهاني": 1,
    "الفاكهي": 1,
    "الفكه": 1,
    "الفكيهة": 1,
    "الفيكهان": 1,
    "فالته": 1,
    "افتلت": 1,
    "انفلت": 1,
    "تفلت": 1,
    "استفلت": 1,
    "الفلت": 1,
    "الفلتان": 1,
    "الفلتة": 1,
    "أفلج": 1,
    "فالجه": 1,
    "تفلج": 1,
    "الفالج": 1,
    "الفلج": 1,
    "الفلجات": 1,
    "الفلجة": 1,
    "الفلوجة": 1,
    "المفلج": 1,
    "انفلحت": 1,
    "تفلح": 1,
    "استفلح": 1,
    "الأفلاح": 1,
    "الفلح": 1,
    "الفلحة": 1,
    "تفلحس": 1,
    "الفلحاس": 1,
    "الفلحس": 1,
    "فالذه": 1,
    "افتلذ": 1,
    "الفالوذ والفالوذج": 1,
    "الفلذ": 1,
    "الفلذة": 1,
    "المفلوذ": 1,
    "الفلز": 1,
    "أفلس": 1,
    "الفلس": 1,
    "فليسة": 1,
    "تفلسف": 1,
    "أفلص": 1,
    "فلصه": 1,
    "تفلص": 1,
    "الفلطاح": 1,
    "فلعه": 1,
    "انفلع": 1,
    "تفلع": 1,
    "فلغ": 1,
    "تفلغ": 1,
    "فلغمون": 1,
    "تفلفل": 1,
    "المفلفل": 1,
    "الفليفلة": 1,
    "فلقت": 1,
    "أفلق": 1,
    "افتلق": 1,
    "انفلق": 1,
    "تفيلق": 1,
    "الفالق": 1,
    "الفلاق": 1,
    "الفلاقة": 1,
    "الفلقان": 1,
    "الفلقى": 1,
    "الفليق": 1,
    "الفليقة": 1,
    "المفلاق": 1,
    "المفلق": 1,
    "تفلك": 1,
    "استفلك": 1,
    "الفلاكة": 1,
    "الفلكة": 1,
    "الفليكة": 1,
    "المفلوك": 1,
    "فلكلور": 1,
    "فلل": 1,
    "افتل": 1,
    "انفل": 1,
    "تفلل": 1,
    "الأفل": 1,
    "الفل": 1,
    "الفلة": 1,
    "الفلى": 1,
    "الفلية": 1,
    "افتلم": 1,
    "تفيلم": 1,
    "الفلهد": 1,
    "الفلهود": 1,
    "فلاه": 1,
    "أفلى": 1,
    "افتلى": 1,
    "تفالى": 1,
    "تفلى": 1,
    "استفلى": 1,
    "الفلاة": 1,
    "الفلاية": 1,
    "الفلو والفلو": 1,
    "فنجل": 1,
    "الفنجال": 1,
    "الفنجانة": 1,
    "فنخه": 1,
    "الفنيخ": 1,
    "المفنخ": 1,
    "أفند": 1,
    "افتند": 1,
    "تفند": 1,
    "الفند": 1,
    "الفندة": 1,
    "فنس": 1,
    "الفنس": 1,
    "فنش": 1,
    "الفنطاس": 1,
    "فنع": 1,
    "الفونغراف": 1,
    "أفنق": 1,
    "فنقه": 1,
    "تفنق": 1,
    "الفنيق": 1,
    "الفنيقة": 1,
    "أفنك": 1,
    "أفنت": 1,
    "افتن": 1,
    "استفن": 1,
    "الأفنون": 1,
    "الفنن": 1,
    "الفينان": 1,
    "المفن": 1,
    "أفنى": 1,
    "فاناه": 1,
    "تفانى": 1,
    "الأفنى": 1,
    "الأفناء": 1,
    "الفناة": 1,
    "فهد": 1,
    "الأفهود": 1,
    "الفهدة": 1,
    "الفهاد": 1,
    "الفوهد": 1,
    "أفهر": 1,
    "فهرت": 1,
    "تفهر": 1,
    "الفهر": 1,
    "الفهرة": 1,
    "المفاهر": 1,
    "الفهرست": 1,
    "فهفه": 1,
    "الفهفه": 1,
    "فهق": 1,
    "أفهق": 1,
    "تفهق": 1,
    "تفيهق": 1,
    "الفهقة": 1,
    "الفيهق": 1,
    "أفهمه": 1,
    "تفاهم": 1,
    "تفهم": 1,
    "استفهمه": 1,
    "الفهامة": 1,
    "فه": 1,
    "أفهه": 1,
    "فههه": 1,
    "الفهاهة": 1,
    "الفهة": 1,
    "فها": 1,
    "أفهى": 1,
    "الأفهاء": 1,
    "أفاته": 1,
    "فوته": 1,
    "افتات": 1,
    "تفوت": 1,
    "الفوت": 1,
    "أفاج": 1,
    "الفائج": 1,
    "الفائجة": 1,
    "أفاح": 1,
    "تفاوح": 1,
    "فاخت": 1,
    "أفاد": 1,
    "تفود": 1,
    "استفاد": 1,
    "الفود": 1,
    "المفواد": 1,
    "أفار": 1,
    "الفوارة": 1,
    "الفارة": 1,
    "الفورة": 1,
    "الفيار": 1,
    "الفيرة": 1,
    "الفيور": 1,
    "أفازه": 1,
    "تفوز": 1,
    "الفائزة": 1,
    "الفازة": 1,
    "المفاز": 1,
    "المفازة": 1,
    "فاوضه": 1,
    "تفاوضا": 1,
    "الفوضة": 1,
    "فوطه": 1,
    "الفوطي": 1,
    "الفواط": 1,
    "فاظت": 1,
    "فاع": 1,
    "الفوعة": 1,
    "فاف": 1,
    "الفوف": 1,
    "المفوف": 1,
    "افتاق": 1,
    "استفاق": 1,
    "الفوقة": 1,
    "الفيقة": 1,
    "المفوق": 1,
    "الفومة": 1,
    "فاهاه": 1,
    "تفاوه": 1,
    "الفاه": 1,
    "الفوه": 1,
    "الفوهاء": 1,
    "المفوه": 1,
    "فيأت": 1,
    "تفيأت": 1,
    "استفاء": 1,
    "الفيء": 1,
    "الفيئة": 1,
    "المفاء": 1,
    "المفيء": 1,
    "الفائج والفائجة": 1,
    "الفيج": 1,
    "فيح": 1,
    "الفياح": 1,
    "الفياحة": 1,
    "فادت": 1,
    "تفايدا": 1,
    "تفيد": 1,
    "الفيد": 1,
    "الفياد": 1,
    "الفيروزج": 1,
    "فاش": 1,
    "فايش": 1,
    "تفايش": 1,
    "تفيش": 1,
    "الفيشة": 1,
    "الفيوش": 1,
    "الفياش": 1,
    "فاص": 1,
    "أفاص": 1,
    "المفيص": 1,
    "الإفاضة": 1,
    "الفيوض": 1,
    "الفياض": 1,
    "المفاض": 1,
    "فاظ": 1,
    "أفاظه": 1,
    "الفيظ": 1,
    "الفيف": 1,
    "الفيفاء": 1,
    "أفيق": 1,
    "فايله": 1,
    "تفيل": 1,
    "استفيل": 1,
    "الفائل": 1,
    "الفائلتان": 1,
    "الفال": 1,
    "الفيال": 1,
    "قأب": 1,
    "قئب": 1,
    "القؤوب": 1,
    "المقأب": 1,
    "القئفي": 1,
    "أقب": 1,
    "تقبب": 1,
    "القابة": 1,
    "القباب": 1,
    "القب": 1,
    "القبي": 1,
    "القبيب": 1,
    "المقبب": 1,
    "المقببة": 1,
    "المقبوبة": 1,
    "القبج": 1,
    "قابحه": 1,
    "قبحه": 1,
    "استقبحه": 1,
    "القباح": 1,
    "القبيح": 1,
    "المقابح": 1,
    "أقبر": 1,
    "القبار": 1,
    "أقبسه": 1,
    "اقتبس": 1,
    "القابوس": 1,
    "القبس": 1,
    "القبسة": 1,
    "القوابس": 1,
    "المقباس": 1,
    "اقتبص": 1,
    "انقبص": 1,
    "تقبص": 1,
    "القابصة": 1,
    "القبص": 1,
    "القبصة": 1,
    "القبيص": 1,
    "المقبص": 1,
    "أقبض": 1,
    "قابضه": 1,
    "اقتبض": 1,
    "تقبض": 1,
    "القبيض": 1,
    "المقبض والمقبضة": 1,
    "المنقبض": 1,
    "القبطية": 1,
    "اقتبع": 1,
    "انقبع": 1,
    "القابعة": 1,
    "القباع": 1,
    "القبيعة": 1,
    "القبع": 1,
    "القوبع": 1,
    "تقبقب": 1,
    "القباقب": 1,
    "اقتبل": 1,
    "تقابلا": 1,
    "استقبله": 1,
    "القابول": 1,
    "القبال": 1,
    "القبالة": 1,
    "المقبلة": 1,
    "أقبن": 1,
    "القبانة": 1,
    "القبان": 1,
    "القباني": 1,
    "القبين": 1,
    "قباه": 1,
    "قبى": 1,
    "انقبى": 1,
    "تقبى": 1,
    "القابياء": 1,
    "القابية": 1,
    "القباء": 1,
    "القبوة": 1,
    "قتبه": 1,
    "أقتب": 1,
    "القتب": 1,
    "القتبة": 1,
    "القتوبة": 1,
    "قتت": 1,
    "القت": 1,
    "القتات": 1,
    "قتدت": 1,
    "قتد": 1,
    "القتد": 1,
    "أقتر": 1,
    "تقاتر": 1,
    "تقتر": 1,
    "القاتر": 1,
    "القتار": 1,
    "القتر": 1,
    "القترة": 1,
    "القتور": 1,
    "القتير": 1,
    "قتع": 1,
    "قاتعه": 1,
    "القتع": 1,
    "القتعة": 1,
    "أقتله": 1,
    "تقتل": 1,
    "استقتل": 1,
    "القتول": 1,
    "المقتتل": 1,
    "المقتلة": 1,
    "أقتم": 1,
    "الأقتم": 1,
    "القاتم": 1,
    "القتام": 1,
    "القتم": 1,
    "القتمة": 1,
    "قتن": 1,
    "أقتن": 1,
    "القتين": 1,
    "قتا": 1,
    "اقتوى": 1,
    "المقتوي": 1,
    "المقتوين": 1,
    "أقثأ": 1,
    "القثاء": 1,
    "المقثأة": 1,
    "اقتث": 1,
    "القثاث": 1,
    "القثاثة": 1,
    "القثيث": 1,
    "القثيثة": 1,
    "المقثة": 1,
    "قثم": 1,
    "اقتثم": 1,
    "قثام": 1,
    "القثم": 1,
    "القثوم": 1,
    "قثا": 1,
    "اقتثى": 1,
    "قحب": 1,
    "قاحبت": 1,
    "تقحبت": 1,
    "القحاب": 1,
    "القحب": 1,
    "القحبة": 1,
    "القحاح": 1,
    "القح": 1,
    "قحر": 1,
    "القحارية": 1,
    "القحر": 1,
    "قحز": 1,
    "قحزه": 1,
    "القحاز": 1,
    "القحازة": 1,
    "أقحط": 1,
    "القحط": 1,
    "المقحط": 1,
    "المقحطة": 1,
    "قاحفه": 1,
    "اقتحف": 1,
    "القحاف": 1,
    "القحافة": 1,
    "القحف": 1,
    "القحفاء": 1,
    "القحوف": 1,
    "المقحفة": 1,
    "أقحل": 1,
    "قاحله": 1,
    "تقحل": 1,
    "القاحل": 1,
    "القحال": 1,
    "قحمه": 1,
    "انقحم": 1,
    "تقحمت": 1,
    "القحامة": 1,
    "القحم": 1,
    "القحمة": 1,
    "القحوم": 1,
    "المقحمة": 1,
    "قحا": 1,
    "أقحت": 1,
    "اقتحى": 1,
    "القحوان": 1,
    "المقحاة": 1,
    "قادحه": 1,
    "تقادحا": 1,
    "استقدح": 1,
    "القادح": 1,
    "القادحة": 1,
    "القدوح": 1,
    "القديح": 1,
    "المتقادح": 1,
    "المقداح": 1,
    "المقدح": 1,
    "المقدح والمقدحة": 1,
    "انقد": 1,
    "تقدد": 1,
    "القداد": 1,
    "القدة": 1,
    "القديد": 1,
    "المقد": 1,
    "المقد والمقدة": 1,
    "المقدودة": 1,
    "أقدره": 1,
    "قادره": 1,
    "اقتدر": 1,
    "انقدر": 1,
    "تقادر": 1,
    "استقدر": 1,
    "القادرة": 1,
    "القدراء": 1,
    "المقتدر": 1,
    "تقدس": 1,
    "القادس": 1,
    "القادوس": 1,
    "قدع": 1,
    "أقدعه": 1,
    "قادعه": 1,
    "انقدع": 1,
    "تقادع": 1,
    "تقدع": 1,
    "القدع": 1,
    "القدعة": 1,
    "القدوع": 1,
    "المقدعة": 1,
    "قدف": 1,
    "القداف": 1,
    "استقدم": 1,
    "القدام": 1,
    "القدمة": 1,
    "القدمية": 1,
    "القيدام": 1,
    "المقدام والمقدامة": 1,
    "أقدى": 1,
    "قاداه": 1,
    "تقدى": 1,
    "اقتدى": 1,
    "القادية": 1,
    "القاديانية": 1,
    "القدا": 1,
    "القدى": 1,
    "القداة": 1,
    "القداوة": 1,
    "القدو": 1,
    "القدوى": 1,
    "القدوة": 1,
    "القدي والقدي": 1,
    "قذه": 1,
    "أقذ": 1,
    "تقذذ": 1,
    "الأقذ": 1,
    "القاذ والقاذة": 1,
    "القذاذة": 1,
    "القذان": 1,
    "المقذ": 1,
    "المقذ والمقذة": 1,
    "المقذذ": 1,
    "قذره": 1,
    "أقذره": 1,
    "تقذره": 1,
    "استقذر": 1,
    "القاذورة": 1,
    "القذر": 1,
    "القذرة": 1,
    "القذور": 1,
    "المقذر": 1,
    "قذعه": 1,
    "أقذعه": 1,
    "قاذعه": 1,
    "تقذع": 1,
    "الأقذع": 1,
    "القذيع": 1,
    "القذيعة": 1,
    "المقذعات": 1,
    "المقذع": 1,
    "القذ عملة": 1,
    "تقاذفوا": 1,
    "الأقذاف": 1,
    "قاذفة القنابل": 1,
    "القذاف": 1,
    "القذاف والقذافة": 1,
    "القذيفى": 1,
    "القذفة": 1,
    "المتقاذف": 1,
    "المقاذف": 1,
    "المقذاف": 1,
    "المقذف": 1,
    "المقذفة": 1,
    "القاذل": 1,
    "القذال": 1,
    "قذم": 1,
    "القذام": 1,
    "القذم": 1,
    "القذمة": 1,
    "القذوم": 1,
    "أقذت": 1,
    "قاذاه": 1,
    "اقتذى": 1,
    "الأقذاء": 1,
    "القذاة": 1,
    "أقرأت": 1,
    "قارأه": 1,
    "اقترأ": 1,
    "استقرأه": 1,
    "الاستقراء": 1,
    "أقرأ": 1,
    "القرء": 1,
    "المقرأة": 1,
    "أقربت": 1,
    "تقاربا": 1,
    "استقربه": 1,
    "التقربات": 1,
    "القراب": 1,
    "القورب": 1,
    "المقربات": 1,
    "القربوس": 1,
    "القرت": 1,
    "قرثه": 1,
    "قرث": 1,
    "قرحه": 1,
    "أقرح": 1,
    "قارحه": 1,
    "اقترح": 1,
    "القارح": 1,
    "القراح": 1,
    "القريح": 1,
    "القريحة": 1,
    "أقرد": 1,
    "تقرد": 1,
    "القراد": 1,
    "قردح": 1,
    "اقرندح": 1,
    "القردحة": 1,
    "القردوح": 1,
    "القردد": 1,
    "القردود": 1,
    "القردودة": 1,
    "القرديدة": 1,
    "القردع": 1,
    "القردوع": 1,
    "قاره": 1,
    "اقتر": 1,
    "تقار": 1,
    "تقرر": 1,
    "القر": 1,
    "القرة": 1,
    "قرة العين": 1,
    "القرور": 1,
    "المقرة": 1,
    "المقرور": 1,
    "قرسه": 1,
    "أقرس": 1,
    "القارس": 1,
    "القرس": 1,
    "القريس": 1,
    "أقرشت": 1,
    "اقترش": 1,
    "تقارش": 1,
    "تقرش": 1,
    "القرشة": 1,
    "القرواش": 1,
    "القريش": 1,
    "المقرشة": 1,
    "القرشب": 1,
    "قارصه": 1,
    "تقارصا": 1,
    "القارص": 1,
    "القارصة": 1,
    "القراصيا": 1,
    "القروص": 1,
    "المقراص": 1,
    "المقرص": 1,
    "قرصع": 1,
    "تقرصعت": 1,
    "أقرضه": 1,
    "قارضه": 1,
    "اقترض": 1,
    "انقرض": 1,
    "تقارضا": 1,
    "استقرض": 1,
    "المقراض": 1,
    "المقرض": 1,
    "قرضب": 1,
    "القرضاب": 1,
    "قرطت": 1,
    "تقرطت": 1,
    "القاريط": 1,
    "القراط": 1,
    "القراطة": 1,
    "قرطب": 1,
    "تقرطس": 1,
    "القرطاس": 1,
    "القرطوم": 1,
    "تقارظا": 1,
    "القارظ": 1,
    "القراظ": 1,
    "القرظ": 1,
    "القرظة": 1,
    "القرظي": 1,
    "أقرع": 1,
    "اقترعوا": 1,
    "تقارع": 1,
    "تقرع": 1,
    "الأقرع": 1,
    "القراعة": 1,
    "القرعاء": 1,
    "القروع": 1,
    "القريع": 1,
    "المقراع": 1,
    "المقرعة": 1,
    "أقرف": 1,
    "اقترف": 1,
    "تقرفت": 1,
    "القرافة": 1,
    "القروف": 1,
    "المقرف": 1,
    "قرفل": 1,
    "القرق": 1,
    "القراقر": 1,
    "القراقرة": 1,
    "القراقري": 1,
    "القرقار والقرقارة": 1,
    "القرقر": 1,
    "القرقور": 1,
    "القرقير": 1,
    "القرقس": 1,
    "القرقوس": 1,
    "قرقف": 1,
    "تقرقف": 1,
    "القرلى": 1,
    "أقرم": 1,
    "قرمه": 1,
    "تقرم": 1,
    "استقرم": 1,
    "القرام": 1,
    "القرامة": 1,
    "المقرم": 1,
    "المقرمة": 1,
    "القرمد": 1,
    "القرمود": 1,
    "القرميد": 1,
    "القرمش": 1,
    "قرمص": 1,
    "تقرمص": 1,
    "القرماص": 1,
    "القرمص": 1,
    "القرموص": 1,
    "قرمط": 1,
    "تقرمط": 1,
    "القرموط": 1,
    "القرامطة": 1,
    "قرمله": 1,
    "القرمل": 1,
    "القرملة": 1,
    "أقرن": 1,
    "قارنه": 1,
    "اقترن": 1,
    "استقرن": 1,
    "القرانيا": 1,
    "القرناء": 1,
    "القرنان": 1,
    "القرنوة": 1,
    "القرونة": 1,
    "القريناء": 1,
    "القرينة": 1,
    "المقرن": 1,
    "قرنس": 1,
    "القرناس": 1,
    "القاره": 1,
    "قره جوز": 1,
    "قرا": 1,
    "قريت": 1,
    "أقرى": 1,
    "اقترى": 1,
    "تقرى": 1,
    "استقرى": 1,
    "اقرورى": 1,
    "القاري": 1,
    "القاراة": 1,
    "القارية": 1,
    "القرا": 1,
    "القرو": 1,
    "القروى": 1,
    "القرواني": 1,
    "القري": 1,
    "قرية النمل": 1,
    "المقرى": 1,
    "المقراء": 1,
    "قزحت": 1,
    "تقزح": 1,
    "التقازيح": 1,
    "التقزيح": 1,
    "القازحة": 1,
    "القزح": 1,
    "القزحية": 1,
    "المقزحة": 1,
    "القازوزة": 1,
    "القزاز": 1,
    "القزة": 1,
    "القزية": 1,
    "القوازيز": 1,
    "أقزع": 1,
    "تقزع": 1,
    "قوزع": 1,
    "القزعة": 1,
    "القزع": 1,
    "الأقزل": 1,
    "قزمه": 1,
    "تقزم": 1,
    "القزام": 1,
    "القزمة": 1,
    "القزان": 1,
    "قزًا": 1,
    "أقزى": 1,
    "القزو": 1,
    "قسب": 1,
    "القيسبة": 1,
    "اقتسره": 1,
    "قسور": 1,
    "القسور": 1,
    "القسورة": 1,
    "تقسس": 1,
    "القساس": 1,
    "القسي": 1,
    "قسطت": 1,
    "اقتسطوا": 1,
    "القسطاء": 1,
    "المقسط": 1,
    "القسطاس": 1,
    "القسطار": 1,
    "القسطري": 1,
    "القسطل": 1,
    "قسقس": 1,
    "تقسقس": 1,
    "القسقاس": 1,
    "اقتسم": 1,
    "تقاسم": 1,
    "تقسم": 1,
    "استقسم": 1,
    "الاستقسام": 1,
    "الأقسومة": 1,
    "القسام": 1,
    "القسامة": 1,
    "القسامي": 1,
    "القسوم": 1,
    "القسيم": 1,
    "أقسن": 1,
    "الأقسى": 1,
    "المقساة": 1,
    "القسور والقسورة": 1,
    "أقشب": 1,
    "القشب": 1,
    "القشيب": 1,
    "قشده": 1,
    "اقتشد": 1,
    "القشادة": 1,
    "اقتشر": 1,
    "انقشر": 1,
    "تقشر": 1,
    "القشار": 1,
    "القشراء": 1,
    "القشير": 1,
    "المقشرة": 1,
    "المقشورة": 1,
    "أقشت": 1,
    "قشش": 1,
    "تقشش": 1,
    "القشاش": 1,
    "قشطه": 1,
    "انقشطت": 1,
    "تقشطت": 1,
    "المقشط": 1,
    "قشعت": 1,
    "أقشع": 1,
    "انقشع": 1,
    "تقشع": 1,
    "القشع": 1,
    "القشعة": 1,
    "القشاعر": 1,
    "القشعام": 1,
    "القشعامة": 1,
    "القشعم": 1,
    "الأقشف": 1,
    "القشف": 1,
    "تقشقش": 1,
    "قشم": 1,
    "اقتشمه": 1,
    "القشام": 1,
    "القشامة": 1,
    "القشم": 1,
    "القشيم": 1,
    "قشا": 1,
    "أقشى": 1,
    "قشى": 1,
    "تقشى": 1,
    "القشاء": 1,
    "القشاوة": 1,
    "القشوان": 1,
    "القشوة": 1,
    "أقصب": 1,
    "اقتصب": 1,
    "التقصيبة": 1,
    "القاصب": 1,
    "القصابة": 1,
    "القصباء": 1,
    "القصاب": 1,
    "المقصبة": 1,
    "المقصب": 1,
    "أقصد": 1,
    "اقتصد": 1,
    "انقصد": 1,
    "تقصد": 1,
    "القاصد": 1,
    "القصدة": 1,
    "القصيد والقصيدة": 1,
    "المقصد": 1,
    "اقتصر": 1,
    "تقاصر": 1,
    "تقوصر": 1,
    "استقصره": 1,
    "القاصرة": 1,
    "القصار": 1,
    "القصارى": 1,
    "القصارة": 1,
    "القصرة": 1,
    "القصور الذاتي": 1,
    "القصيرى": 1,
    "القوصرة": 1,
    "المقصرة": 1,
    "قصت": 1,
    "قاصه": 1,
    "اقتص": 1,
    "تقاص": 1,
    "استقصه": 1,
    "الأقصوصة": 1,
    "التقاص": 1,
    "القصيص": 1,
    "القصيصة": 1,
    "المقصوصة": 1,
    "القصطل": 1,
    "القاصعاء": 1,
    "القصع": 1,
    "القصعة": 1,
    "أقصف": 1,
    "انقصف": 1,
    "تقاصف": 1,
    "تقصف": 1,
    "القصفة": 1,
    "القصيف": 1,
    "المقصف": 1,
    "القصاقص": 1,
    "القصقص": 1,
    "أقصل": 1,
    "اقتصل": 1,
    "انقصل": 1,
    "تقصل": 1,
    "القصالة": 1,
    "القصل": 1,
    "القصلة": 1,
    "القصيل": 1,
    "المقصل": 1,
    "قصمت": 1,
    "انقصم": 1,
    "تقصم": 1,
    "القاصمة": 1,
    "القصم": 1,
    "القصماء": 1,
    "القصمة": 1,
    "القيصوم": 1,
    "قصمل": 1,
    "القصملة": 1,
    "قاصاه": 1,
    "استقصى": 1,
    "القاصي": 1,
    "القصا": 1,
    "القصاء": 1,
    "القصواء": 1,
    "القصي": 1,
    "القضأة": 1,
    "قضبه": 1,
    "أقضبت": 1,
    "قضبت": 1,
    "اقتضب": 1,
    "انقضب": 1,
    "تقضب": 1,
    "القضابة": 1,
    "القضب": 1,
    "القضبة": 1,
    "القضاب والقضابة": 1,
    "أقض": 1,
    "يتعدى ولا يتعدى": 1,
    "انقض": 1,
    "تقضض": 1,
    "القضاض": 1,
    "القض": 1,
    "القضض": 1,
    "القضة": 1,
    "القضيض": 1,
    "المقض": 1,
    "قضعه": 1,
    "انقضع": 1,
    "تقضع": 1,
    "التقضيع": 1,
    "القضاع والقضاعة": 1,
    "القضفة": 1,
    "قضقضت": 1,
    "تقضقض": 1,
    "القضاقض": 1,
    "القضقاض": 1,
    "قضمت": 1,
    "أقضم": 1,
    "استقضم": 1,
    "القضمة": 1,
    "القضيم": 1,
    "قاضاه": 1,
    "انقضى": 1,
    "تقاضاه": 1,
    "تقضى": 1,
    "استقضى": 1,
    "القضى": 1,
    "أقطب": 1,
    "الاستقطاب": 1,
    "القطاب": 1,
    "القطبة": 1,
    "القطوب": 1,
    "القطيب": 1,
    "المقطوب": 1,
    "أقطر": 1,
    "تقاطر": 1,
    "تقطر": 1,
    "استقطر": 1,
    "الاستقطار": 1,
    "القطور": 1,
    "المقاطرة": 1,
    "تقطرب": 1,
    "القطرب": 1,
    "اقتط": 1,
    "انقط": 1,
    "القطاط": 1,
    "القطائط": 1,
    "القطيطة": 1,
    "المقط": 1,
    "المقطة": 1,
    "أقطع": 1,
    "قطعه": 1,
    "اقتطع": 1,
    "استقطعه": 1,
    "الأقطاع": 1,
    "الأقطع": 1,
    "الأقطوعة": 1,
    "القطيعاء": 1,
    "القطيعة": 1,
    "المقطعات": 1,
    "المقطعة": 1,
    "المنقطع": 1,
    "قطفت": 1,
    "أقطف": 1,
    "قطفه": 1,
    "اقتطف": 1,
    "القطاف": 1,
    "القطافة": 1,
    "القطف": 1,
    "القطفة": 1,
    "القطيف": 1,
    "المقطف": 1,
    "قطقطت": 1,
    "تقطقط": 1,
    "القطقاط": 1,
    "القطقط": 1,
    "المقطقط": 1,
    "قطله": 1,
    "تقطل": 1,
    "القطيلة": 1,
    "المقطلة": 1,
    "قطمه": 1,
    "القطام": 1,
    "القطامة": 1,
    "القطيمة": 1,
    "القطمير": 1,
    "القطان": 1,
    "القطنة": 1,
    "القطين": 1,
    "القيطان": 1,
    "القيطون": 1,
    "المقطنة": 1,
    "تقطى": 1,
    "القطى": 1,
    "القطاة": 1,
    "القطوان": 1,
    "القطوطى": 1,
    "قعب": 1,
    "القعب": 1,
    "القعبة": 1,
    "القعيب": 1,
    "قعبل": 1,
    "القعبل": 1,
    "المقعبل": 1,
    "قعث": 1,
    "أقعث": 1,
    "انقعث": 1,
    "القعاث": 1,
    "القعيث": 1,
    "أقعد": 1,
    "قاعده": 1,
    "قعده": 1,
    "اقتعد": 1,
    "تقعده": 1,
    "الإقعاد": 1,
    "القاعد": 1,
    "القعاد": 1,
    "القعد والقعدة": 1,
    "القعدة": 1,
    "القعدي": 1,
    "القعود": 1,
    "القعيد": 1,
    "القعيدة": 1,
    "المقعدة": 1,
    "القعدد": 1,
    "قعرت": 1,
    "انقعر": 1,
    "تقعر": 1,
    "القعران": 1,
    "القعرة": 1,
    "القعور": 1,
    "القعير": 1,
    "المقعار": 1,
    "تقعست": 1,
    "القعاس": 1,
    "القعس": 1,
    "القعساء": 1,
    "قعسر": 1,
    "القعسر": 1,
    "القعسري": 1,
    "قعقش": 1,
    "القعش": 1,
    "قعصه": 1,
    "قعصت": 1,
    "قاعصه": 1,
    "القعاص": 1,
    "قعضبه": 1,
    "القعضب": 1,
    "القعضم": 1,
    "قعط": 1,
    "أقعط": 1,
    "تقعط": 1,
    "المقعط": 1,
    "قعه": 1,
    "أقع": 1,
    "القعاع": 1,
    "القع": 1,
    "قعفه": 1,
    "قعف": 1,
    "اقتعف": 1,
    "القعاف": 1,
    "قعفز": 1,
    "تقعفز": 1,
    "اقعنفز": 1,
    "تقعقع": 1,
    "القعاقع": 1,
    "القعقاع": 1,
    "القعقع": 1,
    "قعم": 1,
    "القعمة": 1,
    "قعن": 1,
    "القعن": 1,
    "قعنب": 1,
    "اقعنبى": 1,
    "القعنب": 1,
    "قعي": 1,
    "أقعى": 1,
    "القعو": 1,
    "القعواء": 1,
    "قعول": 1,
    "قفئت": 1,
    "قفح": 1,
    "قفد": 1,
    "أقفر": 1,
    "اقتفر": 1,
    "تقفر": 1,
    "القفار": 1,
    "القفر": 1,
    "القفرة": 1,
    "المقفار": 1,
    "تقافزوا": 1,
    "تقفزت": 1,
    "القافزة": 1,
    "القفزى": 1,
    "القفيزى": 1,
    "القفيز": 1,
    "قفس": 1,
    "تقافس": 1,
    "الأقفس": 1,
    "اقتفش": 1,
    "انقفش": 1,
    "القفش": 1,
    "تقافص": 1,
    "تقفص": 1,
    "القافصة": 1,
    "القفاص": 1,
    "القفاصة": 1,
    "القفيص والقفيصة": 1,
    "قفطه": 1,
    "القفطان": 1,
    "قفعه": 1,
    "انقفع": 1,
    "تقفع": 1,
    "القفاع": 1,
    "القفع": 1,
    "القفعاء": 1,
    "القفعة": 1,
    "المقفعة": 1,
    "أقفت": 1,
    "القف": 1,
    "القفاف": 1,
    "القفان": 1,
    "القفة": 1,
    "القفيف": 1,
    "تقفقف": 1,
    "القفقاف": 1,
    "القفقف": 1,
    "أقفل": 1,
    "تقفل": 1,
    "استقفلت": 1,
    "القفال": 1,
    "القفلة": 1,
    "القفيل": 1,
    "القيفال": 1,
    "المتقفل": 1,
    "قفن": 1,
    "اقتفن": 1,
    "القفن والقفن": 1,
    "القفن": 1,
    "القفينة": 1,
    "قفاه": 1,
    "قفى": 1,
    "أقفى": 1,
    "اقتفاه": 1,
    "تقافى": 1,
    "تقفى": 1,
    "استقفاه": 1,
    "القفاوة": 1,
    "القفو": 1,
    "القفوة": 1,
    "القفية": 1,
    "القفي": 1,
    "أقلب": 1,
    "القلاب": 1,
    "القلبة": 1,
    "القليب": 1,
    "المقلب": 1,
    "أقلته": 1,
    "القلت": 1,
    "القلتة": 1,
    "المقلات": 1,
    "المقلتة": 1,
    "قلحت": 1,
    "الأقلح": 1,
    "القلاح": 1,
    "القلح": 1,
    "أقلد": 1,
    "تقلد": 1,
    "اقلوده": 1,
    "القلد": 1,
    "القلداء": 1,
    "القلود": 1,
    "القليد": 1,
    "المقلاد": 1,
    "المقلود": 1,
    "قلز": 1,
    "اقتلز": 1,
    "تقلز": 1,
    "القلز": 1,
    "قلزم": 1,
    "تقلزم": 1,
    "قلست": 1,
    "تقلنس": 1,
    "القلس": 1,
    "القلاس": 1,
    "القليس": 1,
    "المقلس": 1,
    "أقلصت": 1,
    "القلصة": 1,
    "القلاص": 1,
    "القلوص": 1,
    "المقلص": 1,
    "القليط": 1,
    "القليطة": 1,
    "أقلع": 1,
    "اقتلعه": 1,
    "انقلع": 1,
    "تقلع": 1,
    "القلاعة": 1,
    "القلع": 1,
    "القلعي": 1,
    "القلوع": 1,
    "المقلاع": 1,
    "المقلوع": 1,
    "اقتلف": 1,
    "انقلفت": 1,
    "القلافة": 1,
    "القلف": 1,
    "القليف": 1,
    "القلفع": 1,
    "أقلقت": 1,
    "المقلاق": 1,
    "القلقاس": 1,
    "تقلقل": 1,
    "القلاقل": 1,
    "القلقلة": 1,
    "قاللت": 1,
    "القلال": 1,
    "قلالة": 1,
    "القل": 1,
    "القلية": 1,
    "القالم": 1,
    "المقلام": 1,
    "المقلمة": 1,
    "تقاليا": 1,
    "تقلى": 1,
    "اقلولى": 1,
    "القلاء": 1,
    "القلاءة": 1,
    "القلو": 1,
    "قلواني": 1,
    "المقلى": 1,
    "قمأت": 1,
    "أقمأ": 1,
    "اقتمأ": 1,
    "تقمأ": 1,
    "قامأه": 1,
    "القمء": 1,
    "القمأة": 1,
    "القميء": 1,
    "أقمح": 1,
    "قامح": 1,
    "اقتمح": 1,
    "تقمح": 1,
    "القامح": 1,
    "القماح": 1,
    "القمحة": 1,
    "القميحة": 1,
    "القمحي": 1,
    "القمحدوة": 1,
    "قمد": 1,
    "قمرت": 1,
    "أقمر": 1,
    "قامره": 1,
    "تقامروا": 1,
    "تقمر": 1,
    "الأقمر": 1,
    "القمراء": 1,
    "القمرة": 1,
    "القمير": 1,
    "القمزة": 1,
    "أقمس": 1,
    "قامس": 1,
    "انقمس": 1,
    "تقامس": 1,
    "القامس": 1,
    "القامسة": 1,
    "القماس": 1,
    "القمس": 1,
    "القموس": 1,
    "القومس": 1,
    "قمشه": 1,
    "اقتمشه": 1,
    "تقمش": 1,
    "القماشة": 1,
    "القمش": 1,
    "قمصت": 1,
    "قامص": 1,
    "تقامص": 1,
    "القمصة": 1,
    "القمص": 1,
    "القامطة": 1,
    "القماط": 1,
    "القمط": 1,
    "القميط": 1,
    "اقمطر": 1,
    "القماطر": 1,
    "القمطر": 1,
    "القمطرى": 1,
    "القمطرير": 1,
    "قمعت": 1,
    "اقتمع": 1,
    "انقمع": 1,
    "تقمعت": 1,
    "الأقمع": 1,
    "القمعة": 1,
    "القميعة": 1,
    "المقمعة": 1,
    "القمقام": 1,
    "القمقم": 1,
    "القمقمة": 1,
    "قمت": 1,
    "اقتمت": 1,
    "تقمم": 1,
    "القميم": 1,
    "المقم": 1,
    "المقمة": 1,
    "تقمن": 1,
    "القمن": 1,
    "القمنة": 1,
    "القمين": 1,
    "المقمنة": 1,
    "قمه": 1,
    "تقمه": 1,
    "أقمى": 1,
    "قاماني": 1,
    "القامية": 1,
    "المقماة": 1,
    "قنأ": 1,
    "قنئ": 1,
    "أقنأ": 1,
    "المقنأة": 1,
    "المقنوءة": 1,
    "أقنب": 1,
    "تقنب": 1,
    "القانب": 1,
    "القناب": 1,
    "القنابة": 1,
    "القنيب": 1,
    "القيناب": 1,
    "المقنب": 1,
    "القنبيط": 1,
    "القنبع": 1,
    "القنبعة": 1,
    "القنبل": 1,
    "القنبلانية": 1,
    "أقنت": 1,
    "قنتت": 1,
    "اقتنت": 1,
    "القنوت": 1,
    "قنح": 1,
    "أقنح": 1,
    "تقنح": 1,
    "القناح": 1,
    "القناحة": 1,
    "أقند": 1,
    "القند": 1,
    "القندأو": 1,
    "القندة": 1,
    "القنديد": 1,
    "قندل": 1,
    "القنادل": 1,
    "القندل": 1,
    "القنديل": 1,
    "القنزع": 1,
    "أقنس": 1,
    "القنس": 1,
    "القونس": 1,
    "قنسرته": 1,
    "تقنسر": 1,
    "القناسر": 1,
    "القنسر": 1,
    "اقتنص": 1,
    "تقنص": 1,
    "القانصة": 1,
    "القنيص": 1,
    "أقنطه": 1,
    "قنطه": 1,
    "المقنطر": 1,
    "قنعت": 1,
    "أقنعت": 1,
    "قنعه": 1,
    "القنع": 1,
    "القنعان": 1,
    "قنفت": 1,
    "أقنف": 1,
    "القناف": 1,
    "القنيف": 1,
    "تقنفذ": 1,
    "القنمة": 1,
    "اقتن": 1,
    "استقن": 1,
    "القنان": 1,
    "القن": 1,
    "القنة": 1,
    "القنقن": 1,
    "قانى": 1,
    "اقتنى": 1,
    "تقنى": 1,
    "استقنى": 1,
    "الإقناءة": 1,
    "القناء": 1,
    "القنو": 1,
    "القنوة": 1,
    "القنية": 1,
    "القني": 1,
    "المقناة": 1,
    "المقنى": 1,
    "قهب": 1,
    "أقهب": 1,
    "القهبة": 1,
    "قهد": 1,
    "القهد": 1,
    "أقهر": 1,
    "القواهر": 1,
    "القهرة": 1,
    "القهرم": 1,
    "القهرمان": 1,
    "القهرمانة": 1,
    "قهقر": 1,
    "تقهقر": 1,
    "القهقار": 1,
    "القهقرى": 1,
    "القهقرة": 1,
    "قهقه": 1,
    "قهل": 1,
    "تقهل": 1,
    "قهم": 1,
    "أقهم": 1,
    "قه": 1,
    "أقهى": 1,
    "قهي": 1,
    "تقوبت": 1,
    "القائبة": 1,
    "القوب": 1,
    "القوباء والقوباء": 1,
    "القوبة": 1,
    "القوبي": 1,
    "أقاته": 1,
    "اقتات": 1,
    "استقاته": 1,
    "القائت": 1,
    "القيت والقيتة": 1,
    "قاوحه": 1,
    "أقاده": 1,
    "اقتاد": 1,
    "انقاد": 1,
    "تقاود": 1,
    "استقادت": 1,
    "الأقود": 1,
    "القائدة": 1,
    "القؤود": 1,
    "القاد": 1,
    "القود": 1,
    "القيدة": 1,
    "المقادة": 1,
    "تقور": 1,
    "اقور": 1,
    "الأقور": 1,
    "القوارة": 1,
    "المقورة": 1,
    "قوز": 1,
    "تقوز": 1,
    "القوز": 1,
    "استقوس": 1,
    "الأقوس": 1,
    "القواس والقياس": 1,
    "المقاوس": 1,
    "القوطة": 1,
    "القواط": 1,
    "تقوع": 1,
    "القواع": 1,
    "اقتاف": 1,
    "تقوف": 1,
    "الأقوف": 1,
    "القائف": 1,
    "القوف": 1,
    "القيافة": 1,
    "قاقت": 1,
    "القاق": 1,
    "القاووق": 1,
    "القوقة": 1,
    "المقوق": 1,
    "قوقأت": 1,
    "قوقت": 1,
    "المقوقس": 1,
    "القوقعيات": 1,
    "قوقل": 1,
    "القاقلة": 1,
    "القاقلى": 1,
    "أقوله": 1,
    "قاوله": 1,
    "تقاولوا": 1,
    "التقوالة": 1,
    "القال": 1,
    "القولية": 1,
    "القيل": 1,
    "المقوال": 1,
    "المقولة": 1,
    "القولنج": 1,
    "قاومه": 1,
    "قومت": 1,
    "تقاوموا": 1,
    "القوامة": 1,
    "القومة": 1,
    "المقوم": 1,
    "القونة": 1,
    "القاوون": 1,
    "تقاوه": 1,
    "القاه": 1,
    "القاهي": 1,
    "القوهة": 1,
    "القوهي": 1,
    "قاويت": 1,
    "التقاوي": 1,
    "القاوي": 1,
    "القاوية": 1,
    "القواء": 1,
    "القواية": 1,
    "القي": 1,
    "أقاءه": 1,
    "قيأه": 1,
    "استقاء واستقيأ": 1,
    "القيوء": 1,
    "القياء": 1,
    "القيثار والقيثارة": 1,
    "أقاح": 1,
    "قاده": 1,
    "قيده": 1,
    "القير": 1,
    "القيار": 1,
    "قايس": 1,
    "اقتاس": 1,
    "انقاس": 1,
    "تقايس": 1,
    "القاس": 1,
    "القيس": 1,
    "قاصت": 1,
    "انقاصت": 1,
    "اقتاض": 1,
    "تقيض": 1,
    "القيض": 1,
    "القيضة": 1,
    "قاظ": 1,
    "قايظه": 1,
    "اقتاظ": 1,
    "القيظ": 1,
    "القيظي": 1,
    "المقيظ والمقيظ": 1,
    "القيقاءة": 1,
    "القيقية": 1,
    "أقال": 1,
    "قايله": 1,
    "قيله": 1,
    "تقايل": 1,
    "تقيل": 1,
    "القائلة": 1,
    "المقال": 1,
    "المقيال": 1,
    "المقيل": 1,
    "قينه": 1,
    "اقتان": 1,
    "تقين": 1,
    "القين": 1,
    "القينان": 1,
    "القينة": 1,
    "المقينة": 1,
    "الكاف": 1,
    "أكأب": 1,
    "اكتأب": 1,
    "الكأباء": 1,
    "تكاءده": 1,
    "تكأده": 1,
    "اكوأد": 1,
    "الكأداء": 1,
    "الكوداء": 1,
    "الكؤود": 1,
    "كأكأ": 1,
    "تكأكأ": 1,
    "الكأكاء": 1,
    "كبه": 1,
    "أكب": 1,
    "تكاب": 1,
    "تكبب": 1,
    "الكبابة": 1,
    "الكبة": 1,
    "الكبيبة": 1,
    "لمكباب": 1,
    "اكتبت": 1,
    "انكبت": 1,
    "كبثت": 1,
    "كبث": 1,
    "الكباث": 1,
    "الكبيث": 1,
    "الكباحة": 1,
    "كبده": 1,
    "تكبد": 1,
    "الكباد": 1,
    "الكبود": 1,
    "الكبداء": 1,
    "الكبيداء": 1,
    "أكبرت": 1,
    "كوبر": 1,
    "الكابر": 1,
    "الكبرة": 1,
    "كبرته": 1,
    "المكبرت": 1,
    "تكبس": 1,
    "الكباس": 1,
    "الكبيس": 1,
    "الكباش": 1,
    "الكبشة": 1,
    "الكبع": 1,
    "تكبكب": 1,
    "الكبكب": 1,
    "الكبكبة": 1,
    "الكابول": 1,
    "الكبل": 1,
    "كبن": 1,
    "الكبينة": 1,
    "أكبى": 1,
    "كبى": 1,
    "اكتبى": 1,
    "تكبى": 1,
    "الكابي": 1,
    "الكبا": 1,
    "الكباء": 1,
    "الكبوة": 1,
    "الكتأة": 1,
    "أكتبه": 1,
    "اكتتب": 1,
    "تكاتب": 1,
    "تكتبوا": 1,
    "استكتبه": 1,
    "الكتبة": 1,
    "المكتوبة": 1,
    "كتت": 1,
    "أكت": 1,
    "اكتت": 1,
    "تكات": 1,
    "الكت": 1,
    "الكتة": 1,
    "كتح": 1,
    "الكتح": 1,
    "كتخدا": 1,
    "الأكتد": 1,
    "الكتد": 1,
    "أكترت": 1,
    "الكتر": 1,
    "الكترة": 1,
    "الأكتع": 1,
    "كتاع": 1,
    "الكتع": 1,
    "الكتعاء": 1,
    "الكتعة": 1,
    "الكتيع": 1,
    "كاتفه": 1,
    "تكاتف": 1,
    "تكتف": 1,
    "الكتاف": 1,
    "الكتيف": 1,
    "الكتيفة": 1,
    "تكتكت": 1,
    "كتله": 1,
    "انكتل": 1,
    "التكتيل": 1,
    "الكتال": 1,
    "كتول": 1,
    "الكتيلة": 1,
    "المكتل": 1,
    "المكتلة": 1,
    "كتلوج": 1,
    "اكتتم": 1,
    "تكاتم": 1,
    "استكتمه": 1,
    "الكاتم": 1,
    "الكاتمة": 1,
    "الكتمة": 1,
    "الكتيم": 1,
    "أكتنه": 1,
    "الكتون": 1,
    "كثأ": 1,
    "الكثأة": 1,
    "أكثب": 1,
    "انكثب": 1,
    "الكثب": 1,
    "الكثبة": 1,
    "أكث": 1,
    "الكاث": 1,
    "كثره": 1,
    "كاثره": 1,
    "تكاثرت": 1,
    "تكثر": 1,
    "تكوثر": 1,
    "الأكثرية": 1,
    "الكثار": 1,
    "الكثر": 1,
    "الكثيراء": 1,
    "المكثار": 1,
    "المكثر": 1,
    "المكثور": 1,
    "المكثير": 1,
    "كثع": 1,
    "كثعت": 1,
    "الكثعة": 1,
    "استكثف": 1,
    "التكثف": 1,
    "الكثف": 1,
    "المكثاف": 1,
    "الكثكث": 1,
    "الكوثل والكوثل": 1,
    "كثم": 1,
    "أكثم": 1,
    "انكثم": 1,
    "تكثم": 1,
    "الأكثم": 1,
    "الكثنة": 1,
    "كج": 1,
    "الكجة": 1,
    "الكح": 1,
    "الكحح": 1,
    "الكحة": 1,
    "الكحكح": 1,
    "كحص": 1,
    "الكاحص": 1,
    "كحلت": 1,
    "اكتحلت": 1,
    "تكحلت": 1,
    "الكحال": 1,
    "الكحل": 1,
    "الكحلاء": 1,
    "الكحلة": 1,
    "الكحلي": 1,
    "الكحيل": 1,
    "المكحالان": 1,
    "المكحال": 1,
    "المكحل": 1,
    "المكحلة": 1,
    "كحلل": 1,
    "كخ كخ": 1,
    "الكخيا": 1,
    "كدأ": 1,
    "الكادئة": 1,
    "الكدب": 1,
    "المكدوبة": 1,
    "اكتدح": 1,
    "تكدح": 1,
    "كاده": 1,
    "اكتد": 1,
    "استكده": 1,
    "أكداد وأكاديد": 1,
    "الكدادة": 1,
    "الكدود": 1,
    "الكديد": 1,
    "المكد": 1,
    "المكدود": 1,
    "انكدر": 1,
    "تكادرت": 1,
    "تكدر": 1,
    "الأكدر": 1,
    "الكدارة": 1,
    "الكدرة": 1,
    "الكدري": 1,
    "تكادس": 1,
    "تكدست": 1,
    "الكداس": 1,
    "الكدس": 1,
    "أكدش": 1,
    "اكتدش": 1,
    "تكدش": 1,
    "الكدش": 1,
    "الكداش": 1,
    "الكدشة": 1,
    "الكديش": 1,
    "أكدفت": 1,
    "الكدفة": 1,
    "أكدم": 1,
    "تكادم": 1,
    "الكدام": 1,
    "الكدامة": 1,
    "الكدم": 1,
    "المكدم": 1,
    "الكدميوم": 1,
    "كدن": 1,
    "كودن": 1,
    "الكدان": 1,
    "الكدانة": 1,
    "الكدن": 1,
    "الكدنة": 1,
    "الكودن": 1,
    "كده": 1,
    "أكدهه": 1,
    "كدهه": 1,
    "تكده": 1,
    "كدي": 1,
    "الكادي": 1,
    "أكدى": 1,
    "الكادية": 1,
    "الكدى": 1,
    "الكداة": 1,
    "الكداية": 1,
    "الكدية": 1,
    "أكذبه": 1,
    "كاذبت": 1,
    "تكاذبوا": 1,
    "تكذب": 1,
    "التكاذيب": 1,
    "الكاذبة": 1,
    "الكذوب": 1,
    "كذ": 1,
    "أكذ": 1,
    "الكذان": 1,
    "أكذى": 1,
    "الكاذي": 1,
    "أكرب": 1,
    "اكترب": 1,
    "الكراب": 1,
    "الكرابة": 1,
    "الكربة": 1,
    "الكروبيون": 1,
    "المكرب": 1,
    "كربس": 1,
    "تكربس": 1,
    "الكرباس": 1,
    "الكرباسة": 1,
    "كربش": 1,
    "تكربش": 1,
    "كربل": 1,
    "الكربال": 1,
    "الكربلة": 1,
    "الكريت": 1,
    "كرثه": 1,
    "أكرثه": 1,
    "اكترث": 1,
    "كرج": 1,
    "كرده": 1,
    "الكرديد": 1,
    "الكرديدة": 1,
    "تكردس": 1,
    "الكردوسة": 1,
    "الكردان": 1,
    "الكردون": 1,
    "الكردينال": 1,
    "تكرر": 1,
    "الكرير": 1,
    "كارزه": 1,
    "كرزم": 1,
    "الكرزمة": 1,
    "انكرس": 1,
    "تكارس": 1,
    "تكرس": 1,
    "الكراسة": 1,
    "الكرس": 1,
    "الكرساء": 1,
    "المكرسة": 1,
    "كرسع": 1,
    "الكرسوع": 1,
    "المكرسع": 1,
    "الكرسف": 1,
    "الكرسفي": 1,
    "تكرش": 1,
    "استكرش": 1,
    "الأكرش": 1,
    "الكرشاء": 1,
    "الكريش": 1,
    "الكريشة": 1,
    "المكرشة": 1,
    "كرعت": 1,
    "أكرع": 1,
    "تكرع": 1,
    "الكراع": 1,
    "الكراعي": 1,
    "كرف": 1,
    "الكراف": 1,
    "كرفأت": 1,
    "تكرفأ": 1,
    "كركت": 1,
    "الكراكة": 1,
    "الكرك": 1,
    "الكركي": 1,
    "الكريك": 1,
    "الكركدية": 1,
    "تكركر": 1,
    "الكركرة": 1,
    "كركس": 1,
    "تكركس": 1,
    "المكركس": 1,
    "الكركمان": 1,
    "المكركم": 1,
    "كرمه": 1,
    "أكرم": 1,
    "كارمه": 1,
    "تكارم": 1,
    "استكرم": 1,
    "الإكرامية": 1,
    "الأكرومة": 1,
    "التكرمة": 1,
    "كرنف": 1,
    "الكرناف": 1,
    "الكرنافة": 1,
    "أكرهه": 1,
    "تكاره": 1,
    "استكره": 1,
    "الكرهاء": 1,
    "المكروهة": 1,
    "أكرى": 1,
    "كاراه": 1,
    "اكترى": 1,
    "تكارى": 1,
    "استكرى": 1,
    "الكراء": 1,
    "الكروة": 1,
    "الكري": 1,
    "المكاري": 1,
    "الكروميت": 1,
    "الكريات الحمر": 1,
    "كزب": 1,
    "الكزب": 1,
    "الكوزب": 1,
    "المكزوبة": 1,
    "أكزه": 1,
    "اكتز": 1,
    "التكزز": 1,
    "الكازوزة": 1,
    "كزم": 1,
    "أكزم": 1,
    "المنكزم": 1,
    "كزى": 1,
    "أكسب": 1,
    "الكواسب": 1,
    "الكستبان": 1,
    "تكوسج": 1,
    "الكوسج": 1,
    "اكتسح": 1,
    "الكساحة": 1,
    "الكسح": 1,
    "المكسح": 1,
    "المكسحة": 1,
    "الكسار": 1,
    "الكسارة": 1,
    "الكسرة": 1,
    "المكسرات": 1,
    "كس": 1,
    "تكسس": 1,
    "الكسيس": 1,
    "اكتسعت": 1,
    "تكسع": 1,
    "الأكسع": 1,
    "الكسعة": 1,
    "كسفت": 1,
    "أكسف": 1,
    "الكاسف": 1,
    "الكسفة": 1,
    "الكسيفة": 1,
    "الكسكسة": 1,
    "الكسكسي": 1,
    "أكسل": 1,
    "استكسل": 1,
    "المكسلة": 1,
    "الأكسوم": 1,
    "الكسم": 1,
    "الكسوم": 1,
    "اكتسى": 1,
    "تكسى": 1,
    "استكساه": 1,
    "كشأ": 1,
    "كشئ": 1,
    "أكشأ": 1,
    "تكشأ": 1,
    "الكشيء": 1,
    "كاشحه": 1,
    "انكشحوا": 1,
    "الكاشح": 1,
    "الكشاح": 1,
    "الكشاحة": 1,
    "الكشح": 1,
    "الكشح والكشاح": 1,
    "المكشاح": 1,
    "كشد": 1,
    "أكشد": 1,
    "الكاشد": 1,
    "الكشود": 1,
    "كاشره": 1,
    "الكشر": 1,
    "الكشري": 1,
    "كشت": 1,
    "كشطه": 1,
    "تكشط": 1,
    "الكشاط": 1,
    "أكشف": 1,
    "اكتشفت": 1,
    "انكشف": 1,
    "تكاشف": 1,
    "الكشفاء": 1,
    "المكشاف": 1,
    "كشم": 1,
    "اكتشم": 1,
    "كشمر": 1,
    "الكشامر": 1,
    "كشا": 1,
    "كص": 1,
    "أكص": 1,
    "الكصيص": 1,
    "كصم": 1,
    "كظب": 1,
    "الكظر": 1,
    "الكظرة": 1,
    "كاظه": 1,
    "اكتظ": 1,
    "تكاظوا": 1,
    "الكظاظ": 1,
    "الكظ": 1,
    "الكظة": 1,
    "كظكظ": 1,
    "تكظكظ": 1,
    "الكاظم": 1,
    "الكظام": 1,
    "الكظامة": 1,
    "الكظم": 1,
    "الكظيم": 1,
    "كظا": 1,
    "تكظى": 1,
    "كعبت": 1,
    "أكعب": 1,
    "تكعب": 1,
    "التكعيبة": 1,
    "كعبره": 1,
    "تكعبر": 1,
    "الكعبورة": 1,
    "كعبس": 1,
    "أكعت": 1,
    "الكعيت": 1,
    "كعتر": 1,
    "كعر": 1,
    "أكعر": 1,
    "الكعس": 1,
    "كعطل": 1,
    "كع": 1,
    "أكع": 1,
    "كعكع": 1,
    "تكعكع": 1,
    "كاعمها": 1,
    "الكعامة والكعام": 1,
    "كعنب": 1,
    "الكاغد": 1,
    "أكفأ": 1,
    "كافأه": 1,
    "اكتفأ": 1,
    "انكفأ": 1,
    "تكافأ": 1,
    "تكفأ": 1,
    "استكفأه": 1,
    "الكفء": 1,
    "الكفاء": 1,
    "الكفأة": 1,
    "الكفؤ": 1,
    "الكفيء": 1,
    "اكتفت": 1,
    "انكفت": 1,
    "تكفت": 1,
    "الكفت": 1,
    "الكفتة": 1,
    "الكفيت": 1,
    "المكفت": 1,
    "أكفح": 1,
    "كافحه": 1,
    "تكافح": 1,
    "الكفيح": 1,
    "المكفر": 1,
    "المكفور": 1,
    "انكف": 1,
    "تكافوا": 1,
    "تكفف": 1,
    "استكف": 1,
    "الكفاف": 1,
    "الكفف": 1,
    "تكفكف": 1,
    "أكفل": 1,
    "كافله": 1,
    "اكتفل": 1,
    "تكفل": 1,
    "الكفيل": 1,
    "اكفهر": 1,
    "المكفهر": 1,
    "اكتفى": 1,
    "تكفى": 1,
    "استكفاه": 1,
    "الاكتفاء الذاتي": 1,
    "الكفي": 1,
    "الكفية": 1,
    "الكوكبة": 1,
    "أكلأت": 1,
    "كالأه": 1,
    "اكتلأت": 1,
    "تكلأ": 1,
    "استكلأ": 1,
    "الكلأ": 1,
    "الكلأة": 1,
    "الكلاء": 1,
    "الكلوء": 1,
    "لمكلأة": 1,
    "المكلأ": 1,
    "كالبه": 1,
    "اكتلب": 1,
    "استكلب": 1,
    "الكالب": 1,
    "الكلبية": 1,
    "الكلبيون": 1,
    "الكليب": 1,
    "المكالب": 1,
    "المكلبة": 1,
    "الكلتة": 1,
    "كلثم": 1,
    "الكلثوم": 1,
    "أكلحه": 1,
    "كالحه": 1,
    "تكلح": 1,
    "الكالح": 1,
    "كلد": 1,
    "تكلد": 1,
    "الكلد": 1,
    "اكلأز": 1,
    "كلز": 1,
    "الكلاس": 1,
    "الكلاسة": 1,
    "الكلسيت": 1,
    "كلسم": 1,
    "الكلسيوم": 1,
    "كلع": 1,
    "أكلعه": 1,
    "الكلاع": 1,
    "الكلاعي": 1,
    "الكلع": 1,
    "أكلفه": 1,
    "التكلفة": 1,
    "الكلاف": 1,
    "الكلوف": 1,
    "المكلاف": 1,
    "المكلف": 1,
    "المكلفة": 1,
    "الكلاكل": 1,
    "الكلكال والكلكل": 1,
    "الكلكلة": 1,
    "اكتل": 1,
    "انكل": 1,
    "تكلل": 1,
    "الكلة": 1,
    "الكليل": 1,
    "المكل": 1,
    "المكلل": 1,
    "المكللة": 1,
    "كالمه": 1,
    "تكالم": 1,
    "التكلام والتكلامة": 1,
    "الكلمة والكلمة": 1,
    "الكليم": 1,
    "كلاه": 1,
    "اكتلى": 1,
    "كمأ": 1,
    "كمئ": 1,
    "أكمأ": 1,
    "تكمأت": 1,
    "الكمء": 1,
    "الكماء": 1,
    "المكمأة": 1,
    "أكمت": 1,
    "اكمت": 1,
    "الكمتة": 1,
    "الكميت": 1,
    "كمتر": 1,
    "أكمح": 1,
    "الكامح": 1,
    "الكومح": 1,
    "أكمخ": 1,
    "الكامخ": 1,
    "أكمد": 1,
    "الكماد والكمادة": 1,
    "الكمدة": 1,
    "الكمر": 1,
    "كمز": 1,
    "الكمزة": 1,
    "كمس": 1,
    "الكيموس": 1,
    "الكيموسية": 1,
    "أكمش": 1,
    "انكمش": 1,
    "تكمش": 1,
    "الأكمش": 1,
    "الانكماش": 1,
    "الكمش": 1,
    "الكميش": 1,
    "كامع": 1,
    "الكمع": 1,
    "الكميع": 1,
    "المكامع": 1,
    "كمكم": 1,
    "تكمكم": 1,
    "الكمكام": 1,
    "تكمل": 1,
    "استكمل": 1,
    "الكمل": 1,
    "كممت": 1,
    "تكمم": 1,
    "الكمام": 1,
    "الكمة": 1,
    "المكم": 1,
    "كمنت": 1,
    "اكتمن": 1,
    "الكمنة": 1,
    "المكمن": 1,
    "الكمنجة والكمان": 1,
    "تكمه": 1,
    "الكامه": 1,
    "الكمه": 1,
    "المكمه": 1,
    "اكمهد": 1,
    "كمهل": 1,
    "المكمهل": 1,
    "كمى": 1,
    "أكمى": 1,
    "اكتمى": 1,
    "انكمى": 1,
    "تكمى": 1,
    "كنبت": 1,
    "أكنب": 1,
    "الكانب": 1,
    "الكناب": 1,
    "الكنب": 1,
    "الكنيب": 1,
    "الكنبار": 1,
    "الكنبرة": 1,
    "اكتنت": 1,
    "الكنتي": 1,
    "الكنتين": 1,
    "كند": 1,
    "الكندة": 1,
    "الكنود": 1,
    "الكنادر": 1,
    "الكندارة": 1,
    "الكندر": 1,
    "الكندرة": 1,
    "كنر": 1,
    "الكنار": 1,
    "الكنارة": 1,
    "اكتنز": 1,
    "تكنز": 1,
    "الكناز": 1,
    "اكتنس": 1,
    "تكنس": 1,
    "الكنيس": 1,
    "المكنس": 1,
    "كنش": 1,
    "الكنشاء": 1,
    "الكناشة": 1,
    "كنظه": 1,
    "الكنظة": 1,
    "كنع": 1,
    "أكنعت": 1,
    "اكتنع": 1,
    "تكنع": 1,
    "الأكنع": 1,
    "الكانع": 1,
    "الكنع": 1,
    "الكنيع": 1,
    "المكنوع": 1,
    "أكنف": 1,
    "كانفه": 1,
    "اكتنف": 1,
    "تكنفه": 1,
    "الكانفة": 1,
    "الكنافة": 1,
    "الكنف": 1,
    "الكنفاني": 1,
    "الكنوف": 1,
    "الكنيف": 1,
    "المكنف": 1,
    "كنفش": 1,
    "الكنكان": 1,
    "كنن": 1,
    "اكتن": 1,
    "الكانون": 1,
    "الكانونة": 1,
    "الكنان": 1,
    "الكنة": 1,
    "الكنينة": 1,
    "المستكنة": 1,
    "أكنه": 1,
    "اكتنه": 1,
    "الكنه": 1,
    "الكنهور": 1,
    "كنهف": 1,
    "أكناه وكناه": 1,
    "اكتنى": 1,
    "تكنى": 1,
    "كهب": 1,
    "اكهاب": 1,
    "الكهب": 1,
    "الكهبة": 1,
    "كهد": 1,
    "أكهد": 1,
    "اكوهد": 1,
    "الكهداء": 1,
    "الكهود": 1,
    "الكوهد": 1,
    "كهر": 1,
    "الكهرور والكهرورة": 1,
    "تكهرب": 1,
    "الكهربا": 1,
    "اكتهف": 1,
    "تكهف": 1,
    "كه كه": 1,
    "كهكه": 1,
    "تكهكه": 1,
    "الكهاكه": 1,
    "الكهكاه": 1,
    "الكهكاهة": 1,
    "اكتهل": 1,
    "تكهل": 1,
    "الكهل": 1,
    "الكهلول": 1,
    "كهمته": 1,
    "تكهم": 1,
    "كهمس": 1,
    "الكهمس": 1,
    "كاهنه": 1,
    "تكهن": 1,
    "الكهنوت": 1,
    "كه": 1,
    "الكهة": 1,
    "أكهى": 1,
    "اكتهى": 1,
    "الأكهى": 1,
    "الأكهاء": 1,
    "الكهاة": 1,
    "الكوبة": 1,
    "كوث": 1,
    "الكوث": 1,
    "الكوثل": 1,
    "كاح": 1,
    "كاوحه": 1,
    "كوحه": 1,
    "تكاوحا": 1,
    "الكودة": 1,
    "المكادة": 1,
    "كوذ": 1,
    "الكاذان": 1,
    "الكاذة": 1,
    "الكوذان": 1,
    "أكار": 1,
    "اكتار": 1,
    "الكارة": 1,
    "الكوار": 1,
    "الكوارة": 1,
    "الكور": 1,
    "الكورة": 1,
    "المكور": 1,
    "الكورمة": 1,
    "اكتاز": 1,
    "تكوز": 1,
    "الكوز": 1,
    "المكوز": 1,
    "أكاس": 1,
    "كوسه": 1,
    "اكتاسه": 1,
    "تكاوس": 1,
    "تكوس": 1,
    "الكاس": 1,
    "الكوس": 1,
    "الكوساء": 1,
    "الكوسة": 1,
    "الكوسي": 1,
    "كوعه": 1,
    "تكوعت": 1,
    "الكاع": 1,
    "تكوف": 1,
    "الكوفى": 1,
    "الكوفان": 1,
    "الكوفية": 1,
    "الكوك": 1,
    "الكوكايين": 1,
    "تكول": 1,
    "انكالوا": 1,
    "الكولان": 1,
    "الكولايا": 1,
    "اكتام": 1,
    "الأكومان": 1,
    "الكوم": 1,
    "اكتان": 1,
    "الكائنة": 1,
    "الكينة": 1,
    "المكانة": 1,
    "كاهه": 1,
    "كوه": 1,
    "تكوهت": 1,
    "تكوى": 1,
    "الكو": 1,
    "كاواه": 1,
    "اكتوى": 1,
    "استكوى": 1,
    "الكاوياء": 1,
    "الكواء": 1,
    "الكية": 1,
    "كاء": 1,
    "أكاءه": 1,
    "الكاء": 1,
    "الكيء والكيء": 1,
    "كيت وكيت": 1,
    "كيح": 1,
    "أكاح": 1,
    "الكاح": 1,
    "الكيح": 1,
    "كايده": 1,
    "اكتاده": 1,
    "تكايد": 1,
    "الكيد": 1,
    "الكياد": 1,
    "تكاير": 1,
    "الكير": 1,
    "الكيروسين": 1,
    "أكاس وأكيس": 1,
    "كايسه": 1,
    "تكيس": 1,
    "الكوسى": 1,
    "كيسان": 1,
    "المكياس": 1,
    "كاص": 1,
    "كايصه": 1,
    "الكيص": 1,
    "انكاف": 1,
    "الكيفية": 1,
    "مكيف الهواء": 1,
    "كيل وكول": 1,
    "كايلت": 1,
    "اكتال": 1,
    "تكايل": 1,
    "الكيالة": 1,
    "الكيلة": 1,
    "الكيلجة": 1,
    "الكيول": 1,
    "الكيلوس": 1,
    "الكيميائي والكيمياوي": 1,
    "أكانه": 1,
    "المكتان": 1,
    "الكيه": 1,
    "كيهك": 1,
    "الكيا": 1,
    "اللام": 1,
    "اللام المفردة": 1,
    "العاملة للجر": 1,
    "العاملة للجزم": 1,
    "اللازورد": 1,
    "لأطه": 1,
    "لأف": 1,
    "ألاكه": 1,
    "استلأك": 1,
    "الملأك": 1,
    "الملأكة": 1,
    "اللآء": 1,
    "اللأل": 1,
    "اللئالة": 1,
    "اللألاء": 1,
    "اللؤلؤان": 1,
    "لأمه": 1,
    "ألأم": 1,
    "لاءمه": 1,
    "التأم": 1,
    "تلاءم": 1,
    "تلأم": 1,
    "استلأم": 1,
    "اللؤم": 1,
    "اللأم": 1,
    "اللئم": 1,
    "اللأمان واللؤمان": 1,
    "اللأمة": 1,
    "اللؤمة": 1,
    "اللمة": 1,
    "ألأى": 1,
    "لأى": 1,
    "التأى": 1,
    "اللأواء": 1,
    "اللأي": 1,
    "اللاءون": 1,
    "لبأ": 1,
    "ألبأ": 1,
    "لبأت": 1,
    "التبأ": 1,
    "اللبؤة": 1,
    "تلبب": 1,
    "استلب": 1,
    "اللباب": 1,
    "اللبب": 1,
    "اللبة": 1,
    "اللبيب": 1,
    "اللبيبة": 1,
    "لبيك ولبيه": 1,
    "المتلبب": 1,
    "الملبوب": 1,
    "ألبثه": 1,
    "تلبث": 1,
    "استلبثه": 1,
    "اللبثة": 1,
    "اللبيثة": 1,
    "لبج": 1,
    "اللبيج": 1,
    "تلبخ": 1,
    "اللبخ": 1,
    "اللبخة": 1,
    "اللبيخة": 1,
    "ألبد": 1,
    "التبد": 1,
    "اللابد": 1,
    "اللبادى": 1,
    "اللباد": 1,
    "اللبادة": 1,
    "اللبد": 1,
    "اللبدة": 1,
    "لبز": 1,
    "لابسه": 1,
    "التبس": 1,
    "اللباسة": 1,
    "اللبسة": 1,
    "اللبوس": 1,
    "اللبوسة واللبوسة": 1,
    "الملبس": 1,
    "التبط": 1,
    "تلبط": 1,
    "اللبطة": 1,
    "ألبك": 1,
    "التبك": 1,
    "تلبك": 1,
    "التلبك": 1,
    "اللبك": 1,
    "اللبكة": 1,
    "اللبيك": 1,
    "اللبيكة": 1,
    "لبالب": 1,
    "اللبلب": 1,
    "لبنه": 1,
    "لبنت": 1,
    "ألبنت": 1,
    "تلبن": 1,
    "استلبن": 1,
    "التلبينة": 1,
    "اللابن": 1,
    "اللبانة": 1,
    "اللبون": 1,
    "اللبين": 1,
    "الملبن": 1,
    "الملبنة": 1,
    "لتأه": 1,
    "لتب": 1,
    "ألتب": 1,
    "التتب": 1,
    "الملاتب": 1,
    "الملتب": 1,
    "اللات واللات": 1,
    "اللتات": 1,
    "لتحه": 1,
    "لتح": 1,
    "اللاتح": 1,
    "اللتاح": 1,
    "اللتلتة": 1,
    "لتم": 1,
    "التي اللت اللت": 1,
    "التي واللتيا": 1,
    "لث": 1,
    "ألث": 1,
    "تلاثغ": 1,
    "لثق": 1,
    "ألثق": 1,
    "التثق": 1,
    "تلثق": 1,
    "اللثق": 1,
    "لثلث": 1,
    "تلثلث": 1,
    "اللثلاث واللثلاثة": 1,
    "التثمت": 1,
    "تلاثما": 1,
    "تلثمت": 1,
    "الملثم": 1,
    "لثبت": 1,
    "ألثت": 1,
    "تلثى": 1,
    "اللثى": 1,
    "اللثاة": 1,
    "ألجأ": 1,
    "لجأه": 1,
    "التجأ": 1,
    "تلجأ": 1,
    "اللاجئ": 1,
    "اللجأ": 1,
    "اللجب": 1,
    "ألج": 1,
    "لججت": 1,
    "التج": 1,
    "تلجج": 1,
    "الألنجج": 1,
    "اللجاج": 1,
    "اللجاجة": 1,
    "اللجة": 1,
    "اللجي": 1,
    "اللجج": 1,
    "اللجح": 1,
    "لجذ": 1,
    "الملجاذ": 1,
    "الملجوذ": 1,
    "لجف": 1,
    "لجفت": 1,
    "تلجفت": 1,
    "اللجاف": 1,
    "اللجف": 1,
    "اللجفة": 1,
    "تلجلج": 1,
    "اللجلاج": 1,
    "اللجلج": 1,
    "ألجم": 1,
    "لجمه": 1,
    "اللجمة": 1,
    "تلجن": 1,
    "اللجين": 1,
    "التحب": 1,
    "اللاحب": 1,
    "اللحب": 1,
    "الملحب": 1,
    "لحوج": 1,
    "ألحجه": 1,
    "التحج": 1,
    "تلحج": 1,
    "استلحج": 1,
    "اللحج": 1,
    "الملحج": 1,
    "الملحوجة": 1,
    "لحت": 1,
    "لححت": 1,
    "تلحح": 1,
    "اللاح": 1,
    "اللحة": 1,
    "اللحوح": 1,
    "الملحاح": 1,
    "ألحد": 1,
    "التحد": 1,
    "اللاحد": 1,
    "لحزه": 1,
    "لحز": 1,
    "تلاحز": 1,
    "تلحز": 1,
    "الملحز": 1,
    "ألحست": 1,
    "التحس": 1,
    "اللاحسة": 1,
    "اللاحوس": 1,
    "اللحسة": 1,
    "اللحوس": 1,
    "الملحس": 1,
    "لحص": 1,
    "لحصت": 1,
    "التحص": 1,
    "لحاص": 1,
    "الملحص": 1,
    "لحط": 1,
    "التحط": 1,
    "لحظه": 1,
    "لاحظه": 1,
    "تلاحظا": 1,
    "اللحاظ": 1,
    "الملحظ": 1,
    "الملحوظة": 1,
    "ألحف": 1,
    "لاحفه": 1,
    "تلحف": 1,
    "الملحف": 1,
    "الملحفة": 1,
    "تلاحقت": 1,
    "استلحق": 1,
    "اللحق": 1,
    "لاحك": 1,
    "تلاحك": 1,
    "لحلح": 1,
    "تلحلح": 1,
    "اللحلح": 1,
    "ألحم": 1,
    "التحم": 1,
    "تلاحمت": 1,
    "استلحم": 1,
    "الالتحام": 1,
    "اللاحم": 1,
    "اللحامة": 1,
    "اللحمة": 1,
    "المتلاحمة": 1,
    "الملتحم": 1,
    "الملحم": 1,
    "ألحن": 1,
    "لاحنه": 1,
    "اللحنة": 1,
    "الملاحن": 1,
    "ألحى": 1,
    "لاحاه": 1,
    "التحى": 1,
    "تلاحى": 1,
    "تلحى": 1,
    "الألحى": 1,
    "اللحي": 1,
    "اللحياني": 1,
    "الملحاة": 1,
    "لخ": 1,
    "لخخت": 1,
    "تلاخ": 1,
    "اللخة": 1,
    "اللخوخ": 1,
    "لخصت": 1,
    "اللخص": 1,
    "اللخصة": 1,
    "لخف": 1,
    "اللخافة": 1,
    "اللخف": 1,
    "اللخفة": 1,
    "اللخلخاني": 1,
    "اللخلخانية": 1,
    "لخم": 1,
    "لاخمه": 1,
    "اللخم": 1,
    "اللخمة": 1,
    "اللخن": 1,
    "لخاه": 1,
    "لخي": 1,
    "ألخاه": 1,
    "التخى": 1,
    "اللخا واللخاء": 1,
    "اللخاء": 1,
    "الملخى والملخاء": 1,
    "ألده": 1,
    "لاده": 1,
    "لدده": 1,
    "التد": 1,
    "تلدد": 1,
    "الألد": 1,
    "اللدد": 1,
    "اللدود": 1,
    "اللديد": 1,
    "اللديدة": 1,
    "المتلدد": 1,
    "لدسه": 1,
    "ألدست": 1,
    "لدس": 1,
    "اللديس": 1,
    "الملدس": 1,
    "لدغته": 1,
    "ألدغ": 1,
    "اللداغة": 1,
    "الملدغ": 1,
    "لدم": 1,
    "ألدمت": 1,
    "التدم": 1,
    "تلدم": 1,
    "اللدام": 1,
    "اللدم": 1,
    "اللديم": 1,
    "الملدام": 1,
    "الملدم": 1,
    "تلدن": 1,
    "اللدن": 1,
    "اللدنة": 1,
    "اللدينة": 1,
    "اللدة": 1,
    "لذجه": 1,
    "لذذه": 1,
    "التذ": 1,
    "تلاذ": 1,
    "تلذذ": 1,
    "استلذ": 1,
    "اللذ": 1,
    "الملذ": 1,
    "الملذة": 1,
    "التذع": 1,
    "تلذعت": 1,
    "اللذاع": 1,
    "اللذع": 1,
    "اللوذع واللوذعي": 1,
    "لذلذ": 1,
    "لذمه": 1,
    "ألذم": 1,
    "اللذمة": 1,
    "اللاذن": 1,
    "تلازب": 1,
    "اللزب": 1,
    "اللزبة": 1,
    "تلزج": 1,
    "لزه": 1,
    "ألز": 1,
    "لازه": 1,
    "لززه": 1,
    "التز": 1,
    "تلزز": 1,
    "اللزاز": 1,
    "اللز": 1,
    "اللزز": 1,
    "الملز": 1,
    "ألزقه": 1,
    "لازقه": 1,
    "التزق": 1,
    "تلازق": 1,
    "اللازوق": 1,
    "اللزاق": 1,
    "اللزق": 1,
    "اللزقاء": 1,
    "اللزقة": 1,
    "اللزيقى": 1,
    "اللزوق": 1,
    "اللزيق": 1,
    "لازمه": 1,
    "استلزم": 1,
    "اللزام": 1,
    "اللزمة": 1,
    "لزن": 1,
    "تلازن": 1,
    "الألزن": 1,
    "اللزن": 1,
    "اللزنة": 1,
    "لسبته": 1,
    "لسب": 1,
    "ألسبه": 1,
    "اللسوب": 1,
    "لسد": 1,
    "الملسد": 1,
    "لس": 1,
    "اللساس": 1,
    "اللس": 1,
    "اللسس": 1,
    "لسعته": 1,
    "ألسعه": 1,
    "اللساع": 1,
    "اللسعة": 1,
    "اللسوع": 1,
    "الملسع": 1,
    "لسق": 1,
    "اللسوق": 1,
    "لاسنه": 1,
    "اللسن": 1,
    "الملسون": 1,
    "لشلش": 1,
    "اللشلش": 1,
    "لشا": 1,
    "لاشاه": 1,
    "لصب": 1,
    "التصب": 1,
    "اللاصبة": 1,
    "اللصب": 1,
    "تلصص": 1,
    "الملصة": 1,
    "لصغ": 1,
    "لصف": 1,
    "اللاصف": 1,
    "اللصف": 1,
    "لاصقة": 1,
    "تلاصقا": 1,
    "الالتصاق": 1,
    "الالتصاقية": 1,
    "اللاصوق": 1,
    "اللصوق": 1,
    "اللصيق": 1,
    "لصلص": 1,
    "لضلض": 1,
    "اللضلاض": 1,
    "لضمه": 1,
    "لطأ": 1,
    "لطئ": 1,
    "اللاطئة": 1,
    "لطث": 1,
    "تلاطث": 1,
    "الملاطث": 1,
    "لطخه": 1,
    "اللطخ": 1,
    "اللطيخ": 1,
    "اللطوخ": 1,
    "لطسه": 1,
    "لاطسه": 1,
    "تلاطست": 1,
    "الملطاس": 1,
    "الملطس": 1,
    "لط": 1,
    "ألط": 1,
    "التطت": 1,
    "تلطط": 1,
    "اللاط": 1,
    "الملطاط": 1,
    "لطعت": 1,
    "التطع": 1,
    "اللطع": 1,
    "اللطعة": 1,
    "تلاطف": 1,
    "استلطف": 1,
    "اللطفة": 1,
    "اللطيفة": 1,
    "اللواطف": 1,
    "لطمه": 1,
    "لاطمه": 1,
    "التطمت": 1,
    "تلاطمت": 1,
    "تلطم": 1,
    "اللطيم": 1,
    "اللطيمة": 1,
    "الملطم": 1,
    "لطا": 1,
    "لطى": 1,
    "تلطى": 1,
    "اللطاة": 1,
    "الملطى والملطاء والملطاة": 1,
    "لظ": 1,
    "ألظ": 1,
    "لاظ": 1,
    "تلاظوا": 1,
    "تلظظت": 1,
    "اللظ": 1,
    "الملظاظ": 1,
    "الملظ": 1,
    "الملظة": 1,
    "لظلظت": 1,
    "تلظلظت": 1,
    "اللظلاظ": 1,
    "لظيت": 1,
    "التظت": 1,
    "تلظت": 1,
    "اللظى": 1,
    "ألعب": 1,
    "لاعبه": 1,
    "تلعب": 1,
    "الألعبان": 1,
    "الألعوبة": 1,
    "التلعاب": 1,
    "التلعابة": 1,
    "الملعبة": 1,
    "ألعج": 1,
    "لاعجه": 1,
    "التعج": 1,
    "اللاعج": 1,
    "لعسه": 1,
    "لعست": 1,
    "تلعس": 1,
    "اللعس": 1,
    "اللعسة": 1,
    "اللعوس": 1,
    "الملعوس": 1,
    "لعص": 1,
    "تلعص": 1,
    "لعطه": 1,
    "ألعط": 1,
    "اللعط": 1,
    "اللعطاء": 1,
    "اللعطة": 1,
    "ألعت": 1,
    "تلعى": 1,
    "اللعاع": 1,
    "اللعاعة": 1,
    "اللعة": 1,
    "ألعقه": 1,
    "لعقه": 1,
    "التعق": 1,
    "اللعاق": 1,
    "اللعوق": 1,
    "تلعلع": 1,
    "اللعلاع": 1,
    "اللعلع": 1,
    "اللعلعة": 1,
    "لعمظ": 1,
    "اللعماظ": 1,
    "اللعمظ": 1,
    "اللعموظ واللعموظة": 1,
    "التعن": 1,
    "تلاعنا": 1,
    "تلعن": 1,
    "اللعان": 1,
    "الملعنة": 1,
    "ألعى": 1,
    "الألعاء": 1,
    "اللاعي": 1,
    "اللاعية": 1,
    "اللعا": 1,
    "اللعو": 1,
    "اللعوة": 1,
    "ألغبه": 1,
    "لغبه": 1,
    "تلغبه": 1,
    "اللغب": 1,
    "اللغوب": 1,
    "لغده": 1,
    "لاغده": 1,
    "التغده": 1,
    "تلغد": 1,
    "اللغدود": 1,
    "اللغديد": 1,
    "ألغز": 1,
    "لاغزه": 1,
    "الألغوزة": 1,
    "اللغاز": 1,
    "لغوس": 1,
    "اللغوس": 1,
    "ألغط": 1,
    "اللغط": 1,
    "لغف": 1,
    "ألغف": 1,
    "لاغفه": 1,
    "تلغف": 1,
    "اللغفة": 1,
    "اللغيف": 1,
    "اللغيفة": 1,
    "الملغفة": 1,
    "لغلغ": 1,
    "اللغلغة": 1,
    "ألغم": 1,
    "لغمه": 1,
    "التغم": 1,
    "تلغم": 1,
    "اللغام": 1,
    "اللغيم": 1,
    "الملغم": 1,
    "الغان": 1,
    "اللغن": 1,
    "اللغنون": 1,
    "لغى": 1,
    "لاغاه": 1,
    "استلغاه": 1,
    "اللاغية": 1,
    "اللغا": 1,
    "لفأ": 1,
    "لفئ": 1,
    "ألفأ": 1,
    "التفأ": 1,
    "اللفاء": 1,
    "اللفأة": 1,
    "اللفيئة": 1,
    "اللفوت": 1,
    "اللفيتة": 1,
    "المتلفتة": 1,
    "ألفج": 1,
    "استلفج": 1,
    "اللفج": 1,
    "لفحته": 1,
    "اللفح": 1,
    "اللفاح": 1,
    "لفخه": 1,
    "اللافظة": 1,
    "اللفاظ": 1,
    "اللفاظة": 1,
    "اللفيظ": 1,
    "الملفظ": 1,
    "التفع": 1,
    "تلفع": 1,
    "اللفاعة": 1,
    "اللفيعة": 1,
    "الملفعة": 1,
    "لففه": 1,
    "تلافوا": 1,
    "تلفف": 1,
    "التلافيف": 1,
    "اللفافة": 1,
    "الملفاف": 1,
    "تلافق": 1,
    "تلفق": 1,
    "التلفاق": 1,
    "اللفاق": 1,
    "اللفق": 1,
    "تلفلف": 1,
    "اللفلاف": 1,
    "اللفلف": 1,
    "لفاه": 1,
    "ألفاه": 1,
    "تلافى": 1,
    "التلافي": 1,
    "اللفى": 1,
    "لقبه": 1,
    "تلاقب": 1,
    "تلقب": 1,
    "لقحت": 1,
    "ألقحت": 1,
    "استلقحت": 1,
    "اللقاحية": 1,
    "اللقح": 1,
    "اللقحة": 1,
    "الملقح": 1,
    "لقسه": 1,
    "لقست": 1,
    "لاقسه": 1,
    "تلاقس": 1,
    "تلقست": 1,
    "اللاقس": 1,
    "اللقس": 1,
    "لقص": 1,
    "التقص": 1,
    "اللقص": 1,
    "لاقطه": 1,
    "تلقط": 1,
    "الألقاط": 1,
    "اللاقط": 1,
    "اللاقطة": 1,
    "اللقاط": 1,
    "اللقاطة": 1,
    "اللقط": 1,
    "اللقيطة": 1,
    "الملقاط": 1,
    "الملقوط": 1,
    "التلقاع": 1,
    "اللقعة": 1,
    "اللقاع": 1,
    "اللقاعة": 1,
    "الملقاع": 1,
    "التقف": 1,
    "اللقف": 1,
    "اللقيف": 1,
    "لق": 1,
    "اللق": 1,
    "اللقاق": 1,
    "تلقلق": 1,
    "اللقلاق": 1,
    "اللقلقة": 1,
    "ألقمه": 1,
    "لقمه": 1,
    "التقم": 1,
    "تلقم": 1,
    "التلقام والتلقامة": 1,
    "اللقيم": 1,
    "لقنه": 1,
    "تلقن": 1,
    "اللقن": 1,
    "لقاه": 1,
    "اللقوة": 1,
    "لقيه": 1,
    "لاقاه": 1,
    "التقيا": 1,
    "تلاقيا": 1,
    "تلقت": 1,
    "استلقى": 1,
    "الألقية": 1,
    "التلاقي": 1,
    "التلقاء": 1,
    "اللقى": 1,
    "اللقاة": 1,
    "اللقي": 1,
    "الملقى": 1,
    "لكأه": 1,
    "لكثه": 1,
    "لكث": 1,
    "اللكاث": 1,
    "اللكائي": 1,
    "اللكث": 1,
    "لكد": 1,
    "لاكد": 1,
    "التكده": 1,
    "تلكد": 1,
    "الألكد": 1,
    "الملكد": 1,
    "لكزه": 1,
    "لاكزه": 1,
    "تلاكزا": 1,
    "اللكاز": 1,
    "الملكز": 1,
    "لكشه": 1,
    "لكع": 1,
    "لكاع": 1,
    "اللكع": 1,
    "اللكعة": 1,
    "الملكعان": 1,
    "لكه": 1,
    "اللكاك": 1,
    "اللكيك": 1,
    "لكمه": 1,
    "لاكمه": 1,
    "تلاكما": 1,
    "التكم": 1,
    "الملكم": 1,
    "تلاكن": 1,
    "اللاكي": 1,
    "لمأه": 1,
    "ألمأ": 1,
    "التمأ": 1,
    "تلمأت": 1,
    "الملمؤة": 1,
    "تلمج": 1,
    "اللماج": 1,
    "اللمج": 1,
    "اللمجة": 1,
    "اللموج": 1,
    "اللميج": 1,
    "ألمح": 1,
    "لامحه": 1,
    "التمحه": 1,
    "الألمحي": 1,
    "اللامح": 1,
    "لمزه": 1,
    "لامزه": 1,
    "اللمزة": 1,
    "اللماز": 1,
    "لمسه": 1,
    "لامسه": 1,
    "الالتماس": 1,
    "اللماسة": 1,
    "اللموس": 1,
    "اللميس": 1,
    "ألمص": 1,
    "اللامص": 1,
    "اللمص": 1,
    "اللموص": 1,
    "ألمظه": 1,
    "لمظه": 1,
    "التمظ": 1,
    "اللماظ": 1,
    "اللماظة": 1,
    "اللمظة": 1,
    "المتلمظ": 1,
    "التمع": 1,
    "تلمع": 1,
    "الألمع": 1,
    "الألمعي": 1,
    "اللامع": 1,
    "اللامعة": 1,
    "اللماع": 1,
    "اللماعة": 1,
    "اللموع": 1,
    "الملمعة": 1,
    "اليلمع": 1,
    "اليلمعي": 1,
    "لمق": 1,
    "تلمق": 1,
    "اللماق": 1,
    "اليلمق": 1,
    "لمك": 1,
    "تلمك": 1,
    "اللماك": 1,
    "اللمك": 1,
    "اللميك": 1,
    "تلملم": 1,
    "اللملم": 1,
    "اللملوم": 1,
    "الململم": 1,
    "الململمة": 1,
    "اللامة": 1,
    "اللمام": 1,
    "الملم": 1,
    "الملمة": 1,
    "الملموم": 1,
    "الوجه الأول": 1,
    "الوجه الثاني": 1,
    "الوجه الثالث": 1,
    "لمى": 1,
    "لمي": 1,
    "تلمت": 1,
    "الألمى": 1,
    "اللمى": 1,
    "اللمياء": 1,
    "التهبت": 1,
    "تلهبت": 1,
    "الألهوب": 1,
    "اللهاب": 1,
    "اللهابة": 1,
    "اللهبان": 1,
    "اللهبة": 1,
    "اللهيب": 1,
    "الملهب": 1,
    "لهث": 1,
    "التهث": 1,
    "اللهاث": 1,
    "اللهاثي": 1,
    "اللهثة": 1,
    "لهج": 1,
    "ألهج": 1,
    "لهجه": 1,
    "لهوج": 1,
    "تلهوج": 1,
    "الهاج": 1,
    "الملهج": 1,
    "لهده": 1,
    "ألهد": 1,
    "اللهد": 1,
    "الملهد": 1,
    "لهذم": 1,
    "تلهذم": 1,
    "اللهاذمة": 1,
    "اللهذم": 1,
    "لهز": 1,
    "اللاهز": 1,
    "اللهاز": 1,
    "اللهز": 1,
    "اللهزة": 1,
    "الملهز": 1,
    "الملهوز": 1,
    "لهزم": 1,
    "اللهزمة": 1,
    "لهس": 1,
    "لاهس": 1,
    "اللهاس": 1,
    "اللهاسة": 1,
    "اللهسة": 1,
    "لهسم": 1,
    "اللهسم": 1,
    "لهطت": 1,
    "اللاهط": 1,
    "لهع": 1,
    "تلهيع": 1,
    "اللهاعة": 1,
    "اللهيعة": 1,
    "لهف": 1,
    "ألهف": 1,
    "تلهف": 1,
    "اللهف": 1,
    "اللهفان": 1,
    "اللهيف": 1,
    "الملهوف": 1,
    "لهق": 1,
    "لهوق": 1,
    "تلهق": 1,
    "تلهوق": 1,
    "اللهاق": 1,
    "الملهق": 1,
    "ألهمه": 1,
    "تلهم": 1,
    "استلهم": 1,
    "اللهام": 1,
    "اللهمة": 1,
    "اللهوم": 1,
    "اللهيم": 1,
    "اللهمم": 1,
    "اللهموم": 1,
    "اللهميم": 1,
    "ألهنه": 1,
    "لهنه": 1,
    "تلهن": 1,
    "اللهنة": 1,
    "ألهى": 1,
    "لاهى": 1,
    "لهاه": 1,
    "التهى": 1,
    "تلاهى": 1,
    "استلهى": 1,
    "الألهوة": 1,
    "الألهية": 1,
    "التلهية": 1,
    "اللهاء": 1,
    "اللهاة": 1,
    "اللهوة": 1,
    "الملهاة": 1,
    "لآب": 1,
    "ألاب": 1,
    "لوب": 1,
    "اللابة": 1,
    "اللوب": 1,
    "اللوبة": 1,
    "اللوبيا": 1,
    "الملاب": 1,
    "الملوب": 1,
    "ألاث": 1,
    "ألوثت": 1,
    "التاث": 1,
    "اللواث": 1,
    "اللواثة": 1,
    "اللوث": 1,
    "اللوثة": 1,
    "اللويثة": 1,
    "الليث": 1,
    "المليث": 1,
    "اللوجاء": 1,
    "ألاح": 1,
    "التاح": 1,
    "تلوح": 1,
    "استلاح": 1,
    "اللياح": 1,
    "الملواح": 1,
    "الملوح": 1,
    "الملياح": 1,
    "لاخه": 1,
    "التاخ": 1,
    "اللواخة": 1,
    "لود": 1,
    "الألود": 1,
    "ألاذ": 1,
    "لاوذ": 1,
    "التلواذ": 1,
    "اللاذ": 1,
    "اللوذ": 1,
    "لوذان": 1,
    "الملاوذ": 1,
    "الملوذ": 1,
    "لاز": 1,
    "اللوزين": 1,
    "اللوزينج": 1,
    "اللواز": 1,
    "الملاز": 1,
    "الملازة": 1,
    "الملوز": 1,
    "اللواس": 1,
    "اللواسة": 1,
    "اللوس": 1,
    "لاص": 1,
    "ألاصه": 1,
    "لاوصه": 1,
    "تلاوص": 1,
    "تلوص": 1,
    "اللواص": 1,
    "اللوص": 1,
    "اللوصة": 1,
    "الملوص": 1,
    "لاوط": 1,
    "لوطه": 1,
    "التاط": 1,
    "استلاط": 1,
    "اللوط": 1,
    "اللوطية": 1,
    "اللويطة": 1,
    "لاظه": 1,
    "التاظت": 1,
    "لاعت": 1,
    "ألاع": 1,
    "لوعه": 1,
    "التاع": 1,
    "اللاعة": 1,
    "اللوعة": 1,
    "اللوفة": 1,
    "اللواف": 1,
    "الملوف": 1,
    "لوق": 1,
    "اللواق": 1,
    "اللوق": 1,
    "اللوقة": 1,
    "لاكه": 1,
    "اللواك": 1,
    "اللولاء": 1,
    "الملولب": 1,
    "لامه": 1,
    "ألام": 1,
    "لاومه": 1,
    "لومه": 1,
    "تلاوموا": 1,
    "تلوم": 1,
    "اللائمة": 1,
    "اللومى واللوماء": 1,
    "اللومة": 1,
    "المتلوم": 1,
    "الملام والملامة": 1,
    "الون": 1,
    "الملونون": 1,
    "تلوه": 1,
    "اللاهة": 1,
    "لوه": 1,
    "ألوى": 1,
    "اللو": 1,
    "اللوة": 1,
    "لاوت": 1,
    "تلاووا": 1,
    "تلوى": 1,
    "استلوى": 1,
    "الالتواء": 1,
    "اللأوياء": 1,
    "اللوى": 1,
    "اللوية": 1,
    "اللياء": 1,
    "الليان": 1,
    "الملاوي": 1,
    "لاته": 1,
    "ألاته": 1,
    "الليت": 1,
    "لايثه": 1,
    "تليث": 1,
    "استليث": 1,
    "الأليث": 1,
    "اللائث": 1,
    "الليثة": 1,
    "تلايس": 1,
    "الأليس": 1,
    "الملايس": 1,
    "ليسض": 1,
    "ألاص": 1,
    "ألاطه": 1,
    "ليطه": 1,
    "اللياط": 1,
    "الليط": 1,
    "الليطة": 1,
    "اللياع": 1,
    "ليعة": 1,
    "الملياع": 1,
    "لاغه": 1,
    "تليغ": 1,
    "الأليغ": 1,
    "اللائغ": 1,
    "اللياغة": 1,
    "الليغ": 1,
    "ليفت": 1,
    "الليفاني": 1,
    "لاقت": 1,
    "ألاق": 1,
    "التاق": 1,
    "استلاقه": 1,
    "اللياق": 1,
    "الليق": 1,
    "الليقة": 1,
    "الملتاق": 1,
    "ألال": 1,
    "لايله": 1,
    "اللائل": 1,
    "الليلاء": 1,
    "المليل": 1,
    "الليم": 1,
    "الليمونيت": 1,
    "في الجيولوجيا": 1,
    "لاينه": 1,
    "استلان": 1,
    "الألين": 1,
    "اللينة": 1,
    "الملينة": 1,
    "الملين": 1,
    "الميم": 1,
    "مأج": 1,
    "مؤج": 1,
    "المأج": 1,
    "مأد": 1,
    "أمأد": 1,
    "امتأد": 1,
    "المأد": 1,
    "المئيد": 1,
    "مأر": 1,
    "مئر": 1,
    "امأر": 1,
    "ماءر": 1,
    "المئر": 1,
    "المئرة": 1,
    "مأس": 1,
    "مئس": 1,
    "المأس": 1,
    "مأش": 1,
    "المئط": 1,
    "أمأق": 1,
    "امتأق": 1,
    "المأق والماق": 1,
    "المؤق والموق": 1,
    "المأقة": 1,
    "مأمأت": 1,
    "المأن": 1,
    "المأنة": 1,
    "المؤنة": 1,
    "الممأنة": 1,
    "مأى": 1,
    "أمأى": 1,
    "ماءى": 1,
    "تماءى": 1,
    "تمتى": 1,
    "الماتة": 1,
    "المتات": 1,
    "أمتح": 1,
    "امتتح": 1,
    "تمتحت": 1,
    "المتوح": 1,
    "متخ": 1,
    "تماترت": 1,
    "امتتر": 1,
    "متش": 1,
    "متشت": 1,
    "المتش": 1,
    "أمتع": 1,
    "الماتع": 1,
    "متك": 1,
    "ماتكه": 1,
    "تمتك": 1,
    "متل": 1,
    "أمتن": 1,
    "ماتنه": 1,
    "تماتن": 1,
    "التمتان": 1,
    "الماتن": 1,
    "المتنة": 1,
    "مته": 1,
    "تماته": 1,
    "تمته": 1,
    "متا": 1,
    "أمتى": 1,
    "مث": 1,
    "المثاث": 1,
    "مثج": 1,
    "مثد": 1,
    "الماثد": 1,
    "امتثل": 1,
    "الأمثولة": 1,
    "المثلة": 1,
    "مثمت": 1,
    "مثنه": 1,
    "مثن": 1,
    "مجج": 1,
    "انمجت": 1,
    "المجاج": 1,
    "المجاجة": 1,
    "أمجده": 1,
    "ماجدهم": 1,
    "مجده": 1,
    "تماجدوا": 1,
    "تمجد": 1,
    "استمجد": 1,
    "الماجد": 1,
    "أمجرت": 1,
    "الأمجر": 1,
    "الممجر": 1,
    "مجسه": 1,
    "تمجس": 1,
    "المجوسي": 1,
    "المجوسية": 1,
    "المجسطي": 1,
    "مجع": 1,
    "ماجعه": 1,
    "امتجع": 1,
    "تماجعا": 1,
    "تمجع": 1,
    "المجعة": 1,
    "المجيع": 1,
    "مجلت": 1,
    "أمجل": 1,
    "تمجل": 1,
    "المجل": 1,
    "مجمج": 1,
    "تمجمج": 1,
    "المجماج": 1,
    "ماجنه": 1,
    "تماجنا": 1,
    "المجان": 1,
    "الممجن": 1,
    "مجنق": 1,
    "محته": 1,
    "محت": 1,
    "المحت": 1,
    "ماحجه": 1,
    "المحاح": 1,
    "أمحش": 1,
    "أمحصت": 1,
    "تمحصت": 1,
    "الأمحص": 1,
    "الممحص": 1,
    "أمحض": 1,
    "امتحض": 1,
    "الأمحوضة": 1,
    "المحض": 1,
    "أمحق": 1,
    "امتحق": 1,
    "انمحق وامحق": 1,
    "تمحق": 1,
    "المحاق": 1,
    "المحقة": 1,
    "المحيق": 1,
    "أمحكه": 1,
    "ماحكه": 1,
    "تماحك": 1,
    "تمحك": 1,
    "المحكان": 1,
    "أمحل": 1,
    "ماحله": 1,
    "تماحل": 1,
    "الماحل": 1,
    "الممحال": 1,
    "الممحل": 1,
    "الممحلة": 1,
    "تمحى": 1,
    "الممحاة": 1,
    "محى": 1,
    "أمخ": 1,
    "مخخ": 1,
    "امتخ": 1,
    "تمخخ": 1,
    "المخاخة": 1,
    "المخة": 1,
    "الممخ": 1,
    "مخرت": 1,
    "امتخر": 1,
    "الماخرة": 1,
    "الماخور": 1,
    "المخر": 1,
    "اليمخور": 1,
    "مخضت": 1,
    "أمخض": 1,
    "امتخض": 1,
    "تمخض": 1,
    "الممخضة": 1,
    "أمخط": 1,
    "امتخط": 1,
    "تمخط": 1,
    "المخاطة": 1,
    "المخطة": 1,
    "مخمخ": 1,
    "مخن": 1,
    "المخن": 1,
    "الممخن": 1,
    "مخى": 1,
    "تمخى": 1,
    "مدحه": 1,
    "امتدح": 1,
    "تمادحا": 1,
    "تمدحت": 1,
    "الأمدوحة": 1,
    "المدحة": 1,
    "الممادح": 1,
    "مدخ": 1,
    "مادخه": 1,
    "امتدخ": 1,
    "المديخ": 1,
    "ماده": 1,
    "امتد": 1,
    "تمادا": 1,
    "استمد": 1,
    "الإمدان": 1,
    "الأمدة": 1,
    "الأمدود": 1,
    "المداد": 1,
    "المدد": 1,
    "الممدود": 1,
    "أمدر": 1,
    "الأمدر": 1,
    "الممدرة والممدرة": 1,
    "مدش": 1,
    "تمدل": 1,
    "أمدى": 1,
    "ماداه": 1,
    "تمادى": 1,
    "المدية": 1,
    "المدي": 1,
    "الميداء": 1,
    "مذح": 1,
    "تمذحت": 1,
    "الأمذح": 1,
    "مذرت": 1,
    "أمذرت": 1,
    "تمذرت": 1,
    "المذر": 1,
    "مذرق": 1,
    "الماذريون": 1,
    "مذع": 1,
    "تمذع": 1,
    "المذاع": 1,
    "امتذق": 1,
    "امذق": 1,
    "المذق": 1,
    "المذقة": 1,
    "أمذل": 1,
    "امذل": 1,
    "المذيل": 1,
    "الممذل": 1,
    "مذمذ": 1,
    "المذماذ": 1,
    "المذمذي": 1,
    "مذى": 1,
    "أمذى": 1,
    "ماذى": 1,
    "الماذي": 1,
    "الماذية": 1,
    "المذاء": 1,
    "المذي": 1,
    "المذية": 1,
    "مرؤت": 1,
    "أمرأ": 1,
    "تمرأ": 1,
    "استمرأ": 1,
    "المروءة": 1,
    "مرت": 1,
    "ماروت": 1,
    "المرت": 1,
    "المروت": 1,
    "مرثه": 1,
    "الممرث": 1,
    "الممرثة": 1,
    "أمرج": 1,
    "المارج": 1,
    "المريج": 1,
    "الممراج": 1,
    "أمرح": 1,
    "التمراحة": 1,
    "المرحة": 1,
    "الممراح": 1,
    "أمرخ": 1,
    "تمرخ": 1,
    "الأمرخ": 1,
    "المرخ": 1,
    "المروخ": 1,
    "امرخد": 1,
    "التمراد": 1,
    "المارد": 1,
    "المراد": 1,
    "المرداء": 1,
    "المريد": 1,
    "المردقوش": 1,
    "مردل": 1,
    "امتر": 1,
    "المارورة": 1,
    "المرار": 1,
    "المري": 1,
    "المريراء": 1,
    "المريرة": 1,
    "مرز": 1,
    "امترز": 1,
    "المرز": 1,
    "المرزتان": 1,
    "أمرس": 1,
    "امترس": 1,
    "تمارس": 1,
    "الأمرس": 1,
    "المارستان": 1,
    "المراسة": 1,
    "المرس": 1,
    "المرسة": 1,
    "المريس": 1,
    "امترش": 1,
    "الأمرش": 1,
    "المراشة": 1,
    "المرشاء": 1,
    "تمرص": 1,
    "مارضت": 1,
    "التمريض": 1,
    "المراض": 1,
    "الممراض": 1,
    "الممروض": 1,
    "أمرط": 1,
    "مارطه": 1,
    "امترط": 1,
    "انمرط الشعر وامرط": 1,
    "تمرط": 1,
    "المراطة": 1,
    "المرط": 1,
    "المرطاء": 1,
    "المريطاء": 1,
    "المريطاوان": 1,
    "المريطى": 1,
    "مرطل": 1,
    "أمرع": 1,
    "تمرع": 1,
    "الأمروعة": 1,
    "المراع": 1,
    "المرع": 1,
    "المريع": 1,
    "الممراع": 1,
    "مرغت": 1,
    "أمرغ": 1,
    "مارغه": 1,
    "مرغه": 1,
    "تمرغت": 1,
    "المراغ": 1,
    "المراغة": 1,
    "المرغ": 1,
    "المرغة": 1,
    "الممرغة": 1,
    "مرقت": 1,
    "أمرق": 1,
    "امترق": 1,
    "امرق وانمرق": 1,
    "تمرق": 1,
    "المارق": 1,
    "المراق": 1,
    "المراقة": 1,
    "المريق": 1,
    "المرقة": 1,
    "الممراق": 1,
    "الممرق": 1,
    "المركيركروم": 1,
    "تمرمر": 1,
    "المرامر": 1,
    "المرمرة": 1,
    "المرمريس": 1,
    "أمرن": 1,
    "مارنت": 1,
    "المارن": 1,
    "المران": 1,
    "مرهت": 1,
    "الأمره": 1,
    "المره": 1,
    "المرهاء": 1,
    "المرهة": 1,
    "المرو": 1,
    "المروة": 1,
    "ماراه": 1,
    "امترى": 1,
    "تمارى": 1,
    "تمرى": 1,
    "المارية": 1,
    "الماري": 1,
    "المرية": 1,
    "الممري": 1,
    "مازجه": 1,
    "أمزح": 1,
    "مازحه": 1,
    "تمازحا": 1,
    "المزدة": 1,
    "الأمزر": 1,
    "المزرة": 1,
    "المازريون": 1,
    "مززه": 1,
    "تمزز": 1,
    "المازة": 1,
    "المز": 1,
    "المزز": 1,
    "المزاء": 1,
    "المزة": 1,
    "المزيز": 1,
    "تمزع": 1,
    "المزاعة": 1,
    "المزاع": 1,
    "المزعة": 1,
    "المزعي": 1,
    "مازقه": 1,
    "تمزق": 1,
    "المزاق": 1,
    "المزق": 1,
    "المزقة": 1,
    "المزيق": 1,
    "مزمزه": 1,
    "تمزمز": 1,
    "مزنه": 1,
    "تمزن": 1,
    "المازن": 1,
    "المزنة": 1,
    "مزا": 1,
    "أمزاه": 1,
    "مزاه": 1,
    "تمازى": 1,
    "تمزى": 1,
    "المازي": 1,
    "المازية": 1,
    "المتمازي": 1,
    "المزو والمزي": 1,
    "المزي": 1,
    "مسأ": 1,
    "أمسأ": 1,
    "المسء": 1,
    "ماسحه": 1,
    "امتسح": 1,
    "انمسح وامسح": 1,
    "تماسح": 1,
    "تمسح": 1,
    "الأمسح": 1,
    "الأمسوح": 1,
    "التمسح": 1,
    "المسحاء": 1,
    "المسيحة": 1,
    "الممسح": 1,
    "الممسوح": 1,
    "مسخه": 1,
    "أمسخ": 1,
    "امتسخ": 1,
    "امسخت": 1,
    "المسيخ": 1,
    "الممسوخ": 1,
    "المساد": 1,
    "المسداء": 1,
    "الممسود": 1,
    "ماسه": 1,
    "المسوس": 1,
    "الماسط": 1,
    "المسيط": 1,
    "المسيطة": 1,
    "المسع": 1,
    "المسعي": 1,
    "امتسك": 1,
    "المساك": 1,
    "المساكة": 1,
    "المسيك": 1,
    "المسكان": 1,
    "المسيكة": 1,
    "امتسل": 1,
    "المسالة": 1,
    "الميسون": 1,
    "مسا": 1,
    "ماساه": 1,
    "مسى": 1,
    "امتسى": 1,
    "تماسى": 1,
    "تمسى": 1,
    "التماسي": 1,
    "الماسي": 1,
    "المشج والمشيج": 1,
    "أمشحت": 1,
    "مشر": 1,
    "أمشر": 1,
    "امتشر": 1,
    "تمشر": 1,
    "الأمشر": 1,
    "الماشرة": 1,
    "المشر": 1,
    "المشرة": 1,
    "مششت": 1,
    "أمش": 1,
    "مشش": 1,
    "امتش": 1,
    "المشاش": 1,
    "المشاشة": 1,
    "المشش": 1,
    "مشطت": 1,
    "امتشطت": 1,
    "الماشطة": 1,
    "المشاطة": 1,
    "المشاط": 1,
    "المشطة": 1,
    "المشيط": 1,
    "الممشط": 1,
    "الممشوط": 1,
    "مشظ": 1,
    "المشظ": 1,
    "المشظة": 1,
    "امتشع": 1,
    "تمشع": 1,
    "المشوع": 1,
    "مشغ": 1,
    "المشغ": 1,
    "مشقت": 1,
    "أمشقه": 1,
    "ماشقه": 1,
    "امتشق": 1,
    "تماشقوا": 1,
    "تمشق": 1,
    "الأمشق": 1,
    "المشاقة": 1,
    "المشق": 1,
    "المشيق": 1,
    "الممشوق": 1,
    "مشل": 1,
    "مشلت": 1,
    "امتشل": 1,
    "مثلث الميمين": 1,
    "مشنه": 1,
    "مشنت": 1,
    "امتشن": 1,
    "تماشنا": 1,
    "المشان": 1,
    "المشنى": 1,
    "أمشى": 1,
    "استمشى": 1,
    "المشا": 1,
    "المشاء والمشو والمشو": 1,
    "ماشاه": 1,
    "امتشى": 1,
    "تماشى": 1,
    "المشاء": 1,
    "أمصح": 1,
    "المصاحة": 1,
    "مصخ": 1,
    "امتصخ": 1,
    "امصخ": 1,
    "مصد": 1,
    "المصدة": 1,
    "مصرت": 1,
    "أمصرت": 1,
    "تمصر": 1,
    "امتصر": 1,
    "الماصر": 1,
    "المصارة": 1,
    "المصر": 1,
    "الممصر": 1,
    "الممصرة": 1,
    "أمصه": 1,
    "تمصص": 1,
    "المصان": 1,
    "المصيصة": 1,
    "الممصوص": 1,
    "الممصوصة": 1,
    "مصطك": 1,
    "المصطكا والمصطكاء": 1,
    "مصع": 1,
    "أمصع": 1,
    "ماصع": 1,
    "انمصع": 1,
    "تماصعوا": 1,
    "الماصع": 1,
    "المصع": 1,
    "المصعة": 1,
    "المصوع": 1,
    "أمصلت": 1,
    "الماصل": 1,
    "المصالة": 1,
    "الممصل": 1,
    "المصامص": 1,
    "مضحت": 1,
    "مضره": 1,
    "تمضر": 1,
    "المضارة": 1,
    "المضر": 1,
    "المضيرة": 1,
    "مضه": 1,
    "أمضه": 1,
    "ماضه": 1,
    "تماض": 1,
    "المضاض": 1,
    "المض": 1,
    "المضض": 1,
    "المضة": 1,
    "أمضغ": 1,
    "ماضغه": 1,
    "مضغه": 1,
    "الماضغة": 1,
    "المضاغ": 1,
    "المضاغة": 1,
    "المضيغة": 1,
    "تمضمض": 1,
    "المضماض": 1,
    "أمضى": 1,
    "تمضى": 1,
    "المضواء": 1,
    "مطحه": 1,
    "امتطح": 1,
    "تمطح": 1,
    "مطخ": 1,
    "الماطخ": 1,
    "المطاخ": 1,
    "تماطر": 1,
    "تمطر": 1,
    "استمطر": 1,
    "الماطر": 1,
    "المستمطر": 1,
    "المطرة": 1,
    "المطير": 1,
    "الممطار": 1,
    "الممطور": 1,
    "المطيطاء": 1,
    "المطيطي": 1,
    "المطيطة": 1,
    "مطع": 1,
    "تمطق": 1,
    "المطقة": 1,
    "ماطله": 1,
    "امتطل": 1,
    "المطالة": 1,
    "المطال": 1,
    "الممطل": 1,
    "مطمط": 1,
    "أمطى": 1,
    "امتطى": 1,
    "تمطى": 1,
    "الأمطى": 1,
    "المطا": 1,
    "المطو": 1,
    "المطواء": 1,
    "المطوة": 1,
    "المطية": 1,
    "مظه": 1,
    "أمظه": 1,
    "ماظه": 1,
    "تماظوا": 1,
    "المظاظة": 1,
    "مظع": 1,
    "تمظع": 1,
    "المظعة": 1,
    "تمعج": 1,
    "المعجة": 1,
    "الممعج": 1,
    "امتعد": 1,
    "المتمعدة": 1,
    "تمعدد": 1,
    "أمعر": 1,
    "تمعر": 1,
    "المعر": 1,
    "أمعز": 1,
    "استمعز": 1,
    "الأمعز": 1,
    "الأمعوز": 1,
    "المعزي والمعزى": 1,
    "المعاز": 1,
    "تمعس": 1,
    "معص": 1,
    "المعص": 1,
    "أمعضه": 1,
    "معضه": 1,
    "امتعض": 1,
    "امتعط": 1,
    "تمعط": 1,
    "الأمعط": 1,
    "الممعط": 1,
    "معق": 1,
    "أمعق": 1,
    "تمعقت": 1,
    "المعق": 1,
    "ماعكه": 1,
    "تمعك": 1,
    "المعك": 1,
    "المعكوكاء": 1,
    "الممعك": 1,
    "أمعله": 1,
    "امتعل": 1,
    "المعل": 1,
    "معمع": 1,
    "المعامع": 1,
    "المعمع": 1,
    "المعمعان": 1,
    "المعمعة": 1,
    "المعمعي": 1,
    "أمعن": 1,
    "المعنة": 1,
    "معًا": 1,
    "أمعت": 1,
    "تمعى": 1,
    "المعو": 1,
    "الماعي": 1,
    "المعاء": 1,
    "مغث": 1,
    "ماغثه": 1,
    "المغث": 1,
    "المغيث": 1,
    "مغد": 1,
    "أمغد": 1,
    "المغد": 1,
    "أمغرت": 1,
    "الأمغر": 1,
    "المغر": 1,
    "الممغار": 1,
    "الممغرة": 1,
    "مغسه": 1,
    "امغس": 1,
    "المغس": 1,
    "تمغص": 1,
    "المغص والمغص": 1,
    "امتغط": 1,
    "أمغط": 1,
    "تمغط": 1,
    "الممغط": 1,
    "مغلت": 1,
    "أمغلت": 1,
    "المغلة": 1,
    "الممغل": 1,
    "مغمغ": 1,
    "تمغمغ": 1,
    "المغناطيس والمغنطيس": 1,
    "مغا": 1,
    "مغى": 1,
    "تمغى": 1,
    "مقته": 1,
    "تماقتوا": 1,
    "تمقت": 1,
    "المقتي": 1,
    "المقدونس": 1,
    "أمقر": 1,
    "اليمقور": 1,
    "مقس": 1,
    "مقست": 1,
    "ماقسه": 1,
    "تماقس": 1,
    "تمقست": 1,
    "مقط": 1,
    "امتقط": 1,
    "تمقط": 1,
    "الماقط": 1,
    "المقاط": 1,
    "امتقع": 1,
    "الميقع": 1,
    "مق": 1,
    "مقق": 1,
    "امتق": 1,
    "تمقق": 1,
    "الأمق": 1,
    "المققة": 1,
    "مقله": 1,
    "ماقله": 1,
    "امتقل": 1,
    "تماقلا": 1,
    "المقل": 1,
    "المقلة": 1,
    "مقمق": 1,
    "المقامق": 1,
    "مقه": 1,
    "الأمقه": 1,
    "مقا": 1,
    "أمكثه": 1,
    "مكثه": 1,
    "تمكث": 1,
    "المكيث": 1,
    "المكيثي والمكيثاء": 1,
    "مكد": 1,
    "ماكره": 1,
    "امتكر": 1,
    "تماكروا": 1,
    "المكرة": 1,
    "الممكورة": 1,
    "ماكسه": 1,
    "الماكس": 1,
    "تماكس": 1,
    "مك": 1,
    "مكك": 1,
    "امتك": 1,
    "تمكك": 1,
    "المكاكة": 1,
    "مكمك": 1,
    "تمكمك": 1,
    "مكنت": 1,
    "أمكنه": 1,
    "استمكن": 1,
    "المتمكن": 1,
    "المكني": 1,
    "المكنة": 1,
    "مكا": 1,
    "مكيت": 1,
    "تمكى": 1,
    "المكا": 1,
    "المكاء": 1,
    "ملؤ": 1,
    "أملأه": 1,
    "مالأه": 1,
    "تمالأ": 1,
    "استملأه": 1,
    "الأملأ": 1,
    "المالئ": 1,
    "الملاء": 1,
    "الملاءة": 1,
    "الملآن": 1,
    "الملأة": 1,
    "المملئ": 1,
    "ملث": 1,
    "مالثه": 1,
    "الملث": 1,
    "الملثة": 1,
    "أملجت": 1,
    "امتلج": 1,
    "املاج": 1,
    "الأملج": 1,
    "الأملوج": 1,
    "المالج": 1,
    "المليج": 1,
    "مالحه": 1,
    "ملحت": 1,
    "امتلح": 1,
    "تملح": 1,
    "املح": 1,
    "استملح": 1,
    "الأملح": 1,
    "الملاحي": 1,
    "الملحاء": 1,
    "الملحة": 1,
    "مالخه": 1,
    "امتلخ": 1,
    "تملخ": 1,
    "الملاخ": 1,
    "الملوخية": 1,
    "المليخ": 1,
    "الأملد": 1,
    "الأملود": 1,
    "الملد": 1,
    "الملداء": 1,
    "الملدان": 1,
    "ملذ": 1,
    "امتلذت": 1,
    "ملز": 1,
    "أملزه": 1,
    "امتلز": 1,
    "أملست": 1,
    "انملس واملس": 1,
    "تملس": 1,
    "املاس": 1,
    "الإمليس": 1,
    "الإمليسة": 1,
    "الملس": 1,
    "الملسى": 1,
    "الملاسة": 1,
    "المملسة": 1,
    "المليساء": 1,
    "ملش": 1,
    "أملصت": 1,
    "انملص": 1,
    "الأملص": 1,
    "الإمليص": 1,
    "الملص": 1,
    "المليص": 1,
    "المملاص": 1,
    "أملطت": 1,
    "مالطه": 1,
    "تملط": 1,
    "امتلط": 1,
    "الملاط": 1,
    "الملط": 1,
    "الملطى": 1,
    "المليط": 1,
    "المملاط": 1,
    "ملعت": 1,
    "أملعت": 1,
    "امتلعت": 1,
    "انملعت": 1,
    "الملاع": 1,
    "الملع": 1,
    "المليع": 1,
    "الملوع": 1,
    "الميلع": 1,
    "ملغ": 1,
    "مالغه": 1,
    "تمالغ": 1,
    "تملغ": 1,
    "الأملغ": 1,
    "المالغ": 1,
    "الملغ": 1,
    "ملقت": 1,
    "أملقت": 1,
    "امتلق": 1,
    "انملق": 1,
    "المالق": 1,
    "الملق": 1,
    "الملقة": 1,
    "الملاق": 1,
    "المليق": 1,
    "المملق": 1,
    "المملقة": 1,
    "الميلق": 1,
    "أملكه": 1,
    "الملكوت": 1,
    "المليك": 1,
    "المليكة": 1,
    "المملوك": 1,
    "امتل": 1,
    "تملل": 1,
    "استمله": 1,
    "الأملولة": 1,
    "المالولة": 1,
    "الملال": 1,
    "الملى": 1,
    "الملالة": 1,
    "الملول": 1,
    "الملولة": 1,
    "المليلة": 1,
    "المليم": 1,
    "الملامل": 1,
    "الململى": 1,
    "الملمول": 1,
    "الملنخوليا": 1,
    "أملاه": 1,
    "استملاه": 1,
    "الملاة": 1,
    "الملاوة": 1,
    "الملوة": 1,
    "الملي": 1,
    "منأ": 1,
    "المنيئة": 1,
    "المنبار": 1,
    "المنجة والمنجو": 1,
    "منحه": 1,
    "أمنحت": 1,
    "مانحه": 1,
    "امتنح": 1,
    "تمنح": 1,
    "استمنحه": 1,
    "المنوح": 1,
    "المنيح": 1,
    "المنيحة": 1,
    "منذ ومذ": 1,
    "مانعه": 1,
    "تمانعا": 1,
    "المنعة": 1,
    "المناع": 1,
    "المنيع": 1,
    "مانه": 1,
    "مننه": 1,
    "امتن": 1,
    "الممنون": 1,
    "المنونة": 1,
    "المنين": 1,
    "مناه": 1,
    "المنا": 1,
    "أمنى": 1,
    "ماناه": 1,
    "امتنى": 1,
    "المنى": 1,
    "المنية": 1,
    "مهج": 1,
    "امتهج": 1,
    "الأمهج": 1,
    "الأمهوج": 1,
    "المهجة": 1,
    "امتهد": 1,
    "تمهد": 1,
    "استمهد": 1,
    "المهدة": 1,
    "المهيد": 1,
    "الممهد": 1,
    "أمهر": 1,
    "تمهر": 1,
    "المهرية": 1,
    "المهيرة": 1,
    "المهرق": 1,
    "مهزه": 1,
    "مهق": 1,
    "تمهق": 1,
    "مهك": 1,
    "تماهكوا": 1,
    "أمهله": 1,
    "مهله": 1,
    "تمهل": 1,
    "استمهله": 1,
    "مهمه": 1,
    "تمهمه": 1,
    "المهمه": 1,
    "مهن": 1,
    "أمهنه": 1,
    "امتهن": 1,
    "مهو": 1,
    "مهى": 1,
    "أمهى": 1,
    "استمهى": 1,
    "المهو": 1,
    "ماوت": 1,
    "موتت": 1,
    "تماوت": 1,
    "استمات": 1,
    "الموتان": 1,
    "ماث": 1,
    "انماث": 1,
    "الممواج": 1,
    "الماذ": 1,
    "أمارت": 1,
    "الموارة": 1,
    "المواز": 1,
    "الموسى": 1,
    "الماش": 1,
    "المواصة": 1,
    "ماق": 1,
    "تماوق": 1,
    "المائق": 1,
    "الموق": 1,
    "الموقان": 1,
    "تمول": 1,
    "الميلة": 1,
    "الموماء": 1,
    "الموماة": 1,
    "الموميا": 1,
    "مونه": 1,
    "تمون": 1,
    "ماهت": 1,
    "أماهت": 1,
    "تموه": 1,
    "الماه": 1,
    "الماهية": 1,
    "الماوية": 1,
    "الموهة": 1,
    "الميهة": 1,
    "ماثت": 1,
    "أماث": 1,
    "ميث": 1,
    "تميث": 1,
    "الميثاء": 1,
    "الميث": 1,
    "امتاح": 1,
    "تمايح": 1,
    "تميح": 1,
    "استماحه": 1,
    "الماح": 1,
    "الميح": 1,
    "أماده": 1,
    "امتاده": 1,
    "الممتاد": 1,
    "الميدة": 1,
    "أمار": 1,
    "الميرة": 1,
    "الميار": 1,
    "أماز": 1,
    "امتاز": 1,
    "انماز": 1,
    "تمايز": 1,
    "استماز": 1,
    "الميز": 1,
    "تميس": 1,
    "الميسان": 1,
    "المياس": 1,
    "أماطه": 1,
    "تمايط": 1,
    "المياط": 1,
    "أماع": 1,
    "انماع": 1,
    "الإماعة": 1,
    "المائعة": 1,
    "الميعة": 1,
    "أمال": 1,
    "تميل": 1,
    "استمال": 1,
    "الميلاء": 1,
    "تماين": 1,
    "المين": 1,
    "المينى": 1,
    "نأث": 1,
    "أنأثه": 1,
    "نأج": 1,
    "النئيج": 1,
    "نأدت": 1,
    "النؤود": 1,
    "نأرت": 1,
    "النؤور": 1,
    "الناسوت": 1,
    "نأش": 1,
    "انتأش": 1,
    "تناءش": 1,
    "النؤوش": 1,
    "نأف": 1,
    "نئف": 1,
    "نأل": 1,
    "نأمت": 1,
    "النأمة": 1,
    "النئيم": 1,
    "نأنأ": 1,
    "تنأنأ": 1,
    "النأنأ": 1,
    "النأنأة": 1,
    "أنأى": 1,
    "انتأى": 1,
    "تناءوا": 1,
    "المنتأى": 1,
    "النؤى": 1,
    "أنبأه": 1,
    "نابأه": 1,
    "استنبأ": 1,
    "النبأ": 1,
    "النبأة": 1,
    "النبيء": 1,
    "نبب": 1,
    "تنبب": 1,
    "الأنبوب والأنبوبة": 1,
    "المنبت": 1,
    "النبوت": 1,
    "الينبوت": 1,
    "نبث": 1,
    "انتبث": 1,
    "تنابث": 1,
    "الأنبوثة": 1,
    "النبيث": 1,
    "النبيثة": 1,
    "نبج": 1,
    "أنبح": 1,
    "استنبح": 1,
    "نبخ": 1,
    "النبخة": 1,
    "نابذه": 1,
    "تنابذ": 1,
    "المنبذة": 1,
    "النباذ": 1,
    "النبذة": 1,
    "انتبر": 1,
    "الأنبار": 1,
    "النبرة": 1,
    "النبراس": 1,
    "نبزه": 1,
    "النبز": 1,
    "النبزة": 1,
    "أنبس": 1,
    "نبشه": 1,
    "انتبش": 1,
    "الأنبوش": 1,
    "النباش": 1,
    "النباشة": 1,
    "نبص": 1,
    "أنبضه": 1,
    "المنبض": 1,
    "أنبط": 1,
    "نبطه": 1,
    "تنبط": 1,
    "انتبط": 1,
    "استنبط": 1,
    "النبطة": 1,
    "أنبع": 1,
    "تنبع": 1,
    "النابعة": 1,
    "النبيع": 1,
    "النابغ والنابغة": 1,
    "النباغ": 1,
    "النباغ والنباغة": 1,
    "النبغ": 1,
    "النبيغ": 1,
    "نبك": 1,
    "أنبك": 1,
    "النبكة": 1,
    "أنبل": 1,
    "نابله": 1,
    "نبله": 1,
    "تنابل": 1,
    "انتبل": 1,
    "استنبله": 1,
    "النابل": 1,
    "النبال": 1,
    "أنبهه": 1,
    "انتبه": 1,
    "استنبه": 1,
    "المنبهة": 1,
    "المنبوه": 1,
    "النابه": 1,
    "النبيه": 1,
    "النبه": 1,
    "أنبى": 1,
    "تنبى": 1,
    "نتت": 1,
    "النتة": 1,
    "تناتجت": 1,
    "استنتج": 1,
    "المنتوجة": 1,
    "النتوج": 1,
    "نتح": 1,
    "تنتح": 1,
    "المنتح": 1,
    "النتح": 1,
    "نتخ": 1,
    "نتره": 1,
    "ناتره": 1,
    "انتتر": 1,
    "النتر": 1,
    "أنتش": 1,
    "المنتاش": 1,
    "النتاش": 1,
    "النتش": 1,
    "أنتع": 1,
    "نتغ": 1,
    "أنتف": 1,
    "انتتف": 1,
    "تناتف": 1,
    "تنتف": 1,
    "المنتاف": 1,
    "النتافة": 1,
    "النتفة": 1,
    "النتوف": 1,
    "النتيف": 1,
    "نتق": 1,
    "أنتق": 1,
    "انتتق": 1,
    "المنتاق": 1,
    "المنتق": 1,
    "النتاق": 1,
    "نتل": 1,
    "انتتل": 1,
    "تناتل": 1,
    "النتيلة": 1,
    "المنتن": 1,
    "نتا": 1,
    "أنتى": 1,
    "تنتى": 1,
    "تناث": 1,
    "المنث": 1,
    "المنثة": 1,
    "النثاث": 1,
    "النث": 1,
    "النثيثة": 1,
    "نثج": 1,
    "استنثج": 1,
    "نثرت": 1,
    "أنثره": 1,
    "ناثره": 1,
    "نثره": 1,
    "انتثر": 1,
    "تنثر": 1,
    "استنثر": 1,
    "المنثار": 1,
    "المنثر": 1,
    "المنثور": 1,
    "النثار والنثارة": 1,
    "النثرة": 1,
    "النثور": 1,
    "النثير": 1,
    "نثط": 1,
    "النثط": 1,
    "أنثع": 1,
    "نثل": 1,
    "أنثله": 1,
    "انتثله": 1,
    "تناثل": 1,
    "استنثل": 1,
    "النثالة": 1,
    "النثلة": 1,
    "النثيلة": 1,
    "نثا": 1,
    "ناثاه": 1,
    "تناثوا": 1,
    "نجأ": 1,
    "النجوء": 1,
    "انتجب": 1,
    "استنجب": 1,
    "المنجاب": 1,
    "النجابة": 1,
    "النجب": 1,
    "النجيب": 1,
    "النجيبة": 1,
    "نجث": 1,
    "انتجث": 1,
    "تناجث": 1,
    "تنجث": 1,
    "النجث": 1,
    "النجاث": 1,
    "النجيث": 1,
    "النجيثة": 1,
    "أنجح": 1,
    "تناجحت": 1,
    "تنجح": 1,
    "استنجحه": 1,
    "النجح": 1,
    "النجيح": 1,
    "نجخ": 1,
    "انتجخ": 1,
    "تناجخ": 1,
    "الناجخ": 1,
    "الناجخة": 1,
    "النجاخ": 1,
    "النجخ": 1,
    "النجخة": 1,
    "النجوخ": 1,
    "أنجد": 1,
    "ناجده": 1,
    "تنجد": 1,
    "استنجد": 1,
    "المنجاد": 1,
    "المنجدة": 1,
    "المنجود": 1,
    "الناجود": 1,
    "النجيد": 1,
    "نجذه": 1,
    "تناجذ": 1,
    "الأنجذان": 1,
    "المنجذ": 1,
    "الناجذ": 1,
    "النجذ": 1,
    "أنجر": 1,
    "المنجر": 1,
    "المنجرة": 1,
    "المنجور": 1,
    "الناجر": 1,
    "النجران": 1,
    "النجر": 1,
    "النجيرة": 1,
    "ناجزه": 1,
    "تنجز": 1,
    "استنجز": 1,
    "الناجز": 1,
    "النجز": 1,
    "النجيز": 1,
    "النجيزة": 1,
    "أنجسه": 1,
    "نجسه": 1,
    "تنجس": 1,
    "الناجس": 1,
    "النجاسة": 1,
    "النجس": 1,
    "النجيس": 1,
    "نجش": 1,
    "تناجش": 1,
    "استنجش": 1,
    "المنجاش": 1,
    "المنجش": 1,
    "المنجوش": 1,
    "الناجش": 1,
    "النجاش": 1,
    "النجاشي": 1,
    "النجوش": 1,
    "أنجع": 1,
    "انتجع": 1,
    "تنجع": 1,
    "استنجع": 1,
    "المنجع": 1,
    "الناجع": 1,
    "النجع": 1,
    "النجعة": 1,
    "النجوع": 1,
    "النجيع": 1,
    "أنجف": 1,
    "انتجف": 1,
    "استنجف": 1,
    "المنجاف": 1,
    "المنجوف": 1,
    "النجاف": 1,
    "النجفة": 1,
    "النجوف": 1,
    "النجيف": 1,
    "أنجل": 1,
    "انتجل": 1,
    "تناجل": 1,
    "استنجل": 1,
    "المنجلة": 1,
    "النجل": 1,
    "النجلاء": 1,
    "النجيل": 1,
    "أنجم": 1,
    "انتجم": 1,
    "تنجم": 1,
    "النجام": 1,
    "نجنجه": 1,
    "تنجنج": 1,
    "نجه": 1,
    "أنجى": 1,
    "نجى": 1,
    "ناجاه": 1,
    "انتجى": 1,
    "تناجى": 1,
    "تنجى": 1,
    "استنجى": 1,
    "المنجى": 1,
    "المنجاة": 1,
    "الناجية": 1,
    "النجاء": 1,
    "النجا": 1,
    "النجاوة": 1,
    "النجو": 1,
    "النجوة": 1,
    "النجي": 1,
    "النجية": 1,
    "تناحب": 1,
    "النحاب": 1,
    "النحب": 1,
    "النحبة": 1,
    "انتحت": 1,
    "المنحات": 1,
    "المنحت": 1,
    "النحيت": 1,
    "النحيتة": 1,
    "النحيح": 1,
    "نحره": 1,
    "ناحره": 1,
    "تناحر": 1,
    "المنحار": 1,
    "المنحر": 1,
    "الناحرة": 1,
    "النحار": 1,
    "النحرير": 1,
    "النحور": 1,
    "النحير": 1,
    "النحيرة": 1,
    "نحز": 1,
    "المنحاز": 1,
    "النحاز": 1,
    "النحازة": 1,
    "نحسه": 1,
    "أنحست": 1,
    "انتحس": 1,
    "تناحس": 1,
    "تنحس": 1,
    "استنحس": 1,
    "المنحس": 1,
    "النحاسة": 1,
    "النحيس": 1,
    "نحصت": 1,
    "نحض": 1,
    "ناحضه": 1,
    "انتحض": 1,
    "النحض": 1,
    "النحضة": 1,
    "نحط": 1,
    "النحطة": 1,
    "النحيط": 1,
    "أنحفه": 1,
    "أنحله": 1,
    "نحله": 1,
    "تنحل": 1,
    "النحالة": 1,
    "النحلى": 1,
    "النحيل": 1,
    "النحمة": 1,
    "النحنح": 1,
    "أنحى": 1,
    "ناحاه": 1,
    "المنحاة": 1,
    "الناحي": 1,
    "النحواء": 1,
    "النحي": 1,
    "النحية": 1,
    "أنخب": 1,
    "انتخبه": 1,
    "المنتخب": 1,
    "المنخاب": 1,
    "المنخوب": 1,
    "النخاب": 1,
    "النخيب": 1,
    "نخج": 1,
    "استنخج": 1,
    "النخاجة": 1,
    "النخ": 1,
    "النخة": 1,
    "الناخذاة": 1,
    "المنخور": 1,
    "الناخر": 1,
    "الناخرة": 1,
    "النخوار": 1,
    "النخور": 1,
    "النخروب": 1,
    "نخزه": 1,
    "تناخست": 1,
    "المنخس والمنخاس": 1,
    "الناخس": 1,
    "النخاس": 1,
    "النخاسة": 1,
    "النخوس": 1,
    "النخيس": 1,
    "النخيسة": 1,
    "نخش": 1,
    "تنخش": 1,
    "نخص": 1,
    "أنخصه": 1,
    "انتخص": 1,
    "الناخص": 1,
    "نخط": 1,
    "النخط": 1,
    "انتخع": 1,
    "تنخع": 1,
    "المنخع": 1,
    "النخاعة": 1,
    "نخف": 1,
    "أنخف": 1,
    "النخاف": 1,
    "النخفة": 1,
    "انتخل": 1,
    "تنخل": 1,
    "النخال": 1,
    "النخيلة": 1,
    "نخنخ": 1,
    "تنخنخت": 1,
    "نخي": 1,
    "أنخى": 1,
    "انتخى": 1,
    "استنخى": 1,
    "النخوة": 1,
    "ندأ": 1,
    "نودأ": 1,
    "الندأة": 1,
    "النديء": 1,
    "أندب": 1,
    "ندبه": 1,
    "انتدب": 1,
    "النادبة": 1,
    "انتدحت": 1,
    "تندحت": 1,
    "المندوحة": 1,
    "الندح": 1,
    "الندحة": 1,
    "ندخه": 1,
    "أندخه": 1,
    "تندخ": 1,
    "المندخ": 1,
    "تناد": 1,
    "الناد": 1,
    "النديد": 1,
    "استندر": 1,
    "الندرى": 1,
    "نادسه": 1,
    "تندس": 1,
    "النادس": 1,
    "الندس": 1,
    "ندش": 1,
    "ندص": 1,
    "المنداص": 1,
    "ندغ": 1,
    "أندغ": 1,
    "نادغ": 1,
    "انتدغ": 1,
    "المندغ": 1,
    "الندغة": 1,
    "ندفت": 1,
    "أندف": 1,
    "المندف والمندفة": 1,
    "الندافة": 1,
    "النداف": 1,
    "الندفة": 1,
    "انتدل": 1,
    "تندل": 1,
    "نودل": 1,
    "أندمه": 1,
    "نادمه": 1,
    "ندمه": 1,
    "تنادم": 1,
    "انتدم": 1,
    "المندم": 1,
    "المندمة": 1,
    "النديم": 1,
    "استنده": 1,
    "انتده": 1,
    "المنده": 1,
    "الندهة": 1,
    "النواده": 1,
    "أندى": 1,
    "انتدى": 1,
    "تنادى": 1,
    "تندى": 1,
    "المتندى": 1,
    "المندية": 1,
    "النادية": 1,
    "الندي": 1,
    "النديان": 1,
    "أنذره": 1,
    "النذارة": 1,
    "نذع": 1,
    "النذعة": 1,
    "النرجسية": 1,
    "النارجيل": 1,
    "النارجيلة": 1,
    "النردن": 1,
    "الناردين": 1,
    "النارنج": 1,
    "نزأ": 1,
    "نزئ": 1,
    "النزاء": 1,
    "النزيء": 1,
    "أنزح": 1,
    "انتزح": 1,
    "المنزاح": 1,
    "النازح": 1,
    "النزح": 1,
    "النزيح": 1,
    "أنزر": 1,
    "تنزر": 1,
    "المنزور": 1,
    "النزر": 1,
    "النزرة": 1,
    "النزور": 1,
    "النزير": 1,
    "ناز": 1,
    "نززه": 1,
    "المنز": 1,
    "النزاز": 1,
    "النزة": 1,
    "نزعه": 1,
    "استنزع": 1,
    "المنزع": 1,
    "المنزعة": 1,
    "النزاعة": 1,
    "النزعاء": 1,
    "النزوع": 1,
    "النزيع": 1,
    "النزيعة": 1,
    "المنزغ": 1,
    "النازغ": 1,
    "النزاغ": 1,
    "النزغ": 1,
    "النزغة": 1,
    "النزيغة": 1,
    "أنزف": 1,
    "المنزاف": 1,
    "المنزفة": 1,
    "النازف": 1,
    "النزفة": 1,
    "النزوف": 1,
    "أنزق": 1,
    "نازقه": 1,
    "نزقه": 1,
    "تنازق": 1,
    "المنازق": 1,
    "النزاق": 1,
    "النزق": 1,
    "النزقة": 1,
    "النزاك": 1,
    "النزك": 1,
    "النزيك": 1,
    "نازله": 1,
    "استنزله": 1,
    "النزالة": 1,
    "النزال": 1,
    "النزل": 1,
    "النزلة": 1,
    "نزهه": 1,
    "استنزه": 1,
    "المنتزه": 1,
    "المنزه": 1,
    "النازه": 1,
    "النزهي": 1,
    "أنزاه": 1,
    "تنزى": 1,
    "انتزى": 1,
    "النزوان": 1,
    "النزي": 1,
    "النزية": 1,
    "نسأت": 1,
    "نسئت": 1,
    "أنسأ": 1,
    "ناساه": 1,
    "انتسأ": 1,
    "استنسأه": 1,
    "المنسأة": 1,
    "النسء": 1,
    "النسأة": 1,
    "النسوء": 1,
    "النسيئة": 1,
    "أنسبت": 1,
    "تنسب": 1,
    "استنسب": 1,
    "المنسوب": 1,
    "النساب": 1,
    "النسابة": 1,
    "النسيب": 1,
    "نسجت": 1,
    "انتسج": 1,
    "المنسج": 1,
    "النساجة": 1,
    "النساج": 1,
    "نسح": 1,
    "المنساح": 1,
    "النساح": 1,
    "النسح": 1,
    "ناسخه": 1,
    "انتسخ": 1,
    "استنسخ": 1,
    "التناسخية": 1,
    "انتسر": 1,
    "تنسر": 1,
    "استنسر": 1,
    "المنسر": 1,
    "النسيرة": 1,
    "النسرين": 1,
    "نس": 1,
    "نسس": 1,
    "تنسس": 1,
    "المنسة": 1,
    "المنسوس": 1,
    "النساس": 1,
    "النسيس": 1,
    "النسيسة": 1,
    "نسع": 1,
    "أنسع": 1,
    "نسعت": 1,
    "انتسعت": 1,
    "المنسعة": 1,
    "الناسع": 1,
    "الناسعة": 1,
    "النسع": 1,
    "النسعة": 1,
    "نسغت": 1,
    "أنسغت": 1,
    "انتسغ": 1,
    "المنسغة": 1,
    "الناسغ": 1,
    "النسغ": 1,
    "النسيغ": 1,
    "أنسفت": 1,
    "تناسف": 1,
    "انتسف": 1,
    "المنسف": 1,
    "المنسفة": 1,
    "النسافة": 1,
    "النسفان": 1,
    "النسفة": 1,
    "النسوف": 1,
    "النسيف": 1,
    "النسيفة": 1,
    "أنسق": 1,
    "نسقه": 1,
    "انتسقت": 1,
    "تناسقت": 1,
    "تنسقت": 1,
    "النسيق": 1,
    "انتسك": 1,
    "تنسك": 1,
    "المنسوكة": 1,
    "الناسكة": 1,
    "النسك": 1,
    "النسيكة": 1,
    "أنسل": 1,
    "انتسل": 1,
    "الناسل": 1,
    "النسال": 1,
    "النسلان": 1,
    "النسول": 1,
    "النسولة": 1,
    "النسيل": 1,
    "النسيلة": 1,
    "نسمت": 1,
    "ناسمه": 1,
    "تنسمت": 1,
    "المنسم": 1,
    "الناسم": 1,
    "النسم": 1,
    "النسمة": 1,
    "نسنس": 1,
    "النسناس": 1,
    "نسا": 1,
    "أنساه": 1,
    "نساه": 1,
    "تناسى": 1,
    "الأنسى": 1,
    "النسوان": 1,
    "النسي": 1,
    "استنشأ": 1,
    "المنشأة": 1,
    "الناشئة": 1,
    "النشئ": 1,
    "النشيئة": 1,
    "أنشب": 1,
    "ناشبه": 1,
    "انتشب": 1,
    "تناشب": 1,
    "تنشب": 1,
    "المنشبة": 1,
    "الناشب": 1,
    "النشب": 1,
    "النشبة": 1,
    "نشح": 1,
    "انتشح": 1,
    "النشاح": 1,
    "النشوح": 1,
    "أنشد": 1,
    "تناشدوا": 1,
    "تنشد": 1,
    "استنشد": 1,
    "النشاد": 1,
    "النشيدة": 1,
    "أنشر": 1,
    "ناشره": 1,
    "انتشر": 1,
    "تناشروا": 1,
    "تنشر": 1,
    "استنشر": 1,
    "التناشير": 1,
    "المنشر": 1,
    "الناشرة": 1,
    "النشير": 1,
    "أنشز": 1,
    "الناشز": 1,
    "الناشزة": 1,
    "النشز": 1,
    "المنشة": 1,
    "المنشوش": 1,
    "النشاش": 1,
    "النش": 1,
    "النشيش": 1,
    "النشيشة": 1,
    "نشص": 1,
    "أنشصه": 1,
    "انتشص": 1,
    "المنشاص": 1,
    "النشاص": 1,
    "النشوص": 1,
    "النشيص": 1,
    "انتشط": 1,
    "تنشط": 1,
    "استنشط": 1,
    "النشط": 1,
    "النشطة": 1,
    "النشوط": 1,
    "النشيطة": 1,
    "أنشع": 1,
    "انتشع": 1,
    "المنشع": 1,
    "النشاعة": 1,
    "النشع": 1,
    "النشوع": 1,
    "نشغ": 1,
    "أنشغ": 1,
    "انتشغ": 1,
    "تنشغ": 1,
    "المنشغة": 1,
    "الناشغة": 1,
    "النشغة": 1,
    "أنشفت": 1,
    "نشفت": 1,
    "انتشف": 1,
    "تنشف": 1,
    "المنشاف": 1,
    "النشاف": 1,
    "النشف": 1,
    "النشفة": 1,
    "أنشق": 1,
    "استنشق": 1,
    "المنشقة": 1,
    "النشق": 1,
    "النشقة": 1,
    "النشاقى": 1,
    "أنشل": 1,
    "انتشل": 1,
    "المنشال": 1,
    "النشيل": 1,
    "نشم": 1,
    "تنشم": 1,
    "المنشم": 1,
    "النشم": 1,
    "نشنشت": 1,
    "تنشنش": 1,
    "النشناش": 1,
    "النشناشة": 1,
    "النشنش": 1,
    "النشنشة": 1,
    "أنشى": 1,
    "انتشى": 1,
    "تنشى": 1,
    "استنشى": 1,
    "النشاء": 1,
    "النشاة": 1,
    "النشوان": 1,
    "النشوي": 1,
    "تناصبوا": 1,
    "تنصب": 1,
    "الأنصوبة": 1,
    "المنصبة": 1,
    "المنصوب": 1,
    "المنصوبة": 1,
    "الناصب": 1,
    "النصبة": 1,
    "النصيبة": 1,
    "انتصت": 1,
    "تنصت": 1,
    "استنصت": 1,
    "النصتة": 1,
    "أنصحه": 1,
    "انتصح": 1,
    "تنصح": 1,
    "استنصحه": 1,
    "المنصح": 1,
    "المنصحة": 1,
    "النصاح": 1,
    "النصوح": 1,
    "النصيح": 1,
    "ناصره": 1,
    "تناصر": 1,
    "تنصر": 1,
    "استنصر": 1,
    "الأنصر": 1,
    "الناصر": 1,
    "الناصور": 1,
    "النصراني": 1,
    "النصرانية": 1,
    "النصور": 1,
    "النصيرة": 1,
    "انتص": 1,
    "تناص": 1,
    "المنصوص": 1,
    "النصة": 1,
    "النصيص": 1,
    "أنصع": 1,
    "ناصعه": 1,
    "الناصع": 1,
    "النصاع": 1,
    "النصع": 1,
    "النصيع": 1,
    "أنصف": 1,
    "ناصفه": 1,
    "انتصف": 1,
    "تناصف": 1,
    "تنصفت": 1,
    "استنصف": 1,
    "الأنصف": 1,
    "المتناصف": 1,
    "الناصف": 1,
    "الناصفة": 1,
    "النصفان": 1,
    "النصفة": 1,
    "النصيف": 1,
    "أنصل": 1,
    "نصله": 1,
    "انتصل": 1,
    "تناصل": 1,
    "استنصل": 1,
    "الأنصولة": 1,
    "المنصال": 1,
    "المنصل": 1,
    "الناصل": 1,
    "النصيل": 1,
    "نصنص": 1,
    "النصناص": 1,
    "نصاه": 1,
    "أنصى": 1,
    "ناصى": 1,
    "انتصى": 1,
    "تناصى": 1,
    "المنتصى": 1,
    "النصية": 1,
    "أنضجه": 1,
    "نضجه": 1,
    "استنضج": 1,
    "المنضاج": 1,
    "النضيج": 1,
    "أنضح": 1,
    "ناضحه": 1,
    "انتضحت": 1,
    "تنضحت": 1,
    "التنضاح": 1,
    "المنضحة": 1,
    "الناضح": 1,
    "النضح": 1,
    "النضحة": 1,
    "النضاحة": 1,
    "النضيح": 1,
    "نضخ": 1,
    "أنضخ": 1,
    "ناضخه": 1,
    "انتضخ": 1,
    "المنضخة": 1,
    "النضخ": 1,
    "النضخة": 1,
    "النضاخ": 1,
    "النضاخة": 1,
    "نضده": 1,
    "تنضدت": 1,
    "النضد": 1,
    "النضيد": 1,
    "النضيدة": 1,
    "أنضر": 1,
    "نضره": 1,
    "استنضر": 1,
    "الأنضر": 1,
    "الناضر": 1,
    "النضار": 1,
    "النضر": 1,
    "النضرة": 1,
    "النضير": 1,
    "تنضض": 1,
    "استنض": 1,
    "النضاضة": 1,
    "النضوض": 1,
    "النضيض": 1,
    "النضيضة": 1,
    "نضفت": 1,
    "أنضف": 1,
    "انتضف": 1,
    "النضف": 1,
    "نضله": 1,
    "أنضل": 1,
    "انتضل": 1,
    "تناضل": 1,
    "تنضل": 1,
    "النضيل": 1,
    "نضنض": 1,
    "النضناض": 1,
    "النضناضة": 1,
    "نضى": 1,
    "أنضى": 1,
    "انتضى": 1,
    "تنضى": 1,
    "النضاوة": 1,
    "النضو": 1,
    "النضي": 1,
    "نطب": 1,
    "المنطب": 1,
    "المنطبة": 1,
    "النواطب": 1,
    "نطحه": 1,
    "ناطحه": 1,
    "تناطح": 1,
    "الناطحة": 1,
    "النطيح": 1,
    "النطيحة": 1,
    "الناطر": 1,
    "الناطور": 1,
    "النطار": 1,
    "تنطس": 1,
    "الناطس": 1,
    "النطاسي": 1,
    "النطس": 1,
    "النطيس": 1,
    "النطسة": 1,
    "الأنط": 1,
    "النطيط": 1,
    "تنطع": 1,
    "النطاعة": 1,
    "النطع والنطع": 1,
    "النطع": 1,
    "أنطفه": 1,
    "تنطف": 1,
    "الناطف": 1,
    "النطافة": 1,
    "النطف": 1,
    "النطوف": 1,
    "أنطقه": 1,
    "ناطقه": 1,
    "نطقه": 1,
    "انتطق": 1,
    "تناطق": 1,
    "تنطق": 1,
    "تمنطق": 1,
    "استنطقه": 1,
    "المستنطق": 1,
    "المنطوق": 1,
    "المنطيق": 1,
    "انتطل": 1,
    "المنطلة": 1,
    "الناطل": 1,
    "النطل": 1,
    "النطلة": 1,
    "النيطل": 1,
    "تنطنط": 1,
    "النطناط": 1,
    "النطنط": 1,
    "أنطى": 1,
    "ناطاه": 1,
    "تناطى": 1,
    "المنطو": 1,
    "النطي": 1,
    "انتظره": 1,
    "تنظره": 1,
    "استنظره": 1,
    "المنظرة": 1,
    "الناظرة": 1,
    "النظور": 1,
    "النظورة": 1,
    "النظيرة": 1,
    "نظفه": 1,
    "تنظف": 1,
    "استنظف": 1,
    "النظيف": 1,
    "تناظمت": 1,
    "الإنظام": 1,
    "الأنظومة": 1,
    "النظيم": 1,
    "النظيمة": 1,
    "أنعب": 1,
    "المنعب": 1,
    "النعب": 1,
    "النعاب": 1,
    "النعابة": 1,
    "النعوب": 1,
    "نعته": 1,
    "أنعت": 1,
    "انتعت": 1,
    "تناعته": 1,
    "تنعته": 1,
    "استنعته": 1,
    "المنعت": 1,
    "المنعوت": 1,
    "النعتة": 1,
    "النعيت": 1,
    "نعث": 1,
    "أنعث": 1,
    "نعثل": 1,
    "النعثل": 1,
    "النعثلة": 1,
    "نعج": 1,
    "أنعج": 1,
    "الناعور": 1,
    "النعار": 1,
    "النعور": 1,
    "أنعس": 1,
    "تناعس": 1,
    "النعسة": 1,
    "النعوس": 1,
    "أنعشه": 1,
    "نعشه": 1,
    "انتعش": 1,
    "المنعوش": 1,
    "بنات نعش": 1,
    "النعيش": 1,
    "نعظل": 1,
    "نع": 1,
    "النعاع": 1,
    "النعف": 1,
    "النعفة": 1,
    "النعاق": 1,
    "أنعلت": 1,
    "انتعل": 1,
    "تنعل": 1,
    "المنعل والمنعلة": 1,
    "الناعل": 1,
    "النعالة": 1,
    "النعلة": 1,
    "تناعم": 1,
    "تنعم": 1,
    "الإنعام": 1,
    "الإنعامة": 1,
    "المتناعم": 1,
    "المنعام": 1,
    "المنعم": 1,
    "الناعمة": 1,
    "النعامى": 1,
    "النعمى": 1,
    "تنعنع": 1,
    "النعنع": 1,
    "النعنعة": 1,
    "نعا": 1,
    "النعو": 1,
    "أنعى": 1,
    "تناعى": 1,
    "استنعى": 1,
    "المنعى والمنعاة": 1,
    "الناعي": 1,
    "نغب": 1,
    "النغبة": 1,
    "نغت": 1,
    "نغرت": 1,
    "أنغرت": 1,
    "نغر": 1,
    "تناغر": 1,
    "تنغر": 1,
    "المنغار": 1,
    "النغر": 1,
    "النغار": 1,
    "النغاز": 1,
    "انتغش": 1,
    "تنغش": 1,
    "النغاش": 1,
    "النغاشة": 1,
    "أنغص": 1,
    "تناغصت": 1,
    "تنغصت": 1,
    "نغض": 1,
    "أنغض": 1,
    "تنغض": 1,
    "الناغض": 1,
    "النغض": 1,
    "النغاض": 1,
    "نغف": 1,
    "النغف": 1,
    "نغق": 1,
    "أنغل": 1,
    "النغل": 1,
    "النغيل": 1,
    "ناغمه": 1,
    "تنغم": 1,
    "النغام": 1,
    "النغوم": 1,
    "نغنغ": 1,
    "النغنغ": 1,
    "النغنغة": 1,
    "ناغى": 1,
    "الناغية": 1,
    "النغية": 1,
    "النفأ": 1,
    "نفت": 1,
    "تنافت": 1,
    "النفثة": 1,
    "أنفج": 1,
    "انتفج وتنفج": 1,
    "استنفج": 1,
    "المنفج والمنفجة": 1,
    "النافج": 1,
    "النافجة": 1,
    "النفج": 1,
    "النفاج": 1,
    "النفاجة": 1,
    "النفيج": 1,
    "النفيجة": 1,
    "نفحت": 1,
    "انتفح": 1,
    "الإنفحة": 1,
    "المنفح": 1,
    "النفح": 1,
    "النفحة": 1,
    "النفوح": 1,
    "النفيح والنفيح": 1,
    "المنفخ": 1,
    "المنفوخ": 1,
    "النفخاء": 1,
    "النفاخ": 1,
    "النفاخة": 1,
    "النفيخ": 1,
    "أنفد": 1,
    "نافده": 1,
    "انتفد": 1,
    "تنافد": 1,
    "المنافد": 1,
    "المنتفد": 1,
    "النفاد": 1,
    "أنفذ": 1,
    "تنافذ": 1,
    "المنتفذ": 1,
    "النافذ": 1,
    "النفذ": 1,
    "النفذة": 1,
    "النفيذ": 1,
    "أنفر": 1,
    "نافره": 1,
    "استنفرت": 1,
    "النافر": 1,
    "النافرة": 1,
    "النفار": 1,
    "النفارة": 1,
    "النفر": 1,
    "النفرة": 1,
    "النفرور": 1,
    "النفير": 1,
    "نفز": 1,
    "نفزت": 1,
    "تنافزوا": 1,
    "النفازى": 1,
    "النفيز": 1,
    "نفست": 1,
    "المتنفس": 1,
    "المنفوس": 1,
    "النافس": 1,
    "النفيس": 1,
    "أنفش": 1,
    "انتفش": 1,
    "المتنفش": 1,
    "النفاش": 1,
    "نفص": 1,
    "أنفص": 1,
    "المنفاص": 1,
    "النفاص": 1,
    "النفصة": 1,
    "أنفض": 1,
    "استنفض": 1,
    "الأنفوضة": 1,
    "المنفاض": 1,
    "المنفض": 1,
    "المنفوض": 1,
    "النافض": 1,
    "النفاض": 1,
    "النفض": 1,
    "النفضاء": 1,
    "النفضة": 1,
    "النفضى": 1,
    "النفوض": 1,
    "النفيضة": 1,
    "نفطت": 1,
    "أنفط": 1,
    "تنفطت": 1,
    "النافطة": 1,
    "النفاطة": 1,
    "النفاط": 1,
    "النفطان": 1,
    "النفطة": 1,
    "النفيط": 1,
    "أنفع": 1,
    "انتفع": 1,
    "استنفع": 1,
    "النافع": 1,
    "النفاع": 1,
    "النفعة": 1,
    "النفيف": 1,
    "استنفق": 1,
    "المنفاق": 1,
    "المنفقة": 1,
    "النافقاء": 1,
    "النيفق": 1,
    "أنفل": 1,
    "انتفل": 1,
    "تنفل": 1,
    "النافلة": 1,
    "النوفل": 1,
    "النفناف": 1,
    "النفنف": 1,
    "نفه": 1,
    "نفهت": 1,
    "أنفه": 1,
    "استنفه": 1,
    "نفاه": 1,
    "نافاه": 1,
    "انتفى": 1,
    "النفيان": 1,
    "النفية": 1,
    "تنقبت": 1,
    "المنقبة": 1,
    "الناقبة": 1,
    "النقبة": 1,
    "النقيب": 1,
    "النقيبة": 1,
    "نقث": 1,
    "انتقث": 1,
    "تنقث": 1,
    "نقاث": 1,
    "النقث": 1,
    "ناقحه": 1,
    "انتقح": 1,
    "تنقح": 1,
    "النقح": 1,
    "نقخ": 1,
    "انتقخ": 1,
    "الأنقخ": 1,
    "النقاخ": 1,
    "النقخة": 1,
    "أنقد": 1,
    "ناقده": 1,
    "تناقد وتنقد": 1,
    "الأنقد": 1,
    "الإنقدان": 1,
    "المنقاد": 1,
    "المنقدة": 1,
    "الناقد الفني": 1,
    "النقدة": 1,
    "أنقذه": 1,
    "نقذه": 1,
    "تنقذه": 1,
    "استنقذه": 1,
    "الأنقذ": 1,
    "النقذ": 1,
    "النقيذ": 1,
    "النقيذة": 1,
    "أنقر": 1,
    "ناقره": 1,
    "انتقر": 1,
    "تنقر": 1,
    "الأنقور": 1,
    "المنقر": 1,
    "الناقر": 1,
    "الناقرة": 1,
    "النقارة": 1,
    "النقرى": 1,
    "النقار": 1,
    "النقيرة": 1,
    "نقرد": 1,
    "المنقرد": 1,
    "النقريس": 1,
    "نقرش": 1,
    "النقرشة": 1,
    "أنقز": 1,
    "نقزه": 1,
    "انتقزت": 1,
    "المنقوز": 1,
    "الناقز": 1,
    "الناقزة": 1,
    "النقاز": 1,
    "النقز": 1,
    "نقس": 1,
    "ناقسه": 1,
    "الناقوس": 1,
    "النقاسة": 1,
    "النقس": 1,
    "أنقش": 1,
    "ناقشه": 1,
    "نقشه": 1,
    "انتقش": 1,
    "المنقاش": 1,
    "المنقوشة": 1,
    "النقاشة": 1,
    "النقيش": 1,
    "استنقص": 1,
    "المنقصة": 1,
    "النقيصة": 1,
    "انتقض": 1,
    "تنقض": 1,
    "المتناقضان": 1,
    "النقاضة": 1,
    "النقيضة": 1,
    "تنقطت": 1,
    "أنقع": 1,
    "انتقع": 1,
    "استنقع": 1,
    "الأنقوعة": 1,
    "المنقع": 1,
    "المنقعة": 1,
    "النقاع": 1,
    "النقاعة": 1,
    "النقعاء": 1,
    "النقوع": 1,
    "النقيع": 1,
    "النقيعة": 1,
    "أنقف": 1,
    "ناقفه": 1,
    "انتقف": 1,
    "الأنقوفة": 1,
    "المنقاف": 1,
    "المنقف": 1,
    "المنقوف": 1,
    "النقاف": 1,
    "النقف": 1,
    "النقفة": 1,
    "النقيف": 1,
    "نقت": 1,
    "النقوق": 1,
    "أنقل": 1,
    "المنقل": 1,
    "النقيل": 1,
    "النقيلة": 1,
    "النقم": 1,
    "النقنق": 1,
    "النقنيق": 1,
    "أنقهه": 1,
    "انتقه": 1,
    "استنقه": 1,
    "نقا": 1,
    "أنقى": 1,
    "نقاه": 1,
    "انتقى": 1,
    "تنقى": 1,
    "المنقى": 1,
    "المنقية": 1,
    "النقا": 1,
    "النقاواة": 1,
    "النقاية": 1,
    "النقو": 1,
    "النقوة": 1,
    "النقى": 1,
    "النقية": 1,
    "النقي": 1,
    "نكأ": 1,
    "انتكأ": 1,
    "نكبت": 1,
    "نكب": 1,
    "انتكب": 1,
    "تنكب": 1,
    "الأنكب": 1,
    "المنكب": 1,
    "النكباء": 1,
    "المناكب": 1,
    "النكب": 1,
    "النكبة": 1,
    "النكيب": 1,
    "الينكوب": 1,
    "انتكت": 1,
    "النكيت": 1,
    "ناكثه": 1,
    "انتكث": 1,
    "تناكث": 1,
    "النكاث": 1,
    "النكاثة": 1,
    "النكث": 1,
    "النكيث": 1,
    "النكيثة": 1,
    "نكحت": 1,
    "أنكح": 1,
    "تناكح": 1,
    "استنكح": 1,
    "الناكح": 1,
    "النكح": 1,
    "النكحة": 1,
    "أنكد": 1,
    "ناكده": 1,
    "نكده": 1,
    "تناكد": 1,
    "تنكد": 1,
    "المنكد": 1,
    "المنكود": 1,
    "الناكد": 1,
    "النكد": 1,
    "ناكره": 1,
    "تناكر": 1,
    "استنكر": 1,
    "الأنكر": 1,
    "المنكور": 1,
    "النكر": 1,
    "النكراء": 1,
    "النكرة": 1,
    "نكزت": 1,
    "أنكز": 1,
    "المنكزة": 1,
    "النكز": 1,
    "انتكس": 1,
    "المنكس": 1,
    "المنكوس": 1,
    "الناكس": 1,
    "النكس": 1,
    "انتكش": 1,
    "المنكاش": 1,
    "المنكش": 1,
    "المنكوش": 1,
    "النكاش": 1,
    "المنكص": 1,
    "نكظ": 1,
    "أنكظه": 1,
    "تنكظ": 1,
    "المنكظة": 1,
    "نكع": 1,
    "أنكع": 1,
    "نكعه": 1,
    "المنكع": 1,
    "النكع": 1,
    "النكعة": 1,
    "النكوع": 1,
    "أنكفه": 1,
    "ناكفه": 1,
    "انتكف": 1,
    "تناكف": 1,
    "استنكف": 1,
    "المنكوف": 1,
    "النكف": 1,
    "النكفة": 1,
    "أنكله": 1,
    "المنكل": 1,
    "الناكل": 1,
    "النكال": 1,
    "النكلة": 1,
    "نكهت": 1,
    "نكي": 1,
    "النلنل": 1,
    "أنمر": 1,
    "تنمر": 1,
    "الأنمر": 1,
    "المنمر": 1,
    "النامرة": 1,
    "النامور": 1,
    "النمير": 1,
    "النمرق": 1,
    "النمرقة": 1,
    "أنمس": 1,
    "انمس": 1,
    "تنمس": 1,
    "الأنمس": 1,
    "الناموسة": 1,
    "الناموسية": 1,
    "النمسة": 1,
    "أنمش": 1,
    "نمص": 1,
    "أنمص": 1,
    "انتمصت": 1,
    "تنمصت": 1,
    "الأنمص": 1,
    "المنماص": 1,
    "النامصة": 1,
    "النماص": 1,
    "النمص": 1,
    "النميص": 1,
    "أنمط": 1,
    "نمطه": 1,
    "الأنمط": 1,
    "نمغ": 1,
    "المنمغ": 1,
    "النمغة": 1,
    "أنمقت": 1,
    "المنمق": 1,
    "النمق": 1,
    "النمقة": 1,
    "النميق": 1,
    "تنمل": 1,
    "الأنملة": 1,
    "المؤنمل": 1,
    "المنمل": 1,
    "المنملة": 1,
    "الناملة": 1,
    "النملية": 1,
    "النمال": 1,
    "النميلة": 1,
    "النامة": 1,
    "النمم": 1,
    "النمة": 1,
    "النمي": 1,
    "النميم": 1,
    "نمنمت": 1,
    "المنمنم": 1,
    "النمنم": 1,
    "النمنمة": 1,
    "أنمى": 1,
    "انتمى": 1,
    "تنمى": 1,
    "الأنمي": 1,
    "النامي": 1,
    "النماة": 1,
    "النموة": 1,
    "النن": 1,
    "نهأ": 1,
    "نهئ": 1,
    "أنهأ": 1,
    "الناهي": 1,
    "النهيء": 1,
    "نهب": 1,
    "أنهب": 1,
    "انتهب": 1,
    "تناهب": 1,
    "المنهب": 1,
    "المنهوب": 1,
    "النهبة": 1,
    "النهبى": 1,
    "النهبرة": 1,
    "النهبور": 1,
    "نهبل": 1,
    "النهبل": 1,
    "النهبلة": 1,
    "نهت": 1,
    "المنهت والمنهت": 1,
    "الناهت": 1,
    "النهات": 1,
    "نهتر": 1,
    "نهج": 1,
    "أنهج": 1,
    "انتهج": 1,
    "استنهج": 1,
    "المنهاج": 1,
    "الناهج": 1,
    "النهج": 1,
    "النهيج": 1,
    "نهد": 1,
    "أنهد": 1,
    "تناهد": 1,
    "تنهد": 1,
    "الناهد": 1,
    "النهاد": 1,
    "النهداء": 1,
    "النهدان": 1,
    "النهد": 1,
    "النهيد": 1,
    "النهيدة": 1,
    "أنهر": 1,
    "انتهر": 1,
    "استنهر": 1,
    "المنهر": 1,
    "المنهرة": 1,
    "الناهر": 1,
    "الناهور": 1,
    "النهرة": 1,
    "نهز": 1,
    "أنهزه": 1,
    "انتهز": 1,
    "تناهز": 1,
    "المناهزة": 1,
    "الناهز": 1,
    "النهاز": 1,
    "النهز": 1,
    "النهزة": 1,
    "النهوز": 1,
    "نهس": 1,
    "انتهس": 1,
    "المنهس": 1,
    "المنهوس": 1,
    "النهس": 1,
    "النهاس": 1,
    "النهوس": 1,
    "النهيس": 1,
    "نهسر": 1,
    "النهسر": 1,
    "نهش": 1,
    "نهشت": 1,
    "انتهشت": 1,
    "المنتهشة": 1,
    "المنهوش": 1,
    "النهاوش": 1,
    "النهش": 1,
    "نهشل": 1,
    "النهشل": 1,
    "النهشلة": 1,
    "أنهض": 1,
    "تناهض": 1,
    "استنهض": 1,
    "الناهضة": 1,
    "النهاض": 1,
    "النهض": 1,
    "نهطه": 1,
    "نهف": 1,
    "تناهقت": 1,
    "الناهق": 1,
    "الناعقة": 1,
    "النهق": 1,
    "نهك": 1,
    "أنهكه": 1,
    "انتهك": 1,
    "المنهكة": 1,
    "الناهك": 1,
    "النهك": 1,
    "النهكة": 1,
    "النهوك": 1,
    "النهيك": 1,
    "أنهل": 1,
    "المنهال": 1,
    "المنهل": 1,
    "الناهلة": 1,
    "النهل": 1,
    "النهلان": 1,
    "النهلة": 1,
    "المنهام": 1,
    "المنهمة": 1,
    "المنهوم": 1,
    "النهام": 1,
    "النهامي": 1,
    "النهمة": 1,
    "نهنه": 1,
    "النهنه": 1,
    "نهو": 1,
    "نهى": 1,
    "نهي": 1,
    "تناهى": 1,
    "استنهى": 1,
    "التنهاء": 1,
    "التنهاة": 1,
    "التنهية": 1,
    "المنهاة": 1,
    "الناهية": 1,
    "النهاء": 1,
    "النهاة": 1,
    "النهو": 1,
    "النهية": 1,
    "أناءت": 1,
    "أنوأت": 1,
    "استناء": 1,
    "الأنوأ": 1,
    "النوء": 1,
    "ناوبه": 1,
    "نوبه": 1,
    "انتابه": 1,
    "استنابه": 1,
    "المناب": 1,
    "المنتاب": 1,
    "المنيب": 1,
    "النائبة": 1,
    "النوتة": 1,
    "النوتي": 1,
    "ناجٍ": 1,
    "النوجة": 1,
    "ناوحه": 1,
    "تناوح": 1,
    "تنوح": 1,
    "استناح": 1,
    "المناحة": 1,
    "أناخ": 1,
    "تنوخ": 1,
    "استناخ": 1,
    "النائخة": 1,
    "النوخة": 1,
    "تنود": 1,
    "أنار": 1,
    "استنار": 1,
    "الأنور": 1,
    "المنار": 1,
    "المنارة": 1,
    "المنور": 1,
    "النائر": 1,
    "النائرة": 1,
    "النوار": 1,
    "النورانية": 1,
    "النورة": 1,
    "النورج": 1,
    "النوروز أو النيروز": 1,
    "أناسه": 1,
    "تنوس": 1,
    "الناووس": 1,
    "النواس": 1,
    "النواسي": 1,
    "ناوش": 1,
    "انتاش": 1,
    "تناوش": 1,
    "تنوش": 1,
    "أناص": 1,
    "ناوصه": 1,
    "انتاصب": 1,
    "استناص": 1,
    "المناص": 1,
    "النوص": 1,
    "النويص": 1,
    "أناض": 1,
    "نوض": 1,
    "النوض": 1,
    "أناط": 1,
    "انتاطت": 1,
    "التنواط": 1,
    "المناط": 1,
    "المنوط": 1,
    "النائط": 1,
    "النائطة": 1,
    "النوطة": 1,
    "النياط": 1,
    "النيط": 1,
    "ناع": 1,
    "استناع": 1,
    "المنواع": 1,
    "النائع": 1,
    "النوعة": 1,
    "أناف": 1,
    "المناف": 1,
    "المنيف": 1,
    "المنيفة": 1,
    "الناف": 1,
    "النوف": 1,
    "النياف": 1,
    "النيف": 1,
    "النيفة": 1,
    "ناق": 1,
    "انتاق": 1,
    "النائق": 1,
    "النوقة": 1,
    "النواق": 1,
    "النيقة": 1,
    "النيق": 1,
    "نوك": 1,
    "أنوكه": 1,
    "استنوك": 1,
    "الأنوك": 1,
    "تنول": 1,
    "المتناول": 1,
    "النال": 1,
    "النوال": 1,
    "النوالة": 1,
    "النول": 1,
    "النولة": 1,
    "أنامه": 1,
    "ناومه": 1,
    "تناوم": 1,
    "تنوم": 1,
    "استنام": 1,
    "التنويم المغنطيسي": 1,
    "النؤوم": 1,
    "النؤام": 1,
    "نومان": 1,
    "النومة": 1,
    "النويم": 1,
    "النيم": 1,
    "النيمة": 1,
    "التنوين": 1,
    "النونة": 1,
    "النوه": 1,
    "النوهة": 1,
    "أنوى": 1,
    "انتوى": 1,
    "تنواه": 1,
    "استنوى": 1,
    "المستنوى": 1,
    "المنوي": 1,
    "الناوي": 1,
    "النواء": 1,
    "الني": 1,
    "أناءه": 1,
    "أنيأه": 1,
    "نيأ": 1,
    "النيء": 1,
    "نيبت": 1,
    "تنيب": 1,
    "الأنيب": 1,
    "النيوب": 1,
    "نيح": 1,
    "النيحة": 1,
    "النيح": 1,
    "النيدل": 1,
    "النيدلان والنيدلان": 1,
    "نايره": 1,
    "تنايرا": 1,
    "المنايرة": 1,
    "النيرة": 1,
    "نيرب": 1,
    "النيرب": 1,
    "نيرج": 1,
    "النيرج": 1,
    "النيرنج": 1,
    "النيروز": 1,
    "نيسب": 1,
    "النيسب": 1,
    "انتاط": 1,
    "تنايلا": 1,
    "استنال": 1,
    "النائل": 1,
    "النالة": 1,
    "النيلة": 1,
    "النيلين": 1,
    "النيلوفر والنينوفر": 1,
    "النيمبرشت": 1,
    "نيون": 1,
    "النائه": 1,
    "الناهة": 1,
    "الهاء": 1,
    "هأهأ": 1,
    "هاتور": 1,
    "هبت": 1,
    "اهتب": 1,
    "تهبب": 1,
    "أهباب": 1,
    "المهباب": 1,
    "المهب": 1,
    "الهباب": 1,
    "هبايب": 1,
    "المهبوب": 1,
    "الهبت": 1,
    "الهبتة": 1,
    "الهبيت": 1,
    "هبجه": 1,
    "هبج": 1,
    "هوبج": 1,
    "التهبج": 1,
    "المهبج": 1,
    "الهبيج": 1,
    "الهوبجة": 1,
    "هبد": 1,
    "اهتبد": 1,
    "الهبيد": 1,
    "هبذ": 1,
    "هبر": 1,
    "أهبر": 1,
    "اهتبر": 1,
    "هوبرت": 1,
    "الهابر": 1,
    "الهبارية": 1,
    "الهبار": 1,
    "الهبور": 1,
    "الهبر": 1,
    "الهبرة": 1,
    "الهبرية": 1,
    "الهبيرة": 1,
    "الهوبر": 1,
    "هبرج": 1,
    "الهبرج": 1,
    "الهبرزي": 1,
    "تهبرس": 1,
    "الهبرقي": 1,
    "هبرم": 1,
    "هبز": 1,
    "الهبس": 1,
    "هبش": 1,
    "اهتبش": 1,
    "تهبش": 1,
    "الهابش": 1,
    "الهابشة": 1,
    "الهباشة": 1,
    "هبص": 1,
    "اهتبص": 1,
    "انهبص": 1,
    "الهبصى": 1,
    "أهبطه": 1,
    "هبطه": 1,
    "انهبط": 1,
    "تهبط": 1,
    "المهبطة": 1,
    "الهبطة": 1,
    "الهبيط": 1,
    "هبع": 1,
    "أهبع": 1,
    "استهبعه": 1,
    "الهبع": 1,
    "الهبوع": 1,
    "هبغ": 1,
    "الهبيغ": 1,
    "الهبيغة": 1,
    "انهبكت": 1,
    "الهبكة": 1,
    "أهبلت": 1,
    "اهتبل": 1,
    "تهبل": 1,
    "الأهبل": 1,
    "الهبالة": 1,
    "الهبال": 1,
    "الهبل": 1,
    "الهبلي": 1,
    "الهبول": 1,
    "الهبلاع": 1,
    "الهبلع": 1,
    "الهبنك": 1,
    "الهبنكة": 1,
    "هبهب": 1,
    "تهبهب": 1,
    "الهبهب": 1,
    "الهبهبي": 1,
    "هبا": 1,
    "أهبى": 1,
    "هبى": 1,
    "تهبى": 1,
    "الهابي": 1,
    "الهباء": 1,
    "الهباءة": 1,
    "الهبابة": 1,
    "الهبو": 1,
    "الهبوة": 1,
    "هتأه": 1,
    "هتىء": 1,
    "تهتأ": 1,
    "الهتأ": 1,
    "الهتأة": 1,
    "هت": 1,
    "المهت": 1,
    "الهت": 1,
    "الهتات": 1,
    "أهتر": 1,
    "هاتره": 1,
    "تهاترا": 1,
    "تهتر": 1,
    "استهتر": 1,
    "التهاتر": 1,
    "التهتار": 1,
    "المهاترة": 1,
    "المهتر": 1,
    "الهتر": 1,
    "هتش": 1,
    "الهتفى": 1,
    "الهتوف": 1,
    "تهتك": 1,
    "الهتك": 1,
    "الهتكة": 1,
    "الهتيكة": 1,
    "هتم": 1,
    "أهتم": 1,
    "هتمه": 1,
    "تهاتما": 1,
    "تهتم": 1,
    "الهتامة": 1,
    "الهتيمة": 1,
    "الهيتم": 1,
    "هتمر": 1,
    "هتمل": 1,
    "الهتملة": 1,
    "هتنت": 1,
    "التهتان": 1,
    "الهتون": 1,
    "هتهت": 1,
    "الهتهات": 1,
    "هتا": 1,
    "هاتى": 1,
    "الهتي": 1,
    "هث": 1,
    "هثم": 1,
    "الهيثم": 1,
    "هثهث": 1,
    "الهثهاث": 1,
    "الهثهثة": 1,
    "هثى": 1,
    "هاثاه": 1,
    "هجأ": 1,
    "هجئ": 1,
    "أهجأ": 1,
    "الهجأ": 1,
    "الهجأة": 1,
    "هجت": 1,
    "هجج": 1,
    "اهتج": 1,
    "استهج": 1,
    "هجاج": 1,
    "الهجاجة": 1,
    "الهج": 1,
    "الهجيج": 1,
    "أهجد": 1,
    "تهجد": 1,
    "التهجد": 1,
    "هجر": 1,
    "أهجر": 1,
    "تهاجر": 1,
    "تهجر": 1,
    "الأهجر": 1,
    "الأهجورة": 1,
    "المهجر": 1,
    "المهجرة": 1,
    "الهاجرة": 1,
    "الهاجري": 1,
    "الهجار": 1,
    "الهجيري": 1,
    "الهجراء": 1,
    "الهجوري": 1,
    "الهجير": 1,
    "الهجيرة": 1,
    "الهويجرة": 1,
    "الهجرس": 1,
    "الهجرع": 1,
    "الهجزع": 1,
    "هجس": 1,
    "هاجسه": 1,
    "تهاجسا": 1,
    "المتهجس": 1,
    "المهجوس": 1,
    "الهجس": 1,
    "الهجيسة": 1,
    "هجش": 1,
    "الهاجشة": 1,
    "الهجشة": 1,
    "أهجعه": 1,
    "التهجاع": 1,
    "المهجع": 1,
    "الهجع": 1,
    "الهجعة": 1,
    "الهجيع": 1,
    "هجف": 1,
    "انهجف": 1,
    "الهجفان": 1,
    "الهجف": 1,
    "هجل": 1,
    "أهجل": 1,
    "هاجل": 1,
    "اهتجل": 1,
    "المهجل": 1,
    "الهاجل": 1,
    "الهجل": 1,
    "الهجول": 1,
    "الهجيل": 1,
    "أهجم": 1,
    "هاجمه": 1,
    "اهتجم": 1,
    "انهجم": 1,
    "تهاجما": 1,
    "تهجم": 1,
    "الاهتجام": 1,
    "الهجام": 1,
    "الهجم": 1,
    "الهجيمة": 1,
    "الهيجمانة": 1,
    "هجنت": 1,
    "أهجن": 1,
    "اهتجنت": 1,
    "تهجنت": 1,
    "استهجنه": 1,
    "الأهيجنة": 1,
    "المهجنة": 1,
    "الهجان": 1,
    "الهجانة": 1,
    "الهجنة": 1,
    "الهجنف": 1,
    "هجهج": 1,
    "الهجاهج": 1,
    "الهجهاج": 1,
    "الهجهج": 1,
    "الهجهجة": 1,
    "هجا": 1,
    "هجو": 1,
    "أهجى": 1,
    "هاجاه": 1,
    "هجى": 1,
    "اهتجى": 1,
    "تهاجيا": 1,
    "تهجى": 1,
    "الأهجوة": 1,
    "الأهجية": 1,
    "هجي": 1,
    "هدأه": 1,
    "المهدأة": 1,
    "الهدء": 1,
    "الهدأة": 1,
    "هدب": 1,
    "اهتدب": 1,
    "تهدب": 1,
    "الأهدب": 1,
    "الهدباء": 1,
    "الهداب": 1,
    "الهيدب": 1,
    "الهيدبى": 1,
    "الهيدبي": 1,
    "الهدبل": 1,
    "هدج": 1,
    "تهدج": 1,
    "استهدج": 1,
    "المستهدج": 1,
    "المهداج": 1,
    "الهدجة": 1,
    "الهداج": 1,
    "الهدوج": 1,
    "الهودج": 1,
    "الهدجدج": 1,
    "هد": 1,
    "هدده": 1,
    "تهاد": 1,
    "تهدده": 1,
    "استهده": 1,
    "الأهد": 1,
    "التهداد": 1,
    "الهاد": 1,
    "الهادة": 1,
    "الهداد": 1,
    "الهدادة": 1,
    "الهد": 1,
    "الهدان": 1,
    "الهدود": 1,
    "الهديد": 1,
    "هدر": 1,
    "أهدر": 1,
    "تهادر": 1,
    "اهدودر": 1,
    "المهدرة": 1,
    "الهادرة": 1,
    "هدغه": 1,
    "انهدغ": 1,
    "المنهدغ": 1,
    "أهدف": 1,
    "استهدف": 1,
    "المهدفة": 1,
    "الهادف": 1,
    "الهادفة": 1,
    "هدك": 1,
    "تهدك": 1,
    "هدل": 1,
    "تهدل": 1,
    "الهدالة": 1,
    "الهديل": 1,
    "الهيدلة": 1,
    "هدمت": 1,
    "أهدم": 1,
    "تهدم": 1,
    "المهدوم": 1,
    "المهدومة": 1,
    "الهدام": 1,
    "الهدمة": 1,
    "الهديم": 1,
    "هدمل": 1,
    "الهدمل": 1,
    "الهدملة": 1,
    "هدن": 1,
    "أهدن": 1,
    "انهدن": 1,
    "تهادن": 1,
    "تهدن": 1,
    "المهدون": 1,
    "الهدن": 1,
    "الهيدان": 1,
    "هدهد": 1,
    "الهدهدة": 1,
    "هادى": 1,
    "تهادى": 1,
    "تهدى": 1,
    "استهدى": 1,
    "المهداء": 1,
    "المهدي": 1,
    "المهيدية": 1,
    "الهادية": 1,
    "الهداء": 1,
    "الهداة": 1,
    "الهديا": 1,
    "هذأت": 1,
    "هذئ": 1,
    "تهذأت": 1,
    "الهذاء": 1,
    "الهذأة": 1,
    "أهذب": 1,
    "تهذب": 1,
    "الهذب": 1,
    "هذ": 1,
    "اهتذ": 1,
    "هذاذيك": 1,
    "الهذ": 1,
    "الهذاذ": 1,
    "الهذوذ": 1,
    "هذر كلامه": 1,
    "أهذر": 1,
    "الهذار": 1,
    "الهذرة والهذرة": 1,
    "الهذريان": 1,
    "الهيذر": 1,
    "هذرم": 1,
    "الهذارم والهذارمة والهذرام": 1,
    "الهذرمي": 1,
    "هذف": 1,
    "الهاذل": 1,
    "الهذلول": 1,
    "هذلم": 1,
    "هذم": 1,
    "المهذم": 1,
    "الهذام": 1,
    "الهذامة والهذمة": 1,
    "الهذوم": 1,
    "الهيذام": 1,
    "الهيذم": 1,
    "الهذاهذ": 1,
    "الهذهاذ": 1,
    "هذاه": 1,
    "هاذاه": 1,
    "تهاذى": 1,
    "هرأ": 1,
    "هرئ": 1,
    "أهرأ": 1,
    "تهرأ": 1,
    "الهرأ": 1,
    "الهراءة": 1,
    "الهريء": 1,
    "الهريئة": 1,
    "أهرب": 1,
    "هربج": 1,
    "هربذ": 1,
    "الهربذ": 1,
    "الهربذي": 1,
    "الهربع": 1,
    "هرت": 1,
    "أهرت": 1,
    "تهارت": 1,
    "الهريت": 1,
    "الهرثمة": 1,
    "أهرج": 1,
    "انهرج": 1,
    "استهرج": 1,
    "الهرجة": 1,
    "الهراجة": 1,
    "هرجل": 1,
    "الهرجل": 1,
    "الهرجول": 1,
    "هرد": 1,
    "الهرد": 1,
    "الهردي": 1,
    "هردب": 1,
    "الهردب": 1,
    "الهردبة": 1,
    "هر": 1,
    "أهر": 1,
    "هاره": 1,
    "الهرار": 1,
    "الهرور": 1,
    "الهرير": 1,
    "هروز": 1,
    "تهروز": 1,
    "المهراس": 1,
    "الهراس": 1,
    "الهراسة": 1,
    "الهرس": 1,
    "هارش": 1,
    "اهترشت": 1,
    "تهارشت": 1,
    "تهرش": 1,
    "مهارش": 1,
    "الهرش": 1,
    "هرشف": 1,
    "تهرشف": 1,
    "الهرشفة": 1,
    "الهرشم": 1,
    "الهرشن": 1,
    "هرص": 1,
    "الهرص": 1,
    "الهريصة": 1,
    "هرض": 1,
    "هرط": 1,
    "تهارط": 1,
    "الهرط": 1,
    "الهرطة": 1,
    "الهيرط": 1,
    "الهرطال": 1,
    "الهرطمان": 1,
    "هرع": 1,
    "أهرع": 1,
    "اهترع": 1,
    "تهرع": 1,
    "المهرع": 1,
    "المهروع": 1,
    "الهراع": 1,
    "الهرع": 1,
    "الهرياع": 1,
    "الهريعة": 1,
    "الهيرع": 1,
    "الهيرعة": 1,
    "هرف": 1,
    "أهرف": 1,
    "الهرف": 1,
    "هرق": 1,
    "أهرق": 1,
    "هراق": 1,
    "تهارق": 1,
    "اهرورق": 1,
    "المهرقان": 1,
    "الهرق": 1,
    "الهرقل": 1,
    "هرقل وهرقل": 1,
    "هركل": 1,
    "الهراكل": 1,
    "الهراكلة": 1,
    "الهركلة والهركلة": 1,
    "الهركولة": 1,
    "الهرل": 1,
    "هرمت": 1,
    "أهرم": 1,
    "تهارم": 1,
    "الهرمى": 1,
    "الهرمان": 1,
    "الهرمة": 1,
    "الهروم": 1,
    "هرمز": 1,
    "هرمس": 1,
    "الهرامس": 1,
    "الهرماس": 1,
    "الهرموس": 1,
    "الهرميس": 1,
    "الهرميسة": 1,
    "هرمط": 1,
    "اهرمع": 1,
    "هرملت": 1,
    "الهرمل": 1,
    "الهرمول": 1,
    "هرنف": 1,
    "هرهر": 1,
    "تهرهرت": 1,
    "الهراهر": 1,
    "الهرهار": 1,
    "الهرهور": 1,
    "هراه": 1,
    "هرى": 1,
    "تهراه": 1,
    "الهرى": 1,
    "هزأ": 1,
    "هزئ": 1,
    "أهزأ": 1,
    "تهزأ": 1,
    "الهازئة": 1,
    "الهزأة": 1,
    "هزبره": 1,
    "الهزبر": 1,
    "هزج": 1,
    "أهزج": 1,
    "تهزج": 1,
    "هزر": 1,
    "المهزر": 1,
    "الهزار": 1,
    "الهزر": 1,
    "الهزرة": 1,
    "الهزور": 1,
    "هزرف": 1,
    "الهزارف والهزراف": 1,
    "الهزرفي": 1,
    "الهزروف": 1,
    "هزرق": 1,
    "الهزارق": 1,
    "هزز": 1,
    "تهزز": 1,
    "الهزائز": 1,
    "اهتزع": 1,
    "انهزع": 1,
    "تهزع": 1,
    "الأهزع": 1,
    "المهزع": 1,
    "الهزاع": 1,
    "الهزع": 1,
    "الهزيع": 1,
    "الهيزعة": 1,
    "هزفت": 1,
    "الهزف": 1,
    "هزق": 1,
    "أهزق": 1,
    "المهزاق": 1,
    "الهزق": 1,
    "هزل": 1,
    "أهزل": 1,
    "المهزول": 1,
    "الهزالة": 1,
    "الهزيل": 1,
    "الهزيلي": 1,
    "هزلج": 1,
    "الهزلاج": 1,
    "اهتزم": 1,
    "تهزم": 1,
    "استهزم": 1,
    "المهزام": 1,
    "الهازمة": 1,
    "الهزم": 1,
    "الهزمة": 1,
    "الهزوم": 1,
    "الهزيم": 1,
    "الهزيمي": 1,
    "الهيزم": 1,
    "الهزمجة": 1,
    "تهزهز": 1,
    "الهزاهز": 1,
    "الهزهاز": 1,
    "الهزهز": 1,
    "هزا": 1,
    "هس": 1,
    "الهسيس": 1,
    "هسهس": 1,
    "تهسهس": 1,
    "الهساهس": 1,
    "الهسهاس": 1,
    "الأهساء": 1,
    "هشر": 1,
    "الهشر": 1,
    "الهشرة": 1,
    "الهشور": 1,
    "الهيشر": 1,
    "اهتش": 1,
    "استهش": 1,
    "الهشاش": 1,
    "الهشوش": 1,
    "الهشوشة": 1,
    "الهشيش": 1,
    "هشلت": 1,
    "اهتشل": 1,
    "الهشيلة": 1,
    "الهيشلة": 1,
    "انهشم": 1,
    "اهتشم": 1,
    "تهشم": 1,
    "المهشام": 1,
    "الهاشم": 1,
    "الهاشمة": 1,
    "الهشام": 1,
    "الهشم": 1,
    "الهشمة": 1,
    "الهشوم": 1,
    "الهشيم": 1,
    "الهشيمة": 1,
    "الهيشوم": 1,
    "الهشنق": 1,
    "هشهش": 1,
    "تهشهشت": 1,
    "الهشهاش": 1,
    "هاشاه": 1,
    "هصر": 1,
    "اهتصر": 1,
    "تهصرت": 1,
    "المهتصر": 1,
    "الهصر": 1,
    "الهصرة": 1,
    "الهصور": 1,
    "هصت": 1,
    "هصص": 1,
    "الهاصة": 1,
    "الهص": 1,
    "هصم": 1,
    "المهصم": 1,
    "الهيصم": 1,
    "الهصاهص": 1,
    "الهصهاص": 1,
    "هصا": 1,
    "هضب": 1,
    "أهضب": 1,
    "اهتضب": 1,
    "الأهضوبة": 1,
    "الهضب": 1,
    "الهضيب": 1,
    "هضج": 1,
    "الهضيج": 1,
    "هضت": 1,
    "هضض": 1,
    "اهتض": 1,
    "الهضاء": 1,
    "الهضاض": 1,
    "الهضض": 1,
    "هضل": 1,
    "أهضلت": 1,
    "الهضال": 1,
    "الهضل": 1,
    "الهضلاء": 1,
    "الهيضل": 1,
    "الهيضلة": 1,
    "اهتضم": 1,
    "انهضمت": 1,
    "تهضمت": 1,
    "الأهضم": 1,
    "المهضمة": 1,
    "المهضومة": 1,
    "الهاضوم": 1,
    "الهضام": 1,
    "الهضوم": 1,
    "الهضيم": 1,
    "الهضيمة": 1,
    "هاضاه": 1,
    "الهضاة": 1,
    "هطر": 1,
    "تهطرت": 1,
    "تهطرس": 1,
    "الهطط": 1,
    "هطع": 1,
    "أهطع": 1,
    "استهطع": 1,
    "المهطع": 1,
    "الهيطع": 1,
    "هطف": 1,
    "الهطف": 1,
    "هطل": 1,
    "تهاطل": 1,
    "تهطل": 1,
    "الهاطل": 1,
    "الهطال": 1,
    "الهطل": 1,
    "الهطلى": 1,
    "الهطلاء": 1,
    "الهياطلة": 1,
    "الهيطل": 1,
    "الهيطلة": 1,
    "الهيطلية": 1,
    "هطلس": 1,
    "تهطلس": 1,
    "الهطلس": 1,
    "الهطلع": 1,
    "هطهط": 1,
    "الهطاهط": 1,
    "هطا": 1,
    "الهطى": 1,
    "هيعر": 1,
    "تهيعرت": 1,
    "الهيعرة": 1,
    "هفت": 1,
    "انهفت": 1,
    "تهافت": 1,
    "الهفات": 1,
    "الهفت": 1,
    "الهفتق": 1,
    "هف": 1,
    "المهفف": 1,
    "الهفاف": 1,
    "الهفان": 1,
    "اليهفوف": 1,
    "هفك": 1,
    "تهفك": 1,
    "المهفك": 1,
    "الهيفك": 1,
    "هفهف": 1,
    "المهفهف": 1,
    "الهفهاف": 1,
    "هفا": 1,
    "هافاه": 1,
    "الهافية": 1,
    "الهفا": 1,
    "الهفاء": 1,
    "الهفاة": 1,
    "الهقب": 1,
    "هقع": 1,
    "اهتقع": 1,
    "انهقع": 1,
    "تهقع": 1,
    "الهقاع": 1,
    "الهقع": 1,
    "الهقعة": 1,
    "الهيقعة": 1,
    "هقف": 1,
    "الهقف": 1,
    "هق": 1,
    "تهقل": 1,
    "الهاقل": 1,
    "الهقل": 1,
    "الهقلس": 1,
    "هقم": 1,
    "تهقم": 1,
    "الهقم": 1,
    "الهيقم": 1,
    "الهيقماني": 1,
    "هقهق": 1,
    "الهقهاق": 1,
    "هقى": 1,
    "أهقى": 1,
    "هكب": 1,
    "هكد": 1,
    "هكر": 1,
    "تهكر": 1,
    "هكع": 1,
    "اهتكع": 1,
    "الهكاع": 1,
    "الهكعة": 1,
    "هكت": 1,
    "انهكت": 1,
    "تهكك": 1,
    "المهكوك": 1,
    "الهك": 1,
    "الهكاك": 1,
    "الهكوك": 1,
    "الهكيك": 1,
    "هكلت": 1,
    "تهاكل": 1,
    "هكم": 1,
    "تهكم": 1,
    "استهكم": 1,
    "الأهكومة": 1,
    "الهكم": 1,
    "تهكن": 1,
    "هاكاه": 1,
    "هلب": 1,
    "أهلبت": 1,
    "اهتلب": 1,
    "الهالب": 1,
    "الهلابة": 1,
    "الهلب": 1,
    "الهلبة": 1,
    "الهلاب": 1,
    "الهلوب": 1,
    "الهلابج": 1,
    "هلت": 1,
    "انهلت": 1,
    "الهلتات": 1,
    "الهلاث": 1,
    "الهلائث": 1,
    "الهلثى": 1,
    "هلج": 1,
    "أهلج": 1,
    "الهالج": 1,
    "الهلج": 1,
    "هلد": 1,
    "هلسه": 1,
    "هلس": 1,
    "أهلس": 1,
    "هالسه": 1,
    "اهتلس": 1,
    "الهالس": 1,
    "الهلاس": 1,
    "الهلس": 1,
    "الهالط": 1,
    "هلع": 1,
    "الهالع": 1,
    "الهلاع": 1,
    "الهلعة": 1,
    "الهلواع": 1,
    "الهولع": 1,
    "الهلوف": 1,
    "الهلوفة": 1,
    "هلقم": 1,
    "الهلقام": 1,
    "أهلكه": 1,
    "هلكه": 1,
    "اهتلك": 1,
    "تهالك": 1,
    "تهلكت": 1,
    "المهلكة": 1,
    "الهالكة": 1,
    "الهالكي": 1,
    "الهالوك": 1,
    "الهلك": 1,
    "الهلكة": 1,
    "الهلكون": 1,
    "الهلوك": 1,
    "الهيلكون": 1,
    "الهلكس": 1,
    "اهتل": 1,
    "انهل": 1,
    "تهلل": 1,
    "استهل": 1,
    "الاستهلال": 1,
    "الأهاليل": 1,
    "الهل": 1,
    "الهلل": 1,
    "الهلى": 1,
    "الهلة": 1,
    "الهليلة": 1,
    "اهتلم": 1,
    "الهلمان": 1,
    "الهيلمان": 1,
    "تهلهل": 1,
    "الهلاهل": 1,
    "الهلهال": 1,
    "الهلهل": 1,
    "المهلهل": 1,
    "المهلهلة": 1,
    "الهليون": 1,
    "همأ": 1,
    "أهمأ": 1,
    "تهمأ": 1,
    "انهمأ": 1,
    "الهمء": 1,
    "أهمت": 1,
    "همجت": 1,
    "أهمج": 1,
    "اهتمج": 1,
    "الهامج": 1,
    "الهميج": 1,
    "أهمد": 1,
    "همده": 1,
    "الهامد": 1,
    "الهامدة": 1,
    "الهميد": 1,
    "همذ": 1,
    "الهماذي": 1,
    "الهمذان": 1,
    "الهمذاني": 1,
    "همر": 1,
    "انهمر": 1,
    "اهتمر": 1,
    "المهمار": 1,
    "المهمر": 1,
    "الهامر": 1,
    "الهمر": 1,
    "الهمرى": 1,
    "الهمرة": 1,
    "الهمار": 1,
    "الهمير": 1,
    "همرج": 1,
    "الهمرج": 1,
    "الهمرجان": 1,
    "همرش": 1,
    "تهمرش": 1,
    "الهمرشة": 1,
    "الهمرش": 1,
    "همزه": 1,
    "المهمز": 1,
    "المهمزة": 1,
    "الهامز": 1,
    "الهمز": 1,
    "الهمزى": 1,
    "الهماز": 1,
    "الهميز": 1,
    "تهامس": 1,
    "الإهماس": 1,
    "الهامس": 1,
    "الهمس": 1,
    "الهماس": 1,
    "الهموس": 1,
    "الهميس": 1,
    "همش": 1,
    "هامشه": 1,
    "اهتمش": 1,
    "تهامش": 1,
    "تهمش": 1,
    "الهمش": 1,
    "الهمشة": 1,
    "الهمشى": 1,
    "الهميشة": 1,
    "همط": 1,
    "اهتمط": 1,
    "همعت": 1,
    "أهمع": 1,
    "اهتمع": 1,
    "تهمع": 1,
    "الهمع": 1,
    "الهموع": 1,
    "همغ": 1,
    "انهمغ": 1,
    "الهميغ": 1,
    "همق": 1,
    "الهمق": 1,
    "همك": 1,
    "انهمك": 1,
    "تهمك": 1,
    "انهملت": 1,
    "الهماليل": 1,
    "الهمل": 1,
    "الهمال": 1,
    "هملجت": 1,
    "المهملج": 1,
    "الهملاج": 1,
    "الهملس": 1,
    "الهملع": 1,
    "هممت": 1,
    "اهتم": 1,
    "انهم": 1,
    "تهمم": 1,
    "استهم": 1,
    "التهميم": 1,
    "الهاموم": 1,
    "الهمام": 1,
    "الهميم": 1,
    "الهماهم": 1,
    "همهام": 1,
    "الهمهام": 1,
    "الهمهامة": 1,
    "الهمهوم": 1,
    "الهمهومة": 1,
    "الهمهيم": 1,
    "الأهماء": 1,
    "الهماء": 1,
    "الهماي": 1,
    "الهميان": 1,
    "الهمايون": 1,
    "الهميسع": 1,
    "هنؤ": 1,
    "أهنأه": 1,
    "اهتنأ": 1,
    "تهنأ": 1,
    "استهنأ": 1,
    "المهنأ": 1,
    "الهانئ": 1,
    "الهنء": 1,
    "الهنيء": 1,
    "هنب": 1,
    "المهنب": 1,
    "الهنباء": 1,
    "الهنبى": 1,
    "الهنبثة": 1,
    "الهنبر": 1,
    "تهنبس": 1,
    "هنبص": 1,
    "الهنبص": 1,
    "هنبع": 1,
    "الهنبع": 1,
    "هنبغ": 1,
    "الهنباغ": 1,
    "الهنبغ": 1,
    "هنبل": 1,
    "تهنج": 1,
    "المهند": 1,
    "الهندواني": 1,
    "هنيدة": 1,
    "الهنيدة": 1,
    "الهندبا": 1,
    "هندز": 1,
    "الهنداز": 1,
    "الهندازة": 1,
    "الهندوز": 1,
    "هندس": 1,
    "الهندس": 1,
    "الهندسي": 1,
    "هندم": 1,
    "هنع": 1,
    "الأهنع": 1,
    "الهناع": 1,
    "الهنعاء": 1,
    "الهنعة": 1,
    "هنغ": 1,
    "هانغ": 1,
    "الهينغ": 1,
    "أهنف": 1,
    "هانف": 1,
    "هنف": 1,
    "تهانف": 1,
    "تهنف": 1,
    "الهناف": 1,
    "الهنوف": 1,
    "هنق": 1,
    "أهنق": 1,
    "هانم": 1,
    "هينم": 1,
    "المهينم": 1,
    "الهانم": 1,
    "الهنم": 1,
    "الهنمة": 1,
    "الهينام": 1,
    "الهينم": 1,
    "الهينمان": 1,
    "الهينوم": 1,
    "أهنه": 1,
    "الهانة": 1,
    "الهننة": 1,
    "الهن": 1,
    "الهناة": 1,
    "الهنة": 1,
    "الهنو": 1,
    "هنيهة وهنية": 1,
    "هه": 1,
    "الهو": 1,
    "هويء": 1,
    "هاوأ": 1,
    "الهوء": 1,
    "الهوب": 1,
    "هوبر": 1,
    "هوت": 1,
    "الهوتة": 1,
    "هوج": 1,
    "أهوجه": 1,
    "تهوج": 1,
    "الهوجاء": 1,
    "هاود": 1,
    "تهود": 1,
    "التهويد": 1,
    "الهوادة": 1,
    "الهود": 1,
    "الهودة": 1,
    "الهودك": 1,
    "الهوذة": 1,
    "هوذة": 1,
    "اليهوذي": 1,
    "اهتور": 1,
    "تهور": 1,
    "الهائر": 1,
    "الهار": 1,
    "الهاري": 1,
    "الهوارة": 1,
    "الهورة": 1,
    "الهير": 1,
    "الهورع": 1,
    "هوز": 1,
    "الهوز": 1,
    "الهوزب": 1,
    "الهوزن": 1,
    "هوست": 1,
    "تهوس": 1,
    "الهواس": 1,
    "الهواسة": 1,
    "الهويس": 1,
    "هاش": 1,
    "هوش": 1,
    "هاوش": 1,
    "تهاوش": 1,
    "تهوش": 1,
    "الهائشة": 1,
    "الهواش": 1,
    "الهواشة": 1,
    "الهوش": 1,
    "الهويشة": 1,
    "هاع": 1,
    "هوع": 1,
    "الهاع": 1,
    "الهواع": 1,
    "الهواعة": 1,
    "الهوع": 1,
    "الهوف": 1,
    "الهوقة": 1,
    "انهاك": 1,
    "تهوك": 1,
    "الهوكة": 1,
    "الهوك": 1,
    "الهواك": 1,
    "الهواكة": 1,
    "هيل": 1,
    "هاول": 1,
    "هول": 1,
    "اهتال": 1,
    "تهول": 1,
    "استهال": 1,
    "التهاويل": 1,
    "الهال": 1,
    "الهولة": 1,
    "الهيلة": 1,
    "الهولع والهولع": 1,
    "هوم": 1,
    "تهوم": 1,
    "الأهوم": 1,
    "التهويم": 1,
    "الهوام": 1,
    "الهوم": 1,
    "الهوماة والهومة": 1,
    "هاون": 1,
    "الأهون": 1,
    "الهاوون": 1,
    "الهونة": 1,
    "الهونى": 1,
    "الهوينى": 1,
    "الهينة": 1,
    "الهين": 1,
    "تهوه": 1,
    "الهاهة": 1,
    "الهواهي": 1,
    "الهواهية": 1,
    "الهوهاء": 1,
    "الهوهاة": 1,
    "الهوه": 1,
    "الهوهة": 1,
    "الهواءة": 1,
    "هاوى": 1,
    "اهتوى": 1,
    "انهوى": 1,
    "تهاوى": 1,
    "استهوى": 1,
    "التهواء": 1,
    "الهوي": 1,
    "هايأه": 1,
    "هيأ": 1,
    "تهايأ": 1,
    "المهايأة": 1,
    "الهيء": 1,
    "هابه": 1,
    "اهتاب": 1,
    "المتهيب": 1,
    "الهيبان": 1,
    "الهيت": 1,
    "هيتاه وهيتاه": 1,
    "هايث": 1,
    "تهايث": 1,
    "تهيث": 1,
    "استهاث": 1,
    "المهايث": 1,
    "الهايثة": 1,
    "الهيثة": 1,
    "أهاج": 1,
    "أهيج": 1,
    "هايج": 1,
    "هيجه": 1,
    "اهتاج": 1,
    "تهايج": 1,
    "تهيج": 1,
    "المهياج": 1,
    "الهائج": 1,
    "الهاجة": 1,
    "الهيج": 1,
    "الهيجاء": 1,
    "هيخ": 1,
    "الهيخ": 1,
    "هاده": 1,
    "الهيد": 1,
    "الهيدكور": 1,
    "الهيدكورة": 1,
    "تهير": 1,
    "الهيار": 1,
    "الهيرة": 1,
    "هيراطيقي": 1,
    "الهيرودين": 1,
    "الهيزب": 1,
    "هاسى": 1,
    "الأهيس": 1,
    "هيس هيس": 1,
    "تهيش": 1,
    "الهيشة": 1,
    "هاص": 1,
    "المهايص": 1,
    "هاض": 1,
    "هيض": 1,
    "اهتاض": 1,
    "انهاض": 1,
    "تهيض": 1,
    "المستهاض": 1,
    "الهيض": 1,
    "الهيضاء": 1,
    "الهيضة": 1,
    "هاط": 1,
    "هايط": 1,
    "تهايط": 1,
    "الهياط": 1,
    "الهيط": 1,
    "تهيع": 1,
    "المهيع": 1,
    "الهائع": 1,
    "الهائعة": 1,
    "الهياع": 1,
    "الهيعة": 1,
    "الهيع": 1,
    "الهيعرون": 1,
    "أهيغ": 1,
    "الأهيغ": 1,
    "الأهيغان": 1,
    "هاف": 1,
    "هيف": 1,
    "أهاف": 1,
    "اهتاف": 1,
    "تهيف": 1,
    "استهاف": 1,
    "المهياف": 1,
    "الهاف": 1,
    "الهافة": 1,
    "الهيف": 1,
    "الهيفان": 1,
    "الهيوف": 1,
    "أهيق": 1,
    "الأهيق": 1,
    "الهيق": 1,
    "أهال": 1,
    "انهال": 1,
    "تهيل": 1,
    "الأهيل": 1,
    "المهيل": 1,
    "الهيال": 1,
    "الهيلان": 1,
    "الهيول": 1,
    "الهيولى": 1,
    "الهيولاني": 1,
    "هيلل": 1,
    "اهتام": 1,
    "تهيم": 1,
    "استهيم": 1,
    "الأهيم": 1,
    "الهيمان": 1,
    "الهيوم": 1,
    "هيمن": 1,
    "الهيمجلوبين": 1,
    "هاهى": 1,
    "الهية": 1,
    "هيه هيه": 1,
    "هيا هيا": 1,
    "هيان": 1,
    "الواو": 1,
    "وئب": 1,
    "أوأب": 1,
    "اتأب": 1,
    "الإبة": 1,
    "التؤبة": 1,
    "الموئبة": 1,
    "الوأبة": 1,
    "الوئيبة": 1,
    "الوأج": 1,
    "توأد": 1,
    "التوآد": 1,
    "التؤدة": 1,
    "الوئيد": 1,
    "أوأره": 1,
    "وأره": 1,
    "الوئار": 1,
    "الوئرة": 1,
    "الوؤرة": 1,
    "وأل": 1,
    "أوألت": 1,
    "واءل": 1,
    "الموئل": 1,
    "الموئلة": 1,
    "الوأل": 1,
    "الوألة": 1,
    "يوأمه": 1,
    "واءمه": 1,
    "تواءم": 1,
    "الموأمة": 1,
    "الوأم": 1,
    "الوأمة": 1,
    "التوأن": 1,
    "الوأن": 1,
    "الوأنة": 1,
    "وأوأ": 1,
    "الوأواء": 1,
    "وأى": 1,
    "اتأى": 1,
    "تواءى": 1,
    "استوأى": 1,
    "الوأي": 1,
    "الوأى": 1,
    "الوأية": 1,
    "الوئية": 1,
    "وبئت": 1,
    "وبؤت": 1,
    "أوبأت": 1,
    "توبأ": 1,
    "استوبأ": 1,
    "الموبئ": 1,
    "الوبأ": 1,
    "وبخه": 1,
    "وبد": 1,
    "أوبد": 1,
    "توبد": 1,
    "استوبد": 1,
    "الوبد": 1,
    "الأوبر": 1,
    "الوابر": 1,
    "أوبش": 1,
    "الوبش": 1,
    "وبص": 1,
    "أوبصت": 1,
    "الوابصة": 1,
    "الوباص": 1,
    "الوبصة": 1,
    "الوبيصة": 1,
    "وبط": 1,
    "الوباط": 1,
    "الوباعة": 1,
    "وبغه": 1,
    "يبغه": 1,
    "الوبغ": 1,
    "الوبغة": 1,
    "أوبقه": 1,
    "استوبق": 1,
    "الموبق": 1,
    "الموبقات": 1,
    "وبلت": 1,
    "استوبلت": 1,
    "الموبل": 1,
    "الموبلة": 1,
    "الميبل": 1,
    "الميبلة": 1,
    "الوابلة": 1,
    "الوبال": 1,
    "الوبل": 1,
    "الوبلى": 1,
    "الوبلة": 1,
    "الوبيل": 1,
    "الوبيلة": 1,
    "الوت": 1,
    "الوتة": 1,
    "الوتاوت": 1,
    "وتح": 1,
    "أوتح": 1,
    "توتح": 1,
    "الوتح": 1,
    "الوتيح": 1,
    "أوتد": 1,
    "الميتد": 1,
    "الميتدة": 1,
    "الوتدة": 1,
    "أوتر": 1,
    "واتر": 1,
    "تواترت": 1,
    "تترا": 1,
    "المتواتر": 1,
    "الوتران": 1,
    "الوترة": 1,
    "الوترتان": 1,
    "وتغ": 1,
    "أوتغ": 1,
    "الموتغة": 1,
    "الوتيغة": 1,
    "الأوتل": 1,
    "وتم": 1,
    "الموتمة": 1,
    "الوتمة": 1,
    "أوتنت": 1,
    "واتن": 1,
    "استوتن": 1,
    "الموتونة": 1,
    "الواتن": 1,
    "الوتن": 1,
    "الوتنة": 1,
    "واتاه": 1,
    "الوتى": 1,
    "وثئت": 1,
    "أوثأ": 1,
    "الميثأة": 1,
    "الوثء": 1,
    "الوثاءة": 1,
    "أوثب": 1,
    "واثبه": 1,
    "وثبه": 1,
    "تواثب": 1,
    "توثب": 1,
    "الموثبان": 1,
    "الميثب": 1,
    "الوثبى": 1,
    "وثج": 1,
    "أوثج": 1,
    "استوثج": 1,
    "الموتثجة": 1,
    "الموثخ": 1,
    "الموثوخ": 1,
    "الوثخة": 1,
    "الوثيخة": 1,
    "استوثر": 1,
    "الأوثر": 1,
    "الميثرة": 1,
    "الواثر": 1,
    "الوثار": 1,
    "الوثر": 1,
    "الوثير": 1,
    "الوثيرة": 1,
    "وثغ": 1,
    "الوثيغة": 1,
    "وثف": 1,
    "أوثف": 1,
    "أوثق": 1,
    "تواثق": 1,
    "استوثق": 1,
    "وثله": 1,
    "يثله": 1,
    "الوثل": 1,
    "الوثيل": 1,
    "وثمت": 1,
    "واثم": 1,
    "الميثم": 1,
    "الوثيمة": 1,
    "وثنت": 1,
    "أوثن": 1,
    "استوثن": 1,
    "الموثونة": 1,
    "وجأ": 1,
    "أوجأ": 1,
    "اتجأ": 1,
    "توجأه": 1,
    "الوجء": 1,
    "الوجأ": 1,
    "الوجيئة": 1,
    "أوجب": 1,
    "تواجب": 1,
    "توجب": 1,
    "استوجب": 1,
    "الموجبة": 1,
    "الوجب": 1,
    "الوجوب": 1,
    "الوجيبة": 1,
    "وج": 1,
    "يوج": 1,
    "الوج": 1,
    "الوجج": 1,
    "وجح": 1,
    "أوجح": 1,
    "الموجح": 1,
    "الموجوح": 1,
    "الوجاح": 1,
    "الوجح": 1,
    "الوجيح": 1,
    "أوجد": 1,
    "تواجد": 1,
    "توجد": 1,
    "الواجد": 1,
    "الوجادة": 1,
    "الوجد": 1,
    "الوجدان": 1,
    "الوجيد": 1,
    "أوجذه": 1,
    "واجذه": 1,
    "الوجذ": 1,
    "أوجر": 1,
    "توجر": 1,
    "الميجار": 1,
    "الميجر": 1,
    "الميجرة": 1,
    "الوجار": 1,
    "الوجر": 1,
    "الوجرة": 1,
    "الوجور": 1,
    "توجز": 1,
    "استوجز": 1,
    "الميجاز": 1,
    "الوجز": 1,
    "أوجس": 1,
    "الأوجس": 1,
    "الواجس": 1,
    "الوجس": 1,
    "أوجع": 1,
    "الوجعاء": 1,
    "الوجيع": 1,
    "أوجف": 1,
    "استوجفه": 1,
    "الميجاف": 1,
    "وجله": 1,
    "يوجله": 1,
    "أوجله": 1,
    "الموجل": 1,
    "الوجول": 1,
    "الوجيل": 1,
    "الوجم": 1,
    "الوجمة": 1,
    "الوجيم": 1,
    "الوجيمة": 1,
    "وجن": 1,
    "توجن": 1,
    "الأوجن": 1,
    "الموجن": 1,
    "الموجونة": 1,
    "الواجن": 1,
    "الوجن": 1,
    "الوجناء": 1,
    "الوجنة": 1,
    "الوجين": 1,
    "أوجهت": 1,
    "تواجها": 1,
    "التجاه": 1,
    "الوجاه": 1,
    "الوجاهة": 1,
    "وجاه": 1,
    "يجيه": 1,
    "وجي": 1,
    "أوجى": 1,
    "توجى": 1,
    "أوحدت": 1,
    "استوحد": 1,
    "الأحدية": 1,
    "الأوحد": 1,
    "الميحاد": 1,
    "الواحدية": 1,
    "وحاد": 1,
    "الوحد": 1,
    "الوحداني": 1,
    "يوحر": 1,
    "أوحرت": 1,
    "الوحر": 1,
    "الوحرة": 1,
    "أوحش": 1,
    "استوحش": 1,
    "الوحشة": 1,
    "الوحيش": 1,
    "وحصه": 1,
    "يحصه": 1,
    "الوحص": 1,
    "الوحصة": 1,
    "أوحف": 1,
    "الموحف": 1,
    "الواحف": 1,
    "الوحف": 1,
    "الوحفاء": 1,
    "الوحفة": 1,
    "أوحله": 1,
    "واحله": 1,
    "توحل": 1,
    "استوحل": 1,
    "الموحل": 1,
    "وحمت": 1,
    "وحن": 1,
    "توحن": 1,
    "الوحنة": 1,
    "توحوح": 1,
    "الوحواح": 1,
    "الوحوح": 1,
    "توحى": 1,
    "الوحى": 1,
    "الوحاء": 1,
    "الوحاة": 1,
    "وخد": 1,
    "الوخيز": 1,
    "وخش": 1,
    "أوخش": 1,
    "الوخش": 1,
    "الوخط": 1,
    "وخف": 1,
    "أوخف": 1,
    "اتخفت": 1,
    "استوخف": 1,
    "الميخف": 1,
    "الوخفة": 1,
    "الوخيف": 1,
    "الوخيفة": 1,
    "أوخمه": 1,
    "واخم": 1,
    "توخم": 1,
    "استوخم": 1,
    "المتخمة": 1,
    "الموخمة": 1,
    "الوخام": 1,
    "الوخم": 1,
    "الوخمة": 1,
    "الوخوم": 1,
    "الوخيم": 1,
    "الوخيمة": 1,
    "الوخواخ": 1,
    "الوخوخة": 1,
    "واخاه": 1,
    "وخاه": 1,
    "استوخاه": 1,
    "الخية": 1,
    "الوخي": 1,
    "الوخى": 1,
    "ودأ": 1,
    "ودئ": 1,
    "تودأ": 1,
    "المودأة": 1,
    "وادجه": 1,
    "الوداج": 1,
    "الودج": 1,
    "أودح": 1,
    "الودحة": 1,
    "وده": 1,
    "واده": 1,
    "توادا": 1,
    "تودد": 1,
    "المود": 1,
    "الوديد": 1,
    "ودره": 1,
    "تودر": 1,
    "ودست": 1,
    "أودست": 1,
    "تودست": 1,
    "الوادس": 1,
    "الوداس": 1,
    "الودس": 1,
    "الوديس": 1,
    "اتدع": 1,
    "توادع": 1,
    "تودع": 1,
    "استودع": 1,
    "التداعة": 1,
    "التدعة": 1,
    "الدعة": 1,
    "المودوع": 1,
    "الميداعة": 1,
    "الميدع": 1,
    "الميدعة": 1,
    "الودع": 1,
    "الوديع": 1,
    "الوديعة": 1,
    "ودف": 1,
    "تودفت": 1,
    "استودف": 1,
    "الودفة": 1,
    "الوديفة": 1,
    "ودق": 1,
    "ودقت": 1,
    "أودقت": 1,
    "المودق": 1,
    "الوادق": 1,
    "الوديقة": 1,
    "يودك": 1,
    "الأودك": 1,
    "المتودك": 1,
    "الوادك": 1,
    "الودك": 1,
    "الوداك": 1,
    "الوديكة": 1,
    "ودل": 1,
    "ودن": 1,
    "ودنت": 1,
    "أودنت": 1,
    "اتدن": 1,
    "تودن": 1,
    "الأودن": 1,
    "المودن": 1,
    "المودنة": 1,
    "المودون": 1,
    "المودونة": 1,
    "الودان": 1,
    "الودنة": 1,
    "أودى": 1,
    "اتدى": 1,
    "استودى": 1,
    "التودية": 1,
    "الدية": 1,
    "الودي": 1,
    "الودى": 1,
    "وذأت": 1,
    "الوذء": 1,
    "الوذأة": 1,
    "وذخ": 1,
    "يوذح": 1,
    "الأوذح": 1,
    "الوذح": 1,
    "وذره": 1,
    "الوذارة": 1,
    "الوذرة": 1,
    "وذف": 1,
    "توذف": 1,
    "الوذفان": 1,
    "الوذف": 1,
    "الوذفة": 1,
    "توذل": 1,
    "الوذالة": 1,
    "الوذل": 1,
    "الوذلة": 1,
    "الوذيلة": 1,
    "وذمت": 1,
    "أوذم": 1,
    "وذم": 1,
    "الوذم": 1,
    "الوذماء": 1,
    "الوذيمة": 1,
    "توذن": 1,
    "وذى": 1,
    "الوذاة": 1,
    "الوذية": 1,
    "ورأ": 1,
    "وريء": 1,
    "أورأه": 1,
    "أوريء": 1,
    "ورئ": 1,
    "استورأت": 1,
    "واربه": 1,
    "الورب": 1,
    "الوربة": 1,
    "أورث": 1,
    "توارثوا": 1,
    "الوراث": 1,
    "الورث": 1,
    "ورخ": 1,
    "أورخ": 1,
    "تورخ": 1,
    "استورخت": 1,
    "الورخ": 1,
    "الوريخة": 1,
    "أورد": 1,
    "وارده": 1,
    "وردت": 1,
    "استورد": 1,
    "الموردة": 1,
    "الواردة": 1,
    "الوريدة الطفلية": 1,
    "الور": 1,
    "الورة": 1,
    "ورست": 1,
    "أورس": 1,
    "الوارس": 1,
    "الورس": 1,
    "الورسي": 1,
    "الوريس": 1,
    "الورش": 1,
    "الورشان": 1,
    "ورصت": 1,
    "أورصت": 1,
    "أورط": 1,
    "وارطه": 1,
    "ورطه": 1,
    "استورط": 1,
    "الوراط": 1,
    "أورع": 1,
    "وارع": 1,
    "تورع": 1,
    "الرعة": 1,
    "أورف": 1,
    "وارقه": 1,
    "تورق": 1,
    "استورق": 1,
    "ايراق": 1,
    "الأورق": 1,
    "الوارقة": 1,
    "الوراق": 1,
    "الوراقة": 1,
    "الورقاء": 1,
    "الوريقة": 1,
    "يرك": 1,
    "وارك": 1,
    "توارك": 1,
    "تورك": 1,
    "المورك": 1,
    "الموركة": 1,
    "الميركة": 1,
    "الوارك": 1,
    "الوراك": 1,
    "الوركي": 1,
    "الوركاء": 1,
    "الوركانة": 1,
    "الورل": 1,
    "أورمت": 1,
    "الأورم": 1,
    "المورم": 1,
    "تورن": 1,
    "الورانية": 1,
    "ورنه": 1,
    "الورنيش": 1,
    "وره": 1,
    "يوره": 1,
    "توره": 1,
    "الوارهة": 1,
    "الورهاء": 1,
    "الوره": 1,
    "ورور": 1,
    "المورور": 1,
    "الوروري": 1,
    "أورى": 1,
    "توارى": 1,
    "استورى": 1,
    "الترية": 1,
    "الواري": 1,
    "الوري": 1,
    "الورية": 1,
    "وزأ": 1,
    "وزأت": 1,
    "توزأ": 1,
    "الوزأ": 1,
    "أوزب": 1,
    "الوزاب": 1,
    "أوزره": 1,
    "وازره": 1,
    "توزر": 1,
    "استوزره": 1,
    "الوزر": 1,
    "الوزرة": 1,
    "أوزع": 1,
    "وازعه": 1,
    "وزعه": 1,
    "اتزع": 1,
    "توزع": 1,
    "استوزعه": 1,
    "الأوزاع": 1,
    "المتزع": 1,
    "الوازع": 1,
    "الوزوع": 1,
    "وزغ": 1,
    "أوزغ": 1,
    "الوزغ": 1,
    "الوزغة": 1,
    "وزف": 1,
    "يزف": 1,
    "أوزف": 1,
    "وازف": 1,
    "توازف": 1,
    "أوزكت": 1,
    "وزم": 1,
    "يزم": 1,
    "توزم": 1,
    "الوزام": 1,
    "الوزم": 1,
    "الوزمة": 1,
    "الوزيم": 1,
    "الوزيمة": 1,
    "اتزن": 1,
    "الأوزن": 1,
    "الاتزان": 1,
    "التوازن الاقتصادي": 1,
    "الموزون": 1,
    "الموزونة": 1,
    "الوازن": 1,
    "الوزان": 1,
    "الوزانة": 1,
    "الوزنة": 1,
    "الوزين": 1,
    "وزوز": 1,
    "الوزوز": 1,
    "أوزى": 1,
    "وازاه": 1,
    "توازى": 1,
    "استوزى": 1,
    "الوزى": 1,
    "وسبت": 1,
    "وسب": 1,
    "أوسبت": 1,
    "الميساب": 1,
    "الوسب": 1,
    "توسخ": 1,
    "استوسخ": 1,
    "أوسد": 1,
    "وسطه": 1,
    "الموسط": 1,
    "الواسط": 1,
    "الوسوط": 1,
    "سع": 1,
    "استوسع": 1,
    "المتسع": 1,
    "الميساع": 1,
    "الوساع": 1,
    "الوسع": 1,
    "الوسيع": 1,
    "وسف": 1,
    "توسف": 1,
    "الوسف": 1,
    "وسقت": 1,
    "أوسقت": 1,
    "واسقه": 1,
    "استوسق": 1,
    "الميساق": 1,
    "الوسق": 1,
    "الوسيق": 1,
    "الوسيقة": 1,
    "الواسل": 1,
    "الواسلة": 1,
    "واسمه": 1,
    "الموسم": 1,
    "الموسوم": 1,
    "الموسومة": 1,
    "الميسم": 1,
    "الوسمة": 1,
    "الوسمي": 1,
    "أوسنته": 1,
    "توسن": 1,
    "استوسن": 1,
    "الموسونة": 1,
    "الوسن": 1,
    "الوسنى": 1,
    "الوسنانة": 1,
    "الوسني": 1,
    "أوسى": 1,
    "الوشب": 1,
    "الوشبة": 1,
    "وشج": 1,
    "توشج": 1,
    "الأوشاج": 1,
    "الواشجة": 1,
    "الوشيج": 1,
    "الوشيجة": 1,
    "اتشحت": 1,
    "توشحت": 1,
    "التوشيح": 1,
    "الموشح": 1,
    "الموشحة": 1,
    "الوشحاء": 1,
    "اتشرت": 1,
    "استوشرت": 1,
    "الميشار": 1,
    "توشز": 1,
    "الوشز": 1,
    "الوشيزة": 1,
    "وشظ": 1,
    "الوشيظ": 1,
    "الوشيظة": 1,
    "وشعت": 1,
    "أوشع": 1,
    "توشع": 1,
    "الموشع": 1,
    "الوشع": 1,
    "الوشوع": 1,
    "الوشيع": 1,
    "الوشيعة": 1,
    "وشغ": 1,
    "أوشغ": 1,
    "توشغ": 1,
    "الوشغ": 1,
    "الوشوغ": 1,
    "الوشيغ": 1,
    "أوشق": 1,
    "اتشق": 1,
    "تواشق": 1,
    "المواشيق": 1,
    "الواشق": 1,
    "الوشيق": 1,
    "الوشيقة": 1,
    "واشك": 1,
    "الوشاك": 1,
    "الوشكان": 1,
    "الوشيك": 1,
    "أوشل": 1,
    "الأوشال": 1,
    "الواشل": 1,
    "الوشلة": 1,
    "الوشول": 1,
    "أوشم": 1,
    "اتشم": 1,
    "استوشم": 1,
    "الوشمة": 1,
    "الوشيمة": 1,
    "توشن": 1,
    "الأوشن": 1,
    "الوشنان": 1,
    "توشوش": 1,
    "الوشواشة": 1,
    "الوشوش": 1,
    "الوشوشي": 1,
    "أوشى": 1,
    "توشى": 1,
    "استوشى": 1,
    "الشية": 1,
    "الموشى": 1,
    "الواشي": 1,
    "الوشاء": 1,
    "الوشي": 1,
    "توصب": 1,
    "الموصب": 1,
    "الواصبة": 1,
    "الوصب": 1,
    "أوصد": 1,
    "وصده": 1,
    "استوصد": 1,
    "الموصد": 1,
    "الوصاد": 1,
    "الوصيد": 1,
    "الوصيدة": 1,
    "الأوصر": 1,
    "الوصر": 1,
    "الوصرة": 1,
    "الوصيرة": 1,
    "وصصت": 1,
    "وصع": 1,
    "الوصع": 1,
    "الوصيع": 1,
    "أوصف": 1,
    "واصفته": 1,
    "تواصفوا": 1,
    "توصف": 1,
    "استوصف": 1,
    "المستوصف": 1,
    "المواصفة": 1,
    "الوصاف": 1,
    "الوصيف": 1,
    "أوصله": 1,
    "واصله": 1,
    "تواصلا": 1,
    "استوصلت": 1,
    "المستوصلة": 1,
    "الموصل المعزول": 1,
    "الوصيل": 1,
    "الوصيلة": 1,
    "وصمه": 1,
    "يصمه": 1,
    "توصم": 1,
    "الوصمة": 1,
    "الوصواص": 1,
    "الوصوص": 1,
    "أوصى": 1,
    "تواصى": 1,
    "الواصية": 1,
    "الوصاة": 1,
    "وضأه": 1,
    "يضؤه": 1,
    "واضأه": 1,
    "توضأ": 1,
    "المتوضأ": 1,
    "الميضأة": 1,
    "الوضاء": 1,
    "وضحه": 1,
    "استوضح": 1,
    "الأواضح": 1,
    "الأوضاح": 1,
    "المتوضح": 1,
    "الموضحة": 1,
    "الواضحة": 1,
    "الوضح": 1,
    "الوضاح": 1,
    "الوضيحة": 1,
    "وضخ": 1,
    "أوضخت": 1,
    "واضخه": 1,
    "تواضخا": 1,
    "يوضر": 1,
    "وضره": 1,
    "الوضر": 1,
    "أوضع": 1,
    "استوضع": 1,
    "التوضيع": 1,
    "الضعة": 1,
    "الواضعة": 1,
    "الوضاع": 1,
    "الوضيعة": 1,
    "أوضم": 1,
    "استوضمه": 1,
    "الوضم": 1,
    "الوضمة": 1,
    "الوضيم": 1,
    "الوضيمة": 1,
    "وضن": 1,
    "أوضن": 1,
    "اتضن": 1,
    "توضن": 1,
    "الموضونة": 1,
    "الميضنة": 1,
    "الوضنة": 1,
    "الوضين": 1,
    "وطؤ": 1,
    "واطأ": 1,
    "تواطأ": 1,
    "توطأ": 1,
    "استوطأ": 1,
    "الإيطاء": 1,
    "الطأة والطئة": 1,
    "الموطأ": 1,
    "الميطاء": 1,
    "الوطاء": 1,
    "الوطاءة": 1,
    "الوطيء": 1,
    "الوطيئة": 1,
    "الوطب": 1,
    "الوطباء": 1,
    "الوطبة": 1,
    "وطحه": 1,
    "يطحه": 1,
    "تواطحت": 1,
    "الوطح": 1,
    "وطده": 1,
    "توطد": 1,
    "اتطد": 1,
    "المتوطد": 1,
    "الميطدة": 1,
    "الوطيدة": 1,
    "الوطر": 1,
    "وطس": 1,
    "تواطس": 1,
    "الوطاس": 1,
    "وط": 1,
    "أوطف": 1,
    "الأوطف": 1,
    "وطمه": 1,
    "يطمه": 1,
    "وطم": 1,
    "أوطن": 1,
    "واطنه": 1,
    "اتطن": 1,
    "توطن": 1,
    "استوطن": 1,
    "الميطان": 1,
    "وطوط": 1,
    "توطوط": 1,
    "الوطواط": 1,
    "الوطواطة": 1,
    "الوطواطي": 1,
    "تواظبت": 1,
    "الموظوب": 1,
    "الموظوبة": 1,
    "الميظب": 1,
    "الوظبة": 1,
    "واظفه": 1,
    "وظفه": 1,
    "الوظيف": 1,
    "وعبه": 1,
    "يعبه": 1,
    "أوعب": 1,
    "استوعبه": 1,
    "الوعب": 1,
    "الوعيب": 1,
    "أوعث": 1,
    "وعثه": 1,
    "الأوعث": 1,
    "الموعث": 1,
    "الموعوث": 1,
    "الوعث": 1,
    "الوعثاء": 1,
    "الوعثة": 1,
    "الوعوث": 1,
    "أوعد": 1,
    "واعده": 1,
    "تواعدوا": 1,
    "توعده": 1,
    "الواعد": 1,
    "الواعدة": 1,
    "أوعر": 1,
    "توعر": 1,
    "استوعر": 1,
    "الأوعر": 1,
    "الواعر": 1,
    "الوعر": 1,
    "الوعير": 1,
    "وعسه": 1,
    "يعسه": 1,
    "أوعس": 1,
    "واعست": 1,
    "الأوعس": 1,
    "الميعاس": 1,
    "الوعس": 1,
    "الوعساء": 1,
    "الوعسة": 1,
    "وعظه": 1,
    "الموعظة": 1,
    "وعف": 1,
    "أوعف": 1,
    "الوعف": 1,
    "توعق": 1,
    "استوعق": 1,
    "الوعاق": 1,
    "الوعق": 1,
    "الوعقة": 1,
    "الوعيق": 1,
    "أوعكت": 1,
    "الموعوك": 1,
    "يعل": 1,
    "توعل": 1,
    "استوعل": 1,
    "المستوعل": 1,
    "وعم": 1,
    "الوعم": 1,
    "توعنت": 1,
    "الوعان": 1,
    "الوعن": 1,
    "الوعنة": 1,
    "الوعواع": 1,
    "الوعوع": 1,
    "الوعوعة": 1,
    "الوعوعي": 1,
    "وع ى": 1,
    "أوعى": 1,
    "استوعى": 1,
    "الموعي": 1,
    "الواعية": 1,
    "الوعى": 1,
    "الوعية": 1,
    "وغب": 1,
    "الوغب": 1,
    "الوغبة": 1,
    "واغده": 1,
    "المواغدة": 1,
    "وغرت": 1,
    "أوغر": 1,
    "وغره": 1,
    "توغر": 1,
    "الميغر": 1,
    "الوغر": 1,
    "الوغرة": 1,
    "الوغير": 1,
    "الوغيرة": 1,
    "وغف": 1,
    "يغف": 1,
    "أوغف": 1,
    "الوغف": 1,
    "أوغل": 1,
    "الواغل": 1,
    "الوغل": 1,
    "وغم": 1,
    "أوغمه": 1,
    "تواغم": 1,
    "توغم": 1,
    "الوغم": 1,
    "الوغى": 1,
    "الوغ ى": 1,
    "وافده": 1,
    "وفده": 1,
    "توافد": 1,
    "توفد": 1,
    "استوفد": 1,
    "الأوفاد": 1,
    "الوافد": 1,
    "الوفاد": 1,
    "أوفر": 1,
    "اتفر": 1,
    "توافر": 1,
    "استوفر": 1,
    "الموفور": 1,
    "الوافرة": 1,
    "الوفر": 1,
    "الوفراء": 1,
    "الوفير": 1,
    "يفز": 1,
    "أوفزه": 1,
    "وافزه": 1,
    "توفز": 1,
    "استوفز": 1,
    "الوفز": 1,
    "أوفض": 1,
    "استوفض": 1,
    "الأوفاض": 1,
    "الميفاض": 1,
    "الوفض": 1,
    "الوفضة": 1,
    "الوفاع": 1,
    "الوفع": 1,
    "الوفعة": 1,
    "الوفيعة": 1,
    "أوفق": 1,
    "توافقت": 1,
    "توفق": 1,
    "استوفق": 1,
    "الاتفاقية لدولية": 1,
    "التوفاق": 1,
    "التوفق": 1,
    "التيفاق": 1,
    "الوفاق": 1,
    "الوفق": 1,
    "وفل": 1,
    "وفله": 1,
    "الوافل": 1,
    "الوفل": 1,
    "وفه": 1,
    "يوفه": 1,
    "الوافه": 1,
    "الوفاهة": 1,
    "الوفهية": 1,
    "وافى": 1,
    "توافى": 1,
    "استوفى": 1,
    "الميفى": 1,
    "الميفاء": 1,
    "الوافي": 1,
    "الوافية": 1,
    "الوفي": 1,
    "وقبت": 1,
    "تقب": 1,
    "أوقب": 1,
    "الأوقاب": 1,
    "الميقاب": 1,
    "الميقب": 1,
    "الوقب": 1,
    "الوقباء": 1,
    "الوقبة": 1,
    "الوقبي": 1,
    "يقته": 1,
    "الميقات": 1,
    "توقح": 1,
    "الموقح": 1,
    "الوقاح": 1,
    "اتقدت": 1,
    "توقدت": 1,
    "استوقدت": 1,
    "الميقاد": 1,
    "الوقد": 1,
    "الوقدة": 1,
    "الوقيد": 1,
    "وقذت": 1,
    "أوقذه": 1,
    "الموقذ": 1,
    "الموقذة": 1,
    "الموقوذ": 1,
    "الموقوذة": 1,
    "الوقيذ": 1,
    "الوقيذة": 1,
    "أوقرت": 1,
    "اتقر": 1,
    "استوقر": 1,
    "الموقر": 1,
    "الموقرة": 1,
    "الموقور": 1,
    "الموقورة": 1,
    "الوقر": 1,
    "الوقرة": 1,
    "الوقري": 1,
    "الوقور": 1,
    "الوقير": 1,
    "الوقيرة": 1,
    "الأوقاس": 1,
    "الوقس": 1,
    "وقش": 1,
    "أوقش": 1,
    "توقش": 1,
    "الأوقاش": 1,
    "الوقش": 1,
    "الوقشة": 1,
    "وقصت": 1,
    "أوقصه": 1,
    "تواقص": 1,
    "توقص": 1,
    "الأوقاص": 1,
    "الموقوص": 1,
    "الوقص": 1,
    "الوقيصة": 1,
    "وقطه": 1,
    "استوقط": 1,
    "الموقوط": 1,
    "الوقط": 1,
    "الوقيط": 1,
    "واقعه": 1,
    "تواقع": 1,
    "استوقع": 1,
    "الموقعة": 1,
    "الموقوع": 1,
    "الموقوعة": 1,
    "الميقعة": 1,
    "الوقاعة": 1,
    "الوقعة": 1,
    "الوقاع": 1,
    "الوقيع": 1,
    "الوقيعة": 1,
    "واقفه": 1,
    "تواقف": 1,
    "استوقفه": 1,
    "التوقيفي": 1,
    "الموقفان": 1,
    "الموقفة": 1,
    "الميقاف": 1,
    "الميقف": 1,
    "الواقفة": 1,
    "الواقفية": 1,
    "الوقاف": 1,
    "الوقيفة": 1,
    "الوق": 1,
    "توقل": 1,
    "الوقل": 1,
    "الوقلة": 1,
    "وقم": 1,
    "وقمت": 1,
    "أوقمه": 1,
    "توقم": 1,
    "الوقام": 1,
    "أوقن": 1,
    "توقن": 1,
    "الوقنة": 1,
    "الموقونة": 1,
    "الوقواقة": 1,
    "وقاه": 1,
    "توقاه": 1,
    "التقاة": 1,
    "التقية": 1,
    "التقي": 1,
    "الموقى": 1,
    "الوقاء": 1,
    "الوقية": 1,
    "تكئ": 1,
    "أتكأ": 1,
    "أوكأ": 1,
    "واكأ": 1,
    "توكأ": 1,
    "التكأة": 1,
    "المتكأ": 1,
    "المتكئ": 1,
    "أوكب": 1,
    "واكب": 1,
    "الواكبة": 1,
    "الوكاب": 1,
    "الوكوب": 1,
    "وكت": 1,
    "الموكوت": 1,
    "الوكت": 1,
    "الوكتة": 1,
    "الوكات": 1,
    "الوكيت": 1,
    "استوكث": 1,
    "الوكاث": 1,
    "وكحه": 1,
    "أوكح": 1,
    "استوكح": 1,
    "الأوكح": 1,
    "الوكح": 1,
    "أوكد": 1,
    "توكد": 1,
    "التواكيد": 1,
    "التوكيد": 1,
    "المتوكد": 1,
    "المواكدة": 1,
    "الوكاد": 1,
    "الوكد": 1,
    "أوكر": 1,
    "اتكر": 1,
    "توكر": 1,
    "الوكر": 1,
    "الوكرى": 1,
    "الوكرة": 1,
    "الوكار": 1,
    "الوكير": 1,
    "الوكيرة": 1,
    "توكز": 1,
    "الوكزى": 1,
    "أوكس": 1,
    "الأوكس": 1,
    "الوكس": 1,
    "وكظ": 1,
    "واكظ": 1,
    "توكظ": 1,
    "أوكع": 1,
    "واكع": 1,
    "اتكع": 1,
    "استوكع": 1,
    "الميكع": 1,
    "الميكعة": 1,
    "الوكعاء": 1,
    "الوكيع": 1,
    "أوكف": 1,
    "واكفه": 1,
    "تواكف": 1,
    "توكف": 1,
    "استوكف": 1,
    "الواكف": 1,
    "الوكاف": 1,
    "الوكف": 1,
    "الوكوف": 1,
    "الوكيف": 1,
    "واكلت": 1,
    "التكلان": 1,
    "التكلة": 1,
    "المواكل": 1,
    "الواكل": 1,
    "الوكال": 1,
    "الوكل": 1,
    "الوكلة": 1,
    "وكمه": 1,
    "وكمت": 1,
    "توكن": 1,
    "الموكن": 1,
    "الموكنة": 1,
    "الواكن": 1,
    "الوكن": 1,
    "الوكنة": 1,
    "وكوك": 1,
    "أوكى": 1,
    "استوكى": 1,
    "الموكى": 1,
    "الوكاء": 1,
    "ولب": 1,
    "الوالبة": 1,
    "أولته": 1,
    "ولث": 1,
    "الوالث": 1,
    "الولث": 1,
    "أولجه": 1,
    "اتلج": 1,
    "التلج": 1,
    "المولج": 1,
    "الوالجة": 1,
    "الولاج": 1,
    "الولجة": 1,
    "الوليجة": 1,
    "ولخه": 1,
    "أولخ": 1,
    "ائتلخ": 1,
    "أولدت": 1,
    "توالدوا": 1,
    "استولد": 1,
    "المولدة": 1,
    "الوالدة": 1,
    "الولود": 1,
    "الوليدة": 1,
    "الوليدية": 1,
    "أولس": 1,
    "والست": 1,
    "توالس": 1,
    "الولس": 1,
    "الولاس": 1,
    "أولعه": 1,
    "أولع": 1,
    "اتلعت": 1,
    "ايتلع": 1,
    "الوالع": 1,
    "الوالعة": 1,
    "الولعة": 1,
    "الولوع": 1,
    "الوليع": 1,
    "أولغ": 1,
    "استولغ": 1,
    "الميلغ": 1,
    "الولغة": 1,
    "ولف": 1,
    "أولف": 1,
    "والفه": 1,
    "توالف": 1,
    "الولاف": 1,
    "الوليف": 1,
    "ولق": 1,
    "الولق": 1,
    "الولقى": 1,
    "الولم": 1,
    "الولمة": 1,
    "أولهه": 1,
    "ولهه": 1,
    "الموله": 1,
    "الميلاه": 1,
    "الميله": 1,
    "ولولت": 1,
    "تولولت": 1,
    "المولول": 1,
    "الولوال": 1,
    "تمولى": 1,
    "المتوالي": 1,
    "المواليا": 1,
    "المولوي": 1,
    "المولوية": 1,
    "الولية": 1,
    "الوامئة": 1,
    "الومد": 1,
    "الومدة": 1,
    "ومز": 1,
    "أومست": 1,
    "المومسة": 1,
    "ومقه": 1,
    "وامقه": 1,
    "توامقوا": 1,
    "تومق": 1,
    "تومن": 1,
    "ومه": 1,
    "الومهة": 1,
    "ومى": 1,
    "أومى": 1,
    "استومى": 1,
    "الومى": 1,
    "الونج": 1,
    "الونع": 1,
    "ونم": 1,
    "الونمة": 1,
    "الونيم": 1,
    "أوناه": 1,
    "الواني": 1,
    "الوناة": 1,
    "الوني": 1,
    "الونية": 1,
    "أوهب": 1,
    "واهبه": 1,
    "اتهب": 1,
    "تواهب": 1,
    "استوهب": 1,
    "وهت": 1,
    "أوهت": 1,
    "الوهتة": 1,
    "وهث": 1,
    "توهث": 1,
    "الواهث": 1,
    "وهجت": 1,
    "أوهج": 1,
    "توهجت": 1,
    "الوهجان": 1,
    "الوهاج": 1,
    "الوهيج": 1,
    "وهد": 1,
    "توهد": 1,
    "أوهد": 1,
    "الوهد": 1,
    "الوهدة": 1,
    "وهره": 1,
    "يهره": 1,
    "توهر": 1,
    "المستوهر": 1,
    "الواهر": 1,
    "الوهر": 1,
    "الوهرة": 1,
    "وهزه": 1,
    "يهزه": 1,
    "توهز": 1,
    "الأوهز": 1,
    "الموهز": 1,
    "الوهازة": 1,
    "الوهز": 1,
    "وهس": 1,
    "توهس": 1,
    "الوهس": 1,
    "الوهاس": 1,
    "الوهيسة": 1,
    "وهص": 1,
    "اتهص": 1,
    "الموهوص": 1,
    "الموهص": 1,
    "الوهصة": 1,
    "الوهاص": 1,
    "وهط": 1,
    "أوهط": 1,
    "توهط": 1,
    "الوهط": 1,
    "الوهطة": 1,
    "وهف": 1,
    "أوهف": 1,
    "الواهف": 1,
    "الوهافة": 1,
    "وهق": 1,
    "أوهق": 1,
    "واهقه": 1,
    "تواهق": 1,
    "وهله": 1,
    "توهله": 1,
    "استوهل": 1,
    "المستوهل": 1,
    "الواهلة": 1,
    "الوهلة": 1,
    "وهمه": 1,
    "توهم": 1,
    "التهمة والتهمة": 1,
    "التهيم": 1,
    "المتهم والمتهم": 1,
    "الموهوم": 1,
    "وهنه": 1,
    "توهن": 1,
    "الموهون": 1,
    "الواهن": 1,
    "الواهنة": 1,
    "الوهنانة": 1,
    "الوهين": 1,
    "الوه": 1,
    "وهوه": 1,
    "الموهوهة": 1,
    "الوهواه": 1,
    "الوهوهة": 1,
    "وهى": 1,
    "أوهاه": 1,
    "الأوهية": 1,
    "الوهي": 1,
    "الوهية": 1,
    "الويبة": 1,
    "الويج": 1,
    "الويس": 1,
    "ويس": 1,
    "توايلا": 1,
    "تويل": 1,
    "الويلة": 1,
    "ويلمه": 1,
    "الوانة": 1,
    "الوين": 1,
    "الوينة": 1,
    "ويه وويها": 1,
    "الياء": 1,
    "الياردة": 1,
    "أيأسه": 1,
    "يأسه": 1,
    "اتأس": 1,
    "يأيأ": 1,
    "اليأياء": 1,
    "اليؤيؤ": 1,
    "يببه": 1,
    "اليباب": 1,
    "ييبس وييبس": 1,
    "يابسه": 1,
    "يبسه": 1,
    "اتبس": 1,
    "الأيبس": 1,
    "الميباس": 1,
    "اليابس": 1,
    "اليباس": 1,
    "يباس": 1,
    "اليبسة": 1,
    "اليبوسة": 1,
    "اليبيس": 1,
    "اليتوع": 1,
    "ييتم": 1,
    "أيتمت": 1,
    "يتمه": 1,
    "الميتمة": 1,
    "اليتيمة": 1,
    "يتنت": 1,
    "أيتنت": 1,
    "أيدع": 1,
    "يدعه": 1,
    "الأيدع": 1,
    "أيدى": 1,
    "ياداه": 1,
    "اليداء": 1,
    "اليدي": 1,
    "ييرع": 1,
    "اليراعة": 1,
    "اليرع": 1,
    "يرق": 1,
    "اليارق": 1,
    "اليرقانة": 1,
    "اليرون": 1,
    "يرنأ": 1,
    "اليرنأ": 1,
    "اليرناء": 1,
    "أيسر": 1,
    "اتسر": 1,
    "تياسر": 1,
    "الأيسر": 1,
    "التيسور": 1,
    "الموسر": 1,
    "الميسر": 1,
    "الميسرة": 1,
    "الميسور": 1,
    "الياسر": 1,
    "اليسارى": 1,
    "اليسارة": 1,
    "اليسرة": 1,
    "في لغة أهل مصر": 1,
    "اليعبوب": 1,
    "اليعار": 1,
    "اليعارة": 1,
    "اليعر": 1,
    "اليعرة": 1,
    "اليعور": 1,
    "اليعورة": 1,
    "يفخه": 1,
    "ييفخه": 1,
    "أيفع": 1,
    "تيفع": 1,
    "اليافع": 1,
    "اليافعات": 1,
    "اليفاع": 1,
    "اليفع": 1,
    "اليفعة": 1,
    "اليفن": 1,
    "أيقظه": 1,
    "يقظه": 1,
    "اليقظ": 1,
    "أيقنه": 1,
    "تيقنه": 1,
    "استيقنه": 1,
    "الميقان": 1,
    "اليقن": 1,
    "اليقنة": 1,
    "اليلب": 1,
    "اليلق": 1,
    "اليلقة": 1,
    "يل ويلل": 1,
    "اليلاء": 1,
    "يممه": 1,
    "تيمم": 1,
    "الميمم": 1,
    "الياموم": 1,
    "اليمام": 1,
    "ييمن": 1,
    "تيامن": 1,
    "استيمن": 1,
    "الميمن": 1,
    "اليامن": 1,
    "اليماني واليماني": 1,
    "اليمانية": 1,
    "اليمنة": 1,
    "اليميني": 1,
    "أينع": 1,
    "اليانع": 1,
    "الينع": 1,
    "الينيع": 1,
    "الينم": 1,
    "يهم": 1,
    "ييهم": 1,
    "الأيهم": 1,
    "الأيهمان": 1,
    "اليهماء": 1,
    "يا هياه": 1,
    "يهيه": 1,
    "ياومه": 1,
    "الأيوم": 1
};

export default allWordsObj;
const words = [
    "العاشر",
    "الأول",
    "الثاني",
    "الثالث",
    "الرابع",
    "الخامس",
    "السادس",
    "السابع",
    "الثامن",
    "التاسع",
    "آي",
    "الحرف",
    "في",
    "اللغة",
    "الإنجليزية",
    "زراعي",
    "و",
    "ميكانيكي",
    "شركة",
    "الشاي",
    "الباسيفيكية",
    "الأطلنطية",
    "العظيمة",
    "حرف",
    "يسبق",
    "الكلمة",
    "ويعني",
    "إتجاه",
    "كذا",
    "وكذا",
    "او",
    "الإستمرارية",
    "القنبلة",
    "النووية",
    "الذرية",
    "بحماس",
    "جيدا",
    "آها",
    "فرقة",
    "بوب",
    "نرويجية",
    "المقياس",
    "المستخدم",
    "التحويل",
    "بين",
    "الإشارات",
    "السينية",
    "الى",
    "الرقمية",
    "اصطف",
    "فستان",
    "ضيق",
    "من",
    "الأعلى",
    "وواسع",
    "الأسفل",
    "ممتاز",
    "الدرجة",
    "الأولى",
    "التوثيق",
    "والتفويض",
    "والمحاسبة",
    "الرابطة",
    "الأمريكية",
    "لتقدم",
    "العلم",
    "اتحاد",
    "هواة",
    "الرياضة",
    "رابطة",
    "المصرفيين",
    "مكيف",
    "هواء",
    "تيار",
    "متردد",
    "الحريات",
    "المدنية",
    "الأمريكي",
    "مادة",
    "تحتوي",
    "على",
    "أسبستوس",
    "الجمعية",
    "العربية",
    "للكمبيوتر",
    "الذكاء",
    "الصناعي",
    "ولاية",
    "آلاسكا",
    "ألاباما",
    "وحدة",
    "المنطق",
    "الحسابية",
    "الطبية",
    "المؤسسة",
    "الوطنية",
    "للمقاييس",
    "نظام",
    "الصورة",
    "المتقدم",
    "أركانسو",
    "شركه",
    "أركو",
    "للوقود",
    "وكالة",
    "مشاريع",
    "البحوث",
    "المتقدمة",
    "اسرع",
    "وقت",
    "ممكن",
    "لفحص",
    "المواد",
    "اي",
    "تي",
    "للإتصالات",
    "الانتباه",
    "أريزونا",
    "درجة",
    "الأستاذ",
    "المساعد",
    "الفنون",
    "قاعدة",
    "لبناء",
    "أجهزة",
    "الكمبيوتر",
    "آل",
    "شجيرة",
    "الهند",
    "مقياس",
    "السوائل",
    "هولندا",
    "وألمانيا",
    "جمعية",
    "الناشرين",
    "الأمريكيين",
    "الأرض",
    "حيوان",
    "ثديي",
    "أفريقي",
    "ليلي",
    "كما",
    "الذئب",
    "خنزير",
    "خنازير",
    "ضبع",
    "مخطط",
    "يقتات",
    "الحشرات",
    "مدينة",
    "وميناء",
    "آرهس",
    "هارون",
    "متعلق",
    "بالقساوسة",
    "آرونز",
    "آرونسبرج",
    "للمتقاعدين",
    "مساعد",
    "العلوم",
    "التطبيقية",
    "آسو",
    "فصيلة",
    "الدم",
    "ا",
    "ب",
    "أديس",
    "أبابا",
    "عاصمة",
    "أثيوبيا",
    "بعيدا",
    "عن",
    "أباك",
    "شجرة",
    "الموز",
    "الفلبينية",
    "العدادات",
    "الآلات",
    "الحاسبة",
    "يسبب",
    "العمى",
    "يعمي",
    "العين",
    "إلى",
    "الخلف",
    "الوراء",
    "العداد",
    "أنثى",
    "الخرتيت",
    "ملاك",
    "الجحيم",
    "مؤخرة",
    "السفينة",
    "الإنحناء",
    "تعبيرا",
    "الإحترام",
    "منزعج",
    "منحرج",
    "أذن",
    "البحر",
    "بحري",
    "آذان",
    "حيوانات",
    "بحرية",
    "تساوي",
    "عشرة",
    "أمبير",
    "يهجر",
    "يترك",
    "يتخلى",
    "يتنازل",
    "مهجور",
    "متروك",
    "متخلى",
    "عنه",
    "سائب",
    "بلا",
    "حدود",
    "المتخلي",
    "التارك",
    "الترك",
    "الهجر",
    "التنازل",
    "التخلي",
    "سلسلة",
    "جبال",
    "شرق",
    "الأردن",
    "يحقر",
    "يهين",
    "يذل",
    "أهان",
    "حقر",
    "التقليل",
    "الشأن",
    "التحقير",
    "الإهانة",
    "موجه",
    "الإهانات",
    "المهين",
    "احرج",
    "أخجل",
    "أربك",
    "أحرج",
    "مرتبك",
    "بشكل",
    "مخزي",
    "محرج",
    "يحرج",
    "يربك",
    "الإحراج",
    "الإرباك",
    "الخجل",
    "الحرج",
    "بانتفاء",
    "القدرة",
    "المشي",
    "قابل",
    "للإلغاء",
    "خفف",
    "أخمد",
    "يلغي",
    "يبطل",
    "همد",
    "خف",
    "أبطل",
    "السكون",
    "التخفيض",
    "انقضاء",
    "(سقوط",
    "الحق)",
    "المخفض",
    "المقلل",
    "يخفف",
    "يقلل",
    "خط",
    "الدفاع",
    "المتكون",
    "الأشجار",
    "والأشياء",
    "الحادة",
    "لردع",
    "العدو",
    "مستغل",
    "بعد",
    "وفاة",
    "مالكها",
    "المذبح",
    "المسلخ",
    "المذابح",
    "المسالخ",
    "الأعشاب",
    "الأعواد",
    "التي",
    "تداس",
    "بالحيوانات",
    "عند",
    "مرورها",
    "عليها",
    "بعيد",
    "المحور",
    "نباح",
    "الكلاب",
    "البنك",
    "المتحد",
    "للأعمال",
    "الله",
    "النسخة",
    "الحديثة",
    "للإنجيل",
    "آبا",
    "السويدية",
    "رئاسات",
    "الدير",
    "عباس",
    "بالدير",
    "ودور",
    "العبادة",
    "مختص",
    "رئيس",
    "الراهبة",
    "الرئيسة",
    "أبيفيل",
    "الكنيسة",
    "الأديرة",
    "الكنائس",
    "رؤساء",
    "أبوت",
    "إختصار",
    "لكلمة",
    "اختصر",
    "اختزل",
    "يختصر",
    "مختصر",
    "مختزل",
    "يختزل",
    "الاختصار",
    "الإختزال",
    "الاختصارات",
    "الإختزالات",
    "المختصر",
    "المختزل",
    "إختصاري",
    "المختصرة",
    "الموجز",
    "الحالات",
    "الإبتدائية",
    "لأي",
    "مرحلة",
    "متدين",
    "أو",
    "درويش",
    "إيران",
    "الأحمق",
    "مواطن",
    "أبديرا",
    "عبدي",
    "خادمي",
    "عبدالله",
    "عبد",
    "للخالق",
    "امتلاك",
    "قدرة",
    "الاختباء",
    "مكان",
    "جيد",
    "لتخبئة",
    "الأشياء",
    "ذو",
    "كرش",
    "كبير",
    "بدين",
    "بطين",
    "يبعد",
    "ينحي",
    "نحى",
    "أبعد",
    "العضلة",
    "المحركة",
    "للعين",
    "مبعد",
    "الإبعاد",
    "يتحمل",
    "يتصرف",
    "التصرف",
    "المبتدئ",
    "قصيدة",
    "شعرية",
    "المبدأ",
    "العضو",
    "البدائي",
    "شيئ",
    "نبات",
    "الحور",
    "الأبيض",
    "الـ",
    "أبيليا",
    "أبيل",
    "نهاية",
    "غير",
    "متوقعة",
    "لعملية",
    "عمليات",
    "الكومبيوتر",
    "أبيركرومبي",
    "عصفور",
    "أصفر",
    "أخضر",
    "صغير",
    "يتجول",
    "يتوه",
    "ينحرف",
    "يضل",
    "الحالة",
    "الشاذة",
    "الإنحراف",
    "الطريق",
    "السوي",
    "المتوقع",
    "بالاضطراب",
    "يتخلص",
    "إنساني",
    "كريه",
    "أبياثار",
    "ابن",
    "أهيميليتش",
    "الكاهن",
    "الأكبر",
    "أبيب",
    "الشهر",
    "السنة",
    "الكنائسية",
    "اليهودية",
    "يستمر",
    "يبقى",
    "ثابتا",
    "مع",
    "أبي",
    "الإله",
    "الأب",
    "الرغبة",
    "الإنسحاب",
    "عدم",
    "التعرض",
    "لموقف",
    "معين",
    "إنسان",
    "يتجنب",
    "المواجهة",
    "الأبيس",
    "بشجرة",
    "الإبيس",
    "مقدم",
    "يد",
    "العون",
    "أب",
    "الهاوية",
    "أبينجتون",
    "التطور",
    "المفترض",
    "للكائنات",
    "الحية",
    "المادة",
    "الميتة",
    "بدراسة",
    "الغير",
    "حية",
    "الضعف",
    "الوهن",
    "الندى",
    "الطيبة",
    "يرفض",
    "بطريقة",
    "قانونية",
    "الرفض",
    "طريق",
    "القانون",
    "يحرر",
    "متميز",
    "إستثنائي",
    "يفطم",
    "الرضاعة",
    "فطم",
    "يفتصل",
    "المتحرك",
    "يتمتع",
    "بجسم",
    "سليم",
    "ممثل",
    "البابا",
    "التمييز",
    "الناس",
    "بناء",
    "المقدرة",
    "الجسمية",
    "أبلين",
    "سمك",
    "نهري",
    "غياب",
    "جفون",
    "يقيد",
    "الإنفاق",
    "المفرط",
    "الطعام",
    "ربما",
    "يختلف",
    "منظف",
    "دواء",
    "مطهر",
    "محمر",
    "خجلا",
    "جرامسنتيمترفي",
    "الثانية",
    "التوصيل",
    "الكهربي",
    "يحل",
    "التداخلات",
    "والتشابكات",
    "النذير",
    "سياسة",
    "إبطال",
    "الاسترقاق",
    "الإبطالية",
    "الإلغائية",
    "أبوما",
    "كبيرة",
    "جنوب",
    "أمريكا",
    "القسم",
    "معدة",
    "مجتر",
    "حيث",
    "يتم",
    "الهظم",
    "عدائي",
    "وحاقد",
    "فوق",
    "الشيء",
    "أبور",
    "أحد",
    "لغات",
    "كاماربان",
    "معروفة",
    "الفم",
    "ببعد",
    "يقترب",
    "يتصل",
    "الشعب",
    "الأصلي",
    "قارة",
    "أستراليا",
    "مصل",
    "الإجهاض",
    "الجنين",
    "البشري",
    "الملفوظ",
    "قبل",
    "رحم",
    "الأم",
    "الإستدارة",
    "180",
    "العدول",
    "رأي",
    "حي",
    "يرزق",
    "المذكور",
    "أعلاه",
    "الأساقفة",
    "معدل",
    "سرعة",
    "الإتصال",
    "الأوتوماتيكي",
    "المجرى",
    "الضيق",
    "التمتمة",
    "الهراء",
    "تعويذة",
    "كلام",
    "مفهوم",
    "أداة",
    "تنعيم",
    "ورق",
    "الصنفرة",
    "بإبراهام",
    "البطريرك",
    "يوقض",
    "ينبه",
    "يثير",
    "يتآكل",
    "الأبروم",
    "حظ",
    "لدى",
    "الفراعنة",
    "مبزول",
    "مفتوح",
    "مثقوب",
    "الألفباء",
    "للتنازل",
    "تنح",
    "تنحى",
    "تنازل",
    "تخلى",
    "يتنحى",
    "التنحي",
    "المتنازل",
    "المتنحي",
    "البطن",
    "الجوف",
    "البطون",
    "بطني",
    "جوفي",
    "بطنيا",
    "جوفيا",
    "اخطف",
    "يخطف",
    "خطف",
    "الخطف",
    "الاختطاف",
    "إبعاد",
    "المختطف",
    "المبعد",
    "المختطفون",
    "نحو",
    "جانب",
    "الفراش",
    "طريح",
    "آبيل",
    "أبيلايان",
    "أبيلسون",
    "أبردين",
    "أبيرناثي",
    "الضلال",
    "شاذ",
    "زائغ",
    "ضال",
    "غاو",
    "بشذوذ",
    "بزيغ",
    "بضلال",
    "الاضطراب",
    "الانحراف",
    "الشذوذ",
    "الاضطرابات",
    "الزيغ",
    "يغري",
    "يحرض",
    "التحريض",
    "الإغواء",
    "يغوي",
    "حرض",
    "أغوى",
    "المحرض",
    "المغوي",
    "تعليق",
    "مؤقت",
    "توقيف",
    "لمدة",
    "محدودة",
    "معطل",
    "معلق",
    "يمقت",
    "يشمئز",
    "بشدة",
    "مقت",
    "اشمئز",
    "كره",
    "يكره",
    "المقت",
    "الكره",
    "الإشمئزاز",
    "ممقوت",
    "مشمئز",
    "ماقت",
    "بصورة",
    "مشمئزة",
    "الماقت",
    "الكاره",
    "المشمئز",
    "التزام",
    "حرص",
    "تقيد",
    "يقيم",
    "يثبت",
    "أقام",
    "التزم",
    "الباقي",
    "الملتزم",
    "يلتزم",
    "الإقامة",
    "الإلتزام",
    "دائم",
    "ثابت",
    "بثبات",
    "بالتزام",
    "أبيدجان",
    "الجارية",
    "الوصيفة",
    "أبيلين",
    "القدرات",
    "المؤهلات",
    "اقتدار",
    "إمكان",
    "ملكة",
    "مشفق",
    "مذل",
    "مقنط",
    "الحقارة",
    "بذل",
    "بوضاعة",
    "الإنحطاط",
    "الذل",
    "التبرؤ",
    "إنكار",
    "الفعلد",
    "السابق",
    "انبذ",
    "تبرأ",
    "نبذ",
    "فعل",
    "سابق",
    "ينبذ",
    "يتبرأ",
    "النبذ",
    "يختفي",
    "خلال",
    "التآكل",
    "التبخر",
    "ذاب",
    "تبخر",
    "إختفى",
    "يذوب",
    "يتبخر",
    "الذوبان",
    "الإختفاء",
    "الاستئصال",
    "الجراحة",
    "صيغة",
    "الجر",
    "بصيغة",
    "إستئصالي",
    "مشتعل",
    "ملتهب",
    "متقد",
    "قادر",
    "مؤهل",
    "القادر",
    "البارع",
    "الجسم",
    "العقل",
    "قوة",
    "الشباب",
    "الحيوية",
    "النشاط",
    "أكثر",
    "نشاطا",
    "حيوية",
    "الأكثر",
    "نشاط",
    "حالة",
    "إزهار",
    "متفتح",
    "مزدهر",
    "الوضوء",
    "الضوء",
    "إستعدادا",
    "للصلاة",
    "المسلمين",
    "ببراعة",
    "متمكن",
    "انكر",
    "حرم",
    "رفض",
    "ينكر",
    "يحرم",
    "الإنكار",
    "التخلى",
    "الزهد",
    "النكران",
    "التنسك",
    "المنكر",
    "الزاهد",
    "المتنسك",
    "أبنر",
    "طبيعي",
    "مختل",
    "العيوب",
    "التصرفات",
    "الغرابة",
    "التخلف",
    "العيب",
    "الخروج",
    "المعهود",
    "سوي",
    "شاذة",
    "السكان",
    "الأصليين",
    "لأستراليا",
    "بالداخل",
    "بجانب",
    "ظهر",
    "المركب",
    "قرب",
    "جزء",
    "مجموعة",
    "المنزل",
    "المسكن",
    "المقر",
    "المنازل",
    "المساكن",
    "البيوت",
    "ألغ",
    "يزيل",
    "إبطاله",
    "إلغاؤه",
    "القضاء",
    "عليه",
    "ألغى",
    "أزال",
    "قضى",
    "المبطل",
    "الملغي",
    "القاضي",
    "يقضي",
    "الإلغاء",
    "الإبطال",
    "مؤيد",
    "العنصرية",
    "المؤيدون",
    "للقضاء",
    "رديء",
    "بغيض",
    "مقيت",
    "اكره",
    "شائن",
    "البغض",
    "المكروه",
    "شيء",
    "شنعة",
    "استنكار",
    "فحشاء",
    "المكروهات",
    "الشناعة",
    "الفحشاء",
    "بدائي",
    "أولي",
    "أصلي",
    "بدائيا",
    "أصليا",
    "استراليا",
    "أثناء",
    "الولادة",
    "اجهض",
    "أحبط",
    "يجهض",
    "يضع",
    "حدا",
    "يوقف",
    "النمو",
    "أجهض",
    "أوقف",
    "وضع",
    "مجهض",
    "إيقاف",
    "الإسقاط",
    "إسقاط",
    "الجهيض",
    "إجهاضي",
    "بالإجهاض",
    "إخصائي",
    "المجهض",
    "فشل",
    "الخطة",
    "فاشل",
    "حابط",
    "مخفق",
    "ناقص",
    "مثمرة",
    "الإخفاق",
    "الفشل",
    "إتمام",
    "المهمة",
    "يتوقف",
    "أداء",
    "كثر",
    "فاض",
    "يسود",
    "يغزر",
    "يكثر",
    "ساد",
    "غزر",
    "الكثرة",
    "متوفر",
    "بكثرة",
    "غزير",
    "يوفر",
    "يغدق",
    "تقريبا",
    "حوالي",
    "حول",
    "زهاء",
    "لأعلى",
    "وراء",
    "صريح",
    "جهارا",
    "صراحة",
    "علانية",
    "سطحي",
    "سطح",
    "الارض",
    "موجود",
    "الذكر",
    "ينكشط",
    "يفرك",
    "بقوة",
    "يتوارى",
    "يضرب",
    "سحج",
    "برى",
    "كشط",
    "يبري",
    "انكشط",
    "حك",
    "ضرب",
    "كاشط",
    "الحاك",
    "الضارب",
    "يكشط",
    "يحك",
    "الكشط",
    "الحك",
    "الضرب",
    "البري",
    "السيد",
    "إبراهيم",
    "أبو",
    "الأنبياء",
    "أبرام",
    "أبرامز",
    "أبرامسون",
    "الجرح",
    "السحج",
    "الجروح",
    "التآكلات",
    "المزيل",
    "الكاشط",
    "كاشطة",
    "وقح",
    "بخشونة",
    "الخشونة",
    "المزيلات",
    "المنظفات",
    "الكواشط",
    "حرر",
    "إزال",
    "العقد",
    "نفس",
    "صرف",
    "تفريج",
    "نفساني",
    "فرج",
    "نفسيا",
    "تخليص",
    "التصريف",
    "النفسي",
    "التفريج",
    "تحرر",
    "عقدة",
    "نفسية",
    "تنفيس",
    "ينفس",
    "يتحرر",
    "جنبا",
    "جنب",
    "متمشيا",
    "واحد",
    "أوجز",
    "قصر",
    "يقصر",
    "فصل",
    "لخص",
    "مقصر",
    "ملخص",
    "موجز",
    "الملخص",
    "المقصر",
    "يوجز",
    "يلخص",
    "الإيجاز",
    "التلخيص",
    "بالخارج",
    "باتساع",
    "خارج",
    "البلاد",
    "كل",
    "اتجاه",
    "مساحة",
    "واسعة",
    "فسخ",
    "يفسخ",
    "الفسخ",
    "إنقطاع",
    "مفاجيء",
    "أبرونيا",
    "مفاجئ",
    "متقطع",
    "مرتبط",
    "شديد",
    "الإنحدار",
    "الانقطاع",
    "توقف",
    "فجأة",
    "عجل",
    "المفاجأة",
    "فظاظة",
    "مباغتة",
    "ترابط",
    "أربس",
    "منطقة",
    "جبلية",
    "وسط",
    "إيطاليا",
    "أبسالوم",
    "إبن",
    "الملك",
    "داود",
    "الدمل",
    "خراج",
    "مصاب",
    "بخراج",
    "فيه",
    "الدمامل",
    "الفصل",
    "العزل",
    "الخراج",
    "الإزالة",
    "يعزل",
    "يفصل",
    "القطع",
    "البتر",
    "الإحداثي",
    "السيني",
    "الإحداثيات",
    "اهرب",
    "هرب",
    "فر",
    "سرا",
    "إستخفى",
    "الإختباء",
    "الإخفاء",
    "المختفي",
    "الهارب",
    "الهروب",
    "خفية",
    "يهرب",
    "الغياب",
    "انعدام",
    "فقدان",
    "شرود",
    "غائب",
    "مفقود",
    "معدوم",
    "شارد",
    "الذهن",
    "المذهول",
    "مشغول",
    "البال",
    "بذهن",
    "ببال",
    "بذهول",
    "غاب",
    "الغائب",
    "المتغيب",
    "التغرب",
    "التغيب",
    "العمل",
    "الواجب",
    "الغائبون",
    "المتغيبون",
    "غيب",
    "النطق",
    "بالحكم",
    "والمتهم",
    "حاضر",
    "الحضور",
    "الظهور",
    "تائه",
    "غافل",
    "بتيه",
    "الشرود",
    "الذهول",
    "(السهو)",
    "الشارد",
    "يغيب",
    "لايظهر",
    "لايحضر",
    "أبسيت",
    "لغة",
    "للبرمجة",
    "الأبسنث",
    "شراب",
    "مسكر",
    "نبتة",
    "تستعمل",
    "كعقار",
    "طبي",
    "القبا",
    "ناتيء",
    "مزخرف",
    "مبنى",
    "نصف",
    "دائري",
    "يقف",
    "يغادر",
    "يقلع",
    "مطلق",
    "حتمي",
    "خالص",
    "كامل",
    "مطبق",
    "تماما",
    "إطلاقا",
    "حتما",
    "جازم",
    "الحقيقة",
    "المطلقة",
    "الغفران",
    "إعفاء",
    "حل",
    "واجب",
    "تبرئة",
    "الاستبداد",
    "حكم",
    "ديكتاتوري",
    "نظرية",
    "سياسية",
    "المستبد",
    "الطاغية",
    "المؤيد",
    "للإستبداد",
    "إعفائي",
    "غفراني",
    "للغفران",
    "للتبرئة",
    "العفو",
    "أعف",
    "برأ",
    "يبرئ",
    "يحلل",
    "غفر",
    "أعفى",
    "حلل",
    "المبرئ",
    "العافي",
    "الغافر",
    "يعفي",
    "يبريء",
    "يغفر",
    "الإعفاء",
    "التبرأة",
    "نشاز",
    "متضارب",
    "متنافر",
    "النغمات",
    "متعارض",
    "امتص",
    "تشرب",
    "يتشرب",
    "يمتص",
    "قابلية",
    "الامتصاص",
    "الامتصاصية",
    "التشربية",
    "للامتصاص",
    "للتشرب",
    "مستغرق",
    "منهمك",
    "بانشغال",
    "بانهماك",
    "التشرب",
    "ممتصية",
    "ممتص",
    "ماص",
    "نشاف",
    "الأعضاء",
    "الماصة",
    "القابلة",
    "للإمتصاص",
    "الممتص",
    "تقوم",
    "إمتصاص",
    "الإشعاع",
    "النووي",
    "المفاعل",
    "الذري",
    "مشوق",
    "مستحوذ",
    "للاهتمام",
    "قابلة",
    "شادة",
    "للإنتباه",
    "الانهماك",
    "استغراق",
    "استيعاب",
    "امتصاص",
    "انشغال",
    "يستوعب",
    "تكاليف",
    "قياس",
    "لمعدل",
    "انخفاض",
    "الكهرومغناطيسي",
    "عملية",
    "كيميائية",
    "بتخلل",
    "اخرى",
    "الإمتصاص",
    "الإستيعاب",
    "خاصية",
    "لإمتصاص",
    "صفة",
    "الكائن",
    "الخاصية",
    "للسوائل",
    "المستوعبة",
    "سرق",
    "شيئا",
    "وفر",
    "هاربا",
    "امتنع",
    "يمتنع",
    "ينفك",
    "تناول",
    "التصويت",
    "الممتنع",
    "محجم",
    "ممتنع",
    "ممسك",
    "الامتناع",
    "الإحجام",
    "متقشف",
    "متعفف",
    "متقلل",
    "متقشفة",
    "بتعفف",
    "التقشف",
    "التعفف",
    "إقلاع",
    "إمساك",
    "انقطاع",
    "زهد",
    "امتناعي",
    "إمساكي",
    "طهر",
    "يطهر",
    "ينظف",
    "المنظفة",
    "التنظيف",
    "التطهير",
    "تعفف",
    "عفة",
    "العفة",
    "بتقشف",
    "الخلاصة",
    "تجريدي",
    "مثالي",
    "مجرد",
    "مذهول",
    "بشرود",
    "ذهن",
    "التركيز",
    "ونسيان",
    "اخر",
    "القائم",
    "الإختصار",
    "الفكرة",
    "المجردة",
    "تجريد",
    "اختلاس",
    "تلخيص",
    "التجريدية",
    "عرض",
    "الشيئ",
    "بدون",
    "الإستدلال",
    "بأي",
    "مرجع",
    "متخصص",
    "الفن",
    "التجريدي",
    "الأفكار",
    "الاختلاس",
    "التجريد",
    "تجردي",
    "تلخيصي",
    "تجريدية",
    "وجهة",
    "التجرد",
    "المعلومات",
    "الوثائق",
    "يجرد",
    "يختلس",
    "صعب",
    "عميق",
    "عويص",
    "مبهم",
    "بصعوبة",
    "الصعوبة",
    "الوضوح",
    "الإستهلاك",
    "اندثار",
    "فناء",
    "إخماد",
    "إطفاء",
    "انقراض",
    "أحمق",
    "باطل",
    "لامعقول",
    "السخف",
    "الحماقة",
    "حماقة",
    "سخافة",
    "طبيعية",
    "بسخف",
    "بحماقة",
    "منافاة",
    "أبسيس",
    "برمجية",
    "قيد",
    "البناء",
    "الإنشاء",
    "الإرادة",
    "القوة",
    "فاقد",
    "أبونا",
    "القسيس",
    "عميد",
    "الحبشية",
    "غزارة",
    "وفرة",
    "وفير",
    "كثير",
    "بوفرة",
    "بغزارة",
    "لإساءة",
    "الإستعمال",
    "سوء",
    "الاستغلال",
    "ابتذال",
    "شتيمة",
    "يسيء",
    "استعمال",
    "يشتم",
    "عابث",
    "استغل",
    "أساء",
    "عبث",
    "شتم",
    "المستغل",
    "الشاتم",
    "العابث",
    "المستغلون",
    "العابثون",
    "الشاتمون",
    "الشتيمة",
    "العبث",
    "إساءة",
    "يخدع",
    "يسيئ",
    "الخديعة",
    "خطأ",
    "بذيء",
    "سفيه",
    "فاسد",
    "مؤذ",
    "بسفالة",
    "بأذى",
    "بفساد",
    "الوقاحة",
    "سفاهة",
    "بذاءة",
    "يتاخم",
    "يرتكز",
    "الأبوطيلون",
    "متاخمة",
    "محاذاة",
    "ملتقى",
    "شيئين",
    "الدعائم",
    "أطراف",
    "اﻷرض",
    "تاخم",
    "شارك",
    "الحدود",
    "المتاخم",
    "المشارك",
    "المتاخمة",
    "ملاصق",
    "محاذ",
    "بصوت",
    "كأزيز",
    "النحل",
    "فرق",
    "جهد",
    "بمقدار",
    "مئة",
    "مليون",
    "فولت",
    "يحسن",
    "لـ",
    "يضيف",
    "قاع",
    "سحيق",
    "لا",
    "قعر",
    "له",
    "سيئ",
    "الهاويات",
    "جهنم",
    "هوة",
    "جدا",
    "يقاس",
    "عمقه",
    "غوير",
    "اﻷعماق",
    "السحيقة",
    "الحبشة",
    "الحبشي",
    "الأثيوبي",
    "الاكاشا",
    "اللثة",
    "المعهد",
    "بيئة",
    "أكاديمية",
    "الجامعي",
    "البيئة",
    "الأكاديمية",
    "أكاديمي",
    "الشخص",
    "الأكاديمي",
    "أدبي",
    "جامعي",
    "دراسي",
    "مدرسي",
    "اللباس",
    "بالحياة",
    "بالدراسة",
    "عضو",
    "هيئة",
    "دراسية",
    "عضوية",
    "شرفية",
    "الحياة",
    "الأكاديميات",
    "المعاهد",
    "المؤسسات",
    "العلمية",
    "مؤسسة",
    "علمية",
    "معهد",
    "المقاطعات",
    "الفرنسية",
    "كندا",
    "المستوطنون",
    "الفرنسيون",
    "الأوائل",
    "أكاديا",
    "الكاجو",
    "أكاليف",
    "نوع",
    "الجيلي",
    "اللاذع",
    "الأقنثا",
    "شائك",
    "حاد",
    "أكانثي",
    "بـ",
    "يشبه",
    "ثعابين",
    "الإلابيد",
    "الأسترالية",
    "ورم",
    "حميد",
    "الأكانثرس",
    "ميناء",
    "أكابولكو",
    "منتجع",
    "الدود",
    "مبيد",
    "حشرات",
    "أكاريد",
    "القمل",
    "تضم",
    "الديدان",
    "والقمل",
    "الدودة",
    "الرهبة",
    "مثمر",
    "أكارس",
    "الشهي",
    "عديم",
    "الذيل",
    "الساق",
    "أكاد",
    "بلاد",
    "الرافدين",
    "القديمة",
    "التعلق",
    "بالجنس",
    "الذي",
    "عاش",
    "بابل",
    "الغزو",
    "الأشوري",
    "يقبل",
    "بعمل",
    "الآخرين",
    "يوافق",
    "ينصاع",
    "وافق",
    "إنصاع",
    "القبول",
    "الموافقة",
    "الإنصياع",
    "التلبية",
    "المنضم",
    "الملبي",
    "الموافق",
    "يلبي",
    "إيقاع",
    "متزايد",
    "تدريجيا",
    "للموسيقى",
    "سرع",
    "يسرع",
    "يعجل",
    "أسرع",
    "متسارع",
    "مسرع",
    "معجل",
    "الإسراع",
    "العجلة",
    "بعجلة",
    "السرعة",
    "تسريع",
    "إسراع",
    "تعجيل",
    "تسارعي",
    "تعجلي",
    "دواسة",
    "البنزين",
    "المسرع",
    "دواسات",
    "المعجلات",
    "المسرعات",
    "ميال",
    "زيادة",
    "المعجل",
    "التسارع",
    "لقياس",
    "يأجج",
    "به",
    "النار",
    "يتوهج",
    "يهيج",
    "إشعال",
    "التوهج",
    "اشتعال",
    "اللكنة",
    "لهجة",
    "نبرة",
    "شدد",
    "أكد",
    "ركز",
    "الإنتباه",
    "التشديد",
    "التأكيد",
    "تركيز",
    "المغني",
    "مغني",
    "اللكنات",
    "باللكنات",
    "تتناسب",
    "أبرز",
    "ينبر",
    "أظهر",
    "أهمية",
    "يبرز",
    "يظهر",
    "الإبراز",
    "إظهار",
    "تفخيم",
    "اقبل",
    "رضي",
    "يرضى",
    "الرضا",
    "الإستحسان",
    "مقبول",
    "مرض",
    "تقبل",
    "موافقة",
    "متقبل",
    "راغب",
    "الاستحسان",
    "حظوة",
    "قبول",
    "معترف",
    "مستحسن",
    "متفق",
    "متعارف",
    "القابل",
    "المستقبل",
    "القابلون",
    "الموافقون",
    "التحرر",
    "الدين",
    "الإرتباط",
    "الصفح",
    "الإستقبال",
    "الحظوة",
    "موافق",
    "متجه",
    "المستحسن",
    "المستحسنون",
    "المستقبلون",
    "يستلم",
    "يستحسن",
    "المدخل",
    "الوصول",
    "الإقتراب",
    "المساعدون",
    "إرتكاب",
    "الجريمة",
    "وصل",
    "حصل",
    "طريقة",
    "للدخول",
    "المداخل",
    "يصل",
    "القرب",
    "سهولة",
    "المنال",
    "إمكانية",
    "إليه",
    "منفتح",
    "الوصولية",
    "قريب",
    "سهلة",
    "للوصول",
    "اليه",
    "الحصول",
    "مدخل",
    "التبوء",
    "الإضافة",
    "الإعتلاء",
    "الزيادة",
    "إضافي",
    "زائد",
    "فائض",
    "ثانوي",
    "الإكسسوارات",
    "الإضافات",
    "الثانوية",
    "الإكسسوار",
    "ملحق",
    "علم",
    "الصرف",
    "الإعراب",
    "الحادث",
    "إصابة",
    "مصيبة",
    "طارئ",
    "مصادفة",
    "عرضي",
    "جوهري",
    "متعمدة",
    "عرضا",
    "العرضية",
    "المصادفة",
    "جوهرية",
    "بالصدفة",
    "عرضيا",
    "الحوادث",
    "العوارض",
    "الإصابات",
    "المصائب",
    "البلادة",
    "سبات",
    "خامل",
    "كسول",
    "الصقر",
    "الطيور",
    "الجارحة",
    "الصقور",
    "جمع",
    "صقر.",
    "النسور",
    "المتكلف",
    "تصنع",
    "يهلل",
    "تصفيق",
    "هتاف",
    "هلل",
    "صفق",
    "هتف",
    "المهلل",
    "المصفق",
    "الهاتف",
    "التصفيق",
    "الهتاف",
    "التهليل",
    "يصفق",
    "يهتف",
    "الترحيب",
    "التهليلات",
    "الترحيبات",
    "التصفيقات",
    "التكيف",
    "التأقلم",
    "أقلمة",
    "تأقلم",
    "يؤقلم",
    "تكيف",
    "يتأقلم",
    "يتكيف",
    "متأقلم",
    "متكيف",
    "المأقلم",
    "المكيف",
    "طرق",
    "ذات",
    "ميل",
    "الأعلى.",
    "المؤدية",
    "أعلى",
    "الجبل",
    "ارتفاع",
    "صعود",
    "منحدر",
    "متحدر",
    "صعدا",
    "متصعد",
    "يجمع",
    "بعض",
    "التقدير",
    "احتضان",
    "معانقة",
    "التقديرات",
    "الإحتضان",
    "المعانقة",
    "أكوماك",
    "استوعب",
    "آوى",
    "كيف",
    "يكيف",
    "يلائم",
    "لائم",
    "الاستيعاب",
    "الملائمة",
    "لطيف",
    "مجامل",
    "بلطف",
    "بمجاملة",
    "بتكيف",
    "توفيق",
    "ملاءمة",
    "ملائم",
    "مريح",
    "التكييف",
    "الملاءمة",
    "التوافق",
    "صاحب",
    "مصحوب",
    "المرافق",
    "المصاحب",
    "يصاحب",
    "يرافق",
    "الموسيقى",
    "تلازم",
    "مرافقة",
    "مصاحب",
    "المرافقة",
    "العازف",
    "العازفون",
    "المصاحبون",
    "رافق",
    "لازم",
    "يلازم",
    "المصاحبة",
    "الملازمة",
    "عازف",
    "الموسيقي",
    "وعادة",
    "يكون",
    "آلة",
    "البيانو",
    "الشريك",
    "المساهم",
    "تنفيذ",
    "شريرة",
    "الشركاء",
    "المساهمون",
    "أنجز",
    "ينجز",
    "محقق",
    "الممكن",
    "إنجازه",
    "تنفيذه",
    "بارع",
    "متمم",
    "منجز",
    "باتقان",
    "المنجز",
    "المتمم",
    "المنفذ",
    "الإنجاز",
    "الإنجازات",
    "الحساب",
    "الفاتورة",
    "المستحقات",
    "الاتفاق",
    "الموافقات",
    "بإنسجام",
    "توافق",
    "المتوافق",
    "المتوافقون",
    "وفقا",
    "لذلك",
    "الأكورديون",
    "الأكورديونات",
    "إتفاقية",
    "الاتفاقيات",
    "فاتح",
    "المفاتحة",
    "يفاتح",
    "طبيب",
    "نساء",
    "توليد",
    "المرأة",
    "المتمرسة",
    "أمور",
    "أكوند",
    "التقرير",
    "المسؤوليات",
    "المسؤولية",
    "مسئول",
    "للتفسير",
    "المحاسبة",
    "المحاسب",
    "المحاسبون",
    "أكونتيد",
    "أكونتر",
    "التقارير",
    "الإتحاد",
    "العلاقة",
    "يلاطف",
    "يجهز",
    "جهز",
    "التجهيز",
    "التجهيزات",
    "المجهزون",
    "الملابس",
    "تحمل",
    "ولاتلبس",
    "كالمعطف",
    "الإضافي",
    "يخضع",
    "يروض",
    "أكرا",
    "فوض",
    "اعتمد",
    "الاعتماد",
    "معتمد",
    "التفويض",
    "يفوض",
    "المستمر",
    "ملتحم",
    "التراكم",
    "تراكمي",
    "التراكمات",
    "يحصل",
    "عد",
    "عاد",
    "الأموال",
    "المتراكمة",
    "يعود",
    "العيادة",
    "حدوث",
    "مبرمجي",
    "السي",
    "والسي",
    "بلس",
    "الاستلقاء",
    "الكنبة",
    "تثقف",
    "يتثقف",
    "التثقف",
    "التبادل",
    "الثقافي",
    "ثقافي",
    "يستلقي",
    "مستلقي",
    "تراكم",
    "يتراكم",
    "التجميع",
    "متراكم",
    "التراكمية",
    "ذاكرة",
    "الأرقام",
    "ذاكرات",
    "الدقة",
    "دقيق",
    "بدقة",
    "لعين",
    "اللعنة",
    "عرضة",
    "لتلقي",
    "الإتهام",
    "التهمة",
    "الاتهام",
    "الاتهامات",
    "منصوب",
    "النصب",
    "متهم",
    "اتهامي",
    "اتهم",
    "المتهم",
    "المتهمون",
    "يتهم",
    "متهما",
    "إتهامي",
    "يوجه",
    "إتهام",
    "عود",
    "مألوف",
    "التعود",
    "التعويد",
    "الآس",
    "تفوق",
    "الفتور",
    "الروحي",
    "الملل",
    "أسيتناس",
    "التجمعات",
    "الفظيعة",
    "خلوي",
    "مركزي",
    "الرخويات",
    "كالمحار",
    "والقواقع",
    "طائفة",
    "مسيحية",
    "زعيم",
    "رأس",
    "قناة",
    "مائية",
    "للري",
    "إسر",
    "أسيريسا",
    "ومدبب",
    "حمضي",
    "المذاق",
    "ساخط",
    "أثار",
    "الإثارة",
    "لاذع",
    "الحدة",
    "حمض",
    "الأسيريك",
    "فاكهة",
    "الأسيرولا",
    "عنقودي",
    "الآسات",
    "التجويف",
    "الفنجان",
    "عظمة",
    "الفخذ",
    "الأسيتال",
    "أي",
    "مركب",
    "عضوي",
    "تشكل",
    "بإضافة",
    "الكحول",
    "الأسيتالديهيد",
    "سائل",
    "للذوبان",
    "متطاير",
    "اللون",
    "أسيتامينوفين",
    "مسكن",
    "للألم",
    "أسيتانيلايد",
    "يستخدم",
    "كمسكن",
    "أسيتاريوس",
    "السلطات",
    "الآستات",
    "اتحد",
    "بحمض",
    "الخل",
    "خلي",
    "خلل",
    "يخلل",
    "التخليل",
    "بالجليسرين",
    "أسيتول",
    "الأسيتون",
    "كمذاق",
    "المجموعة",
    "المتكاملة",
    "لحمض",
    "مشتق",
    "كولين",
    "أبيض",
    "شفاف",
    "الأسيتيلين",
    "وظيفة",
    "الاتصالات",
    "آتش",
    "إسم",
    "يطلق",
    "العديد",
    "النباتات",
    "لأحد",
    "الأقسام",
    "الأربعة",
    "لليونانيين",
    "القدماء",
    "الهمجية",
    "الوجع",
    "آلم",
    "الفاكهة",
    "الجافة",
    "الصغيرة",
    "بالفاكهة",
    "أكرون",
    "نهر",
    ".",
    "الأساطير",
    "اليونانية",
    "غامق",
    "كئيب",
    "الأوجاع",
    "أتشير",
    "متألم",
    "للإنجاز",
    "حقق",
    "الناجح",
    "الناجحون",
    "يحقق",
    "التحقيق",
    "أتشيل",
    "أتشيلي",
    "منيع",
    "أكيليز",
    "أتشيمينيز",
    "الألم",
    "الآلام",
    "الإيلام",
    "مؤلم",
    "أتشيوت",
    "بذور",
    "الأنوتو",
    "أتشيرا",
    "جذور",
    "صالحة",
    "للأكل",
    "تشوهات",
    "هيكلية",
    "موروثة",
    "أتشراس",
    "أشجار",
    "استوائية",
    "النقص",
    "للون",
    "الجلد",
    "لوني",
    "لونيا",
    "الأكروماتية",
    "لون",
    "أكروميسين",
    "مضاد",
    "حيوي",
    "النجم",
    "يسطع",
    "الليل",
    "ويغيب",
    "النهار",
    "طويل",
    "مدبب",
    "الحمض",
    "مقاوم",
    "للأحماض",
    "أسيداليا",
    "اله",
    "الحب",
    "والجنس.",
    "التحمض",
    "تحول",
    "المحمض",
    "يتحول",
    "الحموضة",
    "الحي",
    "يزدهر",
    "حمضية",
    "الحماض",
    "الأحماض",
    "يحمض",
    "التفوق",
    "أسينوس",
    "أ",
    "سيس",
    "يقر",
    "مدفع",
    "للطائرات",
    "أكي",
    "أكيرمان",
    "أكلي",
    "أقر",
    "مشكور",
    "إقراري",
    "الاعتراف",
    "الاعترافات",
    "معترفون",
    "الإقرار",
    "قائمة",
    "تحكم",
    "الدخول",
    "ميلان",
    "القمة",
    "حب",
    "أكنيد",
    "نقطة",
    "معزولة",
    "جاهز",
    "للإنطلاق",
    "مريض",
    "بالحمى",
    "العلاجات",
    "أكوم",
    "قديم",
    "بي",
    "إم",
    "705",
    "أكونكاجوا",
    "جبل",
    "الكرة",
    "الغربي",
    "البيش",
    "الأقونيطن",
    "سام",
    "المنشط",
    "الجوزة",
    "الإوزة",
    "امتلأ",
    "بالجوز",
    "الجوز",
    "اللجنة",
    "للدراسات",
    "العثمانية",
    "التصديق",
    "وجود",
    "العالم",
    "الأبدي",
    "التعبير",
    "التهديد",
    "السخط",
    "متوعد",
    "صوتي",
    "سمعيا",
    "مهندس",
    "الصوت",
    "سمعيات",
    "صوتيضوئي",
    "سمعيكهربائي",
    "إفريقيا",
    "الكاريبي",
    "الهادئ",
    "استكشف",
    "المعرفة",
    "المعارف",
    "الاستكشاف",
    "يستكشف",
    "يكتسب",
    "مذعن",
    "بإذعان",
    "يهديء",
    "الامتلاك",
    "احصل",
    "الاكتساب",
    "المحصل",
    "مولع",
    "بالاقتناء",
    "بغريزة",
    "الاقتناء",
    "الكسبية",
    "برئ",
    "البراءة",
    "أحكام",
    "التبرئة",
    "مستندات",
    "تسديد",
    "أككوتر",
    "كلي",
    "جزئي",
    "للجمجمة",
    "المغالاة",
    "الإفراط",
    "الفدان",
    "كمية",
    "الماء",
    "سيغطي",
    "فدان",
    "الأفدنة",
    "الجراد",
    "القسوة",
    "الشديدة",
    "لاذعة",
    "الشدة",
    "بفظاظة",
    "ضفدع",
    "الحيوانات",
    "الدنيا",
    "لاتملك",
    "جهاز",
    "عصبي",
    "حرارة",
    "مقرونة",
    "بحدة",
    "شفهي",
    "البهلوانية",
    "البهلوان",
    "بهلواني",
    "بهلوانية",
    "الألعاب",
    "البهلوانات",
    "بالرأس",
    "الجمجمة",
    "مزهر",
    "بالنبات",
    "أكرولين",
    "تمثال",
    "حجري",
    "تضخم",
    "عضام",
    "اليد",
    "والقدمين",
    "متضخم",
    "عادة",
    "متعلقة",
    "بعظام",
    "الأطراف",
    "بعظمة",
    "الكتف",
    "قمري",
    "مسائي",
    "اختصاري",
    "قمي",
    "التعاقب",
    "خوف",
    "المرتفعات",
    "رهبة",
    "الأكروبولس",
    "متوسع",
    "كلمات",
    "متساوية",
    "الطول",
    "مرتبة",
    "بحيث",
    "تكون",
    "قراءتها",
    "عموديا",
    "مطابقة",
    "لقرائتها",
    "أفقيا",
    "شكل",
    "ركيزة",
    "توضع",
    "بأعلى",
    "وأسفل",
    "الأعمدة",
    "النبض",
    "ضعفه",
    "أكريليت",
    "أكريلي",
    "أكريلونتريل",
    "(نيتريل",
    "مشبع)",
    "عنصر",
    "خدمة",
    "التحكم",
    "بالترابط",
    "أكتا",
    "التمثيلية",
    "تمثيل",
    "أكتين",
    "عمل",
    "هرمون",
    "Adrenocorticotropic",
    "(محفز",
    "للغدة",
    "الكظرية)",
    "شعاع",
    "أشعة",
    "شعاعي",
    "شقار",
    "شقائق",
    "النعمان",
    "وأشباهها",
    "أكتيني",
    "أكتينايد",
    "الأكتينيديا",
    "(كرمة",
    "آسيوية",
    "خشبية",
    "صغيرة)",
    "الأكتينية",
    "(قدرة",
    "الطاقة",
    "المشعة",
    "إحداث",
    "التغييرات",
    "الكيميائية)",
    "الأكتينيوم",
    "أكتينولايت",
    "الشمس",
    "مقاييس",
    "الحارش",
    "(طفيلي",
    "بكتيري)",
    "بكتيريا",
    "خييطية",
    "الخييطية",
    "أكتينوميسين",
    "(مضاد",
    "حيوي)",
    "العلاج",
    "بالإشعاع",
    "موجب",
    "لإقامة",
    "دعوى",
    "قضائية",
    "الأفعال",
    "مشع",
    "معركة",
    "أكتيوم",
    "البحرية",
    "للنتشيط",
    "نشط",
    "ينشط",
    "التنشيط",
    "المنشطات",
    "الصيغة",
    "المعلوم",
    "بنشاط",
    "الفعالية",
    "أكتف",
    "إكس",
    "الناشط",
    "ناشط",
    "فعالي",
    "الناشطون",
    "الأنشطة",
    "ميوسين",
    "أكتون",
    "الممثل",
    "الممثلون",
    "الممثلة",
    "الممثلات",
    "الأعمال",
    "فعلي",
    "تحقيقي",
    "الحقائق",
    "الإدراك",
    "التحقيقات",
    "الحقيقيات",
    "بحسابات",
    "التأمين",
    "ناحية",
    "الحسابات",
    "التأمينية",
    "خبراء",
    "الخبير",
    "بشؤون",
    "شغل",
    "يشغل",
    "التشغيل",
    "التنفيذ",
    "المحرك",
    "المحركات",
    "موقف",
    "أكتس",
    "(مقياس",
    "روماني)",
    "يجعله",
    "يشحذ",
    "الشحذ",
    "السن",
    "البراعة",
    "أكيولار",
    "(الاسم",
    "التجاري",
    "لدواء",
    "مثبط)",
    "شوائك",
    "لوادغ",
    "شوكي",
    "لادغ",
    "رؤوس",
    "مدببة",
    "(نحل",
    "مثلا)",
    "(رأس",
    "النحلة",
    "الفطنة",
    "مستدق",
    "الطرف",
    "(كورقة",
    "النبات)",
    "الحاد",
    "بالإبر",
    "علاج",
    "الوخز",
    "بالأبر",
    "أكيوشنيت",
    "قاسي",
    "الزاوية",
    "أشد",
    "الأشد",
    "أسيكليك",
    "أسيكليكالي",
    "أسيكلوفر",
    "للفيروس)",
    "أسيل",
    "الإعلان",
    "خاص",
    "مقارب",
    "شبيه",
    "عفوي",
    "ارتجالي",
    "أدا",
    "تفرع",
    "أداباس",
    "(قاعدة",
    "بيانات",
    "علائقية)",
    "يقود",
    "يجبر",
    "أداد",
    "(إله",
    "الطقس",
    "البابليين)",
    "المثل",
    "الأمثال",
    "المقطوعة",
    "الموسيقية",
    "المقطوعات",
    "أداه",
    "أدير",
    "الخلايا",
    "العصبية",
    "الطولية",
    "التكيفية",
    "آدم",
    "مرن",
    "التعديل",
    "عنيد",
    "أدمنتي",
    "صلب",
    "بعناد",
    "آدمي",
    "بشري",
    "رجل",
    "سلالة",
    "أدامو",
    "(نظام",
    "إدارة",
    "بيانات)",
    "أبناء",
    "آدامسون",
    "يرقص",
    "البابلية",
    "بابلي",
    "مترف",
    "تحويلي",
    "التحويلات",
    "تكيفي",
    "المحول",
    "المحولات",
    "التكيفات",
    "آذار",
    "مارس",
    "قماش",
    "قطني",
    "هندي",
    "يفزع",
    "يوقظ",
    "مجاور",
    "محور",
    "أداي",
    "الموجات",
    "الأحادية",
    "بروتوكول",
    "بالاتصالات",
    "البيانية",
    "أضف",
    "الملحق",
    "صفحة",
    "جديدة",
    "اضافةضبط",
    "المهاة",
    "(بقرة",
    "وحش",
    "كبيرة)",
    "مستودع",
    "التطويرية",
    "المضاف",
    "الأفعى",
    "الأفاعي",
    "مدير",
    "المنطقة",
    "للتحقيقات",
    "الجمع",
    "المدمن",
    "جعل",
    "مدمنا",
    "الإدمان",
    "التكرس",
    "مسبب",
    "بادمان",
    "المدمنون",
    "إديسون",
    "مضاف",
    "مجموع",
    "بالإضافة",
    "شوش",
    "شخص",
    "غبي",
    "ومرتبك",
    "يشوش",
    "التشويش",
    "موضوع",
    "لظهر",
    "(كالشعارات)",
    "العنوان",
    "العنونة",
    "للتخاطب",
    "دفتر",
    "العناوين",
    "خاطب",
    "مرسل",
    "مرسلون",
    "المعنون",
    "المعنونون",
    "المخاطبة",
    "عنوان",
    "ماكنة",
    "قدم",
    "مقرب",
    "أدسير",
    "يقدم",
    "تقريبه",
    "التقديم",
    "التقريب",
    "أدكتايف",
    "المقرب",
    "يقرب",
    "مشروب",
    "فواكه",
    "محلى",
    "مخفف",
    "نبيل",
    "أصل",
    "شهير",
    "أديليد",
    "أديل",
    "أديليا",
    "بطاريق",
    "متوسطة",
    "الحجم",
    "أديلين",
    "أدل",
    "أدلفي",
    "أخوة",
    "عدن",
    "غدة",
    "أدينا",
    "أديناين",
    "إلتهاب",
    "أدينوكارسينوما",
    "(مرض",
    "خبيث",
    "ينشأ",
    "نسيج",
    "الغدة)",
    "ليمفاوي",
    "غددي",
    "اللحمية",
    "الورم",
    "الحميد",
    "ورميغدي",
    "بورم",
    "غدي",
    "الغدة",
    "اللمفاوية",
    "يحتوي",
    "غدد",
    "بالغدد",
    "الغدد",
    "الأدينوساين",
    "دهن",
    "حيواني",
    "شحم",
    "المهارة",
    "الخبراء",
    "الكفاءات",
    "الكفاءة",
    "كافي",
    "الكفاية",
    "متأثر",
    "دمج",
    "انضم",
    "انتسب",
    "الهرمون",
    "المضاد",
    "لإدرار",
    "البول",
    "متمسك",
    "التصق",
    "تماسك",
    "يخلص",
    "الولاء",
    "أخلص",
    "بالولاء",
    "الإلتصاق",
    "الإخلاص",
    "تابع",
    "نصير",
    "ملتحق",
    "ملتصق",
    "مؤيدة",
    "شيعة",
    "أتباع",
    "النصير",
    "التابع",
    "يلتصق",
    "يناصر",
    "يؤيد",
    "التصاق",
    "التأييد",
    "النصرة",
    "التحام",
    "تلاصق",
    "موالاة",
    "الصمغ",
    "دبق",
    "لاصق",
    "لزج",
    "لزوجة",
    "اللاصقة",
    "اللزجة",
    "عكس",
    "البيروقراطية",
    "هرمية",
    "وظيفية",
    "الكل",
    "بمقدوره",
    "إتخاذ",
    "قرار",
    "حث",
    "انصح",
    "يحذر",
    "يحض",
    "الحرارة",
    "متغيرة",
    "معها",
    "يخص",
    "المسائل",
    "بالإيمان",
    "والعرف",
    "اللا",
    "مبالاة",
    "خمول",
    "تحيز",
    "الوداع",
    "وداعا",
    "السلامة",
    "أديجي",
    "الإيطالي",
    "نظم",
    "صف",
    "رتب",
    "أدينو",
    "وداع",
    "سمني",
    "دهني",
    "الدهون",
    "الشمعية",
    "الناتجة",
    "إختلاط",
    "الشحم",
    "عضلات",
    "الميت",
    "كيس",
    "دسم",
    "الحيوان",
    "السمنة",
    "الدهنية",
    "أدرندك",
    "السياحية",
    "المنجم",
    "الصفة",
    "تجاور",
    "تماس",
    "التجاور",
    "التماس",
    "المحاذاة",
    "محاذي",
    "متاخم",
    "يلحق",
    "يظم",
    "وصفي",
    "نعتي",
    "كصفة",
    "كنعت",
    "النعت",
    "الصفات",
    "النعوت",
    "جاور",
    "حاذى",
    "يجاور",
    "يحاذي",
    "يضم",
    "ضم",
    "الجوار",
    "الضم",
    "انفض",
    "أرجأ",
    "يؤجل",
    "يرجئ",
    "أجل",
    "الانفضاض",
    "التأجيل",
    "الإرجاء",
    "إرجاء",
    "تأجيل",
    "تأخير",
    "الإنهاء",
    "ينفض",
    "ينهي",
    "يحكم",
    "بالقضاء",
    "ينطق",
    "نطق",
    "القضية",
    "الحكم",
    "التحكيم",
    "محاكمة",
    "المحاكمة",
    "قضائي",
    "المحكم",
    "يساعد",
    "الملاحق",
    "المساعدات",
    "استحلف",
    "يستحلف",
    "يناشد",
    "ناشد",
    "الاستحلاف",
    "المناشدة",
    "ضبط",
    "عدل",
    "ينظم",
    "التضبيط",
    "للضبط",
    "للتعديل",
    "للتكيف",
    "الضابط",
    "المعدل",
    "الضباط",
    "المعدلون",
    "المكيفون",
    "تعديلي",
    "ضبطي",
    "التعديلات",
    "التضبيطات",
    "المضبط",
    "مكيفون",
    "يعدل",
    "يضبط",
    "الإداري",
    "الإداريون",
    "أدكينز",
    "أدلر",
    "وكيل",
    "الإعلانات",
    "المعلن",
    "أدر",
    "يدبر",
    "يدير",
    "يعاقب",
    "أدار",
    "الإدارة",
    "الإدارات",
    "للإدارة",
    "تول",
    "تولى",
    "يتولى",
    "التولي",
    "إداري",
    "إداريا",
    "المدير",
    "المديرون",
    "المديرة",
    "أدمينت",
    "جدير",
    "بالإعجاب",
    "الجدارة",
    "قائد",
    "أمير",
    "أميرال",
    "أميرة",
    "القادة",
    "البحريات",
    "قوات",
    "الإعجاب",
    "أعجبت",
    "أعجب",
    "المعجب",
    "المعجبون",
    "معجب",
    "بإعجاب",
    "مصدق",
    "مصداقية",
    "اعترف",
    "يعترف",
    "باعتراف",
    "الجميع",
    "اخلط",
    "خلط",
    "يخلط",
    "الخلط",
    "عاتب",
    "الناصح",
    "يعاتب",
    "المعاتبة",
    "عتابي",
    "اللوم",
    "العتاب",
    "أدو",
    "اللبنة",
    "المراهقة",
    "المراهق",
    "مراهق",
    "المراهقون",
    "أدولف",
    "أدولفس",
    "أدونيس",
    "تبن",
    "قدرات",
    "التبني",
    "إقامة",
    "المتبني",
    "المتبنون",
    "بالتبني",
    "يتبنى",
    "الجاذبية",
    "جذاب",
    "العشق",
    "اعشق",
    "عشق",
    "أدورر",
    "يعشق",
    "زين",
    "أدورنر",
    "التزيين",
    "الزينة",
    "تزيين",
    "زخرفة",
    "حلي",
    "يزين",
    "الكظرية",
    "أدرينالاين",
    "كظريا",
    "أدرايان",
    "أدرياتيكي",
    "أدرين",
    "هدي",
    "الدهاء",
    "كثف",
    "مكثف",
    "التكثيف",
    "يكثف",
    "الادمصاص",
    "ادمصاصي",
    "بادمصاصية",
    "تملق",
    "يتملق",
    "التملق",
    "المتملق",
    "متملق",
    "البالغ",
    "الغاشة",
    "غش",
    "يغش",
    "الغش",
    "الغشاش",
    "الغشاشون",
    "الزاني",
    "الزناة",
    "الزانية",
    "الزنا",
    "فاسق",
    "بالزنا",
    "البلوغ",
    "بالغ",
    "سن",
    "الرشد",
    "البالغون",
    "أشر",
    "أشار",
    "يشير",
    "الإشارة",
    "إشاري",
    "بإشارة",
    "التقدم",
    "تقدم",
    "المتقدمون",
    "الميزة",
    "مفاد",
    "مفيد",
    "الفائدة",
    "الميزات",
    "الإفادة",
    "إفادي",
    "الحلول",
    "السبتي",
    "مجيئي",
    "بلدي",
    "المغامرة",
    "غامر",
    "المغامر",
    "المغامرون",
    "المغامرات",
    "مغامر",
    "مقدام",
    "بمخاطرة",
    "الظرف",
    "كظرف",
    "الظروف",
    "الأعداء",
    "معادي",
    "عادى",
    "معاد",
    "الخصومة",
    "يخاصم",
    "مخاصمة",
    "المشكلات",
    "المحنة",
    "مشار",
    "الإهتمام",
    "إهتمامات",
    "منتبه",
    "بيقظة",
    "أعلن",
    "المعلنون",
    "يعلن",
    "إعلان",
    "النصيحة",
    "الصحة",
    "باستحسان",
    "نصح",
    "بروية",
    "المنصوح",
    "المنصوحون",
    "التفكير",
    "المستشار",
    "المستشارون",
    "ينصح",
    "النصح",
    "الإنذارات",
    "استشاري",
    "دافع",
    "تأييدي",
    "المحامي",
    "دفاعي",
    "القدوم",
    "إيجي",
    "إيجيس",
    "إينيس",
    "إينييد",
    "إيولايان",
    "عولس",
    "الدهر",
    "إيرا",
    "أشبع",
    "بالغاز",
    "يشبع",
    "إشباع",
    "للإشباع",
    "بالهواء",
    "إيرجر",
    "الهوائي",
    "هوائيا",
    "الهوائيات",
    "طيراني",
    "سمعي",
    "هوائي",
    "جرثوم",
    "أكسجيني",
    "التمارين",
    "الرياضية",
    "المطار",
    "ديناميكي",
    "ديناميكية",
    "هوائية",
    "ديناميكا",
    "الهواء",
    "مسببة",
    "للغاز",
    "الرسالة",
    "الجوية",
    "ميزان",
    "الطيار",
    "طيران",
    "بالطيران",
    "الطيران",
    "الطيارون",
    "الطائرة",
    "الطائرات",
    "البخاخة",
    "انشر",
    "نشر",
    "البخاخات",
    "صناعة",
    "الفضاء",
    "إسكلس",
    "الذواقة",
    "جمالي",
    "جماليا",
    "الجمال",
    "الود",
    "اجتماعي",
    "اللطافة",
    "بعذوبة",
    "الشئون",
    "أثر",
    "التكلف",
    "متكلف",
    "بتكلف",
    "التأثر",
    "المؤثر",
    "التأثير",
    "علي",
    "مؤثر",
    "المحبة",
    "حنون",
    "بمودة",
    "الحنان",
    "عاطفي",
    "يؤثر",
    "ناقل",
    "اخطب",
    "خطب",
    "يخطب",
    "الخطبة",
    "الشهادة",
    "الموثقة",
    "إقرار",
    "وتعهد",
    "مكتوب",
    "بأيمان",
    "غليضة",
    "(أداء",
    "اليمين)",
    "الشهادات",
    "الفرع",
    "الفروع",
    "الدمج",
    "الانتماء",
    "الانتماءات",
    "القريب",
    "التشابهات",
    "الانجذاب",
    "تعهد",
    "التأكيدات",
    "مثبت",
    "الإيجاب",
    "إيجابي",
    "التعهد",
    "يتعهد",
    "اللاحقة",
    "ألصق",
    "اللاحقات",
    "الإلصاق",
    "الإلهام",
    "ابتلي",
    "ابتلى",
    "الابتلاء",
    "مأساوي",
    "يبتلي",
    "الغنى",
    "غني",
    "متاح",
    "التحمل",
    "شجر",
    "التشجير",
    "الشجار",
    "أجر",
    "التأجير",
    "الساكن",
    "الأصوات",
    "الساكنة",
    "تسكين",
    "الفزع",
    "الأفغاني",
    "الأفغان",
    "أفغانستان",
    "الهاو",
    "الهواة",
    "الميدان",
    "مشتعلا",
    "طافيا",
    "وساق",
    "فيما",
    "مضى",
    "سالف",
    "المذكورون",
    "مدبر",
    "خائف",
    "جديد",
    "الإفريقي",
    "الأفارقة",
    "أفريقيا",
    "الأفرو",
    "الحبل",
    "السري",
    "غرفة",
    "احتراق",
    "مساعدة",
    "النصف",
    "الأخير",
    "السفية",
    "رجعي",
    "آثار",
    "رجعية",
    "الذكرى",
    "الذكريات",
    "مسخن",
    "الصور",
    "الآخرة",
    "النتيجة",
    "الأثر",
    "أخير",
    "الظهر",
    "الحلوى",
    "الكولونيا",
    "التوابع",
    "الطعم",
    "ذلك",
    "الختامية",
    "ثانية",
    "ضد",
    "أجاميمنون",
    "مندهشا",
    "هلامية",
    "طحلبية",
    "مشروم",
    "المثخنة",
    "العقيق",
    "أجاثا",
    "الأغاف",
    "العمر",
    "كبر",
    "بما",
    "الكبار",
    "الشيخوخة",
    "أجي",
    "الجيل",
    "الكبر",
    "الوكالات",
    "الوكالة",
    "جدول",
    "جداول",
    "الوكيل",
    "وكالي",
    "الوكلاء",
    "معمر",
    "معمرون",
    "الأعمار",
    "زراعية",
    "الكتلة",
    "تجمع",
    "الكتل",
    "التجمع",
    "مائل",
    "للتجميع",
    "التصقت",
    "اشتقاقي",
    "الملزن",
    "التفخيم",
    "يكبر",
    "التكبير",
    "صعد",
    "يصعد",
    "التصعيد",
    "متفاقم",
    "المجموع",
    "جماعي",
    "التجمعية",
    "المجاميع",
    "إجمالي",
    "الإعتداء",
    "العنف",
    "عدواني",
    "بعدوانية",
    "العدوانية",
    "المعتد",
    "المعتدون",
    "حزن",
    "مهموم",
    "بظلم",
    "يحزن",
    "الإحزان",
    "رشيق",
    "برشاقة",
    "الرشاقة",
    "العمولة",
    "الصرافة",
    "طالب",
    "بارتعاش",
    "يطالب",
    "المطالبة",
    "الانفعال",
    "الثورات",
    "أجيتاتيف",
    "المشاغب",
    "المشاغبون",
    "مضيء",
    "متوهج",
    "أجنيس",
    "أجنو",
    "مستعار",
    "الملحد",
    "الإيمان",
    "الشك",
    "بوجود",
    "الملحدون",
    "منذ",
    "متلهف",
    "رحل",
    "كروب",
    "يناضل",
    "ناضل",
    "مناضل",
    "مناضلون",
    "مناضلة",
    "بنضال",
    "الكرب",
    "الأماكن",
    "المكشوفة",
    "الأغوطي",
    "اتفق",
    "مناسب",
    "الاتفاقية",
    "موافقون",
    "يتفق",
    "أجري",
    "تجارة",
    "المحاصيل",
    "تجارات",
    "أجريكولا",
    "المهندسون",
    "الزراعيون",
    "بالزراعة",
    "الزراعة",
    "المزارع",
    "الغافث",
    "ملامس",
    "القاع",
    "الحمى",
    "أجواي",
    "آه",
    "المقدمة",
    "عهد",
    "نحنحة",
    "أحمدأباد",
    "أحمد",
    "آباد",
    "صيحة",
    "البحارة",
    "المعونة",
    "عايدة",
    "ساعد",
    "أيدر",
    "المساعدة",
    "أيكين",
    "امرض",
    "الإيلنطس",
    "أيل",
    "أيلين",
    "المؤخرة",
    "المؤخرات",
    "المرض",
    "الأمراض",
    "يمرض",
    "الهدف",
    "وجه",
    "الهداف",
    "الهدافون",
    "التوجيه",
    "هدف",
    "عشوائيا",
    "التشتت",
    "الأهداف",
    "فرد",
    "الأينو",
    "جماعة",
    "النازحين",
    "الأرخبيل",
    "الياباني",
    "أفراد",
    "البريد",
    "الجوي",
    "المضخة",
    "بدوار",
    "الجو",
    "المخدة",
    "الهوائية",
    "أكياس",
    "صاعد",
    "رش",
    "أسقط",
    "جويا",
    "يسقط",
    "تعرض",
    "للهواء",
    "كلب",
    "الصيد",
    "جوي",
    "هيكل",
    "لتجفيف",
    "تهوئة",
    "جويون",
    "هياكل",
    "الثمن",
    "المطارات",
    "جريان",
    "حامل",
    "حملة",
    "الغبي",
    "متجدد",
    "المتجدد",
    "بعبث",
    "التهوية",
    "النزهة",
    "النزهات",
    "خال",
    "الخواء",
    "نقل",
    "الجسرات",
    "شركات",
    "الضغط",
    "غرف",
    "المضغوط",
    "مطار",
    "مدني",
    "البث",
    "المظاهر",
    "المنطاد",
    "المناطيد",
    "دوار",
    "دوارات",
    "المجال",
    "السرعات",
    "المهبط",
    "المهابط",
    "هاتف",
    "محكم",
    "السد",
    "إحكام",
    "الشد",
    "الحنجرة",
    "ممر",
    "الحناجر",
    "ممرات",
    "خطوط",
    "جوية",
    "الصلاحية",
    "صالح",
    "للطيران",
    "الممر",
    "الممرات",
    "أيتكين",
    "جزئيا",
    "إجاكس",
    "أك",
    "أكيرز",
    "أكيمبو",
    "مشابه",
    "علاء",
    "المرمر",
    "بلهفة",
    "اللهفة",
    "ألاي",
    "ألاميدا",
    "ألامو",
    "ألاموس",
    "آلان",
    "المنبه",
    "مذعور",
    "مفزع",
    "مقلق",
    "التنبيه",
    "القلق",
    "إنذارات",
    "الخطر",
    "للأسف",
    "الرداء",
    "ألبا",
    "البكورة",
    "ألبانيا",
    "الألباني",
    "الألبانيون",
    "ألباني",
    "البطروس",
    "ولو",
    "ألبيريتش",
    "ألبرت",
    "ألبيرتا",
    "ألبيرتو",
    "ألبريتشت",
    "أولبرايت",
    "الألبوم",
    "البياض",
    "الزلال",
    "زلالي",
    "الألبومات",
    "ألبوكيركي",
    "ألسيستيس",
    "الكيميائيون",
    "الكيمياء",
    "ألسيبيادز",
    "جنرال",
    "سياسي",
    "أثينا",
    "ألكمينا",
    "ألكو",
    "للكحول",
    "إدمان",
    "الخمور",
    "ألكوت",
    "تجويف",
    "قبة",
    "مجوف",
    "مقبب",
    "التجاويف",
    "قبب",
    "الدبران",
    "الألديهيد",
    "ألدين",
    "الألدر",
    "مجلس",
    "محلي",
    "أعضاء",
    "ألدريتش",
    "ألدرين",
    "البيرة",
    "حظي",
    "أليك",
    "الاستعراضي",
    "أليكس",
    "سفينة",
    "البار",
    "أليف",
    "ألر",
    "الإنذار",
    "حذر",
    "متيقظ",
    "متيقظون",
    "التحذير",
    "بانتباه",
    "اليقظة",
    "أليت",
    "الألويف",
    "أليكساندر",
    "أليكسانديرز",
    "ألكساندرا",
    "الإسكندرية",
    "أليكسيي",
    "ألكسي",
    "ألفا",
    "الفلفا",
    "ألفونسو",
    "ألفريد",
    "ألفريدو",
    "الطلق",
    "الطحلب",
    "ألجايسيد",
    "ألجال",
    "الطحالب",
    "الجبر",
    "جبري",
    "جبريا",
    "ألجنب",
    "نجم",
    "ألجير",
    "الجزائر",
    "الجزائري",
    "ملح",
    "ألجيني",
    "أملاح",
    "ألجينية",
    "الغول",
    "ألجونكون",
    "ألجوريزم",
    "القاعدة",
    "حسابي",
    "حسابيا",
    "القواعد",
    "الحمرا",
    "علياء",
    "الاسم",
    "المستعار",
    "مسمى",
    "استعارة",
    "الأسماء",
    "المستعارة",
    "التعرج",
    "دليل",
    "أدلة",
    "أليس",
    "أليسيا",
    "الأجنبي",
    "القابلية",
    "للتحول",
    "للتحويل",
    "تحوليا",
    "مغترب",
    "الاغتراب",
    "محول",
    "الطبيب",
    "النفساني",
    "الأجانب",
    "حط",
    "الحط",
    "ظبطتوزيع",
    "أليجنر",
    "الاصطفاف",
    "الترتيب",
    "الأحلاف",
    "يصطف",
    "حد",
    "سواء",
    "التماثلية",
    "المغذي",
    "غذائي",
    "المغذيات",
    "النفقات",
    "النفقة",
    "تام",
    "القسمة",
    "أليسون",
    "أليستير",
    "الصبغ",
    "الأحمر",
    "القلوي",
    "قلوي",
    "القلويات",
    "شبه",
    "مركبات",
    "هيدروكربون",
    "مشبع",
    "مستحضر",
    "هيدروكربوني",
    "متعدد",
    "الأغراض",
    "الترانزستور",
    "ألان",
    "هدئ",
    "هدأ",
    "التهدئة",
    "يهدئ",
    "أليجيت",
    "الادعاء",
    "الادعاءات",
    "ادع",
    "ادعائي",
    "مزعوم",
    "ظاهريا",
    "يدعي",
    "الليجاني",
    "الولاءات",
    "مخلص",
    "مجازي",
    "استعاري",
    "استعاريا",
    "الاستعارية",
    "الرموز",
    "المجاز",
    "يحول",
    "مجازيا",
    "الرمز",
    "أليجرا",
    "عاجل",
    "أعجل",
    "مقطوعة",
    "شديدة",
    "مقطوعات",
    "أليل",
    "أكبر",
    "رقصة",
    "الأليماند",
    "موسيقى",
    "ألين",
    "ألينديل",
    "تاون",
    "مثيرات",
    "للحساسية",
    "بالحساسية",
    "الحساسية",
    "سكن",
    "يسكن",
    "التسكين",
    "المسكنات",
    "الزقاق",
    "الأزقة",
    "الحارة",
    "الحارات",
    "عيد",
    "القديسين",
    "التحالف",
    "التحالفات",
    "تحالف",
    "محالف",
    "الحلفاء",
    "التمساح",
    "مظهر",
    "التماسيح",
    "استخدم",
    "الجناس",
    "استخدام",
    "متجانس",
    "جناس",
    "للتقسيم",
    "للتخصيص",
    "خصص",
    "يخصص",
    "التخصيص",
    "الحصة",
    "الحصص",
    "حصصي",
    "محصص",
    "محصصون",
    "تباين",
    "تباينات",
    "متباين",
    "حديقة",
    "مخصصة",
    "النصيب",
    "الأنصبة",
    "تآصلي",
    "تآصليا",
    "مخصص",
    "اسمح",
    "مسموح",
    "قبولية",
    "السماح",
    "العلاوة",
    "أعطى",
    "علاوةه",
    "العلاوات",
    "الإعطاء",
    "علاوة",
    "سمح",
    "باتفاق",
    "عام",
    "يسمح",
    "سبك",
    "السبك",
    "المركبات",
    "البهارات",
    "يلمح",
    "ملمح",
    "التلميح",
    "أغرى",
    "الإغراء",
    "الجاذبي",
    "حالم",
    "جذابة",
    "مستوى",
    "التلميحات",
    "رسوبي",
    "الطمي",
    "الحليف",
    "أليلي",
    "ألمى",
    "ألمادين",
    "المجسط",
    "التقويم",
    "التقاويم",
    "بقدرة",
    "هائل",
    "اللوز",
    "الإخصائي",
    "الاجتماعي",
    "الصدقات",
    "بيت",
    "الفقراء",
    "بيوت",
    "الفقير",
    "ألنيكو",
    "الألوة",
    "الصبر",
    "عاليا",
    "ألوها",
    "ألون",
    "وحيدا",
    "والحدة",
    "الأمام",
    "بمحاذاة",
    "الشاطئ",
    "جانبيا",
    "الانطواء",
    "عالي",
    "عصا",
    "التسلق",
    "الألفا",
    "أبجدي",
    "العدد",
    "الأبجدي",
    "أبجديا",
    "الحروف",
    "الأبجدية",
    "مرتب",
    "مرتبات",
    "أبجدية",
    "يرتب",
    "ترتيب",
    "حرفي",
    "رقمي",
    "هجائي",
    "عددي",
    "هجائيات",
    "عددية",
    "ألفيراتس",
    "ألفونس",
    "جبلي",
    "متسلق",
    "الألب",
    "الجبال",
    "بالفعل",
    "حسنا",
    "الألزاسي",
    "أيضا",
    "ألسب",
    "ألت",
    "نسر",
    "اللوحة",
    "تغير",
    "للتغيير",
    "التغير",
    "بتغير",
    "التغيير",
    "تشاحن",
    "يتشاحن",
    "التشاحن",
    "المشاجرة",
    "المشاجرات",
    "مغير",
    "مغيرون",
    "الرجل",
    "البديل",
    "بديل",
    "تناوب",
    "بالتناوب",
    "يتناوب",
    "التناوب",
    "التناوبات",
    "كبديل",
    "التبادلية",
    "البدائل",
    "المولد",
    "المولدات",
    "يتغير",
    "ألثي",
    "بالرغم",
    "أن",
    "الارتفاع",
    "الارتفاعات",
    "الألتو",
    "كليا",
    "ألتون",
    "الإيثار",
    "بإيثار",
    "المؤثرون",
    "الخريج",
    "أكسيد",
    "ألومنيوم",
    "ألومينات",
    "الألومنيوم",
    "الألمنيوم",
    "الخريجة",
    "ألمنا",
    "الخريجون",
    "مصهور",
    "ألفاريز",
    "ألفيولارلي",
    "ألفيولي",
    "ألفين",
    "ألواي",
    "دائما",
    "أليسا",
    "الآلوسن",
    "هل",
    "أماديس",
    "أماه",
    "بكل",
    "المزيج",
    "اندمج",
    "يندمج",
    "الاندماج",
    "إندماجي",
    "الأمزجة",
    "أماندا",
    "الأمانيت",
    "الكاتب",
    "تذبل",
    "أماريلو",
    "الأمارلس",
    "كدس",
    "تكدس",
    "مكدس",
    "يتكدس",
    "التكدس",
    "الرداءة",
    "الهواية",
    "غرامي",
    "أذهل",
    "مندهش",
    "مذهل",
    "مذهلون",
    "يذهل",
    "رائع",
    "القوية",
    "النساء",
    "السفير",
    "السفراء",
    "منصب",
    "مناصب",
    "سفير",
    "الكهرمان",
    "العنبر",
    "الكهرمانات",
    "البيئات",
    "ماهر",
    "يدويا",
    "ماهرة",
    "الأجواء",
    "امبيانت",
    "الغموض",
    "اللبس",
    "ملتبس",
    "التباس",
    "النطاق",
    "الطموح",
    "الطموحات",
    "طموح",
    "الازدواجية",
    "منقسم",
    "متناقض",
    "التمهل",
    "تمشى",
    "المتمهل",
    "التمشي",
    "أمبروز",
    "لذيذ",
    "بمنتهى",
    "اللذة",
    "الأطعمة",
    "اللذيذة",
    "سيارة",
    "الإسعاف",
    "سيارات",
    "متنقل",
    "تجول",
    "التجول",
    "ممشى",
    "متجول",
    "الكمين",
    "باغت",
    "مباغت",
    "الكمائن",
    "نصب",
    "كمينا",
    "أمبشر",
    "أمدال",
    "أميليا",
    "حسن",
    "التحسين",
    "التحسينات",
    "محسن",
    "آمين",
    "المطاوعة",
    "طوعا",
    "تعويض",
    "تحسن",
    "معوض",
    "التحسن",
    "يتحسن",
    "الطمث",
    "الاصطلاح",
    "الأمركة",
    "أمريكي",
    "الأمريكيون",
    "الأمريكتين",
    "أميريشيوم",
    "أميز",
    "أميسلان",
    "حجر",
    "أميثست",
    "بنفسجي",
    "أحجار",
    "أمهيرست",
    "أمي",
    "اللطف",
    "أنيس",
    "محبوب",
    "بأنس",
    "ودي",
    "السلم",
    "الأميد",
    "الصديق",
    "أميني",
    "أمينوبنزوي",
    "خاطئ",
    "الصداقات",
    "الصداقة",
    "عمان",
    "أميرمان",
    "التيار",
    "الكهربائي",
    "الذخيرة",
    "النشادر",
    "نشادري",
    "الأمونيا",
    "الأمونيوم",
    "الذخائر",
    "الذاكرة",
    "عفا",
    "العامة",
    "العام",
    "أموكو",
    "الأميبا",
    "الأميبات",
    "أميبي",
    "الجنون",
    "القاتل",
    "أخلاقي",
    "أخلاقيات",
    "الفساد",
    "متحايد",
    "أخلاقيا",
    "بغرام",
    "الغرام",
    "ملامح",
    "محدد",
    "وشك",
    "الموت",
    "مقسط",
    "التقسيط",
    "سدد",
    "يسدد",
    "التسديد",
    "عاموس",
    "الكمية",
    "مبلوغ",
    "الكم",
    "محددوا",
    "الكميات",
    "الغرامية",
    "العلاقات",
    "الأمبير",
    "كهربائي",
    "الأمبيرات",
    "الو",
    "الوات",
    "أمبيكس",
    "المقوي",
    "المقويات",
    "البرمائي",
    "الكائنات",
    "البرمائية",
    "برمائي",
    "الأمفيبولات",
    "الطرفين",
    "المدرج",
    "المدرجات",
    "قارورة",
    "جرة",
    "قوارير",
    "جرات",
    "متسع",
    "وافي",
    "مسهب",
    "السعة",
    "كفاية",
    "أسهب",
    "مكبر",
    "مكبرات",
    "يسهب",
    "الإسهاب",
    "مدى",
    "التردد",
    "صم",
    "الحقنة",
    "الحقن",
    "ابتر",
    "بتر",
    "يبتر",
    "المعوق",
    "أمرا",
    "إيه",
    "إس",
    "أمستردام",
    "أمتراك",
    "باهتياج",
    "التميمة",
    "التمائم",
    "سل",
    "مستمتع",
    "بسعادة",
    "المتعة",
    "الملاهي",
    "مسل",
    "مسلون",
    "يسلي",
    "التسلية",
    "مسلية",
    "أمسايف",
    "الصخر",
    "البركاني",
    "أميلي",
    "تعريف",
    "النوادر",
    "قائل",
    "بتجديد",
    "عماد",
    "قائلون",
    "أنابل",
    "الآثار",
    "منطوي",
    "مفارقة",
    "تاريخية",
    "للعصر",
    "مناسبة",
    "أفعى",
    "الأناكوندا",
    "أفاعي",
    "الهجرة",
    "المالح",
    "للعذب",
    "لوضع",
    "البيض",
    "الأنيميا",
    "التخدير",
    "النقش",
    "الناقص",
    "الناقصة",
    "أناهييم",
    "شرجي",
    "مقوي",
    "الإحساس",
    "شرجيا",
    "النظير",
    "قياسي",
    "المقارنات",
    "متشابه",
    "متناظرة",
    "التناظرية",
    "المقارنة",
    "المحلل",
    "التحليلات",
    "التحليل",
    "المحللون",
    "تحليلي",
    "خواص",
    "تحليلية",
    "المنطقي",
    "للتحليل",
    "صورة",
    "بصرية",
    "مشوهة",
    "بالجناس",
    "باستخدام",
    "أنابلازموسيس",
    "الفوضوي",
    "فوضوي",
    "الفوضويون",
    "الفوضوية",
    "أناركتيكا",
    "أناستاسيا",
    "العدسة",
    "مصحح",
    "الوصلات",
    "توصيلي",
    "العن",
    "لعن",
    "يلعن",
    "اللعن",
    "أناتول",
    "تشريحي",
    "تشريحيا",
    "تشريحيات",
    "البنى",
    "عالم",
    "التشريح",
    "شرح",
    "يشرح",
    "الجد",
    "الأجداد",
    "الجدودية",
    "سلفي",
    "سلالي",
    "السلف",
    "الأسلاف",
    "الأصول",
    "الأصل",
    "المرساة",
    "أنكريج",
    "المراسي",
    "رسا",
    "الرسو",
    "الناسك",
    "حياة",
    "العزلة",
    "المراس",
    "الأنشوفة",
    "قديما",
    "القدم",
    "إنديزي",
    "منطقيا",
    "المعامل",
    "&",
    "المتغيرات",
    "أندرس",
    "أندرسن",
    "أندرسون",
    "أنديز",
    "أنديساين",
    "أنديسايت",
    "المسند",
    "أندورا",
    "أندوفر",
    "أندريه",
    "أندريي",
    "أندرو",
    "أندروز",
    "منشط",
    "الذكورة",
    "منشطات",
    "مخنث",
    "الروبوت",
    "أندروماش",
    "آندروميدا",
    "AND",
    "أندي",
    "قصصي",
    "قصصية",
    "النادرة",
    "النادرات",
    "القاص",
    "القصاصون",
    "صدوي",
    "ينتج",
    "صدى",
    "أنيمية",
    "مصابوا",
    "شدة",
    "الريح",
    "ريح",
    "الانيمن",
    "مبحث",
    "المخدر",
    "بالتخدير",
    "المخدرات",
    "إخصائيون",
    "خدر",
    "مختصوا",
    "يخدر",
    "الملاك",
    "أنجيلا",
    "أنجيلينو",
    "أنجيلينوس",
    "أنجيليز",
    "السمك",
    "ملائكي",
    "الأنجليك",
    "بملائكية",
    "أنجيلينا",
    "أنجيلاين",
    "أنجلو",
    "الملائكة",
    "الغضب",
    "أغضب",
    "الإغضاب",
    "أنجي",
    "تصوير",
    "تتبعي",
    "بالأشعة",
    "ملقح",
    "ذاتيا",
    "مزوى",
    "الصياد",
    "الصيادون",
    "الزوايا",
    "أنجليا",
    "الأنجليكي",
    "الأنجليكية",
    "الكراهية",
    "أنجولا",
    "الأنجورا",
    "غضبا",
    "بغضب",
    "غاضب",
    "أنجستروم",
    "أنجسترومز",
    "العذاب",
    "تألم",
    "العذابات",
    "بارز",
    "العظام",
    "زوايا",
    "زاويا",
    "كم",
    "أنجس",
    "متناغم",
    "أنهيسر",
    "الأندريد",
    "الأنهيدريت",
    "جاف",
    "لامائي",
    "أني",
    "الأنيلين",
    "الملاحظة",
    "الانتقادية",
    "الملاحظات",
    "انتقد",
    "ينتقد",
    "الصغير",
    "الحيوانية",
    "السمات",
    "جسديا",
    "متحمس",
    "متحرك",
    "الحركية",
    "ينعش",
    "الإنعاش",
    "الرسوم",
    "المتحركة",
    "رسوم",
    "متحركة",
    "انيما",
    "الروحانية",
    "يعطي",
    "الأنيون",
    "أنيوني",
    "أنيونيات",
    "الينسون",
    "اليانسون",
    "الخواص",
    "أنيتا",
    "أنقرة",
    "الكاحل",
    "عظم",
    "الكواحل",
    "الخلخال",
    "سجلي",
    "الشبكة",
    "الإخبارية",
    "الآنة",
    "أنال",
    "أنيل",
    "أنالين",
    "المؤرخ",
    "السجلات",
    "أنابوليس",
    "يصلب",
    "مصلب",
    "مصلبون",
    "الصلب",
    "الحلقية",
    "أنات",
    "استولى",
    "الاستيلاء",
    "اهلك",
    "أهلك",
    "يهلك",
    "الإهلاك",
    "الإبادة",
    "مدمر",
    "المدمر",
    "الأعياد",
    "العيد",
    "علق",
    "يعلق",
    "التعليق",
    "التعليقات",
    "تعليقي",
    "الحواشي",
    "المذيع",
    "المذيعون",
    "ضايق",
    "مصادر",
    "الإزعاج",
    "متضايق",
    "مزعج",
    "مزعجون",
    "يضايق",
    "النبات",
    "الحولي",
    "محسوب",
    "سنويا",
    "الحولية",
    "السناهي",
    "المعاشات",
    "المعاش",
    "حلقي",
    "حلقيا",
    "مبطل",
    "للإبطال",
    "الإبطالات",
    "الحلقة",
    "بشارة",
    "جبريل",
    "بشائر",
    "قطب",
    "الموجب",
    "أقطاب",
    "أنوديك",
    "متعلقا",
    "بالقطب",
    "اطل",
    "بأكسيد",
    "طلى",
    "يطلي",
    "طلاء",
    "ادهن",
    "مدهن",
    "الدهن",
    "المسح",
    "يدهن",
    "الشواذ",
    "شواذ",
    "اجتماعيا",
    "حالا",
    "إخفاء",
    "الهوية",
    "مجهول",
    "اسم",
    "اللاهوية",
    "المعطف",
    "واق",
    "الفقدان",
    "الشهية",
    "ذي",
    "محاور",
    "منحرفة",
    "أنورثيت",
    "أنورثوسيت",
    "آخر",
    "أنز",
    "أنسيلم",
    "أنسيلمو",
    "أنسون",
    "الإجابة",
    "رد",
    "بث",
    "لاسلكي",
    "مجاوب",
    "مجاوبون",
    "الرد",
    "الإجابات",
    "النملة",
    "للحموضة",
    "أنتايس",
    "العداء",
    "العداءات",
    "الخصم",
    "الخصوم",
    "معادون",
    "يعادي",
    "العداوة",
    "القطب",
    "الجنوبي",
    "القارة",
    "القطبية",
    "الجنوبية",
    "قلب",
    "العقرب",
    "ادفع",
    "آكل",
    "النمل",
    "أكلة",
    "ما",
    "الحرب",
    "اسبق",
    "سبق",
    "الأسبقية",
    "السبق",
    "دفع",
    "عتيق",
    "الدفع",
    "الظبي",
    "الظباء",
    "القرن",
    "القرون",
    "أمامي",
    "الأجزاء",
    "الأمامية",
    "حجرة",
    "الانتظار",
    "حجرات",
    "يدفع",
    "النشيد",
    "الأناشيد",
    "الذكري",
    "عش",
    "المقتطفات",
    "الأدبية",
    "الباحث",
    "أنتوني",
    "أنثراسين",
    "أنثراسايت",
    "أنثراكنوز",
    "اصفرار",
    "دراسة",
    "الأحياء",
    "الغابة",
    "أنثربولوجي",
    "أنثروبولوجيا",
    "الأنثربولوجيا",
    "الأنثروبولوجيا",
    "علماء",
    "بالأنثروبومترية",
    "قياسات",
    "الأنثروبومترية",
    "مجسم",
    "المجسمات",
    "الآدمية",
    "للاسم",
    "للألياسينج",
    "للأجنبي",
    "للمظاهرة",
    "تحسين",
    "الحواف",
    "المحسنة",
    "للبكتيريا",
    "الحيوي",
    "المضادات",
    "الأجسام",
    "المضادة",
    "للعمل",
    "غريب",
    "المسيحون",
    "الدجال",
    "توقع",
    "يتوقع",
    "التوقع",
    "التوقعات",
    "متوقع",
    "تطلعي",
    "سيطرة",
    "القساوسة",
    "بانحدار",
    "الصدمات",
    "منع",
    "تخثر",
    "الصدفة",
    "معاداة",
    "تنافسي",
    "الطرائف",
    "دوري",
    "الإشتقاق",
    "حركة",
    "الموالاة",
    "للكنائسية",
    "الرسمية",
    "للسم",
    "الترياق",
    "الأدوية",
    "الإغراق",
    "أنتيتام",
    "المغنطيسي",
    "المذيب",
    "المزلاق",
    "الأصولي",
    "المناعة",
    "أدوية",
    "أنتيغون",
    "أنتيجورايت",
    "الشخصية",
    "الرئيسية",
    "تأريخي",
    "المقابل",
    "لوغاريتم",
    "مقابلات",
    "اللوغاريثم",
    "الغطاء",
    "الأساسية",
    "مكروبي",
    "الأنتيمون",
    "أنتينج",
    "تناقضي",
    "القوانين",
    "تناقض",
    "أنطاكيا",
    "مقبلات",
    "مبغض",
    "كاره",
    "ببغض",
    "للأفراد",
    "للعرق",
    "التجاوبي",
    "تجاوبيا",
    "المجاوبة",
    "الصوتية",
    "التخطيط",
    "واجع",
    "النقيض",
    "واقع",
    "النقائض",
    "مانع",
    "للحمى",
    "موانع",
    "الأثريات",
    "تخصص",
    "الآثاريون",
    "خبير",
    "محل",
    "حلول",
    "مكاني",
    "القطعة",
    "الأثرية",
    "أثري",
    "العتق",
    "بالآثار",
    "العصور",
    "للاستبدال",
    "الرنين",
    "أنتيس",
    "المعادي",
    "للسامية",
    "السامية",
    "المعاداة",
    "المطهر",
    "مضادة",
    "للتعفن",
    "المطهرات",
    "أمصال",
    "الصدمة",
    "مقاومة",
    "الرق",
    "انطوائي",
    "أنتيسو",
    "سيالي",
    "للغواصات",
    "متماثل",
    "التناظر",
    "للدبابات",
    "العكس",
    "معاكسة",
    "الدرقية",
    "للرأسمالية",
    "الإحتكار",
    "قرون",
    "أنطوان",
    "أنطوانيت",
    "أنتون",
    "أنطونيو",
    "أنتكوتيس",
    "أنتويرب",
    "فتحة",
    "الشرج",
    "أنفينجيد",
    "السندان",
    "السنادين",
    "قلق",
    "بقلق",
    "بأية",
    "حال",
    "لم",
    "يعد",
    "أنيونيز",
    "أنيثينجز",
    "أنيوهيريز",
    "الأورطى",
    "أورتا",
    "بسرعة",
    "أباتشي",
    "حدة",
    "التفرقة",
    "بالوضع",
    "الشقة",
    "الشقق",
    "تحديد",
    "مبالي",
    "بفتور",
    "الأباتيت",
    "القرد",
    "قلد",
    "أبر",
    "دورية",
    "بؤرة",
    "بؤري",
    "بؤر",
    "القرود",
    "الرأس",
    "الرءوس",
    "الكلام",
    "الأوج",
    "الأفد",
    "مبحوح",
    "حاكي",
    "حكيم",
    "الأفرودايت",
    "المناحل",
    "المنحلة",
    "بقيمة",
    "عالية",
    "القمم",
    "لكل",
    "التقليد",
    "مقلد",
    "كالقرد",
    "تقليد",
    "بغباء",
    "الثقة",
    "نهايات",
    "تنبؤي",
    "نذير",
    "بنحس",
    "كتب",
    "التوراة",
    "المزورة",
    "ملفق",
    "بتلفيق",
    "التلفيق",
    "المؤكد",
    "متن",
    "الجملة",
    "الشرطية",
    "بخلايا",
    "عصب",
    "محددة",
    "السياسة",
    "أبوليناير",
    "أبولو",
    "أبولوني",
    "اعتذاري",
    "معتذرا",
    "علوم",
    "المسيحية",
    "الاعتذارات",
    "المدافع",
    "المدافعون",
    "اعتذر",
    "معتذر",
    "معتذرون",
    "يعتذر",
    "اعتذار",
    "سريع",
    "السكتات",
    "السكتة",
    "الردة",
    "المرتد",
    "المرتدون",
    "ارتد",
    "يرتد",
    "الارتداد",
    "الحواري",
    "الحواريون",
    "بابوي",
    "رسوليا",
    "الفاصلة",
    "الفاصلات",
    "ناجي",
    "ناجى",
    "يناجي",
    "المناجاة",
    "الصيدليون",
    "الصيدلي",
    "الحكمة",
    "التأليهات",
    "المثال",
    "معرف",
    "التطبيق",
    "أبالاتشيا",
    "الأبالاتشيا",
    "مرعوب",
    "مريع",
    "مرعب",
    "يهول",
    "الأفراس",
    "أبالز",
    "الميراث",
    "المعدات",
    "مكسو",
    "الكسوة",
    "كسا",
    "الكسو",
    "ظاهر",
    "يبدو",
    "شبح",
    "أشباح",
    "يستأنف",
    "للإستئناف",
    "استأنف",
    "المستأنف",
    "المستأنفون",
    "استئنافات",
    "اظهر",
    "مظاهر",
    "ظاهرون",
    "متسامح",
    "المهادنة",
    "المهدئ",
    "استئنافي",
    "أبيلانتس",
    "إسميا",
    "مذيل",
    "الزائدة",
    "الدودية",
    "أضاف",
    "مضيف",
    "مضيفون",
    "التهاب",
    "الزائدات",
    "المظهر",
    "تعلق",
    "يتعلق",
    "الشهوات",
    "الشهوة",
    "مشهي",
    "فاتحات",
    "للشهية",
    "منتمي",
    "لآبيوس",
    "تهليلي",
    "بتهليل",
    "مهلل",
    "تهليل",
    "التهاليل",
    "التفاحة",
    "فطيرة",
    "التفاح",
    "أبليبي",
    "عصير",
    "بريمج",
    "برنامج",
    "أبليتون",
    "بريمجات",
    "الجهاز",
    "الأجهزة",
    "الانطباق",
    "منطبق",
    "بإنطباق",
    "يطلب",
    "الطلب",
    "بالطلب",
    "التطبيقاتالأحداث",
    "الطلبات",
    "عملي",
    "تطبيقي",
    "القضيب",
    "الرفيع",
    "القضبان",
    "الرفيعة",
    "انطبق",
    "مطبقون",
    "ينطبق",
    "طبق",
    "عين",
    "المعين",
    "المعينات",
    "معينون",
    "التعيين",
    "تعييني",
    "الموعد",
    "المواعيد",
    "يعين",
    "أبوماتوكس",
    "كائن",
    "مادي",
    "أظهره",
    "روحاني",
    "قسم",
    "التقسيم",
    "التقسيمات",
    "يقسم",
    "البدل",
    "عطفي",
    "بالبدل",
    "التقييم",
    "التقييمات",
    "قيم",
    "المثمن",
    "المثمنون",
    "بتقدير",
    "ملحوظ",
    "قدر",
    "يقدر",
    "بامتنان",
    "مقدر",
    "اعتقل",
    "معتقل",
    "الاعتقال",
    "يعتقل",
    "الفهم",
    "المخاوف",
    "أرسل",
    "المبتدئون",
    "التدريب",
    "التدريبات",
    "الإرسال",
    "أبلغ",
    "يبلغ",
    "تبليغ",
    "تبليغات",
    "أخبر",
    "المقيم",
    "المقيمون",
    "تقييم",
    "تقييمات",
    "الطريقة",
    "ودود",
    "اقترب",
    "مقترب",
    "مقتربون",
    "الطرق",
    "الاقتراب",
    "صدق",
    "للملائمة",
    "يستولي",
    "التخصيصات",
    "تلائمي",
    "المستولي",
    "المستولون",
    "للتقريب",
    "تقريبي",
    "قارب",
    "يقارب",
    "المقاربة",
    "إبريل/أب",
    "المشمش",
    "الإبريل",
    "استدلالي",
    "المريلة",
    "المرايل",
    "وبالمناسبة",
    "المحراب",
    "المحاريب",
    "محتمل",
    "الموهبة",
    "المواهب",
    "الاستعداد",
    "أسطوانة",
    "الأكسجين",
    "الزبرجد",
    "رائد",
    "بحار",
    "زلاجة",
    "أحواض",
    "حوض",
    "برج",
    "الدلو",
    "نيلي",
    "مائي",
    "مائيا",
    "المائية",
    "القناة",
    "القنوات",
    "طبقة",
    "صخرية",
    "طبقات",
    "أكويلا",
    "معقوف",
    "الأكويني",
    "العربي",
    "الأرابيسك",
    "الجزيرة",
    "عربي",
    "عرب",
    "للزراعة",
    "العرب",
    "أراكني",
    "الفصيلة",
    "العنكبوتيات",
    "الفصائل",
    "القوس",
    "القذوف",
    "عاطل",
    "الحكام",
    "للتحكيم",
    "المتاجرة",
    "تحكيمي",
    "متعسف",
    "الاستبدادية",
    "الإستبداد",
    "تعسفي",
    "احكم",
    "العريش",
    "شجري",
    "مشجر",
    "المشتل",
    "العرش",
    "القطلب",
    "زود",
    "بممرات",
    "ريفية",
    "هادئة",
    "تسوير",
    "أركانا",
    "سري",
    "قوس",
    "جيب",
    "تمام",
    "انحنى",
    "آركاي",
    "الناحية",
    "المهجورات",
    "استعمل",
    "جعله",
    "مهجورا",
    "مستخدم",
    "مستخدموا",
    "الجعل",
    "المطرانية",
    "المطرانيات",
    "الأرشيدوقة",
    "الدوق",
    "تقوس",
    "الرام",
    "الرماة",
    "الرماية",
    "الأقواس",
    "الأمكر",
    "الأمثلة",
    "نموذجي",
    "ارشيبالد",
    "ارشي",
    "أرخميدس",
    "التقوس",
    "التقوسات",
    "الأرخبيلات",
    "المهندس",
    "المعماري",
    "معماري",
    "معماريون",
    "المعماريون",
    "معماريا",
    "الطراز",
    "الطرز",
    "المعمارية",
    "ارشيف",
    "بالأرشيف",
    "الأرشيف",
    "حفظ",
    "أرشيفي",
    "مأرشف",
    "المحفوظات",
    "أمين",
    "أمناء",
    "بغرور",
    "المكر",
    "الانحناء",
    "مصباح",
    "قوسي",
    "القوسي",
    "ظل",
    "الشمالي",
    "السماك",
    "الرامح",
    "أردن",
    "الحماسة",
    "بحماسة",
    "الحماس",
    "المناطق",
    "أرين",
    "الحلبة",
    "رملي",
    "الحلبات",
    "أريكويبا",
    "آريز",
    "الفضي",
    "الأرجنتين",
    "الأرجنتيني",
    "صلصالي",
    "أرجينين",
    "الآرغوسي",
    "الأرجون",
    "أرجون",
    "بروجو",
    "جدل",
    "يزعم",
    "تجادل",
    "المجادل",
    "المجادلون",
    "يتجادل",
    "التجادل",
    "النقاش",
    "الجدال",
    "مجادل",
    "جدليا",
    "الجدل",
    "النقاشات",
    "نبيه",
    "أرهات",
    "الأغنية",
    "أريادن",
    "الآرية",
    "آري",
    "آريون",
    "الجفاف",
    "جافا",
    "الحمل",
    "صحيح",
    "ارتفع",
    "مرتفع",
    "يرتفع",
    "الطبقات",
    "الأرستقراطية",
    "الطبقة",
    "الأرستقراطي",
    "أرستقراطي",
    "أريستوكراتيكال",
    "أرستقراطية",
    "الأرستقراطيون",
    "أرستوفان",
    "أريستوتيلين",
    "أريستوتيلايان",
    "أرسطو",
    "بالحساب",
    "أركانساس",
    "أرلين",
    "أرلينجتون",
    "الذراع",
    "الحشد",
    "الحشود",
    "الأرماديلو",
    "المدرع",
    "الفانية",
    "السلاح",
    "الأسلحة",
    "أسلحة",
    "الكرسي",
    "الكراسي",
    "أرمكو",
    "تسلح",
    "أرمينيا",
    "الأرميني",
    "سلاح",
    "الحفنة",
    "الحفنات",
    "الجيوش",
    "أرميلاريا",
    "التسلح",
    "الهدنة",
    "الإسورة",
    "حمل",
    "ذراع",
    "الدولاب",
    "أرمونك",
    "الدرع",
    "مدرع",
    "بشعار",
    "النبالة",
    "مستودعات",
    "تزويد",
    "بمدرعات",
    "الدروع",
    "الإبط",
    "الآباط",
    "سندة",
    "الأذرع",
    "أرمسترونج",
    "الجيش",
    "أرنولد",
    "العبير",
    "عطري",
    "عطريا",
    "العطر",
    "علا",
    "الإثارات",
    "أربانيت",
    "صوت",
    "تتابعي",
    "أصوات",
    "تتابعية",
    "المشروب",
    "المسكر",
    "أراجون",
    "استدع",
    "استدعى",
    "الاستدعاء",
    "يستدعي",
    "للترتيب",
    "الترتيبات",
    "المنظم",
    "المنظمون",
    "متشرد",
    "محض",
    "التطريز",
    "الصف",
    "أرير",
    "الصفوف",
    "المتأخر",
    "المتأخرات",
    "اقبض",
    "قبض",
    "الموقوف",
    "الموقوفون",
    "الموقف",
    "الموقفون",
    "القبض",
    "الاعتقالات",
    "أرهينيس",
    "القادمون",
    "صل",
    "قادم",
    "الغرور",
    "التكبر",
    "متكبر",
    "بتكبر",
    "ادعى",
    "السهم",
    "النصل",
    "النصال",
    "النشوية",
    "السهام",
    "الغدير",
    "الغدران",
    "العجيزة",
    "الترسانة",
    "الترسانات",
    "الزرنيخات",
    "الزرنيخ",
    "زرنيخي",
    "الزرنيخيد",
    "الأرسين",
    "التخريب",
    "المخرب",
    "المخربون",
    "التخريبات",
    "الأداة",
    "آرتميس",
    "الأرطماسيا",
    "شرياني",
    "بالشريان",
    "الشرايين",
    "الشريان",
    "تصلب",
    "بتصلب",
    "إرتوازي",
    "داهية",
    "بدهاء",
    "الروماتيزم",
    "لمفاصل",
    "محقونة",
    "بمواد",
    "عاكسة",
    "صور",
    "المفصلي",
    "المفصليات",
    "أرثر",
    "معالج",
    "فوري",
    "للواجهة",
    "الخرشوف",
    "تحت",
    "التمرين",
    "الإلزام",
    "للتأويل",
    "مفصلي",
    "انطق",
    "بوضوح",
    "المفاصل",
    "نطقي",
    "متحدث",
    "بالوضوح",
    "متحدثون",
    "أرتي",
    "متطفل",
    "الأدوات",
    "التحايل",
    "الصانع",
    "الحيل",
    "صناعي",
    "الاصطناعي",
    "الاصطناعية",
    "الإصطناعية",
    "المدفعيات",
    "جندي",
    "المدفعية",
    "المدفعي",
    "المدافعي",
    "ادعاء",
    "الحرفي",
    "الفنان",
    "فني",
    "فنيا",
    "المهارات",
    "الفنية",
    "الفنانون",
    "ساذج",
    "بسذاجة",
    "العفوية",
    "أرترو",
    "أروبا",
    "اللوف",
    "أري",
    "الأوروبي",
    "الأوروبيون",
    "الشماليون",
    "أريل",
    "ك",
    "الأسبستوس",
    "للصعود",
    "السيادة",
    "الصعود",
    "فائق",
    "الصاعد",
    "الصاعدات",
    "صعدة",
    "صعدات",
    "تحقق",
    "التحقق",
    "منه",
    "يتحقق",
    "زهدي",
    "بزهد",
    "الزاهدون",
    "أسكي",
    "الفطر",
    "زقي",
    "ربطة",
    "العنق",
    "ربطات",
    "منسوب",
    "يعزو",
    "ينسب",
    "النسب",
    "النسبة",
    "نسبي",
    "بتطهير",
    "مخصي",
    "الخصي",
    "تزاوج",
    "الرماد",
    "مطفأة",
    "السجائر",
    "أشيم",
    "خجلان",
    "مخجل",
    "شاحب",
    "أشفيل",
    "بالرماد",
    "أشلاند",
    "أشلي",
    "أشمان",
    "أشمين",
    "أشمولي",
    "مطافئ",
    "آسيا",
    "الآسيوي",
    "الآسيويون",
    "جانبا",
    "الجانبية",
    "أبله",
    "اسأل",
    "إستنكارا",
    "سأل",
    "سائلون",
    "بميل",
    "السؤال",
    "يسأل",
    "نائم",
    "الهليونين",
    "الاسبراجس",
    "الاسبراجسي",
    "النواحي",
    "الأسبن",
    "الافتراء",
    "الافتراءات",
    "الأسفلت",
    "سفلت",
    "أسفلتي",
    "كروي",
    "الاختناق",
    "الخانق",
    "اختنق",
    "يختنق",
    "الخنق",
    "الإختناق",
    "الحساء",
    "الأسبيدسترة",
    "الطموحون",
    "هاء",
    "بملء",
    "النفس",
    "المضخات",
    "اطمح",
    "طمح",
    "يطمح",
    "الأسبرين",
    "أسبلينيوم",
    "مزودو",
    "خدمات",
    "الانترنت",
    "الأسد",
    "أساي",
    "هاجم",
    "منتقد",
    "المهاجم",
    "المهاجمون",
    "المهاجمة",
    "يهاجم",
    "أسام",
    "سفاك",
    "اغتل",
    "اغتال",
    "يغتال",
    "الاغتيال",
    "الاغتيالات",
    "المغتال",
    "المغتالون",
    "سفاكون",
    "هجوم",
    "متهجم",
    "متهجمون",
    "التهجم",
    "تهجمي",
    "تجهمية",
    "التهجمية",
    "الهجمات",
    "الفحص",
    "اختبر",
    "الفاحص",
    "الفاحصون",
    "الاختبار",
    "الفحوص",
    "المجموعات",
    "الجامع",
    "الجامعون",
    "يتجمع",
    "المجالس",
    "المجلس",
    "أسن",
    "أسنسيو",
    "يصادق",
    "مصادقات",
    "صادق",
    "مصادق",
    "مصادقون",
    "تصديق",
    "مصادقة",
    "مصداقي",
    "حازم",
    "بجزم",
    "مصداقيات",
    "توكيدي",
    "أكيد",
    "يؤكد",
    "المصحح",
    "المصححون",
    "اجزم",
    "جزم",
    "يجزم",
    "الجزم",
    "المتسكعون",
    "المواظبة",
    "مجتهد",
    "باجتهاد",
    "الاجتهاد",
    "المثابرة",
    "كلف",
    "التكليف",
    "مشترك",
    "اللقاء",
    "اللقاءات",
    "المعينون",
    "الواجبات",
    "الملكية",
    "يكلف",
    "للإنضمام",
    "ينضم",
    "الانضمام",
    "إستيعابي",
    "تمثيلي",
    "مترابط",
    "ربط",
    "الربط",
    "ترابطي",
    "الجمعيات",
    "بترابط",
    "ترابطيات",
    "مترابطون",
    "السجع",
    "مسجوع",
    "سجعي",
    "منوع",
    "التنويع",
    "التشكيلة",
    "التشكيلات",
    "ينوع",
    "التخفيف",
    "بطريق",
    "الافتراض",
    "افترض",
    "مفترض",
    "يفترض",
    "الفرض",
    "الفروض",
    "ثقة",
    "بالنفس",
    "مدفوع",
    "للتأمين",
    "واثق",
    "بالتأكيد",
    "المؤمن",
    "المؤمنون",
    "بثقة",
    "الإمبراطورية",
    "الآشورية",
    "مستقر",
    "أستاير",
    "عائلة",
    "عشتروت",
    "الأستاتين",
    "زهرة",
    "النجمة",
    "الحجر",
    "النجمي",
    "الأستريسك",
    "النجوم",
    "الكويكب",
    "نجمي",
    "الكويكبات",
    "زهور",
    "الربو",
    "داء",
    "بالربو",
    "ربوي",
    "باختناق",
    "إستجمات",
    "بزوغان",
    "النظر",
    "الإستجماتيزم",
    "مستيقظ",
    "مدهش",
    "الدهشة",
    "أستور",
    "أستوريا",
    "الإذهال",
    "منفرج",
    "الساقين",
    "بالنجوم",
    "لاذعات",
    "لواذع",
    "الأوعية",
    "الدواء",
    "القابض",
    "قابضا",
    "للأنسجة",
    "القابضة",
    "مرصد",
    "مراصد",
    "الأسطرلاب",
    "الأسطرلابات",
    "المنجمون",
    "تنجيمي",
    "علاقة",
    "بالتنجيم",
    "التنجيم",
    "بملاحة",
    "الملاحة",
    "الفضائية",
    "رواد",
    "الفلك",
    "ضخم",
    "فلكيا",
    "بالفيزياء",
    "الفلكية",
    "الفيزياء",
    "ذكي",
    "بذكاء",
    "أسونسيون",
    "إربا",
    "اللجوء",
    "الملاجئ",
    "متناسق",
    "متماثلة",
    "التناسق",
    "معد",
    "معدية",
    "الخط",
    "المقارب",
    "الخطوط",
    "التزامن",
    "تزامني",
    "متزامن",
    "اللاتزامنية",
    "أتالانتا",
    "أتاري",
    "الأتافيزم",
    "الأتافيزمي",
    "الترنحات",
    "أتشيسن",
    "أكل",
    "وقتي",
    "أثاباسكان",
    "الإلحاد",
    "إلحادي",
    "الأثيني",
    "الأثينيون",
    "عطشان",
    "الرياضي",
    "الرياضيون",
    "رياضي",
    "رياضية",
    "اهتمام",
    "بالرياضة",
    "ألعاب",
    "القوى",
    "أتكينز",
    "أتكينسون",
    "أطلنطا",
    "أتلانتيز",
    "أطلنطي",
    "أطلانتيكا",
    "أطلنتس",
    "الأطلس",
    "الأطالس",
    "بالجو",
    "الظواهر",
    "الجزر",
    "الذرة",
    "ذري",
    "بالذرة",
    "الانقسام",
    "ذرات",
    "فتت",
    "يفتت",
    "التفتيت",
    "الذرات",
    "حر",
    "كفر",
    "التعويض",
    "يكفر",
    "التكفير",
    "قمة",
    "أتريس",
    "أتريا",
    "الأذين",
    "فظيع",
    "بشع",
    "البشاعات",
    "البشاعة",
    "ضامر",
    "ضمر",
    "الضمور",
    "الأتروبين",
    "أتروبس",
    "ألحق",
    "معرض",
    "للمصادرة",
    "ملحقون",
    "الملحقون",
    "التثبيت",
    "الهجوم",
    "للهجوم",
    "يحرز",
    "إحرازه",
    "الإحراز",
    "بمصادرة",
    "ممتلكات",
    "أحرز",
    "محرز",
    "محرزون",
    "النيل",
    "ينال",
    "دن",
    "أدان",
    "الإدانة",
    "يدين",
    "عطر",
    "الورد",
    "عطور",
    "المحاولة",
    "للمحاولة",
    "محاول",
    "محاولون",
    "المحاولات",
    "احضر",
    "الحاضرون",
    "الخادم",
    "الخدم",
    "حضر",
    "حاضرون",
    "يحضر",
    "سيكلوجية",
    "اضعف",
    "أضعف",
    "يضعف",
    "الإضعاف",
    "المخفف",
    "المخففات",
    "دل",
    "الشاهد",
    "الدلالة",
    "يدل",
    "السندرة",
    "أتيكا",
    "السندرات",
    "مرتدي",
    "ثياب",
    "الارتداء",
    "المواقف",
    "اتجاهي",
    "يتصنع",
    "التصنع",
    "المحامون",
    "محاماة",
    "اجذب",
    "منجذب",
    "جذب",
    "الجذب",
    "الجذاب",
    "الجذابون",
    "يجذب",
    "نسب",
    "المسبب",
    "مسند",
    "الاستنزاف",
    "متآكل",
    "الإستنزافات",
    "أقلم",
    "معتاد",
    "أتواتر",
    "أتود",
    "الإجنبي",
    "مقابل",
    "القيام",
    "ببعض",
    "المنزلية",
    "الحانة",
    "أوبري",
    "بني",
    "أوكلاند",
    "المزاد",
    "باع",
    "بالمزاد",
    "العلني",
    "بائع",
    "باعة",
    "بيع",
    "المزادات",
    "جريء",
    "بجراءة",
    "الجرأة",
    "السمعيات",
    "السمع",
    "مسموع",
    "الجمهور",
    "الجماهير",
    "بصري",
    "الترددات",
    "السمعية",
    "تخطيط",
    "بعلم",
    "متخصصوا",
    "لقوة",
    "شريط",
    "التسجيل",
    "سمعية",
    "يراجع",
    "راجع",
    "المراجعة",
    "أدى",
    "اختبارا",
    "اختبار",
    "الاختبارات",
    "المراجع",
    "القاعة",
    "القاعات",
    "مراجعات",
    "أودري",
    "أودوبون",
    "أويرباتش",
    "أوف",
    "أغسطس",
    "قذر",
    "الخرامة",
    "الخرامات",
    "الأوجايت",
    "يزداد",
    "للازدياد",
    "ازدياد",
    "ازديادات",
    "مزيد",
    "مزداد",
    "الازدياد",
    "تنبأ",
    "النذيرة",
    "يتنبأ",
    "أوجستا",
    "أغسطسي",
    "أوغسطين",
    "مهيب",
    "الهيبة",
    "أشهر",
    "آب/أغسطس",
    "أوغسطس",
    "الأوك",
    "العمة",
    "أونتلي",
    "العمات",
    "الهالة",
    "الهالات",
    "أذني",
    "ذهبي",
    "أوريليوس",
    "الأوريومايسين",
    "الذو",
    "الأعنة",
    "الثور",
    "الفجر",
    "فجري",
    "أوسكويتز",
    "افحص",
    "فحص",
    "يفحص",
    "التسمع",
    "الطالع",
    "رعاية",
    "مبشر",
    "السعادة",
    "الأوستينيت",
    "بسيط",
    "البساطة",
    "أوستن",
    "جنوبي",
    "الأسترالي",
    "الأستراليون",
    "يميل",
    "الجنوب",
    "النمسا",
    "النمساوي",
    "النمساويون",
    "سيادة",
    "مطلقة",
    "يصدق",
    "التوثيقات",
    "الموثق",
    "الموثقون",
    "الأصالة",
    "المؤلف",
    "ألف",
    "المؤلفة",
    "المؤلفات",
    "التأليف",
    "المتسلط",
    "التسلط",
    "الإستبدادية",
    "موضع",
    "متسلط",
    "المسئولون",
    "السلطة",
    "التصريح",
    "التصاريح",
    "وكل",
    "المفوض",
    "المفوضون",
    "يوكل",
    "التوكيل",
    "المؤلفون",
    "التأليفات",
    "بالتوحد",
    "السيارة",
    "تنغلق",
    "تدمر",
    "ترفع",
    "إجابة",
    "تكتشف",
    "تعود",
    "تنسيق",
    "تلقائي",
    "تشغيل",
    "آلي",
    "التلقائية",
    "بسيرة",
    "المرء",
    "الذاتية",
    "كسيرة",
    "ذاتية",
    "السير",
    "السيرة",
    "الآلية",
    "السيارات",
    "الأوتوكلاف",
    "اوتوكود",
    "موازاة",
    "تلقائية",
    "موازي",
    "تلقائيا",
    "اكتمل",
    "تكملة",
    "آلية",
    "تصحيح",
    "يربط",
    "تقائيا",
    "التلقائي",
    "ترابطات",
    "الدول",
    "المقهورة",
    "مستبد",
    "استبدادي",
    "مستبدة",
    "المتسلطون",
    "تنازلي",
    "اكتشاف",
    "اتصال",
    "المتصل",
    "ذاتي",
    "التعليم",
    "متعلم",
    "تعبئة",
    "الاستشعاع",
    "نموذج",
    "اختيار",
    "التوقيع",
    "وقع",
    "توقيعي",
    "التوقيعات",
    "التنويم",
    "المغناطيسي",
    "تزايدي",
    "فهرس",
    "آليا",
    "الملقم",
    "الآلي",
    "المطعم",
    "الباردون",
    "الأتمتة",
    "الآليات",
    "إرادي",
    "البارد",
    "المطاعم",
    "تشكيلي",
    "التشكل",
    "بالسيارات",
    "المبحر",
    "المبحرات",
    "مستقل",
    "استقلالي",
    "الاستقلالي",
    "الاستقلاليون",
    "الذاتي",
    "الطيارات",
    "تشريح",
    "ارتدادي",
    "يعيد",
    "الإعادة",
    "الإيحائية",
    "الإيجاء",
    "الإيحاء",
    "درج",
    "الالتفاف",
    "الخريف",
    "خريفي",
    "خريفيا",
    "الخريفات",
    "انفع",
    "التوفر",
    "الوفرة",
    "نفع",
    "النفع",
    "ينفع",
    "انهار",
    "الانهيار",
    "أفانت",
    "المعاصرون",
    "الطمع",
    "الجشع",
    "طماع",
    "بطمع",
    "تجسد",
    "الآلهة",
    "تجسدات",
    "مرحبا",
    "انتقم",
    "المنتقم",
    "المنتقمون",
    "ينتقم",
    "الانتقام",
    "آفينتين",
    "المتوسط",
    "حسب",
    "متوسط",
    "الوسطية",
    "المتوسطات",
    "حساب",
    "بنفور",
    "متجنب",
    "تجنب",
    "التجنب",
    "أفيري",
    "الأفستا",
    "طيري",
    "الأقفاص",
    "مربي",
    "مربو",
    "القفص",
    "طر",
    "مطامع",
    "بإلكترونيات",
    "إلكترونيات",
    "أفيس",
    "الأفوكادو",
    "الهوايات",
    "الطائر",
    "المائي",
    "أفوجادرو",
    "اجتنابه",
    "يمكن",
    "تجنبه",
    "الاجتناب",
    "متجنبون",
    "الأوزان",
    "آفون",
    "بصراحة",
    "مصرح",
    "العم",
    "انتظر",
    "ينتظر",
    "استيقظ",
    "صحا",
    "أيقظ",
    "يستيقظ",
    "الاستيقاظ",
    "الجائزة",
    "مستحق",
    "مكافأة",
    "منح",
    "مكافئ",
    "مكافئون",
    "المكافئة",
    "الجوائز",
    "مدرك",
    "الوعي",
    "مغمور",
    "البعد",
    "أهاب",
    "رفع",
    "بفظاعة",
    "الفظاعة",
    "لحظة",
    "الإهابة",
    "الشوكة",
    "المظلة",
    "مغطى",
    "بمظلة",
    "المظلات",
    "الفأس",
    "طرد",
    "الفؤوس",
    "محوري",
    "محوريا",
    "إبطي",
    "الطرد",
    "القيم",
    "البديهية",
    "بديهي",
    "بديهية",
    "بديهيات",
    "تبسيط",
    "يبسط",
    "بسط",
    "البديهيات",
    "أكسيون",
    "أكسيونات",
    "المحاور",
    "قنفذ",
    "قنافذ",
    "عصبية",
    "أير",
    "أيرز",
    "المصوتون",
    "بنعم",
    "أيليسبري",
    "الأزالي",
    "أزيربيدجاني",
    "أذربيجان",
    "الأذريبيجاني",
    "الأذريبيجانية",
    "السمت",
    "السمتي",
    "الأزيموث",
    "الآزوت",
    "نيتروجيني",
    "الأزتكي",
    "أزول",
    "الأزرق",
    "السماوي",
    "ضغط",
    "المرتفع",
    "الأمحارك",
    "الاثيوبية",
    "الرقمي",
    "تعريب",
    "اللدغ",
    "الشوك",
    "حافتين",
    "رأسين",
    "يعتمد",
    "الإختصارات",
    "مميز",
    "لايؤمن",
    "كافر",
    "انبوب",
    "لتصريف",
    "المياه",
    "الخطاب",
    "الرسمي",
    "متلاصقين",
    "ملتحمين",
    "حلية",
    "فلكية",
    "المسافات",
    "وتعادل",
    "150",
    "البكتيريا",
    "يتأثر",
    "بالبكتيريا",
    "أعقف",
    "المغتابون",
    "يغتاب",
    "اللوح",
    "الخلفي",
    "العمود",
    "الفقري",
    "الفقرية",
    "مرهق",
    "السلسلة",
    "المرجعية",
    "مستتر",
    "التراجع",
    "التراجعات",
    "الخلفية",
    "الخلفيات",
    "رجع",
    "لاعبو",
    "أعد",
    "ملئ",
    "أعيد",
    "إعادة",
    "جئ",
    "بنتيجة",
    "عكسية",
    "جاء",
    "يجيء",
    "مجيء",
    "الطاولة",
    "الضربة",
    "بظهر",
    "اللكمة",
    "الضربات",
    "المساندة",
    "البطانات",
    "الثورة",
    "ثائر",
    "وصلة",
    "عودة",
    "القفل",
    "التل",
    "المتأخرة",
    "الأمر",
    "المؤجل",
    "إلغاء",
    "تعديلات",
    "العمليات",
    "المخفقة",
    "حقيبة",
    "الرحال",
    "الرحل",
    "حقائب",
    "مواسير",
    "خلفية",
    "لوحة",
    "الكترونية",
    "معززة",
    "لوحات",
    "لوح",
    "حامي",
    "للظهر",
    "ألواح",
    "حامية",
    "رذاذ",
    "مرتد",
    "مبعثرا",
    "ارتداد",
    "مبعثر",
    "بعثرة",
    "مرتدة",
    "الشرطة",
    "بشرطة",
    "الشرط",
    "تردى",
    "يتردى",
    "المنحرف",
    "المنحرفون",
    "التردي",
    "زر",
    "لإعادة",
    "الكتابة",
    "حذف",
    "أزرار",
    "الحذف",
    "طاعن",
    "الطعن",
    "بالظهر",
    "الكواليس",
    "أدراج",
    "خيط",
    "يخيط",
    "تخييط",
    "المصد",
    "المصدات",
    "سباحة",
    "سباحات",
    "تتبع",
    "تراجع",
    "متعقب",
    "متعقبون",
    "يتراجع",
    "متخلف",
    "التخلفات",
    "جرف",
    "خلفي",
    "المعزل",
    "المعزلات",
    "النائية",
    "المنعزل",
    "المنعزلون",
    "الفناء",
    "الأفنية",
    "لحم",
    "الخنزير",
    "جرثومي",
    "بكتيري",
    "للجراثيم",
    "الجراثيم",
    "مبيدات",
    "بكتريولوجي",
    "الجرثوم",
    "زايد",
    "بادن",
    "الشارة",
    "البادجر",
    "ألح",
    "الإلحاح",
    "الشارات",
    "التأشير",
    "المزاح",
    "مازح",
    "ممازحة",
    "الوعرة",
    "الأراضي",
    "خارجون",
    "تنس",
    "الريشة",
    "الشتم",
    "السوء",
    "مساوئ",
    "بفن",
    "الحاجز",
    "حير",
    "المحير",
    "المحيرون",
    "الحواجز",
    "التحيير",
    "بحيرة",
    "الحقيبة",
    "البلياردو",
    "الخبز",
    "المقدار",
    "مقادير",
    "الأمتعة",
    "عربة",
    "الحقائب",
    "حمال",
    "حمالو",
    "تدلى",
    "معدوا",
    "فضفضة",
    "فضفاضات",
    "بفضفضة",
    "التهدل",
    "التدلي",
    "فضفاض",
    "بغداد",
    "باجلي",
    "مزماري",
    "الزمارون",
    "مزمار",
    "القربة",
    "الرغيف",
    "الفرنسي",
    "هراء",
    "باهاما",
    "الباهاما",
    "البحرين",
    "الكفالة",
    "إطلاق",
    "سراح",
    "بكفالة",
    "أطلق",
    "بضمان",
    "المنزحة",
    "الأسوار",
    "المأمور",
    "المأمورون",
    "دائرة",
    "النفوذ",
    "بايلي",
    "الإنزال",
    "الكفالات",
    "الضامن",
    "الضامنون",
    "بينايت",
    "بيرد",
    "اصطاد",
    "الاصطياد",
    "الطعوم",
    "الجوخ",
    "باك",
    "اخبز",
    "خبز",
    "الباكليت",
    "الخباز",
    "المخابز",
    "الخبازون",
    "بيكرسفيلد",
    "المخبز",
    "يخبز",
    "بختياري",
    "المعجنات",
    "البقلاوة",
    "باكو",
    "الآلة",
    "الوترية",
    "التوازن",
    "توازن",
    "الموازن",
    "الموازنون",
    "الموازين",
    "بلبووة",
    "بلكونة",
    "البلكونات",
    "البلكونة",
    "أصلع",
    "صلعا",
    "اللغو",
    "الأصلع",
    "باندفاع",
    "ذووا",
    "الرؤوس",
    "الصلعاء",
    "بقسوة",
    "الصلع",
    "بالدوين",
    "الحزمة",
    "البلين",
    "حاقد",
    "بحقد",
    "الهلاك",
    "رزمة",
    "مربوطة",
    "صناع",
    "الرزم",
    "رزم",
    "بالفور",
    "بالي",
    "البالينيز",
    "العارضة",
    "بلقاني",
    "تقسيم",
    "البلقنة",
    "دول",
    "البلقان",
    "تقاعس",
    "بالكر",
    "أعند",
    "الحران",
    "التقاعس",
    "العارضات",
    "القصة",
    "المنشد",
    "المنشدون",
    "أغاني",
    "شعبية",
    "الشعبية",
    "القصص",
    "بالارد",
    "الثقل",
    "بالأثقال",
    "الأثقال",
    "تكور",
    "بالر",
    "راقصة",
    "الباليه",
    "راقصات",
    "للباليه",
    "بالباليه",
    "التكور",
    "قذائفي",
    "قذائفيا",
    "القذائف",
    "انتفخ",
    "ركوب",
    "راكب",
    "راكبوا",
    "الاقتراع",
    "اقترع",
    "مقترع",
    "الاقتراعات",
    "اللاعب",
    "اللاعبون",
    "قلم",
    "الحبر",
    "قاعة",
    "الرقص",
    "قاعات",
    "الكرات",
    "الضجة",
    "إنعاشا",
    "بلسمي",
    "الاعتدال",
    "منعش",
    "البلسا",
    "البلسم",
    "الدهانات",
    "بلطيقي",
    "بالتيمور",
    "بالتيموري",
    "الدرابزين",
    "الدرابزينات",
    "بالزاك",
    "بام",
    "باماكو",
    "بامبيرجر",
    "بامباي",
    "الخيزران",
    "اخدع",
    "خدع",
    "الخداع",
    "الخدعة",
    "الحظر",
    "بنك",
    "عادي",
    "التفاهات",
    "التفاهة",
    "عادية",
    "الموزة",
    "بانبري",
    "بانكروفت",
    "الفرقة",
    "الضمادة",
    "ضمد",
    "مضمد",
    "الضمادات",
    "التضميد",
    "البلاستر",
    "المنديل",
    "الصندوق",
    "الصناديق",
    "العصابة",
    "بانديكس",
    "طوق",
    "مرافق",
    "تقاذف",
    "يتقاذف",
    "التطويق",
    "قاطع",
    "قطاع",
    "المايسترو",
    "أحزمة",
    "الرصاص",
    "الموجة",
    "مصفاة",
    "الفرق",
    "المسرح",
    "المسارح",
    "العربة",
    "العربات",
    "متقوس",
    "التقاذف",
    "مصدر",
    "أذى",
    "التوت",
    "السام",
    "مفجع",
    "الأذى",
    "الدوي",
    "دوي",
    "أحدث",
    "ضجيجا",
    "السجق",
    "بانكوك",
    "بنجلاديش",
    "الأساور",
    "بانجور",
    "بانجوي",
    "إصدار",
    "إنف",
    "نفي",
    "ثافي",
    "ينفي",
    "النفي",
    "البانجو",
    "دفاتر",
    "الرصيد",
    "أودع",
    "مالا",
    "المصرفي",
    "المصرفيون",
    "البنوك",
    "البنكنوت",
    "التمويل",
    "مول",
    "التمويلات",
    "المفلس",
    "الإفلاس",
    "أفقر",
    "الإفقار",
    "مفلس",
    "المفلسون",
    "حظر",
    "الراية",
    "الرايات",
    "الوليمة",
    "أولم",
    "المضيف",
    "المضيفون",
    "استمتاع",
    "بالطعام",
    "اقامة",
    "الولائم",
    "الشؤم",
    "البنطم",
    "مزح",
    "ممازح",
    "ممازحون",
    "المزح",
    "بمزاح",
    "البانتو",
    "الشجر",
    "الاستوائي",
    "المعمد",
    "التعميد",
    "بالتعميد",
    "بمعمودية",
    "بابتست",
    "مياه",
    "ماء",
    "المعمدون",
    "اعمد",
    "عمد",
    "يعمد",
    "رسم",
    "بياني",
    "خطي",
    "باربادوس",
    "باربارا",
    "الهمجي",
    "بربري",
    "الهمجيون",
    "متوحش",
    "بربرية",
    "البربرية",
    "الوحشي",
    "الوحشية",
    "اجعل",
    "متوحشا",
    "يجعل",
    "متوحشة",
    "فظاعة",
    "الشواية",
    "شوى",
    "الشوايات",
    "الشوي",
    "الحديد",
    "الحدائد",
    "الحلاق",
    "حلق",
    "الحلق",
    "الباربريس",
    "الحلاقون",
    "الغناء",
    "مهدئ",
    "منوم",
    "المهدئات",
    "باربر",
    "ذقن",
    "سلك",
    "الأسلاك",
    "الشائكة",
    "برشلونة",
    "باركلاي",
    "شفرة",
    "التعرف",
    "الشاعر",
    "بالشعر",
    "الشعراء",
    "عاري",
    "حافي",
    "مكشوف",
    "سرج",
    "كشف",
    "متبجح",
    "بتبجح",
    "الوقاحات",
    "أعزل",
    "بالكاد",
    "ولدت",
    "الخلو",
    "عريا",
    "يكشف",
    "أزيار",
    "الحانات",
    "زير",
    "البيعة",
    "الرابحة",
    "ساوم",
    "المساوم",
    "المساومون",
    "التفاوض",
    "البيعات",
    "العبارة",
    "تخبط",
    "الملاحون",
    "العبارات",
    "التخبط",
    "الكشف",
    "الباريتون",
    "الباريوم",
    "النباح",
    "نبح",
    "الجرسون",
    "المنادي",
    "المنادون",
    "النبح",
    "الشعير",
    "بارلو",
    "النادلة",
    "النادلات",
    "الشونة",
    "بارناباس",
    "الإوز",
    "بارنأرد",
    "بارنيز",
    "بارنيت",
    "ملء",
    "بارنهأرد",
    "الشون",
    "بارنستابل",
    "أخطب",
    "متنقلا",
    "المتنقل",
    "المتنقلون",
    "خطابة",
    "البارومتر",
    "البارومترات",
    "بارومتري",
    "محراري",
    "البارون",
    "البارونة",
    "البارونات",
    "البارونيت",
    "باروني",
    "منازل",
    "منزلة",
    "بر",
    "اهتف",
    "الفريق",
    "إسكان",
    "ثكنات",
    "الثكنة",
    "البركودة",
    "حجز",
    "السدود",
    "الحجز",
    "الإهمال",
    "بار",
    "قضبان",
    "البرميل",
    "إنطلاق",
    "البراميل",
    "قاحل",
    "مقبولة",
    "القحل",
    "عقيم",
    "(جمع)",
    "باريت",
    "المشبك",
    "المشابك",
    "المتراس",
    "حصن",
    "المتاريس",
    "التحصين",
    "باري",
    "سيديد",
    "بارنجر",
    "بارنجتون",
    "المحام",
    "بارون",
    "البارات",
    "عربات",
    "باريمور",
    "مقعد",
    "للبار",
    "مقاعد",
    "بارستو",
    "اسق",
    "النادل",
    "النوادل",
    "المقايضة",
    "قايض",
    "المقايض",
    "المقايضون",
    "المقايضات",
    "بارث",
    "بارثولومو",
    "بارتليت",
    "بارتوك",
    "بارتون",
    "مركز",
    "باس",
    "أساسي",
    "باسالي",
    "البازلت",
    "بازلتي",
    "باسكوم",
    "رقم",
    "البيسبول",
    "أحادي",
    "الألواح",
    "أسس",
    "باسل",
    "أساس",
    "اللاأساس",
    "الأساس",
    "الأسس",
    "بدناءة",
    "الباسيمان",
    "الباسيمين",
    "البدروم",
    "البدرومات",
    "الخسة",
    "القاعدات",
    "الباشا",
    "البلطجي",
    "البلطجيون",
    "اللكمات",
    "خجول",
    "بخجل",
    "أساسا",
    "الدعاميات",
    "باسي",
    "الريحان",
    "باسيلار",
    "كاتدرائية",
    "البازيليكا",
    "البازيليسق",
    "الرياحين",
    "الحوض",
    "حوضي",
    "التأسيس",
    "الأحواض",
    "تمتع",
    "السلة",
    "كرة",
    "كرات",
    "السلات",
    "التمتع",
    "مستقعد",
    "القميص",
    "الباس",
    "الباست",
    "باسيت",
    "السرير",
    "الأسرة",
    "الباص",
    "الباسوون",
    "الزيزفون",
    "أميركي",
    "اللحاء",
    "النذل",
    "الإفساد",
    "يحط",
    "شرعي",
    "الأنذال",
    "غط",
    "بالحساء",
    "غطى",
    "حائك",
    "الألحية",
    "باستيليز",
    "ضربة",
    "العصا",
    "ضربات",
    "تغطية",
    "المعقل",
    "محصن",
    "المعاقل",
    "الخفاش",
    "باتافيا",
    "الدفعة",
    "مختلق",
    "بالدفعة",
    "باتشلدر",
    "الدفعات",
    "اهدأ",
    "قوارب",
    "باتيمان",
    "باتر",
    "يهدأ",
    "الحمام",
    "الاستحمام",
    "استحم",
    "السباح",
    "السباحون",
    "الحمامات",
    "الإسفاف",
    "البشكير",
    "البشاكير",
    "البانيو",
    "باثرست",
    "الغواصة",
    "طباعة",
    "المنسوجات",
    "الذعر",
    "باتيستا",
    "القماش",
    "القطني",
    "العصي",
    "الخفافيش",
    "بات",
    "الكتيبة",
    "الكتائب",
    "الوصلة",
    "تزايد",
    "التزايد",
    "الخليط",
    "حطم",
    "البطاريات",
    "الأخلاط",
    "البطارية",
    "مشغل",
    "بالبطارية",
    "جنونا",
    "المعركة",
    "حارب",
    "ميدان",
    "القتال",
    "ميادين",
    "جبهة",
    "جبهات",
    "ساحة",
    "ساحات",
    "أبراج",
    "محصنة",
    "المقاتل",
    "المقاتلون",
    "المعارك",
    "حربية",
    "سفن",
    "المحاربة",
    "إثارة",
    "الطرائد",
    "بجناح",
    "شاكلة",
    "جناح",
    "الحلي",
    "الباود",
    "بودلاير",
    "بالثانية",
    "اشارة",
    "باور",
    "بوهوس",
    "بوستش",
    "مؤلف",
    "البوكسيت",
    "بافاريا",
    "بافاري",
    "القواد",
    "القوادون",
    "صح",
    "صاح",
    "صائح",
    "صائحون",
    "الصياح",
    "يصيح",
    "باكستر",
    "الخليج",
    "متصاعد",
    "بالخليج",
    "الميريقة",
    "النظرية",
    "الافتراضية",
    "بيلور",
    "بيلي",
    "الحربة",
    "مطعون",
    "الحراب",
    "بايون",
    "الجدول",
    "الجداول",
    "بيبورت",
    "بايروث",
    "الخلجان",
    "البازار",
    "البازارات",
    "البازوكة",
    "س",
    "نسخة",
    "أرقام",
    "عشرية",
    "حقوق",
    "النشر",
    "كن",
    "بيتشكوم",
    "البائع",
    "المتجول",
    "أرسى",
    "الشواطئ",
    "المربط",
    "المرابط",
    "الإرساء",
    "الفنار",
    "أرشد",
    "الإرشاد",
    "الفنارات",
    "الخرزة",
    "مطرز",
    "الديكور",
    "الشماس",
    "الشمامسة",
    "الخرز",
    "المصلي",
    "المستأجر",
    "كالخرز",
    "البيجل",
    "المنقار",
    "منقار",
    "الكوب",
    "الأكواب",
    "المناقير",
    "الشعاع",
    "ابتسم",
    "متعاطي",
    "متعاطوا",
    "الابتسام",
    "الفول",
    "الرأسه",
    "مؤذية",
    "الدب",
    "باحتمال",
    "عنب",
    "اللحية",
    "ملتحي",
    "نمو",
    "شعر",
    "الوجه",
    "لحية",
    "اللحى",
    "بيردزلي",
    "الشيال",
    "الشيالون",
    "الاتجاهات",
    "فظ",
    "بجفاء",
    "الدببة",
    "الفرو",
    "صغيرة",
    "اللبأ",
    "كرها",
    "مغلوب",
    "يصد",
    "المضرب",
    "المضارب",
    "مبهج",
    "بابتهاج",
    "التقديس",
    "الإبتهاج",
    "أبن",
    "يؤبن",
    "أبنت",
    "التأبين",
    "الطوبى",
    "الشاذ",
    "بياتريس",
    "الدقات",
    "بوتشامب",
    "نبيذ",
    "بوجلاي",
    "بيماونت",
    "بيوريجارد",
    "الأصدقاء",
    "جميل",
    "جميلة",
    "جودة",
    "التجميل",
    "جمل",
    "المجمل",
    "يجمل",
    "بيكس",
    "القندس",
    "خشب",
    "خفيف",
    "مضغوط",
    "فرو",
    "الأرنب",
    "المصبوغ",
    "للنسج",
    "القنادس",
    "بيفيرتون",
    "بيبوب",
    "البيبوب",
    "ساكن",
    "الإيقاف",
    "أصبح",
    "لأن",
    "بكتل",
    "بيكر",
    "المثبتة",
    "بيكمان",
    "بيكي",
    "احجب",
    "حجب",
    "الحجب",
    "يحجب",
    "اصبح",
    "يصبح",
    "لطخ",
    "التلطيخ",
    "يلطخ",
    "أبهر",
    "بهر",
    "الإبهار",
    "يبهر",
    "البقة",
    "البق",
    "فرش",
    "متطبق",
    "بدر",
    "نباتات",
    "غطاء",
    "عرقل",
    "العرقلة",
    "يعرقل",
    "المرافقون",
    "بيدفورد",
    "اعتم",
    "عتم",
    "التعتيم",
    "الفوضى",
    "مستشفيات",
    "المجانين",
    "شريك",
    "شركاء",
    "البدو",
    "وعاء",
    "للتبول",
    "بالفراش",
    "أوعية",
    "وسخ",
    "رث",
    "النوم",
    "للنوم",
    "القرحة",
    "القرح",
    "المفرش",
    "المفارش",
    "الزنبرك",
    "الإطار",
    "الإطارات",
    "القش",
    "أوقات",
    "بيب",
    "الزان",
    "بيتشام",
    "بيتشير",
    "بيتشوود",
    "اللحم",
    "اشتكى",
    "أضخم",
    "الأضخم",
    "الشكوى",
    "الشكاوى",
    "خلية",
    "خلايا",
    "النحال",
    "النحالون",
    "تربية",
    "المباشر",
    "المباشرة",
    "الشياطين",
    "الصفارة",
    "صفر",
    "التصفير",
    "الصفافير",
    "كؤوس",
    "الشمع",
    "البنجر",
    "بيتهوفن",
    "الخنفسة",
    "الخنفسات",
    "الفرار",
    "صب",
    "بيفاليد",
    "أصاب",
    "الإصابة",
    "يصيب",
    "ناسب",
    "يناسب",
    "المناسبة",
    "لف",
    "بالضباب",
    "يلف",
    "مسبقا",
    "لوث",
    "التلويث",
    "يلوث",
    "بيفريديجيند",
    "المصادقة",
    "الخبل",
    "تسول",
    "بدأ",
    "أنجب",
    "ينجب",
    "المنجب",
    "المنجبون",
    "الإنجاب",
    "الشحاذ",
    "فاق",
    "القلة",
    "هزيل",
    "الشحاذون",
    "الفقر",
    "التسول",
    "ابدأ",
    "البداية",
    "البدايات",
    "يبدأ",
    "انصرف",
    "البغونية",
    "استكثر",
    "حسود",
    "يستكثر",
    "الاستكثار",
    "حاسدة",
    "يتسول",
    "المخادع",
    "خادع",
    "المصلحة",
    "تصرف",
    "متصرف",
    "السلوك",
    "سلوكي",
    "سلوكيا",
    "السلوكية",
    "السلوكيات",
    "بالسلوكيات",
    "بالسلوك",
    "السلوكي",
    "اقطع",
    "قطع",
    "يقطع",
    "رأى",
    "الحمولة",
    "الحمولات",
    "أمر",
    "طلب",
    "الوصايا",
    "انظر",
    "مدين",
    "الناظر",
    "النظار",
    "الرؤية",
    "يرى",
    "اقتضيت",
    "اقتضى",
    "المصالح",
    "الاقتضاء",
    "البيج",
    "بكين",
    "بيروت",
    "البل",
    "استفض",
    "استفاض",
    "الاستفاضة",
    "يستفيض",
    "روسيا",
    "البيضاء",
    "بالاروسي",
    "يؤخر",
    "متأخر",
    "متأخرا",
    "التأخير",
    "التأخر",
    "حبل",
    "تربيط",
    "التجشؤ",
    "تجشأ",
    "التدفق",
    "عجوز",
    "عجائز",
    "حاصر",
    "الحصار",
    "يحاصر",
    "بلفاست",
    "الأبراج",
    "البرج",
    "البلجيكي",
    "البلجيكيون",
    "بلجيكا",
    "بلغراد",
    "أخف",
    "أخفى",
    "المعتقدات",
    "ناقض",
    "نواقض",
    "يخفي",
    "المصداقية",
    "للتصديق",
    "استهن",
    "استهان",
    "الاستخفاف",
    "مستهين",
    "يستهين",
    "الاستهانة",
    "بيليز",
    "الجرس",
    "بيلا",
    "البيلادونا",
    "بيلامي",
    "بيلاتريكس",
    "الفراشون",
    "الحسناء",
    "الحسناوات",
    "بيليفيل",
    "عدوانية",
    "العدواني",
    "بطن",
    "قتالي",
    "المحاربون",
    "بيلينجهام",
    "بيليني",
    "الخوار",
    "خار",
    "الأجراس",
    "الكراز",
    "بيلوود",
    "المغص",
    "ممغوص",
    "التوجع",
    "السرة",
    "السرر",
    "الكبير",
    "كعكة",
    "محلاة",
    "النفخ",
    "بلمنت",
    "بليت",
    "خص",
    "الخصوص",
    "الطمأنينة",
    "المتعلقات",
    "أحب",
    "أسفل",
    "بلشزار",
    "الحزام",
    "مربوط",
    "لصنع",
    "السيور",
    "بيلتون",
    "الأحزمة",
    "بيلتسفيل",
    "بيلشي",
    "بلفيدير",
    "بيلفيدير",
    "الكذب",
    "المقدس",
    "بيمان",
    "تحسر",
    "التحسر",
    "يتحسر",
    "حيرة",
    "المقعد",
    "المحكمة",
    "المقاعد",
    "المعيار",
    "اختبارات",
    "المعايير",
    "علامات",
    "خاصة",
    "بأعمال",
    "المساحة",
    "انحن",
    "للانحناء",
    "محني",
    "بنديكس",
    "المنحنيات",
    "حديث",
    "الزواج",
    "البينديكتين",
    "الدعاء",
    "الأدعية",
    "دعائي",
    "مبارك",
    "بنيديكت",
    "الإحسان",
    "التبرعات",
    "المتبرع",
    "المتبرعون",
    "المنصب",
    "متصدق",
    "بإحسان",
    "المناصب",
    "مجدي",
    "الاستفادة",
    "المستفيدون",
    "المستفيد",
    "مستفاد",
    "مفيدون",
    "الفائدات",
    "بينيلكس",
    "الكرم",
    "خيري",
    "العطاء",
    "بينجال",
    "البنغالي",
    "يضلل",
    "مضلل",
    "بتضليل",
    "حليم",
    "برقة",
    "بنين",
    "بنيامين",
    "بينيت",
    "بينينجتون",
    "بيني",
    "بينسون",
    "بينثام",
    "بينتلي",
    "بينتون",
    "الميول",
    "بينز",
    "البنزيدرين",
    "بنزين",
    "بيوجراد",
    "بياولف",
    "يغطي",
    "بضماد",
    "ورث",
    "التوريث",
    "توريث",
    "يورث",
    "التركة",
    "التركات",
    "وبخ",
    "يوبخ",
    "التوبيخ",
    "وزن",
    "احرم",
    "مفجوع",
    "الحرمان",
    "بائس",
    "بيرينيسيس",
    "بيريسفورد",
    "البيريه",
    "البيريهات",
    "الجليدي",
    "الشجرة",
    "برغموت",
    "برجن",
    "بيرجر",
    "برجﻻند",
    "بيرجمان",
    "الجليدية",
    "بيرجيسون",
    "بيرجستين",
    "بيرجستروم",
    "مزين",
    "بري",
    "برنجر",
    "بيركيلي",
    "البركيليوم",
    "بيركاويتز",
    "بيركشاير",
    "بيركشايرز",
    "برلين",
    "برليني",
    "برلينيون",
    "بيرليوز",
    "بيرليتز",
    "بيرمان",
    "برمودا",
    "برن",
    "بيرناداين",
    "برنار",
    "بيرنارداين",
    "بيرناردينو",
    "برناردو",
    "بيرنيت",
    "بيرنهأرد",
    "بيرنيس",
    "بيرني",
    "بيرنيني",
    "بيرنولي",
    "بيرنشتاين",
    "بيرا",
    "منتج",
    "ثمر",
    "عليق",
    "هائج",
    "بيرت",
    "المرسى",
    "البرثية",
    "بيرتي",
    "بيرترام",
    "برتراند",
    "بيرويك",
    "البريل",
    "البيريليوم",
    "بيز",
    "التمس",
    "يلتمس",
    "بتوسل",
    "حصار",
    "المحاصر",
    "المحاصرون",
    "المكنسة",
    "المكانس",
    "أسكر",
    "يسكر",
    "سكران",
    "الإسكار",
    "نظارات",
    "مفصل",
    "بسل",
    "بيسمر",
    "بيسي",
    "الأفضل",
    "راسخ",
    "أفضل",
    "وحشي",
    "البهيميات",
    "البهيمية",
    "بأسلوب",
    "تقديم",
    "الحث",
    "يحث",
    "امنح",
    "الأعطية",
    "معطي",
    "معطون",
    "المنح",
    "عطاء",
    "عطاءات",
    "يمنح",
    "التغطية",
    "اعتل",
    "يعتلي",
    "الاعتلاء",
    "اعتلى",
    "بيستس",
    "كتاب",
    "رواجا",
    "الكتب",
    "مبيعا",
    "ذائع",
    "راهن",
    "البيتا",
    "اذهب",
    "ذهب",
    "الذهاب",
    "الجيدات",
    "تجريبية",
    "البيتاترون",
    "البيتل",
    "منكب",
    "الجوزاء",
    "بيتيز",
    "بيث",
    "المكان",
    "باثزدا",
    "ذكر",
    "يذكر",
    "وقعت",
    "مبكرا",
    "بيتوني",
    "خن",
    "الخيانة",
    "الخيانات",
    "خان",
    "الخائن",
    "الخونة",
    "يخون",
    "الخطبات",
    "الرهانات",
    "بيتسي",
    "بتيز",
    "الرهان",
    "المراهن",
    "المراهنون",
    "بيتي",
    "بينهما",
    "البينية",
    "الحافة",
    "مشطوف",
    "الميلا",
    "الميل",
    "الحافات",
    "المشروبات",
    "بيفرلي",
    "اندب",
    "ندب",
    "الندب",
    "يندب",
    "احترس",
    "يحترس",
    "شارب",
    "الحيرة",
    "مذهلة",
    "الارتباك",
    "يحير",
    "اسحر",
    "سحر",
    "يسحر",
    "ساحر",
    "ساحرة",
    "الفتنة",
    "الأسحار",
    "البك",
    "بيزيير",
    "بوتان",
    "ثنائي",
    "بياليستوك",
    "بيانكو",
    "سنوي",
    "التحيز",
    "متحيز",
    "التحيزات",
    "محورين",
    "ثنائية",
    "بب",
    "ذا",
    "مريلة",
    "الشرب",
    "الإنجيل",
    "الأناجيل",
    "إنجيلي",
    "ببليوغرافي",
    "الفهارس",
    "الفهرس",
    "مجنون",
    "القارئ",
    "القراء",
    "مدمن",
    "خمر",
    "سكير",
    "الولع",
    "السكر",
    "مجلسين",
    "تشريعيين",
    "ثاني",
    "الكربونات",
    "مرور",
    "مائتي",
    "لمحاكاة",
    "المفهوم",
    "المتكامل",
    "لساحة",
    "كرومات",
    "بالثاني",
    "مشاحن",
    "مقعر",
    "الوجهين",
    "محدب",
    "الدراجة",
    "ركب",
    "دراجة",
    "الدراجات",
    "بالدرجات",
    "سائق",
    "العرض",
    "انقيادي",
    "المزايد",
    "المزايدون",
    "الدجاج",
    "المزايدة",
    "بيدل",
    "الدجاجة",
    "منتظر",
    "الاتجاه",
    "العروض",
    "يحدث",
    "عامين",
    "فترة",
    "السنتين",
    "بينفيل",
    "النعش",
    "بيرس",
    "النعوش",
    "السلك",
    "البؤرة",
    "النظارة",
    "انقسم",
    "بانقسام",
    "ينقسم",
    "التشعب",
    "التشعبات",
    "متزوج",
    "بالاثنين",
    "متزوجون",
    "بامرأتين",
    "بالزواج",
    "زوجين",
    "بجلو",
    "بجز",
    "المتعصب",
    "متعصب",
    "متزمت",
    "التعصب",
    "المتعصبون",
    "الهام",
    "الجواهر",
    "السائق",
    "السائقون",
    "البيكيني",
    "مرتدية",
    "البيكينيات",
    "الشفهي",
    "الثنائية",
    "القيد",
    "الصفراء",
    "القلب",
    "منفوخ",
    "البلهارزيا",
    "مجيد",
    "اللغتين",
    "ثنائيات",
    "بالصفراء",
    "صفراوي",
    "الصفراوية",
    "مخادع",
    "مخادعون",
    "بيلابل",
    "اللوحات",
    "الفواتير",
    "معدون",
    "أسكن",
    "الإسكان",
    "المحفظة",
    "المحافظ",
    "الزملاء",
    "بليون",
    "البليونير",
    "البلايين",
    "الانتفاخ",
    "متموج",
    "الإناء",
    "الجدي",
    "الجديان",
    "بيلتمور",
    "بنظام",
    "المعدنين",
    "بيميني",
    "الجزيء",
    "بثنائية",
    "الجزء",
    "مجلات",
    "شهرية",
    "مجلة",
    "أسامة",
    "بن",
    "لادن",
    "مولود",
    "10",
    "مارس,",
    "1957",
    "استهزأ",
    "الرقم",
    "العشري",
    "بكلتا",
    "الأذنين",
    "بالأذنين",
    "معا",
    "المشكلة",
    "الملف",
    "ورش",
    "التجليد",
    "الملفات",
    "ورشة",
    "الغلاف",
    "ملزم",
    "الأغلفة",
    "لفة",
    "بطانية",
    "اللبلاب",
    "بنج",
    "الانغماس",
    "بنجين",
    "بينجهام",
    "بينجمتن",
    "موفقة",
    "البنغو",
    "عيني",
    "عينين",
    "المعظمة",
    "معادلة",
    "حدين",
    "معادلات",
    "النواة",
    "كيميائي",
    "كيمياويا",
    "حيويا",
    "كيمياء",
    "هش",
    "بيوفيدباك",
    "كاتب",
    "كتاتيب",
    "بالسيرة",
    "بيولوجي",
    "بيولوجيا",
    "بيولوجيات",
    "البيولوجي",
    "العلماء",
    "البيولوجيون",
    "بالطب",
    "الأحيائي",
    "الطب",
    "إحصاء",
    "إحصائيات",
    "بيولوجية",
    "جزيء",
    "جزيئات",
    "البيولوجية",
    "الالكترونية",
    "فيزياء",
    "فيزيولوجي",
    "فيزيولوجية",
    "فيزيولوجيون",
    "فيزيولوجيا",
    "المحيط",
    "المحيطات",
    "الإحصاءات",
    "الأحيائية",
    "كائنات",
    "البيوتايت",
    "مكون",
    "الحزبين",
    "قسمين",
    "بالقدمين",
    "قدمين",
    "العرق",
    "البيرش",
    "مصنوع",
    "بيرشر",
    "الأبله",
    "صياح",
    "الطير",
    "صائد",
    "طير",
    "الإنكسار",
    "الثنائي",
    "للضوء",
    "بانكسار",
    "ثنائيا",
    "برجيت",
    "بيرمينجهام",
    "الميلاد",
    "النسل",
    "أعياد",
    "بيرثيد",
    "الشامة",
    "مسقط",
    "مساقط",
    "المواليد",
    "معدلات",
    "الحق",
    "الطبيعي",
    "الحقوق",
    "الطبيعية",
    "جوهرة",
    "بيسكين",
    "البسكويت",
    "البسكويتات",
    "اشطر",
    "شطر",
    "الشطر",
    "التنصيف",
    "المنصف",
    "المنصفات",
    "يشطر",
    "مزدوج",
    "التسلسل",
    "المخنث",
    "ازدواجية",
    "الجنس",
    "المخنثون",
    "الأسقف",
    "الأسقفية",
    "الأسقفيات",
    "بيزمارك",
    "بيسمارك",
    "البزموت",
    "البيسون",
    "بس",
    "بيساو",
    "الاستقرار",
    "المقطع",
    "مقارنة",
    "شريحة",
    "للقطع",
    "الكلبة",
    "شكا",
    "الكلبات",
    "الحقد",
    "الهزل",
    "عمق",
    "البت",
    "العضة",
    "العاض",
    "العاضون",
    "العضات",
    "العض",
    "البتنت",
    "عض",
    "ﻻذع",
    "لذعا",
    "البيترن",
    "اللذاعة",
    "بترنت",
    "روت",
    "حلو",
    "ومر",
    "مر",
    "حﻻوة",
    "مرة",
    "القار",
    "بالقار",
    "المعالجة",
    "أصغر",
    "المعلومة",
    "التكافؤ",
    "البيفالف",
    "الصمام",
    "رخوي",
    "مثل",
    "المحارة",
    "عاى",
    "متغيرين",
    "المعسكر",
    "عسكر",
    "العراء",
    "عسكرة",
    "المعسكرات",
    "حادث",
    "الجانب",
    "العلوي",
    "اﻷلماسة",
    "أفش",
    "السر",
    "أفشى",
    "الثرثار",
    "الثرثارون",
    "يثرثر",
    "إفشاء",
    "يفشي",
    "الأسود",
    "يصوت",
    "العليق",
    "البلاكبيرد",
    "العبيد",
    "السبورة",
    "السبورات",
    "السوداء",
    "جسم",
    "اﻷشعاع",
    "بلاك",
    "بيرن",
    "اسودت",
    "اسود",
    "المسود",
    "المفحم",
    "الاسوداد",
    "سوادا",
    "المكياج",
    "قبيلة",
    "الهنود",
    "الحمر",
    "البثرة",
    "البثور",
    "الدهان",
    "ضارب",
    "السواد",
    "العوامة",
    "العوامات",
    "القائمة",
    "أدرج",
    "واضع",
    "اللائحة",
    "إدراج",
    "القائمات",
    "السوداءة",
    "الابتزاز",
    "ابتز",
    "المبتز",
    "المبتزون",
    "الابتزازات",
    "بلاكمان",
    "بلاكمر",
    "أسود",
    "فقط",
    "السود",
    "الحداد",
    "الحدادة",
    "الحدادون",
    "بلاكستون",
    "بلاكول",
    "بلاكوود",
    "المثانة",
    "الشجيرة",
    "العنقودية",
    "المثائن",
    "بلاديرورت",
    "الشفرة",
    "الشفرات",
    "بلين",
    "بلير",
    "بليك",
    "بلاكي",
    "ملام",
    "للوم",
    "لام",
    "مخطىء",
    "بريء",
    "الإثم",
    "اللائم",
    "اللائمون",
    "استحقاق",
    "مسحوق",
    "تجميلي",
    "ابيضت",
    "بلانتشارد",
    "بلانتش",
    "متبيض",
    "يبيض",
    "الابيضاض",
    "رقيق",
    "أرق",
    "الأرق",
    "النفاق",
    "التقليدية",
    "الفراغ",
    "مسح",
    "فتورا",
    "البطانية",
    "البطانيات",
    "التبلد",
    "الفراغات",
    "بلانتون",
    "الضجيج",
    "نعر",
    "النعر",
    "متضجر",
    "سب",
    "اللاعن",
    "اللاعنون",
    "يسب",
    "السب",
    "كفري",
    "كفرية",
    "الكفرية",
    "الانفجار",
    "الناسف",
    "الناسفون",
    "بلاسثول",
    "النسف",
    "الانطلاق",
    "الانفجارات",
    "مراحل",
    "تطور",
    "البويضة",
    "اثغ",
    "سافر",
    "هذى",
    "المتكلم",
    "الهذيان",
    "الثغاء",
    "بلاتز",
    "اللهب",
    "توهج",
    "السترة",
    "الحلل",
    "بتوهج",
    "الوسام",
    "زركش",
    "الزركشة",
    "شعارات",
    "شعار",
    "الأوسمة",
    "بلدج",
    "المبيض",
    "ابيض",
    "المبيضات",
    "كآبة",
    "الكئابة",
    "أدمع",
    "أعمش",
    "ضعف",
    "مشوش",
    "ثغا",
    "النلطق",
    "الماعز",
    "(تقليد",
    "الماعز)",
    "الفقاعات",
    "نزف",
    "انزف",
    "ثقيل",
    "الظل",
    "ثقيلون",
    "النزيف",
    "ينزف",
    "بليكر",
    "شوه",
    "التشويه",
    "انتفض",
    "الانتفاض",
    "ممزوج",
    "الخلاط",
    "الخلاطات",
    "الانسجام",
    "بلينهيم",
    "انسجم",
    "بارك",
    "ميمون",
    "لحسن",
    "الحظ",
    "النعيم",
    "يبارك",
    "النعمة",
    "النعم",
    "المبارك",
    "هب",
    "أفسد",
    "الشيش",
    "أعمى",
    "الأداء",
    "الجيد",
    "الغمامة",
    "معصوبا",
    "التغمية",
    "الغمائم",
    "نقاط",
    "اصدم",
    "مصدوم",
    "يصدم",
    "الصدم",
    "العظاية",
    "العمياء",
    "الطرفة",
    "رمش",
    "الوامض",
    "الأفق",
    "(للفرس)",
    "الرمش",
    "الطرفات",
    "سعيد",
    "تقرح",
    "التقرح",
    "مرح",
    "اهذ",
    "مرحا",
    "الغارة",
    "الغارات",
    "العاصفة",
    "الثلجية",
    "العاصفات",
    "انفخ",
    "منتفخ",
    "الرنجة",
    "ينفخ",
    "النقطة",
    "النقط",
    "بلوك",
    "بلوكادر",
    "الحصارات",
    "القنابل",
    "سد",
    "بلوكير",
    "الحاجزون",
    "المتخلف",
    "المتخلفون",
    "الحصن",
    "الحصون",
    "المصهورة",
    "ممتلئ",
    "الرجال",
    "بلومبيرج",
    "بلومكويست",
    "أشقر",
    "الشقراء",
    "الشقراوات",
    "شقرة",
    "المذبحة",
    "مروع",
    "دم",
    "الكلب",
    "البوليسي",
    "البوليسية",
    "مدمى",
    "نزفا",
    "دامية",
    "الدموي",
    "سلمي",
    "سلميا",
    "إراقة",
    "دماء",
    "السلالة",
    "الدموية",
    "الدماء",
    "محتقن",
    "بالدم",
    "بقعة",
    "ملطخ",
    "بقع",
    "سفاح",
    "الإدماء",
    "الزهرة",
    "تفتح",
    "البنطلون",
    "البنطلونات",
    "بلومفيلد",
    "التفتح",
    "بلومنغتن",
    "الزهور",
    "الغلطات",
    "أزهر",
    "الإزهار",
    "البقعة",
    "مبقع",
    "التلطخ",
    "شامة",
    "البقع",
    "نشف",
    "النشافة",
    "التنشيف",
    "البلوزة",
    "البلوزات",
    "إنفخ",
    "الرجعي",
    "للبارود",
    "محترق",
    "طلقة",
    "نفخ",
    "المنفاخ",
    "المنافيخ",
    "السمكة",
    "المنتفخة",
    "الذباب",
    "الذبابة",
    "الثقب",
    "الهبوب",
    "الجنسية",
    "اللسان",
    "الخ",
    "موقد",
    "اللحام",
    "مواقد",
    "المكبرة",
    "بلاوبس",
    "مهندم",
    "انتحب",
    "الانتحاب",
    "أزرق",
    "أسماك",
    "السالمون.",
    "يوجد",
    "ألاسكا",
    "العنب",
    "أنواع",
    "البط",
    "البلوبيرد",
    "القنطريون",
    "عنبري",
    "ازرق",
    "شره",
    "سمكة",
    "البلو",
    "جراس",
    "الجندي",
    "البحري",
    "الزرقة",
    "خطط",
    "الخطط",
    "زرقة",
    "البلووز",
    "المثقفة",
    "الهصطونية",
    "مزرق",
    "تحايل",
    "بتلقائية",
    "التهريج",
    "النصبات",
    "الصبغة",
    "الزرقاء",
    "بلم",
    "بلمينثال",
    "الخطأ",
    "البندقية",
    "المتخبط",
    "بتخبط",
    "الأخطاء",
    "كسر",
    "يكسر",
    "الضبابية",
    "انطمس",
    "تشوشا",
    "الانطماس",
    "الضباب",
    "القول",
    "المفاجئ",
    "قال",
    "مندفعا",
    "بلرتر",
    "قول",
    "الأقوال",
    "المفاجئة",
    "الحمرة",
    "احمر",
    "أحمر",
    "الخدود",
    "احمرار",
    "تبجح",
    "المتبجح",
    "المتبجحات",
    "التبجح",
    "عاصف",
    "التهديدات",
    "بلتوورست",
    "الجادة",
    "بليث",
    "دبليو",
    "البواء",
    "التلميذ",
    "التلاميذ",
    "الركوب",
    "اللوكاندة",
    "البنسيونات",
    "الإيواء",
    "الاجتماع",
    "الخنازير",
    "المفخرة",
    "تفاخر",
    "المتفاخر",
    "المتفاخرون",
    "متفاخر",
    "بتفاخر",
    "التفاخر",
    "المفاخر",
    "القارب",
    "بالباخرة",
    "الطاقية",
    "الطواقي",
    "المرفأ",
    "المرافئ",
    "الزوارق",
    "حمولة",
    "المراكبي",
    "المراكبة",
    "القوارب",
    "المراكب",
    "البوب",
    "تمايل",
    "بوبي",
    "الشرطيون",
    "البكرة",
    "التمايل",
    "البكر",
    "الشرطي",
    "البوبكات",
    "طائر",
    "مغرد",
    "طيور",
    "بوبرو",
    "الزلاقة",
    "تزحلق",
    "الجليد",
    "قصير",
    "السماني",
    "بوكا",
    "كأس",
    "البيرا",
    "بوكوورست",
    "أنبئ",
    "أنبأ",
    "بودينهييم",
    "بوديساتفا",
    "الصدر",
    "بالكامل",
    "الإنباء",
    "بودليايان",
    "لاعب",
    "كمال",
    "أجسام",
    "لاعبون",
    "الحارس",
    "الحراس",
    "شكلا",
    "بوينج",
    "اليونان",
    "بويوتيا",
    "(مدينة",
    "يونانية)",
    "البويري",
    "المستنقع",
    "بوجارت",
    "همفري",
    "الهم",
    "الغيلان",
    "الهموم",
    "تعثر",
    "وحلا",
    "التعثر",
    "تحير",
    "يتحير",
    "التحير",
    "موحل",
    "التصويب",
    "بوجوتا",
    "المستنقعات",
    "زائف",
    "بوهيم",
    "بوهيميا",
    "البوهيمي",
    "بوهر",
    "اغل",
    "غلى",
    "الغلاية",
    "غلايات",
    "بويليربليت",
    "الغلايات",
    "الغليان",
    "بويزي",
    "أيداهو",
    "بصخب",
    "الصخب",
    "التقيأ",
    "اجترئ",
    "ملاحظة",
    "عريضة",
    "أجرأ",
    "الأجرأ",
    "بأحرف",
    "بولدفاسيس",
    "طبع",
    "بحروف",
    "ثخينة",
    "بجرأة",
    "الصراحة",
    "الجذع",
    "الجذوع",
    "البوليطس",
    "بوليفار",
    "بوليفيا",
    "الكيس",
    "السكين",
    "بولونيا",
    "البولومتر",
    "البولومترات",
    "السكاكين",
    "البلشفي",
    "البلشفيون",
    "البلشفية",
    "بالبلشفية",
    "بولشوي",
    "الوسادة",
    "دعم",
    "مساند",
    "التدعيم",
    "الوسائد",
    "الترباس",
    "اندفع",
    "لفصل",
    "النخالة",
    "الطحين",
    "المنخل",
    "الاندفاع",
    "بولتون",
    "الترابيس",
    "بولتزمان",
    "القرص",
    "الأقراص",
    "اقصف",
    "قصف",
    "القصف",
    "القذف",
    "يقصف",
    "منمق",
    "طنان",
    "بومباستس",
    "بومباي",
    "منفذ",
    "العملية",
    "القاذفات",
    "بون",
    "الصفقة",
    "الصفقات",
    "بونابارت",
    "بونافنتشر",
    "السند",
    "التعذيب",
    "السلع",
    "الايداع",
    "مضمون",
    "السندات",
    "العظمة",
    "عظام",
    "السماد",
    "النيران",
    "الطبلة",
    "بونهام",
    "الألفة",
    "هزالا",
    "المالك",
    "قطعة",
    "العاج",
    "البينيت",
    "صباح",
    "الخير",
    "جنسيا",
    "الأغطية",
    "بونفيل",
    "بوني",
    "أجمل",
    "بونتمبو",
    "المكافأة",
    "المكافآت",
    "الراهب",
    "البوو",
    "ارتكب",
    "المغفلون",
    "الثدي",
    "المغفل",
    "مزدريا",
    "الرقصة",
    "بوهو",
    "بكى",
    "الكتاب",
    "المجلد",
    "أماكن",
    "تجليد",
    "المجلدون",
    "خزانة",
    "خزائن",
    "المساند",
    "بوكير",
    "الحجوزات",
    "نظري",
    "نظريا",
    "بالأفكار",
    "كتبة",
    "الكتيب",
    "الكتيبات",
    "المؤشر",
    "العلامة",
    "موقع",
    "مفضل",
    "الإنترنت",
    "الشرائط",
    "المكتبة",
    "المتنقلة",
    "الكارت",
    "الكروت",
    "الرف",
    "الرفوف",
    "المكتبات",
    "الكشك",
    "المثقف",
    "المثقفون",
    "مثقف",
    "منطقي",
    "منطقيات",
    "الانتعاش",
    "دوى",
    "العامل",
    "انقلب",
    "بوميرانجينج",
    "الحلقات",
    "المدينة",
    "المزدهرة",
    "المدن",
    "يقوم",
    "تافه",
    "الوقح",
    "فظة",
    "الفظاظة",
    "الوقحون",
    "الدافع",
    "زاد",
    "الجرعة",
    "المنشطة",
    "نصرة",
    "التحمس",
    "الحذاء",
    "محمل",
    "للتشغيل",
    "ماسح",
    "الأحذية",
    "ماسحون",
    "حذاء",
    "بوتيس",
    "شمالي",
    "(فلك)",
    "الأكشاك",
    "الجوارب",
    "الركل",
    "خالعات",
    "الرباط",
    "بوتل",
    "روج",
    "خمور",
    "مروج",
    "مروجون",
    "يروج",
    "المنافق",
    "ألبس",
    "إلباس",
    "يلبس",
    "الغنيمة",
    "البوظة",
    "أسرف",
    "الشراب",
    "ثملا",
    "إسراف",
    "ثمل",
    "البورون",
    "البورات",
    "بالبورات",
    "البوركس",
    "بورد",
    "البوردو",
    "الدعارة",
    "بوردين",
    "ملكوم",
    "بالحدود",
    "مقيم",
    "مناطق",
    "الحد",
    "اثقب",
    "الشمالية",
    "ثقب",
    "السأم",
    "الحفرة",
    "الحفار",
    "الحفارون",
    "المملون",
    "بورج",
    "بوريك",
    "ممل",
    "المتطرفة",
    "بوريس",
    "بورلاند",
    "بورنيو",
    "بورنز",
    "العنصر",
    "المعدني",
    "بورونية",
    "وحوامض",
    "سيليسك",
    "الإقليم",
    "الأقاليم",
    "استعر",
    "استعار",
    "المقترض",
    "المقترضون",
    "المقتبس",
    "المقتبسات",
    "يستعير",
    "البورت",
    "المانية",
    "أمريكية",
    "مجال",
    "الصوتيات",
    "خفي",
    "الصدور",
    "بوسون",
    "الرئيس",
    "متحكم",
    "الرؤساء",
    "تسلطا",
    "بتسلط",
    "التدخل",
    "الحلية",
    "بوسطن",
    "بوستوني",
    "بوستونيون",
    "مترجم",
    "نباتي",
    "نباتيا",
    "بوتانيس",
    "ادرس",
    "مدروس",
    "يدرس",
    "النباتية",
    "الرديء",
    "المفسد",
    "المفسدون",
    "متقنة",
    "الرديئة",
    "متقن",
    "النبر",
    "كلاهما",
    "تضايق",
    "التضايق",
    "بوتسوانا",
    "الزجاجة",
    "معبأ",
    "الضيقة",
    "يعبئ",
    "الأشربة",
    "الزجاجات",
    "المعلب",
    "بالقاع",
    "السفلي",
    "سفلي",
    "الكبس",
    "أقصى",
    "القيعان",
    "بوتيلين",
    "سم",
    "فعال",
    "\"أناروبيك\"",
    "المنتجة",
    "لسم",
    "بوتيلينا",
    "التسمم",
    "بوشير",
    "بوجهيد",
    "اشترى",
    "مشترى",
    "الصخرة",
    "بالصخور",
    "الأحجار",
    "الصخور",
    "تدخل",
    "التخليقية",
    "الشارع",
    "الشوارع",
    "تنطط",
    "مجانا",
    "القفزات",
    "نطاط",
    "التنطط",
    "القفزة",
    "كريم",
    "بكرم",
    "إثابة",
    "مجازاة",
    "الجود",
    "الباقة",
    "الباقات",
    "بورباكي",
    "البوربون",
    "البورجوازي",
    "البورجوازيون",
    "البرجوازية",
    "البطرفة",
    "الوعكة",
    "البوتيك",
    "البوتيكات",
    "الوعكات",
    "بوفيير",
    "بقري",
    "بليد",
    "الانحناءة",
    "الإمرأة",
    "السريعة",
    "الطفل",
    "المدلل",
    "بوديتش",
    "رياضيات",
    "وفلكي",
    "نقح",
    "منقح",
    "ينقح",
    "التنقيح",
    "باودوين",
    "الأمعاء",
    "باوين",
    "معرش",
    "بوويس",
    "البوفن",
    "باوي",
    "العزف",
    "السلطانية",
    "رمى",
    "الرامي",
    "العقدة",
    "البولنج",
    "السلطانيات",
    "الوتر",
    "الأوتار",
    "النقل",
    "تلاكم",
    "الملاكم",
    "الملاكمون",
    "مربعي",
    "الشكل",
    "الملاكمة",
    "المخزن",
    "البقس",
    "الولد",
    "ينتمي",
    "اﻷرستقراطية",
    "بويس",
    "المقاطعة",
    "المقاطع",
    "(من",
    "مقاطعة)",
    "بويد",
    "بوير",
    "الصبا",
    "صبياني",
    "صبيانية",
    "الصبيانية",
    "بويل",
    "بويلستون",
    "الأولاد",
    "هجين",
    "وتوت",
    "(فاكهة)",
    "البترول",
    "البريطانية",
    "لكثافة",
    "التخزين",
    "حمالة",
    "براك",
    "الدعامة",
    "المثبت",
    "الداعم",
    "الحمالات",
    "أذرعة",
    "الكوع",
    "العضد",
    "السرخس",
    "أقواس",
    "مملح",
    "النقاء",
    "الورقة",
    "برادبري",
    "برادفورد",
    "برادلي",
    "برادشو",
    "برادي",
    "المنحدر",
    "المنحدرات",
    "براج",
    "الاستعراض",
    "يتفاخر",
    "براهمابوترا",
    "يصب",
    "خليج",
    "البنغال",
    "براهمس",
    "ملحن",
    "الألماني",
    "بابراهامز",
    "الشريط",
    "مجدل",
    "التضفير",
    "الضفائر",
    "بريل",
    "المخ",
    "براينارد",
    "البنات",
    "دماغ",
    "أذكى",
    "القتل",
    "تهشيم",
    "الماغ",
    "يفهم",
    "الأمخاخ",
    "الرائعة",
    "فكرة",
    "رائعة",
    "اغسل",
    "مخ",
    "غسل",
    "غاسل",
    "الدماغ",
    "يغسل",
    "غسيل",
    "سو",
    "سوى",
    "يسوي",
    "التسوية",
    "الفرامل",
    "فرمل",
    "المسؤول",
    "المسؤولين",
    "الفرملة",
    "الشرشور",
    "عليقي",
    "تشعب",
    "برانتشينجز",
    "برانتشفيل",
    "براندايس",
    "براندل",
    "براندينبرج",
    "براندر",
    "بالبراندي",
    "الخمر",
    "الوسم",
    "يلوح",
    "التلويح",
    "براندون",
    "براندت",
    "البراندي",
    "براندي",
    "واين",
    "برانيف",
    "برانون",
    "البرنتية",
    "حمالات",
    "بوقاحة",
    "برازيليا",
    "النحاس",
    "الأصفر",
    "براسيد",
    "نحاسيا",
    "جسارة",
    "نحاسي",
    "الأطفال",
    "براتوورست",
    "براون",
    "الشجعان",
    "واجه",
    "بشجاعة",
    "أشوس",
    "مغوار",
    "البأس",
    "أشجع",
    "الشجاعة",
    "الأشجع",
    "أحسنت",
    "شجع",
    "استحسن",
    "(",
    "فعلت",
    ")",
    "البرافو",
    "الاستعراضات",
    "تشاجر",
    "المشاكس",
    "المشاكسون",
    "التشاجر",
    "العضلات",
    "العضلية",
    "قوي",
    "النهيق",
    "نهق",
    "المدحاة",
    "يدوية",
    "اصنع",
    "صنع",
    "تطاول",
    "استحياء",
    "التطاول",
    "يصنع",
    "المبخرة",
    "المباخر",
    "البرازيل",
    "البرازيلي",
    "برازافيل",
    "المخالفة",
    "خرق",
    "المتعد",
    "الخارق",
    "للمعاهدة",
    "المتعدون",
    "الناقضون",
    "الخروق",
    "الخرق",
    "سلة",
    "سلات",
    "بالخبز",
    "البريدفروت",
    "بريدينج",
    "بريدروت",
    "العائل",
    "العائلون",
    "الراحة",
    "للكسر",
    "التالف",
    "التالفات",
    "الانفصال",
    "الانهيارات",
    "الإفطار",
    "فطر",
    "المفطر",
    "الانكسار",
    "الكسر",
    "خطير",
    "انقطع",
    "التوقف",
    "الاختراق",
    "الإختراق",
    "الاختراقات",
    "الانتهاء",
    "الأبراميس",
    "رضع",
    "أرضع",
    "للتنفس",
    "تنفس",
    "الاستراحة",
    "الاستراحات",
    "يتنفس",
    "لهاثا",
    "التنفس",
    "لاهث",
    "بتلهف",
    "الأنفاس",
    "مثير",
    "البريشة",
    "تكاثر",
    "الكعب",
    "الكعوب",
    "الطوق",
    "المربي",
    "المربون",
    "التناسل",
    "التربية",
    "السلالات",
    "النسيم",
    "مرق",
    "النسائم",
    "عصفا",
    "بمرح",
    "المرح",
    "المروق",
    "كتلة",
    "لينة",
    "بريمين",
    "أسم",
    "يرد",
    "أقطار",
    "متعددة",
    "بريمستراهلنج",
    "بريندا",
    "بريندان",
    "برينان",
    "برينر",
    "بريسينهام",
    "الإخوة",
    "البريتاني",
    "بريت",
    "النغمة",
    "الطويلة",
    "الترقية",
    "مرفع",
    "الترفيع",
    "الترقيات",
    "مصانع",
    "منتجون",
    "مصنع",
    "البدء",
    "بروستر",
    "برايان",
    "الرشوة",
    "رشا",
    "الراشي",
    "الرشاوي",
    "الراشون",
    "الرشو",
    "بريك",
    "بريس",
    "الطوبة",
    "مطوب",
    "الطابوق",
    "الأتون",
    "البناءون",
    "الطوب",
    "أعمال",
    "بالزفاف",
    "العروسة",
    "العريس",
    "العرسان",
    "العروسات",
    "وصيفة",
    "الشرف",
    "وصيفات",
    "الإصلاحية",
    "الكوبري",
    "جسر",
    "بنى",
    "جسرا",
    "نقط",
    "بريدجبورت",
    "الكباري",
    "بريدجيت",
    "بريدجتاون",
    "بريدج",
    "ووتر",
    "الجسور",
    "اللجام",
    "أشاح",
    "الألجمة",
    "الإشاحة",
    "الاختصاص",
    "أطلع",
    "أقصر",
    "الأقصر",
    "الاجتماعات",
    "باختصار",
    "القصر",
    "الاختصاصات",
    "برين",
    "اللواء",
    "تكوين",
    "لواء",
    "سرية",
    "اللواءات",
    "الأميرالاى",
    "خيالي",
    "الشراعي",
    "بريجس",
    "بريجهام",
    "أضئ",
    "أضاء",
    "منير",
    "الإضاءة",
    "يضيء",
    "إضاءة",
    "ببهاء",
    "الإشراق",
    "السطوع",
    "بريتون",
    "الكشافات",
    "لامع",
    "بتألق",
    "بريق",
    "سناء",
    "الامتلاء",
    "الكبريت",
    "ايطاليا.",
    "معقل",
    "الصليبيين",
    "ابان",
    "الوسطى",
    "الرمادي",
    "الداكن",
    "رمادي",
    "ناقع",
    "بالماء",
    "مالح",
    "المحاليل",
    "الملحية",
    "أحضر",
    "أتى",
    "الإحضار",
    "ملوحة",
    "الملوحة",
    "بالمحلول",
    "الملحي",
    "برينكلي",
    "الدعاية",
    "قالب",
    "فحم",
    "قوالب",
    "بريسبين",
    "نشيط",
    "أنشط",
    "الأنشط",
    "الشعر",
    "الخشن",
    "وقف",
    "خشونة",
    "الوقوف",
    "خشن",
    "بريستول",
    "بريطانيا",
    "بريطاني",
    "بريتانيكا",
    "البريطاني",
    "البريطانيون",
    "بريتاني",
    "بريتين",
    "بهشاشة",
    "الهشاشة",
    "اطرح",
    "طرح",
    "يطرح",
    "الطرح",
    "المكثف",
    "البرنامج",
    "أذاع",
    "البرامج",
    "اتسع",
    "موسع",
    "الاتساع",
    "يتسع",
    "أوسع",
    "الأوسع",
    "منسوج",
    "نول",
    "عريض",
    "بوجه",
    "النقد",
    "برودواي",
    "القطيفة",
    "القرنبيط",
    "بروك",
    "بروكل",
    "بروجلي",
    "طرز",
    "يطرز",
    "اشو",
    "حارق",
    "يشوي",
    "انكسر",
    "يائس",
    "متكسر",
    "السمسار",
    "السمسرة",
    "السمسرات",
    "السماسرة",
    "برومفيلد",
    "البروم",
    "بروملي",
    "الفرس",
    "القصبات",
    "شعبي",
    "بالقصيبات",
    "القصيبة",
    "القصيبات",
    "الرئوي",
    "الشعبة",
    "البرونكو",
    "الديناصور",
    "برونكس",
    "البرونز",
    "برونزي",
    "البشرة",
    "البرنزة",
    "دبوس",
    "دبابيس",
    "العائلة",
    "فكر",
    "الحظيرة",
    "الحظائر",
    "الحضن",
    "العائلات",
    "متأمل",
    "بروكديل",
    "بروكفيلد",
    "بروخافين",
    "بروكلاين",
    "بروكلين",
    "بروكماونت",
    "بروكسايد",
    "المقشة",
    "مكنوس",
    "الكنس",
    "بمكنسة)",
    "يكنس",
    "المقشات",
    "الأخ",
    "الأخوة",
    "أخوي",
    "الحاجب",
    "أكره",
    "الإكراه",
    "براونيل",
    "بنيا",
    "بروبرت",
    "الكعكة",
    "الكعك",
    "الاحمرار",
    "السمرة",
    "اسمرار",
    "سمرة",
    "يحمر",
    "الحواجب",
    "تصفح",
    "المتصفح",
    "المستعرض",
    "المتصفحات",
    "يتصفح",
    "التصفح",
    "برس",
    "المتموجة",
    "بركنر",
    "بريجل",
    "الكدمة",
    "انجرح",
    "الضخم",
    "الضخام",
    "الكدمات",
    "الإشاعة",
    "الغداء",
    "بروناي",
    "برانهيلد",
    "برونو",
    "برنسويك",
    "الوطأة",
    "الفرشة",
    "ناعم",
    "بالفرشاة",
    "الفرش",
    "كثيث",
    "الفرشاة",
    "العيدان",
    "الرسم",
    "كثيف",
    "الجفاء",
    "بروكسل",
    "النزع",
    "الإنسانية",
    "برتاليزاشنس",
    "بهيميا",
    "بوحشية",
    "المتوحش",
    "المتوحشون",
    "عقلاني",
    "وحشية",
    "بروكسيل",
    "بريان",
    "بريانت",
    "الطحلبي",
    "بكالوريوس",
    "دي",
    "الصحيفة",
    "لأنظمة",
    "بيل",
    "زجاجة",
    "يو",
    "حرارية",
    "للشغل",
    "الفقاعة",
    "أخرج",
    "فقاعات",
    "النبع",
    "الفوار",
    "الفقاقيع",
    "مليء",
    "بالفقاعات",
    "إخراج",
    "الشامبانيا",
    "الدبل",
    "لنفاوية",
    "القرصان",
    "القراصنة",
    "بتشانان",
    "بوخارست",
    "بتشينوالد",
    "بتشوالد",
    "الدولار",
    "راعي",
    "البقر",
    "رعاة",
    "الحنطور",
    "الحناطير",
    "قفز",
    "بكر",
    "الجردل",
    "بكيتيد",
    "بكيتفل",
    "المضاربة",
    "الجرادل",
    "بك",
    "اﻷشجار",
    "اﻷمريكية",
    "قرن",
    "القفز",
    "الإبزيم",
    "التوى",
    "الترس",
    "التروس",
    "الأبازيم",
    "بكلي",
    "التواء",
    "بكنل",
    "الدولارات",
    "الجلود",
    "النبق",
    "القمح",
    "بوكي",
    "ريفي",
    "البرعم",
    "بودابست",
    "بد",
    "بوذا",
    "البوذية",
    "البوذي",
    "البوذيون",
    "تزحزح",
    "الببغاء",
    "يتزحزح",
    "الميزانية",
    "بالميزانية",
    "ميزانية",
    "الميزانيات",
    "التزحزح",
    "البراعم",
    "بدويايزر",
    "بويرنج",
    "بوينا",
    "الهاوي",
    "الجاموسة",
    "الجاموسات",
    "لمع",
    "الواق",
    "الدولة",
    "المحايدة",
    "الواقون",
    "البوفيه",
    "لطم",
    "اللطم",
    "الصراع",
    "البوفيهات",
    "التلميع",
    "الزغباء",
    "المهرج",
    "المهرجون",
    "المخيف",
    "بجاتي",
    "جاحظ",
    "التافه",
    "متعب",
    "اللواط",
    "التافهون",
    "اﻷمتلاء",
    "بالبق",
    "المضايقة",
    "البوق",
    "مبوق",
    "عازفون",
    "الأبواق",
    "التبوق",
    "بيوك",
    "الوقت",
    "(العمارة",
    "البنيان)",
    "المبنى",
    "المباني",
    "التعزيز",
    "التعزيزات",
    "مدمج",
    "مكتظ",
    "بالمباني",
    "بوجمبورا",
    "اللمبة",
    "اللمبات",
    "بلغاريا",
    "البلغاري",
    "الانتفاخات",
    "المعظم",
    "بضخامة",
    "الضخامة",
    "البلدغ",
    "اهدم",
    "هدم",
    "البلدوزر",
    "البلدوزرات",
    "يهدم",
    "الهدم",
    "شق",
    "الرصاصة",
    "النشرة",
    "النشرات",
    "مضادا",
    "للرصاص",
    "بليتسنمبيرز",
    "مصارعة",
    "الثيران",
    "مصارع",
    "مصارعات",
    "البولفينش",
    "الضفدع",
    "الضفادع",
    "البلهد",
    "العناد",
    "أرهب",
    "الفتوات",
    "الشق",
    "السبائك",
    "متفائل",
    "باطمئنان",
    "الاطمئنان",
    "غليظ",
    "الرقبة",
    "العجل",
    "العجول",
    "السوط",
    "السياط",
    "الكبيرة",
    "الفتوة",
    "العنيف",
    "الإرهاب",
    "عشب",
    "البرك",
    "الواقيات",
    "المتشرد",
    "تلعثم",
    "التلعثم",
    "المتردد",
    "المترددون",
    "يتلعثم",
    "اهتززت",
    "اهتز",
    "ممتصات",
    "وعورة",
    "وعر",
    "الوعورة",
    "الاهتزاز",
    "لكدمات",
    "مغرور",
    "المتشردون",
    "اجتمع",
    "بنديستاج",
    "الحزم",
    "بندي",
    "الأغبياء",
    "مفسد",
    "الأورام",
    "نام",
    "الفحم",
    "بوقود",
    "بنكيرينج",
    "مشاطر",
    "مشاطروا",
    "الأرانب",
    "بنسن",
    "ألقى",
    "بالعصر",
    "الترياسي",
    "بنيان",
    "الطفو",
    "بانشراح",
    "ساند",
    "الأزيز",
    "بربانك",
    "متمتم",
    "يهذي",
    "البقبقة",
    "بورتش",
    "المسئولية",
    "أرهق",
    "الإرهاق",
    "المسئوليات",
    "الفداحة",
    "الأرقطيون",
    "البر",
    "المكتب",
    "البيروقراطيون",
    "البيروقراطي",
    "بيروقراطي",
    "بيروقراطية",
    "البرقطة",
    "بيروقراطيا",
    "المكاتب",
    "الإنبوبة",
    "القياس",
    "أنابيب",
    "الأعلام",
    "نموت",
    "نما",
    "النماء",
    "ينمو",
    "اللحوم",
    "المواطن",
    "المواطنون",
    "اللص",
    "السطو",
    "اسط",
    "سطا",
    "يسطو",
    "ضمان",
    "السرقة",
    "اللصوص",
    "برغندي",
    "الدفن",
    "دفن",
    "الدافن",
    "يدفن",
    "اخنق",
    "الخيش",
    "الثياب",
    "يعقد",
    "(عمل",
    "عقدة)",
    "السخرية",
    "سخر",
    "هزلي",
    "السخريات",
    "البرلي",
    "أقوى",
    "الأقوى",
    "بورلنجام",
    "بيرلينجتون",
    "فيرمونت",
    "بورما",
    "البورمي",
    "الحرق",
    "احترق",
    "الشعلة",
    "الشعلات",
    "برنيز",
    "برنيت",
    "برنهام",
    "الاحتراق",
    "الإحتراق",
    "اصقل",
    "ملمع",
    "الملمع",
    "الملمعون",
    "يصقل",
    "الصقل",
    "الحروق",
    "الزوالف",
    "(شعر",
    "الرجل)",
    "طنينا",
    "الطنان",
    "الجحش",
    "الجحشات",
    "بروجهس",
    "الجحر",
    "اخترق",
    "المستعير",
    "الجحور",
    "المنحة",
    "الأكياس",
    "كيسي",
    "انفجر",
    "المفجر",
    "برستينجز",
    "برت",
    "برتون",
    "بوروندي",
    "ادفن",
    "الحافلة",
    "بسبار",
    "العمال",
    "بوش",
    "بسيد",
    "الحافلات",
    "البوشل",
    "قام",
    "رديىء",
    "الشجيرات",
    "كثافة",
    "الكثافة",
    "البطانة",
    "الثعبان",
    "بشنل",
    "الأدغال",
    "عيشة",
    "يعيش",
    "انشغالا",
    "منشغلا",
    "التجارة",
    "رجال",
    "سيدة",
    "بوسيه",
    "بسن",
    "التقبيل",
    "التمثال",
    "دجاجة",
    "دجاج",
    "التماثيل",
    "الفضول",
    "الفضولي",
    "الشغل",
    "الانشغال",
    "لكن",
    "البيوتاديين",
    "البيوتان",
    "المترجلة",
    "الجزار",
    "ذبح",
    "الذبح",
    "دموي",
    "الجزارون",
    "بوتين",
    "الأيسومري",
    "(صناعة",
    "المطاط)",
    "بوتيو",
    "نطح",
    "الزبدة",
    "البدين",
    "الغراب",
    "بالزبد",
    "الدسم",
    "بترفيلد",
    "الفراشات",
    "الفراشة",
    "اللبن",
    "محتوي",
    "زبدة",
    "التلال",
    "النطح",
    "الأرداف",
    "الزر",
    "زرر",
    "بتونر",
    "العروة",
    "المكره",
    "العراوي",
    "التزرير",
    "الأزرار",
    "بوتونويد",
    "بترك",
    "بوتيل",
    "زبدي",
    "الزبدات",
    "ناهدة",
    "سمين",
    "الطاعة",
    "بكستيهوده",
    "بكستون",
    "اشتر",
    "للشراء",
    "المشتري",
    "المشترون",
    "الشراء",
    "يشتري",
    "الطنين",
    "أز",
    "الأز",
    "المنشار",
    "التعبيرات",
    "الطنانة",
    "المحلي",
    "وقفة",
    "التأهب",
    "بايرز",
    "وقفات",
    "مهجورات",
    "المحلية",
    "الثانوي",
    "المقالة",
    "الجانبي",
    "تجاوز",
    "التجاوز",
    "النواتج",
    "الزريبة",
    "بايرن",
    "بايرون",
    "بايروني",
    "المتفرج",
    "المتفرجون",
    "البايت",
    "بيزنطي",
    "بيزنطة",
    "المفكرة",
    "سجل",
    "خادم",
    "الويب",
    "سجن",
    "الباستيل",
    "سي",
    "كاليفورنيا",
    "الأجرة",
    "متآمر",
    "التآمر",
    "العصابات",
    "خيمة",
    "خيم",
    "الملهى",
    "الملفوف",
    "اختلس",
    "اﻷختلاس",
    "سائقو",
    "سواق",
    "الحجرة",
    "الوزارة",
    "صانع",
    "الدواليب",
    "صانعو",
    "حرفة",
    "اﻷثاث",
    "المنزلي",
    "الخزانات",
    "الحجرات",
    "مبرق",
    "البرقية",
    "الإبراق",
    "الكاملة",
    "مطبخ",
    "كابوت",
    "مستكشف",
    "إيطالي",
    "كابريوليت",
    "الكاكاو",
    "حوت",
    "المخبأ",
    "مخبأ",
    "المخابئ",
    "الختم",
    "إستخدام",
    "الوسيطة",
    "الثرثرة",
    "مثرثر",
    "تخرجه",
    "وضعها",
    "بيضة",
    "تنافر",
    "بتنافر",
    "الأنغام",
    "ناشز",
    "بتعارض",
    "النشاز",
    "الصبار",
    "كاد",
    "جثمان",
    "جيفة",
    "الشحوب",
    "جثامين",
    "جيف",
    "العلب",
    "رباط",
    "صوفي",
    "العلبة",
    "الإيقاع",
    "مناغم",
    "الإيقاعات",
    "المناغمة",
    "إيقاعي",
    "الدور",
    "المنفرد",
    "الرائع",
    "الطالب",
    "العسكري",
    "الطلاب",
    "العسكريون",
    "متسول",
    "المتسول",
    "كاديلاك",
    "الكادميوم",
    "الملاكات",
    "الأوغاد",
    "كادي",
    "القيصر",
    "قيصري",
    "المقهى",
    "المقاهي",
    "الكافايين",
    "محبوس",
    "كتوم",
    "الحذر",
    "بكتمان",
    "الحبس",
    "كاهيل",
    "كاهوت",
    "التواطؤ",
    "كيمان",
    "زاحف",
    "قابيل",
    "كين",
    "روائي",
    "إنجليزي",
    "بالعلامات",
    "العلامات",
    "القاهرة",
    "صندوق",
    "الأسير",
    "سيء",
    "داهن",
    "مداهن",
    "مداج",
    "مداهنةمراءاة",
    "يداهن",
    "المداهنة",
    "تكتل",
    "كيك",
    "واك",
    "التكتل",
    "كال",
    "كالاباش",
    "كاليه",
    "معدن",
    "أبيض؛",
    "خام",
    "الخارصين",
    "الكوارث",
    "المعاناة",
    "التعاسة",
    "البؤس",
    "الكارثة",
    "القصب",
    "الهندي",
    "احسب",
    "كلسي",
    "كلسية",
    "تكلس",
    "تكليس",
    "مكلس",
    "يكلس",
    "كلس",
    "التكليس",
    "كالسيت",
    "الكالسيوم",
    "إحسب",
    "يحسب",
    "الحاسبات",
    "حسبان",
    "(رياضيات)",
    "التفاضل",
    "والتكامل",
    "حسابات",
    "كلكتا",
    "كالدر",
    "نحات",
    "الولايات",
    "المتحدة",
    "الناجمة",
    "انفجار",
    "البركان",
    "الحفر",
    "البراكين",
    "القدر",
    "كالدويل",
    "كاليب",
    "كاليدونيا",
    "الأسم",
    "القديم",
    "لسكوتلندا",
    "أدخل",
    "الرزنامة",
    "أﻷدخال",
    "التقويمات",
    "بالرزنامة",
    "جلد",
    "كالجاري",
    "كالهون",
    "مدرج",
    "التفصيل",
    "الناعم",
    "يدرج",
    "التدرج",
    "التحديد",
    "التحديدات",
    "المعاير",
    "منقط",
    "بالألوان",
    "كاليفورني",
    "سكان",
    "كاليفورنيوم",
    "كاليجولا",
    "الفرجار",
    "الخليفة",
    "الخلافة",
    "بتمارين",
    "تمارين",
    "يدوس",
    "يتقصى",
    "ملأ",
    "الثغور",
    "والفراغات",
    "ملىء",
    "كولكينز",
    "يسد",
    "النداء",
    "نداء",
    "ليس",
    "كالا",
    "للتسديد",
    "كالاهان",
    "كالار",
    "معاكس",
    "اﻷتصال",
    "بها",
    "الأشخاص",
    "المتصلون",
    "بواسطة",
    "فن",
    "الخطاط",
    "الخطاطون",
    "الدعوة",
    "إلهام",
    "الملحمي",
    "المسماك",
    "قمر",
    "للكوكب",
    "\"المشتري\"",
    "قاس",
    "القساوة",
    "الفتي",
    "النداءات",
    "محطة",
    "الراديو",
    "التلفزيون",
    "النسيج",
    "الهدوء",
    "مهدأ",
    "هادئ",
    "سعري",
    "السعرة",
    "الحرارية",
    "السعرات",
    "فى",
    "إستعمال",
    "للتكنولوجيا",
    "غليون",
    "يستعمل",
    "زورا",
    "أتهم",
    "اﻷتهام",
    "الإفتراء",
    "المفتر",
    "إفترائي",
    "لد",
    "كالفيرت",
    "كالفين",
    "الكالفيني",
    "إمتلاك",
    "طبيعة",
    "قنب",
    "قنبع",
    "كاليبسو",
    "الحدبة",
    "المودة",
    "التحديب",
    "الحدب",
    "الصراف",
    "غشاء",
    "الخلية",
    "(نبات)",
    "كمبوديا",
    "للحصان",
    "أولا",
    "بالترتيب",
    "ابتدائي",
    "كامبردج",
    "كامدين",
    "جيء",
    "الجمل",
    "مقوس",
    "سنام",
    "الكاملية",
    "زرافة",
    "كاميلوت",
    "التصوير",
    "المصور",
    "المصورون",
    "آلات",
    "كاميرون",
    "الكاميرون",
    "جمهورية",
    "الساحل",
    "كامي",
    "كاميلا",
    "كاميل",
    "كامينو",
    "شحن",
    "ضخمة",
    "الأقحوان",
    "التمويه",
    "تخفى",
    "التمويهات",
    "التخفي",
    "الحملة",
    "بحملة",
    "أدارة",
    "الحملات",
    "كامبيل",
    "كامبيلسبورت",
    "مخيم",
    "المخيم",
    "المخيمون",
    "مزارعين",
    "نار",
    "أرض",
    "الكافور",
    "المخيمات",
    "كامبيون",
    "لنوع",
    "مواقع",
    "الحرم",
    "الجامعية",
    "عمود",
    "الحدبات",
    "كنعان",
    "الكنعانيون",
    "الكندي",
    "الكنديون",
    "حفر",
    "يشق",
    "يحفر",
    "شبكة",
    "مشقوق",
    "السخيف",
    "مصطنع",
    "المفرطة",
    "السخيفة",
    "الكناري",
    "الكنستة",
    "كانافيرال",
    "كانبرا",
    "ملغي",
    "العروق",
    "حشوي",
    "بينها",
    "السرطان",
    "سرطاني",
    "أمراض",
    "كانداس",
    "الوحدة",
    "للكثافة",
    "المضيئة",
    "الشمعدانات",
    "الشمعدان",
    "الترشيحات",
    "الترشيح",
    "المرشح",
    "المرشحون",
    "كانديد",
    "بالسكر",
    "العصير",
    "الحلويات",
    "الشمعة",
    "البيضة",
    "ضوء",
    "الشموع",
    "مشعل",
    "أضوية",
    "مضاء",
    "بالشموع",
    "كاندلر",
    "فتيلة",
    "الصدق",
    "كاندي",
    "الحفظ",
    "غلي",
    "بالخيزرانته",
    "الأثاث",
    "قمار",
    "يلعب",
    "الناب",
    "الأنياب",
    "بالخيزرانة",
    "والبرية",
    "الذئاب",
    "الثعالب",
    "استهلك",
    "تآكل",
    "الأكل",
    "أﻷستهلاك",
    "التاكل",
    "قرحي",
    "اليرقة",
    "الخضراء",
    "كانا",
    "مقدار",
    "(6",
    "7",
    "أقدام)",
    "الحشيش",
    "معلب",
    "يحترق",
    "تعليب",
    "المعلبات",
    "للحم",
    "البشر",
    "بأكل",
    "التفكيك",
    "فكك",
    "مفكك",
    "يفكك",
    "لحوم",
    "بتعقل",
    "الحيطة",
    "التعليب",
    "معدني",
    "لحفظ",
    "اﻷطعمة",
    "معدنية",
    "المدفع",
    "قذيفة",
    "إصطدم",
    "الإصطدام",
    "يستطيع",
    "الزورق",
    "مجذوف",
    "يجدف",
    "الشريعة",
    "النظام",
    "الديني",
    "كالكنيسة",
    "الكاثوليكية",
    "قانوني",
    "لباس",
    "محترف",
    "(الزي",
    "اﻷطباء)",
    "بقانون",
    "كانون",
    "مقدس",
    "الشرائع",
    "مغطاة",
    "الستائر",
    "الستارة",
    "الشمام",
    "تشكيلة",
    "شمام",
    "شرس",
    "الشراسة",
    "الأنشودة",
    "الخبب",
    "كانتيربيري",
    "مخبوب",
    "الخب",
    "المنافقون",
    "خنفساء",
    "لونها",
    "الدينية",
    "الكابولي",
    "زاوية",
    "أوطرف",
    "مقطع",
    "طويلة",
    "كانتوني",
    "مساطير",
    "كانوت",
    "الجنفاص",
    "جامع",
    "جامعو",
    "إلتماس",
    "الوادي",
    "الوديان",
    "مطاطي",
    "القبعة",
    "القابليات",
    "قدير",
    "الكفاءةالكفاية",
    "واسع",
    "مؤهلا",
    "قادرا",
    "بالسعة",
    "سعويا",
    "المكثفات",
    "كابيلا",
    "أوريجا",
    "موثوب",
    "الوثب",
    "يثب",
    "كابيت",
    "الملوك",
    "الفرنسيين",
    "كابيتاون",
    "الشعرية",
    "ظاهرة",
    "التى",
    "تحدث",
    "التقاء",
    "السائل",
    "بالصلب",
    "شعري",
    "كابيسترانو",
    "الرأسمال",
    "الرأسمالية",
    "الرأسمالي",
    "رأسمالي",
    "رأسماليا",
    "الرأسماليون",
    "الرسملة",
    "ممول",
    "يمول",
    "مهلك",
    "العواصم",
    "كابيتان",
    "ضريبة",
    "الأعناق",
    "ضرائب",
    "الكابيتول",
    "بالعاصمة",
    "روما",
    "إستسلم",
    "مستسلم",
    "يستسلم",
    "الإستسلام",
    "المعاهدة",
    "فرع",
    "إتحاد",
    "جريمة",
    "تمويلي",
    "منظم",
    "يخصي",
    "كسب",
    "جميع",
    "الخدع",
    "لعبة",
    "\"بيكويت\"",
    "النزوة",
    "النزوات",
    "نزوي",
    "نزويا",
    "القبعات",
    "إنقلب",
    "منقلب",
    "ينقلب",
    "الإنقلاب",
    "الرحوية",
    "الرحويات",
    "اللمسة",
    "اﻷخيرة",
    "الكبسولة",
    "الكبسولات",
    "القائد",
    "الرئاسة",
    "قاد",
    "ترأس",
    "الترأس",
    "مماحك",
    "المماحكة",
    "سر",
    "مأسور",
    "يأسر",
    "الأسر",
    "جالب",
    "إنتباه",
    "الأسرى",
    "الآسر",
    "الآسرون",
    "المؤسر",
    "المؤسرون",
    "العباءة",
    "للنساء",
    "كابتو",
    "كابيبارا",
    "رافعة",
    "كاراباو",
    "كركاس",
    "الدورق",
    "الكاراميل",
    "القيراط",
    "القيراطات",
    "القافلة",
    "المسافر",
    "الكارافان",
    "القوافل",
    "الكرويا",
    "الكربيد",
    "القصيرة",
    "مسلح",
    "ببندقية",
    "قصيرة",
    "البنادق",
    "الكربوهيدرات",
    "كاربوليكي",
    "كاربولوي",
    "سبيكة",
    "الكاربون",
    "فحمي",
    "مكربن",
    "يكربن",
    "الكربنة",
    "بثاني",
    "كاربوني",
    "كاربونيوم",
    "التفحيم",
    "مفحم",
    "يفحم",
    "معدنا",
    "إندمج",
    "أول",
    "أوكسيد",
    "الكربون",
    "الكربوراندم",
    "الكربوكسيل",
    "(كيمياء)",
    "الدمجانة",
    "الجمرة",
    "(جلدي)",
    "الجمرات",
    "الوقود",
    "للاحتراق",
    "كربن",
    "المازج",
    "(خلط",
    "محرك",
    "السيارة)",
    "الكربوراتور",
    "الجثث",
    "الجثة",
    "تنتج",
    "للسرطان",
    "البطاقة",
    "بحجم",
    "بطاقة",
    "الهيل",
    "كاردان",
    "المقوى",
    "الكارتونات",
    "لعب",
    "بالورق",
    "لخيوط",
    "الصوف",
    "استعدادا",
    "للغزل",
    "قلبي",
    "كاردف",
    "بلوز",
    "بالأزرار",
    "بلوزات",
    "الكاردينال",
    "عدد",
    "العناصر",
    "الكاردينالات",
    "الورق",
    "القلبي",
    "منحنى",
    "يدعى",
    "كذلك",
    "لتشابهه",
    "طب",
    "توسيع",
    "وعائي",
    "البطاقات",
    "العناية",
    "مهتم",
    "مل",
    "مميل",
    "الإمالة",
    "المهنة",
    "إنطلق",
    "الإنطلاق",
    "ممارسة",
    "النزاهة",
    "المحترف",
    "المهن",
    "هانئ",
    "بعناية",
    "مهمل",
    "المعتني",
    "المعتنون",
    "المداعبة",
    "مداعب",
    "المداعبات",
    "بملاطفة",
    "مظهرا",
    "للحب",
    "علامة",
    "الإقحام",
    "كاري",
    "أجرة",
    "اﻷجرة",
    "كارجيل",
    "الشحن",
    "كاريبي",
    "الوعل",
    "الوعول",
    "كاريكاتوري",
    "الكاريكاتير",
    "كاريكاتير",
    "الكاريكاتيرات",
    "رسام",
    "نخر",
    "الأسنان",
    "متسوس",
    "كارل",
    "كارلا",
    "كارلتون",
    "كارلن",
    "كارلايل",
    "كارلو",
    "حمولات",
    "كارلوس",
    "كارلزباد",
    "كارلسون",
    "كارليل",
    "كارميلا",
    "كارمن",
    "كارميكل",
    "قرمزي",
    "المجزرة",
    "جسدي",
    "الجسدية",
    "القرنفلة",
    "القرنفل",
    "كارنيجي",
    "كارني",
    "الكرنفال",
    "الكرنفالات",
    "الخروب",
    "كارول",
    "احتفل",
    "حفلات",
    "كارولنا",
    "كارولين",
    "شمال",
    "كارولينا",
    "التراتيل",
    "سباتي",
    "حمراء",
    "عديمة",
    "تستخرج",
    "الإحتفال",
    "الصاخب",
    "الإحتفالات",
    "الصاخبة",
    "إشرب",
    "بالشرب",
    "يشرب",
    "الشبوط",
    "كارباثيا",
    "كاربينتر",
    "النجارة",
    "النجارون",
    "المنتقد",
    "السجادة",
    "نقالة",
    "للمسافرين",
    "مفروش",
    "بالسجاد",
    "السجاد",
    "العياب",
    "ايجاد",
    "العطل",
    "مرآب",
    "كار",
    "كارارا",
    "لنفر",
    "داخل",
    "محمول",
    "الناقل",
    "الناقلون",
    "يحمل",
    "إحمل",
    "الواسعة",
    "مواجهة",
    "غثيان",
    "الحركة",
    "كارسن",
    "إجرة",
    "كرت",
    "الإحتكارات",
    "كارتر",
    "متعهد",
    "ديكارتي",
    "قرطاجة",
    "قرطاجي",
    "كارتج",
    "الغضروف",
    "الغضاريف",
    "غضروفي",
    "الخرائط",
    "خرائطي",
    "الكارتون",
    "علب",
    "رسامو",
    "الخرطوشة",
    "الخراطيش",
    "الخشبية",
    "كارترايت",
    "كارتي",
    "كاروسو",
    "إقطع",
    "مقطوع",
    "بالقطع",
    "النحات",
    "النحاتون",
    "داعم",
    "منحوت",
    "انسان",
    "كازانوفا",
    "القصبة",
    "الشلال",
    "متعاقب",
    "الشلالات",
    "المسهل",
    "فتش",
    "بروتين",
    "الجبن",
    "النافذة",
    "البابية",
    "الإجتماعي",
    "موظف",
    "موظفين",
    "اجتماعيين",
    "كايسي",
    "مصرف",
    "يصرف",
    "اللوزة",
    "الصرافون",
    "نقود",
    "كشمير",
    "الكازينو",
    "الكازينوات",
    "الدن",
    "الأدنان",
    "كاساندرا",
    "محكمة",
    "الاستئناف",
    "كاسافا",
    "الوعاء",
    "المقاوم",
    "للحرارة",
    "المقاومة",
    "الكاسيت",
    "الكاسيتات",
    "كاسيوبيا",
    "نجوم",
    "قديمة",
    "(سكان",
    "بابل)",
    "كاسيوس",
    "ثوب",
    "لابس",
    "الكهنة",
    "ممثلون",
    "الصنج",
    "الصنوج",
    "المنبوذ",
    "الطائفة",
    "مالك",
    "القلعة",
    "قلعوي",
    "المذرة",
    "الطوائف",
    "موبخ",
    "العقوبة",
    "المعاقب",
    "كاستيلو",
    "الاختيار",
    "تسعات",
    "يملك",
    "قلعة",
    "قلاع",
    "مسؤول",
    "القلاع",
    "المهمل",
    "المملحة",
    "إخص",
    "الخصية",
    "الإخصاء",
    "كاسترو",
    "القوالب",
    "الاهتمام",
    "المصاب",
    "القطة",
    "الأرض,",
    "لأسفل,",
    "تقويضي",
    "الانتقاض",
    "كارثي",
    "سرداب",
    "الموتى",
    "سراديب",
    "الإغماءات",
    "الجمدة",
    "جمدي",
    "كاتالينا",
    "الدليل",
    "المفهرس",
    "الفهرسة",
    "الأدلة",
    "التعديد",
    "كاتالبا",
    "العوامل",
    "المحفز",
    "المحفزات",
    "عامل",
    "حفز",
    "محفز",
    "يحفز",
    "التحفيز",
    "المصيادة",
    "مقذوف",
    "المصيادات",
    "الزكام",
    "زكامي",
    "بالنزلة",
    "(مرض)",
    "الإغماء",
    "بتخشب",
    "مشلول",
    "كاتاوبا",
    "(لغة)",
    "الكتبرد",
    "صفير",
    "الاحتجاج",
    "صيد",
    "للمسك",
    "الأدراج",
    "الماسك",
    "الماسكون",
    "يمسك",
    "المسك",
    "صلصة",
    "الطماطم",
    "الناقلات",
    "المتنصر",
    "الأصناف",
    "التصنيف",
    "صنف",
    "مصنف",
    "يصنف",
    "الصنف",
    "سلسل",
    "مون",
    "ممون",
    "الممون",
    "الطبخ",
    "كاتيربيلر",
    "اليرقات",
    "يمون",
    "مواء",
    "الهر",
    "مميئ",
    "الموء",
    "يموء",
    "السلور",
    "التنفيه",
    "المسهلات",
    "عرش",
    "الكاثدرائية",
    "الكاثدرائيات",
    "كاثرين",
    "كاثروود",
    "القسطرة",
    "قسطر",
    "مقسطر",
    "يقسطر",
    "القسطرات",
    "السالب",
    "سالب",
    "كاثوليكي",
    "كاثوليكالي",
    "الشمول",
    "الكاثوليك",
    "كاثي",
    "الآيون",
    "الكاتيوني",
    "الصفصاف",
    "قطي",
    "الغفوة",
    "نعس",
    "النعس",
    "الغفوات",
    "النعناع",
    "القطط",
    "التيفا",
    "حقدا",
    "الخبث",
    "حقود",
    "الماشية",
    "المواشي",
    "الآخربشكل",
    "الكيبل",
    "التلفزيوني",
    "المنصة",
    "المنصات",
    "قوقازي",
    "القوقازيون",
    "القوقاز",
    "المؤتمر",
    "التحضيري",
    "عقد",
    "مؤتمر",
    "مسك",
    "القدور",
    "القرنابيط",
    "سببي",
    "السببية",
    "سببيا",
    "السبب",
    "الأسباب",
    "سبب",
    "التسبب",
    "الحارق",
    "تهكمي",
    "كاوي",
    "الحارقات",
    "الكوي",
    "إكو",
    "مكوي",
    "يكوى",
    "تحذيري",
    "محذر",
    "المحذر",
    "المحذرين",
    "الناصحين",
    "التحذيرات",
    "الإحتراس",
    "الموكب",
    "المواكب",
    "الفارس",
    "متعرجف",
    "مستخفة",
    "المدرعات",
    "الفرسان",
    "الكهف",
    "الكهوف",
    "تحلية",
    "التبغ",
    "ووضعه",
    "المخطمة",
    "(توضع",
    "أنف",
    "الحصان",
    "ريثما",
    "يتروض)",
    "الكافيار",
    "الإعتراض",
    "المعترض",
    "المعترضون",
    "الإعتراضات",
    "التافهة",
    "يجوف",
    "ثب",
    "النعيب",
    "ناعق",
    "النعيق",
    "ينعق",
    "كايين",
    "كيلي",
    "كيجا",
    "(الهنود",
    "الحمر)",
    "أس",
    "الإذاعية",
    "المدمج",
    "قرص",
    "مسجل",
    "السيطرة",
    "التوقيت",
    "النهاري",
    "الوسطي",
    "(الولايات",
    "المتحدة)",
    "الوقف",
    "متوقف",
    "مستمر",
    "دون",
    "سيسيل",
    "سيسيليا",
    "سيكروبيا",
    "الأرز",
    "إترك",
    "سيدر",
    "سيدريك",
    "سقف",
    "السقف",
    "مسقف",
    "السقوف",
    "الكلندين",
    "سيليبز",
    "جزيرة",
    "شرقي",
    "أندونيسيا",
    "إحتفل",
    "مشهور",
    "يحتفل",
    "إحتفالي",
    "المشاهير",
    "المشهور",
    "سيليرياك",
    "نبات.",
    "جذوره",
    "السرع",
    "الكرفس",
    "سيليستا",
    "سيليست",
    "سماوي",
    "سيليا",
    "العزوبة",
    "عازب",
    "العزاب",
    "القبو",
    "الأقبية",
    "الكمنجة",
    "عازفو",
    "الكمان",
    "التشيلو",
    "السيلوفان",
    "أوراق",
    "السليفون",
    "الخلوي",
    "السيليلويد",
    "سليلوزي",
    "سيللوسيز",
    "(السكر)",
    "مئوي",
    "الكيلتي",
    "الإسمنت",
    "مدعم",
    "المغطي",
    "بالأسمنت",
    "يدعم",
    "المقابر",
    "المقبرة",
    "دهر",
    "تحديثي",
    "الرقيب",
    "مراقب",
    "رقابي",
    "المراقبة",
    "عياب",
    "التنديد",
    "الرقباء",
    "الرقابة",
    "باللوم",
    "إحصاءات",
    "السنت",
    "القنطور",
    "المئوي",
    "المئويون",
    "المئوية",
    "المئويات",
    "المركز",
    "ممركز",
    "سينتريرس",
    "المركزة",
    "مستوي",
    "تشطر",
    "المركزية",
    "المراكز",
    "الدرجات",
    "السنتغرام",
    "السنتغرامات",
    "السنتيليتر",
    "السنتيمتر",
    "السنتيمترات",
    "مئوية",
    "الأقدام",
    "مئويات",
    "سينتراليا",
    "تمركز",
    "يتمركز",
    "التمركز",
    "مركزيا",
    "السنترال",
    "سنترال",
    "طارد",
    "طردي",
    "يطرد",
    "المركزي",
    "الطارد",
    "الطاردات",
    "معتدل",
    "السنتات",
    "الروماني",
    "التنفيذي",
    "رأسي",
    "قيفاوس",
    "شمالية",
    "الخزف",
    "السيراميك",
    "يشكل",
    "الآنية",
    "الفخارية",
    "المرهم",
    "بالشمع",
    "سيربيروس",
    "وحش,",
    "بثلاثة",
    "الحبوب",
    "المخيخ",
    "مخي",
    "مفكر",
    "يفكر",
    "بالمخ",
    "رسمي",
    "احتفالي",
    "المراسيم",
    "المراسم",
    "يشمع",
    "سيريس",
    "جنس",
    "لعائلة",
    "كرزي",
    "السيريوم",
    "الدوري",
    "المختبر",
    "اﻷوروبي",
    "لفيزياء",
    "الجزيئات",
    "متأكد",
    "اليقين",
    "قابلا",
    "للتحقق",
    "الضمان",
    "بشهادة",
    "الذين",
    "يصدقون",
    "يؤكدون",
    "يشهد",
    "إشهد",
    "القضائي",
    "عنقي",
    "قيصر",
    "السيزيوم",
    "التوقفات",
    "السيسنا",
    "البالوعة",
    "القيطس",
    "الاستواء",
    "سيلان",
    "النبيذ",
    "تشاد",
    "تشادويك",
    "الإنزعاج",
    "غضب",
    "(حشرة)",
    "يغضب",
    "القشر",
    "المساومة",
    "مساوم",
    "الممازحة",
    "يمازح",
    "الكدر",
    "مكدر",
    "التكدير",
    "الأكدار",
    "مقيد",
    "التقييد",
    "طابعة",
    "السلاسل",
    "المدخن",
    "الشره",
    "مترأس",
    "الرئيسات",
    "تشيس",
    "شالكوسيت",
    "(خامة",
    "نحاس)",
    "الشاليه",
    "الكأس",
    "الكؤوس",
    "الطباشير",
    "طباشيري",
    "المحجر",
    "للطباشير",
    "التحدي",
    "للتحدي",
    "متحدى",
    "المتحدي",
    "المتحدون",
    "التحديات",
    "تشالمرز",
    "حديدي",
    "الغرفة",
    "يحتل",
    "تشريعية",
    "يحظرون",
    "الفسوق",
    "تشامبرلن",
    "الخزانة",
    "عاملة",
    "عاملات",
    "الغرف",
    "الحرباء",
    "الشطب",
    "مشطوب",
    "يشطب",
    "الشمواة",
    "تشامومل",
    "البطل",
    "الشمبانيا",
    "معضوض",
    "دوفع",
    "الأبطال",
    "البطولة",
    "البطولات",
    "تشامبلين",
    "فرنسي",
    "نوفا",
    "سكوتيا",
    "الفرصة",
    "مصادف",
    "مذبح",
    "المستشاريات",
    "المستشارية",
    "تشانسيلورسفيل",
    "الفرص",
    "مشكوك",
    "المخاطرة",
    "تشانكر",
    "الثريا",
    "الثريات",
    "الشماع",
    "تشانج",
    "متغير",
    "التقلب",
    "بتقلب",
    "متقلب",
    "بالتغيير",
    "استبدل",
    "الكلي",
    "شانا",
    "قناةاسم",
    "تشانينج",
    "تشانسون",
    "الأغاني",
    "المرتل",
    "تشانتي",
    "الديك",
    "الديوك",
    "الخاصة",
    "تشاو",
    "باضطراب",
    "الشاب",
    "البلوط",
    "المصلى",
    "المصليات",
    "الحماية",
    "الوصيفات",
    "مدد",
    "القسسة",
    "الإكليل",
    "الأكاليل",
    "شابلن",
    "كوميدي",
    "أفلام",
    "التاجر",
    "الشبان",
    "الفصول",
    "للتمييز",
    "بالماركات",
    "الخصائص",
    "التمثيل",
    "ميز",
    "المميز",
    "المميزون",
    "يميز",
    "الحزورات",
    "الشوندرة",
    "مزاوج",
    "للإتهام",
    "مشحون",
    "الشاحن",
    "الشاحنات",
    "الأجور",
    "بحذر",
    "الحربية",
    "باحسان",
    "المنظمات",
    "الخيرية",
    "الصدقة",
    "نصاب",
    "الدجل",
    "الدجل,",
    "الإقناع",
    "النصابون",
    "شارلمان",
    "تشارلز",
    "تشارليستون",
    "تشارلي",
    "شارلوت",
    "تشارلوتيسفيل",
    "السحر",
    "مسحور",
    "الساحر",
    "السحرة",
    "سرور",
    "الحواس",
    "شارون",
    "محارف",
    "ترميز",
    "المخطط",
    "الميثاق",
    "الدستور",
    "مؤجر",
    "يستأجر",
    "لرحلة",
    "يستأجرون",
    "تأجير",
    "الناقلة",
    "الدساتير",
    "الجارتي",
    "الجارتيون",
    "تشارتريز",
    "مصفر",
    "المخططات",
    "الخادمة",
    "الخادمات",
    "دوامة",
    "خطيرة",
    "ساحل",
    "صقلية",
    "المطاردة",
    "مطارد",
    "المطارد",
    "المطاردون",
    "المطاردات",
    "الهوة",
    "الهيكل",
    "عفيف",
    "عاقب",
    "معاقب",
    "المطهرون",
    "الطهارة",
    "المعاقبة",
    "التأديب",
    "المعاقبون",
    "الدردشة",
    "تشاثام",
    "تشاتاهوتشي",
    "تشاتانوجا",
    "مدردش",
    "الآثاث",
    "مهذار",
    "بثرثرة",
    "تشوسير",
    "شاعر",
    "مساق",
    "السياقة",
    "السواق",
    "تشونسي",
    "الشوفيني",
    "شوفيني",
    "الشوفينيون",
    "إمضغ",
    "ممضوغ",
    "المضغ",
    "يمضغ",
    "تشدر",
    "رخيص",
    "رخص",
    "مرخص",
    "الترخيص",
    "يرخص",
    "أرخص",
    "بسعر",
    "الرخص",
    "البخيل",
    "الإحتيال",
    "مغشوش",
    "الإحتيالات",
    "للفحص",
    "الصكوك",
    "مفحوص",
    "المدقق",
    "يبقي",
    "أوراقه",
    "خضراء",
    "الشتاء",
    "للتبقع",
    "بالمربعات",
    "المدققون",
    "التدقيق",
    "قوائم",
    "كش",
    "ملك",
    "مهزوم",
    "يهزم",
    "الهزيمة",
    "المخارج",
    "التفتيش",
    "الفحوصات",
    "الخد",
    "لعوب",
    "بشيطنة",
    "الزقزقة",
    "مزقزق",
    "المشجع",
    "المشجعون",
    "مبتهج",
    "فرحان",
    "بفرح",
    "يشجع",
    "المشجعين",
    "الكآبة",
    "الهتافات",
    "جبن",
    "برجر",
    "كعك",
    "لشيىء",
    "الأجبان",
    "جبنا",
    "جبني",
    "الفهد",
    "الطباخين",
    "كبار",
    "كلاب",
    "بالكلاب",
    "إزالة",
    "الكيمياوية",
    "الداخلية",
    "يتعاطي",
    "بالكيماويات",
    "التعاطي",
    "الصيادلة",
    "الكيمياوي",
    "تشين",
    "تشيني",
    "تشينج",
    "تشينيل",
    "الصك",
    "المربعات",
    "تشيكرز",
    "عز",
    "عزيز",
    "المعز",
    "يعز",
    "العز",
    "تشيريتون",
    "الشيروكي",
    "أبالاتشايان",
    "الهندية",
    "الكرز",
    "نقي,",
    "ببراءة",
    "الكزبرة",
    "شيريل",
    "تشيسابيك",
    "تشيشاير",
    "الشطرنج",
    "رقعة",
    "شطرنج",
    "للوضع",
    "نعش",
    "تشيستر",
    "تشيسترفيلد",
    "تشيسترتون",
    "الكستناءة",
    "الكستناء",
    "النبيل",
    "بالمجادلة",
    "تشيفروليت",
    "سبعة",
    "تشيفي",
    "الماضغ",
    "الماضغون",
    "شايان",
    "هنود",
    "محاربة",
    "محاربين",
    "الشايان",
    "تشي",
    "شيانك",
    "الإيطالية",
    "والضوء",
    "الأناقة",
    "شيكاغو",
    "المكسيكية",
    "غالط",
    "يغالط",
    "المكسيكي",
    "المكسيكان",
    "الأمريكان",
    "الفرخ",
    "القرقف",
    "(طائر)",
    "تشيكاسوس",
    "أمريكيين",
    "ارتاع",
    "الارتياع",
    "جديري",
    "الحمصة",
    "الفراخ",
    "تشيكل",
    "أنيق",
    "الهندباء",
    "بتوبيخ",
    "رئيسية",
    "الزعيم",
    "الزعماء",
    "الحريري",
    "البرغوث",
    "البراغيث",
    "العقدة,",
    "تورم",
    "الأصابع",
    "الطفولة",
    "طفولي",
    "أطفال",
    "إنجاب",
    "تشيلي",
    "التشيلي",
    "تشيلز",
    "الفلفل",
    "الحار",
    "التوابل",
    "البرد",
    "مبرد",
    "بارد",
    "الروايات",
    "المثيرة",
    "أبرد",
    "البرودة",
    "التبريد",
    "مخيف",
    "يبرد",
    "دق",
    "الرنان",
    "الوهم",
    "وهمي",
    "الرنانين",
    "الدق",
    "المدخنة",
    "كناس",
    "مدخنة",
    "المداخن",
    "الشمبانزي",
    "الذقن",
    "الصين",
    "الصيني",
    "الصينيون",
    "الأواني",
    "الصينية",
    "الشينشيلة",
    "البصيص",
    "مرنون",
    "يرن",
    "حنك",
    "مذقن",
    "بالذقن",
    "(مسك",
    "الة",
    "الكمان)",
    "تشنوكي",
    "الشاهبلوط",
    "(شجرة)",
    "الذقون",
    "الرقاقة",
    "تمكن",
    "رقاقة",
    "منتقاة",
    "شرائح",
    "السنجاب",
    "طراز",
    "إنكليزي",
    "تشيبيوا",
    "التقطيع",
    "الرقائق",
    "بتقويم",
    "مقوم",
    "مقومين",
    "السقسقة",
    "النقيق",
    "يزقزق",
    "صر",
    "(كما",
    "الصرصور)",
    "الصرصرة",
    "يصر",
    "الإزميل",
    "محفور",
    "الأزاميل",
    "شجاع",
    "الفروسية",
    "الثوم",
    "المعمر",
    "الكلورات",
    "كلوريد",
    "الكلوريد",
    "الكلوريدات",
    "كلور",
    "مكلور",
    "يكلور",
    "التكلور",
    "الكلور",
    "الكلوروفورم",
    "الكلوروفيل",
    "الجبيلة",
    "اليخضور",
    "الوتد",
    "مختنق",
    "الأوتاد",
    "الشوكولاته",
    "الشوكولاتة",
    "تشوكتو",
    "حمر",
    "تشوكتوس",
    "الإختيار",
    "الجودة",
    "الإختيارات",
    "الجوقة",
    "(ولد)",
    "الكورس",
    "الجوقات",
    "تشوكيبيري",
    "مزدحم",
    "المختنق",
    "المختنقون",
    "الإختناقات",
    "الكوليرا",
    "الكولوستيرول",
    "كولينيستيريز",
    "أنزيم",
    "اقضم",
    "قضم",
    "يقضم",
    "تشومسكي",
    "إختر",
    "المختار",
    "المختارون",
    "يختار",
    "الإرضاء",
    "الموسيقار",
    "شوبان",
    "تشوب",
    "المروحية",
    "المروحيات",
    "تلاطما",
    "ميجانا",
    "عيدان",
    "كورالي",
    "الترنيمة",
    "الترنيمات",
    "وتري",
    "الفقاريات",
    "ظهري",
    "باوتار",
    "موسيقية",
    "التزويد",
    "الحبال",
    "الرتيب",
    "صمم",
    "مدراء",
    "راقص",
    "بتلحين",
    "تصميم",
    "الرقصات",
    "يصمم",
    "الرتيبة",
    "فتاة",
    "فتيات",
    "يعمل",
    "الروتين",
    "المشيمة",
    "الضحكة",
    "مغنى",
    "الضحك",
    "ردد",
    "الترديد",
    "مختار",
    "تشو",
    "تشوتشو",
    "الشوربة",
    "كريس",
    "المسيح",
    "كريستين",
    "معمد",
    "كريستنسين",
    "كرستينسون",
    "المسيحي",
    "كريستيانا",
    "التنصر",
    "المسيحيون",
    "كرستيانسين",
    "كرستيانسون",
    "كريستي",
    "كريستينا",
    "رباني",
    "كرستوفيل",
    "كريستوف",
    "كريستوفر",
    "الكرومات",
    "اللونية",
    "الألوان",
    "التبقيع",
    "بالصبغات",
    "التسجيلات",
    "كروماتوغرافي",
    "تسجيلي",
    "الكروم",
    "صحن",
    "كروم",
    "مطلي",
    "بالكروم",
    "كرومي",
    "الطلاء",
    "الكروموسوم",
    "جو",
    "مزمن",
    "السجل",
    "مدون",
    "المؤرخون",
    "التدوين",
    "توقيت",
    "الوصف",
    "لوقت",
    "زمنيا",
    "زمني",
    "يتناول",
    "فترات",
    "منتظمة",
    "تواريخ",
    "الأحداث",
    "تأريخ",
    "الكرونومتر",
    "ميقاتي",
    "الزمن",
    "الشرنقة",
    "الشرانق",
    "الإقحوان",
    "كرايسلر",
    "الزيتوني",
    "الشوب",
    "(كائنات",
    "بحرية)",
    "تشب",
    "ماركة",
    "أقفال",
    "تجارية",
    "تشوك",
    "مرمي",
    "الرمي",
    "الخافتة",
    "ضاحك",
    "الخافت",
    "الطبطبات",
    "تشوكوالا",
    "إز",
    "مأزوز*",
    "يئز",
    "تشوكا",
    "الصاحب",
    "ود",
    "الخشبة",
    "الخشبات",
    "الأصحاب",
    "بورك",
    "مرتاد",
    "ارتياد",
    "تشرشل",
    "وينستون",
    "تشرشيل",
    "المباركة",
    "بالكنيسة",
    "كنسي",
    "عضوة",
    "باحة",
    "باحات",
    "الفظ",
    "مخضة",
    "مخضخض",
    "الخضخضة",
    "مخضات",
    "المزلق",
    "بالمزلاق",
    "المزالق",
    "الصلصة",
    "الصلصات",
    "الشبه",
    "سائلة",
    "المهضوم",
    "المخابرات",
    "السيكادا",
    "سيسرو",
    "المطبعي",
    "السواح",
    "مشابهة",
    "للسيسرو",
    "السيجار",
    "السيجارة",
    "السيجارات",
    "الرموش",
    "لديه",
    "رموش",
    "حزام",
    "السرج",
    "سينسيناتي",
    "مطوق",
    "الأطواق",
    "سيندريلا",
    "سيندي",
    "سينمائي",
    "السينما",
    "سينما",
    "السينمات",
    "سينمائيا",
    "السينمائي",
    "سينيراما",
    "سينابار",
    "سينامون",
    "القرفة",
    "خمسة",
    "الصفر",
    "مشفر",
    "التشفير",
    "الأصفار",
    "يوم",
    "سيرس",
    "(الأساطير",
    "اليونانية)",
    "الدائرة",
    "مدار",
    "دائرةشكل",
    "بيضاوي",
    "الدوائر",
    "الخاتم",
    "دار",
    "الدوران",
    "الإلتواء",
    "التعميم",
    "الاستدارة",
    "ينشر",
    "اﻷستدارة",
    "التعميمات",
    "وزع",
    "موزع",
    "يوزع",
    "التوزيع",
    "التوزيعات",
    "الانتشار",
    "المروج",
    "المروجون",
    "تمر",
    "المضلع",
    "إختن",
    "مختون",
    "الختان",
    "يختن",
    "محيطات",
    "محيطي",
    "الجوانب",
    "الإطناب",
    "مطنب",
    "أبحر",
    "مبحر",
    "يبحر",
    "إبحار",
    "الطواف",
    "حدد",
    "يحدد",
    "التقييدات",
    "خاضع",
    "لظروف",
    "ظرفي",
    "برهن",
    "راوغ",
    "مراوغ",
    "المراوغة",
    "المراوغات",
    "يراوغ",
    "السيرك",
    "السيركات",
    "التليف",
    "الكبدي",
    "المعلاق",
    "السيسكو",
    "سيسكة",
    "كبرى",
    "الإتصالات",
    "الصهريج",
    "الصهاريج",
    "الإقتباس",
    "إستشهد",
    "مستشهد",
    "يستشهد",
    "محاط",
    "بمدن",
    "الإستشهاد",
    "بمواطنة",
    "المواطنة",
    "السيترات",
    "سيتروين",
    "الأترنج",
    "الحمضيات",
    "منظر",
    "مناظر",
    "الكيفت",
    "(حيوان",
    "ثديي)",
    "المدني",
    "المدنيون",
    "الحضارة",
    "الألطاف",
    "حضاري",
    "الحضارات",
    "متحضر",
    "الممدن",
    "الممدنون",
    "التحضير",
    "صوتا",
    "المطقطق",
    "ملتف",
    "يكسو",
    "الإدعاء",
    "للإسترجاع",
    "المدعي",
    "المدعون",
    "مدعى",
    "الإدعاءات",
    "كلير",
    "قراءة",
    "البخت",
    "عراف",
    "قارئو",
    "المحار",
    "الكتوم",
    "المتسلق",
    "اﻷخرق",
    "كلاميد",
    "اللزوجة",
    "كلامينج",
    "صارخ",
    "المثير",
    "للصخب",
    "المثيرون",
    "الصراخ",
    "صاخب",
    "يصرخ",
    "صرخ",
    "بأسنان",
    "تلصق",
    "بالحذاء",
    "للمساعدة",
    "صدفة",
    "أصداف",
    "العشيرة",
    "السرية",
    "الفادح",
    "الفادحة",
    "القعقعة",
    "مقعقع",
    "بصلصلة",
    "عشائري",
    "العشائرية",
    "العشائر",
    "مصفق",
    "اخدش",
    "المصفقون",
    "كلارا",
    "كليرمونت",
    "كلارينس",
    "كليرندون",
    "الكلاريت",
    "التوضيح",
    "التوضيحات",
    "موضح",
    "الموضح",
    "يوضح",
    "وضح",
    "الكلارينت",
    "كلارك",
    "كلارج",
    "الإشتباك",
    "إشتبك",
    "المتصادم",
    "الإشتباكات",
    "مشبوك",
    "التناسلي",
    "الشبك",
    "كلاسيكي",
    "الكلاسيكية",
    "الكلاسيكي",
    "الكلاسيكيون",
    "أفخر",
    "للتصنيف",
    "التصنيفات",
    "تصنيفي",
    "المبوب",
    "المبوبة",
    "المصنف",
    "المصنفون",
    "اﻷناقة",
    "طبقي",
    "المساواة",
    "الزميل",
    "الدروس",
    "فاخر",
    "البعثرة",
    "الضوضائي",
    "التبعثر",
    "بضوضائية",
    "الضوضاء",
    "كلود",
    "كلوديا",
    "كلوديو",
    "كلوس",
    "البند",
    "كلوسين",
    "البنود",
    "كلوسيوس",
    "الخوف",
    "المن",
    "خانق",
    "النميمة",
    "الترقوة",
    "المفاتيح",
    "المخلب",
    "مخموش",
    "الخمش",
    "المخالب",
    "كلاي",
    "بالطين",
    "طيني",
    "الطين",
    "كلايتن",
    "نظيف",
    "للتنظيف",
    "المنظف",
    "المنظفون",
    "أنظف",
    "اﻷنظف",
    "النظافة",
    "التنظيفات",
    "واضح",
    "أوضح",
    "بفطنة",
    "المقاصة",
    "كليرووتر",
    "للشق",
    "الشقوق",
    "ملصوق",
    "الساطور",
    "السواطير",
    "المفتاح",
    "كليم",
    "كليمينس",
    "الرحمة",
    "كليمنس",
    "كليمنت",
    "رحيم",
    "كليمينتس",
    "كليمسون",
    "الإحكام",
    "ديني",
    "الاكليروسية",
    "للأكليروسية",
    "مكتبي",
    "مكتبية",
    "كليفيلند",
    "المبتذلة",
    "إنقر",
    "للنقر",
    "منقور",
    "النقر",
    "النقرات",
    "الزبون",
    "بالزبائن",
    "الزبائن",
    "الرعاية",
    "كلفورد",
    "كليفتون",
    "الفترة",
    "الحرجة",
    "ذروي",
    "حرج",
    "المناخ",
    "مناخي",
    "مناخيا",
    "الذروة",
    "الذرى",
    "بلوغ",
    "للتسلق",
    "إرتفع",
    "المتسلقون",
    "بتثبيت",
    "سريري",
    "سريريا",
    "السريري",
    "الأطباء",
    "السريريون",
    "العيادات",
    "الخشخشة",
    "مخشخش",
    "الآجر",
    "القاسي",
    "كلنت",
    "كلنتن",
    "كليو",
    "الدبوس",
    "النصوص",
    "مقصوص",
    "المقص",
    "المقصات",
    "القصاصة",
    "القصاصات",
    "الدبابيس",
    "الزمرة",
    "الزمر",
    "منغلق",
    "باغلاق",
    "الطائفية",
    "البظر",
    "البظور",
    "كلايف",
    "المعاطف",
    "العبي",
    "إضرب",
    "مضروب",
    "النسائية",
    "الساعة",
    "ساعة",
    "الساعاتي",
    "تسجيل",
    "الساعات",
    "بإتجاه",
    "عقرب",
    "التربة",
    "الأفظاظ",
    "السدادة",
    "معرقل",
    "السدادات",
    "معزول",
    "رواقي",
    "امش",
    "متثاقلا",
    "مشى",
    "مشي",
    "يمشي",
    "مستنسخ",
    "النسخ",
    "رفاسي",
    "الإستنساخ",
    "الإنتهاء",
    "إنتهاء",
    "مغلق",
    "مباشرة",
    "أقرب",
    "يغلق",
    "مستقبل",
    "المقربة",
    "الإغلاق",
    "الجلطة",
    "بالقماش",
    "فرشاة",
    "العصري",
    "الغسيل",
    "حبال",
    "مشبك",
    "مشابك",
    "البزاز",
    "الجلطات",
    "مخثر",
    "التخثر",
    "متخثر",
    "إغلاق",
    "أغلق",
    "اغلاق",
    "الغيمة",
    "العواصف",
    "غيما",
    "غيوما",
    "غائم",
    "التغيم",
    "صافي",
    "الصفاء",
    "الغيوم",
    "الفص",
    "البرسيم",
    "ورقة",
    "الفصوص",
    "هرج",
    "الحمق",
    "مهرج",
    "أتخم",
    "متخم",
    "الإتخام",
    "يتخم",
    "النادي",
    "إرتياد",
    "النوادي",
    "المضربية",
    "مشوه",
    "اجتماع",
    "اجتماعات",
    "القرقة",
    "مقيق",
    "القيق",
    "يقيق",
    "جاهل",
    "متثاقل",
    "التثاقل",
    "أخرق",
    "الخراقة",
    "العنقود",
    "متجمع",
    "العناقيد",
    "الفاصل",
    "ممسوك",
    "الفواصل",
    "يبعثر",
    "كلايد",
    "أساطير",
    "يونانية",
    "زوجة",
    "موصل",
    "الأكسيد",
    "المكمل",
    "كولورادو",
    "المختلط",
    "مدرب",
    "السياق",
    "(جمع",
    "سائق)",
    "الحوذي",
    "الحوذيون",
    "للتجلط",
    "المجلط",
    "يتخثر",
    "(أدى",
    "التفحم)",
    "ناقلة",
    "إلتئم",
    "ملتئم",
    "متحد",
    "يلتئم",
    "الإلتئام",
    "كولنج",
    "الحجري",
    "مخشن",
    "التخشين",
    "يخشن",
    "ساحلي",
    "الوقاية",
    "الوقايات",
    "الساحلية",
    "الساحلي",
    "السواحل",
    "المغطون",
    "الطلاءات",
    "ذيل",
    "ذيول",
    "بمشاركة",
    "المشاركة",
    "بالتأليف",
    "المشاركون",
    "الكابل",
    "المحوري",
    "مقنع",
    "الملاطف",
    "الملاطفون",
    "الكابلات",
    "المحورية",
    "بتودد",
    "الأوز",
    "الكوبالت",
    "كوب",
    "الحصوة",
    "مرقع",
    "الإسكافي",
    "الإسكافيون",
    "الحصى",
    "معبد",
    "بالحصاة",
    "الترقيع",
    "كوبول",
    "الكوبرا",
    "ذكور",
    "العنكبوت",
    "متشابك",
    "عنكبوتي",
    "أنسجة",
    "كوكا",
    "الكوكائين",
    "كوكيديوسيس",
    "حيواني)",
    "العصعص",
    "كوتشيس",
    "القوقعة",
    "كوكران",
    "طيار",
    "كوتشران",
    "والثور",
    "الأشرطة",
    "سخيف",
    "الخنفساء",
    "متبختر",
    "المنغمس",
    "الحولاء",
    "اﻷحول",
    "باحولال",
    "(أحول",
    "العين)",
    "اﻷحولال",
    "الديكة",
    "سباق",
    "سباقات",
    "التبختر",
    "الصدف",
    "العشب",
    "متغضن",
    "الأصداف",
    "التغضن",
    "كوكني",
    "مواطني",
    "لندن",
    "قمرة",
    "القيادة",
    "قمرات",
    "الصرصور",
    "الصراصير",
    "العرف",
    "الكوكتيل",
    "الكوكتيلات",
    "كوكو",
    "جوزة",
    "جوز",
    "القد",
    "الختام",
    "كودينجتون",
    "دلل",
    "مدلل",
    "يدلل",
    "التدليل",
    "رمز",
    "وفاك",
    "للشفرة",
    "الكودين",
    "المحارف",
    "المبرمج",
    "المبرمجون",
    "التشفيرات",
    "اﻷجوبة",
    "المحتملة",
    "تسلسل",
    "معلومات",
    "الجيني",
    "رفرف",
    "الفخذين",
    "كودي",
    "برمائية",
    "للديدان",
    "الطالبة",
    "مختلط",
    "الطالبات",
    "المعاملة",
    "المعاملات",
    "مساوي",
    "أجبر",
    "مجبر",
    "للأكراه",
    "الإجبار",
    "قسري",
    "القهر",
    "اﻷكراه",
    "معاصر",
    "تعايش",
    "متعايش",
    "التعايش",
    "تواجدي",
    "يتعايش",
    "متساوي",
    "امتداد",
    "اﻷمتداد",
    "القهوة",
    "إبريق",
    "كوفي",
    "التابوت",
    "التوابيت",
    "كوفمان",
    "عجلة",
    "ترس",
    "التأمل",
    "التأملات",
    "الكونياك",
    "الكلمات",
    "المشتركة",
    "القرابة",
    "القرابات",
    "قرابي",
    "إدراكي",
    "بادراك",
    "اللقب",
    "عايش",
    "السكن",
    "معايش",
    "المعايشة",
    "يعايش",
    "كوهين",
    "متماسك",
    "التماسك",
    "الترابط",
    "الإرث",
    "يتماسك",
    "بالتحام",
    "كون",
    "كوهوش",
    "(أسم",
    "نبات)",
    "مصفف",
    "مصففو",
    "التسريحة",
    "ملفوف",
    "اللف",
    "العملة",
    "المعدنية",
    "العملات",
    "تزامن",
    "متوافق",
    "بنفس",
    "يتزامن",
    "مسكوك",
    "مزيف",
    "النقود",
    "السك",
    "جنسي",
    "الجماع",
    "الكوكا",
    "تفحم",
    "الكولا",
    "المصفاة",
    "المصافي",
    "كولاس",
    "تتمة",
    "تتمات",
    "كولبي",
    "كول",
    "كولمان",
    "كوليردج",
    "كوليت",
    "القوليوس",
    "كولجايت",
    "بمغص",
    "استاد",
    "القولون",
    "كولاب",
    "نت",
    "تعاون",
    "متعاون",
    "يتعاون",
    "التعاون",
    "تعاوني",
    "بتعاون",
    "المتعاون",
    "المتعاونون",
    "التصويري",
    "الكولاجين",
    "الإنهيار",
    "منهار",
    "السقوط",
    "اﻷنهيار",
    "للانهيار",
    "الياقة",
    "الكرنب",
    "ياقة",
    "الياقات",
    "بالتوثيق",
    "المقارنون",
    "رصين",
    "ببرودة",
    "للجمع",
    "الجماعات",
    "الجماعية",
    "الجموع",
    "التعاونية",
    "الكلية",
    "الكليات",
    "علاقات",
    "الزمالة",
    "طلبة",
    "مصطدم",
    "يصطدم",
    "الكولي",
    "غمق",
    "كولير",
    "مناجم",
    "كوليرز",
    "منجم",
    "كوليس",
    "واز",
    "موازى",
    "يوازي",
    "الموازاة",
    "تلسكوب",
    "الكولنز",
    "كحولي",
    "الإصطدامات",
    "الغروية",
    "صمغي",
    "أختصار",
    "عامي",
    "المؤتمرات",
    "العامية",
    "المحادثات",
    "الدراسية",
    "الحديث",
    "تآمر",
    "يتآمر",
    "كولوسيف",
    "بتواطئ",
    "(عطر)",
    "معطر",
    "كولومبيا",
    "الكولمبي",
    "الكولمبيون",
    "كولومبو",
    "العقيد",
    "العقداء",
    "المستعمر",
    "الإستعمارية",
    "الإستعماري",
    "أستعماري",
    "إستعماري",
    "اﻷستعمار",
    "المستعمرون",
    "المستعمرات",
    "الإستعمار",
    "إستعمر",
    "مستعمر",
    "يستعمر",
    "مزود",
    "بأعمدة",
    "الكولونات",
    "المستعمرة",
    "بخداع",
    "يلون",
    "التلوين",
    "لونحد",
    "ملون",
    "الملونين",
    "الزنوج",
    "تلون",
    "التلون",
    "لونتدرج",
    "الأصباغ",
    "الرسام",
    "تلوين",
    "بانعدام",
    "اﻷلوان",
    "العمالقة",
    "العملاق",
    "بعمى",
    "خريطة",
    "المسدس",
    "مسدس",
    "المهور",
    "كولومبي",
    "كولومبوس",
    "عمودي",
    "أعمدة",
    "المعلق",
    "الصحفي",
    "المعلقون",
    "اللفت",
    "الغيبوبة",
    "كومانتش",
    "(قبيلة",
    "هندية)",
    "المشط",
    "مقاتل",
    "المقاتلة",
    "ممشط",
    "الماشط",
    "الماشطات",
    "للدمج",
    "اندماجي",
    "الحاصدة",
    "الموحد",
    "الموحدين",
    "الحاصدات",
    "التمشيط",
    "منسدل",
    "كومز",
    "للإحتراق",
    "باشتعال",
    "إحتراقي",
    "كومديكس",
    "تعال",
    "العودة",
    "الكوميدي",
    "الكوميديون",
    "الكوميدية",
    "الكوميديا",
    "التدني",
    "وسيم",
    "الوسامة",
    "القادم",
    "المذنب",
    "بالمذنب",
    "المذنبات",
    "القصاص",
    "العادل",
    "يحفظ",
    "بارتياح",
    "المعزي",
    "المعزون",
    "الترييح",
    "راحة",
    "أسباب",
    "فكاهي",
    "المجلات",
    "الهزلية",
    "مكتب",
    "الإعلام",
    "الشيوعي",
    "المجيئ",
    "المجاملة",
    "مأمور",
    "إستول",
    "مستولى",
    "الإستيلاء",
    "يستولى",
    "مشرف",
    "الوصية",
    "المغوار",
    "المغاوير",
    "الأوامر",
    "كوم",
    "أحي",
    "محيى",
    "يحيي",
    "الإحياء",
    "تذكاري",
    "إشرع",
    "مشروع",
    "يشرع",
    "الشرع",
    "وص",
    "بالثناء",
    "يستحق",
    "الثناء",
    "الإطراء",
    "مديحي",
    "موصى",
    "الشاكر",
    "التوصية",
    "يوصي",
    "المقايسة",
    "متناسب",
    "متعادل",
    "التعادل",
    "التعادلات",
    "تاجر",
    "(فعل",
    "ماضي)",
    "التجارية",
    "التتجير",
    "تجر",
    "متجر",
    "يتجر",
    "تجاري",
    "اشتراكي",
    "الشيوعيون",
    "إمزج",
    "يمزج",
    "المزج",
    "إرث",
    "مرثو",
    "يرثو",
    "الرثاء",
    "المواساة",
    "التعاطف",
    "المفوضية",
    "النواب",
    "النائب",
    "مكلف",
    "اللجان",
    "إرتكب",
    "الإلتزامات",
    "يرتكب",
    "الإحالة",
    "ملتزم",
    "المتعهد",
    "الإرتكاب",
    "بملائمة",
    "السلعة",
    "العميد",
    "العمداء",
    "البحريون",
    "المشاعة",
    "الشيوع",
    "العامي",
    "عموما",
    "العموم",
    "الإعتيادي",
    "المحلات",
    "الكومنولث",
    "الإضطراب",
    "الإضطرابات",
    "عمومي",
    "مشاعا",
    "الكومونة",
    "مناجى",
    "الكومونات",
    "الإنتقال",
    "للإتصال",
    "للنقل",
    "المبلغ",
    "المبلغون",
    "إتصل",
    "متصل",
    "اتصالي",
    "المحاورون",
    "البيان",
    "البيانات",
    "الشيوعية",
    "شيوعي",
    "الجاليات",
    "الجالية",
    "أشع",
    "مشاع",
    "يشيع",
    "المبادله",
    "مبادلته",
    "إعكس",
    "معكوس",
    "يعكس",
    "التخفيفات",
    "تبادلي",
    "تبادليا",
    "مسافر",
    "المسافرون",
    "يسافر",
    "السفر",
    "كوموروس",
    "الضاغط",
    "الضاغطين",
    "الاكتناز",
    "المطحنة",
    "المطاحن",
    "المواثيق",
    "الشركات",
    "للرفقة",
    "الرفقة",
    "رفاقي",
    "وحيد",
    "الشركة",
    "كومباك",
    "مقارن",
    "بالمقارنة",
    "نسبيا",
    "صيغ",
    "المقارن",
    "يقارن",
    "المقصورة",
    "مقسم",
    "مجزأ",
    "المقصورات",
    "البوصلة",
    "البوصلات",
    "الشفقة",
    "العطف",
    "التجانس",
    "بانسجام",
    "أرغم",
    "سهل",
    "مرغم",
    "الإرغام",
    "يرغم",
    "بإيجاز",
    "المال",
    "عوض",
    "يعوض",
    "التعويضات",
    "تعويضي",
    "المعوض",
    "تنافس",
    "متنافس",
    "يتنافس",
    "التنافس",
    "المنافسة",
    "المسابقات",
    "بتنافس",
    "المنافس",
    "المنافسون",
    "إجمع",
    "المجمع",
    "المجمعات",
    "راضي",
    "إشتك",
    "المشتكي",
    "المشتكون",
    "مشتكى",
    "المتذمر",
    "المتذمرون",
    "بتشكي",
    "يشتكي",
    "قنوع",
    "بليونة",
    "التكملة",
    "تكامل",
    "مكمل",
    "التتميم",
    "التكملات",
    "الكمال",
    "يكمل",
    "الإكمال",
    "الأكمال",
    "الطبيعة",
    "بشرة",
    "الطبائع",
    "التعقيدات",
    "التعقيد",
    "معقد",
    "الإذعان",
    "طيع",
    "محير",
    "التواطئ",
    "ممتثل",
    "المطيع",
    "المطيعون",
    "يمتثل",
    "مجاني",
    "ممدوح",
    "المجامل",
    "المجاملون",
    "المدح",
    "الصلاة",
    "إمتثل",
    "الإمتثال",
    "المكون",
    "المكونات",
    "إنسجم",
    "منسجم",
    "الإنسجام",
    "ينسجم",
    "أعدد",
    "برباطة",
    "جأش",
    "السكينة",
    "الملحن",
    "الملحنون",
    "الإعداد",
    "التركيب",
    "تركيبي",
    "التراكيب",
    "المنضد",
    "المنضدون",
    "العضوي",
    "الكومبوت",
    "(فاكهة",
    "مطبوخة)",
    "إفهم",
    "شامل",
    "الشمولية",
    "الكمادة",
    "الكمادات",
    "الإنضغاطية",
    "الضغوط",
    "ضغطي",
    "الضواغط",
    "إشمل",
    "مشمول",
    "يشمل",
    "الشمل",
    "المتنازلون",
    "التسويات",
    "كومبتون",
    "المراقب",
    "المراقبون",
    "إلزامي",
    "الندم",
    "بالأيمان",
    "شاهد",
    "كومبيو",
    "سيرف",
    "الحاسوب",
    "بمساعدة",
    "الحوسبة",
    "حوسب",
    "إلكتروني",
    "يحوسب",
    "الرفيق",
    "بود",
    "الرفاق",
    "الرفاقية",
    "كومسات",
    "إخدع",
    "كوناكري",
    "كونانت",
    "مسلسل",
    "يسلسل",
    "مقعرا",
    "التقعر",
    "اﻷخفاء",
    "مخفى",
    "المخفي",
    "المخفون",
    "إعترف",
    "شك",
    "الإعتراف",
    "بخيلاء",
    "الأوهام",
    "المعقولية",
    "معقول",
    "اﻷستيعاب",
    "المعقول",
    "المعتقد",
    "تركيزي",
    "مركزية",
    "فكري",
    "المفاهيم",
    "للاستيعاب",
    "تصوري",
    "تصور",
    "يتصور",
    "التصور",
    "مفهوميا",
    "باهتمام",
    "الحفلة",
    "منسق",
    "بتدبير",
    "كونسيرتينو",
    "لأدوات",
    "وحيدة",
    "(موسيقى)",
    "الحفلات",
    "الملتزمون",
    "التنازلات",
    "القوقع",
    "القواقع",
    "حارس",
    "حراس",
    "مصالح",
    "يصالح",
    "المصالحة",
    "إسترضائي",
    "الوسيط",
    "تصالحي",
    "مصغر",
    "الإجتماع",
    "الإجتماعات",
    "إستنتج",
    "مستنتج",
    "المستنتج",
    "يستنتج",
    "الإستنتاج",
    "الخاتمة",
    "الإستنتاجات",
    "حاسم",
    "الحسم",
    "الملفق",
    "الهاضم",
    "الكونكورد",
    "كونكورديا",
    "الإلتقاء",
    "الخرسانة",
    "بالخرسانة",
    "خرساني",
    "الصلابة",
    "التحجير",
    "الإتخاذ",
    "المحظيات",
    "المحظية",
    "شبق",
    "إلتق",
    "متلاقي",
    "يلتقي",
    "الهزة",
    "الهزات",
    "أدن",
    "مدان",
    "الإدانات",
    "إداني",
    "المستنكر",
    "المتكثف",
    "المتكثفات",
    "للتكثيف",
    "متنازل",
    "التابل",
    "شرطي",
    "مشروط",
    "المكيفات",
    "الشروط",
    "عزائي",
    "معزى",
    "التعزية",
    "التعازي",
    "يعزي",
    "الواقية",
    "إقبل",
    "الكندور",
    "أد",
    "مؤدى",
    "يؤدي",
    "الإداء",
    "باعث",
    "المواصلة",
    "مجرى",
    "للتوصيل",
    "الإجراء",
    "التوصيلات",
    "المخروط",
    "كونيكتيفا",
    "الردبكية",
    "المخاريط",
    "الكونستوغة",
    "تحادث",
    "متحادث",
    "يتحادث",
    "المحادثة",
    "الحلواني",
    "الحلوانيون",
    "يركب",
    "الإتحادات",
    "كونفدرالي",
    "تشاور",
    "المشاور",
    "المشاورون",
    "إجراء",
    "الإهداء",
    "للاجتماع",
    "المانح",
    "المانحون",
    "التشاور",
    "يتشاور",
    "كرسي",
    "إعترافي",
    "كراسي",
    "الأعتراف",
    "الإعترافات",
    "المعترف",
    "المعترفون",
    "النثار",
    "المستشارة",
    "إعهد",
    "معهود",
    "المخبر",
    "المخبرين",
    "يعهد",
    "العهد",
    "إعداد",
    "إصدارةالتعريف",
    "شكلي",
    "التشكيل",
    "وفق",
    "مشكل",
    "محصور",
    "المقيد",
    "الإنحصار",
    "مؤكد",
    "تأكيدي",
    "صادر",
    "يصادر",
    "المصادرة",
    "المصادرات",
    "الحريق",
    "الحرائق",
    "النزاع",
    "التضارب",
    "نزاعي",
    "النزاعات",
    "الملتقى",
    "الملتقيات",
    "مندمج",
    "البؤر",
    "مطابق",
    "مطيعة",
    "إمتثالي",
    "بتكوين",
    "يتوافق",
    "فند",
    "بامتعاظ",
    "التفنيد",
    "يفند",
    "المجابهة",
    "مجابهي",
    "المجابهات",
    "مواجه",
    "المواجه",
    "المواجهين",
    "يواجه",
    "كونفوشيوس",
    "(الفيلسوف",
    "الصيني)",
    "الكونفوشيوسية",
    "اﻷرباك",
    "بارباك",
    "بارتباك",
    "المحيرين",
    "الدحض",
    "إدحض",
    "مدحوض",
    "المفند",
    "يدحض",
    "التصلب",
    "المجانس",
    "تناسبي",
    "ثعبان",
    "إزدحم",
    "الإزدحام",
    "إزدحامي",
    "يزدحم",
    "مكتل",
    "التكتلات",
    "الكونغو",
    "الكونغوليون",
    "هنئ",
    "مهنأ",
    "يهنئ",
    "التهنئة",
    "التهنأة",
    "التهاني",
    "المهنيء",
    "تهنيئي",
    "تجمعي",
    "جماعاتي",
    "الأبرشي",
    "الكونجرس",
    "عضوات",
    "التطابق",
    "متطابق",
    "الإتفاقيات",
    "الإتفاق",
    "اﻷتفاق",
    "مخروطي",
    "المخروطية",
    "هندسة",
    "المخروطيات",
    "الصنوبرية",
    "صنوبري",
    "الصنوبريات",
    "مخمن",
    "تخميني",
    "التخمين",
    "محدوس",
    "المخمن",
    "الحدس",
    "وحد",
    "موحد",
    "التوحيد",
    "يوحد",
    "زوجي",
    "صرفي",
    "الإقتران",
    "إقتراني",
    "الإرتباطات",
    "الملتحمة",
    "رابط",
    "الرمد",
    "حروف",
    "الأزمة",
    "الأزمات",
    "تحضير",
    "الأرواح",
    "التعاويذ",
    "مناشد",
    "المشعوذ",
    "المشعوذون",
    "الشعوذة",
    "الأنف",
    "متعطل",
    "الكونكر",
    "(ثمر",
    "يؤكل)",
    "التعطيل",
    "الأنوف",
    "كونلي",
    "خلقي",
    "أوصل",
    "للربط",
    "بارتباط",
    "الموصل",
    "الموصلات",
    "كونيكتيكت",
    "الإيصال",
    "برابطة",
    "يوصل",
    "مخدوع",
    "كونيلي",
    "كونير",
    "كوني",
    "نوبة",
    "الهستيرية",
    "التغاضي",
    "المتستر",
    "ذواق",
    "الخبرة",
    "التذوق",
    "كونورس",
    "التضمين",
    "تلميحي",
    "إعن",
    "معني",
    "يعني",
    "كونس",
    "زيجي",
    "الزوجية",
    "إفتح",
    "مقهور",
    "محتل",
    "الفتح",
    "باخضاع",
    "الفاتح",
    "الفاتحون",
    "يفتح",
    "الفتوحات",
    "كونراد",
    "قرابة",
    "الرحم",
    "الضمير",
    "الضمائر",
    "واعي",
    "شعوريا",
    "المجند",
    "مجند",
    "التجنيد",
    "المجندون",
    "مكرس",
    "يكرس",
    "التكريس",
    "متتالي",
    "بالتوالي",
    "التتالي",
    "متلازم",
    "الإجماع",
    "بموافقة",
    "النتائج",
    "ناتج",
    "لاحقا",
    "ولذلك",
    "للحفظ",
    "المحافظون",
    "الحمايات",
    "المحافظية",
    "محافظ",
    "الواقي",
    "إحفظ",
    "محفوظ",
    "الموفر",
    "إعتبر",
    "مراعي",
    "لشعور",
    "المراعاة",
    "الإعتبار",
    "الإعتبارات",
    "معتبر",
    "يعتبر",
    "للإيداع",
    "مودع",
    "المرسل",
    "الإيداع",
    "الشحنة",
    "الشحنات",
    "يودع",
    "تضمن",
    "متضمن",
    "الإتساق",
    "يتضمن",
    "المعزى",
    "(مستقبل",
    "العزاء)",
    "مواسى",
    "المؤازر",
    "المؤازرون",
    "إدعم",
    "مدعوم",
    "الدعم",
    "بمواساة",
    "القرين",
    "معاشر",
    "المالية",
    "المعاشرة",
    "الإئتلاف",
    "الأقران",
    "المؤامرات",
    "المؤامرة",
    "المتآمر",
    "تآمري",
    "المتآمرون",
    "شرطة",
    "كونستانس",
    "الثبات",
    "قسطنطين",
    "القسطنطينية",
    "الثوابت",
    "إسطع",
    "أرعب",
    "يرعب",
    "الإرعاب",
    "أمسك",
    "الإمساك",
    "الإنتخابية",
    "الانتخابية",
    "الناخب",
    "تأسيسي",
    "الناخبون",
    "دستوري",
    "الدستورية",
    "دستوريا",
    "أعق",
    "باكراه",
    "الإعاقة",
    "يعيق",
    "القيود",
    "التباين",
    "قلص",
    "مقلص",
    "التقليص",
    "الإنقباض",
    "تضيقي",
    "يقلص",
    "مبني",
    "بنائي",
    "بتركيب",
    "الإنشاءات",
    "الإيجابية",
    "البنائون",
    "التركيبات",
    "فسر",
    "مفسر",
    "يفسر",
    "التفسير",
    "مشاركة",
    "النمط",
    "كونسوتود",
    "القنصل",
    "قنصلي",
    "كونسولات",
    "القنصلية",
    "القنصليات",
    "القناصل",
    "إستشر",
    "الإستشارة",
    "إستشارة",
    "الإستشارات",
    "إستشاري",
    "مستشار",
    "يستشير",
    "مستهلك",
    "إستهلك",
    "المستهلك",
    "الاستهلاك",
    "المستهلكون",
    "يستهلك",
    "باستهلاك",
    "الإتمام",
    "إكمالي",
    "المسلول",
    "سلي",
    "اﻷستهلاكي",
    "العدوى",
    "معدي",
    "إحتو",
    "للإحتواء",
    "محتوى",
    "الحاوية",
    "الحاويات",
    "الإحتواء",
    "الملوث",
    "ملوث",
    "التلوث",
    "تلوثي",
    "تأمل",
    "يتأمل",
    "تأملي",
    "البقاء",
    "(في",
    "الوقت)",
    "الإحتقار",
    "الإذلال",
    "حقير",
    "الازدراء",
    "باحتقار",
    "محتقر",
    "الإستخفاف",
    "المحتوى",
    "قانع",
    "باقتناع",
    "الإقتناع",
    "الزعم",
    "المزاعم",
    "بالحاح",
    "المجادلة",
    "بقناعة",
    "القناعة",
    "المحتويات",
    "المسابقة",
    "للنقض",
    "المتسابق",
    "المتسابقون",
    "متنازع",
    "السياقات",
    "سياقي",
    "التلامس",
    "قاري",
    "باعتدال",
    "القارات",
    "حالات",
    "الطوارئ",
    "الإستمرار",
    "واصل",
    "المواصل",
    "يواصل",
    "الاستمرارية",
    "إلو",
    "ملوي",
    "اللوي",
    "اللي",
    "التشويهات",
    "ملتوي",
    "يلوى",
    "أحيط",
    "الإحاطة",
    "المهربة",
    "كونتراباس",
    "للحمل",
    "متقلص",
    "منقبض",
    "المقاولات",
    "الإنكماش",
    "إنقباضي",
    "المقاول",
    "المقاولون",
    "العقود",
    "تعاقدي",
    "تعاقديا",
    "مناقض",
    "المناقضة",
    "التناقض",
    "التناقضات",
    "يناقض",
    "بالصفات",
    "المعاكسة",
    "بالتغاير",
    "بالتضاد",
    "غيمة",
    "صناعية",
    "(صنع",
    "الانسان)",
    "بطلان",
    "مبين",
    "يبين",
    "تبيين",
    "الاستعمال",
    "كونترالتو",
    "البدعة",
    "البدع",
    "بالعكس",
    "المعارضة",
    "عكسيا",
    "متغاير",
    "التغاير",
    "ناف",
    "منافى",
    "المخالف",
    "ينافي",
    "المنافاة",
    "الإنتهاك",
    "اشتباك",
    "ساهم",
    "مساهم",
    "يساهم",
    "المساهمة",
    "المساهمات",
    "تائب",
    "تائبا",
    "الإختراع",
    "الإختراعات",
    "دبر",
    "التدبير",
    "إتش",
    "التحكمالجسم",
    "التحكمالجسمالمقاطعة",
    "المسيطر",
    "مسيطر",
    "للجدل",
    "جداليا",
    "الخلافات",
    "الخلاف",
    "خالف",
    "نزاع",
    "يخالف",
    "متمرد",
    "التمرد",
    "رض",
    "اللغز",
    "الألغاز",
    "المجتمعات",
    "تعاف",
    "متعافى",
    "النقاهة",
    "ناقه",
    "يتعافى",
    "التعافي",
    "إنقل",
    "المسخن",
    "إجتمع",
    "مجتمع",
    "الداعي",
    "الدعاة",
    "يجتمع",
    "وسائل",
    "الإتفاقية",
    "تقليدي",
    "تمسك",
    "بالتقاليد",
    "تقليديا",
    "بميثاق",
    "(شخص)",
    "تلاق",
    "متلاقى",
    "التقارب",
    "التقاربات",
    "متقارب",
    "يتلاقى",
    "التظافر",
    "مطلع",
    "تحادثي",
    "المتحدث",
    "بالمقابل",
    "نقيض",
    "الأحاديث",
    "التحدث",
    "المتحول",
    "المحولون",
    "التحول",
    "المتحولون",
    "محدبمقعر",
    "التحدبات",
    "التحدب",
    "محرر",
    "وثائق",
    "محررون",
    "إجراءات",
    "الحامل",
    "الحاملون",
    "المدان",
    "الإتهامات",
    "تجريمي",
    "المدانون",
    "إقتنع",
    "مقتنع",
    "المقنع",
    "المقنعون",
    "يقتنع",
    "باقناع",
    "الاقناع",
    "مؤنس",
    "إدع",
    "الدعوات",
    "مدعو",
    "يدعو",
    "يلتوي",
    "الإلتواءات",
    "مواكب",
    "المواكبة",
    "تشنج",
    "متشنج",
    "يتشنج",
    "التشنج",
    "التشنجات",
    "تشنجي",
    "كونواي",
    "إهدل",
    "مهدول",
    "الهدل",
    "الطباخ",
    "كوك",
    "مطبوخ",
    "الطباخات",
    "الكوكي",
    "الكوكيز",
    "الطهي",
    "الطباخون",
    "مطعم",
    "إهدأ",
    "ولا",
    "حار",
    "غاز",
    "بعملية",
    "المبردة",
    "برد",
    "المبردات",
    "الأعصاب",
    "كوليدج",
    "الحمال",
    "الراكون",
    "(حيوان)",
    "كوبير",
    "التعاونيات",
    "صانعي",
    "الأعشاش",
    "تنسيقا",
    "الاحداثية",
    "النظراء",
    "التنسيق",
    "التنسيقات",
    "تناسقي",
    "المنسق",
    "المنسقون",
    "كورس",
    "القملة",
    "الكوبال",
    "متحمل",
    "كوبيلاند",
    "كوبنهاكن",
    "كوبرنيكي",
    "كوبرنيكوس",
    "(عالم",
    "البولندي)",
    "منسوخ",
    "الناسخ",
    "الناسخون",
    "الإفريز",
    "المستوى",
    "البوليمر",
    "يتكون",
    "مونمر",
    "كبريتات",
    "الحديدوز",
    "بالنحاس",
    "الكوبرهيد",
    "(أفعى)",
    "القروش",
    "الصفار",
    "الصفارون",
    "الأجمة",
    "الأجمات",
    "كوبرا",
    "كوبرينس",
    "(عيش",
    "الغراب)",
    "الصلة",
    "بفعل",
    "الصلات",
    "زاوج",
    "يزاوج",
    "التزاوج",
    "كوبولاتوري",
    "الدفتر",
    "الدفاتر",
    "الطبع",
    "انسخ",
    "تدلع",
    "الدلال",
    "المغناج",
    "متدلع",
    "المغانيج",
    "التدلع",
    "الصديفي",
    "المرجان",
    "الا",
    "المرجانية",
    "مرجاني",
    "كوربا",
    "أسند",
    "كوربيت",
    "مشدود",
    "بالحبال",
    "كوردر",
    "وديا",
    "الودية",
    "العصائر",
    "الكوردايت",
    "قرطبة",
    "الأنطقة",
    "القرطبي",
    "كودري",
    "الأقطان",
    "الصميم",
    "رئيسي",
    "كوري",
    "كورك",
    "مسدود",
    "بفلينة",
    "الممتاز",
    "الممتازين",
    "الفلين",
    "فليني",
    "الغاق",
    "الغاقات",
    "كوز",
    "القرنية",
    "كورنيليا",
    "كورنيليوس",
    "كورنيل",
    "حقل",
    "حقول",
    "وردة",
    "أسخف",
    "كورنينج",
    "كورنيشي",
    "الدقيق",
    "ساق",
    "نشا",
    "كورنوال",
    "بتلة",
    "التويج",
    "كورونادو",
    "تاجي",
    "التاجية",
    "يتوج",
    "التتويج",
    "قاض",
    "بالوفاة",
    "قضاة",
    "متوج",
    "التويجات",
    "العريف",
    "بدني",
    "بالشركات",
    "نقابي",
    "المادية",
    "الهيئة",
    "البدانة",
    "كرية",
    "نووي",
    "كورالس",
    "الحضائر",
    "للتصليح",
    "التصحيح",
    "تصليحي",
    "التصحيحات",
    "بتصحيح",
    "الصواب",
    "المصلح",
    "يصحح",
    "بتبادل",
    "راسل",
    "مراسل",
    "المراسلة",
    "المراسلات",
    "المراسل",
    "المراسلون",
    "تماثليا",
    "يراسل",
    "تصويب",
    "للإصلاح",
    "أيد",
    "إثباتي",
    "الكوربوري",
    "(احتفال)",
    "تصدأ",
    "متصدأ",
    "يتصدأ",
    "للتاكل",
    "التصدأ",
    "بتآكل",
    "عوامل",
    "موج",
    "يموج",
    "التموج",
    "التمويج",
    "التمويجات",
    "إفسادي",
    "يفسد",
    "صدر",
    "الفستان",
    "المخصر",
    "الكورسيهات",
    "كورسيكا",
    "كورسيكي",
    "لحائي",
    "القشور",
    "كورتيكوستيرويد",
    "هرمونات",
    "منشطة",
    "الكورتيزون",
    "كورتلاند",
    "الألمونيوم",
    "تألق",
    "متألق",
    "يتألق",
    "التألق",
    "غراب",
    "جتا",
    "نسبة",
    "الضلع",
    "التمام",
    "الهراوة",
    "كوشر",
    "الموقعون",
    "الدفء",
    "مستحضرات",
    "نشأة",
    "الكون",
    "بالكوزموغرافيا",
    "أوصاف",
    "كوزمولوجي",
    "الكوزمولوجيا",
    "الروسي",
    "وشكل",
    "عالمي",
    "الكونية",
    "الرحالة",
    "كوزموبوليتيكي",
    "الراعي",
    "كفل",
    "الرعاة",
    "القوزاقي",
    "الكلفة",
    "توفير",
    "كوستا",
    "كوستيلو",
    "الخضري",
    "التكاليف",
    "أغلى",
    "الغلاء",
    "غالي",
    "الكلف",
    "البدلة",
    "بالملابس",
    "مصمم",
    "الأزياء",
    "مصممو",
    "البدلات",
    "الإلباس",
    "المهد",
    "الشلة",
    "مقربة",
    "كوتيلون",
    "(رقصة)",
    "السفرجلية",
    "المهود",
    "كوتا",
    "الكوخ",
    "الأكواخ",
    "القطن",
    "بذرة",
    "النبتة",
    "النبتات",
    "الأريكة",
    "مبسوط",
    "الأرائك",
    "البسط",
    "السعال",
    "ساعل",
    "يسعل",
    "يكح",
    "الكولون",
    "كهربائية",
    "حديدة",
    "المحراث",
    "الوزير",
    "الوزراء",
    "المحليون",
    "الوزيرة",
    "المحليات",
    "منصوح",
    "الإحصاء",
    "العد",
    "للعد",
    "التنازلي",
    "الطلعة",
    "يحابي",
    "الطلعات",
    "صد",
    "مصدود",
    "الصد",
    "بتضاد",
    "الموازنة",
    "موازن",
    "يوازن",
    "المعاكس",
    "بعكس",
    "الثقافة",
    "محتج",
    "مكافحة",
    "الجاسوسية",
    "بالدليل",
    "بالأدلة",
    "التزييف",
    "المزور",
    "التزييفات",
    "الإحتجاج",
    "المحل",
    "ملغى",
    "الإجراءات",
    "البائعين",
    "الحركات",
    "مزج",
    "الألحان",
    "نتيجة",
    "بنتائج",
    "سلبية",
    "الإقتراح",
    "كلمة",
    "وسع",
    "التوسيع",
    "يوسع",
    "كونترتوب",
    "الكونتيسة",
    "الكونتيسات",
    "معدود",
    "البلدان",
    "ريفيا",
    "الريف",
    "المواطنات",
    "التهم",
    "الزوج",
    "المقرنة",
    "المقرنات",
    "الأزواج",
    "الإثنان",
    "الإزدواج",
    "القسيمة",
    "الكوبونات",
    "الإنقلابات",
    "العسكرية",
    "الساعي",
    "السعاة",
    "الجواد",
    "السريع",
    "التعقيب",
    "العدل",
    "مراود",
    "مهذب",
    "بأدب",
    "التهذيب",
    "المداعب",
    "المداعبون",
    "المجاملات",
    "دور",
    "المراودة",
    "الاحترام",
    "كورتناي",
    "المحاكم",
    "المغازلة",
    "الفناءات",
    "الكوسكوس",
    "(أكلة",
    "أفريقية)",
    "تساهمي",
    "التغايرات",
    "معاهد",
    "المتفق",
    "المجتمع",
    "تغطيته",
    "مفرش",
    "بسرية",
    "إطلب",
    "مرغوب",
    "مطلوب",
    "كوفيتر",
    "برغبة",
    "الإشتهاء",
    "السرب",
    "كوفينجتون",
    "البقرة",
    "ماسوني",
    "الجبان",
    "جبان",
    "الجبناء",
    "جرس",
    "كاوبيرد",
    "(طائر",
    "الماشية)",
    "سياج",
    "إنكمش",
    "منكمش",
    "ينكمش",
    "راعية",
    "يجلد",
    "بجلد",
    "زريبة",
    "إجبار",
    "القلنسوة",
    "مقلنس",
    "الخصلة",
    "القلنسة",
    "يقلنس",
    "زميل",
    "زملاء",
    "لوبياء",
    "السائس",
    "جدري",
    "الأبقار",
    "زهر",
    "الحقل",
    "الدفة",
    "المغرور",
    "ذئب",
    "البراري",
    "الكيب",
    "(قارض",
    "أمريكي)",
    "براحة",
    "محاسب",
    "موثق",
    "الولاية",
    "تدليك",
    "ﻷعادة",
    "المعالجالتقليب",
    "وحدات",
    "كر",
    "مخربش",
    "بمرارة",
    "تعكر",
    "المزاج",
    "الخربشة",
    "القبقب",
    "يخربش",
    "القمع",
    "متصدع",
    "المفرقع",
    "المفرقعات",
    "التصدع",
    "مطقطق",
    "الطقطقة",
    "مكسر",
    "المجنون",
    "مهزوز",
    "الهز",
    "الحرفة",
    "إحتيالا",
    "باحتيال",
    "الحيلة",
    "الصناعة",
    "مهارة",
    "الصنعة",
    "الحرفيون",
    "محتال",
    "كريج",
    "إحشر",
    "محشور",
    "التحميل",
    "النعل",
    "يحشر",
    "كراندال",
    "الرافعة",
    "مرفوع",
    "الشبت",
    "الرافعات",
    "كرانفورد",
    "كرانيا",
    "الرفع",
    "الساعد",
    "علبة",
    "النزعة",
    "الغريبة",
    "التحريك",
    "المرفق",
    "السواعد",
    "متشقق",
    "كرانستون",
    "الفضلات",
    "الكريب",
    "يخصل",
    "الكرابي",
    "(سمكة)",
    "التحطم",
    "متحطم",
    "المحطم",
    "الضيف",
    "المحطمون",
    "الضيوف",
    "مدعوون",
    "حوادث",
    "التحطيم",
    "ببلادة",
    "إشته",
    "مشتهى",
    "يشتهي",
    "بتشوق",
    "الحوصلة",
    "جراد",
    "كراوفورد",
    "الزحف",
    "الزاحف",
    "الزواحف",
    "يزحف",
    "الصيانة",
    "مقرف",
    "بطيئة",
    "الملون",
    "الهوس",
    "يجنن",
    "بجنون",
    "التجنن",
    "الصرير",
    "مصرور",
    "مدهون",
    "للصفرة",
    "معامل",
    "الألبان",
    "أدوات",
    "القشدة",
    "معمل",
    "دهنا",
    "الدسامة",
    "الطوية",
    "متجعد",
    "كريسير",
    "الطويات",
    "التجعيد",
    "إخلق",
    "مخلوق",
    "اعملعدل",
    "يخلق",
    "الخلق",
    "قصة",
    "المخلوقات",
    "مبدع",
    "خلاق",
    "الإبداع",
    "الخالق",
    "الصناع",
    "المخلوق",
    "بكينونة",
    "الحضانة",
    "الحضانات",
    "المؤهل",
    "الإعتماد",
    "موثوق",
    "جدارة",
    "الإئتمان",
    "الدائن",
    "الدائنون",
    "الإعتمادات",
    "العقيدة",
    "السذاجة",
    "كري",
    "المذهب",
    "عقائدي",
    "المذاهب",
    "كريكسايد",
    "إزحف",
    "زحف",
    "مرعبة",
    "القشعريرة",
    "كريغتن",
    "إحرق",
    "محروق",
    "يحرق",
    "إحراق",
    "حرقي",
    "كرييولي",
    "كريون",
    "الهلال",
    "الأهلة",
    "الرشاد",
    "مكتئب",
    "كريستفيو",
    "طباشيريا",
    "كريتي",
    "كريت",
    "المشوه",
    "المشوهون",
    "الأخدود",
    "الأخاديد",
    "الطاقم",
    "الغزل",
    "الصوفي",
    "طاقم",
    "الطواقم",
    "الكريبج",
    "الكريكت",
    "الكريكيت",
    "منادي",
    "منادو",
    "كريمي",
    "أوكرانية",
    "القرم",
    "الجرائم",
    "المجرم",
    "إجراميا",
    "المجرمون",
    "باحث",
    "باحثون",
    "الإجرام",
    "إعقص",
    "معقوص",
    "المكشكش",
    "العقص",
    "يعقص",
    "كرمبي",
    "القرمزة",
    "تذلل",
    "متذلل",
    "المهرول",
    "يتذلل",
    "التذلل",
    "مجعد",
    "يجعد",
    "التجعد",
    "الكسيح",
    "تستخدم",
    "دباغة",
    "الكسحاء",
    "الشل",
    "السادة",
    "هشاشة",
    "الاسكافي",
    "كريسس",
    "تقاطع",
    "الشبكات",
    "التقاطع",
    "الناقد",
    "الانتقاد",
    "الخطورة",
    "للنقد",
    "إنتقد",
    "الإنتقاد",
    "النقاد",
    "نوقش",
    "يناقش",
    "المناقشة",
    "الناعب",
    "الناعبون",
    "كرواكي",
    "كرواتيا",
    "الكرواتي",
    "الكروشيه",
    "الحياكة",
    "الكروشيهات",
    "مسخم",
    "كروكير",
    "كروكيت",
    "يسخم",
    "التمساحي",
    "الزعفران",
    "كروفت",
    "كروا",
    "كرومويل",
    "العجوز",
    "الشمطاء",
    "العجائز",
    "الشمطاوات",
    "الأزلام",
    "المحتال",
    "أعوج",
    "باعوجاج",
    "المحتالون",
    "دندن",
    "مدندن",
    "الدندنة",
    "يدندن",
    "المحصول",
    "المثمر",
    "القص",
    "الكروكيه",
    "كروسبي",
    "الصليب",
    "عبر",
    "للعبور",
    "القرزبيل",
    "هجن",
    "سلس",
    "(مطبات)",
    "تيارات",
    "سلسة",
    "قطري",
    "منقطع",
    "معبور",
    "الصلبان",
    "ظلل",
    "يظلل",
    "التظليل",
    "العبور",
    "المعابر",
    "متقاطع",
    "الانتقال",
    "التصالبي",
    "تصالبي",
    "المتبادل",
    "معبر",
    "المشاة",
    "المتقاطع",
    "صليب",
    "المتقاطعة",
    "المنشعب",
    "متفرع",
    "أوهامي",
    "التخضع",
    "جاثم",
    "يجثم",
    "الجثم",
    "الخانوق",
    "مائدة",
    "القمار",
    "المحمص",
    "الجيفة",
    "المخل",
    "كروبيري",
    "الازدحام",
    "المجمهر",
    "الإحتشاد",
    "أرجل",
    "كرولي",
    "التاج",
    "المتوج",
    "التيجان",
    "الغربان",
    "إنبوب",
    "(شاشة",
    "التلفزيون)",
    "البودقة",
    "مصلوب",
    "المصلوب",
    "إصلب",
    "خثر",
    "الخام",
    "أغلظ",
    "أقسى",
    "قسوة",
    "الإبريق",
    "الزجاجي",
    "الغبار",
    "المتراكم",
    "البنية",
    "الجولة",
    "منطلق",
    "الطراد",
    "الطرادات",
    "الجولات",
    "الفتات",
    "مفتت",
    "إنهر",
    "ينهار",
    "إنهيارا",
    "متفتت",
    "أردأ",
    "دمدم",
    "الفطيرة",
    "تكوم",
    "يتكوم",
    "التكويم",
    "مطحون",
    "الجرش",
    "تقسبا",
    "كرانتشينيس",
    "الطحن",
    "مقسب",
    "الصليبية",
    "محارب",
    "الصليبي",
    "الصليبيون",
    "يسحق",
    "السحق",
    "ساحق",
    "القشرة",
    "قشري",
    "القشرية",
    "متقشر",
    "أيبس",
    "يابس",
    "التقشير",
    "العكاز",
    "العكازات",
    "بط",
    "كروز",
    "البكاء",
    "كثيرو",
    "طفل",
    "درجات",
    "المتدنية",
    "ثرموستات",
    "تحليل",
    "محلل",
    "بالشفرة",
    "غامض",
    "بغموض",
    "المشفرة",
    "شفروي",
    "البلور",
    "بلوري",
    "معادن",
    "دقيقة",
    "البلورة",
    "بلور",
    "مبلور",
    "يبلور",
    "التبلور",
    "البلورات",
    "البلوريات",
    "سنيت",
    "الشبل",
    "كوبا",
    "الكوبي",
    "الكوبيون",
    "الفجوة",
    "الفجوات",
    "مكعب",
    "المكعبات",
    "تكعيبي",
    "كوبيس",
    "التكعيب",
    "التكعيبية",
    "التعكيبي",
    "الأشبال",
    "الديوث",
    "الديوثون",
    "الوقواق",
    "الخيار",
    "القرع",
    "المجتر",
    "محضون",
    "حبا",
    "بالهراوة",
    "الهراوات",
    "ملقن",
    "الكفة",
    "مكبل",
    "التكبيل",
    "الكفات",
    "التلقين",
    "المطبخ",
    "المطابخ",
    "كولبيرتسون",
    "المنتقي",
    "النفايات",
    "تتوج",
    "متتوج",
    "يتتوج",
    "اهمال",
    "الذنب",
    "المذنبون",
    "إزرع",
    "مزروع",
    "يزرع",
    "المزارعون",
    "ثقافيا",
    "الثقافات",
    "التثقيف",
    "كولفير",
    "كولفيرس",
    "المجاري",
    "معاق",
    "الكمبرلاند",
    "ببطئ",
    "الازعاج",
    "الكمون",
    "راكم",
    "مراكم",
    "يراكم",
    "المراكمة",
    "السحاب",
    "الركام",
    "كونارد",
    "مسماري",
    "اللحس",
    "للمرأة",
    "كونينغهام",
    "بمكر",
    "النسوي",
    "كبيرتينو",
    "الفناجين",
    "كيوبيد",
    "الأطماع",
    "الوغد",
    "الشفاء",
    "للعلاج",
    "كرير",
    "الخوري",
    "الخورية",
    "شافي",
    "الأمين",
    "مسؤوليا",
    "الأمناء",
    "الحراسة",
    "مكبوح",
    "الكبح",
    "الرائب",
    "يخثر",
    "التخثير",
    "الروائب",
    "كيورير",
    "الكوري",
    "التحفة",
    "فضولي",
    "بفضول",
    "الكوريوم",
    "الضفيرة",
    "ضافر",
    "بكرة",
    "بكرو",
    "الكروان",
    "الكورليكو",
    "تجعيدا",
    "الضفر",
    "بخيل",
    "كران",
    "الكشمش",
    "الحالية",
    "حاليا",
    "التيارات",
    "المناهج",
    "منهجي",
    "المنهج",
    "الكاري",
    "كيوريش",
    "بجبن",
    "ملعون",
    "بلعنة",
    "الشر",
    "القاذف",
    "القاذفون",
    "اللعنات",
    "المؤشرة",
    "بعجالة",
    "المؤشرات",
    "كيرت",
    "قلل",
    "ستارة",
    "بالستائر",
    "كيرتيس",
    "باقتضاب",
    "الأدب",
    "إنحنى",
    "إحتراما",
    "إنحناء",
    "الآداب",
    "المنحنى",
    "المنحن",
    "منحن",
    "التقويس",
    "الإسناد",
    "كوشمان",
    "العند",
    "المحلبي",
    "كستر",
    "وصائي",
    "الحامي",
    "الحماة",
    "العادة",
    "الجمرك",
    "الجمارك",
    "التحوير",
    "المفصل",
    "المفصلون",
    "العادات",
    "جلدي",
    "جلديا",
    "الإستقطاع",
    "الإستقطاعات",
    "بلطافة",
    "كتيس",
    "سيف",
    "الملاحين",
    "القاطعة",
    "لوازم",
    "المائدة",
    "لحمة",
    "القواطع",
    "النقلة",
    "جديد)",
    "التخفيضات",
    "القاطع",
    "السفاح",
    "السفاحون",
    "جارح",
    "القطوع",
    "لسان",
    "ألسنة",
    "الحبار",
    "سيان",
    "سياناميد",
    "(حمض)",
    "سيانيت",
    "السيانيد",
    "تخيلي",
    "معرفي",
    "الكمبيوترية",
    "المرتبطة",
    "ببعضها",
    "سيكاد",
    "سيكلاديز",
    "مايكيناين",
    "السيكلامين",
    "الدورة",
    "دوريا",
    "راكبو",
    "سايكلوهيكسانول",
    "كحول",
    "زيتي",
    "يبعث",
    "رائحة",
    "الدائري",
    "الإعصار",
    "الأعاصير",
    "الموسوعة",
    "موسوعي",
    "دائرية",
    "سايكلوتوميك",
    "(نظرية",
    "رياضية)",
    "تحطيم",
    "فرخ",
    "الوز",
    "كوكبة",
    "سيل",
    "الإسطوانة",
    "الإسطوانات",
    "اسطواني",
    "إسطواني",
    "إسطوانيا",
    "النحاسي",
    "المتهكم",
    "متهكم",
    "التهكم",
    "المتهكمون",
    "قبلة",
    "الأنظار",
    "قبلات",
    "سينثيا",
    "السرو",
    "قبرصي",
    "القبرصي",
    "القبارصة",
    "قبرص",
    "سيريل",
    "سيريلي",
    "سيروس",
    "سيستياين",
    "حامض",
    "الخراجات",
    "الخلوية",
    "انحلال",
    "السيتوبلازم",
    "السيتوساين",
    "(حمض",
    "نووي)",
    "تشيكوسلوفاكيا",
    "التشاردس",
    "حفل",
    "مجري",
    "القيصرة",
    "القيصرية",
    "التشيكي",
    "تشيكوسلوفاكي",
    "ان",
    "إخبارية",
    "عالمية",
    "كارلوتا",
    "إمبراطورة",
    "المكسيك",
    "بلجيكية",
    "سلكين",
    "متقاطعين",
    "النص",
    "المشفر",
    "سياحية",
    "شهيرة",
    "كمنتجع",
    "سياحي",
    "بحر",
    "قزوين",
    "الواقع",
    "ايران",
    "وروسيا",
    "ملموس",
    "الامس",
    "اللمس",
    "بلل",
    "مبلل",
    "المحترفون",
    "يبلل",
    "التبليل",
    "اللمسات",
    "داكا",
    "النهري",
    "الألمانية",
    "الداكرون",
    "ألياف",
    "البوليستر",
    "الصناعية",
    "التفعيلة",
    "بالإصبع",
    "إصبع",
    "داكتيلوجرام",
    "التفعيلات",
    "أﻷب",
    "(لغة",
    "اﻷطفال)",
    "فنية",
    "الدادائية",
    "المنتسب",
    "(دادا)",
    "الآباء",
    "بترنح",
    "ددلس",
    "(مخترع)",
    "الشيطان",
    "عته",
    "النرجس",
    "أزهار",
    "دافي",
    "بحمق",
    "جرامات",
    "الخنجر",
    "الخناجر",
    "الدال",
    "(أعشاب",
    "استوائية)",
    "الداليا",
    "داهومي",
    "بلد",
    "ديلي",
    "الصحف",
    "اليومية",
    "يوميا",
    "ديملير",
    "مزرعة",
    "لزرع",
    "محاصيل",
    "العلف",
    "للمواشي",
    "الحلابة",
    "الحلابات",
    "اللبان",
    "اللبانون",
    "الإقحوانات",
    "ديسيويل",
    "داكار",
    "داكوتا",
    "دايل",
    "الأودية",
    "داليث",
    "لألفباء",
    "العبري",
    "دالي",
    "دالاس",
    "المضيع",
    "للوقت",
    "المضيعون",
    "يداعب",
    "داعب",
    "دالتن",
    "دالزيل",
    "الضرر",
    "للضرر",
    "متضرر",
    "الأضرار",
    "ضار",
    "دمشق",
    "الدمقس",
    "(حرير)",
    "السيدة",
    "داميد",
    "السيدات",
    "بسد",
    "الملعون",
    "بإدانة",
    "دامون",
    "الرطوبة",
    "ثبط",
    "الموهن",
    "الموهنات",
    "أرطب",
    "التثبيط",
    "رطب",
    "الفتاة",
    "الفتيات",
    "دان",
    "دانا",
    "دانبري",
    "الراقص",
    "الراقصون",
    "الهندباءات",
    "الزغب",
    "متأنق",
    "الأنيقون",
    "بتأنق",
    "هز",
    "يهز",
    "قشرة",
    "الأنيق",
    "الدنماركي",
    "الدنماركيون",
    "لعينا",
    "خطر",
    "الأخطار",
    "المتدلي",
    "المتدلون",
    "دانجلينجلي",
    "دانيال",
    "دانيلز",
    "دانيلسون",
    "دانماركي",
    "داني",
    "دانتي",
    "دانيوب",
    "بنهر",
    "الدانوب",
    "دانفيل",
    "دانزيج",
    "دافن",
    "مرقط",
    "يرقط",
    "الترقيط",
    "بنات",
    "تجاسر",
    "المخاطر",
    "متجاسر",
    "المتهورون",
    "المخاطرون",
    "يتجاسر",
    "التجاسر",
    "الجراءة",
    "داريوس",
    "(أحد",
    "ملوك",
    "فارس)",
    "الظلام",
    "ظلم",
    "مظلم",
    "المعتم",
    "المعتمون",
    "التظليم",
    "يظلم",
    "أظلم",
    "إختف",
    "المظلمة",
    "الظلمات",
    "موجة",
    "قاتمة",
    "دارلين",
    "العزيز",
    "الأعزاء",
    "دارلينجتون",
    "يا",
    "إلاهي",
    "مرتق",
    "الرياف",
    "الرواف",
    "الترتيق",
    "المرتقون",
    "لمشاريع",
    "البحث",
    "دارل",
    "دارو",
    "محامي",
    "داري",
    "النبلة",
    "مندفع",
    "الإندفاع",
    "دارتموث",
    "النبلات",
    "داروين",
    "الداروني",
    "الدارونية",
    "الوثبة",
    "شرطةنقطةنقطة",
    "شرطةنقطة",
    "الوثبات",
    "الدنئ",
    "الغدر,",
    "دنيئ",
    "دات",
    "خاطبت",
    "حساسة",
    "بنوك",
    "قواعد",
    "حزمة",
    "حزم",
    "التأريخ",
    "مؤرخ",
    "داتيدنيس",
    "اليومي",
    "التواريخ",
    "المجرور",
    "لشركة",
    "نيسان",
    "مجصص",
    "الزنبور",
    "الزنابير",
    "التجصيص",
    "الأطلية",
    "البنت",
    "بناتي",
    "تهيب",
    "متهيب",
    "التهيب",
    "هياب",
    "يتهيب",
    "دلفين",
    "ولي",
    "ديف",
    "دافينبورت",
    "الكنبات",
    "ديفيد",
    "ديفيدسن",
    "ديفي",
    "دافيس",
    "دافينيتش",
    "ديفيس",
    "دافيسون",
    "روافع",
    "الزرع",
    "تسكع",
    "متسكع",
    "المتكسع",
    "يتسكع",
    "التسكع",
    "طالع",
    "الطلوع",
    "غربان",
    "داوسون",
    "اليوم",
    "سرير",
    "حلم",
    "إستغرق",
    "أحلام",
    "اليقضة",
    "الحالم",
    "الحالمون",
    "إستغراق",
    "الأيام",
    "دايتون",
    "دايتونا",
    "المذهولون",
    "الدوخ",
    "إنبهار",
    "البصر",
    "مبهر",
    "المبهر",
    "المبهرون",
    "الديسيبل",
    "ي",
    "ش",
    "شس",
    "يش",
    "ديلاوير",
    "الشماسة",
    "عطل",
    "يعطل",
    "ميت",
    "النهاية",
    "المسدودة",
    "خفت",
    "مخفت",
    "المعطل",
    "التخفيت",
    "بتعطيل",
    "بافساد",
    "يخفت",
    "الخاوية",
    "موتا",
    "أخطر",
    "النهائي",
    "النهائية",
    "الجمود",
    "التجمد",
    "مميت",
    "الأغصان",
    "أصم",
    "أصمم",
    "مصم",
    "الإصمام",
    "الصمم",
    "يصم",
    "الطرش",
    "التجار",
    "التعامل",
    "التعاملات",
    "تعامل",
    "دين",
    "ديان",
    "دينيري",
    "ديانا",
    "العمادة",
    "الغالي",
    "ديربورن",
    "المحبوب",
    "كثيرا",
    "المعزة",
    "الندرة",
    "فراش",
    "أفرشة",
    "المميتة",
    "خالد",
    "بخلود",
    "الخلود",
    "بالموت",
    "الوفيات",
    "الفخ",
    "سوس",
    "الخشب",
    "(خنفساء)",
    "إمنع",
    "إنزل",
    "النزول",
    "نازل",
    "ينزل",
    "المنع",
    "ممنوع",
    "يمنع",
    "خفض",
    "مخفض",
    "يخفض",
    "للنقاش",
    "مناقش",
    "المناقش",
    "المناقشون",
    "الفسق",
    "الفاسق",
    "الفاسقون",
    "ديبي",
    "مضعف",
    "الأوهان",
    "المضعف",
    "منقص",
    "الإنقاص",
    "الديون",
    "ديبلوك",
    "بشوش",
    "الرقة",
    "خفة",
    "ديبورا",
    "ديبرا",
    "إستجوب",
    "مستجوب",
    "الإستجواب",
    "يستجوب",
    "الحطام",
    "المدين",
    "المدينون",
    "المنقح",
    "المنقحات",
    "إفضح",
    "مفضوح",
    "كاشف",
    "الزيف",
    "الفضح",
    "يفضح",
    "ديبوسي",
    "لأول",
    "المبتدأ",
    "ظهور",
    "ديسمبر/كانون",
    "عشري",
    "منحط",
    "بالنظام",
    "الكافيين",
    "كافين",
    "جوانب",
    "المعشر",
    "السطوح",
    "نزع",
    "خلص",
    "لترات",
    "ينقل",
    "العشرم",
    "إرتحل",
    "مرتحل",
    "الإرتحال",
    "الارتحال",
    "يرتحل",
    "مصبوب",
    "الدوارق",
    "الصب",
    "المباراة",
    "العشارية",
    "ديكاتور",
    "ديكا",
    "يموت",
    "الأموات",
    "المخادعون",
    "أبطأ",
    "مبطأ",
    "يبطئ",
    "الإبطاء",
    "الحشم",
    "الحشمة",
    "عشر",
    "سنوات",
    "محترم",
    "إجعل",
    "لامركزيا",
    "سحب",
    "اسحب",
    "أزل",
    "ازالة",
    "الديسيبلات",
    "اتخاذ",
    "مقرر",
    "قرر",
    "يقيني",
    "المقرر",
    "يقرر",
    "نفضي",
    "تساقظ",
    "اﻷوراق",
    "الكسور",
    "العشرية",
    "عشريقيمة",
    "يحطم",
    "للفك",
    "محلول",
    "معالجات",
    "الحل",
    "فك",
    "القرار",
    "بالقرارات",
    "القرارات",
    "الطابق",
    "ديكير",
    "الطوابق",
    "إخطب",
    "مخطوب",
    "الخطيب",
    "المحاضر",
    "الخطباء",
    "الخطابة",
    "الخطب",
    "حماسي",
    "للتصريح",
    "تصريحي",
    "معلن",
    "منشور",
    "إنشر",
    "الإنحرافات",
    "للرفض",
    "الهبوط",
    "هابط",
    "النازل",
    "النازلة",
    "قبضة",
    "معلوماتية",
    "(شركة",
    "ديجيتال)",
    "ترجم",
    "يترجم",
    "الترجمة",
    "الترجمات",
    "عنق",
    "لثوب",
    "أنه",
    "للتحلل",
    "متفسخ",
    "تفسخ",
    "يتفسخ",
    "التفسخ",
    "تخفيف",
    "التحرير",
    "تزييني",
    "النقاشون",
    "اللياقة",
    "الديكورات",
    "انزع",
    "لحاء",
    "ينزع",
    "مفصول",
    "الأفخاخ",
    "النقصان",
    "التناقصات",
    "متناقص",
    "المرسوم",
    "ديكرير",
    "التناقص",
    "تناقصي",
    "هرم",
    "التهالك",
    "منتقص",
    "ينتقص",
    "إنتقص",
    "الإنتقاص",
    "يفك",
    "ضاعف",
    "أضعاف",
    "يضاعف",
    "مرات",
    "المضاعفة",
    "كرس",
    "إهدائي",
    "للاستنتاج",
    "إخصم",
    "مخصوم",
    "الاقتطاع",
    "إنقاصهم",
    "الخصومات",
    "إستنتاجي",
    "إستنتاجيا",
    "يخصم",
    "(ترك",
    "ارث)",
    "يعتقد",
    "العمق",
    "المجمدة",
    "معمق",
    "التعميق",
    "يعمق",
    "أعمق",
    "بعمق",
    "الأيل",
    "دير",
    "مخترع",
    "الأيائل",
    "جلود",
    "صياد",
    "يشوه",
    "الإختلاس",
    "الإختلاسات",
    "التشهير",
    "التشهيرات",
    "إطعن",
    "السمعة",
    "مشوهين",
    "يطعن",
    "التقصير",
    "تخلف",
    "يهمل",
    "المقصرون",
    "التقصيرات",
    "اﻷطاحة",
    "الإنهزامية",
    "متخاذل",
    "الإنهزاميون",
    "الهزائم",
    "مصفى",
    "يصفي",
    "التصفية",
    "التغوط",
    "الإرتداد",
    "الإرتدادات",
    "معيوب",
    "الدفاعات",
    "حصين",
    "يدافع",
    "يقذف",
    "قذف",
    "الدفاعي",
    "مبرر",
    "تبجيلي",
    "الأجل",
    "مرجأ",
    "متحدي",
    "بتحد",
    "النقائص",
    "بنقص",
    "العجز",
    "يتحدى",
    "المضيق",
    "الجبلي",
    "مدنس",
    "الملوثون",
    "المضائق",
    "الجبلية",
    "التدنيس",
    "للتعريف",
    "عرف",
    "المحدد",
    "المحددون",
    "يعرف",
    "التعريف",
    "تعريفي",
    "التعاريف",
    "إحترق",
    "فرغ",
    "مفرغ",
    "المفرغ",
    "يفرغ",
    "التفريغ",
    "إنكماشي",
    "إنحرف",
    "للانعكاس",
    "منحرف",
    "الإمالات",
    "للانحراف",
    "العاكس",
    "إفتض",
    "مفتض",
    "الإفتضاض",
    "يفتض",
    "واضحة",
    "ديفو",
    "المسقط",
    "المسقطات",
    "الإسقاطات",
    "للاسقاط",
    "تشويهي",
    "العاهات",
    "العاهة",
    "التجزئة",
    "السارق",
    "السارقون",
    "إدفع",
    "للدفع",
    "جرد",
    "مزيل",
    "الصقيع",
    "مزيلو",
    "حاذق",
    "الحذاقة",
    "تحد",
    "بتحدي",
    "شهادة",
    "دبلوم",
    "أفرغ",
    "الغاز",
    "إفراغ",
    "مغناطيسية",
    "منحل",
    "متحلل",
    "الانحلال",
    "المنحطون",
    "إنحلالي",
    "المهانة",
    "أذلل",
    "بتدهور",
    "السفالة",
    "المدهور",
    "مهين",
    "ديجوم",
    "حيون",
    "محيون",
    "يحيون",
    "الحيونة",
    "التجفيف",
    "جفف",
    "مزيلات",
    "يجفف",
    "مجفف",
    "ذوب",
    "مذوب",
    "التذويب",
    "مؤله",
    "يؤله",
    "أله",
    "التأليه",
    "تكرم",
    "متكرم",
    "التكريم",
    "يتكرم",
    "الربوبية",
    "الربوبي",
    "بايمان",
    "الربوبيون",
    "شوهد",
    "الإعتقاد",
    "وضعا",
    "جديدا",
    "قد",
    "حدث",
    "محزن",
    "باكتئاب",
    "الكابة",
    "الإكتئاب",
    "ديكالب",
    "حصة",
    "ديلاني",
    "مسرحي",
    "ديلانو",
    "ديلوار",
    "المؤخر",
    "المؤخرون",
    "التأخيرات",
    "ممتع",
    "لذيذة",
    "أبهج",
    "للتفويض",
    "الانتداب",
    "المندوب",
    "موفد",
    "المندوبون",
    "الإيفاد",
    "الوفد",
    "الوفود",
    "إحذف",
    "محذوف",
    "مؤذي",
    "يحذف",
    "الحذوفات",
    "دلهي",
    "داليا",
    "متعمد",
    "بتعمد",
    "العمد",
    "الدراسة",
    "المشاورات",
    "تداولي",
    "النقاشية",
    "حساس",
    "الرقيقة",
    "المستحضرة",
    "الجرم",
    "البهجة",
    "مسرور",
    "الباعث",
    "بالسرور",
    "المسرات",
    "ديليلا",
    "موصوف",
    "الجنوح",
    "الجانح",
    "بتقصير",
    "الجانحون",
    "هاذي",
    "لحد",
    "محلات",
    "الوجبات",
    "الجاهزة",
    "سلم",
    "للتسليم",
    "النجاة",
    "مسلم",
    "المسلم",
    "المسلمون",
    "التسليم",
    "يسلم",
    "موصلو",
    "ديل",
    "ديلا",
    "ديلوود",
    "ديلمارفا",
    "فل",
    "مفلى",
    "يفلي",
    "التفلية",
    "احدف",
    "الصفحة",
    "ديلفي",
    "بديلفي",
    "بالدرفيل",
    "الدلفينيون",
    "الدلفين",
    "الدلتا",
    "عضلة",
    "دالية",
    "ضلل",
    "التضليل",
    "خادعة",
    "للعقل",
    "الطوفان",
    "مغرق",
    "إحفر",
    "المغناطيسية",
    "مزال",
    "ديماجنيتيزينت",
    "ديماجوجية",
    "الديماغوجي",
    "الديماغوجية",
    "الديماغوجيون",
    "المطلب",
    "الطالبون",
    "المطالب",
    "يخطط",
    "التخطيطات",
    "الخرف",
    "الدامجانة",
    "إنزع",
    "منزوع",
    "مشبوه",
    "التنقية",
    "التنقيات",
    "الرحيل",
    "انتهى",
    "ثلاثيات",
    "إستقل",
    "استقال",
    "الاستقالة",
    "العينة",
    "التسريح",
    "سرح",
    "مسرح",
    "يسرح",
    "الديمقراطيات",
    "الديمقراطية",
    "الديموقراطي",
    "الديمقراطي",
    "ديموقراطيا",
    "الدمقرطة",
    "دمقرط",
    "مدمقرط",
    "بالديموقراطية",
    "يدمقرط",
    "الدمقرط",
    "الديمقراطيون",
    "الإستخلاص",
    "المستخلص",
    "المستخلصات",
    "سكاني",
    "ديموجرافي",
    "سكانيا",
    "السكانية",
    "مهدم",
    "التهديم",
    "التدمير",
    "يدمر",
    "الشيطانة",
    "شيطاني",
    "شيطانية",
    "سمعة",
    "مكروه",
    "تشويه",
    "الإثباتية",
    "للاثبات",
    "للعرض",
    "تظاهر",
    "متظاهر",
    "يتظاهر",
    "التظاهر",
    "المظاهرة",
    "المظاهرات",
    "برهاني",
    "المتظاهر",
    "المتظاهرون",
    "إضعاف",
    "المعنويات",
    "محبط",
    "مثبط",
    "الهمم",
    "مثبطو",
    "يحبط",
    "الإحباط",
    "للإحباط",
    "ديمورجان",
    "العينات",
    "نزل",
    "منزل",
    "التنزيل",
    "التنزيلات",
    "ديمونت",
    "ديمبسي",
    "ملاكم",
    "والذي",
    "كان",
    "بطل",
    "للوزن",
    "الثقيل",
    "رزين",
    "معترض",
    "الإحتجاجات",
    "التبسيط",
    "الأسطورة",
    "تجرد",
    "المتجرد",
    "العرين",
    "خصخص",
    "مخصخص",
    "يخصخص",
    "الخصخصة",
    "بدل",
    "مبدل",
    "يبدل",
    "تبديل",
    "الزوائد",
    "المتشجرة",
    "شجيري",
    "دينيب",
    "دينيبولا",
    "دينين",
    "منكور",
    "التكذيبات",
    "منكر",
    "الناكر",
    "الناكرون",
    "الإستصغار",
    "مضر",
    "الدينيم",
    "دنيس",
    "الدنمارك",
    "يسمي",
    "التسمية",
    "طائفي",
    "مقام",
    "مقامات",
    "دال",
    "الدلالات",
    "دلالي",
    "مدلول",
    "الخواتم",
    "إشجب",
    "مشجوب",
    "الشجب",
    "الشاجب",
    "الشاجبون",
    "يشجب",
    "العرائن",
    "الكثافات",
    "لشدة",
    "الطعجة",
    "بالأسنان",
    "النطعية",
    "معجون",
    "معاجين",
    "أطباء",
    "دينتون",
    "الطعجات",
    "طقم",
    "أطقم",
    "التعرية",
    "عر",
    "يعري",
    "احصاءه",
    "عده",
    "تهديدي",
    "شجبي",
    "دينفير",
    "أنكر",
    "بنكران",
    "الروائح",
    "تلطيف",
    "الرائحة",
    "نمط",
    "ديوكسيريبونكلييك",
    "ديوكسيريبوز",
    "غادر",
    "مغادر",
    "المغادرة",
    "ديباتشر",
    "إعتمد",
    "الموثوقية",
    "التبعية",
    "الأتباع",
    "التبعيات",
    "إسلب",
    "شخصية",
    "مسلوب",
    "يسلب",
    "سلب",
    "مصور",
    "يصور",
    "إسمط",
    "مسموط",
    "يسمط",
    "السماط",
    "إستنفذ",
    "مستنفذ",
    "يستنفذ",
    "الإستنفاذ",
    "النضوب",
    "إستنزافي",
    "الأسى",
    "بشعة",
    "إستهجن",
    "مستهجن",
    "الحزين",
    "يستهجن",
    "الإستهجان",
    "إنتشر",
    "للانتشار",
    "الإنتشار",
    "ينتشر",
    "الإستقطاب",
    "إستقطاب",
    "أخل",
    "مخلى",
    "يخلي",
    "إخلاء",
    "الإخلاء",
    "المبعدون",
    "إخلع",
    "مخلوع",
    "يخلع",
    "الخلع",
    "المستودع",
    "المقدم",
    "التوديع",
    "المودع",
    "المودعون",
    "الإيداعات",
    "المستودعات",
    "الفاسد",
    "إستنكر",
    "مستنكر",
    "يستنكر",
    "الإستنكار",
    "باستهجان",
    "المستنكرون",
    "إستهجاني",
    "للتخفيض",
    "بتناقص",
    "التلف",
    "التلفيات",
    "اسلب",
    "النهب",
    "النهاب",
    "السللاب",
    "ناهب)",
    "إضغط",
    "يضغط",
    "بكآبة",
    "المثبط",
    "المثبطون",
    "إحرم",
    "محروم",
    "الأعماق",
    "أوفد",
    "يوفد",
    "إنتدب",
    "منتدب",
    "ينتدب",
    "الإنتداب",
    "إخرج",
    "السكة",
    "خرج",
    "خروج",
    "يخرج",
    "درات",
    "دربيس",
    "الدربي",
    "ديربيشاير",
    "مقاطعة",
    "إنجلترا",
    "لمحتويات",
    "(برمجيات)",
    "الواصل",
    "الواصلون",
    "رقابة",
    "ديريك",
    "المهجور",
    "إسخر",
    "ساخر",
    "الساخر",
    "الساخرون",
    "يسخر",
    "بسخرية",
    "للإشتقاق",
    "انشأ",
    "يشتق",
    "الاشتقاق",
    "الإشتقاقات",
    "الاشتقاقية",
    "إشتق",
    "المشتق",
    "بطب",
    "الجلدية",
    "الأدمة",
    "إنتقاصي",
    "المرفعة",
    "الدرويش",
    "الدراويش",
    "ديز",
    "يحلي",
    "التحلية",
    "التشبع",
    "دزك",
    "ديكارت",
    "فيلسوف",
    "السليل",
    "الأحفاد",
    "السليلون",
    "الأسلة",
    "موروث",
    "ينحدر",
    "الواصف",
    "الواصفون",
    "يصف",
    "ملموح",
    "الأوصاف",
    "الموصف",
    "إلمح",
    "اللمح",
    "دنس",
    "المنتهك",
    "يدنس",
    "إدمج",
    "مدموج",
    "يدمج",
    "العنصري",
    "إرفض",
    "مرفوض",
    "حساسية",
    "تقليل",
    "الصحراء",
    "الهاربون",
    "الصحاري",
    "إستحق",
    "بجدارة",
    "الاستحقاق",
    "المستحق",
    "الإستحقاق",
    "يفقد",
    "المجفف",
    "الأمنيات",
    "الأمنية",
    "التصميم",
    "تصميمه",
    "مرشح",
    "التعيينات",
    "ترشيحي",
    "عمدا",
    "المصمم",
    "المصممون",
    "التصاميم",
    "الوجهة",
    "دزيرير",
    "الرغبات",
    "تواق",
    "كف",
    "مكفوف",
    "الكف",
    "يكف",
    "ديزموند",
    "مقفر",
    "يقفر",
    "الإقفار",
    "الخراب",
    "مكثفة",
    "السطح",
    "الغازية",
    "السائلة",
    "اليأس",
    "ميئوس",
    "اليائس",
    "اليئس",
    "الإرسالية",
    "مبعوث",
    "الإرساليات",
    "البعث",
    "الشرير",
    "الشريرون",
    "مستميت",
    "للغاية",
    "بحقارة",
    "إحتقر",
    "المحتقر",
    "يحتقر",
    "الرغم",
    "الأحقاد",
    "السلب",
    "بيأس",
    "ييئس",
    "إستبدادي",
    "بديكتاتورية",
    "الطغاة",
    "زعزع",
    "مزعزع",
    "يزعزع",
    "الزعزعة",
    "الإتجاه",
    "الإتجاهات",
    "الأقدار",
    "معدم",
    "مفتقر",
    "عائل",
    "فقير",
    "الفاقة",
    "محطم",
    "المدمرة",
    "المدمرات",
    "دمر",
    "للتدمير",
    "الدمار",
    "تدميري",
    "هدام",
    "التفكك",
    "إفصل",
    "للفصل",
    "الانحياز",
    "الإنفصال",
    "المفارز",
    "التفاصيل",
    "إحجز",
    "محجوز",
    "المحجوز",
    "المحجوزون",
    "يحجز",
    "إكتشف",
    "الاكتشاف",
    "للكشف",
    "مكتشف",
    "الإكتشاف",
    "الكشوفات",
    "المخبرون",
    "الكاشف",
    "الكاشفات",
    "يكتشف",
    "الماسكة",
    "السلام",
    "إردع",
    "تدهور",
    "متدهور",
    "يتدهور",
    "التدهور",
    "تدهوري",
    "التصميمية",
    "الردع",
    "الاصرار",
    "المقررون",
    "بحسم",
    "تحديدي",
    "بتصميم",
    "مردوع",
    "الرادع",
    "رادع",
    "الروادع",
    "يردع",
    "إمقت",
    "بمقت",
    "للانفجار",
    "فجر",
    "مفجر",
    "يفجر",
    "التفجير",
    "المتفجر",
    "المتفجرات",
    "انفجاري",
    "المفجرات",
    "الإنعطاف",
    "انعطف",
    "الانعطاف",
    "الإنعطافات",
    "أنقص",
    "الذام",
    "الذامون",
    "ينقص",
    "ثقف",
    "يثقف",
    "حتاتي",
    "البقايا",
    "ديترويت",
    "الإلة",
    "الديوتريوم",
    "الديوترون",
    "نواة",
    "ألماني",
    "قيمة",
    "تخفيض",
    "القيمة",
    "خرابي",
    "المدمرون",
    "طور",
    "متطور",
    "يطور",
    "التطوير",
    "التطويرات",
    "المطور",
    "المطورون",
    "تطويري",
    "تنمويا",
    "التطورات",
    "مستقلة",
    "معينة",
    "الشيطانية",
    "الشيطاني",
    "الشرور",
    "للقسمة",
    "على..",
    "مبتكر",
    "المورث",
    "المخترع",
    "الإبتكار",
    "تحريك",
    "اﻷحبال",
    "خالي",
    "منقول",
    "ديفون",
    "ديفونشاير",
    "غرب",
    "بولاء",
    "المحب",
    "المحبون",
    "عبادي",
    "إلتهم",
    "ملتهم",
    "المفترس",
    "المفترسون",
    "الإلتهام",
    "يلتهم",
    "بإيمان",
    "(ترمس",
    "الهواء)",
    "قطرة",
    "قطر",
    "مرطب",
    "بالندى",
    "ديوي",
    "ندي",
    "النداوة",
    "يرطب",
    "ديويت",
    "ديكسيدرين",
    "أيمن",
    "ديكستروسنيس",
    "سكر",
    "ظبي",
    "دارما",
    "المصابون",
    "شيطانيا",
    "ديابوليكالنيس",
    "ديابوليكالنيسيس",
    "بالزمن",
    "اللاتينية",
    "للتشخيص",
    "مشخص",
    "يشخص",
    "التشخيص",
    "تشخيصي",
    "تشخيصيا",
    "تشخيص",
    "القطر",
    "جدولته",
    "قطريا",
    "الأقطار",
    "بيانيا",
    "البياني",
    "رسمه",
    "الهاتفي",
    "اللهجة",
    "لهجوي",
    "جدلي",
    "الجدلي",
    "الجدليون",
    "اللهجات",
    "متلفن",
    "المسجل",
    "المسجلون",
    "التلفنة",
    "الحوار",
    "الحوارات",
    "التحاور",
    "الأوجه",
    "مفرز",
    "ضعيف",
    "النفاذية",
    "الماس",
    "دايانا",
    "حفاظة",
    "ديابيريد",
    "ديابيرينج",
    "حفاظات",
    "الشفافية",
    "الحجاب",
    "الحجابات",
    "الحاجزة",
    "المفكرات",
    "اليوميات",
    "الإسهال",
    "بالإسهال",
    "الشتات",
    "اليهودي",
    "البابلي",
    "الإنبساط",
    "الاستحرار",
    "الاستهداف",
    "الدياتوم",
    "يزخر",
    "بالدياتوم",
    "دياتومس",
    "موسيقي",
    "الهجاء",
    "الديازونيوم",
    "إغرز",
    "النرد",
    "المقامر",
    "النردات",
    "ديتش",
    "الديكلوريد",
    "ديكوندرا",
    "الإنقسامات",
    "إنقسم",
    "الإنقسام",
    "التفرع",
    "بتفرع",
    "شبهة",
    "بوليس",
    "لفضة",
    "عامية",
    "لقضيب",
    "مذكر",
    "الدرسة",
    "الأميركية",
    "يساوم",
    "ديكيرسون",
    "دكي",
    "ديكنسن",
    "ديكس",
    "ديكسون",
    "العصفور",
    "الفلقتين",
    "المأثورة",
    "الدكتافون",
    "الدكتافونات",
    "مفروض",
    "الإملاء",
    "الدكتاتور",
    "دكتاتوري",
    "الدكتاتورية",
    "الدكتاتوريون",
    "الدكتاتوريات",
    "الإلقاء",
    "القواميس",
    "القاموس",
    "المأثور",
    "تعليمي",
    "بالتعليمي",
    "تعليميا",
    "الحماقات",
    "مت",
    "ديبولد",
    "مات",
    "ديجو",
    "العنيد",
    "الأشداء",
    "الديلدرين",
    "عازل",
    "عوازل",
    "الكهرباء",
    "ديم",
    "النقطتين",
    "ديرز",
    "الديزل",
    "ديسين",
    "الحمية",
    "ريجيمي",
    "اتبع",
    "الحميات",
    "أخصائيو",
    "غذائيا",
    "التغذية",
    "ديثيلستيلبيسترول",
    "استروجبن",
    "أخصائي",
    "اتباع",
    "ديتريش",
    "ممثلة",
    "ديتز",
    "معرفة",
    "الإختلاف",
    "إختلف",
    "مختلف",
    "باين",
    "الإختلافات",
    "يفرق",
    "الاختلاف",
    "للاختلاف",
    "تفاضلي",
    "التفاضلات",
    "المفرق",
    "المفرقون",
    "الاختلافات",
    "الصعوبات",
    "إنحرافي",
    "ديفراكتوميتر",
    "(جهاز",
    "مخبري)",
    "(أجهزة",
    "مخبرية)",
    "مستفيض",
    "بانتشار",
    "الناشر",
    "الناشرون",
    "الإنتشارية",
    "إنتشاري",
    "انتشاري",
    "منتشر",
    "موجات",
    "بالتساوي",
    "مهضوم",
    "الهضم",
    "الهضمي",
    "هضمي",
    "الملخصات",
    "ديجيد",
    "الحفريات",
    "الديجيتال",
    "الإرجواني",
    "لاضطرابات",
    "علاجات",
    "يرقم",
    "إلكترونيا",
    "أصابع",
    "الترقيم",
    "محولات",
    "تحويل",
    "وقور",
    "يبجل",
    "بجل",
    "التبجيل",
    "الوجهاء",
    "الوجيه",
    "الكرامات",
    "الكرامة",
    "حرفين",
    "متتاليين",
    "محاد",
    "يحود",
    "الحيد",
    "الإستطراد",
    "إستطرادي",
    "الاستطراد",
    "ديكسترا",
    "ديجون",
    "الخندق",
    "اللفض",
    "السحاقية",
    "الخنادق",
    "خرب",
    "مخرب",
    "يخرب",
    "التوسع",
    "توسع",
    "الإتساع",
    "يتوسع",
    "التوسعات",
    "الموسع",
    "بتلكئ",
    "التوسعة",
    "متلكئ",
    "المعضلة",
    "المعضلات",
    "هاوي",
    "الفني",
    "بجد",
    "ديلينجر",
    "عصابة",
    "ديلون",
    "طوفاني",
    "خافت",
    "ديماجيو",
    "الدايم",
    "بعدي",
    "أبعاد",
    "الأبعاد",
    "مكونة",
    "متشابهة",
    "الدايمات",
    "ديميثيلجليوكسايم",
    "(مركب",
    "كيميائي)",
    "للتناقص",
    "بخفوت",
    "تدريجي",
    "ضئيل",
    "تصغيرا",
    "الصغر",
    "الضئيلون",
    "مخفوت",
    "المخفت",
    "المخفتات",
    "خفوت",
    "الخفت",
    "الخفوت",
    "الجزئي",
    "مدمل",
    "التنقير",
    "دمامل",
    "الغباء",
    "دينا",
    "ابنة",
    "\"يعقوب\"",
    "\"ليه\"",
    "تعش",
    "متعشى",
    "المتعشي",
    "المتعشون",
    "يتعشى",
    "إقرع",
    "أقذر",
    "بقذارة",
    "القذارة",
    "المشجر",
    "المشجرة",
    "الدنغو",
    "التعشي",
    "الأصغر",
    "مضجوج",
    "العشاء",
    "بدلة",
    "وجبات",
    "الديناصورات",
    "أبرشي",
    "الأبرشية",
    "ديود",
    "الثنائيات",
    "دايوجينس",
    "يوناني",
    "ديون",
    "ديونيسي",
    "بالراهب",
    "\"ديونيسيس\"",
    "(راهب",
    "السادس)",
    "ديونيسيس",
    "راهب",
    "ديوفانتاين",
    "استاذ",
    "الديوبتر",
    "والمستوى",
    "الديوريت",
    "الإنخفاض",
    "لحيم",
    "الديفتريا",
    "ديفتيري",
    "خناقي",
    "الإدغام",
    "الإدغامات",
    "مضاعف",
    "بتضاعف",
    "الدبلوم",
    "الدبلوماسية",
    "الدبلومات",
    "الدبلوماسي",
    "موثقون",
    "دبلوماسي",
    "دبلوماسيا",
    "والمخطوطات",
    "الدبلوماسيون",
    "بوحدة",
    "\"ديبودي\"",
    "ديبودي",
    "(وحدة",
    "قياس)",
    "الاقطاب",
    "منخفض",
    "الغطاس",
    "الغطاسون",
    "الغمس",
    "الانغماسات",
    "الإنخفاضات",
    "اختصار",
    "\"اتجاه\"",
    "ديراك",
    "فيزيائي",
    "مباشر",
    "إتجاهي",
    "التوجيهات",
    "المديرية",
    "مديري",
    "المدراء",
    "دليلملف",
    "المخرجات",
    "المخرجة",
    "اللحن",
    "الحزينة",
    "ديريتشليت",
    "ديرك",
    "الوسخ",
    "موسخ",
    "أوسخ",
    "يوسخ",
    "الوساخة",
    "الأوساخ",
    "التوسيخ",
    "يعجز",
    "الأهلية",
    "تعطيل",
    "معوق",
    "عاجز",
    "التعجيز",
    "ديساكهارايد",
    "(سكريات)",
    "الإضرار",
    "بسخط",
    "إنفصل",
    "منفصل",
    "ينفصل",
    "الإنتساب",
    "غموض",
    "أزيل",
    "مختفى",
    "خب",
    "أمل",
    "خائب",
    "الأمل",
    "مخيب",
    "للآمال",
    "خيبة",
    "الإحباطات",
    "يخيب",
    "الإستنكارات",
    "باستنكار",
    "المجرد",
    "المجردون",
    "فادح",
    "المصيبة",
    "أقص",
    "يقصي",
    "الجاحد",
    "الكفرة",
    "الجحود",
    "أرح",
    "مراح",
    "يريح",
    "أنفق",
    "منفق",
    "المنفق",
    "ينفق",
    "إنبذ",
    "منبوذ",
    "المتخلص",
    "معروف",
    "للإدراك",
    "بصير",
    "الفطن",
    "الرؤى",
    "الإطلاق",
    "الإطلاقات",
    "التلمذة",
    "الصارم",
    "الصارمون",
    "تأديبي",
    "الإنضباط",
    "منضبط",
    "المجالات",
    "التنصل",
    "إكشف",
    "الديسكو",
    "رامي",
    "ديسكويد",
    "تغيير",
    "أزعج",
    "يدحر",
    "الارتياح",
    "المضايقات",
    "مضايق",
    "يزعج",
    "مربك",
    "محرجة",
    "تعيس",
    "التعارض",
    "مخالف",
    "الدسكو",
    "للخصم",
    "عزيمة",
    "المحبط",
    "يثبط",
    "تثبيط",
    "باحباط",
    "للإكتشاف",
    "المكتشف",
    "المكتشفون",
    "الإكتشافات",
    "معيب",
    "التكذيب",
    "التحفظ",
    "بتحفظ",
    "إختياري",
    "للتفضيل",
    "إستطراديا",
    "التنقل",
    "ناقش",
    "المناقشات",
    "الإستنكاف",
    "مزدرى",
    "الإزدراء",
    "الترجل",
    "إبقر",
    "مبقور",
    "يبقر",
    "الظن",
    "خيب",
    "الإراحة",
    "تحرير",
    "حرمان",
    "منعزل",
    "فاك",
    "الاشتباك",
    "إختلال",
    "إعزل",
    "الإستياء",
    "المستاء",
    "المستائون",
    "تقيأ",
    "متقيأ",
    "يتقيأ",
    "الخزي",
    "مخزى",
    "للعار",
    "التخزية",
    "أسخط",
    "يسخط",
    "الإسخاط",
    "التنكر",
    "متنكر",
    "تنكري",
    "المتنكر",
    "التنكرات",
    "باشمئزاز",
    "القرف",
    "الصحن",
    "التنافر",
    "الليفة",
    "إخذل",
    "مخذول",
    "الخذل",
    "يخذل",
    "الصحون",
    "شعث",
    "مشعث",
    "يشعث",
    "غشاش",
    "أمانة",
    "العار",
    "شريف",
    "المخزي",
    "مشرفون",
    "الإخزاء",
    "يخزي",
    "بشرف",
    "الفوطة",
    "الفوط",
    "غسالة",
    "غسالات",
    "الخذلان",
    "الخيبة",
    "الصحوة",
    "العقبة",
    "العقبات",
    "النفور",
    "نفر",
    "منفر",
    "ينفر",
    "التنفير",
    "تحلل",
    "يتحلل",
    "تكاملي",
    "إنبش",
    "النبش",
    "مدفون",
    "ينبش",
    "المفارق",
    "فاصل",
    "الإزاحة",
    "أزح",
    "مزاح",
    "يزيح",
    "خائن",
    "الإفزاع",
    "إطرد",
    "مطرود",
    "رافض",
    "ترجل",
    "مترجل",
    "يترجل",
    "ديزني",
    "ديسنيلاند",
    "العصيان",
    "عاصي",
    "إعص",
    "معصي",
    "العاصي",
    "يعصي",
    "ملخبط",
    "بفوضوية",
    "اللخبطة",
    "تملك",
    "إستسخف",
    "مستسخف",
    "المستهزئ",
    "يستسخف",
    "الإستسخاف",
    "إستخفافا",
    "التفاوتات",
    "محايد",
    "المرسلون",
    "بدد",
    "التبديد",
    "يبدد",
    "الصيدليات",
    "الصيدلية",
    "التفريق",
    "مفرق",
    "بتشتت",
    "للنشر",
    "التفرق",
    "تفريقي",
    "يئس",
    "متشائم",
    "بتشائم",
    "مرحل",
    "تنسيب",
    "للعب",
    "معروض",
    "إستاء",
    "مستاء",
    "بعدم",
    "رضى",
    "يستاء",
    "باستياء",
    "تسل",
    "متسلى",
    "التسلي",
    "يتسلى",
    "التخلص",
    "ترتيبي",
    "يلوم",
    "النقض",
    "التفاوت",
    "متكافيء",
    "عنصرين",
    "للبرهان",
    "مفند",
    "للنزاع",
    "اثارة",
    "المنازع",
    "المنازعون",
    "مسكت",
    "الإسكات",
    "بانزعاج",
    "متجاهل",
    "التجاهل",
    "الإهمالات",
    "مخز",
    "إجتث",
    "مجتث",
    "الإجتثاث",
    "يجتث",
    "ديسروبتر",
    "العراقيل",
    "مشرح",
    "القطاع",
    "ديسسيمبلير",
    "معارض",
    "المنشق",
    "المنشقون",
    "المعارضات",
    "الإطروحة",
    "الإطروحات",
    "المتفرق",
    "الإنقشاع",
    "للتفرق",
    "إجتماعي",
    "فصامي",
    "ذائب",
    "الذائب",
    "إنصح",
    "الإستنزاع",
    "مقطعي",
    "بكرات",
    "المسافة",
    "رداءه",
    "الإنتفاخ",
    "الإنتفاخات",
    "بندان",
    "لنفس",
    "النوع",
    "المقطرة",
    "التقطير",
    "مقطر",
    "المقطر",
    "يقطر",
    "الفروق",
    "ديستوما",
    "(دودة)",
    "للتشويه",
    "التحريف",
    "يحرف",
    "إصرف",
    "إنتباهه",
    "بتحير",
    "للتشتت",
    "إلهائي",
    "للحجز",
    "المتدرب",
    "المتدربون",
    "الحزن",
    "للتوزيع",
    "الموزع",
    "توزيعي",
    "التسويقية",
    "الموزعون",
    "البيع",
    "بالتجزأة",
    "ديستروي",
    "الإرتياب",
    "مرتاب",
    "بارتياب",
    "بريبة",
    "المزعج",
    "ذرتين",
    "الإنشقاق",
    "مستعمل",
    "المقطعين",
    "متخندق",
    "التخندق",
    "الإرتباك",
    "نفسها",
    "الإنشودة",
    "ديزيل",
    "مدرر",
    "نهاري",
    "المغنية",
    "المغنيات",
    "الغطس",
    "مغيص",
    "الغواص",
    "تباعد",
    "متباعد",
    "يتباعد",
    "التباعد",
    "الغواصون",
    "متنوع",
    "بتنوع",
    "تضليلي",
    "المقسم",
    "المقسمات",
    "التكهن",
    "القس",
    "متكهن",
    "إلهيا",
    "التميز",
    "قدسي",
    "الغوص",
    "الإلهيات",
    "اللاهوت",
    "تقسيمي",
    "خلافي",
    "الشقاق",
    "المقسوم",
    "المقسومات",
    "الطلاق",
    "المطلق",
    "المطلقون",
    "الطلاقات",
    "الإفشاء",
    "أبح",
    "مباح",
    "الإباحة",
    "يبيح",
    "عوائد",
    "الأسهم",
    "ديكسيكراتس",
    "(حزب",
    "ديكسيلاند",
    "ال11",
    "انسحبت",
    "1861",
    "ديكسن",
    "بالدوار",
    "دوخة",
    "الدوار",
    "بدوخة",
    "الدوخة",
    "جيبوتي",
    "تنزيل",
    "ديامأيه",
    "ديميتري",
    "الدي.إن.إيه",
    "(الحامض",
    "النووي)",
    "دنيبير",
    "دياناس",
    "بيان",
    "دوب",
    "دوبين",
    "دوبس",
    "دوبيرمان",
    "دوبسون",
    "الدكتور",
    "السلاسة",
    "السفن",
    "للإرساء",
    "مرسى",
    "الميناء",
    "القوائم",
    "الرصيف",
    "المسفن",
    "دوس",
    "دكتوراه",
    "الدكتوراه",
    "العقائدي",
    "مذهبي",
    "الوثيقة",
    "للتوثيق",
    "وثائقي",
    "الوثائقيات",
    "الوثائقي",
    "الموثقين",
    "وزارة",
    "دود",
    "إرتعش",
    "مرتعش",
    "الإرتعاش",
    "يرتعش",
    "الثنعشري",
    "الأضلاع",
    "ثنعشري",
    "المراوغ",
    "المراوغون",
    "دودنجتون",
    "الدودو",
    "دودسون",
    "الظبية",
    "الفاعل",
    "الفاعلون",
    "سامة",
    "توت",
    "القضاة",
    "الإصرار",
    "باصرار",
    "الركيك",
    "وضيع",
    "الوضاعة",
    "المنعطف",
    "العقائد",
    "دوغماتي",
    "المذهبية",
    "الدوغماتية",
    "الدوغماتي",
    "الهرولة",
    "دورتي",
    "دولان",
    "بحلاوة",
    "الكساد",
    "بكئابة",
    "الكئآبة",
    "الدمية",
    "الدمى",
    "الكمشة",
    "دولي",
    "الدولوميت",
    "دولور",
    "دلوريس",
    "الأحزان",
    "الدولفين",
    "الدلافين",
    "القبة",
    "دومينيكو",
    "القبب",
    "القيامة",
    "داخليا",
    "دجن",
    "مدجن",
    "يدجن",
    "التدجين",
    "العائلية",
    "مسكون",
    "الهيمنة",
    "مهيمن",
    "سيطر",
    "يسيطر",
    "إستبد",
    "يستبد",
    "بقبة",
    "دومينجو",
    "دومينيك",
    "الدومنيكا",
    "الدومنيكي",
    "الدومنيكيون",
    "المعلم",
    "الدومنة",
    "دوناه",
    "دوناهو",
    "دونالد",
    "دونالدسون",
    "تبرع",
    "متبرع",
    "يتبرع",
    "التبرع",
    "تبرعي",
    "معمول",
    "الدونغ",
    "الحمار",
    "الحمير",
    "دونا",
    "مرتدى",
    "دونيلي",
    "دونير",
    "الإرتداء",
    "دونيبروك",
    "دونوفان",
    "الرسومات",
    "دوهيكي",
    "دووليتل",
    "منكوب",
    "الباب",
    "البواب",
    "البوابون",
    "مقبض",
    "مقابض",
    "الابواب",
    "ممسحة",
    "الأرجل",
    "الأبواب",
    "العتبة",
    "العتبات",
    "حاجز",
    "مخدر",
    "تخدير",
    "دوبلر",
    "دورا",
    "دورادو",
    "دوركاس",
    "دورجيستسر",
    "دورين",
    "دوريسي",
    "دوريس",
    "الخمول",
    "الشباك",
    "الزغبات",
    "الزغبة",
    "دوروثي",
    "ظهريا",
    "الخراف",
    "دورتموند",
    "ميكروسوفت)",
    "الجرعات",
    "مداوى",
    "الجرع",
    "الاشعاع",
    "المداواة",
    "دوستويفسكاي",
    "مصفوفة",
    "الخرفان",
    "إخرف",
    "خارف",
    "بطيء",
    "يخرف",
    "مخرف",
    "النقاط",
    "التنقيط",
    "الفعل",
    "أضلاع",
    "دوبليداي",
    "المباراتان",
    "المتعاقبتان",
    "الإزدواجية",
    "بريدية",
    "مضاعفة",
    "دوبليرس",
    "الأضعاف",
    "الدبلون",
    "عملة",
    "ذهبية",
    "أسبانية",
    "مضاعفا",
    "مريب",
    "الشكاك",
    "الشكاكون",
    "التأكد",
    "الشكوك",
    "الدوش",
    "مغسول",
    "الغسل",
    "دوسين",
    "دوغ",
    "العجينة",
    "دوجهيرتاي",
    "لين",
    "دوغلاس",
    "دوجلاس",
    "غطس",
    "مغطس",
    "دوسير",
    "يغطس",
    "التغطيس",
    "الحمامة",
    "دوفيكي",
    "دوفر",
    "معشق",
    "التعشيق",
    "الأربطة",
    "داو",
    "الأرملة",
    "الأرامل",
    "إبتذالا",
    "نسوة",
    "قذرة",
    "بابتذال",
    "الرثاثة",
    "مبتذل",
    "المسمار",
    "موتد",
    "التوتيد",
    "المسامير",
    "ممهور",
    "مهر",
    "الدتشر",
    "دوولينج",
    "المنخفض",
    "ساقط",
    "داوني",
    "المفاجىء",
    "الإنحدارات",
    "محزونا",
    "مريش",
    "النعومة",
    "الاسقاط",
    "هطول",
    "الأمطار",
    "الريشات",
    "السلبي",
    "ماسورة",
    "الرمية",
    "السفلية",
    "الرميات",
    "الضائع",
    "سكنة",
    "الانخفاض",
    "مظلوم",
    "نزولا",
    "اﻷسفل",
    "فنازلا",
    "باتجاه",
    "المهر",
    "المبلل",
    "الترانيم",
    "الحمدلة",
    "دوز",
    "دويل",
    "الإغفاءة",
    "مغفو",
    "دزينة",
    "العشرات",
    "الغفيان",
    "النعاس",
    "دائخ",
    "المومس",
    "قاتم",
    "المومسات",
    "دراكو",
    "متشدد",
    "المسودة",
    "تعرضا",
    "للتيارات",
    "الصياغة",
    "المسودات",
    "الرسامون",
    "صياغة",
    "العائق",
    "مسحوب",
    "الساحب",
    "السحب",
    "بشد",
    "بسحب",
    "التنين",
    "آباء",
    "المغزل",
    "مغزل",
    "دراجونهيد",
    "(أعشاب)",
    "التنينات",
    "العوائق",
    "التصريفات",
    "المصرف",
    "المصرفات",
    "أنبوب",
    "البواليع",
    "درايك",
    "الدرهم",
    "المسرحية",
    "دراماماين",
    "المسرحيات",
    "المسرحي",
    "المسرحيون",
    "المسرحة",
    "ممسرح",
    "يمسرح",
    "التمسرح",
    "شرب",
    "الأقمشة",
    "البزازون",
    "صارم",
    "الرسامة",
    "التخطيطية",
    "الجسر",
    "المسحوب",
    "الدرج",
    "التشدق",
    "متشدق",
    "يمد",
    "بتشدق",
    "بمد",
    "الفزاعة",
    "المدرعة",
    "الحلم",
    "الإنسان",
    "محلوم",
    "أحلاما",
    "الخيالية",
    "الأحلام",
    "أراضي",
    "خيال",
    "كالحلم",
    "كئابة",
    "موحش",
    "الجرافة",
    "مجروف",
    "الجرافات",
    "الجرف",
    "الرواسب",
    "الثمالة",
    "منقع",
    "الناقع",
    "التنقيع",
    "ملبوس",
    "الخزائن",
    "الألبسة",
    "تأنقا",
    "التأنق",
    "الخياط",
    "الخياطون",
    "درو",
    "دريكسيل",
    "دريفوس",
    "قليل",
    "التقطر",
    "دريبلير",
    "القطرات",
    "المجففات",
    "أجف",
    "الإنجراف",
    "منجرف",
    "التائه",
    "التائهون",
    "بانسياب",
    "الأكوام",
    "المجروف",
    "المثقاب",
    "دريلير",
    "المدرب",
    "المثاقب",
    "للشرب",
    "الشارب",
    "الشرابون",
    "القطرة",
    "المرق",
    "دريسكول",
    "قضاء",
    "الحاجة",
    "سال",
    "لعابه",
    "يسيل",
    "الانسياق",
    "رخصة",
    "الرذاذ",
    "أمطر",
    "مطرا",
    "خفيفا",
    "الخفيفة",
    "إمطار",
    "ممطر",
    "طريف",
    "الدعابة",
    "المهزلة",
    "اﻷزاز",
    "اﻷزيز)",
    "برتابة",
    "رول",
    "مرول",
    "الترويل",
    "يرول",
    "تدل",
    "متدلى",
    "تدلي",
    "بتدلي",
    "يتدلى",
    "دروبي",
    "المنبوذون",
    "القطارة",
    "القطارات",
    "الذرق",
    "إستسقائي",
    "التورمات",
    "الإتسقاء",
    "ذبابة",
    "أحثل",
    "من..",
    "اﻷكثر",
    "حثالة",
    "الحثالة",
    "مشوب",
    "بالشوائب",
    "القطيع",
    "هائلة",
    "إغرق",
    "غارق",
    "للغرق",
    "الغرق",
    "يغرق",
    "إنعس",
    "ناعس",
    "ينعس",
    "نعاسا",
    "بخمول",
    "نعسان",
    "الكادح",
    "كادح",
    "الكدح",
    "الكادحون",
    "بكدح",
    "العقار",
    "عقاقير",
    "الطبل",
    "طبل",
    "قرع",
    "الطبول",
    "جلدة",
    "مطبل",
    "الطبال",
    "الطبالون",
    "التطبيل",
    "دروموند",
    "أعواد",
    "السكير",
    "السكارى",
    "بثمالة",
    "النوي",
    "النويات",
    "دروري",
    "الجنية",
    "درايدن",
    "دريوال",
    "الثنائيين",
    "الثنائيون",
    "دوان",
    "لقب",
    "ملقب",
    "التشحيم",
    "دوبه",
    "دبلن",
    "دوبويس",
    "يلقب",
    "دوبوك",
    "دوقي",
    "الدوقية",
    "أوروبية",
    "الدوقة",
    "الدوقات",
    "الدوقيات",
    "البطة",
    "متفادى",
    "الذليل",
    "البطات",
    "الحبيب",
    "أنبوبي",
    "التطرق",
    "دادلي",
    "الفاشلون",
    "المبارزة",
    "متبارز",
    "المبارز",
    "المبارزون",
    "التبارز",
    "المبارزات",
    "يتبارز",
    "الاستحقاقية",
    "دوف",
    "دوفي",
    "الحلمة",
    "دوجان",
    "عذب",
    "السنطور",
    "السناطير",
    "البليد",
    "البلداء",
    "مبلد",
    "دوليس",
    "يبلد",
    "الدلسي",
    "دولوث",
    "الدوما",
    "دوما",
    "أخرس",
    "الدمبل",
    "(ثقل)",
    "(أثقال)",
    "إصعق",
    "مصعوق",
    "ألمذهل",
    "يصعق",
    "البكم",
    "صمت",
    "الصمت",
    "دومونت",
    "النفاية",
    "تخلص",
    "بسمنة",
    "الفطائر",
    "دنبار",
    "دنكان",
    "الكثيب",
    "دنيدن",
    "الكثبان",
    "الروث",
    "الدنغري",
    "رداء",
    "مسمد",
    "الزنزانة",
    "الزنزانات",
    "المزبلة",
    "التسميد",
    "دنهام",
    "غمس",
    "مغمس",
    "دنكل",
    "دنكر",
    "التغميس",
    "دنكيرك",
    "يغمس",
    "دنلاب",
    "دنلوب",
    "مطالب",
    "المطالبون",
    "إثنا",
    "إثني",
    "دووبوليست",
    "الساذج",
    "السذج",
    "شرنقة",
    "مزدوجة",
    "للمضاعفة",
    "المطابقة",
    "دوبونت",
    "دوكوسن",
    "الدوام",
    "المتانة",
    "متين",
    "المعمرة",
    "السجن",
    "دورانجو",
    "المدة",
    "الآماد",
    "دورير",
    "دورهام",
    "دركي",
    "دركين",
    "دريل",
    "درورد",
    "دوسينبيرج",
    "دوسينبري",
    "الغسق",
    "معتم",
    "الغسوقة",
    "دسلدورف",
    "القمامة",
    "صناديق",
    "منفض",
    "المنفضة",
    "المنافض",
    "ترابا",
    "دستين",
    "التغبر",
    "التنفيض",
    "غبار",
    "المجرفة",
    "المجارف",
    "دوستستورم",
    "مترب",
    "هولندي",
    "دتشاس",
    "الهولندي",
    "الهولنديون",
    "مطيع",
    "الإطاعة",
    "للرسوم",
    "دتون",
    "دفوراك",
    "القزم",
    "مقزم",
    "التقزيم",
    "قزم",
    "القزامة",
    "الضئالة",
    "الأقزام",
    "السكنة",
    "دوايت",
    "تضاءل",
    "متضاءل",
    "يتضاءل",
    "التضاءل",
    "دويندلنجس",
    "دوير",
    "مصبوغ",
    "الصباغة",
    "الصباغ",
    "الصباغون",
    "ديلان",
    "ديمانيك",
    "دينامي",
    "بفاعلية",
    "الديناميكا",
    "الدينامية",
    "الديناميت",
    "منسوف",
    "المفجرون",
    "الديناميات",
    "مقوى",
    "الأمير",
    "الداين",
    "الزحار",
    "عسر",
    "متخوم",
    "الدسبروزيوم",
    "(عنصر",
    "المرير",
    "ربحية",
    "تعني",
    "ببرمجيات",
    "لينكس",
    "وبرمجيات",
    "أخرى",
    "المهملات",
    "المسكرات",
    "قضيب",
    "وتستعمل",
    "للإثارة",
    "الإضافية",
    "المفروضة",
    "الباخرة",
    "تأخر",
    "موعد",
    "مغادرتها",
    "يشكو",
    "صعوبة",
    "المكتوب",
    "بمرض",
    "الديسليكسيا",
    "إي",
    "الإلكتروني",
    "ومثال",
    "ايجان",
    "الإشتياق",
    "النسر",
    "إرتفاع",
    "المد",
    "الأذن",
    "سدادة",
    "وجع",
    "أوجاع",
    "طبلة",
    "طبلات",
    "أذنين",
    "الإيرل",
    "الأرلية",
    "أسبق",
    "التبكير",
    "واقيات",
    "للأذن",
    "إكسب",
    "مكسوب",
    "الكاسب",
    "الكسبة",
    "العربون",
    "جدي",
    "الجدية",
    "الدخل",
    "المداخيل",
    "يكسب",
    "إيرب",
    "قانون",
    "السماعة",
    "السماعات",
    "الاذن",
    "الاذان",
    "القرط",
    "الأقراط",
    "الآذان",
    "صام",
    "اختبأ",
    "الفخاريات",
    "الدنيوية",
    "دنيوي",
    "ماكينة",
    "التراب",
    "الزلزال",
    "الزلازل",
    "المانع",
    "الأرضي",
    "دودة",
    "ديدان",
    "ترابي",
    "شمع",
    "الشهود",
    "السهولة",
    "الحوامل",
    "أسهل",
    "بسهولة",
    "الشرق",
    "شرقا",
    "الفصح",
    "الشرقي",
    "الشرقيون",
    "إيستهامبتون",
    "إيستلاند",
    "الشيوخ",
    "إيسمان",
    "إيستويك",
    "إيستوود",
    "مأكول",
    "الآكل",
    "الأكلة",
    "إيتون",
    "يأكل",
    "إيو",
    "إيف",
    "التصنت",
    "متصنت",
    "المتصنت",
    "المتصنتون",
    "الإنصات",
    "يتصنت",
    "إيزيل",
    "إنحسر",
    "الإنحسار",
    "ينحسر",
    "الموسعة",
    "للتبادل",
    "إبين",
    "الإيبونيت",
    "الأبنوس",
    "الابتهاج",
    "الغريب",
    "الأطوار",
    "لامركزي",
    "غرابة",
    "الغريبو",
    "إكليس",
    "إكليروسي",
    "المستويات",
    "إيكيدنا",
    "(آكل",
    "النمل)",
    "الصدى",
    "مردد",
    "الأصداء",
    "ترديد",
    "الشهرة",
    "إنتقائي",
    "الكسوف",
    "مكسوف",
    "مسير",
    "القصيدة",
    "اقتصادي",
    "دراسات",
    "عليا",
    "بيئي",
    "بيئيا",
    "الالبيئة",
    "اقتصاد",
    "إحصائي",
    "المتخصصون",
    "بالإقتصاد",
    "القياسي",
    "الإقتصاد",
    "إقتصادي",
    "إقتصاديا",
    "الإقتصاديات",
    "الإقتصادي",
    "الإقتصاديون",
    "إقتصد",
    "مقتصد",
    "المقتصد",
    "المقتصدون",
    "يقتصد",
    "الاقتصادي",
    "البيئي",
    "الأنظمة",
    "البيئية",
    "النشوات",
    "إنشرح",
    "منشرح",
    "ينشرح",
    "الإنشراح",
    "النشوة",
    "منتشي",
    "بنشوة",
    "منزاح",
    "إكوادور",
    "بتوحيد",
    "اﻷديان",
    "عالميا",
    "موحدين",
    "الوحدوية",
    "توحيد",
    "الأكزيما",
    "إد",
    "إدام",
    "إدي",
    "مدوم",
    "الدوامة",
    "التدويم",
    "إديلويس",
    "الوذمة",
    "متورم",
    "إدغار",
    "حافة",
    "أحرزت",
    "مشحوذ",
    "المقلم",
    "إجيرتون",
    "ايدجواتر",
    "إجوود",
    "تنرفزا",
    "صلوحية",
    "التلاؤم",
    "المأكولات",
    "التنوير",
    "الصرح",
    "الصروح",
    "منشأ",
    "ينشئ",
    "أنشأ",
    "تنويرية",
    "أدنبرة",
    "للتحرير",
    "إديث",
    "الطبعة",
    "الطبعات",
    "المحرر",
    "الإفتتاحية",
    "إكتب",
    "إفتتاحية",
    "الافتتاحية",
    "يكتب",
    "كتابة",
    "تحريري",
    "الإفتتاحيات",
    "المحررون",
    "إدموندز",
    "إدموندسون",
    "أدمنتون",
    "إدموند",
    "إدنة",
    "معالجة",
    "الإلكترونية",
    "ل",
    "تعليم",
    "إدسجير",
    "الصيفي",
    "إدوارد",
    "إدواردو",
    "للتعليم",
    "تعلم",
    "يتعلم",
    "التعلم",
    "تربوي",
    "إستنباطي",
    "بالملك",
    "\"ايدوارد\"",
    "انجلترا",
    "إدواردز",
    "إدوين",
    "إدوينا",
    "الدماغي",
    "الأنقليس",
    "يلجراس",
    "لجنة",
    "فرصة",
    "المتساوية",
    "يرير",
    "يريست",
    "يرينيس",
    "إمسح",
    "للمسح",
    "ممسوح",
    "الطمس",
    "الماسح",
    "يمسح",
    "الإحداث",
    "عمليا",
    "المستجيب",
    "المستجيبات",
    "التأثيرات",
    "الفاعلية",
    "الخنوثة",
    "متخنث",
    "مفار",
    "الإنفعال",
    "منفعل",
    "يفور",
    "الفوران",
    "واهن",
    "العقم",
    "كفوء",
    "إفي",
    "الدفق",
    "الدفقات",
    "متدفق",
    "المخلفات",
    "الجهد",
    "الجهود",
    "ساطع",
    "مراق",
    "يريق",
    "الإراقة",
    "فياض",
    "الفيض",
    "الإفت",
    "(زاحف)",
    "سبيل",
    "الجرافيك",
    "المحسن",
    "التعجب",
    "يعبر",
    "الفرح",
    "عادل",
    "مذهب",
    "إجان",
    "مضرب",
    "يحرض,",
    "يحرك",
    "مجمع",
    "مشروبات",
    "الباذنجان",
    "قشر",
    "قشور",
    "الأنا",
    "الأنانية",
    "أناني",
    "الأنانيون",
    "مزهو",
    "الغرورية",
    "المغرورون",
    "شنيع",
    "فضيع",
    "الفضاعة",
    "المخرج",
    "مصر",
    "المصري",
    "المصريون",
    "ايه",
    "إهرليتش",
    "آيتشمان",
    "ريش",
    "العيدر",
    "الخطي",
    "ثمانية",
    "ثامن",
    "ثماني",
    "ثامنا",
    "الأثمان",
    "الثمانينات",
    "ثمانون",
    "الثمانيات",
    "آيل",
    "آيلين",
    "آينشتاين",
    "أينشتين",
    "الآينشتانيوم",
    "آير",
    "آيزنهاور",
    "آيسنير",
    "أما",
    "أما..",
    "إقذف",
    "قذفي",
    "إكبيرج",
    "إكستروم",
    "إكتاتشروم",
    "ال",
    "المسهبون",
    "إلين",
    "الهمة",
    "إنقض",
    "منقضي",
    "ينقضي",
    "الإنقضاء",
    "المطاطي",
    "مطاطيا",
    "المرونة",
    "المطاطية",
    "إلاستومر",
    "(يشبه",
    "إنتش",
    "إلاتر",
    "ينتشي",
    "الإنتشاء",
    "إلبا",
    "الشيخ",
    "الكهولة",
    "مسن",
    "إلدون",
    "إلينور",
    "إليزار",
    "منتخب",
    "الإنتخاب",
    "الإنتخابات",
    "مساندة",
    "منظمو",
    "إنتخابي",
    "إختياريا",
    "الاختيارية",
    "إنتخابيا",
    "إليكترا",
    "المنتخبة",
    "قطبية",
    "دائمة",
    "الكهربائية",
    "الكهربائيون",
    "الكهربة",
    "مكهرب",
    "يكهرب",
    "كهرب",
    "التكهرب",
    "كهرو",
    "كهروكيمياوي",
    "بالصدمة",
    "الأقطاب",
    "كهروديناميكي",
    "إليكتروجرافيك",
    "المنحل",
    "بالكهرباء",
    "المنحلات",
    "كهربائيا",
    "تحليلا",
    "المغناطيس",
    "كهرومغناطيسي",
    "بالكهرومغناطيسية",
    "الكهرومغناطيسية",
    "الكهرومغناطيسيات",
    "المغناطيسات",
    "كهروميكانيكي",
    "حركي",
    "العضام",
    "الألكترون",
    "بعث",
    "الإلكترونيات",
    "الألكترونات",
    "مولد",
    "للكهرباء",
    "المطلي",
    "الطلي",
    "الكهربية",
    "بالكهربائية",
    "المستقرة",
    "المعالج",
    "الدهانون",
    "والقوة",
    "الضعيفة",
    "المنتخبون",
    "إليد",
    "إليجانسي",
    "رثائي",
    "المرثيات",
    "المرثية",
    "عنصري",
    "جوهريا",
    "مركبة",
    "إليمنات",
    "إلينا",
    "الفيل",
    "فيلي",
    "الفيلة",
    "إرفع",
    "يرفع",
    "الإرتفاع",
    "الإرتفاعات",
    "المصعد",
    "المصاعد",
    "التصبيرة",
    "الصباحية",
    "حادي",
    "العفريت",
    "عفريتي",
    "العفرتة",
    "الجني",
    "إلجين",
    "ألي",
    "إنتزع",
    "الاستخراج",
    "الاستخراجات",
    "منتزع",
    "الإنتزاع",
    "ينتزع",
    "الإستحقاقات",
    "أليجه",
    "إليوت",
    "إليزابيث",
    "إليز",
    "إليشا",
    "الترخيم",
    "النخبة",
    "النخب",
    "النخبية",
    "نخبوي",
    "الإكسير",
    "اليزابيثي",
    "اليزابيثيون",
    "إلخارت",
    "الأيآئل",
    "إيلا",
    "إلي",
    "البيضوي",
    "مجسمات",
    "الأهليلجية",
    "إهليليجي",
    "الإهليليجية",
    "إليسون",
    "ألسويرث",
    "ألوود",
    "الدردار",
    "ألمير",
    "إلمهورست",
    "إلميرا",
    "إلمسفورد",
    "إلويس",
    "طول",
    "مطول",
    "يطول",
    "التطويل",
    "الإطالة",
    "إهرب",
    "هارب",
    "إلوبير",
    "الفصاحة",
    "بليغ",
    "ألروي",
    "إيلس",
    "عدا",
    "إلسي",
    "إلزينور",
    "ألتن",
    "المحلول",
    "تنشأ",
    "توضيحي",
    "تملص",
    "متملص",
    "يتملص",
    "التملص",
    "الجان",
    "ألفيس",
    "الجنة",
    "إضعف",
    "النحول",
    "إماس",
    "إنبثق",
    "منبثق",
    "ينبثق",
    "الإنبثاق",
    "إنبثاقي",
    "أعتق",
    "معتق",
    "يعتق",
    "الإعتاق",
    "الإنعتاق",
    "المعتق",
    "المعتقون",
    "إمانويل",
    "حنط",
    "محنط",
    "المحنط",
    "المحنطون",
    "التحنيط",
    "يحنط",
    "بخاصة",
    "داخلي",
    "مقاطع",
    "إصعد",
    "باحراج",
    "السفارات",
    "السفارة",
    "عبئ",
    "محاصر",
    "التعبئة",
    "ضمن",
    "مضمن",
    "الغرز",
    "يضمن",
    "الجمر",
    "إختلس",
    "مختلس",
    "المختلس",
    "المختلسون",
    "أشعر",
    "بالمرارة",
    "إشعار",
    "التنغيص",
    "يشعر",
    "مجد",
    "مزركش",
    "الزخرفة",
    "يمجد",
    "الشعار",
    "رمزي",
    "الشعارات",
    "مجسد",
    "المجسد",
    "يجسد",
    "جسد",
    "التجسيد",
    "مشجع",
    "التشجيع",
    "إحضن",
    "يحضن",
    "إنقش",
    "منقوش",
    "المزخرف",
    "المزخرفون",
    "ينقش",
    "النقوش",
    "الشفاه",
    "عزف",
    "العناق",
    "للضم",
    "تعانق",
    "المعانق",
    "التعانق",
    "بعناق",
    "إحتوائي",
    "هشش",
    "المطرز",
    "المطرزون",
    "التطريزات",
    "ورط",
    "مورط",
    "التوريط",
    "يورط",
    "جنيني",
    "الأجنة",
    "صحح",
    "للتصحيح",
    "الزمردة",
    "الزمردات",
    "إظهر",
    "المحارب",
    "أنهى",
    "خدمته",
    "فخري",
    "إيمرسن",
    "مقيئ",
    "المقيئات",
    "مهاجر",
    "المهاجرون",
    "هاجر",
    "يهاجر",
    "الهجرات",
    "أميل",
    "إمليو",
    "إيميلي",
    "السمو",
    "سامي",
    "بتفوق",
    "الإمارة",
    "الإمارات",
    "المبعوثون",
    "المبعوث",
    "الإشعاعات",
    "إبعث",
    "الابتعاثية",
    "الباعثون",
    "إيما",
    "إميت",
    "إموري",
    "العاطفة",
    "العاطفية",
    "عاطفيا",
    "العواطف",
    "إمباث",
    "تقمصي",
    "تعاطف",
    "الإمبراطور",
    "الأباطرة",
    "بتأكيد",
    "الإمبراطوريات",
    "المجرب",
    "تجريبي",
    "التجريبية",
    "التجريبي",
    "التجريبيون",
    "ضع",
    "الموضع",
    "إمبلاستيك",
    "الإستخدام",
    "الإستخدامية",
    "للإستخدام",
    "المستخدمون",
    "رب",
    "أرباب",
    "التوظيف",
    "الإستخدامات",
    "المخازن",
    "المتجر",
    "المتاجر",
    "مخول",
    "الإمبراطورة",
    "الإمبراطورات",
    "معنى",
    "فارغ",
    "الإفراغ",
    "السماء",
    "حاك",
    "محاكى",
    "يحاكي",
    "المحاكاة",
    "محاكي",
    "المحاكي",
    "المحاكون",
    "المستحلب",
    "مستحلب",
    "يستحلب",
    "إستحلب",
    "الإستحلاب",
    "المستحلبات",
    "إن",
    "يؤهل‎",
    "يخول",
    "تمكين",
    "التمكين",
    "شرع",
    "مشرع",
    "التشريع",
    "التشريعات",
    "المينا",
    "مصقول",
    "الصاقل",
    "(يصقل)",
    "الصقال",
    "إفتن",
    "مفتون",
    "يفتن",
    "فتن",
    "معسكر",
    "العسكرة",
    "يعسكر",
    "غلف",
    "مغلف",
    "يغلف",
    "التغليف",
    "التلخيصات",
    "التغليفات",
    "برسم",
    "إسحر",
    "بهيج",
    "الساحرة",
    "الساحرات",
    "تكلفة",
    "ثمن",
    "يفرض",
    "رصع",
    "الإنتشلادا",
    "شفر",
    "يشفر",
    "يطوق",
    "الجيب",
    "الجيوب",
    "أرفق",
    "مرفق",
    "يرفق",
    "الإرفاق",
    "التسييج",
    "المرفقات",
    "إنكوميا",
    "الثناءات",
    "أحط",
    "يحيط",
    "المرة",
    "الأخرى",
    "مستعاد",
    "الإستعادة",
    "متجاوز",
    "المتجاوز",
    "يتجاوز",
    "الإنتهاكات",
    "أثقل",
    "مثقل",
    "الإثقال",
    "يثقل",
    "العبء",
    "الأعباء",
    "المنشور",
    "المنشورات",
    "الموسوعات",
    "المستعمل",
    "للخطر",
    "تعريض",
    "يعرض",
    "حبب",
    "محبب",
    "التحبيب",
    "بتحبب",
    "التحبب",
    "يحبب",
    "المسعى",
    "مسعي",
    "المحاول",
    "المحاولون",
    "السعي",
    "المساعي",
    "منتهى",
    "المستوطن",
    "الاستشراء",
    "طرف",
    "إندرز",
    "المرحلة",
    "إنديكوت",
    "النهايات",
    "لانهائي",
    "اللانهائي",
    "ختامي",
    "تعليقات",
    "ختامية",
    "الداخلي",
    "زواجي",
    "الأقارب",
    "النشوء",
    "التشابه",
    "الشكلي",
    "المصدق",
    "المصدقون",
    "التصديقات",
    "السويداء",
    "غشائي",
    "موهوب",
    "الوهب",
    "الهبة",
    "الهبات",
    "يهب",
    "انتهاء",
    "للتحمل",
    "يطاق",
    "دائمي",
    "اﻷمد",
    "طولا",
    "الشرجية",
    "الطاقات",
    "إنفيلد",
    "إطو",
    "مطوي",
    "الطوي",
    "يطوى",
    "إفرض",
    "الإلزامية",
    "فرضا",
    "المنفذون",
    "إنفورسيبل",
    "بتوكيل",
    "إشغل",
    "إنجيل",
    "محدث",
    "بمحرك",
    "الهندسة",
    "إنجلاندر",
    "إنجل",
    "إنجليوود",
    "إنجليتش",
    "جوزيف",
    "نمساوي",
    "الإنجليزي",
    "الإنجليز",
    "الالتهام",
    "مرسخ",
    "إنسخ",
    "ينسخ",
    "بتشويق",
    "الإنشغال",
    "إبتلع",
    "مبتلع",
    "الإبتلاع",
    "يبتلع",
    "إنيد",
    "النهي",
    "بمتعة",
    "متمتع",
    "للزيادة",
    "المكبر",
    "المكبرات",
    "يتضخم",
    "التضخم",
    "نور",
    "ينور",
    "إنليستر",
    "يسجل",
    "شبك",
    "العداوات",
    "معظم",
    "التشريف",
    "المرتق",
    "يعظم",
    "التعظيم",
    "منتظم",
    "إنول",
    "الفظاعات",
    "الهول",
    "إنوس",
    "إستفسر",
    "مستفسر",
    "المستفسر",
    "المستفسرون",
    "يستفسر",
    "الإستفسار",
    "مغضب",
    "يبهج",
    "الإبهاج",
    "أغن",
    "المثري",
    "يغني",
    "الإغناء",
    "إنريكو",
    "إحجب",
    "محجوب",
    "قدس",
    "يقدس",
    "كفن",
    "مكفن",
    "التكفين",
    "يكفن",
    "إستعبد",
    "مستعبد",
    "الإستعباد",
    "المستعبد",
    "المستعبدون",
    "يستعبد",
    "إنستاتايت",
    "(معدن)",
    "إتل",
    "متلي",
    "يتلي",
    "الإتباع",
    "إضمن",
    "مستلزم",
    "الإستلزام",
    "الاستتباع",
    "التشابك",
    "المعقد",
    "التشابكات",
    "المشابكة",
    "التورية",
    "إدخل",
    "الداخل",
    "المشروع",
    "المستثمر",
    "المشاريع",
    "جريئ",
    "يدخل",
    "مسلى",
    "الهزلي",
    "الهزليون",
    "بامتاع",
    "الترفيه",
    "التسالي",
    "للجسم",
    "نتاج",
    "حجمه",
    "المضروبان",
    "توج",
    "حمس",
    "محمس",
    "يحمس",
    "المتحمس",
    "المتحمسون",
    "أغر",
    "مغرى",
    "الإغراءات",
    "المغري",
    "المغرون",
    "الكيانات",
    "أهل",
    "معنون",
    "التأهيل",
    "يؤهل",
    "الكيان",
    "إدفن",
    "القبر",
    "الحشراتي",
    "الحاشية",
    "الأحشاء",
    "جر",
    "مجرور",
    "المنساب",
    "يجر",
    "مدوخ",
    "التدويخ",
    "المشترك",
    "المشتركون",
    "الفخاخ",
    "توسل",
    "متوسل",
    "الإلتماس",
    "التوسل",
    "يتوسل",
    "المقبلات",
    "تحصن",
    "متحصن",
    "يتحصن",
    "الإستحكام",
    "الإستحكامات",
    "الحر",
    "طابق",
    "طابقين",
    "(ميزانين)",
    "طوابق",
    "منخفضة",
    "إنتري",
    "الانتروبيا",
    "إئتمن",
    "مؤتمن",
    "يأتمن",
    "إضفر",
    "يضفر",
    "للاحصاء",
    "معدد",
    "يعدد",
    "ملفوظ",
    "إلفظ",
    "يلفظ",
    "اللفظ",
    "المصرح",
    "المصرحون",
    "المغلف",
    "سمم",
    "مسمم",
    "يسمم",
    "محسود",
    "مدعاة",
    "للحسد",
    "بحسد",
    "الحسود",
    "الحساد",
    "يحسد",
    "الحسد",
    "حوط",
    "محوط",
    "التحويط",
    "إختصاصي",
    "الضواحي",
    "متصور",
    "إنفيد",
    "إنزيمي",
    "الإنزيم",
    "الإنزيمات",
    "بداية",
    "حصان",
    "صبغة",
    "فوسفورية",
    "حماية",
    "الكتفية",
    "الكتفيات",
    "عابر",
    "العوابر",
    "المذكرات",
    "إفيسي",
    "إفيسيون",
    "إفيسس",
    "(مدينة)",
    "إفرايم",
    "الملحمة",
    "ملحمي",
    "الملاحم",
    "الأبيقوري",
    "الأبيقوريون",
    "إبيكرس",
    "فلك",
    "التدوير",
    "أفلاك",
    "تداويري",
    "الوباء",
    "وبائي",
    "الأوبئة",
    "مرضيا",
    "وبائيا",
    "التخلق",
    "المتعاقب",
    "الابيغرافي",
    "الصرع",
    "بالصرع",
    "صرعي",
    "الظاهرة",
    "الكردوس",
    "الأسقفي",
    "الحادثة",
    "بنظرية",
    "الرسائل",
    "رسائل",
    "شفافة",
    "بنمو",
    "إبيتاز",
    "الظهارة",
    "طلائي",
    "الخلاصات",
    "الملخصون",
    "العهود",
    "إبوكسي",
    "للألفباء",
    "إبسوم",
    "إبشتاين",
    "التساوي",
    "مطرد",
    "برصانة",
    "ساوى",
    "ساو",
    "مساوى",
    "المعادل",
    "المعادلات",
    "يساوي",
    "النظائر",
    "الرصانة",
    "المعادلة",
    "تعادلي",
    "الإستواء",
    "إستوائي",
    "استوائيمجري",
    "الساسة",
    "متساوو",
    "وازن",
    "التوازنات",
    "(المشي",
    "باتزان)",
    "فرسي",
    "الخيول",
    "إعتدالي",
    "الإعتدال",
    "الإعتدالات",
    "مجهز",
    "بانصاف",
    "العادية",
    "العدالة",
    "المكافئات",
    "المكافئ",
    "المكافئون",
    "الطواريء",
    "المستشفى",
    "العصر",
    "للإستئصال",
    "إستأصل",
    "مستأصل",
    "يستأصل",
    "الإستئصال",
    "استئصالي",
    "إمح",
    "ممحو",
    "المحاية",
    "المحايات",
    "يمحو",
    "المحو",
    "إراتو",
    "إراتوسثينيز",
    "الأربيوم",
    "إردا",
    "يحرث",
    "منتصب",
    "للإنتصاب",
    "الإنتصاب",
    "المركبون",
    "ينصب",
    "الإرج",
    "الارجاتية",
    "ولهذا",
    "إرجوديك",
    "احتمال",
    "الشيىء",
    "الانسان",
    "بالمادة",
    "الشقران",
    "إيريك",
    "إريك",
    "إريكسون",
    "أريكسون",
    "آيري",
    "بحيرات",
    "\"البحيرات",
    "العظمى\"",
    "أرتيريا",
    "إرلانج",
    "إرلينميير",
    "القاقم",
    "إرمنيد",
    "إيرنست",
    "إرنيستاين",
    "إرني",
    "للتآكل",
    "إروز",
    "تآكلي",
    "اﻷعمال",
    "(أفلام",
    "صور)",
    "أخطأ",
    "المهمات",
    "ضالا",
    "التجوال",
    "الانحرافات",
    "بعصبية",
    "مخطأ",
    "إرول",
    "إكتشاف",
    "يخطئ",
    "إرسكين",
    "سابقا",
    "الاطلاع",
    "سعة",
    "الإطلاع",
    "إنفجر",
    "منفجر",
    "الإنفجار",
    "الإنفجارات",
    "إنفجاري",
    "ينفجر",
    "إرفإن",
    "إرون",
    "سرب",
    "تصاعد",
    "يتصاعد",
    "التصاعد",
    "التصعيدات",
    "الطيش",
    "الإنهزامي",
    "قوقعة",
    "قواقع",
    "إسكارول",
    "الإستيراث",
    "إسكيريتشيا",
    "معوية",
    "منضدة",
    "أسكيمو",
    "الأسكيمو",
    "\"مريء\"",
    "المرئ",
    "باطني",
    "اللاشعوري",
    "التعريشة",
    "التعريشات",
    "خصوصا",
    "الإسبيرانتو",
    "يراقب",
    "التجسس",
    "المتنزه",
    "إسبوسيتو",
    "الإعتناق",
    "تبنى",
    "الإسبريسو",
    "الروح",
    "راقب",
    "المحترم",
    "المحترمون",
    "إساير",
    "المقالات",
    "إسين",
    "الجوهر",
    "ضروري",
    "الضرورة",
    "الضروريات",
    "إسيكس",
    "قائم",
    "المؤسس",
    "يؤسس",
    "العقارات",
    "مثمن",
    "التثمين",
    "الإحترامات",
    "إستيلا",
    "الإستر",
    "إستيس",
    "إستر",
    "إسذيت",
    "إسذيتيس",
    "الجماليات",
    "احترام",
    "تقدير",
    "محترمة",
    "التخمينات",
    "تقديري",
    "المقدر",
    "المقدرون",
    "أستونيا",
    "الأستوني",
    "مجافى",
    "المنفر",
    "يجافي",
    "المجافاة",
    "ايستروجين",
    "الأنثوي",
    "المصبات",
    "مصبي",
    "المصب",
    "الفضائي",
    "إيتا",
    "للأبجدية",
    "التقريبي",
    "آخره",
    "أبدي",
    "يخلد",
    "(الخلود)",
    "خلد",
    "الأبد",
    "إثان",
    "الإثنين",
    "ايثانول",
    "إثيل",
    "الأثير",
    "أثيري",
    "اﻷثيرية",
    "الإيثرنت",
    "اﻷخلاقية",
    "اﻷخلاق",
    "إثيسيستس",
    "الأخلاق",
    "إثيوبيا",
    "الأثيوبيون",
    "عرقي",
    "عرقيا",
    "الإنتماءات",
    "العرقية",
    "الإنتماء",
    "العرقي",
    "الإنثوغرافيون",
    "أنثوغرافي",
    "وصف",
    "الأعراق",
    "البشرية",
    "إنثولوجي",
    "الأجناس",
    "اﻷخلاقيات",
    "الأخلاقيات",
    "الأثيل",
    "أثيل",
    "الإثيلين",
    "المسببات",
    "إتريوريا",
    "إشتقاقي",
    "أصول",
    "الأوكالبتوس",
    "(نوع",
    "النباتات)",
    "القربان",
    "قرباني",
    "يوكر(لعبة",
    "ورق)",
    "يوكر",
    "(لعبة",
    "إقليديس",
    "إقليدي",
    "يوجين",
    "يوجينيا",
    "للنسل",
    "بتحسين",
    "يوكاريوت",
    "(كائن",
    "الخلية)",
    "يولر",
    "يولر,",
    "قصائد",
    "المداح",
    "مدحي",
    "المداحون",
    "إمدح",
    "المادح",
    "المادحون",
    "يمدح",
    "المهذبون",
    "يونيس",
    "التلطيفية",
    "مسيىء",
    "تلطيفي",
    "باساءة",
    "رخيم",
    "الجميلة",
    "رخامة",
    "الفربيون",
    "الغبطة",
    "الفرات",
    "يوراسيا",
    "أوروبا",
    "أوراسيوي",
    "وجدتها",
    "يوريبيديز",
    "أوربي",
    "تيكنو",
    "يوروكأرد",
    "عدة",
    "مقاسات",
    "موحدة",
    "ﻷلواح",
    "رقص",
    "اوروبي",
    "أوروبي",
    "الأوربة",
    "مؤورب",
    "الأوربيون",
    "الأوروبيوم",
    "يوريديس",
    "أورفيس",
    "إنصهاري",
    "يوتيرب",
    "الوحي",
    "موت",
    "إيفا",
    "اخلائي",
    "المجلى",
    "المجلون",
    "مجتنب",
    "المتهرب",
    "المتهربون",
    "تقييمي",
    "المخمنون",
    "التلاشي",
    "متلاشي",
    "الأنجليكانية",
    "التبشيريون",
    "إفانجيلاين",
    "الإنجيلية",
    "الداعية",
    "تبشيرية",
    "نصر",
    "منصر",
    "ينصر",
    "التنصير",
    "إيفانس",
    "إيفانستون",
    "إيفانسفيل",
    "مبخر",
    "التبخير",
    "تبخري",
    "المبخر",
    "التهرب",
    "تهربا",
    "حواء",
    "إفيد",
    "إفيلن",
    "حتى",
    "مسوى",
    "المساوي",
    "الإنصاف",
    "المساء",
    "الأمسيات",
    "بانتظام",
    "صلاة",
    "الحدث",
    "حافل",
    "بالأحداث",
    "زاخرا",
    "تعددية",
    "اﻷحداث",
    "الأماسي",
    "نهائي",
    "الإحتمالات",
    "الإحتمال",
    "إحدث",
    "المحصلة",
    "أبدا",
    "ايفيرست",
    "إفيريت",
    "إفيرجلاد",
    "إفيرجلاديز",
    "الخضرة",
    "إفيرهارت",
    "الديمومة",
    "الليالي",
    "أثبت",
    "الإثبات",
    "الواضح",
    "الأشرار",
    "شرا",
    "شرير",
    "مبرهن",
    "يبرهن",
    "اثباته",
    "البرهنة",
    "أحشاء",
    "للاثارة",
    "شاعريا",
    "الاثارة",
    "إستدع",
    "مستدعى",
    "يستدعى",
    "الإستدعاء",
    "منشىء",
    "مناشئ",
    "تطوري",
    "التطوري",
    "التطوريون",
    "يتطور",
    "النعجة",
    "إوين",
    "النعاج",
    "إوينج",
    "مثار",
    "التفاقم",
    "مضبوط",
    "الفارض",
    "الضبط",
    "بالضبط",
    "مبالغ",
    "بمبالغة",
    "المبالغة",
    "يبالغ",
    "المبالغات",
    "الإعلاء",
    "بتمجيد",
    "المرقي",
    "المرقون",
    "الإمتحان",
    "إختبار",
    "إفحص",
    "الممتحن",
    "الممتحنون",
    "بمثال",
    "الإمتحانات",
    "ألمغيظ",
    "باغضاب",
    "نقب",
    "منقب",
    "ينقب",
    "التنقيب",
    "المنقب",
    "المنقبون",
    "المتفوق",
    "إبرع",
    "أصحاب",
    "يبرع",
    "النشارة",
    "ماعدا",
    "بأستثناء",
    "الإستثناء",
    "استثنائي",
    "أﻹمتياز",
    "الإستثناءات",
    "إستثناء",
    "المقتطف",
    "مقتطف",
    "الزيادات",
    "مفرط",
    "بإفراط",
    "متبادل",
    "المبدل",
    "المبدلون",
    "التبادلات",
    "الخزينة",
    "للضريبة",
    "الضريبة",
    "الضرائب",
    "فرض",
    "إيكسايت",
    "باثارة",
    "اﻹلكترون",
    "مصيح",
    "الصارخ",
    "الصارخون",
    "الصيحة",
    "تعجبي",
    "إستثن",
    "مستثنى",
    "يستثنى",
    "حصري",
    "الحصري",
    "الخصوصية",
    "المطرود",
    "المطرودون",
    "حرماني",
    "إسلخ",
    "مسلوخ",
    "يسلخ",
    "السلخ",
    "الغائط",
    "تغوط",
    "متغوط",
    "ألفصل",
    "ثم",
    "يتغوط",
    "إبرازي",
    "معذب",
    "يعذب",
    "لايطاق",
    "مبرأ",
    "تبريئي",
    "تجوالي",
    "معذور",
    "بعفو",
    "العذر",
    "معذر",
    "الصافح",
    "الأعذار",
    "الإعذار",
    "الإذن",
    "بالتغيب",
    "إكسيك",
    "إلعن",
    "للتنفيذ",
    "نفذ",
    "ينفذ",
    "الإعدام",
    "الجلاد",
    "الجلادون",
    "التنفيذيون",
    "تنفيذي",
    "المنفذة",
    "المنفذات",
    "التفاسير",
    "إكسيجيت",
    "النموذج",
    "نموذجيا",
    "النماذج",
    "معفى",
    "للاستعمال",
    "ممارس",
    "المتمرن",
    "المتمرنون",
    "الممارسة",
    "يمارس",
    "إكسيس",
    "ثلاثة",
    "إكسيتر",
    "إزفر",
    "مزفور",
    "يزفر",
    "الزفير",
    "العادم",
    "منهك",
    "باستنزاف",
    "المستنفذ",
    "مستنزف",
    "الإستنزاف",
    "الإعياء",
    "العوادم",
    "المعروض",
    "المعرض",
    "متلقي",
    "الإعانة",
    "المتباهون",
    "المعارض",
    "العارض",
    "العارضون",
    "المعروضات",
    "إبتهاجي",
    "محثوث",
    "الحاث",
    "منبوش",
    "نباش",
    "القبور",
    "الضرورات",
    "المنفى",
    "منفي",
    "المنفيون",
    "جد",
    "الوجود",
    "وجودي",
    "الوجودية",
    "الوجودي",
    "الوجوديون",
    "الإيجاد",
    "يجد",
    "النزوح",
    "الجماعي",
    "تحريمي",
    "خارجي",
    "باهظ",
    "بفداحة",
    "التعويذي",
    "التعويذيون",
    "الخارجي",
    "الهياكل",
    "الخارجية",
    "متمدد",
    "المتوسع",
    "المتوسعون",
    "الفسحة",
    "التوسعية",
    "التوسعي",
    "توسعي",
    "طف",
    "مطاف",
    "يطوف",
    "الطوف",
    "المغترب",
    "متغرب",
    "التغريب",
    "الإغتراب",
    "بإنتظار",
    "مقشع",
    "إبصق",
    "البصاق",
    "الذرائع",
    "الذريعة",
    "الوسيلة",
    "التعجيل",
    "البعثة",
    "البعثات",
    "للطرد",
    "للإستهلاك",
    "ألزم",
    "بالنفقات",
    "إلزام",
    "التجربة",
    "مجرب",
    "التجارب",
    "التجريب",
    "المجربون",
    "إكسبيشن",
    "مكفر",
    "تكفيري",
    "إنته",
    "ينتهي",
    "للتوضيح",
    "المفسر",
    "المفسرون",
    "التفسيرات",
    "الشتائم",
    "المأثرة",
    "للاستغلال",
    "الإستغلال",
    "إستغلالي",
    "المآثر",
    "الإستكشاف",
    "الإستكشافات",
    "إستطلاعي",
    "إستكشف",
    "المستكشف",
    "المستكشفون",
    "المتفجرة",
    "الإنفجارية",
    "أسي",
    "تصاعديا",
    "التصدير",
    "الصادرات",
    "المصدر",
    "المصدرون",
    "الفاضح",
    "الفاضحون",
    "التعريض",
    "إشرح",
    "الشرح",
    "الشروحات",
    "تفسيري",
    "مشروح",
    "الشارح",
    "السريعون",
    "للتعبير",
    "بتعبير",
    "الإبداء",
    "التعبيرية",
    "التعبيري",
    "تعبيري",
    "التعبيريون",
    "التعابير",
    "الملكيات",
    "إكسبانجير",
    "المتأنق",
    "الروعة",
    "إرتجالي",
    "الإرتجال",
    "إرتجل",
    "مرتجل",
    "المرتجل",
    "المرتجلون",
    "يرتجل",
    "ممتد",
    "التمديد",
    "الممدد",
    "يمدد",
    "للتمديد",
    "التمدد",
    "للإمتداد",
    "الإمتداد",
    "تفرعي",
    "تفرعيا",
    "توسعيا",
    "الإمتدادات",
    "نطاق",
    "الباسطة",
    "المدى",
    "المديات",
    "الخارج",
    "الخارجيات",
    "أبد",
    "مباد",
    "يبيد",
    "المبيد",
    "المبيدات",
    "التبرير",
    "خارجية",
    "خارجيا",
    "أحداث",
    "منقرض",
    "الإنقراض",
    "إنقراضي",
    "أطفأ",
    "مطفأ",
    "المطفأة",
    "المطافئ",
    "يطفئ",
    "الإطفاء",
    "إجتثاثي",
    "يجتث)",
    "ممجد",
    "الممجد",
    "بانتزاع",
    "بابتزاز",
    "إبتز",
    "مبتز",
    "الإبتزاز",
    "الإبتزازي",
    "الإبتزازيون",
    "المنتزع",
    "المنتزعون",
    "الإبتزازات",
    "إبتزازي",
    "يبتز",
    "الأجر",
    "إكستراس",
    "إستخلاصي",
    "النازع",
    "النازعون",
    "مقنن",
    "التنظيم",
    "إستنبط",
    "مستنبط",
    "يستنبط",
    "الإستنباط",
    "إستقرائي",
    "الإضافيات",
    "العليا",
    "التبذير",
    "بإسراف",
    "لناتج",
    "التطرف",
    "متطرف",
    "التطرفية",
    "المتطرف",
    "المتطرفون",
    "القصوى",
    "الأقصى",
    "للتخليص",
    "التخليص",
    "إنتماء",
    "بإرضاء",
    "الاخرين",
    "المنفتح",
    "المنفتحون",
    "النتوء",
    "الغزارة",
    "الإفرازات",
    "الإفراز",
    "إنضح",
    "ناضح",
    "ينضح",
    "النضوح",
    "إغتبط",
    "الإغتباط",
    "مغتبط",
    "ببهجة",
    "يغتبط",
    "إكسون",
    "إكسزيس",
    "مقلة",
    "ييباليد",
    "ييبالينج",
    "مقل",
    "العيون",
    "ييبولت",
    "ييبرايت",
    "ييدنيس",
    "ييجلاس",
    "النظارات",
    "عيون",
    "الثقوب",
    "الجفن",
    "الجفون",
    "ييبينسيل",
    "العدسات",
    "يير",
    "ييرس",
    "القذى",
    "ييسوريس",
    "حسور",
    "ييتيث",
    "العيان",
    "شهود",
    "حزقيال",
    "عزرا",
    "إله",
    "اف",
    "الفدرالي",
    "فيبر",
    "فابي",
    "الخرافة",
    "إسطوري",
    "الرمزية",
    "الخرافات",
    "الرمزي",
    "إصنع",
    "الإفتراءات",
    "المفتري",
    "الأنسجة",
    "الواجهة",
    "الواجهات",
    "ووجه",
    "الجهل",
    "الصفعة",
    "الوجوه",
    "النحت",
    "الطرافة",
    "تجميل",
    "وجهيا",
    "مسهل",
    "يسهل",
    "التسهيل",
    "تسهيلي",
    "الوساطة",
    "الوسائل",
    "الفئة",
    "فئوي",
    "الفئوية",
    "الفئات",
    "فاكتو",
    "المضروب",
    "المصانع",
    "للعوامل",
    "المصنع",
    "واقعي",
    "الحقيقية",
    "الواقعية",
    "الإبتداعيون",
    "إبهت",
    "مبهوت",
    "بتضاءل",
    "الاختفاء",
    "التدريجي",
    "ترويسة",
    "يبهت",
    "البهت",
    "الإبتعاد",
    "التضاؤلات",
    "برازي",
    "الجن",
    "فافنر",
    "تنين",
    "الحطب",
    "رواية",
    "\"أوليفر",
    "تويست\"",
    "فاهي",
    "الفهرنهايتية",
    "مغيب",
    "بضعف",
    "النحيلون",
    "فيربانكس",
    "فيرتشايلد",
    "فير",
    "فيريد",
    "أعدل",
    "فيرفاكس",
    "فيرفيلد",
    "الجنيات",
    "هدية",
    "فيربورت",
    "فيرفيو",
    "السالك",
    "السالكة",
    "دنيا",
    "الخيال",
    "حوري",
    "بإخلاص",
    "الملفقون",
    "المزيفون",
    "الفلافل",
    "صيادي",
    "فالك",
    "المغالطات",
    "المغالطة",
    "الإحتياطي",
    "الساقط",
    "معصوم",
    "العرضة",
    "لإرتكاب",
    "بتعرض",
    "المحروثة",
    "ترك",
    "حرثها",
    "الإنهيارات",
    "فالماوث",
    "البطلان",
    "الزور",
    "المزيف",
    "يزيف",
    "زيف",
    "فالستاف",
    "ماجنة",
    "مسرحيات",
    "شكسبير",
    "فالترير",
    "بتردد",
    "يتعثر",
    "الشهرةشهرة",
    "عائلي",
    "التآلفات",
    "التآلف",
    "المعرف",
    "يآلف",
    "المؤالفة",
    "حميمة",
    "العوائل",
    "عقيدة",
    "المللة",
    "\"فاميلي\"",
    "فاملي",
    "المجاعة",
    "المجاعات",
    "فامنج",
    "جوع",
    "مجوع",
    "يجوع",
    "التجويع",
    "إنفتح",
    "بتعصب",
    "محب",
    "يحب",
    "بهرجة",
    "وهميا",
    "فخامة",
    "بابتكار",
    "الفخامة",
    "الهوى",
    "الإستعراض",
    "التبويق",
    "بمخالب",
    "مهوى",
    "فاني",
    "الأنصار",
    "الفنتازيا",
    "استغرق",
    "التخيلات",
    "تخيل",
    "متخيل",
    "يتخيل",
    "التخيل",
    "المتخيل",
    "الأسئلة",
    "المكررة",
    "الفاراد",
    "فاراداي",
    "الفارادات",
    "فاربر",
    "المهازل",
    "الحشوة",
    "فريد",
    "فارير",
    "اللامعقولية",
    "منطقية",
    "فارجو",
    "فارينا",
    "فاركاس",
    "فارلي",
    "المزرعة",
    "فالح",
    "البيت",
    "الريفي",
    "الريفية",
    "فارمنجتون",
    "الزراعية",
    "وتوابعها",
    "مساكن",
    "عمال",
    "الزرائب",
    "فارنسورث",
    "فارو",
    "الحباشات",
    "نفوذ",
    "متنفذ",
    "فاريل",
    "مخنص",
    "التخنيص",
    "صغار",
    "الفارسية",
    "إيراني",
    "نظر",
    "ربع",
    "البنس",
    "فاسيا",
    "الكتاب)",
    "محزم",
    "بحزمة",
    "بالحزمة",
    "يحزم",
    "التحزم",
    "الفاشية",
    "الفاشي",
    "فاشي",
    "فاشية",
    "الفاشيون",
    "الأسلوب",
    "عصري",
    "عصريا",
    "المرشد",
    "يصوم",
    "الإنشطار",
    "بسقف",
    "منحني",
    "مؤخرتها",
    "إربط",
    "السحابة",
    "السحابات",
    "الصوم",
    "قاتل",
    "الجبرية",
    "الجبري",
    "الجبريون",
    "الضحايا",
    "الضحية",
    "المصير",
    "أبا",
    "الإبوة",
    "الصبح",
    "يتيم",
    "اﻷبوة",
    "أبوي",
    "الزوجة",
    "القامة",
    "للقياس",
    "القامات",
    "التعب",
    "متعوب",
    "فاطمة",
    "السمينة",
    "أسمن",
    "سمن",
    "مسمن",
    "المسمن",
    "المسمنات",
    "التسمين",
    "يسمن",
    "المملوئين",
    "ببدانة",
    "السخافة",
    "الحنفية",
    "الحنفيات",
    "فولكنر",
    "الفولكنريون",
    "منكفئا",
    "التعييب",
    "عيب",
    "الغابات",
    "المهندم",
    "المهذب",
    "فوست",
    "فاوست",
    "الأفضلية",
    "التفضيل",
    "إيجابيا",
    "المفضل",
    "المفضلون",
    "المفضلة",
    "التفضيلية",
    "الحسنات",
    "فوكيز",
    "متودد",
    "فاونير",
    "التودد",
    "الظبيان",
    "الفاكس",
    "الفاكسات",
    "فايت",
    "فايتيفيل",
    "الفدرالية",
    "الخائف",
    "بتخوف",
    "فيسابل",
    "الجدوى",
    "فيستر",
    "الريشي",
    "إحتياجات",
    "البطالة",
    "المقنعة",
    "الأفرشة",
    "الريشية",
    "الترييش",
    "الريش",
    "فيثيرتوب",
    "ريشي",
    "فبراير/شباط",
    "حمي",
    "\"فبراير\"",
    "خصب",
    "يلقح",
    "الخصب",
    "فيديرس",
    "إتحادي",
    "الإتحادية",
    "الإتحادي",
    "الإتحاديون",
    "الفيدراليون",
    "فيدورا",
    "(قبعة",
    "(قبعات",
    "المحققون",
    "الغذاء",
    "الردود",
    "فيديد",
    "الطاعم",
    "الطاعمون",
    "الإطعام",
    "الملمس",
    "اللامس",
    "اللوامس",
    "الشعور",
    "المشاعر",
    "فيني",
    "إختلق",
    "الإختلاق",
    "يختلق",
    "فيلدر",
    "فيلدمان",
    "الفلسبار",
    "فيليس",
    "فيليسيا",
    "الهناء",
    "فيليسيتي",
    "الماكر",
    "سنوري",
    "الماكرون",
    "فيليكس",
    "فيلا",
    "أولاد",
    "للعضو",
    "قاطعو",
    "فيليني",
    "الزمالات",
    "فيلسيت",
    "لبد",
    "أحس",
    "رأسا",
    "ملبد",
    "التلبيد",
    "اللبادات",
    "الأنثى",
    "الأنوثة",
    "الإناث",
    "المؤنث",
    "أنثويا",
    "مساواة",
    "الجنسين",
    "بمساواة",
    "إمرأة",
    "فخذي",
    "الفين",
    "السياج",
    "مسيج",
    "الأسيجة",
    "صن",
    "مصان",
    "يصون",
    "التثقب",
    "فينيل",
    "مستنقعي",
    "الفينات",
    "فينتون",
    "فينوجريك",
    "فينويك",
    "المدفوع",
    "لة",
    "فيربير",
    "فيردناند",
    "فردناندو",
    "فيرغسن",
    "فرمات",
    "الخميرة",
    "التخمر",
    "للتخمر",
    "الإختمار",
    "متخمر",
    "التخمير",
    "الخمائر",
    "فرمي",
    "فيرميون",
    "لإحصاءات",
    "فيرميديراك",
    "الفيرميوم",
    "فرناندو",
    "زرع",
    "السراخس",
    "بالسرخس",
    "فيريرا",
    "النمس",
    "الفيريت",
    "النموس",
    "فيريس",
    "الفريت",
    "المسلح",
    "بالعازل",
    "الشفاف",
    "مغناطيس",
    "الحديدية",
    "بالمغناطيس",
    "الحديدي",
    "بمقبض",
    "عبارة",
    "مسؤولو",
    "فرتيج",
    "فرتيلينيس",
    "الخصوبة",
    "الإخصاب",
    "مخصب",
    "المخصب",
    "المخصبات",
    "يخصب",
    "التخصيب",
    "التسخين",
    "التأجج",
    "العكرش",
    "فيس",
    "المهرجان",
    "القيح",
    "متقيح",
    "التقيح",
    "المهرجانات",
    "إجلب",
    "مجلوب",
    "الجالب",
    "يجلب",
    "الجلب",
    "مكرم",
    "نتن",
    "الإكرام",
    "الوثن",
    "الأوثان",
    "نتوء",
    "إقطاعي",
    "الإقطاعية",
    "محموم",
    "التحمية",
    "القليل",
    "أقل",
    "الخطيبة",
    "الخطيبات",
    "التام",
    "فيات",
    "الإكذوبة",
    "الكذاب",
    "الليف",
    "الليفي",
    "فيبيريد",
    "فيبيرجلاس",
    "(علامة",
    "تجارية)",
    "الألياف",
    "الزجاجية",
    "فيبوناكسي",
    "فيبريل",
    "الفايبرين",
    "ليفي",
    "الليفية",
    "الأكاذيب",
    "الشظية",
    "ميكروفيش",
    "البنيقة",
    "زائفا",
    "إخلاص",
    "فيديل",
    "الوفاء",
    "الململة",
    "متململ",
    "التململ",
    "تأثير",
    "أجيب",
    "(متلقي",
    "الضربات)",
    "(متلقو",
    "(تلقي",
    "الحقول",
    "فيلدستون",
    "الميداني",
    "الباحثون",
    "الميدانيون",
    "عنيف",
    "بعنف",
    "أعنف",
    "ناري",
    "فايف",
    "الناي",
    "حجم",
    "الطابور",
    "خامس",
    "الخمس",
    "خامسا",
    "الأخماس",
    "الخمسينات",
    "خمسون",
    "مناصفة",
    "التينة",
    "فيجارو",
    "التين",
    "النموذجية",
    "رياضة",
    "التزحلق",
    "فيجي",
    "الشعيرة",
    "شعيري",
    "الشعيرات",
    "البندقة",
    "البندق",
    "إسرق",
    "مسروق",
    "لص",
    "لصوص",
    "يسرق",
    "ملف",
    "أسماء",
    "ملفخروج",
    "ملفات",
    "أنظمة",
    "الشريحة",
    "الشرائح",
    "بنوي",
    "المماطلة",
    "السياسية",
    "الثوري",
    "المماطلات",
    "التخريمية",
    "الفلبيني",
    "الفلبينيون",
    "فيليبو",
    "الملء",
    "فيلابل",
    "مملوء",
    "الفيلير",
    "الحشو",
    "معصب",
    "التعصيب",
    "المهرات",
    "الحشوات",
    "الحافز",
    "الحوافز",
    "الفرزة",
    "فيلمور",
    "إملأ",
    "المهرة",
    "الفلم",
    "عارض",
    "فلم",
    "غشائية",
    "غشائيا",
    "فيلماك",
    "الأفلام",
    "سينمائية",
    "ترشح",
    "المصفي",
    "المرشحات",
    "رشح",
    "يرشح",
    "الزعنفة",
    "بالخدعة",
    "منهى",
    "أخيرا",
    "النهائيات",
    "مالي",
    "ماليا",
    "المالي",
    "الماليون",
    "فينتش",
    "البرقش",
    "للإيجاد",
    "الغرامة",
    "مغرم",
    "رفيع",
    "أدق",
    "التبرج",
    "الغرامات",
    "البداعة",
    "أجود",
    "الإصبع",
    "المشير",
    "باستعمال",
    "الأظفر",
    "الأظافر",
    "الأصبعي",
    "الأصبعية",
    "بصمة",
    "بصم",
    "باصبعه",
    "بصمات",
    "بكسل",
    "التغريم",
    "المنهي",
    "القائمون",
    "محدود",
    "المحدودية",
    "فلندا",
    "فينلاي",
    "الفنلندي",
    "فيننيجان",
    "فلندي",
    "الفنلنديون",
    "مزعنف",
    "الزعانف",
    "فيوريلو",
    "فيوري",
    "التنوب",
    "تدريبات",
    "للوقاية",
    "للحريق",
    "الناري",
    "النارية",
    "إنتشار",
    "بهوس",
    "الإحراق",
    "مشعلو",
    "الطرقة",
    "الطرقات",
    "المناجم",
    "غازات",
    "لدعم",
    "المدفأة",
    "بالأسلحة",
    "اليراعات",
    "اليراع",
    "حواجز",
    "محطات",
    "التأمينات",
    "المشعل",
    "الولاعات",
    "الموقد",
    "المواقد",
    "حنفية",
    "حنفيات",
    "النلر",
    "فيرستون",
    "أرباع",
    "السماوات",
    "بحزم",
    "الدائم",
    "الدائمة",
    "الباكورة",
    "الخور",
    "الخوران",
    "فيسكبين",
    "فيشير",
    "إشعاعي",
    "مصيد",
    "فيشر",
    "الثروات",
    "السمكية",
    "صيادو",
    "فيشيرز",
    "الأسماك",
    "الخطاف",
    "الخطافات",
    "الريبة",
    "فيشكيل",
    "فيشميل",
    "ماسكة",
    "بركة",
    "بائعات",
    "بائعة",
    "فيسك",
    "إنشطاري",
    "الانشطار",
    "الإنفلاق",
    "فيسشنابيليتي",
    "إنشطر",
    "الإنفلاقات",
    "منشق",
    "القبضة",
    "الإطباق",
    "ملاكمة",
    "بالأيدي",
    "ملاكمات",
    "القبضات",
    "الناسور",
    "النواسير",
    "النوبة",
    "فيتش",
    "فيتشبيرغ",
    "لائق",
    "اللياقات",
    "النوبات",
    "الميكانيكي",
    "الميكانيكيون",
    "أصلح",
    "فيتنيس",
    "فيزجيرالد",
    "فيزباتريك",
    "فيزروي",
    "الخمسة",
    "باونات",
    "الخمسات",
    "المأزق",
    "للتثبيت",
    "ثبت",
    "التعلقات",
    "المثبتات",
    "الثابتة",
    "الإصلاح",
    "المثبتون",
    "المآزق",
    "إصلاحات",
    "فيزيو",
    "الفاشل",
    "أخفق",
    "يخفق",
    "هساس",
    "المضايق",
    "فلوريدا",
    "فلاب",
    "إندهش",
    "الإندهاش",
    "يندهش",
    "ترهلا",
    "الترهل",
    "مترهل",
    "التهاون",
    "الليونة",
    "فلاك",
    "القنينة",
    "سوطي",
    "مجلود",
    "مأشر",
    "أثيم",
    "فلاجلر",
    "الإباريق",
    "سارية",
    "ساريات",
    "البلاطة",
    "البلاط",
    "المدراس",
    "المدراسات",
    "الذوق",
    "فلاكير",
    "مقشر",
    "الأكذوبة",
    "مبهرج",
    "فلامين",
    "للهب",
    "المشعلات",
    "قاذف",
    "قاذفات",
    "النحام",
    "للاشتعال",
    "للإشتعال",
    "فلانجان",
    "فلانديرز",
    "مشفه",
    "التشفيه",
    "الجناح",
    "حماة",
    "الأجنحة",
    "الفانيلا",
    "الفانيلات",
    "خافق",
    "الخفقان",
    "الضوئية",
    "الإندلاع",
    "مندلع",
    "الوميض",
    "الإرتجاع",
    "الكاميرا",
    "الكاميرات",
    "الفلاش",
    "الفلاشات",
    "مومض",
    "الوماض",
    "المتعري",
    "الوماضات",
    "المتعرون",
    "الومضات",
    "البهرجة",
    "اللمعان",
    "المصباح",
    "المصابيح",
    "الكاشفة",
    "ومضة",
    "ومضات",
    "القارورة",
    "القوارير",
    "الشاحنة",
    "المسطحة",
    "فاتهيد",
    "هندية",
    "المكواة",
    "المكاوي",
    "شقة",
    "شقق",
    "فلاتنيس",
    "مسطح",
    "فلاتينير",
    "التسطيح",
    "يسطح",
    "المتزلف",
    "المتزلفون",
    "بتملق",
    "تسطحا",
    "نوعا",
    "القصير",
    "إنتفاخ",
    "الغازات",
    "مكونات",
    "السطحية",
    "المباهاة",
    "بكبرياء",
    "يتكبر",
    "فلونتي",
    "الفلوت",
    "النكهة",
    "منكه",
    "بطعم",
    "النكهات",
    "طعم",
    "الكتان",
    "كتاني",
    "فلي",
    "المشرط",
    "المشارط",
    "وورت",
    "(عشب",
    "طبي)",
    "يبقع",
    "الثني",
    "إنثنائي",
    "إنثناءات",
    "يريش",
    "مجزوز",
    "الأصواف",
    "بالصوف",
    "الجز",
    "فليد",
    "الأسطول",
    "زائلا",
    "الأساطيل",
    "فليشمان",
    "فليشر",
    "الفلاندري",
    "الفلمنجيون",
    "فلمنكي",
    "باللحم",
    "بنطلونات",
    "بلون",
    "الحسي",
    "بريش",
    "طار",
    "الشفة",
    "مثني",
    "بمرونة",
    "الإنحناءات",
    "المرنة",
    "متعرج",
    "تعرجي",
    "الانثناء",
    "النقرة",
    "حرك",
    "الرجفة",
    "الإيماض",
    "مضطربة",
    "الرجفات",
    "طيشا",
    "يطير",
    "الرحلات",
    "طائش",
    "الجفلة",
    "جافل",
    "المنتفض",
    "الجفلات",
    "الجفل",
    "باجفال",
    "المؤقتة",
    "مؤقتة",
    "الصوان",
    "متصلب",
    "أمان",
    "تقلب",
    "يرجع",
    "ذلاقة",
    "ثرثار",
    "مقلب",
    "التقليب",
    "اللعوب",
    "مغازل",
    "الخلاعة",
    "متغازل",
    "فليرتر",
    "التغازل",
    "اللعوبون",
    "يرفرف",
    "مرفرف",
    "الرفرفة",
    "سهم",
    "للطفو",
    "التعويم",
    "معام",
    "العوام",
    "العوم",
    "العائمة",
    "الشوائب",
    "صوف",
    "مخصل",
    "تلبد",
    "متلبد",
    "يتلبد",
    "التلبد",
    "خصلة",
    "القطعان",
    "الطفاوات",
    "إجلد",
    "الفيضان",
    "بالفيضان",
    "فلودر",
    "البوابة",
    "البوابات",
    "الفيضانات",
    "الأضواء",
    "الأرضية",
    "بلط",
    "الأرضيات",
    "أرضية",
    "فندق",
    "فنادق",
    "رديئة",
    "متخبط",
    "مرونة",
    "فلوبيس",
    "المرن",
    "يتخبط",
    "زهري",
    "فلورينس",
    "فلورنسي",
    "(إيطاليا)",
    "تنبيت",
    "الأزهار",
    "مواطنو",
    "التورد",
    "الفلورين",
    "الخيط",
    "نظف",
    "الخيوط",
    "خيطي",
    "فلوتاج",
    "فلوتاجيس",
    "الطافية",
    "الإنتفاضة",
    "منتفض",
    "الإنتفاضات",
    "الإنتفاض",
    "المفلطح",
    "المفلطحة",
    "الإزدهار",
    "مخروق",
    "المزدري",
    "تدفق",
    "الإنسيابي",
    "فلوتشارتينج",
    "الإنسيابية",
    "المزهر",
    "الزهورية",
    "الزهرية",
    "الزهريات",
    "وردي",
    "مسترسل",
    "التكلس",
    "فلويد",
    "الإنفلونزا",
    "يخطيء",
    "يخطىء",
    "يتقلب",
    "التقلبات",
    "الطلاقة",
    "طليق",
    "بطلاقة",
    "منفوش",
    "نفشا",
    "النفش",
    "ينفش",
    "والتدفق",
    "السيولة",
    "سائلا",
    "محظوظ",
    "إفشل",
    "المرسب",
    "الإمعة",
    "يفشل",
    "إستشع",
    "مستشع",
    "فلوريسسيين",
    "الإسفار",
    "الفلوريسنت",
    "الفلوريسسيين",
    "يستشع",
    "الإستشعاع",
    "فلوري",
    "يعالج",
    "بالفلور",
    "عالج",
    "المعالجات",
    "الفلوريد",
    "بمستوى",
    "الفلور",
    "الفلوريت",
    "الفلوروكاربون",
    "الفلوري",
    "مضطرب",
    "الإحمرار",
    "مهيج",
    "التهيج",
    "مزمر",
    "المزامير",
    "التزمير",
    "المزمر",
    "المزمرون",
    "الإرتجاف",
    "المرفرف",
    "برفرفة",
    "الجريان",
    "مذاب",
    "إنسدادي",
    "بالسلك",
    "مصيدة",
    "مصائد",
    "الفارغة",
    "الصفحات",
    "فلن",
    "صائدة",
    "صائدات",
    "المهاجرة",
    "الحذافة",
    "الحذافات",
    "إف",
    "تشكيل",
    "ولد",
    "مهرا",
    "فولفوت",
    "ولادة",
    "الرغوة",
    "مرغى",
    "رغوي",
    "فومفلاور",
    "الإرغاء",
    "برغوة",
    "الرغو",
    "البؤرية",
    "مراكز",
    "فوجارتاي",
    "مصممة",
    "أزياء",
    "فوجيس",
    "الممل",
    "مضبب",
    "ضبابا",
    "ضبابي",
    "الضبابيات",
    "التضبيب",
    "صافرة",
    "صافرات",
    "يضبب",
    "النواقص",
    "القصدير",
    "دس",
    "مدسوس",
    "الدس",
    "يدس",
    "الطية",
    "للطوي",
    "الحافظة",
    "للأوراق",
    "مجلد",
    "الحافظات",
    "الطي",
    "المطوية",
    "الطيات",
    "فولي",
    "فولج",
    "بأوراق",
    "مورق",
    "يورق",
    "التوريق",
    "القوم",
    "الفولكلور",
    "فولكروك",
    "المسام",
    "مسامي",
    "المتابعة",
    "يتابع",
    "المتابعات",
    "فولسوم",
    "فم",
    "المحرضون",
    "ولعا",
    "لاطف",
    "ملاطف",
    "الحنون",
    "الملاطفة",
    "الفانديو",
    "(طبق",
    "والزبدة)",
    "فنتنبلو",
    "فونتانا",
    "الأغذية",
    "الغذائية",
    "فوليريس",
    "بتهور",
    "التهور",
    "متهور",
    "الغباوة",
    "الحمقى",
    "كور",
    "خدم",
    "(أولاد)",
    "جسور",
    "فوت",
    "أقدام",
    "الهامش",
    "الهوامش",
    "موطئ",
    "مواطئ",
    "الموطئ",
    "المواطئ",
    "يتلهى",
    "صفوف",
    "أمتعة",
    "خزانات",
    "فوتنوتينج",
    "الممشى",
    "للمشاة",
    "إستراحة",
    "موجع",
    "الخطوة",
    "الخطوات",
    "القدمين",
    "إعبث",
    "يعبث",
    "فوب",
    "المتأنقون",
    "للبيان",
    "فورا",
    "معلوف",
    "العلاف",
    "العلافون",
    "الأعلاف",
    "الغزوة",
    "الغازي",
    "الغزوات",
    "إمتنع",
    "المسلف",
    "(أﻵباء",
    "واﻷجداد)",
    "الإمتناع",
    "فوربز",
    "محرم",
    "التحريم",
    "القهري",
    "كالحروب",
    "إجباري",
    "بقسر",
    "المبضع",
    "الفارضون",
    "القوات",
    "بالقوة",
    "فورد",
    "ضحل",
    "فوردام",
    "أنذر",
    "منذر",
    "المتنبئ",
    "المتنبؤون",
    "ينذر",
    "منذرا",
    "بالشر",
    "الشريرة",
    "نذر",
    "المتوقعون",
    "التنبؤ",
    "التعويق",
    "التعويقات",
    "مقدمة",
    "مقدمات",
    "السبابة",
    "السبابات",
    "الطليعة",
    "الطلائع",
    "تخل",
    "المقدمات",
    "مالية",
    "الجبهة",
    "الجباه",
    "الأمامي",
    "أجنبي",
    "إحكم",
    "محكوم",
    "إحدس",
    "المسبقة",
    "يحدس",
    "رئيسات",
    "رئيسة",
    "الناصية",
    "فورمان",
    "الصاري",
    "الصواري",
    "الاول",
    "الأولية",
    "الضحى",
    "عدلي",
    "عدليا",
    "الجنائية",
    "مسبق",
    "مواعيد",
    "مسبقة",
    "الحقبة",
    "الحقب",
    "الشراع",
    "الأشرعة",
    "حدس",
    "المنظورية",
    "منظور",
    "البصيرة",
    "المستقبلية",
    "البصائر",
    "القلفة",
    "القلفات",
    "السابقة",
    "العراف",
    "التنبأ",
    "التدبر",
    "الماضي",
    "إنذار",
    "الهامة",
    "الأبدية",
    "انخفاضا",
    "للشراع",
    "للإستسلام",
    "خاسر",
    "المستسلم",
    "المستسلمون",
    "الخسارة",
    "إحم",
    "محمي",
    "يحمي",
    "يفرع",
    "للتزوير",
    "مزور",
    "المزورون",
    "دكاكين",
    "إنس",
    "النسيان",
    "ينسي",
    "للنسيان",
    "للعفو",
    "إغفر",
    "مغفور",
    "المغفرة",
    "المسامح",
    "نسى",
    "منسي",
    "التسرع",
    "متشعب",
    "فوركس",
    "الفراق",
    "للتشكيل",
    "الثوب",
    "فورمالديهايد",
    "الفورمالين",
    "الشكلية",
    "الشكليون",
    "الشكليات",
    "الصائغ",
    "المعبر",
    "رسميا",
    "صفات",
    "فورميت",
    "فورماتيز",
    "الصيغ",
    "مهيأ",
    "التهيئة",
    "المشكلون",
    "فورمز",
    "لتأليف",
    "نملي",
    "الفورميكا",
    "يقهر",
    "فورموسا",
    "فورموسان",
    "بتايوان",
    "الأشكال",
    "معادلي",
    "السوابق",
    "صيغي",
    "صغ",
    "يصوغ",
    "الصياغات",
    "المعادلون",
    "إزن",
    "فورست",
    "حقا",
    "بنبذه",
    "بنبذ",
    "فورسيذي",
    "البراعات",
    "فورتسكو",
    "فصاعدا",
    "فورتير",
    "الأربعينات",
    "أربعون",
    "أربعين",
    "للتحصين",
    "التحصينات",
    "المحصن",
    "المحصنات",
    "يحصن",
    "الشديد",
    "الإسبوعان",
    "إسبوعين",
    "فورتران",
    "المحظوظين",
    "الثروة",
    "قارئ",
    "المنتدى",
    "المنتديات",
    "يعزز",
    "الشاحنون",
    "قدما",
    "فوس",
    "متحجر",
    "التحجر",
    "محجر",
    "يحجر",
    "المتحجرات",
    "أحفوري",
    "قوتل",
    "الفولار",
    "قذارة",
    "البذاءة",
    "وجد",
    "المعال",
    "المعالون",
    "مؤسس",
    "المؤسسون",
    "اللقيط",
    "اللقطاء",
    "المسابك",
    "المسبك",
    "الينبوع",
    "النافورة",
    "المنبع",
    "المنابع",
    "النافورات",
    "الينابيع",
    "أربعة",
    "فوريير",
    "الأربعات",
    "الثمانون",
    "اللعبة",
    "الرباعية",
    "مربع",
    "رابع",
    "الربع",
    "رابعا",
    "الأرباع",
    "فوتين",
    "بنقر",
    "منقر",
    "مرتكب",
    "الثعلب",
    "قفازات",
    "فوكسهال",
    "جحر",
    "جحور",
    "مكرا",
    "الفوكستروت",
    "ماكر",
    "الإستراحة",
    "النمطي",
    "هندسي",
    "متكرر",
    "كسري",
    "جزئ",
    "يجزيء",
    "المشاكسة",
    "مكسور",
    "متجزأ",
    "الجزئية",
    "التجزؤ",
    "ممزق",
    "التمزيق",
    "العطور",
    "رائحي",
    "دفاعية",
    "فرامبيسيا",
    "معدي)",
    "مؤطر",
    "إطارمجموعة",
    "إطار",
    "المبروز",
    "المبروزون",
    "التأطير",
    "البراويز",
    "فران",
    "الفرنك",
    "فرانكا",
    "فرنسا",
    "فرانسيس",
    "فرانسيسكا",
    "فرانسيسكو",
    "فرانسي",
    "فرانسين",
    "فرنسيس",
    "الفرانسيسكاني",
    "الفرانسيسكانيون",
    "الفرنسيوم",
    "فرانكو",
    "فرانسوا",
    "الفرنكات",
    "فرانكتيرير",
    "فرانجيباني",
    "(شجيرة)",
    "فرانك",
    "مدموغ",
    "فرانكل",
    "فرانكفورد",
    "فرانكفورت",
    "سجقة",
    "فرانكفورتير",
    "سجق",
    "فرانكي",
    "الدمغ",
    "فرانكلين",
    "الفرانكلين",
    "بريد",
    "فراني",
    "مسعور",
    "فرانز",
    "فريزر",
    "نادي",
    "الإخاء",
    "تآخ",
    "متآخى",
    "للنادي",
    "المنتسبون",
    "يتآخي",
    "التآخي",
    "فراتز",
    "قتل",
    "بإحتيال",
    "الإبلاء",
    "فرين",
    "يبلي",
    "فرايزر",
    "أبل",
    "مبلى",
    "النمشة",
    "منمش",
    "النمش",
    "التنميش",
    "فريدي",
    "فريدريك",
    "فريدريكس",
    "فريدريكسبيرغ",
    "فريدريكو",
    "فريدريكتون",
    "فريدولم",
    "فريدريكسون",
    "الحرة",
    "للأرض",
    "الهدية",
    "الترويجية",
    "الحرية",
    "فريمان",
    "الماسوني",
    "الماسونية",
    "الماسونيون",
    "الأحرار",
    "فريبورت",
    "حرية",
    "صخرة",
    "صخور",
    "المفكر",
    "المفكرون",
    "فري",
    "تون",
    "المجاني",
    "حرة",
    "للتجميد",
    "الإنجماد",
    "المجمدات",
    "يتجمد",
    "فريدا",
    "الهيجان",
    "فريون",
    "تردد",
    "يتردد",
    "الجصي",
    "الجصية",
    "أنعش",
    "المعطر",
    "المعطرات",
    "أعذب",
    "المستجدون",
    "الطراوة",
    "العذب",
    "فريسنل",
    "فريسنو",
    "الحنق",
    "مشاكس",
    "النقاق",
    "فرويد",
    "فرويدي",
    "فراي",
    "(إلة",
    "الخصوبة)",
    "فريا",
    "الجمعة",
    "التفتييت",
    "خبرة",
    "الرهبان",
    "المقلي",
    "المقلية",
    "إحتكاكي",
    "الإحتكاكيون",
    "فريك",
    "الإحتكاك",
    "الاحتكاك",
    "أيام",
    "الثلاجة",
    "الثلاجات",
    "مقلي",
    "فريدمان",
    "أصدقاء",
    "ودية",
    "القالي",
    "يقلي",
    "الأفاريز",
    "الفرقاطة",
    "الفرقاطات",
    "فريجا",
    "الجنة)",
    "التخويف",
    "يخوف",
    "فريجيدير",
    "ببرود",
    "المكسيكي.",
    "فريجوليلو.",
    "للماشية",
    "الرتوش",
    "مكشكش",
    "مهدب",
    "التهديب",
    "الفريسبي",
    "طافر",
    "المفتش",
    "الطفر",
    "يطفر",
    "إطل",
    "حشيشة",
    "الحجل",
    "فريتو",
    "مبدد",
    "فريز",
    "المجعد",
    "مقلى",
    "التقلية",
    "قل",
    "لبست",
    "لبس",
    "الفساتين",
    "بالضفادع",
    "مارح",
    "فرحا",
    "السعفة",
    "أماميا",
    "الرئيسي",
    "واجهه",
    "عصابات",
    "الجبهات",
    "فروست",
    "عضة",
    "الثلج",
    "لسعة",
    "أكله",
    "متجمد",
    "تجمدا",
    "التجميد",
    "الزبد",
    "مزبد",
    "زبدا",
    "الإزباد",
    "يزبد",
    "التجهم",
    "معبس",
    "فراونير",
    "التعبيس",
    "بعبوس",
    "التجهمات",
    "عفن",
    "تجمد",
    "مجمد",
    "يثمر",
    "أثمر",
    "الإثمار",
    "الفواكه",
    "فريهوف",
    "الغرباء",
    "الثمار",
    "أطعم",
    "الحرفوش",
    "رثاثة",
    "للخيبة",
    "المقلاة",
    "القلي",
    "الفيدرالية",
    "انتقال",
    "فيوكس",
    "الفوشيه",
    "بالعامية",
    "يستغل",
    "إستغل",
    "مقتاء",
    "ممارسو",
    "إثمل",
    "ثامل",
    "يثمل",
    "الثمل",
    "المزود",
    "بالوقود",
    "المزودون",
    "متسلسل",
    "هروبا",
    "فوجي",
    "فوجيتسو",
    "فلبريت",
    "الإرتكاز",
    "الشاشة",
    "الفيديو",
    "الكامل",
    "فولير",
    "فوليرتون",
    "أكمل",
    "تنظيف",
    "وتقليص",
    "الإمتلاء",
    "فولسومينيس",
    "فولتن",
    "خليط",
    "والبني",
    "فوم",
    "تحسس",
    "متحسس",
    "المتخبطون",
    "يتحسس",
    "التحسس",
    "الدخان",
    "مدخن",
    "الأدخنة",
    "دخن",
    "يدخن",
    "التدخين",
    "مبيدو",
    "الوظيفة",
    "وظيفي",
    "المنفعة",
    "نفعي",
    "الوظائف",
    "الموظفون",
    "الموظف",
    "مشتغل",
    "الإشتغال",
    "الأصولية",
    "الأصوليون",
    "الأساسيات",
    "الجنازة",
    "الجنائز",
    "جنائزي",
    "فطري",
    "للإستبدال",
    "بقتل",
    "الفطريات",
    "حبلي",
    "الكريهة",
    "قمعي",
    "القموع",
    "تسلية",
    "مضحك",
    "للأوقات",
    "السعيدة",
    "الفراء",
    "فوران",
    "جدد",
    "مجدد",
    "المجدد",
    "يجدد",
    "التجديد",
    "بحيوية",
    "بشراسة",
    "الفرلنغ",
    "الإجازة",
    "مجاز",
    "الإجازات",
    "فرمان",
    "الفرن",
    "الأفران",
    "فرنيس",
    "أثث",
    "يؤثث",
    "التأثيث",
    "تجار",
    "بالفرو",
    "مثلم",
    "التثليم",
    "مخفي",
    "خلسة",
    "القندول",
    "داكن",
    "المصهر",
    "مصهر",
    "فوسي",
    "المصاهر",
    "الإنصهار",
    "للإنصهار",
    "للصهر",
    "مغزلي",
    "الوابل",
    "الإهتمامات",
    "الفاضي",
    "الأسوأ",
    "تنورة",
    "بقدم",
    "بنتن",
    "النتانة",
    "المستقبلي",
    "مستقبلي",
    "المستقبليون",
    "بالمستقبل",
    "فوزيس",
    "ضبابية",
    "المعقوف",
    "بالمصطلح",
    "الاسكتلندي",
    "إناء",
    "لإحتواء",
    "إنعدام",
    "جي",
    "جورجيا",
    "المواقع",
    "الكبردين",
    "ببطىء",
    "المهذار",
    "المهذارون",
    "ثرثرة",
    "يتكلم",
    "الهذرمات",
    "غابرو",
    "غابي",
    "الجملون",
    "جملوني",
    "جابلر",
    "الجملونات",
    "غابون",
    "غابريل",
    "مهيم",
    "الهيام",
    "ذباب",
    "الخيل",
    "الجادولينيوم",
    "جادوال",
    "البط)",
    "الغيلية",
    "الغلطة",
    "المسن",
    "الكمامة",
    "الكيال",
    "العربونات",
    "الكمامات",
    "غايل",
    "الناعورة",
    "المكسب",
    "الرابح",
    "الرابحون",
    "جاينز",
    "غاينيسفيل",
    "مربح",
    "مربحا",
    "الكسب",
    "المكاسب",
    "المشية",
    "مشية",
    "الجرموق",
    "الجراميق",
    "غايثرسبيرغ",
    "المشيات",
    "غال",
    "جالاهاد",
    "مسرحية",
    "جزر",
    "الجالاباجوس",
    "قطن",
    "جالاتيا",
    "المجرات",
    "المجرة",
    "جالبريث",
    "جالينوس",
    "غالينا",
    "جالينايت",
    "الجليل",
    "غالاغير",
    "الشهم",
    "الكياسة",
    "جالبيري",
    "المرارة",
    "اللوحية",
    "بإزعاج",
    "السحنون",
    "الجاليوم",
    "الغالون",
    "بالجالون",
    "الغالونات",
    "الركض",
    "العدائون",
    "الجري",
    "غالواي",
    "المشنقة",
    "المشانق",
    "الصفراوات",
    "الحصاة",
    "جالويس",
    "الكالوش",
    "الكالوشات",
    "جالت",
    "بصدمة",
    "الجلفانومتر",
    "الجلفانومترات",
    "بجهاز",
    "جالفيستون",
    "جالفين",
    "جالواي",
    "غامبيا",
    "المناورة",
    "المناورات",
    "المقامرة",
    "مقامر",
    "المقامرون",
    "المقامرات",
    "ديك",
    "المصارعة",
    "ديوك",
    "اللعوبية",
    "جرثومية",
    "فساد",
    "الصبي",
    "اللعب",
    "الغاما",
    "غامير",
    "فخذ",
    "أفخاذ",
    "الشجاع",
    "أنحف",
    "الدروج",
    "الغنغرينا",
    "متعفن",
    "يتعفن",
    "التعفن",
    "غرغريني",
    "الأطيش",
    "جانيت",
    "جغرافي",
    "خرائط",
    "القفاز",
    "المؤقت",
    "جانيميد",
    "(أكبر",
    "أقمار",
    "المشتري)",
    "العمومي",
    "السجان",
    "السجانون",
    "التثاؤب",
    "مفغور",
    "المنبهر",
    "يفغر",
    "الفغر",
    "بانشداه",
    "غار",
    "المرآب",
    "الكراج",
    "المرائيب",
    "يركن",
    "الزي",
    "الزبالة",
    "غربل",
    "مغربل",
    "المغربل",
    "يغربل",
    "الغربلة",
    "غارسيا",
    "الحديقة",
    "البستاني",
    "البستانيون",
    "الغردينيا",
    "البستنة",
    "الحدائق",
    "جاردنر",
    "غارفيلد",
    "غارفانكيل",
    "الغرغرة",
    "مغرغر",
    "الجرغول",
    "مكلل",
    "ثومي",
    "الكساء",
    "ملبس",
    "التلبيس",
    "التكديس",
    "يكدس",
    "غارنيت",
    "الموجه",
    "المنذرون",
    "غاريت",
    "الحامية",
    "الحاميات",
    "خنق",
    "يخنق",
    "غاري",
    "غارث",
    "غارفي",
    "لتسرب",
    "جاسكوني",
    "غازي",
    "البليغ",
    "مجروح",
    "البليغة",
    "مغزز",
    "يغزز",
    "غزز",
    "الحشية",
    "الحشيات",
    "أضواء",
    "وقود",
    "الغازولين",
    "اللهيث",
    "اللهث",
    "مطلقو",
    "تسمم",
    "جاستون",
    "معوي",
    "تذوقي",
    "طهي",
    "بطنيات",
    "والحلزون",
    "الحلزونات",
    "فصيلتهم",
    "المنظار",
    "الطبي",
    "المعدة",
    "للمعدة",
    "بالمنظار",
    "جراحية",
    "بإيصال",
    "المتطفل",
    "أبواب",
    "جايتس",
    "الجوامع",
    "الشلالة",
    "المحاصرة",
    "جاتلينبرج",
    "غاتس",
    "غاتسبي",
    "أرعن",
    "الرعونة",
    "حفلة",
    "قياسه",
    "مقيس",
    "المعايرون",
    "المقاييس",
    "جوجون",
    "المسئول",
    "لمنطقة",
    "النازية",
    "غول",
    "نحيف",
    "بقفاز",
    "النحافة",
    "الغاوس",
    "بالغاوس",
    "الشاش",
    "المطرقة",
    "المطارق",
    "غافن",
    "حدق",
    "محدق",
    "الأخرق",
    "غاوكيلي",
    "التحديق",
    "يحدق",
    "اللوطي",
    "جيلور",
    "جيلورد",
    "النظرة",
    "شرفة",
    "شرفات",
    "الغزالة",
    "الغزال",
    "الناظرون",
    "النظرات",
    "الجريدة",
    "الجغرافي",
    "الجغرافية",
    "الجرائد",
    "جينيرال",
    "إليكتريك",
    "المسننات",
    "مسخر",
    "التسخير",
    "أذرع",
    "جيري",
    "بريص",
    "جيدانكين",
    "المهوس",
    "المهوسون",
    "رملية",
    "جافة",
    "جيجينشين",
    "جيهريج",
    "\"جيجر\"",
    "الراقصة",
    "اليابانية",
    "الراقصات",
    "اليابانيات",
    "الهلام",
    "الجيلاتين",
    "هيلم",
    "مهيلم",
    "يهيلم",
    "الهيلمة",
    "هلامي",
    "الجبلاتينية",
    "المخصي",
    "المخصية",
    "مثلج",
    "تبلور",
    "الكريم",
    "المجوهرات",
    "الكريمة",
    "الجندرمة",
    "الأنجاب",
    "الجين",
    "سلاسل",
    "الأخصائي",
    "الأنساب",
    "الأخصائيون",
    "الأنواع",
    "الجنرال",
    "اختصاصي",
    "اختصاصيون",
    "العموميات",
    "العمومية",
    "للتعميم",
    "عمم",
    "معمم",
    "المعمم",
    "المعممون",
    "يعمم",
    "الجنرالات",
    "يولد",
    "التوليد",
    "جيلي",
    "الأجيال",
    "المكارم",
    "بسخاء",
    "الجينات",
    "التكوين",
    "الرباح",
    "وراثي",
    "وراثيا",
    "الوراثة",
    "جنيف",
    "جينيفيف",
    "اللطيف",
    "جيني",
    "التناسلية",
    "اليهم",
    "العبقري",
    "العباقرة",
    "جنوى",
    "الإبادات",
    "اليهود",
    "الوثنيون",
    "هذب",
    "جينتليمانلنيس",
    "الراقية",
    "النبلاء",
    "إركع",
    "راكع",
    "الركوع",
    "يركع",
    "أصيل",
    "أرضي",
    "بكيميائية",
    "جيولوجية",
    "عمر",
    "الجيود",
    "(صخور)",
    "جيوديسي",
    "بقياسات",
    "محار",
    "جيفري",
    "الجغرافيون",
    "الجغرافيا",
    "جيولوجي",
    "الجيولوجي",
    "الجيولوجيون",
    "الجيومورفولوجي",
    "الجيومورفولوجيا",
    "جيوفيزيائي",
    "المتخصص",
    "بالجيوفيزياء",
    "جورج",
    "واشنطن",
    "للولايات",
    "الجورجي",
    "الجورجية",
    "الجورجيون",
    "حراري",
    "جيرالد",
    "جيرالدين",
    "جيرارد",
    "جيربير",
    "الجربوع",
    "جيرهارد",
    "شيخوخي",
    "الجرثومة",
    "وثيق",
    "جيرمانيا",
    "الجرمانيوم",
    "صار",
    "ألمانيا",
    "الألمان",
    "جيرمانتاون",
    "إنم",
    "نامي",
    "الإستنبات",
    "إستنباتي",
    "الإختصاصي",
    "بالشيخوخة",
    "الإختصاصيون",
    "جرشوين",
    "جيرترود",
    "مصدري",
    "الجشطالت",
    "الجستابو",
    "أومأ",
    "مومأ",
    "يأومأ",
    "الإيماء",
    "الإيماءات",
    "إيمائي",
    "البادرة",
    "المبادرات",
    "المهرب",
    "المهارب",
    "حاصل",
    "جيتي",
    "جيتيسبيرغ",
    "جيو",
    "السخان",
    "السخانات",
    "غانا",
    "غينت",
    "الخيارة",
    "الغيتو",
    "الشبح",
    "تحرك",
    "بخفة",
    "شبحي",
    "الشبحية",
    "الأشباح",
    "غولي",
    "جياكومو",
    "العملاقة",
    "غب",
    "ثرثر",
    "غيبون",
    "قردة",
    "الغيبون",
    "البروز",
    "جيبس",
    "الإستهزاء",
    "مستهزأ",
    "المتلفظ",
    "طارق",
    "جيبسن",
    "أصابه",
    "جيدينجز",
    "يصاب",
    "جديون",
    "جيفورد",
    "الهدايا",
    "جيجابيت",
    "الجيجابايت",
    "جيجاسيكل",
    "جيجاسيكليز",
    "جيجاهيرتز",
    "عملاق",
    "جيجوات",
    "الضاحك",
    "بقهقهة",
    "جل",
    "جيلا",
    "جلبرت",
    "جيلبيرتسون",
    "جيلكريست",
    "جيلدا",
    "التذهيب",
    "بالذهب",
    "يذهب",
    "جيليد",
    "تقع",
    "جيلز",
    "الخيشوم",
    "خيشومي",
    "جيلر",
    "جيليسبي",
    "جيليت",
    "جيليجان",
    "الخياشيم",
    "جيلمور",
    "جيمبال",
    "أفقي",
    "بجمبل",
    "تبقي",
    "جيمبل",
    "المثقب",
    "الأعرج",
    "بعرج",
    "جينا",
    "الزنجبيل",
    "تبل",
    "بالزنجبيل",
    "التتبيل",
    "الفائقة",
    "القطنية",
    "الجنكة",
    "حانة",
    "الكحولية",
    "جن",
    "جينو",
    "جينسبيرج",
    "جينسبيرغ",
    "الجينسنغ",
    "جيوكوندا",
    "جورجيو",
    "جيوفاني",
    "الغجر",
    "الغجري",
    "الزرافة",
    "الزرافات",
    "الزنرات",
    "التحزيم",
    "الصديقة",
    "الصديقات",
    "البنوتة",
    "الفحوى",
    "بوابة",
    "جيوليانو",
    "جيوسيب",
    "أعط",
    "معطى",
    "الأخذ",
    "المفروض",
    "الحوصلات",
    "جليدي",
    "جمد",
    "يجمد",
    "أفرح",
    "مفرح",
    "الإفراح",
    "يفرح",
    "أسعد",
    "الأسعد",
    "الفسح",
    "المصارع",
    "المصارعون",
    "الزنبق",
    "الزنابق",
    "بسرور",
    "جلاديس",
    "مجمل",
    "فتان",
    "الأبهار",
    "الجذابة",
    "الفتن",
    "اللمحة",
    "اللمحات",
    "السقاوة",
    "حشفة",
    "الوهج",
    "وهج",
    "التوهجات",
    "السطع",
    "جلاس",
    "غلاسكو",
    "الزجاج",
    "نافخ",
    "بزجاج",
    "الأقداح",
    "القدح",
    "شبها",
    "بالزجاج",
    "زجاج",
    "الزجاجيات",
    "الأشنان",
    "الغلاسكويي",
    "الزرق",
    "الأخضر",
    "الشاحب",
    "الصقيل",
    "مزجج",
    "الزجاجون",
    "يزجج",
    "التزجيج",
    "المقتنيات",
    "غليسون",
    "غلين",
    "غليندا",
    "غليندال",
    "جلين",
    "طلاقة",
    "عفويا",
    "المعسول",
    "غليدين",
    "الإنزلاق",
    "منزلق",
    "المنزلق",
    "المنزلقون",
    "الإنزلاقات",
    "التزلق",
    "وميض",
    "موموض",
    "يلمحون",
    "جامد",
    "الومض",
    "غليتر",
    "إشمت",
    "مشموت",
    "السعيد",
    "الشماتة",
    "يشمت",
    "العولمة",
    "يعولم",
    "غلوبالس",
    "الترحال",
    "التكوير",
    "الكروية",
    "الكرية",
    "الكريات",
    "الجلوبيولين",
    "كبيبي",
    "الغم",
    "إكتئابا",
    "التشاؤم",
    "غلوريا",
    "غلوريانا",
    "الأمجاد",
    "التمجيد",
    "المعظمون",
    "المجد",
    "المسارد",
    "المسرد",
    "ألمع",
    "لماع",
    "خطاب",
    "أجوف",
    "صورةلامع",
    "المزمار",
    "اللغات",
    "جلوكيستير",
    "قفاز",
    "القفازات",
    "بتوقد",
    "سراج",
    "الجلوكوز",
    "إلتصق",
    "المصمغ",
    "المصمغون",
    "الأصماغ",
    "التخمة",
    "جلتاميت",
    "الجلوتامين",
    "غروي",
    "التخم",
    "النهم",
    "فجعان",
    "نهم",
    "بشراهة",
    "الشراهة",
    "جليسيرايد",
    "الغليسرين",
    "غلسر",
    "مغلسر",
    "الجلسرين",
    "جليكاين",
    "الجليكوجين",
    "الجليكول",
    "إيثيلين",
    "المنقوش",
    "موتورز",
    "غرينيتش",
    "مغضن",
    "البعوضة",
    "البعوض",
    "إقضم",
    "القضام",
    "القضم",
    "إتصال",
    "النيس",
    "جلسة",
    "جنوم",
    "خانة",
    "جينوم",
    "ميلي",
    "الوطني",
    "إذهب",
    "التباطئ",
    "غوا",
    "المهماز",
    "المهمازات",
    "المرمى",
    "أهداف",
    "عوارض",
    "العنزة",
    "السكسوكة",
    "انطلقخلف",
    "انطلقتقديم",
    "انطلقمنزل",
    "انطلقفهرس",
    "الذهب",
    "الجيدة",
    "بأس",
    "غوودريتش",
    "النية",
    "الحسنة",
    "غوودوين",
    "جود",
    "طري",
    "الهفوة",
    "الهفوات",
    "والتصاق",
    "الأوزة",
    "الحزب",
    "الجمهوري",
    "الغوفر",
    "جوردن",
    "غور",
    "غورين",
    "المسفوكة",
    "الزهو",
    "جورجون",
    "خرافية",
    "جورهام",
    "الغوريلا",
    "الغوريلات",
    "غوركي",
    "غورتون",
    "الباز",
    "إلهي",
    "قاريء",
    "الرقيق",
    "بالنميمة",
    "النمام",
    "قشبي",
    "مسكتك",
    "القوطي",
    "الجرماني",
    "نيويورك",
    "قوطي",
    "جرماني",
    "البرمجيات",
    "يجب",
    "جوتفريد",
    "جوتشر",
    "غوودا",
    "الحفارة",
    "الحفارات",
    "اليخني",
    "غوولد",
    "القرعة",
    "النهمون",
    "النقرس",
    "نقرسي",
    "المعلمة",
    "المعلمات",
    "الحكومة",
    "حكومي",
    "الحكومات",
    "الحاكم",
    "الأردية",
    "للمحاكاة",
    "المسكة",
    "الأيادي",
    "المسكات",
    "غرايس",
    "جراسيليسنيس",
    "جرايسي",
    "السوادية",
    "التدريج",
    "التدريجات",
    "تمهيد",
    "تصنيف",
    "متدرج",
    "التخرج",
    "جرادي",
    "جراف",
    "التطعيم",
    "جرافتون",
    "جراهام",
    "جراهامز",
    "المقدسة",
    "الطحان",
    "النحوي",
    "النحويون",
    "قواعدي",
    "نحوي",
    "نحويا",
    "النحوية",
    "الحاكي",
    "السايلوات",
    "السايلو",
    "الحفيد",
    "جرانداد",
    "الحفيدة",
    "الحفيدات",
    "أعظم",
    "الحذلقة",
    "متحذلق",
    "فخم",
    "حفيد",
    "الجدة",
    "بحنان",
    "حنان",
    "الجدات",
    "الأخت",
    "بنت",
    "المغطى",
    "صواني",
    "الجرانولا",
    "جرانت",
    "للمنحة",
    "ممنوح",
    "الممنوح",
    "حبيبي",
    "الحبوبية",
    "تكسيري",
    "الحبيبة",
    "الحبيبات",
    "كريات",
    "جرانفيل",
    "تخطيطي",
    "جرافيس",
    "الجرافايت",
    "الطارح",
    "الكلابات",
    "الممسك",
    "معشب",
    "جراسيرس",
    "الجندب",
    "الجنادب",
    "عشبا",
    "التعشيب",
    "المعشبة",
    "معشوشب",
    "غراتا",
    "مفرم",
    "ممتن",
    "بإمتنان",
    "الإمتنان",
    "المبشارة",
    "يرضي",
    "مرضى",
    "هنأ",
    "حفار",
    "محصب",
    "التحصيب",
    "جرافيرس",
    "شواهد",
    "بالقياس",
    "الوزن",
    "إنجذب",
    "ينجذب",
    "الإنجذاب",
    "جذبي",
    "الجاذبيات",
    "أشيب",
    "رماديا",
    "المسنون",
    "جرايد",
    "رمادية",
    "بالرمادي",
    "الرمادية",
    "جرايز",
    "متدرجألفا",
    "جرايسون",
    "جرايواك",
    "مرعي",
    "الداهن",
    "الداهنون",
    "التشحم",
    "عظيم",
    "السميك",
    "السميكة",
    "العظماء",
    "إغريقي",
    "اليوناني",
    "اليونانيون",
    "جرينبيرج",
    "جرينبلات",
    "جرين",
    "يخضر",
    "خضرة",
    "الخضراوات",
    "جرينفيلد",
    "البقال",
    "المبتدىء",
    "التخضير",
    "مخضوضر",
    "جرينلاند",
    "باخضرار",
    "الخضر",
    "جرينسبورو",
    "جرينسفيل",
    "جرينفيل",
    "تعالج",
    "بحرارة",
    "جرينوود",
    "جرير",
    "محيا",
    "التحية",
    "التحيات",
    "جريج",
    "إجتماعيا",
    "الإجتماعية",
    "جريجوري",
    "غرينادا",
    "الوحش",
    "جرينير",
    "جرينوبل",
    "جريشام",
    "جريتا",
    "جريتشين",
    "جريتيل",
    "نمى",
    "السلوقي",
    "السلوقية",
    "شائب",
    "جرايلاج",
    "أوزة",
    "المشواة",
    "الشكاوي",
    "إحزن",
    "حزين",
    "جريفين",
    "جريفيث",
    "مشوي",
    "الشواي",
    "متجهم",
    "المكشر",
    "التكشير",
    "جريمالدي",
    "القمر",
    "وساخة",
    "جرمم",
    "إزعاج",
    "التكشيرة",
    "جرينديج",
    "طاحن",
    "يطحن",
    "إبتسم",
    "ابتسامة",
    "إبتسام",
    "باستخفاف",
    "القابضون",
    "جريس",
    "روعة",
    "الغضروفية",
    "الطاحونة",
    "جريسوولد",
    "الحصباء",
    "حزما",
    "جرز",
    "قرد",
    "إفريقي",
    "إقلق",
    "أشهب",
    "يقلق",
    "تعبان",
    "الآهة",
    "مأنون",
    "الأنان",
    "الأنين",
    "الآهات",
    "جروات",
    "الجريش",
    "البقالة",
    "البقالون",
    "ترنحا",
    "الترنح",
    "مترنح",
    "الأربية",
    "الأربيات",
    "جروك",
    "إستوعب",
    "جروميت",
    "التهيأ",
    "رفقاء",
    "جروت",
    "مخدد",
    "أروع",
    "الحز",
    "تلمس",
    "متلمس",
    "المتحسس",
    "يتلمس",
    "التلمس",
    "بتلمس",
    "جروسبيك",
    "الإجمالي",
    "رابح",
    "الإجمالية",
    "الربح",
    "جروزسمان",
    "جروسفينور",
    "جروتون",
    "متذمر",
    "بتذمر",
    "الشكاية",
    "المنخفضة",
    "جرذ",
    "جرذان",
    "القريض",
    "جروبير",
    "القنبرة",
    "تذمر",
    "القنبرات",
    "التذمر",
    "الجص",
    "حشى",
    "البستان",
    "خضع",
    "الخاضع",
    "باذلال",
    "جروفير",
    "البساتين",
    "الزارع",
    "الزارعون",
    "الهدير",
    "مهدور",
    "الهادر",
    "زمجرة",
    "الزمجرة",
    "الهدر",
    "زمجري",
    "معزوق",
    "العزق",
    "الضاغن",
    "الثريد",
    "الشنيع",
    "الفضاضة",
    "بشكوى",
    "جرومان",
    "طبعا",
    "شكس",
    "الجرونج",
    "النخير",
    "شاخر",
    "الشاخر",
    "الشخير",
    "الهمهمات",
    "العنقاء",
    "الخدمات",
    "جوام",
    "جوادالوب",
    "جوانيداين",
    "جواناين",
    "ذرق",
    "الضمانات",
    "باحتراس",
    "الجنود",
    "جوارديا",
    "أولياء",
    "الأمور",
    "الوصاية",
    "الحرس",
    "غواتيمالا",
    "الغواتيمالي",
    "حاكمي",
    "القوبيون",
    "جيلف",
    "جونتر",
    "الفدائي",
    "جيرنسي",
    "القادمة",
    "الفدائيون",
    "محزور",
    "الحازر",
    "الحزر",
    "استضاف",
    "الاستضافة",
    "القهقهة",
    "مقهقه",
    "القهقهات",
    "جوجينهيم",
    "جوهليمان",
    "واجهة",
    "الرسومية",
    "جويانا",
    "للتوجيه",
    "التعليمات",
    "جويدر",
    "الأدلاء",
    "دليلواحدللصفحة",
    "دليلاثنانللصفحة",
    "لضبط",
    "النقابة",
    "الغلدر",
    "مقر",
    "مقرات",
    "النقابات",
    "جيلفورد",
    "الغلموت",
    "المقصلة",
    "ذنبا",
    "مذنب",
    "بريئ",
    "الذنوب",
    "غينيا",
    "الغيني",
    "الغينية",
    "الجنيهات",
    "الإنكليزية",
    "القيثارة",
    "القيثارات",
    "الكوجوراتية",
    "النورس",
    "جلاه",
    "الممزوجة",
    "بالأفريقية",
    "المريئ",
    "النوارس",
    "التخديد",
    "المبتلع",
    "البامية",
    "مصمغ",
    "جومينج",
    "النباهة",
    "اللثث",
    "المسلحة",
    "جاندرسون",
    "الوارية",
    "المسلحون",
    "جونر",
    "المدفعيون",
    "الجوال",
    "الأجولة",
    "البارود",
    "بارود",
    "مهرب",
    "مهربو",
    "الطلقات",
    "جانسلنج",
    "جانثر",
    "جورخا",
    "جورموخي",
    "المعلمون",
    "جوس",
    "البئر",
    "المتدفق",
    "التدفقات",
    "تدفقا",
    "بتدفق",
    "التقوية",
    "جستافسون",
    "ذوقي",
    "غوستاف",
    "غوستافوس",
    "العصف",
    "جوتينبيرج",
    "غوثري",
    "جوتسير",
    "طمعا",
    "مميزب",
    "البالوعات",
    "الأجش",
    "الجشة",
    "غويانا",
    "جير",
    "التوجية",
    "إكرع",
    "مكروع",
    "بنهم",
    "يكرع",
    "الكرع",
    "جوين",
    "الجمنازيوم",
    "الجمباز",
    "جمبازي",
    "البذور",
    "نسائي",
    "النسائي",
    "بصحة",
    "دكتور",
    "إحتال",
    "الجبس",
    "غجري",
    "الدورات",
    "السنقر",
    "الجيروسكوبية",
    "طائرة",
    "عمودية",
    "طيارات",
    "الجيروسكوب",
    "الجيروسكوبات",
    "توازني",
    "الماريوانا",
    "مجففة",
    "وتدخن",
    "الجرمانيون",
    "القوطيون",
    "العويل",
    "النواح",
    "ناح",
    "نحب",
    "لمهمة",
    "بحث",
    "للعام",
    "2003.",
    "يبحث",
    "ومواقع",
    "الأخبار",
    "الإنبهار",
    "ممتازة",
    "الروحية",
    "ها",
    "هاج",
    "هاس",
    "هاب",
    "صك",
    "هابن",
    "الخياطة",
    "دكان",
    "هابيرمان",
    "حبيب",
    "زي",
    "المناسبات",
    "صلاحية",
    "للسكن",
    "صالحا",
    "إعتياديا",
    "السكنية",
    "متعود",
    "يتعود",
    "هاسيندا",
    "العزب",
    "المأجور",
    "الثمر",
    "الميس",
    "هاكيت",
    "الممشقة",
    "الماشق",
    "الممشقات",
    "هاكماتاك",
    "للإستعمال",
    "المأجورون",
    "المناشير",
    "الهابط",
    "عنده",
    "هادامارد",
    "حداد",
    "الحدوق",
    "هادلي",
    "أدريان",
    "هادرون",
    "يتفاعل",
    "حافظ",
    "الهفينوم",
    "العفريتة",
    "هاجن",
    "هيدجر",
    "جموحا",
    "الإنهاك",
    "المساومات",
    "العفاريت",
    "هاجستروم",
    "لاهاي",
    "هان",
    "حيفا",
    "هايكو",
    "رحب",
    "هيلير",
    "الحالوب",
    "هينيس",
    "شعرة",
    "حلاقة",
    "الحلاقة",
    "تصفيفة",
    "تصفيف",
    "مشعر",
    "شعرا",
    "كثرة",
    "منبت",
    "الشعرات",
    "نابض",
    "هايتي",
    "الهايتي",
    "هال",
    "المطرد",
    "القاوند",
    "العافية",
    "معافى",
    "هايلي",
    "النصفي",
    "الوسط",
    "فاتر",
    "بتراخي",
    "الإكتمال",
    "الطباعة",
    "النصفية",
    "القفندر",
    "هالايد",
    "هاليفاكس",
    "هالايت",
    "الصالة",
    "سبحان",
    "التسبيح",
    "هالر",
    "فسيولوجي",
    "سويسري",
    "هالي",
    "هالينان",
    "الشماعة",
    "تهلوس",
    "متهلوس",
    "يتهلوس",
    "التهلوس",
    "الهلوسة",
    "مهلوس",
    "للهلوسة",
    "هالما",
    "هالوكاربون",
    "الهلوجين",
    "هالبيرن",
    "هولسي",
    "هالستيد",
    "الرسن",
    "الأرسنة",
    "منصف",
    "الأنصاف",
    "هالفيرسون",
    "هامبورغ",
    "الهامبرغر",
    "الهامبرغرات",
    "هامي",
    "هاملتن",
    "هاميلتونايان",
    "هملت",
    "القرى",
    "هاملين",
    "مطروق",
    "الطارق",
    "بالمطرقة",
    "هاميرليس",
    "هاميت",
    "الأرجوحة",
    "الأراجيح",
    "هاموند",
    "السلال",
    "هامبشاير",
    "هامبتون",
    "الهامستر",
    "وتر",
    "الركبة",
    "أوتار",
    "الهان",
    "هانكوك",
    "اليدوية",
    "المصافحة",
    "نشرة",
    "نشرات",
    "فرملة",
    "سكة",
    "حديد",
    "تعمل",
    "الصفد",
    "الأصفاد",
    "هاندل",
    "يدوي",
    "المسدسات",
    "السباق",
    "الإعاقات",
    "بحرفة",
    "فائدة",
    "اليدوي",
    "المناديل",
    "المقبض",
    "المقود",
    "المقاود",
    "المقابض",
    "هاندلن",
    "باليد",
    "الأيدي",
    "الودود",
    "الوقفة",
    "اليدين",
    "الوقفات",
    "الكتابات",
    "الماهر",
    "هاني",
    "هانفورد",
    "للتعليق",
    "ذليل",
    "مشنوق",
    "الشنق",
    "ظفر",
    "صداع",
    "أغلاق",
    "هانكيل",
    "مشتاق",
    "المتلهف",
    "المتشوق",
    "يشتاق",
    "هانكس",
    "الجنسي",
    "المشبوهة",
    "هانلي",
    "هانلون",
    "حنا",
    "هانا",
    "هانيبال",
    "هانوي",
    "هانوفر",
    "بالعائلة",
    "المالكة",
    "هانز",
    "هانسيل",
    "هانسين",
    "هانسن",
    "الحانوكة",
    "هابجود",
    "عشوائي",
    "منحوس",
    "بسوء",
    "واحدة",
    "الكروموسومات",
    "بإمتلاك",
    "إثنين",
    "بالمصادفة",
    "العرضي",
    "هابسبيرغ",
    "المتحرش",
    "حربين",
    "الرائد",
    "الرواد",
    "المرافيء",
    "مأوي",
    "الموانئ",
    "هاركورت",
    "الورقية",
    "والمعدنية",
    "مليست",
    "الشيكات",
    "المستندات",
    "هاردبيك",
    "مسلوق",
    "المطبوعة",
    "فاضح",
    "الصلبة",
    "هاردينير",
    "هاردينيرس",
    "أصلب",
    "الخوذة",
    "جرأة",
    "هاردن",
    "هاردينج",
    "والعشرون",
    "المحتمل",
    "قاحلة",
    "المشقة",
    "المشاق",
    "أسلاك",
    "الأخشاب",
    "شغول",
    "المشقوقة",
    "الحريم",
    "البرية",
    "أصغ",
    "مصغى",
    "أنصت",
    "الإصغاء",
    "يصغي",
    "هارلان",
    "هارليم",
    "هارلي",
    "العاهرة",
    "البغاء",
    "العاهرات",
    "مآذي",
    "الإيذاء",
    "المسالمة",
    "هارمون",
    "التوافقية",
    "الهارمونيكا",
    "بتناسق",
    "التوافقيات",
    "الإنسجامات",
    "نسق",
    "ينسق",
    "هارولد",
    "قيثار",
    "الطماعون",
    "القيثار",
    "للحوت",
    "برمح",
    "الحيتان",
    "إصطياد",
    "هاربسيكورد",
    "الطماع",
    "مغزو",
    "هارير",
    "يغزو",
    "هاريت",
    "هاريمان",
    "هارينجتون",
    "هاريس",
    "هاريسبيرغ",
    "هاريسن",
    "هاريسونبيرغ",
    "الأمشاط",
    "يتنحنح",
    "تنحنح",
    "التنحنح",
    "هيري",
    "يصعب",
    "التصعيب",
    "هارت",
    "هارتفورد",
    "هارتلي",
    "هارتمان",
    "هارفارد",
    "الحصاد",
    "محصود",
    "الحاصد",
    "الحاصدون",
    "يحصد",
    "هارفي",
    "المهروس",
    "مثروم",
    "هاشير",
    "الثرم",
    "حزقيل",
    "هاسكينز",
    "الرباطات",
    "هاسلر",
    "يمتلك",
    "الإستعجال",
    "الإستعجالات",
    "مستعجل",
    "هاستينجز",
    "البرنيطة",
    "أربطة",
    "الفتحة",
    "مفقس",
    "الفقاس",
    "المفاقس",
    "المفقس",
    "الفتحات",
    "التفقيس",
    "الرقن",
    "هاتفيلد",
    "هاث",
    "هاثاواي",
    "قبعة",
    "هاتيد",
    "هاتيراس",
    "هاتي",
    "هاتيسبيرغ",
    "هاوجين",
    "متغطرس",
    "بغطرسة",
    "الغطرسة",
    "العتلة",
    "متعهدو",
    "العتلات",
    "الورك",
    "بأفخاذ",
    "المزار",
    "الزائر",
    "الزوار",
    "حزنا",
    "المزارات",
    "هوبت",
    "هوسدورف",
    "هوسير",
    "الإستكبار",
    "هافانا",
    "الملجأ",
    "هافيرينج",
    "الجراب",
    "الموسرون",
    "الإمتلاك",
    "الزعرورة",
    "هاواي",
    "إصطاد",
    "الباعة",
    "المتجولون",
    "هوكينز",
    "هاولي",
    "الزعرور",
    "هاوثورن",
    "حمى",
    "هايكوك",
    "أكوام",
    "هايدن",
    "هاير",
    "هايز",
    "الحشائش",
    "مخزن",
    "التبن",
    "مخازن",
    "هاينيس",
    "كومة",
    "هايوارد",
    "هايوود",
    "مخاطر",
    "السديم",
    "هيزل",
    "هازير",
    "إكفهرارا",
    "مكفهر",
    "التشوش",
    "هو",
    "الصداع",
    "نقانق",
    "برأس",
    "الرأسي",
    "رأستذييل",
    "الرأسية",
    "إندفاعا",
    "بلارأس",
    "العلوية",
    "البارز",
    "المتصدر",
    "عناوين",
    "البارزة",
    "المديرات",
    "أغطية",
    "إتخذ",
    "مقرا",
    "المخدات",
    "سياف",
    "السيافون",
    "الرياح",
    "العكسية",
    "المعجم",
    "أشف",
    "للإشفاء",
    "مشفى",
    "المعالجون",
    "هيلي",
    "يشفي",
    "صحي",
    "الصحية",
    "أصح",
    "الكومة",
    "إسمع",
    "سمع",
    "السامع",
    "السامعون",
    "الجلسة",
    "الجلسات",
    "يسمع",
    "هيرست",
    "نبض",
    "بقلب",
    "المعوية",
    "طيب",
    "الأخلاص",
    "قلبية",
    "رفاق",
    "القلوب",
    "الأحبة",
    "للتسخين",
    "ساخن",
    "المدافئ",
    "المرج",
    "وثني",
    "الوثنية",
    "وثنيا",
    "هذر",
    "هيثمان",
    "التدفئة",
    "السخونة",
    "الحمالون",
    "الثقال",
    "الثقيلة",
    "هيب",
    "الربيع",
    "والشباب",
    "منفصم",
    "عبراني",
    "عبري",
    "هيكات",
    "هيك",
    "المقاطعون",
    "هيكمان",
    "الهكتار",
    "الهكتارات",
    "هيكتر",
    "مرهب",
    "يرهب",
    "هيكبا",
    "هيدا",
    "القنفذ",
    "القنافذ",
    "بانخفاض",
    "هيجير",
    "المتعي",
    "تلذذي",
    "تمتعي",
    "المتعيون",
    "الغفلة",
    "منعل",
    "التنعيل",
    "كعوب",
    "هيغل",
    "هيغلي",
    "الزعامات",
    "هييديجر",
    "هايديلبيرج",
    "هيدي",
    "هيغ",
    "هين",
    "هيينلين",
    "هينريتش",
    "هينز",
    "هيينز",
    "الوريث",
    "الوريثة",
    "الوريثات",
    "الورثة",
    "هيسينبيرج",
    "هيايزر",
    "هيستير",
    "هيلين",
    "هيلينا",
    "هيلجا",
    "حلزوني",
    "اللوالب",
    "الهليكون",
    "بمركز",
    "بمركزية",
    "الملونة",
    "الهليوغراف",
    "بالهليوغراف",
    "هيليوجرافي",
    "الفوتوغرافي",
    "هيليوبوليس",
    "الهليوتروب",
    "الهيليوتيب",
    "الهليوم",
    "اللولب",
    "سمندر",
    "هيلجرامايت",
    "جهنمي",
    "هلو",
    "مخوذ",
    "الخوذ",
    "هيلمولز",
    "هيلموت",
    "مساعدةعن",
    "سوعد",
    "بعجز",
    "هيلسنكي",
    "النصاب",
    "هلفتيكا",
    "الهيماتيت",
    "هيمنغواي",
    "الشوكران",
    "الهيموغلوبين",
    "الهيموفيليا",
    "بالهيموفيليا",
    "النزف",
    "نازف",
    "نزفي",
    "الباسور",
    "بالبواسير",
    "البواسير",
    "هيموسيديرين",
    "المرقأة",
    "القنب",
    "تيل",
    "هيمبستيد",
    "الكفافة",
    "البنج",
    "الآن",
    "السياسي",
    "السياسيون",
    "هيندرسن",
    "هيندريك",
    "هيندريكس",
    "هيندريكسون",
    "هينيكين",
    "هينلي",
    "هينيسي",
    "هينينج",
    "هنري",
    "هنرييتا",
    "كبدي",
    "هيباتيكا",
    "الكبد",
    "هيبورن",
    "فنان",
    "الجاز",
    "فنانو",
    "المسبع",
    "الهيبتان",
    "هي",
    "حيرا",
    "هيراكليتس",
    "التبشير",
    "أعلام",
    "العشبة",
    "عشبي",
    "المختص",
    "بالأعشاب",
    "هيربيرت",
    "للأعشاب",
    "شاق",
    "هرقل",
    "هنا",
    "قريبا",
    "بموجب",
    "هذا",
    "هيريفورد",
    "سابقة",
    "هذه",
    "أدناه",
    "هيريس",
    "الزنديق",
    "ضلالي",
    "الزنادقة",
    "يلي",
    "بهذا",
    "طيا",
    "توريثه",
    "توريثية",
    "التراث",
    "الوارث",
    "هيركيمير",
    "هيرمان",
    "الخنثى",
    "خنثوي",
    "يتعامل",
    "مبادئ",
    "هيرميس",
    "سحري",
    "صومعة",
    "هيرميت",
    "الناسكون",
    "هيرموسا",
    "هيرنانديز",
    "الفتق",
    "بالفتاق",
    "الفتوق",
    "هيرودوتس",
    "التاريخ",
    "بطولي",
    "البطولية",
    "الهيروين",
    "البطلة",
    "البطلات",
    "الحلأ",
    "الزاحفات",
    "هير",
    "الرنغة",
    "زخرف",
    "هيرينجتون",
    "لها",
    "هيرشيل",
    "نفسه",
    "هيرسي",
    "هيرشي",
    "هيرتز",
    "هيرزيس",
    "هيرتزوج",
    "كوكب",
    "هيس",
    "هيستر",
    "ذرة",
    "متحدة",
    "حلقة",
    "بدعي",
    "الزندقة",
    "باقتران",
    "ترددين",
    "الأمشاج",
    "بتباين",
    "المختلف",
    "بالزيجوتات",
    "المتغايرة",
    "هيتمان",
    "هيتي",
    "هيوبلين",
    "هيوتشليريستش",
    "إرشادي",
    "تجريبيا",
    "الاستدلال",
    "هيوسين",
    "هيوسير",
    "هويت",
    "هيويت",
    "هيوليت",
    "الهيكس",
    "سادس",
    "هيكساكلورايد",
    "ستعشري",
    "ستة",
    "السداسي",
    "سداسي",
    "السداسيات",
    "المكعب",
    "التفاعيل",
    "الهكسين",
    "مشعوذ",
    "يشعوذ",
    "العنفوان",
    "هيوود",
    "هجسيبم",
    "الصغرى",
    "هيات",
    "هياواثا",
    "هيباتشي",
    "هيبارد",
    "إسبت",
    "مسبوت",
    "يسبت",
    "السبت",
    "السبات",
    "الشاعري",
    "لجزيرة",
    "أيرلندا",
    "الكركديه",
    "الفواق",
    "هيكوك",
    "الهيكري",
    "هيدالجو",
    "قبيح",
    "هرمي",
    "مرتبي",
    "التدرجات",
    "هيروغليفي",
    "الهيروغليفية",
    "الطلاسم",
    "مدعي",
    "هيجينس",
    "العالي",
    "التذبذب",
    "الرتبة",
    "العالية",
    "التقنية",
    "المتطورة",
    "رفيعة",
    "راقي",
    "هايفيلد",
    "المهم",
    "مبرز",
    "هايت",
    "انسحب",
    "الإختطاف",
    "مختطف",
    "الجذل",
    "هيلبيرت",
    "القديس",
    "هيلاري",
    "هيلكريست",
    "بالتلال",
    "هيليل",
    "مرتفعات",
    "هيلينج",
    "هيلمان",
    "الرابية",
    "الروابي",
    "هيلزبورو",
    "هيلزدال",
    "سفح",
    "سفوح",
    "قمم",
    "أقصىأدنى",
    "وصلأقصىأدنى",
    "فتح",
    "هيلتون",
    "النقير",
    "هملايا",
    "الهيمالياس",
    "هيملير",
    "الوعلة",
    "الوعلات",
    "هندوسي",
    "الهندوسية",
    "الهنودس",
    "المفصلة",
    "متمحور",
    "هينجر",
    "المفصلات",
    "التمحور",
    "هينكل",
    "هينمان",
    "هينسدال",
    "اللماح",
    "الهيب",
    "هوب",
    "الهبي",
    "مجموعات",
    "الهبيز",
    "فرس",
    "النهر",
    "هيبوقراط",
    "أبقراط",
    "أفراس",
    "الوروك",
    "محبو",
    "حيرم",
    "بالتقسيط",
    "مستأجر",
    "المستأجرون",
    "المؤجر",
    "المؤجرون",
    "الإستئجار",
    "التوظيفات",
    "هيروزهي",
    "هيروشيما",
    "هيرش",
    "الكثيف",
    "هسباني",
    "الهسبان",
    "الفحيح",
    "مهسهس",
    "الفحاح",
    "يهسهس",
    "الهسهسة",
    "أصمت",
    "الهستامين",
    "الحامض",
    "الأميني",
    "بكيمياء",
    "وأنسجة",
    "الإحصائي",
    "التاريخية",
    "التأريخية",
    "مسرحيا",
    "وهروب",
    "هيتاتشي",
    "هيتشكوك",
    "الرابط",
    "بالتماس",
    "المجانية",
    "هتلر",
    "الضاربون",
    "الإنحال",
    "هم",
    "سندويشة",
    "سندويشات",
    "هواجلاند",
    "الكنز",
    "مكتنز",
    "المكتنز",
    "المكتنزون",
    "الكنوز",
    "أجش",
    "هوارسين",
    "الجشاشة",
    "هوبارت",
    "هوبز",
    "العقال",
    "عارج",
    "الماشي",
    "يعرج",
    "العرج",
    "هوبداي",
    "عاشر",
    "الأفاق",
    "الأفاقون",
    "هوبوكين",
    "النتوءات",
    "هوك",
    "العرقوب",
    "هوكر",
    "الهوكي",
    "العراقيب",
    "الرهن",
    "الخزعبلات",
    "هودج",
    "الخربطة",
    "هوجيس",
    "هودجكين",
    "المعزقة",
    "المعازق",
    "هوف",
    "هوفمان",
    "هوجان",
    "بقايا",
    "هوي",
    "الرافع",
    "هوكان",
    "هولبروك",
    "هولكوم",
    "ملفا",
    "هولدن",
    "المستمرون",
    "التعطيلات",
    "خزن",
    "للثقوب",
    "العطلة",
    "المصطاف",
    "أقدس",
    "المقدسات",
    "القدسية",
    "شمولي",
    "بعصير",
    "الليمون",
    "هولاندا",
    "الهولندية",
    "الصرخة",
    "هولليريث",
    "الصرخات",
    "هولينجسورث",
    "هوليستير",
    "هولواي",
    "الأجواف",
    "الأطباق",
    "الفضية",
    "هولي",
    "الخطمي",
    "هوليود",
    "هولم",
    "هولمان",
    "هولمدل",
    "هولمز",
    "هولميوم",
    "السهول",
    "المحرقة",
    "10,000",
    "سنة",
    "المجسمة",
    "المجسم",
    "هولوجرافيكال",
    "الهولوجرامي",
    "هولست",
    "هولشتاين",
    "غمد",
    "الغمد",
    "هولت",
    "هوليوك",
    "الخفان",
    "هولزمان",
    "أثنى",
    "مخبوز",
    "وطني",
    "الصنع",
    "للوطن",
    "هوميد",
    "الوطن",
    "المشرد",
    "مشرد",
    "التشرد",
    "ربة",
    "ربات",
    "تدبير",
    "المثلي",
    "بالمثل",
    "المثلية",
    "ملكية",
    "هوميروس",
    "هومري",
    "الزاجل",
    "الحنين",
    "البلدي",
    "العزبة",
    "هومتاون",
    "البيتي",
    "البيتية",
    "المواعظ",
    "التجانسات",
    "بتجانس",
    "جانس",
    "مجانس",
    "الخالط",
    "الخالطون",
    "يجانس",
    "المجانسة",
    "المشابه",
    "التماثل",
    "تشابه",
    "تشابهات",
    "شكلية",
    "المتجانس",
    "باللفظ",
    "الألفاظ",
    "المتجانسة",
    "كراهية",
    "اللوطيون",
    "بتطابق",
    "المبجل",
    "الهوندا",
    "هوندو",
    "يابانية",
    "هندوراس",
    "الجلخ",
    "هونر",
    "بأمانة",
    "الأمانة",
    "العسل",
    "أقراص",
    "معسل",
    "شهر",
    "مقضي",
    "هونييويل",
    "الجنسيات",
    "هونج",
    "زمر",
    "يزمر",
    "هونكيتونك",
    "هونولولو",
    "الوقار",
    "الأتعاب",
    "المكرم",
    "المكرمون",
    "عبارات",
    "شرفيا",
    "شرف",
    "هونشو",
    "هود",
    "القلنسوات",
    "الفارغ",
    "الحافر",
    "حافر",
    "حوافر",
    "هوفر",
    "الحوافر",
    "النرجيلة",
    "النرجيلات",
    "الإنعقاف",
    "هوكير",
    "هوكوب",
    "الإنضمامات",
    "الأنكلستوما",
    "الشغب",
    "هوبير",
    "الهرج",
    "والمرج",
    "الهدهد",
    "الهداهد",
    "الصيحات",
    "السجون",
    "إنديانا",
    "الشعبي",
    "العطعطة",
    "هوفير",
    "متمنى",
    "المتفائلون",
    "المتمني",
    "الآمال",
    "هوبي",
    "الشوشونية",
    "التمني",
    "هوبكنز",
    "قافز",
    "النطاط",
    "النطاطات",
    "هوراس",
    "هوراشيو",
    "الآفاق",
    "هورموني",
    "الهورمون",
    "الهورمونات",
    "النير",
    "الهورنبلند",
    "مقرن",
    "بذوات",
    "الدبور",
    "تقرنا",
    "المنشارية",
    "هورنوورت",
    "الطوالع",
    "هورويز",
    "ببشاعة",
    "يروع",
    "روع",
    "الترويع",
    "الرعب",
    "بجواد",
    "السبيب",
    "هورسيلي",
    "الخيالة",
    "هورسنز",
    "الفجل",
    "حذوة",
    "هورسيشوير",
    "حذوات",
    "الفارسة",
    "الفارسات",
    "بحصان",
    "تشجيعي",
    "بستاني",
    "هورتن",
    "حورس",
    "الخرطوم",
    "مسقي",
    "الخراطيم",
    "السقاية",
    "العجزة",
    "مضياف",
    "الضيافة",
    "ضيافيا",
    "بالمستشفى",
    "إدخال",
    "المستشفيات",
    "الرهينة",
    "الرهائن",
    "النطاقالمضيف",
    "مستضاف",
    "الفندق",
    "الخانات",
    "الخان",
    "الفنادق",
    "المضيفة",
    "المضيفات",
    "الإستضافة",
    "السياس",
    "بترحيب",
    "المضيفالبوابة",
    "مستضيفمستخدم",
    "المستنبت",
    "عندما",
    "ترتفع",
    "حرارته",
    "المقانق",
    "استعجل",
    "المتهور",
    "المستدفأ",
    "الإستنباتي",
    "يبرمج",
    "المعدلة",
    "أسمر",
    "أحر",
    "هوديل",
    "هودني",
    "هوغ",
    "هوغتون",
    "الرملية",
    "هور",
    "روض",
    "الترويض",
    "سرقات",
    "الروب",
    "مدبرة",
    "مدبرات",
    "المشرف",
    "أسطح",
    "بربة",
    "المقرات",
    "هيوستن",
    "إنتفخ",
    "حم",
    "الحوامة",
    "حائم",
    "المحلق",
    "الحوم",
    "يحوم",
    "هاوارد",
    "هاويل",
    "أية",
    "الهاون",
    "العواء",
    "الفاحشة",
    "يعوي",
    "هاوس",
    "مستهتر",
    "باكرد",
    "هروثجار",
    "المستعملة",
    "المتشعب",
    "وتسمى",
    "هوبارد",
    "هوبيل",
    "هبل",
    "هيوبرت",
    "الكبرياء",
    "إستعلائي",
    "التلملم",
    "مكوم",
    "المزاحم",
    "هودسون",
    "هيوي",
    "السيئ",
    "التكشيرات",
    "الحضنة",
    "معانق",
    "هجينجز",
    "هيو",
    "هيوز",
    "هيوجو",
    "الحضنات",
    "هوجو",
    "هاجينوت",
    "هاه",
    "الاستجواب",
    "بتثاقل",
    "البزر",
    "إنسانيا",
    "إنسانية",
    "الإنسانيون",
    "المحسنون",
    "الانسانية",
    "أنس",
    "بالإنسانية",
    "المطعمون",
    "يؤنس",
    "التأنيس",
    "مواصفات",
    "متواضع",
    "بتواضع",
    "هومبولدت",
    "الإستثنائي",
    "الإستثنائيون",
    "هيوم",
    "عضدي",
    "الترطيب",
    "المرطب",
    "المرطبون",
    "بإذلال",
    "مهمهم",
    "الهمهمة",
    "الربوة",
    "الفكاهي",
    "الفكاهيون",
    "بظرافة",
    "الفكاهة",
    "المزاجات",
    "ظريف",
    "الأحدب",
    "أحدب",
    "همفرييس",
    "الدندنات",
    "الدبال",
    "متحدب",
    "مائة",
    "المئات",
    "الهنغاري",
    "هنغاريا",
    "الجوع",
    "مجاع",
    "جوعا",
    "بجوع",
    "جائع",
    "المركع",
    "جلس",
    "الجلوس",
    "هنتر",
    "هانتينجتن",
    "هانتلي",
    "الصيادة",
    "يطارد",
    "هانتسفيل",
    "هيرد",
    "الموانع",
    "هورلي",
    "هيرن",
    "تهاليل",
    "العجالة",
    "عجالة",
    "هورست",
    "بألم",
    "إندفع",
    "يندفع",
    "يآذي",
    "هرويتز",
    "الفلاح",
    "الفلاحون",
    "الفلاحة",
    "يسكت",
    "القشة",
    "المقشر",
    "المقشرون",
    "أقوياء",
    "قشريا",
    "الوقحات",
    "الوقحة",
    "الزحام",
    "المزاحمون",
    "هوتشينس",
    "هوتشينسون",
    "هوتشيسون",
    "هوكسلي",
    "هاكستابل",
    "ابتهاج",
    "السنبل",
    "القلائص",
    "الضبع",
    "الضباع",
    "الهيالين",
    "هيانيس",
    "الهجين",
    "التهجين",
    "مهجن",
    "يهجن",
    "الهجائن",
    "هيد",
    "الهيدرة",
    "الكوبية",
    "ميه",
    "يميه",
    "التمييه",
    "هيدروليكي",
    "هيدروليكيا",
    "الهايدروليكا",
    "الهيدريد",
    "المصحة",
    "الهيدروكربون",
    "القيلة",
    "القيلات",
    "بتضخم",
    "الهيدرو",
    "كلوريك",
    "الهيدروكلوريد",
    "الهيدروناميكا",
    "كهرومائي",
    "بالهيدروفلورين",
    "المزعنف",
    "المزعنفة",
    "الهيدروجين",
    "هدرج",
    "مهدرج",
    "يهدرج",
    "التهدرج",
    "الهدرجة",
    "هايدروجيني",
    "هيدرولوجي",
    "الهيدرولوجية",
    "الموارد",
    "التميؤ",
    "ثقل",
    "النوعي",
    "الهيدرومتر",
    "هيدرونيوم",
    "بالعلاج",
    "للاتحاد",
    "بفوبيا",
    "الزلاقات",
    "المصحات",
    "متوازن",
    "أستاتيكا",
    "الموائع",
    "بالمياه",
    "الهيدروكسيد",
    "هيدروكسيلي",
    "هيدروكسيل",
    "الهيدروكسيل",
    "هيدروكسيزاين",
    "هيدرس",
    "صحيا",
    "الصحي",
    "بمقياس",
    "هيمان",
    "البكارة",
    "أغشية",
    "الترتيلة",
    "إنشاد",
    "الزائد",
    "إطنابي",
    "مكعبات",
    "فرط",
    "بفرط",
    "الإبصار",
    "للبعيد",
    "للقريب",
    "الكمي",
    "أجزاء",
    "التشريط",
    "الشرطات",
    "التناويم",
    "مغنطيسيا",
    "المنومون",
    "المنوم",
    "للتنويم",
    "نوم",
    "ينوم",
    "الهيبوسلفات",
    "قليلة",
    "هيبوكلورايت",
    "هيبوكلوروس",
    "الوسواس",
    "وسواسي",
    "نفاقي",
    "منافق",
    "الناتج",
    "تتدحرج",
    "هيبودرميس",
    "هيبوفيسيل",
    "نخامية",
    "المهاد",
    "البصري",
    "بالهايبوتلاموس",
    "الهايبوتلاموس",
    "فرضية",
    "إنخفاض",
    "الفرضيات",
    "الفرضية",
    "إفترض",
    "الإفتراض",
    "إفتراضي",
    "إفتراضيا",
    "بالقصور",
    "الدرقي",
    "القصور",
    "استئصالات",
    "استئصال",
    "التخلفية",
    "الهستيريا",
    "هستيري",
    "الهيرتز",
    "العاملة",
    "أوشحة",
    "تغطي",
    "جهة",
    "بتزويد",
    "والدعم",
    "لمستخدمي",
    "تلفزيونية",
    "بالتسوق",
    "والتبضع",
    "البضاعة",
    "شاشة",
    "حلال",
    "مذبوح",
    "الإسلامية",
    "انا",
    "أقسم",
    "وبمعنى",
    "أيوا",
    "الإيامبي",
    "إيان",
    "إيبريا",
    "آيبيري",
    "إبيرو",
    "منجل",
    "ام",
    "إبسن",
    "إكاروس",
    "العالمية",
    "الآيس",
    "الثلجي",
    "للتزحلق",
    "بالجليد",
    "كاسحة",
    "آيسلندا",
    "الآيسلندي",
    "بائعو",
    "الثلوج",
    "رقاقات",
    "أثلج",
    "ثلجيا",
    "التثلج",
    "الأيقونة",
    "أيقوني",
    "الثائر",
    "الثوار",
    "الأيقونات",
    "20",
    "وجها",
    "بمتعدد",
    "العشروني",
    "إدا",
    "إداهو",
    "تطوير",
    "متكاملة",
    "المثالية",
    "المثالي",
    "مثاليا",
    "المثاليون",
    "يمثل",
    "مثله",
    "لو",
    "استخدموا",
    "مماثل",
    "التعريفات",
    "المعرفون",
    "الهويات",
    "إديوليكت",
    "إديولوجيك",
    "أيديولوجي",
    "فكريا",
    "المذهبي",
    "المذهبيون",
    "البلاهة",
    "العلة",
    "تمييزي",
    "البلهاء",
    "مضيع",
    "التسيب",
    "العاطل",
    "العاطلون",
    "يضيع",
    "الضياع",
    "المعبود",
    "الوثني",
    "عبادة",
    "الأصنام",
    "إعبد",
    "معبود",
    "العابد",
    "العابدون",
    "يعبد",
    "الرعوية",
    "شاعري",
    "القصائد",
    "بمعنى",
    "اخر..",
    "مهندسي",
    "والإلكترونيات",
    "إذا",
    "التشكك",
    "متشكك",
    "إيفني",
    "كوخ",
    "الإسكيمو",
    "أكواخ",
    "أشعل",
    "الشاعل",
    "يشعل",
    "الإشعال",
    "الإيقاد",
    "الفضائح",
    "الجهول",
    "الجهلة",
    "الجاهل",
    "أهمل",
    "إيغور",
    "الإيغوانات",
    "تشابك",
    "المشغل",
    "آيك",
    "إلينوي",
    "المعي",
    "اللفائفي",
    "الشلل",
    "إليا",
    "حرقفي",
    "إلياذة",
    "إليوم",
    "الانتهاكات",
    "شرعية",
    "مقروء",
    "تصعب",
    "النغولة",
    "حرام",
    "الآفق",
    "محظور",
    "اللاحدودية",
    "اللامحدود",
    "متناه",
    "الأمية",
    "أميا",
    "الأميون",
    "اللامنطقية",
    "أنر",
    "منار",
    "ينير",
    "للإضاءة",
    "منور",
    "أنوار",
    "مضيئ",
    "الإنارة",
    "خداع",
    "الإيضاح",
    "الإيضاحات",
    "إيضاحي",
    "إلونا",
    "إليشين",
    "للتخيل",
    "الإمكانية",
    "خيالية",
    "إمبيسيليتيس",
    "إطمر",
    "الطمر",
    "يطمر",
    "المتجرع",
    "الورطة",
    "ضرج",
    "مضرج",
    "يضرج",
    "التضريج",
    "شبع",
    "للتقليد",
    "يقلد",
    "المقلد",
    "المقلدون",
    "بنظافة",
    "اللزوم",
    "اللامادية",
    "ناضج",
    "النضوج",
    "النضج",
    "اللامحدودية",
    "حصر",
    "الهائلة",
    "إغطس",
    "غاطس",
    "الغمر",
    "المهاجر",
    "دوائر",
    "الوشاكة",
    "وشيك",
    "للإمتزاج",
    "التعوق",
    "شل",
    "يشل",
    "دعي",
    "ضح",
    "مضحى",
    "يضحي",
    "التضحية",
    "العهر",
    "مخلد",
    "التخليد",
    "الخالدون",
    "الرسوخ",
    "إمان",
    "المناعات",
    "التلقيح",
    "لقح",
    "انفصال",
    "البروتينات",
    "مناعي",
    "مناعيا",
    "إمباكتر",
    "الإنحشار",
    "التصادم",
    "خوزق",
    "مخوزق",
    "يخوزق",
    "التخوزق",
    "محسوس",
    "ملموسة",
    "إمنح",
    "الإفسادات",
    "نزيه",
    "الحيادية",
    "إستحالة",
    "سالكة",
    "المسدود",
    "بعديم",
    "الحس",
    "نفاد",
    "صبور",
    "بنفاذ",
    "صبر",
    "إتهم",
    "غاية",
    "الإتقان",
    "منزه",
    "المعاوقة",
    "المعاوقات",
    "إمبيدر",
    "الدفاعة",
    "هدد",
    "مهدد",
    "يهدد",
    "بمناعة",
    "التمادي",
    "نادم",
    "الأولوية",
    "حتميا",
    "الأولويات",
    "لايدرك",
    "لاإدراكي",
    "مراعاة",
    "للبداية",
    "للفعل",
    "الماضية",
    "مثقب",
    "الإمبريالية",
    "الإمبريالي",
    "إمبريالية",
    "الإمبرياليون",
    "إمبراطوري",
    "خاطر",
    "يخاطر",
    "متجبر",
    "خالدة",
    "المؤقتية",
    "النفاذ",
    "نفاذ",
    "عازلة",
    "شخصي",
    "موضوعيا",
    "الصلافة",
    "صلف",
    "الحصف",
    "المعصية",
    "إرتطم",
    "مرتطم",
    "يرتطم",
    "الإرتطام",
    "العقوق",
    "الغرس",
    "التطبيقات",
    "المطبق",
    "المطبقون",
    "توريطي",
    "مائلا",
    "للتوريط",
    "التضمينية",
    "تضميني",
    "ضمني",
    "ضمنيا",
    "الضمنية",
    "إمبلوسيف",
    "لاسياسيا",
    "ألاسياسية",
    "مستحيل",
    "أستحالة",
    "المجهولة",
    "باستحالة",
    "الإستيراد",
    "للإستيراد",
    "الأهمية",
    "مهم",
    "بأهمية",
    "مستورد",
    "المستورد",
    "المستوردون",
    "الإستيرادات",
    "بإلحاح",
    "المزعجون",
    "اللج",
    "الإستحالات",
    "الإستحالة",
    "مستحيلا",
    "المكس",
    "المنتحل",
    "المنتحلون",
    "المكوس",
    "المفقر",
    "يفقر",
    "التفقير",
    "لاعن",
    "الإشباع",
    "إمبريسير",
    "الأختام",
    "إعجاب",
    "الإنطباع",
    "للتحوير",
    "الإنطباعية",
    "الإنطباعي",
    "إنطباعي",
    "الإنطباعيون",
    "الإنطباعات",
    "للإعجاب",
    "مطبوع",
    "إسجن",
    "مسجون",
    "يسجن",
    "الاستبعاد",
    "إحتمالية",
    "استبعاد",
    "محتملة",
    "إرتجالا",
    "للتحسين",
    "الإسراف",
    "مبذر",
    "إمبروفيسات",
    "صفيق",
    "إمبولس",
    "أبدى",
    "رغبة",
    "قوى",
    "إبداء",
    "الدوافع",
    "الحصانة",
    "الشائبة",
    "للإسناد",
    "إنسب",
    "بالإتجاه",
    "المحطة",
    "دقة",
    "التكاسل",
    "مقصود",
    "تافها",
    "السفاهة",
    "ضاهر",
    "ضاهرة",
    "للإرضاء",
    "اللاتطبيقية",
    "ملائمة",
    "تقديرية",
    "اللاتلائمية",
    "لامفصلي",
    "حس",
    "السهو",
    "إفتتاحي",
    "افتتاحيات",
    "إفتتح",
    "مفتتح",
    "يفتتح",
    "الإفتتاح",
    "التنصيب",
    "مشؤوم",
    "النحس",
    "المنتصف",
    "غريزي",
    "الملعب",
    "الواردات",
    "توالد",
    "التوالد",
    "المحدودة",
    "إنكا",
    "اللاتناهي",
    "بدرجة",
    "إنكانت",
    "التعزيم",
    "إنكانتيد",
    "الإعجاز",
    "شعب",
    "الإينكا",
    "متعجل",
    "المحرقون",
    "البخور",
    "يبخر",
    "الإستهلال",
    "إستهلالي",
    "المتخرج",
    "النكاح",
    "المحرم",
    "زاني",
    "البوصة",
    "البوصات",
    "التحرك",
    "يرقة",
    "فراشة",
    "جيوميتريد",
    "أحرق",
    "محزوز",
    "محرض",
    "للإنحدار",
    "إحصر",
    "يحصر",
    "الحصر",
    "للشمل",
    "بضمن",
    "الإدراج",
    "ضمنا",
    "جامح",
    "التستر",
    "متفكك",
    "قياسية",
    "لايشارك",
    "للتبديل",
    "التفرد",
    "يضاهى",
    "اللامقارنة",
    "العاجز",
    "العاجزون",
    "الإبهام",
    "مبهمة",
    "مستوعب",
    "للضغط",
    "للحساب",
    "هام",
    "مهمة",
    "التضاربات",
    "عزاء",
    "جدال",
    "السلس",
    "منفلت",
    "محسوم",
    "صرفية",
    "للتوحيد",
    "الإندماج",
    "توحيدي",
    "معنوي",
    "فسادية",
    "الإستقامة",
    "إنكوست",
    "تصورية",
    "الشكوكية",
    "بشكوك",
    "إزداد",
    "جرم",
    "مجرم",
    "يجرم",
    "التجريم",
    "فقس",
    "يفقس",
    "حضني",
    "الحاضنة",
    "الحاضنات",
    "إنكوبي",
    "الكابوس",
    "إطبع",
    "يغرس",
    "العضالية",
    "العضول",
    "العضولون",
    "للشفاء",
    "التوغل",
    "التوغلات",
    "الفحش",
    "البذائة",
    "مخل",
    "بالآداب",
    "مخلة",
    "إنديكوروسنيس",
    "بإصرار",
    "للدفاع",
    "زوالية",
    "للإزالة",
    "للإثبات",
    "مطعوج",
    "إندينتر",
    "الطعج",
    "عقود",
    "الإستقلال",
    "المستقلون",
    "أنعدام",
    "متعذر",
    "وصفه",
    "إنتفاء",
    "يوصف",
    "إتلافية",
    "اللاحتمية",
    "مفهرس",
    "إنديانابوليس",
    "مؤشر",
    "المشيرون",
    "أشير",
    "الأساليب",
    "الخبرية",
    "إنديز",
    "اللامبالاة",
    "للهضم",
    "المذلة",
    "إنديرا",
    "تمييزه",
    "مدركة",
    "اكتشافه",
    "الطائشة",
    "اللاتفرقة",
    "اللزومية",
    "غنى",
    "الضرورية",
    "متوعك",
    "التوعك",
    "للجدال",
    "للإنفصال",
    "إنحلالية",
    "يتعذر",
    "تميزه",
    "تميز",
    "إنديوم",
    "الفرد",
    "الفردية",
    "الأناني",
    "فردي",
    "سلوك",
    "التشخيصات",
    "التفصيلات",
    "منفرد",
    "الأفراد",
    "يتفرد",
    "تفرد",
    "ألا",
    "تجزئة",
    "للانقسام",
    "صيني",
    "عنود",
    "لقن",
    "يلقن",
    "المدرس",
    "الكسل",
    "خضوعية",
    "قهر",
    "الأندونوسي",
    "أقنع",
    "مستحث",
    "جينات",
    "يقنع",
    "للتولد",
    "المحاثة",
    "مطاوع",
    "الإدخال",
    "حثي",
    "بالحث",
    "الإستقرائية",
    "أدواة",
    "إنغمس",
    "منغمس",
    "الإنغماس",
    "متساهل",
    "إندولجير",
    "ينغمس",
    "التصنيع",
    "الصناعيون",
    "صناعيا",
    "إندوستري",
    "الصناعات",
    "دؤوب",
    "إندويل",
    "السكنى",
    "إندي",
    "اللاوصفي",
    "يمحى",
    "نافع",
    "اللافاعلية",
    "كفء",
    "اللاأناقة",
    "اللاأهلية",
    "فصيح",
    "ظالم",
    "الإساءات",
    "اللاإنصاف",
    "متأصل",
    "خامد",
    "محتوم",
    "الحتمية",
    "الحتمي",
    "اللاعذرية",
    "لايغتفر",
    "اللانفاذية",
    "ينضب",
    "ينفد",
    "قلة",
    "يغتفر",
    "تعليلية",
    "اللاتعليلية",
    "للإنفجار",
    "يفوق",
    "معبرة",
    "للمد",
    "إطفائه",
    "الإنفصام",
    "المعصومية",
    "يشوبها",
    "الطفولات",
    "الرضيع",
    "الوأد",
    "جنود",
    "عائق",
    "عوائق",
    "إينفونا",
    "للتطبيق",
    "أصب",
    "مسببا",
    "للعدوى",
    "موفق",
    "التوفيق",
    "للإستنتاج",
    "الإستدلالات",
    "الدونية",
    "الإبتلاء",
    "الكافر",
    "الكفار",
    "يلاكم",
    "المشاكل",
    "إخترق",
    "مخترق",
    "يخترق",
    "التسرب",
    "التسربات",
    "إختراقي",
    "أدنى",
    "لقيمتين",
    "الأزل",
    "اللانهائية",
    "المتناهية",
    "متناهي",
    "محدودية",
    "المصادر",
    "تناهي",
    "إنفيرميد",
    "المزيد",
    "المزيدة",
    "ألهب",
    "المهيج",
    "يلهب",
    "الإلهاب",
    "الإشتعال",
    "سريعة",
    "الإلتهاب",
    "الإلتهابات",
    "تحريضي",
    "للنفخ",
    "النافخ",
    "تضخمي",
    "إعرابي",
    "تصريفيا",
    "يقوس",
    "اللامرونة",
    "الصرامة",
    "أوقع",
    "المصيب",
    "يوقع",
    "أعلم",
    "معلوماتي",
    "بالمعلومات",
    "المفيدة",
    "يعلم",
    "الإخلال",
    "الإخلالات",
    "الأشعة",
    "الحمراء",
    "التحتي",
    "التحتية",
    "نادر",
    "للضيق",
    "التحلل",
    "اللاإنصهارية",
    "إنجاثر",
    "الساذجة",
    "الإبداعات",
    "إنجيرسول",
    "القالب",
    "رسخ",
    "متشرب",
    "يرسخ",
    "إنجرام",
    "ناكر",
    "الجميل",
    "تزلف",
    "متزلف",
    "يتزلف",
    "التزلف",
    "الحظي",
    "للداخل",
    "أربيي",
    "إسكن",
    "المستنشق",
    "المستنشقات",
    "الإستنشاق",
    "إستنشق",
    "مستنشق",
    "يستنشق",
    "ملازم",
    "التلازم",
    "أصلا",
    "للتوريث",
    "المواريث",
    "يرث",
    "المانعون",
    "عامة",
    "حفاوة",
    "الظلم",
    "جائر",
    "الأولي",
    "بالأحرف",
    "توقيع",
    "المعد",
    "المعدون",
    "أوليا",
    "مستهل",
    "مبدوء",
    "المبادرة",
    "البادئ",
    "البادئون",
    "إحقن",
    "للحقن",
    "محقون",
    "المتوافقة",
    "الحاقن",
    "يحقن",
    "إينجن",
    "مصطلح",
    "للأمريكيين",
    "إجرح",
    "جريح",
    "المؤذي",
    "يجرح",
    "تجريحي",
    "محاية",
    "حبر",
    "وسادة",
    "رصاص",
    "محبر",
    "المحبر",
    "المحبرات",
    "التحبير",
    "الأحبار",
    "المحبرة",
    "المبطن",
    "إنمان",
    "النزيل",
    "النزلاء",
    "المصارين",
    "الفطرة",
    "يعصب",
    "إينوسينس",
    "الأبرياء",
    "إبتدع",
    "مبتدع",
    "يبتدع",
    "الإبتداع",
    "إبداعي",
    "المبتكر",
    "المبتكرون",
    "الإساءة",
    "المبطنة",
    "اللامعدودية",
    "تعد",
    "تلقح",
    "يتلقح",
    "تلقيحي",
    "مسالم",
    "للعملية",
    "المفعول",
    "العطلان",
    "عضويا",
    "المدخلات",
    "إدخالإخراج",
    "المستعلم",
    "المستعلمون",
    "تحقيق",
    "الإستقصاء",
    "بالمحكمة",
    "المحقق",
    "إستقصائي",
    "المسار",
    "يقطعه",
    "المتزلج",
    "لزيادة",
    "سرعته",
    "خادمات",
    "جشع",
    "بجشع",
    "الحشرة",
    "إنسيكتيفور",
    "آمن",
    "الإطمئنان",
    "الأمان",
    "إكتراث",
    "البرود",
    "ادخل",
    "الواضع",
    "المطلع",
    "المطلعون",
    "الدواخل",
    "بصيرة",
    "بتبصر",
    "التبصر",
    "بتفاهة",
    "تلميحيا",
    "دسي",
    "أصرر",
    "إجتماعية",
    "إنطوائية",
    "لدرجة",
    "للشمس",
    "النعال",
    "فيها",
    "المؤرق",
    "المؤرقون",
    "المبالاة",
    "مفتش",
    "بالتفتيش",
    "المفتشون",
    "يفتش",
    "إلهامي",
    "ألهم",
    "ملهم",
    "الملهم",
    "يلهم",
    "بإلهام",
    "الإستقرار",
    "القسط",
    "الأقساط",
    "تنصيبإزالة",
    "الفورية",
    "اللحظة",
    "آني",
    "التجسيدات",
    "اللحظية",
    "اللحظات",
    "بدلا",
    "مشط",
    "أمشاط",
    "تحريكي",
    "ثوري",
    "إغرس",
    "مغروس",
    "الغريزة",
    "بالغريزة",
    "الفطرية",
    "الغرائز",
    "مؤسساتي",
    "المؤسسي",
    "المؤسساتية",
    "التثقيفية",
    "المدربون",
    "يأمر",
    "المعزوفات",
    "الواسطة",
    "توزيع",
    "واهي",
    "لايحتمل",
    "تحتمل",
    "العازل",
    "العوازل",
    "الأنسيولين",
    "مهان",
    "الإستعصاء",
    "إستعصاء",
    "يحتمل",
    "يقمع",
    "المأمن",
    "أمن",
    "مؤمن",
    "إنجر",
    "يأمن",
    "المتمردون",
    "مستعصية",
    "المتمرد",
    "لايتأثر",
    "بتحصن",
    "المعنوية",
    "الحاجات",
    "الصحيح",
    "الأعداد",
    "الصحيحة",
    "متكامل",
    "يكامل",
    "المكاملة",
    "التكامل",
    "إنتيل",
    "الفكر",
    "التثقفية",
    "الإستخبارات",
    "إنو",
    "المشرفون",
    "يعتزم",
    "ينوى",
    "المشدد",
    "المشددات",
    "يشتد",
    "إشتد",
    "الإشتداد",
    "التوتر",
    "بالشدة",
    "كثيفة",
    "كثافةالإضاءة",
    "تكثيف",
    "النوايا",
    "إنتر",
    "تفاعل",
    "متفاعل",
    "التفاعل",
    "التفاعلات",
    "تفاعلي",
    "التفاعلية",
    "جزئين",
    "تهاجن",
    "مقحم",
    "أقحم",
    "يقحم",
    "بيوم",
    "إقحامي",
    "توسط",
    "المتوسل",
    "يتوسط",
    "التوسط",
    "التعدادات",
    "المتصدي",
    "الاعتراض",
    "الشفاعة",
    "توسطي",
    "الشفيع",
    "المبادل",
    "الاتصال",
    "للقارات",
    "المبرد",
    "الضلوع",
    "إنترديكتيف",
    "مانعة",
    "مهتما",
    "للأنتباه",
    "الإمتاع",
    "متدخل",
    "التدخلات",
    "المتدخل",
    "يتدخل",
    "بتدخل",
    "التداخل",
    "للفيروسات",
    "الأقحام",
    "شابك",
    "يشابك",
    "الموضوعة",
    "صفحات",
    "السطور",
    "الترابطات",
    "إنترليسب",
    "شحوم",
    "إنترلوب",
    "تطفل",
    "المتطفلون",
    "يتطفل",
    "التطفل",
    "الوسطاء",
    "التناغم",
    "يتناغم",
    "اللانهاية",
    "إختلط",
    "يختلط",
    "الإختلاط",
    "التقطع",
    "إنترمودول",
    "التطبع",
    "الدولية",
    "بالدولية",
    "إنترنيشناليتي",
    "مدول",
    "يدول",
    "التدويل",
    "الدوليون",
    "الممارس",
    "الإعتقالات",
    "تبادل",
    "البيني",
    "الكواكب",
    "محرف",
    "موسط",
    "يوسط",
    "المترجم",
    "المترجمون",
    "خلو",
    "متبادلة",
    "المتبادلة",
    "يترابط",
    "الإستجوابات",
    "ضمير",
    "الإستفهام",
    "إستجوابي",
    "ضمائر",
    "المستجوب",
    "استفهاميات",
    "المستجوبون",
    "للمقاطعة",
    "تقاطعي",
    "يقاطع",
    "التقاطعات",
    "يتقاطع",
    "بعثر",
    "التناثر",
    "إنترستاج",
    "المحطات",
    "فراغي",
    "الفترات",
    "للتدخل",
    "فقرات",
    "المقابلة",
    "المقابلون",
    "المقابلات",
    "شوبك",
    "وصية",
    "الانتفاضة",
    "الفلسطينية",
    "الحميميات",
    "إنتيمال",
    "بحميمية",
    "التنويه",
    "التنويهات",
    "مخاف",
    "يخيف",
    "الإخافة",
    "التسامح",
    "تسامح",
    "رتل",
    "الترنيم",
    "مرتل",
    "يرتل",
    "الترتيل",
    "ثملة",
    "العضل",
    "عضلي",
    "العنيدون",
    "اللازم",
    "المتكونة",
    "رئوي",
    "وريدي",
    "الجرءة",
    "الفاتن",
    "الفاتنون",
    "الدسائس",
    "إينترينساكس",
    "المكتبية",
    "Xlib",
    "إنترو",
    "تمهيدي",
    "تمهيديا",
    "الافتتاحي",
    "التفحص",
    "متعمق",
    "بتعمق",
    "وضعك",
    "مشاعرك",
    "الإنطواء",
    "إنطوائي",
    "الإنطوائي",
    "الدخيل",
    "الدخلاء",
    "تدخلي",
    "إبقاء",
    "مفتوحا",
    "حدسي",
    "البداهة",
    "الدهدان",
    "فض",
    "مفيض",
    "يفيض",
    "إغز",
    "المحتل",
    "المحتلون",
    "السقام",
    "باطلا",
    "ثمين",
    "الباهض",
    "الثابتية",
    "الثابت",
    "الإحتلال",
    "الاحتلال",
    "شاتم",
    "مندد",
    "المحتج",
    "يندد",
    "إستدرج",
    "مستدرج",
    "المستدرج",
    "يستدرج",
    "الإستدراج",
    "إخترع",
    "الإختراعية",
    "أحصى",
    "الموجودات",
    "المخترعون",
    "الجرد",
    "بالممتلكات",
    "يخترع",
    "شال",
    "أكمام",
    "العكوس",
    "عكسي",
    "المقلوب",
    "فقريات",
    "العاكسون",
    "للعكس",
    "إستثمر",
    "مستثمر",
    "الإستثمار",
    "الإستثمارات",
    "المستثمرون",
    "يستثمر",
    "مكتمل",
    "حسدا",
    "الإنتعاش",
    "اللاقهر",
    "الإختراقات",
    "إختراقات",
    "يمس",
    "الخفاء",
    "بخفاء",
    "مقصور",
    "للمدعوين",
    "المدعو",
    "المدعوون",
    "ينادي",
    "فاتورة",
    "تنظيم",
    "تضرع",
    "متضرع",
    "المستشهد",
    "المستشهدون",
    "يتضرع",
    "التضرع",
    "اللاإرادية",
    "الإلتفاف",
    "المحتوي",
    "أيو",
    "أحبها",
    "زيوس",
    "نافذة",
    "والإخراج",
    "والخارج",
    "برمجيات",
    "اليود",
    "اليوديد",
    "باليود",
    "أيوني",
    "التأين",
    "للتأين",
    "أين",
    "مؤين",
    "المؤين",
    "يأين",
    "التأيين",
    "الأيونوسفير",
    "أيونوسفيري",
    "الآيونات",
    "الأيوتا",
    "وعد",
    "بدفع",
    "آيوا",
    "إيبيكا",
    "إيبسو",
    "منظمة",
    "الدراسات",
    "التحليلية",
    "الآيرلندي",
    "الإيراني",
    "العراق",
    "العراقي",
    "غضوب",
    "غضوبا",
    "إيرلنده",
    "إرين",
    "متخيف",
    "قزحي",
    "قزحيا",
    "الإيريديوم",
    "آيريس",
    "القزحيات",
    "آيرلندي",
    "الآيرلنديون",
    "الآيرلندية",
    "أتعب",
    "الإتعاب",
    "يتعب",
    "بمشقة",
    "إرما",
    "سخرية",
    "التهكمية",
    "شجاعة",
    "ذوو",
    "القوي",
    "الأشغال",
    "إيروكويس",
    "يضيئ",
    "عقلانية",
    "عقلانيون",
    "إيراوادي",
    "ميانمار",
    "للإسترداد",
    "إسترجاعه",
    "للحل",
    "لايسترد",
    "الإسترجاع",
    "لايعوض",
    "تحرري",
    "للإنقاص",
    "للدحض",
    "اللاتناقضية",
    "لايقبل",
    "المخالفات",
    "إنتظام",
    "إريجولارس",
    "علاقية",
    "عضال",
    "لايمكن",
    "إصلاحه",
    "الإبدال",
    "تستبدل",
    "الكبت",
    "تحاشي",
    "متكاثر",
    "يقاوم",
    "بغض",
    "بالمسؤلية",
    "لامبالي",
    "الإكتراث",
    "مسؤولية",
    "القدرةعلى",
    "القطعية",
    "إسق",
    "يسقي",
    "الري",
    "التهيجات",
    "بانفعال",
    "المهيجات",
    "الإقتحام",
    "إقتحامي",
    "الداخليه",
    "إرفين",
    "إرفينج",
    "إروين",
    "إسحاق",
    "إيساكس",
    "أسترالي",
    "إيزاسون",
    "إيزابيل",
    "إيزابيلا",
    "إيزادور",
    "أشعيا",
    "رقمية",
    "خرمات",
    "إصفهان",
    "إيسينج",
    "جيلاتين",
    "شفاف,",
    "نقي",
    "إيزيس",
    "الربة",
    "المعبودة",
    "المصريين,",
    "الإسلام",
    "إسلام",
    "إسلامي",
    "إيزلانديا",
    "لعمل",
    "الأيزو",
    "آيسو",
    "الأيسوبار",
    "بتساوي",
    "أيزوسيانيت",
    "الإنعزالية",
    "المعتزلي",
    "انعزالي",
    "المعتزلة",
    "أيسولد",
    "أسطورة",
    "أرثرايان,",
    "الأميرة",
    "الأيرلندية",
    "تزوجت",
    "كورنوول",
    "الأيسومر",
    "أيسومري",
    "المماثل",
    "يرسم",
    "الخريطة",
    "بالتوصيل",
    "أيسوبرين",
    "الآيزوبروبيل",
    "الأيسوثرم",
    "توحد",
    "إسرائيل",
    "الإسرائيلي",
    "بإسرائيل",
    "مواطنيها",
    "الإسرائيليون",
    "الإصدار",
    "القضايا",
    "إسطنبول",
    "البرزخ",
    "البرازخ",
    "إيزتفان",
    "الإيطاليون",
    "إتاليس",
    "الحكة",
    "محكوك",
    "كرر",
    "مكرر",
    "يكرر",
    "التكرار",
    "تكراري",
    "عناصر",
    "إيثيكا",
    "الرحلة",
    "تكنولوجيا",
    "إيفان",
    "إفانهوي",
    "إفيرسون",
    "باللبلاب",
    "آيفي",
    "تسعة",
    "إزفيستيا",
    "الأبرة",
    "وخز",
    "جابلونسكاي",
    "الأبر",
    "جاك",
    "المشتغل",
    "الجزمة",
    "جاكيد",
    "جاكير",
    "جاكت",
    "الستر",
    "جاكي",
    "المطواة",
    "جاكمان",
    "جاكسن",
    "جاكسون",
    "جاكسونفيل",
    "صحيفة",
    "يعقوب",
    "يعقوبي",
    "جاكوبي",
    "بطراز",
    "ديكور",
    "جيمس",
    "جاكوبس",
    "جاكوبسين",
    "جاكوبسون",
    "جاكوبوس",
    "جاكلين",
    "اليشم",
    "بانهاك",
    "الساقطات",
    "الكركر",
    "بتعرج",
    "ييجرز",
    "التكسير",
    "جاجي",
    "النمور",
    "خريج",
    "خريجو",
    "جيم",
    "جاكارتا",
    "جايك",
    "المرحاض",
    "القراضة",
    "المربى",
    "جامايكا",
    "الجامايكي",
    "العضادة",
    "العضائد",
    "جيمسن",
    "جيمستاون",
    "المربات",
    "يناير/كانون",
    "جين",
    "جانيرو",
    "جاينسفيل",
    "الخشخاشة",
    "الخشخشات",
    "جانيس",
    "الإنكشاريون",
    "الإنكشاري",
    "بصيانة",
    "يونس",
    "جانسين",
    "كورنليوس",
    "يناير",
    "يانوس",
    "رومانية",
    "اليابان",
    "الجرة",
    "المفردة",
    "التخصصية",
    "المفردات",
    "الجرار",
    "جارفين",
    "الياسمين",
    "جايسون",
    "كاسبر",
    "اليشب",
    "جاسترو",
    "اليرقان",
    "تصفر",
    "بياض",
    "بتبختر",
    "النزه",
    "برمجة",
    "الحاسب",
    "جاوا",
    "جاوي",
    "سكريبت",
    "الرمح",
    "الرماح",
    "الفك",
    "القاسية",
    "الفكاك",
    "بالفك",
    "جاي",
    "جايسي",
    "جازي",
    "الأزهى",
    "غيور",
    "الغيرة",
    "بغيرة",
    "جينيت",
    "الجينز",
    "تعبير",
    "باستهزاء",
    "جيفيرسن",
    "توماس",
    "جيفرسون",
    "متعلقون",
    "جيفريز",
    "فلكي",
    "اليهوه",
    "بضحالة",
    "الضجر",
    "ألأمعاء",
    "الدقيقة",
    "الإثنا",
    "اللفيفي",
    "هلاميا",
    "جيلو",
    "قنديل",
    "جينكنز",
    "جينيفر",
    "جينينجس",
    "جينسين",
    "أرميا",
    "جيرمي",
    "أريحا",
    "مرجوج",
    "متقفز",
    "بتقفز",
    "جيركين",
    "الرجيج",
    "جيروم",
    "جيرزي",
    "القدس",
    "جيسي",
    "جيسيكا",
    "منكت",
    "التنكيت",
    "الدعابات",
    "يسوعي",
    "اليسوعيون",
    "فاحم",
    "النفاثة",
    "أرصفة",
    "إلقاء",
    "رصيف",
    "الجوهرة",
    "مرصع",
    "بالجواهر",
    "الجواهري",
    "الجواهريون",
    "بالمجوهرات",
    "جيول",
    "جويت",
    "يهودي",
    "التأرجح",
    "هزهز",
    "مهزهز",
    "يهزهز",
    "الهزهزة",
    "منشار",
    "التخريم",
    "الجهاد",
    "جيل",
    "إهجر",
    "محبوبته",
    "جيمينيز",
    "جيمي",
    "بعتلة",
    "جيميس",
    "مجلجل",
    "جينجلير",
    "الطنطنة",
    "الجلجلة",
    "جينجلي",
    "القومية",
    "قومي",
    "جلب",
    "جالبو",
    "نرفز",
    "المولع",
    "بالرقص",
    "الجتربغ",
    "منرفز",
    "النرفزة",
    "جوان",
    "جوانا",
    "جوانز",
    "جوكين",
    "بشغل",
    "بالمقاولة",
    "إستغلال",
    "جوبينج",
    "جوك",
    "تسابق",
    "التسابق",
    "الاسطوانات",
    "محبا",
    "للهزل",
    "بنطلون",
    "جودي",
    "يوئيل",
    "هرول",
    "مهرول",
    "إرتج",
    "مهتزة",
    "يرتج",
    "يهرول",
    "يوهان",
    "يوهانز",
    "جوهانسبيرغ",
    "جوهانسين",
    "جوهانسون",
    "جون",
    "جوني",
    "جونس",
    "جونسين",
    "جونسن",
    "جونستون",
    "جونستاون",
    "جويس",
    "إنضم",
    "النجار",
    "الإنضمام",
    "بتمفصل",
    "النكتة",
    "النكات",
    "النكاتون",
    "جوليت",
    "جولا",
    "بالمزاح",
    "الطيب",
    "جوليس",
    "الصاعق",
    "جوناثان",
    "جونز",
    "الجيران",
    "المجاورة",
    "جوبلين",
    "جورجينسين",
    "جورجينسون",
    "خوزيه",
    "يوسف",
    "جوزيفين",
    "جوزيفسون",
    "جوزيفوس",
    "جوش",
    "المازح",
    "يوشع",
    "جوشيا",
    "الزحمة",
    "متدافع",
    "يتدافع",
    "التدافع",
    "الجول",
    "مهتز",
    "يهتز",
    "المجلة",
    "الصحافة",
    "صحفي",
    "صحفيا",
    "الصحفيون",
    "يدون",
    "المدون",
    "المدونون",
    "جوفانوفيتش",
    "البشاشة",
    "بكوكب",
    "اللغد",
    "الألغاد",
    "بألغاد",
    "ثقيلة",
    "مرتخية",
    "مبتهجا",
    "مغموم",
    "سياقة",
    "الإبن",
    "خوان",
    "خوانيتا",
    "كين.",
    "سفر",
    "يبتهج",
    "اليوبيل",
    "يهوذا",
    "يهودا",
    "الأحكام",
    "حكمي",
    "للمحاكمة",
    "قضائيا",
    "سلطات",
    "القضائية",
    "سلطة",
    "متعقل",
    "جوديث",
    "الجودو",
    "جودسون",
    "الطاغوت",
    "أواني",
    "فخارية",
    "جوجوسلافيا",
    "الوداجي",
    "عصر",
    "عصيرا",
    "ممتعة",
    "العصيرية",
    "يوليو/تموز",
    "الجلاب",
    "جولز",
    "جوليا",
    "جوليان",
    "جولي",
    "جوليو",
    "جوليوس",
    "مخلوط",
    "الجمبو",
    "طائرات",
    "البلوز",
    "يونيو/حزيران",
    "الجنك",
    "جانيو",
    "جانج",
    "نظرياته",
    "للسيكولوجيا",
    "مليئة",
    "بالأشجار",
    "الصغار",
    "العرعر",
    "الخردوات",
    "اليونكر",
    "بروسيا",
    "البروسية",
    "يونكرز",
    "صانعها",
    "وسميت",
    "إسمه",
    "وليمة",
    "ضيف",
    "كضيف",
    "الحشاش",
    "الحشاشون",
    "الشراعية",
    "جونو",
    "الجوراسي",
    "جوراسي",
    "جور",
    "هيئات",
    "المحلفين",
    "فقهي",
    "القانوني",
    "القانونيون",
    "المحلف",
    "المحلفون",
    "جستر",
    "للتبرير",
    "التبريرات",
    "المبرر",
    "المبررات",
    "يبرر",
    "برر",
    "جستين",
    "بصراع",
    "جستينايان",
    "بمعاهد",
    "قوانين",
    "الرومان",
    "بعدل",
    "الجوت",
    "بروز",
    "البروزات",
    "برز",
    "الحداثة",
    "صفف",
    "يصفف",
    "التصفيف",
    "التراصف",
    "كي",
    "الحادي",
    "كابوم",
    "لجهاز",
    "الأتاري",
    "كابوكي",
    "لدراما",
    "كابول",
    "واكبر",
    "كاديش",
    "يهودية",
    "يومية",
    "كافكا",
    "بفرانز",
    "مؤلفاته",
    "كاجار",
    "حكمت",
    "كاكو",
    "كالامازو",
    "المشكال",
    "متلون",
    "كالميا",
    "كالموك",
    "كامتشاتكا",
    "إنتحاري",
    "الانتحاريون",
    "كامبالا",
    "الكنغر",
    "الكناغر",
    "كانكاكي",
    "كانساس",
    "كانت",
    "الكاولين",
    "الكولنيت",
    "كابلان",
    "الكابوك",
    "كابا",
    "كراتشي",
    "كارامازوف",
    "الكراتيه",
    "القراريط",
    "كارين",
    "كارب",
    "كاسكاسكيا",
    "كايت",
    "كاثلين",
    "كايتي",
    "كاتماندو",
    "كاتوايس",
    "كاز",
    "الكافا",
    "كاي",
    "كايو",
    "كازاخستان",
    "بايت",
    "كيبيبل",
    "قاموس",
    "لافتة",
    "فارغة",
    "كيلو",
    "بت",
    "بايتث",
    "سعرة",
    "زخارف",
    "النوافذ",
    "كرون",
    "كيدي",
    "كلاتغيراسمالمستضيف",
    "كيتون",
    "كيتس",
    "الكباب",
    "فيلة",
    "كيجان",
    "عارضة",
    "القعر",
    "كيلير",
    "عارضات",
    "كيلسون",
    "كينان",
    "حماسا",
    "كينينج",
    "الحرص",
    "العيش",
    "التذكار",
    "كيشوند",
    "كيث",
    "كيلوج",
    "الجدرة",
    "كيلسي",
    "كيلفن",
    "كيمب",
    "كيندل",
    "كينيلورث",
    "كينيكوت",
    "كندي",
    "كينيث",
    "كيني",
    "كينو",
    "كينوشا",
    "كينسينجتون",
    "كينت",
    "كينتن",
    "كنتاكي",
    "كينيا",
    "الكيني",
    "كينيون",
    "كيبلير",
    "أبقى",
    "القرتين",
    "مغطي",
    "بمنديل",
    "كيرميت",
    "إتصالات",
    "كيرن",
    "اللب",
    "الألباب",
    "كيرنيغان",
    "تقليص",
    "النفط",
    "كيري",
    "كيريجما",
    "كيسلير",
    "العاسوق",
    "الكيتون",
    "الكيتوزية",
    "كيترينج",
    "المغلاة",
    "حاسبة",
    "اليورو",
    "كيفين",
    "كواسكم",
    "كووني",
    "بلوحة",
    "الأورغ",
    "مقفول",
    "كييس",
    "ثقوب",
    "مفتاح",
    "مفاتيح",
    "كيينيز",
    "الأساسات",
    "خرم",
    "الخرام",
    "الدليلية",
    "مميزة",
    "كيز",
    "كفلوبي",
    "كيه",
    "السوفيتي",
    "الخاكي",
    "الخمير",
    "خراشتشيف",
    "ذبذبة",
    "إطحن",
    "كيبتزيم",
    "مستوطنة",
    "إستئذان",
    "الركلة",
    "كيكابو",
    "مرفوس",
    "الراكل",
    "الراكلون",
    "الرفس",
    "الركلات",
    "كيد",
    "اختطف",
    "الكلى",
    "ماعز",
    "كيفير",
    "كيل",
    "كيغالي",
    "الكيكيو",
    "كيلجور",
    "الزقزاق",
    "كيليبرو",
    "مقتول",
    "القتلة",
    "هادم",
    "اللذات",
    "الكيلو",
    "البود",
    "إرسال",
    "الكيلوبت",
    "دولار",
    "الكيلوبايت",
    "الكيلوبايتات",
    "بالكيلو",
    "كيلوجوس",
    "الكيلوغرام",
    "الكيلوغرامات",
    "الكيلوهيرتز",
    "وتساوي",
    "1000",
    "اوم",
    "الكيلوجول",
    "الكيلومتر",
    "الكيلومترات",
    "الكيلوطن",
    "طن",
    "كيلوتونس",
    "الكيلوفولت",
    "الواط",
    "واط",
    "كيلوات",
    "التنورة",
    "جيدة",
    "التنورات",
    "كيم",
    "كيمبول",
    "كيمبيرلي",
    "الكيمونو",
    "أرحم",
    "روضة",
    "بطيبة",
    "طيبة",
    "أوقد",
    "يوقد",
    "عطفا",
    "الكينماتيكا",
    "إحساس",
    "تحركات",
    "حركيا",
    "المملكة",
    "تجيء",
    "الممالك",
    "الرفراف",
    "الرفرافات",
    "لمملكة",
    "ملوكي",
    "كينجليك",
    "الملوكية",
    "كينجسبري",
    "كنجسلي",
    "كينجستون",
    "كينجوود",
    "متلوى",
    "أغرب",
    "التلوي",
    "الأقرباء",
    "كينزي",
    "الأهل",
    "القريبة",
    "القريبات",
    "كيوا",
    "كيبلينج",
    "السلمون",
    "كيربي",
    "كيرتشنير",
    "كيرتشوف",
    "كرباتيا",
    "كيرك",
    "كيركلاند",
    "كيركباتريك",
    "كيركوود",
    "كيروف",
    "كيرش",
    "بروندي",
    "القبلة",
    "للتقبيل",
    "مقبل",
    "المقبل",
    "المقبلون",
    "القبل",
    "العدة",
    "كيتاكيشو",
    "كيتشنر",
    "الورقي",
    "الهريرة",
    "قطط",
    "هريرة",
    "الهريرات",
    "البسيسات",
    "دغدغ",
    "البسيسة",
    "الكيفة",
    "قرية",
    "بويبلو",
    "كيفو",
    "رجالية",
    "وتعنى",
    "بخدمة",
    "الكيوي",
    "كو",
    "كلوكس",
    "كلان",
    "الكوكلوكس",
    "كلينيكس",
    "كلين",
    "كلينروك",
    "احرف",
    "كلاين",
    "كلوف",
    "وادي",
    "لورينز",
    "أهوج",
    "لمشكلة",
    "الخرقاء",
    "الكليسترون",
    "ماتريكس",
    "حافظة",
    "فيلم",
    "ناب",
    "ناير",
    "نافيريس",
    "إعجن",
    "للعجن",
    "نيدر",
    "العجن",
    "يعجن",
    "الرضفة",
    "الرضفات",
    "نيد",
    "نيينج",
    "إسجد",
    "ركع",
    "الراكع",
    "يسجد",
    "نيباد",
    "نيبادس",
    "الركب",
    "النعي",
    "دقات",
    "ساجد",
    "نيك",
    "نيكير",
    "الكلسون",
    "الزهيدة",
    "نيفيليك",
    "نايتيد",
    "نايتينج",
    "نايتليلي",
    "نايتلنيس",
    "فروسي",
    "نايتسبردج",
    "يحوك",
    "محيك",
    "نيتير",
    "نيتوير",
    "عقدا",
    "عقدي",
    "قاضي",
    "أرضا",
    "مقرعة",
    "مقارع",
    "القاضية",
    "مبهرة",
    "سميكة",
    "مقروع",
    "نوب",
    "تخرج",
    "نوت",
    "معقود",
    "إعرف",
    "للمعرفة",
    "العارف",
    "بمعرفة",
    "نولز",
    "ناولتون",
    "نوكس",
    "نوكسفيل",
    "قذفة",
    "تلقى",
    "البطيئة",
    "نودسين",
    "أمريكي,",
    "ندسون",
    "مخرش",
    "نث",
    "نتسين",
    "نتسون",
    "الكوالا",
    "كوباياشي",
    "كوتش",
    "كوداكروم",
    "كوداك",
    "كودياك",
    "دب",
    "كودوميتر",
    "كونيج",
    "كوينيجسبيرج",
    "كولر",
    "كوينونيا",
    "كومبو",
    "كونج",
    "كونكاني",
    "ماراثي",
    "لغربي",
    "كونكيورر",
    "خبلا",
    "القرآن",
    "كوريا",
    "الكوريون",
    "للشريعة",
    "مباحا",
    "كوفاكس",
    "كواليوسكي",
    "كوالسكي",
    "كولون",
    "كبف",
    "أشكال",
    "كرايمير",
    "كرافت",
    "كراكاتوا",
    "كراكو",
    "كرامير",
    "كروس",
    "كروت",
    "كريبز",
    "الكرملين",
    "كريسج",
    "كريوزكويميل",
    "كريوزانجس",
    "كريجير",
    "كريشنا",
    "كرونيكير",
    "كرويجير",
    "كروغار",
    "الكريبتون",
    "السعودية",
    "مؤقتك",
    "جامعة",
    "الكويت",
    "جائزة",
    "كدزو",
    "كرمة",
    "شرقية",
    "الكولاكي",
    "البرتقال",
    "الذهبي",
    "كردي",
    "كورت",
    "مدهور",
    "كمستخدم",
    "نقص",
    "البروتين",
    "كايل",
    "كايوتو",
    "قرغيزستان",
    "بذات",
    "وبما",
    "أنزل",
    "سبحانه",
    "وتعالى",
    "إل",
    "لوس",
    "أنجلوس",
    "الطوابع",
    "واضعو",
    "شفوي",
    "شفويا",
    "بالشفة",
    "والأسنان",
    "المختبرات",
    "شغليا",
    "الإجهاد",
    "اللبرادور",
    "الأطلنطي",
    "اللابرادوريت",
    "سليكات",
    "المتاهة",
    "لاك",
    "مزق",
    "يمزق",
    "تمزقي",
    "لاسيرتا",
    "ليسوينج",
    "بالخيوط",
    "دمعي",
    "متكاسل",
    "لاكوانا",
    "لاكير",
    "كخادم",
    "الإفتقار",
    "باهت",
    "اللاكروس",
    "لاكتيت",
    "أفرز",
    "يفرز",
    "إفراز",
    "الإرضاع",
    "بإفراز",
    "لبني",
    "اللاكتوز",
    "بحيري",
    "مخرم",
    "الفتى",
    "السلالم",
    "لاديد",
    "المغرفة",
    "مغروف",
    "المغرفات",
    "الفتيان",
    "الدعسوقة",
    "الخنافس",
    "لاديفرن",
    "بسيدة",
    "لافايت",
    "بيرة",
    "اللاجر",
    "المتخاذل",
    "البطىء",
    "المتخاذلون",
    "متلكأ",
    "البحيرة",
    "البحيرات",
    "لاجوس",
    "نيجيريا",
    "لاغرانج",
    "لاجير",
    "لاجونا",
    "لاهور",
    "ليدلو",
    "منام",
    "لينج",
    "ليرد",
    "ليسيز",
    "العلماني",
    "لاكيهورست",
    "لاكيسيد",
    "لاكيوود",
    "ذوبان",
    "الهيموجلوبين",
    "بلازما",
    "لامار",
    "لامارك",
    "لمدا",
    "النور",
    "الضياء",
    "هفاف",
    "لامبير",
    "اللامبرت",
    "الحملان",
    "السحيف",
    "لاميد",
    "رقائقي",
    "أعرج",
    "رثى",
    "أقمشة",
    "رقق",
    "مرقق",
    "يرقق",
    "الترقق",
    "التصفيح",
    "السناج",
    "شاعل",
    "القناديل",
    "مهجو",
    "الهجو",
    "لامبورت",
    "الجلكى",
    "أنكليسات",
    "مظلة",
    "مظللات",
    "لانا",
    "لانكاشاير",
    "لانكاستر",
    "الرماحون",
    "المركبة",
    "مراكب",
    "اليابسة",
    "لاندولد",
    "مالكو",
    "ملكيات",
    "لانديس",
    "صاحبات",
    "صاحبة",
    "باليابسة",
    "يفقه",
    "البحار",
    "المعالم",
    "المنظر",
    "المناظر",
    "لانج",
    "مصورة",
    "لانجلاند",
    "لانجفورد",
    "لانغلي",
    "لانجميور",
    "بمعاناة",
    "لانكا",
    "نحافة",
    "لانسينج",
    "الفانوس",
    "الفوانيس",
    "لانثانيد",
    "لانثانام",
    "اللاو",
    "لاوكون",
    "قس",
    "طراودة",
    "أولاده",
    "لاووس",
    "اللاوسي",
    "اللاوسيون",
    "بوذية",
    "التلبيب",
    "بياقة",
    "التلابيب",
    "الجواهرجيين",
    "جواهري",
    "لابلاس",
    "بعمليات",
    "متدلية",
    "تزيينية",
    "الأحضان",
    "منتكس",
    "المنهار",
    "الإنتكاس",
    "النقال",
    "الحاسوبات",
    "النقالة",
    "اللابوينج",
    "لارامي",
    "الجهة",
    "اليسرى",
    "السرقات",
    "لصوصي",
    "لصوصية",
    "اللاريس",
    "طبخ",
    "بشحم",
    "لاريدو",
    "الميتون",
    "أنهم",
    "يحمون",
    "لارجموث",
    "سوداء",
    "قليلا",
    "الوهق",
    "القبرة",
    "لاركن",
    "القبرات",
    "الدلفيون",
    "لاري",
    "لارس",
    "لارسين",
    "لارسون",
    "دودي",
    "حنجري",
    "المجون",
    "يصدر",
    "إشعاعات",
    "الليزر",
    "الكرباج",
    "الجلدات",
    "بالسوط",
    "لاسين",
    "الربقة",
    "بالأنشوطة",
    "مدام",
    "المتحمل",
    "لازلو",
    "المزلاج",
    "المزالج",
    "مؤخرا",
    "المستترون",
    "متقدمة",
    "جانبي",
    "كنيسة",
    "لاتيران",
    "لاتيرايت",
    "مطاط",
    "المخرطة",
    "الترغية",
    "المخارط",
    "الخراطة",
    "لاثروب",
    "لاتيني",
    "متحرر",
    "المراحيض",
    "لاتروب",
    "حديثا",
    "عريش",
    "لاتيمير",
    "والأضلاع",
    "لاتفيا",
    "اللاتفي",
    "بالإحترام",
    "بتبجيل",
    "اللودانوم",
    "إطرائي",
    "الممتدح",
    "لوديرديل",
    "معدات",
    "الضاحكون",
    "الإضحوكة",
    "لافلين",
    "القاذفة",
    "الإصدارات",
    "إغسل",
    "الغسالة",
    "الغسالات",
    "المغسلة",
    "المغاسل",
    "المكوى",
    "منظفو",
    "لورا",
    "الفائز",
    "كرم",
    "بوضع",
    "تاج",
    "الغار",
    "الفائزون",
    "لورين",
    "لورنس",
    "لورنت",
    "لورانس",
    "لوري",
    "لوزان",
    "الحمم",
    "الاغتسال",
    "إغتسل",
    "مغتسل",
    "اللافندر",
    "بنبات",
    "اللافيندر",
    "التعطير",
    "التطييب",
    "يغتسل",
    "الإغتسال",
    "مسرف",
    "مغدق",
    "الإغداق",
    "البذخ",
    "لافويسير",
    "صيدلي",
    "منتهك",
    "منتهكي",
    "لوفورد",
    "الشرعية",
    "المشرع",
    "المشرعون",
    "تشريع",
    "المسؤولون",
    "المعاشب",
    "لورينسيفيل",
    "اللورنسيوم",
    "لوسون",
    "الدعوى",
    "الدعاوي",
    "يصير",
    "محاميا",
    "لاكس",
    "لاكساتيف",
    "رخوا",
    "مستلزمات",
    "الوليد",
    "الوضع",
    "المتخصصين",
    "إبان",
    "ليتون",
    "رمية",
    "رميات",
    "لازاروس",
    "الجديد.",
    "أخو",
    "ماري",
    "مارثا",
    "تكاسل",
    "يتكاسل",
    "كسل",
    "كسلا",
    "بتكاسل",
    "كسلان",
    "الكسول",
    "الباون",
    "الباونات",
    "لي",
    "ليتشات",
    "مرصص",
    "رصاصي",
    "الرصاصية",
    "القيادات",
    "قيادي",
    "المصنوع",
    "متقدم",
    "محددو",
    "حشرة",
    "نطاطة",
    "الإيراق",
    "المنظمة",
    "لمنظمة",
    "مسرب",
    "المسرب",
    "نضوحا",
    "التسريب",
    "ليندر",
    "متكأ",
    "طراوة",
    "قفزة",
    "الكبيسة",
    "القافز",
    "يقفز",
    "لير",
    "للتعلم",
    "مكتسبا",
    "المتعلم",
    "المتعلمون",
    "ليري",
    "للتأجير",
    "الإيجار",
    "الأقل",
    "ليذرباك",
    "سلحفاة",
    "مشاة",
    "أورق",
    "مخمر",
    "ليفينورث",
    "التاركون",
    "الأوراق",
    "اللبناني",
    "اللبنانية",
    "لبنان",
    "ليبيسج",
    "داعر",
    "الفجور",
    "الخطابية",
    "سيتم",
    "قرائتها",
    "المحاضرة",
    "محاضر",
    "المحاضرون",
    "المحاضرات",
    "مقاد",
    "سجلات",
    "تطبيق",
    "المستنزف",
    "ليتشيد",
    "المستنزفون",
    "ليدز",
    "الكراث",
    "الخزرة",
    "شابق",
    "إرتيابا",
    "الشبق",
    "الخزر",
    "ليوينهويك",
    "اليسار",
    "إنترنالسبيكير",
    "يسار",
    "اليساريون",
    "يساري",
    "البقية",
    "اليساري",
    "القانونية",
    "المشروعية",
    "قانونيا",
    "ليجاتيد",
    "الإنابة",
    "المفوضيات",
    "منبسط",
    "ومتساوي",
    "أسطوري",
    "ليجيندر",
    "الجحفل",
    "الفيلق",
    "الجحافل",
    "تشريعي",
    "تشريعيا",
    "التشريعي",
    "التشريعية",
    "التقنين",
    "السيقان",
    "بقلي",
    "ليهاي",
    "ليمان",
    "مصرفي",
    "لاي",
    "لايتن",
    "ليلى",
    "ليايزر",
    "المتكررة",
    "ليلند",
    "ليمكي",
    "قضية",
    "تساعد",
    "تسهيل",
    "قضايا",
    "اليرنب",
    "القطبي",
    "اليرانب",
    "ليمل",
    "تفرغ",
    "لعبادة",
    "اللة",
    "لينا",
    "أعر",
    "الإقراض",
    "يعير",
    "تطويلا",
    "الأطوال",
    "طوليا",
    "التساهل",
    "بتساهل",
    "لينين",
    "لينينغراد",
    "اللينينية",
    "اللينيني",
    "لينيكس",
    "ليني",
    "لينور",
    "لينس",
    "بعدسات",
    "لينسير",
    "فوتوغرافيا",
    "صومي",
    "عدسي",
    "العدس",
    "ليو",
    "ليون",
    "ليونا",
    "ليونارد",
    "ليوناردو",
    "ليوند",
    "أسدي",
    "النمر",
    "ليوبولد",
    "ليوبولدفيل",
    "للاعبي",
    "بداء",
    "الجذام",
    "اللبيدوليت",
    "الميكا",
    "جذامي",
    "الإستجابة",
    "للطوارئ",
    "ليروي",
    "السحاقيات",
    "ليزلي",
    "ليسوتو",
    "مستصغر",
    "ليسينج",
    "كاتبة",
    "بريطانية",
    "الدرس",
    "درس",
    "التدريس",
    "خشية",
    "ليستر",
    "دع",
    "الخمود",
    "ليتيتيا",
    "صحافة",
    "رسالة",
    "نوعية",
    "بالحروف",
    "نقاش",
    "المعنونة",
    "الترقين",
    "ليتيرمان",
    "ليتيرمين",
    "الإيجارات",
    "الخس",
    "ليسين",
    "اللوكيميا",
    "ليف",
    "إستوى",
    "المسوون",
    "المسطحون",
    "الإتزان",
    "المسوي",
    "جابي",
    "البرق",
    "ليفين",
    "بيانو",
    "مايسترو",
    "ليفيس",
    "اللاويين",
    "الهزليات",
    "ليفيت",
    "هاديء",
    "لويلين",
    "لويس",
    "ليكس",
    "معجمي",
    "تأليف",
    "المعاجم",
    "ليكسنغتن",
    "ليدن",
    "ليابلي",
    "الكذابون",
    "ليب",
    "ناشر",
    "تشهيري",
    "ليبيريس",
    "التحرري",
    "التحررية",
    "التحرريون",
    "ليبريا",
    "الخليع",
    "الخليعون",
    "شهواني",
    "المقرونة",
    "بالفجور",
    "الميزان",
    "المكتبي",
    "المكتبيون",
    "ليبراريد",
    "ليبرات",
    "نصوص",
    "الأوبرا",
    "نص",
    "ليبريفيل",
    "العاصمة",
    "وأكبر",
    "الجابون",
    "ليبيا",
    "الليبي",
    "الرخصة",
    "أجيز",
    "الحاصل",
    "ترخيص",
    "للترخيص",
    "المرخص",
    "المرخصون",
    "المجيز",
    "مانح",
    "فاجر",
    "الإباحية",
    "الأشنة",
    "بالطحالب",
    "الأشنات",
    "ليتشتينشتاين",
    "ليتختر",
    "اللعقة",
    "ملعوق",
    "اللاعق",
    "اللعق",
    "اللعقات",
    "عرق",
    "السوس",
    "بغطاء",
    "البلاج",
    "ليبرمان",
    "ليختننشتاين",
    "كذب",
    "الولي",
    "لاين",
    "حق",
    "أموال",
    "شريان",
    "الإنقاذ",
    "ميتا",
    "ليفيليسنيس",
    "ليفيليكينيس",
    "ليفر",
    "ليفرس",
    "المنقذ",
    "أسلوب",
    "أساليب",
    "الخول",
    "للرفع",
    "الرافعون",
    "الإقلاع",
    "الأيون",
    "لتشكيل",
    "تعقيدا",
    "إتحادات",
    "الأيونات",
    "ليجت",
    "مسلط",
    "القداحة",
    "القداحات",
    "الإنارات",
    "الخفة",
    "برق",
    "يبرق",
    "القلم",
    "الضوئي",
    "شاشات",
    "الأقلام",
    "لايسمح",
    "بدخول",
    "الأضوية",
    "المنارات",
    "الخفيف",
    "ليجنوم",
    "احتملا",
    "الإمكانيات",
    "مشبه",
    "التشبيه",
    "الأشباه",
    "ليلا",
    "النيلج",
    "الأرجوانية",
    "الفاتحة",
    "ليليان",
    "زنبقي",
    "ليليس",
    "الهزج",
    "نغمي",
    "التغريد",
    "ليم",
    "ليما",
    "ليمبيد",
    "مرشق",
    "الترشيق",
    "الكلس",
    "بالجير",
    "فرن",
    "لحرق",
    "الجير",
    "الساطع",
    "ليمريك",
    "الفكاهية",
    "بمحدودية",
    "الليموزين",
    "ليموزين",
    "العرجة",
    "البطلينوس",
    "ليمكين",
    "العرجات",
    "لينكولن",
    "ليند",
    "ليندا",
    "ليندبيرج",
    "ليندبيرغ",
    "ليندن",
    "ليندولم",
    "ليندكويست",
    "ليندساي",
    "ليندستروم",
    "ليندي",
    "بالخط",
    "الملامح",
    "الخطية",
    "خطية",
    "الظهير",
    "نهايةالسطر",
    "الطابعة",
    "باخرة",
    "الركاب",
    "بواخر",
    "مباعدة",
    "الإصطفافات",
    "لينغالا",
    "المنطوقة",
    "تباطأ",
    "متباطأ",
    "المتسكع",
    "التباطأ",
    "الأمد",
    "يتباطأ",
    "الرطانة",
    "لساني",
    "اللغوي",
    "لغوي",
    "لغويا",
    "اللغويون",
    "المراهم",
    "الزقاقية",
    "مشمع",
    "بذر",
    "الكتاني",
    "عتبة",
    "الهدب",
    "الكتانية",
    "بالكتان",
    "لينوس",
    "لاينكس",
    "المطابق",
    "يونكس.",
    "ألفه",
    "تورفالدس",
    "ليونيل",
    "اللبوة",
    "اللبوات",
    "إحتف",
    "محتفى",
    "المهتم",
    "المهتمون",
    "يحتفي",
    "الإحتفاء",
    "ليببينكوت",
    "شفة",
    "ليبشيز",
    "ليبسكومب",
    "محمرات",
    "ليبتون",
    "المسيل",
    "المسيلون",
    "الكحولي",
    "التصفيات",
    "ليسا",
    "ليزبن",
    "لايز",
    "اللثغة",
    "ملثوغ",
    "باللثغة",
    "اللثغ",
    "لدن",
    "إستمع",
    "مستمع",
    "المستمع",
    "المستمعون",
    "الإستماع",
    "يستمع",
    "المجدولون",
    "متواني",
    "ليستون",
    "امريكي",
    "خسر",
    "بطولة",
    "وزنه",
    "كاسيس",
    "الإبتهالات",
    "اللتر",
    "القراءة",
    "والكتابة",
    "الحرفية",
    "المطبعية",
    "أدبيا",
    "ليترارينيس",
    "الألتار",
    "اللدانة",
    "الليثيوم",
    "الحجرية",
    "المعدن",
    "الطباعات",
    "أديم",
    "بالجزء",
    "ليتوانيا",
    "ليتواني",
    "المشارع",
    "المشارعون",
    "مقاضى",
    "يقاضي",
    "المقاضاة",
    "للتقاضي",
    "يمكنها",
    "تتلون",
    "باللونين",
    "يلقى",
    "بالقاذورات",
    "الملئ",
    "ليتلينيك",
    "ليتلتون",
    "طقوسي",
    "الطقوس",
    "الصلوات",
    "القداس",
    "للعيش",
    "معيش",
    "الإعالة",
    "مكبود",
    "ليفيرمور",
    "ليفربول",
    "بمدينة",
    "ليفيربول",
    "الأكباد",
    "ليفروورت",
    "بالكبدة",
    "الإسطبل",
    "المعيشة",
    "الأرزاق",
    "ليفينجستون",
    "ليفير",
    "كوبيك",
    "ليز",
    "السحلية",
    "السحالي",
    "ليزي",
    "لويد",
    "المحمل",
    "المحملون",
    "الأحمال",
    "المخصبة",
    "الرمل",
    "الفخار",
    "القرض",
    "مقرض",
    "القروض",
    "الأرغفة",
    "المنحنية",
    "مفصص",
    "اللوبيات",
    "منحنية",
    "اللوبي",
    "شحمة",
    "لوبيد",
    "شحمات",
    "الطينية",
    "يخنة",
    "يأكلها",
    "البحارة.",
    "تتكون",
    "والخضروات",
    "سرطان",
    "سرطانات",
    "بشحمة",
    "الموقع",
    "النعرة",
    "الموضعة",
    "يموضع",
    "محليا",
    "لوكاتر",
    "بالموقع",
    "ظرف",
    "ظروف",
    "معينات",
    "لوتشين",
    "الأمكنة",
    "للإحكام",
    "لوكي",
    "المنجد",
    "المناجد",
    "لوكهارت",
    "لوكهيد",
    "لوكينجز",
    "الموظفين",
    "تعاليق",
    "الأقفال",
    "بإيقاع",
    "لوكوود",
    "محامية",
    "يتحرك",
    "التحركات",
    "القاطرة",
    "القاطرات",
    "لوكويد",
    "الجرادة",
    "مغناطيسات",
    "المنتجع",
    "لودجبول",
    "لودويك",
    "لويب",
    "بتعال",
    "لوفتينيس",
    "لوجان",
    "اللوغاريتم",
    "لوغاريتمي",
    "لوغاريثمية",
    "لوغاريثمي",
    "اللوغاريتمات",
    "مقصورة",
    "المسجلات",
    "ونقل",
    "المنطقية",
    "المنطقيون",
    "لعيسى",
    "لوجستيكي",
    "لوجستيكيا",
    "التموين",
    "الإنقطاع",
    "لوجفيو",
    "الخاصرة",
    "مئزر",
    "الخاصرات",
    "لوير",
    "تريث",
    "متريث",
    "التريث",
    "بتواني",
    "يتريث",
    "شارع",
    "خصوصي",
    "الشرير.",
    "إسكندنافية",
    "لولا",
    "لولير",
    "المصاصات",
    "المصاصة",
    "لومبارد",
    "لومباردي",
    "لومي",
    "توجو",
    "ديري",
    "اللندني",
    "البعيدة",
    "أطول",
    "لونج",
    "فيلو",
    "ذوق",
    "الشوق",
    "بشوق",
    "طولي",
    "لونجس",
    "الملاح",
    "ستريت",
    "البعيد",
    "لونا",
    "أنظر",
    "المشاهدة",
    "المرآة",
    "المنوال",
    "ملاح",
    "لوميس",
    "المنوالات",
    "الإرجاع",
    "لوبير",
    "ثغرة",
    "المنافذ",
    "المرخي",
    "الرخاوة",
    "لووس",
    "ستريف",
    "منهوب",
    "شذب",
    "متخطى",
    "لوبس",
    "لوبيز",
    "التخطي",
    "مشذب",
    "التشذيب",
    "يشذب",
    "لوبسيد",
    "أعشاب",
    "الميلان",
    "لوكوات",
    "اللورد",
    "اللوردية",
    "إعوجاج",
    "اللوردات",
    "صفارة",
    "أسطورية",
    "ألمانية",
    "لوريتا",
    "لوريندا",
    "للضياع",
    "إفقد",
    "الخاسر",
    "الخاسرون",
    "الخسران",
    "ياللخسارة",
    "الخسائر",
    "خسائر",
    "بخسارة",
    "المستحضر",
    "المستحضرات",
    "لوت",
    "اليانصيبات",
    "اليانصيب",
    "لوتي",
    "اللوتس",
    "يزيد",
    "تكلم",
    "الصخابة",
    "لويزا",
    "لويز",
    "لويزيانا",
    "لويسيانان",
    "لويسفيل",
    "لونسبري",
    "تجهم",
    "لورد",
    "بلدة",
    "يتجهم",
    "لوسيد",
    "لاوسوورت",
    "الإبتذال",
    "الشريب",
    "جلف",
    "بجلافة",
    "الكوة",
    "المحبوبية",
    "ببغاء",
    "لفجوي",
    "لوفيليس",
    "لفلاند",
    "خالية",
    "الحسن",
    "ملهوف",
    "لافرينج",
    "الأحباء",
    "ولهان",
    "تشتت",
    "جراء",
    "الواطئ",
    "واطئ",
    "لوبوي",
    "لاو",
    "لويل",
    "أوطأ",
    "الأحرف",
    "إنخفض",
    "ينخفض",
    "الأوطأ",
    "السهل",
    "سهلي",
    "تواضعا",
    "سار",
    "الوطوء",
    "الواطئة",
    "الصخرية",
    "موالي",
    "الموالي",
    "الموالون",
    "باخلاص",
    "لويولا",
    "الواسع",
    "التكتيكية",
    "عديمي",
    "لوبوك",
    "لبيل",
    "زيت",
    "زيوت",
    "مزيت",
    "يزيت",
    "التزييت",
    "لوكاس",
    "لوسينت",
    "بلمعان",
    "الحجازي",
    "لوسيا",
    "لوتشيان",
    "مشرق",
    "لوسدا",
    "لوسين",
    "الإبليس",
    "لوسيل",
    "لوتشيوس",
    "حالفه",
    "حظا",
    "الحظوظ",
    "الربحية",
    "لوكريشيا",
    "لوكريتيوس",
    "لوسي",
    "محطمو",
    "خوفا",
    "إحلال",
    "بسخافة",
    "لدلو",
    "لودفيج",
    "در",
    "اللوفتهانزا",
    "لوفتوافا",
    "مزلاجة",
    "متسابق",
    "بمزلاجة",
    "العروات",
    "لوك",
    "لوكيميا",
    "التهويدات",
    "التهويدة",
    "لولو",
    "ألم",
    "الحطاب",
    "الحطابون",
    "لومنيسسينس",
    "مناول",
    "البضائع",
    "كتلا",
    "لومبور",
    "متكتل",
    "لانات",
    "لاناتيد",
    "لاناتيلي",
    "القمري",
    "متغدى",
    "لانتشير",
    "لانتشهور",
    "التغدي",
    "لاند",
    "لاندبيرج",
    "لاندكويست",
    "الرئة",
    "الطعنة",
    "لانجير",
    "الطعنات",
    "الرئتين",
    "الترمس",
    "الجواس",
    "بشحوب",
    "باستدراج",
    "ترصد",
    "مترصد",
    "لوركير",
    "التسنط",
    "يترصد",
    "لاساكا",
    "زامبيا",
    "حلاوة",
    "الألماس",
    "البريق",
    "شهوانية",
    "بشهوة",
    "اﻷلماس",
    "العود",
    "مطين",
    "لوتيتيم",
    "لوثر",
    "لوثري",
    "التطيين",
    "لوز",
    "لوكسمبورغ",
    "لكس",
    "الثراء",
    "بثراء",
    "مرفه",
    "الرفاهيات",
    "بترف",
    "الرفاهية",
    "الترف",
    "لوزون",
    "صالة",
    "صالات",
    "ليكوبوديوم",
    "ليديا",
    "الغسول",
    "ليكيز",
    "لايل",
    "اللمف",
    "ليمفاويين",
    "اللمفية",
    "أعدم",
    "لنتشبيرغ",
    "تفويض",
    "يعدم",
    "لندون",
    "الوشق",
    "الوشوق",
    "ليرا",
    "الغنائية",
    "غنائي",
    "ليريكالنيس",
    "ليسينكو",
    "لسيرجيك",
    "اوتوماتيكي",
    "لتبادل",
    "وتوزيع",
    "أم",
    "مايبيل",
    "الماك",
    "إرصف",
    "يرصف",
    "الرصف",
    "المكاك",
    "آسيوي",
    "معكرونة",
    "المعكرون",
    "حلوى",
    "وسكر",
    "ولوز",
    "ماكآرثر",
    "لمدينة",
    "Ujung",
    "Pandang",
    "بإندونيسيا",
    "ماكو",
    "ماكولاي",
    "ماكبيث",
    "ماكدونالد",
    "ماكدوجال",
    "ماكدراو",
    "الصولجان",
    "ماسيد",
    "مملكة",
    "إغريقية",
    "مقدونيا",
    "المقدوني",
    "نقع",
    "ينقع",
    "تنقيع",
    "ذبول",
    "الصولجانات",
    "ماكجريجور",
    "الماخي",
    "المنجل",
    "ميكافيللي",
    "المنسوبين",
    "ألالة",
    "المكيدة",
    "مكائد",
    "المكائد",
    "الرشاشة",
    "المكنية",
    "يدار",
    "كالآلة",
    "المكائن",
    "مخرطة",
    "الماكنة",
    "مشغلو",
    "بالعضلات",
    "مفتول",
    "بسائل",
    "الماكنتوش",
    "المشمع",
    "ماكينزي",
    "الإسقمري",
    "الأسقمري",
    "ماكي",
    "مضيق",
    "الماكيناوي",
    "Mackintosh",
    "Mackintoshs",
    "ماكميلان",
    "ماكون",
    "ماكبينت",
    "ماكرو",
    "مسؤولة",
    "مختصرات",
    "عياني",
    "واضحا",
    "بنية",
    "ترى",
    "بالعين",
    "تلوث",
    "مدغشقر",
    "مادكاب",
    "الطائشون",
    "خبل",
    "ماديند",
    "مخبل",
    "التخبيل",
    "يخبل",
    "الفوة",
    "مادوكس",
    "تشخيصا",
    "بإنزال",
    "كتبت",
    "فراق",
    "جعلت",
    "المقفر",
    "مقفرا",
    "حبلى",
    "تأكد",
    "أسوأ",
    "الماديرا",
    "الخمرة",
    "ماديرا",
    "(على",
    "أفريقة",
    "الغربي)",
    "مادلين",
    "آنسة",
    "الآنسات",
    "مايج",
    "مستشفى",
    "ماديسن",
    "سيدات",
    "للملابس",
    "السجف",
    "مدريد",
    "غزلية",
    "يتغزل",
    "المجنونة",
    "ماي",
    "إصخب",
    "المافيا",
    "ماجامب",
    "اللاحق",
    "مريم",
    "المجدلية",
    "ماجي",
    "يرقات",
    "المجوس",
    "السحري",
    "السحرية",
    "سحرية",
    "ماجيل",
    "الصلح",
    "الحاكمية",
    "البركانية",
    "الذائبة",
    "ماجنا",
    "الشهامة",
    "شهم",
    "شهامة",
    "المغنيسيوم",
    "كربونات",
    "المغنسيوم",
    "مغناطيسي",
    "triped",
    "مغناطيسيا",
    "للمغنطة",
    "مغنطة",
    "مغنط",
    "ممغنط",
    "يمغنط",
    "التمغنط",
    "مولدات",
    "صمام",
    "تكبير",
    "بارزة",
    "عدسة",
    "مكبرة",
    "ماغنوليا",
    "قناني",
    "ماجوج",
    "العقعق",
    "مهايانية",
    "شعبة",
    "تقول",
    "مهاياني",
    "ماهلير",
    "الماهوغوني",
    "ماهوني",
    "عذراء",
    "أسرة",
    "سفرة",
    "كزبرة",
    "بكارة",
    "حياء",
    "عذري",
    "عوانس",
    "جواري",
    "خادمة",
    "مير",
    "(اسم",
    "علم)",
    "بالبريد",
    "إرساله",
    "بالإمكان",
    "البريدي",
    "البريدية",
    "ميلجرام",
    "للبرقية",
    "ساعي",
    "سعاة",
    "تشوه",
    "مشوهون",
    "يعوق",
    "بند",
    "المفاوضات",
    "الموضوع",
    "مين",
    "إلكترونية",
    "الكبرى",
    "مينك",
    "حقن",
    "مأخذ",
    "شراع",
    "النابض",
    "النوابض",
    "الركن",
    "الأساسي",
    "الأركان",
    "أبق",
    "للدعم",
    "مبقى",
    "الإبقاء",
    "صيانة",
    "المهيبة",
    "الأغلبيات",
    "الأغلبية",
    "أغلبية",
    "كبرىصغرى",
    "بالغون",
    "تحقيقه",
    "يشارك",
    "بالملاطفة",
    "يجامع",
    "زلة",
    "أعداء",
    "نظير",
    "أعذارا",
    "سقط",
    "خجلانا",
    "ازدهر",
    "ضوضاء",
    "هروب",
    "قم",
    "جعلها",
    "دعاية",
    "وقتا",
    "تركيب",
    "Makefile",
    "Makefiles",
    "الطرازات",
    "إرضاء",
    "طرازات",
    "صعبة",
    "تزدهر",
    "آمنة",
    "غبية",
    "متأكدة",
    "البدلاء",
    "المؤقتون",
    "تجعل",
    "أعذار",
    "المتطلبات",
    "التهيؤ",
    "مهايئ",
    "انحرافات",
    "مدغشقري",
    "توعك",
    "مالامود",
    "مسيء",
    "الملاريا",
    "ملاريا",
    "ملاوي",
    "ملايي",
    "المالايالام",
    "ماليزيا",
    "الماليزي",
    "مالكولم",
    "ولايات",
    "مالديفز",
    "قطة",
    "ممرض",
    "مسبة",
    "مسبات",
    "أشرار",
    "ذكورة",
    "حقد",
    "أحقاد",
    "ارتكاب",
    "يتعطل",
    "ماليبو",
    "بهجة",
    "خبيثة",
    "خبث",
    "خباثة",
    "طعن",
    "تمارض",
    "متمارض",
    "يتمارض",
    "التسوق",
    "قابليات",
    "التطريق",
    "مطواع",
    "مطرقة",
    "مطارق",
    "مالوري",
    "الخبازي",
    "تناغم",
    "سنين",
    "متقابلين",
    "نتانة",
    "منتن",
    "مالون",
    "طاحونة",
    "شعير",
    "مالطا",
    "مملت",
    "مالطي",
    "مالثوسي",
    "بالشعير",
    "يعامل",
    "معاملة",
    "مامبو",
    "رقصات",
    "الثديي",
    "الثدييات",
    "الأمهات",
    "الماموث",
    "مجسدة",
    "غل",
    "الانقياد",
    "مقود",
    "مديرة",
    "نيكاراجوا",
    "المنامه",
    "ماناتي",
    "مانشستر",
    "المؤن",
    "أصدر",
    "أوامر",
    "الأفندي",
    "الأفندية",
    "التفويضات",
    "التخويل",
    "منتدبون",
    "إشارة",
    "إلزامية",
    "ماندلبروت",
    "Mandrake",
    "اللفاحات",
    "MandrakeSoft",
    "(عرف",
    "الديك)",
    "مدرسة",
    "أعراف",
    "مناورة",
    "ناور",
    "مناور",
    "مناورات",
    "منغنيز",
    "الجرب",
    "شوندر",
    "المعلف",
    "المعالف",
    "أجرب",
    "بجرب",
    "يعصر",
    "بالضغط",
    "معصرة",
    "المانجا",
    "قرامات",
    "البدنية",
    "كوكتيل",
    "مانهاتان",
    "فتحات",
    "الرجولة",
    "مطاردة",
    "هوس",
    "معتوه",
    "جنوني",
    "هوسي",
    "مدرم",
    "مدرمات",
    "الإظهار",
    "الأنبوب",
    "المتفرع",
    "المنوع",
    "تنوع",
    "الأنابيب",
    "المتفرعة",
    "مانيلا",
    "للظروف",
    "المحيطه",
    "بتعديله",
    "أوتحويره",
    "بهدف",
    "التلاعب",
    "مانيتوبا",
    "مانيتووك",
    "مانكوسكي",
    "نيبراسكا",
    "رجولة",
    "رجولي",
    "مان",
    "المعرضة",
    "الإسلوب",
    "إسلوب",
    "آداب",
    "تهذيب",
    "بالرجال",
    "مسترجلة",
    "المانومتر",
    "المانومترات",
    "الضيعة",
    "الضيع",
    "يدور",
    "مانسفيلد",
    "المتعمد",
    "مانتا",
    "رف",
    "المستوقد",
    "رفوف",
    "تكهني",
    "النبي",
    "الشرك",
    "تلقيم",
    "مانويل",
    "المنتج",
    "المنتجون",
    "تصنيع",
    "سماد",
    "أسمدة",
    "الأسمدة",
    "المخطوطة",
    "المخطوطات",
    "الكثير",
    "تعدد",
    "الأوقات",
    "جهوري",
    "مانزانيتا",
    "ماو",
    "ماوري",
    "القيقب",
    "نيو",
    "يورك",
    "(الأسفندان)",
    "المخططون",
    "مارس/آذار",
    "الماراثون",
    "الماراثونات",
    "الرخام",
    "رخامي",
    "مرخم",
    "الرخامات",
    "بالرخام",
    "مارك",
    "مارسيل",
    "مارسيلو",
    "اخرج",
    "استعراض",
    "قبالة",
    "سير",
    "أمام",
    "مارسيا",
    "ماركو",
    "ماركوت",
    "ماركوس",
    "مارسي",
    "ماردي",
    "مارديس",
    "مارجريت",
    "زبد",
    "النباتي",
    "مارجيري",
    "هامش",
    "هامشي",
    "مشكلة",
    "هامشية",
    "تلويحات",
    "تهميش",
    "البسيط",
    "بهامش",
    "التهميش",
    "مارجو",
    "اللؤلؤ",
    "ماريا",
    "مريمي",
    "ماريان",
    "ماريتا",
    "المخملية",
    "مارجوانا",
    "عشبة",
    "مخدرة",
    "مارلين",
    "ماريمبا",
    "مارين",
    "لرسو",
    "إصلاحها",
    "مارينو",
    "ماريو",
    "ماريون",
    "المنزلة",
    "الأمة",
    "المرور",
    "السمسق",
    "(نبات",
    "عطري)",
    "مؤنث",
    "مرجعية",
    "(شيئا",
    "آخر)",
    "بحد",
    "للوسم",
    "الملحوظ",
    "السوق",
    "تحليلات",
    "سوق",
    "شروط",
    "إقتصاديات",
    "إقتصاد",
    "خضروات",
    "سعر",
    "تقرير",
    "التسويق",
    "رائج",
    "مسوق",
    "المسوق",
    "المبيعات",
    "الاسواق",
    "أسواق",
    "تأشير",
    "ماركوف",
    "روسي",
    "الماركوفية",
    "رماة",
    "الكلسي",
    "مارلبورو",
    "مارلن",
    "مارلو",
    "مربى",
    "الغرير",
    "الخيمة",
    "الخيمات",
    "المركيز",
    "نبلاء",
    "أوروبة",
    "واليابان",
    "ماركيه",
    "المركيزة",
    "المركيزات",
    "زواج",
    "مراسيم",
    "مراسم",
    "توجيه",
    "منتحل",
    "خطبة",
    "للزواج",
    "أهلية",
    "الزيجات",
    "والزوجة",
    "المتزوجة",
    "يتزوج",
    "ماريوت",
    "نخاع",
    "العظم",
    "النخاعي",
    "النخاعية",
    "نخاعات",
    "نخاعي",
    "تزوج",
    "المريخ",
    "مارسيليس",
    "مستنقع",
    "مارشا",
    "منظمون",
    "مارشال",
    "رتبة",
    "عسكرية",
    "الفرز",
    "الهور",
    "الأهوار",
    "الخبازية",
    "المستنقعية",
    "جرابي",
    "الجرابيون",
    "السمور",
    "الفولاذ",
    "الصلد",
    "عسكري",
    "العرفي",
    "مريخي",
    "المريخيون",
    "مارتن",
    "مارتينيز",
    "مارتينجال",
    "المارتيني",
    "مارتينيكوي",
    "مارتينسون",
    "الأسواق",
    "مارتي",
    "الشهيد",
    "شهيد",
    "الشهداء",
    "الأعجوبة",
    "متعجب",
    "الأعاجيب",
    "مارفن",
    "ماركس",
    "الماركسية",
    "الماركسي",
    "ميريلاند",
    "مجملات",
    "يظن",
    "فحولة",
    "المازر",
    "لتضخيم",
    "النبضات",
    "بابتعاث",
    "وحفزها",
    "ماسيرو",
    "الهريس",
    "مزيح",
    "حنطة",
    "ونخالة",
    "وماء",
    "(يقدم",
    "طعاما",
    "للماشية)",
    "هرس",
    "بطاطا",
    "مهروسة",
    "هراسة",
    "هراسات",
    "الهرائس",
    "يهرس",
    "الهريسة",
    "قناع",
    "مبرمج",
    "مقنعا",
    "التنكرية",
    "المشاهد",
    "أقنعة",
    "الماسوشية",
    "المستألم",
    "ماسوشي",
    "سادي",
    "التلذذ",
    "بالألم",
    "المستألمون",
    "يبني",
    "مصنوعة",
    "ألوح",
    "المضغوطة",
    "مباني",
    "تنكر",
    "ألمتخفي",
    "مقنعون",
    "تمثيليات",
    "الانتاج",
    "الشامل",
    "تحشد",
    "سوية",
    "ماسوشوستس",
    "مذابح",
    "مدلك",
    "المتجمع",
    "جماهير",
    "ماسي",
    "كتل",
    "التحشد",
    "ماسينجر",
    "مقال",
    "بالجملة",
    "بسارية",
    "أستاذ",
    "خياط",
    "بمهارة",
    "براعة",
    "بحكمة",
    "المتقن",
    "العقول",
    "الموجهة",
    "النادر",
    "الإجادة",
    "ماستيك",
    "للمضغ",
    "مضغ",
    "ماضغ",
    "الدرواس",
    "الصناجة",
    "الصناجات",
    "سواري",
    "يستمني",
    "استمنى",
    "استمناء",
    "بالأستمناء",
    "حصير",
    "مباراة",
    "الثقاب",
    "متناظر",
    "مباريات",
    "مواءمة",
    "مواءمات",
    "ماتيو",
    "المادي",
    "بمادية",
    "ماديون",
    "متحقق",
    "مجسدون",
    "ماديا",
    "حسي",
    "والتجهيزات",
    "أمومي",
    "الأمومية",
    "الأمومة",
    "الرياضيات",
    "رياضيا",
    "ماثيوز",
    "ماثيوسون",
    "ماثياس",
    "ماذييو",
    "ماتيلدا",
    "ماتينال",
    "النهارية",
    "صلوات",
    "ماتيسي",
    "الأمومي",
    "مصفوفات",
    "طالبا",
    "كلية",
    "امتحان",
    "إلخ",
    "امتحانات",
    "بجامعة",
    "زيجات",
    "خانات",
    "نسوية)",
    "ماتسون",
    "ماتسوموتو",
    "نحاس",
    "ورصاص",
    "ونيكل",
    "ماثيو",
    "ماتيوس",
    "ماتي",
    "حصيرة",
    "معول",
    "معاول",
    "مفارش",
    "ينضج",
    "نضوج",
    "بالنضوج",
    "نضج",
    "استحقاقات",
    "صباحي",
    "فطير",
    "(يأكله",
    "فصحهم)",
    "مود",
    "قوية",
    "إهذ",
    "مورين",
    "موريس",
    "موريسيو",
    "موريتانيا",
    "موريشيوس",
    "الضريح",
    "الأضرحة",
    "البنفسجي",
    "مخضرم",
    "المستفرد",
    "المستفردون",
    "المطربة",
    "مقزز",
    "مثيرة",
    "للغثيان",
    "تقزز",
    "مار",
    "ماكس",
    "ماكسيلاري",
    "حكمة",
    "سائر",
    "بالحد",
    "عماة",
    "المكبرون",
    "أمثال",
    "سائرة",
    "ماكسين",
    "ماكستور",
    "ماكسويل",
    "مايو",
    "المايا",
    "يقطن",
    "وغواتيمالا",
    "والمكسيك",
    "بيضي",
    "ماير",
    "مايفير",
    "ألأناقة",
    "ضرر",
    "الاحتفال",
    "ماينارد",
    "ميونيز",
    "الميونيز",
    "كثيقة",
    "صفار",
    "المخفوق",
    "والخل",
    "والزيت",
    "والتوابل",
    "البلدية",
    "رئاسة",
    "الناغط",
    "الناغطون",
    "مايست",
    "مازدا",
    "للسيارات",
    "ألحيره",
    "طاس",
    "المتاهات",
    "المازوركا",
    "ميغابايت",
    "ماجستير",
    "ادارة",
    "سوازيلاند",
    "ميجابايت",
    "مكأدامس",
    "مكاليستر",
    "مسبرد",
    "مكاب",
    "مكال",
    "مكالوم",
    "مكارثي",
    "مكارتي",
    "مكولي",
    "مكلين",
    "مكليلان",
    "مكلوسكي",
    "مكونيل",
    "مكورميك",
    "مكراكين",
    "مكولوغ",
    "مكدانيل",
    "مكدرموت",
    "مكدونيل",
    "مكدوجال",
    "مكدويل",
    "مسيلهاني",
    "مسيلروي",
    "مكفادين",
    "مكفارلاند",
    "مكجي",
    "مكجيل",
    "ماجينيز",
    "مكغوفيرن",
    "مكغووان",
    "مكجراث",
    "مكجراو",
    "مكجريجور",
    "مكغواير",
    "متشوغ",
    "مسينتوش",
    "مسينتير",
    "مكاي",
    "مكي",
    "ماكينا",
    "ماكنزي",
    "مكيون",
    "مكيسون",
    "مكينلي",
    "مكينني",
    "مكنايت",
    "مكلاناهان",
    "مكلوغلن",
    "ماكلين",
    "مكليود",
    "مكماهون",
    "مكمارتين",
    "مكميلان",
    "مكمولين",
    "مسنالي",
    "مسناوجهتون",
    "مسنيل",
    "مسنولتي",
    "مكفرسون",
    "المتعددة",
    "ني",
    "عسل",
    "وملت",
    "وخميرة",
    "ميدولاند",
    "ميدولارك",
    "إكليلية",
    "ميدوي",
    "أشربة",
    "ضآلة",
    "وجبة",
    "أكواز",
    "أشحب",
    "طحيني",
    "أحاديث",
    "ممطوطة",
    "تعرجات",
    "بخلا",
    "المعنى",
    "مغزى",
    "المعاني",
    "عنى",
    "الأثناء",
    "حصبة",
    "متحصب",
    "الحصبة",
    "كيال",
    "اجراءات",
    "ميتبال",
    "لحما",
    "لحمي",
    "مكة",
    "المكرمة",
    "ميكانيكيا",
    "ميكانيكيون",
    "ميكانيكا",
    "آليات",
    "مختصون",
    "بالآلات",
    "المكننة",
    "يمكنن",
    "بالمعدات",
    "الميكانيكية",
    "مجهزوا",
    "العقي",
    "داكنة",
    "المولود",
    "ولادته",
    "ميكم",
    "أعطي",
    "ميدالية",
    "اعطاء",
    "النوط",
    "الأنواط",
    "ميديا",
    "ميدفيلد",
    "ميدفورد",
    "إعلامي",
    "الأوساط",
    "بتوسط",
    "وساطة",
    "وساطات",
    "وسيط",
    "وسطاء",
    "طبية",
    "طبيا",
    "ميديسي",
    "لمؤسسات",
    "وفنونها",
    "وأعرافها",
    "بتاريخ",
    "وثقافتها",
    "معجبون",
    "توسطات",
    "تأملات",
    "متوسطات",
    "المشملة",
    "مزيج",
    "مزائج",
    "النخاع",
    "وديع",
    "أحلم",
    "وداعة",
    "ميتر",
    "مصلى",
    "(عند",
    "البروتوستانت",
    "خاصة)",
    "ميج",
    "الميغابايت",
    "الميغابايتات",
    "دورة",
    "هرتز",
    "جنون",
    "مدن",
    "ميجاواط",
    "أوم",
    "انقسام",
    "ميستر",
    "ميكونج",
    "ميلامين",
    "بالسوداء",
    "سوداوي",
    "ميلانيزيا",
    "ميلانيزي",
    "ميلاني",
    "ميلانين",
    "الملاني",
    "ميلبورن",
    "ميلتشر",
    "إخلط",
    "مليندا",
    "تحسينات",
    "ميليسا",
    "سلاسة",
    "ميلون",
    "أنضج",
    "يلين",
    "لحني",
    "بالنغم",
    "لحن",
    "ميلودراما",
    "ميلودرامي",
    "ميلودرامية",
    "(مثير)",
    "بالعمل",
    "النغم",
    "البطيخ",
    "المأساة",
    "الإغريق",
    "ذب",
    "للانصهار",
    "المتسبب",
    "جليد",
    "ثلج",
    "ميلفيل",
    "ميلفن",
    "العضوية",
    "العضويات",
    "الغشاء",
    "بغشاء",
    "الأغشية",
    "تذكار",
    "تذكارات",
    "مذكرة",
    "مذكرات",
    "التذكارية",
    "المذكرة",
    "الحفل",
    "التأبيني",
    "تذكارية",
    "ذكريات",
    "استظهار",
    "استظهر",
    "مستظهر",
    "حفاظ",
    "يستظهر",
    "الإستظهار",
    "الذاكرةالتبديل",
    "ميمفيس",
    "حدائق",
    "تدابير",
    "الحدوث",
    "للحيض",
    "مينكين",
    "ناقد",
    "كذاب",
    "مصلح",
    "ميندل",
    "مندل",
    "رومانسي",
    "إصلاح",
    "ميندوزا",
    "مينيلوس",
    "سبارتا",
    "الإغريقية)",
    "جماعات",
    "الرنكة",
    "سحائي",
    "السحايا",
    "المحدب",
    "مينلو",
    "مينونيت",
    "معارضون",
    "للعنف",
    "الجماعة",
    "المنقرضة",
    "تسمى",
    "(الجونكويان)",
    "مينش",
    "الحيض",
    "حيضي",
    "تحيض",
    "محاض",
    "حيض",
    "عقلي",
    "عقليات",
    "عقلية",
    "عقليا",
    "المنثول",
    "عابرة",
    "مذكور",
    "مذكرون",
    "مينو",
    "مينسيه",
    "تموء",
    "السبعة",
    "الرئيسيين",
    "الوسطى)",
    "مركاتوري",
    "مرسيدس",
    "مرتزقة",
    "الارتزاق",
    "مرتزق",
    "بزاز",
    "وبخاصة",
    "الحريرية",
    "يمتن",
    "المغزول",
    "ويصقله",
    "ويجعله",
    "تقبلا",
    "للأصباغ",
    "بمعالجته",
    "بالصودا",
    "الكاوية",
    "المصقول",
    "ليكون",
    "بزازون",
    "بضائع",
    "يتاجر",
    "متاجرة",
    "رحمة",
    "برحمة",
    "ميرك",
    "زئبقي",
    "الزئبق",
    "ميريديث",
    "ميرينجو",
    "تبهرجا",
    "تقدمها",
    "والعطاء",
    "الفردي",
    "تقدمه",
    "بالتقدير",
    "ميريويذر",
    "لتنظيف",
    "ميرل",
    "صقر",
    "صقور",
    "حورية",
    "حوريات",
    "غرانق",
    "خرافي",
    "وذيل",
    "مخلوقات",
    "ميريام",
    "ميريك",
    "ميريل",
    "ميرريماك",
    "فرح",
    "أفراح",
    "فرحة",
    "ميريت",
    "يلهو",
    "لهو",
    "ميرفن",
    "هضبة",
    "مستوية",
    "السطج",
    "متحدرة",
    "عقار",
    "مساريقي",
    "مساريق",
    "متشبك",
    "شبكات",
    "تشبك",
    "فاتن",
    "منومون",
    "مغناطيسيون",
    "الجرثومية",
    "جنين)",
    "الميزون",
    "البروتون",
    "والإلكترون",
    "النهرين",
    "بالدهر",
    "المسكيت",
    "هوية",
    "هويات",
    "برسالة",
    "رسول",
    "رسل",
    "مطاعم",
    "المنتظر",
    "مسيحي",
    "لخبطة",
    "متسم",
    "بالفوضى",
    "الاختلاط",
    "بادئة",
    "معناها",
    "أيضي",
    "الأيض",
    "الأيضية",
    "الأيضة",
    "ناشئة",
    "محصلة",
    "الغذائي",
    "أيض",
    "مؤيض",
    "يؤيض",
    "التأييض",
    "كسى",
    "بالمعدن",
    "إكساء",
    "التعدين",
    "يعدن",
    "المعادن",
    "مجهريا",
    "بعلوم",
    "معادني",
    "أشغال",
    "بالرياضيات",
    "مسخي",
    "(الصخور)",
    "(بينة",
    "الصخر)",
    "تحويلات",
    "تحولي",
    "الميتافوسفايت",
    "غيبي",
    "الماورائي",
    "الميتافيزيقيا",
    "الانبثاث",
    "\"علة\"",
    "الداء",
    "مقره",
    "السرطان)",
    "الإنبثاث",
    "تيمة",
    "للتيمات",
    "ميتاكاف",
    "نيزك",
    "نيزكي",
    "فائقة",
    "النيزك",
    "النيزكي",
    "شهاب",
    "يتبدد",
    "تبددا",
    "كاملا",
    "نيازك",
    "الشهب",
    "النيازك",
    "الدائر",
    "(حول",
    "الشمس)",
    "إرصادي",
    "أرصادي",
    "بالإرصاد",
    "الأرصادي",
    "الأرصاد",
    "متر",
    "مقاس",
    "أمتار",
    "الميثاكريليت",
    "الميثاكريليك",
    "الميثادون",
    "الميثان",
    "ميثانول",
    "الميثيونين",
    "كزلال",
    "والخميرة",
    "منهجية",
    "المنهاجية",
    "الميثودي",
    "الميثوديون",
    "استنادا",
    "المنهجيات",
    "أشخاص",
    "شديدو",
    "التمسك",
    "بالمذهب",
    "ميتهين",
    "ماساتشوسيتس",
    "ميتهسيلاه",
    "بطريرك",
    "يقال",
    "969",
    "طاعنون",
    "بالسن",
    "الميثيل",
    "مميثل",
    "الميثيلين",
    "جذر",
    "الوسوسة",
    "المتر",
    "الأمتار",
    "متري",
    "ميتريس",
    "المترو",
    "قطار",
    "ببندول",
    "بتناغم",
    "بندول",
    "حضري",
    "حضريون",
    "قطارات",
    "ميتس",
    "ميتزلر",
    "إبك",
    "مبكي",
    "يبكي",
    "الإسطبلات",
    "المكسيكيون",
    "مييرس",
    "المعتدلة",
    "ميغاهيرتز",
    "ميامي",
    "المواء",
    "بخار",
    "العفن",
    "البلق",
    "الفئران",
    "مايكل",
    "ميكلانجيلو",
    "مايكلز",
    "ميكلسون",
    "ميتشل",
    "ميتشيلانجيلو",
    "ميشيل",
    "ميتشيلن",
    "ميتشيلسون",
    "مشيغان",
    "ميك",
    "ميكيلسون",
    "ميكي",
    "الميكي",
    "المليون",
    "الكيميائي",
    "لكمية",
    "ضئيلة",
    "بالتحليل",
    "جرثومة",
    "بمزيل",
    "الميكروبات",
    "مجهري",
    "تتعامل",
    "كميات",
    "لتنفيذ",
    "تعليمات",
    "المصغر",
    "بالأقتصاديات",
    "الأقتصاديات",
    "الألكترونيات",
    "الميكروفيش",
    "الميكروفيلم",
    "صورا",
    "فوتوغرافية",
    "مصغرة",
    "أخذ",
    "مايكروفيلم",
    "الميكروفلم",
    "الأحافير",
    "الميكروغرام",
    "التجهير",
    "بالمجهر",
    "تعليمة",
    "ميكروجول",
    "للغبار",
    "بالجزيء",
    "الميكرومتر",
    "الميكرومترات",
    "الميكرون",
    "ماكرونيزيا",
    "الماكروني",
    "الماكرونيون",
    "الميكرونات",
    "المجهري",
    "المجهرية",
    "بالصور",
    "تتطلب",
    "مجهر",
    "لرؤيتها",
    "الميكروفيلمي",
    "تجزيء",
    "بالكومبيوتر",
    "خطوات",
    "المجهر",
    "المجاهر",
    "مايكروسوفت",
    "سيتوبلازم",
    "الفولت",
    "المايكرويف",
    "ميداس",
    "الأسطوري",
    "لفريجيا",
    "الأوسط",
    "ترددات",
    "منتصف",
    "مددلبري",
    "المنتصفات",
    "ميدلسيكس",
    "مددلتون",
    "مددلتاون",
    "(لا",
    "١٦٠",
    "باوند)",
    "سلع",
    "(حجما",
    "جودة)",
    "البرغشة",
    "البراغيش",
    "الميدي",
    "أوسطي",
    "مدمورن",
    "أوسط",
    "وسطها",
    "الصيف",
    "إختبارات",
    "نغمة",
    "أجنحة",
    "لضروب",
    "البريئة",
    "الأسبوع",
    "الغرب",
    "وضعت",
    "قابلات",
    "ميد",
    "الهيئات",
    "ميس",
    "الميج",
    "هولا",
    "أقدر",
    "مغنون",
    "الشقيقة",
    "هجرة",
    "اندفاع",
    "هجرات",
    "مهاجرون",
    "ميجيل",
    "مايك",
    "الميكروفونات",
    "ميخائيل",
    "ميكويان",
    "الروسية",
    "ميلا",
    "بالأميال",
    "حلوب",
    "إعتدالا",
    "الفطري",
    "ميلدريد",
    "ميلير",
    "أميال",
    "بروح",
    "فدائية",
    "النضالية",
    "عسكريا",
    "مشرب",
    "بالروح",
    "بدعم",
    "للعسكرية",
    "ناهض",
    "مناهض",
    "يناهض",
    "المناهضة",
    "الحليب",
    "محلوب",
    "الحالب",
    "الحالبون",
    "حليبي",
    "الحليبية",
    "الحلب",
    "يحلب",
    "ميلارد",
    "ألفي",
    "بألف",
    "الألفي",
    "الألفيات",
    "الألفية",
    "وأربعين",
    "أمهات",
    "طحان",
    "طحانون",
    "ميليس",
    "الدخن",
    "المليار",
    "الملليغرام",
    "الملليغرامات",
    "ميليكان",
    "الملليلتر",
    "الملليلترات",
    "الملليمتر",
    "المليمترات",
    "قبعات",
    "متاجر",
    "المليونير",
    "المليونيرات",
    "الملايين",
    "الألف",
    "بالميليفولت",
    "مدرجة",
    "الطاحون",
    "طواحين",
    "الرحى",
    "الطواحين",
    "مركبو",
    "الميلورد",
    "ميلتن",
    "ملتوني",
    "بالشاعر",
    "الإنكليزي",
    "ملتون",
    "بشعره",
    "لهما",
    "ميلووكي",
    "مختلفة",
    "(Multiple",
    "Instruction",
    "Multiple",
    "Data)",
    "الصامتة",
    "تمثيلية",
    "صامتة",
    "الناسخة",
    "نسخ",
    "ناسخات",
    "بالتقليد",
    "والمحاكاة",
    "ساخرة",
    "والمحاكاة)",
    "ميمي",
    "حاكى",
    "للتسلية",
    "التقاليد",
    "محاكاة",
    "المئذنة",
    "المفروم",
    "مفروم",
    "المفرمة",
    "المفارم",
    "المفرومة",
    "عقل",
    "الفلبين",
    "متدبر",
    "حريص",
    "يقظة",
    "بغفلة",
    "غفلة",
    "اللغم",
    "الألغام",
    "ممعدن",
    "يمعدن",
    "التمعدن",
    "بالمعادن",
    "متخصصون",
    "إلاهة",
    "ألغام",
    "كاسحات",
    "مينج",
    "المصغرون",
    "التصغير",
    "صغر",
    "يصغر",
    "المتوسطة",
    "الإصلاحي",
    "الإصلاحيون",
    "الأدنى",
    "مينيماكس",
    "مقلل",
    "أشرعة",
    "وزاري",
    "وزاريا",
    "يخدم",
    "الخدمة",
    "وزارات",
    "مينيكس",
    "ثعلب",
    "ثعالب",
    "ميننيبوليس",
    "مينيسوتا",
    "ميني",
    "البلمة",
    "البلم",
    "بحضارة",
    "اقريطش",
    "قاصر",
    "التخصص",
    "التشعيبي",
    "الأقليات",
    "الأقلية",
    "قاصرون",
    "مينوس",
    "مينوت",
    "منسك",
    "مينسكاي",
    "الكاتدرائية",
    "الكاتدرائيات",
    "الأشعار",
    "سك",
    "المطروح",
    "منهم",
    "كلاسيكية",
    "يحتاج",
    "لدقيقة",
    "مستعدا",
    "للقتال",
    "بالاهتمام",
    "بالتفاصيل",
    "الدقائق",
    "المينكس",
    "الثلثي",
    "المعجزة",
    "المعجزات",
    "أعجوبي",
    "عجيبة",
    "السراب",
    "سراب",
    "الميراج",
    "يتوهم",
    "ميراندا",
    "متوحل",
    "الأطيان",
    "مرايا",
    "طينا",
    "التوحل",
    "مرآة",
    "انعكس",
    "الإنعكاس",
    "المرايا",
    "بحزن",
    "الأفراح",
    "وقدر",
    "مصطف",
    "اصطفاف",
    "عدو",
    "للبشر",
    "مبغضا",
    "مبغضون",
    "مساء",
    "ميسابلير",
    "أسيئ",
    "فهم",
    "اساءة",
    "نغل",
    "متفرقات",
    "أخطئ",
    "أهن",
    "الاخفاقات",
    "ميسكاست",
    "ميسكاستس",
    "تمازج",
    "متنوعة",
    "للخلط",
    "للاختلاط",
    "التخاطب",
    "الخاطيء",
    "أسئ",
    "الفاجر",
    "الفاجرون",
    "الآثام",
    "بجنحة",
    "بجنحات",
    "الجنحة",
    "معلومة",
    "خاطئة",
    "بؤس",
    "مثيرا",
    "للشفقة",
    "التعاسات",
    "بخل",
    "بخلاء",
    "تعاسة",
    "السمة",
    "المريضة",
    "اختل",
    "(السلاح",
    "الناري)",
    "إخفاقات",
    "إخفاق",
    "يقيس",
    "صحيحة",
    "التخوف",
    "مضللة",
    "المضلل",
    "استعماله",
    "مسيؤو",
    "السيء",
    "السيئة",
    "الفقد",
    "تسمية",
    "سمي",
    "باسم",
    "كارهو",
    "مكانها",
    "مطبعي",
    "تلفظ",
    "اللفظي",
    "إقتباس",
    "ميسريدر",
    "تمويهي",
    "اخطأ",
    "الخاطئة",
    "مفتقد",
    "ممسوخ",
    "التشوه",
    "الصاروخ",
    "الصواريخ",
    "المبشرون",
    "المبشر",
    "بمهمة",
    "المسيسيبي",
    "بولاية",
    "ميسيسبي",
    "الخطابات",
    "ميسسولا",
    "ميسسوري",
    "تهجئة",
    "خطا",
    "التهجئة",
    "أخطاء",
    "يبذر",
    "كاذب",
    "كاذبة",
    "العثرة",
    "ميسي",
    "للخطأ",
    "خاطيء",
    "مخطئ",
    "المخطئ",
    "مغشى",
    "ضبابيا",
    "التغشية",
    "الهدال",
    "الهدالات",
    "العشيقة",
    "العشيقات",
    "الباطلة",
    "الظنون",
    "بالخطأ",
    "التفاهم",
    "فهمه",
    "إساءات",
    "اساء",
    "ماساتشوستس",
    "ميتش",
    "ميتشيل",
    "العثة",
    "القلوسة",
    "ميتيروورت",
    "العث",
    "مايتوكوندريا",
    "مولدة",
    "للطاقة",
    "ميتزفاهس",
    "إحدى",
    "العشر",
    "للتشريع",
    "للمزج",
    "الخلاطة",
    "خلاطة",
    "الخلطات",
    "شجار",
    "الإزار",
    "(نجم)",
    "المليلتر",
    "المليمتر",
    "شي",
    "بالذاكرة",
    "التذكر",
    "تقوية",
    "الغوغاء",
    "مهاجم",
    "قلنسوة",
    "نسائية",
    "قلنسوات",
    "موبيل",
    "نقال",
    "هواتف",
    "الشقي",
    "الخف",
    "الأخفاف",
    "موكيرنت",
    "المستهزئون",
    "الهزء",
    "هازئ",
    "بالحجم",
    "نماذج",
    "بالأحجام",
    "المودم",
    "المودمات",
    "المعتدل",
    "مراجع",
    "المعتدلون",
    "مودراتلي",
    "العصرانية",
    "متحررون",
    "التحديث",
    "عصرن",
    "المجددون",
    "يعصرن",
    "العصرنة",
    "العصرية",
    "آراء",
    "عصرية",
    "الأنماط",
    "موديستو",
    "التواضع",
    "تعديل",
    "مجزأة",
    "التحويرات",
    "المغير",
    "المغيرون",
    "تغييري",
    "الوحدات",
    "مودولو",
    "مودوس",
    "مو",
    "موقاديشو",
    "الصومال",
    "الموهير",
    "محمد",
    "محمدي",
    "الموهوك",
    "موهر",
    "إكدح",
    "يكدح",
    "موينيس",
    "حرير",
    "المواريه",
    "مويسييف",
    "برطوبة",
    "مولال",
    "الضرس",
    "الأضراس",
    "الدبس",
    "مولدافيا",
    "الدجر",
    "بالمحراث",
    "المفكك",
    "عفنا",
    "المفككون",
    "عفونة",
    "العفونة",
    "مولدوفيا",
    "معفن",
    "الخلد",
    "جزيئي",
    "الجزيئة",
    "الترابية",
    "فروة",
    "إئذ",
    "المتحرشون",
    "مولير",
    "مولن",
    "صديقة",
    "مولي",
    "مهدوء",
    "مهدئة",
    "الرخوي",
    "بالرخويات",
    "المدللون",
    "مولوتش",
    "سحلية",
    "مولوتوف",
    "فقد",
    "مائع",
    "مولوكاس",
    "موليبديت",
    "موليبديك",
    "الموليبدينيت",
    "الموليبدنم",
    "فضي",
    "الزخم",
    "مونا",
    "موناكو",
    "الأحادي",
    "ملكي",
    "المبادىء",
    "مونارتشيستيك",
    "أنصار",
    "الملكي",
    "رهباني",
    "الرهبانية",
    "بإذن",
    "مونيل",
    "مونيه",
    "ترشيد",
    "نقدي",
    "نقد",
    "منقد",
    "ينقد",
    "التنقيد",
    "شنطة",
    "ضاربي",
    "كساب",
    "الفلوس",
    "مونييوورت",
    "مونفورت",
    "التأجيج",
    "منغوليا",
    "المنغولي",
    "المنغوليون",
    "منغولي",
    "النغول",
    "مونيك",
    "مونيكا",
    "الإسم",
    "الشخصي",
    "المزحة",
    "المضحكة",
    "مونماوث",
    "الإنجليزي.",
    "الابن",
    "لتشارلز",
    "أحادية",
    "مكروفون",
    "مونوسيروس",
    "مونوكروميتور",
    "لعزل",
    "لألوان",
    "الطيف",
    "بنظارة",
    "بثلاث",
    "الفلقة",
    "المنفردة",
    "البلورية",
    "المقترن",
    "المقترنة",
    "بزوج",
    "مونويد",
    "منليثي",
    "المونولمج",
    "المونمر",
    "يتحد",
    "بالآخرين",
    "بوليمر",
    "مونونجاهيلا",
    "احتكارات",
    "احتكار",
    "محتكر",
    "احتكاري",
    "محتكرون",
    "احتكر",
    "المحتكر",
    "يحتكر",
    "كهربية",
    "مستقرة",
    "مقتضب",
    "مفرد",
    "مفردة",
    "الرتابة",
    "رتيب",
    "المونوتيرماتا",
    "مونرو",
    "مونروفيا",
    "مونسانتو",
    "الموسمية",
    "الوحوش",
    "المسوخ",
    "المسخ",
    "مونتانا",
    "المونتاج",
    "مونتاج",
    "مونتين",
    "مقالي",
    "مونتكلير",
    "جيرسي",
    "المنت",
    "المونتنيغري",
    "مونتري",
    "مونتيفيردي",
    "مونتيفديو",
    "مونتغومري",
    "الشهرية",
    "شهريا",
    "الشهور",
    "مونتايسلو",
    "مونتمارتر",
    "مونتبيلير",
    "مونتراتشيت",
    "مونتريال",
    "مونتسيرات",
    "مونتي",
    "الأنصاب",
    "مزاجية",
    "مزاجيا",
    "المزاجية",
    "مزاجي",
    "مخار",
    "هلالي",
    "موني",
    "عملا",
    "إضافيا",
    "المهنتين",
    "مقمر",
    "الأقمار",
    "الممنوع",
    "البور",
    "مور",
    "مربط",
    "التربط",
    "مراسي",
    "مغاربي",
    "مقترح",
    "الممسحة",
    "المكتئب",
    "الإكتآب",
    "المماسح",
    "والأحجار",
    "المغزى",
    "الفيلسوف",
    "الأخلاقي",
    "بتزمت",
    "الفلاسفة",
    "الأخلاقيون",
    "الأخلاقية",
    "عظ",
    "موعوظ",
    "الواعظ",
    "يعظ",
    "الوعظ",
    "موران",
    "مورافيا",
    "مورافي",
    "الموراي",
    "سقيم",
    "المرضية",
    "مورهاوس",
    "موريلاند",
    "الأعراف",
    "موريسبي",
    "مورغان",
    "مورغن",
    "المشرحة",
    "المشارح",
    "موريارتاي",
    "محتضر",
    "الاحتضار",
    "المريون",
    "خوذة",
    "مورلي",
    "المرمون",
    "الصباح",
    "المغربي",
    "المغرب",
    "بغباوة",
    "مورفيمي",
    "المورفين",
    "موريل",
    "موريسون",
    "موريسسي",
    "موريستاون",
    "الغد",
    "مورس",
    "اللقمة",
    "اللقم",
    "لنقل",
    "مملط",
    "التمليط",
    "الهاونات",
    "مورتيم",
    "العقاري",
    "مرهون",
    "المرتهن",
    "المرتهنون",
    "الممول",
    "الممولون",
    "العقارية",
    "مستدين",
    "مستدينو",
    "الجنازات",
    "مجهزو",
    "للحرب",
    "الكابح",
    "يكبح",
    "إكبح",
    "بخزي",
    "مورتيمر",
    "ويلزي",
    "عشيق",
    "مورتن",
    "الفسيفساء",
    "موسكون",
    "موسكو",
    "موس",
    "موسر",
    "موسى",
    "إمش",
    "المسجد",
    "المساجد",
    "بنادق",
    "مطحلب",
    "الغالب",
    "السنوي",
    "النفتالين",
    "الواح",
    "موثيس",
    "المواضيع",
    "تحفيزي",
    "موتيفاتيف",
    "حافز",
    "البخارية",
    "موكب",
    "الموتوسيكل",
    "بالمحرك",
    "مكنن",
    "موتورولا",
    "السنوية",
    "مقولب",
    "التبديل",
    "مولتون",
    "التبديلات",
    "للركوب",
    "تسلق",
    "متسلقو",
    "مونتينوسنيس",
    "إندب",
    "مندوب",
    "النادب",
    "النادبون",
    "الفأر",
    "يصطاد",
    "تصغير",
    "فأر",
    "فئراني",
    "والسكينة",
    "الموس",
    "مشورب",
    "الشوارب",
    "موثر",
    "الحال",
    "الأفواه",
    "غسول",
    "المنقول",
    "للحركة",
    "انقل",
    "المحركون",
    "قص",
    "المحشة",
    "المحشات",
    "يقص",
    "موير",
    "موزمبيق",
    "موزارت",
    "بالساعة",
    "موزيلا",
    "برامج",
    "وتحتوي",
    "متصفح",
    "وقاريء",
    "الآنسة",
    "مثانية",
    "الطن",
    "المتري",
    "تزعم",
    "أنها",
    "(Music",
    "TeleVision)",
    "مخاطي",
    "الوحل",
    "يشهر",
    "الأوحال",
    "المخاطي",
    "المخاط",
    "عكر",
    "أوحل",
    "يعكر",
    "التعكير",
    "للطين",
    "المكونون",
    "واقية",
    "المشهر",
    "المشهرون",
    "مويلير",
    "المؤذن",
    "مكتوم",
    "الخمار",
    "الخمارات",
    "السراق",
    "ميور",
    "المجاهدين",
    "موكدين",
    "الخلاسيون",
    "التوتة",
    "البغل",
    "البغال",
    "مولنج",
    "السليم",
    "الملا",
    "مولين",
    "موليجان",
    "البرمجة",
    "المستعملين",
    "مالتيكس",
    "التنوع",
    "مولتيليفيل",
    "والصورة",
    "طيفي",
    "طيفية",
    "مكالمات",
    "بعدة",
    "للضرب",
    "المضروبة",
    "مكثر",
    "التعدد",
    "المضاعفات",
    "المراحل",
    "المهام",
    "المستخدمين",
    "الجامعة",
    "الهزاز",
    "غمغم",
    "مغمغم",
    "المتمتم",
    "المتمتمون",
    "يغمغم",
    "التمتمات",
    "مومفورد",
    "بدور",
    "الصامت",
    "تحنيط",
    "النكاف",
    "عال",
    "رتابة",
    "مندت",
    "مونج",
    "المصطلح",
    "الازدرائي",
    "مونجر",
    "العنون",
    "ميونخ",
    "البلديات",
    "أخضع",
    "للبلدية",
    "السخاء",
    "سخي",
    "مونسي",
    "مونسون",
    "موونج",
    "الجدارية",
    "الجداريات",
    "القاتلة",
    "غدرا",
    "جرائم",
    "مرياتيك",
    "مرك",
    "ظلاما",
    "الظلمة",
    "مرمرير",
    "ميرفي",
    "موراي",
    "المور",
    "مروو",
    "مسكوفي",
    "عضليا",
    "العضلي",
    "موسير",
    "المتحف",
    "المتاحف",
    "مزلاج",
    "تجره",
    "أطرى",
    "الأطرى",
    "كابر",
    "موشي",
    "موسيقيا",
    "أدائي",
    "الموسيقيون",
    "دراسةتاريخ",
    "بتأمل",
    "مسكيجون",
    "المسكلنج",
    "مسكوكس",
    "ثور",
    "مسكوكسين",
    "ثيران",
    "فئران",
    "مسكي",
    "بلح",
    "موسولني",
    "موسورجسكاي",
    "بشارب",
    "بشنب",
    "الخردل",
    "تغيري",
    "التغيرات",
    "اللازمة",
    "متحول",
    "الأخرس",
    "صامت",
    "الخرس",
    "تمرد",
    "التمردات",
    "تمتم",
    "يتمتم",
    "الخروف",
    "موتواليتي",
    "ميوزاك",
    "مكمم",
    "المكمم",
    "التكميم",
    "خاصتي",
    "مينامار",
    "مايكينا",
    "يقطنها",
    "مايكوباكتيريا",
    "مايلاين",
    "دسمة",
    "بيضاء",
    "الشوكي",
    "بالنخاع",
    "مايرز",
    "مايلار",
    "لمادة",
    "ميناه",
    "مينهير",
    "بعضلة",
    "ميوفيبريل",
    "الميوجلوبين",
    "بقصر",
    "ميرا",
    "مايرون",
    "المر",
    "ميرتل",
    "نفسي",
    "الصوفيون",
    "وبسط",
    "نوتة",
    "قطرات",
    "المعلومات.",
    "نابيسكو",
    "الصوديوم",
    "لؤلؤي",
    "نادية",
    "نديم",
    "نادين",
    "ناجاساكي",
    "ناجويا",
    "عروس",
    "مسمر",
    "نيلير",
    "التسمير",
    "نير",
    "نيروبي",
    "سذاجة",
    "ناكامرا",
    "ناكاياما",
    "الاسمالموقع",
    "ناميبيا",
    "النان",
    "نانسي",
    "نانيت",
    "نانكينج",
    "يانجتسي",
    "مربيات",
    "مربية",
    "النانوميتر",
    "نانوك",
    "النانوسيكند",
    "نانتوكيت",
    "نعومي",
    "النابالم",
    "القفا",
    "النفثالين",
    "نابولي",
    "نابليون",
    "نابليوني",
    "مقيل",
    "الحفاظات",
    "التقييل",
    "الحفاظة",
    "الإغفاءات",
    "ناربون",
    "جنوبية",
    "ضابط",
    "الإفتتان",
    "نرجسي",
    "بأنانية",
    "النرجسيون",
    "جرعة",
    "مخدرا",
    "ناراجانسيت",
    "إرو",
    "مروي",
    "يروي",
    "الرواية",
    "قصصيا",
    "الراوي",
    "الرواة",
    "أضيق",
    "التضييق",
    "للفضاء",
    "والطيران",
    "أنفي",
    "بالأنف",
    "متكلم",
    "أنفيا",
    "ناشئ",
    "ناش",
    "ناشوا",
    "ناشفيل",
    "ناسو",
    "بخبث",
    "السلبوت",
    "ناتالي",
    "بالطبع",
    "ناتشيز",
    "نيت",
    "ناثان",
    "ناثانايل",
    "القومي",
    "بوطنية",
    "الوطنيون",
    "التأميم",
    "أمم",
    "مؤمم",
    "يؤمم",
    "قوميا",
    "الأمم",
    "حلف",
    "الأطلسي",
    "أناقة",
    "بأناقة",
    "التجنس",
    "متجنس",
    "يطبع",
    "التطبيع",
    "طبيعيا",
    "بالطبيعة",
    "شقي",
    "نورو",
    "الغثيان",
    "قزز",
    "يقزز",
    "التقزيز",
    "ملاحي",
    "نوتيلاس",
    "نافاهو",
    "نافاجو",
    "السرات",
    "للملاحة",
    "مجيل",
    "يجول",
    "الإبحار",
    "كلا",
    "الناصري",
    "الناصرة",
    "النازي",
    "النازيون",
    "التلفزيونية",
    "الشماليه",
    "لرياضة",
    "الجامعات",
    "للبحث",
    "الكنسي",
    "ار",
    "نجامينا",
    "النيون",
    "نيل",
    "البدائيون",
    "نبراسكا",
    "السدم",
    "سديمي",
    "بالضرورة",
    "إستلزم",
    "مستوجب",
    "يستوجب",
    "الإستوجاب",
    "أشرطة",
    "عانق",
    "نيكر",
    "منديل",
    "مناديل",
    "القلادة",
    "القلائد",
    "الرقاب",
    "إستحضار",
    "النخر",
    "نخري",
    "السلسبيل",
    "رحيقي",
    "الرحيق",
    "الخوخة",
    "المحتاج",
    "نيدام",
    "حاجة",
    "باحتياج",
    "الإحتياج",
    "الإبرة",
    "بإبرة",
    "بالإبرة",
    "بياع",
    "الإبر",
    "المشتغلون",
    "بياعو",
    "داع",
    "الإكتفاء",
    "الماهرة",
    "التطاريز",
    "محتاج",
    "نيف",
    "النافي",
    "سلبي",
    "سلبيا",
    "السلبية",
    "السلبيات",
    "الناقض",
    "بونك",
    "النيجر",
    "مكترث",
    "الجلباب",
    "الجلابيب",
    "للتفاوض",
    "المفاوض",
    "تفاوض",
    "متفاوض",
    "يتفاوض",
    "المفاوضون",
    "الزنجية",
    "الزنجيات",
    "الزنجي",
    "زنجي",
    "نيهرو",
    "الصهيل",
    "الجار",
    "الجيرة",
    "مصهول",
    "لا.",
    "نيلسين",
    "نيلسن",
    "متوازية",
    "الخيطية",
    "الجديد",
    "لقشرة",
    "النيوديميوم",
    "الجديدة",
    "التحديثات",
    "اللغوية",
    "النيومايسين",
    "وليدي",
    "بغاز",
    "النيوبرين",
    "النيبال",
    "نيبال",
    "لنسيان",
    "المآسي",
    "الكلوي",
    "محاباة",
    "محسوبي",
    "نبتون",
    "النيبتنيوم",
    "يذاكر",
    "نيريد",
    "نيبتون",
    "نيرو",
    "العصب",
    "متنرفز",
    "نيس",
    "العش",
    "متداخل",
    "نيستر",
    "العشعشة",
    "نستلة",
    "معشعش",
    "نيسلير",
    "يعشعش",
    "الكتكوت",
    "الكتاكيت",
    "نيترافيرس",
    "النيتسكاب",
    "القراص",
    "ملدوغ",
    "استفزازي",
    "نيومان",
    "بالتهاب",
    "بالجهاز",
    "العصبي",
    "المتعلق",
    "بالأعصاب",
    "بالخلايا",
    "الفيسيولوجية",
    "المتعلقة",
    "والمواصفات",
    "النفسية",
    "خصى",
    "التخصية",
    "الحياد",
    "المحايد",
    "المحايدون",
    "الحيود",
    "حيد",
    "محيد",
    "المحيد",
    "المحيدون",
    "يحيد",
    "التحييد",
    "النيوترون",
    "نيفا",
    "نيفادا",
    "نيفينز",
    "نيوارك",
    "نيوبولد",
    "الجدد",
    "نيوبري",
    "نيوبريبورت",
    "نيوكاسل",
    "سنادة",
    "دعامة",
    "نيويل",
    "نيوفندلند",
    "سطر",
    "المتزوج",
    "المتزوجون",
    "نيوبورت",
    "صحف",
    "موزعو",
    "كشك",
    "فرعية",
    "نيوزويك",
    "أسبوعية",
    "الاسبوعبة",
    "الأنباء",
    "بالإخبار",
    "النيوت",
    "سالاماندير",
    "سمة",
    "نيوتن",
    "سمندلات",
    "نيز",
    "نجيين",
    "النيكوتين",
    "نياكرا",
    "نيامي",
    "مقضوم",
    "القاضم",
    "القاضمون",
    "نيبيلنج",
    "برجندايان",
    "نيكاان",
    "نيكاراكوا",
    "نيكولو",
    "ألطف",
    "الكوات",
    "نيقولاس",
    "نيكولس",
    "نيكولز",
    "نيكلسن",
    "نيتشروم",
    "خدش",
    "النيكل",
    "بالنيكل",
    "بتكلفة",
    "دخول",
    "زهيدة",
    "إصهل",
    "الخدش",
    "نيكلوس",
    "نيكل",
    "الكنية",
    "الوصفي",
    "الكنيات",
    "التلقيب",
    "نيكوديموس",
    "نيقوسيا",
    "نيكوتينامايد",
    "فيتامينات",
    "أبنة",
    "نيلسون",
    "نيزش",
    "ممتازون",
    "بامتياز",
    "نايجيريا",
    "النايجيري",
    "البخل",
    "البخلاء",
    "سفسف",
    "مسفسف",
    "نيجلير",
    "يسفسف",
    "السفسفة",
    "بازعاج",
    "إقترب",
    "طاقية",
    "طاقيات",
    "ملابس",
    "الليلي",
    "الليلية",
    "البومة",
    "السهر",
    "العندليب",
    "العنادل",
    "ليليا",
    "الكوابيس",
    "الثلثان",
    "قميص",
    "نايتي",
    "للصحة",
    "الإنكارية",
    "عدمي",
    "نيجينسكاي",
    "نيكو",
    "نيقولاي",
    "لأس",
    "فطن",
    "الممطر",
    "الممطرة",
    "ميتال",
    "هايدريد",
    "نينا",
    "نينيبارك",
    "تسع",
    "القناني",
    "التسعات",
    "تاسع",
    "التسعينيات",
    "تسعون",
    "نينيفيه",
    "التسع",
    "تاسعا",
    "الأتساع",
    "نيوب",
    "النيوبيوم",
    "القرصة",
    "مقروض",
    "نيبينجلي",
    "الحلمات",
    "نيسي",
    "الصأبة",
    "تصيد",
    "النترات",
    "منترت",
    "ينترت",
    "النترتة",
    "النيتريك",
    "النيتروجين",
    "النتريد",
    "للمعدن",
    "نترات",
    "النطرون",
    "غليسرين",
    "نيترو",
    "النتروجين",
    "نتروجيني",
    "النتروجليسرين",
    "نتري",
    "بيض",
    "بعنوان",
    "نيكسون",
    "مكسيكو",
    "غربي",
    "شيطان",
    "لاتفعل",
    "الادارة",
    "للمحيطات",
    "والغلاف",
    "نوح",
    "نوبل",
    "النوبليوم",
    "الشهامات",
    "النبل",
    "نبلا",
    "بنبل",
    "للألوان",
    "الهادئة",
    "الإيماءة",
    "نوديس",
    "العجيرة",
    "العجيرات",
    "نويل",
    "نويس",
    "إجرامي",
    "محدثو",
    "نوكيا",
    "نولان",
    "البدوي",
    "بدوي",
    "بدوية",
    "التعريفية",
    "إسمي",
    "المرئي",
    "للعلاقات",
    "للعنونة",
    "كاربون",
    "لاخطي",
    "قفل",
    "جائز",
    "مستقطب",
    "لاربحي",
    "تدخين",
    "لطخة",
    "باستمرار",
    "تفاوت",
    "تبرعم",
    "بالأعمال",
    "كربوهيدرات",
    "الحاضر",
    "مفوض",
    "بالتباس",
    "المعتزل",
    "يطابقون",
    "المواصفات",
    "الإعتزال",
    "العادي",
    "تصميمية",
    "طوارئ",
    "التفهاء",
    "مكافئة",
    "نونيس",
    "سطع",
    "لايشكل",
    "خطورة",
    "نونميتالس",
    "لانووي",
    "أرثذوكسي",
    "متعامد",
    "متوازي",
    "الأفذاذ",
    "تحزبي",
    "دفعة",
    "للتلف",
    "إقلاق",
    "تلويث",
    "وصفة",
    "إجرائي",
    "مهني",
    "تقدمي",
    "رجوع",
    "للإرجاع",
    "سكني",
    "مستجيب",
    "تقييدي",
    "علمي",
    "جاد",
    "إشرافي",
    "تقني",
    "تقنيا",
    "طرفية",
    "إنهاء",
    "حقيقة",
    "لانقابي",
    "تنظم",
    "اللاعنف",
    "طوعي",
    "متطوع",
    "ناخب",
    "تصويت",
    "المعكرونة",
    "نودلد",
    "نودلنج",
    "الزواية",
    "الظهيرة",
    "الأنشوطة",
    "الإنشوطات",
    "نورا",
    "نورادرينالين",
    "نورادرينالاين",
    "نوردوف",
    "نوردستروم",
    "نورين",
    "اعادة",
    "نورفولك",
    "نورما",
    "مطبع",
    "معيد",
    "معيدو",
    "الأوضاع",
    "نورمان",
    "نورماندي",
    "معياري",
    "بمعيار",
    "المعيارية",
    "نوريس",
    "نوريستاون",
    "نرويجي",
    "الشمال",
    "نورثهامبتون",
    "شمالا",
    "الشرقية",
    "عواصف",
    "نورثفيلد",
    "نورثروب",
    "نورثرب",
    "نورثمبيرلاند",
    "الغربية",
    "نورثويسترس",
    "نورتن",
    "نوروالك",
    "النرويج",
    "النرويجي",
    "نورويتش",
    "الأنفي",
    "مشتم",
    "واقي",
    "للأنف",
    "فضولية",
    "بتطفل",
    "الإشتمام",
    "بحنين",
    "نوسترادامس",
    "نوستراند",
    "المنخر",
    "المدجل",
    "المدجلة",
    "ليس...",
    "رغم",
    "للعيان",
    "البارزون",
    "توثيقي",
    "يرمز",
    "الترميز",
    "ترميزي",
    "ترميزية",
    "تشكيلية",
    "نوتر",
    "بالملاحظة",
    "العدم",
    "ملاحظ",
    "التبليغ",
    "التبليغات",
    "الإشعار",
    "نوتوكورد",
    "مشهورا",
    "نوتينغهام",
    "نواكشوط",
    "مغذى",
    "يغذي",
    "نوفيل",
    "نوفمبر/تشرين",
    "نوفاك",
    "الاستبدال",
    "الروائي",
    "الروائيون",
    "مقصص",
    "الطرافات",
    "نوفو",
    "نوفوسيبيرسك",
    "نواي",
    "نوس",
    "نوييز",
    "نروف",
    "نو",
    "صحراوية",
    "النوبي",
    "متنوى",
    "النوى",
    "الأنوية",
    "النيوترونات",
    "البروتونات",
    "النكليوتيد",
    "النكليوتيدات",
    "التعري",
    "العراة",
    "الوكزة",
    "الوخاز",
    "الوكز",
    "العري",
    "العاري",
    "نجينت",
    "الرافض",
    "اللاغي",
    "الرافضون",
    "اللاغون",
    "الباطلون",
    "خدران",
    "عداد",
    "بتخدير",
    "التخدر",
    "بالعد",
    "بسوط",
    "للأعداد",
    "عديد",
    "خشوعي",
    "نقودي",
    "بالعملة",
    "النقدية",
    "والأوسمة",
    "نومير",
    "أديرة",
    "الراهبات",
    "الزفاف",
    "الأعراس",
    "الممرضة",
    "الرضع",
    "المربية",
    "المربيات",
    "الممرض",
    "رياض",
    "الممرضات",
    "كسارة",
    "كسارات",
    "هاتش",
    "القوارض",
    "مغذي",
    "المغذية",
    "جوزا",
    "بمس",
    "مس",
    "بالبندق",
    "مرغ",
    "ممرغ",
    "يمرغ",
    "التمريغ",
    "النايلون",
    "الحورية",
    "الحوريات",
    "الصؤاب",
    "ألختم",
    "المدور",
    "بلوطي",
    "أوكلند",
    "أوكلي",
    "أوكمونت",
    "ألبلوط",
    "نكيث",
    "الأحبال",
    "المجذاف",
    "الجذف",
    "محبس",
    "المجداف",
    "المجاذيف",
    "المجذف",
    "المجذفون",
    "الواحات",
    "الواحة",
    "الشوفان",
    "شوفاني",
    "المجروش",
    "القساوه",
    "بطاعة",
    "السجود",
    "بانحناء",
    "بطأطأة",
    "المسلة",
    "المسلات",
    "أوبيرلن",
    "طع",
    "طعة",
    "يطيع",
    "وغير",
    "المشوش",
    "إرباكي",
    "كشيئ",
    "كأشيائ",
    "يجسم",
    "اعتراضية",
    "المعطرض",
    "علية",
    "كريها",
    "بموضوعية",
    "بموضوعي",
    "الموضوعية",
    "المعطرد",
    "المعطردون",
    "توبيخ",
    "مفلطح",
    "كونه",
    "القرابين",
    "بتسيير",
    "يلزم",
    "بألتزام",
    "بإلزام",
    "إلتزم",
    "الملزم",
    "بإنحراف",
    "الأنحراف",
    "الإعوجاج",
    "إلغائي",
    "بنسيان",
    "مستطيل",
    "الاستطالة",
    "المستطيلات",
    "ببغاضة",
    "بذيئ",
    "بفحش",
    "فحاشة",
    "بحجب",
    "تشييع",
    "أوبسيكوي",
    "ملحوظا",
    "بملاحظة",
    "شهوديا",
    "المراصد",
    "المرصد",
    "لاحظ",
    "لاحظة",
    "يلاحظ",
    "توجس",
    "مهوس",
    "يتوجس",
    "التوجس",
    "بتوجس",
    "الإستحواذ",
    "إستحواذي",
    "الأستحواذ",
    "السج",
    "الزوال",
    "الزائل",
    "آيلا",
    "للإهمال",
    "الالغاء",
    "ولادي",
    "إخصائيو",
    "العنادة",
    "بجموح",
    "الجموح",
    "المعرقل",
    "بإعاقة",
    "إحصل",
    "للحصول",
    "مكتسب",
    "إقتحم",
    "مقتحم",
    "المقتحم",
    "يقتحم",
    "ناتئ",
    "الأقتحام",
    "بغير",
    "التحاش",
    "الأكرينا",
    "بلاستيكية",
    "أوكام",
    "حين",
    "لآخر",
    "التسبيب",
    "قفوي",
    "الإنسداد",
    "الخارقة",
    "الفوق",
    "بالقوى",
    "الشاغل",
    "الشاغلون",
    "مهنيا",
    "إحتل",
    "الأقيانوسية",
    "بالمحيط",
    "أوقيانوغرافي",
    "أوشنسايد",
    "القط",
    "المغرة",
    "أكاسيد",
    "أوكونوماووك",
    "أكتوبر/تشرين",
    "الأظلاع",
    "المثمنات",
    "مضلع",
    "بثمانية",
    "الثماني",
    "الأوكتان",
    "محيط",
    "أوكتاف",
    "أكتافيا",
    "أوكتيل",
    "الأقتيليون",
    "الثمانيني",
    "الأخطبوطات",
    "الأخطبوط",
    "أسلافة",
    "أود",
    "المعدود",
    "بغرابة",
    "أفرط",
    "أودر",
    "أودربيرج",
    "أوديسا",
    "الأودين",
    "للحكمة",
    "عدادات",
    "المميزة",
    "أوديسيوس",
    "أوديبي",
    "إديبوس",
    "أويديبالي",
    "جوكاستا",
    "الأصلية",
    "الإرستد",
    "الذبيحة",
    "أوفينباخ",
    "تبرعات",
    "المصلين",
    "مرتجلا",
    "أيدي",
    "ساعات",
    "أمد",
    "بضباط",
    "الدواوين",
    "الإمام",
    "تأدية",
    "بانعزال",
    "الفرعي",
    "أوفس",
    "التسلل",
    "الذريات",
    "يأخذ",
    "غالبا",
    "أغلب",
    "الأحيان",
    "بتكرار",
    "أوجدين",
    "إنظر",
    "المحملق",
    "ينظر",
    "الغولات",
    "الغولة",
    "أوه",
    "أوهايو",
    "الأوم",
    "أومي",
    "المزيتة",
    "المزيت",
    "المزيتون",
    "زيتا",
    "زيتيا",
    "الزيتية",
    "الزيوت",
    "الحبة",
    "يستخرج",
    "منها",
    "الزيت",
    "أوكاموتو",
    "أوكيناوا",
    "أوكلاهوما",
    "أولاف",
    "أولدينبيرغ",
    "أقدم",
    "الموضوعات",
    "أولدسموبيل",
    "الدفلى",
    "الأولفين",
    "أوليج",
    "السمن",
    "شمي",
    "أولجا",
    "بحكومة",
    "حكومة",
    "أوليجوكليس",
    "إحتكاري",
    "إحتكار",
    "أولن",
    "يخن",
    "الزيتون",
    "أوليفير",
    "أوليفيرز",
    "الأوليفيتي",
    "أوليفيا",
    "أولسين",
    "أولسون",
    "أولمبيا",
    "أوليمبي",
    "الأولمبية",
    "أوليمبوس",
    "أوماها",
    "شكاوى",
    "الأوميغا",
    "صحون",
    "أوميكرون",
    "حذفه",
    "أومني",
    "والحيوان",
    "/",
    "أونا",
    "خطوة",
    "أونيدا",
    "الوحدانية",
    "تلك",
    "البصل",
    "المشاهدون",
    "متفرج",
    "فقط.",
    "بالمحاكاة",
    "أونونداجا",
    "ريع",
    "أونتاريو",
    "وجوديا",
    "الجزع",
    "وسيت",
    "ودلس",
    "ويحي",
    "نز",
    "النزيز",
    "براق",
    "الأوبك",
    "الفتاحة",
    "الفتاحات",
    "علني",
    "الإنفتاح",
    "أوبن",
    "أوفيس",
    "مفتوحة",
    "للمعالجة",
    "الجراحية",
    "يطبق",
    "عليهم",
    "الأوبرات",
    "إشتغل",
    "يشتغل",
    "بالأوبرا",
    "شغال",
    "بفعالية",
    "المشغلون",
    "متصلة",
    "بعضها",
    "ناظور",
    "المنومة",
    "إعتقد",
    "الرأي",
    "يعاند",
    "الآراء",
    "الأفيون",
    "الأبوسوم",
    "أوبينهايمر",
    "المعارضون",
    "الإنتهازية",
    "إنتهازي",
    "بانتهازية",
    "الإنتهازيون",
    "للمعارضة",
    "يعارض",
    "التضاد",
    "إضطهد",
    "مضطهد",
    "يضطهد",
    "الإضطهاد",
    "الجور",
    "المضطهد",
    "المضطهدون",
    "متمني",
    "بصريا",
    "فاحص",
    "فاحصو",
    "البصريات",
    "الأمثلية",
    "التفاؤل",
    "المتفائل",
    "بتفائل",
    "باختيار",
    "الخيارات",
    "الكتروضوئي",
    "بإخصائي",
    "العرافة",
    "لقاعدة",
    "مهتفو",
    "نبوئي",
    "أورالس",
    "أورانجيروت",
    "خطابي",
    "مستدير",
    "المدار",
    "مداري",
    "المداريون",
    "حوله",
    "المتتبع",
    "المتتبعات",
    "المدارات",
    "الأجرام",
    "السماوية",
    "الأوركسترا",
    "أوركسترالي",
    "أوركسترا",
    "السحلب",
    "السحالب",
    "الآمر",
    "المحن",
    "الممرضون",
    "الترتيبي",
    "المألوف",
    "العمودي",
    "العمودية",
    "الرسامات",
    "الأوريجانو",
    "أوريغون",
    "الخامات",
    "أوريستيز",
    "كليتيمنيسترا",
    "الأورجانزا",
    "أورجانيسميك",
    "الأرغن",
    "للتنظيم",
    "تنظيمي",
    "بتنظيم",
    "بالمركب",
    "هزة",
    "لذة",
    "هزات",
    "تشبه",
    "العربيدة",
    "المشرق",
    "الفوهة",
    "الفوهات",
    "الأصالات",
    "الأصليون",
    "إنشأ",
    "منشوء",
    "المنشأ",
    "الإبتكارات",
    "بدئي",
    "المنشئ",
    "المنشؤون",
    "الاصول",
    "أورينوكو",
    "فنزويلا",
    "الأوريول",
    "أوركني",
    "أورلندو",
    "أورلينس",
    "أورلي",
    "ضاحية",
    "بباريس",
    "بزخرفة",
    "أورناتينيس",
    "طيوري",
    "أورونو",
    "الجهورية",
    "اليتيم",
    "ملجأ",
    "الأيتام",
    "ملاجئ",
    "اليتامى",
    "اليتم",
    "التيتيم",
    "أور",
    "هوكي",
    "أورتيجا",
    "أورثيكون",
    "كاميرا",
    "أورثوكروماتي",
    "لجميع",
    "الأورثوكلاز",
    "تقويم",
    "الإعتياديون",
    "التعامدية",
    "إملائي",
    "إملائيا",
    "التهجي",
    "تجبيري",
    "جراحة",
    "التشوهات",
    "الجسمانية",
    "أورثوفوسفيت",
    "الفوسفوريك",
    "أورفيل",
    "أورويل",
    "أورويلي",
    "أوساكا",
    "أوسبيرت",
    "أوسبورن",
    "أوسكار",
    "تذبذب",
    "متذبذب",
    "يتذبذب",
    "التذبذبات",
    "المذبذب",
    "المذبذبات",
    "تذبذي",
    "الذبذبات",
    "راسمة",
    "راسمات",
    "لمسي",
    "أوسجوود",
    "أوشكوش",
    "أوزوريس",
    "أوسلو",
    "الأزميوم",
    "التنافذ",
    "تنافذي",
    "الشماط",
    "عظمي",
    "التعظم",
    "تحجر",
    "زعما",
    "زاعم",
    "العظمي",
    "تقويمي",
    "مجبرو",
    "تجبير",
    "أوسترن",
    "السايس",
    "أوستراكود",
    "قشرية",
    "أوستراندر",
    "النعامة",
    "النعامات",
    "أوزوالد",
    "عطيل",
    "إختلاف",
    "الآخرون",
    "الآخر",
    "الغيبية",
    "أخروي",
    "أوتيس",
    "أوتاوا",
    "عثماني",
    "واجادوجو",
    "بوركينا",
    "فاسو",
    "آخ",
    "Ought",
    "الأونس",
    "الأونسات",
    "نا",
    "لنا",
    "بنفسي",
    "أنفسنا",
    "للخارج",
    "منتهي",
    "زويد",
    "فق",
    "بز",
    "مزايد",
    "البز",
    "يبز",
    "متوجه",
    "التفشي",
    "الإفاقة",
    "الضجات",
    "التفطر",
    "التقادم",
    "مجتاز",
    "يجتاز",
    "الإجتياز",
    "مبزوز",
    "وتيد",
    "ملعب",
    "هزم",
    "يتفوق",
    "بالحيلة",
    "بحسن",
    "السفرة",
    "السفرات",
    "يدوم",
    "لايسكن",
    "عمله",
    "لايسكنون",
    "عملهم",
    "إبق",
    "باق",
    "وجهات",
    "إهزم",
    "عددا",
    "مفاق",
    "إفاقة",
    "يفيق",
    "المتعالج",
    "المتعالجون",
    "الخارجيون",
    "إلعب",
    "ملعوب",
    "المخفر",
    "المخافر",
    "إنهل",
    "السيل",
    "السيول",
    "أنتج",
    "الإنتاج",
    "إجتز",
    "بالمدى",
    "تحفظ",
    "ينكشف",
    "بع",
    "الإقصاء",
    "إغلب",
    "الغلب",
    "يغلب",
    "مبيع",
    "إمتد",
    "بعدد",
    "ألبسة",
    "التنزه",
    "زن",
    "موزون",
    "يزن",
    "بالذكاء",
    "أبلى",
    "وزو",
    "الإهليليج",
    "بيضوي",
    "أوفالنيس",
    "الإهليليجات",
    "مبايض",
    "المبايض",
    "أوفاتيس",
    "الحفاوة",
    "الإحتفائية",
    "أوفينبيرد",
    "إنتهى",
    "حالي",
    "متصنع",
    "أوفيراج",
    "التعسف",
    "بدلات",
    "خشع",
    "مخشع",
    "رجح",
    "مرجح",
    "يرجح",
    "إقهر",
    "متعجرف",
    "يزايد",
    "يغلي",
    "حرق",
    "الموجود",
    "تغلب",
    "مغيم",
    "التغييم",
    "يغيم",
    "السعر",
    "زيادات",
    "بالغيوم",
    "بمعطف",
    "المتغلب",
    "يتغلب",
    "التغلب",
    "تحميض",
    "المطلوب",
    "بولغ",
    "زائدة",
    "رصيد",
    "زد",
    "مبالغة",
    "زيد",
    "سق",
    "الملتهم",
    "تأكيد",
    "ينهك",
    "إنتخم",
    "يملأ",
    "فوقه",
    "الفوقي",
    "الرعي",
    "برفع",
    "الطنف",
    "الإصلاحات",
    "الشاملة",
    "إسخن",
    "سخونة",
    "يسخن",
    "إمتاع",
    "للقفز",
    "معتلى",
    "برا",
    "إقفز",
    "إعتل",
    "طل",
    "مطلول",
    "الطل",
    "يطل",
    "يتخطى",
    "تخطى",
    "تخطي",
    "مما",
    "يعملون",
    "مزحم",
    "الفائض",
    "تكديس",
    "السكاني",
    "جبارة",
    "إنتاج",
    "مؤهلات",
    "تزيد",
    "إجتح",
    "مغالى",
    "يغالي",
    "مغالاة",
    "للإصطدام",
    "منقوض",
    "ينقض",
    "الإجتياح",
    "التجاوزات",
    "أشرف",
    "أوفيرسي",
    "الإشراف",
    "يشرف",
    "مبيعات",
    "أوفيرشوت",
    "التبسيطات",
    "المقاسات",
    "أطل",
    "إطالة",
    "يطيل",
    "أطيل",
    "الطفح",
    "صبغ",
    "صبغها",
    "جاوز",
    "مجاوز",
    "المجاوزة",
    "يجاوز",
    "يخزن",
    "يشدد",
    "يرهق",
    "ويصوب",
    "حش",
    "محشى",
    "التحشية",
    "يحشي",
    "اكثر",
    "يزود",
    "بسكر",
    "بالضرائب",
    "إرهاق",
    "أوفيرتيريد",
    "أوفيرتنيس",
    "يتدرب",
    "تدرب",
    "المفاتحات",
    "مقلوب",
    "يفرط",
    "إفراط",
    "إغمر",
    "يغمر",
    "أجهد",
    "ينمق",
    "نمق",
    "مجهد",
    "أوفيرزيل",
    "الإتقاد",
    "أوفيد",
    "روماني",
    "بيوض",
    "البيضويات",
    "بض",
    "مبيض",
    "التبييض",
    "تبييضي",
    "أوين",
    "أوينز",
    "ولير",
    "البوم",
    "كبومة",
    "البومية",
    "مملوك",
    "المالكون",
    "الأكسالات",
    "الأملاح",
    "أكساليكي",
    "يجرها",
    "أكسفورد",
    "المؤكسد",
    "يؤكسد",
    "الأكسدة",
    "مؤكسد",
    "مؤكسدة",
    "الأكسيدات",
    "أكسد",
    "مكسد",
    "المؤكسدون",
    "يكسد",
    "الإكساد",
    "أوكسنارد",
    "أكسفوردي",
    "الأوكسجين",
    "بالأكسجين",
    "بالنعام",
    "أوزارك",
    "أوزاركس",
    "ميسوري",
    "الأوزون",
    "أوزوني",
    "أوزن",
    "موزن",
    "بالأوزون",
    "يوزن",
    "أوزي",
    "بنسلفانيا",
    "باسكال",
    "بابلو",
    "بابست",
    "مخطو",
    "الغليظ",
    "الهادي",
    "بهدوء",
    "مبدأ",
    "المتسالمون",
    "الرزمة",
    "المغلفون",
    "باكارد",
    "الحزامون",
    "مرزوم",
    "باكوود",
    "الحلف",
    "مبطن",
    "المجدف",
    "الوسادات",
    "باجانيني",
    "سرقة",
    "الفخم",
    "الفخمة",
    "مرقم",
    "البيجر",
    "قاعدةحوارإعدادالصفحة",
    "ترقيم",
    "المعبد",
    "السطل",
    "السطول",
    "التألم",
    "تصويري",
    "بيجامة",
    "فضفاضة",
    "باكستان",
    "الباكستاني",
    "الباكستانيون",
    "بالادن",
    "إستساغة",
    "بلذة",
    "تذوق",
    "متذوق",
    "يتذوق",
    "الأذواق",
    "بلاطي",
    "متمتعون",
    "بامتيازات",
    "بالو",
    "بالازي",
    "بالازو",
    "الحضيرة",
    "شحب",
    "لونه",
    "الشحب",
    "230",
    "600",
    "مضت",
    "باليرمو",
    "فلسطين",
    "الفلسطيني",
    "لفظة",
    "تقرأ",
    "طردا",
    "وعكسا",
    "ألفاظ",
    "وعكسيا",
    "صمامات",
    "للمجتمع",
    "بالفن",
    "بالاديو",
    "البالاديوم",
    "تخفيفي",
    "مخففات",
    "الإيهان",
    "النخلة",
    "راحي",
    "بالمير",
    "بالميتو",
    "النخيل",
    "بالمنج",
    "قارىء",
    "بالومار",
    "التداول",
    "للتداول",
    "تسمح",
    "تتناول",
    "إخفق",
    "باميلا",
    "السهوب",
    "البامبرز",
    "الكراريس",
    "الحاسم",
    "الحاسمة",
    "بنما",
    "هبط",
    "بالطائرة",
    "بسلام",
    "هبوط",
    "بانشو",
    "البنكرياس",
    "بنكرياسي",
    "الباندا",
    "باندانس",
    "التقويد",
    "البندور",
    "أفشت",
    "الأرض.",
    "المطبوخة",
    "بانيد",
    "منصة",
    "تنصيب",
    "بمنصة",
    "بألواح",
    "مستجدى",
    "المقالي",
    "الإستجداء",
    "باقة",
    "ورد",
    "المتجبر",
    "العديلات",
    "الإستعراضات",
    "بانورامي",
    "بانورامية",
    "الثالوث",
    "إلهث",
    "بالكون",
    "وخالقه",
    "المهمين",
    "بانتوميميد",
    "التمثيليات",
    "باولي",
    "البابوية",
    "باباو",
    "بابايا",
    "بابين",
    "غلاف",
    "ورقي",
    "الاعلانات",
    "لاصقو",
    "لايحتاج",
    "للورق",
    "ثقالة",
    "ثقالات",
    "الكتابي",
    "النتوئات",
    "حليمي",
    "باباس",
    "بابوا",
    "البردي",
    "المظلي",
    "المكافىء",
    "قطعي",
    "مكافىء",
    "بالمظلة",
    "بالمظلات",
    "المظليون",
    "مستعرض",
    "بارادر",
    "الفردوسات",
    "تفارقي",
    "للمفارقة",
    "المفارقة",
    "نفط",
    "باراجونايت",
    "الفقرة",
    "مفقر",
    "الفقرات",
    "برغواي",
    "الببغاوات",
    "باللغة",
    "المحاذية",
    "المتوازي",
    "مسلسلا",
    "المسلسل",
    "التوازي",
    "متوازيات",
    "المتوازيات",
    "المشل",
    "البارامغناطيسي",
    "باراميسيا",
    "البارامتر",
    "البارامترات",
    "حدودي",
    "بالحدودية",
    "حدودية",
    "العشيق",
    "بارامس",
    "المذعور",
    "المذعورون",
    "الموسوس",
    "لايفسره",
    "يفسرها",
    "بدرابزين",
    "الحاجيات",
    "المتنوعة",
    "أعادة",
    "خوارق",
    "اللاشعور",
    "الطفيلي",
    "الطفيليات",
    "طفيلي",
    "طفيليا",
    "الشمسية",
    "الشمسيات",
    "السمبتاوي",
    "إسلق",
    "السلق",
    "يسلق",
    "إعطش",
    "يعطش",
    "العطش",
    "رق",
    "للصفح",
    "صافحة",
    "معفو",
    "الصافحون",
    "الوالد",
    "الأبوة",
    "أبويا",
    "إعتراضي",
    "أبوين",
    "الأباء",
    "السالخ",
    "يقشر",
    "باريتو",
    "البارفية",
    "عملت",
    "بالقشدة",
    "للمراهنة",
    "القلامة",
    "باريس",
    "الأبرشيات",
    "الأبرشيون",
    "الباريسي",
    "بلوفر",
    "بالفراء",
    "للقطب",
    "واقف",
    "باركر",
    "الفضوليون",
    "باركيرسبيرغ",
    "باركهاوس",
    "باركنسن",
    "متنزه",
    "المتنزهات",
    "بالربح",
    "المفاوضة",
    "البرلمان",
    "برلماني",
    "البرلمانات",
    "البارميجان",
    "أفق",
    "بضيق",
    "الساخرة",
    "السراح",
    "أفرج",
    "السجين",
    "بشروط",
    "السجناء",
    "المطلقوا",
    "الإفراج",
    "المسجون",
    "بالباركيه",
    "تلبيس",
    "سلمون",
    "الإمعه",
    "التفادي",
    "المعدلات",
    "أعرب",
    "3.258",
    "ضوئية",
    "معرب",
    "المعرب",
    "المعربات",
    "يعرب",
    "بارسيفال",
    "ريتشارد",
    "فاجنر",
    "البقدنوس",
    "الخوريون",
    "إشترك",
    "يشترك",
    "الإشتراك",
    "مفترق",
    "بارثينون",
    "لأثينا",
    "جزئيات",
    "مشارك",
    "الإشتراكات",
    "تشاركي",
    "الفعلي",
    "الفعلية",
    "جزيئيات",
    "إستمتع",
    "المفارقات",
    "المجزىء",
    "مجزئ",
    "شورك",
    "الشراكة",
    "الشراكات",
    "الحجول",
    "الإستمتاع",
    "باسادينا",
    "سالك",
    "ركض",
    "باسيك",
    "الباند",
    "فلتر",
    "الجاري",
    "موديل",
    "التصميغ",
    "ممرور",
    "المناول",
    "السبيل",
    "المناولو",
    "عابرو",
    "الترخيصات",
    "وهناك",
    "أحاسيس",
    "لحمايته",
    "السلبيون",
    "الماستر",
    "لعدة",
    "قفول",
    "جواز",
    "جوازات",
    "الباستا",
    "المعجون",
    "البستل",
    "رسغ",
    "الدابة",
    "باستيرناك",
    "المعاجين",
    "باستير",
    "البسترة",
    "بستر",
    "مبستر",
    "يبستر",
    "التبستر",
    "مأخوذة",
    "طابع",
    "يوضع",
    "كتعريف",
    "لهذا",
    "عجيني",
    "فطائر",
    "السكري",
    "العجينية",
    "اللصق",
    "رعوي",
    "المرعى",
    "الغنم",
    "المراعي",
    "باتاجونيا",
    "الرقعة",
    "الرقاع",
    "يرقع",
    "الرقع",
    "رقع",
    "اليافوخ",
    "الطبق",
    "براءة",
    "للتسجيل",
    "إختراع",
    "براءات",
    "الربانية",
    "باترسون",
    "اليافوخات",
    "أمكنة",
    "المرضي",
    "باثولوجي",
    "التأسي",
    "المريض",
    "بصبر",
    "المرضى",
    "الزنجار",
    "الباحة",
    "الباحات",
    "باتلي",
    "يرأس",
    "الأبوي",
    "باترايس",
    "باتريشيا",
    "باتريك",
    "بوراثة",
    "بالباباوات",
    "ومذهب",
    "الباباوات",
    "الدورية",
    "محروس",
    "الدوريات",
    "الراعية",
    "سلطوية",
    "الربتات",
    "باتسيس",
    "بتسي",
    "مربوت",
    "باتيرير",
    "باتيرسون",
    "باتي",
    "مطرب",
    "أوبرا",
    "إيطالية",
    "أسباني",
    "الربت",
    "باتن",
    "بول",
    "بولا",
    "بوليت",
    "بولي",
    "بولين",
    "بولينج",
    "بولو",
    "بولسين",
    "بولسون",
    "بولوس",
    "تكرشا",
    "متكرش",
    "المستديم",
    "المهلة",
    "المهل",
    "مهد",
    "الأرصفة",
    "المرصف",
    "يمهد",
    "السرادق",
    "السرادقات",
    "التبليط",
    "بافلوف",
    "الدقرة",
    "البيدق",
    "باوني",
    "الراهن",
    "البيادق",
    "الرهونات",
    "الكفوف",
    "بوتكيت",
    "تكسب",
    "الراتب",
    "صكوك",
    "الرواتب",
    "يقومون",
    "الصافية",
    "صرافو",
    "بينيز",
    "بايسون",
    "باز",
    "كمبيوتر",
    "كومبيوترات",
    "أنتجت",
    "ديجيتال",
    "الباسفيكي",
    "البازلاء",
    "بيبودي",
    "السلمية",
    "سلمية",
    "سلام",
    "الخوخ",
    "بلغ",
    "خوخي",
    "الإمتياز",
    "بيتشتري",
    "الطاووس",
    "الطواويس",
    "العجيج",
    "مدوى",
    "التدوية",
    "الفستق",
    "الكمثرى",
    "اللؤلؤية",
    "البرليت",
    "بركاني",
    "اللآلئ",
    "بيرلستون",
    "بركانية",
    "زجاجية",
    "الأجاص",
    "بيرسون",
    "بالفلاح",
    "والفلاحة",
    "الفلاحين",
    "الخث",
    "مرصوف",
    "بالحصى",
    "البقان",
    "اقتراف",
    "للإثم",
    "المكيال",
    "المكاييل",
    "بيكوس",
    "صدري",
    "بالصدر",
    "مألوفين",
    "الدواسة",
    "إستعمل",
    "الدواسات",
    "المتحذلق",
    "بتحذلق",
    "التحذلقات",
    "التحذلق",
    "الركيزة",
    "الركائز",
    "بيدياتريك",
    "بالأقدام",
    "واظافرها",
    "بالـ",
    "بيديكور",
    "مؤصل",
    "بقوس",
    "الخطو",
    "بيدرو",
    "تبول",
    "الخاطفة",
    "نظرة",
    "خاطفة",
    "بوو",
    "القشارة",
    "قشارة",
    "مختلسو",
    "الند",
    "المثيل",
    "يتبول",
    "بيفيرس",
    "بيوي",
    "البيغاسوس",
    "المجنح",
    "بفتحات",
    "لتثبيت",
    "شماعات",
    "التشبيك",
    "بيجي",
    "بازدراء",
    "بيلهام",
    "البجع",
    "البجعات",
    "البلاجرا",
    "مكور",
    "لإخفاء",
    "تثبيت",
    "الفروة",
    "مرجوم",
    "الرجم",
    "بيمبروك",
    "طعام",
    "جزائي",
    "العقوبات",
    "الكفارة",
    "آلهة",
    "الرومانية",
    "بالقلم",
    "أقلام",
    "المعلقات",
    "بانتظار",
    "بينديلتون",
    "بندولي",
    "يتأرجح",
    "متدلي",
    "البندول",
    "البندولات",
    "بينيلوب",
    "للإختراق",
    "ثاقب",
    "الإختراقية",
    "المخترق",
    "المخترقون",
    "البطريق",
    "البطاريق",
    "بينه",
    "البنسلين",
    "جزيري",
    "أشباه",
    "سجون",
    "المطاوي",
    "بينا",
    "المثلث",
    "المثلثة",
    "البنسات",
    "بينسلفانيا",
    "بينروز",
    "بينساكولا",
    "التقاعدي",
    "تقاعدي",
    "المتقاعدون",
    "أحال",
    "التقاعد",
    "المتقاعد",
    "التقاعدية",
    "الخماسي",
    "بينتاد",
    "مخمس",
    "المخمسات",
    "خماسي",
    "بينتان",
    "الخماسية",
    "العنصرة",
    "السقيفة",
    "السقائف",
    "المشعشع",
    "الفاونيا",
    "الفاوانيا",
    "مأهول",
    "بيوريا",
    "أحيا",
    "حبوب",
    "متبل",
    "الباباروني",
    "متسرع",
    "البيبسي",
    "بيبسيكو",
    "عملاقة",
    "لإنتاج",
    "البيبسين",
    "بيبتيديس",
    "الببتيد",
    "حامضين",
    "أمينيين",
    "تجولي",
    "بإدراك",
    "أدرك",
    "الملاحظ",
    "الملاحظون",
    "يدرك",
    "بالمائة",
    "التصورات",
    "الإدراكات",
    "العلياء",
    "البركلورات",
    "بيرسيفال",
    "مترشح",
    "يترشح",
    "المسامات",
    "دورق",
    "راووقات",
    "طرقي",
    "الإيقاعية",
    "بيرسي",
    "القطامي",
    "بيريز",
    "للكمال",
    "الكمالية",
    "منشد",
    "منشدو",
    "يتقن",
    "الغدر",
    "يثقب",
    "التثقيب",
    "الخرم",
    "الأداءات",
    "المؤدي",
    "المؤدون",
    "العطارات",
    "العطارة",
    "الإرواء",
    "بيرجامون",
    "العريشة",
    "بيريكليسي",
    "أثيني",
    "بدعمه",
    "للديمقراطية",
    "بريكليز",
    "البريدوتيت",
    "نارية",
    "الحضيض",
    "دوران",
    "الكوكب",
    "بريلا",
    "بخطورة",
    "بالغة",
    "محيطيا",
    "دوريات",
    "بالضرس",
    "الملحقة",
    "الإسهابات",
    "إسهابي",
    "الناظور",
    "المناظير",
    "سريعي",
    "بفناء",
    "بتلف",
    "هالك",
    "مابين",
    "والسائل",
    "منصهر",
    "البريتون",
    "الباروكة",
    "الباروكات",
    "حلزون",
    "حلزونات",
    "إكذب",
    "الحانث",
    "بالقسم",
    "يكذب",
    "الحنث",
    "بيركينس",
    "المنافع",
    "بيرل",
    "التجعيدة",
    "بيرمالوي",
    "نافذ",
    "للنفاذ",
    "مسامات",
    "تخلل",
    "متخلل",
    "يتخلل",
    "التخلل",
    "التغلغل",
    "البرمي",
    "الجوازية",
    "مباحة",
    "التجاعيد",
    "المميت",
    "المنمقة",
    "البيروكسيد",
    "المرتكب",
    "الجناة",
    "أدم",
    "يديم",
    "الإدامة",
    "حائر",
    "بيري",
    "إضطهادي",
    "فرساوس",
    "دانة",
    "مثابر",
    "ثابر",
    "يثابر",
    "بيرشينج",
    "فارس",
    "الفارسي",
    "الكاكي",
    "إستمر",
    "الملح",
    "تجعلها",
    "الشخصيات",
    "إضفاء",
    "يضفي",
    "الإضفاء",
    "شخصيا",
    "إنتحل",
    "ينتحل",
    "إنتحال",
    "المنظور",
    "منظوري",
    "المنظورات",
    "البرسبيكس",
    "تعرقي",
    "تعرق",
    "متعرق",
    "يتعرق",
    "التعرق",
    "للإقناع",
    "مخصوص",
    "الخص",
    "بيرث",
    "أقلق",
    "يوتر",
    "الإقلاق",
    "بيرو",
    "بيروسابل",
    "التمعن",
    "مطالع",
    "القاريء",
    "بتمعن",
    "يطالع",
    "المطالعة",
    "البيروي",
    "البيرويون",
    "المعاندة",
    "الإنسلاخ",
    "والصواب",
    "للشذوذ",
    "شاذا",
    "البسيتا",
    "أسبانيا",
    "المتشائم",
    "بتشاؤم",
    "المتشائمون",
    "الآفة",
    "المدقة",
    "سحق",
    "المدقات",
    "الآفات",
    "الأليف",
    "التويجية",
    "ببتول",
    "تويجي",
    "بيتر",
    "تلاشى",
    "يتلاشى",
    "بيترسبيرغ",
    "بيترسين",
    "بيترسون",
    "العريضة",
    "ملتمس",
    "الملتمس",
    "العرائض",
    "بيتكيويتش",
    "المازور",
    "بيتري",
    "التصخر",
    "بيتروكيماوي",
    "بيتروكيماويات",
    "الأليفة",
    "ملاعب",
    "بيتيرز",
    "بيتيبون",
    "لابسة",
    "داخلية",
    "أتفه",
    "الملاعبة",
    "التغضب",
    "الإستفزاز",
    "البتونيا",
    "البيجو",
    "بيووكي",
    "البيوتر",
    "صبار",
    "بيتن",
    "الفنيك",
    "الفايزر",
    "فيدرا",
    "باسيفا",
    "ثيسيس",
    "الفيروس",
    "تلتهم",
    "الفلنجر",
    "إستراليا",
    "الفلروب",
    "سباح",
    "قضيبي",
    "الخيالات",
    "الفرعون",
    "الصيدلانية",
    "الصيدلة",
    "العقاقير",
    "عقاقيري",
    "دستور",
    "بلعومي",
    "البلعوم",
    "البلاعيم",
    "مرحلي",
    "البطيء",
    "الدراج",
    "فيلبس",
    "الكربوليك",
    "الفينول",
    "فلسفة",
    "الظاهري",
    "فينيلالاناين",
    "فاي",
    "21",
    "فيلادلفيا",
    "غازل",
    "يغازل",
    "فيلانثروب",
    "طوابعي",
    "هواية",
    "للبطاريات",
    "فيليمون",
    "فلاح",
    "فيليب",
    "فيليبيانز",
    "أرسلها",
    "الفيليبي",
    "فلبيني",
    "الفلسطينيون",
    "فيليز",
    "فيليبس",
    "فيلوديندرون",
    "فقه",
    "فيلوسوف",
    "فلسفي",
    "الفلسفات",
    "فلسف",
    "مفلسف",
    "المتفلسف",
    "يفلسف",
    "الفلسفة",
    "فيبس",
    "الوريدي",
    "خزع",
    "الوريد",
    "النخامة",
    "توصيل",
    "الفلوكس",
    "رهابي",
    "فويب",
    "فينيقيا",
    "فينكس",
    "خابر",
    "كلم",
    "الهواتف",
    "سماع",
    "بزيف",
    "المخابرة",
    "الدالة",
    "الفونوغراف",
    "صوتية",
    "الفونوغرافات",
    "الفوسجين",
    "الفوسفات",
    "فوسفاتي",
    "الفوسفيد",
    "الفوسفين",
    "ومض",
    "التفسفر",
    "فوسفوري",
    "فسفوري",
    "الفوسفور",
    "الفوسفورية",
    "الفسفور",
    "إضافة",
    "ضوئي",
    "الكهروضوئية",
    "بالكيمياء",
    "كهروضوئي",
    "بالتغييرات",
    "المتأثرة",
    "بالضوء",
    "الكتروني",
    "الإلكترونات",
    "الصوري",
    "الفوتوغرافية",
    "المساحي",
    "فوتوغرافي",
    "التلألأ",
    "بالتحلل",
    "بالمغناطيسية",
    "الفوتون",
    "الفوتونات",
    "فوتوبلاي",
    "بأدق",
    "مجس",
    "صوري",
    "مصاغ",
    "البلاغة",
    "أسلوبي",
    "بالعقل",
    "الفراسة",
    "فثاليت",
    "الطحلبية",
    "بالتطور",
    "التأريخات",
    "الفيزياوي",
    "الفيزياويون",
    "سيماء",
    "فسلجي",
    "الفسيولوجي",
    "وظائف",
    "فيتوبلانكتون",
    "متمرس",
    "البيانوات",
    "الميادين",
    "بيكاسو",
    "بيكادللي",
    "ملتقط",
    "القاطف",
    "البيكيلر",
    "بيكيرينج",
    "القاطفون",
    "مرابط",
    "المحتجون",
    "بكيت",
    "بيكفورد",
    "الإلتقاط",
    "المخلل",
    "مخلل",
    "المخللات",
    "النشال",
    "النشالون",
    "اللقطات",
    "المتنزهون",
    "بيكوفاراد",
    "بالترليون",
    "الترليون",
    "بالـكتابة",
    "المصورة",
    "التصويرية",
    "متعة",
    "للنظر",
    "بجين",
    "بايقوسوتر",
    "ألوان",
    "بالقطعة",
    "بيسير",
    "المدفوعة",
    "يتقاضي",
    "بيد",
    "بيدمونت",
    "باي",
    "بلانت",
    "مرفأ",
    "إثقب",
    "بيير",
    "مرافيء",
    "التقوى",
    "بيزو",
    "مهذور",
    "الهذر",
    "جشعا",
    "الإصطباغ",
    "الصبغات",
    "حظيرة",
    "حظائر",
    "زرائب",
    "سرحت",
    "شعرها",
    "البيك",
    "الحذرون",
    "بيكينج",
    "الرز",
    "بيلات",
    "الحاج",
    "الحج",
    "محجوج",
    "الحجات",
    "الحجاج",
    "الناهب",
    "توسد",
    "التوسد",
    "بيلسبري",
    "بشعر",
    "القبطانية",
    "الحلو",
    "قوادا",
    "القوادة",
    "مهنة",
    "بالبثور",
    "البثرات",
    "بثورا",
    "دبس",
    "المريلات",
    "والدبابيس",
    "بينس",
    "الكماشة",
    "إقرص",
    "مقروص",
    "القرصات",
    "المدبسة",
    "الصنوبر",
    "بينيل",
    "الأناناس",
    "موهون",
    "بينيهرست",
    "الصنوبرات",
    "غابة",
    "غابات",
    "النابتة",
    "ريشة",
    "بينج",
    "نبضات",
    "الوردي",
    "الخنصر",
    "الخناصر",
    "الوردية",
    "مدبس",
    "التدبيس",
    "البيناكل",
    "وخزة",
    "تقطع",
    "آذانها",
    "وذيلها",
    "بينسكاي",
    "الباينت",
    "تايل",
    "تايلز",
    "بينتر",
    "سيناريست",
    "ومخرج",
    "الباينتات",
    "المروحة",
    "البينكستر",
    "تقي",
    "الإنبوب",
    "بيبيكروزس",
    "الزمار",
    "لشفط",
    "الماصات",
    "المواسير",
    "التفاحات",
    "الببسوة",
    "إهانة",
    "القرصنة",
    "بيرياس",
    "البيرانا",
    "زورق",
    "جذع",
    "بيسا",
    "بيسكاتواي",
    "الحوت",
    "بيسسي",
    "متبول",
    "بيسسير",
    "التبول",
    "البستول",
    "المكبس",
    "المكابس",
    "وإرم",
    "البيتشبلند",
    "أبريق",
    "الأباريق",
    "إقتلع",
    "المعزقات",
    "ظلمة",
    "الترويج",
    "بيتشستون",
    "بشفقة",
    "بلاغة",
    "ببلاغة",
    "مستخطى",
    "المشفق",
    "الرحمات",
    "بيتني",
    "ومنع",
    "الزهيد",
    "بيتسبيرج",
    "بيتسفيلد",
    "بيتستون",
    "نخامي",
    "الإستخطاء",
    "يرثى",
    "بيوس",
    "بيفيت",
    "بيكسبف",
    "بكسلثانية",
    "مخبول",
    "رسمة",
    "بكسلية",
    "البيتزا",
    "مسامح",
    "بتسامح",
    "إسترض",
    "مسترضى",
    "بلاكاتر",
    "يسترضى",
    "الإسترضاء",
    "الاسترضاء",
    "مسترضي",
    "المموه",
    "إستقرار",
    "التنسيب",
    "مشيمي",
    "الإنتحال",
    "البلاجيوكلاز",
    "الطاعون",
    "البلايد",
    "وشاح",
    "أبسط",
    "بلينفيلد",
    "مشفرة",
    "بلينفيو",
    "مضفر",
    "بلاناريتي",
    "بلانك",
    "مملس",
    "إستواء",
    "الفأرات",
    "مفردها",
    "فأرة",
    "وتستخدم",
    "لتنعيم",
    "كوكبي",
    "اللامحدودة",
    "تدور",
    "نشوء",
    "كواكب",
    "التمليس",
    "الخشبي",
    "بلوح",
    "العوالق",
    "الغراس",
    "الغراسون",
    "الطرطشة",
    "مرشوش",
    "الرش",
    "بلاسم",
    "البلازما",
    "بلازمي",
    "بالبلازما",
    "مأكل",
    "اللصقة",
    "اللصقات",
    "البلاستيك",
    "البلاستيسين",
    "بلاستيكي",
    "بلاستيسول",
    "تجهيز",
    "الهضبة",
    "الهضاب",
    "صفيحة",
    "صفيحات",
    "إسطوانة",
    "الكاتبة",
    "دحروجة",
    "إسطوانات",
    "الألة",
    "التصفيحات",
    "بالبلاتين",
    "البلاتين",
    "المكرر",
    "أفلاطون",
    "أفلاطوني",
    "أفلاطونيا",
    "الأفلاطونية",
    "الفصيل",
    "بلات",
    "بلاتيفيل",
    "بلاتي",
    "المستوية",
    "المستهتر",
    "المستهترون",
    "تشغيلإيقاف",
    "قفص",
    "للأطفال",
    "تذرع",
    "متذرع",
    "يتذرع",
    "رجاء",
    "المبهج",
    "يسر",
    "المسرة",
    "برضى",
    "إبتهج",
    "المتع",
    "ثني",
    "الإستفتاء",
    "الإستفتاءات",
    "الوعد",
    "الوعود",
    "إجتماع",
    "الجنب",
    "بالرئه",
    "بليكسيجلاس",
    "إجتهد",
    "طيات",
    "الزردية",
    "بلني",
    "تثاقل",
    "المثابر",
    "يتثاقل",
    "إسقط",
    "الإنفجاري",
    "محروث",
    "الحراثة",
    "المحاريث",
    "بلوير",
    "شفرات",
    "العزم",
    "ناتف",
    "بلوكينيس",
    "العزوم",
    "التلفون",
    "ملحقات",
    "أجاص",
    "بالريش",
    "الشاقول",
    "معلوم",
    "السباك",
    "السباكون",
    "السباكة",
    "بلامر",
    "الشواقيل",
    "بلومبين",
    "سمنة",
    "الناهبون",
    "ينقر",
    "بلانتلي",
    "الأسبق",
    "التعددية",
    "التعددي",
    "تعددي",
    "التعدديون",
    "جمعي",
    "بلترش",
    "سيرة",
    "بلوتو",
    "الأغنياء",
    "بلوتون",
    "البلوتونيوم",
    "الإجتهاد",
    "بليموث",
    "ميكانيكية",
    "بو",
    "بوكاهونتاس",
    "سكينة",
    "سكاكين",
    "السنفة",
    "منصات",
    "السنفات",
    "الشاعرة",
    "الشاعرات",
    "الشعري",
    "بوجو",
    "المدبرة",
    "البوي",
    "بوينكير",
    "بوينديكستر",
    "البوينسيتيا",
    "استوائي",
    "لنقطة",
    "بتركيز",
    "المدبب",
    "الأحد",
    "مستعد",
    "يستعد",
    "الإستعداد",
    "السم",
    "المسمم",
    "السموم",
    "بويسون",
    "الوخزة",
    "البوكر",
    "المساعر",
    "الوخزات",
    "بولندا",
    "قطبي",
    "التقاطب",
    "إستقطب",
    "المقطب",
    "يستقطب",
    "بولارويد",
    "الظربان",
    "الإنفعالية",
    "إنفعالي",
    "المجادلات",
    "جادل",
    "يجادل",
    "بالعمود",
    "الشرطيات",
    "السياسات",
    "المستند",
    "خشبي",
    "شلل",
    "المصقلات",
    "مؤدب",
    "التأدب",
    "أدبا",
    "سياسيا",
    "مسيس",
    "يسيس",
    "التسييس",
    "سياسيون",
    "بوليتوفايس",
    "بولك",
    "البولكا",
    "المنقط",
    "إستطلاع",
    "منيل",
    "الطلع",
    "المستفتي",
    "يرقي",
    "بولوك",
    "إستطلاعات",
    "مستطلع",
    "الملوثات",
    "للبيئة",
    "بولكس",
    "بوليانا",
    "البولو",
    "البولونيز",
    "البولناز",
    "البولونيوم",
    "العاب",
    "والصولجان",
    "بوليبتين",
    "المشحمات",
    "المطاط",
    "التخصصي",
    "الكريستالات",
    "البولستر",
    "البولي",
    "إيثيلن",
    "للأوعية",
    "إيثيلنات",
    "الزوجات",
    "المضلعات",
    "المتعدد",
    "وحي",
    "المقدس.الأساطير",
    "بوليسوبتيلين",
    "الموسوعي",
    "بوليميريس",
    "الكيميائية",
    "بلمر",
    "مبلمر",
    "يبلمر",
    "البلمرة",
    "بولنيسيا",
    "بولينيزي",
    "متعددو",
    "بوليفيمس",
    "بأكثر",
    "صوت.",
    "كحرف",
    "A",
    "التأليفة",
    "بوليفوسفيت",
    "البوليفوسفوريك",
    "البوليبروبيلين",
    "بوليساكيريد",
    "البوليستيرين",
    "بلاستيك",
    "3",
    "التقنيات",
    "الإشراك",
    "بالله",
    "المشرك",
    "بموسيقى",
    "بوليفينيل",
    "بوم",
    "الرمانة",
    "بوميرانيا",
    "الأولى.",
    "الان",
    "مقسومة",
    "والمانيا",
    "لكم",
    "اللكم",
    "بومونا",
    "البهاء",
    "البومبادور",
    "تسريح",
    "بومبانو",
    "بومبيي",
    "بومبي",
    "شدات",
    "التفاخرات",
    "التفاخم",
    "البركة",
    "المتأمل",
    "البركات",
    "بونغ",
    "حريري",
    "بونتياك",
    "لمقاطعة",
    "أقم",
    "قداس",
    "تسريحة",
    "القلطي",
    "احمق",
    "براز",
    "غائط",
    "مسربة",
    "جاسوسي)",
    "للفقراء",
    "قوم",
    "بوبيك",
    "بوبفونك",
    "البوبلين",
    "مفرقع",
    "إبزيم",
    "الخشخاش",
    "التفرقع",
    "الفرقعات",
    "الشعبيات",
    "جاعل",
    "محببا",
    "مرغوبا",
    "بالسكان",
    "الشعبيون",
    "قافزة",
    "خنزيري",
    "النيص",
    "ممعن",
    "البغروس",
    "الإمعان",
    "بدانة",
    "الخلاعي",
    "خلاعي",
    "خلاعيا",
    "المساميات",
    "المسامية",
    "السماقي",
    "العصيدة",
    "تنقليا",
    "تعدية",
    "بوابات",
    "التنغيم",
    "إستدار",
    "متنبأ",
    "مشئوم",
    "النذر",
    "بورتشيا",
    "أورانوس",
    "الرواق",
    "الأروقة",
    "المعمدة",
    "بورتلند",
    "وقار",
    "بورتو",
    "البرتغال",
    "بورتسموث",
    "البرتغالي",
    "الرجلة",
    "تهيئته",
    "بوسايدون",
    "المحيرة",
    "المتكلفون",
    "بوسي",
    "باقات",
    "موقعي",
    "الوضعي",
    "الواقعي",
    "الوضعيون",
    "الواقعيون",
    "بشحنة",
    "موجبة",
    "بوسنر",
    "إمتلك",
    "ممسوس",
    "بوسيسيدنيس",
    "بالملكية",
    "الممتلكات",
    "تملكيا",
    "بريدي",
    "يؤرخ",
    "لاحق",
    "أرخ",
    "الدكتوراة",
    "الملصق",
    "الملصقات",
    "أحرف",
    "مضافة",
    "الوفاة",
    "بمرحلة",
    "ختم",
    "مختوم",
    "بختم",
    "أختام",
    "مكاتب",
    "مسبوق",
    "مؤجل",
    "نتائج",
    "التذييل",
    "التذييلات",
    "المسلمة",
    "المسلمات",
    "الإفتراضات",
    "متموضع",
    "بوستشرر",
    "البوتاسيوم",
    "معاقرة",
    "البطاطة",
    "البطاطا",
    "بكرش",
    "الكروش",
    "كوقود",
    "الكرش",
    "يؤلف",
    "بوتيمكين",
    "جيش",
    "الكامنة",
    "فعلا",
    "تطلق",
    "بالحفريات",
    "المغارات",
    "بوتلاتش",
    "إحتفالات",
    "المتوفر",
    "بوتوماك",
    "رديف",
    "العطرية",
    "بوتسدام",
    "بوتاواتومي",
    "الخزافون",
    "بوتس",
    "القعادة",
    "مكيس",
    "بوغكيبسي",
    "الداجنة",
    "مكمد",
    "التكميد",
    "الدواجن",
    "الهجمة",
    "يهجم",
    "هجم",
    "المنقض",
    "الإنقضاض",
    "مقصوف",
    "المدفق",
    "بوسين",
    "العبوس",
    "عبس",
    "الطابي",
    "التبويز",
    "أسير",
    "المسحوق",
    "البودرة",
    "الذر",
    "لمسح",
    "المساحيق",
    "باول",
    "الزهري",
    "بم",
    "تطبيقه",
    "الممارسات",
    "الممارسون",
    "برادو",
    "متحف",
    "الأسباني",
    "براغماتي",
    "الذرائعي",
    "الذرائعيون",
    "براغ",
    "المديح",
    "إستحقاقية",
    "يرفس",
    "الرفاس",
    "البريزيوديميوم",
    "برات",
    "بسقوط",
    "براتفيل",
    "برافدا",
    "جريدة",
    "روسية",
    "التعليمية",
    "تمرين",
    "مصلي",
    "بخشوع",
    "الصلاوات",
    "يصلي",
    "الوعاظ",
    "واعضة",
    "الديباجة",
    "المضخم",
    "الكمبري.",
    "544",
    "ثبات",
    "الجسيمة",
    "وقائي",
    "الوقائية",
    "إسبق",
    "مرشد",
    "المرتلون",
    "النصائح",
    "يلتف",
    "ويدور",
    "إلتف",
    "وداور",
    "والدوران",
    "بالكلور",
    "عزيزا",
    "النفاسة",
    "للترسيب",
    "مرسب",
    "يهطل",
    "الهطول",
    "ترسبي",
    "الانحدار",
    "حائل",
    "مبكر",
    "المبكر",
    "المسبق",
    "والأحاسيس",
    "الدفينة",
    "إطبخ",
    "يطبخ",
    "البوادر",
    "تبشيري",
    "التسبيق",
    "نهبية",
    "يسند",
    "التوقعية",
    "التنبؤات",
    "المتنبيء",
    "هيئ",
    "يهيئ",
    "سائد",
    "بالدرجة",
    "مساد",
    "الشفعة",
    "وقائيا",
    "مستبق",
    "بريفاب",
    "فضل",
    "التفضيلات",
    "تفضيلي",
    "يفضل",
    "البادئة",
    "بالكلمة",
    "البادئات",
    "إقلاعها",
    "سخن",
    "إلتفافي",
    "حكما",
    "كافية",
    "الإجحاف",
    "الإنحياز",
    "التمهيدات",
    "التمهيد",
    "بدء",
    "الأوان",
    "وقته",
    "المناسب",
    "تعمد",
    "مبيت",
    "يتعمد",
    "التعمد",
    "متعلقق",
    "ليلة",
    "ليالي",
    "الهاجس",
    "الهواجس",
    "بالخطر",
    "متدرب",
    "تلقي",
    "الإنشغالات",
    "المدرسية",
    "بيعها",
    "التحضيرات",
    "تحضيري",
    "تحضيريا",
    "إستعد",
    "بإستعداد",
    "مقدما",
    "الغالبية",
    "متفوق",
    "جرا",
    "إمتاز",
    "الإمتيازات",
    "قصو",
    "المشيخي",
    "بريسبيتارية",
    "ماقبل",
    "المدرسة",
    "الغيب",
    "تنبؤا",
    "بريسكوت",
    "الوصفة",
    "الوصفات",
    "تقادمي",
    "تقادميا",
    "منتقى",
    "المقدمون",
    "حاضرية",
    "الحافظ",
    "الرئاسات",
    "رئاسي",
    "رئاسيا",
    "المترأس",
    "يترأس",
    "بريسلي",
    "غسلها",
    "الضغوطات",
    "الطابعات",
    "بمقام",
    "المهابة",
    "المعزوفة",
    "بريستن",
    "يجهد",
    "تحسبا",
    "لجهد",
    "التظاهرات",
    "تظاهريا",
    "متوتر",
    "خارق",
    "خارقة",
    "إختبر",
    "مختبر",
    "الإختبار",
    "يختبر",
    "بريتوريا",
    "الإدارية",
    "لجنوب",
    "البسكويتة",
    "المملح",
    "بريف",
    "التحاشي",
    "للمنع",
    "بريفينتابلي",
    "الفريسة",
    "مفترس",
    "الإفتراس",
    "الفرائس",
    "بريام",
    "لطروادة",
    "لائحة",
    "الأسعار",
    "مسعر",
    "التسعير",
    "الأشواك",
    "الشائك",
    "الغرزات",
    "الفخر",
    "مفتخر",
    "فخور",
    "الإفتخار",
    "الفضولية",
    "الكاهنة",
    "الكاهنات",
    "الكهانة",
    "بريستلي",
    "لأكثر",
    "100",
    "الكهنوتية",
    "المتصلف",
    "التزمت",
    "المتصلفون",
    "زم",
    "الصدارات",
    "قرود",
    "معبى",
    "مبادىء",
    "يعبي",
    "البدائية",
    "باحتشام",
    "الاحتشام",
    "برموس",
    "أميري",
    "الأميرية",
    "الأمراء",
    "الأميرات",
    "برينسيتون",
    "المباديء",
    "مبدئي",
    "المبادئ",
    "للطبع",
    "برينتيت",
    "الطباع",
    "المطبوعات",
    "مرتين",
    "بريوري",
    "مقدمو",
    "بريسيلا",
    "الموشور",
    "موشوري",
    "منشوري",
    "الموشورات",
    "التشدد",
    "متحفظ",
    "بريتشارد",
    "فضلك",
    "الخلوات",
    "الحناء",
    "امتيازات",
    "امتياز",
    "سريا",
    "للمحترفين",
    "المتنافس",
    "فائز",
    "بأن",
    "تسير",
    "لليقين",
    "إحتمالي",
    "إحتماليا",
    "إثبات",
    "صحة",
    "إثباتات",
    "إختباري",
    "المختبرون",
    "تقصى",
    "بروبير",
    "المسابر",
    "التقصي",
    "البروكايين",
    "لعلاج",
    "الإيراد",
    "الإيرادات",
    "موكبي",
    "المماطل",
    "المماطلون",
    "إنجابي",
    "إنظباط",
    "والتزام",
    "بروكرستيز",
    "أسطوري.",
    "بروكتر",
    "المستقيم",
    "شرج",
    "محصل",
    "الإقتناء",
    "التحصيل",
    "النخزة",
    "المبذرون",
    "الإعجوبة",
    "النخز",
    "المنتجات",
    "للإنتاج",
    "الإنتاجية",
    "تدنيسي",
    "تجديفي",
    "التجديف",
    "صرح",
    "يصرح",
    "الأستاذية",
    "أستاذي",
    "الأساتذة",
    "لمحة",
    "لمحات",
    "مربحة",
    "الإستغلالي",
    "الإستغلاليون",
    "الأرباح",
    "باسراف",
    "التكهنات",
    "برمج",
    "التقدمية",
    "التعجيزية",
    "القذيفة",
    "المقذوفات",
    "التسليط",
    "إسقاطي",
    "بروكاريوت",
    "بروكوفيف",
    "باليه",
    "سيمفونيات",
    "متطاول",
    "والضع",
    "البروليتاري",
    "البرولين",
    "برولوج",
    "للإطالة",
    "مقتبس",
    "بروميثيوس",
    "أوليمبس",
    "أعطاها",
    "للبشرية.",
    "البروميثيوم",
    "بإباحة",
    "موعود",
    "تعهدي",
    "لبحر",
    "للترقية",
    "ترويجي",
    "التشجيعية",
    "ملقنو",
    "مرئي",
    "التلقينات",
    "المسارعة",
    "منكفئ",
    "لـ..",
    "التصريحات",
    "الناطق",
    "التلفظ",
    "البرهان",
    "مقاوما",
    "تصحيحي",
    "للماء",
    "الصدأ",
    "البراهين",
    "دعائية",
    "حاول",
    "إقناع",
    "حوول",
    "محاولة",
    "كاثر",
    "مكاثر",
    "يكاثر",
    "المكاثرة",
    "التوليدات",
    "البروبان",
    "المراوح",
    "النزعات",
    "النبوءات",
    "النبوءة",
    "الرسول",
    "الرباني",
    "النبية",
    "النبيات",
    "نبوي",
    "بروبيونيت",
    "البروبيونيك",
    "الإستعطاف",
    "مواتي",
    "المقترح",
    "المقترحون",
    "التناسب",
    "متكافئ",
    "يتناسب",
    "المنسب",
    "الإقتراحات",
    "إقترح",
    "الاقتراح",
    "يقترح",
    "إقتراحي",
    "راود",
    "المقترحات",
    "إرادية",
    "وأعضاءه",
    "بروبيل",
    "البروبيلين",
    "بروبين",
    "بملل",
    "النثر",
    "حاكم",
    "محاكم",
    "يحاكم",
    "المحاكمات",
    "نثر",
    "الاهتداء",
    "الناثر",
    "بروسيربن",
    "سيريز",
    "أصبحت",
    "الجريمة.",
    "إسهابا",
    "عروضي",
    "مستقبليا",
    "الترقب",
    "إنجح",
    "ناجح",
    "النجاح",
    "ينجح",
    "البروستات",
    "بديلي",
    "مارست",
    "منطرح",
    "ينطرح",
    "الإنطراح",
    "بروتكتينيوم",
    "البروتيني",
    "البروتينية",
    "المحمية",
    "المحمي",
    "المحميون",
    "البروتستانتي",
    "البروتستانتية",
    "البروتستانتيون",
    "إحتجاجا",
    "احادي",
    "البروتوبلازم",
    "بروتوبلازمي",
    "البروتوزوا",
    "بروتوزوي",
    "للتمدد",
    "المنقلة",
    "بروست",
    "مبرهنة",
    "المبرهن",
    "مثلي",
    "كمثل",
    "بروفيدانس",
    "مدخر",
    "المجهز",
    "المحافظة",
    "المحافظات",
    "القروي",
    "إقليمي",
    "مؤقتا",
    "الإستفزازي",
    "الإستفزازيون",
    "الإستفزازات",
    "باستفزاز",
    "روؤساء",
    "الجؤجؤ",
    "المتصيد",
    "المتصيدون",
    "محتويات",
    "المتزمت",
    "التعقل",
    "المتزمتون",
    "محتشم",
    "الإجاص",
    "التقليم",
    "التلهف",
    "ملحة",
    "لحك",
    "هرش",
    "بروسي",
    "المزمور",
    "الرضى",
    "أرطال",
    "المربعة.",
    "الصدفية",
    "النفسانيون",
    "العقلية",
    "روحي",
    "لفهم",
    "السيكولوجيا",
    "بالمقاييس",
    "السيكولوجي",
    "الخلل",
    "العقلي",
    "مضطربو",
    "بدنيا",
    "البدني",
    "الممات",
    "المعقدون",
    "الإختلالات",
    "الإختلال",
    "سيكولوجي",
    "جسمي",
    "ذهاني",
    "المعتوهون",
    "سيلليوم",
    "والمدرسين",
    "الترمجان",
    "بطليموس",
    "الفلكي",
    "البطليموسي",
    "إسكندراني",
    "الجيفين",
    "عاني",
    "والإعلان",
    "علنا",
    "بوشيني",
    "جعد",
    "الحبيبي",
    "الصهر",
    "المحاشم",
    "بويريليتي",
    "ريكو",
    "النفخة",
    "البافبول",
    "إنتفاخا",
    "البفن",
    "النفخات",
    "الصلصال",
    "بف",
    "الضى",
    "تلاكمي",
    "جبار",
    "بولاسكي",
    "نح",
    "ينوح",
    "بليتزر",
    "بالولايات",
    "بولير",
    "البكرات",
    "بلينجز",
    "البولمان",
    "ركاب",
    "فاخرة",
    "بولبيلي",
    "الجوهرية",
    "اللبية",
    "المنبر",
    "المنابر",
    "المشع",
    "إنبض",
    "ينبض",
    "متعاقد",
    "إمتداد",
    "بولسير",
    "للطحن",
    "للسحق",
    "إسحق",
    "الطاحن",
    "الخفاف",
    "غذت",
    "مضخوخ",
    "الضخ",
    "بزرة",
    "اليقطين",
    "بالرسميات",
    "بالشكليات",
    "ينقط",
    "بيونيك",
    "ضئالة",
    "العقاب",
    "للعقاب",
    "فسادا",
    "ماصة",
    "لدماء",
    "المتلاعب",
    "بالألفاظ",
    "المتلاعبون",
    "التسيير",
    "الجرو",
    "يتشرنق",
    "تشرنق",
    "التشرنق",
    "العرائس",
    "محركو",
    "الجراء",
    "جرو",
    "بورسيل",
    "المشتريات",
    "بوردو",
    "الأصيل",
    "أصفى",
    "حملات",
    "منقى",
    "المنقي",
    "المنقيات",
    "ينقي",
    "نق",
    "بورينا",
    "البيورين",
    "وشفاف",
    "الصفوية",
    "المتشددون",
    "النقاوات",
    "النقاوة",
    "الخرير",
    "ضواحي",
    "البلدة",
    "الأرجوان",
    "باللون",
    "العنابي",
    "إرجواني",
    "بوربلنج",
    "المضمون",
    "المضامين",
    "الغرض",
    "هادف",
    "العشوائية",
    "قصد",
    "القرقرة",
    "مخرخر",
    "الخرخرة",
    "بخرخرة",
    "مزموم",
    "محاسبو",
    "الزم",
    "برسلين",
    "للمتابعة",
    "المواصلات",
    "متابع",
    "قيحي",
    "الممونون",
    "آماد",
    "بوسان",
    "بسي",
    "لاهوتي",
    "لمعلومات",
    "مروجو",
    "بإقدام",
    "المفصول",
    "اليسيرة",
    "الحقير",
    "صديدا",
    "الهرات",
    "الهرة",
    "إزدرائي",
    "الضعيف",
    "مضاجعة",
    "بتنام",
    "إفسد",
    "لفافة",
    "لفاف",
    "الجولف",
    "مضارب",
    "الأسطوانات",
    "بايجماليون",
    "خلق",
    "تمثالا",
    "امرأة",
    "أحبه",
    "البيجامة",
    "بايل",
    "بيونج",
    "يانج",
    "بيراكانث",
    "الهرم",
    "الأهرام",
    "بيران",
    "بيركس",
    "لزجاج",
    "بيريداين",
    "وسام",
    "كمثري",
    "البيريميدين",
    "البيريت",
    "البيريتز",
    "متوفرة",
    "كهربي",
    "الحراري",
    "مهووس",
    "بإشعال",
    "البيرومتر",
    "البيرومترات",
    "الحرهرة",
    "المرتفعة",
    "بيروفوسفيت",
    "البيروكسين",
    "البيروكسينيت",
    "مقطعين",
    "قصيرين",
    "فيثاغورس",
    "فيثاغوري",
    "فيثاغوريون",
    "البيثون",
    "افعى",
    "الثعابين",
    "اضطراب",
    "للجهاز",
    "كيو",
    "القات",
    "تمضغ",
    "وتعمل",
    "كعمل",
    "المنبهات",
    "وهو",
    "موقوق",
    "الوقوقة",
    "دجلي",
    "الدجالون",
    "الساحة",
    "رباعي",
    "رباعيات",
    "المخصصة",
    "لدراسة",
    "التربيعية",
    "التربيع",
    "أربع",
    "الرباعي",
    "1,000,000,000,000,000",
    "0.000,000,000,000,001",
    "ذوات",
    "الأربع",
    "يربع",
    "توائم",
    "التربع",
    "كواهوج",
    "يذوى",
    "جاذبية",
    "الكويكري",
    "وطباع",
    "الكويكريون",
    "الإهتزاز",
    "للتأهيل",
    "بقبول",
    "المؤهلون",
    "يتأهل",
    "تأهل",
    "التأهل",
    "نوعي",
    "النوعيات",
    "النوعية",
    "مغثي",
    "بالغثيان",
    "ضليع",
    "كوانتيكو",
    "للكمية",
    "كمي",
    "المقسمون",
    "محجور",
    "المحاجر",
    "كوارك",
    "المتشاجر",
    "متشاجر",
    "للشجار",
    "مقلع",
    "الحجارة",
    "الكوارت",
    "خلف",
    "الفصلية",
    "فصليا",
    "الإعاشة",
    "ضباط",
    "الرباعيات",
    "الرابعة",
    "الربعية",
    "الكوارتات",
    "الكوارتز",
    "الكوارتزيت",
    "صخر",
    "يأتي",
    "يتشابه",
    "سلوكه",
    "لجزيء",
    "المتهدج",
    "مرتجف",
    "المتهدجة",
    "الكندية",
    "كويبيك",
    "الملكة",
    "الملكات",
    "شذوذا",
    "كويز",
    "غصن",
    "إقمع",
    "مقموع",
    "القامع",
    "للإخماد",
    "مخمد",
    "قامع",
    "مطفيء",
    "الضمأ",
    "يطفأ",
    "الإستفسارات",
    "متشكي",
    "التشكي",
    "إستفسار",
    "بتساؤل",
    "مبحوث",
    "الكوازال",
    "طابور",
    "المصطف",
    "المصطفون",
    "الطوابير",
    "الإنتظار",
    "كويزون",
    "إقليم",
    "الكيش",
    "المحيي",
    "المشوق",
    "التسريع",
    "الرمال",
    "الجنيه",
    "يصمت",
    "أسكت",
    "أهدأ",
    "الهمود",
    "كويلوورت",
    "اللحاف",
    "خياطة",
    "اللحف",
    "خمس",
    "السفرجلة",
    "السفرجل",
    "كوينسي",
    "الكينين",
    "كوين",
    "الخناق",
    "التوأم",
    "القنطار",
    "القناطير",
    "الخماسيات",
    "الخامسة",
    "1,000,000,000,000,000,000",
    "0.000,000,000,000,000,001",
    "يخمس",
    "التخميس",
    "كوينتس",
    "قيل",
    "مازحة",
    "الملزمة",
    "كويرينال",
    "إلتواء",
    "كويتو",
    "المخالصة",
    "المخالصات",
    "الانهزامي",
    "الانهزاميون",
    "الرعشة",
    "الرعشات",
    "كاشيوت",
    "الواية",
    "بشهامة",
    "ممتحن",
    "الإختبارات",
    "متسائل",
    "كوونسيت",
    "لأكواخ",
    "متنقلة",
    "الإقتباسات",
    "يومي",
    "خوارج",
    "آر",
    "الراديوم",
    "ربانيا",
    "الرعاع",
    "رابين",
    "أحصنة",
    "المسابقون",
    "مضمار",
    "حلبة",
    "رايتشل",
    "راتشمانينوف",
    "راسين",
    "العنصريون",
    "راك",
    "سبيس",
    "الرادار",
    "الرادارات",
    "رادكليف",
    "للروايات",
    "القوطية",
    "الدائرية",
    "راديان",
    "إشعاع",
    "شع",
    "مشعوع",
    "يشع",
    "المشعاع",
    "المشعاعات",
    "الراديكالي",
    "الراديكالية",
    "التجذر",
    "مجذر",
    "يجذر",
    "التجذير",
    "جذري",
    "الراديكاليون",
    "أنصاف",
    "الإذاعي",
    "الإشعائية",
    "الإشعائي",
    "الإشعاعي",
    "راديو",
    "الإشعاعية",
    "أبرق",
    "إشعاعيا",
    "الشعاعية",
    "بأجهزة",
    "اللاسلكي",
    "المذياع",
    "بأشعة",
    "أكس",
    "رصد",
    "الأحوال",
    "اللاسلكية",
    "البرقيات",
    "التليفون",
    "المهاتفة",
    "بالعناصر",
    "الرادون",
    "الممتصة",
    "راي",
    "رافائيل",
    "النخل",
    "فاحش",
    "باليانصيب",
    "يقامر",
    "الطوافة",
    "رافتر",
    "مرفد",
    "الطوافات",
    "الخرقة",
    "خرقة",
    "الصعلوك",
    "الصعاليك",
    "مهتاج",
    "الإهتياج",
    "المهترئة",
    "مشاهد",
    "مشاهدو",
    "القطار",
    "سيج",
    "الموبخ",
    "الموبخون",
    "السور",
    "المهازلات",
    "المهازلة",
    "سكك",
    "المطر",
    "قزح",
    "المطري",
    "المطرية",
    "الإمطار",
    "الزبيب",
    "راج",
    "الجراف",
    "المتساقطة",
    "ريلاي",
    "محشد",
    "التحشيد",
    "رالف",
    "رالستون",
    "الكبش",
    "رامادا",
    "رامان",
    "المتسلقة",
    "مشتت",
    "المشاغبة",
    "يتفرع",
    "رامريز",
    "النفاث",
    "صدم",
    "رامو",
    "رامونا",
    "التعلية",
    "التعليات",
    "مدك",
    "مدكات",
    "الأكباش",
    "رامسيس",
    "متداعي",
    "الرند",
    "راندل",
    "الهائجون",
    "راندولف",
    "العشوائي",
    "عشوئ",
    "يعشوئ",
    "التعشئة",
    "متراوح",
    "التراوح",
    "رانجون",
    "رانير",
    "المراتب",
    "رانكين",
    "انتخاب",
    "رانكاين",
    "الرتب",
    "إلتهب",
    "يلتهب",
    "الفدية",
    "مفتدى",
    "المفتدي",
    "الإفتداء",
    "الفديات",
    "المتشدق",
    "المتشدقون",
    "راؤول",
    "بضراوة",
    "الإغتصاب",
    "مغتصب",
    "المغتصب",
    "السيف",
    "السيوف",
    "المغتصبون",
    "إهبط",
    "الراب",
    "مغنو",
    "الوئام",
    "سارح",
    "بانغماس",
    "نشوة",
    "الطرب",
    "بانتشاء",
    "التخلخل",
    "مخلخل",
    "يخلخل",
    "خلخل",
    "نادرا",
    "أندر",
    "متشوق",
    "راريتان",
    "جوهر",
    "الديانة",
    "النذالة",
    "نذل",
    "راسموسين",
    "مقشوط",
    "القشاط",
    "القشط",
    "فتات",
    "المبارد",
    "أفقية",
    "راستس",
    "الجرذ",
    "راتا",
    "نيوزيلندا",
    "للتقدير",
    "بالإستدارة",
    "السقاطة",
    "السقاطات",
    "بالأحرى",
    "بالأحرى...",
    "الجوهري",
    "العقلانيون",
    "العقلانية",
    "الترشيد",
    "عقلانيا",
    "الجرذان",
    "راتيل",
    "تهلهلا",
    "الخرخاشة",
    "الخرخاشات",
    "الجرسية",
    "مهلهل",
    "سبقا",
    "بشبق",
    "مدمرة",
    "العربيد",
    "يهتاج",
    "مكرونة",
    "الرافيولي",
    "إنهب",
    "ينهب",
    "هزيلة",
    "نحيفة",
    "بالجلد",
    "التكسية",
    "رولينجز",
    "راولينز",
    "رولينسون",
    "الدبرة",
    "روسون",
    "رايبرن",
    "رايد",
    "رايليغ",
    "رايموند",
    "رايموندفيل",
    "الحرير",
    "رايثيون",
    "محيرة",
    "بالإصدار",
    "كومبيوتر",
    "تعدل",
    "تتضخم",
    "إمتص",
    "ريكلماتيزيشنس",
    "المتناولات",
    "شكر",
    "علما",
    "الحاضرة",
    "مردود",
    "الرجعيون",
    "ردود",
    "المفاعلات",
    "إقرأ",
    "للقراءة",
    "المقروئية",
    "مقروءة",
    "تكييف",
    "يخاطب",
    "أعاد",
    "عنونة",
    "الإستعدادات",
    "الجاهزية",
    "القراءات",
    "تأجل",
    "إقرأني",
    "متبنى",
    "تبني",
    "يقرأ",
    "للإشتغال",
    "للإستلام",
    "للإرسال",
    "ريغان",
    "الكواشف",
    "حقيقي",
    "الإصطفاف",
    "للتحقيق",
    "بواقعية",
    "المتيقن",
    "متيقن",
    "تخصيص",
    "العوالم",
    "الريالات",
    "إحصد",
    "المفغرة",
    "تعيين",
    "تقدر",
    "تسليح",
    "إعتقل",
    "إعتقال",
    "التفكر",
    "تجميع",
    "تأكيده",
    "زعم",
    "ريسيسمينتس",
    "للتعيين",
    "تستوعب",
    "إستيعاب",
    "طمأن",
    "مطمئن",
    "يطمأن",
    "تطميني",
    "إرتباط",
    "يحاول",
    "تخول",
    "تخويل",
    "إصح",
    "مصحو",
    "صحوة",
    "يصحو",
    "معمودية",
    "تعميد",
    "ريبيكا",
    "الإنبعاث",
    "مذاع",
    "إذاعة",
    "إذاعة..",
    "التفنيدات",
    "تدرج",
    "متذكر",
    "التذكير",
    "رسملة",
    "تمويل",
    "لمعاودة",
    "إسترد",
    "مسترد",
    "يسترد",
    "الإسترداد",
    "تفحيم",
    "منحسر",
    "إستلام",
    "الإيصالات",
    "إستلم",
    "موقعا",
    "مستلم",
    "المستلم",
    "المستلمون",
    "الإستلام",
    "مركزة",
    "موظفو",
    "الإستقبالات",
    "التقبل",
    "التلقن",
    "المستقبلات",
    "تراجعي",
    "الإنحسارات",
    "تراجعية",
    "الرجعية",
    "الإرتدائية",
    "المرتكس",
    "ريسيف",
    "للإستقبال",
    "المتبادلات",
    "بادل",
    "مبادل",
    "يبادل",
    "المبادلة",
    "التسميع",
    "المنغمة",
    "حضارة",
    "يمدن",
    "إرع",
    "الحاسبون",
    "الإستصلاح",
    "إتكأ",
    "يتكئ",
    "الإتكاء",
    "المعتكفون",
    "الإنعزال",
    "الإنطوائية",
    "ريكودز",
    "تشفير",
    "المتعرف",
    "تجديد",
    "تذكر",
    "يتذكر",
    "إستعمار",
    "تهجيني",
    "إبدأ",
    "أوص",
    "التوصيات",
    "المزكي",
    "تكليف",
    "إقترف",
    "يقترف",
    "إحالة",
    "مقترف",
    "إقتراف",
    "تتميم",
    "تحسب",
    "حاسبات",
    "للتسوية",
    "الإستطلاع",
    "يستطلع",
    "إستطلع",
    "إحتلال",
    "تعزيز",
    "بثانية",
    "مجددا",
    "ناسخ",
    "بالسجل",
    "المسجلة",
    "إسترجع",
    "مسترجع",
    "يسترجع",
    "الإستعانة",
    "تعافى",
    "المعافاة",
    "الإستجمام",
    "ترفيهي",
    "بلورة",
    "المستقيمات",
    "مستقيمي",
    "المستطيل",
    "مستقيم",
    "كاهن",
    "إرجع",
    "التكرارات",
    "الرجوع",
    "لعنة",
    "استدعاء",
    "التكرارية",
    "معالجته",
    "واستعماله",
    "ريدبيرد",
    "ريدبد",
    "التحمير",
    "إحمرارا",
    "المحمر",
    "تعلن",
    "ريديكليرس",
    "زينة",
    "أهد",
    "المخلص",
    "المخلصون",
    "المتحرر",
    "عرين",
    "إيداع",
    "توديع",
    "مطور",
    "ريدفورد",
    "هضم",
    "تذويب",
    "باحمرار",
    "ريدموند",
    "الزكية",
    "برائحة",
    "بمهابة",
    "الردبول",
    "التصليح",
    "الطيطواة",
    "ريد",
    "ستارت",
    "ريدستون",
    "توب",
    "ريدر",
    "بالقصب",
    "رييدلينج",
    "البوص",
    "رييدفيل",
    "مفاح",
    "نافث",
    "الفوح",
    "إنتخاب",
    "اللفاف",
    "تصعد",
    "تؤكد",
    "توظيف",
    "تشرع",
    "ريس",
    "تخمين",
    "تقيم",
    "مرر",
    "التمرير",
    "تفحص",
    "خفيفة",
    "أحل",
    "المرجع",
    "مرجعي",
    "مرجعيا",
    "الإحالات",
    "الراجع",
    "يحيل",
    "إخصاب",
    "العبوة",
    "للتعبأة",
    "العبوات",
    "الانعكاس",
    "منعكس",
    "الإنعكاسات",
    "عاكس",
    "الإنعكاسية",
    "العاكسات",
    "حان",
    "إنعكاسي",
    "إزدهر",
    "طوي",
    "تشجير",
    "الإصلاحيات",
    "تهيئة",
    "منصلح",
    "المصلحون",
    "إصلاحي",
    "تأسيس",
    "إنكسر",
    "منكسر",
    "انكساري",
    "إنكسارية",
    "المنكسر",
    "ينكسر",
    "تجميد",
    "تنعش",
    "المنعش",
    "المنعشات",
    "المرطبات",
    "قلي",
    "تزود",
    "التزود",
    "يتزود",
    "المأوى",
    "إلتجأ",
    "اللاجىء",
    "اللاجئون",
    "الإلتجاء",
    "مبلغ",
    "السداد",
    "المبالغ",
    "تأثيث",
    "الداحض",
    "يستعيد",
    "متع",
    "يمتع",
    "بخصوص",
    "مهما",
    "يكن",
    "الوصي",
    "الأوصياء",
    "إستنبت",
    "الريجيه",
    "الفوج",
    "التكتيب",
    "الأفواج",
    "ريجينا",
    "ريجنالد",
    "الإقليمية",
    "ريجيس",
    "تعطي",
    "إرتدادي",
    "إعاقة",
    "الأسف",
    "آسف",
    "مؤسف",
    "أسف",
    "نم",
    "تنمية",
    "منمى",
    "ينمي",
    "النظامي",
    "الإنتظام",
    "بإنتظام",
    "النظاميون",
    "التعليمة",
    "التقئ",
    "معه",
    "قولبة",
    "تسخين",
    "الرايخ",
    "ريتشينبيرج",
    "رييتشستاج",
    "النواب,",
    "للإمبراطورية",
    "رايلي",
    "للإبهار",
    "الزمام",
    "التناسخ",
    "إندماج",
    "الرنة",
    "معدى",
    "إعداء",
    "يعدي",
    "تلهب",
    "إلهاب",
    "عزز",
    "أفعم",
    "راينارد",
    "رينهاردت",
    "راينولد",
    "تشغل",
    "شغلت",
    "تلقيح",
    "تفتيش",
    "أوعز",
    "موعز",
    "الإيعاز",
    "يوعز",
    "تأمين",
    "يتكامل",
    "ترجمة",
    "تفسير",
    "مقابلة",
    "الأعادة",
    "إستثمار",
    "إنتعاش",
    "دعوة",
    "المرفوض",
    "رفضي",
    "المرفوضون",
    "سعيدة",
    "منضم",
    "تحوك",
    "إعتبرت",
    "المعاودة",
    "المنتكس",
    "تالي",
    "علائقي",
    "النسبية",
    "النسبوية",
    "بالنسبية",
    "إرتح",
    "الإرخاء",
    "مرتاح",
    "الإسترخاء",
    "المهديء",
    "يرتاح",
    "الإرتياح",
    "تتعلم",
    "لن",
    "ملين",
    "بالموضوع",
    "بصلة",
    "وثيقة",
    "الإعتمادية",
    "متكل",
    "بإعتماد",
    "مجيز",
    "ريليكت",
    "الإغاثة",
    "إشعر",
    "بالإرتياح",
    "المريح",
    "شعور",
    "الأديان",
    "دينيا",
    "التدين",
    "للمتعة",
    "عيش",
    "تحميل",
    "قرض",
    "مكانه",
    "إنتقل",
    "منتقل",
    "ينتقل",
    "ممانع",
    "تكبر",
    "تبقى",
    "التبقي",
    "المتبقي",
    "ريمبراندت",
    "إصلاحية",
    "علاجي",
    "المتذكر",
    "المذكر",
    "ريمنجتن",
    "تذكري",
    "بالذكرى",
    "مغفرته",
    "الحوالة",
    "الحوالات",
    "تحوير",
    "تنقد",
    "يجيز",
    "كعملة",
    "تنقيد",
    "إحتج",
    "يحتج",
    "بندم",
    "المجددة",
    "إعتلاء",
    "البديلة",
    "الإزالات",
    "كافئ",
    "مكافأ",
    "يكافئ",
    "ريمس",
    "لروملس",
    "رينا",
    "النهضة",
    "كلوي",
    "أعاده",
    "طبيعته",
    "يعيده",
    "العوده",
    "رينو",
    "تواعد",
    "التواعد",
    "رينيه",
    "ريني",
    "إنكث",
    "نكث",
    "ينكث",
    "مفاوضات",
    "المنفحة",
    "رينوا",
    "ترشيح",
    "ومقبولة",
    "الهاجر",
    "رمم",
    "يرمم",
    "الترميم",
    "المرمم",
    "الصيت",
    "رينسيلار",
    "يروف",
    "بالتنازل",
    "رينفيل",
    "تعارض",
    "إعادات",
    "يغير",
    "فئة",
    "الممثلين",
    "تهدأة",
    "إصبغ",
    "صباغة",
    "يصبغ",
    "التصليحات",
    "الجواب",
    "يمر",
    "الأكلات",
    "تبليط",
    "التسديدات",
    "مرارا",
    "وتكرارا",
    "المكررون",
    "التوبة",
    "توبة",
    "يندم",
    "ناس",
    "تشك",
    "متشكى",
    "المتضايق",
    "يتشكى",
    "إستبدل",
    "يستبدل",
    "الإستبدال",
    "المرافعة",
    "المعبيء",
    "للمرة",
    "شبعان",
    "الملىء",
    "الشبع",
    "مجاب",
    "المجاوب",
    "الأجوبة",
    "بولندي",
    "تشيع",
    "التعمير",
    "الصحفية",
    "مخبر",
    "ذكرت",
    "تقريري",
    "بسكون",
    "ملصق",
    "للتمثيل",
    "بتمثيل",
    "مكبوت",
    "للكبح",
    "تسعير",
    "التنفيس",
    "الإنتقامي",
    "الإنتقامية",
    "للتأنيب",
    "ملقي",
    "تأنيبي",
    "بتأنيب",
    "لائما",
    "ملوم",
    "النقمة",
    "التكاثر",
    "بتكاثر",
    "التأنيب",
    "مؤنبا",
    "فئات",
    "ريبتيل",
    "الجمهورية",
    "الجمهوريون",
    "الجمهوريات",
    "الكريه",
    "الصدة",
    "لكمة",
    "شراء",
    "تنقي",
    "بتوقير",
    "أرواح",
    "تطلب",
    "المتطلب",
    "يتطلب",
    "التطلب",
    "مبلغا",
    "يعاود",
    "مسار",
    "جاري",
    "للتالي",
    "صهريج",
    "تنقذ",
    "ضوئيا",
    "منقذ",
    "تعزل",
    "عزل",
    "تفرقة",
    "إختيار",
    "البائعون",
    "الشبيه",
    "إشبه",
    "يرسل",
    "بإمتعاض",
    "حسبت",
    "ريسيرباين",
    "إضافتهه",
    "للإحتياطي",
    "التحفظات",
    "الإحتياطيات",
    "الإحتياط",
    "الخزان",
    "الإستيطان",
    "مستوطن",
    "تخيط",
    "تشحذ",
    "إستقر",
    "يستقر",
    "متبقي",
    "الترسبات",
    "البواقي",
    "منخول",
    "الإستقالة",
    "الإستقالات",
    "مستقيل",
    "المستقيل",
    "يستقيل",
    "الرزين",
    "بعنصر",
    "راتنجي",
    "الراتنج",
    "قاوم",
    "أخت",
    "مقاومته",
    "المقاومات",
    "ريسيزينج",
    "نعل",
    "تنعيل",
    "إسترخائي",
    "العزيمة",
    "العزائم",
    "الملاذ",
    "رنان",
    "رن",
    "الرنانون",
    "ريسورسينول",
    "فينول",
    "المصيف",
    "ملجوء",
    "فارز",
    "المصايف",
    "دو",
    "مدوي",
    "خنزيرة",
    "بذرت",
    "بإحترام",
    "متسما",
    "التوالي",
    "تهج",
    "متهجى",
    "تهجي",
    "يتهجى",
    "تنفسي",
    "متنفس",
    "ممهل",
    "التأجيلات",
    "الإمهال",
    "المستجيبون",
    "المجاوبون",
    "بمسؤولية",
    "للقبول",
    "أوكسفورد",
    "متجاوب",
    "باستجابة",
    "الاستجابة",
    "إستأنف",
    "برهانا",
    "مستأنف",
    "مبدئ",
    "الإستئناف",
    "المستريح",
    "المخزون",
    "للترميم",
    "المقوية",
    "المرممون",
    "بضبط",
    "بتحديد",
    "هيكلة",
    "الهيكلة",
    "تقديمه",
    "المحصلات",
    "النتاج",
    "للتكملة",
    "معاود",
    "تموين",
    "الظاهر",
    "منبعث",
    "النهوض",
    "ينبعث",
    "إنبعث",
    "إحيائي",
    "مصاحبة",
    "مزامن",
    "بالمفرد",
    "يبيع",
    "إحتفظ",
    "محتفظ",
    "الإحتفاظ",
    "الاحتباس",
    "يحتفظ",
    "أخذه",
    "إنتقم",
    "منتقم",
    "الإنتقام",
    "انتقامي",
    "إنتقامي",
    "المانعات",
    "تهوع",
    "متهوع",
    "يتهوع",
    "التهوع",
    "الاستبقاء",
    "التكتم",
    "شبكي",
    "يشبك",
    "لمعدة",
    "شبكية",
    "الشبكية",
    "تقاعد",
    "متقاعد",
    "يتقاعد",
    "لمس",
    "للتقصي",
    "متتبع",
    "يتتبع",
    "إنسحب",
    "للسحب",
    "منسحب",
    "للانقباض",
    "الإنقباضات",
    "جرار",
    "الكامشات",
    "ينسحب",
    "تدريب",
    "متراجع",
    "ريتريتر",
    "تخفيضات",
    "جازى",
    "كافأ",
    "المسترد",
    "المستردون",
    "ريترو",
    "اعد",
    "أدراجه",
    "العائد",
    "أعكس",
    "مراجعة",
    "تاريخ",
    "ريفيفال",
    "يسحب",
    "ريفولتر",
    "الثوريون",
    "الثورية",
    "مثور",
    "يثور",
    "التثوير",
    "للدوران",
    "المنوعات",
    "مجزي",
    "دافئ",
    "تزن",
    "تجشأت",
    "يطوي",
    "ريكس",
    "ريكفيك",
    "رينولدز",
    "أحمرأخضرأزرقألفا",
    "بعاطفة",
    "مفرطة",
    "مؤلفو",
    "تحمس",
    "يتحمس",
    "الرابسودي",
    "ريا",
    "ريمز",
    "رينهولدت",
    "الراين",
    "الرينيوم",
    "بتشوه",
    "المتغيرة",
    "بلاغي",
    "البلاغية",
    "البلغاء",
    "مفعمون",
    "بالبلاغة",
    "بالروماتيزم",
    "الروماتزم",
    "روماتيزمي",
    "رينلاندر",
    "الكركدن",
    "الكركدنات",
    "رو",
    "رودا",
    "رود",
    "رودس",
    "روديسيا",
    "الروديوم",
    "الريددندرين",
    "الرودونيت",
    "قرنفلي",
    "معيني",
    "المعينة",
    "روندا",
    "الروبارب",
    "القافية",
    "مقفى",
    "القوافي",
    "التقفية",
    "ايلند",
    "التضليع",
    "بشريط",
    "فيتامين",
    "ريبوز",
    "البنتوز",
    "بالريبوسوم",
    "ريبوسوم",
    "ريكا",
    "ريكي",
    "ريتشاردز",
    "ريتشاردسون",
    "يثري",
    "أثرى",
    "الإثراء",
    "أغنى",
    "ريتشي",
    "ريتشفيلد",
    "ريتشلاند",
    "ريتشموند",
    "ركتر",
    "ريك",
    "ريكينباوجه",
    "الكساح",
    "الطفيلية",
    "محلحل",
    "مركوب",
    "الراكب",
    "الراكبون",
    "ريج",
    "ريدجيفيلد",
    "ردجواي",
    "الإمتطاء",
    "ريجيل",
    "ريمان",
    "ريمانيان",
    "يخدش",
    "ريغا",
    "التزوير",
    "ريجس",
    "يمين",
    "المحق",
    "اليمنى",
    "الجاك",
    "الأيمن",
    "الميكروفون",
    "إستقام",
    "باستقامة",
    "شرعا",
    "يميني",
    "اليمين",
    "يمينا",
    "الحكاية",
    "المهمرجة",
    "الحكايات",
    "بصرامة",
    "الإغاظة",
    "ريلك",
    "سجع",
    "بالصقيع",
    "رينيهارت",
    "المتقطع",
    "سماعة",
    "قارع",
    "قارعو",
    "ووضوح",
    "الجديلة",
    "الجدائل",
    "المتزحلق",
    "التشطيف",
    "الشطاف",
    "يشطف",
    "الشطف",
    "ريو",
    "ريوردان",
    "إضطرابات",
    "شغب",
    "مشاغب",
    "بشغب",
    "التمزق",
    "ضفاف",
    "بنضج",
    "إنضج",
    "المنضج",
    "ريبلي",
    "الممزق",
    "الفاسدون",
    "توجيهات",
    "الناهض",
    "الناهضون",
    "ريسيسيترانسيت",
    "مضحكون",
    "خوطر",
    "المجازف",
    "خطرا",
    "مجازفة",
    "المنسك",
    "المناسك",
    "ريتير",
    "الطقوسية",
    "المتمسك",
    "بالطقوس",
    "شعائري",
    "المتمسكون",
    "طقس",
    "مطقس",
    "يطقس",
    "التطقيس",
    "ريتز",
    "منافس",
    "التنافسات",
    "ضفة",
    "الأنهار",
    "الضفة",
    "ريفيرفيو",
    "المبرشم",
    "شاطئ",
    "الريفييرا",
    "النهير",
    "الرياض",
    "مصلحو",
    "قارعة",
    "قارعات",
    "تصليح",
    "الهائم",
    "الأغبر",
    "الزئير",
    "المزمجر",
    "الشواء",
    "محمص",
    "التحميص",
    "يحمص",
    "روب",
    "روبي",
    "روبن",
    "روبينس",
    "روبرت",
    "روبيرتا",
    "روبيرتو",
    "روبرتس",
    "روبيرتسون",
    "روبيرتسونس",
    "روبينز",
    "روبنسن",
    "روبنسونفيل",
    "الآليون",
    "روكو",
    "روتشستر",
    "روكيفيلير",
    "الجنائن",
    "الهزازة",
    "الجنينة",
    "وتصميم",
    "روكفورد",
    "صخري",
    "روكيز",
    "روكلاند",
    "روكفيل",
    "روكويل",
    "روكوود",
    "قارض",
    "مسابقة",
    "روجيرس",
    "رودني",
    "رودريجو",
    "رونتجن",
    "لأشعة",
    "روجر",
    "روجرز",
    "عربد",
    "معربد",
    "المعربد",
    "العربدة",
    "يعربد",
    "رولند",
    "الأدوار",
    "اللفة",
    "للدحرجة",
    "الدحروجة",
    "المزلجة",
    "الدحروجات",
    "الملفوفة",
    "رولنز",
    "اللفات",
    "إليها",
    "الرومانسية",
    "الرومانسي",
    "الرومانسيون",
    "الرومانسيات",
    "رومانيا",
    "رومانو",
    "العاطفي",
    "روميو",
    "روميوس",
    "رومبير",
    "السروال",
    "رومولوس",
    "رون",
    "رونالد",
    "روني",
    "بناءون",
    "المغدفات",
    "المغدفة",
    "روكي",
    "روزفيلت",
    "فرانكين",
    "المجثم",
    "المجاثم",
    "الجذر",
    "العراقة",
    "الجذور",
    "رورسكاتش",
    "روزا",
    "روزابيل",
    "روزالي",
    "المسبحات",
    "المسبحة",
    "الوردة",
    "بتفاؤل",
    "أغصان",
    "ثمرة",
    "روزلاند",
    "روزيلا",
    "أكاليل",
    "روزماري",
    "روزنبيرج",
    "روزنبلوم",
    "روزنثال",
    "روزنزويج",
    "روزيتا",
    "روزيت",
    "روزتيس",
    "روزوود",
    "روزي",
    "وردية",
    "الراتينج",
    "الصنوبري",
    "روس",
    "روزسي",
    "روزويل",
    "الروتاري",
    "للتدوير",
    "تدويري",
    "دائر",
    "الدوارات",
    "الاحتياط",
    "روتينون",
    "روث",
    "روثشيلد",
    "البدن",
    "كروية",
    "الروبل",
    "تثخينة",
    "خشنا",
    "أخشن",
    "تعارك",
    "العنيفون",
    "الأشراس",
    "الروليت",
    "رحلة",
    "كاملة",
    "ذهابا",
    "وإيابا",
    "مستديرة",
    "مدور",
    "إستدارة",
    "المستديرة",
    "موقظ",
    "الموقض",
    "الإيقاظ",
    "روسو",
    "روستابوت",
    "الإندحار",
    "المسارات",
    "الروتينات",
    "الإندحارات",
    "روان",
    "زوارق",
    "مجذف",
    "شوكة",
    "روينا",
    "رولاند",
    "رولي",
    "روكسبري",
    "روكسي",
    "روي",
    "الملكيون",
    "رويس",
    "روزيل",
    "أجبني",
    "سمحت",
    "رتي",
    "رواندا",
    "التدليك",
    "مفروك",
    "مطط",
    "ممطط",
    "يمطط",
    "التمطيط",
    "السائح",
    "الفرك",
    "الأنقاض",
    "روبين",
    "الفلمنكية",
    "الروبيديوم",
    "الياقوت",
    "الروبلات",
    "الإرشادات",
    "الجربنديات",
    "الدفات",
    "متورد",
    "تورد",
    "كناية",
    "أوقح",
    "رودولف",
    "رودي",
    "رديارد",
    "الحرمل",
    "الكشكشة",
    "المختال",
    "للحمرة",
    "روفس",
    "البساط",
    "الروم",
    "الرومانيون",
    "الرومبا",
    "الدمدمة",
    "مقرقع",
    "المدوي",
    "يقرقع",
    "قرقرة",
    "المجترة",
    "رومفورد",
    "المجترات",
    "إجتر",
    "يجتر",
    "الإجترار",
    "رمل",
    "مفشي",
    "الشائعات",
    "الإشاعات",
    "الردف",
    "الروني",
    "الرونية",
    "رنج",
    "الساقية",
    "السواقي",
    "سيولة",
    "رنيميد",
    "المرات",
    "الضعفاء",
    "المدارج",
    "رنيون",
    "الروبية",
    "الروبيات",
    "روبيرت",
    "التمزقات",
    "روراليتي",
    "الطلبية",
    "العاجلة",
    "المتعجل",
    "رشمور",
    "روسل",
    "الزعفراني",
    "الزعفرانية",
    "الروس",
    "روسولا",
    "ريف",
    "مريف",
    "يريف",
    "التريف",
    "صدءا",
    "بصدىء",
    "الحفيف",
    "محفوف",
    "سارق",
    "سراق",
    "يحف",
    "للصدأ",
    "الحف",
    "صدئ",
    "روتجيرز",
    "الروثينيوم",
    "روثرفورد",
    "رتايل",
    "التيتانيوم",
    "رتلاند",
    "رتليدج",
    "محفر",
    "قراءةكتابة",
    "موارد",
    "التنمية",
    "الاقتصادية",
    "رايان",
    "ريدبيرج",
    "رايدر",
    "الجاودار",
    "بالماضي",
    "إباحة",
    "إشهار",
    "تجل",
    "الميليشيا",
    "المخلوقة",
    "بقدسية",
    "إجازة",
    "تؤخذ",
    "سبع",
    "سبتي",
    "بالسيف",
    "سابينا",
    "سابين",
    "تيكساس",
    "السمامير",
    "القبقاب",
    "القباقيب",
    "صبرا",
    "اسرائيل",
    "الحويصلة",
    "السكرين",
    "ساتشس",
    "والموسيقى",
    "الكياس",
    "عجزي",
    "ساكرامينتو",
    "ضحية",
    "المضحي",
    "المضحون",
    "التضحيات",
    "تدنيس",
    "مصون",
    "الحويصلات",
    "صدام",
    "حسين",
    "إيران.",
    "تسبب",
    "غزوه",
    "للكويت",
    "حرب",
    "الثانية.",
    "1937",
    "الخرج",
    "أخراج",
    "مسرج",
    "السراج",
    "السروج",
    "السراجون",
    "الإسراج",
    "السادية",
    "السادي",
    "ساديا",
    "الساديون",
    "سادلر",
    "المحزن",
    "ممزوجة",
    "بتعذيب",
    "الذات",
    "ومعذب",
    "بسلامة",
    "أمانا",
    "الأمن",
    "القرطم",
    "الحصافة",
    "الميرمية",
    "ساجينو",
    "سهمي",
    "نخيل",
    "ساجوارو",
    "اشجار",
    "الصحارى",
    "سيجون",
    "بحارة",
    "القديسون",
    "الخواطر",
    "ساكورا",
    "للبيع",
    "سالابلي",
    "السمندر",
    "السمندرات",
    "السلامي",
    "سالم",
    "ساليرنو",
    "البائعة",
    "ساليسايان",
    "البائعات",
    "ساليسيليكي",
    "سالينا",
    "سالينجير",
    "ساليسبري",
    "اللعاب",
    "لعابي",
    "الإندفاعات",
    "سالي",
    "السالمونيلا",
    "الصالون",
    "الصالونات",
    "الصلصا",
    "سالسيفي",
    "الصلت",
    "سالتبش",
    "أملح",
    "مالحا",
    "التمليح",
    "ترحيبيا",
    "ترحيبي",
    "سالوتر",
    "سلفادور",
    "السلفادوري",
    "للإنقاذ",
    "النقائذ",
    "سالفاتور",
    "الصواني",
    "الصلية",
    "الصليات",
    "سالز",
    "سامري",
    "السامريون",
    "السمريوم",
    "نفيس",
    "السامبا",
    "سامو",
    "السامون",
    "السماور",
    "سابمسن",
    "سامسن",
    "صموئيل",
    "صمويل",
    "سامويلسون",
    "سان",
    "صنعاء",
    "سانبورن",
    "سانتشيز",
    "سانشو",
    "بتظاهر",
    "بالتقوى",
    "القداسة",
    "المعتكف",
    "المعتكفات",
    "الصندل",
    "الصنادل",
    "الشاطىء",
    "الرملي",
    "الجلي",
    "بالرمل",
    "ساندبيرغ",
    "صقل",
    "سانديرلينج",
    "ساندرز",
    "ساندرسن",
    "سانديا",
    "المنومات",
    "الطيطوي",
    "ساندرا",
    "ساندسكاي",
    "السندويتش",
    "السندويتشات",
    "ساندي",
    "عاقل",
    "أعقل",
    "سانفورد",
    "السنجري",
    "السانجريا",
    "سانهيدرين",
    "الكنائسي",
    "للأمة",
    "سانيكل",
    "المصح",
    "عقم",
    "تصريف",
    "سلامة",
    "يعقم",
    "التعقيم",
    "غرق",
    "سانس",
    "سنسكريتي",
    "بابا",
    "سانتايانا",
    "سانتياغو",
    "سانتو",
    "بالإنسان",
    "الشتلة",
    "الشتلات",
    "صوبن",
    "عصارة",
    "العصارة",
    "عصيري",
    "سابسكير",
    "انواع",
    "نقار",
    "ساره",
    "سيره",
    "ساران",
    "ساراسوتا",
    "ساراتوجا",
    "التهكمات",
    "اللحمي",
    "السردين",
    "سارجينت",
    "الساري",
    "الفشاغ",
    "لباسي",
    "سارتري",
    "الزنار",
    "ترنح",
    "يترنح",
    "الزنانير",
    "ساسكاتشوان",
    "ساسكاتون",
    "المجففة",
    "لشجرة",
    "الساسافراس",
    "ساسين",
    "يزدري",
    "الساتين",
    "الساتان",
    "بقابلية",
    "الهجاؤون",
    "إهج",
    "يهجو",
    "للرضا",
    "ساتوري",
    "إدراك",
    "للتشبع",
    "زحل",
    "ساتور",
    "الإغريقي",
    "إباحي",
    "شبقي",
    "سعود",
    "السعودي",
    "سوكفيل",
    "ساول",
    "سولت",
    "حمام",
    "البخار",
    "حمامات",
    "ساوندرز",
    "المتهادي",
    "الهمج",
    "العشبي",
    "أنقذ",
    "الموفرون",
    "ينقذ",
    "التوفير",
    "المدخرات",
    "سافوير",
    "سافونارولا",
    "مذاق",
    "المتذوق",
    "المشهية",
    "المشهي",
    "سافوي",
    "شاطر",
    "الشطارة",
    "الساوبيلي",
    "دولارات",
    "نشارة",
    "بالمنشار",
    "سوفيش",
    "بمؤخرة",
    "كالمنشار",
    "طاولة",
    "نشره",
    "مشرشف",
    "النشار",
    "ساكس",
    "سكسوني",
    "السكسونيون",
    "ساكسوني",
    "الساكسفون",
    "الساكسفونات",
    "الساكس",
    "ساكستون",
    "القائل",
    "القائلون",
    "يقول",
    "الجلبة",
    "الأغماد",
    "احتشام",
    "السقالة",
    "التسقيل",
    "السقالات",
    "سكالا",
    "استخدمت",
    "لمعالجة",
    "خلع",
    "جدوى",
    "يتعاظم",
    "السمط",
    "السلقة",
    "الحرشفية",
    "الصدفي",
    "صدفي",
    "الأسقلوب",
    "المحارات",
    "المباضع",
    "سلخ",
    "فروات",
    "الجمبري",
    "الأشقياء",
    "الفضيحة",
    "الإسكندنافيا",
    "إسكندنافي",
    "الإسكندنافيون",
    "الإسكنديوم",
    "النواسخ",
    "تقطيع",
    "الضآلة",
    "سكاب",
    "كبش",
    "الفداء",
    "أكباش",
    "كتفي",
    "كتفيات",
    "الندبة",
    "الجعلان",
    "سكاربوروه",
    "الفزاعات",
    "المفزع",
    "الوشاح",
    "سكارفيس",
    "المتحدة.",
    "الوشاحات",
    "إخافة",
    "مخدوش",
    "الخادش",
    "إخدش",
    "سكارلاتي",
    "القرمزي",
    "سكارسديل",
    "التلفيعة",
    "مسرعا",
    "إنتقاد",
    "بانتقاد",
    "البراز",
    "المشتتون",
    "متفرق",
    "المشتت",
    "سكوب",
    "إقتت",
    "مقتات",
    "الزبال",
    "الزبالون",
    "الإقتيات",
    "بمصدر",
    "السيناريو",
    "السيناريوهات",
    "المشهد",
    "بتفويض",
    "شكاك",
    "شايفر",
    "شايفير",
    "شافر",
    "شافنير",
    "شانز",
    "شابيرو",
    "المجدول",
    "الجدولة",
    "شيهيريزاد",
    "شين",
    "شيل",
    "شيلينج",
    "مخططات",
    "خطة",
    "مدبرو",
    "سكينيكتدي",
    "شيلر",
    "إنشقاقي",
    "الإنشقاقات",
    "بالفصام",
    "فطريات",
    "الفصام",
    "تلكأ",
    "شلسينجر",
    "سكليرين",
    "تكتلات",
    "وتكون",
    "باقي",
    "شليز",
    "شلوس",
    "شمالزي",
    "شميت",
    "سكنابل",
    "الشنوزر",
    "شنيدر",
    "شوينبيرج",
    "سكوفيلد",
    "شولاستيس",
    "الشنطة",
    "المدرسي",
    "تلميذ",
    "تلاميذ",
    "المدارس",
    "تلميذة",
    "تلميذات",
    "Schoolhouses",
    "شوبينهاير",
    "شوتكي",
    "شرويدر",
    "شرويدنجير",
    "المضحك",
    "شوبيرت",
    "شولز",
    "شوماخر",
    "شومان",
    "شوستر",
    "شويلر",
    "شيلكيل",
    "شواب",
    "شوارز",
    "شويزير",
    "وركي",
    "علميا",
    "الشرارة",
    "أومض",
    "يومض",
    "بتأثير",
    "الانشقاق",
    "الانشقاقات",
    "صلبي",
    "بقلة",
    "إحترام",
    "سليطة",
    "المعاتب",
    "سليطات",
    "الكعكات",
    "الغارف",
    "بالمغرفة",
    "شعراء",
    "العتيقة",
    "الحارقة",
    "التلويحات",
    "محرق",
    "بطاقات",
    "مخلفات",
    "المصهور",
    "الاحتقار",
    "العقارب",
    "الإسكتلندي",
    "إسكتلندي",
    "سكوتشجارد",
    "أسكوتلندا",
    "الإسكتلنديون",
    "سكوت",
    "سكوتسدال",
    "سكوتي",
    "وغد",
    "الفراكة",
    "الكشاف",
    "المستطلع",
    "الكشافة",
    "الكشافون",
    "لجرف",
    "سكرابلير",
    "النحيف",
    "الهزال",
    "هزالأكثر",
    "تعرج",
    "هزالالأكثر",
    "النحيفون",
    "مجاهد",
    "الانصراف",
    "سكرانتون",
    "المكشطة",
    "المكشطات",
    "الورطات",
    "تفككا",
    "للهرش",
    "الخدوش",
    "التمهيدية",
    "الشخبطة",
    "بخط",
    "هزالة",
    "بصياح",
    "المملة",
    "أساسه",
    "السينمائية",
    "الشاشات",
    "لقطة",
    "البرغي",
    "برغي",
    "بندق",
    "الأهوج",
    "سكروبين",
    "المفك",
    "المفكات",
    "شد",
    "المبرغي",
    "البراغي",
    "بلوفلاي",
    "طباعي",
    "السكريم",
    "تقتيرا",
    "سكريبس",
    "للقاريء",
    "كتابيا",
    "سكريفين",
    "الليمفاوية",
    "خنازيري",
    "اللفيفة",
    "للتدرج",
    "شريطة",
    "تتيح",
    "رؤية",
    "والصور",
    "شرائط",
    "اللفائف",
    "الصفن",
    "إستجد",
    "يستجدى",
    "بوعورة",
    "الدعك",
    "التورع",
    "إكتشافه",
    "مدقق",
    "بفحص",
    "السكازي",
    "سكود",
    "صواريخ",
    "السكود",
    "جرجر",
    "مجرجر",
    "الجرجرة",
    "يجرجر",
    "المجدفون",
    "سكلبين",
    "إنحت",
    "النحاتة",
    "النحاتات",
    "ينحت",
    "نحتي",
    "تزبد",
    "رغوية",
    "ينطلق",
    "الأسقربوط",
    "اختيارين",
    "أحلاهما",
    "المناجل",
    "سيثيا",
    "سيبورج",
    "بحرا",
    "سيبروك",
    "سواحل",
    "سيجيت",
    "صلبة",
    "سيجرام",
    "للغلق",
    "مانعات",
    "الفقمة",
    "سيلي",
    "الدرز",
    "أسوء",
    "تامة",
    "شون",
    "جلسات",
    "اسماك",
    "فاحصة",
    "بحث***",
    "شاطىء",
    "شواطئ",
    "شاطيء",
    "موسمي",
    "موسميا",
    "محنك",
    "مستعملو",
    "جالس",
    "سياتل",
    "للإبحار",
    "سيباستيان",
    "ثواميثانية",
    "القاطعات",
    "المنسحب",
    "إنفصالي",
    "الإنفصاليون",
    "بعزلة",
    "الإختلاء",
    "إنعزالي",
    "المرتبات",
    "بالثانوي",
    "ثنى",
    "التثنية",
    "ثانيا",
    "الثواني",
    "سكرتاري",
    "سيكريتاريات",
    "الأمانات",
    "السكرتيرات",
    "السكرتير",
    "إفرازي",
    "الأسرار",
    "القطاعات",
    "علماني",
    "العلمانية",
    "ملحد",
    "العلمانيون",
    "العلمنة",
    "علمن",
    "معلمن",
    "يعلمن",
    "مأمون",
    "الحمالة",
    "الرزانة",
    "اﻹقامة",
    "السعادى",
    "سيدجويك",
    "الراسب",
    "الترسب",
    "أغو",
    "مغوى",
    "الإغواءات",
    "المغرر",
    "المغررون",
    "مغري",
    "الغواية",
    "الغاوية",
    "الغاويات",
    "البذرة",
    "المشاتل",
    "ناثر",
    "ناثرو",
    "بذورا",
    "البذر",
    "أرد",
    "يريد",
    "إبد",
    "بدا",
    "تسرب",
    "النز",
    "متسرب",
    "يتسرب",
    "العرافون",
    "الإرجوحة",
    "متأرجح",
    "إغل",
    "مغلي",
    "الغلي",
    "سيجوفيا",
    "المعزول",
    "داعية",
    "سيجاندو",
    "لشرب",
    "الإقطاعي",
    "الإقطاعيون",
    "سيادي",
    "الأسياد",
    "سييل",
    "زلزالي",
    "تسجيلات",
    "زلازلي",
    "بالزلازل",
    "المصادرون",
    "التملك",
    "لإمتلاك",
    "المتملكون",
    "للإختيار",
    "يقع",
    "الانتقاء",
    "الإختياريات",
    "الإنتقائية",
    "الامور",
    "نشأتها",
    "مسيرو",
    "الإنتقاء",
    "سيلينا",
    "سيلينيت",
    "السلينيك",
    "سلنيومي",
    "السلنيوم",
    "لافلزي",
    "برغيا",
    "سلفريدج",
    "سيلكيرك",
    "مهجورة",
    "شيلي",
    "سيليد",
    "سلمى",
    "للمحافظة",
    "كربونية",
    "الأنفس",
    "معاني",
    "دلاليا",
    "الإخصائيون",
    "المني",
    "الدراسي",
    "الفارزة",
    "المنقوطة",
    "الفوارز",
    "العتمة",
    "المتأهل",
    "لشبه",
    "المتأهلون",
    "المباريات",
    "شهري",
    "مؤثرا",
    "اللاهوتي",
    "الطلبة",
    "اللاهوتيون",
    "سيمينول",
    "حرفية",
    "سيميراميس",
    "أشورية",
    "الساميات",
    "إسبوعي",
    "السميذ",
    "الشين",
    "الإستشارية",
    "سناتوري",
    "سيناتوري",
    "مرسلمتلقي",
    "سينيكا",
    "السنيغال",
    "بخرف",
    "الأقدم",
    "الأقدمية",
    "السنا",
    "أحسس",
    "الإثارية",
    "الأحاسيس",
    "محس",
    "بعقلانية",
    "أناس",
    "حسس",
    "محسس",
    "يحسس",
    "التحسيس",
    "المحسس",
    "المجسات",
    "المجس",
    "المحسسات",
    "الحسية",
    "الشهواني",
    "الشهوانيون",
    "الشهوانية",
    "حسيا",
    "بريدا",
    "عقوبي",
    "بجملة",
    "للوعظ",
    "بوعي",
    "العاطفيون",
    "مصبح",
    "صبح",
    "حرس",
    "سيؤول",
    "سبتمبر/أيلول",
    "سيبال",
    "سيبالس",
    "منفصلا",
    "الإفتراق",
    "الإنفصالية",
    "الإنفصالي",
    "سيبيرات",
    "البني",
    "بحجاب",
    "السباعية",
    "السباعيات",
    "السبتيليون",
    "1",
    "وعلى",
    "شماله",
    "24",
    "السبعوني",
    "السبعونيون",
    "المرتب",
    "المعقبات",
    "التتابعية",
    "الترتر",
    "تتعلق",
    "بالإجراء",
    "سيكويا",
    "الأمصال",
    "سيرافين",
    "سيريب",
    "المكسيكيين",
    "الساروف",
    "ملائكية",
    "سيرافيم",
    "الصربي",
    "صربيا",
    "السيربو",
    "السلافية",
    "للصرب",
    "والكروات",
    "غن",
    "مواهب",
    "إكتشافي",
    "إكتشافيا",
    "موهبة",
    "بصفاء",
    "العبد",
    "القنانة",
    "العباد",
    "سيرج",
    "العرفاء",
    "سيرجي",
    "الصوفية",
    "للموازاة",
    "التسلسلات",
    "المسلسلات",
    "تباعا",
    "السيرين",
    "بجدية",
    "الواعظون",
    "سيربينز",
    "الاستوائية",
    "أفعواني",
    "يتعرج",
    "سيرا",
    "مسنن",
    "يسنن",
    "التسنين",
    "المصل",
    "المصول",
    "مخدوم",
    "الخادمالقنوات",
    "الصلاح",
    "الغبيراء",
    "أرجوانية",
    "الخدمةالملف",
    "العبودية",
    "الماكينة",
    "سيرفو",
    "المؤازرة",
    "الماكينات",
    "السمسم",
    "بكلمات",
    "بالقاعدة",
    "انعقادي",
    "النكسة",
    "النكسات",
    "سيث",
    "سيتون",
    "زعيمة",
    "دينية",
    "الصامولة",
    "الصواميل",
    "المحددات",
    "المستوطنة",
    "المستوطنات",
    "الإعدادات",
    "السبعات",
    "سابع",
    "سابعا",
    "السبعينات",
    "سبعون",
    "حدية",
    "سيفيرن",
    "ويلز",
    "إشبيلية",
    "سوارد",
    "مخيط",
    "الستيني",
    "بالرقم",
    "60",
    "جنسية",
    "متعصبون",
    "للجنس",
    "الشهوانيات",
    "سيكستانز",
    "السدسية",
    "السدسيات",
    "لستة",
    "مؤدين",
    "السكستليون",
    "القندلفت",
    "سدس",
    "يسدس",
    "التوائم",
    "الستة",
    "التسديس",
    "مجنس",
    "سايتشيلي",
    "سيمور",
    "بالأصفاد",
    "شاد",
    "شادبش",
    "الشادوك",
    "فاكهه",
    "مظلل",
    "الظلال",
    "شادفلوير",
    "ظلالا",
    "جاسوس",
    "الشاد",
    "شافير",
    "إنكح",
    "شاجبارك",
    "منكوح",
    "شعثا",
    "أشعث",
    "ينكح",
    "الشاه",
    "للهز",
    "المضطجع",
    "الهزازات",
    "شكسبيري",
    "السجيل",
    "ضحالة",
    "الضحالة",
    "الضحلة",
    "شالوم",
    "سوف",
    "الشامان",
    "الشامبو",
    "بالشامبو",
    "المغتسل",
    "النفل",
    "شنغهاي",
    "إختطف",
    "يختطف",
    "شانون",
    "ابتدع",
    "الشانتون",
    "جمال",
    "المشكل",
    "للمشاركة",
    "ازرع",
    "بالعمولة",
    "كومبيوتري",
    "نسخه",
    "وتوزيعه",
    "شاري",
    "القرش",
    "حادا",
    "إشحذ",
    "المبراة",
    "المباري",
    "مختبيء",
    "جيدو",
    "شاستا",
    "شاتيربروف",
    "شاتاك",
    "محلوق",
    "شو",
    "شاوانو",
    "الشال",
    "الشالات",
    "شوني",
    "الجزاز",
    "سلاخ",
    "أغمد",
    "مغمد",
    "غمده",
    "يغمد",
    "الإغماد",
    "إحزم",
    "شيبويجان",
    "شيهان",
    "اللمعة",
    "بملاءة",
    "الشراشف",
    "شافيلد",
    "المشيخة",
    "المشيخات",
    "شيلا",
    "شيلبي",
    "شيلدون",
    "قصف‎",
    "يتخاطب",
    "اللك",
    "طلي",
    "بدهان",
    "نيران",
    "صامد",
    "للقنابل",
    "المؤوي",
    "شيلتن",
    "الأرفف",
    "شيناندواه",
    "الإشكال",
    "الإشكالات",
    "شيبرد",
    "فضاء",
    "رعى",
    "الراعيات",
    "الشيراتون",
    "شريدان",
    "شيرلوك",
    "شيرمان",
    "الشيري",
    "شيرون",
    "شيروود",
    "صاد",
    "يخجل",
    "شيعي",
    "الشيعة",
    "الشلن",
    "الشلنات",
    "شيلونج",
    "ميجهاليا",
    "شيلوه",
    "إهتز",
    "البراق",
    "البراقون",
    "لواح",
    "التسقيف",
    "يتسلق",
    "الشنتو",
    "الشنتويه",
    "متون",
    "بنائو",
    "لجسم",
    "شيبلي",
    "لنيوزيلندا",
    "للشحن",
    "حطام",
    "المسفنة",
    "المسافن",
    "تهرب",
    "متهرب",
    "التهريب",
    "يتهرب",
    "شيرلي",
    "اللم",
    "يلم",
    "القمصان",
    "ترزي",
    "شيش",
    "كسلاح",
    "أغنية",
    "شمويل",
    "الأسراب",
    "المفزعات",
    "شوكلي",
    "أردء",
    "الرديئ",
    "لتسهيل",
    "أداوات",
    "أحذية",
    "شوميك",
    "شوير",
    "شوجي",
    "ستار",
    "ياباني",
    "أشرق",
    "مهشوش",
    "الهش",
    "شوس",
    "الدكان",
    "الدكاكين",
    "تسوق",
    "المتسوق",
    "المتسوقون",
    "مهتريء",
    "حواف",
    "شوروود",
    "المنقص",
    "السراويل",
    "انترنت",
    "الطلقة",
    "الأكتاف",
    "الصائح",
    "الصائحون",
    "الشوفيلبورد",
    "الجرافون",
    "التجريف",
    "بيده",
    "العائم",
    "المواجهات",
    "الدش",
    "زخات",
    "المتباهي",
    "التباهي",
    "اعرض",
    "اظهار",
    "الشضية",
    "ماكينات",
    "شريفيبورت",
    "الزبابة",
    "السليطة",
    "فطنة",
    "سليط",
    "السلاطة",
    "الصرد",
    "الروبيان",
    "للتقلص",
    "المتراجع",
    "بالذنب",
    "ذبل",
    "الذبول",
    "شريفير",
    "الكفن",
    "العشرة",
    "تأتي",
    "الجنبات",
    "الجنبة",
    "بفتح",
    "الإهتزازات",
    "الشفلبورد",
    "المتثاقل",
    "شلمان",
    "التحويلة",
    "حديدية",
    "الأغلاق",
    "فريق",
    "احراز",
    "اهداف",
    "الدرفة",
    "الدرفات",
    "المكوك",
    "يتنقل",
    "المرابي",
    "باستحياء",
    "سيال",
    "سيام",
    "اسيا",
    "ورسميا",
    "تايلاند",
    "سيامي",
    "أخ",
    "سايبيريا",
    "سايبيري",
    "الصفير",
    "المهموس",
    "صافر",
    "المهموسة",
    "سيبلي",
    "الشقيق",
    "الأشقاء",
    "العرافات",
    "هكذا",
    "صقلي",
    "المقزز",
    "الإمراض",
    "أمرض",
    "بالمنجل",
    "سيكلوورت",
    "السوالف",
    "المسحوبة",
    "يتحدث",
    "بالتليفون",
    "جدار",
    "مجانب",
    "يجانب",
    "المجانبة",
    "سيدني",
    "سيجل",
    "سيغفريد",
    "سيجموند",
    "سيمينز",
    "سينا",
    "تربة",
    "القيلولة",
    "سيفيرز",
    "فيلولوجي",
    "المناخل",
    "المغربلات",
    "ينخل",
    "التنهدة",
    "متنهد",
    "المتنهد",
    "التنهد",
    "التنهدات",
    "المشاهدات",
    "الأجمل",
    "بجولة",
    "مشاهدة",
    "معالم",
    "سيجما",
    "للحرف",
    "للإشاعة",
    "التواقيع",
    "الموقعين",
    "التبيين",
    "توقيعتحقق",
    "اللافتة",
    "معلم",
    "لافتات",
    "اللافتات",
    "سيخي",
    "السيخ",
    "سيكيم",
    "للهند",
    "سيكورسكاي",
    "سيلاس",
    "كاتم",
    "كاتمات",
    "السكوت",
    "سيليسيا",
    "سيليكس",
    "للسليكون",
    "المطحون",
    "الظلية",
    "السيليكا",
    "السيليكات",
    "بمادة",
    "السيليسيد",
    "احد",
    "السيليكون",
    "السليكوون",
    "السحار",
    "حريرية",
    "كالحرير",
    "الحرائر",
    "القز",
    "الغرين",
    "مغرين",
    "التغرين",
    "المتحجر",
    "بالطمي",
    "الفضة",
    "طلا",
    "بالفضة",
    "سيلفرمان",
    "صائغ",
    "صاغة",
    "سيلفيرشتاين",
    "المصنوعات",
    "سيما",
    "التشبيهات",
    "المشابهة",
    "سمونس",
    "سمونسفيل",
    "سيمز",
    "سايمون",
    "سمونسون",
    "سموني",
    "الإبتسامة",
    "المتكلفة",
    "مبتسم",
    "الإبتسام",
    "الإبتسامات",
    "البسطاء",
    "بالإتصالات",
    "البساطات",
    "مبسط",
    "المبسط",
    "ببساطة",
    "سيمبسن",
    "سمولاتيف",
    "المحاكيات",
    "أذع",
    "يذيع",
    "التواقت",
    "سيناء",
    "سيناترا",
    "بصدق",
    "أصدق",
    "سينكلير",
    "الزاية",
    "السهلة",
    "سنغافورة",
    "السطحي",
    "سطحيا",
    "المغنون",
    "بالوكالة",
    "الوحيدة",
    "الإفراد",
    "فرادى",
    "المفرد",
    "سينه",
    "إتيان",
    "طبائع",
    "أعسر",
    "العصمة",
    "مأثوم",
    "الآثم",
    "متكلس",
    "يتكلس",
    "بالمنحنى",
    "جيبي",
    "ملتو",
    "السو",
    "الرشفة",
    "السيفون",
    "بالسيفون",
    "السيفونات",
    "مرشوف",
    "الرشف",
    "المولى",
    "صفارات",
    "الشعرى",
    "العاتية",
    "السيزال",
    "السسكن",
    "الأختية",
    "الأخوات",
    "بالبابا",
    "سيكستس",
    "سيسواتي",
    "الشاق",
    "عانى",
    "الاسطوري",
    "كورينث",
    "سيسيفوس",
    "إجلس",
    "السيتار",
    "يجلس",
    "راعيات",
    "قع",
    "الوقع",
    "سيتوس",
    "سيفا",
    "الستات",
    "بستة",
    "السدس",
    "سادسا",
    "الستينات",
    "ستون",
    "الحجوم",
    "تحجيم",
    "المسموح",
    "سكا",
    "السكات",
    "ورقية",
    "الزلاجة",
    "متزحلق",
    "المتزلجون",
    "الزلاجات",
    "التزلج",
    "السكيت",
    "رماية",
    "شلة",
    "شلات",
    "هيكلي",
    "العظمية",
    "كراسة",
    "كراسات",
    "مسيخ",
    "التسييخ",
    "الأشياش",
    "المزلاجة",
    "زلق",
    "سكين",
    "إقشط",
    "تصفية",
    "قتر",
    "مقتر",
    "التقتير",
    "يقتر",
    "يقشط",
    "الدباغ",
    "الدباغون",
    "نحولا",
    "نحيل",
    "سكيبجاك",
    "التونا",
    "الربان",
    "الربانون",
    "سكيبي",
    "اول",
    "تحصل",
    "المناوشة",
    "مناوش",
    "المتحارب",
    "المتحاربون",
    "المناوشات",
    "المتجنب",
    "المزلاجات",
    "إنزلق",
    "ينزلق",
    "سكيتل",
    "سكوبي",
    "تسلل",
    "متسلل",
    "المختبيء",
    "المختبئون",
    "يتسلل",
    "الطاقيات",
    "بجمجمة",
    "الجماجم",
    "الظرابين",
    "سكاي",
    "هيلوكوبتر",
    "لحمل",
    "مختطفو",
    "السقفية",
    "نوافذ",
    "وبناء",
    "ناطحة",
    "سحاب",
    "ناطحات",
    "تكتب",
    "اطلاق",
    "دخان",
    "إركد",
    "راكد",
    "يركد",
    "الكسالى",
    "بطيئ",
    "سلاكوير",
    "إخبث",
    "خابث",
    "يخبث",
    "أرو",
    "مروى",
    "مفترى",
    "النمامون",
    "مصفوع",
    "الصفع",
    "الصفعات",
    "المشرح",
    "بناؤو",
    "القذرات",
    "ارجواني",
    "السلاف",
    "كدح",
    "النخاسون",
    "سلافي",
    "خانع",
    "الخنوع",
    "الصقلبيون",
    "إذبح",
    "يذبح",
    "تزلج",
    "تنقل",
    "بالزلاجة",
    "أملس",
    "نعم",
    "التنعيم",
    "ينعم",
    "النائم",
    "النائمون",
    "بنعاس",
    "مؤرق",
    "ينام",
    "نائما",
    "اثناء",
    "يمشون",
    "الإثلاج",
    "ثلجي",
    "الأكمام",
    "ينحف",
    "نحف",
    "التنحيف",
    "نيم",
    "يتجسس",
    "للتقطيع",
    "القطاعة",
    "وسهولة",
    "أهين",
    "مخاطية",
    "أنحل",
    "النحفاء",
    "التنحيل",
    "إنسل",
    "الإنسلال",
    "ينسل",
    "الزلة",
    "سليب",
    "املس",
    "الانزلاق",
    "الزلات",
    "مروحة",
    "انزلق",
    "المزلقة",
    "مزحلق",
    "بفتحة",
    "ضيقة",
    "المشقق",
    "المشققون",
    "الشظايا",
    "بأخشاب",
    "سلون",
    "شاب",
    "انجليزي",
    "الغنية",
    "مبتل",
    "سلوكم",
    "البرقوق",
    "فضلات",
    "مسكوب",
    "إهمالا",
    "باهمال",
    "السكب",
    "الرخيصة",
    "خض",
    "مخاض",
    "يخوض",
    "الخوض",
    "السلوث.",
    "سلوثفولنيس",
    "متراخى",
    "بترهل",
    "التهدلات",
    "ترهل",
    "التراخي",
    "السلوفاكي",
    "سلوفاكيا",
    "سلوفينيا",
    "السلوفيني",
    "البطئ",
    "بطيئو",
    "وحل",
    "البزاقة",
    "البزاق",
    "الغافي",
    "الركود",
    "الفقيرة",
    "اللطخة",
    "مدغم",
    "طين",
    "اللطخات",
    "الفاسقة",
    "الفاسقات",
    "عاهرة",
    "بعهر",
    "لكمات",
    "سمال",
    "سمالي",
    "الجدري",
    "سمولوود",
    "الذكية",
    "أنق",
    "مؤنق",
    "التأنيق",
    "يؤنق",
    "بالخجل",
    "بسطحية",
    "المعرفات",
    "المسحة",
    "الملطخ",
    "تلطخ",
    "المسحات",
    "رائحته",
    "الهف",
    "المبتسم",
    "المعبرة.",
    "سميث",
    "الأشتات",
    "سميثفيلد",
    "سميثسن",
    "سمثسوني",
    "سميثتاون",
    "المضبب",
    "للتدخين",
    "لتدخين",
    "المدخنون",
    "ساتر",
    "للجنود",
    "دخانا",
    "دخاني",
    "يعانق",
    "ملس",
    "أنعم",
    "بيسر",
    "إخنق",
    "مخنوق",
    "الإحصائية",
    "سموكير",
    "المهربون",
    "الاعتزاز",
    "الهندام",
    "سخاما",
    "التسخيم",
    "سميرنا",
    "سميذي",
    "الوجبة",
    "الكافيتريا",
    "لخبط",
    "اللخبطات",
    "متمزق",
    "الحلزون",
    "سنيك",
    "لدغة",
    "لدغات",
    "أفعوي",
    "سناكيروت",
    "الطقة",
    "ينتعش",
    "النهاش",
    "النهاشات",
    "نزقا",
    "نزق",
    "الطقات",
    "اللقطة",
    "مصطاد",
    "سنارف",
    "الإصطياد",
    "مزمجر",
    "يزمجر",
    "الخلسة",
    "سنيد",
    "منسل",
    "سرانية",
    "المخادعة",
    "العطاس",
    "عاطس",
    "العاطس",
    "العطس",
    "الشص",
    "وخيط",
    "السنكرز",
    "الشمة",
    "مشموم",
    "متشمم",
    "الشم",
    "إشهق",
    "شاهق",
    "يشهق",
    "الشهيق",
    "إستنشاقي",
    "شهيقي",
    "القرقرات",
    "الشنقب",
    "مقنوص",
    "القناص",
    "القناصون",
    "القنص",
    "لسانه",
    "سلاطة",
    "يخبر",
    "الإخبار",
    "الباكي",
    "الباكون",
    "المتأبه",
    "التأبه",
    "تكبرا",
    "المتأبهون",
    "سنودجراس",
    "سنوك",
    "السنوكر",
    "سنوكس",
    "السنوك",
    "المتلصص",
    "المتلصصون",
    "سنوبي",
    "عجرفة",
    "إغف",
    "يغفو",
    "يشخر",
    "غص",
    "يغوص",
    "الفخام",
    "النخرة",
    "اختيال",
    "مختال",
    "الخطم",
    "بأنف",
    "الخطوم",
    "متضاعف",
    "التضاعف",
    "المنحدرلت",
    "الفار",
    "بحثا",
    "ن",
    "بالثلوج",
    "تساقط",
    "ندفة",
    "بأحذية",
    "ثلجية",
    "الماشى",
    "الدافئة",
    "مزجور",
    "الزجر",
    "يزجر",
    "شم",
    "النشوق",
    "مطفئة",
    "إشخر",
    "شخير",
    "للإستنشاق",
    "يشم",
    "عابس",
    "ركن",
    "متحاضن",
    "التحاضن",
    "تحاضن",
    "يتحاضن",
    "سنججلي",
    "أركان",
    "سنيدر",
    "لذا",
    "الإنتقاع",
    "الحفاضات",
    "النقع",
    "الصابون",
    "مصوبن",
    "صابونا",
    "صابوني",
    "الزلق",
    "الرغوية",
    "الصوبنة",
    "الأملس",
    "الملساء",
    "رغوة",
    "ناشج",
    "النشيج",
    "ببكاء",
    "صاحي",
    "الألقاب",
    "إشتراكي",
    "المؤانسة",
    "بالناس",
    "الإشتراكية",
    "الإشتراكي",
    "الإشتراكيون",
    "الإجتماعيون",
    "يعاشر",
    "اقتصاديا",
    "الجورب",
    "المقبس",
    "المقابس",
    "سقراط",
    "سقراطي",
    "العشبية",
    "الصودا",
    "الإبتلال",
    "سودينج",
    "سودي",
    "فاز",
    "بجائزة",
    "1921",
    "اللواطة",
    "أيا",
    "صوفيا",
    "اللحيم",
    "السوفتبول",
    "رقة",
    "برمجته",
    "اللين",
    "تنقعا",
    "التعجن",
    "الترب",
    "الأمسية",
    "الزيارة",
    "الزيارات",
    "الصول",
    "العزاء",
    "سولاسير",
    "شمسي",
    "الشمسي",
    "سولاريس",
    "المشمسة",
    "مباع",
    "ملحوم",
    "لحام",
    "كوفح",
    "الكفاح",
    "العسكر",
    "يهيب",
    "التهييب",
    "اللولبي",
    "اللولبية",
    "اجتذب",
    "يجتذب",
    "الإعتناء",
    "التضامن",
    "يقوي",
    "قو",
    "المائل",
    "ناج",
    "مناجاة",
    "سولينجين",
    "بالذات",
    "إنفرادي",
    "المنعزلة",
    "الخلوة",
    "سول",
    "سولي",
    "منفردا",
    "لوحده",
    "المنفردون",
    "سليمان",
    "شأن",
    "ودولي",
    "المذاب",
    "يذيب",
    "الإذابة",
    "الإفاء",
    "المذيبات",
    "الحلال",
    "سومال",
    "الحاميون",
    "الصومالي",
    "الصوماليون",
    "البعض",
    "يوما",
    "سوميرز",
    "الشقلبة",
    "الشقلبات",
    "سومرست",
    "سوميرفيل",
    "أحيانا",
    "الساقي",
    "السقاة",
    "سوميرفيلد",
    "مشاء",
    "بالمشي",
    "مشاؤو",
    "السونار",
    "السوناتة",
    "السوناتات",
    "المغرد",
    "الغنائي",
    "غنائية",
    "للغناء",
    "المطرب",
    "المطربون",
    "ابنائي",
    "سوني",
    "سونوجرام",
    "سونوما",
    "سونورا",
    "الجهارة",
    "جهارة",
    "الأبناء",
    "السخام",
    "المنقوع",
    "السفسطائي",
    "سفسطائي",
    "المحنك",
    "المحنكون",
    "السفسطة",
    "السفسطائيون",
    "سوفوكليسي",
    "سوفوكليس",
    "اعظم",
    "المسرحيين",
    "بالسنة",
    "بطالب",
    "طفولية",
    "السوبرانو",
    "سورا",
    "السورب",
    "سورينسين",
    "سورينسون",
    "نوادي",
    "الحميض",
    "أسفا",
    "للفرز",
    "الفارز",
    "الفارزات",
    "الإستغاثة",
    "الدانق",
    "أدوار",
    "يتنهد",
    "أريد",
    "عديمات",
    "روح",
    "صديق",
    "يشد",
    "تصدر",
    "الأئر",
    "الشوربات",
    "المصدرية",
    "العجين",
    "المخمر",
    "محمض",
    "التحميض",
    "بحموضة",
    "السوروود",
    "سوسا",
    "القوادين",
    "ساوثامبتون",
    "جنوبا",
    "ساوثير",
    "الجنوبيون",
    "الجنوبيات",
    "سوثي",
    "ساوثفيلد",
    "الأعسر",
    "العسر",
    "سوفيتي",
    "الخضوع",
    "للسيطرة",
    "السوفيتية",
    "سوفت",
    "مسوفت",
    "يسوفت",
    "السوفت",
    "السوفييت",
    "سوفخوز",
    "الخنزيرة",
    "سورز",
    "مبذور",
    "الخنزيرات",
    "الصويا",
    "فول",
    "مباعد",
    "بالنظرية",
    "والخاصة",
    "رائدة",
    "رائدات",
    "بالفضاء",
    "المباعدة",
    "الرحابة",
    "المجرف",
    "طلع",
    "سبافورد",
    "السباغيتي",
    "سبان",
    "إسبانيا",
    "سبالدينج",
    "المقوس",
    "للمبنى",
    "المساحات",
    "المتواجدة",
    "القوسين",
    "تلألأ",
    "يتلألأ",
    "الإسباني",
    "الإسبان",
    "إسباني",
    "إصفع",
    "يصفع",
    "القناطر",
    "السارية",
    "إحتياطي",
    "الماسة",
    "الماسات",
    "سباركمان",
    "الشرارات",
    "سبارلينج",
    "العصافير",
    "الساريات",
    "متناثر",
    "تناثرا",
    "المتقشف",
    "متشنجة",
    "حيز",
    "الرشة",
    "سباتردوك",
    "الرشات",
    "سبولدينج",
    "بعظم",
    "للدابة",
    "أورام",
    "البياضة",
    "البيوض",
    "سبيتش",
    "سبيد",
    "المتكلمون",
    "سبيكيرشيب",
    "الطاعن",
    "بالرمح",
    "متزعم",
    "التزعم",
    "يتزعم",
    "الإختصاصات",
    "الإختصاص",
    "التخصصات",
    "يتخصص",
    "الخاصيات",
    "الرقطة",
    "الروائع",
    "سبيكتور",
    "الأطياف",
    "الطيفية",
    "منظار",
    "الطيفي",
    "التطييف",
    "طيفين",
    "مختلفين",
    "تقارن",
    "كشاف",
    "بمنظار",
    "منظاري",
    "خمن",
    "يخمن",
    "المضاربون",
    "مضاربي",
    "الوجوم",
    "قيادة",
    "بسرع",
    "إسأل",
    "باستكشاف",
    "مستكشفو",
    "استكشاف",
    "املائي",
    "الإملائي",
    "المتهجي",
    "المتهجون",
    "التهجئ",
    "التهجئات",
    "سبيلانكير",
    "مكتشفو",
    "إستكشاف",
    "سبينسر",
    "لهربرت",
    "سبنسر",
    "فلسفته",
    "مصروف",
    "المنفقون",
    "المبذر",
    "المسرفون",
    "سبينجلر",
    "ونظريته",
    "بشان",
    "الحيمن",
    "منوي",
    "البزري",
    "الحيامن",
    "سبيري",
    "المتقيء",
    "كبريتيد",
    "الزنك",
    "كرويا",
    "الكروي",
    "المصرة",
    "المصرات",
    "مشين",
    "للمتحدث",
    "بالأسبانية",
    "السنبلة",
    "فيرجو",
    "سبيسبش",
    "توابلا",
    "سبيك",
    "اطراف",
    "حادة",
    "العناكب",
    "سبيدرورت",
    "متجسس",
    "شبيغل",
    "الجاسوس",
    "الجواسيس",
    "سنبل",
    "التشويك",
    "الإنسكاب",
    "يسكب",
    "المسكوبة",
    "والفائضة",
    "المنسكبة",
    "مصارف",
    "انسكب",
    "السبانخ",
    "منغزل",
    "سبيندلر",
    "المغازل",
    "الإنغزال",
    "وزجاجي",
    "اكسيد",
    "الماغنيسيوم",
    "والألمنيوم",
    "فقري",
    "الشوكية",
    "للأقمشة",
    "الغزالون",
    "العانس",
    "العانسات",
    "التنفسية",
    "الإحتكاكي",
    "المستدبة",
    "الروحاني",
    "محضر",
    "محضرو",
    "روحيا",
    "سبيرو",
    "النكاية",
    "مغاظ",
    "العصبيون",
    "المبصقة",
    "المباصق",
    "سبتز",
    "الرشاش",
    "مرذرذ",
    "يرش",
    "فلطح",
    "مسطحة",
    "وملتوية",
    "التفلطح",
    "يفلطح",
    "الطحال",
    "سبلينوورت",
    "بروعة",
    "طحالي",
    "خدد",
    "التجبير",
    "الإتلاف",
    "متباهى",
    "همهم",
    "الغنائم",
    "للفساد",
    "مفسدو",
    "سبوكان",
    "بأسلاك",
    "للإطارات",
    "منطوق",
    "الناطقون",
    "الرسميون",
    "الناطقة",
    "المتحدثات",
    "بالأسلاك",
    "الإسفنج",
    "الإسفنجات",
    "إسفنجي",
    "الإسفنجة",
    "الإسفنجية",
    "آنيا",
    "الملعقة",
    "سبون",
    "الملاعق",
    "البويغة",
    "بويغات",
    "البويغات",
    "بطهارة",
    "متفاوت",
    "سبراج",
    "الإسبرط",
    "البخاخ",
    "المرشات",
    "الموزعة",
    "الموزعات",
    "الإنتشارات",
    "الغصين",
    "ربيع",
    "المقفز",
    "المقافز",
    "إصطد",
    "الوثاب",
    "تضع",
    "حملها",
    "الوثابون",
    "سبرينجفيلد",
    "فصول",
    "سبرينجتيل",
    "ذروة",
    "موسم",
    "مواسم",
    "ري",
    "الرشاشات",
    "الشرذمة",
    "أسنان",
    "سبرول",
    "الكرنبات",
    "التهندم",
    "احصائي",
    "لعلم",
    "سبموني",
    "آيس",
    "سبيرج",
    "سبيرز",
    "سبوتنيك",
    "تفل",
    "متفل",
    "الباصق",
    "التتفيل",
    "يتفل",
    "التربيعي",
    "فراخ",
    "المربع",
    "سكواريسفيل",
    "السكواتش",
    "ممرود",
    "الساحق",
    "بطراوة",
    "ولكنه",
    "يتقرفص",
    "متقرفص",
    "المستحل",
    "التقرفص",
    "سكيووبش",
    "زاعق",
    "الشاكي",
    "الزعيق",
    "سكيووروت",
    "المحاربات",
    "التنس",
    "اصوات",
    "الصئيل",
    "العصرة",
    "معصور",
    "العصارات",
    "العصرات",
    "المسموعة",
    "المفحمة",
    "سكوبب",
    "الفرقعة",
    "متمايل",
    "يتمايل",
    "سكويل",
    "الحول",
    "الأحول",
    "بحول",
    "تلو",
    "يتلوى",
    "السناجيب",
    "المحقنة",
    "اسفنجية",
    "امتصاصا",
    "الاكثر",
    "اسفنجي",
    "وتعني",
    "جنوبجنوب",
    "ستابلي",
    "المتقطعة",
    "للتكويم",
    "المعبئ",
    "ستايسي",
    "الملاعب",
    "ستافرد",
    "ستافوردشاير",
    "بالمسرح",
    "المترنح",
    "التمايلات",
    "التمثيلات",
    "الأيول",
    "ستال",
    "برزانة",
    "يتسخ",
    "استراحة",
    "اعلى",
    "الحليمات",
    "اللحليمات",
    "السفلى",
    "بااخ",
    "ياخي",
    "ستالي",
    "ستالين",
    "البوخ",
    "ستاليني",
    "الملاحقون",
    "الملاحقة",
    "مخلوطا",
    "بالقش",
    "ستولينجز",
    "السداة",
    "ستامفورد",
    "سداة",
    "متلعثم",
    "المتهته",
    "الطابع",
    "فرارا",
    "الفارين",
    "الختامون",
    "ستان",
    "الحازم",
    "الدعامات",
    "الواقف",
    "المقامات",
    "الماسورة",
    "ستانفورد",
    "ستانلي",
    "بالصفيح",
    "ستانز",
    "ستانتن",
    "ستاف",
    "الكباسة",
    "الكباسات",
    "ستابلتون",
    "الميمنة",
    "ميمنات",
    "النشا",
    "منشى",
    "نشوي",
    "التنشية",
    "النجومية",
    "ستارجاز",
    "مراقبوا",
    "ستاركي",
    "النجيم",
    "النجيمات",
    "الزرزور",
    "الزرازير",
    "ستارأوفايس",
    "المبدئ",
    "يباغت",
    "المباغتة",
    "المتضور",
    "المتضورون",
    "منصوص",
    "وطن",
    "لدولة",
    "ستاتين",
    "الوﻻيةالمحافظة",
    "كرجل",
    "دولة",
    "عموم",
    "سكونيا",
    "خموليا",
    "القرطاسية",
    "منسب",
    "الإحصائيون",
    "الإحصائيات",
    "خبري",
    "ستاتلر",
    "مقاول",
    "فنادق.",
    "الموتور",
    "مكانة",
    "حراك",
    "تمثالي",
    "القوام",
    "شرعيا",
    "ستوفر",
    "وفي",
    "وفاء",
    "ستانتون",
    "الإقامات",
    "العاديان",
    "بصمود",
    "الصمود",
    "تثبت",
    "ثباتا",
    "يتثبت",
    "ستيلير",
    "الخفية",
    "البخاري",
    "متبخر",
    "البواخر",
    "بالبخار",
    "بخاري",
    "الحادلة",
    "الحادلات",
    "الأبخرة",
    "الإستارات",
    "ستيرنز",
    "الجياد",
    "ستيل",
    "فولاذي",
    "الفولاذية",
    "ستين",
    "إنحدر",
    "ستيبلبش",
    "ببرج",
    "دولاب",
    "إستيفان",
    "ستيجوسورس",
    "شتاين",
    "شتاينبيرج",
    "شتاينير",
    "ستيلا",
    "الستينسل",
    "مستخدما",
    "الستينسيل",
    "بالستينسيل",
    "الستينسلات",
    "ستنسل",
    "الاختزال",
    "المختزلون",
    "إختزالي",
    "مختزلة",
    "زوج",
    "أزواج",
    "ستيفاني",
    "ستيبهانوتيس",
    "ستيفن",
    "ستفينز",
    "ستيففنسن",
    "زوجات",
    "السائر",
    "لبدء",
    "ستيرادايان",
    "ستيريو",
    "المجسام",
    "المجسامات",
    "تجسيمي",
    "الاستريوسكوبية",
    "ثلاثية",
    "الجاهز",
    "نمطي",
    "معقم",
    "المعقمات",
    "الإستيرليني",
    "ستيرنبيرج",
    "صرامة",
    "بـ..",
    "شخيري",
    "بالسماعة",
    "ستيبين",
    "ستيف",
    "ستيفينس",
    "ستيفينسون",
    "ستيفي",
    "ستيوارت",
    "غرس",
    "اللاصقات",
    "أدبق",
    "ستيك",
    "تيت",
    "الميبس",
    "بتصنع",
    "وصمة",
    "وصمات",
    "الندبات",
    "تشوهي",
    "بوصمة",
    "عار",
    "الوصم",
    "موشوم",
    "الوشم",
    "خناجر",
    "زال",
    "بالولادة",
    "الأهدأ",
    "الأسكن",
    "ستيلووتر",
    "ستيلويل",
    "المطوال",
    "المطوالات",
    "ستيمسون",
    "منبه",
    "اللدغة",
    "اللسع",
    "لاسع",
    "الكريهون",
    "النتن",
    "السلاحف",
    "الممقوت",
    "ينتن",
    "ستينتر",
    "النقطي",
    "النقطية",
    "إشترط",
    "مشترط",
    "يشترط",
    "الإشتراط",
    "الإشتراطات",
    "ستيرلينج",
    "التخييط",
    "مراقبة",
    "إستمرار",
    "بالسهم",
    "إيصال",
    "سمسار",
    "البورصة",
    "سماسرة",
    "ستوكير",
    "ستوكيرس",
    "ستوكفيش",
    "إستوكهولم",
    "جثة",
    "جوارب",
    "الاحتياطي",
    "مخزون",
    "المكدس",
    "ستوكتون",
    "بحساب",
    "المتفاعلة",
    "الرواقية",
    "المتحملون",
    "أذك",
    "مذكى",
    "الوقاد",
    "الوقادون",
    "يذكي",
    "الإذكاء",
    "عباءة",
    "والبطن",
    "مداس",
    "الدوس",
    "السيدوم",
    "الحجار",
    "الحجارون",
    "محشش",
    "حجارة",
    "العميل",
    "العملاء",
    "بالمرور",
    "الصمامات",
    "إشارات",
    "أيقافه",
    "التوقيتية",
    "الخزن",
    "والمهاجم",
    "واجهات",
    "اللقلق",
    "اللقالق",
    "بسبب",
    "قصص",
    "القاء",
    "راوي",
    "رواة",
    "ستاوت",
    "تشجع",
    "ستو",
    "فحج",
    "مفحج",
    "المتواجد",
    "مكانين.",
    "يفحج",
    "التفحيج",
    "إقصف",
    "ته",
    "متيه",
    "يتيه",
    "التيه",
    "ستراهل",
    "مستقيم.",
    "كمنعم",
    "الأجعد",
    "استقامة",
    "الاستقامة",
    "المستقيمون",
    "الصرحاء",
    "الحشر",
    "الخانقة",
    "الخانقون",
    "الأقوياء",
    "ستراسبورغ",
    "إستراتيجي",
    "الإستراتيجيات",
    "الإستراتيجي",
    "الإستراتيجيون",
    "الإستراتيجية",
    "ستراتفورد",
    "التطبق",
    "المطابقات",
    "بطبقات",
    "ستراتون",
    "شتراوس",
    "سترافينسكاي",
    "سترو",
    "الشليك",
    "ستروفلور",
    "الشوارد",
    "مقلم",
    "ينساب",
    "الإنسياب",
    "للجدول",
    "سترينج",
    "سترينجذينير",
    "الستربتوميسين",
    "النقالات",
    "إنثر",
    "منثور",
    "ينثر",
    "شرخ",
    "شروخ",
    "قنوات",
    "يحزز",
    "التحزيز",
    "ستريكلاند",
    "ستردير",
    "كاسر",
    "الإضراب",
    "كاسرو",
    "تشريط",
    "الإخباري",
    "التوتير",
    "زيه",
    "الصبيان",
    "المتعرية",
    "المتعريات",
    "المتجردة",
    "ثيابها",
    "كافح",
    "مكافح",
    "المكافح",
    "يكافح",
    "الستروب",
    "ستروب",
    "الوماضة",
    "ممسد",
    "التمسيد",
    "متمشى",
    "التجوالات",
    "ستروم",
    "سترومبيرج",
    "السترونتيوم",
    "بالأناشيد",
    "يحد",
    "البنيوية",
    "المتعثر",
    "المتعثرون",
    "إعزف",
    "يعزف",
    "موتر",
    "متهادى",
    "المدعم",
    "التهادي",
    "الإستركنين",
    "العقب",
    "ستبليفيلد",
    "الأعقاب",
    "المشغولات",
    "الجبسية",
    "الترصيع",
    "ستوديبيكر",
    "الدارس",
    "الإستوديو",
    "الإستوديوهات",
    "محشو",
    "ستوفير",
    "رسمية",
    "الكتم",
    "التسفيه",
    "مسفه",
    "يسفه",
    "سفه",
    "متعثر",
    "القرمة",
    "تفرض",
    "متعكز",
    "الصعب",
    "الإكتظاظ",
    "وممتليء",
    "التعكز",
    "لسع",
    "منتون",
    "الخطرة",
    "الفيلم",
    "أغب",
    "الإغباء",
    "المتمثلة",
    "والحجم",
    "الهائل",
    "غباء",
    "الإنذهال",
    "ستربريدج",
    "ستيرجون",
    "سترم",
    "التعتعة",
    "متأتأ",
    "المتهتهة",
    "المتلكئ",
    "بالكلام",
    "المتهتهون",
    "المتلكؤون",
    "التأتأة",
    "متلجلجا",
    "كلامه",
    "شتوتغارد",
    "ستيفيسانت",
    "الاستعماري",
    "لهولندا",
    "معاينة",
    "أسلوبيا",
    "زاهد",
    "أعيق",
    "تقلصات",
    "الستايرين",
    "الستايروفوم",
    "ستيكس",
    "للمسائلة",
    "الملازم",
    "ثانوية",
    "فرعي",
    "الفرعية",
    "ميثاق",
    "الباطن",
    "المقاولة",
    "التعاقد",
    "ثقافة",
    "المخضع",
    "الإخضاع",
    "أقسام",
    "مجلدات",
    "ولكن",
    "إضافية",
    "الإلحاق",
    "شرطية",
    "المتصعد",
    "التسامي",
    "شعوري",
    "غواصة",
    "الغواصات",
    "للغمر",
    "مرؤسي",
    "الموصول",
    "إرش",
    "فقرة",
    "مؤامرة",
    "الحبكات",
    "إحضار",
    "تكميلية",
    "برهان",
    "سؤال",
    "روتين",
    "عينة",
    "عينات",
    "مخطوطة",
    "جملة",
    "العواقب",
    "التذللات",
    "التابعة",
    "الإعانات",
    "الداعمون",
    "النويعي",
    "اسمي",
    "الإسمية",
    "تعقيم",
    "للإحلال",
    "مستبدل",
    "إبدالي",
    "إستبدالي",
    "مشيد",
    "للإدراج",
    "الباطني",
    "الباطنيون",
    "يقابل",
    "إطرح",
    "مطروح",
    "الطوارح",
    "المطروحات",
    "لوحدة",
    "الضاحية",
    "تعمير",
    "ممدن",
    "التمدن",
    "المقوض",
    "النفق",
    "النجاحات",
    "بنجاح",
    "التعاقبات",
    "التتابع",
    "مغاث",
    "بالزبدة",
    "النضارة",
    "نضارة",
    "الماص",
    "ريان",
    "وما",
    "أشبه",
    "المصاص",
    "المصاصون",
    "المص",
    "مرضع",
    "يرضع",
    "المصات",
    "السكروز",
    "سود",
    "السودان",
    "السوداني",
    "سوداني",
    "المدبوغ",
    "سوديد",
    "المدبوغة",
    "رافع",
    "الشحوم",
    "السويس",
    "عان",
    "بضنك",
    "المعانون",
    "يعاني",
    "إكف",
    "مكفي",
    "الكافي",
    "يكفي",
    "اللواحق",
    "إختناقي",
    "سوفولك",
    "مساعدو",
    "التصويتات",
    "بحق",
    "اقتراع",
    "المطالبات",
    "إمتلأ",
    "يمتلئ",
    "سكري",
    "البونبون",
    "السكريات",
    "بالإيحاء",
    "إيحائي",
    "إيحائيا",
    "إنتحارا",
    "الإنتحار",
    "إنتحر",
    "سوكارنو",
    "سلفاوي",
    "السلفانيلاميد",
    "للدواجن",
    "كبرت",
    "مكبرت",
    "يكبرت",
    "الكبرتة",
    "الكبريتيد",
    "الكبريتوز",
    "السلفا",
    "بالكبريت",
    "كبريتي",
    "ورطب",
    "الجهنمية",
    "عبوس",
    "سولكيس",
    "سوليفان",
    "الكبريتات",
    "سولفونال",
    "السولفونامايد",
    "سلطان",
    "السلطنة",
    "السلطنات",
    "السلاطين",
    "سولسبيرغ",
    "السماق",
    "سومطرة",
    "سومر.",
    "السامرية",
    "سومر",
    "السومري",
    "جمعه",
    "وجيز",
    "سميرديل",
    "صيف",
    "صيفي",
    "مؤتمرات",
    "المستدعي",
    "سمنر",
    "حيوية.",
    "السومو",
    "القرارة",
    "خيول",
    "للنفقات",
    "سمتر",
    "مشمس",
    "شمس",
    "تشمس",
    "متشمس",
    "المتشمس",
    "المتشمسون",
    "يتشمس",
    "التشمس",
    "حرقة",
    "حروق",
    "مسفوع",
    "سنديو",
    "المغيب",
    "المتفرقات",
    "عباد",
    "بنور",
    "أشمس",
    "ساني",
    "فيل",
    "شروق",
    "الشروق",
    "الشموس",
    "حاجبة",
    "الغروب",
    "إنتصارية",
    "وافر",
    "قاعد",
    "يقاعد",
    "المقاعدة",
    "سوبيربنيس",
    "ضاغط",
    "يشحن",
    "وفوق",
    "الحواسيب",
    "موصلات",
    "انشطاري",
    "طاقة",
    "مقدرة",
    "إحرازها",
    "السوبرمان",
    "السوبرمانات",
    "طاف",
    "ماوراء",
    "الأعظم",
    "انفجارات",
    "نجمية",
    "الفائضون",
    "وضعه",
    "ودمجه",
    "يطابق",
    "ومن",
    "ثمة",
    "تطابق",
    "الشيئين",
    "العظمى",
    "علوي",
    "الفوقية",
    "المستبدل",
    "مجاميع",
    "بالخرافات",
    "بالبنية",
    "تصاحب",
    "الصلاحيات",
    "إتبع",
    "متبوع",
    "منبطح",
    "الإستلقاء",
    "إلى..",
    "المتوسلون",
    "التضرعات",
    "إعالته",
    "بتحمل",
    "افتراض",
    "التحاميل",
    "التحميلة",
    "القامعات",
    "للقمع",
    "الإخماد",
    "قمعية",
    "تقيح",
    "يتقيح",
    "التقيحات",
    "إنقطع",
    "ينقطع",
    "الأصم",
    "جرنيس",
    "الأمواج",
    "تقلل",
    "للسائل",
    "المذابة",
    "مواد",
    "الفرط",
    "الجراح",
    "الجراحون",
    "الجراحات",
    "جراحي",
    "جراحيا",
    "سورينام",
    "سيرجيكشن",
    "فضاضة",
    "مظنون",
    "تجاوزي",
    "الكهنوتي",
    "المفاجئات",
    "باندهاش",
    "سريالي",
    "السريالية",
    "السرياليون",
    "إستبدال",
    "المحيطة",
    "المراقبات",
    "المساح",
    "المساحون",
    "الإستطلاعات",
    "المكافحون",
    "الناجي",
    "الباق",
    "الباقون",
    "إحزر",
    "سوزان",
    "سوسإ",
    "السوتشي",
    "سوزي",
    "المشتبه",
    "بهم",
    "التوقيف",
    "وواقي",
    "الرياضات",
    "العنيفة",
    "إرتاب",
    "يرتاب",
    "للشك",
    "مريبة",
    "سسكويهانا",
    "السوسيكس",
    "الرزاق",
    "ساذرلاند",
    "ساتن",
    "الخياطات",
    "سواني",
    "المهيمنة",
    "سوزوكي",
    "سوابيا",
    "قمط",
    "مقمط",
    "يقمط",
    "التقميط",
    "المسروقة",
    "مختالا",
    "وتشكيلها",
    "سواهيلي",
    "العاشق",
    "القرويون",
    "العاشقون",
    "البلع",
    "سبح",
    "السوامي",
    "الوحولة",
    "البجعة",
    "إختيالا",
    "سوانسون",
    "التبديلالخلفية",
    "المرجة",
    "معجوج",
    "المتجمهر",
    "العج",
    "سوارثمور",
    "سوارثاوت",
    "الأسمر",
    "شوارتز",
    "ترشرش",
    "المتعنتر",
    "المتعنترون",
    "مترشرش",
    "يستعرض",
    "ذراعيه",
    "يترشرش",
    "الترشرش",
    "سواثر",
    "منشة",
    "مذبذب",
    "سواير",
    "الذبذبة",
    "يذبذب",
    "تعرقا",
    "بعرق",
    "سويتين",
    "السويدي",
    "السويد",
    "السويديون",
    "سويدي",
    "سويني",
    "الكناس",
    "الكناسون",
    "الكناسة",
    "رهان",
    "مراهنات",
    "بنكرياس",
    "بنكرياسات",
    "أحلى",
    "المحبوبون",
    "الحلاوة",
    "سويلت",
    "إحتر",
    "محتر",
    "الإحترار",
    "يحتر",
    "سوينسون",
    "كنس",
    "السمامة",
    "الفضلة",
    "السباحة",
    "يسبح",
    "كسوة",
    "كسوات",
    "سوينبرن",
    "عاهر",
    "العهرة",
    "إيقاعا",
    "التفافا",
    "التواءا",
    "الدوامات",
    "دوامي",
    "سويشر",
    "تصفيرا",
    "سويشي",
    "السويسري",
    "المتعرج",
    "التعرجات",
    "سويسرا",
    "التف",
    "بالإغماء",
    "بفقدان",
    "للوعي",
    "منقض",
    "السرتيل",
    "سابح",
    "المترف",
    "المترفون",
    "سيبيل",
    "الجميز",
    "بالتملق",
    "المتملقون",
    "سدني",
    "سيكيس",
    "مقطعية",
    "بمقاطع",
    "لفظ",
    "الرشيقة",
    "الرشيقات",
    "سيلفانيا",
    "سيلفيستر",
    "سيلفيا",
    "سيلفي",
    "المتكافل",
    "التكافل",
    "تعايشي",
    "تكافلية",
    "بعلامة",
    "الرمزيون",
    "سيمينجتون",
    "متعاطف",
    "بالتعاطف",
    "المتعاطف",
    "المتعاطفون",
    "يتعاطف",
    "متعاطفة",
    "سيمفوني",
    "تناغميا",
    "السمفونيات",
    "السمفونية",
    "الأعراض",
    "سين",
    "المعابد",
    "التزامني",
    "زامن",
    "يزامن",
    "المزامنة",
    "السنكروترون",
    "أدغم",
    "يدغم",
    "الغشيان",
    "نقابة",
    "المتلازمة",
    "المتلازمات",
    "سينج",
    "أيرلندي",
    "بمجلس",
    "المجامع",
    "الكنسية",
    "المرادف",
    "سينونيميكال",
    "مرادف",
    "المرادفات",
    "الترادف",
    "النحو",
    "موالفة",
    "موالفةباصات",
    "موالفةتأجيلات",
    "موالفةمؤثرات",
    "موالفةظروف",
    "موالفةمرشحات",
    "موالفةداخلي",
    "موالفةأمثلة",
    "موالفةتجارب",
    "موالفةهيئات",
    "المصطنعة",
    "سينثبوب",
    "ينغم",
    "بالزهري",
    "سيراكوس",
    "سوريا",
    "السوري",
    "السوريون",
    "الليلاك",
    "التنظيمات",
    "إنقباض",
    "الانقباض",
    "الاقتران",
    "سزيلارد",
    "جليل",
    "إمرأه",
    "أثداء",
    "ضخ",
    "ليرش",
    "الأسطح",
    "الجدران",
    "يتكسر",
    "العشرون",
    "شيرت",
    "التنتالوم",
    "الطبرد",
    "العوانس",
    "المنضدة",
    "شرشف",
    "المناضد",
    "ملعقة",
    "ملاعق",
    "الحرام",
    "دفوف",
    "مجدول",
    "يجدول",
    "منظموا",
    "تاكينا",
    "فحض",
    "الرسومي",
    "آﻻت",
    "للقلب",
    "تاسيتس",
    "الابتذال",
    "تاكو",
    "تاكوما",
    "التاكو",
    "لبق",
    "بلباقة",
    "اللباقة",
    "تكتيكي",
    "تعبويا",
    "التكتيك",
    "حاسة",
    "لمسيا",
    "غلام",
    "الشرغوف",
    "الشراغف",
    "التفتا",
    "الويلزيون",
    "الويلزي",
    "تافت",
    "تاهيتي",
    "تاهو",
    "التتبع",
    "بدنو",
    "التعقب",
    "التعقبات",
    "الذيول",
    "تايبيه",
    "تايوان",
    "التايواني",
    "الطاجيكي",
    "طاجيكستان",
    "الوارد",
    "مأخوذ",
    "الشرائ",
    "الآخذ",
    "الآخذون",
    "ناشروا",
    "تايلر",
    "تاليون",
    "التعويذات",
    "تالكين",
    "تالاديجا",
    "تالاهاسي",
    "تالاهاتشي",
    "تالتشيف",
    "الأطول",
    "تاليراند",
    "التلمود",
    "تلمودي",
    "مخالب",
    "صوفية",
    "للترويض",
    "تامال",
    "الطمراق",
    "تمور",
    "الدف",
    "الدفوف",
    "مروض",
    "التروض",
    "مروضون",
    "ألفة",
    "التاميل",
    "المتداولة",
    "سريلانكا",
    "تاماني",
    "إحش",
    "تامبا",
    "يحشو",
    "ظا",
    "التناجر",
    "تاناكا",
    "تاناناريف",
    "المزدوجة",
    "رنين",
    "تانجانيكا",
    "تماسي",
    "اليوسفي",
    "الملموسية",
    "طنجة",
    "التانجو",
    "تان",
    "الدبابة",
    "المشربة",
    "المشربات",
    "الدبابات",
    "تانينبوم",
    "المدابغ",
    "المدبغة",
    "دباغي",
    "التانين",
    "الدباغة",
    "السمرات",
    "تعذيب",
    "بعذاب",
    "التانتالوم",
    "نوبات",
    "تانيا",
    "تانزانيا",
    "الفضيلة",
    "الكونفوشية",
    "الطاوية",
    "طاوي",
    "تاوس",
    "تابا",
    "النقري",
    "الإستدقاق",
    "بالرسوم",
    "النجود",
    "التابير",
    "بساط",
    "مسترق",
    "مسترقوا",
    "عتلة",
    "عتلات",
    "القطران",
    "تارا",
    "الرتيلاء",
    "تاربل",
    "بطيئون",
    "الأبطأ",
    "مستهدف",
    "الإستهداف",
    "التعريفة",
    "لتعريفة",
    "الجمركية",
    "للتلوث",
    "التارو",
    "المقير",
    "المشمعة",
    "الطربون",
    "مقير",
    "يتلكأ",
    "التلكأ",
    "تاريتاون",
    "الترتان",
    "طرطاري",
    "طرطيري",
    "المرائي",
    "طرزان",
    "المتعسف",
    "تاسمانيا",
    "الأسيمبلي",
    "البرمجية",
    "الشرابة",
    "طعمه",
    "المتذوقون",
    "ألذ",
    "يخرم",
    "التتري",
    "التتر",
    "بطاطس",
    "البلاء",
    "تخريم",
    "الواشم",
    "الأوشام",
    "تو",
    "معنف",
    "التعنيف",
    "تعنيفي",
    "بانشداد",
    "الإنشداد",
    "حشو",
    "إدبغ",
    "تاوني",
    "سمارا",
    "المصفر",
    "المصفرة",
    "أصحر",
    "يدبغ",
    "المستحقة",
    "للدولة",
    "معفي",
    "الضريبي",
    "الضريبية",
    "تحنيطي",
    "بسيارة",
    "بسيارات",
    "التاكسي",
    "بإرهاق",
    "المدرجة",
    "دافعو",
    "تايلورز",
    "تشيكوفسكاي",
    "الارسال",
    "قدح",
    "أقداح",
    "الساج",
    "غلاية",
    "أخشاب",
    "فريقا",
    "أباريق",
    "الدمعة",
    "تيرابل",
    "الدموع",
    "باكي",
    "للدموع",
    "دامع",
    "التدميع",
    "المستدر",
    "المستدرون",
    "دموع",
    "بالدمع",
    "الشايات",
    "إنفش",
    "تيسينجس",
    "حلمة",
    "التكنيتيوم",
    "التقني",
    "التقنيون",
    "تكنيكولور",
    "التطبيقي",
    "الإسرائيلية",
    "التيكنو",
    "الفنيين",
    "تيد",
    "دمية",
    "مضجر",
    "الإضجار",
    "المحملة",
    "مراهقة",
    "المحملات",
    "تأرجح",
    "سنن",
    "عضاضة",
    "للأسنان",
    "ممتنعون",
    "الممتنعون",
    "تفلون",
    "بالتفلون",
    "تجيوسيغالبا",
    "طهران",
    "التكتيت",
    "تكترونكس",
    "الإذاعة",
    "الإذاعات",
    "فاكس",
    "تلفزيوني",
    "دروس",
    "تيليدين",
    "برقي",
    "التلغراف",
    "الذهني",
    "تلمان",
    "ورصد",
    "غائي",
    "الغائية",
    "عظمية",
    "تخاطري",
    "التخاطر",
    "مخابر",
    "هاتفي",
    "تليفوت",
    "تليفوتوغرافي",
    "البعدي",
    "المبرقة",
    "المبرقات",
    "الملقن",
    "تلسكوبي",
    "تلفزيون",
    "تلسكربت",
    "للإشارات",
    "مشاهدوا",
    "تلفز",
    "يتلفز",
    "التلفزة",
    "التلفزيونات",
    "مذيع",
    "بالتلفزيون",
    "مذيعون",
    "التلكس",
    "إخباره",
    "التلوريوم",
    "التلنت",
    "المعلقة",
    "بمزاجية",
    "تيمبيستووسنيس",
    "تمبلمان",
    "تيمبيلتن",
    "المؤقتات",
    "ماطل",
    "مماطل",
    "يماطل",
    "سرعات",
    "للإغراء",
    "المغوون",
    "الفاتنة",
    "الفاتنات",
    "المستأجرين",
    "التليين",
    "المتن",
    "المحلاق",
    "تينكس",
    "تينيسي",
    "تيني",
    "تينيسون",
    "يلسن",
    "بتوتر",
    "يتوتر",
    "توتري",
    "توتير",
    "الموتر",
    "التوترات",
    "الشادة",
    "اللامسة",
    "مجسات",
    "الكلابة",
    "عاشرا",
    "الأعشار",
    "التخييم",
    "الخيم",
    "مدة",
    "الحيازة",
    "تيكيلا",
    "بالتشوهات",
    "الجنينية",
    "عجائب",
    "التيربيوم",
    "الثالثة",
    "تيريزا",
    "ترمن",
    "للإنهاء",
    "الانهاء",
    "الطرفية",
    "طرفي",
    "إنتهائي",
    "المنهون",
    "إصطلاحي",
    "بمصطلح",
    "المصطلحات",
    "خطاف",
    "ترناري",
    "تيرا",
    "الشرفة",
    "متجاور",
    "الشرفات",
    "بشرفة",
    "التضاريس",
    "طرمايسين",
    "السلحفاة",
    "أرضيا",
    "أرضيون",
    "الوبرات",
    "أفزع",
    "إقليميا",
    "الإرهابي",
    "إرهابي",
    "الإرهابيون",
    "مرهبون",
    "الوبرة",
    "إقتضابا",
    "ثلثي",
    "تيس",
    "بالفسيفساء",
    "فسيفسائي",
    "تيسي",
    "للاختبار",
    "الموصي",
    "الموصون",
    "الموصية",
    "الفاحصات",
    "الخصيات",
    "خصيوي",
    "التزكية",
    "التزكيات",
    "التيستوستيرون",
    "درع",
    "الكزاز",
    "تيترسيكلين",
    "4",
    "الهالوجين",
    "ذوي",
    "توتوني",
    "تكس",
    "تيكساكو",
    "تكساسي",
    "تكساس",
    "توسعة",
    "لارتفاع",
    "المنسوج",
    "إقتباس نص",
    "نصي",
    "نصيا",
    "ث",
    "التايلانديون",
    "تايلند",
    "مهادي",
    "ثاليا",
    "الثاليوم",
    "المشرية",
    "التايمز",
    "إشكر",
    "شاكر",
    "متشكر",
    "شكرا",
    "التشكر",
    "الشكر",
    "للجميل",
    "نكران",
    "تسقيف",
    "ثاير",
    "ثيا",
    "إلف",
    "إلفو",
    "إلفة",
    "الالمسرح",
    "الثيبات",
    "أنت",
    "لهم",
    "إيماني",
    "ثيلما",
    "موضوعي",
    "أنفسهم",
    "الحين",
    "ثيوقراطي",
    "ثيودور",
    "ثيودوسي",
    "ثيودوسيوس",
    "ﻻهوتي",
    "اللاهوتية",
    "النظريات",
    "النظري",
    "نظريات",
    "النظريون",
    "التنظير",
    "تصوفي",
    "التصوف",
    "المتصوف",
    "التداوي",
    "هناك",
    "بذلك",
    "التالي",
    "تيريسا",
    "عقب",
    "الدافئ",
    "حراريا",
    "الثرميوني",
    "الثرميونيات",
    "الثرمستور",
    "الثرمستورات",
    "المزدوجات",
    "بالحرارة",
    "والضغط",
    "لوادن",
    "صلد",
    "بالثرموستات",
    "ثرموستاتي",
    "ثرموستاتيات",
    "منظمات",
    "قواميس",
    "المرادفة",
    "أطروحات",
    "ثيزيوس",
    "أثينا.",
    "الأطروحة",
    "مسرحيون",
    "ثيتا",
    "ثيتيس",
    "ال50",
    "الماء.",
    "الثيامين",
    "سميك",
    "إثخن",
    "ثخن",
    "مثخن",
    "مثخنات",
    "التثخن",
    "يثخن",
    "أثخن",
    "مؤجم",
    "السماكة",
    "السماكات",
    "ثينزفيل",
    "اللصوصية",
    "مفخذ",
    "الأفخاذ",
    "الكشتبان",
    "الكشتبانات",
    "ثيمبو",
    "ذين",
    "مجهولوا",
    "ذينيرس",
    "الثيونيك",
    "ثوراسل",
    "الثلث",
    "ثالثا",
    "الأثلاث",
    "عطش",
    "متعطش",
    "عطشا",
    "التعطش",
    "الثلاثينات",
    "ثلاثون",
    "زغب",
    "ذيسلي",
    "توماني",
    "توبمسن",
    "تومسن",
    "ارتدى",
    "سيور",
    "جلدية",
    "ثورو",
    "الثوريات",
    "الثوريوم",
    "ثورنبيرغ",
    "بمشاكل",
    "الإشاكة",
    "ثورنتن",
    "الأصيلون",
    "ثورب",
    "ثورستاين",
    "أولئك",
    "الآلاف",
    "بتهديد",
    "الثلاثات",
    "الثلاثية",
    "ثريونين",
    "إدرس",
    "ثلاث",
    "إقتصادا",
    "الاقتصاد",
    "التربسة",
    "حنجرة",
    "جلطات",
    "التخثرات",
    "تخثري",
    "جلطة",
    "ثرونبري",
    "اعترش",
    "العروش",
    "متحشد",
    "الإعتراش",
    "السمنات",
    "كافة",
    "أنحاء",
    "إرم",
    "الدج",
    "التهجمات",
    "الخميس",
    "الهدة",
    "الهدات",
    "الثليوم",
    "تقليب",
    "أظافر",
    "القلاووظ",
    "الإبهامي",
    "القلاويظ",
    "الإبهامية",
    "المسيمير",
    "بمسيمير",
    "إبهامي",
    "ضاربون",
    "الرعد",
    "الزوبعة",
    "الرعدية",
    "الصاعقة",
    "الصواعق",
    "الرعادة",
    "رعد",
    "راعد",
    "الرعدي",
    "الزوابع",
    "ثوربر",
    "ثرمان",
    "المجذفين",
    "خاصتك",
    "الزعتر",
    "ثايمين",
    "الصعترية",
    "الثايراترون",
    "الثايرستور",
    "ثايروجلوبولين",
    "بالغدة",
    "ثيرونين",
    "بالثيروتروبين",
    "ثيروتروبين",
    "الثيروتروبين",
    "نفسك",
    "تايبر",
    "التبت",
    "تيبتي",
    "قصبي",
    "تيبورون",
    "اللاإرادي",
    "مدقوق",
    "التذكرة",
    "بتذكرة",
    "التذاكر",
    "الدغدغة",
    "مدغدغ",
    "تيكونديروجا",
    "مدي",
    "أرتب",
    "ترتيبا",
    "تيدنج",
    "الربطة",
    "تيك",
    "تاينستن",
    "الروابط",
    "تيفاني",
    "نمري",
    "النمورة",
    "مشدد",
    "مغلول",
    "بإحكام",
    "النمرة",
    "النمرات",
    "تيجرينيا",
    "سامية",
    "منطوقة",
    "إريتريا",
    "دجلة",
    "تيجوانا",
    "سمسم",
    "التلدة",
    "مبلط",
    "مبلطون",
    "للفلاحة",
    "فلاحة",
    "مفلوح",
    "تليك",
    "تيلي",
    "الحرث",
    "تيم",
    "مشجرة",
    "مشاجر",
    "الشجري",
    "الشجرية",
    "طباع",
    "بالوقت",
    "موقوت",
    "اننضباطا",
    "انضباطا",
    "الانضباط",
    "الموقت",
    "زمن",
    "الموقتات",
    "موفر",
    "مستغلوا",
    "استغلال",
    "جدولة",
    "تيميكس",
    "تيمي",
    "تيمون",
    "الرعبة",
    "تيموثي",
    "طبال",
    "طبالون",
    "تيمس",
    "الصفيح",
    "تينا",
    "التينامو",
    "الصوفان",
    "القداح",
    "رقائق",
    "توخز",
    "متوخز",
    "يتوخز",
    "التوخز",
    "توخزي",
    "صغرا",
    "صفيحي",
    "تنسلتاون",
    "السمكري",
    "السمكريون",
    "صابغ",
    "تيوجا",
    "القلابة",
    "تبيكانو",
    "تبيراري",
    "القلابات",
    "اللفاع",
    "اللفاعات",
    "مبقشش",
    "البقشيش",
    "راشي",
    "الأنتشاء",
    "برفق",
    "تيرانا",
    "تيرينج",
    "لايتعب",
    "كلل",
    "الدأب",
    "العجلات",
    "ينسج",
    "التنسيج",
    "التيتان",
    "التيتانيت",
    "جبابرة",
    "العيار",
    "الحجمي",
    "العيارات",
    "الحجمية",
    "برتقالي",
    "يلاعب",
    "تيتو",
    "عاير",
    "معاير",
    "يعاير",
    "المعايرة",
    "المكتومة",
    "الضحكات",
    "فخريا",
    "تيتوس",
    "اهتياجات",
    "اهتياج",
    "ثالث",
    "التولوين",
    "متفجر",
    "العلجوم",
    "تودفلاكس",
    "التملقية",
    "المحمصة",
    "المحامص",
    "دفء",
    "نخب",
    "المأدبة",
    "دافء",
    "التبوغ",
    "توباغو",
    "متزلج",
    "المزلقات",
    "توبي",
    "التوكاتا",
    "ناقوس",
    "نواقيس",
    "تود",
    "تخط",
    "باصبع",
    "الانجليزية",
    "كلغة",
    "أجنبية",
    "التوفو",
    "تهندم",
    "التآزر",
    "توغو",
    "الشراك",
    "مجهودا",
    "توكاماك",
    "طوكيو",
    "تولاند",
    "توليدو",
    "الاحتمال",
    "الاحتمالية",
    "احتمالي",
    "لدفع",
    "الجزية",
    "محصلي",
    "تولي",
    "الجابي",
    "تولستوي",
    "تولوين",
    "توم",
    "توما",
    "التوماهوك",
    "الطماطة",
    "التمبك",
    "تومبجبي",
    "المسترجلة",
    "المسترجلات",
    "شاهدة",
    "المجلدات",
    "الصبياني",
    "حمقى",
    "توملينسون",
    "تومي",
    "غدا",
    "تومبكينس",
    "تومز",
    "النغمية",
    "تناسق",
    "منغم",
    "تونج",
    "التونجا",
    "الملاقط",
    "ملسن",
    "الألسنة",
    "باللسان",
    "توني",
    "تناغما",
    "الليلة",
    "يضاجع",
    "تونكس",
    "الأطنان",
    "لوزة",
    "اللوزتين",
    "لوزي",
    "إستئصال",
    "حلاقات",
    "تودل",
    "تولنج",
    "تولماك",
    "زمار",
    "المسواك",
    "يصفر",
    "تصفير",
    "تصافير",
    "أصبع",
    "إسكر",
    "ساكر",
    "توبيكا",
    "القنزعة",
    "القنزعات",
    "السواري",
    "الطوبوغرافي",
    "الطوبوغرافيون",
    "طوبوغرافي",
    "الطوبوغرافيا",
    "طوبولوجي",
    "اللاكمية",
    "أمة",
    "حاملوا",
    "المشاعل",
    "مصارعو",
    "نتوءات",
    "المعذب",
    "المعذبون",
    "حلقية",
    "تورنتو",
    "الطوربيد",
    "الطوربيدات",
    "الخدر",
    "بخدر",
    "للعنق",
    "توركيمادا",
    "لاوي",
    "لاوون",
    "أطواق",
    "تورانس",
    "تورنس",
    "إتقاد",
    "إلتوائي",
    "تورتين",
    "الجنح",
    "توروسيس",
    "توسكا",
    "توسكانيني",
    "توشيبا",
    "قذفات",
    "مسالة",
    "الواحد",
    "المجموعيات",
    "الإجمال",
    "مجموعه",
    "المراهنات",
    "الطوطم",
    "الطواطم",
    "كحمال",
    "توتو",
    "لمسا",
    "الملامس",
    "المحك",
    "التجلد",
    "القساة",
    "تولوز",
    "السياحة",
    "السياح",
    "التشعيث",
    "المرابح",
    "المرابحون",
    "واعد",
    "بالخير",
    "المنشفة",
    "بالفوطة",
    "المناشف",
    "بان",
    "التوهي",
    "عصافير",
    "تاونلي",
    "البلدات",
    "تاونسند",
    "قاطن",
    "قاطنو",
    "المتبع",
    "المحاذي",
    "لجر",
    "المتبعة",
    "تاوسلي",
    "بالسم",
    "السمية",
    "توينبي",
    "تويوتا",
    "لإقتفاء",
    "دالة",
    "الراسم",
    "الراسمات",
    "التراخوما",
    "الإقتفاء",
    "إقتفاء",
    "أثره",
    "المقتفي",
    "المقتفون",
    "الإستطراق",
    "الجرارات",
    "تريسي",
    "النقابية",
    "المبادلات",
    "التقليدي",
    "التقليديون",
    "الناجم",
    "إتهامات",
    "زائفة",
    "تراج",
    "التراجيدي",
    "التراجيديون",
    "التراجيدية",
    "مأسوي",
    "تراجيدي",
    "تراجيدية",
    "كوميدية",
    "رائدا",
    "المقطورة",
    "المقطورات",
    "للقافلة",
    "للتدريب",
    "تمش",
    "يتمشى",
    "الخائنة",
    "الخائنات",
    "الترام",
    "المنتظمة",
    "المهشم",
    "الترامبولين",
    "أتمم",
    "متم",
    "قادت",
    "بجبال",
    "ترانسامينيس",
    "الأنزيمات",
    "والاستقبال",
    "متعال",
    "متسام",
    "المتعالية",
    "بالفلسفة",
    "فيرت",
    "لإشارات",
    "وفرق",
    "الناسخات",
    "الإنتقالات",
    "تبدل",
    "المحدود",
    "نقله",
    "إنتهك",
    "ينتهك",
    "المتجاوزون",
    "العابرو",
    "الترنزستور",
    "بترانزستور",
    "الترنزستورات",
    "إنتقالي",
    "متعدي",
    "للترجمة",
    "بالحركة",
    "نقلي",
    "تكريم",
    "للمترجمين",
    "صوتيا",
    "للإنتقال",
    "المرسلات",
    "القبيحة",
    "تحولية",
    "الشفافيات",
    "إرشح",
    "مرشوح",
    "الرشح",
    "للزرع",
    "الزروعات",
    "إبدال",
    "المتخنث",
    "المتخنثون",
    "ترانسهيب",
    "بالعرض",
    "التشبه",
    "ترانسيلفانيا",
    "المنحرفة",
    "البهارج",
    "الموسخ",
    "العناء",
    "معانى",
    "الشاقة",
    "ترافرز",
    "عبره",
    "عبر..",
    "العابر",
    "ترسبات",
    "والأنهار",
    "التحريفات",
    "ترافس",
    "شباك",
    "معسول",
    "لامس",
    "بقدمه",
    "تريدويل",
    "خياني",
    "خائنة",
    "الإدخار",
    "المعاهدات",
    "إرتجف",
    "المرتجف",
    "يرتجف",
    "الارتعاش",
    "الأكول",
    "الأكولون",
    "مسايرة",
    "الموضة",
    "ترينتن",
    "الهلع",
    "الخطيئة",
    "المتجاوزن",
    "الخصلات",
    "تريفيليان",
    "ثلاثي",
    "والخطأ",
    "للإلتهابات",
    "المثلثات",
    "مثلثي",
    "ثلث",
    "مثلث",
    "وتحديد",
    "ترايانجلم",
    "القبلية",
    "القبيلة",
    "القبائل",
    "يمتحن",
    "منبر",
    "الروافد",
    "الرافد",
    "يثني",
    "ثلاثيو",
    "ترايسيراتوبس",
    "تريكينيلا",
    "خيطية",
    "طفيلية",
    "تريكينوسيس",
    "مطهو",
    "التريكلوروسيتيك",
    "تريكلورويثان",
    "الثلاثي",
    "تريكير",
    "أصعب",
    "الوشل",
    "متقطر",
    "يتقطر",
    "تردياجونال",
    "كعكات",
    "الزناد",
    "ثلاثيا",
    "التسجيع",
    "تريليون",
    "تريليونات",
    "تريلوبيت",
    "الثلاثيات",
    "تريمبل",
    "المتقلب",
    "تهذيبا",
    "ترنيداد",
    "بالثالوث",
    "الثالوثية",
    "نتريت",
    "الثلاثيون",
    "تريب",
    "البيريداين",
    "فوسفور",
    "يتضاعف",
    "توأم",
    "تريبليت",
    "يثلث",
    "التثليث",
    "تضاعف",
    "الحاملات",
    "طرابلس",
    "ترايبوليفوسفيت",
    "الكامة",
    "الاحتواء",
    "صوديوم",
    "تريستان",
    "التريتيوم",
    "التريتون",
    "الإنتصار",
    "منتصر",
    "بانتصار",
    "الإنتصارات",
    "الثلاثة",
    "حكومات",
    "ثالوثي",
    "التوافه",
    "مسلي",
    "ديس",
    "للمصابيح",
    "بإنسان",
    "الزحافة",
    "الزحافات",
    "طروادة",
    "بالشاحنة",
    "شاحنات",
    "التصيد",
    "الباغية",
    "الباغيات",
    "شاحنة",
    "الترومبون",
    "الإستعارة",
    "بالجوائز",
    "المؤثرات",
    "الجزيئي",
    "لألياف",
    "كولاجين",
    "للغلاف",
    "تروتسكاي",
    "الكوارع",
    "مثيرو",
    "حلالو",
    "المنخفضات",
    "العروس",
    "ملبوسات",
    "التراوت",
    "تروتمان",
    "الدفين",
    "إملج",
    "مملوج",
    "الجارف",
    "بالجاروف",
    "الملج",
    "يملج",
    "تروي",
    "متغيب",
    "الهدنات",
    "يتهادن",
    "نفقة",
    "مقايض",
    "تروكي",
    "بالشاحنات",
    "ترودو",
    "ممشي",
    "المنهك",
    "ترودي",
    "تروس",
    "التروتايب",
    "الكمأ",
    "تريجيلو",
    "ترومان",
    "ترمبل",
    "بلعب",
    "فوز",
    "التدحرج",
    "مدحرج",
    "المدحرج",
    "يدحرج",
    "بجذع",
    "مجراه",
    "مرتكز",
    "أوصي",
    "وصاية",
    "وصي",
    "بوفاء",
    "وثوق",
    "بأهلية",
    "للثقة",
    "بالثقة",
    "التربسين",
    "القيصري",
    "القياصرة",
    "التسونجا",
    "تسنيماتسو",
    "التسوانية",
    "أتكلم",
    "معك",
    "الثلاثاء",
    "تيوتارا",
    "توبيد",
    "الدرنة",
    "الدرنات",
    "درني",
    "السلين",
    "السل",
    "مسلول",
    "تيوبروز",
    "مكسيكية",
    "الثنية",
    "الصدرية",
    "الثنيات",
    "توسون",
    "تيودور",
    "المسامي",
    "الخصل",
    "تافتس",
    "مصاريف",
    "تولان",
    "التوليب",
    "تولسا",
    "السقطة",
    "التورم",
    "وارم",
    "بتورم",
    "ورمي",
    "تقسيمه",
    "وتضبيطه",
    "والتضبيط",
    "التونة",
    "التندرا",
    "بنغم",
    "العذوبة",
    "مقسمو",
    "المضبطون",
    "تونغ",
    "صينية",
    "تنجستيت",
    "التنجستيك",
    "التنجستن",
    "تونس",
    "التونسي",
    "نفق",
    "الأنفاق",
    "توبيلو",
    "العمامة",
    "العمائم",
    "العكرة",
    "التعكر",
    "المقاوب",
    "القرائن",
    "التوربين",
    "التوربينات",
    "نفاث",
    "التربيني",
    "المروحي",
    "تربيني",
    "التربينية",
    "المطب",
    "السباقات",
    "تورين",
    "تورينج",
    "التركي",
    "تركيا",
    "الرومي",
    "تركي",
    "توركمنستان",
    "الأتراك",
    "الكركم",
    "تيرنابل",
    "وشد",
    "الإنتهازي",
    "المنعطفات",
    "نافر",
    "الإقبال",
    "الدوارة",
    "تيرنستون",
    "التربنتين",
    "الدناءة",
    "السفالات",
    "الفيروز",
    "الحدباء",
    "المدورة",
    "توسكالوسا",
    "جامعية",
    "توسكان",
    "تسكانيا",
    "توسكارورا",
    "توسكيجي",
    "التنازع",
    "الإمتعاض",
    "عنخ",
    "آمون",
    "تاتل",
    "تنورات",
    "قصيرات",
    "توفالو",
    "تكسيدو",
    "مضخم",
    "ترانس",
    "وورلد",
    "توادلر",
    "تواين",
    "الرنات",
    "التويد",
    "ينتف",
    "نتف",
    "النتاف",
    "النتف",
    "الملقط",
    "ثانيات",
    "إثناعشر",
    "العشرينات",
    "العشرين",
    "عشرون",
    "عشرين",
    "توي",
    "الشفق",
    "البرمة",
    "الملتف",
    "المتلازم",
    "البرم",
    "موخوز",
    "التوأمة",
    "متلألأ",
    "المتلألىء",
    "التلألئ",
    "توأمين",
    "بحركة",
    "وفرح",
    "اللية",
    "للف",
    "الإختلاجة",
    "تويت",
    "التغاريد",
    "إثنان",
    "شرطين",
    "صميمين",
    "رقمين",
    "مستو",
    "حزبين",
    "مرحلتين",
    "مينائين",
    "وجهين",
    "دولتين",
    "خطوتين",
    "ضربتين",
    "نغمتين",
    "المقيمين",
    "ضعفين",
    "توومبلي",
    "الإثنينات",
    "مقعدين",
    "تايم",
    "وورنر",
    "تيبرن",
    "للإعدام",
    "الوضيع",
    "الحقراء",
    "تيلر",
    "طبلاني",
    "تيندول",
    "عرضها",
    "للطباعة",
    "التيفوئيد",
    "التيفويد",
    "تيفون",
    "التيفوس",
    "مثال",
    "المماثلة",
    "كاتبو",
    "الطباعون",
    "رمزيا",
    "طغياني",
    "مضطهدة",
    "المستبدون",
    "بمقاطعة",
    "تيرول",
    "التيروسين",
    "تايسن",
    "التكسيدو",
    "الرجالية",
    "مضغة",
    "للخلف",
    "المرسلالمستقبل",
    "العالمي",
    "اللاتزامني",
    "أكاري",
    "القردة",
    "التواجد",
    "يتواجد",
    "بتواجد",
    "أنجليس",
    "بيركلي",
    "يوكون",
    "يودال",
    "الضرع",
    "ضرع",
    "ضروع",
    "حليب",
    "الضروع",
    "يوديل",
    "فنلندية",
    "يوفا",
    "أوغندا",
    "الإجاراتية",
    "اشمئزاز",
    "الأجلي",
    "أقبح",
    "قبيحا",
    "القبح",
    "أهلاند",
    "مرسوم",
    "قيثارة",
    "يوكيا",
    "أوكرانيا",
    "الأوكراني",
    "الأوكرانيون",
    "اوكرانيا",
    "الأكلال",
    "قيثارات",
    "متقرح",
    "يتقرح",
    "تقرحي",
    "يولين",
    "النقوصات",
    "ألمان",
    "أولمان",
    "الزند",
    "للندب",
    "معطف",
    "ارلندي",
    "معاطف",
    "ارلندية",
    "للشهر",
    "بالثأر",
    "إبتدائية",
    "بنفسجية",
    "نابذة",
    "اللازود",
    "المتعصبة",
    "الصوتي",
    "صوتيات",
    "السوناري",
    "الألتريكس",
    "النابح",
    "العاوي",
    "أوليسيس",
    "خيمي",
    "عنقود",
    "اللبيس",
    "مغمق",
    "يغمق",
    "سرر",
    "سرة",
    "ظليل",
    "الاستياء",
    "ظلال",
    "الأمبرين",
    "الضل",
    "يحصى",
    "لكثير",
    "خجل",
    "مقبولية",
    "مرحب",
    "لأسباب",
    "مجهولة",
    "متعذرة",
    "لاينصح",
    "أكسجين",
    "تأثر",
    "اللاتأثر",
    "أناكا",
    "يقظ",
    "تغرب",
    "فتنة",
    "الإجماعية",
    "لكذا",
    "إيفاء",
    "ممات",
    "مرساة",
    "غضبان",
    "بالإجماع",
    "لاجواب",
    "عداء",
    "لتقديم",
    "إعتذار",
    "للاعتذار",
    "للاستئناف",
    "للإسترضاء",
    "تقاربية",
    "استحالة",
    "اللامناسبة",
    "اللاجاذبية",
    "أناو",
    "الكسلان",
    "ارفض",
    "توفر",
    "اللاجدوى",
    "اللامفر",
    "للتجنب",
    "تكييس",
    "أخلل",
    "بتوازن",
    "اللاتوازن",
    "إخلال",
    "أنبانس",
    "قيمته",
    "مخفوف",
    "لجام",
    "لايهزم",
    "ﻻ",
    "ﻻئق",
    "ينهض",
    "المتشكك",
    "المتشككون",
    "بشك",
    "ينحاز",
    "اللاتحيز",
    "المحروم",
    "معمى",
    "لايرف",
    "تبجحا",
    "شكلاه",
    "الجسد",
    "حاسر",
    "اللاحدود",
    "يعتدل",
    "لايقهر",
    "محتوياتها",
    "للشم",
    "مفكوك",
    "متزحزح",
    "مخرج",
    "مغاير",
    "لأصول",
    "مقدرتقديرا",
    "للطبيعة",
    "متواصل",
    "أنسيد",
    "اللارسمية",
    "للمنازلة",
    "تحدي",
    "اللاتسامحية",
    "همجي",
    "وضوح",
    "مرخى",
    "يرخي",
    "الأعمام",
    "يزل",
    "تهيأ",
    "متهيأ",
    "يتهيأ",
    "تحصيله",
    "للتمشيط",
    "بتضايق",
    "إعتيادي",
    "بصمت",
    "مشتكي",
    "مساومة",
    "اللامباﻻة",
    "شرط",
    "إلتزام",
    "بالأمر",
    "بالأمور",
    "متفاني",
    "معقولية",
    "دستورية",
    "أنكونتابل",
    "إعتراض",
    "تقليدية",
    "اللاإقناع",
    "وثاق",
    "معضد",
    "بالقرابة",
    "عبور",
    "اخلع",
    "مرهم",
    "سدل",
    "مسدل",
    "ديموقراطي",
    "لاينكر",
    "دهان",
    "القاصر",
    "يساند",
    "النامية",
    "عجلات",
    "اللامتخرج",
    "التحتانية",
    "تهاون",
    "المستضعف",
    "المستضعفون",
    "لصرف",
    "أسيء",
    "مهاراته",
    "تغذية",
    "إنقاص",
    "الداعمة",
    "تحتي",
    "أثواب",
    "تحتية",
    "معنويا",
    "الجامعيون",
    "تحأرضي",
    "يخفى",
    "بخفية",
    "أندرهيل",
    "متدني",
    "موقوع",
    "يؤجر",
    "بالباطن",
    "بثمن",
    "بخس",
    "تحته",
    "قوض",
    "مقوض",
    "يقوض",
    "التقويض",
    "مرءوس",
    "يتحفظ",
    "الحبكة",
    "إعلانه",
    "قله",
    "تمثيله",
    "وكلاء",
    "الوزارات",
    "إبخس",
    "يبخس",
    "نائب",
    "العمدة",
    "مقصرا",
    "بإقصار",
    "المصروف",
    "كرافعة",
    "السرداب",
    "إغمطه",
    "مغموط",
    "الإستهانة",
    "الإستهانات",
    "يغمطه",
    "الغمط",
    "بدﻻ",
    "التعهدات",
    "البخس",
    "الاستحباب",
    "المكروهون",
    "مستحب",
    "تفاصيل",
    "التنحية",
    "الإستغناء",
    "يقينية",
    "فاعل",
    "داعي",
    "تموج",
    "يتموج",
    "التموجات",
    "بتموج",
    "اللاإلتزام",
    "لهفة",
    "سماوية",
    "إنشاء",
    "مستحي",
    "إستخدامية",
    "يخيص",
    "استنتاج",
    "لاينتهي",
    "الإطاقة",
    "تنوير",
    "انتفاء",
    "لايحسد",
    "يجارى",
    "بلانلد",
    "صائب",
    "مفر",
    "للعلوم",
    "والتربية",
    "والثقافة",
    "محالة",
    "تحسينه",
    "لبعد",
    "الفجائية",
    "زهيد",
    "تنم",
    "يخبأ",
    "يعيض",
    "إيمان",
    "تاعب",
    "أنثوي",
    "مختمر",
    "بالقانون",
    "مليئ",
    "لايكل",
    "الاستثارة",
    "استثارته",
    "إغراء",
    "إغرائي",
    "تفتج",
    "يتفتح",
    "فولو",
    "يالتحلل",
    "إلالأجز",
    "ءيلتمير",
    "نيلتدمير",
    "لسوء",
    "التعساء",
    "تآخي",
    "بالصديق",
    "اللاودية",
    "مؤثث",
    "الغلاظة",
    "شحيح",
    "إعطاء",
    "أنجلوفيد",
    "بعدما",
    "إنغلق",
    "بالصمغ",
    "الإنقياد",
    "لطافة",
    "اللاقواعدية",
    "مكره",
    "بالمسمار",
    "يتركه",
    "للتخمين",
    "والمخالب",
    "متكون",
    "للدهن",
    "الظفر",
    "الظلف",
    "والماعز",
    "ظلف",
    "أنيلهيكسيوم",
    "الطقم",
    "البلية",
    "مشفي",
    "بالصحة",
    "السماع",
    "بالأذن",
    "انتباه",
    "لاتأريخي",
    "إثما",
    "صهوة",
    "متأني",
    "اليونيسيف",
    "للأمم",
    "مسئولة",
    "فريدد",
    "الدولي",
    "وحيدات",
    "ينيكس",
    "أفكار",
    "الاتساق",
    "تصوره",
    "بلاشك",
    "متأئر",
    "مستلهم",
    "إهتمام",
    "لايتدخل",
    "شؤون",
    "النقابي",
    "يلد",
    "أحاديات",
    "إستثنائيا",
    "التفردية",
    "الإنفراد",
    "للجنسين",
    "الإئتلافات",
    "يونيسس",
    "ينيتال",
    "التوحيدية",
    "موحدون",
    "وحدوي",
    "إتحد",
    "باتحاد",
    "توحدي",
    "يونيفاك",
    "المتغير",
    "للعالمية",
    "بالعالمية",
    "الأكوان",
    "اليونيكس",
    "تشويش",
    "غري",
    ",",
    "مبهذل",
    "المجهول",
    "اتفاق",
    "مستوفى",
    "اللاأدب",
    "الإطاحة",
    "الخال",
    "عارف",
    "المجهولون",
    "غرامة",
    "مخالفة",
    "اللاشرعية",
    "عنان",
    "لايتمتع",
    "بشخصية",
    "قيادية",
    "مالم",
    "خلاف",
    "المستبعد",
    "ملدن",
    "التلدين",
    "يقسي",
    "بالحسبان",
    "الكراهة",
    "يغتصب",
    "الساخطة",
    "اللاأخلاقية",
    "إنزال",
    "المستور",
    "أعزب",
    "أعازب",
    "للتحكم",
    "إدارته",
    "ممنوعة",
    "اللارحمة",
    "شاده",
    "الدخيلة",
    "التلطيف",
    "يقيل",
    "تنغيم",
    "خافض",
    "عيوب",
    "القناع",
    "أعصاب",
    "تتراجع",
    "كونها",
    "راهبة",
    "أونو",
    "خدوم",
    "الإحتشام",
    "هجومي",
    "معارضة",
    "للصبغ",
    "الاستساغة",
    "مستساغ",
    "خلاله",
    "للوطنية",
    "سلطوي",
    "وبلا",
    "مهلة",
    "سراحه",
    "للإداء",
    "الجأش",
    "أصباغ",
    "إستخطاء",
    "مرير",
    "القابس",
    "اللاشعبية",
    "لحوح",
    "يتلق",
    "يطيح",
    "مثيل",
    "الانطباع",
    "الحيادي",
    "للتقديم",
    "اللامبدئية",
    "باهض",
    "ربح",
    "أرباح",
    "فوائد",
    "الإرباح",
    "مرجو",
    "إستفزازي",
    "تحفيز",
    "تحرش",
    "عقاب",
    "لاشك",
    "اختم",
    "كلاما",
    "مقتبسا",
    "خميرة",
    "تكشف",
    "متكشف",
    "التكشف",
    "يتكشف",
    "مقروئية",
    "الجهوزية",
    "واقعية",
    "الوهمية",
    "الجهالة",
    "تقبلي",
    "بالجميل",
    "إعتراف",
    "تسوية",
    "لافائدة",
    "ترجى",
    "زنديق",
    "تشكي",
    "إرجائه",
    "تأجيله",
    "للتكرار",
    "لايقاوم",
    "حله",
    "إيجاد",
    "عالق",
    "إزدراء",
    "إستهتار",
    "لايستحق",
    "هامل",
    "التجاوب",
    "إرتياح",
    "التقيد",
    "ليست",
    "لايحفظ",
    "تعويضه",
    "إرجاعه",
    "التوقير",
    "ينم",
    "لانظير",
    "المطوي",
    "رومانسية",
    "مسقوف",
    "يتورع",
    "لايحرم",
    "المستحيل",
    "بيعه",
    "بمذاق",
    "لأنه",
    "منذور",
    "لله",
    "اذن",
    "مصنفر",
    "منعم",
    "استياء",
    "سخط",
    "تبرم",
    "مرضي",
    "تشبع",
    "بلاطعم",
    "إسحب",
    "لاعلمي",
    "مدرز",
    "أوانه",
    "متزعزع",
    "يسلط",
    "أنانية",
    "بلادة",
    "مخصى",
    "خصوبة",
    "الكلبشات",
    "حليق",
    "سقيفة",
    "حفى",
    "لايتقلص",
    "الخطايا",
    "نقيا",
    "للإغراق",
    "برداءة",
    "نهاش",
    "مؤانسة",
    "ملتصقين",
    "عاثر",
    "مبذورة",
    "مزروعة",
    "بلازرع",
    "يصح",
    "ذكره",
    "ضاربا",
    "الحائط",
    "بالقوانين",
    "والمثل",
    "عصرة",
    "تحديق",
    "قاعدتها",
    "جواد",
    "إيقافه",
    "مجزئه",
    "كريهة",
    "ملمة",
    "بإخفاق",
    "العوز",
    "كاف",
    "تشوبه",
    "شائبة",
    "اشتباه",
    "للقهر",
    "تجاوزه",
    "بطأ",
    "دعمه",
    "للبلع",
    "متسق",
    "نظامي",
    "أنتامابل",
    "معتنى",
    "بحبل",
    "جاحد",
    "المعروف",
    "الامتنان",
    "يذب",
    "وارد",
    "الغفل",
    "مغفول",
    "غفل",
    "لأوانه",
    "ارهاقه",
    "المحصنون",
    "للسفر",
    "داس",
    "مستبعد",
    "بكذب",
    "لايثق",
    "الكذبة",
    "مطويا",
    "كالسجادة",
    "تنشز",
    "دارس",
    "المتشابك",
    "المتحابك",
    "انحل",
    "انفك",
    "يسلك",
    "النقاب",
    "يميط",
    "اللثام",
    "يعادله",
    "تزويق",
    "منفس",
    "مفعل",
    "ملم",
    "مزار",
    "بتولي",
    "بحت",
    "طاهر",
    "مأجور",
    "دفىء",
    "مدفأ",
    "مفطوم",
    "لازال",
    "لايصلح",
    "للإرتداء",
    "متزوجة",
    "المأخذ",
    "مرثي",
    "مخفوق",
    "مراسا",
    "الأصعب",
    "واردة",
    "برعونة",
    "ذابل",
    "نباتية",
    "مشاكل",
    "هموم",
    "محاك",
    "لتحقيق",
    "وأدنى",
    "سيحصل",
    "آت",
    "بأخرى",
    "وحديثة",
    "يحشد",
    "يتعاقد",
    "حشد",
    "يسمو",
    "لذوي",
    "سلعة",
    "باهضة",
    "اصول",
    "متواضعة",
    "للأعلى",
    "مستبشر",
    "توبيخي",
    "طريقه",
    "رئتيه",
    "يحتضن",
    "يربي",
    "تهوية",
    "وتنقية",
    "تقيؤ",
    "(الملف)",
    "يداور",
    "التجديدات",
    "يتدفق",
    "اﻷعلى",
    "لصالح",
    "مرقى",
    "يترعرع",
    "بسيف",
    "بخنجر",
    "مؤيدون",
    "نجد",
    "منجد",
    "المنجدون",
    "التنجيد",
    "ينجد",
    "النجادة",
    "النجد",
    "متحظر",
    "يستند",
    "يقفل",
    "ومالية",
    "حروفا",
    "طلاب",
    "موجهة",
    "صعودا",
    "يكوم",
    "الخيلاء",
    "إنهض",
    "(السجادة)",
    "يستيقض",
    "يستحث",
    "أبسالا",
    "التكدر",
    "يرمي",
    "إبسلون",
    "يحلق",
    "وثبة",
    "قف",
    "جلي",
    "حديثو",
    "وﻻية",
    "يؤازر",
    "تمسيد",
    "الإنظمام",
    "يأرجح",
    "يوثق",
    "القرية",
    "يدرب",
    "والواقعة",
    "يوراسيل",
    "إنحباس",
    "إنسداد",
    "بانسداد",
    "تزين",
    "والدال",
    "أورال",
    "الممتدة",
    "اوروبا",
    "واسيا",
    "للبول",
    "يورانيا",
    "بالكوكب",
    "باﻷجرام",
    "باليورانيم",
    "اليورانيوم",
    "للنجوم",
    "والمجرات",
    "وباقي",
    "الفيزيائي",
    "المختصة",
    "واﻷجرام",
    "توضح",
    "متابعة",
    "وملاحظة",
    "التهابات",
    "مؤلمة",
    "إبهام",
    "والقدم",
    "أوربانا",
    "العمران",
    "كمثرى",
    "وطيبة",
    "المديني",
    "المدينيون",
    "الدماثة",
    "أوربانك",
    "لغسل",
    "وفقير",
    "وعابث",
    "والقدر",
    "الأردو",
    "وباكستان",
    "وتكتب",
    "يوريا",
    "اليوريا",
    "تبولن",
    "أسيد",
    "إريثان",
    "الإحليل",
    "الأحاليل",
    "إحليلي",
    "للرؤية",
    "لمجرى",
    "بالبول",
    "الإضطرار",
    "عاجلة",
    "إلحاح",
    "إلحاحات",
    "وري",
    "الملتحية",
    "المبولة",
    "المبولات",
    "تحاليل",
    "لتدفق",
    "بالتبول",
    "بالأعضاء",
    "البولية",
    "البرمائيات",
    "بالبرمائيات",
    "تناسلي",
    "والمسالك",
    "بالقمار",
    "أورسا",
    "دبي",
    "أورسولا",
    "أورسلينية",
    "إلهة",
    "أورغواي",
    "أوروغواي",
    "ومنقرض",
    "نحن",
    "الاستخدام",
    "للاستخدام",
    "الأميركي",
    "المؤتمن",
    "الإستعمالات",
    "العرفية",
    "الكمبيالة",
    "الجيوديكي",
    "والساحلي",
    "اليوزنت",
    "بمستعمل",
    "المستعملون",
    "مستخدمونمهاممتغييرات",
    "يونيكس",
    "الأبحاث",
    "الجيولوجية",
    "المرشدة",
    "هادي",
    "الأسك",
    "الاعتياد",
    "أوسومبورا",
    "المرابون",
    "مراباة",
    "رباوي",
    "الربوية",
    "إغتصب",
    "اغتصابي",
    "الربا",
    "يوتاه",
    "يوتا",
    "رحمي",
    "(بغرض",
    "الولادة)",
    "المهبل",
    "والرحم",
    "الأرحام",
    "العالميه",
    "لتحوير",
    "يوتكا",
    "النفعي",
    "النفعية",
    "منفعة",
    "إستعماله",
    "ورائه",
    "متنفع",
    "اليوطوبيا",
    "اليوطوبي",
    "اليوطوبية",
    "اليوطوبيون",
    "تفائلي",
    "ملتفة",
    "لإلغاء",
    "يوتركت",
    "أوتريللو",
    "إشتهر",
    "برسمه",
    "للشوارع",
    "الباريسية",
    "ألعظمة",
    "والبريد",
    "الالكتروني",
    "ASCII",
    "Binary",
    "يجميع",
    "البنفسجية",
    "لهاة",
    "قرينة",
    "بالزوجة",
    "بالقرينة",
    "زوجته",
    "يقتل",
    "بزوجته",
    "للزوجة",
    "لجمهورية",
    "أوزبيكيستان",
    "أوزبكستان",
    "عوزي",
    "رشاش",
    "انتصار",
    "لاتصالات",
    "فرجينيا",
    "شاغر",
    "الشاغرة",
    "الشاغر",
    "الشواغر",
    "اجازة",
    "المصطافون",
    "القاضى",
    "للاجازة",
    "القاضون",
    "الاجازة",
    "التطعيمات",
    "القائمين",
    "اللقاح",
    "للقاح",
    "اللقاحات",
    "فاكسينيوم",
    "المتذبذب",
    "المتذبذبين",
    "المترددين",
    "فاكنا",
    "الريفي.",
    "فراغ",
    "فراغات",
    "بالفراغ",
    "فادا",
    "فادر",
    "فينا",
    "هوى",
    "والعويل",
    "مهبل",
    "مهبلي",
    "المهابل",
    "بغمد",
    "المهبلي",
    "تشرد",
    "المشردون",
    "إبهاما",
    "عب",
    "تتحكم",
    "بالرئتين",
    "والبلعوم",
    "طأطأ",
    "احتراما",
    "خضوعا",
    "المطأطىء",
    "الساتر",
    "تكبرأ",
    "التصلف",
    "عبثا",
    "هندوسية",
    "ممتلك",
    "إندرا",
    "الإلة.",
    "فالديرز",
    "فالديز",
    "فالدوستا",
    "المتفوقون",
    "فالينسيا",
    "الأسبانية",
    "تكافؤ",
    "فاليرا",
    "الفاليريت",
    "فاليري",
    "الفاليرين",
    "معلول",
    "السقيم",
    "الجسارة",
    "فاليانتنيس",
    "فالير",
    "فاليوم",
    "لمهديء",
    "الإسطورية",
    "فاليهو",
    "فاليانت",
    "حاكمة",
    "فرنسية",
    "معدلة",
    "يسعر",
    "الاستثنائية",
    "الفالز",
    "الثمين",
    "نفاسة",
    "الثمينة",
    "الدلالون",
    "فالفاتا",
    "بأجزاء",
    "بصمام",
    "احكام",
    "صمامي",
    "فاليلين",
    "إرحل",
    "راحل",
    "يرحل",
    "للمغادرة",
    "فامبير",
    "مصاص",
    "مصاصو",
    "الرقعات",
    "للدم",
    "فان",
    "فاناديت",
    "فانديك",
    "الفاناديوم",
    "الفانديوم",
    "فانكوفر",
    "فاندا",
    "فانداليا",
    "تخريبي",
    "تهديمي",
    "فاندينبيرج",
    "فاندر",
    "فاندربيلت",
    "فاندربيرغه",
    "فاندربويل",
    "فاندلينج",
    "دوارة",
    "فينيسا",
    "وقاعدة",
    "ونيلين",
    "الآلهة.",
    "احتجب",
    "بزوال",
    "ألشعور",
    "تنبؤ",
    "تكنولوجي",
    "قهار",
    "الفضل",
    "الأفضال",
    "فانواتو",
    "بمواجهة",
    "سليخ",
    "تبخيره",
    "التبخرة",
    "كالحرارة",
    "ألقائم",
    "تبخير",
    "تحويله",
    "بخر",
    "إنتاجه",
    "اضافية",
    "اﻷصلية",
    "الخفق",
    "أسبانية.",
    "ياردة",
    "فاران",
    "العرسة",
    "فاري",
    "ليموري",
    "ألتقلب",
    "فاريان",
    "المغاير",
    "المغايرات",
    "بالتغير",
    "عروق",
    "الملتوية",
    "المتورمة",
    "الدوالي",
    "دوالي",
    "دموية",
    "متورمة",
    "انتفاخات",
    "انتفاخ",
    "بتفاوت",
    "نقش",
    "هائم",
    "يتفاوت",
    "التنويعات",
    "يباين",
    "وملاحظات",
    "ثلة",
    "الأدباء",
    "اختلاف",
    "ألكع",
    "زنيم",
    "فيه.",
    "اللئيم",
    "اللئيمون",
    "فارنا",
    "البلغارية",
    "ألد",
    "ألدهانون",
    "الهندوسي",
    "توثق",
    "باختلاف",
    "الأوردة",
    "الوعائية",
    "الإحتوائية",
    "تمنع",
    "انتاج",
    "المنوية",
    "الفاسيلين",
    "الفازيلين",
    "فاشتي",
    "اسطوانية",
    "انقباض",
    "لتقلص",
    "فاسوبريسين",
    "فاسكويز",
    "فاس",
    "قطيعة",
    "فاسار",
    "شاسع",
    "فاسم",
    "كمثرية",
    "فاتيا",
    "بالنبي",
    "ونبوأته",
    "رسالته",
    "الفاتيكان",
    "بشيئ",
    "وعلم",
    "برميل",
    "اقليم",
    "فود",
    "فآن",
    "المدفن",
    "التقبيب",
    "المدافن",
    "فسيح",
    "متفاخرة",
    "الفسيح",
    "فوكسهول",
    "تشريف",
    "تم",
    "تصنيفه",
    "فيديو",
    "VAX",
    "الهندوس",
    "ف",
    "فيبلين",
    "إنتقال",
    "الموجهات",
    "الفيدا",
    "بالمدرسة",
    "بديانة",
    "فيدرو",
    "نائبوا",
    "فيري",
    "انحراف",
    "فيجا",
    "فيجاس",
    "الخضار",
    "الريجيم",
    "النباتيون",
    "إنبت",
    "ينبت",
    "والإثمار",
    "الخضروات",
    "الشحخص",
    "بمركبة",
    "بالعربات",
    "محجب",
    "البراقع",
    "الأحجبة",
    "بالوريد",
    "أدات",
    "بالعروق",
    "اصدر",
    "الحلقي",
    "فيلاسكويز",
    "حجاب",
    "الفيلكرو",
    "السهب",
    "بأشرعة",
    "يرقية",
    "لحيوان",
    "مصحوبة",
    "بمجهود",
    "لتحقيقها",
    "المخمل",
    "إلتقط",
    "الرقاق",
    "فيلما",
    "فيلوسيرابتور",
    "القطائف",
    "مخملي",
    "المخملي",
    "الفيلفيتليف",
    "مرتش",
    "الإرتشاء",
    "بالصيد",
    "لعرضه",
    "الأوربي",
    "الشهرالأول",
    "الثأر",
    "لكسب",
    "الرزق",
    "الكاسي",
    "موقر",
    "مبجل",
    "والتبجيل",
    "فينيتا",
    "فينيسي",
    "الفينيسيون",
    "فينيتو",
    "فينيزيا",
    "فينزويلا",
    "الفينزويلي",
    "الإنتقامات",
    "طفيف",
    "فينيسيا",
    "إنتقاء",
    "فين",
    "السمومية",
    "المنفس",
    "يهوي",
    "بالتهوية",
    "فينتولين",
    "البطين",
    "البطينات",
    "بطيني",
    "لبعض",
    "التكلم",
    "بالتكلم",
    "فينتشورا",
    "مخاطرون",
    "بمغامرة",
    "بخاخ",
    "بخاخات",
    "شعيرة",
    "الفيبس",
    "الفيبسيان",
    "فيرا",
    "فيراكروز",
    "فيراباميل",
    "فيراترم",
    "شفهية",
    "شفهيات",
    "فصحاء",
    "الشفهية",
    "يفعل",
    "التفعيل",
    "شفهيا",
    "فيربريوم",
    "تعتمد",
    "فيرباسكم",
    "حرفيا",
    "رعي",
    "للكلمات",
    "فيرد",
    "فيردل",
    "فردن",
    "فيردي",
    "الفيردن",
    "فيرداين",
    "لصبغة",
    "فيردن",
    "1916",
    ").",
    "بالخضرة",
    "مخضر",
    "الاتحاد",
    "فيرجاس",
    "فرجينيز",
    "فيرجل",
    "فيريلوج",
    "مخيلة",
    "فيرميل",
    "بالديدان",
    "متموجة",
    "فيرميكيوﻻيت",
    "للدود",
    "الزنجفر",
    "الزفنجر",
    "حشري",
    "فيرن",
    "فيرنا",
    "بلغة",
    "ربيعي",
    "ربيعيا",
    "الورنية",
    "فيرنون",
    "فيرنونيا",
    "فيرونا",
    "فيرونال",
    "لمنوم",
    "فيرونيكا",
    "الفيرونيكا",
    "فيرساي",
    "إنحدار",
    "فيرساتيك",
    "الاستخدامات",
    "أشعار",
    "منظوم",
    "إنظم",
    "النظم",
    "الفرست",
    "فقاري",
    "الفقريات",
    "90",
    "فيرفين",
    "النشاطات",
    "الفرفت",
    "معايير",
    "فيسبيوم",
    "بالمثانة",
    "منفط",
    "منفطات",
    "فيسيكاريا",
    "بثر",
    "مبثر",
    "يبثر",
    "التبثير",
    "بثري",
    "فيسبا",
    "نجمة",
    "فيسبكي",
    "دهليزي",
    "lمتعلق",
    "بالدهليز",
    "الدهليز",
    "دهليز",
    "الدهاليز",
    "مجالس",
    "الصداري",
    "فيسفيس",
    "بركان",
    "البيطري",
    "فيتال",
    "الجلبان",
    "الجلبانات",
    "بيطري",
    "بيطريون",
    "البيطريون",
    "فيتيفر",
    "الفيتو",
    "أغظ",
    "يغيظ",
    "الألوية",
    "باللواء",
    "فيو",
    "لنظام",
    "MSDOS",
    "مستعملة",
    "لشكل",
    "المداراة",
    "فايان",
    "مؤنة",
    "مؤن",
    "بالطرقات",
    "بالرحلة",
    "بوليصات",
    "التامين",
    "المصابين",
    "إيقاعية",
    "بقطع",
    "الفيبرافون",
    "الفيبرافونات",
    "إهتزاز",
    "الإهتزازة",
    "تهديج",
    "إهتزازي",
    "الضمة",
    "الضمات",
    "الويبرنوم",
    "فيك",
    "كنائب",
    "بنائب",
    "قاسي,",
    "نائبي",
    "نواب",
    "فيشي",
    "فيسي",
    "فيسيا",
    "جوار",
    "بالقرب",
    "فايكرز",
    "فيكاري",
    "فيكي",
    "فيكسبيرغ",
    "الفيكونت",
    "ضحايا",
    "ضحى",
    "مضحي",
    "المنتصر",
    "فيكتوريا",
    "الفيكتوري",
    "أشياء",
    "الفيكتوريون",
    "النصر",
    "المنتصرون",
    "فيكترولا",
    "المؤونة",
    "ممونون",
    "والمؤن",
    "الطعام,",
    "فيدا",
    "فيدال",
    "فيداليا",
    "لشريط",
    "الفيديوات",
    "الفديو",
    "النصي",
    "فيدور",
    "أرمل",
    "الأرمل",
    "المفجوع",
    "الترمل",
    "نافس",
    "وترية",
    "فينتيان",
    "فيير",
    "ينافس",
    "فيتنام",
    "الفيتناميون",
    "للمشاهدة",
    "فيوجراف",
    "مبدي",
    "الراي",
    "غريبة",
    "بإحتراس",
    "فيجنا",
    "المقاﻻت",
    "فيجو",
    "الفايكنك",
    "الفايكنكز",
    "فانوتو",
    "فيلاس",
    "افترائي",
    "حقارة",
    "مذموم",
    "للسمعة",
    "يذم",
    "ذم",
    "الذم",
    "يستخفيزدري",
    "الفيللا",
    "الخباثة",
    "خسيس",
    "فيلانوفا",
    "فيلارد",
    "الفيلات",
    "زغبي",
    "فيلنا",
    "والمدينة",
    "ليثوانيا",
    "فيلنيوس",
    "فيميناريا",
    "شجيرات",
    "أسترالية",
    "مورقة",
    "بالنبيذ",
    "فينكا",
    "فينس",
    "فينسينز",
    "فنسينت",
    "قهره",
    "التبريئات",
    "الكرمة",
    "لايقوى",
    "ويعتمد",
    "زارع",
    "الكرمات",
    "مزارع",
    "بالخمر",
    "زراعة",
    "كالكرمة",
    "فينينجز",
    "مخمور",
    "فينوس",
    "فينسن",
    "محصول",
    "الخمارون",
    "الفينيل",
    "الفيول",
    "مةسيقية",
    "للإنتهاك",
    "انتهاكي",
    "الكمانات",
    "إنتهاكي",
    "المنتهكون",
    "البنفسج",
    "فيولون",
    "هامة",
    "الخبيثة",
    "فيروسي",
    "النشاب",
    "فيرو",
    "العذراء",
    "فيرجيني",
    "العذارى",
    "المائلة",
    "المعدي",
    "الفيروسات",
    "مقززة",
    "التحف",
    "المزية",
    "المزايا",
    "الموهوبون",
    "الموهوب",
    "فتاك",
    "لوجه",
    "التأشيرة",
    "يعطى",
    "تأشيرة",
    "المحيا",
    "فايسليا",
    "التأشيرات",
    "مطاطية",
    "أرملة",
    "الفيكونتات",
    "فيسيد",
    "كالملزمة",
    "فيشنو",
    "المرئية",
    "الغربيون",
    "للزيارة",
    "بالنظر",
    "المنك",
    "كمشهد",
    "فيستلا",
    "البصرية",
    "متخيلون",
    "المرئيات",
    "الأعناب",
    "الفيتامين",
    "بالفيتامينات",
    "الفيتامينات",
    "الفيتالين",
    "مح",
    "فيتو",
    "زجاجي",
    "تزجيج",
    "زجج",
    "الفترينة",
    "المحال",
    "يلذع",
    "بماء",
    "فيترو",
    "بالمعماري",
    "فيترفيس",
    "ملونة",
    "انتقادي",
    "فيتاس",
    "فيفالدي",
    "المحميات",
    "المقدد",
    "فليحيى",
    "فيفيرين",
    "فيفيان",
    "إفعام",
    "بالحيوية",
    "محيي",
    "الولودية",
    "ولود",
    "ولودي",
    "فيفو",
    "الثعلبة",
    "فييلا",
    "لقماش",
    "فيزلا",
    "للوجه",
    "فلاديمير",
    "فلاديفوستوك",
    "اندماج",
    "فيزا",
    "30",
    "كهتز",
    "فليتس",
    "الإفتراضية",
    "فوكا",
    "اللفظة",
    "لفظي",
    "معتل",
    "غناء",
    "ناطق",
    "ندائي",
    "ندائيات",
    "الفودكا",
    "فودون",
    "الكاريبية",
    "فوجل",
    "الرواج",
    "متماشي",
    "بالصوت",
    "مصوت",
    "مصوتون",
    "المبرم",
    "فويلا",
    "الفوال",
    "الرشيق",
    "وسريع",
    "مبنية",
    "أخمصي",
    "متطايرة",
    "التطايرية",
    "تطيير",
    "الفولجا",
    "فولين",
    "للتطاير",
    "الإرادات",
    "فولكسواجين",
    "ﻻعب",
    "السوفييتي",
    "فولستد",
    "فولتا",
    "الفولطية",
    "فولطية",
    "الفولطيات",
    "فولطي",
    "فولتاير",
    "الكلفانية",
    "بالتفاعل",
    "فولتيرا",
    "الفولتميتر",
    "الفولتميترات",
    "الفولتات",
    "طلق",
    "بمجلدات",
    "حجمي",
    "التحجيم",
    "الطوعية",
    "التطوعية",
    "المتطوع",
    "تطوع",
    "التطوع",
    "المتطوعون",
    "فولفو",
    "فولفوكس",
    "طحالب",
    "عذبة",
    "وانسدادها",
    "المهجن",
    "لفرق",
    "والمقاومة",
    "الأنفية",
    "بالعظمة",
    "القيئ",
    "للتقيء",
    "للتقيؤ",
    "فون",
    "فونا",
    "الودونية",
    "الدردورية",
    "دوراني",
    "نذري",
    "النذرية",
    "إكفل",
    "مكفول",
    "مستند",
    "يكفل",
    "الكفل",
    "الوصل",
    "فوغت",
    "الناذر",
    "بنقل",
    "فويال",
    "التلصص",
    "بالتلصص",
    "مختلسوا",
    "فريلاند",
    "اصطناعية",
    "HP",
    "فولكان",
    "يبركن",
    "المبتذﻻت",
    "السوقية",
    "إبتذل",
    "يبتذل",
    "ثعلبي",
    "العقبان",
    "نسراني",
    "الفرج",
    "فولفا",
    "بالفرج",
    "فولفار",
    "الإفتراضي",
    "الأفقي",
    "إبتدعته",
    "زاناكس",
    "للقلق",
    "الزنتات",
    "الزانتك",
    "فاتحة",
    "زانثوما",
    "صفراء",
    "أمحاح",
    "زانثس",
    "خافير",
    "للنوافذ",
    "لبرامج",
    "اختراق",
    "الزينون.",
    "صواري",
    "زينيا",
    "زينويكس",
    "للضيوف",
    "ضيافة",
    "ينبغي",
    "الإكسينون",
    "للأجانب",
    "لمركبات",
    "زينويليك",
    "الشريف",
    "بالتصوير",
    "الجاف",
    "صوم",
    "المسيحيين",
    "الزيروفيل",
    "موطن",
    "الزيروفيللم",
    "الصحراوي",
    "يصلح",
    "الصحراوية",
    "زيرس",
    "زركسيس",
    "الزوشا",
    "زيان",
    "زيميان",
    "زينيراما",
    "ابو",
    "لعظمة",
    "أل",
    "مودم",
    "الأشعات",
    "بالإطلاع",
    "تيرم",
    "X",
    "22",
    "23",
    "29",
    "25",
    "26",
    "27",
    "28",
    "الزيلان",
    "لزجة",
    "الزيليم",
    "الزيلين",
    "زيلينول",
    "زيلوكين",
    "لمخدر",
    "موضعي",
    "زيلوكوباين",
    "الناشئ",
    "للخشب",
    "الزيلوميلم",
    "الإسترالية",
    "زيلوفيلان",
    "تعيش",
    "الاكسليفون",
    "الزيلوز",
    "للدراسة",
    "للتمارين",
    "لكشط",
    "واي",
    "مجهول.",
    "للأفعال",
    "الصادي",
    "ص",
    "2000",
    "ياكير",
    "تمساح",
    "ياك",
    "اليخت",
    "باليخت",
    "اليخوت",
    "مالكة",
    "ياكولت",
    "يافل",
    "ييدجر",
    "ياجي",
    "متوحشون",
    "بشرية",
    "بالعبرية",
    "ياكيما",
    "ياكين",
    "ياكت",
    "ييل",
    "يالتا",
    "اليالو",
    "ياما",
    "ياماها",
    "رسميي",
    "اليامب",
    "يانسي",
    "الفحولة",
    "يانجون",
    "اليانجتز",
    "انتزع",
    "أمريكيون",
    "الانتزاع",
    "يانكتون",
    "ياوندي",
    "الياورت",
    "الأوبوسوم",
    "اليابون",
    "ياكي",
    "الصارية",
    "بالساحة",
    "ياردي",
    "إجرامية",
    "جامايكيا",
    "الساحات",
    "بجاهزية",
    "يارك",
    "يلبسها",
    "المتدينون",
    "يارماوث",
    "خيوط",
    "تجديل",
    "للزمجرة",
    "كالكلب",
    "اليارو",
    "البرقع",
    "اليطقان",
    "بشفرة",
    "ييتس",
    "يستغيث",
    "المستغيث",
    "اليوتيا",
    "انحرف",
    "تثاءب",
    "يونر",
    "بتثاؤب",
    "الجلدي",
    "ياي",
    "التايلندية",
    "الإيتربيوم",
    "أسمى",
    "ييغر",
    "تلد",
    "قامت",
    "يارا",
    "بلوط",
    "اشتق",
    "اشتاق",
    "المشتاق",
    "متمنيا",
    "الأشواق",
    "السنوات",
    "اختمارا",
    "الاختمار",
    "ياتس",
    "يكه",
    "يدو",
    "اليابان.",
    "مرادفة",
    "لطوكيو",
    "اصفر",
    "اصفرارا",
    "يلوفين",
    "اليلوهامر",
    "الاصفرار",
    "يلو",
    "نايف",
    "اليمن",
    "اليمني",
    "الين",
    "الينات",
    "النمامة",
    "اليومن",
    "اليربا",
    "الرفسة",
    "يركس",
    "اليشيفا",
    "يسو",
    "بالأمس",
    "أمس",
    "الأمس",
    "اليتي",
    "انه",
    "الهيمالايا",
    "يتر",
    "اليو",
    "يزو",
    "راح",
    "يهفه",
    "العبرية",
    "يي",
    "كورية",
    "يهود",
    "استسلم",
    "الاستسلام",
    "بإستسلام",
    "يجو",
    "انبح",
    "ينبح",
    "يمير",
    "اسطوري",
    "استمر",
    "البكائية",
    "تغنى",
    "البكائي",
    "البكائيون",
    "التغني",
    "بكائيا",
    "يودر",
    "يود",
    "اليوجا",
    "الزبادي",
    "برياضة",
    "صرخة",
    "تشجيع",
    "لكلاب",
    "يرتبط",
    "يزدوج",
    "عبودية",
    "لفلسفة",
    "يوكناباتوفا",
    "ميسيسيبي",
    "يوكوهاما",
    "يوكتس",
    "المح",
    "الأمحاح",
    "عبرية",
    "يونا",
    "يونكرس",
    "يوريك",
    "يوركر",
    "يوركس",
    "يوركشر",
    "يوركتاون",
    "اليوروبي",
    "اليوروبا",
    "يوسامتي",
    "يوست",
    "يوتابيت",
    "بصغر",
    "يونغز",
    "يانجز",
    "اليوبون",
    "ملكك",
    "أنفسكما",
    "التشيتاه",
    "يوو",
    "عوى",
    "اليويو",
    "إبرس",
    "إبسيﻻنتي",
    "يريكا",
    "الإيتريوم",
    "يوغسلافيا",
    "يوان",
    "للصين",
    "1279",
    "1368",
    "يوبا",
    "يوكاتان",
    "اليوكا",
    "يك",
    "يوين",
    "اليوغا",
    "حقبة",
    "اليوغسلافي",
    "يوغسلافي",
    "الاشمئزاز",
    "يوكي",
    "اليولان",
    "ميلاد",
    "اليومان",
    "الألذ",
    "يونان",
    "يوري",
    "يوتان",
    "إيفز",
    "يفات",
    "المسيحيات",
    "الأغلب",
    "المشهورة",
    "الزابليونية",
    "الحلويات)",
    "زابيولون",
    "زكريا",
    "زاكاري",
    "الزعفر",
    "(أكسيد",
    "الكوبالت)",
    "ممتلىء",
    "تعريج",
    "زغرب",
    "متعرجة",
    "زاهل",
    "زائير",
    "زاليسكي",
    "زاما",
    "زامان",
    "زامبيزي",
    "أبوان",
    "وزنجية",
    "الزاميا",
    "الزمندارية",
    "لمالك",
    "زامورا",
    "زان",
    "الزندر",
    "إضحاكا",
    "تملقا",
    "المضحكون",
    "هزلا",
    "تزلفا",
    "تهريجي",
    "الإضحاك",
    "زانوني",
    "زانت",
    "زنزبار",
    "انطلق",
    "زاباتا",
    "مكسيكي",
    "زاراثسترا",
    "الزرادشتية",
    "زاراتايت",
    "لصهر",
    "ممسكة",
    "لقدح",
    "الساخن",
    "الزارث",
    "الزرزويلة",
    "هزلية",
    "زوسكنيريا",
    "لإصلاح",
    "أسقف",
    "زازن",
    "(التأمل",
    "البوذي)",
    "زيلاند",
    "الزيلوت",
    "تعصب",
    "حماسة",
    "شراعي",
    "أبناءه",
    "يسوع",
    "العتابي",
    "حمار",
    "الزرد",
    "بالحمار",
    "بحمار",
    "الدرباني",
    "ثدي",
    "البقرية",
    "ضارة",
    "تسي",
    "زيبيولون",
    "ليا",
    "ثدية",
    "زخرفي",
    "يخاط",
    "Z",
    "زيمان",
    "زيفيريلي",
    "زيهنر",
    "فضية",
    "نمساوية",
    "زيايدي",
    "زيجلر",
    "الزين",
    "يأتى",
    "زييس",
    "زيل",
    "زيليرباتش",
    "زيمني",
    "الأعمى",
    "زينا",
    "لتجمع",
    "فارسية",
    "للديانة",
    "زيندا",
    "بالأوج",
    "زينو",
    "الزيوليت",
    "زيونا",
    "زيفانياه",
    "نبي",
    "زيفيرهيلس",
    "للأس",
    "الفنك",
    "بالأصفار",
    "زيربابل",
    "عيزرا",
    "نكهة",
    "حريف",
    "زيستريل",
    "لتخفيض",
    "الزيتا",
    "فروع",
    "الف",
    "موقعين",
    "كأن",
    "اخذ",
    "كتابه",
    "وإجازة",
    "زوس",
    "زيزيرا",
    "زيبيت",
    "صيدا",
    "زيجفيلد",
    "منعرج",
    "زيكلاج",
    "اللاشيء",
    "أجراس",
    "تستعملها",
    "الشرقيات",
    "زيلا",
    "والكبير",
    "والكبيرة",
    "زيلوج",
    "منتجة",
    "لمعالجات",
    "زيم",
    "وسامة",
    "زيمبابوي",
    "زيمبابواي",
    "زيميرمان",
    "زنكي",
    "الطﻻء",
    "بالزنك",
    "الأنود",
    "القفشة",
    "الزينية",
    "صهيون",
    "الصهيونية",
    "الصهيوني",
    "الصهاينة",
    "ألصهيونيون",
    "زيور",
    "الكود",
    "سوستة",
    "السوستة",
    "انغلق",
    "الزركون",
    "الزركونات",
    "زركوني",
    "الزركونيوم",
    "احتواء",
    "المكرونة",
    "6133",
    "ترمز",
    "ال613",
    "الزلوتي",
    "زور",
    "زوبو",
    "الجدار",
    "البروج",
    "زوي",
    "زويتروب",
    "داخلها",
    "بعالم",
    "زويلس",
    "وحاسد",
    "الإقلال",
    "الزوسيت",
    "زوكور",
    "زولا",
    "جمركي",
    "زومبا",
    "أعيدت",
    "لبسه",
    "واليهود",
    "لتمييزهم",
    "بمنطقة",
    "للحيوانات",
    "أشكالهم",
    "عاداتهم",
    "داخله",
    "زوك",
    "طبقا",
    "الحيواني",
    "الحيوانيون",
    "بتأليه",
    "تأليه",
    "للحوم",
    "الفقارية",
    "الزوفيت",
    "البلانكتونية",
    "زووزو",
    "زوبل",
    "نعال",
    "زوريل",
    "زورك",
    "زرادشت",
    "زرادشتي",
    "زوروبابل",
    "للملك",
    "جيهوياتشين",
    "زوستيرا",
    "زوفيراكس",
    "للفيروس",
    "زويسيا",
    "للأوامر",
    "يونكس",
    "زولو",
    "المتواجدون",
    "بزيمبابوي",
    "زيوريخ",
    "بحياة",
    "زوينجلي",
    "بأرجل",
    "وجني",
    "للإنقسام",
    "لنصفين",
    "متساويين",
    "موسمية",
    "الملقحة",
    "زيلوبريم",
    "الحمضية",
    "زيماس",
    "أنزيمي",
    "الهياج",
    "بالهياج",
    "الأنزيمية",
    "تفتيت",
    "بسيطة",
    "وانتشار",
    "للإلتهاب",
    "لمشروب",
    "آباءكم",
    "آباءنا",
    "آباءهم",
    "آباؤكم",
    "آباؤنا",
    "آباؤهم",
    "آبائك",
    "آبائكم",
    "آبائنا",
    "آبائهم",
    "آبائهن",
    "آبائي",
    "آتاك",
    "آتاكم",
    "آتانا",
    "آتاني",
    "آتاه",
    "آتاها",
    "آتاهم",
    "آتاهما",
    "آتت",
    "آتنا",
    "آتهم",
    "آتوا",
    "آتوني",
    "آتوه",
    "آتي",
    "آتية",
    "آتيت",
    "آتيتك",
    "آتيتكم",
    "آتيتم",
    "آتيتموهن",
    "آتيتنا",
    "آتيتني",
    "آتيتهن",
    "آتيك",
    "آتيكم",
    "آتينا",
    "آتيناك",
    "آتيناكم",
    "آتيناه",
    "آتيناها",
    "آتيناهم",
    "آتيه",
    "آتيهم",
    "آثارهم",
    "آثارهما",
    "آثرك",
    "آثم",
    "آثما",
    "آخذ",
    "آخذين",
    "آخران",
    "آخرون",
    "آخرين",
    "آذاننا",
    "آذانهم",
    "آذن",
    "آذناك",
    "آذنتكم",
    "آذوا",
    "آذيتمونا",
    "آزر",
    "آسفونا",
    "آسن",
    "آسى",
    "آلآن",
    "آلاء",
    "آلاف",
    "آلذكرين",
    "آلله",
    "آلهتكم",
    "آلهتنا",
    "آلهتهم",
    "آلهتي",
    "آمره",
    "آمنا",
    "آمنت",
    "آمنتم",
    "آمنكم",
    "آمنوا",
    "آمنون",
    "آمنين",
    "آن",
    "آناء",
    "آنس",
    "آنست",
    "آنستم",
    "آنفا",
    "آنية",
    "آووا",
    "آوي",
    "آيات",
    "آياتك",
    "آياتنا",
    "آياته",
    "آياتها",
    "آياتي",
    "آية",
    "آيتك",
    "آيتين",
    "أآلهتنا",
    "أأتخذ",
    "أأرباب",
    "أأسجد",
    "أأسلمتم",
    "أأشفقتم",
    "أأشكر",
    "أأعجمي",
    "أأقررتم",
    "أألد",
    "أألقي",
    "أأمنتم",
    "أأنت",
    "أأنتم",
    "أأنذرتهم",
    "أأنزل",
    "أؤنبئكم",
    "أإذا",
    "أإله",
    "أإنا",
    "أإنك",
    "أئذا",
    "أئفكا",
    "أئمة",
    "أئن",
    "أئنا",
    "أئنكم",
    "أبابيل",
    "أباكم",
    "أبالله",
    "أبانا",
    "أباه",
    "أباهم",
    "أبت",
    "أبتغي",
    "أبدله",
    "أبرئ",
    "أبرح",
    "أبرموا",
    "أبسلوا",
    "أبشر",
    "أبشرا",
    "أبشرتموني",
    "أبصار",
    "أبصاركم",
    "أبصارنا",
    "أبصارها",
    "أبصارهم",
    "أبصارهن",
    "أبصر",
    "أبصرنا",
    "أبعث",
    "أبغي",
    "أبغيكم",
    "أبكارا",
    "أبكم",
    "أبلغتكم",
    "أبلغكم",
    "أبلغه",
    "أبلغوا",
    "أبناءكم",
    "أبناءنا",
    "أبناءهم",
    "أبنائكم",
    "أبنائهن",
    "أبوابا",
    "أبوابها",
    "أبواه",
    "أبوك",
    "أبوهم",
    "أبوهما",
    "أبويك",
    "أبويكم",
    "أبويه",
    "أبى",
    "أبيكم",
    "أبينا",
    "أبيهم",
    "أتأتون",
    "أتأخذونه",
    "أتأمرون",
    "أتاك",
    "أتاكم",
    "أتانا",
    "أتاها",
    "أتاهم",
    "أتبع",
    "أتبعك",
    "أتبعه",
    "أتبنون",
    "أتت",
    "أتتخذ",
    "أتتخذنا",
    "أتتركون",
    "أتتك",
    "أتتكم",
    "أتتهم",
    "أتجادلونني",
    "أتجعل",
    "أتحاجوننا",
    "أتحاجوني",
    "أتحدثونهم",
    "أتخذ",
    "أتخذتم",
    "أتخذناهم",
    "أتخشونهم",
    "أتدعون",
    "أتذر",
    "أتراب",
    "أترابا",
    "أترفتم",
    "أترفوا",
    "أتريد",
    "أتريدون",
    "أتستبدلون",
    "أتصبرون",
    "أتعبدون",
    "أتعجبين",
    "أتعدانني",
    "أتعلمون",
    "أتقاكم",
    "أتقتلون",
    "أتقن",
    "أتقولون",
    "أتل",
    "أتلو",
    "أتمدونن",
    "أتممت",
    "أتمها",
    "أتموا",
    "أتنبئون",
    "أتنهانا",
    "أتهتدي",
    "أتهلكنا",
    "أتوا",
    "أتواصوا",
    "أتوب",
    "أتوك",
    "أتوكأ",
    "أتوه",
    "أتيا",
    "أتيت",
    "أتين",
    "أتينا",
    "أتيناهم",
    "أثاثا",
    "أثارة",
    "أثاما",
    "أثخنتموهم",
    "أثقالكم",
    "أثقالها",
    "أثقالهم",
    "أثقلت",
    "أثم",
    "أثيما",
    "أجئتنا",
    "أجاج",
    "أجاجا",
    "أجبتم",
    "أجد",
    "أجرا",
    "أجرمنا",
    "أجرموا",
    "أجره",
    "أجرها",
    "أجرهم",
    "أجزعنا",
    "أجسامهم",
    "أجعل",
    "أجعلتم",
    "أجعلنا",
    "أجلا",
    "أجلت",
    "أجلنا",
    "أجله",
    "أجلها",
    "أجلهم",
    "أجلهن",
    "أجمعوا",
    "أجمعون",
    "أجمعين",
    "أجنة",
    "أجوركم",
    "أجورهم",
    "أجورهن",
    "أجيبت",
    "أجيبوا",
    "أحاط",
    "أحبارهم",
    "أحببت",
    "أحدا",
    "أحدكم",
    "أحدكما",
    "أحدنا",
    "أحدهم",
    "أحدهما",
    "أحرص",
    "أحسب",
    "أحسن",
    "أحسنتم",
    "أحسنه",
    "أحسنوا",
    "أحسوا",
    "أحصاه",
    "أحصاها",
    "أحصاهم",
    "أحصرتم",
    "أحصروا",
    "أحصن",
    "أحصنت",
    "أحصيناه",
    "أحضرت",
    "أحطت",
    "أحطنا",
    "أحق",
    "أحقابا",
    "أحكم",
    "أحكمت",
    "أحلامهم",
    "أحلت",
    "أحللنا",
    "أحلنا",
    "أحمل",
    "أحملكم",
    "أحوى",
    "أحياء",
    "أحياكم",
    "أحياها",
    "أحياهم",
    "أحيي",
    "أحييناها",
    "أخا",
    "أخاف",
    "أخالفكم",
    "أخانا",
    "أخاه",
    "أخاهم",
    "أخباركم",
    "أخبارها",
    "أختك",
    "أختها",
    "أخدان",
    "أخذا",
    "أخذة",
    "أخذت",
    "أخذتم",
    "أخذته",
    "أخذتها",
    "أخذتهم",
    "أخذنا",
    "أخذناهم",
    "أخذوا",
    "أخر",
    "أخراكم",
    "أخراهم",
    "أخرتن",
    "أخرتنا",
    "أخرتني",
    "أخرجت",
    "أخرجتك",
    "أخرجتم",
    "أخرجك",
    "أخرجكم",
    "أخرجنا",
    "أخرجني",
    "أخرجه",
    "أخرجوا",
    "أخرجوكم",
    "أخرجوهم",
    "أخرقتها",
    "أخرنا",
    "أخزى",
    "أخزيته",
    "أخطأتم",
    "أخطأنا",
    "أخفي",
    "أخفيتم",
    "أخفيها",
    "أخلد",
    "أخلده",
    "أخلصناهم",
    "أخلفنا",
    "أخلفوا",
    "أخلق",
    "أخنه",
    "أخواتكم",
    "أخواتهن",
    "أخوالكم",
    "أخوك",
    "أخوهم",
    "أخويكم",
    "أخي",
    "أخيه",
    "أدباركم",
    "أدبارها",
    "أدبارهم",
    "أدبر",
    "أدخلني",
    "أدخلوا",
    "أدراك",
    "أدراكم",
    "أدركه",
    "أدري",
    "أدعو",
    "أدعوتموهم",
    "أدعوكم",
    "أدعياءكم",
    "أدعيائهم",
    "أدلك",
    "أدلكم",
    "أدهى",
    "أدوا",
    "أذاعوا",
    "أذاقهم",
    "أذاهم",
    "أذبحك",
    "أذقنا",
    "أذقناه",
    "أذكركم",
    "أذكره",
    "أذلة",
    "أذلك",
    "أذنت",
    "أذنيه",
    "أذهب",
    "أذهبتم",
    "أرأيت",
    "أرأيتك",
    "أرأيتكم",
    "أرأيتم",
    "أراد",
    "أرادا",
    "أرادني",
    "أرادوا",
    "أراذلنا",
    "أراغب",
    "أراك",
    "أراكم",
    "أراكهم",
    "أراني",
    "أربابا",
    "أربى",
    "أرجائها",
    "أرجع",
    "أرجلكم",
    "أرجلهم",
    "أرجه",
    "أرحام",
    "أرحامكم",
    "أرحامهن",
    "أرداكم",
    "أردت",
    "أردتم",
    "أردنا",
    "أردناه",
    "أرذل",
    "أرساها",
    "أرسلت",
    "أرسلتم",
    "أرسلنا",
    "أرسلناك",
    "أرسلناه",
    "أرسله",
    "أرسلوا",
    "أرضعت",
    "أرضعن",
    "أرضعنكم",
    "أرضعيه",
    "أرضكم",
    "أرضنا",
    "أرضهم",
    "أرضيتم",
    "أركسهم",
    "أركسوا",
    "أرنا",
    "أرني",
    "أرهطي",
    "أروني",
    "أرى",
    "أريكم",
    "أريناك",
    "أريناه",
    "أزا",
    "أزاغ",
    "أزري",
    "أزفت",
    "أزكى",
    "أزلفت",
    "أزواجا",
    "أزواجك",
    "أزواجكم",
    "أزواجنا",
    "أزواجه",
    "أزواجهم",
    "أزواجهن",
    "أزيد",
    "أسأتم",
    "أسألك",
    "أسألكم",
    "أساءوا",
    "أسارى",
    "أساور",
    "أسباطا",
    "أستجب",
    "أستخلصه",
    "أستغفر",
    "أستغفرت",
    "أستكبرت",
    "أسحر",
    "أسر",
    "أسرفوا",
    "أسرهم",
    "أسروا",
    "أسرى",
    "أسفارا",
    "أسفارنا",
    "أسفر",
    "أسفى",
    "أسكنت",
    "أسكنوهن",
    "أسلحتكم",
    "أسلحتهم",
    "أسلفت",
    "أسلفتم",
    "أسلم",
    "أسلما",
    "أسلمت",
    "أسلمنا",
    "أسلموا",
    "أسمائه",
    "أسمع",
    "أسمعهم",
    "أسوة",
    "أسورة",
    "أشاء",
    "أشتاتا",
    "أشحة",
    "أشداء",
    "أشدكم",
    "أشده",
    "أشدهما",
    "أشراطها",
    "أشرك",
    "أشركت",
    "أشركتم",
    "أشركتمون",
    "أشركنا",
    "أشركوا",
    "أشق",
    "أشقاها",
    "أشكر",
    "أشكو",
    "أشهد",
    "أشهدتهم",
    "أشهدوا",
    "أشياءهم",
    "أشياعكم",
    "أصابت",
    "أصابتكم",
    "أصابته",
    "أصابتهم",
    "أصابعهم",
    "أصابك",
    "أصابكم",
    "أصابها",
    "أصابهم",
    "أصبتم",
    "أصبحوا",
    "أصبرهم",
    "أصبناهم",
    "أصحابهم",
    "أصدقت",
    "أصطفى",
    "أصلابكم",
    "أصلاتك",
    "أصلها",
    "أصنام",
    "أصناما",
    "أصنامكم",
    "أصواتكم",
    "أصواتهم",
    "أصوافها",
    "أصولها",
    "أصيب",
    "أضاءت",
    "أضاعوا",
    "أضحك",
    "أضطره",
    "أضعافا",
    "أضغاث",
    "أضغانكم",
    "أضغانهم",
    "أضل",
    "أضلانا",
    "أضللتم",
    "أضللن",
    "أضلنا",
    "أضلني",
    "أضلوا",
    "أضلونا",
    "أضيع",
    "أطاع",
    "أطاعونا",
    "أطرافها",
    "أطعتم",
    "أطعتموهم",
    "أطعمه",
    "أطعمهم",
    "أطعنا",
    "أطعنكم",
    "أطغيته",
    "أطفأها",
    "أطمع",
    "أطهر",
    "أطوارا",
    "أطيعوا",
    "أظفركم",
    "أظن",
    "أعبد",
    "أعتدنا",
    "أعثرنا",
    "أعجاز",
    "أعجبتكم",
    "أعجبك",
    "أعجبكم",
    "أعجزت",
    "أعجلتم",
    "أعجلك",
    "أعجمي",
    "أعجميا",
    "أعدت",
    "أعذبه",
    "أعرض",
    "أعرضتم",
    "أعرضوا",
    "أعز",
    "أعزة",
    "أعصر",
    "أعصي",
    "أعطوا",
    "أعطيناك",
    "أعظك",
    "أعظكم",
    "أعقابكم",
    "أعقابنا",
    "أعلنت",
    "أعلنتم",
    "أعمالا",
    "أعمالكم",
    "أعمالنا",
    "أعمالهم",
    "أعمامكم",
    "أعمل",
    "أعناب",
    "أعناق",
    "أعناقهم",
    "أعنده",
    "أعهد",
    "أعوذ",
    "أعيبها",
    "أعيدوا",
    "أعيذها",
    "أعين",
    "أعينكم",
    "أعينهم",
    "أعينهن",
    "أغرقنا",
    "أغرقناهم",
    "أغرقوا",
    "أغشيت",
    "أغفلنا",
    "أغلالا",
    "أغناهم",
    "أغنت",
    "أغني",
    "أغنياء",
    "أغويتني",
    "أغوينا",
    "أغويناهم",
    "أغير",
    "أف",
    "أفأصفاكم",
    "أفأمن",
    "أفأمنتم",
    "أفأمنوا",
    "أفأنبئكم",
    "أفأنت",
    "أفأنتم",
    "أفإن",
    "أفئدة",
    "أفئدتهم",
    "أفاء",
    "أفاتخذتم",
    "أفاض",
    "أفاق",
    "أفاك",
    "أفبالباطل",
    "أفبعذابنا",
    "أفبنعمة",
    "أفبهذا",
    "أفتأتون",
    "أفتؤمنون",
    "أفتتخذونه",
    "أفترى",
    "أفتطمعون",
    "أفتعبدون",
    "أفتمارونه",
    "أفتنا",
    "أفتهلكنا",
    "أفتوني",
    "أفحسب",
    "أفحسبتم",
    "أفحكم",
    "أفرأيت",
    "أفرأيتم",
    "أفسحر",
    "أفسدوها",
    "أفصح",
    "أفضتم",
    "أفضى",
    "أفطال",
    "أفعصيت",
    "أفعيينا",
    "أفغير",
    "أفك",
    "أفكلما",
    "أفل",
    "أفلا",
    "أفلت",
    "أفلح",
    "أفلم",
    "أفما",
    "أفمن",
    "أفنان",
    "أفنجعل",
    "أفنضرب",
    "أفهم",
    "أفواجا",
    "أفواههم",
    "أفي",
    "أفيضوا",
    "أقاموا",
    "أقبل",
    "أقبلنا",
    "أقتت",
    "أقتل",
    "أقتلت",
    "أقدامكم",
    "أقدامنا",
    "أقربهم",
    "أقررتم",
    "أقررنا",
    "أقريب",
    "أقسط",
    "أقسمتم",
    "أقسموا",
    "أقطارها",
    "أقفالها",
    "أقلامهم",
    "أقلت",
    "أقلعي",
    "أقمتم",
    "أقواتها",
    "أقول",
    "أقوم",
    "أقيموا",
    "أكابر",
    "أكالون",
    "أكان",
    "أكبرنه",
    "أكثركم",
    "أكثرهم",
    "أكذبتم",
    "أكرمكم",
    "أكرمن",
    "أكرمي",
    "أكرهتنا",
    "أكفاركم",
    "أكفر",
    "أكفرت",
    "أكفرتم",
    "أكفره",
    "أكفلنيها",
    "أكلا",
    "أكلم",
    "أكلها",
    "أكمامها",
    "أكملت",
    "أكن",
    "أكنانا",
    "أكنة",
    "أكننتم",
    "أكون",
    "ألتناهم",
    "ألحقتم",
    "ألحقنا",
    "ألربك",
    "ألزمناه",
    "ألست",
    "ألسنتكم",
    "ألسنتهم",
    "ألفافا",
    "ألفت",
    "ألفوا",
    "ألفينا",
    "ألق",
    "ألقاه",
    "ألقاها",
    "ألقها",
    "ألقوا",
    "ألقي",
    "ألقيا",
    "ألكم",
    "ألن",
    "ألهاكم",
    "ألوانه",
    "ألوانها",
    "ألوف",
    "أليم",
    "أليما",
    "أمات",
    "أماته",
    "أماذا",
    "أمامه",
    "أماناتكم",
    "أمانته",
    "أماني",
    "أمانيهم",
    "أمتا",
    "أمتعكن",
    "أمتكم",
    "أمتنا",
    "أمثالكم",
    "أمثالها",
    "أمثالهم",
    "أمثلهم",
    "أمدا",
    "أمدكم",
    "أمرت",
    "أمرتك",
    "أمرتني",
    "أمرتهم",
    "أمركم",
    "أمرنا",
    "أمره",
    "أمرها",
    "أمرهم",
    "أمروا",
    "أمري",
    "أمسكن",
    "أمسكهما",
    "أمشاج",
    "أمضي",
    "أمطرت",
    "أمعاءهم",
    "أمك",
    "أملا",
    "أملك",
    "أمليت",
    "أمما",
    "أمنا",
    "أمنة",
    "أمنتكم",
    "أمنتم",
    "أمنيته",
    "أمه",
    "أمها",
    "أمهاتكم",
    "أمهاتهم",
    "أمهلهم",
    "أموات",
    "أمواتا",
    "أموالا",
    "أموالكم",
    "أموالنا",
    "أموالهم",
    "أموت",
    "أميون",
    "أنؤمن",
    "أنا",
    "أناب",
    "أنبأك",
    "أنبأهم",
    "أنبئكم",
    "أنبئهم",
    "أنبئوني",
    "أنباء",
    "أنبائكم",
    "أنبائها",
    "أنبتت",
    "أنبتكم",
    "أنبتنا",
    "أنبنا",
    "أنبياء",
    "أنتم",
    "أنتما",
    "أنجاكم",
    "أنجانا",
    "أنجاهم",
    "أنجيتنا",
    "أنجينا",
    "أنجيناكم",
    "أنحن",
    "أندادا",
    "أندعو",
    "أنذرتكم",
    "أنذركم",
    "أنذرناكم",
    "أنذرهم",
    "أنذروا",
    "أنزلت",
    "أنزلتموه",
    "أنزلنا",
    "أنزلناه",
    "أنزلناها",
    "أنزلني",
    "أنزله",
    "أنساب",
    "أنسانيه",
    "أنسجد",
    "أنسوكم",
    "أنشأتم",
    "أنشأكم",
    "أنشأنا",
    "أنشأناه",
    "أنشأناهن",
    "أنشأها",
    "أنشره",
    "أنصارا",
    "أنصاري",
    "أنصتوا",
    "أنصح",
    "أنطعم",
    "أنطق",
    "أنطقنا",
    "أنظرني",
    "أنعام",
    "أنعاما",
    "أنعامكم",
    "أنعامهم",
    "أنعمت",
    "أنعمنا",
    "أنعمها",
    "أنفسكم",
    "أنفسهن",
    "أنفقت",
    "أنفقتم",
    "أنفقوا",
    "أنقض",
    "أنك",
    "أنكاثا",
    "أنكالا",
    "أنكحك",
    "أنكم",
    "أنلزمكموها",
    "أنما",
    "أننا",
    "أنهار",
    "أنهارا",
    "أنهاكم",
    "أنهكما",
    "أنهما",
    "أنى",
    "أنيب",
    "أهؤلاء",
    "أهانن",
    "أهدك",
    "أهدكم",
    "أهدى",
    "أهديكم",
    "أهذا",
    "أهكذا",
    "أهلكت",
    "أهلكتهم",
    "أهلكنا",
    "أهلكناها",
    "أهلكناهم",
    "أهلكني",
    "أهلنا",
    "أهله",
    "أهلها",
    "أهلهم",
    "أهلهن",
    "أهلي",
    "أهليكم",
    "أهليهم",
    "أهم",
    "أهمتهم",
    "أهواء",
    "أهواءكم",
    "أهواءهم",
    "أهون",
    "أهوى",
    "أوآباؤنا",
    "أوأمن",
    "أواب",
    "أواه",
    "أوبي",
    "أوت",
    "أوتادا",
    "أوتوا",
    "أوتوه",
    "أوتي",
    "أوتيت",
    "أوتيتم",
    "أوتيته",
    "أوثانا",
    "أوجفتم",
    "أوحى",
    "أوحي",
    "أوحيت",
    "أوحينا",
    "أودية",
    "أوديتهم",
    "أوذي",
    "أوذينا",
    "أورثتموها",
    "أورثنا",
    "أورثوا",
    "أوزار",
    "أوزارا",
    "أوزارها",
    "أوزارهم",
    "أوزعني",
    "أوسطهم",
    "أوعجبتم",
    "أوعظت",
    "أوفوا",
    "أوفى",
    "أوفي",
    "أوقدوا",
    "أوكلما",
    "أولئكم",
    "أولاء",
    "أولات",
    "أولادكم",
    "أولادهم",
    "أولادهن",
    "أولاهم",
    "أولاهما",
    "أولما",
    "أولو",
    "أولى",
    "أولياءه",
    "أولياؤكم",
    "أولياؤه",
    "أولياؤهم",
    "أوليائكم",
    "أوليائهم",
    "أوليس",
    "أومن",
    "أوهن",
    "أوى",
    "أوينا",
    "أيأمركم",
    "أياما",
    "أيان",
    "أيبتغون",
    "أيتها",
    "أيحب",
    "أيحسب",
    "أيحسبون",
    "أيدتك",
    "أيدك",
    "أيديكم",
    "أيدينا",
    "أيديهم",
    "أيديهما",
    "أيديهن",
    "أيشركون",
    "أيطمع",
    "أيعدكم",
    "أيقاظا",
    "أيكم",
    "أيما",
    "أيمان",
    "أيمانكم",
    "أيمانهم",
    "أيمانهن",
    "أيمسكه",
    "أينا",
    "أينما",
    "أيه",
    "أيها",
    "أيهم",
    "أيوب",
    "أيود",
    "إبليس",
    "إثم",
    "إثمه",
    "إحداهما",
    "إحداهن",
    "إحسانا",
    "إخراجا",
    "إخراجكم",
    "إخراجهم",
    "إخوان",
    "إخوانا",
    "إخوانكم",
    "إخوانهم",
    "إخوانهن",
    "إخوة",
    "إخوتك",
    "إخوتي",
    "إدريس",
    "إذ",
    "إذنه",
    "إستبرق",
    "إسرارا",
    "إسرارهم",
    "إسرافا",
    "إسلامكم",
    "إسلامهم",
    "إسماعيل",
    "إصرا",
    "إصرهم",
    "إصري",
    "إصلاحا",
    "إطعام",
    "إعراضا",
    "إعراضهم",
    "إعصار",
    "إفك",
    "إفكا",
    "إفكهم",
    "إقامتكم",
    "إكراه",
    "إكراههن",
    "إلا",
    "إلحافا",
    "إلها",
    "إلهك",
    "إلهكم",
    "إلهه",
    "إلهين",
    "إلياس",
    "إليك",
    "إليكم",
    "إليكما",
    "إليهم",
    "إليهن",
    "إما",
    "إمام",
    "إماما",
    "إمرا",
    "إملاق",
    "إنا",
    "إناثا",
    "إناه",
    "إنسيا",
    "إنك",
    "إنكم",
    "إنما",
    "إننا",
    "إنني",
    "إنه",
    "إنها",
    "إنهم",
    "إنهما",
    "إنهن",
    "إني",
    "إيابهم",
    "إياك",
    "إياكم",
    "إيانا",
    "إياه",
    "إيلافهم",
    "إيمانا",
    "إيمانكم",
    "إيمانه",
    "إيمانها",
    "إيمانهم",
    "اؤتمن",
    "ائت",
    "ائتنا",
    "ائتوا",
    "ائتوني",
    "ائتيا",
    "ائذن",
    "ابتدعوها",
    "ابتغاء",
    "ابتغوا",
    "ابتغى",
    "ابتغيت",
    "ابتلاه",
    "ابعث",
    "ابلعي",
    "ابنت",
    "ابنتي",
    "ابنك",
    "ابنه",
    "ابنوا",
    "ابني",
    "اتبعت",
    "اتبعتم",
    "اتبعتني",
    "اتبعك",
    "اتبعكما",
    "اتبعن",
    "اتبعني",
    "اتبعوا",
    "اتبعوك",
    "اتبعون",
    "اتبعوه",
    "اتبعوهم",
    "اتخذ",
    "اتخذت",
    "اتخذتم",
    "اتخذها",
    "اتخذوا",
    "اتخذوني",
    "اتخذوه",
    "اتخذوها",
    "اتخذوهم",
    "اتخذي",
    "اتسق",
    "اتق",
    "اتقوا",
    "اتقى",
    "اتقيتن",
    "اتل",
    "اثاقلتم",
    "اثنا",
    "اثنان",
    "اثنتا",
    "اثنتي",
    "اثنتين",
    "اثني",
    "اثنين",
    "اجتباكم",
    "اجتباه",
    "اجتبيتها",
    "اجتثت",
    "اجترحوا",
    "اجتمعت",
    "اجتمعوا",
    "اجتنبوا",
    "اجعلني",
    "اجعلوا",
    "اجهروا",
    "احتمل",
    "احتملوا",
    "احشروا",
    "احمل",
    "اخترتك",
    "اخترناهم",
    "اختصموا",
    "اختلافا",
    "اختلاق",
    "اختلط",
    "اختلف",
    "اختلفتم",
    "اختلفوا",
    "اخرجوا",
    "اخسئوا",
    "اخلفني",
    "ادارك",
    "اداركوا",
    "ادخلا",
    "ادخلوا",
    "ادخلوها",
    "ادخلي",
    "ادعهن",
    "ادعوا",
    "ادعوني",
    "ادعوهم",
    "ادفعوا",
    "اذكر",
    "اذكرني",
    "اذكروا",
    "اذهبا",
    "اذهبوا",
    "ارتابوا",
    "ارتبتم",
    "ارتدوا",
    "ارتضى",
    "ارجع",
    "ارجعوا",
    "ارجعون",
    "ارجعي",
    "ارحمهما",
    "اركب",
    "اركبوا",
    "اركض",
    "اركعوا",
    "ازدادوا",
    "استأجرت",
    "استأجره",
    "استأذن",
    "استأذنك",
    "استأذنوك",
    "استبدال",
    "استجابوا",
    "استجارك",
    "استجيب",
    "استجيبوا",
    "استحبوا",
    "استحفظوا",
    "استحق",
    "استحقا",
    "استحوذ",
    "استخرجها",
    "استخلف",
    "استرق",
    "استزلهم",
    "استسقاه",
    "استسقى",
    "استضعفوا",
    "استضعفوني",
    "استطاع",
    "استطاعوا",
    "استطعت",
    "استطعتم",
    "استطعما",
    "استطعنا",
    "استعجالهم",
    "استعجلتم",
    "استعلى",
    "استعينوا",
    "استغفار",
    "استغفر",
    "استغفروا",
    "استغنى",
    "استقاموا",
    "استقر",
    "استكانوا",
    "استكبارا",
    "استكبر",
    "استكبرتم",
    "استكبروا",
    "استكثرتم",
    "استمتع",
    "استمتعتم",
    "استمسك",
    "استمع",
    "استمعوه",
    "استنصره",
    "استنصروكم",
    "استنكفوا",
    "استهزئ",
    "استهزئوا",
    "استهوته",
    "استوقد",
    "استوى",
    "استويت",
    "استويتم",
    "استيأس",
    "استيأسوا",
    "استيسر",
    "اسجدوا",
    "اسطاعوا",
    "اسكن",
    "اسكنوا",
    "اسلك",
    "اسمه",
    "اشتدت",
    "اشتراه",
    "اشتروا",
    "اشتملت",
    "اشتهت",
    "اشدد",
    "اشرح",
    "اشكر",
    "اشمأزت",
    "اشهدوا",
    "اصبر",
    "اصبروا",
    "اصرف",
    "اصطفاك",
    "اصطفاه",
    "اصطفى",
    "اصطفيتك",
    "اصطفينا",
    "اصطفيناه",
    "اصلوها",
    "اضرب",
    "اضربوه",
    "اضطر",
    "اضطررتم",
    "اطرحوه",
    "اطلعت",
    "اطمأن",
    "اطمأننتم",
    "اطمس",
    "اطيرنا",
    "اعبدوا",
    "اعبدوني",
    "اعتدوا",
    "اعتدى",
    "اعتدينا",
    "اعتراك",
    "اعترفوا",
    "اعتزلتموهم",
    "اعتزلهم",
    "اعتزلوكم",
    "اعتمر",
    "اعدلوا",
    "اعلموا",
    "اعمل",
    "اعملوا",
    "اغترف",
    "اغدوا",
    "اغفر",
    "افتح",
    "افتدت",
    "افتدى",
    "افتراء",
    "افتراه",
    "افترى",
    "افتريته",
    "افترينا",
    "افعل",
    "اقتتل",
    "اقتتلوا",
    "اقتحم",
    "اقتده",
    "اقتربت",
    "اقترفتموها",
    "اقتلوا",
    "اقتلوه",
    "اقذفيه",
    "اقرءوا",
    "اقرأ",
    "اقضوا",
    "اقعدوا",
    "اقنتي",
    "اكتالوا",
    "اكتتبها",
    "اكتسب",
    "اكتسبت",
    "اكتسبن",
    "اكتسبوا",
    "اكشف",
    "اكفر",
    "الآثمين",
    "الآزفة",
    "الآفلين",
    "الآمرون",
    "الآمنين",
    "الآيات",
    "الآية",
    "الأبتر",
    "الأبرار",
    "الأبصار",
    "الأتقى",
    "الأثيم",
    "الأجداث",
    "الأجلين",
    "الأحزاب",
    "الأختين",
    "الأخسرون",
    "الأخسرين",
    "الأخلاء",
    "الأخيار",
    "الأدبار",
    "الأذقان",
    "الأذل",
    "الأذلين",
    "الأرذلون",
    "الأسفلين",
    "الأشر",
    "الأشقى",
    "الأشهاد",
    "الأشهر",
    "الأعجمين",
    "الأعراب",
    "الأعز",
    "الأعلون",
    "الأعين",
    "الأغلال",
    "الأفئدة",
    "الأقاويل",
    "الأقدمون",
    "الأقربين",
    "الأكرم",
    "الأكمه",
    "الأليم",
    "الأماني",
    "الأمي",
    "الأميين",
    "الأنامل",
    "الأنثيين",
    "الأنعام",
    "الأنفال",
    "الأوفى",
    "الأولون",
    "الأوليان",
    "الأولين",
    "الأيامى",
    "الأيد",
    "الأيكة",
    "الأيمان",
    "الإبل",
    "الإربة",
    "الإصباح",
    "الإنس",
    "البأساء",
    "البائس",
    "البارئ",
    "الباطل",
    "الباقين",
    "البالغة",
    "البحران",
    "البشرى",
    "البشير",
    "البصير",
    "البطشة",
    "البغضاء",
    "البغي",
    "البلاغ",
    "البلد",
    "البنون",
    "البنين",
    "البوار",
    "البينات",
    "البينة",
    "التائبون",
    "التابعين",
    "التراقي",
    "التغابن",
    "التقتا",
    "التقى",
    "التقيتم",
    "التلاق",
    "التناد",
    "التناوش",
    "التنور",
    "التهلكة",
    "التواب",
    "التوابين",
    "التوب",
    "الثاقب",
    "الثرى",
    "الثقلان",
    "الثمرات",
    "الثواب",
    "الجاهلون",
    "الجاهلية",
    "الجاهلين",
    "الجب",
    "الجبار",
    "الجرز",
    "الجزاء",
    "الجلاء",
    "الجلال",
    "الجمعان",
    "الجنات",
    "الجنتين",
    "الجهر",
    "الجوارح",
    "الجودي",
    "الحاسبين",
    "الحافرة",
    "الحاقة",
    "الحاكمين",
    "الحامدون",
    "الحبك",
    "الحجة",
    "الحرور",
    "الحسرة",
    "الحسنى",
    "الحسنيين",
    "الحصيد",
    "الحطمة",
    "الحكيم",
    "الحلقوم",
    "الحليم",
    "الحمد",
    "الحميم",
    "الحواريين",
    "الحوايا",
    "الخائضين",
    "الخائنين",
    "الخاسرين",
    "الخاشعين",
    "الخاطئون",
    "الخاطئين",
    "الخالدين",
    "الخالص",
    "الخالفين",
    "الخالقون",
    "الخالقين",
    "الخالية",
    "الخبء",
    "الخبائث",
    "الخبيث",
    "الخبيثات",
    "الخراصون",
    "الخصام",
    "الخطفة",
    "الخلاق",
    "الخلطاء",
    "الخناس",
    "الخوالف",
    "الخيام",
    "الخيرات",
    "الخيرة",
    "الداخلين",
    "الدار",
    "الداع",
    "الدبر",
    "الدرك",
    "الدمع",
    "الدواب",
    "الديار",
    "الذكران",
    "الذكور",
    "الذلة",
    "الر",
    "الرؤيا",
    "الراجفة",
    "الراحمين",
    "الرادفة",
    "الرازقين",
    "الراسخون",
    "الراشدون",
    "الراكعون",
    "الراكعين",
    "الربانيون",
    "الرجز",
    "الرجس",
    "الرجع",
    "الرجعى",
    "الرجيم",
    "الرحمن",
    "الرحيم",
    "الرس",
    "الرسل",
    "الرسولا",
    "الرشيد",
    "الرعاء",
    "الرفث",
    "الرفد",
    "الرهب",
    "الروع",
    "الزاد",
    "الزاهدين",
    "الزبانية",
    "الزبر",
    "الزبور",
    "الزراع",
    "الزقوم",
    "الزكاة",
    "الزوجين",
    "السائحون",
    "السابقون",
    "الساجدون",
    "الساجدين",
    "الساحرون",
    "الساخرين",
    "السامري",
    "السبع",
    "السبل",
    "السبيلا",
    "السحت",
    "السدرة",
    "السدين",
    "السراء",
    "السرائر",
    "السرد",
    "السعير",
    "السفهاء",
    "السميع",
    "السنين",
    "السوأى",
    "السيئات",
    "الشافعين",
    "الشاكرين",
    "الشاهدين",
    "الشح",
    "الشكور",
    "الصابرون",
    "الصابرين",
    "الصاخة",
    "الصادقون",
    "الصادقين",
    "الصاغرين",
    "الصافنات",
    "الصافون",
    "الصالح",
    "الصالحات",
    "الصالحون",
    "الصالحين",
    "الصدع",
    "الصدفين",
    "الصديقون",
    "الصراط",
    "الصفا",
    "الصم",
    "الصمد",
    "الصيام",
    "الضأن",
    "الضالون",
    "الضالين",
    "الضر",
    "الضراء",
    "الضلالة",
    "الطائفتين",
    "الطامة",
    "الطور",
    "الطيبات",
    "الظالم",
    "الظالمون",
    "الظالمين",
    "الظانين",
    "الظلة",
    "الظمآن",
    "الظنونا",
    "العابدين",
    "العادون",
    "العادين",
    "العاقبة",
    "العاكف",
    "العالمون",
    "العالمين",
    "العالين",
    "العاملون",
    "العاملين",
    "العتيق",
    "العرم",
    "العزة",
    "العسرة",
    "العشار",
    "العشير",
    "العظيم",
    "العقيم",
    "العلقة",
    "العلى",
    "العلي",
    "العليم",
    "العماد",
    "العمي",
    "العنت",
    "العير",
    "الغائبين",
    "الغابرين",
    "الغاشية",
    "الغافرين",
    "الغافلات",
    "الغافلون",
    "الغافلين",
    "الغالبون",
    "الغالبين",
    "الغاوون",
    "الغاوين",
    "الغرفات",
    "الغفار",
    "الغفور",
    "الغلام",
    "الغمام",
    "الغني",
    "الغي",
    "الغيث",
    "الغيظ",
    "الغيوب",
    "الفؤاد",
    "الفئتان",
    "الفاتحين",
    "الفاسقين",
    "الفاصلين",
    "الفتاح",
    "الفتية",
    "الفجار",
    "الفجرة",
    "الفرحين",
    "الفردوس",
    "الفرقان",
    "الفريضة",
    "الفريقين",
    "الفلق",
    "الفواحش",
    "الفوز",
    "القادرون",
    "القارعة",
    "القاسطون",
    "القاعدون",
    "القاعدين",
    "القالين",
    "القانتين",
    "القانطين",
    "القانع",
    "القاهر",
    "القتلى",
    "القدوس",
    "القدير",
    "القربى",
    "القرنين",
    "القريتين",
    "القهار",
    "القيوم",
    "الكاذبون",
    "الكاذبين",
    "الكافرون",
    "الكافرين",
    "الكعبة",
    "الكعبين",
    "الكفر",
    "الكفور",
    "الكلالة",
    "الكلم",
    "الكوافر",
    "الكوثر",
    "الكيل",
    "اللائي",
    "اللات",
    "اللاتي",
    "اللاعبين",
    "اللذين",
    "اللمم",
    "اللهم",
    "اللهو",
    "اللوامة",
    "الم",
    "المآب",
    "المؤمنات",
    "المؤمنين",
    "الماعون",
    "الماكرين",
    "الماهدون",
    "المبثوث",
    "المبذرين",
    "المبطلون",
    "المبين",
    "المتربصين",
    "المتصدقين",
    "المتطهرين",
    "المتعال",
    "المتقون",
    "المتقين",
    "المتكبر",
    "المتكبرين",
    "المتكلفين",
    "المتلقيان",
    "المتنافسون",
    "المتوكلون",
    "المتوكلين",
    "المتين",
    "المثاني",
    "المثلات",
    "المثلى",
    "المجرمين",
    "المجيبون",
    "المجيد",
    "المحتظر",
    "المحسنين",
    "المحضرين",
    "المحيض",
    "المخاض",
    "المخبتين",
    "المخرجين",
    "المخسرين",
    "المخلصين",
    "المخلفون",
    "المدائن",
    "المدثر",
    "المدحضين",
    "المراضع",
    "المرجومين",
    "المرسلين",
    "المرفود",
    "المرفوع",
    "المزمل",
    "المزن",
    "المس",
    "المساق",
    "المسبحون",
    "المسبحين",
    "المستأخرين",
    "المستبين",
    "المستضعفين",
    "المستعان",
    "المستقدمين",
    "المستقر",
    "المستكبرين",
    "المستهزئين",
    "المسجور",
    "المسجونين",
    "المسحرين",
    "المسخر",
    "المسرفين",
    "المسكنة",
    "المسكين",
    "المسومة",
    "المسيء",
    "المشأمة",
    "المشارق",
    "المشحون",
    "المشرقين",
    "المشركات",
    "المشركون",
    "المشركين",
    "المشعر",
    "المصدقين",
    "المصطفين",
    "المصلحين",
    "المصيطرون",
    "المضاجع",
    "المضطر",
    "المضعفون",
    "المضغة",
    "المضلين",
    "المطمئنة",
    "المطهرين",
    "المطوعين",
    "المعارج",
    "المعتبين",
    "المعتدين",
    "المعذبين",
    "المعذرون",
    "المعصرات",
    "المعمور",
    "المعوقين",
    "المغربين",
    "المغرقين",
    "المغشي",
    "المغضوب",
    "المفترين",
    "المفتون",
    "المفر",
    "المفسدين",
    "المفلحون",
    "المفلحين",
    "المقامة",
    "المقبوحين",
    "المقتر",
    "المقتسمين",
    "المقربون",
    "المقربين",
    "المقسطين",
    "المقنطرة",
    "المكذبون",
    "المكذبين",
    "المكرمين",
    "المكنون",
    "المكيدون",
    "الملأ",
    "الملإ",
    "الملة",
    "الملعونة",
    "الملقين",
    "الملكين",
    "الممترين",
    "المناد",
    "المنافقين",
    "المنام",
    "المنتصرين",
    "المنتظرين",
    "المنتهى",
    "المنذرين",
    "المنزلون",
    "المنزلين",
    "المنشآت",
    "المنشئون",
    "المنصورون",
    "المنظرين",
    "المنفوش",
    "المنون",
    "المنير",
    "المهاجرين",
    "المهتد",
    "المهتدون",
    "المهتدي",
    "المهتدين",
    "المهلكين",
    "المهيمن",
    "الموءودة",
    "الموتة",
    "الموج",
    "المورود",
    "الموعود",
    "الموقدة",
    "الموقنين",
    "الميعاد",
    "النادمين",
    "الناصرين",
    "الناظرين",
    "الناقة",
    "الناقور",
    "النبإ",
    "النبوة",
    "النبيون",
    "النبيين",
    "النجدين",
    "النجوى",
    "الندامة",
    "النسوة",
    "النسيء",
    "النشأة",
    "النشور",
    "النصارى",
    "النطفة",
    "النفاثات",
    "النفوس",
    "النهى",
    "النون",
    "الهالكين",
    "الهدى",
    "الهدي",
    "الهون",
    "الهيم",
    "الواد",
    "الوارثون",
    "الوارثين",
    "الواعظين",
    "الواقعة",
    "الوالدان",
    "الوالدين",
    "الوتين",
    "الوثاق",
    "الوثقى",
    "الودق",
    "الوعيد",
    "الولدان",
    "الوهاب",
    "الويل",
    "اليسر",
    "اليم",
    "امتحن",
    "امتلأت",
    "امرأ",
    "امرأت",
    "امرأتك",
    "امرأته",
    "امرأتي",
    "امرأتين",
    "امرؤ",
    "امرئ",
    "امشوا",
    "امكثوا",
    "انبعاثهم",
    "انبعث",
    "انتبذت",
    "انتثرت",
    "انتصر",
    "انتظروا",
    "انتقام",
    "انتقمنا",
    "انتهوا",
    "انسلخ",
    "انشزوا",
    "انشقت",
    "انصرفوا",
    "انصرني",
    "انطلقتم",
    "انطلقوا",
    "انظرنا",
    "انظروا",
    "انظرونا",
    "انفخوا",
    "انفروا",
    "انفصام",
    "انفضوا",
    "انفطرت",
    "انقص",
    "انقلبتم",
    "انقلبوا",
    "انكدرت",
    "اهبط",
    "اهبطا",
    "اهبطوا",
    "اهتدوا",
    "اهتدى",
    "اهتديت",
    "اهتديتم",
    "اهتزت",
    "اهدنا",
    "بآبائنا",
    "بآخذيه",
    "بآخرين",
    "بآل",
    "بآلهتنا",
    "بآنية",
    "بآيات",
    "بآياتنا",
    "بآياته",
    "بآياتي",
    "بآية",
    "بأبصارهم",
    "بأحسن",
    "بأحسنها",
    "بأحكم",
    "بأخ",
    "بأخيك",
    "بأربعة",
    "بأرجلهن",
    "بأسا",
    "بأسكم",
    "بأسماء",
    "بأسمائهم",
    "بأسنا",
    "بأسه",
    "بأسهم",
    "بأشياعهم",
    "بأصحاب",
    "بأعدائكم",
    "بأعلم",
    "بأعيننا",
    "بأفواهكم",
    "بأفواههم",
    "بأكواب",
    "بألسنة",
    "بألسنتكم",
    "بألسنتهم",
    "بأمانيكم",
    "بأمر",
    "بأمرنا",
    "بأمره",
    "بأمرهم",
    "بأموال",
    "بأموالكم",
    "بأموالهم",
    "بأنا",
    "بأنعام",
    "بأنعم",
    "بأنفسهم",
    "بأنفسهن",
    "بأنكم",
    "بأننا",
    "بأنه",
    "بأنهم",
    "بأهدى",
    "بأهلك",
    "بأهلكم",
    "بأهله",
    "بأهوائهم",
    "بأوعيتهم",
    "بأيام",
    "بأيد",
    "بأيدي",
    "بأيديكم",
    "بأيدينا",
    "بأيديهم",
    "بأييكم",
    "بإبراهيم",
    "بإثمي",
    "بإخراج",
    "بإذنه",
    "بإذني",
    "بإسحاق",
    "بإلحاد",
    "بإمامهم",
    "بإيمانكم",
    "بإيمانهم",
    "بإيمانهن",
    "بئس",
    "بئسما",
    "بئيس",
    "باء",
    "باب",
    "باتخاذكم",
    "باخع",
    "بادون",
    "بادي",
    "بارئكم",
    "بارزون",
    "باركنا",
    "بازغا",
    "بازغة",
    "باسرة",
    "باسط",
    "باسطو",
    "باسقات",
    "باشروهن",
    "باطنه",
    "باعد",
    "باغ",
    "باقية",
    "بال",
    "بالآخرة",
    "بالآيات",
    "بالأبصار",
    "بالأحقاف",
    "بالأخسرين",
    "بالأزلام",
    "بالأسحار",
    "بالأفق",
    "بالألقاب",
    "بالأمن",
    "بالأنثى",
    "بالإثم",
    "بالإفك",
    "بالبأساء",
    "بالباطل",
    "بالبخل",
    "بالبر",
    "بالبشرى",
    "بالبصر",
    "بالبنين",
    "بالبيت",
    "بالبينات",
    "بالتوراة",
    "بالتي",
    "بالجبت",
    "بالجنب",
    "بالجنة",
    "بالجنود",
    "بالحج",
    "بالحجاب",
    "بالحر",
    "بالحسنات",
    "بالحسنة",
    "بالحسنى",
    "بالحق",
    "بالحكمة",
    "بالخاطئة",
    "بالخلق",
    "بالخنس",
    "بالخيرات",
    "بالدهن",
    "بالدين",
    "بالذكر",
    "بالذي",
    "بالذين",
    "بالرحمن",
    "بالرسل",
    "بالساحل",
    "بالساق",
    "بالساهرة",
    "بالسنين",
    "بالسوء",
    "بالسوق",
    "بالسيئة",
    "بالشاكرين",
    "بالشفق",
    "بالشمس",
    "بالشهادة",
    "بالشهداء",
    "بالشهر",
    "بالصالحين",
    "بالصبر",
    "بالصدق",
    "بالصلاة",
    "بالطاغوت",
    "بالطاغية",
    "بالطيب",
    "بالظالمين",
    "بالعباد",
    "بالعبد",
    "بالعدل",
    "بالعدوة",
    "بالعذاب",
    "بالعراء",
    "بالعرف",
    "بالعروة",
    "بالعشي",
    "بالعصبة",
    "بالعقود",
    "بالعمرة",
    "بالعهد",
    "بالغداة",
    "بالغدو",
    "بالغمام",
    "بالغوه",
    "بالغيب",
    "بالغيه",
    "بالفتح",
    "بالفحشاء",
    "بالقارعة",
    "بالقرآن",
    "بالقسط",
    "بالقسطاس",
    "بالقعود",
    "بالقول",
    "بالكافرين",
    "بالكتاب",
    "بالكفر",
    "باللغو",
    "بالليل",
    "بالمؤمنين",
    "بالمتقين",
    "بالمجرمين",
    "بالمرحمة",
    "بالمعتدين",
    "بالمعروف",
    "بالمغفرة",
    "بالمفسدين",
    "بالملإ",
    "بالملائكة",
    "بالمن",
    "بالمنكر",
    "بالمهتدين",
    "بالمودة",
    "بالناصية",
    "بالنبيين",
    "بالنذر",
    "بالنهار",
    "بالنواصي",
    "بالهدى",
    "بالهزل",
    "بالهم",
    "بالواد",
    "بالوحي",
    "بالوصيد",
    "بالوعيد",
    "باليمين",
    "باليوم",
    "بايعتم",
    "ببابل",
    "بباسط",
    "ببالغه",
    "ببالغيه",
    "ببدر",
    "ببدنك",
    "ببضاعة",
    "ببطن",
    "ببعيد",
    "ببغيهم",
    "ببكة",
    "ببني",
    "ببنيه",
    "ببهتان",
    "ببيعكم",
    "ببينة",
    "بتأويل",
    "بتأويله",
    "بتابع",
    "بتاركي",
    "بتحية",
    "بثالث",
    "بثمره",
    "بثي",
    "بجالوت",
    "بجانبه",
    "بجبار",
    "بجناحيه",
    "بجنتيهم",
    "بجنود",
    "بجنوده",
    "بجهازهم",
    "بجهالة",
    "بجهنم",
    "بحاملين",
    "بحجارة",
    "بحديث",
    "بحرب",
    "بحسبان",
    "بحفيظ",
    "بحكمه",
    "بحمد",
    "بحمدك",
    "بحمده",
    "بحور",
    "بخارج",
    "بخارجين",
    "بخازنين",
    "بخالصة",
    "بخبر",
    "بخسا",
    "بخلاقكم",
    "بخلاقهم",
    "بخلق",
    "بخلقهن",
    "بخلوا",
    "بخمرهن",
    "بخمسة",
    "بخير",
    "بخيلك",
    "بدءوكم",
    "بدأكم",
    "بدأنا",
    "بدت",
    "بدخان",
    "بدعا",
    "بدعاء",
    "بدعائك",
    "بدلنا",
    "بدلناهم",
    "بدله",
    "بدلوا",
    "بدم",
    "بديع",
    "بدينار",
    "بدينكم",
    "بذبح",
    "بذكر",
    "بذكرهم",
    "بذنبه",
    "بذنبهم",
    "بذنوب",
    "بذنوبكم",
    "بذنوبنا",
    "بذنوبهم",
    "برءوسكم",
    "برآء",
    "برأسي",
    "براء",
    "برازقين",
    "برب",
    "بربك",
    "بربكم",
    "بربنا",
    "بربه",
    "بربهم",
    "بربوة",
    "بربي",
    "برجال",
    "برجلك",
    "برحمتك",
    "برحمتنا",
    "برحمته",
    "بردا",
    "بردهن",
    "بررة",
    "برزخ",
    "برزخا",
    "برزق",
    "برزوا",
    "برسالاتي",
    "برسل",
    "برسلنا",
    "برسلي",
    "برسول",
    "برسوله",
    "برسولهم",
    "برشيد",
    "برقه",
    "بركات",
    "بركنه",
    "برهانان",
    "برهانكم",
    "بروج",
    "بروجا",
    "بريئا",
    "بريئون",
    "بريح",
    "بزعمهم",
    "بزينة",
    "بسؤال",
    "بسا",
    "بساحتهم",
    "بساطا",
    "بسحر",
    "بسحرك",
    "بسحره",
    "بسحرهما",
    "بسطة",
    "بسطت",
    "بسكارى",
    "بسلطان",
    "بسم",
    "بسمعهم",
    "بسور",
    "بسورة",
    "بسيماهم",
    "بشر",
    "بشرا",
    "بشراكم",
    "بشرر",
    "بشركائهم",
    "بشرككم",
    "بشرناك",
    "بشرى",
    "بشق",
    "بشماله",
    "بشهاب",
    "بشهاداتهم",
    "بشهيد",
    "بشيء",
    "بشير",
    "بشيرا",
    "بصائر",
    "بصاحبكم",
    "بصاحبهم",
    "بصحاف",
    "بصدقة",
    "بصدقهم",
    "بصرت",
    "بصره",
    "بصلاتك",
    "بصوتك",
    "بصيرا",
    "بضارهم",
    "بضارين",
    "بضاعة",
    "بضاعتنا",
    "بضاعتهم",
    "بضر",
    "بضع",
    "بضنين",
    "بضياء",
    "بطائنها",
    "بطارد",
    "بطانة",
    "بطرا",
    "بطرت",
    "بطريقتكم",
    "بطش",
    "بطشا",
    "بطشتم",
    "بطشتنا",
    "بطغواها",
    "بطنه",
    "بطون",
    "بطونه",
    "بطونها",
    "بطونهم",
    "بظاهر",
    "بظلام",
    "بظلمهم",
    "بعاد",
    "بعالمين",
    "بعبادة",
    "بعبادتهم",
    "بعباده",
    "بعبادي",
    "بعبده",
    "بعثرت",
    "بعثكم",
    "بعثنا",
    "بعثناكم",
    "بعثناهم",
    "بعثه",
    "بعجل",
    "بعدا",
    "بعدت",
    "بعدتهم",
    "بعدك",
    "بعدكم",
    "بعده",
    "بعدها",
    "بعدهم",
    "بعدهن",
    "بعذابكم",
    "بعرشها",
    "بعزة",
    "بعزيز",
    "بعشر",
    "بعصاك",
    "بعصم",
    "بعضا",
    "بعضكم",
    "بعضنا",
    "بعضه",
    "بعضهم",
    "بعلا",
    "بعلمه",
    "بعلها",
    "بعلي",
    "بعهد",
    "بعهدكم",
    "بعهده",
    "بعهدهم",
    "بعهدي",
    "بعورة",
    "بعوضة",
    "بعولتهن",
    "بعير",
    "بعيسى",
    "بغائبين",
    "بغافل",
    "بغت",
    "بغتة",
    "بغلام",
    "بغم",
    "بغى",
    "بغي",
    "بغيا",
    "بغيظكم",
    "بغيظهم",
    "بغيكم",
    "بفاتنين",
    "بفاحشة",
    "بفاكهة",
    "بفضل",
    "بقادر",
    "بقبس",
    "بقدر",
    "بقدرها",
    "بقرآن",
    "بقرات",
    "بقربان",
    "بقرة",
    "بقريب",
    "بقلها",
    "بقميصي",
    "بقنطار",
    "بقول",
    "بقوم",
    "بقي",
    "بقية",
    "بقيت",
    "بقيعة",
    "بكأس",
    "بكاف",
    "بكافرين",
    "بكاهن",
    "بكت",
    "بكتاب",
    "بكتابكم",
    "بكتابي",
    "بكفرك",
    "بكفرهم",
    "بكلماته",
    "بكلمة",
    "بكم",
    "بكيدهن",
    "بل",
    "بلاء",
    "بلاغ",
    "بلاغا",
    "بلحيتي",
    "بلدا",
    "بلسان",
    "بلسانك",
    "بلغا",
    "بلغت",
    "بلغن",
    "بلغني",
    "بلغوا",
    "بلقاء",
    "بلونا",
    "بلوناهم",
    "بلى",
    "بليغا",
    "بليل",
    "بمؤمن",
    "بمؤمنين",
    "بمال",
    "بمبعوثين",
    "بمثل",
    "بمثله",
    "بمثلها",
    "بمجنون",
    "بمخرجين",
    "بمزحزحه",
    "بمسبوقين",
    "بمستيقنين",
    "بمسمع",
    "بمصابيح",
    "بمصر",
    "بمصرخكم",
    "بمصرخي",
    "بمصيطر",
    "بمعجز",
    "بمعجزين",
    "بمعذبين",
    "بمعروف",
    "بمغفرة",
    "بمفازة",
    "بمفازتهم",
    "بمقعدهم",
    "بمكرهن",
    "بملكنا",
    "بملوم",
    "بمن",
    "بمنشرين",
    "بمواقع",
    "بموسى",
    "بميت",
    "بميتين",
    "بميثاقهم",
    "بنا",
    "بناتك",
    "بناصيتها",
    "بنان",
    "بنانه",
    "بناها",
    "بنبإ",
    "بنخل",
    "بنصب",
    "بنصر",
    "بنصره",
    "بنعمة",
    "بنعمت",
    "بنعمته",
    "بنفسك",
    "بنميم",
    "بنو",
    "بنوا",
    "بنورهم",
    "بنون",
    "بنيانا",
    "بنيانه",
    "بنيانهم",
    "بنيناها",
    "بنيه",
    "بهاد",
    "بهادي",
    "بهتان",
    "بهتانا",
    "بهدية",
    "بهديتكم",
    "بهما",
    "بهن",
    "بهيمة",
    "بوأنا",
    "بواحدة",
    "بواد",
    "بوالدتي",
    "بوالديه",
    "بوجهه",
    "بوجوهكم",
    "بورا",
    "بورقكم",
    "بوكيل",
    "بولده",
    "بولدها",
    "بياتا",
    "بيانه",
    "بيتا",
    "بيتك",
    "بيته",
    "بيتها",
    "بيحيى",
    "بيدك",
    "بيدي",
    "بيمينك",
    "بيمينه",
    "بينات",
    "بيناه",
    "بينة",
    "بينت",
    "بينك",
    "بينكم",
    "بيننا",
    "بينهم",
    "بينهن",
    "بيوتا",
    "بيوتكم",
    "بيوتكن",
    "بيوتنا",
    "بيوتهم",
    "بيوتهن",
    "بيوسف",
    "تأتنا",
    "تأتهم",
    "تأتوا",
    "تأتوننا",
    "تأتوني",
    "تأتيكم",
    "تأتينا",
    "تأتيهم",
    "تأثيم",
    "تأثيما",
    "تأجرني",
    "تأخذ",
    "تأخذكم",
    "تأخذه",
    "تأخذهم",
    "تأخذوا",
    "تأخذونه",
    "تأخذونها",
    "تأذن",
    "تأس",
    "تأسوا",
    "تأكل",
    "تأكله",
    "تأكلوا",
    "تأكلون",
    "تأكلوها",
    "تألمون",
    "تأمرك",
    "تأمرنا",
    "تأمرهم",
    "تأمرون",
    "تأمروننا",
    "تأمروني",
    "تأمرين",
    "تأمنا",
    "تأمنه",
    "تأويل",
    "تأويلا",
    "تأويله",
    "تؤاخذنا",
    "تؤاخذني",
    "تؤتوا",
    "تؤتون",
    "تؤتونهن",
    "تؤتوه",
    "تؤتي",
    "تؤثرون",
    "تؤدوا",
    "تؤذوا",
    "تؤذونني",
    "تؤزهم",
    "تؤفكون",
    "تؤمر",
    "تؤمرون",
    "تؤمن",
    "تؤمنوا",
    "تؤمنون",
    "تؤويه",
    "تائبات",
    "تاب",
    "تابوا",
    "تارة",
    "تارك",
    "تالله",
    "تباب",
    "تبارا",
    "تبارك",
    "تباشروهن",
    "تبايعتم",
    "تبت",
    "تبتئس",
    "تبتغوا",
    "تبتغون",
    "تبتغي",
    "تبتم",
    "تبتيلا",
    "تبخسوا",
    "تبخلوا",
    "تبد",
    "تبدوا",
    "تبدون",
    "تبدونها",
    "تبدوه",
    "تبديلا",
    "تبذر",
    "تبذيرا",
    "تبرءوا",
    "تبرأنا",
    "تبرج",
    "تبرجن",
    "تبرنا",
    "تبروا",
    "تبروهم",
    "تبسطها",
    "تبسل",
    "تبشرون",
    "تبصرة",
    "تبصرون",
    "تبطلوا",
    "تبع",
    "تبعا",
    "تبعثون",
    "تبعك",
    "تبعني",
    "تبعوا",
    "تبغ",
    "تبغوا",
    "تبغونها",
    "تبغي",
    "تبكون",
    "تبلغ",
    "تبلو",
    "تبلى",
    "تبوء",
    "تبوءوا",
    "تبوآ",
    "تبوئ",
    "تبور",
    "تبيانا",
    "تبيد",
    "تبيض",
    "تبيعا",
    "تبين",
    "تبينت",
    "تتبدلوا",
    "تتبعان",
    "تتبعن",
    "تتبعها",
    "تتبعوا",
    "تتبعون",
    "تتبعونا",
    "تتبيب",
    "تتبيرا",
    "تتجافى",
    "تتخذ",
    "تتخذوا",
    "تتخذون",
    "تتذكرون",
    "تتركه",
    "تتركوا",
    "تترى",
    "تتفرقوا",
    "تتفكروا",
    "تتفكرون",
    "تتقلب",
    "تتقوا",
    "تتقون",
    "تتكبر",
    "تتلو",
    "تتلى",
    "تتمارى",
    "تتمنوا",
    "تتناجوا",
    "تتنزل",
    "تتوبا",
    "تتوفاهم",
    "تتولوا",
    "تثبيتا",
    "تثريب",
    "تثقفنهم",
    "تثير",
    "تجأروا",
    "تجأرون",
    "تجادلك",
    "تجادلوا",
    "تجارتهم",
    "تجتنبوا",
    "تجد",
    "تجدوا",
    "تجدوه",
    "تجرمون",
    "تجري",
    "تجريان",
    "تجزون",
    "تجزى",
    "تجزي",
    "تجسسوا",
    "تجعلنا",
    "تجعلني",
    "تجعلوا",
    "تجعلونه",
    "تجلى",
    "تجمعوا",
    "تجهر",
    "تجهروا",
    "تجهلون",
    "تجوع",
    "تحاجون",
    "تحاضون",
    "تحاوركما",
    "تحبرون",
    "تحبسونهما",
    "تحبط",
    "تحبوا",
    "تحبون",
    "تحبونها",
    "تحبونهم",
    "تحتك",
    "تحتها",
    "تحتهم",
    "تحذرون",
    "تحرثون",
    "تحرص",
    "تحرم",
    "تحرموا",
    "تحروا",
    "تحزن",
    "تحزنوا",
    "تحزنون",
    "تحزني",
    "تحس",
    "تحسبن",
    "تحسبنهم",
    "تحسبها",
    "تحسبهم",
    "تحسبوه",
    "تحسدوننا",
    "تحسنوا",
    "تحسونهم",
    "تحشرون",
    "تحصنا",
    "تحصنون",
    "تحصوه",
    "تحصوها",
    "تحط",
    "تحكموا",
    "تحكمون",
    "تحل",
    "تحلة",
    "تحلقوا",
    "تحلوا",
    "تحملنا",
    "تحمله",
    "تحملون",
    "تحنث",
    "تحويلا",
    "تحية",
    "تحيتهم",
    "تحيد",
    "تحيطوا",
    "تحيون",
    "تحيي",
    "تخاصم",
    "تخاطبني",
    "تخاف",
    "تخافا",
    "تخافت",
    "تخافن",
    "تخافوا",
    "تخافون",
    "تخافونهم",
    "تخافوهم",
    "تخافي",
    "تخالطوهم",
    "تختانون",
    "تختصموا",
    "تختصمون",
    "تختلفون",
    "تخرجوا",
    "تخرجون",
    "تخرجوهن",
    "تخرصون",
    "تخرق",
    "تخزنا",
    "تخزني",
    "تخزون",
    "تخسروا",
    "تخسير",
    "تخشاه",
    "تخشع",
    "تخشوا",
    "تخشون",
    "تخشوه",
    "تخشوهم",
    "تخشى",
    "تخضعن",
    "تخطه",
    "تخف",
    "تخفوا",
    "تخفون",
    "تخفوه",
    "تخفوها",
    "تخفي",
    "تخلدون",
    "تخلفه",
    "تخلق",
    "تخلقونه",
    "تخوف",
    "تخونوا",
    "تخويفا",
    "تخيرون",
    "تداركه",
    "تداينتم",
    "تدخرون",
    "تدخلوا",
    "تدخلوها",
    "تدرسون",
    "تدرك",
    "تدركه",
    "تدرون",
    "تدري",
    "تدع",
    "تدعهم",
    "تدعو",
    "تدعوا",
    "تدعون",
    "تدعونا",
    "تدعوننا",
    "تدعونني",
    "تدعونه",
    "تدعوهم",
    "تدعى",
    "تدميرا",
    "تدهن",
    "تديرونها",
    "تذبحوا",
    "تذر",
    "تذرن",
    "تذرني",
    "تذرهم",
    "تذروه",
    "تذكرة",
    "تذكروا",
    "تذكرون",
    "تذليلا",
    "تذهب",
    "تذهبوا",
    "تذهبون",
    "تذهل",
    "تذودان",
    "تر",
    "تراءت",
    "تراءى",
    "تراب",
    "تراض",
    "تراضوا",
    "تراضيتم",
    "تراني",
    "تراهم",
    "تراود",
    "تربص",
    "تربصوا",
    "تربصون",
    "ترتابوا",
    "ترتدوا",
    "ترتيلا",
    "ترثوا",
    "ترجع",
    "ترجعون",
    "ترجعونها",
    "ترجعوهن",
    "ترجف",
    "ترجمون",
    "ترجو",
    "ترجون",
    "ترجوها",
    "ترجي",
    "ترحمون",
    "ترد",
    "تردن",
    "تردون",
    "ترزقانه",
    "ترضاه",
    "ترضاها",
    "ترضوا",
    "ترضون",
    "ترضونها",
    "ترضى",
    "ترفعوا",
    "ترقب",
    "ترقى",
    "تركبون",
    "تركت",
    "تركتم",
    "تركتموها",
    "تركضوا",
    "تركن",
    "تركنا",
    "تركناها",
    "تركنوا",
    "تركوا",
    "ترمي",
    "ترميهم",
    "ترن",
    "ترهبون",
    "ترهقني",
    "ترهقها",
    "ترهقهم",
    "تروا",
    "ترون",
    "ترونها",
    "ترونهم",
    "تروها",
    "تريحون",
    "تريد",
    "تريدون",
    "ترين",
    "تريني",
    "تزال",
    "تزاور",
    "تزد",
    "تزداد",
    "تزدري",
    "تزر",
    "تزرعون",
    "تزرعونه",
    "تزعمون",
    "تزغ",
    "تزكوا",
    "تزكى",
    "تزولا",
    "تزيدونني",
    "تزيلوا",
    "تسأل",
    "تسألن",
    "تسألني",
    "تسألهم",
    "تسألوا",
    "تسألون",
    "تسأموا",
    "تسؤكم",
    "تسؤهم",
    "تساءلون",
    "تسبح",
    "تسبحون",
    "تسبق",
    "تسبوا",
    "تسبيحهم",
    "تستأخرون",
    "تستأنسوا",
    "تستترون",
    "تستخفونها",
    "تسترضعوا",
    "تستطع",
    "تستطيع",
    "تستطيعوا",
    "تستطيعون",
    "تستعجل",
    "تستعجلون",
    "تستعجلوه",
    "تستغفر",
    "تستغفرون",
    "تستغيثون",
    "تستفت",
    "تستفتحوا",
    "تستفتيان",
    "تستقدمون",
    "تستقسموا",
    "تستكبرون",
    "تستكثر",
    "تستمعون",
    "تستهزئون",
    "تستوي",
    "تسجد",
    "تسجدوا",
    "تسحرون",
    "تسخروا",
    "تسخرون",
    "تسر",
    "تسرحون",
    "تسرفوا",
    "تسرون",
    "تسطع",
    "تسعا",
    "تسعى",
    "تسفكون",
    "تسقط",
    "تسقى",
    "تسقي",
    "تسكن",
    "تسكنون",
    "تسلمون",
    "تسليما",
    "تسمع",
    "تسمعوا",
    "تسمعون",
    "تسنيم",
    "تسوروا",
    "تسوى",
    "تسيمون",
    "تشاء",
    "تشاءون",
    "تشابهت",
    "تشاقون",
    "تشتروا",
    "تشتهي",
    "تشتهيه",
    "تشخص",
    "تشربون",
    "تشرك",
    "تشركوا",
    "تشركون",
    "تشطط",
    "تشعرون",
    "تشقق",
    "تشكروا",
    "تشكرون",
    "تشمت",
    "تشهد",
    "تشهدون",
    "تصاحبني",
    "تصبحون",
    "تصبر",
    "تصبروا",
    "تصبك",
    "تصبكم",
    "تصبهم",
    "تصدق",
    "تصدقوا",
    "تصدقون",
    "تصدون",
    "تصدونا",
    "تصدى",
    "تصرفون",
    "تصطلون",
    "تصعدون",
    "تصعر",
    "تصف",
    "تصفون",
    "تصل",
    "تصلحوا",
    "تصلى",
    "تصنعون",
    "تصوموا",
    "تصيبن",
    "تصيبنا",
    "تصيبهم",
    "تصيبوا",
    "تصير",
    "تضار",
    "تضاروهن",
    "تضحكون",
    "تضحى",
    "تضربوا",
    "تضرعا",
    "تضرعوا",
    "تضرونه",
    "تضروه",
    "تضعوا",
    "تضعون",
    "تضل",
    "تضلوا",
    "تضليل",
    "تطئوها",
    "تطئوهم",
    "تطرد",
    "تطع",
    "تطعمون",
    "تطعه",
    "تطعهما",
    "تطغوا",
    "تطلع",
    "تطمئن",
    "تطهرن",
    "تطهرهم",
    "تطهيرا",
    "تطيرنا",
    "تطيعوا",
    "تطيعوه",
    "تظاهرا",
    "تظاهرون",
    "تظلم",
    "تظلموا",
    "تظلمون",
    "تظمأ",
    "تظن",
    "تظهرون",
    "تعاسرتم",
    "تعالوا",
    "تعالى",
    "تعاونوا",
    "تعبثون",
    "تعبد",
    "تعبدوا",
    "تعبدون",
    "تعبرون",
    "تعتدوا",
    "تعتدونها",
    "تعتدوها",
    "تعتذروا",
    "تعثوا",
    "تعجب",
    "تعجبك",
    "تعجبون",
    "تعجل",
    "تعدلوا",
    "تعدنا",
    "تعدوا",
    "تعدون",
    "تعذب",
    "تعذبهم",
    "تعرضن",
    "تعرضوا",
    "تعرضون",
    "تعرف",
    "تعرفهم",
    "تعرى",
    "تعزموا",
    "تعضلوهن",
    "تعظون",
    "تعفوا",
    "تعقلون",
    "تعلمن",
    "تعلمها",
    "تعلمهم",
    "تعلموا",
    "تعلمون",
    "تعلمونهم",
    "تعلمونهن",
    "تعلموهم",
    "تعلنون",
    "تعلوا",
    "تعمدت",
    "تعملون",
    "تعمى",
    "تعودوا",
    "تعودون",
    "تعولوا",
    "تغتسلوا",
    "تغرنكم",
    "تغشاها",
    "تغفر",
    "تغفلون",
    "تغلبون",
    "تغلوا",
    "تغمضوا",
    "تغن",
    "تغني",
    "تغيض",
    "تغيظا",
    "تفادوهم",
    "تفتأ",
    "تفتروا",
    "تفترون",
    "تفتنون",
    "تفتني",
    "تفثهم",
    "تفجر",
    "تفجيرا",
    "تفرح",
    "تفرحوا",
    "تفرحون",
    "تفرضوا",
    "تفرق",
    "تفرقوا",
    "تفرون",
    "تفسحوا",
    "تفسدوا",
    "تفسقون",
    "تفسيرا",
    "تفشلا",
    "تفصيلا",
    "تفضحون",
    "تفضيلا",
    "تفعل",
    "تفعلوا",
    "تفعلون",
    "تفعلوه",
    "تفقدون",
    "تفقهون",
    "تفكهون",
    "تفلحوا",
    "تفلحون",
    "تفندون",
    "تفور",
    "تفيء",
    "تفيض",
    "تفيضون",
    "تق",
    "تقاة",
    "تقاتل",
    "تقاتلوا",
    "تقاتلون",
    "تقاتلونهم",
    "تقاتلوهم",
    "تقاته",
    "تقاسموا",
    "تقبلوا",
    "تقتلني",
    "تقتلوا",
    "تقتلون",
    "تقتلوه",
    "تقتلوهم",
    "تقتيلا",
    "تقدروا",
    "تقدموا",
    "تقديرا",
    "تقر",
    "تقربا",
    "تقربكم",
    "تقربوا",
    "تقربون",
    "تقربوها",
    "تقربوهن",
    "تقرضهم",
    "تقرضوا",
    "تقسطوا",
    "تقسموا",
    "تقشعر",
    "تقصروا",
    "تقصص",
    "تقضي",
    "تقعد",
    "تقعدوا",
    "تقف",
    "تقل",
    "تقلبهم",
    "تقلبون",
    "تقم",
    "تقنطوا",
    "تقهر",
    "تقواهم",
    "تقولن",
    "تقوله",
    "تقولوا",
    "تقولون",
    "تقوموا",
    "تقوى",
    "تقيا",
    "تقيكم",
    "تقيموا",
    "تك",
    "تكاد",
    "تكبيرا",
    "تكتبوه",
    "تكتبوها",
    "تكتموا",
    "تكتمون",
    "تكتمونه",
    "تكذبان",
    "تكذبوا",
    "تكذبون",
    "تكذيب",
    "تكرمون",
    "تكره",
    "تكرهوا",
    "تكسبون",
    "تكفر",
    "تكفروا",
    "تكفرون",
    "تكلف",
    "تكلمهم",
    "تكلمون",
    "تكليما",
    "تكن",
    "تكنزون",
    "تكونا",
    "تكونن",
    "تكونوا",
    "تلاها",
    "تلاوته",
    "تلبثوا",
    "تلبسوا",
    "تلبسون",
    "تلبسونها",
    "تلظى",
    "تلفح",
    "تلقاء",
    "تلقف",
    "تلقوا",
    "تلقون",
    "تلقونه",
    "تلقوه",
    "تلكم",
    "تلكما",
    "تلمزوا",
    "تلهكم",
    "تلهى",
    "تلهيهم",
    "تلوته",
    "تلوموني",
    "تلووا",
    "تلوون",
    "تليت",
    "تلين",
    "تمار",
    "تمت",
    "تمترن",
    "تمترون",
    "تمتعوا",
    "تمتعون",
    "تمدن",
    "تمرحون",
    "تمسسكم",
    "تمسسه",
    "تمسكوا",
    "تمسكوهن",
    "تمسنا",
    "تمسون",
    "تمسوها",
    "تمسوهن",
    "تمشون",
    "تمشي",
    "تمكرون",
    "تملكهم",
    "تملكون",
    "تملى",
    "تمنعهم",
    "تمنن",
    "تمنها",
    "تمنوا",
    "تمنون",
    "تمنى",
    "تمهيدا",
    "تموت",
    "تموتن",
    "تموتون",
    "تميد",
    "تميلوا",
    "تنابزوا",
    "تناجيتم",
    "تنازعتم",
    "تنازعوا",
    "تناصرون",
    "تناله",
    "تنالوا",
    "تنبئهم",
    "تنبئونه",
    "تنبت",
    "تنبتوا",
    "تنتشرون",
    "تنتصران",
    "تنته",
    "تنتهوا",
    "تنجيكم",
    "تنحتون",
    "تنذر",
    "تنذرهم",
    "تنزع",
    "تنزل",
    "تنزلت",
    "تنزيلا",
    "تنسوا",
    "تنسى",
    "تنصروا",
    "تنصرون",
    "تنصروه",
    "تنطقون",
    "تنظرون",
    "تنفد",
    "تنفذوا",
    "تنفذون",
    "تنفروا",
    "تنفع",
    "تنفعكم",
    "تنفعها",
    "تنفعهم",
    "تنفقوا",
    "تنفقون",
    "تنقص",
    "تنقصوا",
    "تنقضوا",
    "تنقم",
    "تنقمون",
    "تنكح",
    "تنكحوا",
    "تنكحوهن",
    "تنكرون",
    "تنكصون",
    "تنكيلا",
    "تنهر",
    "تنهرهما",
    "تنهون",
    "تنهى",
    "تنيا",
    "تهتدوا",
    "تهتدون",
    "تهتز",
    "تهجرون",
    "تهدوا",
    "تهدي",
    "تهنوا",
    "تهوى",
    "تهوي",
    "تواب",
    "توابا",
    "توارت",
    "تواعدتم",
    "تواعدوهن",
    "توبتهم",
    "توبوا",
    "توجل",
    "توجه",
    "تورون",
    "توسوس",
    "توصون",
    "توصية",
    "توعدون",
    "توعظون",
    "توفاهم",
    "توفته",
    "توفتهم",
    "توفني",
    "توفون",
    "توفى",
    "توفيتني",
    "توفيقي",
    "توقدون",
    "توقنون",
    "توكلت",
    "توكلنا",
    "توكلوا",
    "توكيدها",
    "تولاه",
    "تولج",
    "تولوا",
    "تولون",
    "تولوهم",
    "توليتم",
    "تيأسوا",
    "تيسر",
    "تيمموا",
    "ثاويا",
    "ثبتناك",
    "ثبوتها",
    "ثبورا",
    "ثجاجا",
    "ثقالا",
    "ثقفتموهم",
    "ثقفوا",
    "ثقلت",
    "ثقيلا",
    "ثلاثين",
    "ثلثا",
    "ثمانين",
    "ثمرات",
    "ثمره",
    "ثمنا",
    "ثمود",
    "ثواب",
    "ثوابا",
    "ثيابا",
    "ثيابكم",
    "ثيابهم",
    "ثيابهن",
    "ثيبات",
    "جئت",
    "جئتك",
    "جئتكم",
    "جئتم",
    "جئتمونا",
    "جئتنا",
    "جئتهم",
    "جئنا",
    "جئناك",
    "جئناكم",
    "جئناهم",
    "جاءت",
    "جاءتك",
    "جاءتكم",
    "جاءتنا",
    "جاءته",
    "جاءتها",
    "جاءتهم",
    "جاءك",
    "جاءكم",
    "جاءنا",
    "جاءني",
    "جاءه",
    "جاءها",
    "جاءهم",
    "جاءوا",
    "جاءوك",
    "جاءوكم",
    "جاءوها",
    "جابوا",
    "جاثمين",
    "جاثية",
    "جادلتم",
    "جادلتنا",
    "جادلوك",
    "جار",
    "جارية",
    "جاز",
    "جاعلك",
    "جالوت",
    "جامدة",
    "جان",
    "جاهد",
    "جاهداك",
    "جاهدوا",
    "جاهلون",
    "جاوزا",
    "جاوزه",
    "جبارا",
    "جبارين",
    "جباههم",
    "جبلا",
    "جثيا",
    "جحدوا",
    "جحيم",
    "جدارا",
    "جدالنا",
    "جدر",
    "جدلا",
    "جذاذا",
    "جذوة",
    "جذوع",
    "جرحتم",
    "جرزا",
    "جزءا",
    "جزاء",
    "جزاؤكم",
    "جزاؤه",
    "جزاؤهم",
    "جزوعا",
    "جزيتهم",
    "جزيناهم",
    "جسدا",
    "جعلا",
    "جعلتم",
    "جعلته",
    "جعلكم",
    "جعلنا",
    "جعلناك",
    "جعلناكم",
    "جعلناه",
    "جعلناها",
    "جعلناهم",
    "جعلوا",
    "جفاء",
    "جلابيبهن",
    "جلاها",
    "جلودا",
    "جلودكم",
    "جلودهم",
    "جما",
    "جمالت",
    "جمعا",
    "جمعكم",
    "جمعناكم",
    "جمعناهم",
    "جمعهم",
    "جمعوا",
    "جميعا",
    "جميلا",
    "جنات",
    "جناحك",
    "جنة",
    "جنتان",
    "جنتك",
    "جنته",
    "جنتي",
    "جنتين",
    "جنحوا",
    "جند",
    "جندا",
    "جندنا",
    "جنفا",
    "جنوبكم",
    "جنوبها",
    "جنوبهم",
    "جنودا",
    "جنوده",
    "جنيا",
    "جهادا",
    "جهاده",
    "جهدهم",
    "جهر",
    "جهرة",
    "جهزهم",
    "جهولا",
    "جواب",
    "جوفه",
    "جيبك",
    "جيدها",
    "جيوبهن",
    "حاج",
    "حاججتم",
    "حاجزا",
    "حاجزين",
    "حاجك",
    "حاجوك",
    "حاذرون",
    "حاسبين",
    "حاسد",
    "حاش",
    "حاشرين",
    "حاصبا",
    "حاضرا",
    "حاضرة",
    "حاضري",
    "حافظا",
    "حافظات",
    "حافظوا",
    "حافظون",
    "حافظين",
    "حافين",
    "حام",
    "حبالهم",
    "حبة",
    "حبط",
    "حبطت",
    "حبه",
    "حثيثا",
    "حج",
    "حجابا",
    "حجة",
    "حجتنا",
    "حجتهم",
    "حجج",
    "حجرا",
    "حجوركم",
    "حدب",
    "حدوده",
    "حديدا",
    "حذركم",
    "حذرهم",
    "حرا",
    "حراما",
    "حرث",
    "حرثكم",
    "حرثه",
    "حرجا",
    "حرد",
    "حرسا",
    "حرصت",
    "حرصتم",
    "حرضا",
    "حرقوه",
    "حرما",
    "حرمات",
    "حرمت",
    "حرمنا",
    "حرمها",
    "حرمهما",
    "حزب",
    "حزبه",
    "حسابا",
    "حسابك",
    "حسابه",
    "حسابهم",
    "حسابيه",
    "حسان",
    "حسبانا",
    "حسبتم",
    "حسبته",
    "حسبتهم",
    "حسبك",
    "حسبنا",
    "حسبه",
    "حسبهم",
    "حسبي",
    "حسد",
    "حسرات",
    "حسرة",
    "حسرتا",
    "حسرتنا",
    "حسنات",
    "حسنة",
    "حسنت",
    "حسنهن",
    "حسوما",
    "حسيبا",
    "حسير",
    "حسيسها",
    "حشر",
    "حشرت",
    "حشرتني",
    "حصاده",
    "حصب",
    "حصحص",
    "حصدتم",
    "حصرت",
    "حصونهم",
    "حصيدا",
    "حصيرا",
    "حضروه",
    "حطاما",
    "حطبا",
    "حطة",
    "حفرة",
    "حفظة",
    "حفظهما",
    "حفي",
    "حفيا",
    "حفيظ",
    "حفيظا",
    "حقبا",
    "حقت",
    "حقه",
    "حقيق",
    "حكمتم",
    "حكمه",
    "حكيما",
    "حلا",
    "حلاف",
    "حلالا",
    "حلفتم",
    "حللتم",
    "حليما",
    "حليهم",
    "حمإ",
    "حمئة",
    "حمارك",
    "حملا",
    "حملت",
    "حملتم",
    "حملته",
    "حملنا",
    "حملناكم",
    "حملهن",
    "حملوا",
    "حمية",
    "حميدا",
    "حميم",
    "حميما",
    "حنفاء",
    "حنيذ",
    "حنيفا",
    "حنين",
    "حوبا",
    "حوتهما",
    "حور",
    "حولا",
    "حولك",
    "حولكم",
    "حولها",
    "حولهم",
    "حولين",
    "حيا",
    "حياتكم",
    "حياتنا",
    "حيتانهم",
    "حيران",
    "حيلة",
    "حينئذ",
    "حيوك",
    "حييتم",
    "خائبين",
    "خائفا",
    "خائفين",
    "خاب",
    "خادعهم",
    "خاسئا",
    "خاسئين",
    "خاسرة",
    "خاسرين",
    "خاشعا",
    "خاشعة",
    "خاشعون",
    "خاشعين",
    "خاضعين",
    "خاضوا",
    "خاطئين",
    "خاطبهم",
    "خاف",
    "خافضة",
    "خافوا",
    "خافية",
    "خالاتك",
    "خالاتكم",
    "خالدا",
    "خالدون",
    "خالدين",
    "خالصا",
    "خالصة",
    "خالق",
    "خالك",
    "خامدون",
    "خامدين",
    "خانوا",
    "خاوية",
    "خبالا",
    "خبت",
    "خبرا",
    "خبزا",
    "خبيرا",
    "ختار",
    "ختامه",
    "خدك",
    "خذ",
    "خذها",
    "خذوا",
    "خذولا",
    "خذوه",
    "خر",
    "خرابها",
    "خرجا",
    "خرجت",
    "خرجتم",
    "خرجن",
    "خرجوا",
    "خردل",
    "خرقها",
    "خروا",
    "خزائنه",
    "خزنتها",
    "خزي",
    "خسارا",
    "خسرا",
    "خسرانا",
    "خسروا",
    "خسفنا",
    "خشعا",
    "خشوعا",
    "خشي",
    "خشيت",
    "خشيته",
    "خصاصة",
    "خصمان",
    "خصمون",
    "خصيم",
    "خصيما",
    "خضر",
    "خضرا",
    "خطئا",
    "خطابا",
    "خطاياكم",
    "خطايانا",
    "خطاياهم",
    "خطبك",
    "خطبكم",
    "خطبكما",
    "خطبكن",
    "خطيئاتكم",
    "خطيئاتهم",
    "خطيئة",
    "خطيئته",
    "خطيئتي",
    "خفافا",
    "خفتكم",
    "خفتم",
    "خفيا",
    "خلا",
    "خلائف",
    "خلافك",
    "خلالكم",
    "خلالها",
    "خلالهما",
    "خلة",
    "خلت",
    "خلصوا",
    "خلطوا",
    "خلفاء",
    "خلفة",
    "خلفتموني",
    "خلفك",
    "خلفكم",
    "خلفنا",
    "خلفه",
    "خلفها",
    "خلفهم",
    "خلفوا",
    "خلقا",
    "خلقت",
    "خلقتك",
    "خلقتني",
    "خلقته",
    "خلقك",
    "خلقكم",
    "خلقنا",
    "خلقناكم",
    "خلقناه",
    "خلقناهم",
    "خلقناهما",
    "خلقني",
    "خلقه",
    "خلقها",
    "خلقهم",
    "خلقهن",
    "خلقوا",
    "خلوا",
    "خليفة",
    "خليلا",
    "خمرا",
    "خمسه",
    "خمسين",
    "خمط",
    "خوار",
    "خوانا",
    "خوض",
    "خوضهم",
    "خوفهم",
    "خولناكم",
    "خولناه",
    "خوله",
    "خيانة",
    "خيانتك",
    "خير",
    "خيرا",
    "خيرات",
    "خيفة",
    "خيفته",
    "خيل",
    "دأب",
    "دأبا",
    "دائبين",
    "دائمون",
    "دابة",
    "دابر",
    "داحضة",
    "داخرون",
    "داخرين",
    "داخلون",
    "داركم",
    "دارهم",
    "دافق",
    "دامت",
    "داموا",
    "دانية",
    "داوود",
    "دبره",
    "دحاها",
    "دحورا",
    "دخل",
    "دخلا",
    "دخلت",
    "دخلتم",
    "دخلتموه",
    "دخله",
    "دخلوا",
    "دخلوه",
    "دراستهم",
    "دراهم",
    "درست",
    "دركا",
    "دري",
    "دساها",
    "دعا",
    "دعاء",
    "دعاءكم",
    "دعاءه",
    "دعاؤكم",
    "دعائهم",
    "دعاكم",
    "دعان",
    "دعانا",
    "دعاه",
    "دعوا",
    "دعواهم",
    "دعوت",
    "دعوتك",
    "دعوتكم",
    "دعوتكما",
    "دعوتهم",
    "دعيتم",
    "دفعتم",
    "دكا",
    "دكاء",
    "دكة",
    "دكت",
    "دلهم",
    "دلوه",
    "دليلا",
    "دما",
    "دماءكم",
    "دماؤها",
    "دمت",
    "دمتم",
    "دمرنا",
    "دمرناهم",
    "دنا",
    "دهاقا",
    "دونك",
    "دونكم",
    "دوننا",
    "دونه",
    "دونها",
    "دونهم",
    "دونهما",
    "دوني",
    "ديارا",
    "دياركم",
    "ديارنا",
    "ديارهم",
    "دينكم",
    "دينه",
    "دينهم",
    "ذائقة",
    "ذاقا",
    "ذاقوا",
    "ذاهب",
    "ذبابا",
    "ذرأ",
    "ذرأكم",
    "ذرأنا",
    "ذراعا",
    "ذرعا",
    "ذرعها",
    "ذرنا",
    "ذرني",
    "ذرهم",
    "ذروا",
    "ذرونا",
    "ذروني",
    "ذرية",
    "ذريتنا",
    "ذريته",
    "ذريتهم",
    "ذريتهما",
    "ذريتي",
    "ذق",
    "ذكرا",
    "ذكرانا",
    "ذكراها",
    "ذكراهم",
    "ذكرتم",
    "ذكرك",
    "ذكركم",
    "ذكرنا",
    "ذكرهم",
    "ذكروا",
    "ذكرى",
    "ذكري",
    "ذكيتم",
    "ذلة",
    "ذلكم",
    "ذلكما",
    "ذللا",
    "ذلول",
    "ذلولا",
    "ذمة",
    "ذنب",
    "ذنبك",
    "ذنبه",
    "ذنوب",
    "ذنوبا",
    "ذنوبكم",
    "ذنوبنا",
    "ذنوبهم",
    "ذهاب",
    "ذهبا",
    "ذهبت",
    "ذهبنا",
    "ذهبوا",
    "ذوا",
    "ذواتا",
    "ذواتي",
    "ذوقوا",
    "رءوس",
    "رءوسكم",
    "رءوسهم",
    "رءوف",
    "رآك",
    "رآه",
    "رآها",
    "رأته",
    "رأتهم",
    "رأسه",
    "رأفة",
    "رأوا",
    "رأوك",
    "رأوه",
    "رأوها",
    "رأوهم",
    "رأيت",
    "رأيتموه",
    "رأيتهم",
    "رأينه",
    "رؤياك",
    "رؤياي",
    "رئاء",
    "رابعهم",
    "رابيا",
    "رابية",
    "راجعون",
    "راد",
    "رادوه",
    "راسيات",
    "راضية",
    "راعنا",
    "راعون",
    "راغبون",
    "راق",
    "راكعا",
    "راكعون",
    "ران",
    "راودتن",
    "راودتني",
    "راودته",
    "راودوه",
    "ربا",
    "ربانيين",
    "ربحت",
    "ربطنا",
    "ربك",
    "ربكم",
    "ربكما",
    "ربنا",
    "ربه",
    "ربها",
    "ربهم",
    "ربهما",
    "ربوة",
    "ربي",
    "ربياني",
    "ربيون",
    "رتقا",
    "رجا",
    "رجالا",
    "رجالكم",
    "رجت",
    "رجز",
    "رجزا",
    "رجس",
    "رجسا",
    "رجسهم",
    "رجعت",
    "رجعتم",
    "رجعك",
    "رجعنا",
    "رجعه",
    "رجعوا",
    "رجلا",
    "رجلان",
    "رجلين",
    "رجما",
    "رجوما",
    "رجيم",
    "رحالهم",
    "رحبت",
    "رحله",
    "رحما",
    "رحماء",
    "رحمت",
    "رحمتك",
    "رحمتنا",
    "رحمته",
    "رحمتي",
    "رحمنا",
    "رحمناهم",
    "رحمه",
    "رحيق",
    "رحيما",
    "رخاء",
    "ردءا",
    "ردت",
    "رددت",
    "رددنا",
    "رددناه",
    "ردف",
    "ردما",
    "ردها",
    "ردوا",
    "ردوه",
    "ردوها",
    "رزق",
    "رزقا",
    "رزقكم",
    "رزقنا",
    "رزقناكم",
    "رزقناه",
    "رزقناهم",
    "رزقه",
    "رزقها",
    "رزقهم",
    "رزقهن",
    "رزقوا",
    "رسالات",
    "رسلا",
    "رسلك",
    "رسلكم",
    "رسلنا",
    "رسله",
    "رسلهم",
    "رسلي",
    "رسولا",
    "رسولكم",
    "رسولنا",
    "رسوله",
    "رسولها",
    "رسولهم",
    "رشدا",
    "رشده",
    "رشيد",
    "رصدا",
    "رضوا",
    "رضوان",
    "رضوانه",
    "رضيا",
    "رضيتم",
    "رطبا",
    "رعايتها",
    "رعبا",
    "رعوها",
    "رغبا",
    "رغدا",
    "رفث",
    "رفعت",
    "رفعه",
    "رفعها",
    "رفيقا",
    "رقبة",
    "رقود",
    "رقيب",
    "رقيبا",
    "ركاما",
    "ركبا",
    "ركبانا",
    "ركبك",
    "ركبوا",
    "ركزا",
    "ركعا",
    "ركوبهم",
    "رمزا",
    "رمضان",
    "رميت",
    "رميم",
    "رهط",
    "رهطك",
    "رهقا",
    "رهوا",
    "رهين",
    "رهينة",
    "رواسي",
    "رواكد",
    "روحا",
    "روحنا",
    "روحه",
    "روضات",
    "رويدا",
    "ريب",
    "ريبة",
    "ريبهم",
    "ريحا",
    "ريحكم",
    "زادته",
    "زادتهم",
    "زادهم",
    "زادوكم",
    "زادوهم",
    "زاغ",
    "زاغت",
    "زاغوا",
    "زالت",
    "زالتا",
    "زانية",
    "زاهق",
    "زبر",
    "زبرا",
    "زبورا",
    "زجرا",
    "زجرة",
    "زحزح",
    "زحفا",
    "زخرفها",
    "زدناهم",
    "زدني",
    "زرتم",
    "زرعا",
    "زرقا",
    "زعمت",
    "زعمتم",
    "زفير",
    "زقوم",
    "زكاة",
    "زكاها",
    "زكى",
    "زكيا",
    "زكية",
    "زلتم",
    "زلزالا",
    "زلزالها",
    "زلزلة",
    "زلزلت",
    "زلفة",
    "زلفى",
    "زلقا",
    "زللتم",
    "زمرا",
    "زمهريرا",
    "زنجبيلا",
    "زهوقا",
    "زوال",
    "زوجا",
    "زوجان",
    "زوجت",
    "زوجك",
    "زوجناكها",
    "زوجه",
    "زوجها",
    "زيتها",
    "زيتونة",
    "زيغ",
    "زينتكم",
    "زينته",
    "زينتهن",
    "سآتيكم",
    "سآوي",
    "سأتلو",
    "سأرهقه",
    "سأريكم",
    "سأستغفر",
    "سأصرف",
    "سأصليه",
    "سألتك",
    "سألتكم",
    "سألتم",
    "سألتموه",
    "سألتموهن",
    "سألتهم",
    "سألقي",
    "سألك",
    "سألها",
    "سألهم",
    "سألوا",
    "سأنبئك",
    "سأنزل",
    "سؤلك",
    "سئل",
    "سئلت",
    "سئلوا",
    "ساء",
    "ساءت",
    "سائبة",
    "سائحات",
    "سائغ",
    "سائغا",
    "سابغات",
    "سابقوا",
    "سابقون",
    "سابقين",
    "ساجدا",
    "ساجدين",
    "سادتنا",
    "سادسهم",
    "سارقين",
    "سافلها",
    "سافلين",
    "ساقطا",
    "ساقيها",
    "ساكنا",
    "سالمون",
    "سامدون",
    "سامرا",
    "ساهون",
    "سبإ",
    "سباتا",
    "سببا",
    "سبتهم",
    "سبحا",
    "سبحانك",
    "سبحوا",
    "سبعا",
    "سبعين",
    "سبقت",
    "سبقكم",
    "سبقوا",
    "سبقونا",
    "سبل",
    "سبلا",
    "سبلنا",
    "سبيلا",
    "سبيلك",
    "سبيلنا",
    "سبيله",
    "سبيلهم",
    "سبيلي",
    "ستجدني",
    "ستجدون",
    "ستدعون",
    "ستذكرونهن",
    "سترا",
    "ستغلبون",
    "ستكتب",
    "سجدا",
    "سجدوا",
    "سجرت",
    "سجى",
    "سجيل",
    "سجين",
    "سحابا",
    "سحار",
    "سحران",
    "سحرهم",
    "سحروا",
    "سخرنا",
    "سخرناها",
    "سخرها",
    "سخروا",
    "سخريا",
    "سدا",
    "سدر",
    "سدرة",
    "سدى",
    "سديدا",
    "سرابا",
    "سرابيل",
    "سرابيلهم",
    "سراجا",
    "سراحا",
    "سرادقها",
    "سراعا",
    "سربا",
    "سرحوهن",
    "سركم",
    "سرمدا",
    "سرهم",
    "سطحت",
    "سعته",
    "سعدوا",
    "سعرت",
    "سعوا",
    "سعى",
    "سعيا",
    "سعيرا",
    "سعيكم",
    "سعيه",
    "سعيها",
    "سعيهم",
    "سفرنا",
    "سفها",
    "سفيها",
    "سفيهنا",
    "سقاية",
    "سقر",
    "سقطوا",
    "سقفا",
    "سقناه",
    "سقيت",
    "سكارى",
    "سكت",
    "سكرا",
    "سكرة",
    "سكرت",
    "سكرتهم",
    "سكنا",
    "سكنتم",
    "سكينا",
    "سكينته",
    "سلاما",
    "سلسبيلا",
    "سلطانا",
    "سلطانه",
    "سلطانيه",
    "سلف",
    "سلفا",
    "سلقوكم",
    "سلككم",
    "سلكناه",
    "سلما",
    "سلمتم",
    "سلهم",
    "سماء",
    "سماعون",
    "سماكم",
    "سمان",
    "سماوات",
    "سمعا",
    "سمعت",
    "سمعتم",
    "سمعتموه",
    "سمعكم",
    "سمعنا",
    "سمعه",
    "سمعهم",
    "سمعوا",
    "سمكها",
    "سموم",
    "سموهم",
    "سميا",
    "سميتموها",
    "سميتها",
    "سميع",
    "سميعا",
    "سنؤتيهم",
    "سنا",
    "سنابل",
    "سنبلات",
    "سنبلة",
    "سنبله",
    "سنت",
    "سنجزي",
    "سندخلهم",
    "سندس",
    "سندع",
    "سنراود",
    "سنريهم",
    "سنزيد",
    "سنستدرجهم",
    "سنسمه",
    "سنشد",
    "سنطيعكم",
    "سنعذبهم",
    "سنعيدها",
    "سنفرغ",
    "سنقتل",
    "سنقرئك",
    "سنكتب",
    "سنلقي",
    "سنمتعهم",
    "سننظر",
    "سهولها",
    "سوءا",
    "سوءة",
    "سوآتكم",
    "سوآتهما",
    "سواعا",
    "سواك",
    "سواه",
    "سواها",
    "سور",
    "سورة",
    "سوط",
    "سوقه",
    "سويا",
    "سويته",
    "سيؤتينا",
    "سيئا",
    "سيئات",
    "سيئاتكم",
    "سيئاتنا",
    "سيئاته",
    "سيئاتهم",
    "سيئة",
    "سيئت",
    "سيئه",
    "سيبطله",
    "سيجزون",
    "سيجزيهم",
    "سيجعل",
    "سيحلفون",
    "سيدخلهم",
    "سيدخلون",
    "سيدها",
    "سيذكر",
    "سيرت",
    "سيرتها",
    "سيرحمهم",
    "سيروا",
    "سيريكم",
    "سيصلى",
    "سيصيب",
    "سيصيبهم",
    "سيطوقون",
    "سيعلمون",
    "سيغفر",
    "سيغلبون",
    "سيقول",
    "سيقولون",
    "سيكفرون",
    "سيكون",
    "سيماهم",
    "سينالهم",
    "سينين",
    "سيهدين",
    "سيهديهم",
    "سيهزم",
    "شأنهم",
    "شئت",
    "شئتم",
    "شئتما",
    "شئنا",
    "شاء",
    "شاخصة",
    "شافعين",
    "شاقوا",
    "شاكرا",
    "شاكرون",
    "شاكرين",
    "شاكلته",
    "شامخات",
    "شانئك",
    "شاهدا",
    "شاهدون",
    "شاهدين",
    "شتى",
    "شجرت",
    "شجرتها",
    "شجرها",
    "شح",
    "شحومهما",
    "شداد",
    "شدادا",
    "شديدا",
    "شر",
    "شرابا",
    "شرابه",
    "شرعة",
    "شرعوا",
    "شرقيا",
    "شرك",
    "شركاءكم",
    "شركاءهم",
    "شركاؤكم",
    "شركاؤنا",
    "شركاؤهم",
    "شركائكم",
    "شركائهم",
    "شركائي",
    "شروا",
    "شريعة",
    "شطأه",
    "شطره",
    "شططا",
    "شعائر",
    "شعوبا",
    "شعيب",
    "شعيبا",
    "شغفها",
    "شغلتنا",
    "شفا",
    "شفاء",
    "شفاعة",
    "شفاعتهم",
    "شفعاء",
    "شفعاءكم",
    "شفعاؤنا",
    "شفيع",
    "شقا",
    "شقاق",
    "شقاقي",
    "شققنا",
    "شقوا",
    "شقوتنا",
    "شقيا",
    "شكرتم",
    "شكله",
    "شكور",
    "شكورا",
    "شمائلهم",
    "شمسا",
    "شنآن",
    "شهابا",
    "شهادات",
    "شهادتهم",
    "شهادتهما",
    "شهد",
    "شهداء",
    "شهداءكم",
    "شهدتم",
    "شهدنا",
    "شهدوا",
    "شهرا",
    "شهرين",
    "شهوة",
    "شهودا",
    "شهيدا",
    "شهيدين",
    "شهيقا",
    "شواظ",
    "شورى",
    "شياطين",
    "شياطينهم",
    "شيبا",
    "شية",
    "شيخ",
    "شيخا",
    "شيطانا",
    "شيع",
    "شيعا",
    "شيعته",
    "شيوخا",
    "صابرا",
    "صابرة",
    "صابرون",
    "صاحبكم",
    "صاحبه",
    "صاحبهم",
    "صاحبي",
    "صادقا",
    "صادقين",
    "صارمين",
    "صاعقة",
    "صاغرون",
    "صاغرين",
    "صافات",
    "صال",
    "صالحين",
    "صالو",
    "صامتون",
    "صبا",
    "صببنا",
    "صبحا",
    "صبحهم",
    "صبرتم",
    "صبرك",
    "صبرنا",
    "صبروا",
    "صبوا",
    "صبيا",
    "صحفا",
    "صددتم",
    "صددناكم",
    "صدرا",
    "صدرك",
    "صدره",
    "صدقا",
    "صدقات",
    "صدقاتكم",
    "صدقاتهن",
    "صدقة",
    "صدقت",
    "صدقتنا",
    "صدقكم",
    "صدقنا",
    "صدقناهم",
    "صدقهم",
    "صدقوا",
    "صدودا",
    "صدور",
    "صدوركم",
    "صدورهم",
    "صدوكم",
    "صديد",
    "صديقا",
    "صديقكم",
    "صراط",
    "صراطا",
    "صراطك",
    "صراطي",
    "صرة",
    "صرحا",
    "صرصر",
    "صرصرا",
    "صرعى",
    "صرفا",
    "صرفت",
    "صرفكم",
    "صرفنا",
    "صرفناه",
    "صريخ",
    "صعقا",
    "صعيدا",
    "صغت",
    "صغيرا",
    "صفا",
    "صفحا",
    "صفصفا",
    "صفوان",
    "صلاتك",
    "صلاته",
    "صلاتهم",
    "صلاتي",
    "صلبوه",
    "صلح",
    "صلحا",
    "صلدا",
    "صلصال",
    "صلوا",
    "صلواتهم",
    "صلوه",
    "صلى",
    "صليا",
    "صما",
    "صنعا",
    "صنعة",
    "صنعوا",
    "صنوان",
    "صوابا",
    "صواع",
    "صواف",
    "صوامع",
    "صوتك",
    "صوركم",
    "صورناكم",
    "صوما",
    "صياصيهم",
    "صيام",
    "صياما",
    "ضاحكا",
    "ضاحكة",
    "ضاقت",
    "ضالين",
    "ضبحا",
    "ضحاها",
    "ضدا",
    "ضر",
    "ضرا",
    "ضراء",
    "ضرارا",
    "ضربا",
    "ضربت",
    "ضربتم",
    "ضربنا",
    "ضربوا",
    "ضربوه",
    "ضره",
    "ضريع",
    "ضعافا",
    "ضعفا",
    "ضعفاء",
    "ضعفوا",
    "ضعيفا",
    "ضغثا",
    "ضل",
    "ضلال",
    "ضلالا",
    "ضلالة",
    "ضلالتهم",
    "ضلالك",
    "ضللت",
    "ضللنا",
    "ضلوا",
    "ضنكا",
    "ضياء",
    "ضير",
    "ضيزى",
    "ضيفه",
    "ضيفي",
    "ضيقا",
    "طائركم",
    "طائره",
    "طائرهم",
    "طائعين",
    "طائف",
    "طائفتان",
    "طائفتين",
    "طاب",
    "طاعة",
    "طاعم",
    "طاغون",
    "طاغين",
    "طال",
    "طالوت",
    "طباقا",
    "طبتم",
    "طبن",
    "طحاها",
    "طرائق",
    "طردتهم",
    "طرفا",
    "طرفك",
    "طرفهم",
    "طريا",
    "طريقا",
    "طس",
    "طسم",
    "طعامك",
    "طعامه",
    "طعمتم",
    "طعموا",
    "طغوا",
    "طغى",
    "طغيانا",
    "طغيانهم",
    "طفلا",
    "طلبا",
    "طلعت",
    "طلعها",
    "طلقتم",
    "طلقتموهن",
    "طلقكن",
    "طلقها",
    "طلوع",
    "طمست",
    "طه",
    "طهرا",
    "طهورا",
    "طوافون",
    "طوبى",
    "طوى",
    "طويلا",
    "طيبا",
    "طيبات",
    "طيباتكم",
    "طيبين",
    "طيرا",
    "ظالمة",
    "ظالمون",
    "ظالمي",
    "ظالمين",
    "ظاهرا",
    "ظاهروهم",
    "ظاهرين",
    "ظعنكم",
    "ظلا",
    "ظلاله",
    "ظلالها",
    "ظلة",
    "ظلت",
    "ظلما",
    "ظلمات",
    "ظلمت",
    "ظلمتم",
    "ظلمك",
    "ظلمنا",
    "ظلمناهم",
    "ظلمه",
    "ظلمهم",
    "ظلموا",
    "ظلمونا",
    "ظلوما",
    "ظليلا",
    "ظمأ",
    "ظن",
    "ظنا",
    "ظنكم",
    "ظننا",
    "ظننت",
    "ظننتم",
    "ظنه",
    "ظنوا",
    "ظهرك",
    "ظهره",
    "ظهرها",
    "ظهوركم",
    "ظهوره",
    "ظهورها",
    "ظهورهم",
    "ظهورهما",
    "ظهير",
    "ظهيرا",
    "عائدون",
    "عائلا",
    "عابد",
    "عابدات",
    "عابدون",
    "عابدين",
    "عابري",
    "عاتية",
    "عادا",
    "عادون",
    "عاديتم",
    "عارضا",
    "عاصفة",
    "عاصم",
    "عاقبة",
    "عاقبتم",
    "عاقبتهما",
    "عاقر",
    "عاقرا",
    "عاكفا",
    "عاكفون",
    "عاكفين",
    "عالمين",
    "عالين",
    "عاليها",
    "عاليهم",
    "عاما",
    "عاملون",
    "عامهم",
    "عاهد",
    "عاهدت",
    "عاهدتم",
    "عاهدوا",
    "عبادا",
    "عبادتكم",
    "عبادته",
    "عبادتي",
    "عبادك",
    "عبادكم",
    "عبادنا",
    "عباده",
    "عبدا",
    "عبدت",
    "عبدتم",
    "عبدنا",
    "عبدناهم",
    "عبده",
    "عبدين",
    "عبرة",
    "عبوسا",
    "عتت",
    "عتل",
    "عتو",
    "عتوا",
    "عتيا",
    "عتيد",
    "عثر",
    "عجاب",
    "عجاف",
    "عجبا",
    "عجبت",
    "عجبوا",
    "عجلا",
    "عجلنا",
    "عجوزا",
    "عجولا",
    "عجيب",
    "عداوة",
    "عدتم",
    "عدتهم",
    "عدنا",
    "عدوا",
    "عدوان",
    "عدوانا",
    "عدوكم",
    "عدوه",
    "عدوهم",
    "عدوي",
    "عذاب",
    "عذابا",
    "عذابه",
    "عذابها",
    "عذابهما",
    "عذابي",
    "عذت",
    "عذرا",
    "عربا",
    "عربيا",
    "عرشك",
    "عرشه",
    "عرشها",
    "عرضتم",
    "عرضنا",
    "عرضهم",
    "عرفا",
    "عرفات",
    "عرفها",
    "عرفوا",
    "عروشها",
    "عزا",
    "عزة",
    "عزلت",
    "عزم",
    "عزما",
    "عزمت",
    "عزموا",
    "عزير",
    "عزين",
    "عسرا",
    "عسرة",
    "عسعس",
    "عسق",
    "عسى",
    "عسيتم",
    "عسير",
    "عسيرا",
    "عشاء",
    "عشرا",
    "عشية",
    "عشيرتك",
    "عشيرتهم",
    "عصاك",
    "عصاني",
    "عصاه",
    "عصاي",
    "عصبة",
    "عصوا",
    "عصوك",
    "عصوني",
    "عصيا",
    "عصيب",
    "عصيت",
    "عصيته",
    "عضدا",
    "عضدك",
    "عضوا",
    "عضين",
    "عطاؤنا",
    "عطفه",
    "عطلت",
    "عظاما",
    "عظامه",
    "عظيما",
    "عفريت",
    "عفوا",
    "عفونا",
    "عفي",
    "عقبا",
    "عقباها",
    "عقبه",
    "عقبى",
    "عقبيه",
    "عقدت",
    "عقدتم",
    "عقلوه",
    "عقيما",
    "علام",
    "علقة",
    "علمت",
    "علمتك",
    "علمتم",
    "علمتموهن",
    "علمتنا",
    "علمته",
    "علمكم",
    "علمنا",
    "علمناه",
    "علمني",
    "علمه",
    "علمها",
    "علمهم",
    "علموا",
    "علوا",
    "عليك",
    "عليكم",
    "عليكما",
    "عليم",
    "عليما",
    "علينا",
    "عليهما",
    "عليهن",
    "عليون",
    "عليين",
    "عم",
    "عما",
    "عماتك",
    "عماتكم",
    "عمرا",
    "عمران",
    "عمرك",
    "عمره",
    "عمروها",
    "عمك",
    "عملتم",
    "عملته",
    "عملك",
    "عملكم",
    "عملوا",
    "عموا",
    "عمون",
    "عمى",
    "عمي",
    "عميا",
    "عمين",
    "عنا",
    "عنتم",
    "عندك",
    "عندكم",
    "عندنا",
    "عندها",
    "عندهم",
    "عندي",
    "عنقك",
    "عنقه",
    "عنك",
    "عنكم",
    "عنها",
    "عنهم",
    "عنهما",
    "عني",
    "عنيدا",
    "عهدا",
    "عهدنا",
    "عهده",
    "عهدهم",
    "عهدي",
    "عوان",
    "عوج",
    "عوجا",
    "عورات",
    "عورة",
    "عوقب",
    "عوقبتم",
    "عيدا",
    "عيسى",
    "عيلة",
    "عينا",
    "عيناك",
    "عينان",
    "عيناه",
    "عينها",
    "عينيك",
    "عيونا",
    "غائبة",
    "غائبين",
    "غاسق",
    "غاشية",
    "غافر",
    "غافلا",
    "غافلون",
    "غافلين",
    "غالب",
    "غالبون",
    "غاوين",
    "غبرة",
    "غثاء",
    "غداءنا",
    "غدقا",
    "غدوا",
    "غدوت",
    "غدوها",
    "غر",
    "غرابا",
    "غراما",
    "غربت",
    "غربية",
    "غرفا",
    "غرقا",
    "غرك",
    "غروبها",
    "غرور",
    "غرورا",
    "غزلها",
    "غزى",
    "غسق",
    "غسلين",
    "غشاوة",
    "غشى",
    "غشيهم",
    "غصبا",
    "غصة",
    "غضبوا",
    "غضبي",
    "غطاءك",
    "غفارا",
    "غفرانك",
    "غفور",
    "غفورا",
    "غلا",
    "غلاظ",
    "غلاما",
    "غلبا",
    "غلبت",
    "غلبهم",
    "غلبوا",
    "غلت",
    "غلظة",
    "غلمان",
    "غليظا",
    "غم",
    "غما",
    "غمة",
    "غمرات",
    "غمرة",
    "غمرتهم",
    "غنم",
    "غنمتم",
    "غنمي",
    "غنيا",
    "غواش",
    "غورا",
    "غوى",
    "غوينا",
    "غيا",
    "غيابت",
    "غيبه",
    "غيث",
    "غيركم",
    "غيره",
    "غيرها",
    "غيري",
    "غيظ",
    "فآت",
    "فآتاهم",
    "فآتت",
    "فآتهم",
    "فآتوا",
    "فآتوهم",
    "فآتوهن",
    "فآتينا",
    "فآخران",
    "فآذوهما",
    "فآزره",
    "فآمن",
    "فآمنا",
    "فآمنت",
    "فآمنوا",
    "فآواكم",
    "فآوى",
    "فأبوا",
    "فأبى",
    "فأبين",
    "فأت",
    "فأتاهم",
    "فأتبع",
    "فأتبعنا",
    "فأتبعه",
    "فأتبعهم",
    "فأتبعوهم",
    "فأتت",
    "فأتمهن",
    "فأتموا",
    "فأتنا",
    "فأتوا",
    "فأتونا",
    "فأتوهن",
    "فأتى",
    "فأتيا",
    "فأتياه",
    "فأثابكم",
    "فأثابهم",
    "فأثرن",
    "فأجاءها",
    "فأجره",
    "فأجمعوا",
    "فأحبط",
    "فأحسن",
    "فأحكم",
    "فأحيا",
    "فأحياكم",
    "فأحيينا",
    "فأحييناه",
    "فأخاف",
    "فأخذتكم",
    "فأخذتهم",
    "فأخذناه",
    "فأخذناهم",
    "فأخذه",
    "فأخذهم",
    "فأخرج",
    "فأخرجنا",
    "فأخرجناهم",
    "فأخرجهما",
    "فأخلفتكم",
    "فأخلفتم",
    "فأدخلوا",
    "فأدلى",
    "فأذاقها",
    "فأذاقهم",
    "فأذن",
    "فأذنوا",
    "فأراد",
    "فأرادوا",
    "فأراه",
    "فأردت",
    "فأردنا",
    "فأرسل",
    "فأرسلنا",
    "فأرسله",
    "فأرسلوا",
    "فأرسلون",
    "فأروني",
    "فأزلهما",
    "فأسر",
    "فأسرها",
    "فأسقط",
    "فأسقيناكموه",
    "فأسكناه",
    "فأشارت",
    "فأشهدوا",
    "فأصابتكم",
    "فأصابه",
    "فأصابها",
    "فأصابهم",
    "فأصبح",
    "فأصبحت",
    "فأصبحتم",
    "فأصبحوا",
    "فأصحاب",
    "فأصدق",
    "فأصلح",
    "فأصلحوا",
    "فأصمهم",
    "فأضلونا",
    "فأطاعوه",
    "فأطلع",
    "فأعذبهم",
    "فأعرض",
    "فأعرضوا",
    "فأعقبهم",
    "فأعينوني",
    "فأغرقناه",
    "فأغرقناهم",
    "فأغرينا",
    "فأغشيناهم",
    "فأغنى",
    "فأغويناكم",
    "فأفوز",
    "فأقامه",
    "فأقبره",
    "فأقبل",
    "فأقبلت",
    "فأقبلوا",
    "فأقم",
    "فأقمت",
    "فأقيموا",
    "فأكثرت",
    "فأكثروا",
    "فأكرمه",
    "فأكلا",
    "فأكله",
    "فأكون",
    "فألف",
    "فألقاها",
    "فألقه",
    "فألقوا",
    "فألقوه",
    "فألقى",
    "فألقي",
    "فألقياه",
    "فألقيه",
    "فألهمها",
    "فأما",
    "فأماته",
    "فأمتعه",
    "فأمسكوهن",
    "فأمطر",
    "فأمكن",
    "فأمليت",
    "فأمه",
    "فأن",
    "فأنا",
    "فأنبئكم",
    "فأنبتنا",
    "فأنت",
    "فأنتم",
    "فأنجاه",
    "فأنجيناكم",
    "فأنجيناه",
    "فأنجيناهم",
    "فأنذر",
    "فأنذرتكم",
    "فأنزل",
    "فأنزلنا",
    "فأنساه",
    "فأنساهم",
    "فأنشأنا",
    "فأنشرنا",
    "فأنظرني",
    "فأنفخ",
    "فأنفقوا",
    "فأنقذكم",
    "فأنه",
    "فأنى",
    "فأهلكته",
    "فأهلكنا",
    "فأهلكناهم",
    "فأهلكوا",
    "فأواري",
    "فأوجس",
    "فأوحى",
    "فأوحينا",
    "فأوردهم",
    "فأوعى",
    "فأوف",
    "فأوفوا",
    "فأوقد",
    "فأولئك",
    "فأولى",
    "فأووا",
    "فأي",
    "فأيدنا",
    "فأين",
    "فأينما",
    "فؤاد",
    "فؤادك",
    "فإخوانكم",
    "فإذ",
    "فإذا",
    "فإطعام",
    "فإلم",
    "فإلهكم",
    "فإلينا",
    "فإليه",
    "فإما",
    "فإمساك",
    "فإن",
    "فإنا",
    "فإنك",
    "فإنكم",
    "فإنما",
    "فإنه",
    "فإنها",
    "فإنهم",
    "فإني",
    "فإياي",
    "فئتكم",
    "فئتين",
    "فاءت",
    "فاءوا",
    "فابتغوا",
    "فابعثوا",
    "فاتباع",
    "فاتبع",
    "فاتبعني",
    "فاتبعها",
    "فاتبعوا",
    "فاتبعوني",
    "فاتبعوه",
    "فاتخذ",
    "فاتخذت",
    "فاتخذتموهم",
    "فاتخذه",
    "فاتخذوه",
    "فاتقوا",
    "فاتقون",
    "فاتكم",
    "فاتلوها",
    "فاثبتوا",
    "فاجتباه",
    "فاجتنبوا",
    "فاجتنبوه",
    "فاجرا",
    "فاجعل",
    "فاجلدوا",
    "فاجلدوهم",
    "فاجنح",
    "فاحترقت",
    "فاحتمل",
    "فاحذرهم",
    "فاحذروا",
    "فاحذروه",
    "فاحذروهم",
    "فاحشة",
    "فاحكم",
    "فاختلط",
    "فاختلف",
    "فاختلفوا",
    "فاخرج",
    "فاخشوهم",
    "فاخلع",
    "فادارأتم",
    "فادخلوا",
    "فادخلوها",
    "فادخلي",
    "فادرءوا",
    "فادع",
    "فادعوا",
    "فادعوه",
    "فادعوهم",
    "فادفعوا",
    "فاذكروا",
    "فاذكروني",
    "فاذهب",
    "فاذهبا",
    "فارتد",
    "فارتدا",
    "فارتقب",
    "فارتقبهم",
    "فارجع",
    "فارجعنا",
    "فارجعوا",
    "فارزقوهم",
    "فارض",
    "فارغا",
    "فارغب",
    "فارقوهن",
    "فارهبون",
    "فارهين",
    "فاسأل",
    "فاسأله",
    "فاسألوا",
    "فاسألوهم",
    "فاسألوهن",
    "فاستأذنوك",
    "فاستبشروا",
    "فاستبقوا",
    "فاستجاب",
    "فاستجبتم",
    "فاستجبنا",
    "فاستحبوا",
    "فاستخف",
    "فاستشهدوا",
    "فاستعذ",
    "فاستعصم",
    "فاستغاثه",
    "فاستغفر",
    "فاستغفروا",
    "فاستغفروه",
    "فاستغلظ",
    "فاستفتهم",
    "فاستقم",
    "فاستقيما",
    "فاستقيموا",
    "فاستكبرتم",
    "فاستكبروا",
    "فاستمتعتم",
    "فاستمتعوا",
    "فاستمسك",
    "فاستمع",
    "فاستمعوا",
    "فاستوى",
    "فاسجد",
    "فاسجدوا",
    "فاسعوا",
    "فاسقا",
    "فاسقون",
    "فاسقين",
    "فاسلك",
    "فاسلكوه",
    "فاسلكي",
    "فاسمعون",
    "فاشهدوا",
    "فاصبر",
    "فاصبروا",
    "فاصدع",
    "فاصطادوا",
    "فاصفح",
    "فاضرب",
    "فاضربوا",
    "فاطر",
    "فاطلع",
    "فاطهروا",
    "فاعبد",
    "فاعبدني",
    "فاعبده",
    "فاعبدوا",
    "فاعبدون",
    "فاعبدوه",
    "فاعتبروا",
    "فاعتدوا",
    "فاعترفنا",
    "فاعترفوا",
    "فاعتزلوا",
    "فاعتزلون",
    "فاعتلوه",
    "فاعدلوا",
    "فاعف",
    "فاعفوا",
    "فاعلم",
    "فاعلموا",
    "فاعلون",
    "فاعلين",
    "فاعمل",
    "فاغسلوا",
    "فاغفر",
    "فافتح",
    "فافرق",
    "فافسحوا",
    "فافعلوا",
    "فاقتلوا",
    "فاقتلوهم",
    "فاقذفيه",
    "فاقرءوا",
    "فاقرة",
    "فاقصص",
    "فاقض",
    "فاقطعوا",
    "فاقع",
    "فاقعدوا",
    "فاكتبنا",
    "فاكتبوه",
    "فاكهون",
    "فاكهين",
    "فالآن",
    "فالتاليات",
    "فالتقطه",
    "فالتقمه",
    "فالتقى",
    "فالتمسوا",
    "فالجاريات",
    "فالحاملات",
    "فالحق",
    "فالحكم",
    "فالذين",
    "فالزاجرات",
    "فالسابقات",
    "فالصالحات",
    "فالعاصفات",
    "فالفارقات",
    "فالق",
    "فالله",
    "فالمدبرات",
    "فالمغيرات",
    "فالمقسمات",
    "فالملقيات",
    "فالموريات",
    "فالنار",
    "فامتحنوهن",
    "فامسحوا",
    "فامشوا",
    "فامنن",
    "فانبجست",
    "فانبذ",
    "فانتبذت",
    "فانتشروا",
    "فانتصر",
    "فانتظروا",
    "فانتقمنا",
    "فانتهوا",
    "فانتهى",
    "فانسلخ",
    "فانشزوا",
    "فانصب",
    "فانصرنا",
    "فانطلقا",
    "فانطلقوا",
    "فانظر",
    "فانظروا",
    "فانظري",
    "فانفجرت",
    "فانفذوا",
    "فانفروا",
    "فانفلق",
    "فانقلبوا",
    "فانكحوا",
    "فانكحوهن",
    "فانهار",
    "فاه",
    "فاهبط",
    "فاهجر",
    "فاهدوهم",
    "فبأي",
    "فبإذن",
    "فبئس",
    "فباءوا",
    "فبايعهن",
    "فبدأ",
    "فبدت",
    "فبدل",
    "فبذلك",
    "فبرأه",
    "فبشر",
    "فبشرناه",
    "فبشرناها",
    "فبشره",
    "فبشرهم",
    "فبصرت",
    "فبصرك",
    "فبظلم",
    "فبعث",
    "فبعدا",
    "فبعزتك",
    "فبغى",
    "فبلغن",
    "فبم",
    "فبما",
    "فبهت",
    "فبهداهم",
    "فتأتون",
    "فتأتيهم",
    "فتأكل",
    "فتاب",
    "فتاها",
    "فتبارك",
    "فتبسم",
    "فتبهتهم",
    "فتبينوا",
    "فتثير",
    "فتحا",
    "فتحت",
    "فتحرير",
    "فتحسسوا",
    "فتحنا",
    "فتحوا",
    "فتخبت",
    "فتخرجوه",
    "فتخشى",
    "فتخطفه",
    "فتدلى",
    "فتذروها",
    "فتذكر",
    "فتراه",
    "فتربصوا",
    "فتردى",
    "فترضى",
    "فتركه",
    "فترى",
    "فتزل",
    "فتستجيبون",
    "فتشابه",
    "فتشقى",
    "فتصبح",
    "فتصبحوا",
    "فتصيبكم",
    "فتطاول",
    "فتطردهم",
    "فتعاطى",
    "فتعالى",
    "فتعالين",
    "فتعرفونها",
    "فتعسا",
    "فتفجر",
    "فتفرق",
    "فتفشلوا",
    "فتقبل",
    "فتقبلها",
    "فتقطعوا",
    "فتقعد",
    "فتقول",
    "فتكفرون",
    "فتكن",
    "فتكون",
    "فتكونا",
    "فتكونون",
    "فتكوى",
    "فتلقى",
    "فتلك",
    "فتم",
    "فتماروا",
    "فتمتعوا",
    "فتمثل",
    "فتمسكم",
    "فتمنوا",
    "فتنا",
    "فتنادوا",
    "فتنازعوا",
    "فتناه",
    "فتنتك",
    "فتنتكم",
    "فتنتم",
    "فتنته",
    "فتنتهم",
    "فتنفخ",
    "فتنفعه",
    "فتنقلبوا",
    "فتنوا",
    "فتهاجروا",
    "فتهجد",
    "فتوبوا",
    "فتوكل",
    "فتوكلوا",
    "فتول",
    "فتولوا",
    "فتولى",
    "فتونا",
    "فتى",
    "فتياتكم",
    "فتيان",
    "فتية",
    "فتيلا",
    "فتيمموا",
    "فثبتوا",
    "فثبطهم",
    "فثم",
    "فج",
    "فجاء",
    "فجاءته",
    "فجاءها",
    "فجاءوهم",
    "فجاجا",
    "فجاسوا",
    "فجرت",
    "فجزاء",
    "فجزاؤه",
    "فجعل",
    "فجعلتم",
    "فجعلنا",
    "فجعلناه",
    "فجعلناها",
    "فجعلناهم",
    "فجعلناهن",
    "فجعله",
    "فجعلهم",
    "فجمع",
    "فجمعناهم",
    "فجوة",
    "فجورها",
    "فحاسبناها",
    "فحاق",
    "فحبطت",
    "فحدث",
    "فحسبه",
    "فحشر",
    "فحق",
    "فحكمه",
    "فحملته",
    "فحيوا",
    "فخانتاهما",
    "فخذها",
    "فخذوه",
    "فخذوهم",
    "فخر",
    "فخراج",
    "فخرج",
    "فخسفنا",
    "فخشينا",
    "فخلف",
    "فخلق",
    "فخلقنا",
    "فخلوا",
    "فخورا",
    "فداء",
    "فدخلوا",
    "فدعا",
    "فدعوهم",
    "فدكتا",
    "فدلاهما",
    "فدمدم",
    "فدمرناها",
    "فدمرناهم",
    "فدية",
    "فذاقت",
    "فذاقوا",
    "فذانك",
    "فذبحوها",
    "فذرني",
    "فذرهم",
    "فذروه",
    "فذروها",
    "فذكر",
    "فذلك",
    "فذلكم",
    "فذلكن",
    "فذو",
    "فذوقوا",
    "فذوقوه",
    "فرآه",
    "فرأوه",
    "فرات",
    "فراتا",
    "فراشا",
    "فربكم",
    "فرت",
    "فرث",
    "فرجالا",
    "فرجت",
    "فرجع",
    "فرجعناك",
    "فرجعوا",
    "فرجل",
    "فرجها",
    "فرحوا",
    "فرحون",
    "فرحين",
    "فردا",
    "فرددناه",
    "فردوا",
    "فردوه",
    "فررتم",
    "فرشناها",
    "فرضتم",
    "فرضنا",
    "فرطا",
    "فرطت",
    "فرطتم",
    "فرطنا",
    "فرعون",
    "فرغت",
    "فرقا",
    "فرقانا",
    "فرقت",
    "فرقنا",
    "فرقناه",
    "فرقوا",
    "فرهان",
    "فروج",
    "فروجهم",
    "فروجهن",
    "فروح",
    "فريضة",
    "فريقان",
    "فزادتهم",
    "فزادهم",
    "فزادوهم",
    "فزده",
    "فزع",
    "فزعوا",
    "فزيلنا",
    "فزين",
    "فزينوا",
    "فسأكتبها",
    "فساء",
    "فسالت",
    "فساهم",
    "فسبح",
    "فسبحان",
    "فسبحه",
    "فستبصر",
    "فستذكرون",
    "فسترضع",
    "فستعلمون",
    "فسجد",
    "فسجدوا",
    "فسحقا",
    "فسخرنا",
    "فسق",
    "فسقا",
    "فسقناه",
    "فسقوا",
    "فسقى",
    "فسلام",
    "فسلكه",
    "فسلموا",
    "فسنيسره",
    "فسواك",
    "فسواها",
    "فسواهن",
    "فسوف",
    "فسوق",
    "فسوى",
    "فسيأتيهم",
    "فسيؤتيه",
    "فسيحشرهم",
    "فسيحوا",
    "فسيدخلهم",
    "فسيروا",
    "فسيرى",
    "فسيعلمون",
    "فسيقولون",
    "فسيكفيكهم",
    "فسينغضون",
    "فسينفقونها",
    "فشاربون",
    "فشدوا",
    "فشربوا",
    "فشرد",
    "فشلتم",
    "فشهادة",
    "فصالا",
    "فصب",
    "فصبر",
    "فصبروا",
    "فصدقت",
    "فصدهم",
    "فصدوا",
    "فصرف",
    "فصرهن",
    "فصعق",
    "فصكت",
    "فصلت",
    "فصلنا",
    "فصلناه",
    "فصلى",
    "فصيام",
    "فضة",
    "فضحكت",
    "فضرب",
    "فضربنا",
    "فضلا",
    "فضلتكم",
    "فضلكم",
    "فضلنا",
    "فضله",
    "فضلوا",
    "فطاف",
    "فطال",
    "فطبع",
    "فطرت",
    "فطركم",
    "فطرنا",
    "فطرني",
    "فطرهن",
    "فطفق",
    "فطل",
    "فطلقوهن",
    "فطمسنا",
    "فطهر",
    "فطور",
    "فطوعت",
    "فظا",
    "فظلت",
    "فظلتم",
    "فظلموا",
    "فظلوا",
    "فظن",
    "فظنوا",
    "فعاقبتم",
    "فعاقبوا",
    "فعتوا",
    "فعجب",
    "فعجل",
    "فعدة",
    "فعدتهن",
    "فعدلك",
    "فعرفهم",
    "فعززنا",
    "فعسى",
    "فعصوا",
    "فعصى",
    "فعظوهن",
    "فعفونا",
    "فعقر",
    "فعقروا",
    "فعقروها",
    "فعلتك",
    "فعلتم",
    "فعلته",
    "فعلتها",
    "فعلم",
    "فعلموا",
    "فعلن",
    "فعلنا",
    "فعله",
    "فعلوا",
    "فعلوه",
    "فعلى",
    "فعليكم",
    "فعليه",
    "فعليها",
    "فعليهم",
    "فعليهن",
    "فعموا",
    "فعميت",
    "فعند",
    "فغشاها",
    "فغشيهم",
    "فغفر",
    "فغفرنا",
    "فغلبوا",
    "فغلوه",
    "فغوى",
    "ففتحنا",
    "ففتقناهما",
    "ففدية",
    "ففررت",
    "ففروا",
    "ففريقا",
    "ففزع",
    "ففسق",
    "ففسقوا",
    "ففهمناها",
    "ففي",
    "فقاتل",
    "فقاتلا",
    "فقاتلوا",
    "فقال",
    "فقالت",
    "فقالوا",
    "فقبضت",
    "فقتل",
    "فقتله",
    "فقدر",
    "فقدرنا",
    "فقدره",
    "فقدموا",
    "فقذفناها",
    "فقرأه",
    "فقراء",
    "فقربه",
    "فقست",
    "فقضاهن",
    "فقضى",
    "فقطع",
    "فقعوا",
    "فقل",
    "فقلت",
    "فقلنا",
    "فقليلا",
    "فقنا",
    "فقولا",
    "فقولوا",
    "فقولي",
    "فقيرا",
    "فكأنما",
    "فكأين",
    "فكاتبوهم",
    "فكان",
    "فكانت",
    "فكانوا",
    "فكبت",
    "فكبر",
    "فكبكبوا",
    "فكثركم",
    "فكذب",
    "فكذبت",
    "فكذبنا",
    "فكذبوا",
    "فكذبوه",
    "فكذبوهما",
    "فكذلك",
    "فكرهتموه",
    "فكسونا",
    "فكشفنا",
    "فكف",
    "فكفارته",
    "فكفرت",
    "فكفروا",
    "فكفى",
    "فكلا",
    "فكلوا",
    "فكلوه",
    "فكلي",
    "فكنتم",
    "فكهين",
    "فكيدون",
    "فكيدوني",
    "فكيف",
    "فلأقطعن",
    "فلأمه",
    "فلأنفسكم",
    "فلأنفسهم",
    "فلا",
    "فلانا",
    "فلبئس",
    "فلبث",
    "فلبثت",
    "فلتقم",
    "فلذلك",
    "فلسوف",
    "فلعرفتهم",
    "فلعلك",
    "فلعنة",
    "فلقاتلوكم",
    "فلكل",
    "فلكم",
    "فللذكر",
    "فلله",
    "فللوالدين",
    "فلما",
    "فلمسوه",
    "فلنأتينك",
    "فلنأتينهم",
    "فلنحيينه",
    "فلنذيقن",
    "فلنسألن",
    "فلنعم",
    "فلنفسه",
    "فلنقصن",
    "فلننبئن",
    "فلنولينك",
    "فله",
    "فلها",
    "فلهم",
    "فلهما",
    "فلهن",
    "فلو",
    "فلولا",
    "فليأت",
    "فليأتكم",
    "فليأتنا",
    "فليأتوا",
    "فليأكل",
    "فليؤد",
    "فليؤمن",
    "فليبتكن",
    "فليتقوا",
    "فليتنافس",
    "فليتوكل",
    "فليحذر",
    "فليدع",
    "فليذوقوه",
    "فليرتقوا",
    "فليس",
    "فليستأذنوا",
    "فليستجيبوا",
    "فليستعفف",
    "فليصلوا",
    "فليصمه",
    "فليضحكوا",
    "فليعبدوا",
    "فليعلمن",
    "فليعمل",
    "فليغيرن",
    "فليفرحوا",
    "فليقاتل",
    "فليكتب",
    "فليكفر",
    "فليكونوا",
    "فليلقه",
    "فليمدد",
    "فليملل",
    "فلينظر",
    "فلينفق",
    "فمأواهم",
    "فما",
    "فماذا",
    "فمال",
    "فمالئون",
    "فمتاع",
    "فمتعناهم",
    "فمتعوهن",
    "فمثله",
    "فمحونا",
    "فمرت",
    "فمستقر",
    "فمكث",
    "فملاقيه",
    "فمن",
    "فمنكم",
    "فمنه",
    "فمنها",
    "فمنهم",
    "فمهل",
    "فناداها",
    "فنادته",
    "فنادوا",
    "فنادى",
    "فناظرة",
    "فنبذتها",
    "فنبذناه",
    "فنبذناهم",
    "فنبذوه",
    "فنتبرأ",
    "فنتبع",
    "فنجعل",
    "فنجي",
    "فنجيناك",
    "فنجيناه",
    "فنخرج",
    "فنذر",
    "فنردها",
    "فنزل",
    "فنسوا",
    "فنسي",
    "فنسيتها",
    "فنسيهم",
    "فنصف",
    "فنظر",
    "فنظرة",
    "فنظل",
    "فنعم",
    "فنعما",
    "فنعمل",
    "فنفخنا",
    "فنفعها",
    "فنقبوا",
    "فنكون",
    "فننبئكم",
    "فننبئهم",
    "فهب",
    "فهدى",
    "فهديناهم",
    "فهذا",
    "فهزموهم",
    "فهل",
    "فهو",
    "فهي",
    "فواحدة",
    "فواق",
    "فوج",
    "فوجا",
    "فوجد",
    "فوجدا",
    "فوجدناها",
    "فورب",
    "فوربك",
    "فورهم",
    "فوزا",
    "فوسطن",
    "فوسوس",
    "فوفاه",
    "فوقاه",
    "فوقاهم",
    "فوقع",
    "فوقكم",
    "فوقها",
    "فوقهم",
    "فوقهن",
    "فوكزه",
    "فولوا",
    "فوهب",
    "فويل",
    "فيأتيهم",
    "فيأخذكم",
    "فيؤخذ",
    "فيؤمنوا",
    "فيئوس",
    "فيبسطه",
    "فيتبعون",
    "فيتعلمون",
    "فيجعله",
    "فيحفكم",
    "فيحل",
    "فيحلفون",
    "فيحلوا",
    "فيحيي",
    "فيخرج",
    "فيدخلهم",
    "فيدمغه",
    "فيدهنون",
    "فيذرها",
    "فيذهب",
    "فيرسل",
    "فيركمه",
    "فيسبوا",
    "فيستحيي",
    "فيسحتكم",
    "فيسخرون",
    "فيسقي",
    "فيشفعوا",
    "فيصبحوا",
    "فيصلب",
    "فيصيب",
    "فيضاعفه",
    "فيضل",
    "فيضلك",
    "فيطمع",
    "فيظللن",
    "فيعتذرون",
    "فيعذبه",
    "فيعذبهم",
    "فيعلمون",
    "فيغرقكم",
    "فيغفر",
    "فيقتل",
    "فيقتلون",
    "فيقسمان",
    "فيقول",
    "فيقولوا",
    "فيقولون",
    "فيكشف",
    "فيكم",
    "فيكون",
    "فيكيدوا",
    "فيم",
    "فيمت",
    "فيمسك",
    "فيمكث",
    "فيموتوا",
    "فيميلون",
    "فينبئكم",
    "فينبئهم",
    "فينتقم",
    "فينسخ",
    "فينظر",
    "فينظروا",
    "فينقلبوا",
    "فيهم",
    "فيهما",
    "فيهن",
    "فيوحي",
    "فيوفيهم",
    "فيومئذ",
    "قائلها",
    "قائما",
    "قائمون",
    "قاب",
    "قاتلكم",
    "قاتلهم",
    "قاتلوا",
    "قاتلوكم",
    "قاتلوهم",
    "قادرون",
    "قادرين",
    "قارون",
    "قاسية",
    "قاصدا",
    "قاصرات",
    "قاصفا",
    "قاطعة",
    "قاعا",
    "قاعدا",
    "قاعدون",
    "قالا",
    "قالت",
    "قالتا",
    "قالها",
    "قالوا",
    "قاموا",
    "قانت",
    "قانتا",
    "قانتات",
    "قانتون",
    "قانتين",
    "قاهرون",
    "قبره",
    "قبس",
    "قبضا",
    "قبضته",
    "قبضناه",
    "قبلا",
    "قبلتك",
    "قبلتهم",
    "قبلك",
    "قبلكم",
    "قبلنا",
    "قبله",
    "قبلها",
    "قبلهم",
    "قبلي",
    "قبيلا",
    "قتال",
    "قتالا",
    "قترة",
    "قتلت",
    "قتلتم",
    "قتلتموهم",
    "قتلنا",
    "قتله",
    "قتلهم",
    "قتلوا",
    "قتلوه",
    "قتورا",
    "قدحا",
    "قددا",
    "قدرا",
    "قدرنا",
    "قدرناه",
    "قدرناها",
    "قدره",
    "قدروا",
    "قدروها",
    "قدمت",
    "قدمتم",
    "قدمتموه",
    "قدموا",
    "قديرا",
    "قرآن",
    "قرآنا",
    "قرآنه",
    "قرأت",
    "قرأناه",
    "قرئ",
    "قرارا",
    "قراطيس",
    "قربا",
    "قربات",
    "قربانا",
    "قربة",
    "قربى",
    "قرة",
    "قرت",
    "قرح",
    "قرضا",
    "قرطاس",
    "قرنا",
    "قرناء",
    "قروء",
    "قرونا",
    "قرى",
    "قريتك",
    "قريتكم",
    "قريتنا",
    "قريش",
    "قرين",
    "قرينا",
    "قرينه",
    "قست",
    "قسمة",
    "قسمنا",
    "قسورة",
    "قسيسين",
    "قصاص",
    "قصصا",
    "قصصنا",
    "قصصناهم",
    "قصصهم",
    "قصمنا",
    "قصورا",
    "قصيا",
    "قصيه",
    "قضاها",
    "قضوا",
    "قضي",
    "قضيت",
    "قضيتم",
    "قضينا",
    "قطرا",
    "قطران",
    "قطعا",
    "قطعت",
    "قطعتم",
    "قطعن",
    "قطمير",
    "قطنا",
    "قطوفها",
    "قعود",
    "قعيد",
    "قفينا",
    "قلبك",
    "قلبه",
    "قلبها",
    "قلبين",
    "قلت",
    "قلتم",
    "قلته",
    "قلن",
    "قلنا",
    "قلوب",
    "قلوبكم",
    "قلوبكما",
    "قلوبنا",
    "قلوبهم",
    "قلى",
    "قليلون",
    "قمتم",
    "قمطريرا",
    "قميصه",
    "قنطارا",
    "قنطوا",
    "قنوان",
    "قنوط",
    "قوا",
    "قواريرا",
    "قواما",
    "قوامون",
    "قوامين",
    "قوتكم",
    "قوتلتم",
    "قوتلوا",
    "قوسين",
    "قولا",
    "قولك",
    "قولكم",
    "قولنا",
    "قوله",
    "قولها",
    "قولهم",
    "قولوا",
    "قولي",
    "قوما",
    "قومك",
    "قومنا",
    "قومه",
    "قومها",
    "قومهم",
    "قويا",
    "قيام",
    "قياما",
    "قيلا",
    "قيما",
    "كأحد",
    "كأسا",
    "كألف",
    "كأمثال",
    "كأنك",
    "كأنما",
    "كأنه",
    "كأنها",
    "كأنهم",
    "كأنهن",
    "كاتبا",
    "كاتبون",
    "كاتبين",
    "كادت",
    "كادوا",
    "كاذبا",
    "كاذبون",
    "كاذبين",
    "كارهون",
    "كارهين",
    "كاشفات",
    "كاشفة",
    "كاشفو",
    "كاظمين",
    "كافرة",
    "كافرون",
    "كافرين",
    "كافورا",
    "كالأعلام",
    "كالأعمى",
    "كالأنثى",
    "كالأنعام",
    "كالتي",
    "كالجبال",
    "كالجواب",
    "كالحجارة",
    "كالحون",
    "كالدهان",
    "كالذي",
    "كالذين",
    "كالرميم",
    "كالصريم",
    "كالطود",
    "كالظلل",
    "كالعرجون",
    "كالعهن",
    "كالفجار",
    "كالفخار",
    "كالفراش",
    "كالقصر",
    "كالمجرمين",
    "كالمعلقة",
    "كالمفسدين",
    "كالمهل",
    "كالوهم",
    "كاملين",
    "كانتا",
    "كانوا",
    "كبائر",
    "كبارا",
    "كباسط",
    "كبت",
    "كبتوا",
    "كبد",
    "كبره",
    "كبيرا",
    "كبيرهم",
    "كتابا",
    "كتابك",
    "كتابنا",
    "كتابها",
    "كتابهم",
    "كتابيه",
    "كتبنا",
    "كتبناها",
    "كتم",
    "كثرت",
    "كثرتكم",
    "كثيبا",
    "كثيرة",
    "كجهر",
    "كحب",
    "كخشية",
    "كخلقه",
    "كخيفتكم",
    "كدأب",
    "كدت",
    "كدحا",
    "كدعاء",
    "كدنا",
    "كذابا",
    "كذبا",
    "كذبت",
    "كذبتم",
    "كذبه",
    "كذبوا",
    "كذبوك",
    "كذبوكم",
    "كذبون",
    "كذبوه",
    "كذكركم",
    "كذلكم",
    "كرام",
    "كراما",
    "كرب",
    "كرتين",
    "كرسيه",
    "كرماد",
    "كرمت",
    "كرمنا",
    "كرهتموهن",
    "كرهوا",
    "كريما",
    "كزرع",
    "كسادها",
    "كسالى",
    "كسبا",
    "كسبت",
    "كسبتم",
    "كسبوا",
    "كسراب",
    "كسفا",
    "كسوتهم",
    "كشجرة",
    "كشطت",
    "كشفت",
    "كشفنا",
    "كصاحب",
    "كصيب",
    "كطي",
    "كظلمات",
    "كظيم",
    "كعذاب",
    "كعرض",
    "كعصف",
    "كغلي",
    "كفاتا",
    "كفار",
    "كفارا",
    "كفارة",
    "كفرا",
    "كفران",
    "كفرت",
    "كفرتم",
    "كفرنا",
    "كفره",
    "كفرهم",
    "كفروا",
    "كففت",
    "كفلين",
    "كفوا",
    "كفور",
    "كفورا",
    "كفى",
    "كفيلا",
    "كفيناك",
    "كفيه",
    "كلالة",
    "كلبهم",
    "كلتا",
    "كلتم",
    "كلما",
    "كلمت",
    "كلمتنا",
    "كلمح",
    "كلمه",
    "كله",
    "كلها",
    "كلهم",
    "كلهن",
    "كلوا",
    "كماء",
    "كمثله",
    "كمشكاة",
    "كمن",
    "كنا",
    "كنت",
    "كنتم",
    "كنتن",
    "كنز",
    "كنزتم",
    "كنزهما",
    "كنفس",
    "كهشيم",
    "كهفهم",
    "كهيئة",
    "كهيعص",
    "كوكبا",
    "كونوا",
    "كيدا",
    "كيدكم",
    "كيدكن",
    "كيده",
    "كيدهم",
    "كيدهن",
    "كيدون",
    "لآبائهم",
    "لآت",
    "لآتوها",
    "لآتية",
    "لآتينا",
    "لآتيناهم",
    "لآتينهم",
    "لآدم",
    "لآكلون",
    "لآمن",
    "لآيات",
    "لآياتنا",
    "لآية",
    "لأبي",
    "لأبيه",
    "لأتخذن",
    "لأجد",
    "لأجدن",
    "لأجرا",
    "لأجعلنك",
    "لأجل",
    "لأحتنكن",
    "لأحدهما",
    "لأخته",
    "لأخذنا",
    "لأخراهم",
    "لأخيه",
    "لأذبحنه",
    "لأذقناك",
    "لأرجمنك",
    "لأريناكهم",
    "لأزواجك",
    "لأزواجهم",
    "لأزيدنكم",
    "لأزينن",
    "لأستغفرن",
    "لأسجد",
    "لأسقيناهم",
    "لأسمعهم",
    "لأصحاب",
    "لأصلبنكم",
    "لأظنك",
    "لأظنه",
    "لأعدل",
    "لأعدوا",
    "لأعذبنه",
    "لأعنتكم",
    "لأغلبن",
    "لأغوينهم",
    "لأقتلك",
    "لأقتلنك",
    "لأقرب",
    "لأقطعن",
    "لأقعدن",
    "لأكثرهم",
    "لأكفر",
    "لأكفرن",
    "لأكلوا",
    "لأكونن",
    "لأكيدن",
    "لأمارة",
    "لأماناتهم",
    "لأمر",
    "لأمسكتم",
    "لأملأن",
    "لأنت",
    "لأنتم",
    "لأنذركم",
    "لأنزل",
    "لأنعمه",
    "لأنفسكم",
    "لأنفسهم",
    "لأهب",
    "لأهل",
    "لأهله",
    "لأواه",
    "لأوتين",
    "لأولاهم",
    "لأولنا",
    "لأولي",
    "لأيمانكم",
    "لؤلؤ",
    "لؤلؤا",
    "لإبراهيم",
    "لإثم",
    "لإحدى",
    "لإخوانهم",
    "لإلى",
    "لإيلاف",
    "لئلا",
    "لئن",
    "لابتغوا",
    "لابثين",
    "لابنه",
    "لاتبعتم",
    "لاتبعناكم",
    "لاتبعوك",
    "لاتخذت",
    "لاتخذناه",
    "لاتخذوك",
    "لاختلفتم",
    "لارتاب",
    "لازب",
    "لاستكثرت",
    "لاصطفى",
    "لاعبين",
    "لاغية",
    "لافتدت",
    "لافتدوا",
    "لاقيه",
    "لامرأته",
    "لامستم",
    "لانتصر",
    "لانفضوا",
    "لاهية",
    "لبإمام",
    "لبئس",
    "لباسا",
    "لباسهما",
    "لبالمرصاد",
    "لبث",
    "لبثت",
    "لبثتم",
    "لبثنا",
    "لبثوا",
    "لبدا",
    "لبرز",
    "لبسبيل",
    "لبشر",
    "لبشرين",
    "لبعثنا",
    "لبعولتهن",
    "لبغوا",
    "لبلاغا",
    "لبلد",
    "لبن",
    "لبنا",
    "لبنيه",
    "لبوس",
    "لبيت",
    "لبيوتهم",
    "لتأتنني",
    "لتأتون",
    "لتأتينكم",
    "لتأخذوها",
    "لتأفكنا",
    "لتأكلوا",
    "لتؤمنن",
    "لتؤمنوا",
    "لتاركو",
    "لتبتغوا",
    "لتبدي",
    "لتبشر",
    "لتبعثن",
    "لتبلغوا",
    "لتبلون",
    "لتبين",
    "لتبيننه",
    "لتتلو",
    "لتجدن",
    "لتجري",
    "لتجزى",
    "لتحسبوه",
    "لتحصنكم",
    "لتحكم",
    "لتحملهم",
    "لتخرج",
    "لتخرجنا",
    "لتخرجوا",
    "لتدخلن",
    "لتدعوهم",
    "لتذكرة",
    "لتذهبوا",
    "لتردين",
    "لترضوا",
    "لترضى",
    "لتركبن",
    "لتركبوا",
    "لتركبوها",
    "لترون",
    "لترونها",
    "لتزول",
    "لتسألن",
    "لتستووا",
    "لتسحرنا",
    "لتسكنوا",
    "لتسلكوا",
    "لتشرك",
    "لتشقى",
    "لتشهدون",
    "لتضيقوا",
    "لتعارفوا",
    "لتعتدوا",
    "لتعجل",
    "لتعرضوا",
    "لتعلم",
    "لتعلموا",
    "لتعودن",
    "لتغرق",
    "لتغفر",
    "لتفتروا",
    "لتفتري",
    "لتفسدن",
    "لتقتلني",
    "لتقرأه",
    "لتقولون",
    "لتكبروا",
    "لتكفرون",
    "لتكون",
    "لتكونن",
    "لتكونوا",
    "لتلفتنا",
    "لتلقى",
    "لتمرون",
    "لتنبؤن",
    "لتنبئنهم",
    "لتنذر",
    "لتنزيل",
    "لتنفقوا",
    "لتنوء",
    "لتهتدوا",
    "لتهدي",
    "لتولوا",
    "لثمود",
    "لجاءهم",
    "لجاعلون",
    "لجالوت",
    "لجبريل",
    "لجة",
    "لجعل",
    "لجعلكم",
    "لجعلنا",
    "لجعلناه",
    "لجعله",
    "لجعلهم",
    "لجلودهم",
    "لجمعهم",
    "لجنبه",
    "لجهنم",
    "لجوا",
    "لجي",
    "لحافظون",
    "لحافظين",
    "لحب",
    "لحبط",
    "لحدود",
    "لحديث",
    "لحسرة",
    "لحق",
    "لحكم",
    "لحكمه",
    "لحكمهم",
    "لحليم",
    "لحومها",
    "لحياتي",
    "لخاسرون",
    "لخاطئين",
    "لخبير",
    "لخرجنا",
    "لخزنة",
    "لخسف",
    "لخلق",
    "لدا",
    "لداوود",
    "لدلوك",
    "لدنا",
    "لدنك",
    "لدنه",
    "لدني",
    "لدي",
    "لدينا",
    "لديهم",
    "لذائقو",
    "لذائقون",
    "لذكر",
    "لذكرى",
    "لذكري",
    "لذكورنا",
    "لذنبك",
    "لذنوبهم",
    "لذهب",
    "لذو",
    "لذي",
    "لرءوف",
    "لرأيته",
    "لرادك",
    "لرب",
    "لربك",
    "لربكم",
    "لربه",
    "لربها",
    "لربهم",
    "لرجل",
    "لرجمناك",
    "لرحمة",
    "لرزقنا",
    "لرسلهم",
    "لرسول",
    "لرسوله",
    "لرفعناه",
    "لرقيك",
    "لزاما",
    "لزلفى",
    "لساحر",
    "لساحران",
    "لسارقون",
    "لسانا",
    "لسانك",
    "لسبإ",
    "لست",
    "لستم",
    "لستن",
    "لسحر",
    "لسريع",
    "لسعيه",
    "لسعيها",
    "لسلطهم",
    "لسليمان",
    "لسميع",
    "لسنة",
    "لسنت",
    "لسنتنا",
    "لسوف",
    "لشاعر",
    "لشتى",
    "لشديد",
    "لشر",
    "لشرذمة",
    "لشركائنا",
    "لشركائهم",
    "لشهادتنا",
    "لشهيد",
    "لشوبا",
    "لشيء",
    "لصاحبه",
    "لصادق",
    "لصادقون",
    "لصالو",
    "لصوت",
    "لضالون",
    "لطمسنا",
    "لطيفا",
    "لظالمون",
    "لظالمين",
    "لظلم",
    "لظلوا",
    "لظلوم",
    "لظى",
    "لعاد",
    "لعادوا",
    "لعال",
    "لعبا",
    "لعبادته",
    "لعبادنا",
    "لعباده",
    "لعبادي",
    "لعبرة",
    "لعجل",
    "لعدتهن",
    "لعذبنا",
    "لعذبهم",
    "لعفو",
    "لعل",
    "لعلك",
    "لعلكم",
    "لعلمه",
    "لعلنا",
    "لعله",
    "لعلهم",
    "لعلى",
    "لعلي",
    "لعليم",
    "لعمرك",
    "لعملكم",
    "لعنا",
    "لعناهم",
    "لعنت",
    "لعنتم",
    "لعنتي",
    "لعنه",
    "لعنهم",
    "لعنوا",
    "لغائظون",
    "لغافلون",
    "لغافلين",
    "لغد",
    "لغفار",
    "لغفور",
    "لغلامين",
    "لغني",
    "لغو",
    "لغوا",
    "لغوب",
    "لغير",
    "لفاسقون",
    "لفاسقين",
    "لفاعلون",
    "لفتاه",
    "لفتحنا",
    "لفتيانه",
    "لفرح",
    "لفرعون",
    "لفروجهم",
    "لفريقا",
    "لفسدت",
    "لفسدتا",
    "لفسق",
    "لفشلتم",
    "لفضله",
    "لفي",
    "لفيفا",
    "لقاء",
    "لقاءنا",
    "لقائه",
    "لقادر",
    "لقادرون",
    "لقال",
    "لقالوا",
    "لقتال",
    "لقد",
    "لقدير",
    "لقرآن",
    "لقسم",
    "لقضي",
    "لقطعنا",
    "لقلنا",
    "لقلوبكم",
    "لقمان",
    "لقوا",
    "لقوكم",
    "لقول",
    "لقولهم",
    "لقوم",
    "لقومكما",
    "لقومه",
    "لقومهم",
    "لقوي",
    "لقيا",
    "لقيتم",
    "لقينا",
    "لك",
    "لكاذبون",
    "لكارهون",
    "لكافرون",
    "لكان",
    "لكبيرة",
    "لكبيركم",
    "لكتاب",
    "لكفرنا",
    "لكفور",
    "لكلمات",
    "لكلماته",
    "لكما",
    "لكنا",
    "لكنت",
    "لكنتم",
    "لكنود",
    "لكي",
    "لكيلا",
    "للآخرة",
    "للآخرين",
    "للآكلين",
    "للأبرار",
    "للأذقان",
    "للأنام",
    "للأوابين",
    "للإسلام",
    "للإنسان",
    "للإيمان",
    "للبث",
    "للتقوى",
    "للتي",
    "للجبل",
    "للجبين",
    "للجوا",
    "للحسنى",
    "للحق",
    "للحواريين",
    "للحي",
    "للخائنين",
    "للخبيثات",
    "للخبيثين",
    "للخروج",
    "للخير",
    "للدين",
    "للذاكرين",
    "للذكر",
    "للذي",
    "للذين",
    "للرؤيا",
    "للرجال",
    "للرحمن",
    "للرسل",
    "للزكاة",
    "للسائلين",
    "للساعة",
    "للسحت",
    "للسلم",
    "للسمع",
    "للشاربين",
    "للشهادة",
    "للشوى",
    "للشياطين",
    "للشيطان",
    "للصابرين",
    "للطائفين",
    "للطاغين",
    "للطيبات",
    "للطيبين",
    "للظالمين",
    "للعابدين",
    "للعالمين",
    "للعباد",
    "للعبيد",
    "للعسرى",
    "للغاوين",
    "للغيب",
    "للقاسية",
    "للقمر",
    "للقوم",
    "للكافرين",
    "للكتب",
    "للكذب",
    "للكفر",
    "للمؤمنات",
    "للمؤمنين",
    "للمتقين",
    "للمتكبرين",
    "للمتوسمين",
    "للمجرمين",
    "للمحسنات",
    "للمحسنين",
    "للمخلفين",
    "للمسرفين",
    "للمسلمين",
    "للمشركين",
    "للمصلين",
    "للمطففين",
    "للمقوين",
    "للمكذبين",
    "للملإ",
    "للملائكة",
    "للموقنين",
    "للناس",
    "للناظرين",
    "للنبي",
    "للهدى",
    "للوالدين",
    "لليتامى",
    "لليسرى",
    "لمؤمن",
    "لما",
    "لمبتلين",
    "لمبعوثون",
    "لمبلسين",
    "لمتنني",
    "لمثل",
    "لمثله",
    "لمثوبة",
    "لمجموعون",
    "لمجنون",
    "لمحجوبون",
    "لمحضرون",
    "لمحيطة",
    "لمحيي",
    "لمخرجون",
    "لمدركون",
    "لمدين",
    "لمدينون",
    "لمردودون",
    "لمرسلون",
    "لمزة",
    "لمساكين",
    "لمستقر",
    "لمسجد",
    "لمسخناهم",
    "لمسرفون",
    "لمسكم",
    "لمسنا",
    "لمشركون",
    "لمعزولون",
    "لمعكم",
    "لمغرمون",
    "لمغفرة",
    "لمفعولا",
    "لمقت",
    "لمكر",
    "لمن",
    "لمنجوهم",
    "لمنقلبون",
    "لمنكم",
    "لمهتدون",
    "لمهلكهم",
    "لموسعون",
    "لموسى",
    "لموعدهم",
    "لموفوهم",
    "لميتون",
    "لميقات",
    "لميقاتنا",
    "لنؤمنن",
    "لناصحون",
    "لناكبون",
    "لنبذ",
    "لنبلوهم",
    "لنبوئنهم",
    "لنبي",
    "لنبيتنه",
    "لنبين",
    "لنت",
    "لنتخذن",
    "لنثبت",
    "لنجعلها",
    "لنحرقنه",
    "لنحشرنهم",
    "لنحضرنهم",
    "لنحن",
    "لنحيي",
    "لنخرج",
    "لنخرجن",
    "لنخرجنك",
    "لنخرجنكم",
    "لندخلنهم",
    "لنذهبن",
    "لنذيقهم",
    "لنراك",
    "لنراها",
    "لنرجمنكم",
    "لنرسل",
    "لنريك",
    "لنريه",
    "لنزلنا",
    "لنسألنهم",
    "لنسفعا",
    "لنسلم",
    "لنصدقن",
    "لنصرف",
    "لنظنك",
    "لنعبد",
    "لنعلم",
    "لنغرينك",
    "لنفتنهم",
    "لنفد",
    "لنفسد",
    "لنفسه",
    "لنفسي",
    "لنقولن",
    "لنكفرن",
    "لنكونن",
    "لننجينه",
    "لننزعن",
    "لننسفنه",
    "لننصر",
    "لننصرنكم",
    "لننظر",
    "لنهتدي",
    "لنهدينهم",
    "لنهلكن",
    "لنوره",
    "لهاد",
    "لهب",
    "لهداكم",
    "لهدمت",
    "لهدى",
    "لهديناكم",
    "لهمت",
    "لهن",
    "لهوا",
    "لهي",
    "لواحة",
    "لواحد",
    "لواذا",
    "لواقح",
    "لواقع",
    "لوالديه",
    "لوجدوا",
    "لوط",
    "لوطا",
    "لوقتها",
    "لوقعتها",
    "لولوا",
    "لوليت",
    "لوليه",
    "لومة",
    "لووا",
    "ليأتيني",
    "ليأخذ",
    "ليأخذوه",
    "ليأكلوا",
    "ليأكلون",
    "ليؤذن",
    "ليؤمنن",
    "ليؤمنوا",
    "ليئوس",
    "ليال",
    "ليبتليكم",
    "ليبدي",
    "ليبطئن",
    "ليبعثن",
    "ليبغي",
    "ليبلغ",
    "ليبلو",
    "ليبلوكم",
    "ليبلونكم",
    "ليبلوني",
    "ليبين",
    "ليت",
    "ليتخذ",
    "ليتساءلوا",
    "ليتفقهوا",
    "ليتنا",
    "ليتني",
    "ليتها",
    "ليتوبوا",
    "ليثبت",
    "ليثبتوك",
    "ليجادلوكم",
    "ليجزي",
    "ليجزيك",
    "ليجزيهم",
    "ليجعل",
    "ليجمعنكم",
    "ليحاجوكم",
    "ليحبطن",
    "ليحزن",
    "ليحزنك",
    "ليحزنني",
    "ليحق",
    "ليحكم",
    "ليحملوا",
    "ليخرج",
    "ليخرجكم",
    "ليخرجن",
    "ليخرجوك",
    "ليدبروا",
    "ليدحضوا",
    "ليدخل",
    "ليدخلنهم",
    "ليذر",
    "ليذكروا",
    "ليذهب",
    "ليذوق",
    "ليذوقوا",
    "ليذيقهم",
    "ليربو",
    "ليردوهم",
    "ليرزقنهم",
    "ليرضوكم",
    "ليروا",
    "ليريكم",
    "ليريه",
    "ليريهما",
    "ليزدادوا",
    "ليزلقونك",
    "ليسأل",
    "ليستأذنكم",
    "ليستخفوا",
    "ليستخلفنهم",
    "ليستفزونك",
    "ليستيقن",
    "ليسجنن",
    "ليسجننه",
    "ليسكن",
    "ليسكنوا",
    "ليسمون",
    "ليسوءوا",
    "ليسوا",
    "ليشتروا",
    "ليشهدوا",
    "ليصبحن",
    "ليصدوا",
    "ليصدونهم",
    "ليصرمنها",
    "ليضل",
    "ليضلنا",
    "ليضلوا",
    "ليضلون",
    "ليضيع",
    "ليطاع",
    "ليطغى",
    "ليطفئوا",
    "ليطلعكم",
    "ليطمئن",
    "ليطهركم",
    "ليظلمهم",
    "ليظهره",
    "ليعبدوا",
    "ليعبدون",
    "ليعجزه",
    "ليعذب",
    "ليعذبهم",
    "ليعلم",
    "ليعلموا",
    "ليعلمون",
    "ليغفر",
    "ليغيظ",
    "ليفتدوا",
    "ليفتنونك",
    "ليفجر",
    "ليفسد",
    "ليفسدوا",
    "ليقتلوك",
    "ليقربونا",
    "ليقض",
    "ليقضوا",
    "ليقضى",
    "ليقضي",
    "ليقطع",
    "ليقولن",
    "ليقولوا",
    "ليقولون",
    "ليقوم",
    "ليقيموا",
    "ليكتمون",
    "ليكفر",
    "ليكفروا",
    "ليكونا",
    "ليكونن",
    "ليكونوا",
    "ليلها",
    "ليمسن",
    "ليمكروا",
    "ليميز",
    "لينبذن",
    "لينذر",
    "لينذركم",
    "لينصرنه",
    "لينفروا",
    "لينفق",
    "ليهديهم",
    "ليهلك",
    "ليواطئوا",
    "ليوحون",
    "ليوسف",
    "ليوفينهم",
    "ليوفيهم",
    "ليولن",
    "ليوم",
    "مآب",
    "مآبا",
    "مآرب",
    "مأتيا",
    "مأمنه",
    "مأواكم",
    "مأواهم",
    "مؤجلا",
    "مؤذن",
    "مؤصدة",
    "مؤمنا",
    "مؤمنات",
    "مؤمنة",
    "مؤمنون",
    "مؤمنين",
    "ماءك",
    "ماءها",
    "ماؤكم",
    "ماؤها",
    "مائتين",
    "ماتوا",
    "ماذا",
    "مارج",
    "مارد",
    "ماكثون",
    "ماكثين",
    "مال",
    "مالكون",
    "ماله",
    "ماليه",
    "مانعتهم",
    "مباركا",
    "مباركة",
    "مبتليكم",
    "مبثوثة",
    "مبديه",
    "مبرءون",
    "مبرمون",
    "مبسوطتان",
    "مبشرا",
    "مبشرات",
    "مبشرين",
    "مبصرا",
    "مبصرة",
    "مبصرون",
    "مبطلون",
    "مبعدون",
    "مبعوثون",
    "مبلسون",
    "مبلغهم",
    "مبوأ",
    "مبينا",
    "مبينات",
    "مبينة",
    "متاب",
    "متابا",
    "متاع",
    "متاعا",
    "متاعنا",
    "متاعهم",
    "متبر",
    "متبرجات",
    "متبعون",
    "متتابعين",
    "متجانف",
    "متجاورات",
    "متحرفا",
    "متحيزا",
    "متخذ",
    "متخذات",
    "متخذي",
    "متراكبا",
    "متربة",
    "متربص",
    "متربصون",
    "مترفوها",
    "مترفين",
    "مترفيها",
    "مترفيهم",
    "متشابها",
    "متشابهات",
    "متشاكسون",
    "متصدعا",
    "متعت",
    "متعتهم",
    "متعمدا",
    "متعنا",
    "متعناه",
    "متعناهم",
    "متفرقة",
    "متفرقون",
    "متقلبكم",
    "متكئون",
    "متكئين",
    "متنا",
    "متوفيك",
    "متى",
    "مثابة",
    "مثاني",
    "مثبورا",
    "مثقال",
    "مثقلة",
    "مثقلون",
    "مثلا",
    "مثلكم",
    "مثلنا",
    "مثلها",
    "مثلهم",
    "مثلهن",
    "مثليها",
    "مثليهم",
    "مثنى",
    "مثواكم",
    "مثواه",
    "مثواي",
    "مثوبة",
    "مثوى",
    "مجتمعون",
    "مجذوذ",
    "مجراها",
    "مجرما",
    "مجرمون",
    "مجرمين",
    "مجرميها",
    "مجيب",
    "محاريب",
    "محبة",
    "محجورا",
    "محذورا",
    "محررا",
    "محرما",
    "محرمة",
    "محرومون",
    "محسنون",
    "محسنين",
    "محسورا",
    "محشورة",
    "محصنات",
    "محصنين",
    "محضرا",
    "محضرون",
    "محظورا",
    "محفوظا",
    "محكمات",
    "محلقين",
    "محله",
    "محلها",
    "محمودا",
    "محياهم",
    "محيص",
    "محيصا",
    "محيطا",
    "مختلفا",
    "مختلفون",
    "مخذولا",
    "مخرجا",
    "مخرجون",
    "مخضرة",
    "مخضود",
    "مخلدون",
    "مخلصا",
    "مخلصون",
    "مخلصين",
    "مخلف",
    "مخلقة",
    "مخمصة",
    "مد",
    "مدا",
    "مدادا",
    "مدبرا",
    "مدبرين",
    "مدت",
    "مدتهم",
    "مدحورا",
    "مدخلا",
    "مددا",
    "مددناها",
    "مدرارا",
    "مدكر",
    "مدهامتان",
    "مدهنون",
    "مدينين",
    "مذءوما",
    "مذبذبين",
    "مذعنين",
    "مذكورا",
    "مذموما",
    "مراء",
    "مراغما",
    "مرتان",
    "مرتفقا",
    "مرتقبون",
    "مرج",
    "مرجعكم",
    "مرجعهم",
    "مرجوا",
    "مرجون",
    "مرد",
    "مردا",
    "مردفين",
    "مردنا",
    "مردوا",
    "مرساها",
    "مرسلا",
    "مرسلة",
    "مرسلو",
    "مرسلين",
    "مرشدا",
    "مرصادا",
    "مرصوص",
    "مرضا",
    "مرضات",
    "مرضاتي",
    "مرضت",
    "مرضعة",
    "مرضية",
    "مرفقا",
    "مرفوعة",
    "مرقدنا",
    "مرقوم",
    "مركوم",
    "مروا",
    "مريئا",
    "مرية",
    "مريج",
    "مريد",
    "مريدا",
    "مريضا",
    "مزاجها",
    "مزجاة",
    "مزدجر",
    "مزقتم",
    "مسئولا",
    "مسئولون",
    "مساجد",
    "مساس",
    "مسافحات",
    "مسافحين",
    "مساكنكم",
    "مساكنهم",
    "مساكين",
    "مستأنسين",
    "مستبشرة",
    "مستبصرين",
    "مستخف",
    "مستخلفين",
    "مستسلمون",
    "مستضعفون",
    "مستضعفين",
    "مستطر",
    "مستطيرا",
    "مستقرا",
    "مستقرها",
    "مستقيما",
    "مستكبرا",
    "مستكبرون",
    "مستكبرين",
    "مستمسكون",
    "مستمعهم",
    "مستمعون",
    "مستنفرة",
    "مسته",
    "مستهزئون",
    "مستهم",
    "مستورا",
    "مسجد",
    "مسجدا",
    "مسحا",
    "مسحورا",
    "مسحورون",
    "مسخرات",
    "مسد",
    "مسرفون",
    "مسرفين",
    "مسرورا",
    "مسطور",
    "مسطورا",
    "مسغبة",
    "مسفرة",
    "مسفوحا",
    "مسكم",
    "مسكنهم",
    "مسكونة",
    "مسكين",
    "مسكينا",
    "مسلما",
    "مسلمات",
    "مسلمة",
    "مسلمون",
    "مسلمين",
    "مسمع",
    "مسنا",
    "مسندة",
    "مسنون",
    "مسني",
    "مسه",
    "مسهم",
    "مسودا",
    "مسودة",
    "مسومة",
    "مسومين",
    "مشارق",
    "مشتبها",
    "مشتركون",
    "مشربهم",
    "مشرقين",
    "مشرك",
    "مشركة",
    "مشركون",
    "مشركين",
    "مشفقون",
    "مشفقين",
    "مشكورا",
    "مشهد",
    "مشهود",
    "مشهودا",
    "مشوا",
    "مشيدة",
    "مشيك",
    "مصبحين",
    "مصدقا",
    "مصرا",
    "مصرفا",
    "مصروفا",
    "مصفرا",
    "مصلحون",
    "مصيبها",
    "مصيرا",
    "مصيركم",
    "مضاجعهم",
    "مضار",
    "مضل",
    "مضيا",
    "مطاع",
    "مطر",
    "مطلعون",
    "مطمئنة",
    "مطمئنين",
    "مطهرة",
    "مطويات",
    "مظلما",
    "مظلمون",
    "مظلوما",
    "معاجزين",
    "معاذ",
    "معاذيره",
    "معاشا",
    "معتد",
    "معجزي",
    "معجزين",
    "معدودات",
    "معدودة",
    "معذبهم",
    "معذبوها",
    "معذبين",
    "معذرة",
    "معذرتهم",
    "معرة",
    "معرضون",
    "معرضين",
    "معروشات",
    "معروفا",
    "معزل",
    "معشار",
    "معشر",
    "معطلة",
    "معقب",
    "معقبات",
    "معكم",
    "معكما",
    "معكوفا",
    "معنا",
    "معهم",
    "معي",
    "معيشة",
    "معيشتها",
    "معيشتهم",
    "مغارات",
    "مغاضبا",
    "مغانم",
    "مغرب",
    "مغرقون",
    "مغرما",
    "مغفرة",
    "مغلولة",
    "مغيرا",
    "مفاتح",
    "مفاتحه",
    "مفازا",
    "مفتحة",
    "مفتر",
    "مفترون",
    "مفتريات",
    "مفرطون",
    "مفروضا",
    "مفسدون",
    "مفسدين",
    "مفصلا",
    "مفصلات",
    "مفعولا",
    "مقاليد",
    "مقاما",
    "مقامع",
    "مقامك",
    "مقامهما",
    "مقامي",
    "مقبوضة",
    "مقتا",
    "مقتدر",
    "مقتدرا",
    "مقتدرون",
    "مقتدون",
    "مقترفون",
    "مقترنين",
    "مقتصدة",
    "مقتكم",
    "مقداره",
    "مقدورا",
    "مقرنين",
    "مقسوم",
    "مقصورات",
    "مقضيا",
    "مقمحون",
    "مقنعي",
    "مقيتا",
    "مقيلا",
    "مكاء",
    "مكانا",
    "مكانتكم",
    "مكانتهم",
    "مكانكم",
    "مكبا",
    "مكتوبا",
    "مكث",
    "مكذبين",
    "مكذوب",
    "مكر",
    "مكرتموه",
    "مكرمة",
    "مكرمون",
    "مكرهم",
    "مكروا",
    "مكروها",
    "مكظوم",
    "مكلبين",
    "مكنا",
    "مكناكم",
    "مكناهم",
    "مكنون",
    "مكني",
    "مكين",
    "ملئت",
    "ملائكة",
    "ملاق",
    "ملاقو",
    "ملاقوه",
    "ملاقيكم",
    "ملة",
    "ملتحدا",
    "ملتكم",
    "ملتنا",
    "ملجإ",
    "ملعونين",
    "ملقون",
    "ملكا",
    "ملكت",
    "ملكتم",
    "ملكه",
    "ملكوت",
    "ملكين",
    "ملوكا",
    "ملوما",
    "ملومين",
    "مليا",
    "مليك",
    "مليم",
    "مم",
    "ممددة",
    "ممدكم",
    "ممدود",
    "ممدودا",
    "ممرد",
    "ممسكات",
    "ممطرنا",
    "مملوكا",
    "ممن",
    "ممنون",
    "منا",
    "مناديا",
    "مناسككم",
    "مناسكنا",
    "مناص",
    "مناع",
    "منافع",
    "منافقون",
    "مناكبها",
    "منامك",
    "منامكم",
    "منامها",
    "منبثا",
    "منتصرا",
    "منتصرين",
    "منتظرون",
    "منتقمون",
    "منتهاها",
    "منتهون",
    "منثورا",
    "منجوك",
    "منذرون",
    "منذرين",
    "منزلا",
    "منزلها",
    "منزلون",
    "منزلين",
    "منسأته",
    "منسكا",
    "منسيا",
    "منشرة",
    "منشورا",
    "منصورا",
    "منضود",
    "منطق",
    "منظرون",
    "منظرين",
    "منعك",
    "منعنا",
    "منعهم",
    "منفطر",
    "منفكين",
    "منقعر",
    "منقلبا",
    "منقلبون",
    "منقوص",
    "منك",
    "منكرا",
    "منكرة",
    "منكرون",
    "منكم",
    "منكن",
    "مننا",
    "منهما",
    "منهمر",
    "منهن",
    "منوعا",
    "مني",
    "منيب",
    "منيبا",
    "منيبين",
    "منيرا",
    "مهاجرا",
    "مهاجرات",
    "مهاد",
    "مهادا",
    "مهانا",
    "مهتد",
    "مهتدون",
    "مهتدين",
    "مهدا",
    "مهطعين",
    "مهلكهم",
    "مهلكو",
    "مهلكوها",
    "مهلكي",
    "مهيلا",
    "مهينا",
    "موئلا",
    "مواخر",
    "موازينه",
    "مواضعه",
    "مواقعوها",
    "مواقيت",
    "موبقا",
    "موتتنا",
    "موتكم",
    "موته",
    "موتها",
    "موتوا",
    "موثقا",
    "موثقهم",
    "مودة",
    "مورا",
    "موص",
    "موضوعة",
    "موضونة",
    "موطئا",
    "موعدا",
    "موعدة",
    "موعدك",
    "موعدكم",
    "موعده",
    "موعدهم",
    "موعدي",
    "موعظة",
    "موفورا",
    "موقنون",
    "موقنين",
    "موقوتا",
    "موقوفون",
    "مولاكم",
    "مولانا",
    "مولاه",
    "مولاهم",
    "مولى",
    "موليها",
    "موهن",
    "ميتة",
    "ميتون",
    "ميثاقا",
    "ميثاقكم",
    "ميثاقه",
    "ميثاقهم",
    "ميراث",
    "ميسرة",
    "ميسورا",
    "ميعاد",
    "ميقات",
    "ميقاتا",
    "ميقاتهم",
    "ميلة",
    "نأت",
    "نأتي",
    "نأتيكم",
    "نأخذ",
    "نأكل",
    "نؤته",
    "نؤتها",
    "نؤتى",
    "نؤتيه",
    "نؤثرك",
    "نؤخره",
    "نؤمن",
    "نائمون",
    "ناجيتم",
    "نادانا",
    "ناداه",
    "نادمين",
    "نادوا",
    "نادى",
    "ناديتم",
    "ناديكم",
    "نادينا",
    "ناديه",
    "نارا",
    "ناسكوه",
    "ناصبة",
    "ناصح",
    "ناصحون",
    "ناصر",
    "ناصرا",
    "ناصرين",
    "ناصية",
    "ناضرة",
    "ناظرة",
    "ناظرين",
    "ناعمة",
    "نافقوا",
    "نافلة",
    "ناقة",
    "ناكسو",
    "نبأ",
    "نبأت",
    "نبأتكما",
    "نبأنا",
    "نبأني",
    "نبأه",
    "نبأها",
    "نبأهم",
    "نبإ",
    "نبئ",
    "نبئنا",
    "نبئوني",
    "نباتا",
    "نباته",
    "نبتغي",
    "نبتليه",
    "نبتهل",
    "نبدل",
    "نبذه",
    "نبرأها",
    "نبرح",
    "نبشرك",
    "نبطش",
    "نبعث",
    "نبغ",
    "نبغي",
    "نبلوهم",
    "نبيا",
    "نبين",
    "نبيهم",
    "نتبع",
    "نتبعكم",
    "نتبعه",
    "نتبعهم",
    "نتبوأ",
    "نتخذ",
    "نتخذه",
    "نتخطف",
    "نتربص",
    "نترك",
    "نتقبل",
    "نتقنا",
    "نتكلم",
    "نتلو",
    "نتلوه",
    "نتلوها",
    "نتنزل",
    "نتوفينك",
    "نتوكل",
    "نثبت",
    "نجا",
    "نجازي",
    "نجاكم",
    "نجانا",
    "نجاهم",
    "نجب",
    "نجزي",
    "نجزيه",
    "نجس",
    "نجعل",
    "نجعلها",
    "نجعلهم",
    "نجعلهما",
    "نجمع",
    "نجني",
    "نجواكم",
    "نجواهم",
    "نجوت",
    "نجوى",
    "نجيا",
    "نجينا",
    "نجيناكم",
    "نجيناه",
    "نجيناهم",
    "نحبه",
    "نحس",
    "نحسات",
    "نحشر",
    "نحشرهم",
    "نحلة",
    "نحيي",
    "نخاف",
    "نختم",
    "نخرة",
    "نخرج",
    "نخرجكم",
    "نخسف",
    "نخشى",
    "نخفي",
    "نخل",
    "نخلفه",
    "نخلقكم",
    "نخوض",
    "نداولها",
    "ندخلها",
    "ندري",
    "ندع",
    "ندعو",
    "ندعوه",
    "ندلكم",
    "نديا",
    "نذرا",
    "نذرت",
    "نذرتم",
    "نذقه",
    "نذهبن",
    "نذورهم",
    "نذيرا",
    "نذيقهم",
    "نراك",
    "نربك",
    "نرث",
    "نرد",
    "نرزقك",
    "نرزقكم",
    "نرزقهم",
    "نرسل",
    "نرفع",
    "نرى",
    "نري",
    "نريد",
    "نريك",
    "نرينك",
    "نريهم",
    "نزاعة",
    "نزد",
    "نزعناها",
    "نزغ",
    "نزلا",
    "نزلة",
    "نزلت",
    "نزلنا",
    "نزلناه",
    "نزله",
    "نزلهم",
    "نزيدكم",
    "نسأل",
    "نسألك",
    "نساءكم",
    "نساءهم",
    "نساؤكم",
    "نسائكم",
    "نسائهم",
    "نسائهن",
    "نسارع",
    "نسبا",
    "نسبح",
    "نسبحك",
    "نستبق",
    "نستحوذ",
    "نستعين",
    "نستنسخ",
    "نسختها",
    "نسخر",
    "نسفا",
    "نسفت",
    "نسقط",
    "نسقي",
    "نسقيكم",
    "نسك",
    "نسلخ",
    "نسلكه",
    "نسله",
    "نسمع",
    "نسوا",
    "نسوق",
    "نسوه",
    "نسوي",
    "نسويكم",
    "نسي",
    "نسيا",
    "نسيت",
    "نسيتم",
    "نسير",
    "نسينا",
    "نسيناكم",
    "نشأ",
    "نشاء",
    "نشتري",
    "نشرا",
    "نشرت",
    "نشرح",
    "نشرك",
    "نشطا",
    "نشهد",
    "نشورا",
    "نشوزا",
    "نشوزهن",
    "نصارى",
    "نصبا",
    "نصبت",
    "نصبر",
    "نصحوا",
    "نصحي",
    "نصرا",
    "نصرانيا",
    "نصرف",
    "نصركم",
    "نصرنا",
    "نصره",
    "نصرهم",
    "نصروهم",
    "نصفه",
    "نصليه",
    "نصليهم",
    "نصوحا",
    "نصيب",
    "نصيبا",
    "نصيبك",
    "نصيبهم",
    "نصيرا",
    "نضاختان",
    "نضجت",
    "نضربها",
    "نضرة",
    "نضطرهم",
    "نضيد",
    "نضيع",
    "نطبع",
    "نطعم",
    "نطعمكم",
    "نطفة",
    "نطمس",
    "نطمع",
    "نطوي",
    "نطيع",
    "نظن",
    "نظنك",
    "نظنكم",
    "نعاجه",
    "نعبد",
    "نعبدهم",
    "نعجة",
    "نعجتك",
    "نعجز",
    "نعجزه",
    "نعد",
    "نعدهم",
    "نعذب",
    "نعذبه",
    "نعف",
    "نعقل",
    "نعلم",
    "نعلمهم",
    "نعلن",
    "نعليك",
    "نعما",
    "نعماء",
    "نعمة",
    "نعمت",
    "نعمتك",
    "نعمته",
    "نعمتي",
    "نعمركم",
    "نعمره",
    "نعمل",
    "نعمه",
    "نعود",
    "نعيدكم",
    "نعيده",
    "نعيم",
    "نعيما",
    "نغادر",
    "نغرقهم",
    "نغفر",
    "نفاقا",
    "نفحة",
    "نفخة",
    "نفدت",
    "نفرا",
    "نفرق",
    "نفسا",
    "نفشت",
    "نفصل",
    "نفعا",
    "نفعت",
    "نفعل",
    "نفعه",
    "نفعهما",
    "نفقا",
    "نفقاتهم",
    "نفقد",
    "نفقه",
    "نفورا",
    "نفوسكم",
    "نفيرا",
    "نقاتل",
    "نقبا",
    "نقتبس",
    "نقدر",
    "نقذف",
    "نقر",
    "نقرؤه",
    "نقصص",
    "نقصصهم",
    "نقصه",
    "نقضت",
    "نقضهم",
    "نقعا",
    "نقعد",
    "نقموا",
    "نقول",
    "نقيبا",
    "نقيرا",
    "نقيم",
    "نك",
    "نكاحا",
    "نكال",
    "نكالا",
    "نكتل",
    "نكتم",
    "نكثوا",
    "نكح",
    "نكحتم",
    "نكدا",
    "نكذب",
    "نكر",
    "نكرا",
    "نكرهم",
    "نكروا",
    "نكسوا",
    "نكسوها",
    "نكص",
    "نكفر",
    "نكلف",
    "نكلم",
    "نكن",
    "نكون",
    "نكير",
    "نلعنهم",
    "نمتعهم",
    "نمد",
    "نمدهم",
    "نمكن",
    "نملة",
    "نمن",
    "ننبئكم",
    "ننج",
    "ننجي",
    "ننجيك",
    "ننزل",
    "ننزله",
    "ننساكم",
    "ننساهم",
    "ننسخ",
    "ننسها",
    "ننشزها",
    "ننظر",
    "ننقصها",
    "ننكسه",
    "ننهك",
    "نهار",
    "نهارا",
    "نهاكم",
    "نهاكما",
    "نهدي",
    "نهرا",
    "نهلك",
    "نهوا",
    "نهيت",
    "نوحا",
    "نوحي",
    "نوحيه",
    "نوحيها",
    "نودي",
    "نورث",
    "نوركم",
    "نورنا",
    "نوره",
    "نورهم",
    "نوف",
    "نوله",
    "نولي",
    "نومكم",
    "نيلا",
    "هؤلاء",
    "هاؤم",
    "هاتوا",
    "هاتين",
    "هاجرن",
    "هاجروا",
    "هاد",
    "هادوا",
    "هاديا",
    "هار",
    "هاروت",
    "هامان",
    "هامدة",
    "هاهنا",
    "هاوية",
    "هباء",
    "هجرا",
    "هدا",
    "هداكم",
    "هدان",
    "هدانا",
    "هداني",
    "هداها",
    "هداهم",
    "هداي",
    "هدنا",
    "هدى",
    "هديا",
    "هديتنا",
    "هدينا",
    "هديناه",
    "هذان",
    "هربا",
    "هزوا",
    "هشيما",
    "هضما",
    "هضيم",
    "هلك",
    "هلم",
    "هلوعا",
    "هما",
    "هماز",
    "همت",
    "همزات",
    "همزة",
    "همسا",
    "هن",
    "هنالك",
    "هنيئا",
    "هواه",
    "هودا",
    "هون",
    "هونا",
    "هيت",
    "هينا",
    "هيه",
    "هيهات",
    "وآباءهم",
    "وآباؤكم",
    "وآباؤنا",
    "وآت",
    "وآتاكم",
    "وآتاني",
    "وآتاه",
    "وآتاهم",
    "وآتت",
    "وآتنا",
    "وآتوا",
    "وآتوهم",
    "وآتوهن",
    "وآتى",
    "وآتيتم",
    "وآتين",
    "وآتينا",
    "وآتيناه",
    "وآتيناهم",
    "وآتيناهما",
    "وآثارا",
    "وآثارهم",
    "وآثر",
    "وآخر",
    "وآخرنا",
    "وآخرون",
    "وآخرين",
    "وآل",
    "وآلهتك",
    "وآمن",
    "وآمنتم",
    "وآمنهم",
    "وآمنوا",
    "وآويناهما",
    "وآياته",
    "وآية",
    "وأبا",
    "وأباريق",
    "وأبرئ",
    "وأبشروا",
    "وأبصارا",
    "وأبصاركم",
    "وأبصارهم",
    "وأبصر",
    "وأبصرهم",
    "وأبقى",
    "وأبكارا",
    "وأبكى",
    "وأبلغكم",
    "وأبناءكم",
    "وأبناؤكم",
    "وأبنائنا",
    "وأبونا",
    "وأبى",
    "وأبيه",
    "وأتاهم",
    "وأتبعناهم",
    "وأتبعوا",
    "وأترفناهم",
    "وأتمروا",
    "وأتممت",
    "وأتممناها",
    "وأتموا",
    "وأتوا",
    "وأتوني",
    "وأتيناك",
    "وأثابهم",
    "وأثاروا",
    "وأثقالا",
    "وأثل",
    "وأجدر",
    "وأجر",
    "وأجرا",
    "وأجل",
    "وأجلب",
    "وأجمعوا",
    "وأحاط",
    "وأحاطت",
    "وأحباؤه",
    "وأحسن",
    "وأحسنوا",
    "وأحصوا",
    "وأحصى",
    "وأحضرت",
    "وأحل",
    "وأحلت",
    "وأحلوا",
    "وأحيا",
    "وأحيط",
    "وأحيي",
    "وأحييتنا",
    "وأحيينا",
    "وأخاف",
    "وأخاه",
    "وأخبتوا",
    "وأخذ",
    "وأخذت",
    "وأخذتم",
    "وأخذن",
    "وأخذنا",
    "وأخذناهم",
    "وأخذهم",
    "وأخذوا",
    "وأخر",
    "وأخرت",
    "وأخرج",
    "وأخرجت",
    "وأخرجنا",
    "وأخرجني",
    "وأخرجوا",
    "وأخرجوكم",
    "وأخرجوهم",
    "وأخرى",
    "وأخفى",
    "وأخلصوا",
    "وأخواتكم",
    "وأخوك",
    "وأخوه",
    "وأخي",
    "وأخيه",
    "وأداء",
    "وأدبار",
    "وأدبارهم",
    "وأدخل",
    "وأدخلنا",
    "وأدخلناه",
    "وأدخلناهم",
    "وأدخلني",
    "وأدخلهم",
    "وأدعو",
    "وأذان",
    "وأذن",
    "وأذنت",
    "وأرادوا",
    "وأرجلكم",
    "وأرجلهم",
    "وأرجلهن",
    "وأرسل",
    "وأرسلنا",
    "وأرسلناك",
    "وأرسلناه",
    "وأرض",
    "وأرضا",
    "وأرنا",
    "وأرى",
    "وأزلفت",
    "وأزلفنا",
    "وأزواج",
    "وأزواجكم",
    "وأزواجه",
    "وأزواجهم",
    "وأسبغ",
    "وأسرحكن",
    "وأسررت",
    "وأسروا",
    "وأسروه",
    "وأسقيناكم",
    "وأسلحتهم",
    "وأسلمت",
    "وأسلموا",
    "وأسلنا",
    "وأسمع",
    "وأسيرا",
    "وأشد",
    "وأشربوا",
    "وأشرقت",
    "وأشرك",
    "وأشركه",
    "وأشعارها",
    "وأشفقن",
    "وأشهدهم",
    "وأشهدوا",
    "وأصابه",
    "وأصبح",
    "وأصحاب",
    "وأصروا",
    "وأصفاكم",
    "وأصلح",
    "وأصلحا",
    "وأصلحنا",
    "وأصلحوا",
    "وأصيلا",
    "وأضعف",
    "وأضل",
    "وأضله",
    "وأضلهم",
    "وأضلوا",
    "وأطراف",
    "وأطعموا",
    "وأطعن",
    "وأطعنا",
    "وأطغى",
    "وأطهر",
    "وأطيعوا",
    "وأطيعون",
    "وأظهره",
    "وأعانه",
    "وأعتدت",
    "وأعتدنا",
    "وأعتزلكم",
    "وأعد",
    "وأعدوا",
    "وأعرض",
    "وأعز",
    "وأعطى",
    "وأعظم",
    "وأعلم",
    "وأعمى",
    "وأعناب",
    "وأعنابا",
    "وأعوذ",
    "وأعينهم",
    "وأغرقنا",
    "وأغطش",
    "وأغلالا",
    "وأفئدة",
    "وأفئدتهم",
    "وأفوض",
    "وأقام",
    "وأقاموا",
    "وأقبل",
    "وأقبلوا",
    "وأقرب",
    "وأقرضتم",
    "وأقرضوا",
    "وأقسطوا",
    "وأقسموا",
    "وأقل",
    "وأقم",
    "وأقمن",
    "وأقنى",
    "وأقوم",
    "وأقيموا",
    "وأكثر",
    "وأكثرهم",
    "وأكدى",
    "وأكلهم",
    "وأكن",
    "وأكواب",
    "وأكيد",
    "وألحقني",
    "وألزمهم",
    "وألسنتهم",
    "وألف",
    "وألفيا",
    "وألق",
    "وألقت",
    "وألقوا",
    "وألقوه",
    "وألقى",
    "وألقي",
    "وألقيت",
    "وألقينا",
    "وألنا",
    "وألوانكم",
    "وأما",
    "وأمتعتكم",
    "وأمددناكم",
    "وأمددناهم",
    "وأمر",
    "وأمرت",
    "وأمرنا",
    "وأمره",
    "وأمرهم",
    "وأمروا",
    "وأمطرنا",
    "وأملى",
    "وأملي",
    "وأمم",
    "وأمنا",
    "وأمه",
    "وأمهات",
    "وأمهاتكم",
    "وأمواتا",
    "وأموال",
    "وأموالا",
    "وأموالهم",
    "وأمي",
    "وأميت",
    "وأن",
    "وأنا",
    "وأناب",
    "وأنابوا",
    "وأناسي",
    "وأنبئكم",
    "وأنبتت",
    "وأنبتنا",
    "وأنبتها",
    "وأنت",
    "وأنتم",
    "وأنثى",
    "وأنجينا",
    "وأنذر",
    "وأنذرهم",
    "وأنزل",
    "وأنزلنا",
    "وأنشأنا",
    "وأنصتوا",
    "وأنصح",
    "وأنعام",
    "وأنعمت",
    "وأنفسكم",
    "وأنفسنا",
    "وأنفسهم",
    "وأنفقوا",
    "وأنك",
    "وأنكحوا",
    "وأنكم",
    "وأنه",
    "وأنهار",
    "وأنهارا",
    "وأنهم",
    "وأنى",
    "وأني",
    "وأنيبوا",
    "وأهديك",
    "وأهش",
    "وأهل",
    "وأهلك",
    "وأهلكنا",
    "وأهلنا",
    "وأهله",
    "وأهلها",
    "وأهلونا",
    "وأهلي",
    "وأهليكم",
    "وأهليهم",
    "وأوبارها",
    "وأوتيت",
    "وأوتينا",
    "وأوجس",
    "وأوحى",
    "وأوحي",
    "وأوحينا",
    "وأوذوا",
    "وأورثكم",
    "وأورثنا",
    "وأورثناها",
    "وأوصاني",
    "وأوفوا",
    "وأولئك",
    "وأولئكم",
    "وأولات",
    "وأولادا",
    "وأولادكم",
    "وأولادهم",
    "وأولو",
    "وأولي",
    "وأياما",
    "وأيدكم",
    "وأيدناه",
    "وأيده",
    "وأيدهم",
    "وأيدي",
    "وأيديكم",
    "وأيديهم",
    "وأيمانهم",
    "وأيوب",
    "وإبراهيم",
    "وإثما",
    "وإثمك",
    "وإثمهما",
    "وإخراج",
    "وإخوان",
    "وإخوانكم",
    "وإخوانهم",
    "وإخوته",
    "وإدبار",
    "وإدريس",
    "وإذ",
    "وإذا",
    "وإرصادا",
    "وإستبرق",
    "وإسحاق",
    "وإسرائيل",
    "وإسرافنا",
    "وإسماعيل",
    "وإقام",
    "وإلا",
    "وإله",
    "وإلهكم",
    "وإلهنا",
    "وإلى",
    "وإلي",
    "وإلياس",
    "وإليك",
    "وإلينا",
    "وإليه",
    "وإما",
    "وإمائكم",
    "وإن",
    "وإنا",
    "وإناثا",
    "وإنك",
    "وإنكم",
    "وإنما",
    "وإننا",
    "وإنني",
    "وإنه",
    "وإنها",
    "وإنهم",
    "وإنهما",
    "وإني",
    "وإياك",
    "وإياكم",
    "وإياهم",
    "وإياي",
    "وإيتاء",
    "وابتغ",
    "وابتغاء",
    "وابتغاؤكم",
    "وابتغوا",
    "وابتلوا",
    "وابعث",
    "وابل",
    "وابن",
    "وابنها",
    "وابيضت",
    "واتبع",
    "واتبعت",
    "واتبعتهم",
    "واتبعك",
    "واتبعنا",
    "واتبعوا",
    "واتبعون",
    "واتبعوه",
    "واتخذ",
    "واتخذتموه",
    "واتخذوا",
    "واترك",
    "واتق",
    "واتقوا",
    "واتقون",
    "واتقوه",
    "واتقى",
    "واتقين",
    "واتل",
    "واثقكم",
    "واجتبينا",
    "واجتبيناهم",
    "واجتنبوا",
    "واجعل",
    "واجعلنا",
    "واجعلني",
    "واجعله",
    "واجعلوا",
    "واجفة",
    "واجنبني",
    "واحدا",
    "واحذرهم",
    "واحذروا",
    "واحصروهم",
    "واحفظوا",
    "واحلل",
    "واختار",
    "واختلاف",
    "واختلفوا",
    "واخشوا",
    "واخشون",
    "واخشوني",
    "واخفض",
    "واد",
    "وادخلوا",
    "وادخلي",
    "وادع",
    "وادعوا",
    "وادعوه",
    "وادكر",
    "واديا",
    "واذكر",
    "واذكرن",
    "واذكروا",
    "واذكروه",
    "وارتابت",
    "وارتبتم",
    "وارتقبوا",
    "وارجعوا",
    "وارجوا",
    "وارحم",
    "وارحمنا",
    "واردها",
    "واردهم",
    "واردون",
    "وارزق",
    "وارزقنا",
    "وارزقهم",
    "وارزقوهم",
    "وارعوا",
    "واركعوا",
    "واركعي",
    "وازدادوا",
    "وازدجر",
    "وازرة",
    "وازينت",
    "واسأل",
    "واسألهم",
    "واسألوا",
    "واستبقا",
    "واستحيوا",
    "واسترهبوهم",
    "واستشهدوا",
    "واستعمركم",
    "واستعينوا",
    "واستغشوا",
    "واستغفر",
    "واستغفره",
    "واستغفروا",
    "واستغفروه",
    "واستغفري",
    "واستغنى",
    "واستفتحوا",
    "واستفزز",
    "واستقم",
    "واستكبر",
    "واستكبرت",
    "واستكبرتم",
    "واستكبروا",
    "واستمتعتم",
    "واستمع",
    "واستوت",
    "واستوى",
    "واستيقنتها",
    "واسجد",
    "واسجدوا",
    "واسجدي",
    "واسعا",
    "واسمع",
    "واسمعوا",
    "واشتروا",
    "واشتعل",
    "واشدد",
    "واشربوا",
    "واشربي",
    "واشكروا",
    "واشهد",
    "واشهدوا",
    "واصب",
    "واصبا",
    "واصبر",
    "واصبروا",
    "واصطبر",
    "واصطفاك",
    "واصطنعتك",
    "واصفح",
    "واصفحوا",
    "واصنع",
    "واضرب",
    "واضربوا",
    "واضربوهن",
    "واضمم",
    "واطمأنوا",
    "واعبد",
    "واعبدوا",
    "واعبدوه",
    "واعتصموا",
    "واعدنا",
    "واعف",
    "واعلم",
    "واعلموا",
    "واعملوا",
    "واعية",
    "واغضض",
    "واغفر",
    "واغلظ",
    "وافعلوا",
    "واقترب",
    "واقتلوهم",
    "واقصد",
    "واقعدوا",
    "واكتب",
    "واكسوهم",
    "واكفروا",
    "وال",
    "والآخر",
    "والآخرة",
    "والآخرين",
    "والآصال",
    "والأبرص",
    "والأبصار",
    "والأحبار",
    "والأحزاب",
    "والأذن",
    "والأذى",
    "والأرحام",
    "والأرض",
    "والأزلام",
    "والأسباط",
    "والأصم",
    "والأعناب",
    "والأعناق",
    "والأغلال",
    "والأفئدة",
    "والأقدام",
    "والأقربون",
    "والأقربين",
    "والأمر",
    "والأميين",
    "والأنثى",
    "والأنصاب",
    "والأنصار",
    "والأنعام",
    "والأنف",
    "والأنفس",
    "والأولاد",
    "والأولى",
    "والأولين",
    "والإبكار",
    "والإثم",
    "والإحسان",
    "والإشراق",
    "والإكرام",
    "والإنجيل",
    "والإنس",
    "والإيمان",
    "والباد",
    "والباطل",
    "والباطن",
    "والباقيات",
    "والبحر",
    "والبدن",
    "والبصر",
    "والبصير",
    "والبغال",
    "والبغضاء",
    "والبغي",
    "والبلد",
    "والبنون",
    "والبنين",
    "والبيت",
    "والترائب",
    "والتفت",
    "والتقوى",
    "والتوراة",
    "والتي",
    "والتين",
    "والثمرات",
    "والجار",
    "والجان",
    "والجبال",
    "والجبلة",
    "والجراد",
    "والجروح",
    "والجسم",
    "والجلود",
    "والجن",
    "والجوع",
    "والحافظات",
    "والحافظون",
    "والحافظين",
    "والحب",
    "والحج",
    "والحجارة",
    "والحرث",
    "والحرمات",
    "والحساب",
    "والحق",
    "والحكم",
    "والحكمة",
    "والحمد",
    "والحمير",
    "والحياة",
    "والخاشعات",
    "والخاشعين",
    "والخامسة",
    "والخبيثون",
    "والخنازير",
    "والخوف",
    "والخير",
    "والخيل",
    "والد",
    "والدار",
    "والدة",
    "والدتك",
    "والدم",
    "والده",
    "والدواب",
    "والدي",
    "والذاريات",
    "والذاكرات",
    "والذاكرين",
    "والذكر",
    "والذين",
    "والراسخون",
    "والربانيون",
    "والرجز",
    "والرسول",
    "والرقيم",
    "والركب",
    "والركع",
    "والرمان",
    "والرهبان",
    "والروح",
    "والريحان",
    "والزاني",
    "والزانية",
    "والزبر",
    "والزرع",
    "والزكاة",
    "والزيتون",
    "والسائلين",
    "والسابحات",
    "والسابقون",
    "والسارق",
    "والسارقة",
    "والساعة",
    "والسحاب",
    "والسراء",
    "والسعة",
    "والسقف",
    "والسلاسل",
    "والسلام",
    "والسلوى",
    "والسماء",
    "والسماوات",
    "والسميع",
    "والسن",
    "والسوء",
    "والسيئات",
    "والشجر",
    "والشجرة",
    "والشعراء",
    "والشفع",
    "والشمائل",
    "والشمس",
    "والشهادة",
    "والشهداء",
    "والشهر",
    "والشياطين",
    "والصائمات",
    "والصائمين",
    "والصابئون",
    "والصابئين",
    "والصابرات",
    "والصابرين",
    "والصاحب",
    "والصادقات",
    "والصادقين",
    "والصافات",
    "والصالحين",
    "والصبح",
    "والصديقين",
    "والصلاة",
    "والصلح",
    "والصيف",
    "والضحى",
    "والضراء",
    "والضفادع",
    "والضلال",
    "والطارق",
    "والطاغوت",
    "والطور",
    "والطيب",
    "والطيبات",
    "والطيبون",
    "والطير",
    "والظالمون",
    "والظالمين",
    "والظاهر",
    "والعاديات",
    "والعافين",
    "والعاقبة",
    "والعاكفين",
    "والعاملين",
    "والعبد",
    "والعدوان",
    "والعذاب",
    "والعزى",
    "والعشي",
    "والعصر",
    "والعصيان",
    "والعمرة",
    "والعمل",
    "والعنهم",
    "والعير",
    "والعين",
    "والغارمين",
    "والغاوون",
    "والغنم",
    "والغوا",
    "والفؤاد",
    "والفتح",
    "والفتنة",
    "والفجر",
    "والفحشاء",
    "والفرقان",
    "والفسوق",
    "والفضة",
    "والفلك",
    "والفواحش",
    "والقائلين",
    "والقائمين",
    "والقاسية",
    "والقانتات",
    "والقانتين",
    "والقرآن",
    "والقلائد",
    "والقلم",
    "والقمر",
    "والقناطير",
    "والقواعد",
    "والكاظمين",
    "والكافرون",
    "والكافرين",
    "والكتاب",
    "والكفار",
    "واللائي",
    "واللاتي",
    "واللذان",
    "والله",
    "والليل",
    "والمؤتفكات",
    "والمؤتفكة",
    "والمؤتون",
    "والمؤلفة",
    "والمؤمنات",
    "والمؤمنون",
    "والمؤمنين",
    "والمتردية",
    "والمتصدقات",
    "والمتصدقين",
    "والمجاهدون",
    "والمجوس",
    "والمحروم",
    "والمحصنات",
    "والمرجان",
    "والمرجفون",
    "والمرسلات",
    "والمروة",
    "والمساكين",
    "والمستضعفين",
    "والمستغفرين",
    "والمسجد",
    "والمسكنة",
    "والمسكين",
    "والمسلمات",
    "والمسيح",
    "والمشركات",
    "والمشركين",
    "والمصدقات",
    "والمطلقات",
    "والمطلوب",
    "والمعتر",
    "والمغارب",
    "والمغرب",
    "والمغفرة",
    "والمقيمي",
    "والمقيمين",
    "والمكذبين",
    "والملائكة",
    "والملك",
    "والمنافقات",
    "والمنافقين",
    "والمنخنقة",
    "والمنفقين",
    "والمنكر",
    "والمهاجرين",
    "والموتى",
    "والموعظة",
    "والموفون",
    "والموقوذة",
    "والميزان",
    "والميسر",
    "والنار",
    "والنازعات",
    "والناس",
    "والناشرات",
    "والناشطات",
    "والناهون",
    "والنبوة",
    "والنبي",
    "والنبيون",
    "والنبيين",
    "والنجم",
    "والنجوم",
    "والنخل",
    "والنخيل",
    "والنذر",
    "والنساء",
    "والنسل",
    "والنصارى",
    "والنطيحة",
    "والنهار",
    "والنور",
    "والنوم",
    "والنوى",
    "والهدى",
    "والهدي",
    "والوالدات",
    "والوتر",
    "والوزن",
    "والولدان",
    "واليتامى",
    "واليسع",
    "واليوم",
    "وامتازوا",
    "وامرأة",
    "وامرأت",
    "وامرأتان",
    "وامرأته",
    "وامرأتي",
    "وامسحوا",
    "وامضوا",
    "وانتصروا",
    "وانتظر",
    "وانتظروا",
    "وانحر",
    "وانشق",
    "وانشقت",
    "وانصرنا",
    "وانصروا",
    "وانطلق",
    "وانظر",
    "وانظرنا",
    "وانظروا",
    "وانقلبوا",
    "وانه",
    "واهجرني",
    "واهجرهم",
    "واهجروهن",
    "واهدنا",
    "واهية",
    "وبأيمانهم",
    "وبئر",
    "وبئس",
    "وباءوا",
    "وبارك",
    "وباركنا",
    "وباطل",
    "وباطنة",
    "وباطنه",
    "وبال",
    "وبالآخرة",
    "وبالأسحار",
    "وبالحق",
    "وبالذي",
    "وبالرسول",
    "وبالزبر",
    "وبالكتاب",
    "وبالليل",
    "وبالمؤمنين",
    "وبالنجم",
    "وبالوالدين",
    "وباليوم",
    "وبث",
    "وبدأ",
    "وبدا",
    "وبدارا",
    "وبداره",
    "وبدلناهم",
    "وبذلك",
    "وبذي",
    "وبرا",
    "وبرحمته",
    "وبرزت",
    "وبرزوا",
    "وبرسوله",
    "وبرسولي",
    "وبرق",
    "وبركات",
    "وبركاته",
    "وبست",
    "وبسر",
    "وبشر",
    "وبشرناه",
    "وبشروه",
    "وبشرى",
    "وبشير",
    "وبصدهم",
    "وبصلها",
    "وبطل",
    "وبعثنا",
    "وبعهد",
    "وبعولتهن",
    "وبقية",
    "وبكفرهم",
    "وبكلامي",
    "وبكم",
    "وبكما",
    "وبكيا",
    "وبلغ",
    "وبلغت",
    "وبلغنا",
    "وبلوناهم",
    "وبمن",
    "وبنات",
    "وبناتك",
    "وبناتكم",
    "وبنعمة",
    "وبنعمت",
    "وبني",
    "وبنين",
    "وبنينا",
    "وبنيه",
    "وبه",
    "وبوأكم",
    "وبيع",
    "وبيلا",
    "وبين",
    "وبينات",
    "وبينك",
    "وبينكم",
    "وبينه",
    "وبينهم",
    "وبينهما",
    "وبينوا",
    "وتأبى",
    "وتأتون",
    "وتأسرون",
    "وتأكلون",
    "وتؤتوها",
    "وتؤمنون",
    "وتؤوي",
    "وتاب",
    "وتالله",
    "وتب",
    "وتبارك",
    "وتبتل",
    "وتبرئ",
    "وتبغونها",
    "وتبين",
    "وتتخذون",
    "وتتقوا",
    "وتتلقاهم",
    "وتثبيتا",
    "وتجارة",
    "وتجاهدون",
    "وتجعلون",
    "وتحبون",
    "وتحرير",
    "وتحسبهم",
    "وتحسبونه",
    "وتحشرون",
    "وتحمل",
    "وتحيتهم",
    "وتخر",
    "وتخرج",
    "وتخرجون",
    "وتخشى",
    "وتخفون",
    "وتخفي",
    "وتخلت",
    "وتخلقون",
    "وتخونوا",
    "وتدعوا",
    "وتدعونني",
    "وتدلوا",
    "وتذرون",
    "وتذكيري",
    "وتذل",
    "وتذهب",
    "وتذوقوا",
    "وتراهم",
    "وتربصتم",
    "وترجون",
    "وترحمنا",
    "وترحمني",
    "وترزق",
    "وترغبون",
    "وتركتم",
    "وتركنا",
    "وتركهم",
    "وتركوك",
    "وترهقهم",
    "وترى",
    "وتزكيهم",
    "وتزهق",
    "وتزودوا",
    "وتسبحوه",
    "وتسبيحه",
    "وتستخرجوا",
    "وتستخرجون",
    "وتسعون",
    "وتسلموا",
    "وتسليما",
    "وتسود",
    "وتسير",
    "وتشاور",
    "وتشتكي",
    "وتشهد",
    "وتصدق",
    "وتصدون",
    "وتصدية",
    "وتصريف",
    "وتصف",
    "وتصفحوا",
    "وتصلحوا",
    "وتصلية",
    "وتضحكون",
    "وتضع",
    "وتطمئن",
    "وتظنون",
    "وتعاونوا",
    "وتعز",
    "وتعزروه",
    "وتعلم",
    "وتعيها",
    "وتغشى",
    "وتغفروا",
    "وتفاخر",
    "وتفريقا",
    "وتفصيل",
    "وتفصيلا",
    "وتفقد",
    "وتقبل",
    "وتقسطوا",
    "وتقطعت",
    "وتقطعوا",
    "وتقطعون",
    "وتقلبك",
    "وتقواها",
    "وتقول",
    "وتقولوا",
    "وتقولون",
    "وتكاثر",
    "وتكتموا",
    "وتكتمون",
    "وتكفرون",
    "وتكلمنا",
    "وتكونوا",
    "وتلذ",
    "وتلك",
    "وتله",
    "وتماثيل",
    "وتمت",
    "وتمتعوا",
    "وتناجوا",
    "وتنازعتم",
    "وتنحتون",
    "وتنذر",
    "وتنزع",
    "وتنسون",
    "وتنشق",
    "وتنهون",
    "وتهدي",
    "وتواصوا",
    "وتوبوا",
    "وتودون",
    "وتوفنا",
    "وتوفى",
    "وتوفيقا",
    "وتوقروه",
    "وتوكل",
    "وتول",
    "وتولج",
    "وتولوا",
    "وتولى",
    "وثاقه",
    "وثامنهم",
    "وثبت",
    "وثقالا",
    "وثلاث",
    "وثلة",
    "وثلثه",
    "وثمانية",
    "وثمود",
    "وثيابك",
    "وجئتك",
    "وجئتكم",
    "وجئنا",
    "وجاء",
    "وجاءت",
    "وجاءته",
    "وجاءتهم",
    "وجاءك",
    "وجاءكم",
    "وجاءه",
    "وجاءهم",
    "وجاءوا",
    "وجادلهم",
    "وجادلوا",
    "وجاعل",
    "وجاعلوه",
    "وجاهد",
    "وجاهدهم",
    "وجاهدوا",
    "وجاوزنا",
    "وجبت",
    "وجبريل",
    "وجحدوا",
    "وجحيما",
    "وجدت",
    "وجدتم",
    "وجدتموهم",
    "وجدكم",
    "وجدنا",
    "وجدناه",
    "وجدها",
    "وجدوا",
    "وجرين",
    "وجزاء",
    "وجزاهم",
    "وجعل",
    "وجعلت",
    "وجعلكم",
    "وجعلنا",
    "وجعلناكم",
    "وجعلناه",
    "وجعلناها",
    "وجعلناهم",
    "وجعلني",
    "وجعلها",
    "وجعلوا",
    "وجفان",
    "وجلة",
    "وجلت",
    "وجلودهم",
    "وجلون",
    "وجمع",
    "وجنات",
    "وجنة",
    "وجنت",
    "وجنوبهم",
    "وجنود",
    "وجنودا",
    "وجنوده",
    "وجنودهما",
    "وجنى",
    "وجهاد",
    "وجهت",
    "وجهرا",
    "وجهركم",
    "وجهك",
    "وجهه",
    "وجهها",
    "وجهي",
    "وجوه",
    "وجوها",
    "وجوهكم",
    "وجوههم",
    "وجيء",
    "وجيها",
    "وحاجه",
    "وحاق",
    "وحال",
    "وحب",
    "وحبط",
    "وحبل",
    "وحجرا",
    "وحدائق",
    "وحده",
    "وحرام",
    "وحرث",
    "وحرض",
    "وحرم",
    "وحرمنا",
    "وحرموا",
    "وحريرا",
    "وحزنا",
    "وحزني",
    "وحسبوا",
    "وحسن",
    "وحسنت",
    "وحشر",
    "وحشرنا",
    "وحشرناهم",
    "وحصل",
    "وحصورا",
    "وحصيد",
    "وحفدة",
    "وحفظا",
    "وحفظناها",
    "وحففناهما",
    "وحق",
    "وحقت",
    "وحكما",
    "وحكمة",
    "وحلائل",
    "وحلالا",
    "وحلوا",
    "وحمر",
    "وحملت",
    "وحملناه",
    "وحملناهم",
    "وحمله",
    "وحملها",
    "وحميم",
    "وحنانا",
    "وحور",
    "وحيا",
    "وحيث",
    "وحيل",
    "وحين",
    "وحيه",
    "وخاب",
    "وخاتم",
    "وخاف",
    "وخافون",
    "وخالاتكم",
    "وختم",
    "وخذ",
    "وخذوا",
    "وخذوهم",
    "وخر",
    "وخرقوا",
    "وخروا",
    "وخسر",
    "وخسف",
    "وخشعت",
    "وخشي",
    "وخضتم",
    "وخفية",
    "وخلق",
    "وخلقته",
    "وخلقنا",
    "وخلقناكم",
    "وخلقهم",
    "وخير",
    "وخيفة",
    "ودا",
    "وداعيا",
    "ودانية",
    "وداوود",
    "ودت",
    "ودخل",
    "ودرسوا",
    "ودسر",
    "ودع",
    "ودعك",
    "ودم",
    "ودمرنا",
    "ودوا",
    "ودون",
    "وديارهم",
    "ودين",
    "وذا",
    "وذات",
    "وذر",
    "وذرني",
    "وذروا",
    "وذرياتنا",
    "وذرياتهم",
    "وذرية",
    "وذريته",
    "وذريتها",
    "وذكر",
    "وذكرا",
    "وذكرهم",
    "وذكروا",
    "وذكرى",
    "وذلة",
    "وذلك",
    "وذلكم",
    "وذللت",
    "وذللناها",
    "وذو",
    "وذوقوا",
    "وذي",
    "ورأوا",
    "ورأى",
    "ورأيت",
    "ورأيتهم",
    "ورئاء",
    "ورئيا",
    "وراءكم",
    "وراءه",
    "وراءهم",
    "ورائكم",
    "ورائهم",
    "ورائي",
    "ورابطوا",
    "وراعنا",
    "ورافعك",
    "وراودته",
    "ورب",
    "وربائبكم",
    "ورباع",
    "وربت",
    "وربطنا",
    "وربك",
    "وربكم",
    "وربنا",
    "وربي",
    "ورتل",
    "ورتلناه",
    "ورثة",
    "ورثوا",
    "ورجلا",
    "ورجلك",
    "ورحمة",
    "ورحمت",
    "ورحمته",
    "ورحمتي",
    "وردا",
    "وردوا",
    "وردوها",
    "ورزق",
    "ورزقا",
    "ورزقكم",
    "ورزقناهم",
    "ورزقني",
    "ورسالاته",
    "ورسلا",
    "ورسلنا",
    "ورسله",
    "ورسلي",
    "ورسول",
    "ورسولا",
    "ورسوله",
    "ورضوا",
    "ورضوان",
    "ورضوانا",
    "ورضي",
    "ورضيت",
    "ورعد",
    "ورفاتا",
    "ورفع",
    "ورفعنا",
    "ورفعناه",
    "ورماحكم",
    "ورمان",
    "ورهبا",
    "ورهبانا",
    "ورهبانهم",
    "ورهبانية",
    "ورواحها",
    "وروح",
    "وريحان",
    "وريشا",
    "وزادكم",
    "وزاده",
    "وزادهم",
    "وزخرفا",
    "وزدناهم",
    "وزر",
    "وزرا",
    "وزرابي",
    "وزرع",
    "وزرك",
    "وزروع",
    "وزفيرا",
    "وزكاة",
    "وزكريا",
    "وزلزلوا",
    "وزلفا",
    "وزنا",
    "وزنوا",
    "وزنوهم",
    "وزهق",
    "وزوجك",
    "وزوجناهم",
    "وزوجه",
    "وزورا",
    "وزيادة",
    "وزيتونا",
    "وزيرا",
    "وزين",
    "وزينا",
    "وزيناها",
    "وزينة",
    "وزينتها",
    "وزينه",
    "وساء",
    "وساءت",
    "وسار",
    "وسارب",
    "وسارعوا",
    "وسبح",
    "وسبحان",
    "وسبحه",
    "وسبحوا",
    "وسبحوه",
    "وسبع",
    "وسبعة",
    "وسبلا",
    "وستردون",
    "وسخر",
    "وسخرنا",
    "وسرابيل",
    "وسراجا",
    "وسرحوهن",
    "وسررا",
    "وسرورا",
    "وسطا",
    "وسعة",
    "وسعت",
    "وسعر",
    "وسعها",
    "وسعى",
    "وسعيد",
    "وسعيرا",
    "وسفرا",
    "وسق",
    "وسقاهم",
    "وسقوا",
    "وسقياها",
    "وسكنتم",
    "وسلام",
    "وسلاما",
    "وسلطان",
    "وسلك",
    "وسلموا",
    "وسليمان",
    "وسمعنا",
    "وسمعهم",
    "وسنجزي",
    "وسنزيد",
    "وسنقول",
    "وسواء",
    "وسوف",
    "وسيجزي",
    "وسيجنبها",
    "وسيحبط",
    "وسيحلفون",
    "وسيدا",
    "وسيرت",
    "وسيرى",
    "وسيصلون",
    "وسيعلم",
    "وسيق",
    "وشاركهم",
    "وشاقوا",
    "وشاهد",
    "وشاورهم",
    "وشجرة",
    "وشددنا",
    "وشراب",
    "وشرابك",
    "وشركاءكم",
    "وشركاؤكم",
    "وشروه",
    "وشفاء",
    "وشفتين",
    "وشقاق",
    "وشمال",
    "وشهبا",
    "وشهد",
    "وشهدوا",
    "وشهيد",
    "وشهيق",
    "وشيء",
    "وشيبة",
    "وصابروا",
    "وصاحبته",
    "وصاحبهما",
    "وصاكم",
    "وصالح",
    "وصبروا",
    "وصبغ",
    "وصد",
    "وصدف",
    "وصدق",
    "وصدقت",
    "وصدها",
    "وصدوا",
    "وصدوكم",
    "وصرفنا",
    "وصفهم",
    "وصلنا",
    "وصلوات",
    "وصما",
    "وصموا",
    "وصهرا",
    "وصوركم",
    "وصى",
    "وصيلة",
    "وصينا",
    "وضائق",
    "وضاق",
    "وضاقت",
    "وضحاها",
    "وضرب",
    "وضربت",
    "وضربنا",
    "وضعتها",
    "وضعف",
    "وضل",
    "وضلوا",
    "وضياء",
    "وطئا",
    "وطائفة",
    "وطبع",
    "وطرا",
    "وطعام",
    "وطعاما",
    "وطعامكم",
    "وطعامه",
    "وطعنا",
    "وطعنوا",
    "وطفقا",
    "وطلح",
    "وطمعا",
    "وطهر",
    "وطهرك",
    "وطور",
    "وظالم",
    "وظاهره",
    "وظاهروا",
    "وظل",
    "وظلالهم",
    "وظللنا",
    "وظلما",
    "وظلموا",
    "وظلها",
    "وظن",
    "وظننتم",
    "وظنوا",
    "وظهر",
    "وظهورهم",
    "وعاد",
    "وعادا",
    "وعاشروهن",
    "وعباد",
    "وعبد",
    "وعبقري",
    "وعتوا",
    "وعجبوا",
    "وعجلت",
    "وعدا",
    "وعدتنا",
    "وعدتهم",
    "وعدده",
    "وعدسها",
    "وعدك",
    "وعدكم",
    "وعدلا",
    "وعدنا",
    "وعدناه",
    "وعدناهم",
    "وعده",
    "وعدها",
    "وعدهم",
    "وعدو",
    "وعدوا",
    "وعدوكم",
    "وعدوه",
    "وعذاب",
    "وعذابا",
    "وعذب",
    "وعذبناها",
    "وعربي",
    "وعرضنا",
    "وعرضوا",
    "وعزرتموهم",
    "وعزروه",
    "وعزني",
    "وعسى",
    "وعشرا",
    "وعشيا",
    "وعشيرتكم",
    "وعصوا",
    "وعصى",
    "وعصيتم",
    "وعصينا",
    "وعصيهم",
    "وعظاما",
    "وعظهم",
    "وعفا",
    "وعقبى",
    "وعلامات",
    "وعلانية",
    "وعلما",
    "وعلمتم",
    "وعلمتني",
    "وعلمك",
    "وعلمناه",
    "وعلمه",
    "وعلوا",
    "وعليكم",
    "وعلينا",
    "وعليه",
    "وعليها",
    "وعليهم",
    "وعماتكم",
    "وعمارة",
    "وعمروها",
    "وعمل",
    "وعمله",
    "وعملوا",
    "وعميانا",
    "وعن",
    "وعنب",
    "وعنبا",
    "وعنت",
    "وعند",
    "وعندنا",
    "وعنده",
    "وعندهم",
    "وعهدنا",
    "وعهدهم",
    "وعيد",
    "وعيسى",
    "وعيون",
    "وغدوا",
    "وغرابيب",
    "وغرتكم",
    "وغرتهم",
    "وغركم",
    "وغرهم",
    "وغساق",
    "وغساقا",
    "وغضب",
    "وغفر",
    "وغلقت",
    "وغواص",
    "وغيض",
    "وفار",
    "وفاقا",
    "وفاكهة",
    "وفتح",
    "وفتحت",
    "وفتناك",
    "وفجرنا",
    "وفدا",
    "وفديناه",
    "وفرادى",
    "وفرحوا",
    "وفرش",
    "وفرشا",
    "وفرضناها",
    "وفرعها",
    "وفرعون",
    "وفريق",
    "وفريقا",
    "وفساد",
    "وفصاله",
    "وفصل",
    "وفصيلته",
    "وفضل",
    "وفضلا",
    "وفضلناهم",
    "وفعلت",
    "وفواكه",
    "وفومها",
    "وفى",
    "وفيكم",
    "وفيه",
    "وفيها",
    "وقائما",
    "وقابل",
    "وقاتل",
    "وقاتلوا",
    "وقاتلوهم",
    "وقارا",
    "وقارون",
    "وقاسمهما",
    "وقال",
    "وقالا",
    "وقالت",
    "وقالوا",
    "وقب",
    "وقبائل",
    "وقبل",
    "وقبيله",
    "وقتل",
    "وقتلت",
    "وقتلهم",
    "وقتلوا",
    "وقثائها",
    "وقد",
    "وقدت",
    "وقدرنا",
    "وقدره",
    "وقدمنا",
    "وقدموا",
    "وقدور",
    "وقذف",
    "وقر",
    "وقرآن",
    "وقرآنا",
    "وقرآنه",
    "وقرا",
    "وقربناه",
    "وقرن",
    "وقرونا",
    "وقري",
    "وقص",
    "وقصر",
    "وقضبا",
    "وقضى",
    "وقضي",
    "وقضينا",
    "وقطعن",
    "وقطعنا",
    "وقطعناهم",
    "وقعد",
    "وقعدوا",
    "وقعودا",
    "وقفوا",
    "وقفوهم",
    "وقفينا",
    "وقل",
    "وقلبه",
    "وقلبوا",
    "وقلن",
    "وقلنا",
    "وقلوبهم",
    "وقلوبهن",
    "وقليل",
    "وقمرا",
    "وقنا",
    "وقهم",
    "وقودها",
    "وقول",
    "وقولهم",
    "وقولوا",
    "وقوم",
    "وقومك",
    "وقومه",
    "وقومها",
    "وقومهم",
    "وقومهما",
    "وقوموا",
    "وقياما",
    "وقيضنا",
    "وقيل",
    "وقيله",
    "وكأس",
    "وكأسا",
    "وكأين",
    "وكادوا",
    "وكان",
    "وكانت",
    "وكانوا",
    "وكبراءنا",
    "وكبره",
    "وكبير",
    "وكتاب",
    "وكتبنا",
    "وكتبه",
    "وكثير",
    "وكذب",
    "وكذبتم",
    "وكذبوا",
    "وكذلك",
    "وكره",
    "وكرها",
    "وكرهوا",
    "وكسوتهن",
    "وكشفت",
    "وكشفنا",
    "وكف",
    "وكفر",
    "وكفرا",
    "وكفرت",
    "وكفرتم",
    "وكفرنا",
    "وكفرهم",
    "وكفروا",
    "وكفلها",
    "وكفى",
    "وكلا",
    "وكلبهم",
    "وكلم",
    "وكلما",
    "وكلماته",
    "وكلمة",
    "وكلمته",
    "وكلمه",
    "وكلمهم",
    "وكلنا",
    "وكلهم",
    "وكلوا",
    "وكم",
    "وكن",
    "وكنا",
    "وكنت",
    "وكنتم",
    "وكنوز",
    "وكهلا",
    "وكواعب",
    "وكونوا",
    "وكيف",
    "وكيلا",
    "ولآمرنهم",
    "ولأبويه",
    "ولأبين",
    "ولأتم",
    "ولأجر",
    "ولأحل",
    "ولأخي",
    "ولأدخلناهم",
    "ولأدخلنكم",
    "ولأدخلنهم",
    "ولأصلبنكم",
    "ولأضلنهم",
    "ولأغوينهم",
    "ولأمة",
    "ولأمنينهم",
    "ولأنعامكم",
    "ولأوضعوا",
    "ولؤلؤا",
    "ولإخواننا",
    "ولئن",
    "ولات",
    "ولاهم",
    "ولايتهم",
    "ولبئس",
    "ولباس",
    "ولباسهم",
    "ولبثت",
    "ولبثوا",
    "ولبيوتهم",
    "ولتأت",
    "ولتبتغوا",
    "ولتبلغوا",
    "ولتتقوا",
    "ولتجدن",
    "ولتجدنهم",
    "ولتجري",
    "ولتجزى",
    "ولتسألن",
    "ولتستبين",
    "ولتسمعن",
    "ولتصغى",
    "ولتصنع",
    "ولتطمئن",
    "ولتعرفنهم",
    "ولتعلم",
    "ولتعلمن",
    "ولتعلموا",
    "ولتعلن",
    "ولتكبروا",
    "ولتكملوا",
    "ولتكن",
    "ولتكون",
    "ولتكونن",
    "ولتنازعتم",
    "ولتنذر",
    "ولتنصرنه",
    "ولتنظر",
    "ولحم",
    "ولدا",
    "ولدار",
    "ولدان",
    "ولدنهم",
    "ولده",
    "ولدينا",
    "ولذكر",
    "ولذي",
    "ولربك",
    "ولرسوله",
    "ولزوجك",
    "ولسانا",
    "ولستم",
    "ولسليمان",
    "ولسوف",
    "ولعب",
    "ولعبا",
    "ولعبد",
    "ولعذاب",
    "ولعلا",
    "ولعلكم",
    "ولعلهم",
    "ولعنه",
    "ولعنهم",
    "ولعنوا",
    "ولقاء",
    "ولقائه",
    "ولقاهم",
    "ولقد",
    "ولقومك",
    "ولك",
    "ولكل",
    "ولكم",
    "ولكنا",
    "ولكنكم",
    "ولكنهم",
    "ولكني",
    "وللآخرة",
    "وللأرض",
    "وللبسنا",
    "وللدار",
    "وللذين",
    "وللرجال",
    "وللرسول",
    "وللسيارة",
    "وللكافرين",
    "وللمؤمنين",
    "وللمطلقات",
    "وللنساء",
    "ولله",
    "ولم",
    "ولما",
    "ولملئت",
    "ولمن",
    "ولن",
    "ولنا",
    "ولنبلونكم",
    "ولنبينه",
    "ولنجزين",
    "ولنجزينهم",
    "ولنجعلك",
    "ولنجعله",
    "ولنحمل",
    "ولنخرجنهم",
    "ولنذيقنهم",
    "ولنرسلن",
    "ولنسألن",
    "ولنسكننكم",
    "ولنصبرن",
    "ولنعلمه",
    "ولنعم",
    "ولنكونن",
    "وله",
    "ولها",
    "ولهديناهم",
    "ولهم",
    "ولهن",
    "ولهو",
    "ولهوا",
    "ولوا",
    "ولوالدي",
    "ولوالديك",
    "ولوطا",
    "ولولا",
    "ولوموا",
    "ولى",
    "وليأتينهم",
    "وليأخذوا",
    "وليؤمنوا",
    "وليا",
    "وليال",
    "وليبتلي",
    "وليبدلنهم",
    "وليبكوا",
    "وليبلي",
    "وليبينن",
    "وليتبروا",
    "وليتذكر",
    "وليتق",
    "وليتلطف",
    "وليتم",
    "وليتمتعوا",
    "وليجة",
    "وليجدوا",
    "وليحكم",
    "وليحلفن",
    "وليحملن",
    "وليخزي",
    "وليخش",
    "وليدا",
    "وليدخلوا",
    "وليدع",
    "وليذكر",
    "وليذيقكم",
    "وليربط",
    "وليرضوه",
    "وليزيدن",
    "وليس",
    "وليسألن",
    "وليسألوا",
    "وليست",
    "وليستعفف",
    "وليشهد",
    "وليصفحوا",
    "وليضربن",
    "وليطوفوا",
    "وليعفوا",
    "وليعلم",
    "وليعلمن",
    "وليعلموا",
    "وليقترفوا",
    "وليقول",
    "وليقولوا",
    "وليكتب",
    "وليكم",
    "وليكون",
    "وليكونا",
    "وليلبسوا",
    "وليمحص",
    "وليمسنكم",
    "وليمكنن",
    "وليملل",
    "ولينا",
    "ولينذروا",
    "ولينصرن",
    "وليه",
    "وليهم",
    "وليهما",
    "وليوفوا",
    "وليوفيهم",
    "وليي",
    "ومأجوج",
    "ومأواكم",
    "ومأواه",
    "ومأواهم",
    "وماتوا",
    "وماذا",
    "وماروت",
    "ومبشرا",
    "ومتاع",
    "ومتاعا",
    "ومتعناهم",
    "ومتعوهن",
    "ومثل",
    "ومثلا",
    "ومثله",
    "ومثلهم",
    "ومثواكم",
    "ومحرم",
    "ومحياي",
    "ومرساها",
    "ومرعاها",
    "ومريم",
    "ومزاجه",
    "ومزقناهم",
    "ومساجد",
    "ومساكن",
    "ومساكنكم",
    "ومستودع",
    "ومستودعها",
    "ومشارب",
    "ومشهود",
    "ومصدقا",
    "ومصيرا",
    "ومضى",
    "ومطهرك",
    "ومعارج",
    "ومعصيت",
    "ومعين",
    "ومغاربها",
    "ومغانم",
    "ومغفرة",
    "ومقام",
    "ومقاما",
    "ومقتا",
    "ومقصرين",
    "ومكر",
    "ومكرنا",
    "ومكروا",
    "وملأه",
    "وملئه",
    "وملئهم",
    "وملائكته",
    "وملك",
    "وملكا",
    "ومما",
    "ومماتهم",
    "ومماتي",
    "وممن",
    "ومنا",
    "ومناة",
    "ومنافع",
    "ومنذرين",
    "ومنك",
    "ومنكم",
    "ومنه",
    "ومنها",
    "ومنهاجا",
    "ومنهم",
    "ومهدت",
    "ومهلهم",
    "ومهيمنا",
    "ومواليكم",
    "وموسى",
    "وموعظة",
    "وميثاقه",
    "وميكال",
    "ونأى",
    "وناداهما",
    "ونادوا",
    "ونادى",
    "وناديناه",
    "ونبئهم",
    "ونباتا",
    "ونبلو",
    "ونبلوكم",
    "ونبيا",
    "ونتبع",
    "ونتجاوز",
    "ونجعل",
    "ونجعلهم",
    "ونجنا",
    "ونجني",
    "ونجواهم",
    "ونجينا",
    "ونجيناه",
    "ونجيناهم",
    "ونجيناهما",
    "ونحاس",
    "ونحشر",
    "ونحشره",
    "ونحشرهم",
    "ونحفظ",
    "ونحن",
    "ونحيا",
    "ونخرج",
    "ونخزى",
    "ونخل",
    "ونخلا",
    "ونخوفهم",
    "ونخيل",
    "ونداء",
    "وندخلكم",
    "وندخلهم",
    "ونذر",
    "ونذرهم",
    "ونذكرك",
    "ونذير",
    "ونذيرا",
    "ونذيقه",
    "ونراه",
    "ونرثه",
    "ونرد",
    "ونري",
    "ونريد",
    "ونزداد",
    "ونزع",
    "ونزعنا",
    "ونزل",
    "ونزلنا",
    "ونزلناه",
    "ونساء",
    "ونساءكم",
    "ونساءنا",
    "ونستحيي",
    "ونسرا",
    "ونسقيه",
    "ونسكي",
    "ونسوا",
    "ونسوق",
    "ونسوه",
    "ونسي",
    "ونصحت",
    "ونصرناه",
    "ونصرناهم",
    "ونصروا",
    "ونصروه",
    "ونصفه",
    "ونصله",
    "ونصيرا",
    "ونضع",
    "ونطبع",
    "ونطمع",
    "ونعلم",
    "ونعم",
    "ونعمة",
    "ونعمه",
    "ونعيم",
    "ونفاقا",
    "ونفخ",
    "ونفخت",
    "ونفس",
    "ونفصل",
    "ونفضل",
    "ونفور",
    "ونقدس",
    "ونقر",
    "ونقص",
    "ونقلب",
    "ونقلبهم",
    "ونقول",
    "ونكتب",
    "ونكفر",
    "ونكون",
    "ونلعب",
    "ونمارق",
    "ونمد",
    "ونمكن",
    "ونمنعكم",
    "ونميت",
    "ونمير",
    "وننزل",
    "وننشئكم",
    "ونهارا",
    "ونهر",
    "ونهوا",
    "ونهى",
    "ونوحا",
    "ونودوا",
    "ونور",
    "ونورهم",
    "ونيسرك",
    "وهؤلاء",
    "وهاجا",
    "وهاجروا",
    "وهارون",
    "وهامان",
    "وهب",
    "وهبت",
    "وهبنا",
    "وهداه",
    "وهدوا",
    "وهدى",
    "وهديناه",
    "وهديناهم",
    "وهديناهما",
    "وهذا",
    "وهذه",
    "وهزي",
    "وهل",
    "وهم",
    "وهما",
    "وهمت",
    "وهموا",
    "وهن",
    "وهنا",
    "وهنوا",
    "وهي",
    "وهيئ",
    "وواعدنا",
    "وواعدناكم",
    "ووالد",
    "ووجد",
    "ووجدك",
    "ووجدوا",
    "ووجوه",
    "ووحينا",
    "وودوا",
    "وورث",
    "وورثه",
    "ووري",
    "ووصى",
    "ووصينا",
    "ووضع",
    "ووضعته",
    "ووضعنا",
    "ووعدتكم",
    "ووفيت",
    "ووقانا",
    "ووقاهم",
    "ووقع",
    "وولدا",
    "وولده",
    "ووهبنا",
    "وويل",
    "ويأبى",
    "ويأت",
    "ويأتوكم",
    "ويأتينا",
    "ويأتيه",
    "ويأخذ",
    "ويأكلون",
    "ويأمركم",
    "ويأمرون",
    "ويأمنوا",
    "ويؤت",
    "ويؤتوا",
    "ويؤتون",
    "ويؤثرون",
    "ويؤخركم",
    "ويؤمن",
    "ويؤمنون",
    "ويا",
    "ويبسط",
    "ويبسطوا",
    "ويبشر",
    "ويبصرون",
    "ويبطل",
    "ويبغون",
    "ويبغونها",
    "ويبقى",
    "ويبين",
    "ويتبع",
    "ويتجنبها",
    "ويتخذ",
    "ويتخذها",
    "ويتخطف",
    "ويتربص",
    "ويتعد",
    "ويتعلمون",
    "ويتفكرون",
    "ويتقه",
    "ويتلوه",
    "ويتم",
    "ويتمتعوا",
    "ويتناجون",
    "ويتوب",
    "ويتولوا",
    "ويتيما",
    "ويثبت",
    "ويجادل",
    "ويجركم",
    "ويجزي",
    "ويجزيهم",
    "ويجعل",
    "ويجعلكم",
    "ويجعلون",
    "ويحب",
    "ويحبون",
    "ويحبونه",
    "ويحذركم",
    "ويحرم",
    "ويحرمونه",
    "ويحسبون",
    "ويحفظن",
    "ويحفظوا",
    "ويحق",
    "ويحل",
    "ويحلفون",
    "ويحمل",
    "ويحيى",
    "ويحيي",
    "ويخافون",
    "ويختار",
    "ويخرج",
    "ويخرجكم",
    "ويخرجهم",
    "ويخرون",
    "ويخزهم",
    "ويخش",
    "ويخشون",
    "ويخشونه",
    "ويخلد",
    "ويخلق",
    "ويخوفونك",
    "ويدخلكم",
    "ويدخله",
    "ويدخلهم",
    "ويدرءون",
    "ويدرأ",
    "ويدع",
    "ويدعون",
    "ويدعوننا",
    "ويذبحون",
    "ويذرك",
    "ويذرهم",
    "ويذرون",
    "ويذكر",
    "ويذكروا",
    "ويذهب",
    "ويذهبا",
    "ويذيق",
    "ويربي",
    "ويرث",
    "ويرجو",
    "ويرجون",
    "ويرحم",
    "ويرزقه",
    "ويرسل",
    "ويرضى",
    "ويرضين",
    "ويرى",
    "ويريد",
    "ويريدون",
    "ويريكم",
    "ويزداد",
    "ويزدكم",
    "ويزكيكم",
    "ويزكيهم",
    "ويزيد",
    "ويزيدهم",
    "ويسألون",
    "ويسألونك",
    "ويسارعون",
    "ويسبح",
    "ويسبحونه",
    "ويستأذن",
    "ويستبدل",
    "ويستبشرون",
    "ويستجيب",
    "ويستحيون",
    "ويستحيي",
    "ويستخرجا",
    "ويستخلف",
    "ويستخلفكم",
    "ويستعجلونك",
    "ويستغفروا",
    "ويستغفرون",
    "ويستغفرونه",
    "ويستفتونك",
    "ويستكبر",
    "ويستنبئونك",
    "ويستهزأ",
    "ويسخرون",
    "ويسر",
    "ويسعون",
    "ويسفك",
    "ويسقون",
    "ويسقى",
    "ويسقين",
    "ويسلموا",
    "ويشترون",
    "ويشرب",
    "ويشف",
    "ويشهد",
    "ويصبر",
    "ويصدكم",
    "ويصدون",
    "ويصرفه",
    "ويصلح",
    "ويصلى",
    "ويصنع",
    "ويضرب",
    "ويضع",
    "ويضل",
    "ويضيق",
    "ويطاف",
    "ويطعمون",
    "ويطهركم",
    "ويطوف",
    "ويطيعون",
    "ويعبدون",
    "ويعذب",
    "ويعظم",
    "ويعف",
    "ويعفو",
    "ويعقوب",
    "ويعلم",
    "ويعلمك",
    "ويعلمكم",
    "ويعلمه",
    "ويعلمهم",
    "ويعلمون",
    "ويعمل",
    "ويعملون",
    "ويعوق",
    "ويعيد",
    "ويغفر",
    "ويفسدون",
    "ويفعل",
    "ويفعلون",
    "ويقبضن",
    "ويقبضون",
    "ويقتلون",
    "ويقدر",
    "ويقذفون",
    "ويقطع",
    "ويقطعون",
    "ويقللكم",
    "ويقول",
    "ويقولوا",
    "ويقولون",
    "ويقيموا",
    "ويقيمون",
    "ويكأن",
    "ويكأنه",
    "ويكتمون",
    "ويكشف",
    "ويكفر",
    "ويكفرون",
    "ويكفوا",
    "ويكلم",
    "ويكور",
    "ويكون",
    "ويكونون",
    "ويل",
    "ويلبسون",
    "ويلتا",
    "ويلتنا",
    "ويلتى",
    "ويلعب",
    "ويلعبوا",
    "ويلعن",
    "ويلعنهم",
    "ويلقوا",
    "ويلقون",
    "ويلك",
    "ويلكم",
    "ويلنا",
    "ويلههم",
    "ويمح",
    "ويمحق",
    "ويمددكم",
    "ويمدهم",
    "ويمسك",
    "ويمشون",
    "ويمشي",
    "ويمكر",
    "ويمكرون",
    "ويمنعون",
    "ويمنيهم",
    "ويميت",
    "وينأون",
    "وينجي",
    "وينذر",
    "وينذرونكم",
    "وينزل",
    "وينشئ",
    "وينشر",
    "وينصرك",
    "وينصركم",
    "وينصرون",
    "وينعه",
    "وينفقوا",
    "وينقلب",
    "وينهاهم",
    "وينهون",
    "وينهى",
    "ويهب",
    "ويهدي",
    "ويهديك",
    "ويهديكم",
    "ويهديه",
    "ويهديهم",
    "ويهلك",
    "ويهيئ",
    "ويوسف",
    "ويولج",
    "ويولون",
    "ويوم",
    "ويومئذ",
    "ويونس",
    "يأب",
    "يأت",
    "يأتك",
    "يأتكم",
    "يأتل",
    "يأتمرون",
    "يأته",
    "يأتهم",
    "يأتوا",
    "يأتوك",
    "يأتوكم",
    "يأتون",
    "يأتونك",
    "يأتوننا",
    "يأتوني",
    "يأتيانها",
    "يأتيك",
    "يأتيكم",
    "يأتيكما",
    "يأتين",
    "يأتينا",
    "يأتينك",
    "يأتينكم",
    "يأتيني",
    "يأتيه",
    "يأتيها",
    "يأتيهم",
    "يأجوج",
    "يأخذه",
    "يأخذهم",
    "يأخذوا",
    "يأخذون",
    "يأخذونها",
    "يأخذوه",
    "يأذن",
    "يأفكون",
    "يأكلان",
    "يأكلن",
    "يأكله",
    "يأكلهن",
    "يأكلوا",
    "يأكلون",
    "يألمون",
    "يألونكم",
    "يأمركم",
    "يأمرهم",
    "يأمرون",
    "يأمنوكم",
    "يأن",
    "يؤاخذ",
    "يؤاخذكم",
    "يؤاخذهم",
    "يؤت",
    "يؤتكم",
    "يؤتوا",
    "يؤتون",
    "يؤتى",
    "يؤتي",
    "يؤتين",
    "يؤتيه",
    "يؤتيهم",
    "يؤخذ",
    "يؤخرهم",
    "يؤده",
    "يؤذن",
    "يؤذون",
    "يؤذي",
    "يؤذين",
    "يؤفك",
    "يؤفكون",
    "يؤلون",
    "يؤمرون",
    "يؤمن",
    "يؤمنوا",
    "يؤمنون",
    "يئسن",
    "يئسوا",
    "يئوده",
    "يئوسا",
    "يابسات",
    "ياسين",
    "يبايعنك",
    "يبايعون",
    "يبايعونك",
    "يبتغ",
    "يبتغون",
    "يبث",
    "يبخسون",
    "يبخل",
    "يبخلون",
    "يبدئ",
    "يبدلنا",
    "يبدله",
    "يبدلهما",
    "يبدلوا",
    "يبدلونه",
    "يبدها",
    "يبدون",
    "يبدين",
    "يبسا",
    "يبسطوا",
    "يبشر",
    "يبشرك",
    "يبشرهم",
    "يبصر",
    "يبصروا",
    "يبصرون",
    "يبصرونهم",
    "يبطش",
    "يبطشون",
    "يبعثك",
    "يبعثكم",
    "يبعثهم",
    "يبعثوا",
    "يبعثون",
    "يبغون",
    "يبغونكم",
    "يبغيان",
    "يبكون",
    "يبلس",
    "يبلغا",
    "يبلغن",
    "يبلغوا",
    "يبلغون",
    "يبلوكم",
    "يبلى",
    "يبور",
    "يبيتون",
    "يبينها",
    "يتأخر",
    "يتامى",
    "يتب",
    "يتبدل",
    "يتبع",
    "يتبعها",
    "يتبعهم",
    "يتبعوكم",
    "يتبعون",
    "يتبوأ",
    "يتبين",
    "يتجرعه",
    "يتحاجون",
    "يتحاكموا",
    "يتخافتون",
    "يتخبطه",
    "يتخذ",
    "يتخذوا",
    "يتخذون",
    "يتخذونك",
    "يتخذوه",
    "يتخطفكم",
    "يتخلفوا",
    "يتخيرون",
    "يتدبرون",
    "يتذكرون",
    "يتراجعا",
    "يتربصن",
    "يتربصون",
    "يترددون",
    "يترقب",
    "يتركم",
    "يتركوا",
    "يتزكى",
    "يتساءلون",
    "يتسللون",
    "يتسنه",
    "يتضرعون",
    "يتطهروا",
    "يتطهرون",
    "يتعارفون",
    "يتعد",
    "يتغامزون",
    "يتفجر",
    "يتفرقا",
    "يتفرقون",
    "يتفضل",
    "يتفطرن",
    "يتفكروا",
    "يتفكرون",
    "يتفيأ",
    "يتق",
    "يتقبل",
    "يتقدم",
    "يتقون",
    "يتقي",
    "يتكئون",
    "يتكبرون",
    "يتكلمون",
    "يتلاومون",
    "يتلقى",
    "يتلو",
    "يتلون",
    "يتلونه",
    "يتلى",
    "يتماسا",
    "يتمتعون",
    "يتمطى",
    "يتمنونه",
    "يتمنوه",
    "يتنازعون",
    "يتناهون",
    "يتنزل",
    "يتوب",
    "يتوبوا",
    "يتوبون",
    "يتوفاكم",
    "يتوفاهن",
    "يتوفون",
    "يتوفونهم",
    "يتوفى",
    "يتوكل",
    "يتوكلون",
    "يتول",
    "يتولهم",
    "يتولوا",
    "يتولون",
    "يتولونه",
    "يتيما",
    "يتيمين",
    "يتيهون",
    "يثرب",
    "يثقفوكم",
    "يثنون",
    "يجأرون",
    "يجادلنا",
    "يجادلون",
    "يجادلونك",
    "يجار",
    "يجاهد",
    "يجاهدوا",
    "يجاهدون",
    "يجاورونك",
    "يجبى",
    "يجتبي",
    "يجتبيك",
    "يجتنبون",
    "يجحد",
    "يجحدون",
    "يجدك",
    "يجده",
    "يجدوا",
    "يجدون",
    "يجدونه",
    "يجرمنكم",
    "يجره",
    "يجري",
    "يجز",
    "يجزاه",
    "يجزون",
    "يجزى",
    "يجزي",
    "يجعلني",
    "يجعلون",
    "يجعلوه",
    "يجليها",
    "يجمحون",
    "يجمعكم",
    "يجمعون",
    "يجهلون",
    "يجيب",
    "يجير",
    "يجيرني",
    "يحاجوكم",
    "يحاجون",
    "يحادد",
    "يحادون",
    "يحاربون",
    "يحاسب",
    "يحاسبكم",
    "يحاط",
    "يحافظون",
    "يحاوره",
    "يحببكم",
    "يحبرون",
    "يحبسه",
    "يحبهم",
    "يحبون",
    "يحبونكم",
    "يحبونهم",
    "يحتسب",
    "يحتسبوا",
    "يحتسبون",
    "يحذرون",
    "يحرفون",
    "يحرفونه",
    "يحرمون",
    "يحزنك",
    "يحزنهم",
    "يحزنون",
    "يحسبن",
    "يحسبه",
    "يحسبهم",
    "يحسبون",
    "يحسدون",
    "يحسنون",
    "يحشرهم",
    "يحشروا",
    "يحشرون",
    "يحضرون",
    "يحطمنكم",
    "يحفظونه",
    "يحق",
    "يحكمان",
    "يحكموك",
    "يحكمون",
    "يحكمونك",
    "يحلفون",
    "يحلون",
    "يحلونه",
    "يحمدوا",
    "يحملنها",
    "يحملون",
    "يحملوها",
    "يحموم",
    "يحمى",
    "يحور",
    "يحيطوا",
    "يحيطون",
    "يحيف",
    "يحيق",
    "يحيك",
    "يحيى",
    "يحييكم",
    "يحيين",
    "يحييها",
    "يخادعون",
    "يخاف",
    "يخافا",
    "يخافه",
    "يخافوا",
    "يخافون",
    "يخالفون",
    "يختانون",
    "يختص",
    "يختصمون",
    "يختلفون",
    "يختم",
    "يخدعوك",
    "يخدعون",
    "يخذلكم",
    "يخربون",
    "يخرجاكم",
    "يخرجكم",
    "يخرجن",
    "يخرجنكما",
    "يخرجهم",
    "يخرجوا",
    "يخرجوك",
    "يخرجوكم",
    "يخرجون",
    "يخرجونهم",
    "يخرصون",
    "يخروا",
    "يخرون",
    "يخزيه",
    "يخزيهم",
    "يخسر",
    "يخسرون",
    "يخسف",
    "يخش",
    "يخشاها",
    "يخشون",
    "يخشى",
    "يخصفان",
    "يخصمون",
    "يخفون",
    "يخفين",
    "يخل",
    "يخلف",
    "يخلفه",
    "يخلفون",
    "يخلقكم",
    "يخلقوا",
    "يخلقون",
    "يخوضوا",
    "يخوضون",
    "يخيل",
    "يدا",
    "يداك",
    "يداه",
    "يدبروا",
    "يدخلنا",
    "يدخلنها",
    "يدخله",
    "يدخلون",
    "يدخلونها",
    "يدخلوها",
    "يدرسونها",
    "يدرككم",
    "يدركه",
    "يدريك",
    "يدسه",
    "يدع",
    "يدعنا",
    "يدعوك",
    "يدعوكم",
    "يدعون",
    "يدعونني",
    "يدعونه",
    "يدعوه",
    "يدعوهم",
    "يدك",
    "يدنين",
    "يده",
    "يدي",
    "يدينون",
    "يديه",
    "يديها",
    "يذبحون",
    "يذرؤكم",
    "يذكرهم",
    "يذكرون",
    "يذهبكم",
    "يذهبن",
    "يذهبوا",
    "يذوقوا",
    "يذوقون",
    "ير",
    "يراءون",
    "يراد",
    "يراك",
    "يراها",
    "يربو",
    "يرتابوا",
    "يرتدد",
    "يرتع",
    "يرثني",
    "يرثها",
    "يرثون",
    "يرجعون",
    "يرجموكم",
    "يرجو",
    "يرجون",
    "يرحمكم",
    "يرحمنا",
    "يردك",
    "يردن",
    "يردوكم",
    "يردون",
    "يردونكم",
    "يرزقكم",
    "يرزقها",
    "يرزقون",
    "يرشدون",
    "يرضعن",
    "يرضه",
    "يرضونكم",
    "يرضونه",
    "يرضوه",
    "يرغب",
    "يرغبوا",
    "يرفعه",
    "يرقبوا",
    "يرقبون",
    "يركبون",
    "يركضون",
    "يركعون",
    "يرم",
    "يرمون",
    "يره",
    "يرهبون",
    "يرهقهما",
    "يروا",
    "يرون",
    "يرونه",
    "يرونها",
    "يرونهم",
    "يريدا",
    "يريدان",
    "يريدوا",
    "يريدون",
    "يريكم",
    "يريكموهم",
    "يريكهم",
    "يريهم",
    "يزال",
    "يزالون",
    "يزجي",
    "يزده",
    "يزدهم",
    "يزرون",
    "يزعمون",
    "يزغ",
    "يزفون",
    "يزكون",
    "يزكى",
    "يزكي",
    "يزكيهم",
    "يزنون",
    "يزنين",
    "يزوجهم",
    "يزيدهم",
    "يزيدون",
    "يزيغ",
    "يس",
    "يسألك",
    "يسألكم",
    "يسألكموها",
    "يسأله",
    "يسألون",
    "يسألونك",
    "يسأم",
    "يسأمون",
    "يسارعون",
    "يساقون",
    "يسبتون",
    "يسبحن",
    "يسبحون",
    "يسبقونا",
    "يسبقونه",
    "يستأخرون",
    "يستأذنك",
    "يستأذنونك",
    "يستأذنوه",
    "يستبشرون",
    "يستثنون",
    "يستجيب",
    "يستجيبوا",
    "يستجيبون",
    "يستحبون",
    "يستحسرون",
    "يستحيي",
    "يستخفنك",
    "يستخفون",
    "يستسخرون",
    "يستصرخه",
    "يستضعف",
    "يستضعفون",
    "يستطع",
    "يستطيعون",
    "يستعتبوا",
    "يستعتبون",
    "يستعجل",
    "يستعجلون",
    "يستعجلونك",
    "يستعففن",
    "يستغشون",
    "يستغفر",
    "يستغفروا",
    "يستغفرون",
    "يستغيثان",
    "يستغيثوا",
    "يستفتحون",
    "يستفتونك",
    "يستفزهم",
    "يستقدمون",
    "يستقيم",
    "يستكبرون",
    "يستمعون",
    "يستنبطونه",
    "يستنقذوه",
    "يستنكحها",
    "يستنكف",
    "يستهزئ",
    "يستهزئون",
    "يستوفون",
    "يستوون",
    "يستوي",
    "يستويان",
    "يسجدان",
    "يسجدوا",
    "يسجدون",
    "يسجرون",
    "يسحبون",
    "يسخطون",
    "يسرا",
    "يسرف",
    "يسرفوا",
    "يسرقن",
    "يسرنا",
    "يسرناه",
    "يسره",
    "يسرون",
    "يسطرون",
    "يسطون",
    "يسعون",
    "يسعى",
    "يسقون",
    "يسقى",
    "يسلبهم",
    "يسلكه",
    "يسلمون",
    "يسمعها",
    "يسمعوا",
    "يسمعون",
    "يسمعونكم",
    "يسومهم",
    "يسومونكم",
    "يسير",
    "يسيرا",
    "يسيركم",
    "يسيروا",
    "يسيغه",
    "يشأ",
    "يشإ",
    "يشاء",
    "يشاءون",
    "يشاق",
    "يشاقق",
    "يشترون",
    "يشتهون",
    "يشربون",
    "يشرك",
    "يشركن",
    "يشركون",
    "يشرون",
    "يشري",
    "يشعركم",
    "يشعرن",
    "يشعرون",
    "يشفع",
    "يشفعون",
    "يشفين",
    "يشقق",
    "يشقى",
    "يشكر",
    "يشكرون",
    "يشهده",
    "يشهدون",
    "يصبروا",
    "يصبكم",
    "يصبها",
    "يصحبون",
    "يصدعون",
    "يصدفون",
    "يصدقني",
    "يصدقوا",
    "يصدكم",
    "يصدنك",
    "يصدنكم",
    "يصدون",
    "يصرفون",
    "يصروا",
    "يصرون",
    "يصطرخون",
    "يصطفي",
    "يصعقون",
    "يصفون",
    "يصلاها",
    "يصلبوا",
    "يصلحا",
    "يصلحون",
    "يصلوا",
    "يصلون",
    "يصلونها",
    "يصلى",
    "يصنعون",
    "يصهر",
    "يصوركم",
    "يصيبكم",
    "يصيبنا",
    "يصيبهم",
    "يضار",
    "يضاعفه",
    "يضاعفها",
    "يضاهئون",
    "يضحكون",
    "يضر",
    "يضربن",
    "يضربون",
    "يضرعون",
    "يضرك",
    "يضركم",
    "يضرنا",
    "يضره",
    "يضرهم",
    "يضروا",
    "يضروك",
    "يضروكم",
    "يضرون",
    "يضرونك",
    "يضعن",
    "يضلله",
    "يضله",
    "يضلهم",
    "يضلوا",
    "يضلوك",
    "يضلون",
    "يضلونكم",
    "يضلونهم",
    "يضيفوهما",
    "يضيق",
    "يطئون",
    "يطاع",
    "يطاف",
    "يطع",
    "يطعم",
    "يطعمني",
    "يطعمه",
    "يطعمها",
    "يطعمون",
    "يطغى",
    "يطفئوا",
    "يطلبه",
    "يطمثهن",
    "يطمع",
    "يطمعون",
    "يطهرن",
    "يطوفون",
    "يطيروا",
    "يطيعكم",
    "يطيقونه",
    "يظاهروا",
    "يظاهرون",
    "يظلمون",
    "يظنون",
    "يظهروا",
    "يظهرون",
    "يظهروه",
    "يعبأ",
    "يعبدون",
    "يعبدونني",
    "يعبدوها",
    "يعتدون",
    "يعتذرون",
    "يعتزلوكم",
    "يعتصم",
    "يعجب",
    "يعجبك",
    "يعجزون",
    "يعدكم",
    "يعدلون",
    "يعدهم",
    "يعدون",
    "يعذبكم",
    "يعذبنا",
    "يعذبه",
    "يعذبهم",
    "يعرجون",
    "يعرشون",
    "يعرضوا",
    "يعرضون",
    "يعرفن",
    "يعرفوا",
    "يعرفون",
    "يعرفونه",
    "يعرفونها",
    "يعرفونهم",
    "يعزب",
    "يعش",
    "يعص",
    "يعصرون",
    "يعصمك",
    "يعصمكم",
    "يعصمني",
    "يعصون",
    "يعصينك",
    "يعض",
    "يعطوا",
    "يعطيك",
    "يعظكم",
    "يعظه",
    "يعفو",
    "يعفون",
    "يعقب",
    "يعقلها",
    "يعقلون",
    "يعكفون",
    "يعلمان",
    "يعلمه",
    "يعلمها",
    "يعلمهم",
    "يعلموا",
    "يعلمون",
    "يعلنون",
    "يعمر",
    "يعمروا",
    "يعمهون",
    "يعودوا",
    "يعودون",
    "يعوذون",
    "يعي",
    "يعيدكم",
    "يعيدنا",
    "يعيدوكم",
    "يغاث",
    "يغاثوا",
    "يغتب",
    "يغررك",
    "يغرنك",
    "يغرنكم",
    "يغشاه",
    "يغشاها",
    "يغشاهم",
    "يغشى",
    "يغشي",
    "يغشيكم",
    "يغضضن",
    "يغضوا",
    "يغضون",
    "يغفروا",
    "يغفرون",
    "يغل",
    "يغلبوا",
    "يغلبون",
    "يغلل",
    "يغن",
    "يغنهم",
    "يغنوا",
    "يغنيا",
    "يغنيكم",
    "يغنيه",
    "يغنيهم",
    "يغوث",
    "يغوصون",
    "يغويكم",
    "يغيروا",
    "يفتدي",
    "يفتر",
    "يفترون",
    "يفترى",
    "يفتري",
    "يفترينه",
    "يفتنكم",
    "يفتننكم",
    "يفتنهم",
    "يفتنوك",
    "يفتنون",
    "يفتيكم",
    "يفجرونها",
    "يفر",
    "يفرحوا",
    "يفرحون",
    "يفرطون",
    "يفرقوا",
    "يفرقون",
    "يفسح",
    "يفسدون",
    "يفسقون",
    "يفعله",
    "يفعلوا",
    "يفعلون",
    "يفقهوا",
    "يفقهون",
    "يفقهوه",
    "يفلح",
    "يفلحون",
    "يقاتل",
    "يقاتلوا",
    "يقاتلوكم",
    "يقاتلون",
    "يقاتلونكم",
    "يقبض",
    "يقتتلان",
    "يقترفون",
    "يقتروا",
    "يقتلن",
    "يقتلوا",
    "يقتلوك",
    "يقتلون",
    "يقتلونني",
    "يقدرون",
    "يقرءون",
    "يقربوا",
    "يقرض",
    "يقسمون",
    "يقصرون",
    "يقصون",
    "يقض",
    "يقضون",
    "يقضى",
    "يقطعون",
    "يقطين",
    "يقل",
    "يقلب",
    "يقنت",
    "يقنط",
    "يقنطون",
    "يقولا",
    "يقولوا",
    "يقولون",
    "يقومان",
    "يقيما",
    "يقيموا",
    "يقيمون",
    "يقين",
    "يقينا",
    "يكاد",
    "يكادون",
    "يكبتهم",
    "يكبروا",
    "يكتبون",
    "يكتم",
    "يكتمن",
    "يكتمها",
    "يكتمون",
    "يكد",
    "يكذبك",
    "يكذبوك",
    "يكذبون",
    "يكذبونك",
    "يكرههن",
    "يكرهون",
    "يكسبه",
    "يكسبون",
    "يكفروا",
    "يكفرون",
    "يكفروه",
    "يكفله",
    "يكفلونه",
    "يكفهم",
    "يكفون",
    "يكفيكم",
    "يكلؤكم",
    "يكلمنا",
    "يكلمه",
    "يكلمهم",
    "يكنزون",
    "يكور",
    "يكونا",
    "يكونوا",
    "يكونون",
    "يكيدون",
    "يلاقوا",
    "يلبثوا",
    "يلبثون",
    "يلبسكم",
    "يلبسوا",
    "يلبسون",
    "يلتفت",
    "يلتقطه",
    "يلتقيان",
    "يلتكم",
    "يلج",
    "يلحدون",
    "يلحقوا",
    "يلدوا",
    "يلعبون",
    "يلعنهم",
    "يلق",
    "يلقاه",
    "يلقاها",
    "يلقون",
    "يلقونه",
    "يلقي",
    "يلمزك",
    "يلمزون",
    "يلهث",
    "يلونكم",
    "يلوون",
    "يمارون",
    "يمترون",
    "يمتعكم",
    "يمتعون",
    "يمحق",
    "يمددكم",
    "يمدكم",
    "يمده",
    "يمدونهم",
    "يمرون",
    "يمسسك",
    "يمسسكم",
    "يمسسني",
    "يمسسهم",
    "يمسكهن",
    "يمسكون",
    "يمسنا",
    "يمسه",
    "يمسهم",
    "يمكر",
    "يمكرون",
    "يمل",
    "يملكون",
    "يمن",
    "يمنون",
    "يمنى",
    "يمهدون",
    "يموتون",
    "يميتكم",
    "يميتني",
    "يمينك",
    "ينابيع",
    "يناد",
    "ينادون",
    "ينادونك",
    "ينادونهم",
    "يناديهم",
    "ينازعنك",
    "يناله",
    "ينالهم",
    "ينالوا",
    "ينالون",
    "ينبأ",
    "ينبئك",
    "ينبئكم",
    "ينبئهم",
    "ينبوعا",
    "ينتصرون",
    "ينتظرون",
    "ينته",
    "ينتهوا",
    "ينتهون",
    "ينجيكم",
    "ينجيه",
    "ينحتون",
    "ينذرون",
    "ينزغ",
    "ينزغنك",
    "ينزفون",
    "ينسفها",
    "ينسلون",
    "ينسى",
    "ينسينك",
    "ينشرون",
    "ينصركم",
    "ينصرنا",
    "ينصرني",
    "ينصره",
    "ينصرون",
    "ينصرونكم",
    "ينصرونه",
    "ينصرونهم",
    "ينطقون",
    "ينظروا",
    "ينظرون",
    "ينفضوا",
    "ينفعك",
    "ينفعكم",
    "ينفعنا",
    "ينفعه",
    "ينفعهم",
    "ينفعونكم",
    "ينفقون",
    "ينفقونها",
    "ينفوا",
    "ينقذون",
    "ينقصوكم",
    "ينقضون",
    "ينقلبون",
    "ينكثون",
    "ينكحن",
    "ينكحها",
    "ينكرونها",
    "ينهاكم",
    "ينهاهم",
    "ينهون",
    "ينهى",
    "ينيب",
    "يهاجروا",
    "يهبط",
    "يهتدوا",
    "يهتدون",
    "يهتدي",
    "يهجعون",
    "يهد",
    "يهدني",
    "يهدون",
    "يهدوننا",
    "يهدى",
    "يهدي",
    "يهديكم",
    "يهدين",
    "يهديني",
    "يهديه",
    "يهديهم",
    "يهرعون",
    "يهلكنا",
    "يهلكون",
    "يهن",
    "يهوديا",
    "يهيمون",
    "يوادون",
    "يواري",
    "يوبقهن",
    "يوجهه",
    "يوح",
    "يوحى",
    "يوحي",
    "يودوا",
    "يورثها",
    "يوزعون",
    "يوسوس",
    "يوصى",
    "يوصيكم",
    "يوصين",
    "يوعدون",
    "يوعظ",
    "يوعظون",
    "يوعون",
    "يوف",
    "يوفضون",
    "يوفق",
    "يوفون",
    "يوفى",
    "يوفيهم",
    "يوق",
    "يوقدون",
    "يوقنون",
    "يولج",
    "يولهم",
    "يولوكم",
    "يولون",
    "يومئذ",
    "يومكم",
    "يومهم",
    "يومين",
    "ييأس",
    "آض",
    "أأجمع",
    "أأجمعك",
    "أأجمعكم",
    "أأجمعكما",
    "أأجمعكن",
    "أأجمعنا",
    "أأجمعه",
    "أأجمعها",
    "أأجمعهم",
    "أأجمعهما",
    "أأجمعهن",
    "أأجمعي",
    "أأمام",
    "أأمامك",
    "أأمامكم",
    "أأمامكما",
    "أأمامكن",
    "أأمامنا",
    "أأمامه",
    "أأمامها",
    "أأمامهم",
    "أأمامهما",
    "أأمامهن",
    "أأمامي",
    "أأنا",
    "أأنتما",
    "أأنتن",
    "أأولئك",
    "أأولئكم",
    "أأولاء",
    "أأولالك",
    "أإلى",
    "أإلي",
    "أإليك",
    "أإليكم",
    "أإليكما",
    "أإليكن",
    "أإلينا",
    "أإليه",
    "أإليها",
    "أإليهم",
    "أإليهما",
    "أإليهن",
    "أإن",
    "أإنكم",
    "أإنكما",
    "أإنكن",
    "أإننا",
    "أإنه",
    "أإنها",
    "أإنهم",
    "أإنهما",
    "أإنهن",
    "أإني",
    "أبأجمع",
    "أبأجمعك",
    "أبأجمعكم",
    "أبأجمعكما",
    "أبأجمعكن",
    "أبأجمعنا",
    "أبأجمعه",
    "أبأجمعها",
    "أبأجمعهم",
    "أبأجمعهما",
    "أبأجمعهن",
    "أبأجمعي",
    "أبأولئك",
    "أبأولئكم",
    "أبأولاء",
    "أبأولالك",
    "أبإن",
    "أبإنك",
    "أبإنكم",
    "أبإنكما",
    "أبإنكن",
    "أبإننا",
    "أبإنه",
    "أبإنها",
    "أبإنهم",
    "أبإنهما",
    "أبإنهن",
    "أبإني",
    "أببعض",
    "أببعضك",
    "أببعضكم",
    "أببعضكما",
    "أببعضكن",
    "أببعضنا",
    "أببعضه",
    "أببعضها",
    "أببعضهم",
    "أببعضهما",
    "أببعضهن",
    "أببعضي",
    "أبتلك",
    "أبتلكم",
    "أبتلكما",
    "أبتينك",
    "أبجميع",
    "أبجميعك",
    "أبجميعكم",
    "أبجميعكما",
    "أبجميعكن",
    "أبجميعنا",
    "أبجميعه",
    "أبجميعها",
    "أبجميعهم",
    "أبجميعهما",
    "أبجميعهن",
    "أبجميعي",
    "أبحسب",
    "أبحسبك",
    "أبحسبكم",
    "أبحسبكما",
    "أبحسبكن",
    "أبحسبنا",
    "أبحسبه",
    "أبحسبها",
    "أبحسبهم",
    "أبحسبهما",
    "أبحسبهن",
    "أبحسبي",
    "أبذاك",
    "أبذانك",
    "أبذلك",
    "أبذلكم",
    "أبذلكما",
    "أبذلكن",
    "أبذواتي",
    "أبذي",
    "أبذين",
    "أبذينك",
    "أبسوى",
    "أبسوي",
    "أبسويك",
    "أبسويكم",
    "أبسويكما",
    "أبسويكن",
    "أبسوينا",
    "أبسويه",
    "أبسويها",
    "أبسويهم",
    "أبسويهما",
    "أبسويهن",
    "أبشبه",
    "أبشبهك",
    "أبشبهكم",
    "أبشبهكما",
    "أبشبهكن",
    "أبشبهنا",
    "أبشبهه",
    "أبشبهها",
    "أبشبههم",
    "أبشبههما",
    "أبشبههن",
    "أبشبهي",
    "أبضع",
    "أبضعك",
    "أبضعكم",
    "أبضعكما",
    "أبضعكن",
    "أبضعنا",
    "أبضعه",
    "أبضعها",
    "أبضعهم",
    "أبضعهما",
    "أبضعهن",
    "أبضعي",
    "أبعامة",
    "أبعامتك",
    "أبعامتكم",
    "أبعامتكما",
    "أبعامتكن",
    "أبعامتنا",
    "أبعامته",
    "أبعامتها",
    "أبعامتهم",
    "أبعامتهما",
    "أبعامتهن",
    "أبعامتي",
    "أبعض",
    "أبعضك",
    "أبعضكم",
    "أبعضكما",
    "أبعضكن",
    "أبعضنا",
    "أبعضه",
    "أبعضها",
    "أبعضهم",
    "أبعضهما",
    "أبعضهن",
    "أبعضي",
    "أبعين",
    "أبعينك",
    "أبعينكم",
    "أبعينكما",
    "أبعينكن",
    "أبعيننا",
    "أبعينه",
    "أبعينها",
    "أبعينهم",
    "أبعينهما",
    "أبعينهن",
    "أبعيني",
    "أبغير",
    "أبغيرك",
    "أبغيركم",
    "أبغيركما",
    "أبغيركن",
    "أبغيرنا",
    "أبغيره",
    "أبغيرها",
    "أبغيرهم",
    "أبغيرهما",
    "أبغيرهن",
    "أبغيري",
    "أبك",
    "أبكل",
    "أبكلتا",
    "أبكلك",
    "أبكلكم",
    "أبكلكما",
    "أبكلكن",
    "أبكلنا",
    "أبكله",
    "أبكلها",
    "أبكلهم",
    "أبكلهما",
    "أبكلهن",
    "أبكلي",
    "أبكليكما",
    "أبكليهما",
    "أبكما",
    "أبكن",
    "أبما",
    "أبمثل",
    "أبمثلك",
    "أبمثلكم",
    "أبمثلكما",
    "أبمثلكن",
    "أبمثلنا",
    "أبمثله",
    "أبمثلها",
    "أبمثلهم",
    "أبمثلهما",
    "أبمثلهن",
    "أبمثلي",
    "أبمن",
    "أبنا",
    "أبنحو",
    "أبنحوك",
    "أبنحوكم",
    "أبنحوكما",
    "أبنحوكن",
    "أبنحونا",
    "أبنحوه",
    "أبنحوها",
    "أبنحوهم",
    "أبنحوهما",
    "أبنحوهن",
    "أبنحوي",
    "أبنفس",
    "أبنفسك",
    "أبنفسكم",
    "أبنفسكما",
    "أبنفسكن",
    "أبنفسنا",
    "أبنفسه",
    "أبنفسها",
    "أبنفسهم",
    "أبنفسهما",
    "أبنفسهن",
    "أبنفسي",
    "أبه",
    "أبهؤلاء",
    "أبها",
    "أبهاته",
    "أبهاتي",
    "أبهاتين",
    "أبهذا",
    "أبهذه",
    "أبهم",
    "أبهما",
    "أبهن",
    "أبهناك",
    "أبهنالك",
    "أبهو",
    "أبهي",
    "أتحت",
    "أتحتك",
    "أتحتكم",
    "أتحتكما",
    "أتحتكن",
    "أتحتنا",
    "أتحته",
    "أتحتها",
    "أتحتهم",
    "أتحتهما",
    "أتحتهن",
    "أتحتي",
    "أتلك",
    "أتلكم",
    "أتلكما",
    "أتينك",
    "أثمة",
    "أجمع",
    "أجمعك",
    "أجمعكم",
    "أجمعكما",
    "أجمعكن",
    "أجمعنا",
    "أجمعه",
    "أجمعها",
    "أجمعهم",
    "أجمعهما",
    "أجمعهن",
    "أجمعي",
    "أجميع",
    "أجميعك",
    "أجميعكم",
    "أجميعكما",
    "أجميعكن",
    "أجميعنا",
    "أجميعه",
    "أجميعها",
    "أجميعهم",
    "أجميعهما",
    "أجميعهن",
    "أجميعي",
    "أحسبك",
    "أحسبكم",
    "أحسبكما",
    "أحسبكن",
    "أحسبنا",
    "أحسبه",
    "أحسبها",
    "أحسبهم",
    "أحسبهما",
    "أحسبهن",
    "أحسبي",
    "أخك",
    "أخكم",
    "أخكما",
    "أخكن",
    "أخلف",
    "أخلفك",
    "أخلفكم",
    "أخلفكما",
    "أخلفكن",
    "أخلفه",
    "أخلفها",
    "أخلفهم",
    "أخلفهما",
    "أخلفهن",
    "أخلفي",
    "أخنا",
    "أخه",
    "أخها",
    "أخهم",
    "أخهما",
    "أخهن",
    "أذاك",
    "أذانك",
    "أذلكم",
    "أذلكما",
    "أذلكن",
    "أذواتا",
    "أذواتي",
    "أذي",
    "أذين",
    "أذينك",
    "أسوى",
    "أسوي",
    "أسويك",
    "أسويكم",
    "أسويكما",
    "أسويكن",
    "أسوينا",
    "أسويه",
    "أسويها",
    "أسويهم",
    "أسويهما",
    "أسويهن",
    "أشبهك",
    "أشبهكم",
    "أشبهكما",
    "أشبهكن",
    "أشبهنا",
    "أشبهه",
    "أشبهها",
    "أشبههم",
    "أشبههما",
    "أشبههن",
    "أشبهي",
    "أشمال",
    "أشمالك",
    "أشمالكم",
    "أشمالكما",
    "أشمالكن",
    "أشمالنا",
    "أشماله",
    "أشمالها",
    "أشمالهم",
    "أشمالهما",
    "أشمالهن",
    "أشمالي",
    "أضحى",
    "أطالما",
    "أعامة",
    "أعامتك",
    "أعامتكم",
    "أعامتكما",
    "أعامتكن",
    "أعامتنا",
    "أعامته",
    "أعامتها",
    "أعامتهم",
    "أعامتهما",
    "أعامتهن",
    "أعامتي",
    "أعلي",
    "أعليك",
    "أعليكم",
    "أعليكما",
    "أعليكن",
    "أعلينا",
    "أعليه",
    "أعليها",
    "أعليهم",
    "أعليهما",
    "أعليهن",
    "أعن",
    "أعنك",
    "أعنكم",
    "أعنكما",
    "أعنكن",
    "أعننا",
    "أعنه",
    "أعنها",
    "أعنهم",
    "أعنهما",
    "أعنهن",
    "أعني",
    "أعينك",
    "أعينكما",
    "أعينكن",
    "أعيننا",
    "أعينه",
    "أعينها",
    "أعينهما",
    "أعيني",
    "أغيرك",
    "أغيركم",
    "أغيركما",
    "أغيركن",
    "أغيرنا",
    "أغيره",
    "أغيرها",
    "أغيرهم",
    "أغيرهما",
    "أغيرهن",
    "أغيري",
    "أفأجمع",
    "أفأجمعك",
    "أفأجمعكم",
    "أفأجمعكما",
    "أفأجمعكن",
    "أفأجمعنا",
    "أفأجمعه",
    "أفأجمعها",
    "أفأجمعهم",
    "أفأجمعهما",
    "أفأجمعهن",
    "أفأجمعي",
    "أفأمام",
    "أفأمامك",
    "أفأمامكم",
    "أفأمامكما",
    "أفأمامكن",
    "أفأمامنا",
    "أفأمامه",
    "أفأمامها",
    "أفأمامهم",
    "أفأمامهما",
    "أفأمامهن",
    "أفأمامي",
    "أفأنا",
    "أفأنتما",
    "أفأنتن",
    "أفأولئك",
    "أفأولئكم",
    "أفأولاء",
    "أفأولالك",
    "أفإلى",
    "أفإلي",
    "أفإليك",
    "أفإليكم",
    "أفإليكما",
    "أفإليكن",
    "أفإلينا",
    "أفإليه",
    "أفإليها",
    "أفإليهم",
    "أفإليهما",
    "أفإليهن",
    "أفإنك",
    "أفإنكم",
    "أفإنكما",
    "أفإنكن",
    "أفإننا",
    "أفإنه",
    "أفإنها",
    "أفإنهم",
    "أفإنهما",
    "أفإنهن",
    "أفإني",
    "أفبأجمع",
    "أفبأجمعك",
    "أفبأجمعكم",
    "أفبأجمعكما",
    "أفبأجمعكن",
    "أفبأجمعنا",
    "أفبأجمعه",
    "أفبأجمعها",
    "أفبأجمعهم",
    "أفبأجمعهما",
    "أفبأجمعهن",
    "أفبأجمعي",
    "أفبأولئك",
    "أفبأولئكم",
    "أفبأولاء",
    "أفبأولالك",
    "أفبإن",
    "أفبإنك",
    "أفبإنكم",
    "أفبإنكما",
    "أفبإنكن",
    "أفبإننا",
    "أفبإنه",
    "أفبإنها",
    "أفبإنهم",
    "أفبإنهما",
    "أفبإنهن",
    "أفبإني",
    "أفببعض",
    "أفببعضك",
    "أفببعضكم",
    "أفببعضكما",
    "أفببعضكن",
    "أفببعضنا",
    "أفببعضه",
    "أفببعضها",
    "أفببعضهم",
    "أفببعضهما",
    "أفببعضهن",
    "أفببعضي",
    "أفبتلك",
    "أفبتلكم",
    "أفبتلكما",
    "أفبتينك",
    "أفبجميع",
    "أفبجميعك",
    "أفبجميعكم",
    "أفبجميعكما",
    "أفبجميعكن",
    "أفبجميعنا",
    "أفبجميعه",
    "أفبجميعها",
    "أفبجميعهم",
    "أفبجميعهما",
    "أفبجميعهن",
    "أفبجميعي",
    "أفبحسب",
    "أفبحسبك",
    "أفبحسبكم",
    "أفبحسبكما",
    "أفبحسبكن",
    "أفبحسبنا",
    "أفبحسبه",
    "أفبحسبها",
    "أفبحسبهم",
    "أفبحسبهما",
    "أفبحسبهن",
    "أفبحسبي",
    "أفبذاك",
    "أفبذانك",
    "أفبذلك",
    "أفبذلكم",
    "أفبذلكما",
    "أفبذلكن",
    "أفبذواتي",
    "أفبذي",
    "أفبذين",
    "أفبذينك",
    "أفبسوى",
    "أفبسوي",
    "أفبسويك",
    "أفبسويكم",
    "أفبسويكما",
    "أفبسويكن",
    "أفبسوينا",
    "أفبسويه",
    "أفبسويها",
    "أفبسويهم",
    "أفبسويهما",
    "أفبسويهن",
    "أفبشبه",
    "أفبشبهك",
    "أفبشبهكم",
    "أفبشبهكما",
    "أفبشبهكن",
    "أفبشبهنا",
    "أفبشبهه",
    "أفبشبهها",
    "أفبشبههم",
    "أفبشبههما",
    "أفبشبههن",
    "أفبشبهي",
    "أفبضع",
    "أفبضعك",
    "أفبضعكم",
    "أفبضعكما",
    "أفبضعكن",
    "أفبضعنا",
    "أفبضعه",
    "أفبضعها",
    "أفبضعهم",
    "أفبضعهما",
    "أفبضعهن",
    "أفبضعي",
    "أفبعامة",
    "أفبعامتك",
    "أفبعامتكم",
    "أفبعامتكما",
    "أفبعامتكن",
    "أفبعامتنا",
    "أفبعامته",
    "أفبعامتها",
    "أفبعامتهم",
    "أفبعامتهما",
    "أفبعامتهن",
    "أفبعامتي",
    "أفبعض",
    "أفبعضك",
    "أفبعضكم",
    "أفبعضكما",
    "أفبعضكن",
    "أفبعضنا",
    "أفبعضه",
    "أفبعضها",
    "أفبعضهم",
    "أفبعضهما",
    "أفبعضهن",
    "أفبعضي",
    "أفبعين",
    "أفبعينك",
    "أفبعينكم",
    "أفبعينكما",
    "أفبعينكن",
    "أفبعيننا",
    "أفبعينه",
    "أفبعينها",
    "أفبعينهم",
    "أفبعينهما",
    "أفبعينهن",
    "أفبعيني",
    "أفبغير",
    "أفبغيرك",
    "أفبغيركم",
    "أفبغيركما",
    "أفبغيركن",
    "أفبغيرنا",
    "أفبغيره",
    "أفبغيرها",
    "أفبغيرهم",
    "أفبغيرهما",
    "أفبغيرهن",
    "أفبغيري",
    "أفبك",
    "أفبكل",
    "أفبكلتا",
    "أفبكلك",
    "أفبكلكم",
    "أفبكلكما",
    "أفبكلكن",
    "أفبكلنا",
    "أفبكله",
    "أفبكلها",
    "أفبكلهم",
    "أفبكلهما",
    "أفبكلهن",
    "أفبكلي",
    "أفبكليكما",
    "أفبكليهما",
    "أفبكم",
    "أفبكما",
    "أفبكن",
    "أفبما",
    "أفبمثل",
    "أفبمثلك",
    "أفبمثلكم",
    "أفبمثلكما",
    "أفبمثلكن",
    "أفبمثلنا",
    "أفبمثله",
    "أفبمثلها",
    "أفبمثلهم",
    "أفبمثلهما",
    "أفبمثلهن",
    "أفبمثلي",
    "أفبمن",
    "أفبنا",
    "أفبنحو",
    "أفبنحوك",
    "أفبنحوكم",
    "أفبنحوكما",
    "أفبنحوكن",
    "أفبنحونا",
    "أفبنحوه",
    "أفبنحوها",
    "أفبنحوهم",
    "أفبنحوهما",
    "أفبنحوهن",
    "أفبنحوي",
    "أفبنفس",
    "أفبنفسك",
    "أفبنفسكم",
    "أفبنفسكما",
    "أفبنفسكن",
    "أفبنفسنا",
    "أفبنفسه",
    "أفبنفسها",
    "أفبنفسهم",
    "أفبنفسهما",
    "أفبنفسهن",
    "أفبنفسي",
    "أفبه",
    "أفبهؤلاء",
    "أفبها",
    "أفبهاته",
    "أفبهاتي",
    "أفبهاتين",
    "أفبهذه",
    "أفبهم",
    "أفبهما",
    "أفبهن",
    "أفبهناك",
    "أفبهنالك",
    "أفبهو",
    "أفبهي",
    "أفبي",
    "أفتحت",
    "أفتحتك",
    "أفتحتكم",
    "أفتحتكما",
    "أفتحتكن",
    "أفتحتنا",
    "أفتحته",
    "أفتحتها",
    "أفتحتهم",
    "أفتحتهما",
    "أفتحتهن",
    "أفتحتي",
    "أفتلك",
    "أفتلكم",
    "أفتلكما",
    "أفتينك",
    "أفثم",
    "أفثمة",
    "أفجميع",
    "أفجميعك",
    "أفجميعكم",
    "أفجميعكما",
    "أفجميعكن",
    "أفجميعنا",
    "أفجميعه",
    "أفجميعها",
    "أفجميعهم",
    "أفجميعهما",
    "أفجميعهن",
    "أفجميعي",
    "أفحسبك",
    "أفحسبكم",
    "أفحسبكما",
    "أفحسبكن",
    "أفحسبنا",
    "أفحسبه",
    "أفحسبها",
    "أفحسبهم",
    "أفحسبهما",
    "أفحسبهن",
    "أفحسبي",
    "أفخلف",
    "أفخلفك",
    "أفخلفكم",
    "أفخلفكما",
    "أفخلفكن",
    "أفخلفنا",
    "أفخلفه",
    "أفخلفها",
    "أفخلفهم",
    "أفخلفهما",
    "أفخلفهن",
    "أفخلفي",
    "أفذاك",
    "أفذانك",
    "أفذلك",
    "أفذلكم",
    "أفذلكما",
    "أفذلكن",
    "أفذواتا",
    "أفذواتي",
    "أفذي",
    "أفذين",
    "أفذينك",
    "أفسوى",
    "أفسوي",
    "أفسويك",
    "أفسويكم",
    "أفسويكما",
    "أفسويكن",
    "أفسوينا",
    "أفسويه",
    "أفسويها",
    "أفسويهم",
    "أفسويهما",
    "أفسويهن",
    "أفشبه",
    "أفشبهك",
    "أفشبهكم",
    "أفشبهكما",
    "أفشبهكن",
    "أفشبهنا",
    "أفشبهه",
    "أفشبهها",
    "أفشبههم",
    "أفشبههما",
    "أفشبههن",
    "أفشبهي",
    "أفشمال",
    "أفشمالك",
    "أفشمالكم",
    "أفشمالكما",
    "أفشمالكن",
    "أفشمالنا",
    "أفشماله",
    "أفشمالها",
    "أفشمالهم",
    "أفشمالهما",
    "أفشمالهن",
    "أفشمالي",
    "أفطالما",
    "أفعامة",
    "أفعامتك",
    "أفعامتكم",
    "أفعامتكما",
    "أفعامتكن",
    "أفعامتنا",
    "أفعامته",
    "أفعامتها",
    "أفعامتهم",
    "أفعامتهما",
    "أفعامتهن",
    "أفعامتي",
    "أفعلى",
    "أفعلي",
    "أفعليك",
    "أفعليكم",
    "أفعليكما",
    "أفعليكن",
    "أفعلينا",
    "أفعليه",
    "أفعليها",
    "أفعليهم",
    "أفعليهما",
    "أفعليهن",
    "أفعن",
    "أفعنك",
    "أفعنكم",
    "أفعنكما",
    "أفعنكن",
    "أفعننا",
    "أفعنه",
    "أفعنها",
    "أفعنهم",
    "أفعنهما",
    "أفعنهن",
    "أفعني",
    "أفعين",
    "أفعينك",
    "أفعينكم",
    "أفعينكما",
    "أفعينكن",
    "أفعيننا",
    "أفعينه",
    "أفعينها",
    "أفعينهم",
    "أفعينهما",
    "أفعينهن",
    "أفعيني",
    "أفغيرك",
    "أفغيركم",
    "أفغيركما",
    "أفغيركن",
    "أفغيرنا",
    "أفغيره",
    "أفغيرها",
    "أفغيرهم",
    "أفغيرهما",
    "أفغيرهن",
    "أفغيري",
    "أففلان",
    "أففوق",
    "أففوقك",
    "أففوقكم",
    "أففوقكما",
    "أففوقكن",
    "أففوقنا",
    "أففوقه",
    "أففوقها",
    "أففوقهم",
    "أففوقهما",
    "أففوقهن",
    "أففوقي",
    "أففي",
    "أففيك",
    "أففيكم",
    "أففيكما",
    "أففيكن",
    "أففينا",
    "أففيه",
    "أففيها",
    "أففيهم",
    "أففيهما",
    "أففيهن",
    "أففيي",
    "أفقلما",
    "أفكأجمع",
    "أفكأجمعك",
    "أفكأجمعكم",
    "أفكأجمعكما",
    "أفكأجمعكن",
    "أفكأجمعنا",
    "أفكأجمعه",
    "أفكأجمعها",
    "أفكأجمعهم",
    "أفكأجمعهما",
    "أفكأجمعهن",
    "أفكأجمعي",
    "أفكأن",
    "أفكأنك",
    "أفكأنكم",
    "أفكأنكما",
    "أفكأنكن",
    "أفكأنما",
    "أفكأننا",
    "أفكأنه",
    "أفكأنها",
    "أفكأنهم",
    "أفكأنهما",
    "أفكأنهن",
    "أفكأني",
    "أفكأولئك",
    "أفكأولئكم",
    "أفكأولاء",
    "أفكأولالك",
    "أفكإن",
    "أفكإنك",
    "أفكإنكم",
    "أفكإنكما",
    "أفكإنكن",
    "أفكإننا",
    "أفكإنه",
    "أفكإنها",
    "أفكإنهم",
    "أفكإنهما",
    "أفكإنهن",
    "أفكإني",
    "أفكبعض",
    "أفكبعضك",
    "أفكبعضكم",
    "أفكبعضكما",
    "أفكبعضكن",
    "أفكبعضنا",
    "أفكبعضه",
    "أفكبعضها",
    "أفكبعضهم",
    "أفكبعضهما",
    "أفكبعضهن",
    "أفكبعضي",
    "أفكتلك",
    "أفكتلكم",
    "أفكتلكما",
    "أفكتينك",
    "أفكجميع",
    "أفكجميعك",
    "أفكجميعكم",
    "أفكجميعكما",
    "أفكجميعكن",
    "أفكجميعنا",
    "أفكجميعه",
    "أفكجميعها",
    "أفكجميعهم",
    "أفكجميعهما",
    "أفكجميعهن",
    "أفكجميعي",
    "أفكحسب",
    "أفكحسبك",
    "أفكحسبكم",
    "أفكحسبكما",
    "أفكحسبكن",
    "أفكحسبنا",
    "أفكحسبه",
    "أفكحسبها",
    "أفكحسبهم",
    "أفكحسبهما",
    "أفكحسبهن",
    "أفكحسبي",
    "أفكذا",
    "أفكذاك",
    "أفكذانك",
    "أفكذلك",
    "أفكذلكم",
    "أفكذلكما",
    "أفكذلكن",
    "أفكذواتي",
    "أفكذي",
    "أفكذين",
    "أفكذينك",
    "أفكسوى",
    "أفكسوي",
    "أفكسويك",
    "أفكسويكم",
    "أفكسويكما",
    "أفكسويكن",
    "أفكسوينا",
    "أفكسويه",
    "أفكسويها",
    "أفكسويهم",
    "أفكسويهما",
    "أفكسويهن",
    "أفكشبه",
    "أفكشبهك",
    "أفكشبهكم",
    "أفكشبهكما",
    "أفكشبهكن",
    "أفكشبهنا",
    "أفكشبهه",
    "أفكشبهها",
    "أفكشبههم",
    "أفكشبههما",
    "أفكشبههن",
    "أفكشبهي",
    "أفكعامة",
    "أفكعامتك",
    "أفكعامتكم",
    "أفكعامتكما",
    "أفكعامتكن",
    "أفكعامتنا",
    "أفكعامته",
    "أفكعامتها",
    "أفكعامتهم",
    "أفكعامتهما",
    "أفكعامتهن",
    "أفكعامتي",
    "أفكعين",
    "أفكعينك",
    "أفكعينكم",
    "أفكعينكما",
    "أفكعينكن",
    "أفكعيننا",
    "أفكعينه",
    "أفكعينها",
    "أفكعينهم",
    "أفكعينهما",
    "أفكعينهن",
    "أفكعيني",
    "أفكغير",
    "أفكغيرك",
    "أفكغيركم",
    "أفكغيركما",
    "أفكغيركن",
    "أفكغيرنا",
    "أفكغيره",
    "أفكغيرها",
    "أفكغيرهم",
    "أفكغيرهما",
    "أفكغيرهن",
    "أفكغيري",
    "أفككل",
    "أفككلتا",
    "أفككلك",
    "أفككلكم",
    "أفككلكما",
    "أفككلكن",
    "أفككلنا",
    "أفككله",
    "أفككلها",
    "أفككلهم",
    "أفككلهما",
    "أفككلهن",
    "أفككلي",
    "أفككليكما",
    "أفككليهما",
    "أفكل",
    "أفكلا",
    "أفكلاهما",
    "أفكلتا",
    "أفكلك",
    "أفكلكم",
    "أفكلكما",
    "أفكلكن",
    "أفكلنا",
    "أفكله",
    "أفكلها",
    "أفكلهم",
    "أفكلهما",
    "أفكلهن",
    "أفكلي",
    "أفكليكما",
    "أفكليهما",
    "أفكمثل",
    "أفكمثلك",
    "أفكمثلكم",
    "أفكمثلكما",
    "أفكمثلكن",
    "أفكمثلنا",
    "أفكمثله",
    "أفكمثلها",
    "أفكمثلهم",
    "أفكمثلهما",
    "أفكمثلهن",
    "أفكمثلي",
    "أفكمن",
    "أفكنحو",
    "أفكنحوك",
    "أفكنحوكم",
    "أفكنحوكما",
    "أفكنحوكن",
    "أفكنحونا",
    "أفكنحوه",
    "أفكنحوها",
    "أفكنحوهم",
    "أفكنحوهما",
    "أفكنحوهن",
    "أفكنحوي",
    "أفكنفس",
    "أفكنفسك",
    "أفكنفسكم",
    "أفكنفسكما",
    "أفكنفسكن",
    "أفكنفسنا",
    "أفكنفسه",
    "أفكنفسها",
    "أفكنفسهم",
    "أفكنفسهما",
    "أفكنفسهن",
    "أفكنفسي",
    "أفكهؤلاء",
    "أفكهاته",
    "أفكهاتي",
    "أفكهاتين",
    "أفكهذا",
    "أفكهذه",
    "أفكهم",
    "أفكهما",
    "أفكهن",
    "أفكهناك",
    "أفكهنالك",
    "أفكهو",
    "أفكهي",
    "أفلأجمع",
    "أفلأجمعك",
    "أفلأجمعكم",
    "أفلأجمعكما",
    "أفلأجمعكن",
    "أفلأجمعنا",
    "أفلأجمعه",
    "أفلأجمعها",
    "أفلأجمعهم",
    "أفلأجمعهما",
    "أفلأجمعهن",
    "أفلأجمعي",
    "أفلأمام",
    "أفلأمامك",
    "أفلأمامكم",
    "أفلأمامكما",
    "أفلأمامكن",
    "أفلأمامنا",
    "أفلأمامه",
    "أفلأمامها",
    "أفلأمامهم",
    "أفلأمامهما",
    "أفلأمامهن",
    "أفلأمامي",
    "أفلأنا",
    "أفلأنت",
    "أفلأنتم",
    "أفلأنتما",
    "أفلأنتن",
    "أفلأولئك",
    "أفلأولئكم",
    "أفلأولاء",
    "أفلأولالك",
    "أفلإلى",
    "أفلإلي",
    "أفلإليك",
    "أفلإليكم",
    "أفلإليكما",
    "أفلإليكن",
    "أفلإلينا",
    "أفلإليه",
    "أفلإليها",
    "أفلإليهم",
    "أفلإليهما",
    "أفلإليهن",
    "أفلإن",
    "أفلإنك",
    "أفلإنكم",
    "أفلإنكما",
    "أفلإنكن",
    "أفلإننا",
    "أفلإنه",
    "أفلإنها",
    "أفلإنهم",
    "أفلإنهما",
    "أفلإنهن",
    "أفلإني",
    "أفلان",
    "أفلبعض",
    "أفلبعضك",
    "أفلبعضكم",
    "أفلبعضكما",
    "أفلبعضكن",
    "أفلبعضنا",
    "أفلبعضه",
    "أفلبعضها",
    "أفلبعضهم",
    "أفلبعضهما",
    "أفلبعضهن",
    "أفلبعضي",
    "أفلتحت",
    "أفلتحتك",
    "أفلتحتكم",
    "أفلتحتكما",
    "أفلتحتكن",
    "أفلتحتنا",
    "أفلتحته",
    "أفلتحتها",
    "أفلتحتهم",
    "أفلتحتهما",
    "أفلتحتهن",
    "أفلتحتي",
    "أفلتلك",
    "أفلتلكم",
    "أفلتلكما",
    "أفلتينك",
    "أفلجميع",
    "أفلجميعك",
    "أفلجميعكم",
    "أفلجميعكما",
    "أفلجميعكن",
    "أفلجميعنا",
    "أفلجميعه",
    "أفلجميعها",
    "أفلجميعهم",
    "أفلجميعهما",
    "أفلجميعهن",
    "أفلجميعي",
    "أفلحسب",
    "أفلحسبك",
    "أفلحسبكم",
    "أفلحسبكما",
    "أفلحسبكن",
    "أفلحسبنا",
    "أفلحسبه",
    "أفلحسبها",
    "أفلحسبهم",
    "أفلحسبهما",
    "أفلحسبهن",
    "أفلحسبي",
    "أفلخلف",
    "أفلخلفك",
    "أفلخلفكم",
    "أفلخلفكما",
    "أفلخلفكن",
    "أفلخلفنا",
    "أفلخلفه",
    "أفلخلفها",
    "أفلخلفهم",
    "أفلخلفهما",
    "أفلخلفهن",
    "أفلخلفي",
    "أفلدى",
    "أفلدي",
    "أفلديك",
    "أفلديكم",
    "أفلديكما",
    "أفلديكن",
    "أفلدينا",
    "أفلديه",
    "أفلديها",
    "أفلديهم",
    "أفلديهما",
    "أفلديهن",
    "أفلذاك",
    "أفلذانك",
    "أفلذلك",
    "أفلذلكم",
    "أفلذلكما",
    "أفلذلكن",
    "أفلذواتي",
    "أفلذي",
    "أفلذين",
    "أفلذينك",
    "أفلست",
    "أفلستم",
    "أفلستما",
    "أفلستن",
    "أفلسن",
    "أفلسنا",
    "أفلسوى",
    "أفلسوي",
    "أفلسويك",
    "أفلسويكم",
    "أفلسويكما",
    "أفلسويكن",
    "أفلسوينا",
    "أفلسويه",
    "أفلسويها",
    "أفلسويهم",
    "أفلسويهما",
    "أفلسويهن",
    "أفلشبه",
    "أفلشبهك",
    "أفلشبهكم",
    "أفلشبهكما",
    "أفلشبهكن",
    "أفلشبهنا",
    "أفلشبهه",
    "أفلشبهها",
    "أفلشبههم",
    "أفلشبههما",
    "أفلشبههن",
    "أفلشبهي",
    "أفلشمال",
    "أفلشمالك",
    "أفلشمالكم",
    "أفلشمالكما",
    "أفلشمالكن",
    "أفلشمالنا",
    "أفلشماله",
    "أفلشمالها",
    "أفلشمالهم",
    "أفلشمالهما",
    "أفلشمالهن",
    "أفلشمالي",
    "أفلطالما",
    "أفلعامة",
    "أفلعامتك",
    "أفلعامتكم",
    "أفلعامتكما",
    "أفلعامتكن",
    "أفلعامتنا",
    "أفلعامته",
    "أفلعامتها",
    "أفلعامتهم",
    "أفلعامتهما",
    "أفلعامتهن",
    "أفلعامتي",
    "أفلعل",
    "أفلعلك",
    "أفلعلكم",
    "أفلعلكما",
    "أفلعلكن",
    "أفلعلنا",
    "أفلعله",
    "أفلعلها",
    "أفلعلهم",
    "أفلعلهما",
    "أفلعلهن",
    "أفلعلى",
    "أفلعلي",
    "أفلعليك",
    "أفلعليكم",
    "أفلعليكما",
    "أفلعليكن",
    "أفلعلينا",
    "أفلعليه",
    "أفلعليها",
    "أفلعليهم",
    "أفلعليهما",
    "أفلعليهن",
    "أفلعن",
    "أفلعنك",
    "أفلعنكم",
    "أفلعنكما",
    "أفلعنكن",
    "أفلعننا",
    "أفلعنه",
    "أفلعنها",
    "أفلعنهم",
    "أفلعنهما",
    "أفلعنهن",
    "أفلعني",
    "أفلعين",
    "أفلعينك",
    "أفلعينكم",
    "أفلعينكما",
    "أفلعينكن",
    "أفلعيننا",
    "أفلعينه",
    "أفلعينها",
    "أفلعينهم",
    "أفلعينهما",
    "أفلعينهن",
    "أفلعيني",
    "أفلغير",
    "أفلغيرك",
    "أفلغيركم",
    "أفلغيركما",
    "أفلغيركن",
    "أفلغيرنا",
    "أفلغيره",
    "أفلغيرها",
    "أفلغيرهم",
    "أفلغيرهما",
    "أفلغيرهن",
    "أفلغيري",
    "أفلفوق",
    "أفلفوقك",
    "أفلفوقكم",
    "أفلفوقكما",
    "أفلفوقكن",
    "أفلفوقنا",
    "أفلفوقه",
    "أفلفوقها",
    "أفلفوقهم",
    "أفلفوقهما",
    "أفلفوقهن",
    "أفلفوقي",
    "أفلفي",
    "أفلفيك",
    "أفلفيكم",
    "أفلفيكما",
    "أفلفيكن",
    "أفلفينا",
    "أفلفيه",
    "أفلفيها",
    "أفلفيهم",
    "أفلفيهما",
    "أفلفيهن",
    "أفلفيي",
    "أفلقلما",
    "أفلك",
    "أفلكأن",
    "أفلكأنك",
    "أفلكأنكم",
    "أفلكأنكما",
    "أفلكأنكن",
    "أفلكأنما",
    "أفلكأننا",
    "أفلكأنه",
    "أفلكأنها",
    "أفلكأنهم",
    "أفلكأنهما",
    "أفلكأنهن",
    "أفلكأني",
    "أفلكل",
    "أفلكلتا",
    "أفلكلك",
    "أفلكلكم",
    "أفلكلكما",
    "أفلكلكن",
    "أفلكلنا",
    "أفلكله",
    "أفلكلها",
    "أفلكلهم",
    "أفلكلهما",
    "أفلكلهن",
    "أفلكلي",
    "أفلكليكما",
    "أفلكليهما",
    "أفلكم",
    "أفلكما",
    "أفلكن",
    "أفلما",
    "أفلمثل",
    "أفلمثلك",
    "أفلمثلكم",
    "أفلمثلكما",
    "أفلمثلكن",
    "أفلمثلنا",
    "أفلمثله",
    "أفلمثلها",
    "أفلمثلهم",
    "أفلمثلهما",
    "أفلمثلهن",
    "أفلمثلي",
    "أفلمع",
    "أفلمعك",
    "أفلمعكم",
    "أفلمعكما",
    "أفلمعكن",
    "أفلمعنا",
    "أفلمعه",
    "أفلمعها",
    "أفلمعهم",
    "أفلمعهما",
    "أفلمعهن",
    "أفلمعي",
    "أفلمن",
    "أفلمنك",
    "أفلمنكم",
    "أفلمنكما",
    "أفلمنكن",
    "أفلمننا",
    "أفلمنه",
    "أفلمنها",
    "أفلمنهم",
    "أفلمنهما",
    "أفلمنهن",
    "أفلمني",
    "أفلن",
    "أفلنا",
    "أفلنحن",
    "أفلنحو",
    "أفلنحوك",
    "أفلنحوكم",
    "أفلنحوكما",
    "أفلنحوكن",
    "أفلنحونا",
    "أفلنحوه",
    "أفلنحوها",
    "أفلنحوهم",
    "أفلنحوهما",
    "أفلنحوهن",
    "أفلنحوي",
    "أفلنفس",
    "أفلنفسك",
    "أفلنفسكم",
    "أفلنفسكما",
    "أفلنفسكن",
    "أفلنفسنا",
    "أفلنفسه",
    "أفلنفسها",
    "أفلنفسهم",
    "أفلنفسهما",
    "أفلنفسهن",
    "أفلنفسي",
    "أفله",
    "أفلهؤلاء",
    "أفلها",
    "أفلهاته",
    "أفلهاتي",
    "أفلهاتين",
    "أفلهذا",
    "أفلهذه",
    "أفلهم",
    "أفلهما",
    "أفلهن",
    "أفلهناك",
    "أفلهنالك",
    "أفلهو",
    "أفلهي",
    "أفلو",
    "أفلي",
    "أفليس",
    "أفليسا",
    "أفليست",
    "أفليستا",
    "أفليسوا",
    "أفليمين",
    "أفليمينك",
    "أفليمينكم",
    "أفليمينكما",
    "أفليمينكن",
    "أفليميننا",
    "أفليمينه",
    "أفليمينها",
    "أفليمينهم",
    "أفليمينهما",
    "أفليمينهن",
    "أفليميني",
    "أفماذا",
    "أفمثل",
    "أفمثلك",
    "أفمثلكم",
    "أفمثلكما",
    "أفمثلكن",
    "أفمثلنا",
    "أفمثله",
    "أفمثلها",
    "أفمثلهم",
    "أفمثلهما",
    "أفمثلهن",
    "أفمثلي",
    "أفمع",
    "أفمعك",
    "أفمعكم",
    "أفمعكما",
    "أفمعكن",
    "أفمعنا",
    "أفمعه",
    "أفمعها",
    "أفمعهم",
    "أفمعهما",
    "أفمعهن",
    "أفمعي",
    "أفمما",
    "أفمنك",
    "أفمنكم",
    "أفمنكما",
    "أفمنكن",
    "أفمننا",
    "أفمنه",
    "أفمنها",
    "أفمنهم",
    "أفمنهما",
    "أفمنهن",
    "أفمني",
    "أفمهما",
    "أفنحن",
    "أفنحو",
    "أفنحوك",
    "أفنحوكم",
    "أفنحوكما",
    "أفنحوكن",
    "أفنحونا",
    "أفنحوه",
    "أفنحوها",
    "أفنحوهم",
    "أفنحوهما",
    "أفنحوهن",
    "أفنحوي",
    "أفنفس",
    "أفنفسك",
    "أفنفسكم",
    "أفنفسكما",
    "أفنفسكن",
    "أفنفسنا",
    "أفنفسه",
    "أفنفسها",
    "أفنفسهم",
    "أفنفسهما",
    "أفنفسهن",
    "أفنفسي",
    "أفهؤلاء",
    "أفهاتان",
    "أفهاته",
    "أفهاتي",
    "أفهاتين",
    "أفهاهنا",
    "أفهذا",
    "أفهذان",
    "أفهذه",
    "أفهكذا",
    "أفهما",
    "أفهن",
    "أفهنا",
    "أفهناك",
    "أفهنالك",
    "أفهو",
    "أفهي",
    "أفوق",
    "أفوقك",
    "أفوقكم",
    "أفوقكما",
    "أفوقكن",
    "أفوقنا",
    "أفوقه",
    "أفوقها",
    "أفوقهم",
    "أفوقهما",
    "أفوقهن",
    "أفوقي",
    "أفيك",
    "أفيكم",
    "أفيكما",
    "أفيكن",
    "أفيمين",
    "أفيمينك",
    "أفيمينكم",
    "أفيمينكما",
    "أفيمينكن",
    "أفيميننا",
    "أفيمينه",
    "أفيمينها",
    "أفيمينهم",
    "أفيمينهما",
    "أفيمينهن",
    "أفيميني",
    "أفينا",
    "أفيه",
    "أفيها",
    "أفيهم",
    "أفيهما",
    "أفيهن",
    "أفيي",
    "أقلك",
    "أقلكم",
    "أقلكما",
    "أقلكن",
    "أقلما",
    "أقلنا",
    "أقله",
    "أقلها",
    "أقلهم",
    "أقلهما",
    "أقلهن",
    "أقلي",
    "أكأجمع",
    "أكأجمعك",
    "أكأجمعكم",
    "أكأجمعكما",
    "أكأجمعكن",
    "أكأجمعنا",
    "أكأجمعه",
    "أكأجمعها",
    "أكأجمعهم",
    "أكأجمعهما",
    "أكأجمعهن",
    "أكأجمعي",
    "أكأن",
    "أكأنك",
    "أكأنكم",
    "أكأنكما",
    "أكأنكن",
    "أكأنما",
    "أكأننا",
    "أكأنه",
    "أكأنها",
    "أكأنهم",
    "أكأنهما",
    "أكأنهن",
    "أكأني",
    "أكأولئك",
    "أكأولئكم",
    "أكأولاء",
    "أكأولالك",
    "أكإن",
    "أكإنك",
    "أكإنكم",
    "أكإنكما",
    "أكإنكن",
    "أكإننا",
    "أكإنه",
    "أكإنها",
    "أكإنهم",
    "أكإنهما",
    "أكإنهن",
    "أكإني",
    "أكبعض",
    "أكبعضك",
    "أكبعضكم",
    "أكبعضكما",
    "أكبعضكن",
    "أكبعضنا",
    "أكبعضه",
    "أكبعضها",
    "أكبعضهم",
    "أكبعضهما",
    "أكبعضهن",
    "أكبعضي",
    "أكتلك",
    "أكتلكم",
    "أكتلكما",
    "أكتينك",
    "أكثرك",
    "أكثركما",
    "أكثركن",
    "أكثرنا",
    "أكثره",
    "أكثرها",
    "أكثرهما",
    "أكثرهن",
    "أكثري",
    "أكجميع",
    "أكجميعك",
    "أكجميعكم",
    "أكجميعكما",
    "أكجميعكن",
    "أكجميعنا",
    "أكجميعه",
    "أكجميعها",
    "أكجميعهم",
    "أكجميعهما",
    "أكجميعهن",
    "أكجميعي",
    "أكحسب",
    "أكحسبك",
    "أكحسبكم",
    "أكحسبكما",
    "أكحسبكن",
    "أكحسبنا",
    "أكحسبه",
    "أكحسبها",
    "أكحسبهم",
    "أكحسبهما",
    "أكحسبهن",
    "أكحسبي",
    "أكذا",
    "أكذاك",
    "أكذانك",
    "أكذلك",
    "أكذلكم",
    "أكذلكما",
    "أكذلكن",
    "أكذواتي",
    "أكذي",
    "أكذين",
    "أكذينك",
    "أكسوى",
    "أكسوي",
    "أكسويك",
    "أكسويكم",
    "أكسويكما",
    "أكسويكن",
    "أكسوينا",
    "أكسويه",
    "أكسويها",
    "أكسويهم",
    "أكسويهما",
    "أكسويهن",
    "أكشبه",
    "أكشبهك",
    "أكشبهكم",
    "أكشبهكما",
    "أكشبهكن",
    "أكشبهنا",
    "أكشبهه",
    "أكشبهها",
    "أكشبههم",
    "أكشبههما",
    "أكشبههن",
    "أكشبهي",
    "أكعامة",
    "أكعامتك",
    "أكعامتكم",
    "أكعامتكما",
    "أكعامتكن",
    "أكعامتنا",
    "أكعامته",
    "أكعامتها",
    "أكعامتهم",
    "أكعامتهما",
    "أكعامتهن",
    "أكعامتي",
    "أكعين",
    "أكعينك",
    "أكعينكم",
    "أكعينكما",
    "أكعينكن",
    "أكعيننا",
    "أكعينه",
    "أكعينها",
    "أكعينهم",
    "أكعينهما",
    "أكعينهن",
    "أكعيني",
    "أكغير",
    "أكغيرك",
    "أكغيركم",
    "أكغيركما",
    "أكغيركن",
    "أكغيرنا",
    "أكغيره",
    "أكغيرها",
    "أكغيرهم",
    "أكغيرهما",
    "أكغيرهن",
    "أكغيري",
    "أككل",
    "أككلتا",
    "أككلك",
    "أككلكم",
    "أككلكما",
    "أككلكن",
    "أككلنا",
    "أككله",
    "أككلها",
    "أككلهم",
    "أككلهما",
    "أككلهن",
    "أككلي",
    "أككليكما",
    "أككليهما",
    "أكلاهما",
    "أكلتا",
    "أكلك",
    "أكلكم",
    "أكلكما",
    "أكلكن",
    "أكلما",
    "أكلنا",
    "أكلهم",
    "أكلهما",
    "أكلهن",
    "أكليكما",
    "أكليهما",
    "أكمثل",
    "أكمثلك",
    "أكمثلكم",
    "أكمثلكما",
    "أكمثلكن",
    "أكمثلنا",
    "أكمثله",
    "أكمثلها",
    "أكمثلهم",
    "أكمثلهما",
    "أكمثلهن",
    "أكمثلي",
    "أكمن",
    "أكنحو",
    "أكنحوك",
    "أكنحوكم",
    "أكنحوكما",
    "أكنحوكن",
    "أكنحونا",
    "أكنحوه",
    "أكنحوها",
    "أكنحوهم",
    "أكنحوهما",
    "أكنحوهن",
    "أكنحوي",
    "أكنفس",
    "أكنفسك",
    "أكنفسكم",
    "أكنفسكما",
    "أكنفسكن",
    "أكنفسنا",
    "أكنفسه",
    "أكنفسها",
    "أكنفسهم",
    "أكنفسهما",
    "أكنفسهن",
    "أكنفسي",
    "أكهؤلاء",
    "أكهاته",
    "أكهاتي",
    "أكهاتين",
    "أكهذا",
    "أكهذه",
    "أكهم",
    "أكهما",
    "أكهن",
    "أكهناك",
    "أكهنالك",
    "أكهو",
    "أكهي",
    "ألأجمع",
    "ألأجمعك",
    "ألأجمعكم",
    "ألأجمعكما",
    "ألأجمعكن",
    "ألأجمعنا",
    "ألأجمعه",
    "ألأجمعها",
    "ألأجمعهم",
    "ألأجمعهما",
    "ألأجمعهن",
    "ألأجمعي",
    "ألأمام",
    "ألأمامك",
    "ألأمامكم",
    "ألأمامكما",
    "ألأمامكن",
    "ألأمامنا",
    "ألأمامه",
    "ألأمامها",
    "ألأمامهم",
    "ألأمامهما",
    "ألأمامهن",
    "ألأمامي",
    "ألأنا",
    "ألأنت",
    "ألأنتم",
    "ألأنتما",
    "ألأنتن",
    "ألأولئك",
    "ألأولئكم",
    "ألأولاء",
    "ألأولالك",
    "ألإلى",
    "ألإلي",
    "ألإليك",
    "ألإليكم",
    "ألإليكما",
    "ألإليكن",
    "ألإلينا",
    "ألإليه",
    "ألإليها",
    "ألإليهم",
    "ألإليهما",
    "ألإليهن",
    "ألإن",
    "ألإنك",
    "ألإنكم",
    "ألإنكما",
    "ألإنكن",
    "ألإننا",
    "ألإنه",
    "ألإنها",
    "ألإنهم",
    "ألإنهما",
    "ألإنهن",
    "ألإني",
    "ألبعض",
    "ألبعضك",
    "ألبعضكم",
    "ألبعضكما",
    "ألبعضكن",
    "ألبعضنا",
    "ألبعضه",
    "ألبعضها",
    "ألبعضهم",
    "ألبعضهما",
    "ألبعضهن",
    "ألبعضي",
    "ألتحت",
    "ألتحتك",
    "ألتحتكم",
    "ألتحتكما",
    "ألتحتكن",
    "ألتحتنا",
    "ألتحته",
    "ألتحتها",
    "ألتحتهم",
    "ألتحتهما",
    "ألتحتهن",
    "ألتحتي",
    "ألتلك",
    "ألتلكم",
    "ألتلكما",
    "ألتينك",
    "ألجميع",
    "ألجميعك",
    "ألجميعكم",
    "ألجميعكما",
    "ألجميعكن",
    "ألجميعنا",
    "ألجميعه",
    "ألجميعها",
    "ألجميعهم",
    "ألجميعهما",
    "ألجميعهن",
    "ألجميعي",
    "ألحسب",
    "ألحسبك",
    "ألحسبكم",
    "ألحسبكما",
    "ألحسبكن",
    "ألحسبنا",
    "ألحسبه",
    "ألحسبها",
    "ألحسبهم",
    "ألحسبهما",
    "ألحسبهن",
    "ألحسبي",
    "ألخلف",
    "ألخلفك",
    "ألخلفكم",
    "ألخلفكما",
    "ألخلفكن",
    "ألخلفنا",
    "ألخلفه",
    "ألخلفها",
    "ألخلفهم",
    "ألخلفهما",
    "ألخلفهن",
    "ألخلفي",
    "ألدى",
    "ألدي",
    "ألديك",
    "ألديكم",
    "ألديكما",
    "ألديكن",
    "ألدينا",
    "ألديه",
    "ألديها",
    "ألديهم",
    "ألديهما",
    "ألديهن",
    "ألذاك",
    "ألذانك",
    "ألذلك",
    "ألذلكم",
    "ألذلكما",
    "ألذلكن",
    "ألذواتي",
    "ألذي",
    "ألذين",
    "ألذينك",
    "ألستم",
    "ألستما",
    "ألستن",
    "ألسن",
    "ألسنا",
    "ألسوى",
    "ألسوي",
    "ألسويك",
    "ألسويكم",
    "ألسويكما",
    "ألسويكن",
    "ألسوينا",
    "ألسويه",
    "ألسويها",
    "ألسويهم",
    "ألسويهما",
    "ألسويهن",
    "ألشبه",
    "ألشبهك",
    "ألشبهكم",
    "ألشبهكما",
    "ألشبهكن",
    "ألشبهنا",
    "ألشبهه",
    "ألشبهها",
    "ألشبههم",
    "ألشبههما",
    "ألشبههن",
    "ألشبهي",
    "ألشمال",
    "ألشمالك",
    "ألشمالكم",
    "ألشمالكما",
    "ألشمالكن",
    "ألشمالنا",
    "ألشماله",
    "ألشمالها",
    "ألشمالهم",
    "ألشمالهما",
    "ألشمالهن",
    "ألشمالي",
    "ألطالما",
    "ألعامة",
    "ألعامتك",
    "ألعامتكم",
    "ألعامتكما",
    "ألعامتكن",
    "ألعامتنا",
    "ألعامته",
    "ألعامتها",
    "ألعامتهم",
    "ألعامتهما",
    "ألعامتهن",
    "ألعامتي",
    "ألعل",
    "ألعلك",
    "ألعلكم",
    "ألعلكما",
    "ألعلكن",
    "ألعلنا",
    "ألعله",
    "ألعلها",
    "ألعلهم",
    "ألعلهما",
    "ألعلهن",
    "ألعلى",
    "ألعلي",
    "ألعليك",
    "ألعليكم",
    "ألعليكما",
    "ألعليكن",
    "ألعلينا",
    "ألعليه",
    "ألعليها",
    "ألعليهم",
    "ألعليهما",
    "ألعليهن",
    "ألعن",
    "ألعنك",
    "ألعنكم",
    "ألعنكما",
    "ألعنكن",
    "ألعننا",
    "ألعنه",
    "ألعنها",
    "ألعنهم",
    "ألعنهما",
    "ألعنهن",
    "ألعني",
    "ألعين",
    "ألعينك",
    "ألعينكم",
    "ألعينكما",
    "ألعينكن",
    "ألعيننا",
    "ألعينه",
    "ألعينها",
    "ألعينهم",
    "ألعينهما",
    "ألعينهن",
    "ألعيني",
    "ألغير",
    "ألغيرك",
    "ألغيركم",
    "ألغيركما",
    "ألغيركن",
    "ألغيرنا",
    "ألغيره",
    "ألغيرها",
    "ألغيرهم",
    "ألغيرهما",
    "ألغيرهن",
    "ألغيري",
    "ألفوق",
    "ألفوقك",
    "ألفوقكم",
    "ألفوقكما",
    "ألفوقكن",
    "ألفوقنا",
    "ألفوقه",
    "ألفوقها",
    "ألفوقهم",
    "ألفوقهما",
    "ألفوقهن",
    "ألفوقي",
    "ألفيك",
    "ألفيكم",
    "ألفيكما",
    "ألفيكن",
    "ألفيه",
    "ألفيها",
    "ألفيهم",
    "ألفيهما",
    "ألفيهن",
    "ألفيي",
    "ألقلما",
    "ألك",
    "ألكأن",
    "ألكأنك",
    "ألكأنكم",
    "ألكأنكما",
    "ألكأنكن",
    "ألكأنما",
    "ألكأننا",
    "ألكأنه",
    "ألكأنها",
    "ألكأنهم",
    "ألكأنهما",
    "ألكأنهن",
    "ألكأني",
    "ألكل",
    "ألكلتا",
    "ألكلك",
    "ألكلكم",
    "ألكلكما",
    "ألكلكن",
    "ألكلنا",
    "ألكله",
    "ألكلها",
    "ألكلهم",
    "ألكلهما",
    "ألكلهن",
    "ألكلي",
    "ألكليكما",
    "ألكليهما",
    "ألكما",
    "ألكن",
    "ألما",
    "ألمثل",
    "ألمثلك",
    "ألمثلكم",
    "ألمثلكما",
    "ألمثلكن",
    "ألمثلنا",
    "ألمثله",
    "ألمثلها",
    "ألمثلهم",
    "ألمثلهما",
    "ألمثلهن",
    "ألمثلي",
    "ألمعك",
    "ألمعكم",
    "ألمعكما",
    "ألمعكن",
    "ألمعنا",
    "ألمعه",
    "ألمعها",
    "ألمعهم",
    "ألمعهما",
    "ألمعهن",
    "ألمعي",
    "ألمن",
    "ألمنك",
    "ألمنكم",
    "ألمنكما",
    "ألمنكن",
    "ألمننا",
    "ألمنه",
    "ألمنها",
    "ألمنهم",
    "ألمنهما",
    "ألمنهن",
    "ألمني",
    "ألنا",
    "ألنحن",
    "ألنحو",
    "ألنحوك",
    "ألنحوكم",
    "ألنحوكما",
    "ألنحوكن",
    "ألنحونا",
    "ألنحوه",
    "ألنحوها",
    "ألنحوهم",
    "ألنحوهما",
    "ألنحوهن",
    "ألنحوي",
    "ألنفس",
    "ألنفسك",
    "ألنفسكم",
    "ألنفسكما",
    "ألنفسكن",
    "ألنفسنا",
    "ألنفسه",
    "ألنفسها",
    "ألنفسهم",
    "ألنفسهما",
    "ألنفسهن",
    "ألنفسي",
    "ألهؤلاء",
    "ألها",
    "ألهاته",
    "ألهاتي",
    "ألهاتين",
    "ألهذا",
    "ألهذه",
    "ألهما",
    "ألهن",
    "ألهناك",
    "ألهنالك",
    "ألهو",
    "ألهي",
    "ألو",
    "أليست",
    "أليستا",
    "أليسوا",
    "أليمين",
    "أليمينك",
    "أليمينكم",
    "أليمينكما",
    "أليمينكن",
    "أليميننا",
    "أليمينه",
    "أليمينها",
    "أليمينهم",
    "أليمينهما",
    "أليمينهن",
    "أليميني",
    "أمامك",
    "أمامكم",
    "أمامكما",
    "أمامكن",
    "أمامنا",
    "أمامها",
    "أمامهم",
    "أمامهما",
    "أمامهن",
    "أمثل",
    "أمثلك",
    "أمثلكم",
    "أمثلكما",
    "أمثلكن",
    "أمثلنا",
    "أمثله",
    "أمثلها",
    "أمثلهما",
    "أمثلهن",
    "أمثلي",
    "أمسى",
    "أمع",
    "أمعك",
    "أمعكم",
    "أمعكما",
    "أمعكن",
    "أمعنا",
    "أمعه",
    "أمعها",
    "أمعهم",
    "أمعهما",
    "أمعهن",
    "أمعي",
    "أمنك",
    "أمنكم",
    "أمنكما",
    "أمنكن",
    "أمننا",
    "أمنه",
    "أمنها",
    "أمنهم",
    "أمنهما",
    "أمنهن",
    "أمني",
    "أمهما",
    "أنتن",
    "أنحو",
    "أنحوك",
    "أنحوكم",
    "أنحوكما",
    "أنحوكن",
    "أنحونا",
    "أنحوه",
    "أنحوها",
    "أنحوهم",
    "أنحوهما",
    "أنحوهن",
    "أنحوي",
    "أنفس",
    "أنفسك",
    "أنفسكن",
    "أنفسه",
    "أنفسها",
    "أنفسهما",
    "أنفسي",
    "أنكما",
    "أنكن",
    "أنهن",
    "أهاتان",
    "أهاته",
    "أهاتي",
    "أهاتين",
    "أهاهنا",
    "أهذان",
    "أهذه",
    "أهما",
    "أهنا",
    "أهناك",
    "أهنالك",
    "أهو",
    "أهي",
    "أوأجمع",
    "أوأجمعك",
    "أوأجمعكم",
    "أوأجمعكما",
    "أوأجمعكن",
    "أوأجمعنا",
    "أوأجمعه",
    "أوأجمعها",
    "أوأجمعهم",
    "أوأجمعهما",
    "أوأجمعهن",
    "أوأجمعي",
    "أوأمام",
    "أوأمامك",
    "أوأمامكم",
    "أوأمامكما",
    "أوأمامكن",
    "أوأمامنا",
    "أوأمامه",
    "أوأمامها",
    "أوأمامهم",
    "أوأمامهما",
    "أوأمامهن",
    "أوأمامي",
    "أوأنا",
    "أوأنت",
    "أوأنتم",
    "أوأنتما",
    "أوأنتن",
    "أوأولئك",
    "أوأولئكم",
    "أوأولاء",
    "أوأولالك",
    "أوإلى",
    "أوإلي",
    "أوإليك",
    "أوإليكم",
    "أوإليكما",
    "أوإليكن",
    "أوإلينا",
    "أوإليه",
    "أوإليها",
    "أوإليهم",
    "أوإليهما",
    "أوإليهن",
    "أوإن",
    "أوإنك",
    "أوإنكم",
    "أوإنكما",
    "أوإنكن",
    "أوإننا",
    "أوإنه",
    "أوإنها",
    "أوإنهم",
    "أوإنهما",
    "أوإنهن",
    "أوإني",
    "أوبأجمع",
    "أوبأجمعك",
    "أوبأجمعكم",
    "أوبأجمعكما",
    "أوبأجمعكن",
    "أوبأجمعنا",
    "أوبأجمعه",
    "أوبأجمعها",
    "أوبأجمعهم",
    "أوبأجمعهما",
    "أوبأجمعهن",
    "أوبأجمعي",
    "أوبأولئك",
    "أوبأولئكم",
    "أوبأولاء",
    "أوبأولالك",
    "أوبإن",
    "أوبإنك",
    "أوبإنكم",
    "أوبإنكما",
    "أوبإنكن",
    "أوبإننا",
    "أوبإنه",
    "أوبإنها",
    "أوبإنهم",
    "أوبإنهما",
    "أوبإنهن",
    "أوبإني",
    "أوببعض",
    "أوببعضك",
    "أوببعضكم",
    "أوببعضكما",
    "أوببعضكن",
    "أوببعضنا",
    "أوببعضه",
    "أوببعضها",
    "أوببعضهم",
    "أوببعضهما",
    "أوببعضهن",
    "أوببعضي",
    "أوبتلك",
    "أوبتلكم",
    "أوبتلكما",
    "أوبتينك",
    "أوبجميع",
    "أوبجميعك",
    "أوبجميعكم",
    "أوبجميعكما",
    "أوبجميعكن",
    "أوبجميعنا",
    "أوبجميعه",
    "أوبجميعها",
    "أوبجميعهم",
    "أوبجميعهما",
    "أوبجميعهن",
    "أوبجميعي",
    "أوبحسب",
    "أوبحسبك",
    "أوبحسبكم",
    "أوبحسبكما",
    "أوبحسبكن",
    "أوبحسبنا",
    "أوبحسبه",
    "أوبحسبها",
    "أوبحسبهم",
    "أوبحسبهما",
    "أوبحسبهن",
    "أوبحسبي",
    "أوبذاك",
    "أوبذانك",
    "أوبذلك",
    "أوبذلكم",
    "أوبذلكما",
    "أوبذلكن",
    "أوبذواتي",
    "أوبذي",
    "أوبذين",
    "أوبذينك",
    "أوبسوى",
    "أوبسوي",
    "أوبسويك",
    "أوبسويكم",
    "أوبسويكما",
    "أوبسويكن",
    "أوبسوينا",
    "أوبسويه",
    "أوبسويها",
    "أوبسويهم",
    "أوبسويهما",
    "أوبسويهن",
    "أوبشبه",
    "أوبشبهك",
    "أوبشبهكم",
    "أوبشبهكما",
    "أوبشبهكن",
    "أوبشبهنا",
    "أوبشبهه",
    "أوبشبهها",
    "أوبشبههم",
    "أوبشبههما",
    "أوبشبههن",
    "أوبشبهي",
    "أوبضع",
    "أوبضعك",
    "أوبضعكم",
    "أوبضعكما",
    "أوبضعكن",
    "أوبضعنا",
    "أوبضعه",
    "أوبضعها",
    "أوبضعهم",
    "أوبضعهما",
    "أوبضعهن",
    "أوبضعي",
    "أوبعامة",
    "أوبعامتك",
    "أوبعامتكم",
    "أوبعامتكما",
    "أوبعامتكن",
    "أوبعامتنا",
    "أوبعامته",
    "أوبعامتها",
    "أوبعامتهم",
    "أوبعامتهما",
    "أوبعامتهن",
    "أوبعامتي",
    "أوبعض",
    "أوبعضك",
    "أوبعضكم",
    "أوبعضكما",
    "أوبعضكن",
    "أوبعضنا",
    "أوبعضه",
    "أوبعضها",
    "أوبعضهم",
    "أوبعضهما",
    "أوبعضهن",
    "أوبعضي",
    "أوبعين",
    "أوبعينك",
    "أوبعينكم",
    "أوبعينكما",
    "أوبعينكن",
    "أوبعيننا",
    "أوبعينه",
    "أوبعينها",
    "أوبعينهم",
    "أوبعينهما",
    "أوبعينهن",
    "أوبعيني",
    "أوبغير",
    "أوبغيرك",
    "أوبغيركم",
    "أوبغيركما",
    "أوبغيركن",
    "أوبغيرنا",
    "أوبغيره",
    "أوبغيرها",
    "أوبغيرهم",
    "أوبغيرهما",
    "أوبغيرهن",
    "أوبغيري",
    "أوبك",
    "أوبكل",
    "أوبكلتا",
    "أوبكلك",
    "أوبكلكم",
    "أوبكلكما",
    "أوبكلكن",
    "أوبكلنا",
    "أوبكله",
    "أوبكلها",
    "أوبكلهم",
    "أوبكلهما",
    "أوبكلهن",
    "أوبكلي",
    "أوبكليكما",
    "أوبكليهما",
    "أوبكم",
    "أوبكما",
    "أوبكن",
    "أوبما",
    "أوبمثل",
    "أوبمثلك",
    "أوبمثلكم",
    "أوبمثلكما",
    "أوبمثلكن",
    "أوبمثلنا",
    "أوبمثله",
    "أوبمثلها",
    "أوبمثلهم",
    "أوبمثلهما",
    "أوبمثلهن",
    "أوبمثلي",
    "أوبمن",
    "أوبنا",
    "أوبنحو",
    "أوبنحوك",
    "أوبنحوكم",
    "أوبنحوكما",
    "أوبنحوكن",
    "أوبنحونا",
    "أوبنحوه",
    "أوبنحوها",
    "أوبنحوهم",
    "أوبنحوهما",
    "أوبنحوهن",
    "أوبنحوي",
    "أوبنفس",
    "أوبنفسك",
    "أوبنفسكم",
    "أوبنفسكما",
    "أوبنفسكن",
    "أوبنفسنا",
    "أوبنفسه",
    "أوبنفسها",
    "أوبنفسهم",
    "أوبنفسهما",
    "أوبنفسهن",
    "أوبنفسي",
    "أوبه",
    "أوبهؤلاء",
    "أوبها",
    "أوبهاته",
    "أوبهاتي",
    "أوبهاتين",
    "أوبهذا",
    "أوبهذه",
    "أوبهم",
    "أوبهما",
    "أوبهن",
    "أوبهناك",
    "أوبهنالك",
    "أوبهو",
    "أوبهي",
    "أوتحت",
    "أوتحتك",
    "أوتحتكم",
    "أوتحتكما",
    "أوتحتكن",
    "أوتحتنا",
    "أوتحته",
    "أوتحتها",
    "أوتحتهم",
    "أوتحتهما",
    "أوتحتهن",
    "أوتحتي",
    "أوتلك",
    "أوتلكم",
    "أوتلكما",
    "أوتينك",
    "أوثم",
    "أوثمة",
    "أوجميع",
    "أوجميعك",
    "أوجميعكم",
    "أوجميعكما",
    "أوجميعكن",
    "أوجميعنا",
    "أوجميعه",
    "أوجميعها",
    "أوجميعهم",
    "أوجميعهما",
    "أوجميعهن",
    "أوجميعي",
    "أوحسب",
    "أوحسبك",
    "أوحسبكم",
    "أوحسبكما",
    "أوحسبكن",
    "أوحسبنا",
    "أوحسبه",
    "أوحسبها",
    "أوحسبهم",
    "أوحسبهما",
    "أوحسبهن",
    "أوحسبي",
    "أوخلف",
    "أوخلفك",
    "أوخلفكم",
    "أوخلفكما",
    "أوخلفكن",
    "أوخلفنا",
    "أوخلفه",
    "أوخلفها",
    "أوخلفهم",
    "أوخلفهما",
    "أوخلفهن",
    "أوخلفي",
    "أوذاك",
    "أوذانك",
    "أوذلك",
    "أوذلكم",
    "أوذلكما",
    "أوذلكن",
    "أوذواتا",
    "أوذواتي",
    "أوذين",
    "أوذينك",
    "أوسوى",
    "أوسوي",
    "أوسويك",
    "أوسويكم",
    "أوسويكما",
    "أوسويكن",
    "أوسوينا",
    "أوسويه",
    "أوسويها",
    "أوسويهم",
    "أوسويهما",
    "أوسويهن",
    "أوشبه",
    "أوشبهك",
    "أوشبهكم",
    "أوشبهكما",
    "أوشبهكن",
    "أوشبهنا",
    "أوشبهه",
    "أوشبهها",
    "أوشبههم",
    "أوشبههما",
    "أوشبههن",
    "أوشبهي",
    "أوشك",
    "أوشمال",
    "أوشمالك",
    "أوشمالكم",
    "أوشمالكما",
    "أوشمالكن",
    "أوشمالنا",
    "أوشماله",
    "أوشمالها",
    "أوشمالهم",
    "أوشمالهما",
    "أوشمالهن",
    "أوشمالي",
    "أوطالما",
    "أوعامة",
    "أوعامتك",
    "أوعامتكم",
    "أوعامتكما",
    "أوعامتكن",
    "أوعامتنا",
    "أوعامته",
    "أوعامتها",
    "أوعامتهم",
    "أوعامتهما",
    "أوعامتهن",
    "أوعامتي",
    "أوعلى",
    "أوعلي",
    "أوعليك",
    "أوعليكم",
    "أوعليكما",
    "أوعليكن",
    "أوعلينا",
    "أوعليه",
    "أوعليها",
    "أوعليهم",
    "أوعليهما",
    "أوعليهن",
    "أوعن",
    "أوعنك",
    "أوعنكم",
    "أوعنكما",
    "أوعنكن",
    "أوعننا",
    "أوعنه",
    "أوعنها",
    "أوعنهم",
    "أوعنهما",
    "أوعنهن",
    "أوعني",
    "أوعين",
    "أوعينك",
    "أوعينكم",
    "أوعينكما",
    "أوعينكن",
    "أوعيننا",
    "أوعينه",
    "أوعينها",
    "أوعينهم",
    "أوعينهما",
    "أوعينهن",
    "أوعيني",
    "أوغير",
    "أوغيرك",
    "أوغيركم",
    "أوغيركما",
    "أوغيركن",
    "أوغيرنا",
    "أوغيره",
    "أوغيرها",
    "أوغيرهم",
    "أوغيرهما",
    "أوغيرهن",
    "أوغيري",
    "أوفلان",
    "أوفوق",
    "أوفوقك",
    "أوفوقكم",
    "أوفوقكما",
    "أوفوقكن",
    "أوفوقنا",
    "أوفوقه",
    "أوفوقها",
    "أوفوقهم",
    "أوفوقهما",
    "أوفوقهن",
    "أوفوقي",
    "أوفيك",
    "أوفيكم",
    "أوفيكما",
    "أوفيكن",
    "أوفينا",
    "أوفيه",
    "أوفيها",
    "أوفيهم",
    "أوفيهما",
    "أوفيهن",
    "أوفيي",
    "أوقلما",
    "أوكأجمع",
    "أوكأجمعك",
    "أوكأجمعكم",
    "أوكأجمعكما",
    "أوكأجمعكن",
    "أوكأجمعنا",
    "أوكأجمعه",
    "أوكأجمعها",
    "أوكأجمعهم",
    "أوكأجمعهما",
    "أوكأجمعهن",
    "أوكأجمعي",
    "أوكأن",
    "أوكأنك",
    "أوكأنكم",
    "أوكأنكما",
    "أوكأنكن",
    "أوكأنما",
    "أوكأننا",
    "أوكأنه",
    "أوكأنها",
    "أوكأنهم",
    "أوكأنهما",
    "أوكأنهن",
    "أوكأني",
    "أوكأولئك",
    "أوكأولئكم",
    "أوكأولاء",
    "أوكأولالك",
    "أوكإن",
    "أوكإنك",
    "أوكإنكم",
    "أوكإنكما",
    "أوكإنكن",
    "أوكإننا",
    "أوكإنه",
    "أوكإنها",
    "أوكإنهم",
    "أوكإنهما",
    "أوكإنهن",
    "أوكإني",
    "أوكبعض",
    "أوكبعضك",
    "أوكبعضكم",
    "أوكبعضكما",
    "أوكبعضكن",
    "أوكبعضنا",
    "أوكبعضه",
    "أوكبعضها",
    "أوكبعضهم",
    "أوكبعضهما",
    "أوكبعضهن",
    "أوكبعضي",
    "أوكتلك",
    "أوكتلكم",
    "أوكتلكما",
    "أوكتينك",
    "أوكجميع",
    "أوكجميعك",
    "أوكجميعكم",
    "أوكجميعكما",
    "أوكجميعكن",
    "أوكجميعنا",
    "أوكجميعه",
    "أوكجميعها",
    "أوكجميعهم",
    "أوكجميعهما",
    "أوكجميعهن",
    "أوكجميعي",
    "أوكحسب",
    "أوكحسبك",
    "أوكحسبكم",
    "أوكحسبكما",
    "أوكحسبكن",
    "أوكحسبنا",
    "أوكحسبه",
    "أوكحسبها",
    "أوكحسبهم",
    "أوكحسبهما",
    "أوكحسبهن",
    "أوكحسبي",
    "أوكذا",
    "أوكذاك",
    "أوكذانك",
    "أوكذلك",
    "أوكذلكم",
    "أوكذلكما",
    "أوكذلكن",
    "أوكذواتي",
    "أوكذي",
    "أوكذين",
    "أوكذينك",
    "أوكسوى",
    "أوكسوي",
    "أوكسويك",
    "أوكسويكم",
    "أوكسويكما",
    "أوكسويكن",
    "أوكسوينا",
    "أوكسويه",
    "أوكسويها",
    "أوكسويهم",
    "أوكسويهما",
    "أوكسويهن",
    "أوكشبه",
    "أوكشبهك",
    "أوكشبهكم",
    "أوكشبهكما",
    "أوكشبهكن",
    "أوكشبهنا",
    "أوكشبهه",
    "أوكشبهها",
    "أوكشبههم",
    "أوكشبههما",
    "أوكشبههن",
    "أوكشبهي",
    "أوكعامة",
    "أوكعامتك",
    "أوكعامتكم",
    "أوكعامتكما",
    "أوكعامتكن",
    "أوكعامتنا",
    "أوكعامته",
    "أوكعامتها",
    "أوكعامتهم",
    "أوكعامتهما",
    "أوكعامتهن",
    "أوكعامتي",
    "أوكعين",
    "أوكعينك",
    "أوكعينكم",
    "أوكعينكما",
    "أوكعينكن",
    "أوكعيننا",
    "أوكعينه",
    "أوكعينها",
    "أوكعينهم",
    "أوكعينهما",
    "أوكعينهن",
    "أوكعيني",
    "أوكغير",
    "أوكغيرك",
    "أوكغيركم",
    "أوكغيركما",
    "أوكغيركن",
    "أوكغيرنا",
    "أوكغيره",
    "أوكغيرها",
    "أوكغيرهم",
    "أوكغيرهما",
    "أوكغيرهن",
    "أوكغيري",
    "أوككل",
    "أوككلتا",
    "أوككلك",
    "أوككلكم",
    "أوككلكما",
    "أوككلكن",
    "أوككلنا",
    "أوككله",
    "أوككلها",
    "أوككلهم",
    "أوككلهما",
    "أوككلهن",
    "أوككلي",
    "أوككليكما",
    "أوككليهما",
    "أوكل",
    "أوكلا",
    "أوكلاهما",
    "أوكلتا",
    "أوكلك",
    "أوكلكم",
    "أوكلكما",
    "أوكلكن",
    "أوكلنا",
    "أوكله",
    "أوكلها",
    "أوكلهم",
    "أوكلهما",
    "أوكلهن",
    "أوكليكما",
    "أوكليهما",
    "أوكمثل",
    "أوكمثلك",
    "أوكمثلكم",
    "أوكمثلكما",
    "أوكمثلكن",
    "أوكمثلنا",
    "أوكمثله",
    "أوكمثلها",
    "أوكمثلهم",
    "أوكمثلهما",
    "أوكمثلهن",
    "أوكمثلي",
    "أوكمن",
    "أوكنحو",
    "أوكنحوك",
    "أوكنحوكم",
    "أوكنحوكما",
    "أوكنحوكن",
    "أوكنحونا",
    "أوكنحوه",
    "أوكنحوها",
    "أوكنحوهم",
    "أوكنحوهما",
    "أوكنحوهن",
    "أوكنحوي",
    "أوكنفس",
    "أوكنفسك",
    "أوكنفسكم",
    "أوكنفسكما",
    "أوكنفسكن",
    "أوكنفسنا",
    "أوكنفسه",
    "أوكنفسها",
    "أوكنفسهم",
    "أوكنفسهما",
    "أوكنفسهن",
    "أوكنفسي",
    "أوكهؤلاء",
    "أوكهاته",
    "أوكهاتي",
    "أوكهاتين",
    "أوكهذا",
    "أوكهذه",
    "أوكهم",
    "أوكهما",
    "أوكهن",
    "أوكهناك",
    "أوكهنالك",
    "أوكهو",
    "أوكهي",
    "أولأجمع",
    "أولأجمعك",
    "أولأجمعكم",
    "أولأجمعكما",
    "أولأجمعكن",
    "أولأجمعنا",
    "أولأجمعه",
    "أولأجمعها",
    "أولأجمعهم",
    "أولأجمعهما",
    "أولأجمعهن",
    "أولأجمعي",
    "أولأمام",
    "أولأمامك",
    "أولأمامكم",
    "أولأمامكما",
    "أولأمامكن",
    "أولأمامنا",
    "أولأمامه",
    "أولأمامها",
    "أولأمامهم",
    "أولأمامهما",
    "أولأمامهن",
    "أولأمامي",
    "أولأنا",
    "أولأنت",
    "أولأنتم",
    "أولأنتما",
    "أولأنتن",
    "أولأولئك",
    "أولأولئكم",
    "أولأولاء",
    "أولأولالك",
    "أولإلى",
    "أولإلي",
    "أولإليك",
    "أولإليكم",
    "أولإليكما",
    "أولإليكن",
    "أولإلينا",
    "أولإليه",
    "أولإليها",
    "أولإليهم",
    "أولإليهما",
    "أولإليهن",
    "أولإن",
    "أولإنك",
    "أولإنكم",
    "أولإنكما",
    "أولإنكن",
    "أولإننا",
    "أولإنه",
    "أولإنها",
    "أولإنهم",
    "أولإنهما",
    "أولإنهن",
    "أولإني",
    "أولالك",
    "أولبعض",
    "أولبعضك",
    "أولبعضكم",
    "أولبعضكما",
    "أولبعضكن",
    "أولبعضنا",
    "أولبعضه",
    "أولبعضها",
    "أولبعضهم",
    "أولبعضهما",
    "أولبعضهن",
    "أولبعضي",
    "أولتحت",
    "أولتحتك",
    "أولتحتكم",
    "أولتحتكما",
    "أولتحتكن",
    "أولتحتنا",
    "أولتحته",
    "أولتحتها",
    "أولتحتهم",
    "أولتحتهما",
    "أولتحتهن",
    "أولتحتي",
    "أولتلك",
    "أولتلكم",
    "أولتلكما",
    "أولتينك",
    "أولجميع",
    "أولجميعك",
    "أولجميعكم",
    "أولجميعكما",
    "أولجميعكن",
    "أولجميعنا",
    "أولجميعه",
    "أولجميعها",
    "أولجميعهم",
    "أولجميعهما",
    "أولجميعهن",
    "أولجميعي",
    "أولحسب",
    "أولحسبك",
    "أولحسبكم",
    "أولحسبكما",
    "أولحسبكن",
    "أولحسبنا",
    "أولحسبه",
    "أولحسبها",
    "أولحسبهم",
    "أولحسبهما",
    "أولحسبهن",
    "أولحسبي",
    "أولخلف",
    "أولخلفك",
    "أولخلفكم",
    "أولخلفكما",
    "أولخلفكن",
    "أولخلفنا",
    "أولخلفه",
    "أولخلفها",
    "أولخلفهم",
    "أولخلفهما",
    "أولخلفهن",
    "أولخلفي",
    "أولدى",
    "أولدي",
    "أولديك",
    "أولديكم",
    "أولديكما",
    "أولديكن",
    "أولدينا",
    "أولديه",
    "أولديها",
    "أولديهم",
    "أولديهما",
    "أولديهن",
    "أولذاك",
    "أولذانك",
    "أولذلك",
    "أولذلكم",
    "أولذلكما",
    "أولذلكن",
    "أولذواتي",
    "أولذي",
    "أولذين",
    "أولذينك",
    "أولست",
    "أولستم",
    "أولستما",
    "أولستن",
    "أولسن",
    "أولسنا",
    "أولسوى",
    "أولسوي",
    "أولسويك",
    "أولسويكم",
    "أولسويكما",
    "أولسويكن",
    "أولسوينا",
    "أولسويه",
    "أولسويها",
    "أولسويهم",
    "أولسويهما",
    "أولسويهن",
    "أولشبه",
    "أولشبهك",
    "أولشبهكم",
    "أولشبهكما",
    "أولشبهكن",
    "أولشبهنا",
    "أولشبهه",
    "أولشبهها",
    "أولشبههم",
    "أولشبههما",
    "أولشبههن",
    "أولشبهي",
    "أولشمال",
    "أولشمالك",
    "أولشمالكم",
    "أولشمالكما",
    "أولشمالكن",
    "أولشمالنا",
    "أولشماله",
    "أولشمالها",
    "أولشمالهم",
    "أولشمالهما",
    "أولشمالهن",
    "أولشمالي",
    "أولطالما",
    "أولعامة",
    "أولعامتك",
    "أولعامتكم",
    "أولعامتكما",
    "أولعامتكن",
    "أولعامتنا",
    "أولعامته",
    "أولعامتها",
    "أولعامتهم",
    "أولعامتهما",
    "أولعامتهن",
    "أولعامتي",
    "أولعل",
    "أولعلك",
    "أولعلكم",
    "أولعلكما",
    "أولعلكن",
    "أولعلنا",
    "أولعله",
    "أولعلها",
    "أولعلهم",
    "أولعلهما",
    "أولعلهن",
    "أولعلى",
    "أولعلي",
    "أولعليك",
    "أولعليكم",
    "أولعليكما",
    "أولعليكن",
    "أولعلينا",
    "أولعليه",
    "أولعليها",
    "أولعليهم",
    "أولعليهما",
    "أولعليهن",
    "أولعن",
    "أولعنك",
    "أولعنكم",
    "أولعنكما",
    "أولعنكن",
    "أولعننا",
    "أولعنه",
    "أولعنها",
    "أولعنهم",
    "أولعنهما",
    "أولعنهن",
    "أولعني",
    "أولعين",
    "أولعينك",
    "أولعينكم",
    "أولعينكما",
    "أولعينكن",
    "أولعيننا",
    "أولعينه",
    "أولعينها",
    "أولعينهم",
    "أولعينهما",
    "أولعينهن",
    "أولعيني",
    "أولغير",
    "أولغيرك",
    "أولغيركم",
    "أولغيركما",
    "أولغيركن",
    "أولغيرنا",
    "أولغيره",
    "أولغيرها",
    "أولغيرهم",
    "أولغيرهما",
    "أولغيرهن",
    "أولغيري",
    "أولفوق",
    "أولفوقك",
    "أولفوقكم",
    "أولفوقكما",
    "أولفوقكن",
    "أولفوقنا",
    "أولفوقه",
    "أولفوقها",
    "أولفوقهم",
    "أولفوقهما",
    "أولفوقهن",
    "أولفوقي",
    "أولفي",
    "أولفيك",
    "أولفيكم",
    "أولفيكما",
    "أولفيكن",
    "أولفينا",
    "أولفيه",
    "أولفيها",
    "أولفيهم",
    "أولفيهما",
    "أولفيهن",
    "أولفيي",
    "أولقلما",
    "أولك",
    "أولكأن",
    "أولكأنك",
    "أولكأنكم",
    "أولكأنكما",
    "أولكأنكن",
    "أولكأنما",
    "أولكأننا",
    "أولكأنه",
    "أولكأنها",
    "أولكأنهم",
    "أولكأنهما",
    "أولكأنهن",
    "أولكأني",
    "أولكل",
    "أولكلتا",
    "أولكلك",
    "أولكلكم",
    "أولكلكما",
    "أولكلكن",
    "أولكلنا",
    "أولكله",
    "أولكلها",
    "أولكلهم",
    "أولكلهما",
    "أولكلهن",
    "أولكلي",
    "أولكليكما",
    "أولكليهما",
    "أولكم",
    "أولكما",
    "أولكن",
    "أولمثل",
    "أولمثلك",
    "أولمثلكم",
    "أولمثلكما",
    "أولمثلكن",
    "أولمثلنا",
    "أولمثله",
    "أولمثلها",
    "أولمثلهم",
    "أولمثلهما",
    "أولمثلهن",
    "أولمثلي",
    "أولمع",
    "أولمعك",
    "أولمعكم",
    "أولمعكما",
    "أولمعكن",
    "أولمعنا",
    "أولمعه",
    "أولمعها",
    "أولمعهم",
    "أولمعهما",
    "أولمعهن",
    "أولمعي",
    "أولمن",
    "أولمنك",
    "أولمنكم",
    "أولمنكما",
    "أولمنكن",
    "أولمننا",
    "أولمنه",
    "أولمنها",
    "أولمنهم",
    "أولمنهما",
    "أولمنهن",
    "أولمني",
    "أولنا",
    "أولنحن",
    "أولنحو",
    "أولنحوك",
    "أولنحوكم",
    "أولنحوكما",
    "أولنحوكن",
    "أولنحونا",
    "أولنحوه",
    "أولنحوها",
    "أولنحوهم",
    "أولنحوهما",
    "أولنحوهن",
    "أولنحوي",
    "أولنفس",
    "أولنفسك",
    "أولنفسكم",
    "أولنفسكما",
    "أولنفسكن",
    "أولنفسنا",
    "أولنفسه",
    "أولنفسها",
    "أولنفسهم",
    "أولنفسهما",
    "أولنفسهن",
    "أولنفسي",
    "أوله",
    "أولهؤلاء",
    "أولها",
    "أولهاته",
    "أولهاتي",
    "أولهاتين",
    "أولهذا",
    "أولهذه",
    "أولهم",
    "أولهما",
    "أولهن",
    "أولهناك",
    "أولهنالك",
    "أولهو",
    "أولهي",
    "أوليسا",
    "أوليست",
    "أوليستا",
    "أوليسوا",
    "أوليمين",
    "أوليمينك",
    "أوليمينكم",
    "أوليمينكما",
    "أوليمينكن",
    "أوليميننا",
    "أوليمينه",
    "أوليمينها",
    "أوليمينهم",
    "أوليمينهما",
    "أوليمينهن",
    "أوليميني",
    "أوماذا",
    "أومثل",
    "أومثلك",
    "أومثلكم",
    "أومثلكما",
    "أومثلكن",
    "أومثلنا",
    "أومثله",
    "أومثلها",
    "أومثلهم",
    "أومثلهما",
    "أومثلهن",
    "أومثلي",
    "أومع",
    "أومعك",
    "أومعكم",
    "أومعكما",
    "أومعكن",
    "أومعنا",
    "أومعه",
    "أومعها",
    "أومعهم",
    "أومعهما",
    "أومعهن",
    "أومعي",
    "أومما",
    "أومنك",
    "أومنكم",
    "أومنكما",
    "أومنكن",
    "أومننا",
    "أومنه",
    "أومنها",
    "أومنهم",
    "أومنهما",
    "أومنهن",
    "أومهما",
    "أونحن",
    "أونحو",
    "أونحوك",
    "أونحوكم",
    "أونحوكما",
    "أونحوكن",
    "أونحونا",
    "أونحوه",
    "أونحوها",
    "أونحوهم",
    "أونحوهما",
    "أونحوهن",
    "أونحوي",
    "أونفس",
    "أونفسك",
    "أونفسكم",
    "أونفسكما",
    "أونفسكن",
    "أونفسنا",
    "أونفسه",
    "أونفسها",
    "أونفسهم",
    "أونفسهما",
    "أونفسهن",
    "أونفسي",
    "أوهؤلاء",
    "أوهاتان",
    "أوهاته",
    "أوهاتي",
    "أوهاتين",
    "أوهاهنا",
    "أوهذا",
    "أوهذان",
    "أوهذه",
    "أوهكذا",
    "أوهم",
    "أوهما",
    "أوهنا",
    "أوهناك",
    "أوهنالك",
    "أوهو",
    "أوهي",
    "أويمين",
    "أويمينك",
    "أويمينكم",
    "أويمينكما",
    "أويمينكن",
    "أويميننا",
    "أويمينه",
    "أويمينها",
    "أويمينهم",
    "أويمينهما",
    "أويمينهن",
    "أويميني",
    "أيك",
    "أيكما",
    "أيكن",
    "أيمين",
    "أيمينك",
    "أيمينكم",
    "أيمينكما",
    "أيمينكن",
    "أيميننا",
    "أيمينه",
    "أيمينها",
    "أيمينهم",
    "أيمينهما",
    "أيمينهن",
    "أيميني",
    "أيهما",
    "أيهن",
    "أيي",
    "إذما",
    "إذن",
    "إزاء",
    "إليكن",
    "إليهما",
    "إنكما",
    "إنكن",
    "إياكما",
    "إياكن",
    "إياها",
    "إياهم",
    "إياهما",
    "إياهن",
    "إياي",
    "ابتدأ",
    "اخلولق",
    "استحال",
    "الألاء",
    "الألى",
    "البأب",
    "البأخ",
    "البحم",
    "البفو",
    "الحم",
    "الفأب",
    "الفأخ",
    "الفبأب",
    "الفبأخ",
    "الفبحم",
    "الفبفو",
    "الففو",
    "الفكأب",
    "الفكأخ",
    "الفكحم",
    "الفكفو",
    "الفلأب",
    "الفلأخ",
    "الفلحم",
    "الفلفو",
    "الفو",
    "الكأب",
    "الكأخ",
    "الكحم",
    "الكفو",
    "اللأب",
    "اللأخ",
    "اللتان",
    "اللتيا",
    "اللتين",
    "اللذان",
    "اللفو",
    "اللواتي",
    "الوأب",
    "الوأخ",
    "الوبأب",
    "الوبأخ",
    "الوبحم",
    "الوبفو",
    "الوحم",
    "الوفو",
    "الوكأب",
    "الوكأخ",
    "الوكحم",
    "الوكفو",
    "الولأب",
    "الولأخ",
    "الولحم",
    "الولفو",
    "انبرى",
    "بأب",
    "بأبك",
    "بأبكم",
    "بأبكما",
    "بأبكن",
    "بأبنا",
    "بأبه",
    "بأبها",
    "بأبهم",
    "بأبهما",
    "بأبهن",
    "بأبي",
    "بأجمع",
    "بأجمعك",
    "بأجمعكم",
    "بأجمعكما",
    "بأجمعكن",
    "بأجمعنا",
    "بأجمعه",
    "بأجمعها",
    "بأجمعهم",
    "بأجمعهما",
    "بأجمعهن",
    "بأجمعي",
    "بأخك",
    "بأخكم",
    "بأخكما",
    "بأخكن",
    "بأخنا",
    "بأخه",
    "بأخها",
    "بأخهم",
    "بأخهما",
    "بأخهن",
    "بأخي",
    "بأقل",
    "بأقلك",
    "بأقلكم",
    "بأقلكما",
    "بأقلكن",
    "بأقلنا",
    "بأقله",
    "بأقلها",
    "بأقلهم",
    "بأقلهما",
    "بأقلهن",
    "بأقلي",
    "بأكثرك",
    "بأكثركم",
    "بأكثركما",
    "بأكثركن",
    "بأكثرنا",
    "بأكثره",
    "بأكثرها",
    "بأكثرهم",
    "بأكثرهما",
    "بأكثرهن",
    "بأكثري",
    "بأنك",
    "بأنكما",
    "بأنكن",
    "بأنها",
    "بأنهما",
    "بأنهن",
    "بأني",
    "بأولئك",
    "بأولئكم",
    "بأولاء",
    "بأولالك",
    "بأيك",
    "بأيكم",
    "بأيكما",
    "بأيكن",
    "بأينا",
    "بأيه",
    "بأيها",
    "بأيهم",
    "بأيهما",
    "بأيهن",
    "بأيي",
    "بإن",
    "بإنك",
    "بإنكم",
    "بإنكما",
    "بإنكن",
    "بإننا",
    "بإنه",
    "بإنها",
    "بإنهم",
    "بإنهما",
    "بإنهن",
    "بإني",
    "بإياك",
    "بإياكم",
    "بإياكما",
    "بإياكن",
    "بإيانا",
    "بإياه",
    "بإياها",
    "بإياهم",
    "بإياهما",
    "بإياهن",
    "بإياي",
    "بالألاء",
    "بالألى",
    "باللائي",
    "باللاتي",
    "باللتيا",
    "باللتين",
    "باللذين",
    "باللواتي",
    "ببعضك",
    "ببعضكم",
    "ببعضكما",
    "ببعضكن",
    "ببعضنا",
    "ببعضه",
    "ببعضهم",
    "ببعضهما",
    "ببعضهن",
    "ببعضي",
    "بتانك",
    "بتجاه",
    "بتجاهك",
    "بتجاهكم",
    "بتجاهكما",
    "بتجاهكن",
    "بتجاهنا",
    "بتجاهه",
    "بتجاهها",
    "بتجاههم",
    "بتجاههما",
    "بتجاههن",
    "بتجاهي",
    "بتلقاء",
    "بتلقاءك",
    "بتلقاءكم",
    "بتلقاءكما",
    "بتلقاءكن",
    "بتلقاءنا",
    "بتلقاءه",
    "بتلقاءها",
    "بتلقاءهم",
    "بتلقاءهما",
    "بتلقاءهن",
    "بتلقاءي",
    "بتلك",
    "بتلكم",
    "بتلكما",
    "بته",
    "بتي",
    "بتين",
    "بتينك",
    "بجميع",
    "بجميعك",
    "بجميعكم",
    "بجميعكما",
    "بجميعكن",
    "بجميعنا",
    "بجميعه",
    "بجميعها",
    "بجميعهم",
    "بجميعهما",
    "بجميعهن",
    "بجميعي",
    "بحسب",
    "بحسبك",
    "بحسبكم",
    "بحسبكما",
    "بحسبكن",
    "بحسبنا",
    "بحسبه",
    "بحسبها",
    "بحسبهم",
    "بحسبهما",
    "بحسبهن",
    "بحسبي",
    "بحم",
    "بحمك",
    "بحمكم",
    "بحمكما",
    "بحمكن",
    "بحمنا",
    "بحمه",
    "بحمها",
    "بحمهم",
    "بحمهما",
    "بحمهن",
    "بحمي",
    "بخ",
    "بذا",
    "بذاك",
    "بذانك",
    "بذلكم",
    "بذلكما",
    "بذلكن",
    "بذه",
    "بذواتي",
    "بذي",
    "بذين",
    "بذينك",
    "بسوى",
    "بسوي",
    "بسويك",
    "بسويكم",
    "بسويكما",
    "بسويكن",
    "بسوينا",
    "بسويه",
    "بسويها",
    "بسويهم",
    "بسويهما",
    "بسويهن",
    "بشبه",
    "بشبهك",
    "بشبهكم",
    "بشبهكما",
    "بشبهكن",
    "بشبهنا",
    "بشبهه",
    "بشبهها",
    "بشبههم",
    "بشبههما",
    "بشبههن",
    "بشبهي",
    "بضعك",
    "بضعكم",
    "بضعكما",
    "بضعكن",
    "بضعنا",
    "بضعه",
    "بضعها",
    "بضعهم",
    "بضعهما",
    "بضعهن",
    "بضعي",
    "بطآن",
    "بعامة",
    "بعامتك",
    "بعامتكم",
    "بعامتكما",
    "بعامتكن",
    "بعامتنا",
    "بعامته",
    "بعامتها",
    "بعامتهم",
    "بعامتهما",
    "بعامتهن",
    "بعامتي",
    "بعدكما",
    "بعدكن",
    "بعدنا",
    "بعدهما",
    "بعضك",
    "بعضكما",
    "بعضكن",
    "بعضهما",
    "بعضهن",
    "بعضي",
    "بعين",
    "بعينك",
    "بعينكم",
    "بعينكما",
    "بعينكن",
    "بعيننا",
    "بعينه",
    "بعينها",
    "بعينهم",
    "بعينهما",
    "بعينهن",
    "بعيني",
    "بغيرك",
    "بغيركم",
    "بغيركما",
    "بغيركن",
    "بغيرنا",
    "بغيره",
    "بغيرها",
    "بغيرهم",
    "بغيرهما",
    "بغيرهن",
    "بغيري",
    "بفو",
    "بفوك",
    "بفوكم",
    "بفوكما",
    "بفوكن",
    "بفونا",
    "بفوه",
    "بفوها",
    "بفوهم",
    "بفوهما",
    "بفوهن",
    "بفوي",
    "بكلك",
    "بكلكم",
    "بكلكما",
    "بكلكن",
    "بكلنا",
    "بكله",
    "بكلها",
    "بكلهم",
    "بكلهما",
    "بكلهن",
    "بكليكما",
    "بكليهما",
    "بكما",
    "بكن",
    "بله",
    "بماذا",
    "بمثلك",
    "بمثلكم",
    "بمثلكما",
    "بمثلكن",
    "بمثلنا",
    "بمثلهم",
    "بمثلهما",
    "بمثلهن",
    "بمثلي",
    "بنحو",
    "بنحوك",
    "بنحوكم",
    "بنحوكما",
    "بنحوكن",
    "بنحونا",
    "بنحوه",
    "بنحوها",
    "بنحوهم",
    "بنحوهما",
    "بنحوهن",
    "بنحوي",
    "بنفسكم",
    "بنفسكما",
    "بنفسكن",
    "بنفسنا",
    "بنفسه",
    "بنفسها",
    "بنفسهم",
    "بنفسهما",
    "بنفسهن",
    "بهؤلاء",
    "بهاته",
    "بهاتي",
    "بهاتين",
    "بهذه",
    "بهذي",
    "بهذين",
    "بهناك",
    "بهنالك",
    "بهو",
    "بهي",
    "بينكما",
    "بينكن",
    "تانك",
    "تجاه",
    "تجاهك",
    "تجاهكم",
    "تجاهكما",
    "تجاهكن",
    "تجاهنا",
    "تجاهه",
    "تجاهها",
    "تجاههم",
    "تجاههما",
    "تجاههن",
    "تجاهي",
    "تحتكم",
    "تحتكما",
    "تحتكن",
    "تحتنا",
    "تحتهما",
    "تحتهن",
    "تلقاءك",
    "تلقاءكم",
    "تلقاءكما",
    "تلقاءكن",
    "تلقاءنا",
    "تلقاءه",
    "تلقاءها",
    "تلقاءهم",
    "تلقاءهما",
    "تلقاءهن",
    "تلقاءي",
    "تين",
    "تينك",
    "جلل",
    "جميعك",
    "جميعكم",
    "جميعكما",
    "جميعكن",
    "جميعنا",
    "جميعه",
    "جميعها",
    "جميعهم",
    "جميعهما",
    "جميعهن",
    "جميعي",
    "حاشا",
    "حاشاك",
    "حاشاكم",
    "حاشاكما",
    "حاشاكن",
    "حاشانا",
    "حاشاه",
    "حاشاها",
    "حاشاهم",
    "حاشاهما",
    "حاشاهن",
    "حاشاي",
    "حاي",
    "حبذا",
    "حذار",
    "حرى",
    "حسبكم",
    "حسبكما",
    "حسبكن",
    "حسبها",
    "حسبهما",
    "حسبهن",
    "حمك",
    "حمكم",
    "حمكما",
    "حمكن",
    "حمنا",
    "حمه",
    "حمها",
    "حمهم",
    "حمهما",
    "حمهن",
    "حوالى",
    "حواليك",
    "حواليكم",
    "حواليكما",
    "حواليكن",
    "حوالينا",
    "حواليه",
    "حواليها",
    "حواليهم",
    "حواليهما",
    "حواليهن",
    "حولكما",
    "حولكن",
    "حولنا",
    "حولهما",
    "حولهن",
    "حولي",
    "حيثما",
    "حينك",
    "حينكم",
    "حينكما",
    "حينكن",
    "حيننا",
    "حينه",
    "حينها",
    "حينهم",
    "حينهما",
    "حينهن",
    "حيني",
    "خلاك",
    "خلاكم",
    "خلاكما",
    "خلاكن",
    "خلالك",
    "خلالكما",
    "خلالكن",
    "خلالنا",
    "خلالهم",
    "خلالهن",
    "خلالي",
    "خلانا",
    "خلاه",
    "خلاها",
    "خلاهم",
    "خلاهما",
    "خلاهن",
    "خلاي",
    "خلفكما",
    "خلفكن",
    "خلفهما",
    "خلفهن",
    "ذاك",
    "ذان",
    "ذانك",
    "ذلكن",
    "ذه",
    "ذيت",
    "ذينك",
    "رويدك",
    "ريث",
    "ساءما",
    "سبحانكم",
    "سبحانكما",
    "سبحانكن",
    "سبحاننا",
    "سبحانها",
    "سبحانهم",
    "سبحانهما",
    "سبحانهن",
    "سبحاني",
    "سرعان",
    "سويك",
    "سويكم",
    "سويكما",
    "سويكن",
    "سوينا",
    "سويه",
    "سويها",
    "سويهم",
    "سويهما",
    "سويهن",
    "شبهك",
    "شبهكم",
    "شبهكما",
    "شبهكن",
    "شبهنا",
    "شبهه",
    "شبهها",
    "شبههم",
    "شبههما",
    "شبههن",
    "شبهي",
    "شتان",
    "شمالك",
    "شمالكم",
    "شمالكما",
    "شمالكن",
    "شمالنا",
    "شمالها",
    "شمالهم",
    "شمالهما",
    "شمالهن",
    "صه",
    "ضحوة",
    "ضمنك",
    "ضمنكم",
    "ضمنكما",
    "ضمنكن",
    "ضمننا",
    "ضمنه",
    "ضمنها",
    "ضمنهم",
    "ضمنهما",
    "ضمنهن",
    "طاق",
    "طالما",
    "طفق",
    "طق",
    "عامتك",
    "عامتكم",
    "عامتكما",
    "عامتكن",
    "عامتنا",
    "عامته",
    "عامتها",
    "عامتهم",
    "عامتهما",
    "عامتهن",
    "عامتي",
    "عداك",
    "عداكم",
    "عداكما",
    "عداكن",
    "عدانا",
    "عداه",
    "عداها",
    "عداهم",
    "عداهما",
    "عداهن",
    "عداي",
    "عدس",
    "عل",
    "عليكن",
    "عندكما",
    "عندكن",
    "عندهما",
    "عندهن",
    "عنكما",
    "عنكن",
    "عننا",
    "عنهن",
    "عينك",
    "عينكم",
    "عينكما",
    "عينكن",
    "عيننا",
    "عينه",
    "عينهم",
    "عينهما",
    "عينهن",
    "غداة",
    "غيرك",
    "غيركما",
    "غيركن",
    "غيرنا",
    "غيرهم",
    "غيرهما",
    "غيرهن",
    "فآض",
    "فآمين",
    "فآناء",
    "فآنفا",
    "فآه",
    "فأب",
    "فأبدا",
    "فأبك",
    "فأبكم",
    "فأبكما",
    "فأبكن",
    "فأبنا",
    "فأبه",
    "فأبها",
    "فأبهم",
    "فأبهما",
    "فأبهن",
    "فأبي",
    "فأجمع",
    "فأجمعك",
    "فأجمعكم",
    "فأجمعكما",
    "فأجمعكن",
    "فأجمعنا",
    "فأجمعه",
    "فأجمعها",
    "فأجمعهم",
    "فأجمعهما",
    "فأجمعهن",
    "فأجمعي",
    "فأخ",
    "فأخذ",
    "فأخك",
    "فأخكم",
    "فأخكما",
    "فأخكن",
    "فأخنا",
    "فأخه",
    "فأخها",
    "فأخهم",
    "فأخهما",
    "فأخهن",
    "فأخي",
    "فأصلا",
    "فأضحى",
    "فأف",
    "فأقل",
    "فأقلك",
    "فأقلكم",
    "فأقلكما",
    "فأقلكن",
    "فأقلنا",
    "فأقله",
    "فأقلها",
    "فأقلهم",
    "فأقلهما",
    "فأقلهن",
    "فأقلي",
    "فأكثر",
    "فأكثرك",
    "فأكثركم",
    "فأكثركما",
    "فأكثركن",
    "فأكثرنا",
    "فأكثره",
    "فأكثرها",
    "فأكثرهم",
    "فأكثرهما",
    "فأكثرهن",
    "فأكثري",
    "فألا",
    "فأمام",
    "فأمامك",
    "فأمامكم",
    "فأمامكما",
    "فأمامكن",
    "فأمامنا",
    "فأمامه",
    "فأمامها",
    "فأمامهم",
    "فأمامهما",
    "فأمامهن",
    "فأمامي",
    "فأمد",
    "فأمس",
    "فأمسى",
    "فأنتما",
    "فأنتن",
    "فأنشأ",
    "فأنك",
    "فأنكم",
    "فأنكما",
    "فأنكن",
    "فأننا",
    "فأنها",
    "فأنهم",
    "فأنهما",
    "فأنهن",
    "فأني",
    "فأوشك",
    "فأول",
    "فأولئكم",
    "فأولاء",
    "فأولالك",
    "فأوه",
    "فأيا",
    "فأيان",
    "فأيك",
    "فأيكم",
    "فأيكما",
    "فأيكن",
    "فأينا",
    "فأيه",
    "فأيها",
    "فأيهم",
    "فأيهما",
    "فأيهن",
    "فأيي",
    "فإذما",
    "فإذن",
    "فإزاء",
    "فإلا",
    "فإلى",
    "فإلي",
    "فإليك",
    "فإليكم",
    "فإليكما",
    "فإليكن",
    "فإليها",
    "فإليهم",
    "فإليهما",
    "فإليهن",
    "فإنكما",
    "فإنكن",
    "فإننا",
    "فإنهما",
    "فإنهن",
    "فإي",
    "فإياك",
    "فإياكم",
    "فإياكما",
    "فإياكن",
    "فإيانا",
    "فإياه",
    "فإياها",
    "فإياهم",
    "فإياهما",
    "فإياهن",
    "فإيه",
    "فابتدأ",
    "فاخلولق",
    "فاستحال",
    "فالألاء",
    "فالألى",
    "فالتي",
    "فالذي",
    "فاللائي",
    "فاللاتي",
    "فاللتان",
    "فاللتيا",
    "فاللتين",
    "فاللذان",
    "فاللذين",
    "فاللواتي",
    "فانبرى",
    "فانقلب",
    "فبأب",
    "فبأبك",
    "فبأبكم",
    "فبأبكما",
    "فبأبكن",
    "فبأبنا",
    "فبأبه",
    "فبأبها",
    "فبأبهم",
    "فبأبهما",
    "فبأبهن",
    "فبأبي",
    "فبأجمع",
    "فبأجمعك",
    "فبأجمعكم",
    "فبأجمعكما",
    "فبأجمعكن",
    "فبأجمعنا",
    "فبأجمعه",
    "فبأجمعها",
    "فبأجمعهم",
    "فبأجمعهما",
    "فبأجمعهن",
    "فبأجمعي",
    "فبأخ",
    "فبأخك",
    "فبأخكم",
    "فبأخكما",
    "فبأخكن",
    "فبأخنا",
    "فبأخه",
    "فبأخها",
    "فبأخهم",
    "فبأخهما",
    "فبأخهن",
    "فبأخي",
    "فبأقل",
    "فبأقلك",
    "فبأقلكم",
    "فبأقلكما",
    "فبأقلكن",
    "فبأقلنا",
    "فبأقله",
    "فبأقلها",
    "فبأقلهم",
    "فبأقلهما",
    "فبأقلهن",
    "فبأقلي",
    "فبأكثر",
    "فبأكثرك",
    "فبأكثركم",
    "فبأكثركما",
    "فبأكثركن",
    "فبأكثرنا",
    "فبأكثره",
    "فبأكثرها",
    "فبأكثرهم",
    "فبأكثرهما",
    "فبأكثرهن",
    "فبأكثري",
    "فبأن",
    "فبأنك",
    "فبأنكم",
    "فبأنكما",
    "فبأنكن",
    "فبأننا",
    "فبأنه",
    "فبأنها",
    "فبأنهم",
    "فبأنهما",
    "فبأنهن",
    "فبأني",
    "فبأولئك",
    "فبأولئكم",
    "فبأولاء",
    "فبأولالك",
    "فبأيك",
    "فبأيكم",
    "فبأيكما",
    "فبأيكن",
    "فبأينا",
    "فبأيه",
    "فبأيها",
    "فبأيهم",
    "فبأيهما",
    "فبأيهن",
    "فبأيي",
    "فبإن",
    "فبإنك",
    "فبإنكم",
    "فبإنكما",
    "فبإنكن",
    "فبإننا",
    "فبإنه",
    "فبإنها",
    "فبإنهم",
    "فبإنهما",
    "فبإنهن",
    "فبإني",
    "فبإياك",
    "فبإياكم",
    "فبإياكما",
    "فبإياكن",
    "فبإيانا",
    "فبإياه",
    "فبإياها",
    "فبإياهم",
    "فبإياهما",
    "فبإياهن",
    "فبإياي",
    "فبات",
    "فبالألاء",
    "فبالألى",
    "فبالتي",
    "فبالذي",
    "فبالذين",
    "فباللائي",
    "فباللاتي",
    "فباللتيا",
    "فباللتين",
    "فباللذين",
    "فباللواتي",
    "فببعض",
    "فببعضك",
    "فببعضكم",
    "فببعضكما",
    "فببعضكن",
    "فببعضنا",
    "فببعضه",
    "فببعضها",
    "فببعضهم",
    "فببعضهما",
    "فببعضهن",
    "فببعضي",
    "فبتانك",
    "فبتجاه",
    "فبتجاهك",
    "فبتجاهكم",
    "فبتجاهكما",
    "فبتجاهكن",
    "فبتجاهنا",
    "فبتجاهه",
    "فبتجاهها",
    "فبتجاههم",
    "فبتجاههما",
    "فبتجاههن",
    "فبتجاهي",
    "فبتلقاء",
    "فبتلقاءك",
    "فبتلقاءكم",
    "فبتلقاءكما",
    "فبتلقاءكن",
    "فبتلقاءنا",
    "فبتلقاءه",
    "فبتلقاءها",
    "فبتلقاءهم",
    "فبتلقاءهما",
    "فبتلقاءهن",
    "فبتلقاءي",
    "فبتلك",
    "فبتلكم",
    "فبتلكما",
    "فبته",
    "فبتي",
    "فبتين",
    "فبتينك",
    "فبجميع",
    "فبجميعك",
    "فبجميعكم",
    "فبجميعكما",
    "فبجميعكن",
    "فبجميعنا",
    "فبجميعه",
    "فبجميعها",
    "فبجميعهم",
    "فبجميعهما",
    "فبجميعهن",
    "فبجميعي",
    "فبحسب",
    "فبحسبك",
    "فبحسبكم",
    "فبحسبكما",
    "فبحسبكن",
    "فبحسبنا",
    "فبحسبه",
    "فبحسبها",
    "فبحسبهم",
    "فبحسبهما",
    "فبحسبهن",
    "فبحسبي",
    "فبحم",
    "فبحمك",
    "فبحمكم",
    "فبحمكما",
    "فبحمكن",
    "فبحمنا",
    "فبحمه",
    "فبحمها",
    "فبحمهم",
    "فبحمهما",
    "فبحمهن",
    "فبحمي",
    "فبحيث",
    "فبخ",
    "فبذا",
    "فبذات",
    "فبذاك",
    "فبذانك",
    "فبذلكم",
    "فبذلكما",
    "فبذلكن",
    "فبذه",
    "فبذواتي",
    "فبذي",
    "فبذين",
    "فبذينك",
    "فبس",
    "فبسوى",
    "فبسوي",
    "فبسويك",
    "فبسويكم",
    "فبسويكما",
    "فبسويكن",
    "فبسوينا",
    "فبسويه",
    "فبسويها",
    "فبسويهم",
    "فبسويهما",
    "فبسويهن",
    "فبشبه",
    "فبشبهك",
    "فبشبهكم",
    "فبشبهكما",
    "فبشبهكن",
    "فبشبهنا",
    "فبشبهه",
    "فبشبهها",
    "فبشبههم",
    "فبشبههما",
    "فبشبههن",
    "فبشبهي",
    "فبضع",
    "فبضعك",
    "فبضعكم",
    "فبضعكما",
    "فبضعكن",
    "فبضعنا",
    "فبضعه",
    "فبضعها",
    "فبضعهم",
    "فبضعهما",
    "فبضعهن",
    "فبضعي",
    "فبطآن",
    "فبعامة",
    "فبعامتك",
    "فبعامتكم",
    "فبعامتكما",
    "فبعامتكن",
    "فبعامتنا",
    "فبعامته",
    "فبعامتها",
    "فبعامتهم",
    "فبعامتهما",
    "فبعامتهن",
    "فبعامتي",
    "فبعد",
    "فبعدك",
    "فبعدكم",
    "فبعدكما",
    "فبعدكن",
    "فبعدنا",
    "فبعده",
    "فبعدها",
    "فبعدهم",
    "فبعدهما",
    "فبعدهن",
    "فبعدي",
    "فبعض",
    "فبعضك",
    "فبعضكم",
    "فبعضكما",
    "فبعضكن",
    "فبعضنا",
    "فبعضه",
    "فبعضها",
    "فبعضهم",
    "فبعضهما",
    "فبعضهن",
    "فبعضي",
    "فبعين",
    "فبعينك",
    "فبعينكم",
    "فبعينكما",
    "فبعينكن",
    "فبعيننا",
    "فبعينه",
    "فبعينها",
    "فبعينهم",
    "فبعينهما",
    "فبعينهن",
    "فبعيني",
    "فبغير",
    "فبغيرك",
    "فبغيركم",
    "فبغيركما",
    "فبغيركن",
    "فبغيرنا",
    "فبغيره",
    "فبغيرها",
    "فبغيرهم",
    "فبغيرهما",
    "فبغيرهن",
    "فبغيري",
    "فبفو",
    "فبفوك",
    "فبفوكم",
    "فبفوكما",
    "فبفوكن",
    "فبفونا",
    "فبفوه",
    "فبفوها",
    "فبفوهم",
    "فبفوهما",
    "فبفوهن",
    "فبفوي",
    "فبك",
    "فبكل",
    "فبكلتا",
    "فبكلك",
    "فبكلكم",
    "فبكلكما",
    "فبكلكن",
    "فبكلنا",
    "فبكله",
    "فبكلها",
    "فبكلهم",
    "فبكلهما",
    "فبكلهن",
    "فبكلي",
    "فبكليكما",
    "فبكليهما",
    "فبكم",
    "فبكما",
    "فبكن",
    "فبله",
    "فبماذا",
    "فبمثل",
    "فبمثلك",
    "فبمثلكم",
    "فبمثلكما",
    "فبمثلكن",
    "فبمثلنا",
    "فبمثله",
    "فبمثلها",
    "فبمثلهم",
    "فبمثلهما",
    "فبمثلهن",
    "فبمثلي",
    "فبمن",
    "فبنا",
    "فبنحو",
    "فبنحوك",
    "فبنحوكم",
    "فبنحوكما",
    "فبنحوكن",
    "فبنحونا",
    "فبنحوه",
    "فبنحوها",
    "فبنحوهم",
    "فبنحوهما",
    "فبنحوهن",
    "فبنحوي",
    "فبنفس",
    "فبنفسك",
    "فبنفسكم",
    "فبنفسكما",
    "فبنفسكن",
    "فبنفسنا",
    "فبنفسه",
    "فبنفسها",
    "فبنفسهم",
    "فبنفسهما",
    "فبنفسهن",
    "فبنفسي",
    "فبه",
    "فبهؤلاء",
    "فبها",
    "فبهاته",
    "فبهاتي",
    "فبهاتين",
    "فبهذا",
    "فبهذه",
    "فبهذي",
    "فبهذين",
    "فبهم",
    "فبهما",
    "فبهن",
    "فبهناك",
    "فبهنالك",
    "فبهو",
    "فبهي",
    "فبي",
    "فبيد",
    "فبين",
    "فبينك",
    "فبينكم",
    "فبينكما",
    "فبينكن",
    "فبيننا",
    "فبينه",
    "فبينها",
    "فبينهم",
    "فبينهما",
    "فبينهن",
    "فبيني",
    "فتارة",
    "فتانك",
    "فتبدل",
    "فتجاه",
    "فتجاهك",
    "فتجاهكم",
    "فتجاهكما",
    "فتجاهكن",
    "فتجاهنا",
    "فتجاهه",
    "فتجاهها",
    "فتجاههم",
    "فتجاههما",
    "فتجاههن",
    "فتجاهي",
    "فتحتك",
    "فتحتكم",
    "فتحتكما",
    "فتحتكن",
    "فتحتنا",
    "فتحته",
    "فتحتها",
    "فتحتهم",
    "فتحتهما",
    "فتحتهن",
    "فتحتي",
    "فتحول",
    "فتلقاء",
    "فتلقاءك",
    "فتلقاءكم",
    "فتلقاءكما",
    "فتلقاءكن",
    "فتلقاءنا",
    "فتلقاءه",
    "فتلقاءها",
    "فتلقاءهم",
    "فتلقاءهما",
    "فتلقاءهن",
    "فتلقاءي",
    "فتلكم",
    "فتلكما",
    "فته",
    "فتي",
    "فتين",
    "فتينك",
    "فثمة",
    "فجميع",
    "فجميعك",
    "فجميعكم",
    "فجميعكما",
    "فجميعكن",
    "فجميعنا",
    "فجميعه",
    "فجميعها",
    "فجميعهم",
    "فجميعهما",
    "فجميعهن",
    "فجميعي",
    "فجير",
    "فحار",
    "فحاشا",
    "فحاشاك",
    "فحاشاكم",
    "فحاشاكما",
    "فحاشاكن",
    "فحاشانا",
    "فحاشاه",
    "فحاشاها",
    "فحاشاهم",
    "فحاشاهما",
    "فحاشاهن",
    "فحاشاي",
    "فحبذا",
    "فحتى",
    "فحذار",
    "فحرى",
    "فحسب",
    "فحسبك",
    "فحسبكم",
    "فحسبكما",
    "فحسبكن",
    "فحسبنا",
    "فحسبها",
    "فحسبهم",
    "فحسبهما",
    "فحسبهن",
    "فحسبي",
    "فحمك",
    "فحمكم",
    "فحمكما",
    "فحمكن",
    "فحمنا",
    "فحمه",
    "فحمها",
    "فحمهم",
    "فحمهما",
    "فحمهن",
    "فحوالى",
    "فحوالي",
    "فحواليك",
    "فحواليكم",
    "فحواليكما",
    "فحواليكن",
    "فحوالينا",
    "فحواليه",
    "فحواليها",
    "فحواليهم",
    "فحواليهما",
    "فحواليهن",
    "فحول",
    "فحولك",
    "فحولكم",
    "فحولكما",
    "فحولكن",
    "فحولنا",
    "فحوله",
    "فحولها",
    "فحولهم",
    "فحولهما",
    "فحولهن",
    "فحولي",
    "فحي",
    "فحيث",
    "فحيثما",
    "فحين",
    "فحينك",
    "فحينكم",
    "فحينكما",
    "فحينكن",
    "فحيننا",
    "فحينه",
    "فحينها",
    "فحينهم",
    "فحينهما",
    "فحينهن",
    "فحيني",
    "فخلا",
    "فخلاك",
    "فخلاكم",
    "فخلاكما",
    "فخلاكن",
    "فخلال",
    "فخلالك",
    "فخلالكم",
    "فخلالكما",
    "فخلالكن",
    "فخلالنا",
    "فخلاله",
    "فخلالها",
    "فخلالهم",
    "فخلالهما",
    "فخلالهن",
    "فخلالي",
    "فخلانا",
    "فخلاه",
    "فخلاها",
    "فخلاهم",
    "فخلاهما",
    "فخلاهن",
    "فخلاي",
    "فخلفك",
    "فخلفكم",
    "فخلفكما",
    "فخلفكن",
    "فخلفنا",
    "فخلفه",
    "فخلفها",
    "فخلفهم",
    "فخلفهما",
    "فخلفهن",
    "فخلفي",
    "فدون",
    "فدونك",
    "فذا",
    "فذات",
    "فذاك",
    "فذان",
    "فذلكما",
    "فذه",
    "فذوا",
    "فذواتا",
    "فذواتي",
    "فذي",
    "فذيت",
    "فذين",
    "فذينك",
    "فراح",
    "فرب",
    "فرويدك",
    "فريث",
    "فساءما",
    "فسبحانك",
    "فسبحانكم",
    "فسبحانكما",
    "فسبحانكن",
    "فسبحاننا",
    "فسبحانه",
    "فسبحانها",
    "فسبحانهم",
    "فسبحانهما",
    "فسبحانهن",
    "فسبحاني",
    "فسرعان",
    "فسوي",
    "فسويك",
    "فسويكم",
    "فسويكما",
    "فسويكن",
    "فسوينا",
    "فسويه",
    "فسويها",
    "فسويهم",
    "فسويهما",
    "فسويهن",
    "فشبه",
    "فشبهك",
    "فشبهكم",
    "فشبهكما",
    "فشبهكن",
    "فشبهنا",
    "فشبهه",
    "فشبهها",
    "فشبههم",
    "فشبههما",
    "فشبههن",
    "فشبهي",
    "فشتان",
    "فشرع",
    "فشمال",
    "فشمالك",
    "فشمالكم",
    "فشمالكما",
    "فشمالكن",
    "فشمالنا",
    "فشماله",
    "فشمالها",
    "فشمالهم",
    "فشمالهما",
    "فشمالهن",
    "فشمالي",
    "فصار",
    "فصباح",
    "فضحوة",
    "فضمن",
    "فضمنك",
    "فضمنكم",
    "فضمنكما",
    "فضمنكن",
    "فضمننا",
    "فضمنه",
    "فضمنها",
    "فضمنهم",
    "فضمنهما",
    "فضمنهن",
    "فضمني",
    "فطالما",
    "فظل",
    "فعاد",
    "فعامة",
    "فعامتك",
    "فعامتكم",
    "فعامتكما",
    "فعامتكن",
    "فعامتنا",
    "فعامته",
    "فعامتها",
    "فعامتهم",
    "فعامتهما",
    "فعامتهن",
    "فعامتي",
    "فعدا",
    "فعداك",
    "فعداكم",
    "فعداكما",
    "فعداكن",
    "فعدانا",
    "فعداه",
    "فعداها",
    "فعداهم",
    "فعداهما",
    "فعداهن",
    "فعداي",
    "فعلق",
    "فعليك",
    "فعليكما",
    "فعليكن",
    "فعلينا",
    "فعليهما",
    "فعما",
    "فعن",
    "فعندك",
    "فعندكم",
    "فعندكما",
    "فعندكن",
    "فعندنا",
    "فعنده",
    "فعندها",
    "فعندهم",
    "فعندهما",
    "فعندهن",
    "فعندي",
    "فعنك",
    "فعنكم",
    "فعنكما",
    "فعنكن",
    "فعننا",
    "فعنه",
    "فعنها",
    "فعنهم",
    "فعنهما",
    "فعنهن",
    "فعني",
    "فعوض",
    "فعين",
    "فعينك",
    "فعينكم",
    "فعينكما",
    "فعينكن",
    "فعيننا",
    "فعينه",
    "فعينها",
    "فعينهم",
    "فعينهما",
    "فعينهن",
    "فعيني",
    "فغدا",
    "فغداة",
    "فغير",
    "فغيرك",
    "فغيركم",
    "فغيركما",
    "فغيركن",
    "فغيرنا",
    "فغيره",
    "فغيرها",
    "فغيرهم",
    "فغيرهما",
    "فغيرهن",
    "فغيري",
    "ففلان",
    "ففو",
    "ففوق",
    "ففوقك",
    "ففوقكم",
    "ففوقكما",
    "ففوقكن",
    "ففوقنا",
    "ففوقه",
    "ففوقها",
    "ففوقهم",
    "ففوقهما",
    "ففوقهن",
    "ففوقي",
    "ففوك",
    "ففوكم",
    "ففوكما",
    "ففوكن",
    "ففونا",
    "ففوه",
    "ففوها",
    "ففوهم",
    "ففوهما",
    "ففوهن",
    "ففوي",
    "ففيك",
    "ففيكم",
    "ففيكما",
    "ففيكن",
    "ففيم",
    "ففيما",
    "ففينا",
    "ففيه",
    "ففيها",
    "ففيهم",
    "ففيهما",
    "ففيهن",
    "ففيي",
    "فقام",
    "فقبل",
    "فقلما",
    "فكأب",
    "فكأبك",
    "فكأبكم",
    "فكأبكما",
    "فكأبكن",
    "فكأبنا",
    "فكأبه",
    "فكأبها",
    "فكأبهم",
    "فكأبهما",
    "فكأبهن",
    "فكأبي",
    "فكأجمع",
    "فكأجمعك",
    "فكأجمعكم",
    "فكأجمعكما",
    "فكأجمعكن",
    "فكأجمعنا",
    "فكأجمعه",
    "فكأجمعها",
    "فكأجمعهم",
    "فكأجمعهما",
    "فكأجمعهن",
    "فكأجمعي",
    "فكأخ",
    "فكأخك",
    "فكأخكم",
    "فكأخكما",
    "فكأخكن",
    "فكأخنا",
    "فكأخه",
    "فكأخها",
    "فكأخهم",
    "فكأخهما",
    "فكأخهن",
    "فكأخي",
    "فكأقل",
    "فكأقلك",
    "فكأقلكم",
    "فكأقلكما",
    "فكأقلكن",
    "فكأقلنا",
    "فكأقله",
    "فكأقلها",
    "فكأقلهم",
    "فكأقلهما",
    "فكأقلهن",
    "فكأقلي",
    "فكأكثر",
    "فكأكثرك",
    "فكأكثركم",
    "فكأكثركما",
    "فكأكثركن",
    "فكأكثرنا",
    "فكأكثره",
    "فكأكثرها",
    "فكأكثرهم",
    "فكأكثرهما",
    "فكأكثرهن",
    "فكأكثري",
    "فكأن",
    "فكأنك",
    "فكأنكم",
    "فكأنكما",
    "فكأنكن",
    "فكأننا",
    "فكأنه",
    "فكأنها",
    "فكأنهم",
    "فكأنهما",
    "فكأنهن",
    "فكأني",
    "فكأولئك",
    "فكأولئكم",
    "فكأولاء",
    "فكأولالك",
    "فكأي",
    "فكأيك",
    "فكأيكم",
    "فكأيكما",
    "فكأيكن",
    "فكأينا",
    "فكأيه",
    "فكأيها",
    "فكأيهم",
    "فكأيهما",
    "فكأيهن",
    "فكأيي",
    "فكإن",
    "فكإنك",
    "فكإنكم",
    "فكإنكما",
    "فكإنكن",
    "فكإننا",
    "فكإنه",
    "فكإنها",
    "فكإنهم",
    "فكإنهما",
    "فكإنهن",
    "فكإني",
    "فكإياك",
    "فكإياكم",
    "فكإياكما",
    "فكإياكن",
    "فكإيانا",
    "فكإياه",
    "فكإياها",
    "فكإياهم",
    "فكإياهما",
    "فكإياهن",
    "فكإياي",
    "فكاد",
    "فكالألاء",
    "فكالألى",
    "فكالتي",
    "فكالذي",
    "فكالذين",
    "فكاللائي",
    "فكاللاتي",
    "فكاللتيا",
    "فكاللتين",
    "فكاللذين",
    "فكاللواتي",
    "فكبعض",
    "فكبعضك",
    "فكبعضكم",
    "فكبعضكما",
    "فكبعضكن",
    "فكبعضنا",
    "فكبعضه",
    "فكبعضها",
    "فكبعضهم",
    "فكبعضهما",
    "فكبعضهن",
    "فكبعضي",
    "فكتانك",
    "فكتجاه",
    "فكتجاهك",
    "فكتجاهكم",
    "فكتجاهكما",
    "فكتجاهكن",
    "فكتجاهنا",
    "فكتجاهه",
    "فكتجاهها",
    "فكتجاههم",
    "فكتجاههما",
    "فكتجاههن",
    "فكتجاهي",
    "فكتلقاء",
    "فكتلقاءك",
    "فكتلقاءكم",
    "فكتلقاءكما",
    "فكتلقاءكن",
    "فكتلقاءنا",
    "فكتلقاءه",
    "فكتلقاءها",
    "فكتلقاءهم",
    "فكتلقاءهما",
    "فكتلقاءهن",
    "فكتلقاءي",
    "فكتلك",
    "فكتلكم",
    "فكتلكما",
    "فكته",
    "فكتي",
    "فكتين",
    "فكتينك",
    "فكجميع",
    "فكجميعك",
    "فكجميعكم",
    "فكجميعكما",
    "فكجميعكن",
    "فكجميعنا",
    "فكجميعه",
    "فكجميعها",
    "فكجميعهم",
    "فكجميعهما",
    "فكجميعهن",
    "فكجميعي",
    "فكحسب",
    "فكحسبك",
    "فكحسبكم",
    "فكحسبكما",
    "فكحسبكن",
    "فكحسبنا",
    "فكحسبه",
    "فكحسبها",
    "فكحسبهم",
    "فكحسبهما",
    "فكحسبهن",
    "فكحسبي",
    "فكحم",
    "فكحمك",
    "فكحمكم",
    "فكحمكما",
    "فكحمكن",
    "فكحمنا",
    "فكحمه",
    "فكحمها",
    "فكحمهم",
    "فكحمهما",
    "فكحمهن",
    "فكحمي",
    "فكحيث",
    "فكذا",
    "فكذات",
    "فكذاك",
    "فكذانك",
    "فكذلكم",
    "فكذلكما",
    "فكذلكن",
    "فكذه",
    "فكذواتي",
    "فكذي",
    "فكذين",
    "فكذينك",
    "فكرب",
    "فكسوى",
    "فكسوي",
    "فكسويك",
    "فكسويكم",
    "فكسويكما",
    "فكسويكن",
    "فكسوينا",
    "فكسويه",
    "فكسويها",
    "فكسويهم",
    "فكسويهما",
    "فكسويهن",
    "فكشبه",
    "فكشبهك",
    "فكشبهكم",
    "فكشبهكما",
    "فكشبهكن",
    "فكشبهنا",
    "فكشبهه",
    "فكشبهها",
    "فكشبههم",
    "فكشبههما",
    "فكشبههن",
    "فكشبهي",
    "فكعامة",
    "فكعامتك",
    "فكعامتكم",
    "فكعامتكما",
    "فكعامتكن",
    "فكعامتنا",
    "فكعامته",
    "فكعامتها",
    "فكعامتهم",
    "فكعامتهما",
    "فكعامتهن",
    "فكعامتي",
    "فكعين",
    "فكعينك",
    "فكعينكم",
    "فكعينكما",
    "فكعينكن",
    "فكعيننا",
    "فكعينه",
    "فكعينها",
    "فكعينهم",
    "فكعينهما",
    "فكعينهن",
    "فكعيني",
    "فكغير",
    "فكغيرك",
    "فكغيركم",
    "فكغيركما",
    "فكغيركن",
    "فكغيرنا",
    "فكغيره",
    "فكغيرها",
    "فكغيرهم",
    "فكغيرهما",
    "فكغيرهن",
    "فكغيري",
    "فكفو",
    "فكفوك",
    "فكفوكم",
    "فكفوكما",
    "فكفوكن",
    "فكفونا",
    "فكفوه",
    "فكفوها",
    "فكفوهم",
    "فكفوهما",
    "فكفوهن",
    "فكفوي",
    "فككل",
    "فككلتا",
    "فككلك",
    "فككلكم",
    "فككلكما",
    "فككلكن",
    "فككلنا",
    "فككله",
    "فككلها",
    "فككلهم",
    "فككلهما",
    "فككلهن",
    "فككلي",
    "فككليكما",
    "فككليهما",
    "فكل",
    "فكلاهما",
    "فكلتا",
    "فكلك",
    "فكلكم",
    "فكلكما",
    "فكلكن",
    "فكلما",
    "فكلنا",
    "فكله",
    "فكلها",
    "فكلهم",
    "فكلهما",
    "فكلهن",
    "فكليكما",
    "فكليهما",
    "فكم",
    "فكما",
    "فكمثل",
    "فكمثلك",
    "فكمثلكم",
    "فكمثلكما",
    "فكمثلكن",
    "فكمثلنا",
    "فكمثله",
    "فكمثلها",
    "فكمثلهم",
    "فكمثلهما",
    "فكمثلهن",
    "فكمثلي",
    "فكمن",
    "فكنحو",
    "فكنحوك",
    "فكنحوكم",
    "فكنحوكما",
    "فكنحوكن",
    "فكنحونا",
    "فكنحوه",
    "فكنحوها",
    "فكنحوهم",
    "فكنحوهما",
    "فكنحوهن",
    "فكنحوي",
    "فكنفس",
    "فكنفسك",
    "فكنفسكم",
    "فكنفسكما",
    "فكنفسكن",
    "فكنفسنا",
    "فكنفسه",
    "فكنفسها",
    "فكنفسهم",
    "فكنفسهما",
    "فكنفسهن",
    "فكنفسي",
    "فكهؤلاء",
    "فكهاته",
    "فكهاتي",
    "فكهاتين",
    "فكهذا",
    "فكهذه",
    "فكهذي",
    "فكهذين",
    "فكهم",
    "فكهما",
    "فكهن",
    "فكهناك",
    "فكهنالك",
    "فكهو",
    "فكهي",
    "فكي",
    "فكيت",
    "فكيفما",
    "فلآض",
    "فلأب",
    "فلأبك",
    "فلأبكم",
    "فلأبكما",
    "فلأبكن",
    "فلأبنا",
    "فلأبه",
    "فلأبها",
    "فلأبهم",
    "فلأبهما",
    "فلأبهن",
    "فلأبي",
    "فلأجمع",
    "فلأجمعك",
    "فلأجمعكم",
    "فلأجمعكما",
    "فلأجمعكن",
    "فلأجمعنا",
    "فلأجمعه",
    "فلأجمعها",
    "فلأجمعهم",
    "فلأجمعهما",
    "فلأجمعهن",
    "فلأجمعي",
    "فلأخ",
    "فلأخذ",
    "فلأخك",
    "فلأخكم",
    "فلأخكما",
    "فلأخكن",
    "فلأخنا",
    "فلأخه",
    "فلأخها",
    "فلأخهم",
    "فلأخهما",
    "فلأخهن",
    "فلأخي",
    "فلأصبح",
    "فلأضحى",
    "فلأقبل",
    "فلأقل",
    "فلأقلك",
    "فلأقلكم",
    "فلأقلكما",
    "فلأقلكن",
    "فلأقلنا",
    "فلأقله",
    "فلأقلها",
    "فلأقلهم",
    "فلأقلهما",
    "فلأقلهن",
    "فلأقلي",
    "فلأكثر",
    "فلأكثرك",
    "فلأكثركم",
    "فلأكثركما",
    "فلأكثركن",
    "فلأكثرنا",
    "فلأكثره",
    "فلأكثرها",
    "فلأكثرهم",
    "فلأكثرهما",
    "فلأكثرهن",
    "فلأكثري",
    "فلأمام",
    "فلأمامك",
    "فلأمامكم",
    "فلأمامكما",
    "فلأمامكن",
    "فلأمامنا",
    "فلأمامه",
    "فلأمامها",
    "فلأمامهم",
    "فلأمامهما",
    "فلأمامهن",
    "فلأمامي",
    "فلأمسى",
    "فلأن",
    "فلأنا",
    "فلأنت",
    "فلأنتم",
    "فلأنتما",
    "فلأنتن",
    "فلأنشأ",
    "فلأنك",
    "فلأنكم",
    "فلأنكما",
    "فلأنكن",
    "فلأننا",
    "فلأنه",
    "فلأنها",
    "فلأنهم",
    "فلأنهما",
    "فلأنهن",
    "فلأني",
    "فلأوشك",
    "فلأولئك",
    "فلأولئكم",
    "فلأولاء",
    "فلأولالك",
    "فلأي",
    "فلأيك",
    "فلأيكم",
    "فلأيكما",
    "فلأيكن",
    "فلأينا",
    "فلأيه",
    "فلأيها",
    "فلأيهم",
    "فلأيهما",
    "فلأيهن",
    "فلأيي",
    "فلإلى",
    "فلإلي",
    "فلإليك",
    "فلإليكم",
    "فلإليكما",
    "فلإليكن",
    "فلإلينا",
    "فلإليه",
    "فلإليها",
    "فلإليهم",
    "فلإليهما",
    "فلإليهن",
    "فلإن",
    "فلإنك",
    "فلإنكم",
    "فلإنكما",
    "فلإنكن",
    "فلإننا",
    "فلإنه",
    "فلإنها",
    "فلإنهم",
    "فلإنهما",
    "فلإنهن",
    "فلإني",
    "فلإياك",
    "فلإياكم",
    "فلإياكما",
    "فلإياكن",
    "فلإيانا",
    "فلإياه",
    "فلإياها",
    "فلإياهم",
    "فلإياهما",
    "فلإياهن",
    "فلإياي",
    "فلئن",
    "فلابتدأ",
    "فلات",
    "فلاخلولق",
    "فلارتد",
    "فلاستحال",
    "فلاسيما",
    "فلان",
    "فلانبرى",
    "فلانقلب",
    "فلبات",
    "فلبعض",
    "فلبعضك",
    "فلبعضكم",
    "فلبعضكما",
    "فلبعضكن",
    "فلبعضنا",
    "فلبعضه",
    "فلبعضها",
    "فلبعضهم",
    "فلبعضهما",
    "فلبعضهن",
    "فلبعضي",
    "فلتانك",
    "فلتبدل",
    "فلتجاه",
    "فلتجاهك",
    "فلتجاهكم",
    "فلتجاهكما",
    "فلتجاهكن",
    "فلتجاهنا",
    "فلتجاهه",
    "فلتجاهها",
    "فلتجاههم",
    "فلتجاههما",
    "فلتجاههن",
    "فلتجاهي",
    "فلتحت",
    "فلتحتك",
    "فلتحتكم",
    "فلتحتكما",
    "فلتحتكن",
    "فلتحتنا",
    "فلتحته",
    "فلتحتها",
    "فلتحتهم",
    "فلتحتهما",
    "فلتحتهن",
    "فلتحتي",
    "فلتحول",
    "فلتلقاء",
    "فلتلقاءك",
    "فلتلقاءكم",
    "فلتلقاءكما",
    "فلتلقاءكن",
    "فلتلقاءنا",
    "فلتلقاءه",
    "فلتلقاءها",
    "فلتلقاءهم",
    "فلتلقاءهما",
    "فلتلقاءهن",
    "فلتلقاءي",
    "فلتلك",
    "فلتلكم",
    "فلتلكما",
    "فلته",
    "فلتي",
    "فلتين",
    "فلتينك",
    "فلجعل",
    "فلجميع",
    "فلجميعك",
    "فلجميعكم",
    "فلجميعكما",
    "فلجميعكن",
    "فلجميعنا",
    "فلجميعه",
    "فلجميعها",
    "فلجميعهم",
    "فلجميعهما",
    "فلجميعهن",
    "فلجميعي",
    "فلحار",
    "فلحرى",
    "فلحسب",
    "فلحسبك",
    "فلحسبكم",
    "فلحسبكما",
    "فلحسبكن",
    "فلحسبنا",
    "فلحسبه",
    "فلحسبها",
    "فلحسبهم",
    "فلحسبهما",
    "فلحسبهن",
    "فلحسبي",
    "فلحم",
    "فلحمك",
    "فلحمكم",
    "فلحمكما",
    "فلحمكن",
    "فلحمنا",
    "فلحمه",
    "فلحمها",
    "فلحمهم",
    "فلحمهما",
    "فلحمهن",
    "فلحمي",
    "فلحيث",
    "فلخلف",
    "فلخلفك",
    "فلخلفكم",
    "فلخلفكما",
    "فلخلفكن",
    "فلخلفنا",
    "فلخلفه",
    "فلخلفها",
    "فلخلفهم",
    "فلخلفهما",
    "فلخلفهن",
    "فلخلفي",
    "فلدن",
    "فلدنك",
    "فلدنكم",
    "فلدنكما",
    "فلدنكن",
    "فلدننا",
    "فلدنه",
    "فلدنها",
    "فلدنهم",
    "فلدنهما",
    "فلدنهن",
    "فلدني",
    "فلدى",
    "فلدي",
    "فلديك",
    "فلديكم",
    "فلديكما",
    "فلديكن",
    "فلدينا",
    "فلديه",
    "فلديها",
    "فلديهم",
    "فلديهما",
    "فلديهن",
    "فلذا",
    "فلذات",
    "فلذاك",
    "فلذانك",
    "فلذلكم",
    "فلذلكما",
    "فلذلكن",
    "فلذه",
    "فلذواتي",
    "فلذي",
    "فلذين",
    "فلذينك",
    "فلراح",
    "فلرجع",
    "فلست",
    "فلستم",
    "فلستما",
    "فلستن",
    "فلسن",
    "فلسنا",
    "فلسوى",
    "فلسوي",
    "فلسويك",
    "فلسويكم",
    "فلسويكما",
    "فلسويكن",
    "فلسوينا",
    "فلسويه",
    "فلسويها",
    "فلسويهم",
    "فلسويهما",
    "فلسويهن",
    "فلشبه",
    "فلشبهك",
    "فلشبهكم",
    "فلشبهكما",
    "فلشبهكن",
    "فلشبهنا",
    "فلشبهه",
    "فلشبهها",
    "فلشبههم",
    "فلشبههما",
    "فلشبههن",
    "فلشبهي",
    "فلشرع",
    "فلشمال",
    "فلشمالك",
    "فلشمالكم",
    "فلشمالكما",
    "فلشمالكن",
    "فلشمالنا",
    "فلشماله",
    "فلشمالها",
    "فلشمالهم",
    "فلشمالهما",
    "فلشمالهن",
    "فلشمالي",
    "فلصار",
    "فلطالما",
    "فلطفق",
    "فلظل",
    "فلعاد",
    "فلعامة",
    "فلعامتك",
    "فلعامتكم",
    "فلعامتكما",
    "فلعامتكن",
    "فلعامتنا",
    "فلعامته",
    "فلعامتها",
    "فلعامتهم",
    "فلعامتهما",
    "فلعامتهن",
    "فلعامتي",
    "فلعسى",
    "فلعل",
    "فلعلق",
    "فلعلكم",
    "فلعلكما",
    "فلعلكن",
    "فلعلنا",
    "فلعله",
    "فلعلها",
    "فلعلهم",
    "فلعلهما",
    "فلعلهن",
    "فلعلى",
    "فلعلي",
    "فلعليك",
    "فلعليكم",
    "فلعليكما",
    "فلعليكن",
    "فلعلينا",
    "فلعليه",
    "فلعليها",
    "فلعليهم",
    "فلعليهما",
    "فلعليهن",
    "فلعمر",
    "فلعمرك",
    "فلعمركم",
    "فلعمركما",
    "فلعمركن",
    "فلعمرنا",
    "فلعمره",
    "فلعمرها",
    "فلعمرهم",
    "فلعمرهما",
    "فلعمرهن",
    "فلعمري",
    "فلعن",
    "فلعنك",
    "فلعنكم",
    "فلعنكما",
    "فلعنكن",
    "فلعننا",
    "فلعنه",
    "فلعنها",
    "فلعنهم",
    "فلعنهما",
    "فلعنهن",
    "فلعني",
    "فلعين",
    "فلعينك",
    "فلعينكم",
    "فلعينكما",
    "فلعينكن",
    "فلعيننا",
    "فلعينه",
    "فلعينها",
    "فلعينهم",
    "فلعينهما",
    "فلعينهن",
    "فلعيني",
    "فلغدا",
    "فلغير",
    "فلغيرك",
    "فلغيركم",
    "فلغيركما",
    "فلغيركن",
    "فلغيرنا",
    "فلغيره",
    "فلغيرها",
    "فلغيرهم",
    "فلغيرهما",
    "فلغيرهن",
    "فلغيري",
    "فلفو",
    "فلفوق",
    "فلفوقك",
    "فلفوقكم",
    "فلفوقكما",
    "فلفوقكن",
    "فلفوقنا",
    "فلفوقه",
    "فلفوقها",
    "فلفوقهم",
    "فلفوقهما",
    "فلفوقهن",
    "فلفوقي",
    "فلفوك",
    "فلفوكم",
    "فلفوكما",
    "فلفوكن",
    "فلفونا",
    "فلفوه",
    "فلفوها",
    "فلفوهم",
    "فلفوهما",
    "فلفوهن",
    "فلفوي",
    "فلفي",
    "فلفيك",
    "فلفيكم",
    "فلفيكما",
    "فلفيكن",
    "فلفينا",
    "فلفيه",
    "فلفيها",
    "فلفيهم",
    "فلفيهما",
    "فلفيهن",
    "فلفيي",
    "فلقام",
    "فلقد",
    "فلقلما",
    "فلكأن",
    "فلكأنك",
    "فلكأنكم",
    "فلكأنكما",
    "فلكأنكن",
    "فلكأنما",
    "فلكأننا",
    "فلكأنه",
    "فلكأنها",
    "فلكأنهم",
    "فلكأنهما",
    "فلكأنهن",
    "فلكأني",
    "فلكاد",
    "فلكان",
    "فلكرب",
    "فلكلتا",
    "فلكلك",
    "فلكلكم",
    "فلكلكما",
    "فلكلكن",
    "فلكلنا",
    "فلكله",
    "فلكلها",
    "فلكلهم",
    "فلكلهما",
    "فلكلهن",
    "فلكلي",
    "فلكليكما",
    "فلكليهما",
    "فلكما",
    "فلكن",
    "فلكنك",
    "فلكنكم",
    "فلكنكما",
    "فلكنكن",
    "فلكنما",
    "فلكننا",
    "فلكنه",
    "فلكنها",
    "فلكنهم",
    "فلكنهما",
    "فلكنهن",
    "فلكني",
    "فلكيلا",
    "فللألاء",
    "فللألى",
    "فللائي",
    "فللاتي",
    "فللتي",
    "فللتيا",
    "فللتين",
    "فللذي",
    "فللذين",
    "فللواتي",
    "فلمثل",
    "فلمثلك",
    "فلمثلكم",
    "فلمثلكما",
    "فلمثلكن",
    "فلمثلنا",
    "فلمثله",
    "فلمثلها",
    "فلمثلهم",
    "فلمثلهما",
    "فلمثلهن",
    "فلمثلي",
    "فلمع",
    "فلمعك",
    "فلمعكم",
    "فلمعكما",
    "فلمعكن",
    "فلمعنا",
    "فلمعه",
    "فلمعها",
    "فلمعهم",
    "فلمعهما",
    "فلمعهن",
    "فلمعي",
    "فلمن",
    "فلمنك",
    "فلمنكم",
    "فلمنكما",
    "فلمنكن",
    "فلمننا",
    "فلمنه",
    "فلمنها",
    "فلمنهم",
    "فلمنهما",
    "فلمنهن",
    "فلمني",
    "فلنا",
    "فلنحن",
    "فلنحو",
    "فلنحوك",
    "فلنحوكم",
    "فلنحوكما",
    "فلنحوكن",
    "فلنحونا",
    "فلنحوه",
    "فلنحوها",
    "فلنحوهم",
    "فلنحوهما",
    "فلنحوهن",
    "فلنحوي",
    "فلنفس",
    "فلنفسك",
    "فلنفسكم",
    "فلنفسكما",
    "فلنفسكن",
    "فلنفسنا",
    "فلنفسها",
    "فلنفسهم",
    "فلنفسهما",
    "فلنفسهن",
    "فلنفسي",
    "فلهؤلاء",
    "فلهاته",
    "فلهاتي",
    "فلهاتين",
    "فلهب",
    "فلهذا",
    "فلهذه",
    "فلهذي",
    "فلهذين",
    "فلهناك",
    "فلهنالك",
    "فلهو",
    "فلهي",
    "فلوما",
    "فليت",
    "فليتك",
    "فليتكم",
    "فليتكما",
    "فليتكن",
    "فليتنا",
    "فليته",
    "فليتها",
    "فليتهم",
    "فليتهما",
    "فليتهن",
    "فليتي",
    "فليسا",
    "فليست",
    "فليستا",
    "فليسوا",
    "فليمين",
    "فليمينك",
    "فليمينكم",
    "فليمينكما",
    "فليمينكن",
    "فليميننا",
    "فليمينه",
    "فليمينها",
    "فليمينهم",
    "فليمينهما",
    "فليمينهن",
    "فليميني",
    "فماانفك",
    "فمابرح",
    "فمادام",
    "فمازال",
    "فمافتئ",
    "فمتى",
    "فمثل",
    "فمثلك",
    "فمثلكم",
    "فمثلكما",
    "فمثلكن",
    "فمثلنا",
    "فمثلها",
    "فمثلهم",
    "فمثلهما",
    "فمثلهن",
    "فمثلي",
    "فمذ",
    "فمرة",
    "فمساء",
    "فمع",
    "فمعاذ",
    "فمعاذك",
    "فمعاذكم",
    "فمعاذكما",
    "فمعاذكن",
    "فمعاذنا",
    "فمعاذه",
    "فمعاذها",
    "فمعاذهم",
    "فمعاذهما",
    "فمعاذهن",
    "فمعاذي",
    "فمعك",
    "فمعكم",
    "فمعكما",
    "فمعكن",
    "فمعنا",
    "فمعه",
    "فمعها",
    "فمعهم",
    "فمعهما",
    "فمعهن",
    "فمعي",
    "فمكانك",
    "فمكانكم",
    "فمكانكما",
    "فمكانكن",
    "فمما",
    "فممن",
    "فمنذ",
    "فمنك",
    "فمنكما",
    "فمنكن",
    "فمننا",
    "فمنهما",
    "فمنهن",
    "فمني",
    "فمهما",
    "فنحن",
    "فنحو",
    "فنحوك",
    "فنحوكم",
    "فنحوكما",
    "فنحوكن",
    "فنحونا",
    "فنحوه",
    "فنحوها",
    "فنحوهم",
    "فنحوهما",
    "فنحوهن",
    "فنحوي",
    "فنفس",
    "فنفسك",
    "فنفسكم",
    "فنفسكما",
    "فنفسكن",
    "فنفسنا",
    "فنفسه",
    "فنفسها",
    "فنفسهم",
    "فنفسهما",
    "فنفسهن",
    "فنفسي",
    "فهؤلاء",
    "فهاؤم",
    "فهاتان",
    "فهاته",
    "فهاتي",
    "فهاتين",
    "فهاهنا",
    "فهذان",
    "فهذه",
    "فهذي",
    "فهذين",
    "فهكذا",
    "فهلا",
    "فهما",
    "فهن",
    "فهنا",
    "فهناك",
    "فهنالك",
    "فهيا",
    "فهيت",
    "فو",
    "فوقك",
    "فوقكما",
    "فوقكن",
    "فوقنا",
    "فوقهما",
    "فوقي",
    "فوك",
    "فوكم",
    "فوكما",
    "فوكن",
    "فوه",
    "فوها",
    "فوهم",
    "فوهما",
    "فوهن",
    "فوي",
    "فيكما",
    "فيكن",
    "فيمين",
    "فيمينك",
    "فيمينكم",
    "فيمينكما",
    "فيمينكن",
    "فيميننا",
    "فيمينه",
    "فيمينها",
    "فيمينهم",
    "فيمينهما",
    "فيمينهن",
    "فيميني",
    "فيي",
    "قط",
    "قلما",
    "كأب",
    "كأبك",
    "كأبكم",
    "كأبكما",
    "كأبكن",
    "كأبنا",
    "كأبه",
    "كأبها",
    "كأبهم",
    "كأبهما",
    "كأبهن",
    "كأبي",
    "كأجمع",
    "كأجمعك",
    "كأجمعكم",
    "كأجمعكما",
    "كأجمعكن",
    "كأجمعنا",
    "كأجمعه",
    "كأجمعها",
    "كأجمعهم",
    "كأجمعهما",
    "كأجمعهن",
    "كأجمعي",
    "كأخ",
    "كأخك",
    "كأخكم",
    "كأخكما",
    "كأخكن",
    "كأخنا",
    "كأخه",
    "كأخها",
    "كأخهم",
    "كأخهما",
    "كأخهن",
    "كأخي",
    "كأقل",
    "كأقلك",
    "كأقلكم",
    "كأقلكما",
    "كأقلكن",
    "كأقلنا",
    "كأقله",
    "كأقلها",
    "كأقلهم",
    "كأقلهما",
    "كأقلهن",
    "كأقلي",
    "كأكثر",
    "كأكثرك",
    "كأكثركم",
    "كأكثركما",
    "كأكثركن",
    "كأكثرنا",
    "كأكثره",
    "كأكثرها",
    "كأكثرهم",
    "كأكثرهما",
    "كأكثرهن",
    "كأكثري",
    "كأنكم",
    "كأنكما",
    "كأنكن",
    "كأننا",
    "كأنهما",
    "كأني",
    "كأولئك",
    "كأولئكم",
    "كأولاء",
    "كأولالك",
    "كأي",
    "كأيك",
    "كأيكم",
    "كأيكما",
    "كأيكن",
    "كأين",
    "كأينا",
    "كأيه",
    "كأيها",
    "كأيهم",
    "كأيهما",
    "كأيهن",
    "كأيي",
    "كإن",
    "كإنك",
    "كإنكم",
    "كإنكما",
    "كإنكن",
    "كإننا",
    "كإنه",
    "كإنها",
    "كإنهم",
    "كإنهما",
    "كإنهن",
    "كإني",
    "كإياك",
    "كإياكم",
    "كإياكما",
    "كإياكن",
    "كإيانا",
    "كإياه",
    "كإياها",
    "كإياهم",
    "كإياهما",
    "كإياهن",
    "كإياي",
    "كالألاء",
    "كالألى",
    "كاللائي",
    "كاللاتي",
    "كاللتيا",
    "كاللتين",
    "كاللذين",
    "كاللواتي",
    "كبعض",
    "كبعضك",
    "كبعضكم",
    "كبعضكما",
    "كبعضكن",
    "كبعضنا",
    "كبعضه",
    "كبعضها",
    "كبعضهم",
    "كبعضهما",
    "كبعضهن",
    "كبعضي",
    "كتانك",
    "كتجاه",
    "كتجاهك",
    "كتجاهكم",
    "كتجاهكما",
    "كتجاهكن",
    "كتجاهنا",
    "كتجاهه",
    "كتجاهها",
    "كتجاههم",
    "كتجاههما",
    "كتجاههن",
    "كتجاهي",
    "كتلقاء",
    "كتلقاءك",
    "كتلقاءكم",
    "كتلقاءكما",
    "كتلقاءكن",
    "كتلقاءنا",
    "كتلقاءه",
    "كتلقاءها",
    "كتلقاءهم",
    "كتلقاءهما",
    "كتلقاءهن",
    "كتلقاءي",
    "كتلك",
    "كتلكم",
    "كتلكما",
    "كته",
    "كتي",
    "كتين",
    "كتينك",
    "كجميع",
    "كجميعك",
    "كجميعكم",
    "كجميعكما",
    "كجميعكن",
    "كجميعنا",
    "كجميعه",
    "كجميعها",
    "كجميعهم",
    "كجميعهما",
    "كجميعهن",
    "كجميعي",
    "كحسب",
    "كحسبك",
    "كحسبكم",
    "كحسبكما",
    "كحسبكن",
    "كحسبنا",
    "كحسبه",
    "كحسبها",
    "كحسبهم",
    "كحسبهما",
    "كحسبهن",
    "كحسبي",
    "كحم",
    "كحمك",
    "كحمكم",
    "كحمكما",
    "كحمكن",
    "كحمنا",
    "كحمه",
    "كحمها",
    "كحمهم",
    "كحمهما",
    "كحمهن",
    "كحمي",
    "كحيث",
    "كخ",
    "كذات",
    "كذاك",
    "كذانك",
    "كذلكما",
    "كذلكن",
    "كذه",
    "كذواتي",
    "كذي",
    "كذين",
    "كذينك",
    "كسوى",
    "كسوي",
    "كسويك",
    "كسويكم",
    "كسويكما",
    "كسويكن",
    "كسوينا",
    "كسويه",
    "كسويها",
    "كسويهم",
    "كسويهما",
    "كسويهن",
    "كشبه",
    "كشبهك",
    "كشبهكم",
    "كشبهكما",
    "كشبهكن",
    "كشبهنا",
    "كشبهه",
    "كشبهها",
    "كشبههم",
    "كشبههما",
    "كشبههن",
    "كشبهي",
    "كعامة",
    "كعامتك",
    "كعامتكم",
    "كعامتكما",
    "كعامتكن",
    "كعامتنا",
    "كعامته",
    "كعامتها",
    "كعامتهم",
    "كعامتهما",
    "كعامتهن",
    "كعامتي",
    "كعين",
    "كعينك",
    "كعينكم",
    "كعينكما",
    "كعينكن",
    "كعيننا",
    "كعينه",
    "كعينها",
    "كعينهم",
    "كعينهما",
    "كعينهن",
    "كعيني",
    "كغير",
    "كغيرك",
    "كغيركم",
    "كغيركما",
    "كغيركن",
    "كغيرنا",
    "كغيره",
    "كغيرها",
    "كغيرهم",
    "كغيرهما",
    "كغيرهن",
    "كغيري",
    "كفو",
    "كفوك",
    "كفوكم",
    "كفوكما",
    "كفوكن",
    "كفونا",
    "كفوه",
    "كفوها",
    "كفوهم",
    "كفوهما",
    "كفوهن",
    "كفوي",
    "ككل",
    "ككلتا",
    "ككلك",
    "ككلكم",
    "ككلكما",
    "ككلكن",
    "ككلنا",
    "ككله",
    "ككلها",
    "ككلهم",
    "ككلهما",
    "ككلهن",
    "ككلي",
    "ككليكما",
    "ككليهما",
    "كلك",
    "كلكم",
    "كلكما",
    "كلكن",
    "كلنا",
    "كلهما",
    "كليكما",
    "كليهما",
    "كمثلك",
    "كمثلكم",
    "كمثلكما",
    "كمثلكن",
    "كمثلنا",
    "كمثلها",
    "كمثلهم",
    "كمثلهما",
    "كمثلهن",
    "كمثلي",
    "كنحو",
    "كنحوك",
    "كنحوكم",
    "كنحوكما",
    "كنحوكن",
    "كنحونا",
    "كنحوه",
    "كنحوها",
    "كنحوهم",
    "كنحوهما",
    "كنحوهن",
    "كنحوي",
    "كنفسك",
    "كنفسكم",
    "كنفسكما",
    "كنفسكن",
    "كنفسنا",
    "كنفسه",
    "كنفسها",
    "كنفسهم",
    "كنفسهما",
    "كنفسهن",
    "كنفسي",
    "كهؤلاء",
    "كهاته",
    "كهاتي",
    "كهاتين",
    "كهذا",
    "كهذه",
    "كهذي",
    "كهذين",
    "كهم",
    "كهما",
    "كهن",
    "كهناك",
    "كهنالك",
    "كهو",
    "كهي",
    "كيت",
    "كيفما",
    "لآض",
    "لأب",
    "لأبك",
    "لأبكم",
    "لأبكما",
    "لأبكن",
    "لأبنا",
    "لأبه",
    "لأبها",
    "لأبهم",
    "لأبهما",
    "لأبهن",
    "لأجمع",
    "لأجمعك",
    "لأجمعكم",
    "لأجمعكما",
    "لأجمعكن",
    "لأجمعنا",
    "لأجمعه",
    "لأجمعها",
    "لأجمعهم",
    "لأجمعهما",
    "لأجمعهن",
    "لأجمعي",
    "لأخ",
    "لأخذ",
    "لأخك",
    "لأخكم",
    "لأخكما",
    "لأخكن",
    "لأخنا",
    "لأخه",
    "لأخها",
    "لأخهم",
    "لأخهما",
    "لأخهن",
    "لأخي",
    "لأصبح",
    "لأضحى",
    "لأقبل",
    "لأقل",
    "لأقلك",
    "لأقلكم",
    "لأقلكما",
    "لأقلكن",
    "لأقلنا",
    "لأقله",
    "لأقلها",
    "لأقلهم",
    "لأقلهما",
    "لأقلهن",
    "لأقلي",
    "لأكثرك",
    "لأكثركم",
    "لأكثركما",
    "لأكثركن",
    "لأكثرنا",
    "لأكثره",
    "لأكثرها",
    "لأكثرهما",
    "لأكثرهن",
    "لأكثري",
    "لأمام",
    "لأمامك",
    "لأمامكم",
    "لأمامكما",
    "لأمامكن",
    "لأمامنا",
    "لأمامه",
    "لأمامها",
    "لأمامهم",
    "لأمامهما",
    "لأمامهن",
    "لأمامي",
    "لأمسى",
    "لأنا",
    "لأنتما",
    "لأنتن",
    "لأنشأ",
    "لأنك",
    "لأنكم",
    "لأنكما",
    "لأنكن",
    "لأننا",
    "لأنها",
    "لأنهم",
    "لأنهما",
    "لأنهن",
    "لأني",
    "لأوشك",
    "لأولئك",
    "لأولئكم",
    "لأولاء",
    "لأولالك",
    "لأيك",
    "لأيكم",
    "لأيكما",
    "لأيكن",
    "لأينا",
    "لأيه",
    "لأيها",
    "لأيهم",
    "لأيهما",
    "لأيهن",
    "لأيي",
    "لإلي",
    "لإليك",
    "لإليكم",
    "لإليكما",
    "لإليكن",
    "لإلينا",
    "لإليه",
    "لإليها",
    "لإليهم",
    "لإليهما",
    "لإليهن",
    "لإن",
    "لإنك",
    "لإنكم",
    "لإنكما",
    "لإنكن",
    "لإننا",
    "لإنه",
    "لإنها",
    "لإنهم",
    "لإنهما",
    "لإنهن",
    "لإني",
    "لإياك",
    "لإياكم",
    "لإياكما",
    "لإياكن",
    "لإيانا",
    "لإياه",
    "لإياها",
    "لإياهم",
    "لإياهما",
    "لإياهن",
    "لإياي",
    "لابتدأ",
    "لات",
    "لاخلولق",
    "لارتد",
    "لاستحال",
    "لاسيما",
    "لانبرى",
    "لانقلب",
    "لبات",
    "لبعضك",
    "لبعضكم",
    "لبعضكما",
    "لبعضكن",
    "لبعضنا",
    "لبعضه",
    "لبعضها",
    "لبعضهم",
    "لبعضهما",
    "لبعضهن",
    "لبعضي",
    "لتانك",
    "لتبدل",
    "لتجاه",
    "لتجاهك",
    "لتجاهكم",
    "لتجاهكما",
    "لتجاهكن",
    "لتجاهنا",
    "لتجاهه",
    "لتجاهها",
    "لتجاههم",
    "لتجاههما",
    "لتجاههن",
    "لتجاهي",
    "لتحت",
    "لتحتك",
    "لتحتكم",
    "لتحتكما",
    "لتحتكن",
    "لتحتنا",
    "لتحته",
    "لتحتها",
    "لتحتهم",
    "لتحتهما",
    "لتحتهن",
    "لتحتي",
    "لتحول",
    "لتلقاء",
    "لتلقاءك",
    "لتلقاءكم",
    "لتلقاءكما",
    "لتلقاءكن",
    "لتلقاءنا",
    "لتلقاءه",
    "لتلقاءها",
    "لتلقاءهم",
    "لتلقاءهما",
    "لتلقاءهن",
    "لتلقاءي",
    "لتلك",
    "لتلكم",
    "لتلكما",
    "لته",
    "لتي",
    "لتين",
    "لتينك",
    "لجميعك",
    "لجميعكم",
    "لجميعكما",
    "لجميعكن",
    "لجميعنا",
    "لجميعه",
    "لجميعها",
    "لجميعهم",
    "لجميعهما",
    "لجميعهن",
    "لجميعي",
    "لحار",
    "لحرى",
    "لحسب",
    "لحسبك",
    "لحسبكم",
    "لحسبكما",
    "لحسبكن",
    "لحسبنا",
    "لحسبه",
    "لحسبها",
    "لحسبهم",
    "لحسبهما",
    "لحسبهن",
    "لحسبي",
    "لحمك",
    "لحمكم",
    "لحمكما",
    "لحمكن",
    "لحمنا",
    "لحمه",
    "لحمها",
    "لحمهم",
    "لحمهما",
    "لحمهن",
    "لحيث",
    "لخلف",
    "لخلفك",
    "لخلفكم",
    "لخلفكما",
    "لخلفكن",
    "لخلفنا",
    "لخلفه",
    "لخلفها",
    "لخلفهم",
    "لخلفهما",
    "لخلفهن",
    "لخلفي",
    "لدنكم",
    "لدنكما",
    "لدنكن",
    "لدننا",
    "لدنها",
    "لدنهم",
    "لدنهما",
    "لدنهن",
    "لديك",
    "لديكم",
    "لديكما",
    "لديكن",
    "لديها",
    "لديهما",
    "لديهن",
    "لذات",
    "لذاك",
    "لذانك",
    "لذلكم",
    "لذلكما",
    "لذلكن",
    "لذه",
    "لذواتي",
    "لذين",
    "لذينك",
    "لراح",
    "لرجع",
    "لستما",
    "لسن",
    "لسنا",
    "لسوى",
    "لسوي",
    "لسويك",
    "لسويكم",
    "لسويكما",
    "لسويكن",
    "لسوينا",
    "لسويه",
    "لسويها",
    "لسويهم",
    "لسويهما",
    "لسويهن",
    "لشبهك",
    "لشبهكم",
    "لشبهكما",
    "لشبهكن",
    "لشبهنا",
    "لشبهه",
    "لشبهها",
    "لشبههم",
    "لشبههما",
    "لشبههن",
    "لشبهي",
    "لشرع",
    "لشمال",
    "لشمالك",
    "لشمالكم",
    "لشمالكما",
    "لشمالكن",
    "لشمالنا",
    "لشماله",
    "لشمالها",
    "لشمالهم",
    "لشمالهما",
    "لشمالهن",
    "لشمالي",
    "لصار",
    "لطالما",
    "لطفق",
    "لظل",
    "لعامة",
    "لعامتك",
    "لعامتكم",
    "لعامتكما",
    "لعامتكن",
    "لعامتنا",
    "لعامته",
    "لعامتها",
    "لعامتهم",
    "لعامتهما",
    "لعامتهن",
    "لعامتي",
    "لعسى",
    "لعلق",
    "لعلكما",
    "لعلكن",
    "لعلها",
    "لعلهما",
    "لعلهن",
    "لعليك",
    "لعليكم",
    "لعليكما",
    "لعليكن",
    "لعلينا",
    "لعليه",
    "لعليها",
    "لعليهم",
    "لعليهما",
    "لعليهن",
    "لعمر",
    "لعمركم",
    "لعمركما",
    "لعمركن",
    "لعمرنا",
    "لعمره",
    "لعمرها",
    "لعمرهم",
    "لعمرهما",
    "لعمرهن",
    "لعمري",
    "لعنك",
    "لعنكم",
    "لعنكما",
    "لعنكن",
    "لعننا",
    "لعنها",
    "لعنهما",
    "لعنهن",
    "لعني",
    "لعينك",
    "لعينكم",
    "لعينكما",
    "لعينكن",
    "لعيننا",
    "لعينه",
    "لعينها",
    "لعينهم",
    "لعينهما",
    "لعينهن",
    "لعيني",
    "لغدا",
    "لغيرك",
    "لغيركم",
    "لغيركما",
    "لغيركن",
    "لغيرنا",
    "لغيره",
    "لغيرها",
    "لغيرهم",
    "لغيرهما",
    "لغيرهن",
    "لغيري",
    "لفو",
    "لفوق",
    "لفوقك",
    "لفوقكم",
    "لفوقكما",
    "لفوقكن",
    "لفوقنا",
    "لفوقه",
    "لفوقها",
    "لفوقهم",
    "لفوقهما",
    "لفوقهن",
    "لفوقي",
    "لفوك",
    "لفوكم",
    "لفوكما",
    "لفوكن",
    "لفونا",
    "لفوه",
    "لفوها",
    "لفوهم",
    "لفوهما",
    "لفوهن",
    "لفوي",
    "لفيك",
    "لفيكم",
    "لفيكما",
    "لفيكن",
    "لفينا",
    "لفيه",
    "لفيها",
    "لفيهم",
    "لفيهما",
    "لفيهن",
    "لفيي",
    "لقام",
    "لقلما",
    "لكأن",
    "لكأنك",
    "لكأنكم",
    "لكأنكما",
    "لكأنكن",
    "لكأنما",
    "لكأننا",
    "لكأنه",
    "لكأنها",
    "لكأنهم",
    "لكأنهما",
    "لكأنهن",
    "لكأني",
    "لكاد",
    "لكرب",
    "لكلتا",
    "لكلك",
    "لكلكم",
    "لكلكما",
    "لكلكن",
    "لكلنا",
    "لكله",
    "لكلها",
    "لكلهم",
    "لكلهما",
    "لكلهن",
    "لكلي",
    "لكليكما",
    "لكليهما",
    "لكنك",
    "لكنكم",
    "لكنكما",
    "لكنكن",
    "لكنما",
    "لكننا",
    "لكنه",
    "لكنها",
    "لكنهم",
    "لكنهما",
    "لكنهن",
    "لكني",
    "للألاء",
    "للألى",
    "للائي",
    "للاتي",
    "للتيا",
    "للتين",
    "للواتي",
    "لمثلك",
    "لمثلكم",
    "لمثلكما",
    "لمثلكن",
    "لمثلنا",
    "لمثلها",
    "لمثلهم",
    "لمثلهما",
    "لمثلهن",
    "لمثلي",
    "لمعك",
    "لمعكما",
    "لمعكن",
    "لمعنا",
    "لمعه",
    "لمعها",
    "لمعهم",
    "لمعهما",
    "لمعهن",
    "لمعي",
    "لمنك",
    "لمنكما",
    "لمنكن",
    "لمننا",
    "لمنه",
    "لمنها",
    "لمنهم",
    "لمنهما",
    "لمنهن",
    "لمني",
    "لنحو",
    "لنحوك",
    "لنحوكم",
    "لنحوكما",
    "لنحوكن",
    "لنحونا",
    "لنحوه",
    "لنحوها",
    "لنحوهم",
    "لنحوهما",
    "لنحوهن",
    "لنحوي",
    "لنفسك",
    "لنفسكم",
    "لنفسكما",
    "لنفسكن",
    "لنفسنا",
    "لنفسها",
    "لنفسهم",
    "لنفسهما",
    "لنفسهن",
    "لهؤلاء",
    "لهاته",
    "لهاتي",
    "لهاتين",
    "لهذه",
    "لهذي",
    "لهذين",
    "لهناك",
    "لهنالك",
    "لوما",
    "ليتك",
    "ليتكم",
    "ليتكما",
    "ليتكن",
    "ليته",
    "ليتهم",
    "ليتهما",
    "ليتهن",
    "ليتي",
    "ليستا",
    "ليمين",
    "ليمينك",
    "ليمينكم",
    "ليمينكما",
    "ليمينكن",
    "ليميننا",
    "ليمينه",
    "ليمينها",
    "ليمينهم",
    "ليمينهما",
    "ليمينهن",
    "ليميني",
    "ماانفك",
    "مابرح",
    "مادام",
    "مازال",
    "مافتئ",
    "مثلك",
    "مثلكما",
    "مثلكن",
    "مثلهما",
    "مذ",
    "معاذك",
    "معاذكم",
    "معاذكما",
    "معاذكن",
    "معاذنا",
    "معاذه",
    "معاذها",
    "معاذهم",
    "معاذهما",
    "معاذهن",
    "معاذي",
    "معكن",
    "معهما",
    "معهن",
    "مكانك",
    "مكانكما",
    "مكانكن",
    "منكما",
    "مه",
    "نحوك",
    "نحوكم",
    "نحوكما",
    "نحوكن",
    "نحونا",
    "نحوه",
    "نحوها",
    "نحوهم",
    "نحوهما",
    "نحوهن",
    "نخ",
    "نفسكم",
    "نفسكما",
    "نفسكن",
    "نفسنا",
    "نفسهم",
    "نفسهما",
    "نفسهن",
    "هاتان",
    "هاته",
    "هاك",
    "هج",
    "هذي",
    "هذين",
    "هلا",
    "هيا",
    "وآض",
    "وآمين",
    "وآناء",
    "وآنفا",
    "وآه",
    "وأب",
    "وأبدا",
    "وأبك",
    "وأبكم",
    "وأبكما",
    "وأبكن",
    "وأبنا",
    "وأبه",
    "وأبها",
    "وأبهم",
    "وأبهما",
    "وأبهن",
    "وأبي",
    "وأجمع",
    "وأجمعك",
    "وأجمعكم",
    "وأجمعكما",
    "وأجمعكن",
    "وأجمعنا",
    "وأجمعه",
    "وأجمعها",
    "وأجمعهم",
    "وأجمعهما",
    "وأجمعهن",
    "وأجمعي",
    "وأخ",
    "وأخك",
    "وأخكم",
    "وأخكما",
    "وأخكن",
    "وأخنا",
    "وأخه",
    "وأخها",
    "وأخهم",
    "وأخهما",
    "وأخهن",
    "وأصلا",
    "وأضحى",
    "وأف",
    "وأقلك",
    "وأقلكم",
    "وأقلكما",
    "وأقلكن",
    "وأقلنا",
    "وأقله",
    "وأقلها",
    "وأقلهم",
    "وأقلهما",
    "وأقلهن",
    "وأقلي",
    "وأكثرك",
    "وأكثركم",
    "وأكثركما",
    "وأكثركن",
    "وأكثرنا",
    "وأكثره",
    "وأكثرها",
    "وأكثرهما",
    "وأكثرهن",
    "وأكثري",
    "وألا",
    "وأمام",
    "وأمامك",
    "وأمامكم",
    "وأمامكما",
    "وأمامكن",
    "وأمامنا",
    "وأمامه",
    "وأمامها",
    "وأمامهم",
    "وأمامهما",
    "وأمامهن",
    "وأمامي",
    "وأمد",
    "وأمس",
    "وأمسى",
    "وأنتما",
    "وأنتن",
    "وأنشأ",
    "وأنكما",
    "وأنكن",
    "وأننا",
    "وأنها",
    "وأنهما",
    "وأنهن",
    "وأوشك",
    "وأول",
    "وأولاء",
    "وأولالك",
    "وأوه",
    "وأي",
    "وأيا",
    "وأيان",
    "وأيك",
    "وأيكم",
    "وأيكما",
    "وأيكن",
    "وأين",
    "وأينا",
    "وأينما",
    "وأيه",
    "وأيها",
    "وأيهم",
    "وأيهما",
    "وأيهن",
    "وأيي",
    "وإذما",
    "وإذن",
    "وإزاء",
    "وإليكم",
    "وإليكما",
    "وإليكن",
    "وإليها",
    "وإليهم",
    "وإليهما",
    "وإليهن",
    "وإنكما",
    "وإنكن",
    "وإنهن",
    "وإي",
    "وإياكما",
    "وإياكن",
    "وإيانا",
    "وإياه",
    "وإياها",
    "وإياهما",
    "وإياهن",
    "وإيه",
    "وا",
    "وابتدأ",
    "واخلولق",
    "وارتد",
    "واستحال",
    "والآن",
    "والألاء",
    "والألى",
    "واللتان",
    "واللتيا",
    "واللتين",
    "واللذين",
    "واللواتي",
    "وانبرى",
    "وانقلب",
    "واها",
    "وبأب",
    "وبأبك",
    "وبأبكم",
    "وبأبكما",
    "وبأبكن",
    "وبأبنا",
    "وبأبه",
    "وبأبها",
    "وبأبهم",
    "وبأبهما",
    "وبأبهن",
    "وبأبي",
    "وبأجمع",
    "وبأجمعك",
    "وبأجمعكم",
    "وبأجمعكما",
    "وبأجمعكن",
    "وبأجمعنا",
    "وبأجمعه",
    "وبأجمعها",
    "وبأجمعهم",
    "وبأجمعهما",
    "وبأجمعهن",
    "وبأجمعي",
    "وبأخ",
    "وبأخك",
    "وبأخكم",
    "وبأخكما",
    "وبأخكن",
    "وبأخنا",
    "وبأخه",
    "وبأخها",
    "وبأخهم",
    "وبأخهما",
    "وبأخهن",
    "وبأخي",
    "وبأقل",
    "وبأقلك",
    "وبأقلكم",
    "وبأقلكما",
    "وبأقلكن",
    "وبأقلنا",
    "وبأقله",
    "وبأقلها",
    "وبأقلهم",
    "وبأقلهما",
    "وبأقلهن",
    "وبأقلي",
    "وبأكثر",
    "وبأكثرك",
    "وبأكثركم",
    "وبأكثركما",
    "وبأكثركن",
    "وبأكثرنا",
    "وبأكثره",
    "وبأكثرها",
    "وبأكثرهم",
    "وبأكثرهما",
    "وبأكثرهن",
    "وبأكثري",
    "وبأن",
    "وبأنك",
    "وبأنكم",
    "وبأنكما",
    "وبأنكن",
    "وبأننا",
    "وبأنه",
    "وبأنها",
    "وبأنهم",
    "وبأنهما",
    "وبأنهن",
    "وبأني",
    "وبأولئك",
    "وبأولئكم",
    "وبأولاء",
    "وبأولالك",
    "وبأي",
    "وبأيك",
    "وبأيكم",
    "وبأيكما",
    "وبأيكن",
    "وبأينا",
    "وبأيه",
    "وبأيها",
    "وبأيهم",
    "وبأيهما",
    "وبأيهن",
    "وبأيي",
    "وبإن",
    "وبإنك",
    "وبإنكم",
    "وبإنكما",
    "وبإنكن",
    "وبإننا",
    "وبإنه",
    "وبإنها",
    "وبإنهم",
    "وبإنهما",
    "وبإنهن",
    "وبإني",
    "وبإياك",
    "وبإياكم",
    "وبإياكما",
    "وبإياكن",
    "وبإيانا",
    "وبإياه",
    "وبإياها",
    "وبإياهم",
    "وبإياهما",
    "وبإياهن",
    "وبإياي",
    "وبات",
    "وبالألاء",
    "وبالألى",
    "وبالتي",
    "وبالذين",
    "وباللائي",
    "وباللاتي",
    "وباللتيا",
    "وباللتين",
    "وباللذين",
    "وباللواتي",
    "وببعض",
    "وببعضك",
    "وببعضكم",
    "وببعضكما",
    "وببعضكن",
    "وببعضنا",
    "وببعضه",
    "وببعضها",
    "وببعضهم",
    "وببعضهما",
    "وببعضهن",
    "وببعضي",
    "وبتانك",
    "وبتجاه",
    "وبتجاهك",
    "وبتجاهكم",
    "وبتجاهكما",
    "وبتجاهكن",
    "وبتجاهنا",
    "وبتجاهه",
    "وبتجاهها",
    "وبتجاههم",
    "وبتجاههما",
    "وبتجاههن",
    "وبتجاهي",
    "وبتلقاء",
    "وبتلقاءك",
    "وبتلقاءكم",
    "وبتلقاءكما",
    "وبتلقاءكن",
    "وبتلقاءنا",
    "وبتلقاءه",
    "وبتلقاءها",
    "وبتلقاءهم",
    "وبتلقاءهما",
    "وبتلقاءهن",
    "وبتلقاءي",
    "وبتلك",
    "وبتلكم",
    "وبتلكما",
    "وبته",
    "وبتي",
    "وبتين",
    "وبتينك",
    "وبجميع",
    "وبجميعك",
    "وبجميعكم",
    "وبجميعكما",
    "وبجميعكن",
    "وبجميعنا",
    "وبجميعه",
    "وبجميعها",
    "وبجميعهم",
    "وبجميعهما",
    "وبجميعهن",
    "وبجميعي",
    "وبحسب",
    "وبحسبك",
    "وبحسبكم",
    "وبحسبكما",
    "وبحسبكن",
    "وبحسبنا",
    "وبحسبه",
    "وبحسبها",
    "وبحسبهم",
    "وبحسبهما",
    "وبحسبهن",
    "وبحسبي",
    "وبحم",
    "وبحمك",
    "وبحمكم",
    "وبحمكما",
    "وبحمكن",
    "وبحمنا",
    "وبحمه",
    "وبحمها",
    "وبحمهم",
    "وبحمهما",
    "وبحمهن",
    "وبحمي",
    "وبحيث",
    "وبذا",
    "وبذات",
    "وبذاك",
    "وبذانك",
    "وبذلكم",
    "وبذلكما",
    "وبذلكن",
    "وبذه",
    "وبذواتي",
    "وبذين",
    "وبذينك",
    "وبس",
    "وبسوى",
    "وبسوي",
    "وبسويك",
    "وبسويكم",
    "وبسويكما",
    "وبسويكن",
    "وبسوينا",
    "وبسويه",
    "وبسويها",
    "وبسويهم",
    "وبسويهما",
    "وبسويهن",
    "وبشبه",
    "وبشبهك",
    "وبشبهكم",
    "وبشبهكما",
    "وبشبهكن",
    "وبشبهنا",
    "وبشبهه",
    "وبشبهها",
    "وبشبههم",
    "وبشبههما",
    "وبشبههن",
    "وبشبهي",
    "وبضع",
    "وبضعك",
    "وبضعكم",
    "وبضعكما",
    "وبضعكن",
    "وبضعنا",
    "وبضعه",
    "وبضعها",
    "وبضعهم",
    "وبضعهما",
    "وبضعهن",
    "وبضعي",
    "وبطآن",
    "وبعامة",
    "وبعامتك",
    "وبعامتكم",
    "وبعامتكما",
    "وبعامتكن",
    "وبعامتنا",
    "وبعامته",
    "وبعامتها",
    "وبعامتهم",
    "وبعامتهما",
    "وبعامتهن",
    "وبعامتي",
    "وبعد",
    "وبعدك",
    "وبعدكم",
    "وبعدكما",
    "وبعدكن",
    "وبعدنا",
    "وبعده",
    "وبعدها",
    "وبعدهم",
    "وبعدهما",
    "وبعدهن",
    "وبعدي",
    "وبعض",
    "وبعضك",
    "وبعضكم",
    "وبعضكما",
    "وبعضكن",
    "وبعضنا",
    "وبعضه",
    "وبعضها",
    "وبعضهم",
    "وبعضهما",
    "وبعضهن",
    "وبعضي",
    "وبعين",
    "وبعينك",
    "وبعينكم",
    "وبعينكما",
    "وبعينكن",
    "وبعيننا",
    "وبعينه",
    "وبعينها",
    "وبعينهم",
    "وبعينهما",
    "وبعينهن",
    "وبعيني",
    "وبغير",
    "وبغيرك",
    "وبغيركم",
    "وبغيركما",
    "وبغيركن",
    "وبغيرنا",
    "وبغيره",
    "وبغيرها",
    "وبغيرهم",
    "وبغيرهما",
    "وبغيرهن",
    "وبغيري",
    "وبفو",
    "وبفوك",
    "وبفوكم",
    "وبفوكما",
    "وبفوكن",
    "وبفونا",
    "وبفوه",
    "وبفوها",
    "وبفوهم",
    "وبفوهما",
    "وبفوهن",
    "وبفوي",
    "وبك",
    "وبكل",
    "وبكلتا",
    "وبكلك",
    "وبكلكم",
    "وبكلكما",
    "وبكلكن",
    "وبكلنا",
    "وبكله",
    "وبكلها",
    "وبكلهم",
    "وبكلهما",
    "وبكلهن",
    "وبكلي",
    "وبكليكما",
    "وبكليهما",
    "وبكن",
    "وبله",
    "وبماذا",
    "وبمثل",
    "وبمثلك",
    "وبمثلكم",
    "وبمثلكما",
    "وبمثلكن",
    "وبمثلنا",
    "وبمثله",
    "وبمثلها",
    "وبمثلهم",
    "وبمثلهما",
    "وبمثلهن",
    "وبمثلي",
    "وبنا",
    "وبنحو",
    "وبنحوك",
    "وبنحوكم",
    "وبنحوكما",
    "وبنحوكن",
    "وبنحونا",
    "وبنحوه",
    "وبنحوها",
    "وبنحوهم",
    "وبنحوهما",
    "وبنحوهن",
    "وبنحوي",
    "وبنفس",
    "وبنفسك",
    "وبنفسكم",
    "وبنفسكما",
    "وبنفسكن",
    "وبنفسنا",
    "وبنفسه",
    "وبنفسها",
    "وبنفسهم",
    "وبنفسهما",
    "وبنفسهن",
    "وبنفسي",
    "وبهؤلاء",
    "وبها",
    "وبهاته",
    "وبهاتي",
    "وبهاتين",
    "وبهذا",
    "وبهذه",
    "وبهذي",
    "وبهذين",
    "وبهم",
    "وبهما",
    "وبهن",
    "وبهناك",
    "وبهنالك",
    "وبهو",
    "وبهي",
    "وبي",
    "وبيد",
    "وبينكما",
    "وبينكن",
    "وبيننا",
    "وبينها",
    "وبينهن",
    "وبيني",
    "وتارة",
    "وتان",
    "وتانك",
    "وتبدل",
    "وتجاه",
    "وتجاهك",
    "وتجاهكم",
    "وتجاهكما",
    "وتجاهكن",
    "وتجاهنا",
    "وتجاهه",
    "وتجاهها",
    "وتجاههم",
    "وتجاههما",
    "وتجاههن",
    "وتجاهي",
    "وتحت",
    "وتحتك",
    "وتحتكم",
    "وتحتكما",
    "وتحتكن",
    "وتحتنا",
    "وتحته",
    "وتحتها",
    "وتحتهم",
    "وتحتهما",
    "وتحتهن",
    "وتحتي",
    "وتحول",
    "وتلقاء",
    "وتلقاءك",
    "وتلقاءكم",
    "وتلقاءكما",
    "وتلقاءكن",
    "وتلقاءنا",
    "وتلقاءه",
    "وتلقاءها",
    "وتلقاءهم",
    "وتلقاءهما",
    "وتلقاءهن",
    "وتلقاءي",
    "وتلكم",
    "وتلكما",
    "وته",
    "وتي",
    "وتين",
    "وتينك",
    "وثم",
    "وثمة",
    "وجميع",
    "وجميعك",
    "وجميعكم",
    "وجميعكما",
    "وجميعكن",
    "وجميعنا",
    "وجميعه",
    "وجميعها",
    "وجميعهم",
    "وجميعهما",
    "وجميعهن",
    "وجميعي",
    "وجير",
    "وحار",
    "وحاشا",
    "وحاشاك",
    "وحاشاكم",
    "وحاشاكما",
    "وحاشاكن",
    "وحاشانا",
    "وحاشاه",
    "وحاشاها",
    "وحاشاهم",
    "وحاشاهما",
    "وحاشاهن",
    "وحاشاي",
    "وحبذا",
    "وحتى",
    "وحذار",
    "وحرى",
    "وحسب",
    "وحسبك",
    "وحسبكم",
    "وحسبكما",
    "وحسبكن",
    "وحسبنا",
    "وحسبه",
    "وحسبها",
    "وحسبهم",
    "وحسبهما",
    "وحسبهن",
    "وحسبي",
    "وحم",
    "وحمك",
    "وحمكم",
    "وحمكما",
    "وحمكن",
    "وحمنا",
    "وحمه",
    "وحمها",
    "وحمهم",
    "وحمهما",
    "وحمهن",
    "وحمي",
    "وحوالى",
    "وحوالي",
    "وحواليك",
    "وحواليكم",
    "وحواليكما",
    "وحواليكن",
    "وحوالينا",
    "وحواليه",
    "وحواليها",
    "وحواليهم",
    "وحواليهما",
    "وحواليهن",
    "وحول",
    "وحولك",
    "وحولكم",
    "وحولكما",
    "وحولكن",
    "وحولنا",
    "وحوله",
    "وحولها",
    "وحولهم",
    "وحولهما",
    "وحولهن",
    "وحولي",
    "وحيثما",
    "وحينك",
    "وحينكم",
    "وحينكما",
    "وحينكن",
    "وحيننا",
    "وحينه",
    "وحينها",
    "وحينهم",
    "وحينهما",
    "وحينهن",
    "وحيني",
    "وخلا",
    "وخلاك",
    "وخلاكم",
    "وخلاكما",
    "وخلاكن",
    "وخلال",
    "وخلالك",
    "وخلالكم",
    "وخلالكما",
    "وخلالكن",
    "وخلالنا",
    "وخلاله",
    "وخلالها",
    "وخلالهم",
    "وخلالهما",
    "وخلالهن",
    "وخلالي",
    "وخلانا",
    "وخلاه",
    "وخلاها",
    "وخلاهم",
    "وخلاهما",
    "وخلاهن",
    "وخلاي",
    "وخلف",
    "وخلفك",
    "وخلفكم",
    "وخلفكما",
    "وخلفكن",
    "وخلفنا",
    "وخلفه",
    "وخلفها",
    "وخلفهم",
    "وخلفهما",
    "وخلفهن",
    "وخلفي",
    "ودونك",
    "وذاك",
    "وذان",
    "وذانك",
    "وذلكما",
    "وذلكن",
    "وذه",
    "وذوا",
    "وذواتا",
    "وذواتي",
    "وذيت",
    "وذين",
    "وذينك",
    "وراءك",
    "وراح",
    "ورجع",
    "ورويدك",
    "وريث",
    "وساءما",
    "وسبحانك",
    "وسبحانكم",
    "وسبحانكما",
    "وسبحانكن",
    "وسبحاننا",
    "وسبحانه",
    "وسبحانها",
    "وسبحانهم",
    "وسبحانهما",
    "وسبحانهن",
    "وسبحاني",
    "وسرعان",
    "وسوى",
    "وسوي",
    "وسويك",
    "وسويكم",
    "وسويكما",
    "وسويكن",
    "وسوينا",
    "وسويه",
    "وسويها",
    "وسويهم",
    "وسويهما",
    "وسويهن",
    "وشبه",
    "وشبهك",
    "وشبهكم",
    "وشبهكما",
    "وشبهكن",
    "وشبهنا",
    "وشبهه",
    "وشبهها",
    "وشبههم",
    "وشبههما",
    "وشبههن",
    "وشبهي",
    "وشتان",
    "وشرع",
    "وشكان",
    "وشمالك",
    "وشمالكم",
    "وشمالكما",
    "وشمالكن",
    "وشمالنا",
    "وشماله",
    "وشمالها",
    "وشمالهم",
    "وشمالهما",
    "وشمالهن",
    "وشمالي",
    "وصار",
    "وصباح",
    "وضحوة",
    "وضمن",
    "وضمنك",
    "وضمنكم",
    "وضمنكما",
    "وضمنكن",
    "وضمننا",
    "وضمنه",
    "وضمنها",
    "وضمنهم",
    "وضمنهما",
    "وضمنهن",
    "وضمني",
    "وطالما",
    "وطفق",
    "وعامة",
    "وعامتك",
    "وعامتكم",
    "وعامتكما",
    "وعامتكن",
    "وعامتنا",
    "وعامته",
    "وعامتها",
    "وعامتهم",
    "وعامتهما",
    "وعامتهن",
    "وعامتي",
    "وعداك",
    "وعداكم",
    "وعداكما",
    "وعداكن",
    "وعدانا",
    "وعداه",
    "وعداها",
    "وعداهم",
    "وعداهما",
    "وعداهن",
    "وعداي",
    "وعل",
    "وعلق",
    "وعلي",
    "وعليك",
    "وعليكما",
    "وعليكن",
    "وعليهما",
    "وعليهن",
    "وعما",
    "وعندك",
    "وعندكم",
    "وعندكما",
    "وعندكن",
    "وعندها",
    "وعندهما",
    "وعندهن",
    "وعندي",
    "وعنك",
    "وعنكم",
    "وعنكما",
    "وعنكن",
    "وعننا",
    "وعنه",
    "وعنها",
    "وعنهم",
    "وعنهما",
    "وعنهن",
    "وعني",
    "وعوض",
    "وعين",
    "وعينك",
    "وعينكم",
    "وعينكما",
    "وعينكن",
    "وعيننا",
    "وعينه",
    "وعينها",
    "وعينهم",
    "وعينهما",
    "وعينهن",
    "وعيني",
    "وغدا",
    "وغداة",
    "وغيرك",
    "وغيركم",
    "وغيركما",
    "وغيركن",
    "وغيرنا",
    "وغيره",
    "وغيرها",
    "وغيرهم",
    "وغيرهما",
    "وغيرهن",
    "وغيري",
    "وفلان",
    "وفو",
    "وفوقك",
    "وفوقكم",
    "وفوقكما",
    "وفوقكن",
    "وفوقنا",
    "وفوقه",
    "وفوقها",
    "وفوقهم",
    "وفوقهما",
    "وفوقهن",
    "وفوقي",
    "وفوك",
    "وفوكم",
    "وفوكما",
    "وفوكن",
    "وفونا",
    "وفوه",
    "وفوها",
    "وفوهم",
    "وفوهما",
    "وفوهن",
    "وفوي",
    "وفيك",
    "وفيكما",
    "وفيكن",
    "وفيم",
    "وفيما",
    "وفينا",
    "وفيهم",
    "وفيهما",
    "وفيهن",
    "وفيي",
    "وقام",
    "وقط",
    "وقلما",
    "وكأب",
    "وكأبك",
    "وكأبكم",
    "وكأبكما",
    "وكأبكن",
    "وكأبنا",
    "وكأبه",
    "وكأبها",
    "وكأبهم",
    "وكأبهما",
    "وكأبهن",
    "وكأبي",
    "وكأجمع",
    "وكأجمعك",
    "وكأجمعكم",
    "وكأجمعكما",
    "وكأجمعكن",
    "وكأجمعنا",
    "وكأجمعه",
    "وكأجمعها",
    "وكأجمعهم",
    "وكأجمعهما",
    "وكأجمعهن",
    "وكأجمعي",
    "وكأخ",
    "وكأخك",
    "وكأخكم",
    "وكأخكما",
    "وكأخكن",
    "وكأخنا",
    "وكأخه",
    "وكأخها",
    "وكأخهم",
    "وكأخهما",
    "وكأخهن",
    "وكأخي",
    "وكأقل",
    "وكأقلك",
    "وكأقلكم",
    "وكأقلكما",
    "وكأقلكن",
    "وكأقلنا",
    "وكأقله",
    "وكأقلها",
    "وكأقلهم",
    "وكأقلهما",
    "وكأقلهن",
    "وكأقلي",
    "وكأكثر",
    "وكأكثرك",
    "وكأكثركم",
    "وكأكثركما",
    "وكأكثركن",
    "وكأكثرنا",
    "وكأكثره",
    "وكأكثرها",
    "وكأكثرهم",
    "وكأكثرهما",
    "وكأكثرهن",
    "وكأكثري",
    "وكأن",
    "وكأنك",
    "وكأنكم",
    "وكأنكما",
    "وكأنكن",
    "وكأنما",
    "وكأننا",
    "وكأنه",
    "وكأنها",
    "وكأنهم",
    "وكأنهما",
    "وكأنهن",
    "وكأني",
    "وكأولئك",
    "وكأولئكم",
    "وكأولاء",
    "وكأولالك",
    "وكأي",
    "وكأيك",
    "وكأيكم",
    "وكأيكما",
    "وكأيكن",
    "وكأينا",
    "وكأيه",
    "وكأيها",
    "وكأيهم",
    "وكأيهما",
    "وكأيهن",
    "وكأيي",
    "وكإن",
    "وكإنك",
    "وكإنكم",
    "وكإنكما",
    "وكإنكن",
    "وكإننا",
    "وكإنه",
    "وكإنها",
    "وكإنهم",
    "وكإنهما",
    "وكإنهن",
    "وكإني",
    "وكإياك",
    "وكإياكم",
    "وكإياكما",
    "وكإياكن",
    "وكإيانا",
    "وكإياه",
    "وكإياها",
    "وكإياهم",
    "وكإياهما",
    "وكإياهن",
    "وكإياي",
    "وكاد",
    "وكالألاء",
    "وكالألى",
    "وكالتي",
    "وكالذي",
    "وكالذين",
    "وكاللائي",
    "وكاللاتي",
    "وكاللتيا",
    "وكاللتين",
    "وكاللذين",
    "وكاللواتي",
    "وكبعض",
    "وكبعضك",
    "وكبعضكم",
    "وكبعضكما",
    "وكبعضكن",
    "وكبعضنا",
    "وكبعضه",
    "وكبعضها",
    "وكبعضهم",
    "وكبعضهما",
    "وكبعضهن",
    "وكبعضي",
    "وكتانك",
    "وكتجاه",
    "وكتجاهك",
    "وكتجاهكم",
    "وكتجاهكما",
    "وكتجاهكن",
    "وكتجاهنا",
    "وكتجاهه",
    "وكتجاهها",
    "وكتجاههم",
    "وكتجاههما",
    "وكتجاههن",
    "وكتجاهي",
    "وكتلقاء",
    "وكتلقاءك",
    "وكتلقاءكم",
    "وكتلقاءكما",
    "وكتلقاءكن",
    "وكتلقاءنا",
    "وكتلقاءه",
    "وكتلقاءها",
    "وكتلقاءهم",
    "وكتلقاءهما",
    "وكتلقاءهن",
    "وكتلقاءي",
    "وكتلك",
    "وكتلكم",
    "وكتلكما",
    "وكته",
    "وكتي",
    "وكتين",
    "وكتينك",
    "وكجميع",
    "وكجميعك",
    "وكجميعكم",
    "وكجميعكما",
    "وكجميعكن",
    "وكجميعنا",
    "وكجميعه",
    "وكجميعها",
    "وكجميعهم",
    "وكجميعهما",
    "وكجميعهن",
    "وكجميعي",
    "وكحسب",
    "وكحسبك",
    "وكحسبكم",
    "وكحسبكما",
    "وكحسبكن",
    "وكحسبنا",
    "وكحسبه",
    "وكحسبها",
    "وكحسبهم",
    "وكحسبهما",
    "وكحسبهن",
    "وكحسبي",
    "وكحم",
    "وكحمك",
    "وكحمكم",
    "وكحمكما",
    "وكحمكن",
    "وكحمنا",
    "وكحمه",
    "وكحمها",
    "وكحمهم",
    "وكحمهما",
    "وكحمهن",
    "وكحمي",
    "وكحيث",
    "وكذات",
    "وكذاك",
    "وكذانك",
    "وكذلكم",
    "وكذلكما",
    "وكذلكن",
    "وكذه",
    "وكذواتي",
    "وكذي",
    "وكذين",
    "وكذينك",
    "وكرب",
    "وكسوى",
    "وكسوي",
    "وكسويك",
    "وكسويكم",
    "وكسويكما",
    "وكسويكن",
    "وكسوينا",
    "وكسويه",
    "وكسويها",
    "وكسويهم",
    "وكسويهما",
    "وكسويهن",
    "وكشبه",
    "وكشبهك",
    "وكشبهكم",
    "وكشبهكما",
    "وكشبهكن",
    "وكشبهنا",
    "وكشبهه",
    "وكشبهها",
    "وكشبههم",
    "وكشبههما",
    "وكشبههن",
    "وكشبهي",
    "وكعامة",
    "وكعامتك",
    "وكعامتكم",
    "وكعامتكما",
    "وكعامتكن",
    "وكعامتنا",
    "وكعامته",
    "وكعامتها",
    "وكعامتهم",
    "وكعامتهما",
    "وكعامتهن",
    "وكعامتي",
    "وكعين",
    "وكعينك",
    "وكعينكم",
    "وكعينكما",
    "وكعينكن",
    "وكعيننا",
    "وكعينه",
    "وكعينها",
    "وكعينهم",
    "وكعينهما",
    "وكعينهن",
    "وكعيني",
    "وكغير",
    "وكغيرك",
    "وكغيركم",
    "وكغيركما",
    "وكغيركن",
    "وكغيرنا",
    "وكغيره",
    "وكغيرها",
    "وكغيرهم",
    "وكغيرهما",
    "وكغيرهن",
    "وكغيري",
    "وكفو",
    "وكفوك",
    "وكفوكم",
    "وكفوكما",
    "وكفوكن",
    "وكفونا",
    "وكفوه",
    "وكفوها",
    "وكفوهم",
    "وكفوهما",
    "وكفوهن",
    "وكفوي",
    "وككل",
    "وككلتا",
    "وككلك",
    "وككلكم",
    "وككلكما",
    "وككلكن",
    "وككلنا",
    "وككله",
    "وككلها",
    "وككلهم",
    "وككلهما",
    "وككلهن",
    "وككلي",
    "وككليكما",
    "وككليهما",
    "وكلاهما",
    "وكلتا",
    "وكلك",
    "وكلكم",
    "وكلكما",
    "وكلكن",
    "وكله",
    "وكلها",
    "وكلهما",
    "وكلهن",
    "وكلي",
    "وكليكما",
    "وكليهما",
    "وكما",
    "وكمثل",
    "وكمثلك",
    "وكمثلكم",
    "وكمثلكما",
    "وكمثلكن",
    "وكمثلنا",
    "وكمثله",
    "وكمثلها",
    "وكمثلهم",
    "وكمثلهما",
    "وكمثلهن",
    "وكمثلي",
    "وكمن",
    "وكنحو",
    "وكنحوك",
    "وكنحوكم",
    "وكنحوكما",
    "وكنحوكن",
    "وكنحونا",
    "وكنحوه",
    "وكنحوها",
    "وكنحوهم",
    "وكنحوهما",
    "وكنحوهن",
    "وكنحوي",
    "وكنفس",
    "وكنفسك",
    "وكنفسكم",
    "وكنفسكما",
    "وكنفسكن",
    "وكنفسنا",
    "وكنفسه",
    "وكنفسها",
    "وكنفسهم",
    "وكنفسهما",
    "وكنفسهن",
    "وكنفسي",
    "وكهؤلاء",
    "وكهاته",
    "وكهاتي",
    "وكهاتين",
    "وكهذا",
    "وكهذه",
    "وكهذي",
    "وكهذين",
    "وكهم",
    "وكهما",
    "وكهن",
    "وكهناك",
    "وكهنالك",
    "وكهو",
    "وكهي",
    "وكي",
    "وكيت",
    "وكيفما",
    "ولآض",
    "ولأب",
    "ولأبك",
    "ولأبكم",
    "ولأبكما",
    "ولأبكن",
    "ولأبنا",
    "ولأبه",
    "ولأبها",
    "ولأبهم",
    "ولأبهما",
    "ولأبهن",
    "ولأبي",
    "ولأجمع",
    "ولأجمعك",
    "ولأجمعكم",
    "ولأجمعكما",
    "ولأجمعكن",
    "ولأجمعنا",
    "ولأجمعه",
    "ولأجمعها",
    "ولأجمعهم",
    "ولأجمعهما",
    "ولأجمعهن",
    "ولأجمعي",
    "ولأخ",
    "ولأخذ",
    "ولأخك",
    "ولأخكم",
    "ولأخكما",
    "ولأخكن",
    "ولأخنا",
    "ولأخه",
    "ولأخها",
    "ولأخهم",
    "ولأخهما",
    "ولأخهن",
    "ولأصبح",
    "ولأضحى",
    "ولأقبل",
    "ولأقل",
    "ولأقلك",
    "ولأقلكم",
    "ولأقلكما",
    "ولأقلكن",
    "ولأقلنا",
    "ولأقله",
    "ولأقلها",
    "ولأقلهم",
    "ولأقلهما",
    "ولأقلهن",
    "ولأقلي",
    "ولأكثر",
    "ولأكثرك",
    "ولأكثركم",
    "ولأكثركما",
    "ولأكثركن",
    "ولأكثرنا",
    "ولأكثره",
    "ولأكثرها",
    "ولأكثرهم",
    "ولأكثرهما",
    "ولأكثرهن",
    "ولأكثري",
    "ولأمام",
    "ولأمامك",
    "ولأمامكم",
    "ولأمامكما",
    "ولأمامكن",
    "ولأمامنا",
    "ولأمامه",
    "ولأمامها",
    "ولأمامهم",
    "ولأمامهما",
    "ولأمامهن",
    "ولأمامي",
    "ولأمسى",
    "ولأن",
    "ولأنا",
    "ولأنت",
    "ولأنتم",
    "ولأنتما",
    "ولأنتن",
    "ولأنشأ",
    "ولأنك",
    "ولأنكم",
    "ولأنكما",
    "ولأنكن",
    "ولأننا",
    "ولأنه",
    "ولأنها",
    "ولأنهم",
    "ولأنهما",
    "ولأنهن",
    "ولأني",
    "ولأوشك",
    "ولأولئك",
    "ولأولئكم",
    "ولأولاء",
    "ولأولالك",
    "ولأي",
    "ولأيك",
    "ولأيكم",
    "ولأيكما",
    "ولأيكن",
    "ولأينا",
    "ولأيه",
    "ولأيها",
    "ولأيهم",
    "ولأيهما",
    "ولأيهن",
    "ولأيي",
    "ولإلى",
    "ولإلي",
    "ولإليك",
    "ولإليكم",
    "ولإليكما",
    "ولإليكن",
    "ولإلينا",
    "ولإليه",
    "ولإليها",
    "ولإليهم",
    "ولإليهما",
    "ولإليهن",
    "ولإن",
    "ولإنك",
    "ولإنكم",
    "ولإنكما",
    "ولإنكن",
    "ولإننا",
    "ولإنه",
    "ولإنها",
    "ولإنهم",
    "ولإنهما",
    "ولإنهن",
    "ولإني",
    "ولإياك",
    "ولإياكم",
    "ولإياكما",
    "ولإياكن",
    "ولإيانا",
    "ولإياه",
    "ولإياها",
    "ولإياهم",
    "ولإياهما",
    "ولإياهن",
    "ولإياي",
    "ولابتدأ",
    "ولاخلولق",
    "ولارتد",
    "ولاستحال",
    "ولاسيما",
    "ولانبرى",
    "ولانقلب",
    "ولبات",
    "ولبعض",
    "ولبعضك",
    "ولبعضكم",
    "ولبعضكما",
    "ولبعضكن",
    "ولبعضنا",
    "ولبعضه",
    "ولبعضها",
    "ولبعضهم",
    "ولبعضهما",
    "ولبعضهن",
    "ولبعضي",
    "ولتانك",
    "ولتبدل",
    "ولتجاه",
    "ولتجاهك",
    "ولتجاهكم",
    "ولتجاهكما",
    "ولتجاهكن",
    "ولتجاهنا",
    "ولتجاهه",
    "ولتجاهها",
    "ولتجاههم",
    "ولتجاههما",
    "ولتجاههن",
    "ولتجاهي",
    "ولتحت",
    "ولتحتك",
    "ولتحتكم",
    "ولتحتكما",
    "ولتحتكن",
    "ولتحتنا",
    "ولتحته",
    "ولتحتها",
    "ولتحتهم",
    "ولتحتهما",
    "ولتحتهن",
    "ولتحتي",
    "ولتحول",
    "ولتلقاء",
    "ولتلقاءك",
    "ولتلقاءكم",
    "ولتلقاءكما",
    "ولتلقاءكن",
    "ولتلقاءنا",
    "ولتلقاءه",
    "ولتلقاءها",
    "ولتلقاءهم",
    "ولتلقاءهما",
    "ولتلقاءهن",
    "ولتلقاءي",
    "ولتلك",
    "ولتلكم",
    "ولتلكما",
    "ولته",
    "ولتي",
    "ولتين",
    "ولتينك",
    "ولجعل",
    "ولجميع",
    "ولجميعك",
    "ولجميعكم",
    "ولجميعكما",
    "ولجميعكن",
    "ولجميعنا",
    "ولجميعه",
    "ولجميعها",
    "ولجميعهم",
    "ولجميعهما",
    "ولجميعهن",
    "ولجميعي",
    "ولحار",
    "ولحرى",
    "ولحسب",
    "ولحسبك",
    "ولحسبكم",
    "ولحسبكما",
    "ولحسبكن",
    "ولحسبنا",
    "ولحسبه",
    "ولحسبها",
    "ولحسبهم",
    "ولحسبهما",
    "ولحسبهن",
    "ولحسبي",
    "ولحمك",
    "ولحمكم",
    "ولحمكما",
    "ولحمكن",
    "ولحمنا",
    "ولحمه",
    "ولحمها",
    "ولحمهم",
    "ولحمهما",
    "ولحمهن",
    "ولحمي",
    "ولحيث",
    "ولخلف",
    "ولخلفك",
    "ولخلفكم",
    "ولخلفكما",
    "ولخلفكن",
    "ولخلفنا",
    "ولخلفه",
    "ولخلفها",
    "ولخلفهم",
    "ولخلفهما",
    "ولخلفهن",
    "ولخلفي",
    "ولدن",
    "ولدنك",
    "ولدنكم",
    "ولدنكما",
    "ولدنكن",
    "ولدننا",
    "ولدنه",
    "ولدنها",
    "ولدنهما",
    "ولدنهن",
    "ولدني",
    "ولدى",
    "ولدي",
    "ولديك",
    "ولديكم",
    "ولديكما",
    "ولديكن",
    "ولديه",
    "ولديها",
    "ولديهم",
    "ولديهما",
    "ولديهن",
    "ولذا",
    "ولذات",
    "ولذاك",
    "ولذانك",
    "ولذلكم",
    "ولذلكما",
    "ولذلكن",
    "ولذه",
    "ولذواتي",
    "ولذين",
    "ولذينك",
    "ولراح",
    "ولرجع",
    "ولست",
    "ولستما",
    "ولستن",
    "ولسن",
    "ولسنا",
    "ولسوى",
    "ولسوي",
    "ولسويك",
    "ولسويكم",
    "ولسويكما",
    "ولسويكن",
    "ولسوينا",
    "ولسويه",
    "ولسويها",
    "ولسويهم",
    "ولسويهما",
    "ولسويهن",
    "ولشبه",
    "ولشبهك",
    "ولشبهكم",
    "ولشبهكما",
    "ولشبهكن",
    "ولشبهنا",
    "ولشبهه",
    "ولشبهها",
    "ولشبههم",
    "ولشبههما",
    "ولشبههن",
    "ولشبهي",
    "ولشرع",
    "ولشمال",
    "ولشمالك",
    "ولشمالكم",
    "ولشمالكما",
    "ولشمالكن",
    "ولشمالنا",
    "ولشماله",
    "ولشمالها",
    "ولشمالهم",
    "ولشمالهما",
    "ولشمالهن",
    "ولشمالي",
    "ولصار",
    "ولطالما",
    "ولطفق",
    "ولظل",
    "ولعاد",
    "ولعامة",
    "ولعامتك",
    "ولعامتكم",
    "ولعامتكما",
    "ولعامتكن",
    "ولعامتنا",
    "ولعامته",
    "ولعامتها",
    "ولعامتهم",
    "ولعامتهما",
    "ولعامتهن",
    "ولعامتي",
    "ولعسى",
    "ولعل",
    "ولعلق",
    "ولعلك",
    "ولعلكما",
    "ولعلكن",
    "ولعلنا",
    "ولعله",
    "ولعلها",
    "ولعلهما",
    "ولعلهن",
    "ولعلى",
    "ولعلي",
    "ولعليك",
    "ولعليكم",
    "ولعليكما",
    "ولعليكن",
    "ولعلينا",
    "ولعليه",
    "ولعليها",
    "ولعليهم",
    "ولعليهما",
    "ولعليهن",
    "ولعمر",
    "ولعمرك",
    "ولعمركم",
    "ولعمركما",
    "ولعمركن",
    "ولعمرنا",
    "ولعمره",
    "ولعمرها",
    "ولعمرهم",
    "ولعمرهما",
    "ولعمرهن",
    "ولعمري",
    "ولعن",
    "ولعنك",
    "ولعنكم",
    "ولعنكما",
    "ولعنكن",
    "ولعننا",
    "ولعنها",
    "ولعنهما",
    "ولعنهن",
    "ولعني",
    "ولعين",
    "ولعينك",
    "ولعينكم",
    "ولعينكما",
    "ولعينكن",
    "ولعيننا",
    "ولعينه",
    "ولعينها",
    "ولعينهم",
    "ولعينهما",
    "ولعينهن",
    "ولعيني",
    "ولغدا",
    "ولغير",
    "ولغيرك",
    "ولغيركم",
    "ولغيركما",
    "ولغيركن",
    "ولغيرنا",
    "ولغيره",
    "ولغيرها",
    "ولغيرهم",
    "ولغيرهما",
    "ولغيرهن",
    "ولغيري",
    "ولفو",
    "ولفوق",
    "ولفوقك",
    "ولفوقكم",
    "ولفوقكما",
    "ولفوقكن",
    "ولفوقنا",
    "ولفوقه",
    "ولفوقها",
    "ولفوقهم",
    "ولفوقهما",
    "ولفوقهن",
    "ولفوقي",
    "ولفوك",
    "ولفوكم",
    "ولفوكما",
    "ولفوكن",
    "ولفونا",
    "ولفوه",
    "ولفوها",
    "ولفوهم",
    "ولفوهما",
    "ولفوهن",
    "ولفوي",
    "ولفي",
    "ولفيك",
    "ولفيكم",
    "ولفيكما",
    "ولفيكن",
    "ولفينا",
    "ولفيه",
    "ولفيها",
    "ولفيهم",
    "ولفيهما",
    "ولفيهن",
    "ولفيي",
    "ولقام",
    "ولقلما",
    "ولكأن",
    "ولكأنك",
    "ولكأنكم",
    "ولكأنكما",
    "ولكأنكن",
    "ولكأنما",
    "ولكأننا",
    "ولكأنه",
    "ولكأنها",
    "ولكأنهم",
    "ولكأنهما",
    "ولكأنهن",
    "ولكأني",
    "ولكاد",
    "ولكان",
    "ولكرب",
    "ولكلتا",
    "ولكلك",
    "ولكلكم",
    "ولكلكما",
    "ولكلكن",
    "ولكلنا",
    "ولكله",
    "ولكلها",
    "ولكلهم",
    "ولكلهما",
    "ولكلهن",
    "ولكلي",
    "ولكليكما",
    "ولكليهما",
    "ولكما",
    "ولكنك",
    "ولكنكما",
    "ولكنكن",
    "ولكنما",
    "ولكننا",
    "ولكنها",
    "ولكنهما",
    "ولكنهن",
    "ولكي",
    "ولكيلا",
    "وللألاء",
    "وللألى",
    "وللائي",
    "وللاتي",
    "وللتي",
    "وللتيا",
    "وللتين",
    "وللذي",
    "وللواتي",
    "ولمثل",
    "ولمثلك",
    "ولمثلكم",
    "ولمثلكما",
    "ولمثلكن",
    "ولمثلنا",
    "ولمثله",
    "ولمثلها",
    "ولمثلهم",
    "ولمثلهما",
    "ولمثلهن",
    "ولمثلي",
    "ولمع",
    "ولمعك",
    "ولمعكم",
    "ولمعكما",
    "ولمعكن",
    "ولمعنا",
    "ولمعه",
    "ولمعها",
    "ولمعهم",
    "ولمعهما",
    "ولمعهن",
    "ولمعي",
    "ولمنك",
    "ولمنكم",
    "ولمنكما",
    "ولمنكن",
    "ولمننا",
    "ولمنه",
    "ولمنها",
    "ولمنهم",
    "ولمنهما",
    "ولمنهن",
    "ولمني",
    "ولنحن",
    "ولنحو",
    "ولنحوك",
    "ولنحوكم",
    "ولنحوكما",
    "ولنحوكن",
    "ولنحونا",
    "ولنحوه",
    "ولنحوها",
    "ولنحوهم",
    "ولنحوهما",
    "ولنحوهن",
    "ولنحوي",
    "ولنفس",
    "ولنفسك",
    "ولنفسكم",
    "ولنفسكما",
    "ولنفسكن",
    "ولنفسنا",
    "ولنفسه",
    "ولنفسها",
    "ولنفسهم",
    "ولنفسهما",
    "ولنفسهن",
    "ولنفسي",
    "ولهؤلاء",
    "ولهاته",
    "ولهاتي",
    "ولهاتين",
    "ولهب",
    "ولهذه",
    "ولهذي",
    "ولهذين",
    "ولهما",
    "ولهناك",
    "ولهنالك",
    "ولهي",
    "ولوما",
    "وليت",
    "وليتك",
    "وليتكم",
    "وليتكما",
    "وليتكن",
    "وليتنا",
    "وليته",
    "وليتها",
    "وليتهم",
    "وليتهما",
    "وليتهن",
    "وليتي",
    "وليسا",
    "وليستا",
    "وليسوا",
    "وليمين",
    "وليمينك",
    "وليمينكم",
    "وليمينكما",
    "وليمينكن",
    "وليميننا",
    "وليمينه",
    "وليمينها",
    "وليمينهم",
    "وليمينهما",
    "وليمينهن",
    "وليميني",
    "وماانفك",
    "ومابرح",
    "ومادام",
    "ومازال",
    "ومافتئ",
    "ومتى",
    "ومثلك",
    "ومثلكم",
    "ومثلكما",
    "ومثلكن",
    "ومثلنا",
    "ومثلها",
    "ومثلهما",
    "ومثلهن",
    "ومثلي",
    "ومذ",
    "ومرة",
    "ومساء",
    "ومع",
    "ومعاذ",
    "ومعاذك",
    "ومعاذكم",
    "ومعاذكما",
    "ومعاذكن",
    "ومعاذنا",
    "ومعاذه",
    "ومعاذها",
    "ومعاذهم",
    "ومعاذهما",
    "ومعاذهن",
    "ومعاذي",
    "ومعك",
    "ومعكم",
    "ومعكما",
    "ومعكن",
    "ومعنا",
    "ومعه",
    "ومعها",
    "ومعهم",
    "ومعهما",
    "ومعهن",
    "ومعي",
    "ومكانك",
    "ومكانكم",
    "ومكانكما",
    "ومكانكن",
    "ومنذ",
    "ومنكما",
    "ومنكن",
    "ومننا",
    "ومنهما",
    "ومنهن",
    "ومني",
    "ومهما",
    "ونحو",
    "ونحوك",
    "ونحوكم",
    "ونحوكما",
    "ونحوكن",
    "ونحونا",
    "ونحوه",
    "ونحوها",
    "ونحوهم",
    "ونحوهما",
    "ونحوهن",
    "ونحوي",
    "ونعما",
    "ونفسك",
    "ونفسكم",
    "ونفسكما",
    "ونفسكن",
    "ونفسنا",
    "ونفسه",
    "ونفسها",
    "ونفسهم",
    "ونفسهما",
    "ونفسهن",
    "ونفسي",
    "وهاؤم",
    "وهاتان",
    "وهاته",
    "وهاتي",
    "وهاتين",
    "وهاهنا",
    "وهذان",
    "وهذي",
    "وهذين",
    "وهكذا",
    "وهلا",
    "وهنالك",
    "وهيا",
    "وهيت",
    "وي",
    "ويكأنك",
    "ويكأنكم",
    "ويكأنكما",
    "ويكأنكن",
    "ويكأننا",
    "ويكأنها",
    "ويكأنهم",
    "ويكأنهما",
    "ويكأنهن",
    "ويكأني",
    "ويمين",
    "ويمينك",
    "ويمينكم",
    "ويمينكما",
    "ويمينكن",
    "ويميننا",
    "ويمينه",
    "ويمينها",
    "ويمينهم",
    "ويمينهما",
    "ويمينهن",
    "ويميني",
    "يمينكم",
    "يمينكما",
    "يمينكن",
    "يميننا",
    "يمينه",
    "يمينها",
    "يمينهم",
    "يمينهما",
    "يمينهن",
    "آب",
    "آبار",
    "آباض",
    "آباط",
    "آبد",
    "آبدة",
    "آبق",
    "آتشجي",
    "آتن",
    "آتى",
    "آثاره",
    "آثاري",
    "آثام",
    "آثر",
    "آجال",
    "آجام",
    "آجر",
    "آجل",
    "آجلا",
    "آجله",
    "آجن",
    "آح",
    "آحانية",
    "آحد",
    "آحين",
    "آخرا",
    "آخرة",
    "آخرھم",
    "آخرھما",
    "آد",
    "آدب",
    "آدمية",
    "آذانا",
    "آراءه",
    "آراب",
    "آرام",
    "آرامى",
    "آرى",
    "آرية",
    "آزال",
    "آس",
    "آساد",
    "آسر",
    "آصار",
    "آصال",
    "آصرة",
    "آغا",
    "آفاق",
    "آفاقى",
    "آفاقي",
    "آفة",
    "آفرين",
    "آفل",
    "آق",
    "آكام",
    "آكلة",
    "آلاتى",
    "آلاتية",
    "آلام",
    "آلامه",
    "آلاى",
    "آله",
    "آلو",
    "آلى",
    "آلھة",
    "آلھي",
    "آم",
    "آماق",
    "آمال",
    "آمت",
    "آمر",
    "آمل",
    "آنئذ",
    "آنا",
    "آناف",
    "آنذاك",
    "آنف",
    "آنك",
    "آنيسون",
    "آونة",
    "آھ",
    "آھا",
    "آھات",
    "آھة",
    "آھل",
    "أبھته",
    "أثآر",
    "أثاف",
    "أثفية",
    "أثير",
    "أخاذ",
    "أخباره",
    "أخطأه",
    "أخلاقية",
    "أخماسا",
    "أخمص",
    "أخيذ",
    "أخيذة",
    "أدرية",
    "أساورة",
    "أسوار",
    "أسوان",
    "أعطافه",
    "أعماله",
    "أكيدا",
    "ألحاد",
    "أماله",
    "أنفاسه",
    "أولوية",
    "أوناش",
    "أھجأ",
    "أھداف",
    "أھدب",
    "أھمية",
    "إذاك",
    "إلحاد",
    "إمضاء",
    "إنشائي",
    "إيثار",
    "إيماء",
    "إيماءة",
    "ئمجير",
    "ائتكل",
    "ائتلاف",
    "ائتلافي",
    "ائتلافية",
    "ائتمار",
    "ائتمان",
    "ائتمر",
    "ائتناس",
    "ائمة",
    "اانوار",
    "اب",
    "ابؤس",
    "ابا",
    "اباء",
    "اباءة",
    "اباة",
    "اباتي",
    "اباحة",
    "اباحى",
    "اباحية",
    "ابادة",
    "اباديد",
    "اباريق",
    "ابازم",
    "ابازن",
    "ابازير",
    "اباسيط",
    "اباطح",
    "اباطرة",
    "اباطيل",
    "اباعد",
    "اباعر",
    "اباق",
    "ابالة",
    "ابالسة",
    "ابالى",
    "ابالية",
    "ابانة",
    "ابانه",
    "اباه",
    "اباھيم",
    "ابت",
    "ابتاع",
    "ابتداء",
    "ابتدائية",
    "ابتدرھا",
    "ابتذل",
    "ابتزاز",
    "ابتسام",
    "ابتشاك",
    "ابتع",
    "ابتكار",
    "ابتكارات",
    "ابتلاء",
    "ابتلع",
    "ابتياع",
    "ابتھاج",
    "ابتھار",
    "ابتھال",
    "ابجد",
    "ابجدي",
    "ابجديات",
    "ابجر",
    "ابح",
    "ابحاث",
    "ابحار",
    "ابحر",
    "ابخر",
    "ابخرة",
    "ابخق",
    "ابد",
    "ابدا",
    "ابداء",
    "ابداع",
    "ابداعي",
    "ابداعية",
    "ابدال",
    "ابدان",
    "ابدع",
    "ابدن",
    "ابدى",
    "ابدي",
    "ابر",
    "ابرأ",
    "ابراء",
    "ابراج",
    "ابراد",
    "ابرار",
    "ابراز",
    "ابراش",
    "ابراص",
    "ابرام",
    "ابراميس",
    "ابراھيم",
    "ابرة",
    "ابرز",
    "ابرش",
    "ابرص",
    "ابرك",
    "ابرميس",
    "ابروشية",
    "ابرياء",
    "ابريز",
    "ابريسم",
    "ابريشية",
    "ابريق",
    "ابريل",
    "ابزار",
    "ابزاز",
    "ابزن",
    "ابزيم",
    "ابسط",
    "ابسطة",
    "ابسقوبس",
    "ابسوطة",
    "ابشع",
    "ابصار",
    "ابصر",
    "ابض",
    "ابضاع",
    "ابط",
    "ابطأ",
    "ابطاء",
    "ابطال",
    "ابطح",
    "ابطن",
    "ابطنة",
    "ابعاد",
    "ابعادية",
    "ابعد",
    "ابعرة",
    "ابغال",
    "ابق",
    "ابقاء",
    "ابقال",
    "ابقع",
    "ابقى",
    "ابكار",
    "ابكر",
    "ابكم",
    "ابل",
    "ابلاغ",
    "ابلال",
    "ابلام",
    "ابلج",
    "ابلد",
    "ابلطة",
    "ابلغ",
    "ابلق",
    "ابله",
    "ابلى",
    "ابلي",
    "ابليز",
    "ابليس",
    "ابناء",
    "ابنوس",
    "ابنياء",
    "ابنية",
    "ابه",
    "ابواب",
    "ابوابھا",
    "ابواز",
    "ابواش",
    "ابواص",
    "ابواع",
    "ابواق",
    "ابوال",
    "ابوام",
    "ابوة",
    "ابود",
    "ابونا",
    "ابونيت",
    "ابونيه",
    "ابوي",
    "ابويه",
    "ابويھا",
    "ابى",
    "ابي",
    "ابيات",
    "ابيب",
    "ابيضاض",
    "ابيعه",
    "ابيقوري",
    "ابين",
    "ابيه",
    "ابيھم",
    "ابھاء",
    "ابھام",
    "ابھة",
    "ابھر",
    "ابھل",
    "ابھى",
    "ات",
    "اتأد",
    "اتأر",
    "اتاتن",
    "اتان",
    "اتاه",
    "اتاوة",
    "اتاوى",
    "اتباعا",
    "اتباعى",
    "اتباعية",
    "اتجار",
    "اتجه",
    "اتحادي",
    "اتحادية",
    "اتخم",
    "اتراب",
    "اتراح",
    "اتراني",
    "اتراھا",
    "اتربة",
    "اترج",
    "اترس",
    "اترنج",
    "اتزان",
    "اتزر",
    "اتساع",
    "اتساق",
    "اتسخ",
    "اتسم",
    "اتشاح",
    "اتصاله",
    "اتصف",
    "اتصل",
    "اتصلت",
    "اتضاح",
    "اتضاع",
    "اتضح",
    "اتضع",
    "اتعاب",
    "اتعب",
    "اتعتع",
    "اتعد",
    "اتعظ",
    "اتفاقا",
    "اتفاقي",
    "اتفاقية",
    "اتقاد",
    "اتقان",
    "اتقد",
    "اتقن",
    "اتقياء",
    "اتكأ",
    "اتكاء",
    "اتكال",
    "اتكل",
    "اتلاف",
    "اتلال",
    "اتلام",
    "اتم",
    "اتمام",
    "اتماما",
    "اتن",
    "اتوبيس",
    "اتوماتيكي",
    "اتومبيل",
    "اتوموبيل",
    "اتون",
    "اتى",
    "اتياس",
    "اتيان",
    "اتيح",
    "اتيس",
    "اتھام",
    "اتھامية",
    "اتھم",
    "اث",
    "اثأر",
    "اثاث",
    "اثاثة",
    "اثار",
    "اثبات",
    "اثباتى",
    "اثبت",
    "اثبته",
    "اثخن",
    "اثخنه",
    "اثداء",
    "اثر",
    "اثرا",
    "اثرة",
    "اثره",
    "اثري",
    "اثرياء",
    "اثرية",
    "اثفار",
    "اثفية",
    "اثقاب",
    "اثقال",
    "اثقل",
    "اثكل",
    "اثل",
    "اثلاث",
    "اثلج",
    "اثلجت",
    "اثم",
    "اثماء",
    "اثمار",
    "اثمان",
    "اثمة",
    "اثمد",
    "اثمن",
    "اثمنة",
    "اثنتان",
    "اثنى",
    "اثواب",
    "اثول",
    "اثير",
    "اثيل",
    "اثيم",
    "اثينا",
    "اثيوبي",
    "اثيوبيا",
    "اج",
    "اجاب",
    "اجابة",
    "اجاج",
    "اجاحة",
    "اجاد",
    "اجارة",
    "اجاص",
    "اجاطه",
    "اجاعة",
    "اجال",
    "اجانب",
    "اجاود",
    "اجاويد",
    "اجباب",
    "اجباح",
    "اجبار",
    "اجبارى",
    "اجبال",
    "اجبح",
    "اجبن",
    "اجبنة",
    "اجبية",
    "اجتذاب",
    "اجتر",
    "اجترح",
    "اجتلاء",
    "اجتماعى",
    "اجتماعية",
    "اجتناب",
    "اجتياح",
    "اجتياز",
    "اجتھاد",
    "اجثاث",
    "اجحار",
    "اجحاش",
    "اجحاف",
    "اجد",
    "اجداث",
    "اجدب",
    "اجدر",
    "اجدع",
    "اجدى",
    "اجذار",
    "اجذاع",
    "اجذال",
    "اجذام",
    "اجر",
    "اجراء",
    "اجراح",
    "اجراد",
    "اجراس",
    "اجراف",
    "اجرام",
    "اجرامى",
    "اجران",
    "اجراه",
    "اجرب",
    "اجربة",
    "اجرة",
    "اجرضه",
    "اجرودى",
    "اجرى",
    "اجرياء",
    "اجرية",
    "اجزاء",
    "اجزائى",
    "اجزائية",
    "اجزاجى",
    "اجزاجي",
    "اجزاخانة",
    "اجزل",
    "اجساد",
    "اجسام",
    "اجسر",
    "اجسم",
    "اجش",
    "اجعال",
    "اجعد",
    "اجفان",
    "اجفانه",
    "اجفى",
    "اجل",
    "اجلا",
    "اجلاء",
    "اجلاد",
    "اجلاف",
    "اجلال",
    "اجلام",
    "اجلة",
    "اجلح",
    "اجله",
    "اجلى",
    "اجم",
    "اجماع",
    "اجماعى",
    "اجمال",
    "اجمالا",
    "اجماله",
    "اجمالى",
    "اجمالية",
    "اجمة",
    "اجمع",
    "اجمعوا",
    "اجمل",
    "اجمله",
    "اجمية",
    "اجناب",
    "اجناد",
    "اجناس",
    "اجنان",
    "اجنبي",
    "اجنبية",
    "اجنة",
    "اجنح",
    "اجنحة",
    "اجندة",
    "اجنون",
    "اجو",
    "اجواء",
    "اجواخ",
    "اجواد",
    "اجواز",
    "اجواف",
    "اجواق",
    "اجوان",
    "اجوبة",
    "اجود",
    "اجور",
    "اجوف",
    "اجياد",
    "اجياف",
    "اجيال",
    "اجيج",
    "اجير",
    "اجيرة",
    "اجھاد",
    "اجھاش",
    "اجھاض",
    "اجھد",
    "اجھر",
    "اجھزة",
    "اجھش",
    "اجھضت",
    "اح",
    "احابيل",
    "احاج",
    "احاجى",
    "احاديث",
    "احاسن",
    "احاسيس",
    "احاط",
    "احاطة",
    "احافير",
    "احالة",
    "احاييل",
    "احايين",
    "احب",
    "احباء",
    "احباب",
    "احبار",
    "احباس",
    "احباش",
    "احباط",
    "احبال",
    "احبة",
    "احبل",
    "احبه",
    "احبولة",
    "احتباس",
    "احتجاب",
    "احتجاج",
    "احتدام",
    "احتدم",
    "احتذاء",
    "احتراب",
    "احتراز",
    "احتراس",
    "احتراسا",
    "احتراف",
    "احترم",
    "احتساب",
    "احتسب",
    "احتشاد",
    "احتضار",
    "احتفاء",
    "احتفاظ",
    "احتفال",
    "احتفظ",
    "احتقار",
    "احتقان",
    "احتقن",
    "احتك",
    "احتكاك",
    "احتل",
    "احتلال",
    "احتلالي",
    "احتماء",
    "احتياج",
    "احتياط",
    "احتياطى",
    "احتياطي",
    "احتياطية",
    "احتيال",
    "احتيالي",
    "احجاء",
    "احجار",
    "احجاف",
    "احجال",
    "احجام",
    "احجبة",
    "احجة",
    "احجن",
    "احجى",
    "احجية",
    "احدا",
    "احداء",
    "احداب",
    "احداث",
    "احداثيات",
    "احداج",
    "احداق",
    "احدب",
    "احدة",
    "احدث",
    "احدق",
    "احدوة",
    "احدوثة",
    "احدودب",
    "احدى",
    "احدي",
    "احدية",
    "احدھم",
    "احذية",
    "احر",
    "احراج",
    "احرار",
    "احراش",
    "احراق",
    "احرام",
    "احرز",
    "احرش",
    "احرص",
    "احرق",
    "احرمة",
    "احرى",
    "احرياء",
    "احزاب",
    "احزابه",
    "احزابھم",
    "احزان",
    "احزم",
    "احزمة",
    "احساب",
    "احساس",
    "احساسات",
    "احساسية",
    "احسان",
    "احسن",
    "احسنت",
    "احسنه",
    "احسية",
    "احشاء",
    "احصاء",
    "احصائية",
    "احصان",
    "احصنة",
    "احضار",
    "احضان",
    "احضانه",
    "احضانھا",
    "احضة",
    "احضره",
    "احط",
    "احطاب",
    "احطياطية",
    "احفاد",
    "احفور",
    "احق",
    "احقا",
    "احقاء",
    "احقاب",
    "احقاد",
    "احقاق",
    "احقاقا",
    "احقر",
    "احقية",
    "احلاء",
    "احلاس",
    "احلاف",
    "احلاق",
    "احلام",
    "احلس",
    "احله",
    "احلولك",
    "احليل",
    "احم",
    "احماء",
    "احماض",
    "احمال",
    "احمد",
    "احمرة",
    "احمس",
    "احن",
    "احناء",
    "احنائھا",
    "احناث",
    "احناش",
    "احناك",
    "احنة",
    "احنف",
    "احوات",
    "احوار",
    "احواز",
    "احواش",
    "احواض",
    "احوال",
    "احوج",
    "احوجه",
    "احوص",
    "احول",
    "احيا",
    "احياء",
    "احيائي",
    "احيائية",
    "احياز",
    "احيان",
    "احيانا",
    "احيت",
    "احيل",
    "احيلاه",
    "احيلت",
    "احيلى",
    "احيلي",
    "احين",
    "احيى",
    "اخ",
    "اخاء",
    "اخاديد",
    "اخادير",
    "اخاديع",
    "اخافة",
    "اخامص",
    "اخاوة",
    "اخاوين",
    "اخاير",
    "اخايل",
    "اخبئة",
    "اخباث",
    "اخبار",
    "اخبارا",
    "اخباره",
    "اخباري",
    "اخبارية",
    "اخباز",
    "اخبث",
    "اخبل",
    "اخبية",
    "اخت",
    "اختار",
    "اختام",
    "اختان",
    "اختباري",
    "اختبارية",
    "اختبال",
    "اختتام",
    "اختراع",
    "اختزال",
    "اختص",
    "اختصاص",
    "اختطاف",
    "اختفاء",
    "اختفى",
    "اختلاء",
    "اختلاجة",
    "اختلاط",
    "اختلافھا",
    "اختلال",
    "اختلت",
    "اختلج",
    "اختمار",
    "اختناق",
    "اختيارا",
    "اختياره",
    "اختياري",
    "اختھا",
    "اخدار",
    "اخداش",
    "اخدان",
    "اخدعه",
    "اخدود",
    "اخذتني",
    "اخذه",
    "اخرات",
    "اخراج",
    "اخربة",
    "اخرت",
    "اخرجه",
    "اخرس",
    "اخرفة",
    "اخرق",
    "اخروي",
    "اخريات",
    "اخسأ",
    "اخساء",
    "اخشاب",
    "اخشن",
    "اخشوشن",
    "اخشى",
    "اخص",
    "اخصاء",
    "اخصائي",
    "اخصاب",
    "اخصاص",
    "اخصام",
    "اخضاع",
    "اخضر",
    "اخضوضب",
    "اخضوضر",
    "اخطار",
    "اخطبة",
    "اخطبوط",
    "اخطر",
    "اخف",
    "اخفاء",
    "اخفاف",
    "اخفاق",
    "اخفش",
    "اخفض",
    "اخفه",
    "اخفى",
    "اخلاء",
    "اخلاب",
    "اخلاد",
    "اخلاص",
    "اخلاط",
    "اخلاف",
    "اخلاق",
    "اخلاقي",
    "اخلال",
    "اخلة",
    "اخلص",
    "اخلق",
    "اخلى",
    "اخلياء",
    "اخماد",
    "اخماسا",
    "اخماسه",
    "اخمام",
    "اخمرة",
    "اخمص",
    "اخن",
    "اخنان",
    "اخنس",
    "اخو",
    "اخوات",
    "اخوار",
    "اخوال",
    "اخوان",
    "اخوة",
    "اخور",
    "اخوص",
    "اخوف",
    "اخونة",
    "اخوه",
    "اخوي",
    "اخوية",
    "اخيار",
    "اخياط",
    "اخير",
    "اخيرا",
    "اخيل",
    "اخيلة",
    "اد",
    "ادأب",
    "ادؤب",
    "اداء",
    "اداة",
    "ادار",
    "اداري",
    "اداريا",
    "ادارية",
    "ادام",
    "ادان",
    "ادانة",
    "ادب",
    "ادباء",
    "ادباب",
    "ادبار",
    "ادبخانة",
    "ادبي",
    "ادبيا",
    "ادبيات",
    "ادجن",
    "ادخار",
    "ادخال",
    "ادخر",
    "ادخلت",
    "ادخله",
    "ادخنة",
    "ادراج",
    "ادراجه",
    "ادراع",
    "ادراك",
    "ادران",
    "ادرة",
    "ادرد",
    "ادرع",
    "ادرك",
    "ادركه",
    "ادرنة",
    "ادرى",
    "ادري",
    "ادسم",
    "ادعج",
    "ادعم",
    "ادعياء",
    "ادعية",
    "ادغال",
    "ادغشت",
    "ادغم",
    "ادفأ",
    "ادق",
    "ادقاع",
    "ادقة",
    "ادكر",
    "ادكن",
    "ادل",
    "ادلاء",
    "ادلة",
    "ادلى",
    "ادلھمام",
    "ادم",
    "ادماج",
    "ادماس",
    "ادمان",
    "ادمغة",
    "ادنأ",
    "ادناء",
    "ادناس",
    "ادناف",
    "ادناه",
    "ادنون",
    "ادنى",
    "ادنياء",
    "ادواء",
    "ادوات",
    "ادوار",
    "ادواوت",
    "ادور",
    "ادوية",
    "ادى",
    "اديار",
    "ادياك",
    "اديان",
    "اديب",
    "اديبة",
    "اديرة",
    "اديل",
    "اديم",
    "ادھان",
    "ادھر",
    "ادھم",
    "ادھنة",
    "ادھى",
    "اذ",
    "اذا",
    "اذاب",
    "اذابة",
    "اذاة",
    "اذاع",
    "اذاعة",
    "اذان",
    "اذاية",
    "اذبة",
    "اذحال",
    "اذخار",
    "اذرت",
    "اذرع",
    "اذعان",
    "اذقان",
    "اذكى",
    "اذكياء",
    "اذلاء",
    "اذلال",
    "اذلة",
    "اذلف",
    "اذمة",
    "اذنه",
    "اذنية",
    "اذنيه",
    "اذواق",
    "اذون",
    "اذونات",
    "اذى",
    "اذيال",
    "اذية",
    "اذھان",
    "اذھب",
    "ارآد",
    "ارآم",
    "ارأف",
    "ارؤس",
    "ارائك",
    "اراتقة",
    "اراتيقي",
    "اراتيكي",
    "اراجيح",
    "اراجيز",
    "اراجيف",
    "اراح",
    "اراخنة",
    "اراد",
    "ارادب",
    "ارادة",
    "ارادته",
    "اراده",
    "ارادي",
    "اراض",
    "اراضون",
    "اراضى",
    "اراغن",
    "اراغيط",
    "اراق",
    "اراقة",
    "اراكيل",
    "ارام",
    "ارامل",
    "اراملة",
    "ارانب",
    "ارانيك",
    "اراه",
    "اراھط",
    "اراھيط",
    "ارب",
    "اربا",
    "ارباء",
    "ارباب",
    "ارباح",
    "ارباض",
    "ارباع",
    "ارباق",
    "اربة",
    "اربح",
    "اربطة",
    "اربع",
    "اربعاء",
    "اربعة",
    "اربعون",
    "اربيل",
    "ارت",
    "ارتأى",
    "ارتاح",
    "ارتال",
    "ارتباط",
    "ارتباك",
    "ارتج",
    "ارتجاج",
    "ارتجاع",
    "ارتجاعي",
    "ارتجال",
    "ارتحال",
    "ارتحل",
    "ارتخاء",
    "ارترزاق",
    "ارتسام",
    "ارتشاء",
    "ارتشاح",
    "ارتضخ",
    "ارتعاش",
    "ارتعدت",
    "ارتفاق",
    "ارتقاء",
    "ارتقائه",
    "ارتقاب",
    "ارتكاز",
    "ارتمس",
    "ارتمى",
    "ارتوازي",
    "ارتياب",
    "ارتياح",
    "ارتياع",
    "ارتھن",
    "ارث",
    "ارثة",
    "ارثوذكسي",
    "ارج",
    "ارجاء",
    "ارجائه",
    "ارجاز",
    "ارجاس",
    "ارجاع",
    "ارجاف",
    "ارجال",
    "ارجح",
    "ارجحية",
    "ارجف",
    "ارجل",
    "ارجوان",
    "ارجوحة",
    "ارجوز",
    "ارجوزة",
    "ارحاء",
    "ارحام",
    "ارحية",
    "ارخ",
    "ارخبيل",
    "ارخن",
    "ارد",
    "اردأ",
    "ارداء",
    "ارداف",
    "اردان",
    "اردب",
    "اردبة",
    "اردن",
    "اردني",
    "اردواز",
    "اردياء",
    "اردية",
    "ارذال",
    "ارز",
    "ارزاء",
    "ارزاق",
    "ارزق",
    "ارزيز",
    "ارس",
    "ارساغ",
    "ارسال",
    "ارسالا",
    "ارسالية",
    "ارسان",
    "ارستقراطي",
    "ارستقراطية",
    "ارسخ",
    "ارسطو",
    "ارسغ",
    "ارسل",
    "ارسن",
    "ارسنة",
    "ارش",
    "ارشاد",
    "ارشادات",
    "ارشادي",
    "ارشيدوق",
    "ارشيدوقة",
    "ارصاد",
    "ارصدة",
    "ارصفة",
    "ارض",
    "ارضروم",
    "ارضعني",
    "ارضى",
    "ارضي",
    "ارضياء",
    "ارضية",
    "ارط",
    "ارطاب",
    "ارطال",
    "ارطة",
    "ارطقة",
    "ارعاب",
    "ارعن",
    "ارعنى",
    "ارعوى",
    "ارعى",
    "ارعيته",
    "ارغاطة",
    "ارغام",
    "ارغفة",
    "ارغن",
    "ارغى",
    "ارفاد",
    "ارفاض",
    "ارفاق",
    "ارفع",
    "ارق",
    "ارقاء",
    "ارقام",
    "ارقش",
    "ارقط",
    "ارقعة",
    "ارقى",
    "اركان",
    "اركبه",
    "اركزة",
    "اركيلة",
    "ارم",
    "ارماث",
    "ارماح",
    "ارماس",
    "ارماق",
    "ارماك",
    "ارمد",
    "ارمدة",
    "ارمل",
    "ارملة",
    "ارمن",
    "ارمنى",
    "ارمني",
    "ارمينيا",
    "ارناوطي",
    "ارنب",
    "ارنبة",
    "ارنيك",
    "اروائي",
    "ارواب",
    "ارواث",
    "ارواح",
    "ارواق",
    "اروام",
    "اروبا",
    "اروبي",
    "اروج",
    "اروح",
    "اروع",
    "اروغ",
    "اروقة",
    "ارول",
    "ارومة",
    "ارى",
    "ارياح",
    "ارياش",
    "ارياف",
    "ارياق",
    "اريب",
    "اريج",
    "اريح",
    "اريحا",
    "اريحي",
    "اريحية",
    "اريس",
    "اريكة",
    "ارھاب",
    "ارھابي",
    "ارھاط",
    "ارھاف",
    "ارھاق",
    "ارھط",
    "ارھف",
    "ارھقه",
    "از",
    "ازاء",
    "ازاح",
    "ازاحة",
    "ازار",
    "ازاغ",
    "ازاميل",
    "ازاھر",
    "ازاھير",
    "ازب",
    "ازباب",
    "ازباد",
    "ازج",
    "ازجال",
    "ازجى",
    "ازخم",
    "ازداد",
    "ازدان",
    "ازدجر",
    "ازدحام",
    "ازدحم",
    "ازدراء",
    "ازدرد",
    "ازدرع",
    "ازدرى",
    "ازدقم",
    "ازدلف",
    "ازدواج",
    "ازدوج",
    "ازدھاء",
    "ازدھار",
    "ازدھر",
    "ازدھف",
    "ازدھى",
    "ازر",
    "ازراء",
    "ازرار",
    "ازرقاق",
    "ازره",
    "ازعاج",
    "ازعر",
    "ازغب",
    "ازف",
    "ازق",
    "ازقاق",
    "ازقة",
    "ازكى",
    "ازكياء",
    "ازل",
    "ازلام",
    "ازلقه",
    "ازلي",
    "ازلية",
    "ازم",
    "ازمان",
    "ازمة",
    "ازمنة",
    "ازمير",
    "ازميل",
    "ازمھر",
    "ازند",
    "ازواج",
    "ازواد",
    "ازوال",
    "ازوام",
    "ازوت",
    "ازوتي",
    "ازودة",
    "ازور",
    "ازورار",
    "ازوف",
    "ازى",
    "ازياء",
    "ازياح",
    "ازيار",
    "ازياق",
    "ازيتون",
    "ازيد",
    "ازيز",
    "ازيف",
    "ازھار",
    "ازھر",
    "ازھرى",
    "ازھق",
    "ازھى",
    "اس",
    "اسآر",
    "اسأله",
    "اسئلة",
    "اسا",
    "اسابيع",
    "اساتذة",
    "اسار",
    "اساره",
    "اسارى",
    "اسارير",
    "اساس",
    "اساسا",
    "اساسي",
    "اساسيات",
    "اساسية",
    "اساطير",
    "اساطيل",
    "اساطين",
    "اسافل",
    "اسافين",
    "اساق",
    "اساقف",
    "اساقفة",
    "اساكفة",
    "اساكل",
    "اسال",
    "اسالة",
    "اساليب",
    "اسام",
    "اسانيد",
    "اساور",
    "اساورة",
    "اسباب",
    "اسبابه",
    "اسباط",
    "اسباع",
    "اسباق",
    "اسبانخ",
    "اسباني",
    "اسبانيا",
    "اسبتة",
    "اسبخة",
    "اسبداج",
    "اسبرتو",
    "اسبع",
    "اسبغ",
    "اسبقية",
    "اسبلة",
    "اسبليطة",
    "اسبوع",
    "اسبوعي",
    "اسبوعيا",
    "اسبوعية",
    "اسبيداج",
    "است",
    "استأثر",
    "استأصل",
    "استئثار",
    "استئجار",
    "استئمارة",
    "استئمان",
    "استئناف",
    "استئنافا",
    "استئنافى",
    "استئنافيا",
    "استئنافية",
    "استاتيكي",
    "استاذية",
    "استار",
    "استانبول",
    "استانبولي",
    "استانبولية",
    "استاه",
    "استباح",
    "استباحة",
    "استبداد",
    "استبداديات",
    "استبراء",
    "استبرق",
    "استبسال",
    "استبشر",
    "استبقاء",
    "استبوال",
    "استتب",
    "استتباب",
    "استتمام",
    "استثار",
    "استثقل",
    "استثمار",
    "استثناء",
    "استثنائى",
    "استثنائيا",
    "استثنائية",
    "استجاب",
    "استجابة",
    "استجار",
    "استجلاء",
    "استجلاب",
    "استجماتم",
    "استجواب",
    "استجوب",
    "استحداث",
    "استحسان",
    "استحضار",
    "استحكام",
    "استحكامات",
    "استحمام",
    "استحى",
    "استحيا",
    "استحين",
    "استخار",
    "استختبار",
    "استخذاء",
    "استخراج",
    "استخفاف",
    "استخلاص",
    "استخلص",
    "استخون",
    "استدارة",
    "استدانة",
    "استدر",
    "استدراج",
    "استدراك",
    "استدلال",
    "استذكار",
    "استراتيجي",
    "استراح",
    "استرالي",
    "استرجاع",
    "استرجع",
    "استرحام",
    "استرخاء",
    "استرد",
    "استرداد",
    "استرسال",
    "استرضاء",
    "استرضائي",
    "استرليني",
    "استرواح",
    "استروح",
    "استزادة",
    "استسرار",
    "استسقاء",
    "استسقائي",
    "استسلام",
    "استشارة",
    "استشاط",
    "استشفاء",
    "استشھاد",
    "استصباح",
    "استصدار",
    "استصطفاء",
    "استصغر",
    "استصفى",
    "استصلاح",
    "استصواب",
    "استطاب",
    "استطاعة",
    "استطالة",
    "استطراد",
    "استطراق",
    "استطرد",
    "استطلاع",
    "استطلاعي",
    "استطلع",
    "استطلعه",
    "استطلق",
    "استطيب",
    "استطير",
    "استطيع",
    "استعادة",
    "استعباد",
    "استعجاب",
    "استعجال",
    "استعجالي",
    "استعداد",
    "استعداده",
    "استعدادي",
    "استعراضي",
    "استعراضية",
    "استعصاء",
    "استعطاء",
    "استعطاف",
    "استعفاء",
    "استعلاء",
    "استعلام",
    "استعمار",
    "استعماري",
    "استعمارية",
    "استغاثة",
    "استغراب",
    "استغرب",
    "استغشي",
    "استغلالي",
    "استغلق",
    "استغمائية",
    "استغنم",
    "استفادة",
    "استفاضة",
    "استفتاء",
    "استفتاح",
    "استفتاحية",
    "استفحال",
    "استفراغ",
    "استفرغ",
    "استفزاز",
    "استفسار",
    "استفھام",
    "استفھامي",
    "استقالة",
    "استقالته",
    "استقام",
    "استقبال",
    "استقبالا",
    "استقباله",
    "استقراء",
    "استقرائي",
    "استقرار",
    "استقراض",
    "استقرت",
    "استقصاء",
    "استقطاب",
    "استقطار",
    "استقطاع",
    "استقل",
    "استقلال",
    "استكانة",
    "استكتاب",
    "استكتابي",
    "استكتابية",
    "استكشافي",
    "استكشافية",
    "استكمال",
    "استكناه",
    "استلاب",
    "استلام",
    "استلحاق",
    "استلفات",
    "استلفاتة",
    "استلفت",
    "استماتة",
    "استماح",
    "استمارة",
    "استمداد",
    "استمرار",
    "استمساك",
    "استن",
    "استناد",
    "استنبات",
    "استنباط",
    "استنتاجاته",
    "استنزال",
    "استنساخ",
    "استنشاق",
    "استنصاح",
    "استنطاق",
    "استنفد",
    "استنقاذ",
    "استننھاض",
    "استنوق",
    "استنھج",
    "استواء",
    "استوبة",
    "استوحى",
    "استودعك",
    "استودعه",
    "استوديو",
    "استوديوھات",
    "استوصب",
    "استوصى",
    "استوقف",
    "استوكھولم",
    "استونيا",
    "استياتيت",
    "استيحاش",
    "استيداع",
    "استيراد",
    "استيضاح",
    "استيطان",
    "استيفاء",
    "استيلاء",
    "استيلاد",
    "استھان",
    "استھانة",
    "استھتار",
    "استھجان",
    "استھزاء",
    "استھلاك",
    "استھلاكي",
    "استھلال",
    "استھلالي",
    "استھواء",
    "استھون",
    "استھيم",
    "اسجاف",
    "اسجح",
    "اسحات",
    "اسحار",
    "اسحم",
    "اسخياء",
    "اسد",
    "اسداد",
    "اسداس",
    "اسداسه",
    "اسداف",
    "اسدال",
    "اسدة",
    "اسدى",
    "اسدية",
    "اسر",
    "اسراء",
    "اسرائيلي",
    "اسرائيليات",
    "اسرائيلية",
    "اسراب",
    "اسرار",
    "اسراع",
    "اسراف",
    "اسرافيل",
    "اسرب",
    "اسرة",
    "اسروجة",
    "اسرى",
    "اسرياء",
    "اسرية",
    "اسس",
    "اسطار",
    "اسطال",
    "اسطانبول",
    "اسطبة",
    "اسطبل",
    "اسطح",
    "اسطحة",
    "اسطر",
    "اسطرلاب",
    "اسطع",
    "اسطقس",
    "اسطوات",
    "اسطوانات",
    "اسطوانة",
    "اسطوانى",
    "اسطورة",
    "اسطورى",
    "اسطول",
    "اسطى",
    "اسع",
    "اسعار",
    "اسعاف",
    "اسعد",
    "اسف",
    "اسفار",
    "اسفاط",
    "اسفاف",
    "اسفاناخ",
    "اسفانخ",
    "اسفاه",
    "اسفع",
    "اسفل",
    "اسفلت",
    "اسفله",
    "اسفنج",
    "اسفندان",
    "اسفيداج",
    "اسفين",
    "اسقاط",
    "اسقام",
    "اسقربوطي",
    "اسقط",
    "اسقطه",
    "اسقف",
    "اسقفي",
    "اسقفية",
    "اسقمري",
    "اسقيات",
    "اسقية",
    "اسقيل",
    "اسك",
    "اسكاف",
    "اسكافي",
    "اسكان",
    "اسكتش",
    "اسكتلندا",
    "اسكتلندي",
    "اسكفة",
    "اسكلة",
    "اسكملة",
    "اسكندرونة",
    "اسكندينافيا",
    "اسل",
    "اسلئة",
    "اسلاب",
    "اسلاف",
    "اسلاك",
    "اسلام",
    "اسلامبولي",
    "اسلامي",
    "اسلامية",
    "اسلانده",
    "اسلة",
    "اسلحة",
    "اسلس",
    "اسلفنا",
    "اسلم",
    "اسلنطح",
    "اسلوب",
    "اسما",
    "اسماء",
    "اسمار",
    "اسماع",
    "اسماعھم",
    "اسمال",
    "اسمانجوني",
    "اسمدة",
    "اسمر",
    "اسمره",
    "اسمطة",
    "اسمنت",
    "اسمنتي",
    "اسمى",
    "اسمية",
    "اسن",
    "اسناخ",
    "اسناد",
    "اسنان",
    "اسنانه",
    "اسنة",
    "اسند",
    "اسنمة",
    "اسو",
    "اسوأ",
    "اسواء",
    "اسوار",
    "اسواط",
    "اسواق",
    "اسوان",
    "اسوة",
    "اسوج",
    "اسوجة",
    "اسوجي",
    "اسودة",
    "اسورة",
    "اسوياء",
    "اسى",
    "اسياج",
    "اسياخ",
    "اسياد",
    "اسياف",
    "اسير",
    "اسيرة",
    "اسيف",
    "اسيل",
    "اسيوط",
    "اسھاب",
    "اسھال",
    "اسھر",
    "اسھل",
    "اسھم",
    "اسھمي",
    "اشأم",
    "اشائب",
    "اشابة",
    "اشابين",
    "اشاجع",
    "اشاحت",
    "اشاد",
    "اشادة",
    "اشار",
    "اشارته",
    "اشارجي",
    "اشاري",
    "اشاعة",
    "اشاعية",
    "اشاف",
    "اشاوس",
    "اشب",
    "اشباح",
    "اشبار",
    "اشباع",
    "اشبال",
    "اشباه",
    "اشباھه",
    "اشببيلية",
    "اشبع",
    "اشبعه",
    "اشبه",
    "اشبين",
    "اشبينة",
    "اشتات",
    "اشتبك",
    "اشتبه",
    "اشتد",
    "اشتداد",
    "اشتراء",
    "اشتراط",
    "اشتراعي",
    "اشتراك",
    "اشتراكية",
    "اشتعل",
    "اشتغال",
    "اشتغل",
    "اشتقاق",
    "اشتمله",
    "اشتياق",
    "اشتية",
    "اشتھاء",
    "اشتھار",
    "اشجان",
    "اشجر",
    "اشجع",
    "اشحاء",
    "اشحة",
    "اشخاص",
    "اشد",
    "اشداء",
    "اشداق",
    "اشدة",
    "اشدق",
    "اشده",
    "اشر",
    "اشرأب",
    "اشراء",
    "اشراج",
    "اشرار",
    "اشراط",
    "اشراف",
    "اشراق",
    "اشراك",
    "اشربة",
    "اشربه",
    "اشرطة",
    "اشرع",
    "اشرك",
    "اشركه",
    "اشرم",
    "اشرية",
    "اشطره",
    "اشعار",
    "اشعاع",
    "اشعاعي",
    "اشعال",
    "اشعب",
    "اشعبي",
    "اشعة",
    "اشعر",
    "اشعرة",
    "اشغال",
    "اشغل",
    "اشفار",
    "اشفاع",
    "اشفاق",
    "اشفى",
    "اشفية",
    "اشق",
    "اشقاء",
    "اشقة",
    "اشقر",
    "اشقياء",
    "اشكال",
    "اشل",
    "اشلاء",
    "اشم",
    "اشمأز",
    "اشمط",
    "اشمل",
    "اشنان",
    "اشنب",
    "اشنة",
    "اشنراك",
    "اشنع",
    "اشواطا",
    "اشواق",
    "اشواك",
    "اشور",
    "اشوري",
    "اشوس",
    "اشوه",
    "اشياء",
    "اشياخ",
    "اشياع",
    "اشياف",
    "اشيب",
    "اشيك",
    "اشھاد",
    "اشھار",
    "اشھب",
    "اشھر",
    "اشھق",
    "اشھل",
    "اصائل",
    "اصاب",
    "اصابات",
    "اصابة",
    "اصابع",
    "اصابه",
    "اصابيع",
    "اصاغر",
    "اصال",
    "اصالة",
    "اصباء",
    "اصباب",
    "اصباح",
    "اصباغ",
    "اصبع",
    "اصبغة",
    "اصبوع",
    "اصبية",
    "اصتياف",
    "اصح",
    "اصحاء",
    "اصحاب",
    "اصحابه",
    "اصحاح",
    "اصحر",
    "اصحنة",
    "اصد",
    "اصداء",
    "اصدار",
    "اصداغ",
    "اصداف",
    "اصدق",
    "اصدقاء",
    "اصدقة",
    "اصر",
    "اصرار",
    "اصرح",
    "اصص",
    "اصطبار",
    "اصطبل",
    "اصطحاب",
    "اصطخاب",
    "اصطدام",
    "اصطراع",
    "اصطفاء",
    "اصطلاح",
    "اصطلاحي",
    "اصطناع",
    "اصطناعي",
    "اصغاء",
    "اصغار",
    "اصغر",
    "اصغرى",
    "اصفاد",
    "اصفار",
    "اصفان",
    "اصفاه",
    "اصفياء",
    "اصفھان",
    "اصقاع",
    "اصقر",
    "اصقع",
    "اصك",
    "اصل",
    "اصلا",
    "اصلاء",
    "اصلاب",
    "اصلاح",
    "اصلاحي",
    "اصلاحية",
    "اصلاد",
    "اصلال",
    "اصلاه",
    "اصلب",
    "اصلح",
    "اصلحت",
    "اصلخ",
    "اصلع",
    "اصلي",
    "اصلية",
    "اصم",
    "اصمخة",
    "اصناء",
    "اصناف",
    "اصنام",
    "اصواع",
    "اصواف",
    "اصوب",
    "اصوبية",
    "اصوع",
    "اصولي",
    "اصونة",
    "اصياف",
    "اصيب",
    "اصيص",
    "اصيل",
    "اصھار",
    "اصھب",
    "اضاءة",
    "اضابر",
    "اضاحك",
    "اضاحى",
    "اضاع",
    "اضاعة",
    "اضاف",
    "اضافة",
    "اضافي",
    "اضاليل",
    "اضب",
    "اضبارة",
    "اضبع",
    "اضبور",
    "اضجع",
    "اضحاة",
    "اضحك",
    "اضحوكة",
    "اضحى",
    "اضحية",
    "اضداد",
    "اضر",
    "اضراب",
    "اضرار",
    "اضراس",
    "اضرحة",
    "اضطجع",
    "اضطرابات",
    "اضطرار",
    "اضطرارى",
    "اضطراري",
    "اضطرام",
    "اضطرب",
    "اضطھاد",
    "اضعاف",
    "اضعافا",
    "اضعافه",
    "اضغاث",
    "اضغان",
    "اضف",
    "اضفار",
    "اضلاع",
    "اضلال",
    "اضلع",
    "اضلولة",
    "اضمار",
    "اضمحل",
    "اضمحلال",
    "اضمر",
    "اضمن",
    "اضواء",
    "اضوى",
    "اضياف",
    "اضيق",
    "اطار",
    "اطارة",
    "اطاري",
    "اطاعة",
    "اطاقة",
    "اطال",
    "اطالة",
    "اطالس",
    "اطايب",
    "اطباء",
    "اطباق",
    "اطبال",
    "اطبة",
    "اطبق",
    "اطر",
    "اطراء",
    "اطراب",
    "اطراح",
    "اطراحا",
    "اطراد",
    "اطرافا",
    "اطرافھا",
    "اطراقة",
    "اطرب",
    "اطرزة",
    "اطرس",
    "اطرش",
    "اطرغلة",
    "اطرف",
    "اطرق",
    "اطروحة",
    "اطرون",
    "اطرية",
    "اطعام",
    "اطعمة",
    "اطعمه",
    "اطفأ",
    "اطفاء",
    "اطفائي",
    "اطفائية",
    "اطفال",
    "اطقم",
    "اطلاع",
    "اطلاقا",
    "اطلال",
    "اطلانطي",
    "اطلانطيكي",
    "اطلس",
    "اطلسي",
    "اطلق",
    "اطلقوا",
    "اطلنطي",
    "اطمئنان",
    "اطمار",
    "اطماع",
    "اطمع",
    "اطناب",
    "اطنابه",
    "اطناف",
    "اطنان",
    "اطواء",
    "اطواد",
    "اطوار",
    "اطواف",
    "اطواق",
    "اطوال",
    "اطول",
    "اطوم",
    "اطيار",
    "اطياف",
    "اطيان",
    "اطيب",
    "اطيط",
    "اطھار",
    "اظافر",
    "اظافير",
    "اظعان",
    "اظفار",
    "اظفاره",
    "اظفور",
    "اظلاف",
    "اظلال",
    "اظلام",
    "اظلم",
    "اظن",
    "اظنك",
    "اظھار",
    "اظھر",
    "اظھرنا",
    "اظھرھم",
    "اعاجم",
    "اعاجيب",
    "اعاد",
    "اعاده",
    "اعارة",
    "اعاري",
    "اعارية",
    "اعاريض",
    "اعاصير",
    "اعاظم",
    "اعال",
    "اعالي",
    "اعاليم",
    "اعانة",
    "اعبئة",
    "اعباء",
    "اعبل",
    "اعتاء",
    "اعتاب",
    "اعتاق",
    "اعتبار",
    "اعتبارا",
    "اعتباري",
    "اعتبارية",
    "اعتباطا",
    "اعتد",
    "اعتداء",
    "اعتداد",
    "اعتدال",
    "اعتدالي",
    "اعتدة",
    "اعتراض",
    "اعتراف",
    "اعترافا",
    "اعترض",
    "اعتزار",
    "اعتزاز",
    "اعتزال",
    "اعتزل",
    "اعتساف",
    "اعتصاب",
    "اعتصام",
    "اعتصر",
    "اعتطف",
    "اعتقاد",
    "اعتقال",
    "اعتقد",
    "اعتلاء",
    "اعتلال",
    "اعتماد",
    "اعتناء",
    "اعتناق",
    "اعتور",
    "اعتياد",
    "اعتيادي",
    "اعجاب",
    "اعجاز",
    "اعجام",
    "اعجب",
    "اعجز",
    "اعجزه",
    "اعجف",
    "اعجل",
    "اعجله",
    "اعجم",
    "اعجمي",
    "اعجوبة",
    "اعداء",
    "اعداد",
    "اعدادي",
    "اعدادية",
    "اعدال",
    "اعدام",
    "اعدامية",
    "اعدل",
    "اعدى",
    "اعذار",
    "اعذاق",
    "اعذب",
    "اعذبة",
    "اعذر",
    "اعراب",
    "اعرابي",
    "اعراس",
    "اعراش",
    "اعراص",
    "اعراض",
    "اعراف",
    "اعرب",
    "اعرج",
    "اعرف",
    "اعرق",
    "اعره",
    "اعز",
    "اعزاء",
    "اعزاب",
    "اعزاز",
    "اعزب",
    "اعزة",
    "اعزل",
    "اعسار",
    "اعسال",
    "اعسر",
    "اعشاء",
    "اعشاب",
    "اعشار",
    "اعشاري",
    "اعشاش",
    "اعشى",
    "اعشية",
    "اعص",
    "اعصاب",
    "اعصابه",
    "اعصار",
    "اعصر",
    "اعصم",
    "اعصمة",
    "اعصياء",
    "اعضاء",
    "اعضاءه",
    "اعضاد",
    "اعضل",
    "اعط",
    "اعطاف",
    "اعطافه",
    "اعطاه",
    "اعطفة",
    "اعطى",
    "اععجاج",
    "اعف",
    "اعفاء",
    "اعفار",
    "اعفاه",
    "اعفة",
    "اعفر",
    "اعق",
    "اعقاب",
    "اعقابھم",
    "اعقب",
    "اعقة",
    "اعقد",
    "اعقف",
    "اعقك",
    "اعقل",
    "اعلاء",
    "اعلاف",
    "اعلاق",
    "اعلام",
    "اعلان",
    "اعلانات",
    "اعلاه",
    "اعلق",
    "اعلم",
    "اعلن",
    "اعلومة",
    "اعمار",
    "اعماق",
    "اعمال",
    "اعماله",
    "اعمام",
    "اعمدة",
    "اعمر",
    "اعمش",
    "اعمى",
    "اعناب",
    "اعنات",
    "اعناق",
    "اعنة",
    "اعنتھا",
    "اعنز",
    "اعنف",
    "اعنق",
    "اعني",
    "اعواد",
    "اعوام",
    "اعوج",
    "اعود",
    "اعوذ",
    "اعور",
    "اعوز",
    "اعوزه",
    "اعيادا",
    "اعيته",
    "اعيننا",
    "اعھد",
    "اغا",
    "اغاثة",
    "اغارة",
    "اغاريد",
    "اغاريض",
    "اغاسي",
    "اغاليط",
    "اغان",
    "اغاوى",
    "اغباب",
    "اغباش",
    "اغبر",
    "اغبرار",
    "اغبرة",
    "اغبش",
    "اغبى",
    "اغبياء",
    "اغتباط",
    "اغتراب",
    "اغترز",
    "اغتصاب",
    "اغتصب",
    "اغتنم",
    "اغتياب",
    "اغتياظ",
    "اغتيال",
    "اغد",
    "اغدية",
    "اغذية",
    "اغر",
    "اغراء",
    "اغراب",
    "اغرار",
    "اغراس",
    "اغراض",
    "اغراق",
    "اغرب",
    "اغربة",
    "اغرة",
    "اغرض",
    "اغرق",
    "اغرودة",
    "اغرورقت",
    "اغري",
    "اغريقي",
    "اغسال",
    "اغسطس",
    "اغشية",
    "اغصان",
    "اغضاء",
    "اغضاب",
    "اغضى",
    "اغطية",
    "اغفاءة",
    "اغفال",
    "اغلاء",
    "اغلاط",
    "اغلال",
    "اغلالات",
    "اغلب",
    "اغلبية",
    "اغلظ",
    "اغلف",
    "اغلق",
    "اغلوطة",
    "اغلى",
    "اغم",
    "اغماء",
    "اغماد",
    "اغمار",
    "اغمض",
    "اغمي",
    "اغن",
    "اغنام",
    "اغنى",
    "اغنياء",
    "اغنية",
    "اغواء",
    "اغوات",
    "اغوار",
    "اغواط",
    "اغوال",
    "اغوية",
    "اغياث",
    "اغياد",
    "اغياض",
    "اغيال",
    "اغيان",
    "اف",
    "افؤس",
    "افؤل",
    "افئدة",
    "افائك",
    "افاحيص",
    "افادة",
    "افارقة",
    "افاريز",
    "افاشين",
    "افاضة",
    "افاضل",
    "افاع",
    "افاعيل",
    "افاق",
    "افاقة",
    "افاك",
    "افاكيه",
    "افانين",
    "افاويق",
    "افاويه",
    "افتآت",
    "افتأت",
    "افتئت",
    "افتاء",
    "افتار",
    "افتتاح",
    "افتتاحي",
    "افتتاحية",
    "افتخار",
    "افتداه",
    "افتراضي",
    "افتراق",
    "افترش",
    "افترق",
    "افتضاح",
    "افتضاض",
    "افتضح",
    "افتقاد",
    "افتقار",
    "افتك",
    "افتكاك",
    "افتنان",
    "افتيات",
    "افحوص",
    "افخاذ",
    "افخر",
    "افدح",
    "افدنة",
    "افذاذ",
    "افراء",
    "افراج",
    "افراح",
    "افراخ",
    "افراد",
    "افراز",
    "افراس",
    "افراط",
    "افراغ",
    "افران",
    "افرخ",
    "افرزة",
    "افرشة",
    "افرع",
    "افرغ",
    "افرغه",
    "افرقاء",
    "افرقة",
    "افرنجى",
    "افرنجي",
    "افرنسي",
    "افريز",
    "افريقا",
    "افريقي",
    "افريقيا",
    "افريقية",
    "افزاع",
    "افساد",
    "افسد",
    "افسنتين",
    "افشين",
    "افصاح",
    "افصح",
    "افضال",
    "افضل",
    "افضلية",
    "افضى",
    "افضية",
    "افطار",
    "افطح",
    "افطس",
    "افظاظ",
    "افعال",
    "افعله",
    "افعوان",
    "افغانستان",
    "افغاني",
    "افف",
    "افق",
    "افقي",
    "افك",
    "افكار",
    "افكاره",
    "افكوھة",
    "افل",
    "افلاء",
    "افلات",
    "افلاذ",
    "افلاس",
    "افلاطون",
    "افلاك",
    "افلال",
    "افلام",
    "افمام",
    "افن",
    "افناء",
    "افنان",
    "افندم",
    "افندي",
    "افنون",
    "افنية",
    "افوات",
    "افواج",
    "افواجا",
    "افواه",
    "افوك",
    "افوكاتو",
    "افول",
    "افوه",
    "افوھد",
    "افياء",
    "افيال",
    "افيح",
    "افيد",
    "افيكة",
    "افين",
    "افيون",
    "افھام",
    "افھد",
    "اقاح",
    "اقاحى",
    "اقاحي",
    "اقاديح",
    "اقارب",
    "اقاص",
    "اقاصي",
    "اقاصيص",
    "اقال",
    "اقالة",
    "اقاله",
    "اقاليد",
    "اقاليم",
    "اقام",
    "اقامه",
    "اقانيم",
    "اقاويل",
    "اقب",
    "اقبال",
    "اقبالا",
    "اقبح",
    "اقبية",
    "اقة",
    "اقتاب",
    "اقتار",
    "اقتال",
    "اقتباس",
    "اقتبال",
    "اقتحام",
    "اقتداء",
    "اقتدح",
    "اقتراب",
    "اقتراح",
    "اقتراحات",
    "اقتراض",
    "اقتران",
    "اقتسار",
    "اقتسام",
    "اقتصادا",
    "اقتصادى",
    "اقتصادية",
    "اقتضاء",
    "اقتضاب",
    "اقتطاف",
    "اقتفاء",
    "اقتفى",
    "اقتل",
    "اقتم",
    "اقتناء",
    "اقتناع",
    "اقتنع",
    "اقتور",
    "اقحاح",
    "اقحاف",
    "اقحام",
    "اقحم",
    "اقحوان",
    "اقد",
    "اقداح",
    "اقدار",
    "اقداس",
    "اقدام",
    "اقدامه",
    "اقدح",
    "اقدر",
    "اقدس",
    "اقدم",
    "اقدمية",
    "اقذاء",
    "اقذار",
    "اقذلة",
    "اقذى",
    "اقر",
    "اقرأه",
    "اقراص",
    "اقراط",
    "اقران",
    "اقرب",
    "اقرباء",
    "اقرباذين",
    "اقرباذيني",
    "اقربة",
    "اقربون",
    "اقرع",
    "اقرن",
    "اقزام",
    "اقساط",
    "اقسام",
    "اقسة",
    "اقسط",
    "اقسم",
    "اقسماء",
    "اقسموا",
    "اقسى",
    "اقشاب",
    "اقشعر",
    "اقصاء",
    "اقصاه",
    "اقصد",
    "اقصر",
    "اقصوصة",
    "اقصى",
    "اقض",
    "اقضية",
    "اقطاب",
    "اقطار",
    "اقطاري",
    "اقطاع",
    "اقطاعة",
    "اقطاعي",
    "اقطاعية",
    "اقطان",
    "اقعد",
    "اقعدة",
    "اقعس",
    "اقعنسس",
    "اقف",
    "اقفاء",
    "اقفار",
    "اقفاص",
    "اقفال",
    "اقفزة",
    "اقفية",
    "اقل",
    "اقلاء",
    "اقلاع",
    "اقلاق",
    "اقلال",
    "اقلام",
    "اقلبة",
    "اقلص",
    "اقلع",
    "اقلق",
    "اقلم",
    "اقلية",
    "اقليد",
    "اقليدس",
    "اقليمي",
    "اقليمية",
    "اقمار",
    "اقماط",
    "اقماع",
    "اقمح",
    "اقمر",
    "اقمشة",
    "اقمصة",
    "اقمطة",
    "اقناء",
    "اقناع",
    "اقنان",
    "اقنة",
    "اقنعة",
    "اقنوم",
    "اقنى",
    "اقنية",
    "اقواء",
    "اقواب",
    "اقوات",
    "اقواس",
    "اقواش",
    "اقواط",
    "اقواع",
    "اقوال",
    "اقواله",
    "اقوالھم",
    "اقوام",
    "اقود",
    "اقوع",
    "اقول",
    "اقوم",
    "اقونة",
    "اقوى",
    "اقوياء",
    "اقياد",
    "اقيال",
    "اقيانوسية",
    "اقيةن",
    "اقيسة",
    "اقيط",
    "اقھى",
    "اكابر",
    "اكادى",
    "اكاديمية",
    "اكاذيب",
    "اكار",
    "اكارع",
    "اكارم",
    "اكاسر",
    "اكاسرة",
    "اكال",
    "اكاليل",
    "اكام",
    "اكاميم",
    "اكباب",
    "اكباد",
    "اكبار",
    "اكباش",
    "اكبر",
    "اكتئاب",
    "اكتاف",
    "اكتتاب",
    "اكتتام",
    "اكتحل",
    "اكتراء",
    "اكتراث",
    "اكتساب",
    "اكتساح",
    "اكتع",
    "اكتفاء",
    "اكتمال",
    "اكتناز",
    "اكتناه",
    "اكتوبر",
    "اكثار",
    "اكثبة",
    "اكثرية",
    "اكحال",
    "اكحل",
    "اكد",
    "اكداس",
    "اكدر",
    "اكذب",
    "اكذوبة",
    "اكر",
    "اكراء",
    "اكراح",
    "اكراد",
    "اكراش",
    "اكرام",
    "اكراما",
    "اكرامية",
    "اكراه",
    "اكراھي",
    "اكرة",
    "اكرت",
    "اكرش",
    "اكرع",
    "اكرم",
    "اكزيما",
    "اكس",
    "اكسب",
    "اكسبريس",
    "اكسترا",
    "اكسجين",
    "اكسح",
    "اكسية",
    "اكسير",
    "اكشاك",
    "اكف",
    "اكفاء",
    "اكفال",
    "اكفان",
    "اكفس",
    "اكفھرار",
    "اكل",
    "اكلاء",
    "اكلة",
    "اكلف",
    "اكلمة",
    "اكله",
    "اكليركى",
    "اكليركي",
    "اكليروس",
    "اكليروسية",
    "اكليل",
    "اكلينيكي",
    "اكم",
    "اكمؤ",
    "اكماء",
    "اكمار",
    "اكمال",
    "اكمام",
    "اكمة",
    "اكمد",
    "اكمل",
    "اكمه",
    "اكناف",
    "اكنافه",
    "اكنان",
    "اكنة",
    "اكواب",
    "اكواخ",
    "اكواد",
    "اكوار",
    "اكواز",
    "اكواس",
    "اكواع",
    "اكوام",
    "اكوان",
    "اكور",
    "اكوس",
    "اكول",
    "اكيار",
    "اكياس",
    "اكيال",
    "اكيس",
    "اكيل",
    "الآبدين",
    "الآتي",
    "الآجلة",
    "الآحد",
    "الآدميين",
    "الآستانة",
    "الآلھة",
    "الآنام",
    "الآوراس",
    "الآونة",
    "الأبوية",
    "الأثافي",
    "الأثينيين",
    "الأجمية",
    "الأجنبية",
    "الأحايين",
    "الأحباس",
    "الأحمدية",
    "الأحمران",
    "الأخص",
    "الأخمص",
    "الأخوين",
    "الأخيرة",
    "الأدبي",
    "الأدنون",
    "الأدون",
    "الأذھان",
    "الأرب",
    "الأربعاء",
    "الأربعون",
    "الأربعين",
    "الأرجاء",
    "الأرجح",
    "الأروام",
    "الأروبية",
    "الأزمان",
    "الأزھر",
    "الأزھران",
    "الأساسى",
    "الأسبان",
    "الأسھم",
    "الأشراف",
    "الأشھاد",
    "الأشھر",
    "الأصح",
    "الأصقاع",
    "الأصلى",
    "الأضحى",
    "الأطفاء",
    "الأطلال",
    "الأظفار",
    "الأعتاب",
    "الأعرابية",
    "الأعزل",
    "الأعصم",
    "الأعطاف",
    "الأعور",
    "الأعيان",
    "الأفلاك",
    "الأفھام",
    "الأقباط",
    "الأقداس",
    "الأقرب",
    "الأقوام",
    "الأكابر",
    "الأكاذب",
    "الأكثرون",
    "الأكحل",
    "الأكراد",
    "الأكف",
    "الأكمة",
    "الألسن",
    "الألواء",
    "الألوف",
    "الأمانية",
    "الأمران",
    "الأمرض",
    "الأمرين",
    "الأمطر",
    "الأملاك",
    "الأممي",
    "الأممية",
    "الأناة",
    "الأناشد",
    "الأنباط",
    "الأنشاد",
    "الأنقى",
    "الأنوار",
    "الأوقاف",
    "الأھالى",
    "الأھرام",
    "الأھلون",
    "الأھلي",
    "الأھلية",
    "الأھمية",
    "الأھواء",
    "الأھوال",
    "الإخوان",
    "الإشفاق",
    "الإشمام",
    "الإشھار",
    "الإفرنج",
    "الإفرنجي",
    "الإقطاع",
    "الإلھية",
    "الإمضاء",
    "الإمكان",
    "الإنساني",
    "الإيھام",
    "الا",
    "الائف",
    "الااشتباك",
    "الابتدائى",
    "الابجدية",
    "الابدي",
    "الابرة",
    "الابصار",
    "الابعاد",
    "الابعد",
    "الابوان",
    "الابيض",
    "الاتحادية",
    "الاتراك",
    "الاتقان",
    "الاتھام",
    "الاتھامية",
    "الاثبات",
    "الاثر",
    "الاثقال",
    "الاثم",
    "الاثناء",
    "الاثنين",
    "الاثير",
    "الاجازات",
    "الاجانب",
    "الاجبارى",
    "الاجباري",
    "الاجبارية",
    "الاجتماعية",
    "الاجرام",
    "الاجربة",
    "الاجرة",
    "الاجماع",
    "الاجماعية",
    "الاجمال",
    "الاجنبية",
    "الاجواد",
    "الاحالة",
    "الاحايين",
    "الاحباب",
    "الاحبار",
    "الاحباش",
    "الاحتراز",
    "الاحدوثة",
    "الاحرار",
    "الاحساس",
    "الاحساسية",
    "الاحسان",
    "الاحسن",
    "الاحكام",
    "الاحلام",
    "الاحيائية",
    "الاخراج",
    "الاخضر",
    "الاخلال",
    "الاخير",
    "الاداري",
    "الادب",
    "الادراك",
    "الادنى",
    "الادياتيك",
    "الاذاعة",
    "الارادة",
    "الاراضي",
    "الارتكاز",
    "الارث",
    "الارثوذكسي",
    "الارثوذكسية",
    "الارجنتين",
    "الارحام",
    "الاردن",
    "الاردنية",
    "الارشادات",
    "الارضاء",
    "الارضية",
    "الارم",
    "الارمن",
    "الارناوط",
    "الارواح",
    "الازبك",
    "الازدواج",
    "الازرقاق",
    "الازمة",
    "الازياء",
    "الاساسي",
    "الاساقفة",
    "الاسبان",
    "الاستاذ",
    "الاستانة",
    "الاستختبارات",
    "الاسترليني",
    "الاستسقاء",
    "الاستشراق",
    "الاستصباح",
    "الاستطلاع",
    "الاستعجال",
    "الاستعلامات",
    "الاستعمالات",
    "الاستغاثة",
    "الاستغراب",
    "الاستفھام",
    "الاستقبال",
    "الاستلام",
    "الاستماع",
    "الاستمرار",
    "الاستيداع",
    "الاسد",
    "الاسر",
    "الاسراء",
    "الاسرائيلي",
    "الاسعاف",
    "الاسعتمال",
    "الاسغماية",
    "الاسف",
    "الاسفيني",
    "الاسكا",
    "الاسكندرية",
    "الاسلام",
    "الاسلامى",
    "الاسلامية",
    "الاسلية",
    "الاسماء",
    "الاسماك",
    "الاسنان",
    "الاسود",
    "الاشارات",
    "الاشتراع",
    "الاشتراك",
    "الاشتغال",
    "الاشراقيون",
    "الاشعة",
    "الاصابة",
    "الاصابع",
    "الاصدار",
    "الاصطياف",
    "الاصغر",
    "الاضطرار",
    "الاضلاع",
    "الاطفاء",
    "الاطلانتيك",
    "الاطلانطيك",
    "الاطلنطى",
    "الاطلنطي",
    "الاطوال",
    "الاطيان",
    "الاعتبار",
    "الاعتداء",
    "الاعتداد",
    "الاعتقادي",
    "الاعتناء",
    "الاعجاب",
    "الاعظم",
    "الاعلى",
    "الاعمال",
    "الاعيب",
    "الاغارقة",
    "الاغريق",
    "الاف",
    "الافتتاح",
    "الافرنج",
    "الافرنسية",
    "الافرنسيون",
    "الافغان",
    "الافكار",
    "الافلام",
    "الافوكاتو",
    "الافيون",
    "الاق",
    "الاقاليم",
    "الاقامة",
    "الاقتصاديات",
    "الاقدمون",
    "الاقرباذين",
    "الاقصر",
    "الاقصى",
    "الاقليمية",
    "الاكبر",
    "الاكتراث",
    "الاكزيما",
    "الاكل",
    "الاكمة",
    "الالاھيات",
    "الالب",
    "الالبان",
    "الالتباس",
    "الالتفات",
    "الالتقاط",
    "الالتھاب",
    "الالسن",
    "الالسنة",
    "الالعاب",
    "الالمان",
    "الالمانية",
    "الالوف",
    "الام",
    "الامارة",
    "الامام",
    "الامانات",
    "الامة",
    "الامتحان",
    "الامتياز",
    "الامتيازات",
    "الامد",
    "الامر",
    "الامراء",
    "الامراض",
    "الامريكان",
    "الامكان",
    "الامم",
    "الامن",
    "الامناء",
    "الامواج",
    "الاموال",
    "الاميرية",
    "الامين",
    "الاناة",
    "الاناضل",
    "الانام",
    "الانتخاب",
    "الانتدابات",
    "الانتظام",
    "الانتھاء",
    "الانثيان",
    "الانجليز",
    "الانحطاط",
    "الاندلس",
    "الانساب",
    "الانصھار",
    "الانظار",
    "الانعقاد",
    "الانف",
    "الانفراد",
    "الانفية",
    "الانقاذ",
    "الانقضاض",
    "الانقلاب",
    "الانكليز",
    "الانوق",
    "الاه",
    "الاوائل",
    "الاوامر",
    "الاوان",
    "الاوراس",
    "الاولمبية",
    "الاولون",
    "الاولى",
    "الاى",
    "الايام",
    "الايراد",
    "الايفاء",
    "الايمان",
    "الاين",
    "الايوبيون",
    "الاھبة",
    "الاھة",
    "الاھتزازات",
    "الاھتزازي",
    "الاھتمام",
    "الاھمية",
    "الاھي",
    "الاھية",
    "الب",
    "الباء",
    "الباباوية",
    "البابوي",
    "الباد",
    "البادية",
    "البارح",
    "البارحة",
    "الباردة",
    "البارى",
    "الباشقرد",
    "الباطنة",
    "الباطنية",
    "الباع",
    "الباقيات",
    "الباقية",
    "الباكر",
    "الباكستان",
    "الباليار",
    "البان",
    "البانيا",
    "الباى",
    "البتة",
    "البتول",
    "البحارنة",
    "البحتة",
    "البداھية",
    "البديع",
    "البديھة",
    "البرانس",
    "البربر",
    "البرجاس",
    "البرجيس",
    "البردج",
    "البرديات",
    "البرقة",
    "البروتستو",
    "البرونزى",
    "البرھان",
    "البسة",
    "البسيطة",
    "البشارة",
    "البصرة",
    "البطاطس",
    "البطراء",
    "البعير",
    "البقري",
    "البقلة",
    "البقلية",
    "البكتريا",
    "البلطيق",
    "البلوى",
    "البن",
    "البنجاب",
    "البنفسجى",
    "البوسته",
    "البوسطة",
    "البوسفور",
    "البوع",
    "البوليس",
    "البولينا",
    "البيرنيه",
    "البيضان",
    "البيطرى",
    "البين",
    "البيوتات",
    "التأثري",
    "التأمور",
    "التأنيث",
    "التابنة",
    "التاريخى",
    "التاسعة",
    "التاميز",
    "التباغض",
    "التبانة",
    "التبعة",
    "التتار",
    "التجاء",
    "التجريبى",
    "التجلى",
    "التجھيزات",
    "التحري",
    "التحضيرية",
    "التحق",
    "التحقبالحكومة",
    "التحليف",
    "التخديم",
    "التخطاب",
    "التخييل",
    "التدابير",
    "التدرني",
    "التذاكير",
    "التراويح",
    "الترعة",
    "التركمان",
    "التركية",
    "الترويح",
    "التزاما",
    "التسعيرة",
    "التسليف",
    "التسنيم",
    "التشريد",
    "التشريفات",
    "التشريفة",
    "التشريق",
    "التشريك",
    "التشطيب",
    "التشعع",
    "التصوري",
    "التطيب",
    "التعليمي",
    "التفات",
    "التفاتة",
    "التفاف",
    "التفافية",
    "التفاھم",
    "التفت",
    "التفريخ",
    "التفصيلية",
    "التفضل",
    "التفقيص",
    "التفلسة",
    "التفليسة",
    "التقاط",
    "التقط",
    "التكرير",
    "التكسب",
    "التلبس",
    "التليفونات",
    "التمسته",
    "التناول",
    "التنصيص",
    "التنفيذية",
    "التواءة",
    "التوجيھي",
    "التوجيھية",
    "التياع",
    "التيس",
    "التيفودية",
    "التيفية",
    "التھاب",
    "التھابي",
    "التھذيب",
    "التھمة",
    "الثانى",
    "الثغ",
    "الثكلى",
    "الثلاثى",
    "الثلاثين",
    "الثلثاء",
    "الثمام",
    "الثنايا",
    "الجائيات",
    "الجاثى",
    "الجارى",
    "الجبروتية",
    "الجبين",
    "الجبھة",
    "الجدرى",
    "الجدى",
    "الجديدان",
    "الجذماء",
    "الجراية",
    "الجركس",
    "الجرمان",
    "الجريض",
    "الجزئيات",
    "الجس",
    "الجسمانى",
    "الجفر",
    "الجلالة",
    "الجلة",
    "الجلوة",
    "الجليدى",
    "الجماھير",
    "الجمبازية",
    "الجمة",
    "الجمد",
    "الجمعي",
    "الجمھوري",
    "الجمھورية",
    "الجنا",
    "الجنائي",
    "الجنان",
    "الجنايات",
    "الجنباز",
    "الجنسى",
    "الجنويب",
    "الجوق",
    "الجوى",
    "الجويجوري",
    "الجيوغرافيا",
    "الجيوفيزيائية",
    "الجھات",
    "الجھاز",
    "الجھامة",
    "الجھة",
    "الجھد",
    "الح",
    "الحابل",
    "الحاجبين",
    "الحاح",
    "الحاخام",
    "الحارث",
    "الحاش",
    "الحاصمة",
    "الحاظ",
    "الحاف",
    "الحاق",
    "الحالي",
    "الحان",
    "الحبالة",
    "الحبش",
    "الحبلين",
    "الحتد",
    "الحجاز",
    "الحجال",
    "الحجلة",
    "الحديدة",
    "الحذق",
    "الحربى",
    "الحربي",
    "الحرفان",
    "الحرمة",
    "الحرمين",
    "الحروب",
    "الحساس",
    "الحسان",
    "الحسبان",
    "الحسرات",
    "الحسنيون",
    "الحشا",
    "الحصرحصر",
    "الحصين",
    "الحضرة",
    "الحفة",
    "الحقانية",
    "الحقة",
    "الحقوقية",
    "الحكر",
    "الحكمي",
    "الحكومية",
    "الحلوب",
    "الحلوة",
    "الحمسين",
    "الحمقاء",
    "الحنيف",
    "الحنيفة",
    "الحنيفية",
    "الحواني",
    "الحى",
    "الحياء",
    "الحيات",
    "الحيثيات",
    "الحيثية",
    "الحيثيون",
    "الحھشية",
    "الخاتون",
    "الخارجة",
    "الخاسرة",
    "الخاص",
    "الخاطر",
    "الخافقين",
    "الخالدات",
    "الخالي",
    "الخبر",
    "الختامي",
    "الخروع",
    "الخريجين",
    "الخزفي",
    "الخشناء",
    "الخض",
    "الخضيب",
    "الخضيراء",
    "الخطاطيف",
    "الخطل",
    "الخطوب",
    "الخطى",
    "الخفض",
    "الخلاء",
    "الخلاص",
    "الخلفاء",
    "الخلقة",
    "الخلول",
    "الخليفية",
    "الخليل",
    "الخليلى",
    "الخم",
    "الخمسين",
    "الخوافق",
    "الخوافى",
    "الخوالي",
    "الخوص",
    "الد",
    "الدابر",
    "الداخلة",
    "الدارجة",
    "الداما",
    "الداننوب",
    "الداھرين",
    "الدخلة",
    "الدردنيل",
    "الدروز",
    "الدريسة",
    "الدستورى",
    "الدستوري",
    "الدفتان",
    "الدقيقي",
    "الدلاء",
    "الدلع",
    "الدمام",
    "الدملي",
    "الدوا",
    "الدولى",
    "الديان",
    "الديانات",
    "الديجوليون",
    "الديدبان",
    "الديكي",
    "الديموقراطية",
    "الدينار",
    "الدھاليز",
    "الدھر",
    "الدھشة",
    "الدھليز",
    "الدھماء",
    "الدھور",
    "الذبحة",
    "الذلق",
    "الذلقية",
    "الذمة",
    "الذوات",
    "الذوللقية",
    "الذى",
    "الذيوع",
    "الذھاب",
    "الذھب",
    "الذھبي",
    "الذھبية",
    "الذھن",
    "الرأى",
    "الراجعة",
    "الراحل",
    "الرازق",
    "الراس",
    "الراعى",
    "الرافدان",
    "الراقع",
    "الراھنة",
    "الرب",
    "الربانيات",
    "الرجاء",
    "الرجراج",
    "الرحب",
    "الرخوة",
    "الردفان",
    "الردھة",
    "الرسولي",
    "الرسولية",
    "الرضاع",
    "الرعشي",
    "الرعيان",
    "الرعية",
    "الرفعة",
    "الرفق",
    "الرقي",
    "الركائب",
    "الركبان",
    "الركة",
    "الرمضاء",
    "الرمق",
    "الروبابيكيا",
    "الروك",
    "الرياسة",
    "الريحاني",
    "الريحة",
    "الريع",
    "الريق",
    "الرھاء",
    "الرھان",
    "الرھن",
    "الزاس",
    "الزام",
    "الزامي",
    "الزباد",
    "الزبى",
    "الزحلقة",
    "الزفر",
    "الزفرات",
    "الزقازق",
    "الزلط",
    "الزلل",
    "الزمان",
    "الزمه",
    "الزنجير",
    "الزياتين",
    "الزيتونة",
    "الزيتونية",
    "الزيدية",
    "الزيود",
    "الزھد",
    "الزھر",
    "الزھرة",
    "الس",
    "السآمة",
    "السائحين",
    "السائرة",
    "السابعة",
    "السابلة",
    "السات",
    "السادسة",
    "الساسانيون",
    "السالم",
    "السامة",
    "السامرة",
    "السامي",
    "السباتي",
    "السبعينية",
    "السبوت",
    "السبوعات",
    "الست",
    "الستار",
    "السجار",
    "السجدة",
    "السحائي",
    "السخرة",
    "السدة",
    "السراية",
    "السرطانية",
    "السرور",
    "السرى",
    "السريرة",
    "السعدية",
    "السعديون",
    "السعف",
    "السعى",
    "السفريات",
    "السفرية",
    "السكت",
    "السكرى",
    "السلاجقة",
    "السلاق",
    "السلطان",
    "السلفية",
    "السماحة",
    "السماكان",
    "السماكين",
    "السمحاء",
    "السمل",
    "السمھورية",
    "السنتھم",
    "السنسكريتية",
    "السنفرة",
    "السنية",
    "السواء",
    "السوفياتي",
    "السوفيت",
    "السيآت",
    "السيار",
    "السيكارة",
    "السھرة",
    "السھل",
    "السھم",
    "الشأر",
    "الشأو",
    "الشؤون",
    "الشائع",
    "الشادنة",
    "الشافععي",
    "الشام",
    "الشاھدة",
    "الشبيبة",
    "الشبھات",
    "الشبھة",
    "الشحائح",
    "الشرابين",
    "الشراة",
    "الشرعي",
    "الشروية",
    "الشريانية",
    "الشريفة",
    "الشرين",
    "الششخانة",
    "الشطط",
    "الشعائر",
    "الشعانين",
    "الشعوب",
    "الشعوبية",
    "الشعوري",
    "الشفاھي",
    "الشفه",
    "الشفوية",
    "الشفھي",
    "الشفھية",
    "الشكيمة",
    "الشماسمة",
    "الشوال",
    "الشورى",
    "الشوف",
    "الشي",
    "الشيب",
    "الشيشة",
    "الشھباء",
    "الشھر",
    "الشھوة",
    "الشھود",
    "الشھية",
    "الصابئة",
    "الصابغ",
    "الصاص",
    "الصاع",
    "الصاق",
    "الصحابة",
    "الصدد",
    "الصدرعجز",
    "الصدغ",
    "الصرب",
    "الصرة",
    "الصعداء",
    "الصعيد",
    "الصفرة",
    "الصفو",
    "الصق",
    "الصنائع",
    "الصولة",
    "الصون",
    "الصھباء",
    "الضاد",
    "الضارية",
    "الضخمة",
    "الضد",
    "الضررين",
    "الضروري",
    "الضوارب",
    "الطائف",
    "الطاف",
    "الطبيعيات",
    "الطحرورية",
    "الطخرورية",
    "الطريدان",
    "الطف",
    "الطفلي",
    "الطقسيات",
    "الطلقاء",
    "الطليان",
    "الطم",
    "الطوائح",
    "الطولى",
    "الطونة",
    "الطوى",
    "الطويل",
    "الطيارة",
    "الظاھر",
    "الظواھر",
    "الظھر",
    "الظھران",
    "الظھور",
    "العاتب",
    "العاجل",
    "العادمة",
    "العارضان",
    "العارضين",
    "العاشرة",
    "العاقب",
    "العال",
    "العالمان",
    "العالى",
    "العامر",
    "العباس",
    "العبراني",
    "العبرانية",
    "العبرة",
    "العجب",
    "العجم",
    "العدلية",
    "العذار",
    "العذري",
    "العذل",
    "العراقان",
    "العراقية",
    "العربان",
    "العرس",
    "العروسان",
    "العريكة",
    "العزوة",
    "العزى",
    "العس",
    "العسة",
    "العشائري",
    "العصفر",
    "العطب",
    "العطلات",
    "العطن",
    "العطوفة",
    "العفاء",
    "العفش",
    "العفنة",
    "العقليات",
    "العكر",
    "العلائق",
    "العلات",
    "العلقم",
    "العلل",
    "العلمي",
    "العلويون",
    "العلية",
    "العمارة",
    "العمدي",
    "العمرين",
    "العملي",
    "العميان",
    "العنان",
    "العوبة",
    "العورة",
    "العياذ",
    "العينين",
    "العھد",
    "الغاب",
    "الغابر",
    "الغابرة",
    "الغاد",
    "الغارب",
    "الغازى",
    "الغاط",
    "الغال",
    "الغالى",
    "الغام",
    "الغاية",
    "الغب",
    "الغبراء",
    "الغداة",
    "الغدو",
    "الغراء",
    "الغرار",
    "الغربين",
    "الغرل",
    "الغشائي",
    "الغشوم",
    "الغصن",
    "الغضى",
    "الغور",
    "الغوطة",
    "الغيار",
    "الغيد",
    "الغھد",
    "الفأل",
    "الفؤادية",
    "الفائت",
    "الفارط",
    "الفارق",
    "الفاروق",
    "الفاطر",
    "الفاطميون",
    "الفاظ",
    "الفاظه",
    "الفاف",
    "الفت",
    "الفتلة",
    "الفحمية",
    "الفحول",
    "الفخرية",
    "الفرائض",
    "الفراغية",
    "الفرقدان",
    "الفرنسيس",
    "الفرنسيسكان",
    "الفسطاط",
    "الفصحى",
    "الفضائل",
    "الفطحل",
    "الفقه",
    "الفقيد",
    "الفكاھة",
    "الفلاحية",
    "الفلانية",
    "الفليبين",
    "الفه",
    "الفوات",
    "الفور",
    "الفى",
    "الفي",
    "الفيحاء",
    "الفيلاريا",
    "الفيلبين",
    "الفينة",
    "الفيوم",
    "الفھم",
    "الق",
    "القاب",
    "القاصى",
    "القاصية",
    "القاضمة",
    "القاف",
    "القالة",
    "القاھر",
    "القاھرة",
    "القبط",
    "القبلتين",
    "القبلي",
    "القبيل",
    "القتاد",
    "القدرية",
    "القذة",
    "القراع",
    "القران",
    "القرفصاء",
    "القرمز",
    "القرمزية",
    "القرويين",
    "القريتان",
    "القريم",
    "القزر",
    "القساطلة",
    "القصد",
    "القصصي",
    "القصيد",
    "القطاعى",
    "القطوف",
    "القعد",
    "القفقاس",
    "القلاعية",
    "القلزم",
    "القمران",
    "القمرقية",
    "القمرية",
    "القنطرة",
    "القوا",
    "القوت",
    "القوط",
    "القوقاس",
    "القويم",
    "القى",
    "القيء",
    "القياد",
    "القية",
    "القيروان",
    "القيودات",
    "القھار",
    "القھقرى",
    "القھوة",
    "الك",
    "الكاب",
    "الكار",
    "الكارات",
    "الكاك",
    "الكان",
    "الكانتو",
    "الكتمان",
    "الكثلكة",
    "الكثيرون",
    "الكر",
    "الكرازة",
    "الكراكي",
    "الكرام",
    "الكرج",
    "الكرد",
    "الكرمل",
    "الكرنن",
    "الكرى",
    "الكريمان",
    "الكريمتان",
    "الكزازي",
    "الكسبياتي",
    "الكعبرة",
    "الكلدان",
    "الكمبيالات",
    "الكمرك",
    "الكن",
    "الكنانة",
    "الكناية",
    "الكنج",
    "الكنسة",
    "الكنيشة",
    "الكوبون",
    "الكوفة",
    "الكوفي",
    "الكوفيون",
    "الكي",
    "الكيف",
    "الكينا",
    "الكھرباء",
    "الكھربائي",
    "الكھربائية",
    "الكھف",
    "الكھنة",
    "الكھنوت",
    "الكھنوتية",
    "الكھيربي",
    "الل",
    "اللأجنبية",
    "اللائق",
    "اللائى",
    "اللاتى",
    "اللاتين",
    "اللاتيني",
    "اللاجئين",
    "اللاذقية",
    "اللاسامية",
    "اللالتھاب",
    "اللانھاية",
    "اللاوي",
    "اللاھوت",
    "اللاھوتية",
    "اللثوية",
    "اللحد",
    "اللد",
    "اللدني",
    "اللدنية",
    "اللطمات",
    "اللعين",
    "اللفيف",
    "اللواتى",
    "اللواقح",
    "اللوردين",
    "اللوزتان",
    "اللوندا",
    "اللھب",
    "اللھجة",
    "اللھم",
    "اللھو",
    "اللھويتان",
    "اللھيب",
    "المآل",
    "المؤاخذة",
    "المؤلمة",
    "المئة",
    "المائة",
    "الماديات",
    "المار",
    "المارشالية",
    "الماز",
    "الماضى",
    "الماعة",
    "الماكوك",
    "المالكية",
    "المام",
    "المان",
    "المانش",
    "الماني",
    "المانيا",
    "الماھية",
    "المبكى",
    "المبھم",
    "المتأخرون",
    "المتاع",
    "المتاعب",
    "المتجمدة",
    "المتحكم",
    "المتحولة",
    "المتخلفة",
    "المتداول",
    "المتساوى",
    "المتصوفة",
    "المتعاقدة",
    "المتعالي",
    "المتعاھدان",
    "المتقارب",
    "المتقاضيان",
    "المتقشفة",
    "المتناوب",
    "المتوكلية",
    "المجاذيب",
    "المجر",
    "المجن",
    "المجيء",
    "المجھر",
    "المجھول",
    "المحافل",
    "المحاماة",
    "المحبس",
    "المحتفلون",
    "المحجة",
    "المحرسون",
    "المحز",
    "المحسوس",
    "المحسوسات",
    "المحصولات",
    "المحظورات",
    "المحلة",
    "المخا",
    "المخاطئ",
    "المختصون",
    "المختلطة",
    "المخجلة",
    "المخى",
    "المخية",
    "المدارك",
    "المدر",
    "المدعى",
    "المديد",
    "المذاھب",
    "المذھب",
    "المرارية",
    "المرافعات",
    "المربوط",
    "المرتبة",
    "المرجو",
    "المرحوم",
    "المرصاد",
    "المرصع",
    "المرفع",
    "المرقسية",
    "المركم",
    "المريء",
    "المزاحمة",
    "المزركش",
    "المزمع",
    "المسألة",
    "المسائية",
    "المساعى",
    "المساكين",
    "المسالك",
    "المساھمة",
    "المستحية",
    "المستطاع",
    "المستظرفة",
    "المستعجلة",
    "المستقلة",
    "المسجدان",
    "المسطحات",
    "المسكوكات",
    "المسكونة",
    "المسلسلة",
    "المسماري",
    "المسوح",
    "المش",
    "المشار",
    "المشاعيل",
    "المشترى",
    "المشرقان",
    "المشرقيات",
    "المشوار",
    "المشھد",
    "المشھود",
    "المشھور",
    "المصادفات",
    "المصاريف",
    "المصاعب",
    "المصراع",
    "المصرية",
    "المصطفى",
    "المصطكاء",
    "المصوغات",
    "المصونة",
    "المضجع",
    "المضي",
    "المطابع",
    "المطاف",
    "المطاوع",
    "المطبعة",
    "المطبقة",
    "المطران",
    "المطمورات",
    "المظھر",
    "المع",
    "المعاد",
    "المعاري",
    "المعالي",
    "المعاميل",
    "المعانى",
    "المعايدة",
    "المعاھدة",
    "المعتدلتان",
    "المعتمد",
    "المعدنيات",
    "المعراج",
    "المعقود",
    "المعلول",
    "المعلى",
    "المعمدان",
    "المعمودية",
    "المعمورة",
    "المعى",
    "المعية",
    "المعيش",
    "المعھد",
    "المعھود",
    "المغازى",
    "المغبون",
    "المغطس",
    "المغفور",
    "المغنة",
    "المغنطيسية",
    "المغنى",
    "المغول",
    "المغيبات",
    "المفالي",
    "المفتي",
    "المفخمة",
    "المفرزات",
    "المفقودة",
    "المفھوم",
    "المقاصد",
    "المقام",
    "المقتضب",
    "المقتل",
    "المقررة",
    "المقضي",
    "المقطم",
    "المقول",
    "المقولات",
    "المقيئ",
    "المقيت",
    "المقيدة",
    "المكاسر",
    "المكرمية",
    "المكسر",
    "المكشوف",
    "المكن",
    "المكى",
    "الملاعنة",
    "الملايو",
    "الملاھى",
    "الملاھي",
    "الملكى",
    "الملوان",
    "الملوكي",
    "الممناصب",
    "المناداة",
    "المناقصة",
    "المنامة",
    "المنان",
    "المنة",
    "المنتقلة",
    "المنتوجات",
    "المنتھى",
    "المنجمد",
    "المندب",
    "المندل",
    "المنسرح",
    "المنش",
    "المنصرف",
    "المنصورة",
    "المنطقتان",
    "المنفرجة",
    "المنورة",
    "المنيا",
    "الموات",
    "المواسم",
    "الموحدون",
    "المودعة",
    "المورة",
    "الموصى",
    "الموطن",
    "الموفد",
    "الموفي",
    "الموقوفة",
    "المولدون",
    "المومأ",
    "الميري",
    "الميمون",
    "المھاجرون",
    "المھجع",
    "المھد",
    "المھملات",
    "المھنة",
    "المھني",
    "النابذة",
    "الناطعة",
    "النافعة",
    "الناموس",
    "الناھى",
    "النبت",
    "النبط",
    "النبوي",
    "النجاد",
    "النجدة",
    "النجسة",
    "النجف",
    "النحر",
    "النحيزة",
    "الندف",
    "الندوة",
    "النسا",
    "النسق",
    "النسوية",
    "النشء",
    "النشوئيون",
    "النشيط",
    "النصيرية",
    "النعائم",
    "النعماء",
    "النفاثي",
    "النفاس",
    "النفاسية",
    "النفود",
    "النقاھة",
    "النقب",
    "النقطتان",
    "النكفية",
    "النكير",
    "النمشية",
    "النميات",
    "النوازل",
    "النوامي",
    "النوعى",
    "النيابات",
    "النيابة",
    "النھائي",
    "النھار",
    "النھاية",
    "النھج",
    "النھر",
    "النھرين",
    "النھضة",
    "النھي",
    "الواء",
    "الواج",
    "الواحى",
    "الواعي",
    "الوافر",
    "الواقى",
    "الوان",
    "الوانه",
    "الوبر",
    "الوتيرة",
    "الوجاھة",
    "الوجھاء",
    "الوجھة",
    "الورقات",
    "الورى",
    "الوساد",
    "الوضعية",
    "الوطئة",
    "الوطاب",
    "الوطيس",
    "الوف",
    "الوفاض",
    "الوقائع",
    "الوقائي",
    "الومنيا",
    "الومنيوم",
    "الوندل",
    "الونش",
    "الوى",
    "الويات",
    "الوية",
    "الوھابية",
    "الوھلة",
    "الوھية",
    "الي",
    "الياذة",
    "الياف",
    "اليبس",
    "الية",
    "اليدويون",
    "اليرموك",
    "اليزيدية",
    "اليس",
    "اليسير",
    "اليف",
    "اليق",
    "اليقظان",
    "اليك",
    "اليكون",
    "اليمنية",
    "اليمينيون",
    "الينا",
    "اليناء",
    "اليونسكو",
    "اليھا",
    "اليھم",
    "اليھود",
    "الھاب",
    "الھاج",
    "الھاجرة",
    "الھادئ",
    "الھاشمية",
    "الھافر",
    "الھال",
    "الھام",
    "الھان",
    "الھاون",
    "الھاوية",
    "الھبة",
    "الھتاف",
    "الھج",
    "الھجرة",
    "الھجوم",
    "الھدوء",
    "الھر",
    "الھزات",
    "الھزيمة",
    "الھضم",
    "الھفوف",
    "الھلاك",
    "الھلال",
    "الھم",
    "الھمة",
    "الھموم",
    "الھند",
    "الھندسة",
    "الھندسية",
    "الھندي",
    "الھندية",
    "الھنود",
    "الھواء",
    "الھوائي",
    "الھوارة",
    "الھود",
    "الھول",
    "الھوى",
    "الھوية",
    "الھوينا",
    "الھي",
    "الھيئات",
    "الھيئة",
    "الھيل",
    "اما",
    "اما",
    "اماء",
    "امائر",
    "امابير",
    "اماتة",
    "اماثل",
    "اماثيل",
    "اماجد",
    "اماديح",
    "امار",
    "امارات",
    "امارة",
    "اماسى",
    "اماسي",
    "اماط",
    "اماعة",
    "اماكن",
    "امالة",
    "اماليح",
    "اماليد",
    "امام",
    "امامة",
    "امامه",
    "امامى",
    "امامية",
    "امان",
    "امانة",
    "اماني",
    "امبراطور",
    "امبراطوري",
    "امبراطورية",
    "امبير",
    "امبيق",
    "امة",
    "امت",
    "امتار",
    "امتاع",
    "امتثال",
    "امتحانا",
    "امتزاج",
    "امتشاق",
    "امتع",
    "امتعاض",
    "امتعة",
    "امتلاء",
    "امتلك",
    "امتناع",
    "امتنان",
    "امتھان",
    "امثال",
    "امثاله",
    "امثل",
    "امثلة",
    "امثولة",
    "امجاد",
    "امجد",
    "امحاء",
    "امحق",
    "امحوضة",
    "امحى",
    "امخال",
    "امد",
    "امداد",
    "امدة",
    "امدوحة",
    "امر",
    "امرء",
    "امرئة",
    "امرا",
    "امراء",
    "امرار",
    "امراس",
    "امراض",
    "امراع",
    "امرة",
    "امرته",
    "امرر",
    "امرط",
    "امرع",
    "امرك",
    "امرلس",
    "امره",
    "امري",
    "امريقا",
    "امريكا",
    "امريكاني",
    "امرين",
    "امرھا",
    "امرھم",
    "امزجة",
    "امس",
    "امسا",
    "امساء",
    "امساد",
    "امساك",
    "امساكية",
    "امستردام",
    "امسك",
    "امسكت",
    "امسى",
    "امسيات",
    "امسية",
    "امشاق",
    "امشير",
    "امصار",
    "امصرة",
    "امضاء",
    "امضى",
    "امضي",
    "امطار",
    "امطر",
    "امطره",
    "امع",
    "امعاء",
    "امعان",
    "امعة",
    "امعز",
    "امعط",
    "امعن",
    "امعية",
    "امغر",
    "امكان",
    "امكانه",
    "امكانية",
    "امكن",
    "امكنة",
    "امل",
    "املا",
    "املاء",
    "املاح",
    "املاط",
    "املاك",
    "املد",
    "املط",
    "املق",
    "امله",
    "املوحة",
    "املى",
    "امم",
    "اممي",
    "امن",
    "امناء",
    "امنان",
    "امنع",
    "امنيبوس",
    "امنية",
    "اموات",
    "امواج",
    "امواس",
    "اموال",
    "امواه",
    "امور",
    "اموره",
    "امومة",
    "اموى",
    "اموي",
    "امي",
    "اميار",
    "امياس",
    "اميال",
    "اميبا",
    "امية",
    "امير",
    "اميرال",
    "اميرالية",
    "اميرة",
    "اميركا",
    "اميركه",
    "اميري",
    "اميرية",
    "اميز",
    "اميل",
    "امين",
    "امھات",
    "امھار",
    "امھال",
    "امھالي",
    "انآء",
    "انأى",
    "اناء",
    "اناب",
    "انابات",
    "انابة",
    "انابر",
    "انابيب",
    "انابير",
    "انابيش",
    "انابيق",
    "اناة",
    "انات",
    "اناث",
    "اناثى",
    "اناجيل",
    "اناخ",
    "انادر",
    "انار",
    "انارة",
    "اناس",
    "اناسب",
    "اناسى",
    "اناشيد",
    "اناشيط",
    "اناطه",
    "اناقة",
    "انامل",
    "اناناس",
    "اناني",
    "انانية",
    "اناييب",
    "انب",
    "انبا",
    "انباء",
    "انباذ",
    "انبار",
    "انباز",
    "انباشى",
    "انباشي",
    "انباض",
    "انبال",
    "انبت",
    "انبثاق",
    "انبذة",
    "انبساط",
    "انبلاج",
    "انبوبة",
    "انبوبي",
    "انبوش",
    "انبوشة",
    "انبياء",
    "انبيق",
    "انة",
    "انت",
    "انتاجية",
    "انتباھه",
    "انتبذ",
    "انتجاب",
    "انتحار",
    "انتحال",
    "انتحر",
    "انتحل",
    "انتحى",
    "انتخابي",
    "انتخابية",
    "انتداب",
    "انتدابي",
    "انتدابية",
    "انتذا",
    "انتزاع",
    "انتساب",
    "انتشاء",
    "انتشار",
    "انتشق",
    "انتصاب",
    "انتصارا",
    "انتصب",
    "انتظار",
    "انتظاره",
    "انتظام",
    "انتظم",
    "انتظو",
    "انتعاش",
    "انتفاض",
    "انتفاضة",
    "انتفاع",
    "انتفخت",
    "انتقاد",
    "انتقاص",
    "انتقاض",
    "انتقالي",
    "انتقالية",
    "انتقص",
    "انتقل",
    "انتكاس",
    "انتم",
    "انتما",
    "انتماء",
    "انتمون",
    "انتن",
    "انتيكة",
    "انتيكخانة",
    "انتھاء",
    "انتھار",
    "انتھاز",
    "انتھازية",
    "انتھاك",
    "انتھب",
    "انتھز",
    "انتھى",
    "انث",
    "انثروبولوجيا",
    "انثناء",
    "انثناءة",
    "انثوى",
    "انثى",
    "انجاب",
    "انجاح",
    "انجاز",
    "انجاس",
    "انجاص",
    "انجال",
    "انجب",
    "انجذاب",
    "انجر",
    "انجرارية",
    "انجل",
    "انجليزى",
    "انجم",
    "انجماد",
    "انجية",
    "انجيل",
    "انجيلى",
    "انجيلية",
    "انحاء",
    "انحباس",
    "انحدار",
    "انحصار",
    "انحطاط",
    "انحطاطي",
    "انحناء",
    "انحناءة",
    "انحياز",
    "انخاخ",
    "انخذال",
    "انخراط",
    "انخرام",
    "انخرط",
    "انخلع",
    "انداء",
    "انداب",
    "انداد",
    "اندحار",
    "اندر",
    "اندفاعا",
    "اندفاعة",
    "اندق",
    "اندمار",
    "اندونيسيا",
    "اندية",
    "اندھاش",
    "انذار",
    "انذال",
    "انذر",
    "انذره",
    "انزاح",
    "انزال",
    "انزعاج",
    "انزل",
    "انزلاق",
    "انزله",
    "انزلوھم",
    "انزواء",
    "انزوى",
    "انس",
    "انساج",
    "انساق",
    "انسال",
    "انسام",
    "انسانة",
    "انسانى",
    "انسانية",
    "انسب",
    "انسباء",
    "انسجام",
    "انسجة",
    "انسحاب",
    "انسحاق",
    "انسرح",
    "انسرى",
    "انسلال",
    "انسى",
    "انسي",
    "انسيابي",
    "انش",
    "انشاءات",
    "انشائي",
    "انشائية",
    "انشاد",
    "انشاز",
    "انشراح",
    "انشرح",
    "انشطار",
    "انشعاب",
    "انشغف",
    "انشقاق",
    "انشوجة",
    "انشودة",
    "انشوطة",
    "انصاب",
    "انصار",
    "انصارا",
    "انصاف",
    "انصباء",
    "انصبة",
    "انصراف",
    "انصل",
    "انصھار",
    "انضاء",
    "انضاد",
    "انضباط",
    "انضغاط",
    "انضمام",
    "انطاع",
    "انطاكية",
    "انطلاق",
    "انطماس",
    "انطواء",
    "انطولوجى",
    "انطوى",
    "انظار",
    "انظاره",
    "انظف",
    "انظمة",
    "انعاش",
    "انعام",
    "انعتزالية",
    "انعزال",
    "انعطاف",
    "انعقاد",
    "انعكاسي",
    "انعكاسية",
    "انعل",
    "انعم",
    "انغام",
    "انغلاق",
    "انف",
    "انفاذ",
    "انفار",
    "انفاس",
    "انفاسه",
    "انفاق",
    "انفال",
    "انفة",
    "انفتحت",
    "انفتل",
    "انفراج",
    "انفراد",
    "انفرادي",
    "انفرة",
    "انفرس",
    "انفرط",
    "انفس",
    "انفساح",
    "انفسحت",
    "انفسھم",
    "انفصالي",
    "انفصالية",
    "انفضاض",
    "انفطر",
    "انفعال",
    "انفعالي",
    "انفعالية",
    "انفكاك",
    "انفلات",
    "انفلونزا",
    "انفه",
    "انفى",
    "انق",
    "انقاب",
    "انقاذ",
    "انقاص",
    "انقاض",
    "انقبة",
    "انقبض",
    "انقره",
    "انقضاض",
    "انقضاضية",
    "انقطاعية",
    "انقع",
    "انقلاب",
    "انقليس",
    "انقياد",
    "انكاد",
    "انكار",
    "انكاري",
    "انكال",
    "انكب",
    "انكباب",
    "انكد",
    "انكرت",
    "انكسار",
    "انكساف",
    "انكسرت",
    "انكشارى",
    "انكشاري",
    "انكفاء",
    "انكلترا",
    "انكلترة",
    "انكلوسكسون",
    "انكلوسكسونية",
    "انكليزى",
    "انكليزي",
    "انكليزية",
    "انكليس",
    "انكماش",
    "انكى",
    "انما",
    "انماء",
    "انمار",
    "انماط",
    "انمحق",
    "انمحى",
    "انمر",
    "انمش",
    "انملة",
    "انموذج",
    "انمون",
    "انواء",
    "انوار",
    "انواط",
    "انواعھم",
    "انواف",
    "انواك",
    "انوال",
    "انوان",
    "انوثة",
    "انور",
    "انوف",
    "انوق",
    "انى",
    "اني",
    "انياب",
    "انيابه",
    "انيار",
    "انيس",
    "انيسون",
    "انيق",
    "انيميا",
    "انين",
    "انھا",
    "انھاء",
    "انھار",
    "انھاض",
    "انھاك",
    "انھال",
    "انھج",
    "انھر",
    "انھزام",
    "انھزامي",
    "انھزامية",
    "انھضام",
    "انھماك",
    "انھيار",
    "انھيال",
    "اوائل",
    "اوائله",
    "اوابد",
    "اواخر",
    "اوادم",
    "اوار",
    "اواسط",
    "اواش",
    "اواصر",
    "اواطب",
    "اواع",
    "اوام",
    "اوامر",
    "اوان",
    "اوانس",
    "اوانه",
    "اواه",
    "اوب",
    "اوبئة",
    "اوباء",
    "اوبار",
    "اوباش",
    "اوبة",
    "اوبر",
    "اوبرا",
    "اوبريت",
    "اوت",
    "اوتاد",
    "اوتار",
    "اوتجراف",
    "اوتشك",
    "اوتنة",
    "اوتوبيس",
    "اوتوقراطي",
    "اوتوموبيل",
    "اوتيل",
    "اوثان",
    "اوثق",
    "اوج",
    "اوجار",
    "اوجاع",
    "اوجاق",
    "اوجال",
    "اوجرة",
    "اوجس",
    "اوجه",
    "اوح",
    "اوحال",
    "اوحد",
    "اوحي",
    "اوخم",
    "اود",
    "اوداج",
    "اوداجه",
    "اوداد",
    "اودة",
    "اودع",
    "اودعه",
    "اوده",
    "اودى",
    "اودية",
    "اوراب",
    "اوراد",
    "اوراع",
    "اوراق",
    "اوراك",
    "اورال",
    "اورام",
    "اوربا",
    "اوربي",
    "اوردة",
    "اورشليم",
    "اورط",
    "اورطة",
    "اورغوي",
    "اوركسترا",
    "اورنيك",
    "اوره",
    "اوروجواي",
    "اوروپا",
    "اورى",
    "اوز",
    "اوزار",
    "اوزارھا",
    "اوزاع",
    "اوزان",
    "اوساخ",
    "اوساط",
    "اوساق",
    "اوستراليا",
    "اوستريا",
    "اوسط",
    "اوسطى",
    "اوسع",
    "اوسعه",
    "اوسلو",
    "اوسمة",
    "اوشاب",
    "اوشال",
    "اوشحة",
    "اوشية",
    "اوصاب",
    "اوصاف",
    "اوصافه",
    "اوصال",
    "اوصاله",
    "اوصاه",
    "اوصد",
    "اوصل",
    "اوصياء",
    "اوضاح",
    "اوضار",
    "اوضاع",
    "اوضام",
    "اوضح",
    "اوطأ",
    "اوطاب",
    "اوطاد",
    "اوطار",
    "اوطان",
    "اوطف",
    "اوعار",
    "اوعاس",
    "اوعال",
    "اوعر",
    "اوعية",
    "اوغاد",
    "اوغر",
    "اوغل",
    "اوفاد",
    "اوفاز",
    "اوفر",
    "اوفرول",
    "اوفق",
    "اوفى",
    "اوفياء",
    "اوق",
    "اوقاب",
    "اوقات",
    "اوقاتا",
    "اوقار",
    "اوقاف",
    "اوقة",
    "اوقد",
    "اوقع",
    "اوقعه",
    "اوقف",
    "اوقفه",
    "اوقية",
    "اوكار",
    "اوكازيون",
    "اوكسجين",
    "اوكية",
    "اولئك",
    "اولا",
    "اولاء",
    "اولائك",
    "اولائكم",
    "اولاد",
    "اولاه",
    "اولدھا",
    "اولمبى",
    "اولمبياد",
    "اوله",
    "اولو",
    "اولوا",
    "اولى",
    "اولياء",
    "اولية",
    "اومنيبوس",
    "اون",
    "اونباشى",
    "اونباشي",
    "اوه",
    "اوى",
    "اويقات",
    "اويقيانس",
    "اويقيانوس",
    "اوھاق",
    "اوھام",
    "اوھد",
    "اى",
    "ايا",
    "ايائل",
    "ايائم",
    "اياب",
    "اياد",
    "ايادي",
    "ايار",
    "اياس",
    "اياك",
    "ايالة",
    "ايام",
    "اياما",
    "ايامه",
    "ايامى",
    "ايان",
    "اية",
    "ايتام",
    "ايتھا",
    "ايجاب",
    "ايجابا",
    "ايجابي",
    "ايجابية",
    "ايجار",
    "ايجاز",
    "ايجازا",
    "ايحاء",
    "ايحاش",
    "ايد",
    "ايداع",
    "ايدروجين",
    "ايدروجينية",
    "ايدي",
    "ايديكم",
    "ايدينا",
    "ايديھم",
    "ايذاء",
    "ايذان",
    "ايذنا",
    "اير",
    "ايراد",
    "ايرانى",
    "ايرلندا",
    "ايرلندى",
    "ايريال",
    "ايزيس",
    "ايس",
    "ايسر",
    "ايشرب",
    "ايصاء",
    "ايصال",
    "ايض",
    "ايضا",
    "ايضاح",
    "ايضاحي",
    "ايطالى",
    "ايطاليا",
    "ايعاد",
    "ايعاز",
    "ايعازي",
    "ايفاء",
    "ايفاد",
    "ايفاع",
    "ايقاظ",
    "ايقاع",
    "ايقاعي",
    "ايقاف",
    "ايقونة",
    "ايقونية",
    "ايك",
    "ايل",
    "ايلاء",
    "ايلاج",
    "ايلول",
    "ايلولة",
    "ايم",
    "ايما",
    "ايماء",
    "ايمان",
    "ايمانھم",
    "ايمة",
    "ايمن",
    "اين",
    "ايناس",
    "ايناع",
    "اينما",
    "ايواء",
    "ايوان",
    "ايوب",
    "ايور",
    "ايوم",
    "ايون",
    "ايھا",
    "ايھاب",
    "ايھام",
    "اڤلاط",
    "اھاب",
    "اھابط",
    "اھابه",
    "اھاجي",
    "اھازيج",
    "اھال",
    "اھاليل",
    "اھانة",
    "اھانه",
    "اھب",
    "اھباء",
    "اھبة",
    "اھبته",
    "اھبل",
    "اھبوطة",
    "اھتار",
    "اھتبل",
    "اھتز",
    "اھتزاز",
    "اھتزازة",
    "اھتم",
    "اھتمام",
    "اھتمامه",
    "اھجوة",
    "اھجية",
    "اھداء",
    "اھداب",
    "اھداف",
    "اھدام",
    "اھدل",
    "اھدى",
    "اھراء",
    "اھراق",
    "اھرام",
    "اھرامات",
    "اھرامي",
    "اھزوجة",
    "اھطع",
    "اھكومة",
    "اھل",
    "اھلا",
    "اھلاب",
    "اھلب",
    "اھلة",
    "اھلك",
    "اھله",
    "اھلي",
    "اھلية",
    "اھليلج",
    "اھليلجي",
    "اھم",
    "اھماج",
    "اھمال",
    "اھمام",
    "اھمل",
    "اھمية",
    "اھواء",
    "اھوار",
    "اھوال",
    "اھوان",
    "اھوج",
    "اھوس",
    "اھوسة",
    "اھول",
    "اھون",
    "اھوناء",
    "اھونه",
    "اھوى",
    "اھوية",
    "اھيف",
    "بآمال",
    "بأبأ",
    "بأثر",
    "بأجله",
    "بأدبه",
    "بأذيال",
    "بأر",
    "بأرضنا",
    "بأز",
    "بأساء",
    "بأسباب",
    "بأسبابه",
    "بأعباء",
    "بأقدم",
    "بأنظاره",
    "بأنفه",
    "بأوربا",
    "بأوقاتھا",
    "بأھداب",
    "بؤبؤ",
    "بؤساء",
    "بؤسى",
    "بؤوز",
    "بؤونة",
    "بإدانته",
    "بإرشاده",
    "بئار",
    "بئر",
    "بئزان",
    "با",
    "بائت",
    "بائد",
    "بائر",
    "بائض",
    "بائقة",
    "بائن",
    "بائنة",
    "باباوات",
    "باباوى",
    "بابة",
    "بابتسامة",
    "بابلى",
    "بابنك",
    "بابه",
    "بابوات",
    "بابوج",
    "بابور",
    "بابونج",
    "بابوى",
    "بابوية",
    "باتسته",
    "باتصفيق",
    "باتع",
    "باثولوجى",
    "باثولوجيا",
    "باجمعه",
    "باجمعھم",
    "باح",
    "باخ",
    "باخس",
    "باد",
    "بادأه",
    "بادئ",
    "بادر",
    "بادرة",
    "بادره",
    "بادى",
    "بادية",
    "باذخ",
    "باذل",
    "باذن",
    "باذنجان",
    "باذياله",
    "باراشوت",
    "باراغواى",
    "باربوني",
    "بارة",
    "بارت",
    "بارجة",
    "بارح",
    "باردة",
    "بارق",
    "بارقة",
    "باركيه",
    "بارنامج",
    "بارودة",
    "باريسى",
    "باريھا",
    "بازاء",
    "بازار",
    "بازلت",
    "بازوبند",
    "باستثناء",
    "باستيل",
    "باستھزاء",
    "باسره",
    "باسرھم",
    "باسطة",
    "باسق",
    "باسور",
    "باسھاب",
    "باش",
    "باشا",
    "باشاوات",
    "باشجاويش",
    "باشحكيم",
    "باشريش",
    "باشق",
    "باشكاتب",
    "باشكير",
    "باشمفتش",
    "باشمفتى",
    "باشمھندس",
    "باشوات",
    "باص",
    "باصرة",
    "باض",
    "باطان",
    "باطن",
    "باطنا",
    "باطنية",
    "باطون",
    "باطية",
    "باعتبار",
    "باعتباره",
    "باعوث",
    "باغة",
    "بافتراءات",
    "باقرب",
    "باقعة",
    "باكر",
    "باكرا",
    "باكرة",
    "باكستانى",
    "باكمله",
    "باكملھا",
    "باكور",
    "باكورة",
    "باكية",
    "بالأجر",
    "بالأجل",
    "بالأحمر",
    "بالأداء",
    "بالأسعار",
    "بالأقل",
    "بالأمل",
    "بالأولى",
    "بالإسلام",
    "بالإعدام",
    "بالإكراه",
    "بالإنجيل",
    "بالإياب",
    "بالإيجاب",
    "بالإيجاز",
    "بالا",
    "بالاتفاق",
    "بالاجازة",
    "بالاجماع",
    "بالاجمال",
    "بالاحالة",
    "بالاحرف",
    "بالاحرى",
    "بالاحكام",
    "بالاختصار",
    "بالاشتراك",
    "بالاصالة",
    "بالاضافة",
    "بالاعدام",
    "بالامتحان",
    "بالامس",
    "بالانتساب",
    "بالباع",
    "بالبحر",
    "بالبداھة",
    "بالبكاء",
    "بالبنان",
    "بالة",
    "بالتالى",
    "بالتبع",
    "بالتبعية",
    "بالتتابع",
    "بالتدابير",
    "بالتداول",
    "بالتدرج",
    "بالتدريج",
    "بالتراب",
    "بالتزاماته",
    "بالتسلسل",
    "بالتسليم",
    "بالتشھير",
    "بالتصديق",
    "بالتفرقة",
    "بالتفريق",
    "بالتفصيل",
    "بالتقريب",
    "بالتلفزة",
    "بالتمام",
    "بالتناو",
    "بالتوصية",
    "بالثلاثة",
    "بالجراح",
    "بالجرم",
    "بالجريمة",
    "بالحائط",
    "بالحديث",
    "بالحديد",
    "بالحرام",
    "بالحرف",
    "بالحرى",
    "بالحري",
    "بالحصر",
    "بالحضن",
    "بالحمل",
    "بالحيوان",
    "بالخسائر",
    "بالخيار",
    "بالخيبة",
    "بالدار",
    "بالدموع",
    "بالدور",
    "بالدون",
    "بالذمة",
    "بالذھب",
    "بالذھن",
    "بالراحة",
    "بالرترتيب",
    "بالرسمي",
    "بالرصاص",
    "بالرفاء",
    "بالزانة",
    "بالزوال",
    "بالزور",
    "بالسقوط",
    "بالسلاح",
    "بالسلطة",
    "بالسين",
    "بالشؤون",
    "بالشباب",
    "بالشطرنج",
    "بالشكال",
    "بالشكر",
    "بالشكوى",
    "بالشملة",
    "بالشيء",
    "بالصمت",
    "بالضاد",
    "بالضحك",
    "بالضراعة",
    "بالضرب",
    "بالطريحة",
    "بالطلاق",
    "بالطو",
    "بالطوات",
    "بالطول",
    "بالعادة",
    "بالعار",
    "بالعداوة",
    "بالعطاف",
    "بالغصب",
    "بالفارغ",
    "بالفتق",
    "بالفشل",
    "بالفضول",
    "بالفطام",
    "بالقذة",
    "بالقراءة",
    "بالقطاعي",
    "بالقنابل",
    "بالك",
    "بالكاشف",
    "بالكاف",
    "بالكتابة",
    "بالكفاية",
    "بالكل",
    "بالكلية",
    "بالكناية",
    "بالكورجة",
    "بالكھرباء",
    "باللاسلكي",
    "باللياقة",
    "بالمال",
    "بالمجان",
    "بالمجمل",
    "بالمدة",
    "بالمراضاة",
    "بالمرة",
    "بالمرصاد",
    "بالمروحة",
    "بالمسحة",
    "بالمصاريف",
    "بالمعين",
    "بالمفردات",
    "بالمفرق",
    "بالمفھوم",
    "بالمقابلة",
    "بالمقطوعية",
    "بالمقلوب",
    "بالمكان",
    "بالمكشوف",
    "بالمناوبة",
    "بالمنحرف",
    "بالمنطوق",
    "بالمينا",
    "بالميناه",
    "بالنابل",
    "بالنار",
    "بالنبع",
    "بالنتيجة",
    "بالنجاح",
    "بالنسبة",
    "بالنصر",
    "بالنعل",
    "بالنعمة",
    "بالنفاذ",
    "بالنقد",
    "بالنقط",
    "بالنواجذ",
    "بالنوبة",
    "بالنور",
    "بالنيابة",
    "بالنھار",
    "باله",
    "بالواجب",
    "بالورب",
    "بالوساطة",
    "بالوعة",
    "بالوعود",
    "بالوفاء",
    "بالوفقة",
    "بالوكوس",
    "بالون",
    "بالويل",
    "بالياطاش",
    "بالھاتف",
    "بالھموم",
    "بالھواء",
    "بامره",
    "باميا",
    "بامية",
    "بانتصاف",
    "بانتھاء",
    "بانظاره",
    "بانفه",
    "باه",
    "باوده",
    "باى",
    "باية",
    "بايخ",
    "بايديھم",
    "بايرق",
    "باھانة",
    "باھت",
    "باھداب",
    "باھدابه",
    "باھدافه",
    "باھر",
    "باھظ",
    "باھظا",
    "باھل",
    "باھم",
    "بباله",
    "ببر",
    "ببراءته",
    "ببصره",
    "ببطء",
    "ببغاوات",
    "ببنان",
    "ببور",
    "ببيت",
    "ببھائه",
    "بتا",
    "بتاتا",
    "بتاتى",
    "بتار",
    "بتة",
    "بتدقيق",
    "بتراء",
    "بترول",
    "بتسلسل",
    "بتسليم",
    "بتصرف",
    "بتصريح",
    "بتك",
    "بتل",
    "بتلابيبه",
    "بتمام",
    "بتمھل",
    "بتواصل",
    "بتوقيع",
    "بتول",
    "بتولا",
    "بتولية",
    "بتى",
    "بتية",
    "بتيومين",
    "بثرة",
    "بثق",
    "بثور",
    "بثير",
    "بجأش",
    "بجح",
    "بجدة",
    "بجدتھا",
    "بجدع",
    "بجراح",
    "بجرادھا",
    "بجرانه",
    "بجرة",
    "بجس",
    "بجع",
    "بجلاء",
    "بجم",
    "بجمع",
    "بجن",
    "بجنوب",
    "بجوار",
    "بجوم",
    "بجيس",
    "بجھد",
    "بح",
    "بحائر",
    "بحاث",
    "بحاثة",
    "بحاح",
    "بحاحة",
    "بحال",
    "بحاله",
    "بحبح",
    "بحبه",
    "بحبوح",
    "بحبوحة",
    "بحة",
    "بحتا",
    "بحتر",
    "بحتري",
    "بحثر",
    "بحجة",
    "بحجر",
    "بحح",
    "بحذاء",
    "بحذافيره",
    "بحران",
    "بحراني",
    "بحرة",
    "بحرف",
    "بحروفھا",
    "بحرى",
    "بحضوره",
    "بحقه",
    "بحقوقه",
    "بحكم",
    "بحلق",
    "بحمل",
    "بحوث",
    "بحوثات",
    "بحوح",
    "بحوحة",
    "بحياته",
    "بخاخة",
    "بخارية",
    "بخاشيش",
    "بخاطره",
    "بخانق",
    "بخت",
    "بختر",
    "بخدمته",
    "بخشيش",
    "بخع",
    "بخفي",
    "بخقاء",
    "بخلاف",
    "بخناقه",
    "بخنق",
    "بخور",
    "بخياله",
    "بخيت",
    "بخيخة",
    "بخيره",
    "بدأة",
    "بدئه",
    "بداءة",
    "بدائع",
    "بدائه",
    "بداة",
    "بدار",
    "بدافع",
    "بدال",
    "بدالة",
    "بداوة",
    "بداھة",
    "بدرات",
    "بدرة",
    "بدروم",
    "بدرون",
    "بدع",
    "بدعة",
    "بدعوى",
    "بدلاء",
    "بدلوه",
    "بدلية",
    "بدن",
    "بده",
    "بدو",
    "بدوات",
    "بدوره",
    "بدوري",
    "بدونة",
    "بديئة",
    "بديعة",
    "بديعي",
    "بديلة",
    "بديھة",
    "بديھي",
    "بديھية",
    "بدھي",
    "بذ",
    "بذأ",
    "بذؤ",
    "بذئ",
    "بذاء",
    "بذاته",
    "بذاذة",
    "بذار",
    "بذخ",
    "بذراع",
    "بذراعيه",
    "بذريعة",
    "بذكره",
    "بذكرياتي",
    "بذلة",
    "بذمته",
    "بذنجان",
    "برء",
    "برأسه",
    "برأي",
    "برأيه",
    "برئيه",
    "برائز",
    "برائق",
    "برابخ",
    "برابرة",
    "برابى",
    "براثن",
    "براجل",
    "براجم",
    "براجواى",
    "براح",
    "براحا",
    "براد",
    "برادة",
    "براديق",
    "براذع",
    "براذعى",
    "براذين",
    "برارى",
    "براريك",
    "برازخ",
    "براسيرى",
    "براطيل",
    "براعته",
    "براعم",
    "براعيم",
    "براغى",
    "براغيث",
    "برافان",
    "برافير",
    "براقع",
    "براكين",
    "برام",
    "برامة",
    "برامق",
    "براميل",
    "برانس",
    "برانسى",
    "برانى",
    "برانيس",
    "برانيط",
    "براويز",
    "برايا",
    "براية",
    "براھما",
    "براھمة",
    "براھين",
    "بربا",
    "برباط",
    "بربخ",
    "بربر",
    "بربرى",
    "بربط",
    "بربون",
    "بربونى",
    "بربى",
    "بربيس",
    "بربيش",
    "برتغال",
    "برتغالى",
    "برتقال",
    "برتقالى",
    "برتقان",
    "برتقانى",
    "برثن",
    "برجل",
    "برجمة",
    "برجوع",
    "برح",
    "برداء",
    "برداق",
    "برداية",
    "بردة",
    "بردخ",
    "بردعة",
    "بردقان",
    "بردقوش",
    "بردورة",
    "بردى",
    "بردية",
    "برذعة",
    "برذون",
    "برزان",
    "برزوق",
    "برسام",
    "برسيم",
    "برش",
    "برشام",
    "برشامة",
    "برشامجى",
    "برشان",
    "برشت",
    "برشده",
    "برشم",
    "برشمة",
    "برشمجية",
    "برص",
    "برصة",
    "برض",
    "برطع",
    "برطعة",
    "برطل",
    "برطلة",
    "برطم",
    "برطمان",
    "برطوز",
    "برطوم",
    "برطيل",
    "برع",
    "برعايته",
    "برعم",
    "برعوم",
    "برغش",
    "برغل",
    "برغوث",
    "برغى",
    "برفقة",
    "برفير",
    "برقاب",
    "برقت",
    "برقش",
    "برقع",
    "برقوق",
    "برقى",
    "برقية",
    "برك",
    "بركار",
    "بركانى",
    "برلمان",
    "برلمانى",
    "برلمانية",
    "برلنتى",
    "برم",
    "برما",
    "برمائى",
    "برماننت",
    "برمة",
    "برمته",
    "برمتھا",
    "برمق",
    "برمنكھام",
    "برمودة",
    "برمھات",
    "برنجك",
    "برنس",
    "برنسيسة",
    "برنط",
    "برنطية",
    "برنوس",
    "برنية",
    "بره",
    "برواز",
    "بروة",
    "بروتستانتي",
    "بروتستانتية",
    "بروتستو",
    "بروتون",
    "بروجى",
    "بروحه",
    "برود",
    "برودة",
    "بروض",
    "بروعه",
    "بروفة",
    "بروق",
    "برونز",
    "برونزى",
    "بروڤة",
    "برية",
    "بريدى",
    "بريز",
    "بريزة",
    "بريقه",
    "بريم",
    "بريمة",
    "بريمية",
    "برھان",
    "برھة",
    "برھتين",
    "برھم",
    "برھمن",
    "برھمية",
    "برھن",
    "برھنة",
    "بزابيز",
    "بزاة",
    "بزار",
    "بزازة",
    "بزاق",
    "بزاقة",
    "بزال",
    "بزبوز",
    "بزة",
    "بزر",
    "بزغ",
    "بزغبره",
    "بزق",
    "بزل",
    "بزلا",
    "بزلة",
    "بزمامه",
    "بزموت",
    "بزنطى",
    "بزور",
    "بزوز",
    "بزوغ",
    "بزيد",
    "بزيرة",
    "بسء",
    "بسأ",
    "بسائط",
    "بساتن",
    "بساتين",
    "بسار",
    "بسارابيا",
    "بساس",
    "بساطة",
    "بساعة",
    "بسالة",
    "بسام",
    "بسباس",
    "بسباسة",
    "بسبوسة",
    "بسبيل",
    "بسة",
    "بستان",
    "بستانى",
    "بستن",
    "بستنة",
    "بستون",
    "بستيلية",
    "بسخة",
    "بسداد",
    "بسر",
    "بسركم",
    "بسطاء",
    "بسطرمة",
    "بسعة",
    "بسق",
    "بسكليت",
    "بسكوت",
    "بسكويت",
    "بسلاء",
    "بسلة",
    "بسمعه",
    "بسمل",
    "بسنة",
    "بسواء",
    "بسوطه",
    "بسوق",
    "بسيفه",
    "بسيكولوجى",
    "بسينة",
    "بسھم",
    "بسھولة",
    "بش",
    "بشأن",
    "بشأنه",
    "بشاشة",
    "بشاعة",
    "بشاك",
    "بشاكير",
    "بشامرى",
    "بشانيق",
    "بشبر",
    "بشت",
    "بشتة",
    "بشراء",
    "بشرط",
    "بشرفه",
    "بشرق",
    "بشروش",
    "بشقه",
    "بشكره",
    "بشكله",
    "بشكور",
    "بشكير",
    "بشلة",
    "بشم",
    "بشمار",
    "بشمق",
    "بشنة",
    "بشنس",
    "بشنوقة",
    "بشنين",
    "بص",
    "بصائص",
    "بصارة",
    "بصاع",
    "بصاق",
    "بصبص",
    "بصبصة",
    "بصة",
    "بصحته",
    "بصخة",
    "بصدد",
    "بصدده",
    "بصدره",
    "بصر",
    "بصراء",
    "بصرف",
    "بصرى",
    "بصريات",
    "بصفة",
    "بصفته",
    "بصفقة",
    "بصق",
    "بصقة",
    "بصل",
    "بصلى",
    "بصليب",
    "بصنعه",
    "بصوابه",
    "بصوة",
    "بصوته",
    "بصيص",
    "بصيلة",
    "بضعة",
    "بطء",
    "بطؤ",
    "بطئ",
    "بطاء",
    "بطاءة",
    "بطائح",
    "بطائق",
    "بطائل",
    "بطائن",
    "بطابعه",
    "بطاح",
    "بطارخ",
    "بطارقة",
    "بطاركة",
    "بطارية",
    "بطاطين",
    "بطال",
    "بطالة",
    "بطالسة",
    "بطان",
    "بطباعه",
    "بطبط",
    "بطبطة",
    "بطبيعة",
    "بطة",
    "بطح",
    "بطحاء",
    "بطحاوات",
    "بطر",
    "بطرخ",
    "بطرس",
    "بطرشيل",
    "بطرشين",
    "بطرف",
    "بطرفة",
    "بطرك",
    "بطركية",
    "بطريركية",
    "بطريك",
    "بطشة",
    "بطعنة",
    "بطق",
    "بطلة",
    "بطلق",
    "بطم",
    "بطنا",
    "بطنة",
    "بطنى",
    "بطول",
    "بطىء",
    "بطيحة",
    "بطيخ",
    "بظ",
    "بظر",
    "بظرف",
    "بظلفه",
    "بظور",
    "بظھر",
    "بعابع",
    "بعاداته",
    "بعارين",
    "بعاليه",
    "بعبارة",
    "بعبع",
    "بعثة",
    "بعج",
    "بعدئذ",
    "بعداء",
    "بعدان",
    "بعر",
    "بعران",
    "بعرس",
    "بعزق",
    "بعزقة",
    "بعضھم",
    "بعقبه",
    "بعقله",
    "بعكوكة",
    "بعل",
    "بعلبك",
    "بعلة",
    "بعلى",
    "بعملة",
    "بعنايته",
    "بعوث",
    "بعودة",
    "بعوض",
    "بعول",
    "بعولة",
    "بعيدة",
    "بعينيه",
    "بعھدته",
    "بغاء",
    "بغاة",
    "بغاث",
    "بغادة",
    "بغاددة",
    "بغاشة",
    "بغاضة",
    "بغال",
    "بغايا",
    "بغثان",
    "بغدادى",
    "بغدادي",
    "بغدد",
    "بغز",
    "بغش",
    "بغشة",
    "بغشت",
    "بغضاء",
    "بغضة",
    "بغل",
    "بغلة",
    "بغية",
    "بفارغ",
    "بفاعل",
    "بفتة",
    "بفتيك",
    "بق",
    "بقاء",
    "بقار",
    "بقاشيش",
    "بقاع",
    "بقاق",
    "بقال",
    "بقالة",
    "بقام",
    "بقباق",
    "بقبق",
    "بقبقة",
    "بقبوقة",
    "بقج",
    "بقجة",
    "بقدونس",
    "بقر",
    "بقرب",
    "بقرى",
    "بقس",
    "بقسمات",
    "بقشة",
    "بقشيش",
    "بقضيضھم",
    "بقل",
    "بقلاوا",
    "بقلاوة",
    "بقلمه",
    "بقليته",
    "بقليتھم",
    "بقليل",
    "بقيد",
    "بكاء",
    "بكاة",
    "بكارتھا",
    "بكارج",
    "بكاسين",
    "بكالوريا",
    "بكامله",
    "بكباشى",
    "بكبدك",
    "بكتيرى",
    "بكثير",
    "بكذبة",
    "بكران",
    "بكرتھا",
    "بكرتھم",
    "بكرج",
    "بكرسي",
    "بكرى",
    "بكرية",
    "بكسماد",
    "بكسماط",
    "بكلام",
    "بكلامه",
    "بكلاه",
    "بكلة",
    "بكلكله",
    "بكلوريوس",
    "بكليته",
    "بكماء",
    "بكماله",
    "بكوات",
    "بكور",
    "بكورة",
    "بكورى",
    "بكورية",
    "بكوية",
    "بكيء",
    "بكير",
    "بلابل",
    "بلاتين",
    "بلاج",
    "بلاجرا",
    "بلادا",
    "بلارج",
    "بلاشفة",
    "بلاص",
    "بلاط",
    "بلاطة",
    "بلاطى",
    "بلاعة",
    "بلاعم",
    "بلاعيم",
    "بلاغم",
    "بلاقع",
    "بلاليص",
    "بلاليع",
    "بلالين",
    "بلان",
    "بلانة",
    "بلايا",
    "بلايين",
    "بلاھة",
    "بلاھين",
    "بلبال",
    "بلبط",
    "بلبل",
    "بلبلة",
    "بلبه",
    "بلة",
    "بلج",
    "بلجيكى",
    "بلحمه",
    "بلحية",
    "بلدان",
    "بلدى",
    "بلدية",
    "بلزقه",
    "بلسانه",
    "بلسم",
    "بلشف",
    "بلشفة",
    "بلشفى",
    "بلشفية",
    "بلشون",
    "بلص",
    "بلصقي",
    "بلطة",
    "بلطجى",
    "بلطو",
    "بلطى",
    "بلطيق",
    "بلع",
    "بلعة",
    "بلعم",
    "بلعه",
    "بلعوم",
    "بلغاء",
    "بلغارى",
    "بلغم",
    "بلغمى",
    "بلغمي",
    "بلغه",
    "بلف",
    "بلفة",
    "بلقانى",
    "بلقع",
    "بلقعة",
    "بلقيس",
    "بلك",
    "بلكون",
    "بلن",
    "بلنسية",
    "بلو",
    "بلوج",
    "بلورى",
    "بلورية",
    "بلوظة",
    "بلوعة",
    "بلوى",
    "بلي",
    "بلياتشو",
    "بلياردو",
    "بلية",
    "بليسيه",
    "بليلاه",
    "بليلة",
    "بلھارسيا",
    "بلھجة",
    "بلھنية",
    "بلھوان",
    "بلھون",
    "بمباغ",
    "بمباغة",
    "بمباى",
    "بمبلغ",
    "بمثابة",
    "بمجامع",
    "بمجرد",
    "بمحض",
    "بمحضر",
    "بمخنقه",
    "بمداد",
    "بمدرسة",
    "بمراحل",
    "بمرثاة",
    "بمرور",
    "بمزيد",
    "بمشيئة",
    "بمضي",
    "بمعدل",
    "بمعزل",
    "بمعقد",
    "بمعية",
    "بمفرده",
    "بمقامه",
    "بمقتضى",
    "بمقدساتا",
    "بمكان",
    "بمنأى",
    "بمناسبة",
    "بمنتھى",
    "بمنجاة",
    "بمنكبيه",
    "بمننزلة",
    "بمنه",
    "بمواجھة",
    "بمھمة",
    "بنائى",
    "بنابه",
    "بناة",
    "بنادر",
    "بنادقة",
    "بنادورة",
    "بناديق",
    "بنارس",
    "بناره",
    "بنازلة",
    "بناصر",
    "بناصره",
    "بناصيته",
    "بنانك",
    "بناية",
    "بنباشى",
    "بنته",
    "بنتو",
    "بنجر",
    "بندر",
    "بندقانى",
    "بندقة",
    "بندقى",
    "بندقية",
    "بندورة",
    "بندورى",
    "بندوق",
    "بنديرة",
    "بنزھير",
    "بنس",
    "بنسلين",
    "بنسيه",
    "بنسيون",
    "بنصه",
    "بنط",
    "بنظره",
    "بنغازى",
    "بنفسج",
    "بنفسجى",
    "بنفسوسنا",
    "بنفش",
    "بنقة",
    "بنكنوت",
    "بنوار",
    "بنوال",
    "بنوة",
    "بنود",
    "بنوط",
    "بنوع",
    "بنوى",
    "بنيقة",
    "بنيو",
    "بوء",
    "بوأ",
    "بوائض",
    "بوائق",
    "بواب",
    "بوابج",
    "بوابل",
    "بوابير",
    "بواجب",
    "بواجباته",
    "بواخ",
    "بوادر",
    "بوادق",
    "بواذخ",
    "بوار",
    "بوارتة",
    "بوارج",
    "بوارق",
    "بوارى",
    "بواريد",
    "بواز",
    "بواسل",
    "بواسير",
    "بواشق",
    "بواصر",
    "بواط",
    "بواطن",
    "بواعث",
    "بواغيز",
    "بواق",
    "بواقع",
    "بواقيل",
    "بواك",
    "بواكر",
    "بواكير",
    "بوالة",
    "بوالس",
    "بوالص",
    "بواليع",
    "بوانى",
    "بوبلين",
    "بوبينة",
    "بوتاس",
    "بوتاسا",
    "بوتقة",
    "بوجرام",
    "بوجية",
    "بوجيز",
    "بوجھه",
    "بوجھھا",
    "بوح",
    "بوخ",
    "بودرة",
    "بودقة",
    "بودنا",
    "بوذى",
    "بور",
    "بورانى",
    "بورتغال",
    "بورتوريكو",
    "بورتوكول",
    "بورصة",
    "بورغى",
    "بورق",
    "بوروجى",
    "بورى",
    "بوريه",
    "بوز",
    "بوزة",
    "بوس",
    "بوساطة",
    "بوسة",
    "بوستة",
    "بوستو",
    "بوسطة",
    "بوسيلة",
    "بوص",
    "بوصة",
    "بوصلة",
    "بوطة",
    "بوظة",
    "بوع",
    "بوعده",
    "بوغ",
    "بوغادة",
    "بوغاز",
    "بوغاضة",
    "بوفيه",
    "بوق",
    "بوقال",
    "بوقالة",
    "بوقته",
    "بوقوع",
    "بوكسفورد",
    "بوكسكاف",
    "بولاد",
    "بولاق",
    "بولصة",
    "بولندة",
    "بولندى",
    "بولوني",
    "بولى",
    "بولية",
    "بوليسة",
    "بوليسية",
    "بوليصة",
    "بوليفى",
    "بونسايرس",
    "بونى",
    "بوياجى",
    "بوية",
    "بويت",
    "بويجى",
    "بويخرة",
    "بويضة",
    "بوھيمي",
    "بوھيميا",
    "بوھيمية",
    "بيات",
    "بياتة",
    "بيادة",
    "بيادر",
    "بيادق",
    "بيادى",
    "بيارة",
    "بيارتة",
    "بياضات",
    "بياطرة",
    "بيانا",
    "بيانة",
    "بيانى",
    "بيبان",
    "بيبة",
    "بية",
    "بيتى",
    "بيجاما",
    "بيداء",
    "بيداوات",
    "بيدر",
    "بيدق",
    "بيديه",
    "بيذق",
    "بيذنجان",
    "بيرق",
    "بيرقدار",
    "بيروتى",
    "بيروقراطى",
    "بيزان",
    "بيزنطيا",
    "بيسون",
    "بيص",
    "بيضاوى",
    "بيضوى",
    "بيضى",
    "بيضھا",
    "بيطار",
    "بيطر",
    "بيطرة",
    "بيطرى",
    "بيعة",
    "بيك",
    "بيكار",
    "بيكباشى",
    "بيكه",
    "بيكوات",
    "بيكوية",
    "بيلة",
    "بيلسان",
    "بيليه",
    "بيلھارسيا",
    "بيمارستان",
    "بينباشى",
    "بينتو",
    "بينما",
    "بينونة",
    "بينى",
    "بينھم",
    "بينھما",
    "بيه",
    "بيوتات",
    "بيوريه",
    "بيوع",
    "بيوعات",
    "بييضة",
    "بھا",
    "بھاء",
    "بھائم",
    "بھائى",
    "بھار",
    "بھاليل",
    "بھت",
    "بھتان",
    "بھتة",
    "بھج",
    "بھجة",
    "بھدل",
    "بھدلة",
    "بھدوء",
    "بھذا",
    "بھذه",
    "بھر",
    "بھرة",
    "بھرج",
    "بھرجان",
    "بھرجة",
    "بھريز",
    "بھظ",
    "بھق",
    "بھل",
    "بھلوان",
    "بھلوانى",
    "بھلواني",
    "بھلوانية",
    "بھلول",
    "بھم",
    "بھمة",
    "بھو",
    "بھوات",
    "بھى",
    "بھيج",
    "بھيم",
    "بھيمة",
    "بھيمى",
    "بھيمية",
    "تآزر",
    "تآلف",
    "تآليف",
    "تأبين",
    "تأتأ",
    "تأثري",
    "تأثرية",
    "تأثيري",
    "تأخذني",
    "تأدب",
    "تأديب",
    "تأديبى",
    "تأديبية",
    "تأر",
    "تأزم",
    "تأسف",
    "تأسية",
    "تأسيسى",
    "تأصل",
    "تأصيلة",
    "تأم",
    "تأمر",
    "تأمور",
    "تأميم",
    "تأن",
    "تأنان",
    "تأنس",
    "تأنق",
    "تأنيب",
    "تأنيث",
    "تأوه",
    "تأيم",
    "تأييد",
    "تأيين",
    "تأھب",
    "تؤخر",
    "تؤدة",
    "تؤكل",
    "تا",
    "تاء",
    "تائق",
    "تابعة",
    "تابعية",
    "تابل",
    "تابوت",
    "تابيوكا",
    "تاجرة",
    "تاح",
    "تاريخي",
    "تاريع",
    "تازة",
    "تافل",
    "تافھة",
    "تاق",
    "تاك",
    "تال",
    "تالد",
    "تالف",
    "تاله",
    "تانئ",
    "تاه",
    "تاى",
    "تايور",
    "تايير",
    "تب",
    "تبا",
    "تبائع",
    "تبادر",
    "تبار",
    "تباريح",
    "تباشير",
    "تباطؤ",
    "تباع",
    "تباعة",
    "تباغض",
    "تبان",
    "تباه",
    "تباينى",
    "تبب",
    "تبتيت",
    "تبجيل",
    "تبحر",
    "تبديد",
    "تبذير",
    "تبر",
    "تبرر",
    "تبرية",
    "تبريد",
    "تبرير",
    "تبريز",
    "تبريك",
    "تبسط",
    "تبسم",
    "تبشير",
    "تبصر",
    "تبعة",
    "تبعى",
    "تبعية",
    "تبعيد",
    "تبعيض",
    "تبكيت",
    "تبلبل",
    "تبلد",
    "تبلوه",
    "تبوأ",
    "تبوغ",
    "تبولة",
    "تبويب",
    "تبويخ",
    "تبويزة",
    "تبيان",
    "تبيح",
    "تبيع",
    "تبيوكا",
    "تبييضة",
    "تتابع",
    "تتبعات",
    "تتحلب",
    "تتريك",
    "تتسع",
    "تتقطع",
    "تتك",
    "تتن",
    "تتنوس",
    "تتويج",
    "تتيه",
    "تثاوب",
    "تثقيل",
    "تثليث",
    "تثليثى",
    "تثمين",
    "تثنية",
    "تجاذبوا",
    "تجارب",
    "تجارتھم",
    "تجارى",
    "تجاريد",
    "تجاعيد",
    "تجافيف",
    "تجانس",
    "تجاواف",
    "تجاوب",
    "تجاوبت",
    "تجاھل",
    "تجبر",
    "تجبين",
    "تجدد",
    "تجديف",
    "تجذير",
    "تجربة",
    "تجريب",
    "تجريبى",
    "تجريح",
    "تجريدة",
    "تجزية",
    "تجسس",
    "تجسيم",
    "تجعدات",
    "تجفاف",
    "تجفيف",
    "تجلة",
    "تجلد",
    "تجلية",
    "تجمي",
    "تجميم",
    "تجمھر",
    "تجن",
    "تجند",
    "تجنس",
    "تجنيد",
    "تجنيس",
    "تجوال",
    "تجويد",
    "تجويع",
    "تجھيز",
    "تجھيزات",
    "تجھيزي",
    "تجھيل",
    "تحابب",
    "تحات",
    "تحارير",
    "تحاريق",
    "تحاسد",
    "تحاسين",
    "تحاش",
    "تحاك",
    "تحامل",
    "تحاور",
    "تحاويل",
    "تحايا",
    "تحايلي",
    "تحبب",
    "تحتانى",
    "تحتانية",
    "تحجج",
    "تحجير",
    "تحدر",
    "تحدى",
    "تحديات",
    "تحذير",
    "تحر",
    "تحرج",
    "تحرق",
    "تحريات",
    "تحريج",
    "تحريرية",
    "تحريش",
    "تحريض",
    "تحريف",
    "تحريم",
    "تحزب",
    "تحشم",
    "تحشية",
    "تحصيص",
    "تحصيل",
    "تحصيلجي",
    "تحصين",
    "تحضر",
    "تحطم",
    "تحطيب",
    "تحف",
    "تحفة",
    "تحفز",
    "تحفظي",
    "تحفظية",
    "تحفيظ",
    "تحقتر",
    "تحقيقا",
    "تحكمي",
    "تحكيم",
    "تحكيمات",
    "تحكيمية",
    "تحلب",
    "تحليف",
    "تحليق",
    "تحميلة",
    "تحنان",
    "تحنط",
    "تحنن",
    "تحوط",
    "تحويط",
    "تحويلة",
    "تحيل",
    "تحين",
    "تحييذ",
    "تخ",
    "تخابر",
    "تخارج",
    "تخاشيب",
    "تخالفني",
    "تخت",
    "تختة",
    "تختخ",
    "تختذل",
    "تختروان",
    "تختطب",
    "تختم",
    "تخديم",
    "تخريب",
    "تخريج",
    "تخريف",
    "تخريمة",
    "تخزين",
    "تخشب",
    "تخشيب",
    "تخشيبة",
    "تخصيب",
    "تخطيب",
    "تخلخل",
    "تخلع",
    "تخلية",
    "تخليد",
    "تخليط",
    "تخم",
    "تخمة",
    "تخمير",
    "تخمينا",
    "تخنث",
    "تخوم",
    "تخويف",
    "تخييل",
    "تدابر",
    "تداخل",
    "تداركت",
    "تداع",
    "تداعى",
    "تداو",
    "تداول",
    "تداولته",
    "تداينوا",
    "تدبر",
    "تدبيج",
    "تدجيل",
    "تدرجة",
    "تدرن",
    "تدرني",
    "تدريج",
    "تدريجى",
    "تدريس",
    "تدشين",
    "تدعيم",
    "تدفئة",
    "تدقيق",
    "تدلل",
    "تدليس",
    "تدليسي",
    "تدليل",
    "تدليلا",
    "تدلھت",
    "تدمرى",
    "تدمري",
    "تدميج",
    "تدمير",
    "تدن",
    "تدوم",
    "تدويخ",
    "تدوير",
    "تدويل",
    "تدوين",
    "تدين",
    "تدھور",
    "تذاكر",
    "تذكرجي",
    "تذكير",
    "تذليل",
    "ترؤس",
    "ترائب",
    "ترائك",
    "ترابة",
    "ترابس",
    "ترابى",
    "ترابيزة",
    "ترابيس",
    "ترابيع",
    "تراتيل",
    "تراث",
    "تراجم",
    "تراجمة",
    "تراجيم",
    "تراحيم",
    "تراخ",
    "تراخوما",
    "تراخيص",
    "ترادف",
    "تراس",
    "تراص",
    "تراق",
    "تراكز",
    "تراكيب",
    "ترام",
    "ترامواى",
    "ترامى",
    "ترانم",
    "ترب",
    "ترباس",
    "تربان",
    "تربس",
    "تربع",
    "تربنتين",
    "تربى",
    "تربيزة",
    "تربيع",
    "تربيعة",
    "تربيعى",
    "تربيعي",
    "تربين",
    "تربيوي",
    "ترتب",
    "ترتر",
    "ترتيبات",
    "ترتيل",
    "ترتيلة",
    "ترجمان",
    "ترح",
    "ترحاب",
    "ترحال",
    "ترحيب",
    "ترحيل",
    "ترحيم",
    "ترخيم",
    "ترداد",
    "ترزى",
    "ترزية",
    "ترسانة",
    "ترسحانة",
    "ترسكل",
    "ترسل",
    "ترسم",
    "ترسيب",
    "ترسينة",
    "ترضية",
    "ترع",
    "ترعة",
    "ترغل",
    "ترغلة",
    "ترغيب",
    "ترف",
    "ترفاس",
    "ترفة",
    "ترفض",
    "ترفق",
    "ترفل",
    "ترفيع",
    "ترفيه",
    "ترق",
    "ترقم",
    "ترقوة",
    "ترقية",
    "ترقيد",
    "ترقيع",
    "تركة",
    "تركته",
    "تركز",
    "تركستان",
    "تركه",
    "تركى",
    "ترمبطجى",
    "ترمبيتجى",
    "ترمبيط",
    "ترمبيطة",
    "ترمس",
    "ترمل",
    "ترموس",
    "ترمومتر",
    "ترميد",
    "ترميم",
    "ترنج",
    "ترنحت",
    "ترنيمة",
    "تره",
    "ترو",
    "ترواده",
    "تروب",
    "تروبص",
    "تروية",
    "ترويح",
    "ترويحة",
    "ترويض",
    "ترويق",
    "ترويقة",
    "ترياق",
    "تريبة",
    "تريزا",
    "تريض",
    "تريكة",
    "تريكو",
    "ترييح",
    "ترييم",
    "ترھب",
    "ترھة",
    "ترھل",
    "ترھيب",
    "تزاحم",
    "تزايل",
    "تزفيت",
    "تزكية",
    "تزويج",
    "تزوير",
    "تزيا",
    "تزيى",
    "تزييف",
    "تزھد",
    "تزھير",
    "تسآل",
    "تساءل",
    "تساؤل",
    "تسابيح",
    "تساريح",
    "تسامع",
    "تساو",
    "تساوق",
    "تساير",
    "تساھل",
    "تسبحة",
    "تسبيب",
    "تسبيح",
    "تسبيحة",
    "تسبيخ",
    "تسبيك",
    "تستر",
    "تستيف",
    "تسخط",
    "تسخير",
    "تسد",
    "تسرة",
    "تسرر",
    "تسرع",
    "تسرى",
    "تسرية",
    "تسطير",
    "تسعرة",
    "تسعيرة",
    "تسكسك",
    "تسلط",
    "تسلطن",
    "تسلم",
    "تسليف",
    "تسليفة",
    "تسليم",
    "تسميد",
    "تسنم",
    "تسوس",
    "تسويد",
    "تسويغ",
    "تسويف",
    "تسويفي",
    "تسويق",
    "تسويقة",
    "تسيير",
    "تسھيل",
    "تشائم",
    "تشاكس",
    "تشاكل",
    "تشامخ",
    "تشبيه",
    "تشتيت",
    "تشحم",
    "تشحيم",
    "تشدق",
    "تشديد",
    "تشرط",
    "تشريد",
    "تشريفاتية",
    "تشريق",
    "تشريك",
    "تشرين",
    "تشطيب",
    "تشعع",
    "تشف",
    "تشكك",
    "تشكيلات",
    "تشليح",
    "تشمل",
    "تشمم",
    "تشه",
    "تشوق",
    "تشويق",
    "تشيطن",
    "تشيلى",
    "تشييد",
    "تشھاق",
    "تصادق",
    "تصادم",
    "تصاريح",
    "تصاريف",
    "تصاغر",
    "تصاف",
    "تصالح",
    "تصاميم",
    "تصانيف",
    "تصاوير",
    "تصايح",
    "تصبب",
    "تصبح",
    "تصبيرة",
    "تصحيف",
    "تصدية",
    "تصدير",
    "تصديره",
    "تصرفا",
    "تصرفات",
    "تصرفه",
    "تصريح",
    "تصفيح",
    "تصلف",
    "تصميغ",
    "تصنيفة",
    "تصويرة",
    "تصيير",
    "تضاؤل",
    "تضاد",
    "تضارب",
    "تضاريس",
    "تضاعيف",
    "تضاعيفه",
    "تضاغط",
    "تضام",
    "تضاما",
    "تضامن",
    "تضحية",
    "تضخيم",
    "تضييع",
    "تضييق",
    "تطأمن",
    "تطبع",
    "تطبيب",
    "تطبيقية",
    "تطرف",
    "تطريز",
    "تطعيم",
    "تطفيف",
    "تطمين",
    "تطواف",
    "تطوان",
    "تطورية",
    "تطويب",
    "تطويع",
    "تطويق",
    "تطويل",
    "تطير",
    "تطھيري",
    "تظاھر",
    "تظرف",
    "تظريف",
    "تظھير",
    "تع",
    "تعابير",
    "تعادل",
    "تعاريج",
    "تعاريش",
    "تعاريف",
    "تعاز",
    "تعاضد",
    "تعاضدي",
    "تعاط",
    "تعاقب",
    "تعام",
    "تعاور",
    "تعاونية",
    "تعب",
    "تعبيد",
    "تعتع",
    "تعتويذ",
    "تعتيم",
    "تعجله",
    "تعجيب",
    "تعداد",
    "تعدى",
    "تعديات",
    "تعدين",
    "تعذر",
    "تعريشة",
    "تعريفة",
    "تعز",
    "تعزية",
    "تعزيته",
    "تعزير",
    "تعس",
    "تعساء",
    "تعسر",
    "تعسف",
    "تعسيلة",
    "تعشيق",
    "تعضل",
    "تعضيد",
    "تعطب",
    "تعطشا",
    "تعطف",
    "تعطل",
    "تعطيب",
    "تعظيم",
    "تعفن",
    "تعفير",
    "تعقب",
    "تعقد",
    "تعقل",
    "تعقيب",
    "تعقيد",
    "تعكير",
    "تعلامة",
    "تعلة",
    "تعلل",
    "تعلية",
    "تعليقة",
    "تعليل",
    "تعليماتا",
    "تعمدا",
    "تعمدي",
    "تعمدھا",
    "تعميرة",
    "تعميم",
    "تعن",
    "تعنت",
    "تعنيف",
    "تعويد",
    "تعويذ",
    "تعھد",
    "تغابن",
    "تغاض",
    "تغاضى",
    "تغافل",
    "تغايير",
    "تغجير",
    "تغرض",
    "تغرغرت",
    "تغريب",
    "تغريد",
    "تغريق",
    "تغزل",
    "تغطرس",
    "تغطيس",
    "تغفيل",
    "تغيب",
    "تغييرا",
    "تف",
    "تفا",
    "تفاؤل",
    "تفاتيش",
    "تفاح",
    "تفاحش",
    "تفاريق",
    "تفاسير",
    "تفاضل",
    "تفاعيل",
    "تفافة",
    "تفاقم",
    "تفال",
    "تفاليس",
    "تفان",
    "تفاھة",
    "تفاھم",
    "تفتا",
    "تفتاه",
    "تفتق",
    "تفتيشي",
    "تفجع",
    "تفجعن",
    "تفرج",
    "تفرنج",
    "تفريح",
    "تفريدي",
    "تفريط",
    "تفريغ",
    "تفريق",
    "تفزع",
    "تفسرة",
    "تفش",
    "تفصيل",
    "تفصيلي",
    "تفصيليا",
    "تفضل",
    "تفضيل",
    "تفطن",
    "تفعيل",
    "تفقد",
    "تفقيص",
    "تفك",
    "تفكة",
    "تفكر",
    "تفكك",
    "تفكه",
    "تفكير",
    "تفكيك",
    "تفكھة",
    "تفليس",
    "تفنن",
    "تفه",
    "تفوته",
    "تفوه",
    "تفويت",
    "تفھم",
    "تفھيم",
    "تقابض",
    "تقادم",
    "تقادير",
    "تقاديم",
    "تقاذفت",
    "تقارب",
    "تقارضوا",
    "تقارير",
    "تقاريط",
    "تقاريظ",
    "تقاسيم",
    "تقاطيع",
    "تقاليد",
    "تقانة",
    "تقاوى",
    "تقاوي",
    "تقاويم",
    "تقاييد",
    "تقبيل",
    "تقتير",
    "تقتيل",
    "تقدمة",
    "تقدمت",
    "تقديس",
    "تقذيف",
    "تقرب",
    "تقريب",
    "تقريرا",
    "تقريطة",
    "تقريظ",
    "تقريع",
    "تقسموه",
    "تقسيط",
    "تقسيمة",
    "تقشف",
    "تقشير",
    "تقص",
    "تقصير",
    "تقطعت",
    "تقطير",
    "تقفيصة",
    "تقلص",
    "تقلية",
    "تقليش",
    "تقليم",
    "تقمص",
    "تقمقم",
    "تقميص",
    "تقن",
    "تقنع",
    "تقنعنا",
    "تقنين",
    "تقوت",
    "تقوير",
    "تقويرة",
    "تقويض",
    "تقى",
    "تقية",
    "تقييد",
    "تقھقر",
    "تكأة",
    "تكابر",
    "تكاثف",
    "تكاريز",
    "تكاسر",
    "تكافل",
    "تكالب",
    "تكاوين",
    "تكايا",
    "تكبيس",
    "تكة",
    "تكتك",
    "تكتكة",
    "تكتم",
    "تكتيك",
    "تكثير",
    "تكحيل",
    "تكدير",
    "تكرار",
    "تكرارا",
    "تكرمة",
    "تكرير",
    "تكريز",
    "تكريس",
    "تكريعة",
    "تكريما",
    "تكسر",
    "تكسية",
    "تكسير",
    "تكسيم",
    "تكشيرة",
    "تكضكض",
    "تكعيب",
    "تكعيبة",
    "تكفير",
    "تكك",
    "تكلام",
    "تكلامة",
    "تكلة",
    "تكليل",
    "تكميد",
    "تكميل",
    "تكميلي",
    "تكنھات",
    "تكوت",
    "تكية",
    "تكھن",
    "تكھنات",
    "تل",
    "تلآفاق",
    "تلألؤ",
    "تلا",
    "تلابيب",
    "تلاتل",
    "تلاحين",
    "تلاد",
    "تلاش",
    "تلاع",
    "تلاعب",
    "تلاف",
    "تلافيف",
    "تلافيفه",
    "تلال",
    "تلاليس",
    "تلامذة",
    "تلاميح",
    "تلاوة",
    "تلبس",
    "تلبيب",
    "تلبية",
    "تلبيسة",
    "تلحين",
    "تلحيني",
    "تلطف",
    "تلع",
    "تلعاب",
    "تلعة",
    "تلغراف",
    "تلغرافا",
    "تلغرافى",
    "تلغرافية",
    "تلف",
    "تلفاز",
    "تلفازية",
    "تلفان",
    "تلفت",
    "تلفزة",
    "تلفزية",
    "تلفن",
    "تلفون",
    "تلفوني",
    "تلفونيا",
    "تلفونية",
    "تلفيف",
    "تلفيق",
    "تلفيقة",
    "تلق",
    "تلقاه",
    "تلقين",
    "تلم",
    "تلماح",
    "تلماذ",
    "تلمذ",
    "تلمذة",
    "تلمسان",
    "تلمظ",
    "تلمود",
    "تلميح",
    "تلميحا",
    "تلميع",
    "تله",
    "تلوكه",
    "تلول",
    "تلويم",
    "تلى",
    "تلي",
    "تليباثى",
    "تليد",
    "تليس",
    "تليع",
    "تليف",
    "تليفزيون",
    "تليفزيوني",
    "تليفون",
    "تليفونى",
    "تليفونية",
    "تلھية",
    "تمائم",
    "تماثل",
    "تماثيل",
    "تماد",
    "تماريد",
    "تماسيح",
    "تمالك",
    "تمباك",
    "تمتين",
    "تمثل",
    "تمجيد",
    "تمحل",
    "تمحيس",
    "تمدح",
    "تمدد",
    "تمدني",
    "تمديد",
    "تمدين",
    "تمديني",
    "تمراد",
    "تمرجى",
    "تمرجية",
    "تمرس",
    "تمرن",
    "تمريض",
    "تمرينات",
    "تمزيق",
    "تمشيا",
    "تمشيط",
    "تمصير",
    "تمضية",
    "تمطط",
    "تمعن",
    "تمغة",
    "تملأ",
    "تململ",
    "تمليح",
    "تمليق",
    "تمليك",
    "تمن",
    "تمنية",
    "تموجات",
    "تمورا",
    "تمورجى",
    "تموز",
    "تمويج",
    "تمويه",
    "تمويھي",
    "تمويھية",
    "تميع",
    "تميمة",
    "تمييز",
    "تمھل",
    "تمھيد",
    "تمھيدي",
    "تمھيدية",
    "تن",
    "تناء",
    "تنابر",
    "تنابلة",
    "تنابيل",
    "تناثر",
    "تناجز",
    "تنادر",
    "تناذر",
    "تنازع",
    "تناسب",
    "تناسخ",
    "تناسل",
    "تناسليات",
    "تناظر",
    "تناف",
    "تناقص",
    "تناقل",
    "تناقلت",
    "تناقلته",
    "تنانير",
    "تنانين",
    "تناه",
    "تناوبته",
    "تناھب",
    "تناھى",
    "تنباك",
    "تنبال",
    "تنبر",
    "تنبك",
    "تنبل",
    "تنبه",
    "تنبيه",
    "تنجستين",
    "تنجية",
    "تنجيد",
    "تنجيز",
    "تنجيس",
    "تنجيم",
    "تنحني",
    "تندة",
    "تندر",
    "تندم",
    "تنديد",
    "تنزه",
    "تنزيه",
    "تنسق",
    "تنسم",
    "تنشئة",
    "تنشق",
    "تنشيء",
    "تنشيط",
    "تنشيف",
    "تنشيقة",
    "تنصل",
    "تنصير",
    "تنصيص",
    "تنصيف",
    "تنضيد",
    "تنطاف",
    "تنطل",
    "تنفخ",
    "تنفق",
    "تنفيذية",
    "تنفير",
    "تنقيب",
    "تنقيح",
    "تنقيص",
    "تنك",
    "تنكة",
    "تنكجى",
    "تنكس",
    "تنكيت",
    "تنكيل",
    "تنمير",
    "تنميق",
    "تنميل",
    "تنور",
    "تنوط",
    "تنوق",
    "تنويع",
    "تنويم",
    "تنوين",
    "تني",
    "تنيس",
    "تنيق",
    "تنييل",
    "تنھد",
    "توءم",
    "توا",
    "توابع",
    "توابل",
    "توابيت",
    "تواتر",
    "تواد",
    "توارث",
    "توارد",
    "تواز",
    "توازنه",
    "تواشيح",
    "تواص",
    "تواصل",
    "تواصيل",
    "تواضع",
    "تواطؤ",
    "توافه",
    "تواكل",
    "تواكيد",
    "توال",
    "توالى",
    "توالي",
    "تواليت",
    "تواليف",
    "توان",
    "توانسة",
    "توانى",
    "توبل",
    "توبوغرافيا",
    "توتر",
    "توترت",
    "توتيا",
    "توتياء",
    "توتية",
    "توثقة",
    "توثيق",
    "توجع",
    "توجولند",
    "توجيھي",
    "توجيھية",
    "توح",
    "توحده",
    "توحش",
    "توخ",
    "توخى",
    "تور",
    "توراة",
    "توربيد",
    "توربين",
    "تورتة",
    "تورط",
    "تورية",
    "توريد",
    "توسعية",
    "توسلا",
    "توسم",
    "توشية",
    "توشيح",
    "توصل",
    "توصيلة",
    "توصيم",
    "توضؤ",
    "توضيب",
    "توضيح",
    "توطئة",
    "توطنت",
    "توطيد",
    "توظف",
    "توعد",
    "توعدي",
    "توغل",
    "توفاه",
    "توفرت",
    "توفية",
    "توق",
    "توقان",
    "توقد",
    "توقر",
    "توكؤ",
    "توكة",
    "توكل",
    "توكيد",
    "تولد",
    "تولع",
    "توله",
    "تولية",
    "تومرجى",
    "تونة",
    "تونسى",
    "تونية",
    "توه",
    "تويج",
    "توھة",
    "توھم",
    "تياترو",
    "تياتل",
    "تياربطيء",
    "تياه",
    "تيتل",
    "تيتنوس",
    "تيجان",
    "تيح",
    "تيزة",
    "تيسير",
    "تيفوس",
    "تيقظ",
    "تيلة",
    "تيماء",
    "تيمارجى",
    "تيمن",
    "تينن",
    "تيه",
    "تيوس",
    "تيوليب",
    "تيھاء",
    "تيھان",
    "تھاتر",
    "تھافت",
    "تھالك",
    "تھاليل",
    "تھامة",
    "تھانئ",
    "تھاون",
    "تھاويل",
    "تھتك",
    "تھته",
    "تھج",
    "تھجم",
    "تھجية",
    "تھدئة",
    "تھدد",
    "تھدم",
    "تھديد",
    "تھديدي",
    "تھديم",
    "تھذب",
    "تھذيب",
    "تھذيبي",
    "تھريب",
    "تھزيز",
    "تھطال",
    "تھكم",
    "تھكمي",
    "تھلكة",
    "تھلل",
    "تھليل",
    "تھلين",
    "تھم",
    "تھمة",
    "تھميم",
    "تھميمة",
    "تھنئة",
    "تھور",
    "تھوية",
    "تھويد",
    "تھويش",
    "تھويل",
    "تھيأ",
    "تھيؤ",
    "تھيئة",
    "تھيب",
    "تھيج",
    "تھييج",
    "ثآليل",
    "ثأب",
    "ثأر",
    "ثأره",
    "ثأى",
    "ثؤباء",
    "ثؤلول",
    "ثؤلولة",
    "ثئب",
    "ثاء",
    "ثائرة",
    "ثائرته",
    "ثائره",
    "ثاب",
    "ثابتة",
    "ثار",
    "ثاغ",
    "ثاغم",
    "ثاغية",
    "ثاقبات",
    "ثاكلة",
    "ثالب",
    "ثالثة",
    "ثالم",
    "ثالوث",
    "ثان",
    "ثانوى",
    "ثانى",
    "ثبان",
    "ثبر",
    "ثبن",
    "ثبنة",
    "ثبوت",
    "ثبور",
    "ثج",
    "ثجاج",
    "ثخانة",
    "ثخناء",
    "ثخونة",
    "ثخين",
    "ثدى",
    "ثر",
    "ثراه",
    "ثرب",
    "ثرة",
    "ثرثارة",
    "ثرد",
    "ثرم",
    "ثرو",
    "ثروة",
    "ثروته",
    "ثرى",
    "ثري",
    "ثريا",
    "ثريات",
    "ثرية",
    "ثريد",
    "ثعالة",
    "ثعبانى",
    "ثعلبة",
    "ثعلبى",
    "ثغاء",
    "ثغر",
    "ثغم",
    "ثغو",
    "ثغور",
    "ثفر",
    "ثفل",
    "ثفن",
    "ثفنة",
    "ثقاب",
    "ثقافى",
    "ثقال",
    "ثقبة",
    "ثقته",
    "ثقلاء",
    "ثقلية",
    "ثقيف",
    "ثكل",
    "ثكلان",
    "ثكلى",
    "ثكن",
    "ثكنة",
    "ثل",
    "ثلاثا",
    "ثلاثاء",
    "ثلاثى",
    "ثلاج",
    "ثلاجة",
    "ثلالا",
    "ثلب",
    "ثلجت",
    "ثلجى",
    "ثلل",
    "ثلم",
    "ثلمة",
    "ثلوج",
    "ثليج",
    "ثمار",
    "ثمالة",
    "ثمام",
    "ثمان",
    "ثمت",
    "ثمنة",
    "ثناء",
    "ثنائى",
    "ثناد",
    "ثنايا",
    "ثنة",
    "ثندوة",
    "ثنن",
    "ثنوى",
    "ثنوية",
    "ثنيا",
    "ثنية",
    "ثواء",
    "ثوائر",
    "ثوابت",
    "ثوار",
    "ثواكل",
    "ثوان",
    "ثوة",
    "ثوران",
    "ثورة",
    "ثوروى",
    "ثورى",
    "ثوريوم",
    "ثول",
    "ثوم",
    "ثوى",
    "ثيابه",
    "ثياتل",
    "ثيب",
    "ثيتل",
    "جآجئ",
    "جأءني",
    "جأر",
    "جأشا",
    "جأشك",
    "جأشه",
    "جؤار",
    "جؤجؤ",
    "جاؤوا",
    "جائح",
    "جائحة",
    "جائلون",
    "جاب",
    "جابر",
    "جابية",
    "جاث",
    "جاثليق",
    "جاثوم",
    "جاح",
    "جادة",
    "جادت",
    "جاذب",
    "جاذبة",
    "جاذبه",
    "جاذي",
    "جارة",
    "جارحة",
    "جارف",
    "جارور",
    "جارورة",
    "جاروشة",
    "جازت",
    "جازع",
    "جازف",
    "جازون",
    "جاس",
    "جاسئ",
    "جاسوسة",
    "جاسوسى",
    "جاسوسية",
    "جاش",
    "جاع",
    "جاكتة",
    "جاكته",
    "جاكيته",
    "جال",
    "جالون",
    "جالية",
    "جاليرى",
    "جام",
    "جامعى",
    "جامكية",
    "جاموس",
    "جاموسة",
    "جانبه",
    "جانبى",
    "جانح",
    "جانحة",
    "جانرك",
    "جاه",
    "جاودار",
    "جاول",
    "جاوه",
    "جاوى",
    "جاويش",
    "جاھز",
    "جاھزة",
    "جاھل",
    "جاھلى",
    "جاھلية",
    "جب",
    "جبائب",
    "جبائر",
    "جبائي",
    "جباب",
    "جبابر",
    "جباة",
    "جباح",
    "جباسة",
    "جبانة",
    "جباه",
    "جباية",
    "جبب",
    "جبة",
    "جبح",
    "جبخانة",
    "جبر",
    "جبرئيل",
    "جبرا",
    "جبروت",
    "جبرى",
    "جبرياء",
    "جبرية",
    "جبس",
    "جبلاوى",
    "جبلاية",
    "جبلة",
    "جبلى",
    "جبناء",
    "جبنة",
    "جبه",
    "جبور",
    "جبى",
    "جبيرة",
    "جبين",
    "جبينه",
    "جبينى",
    "جبھات",
    "جبھة",
    "جبھته",
    "جث",
    "جثا",
    "جثالقة",
    "جثام",
    "جثث",
    "جثل",
    "جثليق",
    "جثم",
    "جثمانى",
    "جثمة",
    "جثو",
    "جثوة",
    "جثوم",
    "جحاش",
    "جحافل",
    "جحد",
    "جحش",
    "جحشان",
    "جحشة",
    "جحظ",
    "جحف",
    "جحفل",
    "جحود",
    "جحوش",
    "جحوظ",
    "جحيمى",
    "جخ",
    "جخاخ",
    "جداء",
    "جدائل",
    "جداجد",
    "جدارى",
    "جدب",
    "جدباء",
    "جدة",
    "جدث",
    "جدجد",
    "جدراء",
    "جدران",
    "جدرى",
    "جدع",
    "جدعان",
    "جدف",
    "جدلى",
    "جدو",
    "جدوبة",
    "جدود",
    "جدى",
    "جديا",
    "جديان",
    "جديب",
    "جدية",
    "جديلة",
    "جذ",
    "جذاء",
    "جذاذات",
    "جذاذة",
    "جذام",
    "جذامة",
    "جذامى",
    "جذبا",
    "جذبه",
    "جذرا",
    "جذرى",
    "جذعا",
    "جذعة",
    "جذعى",
    "جذف",
    "جذل",
    "جذلان",
    "جذم",
    "جذمور",
    "جذول",
    "جذوم",
    "جذى",
    "جذيل",
    "جرؤ",
    "جرئ",
    "جراءة",
    "جرائد",
    "جرائر",
    "جراب",
    "جرابزة",
    "جرابندية",
    "جرابيع",
    "جراثيم",
    "جراج",
    "جراح",
    "جراحى",
    "جرادل",
    "جراذين",
    "جرارة",
    "جراريف",
    "جرافة",
    "جراك",
    "جراكسة",
    "جرام",
    "جرامز",
    "جراميز",
    "جراميزه",
    "جران",
    "جرانه",
    "جرانيت",
    "جرانيتى",
    "جرانيل",
    "جراية",
    "جرب",
    "جرباء",
    "جربان",
    "جربذة",
    "جربز",
    "جربزة",
    "جربندية",
    "جربوع",
    "جرت",
    "جرجرة",
    "جرجير",
    "جرح",
    "جرحى",
    "جرداء",
    "جردل",
    "جرده",
    "جرذون",
    "جرساية",
    "جرسة",
    "جرسى",
    "جرسية",
    "جرش",
    "جرض",
    "جرع",
    "جركسى",
    "جرمانى",
    "جرن",
    "جرنال",
    "جروح",
    "جروحات",
    "جروسة",
    "جروف",
    "جروم",
    "جرى",
    "جري",
    "جريا",
    "جريب",
    "جريد",
    "جريرة",
    "جريش",
    "جريض",
    "جريم",
    "جرينش",
    "جز",
    "جزأ",
    "جزئى",
    "جزئية",
    "جزائر",
    "جزائرى",
    "جزائز",
    "جزائى",
    "جزار",
    "جزاز",
    "جزازة",
    "جزاف",
    "جزافا",
    "جزاك",
    "جزال",
    "جزالة",
    "جزاه",
    "جزاھم",
    "جزة",
    "جزدان",
    "جزرة",
    "جزرى",
    "جزز",
    "جزع",
    "جزف",
    "جزل",
    "جزلان",
    "جزماتى",
    "جزمة",
    "جزمجى",
    "جزور",
    "جزى",
    "جزية",
    "جزيل",
    "جزيلا",
    "جس",
    "جسا",
    "جسام",
    "جسامة",
    "جسدانى",
    "جسدى",
    "جسطن",
    "جسمان",
    "جسمانى",
    "جسمانية",
    "جسمه",
    "جسمى",
    "جسو",
    "جسورة",
    "جسوم",
    "جسيم",
    "جش",
    "جشأ",
    "جشأة",
    "جشاء",
    "جشار",
    "جشامة",
    "جشم",
    "جشنى",
    "جشيش",
    "جص",
    "جصطن",
    "جعائل",
    "جعاب",
    "جعادة",
    "جعالة",
    "جعب",
    "جعبة",
    "جعة",
    "جعجاع",
    "جعجع",
    "جعجعة",
    "جعدة",
    "جعدنة",
    "جعدى",
    "جعر",
    "جعران",
    "جعفر",
    "جعلان",
    "جعلى",
    "جعودة",
    "جعيدى",
    "جغرافيا",
    "جغرافية",
    "جف",
    "جفا",
    "جفاة",
    "جفاف",
    "جفان",
    "جفت",
    "جفتجي",
    "جفتلك",
    "جفر",
    "جفرة",
    "جفل",
    "جفن",
    "جفنات",
    "جفنة",
    "جفنه",
    "جفنيه",
    "جفو",
    "جفوة",
    "جفوف",
    "جفول",
    "جفونه",
    "جكر",
    "جلا",
    "جلاء",
    "جلائل",
    "جلاب",
    "جلابيب",
    "جلابية",
    "جلاتين",
    "جلاجل",
    "جلاد",
    "جلادة",
    "جلاسية",
    "جلاقة",
    "جلال",
    "جلالاء",
    "جلالة",
    "جلاليب",
    "جلامد",
    "جلاميد",
    "جلايا",
    "جلبا",
    "جلباء",
    "جلباب",
    "جلبان",
    "جلبب",
    "جلبة",
    "جلبى",
    "جلة",
    "جلجل",
    "جلجلان",
    "جلجلة",
    "جلح",
    "جلحاء",
    "جلخ",
    "جلداء",
    "جلدتنا",
    "جلده",
    "جلدى",
    "جلساء",
    "جلسته",
    "جلط",
    "جلفط",
    "جلفطة",
    "جلفن",
    "جلفنة",
    "جلم",
    "جلمبو",
    "جلمد",
    "جلمز",
    "جلمود",
    "جلنار",
    "جله",
    "جلو",
    "جلوب",
    "جلودة",
    "جلوس",
    "جلون",
    "جلوى",
    "جلى",
    "جليا",
    "جليان",
    "جليب",
    "جلية",
    "جليدة",
    "جليدى",
    "جليس",
    "جليسة",
    "جليسه",
    "جليلا",
    "جليوتين",
    "جم",
    "جماجم",
    "جماح",
    "جماحه",
    "جماد",
    "جمادى",
    "جمار",
    "جماع",
    "جماعى",
    "جمام",
    "جمان",
    "جماھير",
    "جمباز",
    "جمبرى",
    "جمبري",
    "جمة",
    "جمجم",
    "جمجمة",
    "جمجمى",
    "جمح",
    "جمدت",
    "جمر",
    "جمرة",
    "جمرك",
    "جمركى",
    "جمركية",
    "جمرى",
    "جمري",
    "جمش",
    "جمعاء",
    "جمعة",
    "جمكية",
    "جموح",
    "جمود",
    "جمودة",
    "جموع",
    "جميز",
    "جميله",
    "جمھر",
    "جمھرة",
    "جمھرى",
    "جمھري",
    "جمھور",
    "جمھورية",
    "جنائز",
    "جنائن",
    "جنائنى",
    "جنائي",
    "جنائية",
    "جناب",
    "جنابة",
    "جنابكم",
    "جنابية",
    "جناة",
    "جناحه",
    "جنادب",
    "جنادل",
    "جناديل",
    "جناز",
    "جنازة",
    "جنازير",
    "جنان",
    "جنانيز",
    "جناية",
    "جنبات",
    "جنباته",
    "جنباز",
    "جنبازى",
    "جنبازي",
    "جنبة",
    "جنبري",
    "جنبى",
    "جنبيه",
    "جنح",
    "جنحة",
    "جنحي",
    "جندارى",
    "جندب",
    "جندر",
    "جندرة",
    "جندرمة",
    "جندرمى",
    "جندفلى",
    "جندل",
    "جندول",
    "جندى",
    "جندية",
    "جنز",
    "جنزار",
    "جنزبيل",
    "جنزير",
    "جنسنا",
    "جنطيانا",
    "جنف",
    "جنفاص",
    "جنفيص",
    "جنك",
    "جنن",
    "جنوا",
    "جنوبى",
    "جنوح",
    "جنوك",
    "جنونه",
    "جنى",
    "جنية",
    "جنين",
    "جنينة",
    "جنيه",
    "جوا",
    "جواء",
    "جوائح",
    "جوائز",
    "جوائط",
    "جوابا",
    "جوابى",
    "جوارح",
    "جوارحه",
    "جوارش",
    "جواره",
    "جوارير",
    "جوازل",
    "جوازم",
    "جواسق",
    "جواسيس",
    "جوافة",
    "جوال",
    "جوالة",
    "جوامد",
    "جوامع",
    "جوامك",
    "جواميس",
    "جوانبه",
    "جوانبھم",
    "جوانتي",
    "جوانح",
    "جوانحه",
    "جوانحي",
    "جوانحھا",
    "جوانى",
    "جواھر",
    "جوب",
    "جوبة",
    "جوت",
    "جوح",
    "جوخ",
    "جودار",
    "جورب",
    "جورة",
    "جورى",
    "جوري",
    "جوزل",
    "جوزي",
    "جوسسة",
    "جوسق",
    "جوعان",
    "جوعه",
    "جوعى",
    "جوف",
    "جوفاء",
    "جوفية",
    "جوق",
    "جوقة",
    "جول",
    "جولان",
    "جولة",
    "جولف",
    "جونلا",
    "جونلة",
    "جونيلة",
    "جوى",
    "جويدار",
    "جويطة",
    "جوھر",
    "جوھرة",
    "جوھرجي",
    "جوھري",
    "جيئة",
    "جياد",
    "جيار",
    "جيارة",
    "جياش",
    "جياع",
    "جيبه",
    "جيران",
    "جيرة",
    "جيرو",
    "جيزة",
    "جيشا",
    "جيشان",
    "جيفي",
    "جيلاتي",
    "جيوب",
    "جيود",
    "جيوش",
    "جيوغرافيا",
    "جيوفيزيا",
    "جيوفيزيقي",
    "جيولوجيا",
    "جھابذة",
    "جھات",
    "جھاد",
    "جھادي",
    "جھار",
    "جھارا",
    "جھارة",
    "جھاز",
    "جھال",
    "جھالة",
    "جھام",
    "جھامة",
    "جھبذ",
    "جھة",
    "جھتي",
    "جھد",
    "جھدا",
    "جھده",
    "جھدي",
    "جھر",
    "جھرا",
    "جھرة",
    "جھري",
    "جھز",
    "جھش",
    "جھشة",
    "جھض",
    "جھل",
    "جھلاء",
    "جھلة",
    "جھم",
    "جھنم",
    "جھنمي",
    "جھنمية",
    "جھود",
    "جھول",
    "جھومة",
    "جھوي",
    "جھيد",
    "جھير",
    "جھيض",
    "حؤول",
    "حاء",
    "حائد",
    "حائرا",
    "حائز",
    "حائض",
    "حائضة",
    "حائط",
    "حابل",
    "حاثة",
    "حاجب",
    "حاجبيه",
    "حاجته",
    "حاجزة",
    "حاجيات",
    "حاخام",
    "حادثة",
    "حادر",
    "حادق",
    "حادى",
    "حاذ",
    "حارة",
    "حارث",
    "حارد",
    "حارزة",
    "حارض",
    "حارقة",
    "حارك",
    "حارون",
    "حاز",
    "حازب",
    "حازوقة",
    "حاسب",
    "حاسمة",
    "حاشدة",
    "حاشى",
    "حاشية",
    "حاص",
    "حاصبة",
    "حاصد",
    "حاصدة",
    "حاصلات",
    "حاضت",
    "حاضنة",
    "حاط",
    "حاطب",
    "حاطمة",
    "حاف",
    "حافرة",
    "حافرته",
    "حافري",
    "حافلة",
    "حاق",
    "حاكة",
    "حاكما",
    "حاكمية",
    "حاكورة",
    "حالب",
    "حالق",
    "حالك",
    "حالما",
    "حاله",
    "حالوم",
    "حالية",
    "حالھا",
    "حامت",
    "حاملات",
    "حاملة",
    "حامى",
    "حانبة",
    "حانت",
    "حانق",
    "حانوت",
    "حانوتي",
    "حاو",
    "حايد",
    "حايل",
    "حباء",
    "حبائب",
    "حبائل",
    "حباب",
    "حبات",
    "حباحب",
    "حبار",
    "حباري",
    "حباريات",
    "حباكة",
    "حبالة",
    "حبالى",
    "حبحب",
    "حبذ",
    "حبرة",
    "حبري",
    "حبرية",
    "حبس",
    "حبسا",
    "حبساء",
    "حبسة",
    "حبق",
    "حبك",
    "حبكة",
    "حبلانة",
    "حبله",
    "حبن",
    "حبو",
    "حبوة",
    "حبور",
    "حبوس",
    "حبوط",
    "حبول",
    "حبي",
    "حبيا",
    "حبيبة",
    "حبيس",
    "حبھان",
    "حت",
    "حتات",
    "حتار",
    "حتة",
    "حتد",
    "حتر",
    "حترة",
    "حتف",
    "حتفه",
    "حتم",
    "حتمية",
    "حتوف",
    "حتوم",
    "حثا",
    "حثو",
    "حثيث",
    "حجا",
    "حجابة",
    "حجاج",
    "حجار",
    "حجازي",
    "حجال",
    "حجام",
    "حجامة",
    "حجبة",
    "حجدز",
    "حجران",
    "حجرصحي",
    "حجزا",
    "حجل",
    "حجلان",
    "حجلى",
    "حجن",
    "حجو",
    "حجور",
    "حجورة",
    "حجول",
    "حجوم",
    "حجى",
    "حجي",
    "حجيج",
    "حجير",
    "حخام",
    "حدآن",
    "حدأ",
    "حدأة",
    "حداء",
    "حدائج",
    "حدائد",
    "حداب",
    "حداة",
    "حداث",
    "حداثة",
    "حداجة",
    "حدادة",
    "حدارة",
    "حداف",
    "حدافة",
    "حداق",
    "حداية",
    "حدباء",
    "حدته",
    "حدثا",
    "حدثاء",
    "حدثان",
    "حدثته",
    "حدثه",
    "حدج",
    "حدر",
    "حدرا",
    "حدقة",
    "حدل",
    "حدم",
    "حده",
    "حدو",
    "حدوة",
    "حدوج",
    "حدور",
    "حدول",
    "حدى",
    "حدي",
    "حديثه",
    "حديج",
    "حديده",
    "حذا",
    "حذاري",
    "حذاف",
    "حذافير",
    "حذاق",
    "حذاقة",
    "حذره",
    "حذق",
    "حذلق",
    "حذلقة",
    "حذو",
    "حذوق",
    "حذوك",
    "حذوه",
    "حرائر",
    "حرائري",
    "حرائق",
    "حراب",
    "حرابش",
    "حرابى",
    "حراث",
    "حراثة",
    "حراج",
    "حراجة",
    "حراذين",
    "حرار",
    "حرارى",
    "حرازة",
    "حراسة",
    "حراشة",
    "حراشف",
    "حراص",
    "حرافة",
    "حراق",
    "حراقة",
    "حراقد",
    "حراقف",
    "حرامل",
    "حرامي",
    "حران",
    "حراير",
    "حربا",
    "حرباء",
    "حرباه",
    "حربة",
    "حربوشة",
    "حربي",
    "حرت",
    "حرثة",
    "حردان",
    "حرذون",
    "حرره",
    "حرز",
    "حرسة",
    "حرش",
    "حرشة",
    "حرشف",
    "حرصا",
    "حرصاء",
    "حرفا",
    "حرفاء",
    "حرفه",
    "حرقان",
    "حرقد",
    "حرقفة",
    "حركات",
    "حركاته",
    "حركته",
    "حركث",
    "حركش",
    "حرمة",
    "حرمته",
    "حرمل",
    "حرمه",
    "حرن",
    "حروب",
    "حروة",
    "حرور",
    "حروز",
    "حروش",
    "حروم",
    "حرون",
    "حري",
    "حريت",
    "حريرة",
    "حريز",
    "حريفات",
    "حريق",
    "حريقة",
    "حريم",
    "حريمي",
    "حز",
    "حزائني",
    "حزاز",
    "حزازة",
    "حزامة",
    "حزانى",
    "حزايني",
    "حزبي",
    "حزبية",
    "حزة",
    "حزر",
    "حزقاني",
    "حزماء",
    "حزناء",
    "حزنان",
    "حزورة",
    "حزوز",
    "حزوقة",
    "حزومة",
    "حزون",
    "حزيران",
    "حزيم",
    "حسا",
    "حساء",
    "حسابة",
    "حساد",
    "حساسي",
    "حساسين",
    "حسام",
    "حسباء",
    "حسباني",
    "حسبة",
    "حسبما",
    "حسبى",
    "حسبية",
    "حسدة",
    "حسر",
    "حسران",
    "حسرتاه",
    "حسرتي",
    "حسرى",
    "حسك",
    "حسكي",
    "حسم",
    "حسناء",
    "حسه",
    "حسو",
    "حسوة",
    "حسوم",
    "حسون",
    "حسيات",
    "حسيب",
    "حسيس",
    "حشا",
    "حشائش",
    "حشاش",
    "حشاشة",
    "حشايا",
    "حشرج",
    "حشرجة",
    "حشرية",
    "حشف",
    "حشك",
    "حشم",
    "حشماء",
    "حشمة",
    "حشود",
    "حشية",
    "حشيد",
    "حشيش",
    "حشيشي",
    "حشيم",
    "حص",
    "حصائد",
    "حصائر",
    "حصائل",
    "حصاة",
    "حصاد",
    "حصادة",
    "حصافة",
    "حصالبان",
    "حصالة",
    "حصانة",
    "حصد",
    "حصرم",
    "حصص",
    "حصف",
    "حصوة",
    "حصول",
    "حصون",
    "حصوي",
    "حصى",
    "حصيات",
    "حصيد",
    "حصيدة",
    "حصيف",
    "حصيلة",
    "حض",
    "حضائر",
    "حضار",
    "حضارم",
    "حضان",
    "حضانة",
    "حضرة",
    "حضرتكم",
    "حضرته",
    "حضرموت",
    "حضرمي",
    "حضض",
    "حضن",
    "حضور",
    "حضوري",
    "حضوريا",
    "حضورية",
    "حضون",
    "حضيرة",
    "حضيض",
    "حضين",
    "حطاب",
    "حطب",
    "حطمة",
    "حطوط",
    "حطيطة",
    "حطيم",
    "حظار",
    "حظايا",
    "حظه",
    "حظو",
    "حظوظ",
    "حظية",
    "حظيرته",
    "حظيظ",
    "حف",
    "حفاء",
    "حفائر",
    "حفائظ",
    "حفاة",
    "حفاف",
    "حفدة",
    "حفريات",
    "حفرية",
    "حفظه",
    "حفن",
    "حفنة",
    "حفو",
    "حفيدة",
    "حفير",
    "حفيرة",
    "حفيظة",
    "حفيف",
    "حفيل",
    "حقاء",
    "حقائد",
    "حقائق",
    "حقاب",
    "حقاق",
    "حقاني",
    "حقانية",
    "حقب",
    "حقة",
    "حقدة",
    "حقراء",
    "حقلي",
    "حقنا",
    "حقنة",
    "حقنه",
    "حقو",
    "حقوقي",
    "حقويه",
    "حقي",
    "حقيدة",
    "حقيقته",
    "حكاك",
    "حكام",
    "حكاية",
    "حكة",
    "حكر",
    "حكرة",
    "حكك",
    "حكماء",
    "حكمدار",
    "حكمدارية",
    "حكومية",
    "حكى",
    "حكيمباشي",
    "حلائل",
    "حلاب",
    "حلابة",
    "حلاج",
    "حلاجة",
    "حلاق",
    "حلاقم",
    "حلاليف",
    "حلاوى",
    "حلب",
    "حلبي",
    "حلة",
    "حلت",
    "حلتيت",
    "حلج",
    "حلحل",
    "حلزونة",
    "حلزونى",
    "حلس",
    "حلساء",
    "حلفا",
    "حلفاء",
    "حلفة",
    "حلقات",
    "حلقوم",
    "حلقى",
    "حلك",
    "حلكة",
    "حلماء",
    "حلمي",
    "حلواء",
    "حلوائي",
    "حلوان",
    "حلواني",
    "حلوة",
    "حلوس",
    "حلوف",
    "حلوق",
    "حلوكة",
    "حلوم",
    "حلويات",
    "حلى",
    "حليج",
    "حليف",
    "حليفة",
    "حليل",
    "حليلة",
    "حمأ",
    "حمأة",
    "حمئ",
    "حماء",
    "حمائر",
    "حمائل",
    "حمائم",
    "حمارة",
    "حماس",
    "حماض",
    "حماق",
    "حماقى",
    "حماه",
    "حماوة",
    "حمة",
    "حمحم",
    "حمحمة",
    "حمد",
    "حمدل",
    "حمدلة",
    "حمرة",
    "حمري",
    "حمز",
    "حمش",
    "حمشة",
    "حمص",
    "حمصية",
    "حمق",
    "حمقاء",
    "حملان",
    "حملايا",
    "حملق",
    "حملقة",
    "حمله",
    "حملي",
    "حمم",
    "حمو",
    "حموات",
    "حمود",
    "حمور",
    "حموضة",
    "حمول",
    "حموي",
    "حميا",
    "حميات",
    "حمير",
    "حميرة",
    "حميض",
    "حميل",
    "حميلة",
    "حن",
    "حنأ",
    "حناء",
    "حنابلة",
    "حناجر",
    "حناجير",
    "حنادس",
    "حناطة",
    "حنانة",
    "حنانيك",
    "حنايا",
    "حناية",
    "حنبلي",
    "حنة",
    "حنث",
    "حنجل",
    "حنجور",
    "حندس",
    "حندقوق",
    "حنش",
    "حنظل",
    "حنف",
    "حنفي",
    "حنق",
    "حنكة",
    "حنكي",
    "حنو",
    "حنى",
    "حني",
    "حنية",
    "حنيف",
    "حنيفة",
    "حوائج",
    "حوائط",
    "حوائل",
    "حواة",
    "حواجب",
    "حواجل",
    "حوار",
    "حوارة",
    "حوارى",
    "حواري",
    "حواس",
    "حواسر",
    "حواسه",
    "حواش",
    "حواصل",
    "حواضر",
    "حواضن",
    "حوافز",
    "حوافظ",
    "حوافل",
    "حوافيه",
    "حواقل",
    "حوال",
    "حوالة",
    "حوامض",
    "حوامل",
    "حوانب",
    "حوانيت",
    "حوانيھم",
    "حوايا",
    "حواية",
    "حوب",
    "حوباء",
    "حوبة",
    "حوج",
    "حوجلة",
    "حود",
    "حودة",
    "حوذ",
    "حوذي",
    "حوذية",
    "حوراء",
    "حوران",
    "حوز",
    "حوزة",
    "حوزه",
    "حوزي",
    "حوس",
    "حوش",
    "حوشي",
    "حوص",
    "حوصاء",
    "حوصل",
    "حوصلة",
    "حوطة",
    "حوف",
    "حوق",
    "حوقل",
    "حوقلة",
    "حوك",
    "حوليات",
    "حوليھما",
    "حوم",
    "حومة",
    "حوى",
    "حوية",
    "حويصل",
    "حويصلة",
    "حويضة",
    "حويط",
    "حوين",
    "حى",
    "حياته",
    "حياتي",
    "حياتھا",
    "حياد",
    "حيادي",
    "حيارى",
    "حيازة",
    "حياصة",
    "حياض",
    "حياضه",
    "حياط",
    "حياطة",
    "حياك",
    "حياكة",
    "حيال",
    "حيتان",
    "حيثية",
    "حيدان",
    "حيدة",
    "حيدراباد",
    "حيرى",
    "حيزبون",
    "حيشان",
    "حيص",
    "حيصة",
    "حيضة",
    "حيطان",
    "حيطة",
    "حيطته",
    "حيف",
    "حيفاء",
    "حيق",
    "حيك",
    "حيل",
    "حيله",
    "حيلولة",
    "حيلي",
    "حينا",
    "حينذاك",
    "حينما",
    "حيو",
    "حيوات",
    "حيوانية",
    "حيود",
    "حيويات",
    "حيي",
    "خؤول",
    "خؤولة",
    "خؤون",
    "خاء",
    "خائط",
    "خابئة",
    "خابور",
    "خابية",
    "خاتام",
    "خاتر",
    "خاتم",
    "خاتمة",
    "خاتون",
    "خاثر",
    "خاخام",
    "خادر",
    "خادرة",
    "خادمية",
    "خارب",
    "خارجا",
    "خارصين",
    "خارصينى",
    "خارطة",
    "خازن",
    "خازوق",
    "خاس",
    "خاسئ",
    "خاش",
    "خاشع",
    "خاصا",
    "خاصرة",
    "خاصرته",
    "خاض",
    "خاط",
    "خاطبة",
    "خاطبه",
    "خاطرك",
    "خاطره",
    "خاطف",
    "خافرة",
    "خافقي",
    "خاقان",
    "خاكى",
    "خالب",
    "خالة",
    "خالج",
    "خالجة",
    "خالط",
    "خالع",
    "خالى",
    "خامات",
    "خانوق",
    "خاو",
    "خاوى",
    "خبء",
    "خبأ",
    "خبا",
    "خباء",
    "خباز",
    "خبازة",
    "خبازى",
    "خباص",
    "خباط",
    "خبال",
    "خبايا",
    "خبب",
    "خبثاء",
    "خبثة",
    "خبر",
    "خبره",
    "خبرية",
    "خبزة",
    "خبص",
    "خبصة",
    "خبط",
    "خبطة",
    "خبو",
    "خبيئة",
    "خبياري",
    "خبيب",
    "خبيز",
    "خبيص",
    "خبيصة",
    "ختام",
    "ختان",
    "ختانة",
    "ختر",
    "ختل",
    "ختلا",
    "ختما",
    "ختمة",
    "ختن",
    "ختوم",
    "خثار",
    "خثارة",
    "خثرة",
    "خد",
    "خدائع",
    "خداج",
    "خداعي",
    "خدام",
    "خدة",
    "خدرة",
    "خدعة",
    "خدل",
    "خدمتكم",
    "خدمه",
    "خدن",
    "خده",
    "خدود",
    "خدور",
    "خدوش",
    "خديج",
    "خديعة",
    "خدين",
    "خديو",
    "خديوي",
    "خذأ",
    "خذاريف",
    "خذره",
    "خذروف",
    "خذروفي",
    "خذف",
    "خذل",
    "خذلان",
    "خذو",
    "خرء",
    "خرئ",
    "خراء",
    "خرائب",
    "خرائد",
    "خراب",
    "خرابة",
    "خرابيش",
    "خراجك",
    "خراجي",
    "خراسان",
    "خراشيف",
    "خراص",
    "خراط",
    "خراطة",
    "خراطيش",
    "خراطيم",
    "خراطين",
    "خراطيني",
    "خراعة",
    "خراف",
    "خرافة",
    "خرافيش",
    "خرامة",
    "خرانق",
    "خربان",
    "خربة",
    "خربش",
    "خربط",
    "خربق",
    "خربوش",
    "خرت",
    "خرتيت",
    "خرجة",
    "خرخر",
    "خرد",
    "خردة",
    "خردجي",
    "خردق",
    "خردوات",
    "خرز",
    "خرس",
    "خرساء",
    "خرسان",
    "خرسانة",
    "خرشوف",
    "خرص",
    "خرصان",
    "خرط",
    "خرطال",
    "خرطوش",
    "خرطوشة",
    "خرطوم",
    "خرطيط",
    "خرع",
    "خرف",
    "خرفان",
    "خرفش",
    "خرفوشة",
    "خرقاء",
    "خرماشة",
    "خرنق",
    "خرنوب",
    "خروب",
    "خروبة",
    "خروت",
    "خروجات",
    "خرور",
    "خروع",
    "خروف",
    "خروق",
    "خروم",
    "خريدة",
    "خرير",
    "خريع",
    "خريف",
    "خز",
    "خزائم",
    "خزازين",
    "خزاف",
    "خزافة",
    "خزام",
    "خزامة",
    "خزامى",
    "خزان",
    "خزايا",
    "خزاية",
    "خزر",
    "خزعبل",
    "خزف",
    "خزفي",
    "خزق",
    "خزل",
    "خزم",
    "خزنة",
    "خزندار",
    "خزوز",
    "خزى",
    "خزيا",
    "خزيان",
    "خزينة",
    "خس",
    "خسأ",
    "خسئ",
    "خسئت",
    "خسائس",
    "خسار",
    "خسارة",
    "خساسة",
    "خسة",
    "خستكة",
    "خسران",
    "خسف",
    "خسفا",
    "خسوف",
    "خسيسة",
    "خش",
    "خشاب",
    "خشاخيش",
    "خشارة",
    "خشاش",
    "خشاف",
    "خشان",
    "خشايا",
    "خشبة",
    "خشت",
    "خشخاش",
    "خشخش",
    "خشخشة",
    "خشخشية",
    "خشعة",
    "خشكار",
    "خشكريشة",
    "خشم",
    "خشناء",
    "خشوت",
    "خشوع",
    "خشيا",
    "خشيان",
    "خصاء",
    "خصائص",
    "خصاص",
    "خصاف",
    "خصال",
    "خصام",
    "خصة",
    "خصر",
    "خصف",
    "خصفة",
    "خصل",
    "خصم",
    "خصماء",
    "خصمه",
    "خصه",
    "خصور",
    "خصوص",
    "خصوصية",
    "خصوم",
    "خصومة",
    "خصيان",
    "خصيب",
    "خصية",
    "خصيصا",
    "خصيصة",
    "خضاب",
    "خضار",
    "خضارة",
    "خضارم",
    "خضب",
    "خضة",
    "خضخض",
    "خضد",
    "خضراوات",
    "خضرم",
    "خضري",
    "خضعان",
    "خضل",
    "خضم",
    "خضوب",
    "خضوع",
    "خضيب",
    "خضير",
    "خطء",
    "خطئ",
    "خطاء",
    "خطابات",
    "خطاة",
    "خطار",
    "خطاط",
    "خطاطة",
    "خطاطيف",
    "خطام",
    "خطاه",
    "خطايا",
    "خطباء",
    "خطبتھا",
    "خطبه",
    "خطران",
    "خطرف",
    "خطرفة",
    "خطفا",
    "خطفات",
    "خطفة",
    "خطل",
    "خطم",
    "خطمي",
    "خطه",
    "خطو",
    "خطوبة",
    "خطوتان",
    "خطور",
    "خطى",
    "خطيب",
    "خطيبة",
    "خطيف",
    "خفاء",
    "خفارة",
    "خفاش",
    "خفاف",
    "خفافش",
    "خفاق",
    "خفان",
    "خفايا",
    "خفر",
    "خفراء",
    "خفس",
    "خفش",
    "خفشاء",
    "خفق",
    "خفقان",
    "خفقة",
    "خفوق",
    "خفى",
    "خفير",
    "خل",
    "خلاء",
    "خلائق",
    "خلاب",
    "خلابة",
    "خلابصة",
    "خلابيص",
    "خلاج",
    "خلاخل",
    "خلاخيل",
    "خلاسي",
    "خلاص",
    "خلاصة",
    "خلاط",
    "خلاع",
    "خلاعة",
    "خلافا",
    "خلافة",
    "خلافه",
    "خلافھم",
    "خلاقة",
    "خلاقين",
    "خلالة",
    "خلان",
    "خلب",
    "خلبص",
    "خلبوص",
    "خلبي",
    "خلج",
    "خلجان",
    "خلجة",
    "خلخال",
    "خلده",
    "خلس",
    "خلسه",
    "خلصاء",
    "خلطاء",
    "خلطة",
    "خلعاء",
    "خلعة",
    "خلعه",
    "خلفھا",
    "خلقاء",
    "خلقان",
    "خلقاني",
    "خلقة",
    "خلقية",
    "خلقين",
    "خلنج",
    "خلنجان",
    "خلوة",
    "خلود",
    "خلوص",
    "خلوق",
    "خلون",
    "خلى",
    "خليص",
    "خليع",
    "خليق",
    "خليقة",
    "خليقون",
    "خليل",
    "خليلة",
    "خم",
    "خمائر",
    "خمائل",
    "خمار",
    "خمارة",
    "خماس",
    "خماسة",
    "خماسين",
    "خماشة",
    "خمة",
    "خمج",
    "خمد",
    "خمرة",
    "خمري",
    "خمرية",
    "خمش",
    "خمص",
    "خمع",
    "خمل",
    "خملة",
    "خمود",
    "خموش",
    "خموع",
    "خموم",
    "خمير",
    "خميس",
    "خميسة",
    "خميص",
    "خميلة",
    "خنا",
    "خناء",
    "خناث",
    "خناثى",
    "خنادق",
    "خناصر",
    "خنافس",
    "خناق",
    "خناقة",
    "خنانيص",
    "خنة",
    "خنث",
    "خنثى",
    "خنجر",
    "خنخن",
    "خندق",
    "خندقا",
    "خنزب",
    "خنزوانية",
    "خنس",
    "خنساء",
    "خنشار",
    "خنصر",
    "خنع",
    "خنف",
    "خنفر",
    "خنفس",
    "خنقة",
    "خنو",
    "خنوثة",
    "خنوص",
    "خنوع",
    "خنى",
    "خني",
    "خنين",
    "خواء",
    "خواب",
    "خوابئ",
    "خوابير",
    "خواتم",
    "خواتيم",
    "خواتين",
    "خواجة",
    "خوازق",
    "خوازيق",
    "خواصة",
    "خواصر",
    "خواطئ",
    "خواطر",
    "خواطف",
    "خواف",
    "خواقين",
    "خوال",
    "خوالج",
    "خوالد",
    "خوانق",
    "خوانيق",
    "خوة",
    "خوجة",
    "خوخ",
    "خوخة",
    "خوذ",
    "خور",
    "خورس",
    "خورنة",
    "خورى",
    "خوري",
    "خوشق",
    "خوص",
    "خوصة",
    "خول",
    "خولة",
    "خولط",
    "خولع",
    "خولى",
    "خون",
    "خونة",
    "خوى",
    "خوي",
    "خويصة",
    "خويف",
    "خيار",
    "خياري",
    "خيازر",
    "خياشم",
    "خياشيم",
    "خياض",
    "خيالة",
    "خياله",
    "خيام",
    "خية",
    "خيدع",
    "خيران",
    "خيرة",
    "خيرى",
    "خيرية",
    "خيزران",
    "خيزرانة",
    "خيس",
    "خيسان",
    "خيش",
    "خيشوم",
    "خيطان",
    "خيلاء",
    "خيلان",
    "خيلت",
    "خيلولة",
    "خيمه",
    "خيور",
    "خيوله",
    "دئب",
    "دائب",
    "دائن",
    "داج",
    "داجن",
    "داجنة",
    "داحة",
    "داحس",
    "داخ",
    "داخلا",
    "داخلة",
    "داخنة",
    "دادة",
    "دارة",
    "دارت",
    "دارسه",
    "دارع",
    "دارعة",
    "دارفلفل",
    "دارنا",
    "داره",
    "دارى",
    "داسر",
    "داغ",
    "داغصة",
    "داغل",
    "داف",
    "دافعة",
    "دافعوا",
    "داقرة",
    "دالت",
    "دالف",
    "دام",
    "داما",
    "دامجانة",
    "دامس",
    "دامغة",
    "دانتيلا",
    "دانق",
    "دانمارك",
    "دانماركى",
    "داه",
    "داورية",
    "داية",
    "داينه",
    "داھية",
    "دباب",
    "دبابة",
    "دبابيج",
    "دباديب",
    "دبارة",
    "دباغ",
    "دببة",
    "دبة",
    "دبج",
    "دبدب",
    "دبدبة",
    "دبدوبة",
    "دبرة",
    "دبري",
    "دبش",
    "دبغ",
    "دبك",
    "دبكة",
    "دبل",
    "دبلة",
    "دبلوماسية",
    "دبلومة",
    "دبور",
    "دبيب",
    "دبير",
    "دثار",
    "دثر",
    "دثور",
    "دج",
    "دجا",
    "دجاجلة",
    "دجال",
    "دجالة",
    "دجة",
    "دججه",
    "دجر",
    "دجل",
    "دجنة",
    "دجنه",
    "دجو",
    "دجى",
    "دجيج",
    "دحا",
    "دحداح",
    "دحدح",
    "دحر",
    "دحرج",
    "دحس",
    "دحض",
    "دحو",
    "دحور",
    "دحوض",
    "دخائل",
    "دخاخني",
    "دخس",
    "دخلاء",
    "دخلة",
    "دخلته",
    "دخمس",
    "دخمسة",
    "دخنة",
    "دخولي",
    "دخولية",
    "دخيل",
    "دخيلة",
    "دخيلك",
    "دخينة",
    "ددن",
    "ددى",
    "درء",
    "درأ",
    "درابزين",
    "درابيس",
    "دراج",
    "درارع",
    "دراريج",
    "درازين",
    "دراس",
    "دراسى",
    "دراعة",
    "دراق",
    "دراك",
    "دراكا",
    "درامة",
    "درامي",
    "دراويش",
    "دراية",
    "دراھم",
    "درب",
    "درباس",
    "دربة",
    "دربس",
    "دربكة",
    "درة",
    "درجته",
    "درد",
    "دردار",
    "دردبيس",
    "دردرة",
    "دردش",
    "دردور",
    "دردى",
    "درر",
    "درز",
    "درزى",
    "درزين",
    "درسا",
    "درف",
    "درفة",
    "درفيل",
    "درق",
    "درقة",
    "درقي",
    "درك",
    "دركة",
    "درم",
    "درمان",
    "درن",
    "درنة",
    "دره",
    "درواس",
    "دروب",
    "دروة",
    "دروج",
    "دروز",
    "دروسا",
    "دروسه",
    "دروع",
    "درى",
    "دريئة",
    "درياق",
    "دريبة",
    "دريس",
    "دريھمات",
    "درھم",
    "دسائس",
    "دساتير",
    "دساس",
    "دساكر",
    "دسام",
    "دسامة",
    "دست",
    "دستة",
    "دسر",
    "دسكر",
    "دسماء",
    "دسمبر",
    "دسو",
    "دسوت",
    "دسومة",
    "دسيسة",
    "دش",
    "دشت",
    "دشن",
    "دشو",
    "دشيش",
    "دشيشة",
    "دعائم",
    "دعاب",
    "دعابة",
    "دعاة",
    "دعار",
    "دعارة",
    "دعاو",
    "دعاوة",
    "دعاوى",
    "دعب",
    "دعبل",
    "دعة",
    "دعج",
    "دعجاء",
    "دعر",
    "دعس",
    "دعك",
    "دعمة",
    "دعنا",
    "دعني",
    "دعو",
    "دعوات",
    "دغال",
    "دغر",
    "دغرى",
    "دغش",
    "دغص",
    "دغل",
    "دغم",
    "دغيشة",
    "دف",
    "دفآن",
    "دفأى",
    "دفؤ",
    "دفئ",
    "دفاء",
    "دفاءة",
    "دفائن",
    "دفاع",
    "دفاق",
    "دفاية",
    "دفة",
    "دفترخانة",
    "دفتيريا",
    "دفر",
    "دفس",
    "دفعات",
    "دفعه",
    "دفق",
    "دفقات",
    "دفقة",
    "دفلى",
    "دفناء",
    "دفوق",
    "دفيء",
    "دفية",
    "دفيف",
    "دفين",
    "دفينة",
    "دقائق",
    "دقاق",
    "دقاقة",
    "دقت",
    "دقشوم",
    "دقع",
    "دقق",
    "دقل",
    "دك",
    "دكانجي",
    "دكتاتورى",
    "دكتاتورية",
    "دكتوراة",
    "دكريتات",
    "دكريتو",
    "دكك",
    "دكن",
    "دكناء",
    "دكه",
    "دكوك",
    "دلاء",
    "دلائل",
    "دلاع",
    "دلافين",
    "دلال",
    "دلالة",
    "دلاية",
    "دلة",
    "دلتا",
    "دلج",
    "دلح",
    "دلدل",
    "دلدول",
    "دلس",
    "دلع",
    "دلغان",
    "دلف",
    "دلفان",
    "دلق",
    "دلك",
    "دله",
    "دلو",
    "دلوح",
    "دلوف",
    "دلوك",
    "دلھم",
    "دمائع",
    "دماث",
    "دماثة",
    "دمار",
    "دماسة",
    "دماع",
    "دمالج",
    "دمام",
    "دمامي",
    "دماميس",
    "دماميل",
    "دمان",
    "دمث",
    "دمجانة",
    "دمس",
    "دمع",
    "دمعة",
    "دمعه",
    "دمعى",
    "دمعية",
    "دمغ",
    "دمغة",
    "دمقراطي",
    "دمقراطية",
    "دمقس",
    "دمقسي",
    "دمل",
    "دملان",
    "دملج",
    "دملي",
    "دمن",
    "دمنة",
    "دمنھور",
    "دمه",
    "دمور",
    "دموري",
    "دموس",
    "دمى",
    "دمي",
    "دمياط",
    "دميرة",
    "دميع",
    "دميم",
    "دنأ",
    "دنؤ",
    "دناءة",
    "دناجل",
    "دنادشة",
    "دنان",
    "دنانير",
    "دناوة",
    "دنايا",
    "دناية",
    "دنتلا",
    "دنتلة",
    "دنجل",
    "دندرمه",
    "دندي",
    "دنساء",
    "دنف",
    "دنقلة",
    "دنمرك",
    "دنو",
    "دنوءة",
    "دنى",
    "دني",
    "دنيء",
    "دنياوي",
    "دنية",
    "دنين",
    "دوء",
    "دوائي",
    "دواب",
    "دواة",
    "دواجن",
    "دواخل",
    "دواخن",
    "دوارس",
    "دوارع",
    "دوارق",
    "دواع",
    "دواعي",
    "دواغص",
    "دوافع",
    "دوافق",
    "دواقر",
    "دوال",
    "دوالف",
    "دواليب",
    "دواليك",
    "دوام",
    "دواما",
    "دوانق",
    "دواه",
    "دواوين",
    "دواية",
    "دوبارة",
    "دوبرة",
    "دوبية",
    "دوبيت",
    "دوح",
    "دوحة",
    "دوخ",
    "دودية",
    "دوره",
    "دورى",
    "دوزان",
    "دوزن",
    "دوزنة",
    "دوزينة",
    "دوسنتاريا",
    "دوسنطاريا",
    "دوسية",
    "دوسيه",
    "دوش",
    "دوشة",
    "دوطة",
    "دوق",
    "دوقة",
    "دوقية",
    "دوك",
    "دولب",
    "دولية",
    "دوم",
    "دومان",
    "دومانجي",
    "دومج",
    "دومرى",
    "دونم",
    "دونھا",
    "دونھم",
    "دويات",
    "دويبة",
    "دويلات",
    "دياج",
    "دياجير",
    "ديادبة",
    "ديار",
    "ديارات",
    "دياري",
    "ديارھم",
    "دياس",
    "ديالوج",
    "دياميس",
    "ديانة",
    "ديانم",
    "ديباج",
    "ديباجة",
    "دية",
    "ديجور",
    "ديجوري",
    "ديدبان",
    "ديدن",
    "ديرانى",
    "ديراني",
    "ديرة",
    "ديرى",
    "ديسة",
    "ديسم",
    "ديسمبر",
    "ديشين",
    "ديكة",
    "ديماس",
    "ديمة",
    "ديمقراطي",
    "ديمقراطية",
    "ديموس",
    "ديموطيقي",
    "ديموقراطية",
    "ديمومة",
    "دينار",
    "ديناري",
    "دينامو",
    "ديناميت",
    "دينم",
    "دينونة",
    "ديوان",
    "ديواني",
    "ديوث",
    "ديورة",
    "ديوس",
    "ديوم",
    "دھاء",
    "دھاة",
    "دھاق",
    "دھاقنة",
    "دھاقين",
    "دھاليز",
    "دھان",
    "دھر",
    "دھري",
    "دھس",
    "دھش",
    "دھشة",
    "دھق",
    "دھقان",
    "دھك",
    "دھلز",
    "دھلي",
    "دھليز",
    "دھم",
    "دھماء",
    "دھمة",
    "دھن",
    "دھناء",
    "دھنيات",
    "دھور",
    "دھون",
    "دھي",
    "دھياء",
    "دھينة",
    "ذأب",
    "ذؤابة",
    "ذؤبان",
    "ذئاب",
    "ذائد",
    "ذابت",
    "ذاتا",
    "ذاته",
    "ذاتى",
    "ذاتھا",
    "ذاد",
    "ذادة",
    "ذاع",
    "ذاق",
    "ذاكر",
    "ذاكرته",
    "ذال",
    "ذاو",
    "ذاھب",
    "ذاھل",
    "ذبائح",
    "ذباح",
    "ذبالة",
    "ذبان",
    "ذبانة",
    "ذبحة",
    "ذبذب",
    "ذبيح",
    "ذبيحة",
    "ذحل",
    "ذحول",
    "ذخائر",
    "ذخر",
    "ذخيرة",
    "ذر",
    "ذرا",
    "ذرائر",
    "ذرائع",
    "ذراح",
    "ذراري",
    "ذراريح",
    "ذرب",
    "ذرع",
    "ذرعان",
    "ذرف",
    "ذرفان",
    "ذرو",
    "ذرور",
    "ذروري",
    "ذروف",
    "ذرى",
    "ذريرة",
    "ذريع",
    "ذريعا",
    "ذريعة",
    "ذريف",
    "ذعاف",
    "ذعر",
    "ذعق",
    "ذعن",
    "ذفر",
    "ذقنه",
    "ذقون",
    "ذقونھم",
    "ذكا",
    "ذكاء",
    "ذكاءه",
    "ذكران",
    "ذكرة",
    "ذكو",
    "ذكى",
    "ذكير",
    "ذل",
    "ذلاذل",
    "ذلالة",
    "ذلذل",
    "ذلف",
    "ذلفاء",
    "ذلق",
    "ذلل",
    "ذليق",
    "ذماء",
    "ذمائم",
    "ذمار",
    "ذمام",
    "ذمته",
    "ذمتي",
    "ذمر",
    "ذمم",
    "ذمي",
    "ذميم",
    "ذميمة",
    "ذنبي",
    "ذنيب",
    "ذوائب",
    "ذوابة",
    "ذود",
    "ذووه",
    "ذوى",
    "ذياد",
    "ذيع",
    "ذيله",
    "ذيوع",
    "ذھاب",
    "ذھابا",
    "ذھب",
    "ذھبوا",
    "ذھبي",
    "ذھبية",
    "ذھل",
    "ذھن",
    "ذھنه",
    "ذھني",
    "ذھنية",
    "ذھوب",
    "ذھول",
    "رء",
    "رءوم",
    "رآسة",
    "رآفة",
    "رأب",
    "رأرأ",
    "رأسمالية",
    "رأف",
    "رأم",
    "رأيا",
    "رأيته",
    "رأيه",
    "رأيي",
    "رأيھم",
    "رؤبة",
    "رؤد",
    "رؤس",
    "رؤف",
    "رؤوسھم",
    "رؤوف",
    "رؤى",
    "رؤي",
    "رؤيا",
    "رئات",
    "رئة",
    "رئد",
    "رئم",
    "رئمان",
    "رئون",
    "رئيسا",
    "راء",
    "رائب",
    "رائح",
    "رائق",
    "رائية",
    "راب",
    "رابة",
    "رابحة",
    "رابعه",
    "رابغ",
    "رابور",
    "راتب",
    "راتينة",
    "راتينج",
    "راث",
    "راجح",
    "راجل",
    "راحلة",
    "راحلته",
    "راحيل",
    "راخ",
    "رادار",
    "راديكال",
    "راديوفونية",
    "راديولوجي",
    "راديوم",
    "راز",
    "راس",
    "راسب",
    "راش",
    "راشد",
    "راشن",
    "راصد",
    "راصدة",
    "راض",
    "راضع",
    "راطب",
    "راع",
    "راعب",
    "راعف",
    "راعني",
    "راعى",
    "راغ",
    "راغم",
    "راغية",
    "رافد",
    "رافدة",
    "رافضة",
    "رافضي",
    "رافيا",
    "راقد",
    "راقوبة",
    "راقود",
    "رال",
    "رام",
    "راموز",
    "راموس",
    "رامية",
    "راو",
    "راوده",
    "راوند",
    "راووق",
    "راوية",
    "راى",
    "راية",
    "رايته",
    "راھب",
    "راھبة",
    "راھل",
    "راھن",
    "ربأ",
    "رباء",
    "ربائب",
    "رباب",
    "ربابة",
    "ربابنة",
    "ربابيح",
    "رباح",
    "رباطه",
    "رباع",
    "رباق",
    "ربالة",
    "ربان",
    "ربايا",
    "ربت",
    "ربد",
    "ربص",
    "ربض",
    "ربعة",
    "ربعي",
    "ربق",
    "ربقة",
    "ربل",
    "ربلة",
    "ربلي",
    "ربو",
    "ربوب",
    "ربوبية",
    "ربورتاج",
    "ربوع",
    "ربوى",
    "ربى",
    "ربيئة",
    "ربيب",
    "ربيبة",
    "ربيل",
    "رت",
    "رتا",
    "رتاء",
    "رتائج",
    "رتائم",
    "رتائن",
    "رتاج",
    "رتاع",
    "رتام",
    "رتباء",
    "رتج",
    "رتع",
    "رتق",
    "رتك",
    "رتكان",
    "رتم",
    "رتمة",
    "رتو",
    "رتوش",
    "رتوع",
    "رتوق",
    "رتى",
    "رتيلاء",
    "رتيمة",
    "رتينة",
    "رتينج",
    "رثا",
    "رثاء",
    "رثاث",
    "رثاه",
    "رثة",
    "رثو",
    "رثوثة",
    "رثي",
    "رثية",
    "رثيث",
    "رج",
    "رجأ",
    "رجاة",
    "رجاج",
    "رجاحة",
    "رجاس",
    "رجاسة",
    "رجاف",
    "رجالات",
    "رجالة",
    "رجالي",
    "رجام",
    "رجاه",
    "رجب",
    "رجة",
    "رجحان",
    "رجراج",
    "رجرج",
    "رجعع",
    "رجف",
    "رجفان",
    "رجفة",
    "رجله",
    "رجليه",
    "رجم",
    "رجمة",
    "رجو",
    "رجوح",
    "رجولية",
    "رجي",
    "رجية",
    "رجيع",
    "رحاب",
    "رحابة",
    "رحال",
    "رحالة",
    "رحاله",
    "رحالھا",
    "رحاوى",
    "رحبا",
    "رحبة",
    "رحراح",
    "رحرح",
    "رحض",
    "رحلوا",
    "رحمك",
    "رحمى",
    "رحوم",
    "رحوى",
    "رحى",
    "رحيب",
    "رحيل",
    "رخ",
    "رخا",
    "رخاخ",
    "رخاصة",
    "رخام",
    "رخاوة",
    "رخخة",
    "رخم",
    "رخمت",
    "رخو",
    "رخي",
    "ردء",
    "ردأ",
    "ردؤ",
    "ردا",
    "رداءة",
    "رداف",
    "ردة",
    "ردح",
    "ردحا",
    "ردس",
    "ردع",
    "ردغة",
    "ردم",
    "ردن",
    "ردنجوت",
    "رده",
    "ردى",
    "ردي",
    "رديفة",
    "رديم",
    "ردينى",
    "ردھة",
    "ردھم",
    "رذ",
    "رذائل",
    "رذالة",
    "رذت",
    "رذل",
    "رذلاء",
    "رذول",
    "رذيل",
    "رز",
    "رزء",
    "رزأ",
    "رزئ",
    "رزاح",
    "رزانة",
    "رزايا",
    "رزب",
    "رزة",
    "رزح",
    "رزن",
    "رزنامة",
    "رزوح",
    "رزيئة",
    "رزية",
    "رسامة",
    "رساميل",
    "رسب",
    "رست",
    "رستامية",
    "رستق",
    "رستو",
    "رستوران",
    "رسراس",
    "رسف",
    "رسلاء",
    "رسمال",
    "رسميات",
    "رسن",
    "رسو",
    "رسوب",
    "رسوخ",
    "رسولي",
    "رسولية",
    "رسومات",
    "رسى",
    "رسيس",
    "رسيل",
    "رسيلات",
    "رسيلاته",
    "رشاء",
    "رشاحة",
    "رشاد",
    "رشاشة",
    "رشاقة",
    "رشاوى",
    "رشة",
    "رشد",
    "رشداء",
    "رشراش",
    "رشرش",
    "رشف",
    "رشفة",
    "رشق",
    "رشم",
    "رشمة",
    "رشو",
    "رشوة",
    "رشوم",
    "رشومات",
    "رشى",
    "رص",
    "رصاد",
    "رصاصة",
    "رصافة",
    "رصانة",
    "رصدخانة",
    "رصف",
    "رصفاء",
    "رصن",
    "رصيص",
    "رصيفة",
    "رصيفتنا",
    "رضا",
    "رضاء",
    "رضائع",
    "رضاب",
    "رضاة",
    "رضاع",
    "رضاعة",
    "رضخ",
    "رضخة",
    "رضراض",
    "رضرض",
    "رضعاء",
    "رضه",
    "رضوخ",
    "رضوض",
    "رضيحة",
    "رضيض",
    "رضيع",
    "رطاب",
    "رطابة",
    "رطانة",
    "رطل",
    "رطم",
    "رطن",
    "رطوبة",
    "رطيب",
    "رطينى",
    "رعا",
    "رعاء",
    "رعائى",
    "رعائية",
    "رعاد",
    "رعاديد",
    "رعارع",
    "رعاع",
    "رعاف",
    "رعال",
    "رعام",
    "رعان",
    "رعاوى",
    "رعايا",
    "رعب",
    "رعدة",
    "رعديد",
    "رعراع",
    "رعرع",
    "رعش",
    "رعشة",
    "رعص",
    "رعف",
    "رعلة",
    "رعمسيس",
    "رعن",
    "رعنته",
    "رعو",
    "رعوة",
    "رعود",
    "رعونة",
    "رعوى",
    "رعوية",
    "رعيا",
    "رعيان",
    "رعية",
    "رعيف",
    "رعيل",
    "رغا",
    "رغاء",
    "رغائب",
    "رغادة",
    "رغام",
    "رغامى",
    "رغاو",
    "رغاوة",
    "رغب",
    "رغبته",
    "رغث",
    "رغد",
    "رغرغ",
    "رغف",
    "رغفان",
    "رغما",
    "رغو",
    "رغوث",
    "رغيبة",
    "رغيد",
    "رغيف",
    "رفأ",
    "رفا",
    "رفاء",
    "رفائع",
    "رفادة",
    "رفارف",
    "رفاريف",
    "رفاس",
    "رفاص",
    "رفاع",
    "رفاف",
    "رفاه",
    "رفاھة",
    "رفاھية",
    "رفت",
    "رفتية",
    "رفح",
    "رفد",
    "رفروف",
    "رفس",
    "رفش",
    "رفعة",
    "رفعنا",
    "رفق",
    "رفقة",
    "رفل",
    "رفه",
    "رفو",
    "رفود",
    "رفيض",
    "رفيف",
    "رفيق",
    "رفيقة",
    "رقأ",
    "رقاء",
    "رقاب",
    "رقابھم",
    "رقاة",
    "رقاد",
    "رقارق",
    "رقاص",
    "رقاصة",
    "رقاع",
    "رقاعة",
    "رقاق",
    "رقب",
    "رقباء",
    "رقبتھا",
    "رقبى",
    "رقد",
    "رقدة",
    "رقراق",
    "رقرق",
    "رقش",
    "رقط",
    "رقما",
    "رقوب",
    "رقوبة",
    "رقوص",
    "رقوق",
    "رقى",
    "رقي",
    "رقية",
    "رقيع",
    "رقيقة",
    "رقيم",
    "رك",
    "ركائب",
    "ركائز",
    "ركابه",
    "ركاز",
    "ركاض",
    "ركاك",
    "ركاكة",
    "ركام",
    "ركبان",
    "ركبة",
    "ركبتاه",
    "ركبتيه",
    "ركة",
    "ركد",
    "ركزان",
    "ركزة",
    "ركس",
    "ركعة",
    "رككة",
    "ركل",
    "ركلة",
    "ركم",
    "ركنى",
    "ركنية",
    "ركوبة",
    "ركوة",
    "ركود",
    "ركوض",
    "ركوع",
    "ركون",
    "ركيك",
    "ركين",
    "رم",
    "رمائم",
    "رماح",
    "رماد",
    "رماك",
    "رمال",
    "رمام",
    "رمان",
    "رمانة",
    "رماه",
    "رمايا",
    "رمة",
    "رمث",
    "رمح",
    "رمد",
    "رمدي",
    "رمزيات",
    "رمس",
    "رمشة",
    "رمص",
    "رمض",
    "رمضاء",
    "رمق",
    "رمقه",
    "رمك",
    "رمكاء",
    "رمكة",
    "رموز",
    "رموس",
    "رمي",
    "رميا",
    "رنا",
    "رنانة",
    "رنة",
    "رنح",
    "رندح",
    "رنق",
    "رنم",
    "رنو",
    "رنى",
    "رواء",
    "روائح",
    "روائس",
    "روائع",
    "رواب",
    "روابط",
    "رواتب",
    "رواج",
    "رواح",
    "رواحل",
    "روادع",
    "رواس",
    "رواسب",
    "رواسم",
    "رواشن",
    "رواغ",
    "روافد",
    "روافض",
    "رواق",
    "رواقه",
    "رواقيد",
    "روال",
    "روامز",
    "روايا",
    "روبابيكا",
    "روبصة",
    "روبل",
    "روبية",
    "روتانية",
    "روحانية",
    "روحة",
    "روحى",
    "روحية",
    "روحھم",
    "روز",
    "روزنامة",
    "روستو",
    "روسم",
    "روشن",
    "روعك",
    "روعه",
    "روغ",
    "روغان",
    "روق",
    "روقة",
    "روم",
    "روماتزم",
    "رومة",
    "رومي",
    "رومية",
    "روند",
    "رونق",
    "روى",
    "روية",
    "رويغة",
    "رياء",
    "رياح",
    "رياحين",
    "ريادة",
    "رياسة",
    "رياسي",
    "رياش",
    "رياضات",
    "رياع",
    "رية",
    "ريحان",
    "ريحة",
    "ريحه",
    "ريخ",
    "ريضان",
    "ريعان",
    "ريفى",
    "ريق",
    "ريقة",
    "ريقه",
    "ريل",
    "ريم",
    "رين",
    "ريوع",
    "رھا",
    "رھاء",
    "رھائن",
    "رھاط",
    "رھافة",
    "رھام",
    "رھان",
    "رھب",
    "رھبان",
    "رھبانية",
    "رھبة",
    "رھبن",
    "رھبنة",
    "رھبوب",
    "رھبى",
    "رھج",
    "رھط",
    "رھف",
    "رھق",
    "رھل",
    "رھم",
    "رھمة",
    "رھن",
    "رھنا",
    "رھنية",
    "رھو",
    "رھوان",
    "رھون",
    "رھونات",
    "رھيب",
    "رھيف",
    "رھين",
    "رھينة",
    "رھينه",
    "زأر",
    "زأط",
    "زؤام",
    "زؤان",
    "زئاط",
    "زئبق",
    "زئير",
    "زاء",
    "زاؤوق",
    "زائر",
    "زائرة",
    "زائغة",
    "زائل",
    "زاج",
    "زاجر",
    "زاجرة",
    "زاجورا",
    "زاح",
    "زاخر",
    "زاده",
    "زار",
    "زاره",
    "زاروب",
    "زاعولة",
    "زاف",
    "زام",
    "زانة",
    "زاننية",
    "زاه",
    "زاول",
    "زاووق",
    "زاي",
    "زايل",
    "زاھر",
    "زب",
    "زباء",
    "زبائن",
    "زباد",
    "زبادي",
    "زباطة",
    "زبال",
    "زبالة",
    "زبانة",
    "زبانى",
    "زبانيا",
    "زبانية",
    "زبدية",
    "زبرة",
    "زبرج",
    "زبرجد",
    "زبط",
    "زبع",
    "زبق",
    "زبل",
    "زبلة",
    "زبور",
    "زبون",
    "زبى",
    "زبيب",
    "زبية",
    "زت",
    "زج",
    "زجا",
    "زجاء",
    "زجال",
    "زجر",
    "زجري",
    "زجل",
    "زحار",
    "زحافة",
    "زحام",
    "زحر",
    "زحزحة",
    "زحلاوي",
    "زحلقة",
    "زحم",
    "زحمة",
    "زحوف",
    "زحول",
    "زحير",
    "زخار",
    "زخة",
    "زخر",
    "زخم",
    "زخمة",
    "زخور",
    "زرائف",
    "زرابى",
    "زرابيل",
    "زرابين",
    "زرازير",
    "زراع",
    "زرافات",
    "زرافى",
    "زراية",
    "زرب",
    "زربول",
    "زربون",
    "زربية",
    "زرة",
    "زرد",
    "زردة",
    "زردية",
    "زرزر",
    "زرزور",
    "زرزوري",
    "زرق",
    "زرقاء",
    "زرقون",
    "زرنيخ",
    "زرود",
    "زرور",
    "زروع",
    "زرى",
    "زريعة",
    "زعابيط",
    "زعارة",
    "زعارير",
    "زعازع",
    "زعاف",
    "زعامة",
    "زعانف",
    "زعبر",
    "زعبوط",
    "زعتر",
    "زعج",
    "زعر",
    "زعراء",
    "زعران",
    "زعرور",
    "زعزعان",
    "زعزعة",
    "زعزوع",
    "زعط",
    "زعف",
    "زعفران",
    "زعق",
    "زعقة",
    "زعل",
    "زعلان",
    "زعماء",
    "زعمھم",
    "زعنفة",
    "زعيق",
    "زغاريد",
    "زغاريط",
    "زغاليل",
    "زغبر",
    "زغد",
    "زغر",
    "زغرد",
    "زغردة",
    "زغرط",
    "زغزغ",
    "زغطة",
    "زغل",
    "زغلل",
    "زغلول",
    "زف",
    "زفاف",
    "زفة",
    "زفت",
    "زفر",
    "زفرة",
    "زفزفة",
    "زفن",
    "زفوف",
    "زفيف",
    "زق",
    "زقا",
    "زقاء",
    "زقائل",
    "زقاق",
    "زقان",
    "زقزاق",
    "زقزق",
    "زقزقة",
    "زقلة",
    "زقلية",
    "زقم",
    "زقو",
    "زقيلة",
    "زكا",
    "زكاء",
    "زكائب",
    "زكام",
    "زكانة",
    "زكب",
    "زكر",
    "زكرة",
    "زكم",
    "زكمة",
    "زكو",
    "زكوات",
    "زكوب",
    "زكوة",
    "زكي",
    "زكيبة",
    "زل",
    "زلابية",
    "زلازل",
    "زلاعيم",
    "زلاقة",
    "زلال",
    "زلالم",
    "زلاليات",
    "زلالية",
    "زلت",
    "زلج",
    "زلزال",
    "زلزل",
    "زلط",
    "زلع",
    "زلعة",
    "زلعوم",
    "زلف",
    "زلقة",
    "زلل",
    "زلم",
    "زلوج",
    "زلومة",
    "زليج",
    "زليجي",
    "زليف",
    "زمارة",
    "زمازم",
    "زمالة",
    "زمام",
    "زمامير",
    "زمان",
    "زمانة",
    "زمانه",
    "زماني",
    "زمانية",
    "زمت",
    "زمجر",
    "زمرة",
    "زمرد",
    "زمردى",
    "زمزم",
    "زمزمة",
    "زمزمية",
    "زمط",
    "زمع",
    "زمل",
    "زملة",
    "زمنا",
    "زمنة",
    "زمنى",
    "زمنية",
    "زمور",
    "زمير",
    "زميلة",
    "زمين",
    "زمھر",
    "زمھرير",
    "زنأ",
    "زنا",
    "زناء",
    "زناببيل",
    "زنابق",
    "زنابك",
    "زنابير",
    "زناة",
    "زناجير",
    "زناد",
    "زنادقة",
    "زنار",
    "زنارة",
    "زنانير",
    "زنبر",
    "زنبرك",
    "زنبق",
    "زنبلك",
    "زنبور",
    "زنبيل",
    "زنة",
    "زنتاري",
    "زنج",
    "زنجار",
    "زنجبار",
    "زنجبيل",
    "زنجر",
    "زنجفر",
    "زنجير",
    "زنخ",
    "زند",
    "زندق",
    "زندقة",
    "زنر",
    "زنزانة",
    "زنطارية",
    "زنق",
    "زنقة",
    "زنك",
    "زنلخت",
    "زنمردة",
    "زنوج",
    "زنود",
    "زنى",
    "زوائد",
    "زوابع",
    "زواجا",
    "زواجر",
    "زواحف",
    "زواد",
    "زوادة",
    "زوار",
    "زوارب",
    "زواق",
    "زوالي",
    "زوان",
    "زوبعة",
    "زوجية",
    "زوجھا",
    "زوح",
    "زوراء",
    "زورة",
    "زوغ",
    "زوغان",
    "زوفاء",
    "زوفى",
    "زوق",
    "زول",
    "زولوجي",
    "زولوجيا",
    "زولية",
    "زوم",
    "زوى",
    "زيا",
    "زيات",
    "زياح",
    "زيار",
    "زيارة",
    "زيانة",
    "زيبق",
    "زيتون",
    "زيتوني",
    "زيتونية",
    "زيتية",
    "زيج",
    "زيجة",
    "زيح",
    "زيحان",
    "زيز",
    "زيزان",
    "زيزفون",
    "زيغان",
    "زيق",
    "زينت",
    "زينكو",
    "زيوف",
    "زيى",
    "زھا",
    "زھاء",
    "زھاد",
    "زھادة",
    "زھار",
    "زھد",
    "زھر",
    "زھراوى",
    "زھرة",
    "زھرةا",
    "زھرى",
    "زھرية",
    "زھف",
    "زھق",
    "زھقت",
    "زھم",
    "زھمة",
    "زھو",
    "زھور",
    "زھوف",
    "زھوق",
    "زھومة",
    "زھى",
    "زھيد",
    "سآمة",
    "سأال",
    "سأسباب",
    "سألة",
    "سأله",
    "سأم",
    "سؤالا",
    "سؤدد",
    "سؤر",
    "سؤرة",
    "سؤل",
    "سؤلة",
    "سؤله",
    "سؤول",
    "سؤوم",
    "سئر",
    "سئم",
    "سائح",
    "سائس",
    "سائغة",
    "سائلية",
    "سائمة",
    "ساب",
    "ساباط",
    "سابحات",
    "سابحة",
    "سابغ",
    "سابقية",
    "سابل",
    "سابلة",
    "ساتان",
    "ساج",
    "ساجات",
    "ساجع",
    "ساجله",
    "ساح",
    "ساحب",
    "ساحته",
    "ساحلية",
    "ساخ",
    "ساخت",
    "سادات",
    "سادة",
    "سادر",
    "سادن",
    "سارب",
    "ساربة",
    "سارقة",
    "سارقه",
    "ساروخ",
    "سارى",
    "ساري",
    "ساس",
    "ساسة",
    "ساط",
    "ساطور",
    "ساع",
    "ساعاتي",
    "ساعتئذ",
    "ساعته",
    "ساعتين",
    "ساعدة",
    "ساعده",
    "ساعى",
    "ساغ",
    "ساغب",
    "ساغو",
    "سافل",
    "سافياء",
    "ساقة",
    "ساقطة",
    "ساقه",
    "ساقية",
    "ساقيه",
    "ساقھا",
    "ساك",
    "ساكت",
    "ساكف",
    "ساكنه",
    "ساكو",
    "ساكوه",
    "سالفا",
    "سالما",
    "سامد",
    "سامر",
    "سامع",
    "سامق",
    "سامه",
    "سانح",
    "سانحة",
    "سانية",
    "ساه",
    "ساھد",
    "ساھر",
    "ساھرة",
    "ساھل",
    "ساھم",
    "ساھيا",
    "سبأ",
    "سبا",
    "سباء",
    "سبائب",
    "سبائخ",
    "سبائك",
    "سباب",
    "سبابة",
    "سبابيط",
    "سباخ",
    "سبار",
    "سباسب",
    "سباط",
    "سباطة",
    "سباع",
    "سباعي",
    "سباك",
    "سباكة",
    "سبال",
    "سبانخ",
    "سبايا",
    "سبايس",
    "سباھلة",
    "سباھى",
    "سباھي",
    "سببية",
    "سبة",
    "سبت",
    "سبتمبر",
    "سبج",
    "سبحاء",
    "سبحات",
    "سبحة",
    "سبحلة",
    "سبخ",
    "سبخة",
    "سبر",
    "سبس",
    "سبسب",
    "سبط",
    "سبعوني",
    "سبغ",
    "سبقة",
    "سبقه",
    "سبلان",
    "سبلة",
    "سبنسة",
    "سبه",
    "سبوح",
    "سبور",
    "سبورة",
    "سبوطة",
    "سبوع",
    "سبوعة",
    "سبى",
    "سبي",
    "سبيب",
    "سبية",
    "سبيخ",
    "سبيداج",
    "سبيذاج",
    "سبھللا",
    "ست",
    "ستائر",
    "ستجابة",
    "ستر",
    "سترة",
    "ستري",
    "ستف",
    "سته",
    "ستور",
    "ستونى",
    "سجا",
    "سجائر",
    "سجاجيد",
    "سجاد",
    "سجادة",
    "سجارة",
    "سجاف",
    "سجال",
    "سجالا",
    "سجام",
    "سجان",
    "سجايا",
    "سجاير",
    "سجحاء",
    "سجد",
    "سجدة",
    "سجر",
    "سجس",
    "سجعة",
    "سجف",
    "سجله",
    "سجم",
    "سجمان",
    "سجناء",
    "سجنى",
    "سجو",
    "سجود",
    "سجوف",
    "سجوم",
    "سجية",
    "سجيته",
    "سجيتھا",
    "سجينة",
    "سح",
    "سحاءة",
    "سحائب",
    "سحابة",
    "سحاحة",
    "سحاحير",
    "سحارة",
    "سحاق",
    "سحالة",
    "سحام",
    "سحايا",
    "سحت",
    "سحتوت",
    "سحرة",
    "سحره",
    "سحقا",
    "سحل",
    "سحلب",
    "سحم",
    "سحماء",
    "سحمة",
    "سحن",
    "سحنة",
    "سحوبات",
    "سحوح",
    "سحور",
    "سحوق",
    "سخا",
    "سخاء",
    "سخائف",
    "سخائم",
    "سخاف",
    "سخال",
    "سخام",
    "سخان",
    "سخانات",
    "سخانة",
    "سخاوة",
    "سختيان",
    "سخرة",
    "سخري",
    "سخف",
    "سخفاء",
    "سخلة",
    "سخم",
    "سخمة",
    "سخنة",
    "سخو",
    "سخية",
    "سخيمة",
    "سدائر",
    "سداد",
    "سدارة",
    "سدانة",
    "سدب",
    "سدة",
    "سدف",
    "سدم",
    "سدنة",
    "سدو",
    "سدود",
    "سدور",
    "سدول",
    "سديد",
    "سديم",
    "سذاب",
    "سذبي",
    "سذج",
    "سراء",
    "سرائر",
    "سراة",
    "سراجة",
    "سرادب",
    "سرادق",
    "سراديك",
    "سرار",
    "سراري",
    "سراس",
    "سراط",
    "سراع",
    "سراه",
    "سراويل",
    "سراى",
    "سرايا",
    "سراية",
    "سراير",
    "سربال",
    "سربة",
    "سربل",
    "سرجين",
    "سرحان",
    "سرد",
    "سردار",
    "سردوك",
    "سردين",
    "سرروات",
    "سرسام",
    "سرط",
    "سرعتك",
    "سرعسكر",
    "سرف",
    "سرقان",
    "سرقسطة",
    "سرقين",
    "سرك",
    "سركي",
    "سرم",
    "سرمدي",
    "سرنا",
    "سرنديب",
    "سره",
    "سرو",
    "سرواء",
    "سروات",
    "سروج",
    "سروجي",
    "سروجية",
    "سروح",
    "سرورنا",
    "سروري",
    "سرولا",
    "سرى",
    "سريان",
    "سرياني",
    "سرياوران",
    "سريح",
    "سريدة",
    "سريرة",
    "سريعا",
    "سريوران",
    "سطام",
    "سطحيات",
    "سطحية",
    "سطرا",
    "سطل",
    "سطو",
    "سطوة",
    "سطوح",
    "سطور",
    "سطوع",
    "سطول",
    "سطيح",
    "سعادة",
    "سعادتكم",
    "سعادين",
    "سعار",
    "سعال",
    "سعاية",
    "سعتر",
    "سعتي",
    "سعد",
    "سعداء",
    "سعدان",
    "سعدانة",
    "سعط",
    "سعف",
    "سعل",
    "سعلاة",
    "سعلة",
    "سعلى",
    "سعليات",
    "سعن",
    "سعودي",
    "سعوط",
    "سعير",
    "سغابة",
    "سغب",
    "سف",
    "سفائن",
    "سفاد",
    "سفارة",
    "سفارج",
    "سفاسف",
    "سفافيد",
    "سفال",
    "سفالة",
    "سفان",
    "سفانة",
    "سفاه",
    "سفاھة",
    "سفتجة",
    "سفد",
    "سفراء",
    "سفرات",
    "سفرجل",
    "سفرجي",
    "سفرجية",
    "سفرى",
    "سفرية",
    "سفساف",
    "سفسة",
    "سفسطات",
    "سفسطة",
    "سفسطي",
    "سفط",
    "سفع",
    "سفعاء",
    "سفعة",
    "سفق",
    "سفك",
    "سفل",
    "سفلاق",
    "سفلة",
    "سفلقة",
    "سفلى",
    "سفنج",
    "سفود",
    "سفور",
    "سفوف",
    "سفول",
    "سفى",
    "سفين",
    "سفھاء",
    "سقاء",
    "سقائف",
    "سقاة",
    "سقارة",
    "سقاط",
    "سقاطة",
    "سقالبة",
    "سقالة",
    "سقام",
    "سقاوة",
    "سقسقة",
    "سقطات",
    "سقطة",
    "سقطراء",
    "سقطى",
    "سقع",
    "سقلب",
    "سقلبي",
    "سقم",
    "سقماء",
    "سقوط",
    "سقوف",
    "سقى",
    "سقيط",
    "سكاء",
    "سكائر",
    "سكائن",
    "سكات",
    "سكارة",
    "سكارتو",
    "سكاريج",
    "سكارين",
    "سكاف",
    "سكافة",
    "سكاكر",
    "سكاكيني",
    "سكب",
    "سكباج",
    "سكتة",
    "سكرات",
    "سكرتاة",
    "سكرتارية",
    "سكرتو",
    "سكرتير",
    "سكرتيرية",
    "سكرجة",
    "سكروز",
    "سكرى",
    "سكرية",
    "سكرين",
    "سكسك",
    "سكسكة",
    "سكسونيا",
    "سكع",
    "سكنت",
    "سكنجبين",
    "سكندنافي",
    "سكندناوة",
    "سكندينافيا",
    "سكنى",
    "سكون",
    "سكي",
    "سكيب",
    "سكيت",
    "سلأ",
    "سلا",
    "سلاء",
    "سلائق",
    "سلائل",
    "سلاب",
    "سلاحدار",
    "سلاحف",
    "سلاحه",
    "سلاطح",
    "سلاطين",
    "سلاف",
    "سلافة",
    "سلاقة",
    "سلاقون",
    "سلاقى",
    "سلاقي",
    "سلال",
    "سلالم",
    "سلالى",
    "سلاليم",
    "سلامتك",
    "سلاملك",
    "سلامى",
    "سلامي",
    "سلاميات",
    "سلامية",
    "سلانيك",
    "سلاوى",
    "سلبند",
    "سلت",
    "سلج",
    "سلجم",
    "سلح",
    "سلحفاء",
    "سلحفائية",
    "سلسبيل",
    "سلسله",
    "سلط",
    "سلطانة",
    "سلطاني",
    "سلطانية",
    "سلطح",
    "سلطن",
    "سلطنة",
    "سلطوا",
    "سلفاء",
    "سلفات",
    "سلفة",
    "سلفت",
    "سلفية",
    "سلفيد",
    "سلق",
    "سلقون",
    "سلكة",
    "سلكه",
    "سلكي",
    "سلكية",
    "سلماء",
    "سلمة",
    "سلندر",
    "سلو",
    "سلواة",
    "سلوان",
    "سلوة",
    "سلوع",
    "سلوقي",
    "سلوى",
    "سليح",
    "سليخة",
    "سليقة",
    "سليل",
    "سليلة",
    "سليماني",
    "سلينيوم",
    "سما",
    "سمائم",
    "سمائي",
    "سماة",
    "سمات",
    "سماج",
    "سماجة",
    "سماجى",
    "سماح",
    "سماحة",
    "سماحته",
    "سماحق",
    "سمادير",
    "سمار",
    "سماسر",
    "سماسير",
    "سماط",
    "سماعي",
    "سماعيات",
    "سماق",
    "سماقي",
    "سماك",
    "سماكة",
    "سمام",
    "سمامن",
    "سمامير",
    "سماناة",
    "سمانة",
    "سمانجوني",
    "سمانى",
    "سمانيات",
    "سمباتوي",
    "سمت",
    "سمته",
    "سمج",
    "سمجاء",
    "سمحاء",
    "سمحاق",
    "سمد",
    "سمدور",
    "سمر",
    "سمراء",
    "سمراوات",
    "سمرقند",
    "سمسر",
    "سمسرة",
    "سمسيرة",
    "سمط",
    "سمعاء",
    "سمعته",
    "سمعك",
    "سمعى",
    "سمعھم",
    "سمق",
    "سمكرة",
    "سمكري",
    "سمكرية",
    "سمكي",
    "سمل",
    "سمنتو",
    "سمنجوني",
    "سمندل",
    "سمو",
    "سموات",
    "سموت",
    "سمود",
    "سمور",
    "سموط",
    "سموق",
    "سمول",
    "سمولة",
    "سمون",
    "سمى",
    "سميج",
    "سميح",
    "سميد",
    "سميذ",
    "سمير",
    "سميرة",
    "سمھوري",
    "سنابك",
    "سنابيق",
    "سنابيك",
    "سناج",
    "سناجق",
    "سناخة",
    "سنادين",
    "سنارة",
    "سناكرة",
    "سنان",
    "سنانير",
    "سنباذج",
    "سنبك",
    "سنبلي",
    "سنبوسك",
    "سنبوق",
    "سنبوك",
    "سنتمتر",
    "سنتين",
    "سنج",
    "سنجاب",
    "سنجابي",
    "سنجة",
    "سنجق",
    "سنح",
    "سنخ",
    "سند",
    "سندات",
    "سندال",
    "سندان",
    "سندروس",
    "سندسي",
    "سنديان",
    "سندياني",
    "سنديك",
    "سنط",
    "سنطة",
    "سنطور",
    "سنطير",
    "سنغافومرة",
    "سنغال",
    "سنف",
    "سنفرة",
    "سنكة",
    "سنكرى",
    "سنكسار",
    "سنكونا",
    "سنم",
    "سنمار",
    "سنمة",
    "سنمورة",
    "سنه",
    "سنو",
    "سنوح",
    "سنوخ",
    "سنود",
    "سنودس",
    "سنور",
    "سنون",
    "سنونو",
    "سنى",
    "سني",
    "سوأة",
    "سوائل",
    "سوائم",
    "سوابح",
    "سوابط",
    "سوابغ",
    "سوابق",
    "سواح",
    "سواحر",
    "سواحلة",
    "سواد",
    "سوار",
    "سوارب",
    "سوارق",
    "سوارى",
    "سواريخ",
    "سواس",
    "سواسية",
    "سواطع",
    "سواطير",
    "سواعد",
    "سواكن",
    "سوالب",
    "سوالف",
    "سوامر",
    "سوان",
    "سوانح",
    "سوايا",
    "سوتيان",
    "سوتيه",
    "سوح",
    "سوخ",
    "سودان",
    "سودن",
    "سودنة",
    "سودوي",
    "سوري",
    "سورية",
    "سوسته",
    "سوسن",
    "سوغ",
    "سوفسطائي",
    "سوفيات",
    "سوفياتي",
    "سوفييت",
    "سوفييتي",
    "سوقة",
    "سوقي",
    "سوقية",
    "سوك",
    "سوكر",
    "سولب",
    "سوم",
    "سويداء",
    "سويدائھا",
    "سويعة",
    "سويف",
    "سويق",
    "سويقة",
    "سوھج",
    "سياح",
    "سياحة",
    "سيادتكم",
    "سيار",
    "سياس",
    "سياط",
    "سياق",
    "سيالة",
    "سيامة",
    "سياية",
    "سيب",
    "سيباط",
    "سيبيريا",
    "سية",
    "سيجار",
    "سيجارة",
    "سيجان",
    "سيح",
    "سيحان",
    "سيخ",
    "سيخان",
    "سيد",
    "سيدارة",
    "سيدي",
    "سيراس",
    "سيرك",
    "سيرورة",
    "سيسان",
    "سيسبان",
    "سيسى",
    "سيسيات",
    "سيطرته",
    "سيقارة",
    "سيقان",
    "سيكارة",
    "سيكران",
    "سيكورتاه",
    "سيكولوجى",
    "سيلة",
    "سيم",
    "سيمة",
    "سيمى",
    "سيميا",
    "سيمياء",
    "سينماتوغراف",
    "سينماسكوب",
    "سينودس",
    "سيوف",
    "سيول",
    "سھا",
    "سھاد",
    "سھار",
    "سھام",
    "سھامية",
    "سھب",
    "سھد",
    "سھر",
    "سھران",
    "سھرة",
    "سھري",
    "سھف",
    "سھل",
    "سھلة",
    "سھم",
    "سھمه",
    "سھو",
    "سھوا",
    "سھوان",
    "سھوب",
    "سھوة",
    "سھول",
    "سھولة",
    "سھوم",
    "سھى",
    "سھيل",
    "شآ",
    "شآبيب",
    "شآمي",
    "شأفة",
    "شأفته",
    "شأم",
    "شأنا",
    "شأنك",
    "شأنه",
    "شأني",
    "شأنھا",
    "شأو",
    "شأوا",
    "شأوه",
    "شؤبوب",
    "شؤم",
    "شؤما",
    "شؤمى",
    "شؤونه",
    "شائع",
    "شائعة",
    "شائق",
    "شائكة",
    "شائه",
    "شابة",
    "شابه",
    "شابورة",
    "شاة",
    "شات",
    "شاحط",
    "شاحن",
    "شاخ",
    "شاخص",
    "شادر",
    "شادن",
    "شادوف",
    "شادون",
    "شادية",
    "شاذرون",
    "شار",
    "شاربان",
    "شارة",
    "شارح",
    "شاردة",
    "شارعا",
    "شاركه",
    "شاروبيم",
    "شارى",
    "شاسعة",
    "شاسي",
    "شاش",
    "شاشية",
    "شاط",
    "شاطره",
    "شاع",
    "شاعرية",
    "شاغل",
    "شاغلا",
    "شاغول",
    "شاف",
    "شافع",
    "شافعي",
    "شافن",
    "شاقة",
    "شاقل",
    "شاقول",
    "شاك",
    "شاكلتھم",
    "شاكوش",
    "شاكي",
    "شالت",
    "شاليه",
    "شام",
    "شاما",
    "شامت",
    "شامح",
    "شامخ",
    "شامس",
    "شامي",
    "شان",
    "شانتاج",
    "شاه",
    "شاور",
    "شاورمة",
    "شاوش",
    "شاويش",
    "شاي",
    "شاھاني",
    "شاھانية",
    "شاھد",
    "شاھدة",
    "شاھق",
    "شاھين",
    "شب",
    "شبا",
    "شبائب",
    "شباب",
    "شبابة",
    "شبابه",
    "شبابي",
    "شبابير",
    "شبابيك",
    "شبابھم",
    "شباة",
    "شباشب",
    "شباط",
    "شباع",
    "شباعي",
    "شبان",
    "شباه",
    "شباين",
    "شبب",
    "شببة",
    "شبة",
    "شبت",
    "شبث",
    "شبثان",
    "شبر",
    "شبرا",
    "شبرق",
    "شبرقة",
    "شبشب",
    "شبط",
    "شبعة",
    "شبعى",
    "شبل",
    "شبن",
    "شبوات",
    "شبوب",
    "شبوح",
    "شبور",
    "شبورة",
    "شبوط",
    "شبوكات",
    "شبيب",
    "شبيبة",
    "شبين",
    "شبينة",
    "شبھات",
    "شبھان",
    "شبھة",
    "شت",
    "شتا",
    "شتاء",
    "شتائل",
    "شتائم",
    "شتات",
    "شتام",
    "شتت",
    "شتر",
    "شترات",
    "شتل",
    "شتلة",
    "شتما",
    "شتو",
    "شتول",
    "شتوي",
    "شتيت",
    "شتيم",
    "شج",
    "شجا",
    "شجائع",
    "شجاب",
    "شجاج",
    "شجب",
    "شجة",
    "شجراء",
    "شجران",
    "شجعاء",
    "شجعان",
    "شجعة",
    "شجن",
    "شجو",
    "شجون",
    "شجوى",
    "شجى",
    "شجية",
    "شجير",
    "شجيع",
    "شحائح",
    "شحات",
    "شحاح",
    "شحاذ",
    "شحاذة",
    "شحار",
    "شحارير",
    "شحاطة",
    "شحامة",
    "شحت",
    "شحذ",
    "شحر",
    "شحرور",
    "شحط",
    "شحطة",
    "شحمي",
    "شحناء",
    "شحنة",
    "شحوب",
    "شحوبة",
    "شحور",
    "شحومات",
    "شحيطة",
    "شحيم",
    "شخ",
    "شخاتير",
    "شخاخ",
    "شخاشخ",
    "شخب",
    "شخبط",
    "شختور",
    "شختورة",
    "شخر",
    "شخشخ",
    "شخشخية",
    "شخشيخة",
    "شخصاتي",
    "شخصيته",
    "شخط",
    "شخلل",
    "شخليلة",
    "شخوص",
    "شدا",
    "شدائد",
    "شدايقة",
    "شدح",
    "شدعضده",
    "شدق",
    "شدقاء",
    "شدقه",
    "شدقيه",
    "شدن",
    "شده",
    "شدو",
    "شدياق",
    "شذ",
    "شذا",
    "شذاذ",
    "شذر",
    "شذرة",
    "شذو",
    "شذوذ",
    "شذور",
    "شذى",
    "شرائك",
    "شرابة",
    "شراة",
    "شراح",
    "شراد",
    "شراذم",
    "شراذيم",
    "شرار",
    "شرارة",
    "شراري",
    "شراريب",
    "شراس",
    "شراسة",
    "شراسيف",
    "شراسيفي",
    "شراشف",
    "شراشير",
    "شراعية",
    "شراق",
    "شراقوة",
    "شراقي",
    "شراك",
    "شراكسة",
    "شراميط",
    "شرانق",
    "شراني",
    "شراويل",
    "شرايين",
    "شراھة",
    "شربة",
    "شربت",
    "شربك",
    "شربين",
    "شرة",
    "شرحة",
    "شرحي",
    "شرد",
    "شردق",
    "شرذم",
    "شرذمة",
    "شرر",
    "شررا",
    "شررى",
    "شرسوف",
    "شرش",
    "شرشور",
    "شرشير",
    "شرطا",
    "شرطن",
    "شرطونية",
    "شرقاوي",
    "شرقراق",
    "شرقرق",
    "شركا",
    "شركسي",
    "شرم",
    "شرماء",
    "شرمط",
    "شرموطة",
    "شرو",
    "شروال",
    "شروب",
    "شروة",
    "شروح",
    "شرور",
    "شروش",
    "شروع",
    "شروم",
    "شروى",
    "شرى",
    "شري",
    "شريب",
    "شريد",
    "شريس",
    "شريفة",
    "شريكة",
    "شزرا",
    "شزراء",
    "شست",
    "ششخان",
    "ششم",
    "ششمة",
    "ششنجي",
    "ششنى",
    "شص",
    "شصر",
    "شصوص",
    "شط",
    "شطآن",
    "شطار",
    "شطارة",
    "شطب",
    "شطة",
    "شطح",
    "شطحات",
    "شطحة",
    "شطرة",
    "شطط",
    "شطف",
    "شطفة",
    "شطن",
    "شطوب",
    "شطور",
    "شطورة",
    "شطوط",
    "شطيرة",
    "شطيطة",
    "شظاف",
    "شظايا",
    "شظف",
    "شظي",
    "شظية",
    "شعاب",
    "شعارى",
    "شعاعا",
    "شعاف",
    "شعانين",
    "شعبان",
    "شعبذ",
    "شعبذة",
    "شعثه",
    "شعراني",
    "شعره",
    "شعرور",
    "شعرى",
    "شعشع",
    "شعط",
    "شعفة",
    "شعل",
    "شعلة",
    "شعنينة",
    "شعواء",
    "شعوب",
    "شعوبي",
    "شعوبية",
    "شعوذ",
    "شعوذة",
    "شعوره",
    "شعيرات",
    "شعيرية",
    "شغاف",
    "شغر",
    "شغف",
    "شغلة",
    "شغلك",
    "شغوب",
    "شغور",
    "شغوف",
    "شغيل",
    "شف",
    "شفائف",
    "شفائي",
    "شفار",
    "شفاطة",
    "شفاع",
    "شفافية",
    "شفاه",
    "شفاهية",
    "شفايف",
    "شفاھا",
    "شفاھي",
    "شفاھيا",
    "شفت",
    "شفتر",
    "شفتشي",
    "شفتلك",
    "شفتورة",
    "شفتيه",
    "شفري",
    "شفشف",
    "شفط",
    "شفع",
    "شفعة",
    "شفعي",
    "شفف",
    "شفق",
    "شفقة",
    "شفه",
    "شفو",
    "شفوات",
    "شفوت",
    "شفوف",
    "شفوق",
    "شفى",
    "شفي",
    "شفير",
    "شفيف",
    "شفيق",
    "شفھى",
    "شفھي",
    "شفھيا",
    "شقاء",
    "شقاذف",
    "شقاشق",
    "شقافة",
    "شقاوة",
    "شقت",
    "شقح",
    "شقذف",
    "شقر",
    "شقراء",
    "شقرق",
    "شقشق",
    "شقشقة",
    "شقف",
    "شقلب",
    "شقلبة",
    "شقو",
    "شقوة",
    "شقوق",
    "شقيق",
    "شقيقة",
    "شكاء",
    "شكائر",
    "شكائم",
    "شكاة",
    "شكات",
    "شكارة",
    "شكاسة",
    "شكاية",
    "شكة",
    "شكران",
    "شكره",
    "شكري",
    "شكك",
    "شكلة",
    "شكليات",
    "شكم",
    "شكه",
    "شكو",
    "شكوات",
    "شكوة",
    "شكوريا",
    "شكوش",
    "شكوك",
    "شكول",
    "شكولاته",
    "شكوى",
    "شكية",
    "شكيم",
    "شكيمة",
    "شلاء",
    "شلال",
    "شلبي",
    "شلت",
    "شلتة",
    "شلح",
    "شلشل",
    "شلفة",
    "شلق",
    "شلن",
    "شلو",
    "شليك",
    "شمأز",
    "شماء",
    "شمائل",
    "شمات",
    "شماتة",
    "شمار",
    "شماريخ",
    "شماس",
    "شماسي",
    "شماع",
    "شماعة",
    "شماعد",
    "شماعدين",
    "شماليل",
    "شمبانيا",
    "شمة",
    "شمت",
    "شمخ",
    "شمر",
    "شمرة",
    "شمروخ",
    "شمسية",
    "شمشم",
    "شمط",
    "شمطاء",
    "شمطان",
    "شمعة",
    "شمعدان",
    "شمعي",
    "شمل",
    "شملة",
    "شمله",
    "شملول",
    "شملھم",
    "شمم",
    "شممسة",
    "شمندر",
    "شمندورة",
    "شموخ",
    "شموس",
    "شموع",
    "شمول",
    "شميم",
    "شن",
    "شنأ",
    "شناتين",
    "شناخيب",
    "شنار",
    "شنارق",
    "شناشن",
    "شناعة",
    "شناغيب",
    "شناكل",
    "شنب",
    "شنتيان",
    "شنج",
    "شنخوبة",
    "شنر",
    "شنشنة",
    "شنط",
    "شنع",
    "شنعاء",
    "شنغوبة",
    "شنف",
    "شنق",
    "شنقا",
    "شنقب",
    "شنكل",
    "شنوف",
    "شنون",
    "شنير",
    "شنيطة",
    "شنيعا",
    "شنھق",
    "شوء",
    "شواء",
    "شوائب",
    "شوائع",
    "شوابق",
    "شوابك",
    "شواة",
    "شواحب",
    "شوادر",
    "شوادف",
    "شوادن",
    "شواذات",
    "شوارب",
    "شوارد",
    "شواش",
    "شواشي",
    "شواشية",
    "شواعر",
    "شواغر",
    "شواغل",
    "شوافع",
    "شواكل",
    "شواكيش",
    "شوال",
    "شوام",
    "شوامت",
    "شوامخ",
    "شواه",
    "شواويل",
    "شواية",
    "شواھد",
    "شواھق",
    "شواھين",
    "شوب",
    "شوبق",
    "شوح",
    "شوحة",
    "شور",
    "شورب",
    "شوربا",
    "شوربة",
    "شورت",
    "شورمة",
    "شوري",
    "شوساء",
    "شوشبرك",
    "شوشة",
    "شوط",
    "شوطا",
    "شوف",
    "شوفان",
    "شوفة",
    "شوق",
    "شوقا",
    "شوك",
    "شوكته",
    "شوكران",
    "شوكى",
    "شول",
    "شولقى",
    "شولقي",
    "شولم",
    "شومة",
    "شوية",
    "شويعر",
    "شوھاء",
    "شى",
    "شيأ",
    "شيئي",
    "شيئية",
    "شياب",
    "شيات",
    "شياح",
    "شياخ",
    "شياخة",
    "شياع",
    "شيال",
    "شيالة",
    "شيامي",
    "شياه",
    "شيب",
    "شيباء",
    "شيبة",
    "شيت",
    "شيح",
    "شيخة",
    "شيخوخة",
    "شيد",
    "شيراز",
    "شيرة",
    "شيرج",
    "شيزوفرانيا",
    "شيشة",
    "شيط",
    "شيطن",
    "شيطنة",
    "شيعت",
    "شيق",
    "شيك",
    "شيكران",
    "شيكوريا",
    "شيكولاته",
    "شيلام",
    "شيلان",
    "شيلة",
    "شيلم",
    "شيلمان",
    "شيم",
    "شيمة",
    "شيمية",
    "شيوتات",
    "شيوخ",
    "شيوخة",
    "شيوع",
    "شيوعية",
    "شيون",
    "شھا",
    "شھاب",
    "شھاد",
    "شھادة",
    "شھاق",
    "شھام",
    "شھامة",
    "شھاوى",
    "شھب",
    "شھباء",
    "شھبان",
    "شھبة",
    "شھد",
    "شھداء",
    "شھدة",
    "شھر",
    "شھرا",
    "شھرة",
    "شھرته",
    "شھري",
    "شھريا",
    "شھرية",
    "شھق",
    "شھقة",
    "شھل",
    "شھلاء",
    "شھلة",
    "شھم",
    "شھو",
    "شھواته",
    "شھوان",
    "شھواني",
    "شھوة",
    "شھود",
    "شھور",
    "شھوى",
    "شھوي",
    "شھي",
    "شھية",
    "شھيد",
    "شھيدة",
    "شھير",
    "شھيق",
    "صأى",
    "صؤاب",
    "صئبان",
    "صئى",
    "صائت",
    "صائتة",
    "صائفة",
    "صائم",
    "صائن",
    "صاب",
    "صابئ",
    "صابة",
    "صابر",
    "صابورة",
    "صابون",
    "صابونة",
    "صات",
    "صاج",
    "صاخ",
    "صاخبة",
    "صادح",
    "صادف",
    "صاروخ",
    "صاروخية",
    "صارى",
    "صارية",
    "صاع",
    "صاعا",
    "صاعة",
    "صاعدا",
    "صاعين",
    "صاغ",
    "صاغر",
    "صاغقول",
    "صاغية",
    "صاف",
    "صافح",
    "صافى",
    "صاقور",
    "صالحات",
    "صالحه",
    "صالحھا",
    "صالون",
    "صامتا",
    "صامولة",
    "صان",
    "صانغ",
    "صبأ",
    "صباء",
    "صبابة",
    "صباحا",
    "صباحة",
    "صباحك",
    "صبارة",
    "صباع",
    "صباغ",
    "صبان",
    "صبايا",
    "صبحان",
    "صبحة",
    "صبحى",
    "صبرة",
    "صبره",
    "صبري",
    "صبع",
    "صبغته",
    "صبغه",
    "صبغيات",
    "صبن",
    "صبو",
    "صبوء",
    "صبوات",
    "صبوة",
    "صبوح",
    "صبى",
    "صبي",
    "صبيان",
    "صبيب",
    "صبية",
    "صبيح",
    "صبيحة",
    "صحائف",
    "صحابة",
    "صحابي",
    "صحاة",
    "صحاح",
    "صحاحير",
    "صحار",
    "صحارة",
    "صحاري",
    "صحاف",
    "صحافي",
    "صحافية",
    "صحافيي",
    "صحب",
    "صحبان",
    "صحبة",
    "صحت",
    "صحراء",
    "صحراوي",
    "صحروات",
    "صحفة",
    "صحل",
    "صحناة",
    "صحو",
    "صحية",
    "صحيفته",
    "صخاب",
    "صخب",
    "صخرات",
    "صخرى",
    "صخورة",
    "صدأ",
    "صدؤ",
    "صدا",
    "صداءة",
    "صداح",
    "صدار",
    "صدارة",
    "صداق",
    "صداقة",
    "صداه",
    "صدح",
    "صدحات",
    "صدد",
    "صدرة",
    "صدرية",
    "صدع",
    "صدغ",
    "صدغي",
    "صدف",
    "صدقاء",
    "صدقان",
    "صدقه",
    "صدمة",
    "صدود",
    "صدوع",
    "صدوف",
    "صدوق",
    "صديدي",
    "صديري",
    "صديرية",
    "صرائح",
    "صرائف",
    "صراح",
    "صراخ",
    "صراد",
    "صرار",
    "صراصير",
    "صراع",
    "صراف",
    "صرافة",
    "صرامى",
    "صربي",
    "صرحاء",
    "صرد",
    "صرر",
    "صرصار",
    "صرصور",
    "صرع",
    "صرفه",
    "صرفيات",
    "صرم",
    "صرمايا",
    "صرمة",
    "صروحة",
    "صروف",
    "صريد",
    "صرير",
    "صريرة",
    "صريع",
    "صريعا",
    "صريف",
    "صريفة",
    "صريمة",
    "صعاب",
    "صعاليك",
    "صعايدة",
    "صعتر",
    "صعداء",
    "صعر",
    "صعق",
    "صعقة",
    "صعلكة",
    "صعلوك",
    "صعيد",
    "صعيدي",
    "صغا",
    "صغائر",
    "صغارة",
    "صغراء",
    "صغرة",
    "صغرني",
    "صغرى",
    "صغو",
    "صغي",
    "صفاء",
    "صفائح",
    "صفاة",
    "صفاح",
    "صفاد",
    "صفارية",
    "صفاع",
    "صفاف",
    "صفافيح",
    "صفاق",
    "صفاقة",
    "صفاكس",
    "صفاه",
    "صفايا",
    "صفح",
    "صفحاتھا",
    "صفحته",
    "صفد",
    "صفراوية",
    "صفرة",
    "صفرت",
    "صفصف",
    "صفع",
    "صفعة",
    "صفقة",
    "صفن",
    "صفو",
    "صفوات",
    "صفوة",
    "صفوح",
    "صفور",
    "صفوفا",
    "صفوه",
    "صفى",
    "صفية",
    "صفيح",
    "صفين",
    "صقائل",
    "صقاع",
    "صقال",
    "صقالبة",
    "صقالة",
    "صقب",
    "صقع",
    "صقعة",
    "صقلب",
    "صقيع",
    "صقيل",
    "صكاك",
    "صكت",
    "صلاء",
    "صلابة",
    "صلاح",
    "صلادة",
    "صلال",
    "صلبان",
    "صلبه",
    "صلبوت",
    "صلة",
    "صلت",
    "صلج",
    "صلحي",
    "صلحية",
    "صلص",
    "صلصل",
    "صلصلة",
    "صلع",
    "صلعاء",
    "صلعان",
    "صلعة",
    "صلعم",
    "صلفاء",
    "صلو",
    "صلوة",
    "صلوتة",
    "صلوح",
    "صلودة",
    "صلون",
    "صليبي",
    "صليل",
    "صماء",
    "صماخ",
    "صمادة",
    "صماليخ",
    "صمامة",
    "صمان",
    "صمة",
    "صمد",
    "صمدانى",
    "صمصم",
    "صمع",
    "صمغ",
    "صمل",
    "صملاخ",
    "صموت",
    "صمود",
    "صموغ",
    "صمولة",
    "صمى",
    "صميم",
    "صميمي",
    "صنائع",
    "صنائعي",
    "صنابير",
    "صنادل",
    "صناديد",
    "صنارة",
    "صنان",
    "صنانير",
    "صنبر",
    "صنبور",
    "صنة",
    "صنتيم",
    "صنج",
    "صنجات",
    "صنجة",
    "صنجقية",
    "صندل",
    "صنديد",
    "صنعه",
    "صنعي",
    "صنفا",
    "صنفر",
    "صنفرة",
    "صنم",
    "صنو",
    "صنوبر",
    "صنوج",
    "صنوف",
    "صنيع",
    "صنيعا",
    "صنيعة",
    "صواب",
    "صوابه",
    "صوات",
    "صواحب",
    "صواحبات",
    "صوار",
    "صواعد",
    "صواعق",
    "صواغ",
    "صوالجة",
    "صوالح",
    "صوامل",
    "صواميل",
    "صوان",
    "صوانية",
    "صواوين",
    "صوب",
    "صوبة",
    "صوة",
    "صوته",
    "صوح",
    "صوخ",
    "صودا",
    "صودم",
    "صورته",
    "صوص",
    "صوصي",
    "صوع",
    "صوغ",
    "صوفان",
    "صوفانة",
    "صول",
    "صولة",
    "صولجان",
    "صومالي",
    "صون",
    "صوى",
    "صويحب",
    "صويحبة",
    "صيادلة",
    "صيارف",
    "صيارفة",
    "صياص",
    "صياغ",
    "صيال",
    "صيامي",
    "صيب",
    "صيبان",
    "صيبوبة",
    "صيت",
    "صيته",
    "صيح",
    "صيحات",
    "صيداء",
    "صيدلاني",
    "صيدليات",
    "صيدلية",
    "صير",
    "صيرف",
    "صيرفي",
    "صيرورة",
    "صيصان",
    "صيصية",
    "صيعان",
    "صيقل",
    "صيم",
    "صيوان",
    "صھاء",
    "صھاريج",
    "صھب",
    "صھباء",
    "صھد",
    "صھر",
    "صھريج",
    "صھل",
    "صھوة",
    "صھود",
    "صھيد",
    "صھير",
    "صھيل",
    "صھيون",
    "صھيوني",
    "صھيونية",
    "ضأن",
    "ضؤل",
    "ضؤلاء",
    "ضؤولة",
    "ضئال",
    "ضاء",
    "ضائع",
    "ضائقة",
    "ضائن",
    "ضابطة",
    "ضابطية",
    "ضاح",
    "ضاد",
    "ضارع",
    "ضاع",
    "ضاغطة",
    "ضاغوط",
    "ضاف",
    "ضاق",
    "ضالة",
    "ضام",
    "ضامة",
    "ضامن",
    "ضاو",
    "ضب",
    "ضباب",
    "ضبارة",
    "ضبان",
    "ضبة",
    "ضبح",
    "ضبر",
    "ضبطا",
    "ضبطية",
    "ضبن",
    "ضبوط",
    "ضج",
    "ضجاج",
    "ضجة",
    "ضجر",
    "ضجع",
    "ضجعة",
    "ضجعي",
    "ضجوج",
    "ضجوع",
    "ضجيج",
    "ضجيع",
    "ضحا",
    "ضحاك",
    "ضحضاح",
    "ضحضح",
    "ضحك",
    "ضحكة",
    "ضحو",
    "ضحوك",
    "ضحيته",
    "ضخامة",
    "ضدة",
    "ضده",
    "ضدية",
    "ضرائح",
    "ضرائر",
    "ضراب",
    "ضراط",
    "ضراع",
    "ضراعة",
    "ضراغم",
    "ضراغمة",
    "ضرام",
    "ضراوة",
    "ضربان",
    "ضربخان",
    "ضربخانة",
    "ضربه",
    "ضرة",
    "ضرس",
    "ضرط",
    "ضرعة",
    "ضرغام",
    "ضرغم",
    "ضرم",
    "ضرمة",
    "ضرو",
    "ضروب",
    "ضرورة",
    "ضروريات",
    "ضروس",
    "ضريب",
    "ضريبي",
    "ضريح",
    "ضرير",
    "ضريط",
    "ضعائف",
    "ضعاف",
    "ضعة",
    "ضعضع",
    "ضعضعة",
    "ضعفة",
    "ضغائن",
    "ضغث",
    "ضغطة",
    "ضغن",
    "ضغوطية",
    "ضغينة",
    "ضفا",
    "ضفادع",
    "ضفار",
    "ضفر",
    "ضفف",
    "ضفو",
    "ضفوة",
    "ضفور",
    "ضفيرة",
    "ضلاعة",
    "ضلع",
    "ضلعه",
    "ضلمه",
    "ضلوع",
    "ضلوعي",
    "ضمائم",
    "ضماد",
    "ضمادة",
    "ضمار",
    "ضمانة",
    "ضمخ",
    "ضمناء",
    "ضمنھم",
    "ضمه",
    "ضمور",
    "ضميره",
    "ضميمة",
    "ضمين",
    "ضن",
    "ضنا",
    "ضناكة",
    "ضنك",
    "ضنو",
    "ضنى",
    "ضنين",
    "ضوائق",
    "ضوابط",
    "ضواح",
    "ضواحك",
    "ضوار",
    "ضوارب",
    "ضوال",
    "ضور",
    "ضوضى",
    "ضوع",
    "ضوى",
    "ضياع",
    "ضيافتي",
    "ضيع",
    "ضيعانة",
    "ضيعة",
    "ضيعت",
    "ضيفا",
    "ضيفان",
    "ضيم",
    "ضيوف",
    "ضيوفا",
    "ضيوم",
    "ضھد",
    "ضھر",
    "ضھور",
    "ضھى",
    "طأمن",
    "طاء",
    "طاؤوس",
    "طائب",
    "طائع",
    "طائعا",
    "طائفية",
    "طائل",
    "طائلة",
    "طاباق",
    "طابة",
    "طابت",
    "طابونة",
    "طابية",
    "طاجن",
    "طاح",
    "طاحنة",
    "طاحون",
    "طارئة",
    "طارة",
    "طارحه",
    "طاردة",
    "طارف",
    "طارقة",
    "طارمة",
    "طازة",
    "طازج",
    "طاسة",
    "طاش",
    "طاع",
    "طاعنة",
    "طاعون",
    "طاغ",
    "طاغوت",
    "طافية",
    "طاقته",
    "طالبي",
    "طالح",
    "طالعة",
    "طالعه",
    "طالعي",
    "طالق",
    "طامة",
    "طامح",
    "طامس",
    "طاه",
    "طاو",
    "طاووس",
    "طاھر",
    "طبابة",
    "طباخ",
    "طباخة",
    "طباشير",
    "طباق",
    "طبالي",
    "طبة",
    "طبخة",
    "طبر",
    "طبردار",
    "طبرية",
    "طبشورة",
    "طبطابة",
    "طبطب",
    "طبعة",
    "طبعه",
    "طبقت",
    "طبلي",
    "طبنجة",
    "طبول",
    "طبونة",
    "طبيبة",
    "طبيخ",
    "طبيعتھا",
    "طبيق",
    "طحال",
    "طحطح",
    "طحل",
    "طحلب",
    "طحن",
    "طحين",
    "طحينة",
    "طحينية",
    "طرآني",
    "طرأ",
    "طرأت",
    "طرا",
    "طرائد",
    "طرائف",
    "طراب",
    "طرابيد",
    "طرابيش",
    "طرابيشي",
    "طراحة",
    "طراد",
    "طرادة",
    "طرار",
    "طرارة",
    "طراريح",
    "طراطور",
    "طراطير",
    "طرافة",
    "طراق",
    "طرب",
    "طربوش",
    "طربوشة",
    "طربيزة",
    "طرة",
    "طرحة",
    "طرحت",
    "طرحه",
    "طرحى",
    "طرخون",
    "طردة",
    "طرده",
    "طرر",
    "طرزي",
    "طرس",
    "طرش",
    "طرشاء",
    "طرشة",
    "طرشي",
    "طرطر",
    "طرطش",
    "طرطور",
    "طرطوفة",
    "طرطير",
    "طرفاء",
    "طرفة",
    "طرفه",
    "طرقات",
    "طرقة",
    "طرقتين",
    "طرقع",
    "طرمبة",
    "طرو",
    "طروء",
    "طروب",
    "طرور",
    "طروز",
    "طروس",
    "طروش",
    "طرى",
    "طريء",
    "طريحة",
    "طريد",
    "طريدة",
    "طريفة",
    "طزازن",
    "طزالق",
    "طزلق",
    "طزينة",
    "طست",
    "طسوت",
    "طشت",
    "طشقند",
    "طشوت",
    "طصلق",
    "طصلقة",
    "طعمة",
    "طعمية",
    "طعنة",
    "طعنت",
    "طعوم",
    "طعون",
    "طغا",
    "طغاة",
    "طغار",
    "طغام",
    "طغراء",
    "طغرى",
    "طغمة",
    "طغو",
    "طغوان",
    "طغي",
    "طغيان",
    "طفئ",
    "طفا",
    "طفاءة",
    "طفاحة",
    "طفال",
    "طفالة",
    "طفاية",
    "طفح",
    "طفحان",
    "طفحة",
    "طفحى",
    "طفحي",
    "طفر",
    "طفران",
    "طفرة",
    "طفرت",
    "طفش",
    "طفلة",
    "طفلي",
    "طفو",
    "طفوء",
    "طفوح",
    "طفولة",
    "طفيليات",
    "طقاطيق",
    "طقسي",
    "طقطق",
    "طقطوقة",
    "طقوس",
    "طقوم",
    "طقومة",
    "طلائق",
    "طلاح",
    "طلاحى",
    "طلاسم",
    "طلاع",
    "طلاق",
    "طلاوة",
    "طلبه",
    "طلبية",
    "طلح",
    "طلحية",
    "طلس",
    "طلسانة",
    "طلسم",
    "طلعة",
    "طلقاء",
    "طلقت",
    "طلل",
    "طلمبة",
    "طلمس",
    "طلو",
    "طلوح",
    "طلوقة",
    "طلول",
    "طليحة",
    "طليطلة",
    "طليعة",
    "طليقة",
    "طم",
    "طمأنينة",
    "طما",
    "طماح",
    "طماطة",
    "طماطم",
    "طماعة",
    "طماعية",
    "طمان",
    "طمث",
    "طمر",
    "طمس",
    "طمطماني",
    "طمع",
    "طمن",
    "طمو",
    "طموس",
    "طموم",
    "طمى",
    "طمي",
    "طنابير",
    "طناجر",
    "طنافس",
    "طنب",
    "طنبور",
    "طنبوري",
    "طنبوشة",
    "طنجرة",
    "طنجي",
    "طنطا",
    "طنطن",
    "طنطنة",
    "طنف",
    "طنفسة",
    "طنوف",
    "طنين",
    "طوائح",
    "طوائف",
    "طواب",
    "طوابع",
    "طوابير",
    "طوابيق",
    "طواجن",
    "طواحن",
    "طوار",
    "طوارق",
    "طواري",
    "طواشي",
    "طواشية",
    "طواعية",
    "طواعين",
    "طواف",
    "طوافة",
    "طواقي",
    "طوال",
    "طوالة",
    "طوالع",
    "طوامر",
    "طوامير",
    "طواويس",
    "طوايا",
    "طواية",
    "طوب",
    "طوبا",
    "طوبجي",
    "طوبجية",
    "طوبه",
    "طوح",
    "طود",
    "طورا",
    "طوراني",
    "طوربيد",
    "طوره",
    "طوري",
    "طورية",
    "طوزلق",
    "طوس",
    "طوش",
    "طوع",
    "طوعت",
    "طوعيا",
    "طوف",
    "طوفان",
    "طوقا",
    "طوقه",
    "طوقي",
    "طولاني",
    "طولك",
    "طولكرم",
    "طوله",
    "طولى",
    "طولھا",
    "طومار",
    "طونولاطة",
    "طوية",
    "طي",
    "طياب",
    "طياته",
    "طيارة",
    "طياشان",
    "طياشة",
    "طيالسة",
    "طيان",
    "طية",
    "طيح",
    "طيرة",
    "طيش",
    "طيشان",
    "طيف",
    "طيقان",
    "طيلة",
    "طيلسان",
    "طينة",
    "طيه",
    "طيوب",
    "طيوف",
    "طھا",
    "طھاة",
    "طھارة",
    "طھاية",
    "طھر",
    "طھران",
    "طھق",
    "طھو",
    "طھور",
    "طھى",
    "طھي",
    "ظئر",
    "ظاء",
    "ظاعن",
    "ظافر",
    "ظامئ",
    "ظاھر",
    "ظاھرا",
    "ظاھرة",
    "ظاھري",
    "ظباء",
    "ظبية",
    "ظر",
    "ظرائف",
    "ظرابي",
    "ظرابين",
    "ظرافة",
    "ظربان",
    "ظرة",
    "ظرفاء",
    "ظرفية",
    "ظعن",
    "ظعينة",
    "ظفران",
    "ظلام",
    "ظلامة",
    "ظلع",
    "ظلماء",
    "ظلمان",
    "ظله",
    "ظلوف",
    "ظلول",
    "ظليم",
    "ظمء",
    "ظمآن",
    "ظمأى",
    "ظمئ",
    "ظماء",
    "ظماءة",
    "ظنابيب",
    "ظنبوب",
    "ظنة",
    "ظنك",
    "ظنن",
    "ظنون",
    "ظني",
    "ظنين",
    "ظواھر",
    "ظھار",
    "ظھارة",
    "ظھر",
    "ظھرا",
    "ظھرانيھم",
    "ظھره",
    "ظھري",
    "ظھريا",
    "ظھور",
    "ظھورات",
    "ظھير",
    "ظھيرة",
    "عائد",
    "عائدات",
    "عائدة",
    "عائدية",
    "عائم",
    "عابئ",
    "عابق",
    "عات",
    "عاتق",
    "عاتقه",
    "عاتك",
    "عاثور",
    "عاج",
    "عاجلا",
    "عاجله",
    "عاجي",
    "عادت",
    "عادته",
    "عادتھم",
    "عادم",
    "عادوا",
    "عاديات",
    "عاذ",
    "عاذل",
    "عاذلة",
    "عارضي",
    "عارفة",
    "عارم",
    "عارية",
    "عاز",
    "عازة",
    "عازم",
    "عاس",
    "عاشق",
    "عاشوراء",
    "عاص",
    "عاطر",
    "عاطش",
    "عاطف",
    "عاطفة",
    "عاطفية",
    "عاطوس",
    "عاظل",
    "عاف",
    "عافن",
    "عافية",
    "عاق",
    "عاقد",
    "عاقلة",
    "عاقول",
    "عاكف",
    "عالجه",
    "عالماني",
    "عالمة",
    "عالمون",
    "عالمى",
    "عالى",
    "عاليه",
    "عامر",
    "عامله",
    "عامود",
    "عانس",
    "عاھر",
    "عاھرة",
    "عاھل",
    "عاھن",
    "عبء",
    "عبأ",
    "عباء",
    "عباب",
    "عباسي",
    "عباقرة",
    "عبال",
    "عباية",
    "عبدان",
    "عبدة",
    "عبدلاوي",
    "عبرود",
    "عبطاء",
    "عبق",
    "عبقة",
    "عبقر",
    "عبقري",
    "عبقرية",
    "عبك",
    "عبل",
    "عبو",
    "عبوة",
    "عبودة",
    "عبوسة",
    "عبيد",
    "عبير",
    "عبيط",
    "عتا",
    "عتاب",
    "عتاة",
    "عتاد",
    "عتاقة",
    "عتال",
    "عتالة",
    "عتامة",
    "عتاھة",
    "عتب",
    "عتد",
    "عتق",
    "عتك",
    "عتمة",
    "عتي",
    "عث",
    "عثا",
    "عثار",
    "عثانين",
    "عثث",
    "عثرة",
    "عثرتك",
    "عثرته",
    "عثنون",
    "عثو",
    "عثور",
    "عثى",
    "عثي",
    "عثير",
    "عج",
    "عجائن",
    "عجاج",
    "عجاجة",
    "عجال",
    "عجالى",
    "عجان",
    "عجب",
    "عجة",
    "عجر",
    "عجرة",
    "عجرف",
    "عجره",
    "عجز",
    "عجزا",
    "عجزة",
    "عجعج",
    "عجعجة",
    "عجفاء",
    "عجلان",
    "عجلى",
    "عجم",
    "عجماء",
    "عجمة",
    "عجموات",
    "عجمي",
    "عجن",
    "عجوة",
    "عجور",
    "عجول",
    "عجيج",
    "عجيزة",
    "عجيل",
    "عجين",
    "عجينة",
    "عدائل",
    "عداة",
    "عدادھم",
    "عدالة",
    "عدت",
    "عدته",
    "عدلا",
    "عدلى",
    "عدلية",
    "عدمية",
    "عدنان",
    "عدواء",
    "عدوة",
    "عدول",
    "عدوى",
    "عدى",
    "عديدة",
    "عديل",
    "عذا",
    "عذار",
    "عذاره",
    "عذارى",
    "عذال",
    "عذر",
    "عذرة",
    "عذق",
    "عذل",
    "عذو",
    "عذوبة",
    "عذول",
    "عذى",
    "عرا",
    "عراء",
    "عرائس",
    "عرائش",
    "عرائض",
    "عرائن",
    "عراب",
    "عرابة",
    "عرابين",
    "عراة",
    "عراض",
    "عرافة",
    "عراق",
    "عراقة",
    "عراقيب",
    "عراقية",
    "عراقيل",
    "عراك",
    "عرام",
    "عرانيس",
    "عرانين",
    "عرايا",
    "عربان",
    "عربجي",
    "عربخانة",
    "عربس",
    "عربن",
    "عربون",
    "عربية",
    "عربيد",
    "عرة",
    "عرج",
    "عرجاء",
    "عرجان",
    "عرر",
    "عرزال",
    "عرس",
    "عرسات",
    "عرسة",
    "عرشا",
    "عرص",
    "عرضاني",
    "عرضحال",
    "عرضحالات",
    "عرضك",
    "عرضه",
    "عرضيات",
    "عرضية",
    "عرفاء",
    "عرفان",
    "عرفانا",
    "عرفت",
    "عرفه",
    "عرفى",
    "عرفي",
    "عرفية",
    "عرقا",
    "عرقب",
    "عرقة",
    "عرقلة",
    "عرقوب",
    "عرقوبي",
    "عرقوبية",
    "عرقية",
    "عرك",
    "عركة",
    "عرم",
    "عرمة",
    "عرمرم",
    "عرن",
    "عرناس",
    "عرنين",
    "عرو",
    "عروب",
    "عروبة",
    "عروة",
    "عروج",
    "عروسة",
    "عروش",
    "عروشه",
    "عروض",
    "عرى",
    "عري",
    "عريان",
    "عريانية",
    "عرية",
    "عريس",
    "عريف",
    "عريق",
    "عريقة",
    "عريكة",
    "عرينة",
    "عزائم",
    "عزائمه",
    "عزاب",
    "عزازة",
    "عزال",
    "عزب",
    "عزبة",
    "عزر",
    "عزرائيل",
    "عزق",
    "عزلاء",
    "عزلة",
    "عزمة",
    "عزمه",
    "عزو",
    "عزوب",
    "عزوبة",
    "عزوة",
    "عزوف",
    "عزوم",
    "عزومة",
    "عزى",
    "عزي",
    "عزيزي",
    "عزيمته",
    "عزھا",
    "عس",
    "عساف",
    "عساكر",
    "عسال",
    "عسالة",
    "عسالج",
    "عساليج",
    "عساه",
    "عسب",
    "عسجد",
    "عسجدي",
    "عسس",
    "عسف",
    "عسقلان",
    "عسكرى",
    "عسلج",
    "عسلوج",
    "عسلي",
    "عسوف",
    "عسول",
    "عسي",
    "عسيب",
    "عسيون",
    "عشا",
    "عشائر",
    "عشابة",
    "عشار",
    "عشاش",
    "عشاق",
    "عشاوة",
    "عشايا",
    "عشبية",
    "عشراء",
    "عشرات",
    "عشش",
    "عششة",
    "عشو",
    "عشواء",
    "عشوة",
    "عشور",
    "عشوراء",
    "عشى",
    "عشير",
    "عشيرة",
    "عشيقة",
    "عص",
    "عصائب",
    "عصاب",
    "عصاة",
    "عصار",
    "عصاري",
    "عصاعص",
    "عصافة",
    "عصام",
    "عصامي",
    "عصامية",
    "عصاية",
    "عصاھم",
    "عصره",
    "عصرى",
    "عصص",
    "عصعص",
    "عصف",
    "عصفر",
    "عصفورة",
    "عصفورية",
    "عصفورين",
    "عصل",
    "عصم",
    "عصماء",
    "عصمة",
    "عصمتھا",
    "عصو",
    "عصور",
    "عصوف",
    "عصى",
    "عصي",
    "عصيان",
    "عصية",
    "عصيدة",
    "عصيرة",
    "عضاض",
    "عضاه",
    "عضب",
    "عضد",
    "عضده",
    "عضل",
    "عضه",
    "عضوض",
    "عضيض",
    "عطا",
    "عطار",
    "عطارة",
    "عطارد",
    "عطاس",
    "عطاش",
    "عطاف",
    "عطالة",
    "عطان",
    "عطايا",
    "عطب",
    "عطرة",
    "عطرية",
    "عطس",
    "عطسة",
    "عطشجي",
    "عطشجيات",
    "عطشى",
    "عطعط",
    "عطف",
    "عطفة",
    "عطلة",
    "عطن",
    "عطو",
    "عطورات",
    "عطوف",
    "عطوفة",
    "عطية",
    "عطين",
    "عظائم",
    "عظامة",
    "عظامي",
    "عظة",
    "عظل",
    "عظماء",
    "عظموت",
    "عظمى",
    "عظيمة",
    "عف",
    "عفاء",
    "عفارة",
    "عفارم",
    "عفاشة",
    "عفاف",
    "عفر",
    "عفراء",
    "عفرت",
    "عفرتة",
    "عفريتة",
    "عفش",
    "عفص",
    "عفو",
    "عفوصة",
    "عق",
    "عقائد",
    "عقائص",
    "عقائق",
    "عقائل",
    "عقابي",
    "عقاد",
    "عقادة",
    "عقارب",
    "عقارة",
    "عقاري",
    "عقارية",
    "عقاص",
    "عقاعق",
    "عقال",
    "عقاله",
    "عقالھا",
    "عقام",
    "عقبان",
    "عقبة",
    "عقداء",
    "عقده",
    "عقدھم",
    "عقر",
    "عقص",
    "عقعق",
    "عقف",
    "عقفاء",
    "عقفة",
    "عقلاء",
    "عقلة",
    "عقله",
    "عقمة",
    "عقوبات",
    "عقوبة",
    "عقور",
    "عقوق",
    "عقول",
    "عقيب",
    "عقيد",
    "عقيدتي",
    "عقيرة",
    "عقيصة",
    "عقيق",
    "عقيقي",
    "عقيلة",
    "عقيلته",
    "عك",
    "عكا",
    "عكاء",
    "عكارة",
    "عكاز",
    "عكازة",
    "عكاشة",
    "عكاكيز",
    "عكة",
    "عكز",
    "عكف",
    "عكم",
    "عكوف",
    "عكيس",
    "عكيك",
    "علائف",
    "علائق",
    "علائم",
    "علاب",
    "علاة",
    "علاته",
    "علاف",
    "علاق",
    "علاقم",
    "علالة",
    "علالي",
    "علاية",
    "علبه",
    "علة",
    "علت",
    "علته",
    "علج",
    "علف",
    "علقم",
    "علك",
    "علل",
    "علله",
    "علمك",
    "علمى",
    "علن",
    "علو",
    "علوان",
    "علوج",
    "علوفة",
    "علوق",
    "علوما",
    "علوية",
    "عليقة",
    "عليل",
    "عليھا",
    "عليھم",
    "عماء",
    "عمائر",
    "عمائم",
    "عماارة",
    "عمارة",
    "عمارية",
    "عماص",
    "عماقة",
    "عمالة",
    "عمالقة",
    "عمالي",
    "عمالية",
    "عمامة",
    "عماني",
    "عماية",
    "عمة",
    "عمت",
    "عمداء",
    "عمدة",
    "عمدي",
    "عمراني",
    "عمرة",
    "عمرى",
    "عمري",
    "عملاء",
    "عمن",
    "عمه",
    "عمولة",
    "عمومة",
    "عمومية",
    "عمياء",
    "عميان",
    "عمية",
    "عميدة",
    "عميل",
    "عميلة",
    "عميم",
    "عناء",
    "عناب",
    "عنابر",
    "عناترة",
    "عناد",
    "عنادل",
    "عناز",
    "عناصل",
    "عناق",
    "عناقيد",
    "عناكب",
    "عناية",
    "عنبر",
    "عنبرة",
    "عنة",
    "عنت",
    "عنتر",
    "عنترى",
    "عنترية",
    "عنجھية",
    "عندئذ",
    "عندلة",
    "عندليب",
    "عندم",
    "عندياته",
    "عندھا",
    "عنز",
    "عنزة",
    "عنصل",
    "عنعنات",
    "عنف",
    "عنفوان",
    "عنقاء",
    "عنقاش",
    "عنكبوت",
    "عنن",
    "عنو",
    "عنوانا",
    "عنوة",
    "عنوز",
    "عنوق",
    "عنون",
    "عنين",
    "عواتق",
    "عواثير",
    "عواجز",
    "عواد",
    "عوادة",
    "عوادم",
    "عواذل",
    "عوار",
    "عواشق",
    "عواصم",
    "عواطف",
    "عواطل",
    "عواقب",
    "عوالم",
    "عوام",
    "عواميد",
    "عواھر",
    "عواھل",
    "عواھن",
    "عواھنه",
    "عوجاء",
    "عودا",
    "عوده",
    "عوذ",
    "عوذة",
    "عور",
    "عوراء",
    "عوز",
    "عوسج",
    "عون",
    "عويجة",
    "عياد",
    "عيادة",
    "عياذ",
    "عيالم",
    "عياله",
    "عيان",
    "عير",
    "عيسلان",
    "عيشه",
    "عيلم",
    "عينيه",
    "عھار",
    "عھارة",
    "عھد",
    "عھدا",
    "عھدة",
    "عھدته",
    "عھدنا",
    "عھده",
    "عھدھا",
    "عھدھم",
    "عھر",
    "عھن",
    "عھود",
    "عھيد",
    "غائر",
    "غائلة",
    "غائية",
    "غاباني",
    "غابر",
    "غاث",
    "غادة",
    "غارات",
    "غارب",
    "غاربه",
    "غارة",
    "غازوزة",
    "غازية",
    "غاسول",
    "غاشم",
    "غاشمة",
    "غاص",
    "غاصب",
    "غاض",
    "غاظ",
    "غاغة",
    "غالبية",
    "غالية",
    "غام",
    "غانة",
    "غانم",
    "غانما",
    "غانية",
    "غبا",
    "غباره",
    "غبارھم",
    "غباشة",
    "غباني",
    "غباوة",
    "غبب",
    "غبة",
    "غبر",
    "غبراء",
    "غبش",
    "غبشاء",
    "غبشة",
    "غبط",
    "غبطة",
    "غبن",
    "غبو",
    "غبوة",
    "غبور",
    "غبون",
    "غت",
    "غث",
    "غثاثة",
    "غثت",
    "غثوثة",
    "غثي",
    "غثيت",
    "غثيث",
    "غجر",
    "غد",
    "غداء",
    "غدائر",
    "غدار",
    "غدارة",
    "غداف",
    "غدان",
    "غدر",
    "غدران",
    "غدف",
    "غدفة",
    "غدق",
    "غدن",
    "غدنة",
    "غدو",
    "غدوات",
    "غدواته",
    "غدوة",
    "غدوه",
    "غدى",
    "غدير",
    "غديرة",
    "غذا",
    "غذاء",
    "غذائية",
    "غذو",
    "غرا",
    "غراء",
    "غرائب",
    "غرائر",
    "غرائز",
    "غرائس",
    "غرائي",
    "غرابلي",
    "غرابلية",
    "غرار",
    "غرارا",
    "غرارة",
    "غراريف",
    "غراس",
    "غراسة",
    "غراف",
    "غرام",
    "غراميات",
    "غرامية",
    "غرانيق",
    "غراية",
    "غربا",
    "غرباء",
    "غربال",
    "غربة",
    "غربه",
    "غرة",
    "غرد",
    "غرر",
    "غرز",
    "غرزة",
    "غرسة",
    "غرش",
    "غرض",
    "غرضه",
    "غرضي",
    "غرغر",
    "غرغرة",
    "غرقان",
    "غرقى",
    "غرل",
    "غرلان",
    "غرلة",
    "غرم",
    "غرماء",
    "غرناطة",
    "غرنوق",
    "غرنيق",
    "غرو",
    "غروب",
    "غرود",
    "غروش",
    "غرول",
    "غروى",
    "غريد",
    "غرير",
    "غريزة",
    "غريسة",
    "غريض",
    "غريق",
    "غريم",
    "غريمة",
    "غرين",
    "غز",
    "غزا",
    "غزاة",
    "غزار",
    "غزارى",
    "غزال",
    "غزالة",
    "غزاوة",
    "غزل",
    "غزلي",
    "غزو",
    "غزوات",
    "غزوان",
    "غزوة",
    "غزي",
    "غس",
    "غسال",
    "غسلة",
    "غشا",
    "غشم",
    "غشماء",
    "غشو",
    "غشوة",
    "غشوم",
    "غشومة",
    "غشي",
    "غشيان",
    "غشية",
    "غشيم",
    "غصب",
    "غصص",
    "غصنة",
    "غصون",
    "غض",
    "غضا",
    "غضاب",
    "غضابى",
    "غضارة",
    "غضاريف",
    "غضاضة",
    "غضباني",
    "غضبة",
    "غضبه",
    "غضبى",
    "غضة",
    "غضر",
    "غضروف",
    "غضن",
    "غضنفر",
    "غضو",
    "غضوضة",
    "غضون",
    "غضى",
    "غضير",
    "غضيض",
    "غطا",
    "غطارف",
    "غطارفة",
    "غطاريس",
    "غطاريف",
    "غطاس",
    "غطرة",
    "غطرس",
    "غطرسة",
    "غطريس",
    "غطريف",
    "غطش",
    "غطم",
    "غطو",
    "غطيط",
    "غطيطة",
    "غف",
    "غفا",
    "غفائر",
    "غفار",
    "غفارة",
    "غفرا",
    "غفراء",
    "غفران",
    "غفرة",
    "غفقة",
    "غفلان",
    "غفو",
    "غفوة",
    "غفول",
    "غفى",
    "غفية",
    "غفير",
    "غلاء",
    "غلائل",
    "غلاب",
    "غلاة",
    "غلاصم",
    "غلاظة",
    "غلاقة",
    "غلال",
    "غلالة",
    "غلامية",
    "غلاوين",
    "غلايين",
    "غلب",
    "غلباوي",
    "غلبة",
    "غلة",
    "غلته",
    "غلس",
    "غلصمة",
    "غلط",
    "غلطان",
    "غلطة",
    "غلظ",
    "غلغل",
    "غلفاء",
    "غلفة",
    "غلق",
    "غلم",
    "غلمة",
    "غله",
    "غلو",
    "غلواء",
    "غلوائه",
    "غليان",
    "غليظة",
    "غليل",
    "غليله",
    "غماء",
    "غمائم",
    "غمار",
    "غمارة",
    "غمازة",
    "غماض",
    "غماضا",
    "غماغم",
    "غمام",
    "غمامة",
    "غمر",
    "غمز",
    "غمص",
    "غمض",
    "غمضا",
    "غمضة",
    "غمط",
    "غمطه",
    "غمغمة",
    "غملاج",
    "غملج",
    "غمود",
    "غمور",
    "غمورة",
    "غموس",
    "غموضة",
    "غموم",
    "غمومه",
    "غمى",
    "غمي",
    "غميزة",
    "غنائم",
    "غنادرة",
    "غنام",
    "غنة",
    "غنج",
    "غندر",
    "غندقجي",
    "غندور",
    "غنغرينا",
    "غنية",
    "غنيمة",
    "غوائل",
    "غواة",
    "غوارب",
    "غواز",
    "غواص",
    "غوامض",
    "غوان",
    "غواية",
    "غوايش",
    "غوث",
    "غوريلا",
    "غوص",
    "غوط",
    "غوطى",
    "غوغاء",
    "غوي",
    "غويشة",
    "غويص",
    "غويط",
    "غى",
    "غي",
    "غيابة",
    "غيابه",
    "غيابي",
    "غيابيا",
    "غياث",
    "غيار",
    "غياري",
    "غياص",
    "غياصة",
    "غياض",
    "غياط",
    "غيام",
    "غياھب",
    "غيبا",
    "غيبة",
    "غيبوبة",
    "غية",
    "غيتو",
    "غيد",
    "غيداء",
    "غيران",
    "غيرة",
    "غيرترت",
    "غيرما",
    "غيرى",
    "غيرية",
    "غيرھا",
    "غيض",
    "غيضة",
    "غيط",
    "غيطان",
    "غيظا",
    "غيظه",
    "غيل",
    "غيلان",
    "غيلة",
    "غيلم",
    "غيم",
    "غين",
    "غينة",
    "غيني",
    "غيه",
    "غيوب",
    "غيوث",
    "غيوم",
    "غيون",
    "غيھب",
    "غھب",
    "فآنا",
    "فأس",
    "فأفأ",
    "فأل",
    "فأله",
    "فأولا",
    "فؤاده",
    "فؤادية",
    "فؤوس",
    "فؤول",
    "فئ",
    "فئام",
    "فاء",
    "فائت",
    "فائرة",
    "فائزة",
    "فابريقة",
    "فابريكة",
    "فات",
    "فاتته",
    "فاتك",
    "فاته",
    "فاجئ",
    "فاجرة",
    "فاجع",
    "فاجعة",
    "فاح",
    "فاخورة",
    "فاخوري",
    "فاد",
    "فادحة",
    "فادن",
    "فار",
    "فارة",
    "فارح",
    "فارزة",
    "فارسة",
    "فارسي",
    "فارط",
    "فارع",
    "فارق",
    "فاره",
    "فاروز",
    "فاروق",
    "فاروقية",
    "فازلين",
    "فاسياء",
    "فاشستي",
    "فاشستية",
    "فاصلة",
    "فاصمى",
    "فاصوليا",
    "فاضت",
    "فاضل",
    "فاطمي",
    "فاعلية",
    "فاغية",
    "فاقة",
    "فاقدو",
    "فاقعة",
    "فاكثر",
    "فاكه",
    "فاكون",
    "فاكھاني",
    "فال",
    "فالاول",
    "فالت",
    "فالج",
    "فالس",
    "فالوذج",
    "فالوذجى",
    "فالية",
    "فانلا",
    "فانلات",
    "فانلة",
    "فانوس",
    "فاوريقة",
    "فايظ",
    "فايظجي",
    "فبارك",
    "فبراير",
    "فبھا",
    "فت",
    "فتء",
    "فتأ",
    "فتئ",
    "فتاء",
    "فتائل",
    "فتاح",
    "فتاحة",
    "فتاش",
    "فتافيت",
    "فتاق",
    "فتال",
    "فتاو",
    "فتاوى",
    "فتة",
    "فتر",
    "فتفت",
    "فتفوتة",
    "فتق",
    "فتقت",
    "فتك",
    "فتكا",
    "فتكة",
    "فتل",
    "فتلة",
    "فتو",
    "فتوة",
    "فتوح",
    "فتوحات",
    "فتور",
    "فتوق",
    "فتون",
    "فتوى",
    "فتيا",
    "فتيت",
    "فتيتة",
    "فتيق",
    "فتيل",
    "فثء",
    "فثأ",
    "فجء",
    "فجأ",
    "فجئ",
    "فجا",
    "فجاءة",
    "فجائع",
    "فجائي",
    "فجاج",
    "فجار",
    "فجاعة",
    "فجرة",
    "فجع",
    "فجعة",
    "فجعنة",
    "فجفج",
    "فجل",
    "فجو",
    "فجور",
    "فجوع",
    "فجول",
    "فجيعة",
    "فح",
    "فحاش",
    "فحام",
    "فحش",
    "فحل",
    "فحمة",
    "فحواه",
    "فحوص",
    "فحوم",
    "فحومات",
    "فحومة",
    "فحوى",
    "فحيح",
    "فحيم",
    "فخ",
    "فخا",
    "فخاخ",
    "فخار",
    "فخاري",
    "فخت",
    "فخذة",
    "فخرة",
    "فخطوة",
    "فخفخ",
    "فخفخة",
    "فخوخ",
    "فخير",
    "فداءه",
    "فدائي",
    "فدائيون",
    "فداحة",
    "فدادين",
    "فدافد",
    "فداك",
    "فدام",
    "فدح",
    "فدخ",
    "فدر",
    "فدفد",
    "فدم",
    "فدن",
    "فدور",
    "فدى",
    "فديات",
    "فذ",
    "فذلكة",
    "فذوذ",
    "فرأ",
    "فراء",
    "فرائد",
    "فرائس",
    "فرائص",
    "فرائصه",
    "فرائض",
    "فراد",
    "فرادا",
    "فراديس",
    "فرار",
    "فرارجي",
    "فراره",
    "فراريج",
    "فرازة",
    "فرازين",
    "فراسة",
    "فراسخ",
    "فراطة",
    "فراعنة",
    "فراغا",
    "فراك",
    "فرامت",
    "فرامين",
    "فراولة",
    "فراھة",
    "فرتيكة",
    "فرجار",
    "فرجة",
    "فرجون",
    "فرحه",
    "فرخان",
    "فرخة",
    "فردة",
    "فردوسي",
    "فردى",
    "فردية",
    "فرز",
    "فرزان",
    "فرسان",
    "فرسخ",
    "فرسه",
    "فرسوفيا",
    "فرشاية",
    "فرشة",
    "فرشح",
    "فرشحة",
    "فرشخ",
    "فرشينة",
    "فرص",
    "فرصاد",
    "فرضة",
    "فرضي",
    "فرطح",
    "فرعاء",
    "فرفر",
    "فرفش",
    "فرفشة",
    "فرفور",
    "فرفوري",
    "فرفير",
    "فرقان",
    "فرقد",
    "فرقع",
    "فرقعة",
    "فرقلة",
    "فرك",
    "فركش",
    "فرم",
    "فرمبواز",
    "فرمسون",
    "فرملجي",
    "فرموزا",
    "فرنج",
    "فرنجة",
    "فرنجي",
    "فرند",
    "فرندة",
    "فرنس",
    "فرنساوي",
    "فرنسة",
    "فرنسوي",
    "فرنك",
    "فرنكة",
    "فره",
    "فروخ",
    "فرود",
    "فرودة",
    "فروسة",
    "فروسية",
    "فروش",
    "فروض",
    "فروغ",
    "فروق",
    "فرى",
    "فرية",
    "فريدة",
    "فريرة",
    "فريسة",
    "فريسته",
    "فريسي",
    "فريصة",
    "فريصته",
    "فز",
    "فزارة",
    "فزاعة",
    "فزة",
    "فزر",
    "فزعان",
    "فزورة",
    "فسا",
    "فسائل",
    "فساتين",
    "فساح",
    "فساحة",
    "فساطيط",
    "فساطين",
    "فسافس",
    "فسافيس",
    "فساق",
    "فساقي",
    "فستق",
    "فسح",
    "فسحة",
    "فسخة",
    "فسخي",
    "فسد",
    "فسدي",
    "فسطاط",
    "فسطان",
    "فسفات",
    "فسفر",
    "فسفس",
    "فسفوسة",
    "فسقة",
    "فسقية",
    "فسل",
    "فسلجة",
    "فسو",
    "فسود",
    "فسول",
    "فسولية",
    "فسيحا",
    "فسيخ",
    "فسيفساء",
    "فسيل",
    "فسيلة",
    "فسيولوجيا",
    "فش",
    "فشا",
    "فشار",
    "فشبرا",
    "فشة",
    "فشخ",
    "فشر",
    "فشش",
    "فشفاش",
    "فشك",
    "فشو",
    "فشي",
    "فشيئا",
    "فص",
    "فصائد",
    "فصائل",
    "فصاح",
    "فصاحة",
    "فصاد",
    "فصادة",
    "فصال",
    "فصح",
    "فصحى",
    "فصد",
    "فصفات",
    "فصفور",
    "فصفوري",
    "فصلان",
    "فصلة",
    "فصم",
    "فصوح",
    "فصوص",
    "فصوليا",
    "فصولية",
    "فصومات",
    "فصى",
    "فصيل",
    "فضا",
    "فضائح",
    "فضائل",
    "فضائي",
    "فضائية",
    "فضاح",
    "فضالة",
    "فضح",
    "فضلاء",
    "فضلة",
    "فضلى",
    "فضليات",
    "فضو",
    "فضول",
    "فضولھا",
    "فضيات",
    "فضيح",
    "فضيحة",
    "فضيل",
    "فضيلة",
    "فطائس",
    "فطاحل",
    "فطاطري",
    "فطاطرية",
    "فطام",
    "فطانة",
    "فطايري",
    "فطايرية",
    "فطح",
    "فطحل",
    "فطرة",
    "فطس",
    "فطناء",
    "فطوس",
    "فطيس",
    "فطيسة",
    "فطيم",
    "فظائع",
    "فظع",
    "فعالية",
    "فعبارة",
    "فعلة",
    "فعلية",
    "فعم",
    "فغر",
    "فغرة",
    "فغفوري",
    "فقأ",
    "فقاح",
    "فقار",
    "فقارة",
    "فقاعة",
    "فقاقيع",
    "فقامة",
    "فقاھة",
    "فقتلوه",
    "فقحة",
    "فقر",
    "فقرية",
    "فقش",
    "فقص",
    "فقع",
    "فقم",
    "فقنس",
    "فقوس",
    "فقوص",
    "فقوم",
    "فقيد",
    "فقيه",
    "فقھاء",
    "فقھي",
    "فكاك",
    "فكاكز",
    "فكاھة",
    "فكاھي",
    "فكاھية",
    "فكة",
    "فكره",
    "فكرى",
    "فكش",
    "فكلمة",
    "فكه",
    "فكوك",
    "فكير",
    "فلائك",
    "فلائكي",
    "فلاة",
    "فلاتي",
    "فلاتية",
    "فلاحي",
    "فلاذي",
    "فلاسفة",
    "فلاق",
    "فلال",
    "فلامنكى",
    "فلانة",
    "فلاني",
    "فلاوى",
    "فلايكي",
    "فلة",
    "فلت",
    "فلتاء",
    "فلتة",
    "فلج",
    "فلجان",
    "فلح",
    "فلذ",
    "فلذة",
    "فلز",
    "فلس",
    "فلسطيني",
    "فلطاح",
    "فلع",
    "فلفل",
    "فلفلة",
    "فلفلي",
    "فلق",
    "فلقة",
    "فلمندي",
    "فلندرة",
    "فلنكة",
    "فلوات",
    "فلوج",
    "فلور",
    "فلوس",
    "فلوع",
    "فلوق",
    "فلوكة",
    "فلول",
    "فلى",
    "فليا",
    "فلية",
    "فليذھب",
    "فليسقط",
    "فليق",
    "فلين",
    "فلينة",
    "فليه",
    "فليون",
    "فمه",
    "فمي",
    "فناجيل",
    "فناجين",
    "فنار",
    "فناطيس",
    "فنانة",
    "فنانون",
    "فنجال",
    "فنجان",
    "فنجانة",
    "فنجر",
    "فنخ",
    "فنط",
    "فنطاس",
    "فنطيس",
    "فنطيسة",
    "فنغراف",
    "فنق",
    "فنك",
    "فنلندا",
    "فنلندي",
    "فنن",
    "فنون",
    "فنيقي",
    "فنيقيا",
    "فنيقية",
    "فنيك",
    "فوائض",
    "فوائه",
    "فوات",
    "فواتح",
    "فواتر",
    "فواتن",
    "فواتير",
    "فواجر",
    "فواجع",
    "فواح",
    "فواحدا",
    "فواحش",
    "فوادح",
    "فوادن",
    "فوار",
    "فوارة",
    "فوارس",
    "فوارق",
    "فواريق",
    "فوازر",
    "فواصل",
    "فواضل",
    "فواقع",
    "فواكين",
    "فوال",
    "فوانيس",
    "فوة",
    "فوتيه",
    "فوح",
    "فوحان",
    "فوحة",
    "فور",
    "فورة",
    "فورشة",
    "فورشينة",
    "فوره",
    "فورى",
    "فوريقة",
    "فورھا",
    "فوسفات",
    "فوصفور",
    "فوضوية",
    "فوضى",
    "فوط",
    "فوطة",
    "فوعة",
    "فوف",
    "فوفة",
    "فوقاني",
    "فولاذ",
    "فونوغراف",
    "فووق",
    "فويت",
    "فوھة",
    "فيء",
    "فيأ",
    "فياح",
    "فياش",
    "فياف",
    "فيالج",
    "فيالق",
    "فيتون",
    "فيحاء",
    "فيد",
    "فيدرالي",
    "فيدرالية",
    "فيران",
    "فيروز",
    "فيروزج",
    "فيسيولوجيا",
    "فيش",
    "فيصل",
    "فيض",
    "فيضان",
    "فيظ",
    "فيفاء",
    "فيقة",
    "فيكونت",
    "فيلات",
    "فيلج",
    "فيلجة",
    "فيلق",
    "فيلولة",
    "فيلولوجيا",
    "فينان",
    "فينة",
    "فينيقي",
    "فيوء",
    "فيوض",
    "فيول",
    "فيولة",
    "فيوما",
    "فيينا",
    "فيھا",
    "فيھقة",
    "فھارس",
    "فھامة",
    "فھاھة",
    "فھد",
    "فھرس",
    "فھرسة",
    "فھرست",
    "فھم",
    "فھماء",
    "فھود",
    "فھيق",
    "فھيم",
    "قؤود",
    "قاء",
    "قائظ",
    "قائلا",
    "قائلة",
    "قائممقام",
    "قائممقامية",
    "قابض",
    "قابضة",
    "قابله",
    "قابوس",
    "قات",
    "قاتر",
    "قاتلات",
    "قاتله",
    "قاح",
    "قاحة",
    "قادات",
    "قادة",
    "قادحة",
    "قادرة",
    "قادس",
    "قادوس",
    "قاذفة",
    "قاذورة",
    "قار",
    "قارس",
    "قارص",
    "قازوزة",
    "قاسى",
    "قاشاني",
    "قاص",
    "قاصد",
    "قاصرة",
    "قاصل",
    "قاصم",
    "قاصمة",
    "قاصون",
    "قاضية",
    "قاطبة",
    "قاطرات",
    "قاطرة",
    "قاف",
    "قافل",
    "قافلة",
    "قافية",
    "قاق",
    "قاقلة",
    "قاقلى",
    "قاقلي",
    "قاقم",
    "قالبه",
    "قالة",
    "قالته",
    "قاله",
    "قالوش",
    "قامة",
    "قامر",
    "قان",
    "قانئ",
    "قانص",
    "قانصة",
    "قانط",
    "قانونا",
    "قانية",
    "قاه",
    "قاورمة",
    "قاوق",
    "قاووش",
    "قاووق",
    "قاوون",
    "قايش",
    "قاھر",
    "قاھرة",
    "قاھري",
    "قب",
    "قبا",
    "قباء",
    "قبائح",
    "قبائل",
    "قباب",
    "قباج",
    "قباح",
    "قباحة",
    "قباحى",
    "قبار",
    "قباض",
    "قباضة",
    "قباطنة",
    "قباطين",
    "قباع",
    "قباقيب",
    "قباقيبي",
    "قبال",
    "قبان",
    "قبج",
    "قبح",
    "قبحا",
    "قبحى",
    "قبر",
    "قبرس",
    "قبسة",
    "قبص",
    "قبصة",
    "قبضاى",
    "قبضت",
    "قبضه",
    "قبط",
    "قبطان",
    "قبطي",
    "قبع",
    "قبعته",
    "قبقاب",
    "قبقب",
    "قبلئذ",
    "قبلى",
    "قبن",
    "قبو",
    "قبوة",
    "قبودان",
    "قبور",
    "قبوع",
    "قبيحة",
    "قبيل",
    "قت",
    "قتات",
    "قتاد",
    "قتار",
    "قتام",
    "قتب",
    "قتلة",
    "قتلى",
    "قتم",
    "قتمة",
    "قتور",
    "قتوم",
    "قتيل",
    "قتيلا",
    "قث",
    "قثاء",
    "قثاطير",
    "قثطرة",
    "قح",
    "قحاب",
    "قحاف",
    "قحبة",
    "قحة",
    "قحط",
    "قحطان",
    "قحف",
    "قحفة",
    "قحل",
    "قحلاء",
    "قحم",
    "قحمة",
    "قحوط",
    "قحوف",
    "قحولة",
    "قدا",
    "قدائم",
    "قداح",
    "قداحة",
    "قداديس",
    "قداسة",
    "قدام",
    "قدامى",
    "قداوة",
    "قدة",
    "قدد",
    "قدرھا",
    "قدسية",
    "قدماء",
    "قدمان",
    "قدمه",
    "قدميه",
    "قده",
    "قدو",
    "قدوة",
    "قدود",
    "قدور",
    "قدوس",
    "قدوم",
    "قدى",
    "قدي",
    "قديد",
    "قديس",
    "قذائف",
    "قذاة",
    "قذاذ",
    "قذاف",
    "قذافة",
    "قذال",
    "قذة",
    "قذذ",
    "قذع",
    "قذفه",
    "قذل",
    "قذى",
    "قر",
    "قرء",
    "قرآني",
    "قرأ",
    "قراء",
    "قرائح",
    "قرائن",
    "قراب",
    "قرابيس",
    "قرابين",
    "قراج",
    "قراح",
    "قراد",
    "قرارات",
    "قرارة",
    "قرارلا",
    "قراريط",
    "قراصة",
    "قراصن",
    "قراصية",
    "قراصين",
    "قراضة",
    "قراط",
    "قراع",
    "قرافة",
    "قراقر",
    "قراقيش",
    "قرام",
    "قرامطة",
    "قراميد",
    "قراميط",
    "قران",
    "قراني",
    "قرايا",
    "قربان",
    "قربانة",
    "قربوس",
    "قربينة",
    "قرحة",
    "قردان",
    "قرس",
    "قرش",
    "قرشا",
    "قرشي",
    "قرصان",
    "قرصة",
    "قرصنة",
    "قرصه",
    "قرصي",
    "قرط",
    "قرطاجنة",
    "قرطجنة",
    "قرطس",
    "قرطم",
    "قرطمان",
    "قرظ",
    "قرعة",
    "قرعه",
    "قرعي",
    "قرف",
    "قرفال",
    "قرفان",
    "قرفة",
    "قرفص",
    "قرفصاء",
    "قرق",
    "قرقذان",
    "قرقر",
    "قرقض",
    "قرقع",
    "قرقعة",
    "قرقوز",
    "قرقوش",
    "قرقوشة",
    "قرقول",
    "قرم",
    "قرمة",
    "قرمد",
    "قرمز",
    "قرمش",
    "قرمطي",
    "قرموط",
    "قرميد",
    "قرنبيط",
    "قرنة",
    "قرنفل",
    "قرنه",
    "قرني",
    "قرنية",
    "قره",
    "قرو",
    "قرواطي",
    "قرواطيا",
    "قروح",
    "قروش",
    "قروض",
    "قروط",
    "قروم",
    "قروى",
    "قروي",
    "قروية",
    "قرويون",
    "قريح",
    "قريحة",
    "قريدس",
    "قرير",
    "قريشة",
    "قريص",
    "قريض",
    "قريظ",
    "قريع",
    "قريفة",
    "قرينته",
    "قز",
    "قزائز",
    "قزاز",
    "قزازة",
    "قزان",
    "قزحية",
    "قزع",
    "قزعة",
    "قزقز",
    "قزل",
    "قزموغرافي",
    "قزموغرافيا",
    "قزوز",
    "قزيعة",
    "قسا",
    "قساء",
    "قسائم",
    "قساة",
    "قساس",
    "قساطل",
    "قساطيس",
    "قسام",
    "قسامة",
    "قسان",
    "قساو",
    "قساوة",
    "قساوسة",
    "قسر",
    "قسرا",
    "قسس",
    "قسط",
    "قسطاس",
    "قسطل",
    "قسما",
    "قسماء",
    "قسمات",
    "قسنطينة",
    "قسو",
    "قسوس",
    "قسوسة",
    "قسوسية",
    "قسى",
    "قسي",
    "قسيس",
    "قسيم",
    "قسيمة",
    "قش",
    "قشاط",
    "قشافة",
    "قشب",
    "قشة",
    "قشد",
    "قشدة",
    "قشط",
    "قشطة",
    "قشع",
    "قشعر",
    "قشف",
    "قشفة",
    "قشقش",
    "قشل",
    "قشلاق",
    "قشلة",
    "قشمش",
    "قشيب",
    "قشيش",
    "قصا",
    "قصاء",
    "قصاب",
    "قصابة",
    "قصاج",
    "قصاد",
    "قصادة",
    "قصار",
    "قصاراك",
    "قصاراه",
    "قصارة",
    "قصارك",
    "قصارى",
    "قصاصة",
    "قصاع",
    "قصال",
    "قصب",
    "قصبة",
    "قصدا",
    "قصدك",
    "قصده",
    "قصدي",
    "قصدير",
    "قصرك",
    "قصرية",
    "قصصيون",
    "قصع",
    "قصعة",
    "قصقص",
    "قصل",
    "قصم",
    "قصور",
    "قصوف",
    "قصوى",
    "قصى",
    "قصي",
    "قصيبة",
    "قصيد",
    "قصيف",
    "قصيل",
    "قصيم",
    "قض",
    "قضائيون",
    "قضابة",
    "قضاع",
    "قضامة",
    "قضب",
    "قضة",
    "قضض",
    "قضع",
    "قضف",
    "قضوية",
    "قضيته",
    "قضيض",
    "قضيضھم",
    "قضھم",
    "قطا",
    "قطائع",
    "قطائف",
    "قطاة",
    "قطارة",
    "قطاط",
    "قطاعات",
    "قطاف",
    "قطان",
    "قطاني",
    "قطانية",
    "قطايف",
    "قطبة",
    "قطرجي",
    "قطرميز",
    "قطرن",
    "قططة",
    "قطعاء",
    "قطعان",
    "قطعيا",
    "قطعية",
    "قطف",
    "قطقوطة",
    "قطل",
    "قطم",
    "قطمة",
    "قطميرا",
    "قطنية",
    "قطوب",
    "قطورات",
    "قطوع",
    "قطوف",
    "قطون",
    "قطيرة",
    "قطيطة",
    "قطيع",
    "قطيفة",
    "قطيلة",
    "قعائد",
    "قعاد",
    "قعارة",
    "قعاقع",
    "قعد",
    "قعدان",
    "قعدة",
    "قعدت",
    "قعدي",
    "قعرة",
    "قعس",
    "قعقع",
    "قعقعة",
    "قعور",
    "قعيدة",
    "قعير",
    "قفا",
    "قفاء",
    "قفار",
    "قفاطين",
    "قفافيز",
    "قفال",
    "قفة",
    "قفر",
    "قفراء",
    "قفران",
    "قفرة",
    "قفزان",
    "قفش",
    "قفشات",
    "قفطان",
    "قفع",
    "قفف",
    "قفقف",
    "قفقفة",
    "قفو",
    "قفوف",
    "قفي",
    "قفير",
    "قفيز",
    "قلا",
    "قلاء",
    "قلائد",
    "قلائل",
    "قلاب",
    "قلابة",
    "قلاح",
    "قلادة",
    "قلاشين",
    "قلاعي",
    "قلافة",
    "قلاقل",
    "قلال",
    "قلالي",
    "قلامة",
    "قلانيس",
    "قلاووز",
    "قلاووظ",
    "قلاية",
    "قلبا",
    "قلبان",
    "قلبة",
    "قلبق",
    "قلبيا",
    "قلح",
    "قلده",
    "قلس",
    "قلش",
    "قلشين",
    "قلع",
    "قلعط",
    "قلعه",
    "قلعوط",
    "قلف",
    "قلفة",
    "قلفته",
    "قلفط",
    "قلفون",
    "قلفونية",
    "قلقاس",
    "قلقل",
    "قلقلة",
    "قلقيلة",
    "قلمه",
    "قلندار",
    "قلنس",
    "قلنسية",
    "قلو",
    "قلوبات",
    "قلوبھم",
    "قلوز",
    "قلوس",
    "قلوظ",
    "قلوع",
    "قلوق",
    "قليب",
    "قلية",
    "قليط",
    "قم",
    "قمؤ",
    "قماءة",
    "قمائم",
    "قماح",
    "قمارق",
    "قماري",
    "قماص",
    "قماط",
    "قماطر",
    "قماقم",
    "قمامة",
    "قمامصة",
    "قمح",
    "قمحة",
    "قمحي",
    "قمراء",
    "قمرق",
    "قمرية",
    "قمز",
    "قمس",
    "قمش",
    "قمشة",
    "قمص",
    "قمصان",
    "قمطة",
    "قمطر",
    "قمع",
    "قمقم",
    "قمقمة",
    "قمل",
    "قمن",
    "قموع",
    "قميء",
    "قمير",
    "قمين",
    "قمينة",
    "قن",
    "قنا",
    "قناء",
    "قنابر",
    "قنابل",
    "قنابيز",
    "قناته",
    "قناج",
    "قنادق",
    "قناديل",
    "قنازع",
    "قناص",
    "قناصة",
    "قناصل",
    "قناطة",
    "قناطر",
    "قناطير",
    "قناعات",
    "قناعة",
    "قنال",
    "قنان",
    "قناية",
    "قنبار",
    "قنباز",
    "قنبر",
    "قنبرة",
    "قنبل",
    "قنبلة",
    "قنبور",
    "قنبي",
    "قنبيط",
    "قنة",
    "قنت",
    "قنجة",
    "قند",
    "قندز",
    "قندس",
    "قندق",
    "قندلفت",
    "قندول",
    "قنزعة",
    "قنص",
    "قنصل",
    "قنصلاتو",
    "قنصلية",
    "قنط",
    "قنطار",
    "قنطاريون",
    "قنطر",
    "قنطرة",
    "قنطرمة",
    "قنع",
    "قنعان",
    "قنقر",
    "قنلسوة",
    "قنم",
    "قنن",
    "قنو",
    "قنوب",
    "قنوة",
    "قنوت",
    "قنود",
    "قنومة",
    "قنون",
    "قنونة",
    "قنى",
    "قني",
    "قنيات",
    "قنيان",
    "قنية",
    "قنيص",
    "قنينة",
    "قواء",
    "قوابل",
    "قواتم",
    "قواد",
    "قواديس",
    "قواربي",
    "قوارص",
    "قوارع",
    "قواس",
    "قواص",
    "قواصم",
    "قواض",
    "قواضى",
    "قواطع",
    "قواف",
    "قوافل",
    "قوال",
    "قوالح",
    "قوام",
    "قوامة",
    "قوامس",
    "قوانص",
    "قواه",
    "قواويش",
    "قواويق",
    "قواية",
    "قوايش",
    "قوب",
    "قوباء",
    "قوبة",
    "قوت",
    "قوح",
    "قود",
    "قور",
    "قورمة",
    "قوسان",
    "قوش",
    "قوط",
    "قوطة",
    "قوع",
    "قوق",
    "قوقع",
    "قولة",
    "قولحة",
    "قولنج",
    "قولون",
    "قولى",
    "قومة",
    "قومس",
    "قومسير",
    "قومندان",
    "قومى",
    "قوميات",
    "قومية",
    "قون",
    "قونة",
    "قونية",
    "قوه",
    "قويسة",
    "قويق",
    "قويم",
    "قى",
    "قىء",
    "قي",
    "قيء",
    "قياء",
    "قياتة",
    "قياتير",
    "قياثير",
    "قياد",
    "قياسا",
    "قياسر",
    "قياسرة",
    "قياسرية",
    "قياسى",
    "قياسيا",
    "قياصر",
    "قياصرة",
    "قياض",
    "قياطين",
    "قيافة",
    "قيامة",
    "قيامته",
    "قيان",
    "قيتار",
    "قيح",
    "قيدوم",
    "قيدى",
    "قيدية",
    "قير",
    "قيراط",
    "قيران",
    "قيروان",
    "قيس",
    "قيسرى",
    "قيسرية",
    "قيشانى",
    "قيصانة",
    "قيصرى",
    "قيصرية",
    "قيض",
    "قيطان",
    "قيطن",
    "قيطوس",
    "قيظ",
    "قيعان",
    "قيف",
    "قيقان",
    "قيقب",
    "قيلة",
    "قيلولة",
    "قيمي",
    "قين",
    "قينة",
    "قيوح",
    "قيود",
    "قيودا",
    "قيوم",
    "قيون",
    "قھار",
    "قھارمة",
    "قھاوي",
    "قھر",
    "قھرا",
    "قھرة",
    "قھرمان",
    "قھرمانة",
    "قھري",
    "قھرية",
    "قھقر",
    "قھقرة",
    "قھقرى",
    "قھقه",
    "قھقھة",
    "قھواتي",
    "قھوة",
    "قھوجي",
    "قھي",
    "كأبة",
    "كأد",
    "كأداء",
    "كأسات",
    "كأمس",
    "كؤود",
    "كئاس",
    "كئب",
    "كائنا",
    "كاب",
    "كابسة",
    "كابل",
    "كابلي",
    "كابوس",
    "كابولي",
    "كابين",
    "كاترة",
    "كاحل",
    "كاخية",
    "كادر",
    "كادميوم",
    "كارتداد",
    "كارتون",
    "كارث",
    "كارثة",
    "كاردينال",
    "كارز",
    "كارع",
    "كاروبيم",
    "كارى",
    "كاريكاتورية",
    "كارھا",
    "كازينو",
    "كازينوھات",
    "كاس",
    "كاسب",
    "كاسد",
    "كاسف",
    "كاشح",
    "كاشحة",
    "كاشفه",
    "كاع",
    "كاعب",
    "كاغط",
    "كافل",
    "كافور",
    "كاكنج",
    "كالآتي",
    "كالأول",
    "كالازم",
    "كالتنوين",
    "كالح",
    "كالحسام",
    "كالسابق",
    "كالماء",
    "كالمجمعين",
    "كالمعتاد",
    "كالو",
    "كالون",
    "كالوھات",
    "كامبوديا",
    "كامبيو",
    "كامخ",
    "كامد",
    "كامن",
    "كاميه",
    "كانتين",
    "كانمن",
    "كاو",
    "كاوتشق",
    "كاوية",
    "كاھل",
    "كاھله",
    "كاھن",
    "كاھية",
    "كب",
    "كبا",
    "كبائن",
    "كباب",
    "كبابة",
    "كبابيت",
    "كباد",
    "كبارة",
    "كباري",
    "كباريه",
    "كباس",
    "كباش",
    "كباية",
    "كباين",
    "كبب",
    "كبة",
    "كبتن",
    "كبتولة",
    "كبح",
    "كبذه",
    "كبراء",
    "كبرة",
    "كبري",
    "كبرياء",
    "كبريات",
    "كبريت",
    "كبريتة",
    "كبس",
    "كبسول",
    "كبسولة",
    "كبشة",
    "كبكب",
    "كبل",
    "كبو",
    "كبوة",
    "كبوت",
    "كبود",
    "كبوش",
    "كبول",
    "كبولي",
    "كبيبة",
    "كبيس",
    "كبيسة",
    "كبيكج",
    "كت",
    "كتائب",
    "كتابات",
    "كتابخانة",
    "كتابي",
    "كتابية",
    "كتاف",
    "كتاكيت",
    "كتالوج",
    "كتام",
    "كتان",
    "كتبخانة",
    "كتبي",
    "كتع",
    "كتف",
    "كتفاء",
    "كتفة",
    "كتفه",
    "كتفيه",
    "كتكت",
    "كتكوت",
    "كتمان",
    "كتن",
    "كتيب",
    "كتيبة",
    "كتيت",
    "كتيم",
    "كتينة",
    "كث",
    "كثاثة",
    "كثار",
    "كثاف",
    "كثب",
    "كثبان",
    "كثث",
    "كثلك",
    "كثلكة",
    "كثوثة",
    "كثوليكي",
    "كثيب",
    "كثيراء",
    "كح",
    "كحائل",
    "كحاطب",
    "كحال",
    "كحة",
    "كحت",
    "كحكح",
    "كحكحة",
    "كحل",
    "كحلاء",
    "كحلة",
    "كحلي",
    "كحيل",
    "كحيلان",
    "كحيلي",
    "كخية",
    "كد",
    "كداديس",
    "كدارة",
    "كداس",
    "كداسة",
    "كدر",
    "كدراء",
    "كدرة",
    "كدش",
    "كدم",
    "كدود",
    "كدور",
    "كدورة",
    "كدى",
    "كدية",
    "كدير",
    "كديش",
    "كذابة",
    "كذبة",
    "كذوب",
    "كرا",
    "كراء",
    "كرائم",
    "كرائه",
    "كرابيج",
    "كرابيس",
    "كرابيل",
    "كراتين",
    "كراث",
    "كراج",
    "كراخين",
    "كرادلة",
    "كرادين",
    "كرار",
    "كرارط",
    "كرارطي",
    "كرارية",
    "كراريط",
    "كرازة",
    "كراس",
    "كراسيع",
    "كراع",
    "كرافتة",
    "كراكة",
    "كراكوفيا",
    "كراكي",
    "كرامات",
    "كرامة",
    "كراميل",
    "كرانيش",
    "كرانيف",
    "كراھة",
    "كراھية",
    "كرباج",
    "كرباس",
    "كربال",
    "كربة",
    "كربلاء",
    "كربون",
    "كرتن",
    "كرتون",
    "كرث",
    "كرجي",
    "كرح",
    "كرخانة",
    "كرخانجي",
    "كردان",
    "كردس",
    "كردون",
    "كرز",
    "كرزى",
    "كرسف",
    "كرسنة",
    "كرسوع",
    "كرسى",
    "كرشة",
    "كرشوني",
    "كرع",
    "كرعة",
    "كرفس",
    "كرك",
    "كركب",
    "كركبة",
    "كركة",
    "كركدن",
    "كركر",
    "كركرة",
    "كركم",
    "كركند",
    "كركوز",
    "كركون",
    "كركي",
    "كرما",
    "كرماء",
    "كرمانا",
    "كرمش",
    "كرمل",
    "كرملا",
    "كرملي",
    "كرناف",
    "كرنافة",
    "كرنب",
    "كرنبة",
    "كرنتينة",
    "كرنك",
    "كرنيش",
    "كرو",
    "كروان",
    "كروبي",
    "كروبيم",
    "كرور",
    "كروسة",
    "كروش",
    "كروع",
    "كروكي",
    "كروكيات",
    "كرى",
    "كريث",
    "كريد",
    "كريشة",
    "كريطة",
    "كريك",
    "كريمة",
    "كريھا",
    "كريھة",
    "كرھا",
    "كز",
    "كزاز",
    "كزازة",
    "كزرونة",
    "كزز",
    "كزورنة",
    "كزوزة",
    "كساء",
    "كسائه",
    "كسابق",
    "كساتا",
    "كساتبين",
    "كساتك",
    "كساح",
    "كساحة",
    "كساد",
    "كسارى",
    "كساه",
    "كساو",
    "كسبرة",
    "كستاك",
    "كستبان",
    "كستك",
    "كستلية",
    "كستنائي",
    "كستنة",
    "كستيك",
    "كسح",
    "كسد",
    "كسرة",
    "كسرت",
    "كسره",
    "كسرونة",
    "كسرى",
    "كسع",
    "كسف",
    "كسكاس",
    "كسكت",
    "كسكس",
    "كسكسو",
    "كسكسي",
    "كسله",
    "كسلى",
    "كسم",
    "كسو",
    "كسود",
    "كسور",
    "كسورات",
    "كسوف",
    "كسي",
    "كسيح",
    "كسيد",
    "كسير",
    "كسيم",
    "كشاتبين",
    "كشاحة",
    "كشافة",
    "كشافي",
    "كشاكش",
    "كشب",
    "كشة",
    "كشتبان",
    "كشح",
    "كشحا",
    "كشحه",
    "كشر",
    "كشرة",
    "كشفة",
    "كشفي",
    "كشفية",
    "كشكش",
    "كشكشية",
    "كشكول",
    "كشمش",
    "كشنى",
    "كشوح",
    "كشوف",
    "كشوفات",
    "كشيف",
    "كض",
    "كظ",
    "كظة",
    "كظر",
    "كظم",
    "كظوم",
    "كظيظ",
    "كظھر",
    "كعاب",
    "كعابر",
    "كعب",
    "كعبا",
    "كعبة",
    "كعبرة",
    "كعبري",
    "كعبل",
    "كعبورة",
    "كعبي",
    "كعبھم",
    "كعم",
    "كعيب",
    "كعھده",
    "كعھدي",
    "كعھدھم",
    "كغم",
    "كفأ",
    "كفؤ",
    "كفا",
    "كفاء",
    "كفاءات",
    "كفاءة",
    "كفاة",
    "كفاح",
    "كفاف",
    "كفافة",
    "كفالة",
    "كفاه",
    "كفة",
    "كفت",
    "كفتة",
    "كفح",
    "كفرة",
    "كفس",
    "كفساء",
    "كفف",
    "كفكف",
    "كفلاء",
    "كفه",
    "كفوف",
    "كفول",
    "كفي",
    "كفيت",
    "كفيف",
    "كفيل",
    "كفھر",
    "كلء",
    "كلأ",
    "كلاء",
    "كلاءة",
    "كلابة",
    "كلابي",
    "كلاح",
    "كلاسة",
    "كلاسط",
    "كلاسكي",
    "كلاف",
    "كلافي",
    "كلاكس",
    "كلاكل",
    "كلاكله",
    "كلاكيع",
    "كلال",
    "كلاليب",
    "كلامي",
    "كلامية",
    "كلاوى",
    "كلبة",
    "كلبش",
    "كلبى",
    "كلبي",
    "كلة",
    "كلت",
    "كلتي",
    "كلح",
    "كلحة",
    "كلخ",
    "كلداني",
    "كلسة",
    "كلسون",
    "كلسيطة",
    "كلسيوم",
    "كلفاء",
    "كلفة",
    "كلفه",
    "كلكل",
    "كلكلة",
    "كلكله",
    "كلماء",
    "كلماني",
    "كلمته",
    "كلمتھم",
    "كلمى",
    "كلوء",
    "كلوب",
    "كلوة",
    "كلوح",
    "كلول",
    "كلولة",
    "كلوم",
    "كلوى",
    "كلى",
    "كليات",
    "كليب",
    "كليشيه",
    "كليل",
    "كلھا",
    "كلھم",
    "كمء",
    "كمأة",
    "كمائم",
    "كماة",
    "كماخ",
    "كمادة",
    "كمارك",
    "كماشة",
    "كمالة",
    "كمالي",
    "كماليات",
    "كمام",
    "كمامة",
    "كمان",
    "كمانجي",
    "كمب",
    "كمبريت",
    "كمبيالة",
    "كمبيو",
    "كمت",
    "كمثراة",
    "كمثريات",
    "كمح",
    "كمخ",
    "كمخا",
    "كمد",
    "كمدة",
    "كمر",
    "كمرة",
    "كمرك",
    "كمركي",
    "كمساري",
    "كمسارية",
    "كمسلم",
    "كمش",
    "كمشة",
    "كمع",
    "كمل",
    "كملة",
    "كممة",
    "كمناء",
    "كمنة",
    "كمنجا",
    "كمنجة",
    "كمه",
    "كمول",
    "كمون",
    "كميت",
    "كميد",
    "كميش",
    "كميع",
    "كمين",
    "كميون",
    "كمھاء",
    "كنائس",
    "كنائسي",
    "كنائن",
    "كنادر",
    "كنار",
    "كناري",
    "كناسة",
    "كناش",
    "كناشات",
    "كناشة",
    "كنافة",
    "كنان",
    "كنانة",
    "كنب",
    "كنبه",
    "كنبيه",
    "كنة",
    "كنجرو",
    "كندر",
    "كندرة",
    "كندش",
    "كنصول",
    "كنغر",
    "كنف",
    "كنفاش",
    "كنكة",
    "كنكن",
    "كنكينا",
    "كنه",
    "كنو",
    "كنود",
    "كنوز",
    "كنون",
    "كنى",
    "كني",
    "كنية",
    "كنيس",
    "كنيف",
    "كنين",
    "كنھور",
    "كواء",
    "كوائر",
    "كوابيس",
    "كوابيل",
    "كواتر",
    "كواحل",
    "كواخ",
    "كوارة",
    "كوارث",
    "كوارع",
    "كواسر",
    "كواعب",
    "كواليس",
    "كوالين",
    "كواليني",
    "كوامخ",
    "كوامن",
    "كوانين",
    "كواه",
    "كواية",
    "كواھل",
    "كوبة",
    "كوبرته",
    "كوبري",
    "كوبنھاج",
    "كوبنھاجن",
    "كوبيا",
    "كوبيل",
    "كوبيه",
    "كوة",
    "كوتر",
    "كوثة",
    "كوثر",
    "كوثل",
    "كود",
    "كودة",
    "كودية",
    "كورة",
    "كوردون",
    "كورريا",
    "كورسيه",
    "كورسيھات",
    "كورنيش",
    "كوروي",
    "كورى",
    "كوريك",
    "كوس",
    "كوسا",
    "كوساة",
    "كوساية",
    "كوسج",
    "كوسى",
    "كوش",
    "كوشة",
    "كوع",
    "كوف",
    "كوفية",
    "كولان",
    "كولونيا",
    "كوليرا",
    "كوماندان",
    "كومساري",
    "كومسيوجي",
    "كومودينو",
    "كوميديا",
    "كونتراتو",
    "كونكرداتو",
    "كونياك",
    "كوى",
    "كوية",
    "كويس",
    "كياد",
    "كياس",
    "كياسة",
    "كياكي",
    "كيان",
    "كية",
    "كير",
    "كيران",
    "كيروان",
    "كيروسين",
    "كيزان",
    "كيسة",
    "كيسه",
    "كيسى",
    "كيعان",
    "كيفك",
    "كيفي",
    "كيفيا",
    "كيفية",
    "كيكا",
    "كيكة",
    "كيلة",
    "كيلوجرام",
    "كيلوسيكل",
    "كيلوغرام",
    "كيلومتر",
    "كيلومترات",
    "كيلون",
    "كيلوواط",
    "كيما",
    "كيماوي",
    "كيماويات",
    "كيموس",
    "كيمي",
    "كينا",
    "كينونة",
    "كينين",
    "كيوان",
    "كيوف",
    "كيھك",
    "كھارب",
    "كھاربي",
    "كھال",
    "كھامة",
    "كھان",
    "كھانة",
    "كھاية",
    "كھذا",
    "كھرب",
    "كھربا",
    "كھرباء",
    "كھربائى",
    "كھربائي",
    "كھربائية",
    "كھربة",
    "كھربي",
    "كھربية",
    "كھرطيسي",
    "كھرمان",
    "كھف",
    "كھل",
    "كھلان",
    "كھن",
    "كھنة",
    "كھنجي",
    "كھنوت",
    "كھنوتي",
    "كھوف",
    "كھول",
    "كھولة",
    "كھيرب",
    "كھيربي",
    "لآلي",
    "لآمة",
    "لأجلك",
    "لأذقانھم",
    "لأسداس",
    "لأعتاب",
    "لأعمال",
    "لأك",
    "لألأ",
    "لألأة",
    "لألاء",
    "لأم",
    "لأمة",
    "لأواء",
    "لأھلية",
    "لؤلؤى",
    "لؤم",
    "لؤماء",
    "لؤمان",
    "لئام",
    "لئم",
    "لئيم",
    "لائذ",
    "لائكي",
    "لائكية",
    "لائمة",
    "لاث",
    "لاج",
    "لاجئ",
    "لاجنسي",
    "لاح",
    "لاحب",
    "لاحقة",
    "لاديني",
    "لاذ",
    "لازمة",
    "لازورد",
    "لازوردي",
    "لازوردية",
    "لازوق",
    "لاس",
    "لاسلكية",
    "لاسيه",
    "لاشتداد",
    "لاشعوري",
    "لاشيئية",
    "لاط",
    "لاطفه",
    "لاع",
    "لاعج",
    "لاعضوي",
    "لاغ",
    "لاغب",
    "لاف",
    "لافح",
    "لاق",
    "لاقط",
    "لاقطة",
    "لاقمي",
    "لاقى",
    "لاكن",
    "لالا",
    "لالاء",
    "لالامر",
    "لاما",
    "لامبالاة",
    "لامبالية",
    "لامة",
    "لامركزية",
    "لامسؤولية",
    "لامعة",
    "لامي",
    "لان",
    "لانت",
    "لانش",
    "لانھائي",
    "لانھائية",
    "لاه",
    "لاول",
    "لاونجي",
    "لايجف",
    "لاھاي",
    "لاھف",
    "لاھوت",
    "لاھوتي",
    "لاھور",
    "لاھيا",
    "لب",
    "لبؤة",
    "لبائن",
    "لباب",
    "لبابة",
    "لبابيد",
    "لباث",
    "لباد",
    "لبادة",
    "لباقة",
    "لباليب",
    "لبان",
    "لبانة",
    "لبب",
    "لبة",
    "لبتة",
    "لبثة",
    "لبخ",
    "لبخة",
    "لبدة",
    "لبسة",
    "لبط",
    "لبطن",
    "لبك",
    "لبكة",
    "لبلاب",
    "لبلب",
    "لبلوب",
    "لبنات",
    "لبناني",
    "لبنة",
    "لبنى",
    "لبنية",
    "لبه",
    "لبوب",
    "لبوة",
    "لبود",
    "لبون",
    "لبونة",
    "لبى",
    "لبي",
    "لبيب",
    "لبيخ",
    "لبيريا",
    "لبيس",
    "لبيق",
    "لبيك",
    "لت",
    "لتات",
    "لتر",
    "لتسجيل",
    "لتفقيص",
    "لتلميع",
    "لتموس",
    "لتوانيا",
    "لتوطئة",
    "لتوى",
    "لتوھا",
    "لثام",
    "لثة",
    "لثغ",
    "لثغاء",
    "لثغة",
    "لثم",
    "لثمة",
    "لثو",
    "لثوي",
    "لثى",
    "لج",
    "لجء",
    "لجأ",
    "لجئ",
    "لجاج",
    "لجاجة",
    "لجان",
    "لجب",
    "لجج",
    "لجلاج",
    "لجلج",
    "لجلجة",
    "لجم",
    "لجميله",
    "لجن",
    "لجنب",
    "لجنس",
    "لجوء",
    "لجوج",
    "لجين",
    "لجيني",
    "لح",
    "لحا",
    "لحاجة",
    "لحاد",
    "لحاف",
    "لحافه",
    "لحاق",
    "لحالة",
    "لحامة",
    "لحانة",
    "لحتفه",
    "لحج",
    "لحح",
    "لحديثه",
    "لحس",
    "لحساب",
    "لحسة",
    "لحظ",
    "لحظات",
    "لحظان",
    "لحظتئذ",
    "لحف",
    "لحقي",
    "لحقية",
    "لحقھا",
    "لحكمة",
    "لحمية",
    "لحو",
    "لحود",
    "لحون",
    "لحى",
    "لحي",
    "لحيان",
    "لحيته",
    "لحين",
    "لخاطرك",
    "لخدمة",
    "لخطة",
    "لخلخ",
    "لخمة",
    "لخن",
    "لخناء",
    "لخواطرھم",
    "لخير",
    "لداء",
    "لدائن",
    "لدات",
    "لداد",
    "لدان",
    "لدانة",
    "لدة",
    "لدد",
    "لدعوته",
    "لدغ",
    "لدغاء",
    "لدغى",
    "لدمائھم",
    "لدواع",
    "لدود",
    "لدون",
    "لدونة",
    "لديد",
    "لديغ",
    "لذ",
    "لذائذ",
    "لذاته",
    "لذاذ",
    "لذاذة",
    "لذاع",
    "لذع",
    "لذكراه",
    "لرصد",
    "لز",
    "لزاب",
    "لزاق",
    "لزام",
    "لزب",
    "لزبة",
    "لزة",
    "لزز",
    "لززاز",
    "لزق",
    "لزقة",
    "لزقه",
    "لزم",
    "لزمة",
    "لزوب",
    "لزوج",
    "لزوق",
    "لزوم",
    "لساعته",
    "لسانين",
    "لسبع",
    "لسبيله",
    "لستك",
    "لستيك",
    "لسعاء",
    "لسعى",
    "لسناء",
    "لسنه",
    "لسيع",
    "لشبونة",
    "لشد",
    "لشى",
    "لصق",
    "لصقي",
    "لصوق",
    "لصيد",
    "لصيق",
    "لطائف",
    "لطابق",
    "لطاف",
    "لطس",
    "لطش",
    "لطع",
    "لطعة",
    "لطف",
    "لطفاء",
    "لطلبكم",
    "لطمة",
    "لطيته",
    "لطيخ",
    "لطيفة",
    "لطيم",
    "لظي",
    "لظھر",
    "لع",
    "لعا",
    "لعاب",
    "لعالع",
    "لعان",
    "لعبت",
    "لعبرته",
    "لعثم",
    "لعثمة",
    "لعج",
    "لعجب",
    "لعجة",
    "لعساء",
    "لعق",
    "لعقة",
    "لعلع",
    "لعوق",
    "لعياله",
    "لعيب",
    "لعيبة",
    "لغا",
    "لغاديد",
    "لغاط",
    "لغام",
    "لغاية",
    "لغايته",
    "لغب",
    "لغدود",
    "لغز",
    "لغط",
    "لغم",
    "لغما",
    "لغمط",
    "لغوة",
    "لغود",
    "لغويات",
    "لغي",
    "لفاء",
    "لفائف",
    "لفات",
    "لفاح",
    "لفت",
    "لفتاء",
    "لفتة",
    "لفح",
    "لفحان",
    "لفحة",
    "لفظا",
    "لفظه",
    "لفظية",
    "لفع",
    "لفق",
    "لفلان",
    "لفلف",
    "لفه",
    "لفوت",
    "لفوح",
    "لفيظ",
    "لفيف",
    "لفيفة",
    "لقاح",
    "لقاط",
    "لقاطة",
    "لقالق",
    "لقانة",
    "لقانية",
    "لقاية",
    "لقرصه",
    "لقس",
    "لقط",
    "لقطاء",
    "لقع",
    "لقف",
    "لقفان",
    "لقلاق",
    "لقلق",
    "لقلقة",
    "لقم",
    "لقمة",
    "لقمي",
    "لقولى",
    "لقى",
    "لقي",
    "لقيان",
    "لقية",
    "لقيط",
    "لقيطة",
    "لقيمة",
    "لكء",
    "لكأ",
    "لكأة",
    "لكئ",
    "لكاز",
    "لكاعة",
    "لكانة",
    "لكز",
    "لكعاء",
    "لكناء",
    "لكنة",
    "لكنونة",
    "لكوك",
    "لكونة",
    "لكونه",
    "لكيع",
    "لكيما",
    "للآداب",
    "للآن",
    "للأخطار",
    "للأعداء",
    "للأمر",
    "للأميرال",
    "للإبرة",
    "للإيجار",
    "للاانثناء",
    "للارصاد",
    "للاسف",
    "للالتھاب",
    "للامتحان",
    "للاميرال",
    "للبوليس",
    "للتجديد",
    "للتجزئة",
    "للتعس",
    "للتو",
    "للتوليد",
    "للجندية",
    "للحال",
    "للحبل",
    "للحسرة",
    "للحقيقة",
    "للحكم",
    "للحيويات",
    "للخجل",
    "للخزى",
    "للدار",
    "للدھان",
    "للرجوع",
    "للريح",
    "للسقف",
    "للسقوط",
    "للشابورة",
    "للشقاء",
    "للشيء",
    "للشيخ",
    "للضرورة",
    "للطعام",
    "للطعن",
    "للعادة",
    "للعجب",
    "للعزل",
    "للغارات",
    "للفظ",
    "للكاتب",
    "للمداولة",
    "للمعروف",
    "للمعلمين",
    "للمقاومة",
    "للموت",
    "للنجاة",
    "للنصاب",
    "للنور",
    "للنھاية",
    "للھول",
    "لماح",
    "لماذا",
    "لماز",
    "لماعة",
    "لمام",
    "لماما",
    "لمباجو",
    "لمبة",
    "لمة",
    "لمج",
    "لمجة",
    "لمح",
    "لمحان",
    "لمحض",
    "لمدافع",
    "لمز",
    "لمسة",
    "لمستزيد",
    "لمسى",
    "لمسية",
    "لمشرقان",
    "لمص",
    "لمصلحة",
    "لمظ",
    "لمعان",
    "لمعاونتنا",
    "لمعة",
    "لملم",
    "لمم",
    "لمناسبة",
    "لميس",
    "لنج",
    "لندرة",
    "لنش",
    "لنينغراد",
    "لواؤھا",
    "لوائح",
    "لوائم",
    "لوائه",
    "لواة",
    "لواحظ",
    "لواحق",
    "لواذ",
    "لواط",
    "لواطة",
    "لواعج",
    "لوافح",
    "لوالب",
    "لوام",
    "لوامع",
    "لوبي",
    "لوبيا",
    "لوثة",
    "لوج",
    "لوجھه",
    "لوذ",
    "لوذع",
    "لوذعي",
    "لوذعية",
    "لور",
    "لورى",
    "لوزى",
    "لوزية",
    "لوص",
    "لوع",
    "لوعة",
    "لوغاريتمات",
    "لوف",
    "لوقته",
    "لوكانده",
    "لوكنده",
    "لولانا",
    "لولب",
    "لولبي",
    "لولبيات",
    "لوم",
    "لومان",
    "لومانجي",
    "لونجي",
    "لونجية",
    "لوندا",
    "لوى",
    "لوي",
    "لى",
    "لياء",
    "ليائل",
    "لياذ",
    "لياقة",
    "ليان",
    "ليبي",
    "لية",
    "ليتبين",
    "ليترجية",
    "ليث",
    "ليحي",
    "ليرة",
    "ليساء",
    "ليسانس",
    "ليسقط",
    "ليصعب",
    "ليفاني",
    "ليفة",
    "ليق",
    "ليقاد",
    "ليقة",
    "ليكتب",
    "ليكن",
    "ليل",
    "ليلاء",
    "ليلاه",
    "ليلتئذ",
    "ليلتكم",
    "ليلك",
    "ليله",
    "ليلية",
    "ليمون",
    "ليوث",
    "ليونة",
    "لھا",
    "لھاء",
    "لھاب",
    "لھاة",
    "لھاث",
    "لھاف",
    "لھافى",
    "لھب",
    "لھبان",
    "لھبى",
    "لھث",
    "لھثان",
    "لھثى",
    "لھج",
    "لھجة",
    "لھد",
    "لھذا",
    "لھذم",
    "لھذه",
    "لھط",
    "لھف",
    "لھفا",
    "لھفان",
    "لھفة",
    "لھفى",
    "لھفي",
    "لھق",
    "لھلق",
    "لھلك",
    "لھم",
    "لھنا",
    "لھو",
    "لھوات",
    "لھوم",
    "لھوى",
    "لھي",
    "لھيات",
    "لھيان",
    "لھيب",
    "لھيف",
    "مآبض",
    "مآت",
    "مآتم",
    "مآثر",
    "مآثم",
    "مآخذ",
    "مآدب",
    "مآذن",
    "مآزر",
    "مآزق",
    "مآزيب",
    "مآس",
    "مآق",
    "مآكل",
    "مآل",
    "مآمل",
    "مآو",
    "مأبض",
    "مأبون",
    "مأتاة",
    "مأتم",
    "مأتى",
    "مأثرة",
    "مأثم",
    "مأثور",
    "مأثورة",
    "مأخذا",
    "مأخوذات",
    "مأدبة",
    "مأذنة",
    "مأذون",
    "مأذونية",
    "مأرب",
    "مأزق",
    "مأزوم",
    "مأساة",
    "مأسورة",
    "مأسوف",
    "مأفون",
    "مأق",
    "مأقة",
    "مأكولات",
    "مألف",
    "مأمأ",
    "مأمل",
    "مأمن",
    "مأمورية",
    "مأمول",
    "مأن",
    "مأنة",
    "مأنوس",
    "مأوى",
    "مأھول",
    "مؤاخذة",
    "مؤازرة",
    "مؤاكل",
    "مؤبد",
    "مؤتمون",
    "مؤتنف",
    "مؤثل",
    "مؤخر",
    "مؤسل",
    "مؤلفة",
    "مؤلما",
    "مؤمل",
    "مؤوف",
    "مؤون",
    "مؤونة",
    "مؤوه",
    "مؤھلات",
    "مئات",
    "مئبر",
    "مئخار",
    "مئذنة",
    "مئرم",
    "مئزاب",
    "مئشار",
    "مئق",
    "مئني",
    "مئون",
    "مئيني",
    "مئينية",
    "مائت",
    "مائج",
    "مائد",
    "مائدته",
    "مائن",
    "ماتة",
    "ماتح",
    "ماتع",
    "ماتينيه",
    "ماثل",
    "ماثلة",
    "ماج",
    "ماجريات",
    "ماجل",
    "ماجن",
    "ماجور",
    "ماح",
    "ماحك",
    "ماحل",
    "ماحية",
    "ماخر",
    "ماخرة",
    "ماخور",
    "ماد",
    "مادح",
    "مادية",
    "مارة",
    "مارستان",
    "مارش",
    "مارشالية",
    "مارق",
    "ماركسي",
    "ماركسية",
    "ماروني",
    "ماز",
    "مازة",
    "مازوت",
    "مازية",
    "ماس",
    "ماسة",
    "ماسون",
    "ماسونية",
    "ماش",
    "ماشط",
    "ماشطة",
    "ماشك",
    "ماشيا",
    "ماشية",
    "ماض",
    "ماضر",
    "ماضي",
    "ماط",
    "ماطر",
    "ماع",
    "ماعلينا",
    "ماعون",
    "ماعونة",
    "ماكن",
    "ماكوك",
    "ماكياج",
    "مالج",
    "مالحة",
    "مالطة",
    "مالكي",
    "مالنخوليا",
    "ماليخاليا",
    "مانجو",
    "مانجوست",
    "مانعا",
    "مانوكان",
    "مانوليا",
    "مانوي",
    "مانوية",
    "مانيفاتورة",
    "مانيفستو",
    "مانيكان",
    "ماه",
    "ماوي",
    "ماوية",
    "مايونيز",
    "مايوه",
    "ماھة",
    "ماھر",
    "ماھن",
    "ماھي",
    "ماھية",
    "مباءة",
    "مباحات",
    "مباحث",
    "مباحثة",
    "مباخر",
    "مبادرة",
    "مبادلات",
    "مبادلة",
    "مباذل",
    "مباذله",
    "مبار",
    "مبارأة",
    "مباران",
    "مبارحة",
    "مبارد",
    "مبارز",
    "مبارزة",
    "مبازق",
    "مبازل",
    "مباسم",
    "مباضع",
    "مباعث",
    "مباغ",
    "مبال",
    "مبان",
    "مباول",
    "مبايعة",
    "مباھاة",
    "مباھج",
    "مبتاع",
    "مبتدأ",
    "مبتدئ",
    "مبتغى",
    "مبتلى",
    "مبتور",
    "مبتھج",
    "مبحبح",
    "مبحثر",
    "مبخة",
    "مبخرة",
    "مبخلة",
    "مبخوت",
    "مبدئيا",
    "مبذل",
    "مبراة",
    "مبرة",
    "مبرح",
    "مبرقش",
    "مبرم",
    "مبرمة",
    "مبرور",
    "مبروم",
    "مبزقة",
    "مبزل",
    "مبسم",
    "مبشرة",
    "مبشور",
    "مبشورة",
    "مبصقة",
    "مبضع",
    "مبطان",
    "مبطخة",
    "مبطلة",
    "مبطون",
    "مبعث",
    "مبعزق",
    "مبغوض",
    "مبغى",
    "مبك",
    "مبكرة",
    "مبلورة",
    "مبلول",
    "مبنك",
    "مبوب",
    "مبوبة",
    "مبوز",
    "مبولة",
    "مبيضة",
    "مبيونة",
    "مبھج",
    "مبھجة",
    "مبھدل",
    "مبھرج",
    "مبھم",
    "مبھوت",
    "مبھور",
    "متآلف",
    "متآمرون",
    "متأجج",
    "متأسف",
    "متأكل",
    "متأله",
    "متأن",
    "متأين",
    "متأھب",
    "متأھل",
    "متئد",
    "متابن",
    "متاحف",
    "متار",
    "متارب",
    "متارس",
    "متارك",
    "متاريس",
    "متاعب",
    "متاعيس",
    "متالف",
    "متان",
    "متانة",
    "متاولة",
    "متاھة",
    "متبار",
    "متباعدة",
    "متبتل",
    "متبحر",
    "متبذل",
    "متبربر",
    "متبرم",
    "متبطل",
    "متبع",
    "متبق",
    "متبلد",
    "متبن",
    "متتابع",
    "متتال",
    "متجاذب",
    "متجرى",
    "متجسد",
    "متجل",
    "متجلد",
    "متح",
    "متحاب",
    "متحالف",
    "متحتم",
    "متحرج",
    "متحرض",
    "متحزب",
    "متحصل",
    "متحوط",
    "متخرج",
    "متخشب",
    "متخف",
    "متخلخل",
    "متخلفات",
    "متخلفة",
    "متداع",
    "متدان",
    "متداول",
    "متدرن",
    "متدل",
    "متدينة",
    "متراخ",
    "مترادف",
    "مترادفات",
    "متراس",
    "متراكز",
    "متراليوز",
    "مترام",
    "مترامي",
    "متربعا",
    "مترد",
    "مترس",
    "مترفع",
    "مترھل",
    "متزن",
    "متساو",
    "متساوى",
    "متساھل",
    "متسبب",
    "متستر",
    "متسربل",
    "متسعا",
    "متشابھات",
    "متشامخ",
    "متشبث",
    "متشح",
    "متشيع",
    "متصف",
    "متصلف",
    "متضاد",
    "متضح",
    "متضعضع",
    "متضلع",
    "متطامن",
    "متطبب",
    "متطربش",
    "متطرفة",
    "متطلبات",
    "متطلع",
    "متطلق",
    "متطير",
    "متظاھر",
    "متظرف",
    "متعاظم",
    "متعاقدان",
    "متعاكس",
    "متعاھد",
    "متعبد",
    "متعد",
    "متعسر",
    "متعص",
    "متعلقاته",
    "متعنت",
    "متعه",
    "متعوس",
    "متعھد",
    "متغفل",
    "متغلغل",
    "متغيم",
    "متفاوتة",
    "متفتر",
    "متفرعات",
    "متفرغ",
    "متفرنج",
    "متفقد",
    "متفلة",
    "متفلسف",
    "متفنن",
    "متق",
    "متقاض",
    "متقاضون",
    "متقاضى",
    "متقاعس",
    "متقدس",
    "متقوم",
    "متكاثف",
    "متكدر",
    "متكره",
    "متكلفة",
    "متكھن",
    "متلألئ",
    "متلاحق",
    "متلاش",
    "متلاصق",
    "متلاعن",
    "متلاف",
    "متلبس",
    "متلبسا",
    "متلثم",
    "متلدد",
    "متلذذ",
    "متلصص",
    "متلصصا",
    "متلطم",
    "متلف",
    "متلفة",
    "متلمع",
    "متله",
    "متلوف",
    "متلھف",
    "متماوج",
    "متماھل",
    "متمدن",
    "متمدين",
    "متمرض",
    "متمرن",
    "متمصر",
    "متمغط",
    "متمول",
    "متمھل",
    "متناقضات",
    "متناوب",
    "متناول",
    "متناولا",
    "متناوله",
    "متنبر",
    "متنبه",
    "متندم",
    "متنسم",
    "متنور",
    "متنوعات",
    "متنيح",
    "متواتر",
    "متوارث",
    "متواز",
    "متواشج",
    "متواصلة",
    "متوافر",
    "متوال",
    "متوالية",
    "متوان",
    "متوثب",
    "متوحد",
    "متوطن",
    "متوع",
    "متوفق",
    "متوفى",
    "متوقد",
    "متوكد",
    "متوكلي",
    "متوله",
    "متولي",
    "متيسر",
    "متيم",
    "متھالك",
    "متھاود",
    "متھاون",
    "متھتك",
    "متھدم",
    "متھذب",
    "متھكم",
    "متھلل",
    "متھم",
    "متھود",
    "متھور",
    "متھوس",
    "متھيب",
    "متھيج",
    "مثائل",
    "مثاب",
    "مثابرة",
    "مثاث",
    "مثاعب",
    "مثاقب",
    "مثاقفة",
    "مثاقيل",
    "مثالب",
    "مثالة",
    "مثالج",
    "مثانة",
    "مثاو",
    "مثبوت",
    "مثر",
    "مثرد",
    "مثعب",
    "مثلبة",
    "مثلة",
    "مثلجة",
    "مثلما",
    "مثلوج",
    "مثلوجات",
    "مثلوم",
    "مثلى",
    "مثمون",
    "مثول",
    "مثيلتھا",
    "مثيله",
    "مج",
    "مجابھة",
    "مجاج",
    "مجاجة",
    "مجادة",
    "مجادف",
    "مجادلة",
    "مجاذب",
    "مجاذف",
    "مجار",
    "مجاراة",
    "مجارد",
    "مجارف",
    "مجارى",
    "مجاريح",
    "مجارير",
    "مجاريھا",
    "مجازا",
    "مجازر",
    "مجازف",
    "مجازى",
    "مجاعة",
    "مجالا",
    "مجالب",
    "مجالد",
    "مجالسة",
    "مجامر",
    "مجامع",
    "مجاملة",
    "مجان",
    "مجانة",
    "مجانسة",
    "مجانق",
    "مجانية",
    "مجانين",
    "مجاورة",
    "مجاوزة",
    "مجاھد",
    "مجاھدة",
    "مجاھر",
    "مجاھرة",
    "مجاھل",
    "مجاھيل",
    "مجبور",
    "مجبى",
    "مجترئ",
    "مجترة",
    "مجتزل",
    "مجتھد",
    "مجثى",
    "مجحف",
    "مجداف",
    "مجدال",
    "مجدب",
    "مجدر",
    "مجدرة",
    "مجدود",
    "مجدور",
    "مجذاف",
    "مجذوب",
    "مجذوم",
    "مجر",
    "مجراف",
    "مجرة",
    "مجردة",
    "مجرف",
    "مجرفة",
    "مجرمة",
    "مجروم",
    "مجريط",
    "مجز",
    "مجزر",
    "مجزرة",
    "مجزع",
    "مجزوم",
    "مجسة",
    "مجسمة",
    "مجففات",
    "مجل",
    "مجلبة",
    "مجلدة",
    "مجلسا",
    "مجلسه",
    "مجلفن",
    "مجلون",
    "مجلى",
    "مجم",
    "مجمرة",
    "مجمرك",
    "مجمعى",
    "مجن",
    "مجنبة",
    "مجنة",
    "مجنح",
    "مجنزر",
    "مجنونا",
    "مجنى",
    "مجنيزيوم",
    "مجوز",
    "مجوس",
    "مجوسي",
    "مجوسية",
    "مجون",
    "مجوني",
    "مجوھرات",
    "مجيؤك",
    "مجيئه",
    "مجيدي",
    "مجھار",
    "مجھد",
    "مجھر",
    "مجھرى",
    "مجھز",
    "مجھل",
    "مجھود",
    "مجھودا",
    "مجھوداته",
    "مجھوده",
    "مجھول",
    "مجھولية",
    "محا",
    "محابر",
    "محابس",
    "محابيب",
    "محاج",
    "محاجر",
    "محاجفة",
    "محاجم",
    "محاجن",
    "محادثة",
    "محاذرة",
    "محارة",
    "محارث",
    "محارق",
    "محارم",
    "محاسبة",
    "محاسبجي",
    "محاسن",
    "محاسنة",
    "محاش",
    "محاشش",
    "محاشم",
    "محاصة",
    "محاصد",
    "محاصرة",
    "محاصل",
    "محاضرة",
    "محاضن",
    "محافر",
    "محافظة",
    "محافل",
    "محاق",
    "محاقلة",
    "محاقن",
    "محال",
    "محالج",
    "محالفة",
    "محالق",
    "محام",
    "محامد",
    "محامل",
    "محاميون",
    "محان",
    "محاورة",
    "محاويج",
    "محايدة",
    "محبسة",
    "محبنا",
    "محبوبة",
    "محبوك",
    "محتار",
    "محتبك",
    "محتد",
    "محترس",
    "محتسب",
    "محتضن",
    "محتفل",
    "محتلة",
    "محتلم",
    "محتم",
    "محج",
    "محجة",
    "محجل",
    "محجن",
    "محدلة",
    "محذور",
    "محر",
    "محراب",
    "محراث",
    "محراك",
    "محرجات",
    "محررات",
    "محرقة",
    "محرور",
    "محروقات",
    "محز",
    "محزرة",
    "محزنات",
    "محزنة",
    "محزون",
    "محسبا",
    "محسة",
    "محسنات",
    "محسنة",
    "محسوبية",
    "محسوسة",
    "محش",
    "محشة",
    "محششة",
    "محشوات",
    "محشي",
    "محص",
    "محصد",
    "محصدة",
    "محضا",
    "محضن",
    "محضور",
    "محط",
    "محطمة",
    "محظورات",
    "محظية",
    "محفة",
    "محفظة",
    "محفل",
    "محفوظات",
    "محفوظة",
    "محق",
    "محقة",
    "محقنة",
    "محقوق",
    "محك",
    "محكة",
    "محكك",
    "محكي",
    "محلا",
    "محلالات",
    "محلب",
    "محلة",
    "محلج",
    "محلجة",
    "محلف",
    "محلق",
    "محليات",
    "محلية",
    "محم",
    "محمدة",
    "محمدل",
    "محمدم",
    "محمدية",
    "محمرة",
    "محمود",
    "محمولة",
    "محمولون",
    "محمى",
    "محمية",
    "محن",
    "محنة",
    "محنق",
    "محنى",
    "محو",
    "محوج",
    "محوضة",
    "محولة",
    "محيض",
    "محيل",
    "مخابئ",
    "مخابرة",
    "مخابز",
    "مخابير",
    "مخاتل",
    "مخاتير",
    "مخاخ",
    "مخاخة",
    "مخاد",
    "مخاديم",
    "مخارج",
    "مخارز",
    "مخارط",
    "مخارق",
    "مخاز",
    "مخازني",
    "مخاصر",
    "مخاصم",
    "مخاضة",
    "مخاط",
    "مخاطب",
    "مخاطبة",
    "مخاطرة",
    "مخافة",
    "مخافر",
    "مخالصة",
    "مخالط",
    "مخالطة",
    "مخالي",
    "مخاليط",
    "مخاليف",
    "مخاليق",
    "مخاو",
    "مخاوض",
    "مخاوف",
    "مخايل",
    "مخبآت",
    "مخبار",
    "مخبز",
    "مخبزة",
    "مختارا",
    "مختارات",
    "مختبئ",
    "مختتم",
    "مختصات",
    "مختف",
    "مختفت",
    "مختلطة",
    "مختم",
    "مخجول",
    "مخدع",
    "مخدم",
    "مخدومة",
    "مخدومية",
    "مخذفة",
    "مخر",
    "مخراز",
    "مخربشات",
    "مخرجات",
    "مخرز",
    "مخرفة",
    "مخرقة",
    "مخروط",
    "مخروم",
    "مخزاة",
    "مخزنجي",
    "مخزني",
    "مخزنية",
    "مخزية",
    "مخستك",
    "مخسر",
    "مخصبات",
    "مخصرة",
    "مخصصات",
    "مخض",
    "مخضرات",
    "مخط",
    "مخطر",
    "مخطرة",
    "مخطمة",
    "مخطوبة",
    "مخطوط",
    "مخفر",
    "مخففة",
    "مخفقة",
    "مخفور",
    "مخق",
    "مخلاة",
    "مخلاف",
    "مخلب",
    "مخلط",
    "مخلوفة",
    "مخم",
    "مخمض",
    "مخمل",
    "مخنق",
    "مخه",
    "مخور",
    "مخوط",
    "مخوف",
    "مخير",
    "مخيض",
    "مخيل",
    "مدائح",
    "مدائن",
    "مدابغ",
    "مداجاة",
    "مداح",
    "مداخل",
    "مداخلة",
    "مداخن",
    "مداخيل",
    "مداد",
    "مداراة",
    "مدارج",
    "مدارس",
    "مدارك",
    "مداره",
    "مداعبة",
    "مدافئ",
    "مدافع",
    "مدافعة",
    "مدافن",
    "مداق",
    "مداليات",
    "مداليه",
    "مداليون",
    "مدامع",
    "مداواة",
    "مداورة",
    "مداولة",
    "مداومة",
    "مداھمة",
    "مداھن",
    "مداھنة",
    "مدب",
    "مدبجات",
    "مدبغة",
    "مدثور",
    "مدجج",
    "مدح",
    "مدحاض",
    "مدحة",
    "مدحور",
    "مدحى",
    "مدخرة",
    "مدخول",
    "مدر",
    "مدراة",
    "مدرات",
    "مدرار",
    "مدراس",
    "مدرة",
    "مدرجه",
    "مدرس",
    "مدرعة",
    "مدرعون",
    "مدركات",
    "مدره",
    "مدرى",
    "مدع",
    "مدعبل",
    "مدعيات",
    "مدغل",
    "مدفأة",
    "مدفعي",
    "مدفعية",
    "مدفن",
    "مدفنة",
    "مدفوعات",
    "مدق",
    "مدقة",
    "مدقع",
    "مدل",
    "مدلس",
    "مدلسة",
    "مدلكة",
    "مدلن",
    "مدله",
    "مدلولات",
    "مدلھم",
    "مدماة",
    "مدمس",
    "مدمشق",
    "مدمع",
    "مدمك",
    "مدموغة",
    "مدموك",
    "مدنف",
    "مدنية",
    "مدود",
    "مدوس",
    "مدونات",
    "مدونة",
    "مديات",
    "مدية",
    "مديح",
    "مديد",
    "مديدة",
    "مديرية",
    "مديل",
    "مدينا",
    "مديون",
    "مديونية",
    "مدھش",
    "مدھم",
    "مدھن",
    "مدھوش",
    "مذار",
    "مذارة",
    "مذاكرة",
    "مذال",
    "مذاود",
    "مذاييع",
    "مذاھب",
    "مذبة",
    "مذبحة",
    "مذخر",
    "مذر",
    "مذرى",
    "مذعان",
    "مذق",
    "مذلة",
    "مذمة",
    "مذود",
    "مذياع",
    "مذيعة",
    "مذيق",
    "مذھب",
    "مذھبا",
    "مذھبه",
    "مذھبي",
    "مذھبية",
    "مذھوب",
    "مذھول",
    "مرأ",
    "مرأب",
    "مرأى",
    "مرؤ",
    "مرؤوس",
    "مرئ",
    "مراءاة",
    "مراءة",
    "مراآة",
    "مراؤون",
    "مرائب",
    "مرائر",
    "مراب",
    "مرابحة",
    "مرابض",
    "مرابع",
    "مراتب",
    "مراتع",
    "مراث",
    "مراجل",
    "مراجيح",
    "مراحض",
    "مراحم",
    "مراحى",
    "مراد",
    "مرادة",
    "مرادن",
    "مراده",
    "مرادى",
    "مرار",
    "مرارة",
    "مرارته",
    "مراريد",
    "مرازب",
    "مرازبة",
    "مرازيب",
    "مراس",
    "مراسة",
    "مراسح",
    "مراسلات",
    "مراسلة",
    "مراشيد",
    "مراضع",
    "مراضى",
    "مراع",
    "مراغم",
    "مرافئ",
    "مرافع",
    "مرافعة",
    "مرافقھم",
    "مراقد",
    "مراقص",
    "مراقصة",
    "مراقم",
    "مراقيش",
    "مراكبى",
    "مراكش",
    "مراكشي",
    "مراكن",
    "مراكنة",
    "مراكيب",
    "مرام",
    "مران",
    "مرانة",
    "مراوح",
    "مراوس",
    "مراوغة",
    "مراويس",
    "مراية",
    "مراھق",
    "مراھقة",
    "مراھم",
    "مراھنة",
    "مرب",
    "مربة",
    "مربد",
    "مربض",
    "مربعة",
    "مربوع",
    "مربون",
    "مرتاع",
    "مرتبه",
    "مرتخص",
    "مرتع",
    "مرتفق",
    "مرتقى",
    "مرتك",
    "مرتھن",
    "مرث",
    "مرثاء",
    "مرثاة",
    "مرثية",
    "مرجاة",
    "مرجان",
    "مرجانية",
    "مرجل",
    "مرجوحة",
    "مرجونة",
    "مرحاض",
    "مرحمة",
    "مرحوم",
    "مرحى",
    "مرخ",
    "مرداء",
    "مردة",
    "مردقوش",
    "مردن",
    "مرده",
    "مردى",
    "مرذول",
    "مرزاب",
    "مرزبان",
    "مرزبة",
    "مرزجوش",
    "مرزغ",
    "مرزنجوش",
    "مرزوق",
    "مرس",
    "مرسال",
    "مرسة",
    "مرسح",
    "مرسحي",
    "مرسلية",
    "مرسم",
    "مرسومة",
    "مرسيليا",
    "مرسين",
    "مرش",
    "مرشال",
    "مرشة",
    "مرشدة",
    "مرص",
    "مرصاد",
    "مرصود",
    "مرضاة",
    "مرضاح",
    "مرط",
    "مرطاء",
    "مرطبات",
    "مرطم",
    "مرع",
    "مرعة",
    "مرعى",
    "مرغبات",
    "مرغرين",
    "مرغمة",
    "مرفعة",
    "مرفقات",
    "مرفين",
    "مرقاة",
    "مرقاش",
    "مرقاق",
    "مرقب",
    "مرقبة",
    "مرقة",
    "مرقد",
    "مرقدات",
    "مرقص",
    "مرقعات",
    "مرقعة",
    "مرقعية",
    "مرقوق",
    "مركزا",
    "مركم",
    "مركن",
    "مركيز",
    "مرمة",
    "مرمتون",
    "مرمر",
    "مرمري",
    "مرمط",
    "مرمطون",
    "مرملة",
    "مرموق",
    "مرمى",
    "مرميس",
    "مرنان",
    "مرنحة",
    "مرنز",
    "مرو",
    "مروء",
    "مروءة",
    "مرواح",
    "مروة",
    "مروخ",
    "مرود",
    "مروق",
    "مروقي",
    "مرون",
    "مرويات",
    "مرى",
    "مريء",
    "مرياح",
    "مريخ",
    "مريرة",
    "مريسة",
    "مريسي",
    "مريط",
    "مريعة",
    "مريمية",
    "مريول",
    "مرھف",
    "مرھق",
    "مرھم",
    "مرھوب",
    "مرھون",
    "مرھونة",
    "مز",
    "مزائد",
    "مزابد",
    "مزابل",
    "مزاج",
    "مزاجه",
    "مزاحة",
    "مزاحم",
    "مزاحمة",
    "مزاد",
    "مزادة",
    "مزارعة",
    "مزاريب",
    "مزاريق",
    "مزاعم",
    "مزاغل",
    "مزافت",
    "مزالج",
    "مزالق",
    "مزاليج",
    "مزامير",
    "مزاوجة",
    "مزاود",
    "مزاول",
    "مزاولة",
    "مزايا",
    "مزايدة",
    "مزاھر",
    "مزبدة",
    "مزبلة",
    "مزة",
    "مزججات",
    "مزججة",
    "مزجر",
    "مزجى",
    "مزدان",
    "مزر",
    "مزراب",
    "مزراق",
    "مزرد",
    "مزرع",
    "مزرعاني",
    "مزرى",
    "مزع",
    "مزعة",
    "مزعجات",
    "مزغل",
    "مزفة",
    "مزفتة",
    "مزقة",
    "مزقه",
    "مزلج",
    "مزلق",
    "مزلقان",
    "مزلقة",
    "مزمز",
    "مزمع",
    "مزمور",
    "مزن",
    "مزوج",
    "مزوق",
    "مزولة",
    "مزية",
    "مزيتة",
    "مزھر",
    "مزھرية",
    "مزھو",
    "مسألة",
    "مسئلة",
    "مسئولية",
    "مساءة",
    "مساءلة",
    "مساؤكم",
    "مسائل",
    "مسابة",
    "مسابح",
    "مسابر",
    "مسابق",
    "مسابك",
    "مسابير",
    "مساتير",
    "مساجلة",
    "مساجين",
    "مساح",
    "مساحج",
    "مساحر",
    "مساحره",
    "مساحق",
    "مساحقة",
    "مساحل",
    "مساحن",
    "مساحيج",
    "مساخر",
    "مساخط",
    "مساخيط",
    "مسارب",
    "مسارج",
    "مسارح",
    "مساطب",
    "مساطر",
    "مساع",
    "مساعر",
    "مساعيد",
    "مساعير",
    "مساغ",
    "مساغا",
    "مسافة",
    "مساقاة",
    "مساقة",
    "مساقه",
    "مساك",
    "مساكب",
    "مساكة",
    "مسال",
    "مسالخ",
    "مسالك",
    "مسالمة",
    "مساليق",
    "مسام",
    "مسامحة",
    "مسامر",
    "مسامرة",
    "مسامط",
    "مسامع",
    "مسامعه",
    "مسامعھم",
    "مسامك",
    "مسامية",
    "مسامير",
    "مسان",
    "مسانھة",
    "مساو",
    "مساوف",
    "مساويك",
    "مسايفة",
    "مسايل",
    "مساھر",
    "مساھمة",
    "مسبار",
    "مسببات",
    "مسبت",
    "مسبح",
    "مسبحة",
    "مسبر",
    "مسبسل",
    "مسبك",
    "مسبول",
    "مسبوه",
    "مسة",
    "مست",
    "مستأنس",
    "مستأنفة",
    "مستأھل",
    "مستبضع",
    "مستجد",
    "مستحجر",
    "مستحدث",
    "مستحفز",
    "مستحفظ",
    "مستحكم",
    "مستحكمات",
    "مستحيلات",
    "مستخذ",
    "مستخذئ",
    "مستخرج",
    "مستخرجة",
    "مستخلص",
    "مستدع",
    "مستديم",
    "مستديمة",
    "مستراب",
    "مستراح",
    "مسترده",
    "مستريب",
    "مستريح",
    "مستسر",
    "مستسھل",
    "مستشرق",
    "مستضعف",
    "مستطاع",
    "مستطير",
    "مستظرف",
    "مستظرفة",
    "مستعرب",
    "مستعص",
    "مستعط",
    "مستعمرة",
    "مستعير",
    "مستغرب",
    "مستغلق",
    "مستفحل",
    "مستفيق",
    "مستقتل",
    "مستقطع",
    "مستكر",
    "مستكه",
    "مستكين",
    "مستلة",
    "مستمد",
    "مستمرة",
    "مستمسك",
    "مستملح",
    "مستنبت",
    "مستنبطات",
    "مستنزل",
    "مستنطق",
    "مستنير",
    "مستوحد",
    "مستوحش",
    "مستوحى",
    "مستوخم",
    "مستور",
    "مستوردات",
    "مستوصف",
    "مستوفز",
    "مستوقد",
    "مستيئس",
    "مستھام",
    "مستھتر",
    "مستھتك",
    "مستھدف",
    "مستھدم",
    "مستھزئ",
    "مستھل",
    "مستھلك",
    "مستھين",
    "مسجر",
    "مسجلة",
    "مسجى",
    "مسحاء",
    "مسحاة",
    "مسحاج",
    "مسحب",
    "مسحة",
    "مسحج",
    "مسحجة",
    "مسحل",
    "مسحنة",
    "مسحوج",
    "مسحى",
    "مسخ",
    "مسخة",
    "مسخرة",
    "مسخطة",
    "مسخنة",
    "مسخوط",
    "مسدسات",
    "مسدسة",
    "مسده",
    "مسر",
    "مسرة",
    "مسرجة",
    "مسرد",
    "مسروقات",
    "مسرى",
    "مسطاح",
    "مسطار",
    "مسطبة",
    "مسطر",
    "مسطرة",
    "مسطرين",
    "مسعار",
    "مسعد",
    "مسعط",
    "مسعود",
    "مسعى",
    "مسفى",
    "مسقام",
    "مسقعة",
    "مسقى",
    "مسكاتي",
    "مسكان",
    "مسكب",
    "مسكة",
    "مسكرات",
    "مسكنة",
    "مسكوبية",
    "مسكوني",
    "مسلاة",
    "مسلاخ",
    "مسلة",
    "مسلحة",
    "مسلخ",
    "مسلطح",
    "مسلفة",
    "مسلفت",
    "مسلك",
    "مسلكا",
    "مسلكي",
    "مسلوقة",
    "مسلوك",
    "مسم",
    "مسمار",
    "مسمدات",
    "مسمط",
    "مسمعة",
    "مسموحات",
    "مسموم",
    "مسميات",
    "مسمھم",
    "مسناة",
    "مسنم",
    "مسننة",
    "مسنيات",
    "مسو",
    "مسواك",
    "مسوح",
    "مسوخ",
    "مسورة",
    "مسوط",
    "مسوغ",
    "مسوقر",
    "مسوكر",
    "مسي",
    "مسيح",
    "مسيرة",
    "مسيك",
    "مسيل",
    "مسيلمة",
    "مسيو",
    "مسھب",
    "مسھر",
    "مسھل",
    "مش",
    "مشا",
    "مشائخ",
    "مشائيم",
    "مشابھة",
    "مشات",
    "مشاتل",
    "مشاتمة",
    "مشاجب",
    "مشاجرة",
    "مشاحة",
    "مشاحم",
    "مشاحنة",
    "مشادة",
    "مشارب",
    "مشارط",
    "مشارطة",
    "مشارقة",
    "مشاط",
    "مشاطرة",
    "مشاعر",
    "مشاعره",
    "مشاعل",
    "مشاعلي",
    "مشاعلية",
    "مشاغبة",
    "مشاغل",
    "مشاف",
    "مشافر",
    "مشافھة",
    "مشاق",
    "مشاقة",
    "مشاك",
    "مشاكسة",
    "مشاكلة",
    "مشال",
    "مشالح",
    "مشانق",
    "مشاو",
    "مشاور",
    "مشاورة",
    "مشاوير",
    "مشاية",
    "مشايخ",
    "مشايع",
    "مشايعة",
    "مشايم",
    "مشاھد",
    "مشاھدات",
    "مشاھدة",
    "مشاھرة",
    "مشاھير",
    "مشبوب",
    "مشتاع",
    "مشتبك",
    "مشتبه",
    "مشتر",
    "مشتريات",
    "مشتك",
    "مشتل",
    "مشتمل",
    "مشتملات",
    "مشته",
    "مشتى",
    "مشتھى",
    "مشتھيات",
    "مشج",
    "مشجب",
    "مشح",
    "مشحة",
    "مشحذ",
    "مشحذة",
    "مشحر",
    "مشحري",
    "مشحم",
    "مشحمة",
    "مشحونات",
    "مشخخة",
    "مشخصات",
    "مشخصة",
    "مشد",
    "مشددة",
    "مشدوه",
    "مشربة",
    "مشربية",
    "مشرحة",
    "مشرط",
    "مشرقي",
    "مشروعا",
    "مششخن",
    "مشطي",
    "مشعال",
    "مشعة",
    "مشعراني",
    "مشعلة",
    "مشعوذة",
    "مشعور",
    "مشغوف",
    "مشغولية",
    "مشف",
    "مشفوع",
    "مشق",
    "مشقة",
    "مشكاة",
    "مشكاوات",
    "مشكو",
    "مشلح",
    "مشلوز",
    "مشمخر",
    "مشمر",
    "مشمش",
    "مشملا",
    "مشملة",
    "مشمولات",
    "مشنة",
    "مشنقة",
    "مشه",
    "مشو",
    "مشوار",
    "مشواه",
    "مشورة",
    "مشورته",
    "مشوم",
    "مشيئة",
    "مشيب",
    "مشيتة",
    "مشيته",
    "مشيج",
    "مشيخة",
    "مشير",
    "مشيق",
    "مشيم",
    "مشيمة",
    "مشھد",
    "مشھر",
    "مشھود",
    "مشھور",
    "مشھيات",
    "مص",
    "مصائب",
    "مصابرة",
    "مصابيح",
    "مصاحف",
    "مصادرة",
    "مصادمة",
    "مصاراف",
    "مصارمة",
    "مصاريع",
    "مصارين",
    "مصاصة",
    "مصاطب",
    "مصاعب",
    "مصاعد",
    "مصاف",
    "مصافاة",
    "مصاقبة",
    "مصاقع",
    "مصاقل",
    "مصالحة",
    "مصايد",
    "مصاير",
    "مصايف",
    "مصاھر",
    "مصاھرة",
    "مصب",
    "مصبرات",
    "مصبع",
    "مصبغة",
    "مصبنة",
    "مصبه",
    "مصبوبات",
    "مصة",
    "مصح",
    "مصحة",
    "مصحف",
    "مصحون",
    "مصدأ",
    "مصداق",
    "مصدقة",
    "مصدور",
    "مصدوع",
    "مصراع",
    "مصراعيه",
    "مصران",
    "مصرع",
    "مصروع",
    "مصرى",
    "مصري",
    "مصرية",
    "مصطاف",
    "مصطبة",
    "مصطخب",
    "مصطفى",
    "مصطكاء",
    "مصطكى",
    "مصطلى",
    "مصطول",
    "مصعد",
    "مصعدة",
    "مصغ",
    "مصف",
    "مصفح",
    "مصفحة",
    "مصقع",
    "مصقلة",
    "مصلحة",
    "مصلحي",
    "مصلطح",
    "مصمت",
    "مصمص",
    "مصنعية",
    "مصنوعات",
    "مصوات",
    "مصوح",
    "مصوغات",
    "مصول",
    "مصيب",
    "مصير",
    "مصيرھا",
    "مصيص",
    "مصيف",
    "مصھر",
    "مض",
    "مضائق",
    "مضابط",
    "مضاجع",
    "مضادات",
    "مضاربة",
    "مضارع",
    "مضارعة",
    "مضاض",
    "مضاضة",
    "مضاغة",
    "مضاغط",
    "مضافا",
    "مضامير",
    "مضامين",
    "مضانك",
    "مضاه",
    "مضايقة",
    "مضاھاة",
    "مضبطة",
    "مضجع",
    "مضجعه",
    "مضحكة",
    "مضخة",
    "مضراب",
    "مضربة",
    "مضرة",
    "مضض",
    "مضطجع",
    "مضطر",
    "مضطرم",
    "مضطھد",
    "مضلة",
    "مضمحل",
    "مضمحلة",
    "مضمدة",
    "مضمر",
    "مضمض",
    "مضموم",
    "مضنك",
    "مضنى",
    "مضور",
    "مضي",
    "مضياع",
    "مضيض",
    "مضيعة",
    "مضيفة",
    "مط",
    "مطأطأ",
    "مطأطئ",
    "مطا",
    "مطائل",
    "مطابخ",
    "مطابع",
    "مطاحن",
    "مطارة",
    "مطارح",
    "مطارنة",
    "مطارين",
    "مطاعمه",
    "مطاعن",
    "مطاق",
    "مطالبة",
    "مطالعة",
    "مطاليب",
    "مطامح",
    "مطامير",
    "مطاو",
    "مطاوى",
    "مطاويه",
    "مطايا",
    "مطايب",
    "مطايبة",
    "مطاھر",
    "مطبعة",
    "مطبعجي",
    "مطبعية",
    "مطحنة",
    "مطحول",
    "مطراق",
    "مطران",
    "مطرانية",
    "مطربة",
    "مطربش",
    "مطرة",
    "مطرت",
    "مطرح",
    "مطردة",
    "مطرش",
    "مطرف",
    "مطرق",
    "مطرن",
    "مطرنة",
    "مطرنية",
    "مطعوم",
    "مطفحة",
    "مطق",
    "مطل",
    "مطلب",
    "مطلقا",
    "مطلمة",
    "مطلى",
    "مطماع",
    "مطمح",
    "مطمر",
    "مطمع",
    "مطمعة",
    "مطمور",
    "مطمورة",
    "مطو",
    "مطواة",
    "مطوة",
    "مطوحة",
    "مطوس",
    "مطوع",
    "مطوف",
    "مطوى",
    "مطي",
    "مطياف",
    "مطيب",
    "مطية",
    "مطير",
    "مطيلة",
    "مطھر",
    "مطھرة",
    "مطھم",
    "مطھى",
    "مظاريف",
    "مظال",
    "مظالم",
    "مظان",
    "مظانه",
    "مظاھر",
    "مظاھرة",
    "مظروف",
    "مظفر",
    "مظل",
    "مظلمة",
    "مظلى",
    "مظنة",
    "مظھر",
    "معاء",
    "معابد",
    "معابر",
    "معاتبة",
    "معاتيه",
    "معاجم",
    "معاجن",
    "معادل",
    "معاديد",
    "معاذر",
    "معاذير",
    "معار",
    "معارج",
    "معارف",
    "معارك",
    "معاركة",
    "معاريج",
    "معاريض",
    "معاز",
    "معازف",
    "معازق",
    "معازل",
    "معاش",
    "معاشرة",
    "معاص",
    "معاصم",
    "معاضدة",
    "معاضل",
    "معاطاة",
    "معاطس",
    "معافاة",
    "معاقبات",
    "معاقبة",
    "معاقد",
    "معاقل",
    "معال",
    "معالف",
    "معالى",
    "معالي",
    "معاليق",
    "معاليم",
    "معاليه",
    "معامع",
    "معاملات",
    "معاملته",
    "معان",
    "معاناة",
    "معاند",
    "معاندة",
    "معاھد",
    "معاھدة",
    "معبودة",
    "معتب",
    "معتر",
    "معترضة",
    "معتز",
    "معتزل",
    "معتزم",
    "معتقد",
    "معتمدية",
    "معتن",
    "معثر",
    "معثوث",
    "معج",
    "معجز",
    "معجزة",
    "معجلة",
    "معجم",
    "معجن",
    "معجنات",
    "معداد",
    "معددة",
    "معدنه",
    "معذار",
    "معر",
    "معراج",
    "معراض",
    "معرج",
    "معرص",
    "معرق",
    "معروق",
    "معز",
    "معزب",
    "معزة",
    "معزف",
    "معزقة",
    "معزوفة",
    "معس",
    "معسجد",
    "معسر",
    "معسرة",
    "معسلة",
    "معسور",
    "معشوق",
    "معشوقة",
    "معصري",
    "معصفر",
    "معصم",
    "معصية",
    "معض",
    "معضل",
    "معضلة",
    "معط",
    "معطاء",
    "معطس",
    "معظمة",
    "معظمه",
    "معقدا",
    "معقرب",
    "معقف",
    "معقودا",
    "معقودة",
    "معقوفان",
    "معكر",
    "معكرونه",
    "معل",
    "معلافة",
    "معلاق",
    "معلف",
    "معلقة",
    "معلمة",
    "معماة",
    "معمار",
    "معمعان",
    "معمعة",
    "معمعي",
    "معمور",
    "معميات",
    "معن",
    "معناه",
    "معنعن",
    "معنوبات",
    "معنوى",
    "معنوية",
    "معو",
    "معوج",
    "معوجه",
    "معود",
    "معى",
    "معية",
    "معير",
    "معيز",
    "معھد",
    "معھود",
    "مغار",
    "مغارب",
    "مغاربة",
    "مغارة",
    "مغارز",
    "مغارس",
    "مغارسة",
    "مغارسى",
    "مغارف",
    "مغارم",
    "مغاز",
    "مغازلة",
    "مغاسل",
    "مغاص",
    "مغاض",
    "مغاطس",
    "مغافر",
    "مغالبة",
    "مغالط",
    "مغالطة",
    "مغاليق",
    "مغامرة",
    "مغامز",
    "مغان",
    "مغاور",
    "مغاوير",
    "مغبة",
    "مغبوط",
    "مغبون",
    "مغتاب",
    "مغتاظ",
    "مغتم",
    "مغدودن",
    "مغدى",
    "مغر",
    "مغراة",
    "مغربة",
    "مغربي",
    "مغرة",
    "مغرز",
    "مغرس",
    "مغرض",
    "مغرفة",
    "مغريات",
    "مغزاة",
    "مغسل",
    "مغسلة",
    "مغص",
    "مغصوب",
    "مغضوب",
    "مغط",
    "مغطسة",
    "مغفر",
    "مغفل",
    "مغفلة",
    "مغل",
    "مغلاق",
    "مغلب",
    "مغلطة",
    "مغلظ",
    "مغلظة",
    "مغمز",
    "مغموس",
    "مغمى",
    "مغن",
    "مغناج",
    "مغنطيس",
    "مغنطيسي",
    "مغنطيسية",
    "مغنم",
    "مغنية",
    "مغنيسيا",
    "مغواة",
    "مغولي",
    "مغويات",
    "مغيبات",
    "مغيبة",
    "مغيث",
    "مفاتحة",
    "مفاتن",
    "مفاجآت",
    "مفاجأة",
    "مفاجئات",
    "مفاخر",
    "مفاخرة",
    "مفاداة",
    "مفادھا",
    "مفارج",
    "مفارح",
    "مفارز",
    "مفارق",
    "مفازة",
    "مفاسد",
    "مفاسق",
    "مفاصد",
    "مفاصل",
    "مفاضل",
    "مفاعيل",
    "مفاقس",
    "مفاكھة",
    "مفاليس",
    "مفاوز",
    "مفاوضة",
    "مفاھيم",
    "مفت",
    "مفتاحجي",
    "مفتح",
    "مفتشية",
    "مفتعل",
    "مفتن",
    "مفتوق",
    "مفتي",
    "مفجعات",
    "مفحشة",
    "مفخرة",
    "مفخم",
    "مفدي",
    "مفراة",
    "مفراك",
    "مفرج",
    "مفردات",
    "مفرزات",
    "مفرزة",
    "مفرشة",
    "مفرص",
    "مفرطح",
    "مفرع",
    "مفرغة",
    "مفرقعات",
    "مفرمة",
    "مفرمم",
    "مفروشات",
    "مفروضات",
    "مفروغ",
    "مفزعة",
    "مفسدة",
    "مفسقة",
    "مفصح",
    "مفصد",
    "مفصلة",
    "مفض",
    "مفضال",
    "مفضلة",
    "مفطح",
    "مفظع",
    "مفعم",
    "مفعول",
    "مفعوله",
    "مفك",
    "مفكرات",
    "مفكرة",
    "مفلح",
    "مفلفل",
    "مفلك",
    "مفلوج",
    "مفلور",
    "مفلوك",
    "مفلول",
    "مفوضية",
    "مفوف",
    "مفوه",
    "مفيضا",
    "مفيق",
    "مفھوم",
    "مقابح",
    "مقابر",
    "مقابيض",
    "مقاتة",
    "مقاتلة",
    "مقاتله",
    "مقاحيم",
    "مقادة",
    "مقاذر",
    "مقاذف",
    "مقاذيف",
    "مقار",
    "مقارعة",
    "مقارم",
    "مقاريض",
    "مقاساة",
    "مقاسم",
    "مقاسمة",
    "مقاشط",
    "مقاص",
    "مقاصة",
    "مقاصد",
    "مقاصف",
    "مقاصل",
    "مقاصير",
    "مقاصيص",
    "مقاضاة",
    "مقاطر",
    "مقاطف",
    "مقاطيع",
    "مقالب",
    "مقالة",
    "مقالد",
    "مقالع",
    "مقاليع",
    "مقامة",
    "مقامرة",
    "مقامه",
    "مقانب",
    "مقانق",
    "مقاه",
    "مقاود",
    "مقاوضة",
    "مقاولة",
    "مقايسة",
    "مقايضة",
    "مقبر",
    "مقبرة",
    "مقبري",
    "مقبع",
    "مقبوض",
    "مقتبسات",
    "مقتبل",
    "مقتتل",
    "مقتصر",
    "مقتضبات",
    "مقتضى",
    "مقتضيات",
    "مقتل",
    "مقتلة",
    "مقتنى",
    "مقتنيات",
    "مقحام",
    "مقدحة",
    "مقدرات",
    "مقدور",
    "مقدوره",
    "مقدونس",
    "مقدوني",
    "مقذاف",
    "مقذف",
    "مقذوفة",
    "مقرئ",
    "مقراء",
    "مقراض",
    "مقرح",
    "مقررات",
    "مقررة",
    "مقرش",
    "مقرقش",
    "مقرم",
    "مقرمش",
    "مقرود",
    "مقرون",
    "مقرى",
    "مقشة",
    "مقشط",
    "مقشطة",
    "مقص",
    "مقصب",
    "مقصد",
    "مقصف",
    "مقصل",
    "مقصلة",
    "مقصوصة",
    "مقضب",
    "مقطرات",
    "مقطرة",
    "مقطف",
    "مقطنة",
    "مقطورات",
    "مقطوعية",
    "مقع",
    "مقعدة",
    "مقفز",
    "مقلاة",
    "مقلاد",
    "مقلاع",
    "مقلقل",
    "مقلمة",
    "مقلية",
    "مقمة",
    "مقمرة",
    "مقمل",
    "مقمن",
    "مقنب",
    "مقنبلات",
    "مقنبلة",
    "مقند",
    "مقنطر",
    "مقنطرة",
    "مقنود",
    "مقو",
    "مقوتب",
    "مقور",
    "مقورة",
    "مقول",
    "مقومات",
    "مقويات",
    "مقيئات",
    "مقيظ",
    "مقيمي",
    "مقيمية",
    "مقينة",
    "مقھاة",
    "مقھاية",
    "مقھى",
    "مكائن",
    "مكابر",
    "مكابرة",
    "مكابس",
    "مكابيس",
    "مكاتبة",
    "مكاتل",
    "مكاتيب",
    "مكاحل",
    "مكار",
    "مكارم",
    "مكاره",
    "مكاريب",
    "مكاس",
    "مكاسب",
    "مكاسدة",
    "مكاشري",
    "مكافاة",
    "مكافيف",
    "مكاكيك",
    "مكالمة",
    "مكامن",
    "مكانته",
    "مكانس",
    "مكانية",
    "مكاو",
    "مكايد",
    "مكايل",
    "مكاييل",
    "مكب",
    "مكباس",
    "مكبتل",
    "مكبح",
    "مكبس",
    "مكبوس",
    "مكبوسات",
    "مكتاب",
    "مكتبة",
    "مكتتب",
    "مكتر",
    "مكترب",
    "مكتشفات",
    "مكتف",
    "مكتنف",
    "مكتوف",
    "مكثار",
    "مكحال",
    "مكحل",
    "مكحلة",
    "مكدام",
    "مكدود",
    "مكدوني",
    "مكدونيا",
    "مكذا",
    "مكرة",
    "مكررونه",
    "مكرش",
    "مكروب",
    "مكروھة",
    "مكرى",
    "مكرھة",
    "مكس",
    "مكسال",
    "مكسب",
    "مكسبة",
    "مكسح",
    "مكسحة",
    "مكسرات",
    "مكسم",
    "مكشط",
    "مكعبة",
    "مكعبر",
    "مكفت",
    "مكفھر",
    "مكلفة",
    "مكلكل",
    "مكلوب",
    "مكمدة",
    "مكمن",
    "مكمور",
    "مكن",
    "مكناء",
    "مكناس",
    "مكناسة",
    "مكنب",
    "مكنة",
    "مكنسة",
    "مكنى",
    "مكواة",
    "مكوث",
    "مكوجي",
    "مكورة",
    "مكوس",
    "مكوك",
    "مكوه",
    "مكوى",
    "مكى",
    "مكيال",
    "مكيدة",
    "مكيساتي",
    "مكيفات",
    "مكيفة",
    "مكيل",
    "مكيلة",
    "مكينا",
    "مكھرب",
    "مكھن",
    "ملآن",
    "ملآنة",
    "ملأة",
    "ملأك",
    "ملأمة",
    "ملؤه",
    "ملا",
    "ملاء",
    "ملاءة",
    "ملائك",
    "ملابسات",
    "ملابسة",
    "ملابسه",
    "ملاحة",
    "ملاحدة",
    "ملاحف",
    "ملاحق",
    "ملاحقة",
    "ملاحم",
    "ملاذ",
    "ملازمة",
    "ملاسة",
    "ملاشاة",
    "ملاصقة",
    "ملاط",
    "ملاطفة",
    "ملاطيس",
    "ملاعيب",
    "ملاعين",
    "ملافاة",
    "ملاقاة",
    "ملاقط",
    "ملاكي",
    "ملال",
    "ملالة",
    "ملاليم",
    "ملامة",
    "ملامسة",
    "ملاه",
    "ملاو",
    "ملاوص",
    "ملاوع",
    "ملاوق",
    "ملاوم",
    "ملايا",
    "ملاية",
    "ملاينة",
    "ملايو",
    "ملايين",
    "ملبن",
    "ملبنة",
    "ملتاث",
    "ملتاح",
    "ملتجئ",
    "ملتح",
    "ملتحف",
    "ملتحمة",
    "ملتفت",
    "ملتقيات",
    "ملتوى",
    "ملتھب",
    "ملتھبة",
    "ملثم",
    "ملج",
    "ملجلج",
    "ملجم",
    "ملجوم",
    "ملحاح",
    "ملحس",
    "ملحف",
    "ملحفة",
    "ملحقة",
    "ملحمة",
    "ملحوس",
    "ملحوظة",
    "ملحون",
    "ملحي",
    "ملخ",
    "ملخلخ",
    "ملد",
    "ملداء",
    "ملذة",
    "ملزز",
    "ملزمة",
    "ملزوم",
    "ملزومية",
    "ملساء",
    "ملسون",
    "ملص",
    "ملضوم",
    "ملط",
    "ملطاس",
    "ملطفات",
    "ملطم",
    "ملعبة",
    "ملغاة",
    "ملغز",
    "ملفاف",
    "ملفت",
    "ملفع",
    "ملق",
    "ملقط",
    "ملقم",
    "ملقى",
    "ملقيات",
    "ملكاء",
    "ملكمة",
    "ملكوتي",
    "ملل",
    "مللأى",
    "ملمس",
    "ملمسي",
    "ململ",
    "ململة",
    "ململمة",
    "ملموسات",
    "ملموم",
    "ملنخوليا",
    "مله",
    "ملو",
    "ملوة",
    "ملوح",
    "ملوخية",
    "ملوسة",
    "ملوط",
    "ملوق",
    "ملول",
    "ملوى",
    "ملى",
    "ملي",
    "مليار",
    "ملية",
    "مليجرام",
    "مليح",
    "مليحة",
    "مليخ",
    "مليص",
    "مليط",
    "مليكة",
    "مليما",
    "مليمتر",
    "ملينات",
    "ملھاة",
    "ملھم",
    "ملھوف",
    "ملھى",
    "مماثلة",
    "مماحكة",
    "مماخض",
    "مماذق",
    "ممارق",
    "مماس",
    "مماسة",
    "مماسح",
    "مماش",
    "مماطر",
    "مماطلة",
    "ممالأة",
    "ممالئ",
    "ممالح",
    "ممالك",
    "مماليك",
    "ممانعة",
    "مماھد",
    "ممتعض",
    "ممثلية",
    "ممجمج",
    "ممحاة",
    "ممحل",
    "ممخضة",
    "ممدد",
    "ممراح",
    "ممراض",
    "ممراع",
    "ممرضة",
    "ممرن",
    "ممسح",
    "ممشوق",
    "ممص",
    "ممصوص",
    "ممض",
    "ممضى",
    "ممطرة",
    "ممعود",
    "ممغطس",
    "ممقتع",
    "ممكنات",
    "مملحة",
    "مملقة",
    "مملك",
    "مملوح",
    "مملول",
    "ممنوعية",
    "ممنونية",
    "مموج",
    "مميتة",
    "ممھد",
    "منأى",
    "منائر",
    "مناب",
    "منابت",
    "منابر",
    "منابع",
    "منابه",
    "منابير",
    "مناج",
    "مناجذ",
    "مناجر",
    "مناجزة",
    "مناجل",
    "مناح",
    "مناحة",
    "مناحت",
    "مناحس",
    "مناحل",
    "مناحي",
    "مناخ",
    "مناخر",
    "مناخس",
    "مناخل",
    "مناخير",
    "مناخيس",
    "مناد",
    "مناداة",
    "منادب",
    "منادح",
    "منادر",
    "منادرات",
    "منادف",
    "منادم",
    "منادمة",
    "منادى",
    "منارة",
    "منازع",
    "منازعة",
    "منازلة",
    "منازه",
    "مناسج",
    "مناسر",
    "مناسف",
    "مناسك",
    "مناسم",
    "مناسيب",
    "مناشدة",
    "مناشف",
    "مناشير",
    "مناصر",
    "مناصرة",
    "مناضح",
    "مناضد",
    "مناط",
    "مناطحة",
    "مناطقة",
    "مناطيد",
    "مناظير",
    "مناعة",
    "مناعم",
    "مناف",
    "منافخ",
    "منافذ",
    "منافسة",
    "منافض",
    "منافقة",
    "منافيخ",
    "مناقب",
    "مناقشة",
    "مناقصة",
    "مناقضة",
    "مناقع",
    "مناقل",
    "مناقيد",
    "مناقيش",
    "مناكب",
    "مناكح",
    "مناكدة",
    "مناكش",
    "مناكيد",
    "مناكيش",
    "منال",
    "منامة",
    "منان",
    "مناو",
    "مناوأة",
    "مناوب",
    "مناوبة",
    "مناوشة",
    "مناوط",
    "مناولة",
    "مناويش",
    "مناويشي",
    "منايا",
    "مناھج",
    "مناھضة",
    "مناھيج",
    "منبار",
    "منبسق",
    "منبض",
    "منبع",
    "منبعج",
    "منبھة",
    "منة",
    "منتاش",
    "منتجات",
    "منتحر",
    "منتخبات",
    "منتدبة",
    "منتدح",
    "منتزه",
    "منتسب",
    "منتشب",
    "منتصت",
    "منتفع",
    "منتقلة",
    "منتم",
    "منتمى",
    "منته",
    "منتوج",
    "منتول",
    "منتھاه",
    "منتھى",
    "منثصور",
    "منثلم",
    "منج",
    "منجاة",
    "منجر",
    "منجزم",
    "منجلة",
    "منجمد",
    "منجنيق",
    "منجو",
    "منجور",
    "منجى",
    "منحاز",
    "منحبس",
    "منحة",
    "منحت",
    "منحر",
    "منحف",
    "منحنيات",
    "منحور",
    "منحوف",
    "منحى",
    "منخار",
    "منخاس",
    "منخر",
    "منخس",
    "منخل",
    "منخوب",
    "مندالة",
    "مندب",
    "مندرة",
    "مندف",
    "مندم",
    "مندوبية",
    "مندوحة",
    "مندوف",
    "مندى",
    "مندية",
    "مندھش",
    "منذرة",
    "منذھل",
    "منزع",
    "منزله",
    "منزلي",
    "منزلية",
    "منزو",
    "منزوعة",
    "منزوف",
    "منزول",
    "منزولي",
    "منزھة",
    "منسأة",
    "منسج",
    "منسحق",
    "منسر",
    "منسرح",
    "منسرق",
    "منسف",
    "منسفة",
    "منسلخ",
    "منسم",
    "منسوبية",
    "منسوجات",
    "منسيات",
    "منشأة",
    "منشئ",
    "منشع",
    "منشفة",
    "منشوبية",
    "منشود",
    "منشودة",
    "منصبه",
    "منصرف",
    "منصرفھم",
    "منصرم",
    "منصور",
    "منصون",
    "منضح",
    "منضحة",
    "منضد",
    "منضمة",
    "منطاد",
    "منطر",
    "منطو",
    "منطوفلة",
    "منطيق",
    "منظرة",
    "منظورة",
    "منظومة",
    "منعاء",
    "منعاة",
    "منعام",
    "منعة",
    "منعطف",
    "منعقد",
    "منعقف",
    "منعقفين",
    "منعكسة",
    "منعن",
    "منعه",
    "منعوت",
    "منعى",
    "منغاظ",
    "منغص",
    "منغنيس",
    "منغوم",
    "منفاخ",
    "منفاق",
    "منفجرة",
    "منفحة",
    "منفخ",
    "منفرجة",
    "منفسح",
    "منفضة",
    "منفى",
    "منفيلا",
    "منقاد",
    "منقاش",
    "منقبة",
    "منقش",
    "منقضة",
    "منقل",
    "منقلة",
    "منقوط",
    "منقوطة",
    "منقوع",
    "منقولات",
    "منقولة",
    "منكاش",
    "منكبه",
    "منكرات",
    "منكس",
    "منكش",
    "منكص",
    "منكود",
    "منكوس",
    "منلوج",
    "منمر",
    "منمنمة",
    "منمول",
    "منن",
    "مننلته",
    "منو",
    "منوال",
    "منوالك",
    "منواله",
    "منوب",
    "منوط",
    "منوفي",
    "منول",
    "منولوج",
    "منولوجات",
    "منومتر",
    "منون",
    "منى",
    "منية",
    "منيص",
    "منيف",
    "منھا",
    "منھاج",
    "منھج",
    "منھك",
    "منھل",
    "منھم",
    "منھمك",
    "منھوم",
    "منھي",
    "موء",
    "موئل",
    "موائد",
    "موات",
    "مواثبة",
    "مواثر",
    "مواثق",
    "مواج",
    "مواجل",
    "مواجن",
    "مواجير",
    "مواجھة",
    "مواخير",
    "موار",
    "موارب",
    "مواربة",
    "موارنة",
    "مواريث",
    "مواز",
    "موازنة",
    "موازين",
    "مواس",
    "مواساة",
    "مواش",
    "مواشك",
    "مواشير",
    "مواصفة",
    "مواصلة",
    "مواض",
    "مواضع",
    "مواضعات",
    "مواضيع",
    "مواطأة",
    "مواظب",
    "مواظبة",
    "مواعد",
    "مواعدة",
    "مواعز",
    "مواعظ",
    "مواعين",
    "موافاة",
    "مواقت",
    "مواقف",
    "مواكبة",
    "مواكيك",
    "موال",
    "موالج",
    "موالح",
    "موالد",
    "موالسة",
    "مواليا",
    "موالية",
    "مواليد",
    "موام",
    "موان",
    "موانئ",
    "موانيء",
    "مواويل",
    "مواھب",
    "موبر",
    "موبق",
    "موبقة",
    "موبوء",
    "موبيليات",
    "موتان",
    "موتة",
    "موتور",
    "موتوسيكل",
    "موتى",
    "موجد",
    "موجدة",
    "موجل",
    "موجوب",
    "موجوع",
    "موح",
    "موحى",
    "موحيات",
    "مودتھا",
    "مودل",
    "موده",
    "موراتزم",
    "موراني",
    "مورث",
    "مورد",
    "موردة",
    "مورفين",
    "مورم",
    "موروب",
    "موروط",
    "مورينة",
    "موز",
    "موسد",
    "موسطردة",
    "موسوس",
    "موسوعة",
    "موسوم",
    "موسوي",
    "موسيقار",
    "موشح",
    "موشحة",
    "موشور",
    "موصول",
    "موصولة",
    "موضعه",
    "موضه",
    "موضوعا",
    "موضوعية",
    "موطأ",
    "موطد",
    "موعودة",
    "موعوك",
    "موغل",
    "موف",
    "موفور",
    "موق",
    "موقت",
    "موقتة",
    "موقعة",
    "موقعه",
    "موقفا",
    "موقفه",
    "موقن",
    "موقود",
    "موقوذ",
    "موقوف",
    "موقيت",
    "موكد",
    "موكل",
    "موكم",
    "مولاة",
    "مولاى",
    "مولج",
    "مولودا",
    "مولوى",
    "موم",
    "موماء",
    "موماة",
    "مومس",
    "مومسة",
    "مومياء",
    "مومية",
    "مونة",
    "مونق",
    "مونيخ",
    "موه",
    "موھبة",
    "موھن",
    "موھوب",
    "موھوم",
    "ميؤوس",
    "مياتم",
    "مياثر",
    "ميار",
    "ميازيب",
    "ميازيت",
    "مياس",
    "مياسر",
    "مياسم",
    "مياسير",
    "مياط",
    "ميامن",
    "ميان",
    "مياومة",
    "ميتم",
    "ميتوى",
    "ميثرة",
    "ميجار",
    "ميح",
    "ميحار",
    "ميحوحة",
    "ميداء",
    "ميدة",
    "ميدعة",
    "ميدى",
    "ميرآلاى",
    "ميرة",
    "ميرلواء",
    "ميروق",
    "ميرون",
    "ميري",
    "ميزاب",
    "ميزانين",
    "ميزة",
    "ميسان",
    "ميسر",
    "ميسم",
    "ميسور",
    "ميضأة",
    "ميضاءة",
    "ميط",
    "ميطان",
    "ميطدة",
    "ميع",
    "ميعاده",
    "ميعاس",
    "ميعة",
    "ميقان",
    "ميقعة",
    "ميكا",
    "ميكاني",
    "ميكروب",
    "ميكروفون",
    "ميكعة",
    "ميلادي",
    "ميلادية",
    "ميمن",
    "ميمنة",
    "مينة",
    "ميوعة",
    "ميول",
    "ميون",
    "مھاب",
    "مھابة",
    "مھابط",
    "مھابل",
    "مھاة",
    "مھاترة",
    "مھاجر",
    "مھاجرة",
    "مھاجع",
    "مھاجم",
    "مھاجمة",
    "مھاجنة",
    "مھاجين",
    "مھاد",
    "مھادنة",
    "مھار",
    "مھارب",
    "مھارة",
    "مھارق",
    "مھاريس",
    "مھازل",
    "مھازيل",
    "مھالك",
    "مھام",
    "مھامز",
    "مھاميز",
    "مھان",
    "مھانة",
    "مھاو",
    "مھاودة",
    "مھايأة",
    "مھايع",
    "مھب",
    "مھبط",
    "مھبل",
    "مھبلي",
    "مھبوط",
    "مھبول",
    "مھتاج",
    "مھتد",
    "مھتر",
    "مھترئ",
    "مھتز",
    "مھتم",
    "مھتمات",
    "مھج",
    "مھجات",
    "مھجة",
    "مھجتھا",
    "مھجر",
    "مھجع",
    "مھجور",
    "مھد",
    "مھدة",
    "مھدد",
    "مھدر",
    "مھدل",
    "مھدم",
    "مھده",
    "مھدود",
    "مھدوم",
    "مھدي",
    "مھذب",
    "مھر",
    "مھرات",
    "مھراس",
    "مھراق",
    "مھرب",
    "مھرة",
    "مھرج",
    "مھرجان",
    "مھردار",
    "مھرق",
    "مھرقان",
    "مھرم",
    "مھرمة",
    "مھروش",
    "مھرول",
    "مھزأة",
    "مھزة",
    "مھزلة",
    "مھزول",
    "مھشم",
    "مھضوم",
    "مھفة",
    "مھفوت",
    "مھفوف",
    "مھفھف",
    "مھك",
    "مھكة",
    "مھل",
    "مھلا",
    "مھلبية",
    "مھلة",
    "مھلك",
    "مھلكة",
    "مھلل",
    "مھلھل",
    "مھم",
    "مھما",
    "مھمات",
    "مھماز",
    "مھمة",
    "مھمز",
    "مھمل",
    "مھملة",
    "مھموم",
    "مھن",
    "مھنئ",
    "مھناء",
    "مھنة",
    "مھند",
    "مھندس",
    "مھندم",
    "مھني",
    "مھواة",
    "مھوات",
    "مھوب",
    "مھود",
    "مھور",
    "مھوس",
    "مھوش",
    "مھول",
    "مھووس",
    "مھوى",
    "مھيأ",
    "مھيات",
    "مھيب",
    "مھيج",
    "مھيض",
    "مھيع",
    "مھيلة",
    "مھيمن",
    "مھين",
    "نأم",
    "نأمة",
    "نأى",
    "نأي",
    "نؤوم",
    "نؤى",
    "نئ",
    "ناء",
    "ناءوس",
    "نائبة",
    "نائحات",
    "نائحة",
    "نائرة",
    "نائل",
    "نابتة",
    "نابذ",
    "نابغ",
    "نابغة",
    "نابل",
    "نابلس",
    "نابلي",
    "نابه",
    "نات",
    "ناتئة",
    "ناجذ",
    "ناجذيه",
    "ناجز",
    "ناجع",
    "ناحل",
    "ناخر",
    "ناد",
    "نادبة",
    "نادرة",
    "نادل",
    "ناذر",
    "نارجيل",
    "نارجيلة",
    "ناردين",
    "نارنج",
    "نارى",
    "نازح",
    "نازع",
    "نازعة",
    "نازلة",
    "نازي",
    "نازية",
    "ناسخة",
    "ناسف",
    "ناسفة",
    "ناسك",
    "ناسوت",
    "ناسور",
    "ناشزة",
    "ناشف",
    "ناشفة",
    "ناص",
    "ناصب",
    "ناصبه",
    "ناصري",
    "ناصع",
    "ناصعة",
    "ناصف",
    "ناصل",
    "ناصور",
    "ناصيته",
    "ناض",
    "ناضب",
    "ناضر",
    "ناط",
    "ناطح",
    "ناطر",
    "ناطقة",
    "ناطور",
    "ناطورة",
    "ناظر",
    "ناظريه",
    "ناظم",
    "ناعل",
    "ناعور",
    "ناعورة",
    "ناعوظ",
    "نافجة",
    "نافد",
    "نافدة",
    "نافذا",
    "نافعة",
    "نافق",
    "نافورة",
    "ناقات",
    "ناقلات",
    "ناقلية",
    "ناقم",
    "ناك",
    "ناكث",
    "نال",
    "ناله",
    "نامة",
    "ناموس",
    "ناموسية",
    "نامية",
    "ناه",
    "ناوأه",
    "ناوله",
    "ناولون",
    "ناووس",
    "ناي",
    "ناھج",
    "ناھد",
    "ناھز",
    "ناھض",
    "ناھية",
    "ناھيك",
    "نب",
    "نبء",
    "نبأة",
    "نبا",
    "نبابيت",
    "نباريس",
    "نبال",
    "نبالة",
    "نباھة",
    "نبت",
    "نبذة",
    "نبر",
    "نبرات",
    "نبراس",
    "نبراسا",
    "نبز",
    "نبس",
    "نبسة",
    "نبش",
    "نبضان",
    "نبضة",
    "نبضه",
    "نبط",
    "نبطي",
    "نبع",
    "نبعان",
    "نبق",
    "نبكة",
    "نبل",
    "نبه",
    "نبو",
    "نبوء",
    "نبوءة",
    "نبوة",
    "نبوت",
    "نبوض",
    "نبوط",
    "نبوع",
    "نبوغ",
    "نبيح",
    "نبيش",
    "نبھاء",
    "نتء",
    "نتأ",
    "نتأة",
    "نتائجه",
    "نتج",
    "نتحة",
    "نتر",
    "نتروجين",
    "نتش",
    "نتع",
    "نتفة",
    "نتوع",
    "نتونة",
    "نتيف",
    "نثار",
    "نثريات",
    "نثرية",
    "نجاء",
    "نجاب",
    "نجابة",
    "نجاة",
    "نجاح",
    "نجاحا",
    "نجاد",
    "نجادة",
    "نجار",
    "نجارة",
    "نجاري",
    "نجاز",
    "نجاسة",
    "نجاشي",
    "نجاف",
    "نجام",
    "نجاوى",
    "نجباء",
    "نجبة",
    "نجح",
    "نجدة",
    "نجدي",
    "نجذ",
    "نجر",
    "نجز",
    "نجع",
    "نجعة",
    "نجف",
    "نجفة",
    "نجل",
    "نجلاء",
    "نجو",
    "نجوة",
    "نجوع",
    "نجوما",
    "نجي",
    "نجيب",
    "نجيح",
    "نجيس",
    "نجيع",
    "نجيل",
    "نجيمة",
    "نحا",
    "نحاة",
    "نحاتة",
    "نحارير",
    "نحاسة",
    "نحاف",
    "نحال",
    "نحالة",
    "نحام",
    "نحت",
    "نحر",
    "نحرير",
    "نحفاء",
    "نحل",
    "نحلى",
    "نحم",
    "نحمان",
    "نحنح",
    "نحوا",
    "نحور",
    "نحوس",
    "نحوسة",
    "نحول",
    "نحيب",
    "نحير",
    "نحيزة",
    "نحيم",
    "نخا",
    "نخاريب",
    "نخاسة",
    "نخاشيش",
    "نخاعة",
    "نخالة",
    "نخامة",
    "نخبة",
    "نخبه",
    "نخرب",
    "نخروب",
    "نخز",
    "نخس",
    "نخشوش",
    "نخع",
    "نخم",
    "نخو",
    "نخوة",
    "نخير",
    "ند",
    "ندا",
    "ندابة",
    "نداد",
    "ندارة",
    "نداف",
    "ندام",
    "ندامة",
    "ندامى",
    "نداوة",
    "ندبة",
    "ندح",
    "ندحة",
    "ندد",
    "ندداء",
    "ندر",
    "ندرة",
    "ندس",
    "ندف",
    "ندل",
    "ندم",
    "ندماء",
    "ندمان",
    "نده",
    "ندو",
    "ندوب",
    "ندوة",
    "ندود",
    "ندور",
    "ندورة",
    "ندى",
    "نديان",
    "نديد",
    "نديف",
    "نذال",
    "نذالة",
    "نذلاء",
    "نذور",
    "نذورات",
    "نذول",
    "نذولة",
    "نذيل",
    "نذھب",
    "نرابيج",
    "نرابيش",
    "نربيج",
    "نربيش",
    "نرجس",
    "نرجع",
    "نردين",
    "نرفزة",
    "نرنج",
    "نروج",
    "نرويج",
    "نزا",
    "نزازة",
    "نزال",
    "نزالة",
    "نزاه",
    "نزاھة",
    "نزة",
    "نزح",
    "نزر",
    "نزعة",
    "نزعته",
    "نزغة",
    "نزفة",
    "نزك",
    "نزلاء",
    "نزنز",
    "نزه",
    "نزو",
    "نزوان",
    "نزوة",
    "نزوح",
    "نزوز",
    "نزوع",
    "نزوق",
    "نزول",
    "نزولي",
    "نزيح",
    "نزير",
    "نزيز",
    "نزيع",
    "نزيف",
    "نزيل",
    "نزھاء",
    "نزھة",
    "نسء",
    "نسأ",
    "نسائم",
    "نسائيات",
    "نساب",
    "نساج",
    "نساجة",
    "نساخ",
    "نساخة",
    "نسارية",
    "نساسيف",
    "نساطرة",
    "نساف",
    "نسافة",
    "نساك",
    "نساكة",
    "نسالة",
    "نسام",
    "نسانس",
    "نسبية",
    "نستقر",
    "نسج",
    "نسجه",
    "نسخي",
    "نسطوري",
    "نسغ",
    "نسف",
    "نسقا",
    "نسكي",
    "نسل",
    "نسم",
    "نسمان",
    "نسمة",
    "نسو",
    "نسوان",
    "نسوج",
    "نسور",
    "نسورة",
    "نسول",
    "نسولة",
    "نسيئة",
    "نسيان",
    "نسيب",
    "نسيق",
    "نسيلة",
    "نسيم",
    "نش",
    "نشء",
    "نشأته",
    "نشؤ",
    "نشائد",
    "نشاب",
    "نشادر",
    "نشار",
    "نشاش",
    "نشاشيب",
    "نشافة",
    "نشال",
    "نشان",
    "نشانجي",
    "نشاوى",
    "نشب",
    "نشبة",
    "نشج",
    "نشد",
    "نشدان",
    "نشدة",
    "نشز",
    "نشطة",
    "نشع",
    "نشق",
    "نشل",
    "نشن",
    "نشنجي",
    "نشنش",
    "نشنكاه",
    "نشو",
    "نشوءا",
    "نشوان",
    "نشوب",
    "نشور",
    "نشوز",
    "نشوق",
    "نشوى",
    "نشويات",
    "نشي",
    "نشيج",
    "نشيد",
    "نشيش",
    "نصا",
    "نصائح",
    "نصابه",
    "نصابھا",
    "نصاح",
    "نصاحة",
    "نصار",
    "نصاعة",
    "نصاف",
    "نصال",
    "نصبة",
    "نصبوا",
    "نصة",
    "نصت",
    "نصحه",
    "نصراء",
    "نصراني",
    "نصرانية",
    "نصص",
    "نصع",
    "نصفة",
    "نصفي",
    "نصل",
    "نصمة",
    "نصوح",
    "نصور",
    "نصوع",
    "نصول",
    "نصيبه",
    "نصيح",
    "نصيحة",
    "نصيع",
    "نصيف",
    "نض",
    "نضا",
    "نضائد",
    "نضاحة",
    "نضار",
    "نضال",
    "نضالي",
    "نضب",
    "نضح",
    "نضد",
    "نضر",
    "نضف",
    "نضل",
    "نضناض",
    "نضو",
    "نضوب",
    "نضور",
    "نضيج",
    "نضيدة",
    "نضير",
    "نضيض",
    "نضيف",
    "نط",
    "نطا",
    "نطاح",
    "نطار",
    "نطارة",
    "نطاسي",
    "نطافة",
    "نطاقا",
    "نطة",
    "نطحة",
    "نطر",
    "نطراء",
    "نطرة",
    "نطرون",
    "نطس",
    "نطع",
    "نطف",
    "نطفان",
    "نطل",
    "نطنط",
    "نطوع",
    "نطوق",
    "نطول",
    "نطيح",
    "نظائر",
    "نظار",
    "نظاراتي",
    "نظارة",
    "نظارته",
    "نظاف",
    "نظافة",
    "نظامية",
    "نظراء",
    "نظراؤه",
    "نظرات",
    "نظره",
    "نظفاء",
    "نظلي",
    "نظما",
    "نظيرة",
    "نعائم",
    "نعاب",
    "نعاج",
    "نعار",
    "نعارة",
    "نعاس",
    "نعام",
    "نعامته",
    "نعب",
    "نعت",
    "نعرة",
    "نعسة",
    "نعشة",
    "نعظ",
    "نعق",
    "نعمان",
    "نعمى",
    "نعناع",
    "نعناعي",
    "نعنع",
    "نعوت",
    "نعوظ",
    "نعومة",
    "نعى",
    "نعي",
    "نعيان",
    "نعيب",
    "نعية",
    "نعير",
    "نعيق",
    "نغا",
    "نغاش",
    "نغاشة",
    "نغاشي",
    "نغبة",
    "نغبشة",
    "نغز",
    "نغش",
    "نغشان",
    "نغشة",
    "نغص",
    "نغم",
    "نغو",
    "نغولة",
    "نغى",
    "نغيل",
    "نف",
    "نفا",
    "نفاء",
    "نفائس",
    "نفائض",
    "نفاة",
    "نفاثة",
    "نفاثي",
    "نفاج",
    "نفاح",
    "نفاخ",
    "نفاخة",
    "نفار",
    "نفاس",
    "نفاسه",
    "نفاسية",
    "نفاش",
    "نفاض",
    "نفاضة",
    "نفاع",
    "نفاف",
    "نفاق",
    "نفال",
    "نفانخ",
    "نفانف",
    "نفاوة",
    "نفايات",
    "نفاية",
    "نفة",
    "نفث",
    "نفثات",
    "نفثة",
    "نفج",
    "نفجان",
    "نفح",
    "نفحان",
    "نفد",
    "نفدة",
    "نفرة",
    "نفساء",
    "نفسانية",
    "نفسة",
    "نفسى",
    "نفسھا",
    "نفش",
    "نفض",
    "نفضة",
    "نفضية",
    "نفطة",
    "نفطي",
    "نفل",
    "نفنف",
    "نفنوف",
    "نفو",
    "نفوج",
    "نفور",
    "نفوس",
    "نفوسنا",
    "نفوض",
    "نفوع",
    "نفوق",
    "نفول",
    "نفى",
    "نفير",
    "نفيسة",
    "نفيضة",
    "نفيي",
    "نقاء",
    "نقائب",
    "نقائص",
    "نقائض",
    "نقاب",
    "نقابية",
    "نقاد",
    "نقادة",
    "نقارية",
    "نقاز",
    "نقاشة",
    "نقاطة",
    "نقاع",
    "نقاعيات",
    "نقاق",
    "نقاقة",
    "نقاوة",
    "نقاية",
    "نقاھة",
    "نقباء",
    "نقدا",
    "نقدة",
    "نقدية",
    "نقذ",
    "نقرة",
    "نقرزان",
    "نقرس",
    "نقريس",
    "نقز",
    "نقزان",
    "نقزة",
    "نقصان",
    "نقض",
    "نقضه",
    "نقف",
    "نقلا",
    "نقلة",
    "نقليات",
    "نقلية",
    "نقم",
    "نقمات",
    "نقمة",
    "نقنق",
    "نقه",
    "نقو",
    "نقودا",
    "نقوش",
    "نقوط",
    "نقوع",
    "نقوه",
    "نقيب",
    "نقيبة",
    "نقير",
    "نقيصة",
    "نقيضة",
    "نقيضه",
    "نقيطة",
    "نقيع",
    "نقيق",
    "نقيل",
    "نقھة",
    "نكات",
    "نكاح",
    "نكاف",
    "نكاية",
    "نكت",
    "نكتة",
    "نكد",
    "نكراء",
    "نكرة",
    "نكز",
    "نكس",
    "نكسة",
    "نكش",
    "نكف",
    "نكفة",
    "نكفي",
    "نكل",
    "نكلة",
    "نكه",
    "نكور",
    "نكوص",
    "نكول",
    "نكى",
    "نكھة",
    "نماء",
    "نمائم",
    "نماة",
    "نمارة",
    "نمارسة",
    "نمارق",
    "نماط",
    "نمال",
    "نمام",
    "نمة",
    "نمر",
    "نمراء",
    "نمرة",
    "نمرسي",
    "نمرق",
    "نمرقة",
    "نمس",
    "نمسا",
    "نمش",
    "نمشاء",
    "نمل",
    "نملية",
    "نمنم",
    "نمنمة",
    "نموس",
    "نمي",
    "نمية",
    "نميمة",
    "نوء",
    "نوآن",
    "نوائب",
    "نوائح",
    "نوابغ",
    "نواتئ",
    "نواتي",
    "نواجذ",
    "نواجذه",
    "نواح",
    "نواحة",
    "نواد",
    "نوادب",
    "نوادر",
    "نوار",
    "نوارج",
    "نوازع",
    "نوازل",
    "نواس",
    "نواسر",
    "نواشز",
    "نواص",
    "نواصب",
    "نواصي",
    "نواصير",
    "نواصيه",
    "نواطح",
    "نواطر",
    "نواطير",
    "نواظر",
    "نواظم",
    "نواعر",
    "نوافج",
    "نوافر",
    "نوافس",
    "نوافل",
    "نوافير",
    "نوال",
    "نوالك",
    "نوالين",
    "نوام",
    "نواميس",
    "نواه",
    "نواوير",
    "نواويس",
    "نوايا",
    "نوبتجي",
    "نوبته",
    "نوبي",
    "نوة",
    "نوتي",
    "نوتية",
    "نوخ",
    "نود",
    "نودان",
    "نوراني",
    "نورانية",
    "نورج",
    "نورز",
    "نورستانيا",
    "نوروز",
    "نوري",
    "نوسان",
    "نوسر",
    "نوش",
    "نوشادر",
    "نوشة",
    "نوص",
    "نوط",
    "نوعه",
    "نوفرة",
    "نوفمبر",
    "نوق",
    "نوكيل",
    "نولون",
    "نومة",
    "نومه",
    "نومي",
    "نون",
    "نونة",
    "نوني",
    "نوه",
    "نووية",
    "نوى",
    "نويات",
    "نيء",
    "نيابة",
    "نيابى",
    "نيابي",
    "نيابية",
    "نياح",
    "نياحة",
    "نياشين",
    "نياط",
    "نيافة",
    "نياق",
    "نيام",
    "نيب",
    "نية",
    "نيتروجين",
    "نيته",
    "نيرة",
    "نيروز",
    "نيزيلاندا",
    "نيشان",
    "نيص",
    "نيط",
    "نيق",
    "نيلة",
    "نيلج",
    "نيلوفر",
    "نيلون",
    "نينان",
    "نينة",
    "نيوء",
    "نيوءة",
    "نيورالجيا",
    "نيوني",
    "نھا",
    "نھاء",
    "نھائي",
    "نھائيا",
    "نھائية",
    "نھاب",
    "نھار",
    "نھارا",
    "نھاري",
    "نھاريات",
    "نھاز",
    "نھاش",
    "نھاق",
    "نھاكة",
    "نھامة",
    "نھاية",
    "نھب",
    "نھبا",
    "نھبة",
    "نھبرة",
    "نھبى",
    "نھتز",
    "نھج",
    "نھجه",
    "نھد",
    "نھر",
    "نھري",
    "نھرية",
    "نھز",
    "نھزة",
    "نھش",
    "نھض",
    "نھضة",
    "نھضت",
    "نھق",
    "نھك",
    "نھكة",
    "نھل",
    "نھلة",
    "نھم",
    "نھمة",
    "نھنه",
    "نھو",
    "نھوج",
    "نھود",
    "نھور",
    "نھوض",
    "نھى",
    "نھي",
    "نھية",
    "نھيج",
    "نھير",
    "نھيق",
    "نھيم",
    "نھيي",
    "وآخرا",
    "وآونة",
    "وآھات",
    "وأد",
    "وأر",
    "وأشكالھم",
    "وأم",
    "وئام",
    "وئية",
    "وئيد",
    "وئيدا",
    "وابلا",
    "وابور",
    "وات",
    "واتتني",
    "واجبات",
    "واجبه",
    "واجد",
    "واجس",
    "واجف",
    "واجم",
    "واجھة",
    "واح",
    "واحة",
    "واحده",
    "واحرق",
    "واخرى",
    "واخز",
    "واخص",
    "واخيرا",
    "وادبارا",
    "وادك",
    "وادى",
    "واراه",
    "واربعين",
    "وارث",
    "واردات",
    "وارسو",
    "وارش",
    "وارف",
    "وارق",
    "وازبد",
    "وازع",
    "واستمرارا",
    "واسطة",
    "واسلات",
    "واسى",
    "واش",
    "واشنطون",
    "واشون",
    "واضرابه",
    "واطراف",
    "واع",
    "واعظ",
    "واغل",
    "واف",
    "وافاه",
    "وافد",
    "وافدة",
    "وافرادا",
    "واقعا",
    "واقعة",
    "واقعده",
    "واقعدھا",
    "واقفا",
    "واقل",
    "واقواق",
    "واكل",
    "والآجل",
    "والأعم",
    "والأعياد",
    "والأعيان",
    "والأنجد",
    "والإبرام",
    "والإخلاص",
    "والإدارة",
    "والإرادة",
    "والإساءة",
    "والإضافة",
    "والإعارة",
    "والا",
    "والابرام",
    "والاحسان",
    "والاحمر",
    "والاخرى",
    "والاستعداد",
    "والامر",
    "والبأساء",
    "والترقي",
    "والتسويغ",
    "والتعليم",
    "والتنباك",
    "والتى",
    "والثبور",
    "والجمال",
    "والجوھر",
    "والحالة",
    "والحديد",
    "والحرف",
    "والخاص",
    "والخلاصة",
    "والدانى",
    "والدانية",
    "والدفع",
    "والذراع",
    "والربط",
    "والرخيص",
    "والرم",
    "والزراعة",
    "والسلوك",
    "والشكوى",
    "والصنائع",
    "والضحايا",
    "والضرع",
    "والطاعة",
    "والطلب",
    "والطول",
    "والعام",
    "والعامة",
    "والعقد",
    "والعكس",
    "والغالب",
    "والغلب",
    "والفر",
    "والفضية",
    "والفعل",
    "والفينة",
    "والقبول",
    "والقدرة",
    "والقروية",
    "والكبراء",
    "والكليات",
    "والكنس",
    "والكيل",
    "واللأواء",
    "واللعب",
    "والمبنى",
    "والمتأخرون",
    "والمدر",
    "والمسبب",
    "والمشرقين",
    "والمشي",
    "والمعاد",
    "والمعلول",
    "والمعنويات",
    "والمغربان",
    "والمفسدة",
    "والمكاييل",
    "والملاحظ",
    "والنفس",
    "والنفيس",
    "والنقل",
    "والنمو",
    "والنواھي",
    "والنھي",
    "واله",
    "والوبر",
    "والوضيع",
    "والى",
    "واليابس",
    "واليكھا",
    "واليه",
    "والھيلمان",
    "واما",
    "وان",
    "وانتم",
    "وانفه",
    "وانى",
    "واه",
    "واو",
    "واياب",
    "وايابا",
    "واياك",
    "واياه",
    "واھا",
    "واھب",
    "واھمة",
    "واھن",
    "وبأ",
    "وبؤ",
    "وبئ",
    "وباء",
    "وباءة",
    "وبار",
    "وبارة",
    "وبالة",
    "وبجره",
    "وبحثا",
    "وبحرا",
    "وبحرية",
    "وبدءا",
    "وبر",
    "وبراء",
    "وبرح",
    "وبش",
    "وبق",
    "وبل",
    "وبور",
    "وبوق",
    "وبول",
    "وبيء",
    "وبيل",
    "وبينھم",
    "وتائر",
    "وتد",
    "وترا",
    "وترث",
    "وترحاله",
    "وتسلسل",
    "وتن",
    "وتى",
    "وتيرة",
    "وثء",
    "وثأ",
    "وثئ",
    "وثاءة",
    "وثاب",
    "وثار",
    "وثارة",
    "وثاقة",
    "وثب",
    "وثبا",
    "وثبان",
    "وثر",
    "وثق",
    "وثقي",
    "وثل",
    "وثن",
    "وثنية",
    "وثوء",
    "وثوب",
    "وثيب",
    "وثير",
    "وثيل",
    "وجائب",
    "وجار",
    "وجازة",
    "وجاع",
    "وجاق",
    "وجال",
    "وجاھة",
    "وجاھي",
    "وجب",
    "وجبان",
    "وجدان",
    "وجداني",
    "وجدانية",
    "وجر",
    "وجرة",
    "وجز",
    "وجس",
    "وجسان",
    "وجف",
    "وجق",
    "وجقات",
    "وجل",
    "وجلاء",
    "وجم",
    "وجملة",
    "وجوب",
    "وجوز",
    "وجوف",
    "وجوم",
    "وجوية",
    "وجيب",
    "وجيزة",
    "وجيع",
    "وجيعا",
    "وجيف",
    "وجيه",
    "وجيھة",
    "وجھا",
    "وجھاء",
    "وجھة",
    "وجھته",
    "وجھه",
    "وجھي",
    "وجھين",
    "وجھھا",
    "وحام",
    "وحامى",
    "وحدان",
    "وحداني",
    "وحدانية",
    "وحدب",
    "وحدھا",
    "وحشان",
    "وحشة",
    "وحف",
    "وحمى",
    "وحوح",
    "وحوش",
    "وحى",
    "وخارجية",
    "وخاز",
    "وخامة",
    "وخزان",
    "وخصوم",
    "وخط",
    "وخلافه",
    "وخم",
    "وخى",
    "وخي",
    "وخيم",
    "ودائع",
    "ودائق",
    "وداج",
    "وداد",
    "ودادي",
    "ودب",
    "ودبير",
    "ودج",
    "وددت",
    "ودر",
    "ودركات",
    "ودعه",
    "ودقة",
    "ودك",
    "ودما",
    "ودى",
    "وديان",
    "وديد",
    "وديعة",
    "وديقة",
    "وديك",
    "ودھا",
    "وذراعا",
    "وذھاب",
    "وذھابا",
    "وذھوب",
    "وراءھا",
    "وراب",
    "وراث",
    "وراثة",
    "وراثية",
    "وراد",
    "وراط",
    "وراعة",
    "وراق",
    "وراقة",
    "ورثاء",
    "وردان",
    "ورطة",
    "ورع",
    "ورف",
    "ورك",
    "ورل",
    "ورلان",
    "ورن",
    "ورنش",
    "ورنيش",
    "ورواحه",
    "وروار",
    "وروحا",
    "وروحاته",
    "ورود",
    "وروط",
    "وروف",
    "ورى",
    "وريد",
    "وريف",
    "ورھاء",
    "ورھبا",
    "وز",
    "وزائع",
    "وزارية",
    "وزال",
    "وزان",
    "وزب",
    "وزراء",
    "وزرة",
    "وزره",
    "وزنة",
    "وزني",
    "وزوب",
    "وزى",
    "وزير",
    "وزيعة",
    "وسائد",
    "وسائط",
    "وساد",
    "وساطته",
    "وساع",
    "وساعة",
    "وساوس",
    "وسد",
    "وسداد",
    "وسده",
    "وسطاني",
    "وسطنا",
    "وسطى",
    "وسطي",
    "وسطية",
    "وسعه",
    "وسعي",
    "وسكناته",
    "وسكي",
    "وسل",
    "وسلم",
    "وسم",
    "وسماء",
    "وسمه",
    "وسن",
    "وسنان",
    "وسنى",
    "وسواد",
    "وسواس",
    "وسوس",
    "وسوم",
    "وسى",
    "وسيطة",
    "وسيع",
    "وسيلة",
    "وسھلا",
    "وشأن",
    "وشأنه",
    "وشأني",
    "وشاء",
    "وشائج",
    "وشائح",
    "وشائع",
    "وشاة",
    "وشاحة",
    "وشارق",
    "وشاكة",
    "وشام",
    "وشاية",
    "وشب",
    "وشبھھم",
    "وشتما",
    "وشح",
    "وشحمه",
    "وشر",
    "وشرب",
    "وشرق",
    "وشع",
    "وشق",
    "وشل",
    "وشم",
    "وشمالا",
    "وشنة",
    "وشوش",
    "وشوشة",
    "وشوم",
    "وشى",
    "وشي",
    "وشيج",
    "وشيجة",
    "وشيع",
    "وشيعة",
    "وشيمة",
    "وصائف",
    "وصاة",
    "وصادرات",
    "وصاف",
    "وصال",
    "وصاوص",
    "وصايا",
    "وصب",
    "وصفاء",
    "وصلا",
    "وصلني",
    "وصله",
    "وصلية",
    "وصم",
    "وصو",
    "وصواص",
    "وصوب",
    "وصوص",
    "وصوصة",
    "وصول",
    "وصولات",
    "وصولي",
    "وصيد",
    "وصيف",
    "وصيل",
    "وضؤ",
    "وضاء",
    "وضاءة",
    "وضائع",
    "وضاح",
    "وضاعة",
    "وضب",
    "وضحاھا",
    "وضر",
    "وضعاء",
    "وضعة",
    "وضعي",
    "وضعية",
    "وضم",
    "وضمنا",
    "وضوء",
    "وضوع",
    "وضيء",
    "وضيعة",
    "وطء",
    "وطأ",
    "وطأة",
    "وطئ",
    "وطاء",
    "وطاب",
    "وطاعة",
    "وطاوط",
    "وطاويط",
    "وطب",
    "وطد",
    "وطر",
    "وطره",
    "وطش",
    "وطف",
    "وطفاء",
    "وطنه",
    "وطنية",
    "وطواط",
    "وطى",
    "وطيد",
    "وطيس",
    "وظب",
    "وظف",
    "وظلال",
    "وظوب",
    "وظيفته",
    "وعارة",
    "وعاظ",
    "وعب",
    "وعث",
    "وعثاء",
    "وعجن",
    "وعدت",
    "وعرة",
    "وعرضھا",
    "وعز",
    "وعس",
    "وعشرون",
    "وعطاء",
    "وعظ",
    "وعظة",
    "وعق",
    "وعك",
    "وعكة",
    "وعملا",
    "وعود",
    "وعودا",
    "وعور",
    "وعوع",
    "وعى",
    "وعي",
    "وعيدي",
    "وعير",
    "وعيه",
    "وغاد",
    "وغال",
    "وغدان",
    "وغر",
    "وغل",
    "وغلان",
    "وغول",
    "وغي",
    "وفاد",
    "وفادة",
    "وفادته",
    "وفارة",
    "وفاض",
    "وفاق",
    "وفد",
    "وفدي",
    "وفرخ",
    "وفرعا",
    "وفز",
    "وفصه",
    "وفض",
    "وفقة",
    "وفود",
    "وفور",
    "وفيات",
    "وفيعة",
    "وقاء",
    "وقائع",
    "وقاح",
    "وقاحة",
    "وقاد",
    "وقارة",
    "وقاع",
    "وقاعة",
    "وقاف",
    "وقالبا",
    "وقاية",
    "وقبة",
    "وقة",
    "وقتئذ",
    "وقتذاك",
    "وقتما",
    "وقتيا",
    "وقدة",
    "وقدرا",
    "وقذ",
    "وقرة",
    "وقرت",
    "وقس",
    "وقطران",
    "وقعة",
    "وقعه",
    "وقعوا",
    "وقفا",
    "وقفزة",
    "وقفه",
    "وقفي",
    "وقفية",
    "وقق",
    "وقواق",
    "وقوحة",
    "وقوع",
    "وقوف",
    "وقوق",
    "وقولا",
    "وقى",
    "وقي",
    "وقية",
    "وقيح",
    "وقيد",
    "وقيذ",
    "وقيعة",
    "وكأ",
    "وكاء",
    "وكاعة",
    "وكب",
    "وكبان",
    "وكبيرة",
    "وكد",
    "وكر",
    "وكرامة",
    "وكرة",
    "وكز",
    "وكس",
    "وكسور",
    "وكع",
    "وكفان",
    "وكمية",
    "وكنة",
    "وكوب",
    "وكور",
    "وكول",
    "وكون",
    "وكى",
    "وكيد",
    "ولآخر",
    "ولاء",
    "ولائد",
    "ولائم",
    "ولائي",
    "ولاة",
    "ولاعة",
    "ولاه",
    "ولاول",
    "ولج",
    "ولدة",
    "ولدنة",
    "ولدھا",
    "ولس",
    "ولسان",
    "ولط",
    "ولع",
    "ولغ",
    "ولنھب",
    "ولوج",
    "ولودية",
    "ولوع",
    "ولوغ",
    "ولول",
    "ولولة",
    "ولية",
    "وليد",
    "وليدة",
    "وليلا",
    "وليلة",
    "ولھان",
    "ومآب",
    "ومأ",
    "وماديا",
    "ومبادئھم",
    "ومتكلم",
    "ومد",
    "ومراح",
    "ومرج",
    "ومرح",
    "ومروج",
    "ومس",
    "ومستجمعه",
    "ومسمع",
    "ومصر",
    "ومضان",
    "ومعنى",
    "ومغاربھا",
    "ومغربھا",
    "ومق",
    "ومقبلا",
    "ومنھم",
    "ون",
    "وناء",
    "ونابلھم",
    "وناعر",
    "وندر",
    "ونش",
    "ونشر",
    "ونصيب",
    "ونعمت",
    "ونكير",
    "ونى",
    "ونيابة",
    "ونيف",
    "ووحدانا",
    "ووزير",
    "ووعد",
    "ووھمية",
    "ويؤخر",
    "وياءه",
    "ويبة",
    "ويبدأه",
    "ويح",
    "ويحا",
    "ويحك",
    "ويحه",
    "ويركو",
    "ويسرة",
    "ويسكى",
    "ويغاديھا",
    "ويفعله",
    "ويقيم",
    "ويك",
    "ويكة",
    "ويله",
    "ويماسيه",
    "ويمنا",
    "ويوجد",
    "وھابي",
    "وھاة",
    "وھاج",
    "وھاد",
    "وھب",
    "وھبة",
    "وھبته",
    "وھج",
    "وھجان",
    "وھد",
    "وھدة",
    "وھذا",
    "وھر",
    "وھران",
    "وھرة",
    "وھق",
    "وھكذا",
    "وھل",
    "وھلة",
    "وھلم",
    "وھم",
    "وھمي",
    "وھمية",
    "وھن",
    "وھناك",
    "وھو",
    "وھواه",
    "وھى",
    "وھيج",
    "وھين",
    "وھيھات",
    "يآسة",
    "يأس",
    "يألو",
    "يؤبه",
    "يؤنقه",
    "يؤوس",
    "يئد",
    "يئر",
    "ياء",
    "يائه",
    "يار",
    "يازرجة",
    "يازرجي",
    "يازول",
    "ياسمين",
    "ياسنت",
    "ياطاش",
    "يافا",
    "يافة",
    "يافطة",
    "يافوت",
    "يافوخ",
    "ياقوت",
    "ياميش",
    "يانسون",
    "يانع",
    "ياور",
    "ياى",
    "يباب",
    "يبالى",
    "يبتسم",
    "يبس",
    "يبق",
    "يبل",
    "يبوسة",
    "يبھر",
    "يتزعزع",
    "يتطرق",
    "يتعاظمه",
    "يثأ",
    "يثر",
    "يثق",
    "يجدر",
    "يجدى",
    "يجدي",
    "يجديك",
    "يجرى",
    "يجس",
    "يجف",
    "يجم",
    "يجوز",
    "يجيبه",
    "يحبور",
    "يحتمله",
    "يحلو",
    "يحم",
    "يحمور",
    "يحي",
    "يحيا",
    "يخبط",
    "يخت",
    "يخز",
    "يخصني",
    "يخضور",
    "يخط",
    "يخطر",
    "يخطو",
    "يخلو",
    "يخنى",
    "يخوت",
    "يخي",
    "يدان",
    "يدانى",
    "يدخر",
    "يدري",
    "يدلع",
    "يدھا",
    "يذر",
    "يذھب",
    "يرابيع",
    "يراع",
    "يرام",
    "يراوحھا",
    "يراھا",
    "يربوع",
    "يرع",
    "يرف",
    "يرقان",
    "يرقب",
    "يري",
    "يزب",
    "يزجو",
    "يزر",
    "يزرجة",
    "يزع",
    "يزك",
    "يزيدي",
    "يسارا",
    "يساره",
    "يساق",
    "يستغنى",
    "يستھان",
    "يسرة",
    "يسرى",
    "يسريات",
    "يسع",
    "يسعدن",
    "يسعني",
    "يسق",
    "يسقجي",
    "يسم",
    "يسمين",
    "يسوءني",
    "يسيرة",
    "يسھل",
    "يشار",
    "يشب",
    "يشتھى",
    "يشر",
    "يشمق",
    "يشمك",
    "يشي",
    "يصابحه",
    "يصار",
    "يصطلى",
    "يضح",
    "يضحك",
    "يطأ",
    "يطد",
    "يطرأ",
    "يطش",
    "يطق",
    "يطن",
    "يطھى",
    "يظب",
    "يعاسيب",
    "يعافير",
    "يعاقبة",
    "يعاقيب",
    "يعب",
    "يعبوب",
    "يعتم",
    "يعدو",
    "يعذرني",
    "يعر",
    "يعرفه",
    "يعس",
    "يعسوب",
    "يعطني",
    "يعفور",
    "يعول",
    "يغر",
    "يغرب",
    "يفته",
    "يفد",
    "يفض",
    "يفطة",
    "يفع",
    "يفق",
    "يفنى",
    "يفوقه",
    "يفي",
    "يفھم",
    "يفھموا",
    "يقاظة",
    "يقاظى",
    "يقام",
    "يقب",
    "يقح",
    "يقد",
    "يقذ",
    "يقصيه",
    "يقظان",
    "يقظى",
    "يقن",
    "يقنة",
    "يقوله",
    "يقي",
    "يقينيات",
    "يكب",
    "يكز",
    "يكس",
    "يكل",
    "يكي",
    "يلبث",
    "يلة",
    "يلس",
    "يلغ",
    "يلمس",
    "يله",
    "يلوي",
    "يم",
    "يم",
    "يمائم",
    "يمام",
    "يمض",
    "يمق",
    "يمكنه",
    "يمم",
    "يمنا",
    "يمنة",
    "يمنيات",
    "يموم",
    "يمي",
    "ينائر",
    "ينبغى",
    "ينبوع",
    "ينتھك",
    "ينجلى",
    "يندى",
    "ينسون",
    "ينشب",
    "ينع",
    "ينعقد",
    "ينكف",
    "ينوف",
    "يني",
    "ينيع",
    "ينيف",
    "يوافيخ",
    "يواقيت",
    "يوبأ",
    "يوبر",
    "يوبق",
    "يوبل",
    "يوبه",
    "يوبيل",
    "يوثر",
    "يوجع",
    "يوحل",
    "يوحم",
    "يوحنا",
    "يوخم",
    "يورانيوم",
    "يوزباشي",
    "يوزر",
    "يوسفي",
    "يوسن",
    "يوشك",
    "يوصب",
    "يوضؤ",
    "يوطف",
    "يوطنة",
    "يوعر",
    "يوغر",
    "يوغوسلافي",
    "يوقح",
    "يوقر",
    "يوكع",
    "يولع",
    "يوله",
    "يوليه",
    "يوليو",
    "يومذاك",
    "يومنا",
    "يومه",
    "يوميات",
    "يومھا",
    "يونوسفير",
    "يونى",
    "يونيو",
    "يووجل",
    "يوية",
    "يوھل",
    "يوھم",
    "يوھن",
    "يھب",
    "يھج",
    "يھر",
    "يھم",
    "يھن",
    "يھودي",
    "يھودية",
    "يھي",
    "پلاتين",
    "پنى",
    "پيد",
    "چفت",
    "چفتشى",
    "چفلك",
    "چيت",
    "چيكى",
    "ڤلط",
    "ڤيزا",
    "ھأمتذا",
    "ھأنتذا",
    "ھأنذا",
    "ھأھأ",
    "ھؤلاء",
    "ھؤلائك",
    "ھؤور",
    "ھؤول",
    "ھا",
    "ھاء",
    "ھاءناذا",
    "ھاءنذا",
    "ھائج",
    "ھائجه",
    "ھائل",
    "ھائم",
    "ھاب",
    "ھابط",
    "ھابطة",
    "ھابيل",
    "ھات",
    "ھاتان",
    "ھاتف",
    "ھاتفي",
    "ھاته",
    "ھاتوا",
    "ھاتور",
    "ھاتيك",
    "ھاج",
    "ھاجرة",
    "ھاجري",
    "ھاجس",
    "ھاد",
    "ھادئ",
    "ھادم",
    "ھاذ",
    "ھار",
    "ھارب",
    "ھاربا",
    "ھازئ",
    "ھازل",
    "ھازلة",
    "ھاش",
    "ھاشمي",
    "ھاض",
    "ھاط",
    "ھاع",
    "ھاف",
    "ھافت",
    "ھاك",
    "ھاكم",
    "ھاكه",
    "ھال",
    "ھالة",
    "ھالك",
    "ھام",
    "ھامة",
    "ھامج",
    "ھامد",
    "ھامش",
    "ھامشي",
    "ھامل",
    "ھان",
    "ھانئ",
    "ھانئة",
    "ھانم",
    "ھاو",
    "ھاواي",
    "ھاون",
    "ھاوون",
    "ھاوية",
    "ھاويس",
    "ھايتي",
    "ھاھنا",
    "ھب",
    "ھبا",
    "ھباء",
    "ھباءة",
    "ھباب",
    "ھبة",
    "ھبت",
    "ھبر",
    "ھبرة",
    "ھبش",
    "ھبط",
    "ھبطة",
    "ھبل",
    "ھبلاء",
    "ھبلك",
    "ھبني",
    "ھبو",
    "ھبوب",
    "ھبوة",
    "ھبوط",
    "ھبيب",
    "ھبيت",
    "ھبيرة",
    "ھبيط",
    "ھبيل",
    "ھبھاب",
    "ھبھب",
    "ھتاريخ",
    "ھتاف",
    "ھتافا",
    "ھتامة",
    "ھتر",
    "ھتف",
    "ھتفة",
    "ھتك",
    "ھتكة",
    "ھتم",
    "ھتماء",
    "ھتن",
    "ھتون",
    "ھتيكة",
    "ھج",
    "ھجء",
    "ھجأ",
    "ھجا",
    "ھجاء",
    "ھجائن",
    "ھجائي",
    "ھجاس",
    "ھجان",
    "ھجانة",
    "ھجد",
    "ھجر",
    "ھجراء",
    "ھجران",
    "ھجرة",
    "ھجرية",
    "ھجس",
    "ھجسة",
    "ھجص",
    "ھجع",
    "ھجعة",
    "ھجل",
    "ھجم",
    "ھجمة",
    "ھجمي",
    "ھجن",
    "ھجناء",
    "ھجنة",
    "ھجو",
    "ھجوء",
    "ھجود",
    "ھجوع",
    "ھجوم",
    "ھجوما",
    "ھجومي",
    "ھجونة",
    "ھجوي",
    "ھجيج",
    "ھجير",
    "ھجيرة",
    "ھجين",
    "ھجينة",
    "ھد",
    "ھدء",
    "ھدأ",
    "ھدئ",
    "ھداء",
    "ھداءة",
    "ھداب",
    "ھداة",
    "ھداج",
    "ھداد",
    "ھداديك",
    "ھدار",
    "ھدارة",
    "ھداف",
    "ھدام",
    "ھدايا",
    "ھداية",
    "ھداھد",
    "ھدب",
    "ھدباء",
    "ھدة",
    "ھدج",
    "ھدجان",
    "ھدر",
    "ھدرا",
    "ھدف",
    "ھدفا",
    "ھدفان",
    "ھدل",
    "ھدلاء",
    "ھدم",
    "ھدن",
    "ھدنة",
    "ھدوء",
    "ھدود",
    "ھدوم",
    "ھدون",
    "ھدى",
    "ھدي",
    "ھدية",
    "ھديد",
    "ھدير",
    "ھديل",
    "ھدھد",
    "ھذا",
    "ھذاء",
    "ھذاك",
    "ھذاليل",
    "ھذان",
    "ھذب",
    "ھذر",
    "ھذرم",
    "ھذلول",
    "ھذلولي",
    "ھذه",
    "ھذى",
    "ھذي",
    "ھذيان",
    "ھر",
    "ھرأ",
    "ھرا",
    "ھراء",
    "ھراب",
    "ھراة",
    "ھراس",
    "ھراسة",
    "ھراش",
    "ھراطقة",
    "ھراطقي",
    "ھراع",
    "ھراوة",
    "ھراوى",
    "ھرب",
    "ھربان",
    "ھرة",
    "ھرج",
    "ھرجلة",
    "ھردبشت",
    "ھرر",
    "ھررة",
    "ھرس",
    "ھرش",
    "ھرطق",
    "ھرطقة",
    "ھرطمان",
    "ھرطوقي",
    "ھرع",
    "ھرف",
    "ھرق",
    "ھرقل",
    "ھرم",
    "ھرمس",
    "ھرمون",
    "ھرمي",
    "ھرو",
    "ھروب",
    "ھرول",
    "ھرولة",
    "ھروي",
    "ھري",
    "ھرير",
    "ھريرة",
    "ھرھر",
    "ھز",
    "ھزء",
    "ھزأ",
    "ھزأة",
    "ھزؤ",
    "ھزئ",
    "ھزئي",
    "ھزائم",
    "ھزابر",
    "ھزار",
    "ھزاز",
    "ھزال",
    "ھزاھز",
    "ھزبر",
    "ھزة",
    "ھزج",
    "ھزر",
    "ھزع",
    "ھزل",
    "ھزلى",
    "ھزلي",
    "ھزلية",
    "ھزم",
    "ھزمھم",
    "ھزه",
    "ھزوء",
    "ھزيز",
    "ھزيل",
    "ھزيم",
    "ھزيمة",
    "ھزھز",
    "ھزھزة",
    "ھس",
    "ھستولوجيا",
    "ھستيريا",
    "ھسيس",
    "ھش",
    "ھشاش",
    "ھشاشة",
    "ھشم",
    "ھشوشة",
    "ھشيش",
    "ھشيم",
    "ھصر",
    "ھصور",
    "ھض",
    "ھضاب",
    "ھضب",
    "ھضبة",
    "ھضم",
    "ھضمي",
    "ھضوم",
    "ھضيم",
    "ھضيمة",
    "ھطع",
    "ھطل",
    "ھطلان",
    "ھطول",
    "ھف",
    "ھفا",
    "ھفات",
    "ھفاف",
    "ھفت",
    "ھفتان",
    "ھفو",
    "ھفوان",
    "ھفوة",
    "ھفيف",
    "ھفھاف",
    "ھفھف",
    "ھفھفة",
    "ھكتار",
    "ھكذا",
    "ھكم",
    "ھل",
    "ھلا",
    "ھلابة",
    "ھلاك",
    "ھلال",
    "ھلالي",
    "ھلام",
    "ھلامى",
    "ھلاھل",
    "ھلاھيل",
    "ھلب",
    "ھلباء",
    "ھلس",
    "ھلسنكي",
    "ھلع",
    "ھلعدل",
    "ھلقم",
    "ھلك",
    "ھلكة",
    "ھلكى",
    "ھلل",
    "ھللويا",
    "ھلم",
    "ھلمي",
    "ھلوسة",
    "ھلوع",
    "ھلوف",
    "ھليكوبتر",
    "ھليلج",
    "ھليون",
    "ھلھل",
    "ھلھولة",
    "ھم",
    "ھما",
    "ھمائم",
    "ھماز",
    "ھماليج",
    "ھمام",
    "ھمايون",
    "ھماھم",
    "ھمة",
    "ھمته",
    "ھمج",
    "ھمجي",
    "ھمجية",
    "ھمد",
    "ھمر",
    "ھمرة",
    "ھمز",
    "ھمزة",
    "ھمس",
    "ھمسات",
    "ھمسة",
    "ھمش",
    "ھمع",
    "ھمك",
    "ھمل",
    "ھملاج",
    "ھملان",
    "ھملج",
    "ھمم",
    "ھمو",
    "ھمود",
    "ھمول",
    "ھموم",
    "ھمھم",
    "ھمھمة",
    "ھن",
    "ھنء",
    "ھنأ",
    "ھنئ",
    "ھنا",
    "ھناء",
    "ھناءة",
    "ھناشير",
    "ھناك",
    "ھنالك",
    "ھنة",
    "ھنداز",
    "ھندازة",
    "ھنداسة",
    "ھندام",
    "ھندامه",
    "ھندب",
    "ھندباء",
    "ھندسة",
    "ھندسي",
    "ھندسية",
    "ھندم",
    "ھندمة",
    "ھندى",
    "ھندي",
    "ھنشير",
    "ھنغاري",
    "ھنف",
    "ھنم",
    "ھنو",
    "ھنوات",
    "ھنود",
    "ھني",
    "ھنيء",
    "ھنيئا",
    "ھنيھة",
    "ھنھن",
    "ھنھونة",
    "ھه",
    "ھو",
    "ھواء",
    "ھوائي",
    "ھوائية",
    "ھواة",
    "ھواتف",
    "ھواجر",
    "ھواجس",
    "ھوادة",
    "ھوادج",
    "ھوارة",
    "ھواري",
    "ھوالك",
    "ھوام",
    "ھوامس",
    "ھوان",
    "ھوانم",
    "ھوانمي",
    "ھواه",
    "ھواوين",
    "ھوايا",
    "ھواية",
    "ھواھا",
    "ھوة",
    "ھوت",
    "ھوتة",
    "ھوج",
    "ھوجاء",
    "ھود",
    "ھودج",
    "ھوذا",
    "ھور",
    "ھورة",
    "ھورمون",
    "ھوس",
    "ھوسة",
    "ھوش",
    "ھوشة",
    "ھوع",
    "ھوقظ",
    "ھوكي",
    "ھول",
    "ھولاندا",
    "ھولانده",
    "ھولاندي",
    "ھولة",
    "ھوليوود",
    "ھوم",
    "ھون",
    "ھونا",
    "ھوناء",
    "ھونك",
    "ھوى",
    "ھوية",
    "ھويته",
    "ھويس",
    "ھوينا",
    "ھي",
    "ھيء",
    "ھيأ",
    "ھيأة",
    "ھيؤ",
    "ھيئ",
    "ھيئة",
    "ھيا",
    "ھياءة",
    "ھياب",
    "ھياج",
    "ھياط",
    "ھياطل",
    "ھياطلة",
    "ھياكل",
    "ھيام",
    "ھيب",
    "ھيبة",
    "ھيت",
    "ھيج",
    "ھيجا",
    "ھيجاء",
    "ھيجان",
    "ھيدروجين",
    "ھيدروجينية",
    "ھير",
    "ھيراطيقي",
    "ھيروغليفي",
    "ھيروين",
    "ھيستيري",
    "ھيستيريا",
    "ھيش",
    "ھيشة",
    "ھيض",
    "ھيضة",
    "ھيط",
    "ھيطل",
    "ھيف",
    "ھيفاء",
    "ھيفان",
    "ھيكل",
    "ھيكلي",
    "ھيكلية",
    "ھيل",
    "ھيلان",
    "ھيم",
    "ھيمان",
    "ھيمن",
    "ھيمنة",
    "ھيمى",
    "ھين",
    "ھينة",
    "ھينم",
    "ھيه",
    "ھيوب",
    "ھيوف",
    "ھيول",
    "ھيولاني",
    "ھيولى",
    "ھيولي",
    "ھيوم",
    "ھيھات",
    "ھھ",
    "ھھنا",
    "آخراً",
    "أبدأ",
    "أبلع",
    "أجوبة",
    "أحادي",
    "أحتمالية",
    "أحتياطي",
    "أحصائي",
    "أخبار",
    "أختيار",
    "أخدم",
    "أذون",
    "أربعينية",
    "أرجوانى",
    "أرشفة",
    "أرشيف",
    "أزمة",
    "أس",
    "أسئلة",
    "أسابيع",
    "أساسٌ",
    "أساسية",
    "أسبوع",
    "أسبوعان",
    "أستغلال",
    "أسهم",
    "أسي",
    "أسية",
    "أصدرة",
    "أصناف",
    "أطروحة",
    "أعداد",
    "أعزف",
    "أعيان",
    "أغرق",
    "أفتار",
    "أفتراضي",
    "أفقى",
    "أفقياً",
    "أقذف",
    "أكبر",
    "أكتب",
    "ألأول",
    "ألضرورة",
    "ألكترونيات",
    "أمً",
    "أمامية",
    "أمثل",
    "أمثلة",
    "أمضاء",
    "أنبوبة",
    "أهلا",
    "أولاً",
    "أوليً",
    "أوًلي",
    "أولية",
    "أيسلندا",
    "أيقونات",
    "أيقونة",
    "أيقونية",
    "إبانة",
    "إبتدائي",
    "إبراز",
    "إبرة",
    "إبلاغ",
    "إتاحة",
    "إتساع",
    "إتصالي",
    "إجبارية",
    "إجتثاث",
    "إجتذاب",
    "إجرائية",
    "إجمالية",
    "إجهاض",
    "إحاطة",
    "إحتشاد",
    "إحتياج",
    "إحداثيات",
    "إحراز",
    "إخباري",
    "إختمال",
    "إخطاء",
    "إخلاء",
    "إدانة",
    "إدراكية",
    "إدلاء",
    "إذابة",
    "إذاعية",
    "إرباك",
    "إرتعاش",
    "إرتقب",
    "إرتياع",
    "إرجاء",
    "إرساء",
    "إرشادات",
    "إرفاق",
    "إزاحة",
    "إستثنائات",
    "إستخبر",
    "إستدعاء",
    "إسترجاع",
    "إستقلالي",
    "إستنتاجية",
    "إسناد",
    "إسهال",
    "إشادات",
    "إشهاد",
    "إصاغة",
    "إصدارة",
    "إضائة",
    "إطارات",
    "إعتماد",
    "إعتمادية",
    "إعتيان",
    "إعدادات",
    "إغفاءة",
    "إقبال",
    "إقتراح",
    "إقترانية",
    "إقرء",
    "إقفال",
    "إكثار",
    "إكسابايت",
    "إكسترانت",
    "إكمال",
    "إلتقاط",
    "إلحاق",
    "إلصاق",
    "إلمام",
    "إمتدادة",
    "إمتياز",
    "إنترانت",
    "إنترنت",
    "إنسدال",
    "إنطلاقة",
    "إنطمار",
    "إنعاش",
    "إنفراج",
    "إنفراجي",
    "إنفراق",
    "إنقضة",
    "إنقلاب",
    "إنقياد",
    "إنهياري",
    "إهليج",
    "إهليلجي",
    "إيفاد",
    "اتجديد",
    "اتصالات",
    "اتقاء",
    "اختر",
    "ارتباطية",
    "استحصال",
    "استخرج",
    "استراتجية",
    "استعاد",
    "استقصاء",
    "استيثاق",
    "اشترك",
    "اصدارات",
    "اصدارة",
    "اصطلاحية",
    "اضافى",
    "اعتمادية",
    "اقتصار",
    "اقتصاص",
    "الأبحار",
    "الأبكر",
    "الأحجام",
    "الأحساس",
    "الأصل",
    "الأصلية",
    "الأضلاع",
    "الأمثل",
    "الأوًل",
    "الإخراج",
    "الإصطلاحية",
    "الإعتيان",
    "الإنضغاط",
    "الابتدائي",
    "الاجراء",
    "الارتباط",
    "الاستفهام",
    "الاقسام",
    "الاقلاع",
    "الانظمة",
    "البت",
    "البرتغالية",
    "البلًور",
    "البولندية",
    "التأدية",
    "التجهيزة",
    "التربوي",
    "الترويسة",
    "التسامتي",
    "التسلسي",
    "التسنن",
    "التشظية",
    "التطبيع",
    "التعاونيً",
    "التعجب",
    "التعمية",
    "التفرع",
    "التنبيه",
    "التوضيحي",
    "الجذري",
    "الحثً",
    "الحدً",
    "الحرة",
    "الحلً",
    "الحمل",
    "الخردة",
    "الخزامى",
    "الخلوص",
    "الدفعات",
    "الدقًة",
    "الدينامي",
    "الرصة",
    "الرطل",
    "الرقمنة",
    "الرماز",
    "الزوجيًة",
    "السطر",
    "السطور",
    "السواقة",
    "السوكت",
    "الشفافية",
    "الشيفرة",
    "الصادر",
    "الصوان",
    "الضجًة",
    "الضمنيًة",
    "الظاهرية",
    "العدً",
    "العرض",
    "العلًة",
    "الفأرة",
    "الفعال",
    "الفكرية",
    "الفيليبين",
    "القاسم",
    "القرفصاء",
    "القصً",
    "القوًة",
    "الكدسة",
    "الكروماتية",
    "الكمومية",
    "اللب",
    "اللجوء",
    "الماكرو",
    "المتبقي",
    "المتبقية",
    "المتوازية",
    "المتورطة",
    "المثلثة",
    "المجلًد",
    "المجلد",
    "المجمتع",
    "المحلية",
    "المدخلة",
    "المربعة",
    "المرتقب",
    "المضيفين",
    "المعطيات",
    "المعياري",
    "المفتوحة",
    "الملموس",
    "الملموسة",
    "الموجودة",
    "المورد",
    "الموسطة",
    "الميدى",
    "الميفاق",
    "الميقاتية",
    "النانو",
    "النتيحة",
    "النشدان",
    "النكل",
    "النواقل",
    "الهاك",
    "الواب",
    "الوحيد",
    "الوسائط",
    "الوظيفية",
    "الولوج",
    "انتحارية",
    "انتقاء",
    "انتهت",
    "انصهار",
    "انضمامي",
    "انطباع",
    "انهاء",
    "انهيار",
    "باث",
    "باعية",
    "باقٍ",
    "بالاتصال",
    "بالاسم",
    "بالحاجة",
    "بالحاسوب",
    "بالدفعات",
    "بالسياق",
    "بالعالم",
    "بالعمق",
    "بالقيمة",
    "باللمس",
    "بالمئة",
    "بالمرجع",
    "بالمظاهر",
    "بالمفاتيح",
    "بالمفتاح",
    "بالملفات",
    "بالمواصلة",
    "باني",
    "بايتات",
    "بت",
    "بتة",
    "بتي",
    "بداهة",
    "برايل",
    "برمجي",
    "برهة",
    "بزبز",
    "بسطٌ",
    "بطل",
    "بعد",
    "بفواصل",
    "بكبر",
    "بلازمية",
    "بلخدمة",
    "بلضرورة",
    "بلماكنتوش",
    "بلمذاق",
    "بلمورد",
    "بلنكهة",
    "بنائية",
    "بنوية",
    "بنية",
    "بنيوي",
    "بوت",
    "بيئيات",
    "تأجج",
    "تأكد",
    "تباطؤ",
    "تباعدي",
    "تبدد",
    "تبديلي",
    "تتالي",
    "تجاهل",
    "تجريد",
    "تجميعة",
    "تجميعية",
    "تجنيب",
    "تجهيزات",
    "تجهيزة",
    "تحتية",
    "تحديث",
    "تحريكة",
    "تحسيب",
    "تحضيرية",
    "تحكم",
    "تخبئة",
    "تخطيطة",
    "تدرج",
    "تدفق",
    "تدفًق",
    "تدفيع",
    "تذكري",
    "تذكيري",
    "تذييل",
    "ترابطية",
    "تراثى",
    "تراثية",
    "تراكب",
    "ترتيبة",
    "ترددي",
    "ترصدات",
    "ترصيف",
    "ترقين",
    "تسامتي",
    "تسامتية",
    "تسجيلة",
    "تسريب",
    "تسلسلي",
    "تسلسلية",
    "تسلًق",
    "تسنن",
    "تسنن",
    "تشعًب",
    "تشغيلي",
    "تشفيرة",
    "تصادمات",
    "تصاعدي",
    "تصالب",
    "تصفح",
    "تصنيفات",
    "تصوين",
    "تصيير",
    "تضبيط",
    "تضليل",
    "تضمين",
    "تطبيقات",
    "تظليل",
    "تعاقبية",
    "تعالق",
    "تعداد",
    "تعرف",
    "تعريفات",
    "تعقد",
    "تعمية",
    "تغليف",
    "تغيرات",
    "تفاعلية",
    "تفضيلات",
    "تفقد",
    "تقابل",
    "تقدًمي",
    "تقريباً",
    "تقريبية",
    "تقريرية",
    "تقصص",
    "تقنية",
    "تكراري",
    "تكرارية",
    "تكيفية",
    "تكييفي",
    "تلاؤم",
    "تلاشي",
    "تلبيد",
    "تلفزيونية",
    "تماثلي",
    "تماماً",
    "تمرير",
    "تمرير",
    "تمهيدية",
    "تمهيل",
    "تمييزية",
    "تناظري",
    "تناقش",
    "تناوبي",
    "تنقيل",
    "تنكر",
    "تنويت",
    "تنويت",
    "تنويت",
    "تنويعة",
    "تواردية",
    "توازي",
    "توافقي",
    "توافقية",
    "توجه",
    "توجيهي",
    "توزيعة",
    "توطين",
    "توقع",
    "توقًع",
    "ثغرة",
    "ثقة",
    "ثمانيات",
    "ثنائي",
    "جاما",
    "جانت",
    "جذرية",
    "جرسي",
    "جريان",
    "جزء",
    "جزىء",
    "جزيئية",
    "جس",
    "جسر",
    "جسيم",
    "جلب",
    "جنو",
    "جني",
    "جنيسة",
    "جواني",
    "حاسوب",
    "حاسوبون",
    "حاسوبي",
    "حاسوبية",
    "حاصرة",
    "حاصرة",
    "حال",
    "حاوية",
    "حبً",
    "حد",
    "حدًة",
    "حدًث",
    "حدسي",
    "حدسية",
    "حر",
    "حرً",
    "حرج",
    "حزات",
    "حصة",
    "حقن",
    "حقوقيات",
    "حقيقياً",
    "حقيقية",
    "حل",
    "حلقت",
    "حلقيًة",
    "حلل",
    "حمل",
    "حواسب",
    "حواشي",
    "حوسبة",
    "حومان",
    "حوى",
    "حيً",
    "خادوم",
    "خارجة",
    "خارجية",
    "خاصيات",
    "خبء",
    "خبرة",
    "خدمية",
    "خربشة",
    "خرج",
    "خرج",
    "خصلي",
    "خصيلة",
    "خطً",
    "خطًاط",
    "خطاطة",
    "خطة",
    "خطية",
    "خطية",
    "خفي",
    "خلفيا",
    "خلوية",
    "خوادم",
    "خوارزم",
    "خوارزمية",
    "خوارزمية",
    "خيارات",
    "داب",
    "دال",
    "دال",
    "دالًة",
    "دالية",
    "دبينة",
    "دخل",
    "دخل",
    "دراية",
    "دردشة",
    "دعاميع",
    "دفعة",
    "دلاليات",
    "دورات",
    "دوريً",
    "دولي",
    "ديكارتية",
    "دينامية",
    "ديناميكياً",
    "ذرائعي",
    "ذقف",
    "ذكيًة",
    "رأسية",
    "ربط",
    "رتبية",
    "رجرجة",
    "ردً",
    "رسومي",
    "رسوميات",
    "رسومية",
    "رص",
    "رصة",
    "رفً",
    "رقم",
    "رقمنة",
    "ركام",
    "رماز",
    "رمزية",
    "رنًم",
    "روبوت",
    "رياضي",
    "زاوية",
    "زرً",
    "زوجيًة",
    "زومبي",
    "زيحان",
    "ساعياً",
    "سبستروم",
    "سبقي",
    "ستي",
    "سجلً",
    "سر",
    "سري",
    "سعي",
    "سكزي",
    "سكوني",
    "سلة",
    "سلكي",
    "سلًمي",
    "سماحية",
    "سمة",
    "سنتيمتر",
    "سنغفورة",
    "سياقية",
    "سيني",
    "شبكى",
    "شبيكة",
    "شجرى",
    "شجرية",
    "شدة",
    "شرطة",
    "شره",
    "شعار",
    "شعاعية",
    "شفرةٌ",
    "شقب",
    "شقة",
    "شكل",
    "شمل",
    "شيفرة",
    "صادي",
    "صافية",
    "صحً",
    "صد",
    "صدً",
    "صدفة",
    "صرًة",
    "صغر",
    "صفة",
    "صفحيً",
    "صفحي",
    "صفحيًة",
    "صفد",
    "صفرية",
    "صمًام",
    "صنفية",
    "صوان",
    "صير",
    "ضؤية",
    "ضجًة",
    "ضم",
    "ضمنيً",
    "ضمنيًة",
    "طب",
    "طبق",
    "طبيعى",
    "طرح",
    "طرفية",
    "طور",
    "ظاهري",
    "ظاهرية",
    "ظرافة",
    "ظلًة",
    "عائمة",
    "عالميأً",
    "عامودي",
    "عاموديا",
    "عبارة",
    "عتادى",
    "عدً",
    "عدم",
    "عروية",
    "عشري",
    "عشوائية",
    "عفنة",
    "علة",
    "علة",
    "علق",
    "عماد",
    "عمادي",
    "عملية",
    "عميمة",
    "عنصورات",
    "عول",
    "عيًنة",
    "غائمة",
    "غامضة",
    "غرضية",
    "غلاف",
    "غميق",
    "غورو",
    "غيغابايت",
    "فاكس",
    "فايروس",
    "فجوة",
    "فرعيًة",
    "فرق",
    "فسيفساء",
    "فصل",
    "فطرى",
    "فعالة",
    "فعلي",
    "فقود",
    "فك",
    "فكً",
    "فكً",
    "فكً",
    "فوريه",
    "فوقية",
    "فيروس",
    "فيروسات",
    "قارءى",
    "قاسم",
    "قانونيات",
    "قردى",
    "قسر",
    "قسم",
    "قصاصات",
    "قطً",
    "قطر",
    "قطرية",
    "قنوية",
    "قوسان",
    "كائنية",
    "كاتيوني",
    "كاثودي",
    "كافيا",
    "كامرة",
    "كبر",
    "كتل",
    "كتيب",
    "كراس",
    "كراسة",
    "كراكيب",
    "كسبية",
    "كٍسرة",
    "كسوري",
    "كسوري",
    "كسولة",
    "كلً",
    "كمومي",
    "كنغرية",
    "كنية",
    "كونسول",
    "كيلوبايت",
    "كيلوبت",
    "كيلوبود",
    "لاخطي",
    "لاسم",
    "لاشيء",
    "لامتناظر",
    "لامتناهية",
    "لامدا",
    "لب",
    "لتغيير",
    "لصاق",
    "لصيقة",
    "لل",
    "للإنطمار",
    "للإنعاش",
    "للاتصال",
    "للاشيء",
    "للتجهيزة",
    "للتحسيب",
    "للتحميل",
    "للتعداد",
    "للجميع",
    "للجيب",
    "للزلق",
    "للطمر",
    "للقصد",
    "للكتابة",
    "لللغة",
    "للنافذة",
    "للنظام",
    "لليسار",
    "لليمين",
    "لم",
    "لمصفوفة",
    "لمورد",
    "لوغارتم",
    "ليزر",
    "لينكس",
    "مأمثل",
    "مؤثر",
    "مؤثرات",
    "مؤشر",
    "مؤشر",
    "مؤمن",
    "مؤول",
    "مؤول",
    "مائلة",
    "مارً",
    "ماسحة",
    "ماسك",
    "ماسيً",
    "ماليً",
    "مبدأ",
    "مبدال",
    "مبرز",
    "مبطًل",
    "مبنٍ",
    "مبن",
    "مبهم",
    "مبهمة",
    "مبيان",
    "متأثرة",
    "متأهب",
    "متاح",
    "متتالية",
    "متجانسة",
    "متجر",
    "متًجه",
    "متجهية",
    "متدرًج",
    "مترفعة",
    "متسلسلة",
    "متصفًح",
    "متطلب",
    "متطلًبات",
    "متطورة",
    "متعامد",
    "متعامدة",
    "متعدية",
    "متغيًر",
    "متغير",
    "متغييرة",
    "متقصف",
    "متقطًع",
    "متناظر",
    "متناقضة",
    "متناه",
    "متنصت",
    "متوائم",
    "متواز",
    "متوازية",
    "متورط",
    "متوسط",
    "متوصل",
    "متوقع",
    "مثبًت",
    "مثبت",
    "مثنوي",
    "مجالات",
    "مجدداً",
    "مجرد",
    "مجس",
    "مجسد",
    "مجسم",
    "مجلة",
    "مجلد",
    "مجلًد",
    "مجمع",
    "مجمع",
    "مجمل",
    "محاذى",
    "محاكاه",
    "محث",
    "محجوزة",
    "محد",
    "محدود",
    "محرر",
    "محرف",
    "محرفي",
    "محرفي",
    "محرك",
    "محفظ",
    "محك",
    "محلًل",
    "محلي",
    "محلًي",
    "محليً",
    "محمل",
    "محوًل",
    "مخبأ",
    "مخبأ",
    "مخبأة",
    "مخسوف",
    "مخطًط",
    "مخطط",
    "مخير",
    "مداول",
    "مدة",
    "مدخل",
    "مدخلة",
    "مدرج",
    "مدرجة",
    "مدلًى",
    "مدمج",
    "مدونة",
    "مذيل",
    "مرآوي",
    "مرآوية",
    "مرئي",
    "مرئيات",
    "مرئية",
    "مربعات",
    "مرجع",
    "مرذاذ",
    "مرسال",
    "مرشح",
    "مرشح",
    "مرقاب",
    "مرقمة",
    "مركب",
    "مركب",
    "مركز",
    "مركم",
    "مرماز",
    "مرمز",
    "مرمز",
    "مزامنة",
    "مزدوج",
    "مزودج",
    "مزودجا",
    "مزيد",
    "مساحات",
    "مساق",
    "مساهم",
    "مساهمة",
    "مساو",
    "مسببي",
    "مستصعب",
    "مستضيف",
    "مستقل",
    "مستلم",
    "مسجل",
    "مسجل",
    "مسرع",
    "مسروية",
    "مسط",
    "مسماة",
    "مسمى",
    "مسوق",
    "مسيًر",
    "مشاركون",
    "مشبًك",
    "مشتركة",
    "مشتغلات",
    "مشتق",
    "مشتقة",
    "مشغل",
    "مشفر",
    "مصححة",
    "مصرف",
    "مصطلحات",
    "مصفر",
    "مصلح",
    "مصين",
    "مضروب",
    "مضغوطة",
    "مضلع",
    "مضمن",
    "مضمن",
    "مضمًن",
    "مضيف",
    "مطارف",
    "مطراف",
    "مطراف",
    "مطلق",
    "مطور",
    "مظلل",
    "مظهر",
    "معادل",
    "معامل",
    "معامل",
    "معاودة",
    "معتوه",
    "معدات",
    "معدد",
    "معدل",
    "معدًل",
    "معرف",
    "معرًف",
    "معرف",
    "معطوب",
    "معطى",
    "معطيات",
    "معقد",
    "معيار",
    "مغادرة",
    "مغلف",
    "مغلقة",
    "مغناطيسى",
    "مغوي",
    "مغير",
    "مغيير",
    "مفتاحية",
    "مفتاحية",
    "مفروز",
    "مفسًر",
    "مفسر",
    "مفصلًة",
    "مفصولة",
    "مفككة",
    "مفل",
    "مفهمة",
    "مفوض",
    "مقبس",
    "مقتبس",
    "مقترح",
    "مقتطع",
    "مقتطف",
    "مقهى",
    "مقيد",
    "مقيد",
    "مقيد",
    "مقيمة",
    "مكافئ",
    "مكانيً",
    "مكتبات",
    "مكتلة",
    "مكرًس",
    "مكروفون",
    "مكسورة",
    "مكعب",
    "مكعًب",
    "مكنز",
    "مكون",
    "مكونية",
    "مكيًف",
    "ملاحظات",
    "ملتقطة",
    "ملحق",
    "ملصق",
    "ملمتر",
    "مميز",
    "مميًزة",
    "من",
    "مناسخة",
    "منبثقة",
    "منتدى",
    "منتظم",
    "مندسس",
    "منساق",
    "منسدلة",
    "منضدة",
    "منطقى",
    "منظم",
    "منظمة",
    "منقال",
    "منقح",
    "منقط",
    "مهاتفة",
    "مهمة",
    "مهملات",
    "مهنية",
    "مهيء",
    "مهيئ",
    "موائم",
    "مواردة",
    "مواصلات",
    "موثق",
    "موجه",
    "موجهة",
    "موجًهة",
    "موحد",
    "مورد",
    "موزع",
    "موزعة",
    "موسط",
    "موسطي",
    "موسع",
    "موسعة",
    "موفر",
    "موقعيً",
    "مولد",
    "ميجا",
    "ميركرى",
    "ميفاق",
    "ميكرو",
    "ميكرون",
    "مينكس",
    "ناقذة",
    "نانو",
    "نبذ",
    "نجاعة",
    "نزولاً",
    "نسخةٌ",
    "نسيجي",
    "نشيط",
    "نصفية",
    "نصيبية",
    "نقر",
    "نقرتان",
    "نقطة",
    "نقطتان",
    "نقطي",
    "نقطي",
    "نقطي",
    "نقطية",
    "نمذجة",
    "نمظ",
    "نهائية",
    "نهايةغير",
    "نهايتين",
    "نواقل",
    "نوية",
    "نيتسكيب",
    "هاكر",
    "هامد",
    "هامشيات",
    "هتفي",
    "هجاء",
    "هرمية",
    "هلال",
    "هوجاء",
    "هيديوغرافيا",
    "وألصق",
    "وإفلات",
    "واب",
    "واسم",
    "واصف",
    "واعٍ",
    "واقعي",
    "والصق",
    "وب",
    "وتر",
    "وثوب",
    "وجهاء",
    "وخادم",
    "وخادوم",
    "ودجة",
    "وزع",
    "وسرد",
    "وسم",
    "وصفية",
    "وصل",
    "وصلات",
    "وقائية",
    "وقتياً",
    "وقتية",
    "ومتبوع",
    "ويب",
    "ويكي",
    "ويندوز",
    "يؤشر",
    "يتوسطه",
    "يحتشد",
    "يرمز",
    "يسارى",
    "يسارية",
    "يستثني",
    "يستشم",
    "يسدً",
    "يمينى",
    "يمينية",
    "يناسخ",
    "يندس",
    "يندسس",
    "يهمهم",
    "يوقع",
    "يونكس",
    "يونيكود",
    "متأبط",
    "أواتي",
    "مستأثر",
    "متأدب",
    "مؤدي",
    "مؤارب",
    "مؤتزر",
    "أواسف",
    "أفول",
    "متأكسد",
    "مؤتلف",
    "أوامن",
    "متأمرك",
    "مستأهل",
    "آهل",
    "أواهل",
    "متأهل",
    "متأوه",
    "بوائس",
    "بوائر",
    "بواتر",
    "بترة",
    "بواث",
    "بثثة",
    "باجح",
    "بواجح",
    "بجحة",
    "بجاح",
    "بواحث",
    "بحثة",
    "منبث",
    "متباجح",
    "متباحث",
    "متبدل",
    "باخر",
    "بوادئ",
    "متبدد",
    "متبادر",
    "بادع",
    "بوادن",
    "بدنة",
    "بدان",
    "بوادي",
    "بذخة",
    "بذاخ",
    "باذر",
    "براة",
    "بواري",
    "بارئ",
    "بوارئ",
    "مبربر",
    "متبرج",
    "بواذر",
    "بواذل",
    "بذال",
    "بوارح",
    "برحة",
    "مبارح",
    "بوارد",
    "متبرد",
    "أبرار",
    "بوارز",
    "برزة",
    "متبرز",
    "بارص",
    "مبرطل",
    "بوارع",
    "برعة",
    "براع",
    "متبرعم",
    "متبارك",
    "متبرك",
    "مستبرك",
    "بارم",
    "منبرم",
    "مبروز",
    "متباري",
    "بازغ",
    "بوازغ",
    "بازق",
    "بازل",
    "باسر",
    "بواسط",
    "مباسط",
    "متبسط",
    "بواسق",
    "بسال",
    "مستبسل",
    "بواسم",
    "بسمة",
    "بواطل",
    "متبسم",
    "مبسمل",
    "باشر",
    "بواش",
    "بشاش",
    "بششة",
    "مستبشع",
    "باصر",
    "بصار",
    "بصرة",
    "مبصر",
    "متبصر",
    "مستبصر",
    "باصق",
    "باصم",
    "باضع",
    "بواضع",
    "متبضع",
    "متباطئ",
    "باطح",
    "باطر",
    "باطش",
    "أباطيل",
    "مستبطن",
    "بعاث",
    "متبعثر",
    "مبتعد",
    "باعر",
    "باغض",
    "متباغض",
    "باغي",
    "بواغي",
    "بقاة",
    "بواقي",
    "باقر",
    "باكم",
    "بواكي",
    "متباكي",
    "متبلبل",
    "متبلج",
    "مبلسم",
    "بالع",
    "متبلع",
    "مبلعم",
    "بوالغ",
    "متبلغ",
    "متبلل",
    "متبلور",
    "بوالي",
    "مبنج",
    "بواني",
    "متبني",
    "باهج",
    "متبهج",
    "مبهدل",
    "باهر",
    "بواهر",
    "متبهر",
    "منبهر",
    "متبهرج",
    "بواهظ",
    "مبهظ",
    "مبتهل",
    "مبرئ",
    "متباهي",
    "بائخ",
    "بوائخ",
    "مبور",
    "بوائت",
    "مبيح",
    "بوائد",
    "مبادر",
    "مبيطر",
    "متبيطر",
    "بوائع",
    "مبايع",
    "متبايع",
    "بوائن",
    "بثاث",
    "بوات",
    "بتات",
    "بتتة",
    "متبين",
    "مستتب",
    "مستتبع",
    "تواجر",
    "تجرة",
    "تواسع",
    "تاعس",
    "تفاه",
    "تفهة",
    "أواثم",
    "متكتك",
    "تالع",
    "متتالع",
    "متلفز",
    "متتلمذ",
    "متلمذ",
    "تلاة",
    "تامر",
    "توام",
    "تممة",
    "توائب",
    "متتوق",
    "توائه",
    "متثائب",
    "ثوامر",
    "ثبتة",
    "متثبت",
    "ثابط",
    "ثارب",
    "ثارد",
    "مثري",
    "ثواقب",
    "منثقب",
    "ثاقف",
    "متثقف",
    "مستثقل",
    "ثاكل",
    "ثكال",
    "ثكلة",
    "ثوالث",
    "ثالج",
    "متثلج",
    "ثامر",
    "ثواني",
    "روابع",
    "خوامس",
    "سوادس",
    "سوابع",
    "ثوامن",
    "مثامن",
    "ثناة",
    "مستثير",
    "جابذ",
    "جوابر",
    "جبرة",
    "منجبر",
    "مجبس",
    "جابل",
    "متجبن",
    "جابه",
    "مجابه",
    "جوابي",
    "جواثم",
    "جاثي",
    "جثاة",
    "جواحد",
    "جحاد",
    "جحدة",
    "جواحظ",
    "جحاظ",
    "جحظة",
    "أثام",
    "جداد",
    "جددة",
    "جادر",
    "جادع",
    "جادف",
    "مجدف",
    "متجادل",
    "مستجدي",
    "جوارف",
    "جواذب",
    "جذبة",
    "مجتذب",
    "جاذع",
    "جاذف",
    "متجذف",
    "جرحة",
    "مجرح",
    "متجرد",
    "جارد",
    "مجرر",
    "جارز",
    "جارس",
    "مجرس",
    "جارع",
    "متجرع",
    "مجترع",
    "جواسر",
    "جرفة",
    "مجترف",
    "جارم",
    "جازر",
    "جزام",
    "جاسد",
    "جاسر",
    "متجسم",
    "متجشم",
    "جاعب",
    "جافر",
    "جواف",
    "جففة",
    "متجفف",
    "مجفل",
    "جسار",
    "جسرة",
    "جافن",
    "جافي",
    "جوافي",
    "مجتلب",
    "مجلب",
    "مستجلب",
    "منجلب",
    "متجلبب",
    "مجلبب",
    "جالخ",
    "جالد",
    "جوالس",
    "متجالس",
    "جالف",
    "منجلي",
    "متجلي",
    "مجلي",
    "جوافل",
    "جمدة",
    "جامر",
    "مستجمع",
    "جامل",
    "متجمل",
    "مستجمل",
    "متجمهر",
    "مجمهر",
    "مجنب",
    "جهدة",
    "متجانب",
    "جوامح",
    "متجند",
    "مجندل",
    "متجنن",
    "مجنن",
    "جاني",
    "جواهد",
    "جهاد",
    "جهلة",
    "جاهر",
    "متجاهر",
    "مجاهر",
    "جواهز",
    "جهزة",
    "متجهز",
    "مجاهض",
    "جواهل",
    "جهال",
    "جهلاء",
    "مجهل",
    "مجود",
    "مجير",
    "مستجير",
    "جوائر",
    "جواثي",
    "جوائع",
    "متجوع",
    "مجول",
    "جائب",
    "جوائب",
    "متجيش",
    "مجيش",
    "مجيف",
    "متحبب",
    "محبذ",
    "حابر",
    "حابس",
    "محتبس",
    "حابض",
    "حابق",
    "حابك",
    "حواد",
    "محابي",
    "محثث",
    "متحجب",
    "محتجب",
    "حاجر",
    "حجاز",
    "حجزة",
    "محتجز",
    "حاجل",
    "حاجم",
    "محدودب",
    "محادث",
    "حادج",
    "محتدم",
    "حاذف",
    "حواذق",
    "حذقة",
    "حوارث",
    "متحذق",
    "متحارب",
    "حددة",
    "حارز",
    "متحرز",
    "محترز",
    "حوارس",
    "حارش",
    "متحرش",
    "محرش",
    "متحرق",
    "حارم",
    "حارن",
    "محزب",
    "حازر",
    "حازق",
    "حوازم",
    "متحزم",
    "حازن",
    "متحاسب",
    "متحسب",
    "حواسد",
    "متحاسد",
    "متحسر",
    "حواسم",
    "حسمة",
    "متحسن",
    "حاشد",
    "حواشد",
    "حشاد",
    "حشدة",
    "حشار",
    "حواشر",
    "متحاشد",
    "محتشد",
    "حاشر",
    "حاشم",
    "حاصب",
    "حصدة",
    "حواصد",
    "منحصر",
    "حاصف",
    "حصال",
    "حصلة",
    "مستحصل",
    "محضض",
    "حاضن",
    "حضنة",
    "حواطب",
    "حطبة",
    "محتطب",
    "حاطم",
    "منحطم",
    "متحفز",
    "محفض",
    "حففة",
    "محفف",
    "حفال",
    "حوافي",
    "حاقب",
    "حواقد",
    "حقاد",
    "متحاقد",
    "محقد",
    "حاقر",
    "حاقن",
    "حاكر",
    "محتك",
    "متحاكم",
    "محتكم",
    "حكاة",
    "حواكي",
    "حوالب",
    "متحلب",
    "محتلب",
    "منحلب",
    "حالج",
    "حالف",
    "مستحلف",
    "متحلق",
    "محلقم",
    "مستحل",
    "متحلي",
    "حامد",
    "حواكم",
    "حامس",
    "حامص",
    "متحامل",
    "مستحمل",
    "محملق",
    "متحمم",
    "محمم",
    "مستحم",
    "متحنث",
    "محنجر",
    "حانط",
    "حانف",
    "محنف",
    "حانك",
    "حوائر",
    "متحاور",
    "حوائز",
    "محوز",
    "محوصل",
    "محوض",
    "محتاط",
    "محوق",
    "حوكة",
    "حوائك",
    "حاوي",
    "متحير",
    "حوائض",
    "متحايل",
    "متحين",
    "متخابر",
    "متخبر",
    "مستخبر",
    "خابز",
    "خابط",
    "خوابط",
    "خابل",
    "خوابل",
    "خاتل",
    "خواتل",
    "ختال",
    "ختلة",
    "متخاتل",
    "خاتن",
    "خواتن",
    "ختنة",
    "خواثر",
    "خبلة",
    "خادش",
    "خوادع",
    "متخادع",
    "منخدع",
    "خاذل",
    "خرخار",
    "خارس",
    "خارط",
    "منخرط",
    "خارع",
    "خراق",
    "خازر",
    "مخزر",
    "خازف",
    "خوازن",
    "مختزن",
    "خواسئ",
    "خواسر",
    "خسرة",
    "خاسف",
    "خواسف",
    "خساف",
    "خسفة",
    "متخشع",
    "خواشع",
    "خشاع",
    "خاشف",
    "متخشن",
    "خاصب",
    "خاصر",
    "خاصل",
    "متخاصم",
    "مختصم",
    "خاضب",
    "متخضب",
    "خواضع",
    "خضاع",
    "خضعة",
    "مخضع",
    "خطأة",
    "متخاطب",
    "متخاطر",
    "خوافت",
    "خوافي",
    "خافر",
    "خواطب",
    "خوافض",
    "خفاض",
    "خفضة",
    "خوافق",
    "مختفي",
    "خلاد",
    "خلدة",
    "خالس",
    "خوالص",
    "خلصة",
    "متخالص",
    "متخلص",
    "متخالط",
    "مخلع",
    "منخلع",
    "خوائن",
    "متخالف",
    "مستخلف",
    "خوالق",
    "متخلق",
    "خلاة",
    "خوالي",
    "خامر",
    "خامص",
    "مخندق",
    "خانس",
    "خوانع",
    "خناع",
    "خنعة",
    "مخنع",
    "خانف",
    "منخنق",
    "خائر",
    "حوامي",
    "مخوصص",
    "خوائف",
    "خافي",
    "خفاة",
    "دوائم",
    "متخوف",
    "خوائر",
    "خاوي",
    "خوامل",
    "خمال",
    "خوائب",
    "متخير",
    "خوائط",
    "متخايل",
    "متخيم",
    "دوائب",
    "دوابر",
    "دواثر",
    "مستدبر",
    "دابغ",
    "دابق",
    "دابل",
    "مدبلج",
    "مدثر",
    "متدثر",
    "داثر",
    "مندثر",
    "متدجج",
    "مدجل",
    "داجم",
    "داجي",
    "دواجي",
    "داحر",
    "مندحر",
    "متدحرج",
    "دواحس",
    "داحص",
    "داحض",
    "مدحض",
    "دخال",
    "داخن",
    "متدخن",
    "دارج",
    "دوارج",
    "مندرج",
    "درسة",
    "متدارس",
    "مندرس",
    "دراع",
    "درعة",
    "دارك",
    "متدارك",
    "مستدرك",
    "دارم",
    "متدروش",
    "مندس",
    "داسع",
    "مدسم",
    "داشن",
    "مدشن",
    "دواعب",
    "دعبة",
    "متداعب",
    "داعث",
    "دواعر",
    "دعرة",
    "داعص",
    "داعق",
    "داعك",
    "مستدعي",
    "داغر",
    "دواغر",
    "دغار",
    "دغرة",
    "داغم",
    "مندفق",
    "مندفن",
    "مندق",
    "مدكك",
    "مندك",
    "دواكن",
    "مدكن",
    "دالج",
    "مدلج",
    "مندلس",
    "دالق",
    "دالك",
    "متدلك",
    "متدلل",
    "متدله",
    "مدلهم",
    "دامج",
    "دوامج",
    "دماج",
    "دمجة",
    "دواني",
    "مدمدم",
    "دلاف",
    "دلفة",
    "دوامس",
    "دامغ",
    "دوامغ",
    "دامل",
    "مدملج",
    "دامن",
    "دامي",
    "متدنس",
    "دناة",
    "ذواد",
    "داهر",
    "داهس",
    "داهق",
    "داهك",
    "مداهم",
    "داهم",
    "متدهن",
    "داهي",
    "دهاة",
    "دواهي",
    "مداور",
    "مداوم",
    "داوي",
    "مديث",
    "دوائن",
    "متداين",
    "ذابح",
    "ذابر",
    "ذوابل",
    "ذكار",
    "ذواخر",
    "ذخار",
    "ذخرة",
    "ذاخر",
    "ذوائد",
    "ذارب",
    "ذارح",
    "ذارع",
    "ذارف",
    "مذرف",
    "ذاعر",
    "ذواعر",
    "ذعار",
    "ذعرة",
    "ذاعف",
    "ذاقن",
    "ذواكر",
    "متذاكر",
    "مستذكر",
    "ذالق",
    "مذلل",
    "ذامر",
    "مذم",
    "ذانب",
    "منذهل",
    "ذاهل",
    "ذاهن",
    "ذاوي",
    "ذواة",
    "ذوائع",
    "ذائل",
    "ذوائل",
    "رائز",
    "روائز",
    "رازة",
    "مربت",
    "رابث",
    "مربث",
    "روابح",
    "رابد",
    "رابص",
    "رابض",
    "ربوض",
    "روابض",
    "رباض",
    "ربضة",
    "رجاز",
    "متربع",
    "رابق",
    "متربل",
    "مرابي",
    "مترتب",
    "راتع",
    "راثم",
    "راجب",
    "مرتج",
    "رواجح",
    "رجاح",
    "رجحة",
    "مترجح",
    "راجز",
    "رواجز",
    "رجزة",
    "رواجع",
    "رجاع",
    "رجعة",
    "راجف",
    "رواجف",
    "مترجف",
    "رواجل",
    "رجلة",
    "راجم",
    "مرجم",
    "راجن",
    "راجي",
    "رواجي",
    "رداع",
    "راحم",
    "رواحم",
    "رحام",
    "متراحم",
    "مترحم",
    "مسترخص",
    "راخف",
    "راخم",
    "متراخي",
    "راخي",
    "رواخي",
    "رادح",
    "مرتدع",
    "مردع",
    "رادف",
    "مردف",
    "رادم",
    "راذل",
    "رازح",
    "رازق",
    "مسترزق",
    "رازم",
    "رازن",
    "مترزن",
    "مترسب",
    "رساب",
    "رسبة",
    "رواسخ",
    "رساخ",
    "رسخة",
    "مترسخ",
    "راسف",
    "متراسل",
    "مترسل",
    "راسم",
    "ردعة",
    "مترسم",
    "مرتسم",
    "راسن",
    "راسي",
    "راشح",
    "رواشح",
    "رواشد",
    "رشدة",
    "مسترشد",
    "مترشش",
    "راشف",
    "مترشف",
    "مرتشف",
    "راشق",
    "متراشق",
    "راشم",
    "مرتشي",
    "رشاة",
    "رواشي",
    "رواصد",
    "رصدة",
    "متراصد",
    "متراص",
    "راصع",
    "مترصع",
    "راصف",
    "متراصف",
    "مترصف",
    "راصن",
    "راضخ",
    "رواضع",
    "رضعة",
    "راضف",
    "مرضف",
    "رواضي",
    "مترطب",
    "راطل",
    "راطم",
    "مرتعب",
    "رواعب",
    "رعاب",
    "رواغد",
    "رعبة",
    "مرتعد",
    "مرعد",
    "مترعرع",
    "مرعرع",
    "راعش",
    "مرعش",
    "رواعف",
    "رعفة",
    "رواعي",
    "متراغب",
    "مرغب",
    "راغد",
    "رغاد",
    "رغدة",
    "راغف",
    "راغن",
    "رافت",
    "رافس",
    "رفاض",
    "رفضة",
    "رفهة",
    "مترافع",
    "روافق",
    "مترافق",
    "مترفق",
    "رافه",
    "روافه",
    "مترفه",
    "مرتقب",
    "متراقب",
    "مترقب",
    "رفاد",
    "رفدة",
    "مترقرق",
    "مرقش",
    "رواقص",
    "متراقص",
    "راقط",
    "راقع",
    "مترقع",
    "مترقق",
    "راقن",
    "رواقن",
    "رقان",
    "رقنة",
    "مرتقي",
    "رواكب",
    "متركب",
    "راكز",
    "مرتكس",
    "راكض",
    "متراكض",
    "مرتكض",
    "مركض",
    "روامق",
    "رواكع",
    "ركاع",
    "مركع",
    "راكل",
    "متراكل",
    "متركل",
    "راكن",
    "متركن",
    "رامح",
    "روامح",
    "رمحة",
    "رامد",
    "مرمد",
    "رامز",
    "رامس",
    "رامش",
    "رامق",
    "رهاب",
    "رماق",
    "رمقة",
    "مرمق",
    "رامل",
    "مترمل",
    "مرمم",
    "مترمم",
    "روامي",
    "مرنح",
    "مترنق",
    "مرنق",
    "مترنم",
    "مرنم",
    "رهبان",
    "رواهب",
    "مترهب",
    "راهص",
    "مرهط",
    "راهف",
    "مرتهن",
    "رواهن",
    "رهنة",
    "متراهن",
    "مراهن",
    "روائب",
    "مروب",
    "روائج",
    "مروح",
    "متروح",
    "روائد",
    "متروض",
    "ربحة",
    "متروع",
    "متراوغ",
    "روائق",
    "مترول",
    "مروم",
    "متريض",
    "مريق",
    "مزئر",
    "زابد",
    "زابر",
    "زابل",
    "زجار",
    "منزجر",
    "زاجل",
    "زواجل",
    "زجلة",
    "زاحر",
    "مزحزح",
    "زحاف",
    "زحفة",
    "متزاحف",
    "زاحم",
    "متزاحم",
    "زواخر",
    "زخرة",
    "متزخرف",
    "مزدرد",
    "زارد",
    "زوارع",
    "زرعة",
    "زارف",
    "مزدري",
    "مزغرد",
    "زافت",
    "مزفت",
    "زافر",
    "زاكم",
    "زاكي",
    "زواكي",
    "زالج",
    "متزلزل",
    "مزلزل",
    "زالف",
    "مزلف",
    "مزلل",
    "زالم",
    "مزلم",
    "زامت",
    "زامج",
    "متزمخر",
    "متزمزم",
    "مزمزم",
    "زامع",
    "زامل",
    "زوامل",
    "زمال",
    "مزمل",
    "متزمل",
    "متزندق",
    "زواني",
    "متزهد",
    "زهاد",
    "زواهد",
    "زهدة",
    "زاهر",
    "زواهر",
    "زواهق",
    "زاهي",
    "زهاة",
    "زواهي",
    "متزاوج",
    "متزود",
    "زوائر",
    "متزاور",
    "زوائل",
    "زامر",
    "زوامر",
    "منزوي",
    "مستزيد",
    "مزير",
    "زوائغ",
    "زوائف",
    "متزين",
    "ساكب",
    "سواكب",
    "سكبة",
    "ساهر",
    "سواهر",
    "سهار",
    "سهرة",
    "سابت",
    "سابخ",
    "سابر",
    "مسبع",
    "مسبغ",
    "سابك",
    "منسبك",
    "سوابل",
    "سواتر",
    "سواجد",
    "سواجع",
    "سجاع",
    "ساجل",
    "متساجل",
    "متسجل",
    "مساجل",
    "ساجن",
    "ساجي",
    "سجاة",
    "سواجي",
    "ساحت",
    "سواحق",
    "سحقة",
    "سواخر",
    "سخار",
    "مستسخر",
    "سواخط",
    "سخاط",
    "سخطة",
    "مسخف",
    "سواخن",
    "سادج",
    "مسدد",
    "سوادر",
    "سدار",
    "سادل",
    "سواذج",
    "سذاج",
    "سذجة",
    "منسرب",
    "سارج",
    "سوارح",
    "سرحة",
    "متسرح",
    "سارد",
    "سررة",
    "مسارع",
    "متسرول",
    "مسرول",
    "ساطح",
    "متسطح",
    "منسطح",
    "ساطر",
    "سطاع",
    "سطعة",
    "مسطع",
    "مستعر",
    "ساعر",
    "مسعف",
    "مساعف",
    "ساعف",
    "سواعي",
    "سافح",
    "سوافح",
    "سفحة",
    "سوافر",
    "مسفر",
    "مسفسط",
    "سافط",
    "مسفط",
    "سافك",
    "مسفك",
    "منسفك",
    "سوافل",
    "متسفل",
    "مسفل",
    "سافه",
    "سوافه",
    "سفهة",
    "متسفه",
    "ساقر",
    "متساقط",
    "سواقط",
    "ساقف",
    "ساقي",
    "سواقي",
    "منسكب",
    "سواكت",
    "سواكر",
    "سكار",
    "ساكع",
    "مسكع",
    "سواكف",
    "متساكن",
    "متسكن",
    "سلبة",
    "مستلب",
    "منسلب",
    "سالت",
    "متسلح",
    "سالخ",
    "مسلس",
    "متسلطن",
    "مسلطن",
    "متسلف",
    "مسلف",
    "سالق",
    "سوالك",
    "سلاك",
    "سوالم",
    "متسالم",
    "سامح",
    "مستسمح",
    "سوامد",
    "سمدة",
    "متسمر",
    "سوامع",
    "متسامر",
    "سامط",
    "متسامع",
    "سوامق",
    "متسمم",
    "سامن",
    "سوامن",
    "سهاة",
    "سوامي",
    "سناح",
    "سنحة",
    "متساند",
    "ساهب",
    "متسهد",
    "متسهل",
    "مستسهل",
    "سواهم",
    "سهام",
    "سهمة",
    "ساهي",
    "مستاءة",
    "مسود",
    "سوائد",
    "مساور",
    "مسور",
    "مسوس",
    "سوائغ",
    "مسوف",
    "سوائق",
    "متسوق",
    "مسوم",
    "سوائب",
    "مسيب",
    "سوائح",
    "سوائر",
    "شوائط",
    "متساير",
    "مساير",
    "سواهي",
    "متسايف",
    "مسايف",
    "شائم",
    "متشبب",
    "مشبب",
    "شابح",
    "متشبع",
    "شواجن",
    "شابل",
    "متشبه",
    "متشتت",
    "متشاتم",
    "مشتي",
    "شاتي",
    "شواتي",
    "شاجب",
    "شاجر",
    "شاجع",
    "متشجع",
    "شاجن",
    "شجان",
    "شجنة",
    "شاجي",
    "شجاة",
    "شواجي",
    "شاحذ",
    "شاحم",
    "شواحم",
    "شحام",
    "شواحن",
    "شحان",
    "متشاحن",
    "شاخب",
    "شواخص",
    "شخاص",
    "شخصة",
    "متشخص",
    "شادي",
    "شوادي",
    "شداة",
    "شاذب",
    "شرخة",
    "شوارح",
    "شارخ",
    "شوارخ",
    "شردة",
    "متشارس",
    "شارط",
    "متشارط",
    "شوارع",
    "شارف",
    "شوارف",
    "شراف",
    "متشرف",
    "مستشرف",
    "شارق",
    "شوارق",
    "متشارك",
    "مشتري",
    "أواسن",
    "شواري",
    "متشازر",
    "شواسع",
    "شاطب",
    "مشطب",
    "منشطر",
    "شواطر",
    "مشطر",
    "شاطف",
    "مشاعب",
    "مشعبذ",
    "مستشعر",
    "متشعشع",
    "شاعف",
    "متشعل",
    "شاغب",
    "شواغب",
    "شغاب",
    "شغبة",
    "متشاغب",
    "شوافي",
    "منشغف",
    "شاغف",
    "منشغل",
    "متشاغل",
    "مستشف",
    "متشفع",
    "مستشفع",
    "مشفع",
    "شافه",
    "مشافه",
    "شفاة",
    "شواق",
    "شققة",
    "مشقق",
    "شوارك",
    "متشاكس",
    "مشكك",
    "شاكل",
    "متشاكل",
    "متشكل",
    "شاكم",
    "شواكي",
    "شالخ",
    "شمتة",
    "شماخ",
    "شمخة",
    "متشمت",
    "مشمت",
    "شكال",
    "شامر",
    "متشمر",
    "شوامس",
    "شوامل",
    "مشمم",
    "شانع",
    "مشنف",
    "شانق",
    "متشانق",
    "شاهب",
    "أشهاد",
    "شهاد",
    "شهدة",
    "متشهد",
    "شاهر",
    "مشتهر",
    "مشهر",
    "شواهق",
    "شاهم",
    "مشتهي",
    "متشاور",
    "مشور",
    "متشوش",
    "شائط",
    "شوائم",
    "مشوط",
    "شوائق",
    "مشوك",
    "متشوه",
    "متشايخ",
    "مشيخ",
    "مستشيط",
    "مشيط",
    "متشيطن",
    "مشيطن",
    "شوانع",
    "مشيع",
    "متشايع",
    "شوائن",
    "صواحي",
    "متصبب",
    "صابح",
    "متصبح",
    "صوابر",
    "متصبر",
    "مصبر",
    "مصطبر",
    "صابع",
    "مصبغ",
    "مصطبغ",
    "متصبن",
    "مصبن",
    "صابي",
    "صباة",
    "صوابي",
    "مصطحب",
    "صحاب",
    "متصاحب",
    "مستصحب",
    "متصحح",
    "مصحر",
    "صاحن",
    "صواخب",
    "صخبة",
    "متصاخب",
    "صاخر",
    "صداد",
    "صواد",
    "صددة",
    "صوادح",
    "صوادر",
    "متصدر",
    "مستصدر",
    "صادع",
    "مصدع",
    "منصدع",
    "صادغ",
    "متصادف",
    "صوادق",
    "صوارم",
    "متصادق",
    "صادم",
    "متصادم",
    "صداة",
    "صوادي",
    "صارح",
    "مصارح",
    "منصرح",
    "صوارخ",
    "متصارخ",
    "مستصرخ",
    "صوابئ",
    "صوائغ",
    "صارع",
    "متصارع",
    "منصرع",
    "صارف",
    "صرام",
    "متصرم",
    "صاري",
    "مصطب",
    "مصعب",
    "متصاعب",
    "صعاد",
    "صاعق",
    "متصعلك",
    "صواغر",
    "متصاغر",
    "صاغي",
    "صغاة",
    "صواغي",
    "مصغي",
    "مصافح",
    "متصافح",
    "صافد",
    "صافع",
    "متصافع",
    "صافق",
    "صوافي",
    "مصفي",
    "صاقر",
    "مصقر",
    "صاقع",
    "صاقل",
    "صالب",
    "صالت",
    "صلحاء",
    "صمدة",
    "صلحة",
    "متصالح",
    "مستصلح",
    "مصلد",
    "صوامت",
    "صمات",
    "صمتة",
    "صوامد",
    "صماد",
    "صانج",
    "صوانع",
    "متصنف",
    "صاهد",
    "متصاهر",
    "مصاهر",
    "صاهل",
    "صواهل",
    "متصاهل",
    "شواهر",
    "شهار",
    "شهرة",
    "مصوب",
    "ضواغن",
    "صوائب",
    "متصوف",
    "متصاول",
    "صوائم",
    "مصوم",
    "صاوي",
    "صوائح",
    "متصايح",
    "صوائد",
    "مصيدل",
    "صائر",
    "صوائر",
    "متصيف",
    "ضوائن",
    "ظأنة",
    "ضابح",
    "ضابر",
    "ضبطة",
    "ضابن",
    "مضاجر",
    "ضاجع",
    "ضواجع",
    "ضجاع",
    "متضاجع",
    "متضاحك",
    "ضاحل",
    "ضاحي",
    "ضحاة",
    "ضارح",
    "ضرار",
    "ضررة",
    "ضارس",
    "ضوارع",
    "متضرم",
    "مضرم",
    "ضاري",
    "ضراة",
    "ضواري",
    "ضاغث",
    "متضاغط",
    "منضغط",
    "ضاغم",
    "ضاغن",
    "ضغان",
    "ضغنة",
    "متضاغن",
    "متضافر",
    "مضافر",
    "ضالع",
    "مضطلع",
    "ضواغط",
    "ضغاط",
    "ضللة",
    "ضامخ",
    "ضامد",
    "متضمد",
    "ضوامر",
    "ضمرة",
    "متضمر",
    "متضامن",
    "ضمنة",
    "ضوامن",
    "مضني",
    "مضيئة",
    "متضور",
    "ضافي",
    "ضوافي",
    "منضوي",
    "ضاوي",
    "ضواة",
    "ضوائع",
    "ضائق",
    "متضيق",
    "ضائم",
    "ضوائم",
    "طوائع",
    "مطبب",
    "طابخ",
    "منطبخ",
    "متطبع",
    "منطبع",
    "طاحل",
    "طراء",
    "طرأة",
    "مستطرب",
    "طارح",
    "متطارح",
    "متطارد",
    "مستطرد",
    "متطرز",
    "طوارف",
    "متطرق",
    "طواعم",
    "مستطعم",
    "طواعن",
    "طعان",
    "متطاعن",
    "طاغي",
    "مطفئ",
    "طافح",
    "طوافح",
    "طفاح",
    "طوالب",
    "مطفف",
    "طافي",
    "طفاة",
    "طوافي",
    "طواغي",
    "طوالح",
    "طلحة",
    "طالس",
    "طماس",
    "طوالق",
    "طالم",
    "طامث",
    "طوامح",
    "طمحة",
    "طامر",
    "طوامس",
    "منطمس",
    "طامع",
    "طمعاء",
    "طوامع",
    "طمعة",
    "طمسة",
    "أطهار",
    "طواهر",
    "طهار",
    "طهرة",
    "متطهر",
    "طاهي",
    "طهاة",
    "طواهي",
    "ظوافر",
    "متطوس",
    "متطاوع",
    "متطوف",
    "طوائل",
    "متطول",
    "طام",
    "طوام",
    "طواة",
    "متطيب",
    "مطاير",
    "طوائش",
    "متطيف",
    "ظارف",
    "ظوالم",
    "ظفار",
    "ظفرة",
    "متظافر",
    "مستظل",
    "ظالع",
    "ظوالع",
    "ظلاع",
    "ظلعة",
    "ظالف",
    "متظلل",
    "متظلم",
    "ظوامئ",
    "ظمأة",
    "ظواهر",
    "ظهار",
    "ظهرة",
    "عوائل",
    "عوابث",
    "عباث",
    "عبثة",
    "عبسة",
    "عوابد",
    "عوابر",
    "عبار",
    "عوابس",
    "متعبس",
    "عابط",
    "معتبط",
    "معبق",
    "عواتب",
    "معاتب",
    "عتاق",
    "عتقة",
    "عاتل",
    "عاتم",
    "عواتم",
    "عتام",
    "عاتي",
    "عواتي",
    "عواثر",
    "مستعجب",
    "معجج",
    "عدال",
    "عجاز",
    "عاجف",
    "عواجل",
    "عاجم",
    "عاجن",
    "معتجن",
    "عادس",
    "عوادل",
    "عدلة",
    "منعدم",
    "عادن",
    "عوادي",
    "معتدي",
    "عاذب",
    "متعذب",
    "مستعذب",
    "عاذر",
    "عاذق",
    "عذلة",
    "عارب",
    "متعرب",
    "معرس",
    "عارش",
    "متعرض",
    "عوارف",
    "عرفة",
    "متعرف",
    "مستعرف",
    "عارق",
    "متعرقل",
    "عارك",
    "متعارك",
    "معترك",
    "عوارم",
    "عواري",
    "عوازب",
    "متعزب",
    "عازر",
    "معزر",
    "معزز",
    "متعزز",
    "عوازف",
    "عزاف",
    "عزفة",
    "متعازل",
    "عوازم",
    "عزام",
    "معزم",
    "معزي",
    "عاسر",
    "عاسف",
    "عاسل",
    "عاشب",
    "عواشب",
    "متعاشر",
    "معشش",
    "عاصب",
    "عواصب",
    "عاصد",
    "عاصر",
    "عواصر",
    "متعصر",
    "معتصر",
    "معصر",
    "منعصر",
    "معصف",
    "معاصف",
    "عشقة",
    "معتصم",
    "مستعصم",
    "مستعصي",
    "عواصي",
    "عاضد",
    "متعاضد",
    "معاضد",
    "عاضل",
    "معطب",
    "عواطر",
    "متعطر",
    "معطش",
    "متعطف",
    "مستعطف",
    "عطال",
    "مستعطي",
    "متعاظل",
    "متعظل",
    "متعظم",
    "مستعظم",
    "عافر",
    "متعفر",
    "متعفرت",
    "عافس",
    "عافي",
    "عفاة",
    "عوافي",
    "عواقد",
    "متعقد",
    "عاقص",
    "عاقف",
    "عواق",
    "عقاق",
    "عققة",
    "عواقل",
    "عكفة",
    "عاقم",
    "عاكر",
    "عاكز",
    "عواكس",
    "عكاس",
    "عكسة",
    "عواكف",
    "عكاف",
    "معتكف",
    "متعالج",
    "متعلف",
    "معلقم",
    "عالك",
    "معلك",
    "معلل",
    "متعلل",
    "علمة",
    "متعالم",
    "مستعلم",
    "عالن",
    "عوالن",
    "متعالي",
    "عوالي",
    "عامد",
    "معتمر",
    "عوامر",
    "عمار",
    "عامش",
    "متعامل",
    "عامه",
    "معمي",
    "معنبر",
    "عاند",
    "عواند",
    "متعاند",
    "عانز",
    "عناس",
    "عنسة",
    "معتنق",
    "متعانق",
    "معتني",
    "متعاهد",
    "عهار",
    "عواهر",
    "غاصة",
    "عاهل",
    "عواهل",
    "عهل",
    "متعوج",
    "متعوذ",
    "معور",
    "معوز",
    "عائز",
    "عوائز",
    "عندة",
    "معيل",
    "عالة",
    "عيل",
    "معولم",
    "عوائم",
    "معاون",
    "عاوي",
    "عائب",
    "عوائب",
    "متعايب",
    "متعاير",
    "متعيش",
    "عائش",
    "عيلى",
    "عوائش",
    "معيق",
    "معاين",
    "عوابئ",
    "عبأة",
    "غوابر",
    "مغبر",
    "متغابط",
    "متغابن",
    "غادق",
    "غادي",
    "غوادي",
    "غوادر",
    "غدرة",
    "متغرد",
    "مغرر",
    "غارز",
    "غوارز",
    "غراز",
    "غواسق",
    "منغرز",
    "غارس",
    "منغرس",
    "غارض",
    "متغرغر",
    "غارف",
    "مغترف",
    "غوارق",
    "غراق",
    "غرقة",
    "غارم",
    "متغزل",
    "غوازي",
    "منغسل",
    "غاش",
    "غششة",
    "غوامش",
    "غماش",
    "غمشة",
    "غصاب",
    "غواصب",
    "غصبة",
    "غاصن",
    "مغصن",
    "غواضب",
    "متغضب",
    "مغطرس",
    "متغطرف",
    "غواطس",
    "غطسة",
    "مغطش",
    "مستغفر",
    "غوافر",
    "مغتفر",
    "غافق",
    "غوافل",
    "غفال",
    "مستغفل",
    "غوالب",
    "متغلب",
    "متغالط",
    "مغلط",
    "غالظ",
    "غوالظ",
    "مغلغل",
    "غالف",
    "متغلف",
    "غالق",
    "غوالي",
    "مغتمر",
    "غوائص",
    "غوامر",
    "منغمر",
    "غامز",
    "غوامز",
    "غماز",
    "غمزة",
    "متغامز",
    "غامس",
    "مغتمس",
    "مغمض",
    "غوامق",
    "غوام",
    "مغم",
    "متغنج",
    "مغتنم",
    "غاني",
    "غناة",
    "غواني",
    "مستغيث",
    "غوائر",
    "غائص",
    "مغوص",
    "غاوي",
    "غوائب",
    "متغيظ",
    "مغيظ",
    "غوائم",
    "متفاتح",
    "مستفتح",
    "فتار",
    "فاتق",
    "متفتق",
    "مفتق",
    "منفتق",
    "فواتك",
    "متفتل",
    "مفتل",
    "منفتل",
    "مفتتن",
    "أوافل",
    "متفجع",
    "فحشة",
    "متفحش",
    "متفحص",
    "فواحص",
    "فحاص",
    "فحصة",
    "مفتحص",
    "فاحل",
    "فواحم",
    "فاخذ",
    "متفخر",
    "فداح",
    "فدحة",
    "فادي",
    "فداة",
    "فوادي",
    "مفرخ",
    "متفرد",
    "مستفرد",
    "فررة",
    "فوارز",
    "فراز",
    "فرزة",
    "متفرس",
    "مفترش",
    "فارش",
    "فوارض",
    "فراض",
    "فراط",
    "فوارط",
    "فرطة",
    "منفرط",
    "فوارع",
    "فراع",
    "فرعة",
    "متفرعن",
    "مفرعن",
    "فوارغ",
    "مستفرغ",
    "متفارق",
    "منفرق",
    "متفرقع",
    "فارك",
    "منفرك",
    "متفرنس",
    "فواره",
    "فراه",
    "فرهة",
    "فازر",
    "متفزر",
    "مفزر",
    "مستفز",
    "فازع",
    "فاسح",
    "متفسح",
    "منفسخ",
    "فواسد",
    "فسدة",
    "متفاسد",
    "فاسر",
    "فواسق",
    "مفسق",
    "مفسل",
    "متفشي",
    "فاصح",
    "متفصح",
    "فاصد",
    "متفصد",
    "منفصد",
    "متفصل",
    "فاصم",
    "فواضح",
    "فضحة",
    "متفاضح",
    "مفتضح",
    "منفضح",
    "مفضض",
    "فضال",
    "متفاضل",
    "متفضل",
    "فاضي",
    "فواضي",
    "فاطح",
    "فواطر",
    "فطار",
    "متفطر",
    "مفطر",
    "فاطس",
    "مفطس",
    "فاطم",
    "مفطم",
    "منفطم",
    "فاطن",
    "فواطن",
    "فطان",
    "متفطن",
    "مفطن",
    "فواعل",
    "فاغر",
    "فواغر",
    "فغار",
    "فواقد",
    "فقاد",
    "فقدة",
    "مفقد",
    "فاقر",
    "فاقس",
    "فاقص",
    "متفقص",
    "متفقفق",
    "فاقم",
    "متفقم",
    "فاقه",
    "متفقه",
    "مفقه",
    "فاكر",
    "متفكر",
    "مفتكر",
    "فكهة",
    "فكاه",
    "متفكه",
    "متفلت",
    "متفلج",
    "منفلج",
    "متفلح",
    "مفلق",
    "متفلق",
    "منفلق",
    "مفنن",
    "فناة",
    "فواني",
    "فاهم",
    "متفاهم",
    "متفهم",
    "مستفهم",
    "مفهم",
    "مفوت",
    "مفوج",
    "فائر",
    "فوائر",
    "مفاوض",
    "فوائق",
    "متفاوه",
    "متفوه",
    "مستفيد",
    "قابح",
    "مستقبح",
    "مقبح",
    "قابر",
    "قابس",
    "قوابض",
    "متقبض",
    "قابع",
    "قوابع",
    "متقابل",
    "قواتر",
    "قواتن",
    "قواتل",
    "متقاتل",
    "قاتن",
    "قاحب",
    "قواحب",
    "قاحط",
    "قواحط",
    "قاحف",
    "قواحل",
    "قاحم",
    "متقحم",
    "قادح",
    "متقدد",
    "قوادر",
    "قدار",
    "متقدر",
    "قوادم",
    "قدمة",
    "متقادم",
    "مستقدم",
    "قاذر",
    "مقذع",
    "قاذع",
    "قواذف",
    "متقاذف",
    "قوارئ",
    "قرأة",
    "متقرب",
    "قارح",
    "قوارح",
    "قارد",
    "قوار",
    "قررة",
    "متقرر",
    "قراة",
    "قواري",
    "قارش",
    "قراص",
    "متقارص",
    "مقرص",
    "مقترض",
    "قوارض",
    "قراض",
    "قرضة",
    "قارط",
    "مقرظ",
    "متقارع",
    "مقرع",
    "قارف",
    "مقرفص",
    "قوارس",
    "مقترن",
    "قارن",
    "متقارن",
    "متقزز",
    "قازم",
    "متقزم",
    "قاسح",
    "قواسح",
    "قاسر",
    "قاسط",
    "قساط",
    "قواسط",
    "قسطة",
    "قواسم",
    "مقتسم",
    "متقاسم",
    "قواسي",
    "قاشر",
    "قاشط",
    "متقشط",
    "قاشع",
    "منقشع",
    "مقشعر",
    "قاصب",
    "قواصر",
    "قصرة",
    "قصصة",
    "قواصد",
    "قصدة",
    "قاصع",
    "قاصف",
    "قواصف",
    "قصاف",
    "قصفة",
    "متقاصف",
    "متقصف",
    "منقصف",
    "قصام",
    "قصمة",
    "منقصم",
    "قاصي",
    "قصاة",
    "قواصي",
    "مستقصي",
    "قاضب",
    "متقضب",
    "قاضم",
    "قواضم",
    "قضام",
    "قضمة",
    "قواضي",
    "قاطب",
    "قواضب",
    "مقطب",
    "قواطب",
    "قطاب",
    "قاطر",
    "قواطر",
    "قاطف",
    "قواطن",
    "قطنة",
    "مقتعد",
    "متقعر",
    "قاعس",
    "قافش",
    "قافص",
    "قفلة",
    "منقفل",
    "متقلد",
    "قالص",
    "مقتلع",
    "قالع",
    "قوالع",
    "متقلع",
    "منقلع",
    "متقلقل",
    "متقامر",
    "متقمص",
    "مقمص",
    "قامط",
    "قوانئ",
    "مقنبل",
    "قوانع",
    "قوانت",
    "قنات",
    "قنتة",
    "مقتنص",
    "قوانط",
    "قناط",
    "متقنع",
    "مقتني",
    "قاني",
    "قواني",
    "قاهر",
    "قواهر",
    "قهرة",
    "متقهقر",
    "متقوت",
    "قوائد",
    "متقوض",
    "قوائل",
    "متقاول",
    "متقول",
    "كوابي",
    "قاوي",
    "قنعة",
    "مقيح",
    "متقيد",
    "متقايض",
    "متقيض",
    "مقيض",
    "قوائظ",
    "مقايظ",
    "متقيل",
    "مقين",
    "كابت",
    "منكبت",
    "كابح",
    "مكبد",
    "كابد",
    "متكبد",
    "مكابد",
    "متكابر",
    "مستكبر",
    "كابس",
    "كوابس",
    "كبسة",
    "منكبس",
    "كابش",
    "كابي",
    "كواتب",
    "متكاتب",
    "مستكتب",
    "منكتب",
    "كاتف",
    "متكاتف",
    "متكتف",
    "متكتكت",
    "كاتل",
    "متكتم",
    "كواتم",
    "كتمة",
    "مكتم",
    "منكتم",
    "كاثب",
    "متكثر",
    "مستكثر",
    "كاثم",
    "مكتحل",
    "متكحل",
    "كادس",
    "متكدس",
    "كواذب",
    "كواسب",
    "متكاذب",
    "متكذب",
    "مكذب",
    "متكربع",
    "متكرس",
    "متكركر",
    "كارم",
    "كواره",
    "كراه",
    "كرهة",
    "مستكره",
    "مكاري",
    "متكسب",
    "مكتسح",
    "كاسح",
    "كواسح",
    "كسحة",
    "كواسد",
    "كسبة",
    "كواشح",
    "كسار",
    "كواسف",
    "كساف",
    "كسفة",
    "منكسف",
    "كاسي",
    "كساة",
    "كواسي",
    "كشاح",
    "كشحة",
    "كاشر",
    "كواشر",
    "كشار",
    "متكشر",
    "مكشر",
    "كواشف",
    "متكاشف",
    "منكشف",
    "كاظم",
    "كواظم",
    "كظام",
    "كظمة",
    "كوافل",
    "كففة",
    "كواف",
    "متكعب",
    "متكافح",
    "كوافر",
    "متكفف",
    "متكفكف",
    "كفال",
    "كفلة",
    "متكافل",
    "متكفل",
    "مكفل",
    "كافن",
    "متكفن",
    "مكتفي",
    "كوافي",
    "كالب",
    "كوالب",
    "متكالب",
    "كوالح",
    "متكلل",
    "كالم",
    "مكالم",
    "كامت",
    "كوامد",
    "كامش",
    "مكمش",
    "متكمكم",
    "كوامل",
    "متكمل",
    "مستكمل",
    "كانز",
    "كانس",
    "كوانس",
    "كنسة",
    "متكنس",
    "مكتنس",
    "كانف",
    "مكتنه",
    "متكهرب",
    "كاهل",
    "كهنة",
    "كهان",
    "كواهن",
    "متكوثر",
    "متكور",
    "مكوكب",
    "كوائن",
    "كائد",
    "كوائد",
    "متكايد",
    "متكيس",
    "متكايل",
    "متلائم",
    "لابد",
    "لوابد",
    "لاهي",
    "لابث",
    "لوابث",
    "متلبث",
    "لابط",
    "لابن",
    "لوابن",
    "متلبنن",
    "ملبنن",
    "ملبي",
    "لاثم",
    "لواجئ",
    "لجاء",
    "لجأة",
    "لواحس",
    "لحاس",
    "لاجم",
    "لاجن",
    "متلجن",
    "لاحد",
    "لاحس",
    "متلاحظ",
    "لاحف",
    "متلحف",
    "لحقة",
    "ملحم",
    "متلاحم",
    "لاحم",
    "لواحم",
    "لاحن",
    "لواحن",
    "لحان",
    "لحنة",
    "متلدن",
    "مستلذ",
    "لذعة",
    "متلذع",
    "لوازب",
    "ملتزق",
    "ملزق",
    "لاسن",
    "ملاسن",
    "لواصق",
    "لصقة",
    "لاطخ",
    "متلطخ",
    "لاطع",
    "ملطف",
    "متلاطف",
    "متلطف",
    "مستلطف",
    "لاطم",
    "متلاطم",
    "ملاطم",
    "ملتطم",
    "لواعب",
    "متلاعب",
    "لاعق",
    "لواغق",
    "لعاق",
    "ملعق",
    "متلعلع",
    "لواعن",
    "لواغي",
    "ملاعن",
    "ملعن",
    "لاغد",
    "لاغز",
    "لاغط",
    "لاغم",
    "متلغم",
    "لاغي",
    "لافت",
    "لوافت",
    "متلفت",
    "مستلفت",
    "لافظ",
    "متلفظ",
    "متلفع",
    "لافع",
    "متلفف",
    "ملفف",
    "لافق",
    "متلفلف",
    "ملفلف",
    "متلقب",
    "لاقح",
    "متلقح",
    "لواقط",
    "متلقف",
    "ملتقف",
    "متلقم",
    "ملتقم",
    "متلقن",
    "ملاقي",
    "لاكز",
    "متلاكز",
    "لاكع",
    "لاكم",
    "متلاكم",
    "متلاكن",
    "لامج",
    "متلمج",
    "لامح",
    "لوامح",
    "لامز",
    "لوامس",
    "لماس",
    "متلامع",
    "متلمق",
    "متلملم",
    "ململم",
    "متلهب",
    "ملهب",
    "لاهج",
    "لواهج",
    "لهاج",
    "لاهف",
    "لواهف",
    "لهاف",
    "متلوث",
    "متلوح",
    "متلاوم",
    "متلوم",
    "متلوي",
    "متليث",
    "مليف",
    "لوائق",
    "متلين",
    "متشائل",
    "ماتن",
    "ماته",
    "متمثل",
    "ماجد",
    "مواجد",
    "مجاد",
    "مجدة",
    "متماجد",
    "متمجد",
    "متمجن",
    "ماحص",
    "متمحص",
    "ممحص",
    "ماحض",
    "ماحق",
    "مواحق",
    "منمحق",
    "مواحك",
    "مواحل",
    "ماخض",
    "متمخض",
    "ممتخض",
    "متمخط",
    "موادح",
    "ممتدح",
    "متمدرس",
    "مادق",
    "متمادي",
    "ماذق",
    "متمذهب",
    "ممرر",
    "متمترس",
    "ممترس",
    "متمرغ",
    "مارغ",
    "متمرفق",
    "ممرق",
    "موارق",
    "متمركز",
    "مارن",
    "موارن",
    "مازج",
    "متمازج",
    "ممتزج",
    "متمازح",
    "مازق",
    "مواسح",
    "ماسخ",
    "ماسد",
    "مسسة",
    "مواسك",
    "متمسكن",
    "متمسلم",
    "ماشج",
    "مواشط",
    "مشطة",
    "ماشق",
    "ممتشق",
    "مشنع",
    "ماشي",
    "مواشي",
    "ماصل",
    "متمضمض",
    "ممضمض",
    "مضاة",
    "مواضي",
    "مواطر",
    "متمطر",
    "متمطط",
    "ممعجم",
    "ماعد",
    "ماعك",
    "متمعن",
    "متمغرب",
    "ماغس",
    "ممفصل",
    "متمقت",
    "ممتقع",
    "ماقل",
    "ماكث",
    "متمكث",
    "ممكث",
    "متماكر",
    "مالذ",
    "مالس",
    "متملس",
    "مالص",
    "ممالق",
    "مالق",
    "موالك",
    "متمالك",
    "متملك",
    "لواهي",
    "ممنح",
    "متمندل",
    "متمنطق",
    "مواكر",
    "متمنع",
    "ممنع",
    "ممهد",
    "ماهد",
    "متمهد",
    "مهرة",
    "مواهر",
    "مهار",
    "متمهر",
    "متمهل",
    "مستمهل",
    "ممتهن",
    "ماهن",
    "متماوت",
    "موائت",
    "موائج",
    "مموضع",
    "أندية",
    "متمون",
    "مموه",
    "متموه",
    "أمجاد",
    "متمايز",
    "متميس",
    "موائع",
    "متميع",
    "مميع",
    "موائل",
    "متميل",
    "نائي",
    "نآة",
    "نوائي",
    "نابت",
    "نوابت",
    "نابج",
    "نابح",
    "نوابي",
    "نوابح",
    "نبحة",
    "متنابذ",
    "نابر",
    "متنابز",
    "نابش",
    "نوابض",
    "نباض",
    "نابع",
    "نوابع",
    "نوابل",
    "نبلة",
    "متنبل",
    "نابي",
    "نواتج",
    "ناتح",
    "ناتر",
    "ناتش",
    "متنتف",
    "نواثر",
    "نثرة",
    "متنثر",
    "منجب",
    "مناجب",
    "ناجب",
    "نواجح",
    "نواجم",
    "نجحة",
    "منجح",
    "متنجد",
    "مستنجد",
    "ناجد",
    "ناجر",
    "نواجز",
    "ناجس",
    "نواجس",
    "متنجس",
    "منجس",
    "نواجع",
    "ناجف",
    "ناجل",
    "ناجم",
    "متنجم",
    "مناجي",
    "نواجي",
    "منجي",
    "منتحب",
    "ناحب",
    "ناحت",
    "ناحر",
    "نواحر",
    "نحار",
    "نحرة",
    "نواحل",
    "متناحر",
    "ناحس",
    "ناحط",
    "ناحم",
    "متنحنح",
    "منحنح",
    "ناحي",
    "نواحي",
    "نواخب",
    "نخاب",
    "نخال",
    "نواخر",
    "نخار",
    "ناخز",
    "ناخس",
    "نواخس",
    "نخاس",
    "نخسة",
    "ناخط",
    "ناخع",
    "ناخل",
    "نواخل",
    "نوادل",
    "نخلة",
    "ناخم",
    "نادب",
    "نداب",
    "نادف",
    "نزحة",
    "ندال",
    "ندلة",
    "نوادم",
    "ندمة",
    "متنادم",
    "ناده",
    "نوازح",
    "نزاح",
    "نازر",
    "نازغ",
    "نوازف",
    "نزاف",
    "نواسخ",
    "متنزل",
    "نازه",
    "ناسئ",
    "نسأة",
    "نواشئ",
    "متنسب",
    "ناسج",
    "ناسح",
    "متناسخ",
    "ناسغ",
    "نواسغ",
    "نساغ",
    "نسغة",
    "نواسف",
    "نسفة",
    "متنسق",
    "ناسق",
    "نواسك",
    "بسكة",
    "متنسك",
    "ناسل",
    "نواسل",
    "نسال",
    "متناسل",
    "منتسل",
    "ناسم",
    "نواسم",
    "ناشب",
    "نسلة",
    "متنشب",
    "نواشد",
    "نشاد",
    "نواشر",
    "نواشف",
    "نشزة",
    "متنشر",
    "منشر",
    "نشابة",
    "نواشط",
    "متنشط",
    "منشف",
    "منتشل",
    "منشل",
    "ناشل",
    "متنصب",
    "منصت",
    "ناصت",
    "نواصح",
    "نصحة",
    "نواصر",
    "منتصح",
    "متناصر",
    "متنصر",
    "مستنصر",
    "نواصع",
    "متناصف",
    "نواصف",
    "متنصف",
    "مناصف",
    "متنصل",
    "نواضب",
    "نواضح",
    "نضاح",
    "نضحة",
    "متنضح",
    "ناضخ",
    "متنضد",
    "ناضد",
    "نواضر",
    "منضر",
    "متناطح",
    "متنطع",
    "ناطع",
    "ناطف",
    "نواطف",
    "نواطق",
    "نطقة",
    "متنطق",
    "ناطل",
    "متنظف",
    "نظمة",
    "متناظم",
    "متنظم",
    "ناعب",
    "نواعب",
    "ناعت",
    "نواعم",
    "متناعت",
    "منعت",
    "ناعر",
    "نواعس",
    "منتعش",
    "مستنعش",
    "ناعش",
    "نعقة",
    "نعاق",
    "نواعق",
    "متنعل",
    "منتعل",
    "متناعم",
    "متنعم",
    "ناعي",
    "نعاة",
    "نواعي",
    "ناغب",
    "ناغز",
    "متنغص",
    "ناغص",
    "متنغم",
    "ناغم",
    "نوافيث",
    "نوافث",
    "منافح",
    "نوائم",
    "نافح",
    "مستنفد",
    "نفذة",
    "مستنفر",
    "منتفش",
    "متنفش",
    "منفش",
    "نافش",
    "نافض",
    "نوافض",
    "نافط",
    "نوافط",
    "متنفط",
    "نوافع",
    "نفعة",
    "نوافق",
    "متنفل",
    "نافل",
    "منافي",
    "نافي",
    "نوافي",
    "متنقب",
    "ناقب",
    "ناقح",
    "نواقد",
    "ناقذ",
    "ناقر",
    "نواقر",
    "ناقس",
    "نواقص",
    "نقاص",
    "نقصة",
    "متنقص",
    "متنقض",
    "منتقض",
    "ناقط",
    "نواقع",
    "نقعة",
    "ناقف",
    "متناقل",
    "نواقم",
    "نقام",
    "منتقي",
    "متنكب",
    "ناكب",
    "ناكح",
    "نواكح",
    "نكحة",
    "متناكح",
    "منكح",
    "ناكد",
    "نواكد",
    "منكد",
    "متنكد",
    "نواكر",
    "نكار",
    "ناكز",
    "ناكس",
    "نواكس",
    "نكاس",
    "متنكس",
    "منتكص",
    "ناكص",
    "مستنكف",
    "منكل",
    "ناكل",
    "ناكه",
    "متنمر",
    "نامس",
    "نامش",
    "متنمل",
    "منمنم",
    "ناهب",
    "منتهج",
    "ناهج",
    "ناهد",
    "نهداء",
    "نهاد",
    "نواهد",
    "ناهر",
    "نواهر",
    "نهرة",
    "منتهر",
    "ناهز",
    "نواهز",
    "نهاز",
    "نهزة",
    "منتهز",
    "متناهش",
    "ناهش",
    "نهض",
    "نواهض",
    "نهاض",
    "نهضة",
    "متناهض",
    "مستنهض",
    "منتهض",
    "منهض",
    "ناهق",
    "نواهق",
    "ناهك",
    "ناهل",
    "نهل",
    "نهول",
    "نهلة",
    "نواهل",
    "منهل",
    "ناهم",
    "ناهي",
    "نهاة",
    "نواهي",
    "مناوئ",
    "نائح",
    "نوائل",
    "متناوم",
    "متنوم",
    "منوه",
    "ناوي",
    "نواء",
    "هابر",
    "هابش",
    "هوابط",
    "هباط",
    "هبطة",
    "منهبط",
    "مهبط",
    "هابل",
    "هوابل",
    "هبال",
    "هبلة",
    "مهاتر",
    "متهاتر",
    "هاتر",
    "هتفة",
    "متهتك",
    "منهتك",
    "هاتك",
    "هاتم",
    "متهجد",
    "هاجد",
    "هجود",
    "هجد",
    "هواجد",
    "هجاد",
    "هجدة",
    "هواجر",
    "هجار",
    "متهجر",
    "هاجس",
    "هواجس",
    "هاجع",
    "هجوع",
    "هجع",
    "هواجع",
    "هجاع",
    "هجعة",
    "متهاجم",
    "هاجي",
    "هجاة",
    "هواجي",
    "هوادئ",
    "متهدب",
    "هادب",
    "متهدج",
    "هواد",
    "هداد",
    "هددة",
    "متهدد",
    "هادر",
    "هوادر",
    "هدار",
    "هدرة",
    "مهدر",
    "هوادف",
    "هداف",
    "هدفة",
    "هادل",
    "هوادل",
    "متهدم",
    "هوادم",
    "هدمة",
    "منهدم",
    "مهادن",
    "متهادن",
    "هادن",
    "مهتدي",
    "هداة",
    "هوادي",
    "متهذب",
    "هاذب",
    "هاذر",
    "هواذر",
    "مهترئ",
    "هززة",
    "هوارب",
    "هراب",
    "هربة",
    "متهارب",
    "هارج",
    "هارد",
    "هارس",
    "متهرطق",
    "هارع",
    "هارف",
    "هارق",
    "مستهزئ",
    "هوازئ",
    "هزاء",
    "هزأة",
    "متهزج",
    "هازر",
    "هاز",
    "هواز",
    "هزاز",
    "هشم",
    "متهزز",
    "متهزع",
    "هازع",
    "هازل",
    "هوازل",
    "هزال",
    "هزلة",
    "منهزم",
    "هازم",
    "هاشم",
    "متهشم",
    "منهشم",
    "مهشم",
    "هاصر",
    "هاضب",
    "هاضل",
    "هاضم",
    "هواضم",
    "هضام",
    "هضمة",
    "منهضم",
    "هاطل",
    "هواطل",
    "همدة",
    "هلكى",
    "هوالك",
    "هلاك",
    "هلكة",
    "متهطل",
    "متهافت",
    "هافت",
    "متهفهف",
    "هواشم",
    "هشام",
    "هشمة",
    "متهالك",
    "منهلك",
    "مهتلك",
    "متهلل",
    "متهلهل",
    "هوامد",
    "هماد",
    "همسة",
    "مهمد",
    "هامر",
    "هوامر",
    "هامز",
    "هوامز",
    "متهامس",
    "هامس",
    "هوامس",
    "هماس",
    "هوامش",
    "متهامش",
    "هامع",
    "هوامع",
    "هامك",
    "هوامل",
    "",
    "هوانئ",
    "هناء",
    "هنأة",
    "مهند",
    "متهوج",
    "هائد",
    "هوائد",
    "متهود",
    "مهود",
    "متهوس",
    "متهوش",
    "هوائل",
    "متهاون",
    "مهون",
    "هائب",
    "هوائب",
    "هوائج",
    "متهايج",
    "متهيج",
    "هائف",
    "هوائف",
    "مهيكل",
    "مستهيم",
    "هيم",
    "هيام",
    "هوائم",
    "متهيم",
    "واظب",
    "وظاب",
    "وظبة",
    "وبلة",
    "واتد",
    "واثب",
    "متواثب",
    "موثف",
    "وثقة",
    "متوثق",
    "واثن",
    "متوجب",
    "وجاد",
    "وجدة",
    "متواجد",
    "واجز",
    "متوجس",
    "موجس",
    "متوجع",
    "وجاف",
    "وجفة",
    "واجل",
    "وجام",
    "وجمة",
    "متواجه",
    "متوحم",
    "موحم",
    "موحي",
    "ودعة",
    "متوادع",
    "متودع",
    "متوارد",
    "متورع",
    "وراف",
    "ورفة",
    "وسطة",
    "متورك",
    "متواري",
    "متوزر",
    "موزر",
    "وزعة",
    "وزاع",
    "متوزع",
    "متسخ",
    "متوسخ",
    "متوسد",
    "واسط",
    "وساط",
    "واسق",
    "متوسم",
    "مواسي",
    "واشج",
    "متوشح",
    "موشك",
    "موشم",
    "واشي",
    "موصد",
    "متواصف",
    "مواصل",
    "موصي",
    "وضحة",
    "متوضح",
    "مستوضح",
    "وضاع",
    "متواطئ",
    "متوطد",
    "متوظف",
    "متواعد",
    "مستوعد",
    "واعر",
    "موعر",
    "واعز",
    "وفدة",
    "متعظ",
    "موعك",
    "واعك",
    "وعاة",
    "واغر",
    "وغلة",
    "متوغل",
    "متوافد",
    "مستوفد",
    "متوفي",
    "متوقر",
    "وقاة",
    "متكئ",
    "متوكئ",
    "موكس",
    "واكف",
    "وكال",
    "وكلة",
    "متوكل",
    "متواكل",
    "واكن",
    "متولد",
    "متوالد",
    "مستولد",
    "متولع",
    "ولهة",
    "مولول",
    "متولول",
    "مستولي",
    "متوالي",
    "والي",
    "وامض",
    "وهاة",
    "واني",
    "واهب",
    "وهاب",
    "وهبة",
    "متواهب",
    "متوهم",
    "موهم",
    "وهان",
    "وهنة",
    "يفعة",
    "يوائس",
    "يوابس",
    "متيتم",
    "ياتن",
    "ياسر",
    "أيسار",
    "يواسر",
    "يافع",
    "يوانع",
    "يفعان",
    "يوافر",
    "يفاع",
    "ميقظ",
    "مستيقن",
    "متيمم",
    "يامن",
    "يوامن",
    "يمان",
    "متيامن",
    "متيمن",
    "عواشر",
    "إفرنج",
    "أهواز",
    "أوباش",
    "برائل",
    "أكال",
    "أكتوبر",
    "إكسير",
    "إكليروس",
    "إلق",
    "ألماس",
    "أمونيوم",
    "أناناس",
    "أندلس",
    "أنقليس",
    "أنوق",
    "إهاب",
    "أوج",
    "أورانيوم",
    "أورثوذكس",
    "أيار",
    "أيدز",
    "أيلول",
    "باريوم",
    "بجيش",
    "بديه",
    "برجد",
    "برطال",
    "بشام",
    "بطير",
    "بغام",
    "بغرير",
    "بنكام",
    "بهار",
    "بهرج",
    "بوتاسيوم",
    "بيرم",
    "تفلق",
    "تيفوئيد",
    "ثدام",
    "ثيل",
    "جساد",
    "جودو",
    "حاقول",
    "إحبال",
    "محرار",
    "حساف",
    "حشيف",
    "حاطوم",
    "حفف",
    "حقال",
    "حملاج",
    "حنبل",
    "حناط",
    "حنوط",
    "حو",
    "حيدر",
    "خبار",
    "خدار",
    "خرشاف",
    "خزرج",
    "خزاع",
    "خزعبيل",
    "تخليف",
    "خناس",
    "مخوض",
    "خيت",
    "خيلع",
    "دباج",
    "دراما",
    "درور",
    "دعاع",
    "دفتريا",
    "دفل",
    "دقوق",
    "دكتاتور",
    "دلبوث",
    "ديلم",
    "دمال",
    "دهس",
    "دهش",
    "دهق",
    "ديناصور",
    "ديناميك",
    "ذؤاف",
    "ذفاف",
    "ذولق",
    "ذؤب",
    "رؤال",
    "رؤام",
    "ربيد",
    "ربيض",
    "ربيق",
    "تربل",
    "أرتج",
    "مرجاس",
    "رحاق",
    "رخف",
    "رساغ",
    "رضاض",
    "رطام",
    "تركيع",
    "رهبل",
    "روماتيزم",
    "ريال",
    "زبن",
    "زريع",
    "مزعم",
    "زك",
    "زلاج",
    "زمخر",
    "زمهرير",
    "زها",
    "سدير",
    "سداس",
    "سديس",
    "سرعوب",
    "سرمد",
    "سرمق",
    "سريل",
    "سفار",
    "سافن",
    "سلبوت",
    "سها",
    "سهيل",
    "سيدا",
    "سيناريو",
    "شبام",
    "شزر",
    "شعف",
    "شقراق",
    "مشكاك",
    "شليخ",
    "شهاق",
    "شوار",
    "شياط",
    "صبير",
    "صفصاف",
    "صفيف",
    "صناج",
    "صهر",
    "صومال",
    "طفطاف",
    "طلم",
    "طياف",
    "عاسوق",
    "عبط",
    "إعتاق",
    "عرار",
    "عرعر",
    "عقاف",
    "عهر",
    "عواذ",
    "عياط",
    "غرانيت",
    "غرقئ",
    "غشت",
    "غضار",
    "فالوذ",
    "فتوش",
    "فرير",
    "فرزدق",
    "فراس",
    "فسفاط",
    "فسفور",
    "فصيد",
    "فقاس",
    "فقاع",
    "قاقوم",
    "قائمقام",
    "قداد",
    "مقرأ",
    "قراصيا",
    "قشار",
    "قنزع",
    "قنغر",
    "قواع",
    "قيق",
    "كاكاو",
    "كاليتوس",
    "كروميت",
    "كلوروفورم",
    "كهربا",
    "كهرطيس",
    "كهنوت",
    "كوبلت",
    "كولوم",
    "كويت",
    "كيلوس",
    "لاهوت",
    "لحاظ",
    "لاصوق",
    "لهاب",
    "مازور",
    "مؤق",
    "مخيخ",
    "مرددوش",
    "مريس",
    "مضير",
    "معدنوس",
    "مفاعل",
    "مقراج",
    "مكروسكوب",
    "مكاك",
    "مهل",
    "ممواج",
    "ميم",
    "ميموزا",
    "نافور",
    "نيفق",
    "نورس",
    "هباب",
    "هتر",
    "هتك",
    "هجيع",
    "هداب",
    "هدال",
    "إهذار",
    "هريس",
    "هزع",
    "هستيريا",
    "هلام",
    "هلهل",
    "هليكوبتر",
    "هليوم",
    "همس",
    "هند",
    "هندبا",
    "هندام",
    "هوام",
    "هوينا",
    "هيول",
    "وجور",
    "وحر",
    "ورس",
    "وسوط",
    "ميقاف",
    "يانصيب",
    "تيتم",
    "مياوم",
    "يونيسكو",
    "يونيسيف",
    "يونيه",
    "باسك",
    "بانجو",
    "بانوراما",
    "برازيل",
    "تبرقش",
    "تبرقع",
    "أميركا",
    "مقفاة",
    "مبتوت",
    "ميامين",
    "مناديب",
    "أرعاء",
    "مؤاتي",
    "متابيع",
    "مناكير",
    "مساليخ",
    "مغاليل",
    "مثؤوب",
    "مبتسر",
    "مبتغاة",
    "مبتلاة",
    "مترع",
    "متكأة",
    "مؤداة",
    "مرتجع",
    "مرتقع",
    "مرتقاة",
    "مراسيل",
    "مرهف",
    "مستباح",
    "مستثناة",
    "مستجاب",
    "مستدار",
    "مستدل",
    "مستشفاة",
    "مستعان",
    "مستعجم",
    "مستمطر",
    "مستنجع",
    "مستولاة",
    "مستواة",
    "مشتكاة",
    "مشتهاة",
    "مصطفاة",
    "مضناة",
    "معطاة",
    "معفاة",
    "مغتال",
    "مغماة",
    "مقتضاة",
    "مقفل",
    "مآكيل",
    "ملتاع",
    "ملقاة",
    "منتهاة",
    "منساب",
    "مهرق",
    "مهارق",
    "مواجز",
    "موحاة",
    "موصاة",
    "مومأة",
    "مبثور",
    "مبرعم",
    "مبري",
    "مبغاة",
    "مباغي",
    "مبهور",
    "متبناة",
    "متنصح",
    "متوفاة",
    "مثرود",
    "مثناة",
    "مجبول",
    "مجاذيب",
    "مجذور",
    "مجمر",
    "مجني",
    "مجهود",
    "مجاهيل",
    "محاسيب",
    "محشاة",
    "محاليل",
    "محلوج",
    "مخبوء",
    "مخبوءة",
    "مخابيل",
    "مخاذيل",
    "مخصور",
    "مخضب",
    "مخطوف",
    "مدبج",
    "مدبوغ",
    "مدلع",
    "مدهوش",
    "مذلق",
    "مذمم",
    "مرجوع",
    "مراجيع",
    "مردوم",
    "مرزأ",
    "مرزأة",
    "مرعبل",
    "مرموز",
    "مرمش",
    "مرهوب",
    "مزكوم",
    "مسترة",
    "مسحوت",
    "مساحيق",
    "مسطول",
    "مسعاة",
    "مساعي",
    "مسفوح",
    "مسفوك",
    "مسكوت",
    "مساليب",
    "مسهد",
    "مشابيب",
    "مشاريب",
    "مشطور",
    "مشاغيل",
    "مشكول",
    "مشنج",
    "مشاهير",
    "مصعر",
    "مضرس",
    "مضروس",
    "مطروف",
    "مطموس",
    "مطهم",
    "معتقة",
    "معرى",
    "معراة",
    "معازيل",
    "معصوب",
    "معفر",
    "معلى",
    "معلاة",
    "معناة",
    "مغذاة",
    "مغارير",
    "مغشي",
    "مذهوب",
    "مغلوط",
    "مغلوق",
    "مغمود",
    "مغموز",
    "مفاهيم",
    "مفلوق",
    "مقبور",
    "مقدود",
    "مقدد",
    "مقرور",
    "مقروح",
    "مقشب",
    "مقفع",
    "مقواة",
    "مكاسير",
    "مكموم",
    "مكمود",
    "مكناة",
    "ملسوع",
    "ملغوم",
    "ملوز",
    "ملولب",
    "ممخوض",
    "ممشوط",
    "مملوءة",
    "مناحيس",
    "منسوق",
    "منفوض",
    "منقوب",
    "منكوش",
    "منهوك",
    "منهي",
    "مهيض",
    "مهول",
    "مهبول",
    "مهابيل",
    "مهجر",
    "مهدي",
    "مهزول",
    "مهفهف",
    "مهموس",
    "مهمش",
    "موبوءة",
    "موشى",
    "موشاة",
    "موصوم",
    "موطأة",
    "موله",
    "موهوم",
    "ميمم",
    "الهمزة",
    "آ",
    "الآب",
    "الآبنوس - الآبنوس",
    "الآبنوسية",
    "الآجر",
    "الآح",
    "الآذريون",
    "الآنسون",
    "الآنك",
    "الآيين",
    "أبأه",
    "الأباءة",
    "أب",
    "ائتب",
    "استأب",
    "تأبب به",
    "الأباب",
    "الأبابة",
    "الأب",
    "أبت",
    "أبجد",
    "أبد",
    "تأبد",
    "الآبدة",
    "الأبد",
    "المؤبد",
    "ائتبر",
    "تأبر",
    "الإبارة",
    "الأبار",
    "الإبرة المغنيطيسية",
    "بيت الإبرة",
    "الأبور",
    "المأبر",
    "المئبر",
    "المئبرة",
    "الأبرا",
    "الإبريز",
    "الإبريسم",
    "إبريل",
    "أبز",
    "الأبزن",
    "أبسه",
    "أبيس",
    "أبش",
    "أبشه",
    "تأبش",
    "الأباشة",
    "أبص",
    "أبض",
    "تأبض",
    "الإباض",
    "الإباضية",
    "الأبوض",
    "المأبض",
    "تأبط",
    "الإباط",
    "الإبط",
    "تأبق",
    "أبلت",
    "آبل",
    "ائتبل",
    "تأبلت",
    "الأبابيل",
    "الإبالة",
    "الإبل",
    "الأبلة",
    "الأبيل",
    "الأبيلة",
    "المأبلة",
    "الإبليز",
    "إبليس",
    "تأبن",
    "الابن",
    "الأبنة",
    "المأبون",
    "تأبه",
    "الأبهة",
    "أبا",
    "أباه",
    "تأبى",
    "استأبى",
    "الأبا",
    "الأبوان",
    "أبى",
    "آبى",
    "الإبية",
    "المأباة",
    "أتب",
    "تأتبت",
    "الإتب",
    "المؤتب",
    "أت",
    "الإتاد",
    "الأترج",
    "أتم",
    "الأتم",
    "المأتم",
    "أتن",
    "آتنت",
    "استأتن",
    "الأتان",
    "أتا",
    "الإتاء",
    "الإتاوة",
    "الأتو",
    "أتى",
    "أتي",
    "تأتى",
    "استأتاه",
    "الآتية",
    "الأتي",
    "الميتاء",
    "الأثأب",
    "الأثب",
    "أث",
    "أثثه",
    "تأثث",
    "أثره",
    "آثره",
    "ائتثره",
    "استأثر",
    "الآثار",
    "الإثار",
    "الأثارة",
    "الإثر",
    "الأثر الأثر",
    "الأثر",
    "الأثرة",
    "الأثري",
    "الأثير",
    "وعند الطبيعيين",
    "الإيثارية",
    "التأثرية",
    "المئثرة",
    "المأثور",
    "أثف",
    "آثف",
    "تأثفت",
    "الأثفية",
    "أثل",
    "تأثل",
    "الأثال",
    "الأثل",
    "الأثلة",
    "أثم",
    "آثمه",
    "أثمه",
    "تأثم",
    "الأثام",
    "الإثم",
    "الإثمد",
    "أثا",
    "آثاه",
    "تآثوا",
    "أجت",
    "آجج",
    "أجج",
    "ائتجت",
    "تأججت",
    "الأجاج",
    "الأجوج",
    "آجده",
    "أجده",
    "الأجد",
    "آجره",
    "ائتجر",
    "استأجره",
    "الإجارة",
    "الأجر",
    "والأجر الحقيقي",
    "الأجرة",
    "الأجير",
    "استأجز",
    "الإجاز",
    "الإجازة",
    "الأجزخانة",
    "استأجل",
    "الأجل",
    "الإجل",
    "الأجيل",
    "المأجل",
    "أجم",
    "أجمه",
    "آجمه",
    "تأجم",
    "الأجم",
    "الأجوم",
    "أجن",
    "الإجانة",
    "المئجنة",
    "الميجنة",
    "أح",
    "أحى",
    "الأحاح",
    "الأحيح",
    "اتحد",
    "استأحد",
    "أحاد",
    "الأحد",
    "أحن",
    "آحنه",
    "الإحنة",
    "أخ",
    "إخ",
    "الأخ والإخ",
    "الأخيخة",
    "أخذ",
    "آخذته",
    "آخذه",
    "ائتخذ",
    "اتخذه",
    "استأخذ",
    "الإخاذة",
    "الأخذ والإخذ",
    "الأخذ والأخذ",
    "الإخذة",
    "الأخذة",
    "الأخيذ",
    "الأخيذة",
    "تأخر",
    "استأخر",
    "الآخرة",
    "الآخري",
    "الأخر",
    "الأخرة",
    "الأخرة والأخرة",
    "الأخرى",
    "الأخروي",
    "الأخير",
    "المئخار",
    "المؤخر",
    "الإخشيد",
    "أخا",
    "آخى",
    "أخى",
    "تآخيا",
    "تأخى",
    "الآخية",
    "الأخ",
    "دم الأخوين",
    "الأخت",
    "الأخية",
    "أدب",
    "أدبه",
    "الآدب",
    "الأدب",
    "الأديب",
    "التأديب",
    "المأدبة والمأدبة",
    "المؤدب",
    "تأدد",
    "الأدد",
    "الإد",
    "الإدة",
    "الأديد",
    "الأدرة",
    "المأدور",
    "الإدرجين أو الأيدروجين",
    "أدرينالين",
    "اللاأدرية",
    "أدم",
    "ائتدم",
    "استأدم",
    "الآدمي",
    "الإدام",
    "الأديم",
    "أدى",
    "آدى",
    "تآدى",
    "تأدى",
    "استأداه",
    "الأداء",
    "الإداوة",
    "إذ",
    "إذ ما",
    "إذن",
    "إذا",
    "تأذن",
    "استأذنه",
    "الآذن",
    "الأذان",
    "الأذانان",
    "الأذاني",
    "الأذن الأذن",
    "أذن الحمار",
    "آذان الأرنب",
    "آذان الجدي",
    "آذان الدب",
    "آذان الشاة",
    "آذان العنز",
    "آذان الفيل",
    "آذان الحيطان",
    "الإذن",
    "إذن البريد",
    "الأذنة",
    "الأذينة",
    "المأذون",
    "آذاه",
    "تأذى به",
    "الآذي",
    "الأذى",
    "الأذاة",
    "الأذية",
    "أربه",
    "أرب",
    "آرب",
    "تأرب",
    "استأرب",
    "الإرب",
    "الأربى",
    "الأربان",
    "الأربة",
    "الأربون",
    "المأرب",
    "المأربة",
    "أرثوذكس",
    "أرث",
    "تأرثت",
    "الإراث",
    "الإرث",
    "الأرثة",
    "أرج",
    "تأرجت",
    "الأريجة",
    "التاريخ",
    "الإردب",
    "الإردبة",
    "الإردواز",
    "أر",
    "الإرة",
    "الأرير",
    "أرز",
    "الأرز",
    "الأريز",
    "أرس",
    "آرس",
    "الإرس",
    "الإريس",
    "الأريس",
    "أرش",
    "ائترش",
    "الأرش",
    "أرضت",
    "أرض",
    "تأرض",
    "استأرضت",
    "الإراض",
    "الأرض",
    "الأرضة",
    "أرطت",
    "آرطت",
    "الأرطاة",
    "الأرغول",
    "آرفه",
    "أرف",
    "الأرفة",
    "آرقه",
    "أرقه",
    "ائترق",
    "تأرق",
    "الأرقان",
    "أركت",
    "الأراك",
    "الأركون",
    "أرم",
    "الأرم",
    "الأرم الإرم",
    "الإرم",
    "الأروم والأرومة",
    "الأرمادا",
    "أرنه",
    "أرن",
    "آرنه",
    "الإران",
    "الأرنة",
    "الأرنباني",
    "الأرنبة",
    "الأرندج",
    "أرا النار",
    "أري",
    "ائترى",
    "تأرى",
    "الآري",
    "الأروية",
    "الأري",
    "الأريحي",
    "أزب",
    "تأزبوا",
    "الإزب",
    "المئزاب",
    "أزج",
    "الأزج",
    "تأزح",
    "الأزوح",
    "الأزاذ",
    "أزر",
    "أزره",
    "ائتزر واتزر",
    "تأزر",
    "الإزار",
    "الإزارة",
    "الأزر",
    "الإزر",
    "المئزر",
    "المئزرة",
    "ائتز",
    "تأزز",
    "الأزز",
    "الأزة",
    "أزف",
    "آزفه",
    "تآزف",
    "الأزف",
    "أزق",
    "تأزق",
    "آزلت",
    "تأزل",
    "الآزل",
    "الأزل",
    "الأزلي",
    "المأزل",
    "أزم",
    "الآزم",
    "الآزمة",
    "الأزمة الأزمة",
    "المأزم",
    "أزى",
    "أزي",
    "آزى",
    "آزاه",
    "تآزيا",
    "تأزى",
    "الإزاء",
    "الإسباناخ",
    "الإسبيداج",
    "الأسبيرين",
    "الإستاج",
    "الأستاذ",
    "الإستار",
    "الإستبرق",
    "الإستيج",
    "أسد",
    "آسد",
    "تأسد",
    "استأسد",
    "الأسد",
    "داء الأسد",
    "المأسدة",
    "أسره",
    "استأسره",
    "الإسار",
    "الأسر",
    "الأسر الأسر",
    "الأسير",
    "الأسرب",
    "الأس الإس الأس",
    "الأس",
    "الأسطبة",
    "الأسطقس",
    "الأسطوانة",
    "وأساطين العلم أو الأدب",
    "أسف",
    "آسفه",
    "تأسف عليه",
    "الأسيف",
    "الاسفاناخ",
    "الإسفيداج",
    "الإسفين",
    "الإسقاله",
    "الأسكرجة",
    "الإسكارية",
    "الإسكاف",
    "الإسكلة",
    "الإسكيم",
    "أسل",
    "أسله",
    "تأسل",
    "الأسل",
    "الأسمنت",
    "أسمنجون",
    "آسنته",
    "تأسن",
    "الأسينة",
    "أسا",
    "أسى",
    "آساه",
    "ائتسى",
    "تآسوا",
    "تأسى",
    "الآسي",
    "الآسية",
    "الأسوة",
    "أسيا",
    "أشب",
    "ائتشبوا",
    "تأشب",
    "الأشابة",
    "أشره",
    "الأشارة",
    "الإشارة",
    "الأشرة",
    "المئشار",
    "الإشراس",
    "أش",
    "الأش",
    "الإشفى",
    "تأشن",
    "الإشنان",
    "أصد",
    "آصده",
    "الأصدة",
    "الإصدة",
    "الأصيد",
    "الأصيدة",
    "المؤصد",
    "المؤصدة",
    "أصره",
    "ائتصرت",
    "الآصرة",
    "الإصار",
    "الإصر",
    "الأصير",
    "المأصر",
    "أصت",
    "أصصه",
    "ائتصوا",
    "الأصاص",
    "الأصيص",
    "الأصيصة",
    "الأصطبة",
    "الإصطبل",
    "الإصطفلين",
    "الإصطيل",
    "الأصف",
    "آصل",
    "الأصالة",
    "الأصلي",
    "الأصلة",
    "الأصول",
    "الأصيل",
    "الأصيلة",
    "أضت",
    "ائتض",
    "الإضاض",
    "الإض",
    "أضم",
    "الإضاء",
    "الأضاة",
    "أطر",
    "انأطر",
    "تأطر",
    "الآطرة",
    "الأطر",
    "الأطرة",
    "الأطير",
    "المأطورة",
    "الأطربون",
    "أط",
    "الإطل",
    "الأيطل",
    "أطم",
    "آطم",
    "تأطم",
    "الأطام",
    "الأطم الأطم",
    "الأطوم",
    "آفخه",
    "اليأفوخ",
    "أفد",
    "استأفد",
    "أفر",
    "استأفر",
    "الأفرة",
    "الأفار",
    "المئفر",
    "الإفرنج والإفرنجة",
    "إفريقية",
    "أفٍ",
    "أفف",
    "تأفف",
    "الأفة",
    "اليأفوف",
    "تأفق",
    "الآفقة",
    "الأفق",
    "الأفيق",
    "آفكه",
    "ائتفكت",
    "تأفك",
    "الأفكة",
    "المؤتفكات",
    "الأفيل",
    "أفن",
    "تأفن",
    "أفندي",
    "أقته",
    "الأقط",
    "الأقة",
    "الإقليد",
    "الأقنوم",
    "الأقيانس",
    "آكده",
    "أكده",
    "تأكد",
    "الإكاد",
    "أكر",
    "آكره",
    "الأكرة",
    "الأكار",
    "الأكارة",
    "والأكسيجين",
    "أكف",
    "الإكاف",
    "الأكاف",
    "ائتك",
    "آكله",
    "تأكل",
    "استأكل",
    "الائتكال",
    "الآكلة",
    "الأكال",
    "الأكل الأكل",
    "الأكلة",
    "الأكولة",
    "الأكيل",
    "الأكيلة",
    "المئكال",
    "المأكل",
    "المأكلة",
    "استأكم",
    "المأكم المأكم",
    "المأكمة",
    "الأكنة",
    "أكونتين",
    "ألا",
    "إلى",
    "أولى أولاء أولئك",
    "ألب",
    "تألبوا",
    "الإلب",
    "الألبة",
    "المئلب",
    "آلت",
    "الألتة",
    "ألسه",
    "ألس",
    "آلسه",
    "تألس",
    "الألوس",
    "ألفه",
    "آلف",
    "آلفه",
    "ائتلف",
    "تألف",
    "الإلاف",
    "الألف",
    "الإلف",
    "المألف",
    "المؤلف",
    "ائتلق",
    "الإلاق",
    "الألاق",
    "الإلق",
    "الإلقة",
    "الألقة",
    "الألق",
    "الأولق",
    "المئلق",
    "ألك",
    "استألك",
    "الألوك",
    "الألوكة",
    "المألك",
    "المألكة والمألكة",
    "الملك",
    "الالكترون",
    "ألل",
    "ألله",
    "ائتل",
    "تألل",
    "الإل",
    "الألل",
    "الأليل",
    "الأليلة",
    "المئل",
    "إلا",
    "آلمه",
    "الألم",
    "الألومة",
    "الألمنيم",
    "أله",
    "ألهه",
    "تأله",
    "الإله",
    "الإلاهيات",
    "الإلاهة",
    "الأليهة",
    "اللهم",
    "ألي",
    "ألى",
    "ائتلى",
    "تألى",
    "الإلى الألى",
    "الألا",
    "الألو",
    "الإلوة الألوة",
    "الإلى والألى",
    "الألية",
    "الألي",
    "المئلاة",
    "أمته",
    "أمت",
    "الأمت",
    "أمج",
    "أمح",
    "أمده",
    "تأمد",
    "الآمد",
    "الآمدة",
    "أمر",
    "آمر",
    "تآمروا",
    "استأمره",
    "الاستئمارة",
    "الأمارة",
    "الإمارة",
    "الأمر",
    "الإمر",
    "الإمرة",
    "الأمرة",
    "الإمر الأمر",
    "الأمير",
    "التأمور والتامور",
    "التأمورة والتامورة",
    "التؤمري",
    "التؤمور",
    "المئمر",
    "المأمور",
    "أمريكة",
    "أمريكة الشمالية",
    "أمشير",
    "أمض",
    "تأمع",
    "الإمع",
    "أمق",
    "أمله",
    "تأمل",
    "الآمل",
    "المؤمل",
    "أممه",
    "ائتم",
    "تأمم",
    "استأم",
    "الآمة",
    "أمام",
    "الإمام",
    "الإمامة",
    "الإمامية",
    "الأم",
    "الأمم",
    "الأمة",
    "الإمة",
    "الأمي",
    "الأميم",
    "الأميمة",
    "المئم",
    "إما",
    "أمن",
    "ائتمن",
    "استأمن",
    "الأمانة",
    "الأمنة والأمنة",
    "الأمنة",
    "الأمون",
    "أمين",
    "الأمين",
    "الإيمان",
    "التأمين",
    "تأمه",
    "الأمه",
    "الأميهة",
    "أميت",
    "أموت",
    "أمى",
    "تأمت",
    "استأمى",
    "أمية",
    "وبنو أمية",
    "أن",
    "إن",
    "أنا",
    "أناضول أناطول",
    "الأنام",
    "أنبه",
    "الأناب",
    "الأنب",
    "الأنبوبة",
    "الأنبج",
    "الإنبيق",
    "أنت",
    "أنتم",
    "أنتما",
    "أنتاركتكا",
    "أنث",
    "آنثت",
    "تأنث",
    "الأنثى",
    "الأنثيان",
    "الأنيث",
    "المئناث",
    "المؤنث",
    "الإنجاص",
    "الأنجر",
    "الإنجيل",
    "أنح",
    "الأنزيم",
    "آنسه",
    "أنسه",
    "تآنسا",
    "استأنس",
    "الإنس",
    "الأنس",
    "الإنسان",
    "الإنسي",
    "الأنوس",
    "الأنيس",
    "الأنيسة",
    "الأنيسون",
    "المؤنسات",
    "المأنوس",
    "الأنسولين",
    "الأنشوجة",
    "أنض",
    "آنض",
    "أنفت",
    "آنفه",
    "ائتنفه",
    "تأنف",
    "استأنف",
    "الاستئناف",
    "الآنف",
    "الآنفة",
    "الأنف",
    "الأنفة",
    "الأنيف",
    "الأنيفة",
    "المئناف",
    "الانفلونزا",
    "آنقه",
    "الأنوق",
    "الأنكليس",
    "الأنموذج",
    "أننه",
    "تأننه",
    "الأنن",
    "الأنة",
    "الأننة",
    "المئنة",
    "أنى",
    "أني",
    "آناه",
    "تأنى",
    "استأنى",
    "الآناء",
    "الإناء",
    "أهب",
    "تأهب",
    "الإهاب",
    "الأهبة",
    "آهله",
    "ائتهل",
    "استأهل",
    "الآهل",
    "الإهالة",
    "الأهل",
    "الأهلي",
    "الأهلية",
    "الإهليلج",
    "الإهليجي",
    "أه",
    "أهى",
    "أو",
    "آوبه",
    "أوب",
    "تآوبا",
    "تأوب",
    "الأوب",
    "الأواب",
    "أوده",
    "انآد",
    "تأود",
    "الأويد",
    "استأور",
    "الآر",
    "الأوار",
    "الأواري",
    "الأورة",
    "أورانس",
    "الإوزي",
    "المأوزة",
    "آسه",
    "استآسه",
    "الأوس",
    "الأويس",
    "آفت",
    "أوقه",
    "تأوق",
    "الأواقي",
    "الأوق",
    "الإوقة",
    "الأوقة",
    "الأوقية",
    "ائتال",
    "تأول",
    "الآل",
    "الآلة",
    "الآلاتي",
    "الآلي أو الذاتي",
    "الإيال",
    "الإيالة",
    "أومه",
    "الأوام",
    "أونت",
    "تأون",
    "الإوان والإيوان",
    "الأون",
    "آه آه",
    "الأواه",
    "أوى",
    "ائتوى",
    "تآووا",
    "تأوى",
    "استأوى",
    "ابن آوى",
    "أي",
    "إي",
    "الأياب",
    "آيد",
    "آيده",
    "أيده",
    "تأيد",
    "الإياد",
    "المؤيد",
    "الأير",
    "الأيار",
    "الإيار",
    "أيس",
    "آيسه",
    "تأيس",
    "الآيسة",
    "الإياس",
    "أيش",
    "استأيك",
    "إيل",
    "إيلياء",
    "الأيل الأيل",
    "أيم",
    "ائتامت",
    "تأيمت",
    "الأيم",
    "أيم الله",
    "المأيمة",
    "المؤيمة",
    "الآن",
    "الأين",
    "أين",
    "أيها",
    "أيهات أيهات",
    "الأيهقان",
    "الإيوان",
    "تأيا",
    "الآية",
    "إيا",
    "إياة الشمس",
    "أيان",
    "الباء",
    "بابه",
    "البابوج",
    "البابونج",
    "الباذق",
    "الباسليق",
    "الباسور",
    "البؤبؤ",
    "البأج",
    "بأدل",
    "البأدلة",
    "أبأر",
    "ابتأر",
    "البآر",
    "البئر",
    "البئرة",
    "البئيرة",
    "البأز",
    "أبأس",
    "ابتأس",
    "تباءس",
    "تبأس",
    "البأس",
    "البؤس",
    "البؤسي",
    "بأش",
    "تبأط",
    "بؤل",
    "بأت",
    "بأى",
    "البب",
    "الببان",
    "الببة",
    "ببة",
    "الببر",
    "بتأ",
    "بتت",
    "تبتت",
    "البتات",
    "البت",
    "البتة",
    "البتي",
    "بتره",
    "أبتره",
    "انبتر",
    "تبتر",
    "الأبتران",
    "الباتر",
    "البتار",
    "البتراء",
    "البتور",
    "بتع",
    "أبتع",
    "البتاع",
    "البتع",
    "بتكه",
    "انبتك",
    "تبتك",
    "الباتك",
    "البتاك",
    "البتكة",
    "البتوك",
    "بتله",
    "انبتل",
    "تبتل",
    "البتل",
    "البتلاء",
    "البتلة",
    "البتيل",
    "البتيلة",
    "بثه",
    "أبثه",
    "باثه",
    "انبث",
    "استبثه",
    "تبثر",
    "الباثر",
    "البثر",
    "بثعت",
    "بثع",
    "تبثعت",
    "البثعة",
    "بثقه",
    "انبثق",
    "البثق",
    "البثنة",
    "البثاء",
    "البثي",
    "بجبج",
    "تبجبج",
    "البجباج",
    "البجباجة",
    "بجه",
    "باجه",
    "تباجا",
    "البجة",
    "أبجحه",
    "بجحه",
    "ابتجح",
    "تباجحوا",
    "البجاد",
    "البجد",
    "البجدة",
    "بجر",
    "أبجر",
    "تبجر",
    "الأبجر",
    "البجر",
    "البجراء",
    "البجرة",
    "البجير",
    "بجسه",
    "انبجس",
    "تبجس",
    "البجاس",
    "البجيس",
    "أبجله",
    "بجله",
    "الأبجل",
    "البجال",
    "البجلة",
    "البجيل",
    "البجامة",
    "البجم",
    "تبحبح",
    "بحباح",
    "البحباح",
    "البحبحي",
    "البحبوحة",
    "باحت",
    "البحت",
    "البحتر",
    "باحثه",
    "ابتحثه",
    "تباحثا",
    "تبحثه",
    "استبحثه",
    "البحاثة",
    "البحث",
    "البحثة",
    "البحيث",
    "تبحثر",
    "أبحه",
    "الأبح",
    "البحاح",
    "البحة",
    "بحدل",
    "بحر",
    "استبحر",
    "الباحر",
    "الباحرة",
    "الباحور",
    "الباحوراء",
    "البحار",
    "البحر",
    "البحرة",
    "البحيرة",
    "بحشل",
    "البحشل",
    "البحشلي",
    "بخبخ",
    "تبخبخ",
    "البخباخ",
    "البخات",
    "البخيت",
    "المبخوت",
    "تبختر",
    "البختري",
    "البخترية",
    "بخدج",
    "البخدج",
    "أبخره",
    "البخر",
    "تباخس",
    "الأباخس",
    "بخص",
    "البخص",
    "بخقت",
    "أبخق",
    "انبخقت",
    "أبخله",
    "بخله",
    "تبخل",
    "استبخله",
    "البخال",
    "البخل",
    "المبخلة",
    "تبخنقت",
    "البخنق",
    "المبخنق",
    "بخا",
    "أبخى",
    "البخو",
    "بدأ",
    "بدئ",
    "ابتدأ",
    "تبدأ",
    "البداء",
    "البداءة",
    "البدأة",
    "البدئ",
    "البديئة",
    "المبدأة",
    "بدحت",
    "تبادحوا",
    "تبدحت",
    "بد",
    "ابتد",
    "تباد",
    "تبدد",
    "استبد",
    "أباديد",
    "بداد",
    "البداد",
    "البد",
    "البدد",
    "البدة",
    "البديد",
    "البديدة",
    "أبدر",
    "بادر",
    "ابتدرت",
    "البادر",
    "البدارة",
    "البدر",
    "البدري",
    "البدرة",
    "المبتدر",
    "البدرون",
    "بدعه",
    "أبدع",
    "أبدعت",
    "تبدع",
    "استبدعه",
    "الابتداعية",
    "البادع",
    "البدع",
    "البدعة",
    "تبادلا",
    "الأبدال",
    "البدال",
    "البدل",
    "التبذل",
    "البدن",
    "البدنة",
    "المبدان",
    "بدهه",
    "بادهه",
    "ابتده",
    "تباده",
    "البديهة",
    "المبده",
    "ابتدى",
    "تبادى",
    "تبدى",
    "البادي",
    "البادية",
    "البداة",
    "البداوة",
    "أبذأ",
    "باذأه",
    "البذج",
    "بذحه",
    "بذحت",
    "تبذح",
    "البذح",
    "باذخه",
    "تبذخ",
    "البيذخ",
    "باذه",
    "ابتذه",
    "استبذر",
    "البذارة",
    "البذر",
    "البذيرة",
    "البيذار",
    "التبذارة",
    "بذرق",
    "البذرقة",
    "ابذعرت",
    "ابذقر",
    "بذله",
    "تبذل",
    "استبذله",
    "البذل",
    "البذلة",
    "المبذل",
    "المبذلة",
    "بذم",
    "بذو",
    "أبذى",
    "برأ",
    "برئ",
    "برؤ",
    "أبرأ",
    "بارأ",
    "برأه",
    "تبارأ",
    "تبرأ",
    "استبرأ",
    "البراء",
    "البراءة",
    "البرأة",
    "البريئة البرية",
    "برأل",
    "ابرأل",
    "تبرأل",
    "البرائل",
    "البربخ",
    "بربرت",
    "البربار",
    "البربري",
    "البربط",
    "البرت",
    "البرت البرت",
    "البرثن",
    "برجت",
    "أبرج",
    "تبرجت",
    "الإبريج",
    "البارجة",
    "التباريج",
    "البرجد",
    "البرجل",
    "برجم",
    "البرجمة",
    "أبرح",
    "تبرح",
    "براح براح",
    "البراح",
    "البرح",
    "برحى",
    "البرحاء",
    "البرحة",
    "البريح",
    "التباريح",
    "ابترد",
    "تبرد",
    "استبرد",
    "الأبردان",
    "الإبردة",
    "البارد",
    "الباردة",
    "البرادة",
    "البرد",
    "البرداء",
    "البردان",
    "البردة",
    "البراد",
    "البريد",
    "المبرد",
    "البردعة",
    "البرذعة",
    "برذن",
    "البرذون",
    "تباروا",
    "البر",
    "البراني",
    "برة",
    "البرية",
    "البرير",
    "المبرة",
    "بارزه",
    "تبارزا",
    "تبرز",
    "البراز",
    "البرزة",
    "البريزة",
    "البرس",
    "البرستاتة",
    "البرسام",
    "البرش",
    "برشق",
    "ابرنشق",
    "البراشم",
    "البرشام",
    "البرشم",
    "أبرص",
    "تبرص",
    "الأبرص",
    "البرص",
    "البرصة",
    "البريص",
    "البرصوم",
    "أبرضت",
    "برضت",
    "ابترض",
    "تبرضت",
    "البارض",
    "البراض",
    "البراضة",
    "البرض",
    "البرضة",
    "تبرطل",
    "البرطل",
    "البرطلة البرطلة",
    "البرطيل",
    "تبرطم",
    "البراطم",
    "البرطم",
    "البرطمان",
    "البرطوم",
    "البرعمة",
    "البرعوم",
    "البرعومة",
    "برغ",
    "البرغ",
    "البرغثة",
    "ابرغش",
    "البرغش",
    "البرغل",
    "البرغيل",
    "أبرقت",
    "الأبرق",
    "الاستبرق",
    "البارقة",
    "البراق",
    "البرق",
    "البرقاء",
    "البيرق",
    "البيرقدار",
    "ابرنقشت",
    "براقش",
    "برقط",
    "تبرقط",
    "برقعت",
    "البرقوع",
    "المبرقع",
    "المبرقعة",
    "برقل",
    "البرقيل",
    "أبرك",
    "بارك",
    "ابترك",
    "تبارك",
    "تبرك",
    "البراكاء",
    "البركة",
    "وحبة البركة",
    "البروك",
    "البريك",
    "البريكة",
    "المبرك",
    "البركار",
    "بركع",
    "تبركع",
    "البركع",
    "البرلنت",
    "أبرم",
    "البرامة",
    "البريمة",
    "البرم",
    "البريم",
    "البيرم",
    "برمهات",
    "البرني",
    "البرنية",
    "البرنز",
    "تبرنس",
    "البرنس",
    "البرنوف",
    "برنق",
    "البرنيقي",
    "أبره",
    "البرهة",
    "برهم",
    "البراهمة",
    "البرهمة",
    "أبرى",
    "البرة",
    "البروة",
    "البروتستينية",
    "البرواز",
    "البروفة",
    "البروق",
    "برى",
    "باراه",
    "ابترى",
    "تبارى",
    "تبرى",
    "البارياء",
    "الباري",
    "البارية",
    "البرى",
    "البراية",
    "البراءة البراية",
    "البري بري",
    "البزابز",
    "البزباز",
    "البزبز",
    "بزج",
    "بازج",
    "تبازج",
    "البزيج",
    "بزخه",
    "بزخ",
    "انبزخ",
    "تبازخ",
    "البزخ",
    "البزدرة",
    "البازور",
    "وبزر قطونا",
    "البزراء",
    "البزرة",
    "البزار",
    "البيزار",
    "البيزارة",
    "البيزر",
    "البيزرة",
    "المبزر",
    "المبزور",
    "ابتز",
    "البزازة",
    "البز",
    "البزز",
    "البزاز",
    "البزة",
    "بزع",
    "تبزع",
    "بزغت",
    "بزغه",
    "ابتزغ",
    "البازغة",
    "المبزغ",
    "البزق",
    "انبزل",
    "تبزل",
    "البازل",
    "البازلة",
    "البزال",
    "المبزال",
    "المبزلة",
    "بزم",
    "البازمة",
    "البزمة",
    "البزيم",
    "البزيمة",
    "بزا",
    "بزي",
    "أبزى",
    "تبازى",
    "البازي",
    "البزو",
    "بس بس",
    "بسئ",
    "أبسأه",
    "بسبس",
    "تبسبس",
    "البسباسة",
    "البسبس",
    "البستان",
    "أبسر",
    "باسرت",
    "ابتسرت",
    "ابتسر",
    "تبسر",
    "البسر",
    "البسرة",
    "المبسار",
    "المبسرة",
    "أبس",
    "انبس",
    "الباسة",
    "البس",
    "البساسة",
    "البسوس",
    "البسيس",
    "باسطه",
    "انبسط",
    "الباسط",
    "البساط",
    "البسط",
    "البسطة",
    "البسيط",
    "المتبسط",
    "أبسقت",
    "تبسق",
    "الباسقة",
    "بساقة القمر",
    "البسقة",
    "أبسل",
    "باسله",
    "ابتسل",
    "استبسل",
    "الباسل",
    "البسل",
    "البسلة",
    "البسلي",
    "البسول",
    "البسيل",
    "البسيلة",
    "المتبسل",
    "بسم",
    "تبسم",
    "البسيمة",
    "المبسم",
    "أبسن",
    "الباسنة",
    "البشت",
    "أبشرت",
    "باشر",
    "بشرت",
    "ابتشر",
    "تباشر",
    "تبشر",
    "البشار",
    "البشارة",
    "البشر",
    "البشرة",
    "البشرى",
    "البشور",
    "التباشير",
    "المبشرة",
    "المبشورة",
    "البشرف",
    "أبشت",
    "تبشش",
    "البشيش",
    "أبشعه",
    "استبشع",
    "الباشق",
    "البشق",
    "بشكت",
    "أبشك",
    "ابتشك",
    "البشكى",
    "البشكور",
    "أبشمه",
    "البشامة",
    "البشملة",
    "البشنين",
    "تبصبص",
    "البصابص",
    "البصباص",
    "أبصر",
    "باصره",
    "تباصر",
    "استبصر",
    "الباصر",
    "الباصرة",
    "البصارة",
    "البصر",
    "البصرة",
    "البصريون",
    "البصير",
    "المبصر",
    "بصصت",
    "تبصص",
    "بصع",
    "تبصع",
    "البصع",
    "البصيع",
    "بصق",
    "البصاقة",
    "بصله",
    "تبصل",
    "البصلة",
    "البصم",
    "البصمة",
    "بصا",
    "البصوة",
    "تبضبض",
    "البضابض",
    "البضباضة",
    "بضض",
    "ابتض",
    "تبضض",
    "البضاضة",
    "انبضع",
    "تبضع",
    "استبضع",
    "الباضعة",
    "البضع",
    "البضعة",
    "البضيعة",
    "أبطأ",
    "بطأه",
    "تبطأ",
    "استبطأه",
    "أبطح",
    "انبطح",
    "تبطح",
    "استبطح",
    "الأبطح",
    "البطاح",
    "البطحاء",
    "البطحة",
    "البطيحة",
    "أبطخ",
    "تبطخ",
    "البطيخ",
    "المبطخة",
    "أبطره",
    "البطرير",
    "البطير",
    "البيطار",
    "تبطرق",
    "البطارق",
    "البطرك",
    "بطش",
    "بطط",
    "البطاط",
    "البطيط",
    "المبط",
    "المبطة",
    "بطل",
    "تبطل",
    "الأبطولة",
    "الباطل",
    "البطم",
    "أبطن",
    "بطنه",
    "تبطن",
    "استبطن",
    "الأبطن",
    "الباطن",
    "الباطنة",
    "البطان",
    "البطن",
    "البطنان",
    "البطنة",
    "المبطان",
    "الباطية",
    "البظارة",
    "البظراء",
    "بظرم",
    "تبظرم",
    "أبظ",
    "بظا",
    "البعبع",
    "بعثه",
    "ابتعثه",
    "انبعث",
    "تباعث",
    "تبعث",
    "الباعث",
    "بعاث",
    "البعث",
    "البعثة",
    "انبعج",
    "تبعج",
    "باعده",
    "ابتعد",
    "تباعد",
    "تبعد",
    "استبعد",
    "الأبعد",
    "أما بعد",
    "وبعد",
    "البعد",
    "البعيد",
    "بعرت",
    "أبعر",
    "باعرت",
    "البعار",
    "البعر",
    "البعير",
    "المبعر المبعرة",
    "بعزقه",
    "تبعزق",
    "بعص",
    "تبعصص",
    "البعصوص",
    "أبعضت",
    "تبعض",
    "بعض الشيء",
    "بعط",
    "أبعطه",
    "بع",
    "البعاع",
    "بعق",
    "ابتعق",
    "انبعق",
    "تبعق",
    "الباعق",
    "بعكه",
    "بعك",
    "البعكوكاء",
    "البعكوكة",
    "باعل",
    "ابتعلت",
    "تباعل",
    "تبعلت",
    "استبعل",
    "البعل",
    "البعلة",
    "البعيم",
    "بعا",
    "أبعاه",
    "استبعاه",
    "بغبغ",
    "البغباغ",
    "البغبغ",
    "بغته",
    "باغته",
    "بغث",
    "البغاث",
    "البغثاء",
    "البغيث",
    "تبغدد",
    "بغداد",
    "بغر",
    "البغر",
    "البغرة",
    "بغزت",
    "البغاشة",
    "البغشة",
    "أبغضه",
    "باغضه",
    "بغضه",
    "تبغض",
    "البغضاء",
    "بغ",
    "البغل",
    "بغمت",
    "البغام",
    "البغو",
    "أبغاه",
    "ابتغى",
    "انبغى",
    "تباغى",
    "تبغى",
    "الباغة",
    "الباغي",
    "البغي",
    "البغية والبغية",
    "بقبقت",
    "البقباق",
    "انبقر",
    "تبقر",
    "الأبيقر",
    "الباقر",
    "البقر",
    "البقار",
    "البقير",
    "بقسماط",
    "بقط",
    "تبقط",
    "البقط",
    "بقعه",
    "تبقع",
    "الأبقع",
    "الباقعة",
    "البقعاء",
    "البقعة",
    "البقيع",
    "بقق",
    "البقاق",
    "المبق",
    "المبقة",
    "أبقل",
    "ابتقلت",
    "تبقلت",
    "الباقلاء",
    "الباقلي",
    "الباقول",
    "البقل",
    "البقال",
    "المبقلة",
    "تبقمت",
    "البقامة",
    "البقم",
    "بقي",
    "بقاه",
    "استبقاه",
    "الأبقى",
    "الباقي",
    "الباقية",
    "البقيا",
    "البقية",
    "بكأت",
    "بكؤ",
    "أبكأ",
    "بكبك",
    "البكبكة",
    "بكته",
    "أبكر",
    "باكره",
    "ابتكر",
    "تبكر",
    "الإبكار",
    "الباكور الباكورة",
    "البكارة",
    "البكر",
    "البكرة البكرة",
    "المبكار",
    "بكس",
    "بكش",
    "البكاش",
    "بكعه",
    "تباك",
    "الباك",
    "بكة",
    "بكل",
    "تبكل",
    "البكالة",
    "البكل",
    "البكلة",
    "البكيلة",
    "تبكم",
    "بكى",
    "أبكاه",
    "بكاه",
    "تباكى",
    "استبكاه",
    "التبكاء",
    "البلبال والبلبالة",
    "البلبل",
    "البلبول",
    "بلت",
    "تبلتع",
    "أبلج",
    "ابتلج انبلج تبلج",
    "البلجة",
    "أبلحت",
    "بالح",
    "تبالحا",
    "البلح",
    "بلخ",
    "تبلخ",
    "تبلخص",
    "أبلد",
    "البلد والبلدة",
    "البلدي",
    "بلدح",
    "تبلدح",
    "البلدح",
    "بلدم",
    "البلدم",
    "بلوره",
    "البلور البلور",
    "أبلس",
    "البلاس",
    "البلس",
    "البلسان",
    "تبلسم",
    "البلسن",
    "بلصه",
    "بالصه",
    "تبلص",
    "البلاصي",
    "أبلط",
    "بالط",
    "تبالطوا",
    "البلط",
    "البلطة",
    "البلطي",
    "بلطح",
    "أبلعه",
    "ابتلعه",
    "تبلعه",
    "البالوعة البلوعة",
    "البلعة",
    "البلاعة",
    "البلوع",
    "البلعم والبلعوم",
    "بالغ",
    "تبالغ",
    "البلاغ",
    "البلغة",
    "التبلغة",
    "البلوغ",
    "المبلغ",
    "البلغم",
    "بلق",
    "أبلق",
    "انبلق",
    "ابلولق",
    "البلقع",
    "بلله",
    "ابتل",
    "تبلل",
    "البالة",
    "البالول",
    "البلال",
    "البلالة",
    "البلة",
    "البليلة",
    "بليم",
    "أبلم",
    "الأبلم",
    "البلام",
    "البلان",
    "البلانة",
    "البلنط",
    "تباله",
    "تبله",
    "البلهنية",
    "البلهارسيا",
    "بلى",
    "بلاه",
    "بالى",
    "البلاء",
    "البلوى",
    "البلي",
    "البم",
    "بنجه",
    "البندار",
    "البندر",
    "البندقي",
    "بنزهير",
    "البنصر",
    "البنط",
    "في اصطلاح الطباعة",
    "بنق",
    "تبنك",
    "البنكام",
    "بنن",
    "البنان",
    "أبنى",
    "ابتنى",
    "انبنى",
    "استبنت",
    "الأبناء",
    "ابنم",
    "البانية",
    "البناء",
    "البناية",
    "البنت",
    "البناؤون الأحرار",
    "البنوة",
    "البنيان",
    "المبناة",
    "بهأ",
    "بهىء",
    "البهائية",
    "بهته",
    "بهت",
    "تباهت",
    "البهت والبهتان",
    "البهيتة",
    "بهتر",
    "بهث",
    "تباهث",
    "البهثة",
    "بهجه",
    "بهج",
    "أبهجت",
    "باهجه",
    "ابتهج",
    "تباهج",
    "استبهج",
    "المبهاج",
    "بهدل",
    "البهدل",
    "البهدلة",
    "بهره",
    "باهره",
    "ابتهر",
    "انبهر",
    "تبهر",
    "ابهار",
    "الأبهر",
    "الأبهران",
    "البهار",
    "البهر",
    "البهرة",
    "البهيرة",
    "تبهرج",
    "البهرج",
    "بهرم",
    "تبهرم",
    "بهرام",
    "البهرم",
    "بهزه",
    "أبهزه",
    "باهزه",
    "تبهزت",
    "بهس",
    "تبيهس",
    "البهس",
    "البيهس",
    "البيهسية",
    "بهش",
    "ابتهش",
    "تباهش",
    "البهش",
    "بهص",
    "أبهصه",
    "بهصل",
    "تبهصل",
    "بهضه",
    "أبهضه",
    "المبهوض",
    "بهط",
    "بهظه",
    "أبهظه",
    "الباهظ",
    "الباهظة",
    "بهق",
    "البهاق",
    "البهق",
    "تبهكنت",
    "البهكن",
    "بهله",
    "بهل",
    "أبهله",
    "باهل",
    "ابتهل",
    "تباهل",
    "تبهل",
    "استبهله",
    "أبهل",
    "البهل",
    "البهلول",
    "تبهم",
    "استبهم",
    "الإبهام",
    "الأبهم",
    "البهمة",
    "البهيم",
    "البهيمة",
    "المبهم",
    "المبهمة",
    "البهنانة",
    "بهنس",
    "تبهنس",
    "البهنس",
    "بها",
    "أبهى",
    "باهاه",
    "بهى",
    "ابتهى",
    "تباهى",
    "البهو",
    "البها والبهاء",
    "باء",
    "أباء",
    "باوأه",
    "تباوأ",
    "استباء",
    "الباءة",
    "المباءة",
    "باب",
    "تبوب",
    "الباب",
    "البابي",
    "البوتقة",
    "أباثه",
    "ابتاثه",
    "استباثه",
    "باج",
    "بوج",
    "انباج",
    "تبوج",
    "البائج",
    "البائجة",
    "الباج",
    "أباحه",
    "استباحه",
    "الإباحة",
    "أباخ",
    "أباره",
    "ابتاره",
    "البوار",
    "البوري",
    "البورق",
    "البوز",
    "باسه",
    "باوشه",
    "بوشوا",
    "انباش",
    "تباوشا",
    "تبوشوا",
    "البوش",
    "البوشي البوشي",
    "انباص",
    "استباصه",
    "البوصي",
    "باط",
    "بوط",
    "باع",
    "انباع",
    "تبوع",
    "البائع",
    "البواع",
    "أباغ",
    "البوغاء",
    "باقٍ",
    "انباق",
    "تبوق",
    "البائق",
    "البائقة",
    "البوقة",
    "البواق",
    "باوكهم",
    "انباك",
    "البائكة",
    "البوكاء",
    "البوكة",
    "بال",
    "أباله",
    "بول",
    "استباله",
    "البوال",
    "البول",
    "البولة",
    "المبال",
    "البوام",
    "بانه",
    "البوان",
    "البون والبون",
    "استباه",
    "استبيه",
    "الباه",
    "الباهة",
    "البوه",
    "البوهة",
    "البو",
    "البوية",
    "البيب",
    "البيبة",
    "بات",
    "أباته",
    "بيت",
    "تبيت",
    "البيات",
    "البيت",
    "البيتة",
    "البيوت",
    "البيوتة",
    "بيح",
    "البيحان",
    "أباده",
    "البيد",
    "البيداء",
    "البيدانة",
    "البيدر",
    "البيذق",
    "باضت",
    "أباض",
    "أبيض",
    "بايضه",
    "ابتاض",
    "ابياض",
    "الأبيض",
    "البياض",
    "البيضاء",
    "المبيضة",
    "تبيطر",
    "البيطر",
    "البيطرة",
    "باظ",
    "البيظ",
    "باعه",
    "أباعه",
    "بايعه",
    "ابتاعه",
    "تبايعا",
    "استباعه",
    "البياعة",
    "البيعة",
    "البيوع",
    "باغ",
    "تبيغ",
    "البيغ",
    "بيقر",
    "تبيقر",
    "البيقر",
    "البيقرة",
    "البيلم",
    "البيمارستان",
    "بان",
    "أبان",
    "باينه",
    "بين",
    "تباينا",
    "استبان",
    "البائن",
    "البائنة",
    "البانة",
    "البيان",
    "البيانلا",
    "بين بين",
    "البين",
    "البيون",
    "البينة",
    "بياه",
    "تبياه",
    "البي",
    "التاء",
    "التأتاء",
    "البى",
    "أتأره",
    "التأرة",
    "التؤرور",
    "تأز",
    "تئق",
    "أتأق",
    "المتأق",
    "أتأمت",
    "تاءم",
    "التؤام",
    "التؤامية",
    "التؤم",
    "التئيم",
    "التوءم",
    "التوءمة",
    "المتئام",
    "تتاءن",
    "تتأن",
    "تببه",
    "تبتب",
    "التبوت",
    "التبر",
    "أتبعت",
    "تابعه",
    "تتابعت",
    "تتبعه",
    "استتبعه",
    "الاتباعية",
    "التابع",
    "التابعي",
    "التباعة",
    "التبع",
    "التبعية",
    "التبيع",
    "التبغين",
    "أتبله",
    "التبال",
    "التبل",
    "التوبال",
    "التوبل",
    "اتبن",
    "التبان",
    "التبن",
    "المتبن",
    "المتبنة",
    "التجاب",
    "التجباب",
    "تاجر",
    "اتجر",
    "التاجر",
    "التاجرة",
    "التجارة",
    "المتجرة",
    "تحت",
    "التحت",
    "تحتحه",
    "تتحتح",
    "التحتحة",
    "التحتربة",
    "أتحفه",
    "اتحفه",
    "التحفة",
    "التخت",
    "التختة",
    "التختاخ",
    "التختروان",
    "أتخ",
    "التخ",
    "تخذ",
    "التخمة",
    "التدرة",
    "تراجيديا",
    "الرمد الحبيبي",
    "أترب",
    "تاربه",
    "تترب",
    "الترائب",
    "التراب",
    "الترباء",
    "التربي",
    "التربس",
    "التربنة",
    "التربين",
    "تترتر",
    "الترتور",
    "ترج",
    "الترج",
    "التريج",
    "ترجم",
    "الترجمان",
    "الترجمة",
    "أترحه",
    "ترحه",
    "تترح",
    "المتارح",
    "المترح",
    "الترخ",
    "تر",
    "أتر",
    "الأترور",
    "التر",
    "ترز",
    "أترزه",
    "التراز التراز",
    "الترزي",
    "تترس",
    "التارس",
    "التراسة",
    "التراس",
    "الترسة",
    "المترس",
    "ترص",
    "أترصه",
    "ترصه",
    "المترصات",
    "ترعه",
    "أترع",
    "اترع",
    "تترع",
    "الأترع",
    "الترع",
    "التراع",
    "أترف",
    "ترفه",
    "تترف",
    "استترف",
    "الترفة",
    "الترفاس",
    "تاركه",
    "اترك",
    "تراك",
    "تتاركوا",
    "الترك",
    "التركة",
    "التريك",
    "التريكة",
    "الترمسة",
    "ترموجراف",
    "ترموجراء",
    "التره",
    "الترهة",
    "أترى",
    "أتسع",
    "التاسوعاء",
    "التسعة",
    "التسيع",
    "تعب",
    "تعتعت",
    "تتعتع",
    "التعاتع",
    "أتعسه",
    "التعس",
    "التعل",
    "تغب",
    "أتغبه",
    "تغتع",
    "تغر",
    "تفئ",
    "التفيئة",
    "تفتف",
    "التفتاف",
    "تفث",
    "التفث",
    "أتفحه",
    "المتفحة",
    "أتفر",
    "التفرة",
    "تففه",
    "التفافة",
    "التفان",
    "التفة",
    "أتفله",
    "التنفل التنفل",
    "التفال",
    "التفل",
    "المتفلة",
    "تفنه",
    "التفن",
    "أتقنه",
    "تتقنت",
    "التقن",
    "التقنة",
    "تك",
    "استتك",
    "التك",
    "التكة",
    "التكيك",
    "المتك",
    "التكية",
    "التلب",
    "التولب",
    "التلبائي",
    "تلتل",
    "التلاتل",
    "التلتلة",
    "تلد",
    "أتلد",
    "التلاد",
    "التلد",
    "التليد",
    "التلسكوب",
    "أتلع",
    "تتالع",
    "تتلع",
    "استتلع",
    "التلعة",
    "أتلفه",
    "التلفة",
    "المتلاف",
    "المتلف",
    "المتلفة",
    "تلك",
    "تلله",
    "التلالة",
    "التلل",
    "التلة",
    "التلى",
    "التلول",
    "التليل",
    "المتل",
    "المتلول",
    "التليسة",
    "التلام",
    "التلم",
    "أتله",
    "تلهه",
    "اتله",
    "تتله",
    "المتلة",
    "المتلهة",
    "تلا",
    "تلاه",
    "تلي",
    "أتلت",
    "تالاه",
    "تتالت",
    "استتلى",
    "التالي",
    "التلاء",
    "التلاوة",
    "التلو",
    "التلية",
    "المتالي",
    "تمره",
    "تمرت",
    "أتمر",
    "تتمر",
    "التامر",
    "التامور",
    "التامورة",
    "التماري",
    "التمر",
    "التمار",
    "تمك",
    "أتمك",
    "التامك",
    "التامول",
    "تم",
    "أتمت",
    "تمم",
    "تتام",
    "استتم",
    "التتمة",
    "التمام",
    "التمامة التمامة",
    "التمامة",
    "التم",
    "التمم",
    "تميم",
    "المتم",
    "تمه",
    "المتماه",
    "تنأ",
    "التانبول",
    "التنبال",
    "التنبالة",
    "التنبل",
    "التنبول",
    "تنتلت",
    "التنتال",
    "التنتالة",
    "التنتل",
    "التنتيل",
    "تنتن",
    "التنتنة",
    "التنوج",
    "تنخ",
    "أتنخه",
    "تانخه",
    "التنار",
    "التنور",
    "التنضب",
    "التنوفة",
    "التنوفية",
    "التنكة",
    "التنوم",
    "التن",
    "ته ته",
    "تهته",
    "تتهته",
    "التهاتة",
    "التهتهة",
    "التيهور",
    "تهم",
    "تاهم",
    "اتهمه",
    "تتهم",
    "تهامة",
    "التهمة",
    "المتهام",
    "تاب",
    "استتابه",
    "التوبة",
    "التوتياء",
    "المتوتة",
    "التائج",
    "التاج",
    "التاجي",
    "تار",
    "تير",
    "أتار",
    "تاوره",
    "التارة",
    "التور",
    "التوراة",
    "التورة",
    "تاف",
    "التوفة",
    "التويفة",
    "تتوق",
    "التال",
    "التولة",
    "التويلة",
    "التولج",
    "التومة",
    "توهه",
    "التوه",
    "أتوى",
    "التو",
    "التوة",
    "توى",
    "المتواة",
    "أتاحه",
    "التيحان والتيحان",
    "التياح",
    "المتياح",
    "المتيح",
    "التيد",
    "التير",
    "تاز",
    "تايزه",
    "تتيز",
    "تس تس",
    "تاس",
    "تيست",
    "تايسه",
    "تيس",
    "تتايس",
    "استتيست",
    "المتيوساء",
    "تاع",
    "أتاع",
    "تايع",
    "تيع",
    "تتايع",
    "اتايع",
    "تتيع",
    "الأتيع",
    "التاعة",
    "التيع",
    "التيعة",
    "التيعان",
    "التيفود",
    "تام",
    "أتام",
    "تيمه",
    "اتام",
    "التيم",
    "التيماء",
    "التيمة",
    "التين",
    "التين الشوكي",
    "التيان",
    "المتينة",
    "أتاهه",
    "تيهه",
    "الأتيه",
    "التيهاء",
    "التيهان",
    "المتيه",
    "المتيهة",
    "التيوقراطية",
    "الثاء",
    "تثأب",
    "الثؤباء",
    "ثأثأ",
    "تثأثأ",
    "ثئد",
    "الثأد",
    "الثأداء",
    "الثأدة",
    "أثأر",
    "استثأر",
    "أنا له يا رسول الله الموتور الثائر",
    "ثألله",
    "تثألل",
    "الثؤلول",
    "ثئي",
    "الثأي والثأي",
    "اثبأج",
    "ثبت",
    "استثبت",
    "الثبات",
    "الثبت",
    "المثبت",
    "ثبج",
    "الثبج",
    "الثبجة",
    "اثبجر",
    "ثبرت",
    "ثبره",
    "تثابروا",
    "الثبار",
    "الثبرة",
    "أثبطه",
    "ثبطه",
    "تثبط",
    "ثبقت",
    "أثبن",
    "الثبان",
    "الثبنة",
    "الثبين",
    "المثبنة",
    "ثبى",
    "الثبة",
    "ثتم",
    "انثتم",
    "تثتم",
    "الثتى",
    "الثتاة",
    "ثجثج",
    "تثجثج",
    "الثجاج",
    "الثجة",
    "الثجوج",
    "الثجيج",
    "المثج",
    "ثجر",
    "الأثجر",
    "الثجر",
    "الثجرة",
    "الثجير",
    "المثجر",
    "ثجل",
    "الأثجل",
    "ثجمه",
    "ثجم",
    "أثجمت",
    "ثجمت",
    "ثحثح",
    "الثحثحة",
    "استثخن",
    "الثخن",
    "الثخنة",
    "ثدق",
    "انثدق",
    "ثدم",
    "الثدام",
    "ثدن",
    "ثداه",
    "ثربه",
    "أثرب",
    "الثرب",
    "ثردت",
    "أثرد",
    "الثرد",
    "الثريد",
    "المثراد",
    "المثردة",
    "ثرر",
    "الثر",
    "الثرعلة",
    "الثرعامة",
    "ثرمه",
    "أثرمه",
    "انثرم",
    "الأثرم",
    "الأثرمان",
    "ثرمد",
    "ثرمل",
    "الثرملة",
    "ثريوم",
    "ثرا",
    "المثراة",
    "ثط",
    "ثطا",
    "ثعب",
    "انثعب",
    "الأثعوب",
    "الثعب",
    "ثعبان السمك",
    "الثعبة",
    "الثعوب",
    "المثعب",
    "ثعثع",
    "الثعثع",
    "ثعجر",
    "اثعنجر",
    "المثعنجر",
    "الثعد",
    "ثعر",
    "أثعر",
    "الثعر",
    "ثعرر",
    "الثعارير",
    "الثعرور",
    "ثعط",
    "ثعطه",
    "الثعيط",
    "ثع",
    "انثع",
    "ثعلت",
    "أثعل",
    "الأثعل",
    "الثعل",
    "الثعلول",
    "الثعول",
    "المثعلة",
    "ثعلب",
    "تثعلب",
    "الثعلب",
    "الثعلبان",
    "الثعلبية",
    "ثغب",
    "تثغبت",
    "الثغب",
    "ثغثغ",
    "أثغر",
    "اثغر",
    "الثغر",
    "الثغرة",
    "أثغم",
    "ثاغمها",
    "الثغامة",
    "ثغت",
    "أثغى",
    "الثاغي",
    "الثاغية",
    "ثفأ",
    "الثفاءة",
    "ثفد",
    "الثفافيد",
    "أثفر",
    "استثفر",
    "الثفر",
    "الثفروق",
    "أثفل",
    "ثافل",
    "تثفل",
    "الثافل",
    "الثفال",
    "الثفل",
    "ثفنت",
    "أثفن",
    "ثافن",
    "الثفنة",
    "ثفاه",
    "أثفى",
    "ثفى",
    "ثقببت",
    "أثقب",
    "انثقب",
    "تثقب",
    "الأثقوب",
    "الثقبة",
    "الثقابة",
    "ثاقفه",
    "تثاقفوا",
    "الثقاف",
    "ثقله",
    "اثاقل",
    "الثاقل",
    "الثقالة",
    "الثقل",
    "الثقلة",
    "المثقال",
    "المثقلة",
    "أثكلت",
    "الثكل",
    "الثكول",
    "الثلب والثلب",
    "المثلب",
    "المثلبة",
    "أثلث",
    "ثلاث",
    "الثلاثاء",
    "الثلاثة",
    "الثلاثون",
    "الثلث الثلث",
    "الثلث",
    "الثليث",
    "أثلجت",
    "الثلاجي",
    "الثلج",
    "الثلاج",
    "المثلجة",
    "ثلخ",
    "ثلخه",
    "ثلط",
    "الثلط",
    "ثلغ",
    "انثل",
    "تثلل",
    "الثلة",
    "ثلمه",
    "انثلم",
    "تثلم",
    "الثلم",
    "الثلمة",
    "ثمأ",
    "ثمثم",
    "تثمثم",
    "الثمثام",
    "الثمثم",
    "ثمج",
    "أثمج",
    "المثمج",
    "ثمد",
    "أثمده",
    "استثمد",
    "الثمد",
    "ثمود",
    "ثمر",
    "استثمر",
    "الاستثمار",
    "الثامر",
    "الثمرة",
    "الثمير",
    "ثمغ",
    "ثمغه",
    "الثميغة",
    "أثمل",
    "تثمل",
    "الثمال",
    "الثملة",
    "الثميلة",
    "المثملة",
    "ثم",
    "ثمم",
    "انثم",
    "الثمة",
    "المثم",
    "المثمة",
    "أثمن",
    "ثامنه",
    "الثمن",
    "الثمن الثمن",
    "الثندوة",
    "الثنطب",
    "أثنت",
    "الثن",
    "الثنة",
    "انثنى",
    "تثانوا",
    "تثنى",
    "استثناه",
    "الاثنان",
    "الاثنتان",
    "اثنا عشر",
    "اثنتا عشرة",
    "الاثنا عشري",
    "الاثنا عشرية",
    "الثانية",
    "الثنى",
    "الثناء",
    "ثناء",
    "الثنوية",
    "الثنية",
    "الثنيان",
    "المثنى",
    "المثنوي",
    "أثابه",
    "ثاوبه",
    "ثيبت",
    "تثوب",
    "تثيبت",
    "استثابه",
    "الثائب",
    "الثواب",
    "الثوب",
    "الثيب",
    "المثاب والمثابة",
    "المثوبة والمثوبة",
    "أثاره",
    "ثاوره",
    "ثوره",
    "تثور",
    "تثاوروا",
    "استثاره",
    "المثورة",
    "ثال",
    "انثال",
    "تثول",
    "الأثول",
    "الثول",
    "الثوينى",
    "أثوى",
    "ثواه",
    "تثواه",
    "الثاية",
    "الثوة",
    "الثوي",
    "الثوية",
    "المثوى",
    "الثيل",
    "الجيم",
    "الجازولين",
    "جئ جئ",
    "جأجأ",
    "جأب",
    "الجأب",
    "الجأبة",
    "جأث",
    "جئث",
    "أجأثه",
    "انجأث",
    "الجؤذر",
    "جئر",
    "الجؤار",
    "الجأر",
    "الجائر",
    "جئز",
    "أجأزه",
    "جأشت",
    "الجؤشوش",
    "جأفه",
    "جئف",
    "اجتأفه",
    "انجأفت",
    "جيئل",
    "جيئلة",
    "الجيئل",
    "جأى",
    "جئي",
    "أجأى",
    "الجئاوة",
    "الجئوة",
    "جبأ",
    "أجبأت",
    "الجبء",
    "الجبأة",
    "الجبأى",
    "الجبأ",
    "الجباء",
    "أجب",
    "اجتب",
    "تجاب",
    "الجباب",
    "الجب",
    "الجبائية",
    "الجبة",
    "الجبوب",
    "المجبة",
    "الجبت",
    "جبجب",
    "تجبجب",
    "الجبجبة",
    "الجبخانة",
    "جبذ",
    "اجتبذه",
    "انجبذ",
    "أجبره",
    "اجتبر",
    "انجبر",
    "استجبر",
    "التجبار",
    "الجبار",
    "الجبارة",
    "الجبير",
    "الجبر",
    "الجبرياء",
    "الجبروت",
    "الجبيرة",
    "جبريل",
    "تجبس",
    "الأجبس",
    "الجباس",
    "الجباسة",
    "الجبيس",
    "أجبل",
    "جبله",
    "تجبل",
    "الجبل",
    "الجبلة",
    "الجبيل",
    "المجبال",
    "أجبنه",
    "جبنه",
    "اجتبته",
    "تجبن",
    "التجبن",
    "الجبانة",
    "الجبن",
    "المجبنة",
    "جبهه",
    "اجتبه",
    "الأجبه",
    "الجابه",
    "الجبه",
    "الجبهة",
    "جبا",
    "أجبا",
    "الجابية",
    "الجبا",
    "اجثأل",
    "اجتث",
    "انجث",
    "الجث",
    "الجثيثة",
    "المجتث",
    "المجثاث",
    "المجثة",
    "جثجث",
    "تجثجث",
    "الجثاجث",
    "الجثجاث",
    "جثلته",
    "الجثالة",
    "الجثلة",
    "الجاثليق",
    "جثمه",
    "تجثم",
    "الجاثوم",
    "الجثام",
    "الجثامة",
    "الجثمان",
    "الجثمة",
    "أجثاه",
    "جاثى",
    "جثاه",
    "تجاثوا",
    "الجثاء",
    "الجثوة",
    "جحجح",
    "الجحجاح",
    "الجحجح",
    "جحه",
    "أجحت",
    "انجح",
    "الجحة",
    "أجحد",
    "الجحود",
    "جحدره",
    "تجحدر",
    "الجحدر",
    "جحدل",
    "الجحدل",
    "جحر",
    "أجحر",
    "اجتحر",
    "انجحر",
    "تجحر",
    "الجحر",
    "الجحرة",
    "المجحر",
    "جاحش",
    "الجحشة",
    "جحظت",
    "تجاحظ",
    "الجاحظة",
    "الجاحظية",
    "الجحاظ",
    "جاحف",
    "أجحف",
    "اجتحف",
    "تجاحفوا",
    "الجحاف",
    "الجحفة",
    "جحفله",
    "تجحفل",
    "الجحفلة",
    "جحله",
    "الجحال",
    "الجحل",
    "جحم",
    "جحمت",
    "أجحم",
    "جحمه",
    "تجاحم",
    "تجحم",
    "الجاحم",
    "الجحام",
    "الجحمة",
    "الجحيم",
    "الجحمرش",
    "جحن",
    "أجحن",
    "الجحن",
    "جحا",
    "اجتحى",
    "جخا",
    "جخى",
    "تجخى",
    "جدب",
    "أجدب",
    "جادبت",
    "تجدبه",
    "الأجدب",
    "المجداب",
    "اجتدث",
    "الجدث",
    "الجدجد",
    "جدح",
    "أجدح",
    "جدحه",
    "اجتدح",
    "المجدح",
    "جاده",
    "تجدد",
    "استجد",
    "الجداد",
    "الجدادة",
    "الجد",
    "الجداء",
    "الجدة",
    "الجديد",
    "أجدر",
    "اجتدر",
    "الجدار",
    "الجداري",
    "الجدر",
    "الجدير",
    "الجديرة",
    "المجدار",
    "المجدرة",
    "المجدور",
    "جدس",
    "جدعه",
    "أجدعه",
    "جادعه",
    "تجادعا",
    "الجداع",
    "الجدعة",
    "جدفت",
    "أجدفوا",
    "الأجدف",
    "الجادوف",
    "الجدف",
    "الجدفة",
    "أجدلت",
    "جادله",
    "جدله",
    "اجتدل",
    "انجدل",
    "تجادلا",
    "تجدل",
    "الأجدل",
    "الأجدلي",
    "الجدال",
    "الجدالة",
    "الجدلة",
    "الجدول",
    "الجديل",
    "المجدل",
    "الجدام",
    "الجدامة",
    "الجدامية",
    "الجدمة",
    "جداه",
    "أجدى",
    "اجتداه",
    "استجداه",
    "الجادي",
    "الجادياء",
    "الجدا",
    "الجداية",
    "اجذأر",
    "انجذب",
    "تجذب",
    "التجاذب المغنطيسي",
    "الجاذبة",
    "الجذبة",
    "الجوذاب",
    "المجذوب",
    "جذه",
    "جذذه",
    "انجذ",
    "تجذذ",
    "الجذاذ",
    "الجذاذة",
    "الجذاء",
    "الجذة",
    "الجذيذ",
    "المجذ",
    "أجذر",
    "انجذر",
    "الجذر الأصم",
    "الجذير",
    "المجذر",
    "المجذور",
    "جذعه",
    "أجذع",
    "تجاذع",
    "الجذع",
    "المجذع",
    "أجذف",
    "انجذف",
    "تجذف",
    "أجذله",
    "اجتذل",
    "تجاذلوا",
    "استجذل",
    "جذمه",
    "جذمت",
    "أجذم",
    "انجذم",
    "تجذم",
    "الجذامة",
    "الجذم",
    "الجذمة",
    "المجذام",
    "المجذامة",
    "المجذم",
    "جذا",
    "جذاه",
    "أجذى",
    "تجذى",
    "تجاذى",
    "اجذوى",
    "اجذوذى",
    "الجاذي",
    "الجاذية",
    "الجذاة",
    "الجذوة",
    "الجذي",
    "المجذاء",
    "جرأه",
    "اجترأ",
    "تجرأ",
    "الجريئة",
    "الجرية",
    "اجرأش",
    "الجرائض",
    "جرافيت",
    "الجرانيت",
    "جربه",
    "جورب وتجورب",
    "الجرابيات",
    "الجرباء",
    "الجربان",
    "الجربة",
    "الجربياء",
    "الجريب",
    "تجرثم",
    "اجرنثم",
    "جرجت",
    "جرج",
    "جرجه",
    "الجرجة",
    "الجراجر",
    "الجرجار",
    "الجرجر",
    "الجرجير",
    "جرجم",
    "تجرجم",
    "جرحه",
    "استجرح",
    "الجارح",
    "الجارحة",
    "الجراحة",
    "الجرح",
    "الجرحة",
    "الجراح",
    "انجرد",
    "الأجرد",
    "التجريد",
    "الجارود",
    "الجارودية",
    "الجراد",
    "الجرداء",
    "الجردة",
    "الجرود",
    "المجرد",
    "جردب",
    "الجردق",
    "جردم",
    "الجردم",
    "جرذت",
    "أجرذه",
    "الأجرذ",
    "الجرذة",
    "أجرت",
    "جاره",
    "جرره",
    "استجر",
    "الأجران",
    "الجارة",
    "الجارور",
    "الجرارة",
    "الجر",
    "الجرور",
    "الجرير",
    "الجريرة",
    "جرزت",
    "أجرزت",
    "جارزه",
    "تجارزا",
    "الجارز",
    "الجارزة",
    "الجراز",
    "الجرزة",
    "المجراز",
    "أجرس",
    "تجرس",
    "الجاروس",
    "الجرسة",
    "جرشه",
    "اجترش",
    "الجراشة",
    "الجرشي",
    "قانون جريشام في الاقتصاد السياسي",
    "جرضه",
    "أجرضه",
    "الجراض",
    "أجرع",
    "جرعه",
    "اجترع",
    "الأجرع",
    "الجرعاء",
    "أجرف المكان",
    "جرفه",
    "اجترفه",
    "تجرفه",
    "انجرف",
    "الجاروف",
    "الجرفة",
    "أجرم",
    "اجترم",
    "تجرم",
    "الجرام",
    "الجرامة",
    "الجرمة",
    "الجريم",
    "جرمز",
    "اجرمز",
    "تجرمز",
    "الجراميز",
    "الجرموز",
    "الجرمقاني",
    "أجرن",
    "اجترن",
    "الجارن",
    "الجران",
    "الجرن",
    "الجرين",
    "المجرن",
    "الجروة",
    "أجرى",
    "جاراه",
    "جراه",
    "تجاروا",
    "استجراه",
    "الإجريا",
    "الإجرياء",
    "الإجرية",
    "الجاري",
    "الجارية",
    "الجرى",
    "الجرياء",
    "الجريال",
    "جزأت",
    "أجزأ",
    "جزأه",
    "اجتزأ",
    "تجزأ",
    "الجازيء",
    "الجازئة",
    "الجزء",
    "الجزأة",
    "الجزئية",
    "جزحت",
    "أجزر",
    "اجتزر",
    "انجزر",
    "الجزارة",
    "الجزور",
    "المجزر",
    "أجز",
    "اجتز",
    "استجز",
    "الجازة",
    "الجزازة",
    "الجزز",
    "الجزة",
    "الجزوز",
    "الجزوزة",
    "المجز",
    "أجزعه",
    "اجتزعه",
    "انجزع",
    "تجزع",
    "الجازع",
    "الجزاع",
    "الجزع",
    "الجزعة",
    "المجزع",
    "اجتزف",
    "الجزاف",
    "الجزافة",
    "الجزفة",
    "جزله",
    "أجزله",
    "استجزله",
    "الجزال",
    "الجزل",
    "الجزلة",
    "اجتزم",
    "انجزم",
    "تجزم",
    "الجزام",
    "الجزم",
    "الجزمية",
    "أجزى",
    "جازاه",
    "اجتزاه",
    "تجازى",
    "الجازي",
    "الجازية",
    "الجزاء",
    "الجزية",
    "جسأ",
    "الجاسي",
    "الجسوء البسيط",
    "الجاسياء",
    "جسده",
    "جسد الدم",
    "أجسد",
    "جسد",
    "الجساد",
    "الجسد",
    "جسره",
    "اجتسرت",
    "الجسرة",
    "اجتسه",
    "الجاسة",
    "الجساس",
    "الجسيس",
    "المجسة",
    "تجسم",
    "الجسام",
    "الجسم",
    "الجسمان",
    "الجسماني",
    "الجسمي",
    "الجسيم",
    "الجسيمات الكنسلمانية",
    "الجسان",
    "الجسنة",
    "جسي",
    "جاساه",
    "جشأت",
    "اجتشأته",
    "الجشء",
    "الجشاء",
    "الجشأة",
    "جشب",
    "الجشوب",
    "المجشاب",
    "المجشب",
    "جشر",
    "جشره",
    "تجشر",
    "الجاشر",
    "الجاشرية",
    "الجشار",
    "الجشر",
    "الجشرة",
    "الجشير",
    "المجشر",
    "أجشت",
    "اجتشت",
    "الجشش",
    "الجش",
    "الجشان",
    "الجشيشة",
    "المجش",
    "المجشة",
    "تجاشعوا",
    "تجشع",
    "أجشمه",
    "جشمه",
    "تجشم",
    "الجشم",
    "جشن",
    "الجشنة",
    "جصص",
    "الجصاص",
    "الجصاصة",
    "الجصيصة",
    "جض",
    "جضض",
    "الجيضي",
    "انجعب",
    "تجعب",
    "الجعابة",
    "الجعبة",
    "الجعاب",
    "المجعب",
    "الجعبوب",
    "جعبره",
    "الجعبر",
    "الجعبري",
    "تجعثن",
    "الجعثن",
    "الجعثنة",
    "تجعجع",
    "الجعجاع",
    "الجعجع",
    "تجعد",
    "الجعادة",
    "الجعد",
    "الجعدة",
    "تجعر",
    "الجاعرة",
    "جعار",
    "الجعار",
    "الجعر",
    "الجعراء",
    "الجعران",
    "الجعرة",
    "الجعري",
    "المجعر",
    "الجعرور",
    "جعس",
    "تجعس",
    "الجعس",
    "الجعيس",
    "الجعسوس",
    "الجعشم",
    "الجعضيض",
    "جعظ",
    "أجعظ",
    "الجعظ",
    "جع",
    "جعفه",
    "أجعفه",
    "اجتعف",
    "انجعف",
    "الجاعف",
    "الجعاف",
    "الجعف",
    "الجعفر",
    "الجعفرية",
    "الجعفيل",
    "أجعل",
    "جاعله",
    "اجتعل",
    "تجاعلوا",
    "الجعال",
    "الجعالة",
    "الجعل",
    "الجعلة",
    "الجعيلة",
    "جعم",
    "أجعم",
    "تجعم",
    "الجعام",
    "الجعم",
    "الجعمي",
    "الجعوم",
    "الجيعم",
    "المجعم",
    "جعا",
    "الجعة",
    "الجعو",
    "الجعول",
    "جفأ",
    "أجفأت",
    "اجتفأ",
    "تجفأت",
    "الجفاء",
    "الجفت",
    "جفخف",
    "تجفجف",
    "الجفاجف",
    "الجفجف",
    "جفخ",
    "جافخه",
    "أجفر",
    "اجتفر",
    "تجفر",
    "استجفر",
    "الجفرة",
    "الجفرى",
    "الجفير",
    "الجفيرة",
    "المجفر",
    "المجفرة",
    "جفس",
    "الجفس",
    "جفشه",
    "جفظه",
    "اجفأظ",
    "الجفظ",
    "الجفيظ",
    "اجتف",
    "تجفف",
    "التجفاف",
    "الجفاف",
    "الجفافة",
    "الجفف",
    "الجف",
    "الجفة",
    "الجفيف",
    "أجفل",
    "جفله",
    "انجفل",
    "تجفل",
    "الأجفلى",
    "الأجفلة",
    "الإجفيل",
    "الجفال",
    "الجفالة",
    "الجفلى",
    "الجفول",
    "الجفيل",
    "تجفن",
    "الجفنة",
    "جفا",
    "جفى",
    "أجفت",
    "جافى",
    "اجتفاه",
    "تجافى",
    "استجفاه",
    "الجافي",
    "الجفاية",
    "أجلب",
    "اجتلب",
    "انجلب",
    "تجلب",
    "استجلب",
    "الجالبه",
    "الجلاب",
    "الجلوبة",
    "الجليب",
    "الجليبة",
    "جلببه",
    "تجلبب",
    "تجلجل",
    "الجلاجل",
    "الجلجال",
    "الجلجلان",
    "الجلجل",
    "المجلجل",
    "جالحه",
    "الأجلح",
    "الإجليح",
    "الجالحة",
    "الجلاح",
    "الجلحاء",
    "الجلحة",
    "المجالح",
    "المجلاح",
    "اجلخ",
    "الجلاخ",
    "الجليخ",
    "جلدت",
    "أجلد",
    "جالده",
    "اجتلدوا",
    "تجالدوا",
    "الأجلد",
    "التجاليد",
    "الجلد",
    "الجلدة",
    "المجلاد",
    "المجلدة",
    "اجلوذ",
    "الجلاذي",
    "الجلذاء",
    "الجلذي",
    "الجلوذ",
    "جلز",
    "جلوز",
    "الجلاز",
    "الجلز",
    "الجلوز",
    "الجلواز",
    "المجلوز",
    "أجلسه",
    "جالسه",
    "تجالسوا",
    "استجلسه",
    "الجلس",
    "الجلسي",
    "الجلسان",
    "الجليس",
    "المجلس",
    "جلسرين",
    "جلاش",
    "تجلط",
    "اجتلطه",
    "انجلط",
    "جلع",
    "جالع",
    "انجلع",
    "تجالعوا",
    "الجليع",
    "جلفه",
    "أجلف",
    "اجتلفه",
    "تجلف",
    "الجالفة",
    "الجلاف",
    "الجلف",
    "الجلفة",
    "الجليف",
    "الجليفة",
    "الجلفاط",
    "الجلفق",
    "جلق",
    "تجلق",
    "الجلاقة",
    "الجلقة",
    "الجلق",
    "الجوالق",
    "المنجليق",
    "جل",
    "اجتل",
    "تجال",
    "تجلل",
    "إجلال",
    "التجلة",
    "الجالة",
    "الجلال",
    "الجلائل",
    "الجل",
    "الجلل",
    "الجلى",
    "الجلاء",
    "الجلالة",
    "الجليل",
    "المجلة",
    "اجتلم",
    "الجلامة",
    "الجلم",
    "الجلمان",
    "الجلمد",
    "الجلمدة",
    "الجلمود",
    "جلمق",
    "الجلماق",
    "الجلمق",
    "جلنبلق",
    "الجلنار",
    "الأجله",
    "الجلهة",
    "الجليهة",
    "جلهز",
    "الجلاهق",
    "الجلهم",
    "الجلهمة",
    "الجلهوم",
    "جليت",
    "أجلى",
    "جاليته",
    "اجتلى",
    "انجلى",
    "تجاليا",
    "اجلولى",
    "الأجلى",
    "الجلا",
    "الجلي",
    "الجلية",
    "المجالي",
    "الجلوكوما",
    "جمئ",
    "تجمأ",
    "الجمأ",
    "الجماء",
    "المجمأ",
    "تجمجم",
    "الجمجم",
    "الجماح",
    "الجمحل",
    "جمخ",
    "جامخه",
    "انجمخ",
    "أجمد",
    "الجامد",
    "الجماد",
    "جمادى",
    "أجمر",
    "اجتمر",
    "تجمر",
    "استجمر",
    "الجامور",
    "الجمار",
    "الجمارا",
    "جمارى",
    "الجمرة",
    "الجمير",
    "الجميرة",
    "المجمر",
    "جمز",
    "الجمز",
    "الجمزة",
    "الجماز",
    "الجمازة",
    "الجميزى",
    "جمس",
    "الجاموس",
    "الجماسية",
    "الجمسة",
    "الجموش",
    "الجميش",
    "جامع",
    "اجتمع",
    "استجمع",
    "الإجماع",
    "الاجتماع",
    "الجامع",
    "الجماع",
    "الجماعة",
    "الجمع",
    "الجمعاء",
    "الجمعة",
    "الجميع",
    "المجتمع",
    "المجمع",
    "المجمعة",
    "المجموع",
    "جامله",
    "جمله",
    "اجتمل",
    "تجمل",
    "استجمل",
    "الجامل",
    "الجمال",
    "الجمالي",
    "الجمل",
    "الجملانة",
    "الجملة",
    "الجميل",
    "الجمول",
    "المجمل",
    "عند النطاح يغلب الكبش الأجم",
    "جمم",
    "تجمم",
    "استجم",
    "الجمام",
    "الجمامة",
    "الجم",
    "الجمم",
    "الجماني",
    "الجموم",
    "الجميم",
    "المجم",
    "المجمة",
    "الجمان",
    "جمهر",
    "تجمهر",
    "الجماهر",
    "الجمهرة",
    "الجمهور",
    "الجمهورة",
    "تجمى",
    "الجما",
    "الجماءة",
    "جنأ",
    "جنئ",
    "أجنأ",
    "جانأ",
    "اجتنأ",
    "تجانأ",
    "الأجنأ",
    "المجنأ",
    "المجنأة",
    "أجنب",
    "جانبه",
    "اجتنب",
    "تجانب",
    "استجنب",
    "الأجنب",
    "الأجنبي",
    "الجانب",
    "الجناب",
    "الجنابى",
    "الجناباء",
    "الجنابة",
    "الجنب",
    "الجنوب",
    "الجنيب",
    "الجنيبة",
    "المجنب",
    "المجنبة",
    "تجنث",
    "الجنث",
    "الجنثي",
    "الجنجن والجنجن",
    "الجنجنة والجنجنة",
    "الجنجون",
    "أجنح",
    "جنحه",
    "اجتنح",
    "تجنح",
    "استجنح",
    "الجانحة",
    "الجناح",
    "الجناحية",
    "الجنبح",
    "المجنحة",
    "الجنادي",
    "الجند",
    "الجندية",
    "الجندرة",
    "الجنادل",
    "الجندل",
    "الجنازة",
    "الجنائزي",
    "الجنزير",
    "جنست",
    "جانسه",
    "تجانسا",
    "التجنيس",
    "الجنس",
    "الجنيس",
    "أجنف",
    "جانف",
    "تجانف",
    "الأجنف",
    "الجنافي",
    "المجنف",
    "جنقه",
    "الجنق",
    "المنجنيق",
    "جننه",
    "اجتن",
    "تجان وتجانن",
    "تجنن",
    "استجن",
    "التجنين",
    "الجنان",
    "الجن",
    "الجنن",
    "الجنة",
    "الجنون",
    "الجنين",
    "المجن",
    "المجنة",
    "المجنون",
    "المجنونة",
    "جني",
    "أجنى",
    "جانى",
    "اجتنى",
    "تجنى",
    "الجاني",
    "الجنى",
    "الجناية",
    "الجاهب",
    "الجهب",
    "المجهب",
    "الجهباذ",
    "الجهبذ",
    "الجهبل",
    "الجهبلة",
    "جهث",
    "جهجه",
    "تجهجه",
    "جاهد",
    "اجتهد",
    "تجاهد",
    "الاجتهاد",
    "الجهاد",
    "الجهادى",
    "الجهد",
    "الجهدان",
    "الجهيدى",
    "المجتهد",
    "المجهود",
    "أجهر",
    "جاهره",
    "جهور",
    "تجاهر",
    "اجتهر",
    "الجهار",
    "الجهر",
    "الجهراء",
    "الجهرة",
    "الجهور",
    "الجهير",
    "الجهيرة",
    "الجوهر",
    "المجهار",
    "المجهور",
    "أجهز",
    "جهزه",
    "تجهز",
    "الجهيز",
    "الجهيزة",
    "جهشت",
    "جهش",
    "أجهش",
    "الجهشة",
    "جهض",
    "أجهضت",
    "جاهضه",
    "الجاهض",
    "الجاهضة",
    "الجهاض",
    "الجهض",
    "الجهاضة",
    "المجهاض",
    "تجهضم",
    "الجهضم",
    "جهلت",
    "أجهله",
    "جهله",
    "جاهله",
    "اجتهله",
    "استجهله",
    "الجاهل",
    "الجاهلية",
    "الجهل",
    "الجيهل",
    "الجيهلة",
    "المجهال",
    "المجهل",
    "المجهلة",
    "جهمه",
    "جهم",
    "أجهمت",
    "اجتهم",
    "تجهمه",
    "الجهام",
    "الجهمة",
    "الجهمية",
    "الجهوم",
    "الجيهمان",
    "جهن",
    "الجهانة",
    "الجهن",
    "الجهنة",
    "جهينة",
    "الجهنام",
    "جهنم",
    "جهنمية",
    "جهيت",
    "أجهت",
    "جاهاه",
    "جهى",
    "الجاهي",
    "الجهوة",
    "أجابت",
    "جاوبه",
    "اجتاب",
    "استجابه",
    "استجوبه",
    "الجواب",
    "الجوب",
    "الجوبة",
    "المجواب",
    "المجوب",
    "المجوبة",
    "جوث",
    "أجاحت",
    "اجتاحت",
    "الأجوح",
    "الجائحة",
    "الجوح",
    "الجوحة",
    "المجوح",
    "جاخ",
    "تجوخت",
    "الجوخة",
    "أجاد",
    "أجود",
    "جاوده",
    "تجاودوا",
    "تجود",
    "استجاد",
    "التجاويد",
    "الجواد",
    "الجود",
    "الجودة",
    "جار",
    "أجاره",
    "جاوره",
    "جوره",
    "اجتور",
    "تجور",
    "الجار",
    "الجوار",
    "الجور",
    "جوربه",
    "تجورب",
    "جاز",
    "أجاز",
    "جاوز",
    "اجتاز",
    "تجاوز",
    "تجوز",
    "استجاز",
    "الجائز",
    "الجائزة",
    "الجواز",
    "الجوز",
    "الجيز",
    "الجيزة",
    "المجاز",
    "المجازة",
    "المجيز",
    "اجتاس",
    "الجوسق",
    "تجوش",
    "الجوش",
    "الجوشن",
    "جاع",
    "أجاعه",
    "استجاع",
    "الجوع",
    "المجاع",
    "المجوعة",
    "جافه",
    "جوف",
    "أجافه",
    "اجتافه",
    "تجوف",
    "استجاف",
    "استجوف",
    "الأجوفان",
    "الجائف",
    "الجائفة",
    "الجواف",
    "الجوافة",
    "الجوف",
    "المجوف",
    "تجوق",
    "المجوق",
    "أجاله",
    "جاوله",
    "اجتال",
    "تجاولوا",
    "استجاله",
    "الجال",
    "الجولان",
    "الجولاني",
    "الجوالة",
    "الجويل",
    "المجول",
    "جام",
    "الجام",
    "الجامة",
    "الجوم",
    "جان",
    "الجون",
    "الجوناء",
    "الجونة",
    "الجواني",
    "الجوني",
    "تجوه",
    "الجاه",
    "الجاهة",
    "الجوهري",
    "اجتوى",
    "استجوى",
    "الجواء",
    "الجواءة",
    "الجوة",
    "جاء",
    "أجاء",
    "جايأه",
    "جيأ",
    "الجايئة",
    "الجياء",
    "الجياءة",
    "الجيئة",
    "يجيد ويجاد",
    "الجيد",
    "جيره",
    "الجيار",
    "الجيز والجيزة",
    "جيش",
    "تجيشت",
    "استجاشت",
    "الجيش",
    "الجيشان",
    "جاض",
    "جايضهم",
    "جيض",
    "جافت",
    "جيفت",
    "اجتافت",
    "انجافت",
    "جيم",
    "الجيولوجيا",
    "الحاء",
    "حأحأ",
    "حابه",
    "تحابوا",
    "استحبه",
    "الحاب",
    "الحباب",
    "الحب",
    "الحبب",
    "الحبة",
    "المحبة",
    "المستحب",
    "حبتر",
    "الحباتر والحبتر",
    "تحبجرت",
    "الحباحب",
    "الحبحب",
    "حبره",
    "أحبرت",
    "الحابور",
    "الحبارى",
    "الحبرة",
    "الحبير",
    "الحبركى",
    "حبسه",
    "أحبسه",
    "احتبس",
    "تحبس",
    "الحابس",
    "الحباسة",
    "الحبس",
    "الحبسة",
    "الحبيس",
    "المحبس",
    "حبش",
    "أحبشت",
    "احتبش",
    "تحبش",
    "الأحبش",
    "الأحبوش والأحبوشة",
    "الحباشة",
    "الحباشية",
    "الحبش",
    "الحبشة",
    "حبض",
    "أحبض",
    "الحباض",
    "الحبض",
    "المحبض",
    "حبط",
    "الحباط",
    "الحبق",
    "الحبقى",
    "الحبقة",
    "احتبك",
    "تحبك",
    "الحباك",
    "الحبيكة",
    "المحبوك",
    "حبلت",
    "أحبله",
    "حبل",
    "احتبل",
    "تحبلت",
    "الأحبول والأحبولة",
    "الحابول",
    "الحبل",
    "الحبلان",
    "الحبلة",
    "المحبل",
    "المحتبل",
    "أحبنه",
    "الحبن",
    "الحبناء",
    "الحبنة",
    "الحبين",
    "أحبى",
    "حاباه",
    "احتبى",
    "الحبا",
    "الحباء",
    "الحبوة",
    "حتأ",
    "أحتأ",
    "الحتء",
    "الحتيء",
    "أحت",
    "انحت",
    "الحتات والحتاتة",
    "الحتات",
    "الحت",
    "الحتت",
    "الحتة",
    "الحتوت والمحتات",
    "حتى",
    "حتحت",
    "تحتحت",
    "حتده",
    "الحتود",
    "المحتد",
    "أحتر",
    "الحتار",
    "الحتر",
    "الحترة",
    "الحتيرة",
    "الحتف",
    "الحتفل",
    "حتك",
    "تحتك",
    "الحوتك",
    "أحتم",
    "انحتم",
    "تحتم",
    "الحاتم",
    "الحتامة",
    "الحتم",
    "الحتمة",
    "حتنت",
    "أحتن",
    "حاتن",
    "احتتن",
    "تحاتنت",
    "الحاتن",
    "الحتن",
    "الحتنى",
    "حتا",
    "أحتى",
    "الحاتي",
    "الحتي",
    "حثه",
    "أحثه",
    "حثث",
    "احتثه",
    "تحاث",
    "استحثه",
    "الحثاث",
    "الحثاثة",
    "الحث",
    "الحثيثي",
    "الحثوث",
    "الحثيث",
    "المحثة",
    "حثحث",
    "الحثحاث",
    "الحثحوث",
    "حثر",
    "أحثر",
    "الحثر",
    "الحثرة",
    "الحثيرة",
    "المحثر",
    "حثرب",
    "الحثرب",
    "الحثرفة",
    "حثرمت",
    "الحثارم",
    "الحثرمة",
    "الحثافير",
    "الحثفر",
    "الحثفرة",
    "حثل",
    "أحثله",
    "الحثل",
    "الحثلة",
    "الحثمة",
    "الحوثم",
    "حثى",
    "أحثاه",
    "احتثى",
    "استحثوا",
    "الحاثياء",
    "الحثا",
    "الحثى",
    "الحثواء",
    "الحثوة",
    "الحثية",
    "حجأ",
    "تحجأ",
    "المحجأ",
    "تحجب",
    "استحجبه",
    "الحاجب",
    "الحجاب",
    "الحجابة",
    "الحجب",
    "الحجبة",
    "أحج",
    "حاجه",
    "احتج",
    "تحاجوا",
    "الحاج",
    "الحاجة",
    "الحجاج",
    "الحج",
    "الحجة",
    "المحجاج",
    "حجحج",
    "احتجر",
    "استحجر",
    "الحاجر",
    "الحاجور",
    "الحاجورة",
    "الحجر",
    "الحجرة",
    "أحجز",
    "حاجزه",
    "احتجز",
    "انحجز",
    "تحاجز",
    "تحجز",
    "الحجاز",
    "حجازيك",
    "الحجزة",
    "حجف",
    "الحجاف",
    "الحجفة",
    "الحجيف",
    "حجلت",
    "أحجل",
    "التحجيل",
    "المحجل",
    "أحجم",
    "احتجم",
    "الحجام",
    "الحجامة",
    "المحجم",
    "المحجمة",
    "حجنه",
    "احتجن",
    "تحجن",
    "التحجين",
    "الحجنة",
    "الحجون",
    "المحجن",
    "المحجنة",
    "أحجى",
    "حاجاه",
    "احتجى",
    "تحجى",
    "استحجى",
    "الأحجوة",
    "الأحجية",
    "الحجا",
    "الحجوى",
    "الحجيا",
    "حدأه",
    "حدئ",
    "الحدأة",
    "حدبت",
    "أحدبه",
    "حدبه",
    "تحادب",
    "تحدب",
    "حادثه",
    "استحدثه",
    "الأحداث",
    "الأحدوثة",
    "الحادث",
    "الحادثة",
    "الحدث",
    "الحدثان",
    "الحديث",
    "الحديثي",
    "المحدث",
    "المحدثون",
    "حدجه",
    "أحدجت",
    "الحدج",
    "المحدج",
    "أحدت",
    "حادت",
    "احتد",
    "تحادوا",
    "تحدد",
    "استحد",
    "حدادك",
    "الحداد",
    "الحد",
    "الحدد",
    "الحديد",
    "المحدود",
    "حدرت",
    "أحدر",
    "انحدر",
    "تحادر",
    "الأحدور",
    "الحادر",
    "الحادرة",
    "الحادور",
    "الحدر",
    "الحدراء",
    "الحدرة",
    "الحدور",
    "حدرج",
    "الحدرج",
    "الحدروج",
    "المحدرج",
    "تحدس",
    "الحدسية",
    "أحدقت",
    "الحدقة",
    "حادله",
    "تحادل",
    "الأحدل",
    "الحدل",
    "حدمه",
    "حدمت",
    "أحدمت",
    "احتدمت",
    "تحدم",
    "الحدمة",
    "احتدى",
    "الأحدوة والأحدية",
    "الحادي",
    "الحداء",
    "الحدى",
    "الحدواء",
    "الحديا",
    "حذه",
    "حذ",
    "الأحذ",
    "الحذاء",
    "الحذذ",
    "الحذة",
    "حاذره",
    "الحاذورة",
    "الحذرية",
    "الحذير",
    "المحذور",
    "المحذورة",
    "احتذف",
    "الحذف",
    "الحذفاء",
    "الحذفة",
    "المحذوف",
    "حذفره",
    "الحذفار والحذفور",
    "أحذقه",
    "انحذق",
    "تحذق",
    "الحذاقي",
    "حذلت",
    "أحذل",
    "الحذال",
    "الحذل",
    "تحذلق",
    "الحذلق",
    "حذلم",
    "تحذلم",
    "الحذلوم",
    "حذم",
    "الحذم",
    "الحذيم",
    "الحذمور",
    "الحذن",
    "الحذنة",
    "أحذاه",
    "حاذاه",
    "احتذى",
    "تحاذى",
    "استحذاه",
    "الحاذي",
    "الحذاوة",
    "الحذاية",
    "الحذوة",
    "الحذيا",
    "الحذية",
    "المحذى",
    "المحذاء",
    "حربه",
    "حرب",
    "أحرب",
    "حاربه",
    "احتربوا",
    "تحاربوا",
    "احرنبى",
    "الحرب",
    "الحرابة",
    "الحريبة",
    "المحراب",
    "المحرب",
    "المحروبة",
    "حرته",
    "الحرتة",
    "أحرث",
    "احترث",
    "الحارث",
    "الحراث",
    "الحرث",
    "المحراث والمحرث",
    "الحارج",
    "الحرج",
    "الحروج",
    "الحريج",
    "المحراج",
    "المحرجة",
    "الحرجوج",
    "الحرجف",
    "حرجل",
    "الحرجلة",
    "حرجم",
    "احرنجم",
    "حرده",
    "أحرد",
    "حاردت",
    "انحرد",
    "تحرد",
    "الأحرد",
    "الحارد",
    "الحرد",
    "الحرود",
    "الحريد",
    "حردب",
    "حرره",
    "استحر",
    "الأحر",
    "الحار",
    "الحرارة",
    "الحراريات",
    "الحر",
    "الحرار",
    "الحرة",
    "الحرية",
    "الحرورية",
    "الحرير",
    "الحريرة",
    "المحر",
    "المحرور",
    "حرزه",
    "أحرزه",
    "احترز",
    "تحرز",
    "استحرز",
    "الحارز",
    "الحرز",
    "الحرزة",
    "الحريز",
    "الحريزة",
    "حرسه",
    "أحرس",
    "تحرس",
    "الاحتراس",
    "الأحرس",
    "الحرسي",
    "الحريسة",
    "المحروسة",
    "حرشه",
    "أحرش",
    "حارشه",
    "احترش",
    "الحرش",
    "الحرشاء",
    "الحريش",
    "الحريشة",
    "المحراش",
    "الحرشف",
    "حرصه",
    "احترص",
    "الحارصة",
    "الحرصة",
    "الحرصيان",
    "الحريصة",
    "أحرض",
    "حرضه",
    "تحارضوا",
    "الأحرض",
    "الإحريض",
    "الحارضة",
    "الحراض",
    "الحراضة",
    "الحرض",
    "الحرضة",
    "المحرضة",
    "حارف",
    "حورف",
    "احترف",
    "تحرف",
    "الحرافة",
    "الحريف",
    "الحرف",
    "الحرفي",
    "المحراف",
    "المحرف",
    "أحرقت",
    "حرقت",
    "احترق",
    "الحاروقة",
    "الحراق",
    "الحراقة",
    "الحرقان",
    "الحرقة",
    "الحرقوة",
    "الحريق",
    "الحريقة",
    "الحرقدة",
    "حرقص",
    "الحرقوص",
    "الحرقفة",
    "الحرقوف",
    "حركه",
    "تحرك",
    "الحارك",
    "الحراك",
    "الحرك",
    "الحركة",
    "المحراك",
    "الحرككة",
    "الحركوك",
    "أحرم",
    "احترمه",
    "استحرم",
    "الحرام",
    "الحرامي",
    "الحرم",
    "الحرمة",
    "الحرمي",
    "الحريم",
    "الحريمة",
    "المحرم",
    "المحرمة والمحرمة",
    "حرمد",
    "حرمزه",
    "تحرمز",
    "الحرماس",
    "الحرمس",
    "الحرملة",
    "حرنت",
    "المحران",
    "أحراه",
    "تحرى",
    "الأحرى",
    "الحرا",
    "الحرى",
    "الحراوة",
    "الحروة",
    "حزأ",
    "احزوزأ",
    "حزبهم",
    "تحازب",
    "الحزباءة",
    "حزحز",
    "تحزحز",
    "الحزحزة",
    "الحزراء",
    "الحزرة",
    "الحزور",
    "الحزورة",
    "الحزيرة",
    "حزه",
    "أحز",
    "حازه",
    "حززه",
    "احتزه",
    "الحزاز",
    "الحزازي",
    "الحزز",
    "الحزة",
    "الحزيز",
    "حزق",
    "أحزقه",
    "تحزق",
    "الحازق",
    "الحازقة",
    "الحزاق",
    "الحزق",
    "الحزقة",
    "احتزك",
    "حزمه",
    "أحزمه",
    "احتزم",
    "تحزم",
    "الأحزم",
    "الحزيم",
    "الحيزوم",
    "المحزم المحزمة",
    "أحزن",
    "تحازن",
    "الحزانة",
    "الحزن",
    "حزا",
    "أحزى",
    "تحزى",
    "الحازي",
    "الحزاء",
    "أحسب",
    "حاسبه",
    "حسبه",
    "تحاسبا",
    "الحساب",
    "الحسب",
    "الحسبة",
    "الحسيب",
    "المحتسب",
    "حسبل",
    "حسحس",
    "تحسحس",
    "الحسحاس",
    "حسده",
    "أحسده",
    "تحاسدا",
    "المحسدة",
    "الحسدل",
    "أحسر",
    "انحسر",
    "استحسر",
    "الحاسر",
    "الحسار",
    "الحسرة",
    "المحسر",
    "انحس",
    "الحاسة",
    "الحس",
    "الحسي",
    "الحسيس",
    "المحسة",
    "حسف",
    "أحسفه",
    "حسفه",
    "انحسف",
    "تحسف",
    "الحساف",
    "الحسافة",
    "الحشف",
    "الحسيفة",
    "أحسك",
    "الحساكة",
    "الحسك",
    "الحسكة",
    "الحسيكة",
    "حسل",
    "احتسل",
    "الحسالة",
    "الحسل",
    "الحسيل",
    "الحسيلة",
    "الأحسم",
    "الحسام",
    "الحسوم",
    "المحسمة",
    "المحسوم",
    "حاسنه",
    "استحسنه",
    "الأحسن",
    "الاستحسان",
    "التحاسين",
    "الحسن",
    "الحسنى",
    "الحسنة",
    "أحساه",
    "حاساه",
    "حساه",
    "احتسى",
    "تحاسيا",
    "تحسى",
    "الحسا",
    "الحسو",
    "الحسوة",
    "حسى",
    "الحسى",
    "حشأه",
    "المحشأة",
    "أحشبه",
    "احتشبوا",
    "الحشيب",
    "حشحشوا",
    "تحشحشوا",
    "أحشدوا",
    "احتشدوا",
    "تحاشدوا",
    "تحشدوا",
    "الحاشد",
    "الحشاد",
    "المحشود",
    "حشرهم",
    "حشره",
    "احتشر",
    "انحشر",
    "تحشر",
    "الحاشر",
    "الحشر",
    "الحشر الحشر",
    "المحشر",
    "المحشرة",
    "الحشرج",
    "أحش",
    "احتش",
    "استحش",
    "الأحشوش",
    "الحشاشى",
    "الحشاشة",
    "الحش",
    "الحشة",
    "الحشيش",
    "الحشيشة",
    "المحش",
    "أحشف",
    "تحشف",
    "استحشف",
    "الحشافة",
    "الحشفة",
    "الحشيف",
    "أحشك",
    "الحشاك",
    "الحشكة",
    "الحشوك",
    "الحشيكة",
    "أحشمه",
    "حشمه",
    "احتشم",
    "الحشوم",
    "الحشيم",
    "حشن",
    "أحشن",
    "حاشنه",
    "تحشن",
    "الحشان",
    "الحشنة",
    "الحشور",
    "الحشورة",
    "حشا",
    "حشي",
    "أحشاه",
    "احتشى",
    "انحشى",
    "تحاشى",
    "تحشت",
    "الحاشية",
    "الحاشيتان",
    "الحشاة",
    "الحشوية الحشوية",
    "الحشي",
    "حصأ",
    "أحصأه",
    "حصبه",
    "أحصب",
    "تحاصبا",
    "تحصب",
    "الحاصب",
    "الحصاب",
    "الحصب",
    "الحصباء",
    "المحصبة",
    "المحصب",
    "تحصحص",
    "الحصحاص",
    "الحصحص والحصحوص",
    "أحصد",
    "احتصد",
    "استحصد",
    "تحصدوا",
    "الحصاد",
    "الحصد",
    "الحصداء",
    "الحصيدة",
    "المحصد",
    "أحصر",
    "حاصره",
    "احتصر",
    "الحصار",
    "الحصر",
    "الحصور",
    "الحصير",
    "الحصيرة",
    "المحصورة",
    "تحصرم",
    "الحصرم",
    "أحصه",
    "انحص",
    "تحاصوا",
    "تحصص",
    "الأحص",
    "الحصاء",
    "الحاصة",
    "الحصاصة",
    "الحص",
    "الحصة",
    "الحصيص",
    "الحصيصة",
    "أحصف",
    "استحصف",
    "المحصاف",
    "المحصف والمحصف",
    "أحصل",
    "الحاصل",
    "الحصالة",
    "الحصل",
    "الحصيل",
    "الحصيلة",
    "المحصول",
    "حصم",
    "انحصم",
    "الحصيم",
    "المحصمة",
    "الحاصن والحاصنة",
    "الحصناء",
    "الحصن",
    "الحصين",
    "المحصن",
    "حصاه",
    "الحصو",
    "حصيت",
    "حصي",
    "حصى",
    "تحصى",
    "استحصى",
    "الحصى",
    "الحصاة",
    "الحصي",
    "المحصاة",
    "حضأت",
    "احتضأ",
    "الحضاء",
    "الحضيء",
    "المحضأ",
    "حضب",
    "أحضبت",
    "تحضب",
    "الحضب الحضب",
    "الحضب",
    "المحضب",
    "حضج",
    "انحضج",
    "الحضج",
    "الحضيج",
    "المحضاج",
    "المحضج",
    "المحضجة",
    "حاضر",
    "احتضر",
    "استحضره",
    "الحاضر",
    "الحاضرة",
    "الحضار",
    "الحضر",
    "الحضراء",
    "الحضوري",
    "المحضار",
    "المحضر",
    "المحضور",
    "حضرم",
    "الحضرمي",
    "حضه",
    "حاض",
    "حضضه",
    "تحاضوا",
    "التحضيض",
    "الحضض",
    "الحضيضي",
    "الحضيضة",
    "حضنه",
    "أحضن",
    "احتضن",
    "الحضانة",
    "المحتضن",
    "المحضن",
    "حضا",
    "حطأ",
    "الحطء",
    "الحطيء",
    "الحطيئة",
    "أحطب",
    "احتطب",
    "استحطب",
    "الحطاب",
    "الحطب",
    "الحطوبة",
    "المحطب",
    "حطحط",
    "حطره",
    "الحاطورة",
    "احتطه",
    "انحط",
    "استحط",
    "الحطائط",
    "الحطاط",
    "الحطاطة",
    "الحطة",
    "الحطوط",
    "الحطيط",
    "الحطيطة",
    "المحط",
    "المحطوط",
    "المحطوطة",
    "أحطمت",
    "حطمه",
    "انحطم",
    "الحاطوم",
    "الحطم",
    "الحطمة الحطمة",
    "الحطمية",
    "الحطيم",
    "حطا",
    "احطوطى",
    "الحطا",
    "الحطواء",
    "حظب",
    "الحظب",
    "أحظر",
    "احتظر",
    "الحظر",
    "الحظار",
    "المحظار",
    "حظ",
    "أحظ",
    "الحظ",
    "حظل",
    "أحظل",
    "أحظاه",
    "احتظى",
    "الحظة",
    "الحظوة الحظوة",
    "الحظيا",
    "الحظية المحظية",
    "حف حف",
    "حفأه",
    "احتفأ",
    "الحفأ",
    "حفحف",
    "حفد",
    "أحفد",
    "احتفد",
    "الحافد",
    "الحفد",
    "الحفيد",
    "المحفد",
    "حفرت",
    "أحفر",
    "احتفر",
    "تحفر",
    "استحفر",
    "الحافر",
    "الحفر",
    "الحفراة",
    "الحفير",
    "الحفيرة",
    "المحافر",
    "المحفار",
    "حفزه",
    "حافزه",
    "احتفز",
    "الحفاز",
    "حفش",
    "الحافشة",
    "الحفش",
    "حفص",
    "الحفاصة",
    "الحفص",
    "حفصة",
    "المحفصة",
    "حفض",
    "الحفض",
    "الحفيضة",
    "أحفظه",
    "حافظ",
    "استحفظه",
    "الحافظ",
    "الحفاظ",
    "الحفظة",
    "الحفيظ",
    "الحفيظة",
    "حفت",
    "أحف",
    "احتف",
    "استحف",
    "الحفاف",
    "الحفافة",
    "الحفف",
    "الحفان",
    "المحفة",
    "المحفوف",
    "تحفل",
    "الحفال",
    "الحفيل",
    "المحتفل",
    "المحفل",
    "احتفن",
    "الحفنة الحفنة",
    "المحفن",
    "حفاه وحفا به",
    "أحفى",
    "حافاه",
    "احتفى",
    "تحفى",
    "استحفى",
    "الحفا",
    "الحافي",
    "الحفي",
    "أحقب",
    "احتقب",
    "استحقب",
    "الأحقب",
    "الحاقب",
    "الحقاب",
    "الحقب الحقب",
    "أحقد",
    "تحاقدوا",
    "احتقد",
    "تحقد",
    "الحقيدة",
    "أحقره",
    "حقره",
    "تحاقر",
    "احتقره",
    "استحقره",
    "المحقرة",
    "المحقرات",
    "حقط",
    "الحقطة",
    "احقوقف",
    "الأحقف",
    "الحقف",
    "المحقف",
    "أحق",
    "حاقه",
    "احتق",
    "انحقت",
    "تحاقا",
    "استحق",
    "التحقيق",
    "الحق",
    "الحقاني",
    "الحقيق",
    "الحقيقة",
    "المحقوق",
    "حقلت",
    "أحقل",
    "حاقله",
    "احتقل",
    "الحاقل",
    "الحقال",
    "الحقلي",
    "الحقلة",
    "المحقلة",
    "الحقلد",
    "الحقيم",
    "الحاقنة",
    "المحقان",
    "المحقن",
    "حقاه",
    "حقي",
    "تحقى",
    "الحقاء",
    "الحقو",
    "حكأ",
    "أحكأها",
    "احتكأ",
    "الحكأة",
    "الحكاءة",
    "حكره",
    "حاكره",
    "تحكر",
    "الحاكورة",
    "الحكرة",
    "حكش",
    "الحكشة",
    "أحك",
    "حاكه",
    "حككه",
    "تحاكا",
    "تحكك",
    "استحك",
    "الحاكة",
    "الحكاك",
    "الحكاكة",
    "الحكك",
    "الحككة",
    "الحكيكة",
    "حكل",
    "أحكل",
    "احتكل",
    "تحكل",
    "الأحكل",
    "الحاكل",
    "الحكلة",
    "الحكيلة",
    "حاكمه",
    "احتكم",
    "تحاكما",
    "استحكم",
    "الحاكم",
    "الحكمة",
    "الحكيم",
    "المحكم",
    "حاكاه",
    "الحكاية",
    "الحكاء",
    "الحكي",
    "حلأ",
    "حلئ",
    "أحلأه",
    "حلأه",
    "التحلئ",
    "التحلئة",
    "الحلاءة",
    "الحلوء",
    "المحلأة",
    "أحلب",
    "حالبه",
    "احتلب",
    "استحلب",
    "الإحلابة",
    "الحلاب",
    "الحلب",
    "الحلباة",
    "الحلوبة",
    "المحلب",
    "الحلابس",
    "الحلبس",
    "الحلاتة",
    "الحلتيت",
    "الحليت",
    "الحلاجة",
    "الحلاج",
    "الحلوج",
    "المحلاج",
    "المحلج",
    "تحلحل",
    "الحلاحل",
    "المحلحل",
    "حلز",
    "احتلز",
    "تحلز",
    "الحالز",
    "الحلز",
    "الحلزوني",
    "حلست",
    "حلس",
    "أحلست",
    "حالسه",
    "تحلس",
    "استحلست",
    "الحلس",
    "الحلوس",
    "الحليس",
    "حلط",
    "أحلط",
    "احتلط",
    "أحلفت",
    "حلفه",
    "تحالفوا",
    "استحلفه",
    "الحلف",
    "الحلفة",
    "الحلاف",
    "الحلفق",
    "أحلق",
    "احتلق",
    "تحلق",
    "تحلاق",
    "الحالق",
    "الحالقة",
    "الحالوق",
    "الحالوقة",
    "الحلق",
    "الحلقة",
    "حلقم",
    "الحلقامة",
    "الحلقوم",
    "حلقن",
    "الحلقانة",
    "استحلك",
    "الحلك",
    "الحلكة",
    "حاله",
    "انحلت",
    "استحل",
    "التحلة",
    "التحليل",
    "الحلال",
    "الحلل",
    "الحل",
    "الحلان",
    "الحلة",
    "الحلول",
    "الحلولية",
    "الحليل",
    "الحليلة",
    "المحلال",
    "المحل",
    "المحلل",
    "المحلة",
    "حلمه",
    "احتلم",
    "تحالم",
    "تحلم",
    "التحلمة",
    "الحالوم",
    "الحلام",
    "الحلم",
    "حالاه",
    "تحالى",
    "تحلى",
    "استحلى",
    "احلولى",
    "الحلى",
    "حلاوة القفا",
    "الحلواء",
    "الحلوى",
    "الحلوان",
    "الحلواني",
    "الحلي",
    "المحلى",
    "حليت",
    "الحلاة",
    "الحلية",
    "أحمأ",
    "الحمأ",
    "الحمأة",
    "الحمئ",
    "حمته",
    "حمت",
    "تحمت",
    "الحميت",
    "تحمحم",
    "الحماحم",
    "الحمحم",
    "حمده",
    "أحمد",
    "تحمد",
    "تحامدوا",
    "استحمد",
    "حماد",
    "حمادى",
    "الحمد",
    "الحمدة",
    "المحمدة",
    "أحمر",
    "احمار",
    "الأحمر",
    "الأحيمر",
    "الحامر",
    "الحامرة",
    "الحمار",
    "حمار قبان",
    "الحمارة",
    "الحمارة الحمارة",
    "الحمر",
    "الحمراء",
    "وحمراء الشدقين",
    "الحمرة",
    "الحميراء",
    "المحمرة",
    "اليحمور",
    "الحمارس",
    "الحمزة",
    "الحموز",
    "الحميز",
    "المحموز",
    "أحمسه",
    "احتمس",
    "تحامس",
    "الحماس والحماسة",
    "الحمسة",
    "الحميس",
    "الحميسة",
    "حمشت",
    "أحمش",
    "تحمش",
    "استحمش",
    "انحمص",
    "تحمص",
    "الحمص والحمص",
    "الحمصاني",
    "حمضت",
    "أحمض",
    "تحمض",
    "استحمض",
    "الحمضي",
    "المحمض والمحمض",
    "حمط",
    "الحماط",
    "الحماطة",
    "أحمق",
    "حامقه",
    "حمقه",
    "انحمق",
    "تحامق",
    "تحمق",
    "استحمق",
    "الأحموقة",
    "الحماق والحماق",
    "الحموقة",
    "المحماق",
    "المحقات",
    "الحمك",
    "أحملت",
    "احتمل",
    "استحمل",
    "الحاملة",
    "الحمال",
    "الحمالة",
    "الحمل",
    "الحملة والحملة",
    "الحمول",
    "الحميل",
    "الحميلة",
    "المحمل",
    "المحمول",
    "حملج",
    "الحملاج",
    "حملاق",
    "أحمت",
    "حممت",
    "احتم",
    "تحمم",
    "الحامة",
    "الحمام",
    "الحمامة",
    "الحمى",
    "الحمامي",
    "الحمة",
    "الحميم",
    "الحميمة",
    "المحم",
    "المحمة",
    "المستحم",
    "اليحموم",
    "الحمنان",
    "المحمنة",
    "حميت",
    "أحمى",
    "احتمى",
    "تحاماه",
    "حما",
    "حموة",
    "الحمي",
    "تحمير",
    "تحنأ",
    "الحناء",
    "حنب",
    "تحنب",
    "حنبل",
    "تحنبل",
    "الحنابل",
    "الحنبل",
    "الحنبلي",
    "الحانوت",
    "الحنتف",
    "الحنتوف",
    "الحنتم",
    "أحنثه",
    "حنثه",
    "تحنث",
    "الحنث",
    "المحانث",
    "حنجت",
    "أحنج",
    "احتنج",
    "الحنج",
    "حنجرت",
    "الحنجور",
    "الحناجل",
    "الحنجل",
    "الحندرة",
    "الحندورة",
    "تحندس",
    "الحندس",
    "الحندقوق",
    "حنذ",
    "أحنذ",
    "استحنذ",
    "حناذ",
    "الحنذة",
    "الحنذيان",
    "الحنيذ",
    "حنر",
    "الحنيرة",
    "حنس",
    "الحنس",
    "الحونس",
    "حنشه",
    "حنش",
    "أحنشه",
    "الحنش",
    "المحنش",
    "أحنط",
    "تحنط",
    "استحنط",
    "الحانط",
    "الحناط",
    "الحناطة",
    "الحنطة",
    "الحنطي",
    "الحنوط",
    "الحنوطي",
    "حنظلت",
    "الحنظل",
    "حنفه",
    "تحنف",
    "الحنفاء",
    "الحنفي",
    "الحنفية",
    "أحنق",
    "الحنيق",
    "حنكت",
    "أحنكته",
    "احتنك",
    "استحنك",
    "الحانك",
    "الحناك",
    "الحنك",
    "الحنكة",
    "الحنيك",
    "حنكل",
    "الحنكل",
    "الحنكلة",
    "حنن",
    "تحان",
    "تحنن",
    "استحن",
    "التحنان",
    "الحنانة",
    "الحنة",
    "حنى",
    "أحنى",
    "تحنى",
    "الأحنى",
    "الحاناة",
    "الحانية",
    "الحاني",
    "الحنو",
    "الحنواء",
    "الحنية",
    "المحنية",
    "حاب",
    "أحوب",
    "تحوب",
    "الحوب",
    "الحوباء",
    "الحوبة",
    "حات",
    "حاوته",
    "الحائت",
    "الحوت",
    "الحوتاء",
    "حوتك",
    "أحاثه",
    "استحاثه",
    "حاث باث",
    "حوث",
    "الحوث",
    "حوث بوث",
    "الحوثاء",
    "حاج",
    "أحوج",
    "احتاج",
    "تحوج",
    "الحائج",
    "الحائجة",
    "الحوج",
    "الحوجاء",
    "المحوجب",
    "الحوجلة",
    "حاودته",
    "أحوذ",
    "الأحوذي",
    "الحاذ",
    "الحواذ",
    "الحوذان",
    "الحويذ",
    "حار",
    "حورت",
    "أحارت",
    "حاوره",
    "تحاوروا",
    "احور",
    "استحاره",
    "الأحوري",
    "الحائر",
    "الحائرة",
    "الحواري",
    "الحور",
    "الحوراء",
    "الحوارى",
    "الحوير",
    "المحورة",
    "حاز",
    "احتازه",
    "انحاز",
    "تحاوزوا",
    "تحوز",
    "استحازه",
    "الأحوز",
    "الأحوزي",
    "الحوز",
    "الحوزاء",
    "الحوزة",
    "الحوزي",
    "الحويزاء",
    "الحيازة",
    "الحيز",
    "حاست",
    "حاوست",
    "تحوس",
    "استحوس",
    "الأحوسي",
    "الحائس",
    "الحواسة",
    "الحواس",
    "أحاش",
    "أحوش",
    "حاوشه",
    "احتوش",
    "انحاش",
    "تحاوشوا",
    "تحوش",
    "الحائش",
    "الحواشة",
    "الحوش",
    "الحوشي",
    "المحاش",
    "الحوشب",
    "الحوشبة",
    "الحوشكة",
    "حاص",
    "حاوصه",
    "احتاص",
    "الحواص",
    "الحوص",
    "الحياصة",
    "الحوصل",
    "حوض",
    "احتاض",
    "استحوض",
    "الحوض",
    "المحوض",
    "أحاط",
    "حاوطه",
    "احتاط",
    "تحوطه",
    "استحاط",
    "التحويطة",
    "الحائط",
    "الحواطة",
    "الحوط",
    "الحوطة",
    "الحواط",
    "الحيطة الحيطة",
    "الحيط",
    "المحاط",
    "المحيط",
    "حوفه",
    "تحوف",
    "الحوف",
    "حوفز",
    "احتاق",
    "الحواقة",
    "الحوق",
    "الحوقة",
    "المحوقة",
    "الحوقل",
    "الحوقلة",
    "أحاك",
    "الحوك",
    "الحوكة",
    "حال",
    "حولت",
    "أحول",
    "أحال",
    "احتوله",
    "احتال",
    "استحال",
    "الاحتيال",
    "التحاويل",
    "الحائل",
    "الحال",
    "الحالة",
    "الحوال",
    "الحوالة",
    "الحوالي الحوالي",
    "الحول",
    "الحولان",
    "الحولة",
    "الحيال",
    "الحيلة",
    "الحيل",
    "المحال",
    "المحالة",
    "المحوال",
    "المحيل",
    "حولق",
    "الحومة",
    "الحومانة",
    "الحومل",
    "تحون",
    "حوي",
    "احتوى",
    "تحوى",
    "الحاوي",
    "الحاوياء",
    "الحواء",
    "الحو",
    "حواء",
    "الحواءة",
    "الحوة",
    "الحوي",
    "الحوية",
    "الحية",
    "المحوى",
    "المحواة",
    "حيث",
    "أحاجت",
    "حيجم",
    "أحاده",
    "حايده",
    "الحيدى",
    "الحيدان",
    "الحيدة",
    "حيره",
    "استحار",
    "استحير",
    "الحاري",
    "الحير",
    "الحيرة",
    "الخير",
    "المستحير",
    "المستحيرة",
    "حاس",
    "حيس",
    "الحيس",
    "تحيشت",
    "الحيش",
    "الحيشان",
    "حايصه",
    "انحاص",
    "تحايص",
    "حيص وحيص",
    "الحيوص",
    "المحيص",
    "حاضت",
    "تحيضت",
    "استحيضت",
    "الحياض",
    "الحيض",
    "الحيضة",
    "المحيض",
    "حيعل",
    "تحيف",
    "الحائف",
    "الحيف",
    "الحيفة",
    "أحاق",
    "حايقه",
    "الحيق",
    "الحيقر",
    "احتاك",
    "تحايك",
    "تحيك",
    "الحيلان",
    "حان",
    "أحان",
    "أحين",
    "حاينه",
    "حينه",
    "الحائنة",
    "الحين",
    "الحينة الحينة",
    "المحيان",
    "حيي",
    "أحيا",
    "حياه",
    "حايا",
    "تحيا",
    "التحية",
    "الحيا",
    "الحياء",
    "الحياة",
    "الحابي",
    "الحيوان",
    "حي",
    "الخاء",
    "خبأه",
    "أخبأه",
    "خابأه",
    "اختبأ",
    "تخبأ",
    "الخابية",
    "الخباء",
    "الخبأة",
    "الخبئ",
    "الخبيئة",
    "المخبأة",
    "أخب",
    "خببه",
    "اختب",
    "الخاب",
    "الخبة",
    "الخبيب",
    "الخبيبة",
    "المخبة",
    "أخبت",
    "الخبت",
    "الخبتة",
    "أخبث",
    "تخابث",
    "استخبثه",
    "الأخبثان",
    "خباث",
    "الخبيث",
    "الخبث",
    "الخبثة",
    "المخبثة",
    "مخبثان",
    "خبخب",
    "تخبخب",
    "خبرت",
    "أخبره",
    "خابره",
    "خبره",
    "تخبر",
    "استخبره",
    "الأخباري",
    "الخابور",
    "الخبار",
    "الخبر",
    "الخبرة",
    "الخبور",
    "الخبير",
    "الخبيرة",
    "المخبار",
    "المخبر",
    "المخبراني",
    "المخبرة",
    "اختبز",
    "انخبز",
    "تخبزه",
    "الخابز",
    "الخبازى",
    "الخبازة",
    "الخبازي",
    "الخبازيات",
    "الخبز",
    "الخبيز",
    "خبس",
    "اختبس",
    "تخبس",
    "الخباساء",
    "الخباسة",
    "خبشه",
    "تخبشه",
    "الخباشة",
    "خبصه",
    "اختبص",
    "تخبص",
    "الخبيص",
    "الخبيصة",
    "المخبصة",
    "اختبطت",
    "تخبطت",
    "الخابط",
    "الخباط",
    "الخبط",
    "الخبطة",
    "الخبوط",
    "الخبيط",
    "المخبط",
    "المخبطة",
    "خبع",
    "الخباع",
    "الخبعة",
    "خبق",
    "تخبق",
    "الخبقة",
    "الخبق",
    "الخبقى",
    "الخبقاء",
    "أخبله",
    "خبله",
    "اختبل",
    "تخبل",
    "استخبله",
    "الخابل",
    "الخبال",
    "الخبلة",
    "الخبول",
    "خبن",
    "أخبن",
    "الخبن",
    "الخبنة",
    "أخبى",
    "خبى",
    "تخبى",
    "استخبى",
    "ختأه",
    "اختتأ",
    "المختتئة",
    "خت",
    "أخت",
    "خترت",
    "ختره",
    "تختر",
    "ختع",
    "انختع",
    "الختع",
    "الختعة",
    "الختيع",
    "الختيعة",
    "الخوتع",
    "الخوتعة",
    "ختعر",
    "الخيتعور",
    "ختله",
    "خاتله",
    "اختتل",
    "تخاتلوا",
    "الختل",
    "الخوتل",
    "ختمه",
    "اختتم",
    "الخاتام",
    "الخاتم",
    "الخاتيام",
    "الختم",
    "الخيتام",
    "المختم",
    "المختوم",
    "اختتن",
    "الختان",
    "الختانة",
    "الختن",
    "ختا",
    "أختى",
    "اختتى",
    "الختي",
    "خثثه",
    "الخثة",
    "أخثر",
    "التخثر التاجي",
    "الخثار",
    "الخثارة",
    "الخثارم",
    "الخثرمة",
    "خثعمه",
    "تخثعم",
    "خثم",
    "خثمه",
    "خثى",
    "أخثى",
    "الخثو",
    "الخثواء",
    "الخثوة",
    "الخثى",
    "الخثي",
    "خجأ",
    "خجىء",
    "أخجأه",
    "تخاجأت",
    "الخجأة",
    "خج",
    "اختج",
    "الخجيج",
    "خجخج",
    "الخجخاجة",
    "خجله",
    "اختجله",
    "الخوجلى",
    "خدب",
    "تخدب",
    "الخادبة",
    "الخدب",
    "الخيدب",
    "الخيدبة",
    "خدج",
    "أخدجت",
    "خدجت",
    "الخديج",
    "المخداج",
    "خاده",
    "تخادا",
    "تخدد",
    "الأخدود",
    "الخدة",
    "المخد",
    "أخدر",
    "خدره",
    "اختدر",
    "تخدر",
    "الأخدور",
    "التخدير الكوكاييني",
    "الخداري",
    "الخدرة",
    "خدشه",
    "المخادش",
    "المخدش",
    "أخدعه",
    "خادعه",
    "خدعه",
    "انخدع",
    "تخادعا",
    "تخدع",
    "اختدعه",
    "الأخدع",
    "الخادع",
    "الخادعة",
    "الخدوع",
    "الخيدع",
    "المخدع",
    "خدف",
    "اختدفه",
    "الخدف",
    "الخدفة",
    "الخدل",
    "الخدلج",
    "أخدمه",
    "تخدم",
    "اختدم",
    "استخدمه",
    "الخدام",
    "الخدمة",
    "الخدوم",
    "المخدم",
    "المخدمة",
    "خادنه",
    "الخدن",
    "الخدنة",
    "الخديو",
    "الخديوية",
    "خذىء",
    "أخذأه",
    "استخذأ",
    "خذ",
    "خذرف",
    "تخذرف",
    "الخذرفة",
    "الخذروف",
    "خذع",
    "خذعه",
    "تخذع",
    "الخذعة",
    "المخدعة",
    "المخذفة",
    "خدق",
    "الخدق",
    "أخذلت",
    "خذله",
    "تخاذلت",
    "الخذلة",
    "الخذول",
    "خذم",
    "أخذم",
    "خذمه",
    "تخذم",
    "الخذامة",
    "الخذماء",
    "المخذم",
    "خذا",
    "خذي",
    "أخذاه",
    "استخذى",
    "الخذا",
    "الخذاوية",
    "الخرء",
    "المخرأة",
    "المخرؤة",
    "أخربه",
    "خربه",
    "استخرب",
    "الأخرب",
    "الخرابة",
    "الخرب",
    "الخرباء",
    "الخربان",
    "الخربة",
    "الخروبة",
    "الخربز",
    "الخرباش",
    "خربص",
    "اخرنبق",
    "الخرباق",
    "الخربق",
    "الخرت",
    "الخرتة",
    "الخريت",
    "المخرت",
    "خرثت",
    "الخرثاء",
    "الخرثي",
    "خارج",
    "خرجه",
    "اخترج",
    "اخرج",
    "استخرجه",
    "التخريج",
    "الخارج",
    "الخارجي",
    "الخراج",
    "الخرجة",
    "الخروج",
    "الخوارج",
    "المخرج",
    "تخرخر",
    "الخرخار",
    "الخرخر",
    "الخرخور",
    "أخرد",
    "تخردت",
    "الخرود",
    "الخريد",
    "الخريدة",
    "الخرديق",
    "خردلت",
    "الخردل",
    "الخردولة",
    "خرذل",
    "أخره",
    "انخر",
    "الخرارة",
    "الخر",
    "الخريان",
    "خرزه",
    "الخرازة",
    "الخراز",
    "المخراز",
    "المخرز",
    "أخرست",
    "تخارس",
    "تخرست",
    "استخرست",
    "الخراس",
    "الخرساء",
    "الخرسة",
    "الخروس",
    "الخرسى",
    "تخرسن",
    "خرش",
    "خارشه",
    "اخترش",
    "تخارشت",
    "الخراش",
    "الخراشة",
    "الخرش",
    "الخرشاء",
    "المخراش",
    "المخرش",
    "المخرشة",
    "خرشب",
    "الخرشب",
    "خرشف",
    "الخرشاف",
    "الخرشفة",
    "خرشم",
    "اخرنشم",
    "الخرشم",
    "الخرشمة",
    "الخرشوم",
    "اخترص",
    "تخرص",
    "الخراص",
    "الخرص",
    "الخرصة",
    "الخريص",
    "المخرص",
    "خرطت",
    "أخرطت",
    "اخترط",
    "انخرطت",
    "تخرط",
    "استخرط",
    "الإخريط",
    "الخراط",
    "الخرط",
    "المخراط",
    "المخرط",
    "الخرطوش",
    "خرطمه",
    "اخرنطم",
    "الخراطم",
    "الخرطماني",
    "الخراطين",
    "اخترعه",
    "انخرع",
    "تخرع",
    "الخراع",
    "الخراعة",
    "الخريع",
    "الخرع",
    "الخروعة",
    "الخريعة",
    "الخرعب",
    "الخرعوب",
    "الخرعوبة",
    "أخرف",
    "خارفه",
    "خرفه",
    "اخترف",
    "الخارف",
    "الخرفة",
    "الخروفة",
    "الخريفة",
    "المخرف",
    "المخرفة",
    "خرفجه",
    "الخرافج",
    "الخرفاج",
    "الخرفج",
    "الخرفيج",
    "الخرفع",
    "الخرفق",
    "أخرقه",
    "انخرق",
    "الخرق",
    "الخريق",
    "الخرقة",
    "المخراق",
    "المخرق",
    "المخروق",
    "خرقل",
    "اخترمته",
    "انخرم",
    "تخرم",
    "الأخرم",
    "الخرمية",
    "الخرماء",
    "الخرمان",
    "الخرمة",
    "الخورمة",
    "المخرم",
    "خرمد",
    "اخرمس",
    "الخرمس",
    "خرمش",
    "اخرمص",
    "خرمل",
    "تخرمل",
    "الخرمل",
    "خرنفه",
    "الخرانف",
    "الخرنف",
    "الخرنفة",
    "خرنقت",
    "الخرنق",
    "الخورنق",
    "خزب",
    "تخزب",
    "الخزب",
    "الخزباء",
    "الخزيبة",
    "الخوزب",
    "الخيزب",
    "الخيزبان",
    "الخيزبة",
    "المخزاب",
    "تخزبز",
    "الخازباز",
    "الخزباز",
    "خزج",
    "المخزاج",
    "الخزاخز",
    "الخزخز",
    "خزرت",
    "تخازر",
    "الخزير",
    "الخزيرة",
    "الخوزرى",
    "الخيزرانة",
    "خزرب",
    "خزرجت",
    "الخزرج",
    "الخزرافة",
    "خزه",
    "خز",
    "اختزه",
    "الخز",
    "الخزز",
    "الخزاز",
    "الخزيز",
    "المخزة",
    "اختزعه",
    "انخزع",
    "تخزع",
    "الخزاع",
    "الخزاعة",
    "الخزعة",
    "الخزوع",
    "المخزاع",
    "المخزع",
    "الخزعبل",
    "الخزعبيلة",
    "خزعلت",
    "الخزعال",
    "الخزعالة",
    "الخزعل",
    "الخزاف",
    "انخزق",
    "الخازق",
    "الخازوق",
    "الخزق",
    "الخزوق",
    "المخزق",
    "المخزقة",
    "انخزل",
    "تخزل",
    "الأخزل",
    "الخزل",
    "الخزلة",
    "الخوزلى",
    "الخيزل",
    "الخيزلى",
    "خزلب",
    "خزمه",
    "خازمه",
    "تخازم",
    "تخزم",
    "الأخزم",
    "الخزام",
    "الخزامى",
    "الخزامة",
    "الخزم",
    "الخزومة",
    "المخزم",
    "أخزن",
    "اختزن",
    "استخزنه",
    "الخزان",
    "خزًا",
    "أخزاه",
    "خازى",
    "الخزية",
    "المخزاة",
    "المخزية",
    "خاسأ",
    "انخسأ",
    "تخاسأ",
    "الخاسئ",
    "الخسئ",
    "أخسر",
    "الخناسير",
    "الخنسرى",
    "الخيسرى",
    "أخس",
    "خسس",
    "تخاسوه",
    "استخسه",
    "الخساس",
    "الخساسة",
    "الخسان",
    "الخسيس",
    "الخسيسة",
    "المستخس",
    "خسفت",
    "أخسفت",
    "انخسفت",
    "الأخاسيف",
    "الخاسف",
    "الخسف",
    "الخسيفة",
    "خسق",
    "الخساق",
    "الخسقة",
    "الخيسق",
    "خسله",
    "الخسالة",
    "الخسيل",
    "الخسيلة",
    "أخساه",
    "خاساه",
    "تخاسى",
    "الخسا",
    "الخسي",
    "خشبه",
    "اختشب",
    "تخشبت",
    "اخشوشب",
    "الخشاب",
    "الخشباء",
    "الخشب",
    "الخشيب",
    "الخشيبة",
    "المخشب",
    "تخشخش",
    "خشر",
    "الخشار",
    "الخشارة",
    "مخاشر",
    "خشرمت",
    "الخشرم",
    "أخش",
    "اختش",
    "انخش",
    "الخشاش",
    "الخشاشة",
    "الخش",
    "الخشاء",
    "الخشيش",
    "المخش",
    "اختشع",
    "الخاشع",
    "الخشعة",
    "خشف",
    "أخشفت",
    "الخشاف",
    "الخشف",
    "الخشفة",
    "الخشوف",
    "الخشيف",
    "المخشف",
    "الخشكار",
    "الخشكنان",
    "خشله",
    "خشل",
    "تخشل",
    "الخشل",
    "الخشيل",
    "المخشلة",
    "خشمه",
    "أخشم",
    "تخشم",
    "الخشام",
    "الخشم",
    "خاشنه",
    "خشنه",
    "تخاشنوا",
    "تخشن",
    "استخشنه",
    "الخشا",
    "الخشو",
    "الخشي",
    "خشاه",
    "خشي",
    "خاشاه",
    "الأخشى",
    "أخصب",
    "اختصب",
    "الخصاب",
    "الخصيب",
    "خصره",
    "أخصره",
    "خاصره",
    "تخاصر",
    "تخصر",
    "الأخصر",
    "الخصار",
    "الخصر",
    "الخصيرى",
    "المخصرة",
    "المخصور",
    "أخصه",
    "اختص",
    "استخصه",
    "الاختصاص",
    "الخاصة",
    "الخصاصة",
    "الخصيص",
    "الخصوص",
    "الخصوصة",
    "الخصيصة",
    "خصفت",
    "أخصف",
    "اختصفت",
    "تخصف",
    "الخصاف",
    "الخصفة",
    "الخصيف",
    "الخصيفة",
    "المخصف",
    "أخصل",
    "خاصله",
    "تخاصل",
    "الخصلة",
    "الخصيل",
    "الخصيلة",
    "المخصال",
    "المخصل",
    "خصمه",
    "أخصم",
    "خاصمه",
    "اختصم",
    "تخاصموا",
    "الأخصوم",
    "الخصم",
    "الخصين",
    "خصاه",
    "خصي",
    "اختصاه",
    "الخصي",
    "المخصى",
    "أخضبت",
    "خضبه",
    "اختضب",
    "تخضب",
    "الخاضب",
    "الخضاب",
    "الخضب",
    "الخضبة",
    "الخضوب",
    "المخضب",
    "المخضبة",
    "تخضخض",
    "الخضاخض",
    "الخضخاض",
    "الخضخض",
    "أخضد",
    "خضده",
    "اختضد",
    "انخضد",
    "تخضد",
    "الخضاد",
    "الخضد",
    "المخضد",
    "المخضود",
    "أخضره",
    "خاضره",
    "خضره",
    "اختضره",
    "اخضار",
    "الأخاضر",
    "الأخضر",
    "الأخيضر",
    "الخضارة",
    "الخضاري",
    "الخضر",
    "الخضارى",
    "الخضور",
    "الخضير",
    "الخضيرة",
    "الخضيري",
    "المخضار",
    "المخضر",
    "المخضرة",
    "خضرب",
    "الخضارب",
    "المخضرب",
    "الخضريج",
    "خضرع",
    "تخضرع",
    "الخضارع",
    "خضرف",
    "تخضرم",
    "الخضارم",
    "الخضارمة",
    "الخضرم",
    "المخضرم",
    "خاضه",
    "خضض",
    "الخضاض",
    "الخضاضة",
    "الخضض",
    "الخضيض",
    "خاضعه",
    "اختضع",
    "تخضع",
    "الخضعة",
    "الخضيعة",
    "الخيضعة",
    "خضف",
    "الخضف",
    "أخضل",
    "خضله",
    "اخضل",
    "اخضأل",
    "اخضال",
    "اخضوضل",
    "الخضل",
    "الخضلة",
    "الخضيلة",
    "خضمه",
    "أخضم",
    "اختضمه",
    "الخضام",
    "الخضامة",
    "الخضمة",
    "الخضم",
    "الخضيمة",
    "خضن",
    "خاضن",
    "المخضن",
    "خطئ",
    "أخطأ",
    "خطأه",
    "تخاطأ",
    "تخطأه",
    "استخطأت",
    "الخطء",
    "الخطاء",
    "الخطأ",
    "الخطيئة",
    "أخطب",
    "اختطب",
    "تخاطبا",
    "الخطاب",
    "الخطب",
    "الخطبة",
    "الخطيب",
    "تخاطرا",
    "الخاطرة",
    "الخطر",
    "الخطار",
    "الخطارة",
    "الخطير",
    "خط",
    "خططه",
    "اختط",
    "الخط",
    "الخطيطة",
    "المخطاط",
    "المخط",
    "المخطوط",
    "أخطف",
    "اختطفه",
    "تخطفه",
    "الأخطف",
    "الخاطف",
    "الخاطوف",
    "الخطفة",
    "الخطفى",
    "الخطيفة",
    "المخطف",
    "أخطل",
    "تخطل",
    "الخطال",
    "خطمه",
    "الأخطم",
    "الخاطم",
    "الخطام",
    "المخطم",
    "أخطاه",
    "اختطى",
    "تخطاه",
    "أخظ",
    "خظا",
    "أخظى",
    "خظاه",
    "الخاظي",
    "الخظاة",
    "الخظوان",
    "الخظي",
    "خافت",
    "تخافتا",
    "خفج",
    "تخفج",
    "الخفج",
    "الخفيج",
    "خفخف",
    "الخفخاف",
    "خفد",
    "أخفدت",
    "خفره",
    "خفرت",
    "أخفره",
    "تخفر",
    "استخفره",
    "الخفارة",
    "الخفير",
    "أخفس",
    "انخفس",
    "تخفس",
    "خفشه",
    "الخفش",
    "اختفض",
    "انخفض",
    "تخفض",
    "الخافض",
    "الخافضة",
    "الخفض",
    "خفع",
    "أخفعه",
    "انخفعت",
    "الخفاع",
    "تخاف",
    "تخفف",
    "استخفه",
    "التخفيف",
    "الخفاف",
    "الخف",
    "الخفيف",
    "خفقت",
    "خفق",
    "اختفق",
    "الخافق",
    "الخفاق",
    "الخفاقة",
    "المخفق",
    "خفا",
    "خفي",
    "أخفى",
    "استخفى",
    "الخافي",
    "الخافية",
    "الخفية",
    "خقخق",
    "خق",
    "أخقت",
    "الأخقوق",
    "الخق",
    "خقن",
    "الخاقان",
    "خلأت",
    "خالأ",
    "أخلب",
    "اختلب",
    "استخلب",
    "الخلابة",
    "الخلب",
    "الخلبوب",
    "خلبسه",
    "الخلابس",
    "الخلابيس",
    "الخلبوص",
    "أخلج",
    "تخلج",
    "تخالجه",
    "المخلج",
    "الخلخل",
    "المخلخل",
    "الخلد",
    "الخلدة",
    "الخوالد",
    "المخلد",
    "أخلس",
    "خالسه",
    "تخالس",
    "تخلس",
    "الخالس",
    "الخلاسي",
    "الخلس",
    "الخليس",
    "الخليسة",
    "أخلص",
    "خالصه",
    "تخالص",
    "استخلصه",
    "الإخلاص",
    "الخالص",
    "الخالصة",
    "الخلاص",
    "الخلاصة",
    "الخلص",
    "الخلصان",
    "الخلصة",
    "الخليص",
    "خالطه",
    "اختلط",
    "تخالط",
    "الخلط",
    "الخلطة",
    "المخلاط",
    "المخلط",
    "خالعت",
    "تخالع",
    "اختلع",
    "الخالع",
    "الخلاع",
    "الخلع",
    "الخلعة",
    "الخولع",
    "المخلع",
    "المخلوع",
    "خالف",
    "تخالفا",
    "اختلف",
    "استخلفه",
    "التخاليف",
    "التخلف",
    "الخالف",
    "الخالفة",
    "الخلاف",
    "الخلافة",
    "الخلف",
    "الخلفة",
    "الخليف",
    "الخليفة",
    "المخالفة",
    "المخلاف",
    "المخلفة",
    "أخلق به وما أخلقه",
    "خالقه",
    "اختلق",
    "الأخلاق",
    "الأخلق",
    "الخالق",
    "الخلقاء",
    "الخلق",
    "الخلقة",
    "الخلوق",
    "الخليقة",
    "المخلقة",
    "خاله",
    "اختل",
    "تخلل",
    "الخلال",
    "الخلالة",
    "الخلل",
    "الخل",
    "الخلة",
    "الخليل",
    "خالمه",
    "خلمه",
    "اختلمه",
    "الخالم",
    "الخلم",
    "الخلمة",
    "أخلى",
    "تخالى",
    "استخلى",
    "اخلولى",
    "التخلاء الشحماني",
    "الخالي",
    "الخلا",
    "الخلاء",
    "الخلو",
    "الخلوة",
    "الخلي",
    "المخالي",
    "المخلاء",
    "المخلاة",
    "أخلت",
    "اختلى",
    "الخلى",
    "المخمج",
    "خمدت",
    "الخامد",
    "أخمر",
    "اختمرت",
    "تخمرت",
    "استخمر",
    "الخمار",
    "الخمر",
    "الخمرة",
    "الخمري",
    "الخمارة",
    "المختمرة",
    "المخمرة",
    "المستخمر",
    "أخمسوا",
    "الأخماس",
    "الخماسين",
    "الخمس",
    "الخمسة",
    "الخميس",
    "المخمس",
    "المخموس",
    "خمش",
    "خمشه",
    "تخمش",
    "الخامشة",
    "الخماشة",
    "تخامص",
    "انخمص",
    "الخمصان",
    "الخميصة",
    "المخمصة",
    "تخمط",
    "الخمط",
    "الخمطة",
    "الخماط",
    "أخملت",
    "اختمل",
    "الخامل",
    "الخمال",
    "الخمالة",
    "الخمل",
    "الخملة",
    "الخميل",
    "الخميلة",
    "أخم",
    "تخمم",
    "الخمامة",
    "الخميم",
    "المخمة",
    "الخمن",
    "الخمان",
    "الخمانة",
    "خنب",
    "أخنب",
    "اختنبوا",
    "تخنب",
    "الخناب",
    "الخنابة",
    "الخنب",
    "الخنبات",
    "الخنبة",
    "المخنبة",
    "خنبس",
    "الخنابس",
    "الخنابسة",
    "الخنبس",
    "الخنبسة",
    "الخنبوس",
    "خنثه",
    "اختنث",
    "انخنث",
    "الخناثة",
    "الخنث",
    "الخنثى",
    "المخناث",
    "الخنجرة",
    "الخنجرير",
    "الخندب",
    "الخندريس",
    "الخندع",
    "خندق",
    "الخندق",
    "تخنذذ",
    "الخنذيذ",
    "خنز",
    "الخنزوان",
    "الخنزوانة",
    "الخنزوانية",
    "الخناز",
    "الخنوز",
    "الخنيز",
    "خنزر",
    "الخنازير",
    "الخنزرة",
    "الخنزير",
    "أخنسه",
    "خنسه",
    "اختنس",
    "انخنس",
    "تخنس",
    "الأخنس",
    "الخنساء",
    "الخنس",
    "الخنوس",
    "الخنسر",
    "الخنسير",
    "الخنوص",
    "الخنصر",
    "أخنعته",
    "خنعه",
    "الخناعة",
    "الخنعة",
    "الخناف",
    "الخنفة",
    "الخنيف",
    "المخناف",
    "الخنفس",
    "خنقه",
    "انخنق",
    "الخانقاه",
    "الخناقة",
    "الخناقية",
    "المخنقة",
    "المخنق",
    "استخنب",
    "الخنان",
    "الخن",
    "الخنة",
    "الخنين",
    "المخنة",
    "أخنى",
    "الخنا",
    "الخنوة",
    "خاب",
    "الخوبة",
    "خات",
    "خاوت",
    "خوت",
    "اختات",
    "انخات",
    "تخوت",
    "الخائتة",
    "خوث",
    "الخوثاء",
    "أخاخ",
    "خود",
    "تخود",
    "الخود",
    "خاوذ",
    "تخاوذوه",
    "تخوذه",
    "الخوذان",
    "تخاورت",
    "الخوارة",
    "خازه",
    "خاوش",
    "خوش",
    "تخوش",
    "الخوش",
    "الخوشان",
    "الخوشق",
    "خاص",
    "أخوصت",
    "أخاصت",
    "خاوص",
    "خوصت",
    "تخاوص",
    "تخوص",
    "اخواصت",
    "الخوصاء",
    "الخواص",
    "الخياصة",
    "المخوص",
    "خاض",
    "أخاض",
    "خاوضه",
    "اختاض",
    "تخاوضوا",
    "تخوض",
    "الخوضة",
    "الخيض",
    "المخاض",
    "المخاضة",
    "المخوض",
    "تخوط",
    "الخوط",
    "خوع",
    "تخوع",
    "الخواع",
    "الخواعة",
    "الخوع",
    "خاف",
    "أخاف",
    "خاوفه",
    "خوفه",
    "الخاف",
    "الخواف",
    "الخوف",
    "خوق",
    "أخاق",
    "انخاق",
    "تخوق",
    "الخاق",
    "الخوق",
    "خال",
    "أخالت",
    "أخول",
    "استخول",
    "الأخول",
    "الخائل",
    "الخال",
    "الخؤولة",
    "الخولة",
    "الخولي",
    "أخام",
    "خوم",
    "الخامة",
    "خان",
    "اختانه",
    "تخون",
    "الخانة",
    "الخوان",
    "الخو",
    "الخوة",
    "أخوى",
    "اختوى",
    "الخوى",
    "الخواة",
    "الخواية",
    "الخوي",
    "الخوية",
    "خيبه",
    "الأخيب",
    "الخياب",
    "اختاته",
    "خايره",
    "اختاره",
    "تخايروا",
    "تخيره",
    "استخاره",
    "الاستخارة",
    "الخيار",
    "الخيرة",
    "الخيري",
    "الخبزبة",
    "تخيس",
    "الأخيس",
    "الخيس",
    "الخيسة",
    "المخيس",
    "خيشه",
    "المخيش",
    "خيص",
    "الخائص",
    "الخيص",
    "الخيصاء",
    "الخيصى",
    "الخيصان",
    "خاطت",
    "اختاط",
    "الخياط",
    "الخياطة",
    "الخيط",
    "الخيطة",
    "الخياطية",
    "المخيط",
    "خيف",
    "أخيف",
    "اختاف",
    "تخيفت",
    "الأخياف",
    "الخيف",
    "الخيفانة",
    "الخيفة",
    "أخيلت",
    "خايلت",
    "اختالت",
    "تخايل",
    "تخيلت",
    "استخال",
    "الأخيل",
    "الخالة",
    "الخيلاء",
    "الخيل",
    "الخيلة",
    "المخيل",
    "المخيلة",
    "المخيول",
    "أخامت",
    "أخيم",
    "تخيم",
    "الخيمة",
    "الخيمي",
    "الخيام",
    "الدال",
    "أدأب",
    "الدأب الدأب",
    "الدائبان",
    "دأث",
    "الدئث",
    "الدأثاء",
    "الدئثان",
    "الدؤثي",
    "دأدأ",
    "تدأدأ",
    "الدأداء",
    "الدئداء",
    "الدؤدؤ",
    "الدأدأة",
    "دأظ",
    "دأل",
    "داءله",
    "الدأل",
    "الدئل",
    "الدألان",
    "الدؤلول",
    "دأم",
    "تداءم",
    "تدأمه",
    "الدأم",
    "الدأماء",
    "دءا",
    "دأى",
    "الدأية",
    "دبأ",
    "دبأه",
    "دببه",
    "الدابة",
    "الدبب",
    "الدباء",
    "الدبة",
    "الدبوب",
    "الدبيب",
    "المدبة",
    "دبجه",
    "الديباج",
    "المدبج",
    "دبح",
    "اندبح",
    "دبخ",
    "دباخ",
    "الدبادب",
    "الدبداب",
    "الدبدبة",
    "دبرت",
    "استدبره",
    "التدبير",
    "الدابرة",
    "دبار",
    "الدبار",
    "الدبارة",
    "الدبري",
    "الدبير",
    "المدابر",
    "المستدبر",
    "أدبست",
    "دبسه",
    "ادبس",
    "الأدبس",
    "الدباسة",
    "الدبسة",
    "الدبسي",
    "دبشه",
    "الدباش",
    "الدبش",
    "دبغه",
    "اندبغ",
    "الدباغ",
    "الدبغ",
    "الدبغة",
    "الدبوغ",
    "تدبق",
    "الدابوق",
    "الدبوق",
    "الدبوقة",
    "الدبق",
    "الدبيقية",
    "المدبق",
    "الدبلة",
    "الدبول",
    "الدبيلة",
    "الدوبل",
    "الدبن",
    "الدبنة",
    "دبيت",
    "أدبت",
    "الدبى",
    "المدباة",
    "دثت",
    "دث",
    "الدث",
    "الدثاث",
    "الدثة",
    "تداثر",
    "تدثر",
    "أدثر",
    "اندثر",
    "الأدثر",
    "الداثر",
    "الدثار",
    "الدثاري",
    "الدثر",
    "الدثور",
    "دججت",
    "تدجج",
    "الداج",
    "الداجة",
    "الدجاجة",
    "الدجاجي",
    "الدجة",
    "الدجوج",
    "الدجوجي",
    "الدجيج",
    "الديجوج",
    "المدجج",
    "دج دج",
    "دجدجت",
    "تدجدج",
    "الدجداج",
    "داجر",
    "اندجر",
    "الدجران",
    "الديجور",
    "الديجوري",
    "الدجال",
    "الدجالة",
    "الدجيل",
    "دجم",
    "الدجم",
    "الدجمة",
    "أدجن",
    "داجنه",
    "الداجن",
    "الدجن",
    "الدجنة",
    "المدجان",
    "أدجى",
    "داجاه",
    "تدجى",
    "الداجية",
    "الدجى",
    "الدجية",
    "الدياجي",
    "دحبه",
    "دحجه",
    "دح",
    "اندح",
    "الدحوح",
    "دح دح",
    "الدحادح",
    "الدحداح",
    "الدحداحة",
    "الدحدح",
    "الدحيدحة",
    "دحدره",
    "تدحدر",
    "دحره",
    "اندحر",
    "دحرجه",
    "تدحرج",
    "الدحريج",
    "دحست",
    "أدحس",
    "الداحس",
    "الداحوس",
    "الدحاس",
    "الدحاسة",
    "الدحس",
    "الديحس",
    "دحص",
    "دحضت",
    "أدحضه",
    "الداحضة",
    "الدحوض",
    "المدحاض",
    "المدحضة",
    "دحقت",
    "أدحقه",
    "اندحق",
    "الداحق",
    "الدحاق",
    "الدحوق",
    "الدحيق",
    "دحقبه",
    "الدحقوم",
    "دحل",
    "أدحل",
    "داحله",
    "الداحل",
    "الداحول",
    "الدحال",
    "الدحل",
    "الدحلاء",
    "الدحلة",
    "الدحول",
    "الدحيلة",
    "دحلق",
    "دحمه",
    "الداحوم",
    "الدحم",
    "دحمر",
    "دحمس",
    "الدحامس",
    "الدحمس",
    "الدحمسان",
    "الدحمسة",
    "دحمله",
    "الدحامل",
    "الدحمل",
    "الدحملة",
    "دحن",
    "الدحن",
    "الدحنة",
    "الدحونة",
    "الديحان",
    "دحاه",
    "داحاه",
    "اندحى",
    "تداحيا",
    "تدحى",
    "ادحوى",
    "الأدحوة",
    "الأدحي",
    "الأدحية",
    "الدحية",
    "دخه",
    "دخ",
    "الدخ",
    "دخ دخ",
    "دخدخ",
    "تدخدخ",
    "الدخادخ",
    "الدخداخ",
    "الدخدخ",
    "دخدره",
    "الدخدار",
    "دخر",
    "أدخره",
    "ادخره",
    "الادخار",
    "دخرص",
    "الدخرص",
    "الدخرصة",
    "أدخس",
    "الدخاس",
    "الدخس",
    "الدخوس",
    "الدخيس",
    "الديخس",
    "المداخس",
    "أدخله",
    "داخلت",
    "تداخلت",
    "الداخل",
    "الداخلة",
    "الدخال",
    "الدوخلة",
    "المدخل",
    "الدخلل",
    "الدخامس",
    "الدخماس",
    "الدخمس",
    "الدخمسة",
    "المدخمس",
    "دخنت",
    "أدخنت",
    "ادخنت",
    "تدخن",
    "الداخنة",
    "الدخان",
    "الدخنان",
    "الدخنة",
    "الدخنس",
    "الداخي",
    "الدخى",
    "الدخياء",
    "الديدب",
    "الددان",
    "الددن",
    "الديدن",
    "الددا",
    "الدد",
    "درأ",
    "أدرأت",
    "دارأه",
    "ادرأ",
    "اندرأ",
    "تدارءا",
    "ادارءا",
    "تدرأ",
    "التدرأ",
    "التدرأة",
    "الدرء",
    "الدريء",
    "الدريئة",
    "المدرأ",
    "أدرب",
    "الدارب",
    "الدرب",
    "الدربة",
    "تدربأ",
    "دربج",
    "الدرابج",
    "دربح",
    "دربخ",
    "تدربس",
    "الدرابس",
    "الدرباس",
    "دربص",
    "الدرابكة",
    "الدربكة",
    "دربل",
    "الدربان",
    "الدربانية",
    "درجه",
    "اندرج",
    "استدرجه",
    "الأدرجة",
    "الدارج",
    "الدرجة",
    "الدريج",
    "المدراج",
    "درحه",
    "درح",
    "أدرده",
    "الدردي",
    "دردب",
    "الدرداب",
    "الدردب",
    "الدردبي",
    "الدردبيس",
    "الدردح",
    "الدردحة",
    "دردر",
    "تدردر",
    "الدردر",
    "الدردرى",
    "الدردور",
    "الدرداق",
    "الدردق",
    "الدردم",
    "الدار",
    "الدر",
    "الدرر",
    "الدرارة",
    "الدرة",
    "الدري",
    "الدرير",
    "المدرار",
    "المدرة",
    "الدرزة",
    "الدرزي",
    "الدرزية",
    "أدرس",
    "اندرس",
    "تدارس",
    "ادارس",
    "تدرس",
    "الدرس",
    "الدرياس",
    "الدريس",
    "المدراس",
    "المدرس",
    "المدرسة",
    "المدروس",
    "الدارش",
    "دروش",
    "تدروش",
    "درصت",
    "الدرص",
    "الدروص",
    "درعت",
    "أدرع",
    "اندرع",
    "تدرع",
    "تمدرع",
    "الدارع",
    "الدراعة",
    "الدرع",
    "الدرعة",
    "الدرعية",
    "درفس",
    "الدرفاس",
    "الدرفس",
    "الدرفسة",
    "الدرفلة",
    "درقه",
    "تدرق",
    "الدرق",
    "الدرقاء",
    "الدرقة",
    "الدرياق",
    "الدرياقة",
    "درقع",
    "ادرنقع",
    "الدرقع",
    "الدرقوع",
    "درقل",
    "الدرقل",
    "الدرقلة",
    "الدراقن",
    "أدرك",
    "داركه",
    "درك",
    "ادرك",
    "تدارك",
    "استدرك",
    "الدرك",
    "الدركة",
    "الدريكة",
    "المتدارك",
    "درمت",
    "أدرم",
    "درمه",
    "الأدرم",
    "الدارم",
    "الدراما",
    "الدرام",
    "الدرامة",
    "الدرماء",
    "الدرمة",
    "الدريم",
    "درمج",
    "ادرمج",
    "الدرامج",
    "درمك",
    "الدرمك",
    "أدرن",
    "الإدرون",
    "الدرانة",
    "الدرن",
    "الدرين",
    "المدران",
    "الداره",
    "الدارهة",
    "الدريه",
    "المدره",
    "درهمت",
    "ادرهم",
    "الدرهم",
    "المدرهم",
    "أدراه",
    "داراه",
    "ادرت",
    "تدرت",
    "الدرية",
    "المدرى",
    "المدراة",
    "الدست",
    "الدستة",
    "الدستجة",
    "الداسرة",
    "الدسار",
    "الدواسر",
    "الدوسر",
    "الدوسرة",
    "الدوسري",
    "الدوسراني",
    "دسه",
    "دسسه",
    "اندس",
    "الدساس",
    "الدساسة",
    "الدسة",
    "الدسيس",
    "الدسيسة",
    "دسع",
    "ادسع",
    "الدسيع",
    "الدسيعة",
    "المدسع",
    "دسق",
    "الدسق",
    "الدسقان",
    "الديسق",
    "الدسكرة",
    "أدسم",
    "تدسم",
    "الدسام",
    "الدسمة",
    "الدسيم",
    "الديسم",
    "الديسمة",
    "دسا",
    "دسى",
    "تدسى",
    "الدشت",
    "الدشيشة",
    "أدعب",
    "داعبه",
    "تداعب",
    "تدعب",
    "الداعب",
    "الداعبة",
    "الدعاب",
    "الدعبب",
    "الدعبوب",
    "دعث",
    "أدعث",
    "اندعث",
    "تدعثت",
    "الدعث",
    "دعثره",
    "الدعثر",
    "الدعثور",
    "دعجت",
    "الأدعج",
    "الدعجاء",
    "المدعوج",
    "داع داع وداع داع",
    "دع دع",
    "دعدع",
    "تدعدع",
    "الدعداع",
    "الدعدع",
    "تدعر",
    "الداعرة",
    "الدعر",
    "الدعرة",
    "المداعير",
    "الدعرور",
    "دعرم",
    "الدعرم",
    "دعسه",
    "أدعسه",
    "داعسه",
    "الدعس",
    "الدعوس",
    "الدعيس",
    "المدعس",
    "المدعاس",
    "المدعوس",
    "دعص",
    "أدعص",
    "داعصه",
    "تدعص",
    "اندعص",
    "الدعص",
    "الدعصاء",
    "الدعصة",
    "المدعص",
    "دعه",
    "أدع",
    "الدعاع",
    "دعقت",
    "أدعق",
    "الدعقة",
    "المداعق",
    "المداعيق",
    "المدعق",
    "المدعوقة",
    "داعكه",
    "تداعك",
    "الدعكة",
    "المدعك",
    "المدعوك",
    "دعله",
    "داعله",
    "الداعل",
    "دعلج",
    "الدعلج",
    "الدعلجة",
    "تداعمته",
    "الأدعم",
    "الدعام",
    "الدعمة",
    "الدعمي",
    "المدعوم",
    "دعن",
    "أدعن",
    "الدعن",
    "المدعن",
    "دعا",
    "داعى",
    "دعى",
    "ادعى",
    "اندعى",
    "تدعت",
    "استدعاه",
    "الأدعوة",
    "الأدعية",
    "الداعي",
    "الدعاء",
    "الدعاوة",
    "الدعاءة",
    "الدعوة",
    "الدعوى",
    "الدعوي",
    "الدعي",
    "المدعاة",
    "المدعى والمدعى عليه",
    "المدعي",
    "المدغدغ",
    "تدغر",
    "الداغر",
    "الدغرة",
    "الدغرى",
    "المدغرة",
    "الدغرور",
    "دغرق",
    "الدغرق",
    "أدغش",
    "داغش",
    "تداغش",
    "الدغش",
    "الدغشة",
    "الدغيشة",
    "أدغصه",
    "داغصه",
    "الداغصة",
    "دغف",
    "دغفاء",
    "دغفق",
    "الدغفق",
    "الدغفل",
    "الدغفلي",
    "أدغل",
    "الداغل",
    "الداغلة",
    "الدغل",
    "الدغيلة",
    "المدغل",
    "ادغمه",
    "ادغام",
    "الأدغم",
    "الدغام",
    "الدغمان",
    "دغمره",
    "الدغمري",
    "الدغمور",
    "المدغمر",
    "المدغمس",
    "دغن",
    "الدغنة",
    "دغينة",
    "أدفأت",
    "دافأ",
    "دفأه",
    "تدفأ",
    "استدفأ",
    "الدفاء",
    "الدفيئة",
    "المدفئة",
    "الدفتردار",
    "دفدف",
    "الدفادف",
    "دفره",
    "أدفر",
    "دفار",
    "الدفر",
    "دافع",
    "دفعه",
    "اندفع",
    "تدافع",
    "تدفع",
    "استدفع",
    "الدفع",
    "الدفاع الشرعي",
    "وزارة الدفاع",
    "الدفوع",
    "دفت",
    "أدف",
    "دافه",
    "دفف",
    "تداف",
    "استدف",
    "الدافة",
    "الدف",
    "الدفاف",
    "أدفق",
    "اندفق",
    "استدفق",
    "الأدفق",
    "الدفقة",
    "الدفوق",
    "الدفل",
    "دفنت",
    "اندفن",
    "تدافن",
    "تدفن",
    "الدفن",
    "الدفون",
    "دفا",
    "دفي",
    "أدفى",
    "دافى",
    "تدافى",
    "الدفواء",
    "دقدق",
    "دقر",
    "الدقر والدقرى",
    "الدقرارة",
    "الدقران",
    "دقس",
    "أدقع",
    "الأدقع",
    "الداقع",
    "الدقاع",
    "الدقعاء",
    "الدقعى",
    "الدوقعة",
    "الديقوع",
    "المدقع",
    "داقه",
    "تداقا",
    "استدق",
    "الدقاق والدقاقة",
    "الدقاق",
    "الدقاقة",
    "الدققة",
    "الدقوق",
    "الدقوقة",
    "الدقيق",
    "الدقية",
    "المدق والمدق",
    "المستدق",
    "أدقلت",
    "دوقل",
    "الدقل",
    "الدقلة",
    "الدقيلة",
    "الدوقل",
    "دقمه",
    "دقم",
    "أدقم",
    "الدقم",
    "الدقمة",
    "دقن",
    "الديقان",
    "دقي",
    "دكدك",
    "تدكدكت",
    "الدكداك والدكدك",
    "دكس",
    "تداكس",
    "الداكس",
    "الدكاس",
    "الدكيسة",
    "الدوكس",
    "دكع",
    "الدكاع",
    "تداك",
    "اندك",
    "الدكاء",
    "الدك",
    "الدكان",
    "الدكة",
    "المدك",
    "دكل",
    "تدكل",
    "الدكلة",
    "دكمه",
    "دكم",
    "تداكم",
    "أدكن",
    "الدالب",
    "الدلب",
    "المدلبة",
    "دلث",
    "ادلث",
    "اندلث",
    "تدلث",
    "الدلاث",
    "المدالث",
    "أدلج",
    "ادلج",
    "الدلجان",
    "الدلجة",
    "الدولج",
    "المدلج",
    "المدلجة",
    "تدالح",
    "دلخ",
    "الدالخ",
    "الدلاخ",
    "الدلخة",
    "الدلوخ",
    "دلدل",
    "تدلدل",
    "الدلدل",
    "أدلس",
    "دالسه",
    "دلس",
    "اندلس",
    "تدلس",
    "الدلس",
    "الدلسة",
    "دلصت",
    "دلص",
    "أدلصت",
    "اندلص",
    "الدلاص",
    "الدلص",
    "الدلصة",
    "الدليص",
    "دلظ",
    "دالظه",
    "اندلظ",
    "الدلظى",
    "الدلظ",
    "الدليظ",
    "أدلع",
    "ادلع",
    "اندلع",
    "الأدلع",
    "الدالع",
    "الدلوع",
    "الدليع",
    "الدولع",
    "أدلف",
    "اندلف",
    "تدلف",
    "الدالف",
    "الدلف",
    "الدلوف",
    "أدلقه",
    "اندلق",
    "تدلق",
    "استدلق",
    "الأدلق",
    "الدالق",
    "الدلق",
    "الدلوق",
    "دلكت",
    "تدلك",
    "الدلك",
    "الدلاك",
    "الدلوك",
    "الدليك",
    "المدالك",
    "المدلك",
    "دل",
    "دلله",
    "اندل",
    "تدللت",
    "استدل",
    "الأدل",
    "الدالة",
    "الدلال",
    "الدلالة",
    "الدل",
    "الدليلي",
    "الدليل",
    "الدليلة",
    "دلم",
    "ادلام",
    "الدلام",
    "الدلم",
    "الدلماء",
    "الدلمة",
    "الديلم",
    "ادلمس",
    "الدلامس",
    "الدلمس",
    "دلمص",
    "تدلمص",
    "الدلامص",
    "دلهه",
    "تدله",
    "الداله والدالهة",
    "المدله",
    "ادلهم",
    "الدلهام",
    "المدلهمة",
    "دلا",
    "دلي",
    "أدلى",
    "دالاه",
    "دلى",
    "ادلولى",
    "الدالي",
    "الدالية",
    "الدلاة",
    "الدلو",
    "الدمثاء",
    "أدمج",
    "دامجه",
    "ادمج",
    "تدامجوا",
    "تدمج",
    "الدامج",
    "الدماج",
    "الدمجة",
    "الدميجة",
    "المدماجة",
    "تدمدم",
    "الدمادم",
    "الدمدم",
    "دامر",
    "دمست",
    "أدمس",
    "دامسه",
    "اندمس",
    "تدمست",
    "الأدموس",
    "الداموس",
    "الدماس",
    "الدمس",
    "الدميس",
    "الديماس",
    "المدمس",
    "دمش",
    "أدمشه",
    "دمشه",
    "الدماشق",
    "الدمشق",
    "دمص",
    "الدومص",
    "دمعت",
    "الدماع",
    "الدمعان",
    "الدموع",
    "الدميع",
    "المدمع",
    "أدمغ",
    "الدامغة",
    "الداموغ والداموغة",
    "الدماغ",
    "الدمغة",
    "دمق",
    "اندمق",
    "الدمق",
    "الدمقاس والدمقس",
    "دمك",
    "الدموك",
    "المدماك",
    "المدمك",
    "أدمل",
    "دامله",
    "ادمل",
    "اندمل",
    "تدامل",
    "تدملت",
    "الدمال",
    "الدملج والدملوج",
    "دملق",
    "الدملوق والدمالق",
    "دملك",
    "تدملك",
    "الدملوك",
    "الداماء",
    "الدم",
    "الديموم والديمومة",
    "المدمة",
    "أدمن",
    "دمنت",
    "تدمن",
    "الأدمان",
    "الدمان",
    "الدمون",
    "الدمن",
    "الدمنة",
    "أدمى",
    "استدمى",
    "الدامي",
    "الدامية",
    "الدمة",
    "المدمى",
    "دنئ",
    "أدنأ",
    "تدنأ",
    "الدنيء",
    "الدنيئة",
    "الدنية",
    "دنح",
    "الدنح",
    "دنخ",
    "دنخت",
    "دنر",
    "تدنر",
    "الدينار",
    "تدنس",
    "الدنس",
    "الدنع",
    "أدنف",
    "الدنف",
    "دنق",
    "الدوانيقي",
    "دنقس",
    "دنقع",
    "دنن",
    "الدنانة",
    "الدنان",
    "داناه",
    "تدانى",
    "تدنى",
    "استدناه",
    "الأدنى",
    "الدانية",
    "الدنا",
    "الدنيا",
    "ده ده",
    "الدهب",
    "دهبل",
    "دهثه",
    "الدهثم",
    "دهدق",
    "الدهداق",
    "دهدم",
    "تدهدم",
    "دهده",
    "تدهده",
    "الدهداه",
    "الدهدهان",
    "الدهدهة",
    "دهدى",
    "تدهدى",
    "داهره",
    "دهور",
    "الداهر",
    "الداهرة",
    "الدهارير",
    "الدهر",
    "الدهري",
    "الدهوري",
    "الدهير",
    "دهرج",
    "أدهس",
    "ادهاس",
    "الدهاس",
    "الدهس",
    "الدهسة",
    "الدهوس",
    "دهشه",
    "أدهشه",
    "دهشر",
    "الدهشرة",
    "دهف",
    "أدهف",
    "الداهف",
    "الداهفة",
    "أدهق",
    "ادهقت",
    "الدهاق",
    "الدهق",
    "الدهقة",
    "دهقن",
    "تدهقن",
    "الدهقان",
    "دهك",
    "الدهوك",
    "تدهكر",
    "الدهكر",
    "تدهكم",
    "الدهكم",
    "الداهل",
    "الدهل",
    "دهمه",
    "دهم",
    "أدهمه",
    "دهمت",
    "ادهم",
    "ادهام",
    "الأدهم",
    "الدهام",
    "الدهم",
    "الدهماء",
    "الدهمة",
    "الدهيم",
    "الدهيماء",
    "الدهمثة",
    "الدهموث",
    "دهمج",
    "الدهامج",
    "الدهمج",
    "دهمس",
    "دهمق",
    "الدهامق",
    "دهنت",
    "أدهن",
    "ادهان",
    "تمدهن",
    "الدهن",
    "الدهناء",
    "الدهنة",
    "الدهين",
    "المدهن",
    "المدهنة",
    "دهاه",
    "دهي",
    "دهو",
    "داهاه",
    "تدهى",
    "الداهي",
    "الداهية",
    "الدهو",
    "الدهواء",
    "الدهوية",
    "الدهي",
    "الدهياء",
    "داء",
    "أداء",
    "أدوأ",
    "الداء",
    "الديئ",
    "الدوبارة",
    "الدواج",
    "داحت",
    "انداح",
    "تدوح",
    "الداح",
    "الداحة",
    "الدوح",
    "الدوحة",
    "الدواح",
    "أداخ",
    "الدائخ",
    "داد",
    "ديد",
    "الدواد",
    "الدوداة",
    "دار",
    "أدار",
    "داوره",
    "تدير",
    "استدار",
    "التدورة",
    "الداري",
    "الدارة",
    "الدور",
    "الدواري",
    "الديور",
    "الديرة",
    "المدار",
    "المدارة",
    "أداس",
    "انداس",
    "الدوائس",
    "الدواس",
    "الدويسة",
    "الديس",
    "الديسة",
    "المداس",
    "المداسة",
    "المدواس",
    "المدوس",
    "المدوسة",
    "الدوسق",
    "داش",
    "الدوطة",
    "عند الفرنجة",
    "أداف",
    "الدوفان",
    "داق",
    "ديقت",
    "أداقوا",
    "دوقه",
    "انداق",
    "تدوق",
    "الدائق",
    "الدوقانية",
    "الدوقلة",
    "داك",
    "تداوك",
    "الدوك",
    "الدوكة",
    "المداك",
    "المدوك",
    "أدال",
    "داول",
    "دول",
    "اندال",
    "تداولت",
    "استدال",
    "الدويل",
    "المداولة",
    "دام",
    "ما دام",
    "ديم به",
    "أدامت",
    "أديم",
    "داوم",
    "دومت",
    "استدام",
    "الدوام",
    "الدوم",
    "الديمة",
    "الديوم",
    "المدام",
    "المدامة",
    "المدوام",
    "المدوم",
    "دان",
    "أدين",
    "تدون",
    "الدون",
    "الديوان",
    "دوي",
    "أدوى",
    "داوى",
    "ادوى",
    "تداوى",
    "الداوية",
    "الداية",
    "الدوى",
    "الدواء",
    "الدواة",
    "الدواية",
    "الدو",
    "الدوية",
    "الديبوب",
    "داث",
    "ديث",
    "تديث",
    "الديثان",
    "الديثاني",
    "الدير",
    "الديراني",
    "داص",
    "انداص",
    "الدائص",
    "الدياص",
    "الدياصة",
    "المداكة",
    "المديكة",
    "الديلع",
    "تداين",
    "استدان",
    "الديانة",
    "الدين",
    "الدينة",
    "الديان",
    "المديان",
    "المدين",
    "دي دي",
    "الذال",
    "ذا",
    "ذات",
    "وذات الصدر",
    "وذات الرئة",
    "وذات الجنب",
    "ذئب",
    "أذأبت",
    "ذأبه",
    "تذاءبت",
    "تذأب",
    "استذأب",
    "الذؤابة",
    "الذئب",
    "المذأبة",
    "ذئر",
    "أذأره",
    "ذاءرت",
    "ذأف",
    "الذؤاف",
    "ذأل",
    "تذاءل",
    "ذؤالة",
    "المذأل",
    "ذأمه",
    "أذأمه",
    "الذأم",
    "الذأمة",
    "أذب",
    "ذبب",
    "الذباب",
    "الذب",
    "المذبة",
    "ذبحه",
    "اذبح",
    "تذابحوا",
    "الذابح",
    "الذباح",
    "الذبح",
    "الذبحة",
    "الذبيح",
    "الذبيحة",
    "الذبذب",
    "ذبر",
    "الذبر",
    "المذبر",
    "أذبله",
    "الذابل",
    "الذبالة",
    "الذيلة",
    "الذبيل",
    "ذحجته",
    "أذحجت",
    "ذح",
    "ذحذح",
    "الذحذاح",
    "الذحل",
    "اذخر",
    "الذاخر",
    "الذخر",
    "الذخيرة",
    "المذاخر",
    "ذرأ",
    "ذرئ",
    "أذرأه",
    "الذرأة",
    "الذرء",
    "ذرء ذرء",
    "الذرآني",
    "الذرئ",
    "الذرية",
    "أذرب",
    "الذراب",
    "الذرب",
    "الذربة",
    "المذرب",
    "ذرح",
    "الذراح",
    "الذريحة",
    "الذريحي",
    "ذرت",
    "الذرارة",
    "الذر",
    "الذرة",
    "الذرور",
    "الذريرة",
    "المذره",
    "أذرع",
    "ذارعت",
    "انذرع",
    "تذارعوا",
    "استذرع",
    "الذراع",
    "الذرع",
    "الذرعة",
    "الذروع",
    "الذريع",
    "المذراع",
    "المذرع",
    "ذرفت",
    "تذارف",
    "استذرف",
    "الذراف",
    "المذرف",
    "أذرق",
    "الذراق",
    "أذرت",
    "تذرى",
    "استذرى",
    "الذرا",
    "الذراوة",
    "الذرو",
    "الذروة",
    "المذرى",
    "المذراة",
    "دغدغت",
    "تذعذع",
    "الذعاذع",
    "الذعذاع",
    "ذعره",
    "أذعره",
    "تذعر",
    "انذعر",
    "الذاعر",
    "الذعرة",
    "الذعور",
    "ذعف",
    "أذعفه",
    "انذعف",
    "الذعاف",
    "الذعف",
    "أذعن",
    "المذعان",
    "ذفذف",
    "استذفر",
    "الذفرى",
    "ذف",
    "أذف",
    "ذاف",
    "ذففت",
    "استذف",
    "الذفاف",
    "الذف",
    "الذفف",
    "الذفيف",
    "المذفف",
    "ذقنت",
    "الذاقنة",
    "أذكرت",
    "ذاكره",
    "اذكره",
    "تذاكروا",
    "تذكرت",
    "التذكرة",
    "الذكر",
    "الذكرة",
    "الذكورة",
    "الذكير",
    "المذكار",
    "المذكر",
    "المذكور",
    "ذكت",
    "أذكت",
    "استذكت",
    "الذكا",
    "الذكاة",
    "الذكوة",
    "أذلق",
    "انذلق",
    "الذلقة",
    "الذولق",
    "المذلاقة",
    "تذلذل",
    "الذلذل",
    "أذل",
    "ذلله",
    "استذله",
    "الذلول",
    "الذليل",
    "المذلل",
    "ذمره",
    "تذامروا",
    "الذمار",
    "الذمارة",
    "الذمر",
    "الذمرة",
    "الذمير",
    "المذمر",
    "ذمل",
    "ذمله",
    "ذم",
    "أذم",
    "ذممه",
    "تذاموا",
    "تذمم",
    "استذم",
    "الأذم",
    "الذمام",
    "الذمامة",
    "الذم",
    "الذمة",
    "الذمي",
    "الذميم",
    "الذميمة",
    "المذم",
    "المذمة",
    "المذمم",
    "ذمه",
    "أذمهته",
    "ذمى",
    "أذماه",
    "استذمى",
    "الذمى",
    "الذماء",
    "المذماة",
    "ذنبه",
    "أذنب",
    "ذنب",
    "تذانب",
    "تذنب",
    "استذنب",
    "الأذنب",
    "الذناب",
    "الذنابى",
    "الذنابة",
    "الذنب",
    "الذنبة",
    "الذنوب",
    "الذنيباء",
    "الذنبية",
    "ذن",
    "ذنن",
    "الذنان",
    "الذنانة",
    "الذنن",
    "الذنين",
    "أذهبه",
    "ذهبه",
    "الذهب",
    "الذهبة",
    "الذهبية",
    "الذهيب",
    "المذهب",
    "ذهله",
    "ذهل",
    "أذهله",
    "الذهل",
    "المذهل",
    "ذاهنه",
    "استذهنه",
    "الذهن",
    "ذو",
    "أذاب",
    "ذوبه",
    "استذاب",
    "الإذواب",
    "الإذوابة",
    "الذوب",
    "الذوبة",
    "المذوب",
    "المذوبة",
    "ذاح",
    "ذوح",
    "المذوح",
    "ذاده",
    "أذاده",
    "الذود",
    "المذاد",
    "المذود",
    "تذاوق",
    "استذاق",
    "الذواق",
    "الذوق",
    "ذول",
    "أذواه",
    "الذواة",
    "ذبأه",
    "تذيأ",
    "أذاخ",
    "ذيخت",
    "الذيخ",
    "المذيخة",
    "ذاط",
    "أذاعه",
    "أذاله",
    "تذايلت",
    "تذيلت",
    "الذيال",
    "ذامه",
    "الذيم",
    "الراء",
    "الراتين",
    "رأبت",
    "الرئابة",
    "الرؤية",
    "رأبل",
    "ترأبل",
    "الرئبال",
    "رأد",
    "ارتأدت",
    "تراءد",
    "ترأد",
    "الرأد",
    "الرؤد",
    "الرئد",
    "الرأدة",
    "الرؤدة",
    "الرؤودة",
    "رأس",
    "رئس",
    "راءس",
    "رأسه",
    "ارتأس",
    "الرائس",
    "الرئاس",
    "الرآس",
    "الرأس",
    "الرؤاس",
    "الرؤاسي",
    "الريس",
    "المرآس",
    "رئف",
    "تراءفوا",
    "ترأف",
    "استرأفه",
    "أرألت",
    "راءل",
    "استرأل",
    "الرال",
    "الراءول",
    "الرؤال",
    "أرأمها",
    "راءم",
    "ترأمت",
    "الرأم",
    "الرئم",
    "الرؤمة",
    "رآه",
    "رئي",
    "أرأى",
    "راءاه",
    "تراءى",
    "ترأى",
    "تمرأى",
    "استرأى",
    "الرأي",
    "الرئي",
    "الرؤيا",
    "الرواء",
    "المرأى",
    "المرآة",
    "رابأه",
    "ارتبأ",
    "الربيء",
    "الربيئة",
    "المربأ",
    "المربأة",
    "المرتبأ",
    "أربت",
    "ربب",
    "ارتب",
    "تربب",
    "التربيب",
    "الرابة",
    "الرباب",
    "الربابة",
    "الربب",
    "الربى",
    "الربي",
    "الربوب",
    "الربيب",
    "الربيبة",
    "المرباب",
    "المرب",
    "ربثه",
    "ارتبث",
    "اربث",
    "تربث",
    "ارباث",
    "الربيثى",
    "ربج",
    "أربج",
    "تربج",
    "الرابج",
    "الرباجي",
    "أربحت",
    "رابحه",
    "تربح",
    "الربح",
    "الربيح",
    "المرابحة",
    "ربخ",
    "أربخ",
    "تربخ",
    "الربيخ",
    "اربد",
    "تربد",
    "ارباد",
    "الربد",
    "الربيد",
    "الربيدة",
    "المربد",
    "ربذ",
    "أربذ",
    "الربذاني",
    "الربذة",
    "الربذي",
    "المرباذ",
    "الربرب",
    "ربز",
    "ارتبز",
    "الربيز",
    "ربسه",
    "ارتبس",
    "اربس",
    "الربس",
    "الربيس",
    "ربشت",
    "أربش",
    "الربش",
    "الربصة",
    "ربضت",
    "أربضت",
    "تربض",
    "الرابض",
    "الرابضة",
    "الربض",
    "الربضة",
    "الربوض",
    "الربيض",
    "الرويبضة",
    "المربض",
    "ارتبط",
    "الرباط",
    "الربط",
    "الربيط",
    "الربيطة",
    "المرابطة",
    "المربطة",
    "أربع",
    "رابع",
    "ارتبع",
    "تربعت",
    "استربع",
    "الأربع",
    "الأربعاء",
    "الأربعا",
    "الأربعة",
    "الأربعون",
    "الرابع",
    "الرباع",
    "الرباعة",
    "الرباعية",
    "الربع",
    "الربعة",
    "الربعي",
    "الربوع",
    "الربيع",
    "الربيعة",
    "الروبع",
    "الروبعة",
    "المرباع",
    "المربعة",
    "المربوع",
    "اليربوع",
    "ربغ",
    "أربغت",
    "الرابغ",
    "الربغ",
    "ربقه",
    "ارتبق",
    "الربق",
    "الريبق",
    "ربك",
    "ارتبك",
    "الربك",
    "الربيك",
    "الربيكة",
    "ارتبل",
    "الرابلة",
    "الربل",
    "الربلة",
    "الربيل",
    "الربيلة",
    "الريبال",
    "الريبالة",
    "الريبل",
    "المربال",
    "أربنه",
    "تربن",
    "الربون",
    "ربًا",
    "ربي",
    "راباه",
    "رباه",
    "الربا",
    "الرباء",
    "الرباة",
    "الرباوة",
    "رتأ",
    "أرتأ",
    "رتبه",
    "ترتب",
    "الراتب",
    "الرتبة",
    "المرتبة",
    "المرتب",
    "أرته",
    "الأرت",
    "الرت",
    "الرتة",
    "الرتى",
    "الرتاج",
    "الرتاجة",
    "الرتج",
    "المراتج",
    "المرتاج",
    "رتخ",
    "أرتخ",
    "الرتخ",
    "الرتخة",
    "رتعت",
    "أرتع",
    "الرتاع",
    "الرتعة",
    "المرتع",
    "ارتتق",
    "الرتاق",
    "الرتق",
    "الرتقة",
    "أرتك",
    "ترتل",
    "الرتل",
    "الرتيلي",
    "رتمت",
    "أرتم",
    "ارتتم",
    "ترتم",
    "الرتام",
    "الرتم",
    "الرتمة",
    "الرتيمة",
    "رتن",
    "المرتنة",
    "الراتي",
    "الرتوة",
    "الرتينة",
    "رثأ",
    "رثئ",
    "أرثأ",
    "ارتثأ",
    "الرثء",
    "الرثأة",
    "الرثيئة",
    "المرثوء",
    "ارتثوا",
    "ارتث",
    "الرث",
    "الرثة",
    "الرثيث",
    "رثد",
    "أرثد",
    "ارتثد",
    "الرثد",
    "الرثدة",
    "المرثد",
    "رثع",
    "ارثعن",
    "المرثعن",
    "رثم",
    "رثمت",
    "الرثم",
    "الرثمة",
    "الرثيم",
    "المرثم",
    "رثنت",
    "ترثنت",
    "الرثنة",
    "الرثو",
    "ترثاه",
    "الرثاية",
    "الرثية",
    "المرثاة",
    "أرجأت",
    "المرجئة",
    "رجب",
    "أرجب",
    "الأرجاب",
    "الراجبة",
    "الرجب",
    "الرجبة",
    "الرجبية",
    "رجه",
    "أرجت",
    "الارتجاج",
    "الرجاج",
    "الرجاجة",
    "الرجة",
    "أرجحه",
    "راجحه",
    "رجحه",
    "ارتجح",
    "ترجح",
    "الراجح",
    "الرجاح",
    "الرجاحة",
    "الرجحانية",
    "المرجاح",
    "المرجوحة",
    "ارجحن",
    "رجد",
    "ترجرج",
    "الرجراجة",
    "الرجرج",
    "الرجرجة",
    "راجزه",
    "رجزه",
    "ارتجز",
    "تراجز",
    "ترجز",
    "الأرجوزة",
    "الراجز",
    "الرجازة",
    "الرجاز",
    "رجس",
    "أرجس",
    "ارتجست",
    "المرجاس",
    "المرجوسة",
    "راجع",
    "رجع",
    "ارتجع",
    "استرجع",
    "الراجع",
    "الرجاع",
    "الرجعة",
    "الرجعي",
    "الرجعية",
    "الرجيع",
    "الرجيعة",
    "المرجع",
    "المرجعة",
    "المرجوع",
    "المرجوعة",
    "أرجف",
    "ارتجف",
    "استرجف",
    "الإرجاف",
    "الراجف",
    "الرجاف",
    "الرجف",
    "أرجلت",
    "ارتجل",
    "الراجل",
    "الرجلان",
    "الرجليون",
    "الرجولة والرجولية",
    "الرجيل",
    "المرجل",
    "رجمه",
    "ارتجم",
    "تراجموا",
    "استرجمه",
    "الرجام",
    "الرجم",
    "الرجمة",
    "المراجم",
    "المرجام",
    "المرجم",
    "رجن",
    "أرجن",
    "ارتجن",
    "الرجين",
    "الرجينة",
    "المرجونة",
    "رجي",
    "ارتجاه",
    "ترجاه",
    "الأرجية",
    "الترجي",
    "الرجا",
    "الرجية",
    "أرحب",
    "تراحب",
    "الرحاب",
    "الرحبى",
    "الرحبة",
    "الرحيب",
    "المرحب",
    "أبو مرحب",
    "رح",
    "الأرح",
    "ترحرحت",
    "الرحراح",
    "الرحرح",
    "الرحرحان",
    "أرحض",
    "رحضه",
    "ارتحض",
    "الرحاضة",
    "الرحض",
    "الرحضاء",
    "المرحضة",
    "الرحاق",
    "الرحيق",
    "رحل",
    "أرحل",
    "راحله",
    "رحله",
    "ترحل",
    "استرحله",
    "الراحلة",
    "الراحول",
    "الرحال",
    "الرحول",
    "الرحولة",
    "المرتحل",
    "المرحل",
    "تراحم",
    "ترحم",
    "استرحمه",
    "الراحم",
    "الرحام",
    "الرحم والرحم والرحم",
    "الرحم",
    "الرحمن",
    "الرحمة",
    "الرحموت",
    "الرحمى",
    "الرحوم",
    "الرحيم",
    "المرحمة",
    "رحت",
    "ترحت",
    "الرحا والرحى",
    "المرحى",
    "ارتخ",
    "الرخاخ",
    "الرخ",
    "الرخاء",
    "الرخراخ",
    "الرخرخ",
    "ارتخصه",
    "ترخص",
    "استرخصه",
    "الرخصة والرخصة",
    "الرخيص",
    "أرخف",
    "الرخفة",
    "ترخل",
    "الرخاخيل",
    "الرخل",
    "الرخلة",
    "أرخمت",
    "الرخامى",
    "الرخم",
    "أرخى",
    "رخى",
    "راخى",
    "تراخى",
    "استرخى",
    "الأرخية",
    "الرخو",
    "الرخي",
    "المرخاء",
    "رخود",
    "الرخود",
    "الرخودة",
    "ردأه",
    "ترادأ",
    "الردء",
    "الرديء",
    "تردب",
    "الرداب",
    "الردب",
    "ردج",
    "الردج",
    "اليرندج",
    "ردحت",
    "أردح",
    "الرادحة",
    "الرداح",
    "الرداحة",
    "الردح",
    "الردحة",
    "الردحي",
    "الردوح",
    "المرتدح",
    "ردخ",
    "الردخ",
    "راده",
    "ردده",
    "ارتد",
    "تراد",
    "استرده",
    "الأرد",
    "الراد",
    "الرادة",
    "الرد",
    "الردة",
    "الردي",
    "الرديد",
    "الاسترداد",
    "المرد",
    "المردد",
    "المردودة",
    "ردسه",
    "تردس",
    "المرداس",
    "المردس",
    "ردعه",
    "ارتدع",
    "ترادعوا",
    "تردع",
    "الأردع",
    "الرداع",
    "الردعاء",
    "الرديع",
    "المردع",
    "ردغ",
    "أردغ",
    "ارتدغ",
    "الردغة",
    "المردغة",
    "ردفه",
    "أردف",
    "رادفت",
    "ارتدف",
    "ترادفا",
    "تردفه",
    "استردفه",
    "الرداف",
    "الردافى",
    "الردافة",
    "الرديف",
    "أردم",
    "ارتدم",
    "تردم",
    "الأردم",
    "الردام",
    "الردم",
    "الرديم",
    "الرديمة",
    "المتردم",
    "ارتدن",
    "رودن",
    "الأردن",
    "الرادن",
    "الردن",
    "الرديني",
    "المردن",
    "الرده",
    "الردهة",
    "أردى",
    "رادى",
    "رداه",
    "ترادوا",
    "الرداء",
    "الردى",
    "الرداة",
    "الردية",
    "المردى",
    "المرداة",
    "المردي",
    "أرذت",
    "المرذاذ",
    "الروذق",
    "رذله",
    "استرذله",
    "الأرذل",
    "الرذال",
    "الرذالة",
    "الرذل",
    "الرذيل",
    "الرذيلة",
    "رذم",
    "أرذم",
    "الرذم",
    "الرذوم",
    "الروذمة",
    "رذي",
    "أرذى",
    "أرذي",
    "المرذي",
    "رزأه",
    "رزئه",
    "ارتزأ",
    "ترازءوا",
    "الرزء",
    "الرزيئة",
    "المرزئة",
    "الإرزب",
    "الإرزبة",
    "المرزاب",
    "المرزبان",
    "المرزبة",
    "أرزح",
    "رزحه",
    "ترازح",
    "المرزاح",
    "المرزح",
    "المرزحة",
    "المرزيح",
    "رزخه",
    "المرزخة",
    "الرزداق",
    "الرزدق",
    "رزرزه",
    "رزت",
    "أرزت",
    "رززه",
    "ارتز",
    "الإرزيز",
    "الرزاز",
    "الرزازة",
    "الرزة",
    "الرزيز",
    "المرزة",
    "رزغ",
    "أرزغ",
    "ارتزغ",
    "استرزغه",
    "رازغه",
    "الرازغ",
    "الرزغة",
    "رزف",
    "أرزف",
    "الرزافة",
    "ارتزق",
    "استرزقه",
    "الرازقي",
    "الرازقية",
    "الرزاق",
    "الرزقة",
    "الروازق",
    "المرتزقة",
    "المرزوق",
    "أزرم",
    "ترزم",
    "الرزام",
    "الرزيم",
    "المرزام",
    "المرزم",
    "رازنه",
    "ترازن",
    "ترزن",
    "الأرزن",
    "الرزان",
    "الرزن",
    "الرزنة",
    "الروزنة",
    "أرسب",
    "الرسابة",
    "الرسوب",
    "المراسب",
    "الرستاق",
    "رسح",
    "أرسحه",
    "الأرسح",
    "الرسحاء",
    "أرسخه",
    "رسرس",
    "رسس",
    "ارتس",
    "تراسوا",
    "الأرسوسة",
    "الزس",
    "الرسة",
    "الرسيس",
    "رسعت",
    "الرساعة",
    "الرسيع",
    "المرسع",
    "المرسعة",
    "راسغه",
    "ارتسغ",
    "الرساغ",
    "الرسغ",
    "الرسيغ",
    "المرسغ",
    "أرسف",
    "راسله",
    "تراسل",
    "استرسل",
    "الراسلان",
    "الرسال",
    "الرسالة",
    "الرسل",
    "الرسلة",
    "الرسول",
    "الرسيل",
    "الرسيلة",
    "المرسال",
    "المرسل",
    "المرسلة",
    "رسمت",
    "أرسم",
    "ارتسم",
    "الراسوم",
    "الرسم",
    "الروسم",
    "المرسم",
    "أرسنت",
    "الأرسان",
    "الراسن",
    "المرسن",
    "الراسي",
    "الراسية",
    "الرسوة",
    "الرسي",
    "رشأت",
    "الرشأ",
    "الرشتة",
    "أرشح",
    "رشحه",
    "استرشح",
    "الارتشاح",
    "الراشح",
    "الرشوح",
    "الرشيح",
    "المرشحة",
    "أرشده",
    "استرشد",
    "الراشد",
    "الرشادة",
    "الرشدة",
    "الرشيدية",
    "المراشد",
    "الرشراش",
    "الرشرش",
    "رشت",
    "أرشت",
    "ترشش",
    "استرش",
    "المرش",
    "المرشة",
    "رشفه",
    "أرشف",
    "ارتشفه",
    "ترشفه",
    "الرشوف",
    "الرشيفة",
    "المرشف",
    "رشقه",
    "أرشق",
    "راشقه",
    "تراشق",
    "ترشق",
    "الأرشق",
    "الرشق",
    "رشمه",
    "أرشم",
    "ارتشم",
    "الأرشم",
    "الراشوم",
    "الرشم",
    "الروشم",
    "المرشم",
    "رشن",
    "الراشن",
    "الرشن",
    "الروشن",
    "أرشى",
    "راشاه",
    "ارتشى",
    "ترشاه",
    "استرشى",
    "الرشاء",
    "الرشاة",
    "الرشوة",
    "الرصب",
    "رصده",
    "رصدت",
    "أرصدت",
    "راصده",
    "ارتصده",
    "تراصدا",
    "ترصده",
    "الراصد",
    "الرصد",
    "الرصدة",
    "الرصدي",
    "رصرص",
    "الرصراصة",
    "رصه",
    "رصص",
    "ارتصت",
    "تراصت",
    "ترصصت",
    "الأرصوصة",
    "الرصاصي",
    "الرصيص",
    "أرصعه",
    "رصعه",
    "ارتصع",
    "الرصيع",
    "الرصيعة",
    "المرصاع",
    "المرصعان",
    "أرصف",
    "رصفه",
    "ارتصف",
    "تراصف",
    "ترصف",
    "الرصاف",
    "الرصافة",
    "الرصفة",
    "المرصافة",
    "أرصقه",
    "ارتصق",
    "رصنه",
    "أرصنه",
    "الراصن",
    "الرصين",
    "المرصن",
    "رصاه",
    "أرصى",
    "رضب",
    "ترضب",
    "الراضب",
    "الرضاب",
    "المرضب",
    "رضحه",
    "ارتضح",
    "ترضح",
    "الرضح",
    "الرضحة",
    "المرضاح",
    "المرضحة",
    "رضخت",
    "أرضخ",
    "تراضخوا",
    "ترضخ",
    "الرضاخة",
    "الرضخ",
    "الرضخة",
    "الرضيخة",
    "المرضاخ",
    "المرضخة",
    "رضرضه",
    "ترضرض",
    "الرضراض",
    "الرضراضة",
    "الرضرض",
    "ارتض",
    "الرضاض",
    "الرض",
    "المرضة",
    "رضعها",
    "راضعه",
    "ارتضعها",
    "تراضعا",
    "استرضع",
    "الراضع",
    "الراضعة",
    "الرضاع",
    "الرضاعة",
    "الرضوعة",
    "الرضيع",
    "المرضعة",
    "رضفه",
    "المرضافة",
    "أرضك",
    "رضم",
    "ارتضم",
    "الرضام",
    "الرضم",
    "الرضمة",
    "المرضوم",
    "رضنه",
    "رضاه",
    "رضيه",
    "أرضاه",
    "راضاه",
    "ارتضاه",
    "تراضيا",
    "استرضاه",
    "الرضا",
    "الرضاء",
    "الرضي",
    "رطأه",
    "رطئ",
    "استرطأ",
    "ترطب",
    "الرطب",
    "الرطبة",
    "الرطيب",
    "المرطبة",
    "الرطراط",
    "ارطست",
    "أرط",
    "استرطه",
    "الرطيط",
    "رطل",
    "أرطل",
    "راطله",
    "استرطله",
    "رطمه",
    "أرطم",
    "ارتطم",
    "تراطم",
    "ترطم",
    "الراطم",
    "الرطام",
    "الرطمة",
    "الرطوم",
    "الرطومة",
    "راطنه",
    "تراطنا",
    "الأرطى",
    "أرعبه",
    "رعبت",
    "ارتعب",
    "الأرعب",
    "الراعبي",
    "الرعيب",
    "الترعابة",
    "الترعيبة",
    "المرعبة",
    "رعبب",
    "الرعبب",
    "الرعبوب",
    "الرعبوبة",
    "رعبل",
    "ترعبل",
    "الرعبل",
    "الرعبلة",
    "الرعبولة",
    "رعثت",
    "رعثها",
    "ارتعثت",
    "ترعثت",
    "الأرعوثة",
    "الراعوثة",
    "الرعث",
    "الرعثاء",
    "الرعثة",
    "رعج",
    "أرعج",
    "ارتعج",
    "الرعج",
    "أرعد",
    "ارتعد",
    "ترعد",
    "الراعد",
    "الرعد",
    "الرعدة",
    "الرعاد",
    "رعدد",
    "ترعدد",
    "الرعديد",
    "الرعديدة",
    "ترعرع",
    "الرعراع",
    "الرعرع",
    "راعز",
    "المرعز",
    "المرعزاء",
    "المرعزي",
    "الممرعز",
    "رعس",
    "أرعسه",
    "ارتعس",
    "ترعس",
    "الرعاس",
    "الرعوس",
    "المرعس",
    "أرعشه",
    "رعشه",
    "ارتعش",
    "الرعاش",
    "الرعاشي",
    "الرعش",
    "الرعشاء",
    "الرعيش",
    "المرعش",
    "الرعشيش",
    "الرعشن",
    "أرعصه",
    "ارتعص",
    "ترعص",
    "رعض",
    "أرعضه",
    "ارتعض",
    "رعظ",
    "أرعظه",
    "رعظه",
    "ترعظ",
    "الرعظ",
    "رع",
    "الرعاعة",
    "أرعف",
    "ارتعف",
    "استرعف",
    "الأرعوفة",
    "الراعف",
    "الرعاف",
    "الرعافي",
    "الرعوف",
    "الرعيف",
    "المراعف",
    "رعقت",
    "الرعاق",
    "الرعيق",
    "رعله",
    "رعل",
    "أرعلت",
    "استرعل",
    "الأرعل",
    "الراعل",
    "الرعال",
    "الرعل",
    "الرعلاء",
    "الرعلة",
    "الرعولي",
    "الرعيل",
    "المرعل",
    "رعم",
    "رعمه",
    "الرعام",
    "الرعم",
    "الرعوم",
    "الأرعن",
    "الرعن",
    "الرعناء",
    "الرعون",
    "الأرعاوية",
    "الأرعوة",
    "الرعاوى",
    "الرعاوية",
    "رعت",
    "أرعت",
    "راعاه",
    "رعاه",
    "ارتعت",
    "ترعت",
    "استرعاه",
    "الترعاية",
    "الترعي",
    "الترعية",
    "الراعي",
    "الرعاية",
    "الرعي",
    "الرعية",
    "المرعاة",
    "أرغب",
    "رغبه",
    "ارتغب",
    "تراغب",
    "الرغاب",
    "الرغب",
    "الرغبانة",
    "الرغيب",
    "الرغيبة",
    "المرغب",
    "المرغبة",
    "أرغثته",
    "ارتغثها",
    "الرغاث",
    "الرغثاء",
    "الرغوث",
    "أرغد",
    "استرغد",
    "ارغاد",
    "الراغد",
    "الرغد",
    "الرغيد",
    "الرغيدة",
    "المرغدة",
    "المرغرغ",
    "رغست",
    "أرغس",
    "رغسه",
    "استرغسه",
    "الرغس",
    "المرغس",
    "المرغوس",
    "المرغوسة",
    "رغش",
    "الرغيغة",
    "الرغيف",
    "رغل",
    "أرغل",
    "الأرغل",
    "الرغلة",
    "الرغول",
    "أرغمه",
    "رغمه",
    "ترغم",
    "الراغم",
    "الرغام",
    "الرغامي",
    "الرغامة",
    "المراغم",
    "المرغم",
    "المرغمة",
    "رغن",
    "أرغن",
    "الأرغون",
    "أرغى",
    "رغى",
    "ارتغى",
    "تراغوا",
    "الراغية",
    "الرغاء",
    "الرغاوى",
    "الرغاوة",
    "المرغاة",
    "أرفأت",
    "رافأه",
    "رفأه",
    "الرفاء",
    "الرفاءة",
    "اليرفئي",
    "ارفأن",
    "الرفأنينة",
    "ترفت",
    "الرفات",
    "الرفت",
    "رافثه",
    "ترافثوا",
    "الرفث",
    "رفده",
    "رافده",
    "ارتفد",
    "ترافدوا",
    "استرفده",
    "الترفيد",
    "الرافدة",
    "الرفادة",
    "الرفدة",
    "الرفود",
    "الرفيدة",
    "المرفد",
    "رفرف",
    "الرفرف",
    "الرفوس",
    "المرفس",
    "أرفش",
    "لرفش",
    "الرفاش",
    "المرفشة",
    "ارتفص",
    "ترافصوا",
    "الرفصة",
    "الرفيص",
    "ارفض",
    "استرفض",
    "الرافضة",
    "الرافضي",
    "الرفاض",
    "الرفض",
    "الرفضة",
    "الرفيض",
    "المرفض",
    "رافعه",
    "ارتفع",
    "ترافعا",
    "استرفع",
    "الارتفاع",
    "الرفاع",
    "الرفاعة",
    "الرفع",
    "الرفعة",
    "المرفاع",
    "المرفوع",
    "رفغ",
    "أرفغ",
    "ترفغ",
    "الأرفغ",
    "الرفاغة",
    "الرفاغية",
    "الرفغ",
    "الرفغنية",
    "المرافغ",
    "أرفت",
    "ارتف",
    "الرفاف",
    "الرفافة",
    "الر ف",
    "الرفة",
    "الرفيف",
    "أرفقه",
    "رافقه",
    "ارتفق",
    "ترافقا",
    "استرفقة",
    "الأرفق",
    "الرافق",
    "الرافقة",
    "الرفق",
    "الرفقة",
    "الرفيقة",
    "المرافق",
    "المرتفق",
    "المرفق",
    "المرفقة",
    "أرفل",
    "الترفيل",
    "الرفال",
    "الرفل",
    "المرفال",
    "المرفلة",
    "أرفه",
    "رفهه",
    "استرفه",
    "الرفاهة",
    "الرفاهية",
    "الرفهة",
    "الرفهنية",
    "رافاه",
    "رفى",
    "ترافوا",
    "أرقأه",
    "الرقوء",
    "رقبه",
    "أرقبه",
    "راقبه",
    "ارتقب",
    "ترقبه",
    "الرقبى",
    "الرقبة",
    "الرقوب",
    "الرقيب",
    "المرقب",
    "المرقبة",
    "رقح",
    "ارتقح",
    "ترقح",
    "الرقاحي",
    "أرقده",
    "رقده",
    "تراقد",
    "ارقد",
    "استرقد",
    "الراقود",
    "الرقدة",
    "الرقود",
    "المرقد",
    "ترقرق",
    "الرقارق",
    "الرقراق",
    "رقشه",
    "ارتقشوا",
    "ترقش",
    "الرقش",
    "الرقاش",
    "الرقشاء",
    "الرقشة",
    "أرقص",
    "رقصه",
    "ترقص",
    "الرقاص",
    "الرقاصة",
    "المرقص",
    "المرقصة",
    "رقطه",
    "ترقط",
    "ارقاط",
    "الأرقط",
    "الرقط",
    "الرقطاء",
    "أرقع",
    "رقعه",
    "ارتقع",
    "ترقع",
    "استرقع",
    "الأرقع",
    "الرقاعة",
    "الرقاعي",
    "الرقعاء",
    "الرقيع",
    "المرقعان",
    "المرقع",
    "المرقعة",
    "رقه",
    "ترقق",
    "استرق",
    "الرقاق",
    "الرق",
    "الرقق",
    "الرقيق",
    "المرقاق",
    "المرقق",
    "المسترق",
    "أرقل",
    "الراقول",
    "الرقلة",
    "المرقال",
    "الأرقم",
    "الرقمة",
    "الرقيم",
    "المرقم",
    "رقنت",
    "أرقن",
    "ارتقن",
    "ترقن",
    "الإرقان",
    "الراقن",
    "الرقان",
    "الرقون",
    "الرقين",
    "رقا",
    "رقاه",
    "ارتقى",
    "تراقى",
    "استرقى",
    "الراقي",
    "الرقاء",
    "الرقو",
    "الرقوة",
    "الرقية",
    "المرقى",
    "المرقاة",
    "ركبه",
    "أركب",
    "ارتكب",
    "تركب",
    "استركبه",
    "التراكب",
    "التركيب",
    "الراكب",
    "الراكبة",
    "الراكوب",
    "الراكوبة",
    "الركبان",
    "الركبة",
    "الركيب",
    "الركوب",
    "الركوبة",
    "المركب",
    "المركوب",
    "ركح",
    "أركح",
    "ارتكح",
    "تركح",
    "الركح",
    "الركحاء",
    "الركحة",
    "المركاح",
    "أركده",
    "تراكد",
    "الراكدة",
    "ركرك",
    "تركرك",
    "أركز",
    "ركزه",
    "ارتكز",
    "الركاز",
    "الركز",
    "الركزة",
    "المرتكزة",
    "ركسه",
    "أركست",
    "ارتكس",
    "تراكس",
    "الركاس",
    "الركاسة",
    "الركس",
    "الركوسية",
    "الركيس",
    "المركوس",
    "أركضت",
    "راكضه",
    "ارتكض",
    "تراكضوا",
    "الركوض",
    "المركض",
    "المركضة",
    "أركعه",
    "تركع",
    "الركعة",
    "الركوع",
    "ارتكف",
    "الركفة",
    "رككت",
    "ارتك",
    "استركه",
    "الأرك",
    "الركاك",
    "الركاكة",
    "الرك",
    "الركى",
    "الركيكة",
    "المرتك",
    "ركله",
    "راكله",
    "تراكلوا",
    "تركل",
    "المركل",
    "ركمه",
    "ارتكم",
    "الركم",
    "الركمة",
    "مرتكم",
    "الركن",
    "المركن",
    "ركا",
    "أركى",
    "ارتكى",
    "الركوة",
    "الركية",
    "المركو",
    "رمأ",
    "أرمأ",
    "مرمآت",
    "رمثه",
    "أرمث",
    "استرمث",
    "الرمث",
    "الرمثة",
    "المرمثة",
    "رمج",
    "الرامج",
    "الرماج",
    "رامحه",
    "ترامحوا",
    "الرماحة",
    "الرمح",
    "الرموح",
    "الرميح",
    "أرمخ",
    "الرمخ",
    "أرمد",
    "الأرمد",
    "الرامد",
    "الرمادة",
    "الرمادي",
    "الرمدي",
    "رمرم",
    "ترمرم",
    "الرمرام",
    "ترامزوا",
    "ارتمز",
    "الرامزة",
    "الراموز",
    "الرمز",
    "الرمازة",
    "أرمس",
    "الراموس",
    "الرامس",
    "الرامسة",
    "الرمس",
    "المرمس",
    "المرموسة",
    "رمشت",
    "أرمش",
    "المرماش",
    "المرمش",
    "رمصت",
    "أرمصه",
    "الرمص",
    "الرميصاء",
    "أرمضه",
    "رمضه",
    "ارتمض",
    "ترمضت",
    "رمضان",
    "الرمض",
    "الرمضي",
    "المرمض",
    "رمطه",
    "رمع",
    "أرمع",
    "رمعت",
    "ترمع",
    "الرماع",
    "الرمعة",
    "الرماعة",
    "اليرمع",
    "ارمعل",
    "رمغه",
    "رمغ",
    "رامقه",
    "ارمق",
    "ترمق",
    "الأرماق",
    "الرماق",
    "الرمقة",
    "المرامق",
    "المرمق",
    "أرمكه",
    "ارمك",
    "استرمك",
    "الرامك",
    "الرمكة",
    "ارتمل",
    "أم رمال",
    "الرملاء",
    "الرملي",
    "المرمل",
    "المرملة",
    "رممه",
    "ارتمت",
    "ترمم",
    "استرم",
    "الأرمام",
    "الرمام",
    "الرمامة",
    "الرم",
    "الرماء",
    "الرمة",
    "الرميم",
    "المرمة",
    "الرمان",
    "المرمنة",
    "رمه",
    "أرمى",
    "راماه",
    "الرمي",
    "الرمية",
    "الرميا",
    "المرتمي",
    "المر ماة",
    "المؤرنب",
    "المؤرنبة",
    "الرنح",
    "المرنحة",
    "رنخ",
    "رنخه",
    "ترنخ",
    "الرناز",
    "المرتعة",
    "أرن فت",
    "الرانف",
    "الرانفة",
    "أرنق",
    "ترنق",
    "الرنق",
    "الرنقاء",
    "الرنقة",
    "الرونق",
    "الرنك",
    "ترنم",
    "الرنم",
    "الرنمة",
    "الرنيم",
    "رننت",
    "الرنن",
    "الرنى",
    "المرنان",
    "أرناه",
    "راناه",
    "ترنى",
    "الرنا",
    "الرناء",
    "الرنو",
    "رهبه",
    "رهب",
    "ترهب",
    "استرهبه",
    "الرهاب",
    "الرهابة",
    "الرهبنة",
    "الرهبوت",
    "ترهبل",
    "الرهبل",
    "الرهبلة",
    "أرهجت",
    "الرهج",
    "المرهج",
    "رهده",
    "رهد",
    "المرهود",
    "الرهدل",
    "رهدن",
    "الرهدن",
    "رهره",
    "ترهره",
    "الرهراه",
    "الرهره",
    "ارتهز",
    "رهس",
    "ارتهس",
    "ترهس",
    "رهشت",
    "ارتهش",
    "الراهشان",
    "الرواهش",
    "الرهيش",
    "رهص",
    "رهصت",
    "أرهص",
    "راهصه",
    "الإرهاص",
    "الرهص",
    "الرهصة",
    "الرهيص",
    "الرواهص",
    "المتراهصة",
    "المرهصة",
    "رهط",
    "ارتهطوا",
    "الرهاط",
    "الرهط",
    "رهفه",
    "رهف",
    "أرهفه",
    "المرهف",
    "المرهوف",
    "رهق",
    "راهق",
    "الرهاق",
    "الريهقان",
    "المرهق",
    "رهك",
    "رهوك",
    "ارتهك",
    "ترهوك",
    "الرهكة",
    "الرهوك",
    "رهل",
    "رهله",
    "الرهل",
    "رهمت",
    "أرهمت",
    "الرهمان",
    "الرهمة",
    "الرهوم",
    "رهمس",
    "رهن",
    "أرهن",
    "راهنه",
    "ارتهنه",
    "تراهن",
    "استرهنه",
    "الراهن",
    "الراهنة",
    "الرهان",
    "الرهن",
    "الرهين",
    "رها",
    "أرهى",
    "راهاه",
    "ارتهى",
    "تراهبا",
    "أرهاء",
    "الراهي",
    "الراهية",
    "الرهاء",
    "الرهو",
    "الرهوة",
    "المرهاة",
    "رهيأ",
    "ترهيأ",
    "روأ",
    "تروأ",
    "الرويئة",
    "أراب",
    "الأروب",
    "الروبان",
    "الروبة",
    "المروب",
    "الروثة",
    "راجت",
    "الروجة",
    "راح",
    "أراح",
    "أروح",
    "راوح",
    "ارتاح",
    "ارتوح",
    "تراوح",
    "تروح",
    "الاسترواح",
    "الأريح",
    "الأريحية",
    "التراويح",
    "الرائحة",
    "الراح",
    "الراحة",
    "الرواح",
    "الرواحة",
    "الروح",
    "الروحة",
    "الريح",
    "الريحي",
    "المراح",
    "المرتاح",
    "المرواح",
    "المروح",
    "المرياح",
    "المستراح",
    "رادت",
    "أراد",
    "أرود",
    "ارتاد",
    "استرادت",
    "الأرود",
    "الرود",
    "الرويد",
    "المرود",
    "روذن",
    "رازه",
    "راوزه",
    "الراز",
    "الروز",
    "الروزنامة",
    "الريازة",
    "روش",
    "الرؤوش",
    "راضه",
    "أراض",
    "أروض",
    "راوضه",
    "ارتاض",
    "تراوضا",
    "استراض",
    "استروض",
    "الروضة",
    "الريض",
    "الريضة",
    "راع",
    "أراعه",
    "تروع",
    "الأروع",
    "الرواع",
    "المروع",
    "أراغه",
    "راوغه",
    "ارتاغه",
    "تراوغا",
    "تروغت",
    "الأروغ",
    "الرائغ",
    "الرائغة",
    "الرواغة",
    "الرواغ",
    "الرياغ",
    "راوقه",
    "تروق",
    "الأروق",
    "الراووق",
    "الرواقيون",
    "الروق",
    "الروقة",
    "الريق",
    "ترول",
    "الرائل",
    "الراوول",
    "الروال",
    "المرول",
    "رامه",
    "تروم",
    "الروم",
    "الرومة",
    "الرومي",
    "المرام",
    "الأرونان",
    "الرواني",
    "الرون",
    "الرونة",
    "المرون",
    "روي",
    "أرواه",
    "ارتوى",
    "تروى",
    "الأروى",
    "التروية",
    "الراوي",
    "الراوية",
    "الروى",
    "الرواية",
    "الروي",
    "الروية",
    "الريا",
    "الريان",
    "الرية",
    "المروى",
    "المروي",
    "رابه",
    "ارتاب",
    "استراب",
    "الريب",
    "الريبة",
    "الرياب",
    "المسترابة",
    "الريباس",
    "أراثه",
    "استراثه",
    "الريث",
    "المريث",
    "الريجي",
    "ريخه",
    "الريد",
    "الريدانة",
    "الريدة",
    "رار",
    "أرار",
    "الرائرة",
    "الرير",
    "ارتاش",
    "تريش",
    "الأرتش",
    "الرائش",
    "الرياش",
    "المريش",
    "الرائطة",
    "الريطة",
    "أراع",
    "تريع",
    "استراع",
    "الريع",
    "ريغ",
    "تريغ",
    "الريغ",
    "راف",
    "أريف",
    "أرافت",
    "رايف",
    "تريف",
    "الريف",
    "الريفة",
    "أراق",
    "ريقه",
    "تريق",
    "الرائق",
    "الريقة",
    "الريال",
    "الريالة",
    "ريمت",
    "الريم",
    "أران",
    "الران",
    "الرين",
    "الرينة",
    "راه",
    "ريهت",
    "تريه",
    "الراية",
    "الزاي",
    "زأب",
    "زأبر",
    "الزئبر",
    "زأبق",
    "زئر",
    "أزأر",
    "تزأر",
    "الزئر",
    "الزأرة",
    "زأزأ",
    "تزأزأ",
    "زأفه",
    "أزأف",
    "الزؤاف",
    "زأم",
    "زئم",
    "أزأمه",
    "الزؤام",
    "الزئم",
    "الزأمة",
    "المزآم",
    "الزؤان",
    "زأى",
    "أزآه",
    "ازبأر",
    "أزبت",
    "زبب",
    "تزبب",
    "الأزب",
    "الزبب",
    "الزبيب",
    "الزبيبة",
    "الزبيبي",
    "زبده",
    "أزبد",
    "الزبد",
    "الزبدية",
    "زبره",
    "أزبر",
    "الزبرة",
    "الزبور",
    "المزبر",
    "زبرجه",
    "الزبرج",
    "زبرق",
    "الزبرقان",
    "زبطت",
    "الزباطة",
    "تزبع",
    "الزبعرى",
    "انزبق",
    "الزابوقة",
    "الزبل",
    "الزبيل",
    "الزنبيل",
    "زينه",
    "زابنه",
    "تزابنوا",
    "تزبنه",
    "استزبنه",
    "الزبانية",
    "الزبونة",
    "الزبن",
    "زباه",
    "أزباه",
    "تزابى",
    "تزبى",
    "الزبية",
    "تزتت",
    "الزتة",
    "ازدج",
    "الزجاج",
    "الزج",
    "المزجة",
    "انزجر",
    "تزاجروا",
    "الزجرة",
    "المزجر",
    "المزجرة",
    "زجله",
    "الزجال",
    "الزجل",
    "الزجلة",
    "المزجال",
    "المزجل",
    "زجم",
    "الزجم",
    "الزجمة",
    "أزجى",
    "زجى",
    "تزجى",
    "المزجاء",
    "المزجي",
    "زحه",
    "زاحره",
    "تزحر",
    "الزحر",
    "الزحرة",
    "الزحير",
    "زحزحه",
    "الزحزاح",
    "الزحزح",
    "أزحف",
    "زاحفه",
    "تزاحفوا",
    "تزحف",
    "الزحاف",
    "الزحف",
    "الزحفة",
    "الزحوف",
    "المزحف",
    "زحوله",
    "أزحله",
    "زحله",
    "تزحل",
    "تزحول",
    "الزحل",
    "المزحل",
    "زحلف",
    "تزحلف",
    "الزحلوفة",
    "زحلقه",
    "الزحلوقة",
    "المزحلق",
    "زحمه",
    "زاحمه",
    "ازدحم",
    "تزاحموا",
    "الزحم",
    "المزحم",
    "زحن",
    "تزحن",
    "الزحن",
    "الزحنة",
    "زخ",
    "الزخة",
    "زاخره",
    "تزخر",
    "الزاخر",
    "الزخاري",
    "زخرفه",
    "تزخرف",
    "الزخارف",
    "الزخرف",
    "زخف",
    "تزخف",
    "زخمه",
    "أزخم",
    "الزخمة",
    "انزرب",
    "الزرب",
    "الزربية",
    "الزرياب",
    "المزراب",
    "الزرجون",
    "زرده",
    "الزرادة",
    "الزراد",
    "المزرد",
    "تزرر",
    "الزرة",
    "الزرير",
    "المزر",
    "تزرزر",
    "الزرازر",
    "الزرزار",
    "أزرع",
    "زارعه",
    "الاستزراع",
    "الزارع",
    "الزراعة",
    "الزرع",
    "الزرعة",
    "الزريعة",
    "المزارعة",
    "زرف",
    "أزرف",
    "انزرف",
    "الزراف",
    "أزرقت",
    "انزرق",
    "ازراق",
    "تزورق",
    "الأزارقة",
    "الأزرق",
    "الأزرقي",
    "الزراق",
    "الزراقة",
    "المزراق",
    "زرمه",
    "زرم",
    "الأزرم",
    "الزريم",
    "الزرنب",
    "أزرى",
    "زاراه",
    "تزرى",
    "ازدراه",
    "استزراه",
    "الزارية",
    "المزراء",
    "زوزكت",
    "الزوزك",
    "زعب",
    "تزعب",
    "الزاعب",
    "الزاعبية",
    "الزعب",
    "الزعبة",
    "الزعبل",
    "زعجه",
    "أزعجه",
    "انزعج",
    "المزعاج",
    "ازعار",
    "الأزعر",
    "الزعر",
    "زعزعه",
    "تزعزع",
    "الزعازع",
    "الزعزاع",
    "الزعزاعة",
    "الزعزع",
    "الزاعط",
    "أزعف",
    "الزعاف",
    "الزعوف",
    "المزعف",
    "زعفره",
    "تزعفر",
    "الزعفران",
    "أزعقه",
    "انزعق",
    "الزعاق",
    "الزعقة",
    "المزعق",
    "أزعله",
    "تزعل",
    "الزعلة",
    "أزعم",
    "تزاعما",
    "تزعم",
    "الزعامة",
    "الزعمة",
    "المزعم",
    "زعنفت",
    "ازغاب",
    "الأزغب",
    "الزغابة",
    "أزغدت",
    "تزغدت",
    "الزغيد",
    "زغدب",
    "الزغدب",
    "الزغزغ",
    "زغفت",
    "ازدغف",
    "الزغف",
    "المزغف",
    "أزغلت",
    "الزغل",
    "الزغلة",
    "الزغلول",
    "تزغم",
    "الزغوم",
    "الزفت",
    "الزافرة",
    "زفرة",
    "الزفير",
    "زفزفت",
    "تزفزف",
    "الزفزاف",
    "الزفزافة",
    "الزفزف",
    "استزفه",
    "الزف",
    "الزفة",
    "الزفوف",
    "الزفيف",
    "المزفة",
    "الزفن",
    "أزفاه",
    "زقب",
    "انزقب",
    "الزقب",
    "الزقزاقة",
    "زقفه",
    "تزققه",
    "الزقفة",
    "زقق",
    "الزق",
    "أزقمه",
    "ازدقمه",
    "تزقم",
    "الزقمة",
    "زقن",
    "أزقنه",
    "زقى",
    "أزقاه",
    "الزاقي",
    "الزقية",
    "زكأ",
    "أزكا",
    "الزكاء",
    "المزكأ",
    "زكبت",
    "الزكبة",
    "الزكيبة",
    "زكره",
    "تزكر",
    "الزكرة",
    "زكزك",
    "تزكزك",
    "أزك",
    "تزكك",
    "الزك",
    "الزكة",
    "زكمت",
    "أزكمه",
    "الزكام",
    "الزكمة",
    "زكن",
    "أزكن",
    "زاكنه",
    "الزكانة",
    "الزكن",
    "تزكى",
    "الزكا",
    "الزكاة",
    "زلب",
    "الزلابية",
    "الزلبة",
    "أزلجه",
    "زلجه",
    "انزلج",
    "الزالج",
    "الزلوج",
    "المزلج",
    "زلحه",
    "تزلحه",
    "الزلح",
    "زلحقه",
    "تزلحف",
    "ازلحف",
    "زلخ",
    "زلخه",
    "الزلخة",
    "زلز",
    "الزلز",
    "الزلزة",
    "الزلزاء",
    "زلزله",
    "تزلزل",
    "الزلزل",
    "زلعت",
    "أزلعه",
    "تزلعت",
    "ازدلعه",
    "الزلعة",
    "الزيلع",
    "المزلع",
    "أزلفه",
    "الزلف",
    "الزلفة",
    "الزلفى",
    "المزدلفة",
    "المزلف",
    "المزلفة",
    "زلقت",
    "أزلقت",
    "تزلق",
    "الزلقة",
    "الزليق",
    "الزيلق",
    "المزلقان",
    "زلقمه",
    "الزلقم",
    "الزلقمة",
    "الزلقوم",
    "أزله",
    "استزله",
    "الزلال",
    "الزلالي",
    "الزل",
    "الزلاء",
    "الزلية",
    "الزلول",
    "الزليل",
    "المزلة",
    "زلمه",
    "ازدلم",
    "الأزلم",
    "الزلم",
    "الزلماء",
    "الزلمة",
    "المزلم",
    "زمته",
    "تزمت",
    "الزمت",
    "الزميت",
    "زمج",
    "ازمأج",
    "الزماج",
    "الزمج",
    "تزمجر",
    "زمخ",
    "تزمخ",
    "الزامخ",
    "الزمخ",
    "الزموخ",
    "تزمخر",
    "ازمخر",
    "الزماخر والزماخري",
    "الزمخر",
    "استزمر",
    "الزامر",
    "الزمارة",
    "الزمير",
    "الزمور",
    "الزمرد",
    "زمزم",
    "تزمزم",
    "الزمازم",
    "الزمزام",
    "الزمزم",
    "الزمزمة",
    "الزمزمية",
    "الزمزوم",
    "أزمع",
    "الزماع",
    "الزمع",
    "الزمعة",
    "الزمعي",
    "الزموع",
    "الزميع",
    "زمكه",
    "زمك",
    "الزمكة",
    "الزمك",
    "الزمكي",
    "زامله",
    "زمله",
    "تزاملوا",
    "تزمل",
    "ازدمل",
    "الأزمل",
    "الأزملة",
    "الزامل",
    "الزاملة",
    "الزمال",
    "الزمل",
    "الزملة",
    "المزملة",
    "زممه",
    "الإزميم",
    "الزمام",
    "الزمم",
    "أزمن",
    "زامنه",
    "الزمان",
    "الزمانة",
    "الزمن",
    "الزمين",
    "المتزامن",
    "زمه",
    "زمهرت",
    "ازمهر",
    "الزمهرير",
    "الزماورد",
    "أزنأ",
    "الزناء",
    "الزنيء",
    "زنب",
    "الزنابى",
    "الزنابة",
    "الزينب",
    "الزنبار",
    "الزنبر",
    "الزنبرى",
    "الزنبرية",
    "الزنبورية",
    "المزبرة",
    "الزنبلك",
    "تزنتر",
    "الزنترة",
    "زنجت",
    "زانجه",
    "تزنج",
    "الزنج",
    "الزنجي",
    "الزنجر",
    "أزند",
    "تزند",
    "الزناد",
    "الزندان",
    "الزندة",
    "الزندية",
    "المزند",
    "تزندق",
    "الزندق",
    "زنره",
    "زنرت",
    "تزنر",
    "زنف",
    "تزنف",
    "الزنوف",
    "زنفل",
    "أزنق",
    "الزناق",
    "الزنق",
    "الزنقة",
    "زنم",
    "زنمه",
    "الزنمة",
    "الزنيم",
    "أزنه",
    "الزنن",
    "الزنة",
    "الزنين",
    "زنهر",
    "أزناه",
    "زناه",
    "الزنية",
    "زه",
    "أزهد",
    "زهده",
    "تزاهدوه",
    "تزهد",
    "ازدهده",
    "الزاهد",
    "الزهادة",
    "الزهد",
    "الزهاد",
    "أزهر",
    "ازهر",
    "ازهار",
    "الأزهر",
    "الأزهران",
    "الزاهر",
    "الزاهرية",
    "الزهر",
    "الزهراء",
    "الزهراوان",
    "الزهري",
    "الزهار",
    "زهزق",
    "الزهزاه",
    "زهف",
    "أزهف",
    "انزهف",
    "تزهف",
    "ازدهف",
    "زهق",
    "أزهق",
    "زاهقه",
    "انزهق",
    "الزاهق",
    "الزهق",
    "الزهوق",
    "المزهقة",
    "زهكه",
    "زهل",
    "الزهلول",
    "زهم",
    "زهمت",
    "أزهم",
    "زاهمه",
    "الزهم",
    "الزهمة",
    "الزهومة",
    "زهي",
    "أزهى",
    "زهى",
    "ازدهى",
    "الزهاء",
    "زاب",
    "الميزاب",
    "زوبر",
    "الزوبر",
    "أزوج",
    "زاوجه",
    "ازدوجا",
    "تزاوجا",
    "الزاج",
    "الزوج",
    "الزوجة",
    "الزوجية",
    "المزاوجة",
    "المزواج",
    "المزدوج",
    "أزاحه",
    "الإزاحة الزاوية",
    "زاد",
    "أزاده",
    "زوده",
    "تزود",
    "الزاد",
    "زاره",
    "أزاره",
    "تزور",
    "استزاره",
    "ازوار",
    "الزار",
    "الزارة",
    "الزاورة",
    "الزور",
    "الزوراء",
    "الزورة",
    "الزيار",
    "الزير",
    "زوزك",
    "زوزى",
    "زاع",
    "تزوع",
    "الزاعة",
    "الزوعة",
    "أزاغه",
    "الزاغ",
    "المزوغ",
    "تزاوف",
    "التزاوف",
    "الزواف",
    "الزوفى",
    "زوقه",
    "التزويق",
    "الزاووق",
    "الزواق",
    "الزوقة",
    "المزوق",
    "زاك",
    "أزوك",
    "تزاوك",
    "زال",
    "أزاله",
    "زاوله",
    "زوله",
    "تزول",
    "استزاله",
    "الزائلة",
    "الزوائل",
    "الزوال",
    "الزول",
    "الزولة",
    "المزولة",
    "الزامة",
    "الزويم",
    "الزانة",
    "الزوان",
    "الزون",
    "أزوى",
    "الزو",
    "زواه",
    "تزوى",
    "الزاوي",
    "المزواة",
    "تزيب",
    "الأزيب",
    "زات",
    "أزات",
    "زيته",
    "استزات",
    "الزيت",
    "الزيتي",
    "الزيات",
    "الزيتون",
    "الزيج",
    "زايده",
    "ازداد",
    "استزاد",
    "الزائد",
    "الزائدة",
    "الزيادة",
    "المزادة",
    "الزيازية",
    "الزيزاء",
    "زاط",
    "الزياط",
    "الزيطة",
    "زيغه",
    "الزيق",
    "زال ويزال",
    "زايله",
    "زيله",
    "تزايلوا",
    "تزيمت",
    "الزيمة",
    "زانه",
    "أزين",
    "ازين",
    "الزائن",
    "الزيان",
    "الزينة",
    "المزين",
    "الزينون",
    "في الكيمياء",
    "زياه",
    "السين",
    "سأ",
    "سأبه",
    "سئب",
    "السأب",
    "السؤبان",
    "المسأب",
    "سأده",
    "سئد",
    "أسأد",
    "السؤاد",
    "السؤدة",
    "المسأد",
    "سأر",
    "أسأر",
    "تسأر",
    "السؤر",
    "السؤرة",
    "سأسأ",
    "تسأسأت",
    "سأفت",
    "سئفت",
    "سؤفت",
    "انسأف",
    "السؤاف",
    "السأف",
    "سأله",
    "ساءله",
    "تساءلوا",
    "السائل",
    "السآل",
    "السؤال",
    "السؤل والسول",
    "السؤلة",
    "السؤول",
    "المسألة",
    "أسأمه",
    "السؤوم",
    "سآ",
    "سأى",
    "السأو",
    "أسبأ",
    "استبأ",
    "انسبأ",
    "السباء",
    "السبأة",
    "السبئية",
    "السبئ",
    "السبيئة",
    "المسبأ",
    "سابه",
    "سببه",
    "استبوا",
    "تسابوا",
    "استسب",
    "الأسبوية",
    "السبب",
    "السبابة",
    "السبة",
    "السببة",
    "السببية",
    "المسب",
    "المسبة",
    "أسبتت",
    "انسبت",
    "السبتة",
    "المسبوت",
    "تسبج",
    "السبج",
    "السبجة",
    "السبيجة",
    "أسبحه",
    "السابحات",
    "السبوح",
    "سبحان",
    "السبح",
    "السبحة",
    "السوابح",
    "المسبح",
    "سبحل",
    "سبخت",
    "أسبخت",
    "تسبخ",
    "السباخ",
    "السبخ",
    "السبخة",
    "السبيخة",
    "سبد",
    "أسبد",
    "السبد",
    "سبره",
    "أسبره",
    "استبره",
    "السابري",
    "السبار",
    "السبور",
    "السبر",
    "السبرة",
    "المسبار",
    "المسبرة",
    "المسبور",
    "سبرت",
    "السبرات",
    "السبروت",
    "السبريت",
    "سبرج",
    "سبرد",
    "تسبسب",
    "السبسب",
    "أسبط",
    "سبطت",
    "الساباط",
    "السباطة",
    "مصرية قديمة عربيتها الكباسة",
    "السبط",
    "السبطانة",
    "اسبطر",
    "السبطر",
    "السبطرة",
    "السبيطر",
    "أسبعٌ",
    "استبعوا",
    "الأسبوع",
    "السابع",
    "السباعي",
    "السبع",
    "السبعة",
    "السبوع",
    "السبيع",
    "المسبعة",
    "أسبغ",
    "التسبغة",
    "سابق",
    "استبقوا",
    "تسابقوا",
    "السابق",
    "السابقة",
    "السبق",
    "انسبك",
    "السبيكة",
    "المسبكة",
    "اسبكر",
    "أسبلت",
    "الأسبل",
    "السابل",
    "السبلاء",
    "السبلة",
    "السبيل",
    "السبنجونة",
    "السبنسة",
    "السباهي",
    "السبه",
    "السباهية",
    "السبهلل",
    "السبهلى",
    "تسابى",
    "تسبى",
    "السابياء",
    "السبي",
    "السبية",
    "الست",
    "الستة",
    "الستون",
    "ستره",
    "ساتره",
    "استتر",
    "انستر",
    "الستر",
    "السترة",
    "الستير",
    "المسترة",
    "الستوق",
    "المستقة",
    "ستل",
    "ستله",
    "ساتل",
    "تساتل",
    "ستالة",
    "الستل",
    "المستل",
    "ستهه",
    "الإست",
    "سج",
    "السجاج",
    "سجح",
    "أسجح",
    "سجاح",
    "السجاح",
    "السجح",
    "السجحة",
    "السجيح",
    "السجيحة",
    "سجدت",
    "أسجد",
    "الساجد",
    "الساجدة",
    "السجاد",
    "المسجد",
    "المسجدة",
    "ساجره",
    "انسجر",
    "الأسجر",
    "الساجر",
    "الساجور",
    "السجر",
    "السجور",
    "السجير",
    "المسجرة",
    "المسجور",
    "الساجسي",
    "السجس",
    "السجيس",
    "السجسج",
    "سجعت",
    "الأسجوعة",
    "الساجع",
    "السجعة",
    "المسجع",
    "سجفت",
    "أسجف",
    "السجاف",
    "السجافة",
    "السجفة",
    "أسجل",
    "انسجل",
    "تساجلوا",
    "الساجول",
    "السجل",
    "السجيلة",
    "أسجمت",
    "السجم",
    "السجوم",
    "المسجام",
    "سجنه",
    "الساجون",
    "السجن",
    "السجنجل",
    "أسجى",
    "ساجاه",
    "تسجى",
    "الساجي",
    "السجواء",
    "السجية",
    "أسحبه",
    "تسحب",
    "السحاب",
    "سحبان",
    "السحبان",
    "السحبة",
    "المسحب",
    "أسحت",
    "الأسحت",
    "السحت",
    "السحتوت",
    "السحتيت",
    "السحيت",
    "السحيتة",
    "المسحوت",
    "سحجه",
    "انسحج",
    "تسحج",
    "السحوج",
    "المسحاج",
    "المسحج",
    "سحح",
    "انسح",
    "تسحح",
    "السحاء",
    "السحاحة",
    "أسحر",
    "تسحر",
    "السحارة",
    "السحرة",
    "السحري",
    "السحور",
    "السوحر",
    "المساحر",
    "المسحورة",
    "تسحسح",
    "السحساح",
    "السحساحة",
    "السحسحة",
    "سحطه",
    "انسحط",
    "المسحط",
    "سحف",
    "أسحفت",
    "السحاف",
    "السحفة",
    "السحوف",
    "السحيفة",
    "المسحف",
    "المسحفة",
    "سحقه",
    "أسحق",
    "انسحق",
    "السحوق",
    "سحلت",
    "أسحل",
    "ساحلوا",
    "انسحل",
    "الإسحل",
    "الساحل",
    "السحال",
    "السحالة",
    "السحل",
    "السحيل",
    "المسحلة",
    "المسحل",
    "المسحول",
    "أسحمت",
    "الإسحمان",
    "السحم",
    "السحمة",
    "ساحنه",
    "السحناء",
    "السحن",
    "السحنة",
    "المسحن",
    "المسحنة",
    "سحا",
    "سحى",
    "أسحى",
    "سحاه",
    "استحى",
    "انسحى",
    "الأسحوان",
    "الأسحية",
    "الساحية",
    "السحا",
    "السحاية",
    "المسحاة",
    "السخاب",
    "السخبر",
    "السخت",
    "السختيت",
    "السختيان",
    "المسخوت",
    "سخت",
    "السخاخ",
    "سخد",
    "السخد",
    "السخود",
    "المسخد",
    "سخرت",
    "سخره",
    "تسخره",
    "استسخر",
    "المسخرة",
    "السخرية والسخرية",
    "سخطه",
    "أسخطه",
    "تسخطه",
    "سخفه",
    "السخفة",
    "سخل",
    "أسخل",
    "سخلت",
    "السخالة",
    "السخل",
    "السخلة",
    "تسخم",
    "الأسخم",
    "السخامي",
    "السخم",
    "السخمة",
    "السخيم",
    "السخيمة",
    "المسخم",
    "أسخنه",
    "سخنه",
    "التساخين",
    "السخين",
    "السخن",
    "السخنان",
    "السخنة",
    "السخون",
    "السخينة",
    "المسخنة",
    "أسخى",
    "سخى",
    "تسخى",
    "السخاوية",
    "السخواء",
    "سدحت",
    "السادح",
    "السادحة",
    "استد",
    "انسد",
    "تسدد",
    "الانسداد التاجي",
    "الساد",
    "السدد",
    "المسد",
    "أسدره",
    "انسدر",
    "تسدر",
    "الأسدران",
    "السدار",
    "السدر",
    "السدير",
    "السيدارة",
    "أسدس",
    "السداس",
    "السدس",
    "السدوس",
    "السديس",
    "سدع",
    "المسدع",
    "أسدف",
    "الأسدف",
    "السدافة",
    "السدف",
    "السدفة",
    "السديف",
    "سدك",
    "السدك",
    "أسدله",
    "سدله",
    "انسدل",
    "السدل",
    "السديل",
    "انسدم",
    "سدن",
    "السادن",
    "سدي",
    "أسدى",
    "استدى",
    "تسدى",
    "السدى",
    "السذاب",
    "سرأت",
    "أسرأت",
    "الس رء",
    "أسرب",
    "انسرب",
    "السربة",
    "المسربة",
    "سربله",
    "تسربل",
    "السربال",
    "أسرج",
    "استسرج",
    "السارج",
    "السراجة",
    "سريج",
    "المسرجة",
    "سرجن",
    "السرجين",
    "تسرح",
    "السارح",
    "السارحة",
    "السرح",
    "السرحان",
    "السروح",
    "السريح",
    "السريحة",
    "أسرد",
    "سرده",
    "تسرد",
    "السارد",
    "السراد",
    "السردار",
    "سردق",
    "سرره",
    "استسر",
    "تساروا",
    "الأسارير",
    "السرار",
    "السرارة",
    "السر",
    "السراء",
    "السرة",
    "السرية",
    "السرور",
    "السرسوب",
    "السرسور",
    "السرسام",
    "سرطه",
    "انسرط",
    "تسرطه",
    "السراط",
    "السرط",
    "السريط",
    "المسرط",
    "أسرع",
    "سارع",
    "تسارع",
    "الأسروع",
    "السراع",
    "سرعان",
    "السرعان",
    "السريع",
    "السرعرع",
    "سرفت",
    "السرف",
    "السرفة",
    "السروف",
    "السريف",
    "انسرق",
    "تسرق",
    "السارقة",
    "السراقة",
    "السرق",
    "السرقة",
    "سرقن",
    "السرقين",
    "تسارك",
    "السركي",
    "السركودية",
    "السركومة",
    "السركومة اللمفية",
    "سرمه",
    "تسرم",
    "السرم",
    "السرمد",
    "السرمدي",
    "السراة",
    "سروله",
    "تسرول",
    "السراويل",
    "المسرول",
    "استرى",
    "السري",
    "سريوم",
    "سزيوم",
    "المسطبة",
    "سطحه",
    "انسطح",
    "تسطح",
    "السطح",
    "السطاح",
    "السطيح",
    "السطيحة",
    "المسطح",
    "أسطر",
    "استطر",
    "الساطر",
    "السطرة",
    "السطار",
    "المسطار",
    "المسطرة",
    "المسطر",
    "المسطرين",
    "سطع",
    "السطاع",
    "السطيع",
    "المسطع",
    "الساطل",
    "سطم",
    "الإسطام",
    "الأسطم",
    "الأسطمة",
    "السطام",
    "السطم",
    "سطنه",
    "سطا",
    "أسطى",
    "ساطاه",
    "السواطي",
    "سعب",
    "انسعب",
    "تسعب",
    "السعب",
    "السعابيب",
    "أسعده",
    "استسعد",
    "الساعدة",
    "السعادة",
    "السعدان",
    "السعدانة",
    "سعديك",
    "السعود",
    "أسعر",
    "استعرت",
    "تسعرت",
    "الأسعر",
    "التسعير الجبري",
    "الساعور",
    "الساعورة",
    "السعار",
    "السعر",
    "المسعار",
    "المسعر",
    "المسعور",
    "سعسع",
    "تسعسع",
    "سعطه",
    "أسعطه",
    "استعط",
    "السعاط",
    "السعوط",
    "المسعط",
    "سعفت",
    "أسعف",
    "ساعفه",
    "تسعفت",
    "الإسعاف",
    "السعاف",
    "السعوف",
    "المساعف",
    "أسعله",
    "استسعلت",
    "الساعل",
    "السعلى",
    "السعلاة",
    "أسعن",
    "تسعن",
    "السعن",
    "السعنة",
    "السعو",
    "السعوة",
    "أسعاه",
    "ساعاه",
    "تساعوا",
    "استسعاه",
    "الساعي",
    "المسعاة",
    "أسغب",
    "السغاب",
    "المسغبة",
    "سغد",
    "سغسغ",
    "تسغسغت",
    "سغل",
    "السغل التناسلي",
    "سغمه",
    "أسغمه",
    "المسغم",
    "السغن",
    "سفت",
    "استفت",
    "السفت",
    "سفتج",
    "السفتجة",
    "سافحها",
    "تسافحا",
    "السفح",
    "السفيح",
    "المسافح",
    "المسافحة",
    "المسفوح",
    "أسفد",
    "سافدها",
    "تسافد",
    "تسفد",
    "استسفد",
    "السفود",
    "أسفر",
    "سافر",
    "سفره",
    "انسفر",
    "تسفر",
    "استسفره",
    "السافر",
    "السافرة",
    "السافير",
    "السفار",
    "السفر",
    "السفيرة",
    "المسافر",
    "المسفار",
    "المسفر",
    "المسفرة",
    "سفسط",
    "السفسطي",
    "السوفسطائية",
    "السفساف",
    "السفسافة",
    "المسفسف",
    "سفسق",
    "سفسفة",
    "استفط",
    "تسفط",
    "السفاطة",
    "السفط",
    "السفاط",
    "السفيط",
    "سافعه",
    "سفعت",
    "استفع",
    "تسفع",
    "السفع",
    "السفعاء",
    "السوافع",
    "استف",
    "السفة",
    "السفوف",
    "السفيفة",
    "سفكه",
    "انسفك",
    "السفكة",
    "السفوك",
    "سفله",
    "استفل",
    "تسفل",
    "الأسفل",
    "السافلة",
    "السفلة",
    "المسفلة",
    "سفنت",
    "السافن",
    "السفانة",
    "السفان",
    "السفينة",
    "السوافن",
    "سافهه",
    "سفهه",
    "تسافه",
    "تسفهت",
    "السافه",
    "السفيه",
    "المسفهة",
    "سفا",
    "سفي",
    "السافياء",
    "السفا",
    "السفاء",
    "سقب",
    "أسقب",
    "ساقبه",
    "تساقبا",
    "السقاب",
    "السقب",
    "السقيبة",
    "سقد",
    "السقد",
    "السقدة",
    "أسقرت",
    "الساقور",
    "السقر",
    "السقرة",
    "السقار",
    "المسقار",
    "سقسق",
    "المسقسق",
    "ساقط",
    "تسقط",
    "استسقطه",
    "الإسقاط",
    "الساقط",
    "السقاط",
    "السقط",
    "السقطي",
    "السقوط",
    "السقيط",
    "السقيطة",
    "المسقاط",
    "المسقط",
    "المسقطة",
    "السقطري",
    "الأسقع",
    "أسقف",
    "سقفه",
    "تسقف",
    "السقف",
    "السقاف",
    "السقيف",
    "السقيفة",
    "المسقف",
    "سقلت",
    "الإسقال",
    "الإسقيل",
    "السقل",
    "أسقم",
    "سقمه",
    "المسقام",
    "المسقمة",
    "السقمونيا",
    "سقى بطنه",
    "سقي",
    "أسقاه",
    "ساقى",
    "سقاه",
    "استقى",
    "تساقى",
    "الاستسقاء",
    "السقاء",
    "السقاية",
    "السقي",
    "السقيا",
    "المسقاة",
    "المسقوي",
    "الإسكابة",
    "الأسكوب",
    "السكبة",
    "المسكبة",
    "سكبج",
    "السكباج",
    "أسكته",
    "سكته",
    "الأسكات",
    "الإسكاتة",
    "الساكوت",
    "الساكوتة",
    "السكوت",
    "السكيت",
    "المسكت",
    "أسكره",
    "سكره",
    "تساكر",
    "السكارين",
    "السكرة",
    "السكار",
    "السكري",
    "السكرية",
    "السكور",
    "السكران",
    "المسكر",
    "السكرجة",
    "السكركة",
    "الساكع",
    "السكع",
    "المسكعة",
    "سكف",
    "أسكف",
    "تسكف",
    "الأسكف",
    "الأسكفة",
    "الساكف",
    "السكافة",
    "استك",
    "انسك",
    "تسكك",
    "السكاك",
    "السكاكة",
    "السكاء",
    "السكة",
    "السكي",
    "السكل",
    "سكم",
    "السيكم",
    "استكن",
    "استكان",
    "تساكنوا",
    "تمسكن",
    "الساكن",
    "السكاكيني",
    "السكينة",
    "السكنى",
    "المسكن",
    "المسكين",
    "السكنجبين",
    "استلأ",
    "السلاء",
    "المسلوء",
    "سلبت",
    "أسلب",
    "استلبه",
    "تسلبت",
    "السلاب",
    "السلب",
    "السلبة",
    "السلابة",
    "السليب",
    "سلته",
    "انسلت",
    "الأسلت",
    "السلاتة",
    "السلت",
    "السلتاء",
    "السلتة",
    "سلجت",
    "استلج",
    "تسلج",
    "السلج",
    "السلجان",
    "السليج",
    "السليجة",
    "السلاجم",
    "السلجم",
    "أسلحه",
    "سلحه",
    "الإسليح",
    "السالح",
    "السلاح",
    "السلح",
    "سلخت",
    "سلخه",
    "انسلخ",
    "تسلخ",
    "الأسلخ",
    "السلاخة",
    "السلخة",
    "السلاخ",
    "السليخ",
    "السليخة",
    "المسلاخ",
    "المسلخة",
    "سلس",
    "أسلست",
    "السلاس",
    "السلسة",
    "السلاسل",
    "السلسال",
    "السلسل",
    "سلطه",
    "السلائط",
    "السلط",
    "السليط",
    "المسلاط",
    "المسلوط",
    "سلطنه",
    "السلطان",
    "السلطانية",
    "أسلع",
    "انسلع",
    "تسلع",
    "الأسلع",
    "السلع",
    "السلعة",
    "السولع",
    "المسلع",
    "سلغ",
    "استلف",
    "تسالف",
    "تسلف",
    "استسلف",
    "الأسلوفة",
    "السالفة",
    "السلافة",
    "السلف",
    "السلفة",
    "السلفي",
    "السليف",
    "المسلفة",
    "أسلق",
    "انسلق",
    "الأسالق",
    "السالقة",
    "السليق",
    "السليقة",
    "السليقي",
    "المسلاق",
    "المسلق",
    "المسلوقة",
    "السلقون",
    "السيلقون",
    "أسلكه",
    "انسلك",
    "تسلك",
    "السلكى",
    "السلكة",
    "السلاك",
    "المسلك",
    "استل",
    "انسل",
    "الإسلال",
    "السال",
    "السلة",
    "السليل",
    "السليلة",
    "المسلل",
    "سالمه",
    "استلم",
    "تسالما",
    "تمسلم",
    "الإسلام",
    "الأسيلم",
    "السلام",
    "السلامى",
    "السلامان",
    "السلم",
    "أبو سلمان",
    "السلمة",
    "السليم",
    "المسلم",
    "اسلهب",
    "السلهاب",
    "السلهابة",
    "السلهب",
    "اسلهم",
    "السلهم",
    "سلاه",
    "سليه",
    "أسلى",
    "انسلى",
    "تسلى",
    "السلوى",
    "السلوان",
    "السلوانة",
    "السلوة",
    "المسلى",
    "المسلاة",
    "سلى",
    "سليت",
    "أسلت",
    "استلت",
    "السلى",
    "سمأل",
    "اسمأل",
    "السمأل",
    "السموأل",
    "سامته",
    "تسامتا",
    "تسمته",
    "المتسامتة",
    "المسمت",
    "سمجه",
    "استسمجه",
    "السمج",
    "السميج",
    "سمجر",
    "أسمح",
    "سامحه",
    "تسامح",
    "السمح",
    "السمحة",
    "المسماح",
    "المسمح",
    "السمحاق",
    "السمحوق",
    "سمده",
    "اسمد",
    "السمد",
    "السميد",
    "اسمدر",
    "السمادير",
    "السميدع",
    "سامره",
    "تسامرا",
    "الأسمران",
    "السامر",
    "السمار",
    "السمارة",
    "السمر",
    "السمرة",
    "السمير",
    "السميرية",
    "المسمور",
    "سمرج",
    "السمرج",
    "السمرجة",
    "السمرمر",
    "السمرمرة",
    "السماسم",
    "السمسام",
    "السمسمان",
    "تسمط",
    "السامط",
    "السمطاء",
    "السميط",
    "المسمط",
    "سمعه",
    "استمعه",
    "تسمعه",
    "السامعة",
    "سماع",
    "السماعي",
    "السمع",
    "السميع",
    "المسمع",
    "المسمعة",
    "السمعمع",
    "السمعمعة",
    "السمق",
    "السميق",
    "استمك",
    "انسمك",
    "السمك",
    "السمكة",
    "السميكاء",
    "المسموك",
    "أسمل",
    "استمل",
    "تسمل",
    "السمال",
    "السملان",
    "السملة",
    "سملج",
    "السمالج",
    "السملاج",
    "السملج",
    "السمالخي",
    "السملق",
    "سملك",
    "سممه",
    "السمام",
    "السموم",
    "المسم",
    "المسمة",
    "سمنه",
    "تسمن",
    "استسمن",
    "الأسمن",
    "السامن",
    "السمانى",
    "السمان",
    "السمن",
    "السمنية",
    "السمين",
    "المسمنة",
    "السمند",
    "السمندل",
    "سمه",
    "السمه",
    "السمهى",
    "السمهة",
    "السميهى",
    "سمهج",
    "السماهج",
    "السمهاج",
    "السمهج",
    "السمهجة",
    "سمهر",
    "اسمهر",
    "السمهري",
    "ساماه",
    "سماه",
    "استمى",
    "تسامى",
    "استسمى",
    "الاسم",
    "السامي",
    "السما",
    "السماء",
    "السماوة",
    "السمي",
    "المسماة",
    "المسمى",
    "السناب",
    "السنابة",
    "السنب",
    "السنبة",
    "السنوب",
    "السنبوق",
    "السنبك",
    "السنبوك",
    "السنبول",
    "أسنت",
    "تسنت",
    "السنوت",
    "سنجه",
    "السنجة",
    "السنجق",
    "كانت في التقسيم الإداري",
    "تسنح",
    "استسنحه",
    "السنح",
    "السنيح",
    "السناخة",
    "السنخ",
    "سند",
    "أسند",
    "سانده",
    "استند",
    "تساند",
    "الإسناد",
    "السناد",
    "السند",
    "السنديان",
    "السنيد",
    "المسند",
    "سندر",
    "السندر",
    "السندري",
    "السندروس",
    "السندس",
    "سندل",
    "السندال",
    "السندل",
    "سنر",
    "السنور",
    "سنسنت",
    "السنسن",
    "السنسنة",
    "السنط",
    "السنطير",
    "سنع",
    "أسنع",
    "الأسنع",
    "السنع",
    "السنيع",
    "السنيعة",
    "السنعبة",
    "أسنف",
    "السناف",
    "السنف",
    "السنيف",
    "المسناف",
    "سنق",
    "أسنقه",
    "السنيق",
    "السنكسار",
    "عند النصارى",
    "أسنم",
    "استنم",
    "تسنمه",
    "السنام",
    "السنما",
    "السنماتوغراف",
    "السنمة",
    "السنيم",
    "المسنم",
    "السنمار",
    "سن",
    "سنن",
    "استن",
    "استسن",
    "السنان",
    "السنن",
    "السنة",
    "السنية",
    "السنون",
    "السنينة",
    "المسان",
    "سانهت",
    "تسنه",
    "السناهية",
    "السنهاء",
    "أسنى",
    "سانى",
    "سناه",
    "استنى",
    "تسنى",
    "السانية",
    "السناء",
    "السنواء",
    "المسناة",
    "المسنوية",
    "السنيورية",
    "سهبه",
    "استهب",
    "التسهيب",
    "السهبة",
    "المسهب",
    "المسهبة",
    "سهجت",
    "الأساهيج",
    "الساهج",
    "السهوج",
    "السيهج",
    "المسهج",
    "سهجر",
    "سهد",
    "أسهدت",
    "سهده",
    "الأسهد",
    "السهد",
    "السهدة",
    "السهود",
    "سهر",
    "أسهره",
    "الأسهران",
    "الساهر",
    "الساهرة",
    "الساهور",
    "السهار",
    "السهرة",
    "السهاري",
    "المسهار",
    "سهف",
    "استهفه",
    "الساهف",
    "السهاف",
    "السهف",
    "المسهاف",
    "المسهفة",
    "المسهوف",
    "سهكت",
    "سهك",
    "الأساهيك",
    "الساهك",
    "الساهكة",
    "السهاكة",
    "السهكة",
    "السهوك",
    "ساهله",
    "سهله",
    "تساهل",
    "تسهل",
    "استسهل",
    "السهلي",
    "سهيل",
    "أسهم",
    "ساهمه",
    "تساهم",
    "السهام",
    "السهم",
    "السهمة",
    "السهيم",
    "سها",
    "سهى",
    "سهو",
    "أسهى",
    "ساهاه",
    "سهاه",
    "السها",
    "السهو",
    "السهواء",
    "السهوة",
    "ساء",
    "أساء",
    "سوأه",
    "استاء",
    "السوآء",
    "السوأة",
    "السيئ",
    "السيئة",
    "المساءة",
    "المساوي",
    "السوبة",
    "السوبية",
    "ساج",
    "سوج",
    "الساجة",
    "السوج",
    "المسوجة",
    "أساخه",
    "تسوخ",
    "السواخ",
    "ساد",
    "أساد",
    "ساوده",
    "تسود",
    "اسواد",
    "الأسود",
    "الأسودان",
    "الأسودة",
    "السواد",
    "السوداء",
    "السودان",
    "السودد",
    "السيد",
    "السودق",
    "سودل",
    "السودل",
    "السوذانق",
    "السوذق",
    "السوذقي",
    "سار",
    "ساوره",
    "سوره",
    "تساور",
    "تسورت",
    "الإسوار",
    "السوار",
    "السور",
    "السورة",
    "المسور",
    "المسورة",
    "ساس",
    "استاس",
    "الساس",
    "السواس",
    "السوسن",
    "ساطت",
    "ساوط",
    "سوط",
    "استوط",
    "السوط",
    "السواط",
    "السويطاء",
    "السويطة",
    "المسواط",
    "المسوط",
    "المسياط",
    "أساع",
    "ساوعه",
    "الساع",
    "الساعة",
    "السواع",
    "سواع",
    "السوع",
    "المسياع",
    "ساغ",
    "أساغ",
    "أسوغ",
    "استساغه",
    "الأسوغ",
    "التسويغ",
    "السواغ",
    "السوغ",
    "السوغة",
    "المسوغات",
    "سافت",
    "أساف",
    "ساوف",
    "سوف",
    "استافه",
    "السائفة",
    "الساف",
    "السواف",
    "السوف",
    "السيفة",
    "المساف",
    "المسافة",
    "ساق",
    "أساقه",
    "ساوقه",
    "استاقه",
    "تساوقت",
    "تسوق",
    "السائق",
    "الساق",
    "الساقة",
    "السوق",
    "السوقة",
    "السوقي",
    "السويق",
    "السويقة",
    "السياق",
    "السيق",
    "السيقة",
    "المسوقة",
    "سوكه",
    "استاك",
    "تساوك",
    "تسوك",
    "السواك",
    "سال",
    "سول",
    "السوال",
    "السول",
    "السولار",
    "سام",
    "ساومه",
    "استامت",
    "تساوما",
    "تسوم",
    "السائمة",
    "السومة",
    "السيمة",
    "السيما",
    "السيماء",
    "السيمياء",
    "ساواه",
    "استوى",
    "تساويا",
    "الاستواء",
    "السواء",
    "السوية",
    "سيما",
    "المستوي",
    "سيأت",
    "انسيأ",
    "تسيأت",
    "السياء",
    "ساب",
    "سيبه",
    "انساب",
    "الإسابة",
    "السائبة",
    "السياب",
    "السيابة",
    "السيب",
    "السيوبة",
    "أساح",
    "انساح",
    "السيح",
    "المسياح",
    "المسيح",
    "أساخ",
    "السيدانة",
    "السيداق",
    "أساره",
    "سايره",
    "السير",
    "السيراء",
    "السيرة",
    "المسير",
    "السيساء",
    "السيساءة",
    "تسيطر",
    "أساعه",
    "سيع",
    "انساع",
    "تسيع",
    "الأسيع",
    "السياع",
    "السيع",
    "السيعاء",
    "المسيعة",
    "أساغه",
    "السيغ",
    "سيفت",
    "سايفه",
    "سيفه",
    "استافوا",
    "تسايفوا",
    "تسيفه",
    "السائف",
    "السيف",
    "السيفان",
    "السياف",
    "المسائف",
    "المسايفة",
    "المسيف",
    "أساله",
    "سيله",
    "تسايل",
    "السيال",
    "السيل",
    "السيلان",
    "السيمافور",
    "السية",
    "الشؤبوب",
    "شئز",
    "أشأزه",
    "اشتأز",
    "شأشأت",
    "تشأشأ",
    "شئفت",
    "استشأفت",
    "شأف",
    "الشأفة",
    "شأمهم",
    "شئم",
    "أشأم",
    "شاءم",
    "تشاءم",
    "تشأم",
    "الأشأم",
    "الشأم",
    "الشؤمي",
    "الشأمة",
    "شأن",
    "اشتأن",
    "الشأن",
    "شأوت",
    "شاءاه",
    "تشاءى",
    "شبب",
    "استشب",
    "الشاب",
    "الشباب",
    "الشبب",
    "الشب",
    "الشبة",
    "الشبوب",
    "المشبوب",
    "شابث",
    "تشبث",
    "الشبث",
    "الشبثة",
    "تشابر",
    "الشبر",
    "المشابر",
    "الشبور",
    "الشبورة",
    "شبرقه",
    "شبارق",
    "الشباريق",
    "الشبرقة",
    "أشبعه",
    "الشباعة",
    "الشبع",
    "الشبعة",
    "الشبيع",
    "شبك",
    "الشابك",
    "الشبيكة",
    "شبل",
    "أشبلت",
    "شبمه",
    "شبم",
    "الشبام",
    "الشبين",
    "شابهه",
    "اشتبه",
    "التشبيه",
    "الشبه",
    "الشبهة",
    "المتشابه",
    "المشبهة",
    "أشبى",
    "الشبا",
    "شبوة",
    "أشت",
    "تشتتوا",
    "الشت",
    "الشتوت",
    "الشتيت",
    "شتره",
    "الشتير",
    "الشترة",
    "شتمه",
    "شاتمه",
    "تشاتما",
    "الشتام والشتامة",
    "شتن",
    "أشتى",
    "شاتاه",
    "شتى",
    "الشتاء",
    "الشتوة",
    "الشتوي",
    "الشتي",
    "المشتى",
    "المشتاة",
    "شثنت",
    "الشثن",
    "أشجبه",
    "تشاجبت",
    "الشجاب",
    "الشجوب",
    "المشجب",
    "شجه",
    "شاجه",
    "الشجج",
    "الشجة",
    "الشجيج",
    "أشجرت",
    "شاجره",
    "اشتجر",
    "الشجر",
    "الشجراء",
    "الشجير",
    "الشواجر",
    "شاجعه",
    "الشجعة",
    "الشجيع",
    "شجنت",
    "أشجن",
    "تشجن",
    "الشاجن",
    "الشجن",
    "الشجنة",
    "شجاه",
    "شجي",
    "أشجاه",
    "الشجا",
    "الشجو",
    "الشجي",
    "شحث",
    "الشحاث",
    "شحج",
    "تشحج",
    "الشاحج",
    "شاح",
    "تشاحوا",
    "الشح",
    "الشحة",
    "الشحيح",
    "أشحذ",
    "المشحذ",
    "المشحذة",
    "الشحر",
    "الشحرور",
    "أشحطه",
    "شحطه",
    "الشحط",
    "الشحطة",
    "الشوحط",
    "شحم",
    "أشحم",
    "الشحم",
    "الشحمة",
    "الشحيم",
    "أشحن",
    "شاحنه",
    "تشاحنوا",
    "الشحناء",
    "الشحنة",
    "شحا",
    "شحى",
    "تشحى",
    "الشحوة",
    "انشخب",
    "الأشخوب",
    "الشخب والشخب",
    "الشخبة",
    "شخت",
    "الشخت",
    "شخس",
    "تشاخست",
    "أشخص",
    "الشاخص",
    "الشخص",
    "شخم",
    "أشخم",
    "الأشخم",
    "شدخت",
    "شدخه",
    "انشدخ",
    "تشدخ",
    "الشدخة",
    "المشدخ والمشدخة",
    "شد",
    "أشد",
    "اشتد",
    "تشادا",
    "تشدد",
    "الشداد",
    "الشدة",
    "الشديد",
    "والشديد",
    "المشد",
    "شدفه",
    "شدف",
    "تشادف",
    "الأشدف",
    "الشادوف",
    "الشدفة",
    "الأشدق",
    "الشدق",
    "الشدقم",
    "أشدنت",
    "الشادن",
    "أشدهه",
    "انشده",
    "الشداه",
    "المشاده",
    "الشادي",
    "الشدا",
    "الشدو",
    "تشذب",
    "الشاذب",
    "الشذب",
    "المشذب",
    "شذ",
    "أشذ",
    "تشذر",
    "الشذر",
    "شذر مذر",
    "أشذاه",
    "الشذو",
    "الشذا",
    "الشذاة",
    "أشرب",
    "شاربه",
    "استشرب",
    "الشارب",
    "الشاربة",
    "الشراب",
    "الشرب",
    "الشربة",
    "الشروب",
    "المشرب",
    "المشربة",
    "شرثت",
    "انشرثت",
    "الشرث",
    "أشرج",
    "انشرج",
    "تشرج",
    "الشريج",
    "الشريجة",
    "شرجع",
    "الشرجع",
    "المشرجع",
    "انشرح",
    "الشرخ",
    "أشرده",
    "شرده",
    "تشردوا",
    "الشريد",
    "شر",
    "الإشرارة",
    "الشرار",
    "الشرر",
    "الشر",
    "الشرة",
    "شرز",
    "أشرزه",
    "شارزه",
    "شرزه",
    "الشرز",
    "المشارز",
    "شارسه",
    "تشارس",
    "الشراس",
    "الشريس",
    "الشرسوف",
    "شرشر",
    "الشراشر",
    "الشرشر",
    "الشرشرة",
    "شرط",
    "أشرطه",
    "شارطه",
    "اشترط",
    "تشارطا",
    "الشرط",
    "الشريطة",
    "المشرطة",
    "أشرع",
    "اشترع",
    "الأشرع",
    "الشارع",
    "الشراعة",
    "الشرع",
    "الشرعة",
    "الشريعة",
    "المشرعة",
    "المشروع",
    "شرفت",
    "شارفه",
    "تشرف",
    "استشرف",
    "أشراف",
    "الشارف",
    "الشاروف",
    "الشرافة",
    "الشرف",
    "المشارف",
    "المشرفي",
    "شرقت",
    "شرق",
    "أشرقت",
    "انشرقت",
    "تشرق",
    "الإشراق",
    "التشريق",
    "الشارق",
    "الشراقي",
    "الشرقة",
    "الشريق",
    "المشارقة",
    "المشرق",
    "المشريق",
    "شركت",
    "أشركه",
    "شاركه",
    "اشترك",
    "تشاركا",
    "الاشتراكية",
    "الش راك",
    "الشرك",
    "الشركة",
    "المشترك",
    "شرمه",
    "انشرم",
    "تشرم",
    "الشرم",
    "شرنقه",
    "شره",
    "شراه",
    "أشرت",
    "شاراه",
    "اشتراه",
    "تشرى",
    "استشرى",
    "الشاري",
    "الشرى",
    "الشري",
    "المشتري",
    "شزب",
    "الشزيب",
    "تشازروا",
    "استشزر",
    "الشزر",
    "شزن",
    "تشزنت",
    "الشزن",
    "شسع",
    "شسعت",
    "الشسع",
    "الشسف",
    "الشسيف",
    "الششم",
    "شصب",
    "الشصب",
    "الشصيبة",
    "الشاصر",
    "الشاصرة",
    "الشصار",
    "الشصر",
    "أشصه",
    "الشصوص",
    "شصا",
    "أشصى",
    "الشصو",
    "شطأ",
    "أشطأ",
    "شاطأته",
    "الشطء",
    "انشطب",
    "الشاطب",
    "الشطبة",
    "الشطيبة",
    "الشطحة",
    "الشاطر",
    "الشطر",
    "الشطور",
    "الشطير",
    "الشطيرة",
    "المشطور",
    "وانظر الرقعة",
    "أشط",
    "شاطه",
    "اشتط",
    "الشطاط",
    "الشط",
    "الشطوف",
    "الشطفة",
    "المشطوف",
    "شطنت",
    "أشطنه",
    "الشطن",
    "الشطون",
    "الشيطان",
    "الشظف",
    "أشظاه",
    "شظى",
    "تشظى",
    "الشظى",
    "أشعب",
    "انشعب",
    "الشعب",
    "شعبان",
    "الشعيبة",
    "المشعب",
    "تشعث",
    "الأشعث",
    "الشعث",
    "شاعره",
    "تشاعر",
    "استشعر",
    "الأشعر",
    "الأشعرية",
    "الشاعر",
    "الشعر",
    "الشعراء",
    "الشعراني",
    "الشعرة",
    "الشعرور",
    "الشعور",
    "الشعير",
    "المشعر",
    "تشعشع",
    "الشعشاع",
    "الشعشع",
    "أشعت",
    "الأشعة السينية",
    "الأشعة الكونية",
    "الشع",
    "الشعاف",
    "الشعفة",
    "المشعوف",
    "شعلت",
    "اشتعلت",
    "تشعل",
    "الأشعل",
    "الشعيل",
    "المشعال",
    "شعن",
    "أشعن",
    "اشعن",
    "الشعنون",
    "شعا",
    "شعي",
    "أشعى",
    "الشاعي",
    "الشعى",
    "الشعواء",
    "انظرها مع مشتقاتها في شعذ",
    "شاغبه",
    "تشاغب",
    "المشغب",
    "أشغر",
    "شاغره",
    "اشتغر",
    "تشاغرا",
    "تشغر",
    "الشغار",
    "الشغر",
    "الشغارة",
    "شغشغ",
    "شغفه",
    "تشغفه",
    "الشغاف",
    "شغله",
    "اشتغل",
    "انشغل",
    "تشاغل",
    "الأشغولة",
    "الشغال",
    "الشغل",
    "المشغول",
    "شغيت",
    "أشغى",
    "شفره",
    "شفرت",
    "الشفر",
    "الشفرة",
    "الشفار",
    "الشفير",
    "الشفشاف",
    "المشفشف",
    "تشفع",
    "استشفع",
    "الشافع",
    "الشفائع",
    "الشفاعة",
    "الشفع",
    "الشفعة",
    "الشفوع",
    "الشفيع",
    "اشتف",
    "استشف",
    "الشفافة",
    "الشف",
    "الشفف",
    "الشفيف",
    "أشفق",
    "الشفق",
    "الشفقة",
    "الشفيق",
    "شفنه",
    "شفهه",
    "شافهه",
    "الشافه",
    "الشفاهي",
    "الشفة",
    "أشفى",
    "شافاه",
    "اشتفى",
    "تشفى",
    "استشفى",
    "الأشفى",
    "الشفا",
    "الشفاء",
    "الشفوي",
    "شقأ",
    "المشقأ",
    "أشقح",
    "شاقحه",
    "تشاقحا",
    "الأشقح",
    "الشقحة",
    "الشقدة",
    "الشقدف",
    "شقذ",
    "أشقذه",
    "شاقذه",
    "الشقذ",
    "الشقذان",
    "الشقذانة",
    "الشقيذ",
    "الأشقر",
    "الشقارى",
    "الشقر",
    "الشقرى",
    "الشقرة",
    "الشقار",
    "الشقور",
    "الشقير",
    "المشقر",
    "الشقراق والشقراق",
    "الشقشقة",
    "شقص",
    "الشقص",
    "الشقيص",
    "المشقص",
    "الشقف",
    "الشقاف",
    "شق",
    "شاقه",
    "شققه",
    "انشق",
    "تشاقا",
    "استشق",
    "الاشتقاق",
    "الشقائق",
    "الشقيق",
    "الشقيقة",
    "المشقة",
    "شقله",
    "شقاه",
    "أشقاه",
    "شاقى",
    "الشقاء",
    "الشقاوة",
    "الشقوة",
    "شكئ",
    "أشكأت",
    "الشكاء",
    "الشكبان",
    "شكده",
    "الشكد",
    "شكرت",
    "اشتكر",
    "تشكر",
    "الشكائر",
    "الشكارة",
    "الشكر",
    "الشكور",
    "الشكير",
    "المشكار",
    "المشكرة",
    "شكزه",
    "شاكسه",
    "تشاكسا",
    "الشاكوش",
    "شكشك",
    "شكع",
    "أشكعه",
    "شك",
    "شككه",
    "اشتك",
    "التشكيك",
    "الشاكة",
    "الشك",
    "الشكة",
    "الشكيكة",
    "المشك",
    "أشكل",
    "شاكله",
    "تشاكلا",
    "استشكل",
    "الإشكال",
    "الأشكل",
    "الشاكلة",
    "الشكال",
    "الشكلة",
    "المشاكلة",
    "المشكل",
    "أشكمه",
    "الشكم",
    "شاكهه",
    "تشاكها",
    "أشكى",
    "شاكاه",
    "شكى",
    "اشتكى",
    "تشاكى",
    "تشكى",
    "الشكاة",
    "الشكوة",
    "الشكية",
    "المشكاة",
    "الشلجم",
    "شلحه",
    "شلح العين",
    "تشلشل",
    "الشلاشل",
    "الشلشل",
    "شلقه",
    "أشل",
    "انشل",
    "الشلول",
    "الشليل",
    "المشلل",
    "الشالم",
    "الشولم",
    "الشيلم",
    "أشلى",
    "اشتلاه",
    "استشلى",
    "الشلا",
    "الشلو",
    "الشلية",
    "المشلى",
    "أشمته",
    "شمته",
    "الشامتة",
    "الشمات",
    "الشماتى",
    "شمجت",
    "الشماج",
    "الشمخ",
    "الشموخ",
    "الشمختر",
    "شمخر",
    "اشمخر",
    "الشمخريرة",
    "الشمخر",
    "شمذ",
    "الشامذ",
    "الشمذة",
    "أشمر",
    "انشمر",
    "تشمر",
    "الشامر",
    "الشمار",
    "الشمر",
    "الشمري",
    "الشمير",
    "شمرج",
    "الشمرج",
    "الشمروج",
    "شمرخ",
    "الشمراخ",
    "الشمروخ",
    "الشمردل",
    "شمز",
    "تشمز",
    "الشمأزيزة",
    "شامسه",
    "تشامسا",
    "الشامس",
    "الشمس",
    "شمص",
    "الشموص",
    "المشموص",
    "أشمط",
    "اشمأط",
    "اشماط",
    "الأشمط",
    "الشمط",
    "الشمطات",
    "الشمطان",
    "الشميط",
    "الشماطيط",
    "أشمع",
    "شمعه",
    "الشماع والشماعة",
    "اشمعل",
    "المشمعل",
    "شمق",
    "الأشمق",
    "الشمق",
    "الشمقمق",
    "شملت",
    "شمل",
    "أشملت",
    "اشتمل",
    "تشمل",
    "الشمال",
    "الشملة",
    "الشمول",
    "المشمال",
    "المشمل",
    "المشمول",
    "شملل",
    "الشملال",
    "الشملول",
    "الشمليل",
    "شمه",
    "أشم",
    "شممه",
    "اشتمه",
    "تشممه",
    "الشمم",
    "الشمامات",
    "الشميم",
    "شنأه",
    "تشانؤوا",
    "الشناءة",
    "الشنوءة",
    "المشنأ",
    "المشنأة",
    "المشنوء",
    "الشنب",
    "الشنباء",
    "شنتر",
    "الأشنج",
    "المشنج",
    "شنره",
    "الشنار",
    "شنشن",
    "الشنشنة",
    "تشنع",
    "استشنع",
    "الشنعاء",
    "الشنعة",
    "تشنفت",
    "الشنف",
    "شنقه",
    "اشنق",
    "شانقه",
    "تشانق",
    "الشناق",
    "الشنقاء",
    "المشناق",
    "أشن",
    "شنن",
    "انشن",
    "تشنن",
    "استشنت",
    "الشانة",
    "الشنان",
    "الشنانة",
    "الشن",
    "الشنة",
    "الشنون",
    "الشنين",
    "المشنة",
    "شهبه",
    "شهب",
    "أشهب",
    "اشتهب",
    "اشهاب",
    "اشهب",
    "الأشهب",
    "الشهاب",
    "الشهابة",
    "الشهباء",
    "الشهبة",
    "أشهده",
    "شاهده",
    "استشهد",
    "الإشهاد",
    "التشهد",
    "الشاهد",
    "الشهادة",
    "الشهد",
    "الشهيد",
    "المشاهدة",
    "المشهود",
    "الشهدانج",
    "الشهدانق",
    "شهره",
    "شاهره",
    "اشتهر",
    "تشاهر",
    "الشهر",
    "الشهرة",
    "الشهير",
    "المشهورات",
    "الشهرمان",
    "شهق",
    "الشاهق",
    "شهل",
    "شاهله",
    "تشهل",
    "الشهل",
    "الشهلة",
    "شهمه",
    "الشيهم",
    "الشاهنشاه",
    "الشاهين",
    "شهاه",
    "شهية",
    "شهو",
    "أشهاه",
    "اشتهى",
    "تشهى",
    "الشاهية",
    "الشهوان",
    "الشهوة",
    "المشهيات",
    "شاب",
    "الشوبة",
    "الشياب",
    "شوذت",
    "أشار",
    "شاوره",
    "اشتار",
    "اشتور",
    "تشاوروا",
    "استشار",
    "الشار",
    "الشوار",
    "الشور",
    "الشورة",
    "الشيار",
    "الشير",
    "المشار",
    "المشور",
    "المشورة",
    "المشيرة",
    "شاس",
    "شاوس",
    "تشاوس",
    "شوشه",
    "تشوش",
    "شاص",
    "شوصت",
    "تشوص",
    "الأشوص",
    "الشوص",
    "الشوصاء",
    "الشوصة",
    "الشياص",
    "تشوط",
    "الشوط",
    "شاظ",
    "الشواظ",
    "شوع",
    "الشوع",
    "أشاف",
    "شوفه",
    "شيف",
    "اشتاف",
    "تشوف",
    "الشياف",
    "المشوفة",
    "أشاق",
    "شوقه",
    "اشتاقه",
    "الشائق",
    "الشيق",
    "شاكته",
    "شاك",
    "أشاك",
    "الشوكاء",
    "الشوكة",
    "أشاله",
    "شاول",
    "انشال",
    "تشاول",
    "الشائل",
    "الشائلة",
    "الشول",
    "الشولة",
    "الشوالة",
    "المشوال",
    "المشول",
    "المشولة",
    "الشوم",
    "الشوان",
    "الشونيز",
    "شوهه",
    "الأشوه",
    "الشاة",
    "وشاه بلوط",
    "شاهنشاه وشهنشاه",
    "الشوهاء",
    "الشوهة",
    "المشاهة",
    "أشوى",
    "اشتوى",
    "انشوى",
    "الشوى",
    "الشواة",
    "الشوية",
    "المشوى",
    "شاءه",
    "أشاءه",
    "شيأه",
    "تشيأ",
    "الشيء",
    "المشيئة",
    "المشيأ",
    "أشاب",
    "شيبه",
    "الأشيب",
    "المشيب",
    "الشيت",
    "الشائح",
    "الشيح",
    "تشيخ",
    "الشياخ",
    "الشياخة",
    "الشيخ",
    "أشاد",
    "شيده",
    "الشيد",
    "الشيرج",
    "شيز",
    "الشيز",
    "الشيزى",
    "أشاشت",
    "الشيشاء",
    "أشاصت",
    "شايص",
    "شيصت",
    "الشيص",
    "الشيصاء",
    "أشاط",
    "اشتاط",
    "تشيط",
    "الشياط",
    "تشيظم",
    "الشيظم",
    "أشاع",
    "شايعه",
    "اشتاعا",
    "تشايع",
    "الشائع",
    "الشائعة",
    "الشاع",
    "الشاعة",
    "الشياع",
    "الشيع",
    "الشيعة",
    "الشيعي",
    "المشاع",
    "المشياع",
    "المشيعة",
    "المشيع",
    "الشيك",
    "شاله",
    "الشيالة",
    "المشيال",
    "تشيم",
    "الشيم",
    "الشيمة",
    "شانه",
    "المشاين",
    "الشيه",
    "الصاد",
    "صئب",
    "أصأب",
    "الصؤابة",
    "صأصأ",
    "تصأصأ",
    "صئك",
    "صاءكه",
    "الصأكة",
    "صؤل",
    "تصأى",
    "الصآة",
    "صبأ",
    "أصبأ",
    "الصابئون",
    "انصب",
    "اصطب",
    "تصاب",
    "الصبابة",
    "الصبب",
    "الصبة",
    "الصبيب",
    "اصطبح",
    "تصابح",
    "استصبح",
    "الصابح",
    "الصباح",
    "الصباحي",
    "الصبح",
    "الصبحان",
    "الصبحة",
    "الصبوح",
    "الصبيحة",
    "المصباح",
    "المصبح",
    "صبر",
    "أصبر",
    "صابره",
    "اصطبر",
    "استصبر",
    "الصابورة",
    "الصبارة",
    "الصبر",
    "الصبرة",
    "الصبور",
    "الصبير",
    "المصبور",
    "صبصب",
    "تصبصب",
    "الصبصاب",
    "الإصبع والأصبع",
    "الأصبوع",
    "أصبغت",
    "صبغت",
    "اصطبغ",
    "تصبغ",
    "الصباغ",
    "الصبغ",
    "المصبغة",
    "الصبغي",
    "الصبان",
    "الصبانة",
    "المصبنة",
    "الصبا",
    "الصبي",
    "الصبية",
    "أصبت",
    "صابى",
    "تصابى",
    "تصبى",
    "صحبه",
    "أصحب",
    "صاحبه",
    "اصطحب",
    "تصاحبا",
    "استصحب",
    "الصاحبة",
    "الصحابي",
    "المصحاب",
    "المصحب",
    "صح",
    "صححه",
    "تصحح",
    "استصح",
    "الصحاح",
    "الصحة",
    "الصحيح",
    "صحر",
    "صاحر",
    "الصحار",
    "الصحراء",
    "الصحيرة",
    "صحصح",
    "الصحصاح والصحصح",
    "الصحصح والصحصوح",
    "أصحف",
    "تصحفت",
    "الصحاف",
    "الصحفة",
    "الصحيفة",
    "المصحف",
    "صحل",
    "صحنه",
    "الصحنان",
    "الصحناء",
    "الصحناة",
    "المصحنة",
    "صحا",
    "أصحى",
    "الصحو",
    "المصحاة",
    "صاخبه",
    "اصطخب",
    "تصاخب",
    "صخ",
    "أصخ",
    "الصخة",
    "الصخيخ",
    "صخد",
    "أصخد",
    "اصطخد",
    "الصاخدة",
    "المصخدة",
    "الصيخود",
    "صخر",
    "أصخر",
    "الصاخر",
    "الصاخرة",
    "الصخرة",
    "أصدأ",
    "الصدح",
    "الصيداح",
    "الصيدح",
    "الصديد",
    "صادره",
    "استصدر",
    "الأصدر",
    "الصادر",
    "الصدار",
    "الصدارة",
    "الصدر",
    "الصدرة",
    "الصديرة",
    "المصدر",
    "صدصد",
    "صدعه",
    "تصدع",
    "انصدع",
    "الصادع",
    "الصدعة",
    "الصديع",
    "صادغه",
    "تصدغ",
    "الأصدغان",
    "الصداغ",
    "المصدغة",
    "أصدفه",
    "صادفه",
    "تصادفا",
    "تصدف",
    "الصداف الشدقي",
    "الصدوف",
    "صادقه",
    "تصادقا",
    "التصديق",
    "الصادق",
    "الصادقة",
    "الصداق",
    "الصديق",
    "الصدق",
    "الصدقة",
    "الماصدق",
    "صادمه",
    "اصطدما",
    "تصادما",
    "صدي",
    "أصدى",
    "صاداه",
    "صرب",
    "اصطرب",
    "الصريب",
    "المصرب",
    "صرج",
    "الصاروج",
    "انصرح",
    "تصرح",
    "التصريح",
    "الصراح",
    "الصرحة",
    "الصريح",
    "صرخ",
    "أصرخه",
    "تصرخ",
    "اصطرخ",
    "تصارخوا",
    "استصرخه",
    "الصارخ",
    "الصارخة",
    "الصريخ",
    "أصرد",
    "الصردان",
    "الصراد",
    "الصريدة",
    "المصراد",
    "أصر",
    "صاره",
    "اصطر",
    "الصار",
    "الصارة",
    "الصارور",
    "الصارورة",
    "الصرار",
    "الصر",
    "الصرر",
    "الصراء",
    "الصريرة",
    "المصار",
    "صرصر",
    "الصرصر",
    "الصراط",
    "صرعه",
    "صارعه",
    "اصطرع",
    "تصارع",
    "الصرعة",
    "الصريع",
    "أصرف",
    "صارف",
    "اصطرف",
    "انصرف",
    "استصرف",
    "الصارف",
    "الصرف",
    "الصرفان",
    "الصريف",
    "الصيرف",
    "الصيرفي",
    "المصرف",
    "صرمه",
    "أصرم",
    "صارمه",
    "انصرم",
    "تصارما",
    "تصرم",
    "الصرام",
    "الصرم",
    "الصرمة",
    "الصريم",
    "الصريمة",
    "صرى",
    "صريت",
    "أصرت",
    "الصرى",
    "المصراة",
    "المصطب",
    "المصطبة",
    "صعب",
    "صعبه",
    "استصعب",
    "الصعبة",
    "المصعب",
    "أصعد",
    "الصعد",
    "الصعدة",
    "الصعود",
    "الصعوداء",
    "الصعيد",
    "المصعاد",
    "أصعر",
    "صاعر",
    "تصاعر",
    "اصعرت",
    "الصعر",
    "صعصع",
    "تصعصع",
    "الصعصع",
    "صعفق",
    "الصعفق",
    "الصعفوق",
    "صعقتهم",
    "أصعقه",
    "الصعق",
    "صعل",
    "اصعال",
    "الصعل",
    "الصعلة",
    "صعلك",
    "تصعلكت",
    "المصعلك",
    "صغره",
    "أصغر",
    "الأصغر",
    "الصغيرة",
    "أصغى",
    "الصغو",
    "صفحت",
    "أصفح",
    "صافحه",
    "تصافحا",
    "استصفح",
    "الصفاح",
    "الصفوح",
    "الصفيحة",
    "المصفح",
    "المصفحة",
    "صفده",
    "أصفده",
    "الصفاد",
    "الأصفر",
    "الأصفران",
    "الصفراء",
    "الصفرية",
    "الصفصف",
    "صفعه",
    "أصفه",
    "صاف",
    "صففه",
    "تصافوا",
    "الصف",
    "الصفف",
    "الصفة",
    "الصفيف",
    "أصفق",
    "اصطفق",
    "انصفق",
    "تصافق",
    "تصفق",
    "الصفاق",
    "الصفق",
    "الصفقة",
    "صافن",
    "تصافن",
    "الصافن",
    "صافاه",
    "تصافيا",
    "استصفاه",
    "الصفاة",
    "الصفواء",
    "الصفوان",
    "الصفوة",
    "الصفي",
    "الصوافي",
    "أصقب",
    "صاقبه",
    "تصاقبت",
    "الصقب",
    "صقرت",
    "تصقر",
    "الصاقر",
    "الصاقرة",
    "الصاقور",
    "الصاقورة",
    "الصقر",
    "الصقرة",
    "الصقار",
    "صقعت",
    "أصقع",
    "الصقاع",
    "الصقع",
    "الصقعة",
    "المصقع",
    "صقله",
    "الصقلة",
    "الصقيل",
    "الصيقل",
    "المصقلة",
    "الصقالبة",
    "صكه",
    "اصطك",
    "الأصك",
    "الصك",
    "المصك",
    "صكم",
    "صلبت",
    "اصطلب",
    "الصالب",
    "الصليب",
    "أصلت",
    "انصلت",
    "الإصليت",
    "الصلت",
    "الصلتان",
    "تصالج",
    "الصلجة",
    "الصليجة",
    "الصولج",
    "الصولجة",
    "الصولجانة",
    "صالحه",
    "اصطلح",
    "تصالحوا",
    "استصلح",
    "الاصطلاح",
    "الصالح",
    "الصلاح",
    "الصلح",
    "الصليح",
    "المصلحة",
    "صلخ",
    "تصالخ",
    "أصلد",
    "الأصلد",
    "الصلود",
    "المصلاد",
    "المصلادة",
    "الصلدم",
    "تصلصل",
    "الصلاصل",
    "الصلصل",
    "صلطحه",
    "الصلطح",
    "المصلطح",
    "صلعت",
    "تصلعت",
    "الصلاع",
    "الصلعة",
    "الصلاعة",
    "الصليع",
    "صلفه",
    "أصلف",
    "الأصلف",
    "الصلف",
    "الصلفاء",
    "الصليف",
    "الصليفان",
    "صلق",
    "أصلق",
    "تصلق",
    "اصطلق",
    "الصلاقة",
    "الصلق",
    "الصلاق",
    "الصليق",
    "المصلق",
    "صلل",
    "الصال",
    "الصل",
    "الصلال",
    "الصلة",
    "صلمه",
    "اصطلمه",
    "الأصلم",
    "الصلماء",
    "الصلمة",
    "الصيلم",
    "المصلم",
    "صليت",
    "الصلا",
    "الصلاة",
    "المصلى",
    "صلي",
    "أصلاه",
    "صلاه",
    "اصطلى",
    "الصلى",
    "الصلاء",
    "الصلاية",
    "الصلي",
    "المصلاة",
    "اصمأل",
    "الصامت",
    "الصمات",
    "الصمتة",
    "الصموت",
    "المصمت",
    "صمح",
    "الصماح",
    "صمخه",
    "الأصموخ",
    "الصماخ",
    "الصمخة",
    "الصماخة",
    "صمد",
    "أصمد",
    "صامده",
    "صمده",
    "تصامدا",
    "الصماد",
    "الصمادة",
    "الصمد",
    "الصمدة",
    "المصماد",
    "المصمد",
    "صمر",
    "أصمر",
    "الصامورة",
    "الصمر",
    "الصمير",
    "الصمصام",
    "الصمصامة",
    "الصمصم",
    "الصمصمة",
    "انصمع",
    "الأصمع",
    "الصمع",
    "الصمعاء",
    "صومع",
    "الصومع",
    "الصومعة",
    "أصمغت",
    "صمغه",
    "استصمغ",
    "التصمغ",
    "الصامغان",
    "الصمغان",
    "الصمغة",
    "الصماغة",
    "أصمله",
    "الصمولة",
    "الصملاخ",
    "الصملوخ",
    "أصم",
    "تصام",
    "الأصم",
    "الصماء",
    "الصمامة",
    "الصمة",
    "الصمان",
    "الصمانة",
    "أصمى",
    "الصناب",
    "الصنابي",
    "صنبرت",
    "الصنبر",
    "الصنبور",
    "الصنجة",
    "الصناج",
    "الصندد",
    "الصنديد",
    "تصندل",
    "الصندلاني",
    "الصنارة",
    "أصنع",
    "صانعه",
    "اصطنع",
    "استصنع",
    "الصانع",
    "الصناعة",
    "الصنعة",
    "الصنيع",
    "الصنيعة",
    "المصانعة",
    "المصنعة",
    "المصنوع",
    "تصنف",
    "الصنف",
    "أصنق",
    "صنمت",
    "الصنم",
    "الصنمة",
    "أصن",
    "الصنان",
    "أصنى",
    "تصنى",
    "الصناء",
    "الصنو",
    "صهب",
    "صهبه",
    "اصهب",
    "الأصهب",
    "الصهباء",
    "الصهبة",
    "صهده",
    "الصيهد",
    "أصهر",
    "صاهر",
    "اصطهر",
    "انصهر",
    "تصاهرا",
    "الانصهار",
    "الصهارة",
    "الصهور",
    "الصهير",
    "صهرج",
    "صهصه",
    "صهل",
    "تصاهلت",
    "الصاهل",
    "صهي",
    "أصهى",
    "صاهى",
    "الصهوة",
    "أصاب",
    "تصوب",
    "استصاب",
    "استصوبه",
    "الصاب",
    "الصواب",
    "الصوب",
    "الصوبة",
    "الصوابة",
    "الصويب",
    "الصيب",
    "الصبوب",
    "المصاب",
    "المصيبة",
    "الصوبج",
    "أصات",
    "صوت",
    "انصات",
    "الصات",
    "الصوت",
    "الصيتة",
    "صاحه",
    "انصاح",
    "تصوح",
    "الصاحة",
    "الصواح",
    "الصوحان",
    "الصواحة",
    "أصاخ",
    "صار",
    "أصاره",
    "صوره",
    "التصور",
    "التصورية",
    "التصوير",
    "الصوار",
    "الصور",
    "الصورة",
    "الصير",
    "صاعت",
    "انصاع",
    "تصوع",
    "الصاع",
    "الصاعة",
    "الصواع",
    "صاغه",
    "انصاغ",
    "الصائغ",
    "الصواغ",
    "الصيغة",
    "الصياغ",
    "الصيغ",
    "المصاغ",
    "المصوغ",
    "تصوف",
    "الصوف",
    "الصوفاني",
    "الصوفة",
    "الصوفي",
    "الصوفية",
    "الصواف",
    "الصيف",
    "صاك",
    "تصوك",
    "صاوله",
    "تصاولا",
    "الصؤول",
    "المصولة",
    "الصولج والصولجة",
    "الصولجان والصولجانة",
    "صام",
    "صومه",
    "اصطام",
    "الصائم",
    "الصوام",
    "الصوم",
    "الصيام",
    "المصام",
    "اصطانه",
    "تصاون",
    "تصون",
    "الصينة",
    "صوي",
    "أصوى",
    "الصوة",
    "صيأ",
    "الصاء",
    "الصيأة",
    "الصببوب",
    "الصيابة",
    "صاح",
    "صيح",
    "صايح",
    "الصائحة",
    "الصيحة",
    "صاد",
    "أصاد",
    "اصطاده",
    "تصيده",
    "اصيد",
    "الصيد",
    "الصيود",
    "المصاد",
    "المصطاد",
    "المصيد والمصيدة",
    "صيدل",
    "الصيدل",
    "الصيدلاني",
    "الصيدان",
    "الصيدانة",
    "الصيدن",
    "صيره",
    "الصائرة",
    "الصيارة",
    "الصيرة",
    "الصيور",
    "المصير",
    "صاصت",
    "أصاصت",
    "صيصت",
    "الصيص",
    "الصيصاء",
    "الصيصة",
    "الصيصية",
    "صاع",
    "أصاعه",
    "صيغ",
    "أصاف",
    "صايفه",
    "اصطاف",
    "تصيف",
    "الصائف",
    "الصائفة",
    "الصاف",
    "المصياف",
    "صاق",
    "الصيق",
    "صيل",
    "الضاد",
    "الضئب",
    "الضوبان",
    "ضأزه",
    "الضئزى",
    "ضأضأ",
    "الضأضاء",
    "الضئضئ",
    "ضئط",
    "ضاءل",
    "الضؤلان",
    "أضأن",
    "الضائن",
    "الضأن",
    "الضئنى",
    "ضأى",
    "ضبأ",
    "أضبأ",
    "الضابئ",
    "الضابئة",
    "المضبأ",
    "ضب",
    "أضب",
    "ضبب",
    "تضبب",
    "الضب",
    "الضبب",
    "الضبة",
    "الضبوب",
    "الضبيب",
    "الضبيبة",
    "ضبث",
    "الضباث",
    "ضبج",
    "ضابحه",
    "انضبح",
    "الضباح",
    "الضبح",
    "الضبحاء",
    "المضابح",
    "المضبوحة",
    "أضبر",
    "الإضبارة",
    "الضبارة",
    "الضبارم",
    "الضبارمة",
    "الضبار",
    "الضبر",
    "الضبرة",
    "الضبير",
    "المضبر",
    "المضبور",
    "ضبسه",
    "ضبس",
    "الضبس",
    "ضبطه",
    "انضبط",
    "تضبط",
    "الضابط",
    "الضابطة",
    "المضبطة",
    "ضبعت",
    "أضبعت",
    "ضابعه",
    "اضطبع",
    "استضبعت",
    "الضبعان",
    "المضبعة",
    "ضبنه",
    "أضبن",
    "اضطبن",
    "الضبانة",
    "الضبن",
    "الضبنة",
    "الضبينة",
    "ضبا",
    "أضبى",
    "الضابي",
    "أضج",
    "ضاجه",
    "الضجاج",
    "الضجوج",
    "أضجره",
    "تضجر",
    "الضجور",
    "أضجع",
    "ضاجعه",
    "اضطجع",
    "انضجع",
    "تضاجع",
    "تضجع",
    "الأضجع",
    "الضاجع",
    "الضجعة",
    "الضجعى",
    "الضجوع",
    "الضجيع",
    "المضجوع",
    "ضجم",
    "تضاجم",
    "الضجم",
    "الضح",
    "تضحضح",
    "الضحضاح",
    "الضحضح",
    "أضحكت",
    "ضاحكه",
    "ضحكه",
    "تضاحك",
    "تضحك",
    "استضحك",
    "الأضحوكة",
    "الضاحكة",
    "الضحاك",
    "الضحوك",
    "الضحل",
    "ضاحت",
    "استضحى",
    "الأضحى",
    "الأضحاة",
    "الإضحيان",
    "الأضحية",
    "الضحى",
    "الضحاء",
    "الضحو",
    "الضحوة",
    "المضحاة",
    "انضخ",
    "ضخمه",
    "الأضخومة",
    "ضده",
    "أضد",
    "ضاده",
    "تضاد",
    "الضد",
    "الضديد",
    "المتضادان",
    "ضدي",
    "أضدى",
    "ضاداه",
    "الضوادي",
    "أضرب",
    "ضاربه",
    "اضطرب",
    "تضاربا",
    "تضرب",
    "استضرب",
    "الضرب",
    "الضربة",
    "الضروب",
    "الضريب",
    "المضاربة",
    "المضراب",
    "المضربة",
    "ضرجه",
    "انضرج",
    "تضرج",
    "الإضريج",
    "الضريج",
    "المضرج",
    "المضروجة",
    "ضرحت",
    "أضرحه",
    "ضارحه",
    "اضطرحه",
    "انضرح",
    "ضراح",
    "الضرح",
    "الضروح",
    "الضريحة",
    "المضرح",
    "المضرحي",
    "أضرت",
    "ضاره",
    "اضطره",
    "تضارا",
    "تضرر",
    "استضر",
    "التضرة",
    "الضرارة",
    "الضر",
    "الضرر",
    "الضرة",
    "الضرورة",
    "الضروري",
    "الضرير",
    "المضرار",
    "المضرة",
    "ضرست",
    "أضرسه",
    "ضرسه",
    "تضارس",
    "تضرس",
    "التضريس",
    "الضروس",
    "الضريس",
    "المضروسة",
    "أضرطه",
    "الضراط",
    "الضروط",
    "أضرعت",
    "ضارعه",
    "تضارعا",
    "الضارع",
    "الضرع",
    "الضرعاء",
    "المضارع",
    "ضرغمت",
    "تضرغمت",
    "الضرغام",
    "الضرغامة",
    "الضرغم",
    "ضرك",
    "الضراك",
    "الضريك",
    "ضرمت",
    "أضرم",
    "اضطرمت",
    "تضرمت",
    "استضرم",
    "الضرام",
    "الضرم",
    "الضرمة",
    "الضريم",
    "ضرى",
    "ضري",
    "أضراه",
    "ضراه",
    "استضرى",
    "الضاري",
    "الضرو",
    "الضري",
    "ضزنه",
    "تضازنا",
    "الضيزن",
    "تضعضع",
    "الضعضاع",
    "الضعضع",
    "أضعف",
    "ضاعفه",
    "تضاعف",
    "استضعفه",
    "التضاعيف",
    "الضعف",
    "الضعفان",
    "الضعفة",
    "الضعوف",
    "الضعيف",
    "المضاعف",
    "المضعفة",
    "الضغبوس",
    "أضغث",
    "ضغثه",
    "الضغاثة",
    "الضغث",
    "ضغضغ",
    "ضغطه",
    "ضاغطه",
    "تضاغطا",
    "الضاغطة",
    "الضغطة",
    "الضغيط",
    "المضغط",
    "ضغمه",
    "أضغم",
    "الضغامة",
    "الضيغم",
    "أضغن",
    "ضاغنه",
    "اضطغن",
    "تضاغنا",
    "الضغن",
    "الضغون",
    "الضغينة",
    "ضغا",
    "أضغاه",
    "ضغاء",
    "تضاغى",
    "الضاغية",
    "ضافره",
    "ضفره",
    "انضفر",
    "تضافروا",
    "الضفار",
    "الضفير",
    "ضفز",
    "اضطفز",
    "الضفز",
    "الضفاز",
    "الضفيزة",
    "ضف",
    "تضافوا",
    "الضف",
    "الضفف",
    "الضفوف",
    "الضفيف",
    "الضفيفة",
    "المضفوف",
    "ضفن",
    "ضافنه",
    "تضافنوا",
    "الضفن",
    "الضفا",
    "الضفوة",
    "ضكضك",
    "تضكضك",
    "الضكاضك",
    "الضكضاك",
    "ضكه",
    "أضلعت",
    "اضطلع",
    "تضلع",
    "استضلع",
    "الأضلع",
    "الضالع",
    "الضليع",
    "أضله",
    "ضلله",
    "تضال",
    "تضلل",
    "استضل",
    "الأضلولة",
    "الضال",
    "الضالة",
    "الضلالة",
    "الضلل",
    "الضلة",
    "الضليل",
    "الضلول",
    "المضل",
    "المضلة",
    "ضمخه",
    "تضمخ",
    "الضمخة",
    "أضمد",
    "ضمده",
    "تضمد",
    "الضماد",
    "الضمد",
    "المضمدة",
    "أضمرت",
    "ضمره",
    "اضطمر",
    "انضمر",
    "تضمر",
    "الإضمار",
    "الضامر",
    "الضمار",
    "الضمر",
    "الضمير",
    "الضومران والضيمران",
    "المضمار",
    "ضمز",
    "الضمز",
    "ضمسه",
    "ضمضم",
    "الضماضم",
    "الضمضام",
    "الضمضم",
    "الضميلة",
    "ضامه",
    "اضطم",
    "انضم",
    "الإضمامة",
    "الضمام",
    "الضمامة",
    "الضميم",
    "أضمنه",
    "تضامنوا",
    "التضمين",
    "الضامن",
    "الضامنة",
    "الضمان",
    "الضمانة",
    "الضمن",
    "الضمين",
    "المضمان",
    "المضمون",
    "ضنأت",
    "أضنأت",
    "الضنء",
    "ضنكه",
    "أضنكه",
    "تضنك",
    "الضناك",
    "الضنك",
    "الضنكة",
    "الضنيك",
    "الضنانة",
    "الضنن",
    "الضن",
    "الضنين",
    "المضنة",
    "المضنون والمضنونة",
    "ضنت",
    "ضني",
    "أضنى",
    "ضانى",
    "تضنى",
    "الضنى",
    "الضني",
    "ضاهأه",
    "الضهبأة",
    "ضهب",
    "ضاهيه",
    "الضهباء",
    "ضهده",
    "أضهده",
    "اضطهده",
    "الضهدة",
    "الضاهر",
    "الضهر",
    "ضهل",
    "أضهل",
    "تضهل",
    "استضهل",
    "الضاهلة",
    "الضهل",
    "الضهول",
    "ضهيت",
    "أضهى",
    "ضاهاه",
    "الضهواء",
    "الضهوة",
    "الضهياء",
    "الضهي",
    "أضاء",
    "ضوأ",
    "تضوأ",
    "استضاء",
    "الضوء",
    "ضاج",
    "انضاج",
    "انضوج",
    "تضوج",
    "الضوج",
    "تضور",
    "الضورة",
    "ضوضأ",
    "الضوضى",
    "ضوط",
    "الضويطة",
    "الضوطار",
    "الضوطر",
    "الضوطرى",
    "أبو ضوطرى",
    "ضاع",
    "ضوعه",
    "انضاع",
    "تضوع",
    "الضواع",
    "الضوع",
    "ضان",
    "تضون",
    "الضون",
    "الضيون",
    "ضوي",
    "ضويت",
    "أضوى",
    "انضوى",
    "الضواة",
    "تضبح",
    "ضاز",
    "الضيزى",
    "أضاع",
    "ضيعه",
    "الضيعة",
    "المضياع",
    "المضيعة",
    "أضاف",
    "انضاف",
    "تضايف",
    "تضيف",
    "الإضافة",
    "التضايف",
    "الضيف",
    "الضيفن",
    "المضاف",
    "المضوفة",
    "المضياف",
    "ضاق",
    "أضاق",
    "ضايقه",
    "ضيقه",
    "الضيق",
    "المضيق",
    "أضال",
    "استضامه",
    "الضيم",
    "أطبعه",
    "انطبع",
    "الطبيع",
    "الطبع",
    "المطبوع",
    "أطبق",
    "تطابقا",
    "تطبق",
    "الطباق",
    "الطباقاء",
    "الطبق",
    "الطبقة",
    "المطبقية",
    "التطبل",
    "الطبالة",
    "الطبلية",
    "طابنه",
    "الطابون",
    "الطبن",
    "الطبنة",
    "طباه",
    "طبيت",
    "اطباه",
    "الطبواء",
    "الطبوغرافيا",
    "طثه",
    "الطثرة",
    "طجن",
    "طجنه",
    "الطاجن",
    "المطجن",
    "طحه",
    "أطحه",
    "انطح",
    "طحر",
    "الطحار",
    "الطحور",
    "تطحطح",
    "طحله",
    "الأطحل",
    "الطحل",
    "الطحلاء",
    "الطحلة",
    "طحنه",
    "انطحن",
    "تطحن",
    "الطاحنة",
    "الطحانة",
    "الطحان",
    "الطحن",
    "الطحنة",
    "الطحون",
    "الطحينة",
    "المطحان",
    "المطحن",
    "طحا",
    "طحى",
    "تطحى",
    "الطحا",
    "المطحية",
    "طخ",
    "المطخة",
    "طخا",
    "الطخاء",
    "الطخواء",
    "الطخياء",
    "الطخية",
    "طرأ",
    "طرؤ",
    "طرأه",
    "الطارئ",
    "الطارئة",
    "الطرآني",
    "أطربه",
    "تطرب",
    "استطرب",
    "الطرب",
    "الطروب",
    "الطربيد",
    "الطربوش",
    "طربل",
    "الطربال",
    "الطربيل",
    "الطرثوث",
    "اطرحه",
    "انطرح",
    "تطارحا",
    "تطرح",
    "الطراح",
    "الطرحة",
    "الطروح",
    "الطريح",
    "المطرح",
    "المطرحة",
    "المطروح",
    "الطرخون",
    "الطريخ",
    "أطرده",
    "طارده",
    "اطرد",
    "تطارد",
    "الطرد",
    "الطرادة",
    "الطريد",
    "الطريدة",
    "المطردة",
    "الطر",
    "الطرة",
    "الطرار",
    "الطرور",
    "الطرير",
    "طرزه",
    "تطرز",
    "الطراز",
    "الطرازة",
    "الطرزي",
    "المطرز",
    "طرسه",
    "تطرس",
    "الطرس",
    "تطارش",
    "الأطرش",
    "الأطروش",
    "الطرطر",
    "الطرطور",
    "الطراطير",
    "أطرف",
    "استطرفه",
    "الأطروفة",
    "التطريف",
    "الطارف",
    "الطارفة",
    "الطراف",
    "الطرف",
    "الطرفاء",
    "الطريف",
    "المطرف",
    "المطرفة",
    "المطروف",
    "المطروفة",
    "أطرق",
    "طارق",
    "طرقت",
    "انطرق",
    "تطارق",
    "تطرق",
    "استطرق",
    "الطارقة",
    "الطراق",
    "الطرق",
    "الطريق",
    "الطريقة",
    "المستطرقة",
    "المطراق",
    "المطرق",
    "المطروق",
    "المنطرقات",
    "طرمت",
    "أطرمت",
    "تطرم",
    "الطارمة",
    "الطرامة",
    "الطرم",
    "الطرمة",
    "الطرنشول",
    "طرو",
    "أطراه",
    "طراه",
    "الإطرية",
    "الطري",
    "المطراة",
    "الطازج",
    "الطزاجة",
    "الطست",
    "طسس",
    "الطاسة",
    "الطساسة",
    "الطس",
    "الطساس",
    "الطسان",
    "الطسة",
    "الطشت",
    "طش",
    "أطشت",
    "الطشاش",
    "الطش",
    "الطشيش",
    "أطعمت",
    "طاعمه",
    "اطعمت",
    "تطاعما",
    "تطعم",
    "استطعم",
    "الطعام",
    "الطعامي",
    "الطعم",
    "الطعمة",
    "الطعمية",
    "الطعومة",
    "الطعيم",
    "المطعام",
    "المطعم",
    "المطعمة",
    "طاعنه",
    "اطعنا",
    "تطاعنا",
    "الطاعون",
    "الطعان",
    "الطعين",
    "الطعن",
    "المطعان",
    "المطعن",
    "الطغراء",
    "الطغرى والطغرى",
    "الطغرائي",
    "تطغم",
    "الطغام",
    "الطغامة",
    "الطغم",
    "الطغومة",
    "الطغومية",
    "طغى",
    "أطغاه",
    "تطاغى",
    "الطغوى",
    "الطغيان",
    "الطغية",
    "طفئت",
    "طفأ",
    "انطفأ",
    "المطفئ",
    "المطفئة",
    "أطفحه",
    "طفحه",
    "اطفح",
    "الطفاحة",
    "الطفحان",
    "المطفحة",
    "أطفر",
    "اطفر",
    "الطفرة",
    "طفس",
    "طفطف",
    "الطفطاف",
    "الطفطفة",
    "أطف",
    "طفف",
    "استطف",
    "الطافة",
    "الطفاف",
    "الطفافة",
    "الطفيف",
    "أطفقه",
    "طفلت",
    "طفل",
    "أطفلت",
    "الطفال",
    "الطفل",
    "الطفولية",
    "الطفيل",
    "المطفل",
    "أطفى",
    "الطفاوة",
    "الطفية",
    "أطلب",
    "طالبه",
    "اطلبه",
    "تطلبه",
    "الطالب",
    "الطلابة",
    "الطلب",
    "الطلبة",
    "الطلوب",
    "الطليب",
    "المطلب",
    "أطلح",
    "الطلح",
    "الطليح",
    "طلسه",
    "انطلس",
    "تطلس",
    "الطالسان",
    "الطلس",
    "الطلساء",
    "الطلسة",
    "الطلاسة",
    "الطليس",
    "الطيلس",
    "الطيلسان",
    "الطلسم",
    "طلطل",
    "الطلاطل",
    "الطلاطلة",
    "الطلطل",
    "اطلع",
    "الطالعة",
    "الطلاع",
    "الطلع",
    "أطلف",
    "طلف",
    "الطلف",
    "الطليف",
    "طلقه",
    "انطلق",
    "استطلق",
    "الطالق",
    "الطالقة",
    "الطلاق",
    "الطلقة",
    "الطليق",
    "المطلاق",
    "المطلق",
    "تطال",
    "تطللت",
    "استطل",
    "الطلالة",
    "الطلل",
    "الطلة",
    "الطليل",
    "المطل",
    "المطلول",
    "طلمه",
    "الطلم",
    "الطلمة",
    "المطلمة",
    "اطلمس",
    "الطلمساء",
    "طليت",
    "أطلت",
    "الطلا",
    "الطلاة",
    "الطلاوة",
    "الطلو",
    "الطلواء",
    "الطلوان",
    "الطلوة",
    "اطلى",
    "تطلى",
    "الطلى",
    "الطلياء",
    "الطلية",
    "الطليا",
    "المطلى",
    "المطلاء",
    "طمثت",
    "أطمحه",
    "الطامح",
    "الطمحة",
    "الطماح",
    "الطماحة",
    "طمرت",
    "أطمره",
    "طمره",
    "اطمر",
    "الطامر",
    "الطامور",
    "طمار",
    "طمرة",
    "الطومار",
    "المطمار",
    "المطمر",
    "المطمورة",
    "طمسه",
    "تطمس",
    "الطامس",
    "الطميس",
    "طمطم",
    "الطمطام",
    "الطمطماني",
    "الطمطمانية",
    "الطمطم",
    "الطمطمة",
    "الطمطمي",
    "أطمعه",
    "طمعه",
    "تطمع",
    "الطموع",
    "المطماع",
    "المطمع",
    "المطمعة",
    "طمل",
    "أطمل",
    "اطمل",
    "انطمل",
    "الطمل",
    "الطملة",
    "الطمول",
    "الطميل",
    "الطميلة",
    "المطملة",
    "طمم",
    "استطم",
    "الطام",
    "الطمة",
    "الطموم",
    "الطميم",
    "طمأنه",
    "اطمأن",
    "الطمأنينة",
    "طنأ",
    "طنئ",
    "أطنأ",
    "الطنء",
    "طنب",
    "أطنب",
    "طانبه",
    "تطانبا",
    "الإطنابة",
    "الطنب",
    "الطنيب",
    "المطناب",
    "المطنب",
    "الطنبور",
    "طنبل",
    "الطنبل",
    "طنثر",
    "تطنثر",
    "تطنج",
    "الطنج",
    "الطنجرة",
    "الطنجير",
    "طنخ",
    "أطنخه",
    "طنخه",
    "طنز",
    "طانزه",
    "تطانزوا",
    "الطناز",
    "المطنزة",
    "الطنطان",
    "أطنف",
    "تطنف",
    "طنفس",
    "الطنفس",
    "الطنفسة",
    "طنفش",
    "الطنفش",
    "أطنه",
    "طنن",
    "الطني",
    "طني",
    "أطنى",
    "اطنى",
    "الطنى",
    "اطهر",
    "تطهر",
    "الطاهر",
    "الطهارة",
    "الطهر",
    "الطهور",
    "الطهورية",
    "المطهرة",
    "طهش",
    "طهم",
    "تطهم",
    "الطهم",
    "الطهمة",
    "المطهم",
    "تطهمل",
    "الطهمل",
    "الطهملي",
    "طها",
    "أطهى",
    "الطاهي",
    "الطهاوة",
    "الطهاية",
    "الطهيان",
    "الطواب",
    "طوبة",
    "طوبى",
    "أطاحه",
    "طاوحه",
    "طوحه",
    "تطاوح",
    "تطوح",
    "المطاح",
    "المطاحة",
    "المطواح",
    "طاد",
    "انطاد",
    "تطود",
    "الطاد",
    "الطود",
    "ابن الطود",
    "المطاد",
    "المطادة",
    "طار",
    "الطوار",
    "الطور",
    "الطوراني",
    "الطورة",
    "تطوس",
    "الطاس",
    "الطاءوس",
    "الطواس",
    "الطوس",
    "الطواشي",
    "أطاع",
    "طاوعه",
    "تطاوع",
    "استطاع",
    "الطاعة",
    "الطواعية",
    "الطوع",
    "الطيع",
    "المطاع",
    "المطواع",
    "المطوع",
    "طاف",
    "أطاف",
    "اطاف",
    "تطوف",
    "استطاف",
    "الطائف",
    "الطائفة",
    "الطائفي",
    "الطواف",
    "المطوف",
    "طاقه",
    "أطاقه",
    "تطوق",
    "الطائق",
    "الطاق",
    "الطاقة",
    "الطوقة",
    "المطوق",
    "المطوقة",
    "طال",
    "أطال",
    "أطوله",
    "طاول",
    "تطول",
    "استطال",
    "الطائل",
    "الطائلة",
    "الطوال",
    "الطوالة",
    "الطولى",
    "الطويل",
    "الطويلة",
    "الطيل والطيل والطيلة",
    "أطوى",
    "طواه",
    "اطوى",
    "تطوى",
    "الطاية",
    "المطوى",
    "طاب",
    "أطاب",
    "طايبه",
    "تطايبا",
    "تطيب",
    "الأطيب",
    "الأطيبان",
    "الطاب",
    "الطابة",
    "الطيب",
    "الطيبة",
    "المطايب",
    "المطيبة",
    "المطيبون",
    "طيحه",
    "تطايح",
    "الطيح",
    "الطيحة",
    "طاخ",
    "طيخ",
    "تطيخ",
    "الطائخ",
    "الطيخة",
    "أطار",
    "طايره",
    "طيره",
    "انطار",
    "تطاير",
    "استطار",
    "الطائر",
    "الطيرة",
    "الطيرة والطيرة",
    "الطيرية",
    "الطيور",
    "المطارة",
    "الطيس",
    "أطاشه",
    "الطائش",
    "الطياش",
    "أطافه",
    "تطيف",
    "الطياف",
    "طان",
    "أطانه",
    "طينه",
    "تطين",
    "الطيانة",
    "الطينة",
    "الطيان",
    "الظاء",
    "ظأب",
    "ظاءب",
    "تظاءب",
    "الظأب",
    "ظأرت",
    "أظأرها",
    "ظاءر",
    "اظأرت",
    "الظأر",
    "الظئر",
    "الظئرة",
    "ظأفه",
    "الظبأة",
    "ظبظب",
    "الظبظاب",
    "الظبظبة",
    "الظبة",
    "أظبت",
    "المظباة",
    "ظرب",
    "الظرب",
    "أظر",
    "الظر",
    "الظرر",
    "الظري",
    "أظرف",
    "ظارفه",
    "تظارف",
    "استظرفه",
    "الظرف",
    "الظرفية",
    "المظروف",
    "أظعنه",
    "الظعان",
    "الظعنة",
    "الظعينة",
    "أظفر",
    "تظافروا",
    "أظفار",
    "الأظفور",
    "الظفرة",
    "المظفار",
    "المظفر",
    "الظالع",
    "الظلاع",
    "الظلع",
    "ظلفه",
    "ظلفت",
    "أظلف",
    "الظلف",
    "الظلفات",
    "الظليف",
    "الظليفة",
    "ظل",
    "أظل",
    "تظلل",
    "استظل",
    "الأظل",
    "الظلالة",
    "الظلل",
    "الظليل",
    "الظليلة",
    "ظالمه",
    "تظالم",
    "انظلم",
    "الظلامة",
    "الظلم",
    "الظلماء",
    "الظلمة",
    "الظليم",
    "الظليمة",
    "المظلام",
    "المظلم",
    "المظلمة",
    "أظمأه",
    "الظمء",
    "الظمأى",
    "المظماء",
    "ظميت",
    "الأظمى",
    "الظنب",
    "الظنبوب",
    "أظن",
    "اظنه",
    "تظنن",
    "الظنانة",
    "الظن",
    "الظنة",
    "الظنين",
    "مظنة",
    "ظاهر",
    "تظاهروا",
    "الظاهر",
    "الظاهرة",
    "الظاهرية",
    "الظهار",
    "الظهر",
    "الظهرة",
    "الظهري",
    "المظهر",
    "العين",
    "اعتبأ",
    "العباء",
    "المعبأ",
    "تعبب",
    "العباب",
    "العب",
    "العبية",
    "عبث",
    "العبيثة",
    "العبيثران والعبوثران",
    "عبد الله",
    "عبده",
    "اعتبده",
    "استعبده",
    "العبابيد",
    "العباد",
    "العبادة",
    "عباد الشمس",
    "العبد",
    "العبدة",
    "العبدية",
    "العبودة",
    "العبودية",
    "المتعبد",
    "العبادلة",
    "العبدل",
    "العبدلي",
    "اعتبر",
    "استعبر",
    "الاعتبار",
    "العابرة",
    "العبارة",
    "العبر",
    "العبرة",
    "المعابير",
    "المعبر",
    "المعبرة",
    "تعبس",
    "العباس",
    "العبس",
    "أعبطه",
    "اعتبط",
    "العابط",
    "العبطة",
    "العبيط",
    "العباطة",
    "تعبق",
    "العباقية",
    "العبق",
    "العبقة",
    "العبقر",
    "العبقرة",
    "العبقرية",
    "أعبل",
    "الأعبل",
    "العابل",
    "العبال",
    "العبالة",
    "العبل",
    "العبلاء",
    "عبول",
    "المعبلة",
    "العباهر",
    "العبهر",
    "العبهرة",
    "عبا",
    "عبى",
    "العابية",
    "العبابة",
    "أعتبه",
    "عاتبه",
    "تعاتبوا",
    "تعتب",
    "اعتتب",
    "استعتب",
    "الأعتوبة",
    "العتب",
    "العتبى",
    "العتوب",
    "عت",
    "عاته",
    "تعتت",
    "أعتد",
    "تعتد",
    "العتاد",
    "العتد",
    "العتود",
    "العتيد",
    "العتيدة",
    "عتر",
    "العتار",
    "العتر",
    "العترة",
    "العتيرة",
    "المعتر",
    "عترس",
    "العتريس",
    "العاتق",
    "العتق",
    "العتيق",
    "العاتك",
    "العاتكة",
    "العتيك",
    "عتله",
    "انعتل",
    "تعتل",
    "العتال",
    "العتل",
    "العتيل",
    "المعتل",
    "أعتم",
    "استعتمه",
    "العاتم",
    "العتم",
    "تعته",
    "العتاه الشللي",
    "العتاهة",
    "العتاهية",
    "المعته",
    "تعتى",
    "العاتي",
    "عثت",
    "عاث",
    "اعتثه",
    "العثاء",
    "العثيثة",
    "أعثر",
    "عثره",
    "العاثر",
    "العاثور",
    "العثار",
    "العثر",
    "العثري",
    "العثور",
    "العثير",
    "العثيرة",
    "عثلت",
    "عثل",
    "العثول",
    "العثولية",
    "عثم",
    "أعثم",
    "اعتثم",
    "العاثم",
    "العثمان",
    "العيثم",
    "العيثوم",
    "عثنت",
    "عثن",
    "العثان",
    "العثن",
    "المعثن",
    "المعثون",
    "العثنون",
    "الأعثى",
    "العثوة",
    "أعجبه",
    "عجبه",
    "استعجب",
    "التعاجيب",
    "التعجب",
    "العجاب",
    "العجب",
    "العجيب",
    "أعجت",
    "عجج",
    "تعجج",
    "العجاج",
    "العجاجة",
    "العجة",
    "المعجاج",
    "عاجر",
    "اعتجر",
    "تعجر",
    "الأعجر",
    "العجار",
    "العجراء",
    "العجرة",
    "تعجرف",
    "العجرفة",
    "عجارف",
    "عجاريف",
    "العجرفية",
    "عجزت",
    "أعجز",
    "عاجز",
    "العجازة",
    "العجز",
    "العجوز",
    "العجوزة",
    "المعجاز",
    "العجعاج",
    "العجعجة",
    "عجف",
    "أعجف",
    "الأعجف",
    "العجفاء",
    "أعجلت",
    "العجل",
    "المعجال",
    "المعجل",
    "أعجم",
    "تعاجم",
    "استعجم",
    "الأعجم",
    "الأعجمي",
    "العجام",
    "العجامة",
    "العجم",
    "العجماء",
    "أعجن",
    "اعتجن",
    "عاجنه",
    "العجان",
    "العجين",
    "المعجن",
    "المعجنة",
    "عجت",
    "عجي",
    "عاجى",
    "عجى",
    "العجاوة",
    "العجوة",
    "العجي",
    "عاده",
    "اعتد",
    "تعاد",
    "استعد",
    "التعداد",
    "العداد",
    "العد",
    "العدد",
    "العدان",
    "العدة",
    "العديدة",
    "المعداد",
    "المعدودات",
    "العدوس",
    "العديسة",
    "عادل",
    "اعتدل",
    "تعادلا",
    "الاعتدال",
    "العدالة",
    "العدل",
    "العديل",
    "العديلتان",
    "المعدل",
    "العدم",
    "العديم",
    "المعدوم",
    "العدانة",
    "المعدن",
    "أعداه",
    "عاداه",
    "اعتدى",
    "تعادوا",
    "استعداه",
    "العدى",
    "العداوة",
    "العدوى",
    "العدواء",
    "العدوان",
    "العدوة",
    "العدو",
    "العدي",
    "المتعدي",
    "المعدى",
    "المعدية",
    "العدولية",
    "عذبه",
    "اعتذب",
    "استعذب",
    "اعذوذب",
    "العذاب",
    "العذبة",
    "أعذر",
    "استعذر",
    "العاذر",
    "العاذور",
    "العذر",
    "العذراء",
    "العذرة",
    "العذير",
    "العذيرة",
    "المعذرة",
    "عذف",
    "العذوف",
    "العذق",
    "العذقة",
    "عذله",
    "اعتذل",
    "تعاذلوا",
    "تعذل",
    "العاذل",
    "العذلة",
    "استعدى",
    "العذاة",
    "العذي",
    "العذية",
    "تعرب",
    "استعرب",
    "الأعراب",
    "الإعراب",
    "التعريب",
    "العاربة",
    "العراب",
    "العرب",
    "العرباء",
    "العرباني",
    "العربين",
    "العروب",
    "العروبة",
    "العروبية",
    "العريب",
    "المتعربة",
    "المستعربة",
    "عربنه",
    "انعرج",
    "تعارج",
    "الأعرج",
    "التعاريج",
    "العريج",
    "المعراج",
    "عرجن",
    "العرجون",
    "عرد",
    "أعرد",
    "العرادة",
    "العرد",
    "عرت",
    "عار",
    "اعتر",
    "تعار",
    "استعرهم",
    "العرار",
    "العرارة",
    "العر",
    "العرى",
    "العرة",
    "العرير",
    "المعرة",
    "العرزال",
    "أعرس",
    "تعرس",
    "عرائس النيل",
    "العريسة",
    "العرس",
    "العروس",
    "المعرس",
    "تعرش",
    "العرش",
    "العريش",
    "عرصت",
    "أعرص",
    "اعترص",
    "العراص",
    "العرصة",
    "عارض",
    "اعترض",
    "تعارضا",
    "استعرض",
    "التعرض",
    "العارض",
    "العارضة",
    "العراضة",
    "العرض",
    "العروض",
    "المعارضة",
    "المعراض",
    "العرطنيثا",
    "عرعرة",
    "أعرف",
    "اعترف",
    "تعارفوا",
    "استعرف",
    "الأعراف",
    "التعريف",
    "العارفة",
    "العرف",
    "العرفة",
    "عرفات",
    "عرفة",
    "العرفي",
    "المعرفة",
    "المعروف",
    "العرفط",
    "أعرق",
    "عارقه",
    "اعترق",
    "استعرق",
    "العراق",
    "العرق",
    "العرقاة",
    "العرقة",
    "العرقوتان",
    "العريق",
    "المعرق",
    "تعرقب",
    "تعرقل",
    "عرك",
    "عاركه",
    "اعتركوا",
    "تعاركوا",
    "العراك",
    "العركة",
    "العركي",
    "العريك",
    "المعترك",
    "المعرك",
    "عارمه",
    "اعترم",
    "تعرم",
    "العارم",
    "العرام",
    "العرمة",
    "العريم",
    "العرمرم",
    "عرنت",
    "أعرن",
    "عارن",
    "العارن",
    "العران",
    "العرن",
    "العرنين",
    "العرناس",
    "عراه",
    "أعرى",
    "اعتراه",
    "العرو",
    "العرواء",
    "العرية",
    "عارى",
    "اعرورى",
    "العارية",
    "العريان",
    "عزبه",
    "تعزب",
    "الأعزب",
    "العازبة",
    "العزبة",
    "العزيب",
    "المعزابة",
    "عزر",
    "عزره",
    "التعزير",
    "أعزه",
    "عازه",
    "عززه",
    "اعتز",
    "تعزز",
    "استعز",
    "الأعز",
    "العزاز",
    "العزى",
    "العزة",
    "العزيز",
    "المعز",
    "عزفت",
    "تعازفوا",
    "العزاف",
    "العزوف",
    "العزيف",
    "المعزف",
    "المعزفة",
    "أعزق",
    "العزيق",
    "المعزق",
    "عزله",
    "انعزل",
    "تعازل",
    "العزل",
    "العزلاء",
    "العزلة",
    "المعتزلة",
    "المعزال",
    "اعتزم",
    "تعزم",
    "العزام",
    "العزم",
    "العزمة",
    "العزمي",
    "العزيمة",
    "عزاه",
    "اعتزى",
    "تعازى",
    "تعزى",
    "العزية",
    "عسبه",
    "أعسب",
    "استعسب",
    "العسب",
    "العسبة",
    "العسيب",
    "عسيبة",
    "اليعسوب",
    "العوسج",
    "العسجد",
    "أعسر",
    "عاسره",
    "اعتسره",
    "تعاسر",
    "استعسر",
    "العسراء",
    "العسرة",
    "العسرى",
    "المعسر",
    "المعسرة",
    "المعسور",
    "اعتس",
    "العاس",
    "العس",
    "العساس",
    "العسوس",
    "العسيس",
    "المعس",
    "تعسعس",
    "العسعاس",
    "أعسف",
    "عسفه",
    "اعتسف",
    "انعسف",
    "التعاسيف",
    "العسيف",
    "تعسق",
    "العسق",
    "العسيقة",
    "العسقول",
    "عسكر",
    "العسكر",
    "عسلت",
    "استعسل",
    "العاسل",
    "العاسلة",
    "العسال",
    "العسالة",
    "العسل",
    "العسل الأسود",
    "العسلة",
    "العسلي",
    "العسيل",
    "المعسلة",
    "عسلجت",
    "العسلاج",
    "العسلوج",
    "عسم",
    "عسمت",
    "أعسمت",
    "اعتسم",
    "العسم",
    "العسمة",
    "العسمي",
    "المعسم",
    "عست",
    "عسى",
    "أعسى",
    "العسي",
    "أعشب",
    "اعتشبت",
    "تعشبت",
    "اعشوشب",
    "التعاشيب",
    "العاشب",
    "المعشاب",
    "عشرن",
    "أعشرت",
    "عاشره",
    "اعتشر",
    "تعاشروا",
    "العاشور",
    "عاشوراء",
    "العشارة",
    "العشاري",
    "العشر",
    "العشراء",
    "العشرة",
    "العشيرة",
    "المعشار",
    "أعش",
    "اعتش",
    "انعش",
    "المعش",
    "المعشة",
    "العشعش",
    "عشقه",
    "تعشق",
    "العشق",
    "العشقة",
    "عشم",
    "تعشم",
    "الأعشم",
    "العشمة",
    "عشي",
    "أعشى",
    "عشاه",
    "اعتشى",
    "تعاشى",
    "تعشى",
    "استعشاه",
    "العشاء",
    "العشواء",
    "العشوة",
    "العشي",
    "العشية",
    "عصبت",
    "عصبه",
    "اعتصب",
    "انعصب",
    "اعصوصب",
    "العصاب",
    "العصابة",
    "العصبة",
    "العصيب",
    "المعصب",
    "المعصوب",
    "المعصوبة",
    "عصد",
    "أعصد",
    "المعصد",
    "انعصر",
    "تعصر",
    "العصار",
    "العصر",
    "العصير",
    "المعصار",
    "المعصر",
    "المعصرة",
    "العصعصة",
    "العصعوص",
    "عصفت",
    "أعصفت",
    "العاصف",
    "العصافة",
    "العصفة",
    "العصيفة",
    "تعصفر",
    "العصفورة",
    "العصفوري",
    "العصفورية",
    "العصل",
    "العصلاء",
    "المعصال",
    "المعصل",
    "عصلب",
    "العصلب",
    "العصلبي",
    "عصلج",
    "العصلج",
    "أعصم",
    "اعتصم",
    "انعصم",
    "استعصم",
    "العصام",
    "العصامي",
    "العصمة",
    "المعصم",
    "عصاه",
    "أعصى",
    "عاصاه",
    "اعتصى",
    "العصا",
    "العصية",
    "اعتصت",
    "تعصى",
    "استعصى",
    "العصيان",
    "أعضب",
    "عاضبه",
    "انعضب",
    "الأعضب",
    "العضاب",
    "عضبر",
    "العضبارة",
    "عاضده",
    "اعتضد",
    "تعضده",
    "استعضد",
    "الأعضد",
    "العضاد",
    "العضد",
    "المعضاد",
    "المعضد",
    "اليعضيد",
    "العضرس",
    "أعضت",
    "عضض",
    "العضاض",
    "العضوض",
    "أعضلت",
    "عضلت",
    "تعضله",
    "استعضل",
    "العضال",
    "العضم",
    "عضهت",
    "أعضه",
    "العاضه",
    "العاضهة",
    "العضاه",
    "العضيهة",
    "عضا",
    "عضى",
    "العضو",
    "أعطبه",
    "اعتطب",
    "العطبة",
    "المعطب",
    "العطبل",
    "العطبول",
    "عطره",
    "تعطر",
    "استعطر",
    "العاطر",
    "العطر",
    "العطار",
    "المعطار",
    "المعطير",
    "عطرده",
    "عطارد",
    "العطرود",
    "عطسه",
    "العاطس",
    "العاطوس",
    "العطسة",
    "العطوس",
    "المعطس",
    "عطش",
    "أعطش",
    "عطشه",
    "تعطش",
    "العطاش",
    "المعطشة",
    "عط",
    "عطط",
    "اعتط",
    "انعط",
    "تعطط",
    "استعطفه",
    "العاطوف",
    "العطاف",
    "العطف",
    "العطوف",
    "العطيف",
    "العيطل",
    "المعطال",
    "عطنت",
    "أعطن",
    "انعطن",
    "تعطنت",
    "العطان",
    "المعطن",
    "أعطى",
    "عاطاه",
    "عطاه",
    "تعاطى",
    "تعطى",
    "استعطى",
    "العطاء",
    "العطية",
    "المعطاء",
    "عظر",
    "أعظره",
    "العظار",
    "العظارة",
    "العظاري",
    "العظور",
    "عظه",
    "عاظ",
    "عظلت",
    "عاظلت",
    "اعتظلت",
    "تعاظلت",
    "تعظل",
    "تعظلم",
    "العظلم",
    "العظلمة",
    "عظمه",
    "تعاظم",
    "تعظم",
    "استعظم",
    "العظامة",
    "العظامي",
    "العظم",
    "العظيمة",
    "المعظم",
    "المعظوم",
    "عظاه",
    "العظاءة",
    "عظى",
    "العنظوان",
    "عفته",
    "عفت",
    "العفتان",
    "العفيتة",
    "المعفت",
    "عفجه",
    "عفج",
    "تعفج",
    "العفج",
    "المعفاج",
    "المعفج",
    "المعفجة",
    "عفد",
    "اعتفد",
    "عفره",
    "عافره",
    "اعتفر",
    "انعفر",
    "تعفر",
    "الأعفر",
    "العفار",
    "العفارية",
    "العفر",
    "العفراء",
    "العفرة",
    "العفري",
    "العفرين",
    "العفرية",
    "العفير",
    "المعافر",
    "المعفورة",
    "اليعفور",
    "تعفرت",
    "العفاز",
    "العفازة",
    "عفسه",
    "اعتفس",
    "انعفس",
    "تعافسوا",
    "الأعفش",
    "العفاشة",
    "أعفص",
    "العفاص",
    "العفص",
    "عفطت",
    "أعفه",
    "عففه",
    "اعتف",
    "استعف",
    "العفافة",
    "العفة",
    "العفيفة",
    "عفق",
    "أعفق",
    "عافقه",
    "اعتفق",
    "انعفق",
    "تعفق",
    "المعفاق",
    "عفك",
    "عفل",
    "عفلت",
    "العافل",
    "العفل",
    "العفلاء",
    "العفلة",
    "أعفن",
    "عفا",
    "عافاه",
    "عفى",
    "اعتفاه",
    "تعفى",
    "استعفى",
    "العافي",
    "العافية",
    "العفا",
    "العفاءة",
    "العفاوة",
    "العفو",
    "عقبت",
    "أعقب",
    "اعتقب",
    "استعقب",
    "التعاقب",
    "العاقبة",
    "العقاب",
    "العقب",
    "العقبى",
    "العقبة",
    "العقوب",
    "العقوبة",
    "العقيب",
    "المعقاب",
    "المعقب",
    "اليعاقبة",
    "اليعقوبية",
    "تعقبله",
    "العقبول",
    "أعقد",
    "عاقده",
    "اعتقد",
    "انعقد",
    "تعاقد",
    "العقدة",
    "العقاد",
    "عقرت",
    "أعقر",
    "عاقر",
    "انعقر",
    "تعاقر",
    "تعقر",
    "العاقر",
    "العقار",
    "العقر",
    "العقرة",
    "العقور",
    "العقير",
    "العقيرة",
    "العقرب",
    "العقرباء",
    "العقربة",
    "العقربان",
    "المعقرب",
    "عقصت",
    "العقاص",
    "العقصة",
    "العقيص",
    "العقيصاء",
    "العقيصة",
    "المعقاص",
    "المعقص",
    "العقعقة",
    "عقفت",
    "عقفه",
    "انعقف",
    "تعقف",
    "الأعقف",
    "العقاف",
    "العقفاء",
    "العقافة",
    "عقت",
    "أعقت",
    "عاقه",
    "اعتق",
    "انعق",
    "العقاق",
    "العق",
    "العقق",
    "العقان",
    "العقة",
    "العقيقة",
    "عاقله",
    "تعاقل",
    "تعقله",
    "العاقل",
    "العاقول",
    "العقيلى",
    "العقل",
    "العقلة",
    "العقول",
    "العقيلة",
    "المعتقل",
    "عقمت",
    "عاقمه",
    "العقام",
    "العقنقل",
    "عقا",
    "عقى",
    "أعقى",
    "اعتقى",
    "العقاة",
    "العقوة",
    "العقيان",
    "عكب",
    "عكبت",
    "اعتكب",
    "تعكبته",
    "العكاب",
    "العكوب",
    "عكد",
    "أعكد",
    "اعتكده",
    "استعكد",
    "العكدة",
    "المعكود",
    "أعكر",
    "اعتكر",
    "تعاكر",
    "عكرم",
    "العكرمة",
    "تعكز",
    "العكازة",
    "عاكسه",
    "العكاس",
    "العكس",
    "العكيس",
    "عكشت",
    "عكش",
    "تعكش",
    "العكاشة والعكاشة",
    "عكصه",
    "عكص",
    "تعكص",
    "عكظ",
    "عاكظه",
    "عكظه",
    "تعاكظوا",
    "تعكظ",
    "عكاظ",
    "اعتكف",
    "تعكف",
    "الاعتكاف",
    "العكف",
    "العكاك",
    "العكك",
    "العك",
    "العكة",
    "عكل",
    "عكلت",
    "أعكل",
    "اعتكل",
    "العكال",
    "العكل",
    "العوكل",
    "العوكلة",
    "أعكم",
    "عكمت",
    "اعتكم",
    "العكام",
    "العكم",
    "عكنت",
    "تعكن",
    "العكناء",
    "العكنة",
    "عكت",
    "عكى",
    "أعكى",
    "الأعكى",
    "العاكي",
    "العكوة",
    "العكي",
    "علبى",
    "استعلب",
    "اعلنبى",
    "العلباء",
    "المعلوب",
    "علث",
    "عالثه",
    "علثت",
    "اعتلث",
    "العلاثة",
    "العلث",
    "العلثة",
    "العلثى",
    "العليث",
    "العليثة",
    "اعتلج",
    "تعلج",
    "استعلج",
    "العالج",
    "العلج",
    "العلجان",
    "العلجم",
    "علد",
    "العلد",
    "علز",
    "أعلزه",
    "العلوز",
    "علس",
    "العلاس",
    "العلس",
    "العلسي",
    "العلوس",
    "العليس",
    "علصت",
    "العلوص",
    "علضه",
    "علط",
    "اعتلطه",
    "تعلط",
    "اعلوط",
    "الإعليط",
    "العالط",
    "العلاط",
    "العلط",
    "العلطة",
    "عل عل",
    "تعلعل",
    "العلعال",
    "العلعل",
    "العلعول",
    "أعلف",
    "اعتلفت",
    "تعلف",
    "استعلفت",
    "العلف",
    "العلفى",
    "العلوفة",
    "العليف",
    "العلفوف",
    "علقت",
    "أعلق",
    "عالقه",
    "اعتلقه",
    "الأعاليق",
    "التعليقة",
    "العلاق",
    "العلق",
    "العلقى",
    "العلقة",
    "العليقي",
    "العلوق",
    "المعلقة",
    "المعلوق",
    "العلقمة",
    "العالك",
    "العلاك",
    "العلك",
    "العلكة",
    "أعل",
    "تعالت",
    "تعللت",
    "التعلة",
    "التعليل",
    "العلالة",
    "العل",
    "العلل",
    "العلة",
    "العلول",
    "عالمه",
    "اعتلم",
    "تعالم",
    "استعلمه",
    "الأعلومة",
    "العالم",
    "العلامة",
    "العلامي",
    "العلام",
    "العلم",
    "العلمة",
    "العليم",
    "العيلام",
    "العيلم",
    "المعلم",
    "العلماد",
    "أعلنه",
    "عالنه",
    "علنه",
    "اعتلن",
    "استعلن",
    "العلانية",
    "العلنة",
    "عله",
    "العلهاء",
    "العاله",
    "العله",
    "علي",
    "أعلى",
    "تعالى",
    "تعلى",
    "اعلولى",
    "العالية",
    "العلا",
    "العلاء",
    "العلية",
    "العلي",
    "العلو",
    "العلوة",
    "العليا",
    "المعلاة",
    "المعلى",
    "علون",
    "العلوان",
    "عمت",
    "العميت",
    "العميتة",
    "عمج",
    "تعمج",
    "أعمد",
    "اعتمد",
    "انعمد",
    "العماد",
    "العمد",
    "العمدة",
    "أعمر",
    "اعتمر",
    "تعمر",
    "استعمره",
    "عومر",
    "العمار",
    "العمارة",
    "العمر",
    "العمران",
    "العمرة",
    "العمرتان",
    "العمرى",
    "العمري",
    "العمرد",
    "العمرود",
    "العمرس",
    "العمروس",
    "عمرط",
    "العمروط",
    "عمس",
    "أعمس",
    "عامسه",
    "تعامس",
    "العماس",
    "العموس",
    "العميس",
    "عمش",
    "استعمشه",
    "العمش",
    "عمقت",
    "تعمق",
    "العمقي",
    "أعمله",
    "عامله",
    "اعتمل",
    "تعاملا",
    "تعمل",
    "استعمله",
    "العامل",
    "العمالة",
    "العمل",
    "العمال",
    "العمول",
    "المستعمل",
    "المعاملات",
    "المعمل",
    "المعمول",
    "عملس",
    "العملس",
    "عملق",
    "عم",
    "أعم",
    "تعمم",
    "استعم",
    "الأعم",
    "العام",
    "العامة",
    "العامي",
    "العمامة",
    "العم",
    "العمم",
    "العمة",
    "العمومة",
    "العميم",
    "عمن",
    "عمه",
    "العمه",
    "أعماه",
    "عماه",
    "تعامى",
    "الأعميان",
    "العماء",
    "العماءة",
    "المعمى",
    "عنب",
    "العانب",
    "العناب",
    "العنب",
    "العنبا",
    "أعنته",
    "عنته",
    "تعنته",
    "العنت",
    "العنتر",
    "عنج",
    "أعنج",
    "العناج",
    "عنجد",
    "العنجد",
    "العنجه",
    "العنجهي",
    "العنجهية",
    "عند",
    "تعاند",
    "استعند",
    "العنادية",
    "العندية",
    "العنود",
    "عندل",
    "أعنزه",
    "اعتنز",
    "تعنز",
    "استعنز",
    "العنز",
    "العنزي",
    "المعنز",
    "عنست",
    "عنس",
    "أعنس",
    "العناس",
    "العنس",
    "أعنص",
    "العنصل",
    "العنصاة",
    "العنصوة",
    "العنظاب",
    "عنظى",
    "عنعن",
    "أعنفه",
    "عنفه",
    "اعتنف",
    "العنفة",
    "العنفق",
    "العنفقة",
    "عنقه",
    "أعنق",
    "عانقه",
    "اعتنق",
    "تعانقا",
    "تعنق",
    "العانقاء",
    "العنق",
    "العنيق",
    "المعناق",
    "المعنق",
    "المعنقة",
    "العنقد",
    "العنقر",
    "عنقش",
    "تعنقش",
    "العنقاش",
    "عنك",
    "عنكه",
    "اعتنك",
    "تعنك",
    "العنك",
    "العنيك",
    "العنكب",
    "العنكبة",
    "عنكش",
    "تعنكش",
    "العنكش",
    "عنم",
    "العنم",
    "العنمة",
    "عن",
    "أعنت",
    "عانه",
    "اعتن",
    "تعنن",
    "الأعنان",
    "العنان",
    "العن",
    "العنن",
    "العنة",
    "المعن",
    "عنا",
    "عنى",
    "عاناه",
    "عناه",
    "اعتنى",
    "تعنى",
    "التعنية",
    "العاني",
    "العانية",
    "العنا",
    "العناية",
    "العنو",
    "المعنى",
    "المعنوي",
    "أعهده",
    "عاهده",
    "اعتهده",
    "تعاهدا",
    "استعهد",
    "العهاد",
    "العهادة",
    "العهد",
    "العهدة",
    "العهيد",
    "عاهرها",
    "العاهل",
    "العاهلية",
    "عهن",
    "العاهن",
    "العهن",
    "عاثه",
    "عوثه",
    "تعوث",
    "المعاث",
    "انعاج",
    "تعوج",
    "الأعوجيات",
    "العواج",
    "المعاج",
    "عاد",
    "أعاده",
    "عاوده",
    "اعتاده",
    "استعاده",
    "الأعود",
    "العائد",
    "العائدة",
    "العادة",
    "العواد",
    "العود",
    "العيد",
    "المعاد",
    "المعادة",
    "المعيد",
    "أعاذه",
    "عوذه",
    "تعاوذ",
    "تعوذ",
    "استعاذ",
    "العوائذ",
    "العوذ",
    "العوذة",
    "المعاذ",
    "المعاذة",
    "المعوذتان",
    "عورت",
    "أعور",
    "أعاره",
    "عاوره",
    "عوره",
    "اعتوروا",
    "تعاوروا",
    "تعور",
    "اعوارت",
    "اعورت",
    "استعار",
    "الاستعارة",
    "الأعور",
    "العائر",
    "العائرة",
    "العار",
    "العارة",
    "العوار",
    "العور",
    "العوراء",
    "العورة",
    "المعار",
    "المعور",
    "أعوز",
    "المعوز",
    "المعوزة",
    "عوس",
    "العواسة",
    "عاص",
    "عوص",
    "أعوص",
    "اعتاص",
    "العوصاء",
    "عاضه",
    "أعاضه",
    "عاوضه",
    "عوضه",
    "اعتاض",
    "تعاوض",
    "تعوض",
    "استعاضه",
    "العوض",
    "المعوضة",
    "عاطت",
    "اعتاطت",
    "العواف",
    "العوافة",
    "العوف",
    "عوقه",
    "اعتاقه",
    "تعوق",
    "عاق عاق",
    "العواق",
    "العوق",
    "العوقة",
    "العيوق",
    "عال",
    "أعال",
    "عول",
    "العالة",
    "العول",
    "العولة",
    "العيل",
    "المعول",
    "عام",
    "أعوم",
    "عاومت",
    "عوم",
    "العومة",
    "العوام",
    "عانت",
    "أعانه",
    "عاونه",
    "عونت",
    "استعان",
    "الإعانة",
    "العانة",
    "العوان",
    "المتعاونة",
    "المعانة",
    "المعاون",
    "المعوان",
    "المعون",
    "عاه",
    "أعاه",
    "عوه",
    "عاواهم",
    "اعتوى",
    "انعوى",
    "تعاوت",
    "استعواهم",
    "العوي",
    "العوة",
    "المعاوية",
    "عاب",
    "عيبه",
    "تعيبه",
    "العيب",
    "العيبة",
    "العيابة",
    "المعاب",
    "المعابة",
    "المعيب",
    "عيث",
    "تعيثت",
    "العيوث",
    "العياث",
    "عيدنت",
    "العيدانة",
    "العيدة",
    "العيداه",
    "أعير",
    "عيره",
    "تعايروا",
    "العيار",
    "أعيس",
    "تعيست",
    "الأعيس",
    "العيساء",
    "عاش",
    "أعاشه",
    "عايشه",
    "تعايشوا",
    "العائش",
    "العائشة",
    "العيش",
    "العيشة",
    "العياش",
    "المتعيش",
    "المعيشة",
    "العيص",
    "المعياص",
    "المعيص",
    "عيط",
    "تعيطت",
    "العائط",
    "العياط",
    "العيط",
    "عافت",
    "أعاف",
    "اعتاف",
    "تعيف",
    "العيافة",
    "العيفة",
    "العيوف",
    "عيق",
    "العيق",
    "العيقة",
    "عاك",
    "أعيل",
    "تعيل",
    "العيلة",
    "المعيل",
    "أعام",
    "اعتام",
    "العيام",
    "العيمة",
    "أعان",
    "عاينه",
    "اعتان",
    "تعين",
    "العائن",
    "العائنة",
    "العينة",
    "العيون",
    "المعان",
    "المعيان",
    "المعيون",
    "المعين",
    "عيه",
    "أعيه",
    "العائهة",
    "عي",
    "عيي",
    "أعيا",
    "عايا",
    "عيا",
    "تعايا",
    "تعيا",
    "استعيا",
    "الأعيية",
    "العياء",
    "العي",
    "الغين",
    "الغاز",
    "الغازوزة",
    "غبت",
    "التغبة",
    "الغبب",
    "الغبة",
    "الغبيب",
    "الغبيبة",
    "المغبة",
    "غبث",
    "اغبث",
    "الغبيثة",
    "غبج",
    "الغبجة",
    "أغبر",
    "تغبر",
    "الغبار",
    "الغبارية",
    "الغبر",
    "الغبران",
    "الغبرة",
    "غبس",
    "أغبس",
    "الغبس",
    "الغبسة",
    "أغبش",
    "الغبش",
    "الغبشة",
    "غبصت",
    "الغبص",
    "أغبط",
    "اغتبط",
    "الغبط",
    "الغبطة",
    "الغبيط",
    "غبغب",
    "الغبغب",
    "غبقه",
    "اغتبق",
    "تغبق",
    "الغبقان",
    "الغبوق",
    "المغتبق",
    "غبنه",
    "اغتبن",
    "الغبن",
    "الغبينة",
    "المغبن",
    "أغبت",
    "غبى",
    "تغابى",
    "الأغبى",
    "الغبوة",
    "الغبياء",
    "الغبية",
    "غتت",
    "اغتت",
    "غتم",
    "أغتم",
    "اغتتم",
    "المغتوم",
    "أغث",
    "استغث",
    "الغث",
    "الغثة",
    "الغثيث",
    "الغثيثة",
    "غثر",
    "أغثر",
    "اغثار",
    "تمغثر",
    "الأغثر",
    "الغثر",
    "الغثراء",
    "الغثرة",
    "الغيثرة",
    "المغثار",
    "المغثر",
    "المغثور",
    "غثغث",
    "غثم",
    "الغثمة",
    "غثمر",
    "المغثمر",
    "غثا",
    "الغثاء",
    "غد",
    "أغدت",
    "أغد",
    "الغددة",
    "أغدره",
    "غادره",
    "اغتدر",
    "تغدر",
    "استغدر",
    "الغادرة",
    "الغدارة",
    "الغدر",
    "الغدرة",
    "الغديرة",
    "أغدف",
    "غادف",
    "اغتدف",
    "اغدودف",
    "الغداف",
    "الغدافي",
    "الغدف",
    "الغدفة",
    "غدقت",
    "أغدق",
    "اغدودق",
    "غيدق",
    "الغدق",
    "الغيداق",
    "الغيدق",
    "تغدن",
    "اغدودن",
    "الغداني",
    "الغدن",
    "الغدنة",
    "الغدودن",
    "المغدودن",
    "غدا",
    "غاداه",
    "غداه",
    "اغتدى",
    "تغدى",
    "الغادية",
    "الغد",
    "الغداء",
    "الغداة",
    "الغدوة",
    "المغدى",
    "المغداة",
    "غذ",
    "أغذ",
    "الغاذ",
    "الغاذة",
    "الغذيذة",
    "غذرم",
    "تغذرم",
    "الغذارم",
    "المغذرم",
    "غذمه",
    "أغذم",
    "اغتذم",
    "تغذم",
    "الغذامة",
    "الغذم",
    "الغذمة",
    "غذمر",
    "تغذمر",
    "الغذامر",
    "غذى",
    "اغتذى",
    "تغذى",
    "الغاذي",
    "الغاذية",
    "الغذاء",
    "الغذوي",
    "اغترب",
    "استغرب",
    "الغراب",
    "الغربة",
    "الغريب",
    "المغرب",
    "الغربيب",
    "الغربال",
    "غرث",
    "غرثه",
    "أغرد",
    "استغرده",
    "الأغرودة",
    "الغراد",
    "الغرد",
    "غارت",
    "اغتر",
    "تغرر",
    "استغر",
    "الأغر",
    "الغار",
    "الغرارة",
    "الغر",
    "الغرر",
    "الغرة",
    "الغرور",
    "غرزت",
    "أغرز",
    "التغريز",
    "الغارز",
    "المغرز",
    "أغرس",
    "اغترس",
    "الغراس",
    "الغراسة",
    "الغرس",
    "الغريس",
    "الغريسة",
    "المغرس",
    "غرض",
    "أغرض",
    "اغترض",
    "انغرض",
    "الإغريض",
    "الغارض",
    "الغرض",
    "الغرضان",
    "الغريض",
    "الغريضة",
    "المغروض",
    "تغرغر",
    "الغرغر",
    "أغرف",
    "انغرف",
    "تغرفه",
    "الغرافة",
    "الغراف",
    "الغروف",
    "الغريف",
    "الغريفة",
    "أغرق",
    "غارقه",
    "اغترق",
    "الغرق",
    "غرقأت",
    "الغرقيء",
    "الغرقد",
    "غرقل",
    "الغرقل",
    "الغرلة",
    "أغرمه",
    "أغرم",
    "غرمه",
    "الغارم",
    "الغرم",
    "الغريم",
    "المغرم",
    "غرن",
    "غرنق",
    "الغرانق",
    "الغرنوق",
    "أغري",
    "غاراه",
    "غرى",
    "الغرا",
    "الغرو",
    "الغروى",
    "الغروي",
    "الغرواني",
    "الغري",
    "أغزر",
    "غازر",
    "استغزر",
    "الغزر",
    "المغزرة",
    "أغزت",
    "غازه",
    "اغتز",
    "تغازوا",
    "الغز",
    "غزغز",
    "أغزل",
    "اغتزل",
    "الغزل",
    "أغزاه",
    "غزاه",
    "اغتزاه",
    "الغزاة",
    "الغزوة",
    "المغزاة",
    "غسر",
    "تغسر",
    "الغسر",
    "انغس",
    "الغساس",
    "الغس",
    "أغسق",
    "الغاسق",
    "الغساق",
    "اغتسل",
    "انغسل",
    "الغاسول",
    "الغسل",
    "الغسلة",
    "الغسلين",
    "المغسل",
    "غسم",
    "أغسم",
    "الغسم",
    "الغسمة",
    "غسنه",
    "الغسان",
    "الغساني",
    "الغسنة",
    "الغيسان",
    "غسا",
    "غسي",
    "أغسى",
    "الغسو",
    "أغشه",
    "غششه",
    "اغتشه",
    "استغشه",
    "الغشش",
    "المغشوش",
    "تغاشموا",
    "الأغشم",
    "الغشيم",
    "المغشم",
    "غشمر",
    "تغشمر",
    "الغشمشم",
    "أغشى",
    "تغشى",
    "استغشى",
    "الغشاوة",
    "الغشية",
    "أغصه",
    "اغتص",
    "الغصة",
    "غصن",
    "أغصن",
    "الغصن",
    "الغصنة",
    "أغضبه",
    "تغضب",
    "الغضابي",
    "الغضب",
    "الغضوب",
    "اغتضر",
    "تغضر",
    "الغاضر",
    "الغضار",
    "الغضارة",
    "الغضراء",
    "غضت",
    "أغض",
    "غضض",
    "اغتض",
    "انغض",
    "الغضاض",
    "الغضاضة",
    "الغض",
    "الغضة",
    "الغضيض",
    "المغضة",
    "غضف",
    "أغضفت",
    "غضفه",
    "انغضف",
    "تغضف",
    "الغضف",
    "غضفر",
    "الغضافر",
    "الغضفر",
    "الغضنفر",
    "غضنت",
    "أغضنت",
    "غاضن",
    "تغضن",
    "الغضن",
    "غضيت",
    "أغضى",
    "الغاضية",
    "الغضياء",
    "الغطرس",
    "الغطريس",
    "غطرش",
    "تغطرش",
    "غطرف",
    "تغطرف",
    "الغطارف",
    "الغطراف",
    "الغطريف",
    "غطسه",
    "تغاطس",
    "الغاطس",
    "الغطيس",
    "أغطش",
    "تغاطش",
    "تغطشت",
    "اغطاش",
    "انغط",
    "تغاط",
    "الغطاط",
    "غطغطت",
    "تغطغط",
    "غطف",
    "أغطت",
    "غطاه",
    "اغتطى",
    "تغطى",
    "الغطاية",
    "الغطوان",
    "الغطيان",
    "أغفر",
    "اغتفر",
    "تغافر",
    "تغفر",
    "استغفر",
    "اغفار",
    "الغفار",
    "الغفارة",
    "الغفر",
    "الغفرة",
    "الغفير",
    "الغفيرة",
    "المغفار",
    "المغفر",
    "المغفرة",
    "غافصه",
    "الغافصة",
    "اغتفت",
    "تغففت",
    "الغف",
    "الغفان",
    "الغفة",
    "غفق",
    "اغتفق",
    "تغفق",
    "الغفق",
    "أغفل",
    "تغفل",
    "استغفله",
    "المغفل",
    "أغفى",
    "الغفا",
    "غفي",
    "انغفى",
    "الغفى",
    "الغفاء",
    "الغفاءة",
    "غق غق",
    "غق",
    "الغققة",
    "غقغق",
    "غلبه",
    "غالبه",
    "تغالبوا",
    "استغلب",
    "اغلولب",
    "الأغلب",
    "التغليب",
    "الغلبة",
    "اغتلته",
    "تغلته",
    "الغلتة",
    "غلث",
    "أغلث",
    "اغتلث",
    "تغلث",
    "الغلث",
    "الغليث",
    "المغلث",
    "غلج",
    "تغلج",
    "الأغلوج",
    "أغلس",
    "الغلس",
    "غلصمه",
    "الغلصمة",
    "أغلطه",
    "غالطه",
    "غلطه",
    "الأغلوطة",
    "الغلاط",
    "الغلوط",
    "المغلاط",
    "المغلطاني",
    "المغلطة",
    "أغلظ",
    "غالظه",
    "غلظه",
    "استغلظ",
    "الغلظ",
    "الغلظة",
    "المستغلظ",
    "المغلظة",
    "تغلغل",
    "الغلغل",
    "الغلغلة",
    "المغلغلة",
    "تغلف",
    "الأغلف",
    "الغلفة",
    "الإغليق",
    "الغلق",
    "المغلاق",
    "المغلق",
    "أغل",
    "غلله",
    "انغل",
    "تغلل",
    "الغالة",
    "الغلالة",
    "الغل",
    "الغلل",
    "الغلة",
    "الغلول",
    "الغليل",
    "المغل",
    "أغلمه",
    "اغتلم",
    "الغلام",
    "الغلامية",
    "الغلمة",
    "الغلومة",
    "الغلومية",
    "الغيلم",
    "أغلى",
    "غالى",
    "اغتلى",
    "استغلى",
    "الغلواء",
    "الغلوة",
    "تغلى",
    "الغالية",
    "غمت",
    "اغتمد",
    "تغمد",
    "الغامد",
    "الغامدة",
    "الغمدية",
    "غمره",
    "غمرت",
    "أغمره",
    "اغتمرت",
    "انغمر",
    "تغمرت",
    "الغامر",
    "الغمار",
    "الغمر",
    "الغمرة",
    "الغمير",
    "الغميرة",
    "المغتمر",
    "المغمر",
    "المغمور",
    "غمزت",
    "أغمز",
    "اغتمز",
    "تغامز",
    "الغماز",
    "الغمازة",
    "الغميز",
    "الغميزة",
    "المغمز",
    "المغموز",
    "اغتمس",
    "انغمس",
    "تغامس",
    "الغماسة",
    "الغموس",
    "الغميس",
    "الغميسة",
    "غمصه",
    "غمصت",
    "اغتمصه",
    "الغمص",
    "المتغمص",
    "الغميصاء",
    "أغمضت",
    "اغتمض",
    "انغمض",
    "تغامض",
    "الغامض",
    "الغماض",
    "الغمض",
    "المغمضات والمغمضات",
    "أغمط",
    "غامط",
    "اغتمط",
    "تغمط",
    "تغمغم",
    "الغمغمة",
    "التغمغم",
    "غمقت",
    "الغامق",
    "الغمق",
    "غمل",
    "أغمل",
    "انغمل",
    "تغمل",
    "الغمالج",
    "الغملج والغملج",
    "الغملس",
    "أغمت",
    "غامه",
    "اغتم",
    "انغم",
    "الغم",
    "الغماء",
    "الغمة",
    "الغمى",
    "المغمم",
    "المغموم",
    "غمن",
    "انغمن",
    "الغمنة",
    "أغمي",
    "الإغماء",
    "الغمي",
    "الغمية",
    "الغنب",
    "الغنبة",
    "غنجت",
    "تغنجت",
    "الأغنوجة",
    "الغناج",
    "الغنج",
    "الغندبة",
    "الغندر",
    "الغندور",
    "الغنوصية",
    "غنظ",
    "الغناظ",
    "أغنمه",
    "غنمه",
    "تغنم",
    "الغنم",
    "الغنام",
    "الغنيمة",
    "المغنم",
    "غننه",
    "الغنة",
    "غني",
    "أغنى",
    "اغتنى",
    "تغانى",
    "استغنى",
    "الغاني",
    "الغانية",
    "الغناء",
    "الغنى",
    "الغنيان",
    "الغني",
    "المغني",
    "غهب",
    "أغهب",
    "اغتهب",
    "الغهب",
    "الغيهب",
    "الغيهبة",
    "غاثه",
    "أغاثه",
    "استغاث",
    "الغوث",
    "الغويث",
    "الغياث",
    "المغاث",
    "المغوثة",
    "غاج",
    "تغوج",
    "الغوج",
    "غار",
    "أغار",
    "غاور",
    "تغاور",
    "استغار",
    "الغائرة",
    "الغارة",
    "الغور",
    "المغار",
    "المغارة",
    "المغيرية",
    "غوز",
    "الأغوز",
    "غوصه",
    "الغياصة",
    "المتغوصة",
    "المغاص",
    "المغوصة",
    "غاط",
    "أغاط",
    "تغاوطا",
    "الغائط",
    "الغوط",
    "الغويط",
    "الغيط",
    "الغاغة",
    "غاله",
    "غاول",
    "اغتاله",
    "تغول",
    "الغائل",
    "الغائلة",
    "الغيلة",
    "المغالة",
    "أغواه",
    "غواه",
    "تغاوى",
    "استغواه",
    "الأغواء",
    "الأغوية",
    "الغية",
    "المغواة",
    "غاب",
    "أغاب",
    "أغيبت",
    "غايبه",
    "اغتابه",
    "الغيابة",
    "الغيب",
    "الغيبة",
    "الغيبان",
    "أغاث",
    "الغيث",
    "تغايد",
    "الأغيد",
    "الغادة",
    "الغيدان",
    "غاره",
    "غايره",
    "اغتار",
    "تغايرت",
    "الغير",
    "الغيرية",
    "المتغاير",
    "غيس",
    "الغيساني",
    "أغاض",
    "انغاض",
    "الغيض",
    "الغيضة",
    "المغيض",
    "غايط",
    "غاظه",
    "أغاظه",
    "غايظه",
    "اغتاظ",
    "تغيظ",
    "غافت",
    "غيف",
    "أغاف",
    "تغيف",
    "الغاف",
    "غيق",
    "تغيق",
    "غاق",
    "الغاقة",
    "غالت",
    "أغالت",
    "أغيلت",
    "تغيل",
    "الأغيل",
    "الغيل",
    "غيلان",
    "غامت",
    "أغامت",
    "أغيمت",
    "غيمت",
    "تغيمت",
    "الغيم",
    "المغيوم",
    "غانت",
    "أغبن",
    "الغانة",
    "الغينة",
    "أغيا",
    "غايا",
    "تغايا",
    "الغاية",
    "الغياية",
    "الفاء",
    "الفالوذ",
    "فأده",
    "فئد",
    "افتأد",
    "تفأدت",
    "الفؤاد",
    "الفئيد",
    "المفآد والمفأد والمفأدة",
    "فئر",
    "الفأرة",
    "الفؤارة",
    "المفأر",
    "فأق",
    "فئق",
    "تفأق",
    "الفائق",
    "الفأق",
    "الفؤاق",
    "فاءله",
    "افتأل",
    "تفاءل",
    "تفأل",
    "الفئال",
    "المفائل",
    "فأم",
    "أفأم",
    "الفئام",
    "الفؤمة",
    "فأى",
    "انفأى",
    "تفأى",
    "الفأو",
    "انفت",
    "تفتت",
    "الفتة والفتة",
    "الفتوت",
    "الفتيت",
    "الفتيتة",
    "فاتحه",
    "افتتح",
    "انفتح",
    "تفاتحا",
    "استفتح",
    "الفاتحة",
    "الفتح",
    "المفتاح",
    "المفتح",
    "فتخه",
    "فتخ",
    "أفتخ",
    "تفتخ",
    "الفتخ",
    "الفتخاء",
    "الفتخة",
    "أفتر",
    "الفاتر",
    "الفتار",
    "الفتر",
    "الفتاش",
    "أفتق",
    "انفتق",
    "الفتاق",
    "الفتقة",
    "الفتيق",
    "المفتق",
    "المفتقة",
    "أفتك",
    "تفتك",
    "الفتاك",
    "أفتل",
    "انفتل",
    "تفتل",
    "الفتل",
    "الفتيل",
    "الفتيلة",
    "فتنه",
    "افتتن",
    "تفاتن",
    "الفتان",
    "الفتنة",
    "الفتين",
    "المفتون",
    "فتاه",
    "أفتى",
    "فاتاه",
    "تفاتى",
    "تفتى",
    "استفتاه",
    "الفتى",
    "الفتوى",
    "الفتيا",
    "المفتي",
    "فثئ",
    "أفثأ",
    "فث",
    "افتثه",
    "انفث",
    "الفث",
    "فثج",
    "أفثج",
    "فثد",
    "الفثائيد",
    "الفاثور",
    "فثغ",
    "فجأه",
    "فاجأه",
    "الفجأة",
    "الفجاءة",
    "أفج",
    "فاج",
    "افتج",
    "انفجت",
    "تفاج",
    "الإفجيج",
    "الفجاج",
    "الفجاجة",
    "الفج",
    "الفجة",
    "أفجر",
    "فاجر",
    "افتجر",
    "الفاجر",
    "الفجار",
    "الفجر",
    "فجره",
    "فجس",
    "تفجس",
    "فجعه",
    "الفاجع",
    "الفاجعة",
    "الفجوع",
    "الفجيعة",
    "المفجع",
    "افتجل",
    "الأفجل",
    "الفجال",
    "فجم",
    "انفجم",
    "تفجم",
    "الفجمة",
    "فجي",
    "أفجى",
    "فجاه",
    "تفاجى",
    "الفجواء",
    "فحث",
    "افتحث",
    "الفحث",
    "الفحثة",
    "أفحج",
    "انفحجت",
    "فحت",
    "الفحة",
    "أفحش",
    "تفحش",
    "الفاحشة",
    "الفحش",
    "الفحشاء",
    "فحصت",
    "افتحص",
    "الأفحوص",
    "الفحصة",
    "المفحص",
    "فحفح",
    "فحل",
    "أفحل",
    "افتحل",
    "تفحل",
    "استفحل",
    "الفحالة",
    "الفحال",
    "الفحل",
    "الفحلة",
    "الفحيل",
    "فحم",
    "أفحم",
    "الفاحم",
    "الفحامة",
    "الفحام",
    "الفحم والفحم",
    "الفحمة",
    "الفحيم",
    "فحا",
    "فحى",
    "فاحاه",
    "الفحا",
    "الفحوة",
    "الفحية",
    "فختت",
    "تفخت",
    "الفاختة",
    "الفخت",
    "فخج",
    "فخذه",
    "فخذ",
    "تفخذ",
    "الفخذ والفخذ",
    "فخر",
    "أفخر",
    "فاخره",
    "فخره",
    "افتخر",
    "استفخر",
    "الفاخر",
    "الفاخور",
    "الفاخورة",
    "الفخارة",
    "الفخاري",
    "المفخر والمفخرة",
    "الفخفخة",
    "فخمه",
    "تفخمه",
    "الفيخمان",
    "فدحه",
    "استفدح",
    "فد",
    "فدد",
    "الفداد",
    "الفدادة",
    "الفديد",
    "أفدر",
    "تفدر",
    "الفادر",
    "الفادرة",
    "الفدر",
    "الفدرة",
    "الفدور",
    "المفدرة",
    "فدع",
    "فدعه",
    "الفدع",
    "فدغ",
    "انفدغ",
    "الفدفد",
    "الفدفدة",
    "أفدمه",
    "الفدام",
    "الفدامة",
    "الفدم",
    "المفدمات",
    "الفدن",
    "فداه",
    "أفدى",
    "فاداه",
    "تفادى",
    "الفداء",
    "الفدائية",
    "الفدية",
    "أفذت",
    "تفذذ",
    "استفذ",
    "الأفذ",
    "الفاذة",
    "الفذاذى",
    "الفذاذ",
    "الفذ",
    "الفذة",
    "المفذاذ",
    "فذلك",
    "الفذلكة",
    "الفرأ",
    "الفراء",
    "الفريء",
    "الفرات",
    "فرتك",
    "فرتن",
    "فرتنى",
    "الفرتنى",
    "فرثت",
    "أفرث",
    "انفرثت",
    "تفرث",
    "الفراثة",
    "الفرث",
    "انفرج",
    "التفاريج",
    "الفرج",
    "الفرجة",
    "الفرجية",
    "الفروج",
    "الفريج",
    "المفرج",
    "فرجن",
    "الفرجون",
    "فرح",
    "أفرحه",
    "الفرح",
    "الفرحة",
    "المفراح",
    "المفرح",
    "أفرخ",
    "استفرخ",
    "الفرخة",
    "الفروخ",
    "المفارخ",
    "فرد",
    "أفردت",
    "انفرد",
    "استفرد",
    "الإفراد",
    "الفارد",
    "الفرد",
    "الفردان",
    "الفردة",
    "الفراد",
    "الفرود",
    "الفريد",
    "المفراد",
    "المفرد",
    "فردس",
    "الفرادس",
    "الفردسة",
    "أفرت",
    "فاررته",
    "افتر",
    "تفار",
    "الفرار",
    "الفر",
    "الفرة",
    "الفرى",
    "الفرير",
    "أفرزه",
    "افترز",
    "تفارزا",
    "الإفرير",
    "المفروز",
    "الفرزدق",
    "أفرس",
    "فارسه",
    "افترس",
    "تفرس",
    "الأفرس",
    "الفارس",
    "الفارسي",
    "الفراس",
    "الفرس",
    "فرس البحر",
    "الفرسة",
    "الفريس",
    "فرسخ",
    "الفرسخ",
    "المفرسخ",
    "الفرسن",
    "المفرسن",
    "أفرش",
    "انفرش",
    "تفرش",
    "الفراش",
    "الفرش",
    "الفريش",
    "المفرشة",
    "المفروشة",
    "تفرشح",
    "الفرشاح",
    "أفرصت",
    "فارصه",
    "افترص",
    "تفارص",
    "تفرص",
    "الفراص",
    "الفرصاء",
    "الفريص",
    "الفريصة",
    "المفراص",
    "المفرص",
    "الفرصاد",
    "أفرض",
    "افترض",
    "الفرائض",
    "الفراض",
    "الفرض",
    "الفرضة",
    "الفرضي",
    "الفريض",
    "الفريضة",
    "المفراض",
    "المفرضة",
    "المفرض",
    "افترط",
    "تفارط",
    "تفرط",
    "الفراطة",
    "الفرطي",
    "المفارط",
    "فرطس",
    "الفرطاس",
    "الفرطوسة",
    "أفرع",
    "افترع",
    "الأفرع",
    "الفارع",
    "الفرع",
    "الفريعاء",
    "فرعن",
    "تفرعن",
    "فرعون",
    "افترغ",
    "الفراغ",
    "الفراغة",
    "الفرغ",
    "الفريغ",
    "المفرغة",
    "الفرافر",
    "الفرفار",
    "الفرفر",
    "الفرفير",
    "الفرفيرين",
    "في الطب",
    "الفرفيريني",
    "أفرق",
    "فارقه",
    "انفرق",
    "تفارق",
    "التفاريق",
    "الفارق",
    "الفاروق",
    "الفاروقة",
    "الفراق",
    "الفرق",
    "الفرق والفرق",
    "الفرقان",
    "الفرقة",
    "الفريق",
    "الفرقد",
    "الفرقود",
    "افرنقع",
    "تفرقعت",
    "أفرك",
    "انفرك",
    "تفرك",
    "استفرك",
    "الفريك",
    "الفريكة",
    "المفروكة",
    "أفرم",
    "الفرامة",
    "الفران",
    "الفرنية",
    "الفرند",
    "الفرانق",
    "أفره",
    "استفره",
    "فرهد",
    "تفرهد",
    "الفرهد",
    "الفرهود",
    "الفرا",
    "أفرى",
    "انفرى",
    "تفرى",
    "لفرية",
    "الفري",
    "أفزر",
    "انفرز",
    "تفزر",
    "الفازرة",
    "الفزارة",
    "الفزر",
    "الفزراء",
    "الفزرة",
    "أفزه",
    "تفازا",
    "استفزه",
    "الفز",
    "الفزة",
    "أفزعه",
    "فزعه",
    "الفزع",
    "الفزعة",
    "المفازع",
    "المفزعة",
    "فزفزه",
    "فسأ",
    "فسئ",
    "تفسأ",
    "المفسوء",
    "الفستقي",
    "أفسح",
    "انفسح",
    "تفاسح",
    "تفسح",
    "أفسخ",
    "فاسخه",
    "انفسخ",
    "تفاسخ",
    "الفسخ",
    "الفسيخ",
    "فاسد",
    "فسده",
    "تفاسد",
    "استفسد",
    "الفساد",
    "المفسدة",
    "استفسره",
    "التفسرة",
    "التفسير",
    "الفسطاط",
    "الفسيط",
    "الفسفاس",
    "الفسفس",
    "فسقه",
    "انفسقت",
    "الفسيق",
    "الفسق",
    "الفسقية",
    "فسكل",
    "الفسكول",
    "أفسل",
    "افتسل",
    "الفسالة",
    "الفسل",
    "الفسولة",
    "الفسيلة",
    "المفسى",
    "الفاسياء",
    "فشج",
    "تفشج",
    "تفشخ",
    "انفشت",
    "الفش",
    "الفشاش",
    "الفشة",
    "الفشوش",
    "الفشيش",
    "فشغ",
    "فشغت",
    "فاشغ",
    "فشغه",
    "انفشغ",
    "تفشغ",
    "الأفشغ",
    "الفشغة",
    "فشفش",
    "الفشفاش",
    "فشق",
    "فاشقه",
    "تفشق",
    "تفشل",
    "الفيشلة",
    "فشا",
    "أفشاه",
    "تفشى",
    "الفشاء",
    "الفشوة",
    "فصحه",
    "أفصح",
    "تفاصح",
    "تفصح",
    "الفصيح",
    "أفصدت",
    "افتصد",
    "انفصد",
    "تفصد",
    "الفاصدان",
    "أبو فصادة",
    "المفصد",
    "أفص",
    "فصصه",
    "افتص",
    "انفص",
    "استفص",
    "الفصاص",
    "الفصيص",
    "فصع",
    "افتصع",
    "انفصع",
    "تفصع",
    "الأفصع",
    "الفصعان",
    "الفصعة",
    "فصفص",
    "تفصفصوا",
    "الفصافص",
    "الفصفص",
    "الفصفصة",
    "افتصلت",
    "انفصل",
    "الفاصل",
    "الفاصولية والفاصولياء",
    "الفصال",
    "الفصل",
    "الفصلة",
    "الفيصل",
    "المفصل",
    "المفص ليات",
    "فصم",
    "أفصم",
    "فصمه",
    "انفصم",
    "تفصم",
    "الفصمة",
    "الفصيم",
    "أفصى",
    "فصاه",
    "انفصى",
    "تفصى",
    "تفضج",
    "فضحه",
    "تفاضح",
    "فضخ",
    "أفضخ",
    "افتضخ",
    "انفضخ",
    "الفضوخ",
    "الفضيخ",
    "المفضخة",
    "فضض",
    "افتض",
    "الفضاض",
    "الفض",
    "الفضض",
    "الفضة",
    "الفضيض",
    "المفضاض",
    "المفض والمفضة",
    "فضفض",
    "تفضفض",
    "الفضافضة",
    "الفضفاض",
    "الفضفاضة",
    "فاضله",
    "فضله",
    "استفضل",
    "الفاضل",
    "الفاضلة",
    "الفضال",
    "الفضالة",
    "الفضل",
    "الفضولي",
    "الفضيلة",
    "أفضى",
    "الفضا",
    "فطأ",
    "فطئ",
    "أفطأ",
    "تفاطأ",
    "فاطأ",
    "الفطأة",
    "فطحت",
    "الأفطح",
    "أفطر",
    "افتطر",
    "تفطر",
    "التفاطير",
    "الفطار",
    "الفطاري",
    "الفطر",
    "الفطرة",
    "الفطور",
    "الفطير",
    "الفطسة",
    "الفطيس",
    "الفطيسة",
    "أفطم",
    "انفطم",
    "الفاطمية",
    "الفطام",
    "الفطيم",
    "الفواطم",
    "فاطنه",
    "فطنه",
    "الفطانة",
    "افتظ",
    "الفظ",
    "أفظع",
    "استفظع",
    "فعفع",
    "الفعفاع",
    "افتعل",
    "انفعل",
    "تفاعلا",
    "الأفعولة",
    "الفاعل",
    "الفعل",
    "الفعلة",
    "الفعلي",
    "المفاعل الذري",
    "المفتعل",
    "المفعولية",
    "فعم",
    "فعمه",
    "الأفعم",
    "الفعم",
    "أفعى",
    "فعى",
    "تفعى",
    "الأفعوان",
    "المفعاة",
    "أفغر",
    "انفغر",
    "الفاغرة",
    "الفغرة",
    "فغم",
    "أفغم",
    "فغمه",
    "افتغم",
    "انفغم",
    "تفغم",
    "الفغم",
    "الفغمة",
    "المفغوم",
    "فغا",
    "فغي",
    "أفغى",
    "الفاغية",
    "الفغى",
    "الفغو",
    "الفغوة",
    "المفغو",
    "فقأ",
    "أفقأ",
    "انفقأ",
    "تفقأ",
    "الفاقياء",
    "الفقء",
    "الفقأة",
    "المفقئة",
    "فقح",
    "تفاقح",
    "تفقح",
    "الفقاحة",
    "الفقاحية",
    "أفقده",
    "افتقد",
    "تفاقد",
    "الفاقد",
    "افتقر",
    "تفاقر",
    "تفقرت",
    "الفاقرة",
    "الفقارة",
    "الفقر",
    "الفقرة",
    "الفقير",
    "المفاقر",
    "انفقس",
    "تفاقسا",
    "الفقوس",
    "المفقاس",
    "فقص",
    "الفقوص",
    "فقط",
    "انفقع",
    "تفاقعت",
    "تفقعت",
    "الأفقع",
    "الفاقع",
    "الفاقعة",
    "الفقع",
    "الفقاع",
    "فقفق",
    "الفقفاق",
    "انفق",
    "الفقاق",
    "فقم",
    "الأفقم",
    "الفقم",
    "أفقهه",
    "فاقهه",
    "فقهه",
    "تفقه",
    "الفقاهة",
    "الفقه",
    "الفقيه",
    "أفكر",
    "افتكر",
    "الفكر",
    "الفكرى",
    "الفكير",
    "انفك",
    "استفك",
    "الأفك",
    "الفاك",
    "الأفكل",
    "فكن",
    "تفكن",
    "الفكنة",
    "أفكهت",
    "تفاكه",
    "الأفكوهة",
    "الفاكه",
    "الفاكهاني",
    "الفاكهة",
    "الفاكهي",
    "الفكه",
    "الفكيهة",
    "الفيكهان",
    "فلت",
    "فالته",
    "افتلت",
    "انفلت",
    "تفلت",
    "استفلت",
    "الفلت",
    "الفلتان",
    "الفلتة",
    "أفلج",
    "فالجه",
    "تفلج",
    "الفالج",
    "الفلج",
    "الفلجات",
    "الفلجة",
    "الفلوجة",
    "المفلج",
    "أفلح",
    "انفلحت",
    "تفلح",
    "استفلح",
    "الأفلاح",
    "الفلاح",
    "الفلح",
    "الفلحة",
    "تفلحس",
    "الفلحاس",
    "الفلحس",
    "فالذه",
    "افتلذ",
    "الفالوذ والفالوذج",
    "الفلذ",
    "الفلذة",
    "المفلوذ",
    "الفلز",
    "أفلس",
    "الفلس",
    "المفلس",
    "فليسة",
    "تفلسف",
    "أفلص",
    "فلصه",
    "تفلص",
    "الفلطاح",
    "فلعه",
    "انفلع",
    "تفلع",
    "فلغ",
    "تفلغ",
    "فلغمون",
    "تفلفل",
    "المفلفل",
    "الفليفلة",
    "فلقت",
    "أفلق",
    "افتلق",
    "انفلق",
    "تفيلق",
    "الفالق",
    "الفلاق",
    "الفلاقة",
    "الفلقان",
    "الفلقى",
    "الفليق",
    "الفليقة",
    "المفلاق",
    "المفلق",
    "أفلك",
    "تفلك",
    "استفلك",
    "الفلاكة",
    "الفلك",
    "الفلكة",
    "الفليكة",
    "المفلوك",
    "فلكلور",
    "فلل",
    "افتل",
    "انفل",
    "تفلل",
    "الأفل",
    "الفل",
    "الفلة",
    "الفلى",
    "الفلية",
    "افتلم",
    "تفيلم",
    "فلان",
    "الفلهد",
    "الفلهود",
    "فلاه",
    "أفلى",
    "افتلى",
    "تفالى",
    "تفلى",
    "استفلى",
    "الفلاة",
    "الفلاية",
    "الفلو والفلو",
    "الفم",
    "فنجل",
    "الفنجال",
    "الفنجانة",
    "فنخه",
    "الفنيخ",
    "المفنخ",
    "أفند",
    "افتند",
    "تفند",
    "الفند",
    "الفندة",
    "فنس",
    "الفنس",
    "فنش",
    "الفنطاس",
    "فنع",
    "الفونغراف",
    "أفنق",
    "فنقه",
    "تفنق",
    "الفنيق",
    "الفنيقة",
    "أفنك",
    "أفنت",
    "افتن",
    "استفن",
    "الأفنون",
    "الفن",
    "الفنن",
    "الفينان",
    "المفن",
    "أفنى",
    "فاناه",
    "تفانى",
    "الأفنى",
    "الأفناء",
    "الفناة",
    "فهد",
    "الأفهود",
    "الفهدة",
    "الفهاد",
    "الفوهد",
    "أفهر",
    "فهرت",
    "تفهر",
    "الفهر",
    "الفهرة",
    "المفاهر",
    "الفهرست",
    "فهفه",
    "الفهفه",
    "فهق",
    "أفهق",
    "تفهق",
    "تفيهق",
    "الفهقة",
    "الفيهق",
    "أفهمه",
    "تفاهم",
    "تفهم",
    "استفهمه",
    "الفهم",
    "الفهامة",
    "المفهوم",
    "فه",
    "أفهه",
    "فههه",
    "الفهاهة",
    "الفهة",
    "فها",
    "أفهى",
    "الأفهاء",
    "فات",
    "أفاته",
    "فوته",
    "افتات",
    "تفاوت",
    "تفوت",
    "الفوات",
    "الفوت",
    "أفاج",
    "الفائج",
    "الفائجة",
    "أفاح",
    "تفاوح",
    "فاخت",
    "أفاد",
    "تفود",
    "استفاد",
    "الفائدة",
    "الفود",
    "المفواد",
    "أفار",
    "فور",
    "الفوارة",
    "الفارة",
    "الفور",
    "الفورة",
    "الفيار",
    "الفيرة",
    "الفيور",
    "فاز",
    "أفازه",
    "تفوز",
    "الفائزة",
    "الفازة",
    "المفاز",
    "المفازة",
    "فاوضه",
    "تفاوضا",
    "الفوضة",
    "المفاوضة",
    "المفوض",
    "فوطه",
    "الفوطي",
    "الفواط",
    "فاظت",
    "فاع",
    "الفوعة",
    "فاف",
    "الفوف",
    "المفوف",
    "فوق",
    "أفاق",
    "افتاق",
    "استفاق",
    "الفاقة",
    "الفوقة",
    "الفيقة",
    "المفوق",
    "الفومة",
    "فاه",
    "فاهاه",
    "تفاوه",
    "الفاه",
    "الفوه",
    "الفوهاء",
    "المفوه",
    "فاء",
    "أفاء",
    "فيأت",
    "تفيأت",
    "استفاء",
    "الفيء",
    "الفيئة",
    "المفاء",
    "المفيء",
    "الفائج والفائجة",
    "الفيج",
    "فيح",
    "الفياح",
    "الفياحة",
    "فادت",
    "تفايدا",
    "تفيد",
    "الفيد",
    "الفياد",
    "الفيروزج",
    "فاش",
    "فايش",
    "تفايش",
    "تفيش",
    "الفيشة",
    "الفيوش",
    "الفياش",
    "فاص",
    "أفاص",
    "المفيص",
    "أفاض",
    "الإفاضة",
    "الفيض",
    "الفيوض",
    "الفياض",
    "المفاض",
    "فاظ",
    "أفاظه",
    "الفيظ",
    "الفيف",
    "الفيفاء",
    "أفيق",
    "فايله",
    "تفيل",
    "استفيل",
    "الفائل",
    "الفائلتان",
    "الفال",
    "الفيال",
    "الفيل",
    "القاف",
    "قأب",
    "قئب",
    "القؤوب",
    "المقأب",
    "القئفي",
    "أقب",
    "تقبب",
    "القابة",
    "القباب",
    "القب",
    "القبة",
    "القبي",
    "القبيب",
    "المقبب",
    "المقببة",
    "المقبوبة",
    "القبج",
    "قابحه",
    "قبحه",
    "استقبحه",
    "القباح",
    "القبح",
    "القبيح",
    "المقابح",
    "أقبر",
    "القبار",
    "القبر",
    "القبور",
    "المقبرة",
    "أقبسه",
    "اقتبس",
    "القابوس",
    "القبس",
    "القبسة",
    "القوابس",
    "المقباس",
    "اقتبص",
    "انقبص",
    "تقبص",
    "القابصة",
    "القبص",
    "القبصة",
    "القبيص",
    "المقبص",
    "أقبض",
    "قابضه",
    "اقتبض",
    "تقبض",
    "القابض",
    "القبض",
    "القبيض",
    "المقبض والمقبضة",
    "المنقبض",
    "القبطية",
    "اقتبع",
    "انقبع",
    "القابعة",
    "القباع",
    "القبيعة",
    "القبع",
    "القوبع",
    "تقبقب",
    "القباقب",
    "اقتبل",
    "تقابلا",
    "استقبله",
    "الاستقبال",
    "القابلة",
    "القابول",
    "القبال",
    "القبالة",
    "القبل",
    "القبلة",
    "القبول",
    "القبيل",
    "القبيلة",
    "المقابل",
    "المقابلة",
    "المقبلة",
    "أقبن",
    "القبانة",
    "القبان",
    "القباني",
    "القبين",
    "قباه",
    "قبى",
    "انقبى",
    "تقبى",
    "القابياء",
    "القابية",
    "القباء",
    "القبوة",
    "قتبه",
    "أقتب",
    "القتب",
    "القتبة",
    "القتوبة",
    "قتت",
    "القت",
    "القتات",
    "قتدت",
    "قتد",
    "القتد",
    "أقتر",
    "تقاتر",
    "تقتر",
    "القاتر",
    "القتار",
    "القتر",
    "القترة",
    "القتور",
    "القتير",
    "قتع",
    "قاتعه",
    "القتع",
    "القتعة",
    "قتله",
    "أقتله",
    "قاتله",
    "تقاتل",
    "تقتل",
    "استقتل",
    "القتال",
    "القتل",
    "القتول",
    "المقاتل",
    "المقتتل",
    "المقتلة",
    "أقتم",
    "الأقتم",
    "القاتم",
    "القتام",
    "القتم",
    "القتمة",
    "قتن",
    "أقتن",
    "القتين",
    "قتا",
    "اقتوى",
    "المقتوي",
    "المقتوين",
    "أقثأ",
    "القثاء",
    "المقثأة",
    "اقتث",
    "القثاث",
    "القثاثة",
    "القثيث",
    "القثيثة",
    "المقثة",
    "قثم",
    "اقتثم",
    "قثام",
    "القثم",
    "القثوم",
    "قثا",
    "اقتثى",
    "قحب",
    "قاحبت",
    "تقحبت",
    "القحاب",
    "القحب",
    "القحبة",
    "القحاح",
    "القح",
    "قحر",
    "القحارية",
    "القحر",
    "قحز",
    "قحزه",
    "القحاز",
    "القحازة",
    "أقحط",
    "القحط",
    "المقحط",
    "المقحطة",
    "قاحفه",
    "اقتحف",
    "القحاف",
    "القحافة",
    "القحف",
    "القحفاء",
    "القحوف",
    "المقحفة",
    "أقحل",
    "قاحله",
    "تقحل",
    "القاحل",
    "القحال",
    "قحمه",
    "انقحم",
    "تقحمت",
    "القحامة",
    "القحم",
    "القحمة",
    "القحوم",
    "المقحمة",
    "قحا",
    "أقحت",
    "اقتحى",
    "القحوان",
    "المقحاة",
    "قادحه",
    "تقادحا",
    "استقدح",
    "القادح",
    "القادحة",
    "القدح",
    "القدوح",
    "القديح",
    "المتقادح",
    "المقداح",
    "المقدح",
    "المقدح والمقدحة",
    "انقد",
    "تقدد",
    "القداد",
    "القد",
    "القدة",
    "القديد",
    "المقد",
    "المقد والمقدة",
    "المقدودة",
    "أقدره",
    "قادره",
    "اقتدر",
    "انقدر",
    "تقادر",
    "استقدر",
    "القادر",
    "القادرة",
    "القدر",
    "القدراء",
    "القدرة",
    "القدرية",
    "القدير",
    "المقتدر",
    "المقدار",
    "المقدرة",
    "تقدس",
    "القادس",
    "القادوس",
    "القدس",
    "المقدس",
    "قدع",
    "أقدعه",
    "قادعه",
    "انقدع",
    "تقادع",
    "تقدع",
    "القدع",
    "القدعة",
    "القدوع",
    "المقدعة",
    "قدف",
    "القداف",
    "استقدم",
    "القدام",
    "قدام",
    "القدم",
    "القدمة",
    "القدمية",
    "القدوم",
    "القديم",
    "القيدام",
    "المقدام والمقدامة",
    "المقدم",
    "المقدمة",
    "أقدى",
    "قاداه",
    "تقدى",
    "اقتدى",
    "القادية",
    "القاديانية",
    "القدا",
    "القدى",
    "القداة",
    "القداوة",
    "القدو",
    "القدوى",
    "القدوة",
    "القدي والقدي",
    "قذه",
    "أقذ",
    "تقذذ",
    "الأقذ",
    "القاذ والقاذة",
    "القذاذة",
    "القذان",
    "المقذ",
    "المقذ والمقذة",
    "المقذذ",
    "قذره",
    "أقذره",
    "تقذره",
    "استقذر",
    "القاذورة",
    "القذر",
    "القذرة",
    "القذور",
    "المقذر",
    "قذعه",
    "أقذعه",
    "قاذعه",
    "تقذع",
    "الأقذع",
    "القذيع",
    "القذيعة",
    "المقذعات",
    "المقذع",
    "القذ عملة",
    "تقاذفوا",
    "الأقذاف",
    "قاذفة القنابل",
    "القذاف",
    "القذاف والقذافة",
    "القذيفى",
    "القذف",
    "القذفة",
    "المتقاذف",
    "المقاذف",
    "المقذاف",
    "المقذف",
    "المقذفة",
    "القاذل",
    "القذال",
    "قذم",
    "القذام",
    "القذم",
    "القذمة",
    "القذوم",
    "أقذت",
    "قاذاه",
    "اقتذى",
    "الأقذاء",
    "القذاة",
    "قرأ",
    "أقرأت",
    "قارأه",
    "اقترأ",
    "تقرأ",
    "استقرأه",
    "الاستقراء",
    "أقرأ",
    "القارئ",
    "القرآن",
    "القرء",
    "القراء",
    "المقرأة",
    "أقربت",
    "قارب",
    "اقترب",
    "تقاربا",
    "استقربه",
    "التقربات",
    "القراب",
    "القرابة",
    "القرب",
    "القربى",
    "القربة",
    "القريب",
    "القريبة",
    "القورب",
    "المقربات",
    "القربوس",
    "القرت",
    "قرثه",
    "قرث",
    "قرحه",
    "أقرح",
    "قارحه",
    "اقترح",
    "القارح",
    "القراح",
    "القرح",
    "القريح",
    "القريحة",
    "أقرد",
    "تقرد",
    "القراد",
    "قردح",
    "اقرندح",
    "القردحة",
    "القردوح",
    "القردد",
    "القردود",
    "القردودة",
    "القرديدة",
    "القردع",
    "القردوع",
    "أقر",
    "قاره",
    "اقتر",
    "تقار",
    "تقرر",
    "استقر",
    "القرار",
    "القر",
    "القرة",
    "قرة العين",
    "القرور",
    "المقر",
    "المقرر",
    "المقرة",
    "المقرور",
    "قرسه",
    "أقرس",
    "القارس",
    "القرس",
    "القريس",
    "قرش",
    "أقرشت",
    "اقترش",
    "تقارش",
    "تقرش",
    "قريش",
    "القرشة",
    "القرواش",
    "القريش",
    "المقرشة",
    "القرشب",
    "قارصه",
    "تقارصا",
    "القارص",
    "القارصة",
    "القراصيا",
    "القروص",
    "المقراص",
    "المقرص",
    "قرصع",
    "تقرصعت",
    "أقرضه",
    "قارضه",
    "اقترض",
    "انقرض",
    "تقارضا",
    "استقرض",
    "القرض",
    "المقراض",
    "المقرض",
    "قرضب",
    "القرضاب",
    "قرطت",
    "تقرطت",
    "القاريط",
    "القراط",
    "القراطة",
    "قرطب",
    "تقرطس",
    "القرطاس",
    "القرطوم",
    "تقارظا",
    "القارظ",
    "القراظ",
    "القرظ",
    "القرظة",
    "القرظي",
    "أقرع",
    "اقترعوا",
    "تقارع",
    "تقرع",
    "الأقرع",
    "القراعة",
    "القرعاء",
    "القرعة",
    "القروع",
    "القريع",
    "المقراع",
    "المقرعة",
    "أقرف",
    "اقترف",
    "تقرفت",
    "القرافة",
    "القروف",
    "المقرف",
    "قرفل",
    "القرق",
    "القراقر",
    "القراقرة",
    "القراقري",
    "القرقار والقرقارة",
    "القرقر",
    "القرقور",
    "القرقير",
    "القرقس",
    "القرقوس",
    "قرقف",
    "تقرقف",
    "القرلى",
    "أقرم",
    "قرمه",
    "تقرم",
    "استقرم",
    "القرام",
    "القرامة",
    "المقرم",
    "المقرمة",
    "القرمد",
    "القرمود",
    "القرميد",
    "القرمش",
    "قرمص",
    "تقرمص",
    "القرماص",
    "القرمص",
    "القرموص",
    "قرمط",
    "تقرمط",
    "القرموط",
    "القرامطة",
    "قرمله",
    "القرمل",
    "القرملة",
    "أقرن",
    "قارنه",
    "اقترن",
    "استقرن",
    "القران",
    "القرانيا",
    "القرن",
    "القرناء",
    "القرنان",
    "القرنوة",
    "القرون",
    "القرونة",
    "القريناء",
    "القرينة",
    "المقرن",
    "قرنس",
    "القرناس",
    "القاره",
    "قره جوز",
    "قرا",
    "قريت",
    "أقرى",
    "اقترى",
    "تقرى",
    "استقرى",
    "اقرورى",
    "القاري",
    "القاراة",
    "القارية",
    "القرا",
    "القرو",
    "القروى",
    "القرواني",
    "القروي",
    "القرى",
    "القري",
    "القرية",
    "قرية النمل",
    "المقرى",
    "المقراء",
    "قزح",
    "قزحت",
    "تقزح",
    "التقازيح",
    "التقزيح",
    "القازحة",
    "القزح",
    "القزحية",
    "المقزحة",
    "قز",
    "القازوزة",
    "القزاز",
    "القزة",
    "القزية",
    "القوازيز",
    "أقزع",
    "تقزع",
    "قوزع",
    "القزعة",
    "القزع",
    "الأقزل",
    "قزمه",
    "تقزم",
    "القزام",
    "القزمة",
    "القزان",
    "قزًا",
    "أقزى",
    "القزو",
    "قسب",
    "القيسبة",
    "اقتسره",
    "قسور",
    "القسور",
    "القسورة",
    "تقسس",
    "القساس",
    "القسي",
    "قسطت",
    "اقتسطوا",
    "القسط",
    "القسطاء",
    "المقسط",
    "القسطاس",
    "القسطار",
    "القسطري",
    "القسطل",
    "قسقس",
    "تقسقس",
    "القسقاس",
    "قاسم",
    "اقتسم",
    "تقاسم",
    "تقسم",
    "استقسم",
    "الاستقسام",
    "الأقسومة",
    "القسام",
    "القسامة",
    "القسامي",
    "القسم",
    "القسمة",
    "القسوم",
    "القسيم",
    "المقسم",
    "أقسن",
    "الأقسى",
    "القسوة",
    "المقساة",
    "القسور والقسورة",
    "أقشب",
    "القشب",
    "القشيب",
    "قشده",
    "اقتشد",
    "القشادة",
    "اقتشر",
    "انقشر",
    "تقشر",
    "القشار",
    "القشراء",
    "القشير",
    "المقشرة",
    "المقشورة",
    "قش",
    "أقشت",
    "قشش",
    "تقشش",
    "القشاش",
    "قشطه",
    "انقشطت",
    "تقشطت",
    "المقشط",
    "قشعت",
    "أقشع",
    "انقشع",
    "تقشع",
    "القشع",
    "القشعة",
    "القشاعر",
    "القشعام",
    "القشعامة",
    "القشعم",
    "الأقشف",
    "القشف",
    "تقشقش",
    "قشم",
    "اقتشمه",
    "القشام",
    "القشامة",
    "القشم",
    "القشيم",
    "قشا",
    "أقشى",
    "قشى",
    "تقشى",
    "القشاء",
    "القشاوة",
    "القشوان",
    "القشوة",
    "أقصب",
    "اقتصب",
    "التقصيبة",
    "القاصب",
    "القصابة",
    "القصب",
    "القصباء",
    "القصاب",
    "المقصبة",
    "المقصب",
    "أقصد",
    "اقتصد",
    "انقصد",
    "تقصد",
    "القاصد",
    "القصد",
    "القصدة",
    "القصيد والقصيدة",
    "المقصد",
    "اقتصر",
    "تقاصر",
    "تقوصر",
    "استقصره",
    "القاصر",
    "القاصرة",
    "القصار",
    "القصارى",
    "القصارة",
    "القصر",
    "القصرة",
    "القصور الذاتي",
    "القصير",
    "القصيرى",
    "القوصرة",
    "المقصرة",
    "المقصورة",
    "قصت",
    "قاصه",
    "قصص",
    "اقتص",
    "تقاص",
    "استقصه",
    "الأقصوصة",
    "التقاص",
    "القاص",
    "القصاص",
    "القصص",
    "القصة",
    "القصيص",
    "القصيصة",
    "المقصوصة",
    "القصطل",
    "القاصعاء",
    "القصع",
    "القصعة",
    "أقصف",
    "انقصف",
    "تقاصف",
    "تقصف",
    "القصفة",
    "القصيف",
    "المقصف",
    "القصاقص",
    "القصقص",
    "أقصل",
    "اقتصل",
    "انقصل",
    "تقصل",
    "القصالة",
    "القصل",
    "القصلة",
    "القصيل",
    "المقصل",
    "قصم",
    "قصمت",
    "انقصم",
    "تقصم",
    "القاصمة",
    "القصم",
    "القصماء",
    "القصمة",
    "القيصوم",
    "قصمل",
    "القصملة",
    "أقصى",
    "قاصاه",
    "استقصى",
    "الأقصى",
    "القاصي",
    "القصا",
    "القصاء",
    "القصواء",
    "القصي",
    "القضأة",
    "قضبه",
    "أقضبت",
    "قضبت",
    "اقتضب",
    "انقضب",
    "تقضب",
    "القضابة",
    "القضب",
    "القضبة",
    "القضاب والقضابة",
    "القضيب",
    "أقض",
    "يتعدى ولا يتعدى",
    "انقض",
    "تقضض",
    "القضاض",
    "القض",
    "القضض",
    "القضة",
    "القضيض",
    "المقض",
    "قضعه",
    "انقضع",
    "تقضع",
    "التقضيع",
    "القضاع والقضاعة",
    "القضفة",
    "قضقضت",
    "تقضقض",
    "القضاقض",
    "القضقاض",
    "قضمت",
    "أقضم",
    "استقضم",
    "القضمة",
    "القضيم",
    "قاضاه",
    "اقتضى",
    "انقضى",
    "تقاضاه",
    "تقضى",
    "استقضى",
    "القاضي",
    "القضاء",
    "القضى",
    "القضاة",
    "القضية",
    "أقطب",
    "الاستقطاب",
    "القطاب",
    "القطبة",
    "القطوب",
    "القطيب",
    "المقطوب",
    "أقطر",
    "تقاطر",
    "تقطر",
    "استقطر",
    "الاستقطار",
    "القطر",
    "القطور",
    "المقاطرة",
    "تقطرب",
    "القطرب",
    "قط",
    "اقتط",
    "انقط",
    "القطاط",
    "القطائط",
    "القطيطة",
    "المقط",
    "المقطة",
    "أقطع",
    "قاطع",
    "قطعه",
    "اقتطع",
    "انقطع",
    "استقطعه",
    "الأقطاع",
    "الأقطع",
    "الأقطوعة",
    "القاطع",
    "القطع",
    "القطعة",
    "القطيعاء",
    "القطيعة",
    "المقطعات",
    "المقطعة",
    "المنقطع",
    "قطفت",
    "أقطف",
    "قطفه",
    "اقتطف",
    "القطاف",
    "القطافة",
    "القطف",
    "القطفة",
    "القطيف",
    "المقطف",
    "قطقطت",
    "تقطقط",
    "القطقاط",
    "القطقط",
    "المقطقط",
    "قطله",
    "تقطل",
    "القطيلة",
    "المقطلة",
    "قطمه",
    "القطام",
    "القطامي",
    "القطامة",
    "القطيمة",
    "القطمير",
    "القطان",
    "القطن",
    "القطنة",
    "القطين",
    "القيطان",
    "القيطون",
    "المقطنة",
    "تقطى",
    "القطى",
    "القطاة",
    "القطوان",
    "القطوطى",
    "قعب",
    "القعب",
    "القعبة",
    "القعيب",
    "قعبل",
    "القعبل",
    "المقعبل",
    "قعث",
    "أقعث",
    "انقعث",
    "القعاث",
    "القعيث",
    "أقعد",
    "قاعده",
    "قعده",
    "اقتعد",
    "تقعده",
    "الإقعاد",
    "القاعد",
    "القاعدة",
    "القعاد",
    "القعد والقعدة",
    "القعدة",
    "القعدي",
    "القعود",
    "القعيد",
    "القعيدة",
    "المقعدة",
    "القعدد",
    "قعرت",
    "قعر",
    "انقعر",
    "تقعر",
    "القعران",
    "القعرة",
    "القعور",
    "القعير",
    "المقعار",
    "تقعست",
    "القعاس",
    "القعس",
    "القعساء",
    "قعسر",
    "القعسر",
    "القعسري",
    "قعقش",
    "القعش",
    "قعصه",
    "قعصت",
    "قاعصه",
    "القعاص",
    "قعضبه",
    "القعضب",
    "القعضم",
    "قعط",
    "أقعط",
    "تقعط",
    "المقعط",
    "قعه",
    "أقع",
    "القعاع",
    "القع",
    "قعفه",
    "قعف",
    "اقتعف",
    "القعاف",
    "قعفز",
    "تقعفز",
    "اقعنفز",
    "تقعقع",
    "القعاقع",
    "القعقاع",
    "القعقع",
    "قعم",
    "القعمة",
    "قعن",
    "القعن",
    "قعنب",
    "اقعنبى",
    "القعنب",
    "قعي",
    "أقعى",
    "القعو",
    "القعواء",
    "قعول",
    "قفئت",
    "قفح",
    "قفد",
    "أقفر",
    "اقتفر",
    "تقفر",
    "القفار",
    "القفر",
    "القفرة",
    "المقفار",
    "تقافزوا",
    "تقفزت",
    "القافزة",
    "القفزى",
    "القفيزى",
    "القفيز",
    "قفس",
    "تقافس",
    "الأقفس",
    "اقتفش",
    "انقفش",
    "القفش",
    "تقافص",
    "تقفص",
    "القافصة",
    "القفاص",
    "القفاصة",
    "القفيص والقفيصة",
    "قفطه",
    "القفطان",
    "قفعه",
    "انقفع",
    "تقفع",
    "القفاع",
    "القفع",
    "القفعاء",
    "القفعة",
    "المقفعة",
    "أقفت",
    "القف",
    "القفاف",
    "القفان",
    "القفة",
    "القفيف",
    "تقفقف",
    "القفقاف",
    "القفقف",
    "أقفل",
    "تقفل",
    "استقفلت",
    "القافلة",
    "القفال",
    "القفلة",
    "القفيل",
    "القيفال",
    "المتقفل",
    "قفن",
    "اقتفن",
    "القفن والقفن",
    "القفن",
    "القفينة",
    "قفاه",
    "قفى",
    "أقفى",
    "اقتفاه",
    "تقافى",
    "تقفى",
    "استقفاه",
    "القفا",
    "القفاوة",
    "القفو",
    "القفوة",
    "القفية",
    "القفي",
    "أقلب",
    "انقلب",
    "القلاب",
    "القلب",
    "القلبة",
    "القلوب",
    "القليب",
    "المقلب",
    "أقلته",
    "القلت",
    "القلتة",
    "المقلات",
    "المقلتة",
    "قلحت",
    "الأقلح",
    "القلاح",
    "القلح",
    "أقلد",
    "قلده",
    "تقلد",
    "اقلوده",
    "القلد",
    "القلداء",
    "القلود",
    "القليد",
    "المقلاد",
    "المقلد",
    "المقلود",
    "قلز",
    "اقتلز",
    "تقلز",
    "القلز",
    "قلزم",
    "تقلزم",
    "قلست",
    "تقلنس",
    "القلس",
    "القلاس",
    "القليس",
    "المقلس",
    "أقلصت",
    "القلصة",
    "القلاص",
    "القلوص",
    "المقلص",
    "القليط",
    "القليطة",
    "أقلع",
    "اقتلعه",
    "انقلع",
    "تقلع",
    "القلاعة",
    "القلع",
    "القلعي",
    "القلوع",
    "المقلاع",
    "المقلوع",
    "اقتلف",
    "انقلفت",
    "القلافة",
    "القلف",
    "القليف",
    "القلفع",
    "أقلقت",
    "المقلاق",
    "القلقاس",
    "تقلقل",
    "القلاقل",
    "القلقلة",
    "قاللت",
    "استقل",
    "القلال",
    "قلالة",
    "القل",
    "القلة",
    "القلية",
    "القليل",
    "القالم",
    "القلم",
    "المقلام",
    "المقلمة",
    "تقاليا",
    "تقلى",
    "اقلولى",
    "القلاء",
    "القلاءة",
    "القلو",
    "قلواني",
    "المقلى",
    "قمأت",
    "أقمأ",
    "اقتمأ",
    "تقمأ",
    "قامأه",
    "القمء",
    "القمأة",
    "القميء",
    "قمح",
    "أقمح",
    "قامح",
    "اقتمح",
    "تقمح",
    "القامح",
    "القماح",
    "القمح",
    "القمحة",
    "القميحة",
    "القمحي",
    "القمحدوة",
    "قمد",
    "قمرت",
    "أقمر",
    "قامره",
    "تقامروا",
    "تقمر",
    "الأقمر",
    "القمار",
    "القمر",
    "القمراء",
    "القمرة",
    "القمري",
    "القمير",
    "القمزة",
    "أقمس",
    "قامس",
    "انقمس",
    "تقامس",
    "القامس",
    "القامسة",
    "القاموس",
    "القماس",
    "القمس",
    "القموس",
    "القومس",
    "قمشه",
    "اقتمشه",
    "تقمش",
    "القماشة",
    "القمش",
    "قمصت",
    "قامص",
    "تقامص",
    "القمصة",
    "القمص",
    "القميص",
    "القامطة",
    "القماط",
    "القمط",
    "القميط",
    "اقمطر",
    "القماطر",
    "القمطر",
    "القمطرى",
    "القمطرير",
    "قمعت",
    "اقتمع",
    "انقمع",
    "تقمعت",
    "الأقمع",
    "القمعة",
    "القميعة",
    "المقمعة",
    "القمقام",
    "القمقم",
    "القمقمة",
    "القمل",
    "قمت",
    "اقتمت",
    "تقمم",
    "القميم",
    "المقم",
    "المقمة",
    "تقمن",
    "القمن",
    "القمنة",
    "القمين",
    "المقمنة",
    "قمه",
    "تقمه",
    "أقمى",
    "قاماني",
    "القامية",
    "المقماة",
    "قنأ",
    "قنئ",
    "أقنأ",
    "المقنأة",
    "المقنوءة",
    "أقنب",
    "تقنب",
    "القانب",
    "القناب",
    "القنابة",
    "القنيب",
    "القيناب",
    "المقنب",
    "القنبيط",
    "القنبع",
    "القنبعة",
    "القنبل",
    "القنبلانية",
    "أقنت",
    "قنتت",
    "اقتنت",
    "القنوت",
    "قنح",
    "أقنح",
    "تقنح",
    "القناح",
    "القناحة",
    "أقند",
    "القند",
    "القندأو",
    "القندة",
    "القنديد",
    "قندل",
    "القنادل",
    "القندل",
    "القنديل",
    "القنزع",
    "أقنس",
    "القنس",
    "القونس",
    "قنسرته",
    "تقنسر",
    "القناسر",
    "القنسر",
    "اقتنص",
    "تقنص",
    "القانصة",
    "القنيص",
    "أقنطه",
    "قنطه",
    "المقنطر",
    "قنعت",
    "أقنعت",
    "قنعه",
    "القنع",
    "القنعان",
    "المقنع",
    "قنفت",
    "أقنف",
    "القناف",
    "القنيف",
    "تقنفذ",
    "القنفذ",
    "القنمة",
    "اقتن",
    "استقن",
    "القنان",
    "القن",
    "القنة",
    "القنقن",
    "قنا",
    "قانى",
    "اقتنى",
    "تقنى",
    "استقنى",
    "الإقناءة",
    "القناء",
    "القناة",
    "القنو",
    "القنوة",
    "القنية",
    "القني",
    "المقناة",
    "المقنى",
    "قهب",
    "أقهب",
    "القهبة",
    "قهد",
    "القهد",
    "أقهر",
    "القاهرة",
    "القواهر",
    "القهرة",
    "القهرم",
    "القهرمان",
    "القهرمانة",
    "قهقر",
    "تقهقر",
    "القهقار",
    "القهقرى",
    "القهقرة",
    "قهقه",
    "قهل",
    "تقهل",
    "قهم",
    "أقهم",
    "قه",
    "أقهى",
    "قهي",
    "تقوبت",
    "القائبة",
    "القوب",
    "القوباء والقوباء",
    "القوبة",
    "القوبي",
    "أقاته",
    "اقتات",
    "استقاته",
    "القائت",
    "القوت",
    "القيت والقيتة",
    "قاوحه",
    "قود",
    "أقاده",
    "اقتاد",
    "انقاد",
    "تقاود",
    "استقادت",
    "الأقود",
    "القائد",
    "القائدة",
    "القؤود",
    "القاد",
    "القود",
    "القيد",
    "القيدة",
    "المقادة",
    "تقور",
    "اقور",
    "الأقور",
    "القوارة",
    "المقورة",
    "قوز",
    "تقوز",
    "القوز",
    "قاس",
    "قوس",
    "استقوس",
    "الأقوس",
    "القوس",
    "القواس والقياس",
    "المقاوس",
    "قاض",
    "القوطة",
    "القواط",
    "تقوع",
    "القواع",
    "قاف",
    "اقتاف",
    "تقوف",
    "الأقوف",
    "القائف",
    "القوف",
    "القيافة",
    "قاقت",
    "القاق",
    "القاووق",
    "القوقة",
    "المقوق",
    "قوقأت",
    "قوقت",
    "المقوقس",
    "القوقعيات",
    "قوقل",
    "القاقلة",
    "القاقلى",
    "قال",
    "أقوله",
    "قاوله",
    "قوله",
    "تقاولوا",
    "تقول",
    "التقوالة",
    "القال",
    "القول",
    "القولية",
    "القيل",
    "المقالة",
    "المقوال",
    "المقول",
    "المقولة",
    "القولنج",
    "قام",
    "أقام",
    "قاومه",
    "قومت",
    "تقاوموا",
    "التقويم",
    "القائم",
    "القائمة",
    "القامة",
    "القوامة",
    "القوم",
    "القومة",
    "القيام",
    "القيامة",
    "القيمة",
    "القيم",
    "المقام",
    "المقوم",
    "القونة",
    "القاوون",
    "تقاوه",
    "القاه",
    "القاهي",
    "القوهة",
    "القوهي",
    "قاويت",
    "التقاوي",
    "القاوي",
    "القاوية",
    "القواء",
    "القواية",
    "القوة",
    "القوي",
    "القي",
    "أقاءه",
    "قيأه",
    "استقاء واستقيأ",
    "القيء",
    "القيوء",
    "القياء",
    "القيثار والقيثارة",
    "أقاح",
    "القيح",
    "قاده",
    "قيده",
    "المقيد",
    "القير",
    "القيار",
    "قايس",
    "اقتاس",
    "انقاس",
    "تقايس",
    "القاس",
    "القياس",
    "القيس",
    "قاصت",
    "انقاصت",
    "اقتاض",
    "تقيض",
    "القيض",
    "القيضة",
    "قاظ",
    "قايظه",
    "اقتاظ",
    "القيظ",
    "القيظي",
    "المقيظ والمقيظ",
    "القيقاءة",
    "القيقية",
    "أقال",
    "قايله",
    "قيله",
    "تقايل",
    "تقيل",
    "القائلة",
    "المقال",
    "المقيال",
    "المقيل",
    "قينه",
    "اقتان",
    "تقين",
    "القين",
    "القينان",
    "القينة",
    "المقينة",
    "الكاف",
    "أكأب",
    "اكتأب",
    "الكأباء",
    "تكاءده",
    "تكأده",
    "اكوأد",
    "الكأداء",
    "الكوداء",
    "الكؤود",
    "كأكأ",
    "تكأكأ",
    "الكأكاء",
    "كأن",
    "كبه",
    "أكب",
    "تكاب",
    "تكبب",
    "الكبابة",
    "الكبة",
    "الكبيبة",
    "لمكباب",
    "اكتبت",
    "انكبت",
    "كبثت",
    "كبث",
    "الكباث",
    "الكبيث",
    "الكباحة",
    "كبده",
    "تكبد",
    "الكباد",
    "الكبود",
    "الكبداء",
    "الكبيداء",
    "أكبرت",
    "كابر",
    "كوبر",
    "الأكبر",
    "الكابر",
    "الكبار",
    "الكبر",
    "الكبرة",
    "الكبرياء",
    "الكبير",
    "الكبيرة",
    "كبرته",
    "المكبرت",
    "تكبس",
    "الكباس",
    "الكبيس",
    "كبش",
    "الكباش",
    "الكبش",
    "الكبشة",
    "الكبع",
    "تكبكب",
    "الكبكب",
    "الكبكبة",
    "الكابول",
    "الكبل",
    "كبن",
    "الكبينة",
    "أكبى",
    "كبى",
    "اكتبى",
    "تكبى",
    "الكابي",
    "الكبا",
    "الكباء",
    "الكبوة",
    "الكتأة",
    "أكتبه",
    "كاتب",
    "اكتتب",
    "تكاتب",
    "تكتبوا",
    "استكتبه",
    "الكاتب",
    "الكتاب",
    "الكتابة",
    "الكتبة",
    "المكاتب",
    "المكتب",
    "المكتوبة",
    "كتت",
    "أكت",
    "اكتت",
    "تكات",
    "الكت",
    "الكتة",
    "كتح",
    "الكتح",
    "كتخدا",
    "الأكتد",
    "الكتد",
    "أكترت",
    "الكتر",
    "الكترة",
    "الأكتع",
    "كتاع",
    "الكتع",
    "الكتعاء",
    "الكتعة",
    "الكتيع",
    "كاتفه",
    "تكاتف",
    "تكتف",
    "الكتاف",
    "الكتف",
    "الكتيف",
    "الكتيفة",
    "تكتكت",
    "كتله",
    "انكتل",
    "التكتيل",
    "الكتال",
    "كتول",
    "الكتيلة",
    "المكتل",
    "المكتلة",
    "كتلوج",
    "اكتتم",
    "تكاتم",
    "استكتمه",
    "الكاتم",
    "الكاتمة",
    "الكتمة",
    "الكتيم",
    "أكتنه",
    "الكتان",
    "الكتون",
    "كثأ",
    "الكثأة",
    "أكثب",
    "انكثب",
    "الكثب",
    "الكثبة",
    "الكثيب",
    "أكث",
    "الكاث",
    "كثره",
    "أكثر",
    "كاثره",
    "تكاثرت",
    "تكثر",
    "تكوثر",
    "الأكثر",
    "الأكثرية",
    "الكثار",
    "الكثر",
    "الكثرة",
    "الكثير",
    "الكثيراء",
    "الكوثر",
    "المكثار",
    "المكثر",
    "المكثور",
    "المكثير",
    "كثع",
    "كثعت",
    "الكثعة",
    "استكثف",
    "التكثف",
    "الكثف",
    "المكثاف",
    "الكثكث",
    "الكوثل والكوثل",
    "كثم",
    "أكثم",
    "انكثم",
    "تكثم",
    "الأكثم",
    "الكثنة",
    "كج",
    "الكجة",
    "الكح",
    "الكحح",
    "الكحة",
    "الكحكح",
    "كحص",
    "الكاحص",
    "كحلت",
    "اكتحلت",
    "تكحلت",
    "الكحال",
    "الكحل",
    "الكحلاء",
    "الكحلة",
    "الكحلي",
    "الكحيل",
    "المكحالان",
    "المكحال",
    "المكحل",
    "المكحلة",
    "كحلل",
    "كخ كخ",
    "الكخيا",
    "كدأ",
    "الكادئة",
    "الكدب",
    "المكدوبة",
    "اكتدح",
    "تكدح",
    "كاده",
    "اكتد",
    "استكده",
    "أكداد وأكاديد",
    "الكدادة",
    "الكدود",
    "الكديد",
    "المكد",
    "المكدود",
    "انكدر",
    "تكادرت",
    "تكدر",
    "الأكدر",
    "الكدارة",
    "الكدرة",
    "الكدري",
    "تكادس",
    "تكدست",
    "الكداس",
    "الكدس",
    "أكدش",
    "اكتدش",
    "تكدش",
    "الكدش",
    "الكداش",
    "الكدشة",
    "الكديش",
    "أكدفت",
    "الكدفة",
    "كدم",
    "أكدم",
    "تكادم",
    "الكدام",
    "الكدامة",
    "الكدم",
    "المكدم",
    "الكدميوم",
    "كدن",
    "كودن",
    "الكدان",
    "الكدانة",
    "الكدن",
    "الكدنة",
    "الكودن",
    "كده",
    "أكدهه",
    "كدهه",
    "تكده",
    "كدي",
    "الكادي",
    "أكدى",
    "الكادية",
    "الكدى",
    "الكداة",
    "الكداية",
    "الكدية",
    "أكذبه",
    "كاذبت",
    "تكاذبوا",
    "تكذب",
    "التكاذيب",
    "الكاذبة",
    "الكذب",
    "الكذاب",
    "الكذوب",
    "كذ",
    "أكذ",
    "الكذان",
    "أكذى",
    "الكاذي",
    "كذا",
    "أكرب",
    "اكترب",
    "الكراب",
    "الكرابة",
    "الكربة",
    "الكروبيون",
    "المكرب",
    "كربس",
    "تكربس",
    "الكرباس",
    "الكرباسة",
    "كربش",
    "تكربش",
    "كربل",
    "الكربال",
    "الكربلة",
    "الكريت",
    "كرثه",
    "أكرثه",
    "اكترث",
    "كرج",
    "كرده",
    "الكرديد",
    "الكرديدة",
    "تكردس",
    "الكردوسة",
    "الكردان",
    "الكردون",
    "الكردينال",
    "كر",
    "تكرر",
    "الكر",
    "الكرير",
    "المكر",
    "كارزه",
    "كرزم",
    "الكرزمة",
    "انكرس",
    "تكارس",
    "تكرس",
    "الكراسة",
    "الكرس",
    "الكرساء",
    "الكرسي",
    "المكرسة",
    "كرسع",
    "الكرسوع",
    "المكرسع",
    "الكرسف",
    "الكرسفي",
    "تكرش",
    "استكرش",
    "الأكرش",
    "الكرشاء",
    "الكريش",
    "الكريشة",
    "المكرشة",
    "كرعت",
    "أكرع",
    "تكرع",
    "الكراع",
    "الكراعي",
    "كرف",
    "الكراف",
    "كرفأت",
    "تكرفأ",
    "كركت",
    "الكراكة",
    "الكرك",
    "الكركي",
    "الكريك",
    "الكركدية",
    "تكركر",
    "الكركرة",
    "كركس",
    "تكركس",
    "المكركس",
    "الكركمان",
    "المكركم",
    "كرمه",
    "أكرم",
    "كارمه",
    "تكارم",
    "استكرم",
    "الإكرامية",
    "الأكرومة",
    "التكرمة",
    "الكرام",
    "الكرامة",
    "الكرم",
    "الكريم",
    "الكريمة",
    "كرنف",
    "الكرناف",
    "الكرنافة",
    "أكرهه",
    "تكاره",
    "استكره",
    "الكرهاء",
    "المكره",
    "المكروهة",
    "أكرى",
    "كاراه",
    "اكترى",
    "تكارى",
    "استكرى",
    "الكرى",
    "الكراء",
    "الكروة",
    "الكري",
    "المكاري",
    "الكروميت",
    "الكريات الحمر",
    "كزب",
    "الكزب",
    "الكوزب",
    "المكزوبة",
    "أكزه",
    "اكتز",
    "التكزز",
    "الكازوزة",
    "كزم",
    "أكزم",
    "المنكزم",
    "كزى",
    "أكسب",
    "اكتسب",
    "الكسب",
    "الكواسب",
    "الكستبان",
    "تكوسج",
    "الكوسج",
    "اكتسح",
    "الكساحة",
    "الكسح",
    "المكسح",
    "المكسحة",
    "انكسر",
    "الكسار",
    "الكسارة",
    "الكسر",
    "الكسرة",
    "المكسرات",
    "كس",
    "تكسس",
    "الكسيس",
    "اكتسعت",
    "تكسع",
    "الأكسع",
    "الكسعة",
    "كسفت",
    "أكسف",
    "الكاسف",
    "الكسفة",
    "الكسوف",
    "الكسيفة",
    "الكسكسة",
    "الكسكسي",
    "أكسل",
    "استكسل",
    "المكسلة",
    "الأكسوم",
    "الكسم",
    "الكسوم",
    "اكتسى",
    "تكسى",
    "استكساه",
    "الكسوة",
    "كشأ",
    "كشئ",
    "أكشأ",
    "تكشأ",
    "الكشيء",
    "كاشحه",
    "انكشحوا",
    "الكاشح",
    "الكشاح",
    "الكشاحة",
    "الكشح",
    "الكشح والكشاح",
    "المكشاح",
    "كشد",
    "أكشد",
    "الكاشد",
    "الكشود",
    "كاشره",
    "الكشر",
    "الكشري",
    "كشت",
    "كشطه",
    "تكشط",
    "الكشاط",
    "أكشف",
    "اكتشفت",
    "انكشف",
    "تكاشف",
    "الكشف",
    "الكشاف",
    "الكشفاء",
    "المكشاف",
    "كشم",
    "اكتشم",
    "كشمر",
    "الكشامر",
    "كشا",
    "كص",
    "أكص",
    "الكصيص",
    "كصم",
    "كظب",
    "الكظر",
    "الكظرة",
    "كاظه",
    "اكتظ",
    "تكاظوا",
    "الكظاظ",
    "الكظ",
    "الكظة",
    "كظكظ",
    "تكظكظ",
    "الكاظم",
    "الكظام",
    "الكظامة",
    "الكظم",
    "الكظيم",
    "كظا",
    "تكظى",
    "كعبت",
    "أكعب",
    "تكعب",
    "التكعيبة",
    "الكعبة",
    "كعبره",
    "تكعبر",
    "الكعبورة",
    "كعبس",
    "أكعت",
    "الكعيت",
    "كعتر",
    "كعر",
    "أكعر",
    "الكعس",
    "كعطل",
    "كع",
    "أكع",
    "كعكع",
    "تكعكع",
    "كاعمها",
    "الكعامة والكعام",
    "كعنب",
    "الكاغد",
    "أكفأ",
    "كافأه",
    "اكتفأ",
    "انكفأ",
    "تكافأ",
    "تكفأ",
    "استكفأه",
    "الكفء",
    "الكفاء",
    "الكفاءة",
    "الكفأة",
    "الكفؤ",
    "الكفيء",
    "اكتفت",
    "انكفت",
    "تكفت",
    "الكفت",
    "الكفتة",
    "الكفيت",
    "المكفت",
    "أكفح",
    "كافحه",
    "تكافح",
    "الكفيح",
    "الكافر",
    "الكفر",
    "الكفارة",
    "المكفر",
    "المكفور",
    "انكف",
    "تكافوا",
    "تكفف",
    "استكف",
    "كافة",
    "الكفاف",
    "الكف",
    "الكفف",
    "تكفكف",
    "أكفل",
    "كافله",
    "اكتفل",
    "تكفل",
    "الكفيل",
    "الكفن",
    "اكفهر",
    "المكفهر",
    "كفاه",
    "اكتفى",
    "تكفى",
    "استكفاه",
    "الاكتفاء الذاتي",
    "الكفي",
    "الكفية",
    "كوكب",
    "الكوكب",
    "الكوكبة",
    "أكلأت",
    "كالأه",
    "اكتلأت",
    "تكلأ",
    "استكلأ",
    "الكلأ",
    "الكلأة",
    "الكلاء",
    "الكلوء",
    "لمكلأة",
    "المكلأ",
    "كالبه",
    "اكتلب",
    "استكلب",
    "الكالب",
    "الكلب",
    "الكلبية",
    "الكلبيون",
    "الكلاب",
    "الكليب",
    "المكالب",
    "المكلبة",
    "الكلتة",
    "كلثم",
    "الكلثوم",
    "أكلحه",
    "كالحه",
    "تكلح",
    "الكالح",
    "كلد",
    "تكلد",
    "الكلد",
    "اكلأز",
    "كلز",
    "الكلاس",
    "الكلاسة",
    "الكلسيت",
    "كلسم",
    "الكلسيوم",
    "كلع",
    "أكلعه",
    "الكلاع",
    "الكلاعي",
    "الكلع",
    "أكلفه",
    "تكلف",
    "التكليف",
    "التكلفة",
    "الكلاف",
    "الكلوف",
    "المتكلف",
    "المكلاف",
    "المكلف",
    "المكلفة",
    "الكلاكل",
    "الكلكال والكلكل",
    "الكلكلة",
    "اكتل",
    "انكل",
    "تكلل",
    "الكلالة",
    "الكل",
    "الكلة",
    "الكليل",
    "المكل",
    "المكلل",
    "المكللة",
    "كلمه",
    "كالمه",
    "تكالم",
    "التكلام والتكلامة",
    "الكلام",
    "الكلم",
    "الكلمة والكلمة",
    "الكليم",
    "المتكلم",
    "كلاه",
    "اكتلى",
    "الكلية",
    "كمأ",
    "كمئ",
    "أكمأ",
    "تكمأت",
    "الكمء",
    "الكماء",
    "المكمأة",
    "أكمت",
    "اكمت",
    "الكمتة",
    "الكميت",
    "كمتر",
    "أكمح",
    "الكامح",
    "الكومح",
    "أكمخ",
    "الكامخ",
    "أكمد",
    "الكماد والكمادة",
    "الكمدة",
    "الكمر",
    "كمز",
    "الكمزة",
    "كمس",
    "الكيموس",
    "الكيموسية",
    "أكمش",
    "انكمش",
    "تكمش",
    "الأكمش",
    "الانكماش",
    "الكمش",
    "الكميش",
    "كامع",
    "الكمع",
    "الكميع",
    "المكامع",
    "كمكم",
    "تكمكم",
    "الكمكام",
    "تكامل",
    "تكمل",
    "استكمل",
    "الكامل",
    "الكمل",
    "كممت",
    "تكمم",
    "الكمام",
    "الكم",
    "الكمة",
    "المكم",
    "كمنت",
    "اكتمن",
    "الكمنة",
    "المكمن",
    "الكمنجة والكمان",
    "تكمه",
    "الكامه",
    "الكمه",
    "المكمه",
    "اكمهد",
    "كمهل",
    "المكمهل",
    "كمى",
    "أكمى",
    "اكتمى",
    "انكمى",
    "تكمى",
    "كنبت",
    "أكنب",
    "الكانب",
    "الكناب",
    "الكنب",
    "الكنيب",
    "الكنبار",
    "الكنبرة",
    "اكتنت",
    "الكنتي",
    "الكنتين",
    "كند",
    "الكندة",
    "الكنود",
    "الكنادر",
    "الكندارة",
    "الكندر",
    "الكندرة",
    "كنر",
    "الكنار",
    "الكنارة",
    "اكتنز",
    "تكنز",
    "الكناز",
    "الكنز",
    "اكتنس",
    "تكنس",
    "الكنيس",
    "الكنيسة",
    "المكنس",
    "كنش",
    "الكنشاء",
    "الكناشة",
    "كنظه",
    "الكنظة",
    "كنع",
    "أكنعت",
    "اكتنع",
    "تكنع",
    "الأكنع",
    "الكانع",
    "الكنع",
    "الكنيع",
    "المكنوع",
    "أكنف",
    "كانفه",
    "اكتنف",
    "تكنفه",
    "الكانفة",
    "الكنافة",
    "الكنف",
    "الكنفاني",
    "الكنوف",
    "الكنيف",
    "المكنف",
    "كنفش",
    "الكنكان",
    "كنن",
    "اكتن",
    "الكانون",
    "الكانونة",
    "الكنان",
    "الكن",
    "الكنة",
    "الكنينة",
    "المستكنة",
    "المكنون",
    "أكنه",
    "اكتنه",
    "الكنه",
    "الكنهور",
    "كنهف",
    "أكناه وكناه",
    "اكتنى",
    "تكنى",
    "الكناية",
    "كهب",
    "اكهاب",
    "الكهب",
    "الكهبة",
    "كهد",
    "أكهد",
    "اكوهد",
    "الكهداء",
    "الكهود",
    "الكوهد",
    "كهر",
    "الكهرور والكهرورة",
    "تكهرب",
    "الكهربا",
    "اكتهف",
    "تكهف",
    "الكهف",
    "كه كه",
    "كهكه",
    "تكهكه",
    "الكهاكه",
    "الكهكاه",
    "الكهكاهة",
    "كاهل",
    "اكتهل",
    "تكهل",
    "الكهل",
    "الكهلول",
    "كهمته",
    "تكهم",
    "كهمس",
    "الكهمس",
    "كاهنه",
    "تكهن",
    "الكهنوت",
    "كه",
    "الكهة",
    "أكهى",
    "اكتهى",
    "الأكهى",
    "الأكهاء",
    "الكهاة",
    "الكوبة",
    "كوث",
    "الكوث",
    "الكوثل",
    "كاح",
    "كاوحه",
    "كوحه",
    "تكاوحا",
    "كاد",
    "الكودة",
    "المكادة",
    "كوذ",
    "الكاذان",
    "الكاذة",
    "الكوذان",
    "أكار",
    "اكتار",
    "الكارة",
    "الكوار",
    "الكوارة",
    "الكور",
    "الكورة",
    "المكور",
    "الكورمة",
    "اكتاز",
    "تكوز",
    "الكوز",
    "المكوز",
    "أكاس",
    "كوسه",
    "اكتاسه",
    "تكاوس",
    "تكوس",
    "الكاس",
    "الكوس",
    "الكوساء",
    "الكوسة",
    "الكوسي",
    "كوعه",
    "تكوعت",
    "الكاع",
    "الكوع",
    "كاف",
    "تكوف",
    "الكوفى",
    "الكوفان",
    "الكوفية",
    "الكوك",
    "الكوكايين",
    "تكول",
    "انكالوا",
    "الكولان",
    "الكولايا",
    "اكتام",
    "الأكومان",
    "الكوم",
    "كان",
    "كون",
    "اكتان",
    "الكائنة",
    "الكون",
    "الكينة",
    "المكان",
    "المكانة",
    "كاهه",
    "كوه",
    "تكوهت",
    "تكوى",
    "الكو",
    "كاواه",
    "اكتوى",
    "استكوى",
    "الكاوياء",
    "الكواء",
    "الكية",
    "كاء",
    "أكاءه",
    "الكاء",
    "الكيء والكيء",
    "كيت",
    "كيت وكيت",
    "كيح",
    "أكاح",
    "الكاح",
    "الكيح",
    "كايده",
    "اكتاده",
    "تكايد",
    "الكيد",
    "الكياد",
    "تكاير",
    "الكير",
    "الكيروسين",
    "أكاس وأكيس",
    "كايسه",
    "تكيس",
    "الكوسى",
    "الكيس",
    "كيسان",
    "المكياس",
    "كاص",
    "كايصه",
    "الكيص",
    "كيف",
    "انكاف",
    "الكيفية",
    "مكيف الهواء",
    "كيل وكول",
    "كايلت",
    "اكتال",
    "تكايل",
    "الكيالة",
    "الكيل",
    "الكيلة",
    "الكيلجة",
    "الكيول",
    "المكيال",
    "الكيلوس",
    "الكيميائي والكيمياوي",
    "أكانه",
    "المكتان",
    "الكيه",
    "كيهك",
    "الكيا",
    "اللام",
    "اللام المفردة",
    "العاملة للجر",
    "العاملة للجزم",
    "لا",
    "اللازورد",
    "لأطه",
    "لأف",
    "ألاكه",
    "استلأك",
    "الملأك",
    "الملأكة",
    "اللآء",
    "اللأل",
    "اللئالة",
    "اللألاء",
    "اللؤلؤ",
    "اللؤلؤان",
    "لأمه",
    "ألأم",
    "لاءمه",
    "التأم",
    "تلاءم",
    "تلأم",
    "استلأم",
    "اللؤم",
    "اللأم",
    "اللئم",
    "اللأمان واللؤمان",
    "اللأمة",
    "اللؤمة",
    "اللئيم",
    "اللمة",
    "ألأى",
    "لأى",
    "التأى",
    "اللأواء",
    "اللأي",
    "اللاءون",
    "لبأ",
    "ألبأ",
    "لبأت",
    "التبأ",
    "اللبؤة",
    "تلبب",
    "استلب",
    "اللباب",
    "اللبب",
    "اللبة",
    "اللبيب",
    "اللبيبة",
    "لبيك ولبيه",
    "المتلبب",
    "الملبوب",
    "ألبثه",
    "تلبث",
    "استلبثه",
    "اللبثة",
    "اللبيثة",
    "لبج",
    "اللبيج",
    "تلبخ",
    "اللبخ",
    "اللبخة",
    "اللبيخة",
    "ألبد",
    "التبد",
    "اللابد",
    "اللبادى",
    "اللباد",
    "اللبادة",
    "اللبد",
    "اللبدة",
    "لبز",
    "لابسه",
    "التبس",
    "اللباس",
    "اللباسة",
    "اللبس",
    "اللبسة",
    "اللبوس",
    "اللبوسة واللبوسة",
    "الملبس",
    "التبط",
    "تلبط",
    "اللبطة",
    "ألبك",
    "التبك",
    "تلبك",
    "التلبك",
    "اللبك",
    "اللبكة",
    "اللبيك",
    "اللبيكة",
    "لبالب",
    "اللبلب",
    "لبنه",
    "لبنت",
    "ألبنت",
    "تلبن",
    "استلبن",
    "التلبينة",
    "اللابن",
    "اللبانة",
    "اللبن",
    "اللبون",
    "اللبين",
    "الملبن",
    "الملبنة",
    "لبى",
    "لتأه",
    "لتب",
    "ألتب",
    "التتب",
    "الملاتب",
    "الملتب",
    "اللات واللات",
    "اللتات",
    "لتحه",
    "لتح",
    "اللاتح",
    "اللتاح",
    "اللتلتة",
    "لتم",
    "التي اللت اللت",
    "التي واللتيا",
    "لث",
    "ألث",
    "تلاثغ",
    "لثق",
    "ألثق",
    "التثق",
    "تلثق",
    "اللثق",
    "لثلث",
    "تلثلث",
    "اللثلاث واللثلاثة",
    "التثمت",
    "تلاثما",
    "تلثمت",
    "الملثم",
    "لثبت",
    "ألثت",
    "تلثى",
    "اللثى",
    "اللثاة",
    "لجأ",
    "ألجأ",
    "لجأه",
    "التجأ",
    "تلجأ",
    "اللاجئ",
    "اللجأ",
    "اللجب",
    "ألج",
    "لججت",
    "التج",
    "تلجج",
    "الألنجج",
    "اللجاج",
    "اللجاجة",
    "اللجة",
    "اللجي",
    "اللجج",
    "اللجح",
    "لجذ",
    "الملجاذ",
    "الملجوذ",
    "لجف",
    "لجفت",
    "تلجفت",
    "اللجاف",
    "اللجف",
    "اللجفة",
    "تلجلج",
    "اللجلاج",
    "اللجلج",
    "ألجم",
    "لجمه",
    "اللجمة",
    "تلجن",
    "اللجين",
    "التحب",
    "اللاحب",
    "اللحب",
    "الملحب",
    "لحوج",
    "ألحجه",
    "التحج",
    "تلحج",
    "استلحج",
    "اللحج",
    "الملحج",
    "الملحوجة",
    "لحت",
    "لححت",
    "تلحح",
    "اللاح",
    "اللحة",
    "اللحوح",
    "الملحاح",
    "ألحد",
    "التحد",
    "اللاحد",
    "اللحد",
    "لحزه",
    "لحز",
    "تلاحز",
    "تلحز",
    "الملحز",
    "ألحست",
    "التحس",
    "اللاحسة",
    "اللاحوس",
    "اللحسة",
    "اللحوس",
    "الملحس",
    "لحص",
    "لحصت",
    "التحص",
    "لحاص",
    "الملحص",
    "لحط",
    "التحط",
    "لحظه",
    "لاحظه",
    "تلاحظا",
    "اللحاظ",
    "الملحظ",
    "الملحوظة",
    "ألحف",
    "لاحفه",
    "تلحف",
    "الملحف",
    "الملحفة",
    "التحق",
    "تلاحقت",
    "استلحق",
    "اللاحق",
    "اللحق",
    "لاحك",
    "تلاحك",
    "لحلح",
    "تلحلح",
    "اللحلح",
    "ألحم",
    "التحم",
    "تلاحمت",
    "استلحم",
    "الالتحام",
    "اللاحم",
    "اللحامة",
    "اللحم",
    "اللحمة",
    "المتلاحمة",
    "الملتحم",
    "الملحم",
    "ألحن",
    "لاحنه",
    "اللحن",
    "اللحنة",
    "الملاحن",
    "ألحى",
    "لاحاه",
    "التحى",
    "تلاحى",
    "تلحى",
    "الألحى",
    "اللحي",
    "اللحياني",
    "اللحية",
    "الملحاة",
    "لخ",
    "لخخت",
    "تلاخ",
    "اللخة",
    "اللخوخ",
    "لخصت",
    "اللخص",
    "اللخصة",
    "لخف",
    "اللخافة",
    "اللخف",
    "اللخفة",
    "اللخلخاني",
    "اللخلخانية",
    "لخم",
    "لاخمه",
    "اللخم",
    "اللخمة",
    "اللخن",
    "لخاه",
    "لخي",
    "ألخاه",
    "التخى",
    "اللخا واللخاء",
    "اللخاء",
    "الملخى والملخاء",
    "ألده",
    "لاده",
    "لدده",
    "التد",
    "تلدد",
    "الألد",
    "اللدد",
    "اللدود",
    "اللديد",
    "اللديدة",
    "المتلدد",
    "لدسه",
    "ألدست",
    "لدس",
    "اللديس",
    "الملدس",
    "لدغته",
    "ألدغ",
    "اللداغة",
    "الملدغ",
    "لدم",
    "ألدمت",
    "التدم",
    "تلدم",
    "اللدام",
    "اللدم",
    "اللديم",
    "الملدام",
    "الملدم",
    "تلدن",
    "اللدن",
    "اللدنة",
    "اللدينة",
    "اللدة",
    "لدى",
    "لذجه",
    "لذذه",
    "التذ",
    "تلاذ",
    "تلذذ",
    "استلذ",
    "اللذ",
    "اللذة",
    "الملذ",
    "الملذة",
    "التذع",
    "تلذعت",
    "اللذاع",
    "اللذع",
    "اللوذع واللوذعي",
    "لذلذ",
    "لذمه",
    "ألذم",
    "اللذمة",
    "اللاذن",
    "لذي",
    "الذي",
    "تلازب",
    "اللزب",
    "اللزبة",
    "تلزج",
    "لزه",
    "ألز",
    "لازه",
    "لززه",
    "التز",
    "تلزز",
    "اللزاز",
    "اللز",
    "اللزز",
    "الملز",
    "ألزقه",
    "لازقه",
    "التزق",
    "تلازق",
    "اللازوق",
    "اللزاق",
    "اللزق",
    "اللزقاء",
    "اللزقة",
    "اللزيقى",
    "اللزوق",
    "اللزيق",
    "لازمه",
    "التزم",
    "استلزم",
    "اللازمة",
    "اللزام",
    "اللزمة",
    "الملتزم",
    "لزن",
    "تلازن",
    "الألزن",
    "اللزن",
    "اللزنة",
    "لسبته",
    "لسب",
    "ألسبه",
    "اللسوب",
    "لسد",
    "الملسد",
    "لس",
    "ألست",
    "اللساس",
    "اللس",
    "اللسس",
    "لسعته",
    "ألسعه",
    "اللساع",
    "اللسعة",
    "اللسوع",
    "الملسع",
    "لسق",
    "اللسوق",
    "لاسنه",
    "اللسن",
    "الملسون",
    "لشلش",
    "اللشلش",
    "لشا",
    "لاشاه",
    "لصب",
    "التصب",
    "اللاصبة",
    "اللصب",
    "تلصص",
    "الملصة",
    "لصغ",
    "لصف",
    "اللاصف",
    "اللصف",
    "لاصقة",
    "التصق",
    "تلاصقا",
    "الالتصاق",
    "الالتصاقية",
    "اللاصوق",
    "اللصوق",
    "اللصيق",
    "لصلص",
    "لضلض",
    "اللضلاض",
    "لضمه",
    "لطأ",
    "لطئ",
    "اللاطئة",
    "لطث",
    "تلاطث",
    "الملاطث",
    "لطخه",
    "اللطخ",
    "اللطيخ",
    "اللطوخ",
    "لطسه",
    "لاطسه",
    "تلاطست",
    "الملطاس",
    "الملطس",
    "لط",
    "ألط",
    "التطت",
    "تلطط",
    "اللاط",
    "الملطاط",
    "لطعت",
    "التطع",
    "اللطع",
    "اللطعة",
    "تلاطف",
    "استلطف",
    "اللطفة",
    "اللطيف",
    "اللطيفة",
    "اللواطف",
    "لطمه",
    "لاطمه",
    "التطمت",
    "تلاطمت",
    "تلطم",
    "اللطيم",
    "اللطيمة",
    "الملطم",
    "لطا",
    "لطى",
    "تلطى",
    "اللطاة",
    "الملطى والملطاء والملطاة",
    "لظ",
    "ألظ",
    "لاظ",
    "تلاظوا",
    "تلظظت",
    "اللظ",
    "الملظاظ",
    "الملظ",
    "الملظة",
    "لظلظت",
    "تلظلظت",
    "اللظلاظ",
    "لظيت",
    "التظت",
    "تلظت",
    "اللظى",
    "ألعب",
    "لاعبه",
    "تلعب",
    "الألعبان",
    "الألعوبة",
    "التلعاب",
    "التلعابة",
    "الملعبة",
    "ألعج",
    "لاعجه",
    "التعج",
    "اللاعج",
    "لعسه",
    "لعست",
    "تلعس",
    "اللعس",
    "اللعسة",
    "اللعوس",
    "الملعوس",
    "لعص",
    "تلعص",
    "لعطه",
    "ألعط",
    "اللعط",
    "اللعطاء",
    "اللعطة",
    "ألعت",
    "تلعى",
    "اللعاع",
    "اللعاعة",
    "اللعة",
    "ألعقه",
    "لعقه",
    "التعق",
    "اللعاق",
    "اللعوق",
    "لعل",
    "تلعلع",
    "اللعلاع",
    "اللعلع",
    "اللعلعة",
    "لعمظ",
    "اللعماظ",
    "اللعمظ",
    "اللعموظ واللعموظة",
    "لعنه",
    "لاعن",
    "التعن",
    "تلاعنا",
    "تلعن",
    "اللعان",
    "اللعن",
    "اللعنة",
    "الملعنة",
    "ألعى",
    "الألعاء",
    "اللاعي",
    "اللاعية",
    "اللعا",
    "اللعو",
    "اللعوة",
    "ألغبه",
    "لغبه",
    "تلغبه",
    "اللغب",
    "اللغوب",
    "لغده",
    "لاغده",
    "التغده",
    "تلغد",
    "اللغدود",
    "اللغديد",
    "ألغز",
    "لاغزه",
    "الألغوزة",
    "اللغاز",
    "لغوس",
    "اللغوس",
    "ألغط",
    "اللغط",
    "لغف",
    "ألغف",
    "لاغفه",
    "تلغف",
    "اللغفة",
    "اللغيف",
    "اللغيفة",
    "الملغفة",
    "لغلغ",
    "اللغلغة",
    "ألغم",
    "لغمه",
    "التغم",
    "تلغم",
    "اللغام",
    "اللغيم",
    "الملغم",
    "الغان",
    "اللغن",
    "اللغنون",
    "لغا",
    "لغى",
    "لاغاه",
    "استلغاه",
    "اللاغية",
    "اللغا",
    "اللغة",
    "اللغو",
    "لفأ",
    "لفئ",
    "ألفأ",
    "التفأ",
    "اللفاء",
    "اللفأة",
    "اللفيئة",
    "التفت",
    "اللفوت",
    "اللفيتة",
    "المتلفتة",
    "ألفج",
    "استلفج",
    "اللفج",
    "لفحته",
    "اللفح",
    "اللفاح",
    "لفخه",
    "التلفظ",
    "اللافظة",
    "اللفاظ",
    "اللفاظة",
    "اللفظ",
    "اللفيظ",
    "الملفظ",
    "التفع",
    "تلفع",
    "اللفاعة",
    "اللفيعة",
    "الملفعة",
    "لففه",
    "تلافوا",
    "تلفف",
    "التلافيف",
    "اللفافة",
    "الملفاف",
    "تلافق",
    "تلفق",
    "التلفاق",
    "اللفاق",
    "اللفق",
    "تلفلف",
    "اللفلاف",
    "اللفلف",
    "لفاه",
    "ألفاه",
    "تلافى",
    "التلافي",
    "اللفى",
    "لقبه",
    "تلاقب",
    "تلقب",
    "لقحت",
    "ألقحت",
    "استلقحت",
    "اللقاح",
    "اللقاحية",
    "اللقح",
    "اللقحة",
    "الملقح",
    "لقسه",
    "لقست",
    "لاقسه",
    "تلاقس",
    "تلقست",
    "اللاقس",
    "اللقس",
    "لقص",
    "التقص",
    "اللقص",
    "لاقطه",
    "التقط",
    "تلقط",
    "الألقاط",
    "اللاقط",
    "اللاقطة",
    "اللقاط",
    "اللقاطة",
    "اللقط",
    "اللقطة",
    "اللقيط",
    "اللقيطة",
    "الملقاط",
    "الملقوط",
    "التلقاع",
    "اللقعة",
    "اللقاع",
    "اللقاعة",
    "الملقاع",
    "التقف",
    "اللقف",
    "اللقيف",
    "لق",
    "اللق",
    "اللقاق",
    "تلقلق",
    "اللقلاق",
    "اللقلقة",
    "ألقمه",
    "لقمه",
    "التقم",
    "تلقم",
    "التلقام والتلقامة",
    "اللقمة",
    "اللقيم",
    "لقنه",
    "تلقن",
    "اللقن",
    "لقاه",
    "لقي",
    "اللقوة",
    "لقيه",
    "ألقى",
    "لاقاه",
    "التقيا",
    "تلاقيا",
    "تلقت",
    "استلقى",
    "الألقية",
    "التلاقي",
    "التلقاء",
    "اللقى",
    "اللقاة",
    "اللقي",
    "الملقى",
    "لكأه",
    "لكثه",
    "لكث",
    "اللكاث",
    "اللكائي",
    "اللكث",
    "لكد",
    "لاكد",
    "التكده",
    "تلكد",
    "الألكد",
    "الملكد",
    "لكزه",
    "لاكزه",
    "تلاكزا",
    "اللكاز",
    "الملكز",
    "لكشه",
    "لكع",
    "لكاع",
    "اللكع",
    "اللكعة",
    "الملكعان",
    "لكه",
    "اللكاك",
    "اللكيك",
    "لكمه",
    "لاكمه",
    "تلاكما",
    "التكم",
    "الملكم",
    "لكن",
    "تلاكن",
    "لكي",
    "اللاكي",
    "لمأه",
    "ألمأ",
    "التمأ",
    "تلمأت",
    "الملمؤة",
    "تلمج",
    "اللماج",
    "اللمج",
    "اللمجة",
    "اللموج",
    "اللميج",
    "ألمح",
    "لامحه",
    "التمحه",
    "الألمحي",
    "اللامح",
    "لمزه",
    "لامزه",
    "اللمزة",
    "اللماز",
    "لمسه",
    "لامسه",
    "الالتماس",
    "اللماسة",
    "اللمس",
    "اللموس",
    "اللميس",
    "ألمص",
    "اللامص",
    "اللمص",
    "اللموص",
    "ألمظه",
    "لمظه",
    "التمظ",
    "اللماظ",
    "اللماظة",
    "اللمظة",
    "المتلمظ",
    "التمع",
    "تلمع",
    "الألمع",
    "الألمعي",
    "اللامع",
    "اللامعة",
    "اللماع",
    "اللمعة",
    "اللماعة",
    "اللموع",
    "الملمعة",
    "اليلمع",
    "اليلمعي",
    "لمق",
    "تلمق",
    "اللماق",
    "اليلمق",
    "لمك",
    "تلمك",
    "اللماك",
    "اللمك",
    "اللميك",
    "تلملم",
    "اللملم",
    "اللملوم",
    "الململم",
    "الململمة",
    "لمم",
    "اللامة",
    "اللمام",
    "الملم",
    "الملمة",
    "الملموم",
    "الوجه الأول",
    "الوجه الثاني",
    "الوجه الثالث",
    "لمى",
    "لمي",
    "تلمت",
    "الألمى",
    "اللمى",
    "اللمياء",
    "لهب",
    "التهبت",
    "تلهبت",
    "الألهوب",
    "اللهاب",
    "اللهابة",
    "اللهبان",
    "اللهبة",
    "اللهيب",
    "الملهب",
    "لهث",
    "التهث",
    "اللهاث",
    "اللهاثي",
    "اللهثة",
    "لهج",
    "ألهج",
    "لهجه",
    "لهوج",
    "تلهوج",
    "الهاج",
    "الملهج",
    "لهده",
    "ألهد",
    "اللهد",
    "الملهد",
    "لهذم",
    "تلهذم",
    "اللهاذمة",
    "اللهذم",
    "لهز",
    "اللاهز",
    "اللهاز",
    "اللهز",
    "اللهزة",
    "الملهز",
    "الملهوز",
    "لهزم",
    "اللهزمة",
    "لهس",
    "لاهس",
    "اللهاس",
    "اللهاسة",
    "اللهسة",
    "لهسم",
    "اللهسم",
    "لهطت",
    "اللاهط",
    "لهع",
    "تلهيع",
    "اللهاعة",
    "اللهيعة",
    "لهف",
    "ألهف",
    "تلهف",
    "اللهف",
    "اللهفان",
    "اللهيف",
    "الملهوف",
    "لهق",
    "لهوق",
    "تلهق",
    "تلهوق",
    "اللهاق",
    "الملهق",
    "لهم",
    "ألهمه",
    "تلهم",
    "استلهم",
    "اللهام",
    "اللهمة",
    "اللهوم",
    "اللهيم",
    "اللهمم",
    "اللهموم",
    "اللهميم",
    "ألهنه",
    "لهنه",
    "تلهن",
    "اللهنة",
    "لها",
    "ألهى",
    "لاهى",
    "لهاه",
    "التهى",
    "تلاهى",
    "استلهى",
    "الألهوة",
    "الألهية",
    "التلهية",
    "اللهاء",
    "اللهاة",
    "اللهو",
    "اللهوة",
    "الملهاة",
    "لو",
    "لآب",
    "ألاب",
    "لوب",
    "اللابة",
    "اللوب",
    "اللوبة",
    "اللوبيا",
    "الملاب",
    "الملوب",
    "ألاث",
    "ألوثت",
    "التاث",
    "اللواث",
    "اللواثة",
    "اللوث",
    "اللوثة",
    "اللويثة",
    "الليث",
    "المليث",
    "اللوجاء",
    "لاح",
    "ألاح",
    "التاح",
    "تلوح",
    "استلاح",
    "اللوح",
    "اللياح",
    "الملواح",
    "الملوح",
    "الملياح",
    "لاخه",
    "التاخ",
    "اللواخة",
    "لود",
    "الألود",
    "لاذ",
    "ألاذ",
    "لاوذ",
    "التلواذ",
    "اللاذ",
    "اللوذ",
    "لوذان",
    "الملاوذ",
    "الملوذ",
    "لاز",
    "اللوزين",
    "اللوزينج",
    "اللواز",
    "الملاز",
    "الملازة",
    "الملوز",
    "اللواس",
    "اللواسة",
    "اللوس",
    "لاص",
    "ألاصه",
    "لاوصه",
    "تلاوص",
    "تلوص",
    "اللواص",
    "اللوص",
    "اللوصة",
    "الملوص",
    "لاط",
    "لاوط",
    "لوطه",
    "التاط",
    "استلاط",
    "اللوط",
    "اللوطية",
    "اللويطة",
    "لاظه",
    "التاظت",
    "لاعت",
    "ألاع",
    "لوعه",
    "التاع",
    "اللاعة",
    "اللوعة",
    "اللوفة",
    "اللواف",
    "الملوف",
    "لاق",
    "لوق",
    "اللواق",
    "اللوق",
    "اللوقة",
    "لاكه",
    "اللواك",
    "لولا",
    "اللولاء",
    "الملولب",
    "لامه",
    "ألام",
    "لاومه",
    "لومه",
    "التام",
    "تلاوموا",
    "تلوم",
    "اللائمة",
    "اللومى واللوماء",
    "اللومة",
    "المتلوم",
    "الملام والملامة",
    "الون",
    "اللون",
    "الملونون",
    "تلوه",
    "اللاهة",
    "لوه",
    "ألوى",
    "اللو",
    "اللوة",
    "لاوت",
    "تلاووا",
    "تلوى",
    "استلوى",
    "الالتواء",
    "اللأوياء",
    "اللوى",
    "اللواء",
    "اللوية",
    "اللياء",
    "الليان",
    "الملاوي",
    "لاته",
    "ألاته",
    "الليت",
    "ليت",
    "لايثه",
    "ليث",
    "تليث",
    "استليث",
    "الأليث",
    "اللائث",
    "الليثة",
    "ليس",
    "تلايس",
    "الأليس",
    "الملايس",
    "ليسض",
    "ألاص",
    "ألاطه",
    "ليطه",
    "اللياط",
    "الليط",
    "الليطة",
    "اللياع",
    "ليعة",
    "الملياع",
    "لاغه",
    "تليغ",
    "الأليغ",
    "اللائغ",
    "اللياغة",
    "الليغ",
    "ليفت",
    "الليفاني",
    "لاقت",
    "ألاق",
    "التاق",
    "استلاقه",
    "اللياق",
    "الليق",
    "الليقة",
    "الملتاق",
    "ألال",
    "لايله",
    "اللائل",
    "الليل",
    "الليلة",
    "الليلاء",
    "المليل",
    "الليم",
    "الليمونيت",
    "في الجيولوجيا",
    "لان",
    "لاينه",
    "استلان",
    "الألين",
    "اللينة",
    "الملينة",
    "الملين",
    "الميم",
    "ما",
    "مأج",
    "مؤج",
    "المأج",
    "مأد",
    "أمأد",
    "امتأد",
    "المأد",
    "المئيد",
    "مأر",
    "مئر",
    "امأر",
    "ماءر",
    "المئر",
    "المئرة",
    "مأس",
    "مئس",
    "المأس",
    "مأش",
    "المئط",
    "أمأق",
    "امتأق",
    "المأق والماق",
    "المؤق والموق",
    "المأقة",
    "مأمأت",
    "المأن",
    "المأنة",
    "المؤنة",
    "الممأنة",
    "مأى",
    "أمأى",
    "ماءى",
    "تماءى",
    "المائة",
    "تمتى",
    "الماتة",
    "المتات",
    "أمتح",
    "امتتح",
    "تمتحت",
    "المتوح",
    "متخ",
    "تماترت",
    "امتتر",
    "متش",
    "متشت",
    "المتش",
    "أمتع",
    "تمتع",
    "الماتع",
    "المتاع",
    "المتعة",
    "متك",
    "ماتكه",
    "تمتك",
    "متل",
    "أمتن",
    "ماتنه",
    "تماتن",
    "التمتان",
    "الماتن",
    "المتن",
    "المتنة",
    "مته",
    "تماته",
    "تمته",
    "متا",
    "أمتى",
    "متى",
    "مث",
    "المثاث",
    "مثج",
    "مثد",
    "الماثد",
    "امتثل",
    "الأمثولة",
    "التمثيل",
    "المثال",
    "المثل",
    "المثلة",
    "مثمت",
    "مثنه",
    "مثن",
    "مجج",
    "انمجت",
    "المجاج",
    "المجاجة",
    "أمجده",
    "ماجدهم",
    "مجده",
    "تماجدوا",
    "تمجد",
    "استمجد",
    "الماجد",
    "المجد",
    "المجيد",
    "أمجرت",
    "الأمجر",
    "الممجر",
    "مجسه",
    "تمجس",
    "المجوس",
    "المجوسي",
    "المجوسية",
    "المجسطي",
    "مجع",
    "ماجعه",
    "امتجع",
    "تماجعا",
    "تمجع",
    "المجعة",
    "المجيع",
    "مجلت",
    "أمجل",
    "تمجل",
    "المجل",
    "مجمج",
    "تمجمج",
    "المجماج",
    "ماجنه",
    "تماجنا",
    "المجان",
    "الممجن",
    "مجنق",
    "محته",
    "محت",
    "المحت",
    "ماحجه",
    "المحاح",
    "أمحش",
    "أمحصت",
    "تمحصت",
    "الأمحص",
    "الممحص",
    "محض",
    "أمحض",
    "امتحض",
    "الأمحوضة",
    "المحض",
    "أمحق",
    "امتحق",
    "انمحق وامحق",
    "تمحق",
    "المحاق",
    "المحقة",
    "المحيق",
    "أمحكه",
    "ماحكه",
    "تماحك",
    "تمحك",
    "المحكان",
    "محل",
    "أمحل",
    "ماحله",
    "تماحل",
    "الماحل",
    "الممحال",
    "الممحل",
    "الممحلة",
    "تمحى",
    "الممحاة",
    "محى",
    "أمخ",
    "مخخ",
    "امتخ",
    "تمخخ",
    "المخاخة",
    "المخة",
    "الممخ",
    "مخرت",
    "امتخر",
    "الماخرة",
    "الماخور",
    "المخر",
    "اليمخور",
    "مخضت",
    "أمخض",
    "امتخض",
    "تمخض",
    "الممخضة",
    "أمخط",
    "امتخط",
    "تمخط",
    "المخاطة",
    "المخطة",
    "مخمخ",
    "مخن",
    "المخن",
    "الممخن",
    "مخى",
    "تمخى",
    "مدحه",
    "امتدح",
    "تمادحا",
    "تمدحت",
    "الأمدوحة",
    "المدحة",
    "الممادح",
    "مدخ",
    "مادخه",
    "امتدخ",
    "المديخ",
    "ماده",
    "امتد",
    "تمادا",
    "استمد",
    "الإمدان",
    "الأمدة",
    "الأمدود",
    "المادة",
    "المداد",
    "المد",
    "المدد",
    "المدة",
    "الممدود",
    "أمدر",
    "الأمدر",
    "المدر",
    "الممدرة والممدرة",
    "مدش",
    "تمدل",
    "تمدن",
    "المدينة",
    "أمدى",
    "ماداه",
    "تمادى",
    "المدية",
    "المدي",
    "الميداء",
    "مذح",
    "تمذحت",
    "الأمذح",
    "مذرت",
    "أمذرت",
    "تمذرت",
    "المذر",
    "مذرق",
    "الماذريون",
    "مذع",
    "تمذع",
    "المذاع",
    "امتذق",
    "امذق",
    "المذق",
    "المذقة",
    "أمذل",
    "امذل",
    "المذيل",
    "الممذل",
    "مذمذ",
    "المذماذ",
    "المذمذي",
    "مذى",
    "أمذى",
    "ماذى",
    "الماذي",
    "الماذية",
    "المذاء",
    "المذي",
    "المذية",
    "مرؤت",
    "أمرأ",
    "تمرأ",
    "استمرأ",
    "المرء",
    "المروءة",
    "مرت",
    "ماروت",
    "المرت",
    "المروت",
    "مرثه",
    "الممرث",
    "الممرثة",
    "أمرج",
    "المارج",
    "المريج",
    "الممراج",
    "أمرح",
    "التمراحة",
    "المرحة",
    "الممراح",
    "أمرخ",
    "تمرخ",
    "الأمرخ",
    "المرخ",
    "المروخ",
    "امرخد",
    "التمراد",
    "المارد",
    "المراد",
    "المرداء",
    "المريد",
    "المردقوش",
    "مردل",
    "امتر",
    "استمر",
    "الأمران",
    "المارورة",
    "المرار",
    "المرة",
    "المري",
    "المريراء",
    "المريرة",
    "مرز",
    "امترز",
    "المرز",
    "المرزتان",
    "أمرس",
    "امترس",
    "تمارس",
    "الأمرس",
    "المارستان",
    "المراسة",
    "المرس",
    "المرسة",
    "المريس",
    "امترش",
    "الأمرش",
    "المراشة",
    "المرشاء",
    "تمرص",
    "مارضت",
    "التمريض",
    "المراض",
    "المرض",
    "المريض",
    "الممراض",
    "الممروض",
    "أمرط",
    "مارطه",
    "امترط",
    "انمرط الشعر وامرط",
    "تمرط",
    "المراطة",
    "المرط",
    "المرطاء",
    "المريطاء",
    "المريطاوان",
    "المريطى",
    "مرطل",
    "أمرع",
    "تمرع",
    "الأمروعة",
    "المراع",
    "المرع",
    "المريع",
    "الممراع",
    "مرغت",
    "أمرغ",
    "مارغه",
    "مرغه",
    "تمرغت",
    "المراغ",
    "المراغة",
    "المرغ",
    "المرغة",
    "الممرغة",
    "مرقت",
    "أمرق",
    "امترق",
    "امرق وانمرق",
    "تمرق",
    "المارق",
    "المراق",
    "المراقة",
    "المريق",
    "المرقة",
    "الممراق",
    "الممرق",
    "المركيركروم",
    "تمرمر",
    "المرامر",
    "المرمرة",
    "المرمريس",
    "أمرن",
    "مارنت",
    "المارن",
    "المران",
    "مرهت",
    "الأمره",
    "المره",
    "المرهاء",
    "المرهة",
    "المرو",
    "المروة",
    "ماراه",
    "امترى",
    "تمارى",
    "تمرى",
    "المارية",
    "الماري",
    "المرية",
    "الممري",
    "مازجه",
    "أمزح",
    "مازحه",
    "تمازحا",
    "المزدة",
    "الأمزر",
    "المزرة",
    "المازريون",
    "مززه",
    "تمزز",
    "المازة",
    "المز",
    "المزز",
    "المزاء",
    "المزة",
    "المزيز",
    "تمزع",
    "المزاعة",
    "المزاع",
    "المزعة",
    "المزعي",
    "مازقه",
    "تمزق",
    "المزاق",
    "المزق",
    "المزقة",
    "المزيق",
    "مزمزه",
    "تمزمز",
    "مزنه",
    "تمزن",
    "المازن",
    "المزنة",
    "مزا",
    "أمزاه",
    "مزاه",
    "تمازى",
    "تمزى",
    "المازي",
    "المازية",
    "المتمازي",
    "المزو والمزي",
    "المزي",
    "مسأ",
    "أمسأ",
    "المسء",
    "ماسحه",
    "امتسح",
    "انمسح وامسح",
    "تماسح",
    "تمسح",
    "الأمسح",
    "الأمسوح",
    "التمسح",
    "المسح",
    "المسحاء",
    "المسيحة",
    "الممسح",
    "الممسوح",
    "مسخه",
    "أمسخ",
    "امتسخ",
    "امسخت",
    "المسيخ",
    "الممسوخ",
    "المساد",
    "المسداء",
    "الممسود",
    "ماسه",
    "المس",
    "المسوس",
    "مسيس",
    "مسط",
    "الماسط",
    "المسيط",
    "المسيطة",
    "المسع",
    "المسعي",
    "امتسك",
    "الإمساك",
    "المساك",
    "المساكة",
    "المسيك",
    "المسك",
    "المسكان",
    "المسيكة",
    "امتسل",
    "المسالة",
    "الميسون",
    "مسا",
    "أمسى",
    "ماساه",
    "مسى",
    "امتسى",
    "المساء",
    "تماسى",
    "تمسى",
    "التماسي",
    "الماسي",
    "المشج والمشيج",
    "أمشحت",
    "مشر",
    "أمشر",
    "امتشر",
    "تمشر",
    "الأمشر",
    "الماشرة",
    "المشر",
    "المشرة",
    "مششت",
    "أمش",
    "مشش",
    "امتش",
    "المشاش",
    "المشاشة",
    "المشش",
    "مشطت",
    "امتشطت",
    "الماشطة",
    "المشاطة",
    "المشاط",
    "المشط",
    "المشطة",
    "المشيط",
    "الممشط",
    "الممشوط",
    "مشظ",
    "المشظ",
    "المشظة",
    "امتشع",
    "تمشع",
    "المشوع",
    "مشغ",
    "المشغ",
    "مشقت",
    "أمشقه",
    "ماشقه",
    "امتشق",
    "تماشقوا",
    "تمشق",
    "الأمشق",
    "المشاقة",
    "المشق",
    "المشيق",
    "الممشوق",
    "مشل",
    "مشلت",
    "امتشل",
    "مثلث الميمين",
    "مشنه",
    "مشنت",
    "امتشن",
    "تماشنا",
    "المشان",
    "المشنى",
    "أمشى",
    "استمشى",
    "المشا",
    "المشاء والمشو والمشو",
    "مشى",
    "ماشاه",
    "امتشى",
    "تماشى",
    "الماشية",
    "المشاء",
    "أمصح",
    "المصاحة",
    "مصخ",
    "امتصخ",
    "امصخ",
    "مصد",
    "المصدة",
    "مصرت",
    "أمصرت",
    "تمصر",
    "امتصر",
    "الماصر",
    "المصارة",
    "المصر",
    "الممصر",
    "الممصرة",
    "أمصه",
    "تمصص",
    "المصان",
    "المصيصة",
    "الممصوص",
    "الممصوصة",
    "مصطك",
    "المصطكا والمصطكاء",
    "مصع",
    "أمصع",
    "ماصع",
    "انمصع",
    "تماصعوا",
    "الماصع",
    "المصع",
    "المصعة",
    "المصوع",
    "أمصلت",
    "الماصل",
    "المصالة",
    "الممصل",
    "المصامص",
    "مضحت",
    "مضر",
    "مضره",
    "تمضر",
    "المضارة",
    "المضر",
    "المضيرة",
    "مضه",
    "أمضه",
    "ماضه",
    "تماض",
    "المضاض",
    "المض",
    "المضض",
    "المضة",
    "أمضغ",
    "ماضغه",
    "مضغه",
    "الماضغة",
    "المضاغ",
    "المضاغة",
    "المضيغة",
    "تمضمض",
    "المضماض",
    "أمضى",
    "تمضى",
    "الماضي",
    "المضواء",
    "مطحه",
    "امتطح",
    "تمطح",
    "مطخ",
    "الماطخ",
    "المطاخ",
    "مطرت",
    "أمطرت",
    "تماطر",
    "تمطر",
    "استمطر",
    "الماطر",
    "المستمطر",
    "المطر",
    "المطرة",
    "المطير",
    "الممطار",
    "الممطور",
    "المطيطاء",
    "المطيطي",
    "المطيطة",
    "مطع",
    "تمطق",
    "المطقة",
    "ماطله",
    "امتطل",
    "المطالة",
    "المطال",
    "الممطل",
    "مطمط",
    "أمطى",
    "امتطى",
    "تمطى",
    "الأمطى",
    "المطا",
    "المطو",
    "المطواء",
    "المطوة",
    "المطية",
    "مظه",
    "أمظه",
    "ماظه",
    "تماظوا",
    "المظاظة",
    "مظع",
    "تمظع",
    "المظعة",
    "تمعج",
    "المعجة",
    "الممعج",
    "امتعد",
    "المتمعدة",
    "المعد",
    "المعدة",
    "تمعدد",
    "أمعر",
    "تمعر",
    "المعر",
    "أمعز",
    "استمعز",
    "الأمعز",
    "الأمعوز",
    "المعزي والمعزى",
    "المعاز",
    "تمعس",
    "معص",
    "المعص",
    "أمعضه",
    "معضه",
    "امتعض",
    "امتعط",
    "تمعط",
    "الأمعط",
    "الممعط",
    "معق",
    "أمعق",
    "تمعقت",
    "المعق",
    "معك",
    "ماعكه",
    "تمعك",
    "المعك",
    "المعكوكاء",
    "الممعك",
    "أمعله",
    "امتعل",
    "المعل",
    "معمع",
    "المعامع",
    "المعمع",
    "المعمعان",
    "المعمعة",
    "المعمعي",
    "أمعن",
    "المعنة",
    "معًا",
    "أمعت",
    "تمعى",
    "المعو",
    "الماعي",
    "المعاء",
    "مغث",
    "ماغثه",
    "المغث",
    "المغيث",
    "مغد",
    "أمغد",
    "المغد",
    "أمغرت",
    "الأمغر",
    "المغر",
    "المغرة",
    "الممغار",
    "الممغرة",
    "مغسه",
    "امغس",
    "المغس",
    "تمغص",
    "المغص والمغص",
    "امتغط",
    "أمغط",
    "تمغط",
    "الممغط",
    "مغلت",
    "أمغلت",
    "المغلة",
    "الممغل",
    "مغمغ",
    "تمغمغ",
    "المغناطيس والمغنطيس",
    "مغا",
    "مغى",
    "تمغى",
    "مقته",
    "تماقتوا",
    "تمقت",
    "المقتي",
    "المقدونس",
    "مقر",
    "أمقر",
    "اليمقور",
    "مقس",
    "مقست",
    "ماقسه",
    "تماقس",
    "تمقست",
    "مقط",
    "امتقط",
    "تمقط",
    "الماقط",
    "المقاط",
    "امتقع",
    "الميقع",
    "مق",
    "مقق",
    "امتق",
    "تمقق",
    "الأمق",
    "المققة",
    "مقله",
    "ماقله",
    "امتقل",
    "تماقلا",
    "المقل",
    "المقلة",
    "مقمق",
    "المقامق",
    "مقه",
    "الأمقه",
    "مقا",
    "أمكثه",
    "مكثه",
    "تمكث",
    "المكيث",
    "المكيثي والمكيثاء",
    "مكد",
    "ماكره",
    "امتكر",
    "تماكروا",
    "المكرة",
    "الممكورة",
    "ماكسه",
    "الماكس",
    "تماكس",
    "مك",
    "مكك",
    "امتك",
    "تمكك",
    "المكاكة",
    "مكمك",
    "تمكمك",
    "مكنت",
    "أمكنه",
    "استمكن",
    "المتمكن",
    "المكني",
    "المكنة",
    "مكا",
    "مكيت",
    "تمكى",
    "المكا",
    "المكاء",
    "ملأ",
    "ملؤ",
    "أملأه",
    "مالأه",
    "امتلأ",
    "تمالأ",
    "استملأه",
    "الأملأ",
    "المالئ",
    "الملأ",
    "الملاء",
    "الملاءة",
    "الملآن",
    "الملأة",
    "المملئ",
    "ملث",
    "مالثه",
    "الملث",
    "الملثة",
    "أملجت",
    "امتلج",
    "املاج",
    "الأملج",
    "الأملوج",
    "المالج",
    "المليج",
    "أملح",
    "مالحه",
    "ملحت",
    "امتلح",
    "تملح",
    "املح",
    "استملح",
    "الأملح",
    "الملاحي",
    "الملح",
    "الملحاء",
    "الملحة",
    "مالخه",
    "امتلخ",
    "تملخ",
    "الملاخ",
    "الملوخية",
    "المليخ",
    "الأملد",
    "الأملود",
    "الملد",
    "الملداء",
    "الملدان",
    "ملذ",
    "امتلذت",
    "ملز",
    "أملزه",
    "امتلز",
    "أملست",
    "انملس واملس",
    "تملس",
    "املاس",
    "الإمليس",
    "الإمليسة",
    "الملس",
    "الملسى",
    "الملاسة",
    "المملسة",
    "المليساء",
    "ملش",
    "أملصت",
    "انملص",
    "الأملص",
    "الإمليص",
    "الملص",
    "المليص",
    "المملاص",
    "أملطت",
    "مالطه",
    "تملط",
    "امتلط",
    "الملاط",
    "الملط",
    "الملطى",
    "المليط",
    "المملاط",
    "ملعت",
    "أملعت",
    "امتلعت",
    "انملعت",
    "الملاع",
    "الملع",
    "المليع",
    "الملوع",
    "الميلع",
    "ملغ",
    "مالغه",
    "تمالغ",
    "تملغ",
    "الأملغ",
    "المالغ",
    "الملغ",
    "ملقت",
    "أملقت",
    "امتلق",
    "انملق",
    "المالق",
    "الملق",
    "الملقة",
    "الملاق",
    "المليق",
    "المملق",
    "المملقة",
    "الميلق",
    "أملكه",
    "المالك",
    "الملاك",
    "الملكوت",
    "المليك",
    "المليكة",
    "المملوك",
    "امتل",
    "تملل",
    "استمله",
    "الأملولة",
    "المالولة",
    "الملال",
    "الملل",
    "الملى",
    "الملالة",
    "الملة",
    "الملول",
    "الملولة",
    "المليلة",
    "المليم",
    "الملامل",
    "الململى",
    "الملمول",
    "الملنخوليا",
    "أملاه",
    "استملاه",
    "الملاة",
    "الملاوة",
    "الملوة",
    "الملي",
    "منأ",
    "المنيئة",
    "المنبار",
    "المنجة والمنجو",
    "منحه",
    "أمنحت",
    "مانحه",
    "امتنح",
    "تمنح",
    "استمنحه",
    "المنوح",
    "المنيح",
    "المنيحة",
    "منذ ومذ",
    "منعه",
    "مانعه",
    "امتنع",
    "تمانعا",
    "المانع",
    "المنعة",
    "المناع",
    "المنيع",
    "أمنه",
    "مانه",
    "مننه",
    "امتن",
    "الممنون",
    "المن",
    "المنة",
    "المنون",
    "المنونة",
    "المنين",
    "مناه",
    "المنا",
    "أمنى",
    "ماناه",
    "امتنى",
    "المنى",
    "المنية",
    "المني",
    "مه",
    "مهج",
    "امتهج",
    "الأمهج",
    "الأمهوج",
    "المهجة",
    "امتهد",
    "تمهد",
    "استمهد",
    "المهدة",
    "المهيد",
    "الممهد",
    "أمهر",
    "تمهر",
    "المهر",
    "المهرية",
    "المهيرة",
    "المهرق",
    "مهزه",
    "مهق",
    "تمهق",
    "مهك",
    "تماهكوا",
    "أمهله",
    "مهله",
    "تمهل",
    "استمهله",
    "المهل",
    "مهمه",
    "تمهمه",
    "المهمه",
    "مهن",
    "أمهنه",
    "امتهن",
    "مهو",
    "مهى",
    "أمهى",
    "استمهى",
    "المهو",
    "مهيم",
    "ماء",
    "مات",
    "ماوت",
    "موتت",
    "تماوت",
    "استمات",
    "الممات",
    "الموات",
    "الموت",
    "الموتان",
    "الميت",
    "الميتة",
    "ماث",
    "انماث",
    "الممواج",
    "الماذ",
    "أمارت",
    "الموارة",
    "المواز",
    "الموسى",
    "الماش",
    "المواصة",
    "ماق",
    "تماوق",
    "المائق",
    "الموق",
    "الموقان",
    "مال",
    "تمول",
    "المال",
    "الميل",
    "الميلة",
    "الموماء",
    "الموماة",
    "الموميا",
    "مونه",
    "تمون",
    "ماهت",
    "أماهت",
    "تموه",
    "الماء",
    "الماه",
    "الماهية",
    "الماوية",
    "الموهة",
    "الميهة",
    "ماثت",
    "أماث",
    "ميث",
    "تميث",
    "الميثاء",
    "الميث",
    "امتاح",
    "تمايح",
    "تميح",
    "استماحه",
    "الماح",
    "الميح",
    "أماده",
    "امتاده",
    "المائدة",
    "الممتاد",
    "الميدة",
    "أمار",
    "الميرة",
    "الميار",
    "أماز",
    "امتاز",
    "انماز",
    "تمايز",
    "استماز",
    "التمييز",
    "الميز",
    "ماس",
    "تميس",
    "الميسان",
    "المياس",
    "ماط",
    "أماطه",
    "تمايط",
    "المياط",
    "أماع",
    "انماع",
    "الإماعة",
    "المائعة",
    "الميعة",
    "أمال",
    "تميل",
    "استمال",
    "الميلاء",
    "تماين",
    "المين",
    "المينى",
    "النون",
    "نأث",
    "أنأثه",
    "نأج",
    "النئيج",
    "نأدت",
    "النؤود",
    "نأرت",
    "النؤور",
    "الناسوت",
    "نأش",
    "انتأش",
    "تناءش",
    "النؤوش",
    "نأف",
    "نئف",
    "نأل",
    "نأمت",
    "النأمة",
    "النئيم",
    "نأنأ",
    "تنأنأ",
    "النأنأ",
    "النأنأة",
    "أنأى",
    "انتأى",
    "تناءوا",
    "المنتأى",
    "النؤى",
    "أنبأه",
    "نابأه",
    "استنبأ",
    "النبأ",
    "النبأة",
    "النبيء",
    "نبب",
    "تنبب",
    "الأنبوب والأنبوبة",
    "تنبت",
    "المنبت",
    "النبات",
    "النبوت",
    "الينبوت",
    "نبث",
    "انتبث",
    "تنابث",
    "الأنبوثة",
    "النبيث",
    "النبيثة",
    "نبج",
    "أنبح",
    "استنبح",
    "نبخ",
    "النبخة",
    "نابذه",
    "تنابذ",
    "المنبذة",
    "النباذ",
    "النبذة",
    "النبيذ",
    "انتبر",
    "الأنبار",
    "المنبر",
    "النبرة",
    "النبراس",
    "نبزه",
    "النبز",
    "النبزة",
    "أنبس",
    "نبشه",
    "انتبش",
    "الأنبوش",
    "النباش",
    "النباشة",
    "نبص",
    "أنبضه",
    "المنبض",
    "أنبط",
    "نبطه",
    "تنبط",
    "انتبط",
    "استنبط",
    "النبطة",
    "أنبع",
    "تنبع",
    "النابعة",
    "النبيع",
    "النابغ والنابغة",
    "النباغ",
    "النباغ والنباغة",
    "النبغ",
    "النبيغ",
    "نبك",
    "أنبك",
    "النبكة",
    "أنبل",
    "نابله",
    "نبله",
    "تنابل",
    "انتبل",
    "استنبله",
    "النابل",
    "النبال",
    "النبيل",
    "أنبهه",
    "انتبه",
    "استنبه",
    "المنبهة",
    "المنبوه",
    "النابه",
    "النبيه",
    "النبه",
    "أنبى",
    "تنبى",
    "النبوة",
    "النبي",
    "نتت",
    "النتة",
    "تناتجت",
    "استنتج",
    "المنتوجة",
    "النتاج",
    "النتوج",
    "نتح",
    "تنتح",
    "المنتح",
    "النتح",
    "نتخ",
    "نتره",
    "ناتره",
    "انتتر",
    "النتر",
    "أنتش",
    "المنتاش",
    "النتاش",
    "النتش",
    "أنتع",
    "نتغ",
    "أنتف",
    "انتتف",
    "تناتف",
    "تنتف",
    "المنتاف",
    "النتافة",
    "النتفة",
    "النتوف",
    "النتيف",
    "نتق",
    "أنتق",
    "انتتق",
    "المنتاق",
    "المنتق",
    "النتاق",
    "نتل",
    "انتتل",
    "تناتل",
    "النتيلة",
    "نتن",
    "المنتن",
    "نتا",
    "أنتى",
    "تنتى",
    "تناث",
    "المنث",
    "المنثة",
    "النثاث",
    "النث",
    "النثيثة",
    "نثج",
    "استنثج",
    "نثرت",
    "أنثره",
    "ناثره",
    "نثره",
    "انتثر",
    "تنثر",
    "استنثر",
    "المنثار",
    "المنثر",
    "المنثور",
    "النثار والنثارة",
    "النثرة",
    "النثور",
    "النثير",
    "نثط",
    "النثط",
    "أنثع",
    "نثل",
    "أنثله",
    "انتثله",
    "تناثل",
    "استنثل",
    "النثالة",
    "النثلة",
    "النثيلة",
    "نثا",
    "ناثاه",
    "تناثوا",
    "نجأ",
    "النجوء",
    "أنجب",
    "انتجب",
    "استنجب",
    "المنجاب",
    "النجابة",
    "النجب",
    "النجيب",
    "النجيبة",
    "نجث",
    "انتجث",
    "تناجث",
    "تنجث",
    "النجث",
    "النجاث",
    "النجيث",
    "النجيثة",
    "أنجح",
    "تناجحت",
    "تنجح",
    "استنجحه",
    "النجح",
    "النجيح",
    "نجخ",
    "انتجخ",
    "تناجخ",
    "الناجخ",
    "الناجخة",
    "النجاخ",
    "النجخ",
    "النجخة",
    "النجوخ",
    "أنجد",
    "ناجده",
    "تنجد",
    "استنجد",
    "المنجاد",
    "المنجدة",
    "المنجود",
    "الناجود",
    "النجود",
    "النجيد",
    "نجذه",
    "تناجذ",
    "الأنجذان",
    "المنجذ",
    "الناجذ",
    "النجذ",
    "أنجر",
    "المنجر",
    "المنجرة",
    "المنجور",
    "الناجر",
    "النجران",
    "النجر",
    "النجيرة",
    "أنجز",
    "ناجزه",
    "تنجز",
    "استنجز",
    "الناجز",
    "النجز",
    "النجيز",
    "النجيزة",
    "أنجسه",
    "نجسه",
    "تنجس",
    "الناجس",
    "النجاسة",
    "النجس",
    "النجيس",
    "نجش",
    "تناجش",
    "استنجش",
    "المنجاش",
    "المنجش",
    "المنجوش",
    "الناجش",
    "النجاش",
    "النجاشي",
    "النجوش",
    "أنجع",
    "انتجع",
    "تنجع",
    "استنجع",
    "المنجع",
    "الناجع",
    "النجع",
    "النجعة",
    "النجوع",
    "النجيع",
    "أنجف",
    "انتجف",
    "استنجف",
    "المنجاف",
    "المنجوف",
    "النجاف",
    "النجفة",
    "النجوف",
    "النجيف",
    "أنجل",
    "انتجل",
    "تناجل",
    "استنجل",
    "المنجلة",
    "النجل",
    "النجلاء",
    "النجيل",
    "أنجم",
    "انتجم",
    "تنجم",
    "النجام",
    "النجم",
    "نجنجه",
    "تنجنج",
    "نجه",
    "أنجى",
    "نجى",
    "ناجاه",
    "انتجى",
    "تناجى",
    "تنجى",
    "استنجى",
    "المنجى",
    "المنجاة",
    "الناجية",
    "النجاء",
    "النجا",
    "النجاة",
    "النجاوة",
    "النجو",
    "النجوة",
    "النجوى",
    "النجي",
    "النجية",
    "تناحب",
    "النحاب",
    "النحب",
    "النحبة",
    "انتحت",
    "المنحات",
    "المنحت",
    "النحيت",
    "النحيتة",
    "النحيح",
    "نحره",
    "ناحره",
    "تناحر",
    "المنحار",
    "المنحر",
    "الناحرة",
    "النحار",
    "النحر",
    "النحرير",
    "النحور",
    "النحير",
    "النحيرة",
    "نحز",
    "المنحاز",
    "النحاز",
    "النحازة",
    "نحسه",
    "أنحست",
    "انتحس",
    "تناحس",
    "تنحس",
    "استنحس",
    "المنحس",
    "النحاس",
    "النحاسة",
    "النحيس",
    "نحصت",
    "نحض",
    "ناحضه",
    "انتحض",
    "النحض",
    "النحضة",
    "نحط",
    "النحطة",
    "النحيط",
    "أنحفه",
    "أنحله",
    "نحله",
    "تنحل",
    "النحالة",
    "النحل",
    "النحلى",
    "النحيل",
    "النحمة",
    "نحن",
    "النحنح",
    "أنحى",
    "ناحاه",
    "المنحاة",
    "الناحي",
    "الناحية",
    "النحواء",
    "النحو",
    "النحوي",
    "النحي",
    "النحية",
    "أنخب",
    "انتخبه",
    "المنتخب",
    "المنخاب",
    "المنخوب",
    "النخاب",
    "النخيب",
    "نخج",
    "استنخج",
    "النخاجة",
    "النخ",
    "النخة",
    "الناخذاة",
    "المنخور",
    "الناخر",
    "الناخرة",
    "النخوار",
    "النخور",
    "النخروب",
    "نخزه",
    "تناخست",
    "المنخس والمنخاس",
    "الناخس",
    "النخاس",
    "النخاسة",
    "النخوس",
    "النخيس",
    "النخيسة",
    "نخش",
    "تنخش",
    "نخص",
    "أنخصه",
    "انتخص",
    "الناخص",
    "نخط",
    "النخط",
    "انتخع",
    "تنخع",
    "المنخع",
    "النخاعة",
    "نخف",
    "أنخف",
    "النخاف",
    "النخفة",
    "انتخل",
    "تنخل",
    "النخال",
    "النخلة",
    "النخيلة",
    "نخنخ",
    "تنخنخت",
    "نخي",
    "أنخى",
    "انتخى",
    "استنخى",
    "النخوة",
    "ندأ",
    "نودأ",
    "الندأة",
    "النديء",
    "أندب",
    "ندبه",
    "انتدب",
    "المندوب",
    "النادبة",
    "الندب",
    "انتدحت",
    "تندحت",
    "المندوحة",
    "الندح",
    "الندحة",
    "ندخه",
    "أندخه",
    "تندخ",
    "المندخ",
    "تناد",
    "الناد",
    "النديد",
    "استندر",
    "الندرى",
    "نادسه",
    "تندس",
    "النادس",
    "الندس",
    "ندش",
    "ندص",
    "المنداص",
    "ندغ",
    "أندغ",
    "نادغ",
    "انتدغ",
    "المندغ",
    "الندغة",
    "ندفت",
    "أندف",
    "المندف والمندفة",
    "الندافة",
    "النداف",
    "الندفة",
    "انتدل",
    "تندل",
    "نودل",
    "ندم",
    "أندمه",
    "نادمه",
    "ندمه",
    "تنادم",
    "انتدم",
    "المندم",
    "المندمة",
    "النديم",
    "استنده",
    "انتده",
    "المنده",
    "الندهة",
    "النواده",
    "أندى",
    "نادى",
    "انتدى",
    "تنادى",
    "تندى",
    "المتندى",
    "المندية",
    "النادية",
    "الندي",
    "النديان",
    "أنذره",
    "النذارة",
    "النذر",
    "نذع",
    "النذعة",
    "النرجسية",
    "النارجيل",
    "النارجيلة",
    "النرد",
    "النردن",
    "الناردين",
    "النارنج",
    "نزأ",
    "نزئ",
    "النزاء",
    "النزيء",
    "أنزح",
    "انتزح",
    "المنزاح",
    "النازح",
    "النزح",
    "النزيح",
    "أنزر",
    "تنزر",
    "المنزور",
    "النزر",
    "النزرة",
    "النزور",
    "النزير",
    "ناز",
    "نززه",
    "المنز",
    "النزاز",
    "النزة",
    "نازع",
    "نزعه",
    "تنزع",
    "تنازع",
    "استنزع",
    "المنزع",
    "المنزعة",
    "النزاعة",
    "النزعاء",
    "النزوع",
    "النزيع",
    "النزيعة",
    "نزغ",
    "المنزغ",
    "النازغ",
    "النزاغ",
    "النزغ",
    "النزغة",
    "النزيغة",
    "أنزف",
    "المنزاف",
    "المنزفة",
    "النازف",
    "النزفة",
    "النزوف",
    "أنزق",
    "نازقه",
    "نزقه",
    "تنازق",
    "المنازق",
    "النزاق",
    "النزق",
    "النزقة",
    "النزاك",
    "النزك",
    "النزيك",
    "نازله",
    "استنزله",
    "المنزل",
    "المنزلة",
    "النازلة",
    "النزالة",
    "النزال",
    "النزل",
    "النزلة",
    "نزهه",
    "استنزه",
    "المنتزه",
    "المنزه",
    "النازه",
    "النزهي",
    "أنزاه",
    "تنزى",
    "انتزى",
    "النزوان",
    "النزي",
    "النزية",
    "نسأت",
    "نسئت",
    "أنسأ",
    "ناساه",
    "انتسأ",
    "استنسأه",
    "المنسأة",
    "النساء",
    "النسء",
    "النسأة",
    "النسوء",
    "النسيئة",
    "أنسبت",
    "تنسب",
    "استنسب",
    "المنسوب",
    "النساب",
    "النسابة",
    "النسب",
    "النسبة",
    "النسيب",
    "نسجت",
    "انتسج",
    "المنسج",
    "النساجة",
    "النساج",
    "نسح",
    "المنساح",
    "النساح",
    "النسح",
    "ناسخه",
    "انتسخ",
    "استنسخ",
    "الناسخ",
    "النسخة",
    "التناسخية",
    "انتسر",
    "تنسر",
    "استنسر",
    "المنسر",
    "النسر",
    "النسيرة",
    "النسرين",
    "نس",
    "نسس",
    "تنسس",
    "المنسة",
    "المنسوس",
    "النساس",
    "النسيس",
    "النسيسة",
    "نسع",
    "أنسع",
    "نسعت",
    "انتسعت",
    "المنسعة",
    "الناسع",
    "الناسعة",
    "النسع",
    "النسعة",
    "نسغت",
    "أنسغت",
    "انتسغ",
    "المنسغة",
    "الناسغ",
    "النسغ",
    "النسيغ",
    "أنسفت",
    "تناسف",
    "انتسف",
    "المنسف",
    "المنسفة",
    "النسافة",
    "النسفان",
    "النسفة",
    "النسوف",
    "النسيف",
    "النسيفة",
    "أنسق",
    "نسقه",
    "انتسقت",
    "تناسقت",
    "تنسقت",
    "النسيق",
    "انتسك",
    "تنسك",
    "المنسوكة",
    "الناسكة",
    "النسك",
    "النسيكة",
    "أنسل",
    "انتسل",
    "الناسل",
    "النسال",
    "النسلان",
    "النسل",
    "النسول",
    "النسولة",
    "النسيل",
    "النسيلة",
    "نسمت",
    "ناسمه",
    "تنسمت",
    "المنسم",
    "الناسم",
    "النسم",
    "النسمة",
    "نسنس",
    "النسناس",
    "نسا",
    "أنساه",
    "نساه",
    "تناسى",
    "الأنسى",
    "النسوة",
    "النسوان",
    "النسيان",
    "النسي",
    "نشأ",
    "استنشأ",
    "الإنشاء",
    "المنشأة",
    "الناشئ",
    "الناشئة",
    "النشئ",
    "النشيئة",
    "أنشب",
    "ناشبه",
    "انتشب",
    "تناشب",
    "تنشب",
    "المنشبة",
    "الناشب",
    "النشب",
    "النشبة",
    "نشح",
    "انتشح",
    "النشاح",
    "النشوح",
    "نشد",
    "أنشد",
    "ناشد",
    "تناشدوا",
    "تنشد",
    "استنشد",
    "النشاد",
    "النشيدة",
    "أنشر",
    "ناشره",
    "انتشر",
    "تناشروا",
    "تنشر",
    "استنشر",
    "التناشير",
    "المنشار",
    "المنشر",
    "الناشرة",
    "النشير",
    "أنشز",
    "الناشز",
    "الناشزة",
    "النشز",
    "المنشة",
    "المنشوش",
    "النشاش",
    "النش",
    "النشيش",
    "النشيشة",
    "نشص",
    "أنشصه",
    "انتشص",
    "المنشاص",
    "النشاص",
    "النشوص",
    "النشيص",
    "انتشط",
    "تنشط",
    "استنشط",
    "النشط",
    "النشطة",
    "النشوط",
    "النشيطة",
    "أنشع",
    "انتشع",
    "المنشع",
    "النشاعة",
    "النشع",
    "النشوع",
    "نشغ",
    "أنشغ",
    "انتشغ",
    "تنشغ",
    "المنشغة",
    "الناشغة",
    "النشغة",
    "أنشفت",
    "نشفت",
    "انتشف",
    "تنشف",
    "المنشاف",
    "النشاف",
    "النشف",
    "النشفة",
    "أنشق",
    "تنشق",
    "استنشق",
    "المنشقة",
    "النشق",
    "النشقة",
    "النشاقى",
    "أنشل",
    "انتشل",
    "المنشال",
    "النشيل",
    "نشم",
    "تنشم",
    "المنشم",
    "النشم",
    "نشنشت",
    "تنشنش",
    "النشناش",
    "النشناشة",
    "النشنش",
    "النشنشة",
    "أنشى",
    "انتشى",
    "تنشى",
    "استنشى",
    "النشاء",
    "النشاة",
    "النشوان",
    "النشوي",
    "تناصبوا",
    "تنصب",
    "الأنصوبة",
    "المنصبة",
    "المنصوب",
    "المنصوبة",
    "الناصب",
    "النصاب",
    "النصبة",
    "النصيب",
    "النصيبة",
    "انتصت",
    "تنصت",
    "استنصت",
    "النصتة",
    "أنصحه",
    "ناصح",
    "انتصح",
    "تنصح",
    "استنصحه",
    "المنصح",
    "المنصحة",
    "النصاح",
    "النصوح",
    "النصيح",
    "النصيحة",
    "نصره",
    "ناصره",
    "تناصر",
    "تنصر",
    "استنصر",
    "الأنصار",
    "الأنصر",
    "الناصر",
    "الناصور",
    "النصر",
    "النصراني",
    "النصرانية",
    "النصرة",
    "النصور",
    "النصيرة",
    "نص",
    "انتص",
    "تناص",
    "المنصوص",
    "النص",
    "النصة",
    "النصيص",
    "أنصع",
    "ناصعه",
    "الناصع",
    "النصاع",
    "النصع",
    "النصيع",
    "أنصف",
    "ناصفه",
    "انتصف",
    "تناصف",
    "تنصفت",
    "استنصف",
    "الأنصف",
    "المتناصف",
    "المنصف",
    "الناصف",
    "الناصفة",
    "النصف",
    "النصفان",
    "النصفة",
    "النصيف",
    "أنصل",
    "نصله",
    "انتصل",
    "تناصل",
    "استنصل",
    "الأنصولة",
    "المنصال",
    "المنصل",
    "الناصل",
    "النصيل",
    "نصنص",
    "النصناص",
    "نصاه",
    "أنصى",
    "ناصى",
    "انتصى",
    "تناصى",
    "المنتصى",
    "النصية",
    "أنضجه",
    "نضجه",
    "استنضج",
    "المنضاج",
    "النضيج",
    "أنضح",
    "ناضحه",
    "انتضحت",
    "تنضحت",
    "التنضاح",
    "المنضحة",
    "الناضح",
    "النضح",
    "النضحة",
    "النضاحة",
    "النضيح",
    "نضخ",
    "أنضخ",
    "ناضخه",
    "انتضخ",
    "المنضخة",
    "النضخ",
    "النضخة",
    "النضاخ",
    "النضاخة",
    "نضده",
    "تنضدت",
    "النضد",
    "النضيد",
    "النضيدة",
    "أنضر",
    "نضره",
    "استنضر",
    "الأنضر",
    "الناضر",
    "النضار",
    "النضر",
    "النضرة",
    "النضير",
    "تنضض",
    "استنض",
    "النضاضة",
    "النضوض",
    "النضيض",
    "النضيضة",
    "نضفت",
    "أنضف",
    "انتضف",
    "النضف",
    "نضله",
    "أنضل",
    "انتضل",
    "تناضل",
    "تنضل",
    "النضيل",
    "نضنض",
    "النضناض",
    "النضناضة",
    "نضى",
    "أنضى",
    "انتضى",
    "تنضى",
    "النضاوة",
    "النضو",
    "النضي",
    "نطب",
    "المنطب",
    "المنطبة",
    "النواطب",
    "نطحه",
    "ناطحه",
    "تناطح",
    "الناطحة",
    "النطيح",
    "النطيحة",
    "الناطر",
    "الناطور",
    "النطار",
    "تنطس",
    "الناطس",
    "النطاسي",
    "النطس",
    "النطيس",
    "النطسة",
    "الأنط",
    "النطيط",
    "تنطع",
    "النطاعة",
    "النطع والنطع",
    "النطع",
    "أنطفه",
    "تنطف",
    "الناطف",
    "النطافة",
    "النطف",
    "النطفة",
    "النطوف",
    "أنطقه",
    "ناطقه",
    "نطقه",
    "انتطق",
    "تناطق",
    "تنطق",
    "تمنطق",
    "استنطقه",
    "المستنطق",
    "المنطق",
    "المنطوق",
    "المنطيق",
    "الناطق",
    "النطق",
    "انتطل",
    "المنطلة",
    "الناطل",
    "النطل",
    "النطلة",
    "النيطل",
    "تنطنط",
    "النطناط",
    "النطنط",
    "أنطى",
    "ناطاه",
    "تناطى",
    "المنطو",
    "النطي",
    "ناظر",
    "انتظره",
    "تنظره",
    "استنظره",
    "المنظرة",
    "الناظر",
    "الناظرة",
    "النظر",
    "النظور",
    "النظورة",
    "النظيرة",
    "نظفه",
    "تنظف",
    "استنظف",
    "النظيف",
    "تناظمت",
    "الإنظام",
    "الأنظومة",
    "النظم",
    "النظيم",
    "النظيمة",
    "أنعب",
    "المنعب",
    "النعب",
    "النعاب",
    "النعابة",
    "النعوب",
    "نعته",
    "أنعت",
    "انتعت",
    "تناعته",
    "تنعته",
    "استنعته",
    "المنعت",
    "المنعوت",
    "النعت",
    "النعتة",
    "النعيت",
    "نعث",
    "أنعث",
    "نعثل",
    "النعثل",
    "النعثلة",
    "نعج",
    "أنعج",
    "الناعور",
    "النعار",
    "النعور",
    "أنعس",
    "تناعس",
    "النعسة",
    "النعوس",
    "أنعشه",
    "نعشه",
    "انتعش",
    "المنعوش",
    "بنات نعش",
    "النعيش",
    "نعظل",
    "نع",
    "النعاع",
    "النعف",
    "النعفة",
    "النعاق",
    "أنعلت",
    "انتعل",
    "تنعل",
    "المنعل والمنعلة",
    "الناعل",
    "النعالة",
    "النعال",
    "النعل",
    "النعلة",
    "تناعم",
    "تنعم",
    "الإنعام",
    "الإنعامة",
    "المتناعم",
    "المنعام",
    "المنعم",
    "الناعمة",
    "النعامى",
    "النعمى",
    "النعمة",
    "النعيم",
    "تنعنع",
    "النعنع",
    "النعنعة",
    "نعا",
    "النعو",
    "أنعى",
    "تناعى",
    "استنعى",
    "المنعى والمنعاة",
    "الناعي",
    "نغب",
    "النغبة",
    "نغت",
    "نغرت",
    "أنغرت",
    "نغر",
    "تناغر",
    "تنغر",
    "المنغار",
    "النغر",
    "النغار",
    "النغاز",
    "انتغش",
    "تنغش",
    "النغاش",
    "النغاشة",
    "أنغص",
    "تناغصت",
    "تنغصت",
    "نغض",
    "أنغض",
    "تنغض",
    "الناغض",
    "النغض",
    "النغاض",
    "نغف",
    "النغف",
    "نغق",
    "أنغل",
    "النغل",
    "النغيل",
    "ناغمه",
    "تنغم",
    "النغام",
    "النغوم",
    "نغنغ",
    "النغنغ",
    "النغنغة",
    "ناغى",
    "الناغية",
    "النغية",
    "النفأ",
    "نفت",
    "تنافت",
    "النفثة",
    "أنفج",
    "انتفج وتنفج",
    "استنفج",
    "المنفج والمنفجة",
    "النافج",
    "النافجة",
    "النفج",
    "النفاج",
    "النفاجة",
    "النفيج",
    "النفيجة",
    "نفحت",
    "انتفح",
    "الإنفحة",
    "المنفح",
    "النفح",
    "النفحة",
    "النفوح",
    "النفيح والنفيح",
    "المنفخ",
    "المنفوخ",
    "النفخاء",
    "النفاخ",
    "النفاخة",
    "النفيخ",
    "أنفد",
    "نافده",
    "انتفد",
    "تنافد",
    "المنافد",
    "المنتفد",
    "النفاد",
    "أنفذ",
    "تنافذ",
    "التنفيذ",
    "المنتفذ",
    "النافذ",
    "النفاذ",
    "النفذ",
    "النفذة",
    "النفوذ",
    "النفيذ",
    "أنفر",
    "نافره",
    "استنفرت",
    "النافر",
    "النافرة",
    "النفار",
    "النفارة",
    "النفر",
    "النفرة",
    "النفرور",
    "النفير",
    "نفز",
    "نفزت",
    "تنافزوا",
    "النفازى",
    "النفيز",
    "نفسه",
    "نفست",
    "الأنفس",
    "المتنفس",
    "المنفوس",
    "النافس",
    "النفاس",
    "النفس",
    "النفوس",
    "النفيس",
    "أنفش",
    "انتفش",
    "المتنفش",
    "النفاش",
    "نفص",
    "أنفص",
    "المنفاص",
    "النفاص",
    "النفصة",
    "أنفض",
    "استنفض",
    "الأنفوضة",
    "المنفاض",
    "المنفض",
    "المنفوض",
    "النافض",
    "النفاض",
    "النفض",
    "النفضاء",
    "النفضة",
    "النفضى",
    "النفوض",
    "النفيضة",
    "نفطت",
    "أنفط",
    "تنفطت",
    "النافطة",
    "النفاطة",
    "النفاط",
    "النفطان",
    "النفطة",
    "النفيط",
    "نفعه",
    "أنفع",
    "انتفع",
    "استنفع",
    "المنفعة",
    "النافع",
    "النفاع",
    "النفع",
    "النفعة",
    "النفي",
    "النفيف",
    "أنفق",
    "نافق",
    "استنفق",
    "الإنفاق",
    "المنافق",
    "المنفاق",
    "المنفق",
    "المنفقة",
    "النافقاء",
    "النفقة",
    "النيفق",
    "أنفل",
    "انتفل",
    "تنفل",
    "النافلة",
    "النفل",
    "النوفل",
    "النفناف",
    "النفنف",
    "نفه",
    "نفهت",
    "أنفه",
    "استنفه",
    "نفاه",
    "نافاه",
    "انتفى",
    "النفيان",
    "النفية",
    "تنقبت",
    "المنقبة",
    "الناقبة",
    "النقبة",
    "النقيب",
    "النقيبة",
    "نقث",
    "انتقث",
    "تنقث",
    "نقاث",
    "النقث",
    "ناقحه",
    "انتقح",
    "تنقح",
    "النقح",
    "نقخ",
    "انتقخ",
    "الأنقخ",
    "النقاخ",
    "النقخة",
    "أنقد",
    "ناقده",
    "تناقد وتنقد",
    "الأنقد",
    "الإنقدان",
    "المنقاد",
    "المنقدة",
    "الناقد الفني",
    "النقد",
    "النقدة",
    "أنقذه",
    "نقذه",
    "تنقذه",
    "استنقذه",
    "الأنقذ",
    "النقذ",
    "النقيذ",
    "النقيذة",
    "أنقر",
    "ناقره",
    "انتقر",
    "تنقر",
    "الأنقور",
    "المنقر",
    "الناقر",
    "الناقرة",
    "النقارة",
    "النقرى",
    "النقار",
    "النقيرة",
    "نقرد",
    "المنقرد",
    "النقريس",
    "نقرش",
    "النقرشة",
    "أنقز",
    "نقزه",
    "انتقزت",
    "المنقوز",
    "الناقز",
    "الناقزة",
    "النقاز",
    "النقز",
    "نقس",
    "ناقسه",
    "الناقوس",
    "النقاسة",
    "النقس",
    "أنقش",
    "ناقشه",
    "نقشه",
    "انتقش",
    "المنقاش",
    "المنقوشة",
    "النقاشة",
    "النقيش",
    "انتقص",
    "استنقص",
    "المنقصة",
    "النقص",
    "النقصان",
    "النقيصة",
    "ناقض",
    "انتقض",
    "تناقض",
    "تنقض",
    "التناقض",
    "المتناقضان",
    "النقاضة",
    "النقض",
    "النقيضة",
    "تنقطت",
    "أنقع",
    "انتقع",
    "استنقع",
    "الأنقوعة",
    "المنقع",
    "المنقعة",
    "النقاع",
    "النقاعة",
    "النقعاء",
    "النقع",
    "النقوع",
    "النقيع",
    "النقيعة",
    "أنقف",
    "ناقفه",
    "انتقف",
    "الأنقوفة",
    "المنقاف",
    "المنقف",
    "المنقوف",
    "النقاف",
    "النقف",
    "النقفة",
    "النقيف",
    "نقت",
    "النقوق",
    "أنقل",
    "نقله",
    "انتقل",
    "المنقل",
    "المنقول",
    "النقل",
    "النقلة",
    "النقيل",
    "النقيلة",
    "النقم",
    "النقمة",
    "النقنق",
    "النقنيق",
    "أنقهه",
    "انتقه",
    "استنقه",
    "نقا",
    "أنقى",
    "نقاه",
    "انتقى",
    "تنقى",
    "المنقى",
    "المنقية",
    "النقا",
    "النقاواة",
    "النقاية",
    "النقو",
    "النقوة",
    "النقى",
    "النقية",
    "النقي",
    "نكأ",
    "انتكأ",
    "نكبت",
    "نكب",
    "انتكب",
    "تنكب",
    "الأنكب",
    "المنكب",
    "النكباء",
    "المناكب",
    "النكب",
    "النكبة",
    "النكيب",
    "الينكوب",
    "انتكت",
    "النكتة",
    "النكيت",
    "ناكثه",
    "انتكث",
    "تناكث",
    "النكاث",
    "النكاثة",
    "النكث",
    "النكيث",
    "النكيثة",
    "نكحت",
    "أنكح",
    "تناكح",
    "استنكح",
    "الناكح",
    "النكح",
    "النكحة",
    "أنكد",
    "ناكده",
    "نكده",
    "تناكد",
    "تنكد",
    "المنكد",
    "المنكود",
    "الناكد",
    "النكد",
    "ناكره",
    "تناكر",
    "استنكر",
    "الإنكار",
    "الأنكر",
    "المنكر",
    "المنكور",
    "النكر",
    "النكراء",
    "النكرة",
    "نكزت",
    "أنكز",
    "المنكزة",
    "النكز",
    "انتكس",
    "المنكس",
    "المنكوس",
    "الناكس",
    "النكس",
    "انتكش",
    "المنكاش",
    "المنكش",
    "المنكوش",
    "النكاش",
    "المنكص",
    "نكظ",
    "أنكظه",
    "تنكظ",
    "المنكظة",
    "نكع",
    "أنكع",
    "نكعه",
    "المنكع",
    "النكع",
    "النكعة",
    "النكوع",
    "أنكفه",
    "ناكفه",
    "انتكف",
    "تناكف",
    "استنكف",
    "المنكوف",
    "النكف",
    "النكفة",
    "أنكله",
    "المنكل",
    "الناكل",
    "النكال",
    "النكلة",
    "نكهت",
    "نكي",
    "النلنل",
    "أنمر",
    "تنمر",
    "الأنمر",
    "المنمر",
    "النامرة",
    "النامور",
    "النمير",
    "النمرق",
    "النمرقة",
    "أنمس",
    "انمس",
    "تنمس",
    "الأنمس",
    "الناموسة",
    "الناموسية",
    "النمسة",
    "أنمش",
    "نمص",
    "أنمص",
    "انتمصت",
    "تنمصت",
    "الأنمص",
    "المنماص",
    "النامصة",
    "النماص",
    "النمص",
    "النميص",
    "أنمط",
    "نمطه",
    "الأنمط",
    "نمغ",
    "المنمغ",
    "النمغة",
    "أنمقت",
    "المنمق",
    "النمق",
    "النمقة",
    "النميق",
    "تنمل",
    "الأنملة",
    "المؤنمل",
    "المنمل",
    "المنملة",
    "الناملة",
    "النمل",
    "النملية",
    "النمال",
    "النميلة",
    "النامة",
    "النمم",
    "النمة",
    "النمي",
    "النميم",
    "نمنمت",
    "المنمنم",
    "النمنم",
    "النمنمة",
    "أنمى",
    "انتمى",
    "تنمى",
    "الأنمي",
    "النامي",
    "النماة",
    "النموة",
    "النن",
    "نهأ",
    "نهئ",
    "أنهأ",
    "الناهي",
    "النهيء",
    "نهب",
    "أنهب",
    "انتهب",
    "تناهب",
    "المنهب",
    "المنهوب",
    "النهبة",
    "النهبى",
    "النهبرة",
    "النهبور",
    "نهبل",
    "النهبل",
    "النهبلة",
    "نهت",
    "المنهت والمنهت",
    "الناهت",
    "النهات",
    "نهتر",
    "نهج",
    "أنهج",
    "انتهج",
    "استنهج",
    "المنهاج",
    "المنهج",
    "الناهج",
    "النهج",
    "النهيج",
    "نهد",
    "أنهد",
    "تناهد",
    "تنهد",
    "الناهد",
    "النهاد",
    "النهداء",
    "النهدان",
    "النهد",
    "النهيد",
    "النهيدة",
    "أنهر",
    "انتهر",
    "استنهر",
    "المنهر",
    "المنهرة",
    "الناهر",
    "الناهور",
    "النهار",
    "النهر",
    "النهرة",
    "نهز",
    "أنهزه",
    "انتهز",
    "تناهز",
    "المناهزة",
    "الناهز",
    "النهاز",
    "النهز",
    "النهزة",
    "النهوز",
    "نهس",
    "انتهس",
    "المنهس",
    "المنهوس",
    "النهس",
    "النهاس",
    "النهوس",
    "النهيس",
    "نهسر",
    "النهسر",
    "نهش",
    "نهشت",
    "انتهشت",
    "المنتهشة",
    "المنهوش",
    "النهاوش",
    "النهش",
    "نهشل",
    "النهشل",
    "النهشلة",
    "نهض",
    "أنهض",
    "تناهض",
    "استنهض",
    "الناهضة",
    "النهاض",
    "النهض",
    "نهطه",
    "نهف",
    "تناهقت",
    "الناهق",
    "الناعقة",
    "النهق",
    "نهك",
    "أنهكه",
    "انتهك",
    "المنهكة",
    "الناهك",
    "النهك",
    "النهكة",
    "النهوك",
    "النهيك",
    "أنهل",
    "المنهال",
    "المنهل",
    "الناهلة",
    "النهل",
    "النهلان",
    "النهلة",
    "المنهام",
    "المنهمة",
    "المنهوم",
    "النهام",
    "النهامي",
    "النهمة",
    "نهنه",
    "النهنه",
    "نهو",
    "نهى",
    "نهي",
    "انتهى",
    "تناهى",
    "استنهى",
    "التنهاء",
    "التنهاة",
    "التنهية",
    "المنتهى",
    "المنهاة",
    "الناهية",
    "النهاء",
    "النهاة",
    "النهاية",
    "النهو",
    "النهي",
    "النهية",
    "أناءت",
    "أنوأت",
    "استناء",
    "الأنوأ",
    "النوء",
    "ناب",
    "ناوبه",
    "نوبه",
    "انتابه",
    "استنابه",
    "المناب",
    "المنتاب",
    "المنيب",
    "النائب",
    "النائبة",
    "النوبة",
    "النيابة",
    "النوتة",
    "النوتي",
    "ناجٍ",
    "النوجة",
    "ناوحه",
    "تناوح",
    "تنوح",
    "استناح",
    "المناحة",
    "أناخ",
    "تنوخ",
    "استناخ",
    "النائخة",
    "النوخة",
    "ناد",
    "تنود",
    "نار",
    "أنار",
    "استنار",
    "الأنور",
    "التنوير",
    "المنار",
    "المنارة",
    "المنور",
    "المنير",
    "النائر",
    "النائرة",
    "النار",
    "النوار",
    "النورانية",
    "النور",
    "النورة",
    "النورج",
    "النوروز أو النيروز",
    "ناس",
    "أناسه",
    "تنوس",
    "الناس",
    "الناووس",
    "النواس",
    "النواسي",
    "ناوش",
    "انتاش",
    "تناوش",
    "تنوش",
    "أناص",
    "ناوصه",
    "انتاصب",
    "استناص",
    "المناص",
    "النوص",
    "النويص",
    "أناض",
    "نوض",
    "النوض",
    "أناط",
    "انتاطت",
    "التنواط",
    "المناط",
    "المنوط",
    "النائط",
    "النائطة",
    "النوطة",
    "النياط",
    "النيط",
    "ناع",
    "استناع",
    "المنواع",
    "النائع",
    "النوع",
    "النوعة",
    "أناف",
    "نيف",
    "المناف",
    "المنيف",
    "المنيفة",
    "الناف",
    "النوف",
    "النياف",
    "النيف",
    "النيفة",
    "ناق",
    "نوق",
    "انتاق",
    "النائق",
    "الناقة",
    "النوقة",
    "النواق",
    "النيقة",
    "النيق",
    "نوك",
    "أنوكه",
    "استنوك",
    "الأنوك",
    "نال",
    "ناوله",
    "تناول",
    "تنول",
    "المتناول",
    "النال",
    "النوال",
    "النوالة",
    "النول",
    "النولة",
    "نام",
    "أنامه",
    "ناومه",
    "تناوم",
    "تنوم",
    "استنام",
    "التنويم المغنطيسي",
    "المنام",
    "النائم",
    "النؤوم",
    "النؤام",
    "النوم",
    "نومان",
    "النومة",
    "النويم",
    "النيم",
    "النيمة",
    "التنوين",
    "النونة",
    "النوه",
    "النوهة",
    "أنوى",
    "انتوى",
    "تنواه",
    "استنوى",
    "المستنوى",
    "المنوي",
    "الناوي",
    "النوى",
    "النواء",
    "النووي",
    "الني",
    "النية",
    "أناءه",
    "أنيأه",
    "نيأ",
    "النيء",
    "نيبت",
    "تنيب",
    "الأنيب",
    "النيوب",
    "نيح",
    "النيحة",
    "النيح",
    "النيدل",
    "النيدلان والنيدلان",
    "نايره",
    "تنايرا",
    "المنايرة",
    "النيرة",
    "نيرب",
    "النيرب",
    "نيرج",
    "النيرج",
    "النيرنج",
    "النيروز",
    "نيسب",
    "النيسب",
    "انتاط",
    "تنايلا",
    "استنال",
    "النائل",
    "النالة",
    "النيل",
    "النيلة",
    "النيلين",
    "النيلوفر والنينوفر",
    "النيمبرشت",
    "نيون",
    "النائه",
    "الناهة",
    "الهاء",
    "ها",
    "هأهأ",
    "هاتور",
    "هبت",
    "اهتب",
    "تهبب",
    "أهباب",
    "المهباب",
    "المهب",
    "الهباب",
    "هبايب",
    "الهبة",
    "المهبوب",
    "الهبت",
    "الهبتة",
    "الهبيت",
    "هبجه",
    "هبج",
    "هوبج",
    "التهبج",
    "المهبج",
    "الهبيج",
    "الهوبجة",
    "هبد",
    "اهتبد",
    "الهبيد",
    "هبذ",
    "هبر",
    "أهبر",
    "اهتبر",
    "هوبرت",
    "الهابر",
    "الهبارية",
    "الهبار",
    "الهبور",
    "الهبر",
    "الهبرة",
    "الهبرية",
    "الهبيرة",
    "الهوبر",
    "هبرج",
    "الهبرج",
    "الهبرزي",
    "تهبرس",
    "الهبرقي",
    "هبرم",
    "هبز",
    "الهبس",
    "هبش",
    "اهتبش",
    "تهبش",
    "الهابش",
    "الهابشة",
    "الهباشة",
    "هبص",
    "اهتبص",
    "انهبص",
    "الهبصى",
    "هبط",
    "أهبطه",
    "هبطه",
    "انهبط",
    "تهبط",
    "المهبطة",
    "الهبطة",
    "الهبيط",
    "هبع",
    "أهبع",
    "استهبعه",
    "الهبع",
    "الهبوع",
    "هبغ",
    "الهبيغ",
    "الهبيغة",
    "انهبكت",
    "الهبكة",
    "هبل",
    "أهبلت",
    "اهتبل",
    "تهبل",
    "الأهبل",
    "الهبالة",
    "الهبال",
    "الهبل",
    "الهبلي",
    "الهبول",
    "الهبلاع",
    "الهبلع",
    "الهبنك",
    "الهبنكة",
    "هبهب",
    "تهبهب",
    "الهبهب",
    "الهبهبي",
    "هبا",
    "أهبى",
    "هبى",
    "تهبى",
    "الهابي",
    "الهباء",
    "الهباءة",
    "الهبابة",
    "الهبو",
    "الهبوة",
    "هتأه",
    "هتىء",
    "تهتأ",
    "الهتأ",
    "الهتأة",
    "هت",
    "المهت",
    "الهت",
    "الهتات",
    "أهتر",
    "هاتره",
    "تهاترا",
    "تهتر",
    "استهتر",
    "التهاتر",
    "التهتار",
    "المهاترة",
    "المهتر",
    "الهتر",
    "هتش",
    "الهاتف",
    "الهتفى",
    "الهتوف",
    "تهتك",
    "الهتك",
    "الهتكة",
    "الهتيكة",
    "هتم",
    "أهتم",
    "هتمه",
    "تهاتما",
    "تهتم",
    "الهتامة",
    "الهتيمة",
    "الهيتم",
    "هتمر",
    "هتمل",
    "الهتملة",
    "هتنت",
    "التهتان",
    "الهتون",
    "هتهت",
    "الهتهات",
    "هتا",
    "هاتى",
    "الهتي",
    "هث",
    "هثم",
    "الهيثم",
    "هثهث",
    "الهثهاث",
    "الهثهثة",
    "هثى",
    "هاثاه",
    "هجأ",
    "هجئ",
    "أهجأ",
    "الهجأ",
    "الهجأة",
    "هجت",
    "هجج",
    "اهتج",
    "استهج",
    "هجاج",
    "الهجاجة",
    "الهج",
    "الهجيج",
    "أهجد",
    "تهجد",
    "التهجد",
    "هجر",
    "أهجر",
    "هاجر",
    "تهاجر",
    "تهجر",
    "الأهجر",
    "الأهجورة",
    "المهاجر",
    "المهجر",
    "المهجرة",
    "الهاجرة",
    "الهاجري",
    "الهجار",
    "الهجيري",
    "الهجراء",
    "الهجرة",
    "الهجوري",
    "الهجير",
    "الهجيرة",
    "الهويجرة",
    "الهجرس",
    "الهجرع",
    "الهجزع",
    "هجس",
    "هاجسه",
    "تهاجسا",
    "المتهجس",
    "المهجوس",
    "الهجس",
    "الهجيسة",
    "هجش",
    "الهاجشة",
    "الهجشة",
    "أهجعه",
    "التهجاع",
    "المهجع",
    "الهجع",
    "الهجعة",
    "الهجيع",
    "هجف",
    "انهجف",
    "الهجفان",
    "الهجف",
    "هجل",
    "أهجل",
    "هاجل",
    "اهتجل",
    "المهجل",
    "الهاجل",
    "الهجل",
    "الهجول",
    "الهجيل",
    "أهجم",
    "هاجمه",
    "اهتجم",
    "انهجم",
    "تهاجما",
    "تهجم",
    "الاهتجام",
    "الهجام",
    "الهجم",
    "الهجيمة",
    "الهيجمانة",
    "هجنت",
    "أهجن",
    "اهتجنت",
    "تهجنت",
    "استهجنه",
    "الأهيجنة",
    "المهجنة",
    "الهجان",
    "الهجانة",
    "الهجنة",
    "الهجنف",
    "هجهج",
    "الهجاهج",
    "الهجهاج",
    "الهجهج",
    "الهجهجة",
    "هجا",
    "هجو",
    "أهجى",
    "هاجاه",
    "هجى",
    "اهتجى",
    "تهاجيا",
    "تهجى",
    "الأهجوة",
    "الأهجية",
    "هجي",
    "هدأه",
    "المهدأة",
    "الهدء",
    "الهدأة",
    "هدب",
    "اهتدب",
    "تهدب",
    "الأهدب",
    "الهدباء",
    "الهداب",
    "الهيدب",
    "الهيدبى",
    "الهيدبي",
    "الهدبل",
    "هدج",
    "تهدج",
    "استهدج",
    "المستهدج",
    "المهداج",
    "الهدجة",
    "الهداج",
    "الهدوج",
    "الهودج",
    "الهدجدج",
    "هد",
    "هدده",
    "تهاد",
    "تهدده",
    "استهده",
    "الأهد",
    "التهداد",
    "الهاد",
    "الهادة",
    "الهداد",
    "الهدادة",
    "الهد",
    "الهدان",
    "الهدود",
    "الهديد",
    "هدر",
    "أهدر",
    "تهادر",
    "اهدودر",
    "المهدرة",
    "الهادرة",
    "هدغه",
    "انهدغ",
    "المنهدغ",
    "أهدف",
    "استهدف",
    "المهدفة",
    "الهادف",
    "الهادفة",
    "هدك",
    "تهدك",
    "هدل",
    "تهدل",
    "الهدالة",
    "الهديل",
    "الهيدلة",
    "هدمت",
    "أهدم",
    "تهدم",
    "المهدوم",
    "المهدومة",
    "الهدام",
    "الهدم",
    "الهدمة",
    "الهديم",
    "هدمل",
    "الهدمل",
    "الهدملة",
    "هدن",
    "أهدن",
    "انهدن",
    "تهادن",
    "تهدن",
    "المهدون",
    "الهدن",
    "الهدنة",
    "الهيدان",
    "هدهد",
    "الهدهدة",
    "أهدى",
    "هادى",
    "اهتدى",
    "تهادى",
    "تهدى",
    "استهدى",
    "المهداء",
    "المهدي",
    "المهيدية",
    "الهادي",
    "الهادية",
    "الهداء",
    "الهداة",
    "الهدى",
    "الهدي",
    "الهدية",
    "الهديا",
    "هذأت",
    "هذئ",
    "تهذأت",
    "الهذاء",
    "الهذأة",
    "أهذب",
    "تهذب",
    "الهذب",
    "هذ",
    "اهتذ",
    "هذاذيك",
    "الهذ",
    "الهذاذ",
    "الهذوذ",
    "هذر كلامه",
    "أهذر",
    "الهذار",
    "الهذرة والهذرة",
    "الهذريان",
    "الهيذر",
    "هذرم",
    "الهذارم والهذارمة والهذرام",
    "الهذرمي",
    "هذف",
    "الهاذل",
    "الهذلول",
    "هذلم",
    "هذم",
    "المهذم",
    "الهذام",
    "الهذامة والهذمة",
    "الهذوم",
    "الهيذام",
    "الهيذم",
    "الهذاهذ",
    "الهذهاذ",
    "هذاه",
    "هاذاه",
    "تهاذى",
    "هرأ",
    "هرئ",
    "أهرأ",
    "تهرأ",
    "الهرأ",
    "الهراءة",
    "الهريء",
    "الهريئة",
    "أهرب",
    "المهرب",
    "هربج",
    "هربذ",
    "الهربذ",
    "الهربذي",
    "الهربع",
    "هرت",
    "أهرت",
    "تهارت",
    "الهريت",
    "الهرثمة",
    "أهرج",
    "انهرج",
    "استهرج",
    "الهرج",
    "الهرجة",
    "الهراجة",
    "هرجل",
    "الهرجل",
    "الهرجول",
    "هرد",
    "الهرد",
    "الهردي",
    "هردب",
    "الهردب",
    "الهردبة",
    "هر",
    "أهر",
    "هاره",
    "الهرار",
    "الهرور",
    "الهرير",
    "هروز",
    "تهروز",
    "المهراس",
    "الهراس",
    "الهراسة",
    "الهرس",
    "هارش",
    "اهترشت",
    "تهارشت",
    "تهرش",
    "مهارش",
    "الهرش",
    "هرشف",
    "تهرشف",
    "الهرشفة",
    "الهرشم",
    "الهرشن",
    "هرص",
    "الهرص",
    "الهريصة",
    "هرض",
    "هرط",
    "تهارط",
    "الهرط",
    "الهرطة",
    "الهيرط",
    "الهرطال",
    "الهرطمان",
    "هرع",
    "أهرع",
    "اهترع",
    "تهرع",
    "المهرع",
    "المهروع",
    "الهراع",
    "الهرع",
    "الهرياع",
    "الهريعة",
    "الهيرع",
    "الهيرعة",
    "هرف",
    "أهرف",
    "الهرف",
    "هرق",
    "أهرق",
    "هراق",
    "تهارق",
    "اهرورق",
    "المهرقان",
    "الهرق",
    "الهرقل",
    "هرقل وهرقل",
    "هركل",
    "الهراكل",
    "الهراكلة",
    "الهركلة والهركلة",
    "الهركولة",
    "الهرل",
    "هرمت",
    "أهرم",
    "تهارم",
    "الهرم",
    "الهرمى",
    "الهرمان",
    "الهرمة",
    "الهروم",
    "هرمز",
    "هرمس",
    "الهرامس",
    "الهرماس",
    "الهرموس",
    "الهرميس",
    "الهرميسة",
    "هرمط",
    "اهرمع",
    "هرملت",
    "الهرمل",
    "الهرمول",
    "هرنف",
    "هرهر",
    "تهرهرت",
    "الهراهر",
    "الهرهار",
    "الهرهور",
    "هراه",
    "هرى",
    "تهراه",
    "الهرى",
    "هزأ",
    "هزئ",
    "أهزأ",
    "تهزأ",
    "استهزأ",
    "الهازئة",
    "الهزأة",
    "هزبره",
    "الهزبر",
    "هزج",
    "أهزج",
    "تهزج",
    "هزر",
    "المهزر",
    "الهزار",
    "الهزر",
    "الهزرة",
    "الهزور",
    "هزرف",
    "الهزارف والهزراف",
    "الهزرفي",
    "الهزروف",
    "هزرق",
    "الهزارق",
    "هزز",
    "تهزز",
    "الهزائز",
    "اهتزع",
    "انهزع",
    "تهزع",
    "الأهزع",
    "المهزع",
    "الهزاع",
    "الهزع",
    "الهزيع",
    "الهيزعة",
    "هزفت",
    "الهزف",
    "هزق",
    "أهزق",
    "المهزاق",
    "الهزق",
    "هزل",
    "أهزل",
    "المهزول",
    "الهزالة",
    "الهزيل",
    "الهزل",
    "الهزيلي",
    "هزلج",
    "الهزلاج",
    "اهتزم",
    "تهزم",
    "استهزم",
    "المهزام",
    "الهازمة",
    "الهزم",
    "الهزمة",
    "الهزوم",
    "الهزيم",
    "الهزيمة",
    "الهزيمي",
    "الهيزم",
    "الهزمجة",
    "تهزهز",
    "الهزاهز",
    "الهزهاز",
    "الهزهز",
    "هزا",
    "هس",
    "الهسيس",
    "هسهس",
    "تهسهس",
    "الهساهس",
    "الهسهاس",
    "الأهساء",
    "هشر",
    "الهشر",
    "الهشرة",
    "الهشور",
    "الهيشر",
    "اهتش",
    "استهش",
    "الهشاش",
    "الهشوش",
    "الهشوشة",
    "الهشيش",
    "هشلت",
    "اهتشل",
    "الهشيلة",
    "الهيشلة",
    "انهشم",
    "اهتشم",
    "تهشم",
    "المهشام",
    "الهاشم",
    "الهاشمة",
    "الهشام",
    "الهشم",
    "الهشمة",
    "الهشوم",
    "الهشيم",
    "الهشيمة",
    "الهيشوم",
    "الهشنق",
    "هشهش",
    "تهشهشت",
    "الهشهاش",
    "هاشاه",
    "هصر",
    "اهتصر",
    "تهصرت",
    "المهتصر",
    "الهصر",
    "الهصرة",
    "الهصور",
    "هصت",
    "هصص",
    "الهاصة",
    "الهص",
    "هصم",
    "المهصم",
    "الهيصم",
    "الهصاهص",
    "الهصهاص",
    "هصا",
    "هضب",
    "أهضب",
    "اهتضب",
    "الأهضوبة",
    "الهضب",
    "الهضيب",
    "هضج",
    "الهضيج",
    "هضت",
    "هضض",
    "اهتض",
    "الهضاء",
    "الهضاض",
    "الهضض",
    "هضل",
    "أهضلت",
    "الهضال",
    "الهضل",
    "الهضلاء",
    "الهيضل",
    "الهيضلة",
    "اهتضم",
    "انهضمت",
    "تهضمت",
    "الأهضم",
    "المهضمة",
    "المهضومة",
    "الهاضوم",
    "الهضام",
    "الهضوم",
    "الهضيم",
    "الهضيمة",
    "هاضاه",
    "الهضاة",
    "هطر",
    "تهطرت",
    "تهطرس",
    "الهطط",
    "هطع",
    "أهطع",
    "استهطع",
    "المهطع",
    "الهيطع",
    "هطف",
    "الهطف",
    "هطل",
    "تهاطل",
    "تهطل",
    "الهاطل",
    "الهطال",
    "الهطل",
    "الهطلى",
    "الهطلاء",
    "الهياطلة",
    "الهيطل",
    "الهيطلة",
    "الهيطلية",
    "هطلس",
    "تهطلس",
    "الهطلس",
    "الهطلع",
    "هطهط",
    "الهطاهط",
    "هطا",
    "الهطى",
    "هيعر",
    "تهيعرت",
    "الهيعرة",
    "هفت",
    "انهفت",
    "تهافت",
    "الهفات",
    "الهفت",
    "الهفتق",
    "هف",
    "المهفف",
    "الهفاف",
    "الهفان",
    "اليهفوف",
    "هفك",
    "تهفك",
    "المهفك",
    "الهيفك",
    "هفهف",
    "المهفهف",
    "الهفهاف",
    "هفا",
    "هافاه",
    "الهافية",
    "الهفا",
    "الهفاء",
    "الهفاة",
    "الهقب",
    "هقع",
    "اهتقع",
    "انهقع",
    "تهقع",
    "الهقاع",
    "الهقع",
    "الهقعة",
    "الهيقعة",
    "هقف",
    "الهقف",
    "هق",
    "تهقل",
    "الهاقل",
    "الهقل",
    "الهقلس",
    "هقم",
    "تهقم",
    "الهقم",
    "الهيقم",
    "الهيقماني",
    "هقهق",
    "الهقهاق",
    "هقى",
    "أهقى",
    "هكب",
    "هكد",
    "هكر",
    "تهكر",
    "هكع",
    "اهتكع",
    "الهكاع",
    "الهكعة",
    "هكت",
    "انهكت",
    "تهكك",
    "المهكوك",
    "الهك",
    "الهكاك",
    "الهكوك",
    "الهكيك",
    "هكلت",
    "تهاكل",
    "هكم",
    "تهكم",
    "استهكم",
    "الأهكومة",
    "الهكم",
    "تهكن",
    "هاكاه",
    "هلب",
    "أهلبت",
    "اهتلب",
    "الهالب",
    "الهلابة",
    "الهلب",
    "الهلبة",
    "الهلاب",
    "الهلوب",
    "الهلابج",
    "هلت",
    "انهلت",
    "الهلتات",
    "الهلاث",
    "الهلائث",
    "الهلثى",
    "هلج",
    "أهلج",
    "الهالج",
    "الهلج",
    "هلد",
    "هلسه",
    "هلس",
    "أهلس",
    "هالسه",
    "اهتلس",
    "الهالس",
    "الهلاس",
    "الهلس",
    "الهالط",
    "هلع",
    "الهالع",
    "الهلاع",
    "الهلع",
    "الهلعة",
    "الهلواع",
    "الهولع",
    "الهلوف",
    "الهلوفة",
    "هلقم",
    "الهلقام",
    "أهلكه",
    "هلكه",
    "اهتلك",
    "تهالك",
    "تهلكت",
    "استهلك",
    "المهلكة",
    "الهالكة",
    "الهالكي",
    "الهالوك",
    "الهلاك",
    "الهلك",
    "الهلكة",
    "الهلكون",
    "الهلوك",
    "الهيلكون",
    "الهلكس",
    "هل",
    "اهتل",
    "انهل",
    "تهلل",
    "استهل",
    "الاستهلال",
    "الأهاليل",
    "الهلال",
    "الهل",
    "الهلل",
    "الهلى",
    "الهلة",
    "الهليلة",
    "هلم",
    "اهتلم",
    "الهلمان",
    "الهيلمان",
    "تهلهل",
    "الهلاهل",
    "الهلهال",
    "الهلهل",
    "المهلهل",
    "المهلهلة",
    "الهليون",
    "همأ",
    "أهمأ",
    "تهمأ",
    "انهمأ",
    "الهمء",
    "همت",
    "أهمت",
    "همجت",
    "أهمج",
    "اهتمج",
    "الهامج",
    "الهميج",
    "أهمد",
    "همده",
    "الهامد",
    "الهامدة",
    "الهميد",
    "همذ",
    "الهماذي",
    "الهمذان",
    "الهمذاني",
    "همر",
    "انهمر",
    "اهتمر",
    "المهمار",
    "المهمر",
    "الهامر",
    "الهمر",
    "الهمرى",
    "الهمرة",
    "الهمار",
    "الهمير",
    "همرج",
    "الهمرج",
    "الهمرجان",
    "همرش",
    "تهمرش",
    "الهمرشة",
    "الهمرش",
    "همزه",
    "المهمز",
    "المهمزة",
    "الهامز",
    "الهمز",
    "الهمزى",
    "الهماز",
    "الهميز",
    "همس",
    "تهامس",
    "الإهماس",
    "الهامس",
    "الهمس",
    "الهماس",
    "الهموس",
    "الهميس",
    "همش",
    "هامشه",
    "اهتمش",
    "تهامش",
    "تهمش",
    "الهامش",
    "الهمش",
    "الهمشة",
    "الهمشى",
    "الهميشة",
    "همط",
    "اهتمط",
    "همعت",
    "أهمع",
    "اهتمع",
    "تهمع",
    "الهمع",
    "الهموع",
    "همغ",
    "انهمغ",
    "الهميغ",
    "همق",
    "الهمق",
    "همك",
    "انهمك",
    "تهمك",
    "انهملت",
    "الهماليل",
    "الهمل",
    "الهمال",
    "هملجت",
    "المهملج",
    "الهملاج",
    "الهملس",
    "الهملع",
    "هممت",
    "اهتم",
    "انهم",
    "تهمم",
    "استهم",
    "التهميم",
    "الهاموم",
    "الهمام",
    "الهم",
    "الهميم",
    "همهم",
    "الهماهم",
    "همهام",
    "الهمهام",
    "الهمهامة",
    "الهمهوم",
    "الهمهومة",
    "الهمهيم",
    "الأهماء",
    "الهماء",
    "الهماي",
    "الهميان",
    "الهمايون",
    "الهميسع",
    "هنأ",
    "هنؤ",
    "أهنأه",
    "اهتنأ",
    "تهنأ",
    "استهنأ",
    "المهنأ",
    "الهانئ",
    "الهنء",
    "الهنيء",
    "هنب",
    "المهنب",
    "الهنباء",
    "الهنبى",
    "الهنبثة",
    "الهنبر",
    "تهنبس",
    "هنبص",
    "الهنبص",
    "هنبع",
    "الهنبع",
    "هنبغ",
    "الهنباغ",
    "الهنبغ",
    "هنبل",
    "تهنج",
    "هند",
    "المهند",
    "الهند",
    "الهندواني",
    "هنيدة",
    "الهنيدة",
    "الهندبا",
    "هندز",
    "الهنداز",
    "الهندازة",
    "الهندوز",
    "هندس",
    "الهندس",
    "الهندسي",
    "هندم",
    "هنع",
    "الأهنع",
    "الهناع",
    "الهنعاء",
    "الهنعة",
    "هنغ",
    "هانغ",
    "الهينغ",
    "أهنف",
    "هانف",
    "هنف",
    "تهانف",
    "تهنف",
    "الهناف",
    "الهنوف",
    "هنق",
    "أهنق",
    "هانم",
    "هينم",
    "المهينم",
    "الهانم",
    "الهنم",
    "الهنمة",
    "الهينام",
    "الهينم",
    "الهينمان",
    "الهينوم",
    "أهنه",
    "الهانة",
    "هنا",
    "الهننة",
    "الهن",
    "الهناة",
    "الهنة",
    "الهنو",
    "هنيهة وهنية",
    "هه",
    "الهو",
    "هاء",
    "هويء",
    "هاوأ",
    "الهوء",
    "الهوب",
    "هوبر",
    "هوت",
    "الهوتة",
    "هوج",
    "أهوجه",
    "تهوج",
    "الهوجاء",
    "هاود",
    "تهود",
    "التهويد",
    "الهوادة",
    "الهود",
    "الهودة",
    "اليهود",
    "اليهودي",
    "اليهودية",
    "الهودك",
    "الهوذة",
    "هوذة",
    "اليهوذي",
    "اهتور",
    "تهور",
    "الهائر",
    "الهار",
    "الهاري",
    "الهوارة",
    "الهورة",
    "الهير",
    "الهورع",
    "هوز",
    "الهوز",
    "الهوزب",
    "الهوزن",
    "هوست",
    "تهوس",
    "الهواس",
    "الهواسة",
    "الهويس",
    "هاش",
    "هوش",
    "هاوش",
    "تهاوش",
    "تهوش",
    "الهائشة",
    "الهواش",
    "الهواشة",
    "الهوش",
    "الهويشة",
    "هاع",
    "هوع",
    "الهاع",
    "الهواع",
    "الهواعة",
    "الهوع",
    "الهوف",
    "الهوقة",
    "انهاك",
    "تهوك",
    "الهوكة",
    "الهوك",
    "الهواك",
    "الهواكة",
    "هيل",
    "هاول",
    "هول",
    "اهتال",
    "تهول",
    "استهال",
    "التهاويل",
    "الهال",
    "الهولة",
    "الهيلة",
    "الهولع والهولع",
    "هوم",
    "تهوم",
    "الأهوم",
    "التهويم",
    "الهوام",
    "الهوم",
    "الهوماة والهومة",
    "هاون",
    "الأهون",
    "الهاوون",
    "الهون",
    "الهونة",
    "الهونى",
    "الهوينى",
    "الهينة",
    "الهين",
    "تهوه",
    "الهاهة",
    "الهواهي",
    "الهواهية",
    "الهوهاء",
    "الهوهاة",
    "الهوه",
    "الهوهة",
    "الهواءة",
    "أهوى",
    "هاوى",
    "اهتوى",
    "انهوى",
    "تهاوى",
    "استهوى",
    "التهواء",
    "الهوى",
    "الهواء",
    "الهوي",
    "هايأه",
    "هيأ",
    "تهايأ",
    "تهيأ",
    "المهايأة",
    "الهيء",
    "الهيئة",
    "هابه",
    "اهتاب",
    "المتهيب",
    "الهيبان",
    "الهيت",
    "هيتاه وهيتاه",
    "هايث",
    "تهايث",
    "تهيث",
    "استهاث",
    "المهايث",
    "الهايثة",
    "الهيثة",
    "أهاج",
    "أهيج",
    "هايج",
    "هيجه",
    "اهتاج",
    "تهايج",
    "تهيج",
    "المهياج",
    "الهائج",
    "الهاجة",
    "الهيج",
    "الهيجاء",
    "الهياج",
    "هيخ",
    "الهيخ",
    "هاده",
    "الهيد",
    "الهيدكور",
    "الهيدكورة",
    "تهير",
    "الهيار",
    "الهيرة",
    "هيراطيقي",
    "الهيرودين",
    "الهيزب",
    "هاسى",
    "الأهيس",
    "هيس هيس",
    "تهيش",
    "الهيشة",
    "هاص",
    "المهايص",
    "هاض",
    "هيض",
    "اهتاض",
    "انهاض",
    "تهيض",
    "المستهاض",
    "الهيض",
    "الهيضاء",
    "الهيضة",
    "هاط",
    "هايط",
    "تهايط",
    "الهياط",
    "الهيط",
    "تهيع",
    "المهيع",
    "الهائع",
    "الهائعة",
    "الهياع",
    "الهيعة",
    "الهيع",
    "الهيعرون",
    "أهيغ",
    "الأهيغ",
    "الأهيغان",
    "هاف",
    "هيف",
    "أهاف",
    "اهتاف",
    "تهيف",
    "استهاف",
    "المهياف",
    "الهاف",
    "الهافة",
    "الهيف",
    "الهيفان",
    "الهيوف",
    "أهيق",
    "الأهيق",
    "الهيق",
    "أهال",
    "انهال",
    "تهيل",
    "الأهيل",
    "المهيل",
    "الهيال",
    "الهيلان",
    "الهيول",
    "الهيولى",
    "الهيولاني",
    "هيلل",
    "اهتام",
    "تهيم",
    "استهيم",
    "الأهيم",
    "الهيمان",
    "الهيوم",
    "هيمن",
    "المهيمن",
    "الهيمجلوبين",
    "هاهى",
    "الهية",
    "هيه هيه",
    "هيهات",
    "هي",
    "هيا هيا",
    "هيان",
    "الواو",
    "وا",
    "وأب",
    "وئب",
    "أوأب",
    "اتأب",
    "الإبة",
    "التؤبة",
    "الموئبة",
    "الوأبة",
    "الوئيبة",
    "الوأج",
    "توأد",
    "التوآد",
    "التؤدة",
    "الوئيد",
    "أوأره",
    "وأره",
    "الوئار",
    "الوئرة",
    "الوؤرة",
    "وأل",
    "أوألت",
    "واءل",
    "الموئل",
    "الموئلة",
    "الوأل",
    "الوألة",
    "وأمه",
    "يوأمه",
    "واءمه",
    "وأم",
    "تواءم",
    "الموأمة",
    "الوأم",
    "الوأمة",
    "التوأن",
    "الوأن",
    "الوأنة",
    "وأوأ",
    "الوأواء",
    "وأى",
    "اتأى",
    "تواءى",
    "استوأى",
    "الوأي",
    "الوأى",
    "الوأية",
    "الوئية",
    "وبئت",
    "وبؤت",
    "أوبأت",
    "توبأ",
    "استوبأ",
    "الموبئ",
    "الوبأ",
    "وبخه",
    "وبد",
    "أوبد",
    "توبد",
    "استوبد",
    "الوبد",
    "الأوبر",
    "الوابر",
    "الوبر",
    "أوبش",
    "الوبش",
    "وبص",
    "أوبصت",
    "الوابصة",
    "الوباص",
    "الوبصة",
    "الوبيصة",
    "وبط",
    "الوباط",
    "الوباعة",
    "وبغه",
    "يبغه",
    "الوبغ",
    "الوبغة",
    "يبق",
    "أوبقه",
    "استوبق",
    "الموبق",
    "الموبقات",
    "وبلت",
    "استوبلت",
    "الموبل",
    "الموبلة",
    "الميبل",
    "الميبلة",
    "الوابلة",
    "الوبال",
    "الوبل",
    "الوبلى",
    "الوبلة",
    "الوبيل",
    "الوبيلة",
    "الوت",
    "الوتة",
    "الوتاوت",
    "وتح",
    "أوتح",
    "توتح",
    "الوتح",
    "الوتيح",
    "أوتد",
    "الميتد",
    "الميتدة",
    "الوتدة",
    "أوتر",
    "واتر",
    "تواترت",
    "تترا",
    "المتواتر",
    "الوتر",
    "الوتران",
    "الوترة",
    "الوترتان",
    "وتغ",
    "أوتغ",
    "الموتغة",
    "الوتيغة",
    "الأوتل",
    "وتم",
    "الموتمة",
    "الوتمة",
    "أوتنت",
    "واتن",
    "استوتن",
    "الموتونة",
    "الواتن",
    "الوتن",
    "الوتنة",
    "واتاه",
    "الوتى",
    "وثئت",
    "أوثأ",
    "الميثأة",
    "الوثء",
    "الوثاءة",
    "أوثب",
    "واثبه",
    "وثبه",
    "تواثب",
    "توثب",
    "الموثبان",
    "الميثب",
    "الوثبى",
    "وثج",
    "أوثج",
    "استوثج",
    "الموتثجة",
    "الموثخ",
    "الموثوخ",
    "الوثخة",
    "الوثيخة",
    "استوثر",
    "الأوثر",
    "الميثرة",
    "الواثر",
    "الوثار",
    "الوثر",
    "الوثير",
    "الوثيرة",
    "وثغ",
    "الوثيغة",
    "وثف",
    "أوثف",
    "أوثق",
    "تواثق",
    "استوثق",
    "الثقة",
    "الميثاق",
    "الوثيقة",
    "وثله",
    "يثله",
    "الوثل",
    "الوثيل",
    "وثم",
    "وثمت",
    "واثم",
    "الميثم",
    "الوثيمة",
    "وثنت",
    "أوثن",
    "استوثن",
    "الموثونة",
    "وجأ",
    "أوجأ",
    "اتجأ",
    "توجأه",
    "الوجء",
    "الوجأ",
    "الوجيئة",
    "وجب",
    "أوجب",
    "واجب",
    "وجبت",
    "تواجب",
    "توجب",
    "استوجب",
    "الموجب",
    "الموجبة",
    "الواجب",
    "الوجب",
    "الوجوب",
    "الوجيبة",
    "وج",
    "يوج",
    "الوج",
    "الوجج",
    "وجح",
    "أوجح",
    "الموجح",
    "الموجوح",
    "الوجاح",
    "الوجح",
    "الوجيح",
    "أوجد",
    "تواجد",
    "توجد",
    "الموجود",
    "الواجد",
    "الوجادة",
    "الوجد",
    "الوجدان",
    "الوجود",
    "الوجيد",
    "أوجذه",
    "واجذه",
    "الوجذ",
    "أوجر",
    "توجر",
    "الميجار",
    "الميجر",
    "الميجرة",
    "الوجار",
    "الوجر",
    "الوجرة",
    "الوجور",
    "توجز",
    "استوجز",
    "الميجاز",
    "الوجز",
    "أوجس",
    "الأوجس",
    "الواجس",
    "الوجس",
    "أوجع",
    "الوجعاء",
    "الوجيع",
    "أوجف",
    "استوجفه",
    "الميجاف",
    "وجله",
    "يوجله",
    "وجل",
    "أوجله",
    "الموجل",
    "الوجول",
    "الوجيل",
    "الوجم",
    "الوجمة",
    "الوجيم",
    "الوجيمة",
    "وجن",
    "توجن",
    "الأوجن",
    "الموجن",
    "الموجونة",
    "الواجن",
    "الوجن",
    "الوجناء",
    "الوجنة",
    "الوجين",
    "وجه",
    "أوجهت",
    "تواجها",
    "التجاه",
    "التوجيه",
    "الجهة",
    "الوجاه",
    "الوجاهة",
    "الوجه",
    "الوجيه",
    "وجاه",
    "يجيه",
    "وجي",
    "أوجى",
    "توجى",
    "أوحدت",
    "توحد",
    "استوحد",
    "الأحدية",
    "الأوحد",
    "التوحيد",
    "الميحاد",
    "الواحد",
    "الواحدية",
    "وحاد",
    "الوحد",
    "الوحداني",
    "الوحدة",
    "يوحر",
    "أوحرت",
    "الوحر",
    "الوحرة",
    "وحش",
    "أوحش",
    "استوحش",
    "الوحش",
    "الوحشة",
    "الوحيش",
    "وحصه",
    "يحصه",
    "الوحص",
    "الوحصة",
    "أوحف",
    "الموحف",
    "الواحف",
    "الوحف",
    "الوحفاء",
    "الوحفة",
    "وحل",
    "أوحله",
    "واحله",
    "توحل",
    "استوحل",
    "الموحل",
    "وحمت",
    "وحن",
    "توحن",
    "الوحنة",
    "توحوح",
    "الوحواح",
    "الوحوح",
    "وحي",
    "توحى",
    "الوحي",
    "الوحى",
    "الوحاء",
    "الوحاة",
    "وخد",
    "الوخيز",
    "وخش",
    "أوخش",
    "الوخش",
    "وخط",
    "الوخط",
    "وخف",
    "أوخف",
    "اتخفت",
    "استوخف",
    "الميخف",
    "الوخفة",
    "الوخيف",
    "الوخيفة",
    "أوخمه",
    "واخم",
    "توخم",
    "استوخم",
    "المتخمة",
    "الموخمة",
    "الوخام",
    "الوخم",
    "الوخمة",
    "الوخوم",
    "الوخيم",
    "الوخيمة",
    "الوخواخ",
    "الوخوخة",
    "واخاه",
    "وخاه",
    "استوخاه",
    "الخية",
    "الوخي",
    "الوخى",
    "ودأ",
    "ودئ",
    "تودأ",
    "المودأة",
    "وادجه",
    "الوداج",
    "الودج",
    "أودح",
    "الودحة",
    "وده",
    "واده",
    "توادا",
    "تودد",
    "المود",
    "المودة",
    "الودود",
    "الوديد",
    "ودره",
    "تودر",
    "ودست",
    "أودست",
    "تودست",
    "الوادس",
    "الوداس",
    "الودس",
    "الوديس",
    "اتدع",
    "توادع",
    "تودع",
    "استودع",
    "الإيداع",
    "التداعة",
    "التدعة",
    "الدعة",
    "المستودع",
    "المودع",
    "المودوع",
    "الميداعة",
    "الميدع",
    "الميدعة",
    "الوداع",
    "الودع",
    "الوديع",
    "الوديعة",
    "ودف",
    "تودفت",
    "استودف",
    "الودفة",
    "الوديفة",
    "ودق",
    "ودقت",
    "أودقت",
    "المودق",
    "الوادق",
    "الوديقة",
    "يودك",
    "الأودك",
    "المتودك",
    "الوادك",
    "الودك",
    "الوداك",
    "الوديكة",
    "ودل",
    "ودن",
    "ودنت",
    "أودنت",
    "اتدن",
    "تودن",
    "الأودن",
    "المودن",
    "المودنة",
    "المودون",
    "المودونة",
    "الودان",
    "الودنة",
    "أودى",
    "اتدى",
    "استودى",
    "التودية",
    "الدية",
    "الوادي",
    "الودي",
    "الودى",
    "وذأت",
    "الوذء",
    "الوذأة",
    "وذخ",
    "يوذح",
    "الأوذح",
    "الوذح",
    "وذره",
    "الوذارة",
    "الوذرة",
    "وذف",
    "توذف",
    "الوذفان",
    "الوذف",
    "الوذفة",
    "توذل",
    "الوذالة",
    "الوذل",
    "الوذلة",
    "الوذيلة",
    "وذمت",
    "أوذم",
    "وذم",
    "الوذم",
    "الوذماء",
    "الوذيمة",
    "توذن",
    "وذى",
    "الوذاة",
    "الوذية",
    "ورأ",
    "وريء",
    "أورأه",
    "أوريء",
    "ورئ",
    "استورأت",
    "ورب",
    "واربه",
    "الورب",
    "الوربة",
    "أورث",
    "توارثوا",
    "الميراث",
    "الوارث",
    "الوراث",
    "الورث",
    "ورخ",
    "أورخ",
    "تورخ",
    "استورخت",
    "الورخ",
    "الوريخة",
    "أورد",
    "وارده",
    "وردت",
    "استورد",
    "الموردة",
    "الوارد",
    "الواردة",
    "الورد",
    "الوريدة الطفلية",
    "الور",
    "الورة",
    "ورست",
    "أورس",
    "الوارس",
    "الورس",
    "الورسي",
    "الوريس",
    "الورش",
    "الورشان",
    "ورصت",
    "أورصت",
    "أورط",
    "وارطه",
    "ورطه",
    "استورط",
    "الوراط",
    "أورع",
    "وارع",
    "تورع",
    "الرعة",
    "أورف",
    "أورق",
    "وارقه",
    "تورق",
    "استورق",
    "ايراق",
    "الأورق",
    "الوارقة",
    "الوراق",
    "الوراقة",
    "الورق",
    "الورقاء",
    "الوريقة",
    "يرك",
    "وارك",
    "توارك",
    "تورك",
    "المورك",
    "الموركة",
    "الميركة",
    "الوارك",
    "الوراك",
    "الوركي",
    "الوركاء",
    "الوركانة",
    "الورل",
    "أورمت",
    "الأورم",
    "المورم",
    "تورن",
    "الورانية",
    "ورنه",
    "الورنيش",
    "وره",
    "يوره",
    "توره",
    "الوارهة",
    "الورهاء",
    "الوره",
    "ورور",
    "المورور",
    "الوروري",
    "أورى",
    "توارى",
    "استورى",
    "الترية",
    "الواري",
    "الوري",
    "الورية",
    "وزأ",
    "وزأت",
    "توزأ",
    "الوزأ",
    "أوزب",
    "الوزاب",
    "أوزره",
    "وازره",
    "توزر",
    "استوزره",
    "الوزر",
    "الوزرة",
    "الوزير",
    "أوزع",
    "وازعه",
    "وزعه",
    "اتزع",
    "توزع",
    "استوزعه",
    "الأوزاع",
    "التوزيع",
    "المتزع",
    "الوازع",
    "الوزوع",
    "وزغ",
    "أوزغ",
    "الوزغ",
    "الوزغة",
    "وزف",
    "يزف",
    "أوزف",
    "وازف",
    "توازف",
    "أوزكت",
    "وزم",
    "يزم",
    "توزم",
    "الوزام",
    "الوزم",
    "الوزمة",
    "الوزيم",
    "الوزيمة",
    "اتزن",
    "الأوزن",
    "الاتزان",
    "التوازن الاقتصادي",
    "الموزون",
    "الموزونة",
    "الميزان",
    "الوازن",
    "الوزان",
    "الوزانة",
    "الوزن",
    "الوزنة",
    "الوزين",
    "وزوز",
    "الوزوز",
    "أوزى",
    "وازاه",
    "توازى",
    "استوزى",
    "الوزى",
    "وسبت",
    "وسب",
    "أوسبت",
    "الميساب",
    "الوسب",
    "توسخ",
    "استوسخ",
    "الوسخ",
    "أوسد",
    "أوسط",
    "وسطه",
    "توسط",
    "الأوسط",
    "الموسط",
    "الواسط",
    "الواسطة",
    "الوسط",
    "الوسطى",
    "الوسوط",
    "الوسيط",
    "سع",
    "اتسع",
    "استوسع",
    "السعة",
    "المتسع",
    "الميساع",
    "الواسع",
    "الوساع",
    "الوسع",
    "الوسيع",
    "وسف",
    "توسف",
    "الوسف",
    "وسقت",
    "أوسقت",
    "واسقه",
    "استوسق",
    "الميساق",
    "الوسق",
    "الوسيق",
    "الوسيقة",
    "الواسل",
    "الواسلة",
    "الوسيلة",
    "واسمه",
    "الموسم",
    "الموسوم",
    "الموسومة",
    "الميسم",
    "الوسمة",
    "الوسمي",
    "وسن",
    "أوسنته",
    "توسن",
    "استوسن",
    "الموسونة",
    "الوسن",
    "الوسنى",
    "الوسنانة",
    "الوسني",
    "أوسى",
    "الوشب",
    "الوشبة",
    "وشج",
    "توشج",
    "الأوشاج",
    "الواشجة",
    "الوشيج",
    "الوشيجة",
    "اتشحت",
    "توشحت",
    "التوشيح",
    "الموشح",
    "الموشحة",
    "الوشحاء",
    "وشر",
    "اتشرت",
    "استوشرت",
    "الميشار",
    "توشز",
    "الوشز",
    "الوشيزة",
    "وشظ",
    "الوشيظ",
    "الوشيظة",
    "وشعت",
    "أوشع",
    "توشع",
    "الموشع",
    "الوشع",
    "الوشوع",
    "الوشيع",
    "الوشيعة",
    "وشغ",
    "أوشغ",
    "توشغ",
    "الوشغ",
    "الوشوغ",
    "الوشيغ",
    "أوشق",
    "اتشق",
    "تواشق",
    "المواشيق",
    "الواشق",
    "الوشيق",
    "الوشيقة",
    "وشك",
    "يوشك",
    "واشك",
    "الوشاك",
    "الوشكان",
    "الوشيك",
    "أوشل",
    "الأوشال",
    "الواشل",
    "الوشلة",
    "الوشول",
    "أوشم",
    "اتشم",
    "استوشم",
    "الوشمة",
    "الوشيمة",
    "توشن",
    "الأوشن",
    "الوشنان",
    "توشوش",
    "الوشواشة",
    "الوشوش",
    "الوشوشي",
    "أوشى",
    "توشى",
    "استوشى",
    "الشية",
    "الموشى",
    "الواشي",
    "الوشاء",
    "الوشي",
    "توصب",
    "الموصب",
    "الواصبة",
    "الوصب",
    "وصد",
    "أوصد",
    "وصده",
    "استوصد",
    "الموصد",
    "الوصاد",
    "الوصيد",
    "الوصيدة",
    "الأوصر",
    "الوصر",
    "الوصرة",
    "الوصيرة",
    "وصصت",
    "وصع",
    "الوصع",
    "الوصيع",
    "أوصف",
    "واصفته",
    "اتصف",
    "تواصفوا",
    "توصف",
    "استوصف",
    "المستوصف",
    "المواصفة",
    "الوصاف",
    "الوصيف",
    "أوصله",
    "واصله",
    "اتصل",
    "تواصلا",
    "استوصلت",
    "المستوصلة",
    "الموصل",
    "الموصل المعزول",
    "الموصول",
    "الوصل",
    "الوصيل",
    "الوصيلة",
    "وصمه",
    "يصمه",
    "توصم",
    "الوصمة",
    "الوصواص",
    "الوصوص",
    "وصى",
    "أوصى",
    "تواصى",
    "الواصية",
    "الوصاة",
    "الوصاية",
    "الوصي",
    "الوصية",
    "وضأه",
    "يضؤه",
    "واضأه",
    "توضأ",
    "المتوضأ",
    "الميضأة",
    "الوضوء",
    "الوضاء",
    "وضحه",
    "اتضح",
    "استوضح",
    "الأواضح",
    "الأوضاح",
    "المتوضح",
    "الموضحة",
    "الواضح",
    "الواضحة",
    "الوضح",
    "الوضاح",
    "الوضيحة",
    "وضخ",
    "أوضخت",
    "واضخه",
    "تواضخا",
    "يوضر",
    "وضره",
    "الوضر",
    "أوضع",
    "واضع",
    "استوضع",
    "التوضيع",
    "الضعة",
    "الموضع",
    "الموضوع",
    "الموضوعة",
    "الواضعة",
    "الوضع",
    "الوضاع",
    "الوضيعة",
    "وضم",
    "أوضم",
    "استوضمه",
    "الوضم",
    "الوضمة",
    "الوضيم",
    "الوضيمة",
    "وضن",
    "أوضن",
    "اتضن",
    "توضن",
    "الموضونة",
    "الميضنة",
    "الوضنة",
    "الوضين",
    "وطؤ",
    "واطأ",
    "تواطأ",
    "توطأ",
    "استوطأ",
    "الإيطاء",
    "الطأة والطئة",
    "الموطأ",
    "الميطاء",
    "الوطاء",
    "الوطاءة",
    "الوطأة",
    "الوطيء",
    "الوطيئة",
    "الوطب",
    "الوطباء",
    "الوطبة",
    "وطحه",
    "يطحه",
    "تواطحت",
    "الوطح",
    "وطده",
    "توطد",
    "اتطد",
    "المتوطد",
    "الميطدة",
    "الوطيدة",
    "الوطر",
    "وطس",
    "تواطس",
    "الوطاس",
    "الوطيس",
    "وط",
    "وطف",
    "أوطف",
    "الأوطف",
    "وطمه",
    "يطمه",
    "وطم",
    "وطن",
    "أوطن",
    "واطنه",
    "اتطن",
    "توطن",
    "استوطن",
    "الميطان",
    "وطوط",
    "توطوط",
    "الوطواط",
    "الوطواطة",
    "الوطواطي",
    "تواظبت",
    "الموظوب",
    "الموظوبة",
    "الميظب",
    "الوظبة",
    "واظفه",
    "وظفه",
    "الوظيف",
    "الوظيفة",
    "وعبه",
    "يعبه",
    "أوعب",
    "استوعبه",
    "الوعب",
    "الوعيب",
    "أوعث",
    "وعثه",
    "الأوعث",
    "الموعث",
    "الموعوث",
    "الوعث",
    "الوعثاء",
    "الوعثة",
    "الوعوث",
    "أوعد",
    "واعده",
    "تواعدوا",
    "توعده",
    "الموعد",
    "الموعود",
    "الواعد",
    "الواعدة",
    "الوعيد",
    "أوعر",
    "توعر",
    "استوعر",
    "الأوعر",
    "الواعر",
    "الوعر",
    "الوعير",
    "وعز",
    "وعسه",
    "يعسه",
    "أوعس",
    "واعست",
    "الأوعس",
    "الميعاس",
    "الوعس",
    "الوعساء",
    "الوعسة",
    "وعظه",
    "الموعظة",
    "الواعظ",
    "وعف",
    "أوعف",
    "الوعف",
    "توعق",
    "استوعق",
    "الوعاق",
    "الوعق",
    "الوعقة",
    "الوعيق",
    "أوعكت",
    "الموعوك",
    "يعل",
    "توعل",
    "استوعل",
    "المستوعل",
    "وعم",
    "الوعم",
    "توعنت",
    "الوعان",
    "الوعن",
    "الوعنة",
    "الوعواع",
    "الوعوع",
    "الوعوعة",
    "الوعوعي",
    "وع ى",
    "أوعى",
    "استوعى",
    "الموعي",
    "الواعية",
    "الوعاء",
    "الوعى",
    "الوعية",
    "وغب",
    "الوغب",
    "الوغبة",
    "واغده",
    "المواغدة",
    "وغرت",
    "أوغر",
    "وغره",
    "توغر",
    "الميغر",
    "الوغر",
    "الوغرة",
    "الوغير",
    "الوغيرة",
    "وغف",
    "يغف",
    "أوغف",
    "الوغف",
    "أوغل",
    "الواغل",
    "الوغل",
    "وغم",
    "أوغمه",
    "تواغم",
    "توغم",
    "الوغم",
    "الوغى",
    "الوغ ى",
    "وفد",
    "وافده",
    "وفده",
    "توافد",
    "توفد",
    "استوفد",
    "الأوفاد",
    "الوافد",
    "الوفاد",
    "الوفد",
    "أوفر",
    "اتفر",
    "توافر",
    "استوفر",
    "الموفور",
    "الوافرة",
    "الوفر",
    "الوفراء",
    "الوفير",
    "يفز",
    "أوفزه",
    "وافزه",
    "توفز",
    "استوفز",
    "الوفز",
    "أوفض",
    "استوفض",
    "الأوفاض",
    "الميفاض",
    "الوفض",
    "الوفضة",
    "الوفاع",
    "الوفع",
    "الوفعة",
    "الوفيعة",
    "وفق",
    "أوفق",
    "وافق",
    "اتفق",
    "توافقت",
    "توفق",
    "استوفق",
    "الاتفاق",
    "الاتفاقية لدولية",
    "التوفاق",
    "التوفق",
    "التوفيق",
    "التيفاق",
    "المتفق",
    "الوفاق",
    "الوفق",
    "وفل",
    "وفله",
    "الوافل",
    "الوفل",
    "وفه",
    "يوفه",
    "الوافه",
    "الوفاهة",
    "الوفهية",
    "وافى",
    "توافى",
    "استوفى",
    "الميفى",
    "الميفاء",
    "الوافي",
    "الوافية",
    "الوفاة",
    "الوفي",
    "وقبت",
    "تقب",
    "أوقب",
    "الأوقاب",
    "الميقاب",
    "الميقب",
    "الوقب",
    "الوقباء",
    "الوقبة",
    "الوقبي",
    "يقته",
    "الميقات",
    "الوقت",
    "توقح",
    "الموقح",
    "الوقاح",
    "وقد",
    "اتقدت",
    "توقدت",
    "استوقدت",
    "الميقاد",
    "الوقاد",
    "الوقد",
    "الوقدة",
    "الوقود",
    "الوقيد",
    "وقذت",
    "أوقذه",
    "الموقذ",
    "الموقذة",
    "الموقوذ",
    "الموقوذة",
    "الوقيذ",
    "الوقيذة",
    "أوقرت",
    "اتقر",
    "استوقر",
    "الموقر",
    "الموقرة",
    "الموقور",
    "الموقورة",
    "الوقار",
    "الوقر",
    "الوقرة",
    "الوقري",
    "الوقور",
    "الوقير",
    "الوقيرة",
    "وقس",
    "الأوقاس",
    "الوقس",
    "وقش",
    "أوقش",
    "توقش",
    "الأوقاش",
    "الوقش",
    "الوقشة",
    "وقصت",
    "أوقصه",
    "تواقص",
    "توقص",
    "الأوقاص",
    "الموقوص",
    "الوقص",
    "الوقيصة",
    "وقطه",
    "استوقط",
    "الموقوط",
    "الوقط",
    "الوقيط",
    "وقع",
    "واقعه",
    "تواقع",
    "استوقع",
    "الإيقاع",
    "الموقع",
    "الموقعة",
    "الموقوع",
    "الموقوعة",
    "الميقعة",
    "الواقع",
    "الواقعة",
    "الوقاعة",
    "الوقعة",
    "الوقاع",
    "الوقيع",
    "الوقيعة",
    "واقفه",
    "تواقف",
    "استوقفه",
    "التوقيف",
    "التوقيفي",
    "الموقف",
    "الموقفان",
    "الموقفة",
    "الموقوف",
    "الميقاف",
    "الميقف",
    "الواقف",
    "الواقفة",
    "الواقفية",
    "الوقف",
    "الوقاف",
    "الوقيفة",
    "الوق",
    "وقل",
    "توقل",
    "الوقل",
    "الوقلة",
    "وقم",
    "وقمت",
    "أوقمه",
    "توقم",
    "الوقام",
    "أوقن",
    "توقن",
    "الوقنة",
    "الموقونة",
    "الوقواقة",
    "وقاه",
    "اتقى",
    "توقاه",
    "التقاة",
    "التقوى",
    "التقية",
    "التقي",
    "الموقى",
    "الوقاء",
    "الوقاية",
    "الوقية",
    "تكئ",
    "أتكأ",
    "أوكأ",
    "واكأ",
    "توكأ",
    "التكأة",
    "المتكأ",
    "المتكئ",
    "أوكب",
    "واكب",
    "الواكبة",
    "الوكاب",
    "الوكوب",
    "وكت",
    "الموكوت",
    "الوكت",
    "الوكتة",
    "الوكات",
    "الوكيت",
    "استوكث",
    "الوكاث",
    "وكحه",
    "أوكح",
    "استوكح",
    "الأوكح",
    "الوكح",
    "أوكد",
    "توكد",
    "التواكيد",
    "التوكيد",
    "المتوكد",
    "المواكدة",
    "الوكاد",
    "الوكد",
    "أوكر",
    "اتكر",
    "توكر",
    "الوكر",
    "الوكرى",
    "الوكرة",
    "الوكار",
    "الوكير",
    "الوكيرة",
    "توكز",
    "الوكزى",
    "أوكس",
    "الأوكس",
    "الوكس",
    "وكظ",
    "واكظ",
    "توكظ",
    "أوكع",
    "واكع",
    "اتكع",
    "استوكع",
    "الميكع",
    "الميكعة",
    "الوكعاء",
    "الوكيع",
    "أوكف",
    "واكفه",
    "تواكف",
    "توكف",
    "استوكف",
    "الواكف",
    "الوكاف",
    "الوكف",
    "الوكوف",
    "الوكيف",
    "واكلت",
    "وكله",
    "التكلان",
    "التكلة",
    "المواكل",
    "الواكل",
    "الوكال",
    "الوكالة",
    "الوكل",
    "الوكلة",
    "الوكيل",
    "وكمه",
    "وكم",
    "وكمت",
    "وكن",
    "توكن",
    "الموكن",
    "الموكنة",
    "الواكن",
    "الوكن",
    "الوكنة",
    "وكوك",
    "أوكى",
    "استوكى",
    "الموكى",
    "الوكاء",
    "ولب",
    "الوالبة",
    "أولته",
    "ولث",
    "الوالث",
    "الولث",
    "أولجه",
    "اتلج",
    "التلج",
    "المولج",
    "الوالجة",
    "الولاج",
    "الولجة",
    "الوليجة",
    "ولخه",
    "أولخ",
    "ائتلخ",
    "أولدت",
    "توالدوا",
    "استولد",
    "المولدة",
    "المولود",
    "الوالد",
    "الوالدان",
    "الوالدة",
    "الولد",
    "الولود",
    "الوليد",
    "الوليدة",
    "الوليدية",
    "ولست",
    "أولس",
    "والست",
    "توالس",
    "الولس",
    "الولاس",
    "أولعه",
    "أولع",
    "اتلعت",
    "ايتلع",
    "الوالع",
    "الوالعة",
    "الولعة",
    "الولوع",
    "الوليع",
    "ولغ",
    "أولغ",
    "استولغ",
    "الميلغ",
    "الولغة",
    "ولف",
    "أولف",
    "والفه",
    "توالف",
    "الولاف",
    "الوليف",
    "ولق",
    "الولق",
    "الولقى",
    "الولم",
    "الولمة",
    "الوليمة",
    "وله",
    "أولهه",
    "ولهه",
    "الموله",
    "الميلاه",
    "الميله",
    "ولولت",
    "تولولت",
    "المولول",
    "الولوال",
    "تمولى",
    "المتوالي",
    "الموالاة",
    "المواليا",
    "المولى",
    "المولوي",
    "المولوية",
    "الولاء",
    "الولاية",
    "الولي",
    "الولية",
    "أومأ",
    "الوامئة",
    "ومد",
    "الومد",
    "الومدة",
    "ومز",
    "ومس",
    "أومست",
    "المومسة",
    "ومقه",
    "وامقه",
    "توامقوا",
    "تومق",
    "تومن",
    "ومه",
    "الومهة",
    "ومى",
    "أومى",
    "استومى",
    "الومى",
    "الونج",
    "الونع",
    "ونم",
    "الونمة",
    "الونيم",
    "أوناه",
    "الواني",
    "الوناة",
    "الوني",
    "الونية",
    "أوهب",
    "واهبه",
    "اتهب",
    "تواهب",
    "استوهب",
    "الموهوب",
    "وهت",
    "أوهت",
    "الوهتة",
    "وهث",
    "توهث",
    "الواهث",
    "وهجت",
    "أوهج",
    "توهجت",
    "الوهجان",
    "الوهاج",
    "الوهيج",
    "وهد",
    "توهد",
    "أوهد",
    "الوهد",
    "الوهدة",
    "وهره",
    "يهره",
    "توهر",
    "المستوهر",
    "الواهر",
    "الوهر",
    "الوهرة",
    "وهزه",
    "يهزه",
    "توهز",
    "الأوهز",
    "الموهز",
    "الوهازة",
    "الوهز",
    "وهس",
    "توهس",
    "الوهس",
    "الوهاس",
    "الوهيسة",
    "وهص",
    "اتهص",
    "الموهوص",
    "الموهص",
    "الوهصة",
    "الوهاص",
    "وهط",
    "أوهط",
    "توهط",
    "الوهط",
    "الوهطة",
    "وهف",
    "أوهف",
    "الواهف",
    "الوهافة",
    "وهق",
    "أوهق",
    "واهقه",
    "تواهق",
    "وهل",
    "وهله",
    "توهله",
    "استوهل",
    "المستوهل",
    "الواهلة",
    "الوهلة",
    "وهمه",
    "توهم",
    "التهمة والتهمة",
    "التهيم",
    "المتهم والمتهم",
    "الموهوم",
    "الوهم",
    "وهن",
    "وهنه",
    "توهن",
    "الموهون",
    "الواهن",
    "الواهنة",
    "الوهنانة",
    "الوهين",
    "الوه",
    "وهوه",
    "الموهوهة",
    "الوهواه",
    "الوهوهة",
    "وهى",
    "أوهاه",
    "الأوهية",
    "الوهي",
    "الوهية",
    "الويبة",
    "الويج",
    "ويح",
    "الويس",
    "ويس",
    "توايلا",
    "تويل",
    "الويلة",
    "ويلمه",
    "الوانة",
    "الوين",
    "الوينة",
    "ويه وويها",
    "الياء",
    "يا",
    "الياردة",
    "أيأسه",
    "يأسه",
    "اتأس",
    "اليأس",
    "يأيأ",
    "اليأياء",
    "اليؤيؤ",
    "يببه",
    "اليباب",
    "ييبس وييبس",
    "يابسه",
    "يبسه",
    "اتبس",
    "الأيبس",
    "الميباس",
    "اليابس",
    "اليباس",
    "يباس",
    "اليبسة",
    "اليبوسة",
    "اليبيس",
    "اليتوع",
    "ييتم",
    "أيتمت",
    "يتمه",
    "الميتمة",
    "اليتم",
    "اليتيم",
    "اليتيمة",
    "يتنت",
    "أيتنت",
    "أيدع",
    "يدعه",
    "الأيدع",
    "يداه",
    "يديه",
    "يدي",
    "أيدى",
    "ياداه",
    "اليد",
    "اليداء",
    "اليدي",
    "ييرع",
    "اليراعة",
    "اليرع",
    "يرق",
    "اليارق",
    "اليرقانة",
    "اليرون",
    "يرنأ",
    "اليرنأ",
    "اليرناء",
    "أيسر",
    "ياسر",
    "اتسر",
    "تياسر",
    "تيسر",
    "استيسر",
    "الأيسر",
    "التيسور",
    "الموسر",
    "الميسر",
    "الميسرة",
    "الميسور",
    "الياسر",
    "اليسار",
    "اليسارى",
    "يسار",
    "اليسارة",
    "اليسر",
    "اليسرى",
    "اليسرة",
    "اليسير",
    "في لغة أهل مصر",
    "اليعبوب",
    "اليعار",
    "اليعارة",
    "اليعر",
    "اليعرة",
    "اليعور",
    "اليعورة",
    "يغوث",
    "يفخه",
    "ييفخه",
    "أيفع",
    "تيفع",
    "اليافع",
    "اليافعات",
    "اليفاع",
    "اليفع",
    "اليفعة",
    "اليفن",
    "الياقوت",
    "أيقظه",
    "يقظه",
    "استيقظ",
    "اليقظ",
    "اليقظان",
    "اليقظة",
    "أيقنه",
    "تيقنه",
    "استيقنه",
    "الميقان",
    "اليقن",
    "اليقنة",
    "اليقين",
    "يك",
    "اليلب",
    "اليلق",
    "اليلقة",
    "يل ويلل",
    "اليلاء",
    "يممه",
    "تيمم",
    "الميمم",
    "الياموم",
    "اليمام",
    "ييمن",
    "أيمن",
    "تيامن",
    "استيمن",
    "الأيمن",
    "الميمن",
    "اليامن",
    "اليماني واليماني",
    "اليمانية",
    "اليمن",
    "اليمنى",
    "اليمنة",
    "اليمين",
    "اليميني",
    "أينع",
    "اليانع",
    "الينع",
    "الينيع",
    "الينم",
    "يهم",
    "ييهم",
    "الأيهم",
    "الأيهمان",
    "اليهماء",
    "يا هياه",
    "يهيه",
    "يوح",
    "يوحى",
    "ياومه",
    "الأيوم",
    "اليوم"
];

export default words;
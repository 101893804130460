export const frequentLetters = ['ا', 'ل', 'م',  'ن',  'و',  'ي', 'ه',  'ر',  'ب',  'ت', 'ك', 'ع',  'ف', 'ق'];

export const allLetters = [
    'ا',  'ب',  'ت',  'ث',  'ج',  'ح',  'خ', 'د',  'ذ',  'ر',  'ز',  'س',  'ش',
     'ص',  'ض',  'ط',  'ظ',  'ع',  'غ',  'ف',  'ق',  'ك',  'ل',  'م',  'ن',  'ه',  'و',  'ي',  'أ', 'آ', 'ء', 'ؤ', 'إ', 'ئ', 'ى', 'ة'
];

export const invalidChars = [
    '(', ')', 'a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v',
    'w','x','y','z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0',
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V',
    'W', 'X', 'Y', 'Z'
];

export const arabicNumbers = ["٠","١","٢","٣","٤","٥","٦","٧","٨","٩",];
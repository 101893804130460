import * as bigWords from '../words/bigWords';
import * as freqLetters from '../words/frequentLetters';

export const createObjectList = (dictionary) => {
    let obj = {};
    for (let i = 0; i < dictionary.length; i++) {
        obj[dictionary[i]] = 1;
    }
    //console.log(obj);
    return obj;
}

export const generateTodaysWord = (wordArray) => {
    //getBigWords(wordArray);
    //return;
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const startDate = new Date(2022, 7, 26); // Months start at 0
    const today = new Date();
    let diffDays = Math.floor(Math.abs((startDate - today) / oneDay));
    diffDays = diffDays % bigWords.bigWords.length;
    // Get today's word
    let options = bigWords.bigWords;
    return options[diffDays];
}

export const simplerAllValid = (wordsArr, word) => {
    // 1. Loop through all dictionary words
    // 2. Loop through chars of that word, check if it has unaccepted char
    let currWord = '';
    let splitWord = word.split('');
    let invalidLetters = freqLetters.allLetters.filter(x => !splitWord.includes(x));
    let validWords = [];
    let isValid = true;
    //console.log(word + ' word');
    let middleIndex = Math.floor(word.length / 2);
    //console.log(word[middleIndex]);
    for (let i = 0; i < wordsArr.length; i++) {
        isValid = true;
        currWord = wordsArr[i];
        if (currWord.length >= 4) {
            for (let j = 0; j < currWord.length; j++) {
                let currChar = currWord[j];
                if (invalidLetters.includes(currChar) || freqLetters.invalidChars.includes(currChar)) {
                    isValid = false;
                    // Skip this word and go to the next possible option
                    break;
                }
            }
            // Needs to have middle letter
            if (isValid && currWord.includes(word[middleIndex])) {
                validWords.push(currWord);
            }
        }
    }
    validWords = [...new Set(validWords)];
    //console.log(validWords);
    return validWords;
}

// Not in use (used to manually generate bigwords)
/*export const tester = () => {
    // Loop until we find a suitable word
    let newwords = [];
    let options = bigWords.bigWords;
    for (let j = 0; j < options.length; j++) {
        let counter = 0;
        let word = options[j];
        let usedLetters = [];
        for (let i = 0; i < freqLetters.frequentLetters.length; i++) {
            if (word.includes(freqLetters.frequentLetters[i]) && !usedLetters.includes(freqLetters.frequentLetters[i])) {
                usedLetters.push(freqLetters.frequentLetters[i]);
                counter++;
            }
            if (counter === 4) {
                newwords.push(word);
            }
        }
    }
    newwords = [...new Set(newwords)];
    console.log(newwords);
}*/

// Code for checking stats
/*for (let i = 0; i < bigWords.bigWords.length; i++) {
			//let wordWithSprinkledFreqs = bigWords.bigWords[i];
			//console.log(bigWords.bigWords[i] + freqLetters.frequentLetters[13]);
			//bigWords.bigWords[i] = bigWords.bigWords[i] + freqLetters.frequentLetters[13] + freqLetters.frequentLetters[12] + freqLetters.frequentLetters[11];
			let found = false;
			let counter = 0;
			while (counter < 3) {
				let rand = Math.floor(Math.random() * ((freqLetters.frequentLetters.length -1) - 0 + 1) + 0);
				if (!wordWithSprinkledFreqs.includes(freqLetters.frequentLetters[rand])) {
					wordWithSprinkledFreqs += freqLetters.frequentLetters[rand];
					counter++;
				}
				bigWords.bigWords[i] = wordWithSprinkledFreqs;
			}
			allValidWords = Generator.getAllWords(bigWords.bigWords[i], allWordsObj);
			if (allValidWords.length > 7) {
				//console.log(bigWords.bigWords[i] + ' word: ' + allValidWords.length);
			}
}*/

// Not in used (used to manually generate bigWords)
/*export const getBigWords = (wordArray) => {
    let arr = [];
    for (let i = 0; i < wordArray.length; i++) {
        let uniqLetters = [...new Set(wordArray[i])];
        //console.log(uniqLetters);
        if (uniqLetters.length === 9) {
            arr.push(wordArray[i]);
        }
    }
    arr = [...new Set(arr)];

    // Loop until we find a suitable word
    let newwords = [];
    for (let j = 0; j < arr.length; j++) {
        let counter = 0;
        let word = arr[j];
        let usedLetters = [];
        for (let i = 0; i < freqLetters.frequentLetters.length; i++) {
            if (word.includes(freqLetters.frequentLetters[i]) && !usedLetters.includes(freqLetters.frequentLetters[i])) {
                usedLetters.push(freqLetters.frequentLetters[i]);
                counter++;
            }
            if (counter === 4) {
                newwords.push(word);
            }
        }
    }
    newwords = [...new Set(newwords)];
    console.log(newwords);

    return arr;
}*/

/*export const getAllWords = (word, dictionary) => {
    let letters = word.split('');
    let allPossibleWords = [];
    // Start from words with at least 2 characters
    for (let i = 2; i <= word.length; i++) {
        allPossibleWords.push(tree(letters));
    }*/
    /*let test = tree(letters).map(function(str) {
        return str.join('')
    }));
    let test = getAnagrams(word);
    console.log(test);
    // Get all valid words
    return getAllValidWords(allPossibleWords, dictionary);
}*/

/*const getAllValidWords = (possibleWords, dictionary) => {
    let validWords = [];
    for (let i = 0; i < possibleWords.length; i++) {
        let batch = possibleWords[i];
        for (let j = 0; j < batch.length; j++) {
            let guessWithAl = 'ال' + batch[j];
            if (dictionary[batch[j]] !== undefined || dictionary[guessWithAl] !== undefined) {
                validWords.push(batch[j]);
            }
        }
    }
    validWords = [ ...new Set(validWords) ];
    console.log(validWords);
    return validWords;
}*/

/*
const generateWasitWords = () => {
    let arr = words;
		let wasit = Object.entries(was.wasit);
		for (let i = 0; i < wasit.length; i++) {
			let obj = wasit[i];
			for (let j = 0; j < obj[1].length; j++) {
				let currWord = obj[1][j][0];
				arr.push(currWord);
			}
		}
		arr = [...new Set(arr)];
		console.log(arr);
}*/
export const bigWords = [
    "الإستمرارية",
    "الكمبيوتر",
    "للمتقاعدين",
    "الإبتدائية",
    "جرامسنتيمترفي",
    "المختطفون",
    "المتغيبون",
    "المستوعبة",
    "المستقبلون",
    "الأكورديون",
    "أسيتامينوفين",
    "الأكتينيوم",
    "البيروقراطية",
    "المستشارون",
    "أليكساندر",
    "الأمريكيون",
    "الأمريكتين",
    "الأمفيبولات",
    "أنجسترومز",
    "الأنهيدريت",
    "أنثربولوجي",
    "الأفرودايت",
    "المستأنفون",
    "المبتدئون",
    "العنكبوتيات",
    "الأرستقراطي",
    "أريستوكراتيكال",
    "أرستقراطية",
    "أريستوتيلايان",
    "الإصطناعية",
    "المتسكعون",
    "الإستجماتيزم",
    "الأتروبين",
    "الأوريومايسين",
    "الأستراليون",
    "الإستبدادية",
    "إلكترونيات",
    "أزيربيدجاني",
    "الأذريبيجاني",
    "الكترونية",
    "الدرابزينات",
    "المصرفيون",
    "المتبرعون",
    "المتعصبون",
    "الالكترونية",
    "العنقودية",
    "الهصطونية",
    "المتفاخرون",
    "المقترضون",
    "البورجوازيون",
    "البرجوازية",
    "اﻷرستقراطية",
    "برانتشينجز",
    "برانتشفيل",
    "البريدفروت",
    "بريدجتاون",
    "بتشينوالد",
    "بليتسنمبيرز",
    "المتشردون",
    "الأرقطيون",
    "البيروقراطيون",
    "المتفرجون",
    "العسكريون",
    "كاليفورنيوم",
    "بالخيزرانة",
    "كابيسترانو",
    "الرأسماليون",
    "الكربوراندم",
    "الكاثدرائية",
    "الكاثدرائيات",
    "المعترضون",
    "المستشارية",
    "تشارليستون",
    "تشارلوتيسفيل",
    "كولينيستيريز",
    "كروماتوغرافي",
    "الإسكافيون",
    "الإستعماري",
    "المستعمرون",
    "الإنضغاطية",
    "الكونستوغة",
    "كونفدرالي",
    "المعترفون",
    "المخروطية",
    "المخروطيات",
    "الصنوبرية",
    "الصنوبريات",
    "الإنتخابية",
    "الدستورية",
    "المستهلكون",
    "المتسابقون",
    "الكوبرهيد",
    "الكورسيهات",
    "الكورتيزون",
    "الكونتيسة",
    "المعالجالتقليب",
    "الكروشيهات",
    "التلفزيون)",
    "كولبيرتسون",
    "السيتوبلازم",
    "تشيكوسلوفاكيا",
    "تشيكوسلوفاكي",
    "المحترفون",
    "الدنماركيون",
    "دارلينجتون",
    "دافينبورت",
    "الإنهزامية",
    "الإنهزاميون",
    "المستحضرة",
    "المغناطيسية",
    "الديماغوجية",
    "الديماغوجيون",
    "الديمقراطيات",
    "الديمقراطية",
    "الديموقراطي",
    "المتظاهرون",
    "ديوكسيريبونكلييك",
    "المستنكرون",
    "بديكتاتورية",
    "المفترسون",
    "ديابوليكالنيس",
    "ديابوليكالنيسيس",
    "الدكتاتورية",
    "الدكتاتوريون",
    "ديثيلستيلبيسترول",
    "ديفراكتوميتر",
    "الإنتشارية",
    "إلكترونيا",
    "الديناصورات",
    "الدبلوماسية",
    "الدبلوماسيون",
    "المكتشفون",
    "المتدربون",
    "الدوغماتية",
    "ميكروسوفت)",
    "الإزدواجية",
    "المسرحيون",
    "الإلكتروني",
    "المقتصدون",
    "الآينشتانيوم",
    "الكهربائية",
    "كهروديناميكي",
    "المغناطيسات",
    "الإلكترونيات",
    "بالكهربائية",
    "المزخرفون",
    "التجريبيون",
    "إمبلاستيك",
    "المستخدمون",
    "الإمبراطورة",
    "الإمبراطورات",
    "إنفورسيبل",
    "المستفسرون",
    "المشتركون",
    "الأبيقوريون",
    "استوائيمجري",
    "أنثوغرافي",
    "المتهربون",
    "التبشيريون",
    "إفيرجلاديز",
    "الإنفجارية",
    "التعبيريون",
    "إكسبانجير",
    "الإبتزازيون",
    "المتطرفون",
    "الفهرنهايتية",
    "الإتحاديون",
    "بالمغناطيس",
    "النموذجية",
    "فيسشنابيليتي",
    "الفلوريسنت",
    "المنظورية",
    "المستقبلية",
    "فورمالديهايد",
    "الأربعينات",
    "إطارمجموعة",
    "الفرانسيسكانيون",
    "الفرنسيوم",
    "المستجدون",
    "الإحتكاكيون",
    "المتخبطون",
    "الجلفانومتر",
    "الجلفانومترات",
    "جالفيستون",
    "الأخصائيون",
    "الجغرافيون",
    "بالجيوفيزياء",
    "الإختصاصيون",
    "جيلبيرتسون",
    "انطلقتقديم",
    "انطلقفهرس",
    "متدرجألفا",
    "الإجتماعية",
    "الغضروفية",
    "دليلواحدللصفحة",
    "الكوجوراتية",
    "بالأفريقية",
    "المدفعيون",
    "الجيروسكوب",
    "هالفيرسون",
    "والمعدنية",
    "الهارمونيكا",
    "هاربسيكورد",
    "هاريسونبيرغ",
    "بالهليوغراف",
    "الهيموغلوبين",
    "هيكساكلورايد",
    "الهيروغليفية",
    "الهندوسية",
    "هولينجسورث",
    "هولوجرافيكال",
    "الأنكلستوما",
    "بالمستشفى",
    "النطاقالمضيف",
    "المضيفالبوابة",
    "الإستثنائي",
    "الهيدروجين",
    "الهيدرولوجية",
    "الهيدرومتر",
    "الهيدروكسيد",
    "الهيدروكسيل",
    "الهيبوسلفات",
    "هيبوكلورايت",
    "بالهايبوتلاموس",
    "الهايبوتلاموس",
    "المذهبيون",
    "والإلكترونيات",
    "الإمبرياليون",
    "المستوردون",
    "الإنطباعية",
    "الإنطباعيون",
    "إنديانابوليس",
    "اللاإنصهارية",
    "المبتكرون",
    "إنسيكتيفور",
    "المستجوبون",
    "الإختراعية",
    "المخترعون",
    "المستثمرون",
    "الأيونوسفير",
    "الأيسوثرم",
    "الإسرائيليون",
    "جابلونسكاي",
    "جاكسونفيل",
    "الإنكشاريون",
    "بالمجوهرات",
    "الكيلوغرامات",
    "الكيلوهيرتز",
    "الكليسترون",
    "نايتسبردج",
    "كريوزانجس",
    "الكريبتون",
    "قرغيزستان",
    "لاكيهورست",
    "الهيموجلوبين",
    "بالأنشوطة",
    "المستنزفون",
    "المشروعية",
    "المكتبيون",
    "الأرجوانية",
    "ليندستروم",
    "نهايةالسطر",
    "لوغاريثمية",
    "لاندكويست",
    "إلكترونية",
    "ماندلبروت",
    "الماركوفية",
    "بالأستمناء",
    "الإغريقية)",
    "الجرثومية",
    "ميتشيلانجيلو",
    "الميكروبات",
    "بالأقتصاديات",
    "الألكترونيات",
    "الميكروفيش",
    "الميكرونات",
    "بالكومبيوتر",
    "مايكروسوفت",
    "سيتوبلازم",
    "الكاتدرائية",
    "مايتوكوندريا",
    "المتحرشون",
    "الموليبدينيت",
    "المونتنيغري",
    "المستخدمين",
    "التلفزيونية",
    "النتروجليسرين",
    "كربوهيدرات",
    "المعكرونة",
    "نورثهامبتون",
    "المعطردون",
    "الأقيانوسية",
    "أوقيانوغرافي",
    "أولدينبيرغ",
    "أوبينهايمر",
    "الإنتهازية",
    "الإنتهازيون",
    "المضطهدون",
    "الكتروضوئي",
    "أوركسترالي",
    "كليتيمنيسترا",
    "أورثوكروماتي",
    "الإعتياديون",
    "الإحتفائية",
    "المؤكسدون",
    "الأوكسجين",
    "بالأكسجين",
    "الباكستانيون",
    "الأبرشيون",
    "الإختراقية",
    "المخترقون",
    "المتقاعدون",
    "للديمقراطية",
    "البيروكسيد",
    "فيلانثروب",
    "الفوتوغرافية",
    "فيتوبلانكتون",
    "البينكستر",
    "بكسلثانية",
    "الأفلاطونية",
    "المستهترون",
    "تشغيلإيقاف",
    "بوينديكستر",
    "البوليفوسفوريك",
    "بوليساكيريد",
    "البوليستيرين",
    "الذرائعيون",
    "البريزيوديميوم",
    "البسكويتة",
    "الكهنوتية",
    "المختبرون",
    "الإستغلاليون",
    "المقترحون",
    "البروتينية",
    "البروتستانتي",
    "البروتستانتيون",
    "بروتوبلازمي",
    "بروفيدانس",
    "المتصيدون",
    "والمدرسين",
    "البيروكسين",
    "راتشمانينوف",
    "المتشدقون",
    "المغتصبون",
    "بالإستدارة",
    "رايموندفيل",
    "المقروئية",
    "الإرتدائية",
    "المعتكفون",
    "الإنطوائية",
    "الإنعكاسية",
    "الإعتمادية",
    "الإنتقامية",
    "الجمهورية",
    "الجمهوريون",
    "الجمهوريات",
    "المستردون",
    "بالروماتيزم",
    "ريتشاردسون",
    "ريكينباوجه",
    "الميكروفون",
    "الرومانسية",
    "الرومانسيات",
    "المستديرة",
    "الجربنديات",
    "الزعفرانية",
    "ساكرامينتو",
    "السندويتش",
    "السندويتشات",
    "الإسكندنافيا",
    "السيناريوهات",
    "الإسكتلندي",
    "سكوتشجارد",
    "أسكوتلندا",
    "الأسقربوط",
    "الإقطاعيون",
    "الإنتقائية",
    "الإخصائيون",
    "الإستشارية",
    "الخادمالقنوات",
    "المستوطنة",
    "الشوفيلبورد",
    "سيلفيرشتاين",
    "المتحاربون",
    "المختبئون",
    "المتأبهون",
    "الإشتراكية",
    "السفسطائيون",
    "الإسفنجية",
    "سبرينجفيلد",
    "ستافوردشاير",
    "الإحصائيون",
    "المستقيمون",
    "الإستراتيجية",
    "ستريكلاند",
    "السترونتيوم",
    "المتعثرون",
    "الإستركنين",
    "الإستوديوهات",
    "الستايروفوم",
    "الكبريتوز",
    "السولفونامايد",
    "السوبرمانات",
    "التبديلالخلفية",
    "المتعاطفون",
    "السمفونيات",
    "السمفونية",
    "موالفةباصات",
    "موالفةداخلي",
    "الكونفوشية",
    "المستدرون",
    "تجيوسيغالبا",
    "بالتلفزيون",
    "الإرهابيون",
    "إقتباس نص",
    "الثرميونيات",
    "بالثرموستات",
    "ثايروجلوبولين",
    "بالثيروتروبين",
    "الثيروتروبين",
    "تيكونديروجا",
    "السمكريون",
    "الطوبوغرافي",
    "الطوبوغرافيا",
    "الطوربيدات",
    "التراجيديون",
    "الترامبولين",
    "تريكلورويثان",
    "تردياجونال",
    "ترايبوليفوسفيت",
    "المستبدون",
    "إستخدامية",
    "أنيلهيكسيوم",
    "البنفسجية",
    "أوزبكستان",
    "الإسطورية",
    "الاستثنائية",
    "فاندربيلت",
    "فاندربيرغه",
    "فاندربويل",
    "الإحتوائية",
    "فيلوسيرابتور",
    "الفيبرافونات",
    "الفيكتوريون",
    "فلاديفوستوك",
    "الإفتراضية",
    "فولكسواجين",
    "الإيتربيوم",
    "الزمندارية",
    "الزرادشتية",
    "الزركونيوم",
    "البلانكتونية"
];
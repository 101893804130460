import title1 from './title1.png';
import title2 from './title2.png';
import './App.css';
import React, {useState, useEffect} from 'react';
import words from './words/allWords';
import allWordsObj from './words/allWordsObj';
import * as bigWords from './words/bigWords';
import * as Generator from './Libraries/Generator';
import * as freqLetters from './words/frequentLetters';
import { FaQuestionCircle } from 'react-icons/fa';
import { FaChartBar } from 'react-icons/fa';
import { BiCoffee } from "react-icons/bi";
import { BsBackspace } from "react-icons/bs";
import LinearProgress from '@mui/material/LinearProgress';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import moment from 'moment';
import { initializeApp } from "firebase/app";
import { getAnalytics, setUserProperties, logEvent } from "firebase/analytics";
import { v4 as uuidv4 } from 'uuid';

const firebaseConfig = {
	apiKey: "AIzaSyCR3h7G_9U9e1MyeztdahEoVV61257jA98",
	authDomain: "tarkeeb-78140.firebaseapp.com",
	projectId: "tarkeeb-78140",
	storageBucket: "tarkeeb-78140.appspot.com",
	messagingSenderId: "68796412221",
	appId: "1:68796412221:web:e189aba6417165123e9554",
	measurementId: "G-YEV07T0SXX"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const userID = uuidv4();
setUserProperties(analytics, { userID: userID });

function App() {

	const [validWords, setValidWords] = useState([]);
	// User's current guess
	const [guess, setGuess] = useState('');
	const [correct, setCorrect] = useState([]);
	const [todaysWord, setTodaysWord] = useState('');
	const [middleIndex, setMiddleIndex] = useState(4);
	const [score, setScore] = useState(0);
	const [instrModalShown, setInstrModalShown] = useState(false);
	const [strkModalShown, setStrkModalShown] = useState(false);
	const [coffeeModalShown, setCoffeeModalShown] = useState(false);
	const [streak, setStreak] = useState(0);
	const [avgWordLength, setAvgWordLength] = useState(0);
	const [highestStreak, setHighestStreak] = useState(0);
	const [highScore, setHighScore] = useState(0);
	const [totalCharsEver, setTotalCharsEver] = useState(0);
	const [totalWordsGuessedEver, setTotalWordsGuessedEver] = useState(0);
	const [wonToday, setWonToday] = useState(false);
	var todaysDate = moment().format('MMDDYYYY');
	var yesterday = moment().subtract(1, 'days').format('MMDDYYYY');

	//todaysDate = moment().add(2, 'days').format('MMDDYYYY');
	//yesterday = moment().add(1, 'days').format('MMDDYYYY');

	const handleLetterClick = (e) => {
		let currentGuess = guess + e.target.value;

		// Conditions for a correct guess:
		// 1. Needs to be in word list
		// 2. Cannot be repeated
		// 3. Must be longer than 3 characters
		let guessWithAl = 'ال' + currentGuess;
		let guessWithW = 'و' + currentGuess;
		if (
			(allWordsObj[currentGuess] !== undefined || allWordsObj[guessWithAl] !== undefined || allWordsObj[guessWithW] !== undefined)
			&& !correct.includes(currentGuess)
			&& currentGuess.length > 3
			&& currentGuess.includes(todaysWord[middleIndex])
		) {
			let tempCorrect = correct.slice(); // Used to store localStorage since state wouldn't have been updated yet
			tempCorrect.push(currentGuess);
			setCorrect(correct => [...correct, currentGuess]);
			setGuess('');
			// Handle stats
			handleStats(currentGuess);
			localStorage.setItem('todaysGuesses_' + todaysDate, tempCorrect);
			//console.log(localStorage.getItem('todaysGuesses_' + todaysDate));

		} else {
			setGuess(guess + '' + e.target.value);
		}
	};

	const handleStats = (currentGuess) => {
		// Scoring based on word length - 4 ^ 2
		//let wordScore = Math.pow(2, currentGuess.length - 4);
		let newScore = score + 1
		localStorage.setItem('score', newScore);
		//console.log(localStorage.getItem('score'));
		setScore(score + 1);
		// If 20 words are found, streak is unlocked
		if (correct.length + 1 === 20) {
			setWonToday(true);
			localStorage.setItem(todaysDate, 'true');
			localStorage.setItem('streak', streak + 1);
			// If streak is > highestStreak, set new highestStreak
			//console.log(streak + 1);
			//console.log(highestStreak + ' highest');
			if (streak + 1 > highestStreak) {
				setHighestStreak(streak + 1);
				localStorage.setItem('highestStreak', streak + 1);
			}
			setStreak(streak + 1);
			setStrkModalShown(true);
			logEvent(analytics, 'user_won', {
				word: todaysWord,
				date: todaysDate,
				words: correct
			});
		}
		// If score is > highscore, set new highscore
		if (score + 1 > highScore) {
			setHighScore(score + 1);
			localStorage.setItem('highScore', score + 1);
		}
		// Update total chars
		let newTotalChars = totalCharsEver + currentGuess.length;
		localStorage.setItem('totalChars', newTotalChars);
		setTotalCharsEver(newTotalChars);
		// Update total words
		let newTotalWords = totalWordsGuessedEver + 1;
		localStorage.setItem('totalWords', newTotalWords);
		setTotalWordsGuessedEver(newTotalWords);
		// Update avg word length
		let newAvg = newTotalChars / newTotalWords;
		localStorage.setItem('avgWordLength', newAvg);
		setAvgWordLength(newAvg);
	}

	// Handle delete char
	const handleBackSpace = () => {
		let newGuess = guess.substring(0, guess.length - 1);
		setGuess(newGuess);
	}


	// Translate score to arabic numbers for display
	const translateScore = () => {
		let scoreString = score.toString();
		scoreString = scoreString.split('');
		let translated = '';
		for (let i = 0; i < scoreString.length; i++) {
			let currChar = scoreString[i];
			translated += freqLetters.arabicNumbers[currChar];
		}
		return translated;
	};

	// Return correct guesses as an arabic-comma-separated string
	const printCorrect = () => {
		let str = '';
		for (let i = 0; i < correct.length; i++) {
			if (i > 0) {
				str += '، ' + correct[i];
			} else {
				str += correct[i];
			}
		}
		return str;
	}

	// Open instructions modal
	const openInstrModal = () => {
		setInstrModalShown(true);
		logEvent(analytics, 'open_instructions');
	};

	// Close instructions modal
	const closeInstrModal = () => {
		setInstrModalShown(false);
	}

	// Open streak modal
	const openStrkModal = () => {
		setStrkModalShown(true);
		logEvent(analytics, 'open_stats');
	};

	// Close streak modal
	const closeStrkModal = () => {
		setStrkModalShown(false);
	}

	// Open coffee modal
	const openCoffeeModal = () => {
		setCoffeeModalShown(true);
		logEvent(analytics, 'open_coffee_modal');
	}

	// Close coffee modal
	const closeCoffeeModal = () => {
		setCoffeeModalShown(false);
	}

	// Get user's streak
	const getStreak = () => {
		let wonYesterday =  localStorage.getItem(yesterday);
		let currStreak = localStorage.getItem('streak');
		if (wonYesterday === null) {
			localStorage.setItem('streak', 0);
			return 0;
		} else {
			localStorage.setItem('streak', currStreak);
			return parseInt(currStreak);
		}
	}

	// Get user's highest streak
	const getHighestStreak = () => {
		let highest =  localStorage.getItem('highestStreak');
		if (highest === null) {
			return 0;
		}
		return parseInt(highest);
	}

	// Get user's total character count
	const getTotalChars = () => {
		let allChars =  localStorage.getItem('totalChars');
		if (allChars === null) {
			return 0;
		}
		return parseInt(allChars);
	}

	// Get user's total word count
	const getTotalWords = () => {
		let allWords =  localStorage.getItem('totalWords');
		if (allWords === null) {
			return 0;
		}
		return parseInt(allWords);
	}

	// Get user's average word length
	const getAvgWordLength = () => {
		let avgLength =  localStorage.getItem('avgWordLength');
		if (avgLength === null) {
			return 0;
		}
		return avgLength;
	}

	// Get user's highest score
	const getHighScore = () => {
		let hScore =  localStorage.getItem('highScore');
		if (hScore === null) {
			return 0;
		}
		return parseInt(hScore);
	}

	// Get user's current score
	const getScore = (newWord) => {
		let guesses = getCorrectWords(newWord);
		if (guesses.length === 0) {
			return 0;
		}
		let currScore =  localStorage.getItem('score');
		if (currScore === null) {
			return 0;
		}
		return parseInt(currScore);
	}

	const clearYesterday = () => {
		localStorage.setItem('todaysGuesses_' + todaysDate, []);
		localStorage.setItem('score', 0);
		setScore(0);
		// Correct will be set to [] when returning this function
	};

	// Get today's correct words
	const getCorrectWords = (newWord) => {
		let correctWords =  localStorage.getItem('todaysGuesses_' + todaysDate);
		if (correctWords === null) {
			return [];
		}
		let localWord = localStorage.getItem('word');
		if (localWord === newWord) {
			//correctWords = correctWords.replace(/\s/g, '');
			correctWords = correctWords.split(',');
			if (correctWords.length >= 15) {
				setWonToday(true);
			}
			return correctWords;
		} else {
			clearYesterday();
			return [];
		}
	}

	const openInNewTab = (url) => {
		logEvent(analytics, 'open_coffee');
    	window.open(url, '_blank', 'noopener,noreferrer');
	};

	// Check if user ID is in localStorage and show instructions based on that
	const showInstructionsFirstTime = () => {
		let localUserID =  localStorage.getItem('userID');
		if (localUserID === null) {
			localStorage.setItem('userID', userID);
			setInstrModalShown(true);
		}
	}

	useEffect(() => {
		//localStorage.clear();
		showInstructionsFirstTime();
		setStreak(getStreak());
		setHighestStreak(getHighestStreak());
		setTotalCharsEver(getTotalChars());
		setTotalWordsGuessedEver(getTotalWords());
		setAvgWordLength(getAvgWordLength());
		setHighScore(getHighScore());
		// Generate today's random word
		let newWord = Generator.generateTodaysWord(words);
		//newWord = 'منفتح';
		newWord = newWord.split('');
		newWord = [...new Set(newWord)];
		newWord = newWord.toString();
		newWord = newWord.replace(/,/g, '');
		//console.log(newWord + ' newword');
		setMiddleIndex(Math.floor(newWord.length / 2));
		setTodaysWord(newWord);
		// if the newWord has been updated, this means we should clear the current answers
		setCorrect(getCorrectWords(newWord));
		localStorage.setItem('word', newWord);
		setScore(getScore(newWord));
		let allValidWords = Generator.simplerAllValid(words, newWord);
		//console.log(allValidWords.length);
		setValidWords(allValidWords);
	}, []);

	const modalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '20em',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
		outline: 0,
		textAlign: 'right'
	};

	const coffeeModalStyle = {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '20em',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
		outline: 0,
		textAlign: 'right'
	}

	return (
		<div className="App">
			<Modal
				open={instrModalShown}
				onClose={closeInstrModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				>
				<Box sx={modalStyle}>
					<h2 className="align-center">طريقة اللعب</h2>
					<p>ركّب أكبر عدد من الكلمات باستخدام الأحرف التسعة</p>
					<p> :مثلاً</p>
					<button className="letter-button" value="ح">ح</button>
					<button className="letter-button" value="ا">ا</button>
					<button className="letter-button" value="ف">ف</button>
					<button className="middle-letter" value="ت">ت</button>
					<p>استخدام الحرف الأزرق اجباري لكل كلمة</p>
					<button className="middle-letter" value="ت">ت</button>
					<p>عدد الأحرف في كل كلمة يجب أن يكون ٤ أو أكبر</p>
					<p>يحسب لك الفوز بعد الحصول على ٢٠ كلمة</p>
				</Box>
			</Modal>
			<Modal
				open={strkModalShown}
				onClose={closeStrkModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				>
				<Box sx={modalStyle}>
					{wonToday && <h1 className="align-center winning-text">💥💥 أحسنت 💥💥</h1>}
					{wonToday && <hr/>}
					<h2 className="align-center">إحصائيات</h2>
					<div className="statBox">
						<p className="modal-stat-text">عدد الانتصارات المتتالية</p>
						<button className="stats-letter-blue" value="ت">{streak}</button>
					</div>
					<div className="statBox">
						<p className="modal-stat-text">أعلى عدد انتصارات متتالية</p>
						<button className="stats-letter-green" value="ت">{highestStreak}</button>
					</div>
					<div className="statBox">
						<p className="modal-stat-text">أعلى نتيجة عبر الزمن</p>
						<button className="stats-letter-blue" value="ت">{highScore}</button>
					</div>
					<div className="statBox">
						<p className="modal-stat-text">معدل عدد الأحرف في كل كلمة</p>
						<button className="stats-letter-green" value="ت">{parseFloat(avgWordLength).toFixed(2)}</button>
					</div>
					<div className="statBox">
						<p className="modal-stat-text">عدد التخمينات الصحيحة عبر الزمن</p>
						<button className="stats-letter-blue" value="ت">{totalWordsGuessedEver}</button>
					</div>
				</Box>
			</Modal>
			<Modal
				open={coffeeModalShown}
				onClose={closeCoffeeModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				>
				<Box sx={coffeeModalStyle}>
					<h2 className="align-center">ادعمونا إذا استمتعتوا بتركيب</h2>
					<button className="stats-letter-blue align-center" value="coffee" onClick={() => openInNewTab('https://www.buymeacoffee.com/tarkeeb')}>☕</button>
				</Box>
			</Modal>
			<FaQuestionCircle className="question-icon" onClick={openInstrModal} />
			<FaChartBar className="chart-icon" onClick={openStrkModal} />
			<BiCoffee className="coffee-icon" onClick={openCoffeeModal} />
			<img className="tarkeeb-title" src={title2} alt="Logo" />
			<div className="divStyle">
				<p className="current-guess">{guess}</p>
				<div className="letters-div">
					{todaysWord.split('').map((letter, i) => <button className={(i !== middleIndex) ? "letter-button " : "middle-letter"} value={letter} key={i} onClick={(e) => handleLetterClick(e)}>{letter}</button>)}
					<div>
						<BsBackspace className="close-button" onClick={handleBackSpace} />
						{/*<button className='close-button' value="backspace" onClick={handleBackSpace}><BsBackspace /></button>*/}
					</div>
				</div>
			</div>
			<div className="guesses-div">
				<p className="guesses">{printCorrect()}</p>
			</div>
			<p className="score">النتيجة: {translateScore(score)}</p>
			<LinearProgress variant="determinate" value={(correct.length / validWords.length) * 100} className="progress-bar" />
		</div>
	);
}

export default App;
